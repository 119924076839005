import React, {useState , useEffect} from 'react'
import update from 'react-addons-update';

// share components
import Loader from '../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import Toast from '../../../shared/components/Toast/Toast';
import Select from '../../../design-system/components/Form/Select/Select';
import TextFieldDefault from '../../../shared/components/Form/TextFielDefault';
import { log } from '../../../shared/utils/console.js'
// @material-ui/core
import  {Card, Grid, CardContent, Typography, Button, Box} from '@material-ui/core';
// SDK-POINT
import {ObjectService, PermissionService} from '@sdk-point/talisis'
//import * as PermissionService from '../../../services/PermissionService'; 

import {PERMISSION_OBJ} from './PermissionObj'

const PermissionForm = (props) => {
  const [state, setState] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toast, setToast] = useState({ toastProps: {message: '',open: false}})
  const permissionId = props.match.params.id; 
  const permissionService = new PermissionService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)

  const items = [
    { to: '/Home', label: 'Administrador' ,title:''},
    { to:'/permisos/management', label: 'Permisos' , title:''}, 
    { label: (permissionId) ? 'Editar':'Nuevo', title:''} 
  ];

  useEffect(()=>{
     getAllRequest();
  },[])

  const getAllRequest = async() => {
    try {
       setIsLoaded(true);
       const objectService = new ObjectService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)
       const responseObjects = await objectService.getAll(1,[],10000);
       const dataObject = responseObjects.map(it=> { return {id:it.id, label:it.name, parent_id:it.parent_id}})
       PERMISSION_OBJ[3].items = dataObject;
       PERMISSION_OBJ[5].items = [{id:null, label:'Sin object padre'},...dataObject];
       if(permissionId){
        const responsePermission = await permissionService.getAll(1,[{'id':permissionId}],10);
        const permission = responsePermission.data.find(item=> item.id == permissionId);
        permission['parent_id'] = (permission['parent_id'])?  permission['parent_id']: 'sin_parent_id';
        setState(formInput(permission));
       } else {
        setState(PERMISSION_OBJ);
       }
    } catch (error) {
      log('Ops! Ha ocurrido un error', error);
    } finally {
      setIsLoaded(false);
    }
  } 
  const formInput = (responsePermission) => {
    let data = PERMISSION_OBJ.map(item=> {
        if (responsePermission) {
          item.defaultValue = responsePermission[item.name]
          if(item.name==='id'){
            item.visibility = 'none';
          }
        }  
       return item;
     })
     return data;
  }

  const handleChange = (e) =>{ 
    if(e.target.name==='object_id') {
      let objectSelect = state[5].items.find(item=>item.id===e.target.value);
     
      setState(update(state,{[5]:{error:{$set:false},
        defaultValue:{$set:(objectSelect.parent_id) ? objectSelect.parent_id: null},
        helperText:{$set:''},
        autoFocus:{$set:false}}}))
    }
    const {name, value} = e.target; 
    const indexObj = state.findIndex(obj=> obj.name===name);
    state[indexObj].defaultValue = value;
    state[indexObj].error = false;
    state[indexObj].helperText = '';
  }

  const ListInput = () => {
    const Item = state.map((item,index)=> {
            item.onChange = handleChange;
            if (item.type==='select') {
              return ( 
                    <Grid item xs={12} md={12} sm={12} key={index}>
                       <Select {...item}/> 
                   </Grid> 
             )
            }
            return (
                <Grid item xs={12} sm={12} md={12} key={index}>
                   <TextFieldDefault {...item} /> 
                </Grid>
           )
    })
    return  (Item);
  }
  
  const handleSubmit = async () => {
    let bodyObject = {};
    let isError=false;
    setIsLoaded(true);
      for (let i=0; i<state.length; i++) {
          const name = state[i].name;
          let value = state[i].defaultValue;
          value = (name==='parent_id' && value==='sin_parent_id')? null: value;
          if(state[i].required) {
            isError = (value) ? false :true;
            if(isError) {
               const indexObj = state.findIndex(obj=> obj.name===name);
               state[indexObj].error = true;
               state[indexObj].helperText = 'Campo requerido'
            }
         }
          bodyObject[name]= value;
      }
    try {
        if(!isError) {
          const response = (permissionId) ? await permissionService.update(bodyObject): await permissionService.save(bodyObject);
          setToast({toastProps:{open:true, message:'Se Creo satisfactoriamente'}})  
        } else {
          setToast({toastProps:{open:true, message:'Ingresar campos requeridos'}})
        }
    } catch (error) {
        setToast({toastProps:{severity: "error", open: true, message: "Ops! Ha ocurrido un error" }})
       log('Ops! Ha ocurrido un error', error);
    } finally {
      setIsLoaded(false) 
        setState(update(state,{$set:PERMISSION_OBJ}))
      
    }
  }
  
  const handleFinishedToast = () => {  
    setToast({toastProps:{open:false, message:''}})
  }

  const handleback = () => props.history.push(`/permisos/management`);

  return(
        <>
          <Breadcrumb items={items} />
            <Grid container spacing={1}>
                <Grid container item xs={6}>
                  <Loader loading={isLoaded}>
                    <Card>
                      <CardContent>
                        <Typography variant="subtitle2" className="mb-4">Permiso</Typography>
                        <ListInput/>
                      </CardContent>    
                    </Card>
                  </Loader>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Box display="flex" justifyContent="flex-end" width="100%" >
                      <Button color="secondary" variant="contained" onClick={handleback}>Cancelar</Button>
                    </Box>
                  </Grid>

                  <Grid item xs={1}>
                    <Box display="flex" justifyContent="flex-end" width="100%" >
                      <Button color="primary" variant="contained" onClick={handleSubmit}>Guardar</Button>
                    </Box>
                  </Grid>
                </Grid>
            </Grid>
          <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={3000} />
        </>
    )
}

export default PermissionForm;