import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';

import { makeStyles } from '@material-ui/core';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme) => ({
    card:{
        width: 286,
        [theme.breakpoints.down('xs')]: {
            width: 224,
        }
    },
    cardContent:{
        display: 'grid',
        padding: '19px 16px 7px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 12px 8px',
            height: 'fit-content',
        }
    },
    cardMedia:{
        height: 167,
        [theme.breakpoints.down('xs')]: {
            height: 130
        }
    },
    cardActions:{
        borderTop: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        padding: '12px 16px',
        height: 42,
        [theme.breakpoints.down('xs')]: {
            padding: '7px 12px',
            height: 33,
        }
    },
    cardContentTags:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 24,
        padding: '4px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '3px 0',
            height: 19,
        }
    },
    cardContentTitle:{
        height: 58,
        padding: '7px 0',
        '& .title':{
            marginBottom: 8
        },
        '& div':{
            height: 18
        },
        [theme.breakpoints.down('xs')]: {
            height: 45,
            padding: '5px 0',
            '& div':{
                height: 14
            },
            '& .title':{
                marginBottom: 6
            }
        }
    },
    cardContentLogo:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        '& .logo':{
            height: 18
        },
        [theme.breakpoints.down('xs')]: {
            height: 43,
            '& .logo':{
                height: 14
            }
        }
    },
    cardContentLevelModality:{
        height: 16,
        [theme.breakpoints.down('xs')]: {
            height: 12,
        }
    },
}));

export default function CourseCardSkeleton(props) {
    const classes = useStyles();
    const {numItems, ...rest} = props;
    return (
        <Box display="flex" gridGap={24} position="absolute" {...rest}>
            {
                [...Array(numItems).keys()].map(it =>                     
                    <Card variant="outlined" key={uuid()} className={classes.card}>
                        <CardMedia className={classes.cardMedia}>
                            <Box className="card-skeleton" height={1} />
                        </CardMedia>
                        <CardContent className={classes.cardContent}>
                            <Box className={classes.cardContentTags}>
                                <Box className="card-skeleton" width={51} height={1} borderRadius={45}/>
                                <Box className="card-skeleton" width={24} height={1} borderRadius={30}/>
                            </Box>
                            <Box className={classes.cardContentTitle}>
                                <Box className="card-skeleton title" width="70%" borderRadius={45}/>
                                <Box className="card-skeleton" width="74%" borderRadius={45}/>
                            </Box>
                            <Box className={classes.cardContentLogo}>
                                <Box className="card-skeleton logo" width="32%" borderRadius={45}/>
                            </Box>
                            <Box display="flex" alignItems="center" className={classes.cardContentLevelModality}>
                                <Box className="card-skeleton" width="60%" borderRadius={45} height={1}/>
                            </Box>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <Box width={1} height={1}>
                                <Box className="card-skeleton" width="30%" height={1} borderRadius={45} marginLeft="auto"/>
                            </Box>
                        </CardActions>
                    </Card>
                )
            }
        </Box>
    );
}

CourseCardSkeleton.defaultProps = {
    numItems:6,
};

CourseCardSkeleton.propTypes = {
    numItems: PropTypes.number
};