import React from 'react';
import { useHistory } from 'react-router-dom';
import { clsx } from 'clsx';

// @material-ui
import {
    Box,
    Button,
    Card,
    InputLabel,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';

// shared
import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';
import Loader from '../../shared/components/Loader/LoaderContent';
import { setSessionCookie } from "../../shared/utils/Sessions";
import SimpleStatus from "../../shared/components/Table/components/SimpleStatus"
import { MEMBERSHIPS } from '../../shared/constants/memberships';
import { log } from '../../shared/utils/console.js'


// redux
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/userActions';
import * as profilePicActions from '../../redux/actions/profilePicActions';
import * as menuActions from '../../redux/actions/menuActions';
import * as membershipActions from '../../redux/actions/membershipActions';
import StudentList from './StudentList/StudentList';
//SDK
import { RestrictionsService, RoleService, SimulationService, UserService } from '@sdk-point/talisis';

const SimulationServiceSDK = new SimulationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RestrictionsServiceSDK = new RestrictionsService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '20px'
    },
    button: {
        marginLeft: 'auto',
        marginBottom: '5px'
    },
    descripcion_role: {
        background: theme.palette.colors.grey[50],
        borderRadius: '2px',
        width: '99px',
        height: '24px',
        padding: '4px 8px',
        gap: '6px',
        marginRight: '10px',
        marginTop: "15px",
    }
}));

const items = [
    { to: '/home', label: 'Home' },
    { label: 'Simulation' },
];

const units = [
    { ou: "iesalud", ouLabel: "IESALUD" },
    { ou: "talisis", ouLabel: "TALISIS" },
    { ou: "uerre", ouLabel: "U-ERRE" },
    { ou: "unid", ouLabel: "UNID" },
    { ou: "hh", ouLabel: "HARMON HALL" }
];

let arrayRole = {}

const Simulation = props => {
    const classes = useStyles();
    const history = useHistory();
    //
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { user, profilePic, applyUser, applyProfilePic, applyMembership, getUserMenu } = props;

    const handleChange = async (e, items) => {
        for (let index = 0; index < items.length; index++) {
            try {
                setLoading(true);
                const responseRole = await UserServiceSDK.getRolesByUser(items[index].person_id);
                arrayRole[items[index].person_id] = []
                if (Boolean(responseRole.length)) {
                    for (const rol of responseRole) {
                        arrayRole[items[index].person_id].push(rol.name);
                    }
                }
            } catch (error) {
                log('Error al obtener roles del usuario simulado', error.message);
            } finally {
                setLoading(false);
            }
        }
        if (items && items.length > 0) {
            setData(items);
        } else {
            setData([]);
        }
    };

    const handleUser = async (item) => {
        setLoading(true);
        log(item);

        // backup current user
        localStorage.setItem("admin_session", JSON.stringify({
            user: user ?? {},
            profilePic: profilePic ?? {}
        }));

        // setup fake user
        let company = !item.company_id ? "" : item.company_id.toLowerCase();
        if (item.email && item.email.includes('iesalud') === true) {
            company = 'iesalud'
        }
        const ou = company;
        const unit = units.filter(u => u.ou === ou)[0];
        const f_user = {
            userName: item.full_name || "",
            email: item.email || "",
            student_id: item.user_id,
            person_id: item.person_id,
            is_admin: false,
            is_fake: true,
            ou: unit.ou,
            ouLabel: unit.ouLabel,
            profile_picture_url: item.profile_picture_url,
            membership_id: item.membership_id || MEMBERSHIPS.FREEMIUM,
            company_id: item.ou_id ? item.ou_id.toUpperCase() : 'TAL',
        };

        try {
            const f_profilePic = {
                src: item.profile_picture_url ?? '/images/avatars/placeholder.png',
                blob: false
            };

            applyProfilePic(f_profilePic);

            const session = ({
                "person_id": user.person_id,
                "student_id": item.person_id
            });

            // create bd session
            await SimulationServiceSDK.createSession(session).then(async response => {
                f_user.fake_session = await response;

                f_user.interests = f_user.interests || [];
                f_user.first_name = f_user.first_name || '';
                f_user.last_name = f_user.last_name || '';

                const getUserRestrictions = async () => {
                    try {
                        const response = await RestrictionsServiceSDK.getRestrictionsByUser(item.person_id);
                        f_user.restrictions = response.result;
                    }
                    catch (error) {
                        console.error('Error al obtener las restricciones:', error);
                    }
                }
                await getUserRestrictions();

                // apply fake user
                applyUser(f_user);
                getUserMenu(f_user);
                applyMembership(f_user.membership_id);
                setSessionCookie(f_user);

                history.push("/home");
            });
        } catch (e) {
            log(e.message);
            setLoading(false);
        }
    };

    return <React.Fragment>
        <Breadcrumb items={items} />
        <Loader loading={loading}>
            <Typography variant="h2" className={classes.title}>Simular usuario</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                    <StudentList user={user} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} >
                    {data.length > 0 &&
                        <Card>
                            <StudentInfo data={data} handleUser={handleUser} loading={loading} />
                        </Card>
                    }
                </Grid>
            </Grid>
        </Loader>
    </React.Fragment>
}

const StudentInfo = ({ data, handleUser, loading }) => {
    const classes = useStyles();
    const Status = ({ status }) => {
        const text = <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ padding: '0px 10px' }}
        ><i className="ri-checkbox-circle-fill" style={{ marginRight: "5px" }}></i><Typography className='variant-subheading-3'>Usuario {status ? 'activo' : ' inactivo'}</Typography></Grid>
        return <SimpleStatus text={text} state={(status) ? 1 : 2} />
    }

    return <Paper elevation={1} className="p-4 mt-2">
        {data.map(item => {
            let ouLabel = ""

            if (item.company_id) {
                if (item.company_id == 'UERRE' && (item.email && item.email.includes('iesalud'))) {
                    ouLabel = 'IESALUD'
                } else {
                    ouLabel = units.filter(unit => unit.ou === item.company_id.toLowerCase())[0].ouLabel;
                }
            }

            return <Grid key={item.id} container spacing={2}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Status status={item.is_active} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <InputLabel><Typography variant='body2' className='semi-bold'>Nombre de usuario</Typography></InputLabel>
                    <TextField
                        variant="outlined"
                        id="nombre"
                        fullWidth
                        margin="dense"
                        placeholder={item.full_name}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {Boolean(arrayRole[item.person_id].length) && <InputLabel style={{ marginBottom: '15px' }}><Typography variant='body2' className='semi-bold'>Descripción</Typography></InputLabel>}
                    {
                        arrayRole[item.person_id].map((role) => {
                            return (
                                <Typography key={role} className={clsx(classes.descripcion_role, "semi-bold")} variant="caption">
                                    {role}
                                </Typography>
                            )
                        })
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <InputLabel><Typography variant='body2' className='semi-bold'>Student ID</Typography></InputLabel>
                    <TextField
                        variant="outlined"
                        id="userId"
                        fullWidth
                        margin="dense"
                        placeholder={item.user_id}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <InputLabel><Typography variant='body2' className='semi-bold'>Marca</Typography></InputLabel>
                    <TextField
                        variant="outlined"
                        id="unidadOperativa"
                        fullWidth
                        margin="dense"
                        placeholder={ouLabel}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <InputLabel><Typography variant='body2' className='semi-bold'>Correo electrónico</Typography></InputLabel>
                    <TextField
                        variant="outlined"
                        id="email"
                        fullWidth
                        margin="dense"
                        placeholder={item.email}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Button color="primary" variant="contained" size="medium" className={classes.button} onClick={() => handleUser(item)}>Iniciar simulación</Button>
            </Grid>
        })
        }
    </Paper>
};

const mapStateToProps = ({ userReducer, profilePicReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...menuActions, ...membershipActions };
export default connect(mapStateToProps, mapDispatchToProps)(Simulation);