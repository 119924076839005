import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootFilterSection: {
        color: theme.palette.colors.darkBlack[200],
        backgroundColor: 'transparent !important',
    },
    icon: {
        color: theme.palette.colors.darkBlack[200],
    },
    accordionTitle: {
        padding: '0px',
        borderRadius:'0px',
        backgroundColor: 'transparent !important',
        border: 0,
    },
    accordionDetails: {
        padding: '16px 0 16px 0',
        backgroundColor:  'transparent !important',
    },
    filterCheckbox:
    {
        padding: '0 8px 0 0',
    },
    formControlContainer: {
        padding: '0 8px 8px 9px !important',
    }
}));