export const debounce = (func) => {
    let timer;
    return function(...args) {
      const debounceContext = this;

      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        timer = null;
        func.apply(debounceContext, args);
      }, 1000);
    };
};
