import React from 'react';

// @material-ui/core
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';


// redux
import { connect } from 'react-redux';

// shared - components
import Form from '../../../../../shared/components/Form/Form';
import DetailFileButton from '../../../../../shared/components/InputFileButton/DetailFileButton';
import InputFileButton from '../../../../../shared/components/InputFileButton/InputFileButton';
import ConfirmDialog from '../../../../../shared/components/ConfirmDialog/ConfirmDialog';

// components
import ShippingGuide from '../components/ShippingGuide';
import PhysicalDocumentReview from '../components/PhysicalDocumentReview';
import RejectedFileComments from '../components/RejectedFileComments';

import dateFormat from '../../../../../design-system/utils/dateFormat';

// styles
import useStyles from '../../../../../assets/styles/jss/pages/requests/RequestDetailStyle';

import {REQUEST_STATUS_ID, REQUEST_STEP_TYPE_ID} from '../../../../../shared/constants/request-status';

import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import RejectedFilesMssg from './components/RejectedFilesMssg';

const ACTIVE_DETAIL_STATUS = [REQUEST_STATUS_ID.APPROVE, REQUEST_STATUS_ID.REVIEW];
const DETAIL_PROPERTIES = ['form', 'links'];

const STEP_AUTHORIZATION_LIST = [REQUEST_STEP_TYPE_ID.SHIPPING_GUIDE, REQUEST_STEP_TYPE_ID.PHYSICAL_DOCUMENT_REVIEW];

function transformValue(item, formValue){
    if(!formValue) return;
    let value = "";

    switch (item.type) {
        case 'select':
            value = item.items.find(it => it.id === formValue).label;
            break;
        case 'checkboxes':
            value = formValue.map(it => it.label).join(',');
            break;
        case 'datepicker':
            value = dateFormat(formValue);
            break;
        default:
            value = formValue;
            break;
    }

    return value;
}

function generateDownloadFileButtons( field, formValue, data, isAuthorizer, handleValidateChangeStatus, handleEditComment) { 
    const activeWorkflowStep = data.find(it => it.is_active);
    let components = [];
    const name = field.name;
    const options = field.options;
    let i = 0;

    for ( const option of options ) {

        const formValueItems = formValue[ name ];
        
        for ( let item of formValueItems ) {
            const hasFile = hasFileOption( name, formValue, item.optionName );

            if ( item.optionName === option.name ) {
                if ( !hasFile ) {
                    components.push( <InputFileButton
                        label={ option.label } 
                        onChange={ null } 
                        outlined={ false } 
                        disabled={ true } 
                        optionName={ item.optionName }
                    /> );
                } else {
                    let file = { isNew: false, path: item.path, value: item.file_name, created_at: item.created_at};

                    if(item.hasOwnProperty('documentStatus')){
                        file.documentStatus = item.documentStatus
                    }
    
                    components.push( 
                        <Box style={{borderBottom: options.length - 1 !== i ? '1px solid rgba(119, 119, 119, 0.2)' : null}}>
                            <DetailFileButton key={uuid()} file={ file } optionName={ item.optionName } label={ option.label } isAuthorizer={isAuthorizer} activeWorkflowStep={activeWorkflowStep} onValidateChangeStatus={handleValidateChangeStatus} statusDocumentValue={item.documentStatus} stepName={field.name}/>
                            
                            {
                                (!!item.commentRejected) && (<RejectedFileComments title={'Comentarios'} comments={[item.commentRejected]} onClick={handleEditComment({optionName: item.optionName, statusValue: item.documentStatus, stepName: field.name, comment: item.commentRejected})}/>) 
                            }
                        </Box>
                    );
                }
                break;
            }
        }
        i++;
    }

    return components;
}

function hasFileOption( name, formValue, optionName ) {
    const value = formValue[ name ];
    let response = false;

    for (let item of value ) {
        if ( item.optionName === optionName && !!item.id ) {
            response = true;
            break;
        }
    }

    return response;
}

function isUserAuthorizer (activeUser, step) {
    return step.authorizers.some(authorizer => authorizer.person_id === activeUser);
}

function stepsWithDetail(steps, activeUser){
    return steps.filter(s => 
        (
            ACTIVE_DETAIL_STATUS.some(status => status === s.status_id)
            && DETAIL_PROPERTIES.some(key => Object.keys(s).includes(key) && s[key] && s[key].length)
        )
        || (REQUEST_STATUS_ID.REVIEW === s.status_id && STEP_AUTHORIZATION_LIST.some(stepItem => stepItem === s.request_step_type_id) && isUserAuthorizer(activeUser, s))
    );
}

function getFormFileName(step) {
    return step.form[0].name;
}

function getPhysicalFiles(step, values) {
    const name = getFormFileName(step);
    const physicalFilesList = step.form[0].options.filter(option => option.isPhysicalFile);
    const optionValues = values[name];
    
    const finalList = physicalFilesList.map(physicalFile => {
        const optionValueItem = optionValues.find(value => value.optionName === physicalFile.name);

        if (!!optionValueItem) {
            physicalFile['path'] = optionValueItem.path;
            physicalFile['physicalReviewStatus'] = optionValueItem.physicalReviewStatus;
        }

        return {...physicalFile};
    });

    return finalList;
};

const RequestDetail = (props) => {
    const {form, values, onChange, onRemoveFile, data: steps, onChangeDetailForm, isAuthorizer, onChangeStatusToDocumentReception, user, onGetShippingGuide, onChangeStatusToPhysicalFilesReview, shipment, deadlineAt} = props;
    const classes = useStyles();
    const [formValue, setFormValue] = React.useState({});
    const [plainTextData, setPlainTextData] = React.useState([]);

    const [rejectDialog, setRejectDialog] = React.useState({
        title: "Rechazar documento",  
        text: "¿Cuál es el motivo del rechazo?", 
        open: false,
    });    
    const [formRejectDialog, setFormRejectDialog] = React.useState({
        comment: "",
        optionName: "", 
        statusValue: 0, 
        stepName: "",
    });

    var disabledConfirmButton = false;

    React.useEffect(() => {
        setPlainTextData(form.filter(it => it.plaintext))
        setFormValue(values);
    }, [form, values]);

    const handleChangePhysicalFileStatus = (step) => {
        return (statusValue, file) => {
            const name = getFormFileName(step);
            onChangeStatusToPhysicalFilesReview(name, statusValue, file);
        };
    };

    const handleValidateChangeStatus = (optionName, statusValue, stepName, isRejected) => {
        if (!isRejected) {
            onChangeStatusToDocumentReception(optionName, statusValue, stepName);
        } else {
            setRejectDialog({
                ...rejectDialog,
                open: true,
            });    
            
            setFormRejectDialog({
                comment: "",
                optionName, 
                statusValue, 
                stepName,
            });
        }
    };

    const resetFormRejectDialog = () => {
        setFormRejectDialog({
            comment: "",
            optionName: "", 
            statusValue: 0, 
            stepName: "",
        });
    };

    const handleConfirmRejectDialog = () => {
        onChangeStatusToDocumentReception(formRejectDialog.optionName, formRejectDialog.statusValue, formRejectDialog.stepName, formRejectDialog.comment);
        resetFormRejectDialog();
    };

    const handleCloseRejectDialog = () => {
        setRejectDialog({
            ...rejectDialog,
            open: false,
        });    
        resetFormRejectDialog();
    };


    const handleChangeTextFieldComment = (event) => {
        const {name, value} = event.target;
        disabledConfirmButton = !!(formRejectDialog.comment) && formRejectDialog.comment.trim().length > 0

        setFormRejectDialog({
            ...formRejectDialog,
            [name]: value,
        });
    };

    const handleEditComment = ({optionName, statusValue, stepName, comment}) => {
        return () => {
            setRejectDialog({
                ...rejectDialog,
                open: true,
            });    
            
            setFormRejectDialog({
                comment,
                optionName, 
                statusValue, 
                stepName,
            });
        };
    };

    return (
        <Grid item xs={12} container>
            {
                stepsWithDetail(steps, user.person_id).map((step, i,{length}) =>  (
                    <Grid item xs={12} key={step.id}>
                        <Typography variant="h5" className="mb-4">{step.label}</Typography>
                        <Card variant="contained" className="bg-bg-fill-stroke-400">
                            {
                                step.links && Boolean(step.links.length) && 
                                <CardContent className="p-4">
                                    <Box className={classes.content}>
                                        {
                                            step.links.map((link, i) =>{
                                                return <React.Fragment key={uuid()}>
                                                    <Box display="flex" mb={1}>
                                                        <Link target="_blank" href={link.link}>{link.label}</Link>
                                                    </Box>
                                                    <Box display="flex" mb={1}>

                                                        <Link target="_blank" href={link.candidate_report_link} style={link.candidate_report_link == "" ? {pointerEvents: "none"} : null}>
                                                            {/* {link.labelRespuesta} */}
                                                            {link.candidate_report_link == "" ? 'Test Pendiente de contestar' : link.labelRespuesta}
                                                            </Link>
                                                    </Box>
                                                        <Divider className="primary my-3"/>
                                                </React.Fragment>

                                            })
                                        }
                                    </Box>
                                </CardContent>
                            }
                            {
                                Boolean(Object.keys(formValue).length && step.form.filter(it => it.plaintext).length) &&
                                <CardContent className="p-0">
                                    <Box>
                                        {
                                            step.form.filter(it => it.plaintext).map((it, index) => {
                                                it.indexId = index;
                                                const isFieldFiles = it.type === 'files';

                                                return <React.Fragment key={uuid()}>
                                                        {
                                                            (isFieldFiles) ? 
                                                            generateDownloadFileButtons(it, formValue, steps, isAuthorizer, handleValidateChangeStatus, handleEditComment) :
                                                            <Box display="flex" mb={1}>
                                                                <Typography color="primary">{it.label}:&nbsp;</Typography>
                                                                <Typography >{transformValue(it, values[it.name])}</Typography>
                                                            </Box>         
                                                        }
                                                    </React.Fragment>
                                            })
                                        }
                                    </Box>
                                </CardContent>
                            }
                            {
                                Boolean(Object.keys(formValue).length) && 
                                <CardContent className="p-0">
                                    {
                                        step.request_step_type_id === REQUEST_STEP_TYPE_ID.DOCUMENTS && 
                                        step.is_active && isAuthorizer &&
                                        <RejectedFilesMssg files={step.form_value[step.form[0].name]}/>
                                    }
                                    <Box>
                                        <Form 
                                            withoutSapcing={step.request_step_type_id === REQUEST_STEP_TYPE_ID.DOCUMENTS}
                                            fields={step.form.filter(it => !it.plaintext)} 
                                            values={formValue} 
                                            onChange={ onChange } 
                                            onRemove={ onRemoveFile } 
                                            isDragAndDropDisabled={ true }
                                        />
                                    </Box>
                                </CardContent>
                            }
                            {
                                (step.request_step_type_id === REQUEST_STEP_TYPE_ID.SHIPPING_GUIDE) && 
                                <CardContent className="p-4">
                                    <Box className={classes.content}>
                                        <ShippingGuide
                                            deadlineAt={deadlineAt} 
                                            onGetShippingGuide={onGetShippingGuide} 
                                            perdonId={user.person_id} 
                                            requestPersonId={step.request_person_id} 
                                            physicalFiles={ getPhysicalFiles(steps[0], values)}
                                        />
                                    </Box>
                                </CardContent>
                            }
                            {
                                (step.request_step_type_id === REQUEST_STEP_TYPE_ID.PHYSICAL_DOCUMENT_REVIEW) && 
                                <CardContent className="p-4">
                                    <Box className={classes.content}>
                                        <PhysicalDocumentReview physicalFiles={ getPhysicalFiles(steps[0], values)} onChangePhysicalFileStatus={handleChangePhysicalFileStatus(steps[0])} shipment={shipment}/>
                                    </Box>
                                </CardContent>
                            }
                        </Card>
                        {
                            length - 1 !== i && <Divider className="primary mt-5 mb-2"/>
                        }              
                    </Grid>
                ))
            }
            <ConfirmDialog {...rejectDialog} 
                onConfirm={handleConfirmRejectDialog} 
                onClose={handleCloseRejectDialog} 
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                disabledConfirmButton={disabledConfirmButton}
            >
                <Box px={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Escribir motivos."
                                multiline
                                margin="dense"
                                maxRows={4}
                                minRows={4}
                                name="comment"
                                value={formRejectDialog.comment}
                                onChange={handleChangeTextFieldComment}
                                variant="outlined"
                                fullWidth
                                required={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </ConfirmDialog>
        </Grid>
    );
};

const mapStateToProps = ({userReducer}) => ({...userReducer});

export default connect(mapStateToProps, null)(RequestDetail);