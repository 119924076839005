import React from 'react';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';

// @material-ui/core components
import { Box, Typography } from '@material-ui/core';

// shared
import * as paths from '../../../../../routes/paths'
import { PAYMENT_METHOD, PRODUCT_TYPE } from '../../../../../shared/constants/orders';

const PaymentInstructions = ({ order, product }) => {
    if (order?.payment?.payment_method === PAYMENT_METHOD.SPEI) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="h2" align='center' className={clsx("text-white", "mb-4")}>Has elegido pago con transferencia electrónica</Typography>
                <Box maxWidth="872px">
                    {product?.product_type_id === PRODUCT_TYPE.COURSE && <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>Una vez que se refleje la transferencia en el sistema,<br />recibirás un correo de confirmación y podrás acceder a tu curso.</Typography>}
                    {product?.product_type_id === PRODUCT_TYPE.PROGRAM && <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>Una vez que se refleje la transferencia en el sistema, recibirás un correo de confirmación y podrás acceder a tu programa y disfrutar de los <Link href={paths.MEMBRESIAS} variant='body1' color='secondary' target='_blank'>beneficios de tu nueva membresía</Link>.</Typography>}
                    {product?.product_type_id === PRODUCT_TYPE.MEMBERSHIP && <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>Una vez que se refleje la transferencia en el sistema, recibirás un correo de confirmación y podrás acceder a los <Link href={paths.MEMBRESIAS} variant='body1' color='secondary' target='_blank'>beneficios de tu nueva membresía</Link>.</Typography>}
                    <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>En unos minutos recibirás una copia del número de referencia para realizar la transferencia en el siguiente correo <b>{order?.person?.email}</b>.</Typography>
                    <Typography variant="body1" align='center' className={clsx("text-white")}>¡Agradecemos tu preferencia y confianza!</Typography>
                </Box>
            </Box>
        )
    }

    if (order?.payment?.payment_method === PAYMENT_METHOD.CASH) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="h2" align='center' className={clsx("text-white", "mb-4")}>Has elegido pago en efectivo</Typography>
                <Box maxWidth="872px">
                    <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>En unos minutos recibirás el número de referencia para realizar tu pago en efectivo en el siguiente correo <b>{order?.person?.email}</b>.</Typography>
                    {product?.product_type_id === PRODUCT_TYPE.COURSE && <Typography variant="body1" align='center' className={clsx("text-white")}>Una vez realizado el pago, recibirás un correo de confirmación y podrás acceder a tu curso.<br />¡Agradecemos tu preferencia y confianza!</Typography>}
                    {product?.product_type_id === PRODUCT_TYPE.PROGRAM && <Typography variant="body1" align='center' className={clsx("text-white")}>Una vez realizado el pago, recibirás un correo de confirmación y podrás acceder a tu programa y disfrutar de los <Link href={paths.MEMBRESIAS} variant='body1' color='secondary' target='_blank'>beneficios de tu nueva membresía</Link>. ¡Agradecemos tu preferencia y confianza!</Typography>}
                    {product?.product_type_id === PRODUCT_TYPE.MEMBERSHIP && <Typography variant="body1" align='center' className={clsx("text-white")}>Una vez realizado el pago, recibirás un correo de confirmación y podrás acceder a tus clases.<br />¡Agradecemos tu preferencia y confianza!</Typography>}
                </Box>
            </Box>
        )
    }

    return null;
};

export default PaymentInstructions;