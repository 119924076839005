import palette from '../palette';

export default {
  root:{
    background: palette.colors.grey[400],
    borderRadius: 8,
    padding: '0px 32px',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: '56px !important',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '15px 0 !important',
    }
  },
};
