export default (text, firstLetter = false) => {
    if(!text) return;
    if(firstLetter){
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    return text.toLowerCase().replace(/\w\S*/g, (txt, index) => {
        const preps = ["del", "de", "la", "con", "que", "a", "se", "o", "el"];
        if(preps.indexOf(txt) < 0 || index === 0){
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
        return txt;
    });      
}