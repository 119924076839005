import React from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';


import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { v4 as uuid } from 'uuid';
import { Divider, Grid, Hidden, Typography } from '@material-ui/core';
import clsx from 'clsx';
import EduOfferList from '../../components/Navbar/components/FloatingMenu/components/EduOfferList';
import ShortcutList from '../../components/Navbar/components/FloatingMenu/components/ShortcutList';
import academies from '../../../shared/constants/academies';
import * as paths from '../../../routes/paths';

import LoginButtonsBlock from '../../components/Login/LoginButtonsBlock/LoginButtonsBlock';
import QuickSearch from '../../components/Navbar/components/QuickSearch'
import DownloadAndroidApp from '../../../assets/images/logos/footer/descargar-android-app.svg';
import DownloadAppleApp from '../../../assets/images/logos/footer/descargar-apple-app.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    collapse:{
        backgroundColor: theme.palette.colors.fillStroke[400]
    },
    listItem:{
        padding: '12px 16px 12px 32px',
        '&.open':{
            backgroundColor: theme.palette.colors.fillStroke[400],
            borderTop: '6px solid transparent',
            position: 'relative',
            '&::before': {
                content: '" "',
                position: 'absolute',
                top: '-6px',
                left: 0,
                right: 0,
                backgroundImage: 'linear-gradient(274.05deg, #CDACFC -8.09%, #8B3FFA 61.08%)',
                height: 6
            },
        }
    },
    listItemAcademy:{
        paddingLeft: theme.spacing(4),
        '& .MuiListItemIcon-root':{
            minWidth: 42,
            '& img':{
                width: 32
            }
        }
    },
    logginButtons:{
        display: 'flex',
        justifyContent: 'center',
        padding: '32px 0',
        gap: 20
    },
    boxDescargaAPP:{
        flexDirection: 'column',
        padding: '32px 0px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        [theme.breakpoints.down('sm')]:{
            padding: '32px 0px',
            display: 'grid'
        }
    },
    marginApps: {
        marginBottom: '24px'
    },
    sidebarFooter:{
        padding: '1.5rem 2rem',
        [theme.breakpoints.down('xs')]:{
            padding: '11px 0px',
        }
    },
    dividerStyle:{
        position: 'relative',
        width: 'calc(100% + 48px)',
        right: '25px'
    }
}));

const SidebarMenu = () => {
    const classes = useStyles();
    const history = useHistory();

    const DESCARGAR_APP_URL = 'https://pointmovil.page.link/tlsNL';
    const DOWNLOAD_APP_GOOGLE_URL = 'https://play.google.com/store/apps/details?id=com.talisis.app.point';
    const DOWNLOAD_APP_APPLE_URL = 'https://apps.apple.com/es/app/talisis/id1621107870';

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickAcademy = id => (event) => {
        event.preventDefault();
        history.push(`${paths.BUSQUEDA}?academia=${id}`);
    };
    
    const handleClickOpenAPP = (type) => window.open(getDownloadUrl(type));
    
    const handleLogin = path => e => {
        history.push(path);
    };

    const getDownloadUrl = (type) => {
        const agent = navigator.userAgent;

        if ( agent.match(/Android/i)
            || agent.match(/webOS/i)
            || agent.match(/iPhone/i)
            || agent.match(/iPad/i)
            || agent.match(/iPod/i)
            || agent.match(/BlackBerry/i)
            || agent.match(/Windows Phone/i))
            return DESCARGAR_APP_URL;

        if (type === 'apple') return DOWNLOAD_APP_APPLE_URL;

        return DOWNLOAD_APP_GOOGLE_URL;
    };

    return (
        <Box display="flex" height={1} flexDirection="column">
            <Box px={2} mb={3}>
                <LoginButtonsBlock className={classes.logginButtons}/>
                <QuickSearch expanded={true}/>
            </Box>

            {/* <-- Menu --> */}
            <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}>
                <ListItem button onClick={handleClick} className={clsx(classes.listItem, {'open': open})}>
                    <ListItemText primary="Explorar" primaryTypographyProps={{variant: 'body1', className: 'semi-bold text-gray-100'}}/>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapse}>
                    <Box pl={4}>
                        <EduOfferList pt={1}/>
                    </Box>
                    <Typography variant="h4" className="text-gray-100" component={Box} p={3} pl={4}>Academias</Typography>
                    <List component="div" disablePadding>
                        {
                            academies.map(academyItem =>                    
                                <ListItem button className={classes.listItemAcademy} key={uuid()} onClick={handleClickAcademy(academyItem.id)}>
                                    <ListItemIcon>
                                        <img src={academyItem.icon} alt={academyItem.name} style={{height: 32}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={academyItem.name} 
                                        primaryTypographyProps={{variant: 'subtitle2', className: 'text-gray-100 text-capitalize'}}
                                    />
                                </ListItem>
                            )
                        }
                    </List>
                    <Divider className="bg-fill-stroke-200 my-3"/>
                    <Box pl={4}>
                        <ShortcutList />
                    </Box>
                </Collapse>
                <ListItem button className={classes.listItem} component={RouterLink} to={paths.SIMPLICITY}>
                    <ListItemText primaryTypographyProps={{variant: 'body1', className: 'semi-bold text-gray-100'}} primary="Conecta"/>
                </ListItem>
                <ListItem button className={classes.listItem}  component={RouterLink} to={paths.EVENTOS}>
                    <ListItemText primaryTypographyProps={{variant: 'body1', className: 'semi-bold text-gray-100'}} primary="Eventos"/>
                </ListItem>
                <ListItem button target="_blank" className={classes.listItem}  component="a" href={paths.BLOG}>
                    <ListItemText primaryTypographyProps={{variant: 'body1', className: 'semi-bold text-gray-100'}} primary="Blog"/>
                </ListItem>
                <ListItem button className={classes.listItem} component={RouterLink} to={paths.MEMBRESIAS}>
                    <ListItemText primaryTypographyProps={{variant: 'body1', className: 'semi-bold text-gray-100'}} primary="Membresías"/>
                </ListItem>
            </List>

            {/* <-- Footer --> */}
            <Box marginTop="auto" px={3}>
                <Divider className={clsx("bg-fill-stroke-200", classes.dividerStyle)}/>
                {/* Descargar APP */}
                <Hidden mdUp>
                    <Grid item xs={12} sm={12} md={2}>
                        <Box className={classes.boxDescargaAPP}>
                            <img className={classes.marginApps} src={DownloadAppleApp} alt="Descargar APP IOS Talisis" onClick={() => handleClickOpenAPP('apple')}/>
                            <img src={DownloadAndroidApp} alt="Descargar APP Android Talisis" onClick={() => handleClickOpenAPP('android')}/>
                        </Box>
                    </Grid>
                </Hidden>
                <Box py={2}>
                    <Box className={classes.sidebarFooter}>
                        <Link variant="body2" color="secondary" component={RouterLink} to={paths.TERMINOS_CONDICIONES}>Términos y condiciones</Link>
                    </Box>
                    <Box className={classes.sidebarFooter}>
                        <Link variant="body2" color="secondary" component={RouterLink} to={paths.AVISO_PRIVACIDAD}>Aviso de privacidad</Link>
                    </Box>
                    <Box className={classes.sidebarFooter}>
                        <Typography  variant="body2" className="text-dark-black-200">Talisis© Copyright 2022 </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SidebarMenu;