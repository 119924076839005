import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    card:{
        width:'300px', 
        marginRight:'10px',
        marginBottom:'15px',
        height:'558.06px', 
        borderRadius: '16px', 
        padding: '8px 24px 8px 24px',
        textAlign:'center',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]:{
            marginRight:'0px',
        }
    },
    cardTextDescription: {
        ...theme.typography.bodyTextSmallRegular,
    },
    cardPopular:{
        border : `1px solid ${theme.palette.colors.violet[300]}`,
        background: 'linear-gradient(180deg, #3B376A 0%, #1C1F24 25.02%)'
    },
    cardFree:{
       backgroundColor:'transparent',
       textAlign:'left' 
    },
    textBlue300: {
        color: theme.palette.colors.blue[300]
    },
    crossedOutPrice: {
        textDecoration: 'line-through'
    },  
    membershipPrice:{
        color: theme.palette.colors.gray[100],
        ...theme.typography.bodyTextSmallSemiBold
    },
    textMembershipPrice: {
        ...theme.typography.bodyTextSmallRegular
    },
    textDarkBlack100: {
        color: theme.palette.colors.darkBlack[100]
    },
    rectangle:{
        width: '252px',
        height: '1px',
        gap: '0px',
        opacity: '0px',
        marginBottom: '5px',
        background:(props)=>{
                        if (props.popular===true) {
                            return theme.palette.colors.violet[300]
                        } else {
                            return theme.palette.colors.darkBlack[200]
                        }
        }
    },
    boxActionSubscription:{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        justifyContent: 'flex-end'
    },
    textSavingsByFees:{
        ...theme.typography.bodyTextXsmallRegular,
        marginTop: '5px',
        marginBottom : '10px',
        color: theme.palette.colors.darkBlack[100],
    }
}))