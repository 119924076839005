import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import { Grid, Typography, Icon } from '@material-ui/core/';
import { transferNewStyles } from './transferNewStyles';
import clsx from 'clsx';

// assets
// import SpeiInstructionsSvg from "../../../../../assets/images/banks/payments/spei_instructions.svg";
import SpeiInstructionsSvg from "../../../../../assets/images/banks/payments/spei.svg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Toast from '../../../../../shared/components/Toast/Toast';

// MixPanel
import { MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';

import { getSessionCookie } from '../../../../../shared/utils/Sessions';

const Transfer = ({ user, order, references, onGenerateRef, captured, back, proceed }) => {
    // const classes = classesRef;
    // const history = useHistory();

    const classes = transferNewStyles();
    // const sessionCookie = getSessionCookie();
    // const checkoutCookie = getCheckoutCookie();
    const data = references?.conekta?.spei || {};
    
    const [accordionExpanded, setAccordionExpanded] = useState(true);
    const [toastProps,setToastProps]=useState({severity: "error", open: false, message: ""});

    if (!data.amount) data.amount = order?.total || "0";
    if (!data.bank) data.bank = "----";
    if (!data.clabe) data.clabe = "----";
    if (!data.currency) data.currency = "MXN";
    
    const sendTrack = () => {
        const session = getSessionCookie();
        
        const role = JSON.parse(localStorage.getItem('permissions'));
        
    };
    useEffect(() => {
        if (data.clabe != "----")
            sendTrack();
    }, []);

    const handleCloseToast = ()=>{

    };

    const valueValidator = (value) =>{
        if (value == null || value == 'undefined'){
            let Fixed= 'Sin informacion';
            return Fixed;
        }
        return value;
    }

    return (
        <>
            <Grid item xs={12}>
                {/* <Grid container direction="row" spacing={12}> */}
                <Grid container direction="row">
                    <Grid item xs={12} className='mt-5 mb-5'>
                        <Accordion className={clsx('bg-fill-stroke-400',classes.accordion)} expanded={accordionExpanded}>
                            <AccordionSummary className={clsx('bg-fill-stroke-400','px-3')} expandIcon={<ExpandMoreIcon className={classes.paymentMethodsAccordionTitleArrow} />} onClick={() => { setAccordionExpanded(!accordionExpanded) }}>
                                <Grid container wrap='nowrap' alignItems='left'>
                                    <Icon className='ri-swap-box-line font-size-16' />
                                    <Typography variant='body2' className={clsx('semi-bold','pl-3')} align='left'>
                                        Información acerca del pago por Transferencia
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.paymentMethodsAccordionDetails}>
                                <img style={{maxWidth:'100%'}} src={SpeiInstructionsSvg} alt="instrucciones efectivo"></img>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
            <Toast {...toastProps} onFinished={handleCloseToast} duration={3000} />
        </>
    )
};

export default Transfer;
