import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    modal:{
        width:'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border :'0px',
    },
    iconCloseModal: {  
        color: theme.palette.colors.darkBlack[200],
    },
    cardModal: {
        width: '641px',
        height: '644px',
        top: '82px',
        left: '400px',
        gap: '0px',
        borderRadius: '8px',
        opacity: '0px',
        [theme.breakpoints.down('xs')]: {
            width: '330px !important',
            height: '717px !important',        
        },
    },
    loaderContainer:{
        [theme.breakpoints.down('xs')]: {
            width: '330px !important',
            height: '717px !important',        
        },
        width: '641px',
        height: '644px',
    },
    cardTitleMain:{
        marginBottom: '5px',
        color: theme.palette.colors.gray[50],
        ...theme.typography.bodyTextLargeRegular
    },
    cardMain:{
        width: '461px',
        height: '112px',
        top: '112px',
        left: '91px',
        paddingTop: '24px',
        gap: '24px',
        borderRadius: '0px 0px 8px 8px',
        opacity: '0px',
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        [theme.breakpoints.down('xs')]: {
            width: '298px !important',
            height: '88px !important',
            paddingTop: '12px !important',        
        },
     },
     cardMainBox: {
        width: '64px',
        height: '64px',
        padding: '16px',
        gap: '8px',
        borderRadius: '8px',
        opacity: '0px', 
        background: 'radial-gradient(139.06% 139.06% at -19.53% 119.53%, #EBB74C 0%, #733AD0 48.99%, #292A35 100%)',
        marginLeft: '25px',
        [theme.breakpoints.down('xs')]:{
            marginLeft: '10px !important',
            marginRight: '10px',
          }
     },
     modalSubTitle:{
        color: theme.palette.colors.blue[300],
        marginTop: '25px',
        marginBottom: '25px',
        [theme.breakpoints.down('xs')]:{
            marginTop: '15px !important',
            marginBottom: '15px !important',
        }
     }
}))