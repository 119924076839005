import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    card: {
        width: '232px',
        height: '128px',
        gap: '0px',
        borderRadius: '8px 0px 8px 8px',
        opacity: '0px',
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        margin: '0 auto',
        [theme.breakpoints.down('xs')]:{
            width: '298px',
            padding: '0px 0px 0px 16px !important'
        }
    },
    savingTitle:
    {
        width: '89px',
        height: '24px',
        padding: '4px 10px 4px 10px',
        gap: '10px',
        borderRadius: '0px 0px 0px 8px',
        opacity: '0px', 
        background:'#8B3FFA', 
        ...theme.typography.captionLargeSemiBold
    },
    textSavingsByFees:{
        ...theme.typography.bodyTextXsmallRegular,
        marginTop: '5px',
        marginBottom : '10px',
        color: theme.palette.colors.darkBlack[100],
        [theme.breakpoints.down('xs')]:{
            marginTop: '12px',
        }
    }, 
    btnToSubscribe: {
        marginTop: '20px',
        marginLeft: '10px',
        marginRight:'10px',
        [theme.breakpoints.down('xs')]:{
            width:'298px !important',
            marginTop: '10px',
            marginLeft: '0px',
            marginRight:'0px',
        }
    }
}))