import React from 'react';
import { useSelector } from 'react-redux';

// @material-ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// assets
import useStyles from '../../../assets/styles/jss/pages/checkout/checkout';

// components
import OrderSummaryCard from '../components/OrderSummaryCard/OrderSummaryCard';
import objectIsEmpty from '../../../design-system/utils/objectIsEmpty';

const StudentInfo = () => {
    const classes = useStyles();
    const { personInfo } = useSelector(state => state.checkoutReducer);

    if (personInfo && objectIsEmpty(personInfo)) {
        return null;
    }

    return (
        <OrderSummaryCard title="Estudiante" titleVariant="body1" titleClassName="semi-bold" showDivider>
            <Box className="py-2">
                <Grid container spacing={2}>
                    <Grid item className={classes.orderSummaryLabel}><Typography variant="body2" className='semi-bold'>Nombre:</Typography></Grid>
                    <Grid item><Typography variant="body2">{personInfo?.userName}</Typography></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item className={classes.orderSummaryLabel}><Typography variant="body2" className='semi-bold'>Teléfono:</Typography></Grid>
                    <Grid item><Typography variant="body2">{personInfo?.phone_number}</Typography></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item className={classes.orderSummaryLabel}><Typography variant="body2" className='semi-bold'>Correo:</Typography></Grid>
                    <Grid item><Typography variant="body2">{personInfo?.email}</Typography></Grid>
                </Grid>
            </Box>
        </OrderSummaryCard>
    )
};

export default StudentInfo;