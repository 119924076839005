import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
// Shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import Toast from '../../../shared/components/Toast/Toast';
import Select from '../../../design-system/components/Form/Select/Select';
import { log } from '../../../shared/utils/console.js'
// Services
import * as RoleService from '../../../services/RoleService';

// @material-ui
import {Card,TextField,Grid,CardContent,Typography,Button,Box} from '@material-ui/core';

import {personRoleObj} from './PersonRoleObj';

//SDK
import { UserPermXService,UserXService } from '@sdk-point/talisis'; 

const UserPermXServiceSDK = new UserPermXService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const UserXServiceSDK = new UserXService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const PersonRoleForm = (props) => {
  const {match}  = props;
  const userId = match.params.id;

  const items = [
    { to: '/Home', label: 'Home' ,title:''},
    { to:`/usuarios/${userId}/editar`,label: 'Usuario', title:''},
    {label:'Role', title:''} 
  ];

  const [state, setState] = useState(personRoleObj);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toast, setToast] = useState({ toastProps: {message: '',open: false}})
  let history = useHistory();

  useEffect(()=>{
    getUserById();
  },[])

  const getUserById = async() => {
   try {
      setIsLoaded(true);
      const  responsePerson = await UserXServiceSDK.getUser(userId)
      log('useee',responsePerson)
      state[0].defaultValue = `${responsePerson.first_name} ${responsePerson.last_name}`
      const responseRole = await RoleService.getAll(1,[], 100);
      const itemsRole = responseRole.data.map(it=> {
        return {id:it.id, label:`${it.name} ${it.description}`}
      })
      state[1].items = itemsRole;
    } catch (error) {
      log('!Ocurrio un error', error);
    } finally {
      setIsLoaded(false);
    }
  }

  const handleChange = (e) =>{
    const {name, value} = e.target; 
    const indexObj = state.findIndex(obj=> obj.name===name);
    state[indexObj].defaultValue = value;
    state[indexObj].error = false;
    state[indexObj].helperText = '';
  }

  const ListInput = () => {
    const Item = state.map((item,index)=> {
            item.onChange = handleChange;
            const FielItem = (item.type==='select') ?
                                            <Select {...item}/>
                                            :
                                            <TextField 
                                              {...item}
                                              margin="dense"
                                              variant="outlined"
                                              className="form-control" 
                                              fullWidth
                                              InputProps = {{readOnly:(item.readOnly) ? item.readOnly:false }}
                                              style = {{display:item.visibility}}
                                              />;

            return (
            <Grid container spacing={3} key={index}>
                <Grid item xs={12} sm={12} md={6}>
                   {FielItem}
                </Grid>
            </Grid>
           )
        
    })
    return  (Item);
  }
  const handleSubmit = async () => {
    let bodyObject = {};
      setIsLoaded(true);
      for (let i=0; i<state.length; i++) {
          const name = state[i].name;
          const value =  (i===0 && userId) ? userId: state[i].defaultValue;
          bodyObject[name]= value;
      }
    try {
      await UserPermXServiceSDK.postUserPerm(bodyObject,'person-role') 
      setToast({toastProps:{open:true, message:`Se ${(userId) ? 'actualizo':'creo'} satisfactoriamente`}})
      if(userId) {
        history.push(`/usuarios/${userId}/editar`);
      }
    } catch (error) {
      setToast({toastProps:{severity: "error", open: true, message: "Ops! Ha ocurrido un error" }})
    } finally {
      setIsLoaded(false)
    }
  }
  
  const handleFinishedToast = () => {  
    setToast({toastProps:{open:false, message:''}})
  }

  return(
        <>
          <Breadcrumb items={items} />
            <Grid container spacing={3}>
                <Grid container item xs={12}>
                    <Loader loading={isLoaded}>
                    <Card>
                        <CardContent>
                            <Typography variant="subtitle2" className="mb-4">{'Asignar Rol al usuario'}</Typography>
                             <ListInput/>
                        </CardContent>    
                    </Card>
                    </Loader>
                </Grid>
                <Grid container item xs={12}>
                  <Box display="flex" justifyContent="flex-end" width="100%" >
                    <Button color="primary" variant="contained" onClick={handleSubmit}>
                      Guardar
                    </Button>
                  </Box>
                </Grid>
            </Grid>
          <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={3000} />
        </>
    )
}

export default PersonRoleForm;