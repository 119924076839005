import React, { useEffect, useState } from 'react'

import { Button, Card, CardContent, Grid, Typography, Box } from '@material-ui/core'

import ProgressBar from '../ProgressBar/ProgressBar'

//! Example of scoresArray
    // scoresArray = [{
    //     "factor_id": "3814",
    //     "factor_name": "Deseabilidad Social",
    //     "factor_score": 3
    // }]

const TestScoreCard = (props) => {

    const { cardTitle, caption, icon, scoresArray, color, gridItemSize = 6, linkTestResult } = props;

    const [cardArray, setCardArray] = useState([]);
    
    useEffect(() => {
        if(scoresArray.length > 0) setCardArray(scoresArray);
    }, [scoresArray]);

    return (
        <Grid item md={gridItemSize}>
            <Card variant='outlined'>
                <CardContent>
                    <Grid container spacing={1} justifyContent='flex-start' alignItems='center'>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h6" color='primary' align='left'>
                                { cardTitle }
                            </Typography>
                            <Typography variant="caption" align='left'>
                                { caption } 
                                <span style={{fontWeight: "bold"}}>{ cardTitle }</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box display="flex" sx={{justifyContent:{xs: 'start', md: 'end'}}}>
                                {
                                    linkTestResult &&
                                    <Button href={linkTestResult} target='_blank' variant='outlined' color='secondary'>
                                        Ver detalle de mi prueba
                                    </Button>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className='mt-4' alignItems='center'>
                        <Grid item xs={12}>
                            <Grid container justifyContent='space-between' wrap="wrap">
                            {
                                cardArray.length > 0 &&
                                cardArray.map((score) =>
                                    <Grid key={score.factor_id} container spacing={1} alignItems='center' className='mb-3'>
                                        <Grid item xs={2}>
                                            <Grid container justifyContent='center'>
                                                <Card style={{ padding: "5px 8px" }}>
                                                    { icon }
                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Grid container justifyContent='space-between' wrap="wrap">
                                                <ProgressBar
                                                    key={score.factor_id}
                                                    title={score.factor_name}
                                                    value={score.factor_score}
                                                    color={color}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                                
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default TestScoreCard
