import React from 'react';
import clsx from 'clsx';

// components
import { Box, Popover, Typography } from '@material-ui/core';

// styles
import useStyles from '../../../../../../assets/styles/jss/pages/market/PaymentConfirmation';

const HelpIcon = ({ product }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return <>
        <i className={clsx("ri-question-line", "text-warning-300", classes.helpIcon)} 
            onMouseEnter={handleClick} 
            onMouseLeave={handleClose}
            onClick={handleClick}></i>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0
                }
            }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <Box className={clsx(classes.helpIconPopoverContent)}>
                {product?.company_id === "UERRE" && <Typography variant='caption' className={clsx('text-gray-200')}>Pagando tu membresía, accede sin costo a este y otros programas de U-ERRE y UNID</Typography>}
                {product?.company_id === "UNID" && <Typography variant='caption' className={clsx('text-gray-200')}>Pagando tu membresía, accede sin costo a este y otros programas de UNID</Typography>}
            </Box>
        </Popover>
    </>
}

export default HelpIcon;