import React from 'react';

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { ChevronLeft, Group, Lock, Place } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const FORM_NAME = 'address';

// Google Places Autocomplete.
let autocomplete;

const formFields = ['street_line1', 'street_line2', 'zip_code', 'city_name', 'state_name'];
const requiredFields = ['street_line1', 'city_name', 'state_name', 'zip_code'];

export const AddAddress = ({ data, onSetForm, onChange, validate, onSubmit }) => {

    const theme = useTheme();
    const useStyles = makeStyles({
        grid: {
            borderTop: `1.5px solid ${theme.palette.colors.grey[700]}`,
        },
        card: {
            backgroundColor: theme.palette.colors.grey[400],
            padding: '1em',
        },
        title: {
            color: theme.palette.colors.white,
            fontSize: '17px',
            fontWeight: 'normal',
        },
        label: {
            color: theme.palette.colors.white,
            fontWeight: 'bold',
        },
        inputText: {
            border: `1.5px solid ${theme.palette.colors.fillStroke[200]}`,
            borderRadius: '5px',
            color: theme.palette.colors.white,
            fontSize: '14px',
            padding: '0.5em'
        },
        icon: {
            color: theme.palette.colors.white,
            marginRight: '10px',
        },
        // toogleButton: {
        //     backgroundColor: theme.palette.colors.grey[500],
        //     border: `2px solid ${theme.palette.colors.fillStroke[200]}`,
        //     color: theme.palette.colors.white,
        // },
        // toogleButtonSelected: {
        //     '&.Mui-selected, &.Mui-selected:hover': {
        //         backgroundColor: theme.palette.colors.fillStroke[100],
        //         color: theme.palette.colors.white,
        //     }
        // },
        toogleButton: {
            backgroundColor: theme.palette.colors.black,
            border: `2px solid ${theme.palette.colors.fillStroke[200]}`,
            color: theme.palette.colors.white,
        },
        toogleButtonSelected: {
            '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: '#3316D2',
                color: theme.palette.colors.white,
            }
        },
        circularProgress: {
            color: theme.palette.colors.white,
        },
        cancelButton: {
            color: theme.palette.colors.white,
        },
        saveButton: {
            backgroundColor: theme.palette.colors.orange[300],
            color: theme.palette.colors.white,
        },
        alertMessage: {
            backgroundColor: theme.palette.colors.grey[500],
            color: theme.palette.colors.white,
            float: 'left',
            fontFamily: "'Source Sans Pro', sans-serif !important",
            fontSize: '14px',
            marginTop: '-10px',
        },
    });

    const [errorMessage, setErrorMessage] = React.useState('');
    const classes = useStyles();

    const handlePlaceChange = () => {
        let place = autocomplete.getPlace();
        if (!place?.geometry) {
            document.getElementById('autocomplete').placeholder = 'Introduce una ubicación';
        }
        else {
            let inputStreetLine1 = document.getElementById("street_line1");
            let inputZipCode = document.getElementById("zip_code");
            let inputCityName = document.getElementById("city_name");
            let inputStateName = document.getElementById("state_name");

            // Borrar datos antes de cargar nueva dirección
            for (let field of formFields) {
                let element = document.getElementById(field);
                if (element) {
                    element.value = "";
                    handleChange(element);
                }
            }

            place.address_components.forEach(component => {
                const componentType = component.types[0];
                switch (componentType) {
                    case "street_number":
                        inputStreetLine1.value = component.long_name;
                        handleChange(inputStreetLine1);
                        break;
                    case "route":
                        inputStreetLine1.value += ` ${component.long_name}`;
                        handleChange(inputStreetLine1);
                        break;
                    case "sublocality_level_1":
                        inputStreetLine1.value += `, ${component.long_name}`;
                        handleChange(inputStreetLine1);
                        break;
                    case "postal_code":
                        inputZipCode.value = component.long_name;
                        handleChange(inputZipCode);
                        break;
                    case "locality":
                        inputCityName.value = component.long_name;
                        handleChange(inputCityName);
                        break;
                    case "administrative_area_level_1":
                        inputStateName.value = component.long_name;
                        handleChange(inputStateName);
                        break;
                    default:
                        break;
                }
            });
            handleChange({ name: 'latitude', value: String(place.geometry.location.lat()) });
            handleChange({ name: 'longitude', value: String(place.geometry.location.lng()) });
        }
    }

    React.useEffect(() => {
        document.getElementById('autocomplete').addEventListener('input', (e) => {
            const text = e.target.value;
            if (!text) {
                for (let field of formFields) {
                    let element = document.getElementById(field);
                    if (element) {
                        element.value = '';
                    }
                }
            }
        });

        autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
            fields: ['address_components', 'geometry'],
            componentRestrictions: { country: "mx" }
        });
        autocomplete.addListener('place_changed', handlePlaceChange);
    }, [handlePlaceChange]);

    const handleChange = (e) => {
        onChange({ target: { name: e.name, value: e.value } }, FORM_NAME);
    };

    const handleReset = () => {
        for (let field of ['street_line1', 'street_line2', 'city_name', 'state_name', 'zip_code', 'latitude', 'longitude']) {
            setTimeout(() => {
                handleChange({ name: field, value: data.original[field] });
            }, 100);
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item>
                        <Tooltip title="Regresar" placement="left">
                            <ChevronLeft className="Profile_ChevronPointer Profile_ChevronLeft" onClick={() => onSetForm('')} />
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" className={`Profile_Title ${classes.title}`}>
                            Dirección
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="autocomplete"
                    fullWidth
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                        startAdornment:
                            <InputAdornment position="start">
                                <Place style={{ color: theme.palette.colors.fillStroke[100] }} />
                            </InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    id="street_line1"
                    name="street_line1"
                    placeholder="Dirección"
                    fullWidth
                    value={(data && data.street_line1) || ''}
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => onChange(e, FORM_NAME)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id="street_line2"
                    name="street_line2"
                    placeholder="No. Exterior"
                    fullWidth
                    value={(data && data.street_line2) || ''}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => onChange(e, FORM_NAME)}
                    inputProps={{ maxLength: 75 }}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id="zip_code"
                    name="zip_code"
                    placeholder="CP."
                    fullWidth
                    value={(data && data.zip_code) || ''}
                    inputProps={{
                        maxLength: 5
                    }}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => onChange(e, FORM_NAME)}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    id="city_name"
                    name="city_name"
                    placeholder="Ciudad"
                    fullWidth
                    value={(data && data.city_name) || ''}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => onChange(e, FORM_NAME)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    id="state_name"
                    name="state_name"
                    placeholder="Estado"
                    fullWidth
                    value={(data && data.state_name) || ''}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => onChange(e, FORM_NAME)}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={`Profile_Title ${classes.label}`}>
                    Elige quien puede ver tu dirección
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ToggleButtonGroup color="primary" style={{ width: '80%'}} exclusive value={data.visibility} onChange={(e, v) => {
                    onChange({ target: { name: "visibility", value: v } }, FORM_NAME)
                }}>
                    <ToggleButton className={`Profile_VisibilityFields ${classes.toogleButton}`} value="public" classes={{
                        selected: classes.toogleButtonSelected
                    }}>
                        {/* <Group className={classes.icon} />Todos los usuarios */}
                        <Group fontSize='inherit' />
                        <Typography variant='caption'>
                            Todos los usuarios
                        </Typography>
                    </ToggleButton>
                    <ToggleButton className={`Profile_VisibilityFields ${classes.toogleButton}`} value="private" classes={{
                        selected: classes.toogleButtonSelected
                    }}>
                        {/* <Lock className={classes.icon} />Solo yo */}
                        <Lock fontSize='inherit' />
                        <Typography variant='caption'>
                            Solo yo
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                {/* <Typography variant="caption" className="Profile_noteContainer mt-1"> */}
                <Typography display='block' variant="caption" className="Profile_noteContainer mt-2">
                    Cualquier persona puede ver esta información cuando se comunica contigo o consulta tu perfil.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    errorMessage &&
                    <Alert id='address_alert_message' variant="outlined" severity={'error'} className={classes.alertMessage}>
                        {errorMessage}.
                    </Alert>
                }
                {
                    data.toastProps.ok &&
                    <Alert id="address_alert_message" variant="outlined" severity={data.toastProps.ok === 'true' ? "success" : "error"} className={classes.alertMessage}>
                        {data.toastProps.message}.
                    </Alert>
                }
                <Typography variant="subtitle2" className="Profile_buttonsContainer">
                    <Button
                        color="primary"
                        variant="text"
                        className={`Profile_Button ${classes.cancelButton}`}
                        disabled={data.loading}
                        onClick={() => {
                            handleReset();
                            onSetForm('default');
                        }}>
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={data.loading}
                        onClick={() => {
                            const error = validate(requiredFields);
                            if (error) {
                                setErrorMessage("Favor de completar los campos requeridos");
                                setTimeout(() => {
                                    if (document.getElementById('address_alert_message')) {
                                        document.getElementById('address_alert_message').style.visibility = 'hidden';
                                        setErrorMessage("");
                                    }
                                }, 3000);

                                return;
                            }

                            onSubmit(FORM_NAME);
                            setTimeout(() => onSetForm("default"), 4000);
                        }}>
                        {data.loading && <CircularProgress size={20} className={classes.circularProgress} />}
                        {!data.loading && 'Guardar'}
                    </Button>
                </Typography>
            </Grid>
        </Grid>
    )
};
