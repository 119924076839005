import { makeStyles } from '@material-ui/core/styles';
import getImageFromAssets from '../../../../../design-system/utils/getImageFromAssets';

export default makeStyles((theme) => ({
    root: {
        paddingTop: 8,
        maxWidth: 540,
        width: '100%',
        margin: 'auto'
    },
    headerContainer: {
        width: '100%',
        height: '440px',
        background: "radial-gradient(45.6% 92.24% at 58.54% 40.11%, rgba(36, 29, 117, 0.8) 0%, rgba(46, 9, 58, 0) 78.77%), linear-gradient(103.16deg, #353383 0%, #1A0F37 32.63%, #1A1529 49.97%, #120A28 59.23%, #351E76 85.52%, #6E3888 106.78%)",
        [theme.breakpoints.down('lg')]: {
            background: "radial-gradient(45.6% 92.24% at 58.54% 40.11%, rgba(36, 29, 117, 0.8) 0%, rgba(46, 9, 58, 0) 78.77%), linear-gradient(103.16deg, #353383 0%, #1A0F37 32.63%, #1A1529 49.97%, #120A28 59.23%, #351E76 85.52%, #6E3888 106.78%)",
        },
        [theme.breakpoints.down('md')]: {
            background: "radial-gradient(45.6% 92.24% at 58.54% 40.11%, rgba(36, 29, 117, 0.8) 0%, rgba(46, 9, 58, 0) 78.77%), linear-gradient(103.16deg, #353383 0%, #1A0F37 32.63%, #1A1529 49.97%, #120A28 59.23%, #351E76 85.52%, #6E3888 106.78%)",
        },
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: "24px 24px 24px 24px",
            height: '840px',
            backgroundImage: `url(${getImageFromAssets('referralsHeadersm')})`,
        }
    },
    radialContainer: {
        width: "100%",
        height: "440px",
        padding: "0px 108px 0px 108px",
        gap: "155px",
        backgroundImage: `url(${getImageFromAssets('referralsHeaderxl')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        [theme.breakpoints.down('lg')]: {
            backgroundImage: `url(${getImageFromAssets('referralsHeaderlg')})`,
        },
        [theme.breakpoints.down('md')]: {
            backgroundImage: `url(${getImageFromAssets('referralsHeadermd')})`,
            padding: "0px 24px 0px 24px",
        },
        [theme.breakpoints.down('sm')]: {
            background: 'none',
            padding: "0px",
            height: "auto"
        }
    },
    benefitsContainer: {
        width: "100%",
        minHeight: "532px",
        padding: "80px 108px 80px 108px",
        gap: "64px",
        backgroundColor: theme.palette.colors.darkBlack[400],
        [theme.breakpoints.down('md')]: {
            padding: '80px 24px 80px 24px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: "80px 24px 80px 24px",
            height: "auto"
        }
    },
    benefitsItem: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    benefitCard: {
        height: '254px',
        minWidth: '258px',
        borderRadius: '8px',
        border: '2px solid #211C33',
        background: 'radial-gradient(68.55% 68.55% at 50% 50%, rgba(218, 218, 218, 0.05) 0%, rgba(218, 218, 218, 0.05) 42.63%, rgba(218, 218, 218, 0.02) 92.77%)',
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    benefitIconContainer: {
        width: "200px",
        height: "186px",
        position: "absolute",
        background: "radial-gradient(50% 50% at top, rgba(159, 111, 251, 0.20) 0%, rgba(159, 111, 251, 0.10) 30.21%, rgba(159, 111, 251, 0.00) 100%)",
    },
    benefitIcon: {
        marginTop: '-16px',
        fontSize: '35px',
    },
    benefitsTitle: {
        maxWidth: "192px",
        marginTop: "68px"
    },
    benefitsBody: {
        maxWidth: "192px",
        marginTop: "24px"
    },
    membershipsContainer: {
        padding: "64px 24px"
    },
    signupContainer: {
        padding: "32px 24px",
        paddingBottom: "64px",
    },
    membershipCards: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 32
        },
    },
    congratsTitle: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        color: theme.palette.colors.blue[300],
        textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
        }
    },
    discountTitle: {
        color: theme.palette.white,
        fontWeight: `${theme.typography.h1.fontWeight}`,
        fontSize: `${theme.typography.h1.fontSize}px`,
        lineHeight: `${theme.typography.h1.lineHeight}`,
        display: 'inline',
        [theme.breakpoints.down('md')]: {
            fontWeight: `${theme.typography.mobile.h1.fontWeight}`,
            fontSize: `32px !important`,
            lineHeight: `${theme.typography.mobile.h1.lineHeight}`
        }
    },
    discountSubtitleWrapper: {
        display: "inline-block",
        minWidth: "600px",
        [theme.breakpoints.down('960')]: {
            display: "inline",
            minWidth: "auto"
        }
    },
    discountSubtitle: {
        fontWeight: `${theme.typography.h1.fontWeight}`,
        fontSize: `${theme.typography.h1.fontSize}px`,
        lineHeight: `${theme.typography.h1.lineHeight}`,
        display: 'inline',
        background: '-webkit-linear-gradient(left top, #FFA700, #FCDC43)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        [theme.breakpoints.down('md')]: {
            fontWeight: `${theme.typography.mobile.h1.fontWeight}`,
            fontSize: `32px !important`,
            lineHeight: `${theme.typography.mobile.h1.lineHeight}`
        }
    },
    referralsInstructionsSubtitle: {
        color: '#D7DEE5'
    },
    referralsDisclaimerSubtitle: {
        color: theme.palette.colors.fillStroke[100],
        ...theme.typography.web.captionSmallBold
    },
    referralsCopyCodeButtonContainer: {
        '& > div > div': {
            bottom: 'auto',
            marginTop: '10px'
        }
    },
    referralsCopyCodeButton: {
        lineHeight: '24px',
        padding: '12px 16px 12px 48px'
    },
    referralsMembershipsButton: {
        lineHeight: '24px',
        padding: '12px 32px'
    },
    headerCircle: {
        width: 340,
        height: 340,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: '50%',
        background: theme.palette.colors.fillStroke[300]
    },
    signupCard: {
        maxWidth: '488px'
    },
    signupCardContent: {
        paddingLeft: '32px',
        paddingBottom: '32px !important',
        paddingRight: '32px',
        paddingTop: '24px'
    }
}));