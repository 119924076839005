import React from 'react';
import clsx from 'clsx';
import moment from 'moment';

// @redux
import { connect } from "react-redux";

// @material-ui/core
import { Hidden, makeStyles, } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MuiCheckbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// @material-ui/lab
import Alert from '@material-ui/lab/Alert';

// shared components
import Loader from '../../../shared/components/Loader/LoaderContent';
import Toast from '../../../shared/components/Toast/Toast'
import { log } from '../../../shared/utils/console.js'

// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PrintIcon from '@material-ui/icons/Print';
import CreateIcon from '@material-ui/icons/Create';

// SDK
import { AccessService, AccountService } from '@sdk-point/talisis';

// assets
import LogoUerreBlack from "../../../assets/images/logos/logo-uerre-negro.png";
import LogoUnidBlack from "../../../assets/images/logos/logo-unid-negro.png";

const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const AccessServiceSDK = new AccessService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const logos = {
    unid: <img alt="logo" src={LogoUnidBlack} draggable={false} style={{ maxHeight: 40 }} />,
    uerre: <img alt="logo" src={LogoUerreBlack} draggable={false} style={{ maxWidth: 150 }} />
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTypography-h5': {
            fontWeight: 'normal'
        }
    },
    card: {
        padding: '16px 32px',
    },
    control: {
        alignItems: 'flex-start',
    },
    checkbox: {
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingTop: '0px',
        paddingBottom: '0px',
        marginTop: '-2px',
    },
    line: {
        flexGrow: 1,
        marginLeft: '4px',
        borderBottom: '1px solid black',
        height: '16px',
    }
}));

const Checkbox = (props) => {
    const classes = useStyles();
    const { name, value, disabled, label, onChange } = props;

    return (
        <FormGroup>
            <FormControlLabel
                className={classes.control}
                control={
                    <MuiCheckbox
                        name={name}
                        color="primary"
                        disabled={disabled}
                        className={classes.checkbox}
                        checked={value}
                        onChange={onChange}
                    />
                }
                label={label}
            />
        </FormGroup>
    );
};

const dateLocalFormat = (date, format = "L") => {
    const dateUTC = moment.utc(date);
    return dateUTC.local(true).locale('es').format(format);
};

const CommitmentLetter = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [profile, setProfile] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({});
    const [printMode, setPrintMode] = React.useState(false);
    const [toastProps, setToastProps] = React.useState({ message: '' })
    const [form, setForm] = React.useState({
        'checked1': false,
        'checked2': false,
        'checked3': false,
        'checked4': false,
        'checked5': false,
        'checked6': false,
    });
    const { user } = props;

    const getPersonCommitment = async () => {
        try {
            setLoading(true);
            const data = await AccessServiceSDK.getPersonCommitment(user);
            setData(data);

            const response = await AccountServiceSDK.getProfileData(user.person_id);
            setProfile(response);
            setLoading(false);
        }
        catch (e) {
            setToastProps({ severity: "error", open: true, message: 'Ocurrió un error. Por favor inténtalo de nuevo más tarde.' });
            setLoading(false);
            log(e.message);
        }
    };

    const postPersonCommitment = async () => {
        try {
            setLoading(true);
            const response = await AccessServiceSDK.postPersonCommitment(user);
            setData(response);
            setLoading(false);
            setToastProps({ severity: "success", open: true, message: 'La carta ha sido firmada exitosamente.' });
        }
        catch (e) {
            setToastProps({ severity: "error", open: true, message: 'Ocurrió un error al firmar la carta. Por favor inténtalo de nuevo.' });
            setLoading(false);
            log(e.message);
        }
    };

    React.useEffect(() => {
        getPersonCommitment();
    }, []);

    React.useEffect(() => {
        if (data.id > 0) {
            setForm(prev => {
                return {
                    ...prev,
                    checked1: true,
                    checked2: true,
                    checked3: true,
                    checked4: true,
                    checked5: true,
                    checked6: true,
                }
            });
        }
    }, [data]);

    React.useEffect(() => {
        if (printMode === true) {
            const pageTitle = document.title;
            document.title = `Carta de compromiso ${user.ou} ${user.student_id}`;
            window.print();
            document.title = pageTitle;
            setPrintMode(false);
        }
    }, [printMode]);

    const handleChecked = (e, checked) => {
        const { name } = e.target;
        setForm(prev => { return { ...prev, [name]: checked } });
    };

    const handleFinishedToast = () => {
        setToastProps(prev => {
            return {
                ...prev, open: false
            }
        });
    };

    const handleSign = async () => {
        await postPersonCommitment();
    };

    const handlePrint = () => setPrintMode(true);

    return (
        <Box className={classes.root}>
            <Box displayPrint="block" className={clsx(printMode && 'printable')} >
                <Loader loading={loading} transparent={true}>
                    <Card className={classes.card}>
                        <CardContent>
                            {
                                data.id > 0 ? (
                                    <Box displayPrint="none">
                                        <Collapse in={open} >
                                            <Alert
                                                className="mb-2"
                                                severity="success"
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                            >
                                                La carta ya ha sido firmada.
                                            </Alert>
                                        </Collapse>
                                    </Box>
                                ) : null
                            }
                            <Box>{user.ou ? logos[user.ou] : null}</Box>
                            <Typography variant="h5"><b>Universidad Regiomontana S.C.<br />PRESENTE.</b></Typography>
                            <Typography variant="h5" align="justify" className="py-4">Yo <b>{user.userName}</b> {profile.birth_date ? <>con fecha de nacimiento <b>{dateLocalFormat(profile.birth_date)}</b></> : null} con número de matrícula <b>{user.student_id}</b> manifiesto lo siguiente:</Typography>
                            <Grid container spacing={2} className="pb-4">
                                <Grid item xs={12}>
                                    <Checkbox
                                        name="checked1"
                                        value={form.checked1 || false}
                                        disabled={data.id > 0}
                                        label={
                                            <Typography variant="h5" align="justify">
                                                Me comprometo a mantener informado a las autoridades del plantel en caso de que se presente algún síntoma sospechoso por parte del alumno o algún integrante de la familia (con el que convivamos cotidianamente).
                                            </Typography>
                                        }
                                        onChange={handleChecked}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        name="checked2"
                                        value={form.checked2 || false}
                                        disabled={data.id > 0}
                                        label={
                                            <Typography variant="h5" align="justify">
                                                Es de mi conocimiento que el regreso a clases para el periodo actual es de forma semipresencial y voluntario. Y para profesional y posgrado se puso de manifiesto desde la selección de mis cursos en la oferta académica, donde pude visualizar la modalidad de cada una de mis materias con la orientación e información adecuada y suficiente. De manera que, al elegir un curso en modalidad PRESENCIAL, HIBRIDA, PRACTICA o TALLER, acepte conscientemente las condiciones de operación de cada curso y las consideraciones correspondientes. Para el nivel de Bachillerato, manifiesto que previo al inicio de clases tuve la posibilidad elegir regresar o no en modalidad hibrida a través de la encuesta que me fue enviada.
                                            </Typography>
                                        }
                                        onChange={handleChecked}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        name="checked3"
                                        value={form.checked3 || false}
                                        disabled={data.id > 0}
                                        label={
                                            <Typography variant="h5" align="justify">
                                                En el campus, atenderé todos los protocolos de prevención, controles en el ingreso, uso de cubrebocas, uso de gel sanitizante, toma de temperatura, entre otros marcados por la autoridad sanitaria. De lo contrario asumo cualquier consecuencia que esto conlleve.
                                            </Typography>
                                        }
                                        onChange={handleChecked}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        name="checked4"
                                        value={form.checked4 || false}
                                        disabled={data.id > 0}
                                        label={
                                            <Typography variant="h5" align="justify">
                                                Entiendo que en caso de algún contagio en algún curso la institución podrá cambiar la modalidad de la clase de manera por un periodo de 2 semanas; dándose lo anterior, las clases continuarán de forma remota o virtual vía plataforma tecnológica.
                                            </Typography>
                                        }
                                        onChange={handleChecked}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        name="checked5"
                                        value={form.checked5 || false}
                                        disabled={data.id > 0}
                                        label={
                                            <Typography variant="h5" align="justify">
                                                Estoy listo y de acuerdo en regresar a mis actividades presenciales en la Universidad Regiomontana.
                                            </Typography>
                                        }
                                        onChange={handleChecked}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        name="checked6"
                                        value={form.checked6 || false}
                                        disabled={data.id > 0}
                                        label={
                                            <Typography variant="h5" align="justify">
                                                Tengo pleno conocimiento en que la Universidad Regiomontana podría modificar cualquiera de estos supuestos para cumplir con la normativa municipal, estatal o federal y estoy de acuerdo.
                                            </Typography>
                                        }
                                        onChange={handleChecked}
                                    />
                                </Grid>
                            </Grid>
                            <Box className="pt-2 pb-4">
                                <Typography className="pb-4" variant="h5" align="justify">
                                    Asumo este testimonio de veracidad con el compromiso de cumplir con los lineamientos protocolarios que se señalan en el manual de Regreso Seguro <Link href="https://www.u-erre.mx/regreso-a-clases" variant="h5" target="_blank">https://www.u-erre.mx/regreso-a-clases</Link> para más información.
                                </Typography>
                                <Box>
                                    <Alert
                                        severity="info"
                                        variant="outlined"
                                        icon={<SupervisorAccountIcon fontSize="inherit" />}
                                    >
                                        <Typography variant="h6">
                                            <b>En caso de ser menor de edad además de tu firma electrónica, favor de entregar la carta firmada por tu padre, madre o tutor y entregar a tu coach.</b>
                                        </Typography>
                                    </Alert>
                                </Box>
                            </Box>
                            <Box display="none" displayPrint="block" className="pt-2 pb-4">
                                <Box display="flex" className="mb-2">
                                    <Typography component="span" variant="h5">Nombre del Padre/Madre/Tutor: </Typography>
                                    <Box component="span" className={classes.line}></Box>
                                </Box>
                                <Box display="flex">
                                    <Typography component="span" variant="h5">Firma: </Typography>
                                    <Box component="span" className={classes.line}></Box>
                                </Box>
                            </Box>
                            <Hidden smDown implementation="css">
                                <Box displayPrint="none" className="pt-2" textAlign="right">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className="mr-2"
                                        startIcon={<PrintIcon />}
                                        onClick={handlePrint}
                                    >
                                        Imprimir Carta
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CreateIcon />}
                                        disabled={data.id > 0 || !(form.checked1 && form.checked2 && form.checked3 && form.checked4 && form.checked5 && form.checked6)}
                                        onClick={handleSign}
                                    >
                                        Firmar Carta
                                    </Button>
                                </Box>
                            </Hidden>
                            <Hidden mdUp implementation="css">
                                <Box displayPrint="none">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant="outlined" color="primary" startIcon={<PrintIcon />} onClick={handlePrint}>
                                                Imprimir Carta
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant="contained" color="primary" startIcon={<CreateIcon />} disabled={data.id > 0 || !(form.checked1 && form.checked2 && form.checked3 && form.checked4 && form.checked5 && form.checked6)} onClick={handleSign}>
                                                Firmar Carta
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Hidden>
                        </CardContent>
                    </Card>
                </Loader>
                <Toast {...toastProps} onFinished={handleFinishedToast} />
            </Box>
        </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(CommitmentLetter);