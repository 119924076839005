import React from 'react'

// @material-ui/core
import { Card, CardContent} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const SkillsList = (props) => {
    const {items, propLabel, ...rest} = props;
    return (
            <>
            {   items?.length > 0 && 
                <Card variant="outlined" component={Box} {...rest}>
                    <CardContent className="p-4">
                        <Typography variant="h6" className="font-weight-700">Habilidades a desarrollar</Typography>
                        <Box mt={2}>
                            { 
                                items?.map((el) => (
                                    <Chip key={el?.course_group_id} variant="outlined" className="mr-3 mb-2" style={{maxWidth: 280}}
                                        label={el?.description}
                                    />
                                ))
                            }
                        </Box>
                    </CardContent>
                </Card>
            }
            </>
    )
}

SkillsList.defaultProps = {
    items:[]
};

export default SkillsList;
