import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    fileIcon:{
        fontSize: 40,
        color: theme.palette.colors.fillStroke[100]
    },
    card:{
        border: `1px solid ${theme.palette.colors.blue[500]}`,
        backgroundColor: 'rgba(33, 158, 188, 0.16)'
    },
    listItemText:{
        ...theme.typography.subtitle1,
        lineHeight: '16px'
    },
        icon:{
        color: theme.palette.colors.blue[500],
        fontSize: '16px !important'
    },
    listItemIcon:{
        minWidth: 24,
        marginTop: 6
    },
    terms:{
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '16px'
    }
}));