import React from 'react';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
      style={{width: '100%'}}
        role="tabpanel"
        hidden={value !== index}
        id={`abpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {children}
      </div>
    );
};

export default TabPanel;