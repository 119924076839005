import React, { useEffect, useState } from 'react';

// @material-ui/core components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

// core components
import LoaderContent from '../../../../shared/components/Loader/LoaderContent'
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Divider, Icon } from '@material-ui/core';
import clsx from 'clsx';
//import { color } from 'html2canvas/dist/types/css/types/color';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        //justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
            },
        "& .MuiChip-root": {
            borderRadius: '2em'
        }
    },
    cardContent:{
        padding: 0
    },
    chip:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 8px',
        gap: '10px',
        margin: '0px 10px 13px 0px !important',
        background: theme.palette.colors.fillStroke[200],
        border: `0.5px solid rgba(255, 255, 255, 0.15)`,
        borderRadius: 1000,
    },
    btnEdit:{
        padding: '1.6px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '10px',
        textDecoration: 'underline',
        letterSpacing: '1px',
        color: theme.palette.colors.fillStroke[100],

    },
    title:{
        color: 'white',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        /* or 114% */
        display: 'flex',
        alignItems: 'center',
    },
    etiqueta:{
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: theme.palette.colors.gray[100],
        padding: '8px 16px'

    }
}));

const EditIcon = () => {
    return  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.54325 11.8334L11.7272 4.64943L10.7256 3.64785L3.54167 10.8318V11.8334H4.54325ZM5.13046 13.25H2.125V10.2446L10.2248 2.14477C10.3576 2.01198 10.5378 1.93738 10.7256 1.93738C10.9134 1.93738 11.0935 2.01198 11.2264 2.14477L13.2302 4.14864C13.363 4.28148 13.4376 4.46161 13.4376 4.64943C13.4376 4.83726 13.363 5.01739 13.2302 5.15023L5.13046 13.25V13.25ZM2.125 14.6667H14.875V16.0834H2.125V14.6667Z" fill="#A0A9BA"/>
            </svg>    
}

const InterestAreas = ({ loading, userInterests: { userInterests }, handleInterestMode }) => {

    useEffect(() => {
    }, [userInterests])
    

    const classes = useStyles();

    return (
        <LoaderContent loading={loading}>
            <Card style={{ borderRadius: "5px", minheight: "250px", padding: "0.5em", textAlign: "center" }}>
            <Divider className="bg-fill-stroke-200" style={{marginBottom: '15px'}}/>
                <CardContent className={classes.cardContent}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} className='mb-1' justifyContent='space-between' alignItems='center'>
                            <Grid item xs={9}>
                                <Grid container >
                                    <Typography variant='subtitle2' className={classes.title}>
                                        Listado de mis Intereses
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container justifyContent='flex-end'>
                                    <Button variant="text" aria-label="delete" size="medium" endIcon={<Icon className='ri-edit-line font-size-16 text-fill-stroke-100' />}onClick={() => handleInterestMode()}>
                                        <Typography variant='caption' className={classes.btnEdit}>
                                            EDITAR
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        userInterests.length === 0
                        ?
                            <Grid className={classes.root}>
                                <Typography variant='body2' className='mb-4' style={{color: '#F1F3F6'}}>
                                    No hay intereses seleccionados
                                </Typography>
                            </Grid>
                        :
                            <Grid className={classes.root}>
                                {
                                    userInterests.map((area) =>
                                        <Box className={classes.chip}>
                                            <Typography className={classes.etiqueta} key={area.id} variant='caption'>{area.name}</Typography>
                                        </Box>
                                    ) 
                                }                
                            </Grid>
                    }
                    <Divider className="bg-fill-stroke-200" />
                </CardContent>
            </Card>
        </LoaderContent>
    );
};

export default InterestAreas;
