import React, { useState } from 'react';

// @material-ui/core
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
    accordionSummary:{
        flexDirection: 'row-reverse'
    },
}));

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        margin: "12px 0px",
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: "12px 0px",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: '#2C3236',
        padding: `0px 12px`,
        borderRadius: '8px',
        minHeight: '40px',
        '&$expanded': {
            minHeight: '40px',
        },
    },
    content: {
        '&$expanded': {
            margin: '0px 0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '4px 12px',
    },
}))(MuiAccordionDetails);

const CustomizedAccordions = ({ id, title, children, topics, allExpanded }) => {
    const [expanded, setExpanded] = React.useState(false);
    const childCount = React.Children.count(children);
    const classes = useStyles();

    const handleChange = (event, expanded) => {
        if (childCount > 0) {
            setExpanded(expanded);
        }
    };

    return (
        <Accordion
            square
            expanded={allExpanded===true?allExpanded:expanded}
            onChange={handleChange}
        >
            <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={childCount > 0 ? <ExpandMoreIcon color="white" style={{ marginLeft: "-10px",color: "white" }} /> : null}
                aria-controls={`${id}-content`}
                id={`${id}-header`}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ marginLeft: "8px",color: "#F1F3F6" }}>{title}</Typography>
                        </Grid>
                        {/*<Grid item style={{ marginLeft: "auto" }}>
                            <Typography variant="body1" color="white" style={{ marginLeft: "8px",color: "#828D9E" }}>{topics.length} Lecciones</Typography>
                        </Grid>*/}
                    </Grid>
            </AccordionSummary>
            <AccordionDetails aria-disabled="true">
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

export default CustomizedAccordions;