import React from 'react';

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

// @material-ui/icons
import GetAppIcon from '@material-ui/icons/GetApp';


// core components
import InputFileButton from '../../../../../shared/components/InputFileButton/InputFileButton'
import DetailFileButton from '../../../../../shared/components/InputFileButton/DetailFileButton';

// dependencies
import { v4 as uuid } from 'uuid';

const RequestFiles = (props) => {
    const {isAuthorizer, onChange} = props;

    const [files, setFiles] = React.useState([]);
    const [fileList, setFileList] = React.useState([]);

    React.useEffect(() => {        
        setFileList([]);
        setFiles([]);
        
        if(props.files.length){
            setFileVariables(props.files);
        }

    }, [props.files]);

    const setFileVariables = (pFiles) => {        
        const _fileList = [];
        Array.from(pFiles).forEach(file => {
            _fileList.push({
                ...file,
                // id: file.id,
                value: file.name || file.file_name, 
                isNew: Boolean(file.name)
            })
        });
        setFiles(pFiles);
        setFileList(_fileList)
    }

    const handleChangeFile = (file) => {
        const id = uuid();
        const _files = [...files];

        file.id = id;
        _files.push(file);
        
        onChange(_files)
    }

    const handleClickDeleteFile = (file) => (e) => {
        const _files = [...files];

        _files.splice(_files.findIndex(it => it.id === file.id), 1);
        onChange(_files)
    }

    return (
        <Grid item xs={12}>
            <Card variant="contained" className="bg-bg-fill-stroke-400">
                <CardContent>
                    <Box>
                        <Box>
                            {
                                !fileList.length &&
                                <Typography variant="subtitle1" align="center" component="div" className="mt-3">No hay archivos disponibles</Typography>
                            }
                            {
                                isAuthorizer &&
                                <Box display="flex" justifyContent="center" mt={3}>
                                    <Box width="300px" height="30px">
                                        <InputFileButton
                                            width="100%"
                                            height="100%" 
                                            label="Cargar archivo" 
                                            onChange={handleChangeFile} 
                                            outlined={false}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                        <Box>
                            {
                                fileList.map(( it, index ) =>
                                    <DetailFileButton file={ it } key={ index }  onClickDeleteFile={ handleClickDeleteFile }/>
                                )
                            }
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default RequestFiles;