import React, { Component } from 'react';
import { generatePath } from "react-router";

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// shared - core components
import Breadcrumb from '../../../../../shared/components/Breadcrumb/Breadcrumb'
import Select from '../../../../../design-system/components/Form/Select/Select'
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent'
import Form from '../../../../../shared/components/Form/Form'
import removeObjectFromArray from '../../../../../shared/utils/removeObjectFromArray'
import TextError from '../../../../../shared/components/TextError/TextError'
import DividerDetail from '../../../../../shared/components/DividerDetail/DividerDetail'


/// services
import * as routerlinks from '../../../../../routes/routelinks';

// core componentes
import AddField from './components/AddField';
import Authorizers from '../Authorizers/Authorizers';

// @redux
import { connect } from "react-redux"
import * as requestWorkflowActions from '../../../../../redux/actions/requestWorkflowActions'

// utils
import capitalize from '../../../../../design-system/utils/capitalize'
import randomNumber from '../../../../../shared/utils/randomNumber';
//SDK
import { GlobalService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const requestType = [
    {id: 1, label: 'Para Alumno', icon:''},
    {id: 2, label: 'Para Colaborador', icon:''},
]

const STEP_TYPE_FORM_ID = 1;
const REQUEST_STEP_TYPE_EMAIL = 3;

function getName(str){
    const strNormalize = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const strSpecialCharacters = strNormalize.replace(/[^\w\s]/gi, '');
    const strName = `${strSpecialCharacters.toLowerCase().replace(/ /g, '-')}-${randomNumber(100, 300)}`;

    return strName;
}

function getFillValue( options ) {
    return options.map( option => ({ optionName: option.name }));
}

function getDefaultValue(type, options){
    let value; 

    switch (type) {
        case 'text':
            value = '';
            break;
        case 'textarea':
            value = '';
            break;
        case 'select':
            value = '';
            break;
        case 'datepicker':
            value = null;
            break;
        case 'file':
            value = '';
            break;
        case 'checkboxes':
            value = [];
            break;
        case 'files':
            value = getFillValue(options);
            break;
        default:
            break;
    }

    return value;
}

function RequestDate(dateString){

    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var date = dateString.dateString.toLocaleDateString("es-ES", options);
    return (
        <Box display="flex" alignItems="center" marginLeft="auto">
            <Typography gutterBottom variant="h6" component="div">
                Fecha de Creacion: {date}
            </Typography>
        </Box>   
    )
}

class StepConfig extends Component {

    constructor(props){
        super();

        this.state = {
            typeId:1,
            path: '',
            ous: [],
            openDynamicForm: null,
            loading: false,
            options: [],
            criteria: "",
            stepTypesList: [],
            operatingUnits: [],
            tab: ['Configuracion de paso'],
            form:{
                title: '',
                instruction: '',
                emailmsg: '',
                type: '',
                fields: [],
                authorizers: [],
                assignee: []
            },
            formField:{
                type: '',
                label: '',
                col: 12,
                required: false
            },
            formValue:{},
            errors:{},
            openAddField: false,
            breadcrumbItems: [
                {to: '/gestion-de-servicios', label: 'Trámites'},
                {to: routerlinks.GESTION_SERVICIOS_CREAR, label: 'Gestion de Servicios'},
                {label: 'Servicio'},
            ],
            assignToApplicant: false,
            dateString: new Date()
        }
    }

    async componentDidMount(){
        const typeId = JSON.parse(localStorage.getItem('newRequestType')) || 1;
        const { match: {params: {id, stepId}, path }, newRequest: {form:requestDetail, workflow} } = this.props;
        const edit = path.includes("editar");
        const breadcrumbItems = [...this.state.breadcrumbItems];
        let parentPath = routerlinks.GESTION_SERVICIOS_CREAR; 

        breadcrumbItems[2].label = `Nuevo Servicio`;
        if(edit){
            parentPath = generatePath(routerlinks.GESTION_SERVICIOS_EDITAR, {id});
            breadcrumbItems[1].to = parentPath;
            breadcrumbItems[2].label = `Servicio ${requestType.find(it => it.id === typeId).label}`;
        }
        
        let step = {
            title: '',
            instruction: '',
            emailmsg: '',
            type: '',
            fields: [],
            authorizers: [],
            assignee: []
        };

        let formValue = {};
        let assignToApplicant = false;

        if(stepId && workflow.length){
            step = workflow.find(it => it.id === stepId);
            assignToApplicant = step.assignToApplicant;

            for(const f of step.fields){
                formValue[f.name] = f.value;
            }
        }

        const stepTypesList = await GlobalServiceSDK.getCatalog('RequestSetpTypesCat', {display_in_request: 1});
        const operatingUnits = await GlobalServiceSDK.getOperatingUnits();
        stepTypesList.forEach(it => it.label = it.description);

        this.setState({
            typeId,
            path: parentPath,
            stepTypesList,
            operatingUnits,
            ous: requestDetail.ou,
            openDynamicForm: step.type === STEP_TYPE_FORM_ID,
            form:{
                ...step
            },
            breadcrumbItems,
            assignToApplicant,
            formValue
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.userID !== prevProps.userID) {
          this.fetchData(this.props.userID);
        }
    }

    validate = () => {
        const _errors = {};
        const requiredFields = ['title', 'instruction', 'type'];
        const {form, ous, assignToApplicant, operatingUnits} = this.state;

        if(form.type === REQUEST_STEP_TYPE_EMAIL){
            requiredFields.splice(1, 1);
            requiredFields.push('emailmsg')
        }

        for(const field of requiredFields){
            if(!form[field]){
                _errors[field] = 'Campo Requerido';
            }
        }

        if(form.type === STEP_TYPE_FORM_ID && !form.fields.length){
            _errors.fields = 'Debe agregar al menos un campo';
        }

        if (!assignToApplicant){
            for(const ou of ous){
                if(!form.authorizers.some(it => it.parent === ou.id)){
                    _errors['authorizers'] = 'Debe agregar al menos 1 autorizador para cada unidad operativa';
                    break;
                }else{
                    const valido = this.validateCampus(ou, form.authorizers, operatingUnits)
                    if (valido == 0){
                        _errors['authorizers'] = 'Faltan campus de asignar authorizador en la unidad operativa: ' + ou.id;
                        break;
                    }
                }
            }
        }
        

        this.setState({errors: _errors});
 
        return Boolean(Object.keys(_errors).length === 0);
    }

    // funcion validacion campus
    validateCampus = (ou, authorizers, operatingUnits) => {
        let val = 0;
        const auts = authorizers.filter(it => it.parent === ou.id );
        if (!auts.some(t => t.scope_type_id === 1)){
            const _ousF = operatingUnits.filter(a => a.id === ou.id);
            for(const campus of _ousF[0].campus){
                for(const aut of auts){
                    val = 0;
                    if (aut.scope_ids.some(c => c.id === campus.id)){
                        val = 1;
                        break;
                    }else{
                    }
                }
                if (val == 0){
                    return val;
                }
            }
        }else {
            val = 1
            return val;
        }

        return val;
    }

    handleSubmit = () => {
        if(this.validate()){
            const {form, ous, assignToApplicant} = this.state;
            form.title = capitalize(form.title);
            form.assignee = ous.map(ou => ({...ou, full_name: ou.label}));
            form.assignToApplicant = assignToApplicant;

            if(!form.id){
                form.id = (this.props.newRequest.workflow.length + 1).toString();
                this.props.addStepToRequest(form);
            }else{
                this.props.updateStep(form);
            }

            this.props.history.push(this.state.path);
        }
    }

    handleCancel = () => {
        this.props.history.push(this.state.path);
    }

    handleChange = e => {
        const {name, value} = e.target;
        const errors = {...this.state.errors};

        let {openDynamicForm} = this.state;

        if(value){
            delete errors[name];
        }

        if(name === 'type'){
            openDynamicForm = value === STEP_TYPE_FORM_ID;
        }

        this.setState({
            form:{
                ...this.state.form,
                [name]: value,
                fields: openDynamicForm ? this.state.form.fields : []
            },
            errors,
            openDynamicForm
        });
    };

    hanldeAddField = (field) => {
        const fields = [...this.state.form.fields];
        const formValue = {...this.state.formValue};
        const index = fields.findIndex(it => it.id === field.id);

        if(index >= 0){
            let oldName = field.name;

            field.value = getDefaultValue( field.type, field.options );
            field.name = getName(field.label);
            fields[index] = {...field};
            
            delete formValue[oldName];
            formValue[field.name] = field.value;
        }else{
            field.name = getName(field.label);
            field.value = getDefaultValue( field.type, field.options );
            field.disabled = true;
            
            fields.push(field);   

            formValue[field.name] = field.value;
        }

        this.setState({
            formField: {
                type: '',
                label: '',
                col: 12,
                required: false
            },
            form:{
                ...this.state.form,
                fields
            },
            openAddField: false,
            formValue
        });
    };

    handleClickDeleteInputForm = input => {
        const fields = [...this.state.form.fields];
        removeObjectFromArray(fields, 'id', input.id);
        this.setState({
            form:{
                ...this.state.form,
                fields
            }
        });
    }

    handleClickEditInputForm = input => {
        this.setState({openAddField: true, formField:input})
    }

    hanldeInputChange = (value) => {
        this.setState({criteria: value})
    }

    hanldeChangeAuthorizers = (value) => {
        this.handleChange({target:{name: 'authorizers', value}})
    }

    hanldeChangeAssignToApplicant = (event) => {
        this.setState({assignToApplicant: event.target.checked})
        this.handleChange({target:{name: 'authorizers', value: []}})
    }

    handleDragSteps = (fields) => {
        const formValue = {...this.state.formValue};
        // this.state.form.fields = fields;

        this.setState({
            form:{
                ...this.state.form,
                fields
            },
            formValue
        });
    }

    render() {
        const {typeId, loading, stepTypesList, formField, form, openDynamicForm, openAddField, errors, formValue, ous, breadcrumbItems, assignToApplicant, tab, dateString} = this.state;
        return (
            <div>
                <Breadcrumb items={breadcrumbItems}/>

                <DividerDetail 
                    titleComponent={
                        <Breadcrumb 
                            items={[
                                {label: `Servicio ${requestType.find(it => it.id === typeId).label}`},
                                {label: 'Agregar paso'},
                                {label: 'Configuración de paso'},
                            ]} 
                            variant="h5" 
                            className="mb-0"
                        /> 
                    }
                    mb={3} 
                >
                    <RequestDate dateString={dateString}/>
                </DividerDetail>

                <Grid container spacing={3}>
                    <Grid container item xs={12}>
                        <LoaderContent loading={loading}>
                            <Card>
                                <CardContent>
                                    {/* <Typography variant="h4" gutterBottom className="mb-4" style={{color: '#5CCAE8'}}>Alta de provedor</Typography> */}
                                    <Grid container spacing={3}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                    required
                                                    margin="dense"
                                                    variant="outlined"
                                                    label="Titulo del paso"
                                                    fullWidth
                                                    name="title"
                                                    onChange={this.handleChange}
                                                    error={Boolean(errors.title)}
                                                    helperText={errors.title}
                                                    value={form.title || ''}
                                                />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Select
                                                        required
                                                        items={stepTypesList}
                                                        label="Tipo de paso"
                                                        name="type"
                                                        value={form.type}
                                                        onChange={this.handleChange}
                                                        error={Boolean(errors.type)}
                                                        helperText={errors.type}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid item xs={12}>
                                                    {
                                                        form.type === REQUEST_STEP_TYPE_EMAIL
                                                        ?
                                                            <TextField
                                                                variant="outlined"
                                                                label="Mensaje del correo"
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                name="emailmsg"
                                                                required
                                                                onChange={this.handleChange}
                                                                error={Boolean(errors.emailmsg)}
                                                                helperText={errors.emailmsg}
                                                                value={form.emailmsg}
                                                            />
                                                        :
                                                            <TextField
                                                                variant="outlined"
                                                                label="Instrucciones"
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                name="instruction"
                                                                required
                                                                onChange={this.handleChange}
                                                                error={Boolean(errors.instruction)}
                                                                helperText={errors.instruction}
                                                                value={form.instruction}
                                                            />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <br/>
                                    <Typography variant="subtitle2" className="mb-4">Autorizadores</Typography>

                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={assignToApplicant}
                                                    onChange={this.hanldeChangeAssignToApplicant}
                                                    color="primary"
                                                    name="assignToApplicant"
                                                />
                                            }
                                            label="Asignar al solicitante"
                                        />
                                    </Box>

                                    <Authorizers ous={ous} authorizers={form.authorizers} onChange={this.hanldeChangeAuthorizers} disabled={assignToApplicant}/>
                                    {errors.authorizers && <TextError text={errors.authorizers} />}

                                    {
                                        openDynamicForm &&
                                        <Grid container item xs={12}>
                                            <Typography variant="subtitle2" className="mb-4">Formulario</Typography>

                                            <Grid container>
                                                <Grid item xs={12} md={8}>
                                                    <Box style={{border:'1px solid #B8BCC0', borderRadius: 16}} p={2} display="flex" justifyContent="center">
                                                        <Form fields={form.fields} onDragSteps={this.handleDragSteps} values={formValue} enableActions={true} onClickDelete={this.handleClickDeleteInputForm} onClickEdit={this.handleClickEditInputForm}/>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <AddField onSave={this.hanldeAddField} open={openAddField} form={formField}/>
                                            {errors.fields && !form.fields.length && <TextError text={errors.fields} />}
                                            
                                        </Grid>
                                    }

                                    
                                </CardContent>
                            </Card> 
                        </LoaderContent>
                    </Grid>
                    
                    <Grid container item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            <Button color="primary" variant="outlined" onClick={this.handleCancel} className="mr-2">Cancelar</Button>
                            <Button color="primary" variant="contained" onClick={this.handleSubmit} disabled={!this.props.newRequest.form.ou.length}>Guardar</Button>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (reducers) => ({newRequest: reducers.requestWorkflowReducer});

export default connect(mapStateToProps, {...requestWorkflowActions})(StepConfig);