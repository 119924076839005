export const generateSteps = (anonymousStepts = true, membership = false) => {
    if(anonymousStepts && membership) return { 2: true, 3: false, 4: false};
    if((anonymousStepts && !membership )|| (!anonymousStepts && membership)) return { 2: true, 3: false};
    if(!anonymousStepts && !membership) return { 2: true };
}

export const assignLastStep = (currentStepts) => {
    return Object.keys(currentStepts)[Object.keys(currentStepts).length - 1]
}

export const checkoutSteps = {
    REGISTER: 1,
    MEMBERSHIP_PERIODS: 2,
    PAYMENT_METHODS: 3,
}

export const isValidInitialStep = (step) => {
    return Object.values(checkoutSteps).includes(step);
};

export const generateCheckoutWorkFlow = (IS_ANONYMOUS_SESSION, IS_MEMBERSHIP_PURCHASE, IS_REFERRED_PURCHASE, IS_PAYMENT_LINK, INITIAL_STEP) => {
    if(INITIAL_STEP && isValidInitialStep(INITIAL_STEP)) return INITIAL_STEP;

    if(IS_PAYMENT_LINK) return checkoutSteps.PAYMENT_METHODS
    
    if(IS_REFERRED_PURCHASE) return checkoutSteps.PAYMENT_METHODS

    if(!IS_MEMBERSHIP_PURCHASE && !IS_ANONYMOUS_SESSION) return checkoutSteps.PAYMENT_METHODS

    if(!IS_ANONYMOUS_SESSION && IS_MEMBERSHIP_PURCHASE) return checkoutSteps.MEMBERSHIP_PERIODS

    return checkoutSteps.REGISTER
}
