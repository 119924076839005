export const LOGO_SOURCE = {
    BANNER: 1,
    ADVENIO: 3,
    TALISIS: 7,
    UERRE: 8,
    UNID: 9,
    HH: 10,
    IESALUD: 11,
    EASYGENERATOR: 20,
}

const logo_images = ({
    [LOGO_SOURCE.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/advenio.svg",
    4: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/l%26d.svg",
    5: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/coursera.svg",
    6: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/learning.svg",
    [LOGO_SOURCE.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis.svg",
    [LOGO_SOURCE.UERRE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/uerre.svg",
    [LOGO_SOURCE.UNID]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/unid.svg",
    [LOGO_SOURCE.HH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/harmonhall.svg",
    [LOGO_SOURCE.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/isesalud.svg",
    12: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/google_skillshop.svg",
    13: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/crehana.svg",
    14: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/hubspot.svg",
    15: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/ie_university.svg",
    16: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/aws.svg",
    17: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/Symplicity.svg",
    18: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/NEO.svg",
    21: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis_academy.svg"
});

const logosResponsive = ({
    [LOGO_SOURCE.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis_icon.svg",
})

export const LogoIesaudImgRow = "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/iesaludRow.svg";

const logo_images_cards_sizes_content= ({
    5: "98px",
    6: "102px",
    7: "82px",
    8: "82px",
    9: "64.5px",
   10: "110px",
   11: "64.5px",
   12: "166px",
   13: "133.5px",
   14: "90.5px",
   15: "76px",
   16: "120px",
});

const logo_images_cards_sizes_media = ({
    5: "88px",
    6: "92px",
    7: "72px",
    8: "72px",
    9: "54.5px",
   10: "100px",
   11: "54.5px",
   12: "156px",
   13: "123.5px",
   14: "80.5px",
   15: "81px",
   16: "110px",
});


export const getLogoCard = (source_id, responsive = false) => {
    if(responsive){
        return logosResponsive[source_id];
    }

    return logo_images[source_id];
}

export const getLogoCardWithSourceAndCompany = (source_id, company_id, responsive = false) => {
    let logoId = source_id;
    
    if (!!company_id && (source_id === LOGO_SOURCE.BANNER || source_id === LOGO_SOURCE.EASYGENERATOR)) {
        if (company_id === "UERRE"){
            logoId = LOGO_SOURCE.UERRE;
        } 
        if (company_id === "UNID"){
            logoId = LOGO_SOURCE.UNID;
        } 
        if (company_id === "HH"){
            logoId = LOGO_SOURCE.HH;
        }
    } 
    
    if(responsive){
        return logosResponsive[logoId];
    }

    return logo_images[logoId];
}

export const getLogoCardSizeMedia = (source_id) => {
    return logo_images_cards_sizes_media[source_id];
}

export const getLogoCardSizeContent = (source_id) => {
    return logo_images_cards_sizes_content[source_id];
}