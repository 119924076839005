import React from 'react';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import ReadMoreLine from '../../../../shared/components/ReadMore/ReadMoreLine';

import { v4 as uuid } from 'uuid';

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 417,
    },
    cardContent:{
        padding: props => props.isResponsive ? "16px" : "32px",
        "&:last-child" : {
            paddingBottom: props => props.isResponsive ? "16px" : "32px",
        }
    },
    listItem:{
        '& .MuiListItemIcon-root':{
            minWidth:8
        },
    }
});

const requirementsTypes = [
    {icon: 'ri-sun-cloudy-fill', text: '', requirementTypeId: 2},
    {icon: 'ri-surgical-mask-fill', text: '', requirementTypeId: 1},
]

const EventDetail = ({data, isResponsive}) => {
    const classes = useStyles({isResponsive});
    const [list, setList] = React.useState([]);

    React.useEffect(()=> {
        if(data){
            const reqList = [
                {icon: 'ri-star-line', text: `${data.event.num_people} Espacios`},
                {icon: 'ri-user-3-line', text: `Evento ${data.event.public_type_name}`},
            ]

            for(const r of data.requirements){
                const reqitem = requirementsTypes.find(it => it.requirementTypeId === r.requirement_type_id);
                reqitem.text = r.description;
                reqList.push(reqitem)
            }

            setList(reqList)
        }
    }, [data])
    
    return (
        <Card variant="outlined" className="w-100">
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h3">
                    Detalle
                </Typography>
                <Box>
                    <List dense={true}>
                    {list.map( it =>
                        <ListItem className={classes.listItem} disableGutters key={uuid()}>
                            <ListItemIcon>
                                <i className={clsx(it.icon, 'ri-map-pin-2-fill mr-2 text-violet-300 font-size-24')}></i>
                            </ListItemIcon>
                            <ListItemText
                                primary={it.text}
                                primaryTypographyProps={{
                                    variant: 'body1'
                                }}
                            />
                        </ListItem>,
                    )}
                    </List>
                </Box>
                <Box>
                    <ReadMoreLine 
                        line={3}
                        text={data?.event?.description}/>
                </Box>
            </CardContent>
        </Card>
    );
};

export default EventDetail;