import fileDetailStyle from './fileDetail';
import inputSearchStyle from './inputSearch';
import loaderStyle from './loader';
import table from './table'
import tableFilter from './tableFilter'

export default {
    fileDetailStyle,
    inputSearchStyle,
    loaderStyle,
    table,
    tableFilter,
}