import React, { Fragment } from 'react';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Select from '../../design-system/components/Form/Select/Select';

import clsx from 'clsx';

const variants = [
    {id:1,type:'h1', label:"H1", desktop: '40px', mobile:'32px'},
    {id:2,type:'h2', label:"H2", desktop: '32px', mobile:'24px'},
    {id:3,type:'h3', label:"H3", desktop: '24px', mobile:'20px'},
    {id:4,type:'h4', label:"H4", desktop: '20px', mobile:'16px'},
    {id:5,type:'h5', label:"H5", desktop: '16px', mobile:'12px'},
    {id:6,type:'h6', label:"H6", desktop: '12px', mobile:'10px'},
    {id:7,type:'subtitle1', label:"Subheading 1", desktop: '20px', mobile:'16px'},
    {id:8,type:'subtitle2', label:"Subheading 2", desktop: '16px', mobile:'12px'},
    {id:9,type:1, label:"Subheading 3", class:'variant-subheading-3', desktop: '12px', mobile:'10px'},
    {id:10,type:2, label:"Subheading 4", class:'variant-subheading-4', desktop: '10px'},
    {id:11,type:'body1', label:"Body Text Large", desktop: '16px'},
    {id:16,type:'body1', label:"Body Text Large SemiBold", class:'semi-bold', desktop: '16px'},
    {id:12,type:'body2', label:"Body Text Small", desktop: '14px'},
    {id:17,type:'body2', label:"Body Text Small SemiBold", class:'semi-bold', desktop: '14px'},
    {id:13,type:3, label:"Body Text XSmall", class:'variant-xsmall', desktop: '12px'},
    {id:18,type:4, label:"Body Text XSmall SemiBold", class:'variant-xsmall semi-bold', desktop: '12px'},
    {id:14,type:'caption', label:"Caption Text Large", desktop: '12px'},
    {id:19,type:'caption', label:"Caption Text Large SemiBold", class:'semi-bold', desktop: '12px'},
    {id:20,type:'caption', label:"Caption Text Small", class:'variant-caption-sm', desktop: '10px'},
    {id:21,type:'caption', label:"Caption Text Small SemiBold", class:'variant-caption-sm semi-bold', desktop: '10px'},
    {id:15,type:'overline', label:"Overline/Large SemiBold", class:'semi-bold', desktop: '12px'},
    {id:22,type:'overline', label:"Overline/Small SemiBold", class:'variant-overline-sm semi-bold', desktop: '10px'},
    {id:23,type:'overline', label:"Overline/Xsmall SemiBold", class:'variant-overline-xs semi-bold', desktop: '8px'},
]

const TypographyComponent = (props) => {
    const [value, setValue] = React.useState("")

    const handleChange = item => {
        setValue(item.target.value)
    }

    return (
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Box my={3}>
                        <Select items={variants} label="Etiqueta figma" onChange={handleChange}/>
                    </Box>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={2}>
                        <Typography className="text-fill-stroke-500" variant="h5">Variant</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="text-fill-stroke-500" variant="h5">Figma</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="text-fill-stroke-500" variant="h5">Clase</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className="text-fill-stroke-500" variant="h5">Desktop</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className="text-fill-stroke-500" variant="h5">Mobile</Typography>
                    </Grid>
                </Grid>
                {variants.map((variant, i) => {
                    return(
                        <Grid item xs={12} key={i} container className={variant.id === value && "bg-violet-100"}>
                            <Grid item xs={2}>
                                <Typography className="text-fill-stroke-500">{!Number.isInteger(variant.type) && variant.type}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography 
                                    variant={!Number.isInteger(variant.type) ? variant.type : 'body1'} 
                                    className={`${variant.class} text-fill-stroke-500`}>
                                    {variant.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className="text-fill-stroke-500">{variant.class}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className="text-fill-stroke-500">{variant.desktop}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className="text-fill-stroke-500">{variant.mobile}</Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
  );
};

export default TypographyComponent;
