import React from 'react';

import Button from '@material-ui/core/Button';

import { logOut } from '../../../../services/LogoutService'
import { useMsal } from "@azure/msal-react";
import { clearUserData, eventTracker } from '../../../../shared/components/EventTracker/EventTracker';
import Cookies from 'js-cookie';

const LogOut = ({children, ...rest}) => {
    const { instance } = useMsal();

    const singleSignOut = e => {
        if ((window.location.host).includes(".talisis.com")) {
            Cookies.remove("session", { domain: ".talisis.com"} );
            Cookies.remove("LOGIN_PLATFORM_TALISIS", { domain: ".talisis.com" });
            Cookies.remove("LOGIN_ADMIN_TALISIS", { domain: ".talisis.com" });
        }
        else {
            Cookies.remove("session");
            Cookies.remove("LOGIN_PLATFORM_TALISIS");
            Cookies.remove("LOGIN_ADMIN_TALISIS");
        }
        
        localStorage.removeItem('refferalPlanBanner');
        console.log('cerrando sesión')
        e.preventDefault();
        // Remover cookies checkout
        Cookies.remove("checkout");
        Cookies.remove("cart");
        Cookies.remove("checkout_sku");
        // const instanceAccount = instance.getAllAccounts()[0];
        const instanceAccount = instance.getActiveAccount()
        const logoutRequest = {
            account: instanceAccount,
            onRedirectNavigate: false
            // postLogoutRedirectUri: `${window.location.origin}/`,
        };
        // logOut();
        eventTracker('logout', {}, ['mixpanel'])
        clearUserData();
        instance.logoutRedirect(logoutRequest);
    }
    
    return (
        <Button onClick={singleSignOut} {...rest}>
            {children}
        </Button>
    );
};

export default LogOut;