import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMsal } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import animationData from '../../../../assets/images/animations/authRedirect.json'
import AuthScreen from '../SplitLogin/AuthScreen/AuthScreen';
import { clearUserData } from '../../../../shared/components/EventTracker/EventTracker';

const useStyles = makeStyles((theme) => ({
    animatedLoader: {
        width: '361px !important',
        // height: '342px !important'
    },
    img: {
        position: 'absolute',
        margin: 'auto',
        left: 0,
        right: 0,
        top: 90,
        textAlign: 'center',
    },
}));

const Auth = props => {
    const classes = useStyles();
    const { instance } = useMsal();
    const [loginError, setLoginError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const onLoginError = errData => {
        console.log(errData)
        Sentry.captureEvent({
            name: "LoginFailUserNotFoundDB",
            message: `LoginFailUserNotFoundDB`,
            user: { email: errData.email },
        });
        setErrorMessage(errData.message);
        setLoginError(true);
    };
    const onOpenAutoSignup = false;
    const loading = true;
    const onLoading= () => {

    }
    const signupData = {

    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            className: classes.animatedLoader
        }
    };
    const handleBackToLogin = () => {
        const instanceAccount = instance.getActiveAccount()
        const logoutRequest = {
            account: instanceAccount,
            onRedirectNavigate: false,
            postLogoutRedirectUri: `${window.location.origin}/login`,
        };
        // logOut();
        clearUserData();
        instance.logoutRedirect(logoutRequest);
    }
    return <>
        <Box mt={14} textAlign={'center'}>        
            { !loginError &&
                <Lottie options={defaultOptions}
                    width={445}
                    height={342}/>
            }
            {
                loginError && 
                <>
                    <Box display="inline-flex">
                        <img className={classes.img} src='/images/theme/surprised.svg'/>
                        <img src='/images/theme/ups.svg'/>
                    </Box>
                    <Box mt={8}>
                        <Typography>{errorMessage}</Typography>
                        <Button className='mt-4' variant='contained' color='primary' onClick={handleBackToLogin}>Intentar de nuevo</Button>
                    </Box>
                </>
            }
        </Box>
        
        <AuthScreen onLoginError={onLoginError} handleLoader={onLoading} loading={loading} signupData={signupData} onOpenAutoSignup={onOpenAutoSignup}></AuthScreen>
    </>;
}

export default Auth;