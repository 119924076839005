import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//@material-ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//Components 
import MembershipsContainer from './MembershipsContainerCard/MembershipsContainerCard';
import MembershipFees from './MembershipFees/MembershipFees';
//Style 
import useStyles from '../../../assets/styles/jss/pages/memberships/versionSKU/membershipsStyle';
//Service
import { MembershipService } from '@sdk-point/talisis';
//Share
import { log } from '../../../shared/utils/console.js'

const TITLE_WIDTH_PROGRAM = 'Elige el mejor plan para tí';
const TITLE = 'Planes de membresías';

const FEE_SLUG_DEFAULT = 'ANU';
const FEE_SLUG_BASE = 'TRIM';

const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)

const Memberships = () => {
    const classes = useStyles();
    const { user } = useSelector((state) => state.userReducer);
    const queryParams = new URLSearchParams(window.location.search);
    const sku = queryParams.get('sku');
    const [title, setTitle] = useState({ main: sku ? TITLE_WIDTH_PROGRAM : TITLE, sub: 'Ahorra hasta un 25% con nuestros planes de pago anticipado' });
    const [membershipFees, setMembershipFees] = useState([])
    const [feeSlugSelected, setFeeSlugSelected] = useState(FEE_SLUG_DEFAULT);
    const [feeSlugBase, setFeeSlugBase] = useState(FEE_SLUG_BASE);
    const [termMonths, setTermMonths] = useState(12)

    React.useEffect(() => {
        getMembershipsPlans();
    }, [])

    const getMembershipsPlans = async () => {
        try {                                                                        //months 3,6,12
            const responseGetMembershipFees = await membershipService.getMembershipFees([3, 6, 12], user?.person_id);
            setMembershipFees(responseGetMembershipFees);
        } catch (error) {
            log(error);
        }
    }

    return (
        <Box style={{ paddingRight: '0px', paddingLeft: '0px' }}>
            <Box className={classes.titleBox}>
                <Typography variant='h1' >
                    {title.main}
                </Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} md={7} className={classes.subTitleGrid}>
                    <Typography variant="h3" className={classes.membershipsLandingSubTitle}>
                        {title.sub}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <MembershipFees onClick={(slug) => setFeeSlugSelected(slug)} membershipTerm={3} membershipPlansD={membershipFees} displaySavedPercentage={false} setTermMonths={setTermMonths} termMonths={termMonths} />
                </Grid>
            </Grid>
            <MembershipsContainer sku={sku} feeSlugSelected={feeSlugSelected} feeSlugBase={feeSlugBase} termMonths={termMonths} />
        </Box>
    )
}

export default Memberships;