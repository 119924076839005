import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import CardNew from '../../../ConektaPayments/Card/CardNew'
import Transfer from '../../../ConektaPayments/Transfer/TransferNew';
import Cash from '../../../ConektaPayments/Cash/CashNew';

import { getCheckoutCookie } from '../../../../../../shared/utils/checkoutSession-legacy.js';

const PaymentComponents = (props) => {

    const { paymentOption, order, user, orderReferences, nextPaymentDate, getCardForm, getAcceptedSaveCard } = props;
    const checkoutCookie = getCheckoutCookie();

    const [loadingCardPayment,setLoadingCardPayment] = useState(false);
    const [paymentsUser, setPaymentsUser] = useState(user || checkoutCookie?.personInfo)

    return (
        <Grid container>
            {/* {
                paymentOption === "card" &&
                <Grid item xs={12}>
                    <CardNew getCardForm={getCardForm} getAcceptedSaveCard={getAcceptedSaveCard} nextPaymentDate={nextPaymentDate} order={order} user={paymentsUser} loadingConekta={setLoadingCardPayment} />
                </Grid>
            } */}
            {
                paymentOption === "spei" &&
                <Grid item xs={12}>
                    <Transfer nextPaymentDate={nextPaymentDate} user={paymentsUser} references={orderReferences} order={order} />
                </Grid>
            }
            {
                paymentOption === "cash" &&
                <Grid item xs={12}>
                    <Cash nextPaymentDate={nextPaymentDate} user={paymentsUser} references={orderReferences} order={order} /> 
                </Grid>
            }
        </Grid>
    )
}

export default PaymentComponents
