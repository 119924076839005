export const benefitsList = {
    'Curso de Inglés':[
        {
            label: 'Disponibilidad 24/7',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: '21 Unidades Harmon Hall',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: 'Más de 50 Unidades de Interés',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: 'Recursos de práctica',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: 'Guía gramatical',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: 'Medición de avances',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: 'Exámenes continuos',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: 'App Móvil',
            standard: true,
            plus: true,
            elite: true
        },
    ],
    'Clases Privadas':[
        {
            label: 'Clase 1 a 1',
            standard: false,
            plus: true,
            elite: true
        },
        {
            label: 'Introducción Personalizada',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: 'Ses. personalizadas al mes',
            standard: false,
            plus: 6,
            elite: 12
        },
        {
            label: 'Ses. personalizadas al año',
            standard: false,
            plus: 72,
            elite: 156
        },
    ],
    'Salas de conversación':[
        {
            label: 'Sesiones grupales',
            standard: true,
            plus: true,
            elite: true
        },
        {
            label: 'Sesiones ilimitadas',
            standard: true,
            plus: true,
            elite: true
        }
    ]
}

export const learnListHH =[
    'Cobertura nacional de más de 100 institutos con 55 años de experiencia que avalan nuestros más de 2.000.000 de egresados.',
    'Cursos con certificaciones internacionales.',
    'Docentes certificados en las mejores prácticas de enseñanzas.',
    'Pertenece a la comunidad de aprendizaje más grande de México.',
    'Cobertura nacional de más de 100 institutos con 55 años de experiencia que avalan nuestros más de 2.000.000 de egresados.'
]