import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import Iframe from 'react-iframe';

// material
import { Box, Button, Card, CardContent, Chip, Grid, Typography } from '@material-ui/core';

// shared components
import Table from '../../../../../shared/components/Table/Table';

// components
import PaymentMethodsList from '../PaymentMethodsList/PaymentMethodsList';
import PaymentReceipt from '../PaymentReceipt/PaymentReceipt';

// style
import useStyles from '../../../../../assets/styles/jss/pages/account/billing/paymentDetailsStyle';

// utils
import moment from 'moment/moment';

const PAYMENT_METHODS_COMP = 'paymentMethods';
const CASH_AND_CHECK_COMP = 'cashAndCheckMethod';
const TRANSFER_COMP = 'transferMethod';
const CARD_COMP = 'cardMethod';

const MuiChip =  styled(Chip)`
    ${({ theme, valid }) => `
        border: 2px solid;
        border-color: ${ valid === 'true'? theme.palette.colors.warning[200] : theme.palette.colors.error[200]};
        background-color: ${ valid === 'true' ? theme.palette.colors.warning[500] : theme.palette.colors.error[400]};
        color: ${theme.palette.colors.white};
        border-radius: 16px;
    `}
`;

const compareDateExpired = (date) => {
    const dateNowValue = moment().startOf('day').valueOf();
    const dateTimeValue = moment(date).valueOf();
    return dateTimeValue < dateNowValue;
};

const currencyFormatFunc = (row, value, index) => {
    return (
        <Box>
            <Box><Typography variant='body2' className={`text-violet-300 semi-bold`}>MXN <CurrencyFormat value={value} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography></Box>
        </Box>
    );
};

const keyAndDescritionConcept = (row, value, index) => {
    return (
        <Box>
            <Box display={'flex'} justifyContent={'start'}><Typography variant="caption" className={`text-gray-500 caption-small`}>CLAVE: {row['conceptKey']}</Typography></Box>
            <Box display={'flex'} justifyContent={'start'}><Typography variant='body2' className={`text-gray-100 semi-bold`}>{row['concept']}</Typography></Box>
        </Box>
    );
};

const columnsOuPendingData = [
    {
        name: 'colPeriod',
        align: 'center',
        minWidth: 0,
        label: 'Periodo',
    },
    {
        name: 'colConcept',
        align: 'left',
        minWidth: 0,
        label: 'Concepto',
        format: keyAndDescritionConcept
    },
    {
        name: 'colEndDate',
        align: 'left',
        minWidth: 0,
        label: 'Fecha limite de pago',
    },
    {
        name: 'colAmount',
        align: 'left',
        minWidth: 0,
        label: 'Monto MXN',
        format: currencyFormatFunc
    },
];

const PaymentDetails = ({onReturnComponent, pendingDataTable, pendingDataSelected, ouPendingData, user, refData}) => {
    const classes = useStyles();

    const [activeComponent, setActiveComponent] = React.useState(PAYMENT_METHODS_COMP);
    const [pendingData, setPendingData] = React.useState([]);
    const [totalDebts, setTotalDebts] = React.useState(0);
    
    const getTotalDebts = async () => {
        const initialValue = 0;

        setTotalDebts(pendingDataTable.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.colAmount), initialValue));
    };
    
    const getPendingData = async () => {
        setPendingData(pendingDataTable.filter(data => !!pendingDataSelected.find( data2 => data2.id === data.id)));
    };
    
    const handleClickReturnButton = () => {
        onReturnComponent();
    };

    const handleClickSelectMethod = (componentName) => {
        setActiveComponent(componentName);
    };

    const handleClickReturnToPaymentMethods = () => {
        setActiveComponent(PAYMENT_METHODS_COMP)
    };

    useEffect(() => {
        getPendingData();
        getTotalDebts();
    }, [pendingDataTable, pendingDataSelected])
    

    return (
        <>
            <Typography variant="h4" className={`${classes.reviewPaymentLabel} text-violet-300`} >Revisa tu orden</Typography>
            <Typography variant="subtitle2" className={`${classes.paymentLabel} text-gray-100`}>Te presentamos un resumen para proceder al método de pago.</Typography>
            <Card className={`${classes.pendingListContainer}`}>
                <CardContent>   
                    <Table columns={columnsOuPendingData} rows={pendingData}/>

                    <Grid container spacing={3} className={`offset-margin-bottom-31 offset-margin-top-25`}>
                        <Grid item xs={12} md={7}>
                            <Box>
                                <i className={`${classes.iconInformation} ri-error-warning-line offset-margin-left-25 font-size-16`}></i>
                                <Typography variant='caption' className={`${classes.chargeLabel} offset-margin-left-12`}>El pago se verá reflejado en un lapso de 48 hrs hábiles una vez realizado.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className={classes.totalCotainer}>
                                <Box className={classes.totalLabel}>
                                    <Typography variant='h5' className={`${classes.debtsTotalLabel} offset-margin-left-12`}>Saldo a pagar:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                    <Typography variant='h5' className={`${classes.debtsTotalAmountLabel} offset-margin-left-12`}>MXN <CurrencyFormat value={totalDebts} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography>
                                </Box>
                                <Box sx={{ width: "10%" }}>
                                    <i className={`ri-error-warning-line font-size-16`}></i>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {activeComponent === PAYMENT_METHODS_COMP && <PaymentMethodsList onClickReturnButton={handleClickReturnButton} onClickSelectMethod={handleClickSelectMethod}/>}
            {activeComponent === CASH_AND_CHECK_COMP && <PaymentReceipt student={user} adeudos={ (!!ouPendingData.length ? ouPendingData[0].Adeudos_pendientes : []) } methods={refData} render={"ventanilla"} onClickReturnToPaymentMethods={handleClickReturnToPaymentMethods}/>}
            {activeComponent === TRANSFER_COMP && <PaymentReceipt student={user} adeudos={(!!ouPendingData.length ? ouPendingData[0].Adeudos_pendientes : [])} methods={refData} render={"transferencia"} onClickReturnToPaymentMethods={handleClickReturnToPaymentMethods}/>}
            {activeComponent === CARD_COMP && <PaymentReceipt student={user} adeudos={(!!ouPendingData.length ? ouPendingData[0].Adeudos_pendientes : [])} methods={refData} render={"tc"} onClickReturnToPaymentMethods={handleClickReturnToPaymentMethods}/>}
        </>
    );
};

PaymentDetails.propTypes = {
    onReturnComponent: PropTypes.func.isRequired,
    pendingDataTable: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        colPeriod: PropTypes.string.isRequired,
        colEndDate: PropTypes.string.isRequired,
        colAmount: PropTypes.string.isRequired,
        date: PropTypes.string,
        concept: PropTypes.string.isRequired,
        conceptKey: PropTypes.string.isRequired,
        checkboxDisabled: PropTypes.bool
    })),
    pendingDataSelected: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired
    })),
    ouPendingData: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object.isRequired,
    refData: PropTypes.object
};


export default PaymentDetails;