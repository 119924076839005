import React from "react";
import clsx from 'clsx';
//@Material-ui
import Card from "@material-ui/core/Card";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
//Style
import useStyles from '../../../../../assets/styles/jss/pages/memberships/versionSKU/MembershipsContainerCard/FreemiumCard';

const FreemiumCard = ({ membership, handleSignup}) => {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.card)}>
            <Typography variant='h3'>{membership?.name}</Typography>
            <Typography className={clsx([classes.cardTextDescription])}> {membership?.description}</Typography>
            {
                membership?.freeBenefits?.length > 0 &&
                    <Box className={classes.cardListBenefits}>
                        {
                            membership.freeBenefits.map((benefit, index) => {
                                return (
                                    <Grid  key={index} container className={classes.cardItemListBenefits}>
                                        <Grid item xs="auto">
                                            <Icon className={clsx(["ri-check-line", classes.ItemListBenefitsIcon])} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography className={classes.ItemListBenefitsDescription} >{benefit?.description}</Typography>
                                        </Grid>
                                    </Grid>
                            )
                        })
                    }
                </Box>
            }
            <Button variant="contained" style={{ width: '100%', marginTop: '20px' }} onClick={(e) => handleSignup(e)}>
                Iniciar gratis
            </Button>
        </Card>
    )
}

export default FreemiumCard;