import React, { useState, useEffect }  from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// shared - componentes
import Input from '../../shared/components/Form/Input'
import { eventData, eventTracker } from '../../shared/components/EventTracker/EventTracker';
// styles
import useStyles from '../../assets/styles/jss/layout/externalTestLayoutStyle'
import FormControlSelect from '../../shared/components/Form/FormControlSelect';
import LoaderContent from '../../shared/components/Loader/LoaderContent';
import isEmail from '../../shared/utils/isEmail';

//sdk
import { UserService, TestVocationalService, MIXPANEL_EVENT } from '@sdk-point/talisis';
import Toast from '../../shared/components/Toast/Toast';
import AutocompleteSelect from '../../shared/components/Form/AutoCompleteSelect';

import { Mixpanel } from '../../shared/components/Mixpanel/mixpanel';
import { log } from '../../shared/utils/console.js'


const typeCat = {
    bachillerato: {
        label: 'Bachillerato',
        id: '11780572-1b4d-ea11-a812-000d3a1ab78a'
    },
    licenciatura: {
        label: 'Licenciatura',
        queryCRM: "scolaris_programaacademicos?$filter=_scolaris_nivelacademico_value+eq+%27BB74B5E2-A59A-EA11-A811-000D3A4D53C3%27+or+_scolaris_nivelacademico_value+eq+%2763D3515C-194F-E811-811A-5065F38B51F1%27+or+_scolaris_nivelacademico_value+eq+%2777061D20-E11C-EB11-A813-000D3A8A7B51%27+or+_scolaris_nivelacademico_value+eq+%27BF74B5E2-A59A-EA11-A811-000D3A4D53C3%27",
        //id: '11780572-1b4d-ea11-a812-000d3a1ab78a'
        //el que viene en catalgos
        id: 'bb74b5e2-a59a-ea11-a811-000d3a4d53c3'
        //dev
        //id:'33fe1c0d-74e6-e711-8113-5065f38a9b71'
    },
    maestria: {
        label: 'Maestria',
        queryCRM: "scolaris_programaacademicos?$filter=_scolaris_nivelacademico_value+eq+%27BD74B5E2-A59A-EA11-A811-000D3A4D53C3%27+or+_scolaris_nivelacademico_value+eq+%27C9DEAD84-E11C-EB11-A813-000D3A8A7B51%27",
        //id: '8e780572-1b4d-ea11-a812-000d3a1ab78a'
        //el que viene el catalagos config
        id: 'bd74b5e2-a59a-ea11-a811-000d3a4d53c3'
    }
}

const optionsUniversity = [
    {
        'id': 723120000,
        'value': 723120000,
        'label': 'Primera Opción',
    },
    {
        'id': 723120001,
        'value': 723120001,
        'label': 'Segunda Opción'
    },
    {
        'id': 723120002,
        'value': 723120002,
        'label': 'Tercera Opción',
    },
    {
        'id': 723120003,
        'value': 723120003,
        'label': 'No somos Opción',
    }
]

const setFormatCellphone = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
};

const ContactProspectUnid = (props) => {
    const {match:{ params:{ type } }} = props;
    const ou = 'unid';

    const classes = useStyles();

    const [acceptTerms, setAcceptTerms] = React.useState(false)
    const [isValid, setValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const [isProgram, setIsProgram] = useState(true);
    const [toastProps, setToastProps] = React.useState({ message: "" });

    const [sedeList, setSedeList] = React.useState([])
    const [programList, setProgramList] = React.useState([]);
    const [accounstsList, setAccountsList] = React.useState([]);

    const [form, setForm] = useState({
        name: '',
        lastName: '',
        secondLastName: '',
        email: '',
        confirmEmail: '',
        phone: '',
        optUniversity: '',
        procedencia: '',
        program: type == 'bachillerato'? '5d6df162-f408-eb11-a812-000d3a546a72' : '',
        sede: '',
        ou: ou
    });

    useEffect(() => {
        setSelectValues();
    }, []);

    useEffect(() => {
        const isValid = validate();
        setValid(isValid);
    }, [form]);

    const emailMatch = () => (form.confirmEmail && form.email) && (form.email !== form.confirmEmail);
    const validEmail = () => form.email && !isEmail(form.email);

    const requiredFields = {
        name: true,
        lastName: true,
        secondLastName: true,
        email: true,
        confirmEmail: true,
        phone: true,
        optUniversity: true,
        sede: true,
    }

    const validate = () => {
        const _errors = {};
        const errorMsg = {
            email: {msg:'Correo Invalido', invalid: validEmail()}, 
            confirmEmail: {msg:'El correo no coincide', invalid: emailMatch()},
        }
        
        for (const field of Object.keys(form)) {
            if (requiredFields[field]) {
                if (errorMsg[field] && errorMsg[field].invalid) {
                    _errors[field] = errorMsg[field].msg;
                } else if (!form[field]) {
                    _errors[field] = 'Campo Requerido';
                }
            }
        }

        setErrors(_errors);

        return Boolean(Object.keys(_errors).length === 0);
    }

    const handleChange = (event) => {
        let {name, value} = event.target;
        event.persist();

        if(name === 'phone'){
            value = setFormatCellphone(value, form.phone);
        }

        if(name === 'sede'){
            setSelectv2(value);
        }
        
        setForm((form) => ({
            ...form,
            [name]: value
        }));
    };

    const handleChangeSearch = (event) => {
        let value = ''
        if (event == null){
            value = ''
        }else{
            value = event.value;
        }
        setForm((form) => ({
            ...form,
            procedencia: value
        }));
       
    };

    const handleFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    };

    const setSelectValues = async () => {
        log(type);
       
        const sedesPath = `scolaris_campuses?$select=scolaris_nombre,scolaris_clave&$filter=_scolaris_marca_value+eq+'11780572-1b4d-ea11-a812-000d3a1ab78a'+and+statecode+eq+0
        and+scolaris_clave+ne+'ONL'
        and+scolaris_clave+ne+'B2B'`

        let data  = await getCatalogORM(sedesPath)
        let infoData = []
        for(const i of data.data){
            let sede = {
                'id': i.scolaris_campusid,
                'value': i.scolaris_campusid,
                'label': i.scolaris_nombre,
            }
            infoData.push(sede);
        }
        setSedeList(infoData);
        await getDatAacconts();
    }

    const setSelectv2 = async (sedeId) => {
        log(sedeId);
        if (type == 'licenciatura' || type == 'maestria') {
            const nivelId = typeCat[type].id
            const programPath = `scolaris_configuraciondecatalogoses?$select=scolaris_Periodo&$expand=scolaris_Programaacademico($select=scolaris_nombre),scolaris_Nivelacademico($select=scolaris_clave)&$filter=_scolaris_nivelacademico_value+eq+'${nivelId}'+and+statecode+eq+0+and+_scolaris_campus_value+eq+'${sedeId}'`
            log(programPath)
            let data  = await getCatalogORM(programPath)
            log(data)
            let infoData = []
            for(const i of data.data){
                if (i.scolaris_Programaacademico){
                    let sede = {
                        'id': i.scolaris_Programaacademico.scolaris_programaacademicoid,
                        'value': i.scolaris_Programaacademico.scolaris_programaacademicoid,
                        'label': i.scolaris_Programaacademico.scolaris_nombre,
                    }
                    infoData.push(sede);
                }
            }
            setProgramList(infoData);
            setIsProgram(false);
        }

    }

    const getDatAacconts = async () => {
        let path = 'accounts?$select=name,scolaris_codigodelaorganizacion&$filter=scolaris_tipodeinstitucion%20eq%201'
        if (type == 'maestria') {
            path = 'accounts?$select=name,scolaris_codigodelaorganizacion&$filter=scolaris_tipodeinstitucion%20eq%203'
        }
       
        let d = await getCatalogORM(path);
        let infoData = []
        for(const i of d.data){
            let accounts = {
                'id': i.accountid,
                'value': i.accountid,
                'label': i.name,
            }
            infoData.push(accounts);
        }
        setAccountsList(infoData)
    }

    const getCatalogORM = async (body) => {
        let bodyquery = {
            "queryORM": body
        }
        const testVocational = new TestVocationalService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY);
        const resultORM = await testVocational.getInfoORM(bodyquery);
        return resultORM;
    }


    const postTestVocationUser = async () => {
        log(form)
        form.phone = form.phone.replace(/[^\d]/g, '');
        setLoading(true);    
        eventTracker('crear_cuenta', eventData, ['mixpanel']);
        const userContact = new UserService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY);
        const response = await userContact.postContactProspect(form);
        if (response.status===200){
            setForm({name: '',
                    lastName: '',
                    secondLastName: '',
                    email: '',
                    confirmEmail: '',
                    phone: '',
                    optUniversity: '',
                    procedencia: '',
                    program: '',
                    sede: '',
                    type});
            setAcceptTerms(false);
            setLoading(false);
            setToastProps({ severity: "success", open: true, message: "Tu Informacion se mando Correctamente" })
            log(response);
        }else if (response.status===203){
            log('usuario ya existe')
            setLoading(false);
            setErrors({email: 'El correo ya existe. Intenta uno nuevo'});
        }else {
            setLoading(false);
            setToastProps({ severity: "error", open: true, message: "Ha ocurrido un error, por favor intentar nuevamente" })
            log(response.data.message);
        }
    }

    const hanldeInputChange = (value) => {
        setAccountsList(value);
    }


    return (
        <Box>
            <LoaderContent loading={loading}>
            <Box my="42px">
                <Box mb="48px">
                    <Typography variant="h2" className="mb-3 subtitle">Hola! Muchas gracias por tu interés de estudiar con nosotros</Typography>
                    <Typography variant="body1" className="text">Para brindarte mayor información sobre nuestros programas, <span className="font-weight-700">Compártenos los siguientes datos</span> y nos pondremos en contacto contigo pronto:</Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Input 
                            fullWidth
                            required
                            label="Nombre (s)"
                            className={classes[ou]}
                            placeholder="Nombres"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Input 
                            fullWidth
                            required={true}
                            label="Apellido paterno"
                            className={classes[ou]}
                            placeholder="Apellido paterno"
                            name="lastName"
                            value={form.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Input 
                            fullWidth
                            required={true}
                            label="Apellido materno"
                            className={classes[ou]}
                            placeholder="Apellido materno"
                            name="secondLastName"
                            value={form.secondLastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Input 
                            fullWidth
                            required={true}
                            label="Correo electrónico"
                            className={classes[ou]}
                            placeholder="correo@mail.com"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            error={form.email && errors.email}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Input 
                            fullWidth
                            required={true}
                            label="Confirmar correo"
                            className={classes[ou]}
                            placeholder="correo@mail.com"
                            name="confirmEmail"
                            value={form.confirmEmail}
                            onChange={handleChange}
                            error={form.confirmEmail && errors.confirmEmail}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            fullWidth
                            required={true}
                            label="Celular"
                            className={classes[ou]}
                            placeholder="(811) 123-456"
                            name="phone"
                            value={form.phone}
                            onChange={handleChange}
                            error={form.phone && errors.phone}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlSelect 
                            fullWidth
                            required={true}
                            label="Opcion Universidad"
                            className={classes[ou]}
                            placeholder="Que opcion somos para estudiar?"
                            name="optUniversity"
                            value={form.optUniversity}
                            onChange={handleChange}
                            items={optionsUniversity}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AutocompleteSelect
                            fullWidth
                            label= { type == 'maestria' ? "Empresa donde laboras (opcional)" : "Escuela de procedencia" }
                            placeholder="Busca el lugar de procedencia"
                            name="procedencia"
                            value={form.procedencia}
                            accounstsList={accounstsList}
                            onChange={handleChangeSearch}
                        />
                    </Grid>
                   
                    <Grid item xs={12}>
                        <FormControlSelect 
                            fullWidth
                            required={true}
                            label="Campus de Interes"
                            className={classes[ou]}
                            placeholder="Selecciona una sede"
                            name="sede"
                            value={form.sede}
                            onChange={handleChange}
                            items={sedeList}
                        />
                    </Grid>

                    {
                        type !== 'bachillerato' &&  
                            <Grid item xs={12}>
                                <FormControlSelect 
                                    fullWidth
                                    required={true}
                                    disabled={isProgram}
                                    label={typeCat[type].label + ' de interés'}
                                    className={classes[ou]}
                                    placeholder="Selecciona una Carrera"
                                    name="program"
                                    value={form.program}
                                    onChange={handleChange}
                                    items={programList}
                                />
                            </Grid>
                    }
                    </Grid>

                <Box my="52px">
                    <FormControlLabel
                        control={<Checkbox checked={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} name="accept" />}
                        label={
                            <Typography variant="body2" className="terms semi-bold">
                            He leído y acepto los <Link component={RouterLink} to="/terminos_condiciones">Términos y Condiciones</Link> y <Link component={RouterLink} to="/aviso_privacidad">Aviso de privacidad</Link> de Talisis.
                            </Typography>
                        }
                    />
                </Box>
                <Box>
                    <Button variant="contained" fullWidth className="button-start" disabled={!acceptTerms || !isValid} onClick={postTestVocationUser}>Enviar Formulario</Button>
                    <Button fullWidth className="button-login" color="primary" component={RouterLink} to="/login">Iniciar sesión</Button>
                </Box>
            </Box>
            <Toast {...toastProps} onFinished={handleFinishedToast} />
            </LoaderContent>
        </Box>
    );
};

export default ContactProspectUnid;