import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    menuRoot: {
        maxWidth: props => props.isResponsive ? 'none' : '200px',
        width: '100%',
    },
    newEventButton: {
        marginTop: '16px',
        width: '100%',
    },
    itemContainer2: {
        cursor: 'pointer',
        [theme.breakpoints.up('sm')] : {
            marginTop: '16px',
        }
    }
}));
