import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useLocation, useHistory } from "react-router-dom";
import { MenuItem } from '@material-ui/core';
import { log } from '../../../../shared/utils/console.js'

// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../redux/actions/userActions';
import * as profilePicActions from '../../../../redux/actions/profilePicActions';
import * as tourInitialActions from '../../../../redux/actions/tourInitialActions';

//SDK
import { LoginService, SingupService } from '@sdk-point/talisis';

const LoginServiceSDK = new LoginService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '395px',
        padding: '12px 0px',
        margin: '0px auto',
    },
    heading: {
        fontSize: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
        flexShrink: 0,
        padding: '24px 0px'
    },
    disabledField: {
        '& > .Mui-disabled': {
            color: '#bdbdbd !important'
        }
    },
    input: {
        alignItems: 'center',
        gap: '10px',
        width: '100%',
        maxWidth: '318px',
        height: '48px',
        background: `${theme.palette.colors.grey[500]} !important`,
        border: `0.5px solid ${theme.palette.colors.grey[50]} !important`,
        borderRadius: '4px',
        textAlign: 'left',
        '& :-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${theme.palette.colors.grey[500]} inset`,
            '-webkit-text-fill-color': `${theme.palette.colors.white} !important`,
            'caret-color': 'white',
            fontWeight: 400,
            padding: '14px',
        },
    },
    legal: {
        textAlign: 'left',
    },
    label: {
        fontSize: '11px',
        color: theme.palette.colors.white,
        textDecoration: 'none !important',
        cursor: 'default',
    },
    link: {
        fontSize: '11px',
        color: theme.palette.colors.orange[300]
    },
    submit: {
        width: '318px',
        height: '48px',
        background: theme.palette.colors.violet[500],
        borderRadius: '4px',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        '&.Mui-disabled': {
            borderRadius: '4px',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '16px',
        }
    }
}));

const AutoSignup = props => {
    const { formData, onLoading, onSignupError, applyUser } = props;
    const classes = useStyles();
    const [errors, setErrors] = React.useState({});
    const [form, setForm] = React.useState({
        'first_name': formData.first_name,
        'last_name': formData.last_name,
        'email': formData.email,
        'phone': '',
        'gender': '',
        'legal': false,
    });

    let history = useHistory();
    const [userState, setUserState] = useState({
        isAuthenticated: false,
        user: {},
        error: null
    });

    const location = useLocation();
    const preventDefault = (event) => event.preventDefault();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            const _errors = { ...errors };
            delete _errors[name];
            setErrors(_errors);
        }

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['first_name', 'last_name', 'email', 'gender', 'phone'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            }
        }

        setErrors({ ..._errors });
        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleSubmit = (e) => {
        preventDefault(e)
        if (validate()) {
            onLoading(true)
            try {
                SingupServiceSDK.signup(form, location.search, true).then(data => {
                    log(data)
                    if (data.error) {
                        onSignupError('Este correo ya está registrado. Por favor, inicia sesión')
                        onLoading(false)
                    } else {
                        window.location.reload()
                    }
                })
                    .catch()
                    .finally(() => {
                        onLoading(false)
                    })
            }
            catch (error) {

            }
        }
    };

    const handleChecked = (e, checked) => {
        const { name } = e.target;
        setForm(prev => { return { ...prev, [name]: checked } });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className="pt-2 pb-4">
                <Grid item xs={12}>
                    <TextField
                        name="first_name"
                        variant="outlined"
                        label="Nombre"
                        fullWidth
                        size="medium"
                        required
                        value={form.first_name || ""}
                        error={Boolean(errors.first_name)}
                        helperText={errors.first_name}
                        onChange={handleChange}
                        InputProps={{
                            className: classes.input,
                        }}
                        InputLabelProps={{
                            style: { color: '#A0A9BA' },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="last_name"
                        variant="outlined"
                        label="Apellido"
                        fullWidth
                        size="medium"
                        required
                        value={form.last_name || ""}
                        error={Boolean(errors.last_name)}
                        helperText={errors.last_name}
                        onChange={handleChange}
                        InputProps={{
                            className: classes.input,
                        }}
                        InputLabelProps={{
                            style: { color: '#A0A9BA' },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="email"
                        variant="outlined"
                        label="Correo Electrónico"
                        fullWidth
                        size="medium"
                        required
                        value={form.email || ""}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        onChange={handleChange}
                        disabled={true}
                        className={classes.disabledField}
                        InputProps={{
                            className: classes.input,
                        }}
                        InputLabelProps={{
                            style: { color: '#A0A9BA' },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        key="gender"
                        select={true}
                        name="gender"
                        variant="outlined"
                        label="Género"
                        fullWidth
                        size="medium"
                        required
                        value={form.gender || ""}
                        error={Boolean(errors.gender)}
                        helperText={errors.gender}
                        onChange={handleChange}
                        InputProps={{
                            className: classes.input,
                        }}
                        InputLabelProps={{
                            style: { color: '#A0A9BA' },
                        }}
                    >
                        <MenuItem key="male" value="M">Masculino</MenuItem>
                        <MenuItem key="female" value="F">Femenino</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="phone"
                        variant="outlined"
                        label="Teléfono"
                        fullWidth
                        size="medium"
                        required
                        value={form.phone || ""}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone}
                        onChange={handleChange}
                        InputProps={{
                            className: classes.input,
                        }}
                        InputLabelProps={{
                            style: { color: '#A0A9BA' },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup row className={classes.legal}>
                        <FormControlLabel
                            control={<Checkbox name="legal" checked={form.legal} color="default" onChange={handleChecked} />}
                            label={
                                <React.Fragment>
                                    <Link variant="caption" color="primary" className={classes.label}>Haciendo click aquí, acepto los </Link>
                                    <Link component={RouterLink} to="/terminos_condiciones" target={'_blank'} variant="caption" className={classes.link}>Términos y Condiciones</Link>
                                    <Link variant="caption" color="primary" className={classes.label}> y </Link>
                                    <Link component={RouterLink} to="/aviso_privacidad" target={'_blank'} variant="caption" className={classes.link}>Aviso de privacidad</Link>
                                    <Link variant="caption" color="primary" className={classes.label}> de Talisis.</Link>
                                </React.Fragment>
                            }
                        />
                    </FormGroup>
                    <Box display="flex" className="mt-2 mb-2">
                        <Button fullWidth variant="contained" color="primary" disabled={!form.legal} type={'submit'} onClick={handleSubmit} className={classes.submit}>Registrate</Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}

const mapStateToProps = ({ userReducer, profilePicReducer, tourInitialReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic,
    showTour: tourInitialReducer.showTour
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...tourInitialActions };
export default connect(mapStateToProps, mapDispatchToProps)(AutoSignup);