import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    pendingListContainer: {
        marginBottom: '57px',
        marginTop: '50px'
    },
    reviewPaymentLabel: {
        color: theme.palette.colors.gray[100]
    },
    paymentLabel: {
        color: theme.palette.colors.darkBlack[200]
    },
    paymentFrame: {
        border: 0,
        marginTop:-240
    },
    cardTotalWrap:{
        padding: '0 15px',
        maxWidth:450
    },
    cardFrameWrap:{
        overflow:'hidden',
        maxWidth:450
    },
    iconInformation: {
        color: theme.palette.colors.warning[300]
    },
    chargeLabel: {
        color: theme.palette.colors.fillStroke[100]
    },
    totalCotainer: {
        display: "flex",
        width: "100%"
    },
    totalLabel: {
        display: "flex",
        justifyContent: "end",
        width: "40%"
    },
    debtsTotalLabel: {
        color: theme.palette.colors.gray[100]
    },
    debtsTotalAmountLabel: {
        color: theme.palette.colors.orange[300]
    },
}));