import React from 'react';
import { useParams } from 'react-router-dom';

import clsx from 'clsx';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';

//SDK
import { HelpCenterService } from '@sdk-point/talisis';
import { useState } from 'react';

//import useStyles from '../../../src/assets/styles/jss/pages/simplicity/simplicityStyle';

const HelpGuide = () => {
    const HelpCenterServiceSDK = new HelpCenterService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const [selectedArticle, setSelectedArticle] = useState([]);
    const theme = useTheme();
    const useStyles = makeStyles({
        learnigRoot: {
            display: 'grid',
            radius: '8px',
            padding: '24px',
            gap: '24px',
            minHeight: '264px',
            [theme.breakpoints.down("sm")]: {
                marginTop: '16px',
            }
        },
        title: {
            fontSize: '24px',
            lineHeight: '26px'
        },
        subTitle: {
            fontSize: '20px',
            lineHeight: '24px'
        },
        info:{
            fontSize: '14px',
            lineHeight: '18px'
        },
        infoGrid: {
            display: 'grid',
            gap: '16px'
        }
    });

    const { name, tag } = useParams();

    useEffect(() => {
        getArticleInfo(tag);
    }, []);

    const getArticleInfo = async (topic) => {
        //let tag = 'no_puedo_crear_cuenta'
        //setLoading(true)
        const infoArticle = await HelpCenterServiceSDK.getCategoriesArticles(topic).then(t => t);
        console.log('info article', infoArticle)
        setSelectedArticle(infoArticle);
        ///setLoading(false)
      }

    const classes = useStyles();
    return (
        <div>
             <Card className={classes.learnigRoot}>
                <Box className={classes.boxTitle}>
                    <Typography variant='h3' className={classes.title}>{name}</Typography>
                </Box>
                {selectedArticle.map((article, index) => (
                    <Grid key={index} className={classes.infoGrid}>
                        <Typography variant='h4' className={classes.subTitle}>{article.title}</Typography>
                        <Typography className={classes.info}>{article.snippet}</Typography>
                    </Grid>
                ))}
             </Card>
        </div>
    );
};

export default HelpGuide;