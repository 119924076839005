import React from 'react'

// Material
import {Popover,
    List, Grid, Typography, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// styles
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledPopover = withStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        left: 'unset !important',
        right: 0,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important'
        }
    },
}))(Popover);

const useStyles = makeStyles((theme) => ({
    list:{
        width: 376,
        padding: '12px 0 0',
        '& .MuiListItem-divider':{
            borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`
        },
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        width: '100%'
    },
    title: {
        color: theme.palette.colors.grey[100],
        marginTop: '6px',
        fontSize: '20px',
        lineHeight: '24px',
    },
    close: {
        color: theme.palette.colors.grey[100],
        float: 'right',
        marginRight: '0',
        padding: '0',
    },
    headerContainer: {
        padding: '0 15px'
    },
}));

export const PopoverMenu = (props) => {
    const classes = useStyles();
    const { children, anchorEl, handleClose, title } = props
    
    const open = Boolean(anchorEl);
  return (
    <StyledPopover
        id="notifications"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
    >
        <div className={classes.drawerHeader}>
            <Grid container className={classes.headerContainer}>
                <Grid item xs={9}>
                    <Typography className={classes.title} variant={'h2'}>{title}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <IconButton className={classes.close} onClick={handleClose}>
                        <CloseIcon style={{width:'0.7em'}}/>
                    </IconButton>
                </Grid>
            </Grid>
        </div>
        <List className={classes.list}>
            {
                children
            }
        </List>
        {/* <Divider className="bg-fill-stroke-200"/> */}
    </StyledPopover>
  )
};

export default PopoverMenu;
