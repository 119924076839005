import React from 'react'

import { Button, Card, CardContent, Grid, makeStyles, Modal, TextField, Typography } from '@material-ui/core'

// Custom Hooks
import { useForm } from '../hooks/useForm';
  
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.dark.main,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    dangerBtn: {
      backgroundColor: theme.palette.error.main,
      marginRight: '0.5rem',
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    }
  }));

const DeleteAccountModal = ({ openModal, handleCloseModal, handleDelete, stateUser}) => {

  const { userName } = stateUser;
  

    const classes = useStyles();
    
    const { formulario, handleInputChange, reset } = useForm({ fullName: "" });
    const { fullName } = formulario;

    const isExactName = fullName !== stateUser;

    const handleCancelar = () => {
      handleCloseModal();
      reset();
    }

    return (
        <Modal
            open={openModal}
            onClose={handleCancelar}
            className={classes.modal}
        >
            <Card>
              <CardContent>
                <Typography variant="h2">Eliminar cuenta</Typography>
                <Grid container className='mt-2'>
                  <Typography variant='body1'>Para confirmar la eliminacion de cuenta,</Typography>
                  <Typography variant='body1'>completa la siguiente informacion. </Typography>
                </Grid>
                <Grid container className='mt-2' direction="column">
                  <Typography variant='body2'> Escribe tu usuario completo: </Typography>
                  <span className="font-italic mt-2">{userName} </span>
                </Grid>
                <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={fullName}
                    type="text"
                    name="fullName"
                    autoComplete="off"
                    onChange={handleInputChange}
                />
                <Grid container justifyContent='flex-end' className='mt-3'>
                  <Button className={classes.dangerBtn} color="secondary" variant='contained' disabled={isExactName} onClick={handleDelete}>
                    Confirmar
                  </Button>
                  <Button variant='outlined' onClick={handleCancelar}>
                    Cancelar
                  </Button>
                </Grid>
              </CardContent>
            </Card>
        </Modal>
    )
}

export default DeleteAccountModal
