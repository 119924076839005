import {withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';

const font =  "'Inter', sans-serif";

export const BootstrapInputStyled = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '&.placeholder-select .MuiSelect-root':{
          color: '#d1d1d3'
      }
    },
    input: {
        ...theme.typography.subtitle2,
        borderRadius: '8px !important',
        position: 'relative',
        backgroundColor: theme.palette.colors.fillStroke[400],
        border: `0.5px solid #404150`,
        padding: '12px 16px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: `${font} !important`,
        '&::placeholder': {
            color: theme.palette.colors.fillStroke[100],
        },
    },
}))(InputBase);

export const LabelStyled = withStyles((theme) => ({
    root: {
        ...theme.typography.h5,
        color: `${theme.palette.colors.gray[100]}`,
    }
}))(InputLabel);