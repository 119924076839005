import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import {BootstrapInputStyled, LabelStyled} from '../../../assets/styles/jss/components/inputComponentsStyle'

const Input = (props) => {
    const {label, classes, error,...rest} = props;

    return (
        <FormControl {...rest} error={Boolean(error)}>
            <LabelStyled shrink htmlFor="bootstrap-input">
                {label}
            </LabelStyled>
            <BootstrapInputStyled id="bootstrap-input" {...rest}/>
            <FormHelperText id="component-error-text">{error}</FormHelperText>
        </FormControl>
    );
};

export default Input;


Input.propTypes = {
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.any,
    placeholder:PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string,
};