import React, { useEffect, useState } from 'react';

// redux
import { connect} from 'react-redux';
import * as userActions from '../../../../../redux/actions/userActions';

// @material-ui/core
import { useTheme } from '@material-ui/core/styles';
import { Box, Grid, Typography, Icon, TextField, InputAdornment, Button } from '@material-ui/core';

// shared
import { useConekta } from '../../../../../shared/hooks';
import Toast from '../../../../../shared/components/Toast/Toast';

// utils
import * as format from '../../../../../shared/utils/format';
import { getCheckoutCookie } from '../../../../../shared/utils/checkoutSession-legacy.js';
import { DEBUG_CARD } from './debugCard';
import { log } from '../../../../../shared/utils/console.js'
import { addCustomerCards, cardExists, generateCardTokens, verifyFailedCards } from '../../../../../shared/utils/cardHelper.js';

import LoaderContent from '../../../../../shared/components/Loader/LoaderContent';

const NewCard = (props) => {
    const { getCardForm, closeModal, getAcceptedSaveCard, setReLoadCards, setOptionSelected } = props;
    const theme = useTheme();

    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    
    const checkoutCookie = getCheckoutCookie();
    
    const conekta = useConekta();
    const [loading,setLoading] = useState(false);
    const [sameUserPayment,setSameUserPayment] = useState(false);
    const [form, setForm] = useState({
        'number': '',
        'name': '',
        'exp_year': '',
        'exp_month': '',
        'cvc': '',
        'phone': '',
        'email': '',
        'card_expiration': ''
    });
    
    const [acceptSaveCard, setAcceptSaveCard] = useState(true)
    const [userSaveCard, setUserSaveCard] = useState(true);

    useEffect(() => {
        getCardForm(form)
        getAcceptedSaveCard(acceptSaveCard)
    }, [form, acceptSaveCard])
    

    const { email_address, first_name, last_name, person_id } = checkoutCookie?.personInfo;

    const [openToast, setOpenToast] = useState(false);
    const [toastSeverity, setToastSeverity] = useState("error")

    const setToastProps = (open, message, severity = "error") => {
        setOpenToast(open);
        setErrorMessage(message);
        setToastSeverity(severity)
    };

    const handleFinishedToast = () => {
        setToastProps(false, '');
    };

    const successfullySaved = () => {
        setLoading(false)
        closeModal()
        setReLoadCards()
    }

    const handleSaveCard = async() => {
        if(validate()){
            setLoading(true)
            const exists = await cardExists({ person_id, formCardNumber: form.number });
            if (exists) {
                setToastProps(true, 'Ya existe el método de pago. Prueba agregando uno diferente', "warning");
                setLoading(false);
                return false;
            }
            if(!exists){
                const tokens = await generateCardTokens( form, conekta);
                const newConektaCards = await addCustomerCards({ tokens, person_id});
                const hasFailedCards = verifyFailedCards(newConektaCards)
                if( hasFailedCards ){
                    setLoading(false)
                    setToastProps(true, "El número de la tarjeta es inválido.");
                    return false
                }
                const newCardSaved =`savedMethod - ${newConektaCards[0]?.last4}`
                setOptionSelected(newCardSaved)
                log("saved Cards", newConektaCards, hasFailedCards, newCardSaved)
                successfullySaved()
                return true
            }
        }
    }

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "v1" && process.env.REACT_APP_ENV !== "v2" && process.env.REACT_APP_ENV !== "v3") {
            setForm(DEBUG_CARD);
        }
        if(checkoutCookie.membershipSession){
            setUserSaveCard(false)
        }
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (value) {
            const _errors = { ...errors };
            delete _errors[name];

            setErrors(_errors);
        };

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const getExpDateInfo = ( expDate ) => {
        if( expDate.length < 5 ) return 

        const expArray = expDate.split("/", 2);
        const expInfo = {
            exp_month: expArray[0],
            exp_year: expArray[1]
        }
        return expInfo
    }

    const validate = () => {
        const _errors = {};
        const requiredFields = ['number', 'name', 'cvc', 'card_expiration'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Este dato es necesario';
            } 
            else {
                if (field === "number") {
                    if (form[field].length < 15) {
                        _errors[field] = 'El número mínimo de caracteres es de 15';
                    }
                } else if (field === "cvc") {
                    if (form[field].length < 3) {
                        _errors[field] = 'El número mínimo de caracteres es de 3';
                    }
                } else if (field === "card_expiration") {
                    const { exp_month, exp_year } = getExpDateInfo(form[field]);
                    const currentYear = new Date().getFullYear().toString();
                    const currentMonth = new Date().getMonth();

                    if( exp_year < currentYear.slice(2, 4) ){
                        _errors[field] = `El año debe ser mayor o igual a ${currentYear.slice(2, 4)}`;
                    }
                    else if( exp_month > 12 ){
                        _errors[field] = "El mes debe ser menor o igual a 12";
                    }
                    else if( exp_year === currentYear.slice(2, 4) && exp_month < currentMonth ){
                        _errors[field] = "La fecha de vencimiento no es valida";
                    }
                }
            }
        }

        setErrors({ ..._errors });

        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleFormatCreditCardExp = (e) => {
        if (e.target.value) {
            e.target.value = format.creditCardExp(e.target.value);
        }
    };

    return (
        <LoaderContent loading={loading}>
            <Box>
                <Grid container direction="row">
                    <Grid item xs={12} className='my-4'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} className="my-2">
                                <TextField 
                                    name="number"
                                    variant="outlined"
                                    label="Número de la tarjeta"
                                    value={form.number || ""}
                                    error={Boolean(errors.number)}
                                    helperText={errors.number}
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={validate}
                                    placeholder="Ingresa un número de tarjeta"
                                    inputProps={{ maxLength: 16 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Icon className='ri-bank-card-line' fontSize='inherit' />
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className="my-2 py-0">
                                <TextField
                                    name="card_expiration"
                                    variant="outlined"
                                    label="Fecha de Vencimiento"
                                    value={form.card_expiration || ""}
                                    error={Boolean(errors.card_expiration)}
                                    helperText={errors.card_expiration}
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={validate}
                                    placeholder="MM/AA"
                                    inputProps={{ maxLength: 5 }}
                                    onInput={(e) => { handleFormatCreditCardExp(e) }}
                                 />
                            </Grid>
                             <Grid item xs={6} className="my-2 py-0">
                                <TextField 
                                    name="cvc"
                                    label="Código de seguridad"
                                    variant="outlined"
                                    value={form.cvc || ""}
                                    type="password"
                                    error={Boolean(errors.cvc)}
                                    helperText={errors.cvc}
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={validate}
                                    placeholder="CVV"
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Icon className='ri-lock-2-line' fontSize='inherit' />
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid> 
                            <Grid item xs={12} className='mt-2'>
                                <TextField 
                                    name="name"
                                    label="Titular de la tarjeta"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    value={form.name || ""}
                                    onChange={handleChange}
                                    onBlur={validate}
                                    inputProps={{ readOnly: sameUserPayment }}
                                    placeholder="Ingresa tu nombre de titular"
                                    InputProps={{ startAdornment: <></> }}
                                />
                            </Grid>
                            <Grid item xs={12} className="text-left">
                                 <Typography variant='caption' style={{ color: theme.palette.colors.darkBlack[200] }}>
                                    *Campos obligatorios
                                </Typography>
                             </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} className="mt-2" alignItems='center' justifyContent='flex-end'>
                            <Grid item xs={6} sm={4}>
                                <Button onClick={closeModal} fullWidth variant='outlined' color='secondary'>
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Button onClick={handleSaveCard} fullWidth variant="contained" color='primary' >
                                    Guardar tarjeta
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        <Toast severity={toastSeverity} open={openToast} message={errorMessage} onFinished={handleFinishedToast} duration={3000} />
        </LoaderContent>
    );
};

const mapStateToProps = ({ userReducer }) => ({
    loggedUser: userReducer.user
});

const mapDispatchToProps = { ...userActions };
export default connect(mapStateToProps, mapDispatchToProps)(NewCard);
