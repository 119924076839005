import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Base from '../components/Base';

// Components
import DisabledCourseMedia from './components/DisabledCourseMedia';
import DisabledCourseContent from './components/DisabledCourseContent';

const useStyles = makeStyles((theme) => ({
    rootBase: {
        height: props => props.isResponsive ? "303px" : "362px",
        width: props => props.isResponsive ? "224px" : "288px",
        minWidth: props => props.isResponsive ? "224px" : "288px",
    },
}));

const DisabledCourseCard = ({courseId, sourceIdLogo, media, content, isResponsive}) => {
    const classes = useStyles({isResponsive});

    return (
        <Base className={classes.rootBase}> 
            <DisabledCourseMedia media={media} sourceIdLogo={sourceIdLogo} isResponsive={isResponsive}/>
            <DisabledCourseContent content={content} isResponsive={isResponsive}/>
        </Base>
    );
};

DisabledCourseCard.defaultProps = {
    isResponsive: false,
};

DisabledCourseCard.propTypes = {
    courseId: PropTypes.number.isRequired,
    sourceIdLogo: PropTypes.number,
    media: PropTypes.shape({
        backgroundImage: PropTypes.string,
        modalityId: PropTypes.number,
    }).isRequired,
    content: PropTypes.shape({
        courseName: PropTypes.string.isRequired,
        dateAvailableFrom: PropTypes.string,
    }).isRequired,
    isResponsive: PropTypes.bool,
};

export default DisabledCourseCard;