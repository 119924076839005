import React from 'react'

// Material
import {
    List,  Chip,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

// styles
import useStyles from '../../../../../assets/styles/jss/layout/floatingMenuNotificationsStyle';
import clsx from 'clsx';
import NothingFound from '../../../../../shared/components/NothingFound/NothingFound';


export const FloatingMenuNotifications = (props) => {
    const classes = useStyles();
    const { children, labelTrue, labelFalse, handleClickTrue, handleClickFalse, list = [], nothingInfo = {}, result } = props

  return (
    <React.Fragment>
        <div className={classes.drawerHeader}>
                    <Chip
                        className={clsx(classes.chip, {['active']: result == false})}
                        variant="outlined"
                        size="small"
                        label={labelFalse}
                        onClick={handleClickFalse}
                    />
                    <Chip
                        className={clsx(classes.chip, {['active']: result})}
                        variant="outlined"
                        size="small"
                        label={labelTrue}
                        onClick={handleClickTrue}
                    />
        </div>
        <Divider className="bg-fill-stroke-200"/>
            {
                list.length ? 
                <List className={classes.list}>
                    {
                        children
                    }
                </List>
                 : 
                <NothingFound type={nothingInfo.type} subcaption={nothingInfo.subcaption} 
                caption={nothingInfo.caption}>
                    {nothingInfo.extra}
                </NothingFound>
            }
    </React.Fragment>
  )
};

export default FloatingMenuNotifications;
