import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

//styles
import { makeStyles } from '@material-ui/core/styles';
import LogoSource from '../../../../design-system/components/LogoSource/LogoSource';
import { SOURCES } from '../../../../design-system/constants/sources';
import { Button, Divider, Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root:{
        '& .MuiDialog-paper':{
            background: theme.palette.colors.gray[100],
            boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.25);',
            borderRadius: 8,
            width: 536,
            [theme.breakpoints.down('xs')]:{
                margin: 16,
            }
        },
    },
    dialogTitle:{
        height: 56,
        padding: theme.spacing(2,2.5),
        textAlign: 'end'
    },
    dialogContent:{
        padding: '25px 0 5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .title':{
            maxWidth:475,
            textAlign:'center',
            marginBottom: 37,
            '& .MuiTypography-root':{
                ...theme.typography.desktop.h4
            }
        },
        [theme.breakpoints.down('xs')]:{
            padding: 0,
            '& .title':{
                maxWidth:272,
                marginBottom: 32
            },
        }
    },
    dialogActions:{
        justifyContent: 'center',
        padding: '26px 0',
        [theme.breakpoints.down('xs')]:{
            padding: '32px 24px 42px',
            '& .MuiButton-root':{
                width: '100%'
            }
        }
    },
    reminderText:{
        width: '100%',
        textAlign: 'center',
        maxWidth: 380,
        '& .label':{
            ...theme.typography.body1
        },
        '& .MuiDivider-root':{
           marginTop: 17
        },
        [theme.breakpoints.down('xs')]:{
            padding: '0 24px',
            '& .label':{
                ...theme.typography.body2
            },
            '& .MuiDivider-root':{
                marginTop: 16
            },
        } 
    }
}));

const SpinPopup = (props) => {
    const classes = useStyles();
    const { onClose, open } = props;

    return (
        <Dialog onClose={onClose} aria-labelledby="spin-dialog" open={open} className={classes.root} disableBackdropClick>
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <IconButton aria-label="close" onClick={onClose} className='p-0'>
                    <Icon className="ri-close-line icon-size-12" />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Hidden xsDown>
                    <Box className='title'>
                        <Typography className='text-dark-black-400 offset-margin-bottom-7'>Aprovecha los beneficios exclusivos en</Typography>
                        <Typography className='text-dark-black-400'>
                            <Typography component="span" className='text-electric-blue-300'>Talisis.com</Typography>
                            &nbsp;por ser miembro de
                        </Typography>
                    </Box>
                </Hidden>
                <Hidden smUp>
                    <Box className='title'>
                        <Typography className='text-dark-black-400'>
                            Aprovecha los beneficios exclusivos en <Typography component="span" className='text-electric-blue-300'>Talisis.com</Typography> por ser miembro de
                        </Typography>
                    </Box>
                </Hidden>
                <Box sx={{mb: {xs: '32px', sm: '34px'}}}>
                    <LogoSource sourceId={SOURCES.SPIN} disabledAutoRes/>
                </Box>
                <Box className={classes.reminderText}>
                    <Typography className='text-dark-black-400 label'>¡Recuerda redimir tu cupón!</Typography>
                    <Divider/>
                </Box>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button variant='contained' color='primary' onClick={onClose}>Ver membresías</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SpinPopup;