import React from 'react';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';

// shared - core components
import Select from '../../../../../../design-system/components/Form/Select/Select'
import MultipleSelect from '../../../../../../shared/components/Form/MultipleSelect'
import ConfirmDialog from '../../../../../../shared/components/ConfirmDialog/ConfirmDialog'
import AutocompleteInput from '../../../../../../shared/components/AutocompleteInput/AutocompleteInput'
import { log } from '../../../../../../shared/utils/console.js'

// service
import { PromptState } from 'msal/lib-commonjs/utils/Constants';
//SDK
import { RequestService } from '@sdk-point/talisis'; 

const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const authorizerstypes = (disabledAuth) => {
    if (disabledAuth) {
        return [
            {id: 2, name: 'notificador', label: 'Notificador'},
        ]
    }else {
        return [
            {id: 1, name: 'autorizador', label: 'Autorizador'},
            {id: 2, name: 'notificador', label: 'Notificador'},
        ]
    }
}

const AddAuthorizers = (props) => {

    const [open, setOpen] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [form, setForm] = React.useState({
        id: null,
        ou: '',
        assignee: '',
        campus: [],
        authorizertype: ''
    });
    const [campus, setCampus] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [state, setState] = React.useState({
        loading: false,
        options: [],
        criteria: ""
    });

    var disabledConfirmButton = !(form.ou && form.assignee && form.campus.length);

    React.useEffect(() => {
        setOpen(props.open)
        setDisabled(props.disabled)
        if(props.form.ou && props.ous.length){
            handleChangeOU({target:{value: props.form.ou}});
        }
        setForm({...form, ...props.form})
    }, [props]);

    const getPersons = async () => {
        setState({loading: true})

        try {
            const options = await RequestServiceSDK.searchPerson(state.criteria);
            setState({options, loading: false});
        } catch (e) {
            setState({options: [], loading: false});
        }
    };

    const validate = () => {
        const _errors = {};
        // const requiredFields = ['ou','assignee'];
        const requiredFields = ['assignee', 'authorizertype'];
        const requiredFieldsLength = ['campus'];

        for(const field of requiredFields){
            if(!form[field]){
                _errors[field] = 'Campo Requerido';
            }
        }

        for(const field of requiredFieldsLength){
            if(!form[field] || !form[field].length){
                _errors[field] = 'Campo Requerido';
            }
        }

        if (props.authorizer.length > 0){
            for(const aut of props.authorizer){
                if (aut.person.id == form.assignee.id && aut.parent == form.ou && form.id == ''){
                    _errors['assignee'] = 'El autorizador ya fue agregado favor de editar en caso de requerir cambios'
                }
            }

        }
        setErrors(_errors);
        log(Object.keys(_errors).length === 0)
        return Boolean(Object.keys(_errors).length === 0);
    }

    const handleSubmit = () => {
        //log('form', form);
        if(validate()){
            props.onSubmit(form);
            setState({
                loading: false,
                options: [],
                criteria: ""
            })
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
        handleChageCampus(props.ou);
    };

    const handleClose = () => {
        props.onClose();
        setOpen(false);
        setState({
            loading: false,
            options: [],
            criteria: ""
        })
        setForm({
            id: null,
            ou: '',
            assignee: '',
            campus: [],
            authorizertype: ''
        })
        setCampus([]);
        setErrors({});
    };

    const handleChange = e => {
        const {name, value} = e.target;
        const _errors = {...errors};

        if(value || (value && value.length)){
            delete _errors[name];
        }

        setForm(prevState => {
            if(name === 'ou' && value && prevState.campus.length){
                delete _errors.campus;
            }

            if(name === 'authorizertype' && value && prevState.authorizertype.length){
                delete _errors.authorizertype;
            }

            return {
                ...prevState,
                [name]: value
            }
        })

        setErrors(_errors);
    };

    const hanldeSelectCampus = campusSelected =>{
        let _campus;
        const prevCampusSelected = [...form.campus];
        const allSelected = campusSelected.some(cs => cs.id === 'all');
        const allWasSelected = prevCampusSelected.some(cs => cs.id === 'all');
        const allItemsSelected = campusSelected.filter(it => it.id !== 'all').length === campus.length - 1;

        // si solo selecciona "all" y solo es 1 o si "all" es seleccionado pero no habia sido seleccionado antes
        if((allSelected && campusSelected.length === 1) || (allSelected && !allWasSelected)){
            _campus = campus;
        }else if(allItemsSelected && allWasSelected){
            // si selecciono "all" pero todo esta seleccionado, poner []
            _campus = [];
        }else if(!allItemsSelected){
            // si selecciono 1 y no estan todos completados, quitar "all"
            _campus = campusSelected.filter(it => it.id !== 'all');
        }else{
            // si selecciono 1 y todos estan completos agregar "all"
            _campus = campusSelected;
            if(!allSelected){
                _campus.splice(0, 0, {id: 'all', label: 'Todos los Campus'})
            }
        }

        handleChange({target:{
            name: 'campus',
            value: _campus
        }})
    }

    const handleChangeOU = (e) => {
        const {value} = e.target;
        const a = [...props.ous];
        const _campus = a.find(it => it.id === value).campus.map(c => ({label: c.name, ...c}));

        _campus.splice(0, 0, {id: 'all', label: 'Todos los Campus'})

        setCampus(_campus);

        if(!props.form.campus){
            setForm({...form, campus: _campus})
        }

        handleChange(e);
    }

    // Llenado de campus al abrir el modal
    const handleChageCampus = (value) => {
        form.ou = value;
        const a = [...props.ous];
        const _campus = a.find(it => it.id === value).campus.map(c => ({label: c.name, ...c}));

        _campus.splice(0, 0, {id: 'all', label: 'Todos los Campus'})

        setCampus(_campus);

        if(!props.form.campus){
            setForm({...form, campus: _campus})
        }


        handleChange({target:{
            name: 'ou', value
        }})
    }

    const hanldeInputChange = (value) => {
        setState({criteria: value})
    }

    const handleChangeAutocomplete = (value) => {
        const _errors = {};

        if (props.authorizer.length > 0 && value != null){
            for(const aut of props.authorizer){
                if (aut.person.id == value.id && aut.parent == form.ou){
                    _errors['assignee'] = 'El autorizador ya fue agregado favor de editar en caso de requerir cambios'
                    disabledConfirmButton = true;
                }else{
                    handleChange({target:{
                        name: 'assignee', value
                    }})
                }
            }
        }else{
            handleChange({target:{
                name: 'assignee', value
            }})
        }
        setErrors(_errors);
    }
    return (
        <div>
            <Box display="flex" alignItems="center" my={2}>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen} //disabled={disabled} 
                variant="outlined"
                >Agregar Autorizador</Button>
            </Box>

            <ConfirmDialog 
                dialogTitle="Autorizadores"
                open={open} 
                onClose={handleClose}
                onConfirm={handleSubmit}
                disableBackdropClick
                disableEscapeKeyDown
                disabledConfirmButton={disabledConfirmButton}
                maxWidth="xs">
                    <Box my={3}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <Select
                                    required
                                    items={props.ous}
                                    label="Unidad operativa"
                                    name="ou"
                                    value={form.ou}
                                    onChange={handleChangeOU}
                                    error={Boolean(errors.ou)}
                                    helperText={errors.ou}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                {/* <AutocompleteInput 
                                    label="Autorizadores"
                                    placeholder="Agregar autorizadores"
                                    optionLabel="full_name"
                                    options={state.options}
                                    loading={state.loading}
                                    criteria={state.criteria}
                                    getData={getPersons}
                                    onInputChange={hanldeInputChange}
                                    onChange={handleChangeAutocomplete}
                                    // multiple
                                    limitTags={2}
                                    error={errors.assignee}
                                    defaultValue={form.assignee || []}
                                /> */}

                                <AutocompleteInput 
                                    required
                                    label="Autorizador"
                                    placeholder="Agregar autorizador"
                                    optionLabel="full_name"
                                    options={state.options}
                                    loading={state.loading}
                                    criteria={state.criteria}
                                    getData={getPersons}
                                    onInputChange={hanldeInputChange}
                                    onChange={handleChangeAutocomplete}
                                    error={errors.assignee}
                                    defaultValue={form.assignee || []}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MultipleSelect
                                    onSelect={hanldeSelectCampus} 
                                    inputLabel="Campus"
                                    inputLabelAllSelected="Todos los campus"
                                    menuItems={campus}
                                    itemsSelected={form.campus}
                                    error={errors.campus}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    items={authorizerstypes(disabled)}
                                    label="Tipo de paso"
                                    name="authorizertype"
                                    value={form.authorizertype}
                                    onChange={handleChange}
                                    // error={Boolean(errors.type)}
                                    // helperText={errors.type}
                                />
                            </Grid>    
                        </Grid>
                    </Box>
            </ConfirmDialog>
        </div>
    );
};

export default AddAuthorizers;