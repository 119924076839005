import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    feedFileItemRoot: {
        width: '100%',
        border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: '8px',
        display: props => props.isResponsive ? 'block' : 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
    },
    iconButton: {
        marginLeft: '8px',
    },
    fileNameContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: props => props.isResponsive ? '16px' : 0,
    },
    fileName: {
        marginLeft: '16px',
    },
    downloadButton: {
        width: props => props.isResponsive ? '100%' : 'initial',
    },
}));