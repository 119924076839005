import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MenuItem, MenuList, Tooltip } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Delete, Edit } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    menu: {
        display: 'flex',
        flexDirection: props => props.reverse !== true ? "row-reverse":"row",
        justifyContent: props => props.reverse !== true ? "flex-end" : "inherit",
    },
    itemIcon:{
        paddingLeft: "8px",
        paddingRight: "8px",
    }
}));

const ButtonsDelEdit = ({handleDeleteClick, handleEditClick, reverse}) => {
    const classes = useStyles({reverse});

    return (
        <MenuList id="menu-list-grow" role="" className={classes.menu}>
            <Tooltip title="Eliminar" interactive={true}>
                <MenuItem className={classes.itemIcon} onClick={handleDeleteClick}>
                    <Icon className={clsx("text-dark-black-100","ri-delete-bin-5-line")} fontSize='small'/>
                </MenuItem>
            </Tooltip>
            <Tooltip title="Editar" interactive={true}>
                <MenuItem className={classes.itemIcon} onClick={handleEditClick}>
                    <Icon className={clsx("text-dark-black-100","ri-edit-line")} fontSize='small'/>
                </MenuItem>
            </Tooltip>
        </MenuList>
    );
};

export default ButtonsDelEdit;