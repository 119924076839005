import React from 'react';

import GoogleMapReact from 'google-map-react';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';

const KEY_GOOGLE_MAPS = "AIzaSyBMJ02gS16bRO-KvH1_QNhz6Rf_Hs-0enI";
const bootstrapURLKeys = {
    key: KEY_GOOGLE_MAPS,
    language: "es",
    region: "MX"
}

const Marker = () => <div>
    <Icon className="ri-map-pin-2-fill mr-2 text-electric-red-300 font-size-24"/>
</div>;

const Maps = (props) => {
    const {lat, lng, styleProps} = props;

    return (
        <Box {...styleProps}>
            <GoogleMapReact
                bootstrapURLKeys={bootstrapURLKeys}
                defaultCenter={{ lat, lng}}
                defaultZoom={15}
            >
                <Marker lat={lat} lng={lng} />
            </GoogleMapReact>
        </Box>
    );
};

Maps.defaultProps = {
    lat: 0,
    lng: 0,
};

export default Maps;