import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root:{
        background: theme.palette.black
    },
    toolbar:{
        height: props=> props.isLogged ? 65 : 80,
        [theme.breakpoints.only('md')]: {
            padding: '0 24px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '65px !important',
            paddingLeft: 60,
            paddingRight: 60,
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 34,
            paddingRight: 34,
        },
        '& .navlink-active':{
            backgroundColor: theme.palette.colors.fillStroke[400],
            borderTop: '8px solid transparent',
            position: 'relative',
            paddingTop: 0,
            '&::before': {
                content: '" "',
                position: 'absolute',
                top: '-8px',
                left: 0,
                right: 0,
                backgroundImage: 'linear-gradient(274.05deg, #CDACFC -8.09%, #8B3FFA 61.08%)',
                height: 8
            },
            '& .MuiListItemText-root':{
                marginBottom: 4
            }
        },
        '& .nav-btn-popover':{ 
            background: 'inherit', 
            padding: '12px 17px 13px', 
            position: 'relative', 
            borderRadius: 0, 
            height: props=> props.isLogged ? 65 : 80,
            borderTop: '8px solid transparent',
            [theme.breakpoints.only('md')]: {
                padding: '12px 14px 13px',
            },
            '@media(max-width: 1010px)': {
                padding: '12px 10px 13px'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '12px 12px 13px',
            },
            '&.active': { background: theme.palette.colors.fillStroke[400], 
                '&::before': { 
                    content: '" "',
                    position: 'absolute',
                    top: '-10px',
                    left: 0,
                    right: 0,
                    backgroundImage: 'linear-gradient(274.05deg, #CDACFC -8.09%, #8B3FFA 61.08%)',
                    height: 8
                }, 
            }, 
        }
    },
    logo:{
       height: 32,
       marginRight: 31,
       cursor: 'pointer',
       '@media(max-width: 1010px)': {
            marginRight: 0
        },
    },
    mTopOption:{
        marginTop: "36px"
    },
    menuLogged:{
        margin: '0 auto',
        height: 65,
        [theme.breakpoints.only('md')]: {
            marginLeft: 0
        },
    },
    menu:{
        height: 80
    },
    button:{
        background: theme.palette.colors.orange[100],
        fontSize: 14,
        color: theme.palette.colors.white,
        padding: theme.spacing(1.5,2),
        lineHeight: '16px',
        marginLeft: 32
    },
    buttonLogin:{
        marginLeft: 24,
        marginRight: 32,
        padding: theme.spacing(1.5, 2)
    },
    buttonLink:{
        marginLeft: 24,
        marginRight: 32,
        padding: theme.spacing(1.5, 2),
        color:theme.palette.colors.white,
        fontSize: 14,
        lineHeight: '16px',
    },
    menuButton: {
        marginRight: 12,
        color: theme.palette.colors.white
    },
    menuButtonMobile:{
        marginRight:0
    },

    hide: {
        display: 'none',
    },
    notification:{
        '&:hover .MuiTypography-root':{
            fontWeight: 600
        },
        '& .MuiBadge-dot':{
            top: '11px',
            right: '-11px',
            height: 7,
            minWidth: 7
        }
    },
    filterSubcontainer: {
        maxWidth: '1249px',
        width: '100%'
    },
    iconButtoFixed:{
        background: 'inherit',
        padding: '12px 17px 13px',
        position: 'relative',
        borderRadius: 0,
        height: props=> props.isLogged ? 65 : 80,
        '&.active': {
          background: theme.palette.colors.fillStroke[400],
          '&::before': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 8,
            borderTop: `8px solid ${theme.palette.colors.violet[400]}`,
          },
        },
        [theme.breakpoints.down('sm')]: {
            height: '65px !important',
        },
    },
}));