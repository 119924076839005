import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';

// Mix Panle
import { trackEventMixPanel } from '../shared/components/Mixpanel/mixpanel';
import {MIXPANEL_EVENT} from '@sdk-point/talisis';

// layouts
import {
    Main as MainLayout,
    Home as MarketHomeLayout
} from '../layout';

import pages from './routelinks'
import publicRoutes from './publicRoutes'
import * as paths from './paths'

// marketplace public layout & pages
import Layout from '../layout/Layout'

import * as userService from "../services/UserService";
import { NotFound } from '../pages/HttpErrors/404';

// shared
import { getSessionCookie } from "../shared/utils/Sessions";
import objectIsEmpty from "../shared/utils/objectIsEmpty";
import RouteWithLayout from './RouteWithLayout';

function isAuthorized(path,pageId){
    const allAcess = ['/home', '/profile', '/cursos', '/refiere-y-gana', '/recepcion-documentos', '/solicitudes'];

    if (allAcess.some(p => path.startsWith(p))) {
        return true;
    }
    const permissions = userService.getAllPermission();
    let canAccess = permissions.some(it => it.object_id === pageId)
    return canAccess;
}

const PrivateRoute = (props) => {
    const { layout: Layout, component: Component, title, subtitle, pageId, ...rest } = props;
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);

    return(
        <Route {...rest} render={matchProps => {
                return(
                    isLogged && isAuthorized(props.path,pageId)
                    ?   <Layout title={title} subtitle={subtitle} isLogged={isLogged}>
                            <Component {...matchProps} pageId={pageId}/>
                        </Layout>
                    : <Redirect to={isLogged ? '/home' : '/'}/>
                )
            }
        } />
    )
}

const Routes = ({isLogged}) => {
    const privateRoutes = [];
    for(const page of pages){
        if (page.path) {
            privateRoutes.push(
                <PrivateRoute
                    pageId={page.pageId}
                    key={page.id}
                    component={page.component}
                    exact layout={MainLayout}
                    path={page.path}
                />
            )
        }

        if (page.children) {
            for(const child of page.children){
                privateRoutes.push(
                    <PrivateRoute
                        pageId={child.pageId}
                        key={child.id}
                        component={child.component}
                        exact layout={MainLayout}
                        path={child.path}
                    />
                )
            }
        }
    }

    return (
        <Switch>
            <Route path="/promo/:propagandaId" exact render={matchProps => {
                const { match:{ params:{ propagandaId } } } = matchProps;
                trackEventMixPanel(MIXPANEL_EVENT.PROPAGANDA, {propaganda: propagandaId});
                return <Redirect to={paths.ROOT_HOME} />
            }}/>

            <Route exact path={paths.SPIN_BY_OXXO}>
                <Redirect to={`${paths.MEMBRESIAS}?spin=member`}/>
            </Route>
            <Route exact path={paths.EDUCACION_CONTINUA}>
                <Redirect to={paths.ROOT_HOME}/>
            </Route>

            <Route path="/navbar" exact render={matchProps => <Layout></Layout>} />
            {
                publicRoutes.map((it, index) => (
                    <RouteWithLayout
                        exact
                        key={index}
                        isLogged={isLogged}
                        {...it}
                    />
                ))
            }
            {privateRoutes}
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
