
export const ROLE_GROUP_MEMBER = 1;
export const ROLE_ADMINISTRADOR = 2;
export const ROLE_ESTUDIANTE = 3;
export const ROLE_MAESTRO = 4;
export const ROLE_STAFF = 5;
export const ROLE_CAPTURISTA = 6;
export const ROLE_PROMOTOR = 14;
export const ROLE_ACADEMY = 15;
export const ROLE_ADMIN_DEGREE = 20;
export const ROLE_ADMIN_RECEPCION_DOCS = 23;

//PERMISSIONS
export const PERMISSION_MY_TALISIS = 24;
export const PERMISSION_ADMIN_DEGREE_CERT = 36;
