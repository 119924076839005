import { v4 as uuidv4 } from 'uuid';
import {API_ENDPOINT} from './endpoints';
import {CURSES_SC, REGISTERS_SC, MARKET_PLACE_PATH, COURSES_PATH} from './endpoints';

const APP_ID = "FNI9KCYG2J";
const APP_SECRET = "lkjtLU6IbvC8xo7aeeAiy2kwF1S1uQWOhJaHqGJm";

export const getNeoCourses = async (user) => {
    const url = `${API_ENDPOINT}/neo/get-classes-by-student/${user.email}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        }
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getThirdPartyCourses = async (user) => {
    const url = `${API_ENDPOINT}/catalogs/third-party-courses/${user.person_id}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        }
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getRegistrationStatus = async (registerid) => {
    const url = `${REGISTERS_SC}/${registerid}?includeChildResults=true&includeInteractionsAndObjectives=true&includeRuntime=true&includeScore=true`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}


export const getStatus = async (courseID, userID) => {
    const url = `${REGISTERS_SC}?courseId=${courseID}&learnerId=${userID}`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}

//SCORM CLOUD CALLS
export const getCourses = async () => {
    const url = `${CURSES_SC}`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Basic ${basic_token}`
        }
    };
    
    const response = await fetch(url, requestOptions);

    const data = await response.json();
    
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getRegister = async (learnedId, courseId) => {
    const url = `${REGISTERS_SC}?learnerId=${learnedId}&courseId=${courseId}`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Basic ${basic_token}`
        }
    };
    
    const response = await fetch(url, requestOptions);

    const data = await response.json();
    
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getEnrolledCourses = async (personId, type) => {
    const url = `${API_ENDPOINT}/catalogs/enrolled-courses/${personId}/${type}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        }
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}
export const searchThirdPartyCourse = async (value) => {
    const url = `${API_ENDPOINT}/catalogs/third-party-courses/search/${value.replace(/\s/g, '+')}`;

    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getCoursesByInterest = async (personId) => {
    const url = `${API_ENDPOINT}/catalogs/courses-by-interest/${personId}`;

    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}
export const getAllCourses = async (queryStringParameters = '') => {
    const url = `${API_ENDPOINT}/catalogs/third-party-courses${queryStringParameters}`;

    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getImportJobStatus = async (jobID) => {
    const url = `${CURSES_SC}/importJobs/${jobID}`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        }
    };
    
    const response = await fetch(url, requestOptions);

    const data = await response.json();
    
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getLaunch = async (registrationId) => {
    const url = `${REGISTERS_SC}/${registrationId}/launchLink`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "redirectOnExitUrl": "http://localhost:3000/"
        }),
    };
    
    const response = await fetch(url, requestOptions);

    const data = await response.json();
    
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const setRegister = async (courseId, learnedId, firstName, lastName, email) => {
    const url = `${REGISTERS_SC}`;
    const basic_token = getAuthorization();
    const registrationId = uuidv4(); 
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "courseId": courseId,
            "learner": {
                "id": learnedId,
                "email": email,
                "firstName": firstName,
                "lastName": lastName
            },
            "registrationId": registrationId
        }),
        headers: {
            'Content-Type': "application/json",
            'Authorization': `Basic ${basic_token}`
        },
    };

    const response = await fetch(url, requestOptions);
    // const data = await response.statusText;

    if (!response.ok) {
        // get error message from body or default to response statusText
        // const error = data || response.statusText;
        return Promise.reject(false);
    }

    return true;
};

export const setCourseApiRollupRegistrationPostBackUrl = async (registrationId) => {
    const url = `${REGISTERS_SC}/${registrationId}/configuration`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "settings": [
                { 
                    "settingId": "ApiRollupRegistrationPostBackUrl", 
                    "value": "full",
                    "explicit": false
                }
            ]
        }),
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        }
    };
    
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = response.statusText;
        return Promise.reject(error);
    }

    return response;
}

export const setCourseVersionConfiguration = async (courseId) => {
    const url = `${CURSES_SC}/${courseId}/configuration`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "settings": [
                { 
                    "settingId": "WhenToRestartRegistration", 
                    "value": "WHEN_EXISTING_REG_IS_COMPLETE_AND_NEWER_PACKAGE_VERSION_EXISTS", 
                    "explicit": false 
                }
            ]
        }),
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        }
    };
    
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = response.statusText;
        return Promise.reject(error);
    }

    return response;
}

export const setPlayerScoLaunchType = async (courseId) => {
    const url = `${CURSES_SC}/${courseId}/configuration`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "settings": [
                { 
                    "settingId": "PlayerScoLaunchType", 
                    "value": "FRAMESET", 
                    "explicit": false 
                }
            ]
        }),
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        }
    };
    
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = response.statusText;
        return Promise.reject(error);
    }

    return response;
}

export const setPlayerLaunchType = async (courseId) => {
    const url = `${CURSES_SC}/${courseId}/configuration`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "settings": [
                { 
                    "settingId": "PlayerLaunchType", 
                    "value": "FRAMESET", 
                    "explicit": false 
                }
            ]
        }),
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        }
    };
    
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = response.statusText;
        return Promise.reject(error);
    }

    return response;
}

export const setProgressUpdateConfiguration = async (courseId) => {
    const url = `${CURSES_SC}/${courseId}/configuration`;
    const basic_token = getAuthorization();
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "settings": [
                { 
                    "settingId": "ApiRollupRegistrationPostBackUrl", 
                    "value": `${MARKET_PLACE_PATH}/courses/progress/update`, 
                    "explicit": false 
                }
            ]
        }),
        headers: {
            'Authorization': `Basic ${basic_token}`,
            "Content-Type": "application/json"
        }
    };
    
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = response.statusText;
        return Promise.reject(error);
    }

    return response;
}

export const uploadCourseFile = async (file) => {
    const uuidRegister = uuidv4();
    const url = `${CURSES_SC}/importJobs/upload?courseId=${uuidRegister}&mayCreateNewVersion=false`;
    const basic_token = getAuthorization();

    const formData = new FormData();
    formData.append('file', file)
    
    const requestOptions = {
        body: formData,
        method: 'POST',
        headers: {
            'Authorization': `Basic ${basic_token}`,
            'uploadedContentType': 'application/zip',
            'Accept': '*/*',
        },
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
};

export const updateCourseFile = async (file, courseid) => {
    const url = `${CURSES_SC}/importJobs/upload?courseId=${courseid}&mayCreateNewVersion=true`;
    const basic_token = getAuthorization();

    const formData = new FormData();
    formData.append('file', file)
    
    const requestOptions = {
        body: formData,
        method: 'POST',
        headers: {
            'Authorization': `Basic ${basic_token}`,
            'uploadedContentType': 'application/zip',
            'Accept': '*/*',
        },
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
};

const getAuthorization = () => {
    return Buffer.from(APP_ID + ":" + APP_SECRET).toString('base64');
};

export const getEnrolledCourseDetail = async (id) => {
    const response = await fetch(`${COURSES_PATH}/${id}`);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}