import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateUserProperty, USER_PROPERTIES } from '../../../redux/actions/userActions';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Icon, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as paths from '../../../routes/paths';

import { UserService } from '@sdk-point/talisis';
import LoaderContent from '../../../design-system/components/Loader/LoaderContent';
import { TERMINOS_CONDICIONES_ID } from '../../../shared/constants/userTerms';
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiDialog-paper':{
			maxWidth: 444,
			borderRadius: 8,
			border: `1px solid ${theme.palette.colors.fillStroke[200]}`
		}
	},
	dialogContent:{
		display: 'flex',
		flexDirection: 'column',
		gap: 24,
		padding: '32px 32px 16px',
		'& .icon-warning':{
			fontSize: 32
		}
	},
	dialogContentText:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 8
	},
	dialogActions:{
		justifyContent: 'center',
		padding: '16px 0 32px'
	},
	checkbox:{
		marginRight: 0,
		'& .MuiSvgIcon-root':{
			fontSize: 24
		},
		'& .MuiCheckbox-root':{
			padding: '0px 8px'
		}
	},
	[theme.breakpoints.down('xs')]:{
		dialogContent:{
			padding: '32px 16px 16px',
			gap: 16,
		},
		dialogContentText:{
			gap: 16
		},
		dialogActions:{
			justifyContent: 'center',
			padding: '16px 16px 32px'
		},
	}
}));

export default function AlertTemsAndConditions() {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const dispatch = useDispatch();

	const classes = useStyles();
	const { user } = useSelector((state) => state.userReducer);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [value, setValue] = React.useState(false)


	React.useEffect(()=> {
		if(!user.hasOwnProperty('term_cond')){
			getTerms();
		}

	}, []);

	const getTerms = async () =>{
		let term_cond = false;
		try {
			const response = await UserServiceSDK.getUserTerms(user.person_id);
			term_cond = await response.data.some(t => t.id_terms === TERMINOS_CONDICIONES_ID)
			if(term_cond){
				dispatch(updateUserProperty(USER_PROPERTIES.ACCEPT_TERMS_CONDITIONS, true));
			}else{
				setOpen(true);
			}
		} catch (error) {
			console.log('error', error);
		}

	}


	const handleClickAccept = async (e) => {
		if(value){
			setLoading(true);
			try {
                await UserServiceSDK.userTermsAcept({
					person_id: user.person_id,
					id_terms: 1
				});
				dispatch(updateUserProperty(USER_PROPERTIES.ACCEPT_TERMS_CONDITIONS, true));
            } catch (error) {
				console.log('error')
            }
			setOpen(false);
			setLoading(false);
		}
	};

	const handleChange = (event) => {
		setValue(event.target.checked);
	};

	return (
		<div id="alert-terms-conditions-root">
			<Dialog
				className={classes.dialog}
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<LoaderContent loading={loading}>
					<DialogContent className={classes.dialogContent}>
						<Box className={classes.dialogContentText}>
							<Icon className='ri-information-line text-warning-300 icon-warning'/>
							<Typography variant='h4'>Términos y condiciones</Typography>
							<Box textAlign='center'>
								<Typography variant='body1' className='text-dark-black-100 body-text-samll-xs'>Para continuar usando la plataforma necesitamos que leas y aceptes:</Typography>
								<Link component={RouterLink}
									className='body-text-samll-xs'
									variant='body1' 
									to={paths.TERMINOS_CONDICIONES} 
									target={'_blank'} 
									color="secondary"
									underline="always"
								>
										Términos y condiciones
								</Link>
							</Box>
						</Box>
						<FormControlLabel
							className={classes.checkbox}
							control={
							<Checkbox
								checked={value}
								onChange={handleChange}
								name="checkedB"
								color="primary"
							/>
							}
							label={<Typography variant='body1' className='semi-bold body-text-samll-xs'>He leído y acepto los Términos y condiciones</Typography>}
						/>
					</DialogContent>
					<DialogActions className={classes.dialogActions}>
						<Button onClick={handleClickAccept} variant='contained' color='primary' disabled={!value} fullWidth={isResponsive}>Continuar</Button>
					</DialogActions>
				</LoaderContent>
			</Dialog>
		</div>
	);
}
