import React, { useEffect, useState } from 'react'

import { Grid, Typography, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';

import { isValidAnswer } from './helpers/isValidAnswer';
import DynamicList from '../../../../../pages/Market/DetailedStudyPlan/DynamicList/DynamicList';

const ExpandableAnswer = ({ faqAnswer }) => {

    const [currentAnswer, setCurrentAnswer] = useState(
        {
            mainAnswer: "",
            list: [],
            caption: "",
        }
    );
    const { mainAnswer, list, caption } = currentAnswer;

    useEffect(() => {
        if( !isValidAnswer(faqAnswer) ) return;
        if(faqAnswer.length === 0 ) return;

        if(faqAnswer.length > 0){
            return setCurrentAnswer({ ...currentAnswer, mainAnswer: faqAnswer })
        }
        setCurrentAnswer({
            mainAnswer: faqAnswer?.mainAnswer,
            list: faqAnswer?.list,
            caption: faqAnswer?.caption ? faqAnswer?.caption : "",
            ...faqAnswer
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const theme = useTheme();

    return (
        <Grid container justifyContent="center" className="mt-3">
            <Grid item xs={11}>
                <Typography 
                    variant='body1' style={{ color: theme.palette.colors.gray[500]}}
                >
                    { mainAnswer }
                </Typography>

                {
                    currentAnswer.hasOwnProperty('nestedList')
                    ? <Box mt={1}>
                        {
                            currentAnswer.nestedList.map((it, index) => (
                                <div key={`nested-list-${index}`} className='mb-3'>
                                    <Typography variant='body1' className="text-gray-500 semi-bold">
                                        { it.main }
                                    </Typography>
                                    <DynamicList listArray={it.subList} customColor={ theme.palette.colors.gray[500] }/>    
                                </div>
                            ))
                        }
                    </Box>
                    :   <DynamicList listArray={list} customColor={ theme.palette.colors.gray[500] }/>
                }

                {
                    (caption.length > 1 ) &&
                    <>
                        <br />
                        <Typography 
                            variant='body1' style={{ color: theme.palette.colors.gray[500]}}
                        >
                            { caption }
                        </Typography>
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default ExpandableAnswer
