import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    /* harmon hall add-ons */
    harmonhallContainer: {
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    harmonhallContainerCard: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    harmonHallCard: {
        width: "279px",
        height: "182px",
        position: "relative",
        cursor: "pointer !important",
        background: props => `linear-gradient(238deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 68.42%), url(${props.image_url}) 149.978px 2.179px / 62.376% 98.705% no-repeat, #3F4550 !important`,
        [theme.breakpoints.down('md')]: {
            width: "310px",
        },
        [theme.breakpoints.down('sm')]: {
            width: "340px",
        },
        [theme.breakpoints.down('xs')]: {
            width: "300px",
        }
    },
    harmonHallCardHover: {
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.03)', // Efecto de escala
            boxShadow: '0 10px 20px rgba(0,0,0,0.2)', // Sombra de la tarjeta
        },
    },
    harmonHallCardFooter: {
        display: "flex",
        width: "100%",
        height: "40px",
        padding: "12px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        background: "#8B3FFA",
        position: "absolute",
        bottom: 0
    },
    harmonHallMembership: {
        display: "inline-flex",
        padding: "4px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        borderRadius: "0px 8px",
        borderLeft: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        background: "rgba(0, 0, 0, 0.60)",
        position: "absolute",
        top: 0,
        right: 0
    },
    harmonHallCardSelected: {
        border: '1px solid #ffff00 !important', // Color del borde para la tarjeta seleccionada
    },
    harmonHallCardDisabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            zIndex: 1,
        },
    },
    priceStrikeThrough: {
        color: "#FF7E00 !important",
        textDecoration: "line-through !important",
    },
    cardContentStyle: {
        height: 230,
        [theme.breakpoints.down('lg')]: {
            height: "auto",
        }
    },
    cardHarmonHallAddOn: {
        width: '1248px',
        marginTop: '32px',
        marginBottom: '12px',
        [theme.breakpoints.down('md')]: {
            width: "auto !important",
            marginTop: '8px !important',
            marginBottom: '48px !important',
        }
    }
}))