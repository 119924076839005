import palette from '../palette';

export default {
    root: {
        backgroundColor: palette.colors.fillStroke[300],
        borderRadius: '33px',
        fontSize: 16,
        color: palette.colors.grey[100],
        fontWeight: 400,
        height: '32px',
        '& .MuiChip-icon':{
            fontSize: 24,
            color: palette.colors.grey[100],
            backgroundColor: palette.colors.fillStroke[300],
        },
        '& .MuiChip-deleteIcon': {
            fontSize: 24,
            color: palette.colors.grey[100],
            backgroundColor: palette.colors.fillStroke[300],
        },
        '&.MuiChip-deletable:focus': {
            backgroundColor: palette.colors.fillStroke[300],
        }
    },
    outlined:{
        backgroundColor: palette.colors.grey[400],
        border: `0.5px solid ${palette.colors.fillStroke[100]}`,
        borderRadius: 24,
        padding: '8px 16px',
        height:32,
        '& .MuiChip-label':{
            color: palette.colors.grey[200],
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '16px'
        }
    }
};