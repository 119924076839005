import React from 'react';
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom';

// material components
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// styles
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    svgNoneDisplay:{
        display: 'none'
    },
    svg: {
        display: 'block'
    },
    navIcon:{
        position: 'relative',
    }
}));

export default function Breadcrumb({items, variant, separator = '', ...rest}) {
    const classes = useStyles();
    const lastItem = items.length - 1;

    return (
        <Box display="flex" className="mb-3" {...rest}>
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumb text-orange-300" separator={<NavigateNextIcon fontSize="small" color="inherit"/>}>
                {
                    items.map((item, index, {length}) => 
                        item.to
                        ?   <Box display={'flex'} alignItems={'center'} key={index}>
                                <Link component={RouterLink} to={item.to} color="inherit" className={length === 1 && item.to && classes.navIcon}>
                                    <Typography color="inherit" variant={variant} className="semi-bold">
                                        <span>{item.label}</span>
                                    </Typography>
                                </Link>
                                <NavigateNextIcon fontSize="small" color="inherit" className={length === 1 && item.to ? classes.svg : classes.svgNoneDisplay}/>
                            </Box>
                        :   <Typography color="inherit" 
                                variant={variant} key={index} 
                                className={clsx(length > 1 && index === lastItem && 'text-decoration-underline', 'semi-bold')}>
                                {item.label}
                            </Typography>
                    )
                }
            </Breadcrumbs>
        </Box>
    );
}

Breadcrumb.defaultProps = {
    variant: 'body2'
};

Breadcrumb.propTypes = {
    variant: PropTypes.string,
    styles: PropTypes.any,
    /**
     * Array of options
     */
    items: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string,
        label: PropTypes.string.isRequired,
        // title: PropTypes.string
    })).isRequired,
};
