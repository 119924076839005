import React, { useEffect, useState } from 'react';

//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Card, Divider, Icon, Dialog, Hidden } from '@material-ui/core';

// style
import useStyles from '../../../assets/styles/jss/pages/consultancies/applicant/MyConsultanciesStyle';
import ProfilePicUsers from '../../../shared/components/ProfilePicUsers/ProfilePicUsers';
import ManageConsultancy from './ManageConsultancy';
import InfiniteScroll from 'react-infinite-scroll-component';
import {getLogoCard} from '../../../constants/logosImages';
import {APROBADA,PENDIENTE} from '../../../shared/constants/consultancies'

import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import clsx from 'clsx';

const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
const dias_semana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

const CardRequestAdviser = (props) => {
    
    const classes = useStyles();

    const [DateSelected, setDateSelected] = useState('');
    const [ScheduleSelected, setScheduleSelected] = useState('');
    const [dateLocal, setDateLocal] = useState('');
    const [scheduleLocal, setScheduleLocal] = useState('');
    const [emailAsesor, setEmailAsesor] = useState('');
    const [emailSolicitante, setEmailSolicitante] = useState('');
    const [nombreAsesor, setNombreAsesor] = useState('');
    const [personIdSolicitante, setPersonIdSolicitante] = useState('');
    const [modalidad, setModalidad] = useState('');
    const [area_conocimiento, setArea_conocimiento] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [ubicacion, setUbicacion] = useState('');
    const [dateConvert, setDateConvert] = useState('');    
    const [ModifyConsultancy, setModifyConsultancy] = useState(false);
    const [viewAdvisers, setViewAdvisers] = useState(true);
    const [idConsultancy, setIdConsultancy] = useState(0);    
    const [ouConsultancy, setOuConsultancy] = useState('');
    const [CurrentMonth, setCurrentMonth] = useState('');
    const [idTeamsAsesoria, setIdTeamsAsesoria] = useState('');
    const [urlTeams, setUrlTeams] = useState('');
    const [actualizando, setActualizando] = useState(false);

    useEffect(() => {
    }, []);


    const convertTimeUTCtoLocal = tiempo => {
        let [scheduleA, scheduleB] = (tiempo).split(' - ')
        
        scheduleA = convertDateTo12('2022-12-27T' + scheduleA +':00.000Z')
        scheduleB =  convertDateTo12('2022-12-27T' + scheduleB +':00.000Z')
        let scheduleTransform = scheduleA + ' - ' + scheduleB;
        return scheduleTransform
    };

    const convertTime12to24 = time12h => {
        const [time, modifier] = time12h.split(" ");
       
        let [hours, minutes] = time.split(":");
        
        if (modifier == "pm" && parseInt(hours) != 12) {
          hours = parseInt(hours, 10) + 12;
        }
       
        return `${hours}:${minutes}`;
    };

    const handleSetConsultancies = (consultancies) =>{ 
        window.scrollTo(0, 0);
        setModifyConsultancy(true);
        setEmailSolicitante(consultancies.email_solicitante)
        setEmailAsesor(consultancies.email_asesor);
        setNombreAsesor(consultancies.nombre_asesor);
        setPersonIdSolicitante(consultancies.person_id);
        setDateSelected(consultancies.fecha_asesoria.length > 9 ? (consultancies.fecha_asesoria).slice(0,10) : consultancies.fecha_asesoria);
        setScheduleSelected(consultancies.tiempo_asesoria);
        setDateLocal(consultancies.fechaLocal);
        setScheduleLocal(consultancies.horaLocal);
        setModalidad(consultancies.modalidad);
        setArea_conocimiento(consultancies.area_conocimiento);
        setDescripcion(consultancies.descripcion);
        setUbicacion(consultancies.ubicacion);
        setDateConvert(dias_semana[new Date(((consultancies.fechaLocal).slice(0,10))+ ' 00:00:00').getDay()] + ' ' + new Date(((consultancies.fechaLocal).slice(0,10))+ ' 00:00:00').getDate() + ' de ' + meses[new Date(((consultancies.fechaLocal).slice(0,10))+ ' 00:00:00').getMonth()]);
        setIdConsultancy(consultancies.id);
        setOuConsultancy(consultancies.ou);
        setIdTeamsAsesoria(consultancies.id_teams_meet);
        setUrlTeams(consultancies.url_teams_meet);
    }

    const handleButtonConsultancies = (consultancies, index) => {
        let dateNow = new Date();
        let dateLocal = convertTime12to24(consultancies.horaLocal)
        let dateConsultancy = new Date(((consultancies.fechaLocal).slice(0,10)) + ' ' + convertTime12to24(dateLocal.slice(0,8)) + ':00');
        let tiempoStart = dateConsultancy.getTime()
        const tiempoMenosMinutos = tiempoStart - 10 * 60 * 1000;
        const tiempoMasMinutos = tiempoStart + 45 * 60 * 1000;
        if(dateNow.getTime() >= tiempoMenosMinutos && dateNow.getTime() <= tiempoMasMinutos && consultancies.status_asesoria === 'APROBADA'){
            return <Button 
                    variant='contained'
                    color="primary"
                    label="Entrar"
                    className={classes.buttonConsultancies}
                    onClick={() => {window.open(consultancies.url_teams_meet)}}
                    >   
                        <Typography>Entrar</Typography>
                    </Button>
        }else if (dateNow.getTime() > tiempoMasMinutos && consultancies.status_asesoria === 'APROBADA'){
            return <Button 
            variant='outlined'
            color="primary"
            disabled={true}
            label="Terminó"
            className={classes.buttonConsultancies}
            >   
                <Typography>Terminó</Typography>
            </Button> 
        }else{
                return <Button 
                        variant='outlined'
                        color="primary"
                        label="Ver detalle"
                        className={classes.buttonConsultancies}
                        onClick={() => {handleSetConsultancies(consultancies)}}
                        >   
                            <Typography>Ver detalle</Typography>
                        </Button>
            }


    }

    const convertDateTo12 = (datex) => {
        let hoursStart = '';
        let minutesStart = '';
        let AmOrPmStart = '';
        let dateFinalStart = '';

        let date = new Date(datex)
        hoursStart = date.getHours();
        minutesStart = date.getMinutes() == 0 ? '00' : date.getMinutes();
        AmOrPmStart = hoursStart >= 12 ? 'pm' : 'am';
        hoursStart = (hoursStart % 12) || 12;
        hoursStart = date.getHours() < 10 || date.getHours() > 12  ?  '0'+hoursStart: hoursStart;
        
        dateFinalStart = minutesStart != '' ? hoursStart + ":" + minutesStart + " " + AmOrPmStart : hoursStart + " " + AmOrPmStart;

        return dateFinalStart
    }

    const handleclickActualizar = () =>{
        setActualizando(true)
        props.findConsultancies()
        setTimeout(() => {
            setActualizando(false)
        }, 2000);
    }

    const handleRegresarModify = () => setModifyConsultancy(false);

    return (
        <Box mt="24px">
            <Box className={classes.boxSolicitudesTittles}>
                <Hidden smDown>
                    <Typography variant='h2'>Solicitudes de asesorías</Typography>     
                </Hidden>                               
                <Button
                    className={clsx("mr-2",classes.buttonActualizar)}
                    startIcon={<Icon className={clsx("ri-refresh-line font-size-24 text-gray-100", actualizando && classes.refreshIcon)} />}
                    onClick={()=>{handleclickActualizar()}}
                >
                    <Typography className='semi-bold'>Actualizar</Typography>
                </Button>
            </Box>
            {
                props.DataConsultancies.length > 0 ?
                    <Box>                 
                        {
                            <InfiniteScroll
                            dataLength={props.DataConsultancies.length}
                            >
                                <Box className={classes.boxRoot}>
                                { 
                                    props.DataConsultancies.map((consultancies, index) => (
                                        <Card  key={consultancies.id} variant='outlined' className={`${classes.card} mr-4`}>
                                            <Box className={classes.boxDataTimes}>
                                                <Box className={classes.boxDataTimesSecond}>
                                                    <Icon className={"ri-calendar-line font-size-16 text-electric-blue-200"} />
                                                    <Typography variant='body1' className='semi-bold'>{(dias_semana[new Date(((consultancies?.fechaLocal).slice(0,10))+' 00:00:00').getDay()] + ' ' + 
                                                    new Date(((consultancies.fechaLocal).slice(0,10))+' 00:00:00').getDate() + ' de ' + meses[new Date(((consultancies.fechaLocal).slice(0,10))+' 00:00:00').getMonth()])}</Typography>
                                                </Box>
                                                <Box className={classes.boxDataTimesSecond}>
                                                    <Icon className={"ri-time-line font-size-16 text-electric-blue-200"}/>
                                                    <Typography variant='body1' className='semi-bold'>{(consultancies.horaLocal)}</Typography>
                                                </Box>
                                            </Box>
                                                <Box className={classes.boxStatus}>
                                                    <Icon className={clsx("ri-checkbox-blank-circle-fill font-size-10", consultancies.status_asesoria === APROBADA ? "text-success-300" : consultancies.status_asesoria === PENDIENTE && "text-warning-300")}/>
                                                    <Typography className="variant-subheading-3" >{consultancies.status_asesoria === APROBADA ? "Solicitud aprobada" : consultancies.status_asesoria === PENDIENTE && "Solicitud en espera"}</Typography>
                                                </Box>
                                                <Divider className={classes.dividerStyle} />
                                            <Box className={classes.boxAsesorData}>
                                                <ProfilePicUsers 
                                                    key={consultancies.id} 
                                                    email={consultancies.email_solicitante} 
                                                    className={classes.profilePic}
                                                />
                                                <Typography variant='body1' className='semibold'>{consultancies.solicitante}</Typography>
                                            </Box>
                                            <Box className={classes.boxLogoOu}>
                                                <img alt="logo" className={classes.logoStyle} src={ getLogoCard(7) }/>
                                            </Box>
                                            <Box className={classes.boxDataSession}>
                                                <Box className={classes.boxSessionSpecific}>
                                                    <Icon className={"ri-map-pin-2-fill font-size-16 text-fill-stroke-100"} />
                                                    <Typography variant='caption'>{consultancies.modalidad}</Typography>
                                                </Box>
                                                <Box className={classes.boxSessionSpecific}>
                                                    <Icon className={"ri-book-2-fill font-size-16 text-fill-stroke-100"} />
                                                    <Typography variant='caption'>{consultancies.area_conocimiento}</Typography>
                                                </Box>
                                            </Box>
                                            
                                            <Box className={classes.boxButtonStyle}>
                                                {handleButtonConsultancies(consultancies, index)}
                                            </Box>
                                        </Card> 
                                    )
                                )}
                                </Box>
                            </InfiniteScroll>
                        }
                                <Dialog classes={{paperWidthSm: classes.paperDialogStyle}} open={ModifyConsultancy} onClose={handleRegresarModify}>
                                    <ManageConsultancy
                                        DateSelected={DateSelected} 
                                        ScheduleSelected={ScheduleSelected}
                                        dateLocal={dateLocal} 
                                        scheduleLocal={scheduleLocal}
                                        emailAsesor={emailAsesor}
                                        emailSolicitante={emailSolicitante}
                                        nombreAsesor={nombreAsesor}
                                        personIdSolicitante={personIdSolicitante}
                                        dateConvert={dateConvert}
                                        modalidad={modalidad}
                                        area_conocimiento = {area_conocimiento}
                                        descripcion = {descripcion}
                                        ubicacion = {ubicacion}
                                        ouConsultancy = {ouConsultancy}
                                        idConsultancy = {idConsultancy}
                                        findConsultancies={props.findConsultancies}
                                        idTeamsAsesoria = {idTeamsAsesoria}
                                        viewAdvisers = {viewAdvisers}
                                        setModifyConsultancy = {setModifyConsultancy}
                                    />
                            </Dialog>
                    </Box>
                :
                <Box className={classes.nothingFound}>
                        <NothingFound caption="No tienes solicitudes agendadas" subcaption=" " type="misasesorias" />
                </Box>
            }
        </Box>
    );
};

export default CardRequestAdviser;