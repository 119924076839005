import * as menuTypes from '../types/menuTypes'
import * as routerlinks from '../../routes/routelinks'

const INITIAL_STATE = {
	menu: routerlinks.routes.filter(route => route.isDefault)
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case menuTypes.GET_MENU:
      return { ...state};
    case menuTypes.APPLY_MENU:
      return { ...state, menu: action.payload };
    default:
      return state;
  }
};