import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    dialog:{
        //width: '536px',
        '& .MuiPaper-rounded':{
            borderRadius: '8px'
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '536px'
        }
    },
    brand: {
        fontSize: '24px',
        fontWeight: '700',
        paddingBottom: '16px',
    },
    price: {
        fontSize: '24px',
        fontWeight: '700',
        paddingTop: '4px',
        paddingBottom: '4px',
        color: `${theme.palette.colors.orange[300]}`
    },
    favoriteButton: {
        marginRight: "10px",
        opacity: "0",
        pointerEvents: "none"
    },
    markerButton: {
        // backgroundColor: theme.palette.colors.grey[400],
        backgroundColor: theme.palette.colors.electricBlue[300],
        fontWeight: "bold",
        flexGrow: 1,
        fontSize: "13px",
        minWidth: '20px',
        padding: "0px 5px",
        marginLeft: "7px",
        // "&:hover span svg":
        // {
        //     color: "#2C3236"
        // }
    },
    cardContent: {
        // padding: "1.5rem !important",
        position: 'relative',
        // paddingTop: "0px !important"
    },
    button: {
        background: theme.palette.colors.orange[100],
        fontSize: 11,
        color: theme.palette.colors.white,
        padding: "2px 0px",
        lineHeight: '16px',
        marginLeft: 0,
        borderRadius: 2
    },
    // cardBorder: {
    //     border: "0.5px solid #2C3236"
    // },
    subscribe:{
        borderRadius: '8px',
        fontWeight: '400',
        fontSize: '14px',
        height: '40px',
        lineHeight: '40px',
        padding: "0px",
    },
    saveIcon:{
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "24px",
        maxWidth: "24px",
        height: "24px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        marginTop: "auto",
        marginBottom: "auto",
        '&:hover':{
            backgroundColor: '#99999996 !important',
            cursor: "pointer",
        },
    },
    lockButton:{
        padding: '8px 14px', 
        backgroundColor:'black',
        borderRadius:'8px',
        color: `${theme.palette.colors.fillStroke[100]}`
    },
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingTop: '58px',
        paddingLeft: '104px',
        paddingRight: '107.14px',
        border: '0.5px solid #404150',
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 0, 0, 0.9)'
    },
    modalInside:{
        backgroundColor:`${theme.palette.colors.fillStroke[400]}`,
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    starIcon: {
        width: '20px',
        height: '19px',
        fontSize: '13px',
        marginRight:'9px',
        background: 'linear-gradient(273.67deg, #FCDC43 -55.56%, #FFA700 71.54%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },
    arrowIcon:{
        fontSize: '9px'
    },
    rootLoader:{
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '100px',
        }
    },
    cardClass:{
        background: theme.palette.colors.fillStroke[400],
        padding: 8
    },
    marginFAQ:{
        marginLeft: '3%',
        [theme.breakpoints.down('xs')]:{
            marginLeft: '0px !important',
        }
    },
    cardFAQ:{
        marginBottom: 62,
        [theme.breakpoints.down('xs')]:{
            margin: '0px 0px 50px 0px !important'
        }
    },
    container:{
        [theme.breakpoints.up('md')]:{
            marginTop: '-64px'
        }
    },
    saveBttn: {
        padding: 0,
        color: theme.palette.colors.darkBlack[200]
    },
    dialogTitle:{
        paddingBottom: 0
    },
    dialogTitleTypography: {
        color: theme.palette.colors.gray[200],
        
    },
    dialogContent: {
        padding: '16px 24px 24px 24px'
    },
    gridClose: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    iconClose:{
        fontSize:'24px',
        color: theme.palette.colors.darkBlack[200], 
        cursor:'pointer'
    },
    containerTitle:{
        justifyContent: 'space-between'
    },
    containerFixedSubscribeButton: {
        position: 'fixed',
        bottom: '58px',
        zIndex: 5,
        width: "100%",
    }
}));