import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import CurrencyFormat from 'react-currency-format'

const MonthlyPayments = ({months, orderQty}) => {
    return (
        <Grid container className='mt-2 px-2' justifyContent="space-between">
            <Grid item>
                <Typography variant="caption" className="text-gray-100">
                    Pago por mes:
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption" className="text-gray-100">
                    <CurrencyFormat
                        value={(orderQty?.order_total/months)} className="text-gray-100"
                        decimalScale={2} fixedDecimalScale={true} displayType="text" 
                        thousandSeparator={true} prefix="$" suffix=' MXN'
                    />
                    {` x ${months}`}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default MonthlyPayments
