import React from 'react'

import { Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core'
import ReadMoreLine from '../../../../shared/components/ReadMore/ReadMoreLine';

const useStyles = makeStyles((theme) => ({
    cardContent:{
        padding: '24px 32px'
    },
    textColor:{
        color: theme.palette.colors.gray[400],
    },
    marginCards:{
        marginTop: 32,
        [theme.breakpoints.down('xs')]:{
            marginTop: 12
        }
    },
    [theme.breakpoints.down('xs')]:{
        cardContent:{
            padding: 16
        },
        textColor:{
            fontSize: 14
        }
    }
}));

const StudyPlanTextCard = ({ title, textParagraph, components }) => {

    const classes = useStyles()

    return (
        <Grid container justifyContent="center" className={classes.marginCards}>
            <Grid item md={12} xs={12}>
                <Card>
                    <CardContent className={classes.cardContent}>
                        <Typography variant='h4' className='mb-3'>
                            { title }
                        </Typography>
                        <Grid container justifyContent="space-between" alignItems="flex-start" className='mt-2'>
                            <ReadMoreLine 
                                line={6}
                                text={
                                <Typography variant='body1' className={classes.textColor} >
                                    { textParagraph }
                                </Typography>
                                }
                            />
                            { 
                                components !== undefined &&
                                <Grid item xs={12} className="mt-3">
                                    { components }                                
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default StudyPlanTextCard
