import React from 'react';
import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    borderDiscount: {
        // borderTop: '2px solid',
        // borderImage: 'linear-gradient(to left, rgba(252, 220, 67, 1), rgba(255, 167, 0, 1)) 1',
        // [theme.breakpoints.down('xs')]: {
        //     padding: '8px 12px',
        // }
    }
}));


const Price = ({prices}) => {
    const classes = useStyles();
    const [percentage, setPercentage] = React.useState("")

    React.useEffect(()=> {
        if(prices.current && prices.original && (prices.current < prices.original)){
            const _percentage = Math.round((prices.current * 100)/prices.original);
            setPercentage(_percentage);
        }
    }, [prices])

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end" gridGap={10} width={1} className={percentage && classes.borderDiscount}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gridGap={8} width={1}>
                <Box flex="none" height={16}>
                    {percentage && <Typography variant="caption" className="semi-bold text-error-300">{percentage}% de Dto.</Typography>}
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-start" gridGap={8} flex="none" height={percentage ? 15 : 16 }>
                    {
                        percentage &&
                        <Typography variant="body2" className="text-dark-black-200 text-decoration-line-through">
                            <CurrencyFormat value={prices.original} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />
                        </Typography>
                    }
                    <Typography variant="h5" color="secondary">
                        <CurrencyFormat value={prices.current} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Price;