import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { FormControl, FormControlLabel, Hidden, Icon, IconButton, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import palette from '../../../design-system/theme/palette';
import { CheckCircleRounded, ChevronLeft, ChevronRight, ArrowBack} from '@material-ui/icons';
import Loader from '../../../shared/components/Loader/Loader';
// style
import useStyles from '../../../assets/styles/jss/pages/consultancies/applicant/CalendarConsultanciesStyle';
//SDK
import { ConsultanciesService } from '@sdk-point/talisis'; 
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { log } from '../../../shared/utils/console.js'

import { eventTracker, eventData } from '../../../shared/components/EventTracker/EventTracker';

const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


let newDate = '';
let daysOfMonth = [];
let firstDay = '';
let dateref = new Date();
const CalendarConsultancies = (props) => {
    
    const [CurrentMonth, setCurrentMonth] = useState('');
    const [DaysOfMonthArr, setDaysOfMonthArr] = useState([]);
    const [DaysInactiveArr, setDaysInactiveArr] = useState([]);
    /*const [CurrentDay, setCurrentDay] = useState(new Date());
    const [date, setdate] = useState(new Date());
    const [dateMax, setdateMax] = useState(new Date());*/
    const [SelectedDay, setSelectedDay] = useState('');    
    const [value, setValue] = useState('');    
    const [active,setActive]=useState(-1);
    const [dataHorarios, setDataHorarios] = useState([]);  
    const [dataHorariosUTC, setDataHorariosUTC] = useState([]);
    const [scheduleCalendar, setScheduleCalendar] = useState('');    
    const [todayCompare, setTodayCompare] = useState(0);
    const [noDataSchedule, setNoDataSchedule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [scheduleDateSelected, setScheduleDateSelected] = useState('');
    
    const classes = useStyles();

    useEffect(() => {
        newDate = new Date();
        var today = new Date();
        today = today.setHours(0,0,0,0);
        setTodayCompare(today)
        setCurrentMonth(newDate.getMonth());
        firstDay = new Date(dateref.getFullYear(), dateref.getMonth(), 1);
        //setdate(newDate.getDate());
        //setdateMax(new Date(newDate.setMonth(newDate.getMonth()+4)));
        /*
        daysOfMonth = getDaysOfMonth()
        daysOfMonth.map(function(day){
            DaysOfMonthArr.push(day);
        })*/
        dateref = new Date()
        setDaysOfMonthArr(() => getDaysOfMonth(new Date()))
    }, []);
    
    const months = {
        0 : 'Enero',
        1 : 'Febrero',
        2 : 'Marzo',
        3 : 'Abril',
        4 : 'Mayo',
        5 : 'Junio',
        6 : 'Julio',
        7 : 'Agosto',
        8 : 'Septiembre',
        9 : 'Octubre',
        10 : 'Noviembre',
        11 : 'Diciembre',
    };
    const diasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const days = ['D','L','M','M','J','V','S'];

    const getDaysOfMonth = (date_change) =>{
        let daysOfMonth = new Array();
        var today = new Date()
        var lastDayOfMonth;
        if(date_change === undefined){
            lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0)
        }else{
            lastDayOfMonth = new Date(date_change.getFullYear(), date_change.getMonth()+1, 0);
            firstDay = new Date(date_change.getFullYear(), date_change.getMonth(), 1);
        }
        if(firstDay.getDay() > 0){
            for(var i=0; i < firstDay.getDay(); i++){
                daysOfMonth.push('');
            }
        }
        DaysInactiveArr.length = 0;
        for(var j=1; j <= lastDayOfMonth.getDate(); j++){
            if(validateInactiveDays(j)){DaysInactiveArr.push(j)}
            daysOfMonth.push(j);
        }
        return daysOfMonth;
    }

    const validateInactiveDays = (day) => {
        //Regresara true si es inactivo
        let result = false;
        let date = '';
        
        date = new Date(dateref.getFullYear(),dateref.getMonth(), day);
        date = date.getDay();

        if(date == 0 || date == 6){
            result = true;
        };
        return result;
    }

    const changeWeekPeriod = (direction) => {
        var data_change;
        if (direction == 'left' && CurrentMonth != 0){
            data_change = (new Date(dateref.setMonth(dateref.getMonth()-1)))
            setCurrentMonth(CurrentMonth - 1)
        }else if(direction == 'right' && CurrentMonth != 11){
            data_change = (new Date(dateref.setMonth(dateref.getMonth()+1)))
            setCurrentMonth(CurrentMonth + 1)
        }else if(direction == 'left' && CurrentMonth == 0){
            data_change = (new Date(dateref.setMonth(dateref.getMonth()+11)))
            data_change = (new Date(dateref.setFullYear(dateref.getFullYear()-1)))
            setCurrentMonth(CurrentMonth + 11)
        }else{
            data_change = (new Date(dateref.setMonth(dateref.getMonth()-11)))
            data_change = (new Date(dateref.setFullYear(dateref.getFullYear()+1)))
            setCurrentMonth(CurrentMonth - 11)
        }
        
        setDaysOfMonthArr(() => getDaysOfMonth(data_change));
    }

    function convertirHora(cadenaHora) {
        // Separar las horas y los minutos
        const partesHora = cadenaHora.split(':');
        let horas = parseInt(partesHora[0]);
        let minutos = partesHora[1];
      
        // Verificar si las horas tienen un solo dígito
        if (horas < 10) {
          horas = '0' + horas;
        }
      
        // Verificar si los minutos tienen un solo dígito
        if (minutos.length < 2) {
          minutos = minutos.padStart(2, '0');
        }
      
        // Unir las horas y los minutos en el formato deseado
        return horas + ':' + minutos;
      }

    const convertTime12to24 = time12h => {
        const [time, modifier] = time12h.split(" ");
       
        let [hours, minutes] = time.split(":");
        
        if (modifier == "pm" && parseInt(hours) != 12) {
          hours = parseInt(hours, 10) + 12;
        }
       
        return `${hours}:${minutes}`;
    };

    const handleClickSelectDay = (day) => {
        var date_select = new Date(dateref.getFullYear(),CurrentMonth, day.day);
        const year = date_select.getFullYear();
        const month = String(date_select.getMonth() + 1).padStart(2, '0');
        const day_digits = String(date_select.getDate()).padStart(2, '0');
        const joined = [year,month,day_digits].join('-');
        setDataHorarios('')
        setLoading(true)
        let arrHorarios = [];
        if(date_select.getTime() >= todayCompare){
            date_select = [date_select.getFullYear(), date_select.getMonth()+1, date_select.getDate()].join('-');
            let dateFind1 = new Date(formatDate(date_select)+'T07:00:00');
            let dateFind2 = new Date(formatDate(date_select)+'T22:00:00');
            dateFind1 = moment.utc(dateFind1)
            dateFind2 = moment.utc(dateFind2)
            var emails = [];
            emails.push(props.emailAsesor);
            ConsultanciesServiceSDK.getMeetingTimes({
                email: emails,
                date_event_start: dateFind1,
                date_event_end: dateFind2}).then
                (function(x){
                    if(x.length !== 0){
                        setDataHorariosUTC(x);
                        x.map(function(fecha){
                            let rule24 = false;
                            let [data1, data2] = fecha.split(' - ');
                            data1 = convertDateTo12(data1)
                            data2 = convertDateTo12(data2)
                            let fechaConCero = formatDate(date_select)
                            data1 = convertirHora(data1)
                            fechaConCero = (fechaConCero + 'T' + (convertTime12to24(data1) + ':00').trim())
                            const fechaComparar = new Date(fechaConCero);
                            let dateNow = new Date();
                            const differenceInMilliseconds = fechaComparar - dateNow;
                            const differenceInHours = differenceInMilliseconds / 1000 / 60 / 60;
                            if(differenceInHours < 24){
                                rule24 = true
                            }
                            const elementosFiltrados = (props.DataConsultancies).filter((elemento) => {
                                return (elemento.person_id_asesor === props.personIdAsesor && elemento.fechaLocal === fechaConCero);
                            });

                            if (elementosFiltrados.length <= 0 && !rule24) {
                                arrHorarios.push(data1 + ' - ' + data2)
                            }
                        })
                        arrHorarios.length > 0 ? setDataHorarios(arrHorarios) : setNoDataSchedule(true)
                    }else{
                        setNoDataSchedule(true);
                    }
                    setLoading(false)
            }).catch(e => {
                log(e)
                setNoDataSchedule(true);
            });

            if(date_select != ''){
                setScheduleDateSelected(handleSelectedDay(date_select))
                props.handleConvertToTextDate(date_select)
                setSelectedDay(date_select);
                props.setDateSelected(joined); 
            }
        }
    }

    const formatDate = (date) => {
    let dateFormated = date.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, (_, anio, mes, dia) => {
            return `${anio}-${mes.padStart(2, "0")}-${dia.padStart(2, "0")}`;
        });
    return dateFormated
    }
    const handleClickSelectSchedule = (el,index) => {
        setActive(index)
        setScheduleCalendar(el); 
    }

    const handleConfirmSchedule = () => {
        eventTracker('s_detalles_asesoria', eventData, ['mixpanel']);
        if(scheduleCalendar != ''){
            props.setScheduleSelected(scheduleCalendar)

            !props.setModifyingOnHorarios &&
            props.setDescriptionOn(true)
        }
        if(props.setModifyingOnHorarios){
            props.setModifyingOnHorarios(false)
            props.setModifyingOn(true)
        }
    }
    
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    
    const convertDateTo12 = (datex) => {
        const date = new Date(datex);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    }

    const handleSelectedDay = (date) =>{
        const dateFormatted = formatDate(date)
        const fecha = new Date(dateFormatted + "T07:00:00");
        const diaSemana = diasSemana[fecha.getDay()];
        const dia = fecha.getDate();
        const mes = months[fecha.getMonth()];
        //const anio = fecha.getFullYear();
        const cadenaFecha = `${diaSemana} ${dia} de ${mes}`;
        return cadenaFecha
    }

    return (
        <Box className={`${ SelectedDay != '' ? classes.calendarContainerSche : ''}`}>
            {
                SelectedDay == '' ?
                <Box className={classes.calendarContainer}>
                    <Box className={classes.closeWindow}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.handleClickRegresar}
                        >
                            <Icon className="ri-close-fill" style={{color: palette.colors.fillStroke[100]}}/>
                        </IconButton>
                    </Box>
                    <Box className={classes.boxTittles}>
                        <Typography variant='h4' style={{marginBottom: '8px'}}>Calendario de asesorias</Typography>
                        <Typography variant='caption'>Selecciona el día de tu interes para agendar.</Typography>
                    </Box>
                    <Box className={classes.boxMonthsControl}>
                        <Button onClick={() => { changeWeekPeriod('left') }} className={classes.arrowLeft}>
                            <Icon className='ri-arrow-drop-left-line font-size-24' />
                        </Button>
                        <Typography>{months[CurrentMonth]}</Typography>
                        <Button onClick={() => { changeWeekPeriod('right') }} className={classes.arrowRight}>
                            <Icon className='ri-arrow-drop-right-line font-size-24' />
                        </Button>
                    </Box>
                    
                    <Box className={classes.boxCalendarContain}>
                        <Box className={classes.boxDaysPeerWeek}>
                            {days.map((day,i) => (
                                <Box className={classes.boxDaysPeerMonth}>
                                    <Typography key={i} variant='caption' className='semi-bold'>{day}</Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box className={classes.boxDaysContainMonth}>
                            { 
                                DaysOfMonthArr.map((day,i) => (
                                    day != '' ?
                                        <Box key={i} className={classes.boxDaysPeerMonth}>
                                            <Button 
                                                disabled={DaysInactiveArr.find(dayInac => dayInac == day) > 0 || (new Date(dateref.getFullYear(),dateref.getMonth(), day)).getTime() < todayCompare+1 ? true:false}  
                                                key={i} variant='outlined' 
                                                onClick={() => {handleClickSelectDay({day})}} 
                                                className={classes.buttonDaysPeerMonth}>
                                                <Typography style={{color: DaysInactiveArr.find(dayInac => dayInac == day) > 0 || (new Date(dateref.getFullYear(),dateref.getMonth(), day)).getTime() < todayCompare+1 ? palette.colors.darkBlack[200]:palette.colors.white}} 
                                                variant='body2' className='semi-bold'>{day}</Typography>
                                            </Button>    
                                        </Box>
                                    :
                                    <Box key={i} className={classes.boxDaysPeerMonth}>
                                        <Typography variant='body2' className='semi-bold'>{day}</Typography>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
            :
            <LoaderContent loading={loading} transparent={true}>
                <Box style={{display: 'flex',flexDirection: 'column', position: 'relative'}}>
                    <Hidden mdDown>
                        <Box className={classes.closeWindow}>                    
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={props.handleClickRegresar}
                            >
                                <Icon className="ri-close-fill" style={{color: palette.colors.fillStroke[100]}}/>
                            </IconButton>
                        </Box>
                    </Hidden>
                    <Box className={classes.boxTittles}>
                        <Typography variant='h4' style={{marginBottom: '8px'}}>Seleccionar Horario</Typography>
                        <Typography variant='caption'>Selecciona el horario de tu interes para agendar.</Typography>
                    </Box>
                    
                    <Box className={classes.boxScheduleResume}>
                            <Button onClick={()=>{setSelectedDay('')}}>
                                <ArrowBack style={{borderRadius: '24px', width: '30px', height: '30px',}}/>
                            </Button>
                            <Typography variant='h4'>{scheduleDateSelected}</Typography>
                    </Box>

                    {/*
                        <Box className={classes.boxScheduleResume}>
                            <Box className={classes.boxScheduleCalendar}>
                                {days.map((day,i) => (
                                    <Typography key={i}>{day}</Typography>
                                ))}
                            </Box>
                        </Box>
                    */}
                    <Box className={classes.boxSchedule}>
                    <FormControl component="fieldset" className={classes.controlRadio}>
                        <RadioGroup className={classes.radioGroup} aria-label="schedule" name="schedule" value={value} onChange={handleChange}>
                            {
                                dataHorarios != '' ? 
                                dataHorarios.map((el, index) => (
                                    <FormControlLabel key={index} className={ active == index ? classes.labelRadioCheck : classes.labelRadio} id={index} value={el} control=
                                    {<Radio onClick={()=> handleClickSelectSchedule(el, index)} checkedIcon={<CheckCircleRounded style={{color: palette.colors.fillStroke[400], width: '13.33px', height: '13.33px'}} />} style={{
                                            color: palette.colors.fillStroke[100]
                                        }} />} 
                                    label={<Typography variant='body2' style={{color: active == index ? palette.colors.fillStroke[400] : ''}} className='semi-bold'>{el}</Typography>} />
                                ))
                                : noDataSchedule &&
                                <Box className={classes.nothingFound}>
                                    <NothingFound caption=" " subcaption="No hay espacios disponibles en el día seleccionado, intenta elegir otro día." type="misasesorias" width='100px' />
                                </Box>
                            }
                        </RadioGroup>
                    </FormControl>
                    </Box>
                    <Box className={classes.boxSig}>
                        <Button 
                        variant='contained'
                        color="primary"
                        label="Primario"
                        className={classes.buttonSig}
                        onClick={() => {handleConfirmSchedule()}}
                        >
                            <Typography variant='body2' className='semi-bold'>Siguiente</Typography>
                        </Button>
                    </Box>
                </Box>
            </LoaderContent>
            }
        </Box>
    );
};

export default CalendarConsultancies;