import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// @redux
import { connect } from 'react-redux';

// design-system
import Breadcrumb from '../../../design-system/components/Breadcrumb/Breadcrumb';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import LoaderContent from '../../../design-system/components/Loader/LoaderContent';
import Toast from '../../../design-system/components/Toast/Toast';

// @material-core
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//@ThirtyParty
import queryString from 'query-string'
import { concat, isEmpty, isNil } from 'ramda';

// SDK
import { DegreeService } from '@sdk-point/talisis';

// local
import ProfileBasic from '../Student/Profile/ProfileBasic';
import ProfileInfo from '../Components/Profile/ProfileInfo';

// const DegreeServiceSDK = new DegreeService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return ({
    rootGrid: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '50px'
      }
    },
    loading:{
      color: '#ffffff',
      width:'50%',
      height:'70%'
    },
    basicInfo:{
      position:'fixed',
      width: '25%',
      marginLeft:'1%',
      height: '92%'
    },
    profileInfo:{
      position:'relative',
      marginLeft:'32%'
    }
  })
});

const EditForm = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const [toast, setToast] = React.useState({ toastProps: { message: '', open: false } })
  const [data, setData] = useState({});
  const [loadingUpdate, setLoadingUpdate] = useState(false);


  const items = [
    { to: '/titulacion-certificacion', label: 'Titulación y Certificación', title: '' },
    { to: '', label: 'Datos Adicionales', title: '' }
  ];

  const handleFinishedToast = () => {
    setToast({ toastProps: { open: false, message: '' } })
  }
  const { loading, student, handledTitulation, handledSubmit,btnTitu, catalogues, holidays  } = props
  return (
    <>
      {/* <Breadcrumb items={items} /> */}
      <LoaderContent loading={loading} minHeight={window.innerHeight - 90}>
        {!isEmpty(student) ?
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12} className={classes.basicInfo}>
                <ProfileBasic {...student} initTitulation={handledTitulation} disableTitu={btnTitu} />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12} className={classes.profileInfo} >
              <Grid container spacing={3} >
                <ProfileInfo data={student} {...props} catalogues={catalogues} holidays={holidays} loading={loadingUpdate} handledSubmit={handledSubmit}/>
                </Grid>
            </Grid>
          </Grid>
          :
          !loading && <NothingFound width='200px' caption={'No se encontraron registros.'} type={"data"} subcaption={'Intenta con otro estudiante.'} />}
      </LoaderContent>
      <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={5000} />
    </>
  )

};
const mapStateToProps = ({ userReducer, profilePicReducer }) => ({
});

const mapDispatchToProps = { };
export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
