import React from 'react';
import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const getMonthlyPayments = () => {
    let monthlyPayments = 1;
    return monthlyPayments;
};

const OrderSummary = ({ order }) => {
    const monthlyPayments = getMonthlyPayments(order);
    let productWithDiscount = order.order_details.find(product => product.discount > 0)
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='body1' color='text-white' className='semi-bold'>Sub-total:</Typography>
                    <Typography variant='h5' className="text-orange-300">
                        <CurrencyFormat value={order?.subtotal} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' />
                    </Typography>
                </Box>
            </Grid>
            {(productWithDiscount && productWithDiscount?.discount>0 && productWithDiscount?.coupon_id > 0) &&
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant='body1' color='text-white'>Cupón:</Typography>
                        <Box display="flex">
                            <Typography variant='caption' className="text-success-300 mr-1">{productWithDiscount?.code}</Typography>
                            <Typography variant='subtitle2' className="text-gray-500">
                                <CurrencyFormat value={ productWithDiscount?.discount} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="-$" suffix=' MXN' />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            }
            {(productWithDiscount && productWithDiscount?.discount>0 && productWithDiscount?.coupon_id === null) &&
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant='body1' color='text-white'>Bonificación por suscripción anterior:</Typography>
                        <Box display="flex">
                            <Typography variant='subtitle2' className="text-gray-500">
                                <CurrencyFormat value={productWithDiscount?.discount} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="-$" suffix=' MXN' />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            }
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='h5' color='text-white'>Total:</Typography>
                    <Typography variant='h3' className="text-orange-300">
                        <CurrencyFormat value={order?.total < 0 ? 0 : order?.total} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' />
                    </Typography>
                </Box>
            </Grid>
            {(monthlyPayments > 1) &&
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant='body1' color='text-white'>Pago por mes:</Typography>
                        <Box display="flex">
                            <Typography variant='subtitle2' className="text-gray-500">
                                <CurrencyFormat value={(order?.total / monthlyPayments)} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="$" suffix={` MXN x ${monthlyPayments}`} />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            }
        </Grid>
    )
};

export default OrderSummary;