import React, { useState } from 'react';

// @material-ui/core components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Grid, Icon, Typography } from '@material-ui/core';
import { AccountService } from '@sdk-point/talisis'

// core components
import EditPhotoDialog from '../EditPhotoDialog/EditPhotoDialog'

// redux
import { connect } from "react-redux";
import * as userActions from '../../../../redux/actions/userActions';

//shared/core components
import InterestAreas from '../InterestAreas/InterestAreas';
import { InterestSelector } from '../InterestSelector/InterestSelector';

import ProfileDetails from '../ProfileDetails/ProfileDetails';

// dependences
import 'moment/locale/es';
import 'react-circular-progressbar/dist/styles.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import GradientSVG from './GradientSVG/GradientSVG';
import ProfileCircle from './ProfileCircle/ProfileCircle';

import LogOut from '../../../../layout/components/Login/LogOut/LogOut'
import { makeStyles } from '@material-ui/styles';
import { eventTracker, eventData } from '../../../../shared/components/EventTracker/EventTracker';

const GoogleAccounts = ['iesalud', 'uerre'];

const EditIcon = () => {
    return  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.54325 11.8334L11.7272 4.64943L10.7256 3.64785L3.54167 10.8318V11.8334H4.54325ZM5.13046 13.25H2.125V10.2446L10.2248 2.14477C10.3576 2.01198 10.5378 1.93738 10.7256 1.93738C10.9134 1.93738 11.0935 2.01198 11.2264 2.14477L13.2302 4.14864C13.363 4.28148 13.4376 4.46161 13.4376 4.64943C13.4376 4.83726 13.363 5.01739 13.2302 5.15023L5.13046 13.25V13.25ZM2.125 14.6667H14.875V16.0834H2.125V14.6667Z" fill="#A0A9BA"/>
            </svg>    
}

const useStyles = makeStyles((theme) => ({
    btnEdit:{
        padding: '1.6px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '10px',
        textDecoration: 'underline',
        letterSpacing: '1px',
        color: theme.palette.colors.fillStroke[100],

    },
}));

const ProfileInfo = (props) => {

    const { ou, isReadOnlyInterests, onChangeInterestMode, onChangeMode, 
            isReadOnlyMode, handleInterestChange, onSubmit, completedProfile } = props;

    const classes = useStyles();

    const [openPhotoEditor, setOpenPhotoEditor] = useState(false);

    const handleChangeMode = () => {
        onChangeInterestMode(false);
        if(isReadOnlyMode){
            eventTracker('s_editar_perfil', eventData, ['mixpanel']);
        }
        onChangeMode(isReadOnlyMode);
        if (isReadOnlyMode) {
            eventTracker('editar_perfil', {}, ['mixpanel']);
        }
    }

    const handleInterestMode = () => {
        onChangeInterestMode(isReadOnlyInterests);
        if (isReadOnlyInterests) {
            eventTracker('editar_intereses', {}, ['mixpanel']);
        }
    }

    const handlePhotoClick = () => {
        setOpenPhotoEditor(true)
    }

    // const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)
    // const handleDelete = () => {
    //     AccountServiceSDK.requestDeleteaccount(user)
    // }

    return (
        <Grid item xs={12}>
            <Card style={{ background: "#1D1E23", border: "1px solid #2C3236"}}>
                <CardContent>
                    {
                        isReadOnlyMode
                        ?
                            <Grid item xs={12}>
                                <Grid container justifyContent='flex-end'>
                                    <Button variant="text" aria-label="delete" size="medium" endIcon={<Icon className='ri-edit-line font-size-16 text-fill-stroke-100' />} onClick={handleChangeMode}>
                                        <Typography variant='caption' className={classes.btnEdit}>
                                            EDITAR
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        :
                        <Grid item xs={12}>
                            <Button variant="text" aria-label="delete" size="medium" startIcon={<Icon className='ri-arrow-left-s-line font-size-24 text-dark-black-200' />} onClick={handleChangeMode}>
                                <Typography style={{textDecoration: 'underline', color: '#78787D'}}>
                                    Regresar
                                </Typography>
                            </Button>
                        </Grid>
                    }
                    <br />
                    <Grid item xs={12}>
                        {
                            // change to develop
                            isReadOnlyInterests && 
                            <>
                                <ProfileCircle completedProfile={completedProfile} handlePhotoClick={handlePhotoClick} />
                                <ProfileDetails {...props} />
                                <EditPhotoDialog openPhotoEditor={openPhotoEditor} setOpenPhotoEditor={setOpenPhotoEditor} />
                                <br />
                            </>
                        }
                        <Grid item xs={12} className="mt-2">
                            {
                                isReadOnlyInterests
                                ? <> 
                                    <InterestAreas {...props} handleInterestMode={handleInterestMode} />
                                    <Grid container justifyContent='center' className='mt-3'>
                                        <LogOut color='primary' variant='contained'>
                                            Cerrar Sesión
                                        </LogOut>
                                    </Grid>
                                </>
                                :
                                <InterestSelector {...props} onChangeInterest={handleInterestChange} onSubmit={onSubmit} handleInterestMode={handleInterestMode} />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <GradientSVG />
        </Grid>
    );
};
const mapStateToProps = ({ userReducer }) => ({
    user: userReducer.user
});

const mapDispatchToProps = { ...userActions };
export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
// export default ProfileInfo;
