import { USER_PATH, API_ENDPOINT } from "./endpoints";
import * as endpoints from './endpoints';
import * as actions from '../shared/constants/permissions'

export const getData = async (person_id) => {
    const url = `${USER_PATH}/${person_id}`;
    const requestOptions = {
        method: 'GET',
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}

export const getPermissions = async (user) => {
    const url = `${endpoints.USER_PATH}/${user.person_id}/permissions/${(user.company_id)? user.company_id:'TAL'}`;
    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getPreMatriculacionData = async (body) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body),
    };
    const response = await fetch(`${USER_PATH}/prematricula`, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const updateOrderFolio = async (orderId, folio) => {
    const url = `${USER_PATH}/prematricula/folio`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            orderId: orderId,
            folio: folio
        }),
    };

    const response = await fetch(url, requestOptions)
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}
export const getPermission = (pageId) => {
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    const permission = permissions.filter(it => it.object_id === pageId);

    return permission.length ? permission[0] : null;
}
export const canReadWrite = (pageId) => {
    return hasPermission(pageId, actions.READ_WRITE);
}

export const hasPermission = (pageId, action) => {
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    if(!permissions || !permissions.length){
        return false;
    }
    const permission = permissions.filter(it => it.object_id === pageId);
    const hasPermission = permission.length ? permission[0].action_id === action : false;

    return hasPermission;
}

export const getAllPermission = () => JSON.parse(localStorage.getItem('permissions')) || [];

export const hasInstructorPermission = () => {
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    if (!permissions || !permissions.length) {
        return false;
    }

    let hasPermission = false;
    if (permissions.length > 0) {
        for (let permission of permissions) {
            if (permission?.role_name === 'Maestro') {
                hasPermission = true;
            }
        };
    }

    return hasPermission;
};

const RESOURCE = 'user/table/entry'
const X_API_KEY = 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A';

export const getAll=async (page,filters, size=10) => { 
    let url = `${API_ENDPOINT}/users?page=${page}&size=${size}`;
    for (let items of filters) {
        let filter = items;
        for(const key in filter){
            let keyFilter = key;
            url+=`&${key}=${filter[keyFilter]}`
        }
    }
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': X_API_KEY
        },
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}
export const getById = async (id) => {
    const url = `${API_ENDPOINT}/${RESOURCE}/${id}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': X_API_KEY
        },
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}
export const save = async (body) => { 
    let url = `${API_ENDPOINT}/${RESOURCE}`; 

    const requestOptions = {
        method:'POST',
        headers: {
            'x-api-key': X_API_KEY
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}


export const update = async (body, id) => { 
    let url = `${API_ENDPOINT}/${RESOURCE}/${id}`; 
    const requestOptions = {
        method:'PUT',
        headers: {
            'x-api-key': X_API_KEY 
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}