import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'

// @material-ui/core
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { MIXPANEL_EVENT } from '@sdk-point/talisis';

//MIXPANEL
import { Mixpanel } from '../../../../shared/components/Mixpanel/mixpanel';
import { userIsLogged, getSessionCookie } from '../../../../shared/utils/Sessions' ;

// styles
import useStyles from '../../../../assets/styles/jss/layout/quickSearchStyle'
import clsx from 'clsx';
import * as paths from '../../../../routes/paths';
import { Hidden } from '@material-ui/core';

const ExpandingSearch = ({initRight = 134, expanded = false, redirectButton,...rest}) => {
    const [inputfocus, setInputFocus] = React.useState(false);
    const isLogged = userIsLogged();
    const classes = useStyles({inputfocus, expanded, isLogged});
    const history = useHistory();
    const location = useLocation();
    const session = getSessionCookie();

    const [value, setValue] = React.useState("");

    const handleClickIcon = event => {
        if(event?.target?.value && event?.target?.value?.replace(/ /g,'')){
            setValue(event.target.value);
        }else if(event?.target?.value.length == 0){
            setValue(event.target.value);
        }
    };

    const handleClose = (course) => {
        handleMix(value, course);
        setValue("");
        setInputFocus(false);
    };
    
    const handleMix = (value, course) => {
        if(value !== ''){
            let companyMixpanel =  course?.company_id != null ? course?.company_id : course?.source_name;
            const role = JSON.parse(localStorage.getItem('permissions'));
            const eventdata = {
                userId: session?.person_id,
                email: session?.email,
                unidadOperativa: session?.ou,
                criteria: value.replace(/\s+/g, ' ').trim().toUpperCase(),
                courseClick: course?.id,
                courseName: course?.name,
                source: companyMixpanel,
                gender: session?.gender,
                userRole: role != null ? JSON.stringify(role[0]?.role_name).replace(/["']/g, "") : undefined,//estudiante,maestro,admin
                userType: role == null ? 'Publico':'Usuario registrado'
            }
           
        };
    };

    const reloadRedirect = ()=> {
        setInputFocus(false);
        const path = `${paths.BUSQUEDA}?buscar=${value}`
        const gotToPathname = path.split("?")[0];
        const currentPath = location.pathname;
    
        if(currentPath === gotToPathname){
            history.push(path);
            window.location.reload();
        }else{
            history.push(path)
        }
    }

    if (redirectButton) {
        return(
            <IconButton aria-label="buscar" component={RouterLink} to={paths.BUSQUEDA}>
                <Icon className="ri-search-line font-size-20 text-dark-black-100"/>
            </IconButton>
        )
    }

    if (location.pathname === paths.BUSQUEDA && !expanded) {
        return(
            <Button aria-label="buscar" className={clsx(classes.iconButtoFixed, 'active')}>
                <Icon className="ri-search-line font-size-20 text-dark-black-100"/>
            </Button>
        )
    }

    return (
        <Box {...rest}>
            <Box className={clsx(classes.root, {'expanded': expanded})}>
                <Box className={classes.searchIcon}>
                    <Icon className="ri-search-line text-dark-black-200 font-size-24"/>
                </Box>

                <InputBase
                    classes={{input: classes.inputPadding}}
                    className={clsx(expanded ? classes.inputBaseExpanded : classes.inputBase)}
                    onFocus={() => {setInputFocus(true)}}
                    onKeyPress={event => {
                        event.key === 'Enter' && value && value.replace(/ /g,'') != "" && reloadRedirect();
                    }}
                    value={value}
                    onBlur={handleClose}
                    placeholder="¿Qué quieres aprender hoy?"
                    inputProps={{ 'aria-label': 'search', 'autoComplete':'off'}}
                    onChange={handleClickIcon}
                    id ="search"
                />
                <Hidden smDown>
                    <IconButton className={classes.iconButton} onClick={handleClose}>
                        <Icon className="ri-close-line text-white font-size-20"/>
                    </IconButton>
                </Hidden>
                <Hidden smUp>
                    <IconButton className={classes.iconButton} onClick={handleClickIcon}>
                        <Icon className="ri-filter-2-line text-dark-black-100 font-size-24"/>
                    </IconButton>
                </Hidden>
            </Box>
        </Box>
    );
};

export default ExpandingSearch;