import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/core
import { Card, CardContent} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';

// @material-ui/icons
import SetRatingStatus from '../../../pages/Courses/CoursesInProgress/components/SetRatingStatus';
import { log } from '../../../shared/utils/console.js'

const useStyles = makeStyles((theme) => ({
    label:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "16px",
        marginRight: "16px",
        color: theme.palette.colors.orange[300],
    }
}));

export default function BlockCourseRating(props){
    const {status, userId, courseId, index, isDetail, enrollment_status_id, progress } = props;
    const [rating, setRating] = React.useState(null);
    const classes = useStyles();

    React.useEffect(() => {
        log(status, userId, courseId, index);
        if(status != null){
            setRating(status);
        }
    }, []);

    return (
        <Card variant="outlined" component={Box}>
            <CardContent className="p-4">
                <Box display="flex" alignItems="center" style={{ pointerEvents: enrollment_status_id == 3 && progress > 50 ? "all": "none"}}>
                    <Typography color="primary" className={classes.label}>{ enrollment_status_id == 3 && progress > 50 ? "Califica el curso":"Calificación" } </Typography>
                    {rating != null && <SetRatingStatus status={rating} userId={userId} courseId={courseId} index={index} isDetail={isDetail}/>}
                </Box>
            </CardContent>
        </Card>
    )
};

BlockCourseRating.defaultProps = {
    value: 0,
};
