import React, { useEffect, useState } from 'react';
import { Box, Divider, Icon, Typography } from '@material-ui/core';
import palette from '../../../design-system/theme/palette';
import { Rating } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';

const BoxCalificaciones = (props) => {

    return(
    <>
        <Box className={props.ClassNameCalificacion}>
        <Typography variant="h4">Calificaciones</Typography>
            <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px', padding: '0px'}}>
                <Typography variant='caption' className='ml-2'>Asesorías:</Typography>
                <Rating
                    readOnly
                    precision={0.5}
                    value={parseFloat(props.profileAdviserData.calificacion) || 0}
                    name="consultancies_rating"
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
                <Typography variant='caption'>({props.profileAdviserData.num_asesorias})</Typography>
            </Box>
            <Divider style={{background: palette.colors.fillStroke[200], width: '689px'}} />
            <Typography variant="h4">Calificaciones Individuales</Typography>
                {
                    (props.profileAdviserData.calificacionInd).map((obj,i) =>( 
                    <Box style={{display: 'flex', flexDirection: 'row', width: '90%', alignItems: 'flex-start', gap: '10px', padding: '0px', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box  style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography className= {clsx('variant-xsmall semi-bold ml-2',props.classIconArrow)}>{i+1}.</Typography>
                            <Typography variant='caption' className='ml-2'>{obj.pregunta}</Typography>
                        </Box>
                        <Box style={{display: 'flex', justifyContent: 'end', alignItems: 'flex-end', alignContent: 'flex-end'}}>
                            <Rating
                                readOnly
                                precision={0.5}
                                value={parseFloat(obj.calificacion) || 0}
                                name="consultancies_rating"
                                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                            />
                        </Box>
                    </Box>

                    ))
                }
        </Box>
    </>
    );
};

export default BoxCalificaciones;
