import React from 'react';
import PropTypes from 'prop-types'

// @material-ui/core
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';

const MultipleSelect = (props) => {
    const {inputLabel, inputLabelAllSelected, menuItems, itemsSelected, onSelect, error, required, ...rest} = props;

    const handleChange = (event) => {
        const {value} = event.target;        
        const lookup = value.reduce((objTemp, obj) => {
            objTemp[obj.id] = ++objTemp[obj.id] || 0;
            return objTemp;
        }, {});
          
        const itemsWithoutDuplicates = value.filter(v => !lookup[v.id]);

        onSelect(itemsWithoutDuplicates);
    };

    return (
        <Box width="100%">
            <FormControl 
                variant="outlined" 
                margin="dense" 
                fullWidth
                style={{width: "100%"}}
                error={error}
                {...rest}
            >
                <InputLabel id="select-mutiple" style={{fontSize: 13 }} required={required}>
                    {inputLabel}
                </InputLabel>
                <Select
                    labelId="select-mutiple"
                    id="demo-mutiple-name"
                    multiple
                    value={itemsSelected}
                    onChange={handleChange}
                    renderValue={(selected) => 
                        selected.length === menuItems.length ? inputLabelAllSelected : selected.map(it => it.label).join(', ')
                    }
                    input={<OutlinedInput />}
                >
                <MenuItem value="" disabled>
                    <em>Seleccione</em>
                </MenuItem>
                {menuItems.map((item) => (
                    <MenuItem key={item.id} value={item}>
                    <Checkbox checked={itemsSelected.some(it => it.id === item.id)}/>
                    <ListItemText primary={item.label} />
                    </MenuItem>
                ))}
                </Select>
                {
                    props.error && <FormHelperText id="component-error-text">{error}</FormHelperText>
                }
            </FormControl> 
        </Box>
    );
};

MultipleSelect.defaultProps = {
    disabled: false,
    error: false,
    required: false,
    inputLabelAllSelected: 'Todos'
};

MultipleSelect.propTypes = {
    inputLabel: PropTypes.string.isRequired,
    inputLabelAllSelected: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    /*Array of options*/
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    /*Array of options selected*/
    itemsSelected: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    /*Optional click handler*/
    onSelect: PropTypes.func
};

export default MultipleSelect;