import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    checkbox:{
        '&.MuiCheckbox-indeterminate':{
            color: '#ACF1FC'
        },
        "&.Mui-disabled" : {
            color: theme.palette.colors.violet[300],
        },
        color: theme.palette.colors.violet[300],
    },
    tableRow:{
        backgroundColor:  theme.palette.background.secondary,
        '&.Mui-selected':{
            backgroundColor: '#FAFAFB',
            '&:hover':{
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            }
        },
        '& .show-button':{
            display: 'none'
        },
        '&:hover':{
            '& .show-button':{
                display: 'block'
            }
        },
        ...theme.styles.table.tableRow
    },
    tableNotFound:{
        backgroundColor: theme.palette.colors.fillStroke[400],
        borderWidth: "0.5px 0px",
        borderStyle: "solid",
        borderColor: theme.palette.colors.fillStroke[300],
    },
    tableCell:{
        // borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        ...theme.typography.body2,
    },
    buttonSortTable: {
        '& svg': {
            color: `${theme.palette.colors.white} !important`,
        }
    },
    stripedRows:{
        '&:nth-of-type(odd)': {
            backgroundColor: `${theme.palette.colors.fillStroke[400]} !important`,
        },
    }
}));