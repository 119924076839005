import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core
import AppBar from '@material-ui/core/AppBar';
import MuiToolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    toolbar:{
        height: props=> props.isLogged ? 65 : 80,
    },
}));

const Toolbar = ({children, isLogged, ...rest}) => {
    const classes = useStyles( {isLogged});

    return (
        <AppBar color='inherit' {...rest}>
            <MuiToolbar className={clsx(classes.toolbar, 'container')}>
                <Box width="100%">
                    {children}
                </Box>      
            </MuiToolbar>
        </AppBar>
    );
};

export default Toolbar;