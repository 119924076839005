import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip'; 
import { log } from '../../../shared/utils/console.js'

//SDK
import { AccountService } from '@sdk-point/talisis'; 

const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const units = [
    { ou: "iesalud", ouLabel: "IESALUD" },
    { ou: "talisis", ouLabel: "TALISIS" },
    { ou: "uerre", ouLabel: "U-ERRE" },
    { ou: "unid", ouLabel: "UNID" },
    { ou: "hh", ouLabel: "HARMON HALL" },
];

const StudentList = props => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [criteria, setCriteria] = React.useState("");
    const searchTimer = React.useRef(null);
    const { user, handleChange, includePersons } = props;

    const getStudents = async () => {
        setLoading(true);
        try {
            const response = await AccountServiceSDK.searchStudent(user.ou, criteria, includePersons);
            setOptions(await response);
        }
        catch (e) {
            log(e.message);
        }

        setLoading(false);
    };

    React.useEffect(() => {
        clearTimeout(searchTimer.current);

        // delay 1 second before search
        if (criteria !== undefined && criteria !== null && String(criteria).trim() !== "") {
            searchTimer.current = setTimeout(() => getStudents(), 500);
        }

        return () => {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
            }
        };
    }, [criteria]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            multiple
            id="asynchronous-student"
            fullWidth
            size="small"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            filterOptions={(options) => options.filter(o => {
                const ou = !o.company_id ? "" : o.company_id.toLowerCase();
                const unit = units.filter(u => u.ou === ou)[0];
                if (unit) {
                    return true;
                }
                else {
                    return false;
                }
            })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.full_name}
            options={options}
            loading={loading}
            autoComplete
            onInputChange={(event, value, reason) => {
                if (reason === "input") {
                    setCriteria(value);
                }
            }}
            onChange={handleChange}
            renderInput={(params) =>{ 
                return (
                <TextField {...params} placeholder='Buscar usuario por nombre, ID' variant="outlined" size="small" InputProps={{
                    startAdornment: (
                        <>
                            {loading ? <CircularProgress color="inherit" size={20} />:<i class="ri-search-line"></i>}
                        </>
                    ),
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    )
                }} />
            )}}
            renderOption={(option) => {
                const ou = !option.company_id ? "" : option.company_id.toLowerCase();
                const unit = units.filter(u => u.ou === ou)[0];
                const company = unit.ou == 'uerre' && (option.email && option.email.includes('iesalud')) ? 'IESALUD' : unit.ouLabel;
                return <React.Fragment>
                    <span>{option.full_name}</span>
                    <span><Chip variant="outlined" size="small" className="ml-2" label={option.user_id} /></span>
                    <span> {unit && <Chip variant="default" size="small" className="ml-2" color="primary" label={company} /> }</span>
                </React.Fragment>
            }}
        />
    );
}

export default StudentList;