import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    list:{
        display: 'flex',
        padding: 0,
        height: 'inherit',
        color: theme.palette.colors.gray[100],
        [theme.breakpoints.only('md')]: {
            gap: 8
        },
        '& .MuiListItemText-root':{
            marginTop: 'unset',
            marginBottom: 'unset',
        },
        '& .MuiListItem-root':{
            paddingLeft: 22,
            paddingRight: 22,
            [theme.breakpoints.only('md')]: {
                paddingLeft: 8,
                paddingRight: 8,
            },
            width:'auto',
            '&.active': {
                background: theme.palette.colors.fillStroke[400],
                '&::before': {
                    content: '" "',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 8,
                    borderTop: `8px solid ${theme.palette.colors.violet[400]}`,
                },
            },
        }
    },
    notification:{
        '&:hover .MuiTypography-root':{
            fontWeight: 600
        },
        '& .MuiBadge-dot':{
            top: '11px',
            right: '-11px',
            height: 7,
            minWidth: 7
        }
    }
}));