import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Backdrop, Fade, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';

// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../redux/actions/userActions';
import * as profilePicActions from '../../../../redux/actions/profilePicActions';
import * as tourInitialActions from '../../../../redux/actions/tourInitialActions';
import { log } from '../../../../shared/utils/console.js'

//SDK
import { SingupService, UserService } from '@sdk-point/talisis';

const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '395px',
        padding: '12px 0px',
        margin: '0px auto',
    },
    heading: {
        fontSize: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
        flexShrink: 0,
        padding: '24px 0px'
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold,
    },
    divider: {
        position: 'relative',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    dividerText: {
        position: 'absolute',
        top: '4px',
        left: '45%',
        backgroundColor: '#ffffff',
        padding: '0px 10px',
    },
    loginButton: {
        minWidth: 120,
        minHeight: 40
    },
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingTop: '58px',
        paddingLeft: '104px',
        paddingRight: '107.14px',
    },
    modalTitle: {
        color: theme.palette.colors.white,
        fontWeight: 700,
        marginLeft: '-2px',
        marginTop: '128.5px',
        fontSize: '18px',
        lineHeight: '40px',
        textAlign: 'center'
    },
    paper: {
        background: theme.palette.colors.grey[500],
        border: `1px solid ${theme.palette.colors.grey[500]}`,
        borderRadius: '8px',
        boxSizing: 'border-box',
        height: '400px',
        top: '112px',
        position: 'absolute',
        width: '500px',
    },
    email: {
        alignItems: 'center',
        gap: '10px',
        width: '318px',
        height: '48px',
        background: `${theme.palette.colors.grey[500]} !important`,
        border: `0.5px solid ${theme.palette.colors.grey[50]} !important`,
        borderRadius: '4px',
        '& :-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${theme.palette.colors.grey[500]} inset`,
            '-webkit-text-fill-color': `${theme.palette.colors.white} !important`,
            'caret-color': 'white',
            fontWeight: 400,
            padding: '14px',
        },
    },
    submit: {
        width: '318px',
        height: '48px',
        background: theme.palette.colors.violet[500],
        borderRadius: '4px',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        marginTop: '40px',
    },
    link: {
        color: theme.palette.colors.orange[300],
        fontSize: '12px',
    },
    catpionBlack: {
        color: theme.palette.colors.darkBlack[200]
    },
    catpionGray: {
        color: theme.palette.colors.gray[400]
    },
    resetPasswordText: {
        fontSize: 14,
        color: theme.palette.colors.gray[400],
        fontWeight: 400
    },
    helpLink: {
        fontSize: 14,
        cursor: 'pointer'
    }
}));

const ResetForm = props => {
    const { mail, onLoading, onPasswordError, mailSent, setPersonalMail, setStatusReset, containsDomain, setContainsDomain, handleSendActivation, setLoginMail, setCropEmail, formatEmail } = props;
    const [errors, setErrors] = React.useState({});

    const [form, setForm] = React.useState({
        'email': mail || ''
    });

    const [resetError, setResetError] = React.useState(false);

    const preventDefault = (event) => event.preventDefault();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            const _errors = { ...errors };
            delete _errors[name];

            setErrors(_errors);
        }

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const handleOpenModal = email => {
        const tempMail = email
        const domain = tempMail.substring(tempMail.lastIndexOf("@"));
        var address = tempMail.substring(0, tempMail.indexOf("@"));
        var cropAddress = address.substring(address.length - 3)
        log(cropAddress + domain)
        setPersonalMail(cropAddress + domain)
        mailSent(true);
    }

    const validate = () => {
        const _errors = {};
        const requiredFields = ['email'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            }
        }

        setErrors({ ..._errors });
        return Boolean(Object.keys(_errors).length === 0);
    };

    const validateDomains = [
        'red.unid.mx', 'unid.mx', 'u-erre.mx', 'u-erre.edu.mx',
        'iesalud.mx', 'iesalud.edu.mx', 'talisis.com', 'miadvenio.com',
        'harmonhall.edu.mx', 'al.harmonhall.edu.mx', 'soy.harmonhall.edu.mx',
        'doc.harmonhall.edu.mx', 'familia.talisis.com', 'familia.advenio.com'
    ];

    const handleSubmit = async (e) => {
        preventDefault(e);    
        const emailDomain = form.email && form.email.split('@')[1];
        const domainInst = validateDomains.includes(emailDomain);
        setContainsDomain(domainInst)
        if (validate()) {
            onLoading(true);
            log(form);
            setLoginMail(form.email)
            // Validating email data
            const dataEmail = await UserServiceSDK.getByEmail(form.email, true);

            if (dataEmail?.data?.length === 0) {
                onPasswordError({
                    data: {
                        error: true
                    },
                    email: form.email
                });
                setResetError(true)
                setStatusReset(1);
                onLoading(false);
                return;
            }

            if (!dataEmail?.data[0]?.is_active) {
                const dataError = {
                    data: {
                        error: true
                    },
                    email: form.email,
                    name: dataEmail?.data[0]?.first_name
                }
                onPasswordError(dataError);
                handleSendActivation(form.email)
                setResetError(true)
                setStatusReset(2);
                onLoading(false);
                return;
            }
            if (domainInst) {
                const datas = {
                    data: {
                        error: true
                    },
                    email: form.email,
                    name: dataEmail?.data[0]?.first_name
                }
                onPasswordError(datas);
                setResetError(true)
                setStatusReset(3);
                onLoading(false);
                return;
            }

            if (!dataEmail?.data[0]?.is_main && !dataEmail?.data[0]?.is_used_for_login) {
                const mainData = await UserServiceSDK.getMainEmail("person_id", dataEmail?.data[0]?.id)
                if(!mainData){
                    onPasswordError({
                        data: {
                            error: true
                        },
                        email: form.email
                    });
                    setResetError(true)
                    setStatusReset(1);
                    onLoading(false);
                    return;
                }
                const datas = {
                    data: {
                        error: true
                    },
                    email: form.email,
                    name: dataEmail?.data[0]?.first_name
                }
                setCropEmail(formatEmail(mainData?.email_address))
                onPasswordError(datas);
                setResetError(true)
                setStatusReset(4);
                onLoading(false);
                return;
            }

            SingupServiceSDK.forgotPassword(form.email).then(data => {
                if (data.error) {
                    setResetError(data.error)
                    onPasswordError({ data, email: form.email })
                }
                else {
                    if (data.active_directory) {
                        log(data)
                        handleOpenModal(data.mail)
                    } else {
                        mailSent(true)
                    }
                }
            })
                .catch(e => {
                    onPasswordError('Ha ocurrido un error. Intenta de nuevo')
                })
                .finally(() => {
                    onLoading(false)
                })
        }
    };

    const contactTechnicalSupport = () => {
        const talisisHelpDeskButton = document.getElementById('talisis_helpdesk_button');
        if (talisisHelpDeskButton) {
            talisisHelpDeskButton.click();
        }
    }

    const classes = useStyles();
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Grid container>
                    {!resetError && <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                className: 'text-gray-100 semi-bold'
                            }}
                            name="email"
                            variant="outlined"
                            label="Correo"
                            fullWidth
                            size="medium"
                            // required
                            value={form.email || ""}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            onChange={handleChange}
                            className="offset-margin-bottom-32"
                            placeholder="Ingresar correo electrónico"
                            InputProps={{
                                classes: {
                                    input: {
                                        fontSize: '14px !important'
                                    }
                                }
                            }}
                        />
                    </Grid>}
                    <Grid item xs={12}>
                        {!resetError &&
                            <>
                                <Box display="flex" className="offset-margin-bottom-32 mb-4">
                                    <Button fullWidth variant="contained" color="primary" type={'submit'} onClick={handleSubmit} >Cambiar Contraseña</Button>
                                </Box>
                                <Typography variant="subtitle2" className={`${classes.resetPasswordText}`}>¿Necesitas ayuda? <Link variant="body1" color="secondary" className={classes.helpLink} onClick={contactTechnicalSupport}>Haz clic aquí</Link></Typography>
                            </>
                        }
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

const mapStateToProps = ({ userReducer, profilePicReducer, tourInitialReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic,
    showTour: tourInitialReducer.showTour
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...tourInitialActions };
export default connect(mapStateToProps, mapDispatchToProps)(ResetForm);