import React from 'react';

import { Button, Icon } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    alert:{
        position: 'absolute',
        bottom: '-48px',
        '& .MuiAlert-root':{
            border: `0.5px solid ${theme.palette.colors.success[300]}`,
            background: theme.palette.colors.success[100],
            '& .MuiAlert-icon':{
                padding: 0,
                fontSize: 16,
                color: theme.palette.colors.success[400]
            },
            '& .MuiAlert-message':{
                padding: 0,
                ...theme.typography.bodyTextSmallSemiBold,
                color: theme.palette.colors.darkBlack[500],
            }
        }
    },
}));


const ClipboardButton = ({ children, textToCopy, ...rest }) => {
    const classes = useStyles();
    const [copied, setCopied] = React.useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 1000); 
    };
  
    return (
        <>
            <Button onClick={handleCopy} {...rest}>
                {children}
            </Button>
            <Collapse in={copied} className={classes.alert}>
                <Alert icon={<Icon className='ri-checkbox-circle-fill font-size-16' />} severity="success">Copiado</Alert>
            </Collapse>
        </>
    );
};

export default ClipboardButton;