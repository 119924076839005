import React from "react";
import { processGroups } from "./GroupsSelectorProcess";

import { Checkbox, Typography } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import useStyles from "../../../assets/styles/jss/treeItemStyle";

//SDK
import { UsersService } from '@sdk-point/talisis'; 

const UsersServiceSDK = new UsersService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const GroupsSelector = (props) => {
    const { onToggle } = props;
    const [groups, setGroups] = React.useState([]);

    const [expanded, setExpanded] = React.useState(['all_groups']);
    const [checkedAllGroups, setCheckedAllGroups] = React.useState(false);
    const [indeterminateGroups, setIndeterminateGroups] = React.useState(false);

    const styles = useStyles();
    const xStyle = { fontFamily: "'Source Sans Pro', sans-serif" };

    React.useEffect(() => {
        async function fetchData() {
            await initialize();
        }
        fetchData();
    }, []);

    const initialize = async () => {
        let initializeGroups = await UsersServiceSDK.getGroups();
        initializeGroups = await Promise.all(
            initializeGroups.map(async (group) => {
                const members = await UsersServiceSDK.getGroupMembers(group.id);
                group.members = members.map(member => {
                    member.checked = false;
                    return member;
                });
                group.checked = false;
                return group;
            })
        );
        setGroups(initializeGroups);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleCheck = (e, level, id) => {
        e.stopPropagation();

        const inputRef = e.target;
        const data = {
            groups: groups,
            level: level,
            id: id,
            ref: inputRef
        };
        const { newGroups, indeterminateGroups, selectedGroupMembersIds, selectedGroups } = processGroups(data);

        setGroups(newGroups());
        setIndeterminateGroups(indeterminateGroups());
        setCheckedAllGroups(!!inputRef.checked);
        onToggle(indeterminateGroups(), selectedGroups(), selectedGroupMembersIds());
    };

    return (
        <TreeView
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            expanded={expanded}
            multiSelect={true}
            onNodeToggle={handleToggle}
            style={{ flexGrow: 1, maxWidth: 400 }}
        >
            <TreeItem
                key={'all_groups'}
                nodeId={'all_groups'}
                label={
                    <div style={xStyle}>
                        <Typography variant="body1" className={styles.labelText} style={xStyle}>
                            <Checkbox
                                key={'all_groups'}
                                name="all_groups"
                                color="primary"
                                onClick={(e) => {
                                    handleCheck(e, 'all_groups');
                                    setCheckedAllGroups(!checkedAllGroups);
                                }}
                                checked={checkedAllGroups}
                                indeterminate={indeterminateGroups}
                            />{'Todos'}
                        </Typography>
                    </div>
                }
                classes={{
                    content: styles.content,
                    expanded: styles.expanded,
                    group: styles.group,
                    label: styles.label,
                    root: styles.root,
                    selected: styles.selected,
                }}
            >
                {groups.length > 0 && groups.map((group) => (
                    <TreeItem
                        key={`group_${group.id}`}
                        nodeId={`group_${group.id}`}
                        label={
                            <div key={`container_group_${group.id}`} style={xStyle}>
                                <Typography variant="body1" className={styles.labelText} style={xStyle}>
                                    <Checkbox
                                        key={`group_${group.id}`}
                                        name={`group_${group.id}`}
                                        color="primary"
                                        onClick={(e) => {
                                            handleCheck(e, 'group', group.id);
                                        }}
                                        checked={group.checked}
                                        indeterminate={false}
                                    />
                                    {group.name}
                                </Typography>
                            </div>
                        }
                        classes={{
                            content: styles.content,
                            expanded: styles.expanded,
                            group: styles.groupChild,
                            label: styles.label,
                            root: styles.root,
                            selected: styles.selected,
                        }}>
                        {group.members.length > 0 && group.members.map(member => (
                            <TreeItem
                                key={`member_${member.person_id}`}
                                nodeId={`member_${member.person_id}`}
                                label={
                                    <div key={`container_member_${member.person_id}`} style={xStyle}>
                                        <Checkbox
                                            key={`member_${member.person_id}`}
                                            name={`member_${member.person_id}`}
                                            color="primary"
                                            checked={member.checked}
                                            disabled
                                        />
                                        {member.member_name}
                                    </div>
                                }
                                classes={{
                                    content: styles.content,
                                }}
                            />
                        ))}
                    </TreeItem>
                ))}
            </TreeItem>
        </TreeView>
    );
};

export default GroupsSelector;