import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Button, Card, CardContent, CardHeader, Divider, formatMs, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { getLogoCard, LOGO_SOURCE } from '../../../shared/utils/imageSource';
import Toast from '../../../shared/components/Toast/Toast';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import ResetForm from './ResetForm/ResetForm';
import * as paths from '../../../routes/paths'
import clsx from 'clsx';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import { SingupService } from '@sdk-point/talisis';
import { log } from '../../../shared/utils/console.js'

const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        width: '100%',
        margin: '0px auto',
        height: '100vh'
    },
    card: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 60,
        },
        backgroundColor: `${theme.palette.colors.fillStroke[400]} !important`,
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        marginTop: 56
    },
    cardHeader: {
        padding: "16px 20px 0 0"
    },
    closeIcon: {
        fontSize: '24px',
        color: theme.palette.colors.darkBlack[200]
    },
    backIconButton: {
        fontSize: 14,
        color: theme.palette.colors.gray[100]
    },
    backIcon: {
        fontSize: '24px',
        color: theme.palette.colors.gray[100]
    },
    closeIconButton: {
        padding: 12,
        float: 'right'
    },
    cardContent: {
        padding: '16px 60px !important',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: '15px !important',
        }
    },
    boxSplitLogin: {
        marginTop: 40
    },
    talisisLogo: {
        width: 120
    },
    resetPasswordText: {
        fontSize: 14,
        color: theme.palette.colors.gray[400],
        fontWeight: 400
    },
    heacerActions: {
        width: '100%'
    },
    helpLink: {
        fontSize: 14,
        cursor: 'pointer'
    }
}));

const ResetPassword = (props) => {
    const classes = useStyles();

    const [toastProps, setToastProps] = React.useState({
        message: 'Por el momento no cuentas con acceso a la plataforma.',
        severity: 'error'
    });

    const location = useLocation();
    const [errorData, setErrorData] = React.useState('');
    const [loginMail, setLoginMail] = React.useState('');
    const [openForm, setOpenForm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [resetPasswordForm, setResetPasswordForm] = React.useState(false)
    const [openAutoSignUp, setOpenAutoSignUp] = React.useState(false);
    const [autoSignupForm, setAutoSignupForm] = React.useState({});
    const [mailSent, setMailSent] = React.useState(false);
    const [resetError, setResetError] = React.useState(false);
    const [hideTalisisLogo, setHideTalisisLogo] = React.useState(false);
    const [personalMail, setPersonalMail] = React.useState('')
    const [sendActivation, setSendActivation] = React.useState({ disabled: true, message: 'Volver a enviar en... ' });
    const [seconds, setSeconds] = React.useState(20);
    const [cropEmail, setCropEmail] = React.useState('')
    const [containsDomain, setContainsDomain] = React.useState(false)
    const [statusReset, setStatusReset] = React.useState(0);
    /* 
    Estatus 1: No se encuentra el correo electronico    
    Estatus 2: Se encontro el correo pero no esta activo
    Estatus 3: No se encuentra el correo electronico  
    Estatus 4: No se encuentra el correo electronico   
    */

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.has('forgot')) {
            setResetPasswordForm(true)
        }
    })

    const handleSignupData = data => {
        setAutoSignupForm(data)
    }

    const handleAutoSignup = isOpen => {
        setOpenAutoSignUp(isOpen)
    }

    const handleLoginError = error => {
        setToastProps({ ...toastProps, open: true, message: error ? error : 'Por el momento no cuentas con acceso a la plataforma.' })
        setLoading(false)
    }

    const hanldeFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    }

    const handleLoader = loader => {
        setLoading(loader);
    }

    const handleOpenForm = canOpenForm => {
        setOpenForm(canOpenForm)
    }

    const getMail = mail => {
        setLoginMail(mail)
    }

    const handleResetPassword = open => {
        setResetPasswordForm(open)
    }

    const sleep = time => {
        return new Promise(resolve => setTimeout(resolve, time))
    }

    const handleMailSent = isSent => {
        setMailSent(isSent)
        if (isSent) {
            // sleep(3000).then(() => {
            //     window.location.reload(false)
            // })
        }
    }

    const preventDefault = (event) => event.preventDefault();

    const openResetPassword = e => {
        setResetError(false)
        preventDefault(e);
        handleResetPassword(true)
    }

    const handleResetError = error => {
        setResetError(error.data.error)
        setCropEmail(formatEmail(error.email))
        setErrorData(error);
    }

    const formatEmail = (email) => {
        const [localPart, domain] = email.split('@');
        if (localPart.length < 3) {
            return email;
        }
        const firstPart = localPart.slice(0, 2);
        const lastPart = localPart.slice(-3);
        const maskedPart = '*'.repeat(localPart.length - 5);
        return `${firstPart}${maskedPart}${lastPart}@${domain}`;
    }
    const history = useHistory();
    const goBack = (e) => {
        preventDefault(e);
        history.goBack();
    }

    const closeResetPassword = e => {
        preventDefault(e);
        history.push('/login')
        /* setMailSent(false)
        const searchParams = new URLSearchParams(location.search)
        searchParams.delete('forgot');
        history.replace({
            search: searchParams.toString(),
        })
        handleResetPassword(false); */
    }

    const contactTechnicalSupport = () => {
        const talisisHelpDeskButton = document.getElementById('talisis_helpdesk_button');
        if (talisisHelpDeskButton) {
            talisisHelpDeskButton.click();
        }
    }

    const handleRedirectTutorialMicrosoft = () => {
        const url = 'https://digitalmedia.talisis.com/talisis/tutoriales/recuperar-contrasena-microsoft.pdf';
        window.open(url, '_blank');
    }

    const handleSendActivation = async (email) => {
        try {
            if (!email)
                email = loginMail

            const payload = {
                env: process.env.REACT_APP_ENV,
                emails: [email]
            }
            await SingupServiceSDK.talisisResendActivation(payload)
        } catch (error) {
            log("Error al enviar correo de activación: ", error)
        }
    }

    useEffect(() => {
        if (statusReset === 2) {
            // If the countdown is active
            if (seconds > 0) {
                const timer = setInterval(() => {
                    setSeconds(prev => prev - 1);
                }, 1000); // Update every second

                // Cleanup the interval on component unmount or when countdown stops
                return () => clearInterval(timer);
            } else {
                // Enable the button when countdown finishes
                setSendActivation({ disabled: false, message: 'Reenviar correo de activación' });
            }
        }
    }, [seconds, statusReset]);

    const handleResendButton = async () => {
        handleSendActivation()
        setSendActivation({ disabled: true, message: 'Correo de activación reenviado', resended: true });
    }

    return (
        <Box>
            <LoaderContent loading={loading}>
                <Box className={classes.root}>
                    <Card elevation={0} className={`${classes.card}`}>
                        <CardHeader className={classes.cardHeader}
                            action={
                                <>
                                    <IconButton className={classes.backIconButton} onClick={closeResetPassword}>
                                        <i className={clsx(classes.backIcon, 'ri-arrow-left-s-line')} /> Regresar
                                    </IconButton>
                                    <IconButton className={classes.closeIconButton} component={RouterLink} to={'/'}>
                                        <i className={clsx(classes.closeIcon, 'ri-close-line')} />
                                    </IconButton>
                                </>
                            }
                            classes={{ action: classes.heacerActions }}
                        />
                        <CardContent className={classes.cardContent}>
                            <img src={getLogoCard(LOGO_SOURCE.TALISIS)} className={classes.talisisLogo} />
                            {
                                !mailSent &&
                                <React.Fragment>
                                    {
                                        (statusReset !== 2 && statusReset !== 3) &&
                                        <Typography variant="h4" className={`semi-bold text-gray-100 ${'mt-4'}`} align={'left'}>Restablecer contraseña</Typography>
                                    }
                                    <React.Fragment>
                                        {
                                            statusReset === 0 ?
                                                <Typography variant="subtitle2" className={`${classes.resetPasswordText} ${'mt-2 mb-4'}`} align={'left'}>{'Ingresa tu correo para que podamos enviarte indicaciones para restablecer tu contraseña.'}</Typography>
                                                :
                                                statusReset === 1 ?
                                                    <>
                                                        <Typography variant="subtitle2" className={`${classes.resetPasswordText} ${'mt-2 mb-4'}`} align={'left'}>No podemos recuperar tu contraseña porque no contamos con <br /> información suficiente para verificar tu identidad. Por favor <br /> contacta a soporte.</Typography>
                                                        <Button
                                                            color='primary'
                                                            variant='contained'
                                                            style={{ marginBottom: 24, width: '100%' }}
                                                            onClick={() => contactTechnicalSupport()}
                                                        >
                                                            Contactar soporte
                                                        </Button>
                                                        <Typography variant="subtitle2" className={`${classes.resetPasswordText}`}>¿Necesitas ayuda? <Link variant="body1" color="secondary" className={classes.helpLink} onClick={contactTechnicalSupport}>Haz clic aquí</Link></Typography>
                                                    </>
                                                    : statusReset === 2 ?
                                                        <>
                                                            <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                                                <Typography variant="body1" className="semi-bold mt-4" color="primary">Bienvenid@</Typography>
                                                                <Typography variant="body1" className={`semi-bold text-gray-100`} align={'center'}>a tu plataforma Talisis</Typography>
                                                            </Box>
                                                            {
                                                                (sendActivation.lastStep) ?
                                                                    <Box width={'100%'} mb={8} mt={4} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gridGap={8}>
                                                                        <Typography variant='h4'>¿No recibiste el correo?</Typography>
                                                                        <Typography variant='body2' align='left'>Lamentamos que no hayas recibido el correo de activación. <br /> Aquí tienes algunas sugerencias para solucionarlo:</Typography>
                                                                        <List disablePadding>
                                                                            <ListItem style={{ padding: 0 }} alignItems="flex-start">
                                                                                <ListItemIcon style={{ minWidth: '15px', marginTop: '10px' }}>
                                                                                    <Icon className="ri-checkbox-blank-circle-fill" style={{ color: 'orange', fontSize: 5 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText
                                                                                    primary={<Typography variant='body2' style={{ color: 'orange' }}>Verifica tu carpeta de spam o correo no deseado.</Typography>}
                                                                                />
                                                                            </ListItem>
                                                                            <ListItem style={{ padding: 0 }} alignItems="flex-start">
                                                                                <ListItemIcon style={{ minWidth: '15px', marginTop: '10px' }}>
                                                                                    <Icon className="ri-checkbox-blank-circle-fill" style={{ color: 'orange', fontSize: 5 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText
                                                                                    primary={<Typography variant='body2' style={{ color: 'orange' }}>Asegúrate de que la dirección de correo electrónico proporcionada es correcta.</Typography>}
                                                                                />
                                                                            </ListItem>
                                                                        </List>
                                                                        <Typography variant='body2' align='left'>Si después de revisar estos pasos aún no has recibido el <br /> correo, por favor, contacta a nuestro equipo de soporte para <br /> asistencia adicional.</Typography>
                                                                        <Button
                                                                            color='primary'
                                                                            variant='contained'
                                                                            style={{ marginBottom: 24, width: '100%', marginTop: 32 }}
                                                                            onClick={() => contactTechnicalSupport()}
                                                                        >
                                                                            Contactar soporte
                                                                        </Button>
                                                                    </Box>
                                                                    :
                                                                    <Box width={'100%'} mb={8} mt={4} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gridGap={8}>
                                                                        <Box width={'100%'} mb={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                                                                            <Typography variant='h4'>Hola, {errorData && errorData.name}</Typography>
                                                                            <Typography align='left'>No has activado tu cuenta, te hemos enviado un correo con los <br /> pasos de activación a la siguiente dirección:</Typography>
                                                                            <Typography variant='h5'>{cropEmail}</Typography>
                                                                        </Box>
                                                                        <Box mt={3} width={'100%'}>
                                                                            <Button fullWidth variant='contained' color='primary' disabled={sendActivation.disabled} onClick={() => { handleResendButton() }}>
                                                                                {sendActivation.message} {seconds !== 0 && seconds}
                                                                            </Button>
                                                                        </Box>
                                                                        <Box mt={3} width={'100%'}>
                                                                            {
                                                                                sendActivation.resended &&
                                                                                <Button color='primary' onClick={() => {
                                                                                    setSendActivation({ disabled: true, message: 'Correo de activación reenviado', resended: true, lastStep: true })
                                                                                }
                                                                                }>¿No recibiste el correo?</Button>
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                            }
                                                        </>
                                                        : statusReset === 3 ?
                                                            <>
                                                                <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                                                    <Typography variant="body1" className="semi-bold mt-4" color="primary">Bienvenid@</Typography>
                                                                    <Typography variant="body1" className={`semi-bold text-gray-100`} align={'center'}>a tu plataforma Talisis</Typography>
                                                                </Box>
                                                                <Box width={'100%'} mb={8} mt={4} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gridGap={8}>
                                                                    <Typography variant='h4' align='left'>Recuperación de Contraseña a través de Microsoft</Typography>
                                                                    <Typography variant='body2' align='left'>Hemos identificado que el correo ingresado es un correo <br /> institucional. Revisa nuestro tutorial para conocer cómo recuperar <br /> tu contraseña.</Typography>
                                                                    <Button
                                                                        color='primary'
                                                                        variant='contained'
                                                                        style={{ width: '100%', marginTop: 32 }}
                                                                        onClick={() => handleRedirectTutorialMicrosoft()}
                                                                    >
                                                                        Ver tutorial
                                                                    </Button>
                                                                    <Divider style={{ width: "100%" }} className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" />
                                                                    <Typography style={{ alignSelf: 'center' }} variant="subtitle2" className={`${classes.resetPasswordText}`}>¿Necesitas ayuda? <Link variant="body1" color="secondary" className={classes.helpLink} onClick={contactTechnicalSupport}>Haz clic aquí</Link></Typography>
                                                                </Box>
                                                            </>
                                                            : statusReset === 4 &&
                                                            <>
                                                                <Box width={'100%'} mb={4} mt={4} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gridGap={8}>
                                                                    <Typography variant='h4'>Correo Incorrecto</Typography>
                                                                    <Typography variant='body2' color='textSecondary' align='left'>Para realizar esta acción, es necesario que utilices tu correo <br/> electrónico de acceso:</Typography>
                                                                    <br/>
                                                                    <Typography>{cropEmail}</Typography>
                                                                    <Button 
                                                                    color='primary' 
                                                                    variant='contained' 
                                                                    style={{ marginBottom: 24, width: '100%', marginTop: 32 }}
                                                                    onClick={()=> closeResetPassword()}
                                                                    >
                                                                        Intentar de nuevo
                                                                    </Button>
                                                                </Box>
                                                                <Divider className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" variant="fullWidth" />
                                                                <Box display="flex" justifyContent="center" mb={7}>
                                                                    <Typography variant="body1" color="textPrimary" className="mr-1">¿No tienes cuenta aún?</Typography>
                                                                    <Link component={RouterLink} variant="body1" color="secondary" to={'/signup' + location.search} onClick={() => {
                                                                        eventData['source'] = 'login';
                                                                        eventTracker('cta_registro', eventData, ['mixpanel', 'hubspot', 'google']);
                                                                    }}>
                                                                        Registrate aquí
                                                                    </Link>
                                                                </Box>
                                                            </>
                                        }
                                        <ResetForm
                                            mailSent={handleMailSent}
                                            resetPassword={handleResetPassword}
                                            onLoading={handleLoader}
                                            mail={loginMail}
                                            personalMail={personalMail}
                                            setPersonalMail={setPersonalMail}
                                            onPasswordError={handleResetError}
                                            setStatusReset={setStatusReset}
                                            containsDomain={containsDomain}
                                            setContainsDomain={setContainsDomain}
                                            handleSendActivation={handleSendActivation}
                                            setLoginMail={setLoginMail}
                                            setCropEmail={setCropEmail}
                                            formatEmail={formatEmail}
                                        />
                                    </React.Fragment>

                                </React.Fragment>
                            }
                            {
                                mailSent &&
                                <Box mt={5}>
                                    <img src='/images/theme/signup-confirmation.svg' />
                                    <Typography align='left' variant="h4" className="mt-4">
                                        !Listo! Revisa tu correo
                                    </Typography>
                                    <Typography align='left' variant='body2' className='text-gray-400 mt-2'>
                                        Te hemos enviado un email {personalMail ? `al correo *******${personalMail}` : ''} con un enlace para que puedas restablecer tu contraseña. ¡Revisa tu bandeja de entrada!
                                    </Typography>
                                    <Typography align='left' variant='body2' className='text-gray-400 mt-2'>
                                        En caso de que no lo hayas recibido, asegúrate de revisar tu carpeta de spam.
                                    </Typography>
                                </Box>

                            }
                            {
                                (statusReset === 0 || statusReset === 1) &&
                                <>
                                    <Divider className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" variant="fullWidth" />
                                    {
                                        mailSent ? <Box mb={7}>
                                            <Typography align='center' variant='body2' className='text-gray-100'>¿Necesitas ayuda? <Link variant="body1" color="secondary" className={classes.helpLink} onClick={contactTechnicalSupport}>Haz clic aquí</Link></Typography>
                                        </Box>
                                            : <Box display="flex" justifyContent="center" mb={7}>
                                                <Typography variant="body1" color="textPrimary" className="mr-1">¿No tienes cuenta aún?</Typography>
                                                <Link component={RouterLink} variant="body1" color="secondary" to={'/signup' + location.search} onClick={() => {
                                                    eventData['source'] = 'login';
                                                    eventTracker('cta_registro', eventData, ['mixpanel', 'hubspot', 'google']);
                                                }}>Registrate aquí</Link>
                                            </Box>
                                    }
                                </>
                            }

                        </CardContent>
                    </Card>
                </Box>
            </LoaderContent>
            <Toast {...toastProps} onFinished={hanldeFinishedToast} />
        </Box>
    );
};

export default ResetPassword;
