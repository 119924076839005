import React, {useEffect} from 'react';
import { generatePath } from "react-router";

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

// shared - components
import Table from '../../../../shared/components/Table/Table'
import LoaderContent from '../../../../shared/components/Loader/LoaderContent'
import dateFormat from '../../../../design-system/utils/dateFormat'
import ConfirmDialog from '../../../../shared/components/ConfirmDialog/ConfirmDialog'
import Toast from '../../../../shared/components/Toast/Toast'
import MoreOptions from '../../../../shared/components/MoreOptions/MoreOptions';
import { log } from '../../../../shared/utils/console.js'

// services
import * as routelinks from '../../../../routes/routelinks';
//SDK
import { RequestService } from '@sdk-point/talisis'; 

const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const columns = [
    { name: 'title', label: 'Servicio', minWidth: 170 },
    { name: 'type', label: 'Tipo de servicio', minWidth: 100 },
    { name: 'ous', label: 'Unidad Operativa', minWidth: 100 },
    { name: 'full_name', label: 'Responsable', minWidth: 100 },
    { name: 'area', label: 'Area', minWidth: 100 },
    { name: 'createdAt', label: 'Tiempo de entrega', minWidth: 120 },
    { name: 'actions', label: 'Acciones', minWidth: 100 },
];

function Actions({item, onClickShow, onClickEdit, onClickDelete}){
    const options = [
        {label: 'Visualizar Flujo', fun: onClickShow(item.id), icon: <VisibilityOutlinedIcon/>},
        {label: 'Editar', fun: onClickEdit(item.id), icon: <EditOutlinedIcon/>},
        {label: 'Eliminar', fun: onClickDelete(item), icon: <DeleteOutlinedIcon/>},
      ];
    
    return (
        <div>
            <MoreOptions options={options} ExAction={true}/>
        </div>
    );
        
    // return <div>
    //     <IconButton color="secondary" aria-label="show" onClick={onClickShow(item.id)} style={{padding: 0, marginLeft: 2}}>
    //         <VisibilityOutlinedIcon />
    //     </IconButton>
    //     <IconButton color="secondary" aria-label="edit" onClick={onClickEdit(item.id)} style={{padding: 0, marginLeft: 2}}>
    //         <EditOutlinedIcon className="font-size-20"/>
    //     </IconButton>
    //     <IconButton color="secondary" aria-label="edit" onClick={onClickDelete(item)} style={{padding: 0}}>
    //         <DeleteOutlinedIcon className="font-size-20"/>
    //     </IconButton>
    // </div>
}

const AllRequests = (props) => {
    const [state, setState] = React.useState({
        loading: true,
        requests: [],
    });
    const [dialogProps, setDialogProps] = React.useState({ 
        title: "",  
        text: "", 
        open: false 
    });
    const [itemSelected, setItemSelected] = React.useState({});
    const [toastProps, setToastProps] = React.useState({});

    useEffect(() => {
        if('boolean' === typeof props.canReadWrite){
            getRequests(props.canReadWrite);
        }
    }, [props]);

    const handleClickShow = id => event => {
        let path =  generatePath(routelinks.GESTION_SERVICIO_SHOW, {id});
        props.history.push(path);
    }

    const handleClickEdit = id => event => {
        props.setRequest({
            workflow:[], 
            form:{
                createdAt:new Date(),
                title: '',
                ou: [],
                responsable: '',
                duration: 0,
                description: ''
            },
        })

        const path = generatePath(routelinks.GESTION_SERVICIOS_EDITAR, {id});
        props.history.push(path)
    }

    const handleClickDelete = row => event => {
        setItemSelected(row)
        const text = <React.Fragment>Esta seguro de querer eliminar el trámite <span className="font-italic">"{row.title}"</span>?</React.Fragment>;

        setDialogProps({ open: true, title: "Eliminar Trámite", text })
    }

    const handleClose = () => {
        setItemSelected({})
        setDialogProps({ ...dialogProps, open: false });
    };

    const handleConfirm = () => {
        setState({...state, loading: true});
        deleteRequest();
    };

    const handleFinishedToast = () => {
        setToastProps({message: '', open: false})
    }

    const deleteRequest = async () => {
        try {
            const response = await RequestServiceSDK.deleteRequest(itemSelected.id);
            setToastProps({ open: true, message: response })
            getRequests();
        } catch (e) {
            log('error', e)
        }
    }

    const getRequests = async (canReadWrite) => {
        try {
            const requests = await RequestServiceSDK.getRequests();
            requests.map(it => {
                it.actions = <Actions item={it}
                    canReadWrite={canReadWrite} 
                    onClickShow={handleClickShow} 
                    onClickEdit={handleClickEdit} 
                    onClickDelete={handleClickDelete}
                />;
                it.ous = it.companies.map(c => c.name).join(', ');
                it.createdAt = dateFormat(it.created_at);
                return it;
            })
            setState({loading: false, requests});
        } catch (e) {
            log('error', e)
        }
    }

    return (
        <LoaderContent loading={state.loading} minHeight={200}>
            <Table 
                columns={columns}
                rows={state.requests}
            />

            <ConfirmDialog {...dialogProps} onConfirm={handleConfirm} onClose={handleClose} />
            <Toast {...toastProps} onFinished={handleFinishedToast}/>
        </LoaderContent>
    );
};

export default AllRequests;