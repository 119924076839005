import React from 'react';
import { RecordDocumentService } from '@sdk-point/talisis';
import { useSelector } from 'react-redux';
import FileDetail from '../components/FileDetail';
import { Button, Card, CardContent, Box, Icon, Typography, Grid, Hidden} from '@material-ui/core';
import Select from '../../../design-system/components/Form/Select/Select';
import dateFormat from '../../../design-system/utils/dateFormat';
import { SIMPLE_DATE_FORMAT } from '../../../design-system/constants/date-formats';
import getImageFromAssets from '../../../design-system/utils/getImageFromAssets';

import { makeStyles } from '@material-ui/styles';

const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root:{
        paddingBottom: 100
    },
    header:{
        display: 'flex',
        justifyContent: 'space-between'
    },
    filterSelectBox:{
        display: 'flex',
        alignItems:'center',
        height: '100%',
        width: '100%',
        maxWidth: 324,
        marginLeft:'auto',
        gridGap: 8,
        '& .select-filter':{
            width: 200
        }
    },
    cardContent: {
        display:'inline-flex',
        width: '100%',
        alignItems:'center',
        paddingBottom:16,
        '& .box-button':{
            marginLeft: 'auto'
        }
    },
    [theme.breakpoints.down('xs')]:{
        header:{
            flexDirection: 'column',
            gridGap: 16
        },
        filterSelectBox:{
            background: theme.palette.colors.fillStroke[400],
            borderRadius: 8,
            padding: '8px 16px',
            flexDirection: 'column',
            alignItems: 'start',
            marginLeft:0,
            maxWidth: '100%',
            '& .select-filter':{
                width: '100%'
            }
        },
        cardContent: {
            flexDirection: 'column',
            '& .box-button':{
                marginTop: 16,
                width: '100%',
                marginLeft: 0
            },
            '& .icon-file':{
                fontSize: '40px !important'
            }
        },
    }
}))

const SchoolRecord = () => {
    const classes = useStyles();

    const { person_id } = useSelector((state) => state.userReducer.user);
    const [loading, setLoading] = React.useState(true);
    const [documents, setDocuments] = React.useState([]);
    const [requestList, setRequestList] = React.useState([]);
    const [requestSelected, setRequestSelected] = React.useState('all');

    React.useEffect(()=> {
        getDocuments();
    }, [requestSelected]);

    React.useEffect(()=> {
        getFilters();
    }, []);

    const getFilters = async () => {
        try {
            const response = await RecordDocumentServiceSDK.getStudentRecordTypes(person_id);
            setRequestList([
                {id: 'all', label: 'Todas'},
                ...response.requests
            ]);
        } catch (error) {
        }
    }

    const getDocuments = async () => {
        setLoading(true)
        try {
            const response = await RecordDocumentServiceSDK.getStudentRecord(person_id, {requestId: requestSelected === 'all' ? '' : requestSelected});
            setDocuments(response.documents)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleChange = (event) => {
        setRequestSelected(event.target.value);
    };

    const loadingState = [...Array(4).keys()].map((index) => (
        <Card key={`school-record-loading-${index}`}>
            <CardContent component={Box} display='inline-flex' width={1} alignItems='center' gridGap={8} pb={2}>
                    <Box height={40} width={40} className="card-skeleton-v2"></Box>
                    <Box display='flex' flex={1} flexDirection='column' gridGap={8}>
                        <Box height={16} width={300} className="card-skeleton-v2"></Box>
                        <Box height={14} width={100} className="card-skeleton-v2"></Box>
                    </Box>
                    <Box height={40} width={219} className="card-skeleton-v2"></Box>
            </CardContent>
        </Card>
    ))

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} md={9}>
                <Box className={classes.header}>
                    <Box>
                        <Typography variant='h4' className='text-violet-300 mb-1'>Expediente escolar</Typography>
                        <Typography className='variant-xsmall text-gray-100'>Repositorio de documentos aprobados.</Typography>
                    </Box>
                    <Box>
                        <Box className={classes.filterSelectBox}>
                            <Typography variant='body2' className='text-gray-100'>Filtrar por trámite</Typography>
                            <Box className='select-filter'>
                                <Select
                                    value={requestSelected}
                                    onChange={handleChange}
                                    placeholder='Servicio estudiantil'
                                    name="ejemplo"
                                    items={requestList}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={9}>
                <Box display='flex' flexDirection='column' gridGap={8}>
                    {
                        loading && loadingState
                    }
                    {
                        !loading && documents && 
                        documents.map((docObj, index) =>                        
                            <Card key={`school-record-doc-${index}`}>
                                <CardContent className={classes.cardContent}>
                                    <FileDetail 
                                        title={<span className='semi-bold'>{docObj.name}</span>}
                                        filePath={docObj.path}
                                        dueDateText="Fecha"
                                        dueDate={<Typography component="span" variant='body2' className='text-gray-100'>{dateFormat(docObj.last_updated, SIMPLE_DATE_FORMAT)}</Typography>}
                                        active={false}
                                        downloadableFile={false}
                                        gridGap={8}
                                    />
                                    <Box className="box-button">
                                        <Button 
                                            fullWidth
                                            variant='outlined' 
                                            color='secondary' 
                                            startIcon={<Icon className='ri-download-line' />} 
                                            disableRipple 
                                            href={docObj.path}>
                                                Descargar documento
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        )
                    }
                    {
                        !loading && !documents.length &&

                        <Card>
                            <Box px={3} py={4} textAlign='center'>
                                <img src={getImageFromAssets('themeSearching')} />
                                <Box pt={3} display='flex' flexDirection='column' gridGap={8}>
                                    <Typography variant='body1' className='text-gray-100 semi-bold'>Aún no cuentas con documentos en tu expediente</Typography>
                                    <Typography variant='body2' className='text-gray-100'>Los documentos que envíes a través de los diferentes trámites aparecerán aquí.</Typography>
                                </Box>
                            </Box>
                        </Card>
                    }
                </Box>
            </Grid>
        </Grid>
    );
};

export default SchoolRecord;