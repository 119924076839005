import React, { Component } from 'react';
import moment from 'moment';

// @redux
import { connect } from "react-redux";

// @shared
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb'
import Loader from '../../../shared/components/Loader/LoaderContent'
import Picker from '../../../shared/components/Form/Picker'
import TextError from '../../../shared/components/TextError/TextError'
import Toast from '../../../shared/components/Toast/Toast'
import TreeList from '../../../shared/components/TreeList/TreeList'
import { checkItem, changeCheckParent, transformToNestedList, someItemChecked, getAllChecked } from './treeListVariations';

// SDK
import { FeedService, GlobalService } from '@sdk-point/talisis';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/InsertLink';
import { InputAdornment, MenuItem } from '@material-ui/core';

const items = [
    { to: '/home', label: 'Home' },
    { label: 'Feed' },
]

const FORMAT_OPTIONS_METADATA = {
    1: {
        label: 'Sólo Texto',
        value: 'text'
    },
    2: {
        label: 'Texto e Imagen',
        value: 'text/image'
    },
    3: {
        label: 'Texto e Imagenes (carrusel)',
        value: 'text/images'
    },
    4: {
        label: 'Texto y Archivos PDF adjuntos',
        value: 'text/files'
    },
    5: {
        label: 'Texto y Video de YouTube',
        value: 'text/youtube'
    },
};

const FeedServiceSDK = new FeedService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

class FeedNew extends Component {
    state = {
        error: false,
        errors: {},
        form: {
            title: '',
            body: '',
            published_date: new Date(),
            type_id: 3,
            files: '',
            url: '',
            format_option: 1,
            format_option_label: 'Ningun archivo selec.',
        },
        unitsPermissionList: [],
        loading: true,
        toastProps: {
            message: '',
        },
    };

    async componentDidMount() {
        this.setState({ loading: true });
        const units = ['UERRE', 'UNID', 'HH', 'IE', 'TAL'];

        try {
            const operatingUnits = await FeedServiceSDK.getOperatingUnits();
            const _operatingUnits = operatingUnits.filter(it => units.some(u => u === it.id))
            const unitsPermissionList = transformToNestedList(_operatingUnits);
            this.setState({ error: false, loading: false, unitsPermissionList });
        }
        catch (err) {
            this.setState({ error: true, toastProps: { severity: "error", open: true, message: 'Ops! ha ocurrido un error.' } });
        }
    }

    validate = () => {
        const errors = {};
        const { form, unitsPermissionList } = this.state;
        const requiredFields = ['title', 'body', 'format_option'];
        for (const field of requiredFields) {
            if (!form[field]) {
                errors[field] = 'Campo Requerido*';
            }
        }

        const formatOption = form.format_option;
        if ([2, 3, 4].includes(formatOption)) {
            const validateFiles = this.validateFiles();
            if (!validateFiles.success) {
                errors.format_option = validateFiles.errorMessage;
            }
        }

        if (!someItemChecked(unitsPermissionList)) {
            errors.permission = 'Campo Requerido*';
        }

        this.setState({ errors });
        return Boolean(Object.keys(errors).length === 0);
    }

    validateFiles = () => {
        let success = true;
        let errorMessage = '';
        const { form } = this.state;
        if (form.files && form.files.length > 0) {
            // Validación de tamaño de archivo
            for (let i = 0; i <= form.files.length - 1; i++) {
                if (form.format_option === 4) {
                    if (form.files[i].size > 256000) {
                        errorMessage = 'Uno o varios de los archivos excede del tamaño permitido (250kb)*';
                        success = false;
                    }
                }
                else {
                    if (form.files[i].size > 1048576) {
                        errorMessage = 'Uno o varios de los archivos excede del tamaño permitido (1MB)*';
                        success = false;
                    }
                }
            }

            // Validación Carrusel de imagenes
            if (form.files.length === 1 && form.format_option === 3) {
                errorMessage = 'Solamente se detectó una imagen y es necesario cargar dos o más, ya que el formato de publicación seleccionado así lo requiere.';
                success = false;
            }
        }
        else {
            errorMessage = 'Ningun archivo selec.';
            success = false;
        }

        return {
            success,
            errorMessage
        };
    };

    handleChange = e => {
        const { name, value } = e.target;
        const errors = { ...this.state.errors };
        if (value) {
            delete errors[name];
        }

        this.setState({ form: { ...this.state.form, [name]: value }, errors });
        if (name === 'files') {
            const files = document.getElementById(name).files;
            delete errors['format_option'];
            this.setState({ errors });
            this.setState({ form: { ...this.state.form, files: files, format_option_label: `${files.length} archivo(s)` } });
        }
    };

    handleFormatChange = (e) => {
        const { value } = e.target;
        this.setState({ form: { ...this.state.form, url: '', files: '', format_option: value, format_option_label: '' } });
    };

    handleLimitTitle = (e) => {
        const maxChars = 60;
        let newTitle = e.target.value;
        if (newTitle.length >= maxChars) {
            const errors = { ...this.state.errors };
            errors.title = "*El límite permitido en el título es de 60 caracteres.";
            this.setState({ form: { ...this.state.form, title: newTitle.substr(0, maxChars), }, errors });
            return false;
        }

        return true;
    };

    handleCheck = itemSelected => {
        const items = [...this.state.unitsPermissionList];
        checkItem(items, 'id', itemSelected, 'children');
        changeCheckParent(items, itemSelected.parent, items);
        this.setState({ unitsPermissionList: items });
    }

    handleSubmit = async () => {
        if (this.validate()) {
            this.setState({ loading: true, error: false })

            const { form, unitsPermissionList } = this.state;
            const files = form.files;
            const itemsChecked = getAllChecked(unitsPermissionList[0].children);

            form.published_date = moment(form.published_date).format('YYYY-MM-DD HH:mm:ss');
            const body = {
                published_date: form.published_date,
                created_by: this.props.user.person_id,
                title: form.title,
                // photo_url: '', @NOTE: deshabilitado, para soportar multiples archivos por publicación.
                body: form.body,
                url: form.url,
                type_id: form.type_id,
                format: FORMAT_OPTIONS_METADATA[form.format_option]['value'],
                companies: itemsChecked.filter(it => it.level === 2).map(obj => obj.id),
                campus: itemsChecked.filter(it => it.level === 3).map(obj => obj.id),
                persons: [],
            };
            
            try {
                const response = await FeedServiceSDK.createFeed(body);
                if (files && files.length > 0 && response.id) {
                    for (let i = 0; i <= files.length - 1; i++) {
                        await GlobalServiceSDK.uploadFile(files[i], 'feed', 'feed-entry', response.id);
                    }
                }

                this.setState({ error: false, loading: false, toastProps: { open: true, message: 'La publicación ha sido creada correctamente.' } });
            }
            catch (error) {
                this.setState({ loading: false, error: true, toastProps: { severity: "error", open: true, message: 'Ops! Ha ocurrido un error.' } });
            }
        }
    }

    hanldeFinishedToast = () => {
        if (this.state.error) {
            const toastProps = { ...this.state.toastProps };
            toastProps.open = false;
            this.setState({ toastProps });
        }
        else {
            this.props.history.push('/home');
        }
    }

    render() {
        const { form, errors, loading, unitsPermissionList } = { ...this.state };
        return (
            <div>
                <Breadcrumb items={items} />
                <Grid container spacing={3}>
                    <Grid container item xs={12}>
                        <Loader loading={loading}>
                            <Card>
                                <CardContent>
                                    <Typography variant="subtitle2" className="mb-4">Noticia</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                margin="none"
                                                variant="outlined"
                                                label="Titulo"
                                                fullWidth
                                                value={form.title || ''}
                                                name="title"
                                                onChange={(e) => {
                                                    this.handleChange(e);
                                                    this.handleLimitTitle(e);
                                                }}
                                                error={Boolean(errors.title)}
                                                helperText={errors.title}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Picker
                                                margin="none"
                                                name="published_date"
                                                value={form.published_date}
                                                onChange={this.handleChange}
                                                label="Fecha de Publicación"
                                                minDate={new Date()}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                margin="none"
                                                variant="outlined"
                                                label="Cuerpo"
                                                fullWidth
                                                multiline
                                                minRows={8}
                                                value={form.body}
                                                name="body"
                                                onChange={this.handleChange}
                                                error={Boolean(errors.body)}
                                                helperText={errors.body}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={4}>
                                            <TextField
                                                select
                                                required
                                                margin="none"
                                                label="Formato de publicación"
                                                name="format_option"
                                                value={form.format_option}
                                                onChange={(e) => {
                                                    this.handleFormatChange(e);
                                                }}
                                                fullWidth
                                                variant='outlined'
                                                error={Boolean(errors.format_option)}
                                                helperText={errors.format_option}>
                                                {[
                                                    { id: 1, label: FORMAT_OPTIONS_METADATA[1]['label'] },
                                                    { id: 2, label: FORMAT_OPTIONS_METADATA[2]['label'] },
                                                    { id: 3, label: FORMAT_OPTIONS_METADATA[3]['label'] },
                                                    { id: 4, label: FORMAT_OPTIONS_METADATA[4]['label'] },
                                                    { id: 5, label: FORMAT_OPTIONS_METADATA[5]['label'] },
                                                ].map(option => (
                                                    <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {form.format_option === 2 &&
                                                <>
                                                    <input id="files" name="files" type="file" style={{ display: 'none' }} accept="image/*" onChange={this.handleChange} />
                                                    <label htmlFor="files">
                                                        <Button variant="contained" color="secondary" component="span" style={{ marginTop: '5px' }}>Cargar imagen</Button>
                                                        <span style={{ marginLeft: '10px' }}>{form.format_option_label}</span>
                                                    </label>
                                                    <br />
                                                    <span style={{ color: 'gray' }}>*Tamaño máximo recomendado 1MB.</span>
                                                </>
                                            }
                                            {form.format_option === 3 &&
                                                <>
                                                    <input id="files" name="files" type="file" style={{ display: 'none' }} accept="image/*" multiple onChange={this.handleChange} />
                                                    <label htmlFor="files">
                                                        <Button variant="contained" color="secondary" component="span" style={{ marginTop: '5px' }}>Cargar imagenes</Button>
                                                        <span style={{ marginLeft: '10px' }}>{form.format_option_label}</span>
                                                    </label>
                                                    <br />
                                                    <span style={{ color: 'gray' }}>*Tamaño máximo recomendado 1MB.</span>
                                                </>
                                            }
                                            {form.format_option === 4 &&
                                                <>
                                                    <input id="files" name="files" type="file" style={{ display: 'none' }} accept="application/pdf" multiple onChange={this.handleChange} />
                                                    <label htmlFor="files">
                                                        <Button variant="contained" color="secondary" component="span" style={{ marginTop: '5px' }}>Cargar archivos</Button>
                                                        <span style={{ marginLeft: '10px' }}>{form.format_option_label}</span>
                                                    </label>
                                                    <br />
                                                    <span style={{ color: 'gray' }}>*Archivos PDF. Tamaño máximo recomendado 250kb.</span>
                                                </>
                                            }
                                            {form.format_option === 5 &&
                                                <TextField
                                                    id="url"
                                                    name="url"
                                                    variant="outlined"
                                                    label="URL del video"
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><LinkIcon /></InputAdornment>,
                                                    }}
                                                    margin="normal"
                                                    value={form.url}
                                                    style={{margin: 0}}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Loader>
                    </Grid>
                    <Grid container item xs={12}>
                        <Loader loading={loading}>
                            <Card style={{ minHeight: 200 }}>
                                <CardContent>
                                    <Typography variant="subtitle2" className="mb-4">¿Quién puede ver la publicación? *</Typography>
                                    <TreeList items={unitsPermissionList} onCheck={this.handleCheck} />
                                    {errors.permission && <TextError text={errors.permission} />}
                                </CardContent>
                            </Card>
                        </Loader>
                    </Grid>
                    <Grid container item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            <Button color="secondary" variant="contained" onClick={this.handleSubmit}>Crear</Button>
                        </Box>
                    </Grid>
                </Grid>
                <Toast {...this.state.toastProps} onFinished={this.hanldeFinishedToast} duration={3000} />
            </div>
        );
    }
}

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(FeedNew);