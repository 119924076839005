import React, { useState } from 'react';

// @material-ui
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';

const WorkflowPage = ({ title, children }) => {
    return (
        <Box className="workflow-page">
            <Typography variant='h3' className="text-violet-300 font-weight-700">{title}</Typography>
            <Box className="workflow-content">{children}</Box>
        </Box>
    );
};

export default WorkflowPage;