import { makeStyles } from "@material-ui/core";
import typography from '../../../../../design-system/theme/typography';

export const membershipPeriodsStyles = makeStyles((theme) => ({
    priceContainer:
    {
        cursor: 'pointer',
        width:'auto', 
        border: '0.5px solid #404150',
        borderRadius:'8px',
        '&:hover':
        {
            border: `0.5px solid ${theme.palette.colors.violet[300]}`,
            backgroundColor: `${theme.palette.colors.fillStroke[400]}`
        }
    },
    priceContainerWithSaving:
    {
        cursor: 'pointer',
        width:'auto', 
        border: '0.5px solid #404150',
        borderTopLeftRadius:'8px',
        borderTopRightRadius:'0px',
        borderBottomLeftRadius:'8px',
        borderBottomRightRadius:'8px',
        '&:hover':
        {
            border: `0.5px solid ${theme.palette.colors.violet[300]}`,
            backgroundColor: `${theme.palette.colors.fillStroke[400]}`
        }
    },
    priceContainerSelected:
    {
        border: `0.5px solid ${theme.palette.colors.violet[300]}`,
        backgroundColor: `${theme.palette.colors.fillStroke[400]}`
    },
    priceContainerTitle:
    {
        ...typography.v10
    },
    priceContainerSubTitle:
    {
        color: '#B8B8BB'
    },
    priceContainerSubTitlePrice:
    {
        color: theme.palette.colors.orange[300]
    },
    priceContainerFooterTitle:
    {
        ...typography.v5
    },
    priceContainerFooterTitlePrice:
    {
        ...typography.caption,
        fontWeight: '600 !important'
    },
    savingContainer:
    {
        paddingTop:'4px',
        [theme.breakpoints.down('md')]:
        {
            paddingTop:'2px',
        }
    },
    savingTitle:
    {
        ...typography.caption,
        fontWeight: '600 !important',
        backgroundColor:'#105870',
        borderBottomLeftRadius:'8px'
    },
    priceCrossedOut:
    {
        display:'inline-block',
        textDecoration:'line-through'
    }
}));
