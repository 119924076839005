import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// @material-ui/core
import { Box, Button, Card, CardContent, Icon, Typography } from '@material-ui/core';

// Styles
import useStyles from '../../../assets/styles/components/cards/cardContinueLearningStyle';

const CardContinueLearning = ({onClickButton, imageUrl, name, labelButton}) => {
    const classes = useStyles({imageUrl});

    const [load, setLoad] = React.useState(false);

    const handleActiveLoad = (e) => {
        setLoad(true);
        onClickButton(e);
        setTimeout(() => {
            setLoad(false);
        }, 2000);
    };

    return (
        <Card className={classes.cardRoot}>
            <CardContent className={classes.cardContentRoot}>
                <Typography variant='h4' className={classes.nameCourse}>{ name }</Typography>
                <Button
                    label={labelButton}
                    onClick={handleActiveLoad}
                    size="medium"
                    variant="contained"
                    className={classes.buttonGoToCourse}
                    disabled={load}
                >
                    {labelButton} <Icon className={clsx(`ri-arrow-right-line`, 'font-size-16', classes.iconButton)}></Icon>
                </Button>
            </CardContent>
        </Card>
  );
};

CardContinueLearning.propTypes = {
    onClickButton: PropTypes.func.isRequired,
    imageUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelButton: PropTypes.string.isRequired,
};

export default CardContinueLearning;