import React from 'react';
import PropTypes from 'prop-types'

// @material-ui/core
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// logos
import { getLogoCard }from '../../../../src/constants/logosImages';


import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import LiveTvOutlinedIcon from '../../../assets/images/logos/landing-icons/microgrados/live_tv_outlined.svg';

import useStyles from '../../../assets/styles/jss/components/sourceDetailStyle';

/*const Logos = ({
    0: LogoTalisisImg,
    5: LogoCourseraImg,
    6: LogoLinkedinImg,
    8: LogoUerreImg,
    9: LogoUnidImg,
    10: LogoHarmonHall,
   12: logo_google_skillshop,
   13: card_crehana_logo,
   14: logo_card_hubspot_academy,
   15: LogoIEPublishing,
});*/

const modalityOptions = ({
    2: "Online",
    3: "Híbrida",
    1: "Presencial",
});

function Level ({value}){
    const levels = [
        {label: 'Básico', height: 4},
        {label: 'Intermedio', height: 6},
        {label: 'Avanzado', height: 8},
    ]

    return <Box display="flex" alignItems="center" mr={1}>
        <Box display="flex" alignItems="end" mr={.5}>
                {
                    levels.map((it, index) => {
                        const isActive = index + 1 <= value;
                        return <Box key={index} width={2} height={it.height} style={{background: '#2ECFE8', marginRight: '1.5px', opacity: isActive ? 'unset' : '30%'}}></Box>
                    })
                }
        </Box>
        <Typography className="font-size-12 font-weight-400" style={{color: '#2ECFE8'}}>{levels[value-1].label}</Typography>
    </Box>
}

const SourceDetail = (props) => {
    const {sourceId, lessons, modality} = props;
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <img loading="lazy" alt="logo" src={ getLogoCard(sourceId) } className={classes.sourceImg}/>
            <Button variant="contained" className={classes.button}>Popular</Button>
            {/*level && <Level value={level} />*/}
           {lessons > 0 && <Box display="flex" alignItems="center" style={{ marginRight: "12px" }}>
                <LibraryBooksOutlinedIcon className={classes.lessons}/>
                <Typography className="font-size-10 font-weight-400" color="secondary">{lessons} Módulos</Typography>
            </Box>}
            {modality &&
                <Grid item>
                    <Box display="flex" alignItems="flex-center">
                        <img alt="live_tv_outlined_icon" className={classes.tv} src={LiveTvOutlinedIcon} />&nbsp;
                        <Typography className={classes.modality_text} color='secondary' component="span" variant="body2">{modalityOptions[modality]}</Typography>
                    </Box>
                </Grid>
            }
            
        </Box>
    );
};

SourceDetail.propTypes = {
    sourceId: PropTypes.number,
    level: PropTypes.number,
    lessons: PropTypes.number
};

export default SourceDetail;