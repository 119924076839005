import React,{useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
// share component 
import Table from '../../../../shared/components/Table/Table'
import ConfirmDialog from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import MoreOptions from '../../../../shared/components/MoreOptions/MoreOptions';

// @material-ui/core
import {Typography, Button, Box} from '@material-ui/core';
// @material-ui/icons
import { Add as AddIcon} from '@material-ui/icons';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

// js
import { columns } from './ColumnaTablePemission';
// service
import * as PermissionService from '../../../../services/PermissionService'
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';
import { log } from '../../../../shared/utils/console.js'

//SDK
import { UserPermXService } from '@sdk-point/talisis'; 

const UserPermXServiceSDK = new UserPermXService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
function Actions({item, onClickDelete}){
    const options = [
        {label: 'Eliminar', fun: onClickDelete(item), icon: <DeleteOutlinedIcon/>, id:1},
    ];
    
    return (
        <div>
            <MoreOptions options={options} ExAction={true}/>
        </div>
    );
}

const TablePermissionByRole = ({idRole}) => {
    const [state, setState] = useState([]);
    const [openModal, setOpenModal] =  useState({open:false, text:''});
    const [permission, setPermission] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const history = useHistory();

    useEffect(()=>{
        getPermissionsByRoleId();
    },[])

    const handleClickDelete = it => (e) => {
        e.stopPropagation();
        setOpenModal({open:true,text:`¿Está seguro que desea eliminar el registro?`});
        setPermission(it); 
    } 

    const getPermissionsByRoleId = async() => {
        try {
            const responsePermissionByIdRol = (idRole)? await PermissionService.getPermissionByRoleId(idRole):[];
            responsePermissionByIdRol.map(it => {
                it.label = '';
                it.actions = <Actions item={it} onClickDelete={handleClickDelete} />;
               return (it)
           })
           setState(responsePermissionByIdRol);
        } catch (error) {
            log('Error get PermissionByIdRol', error)
        }
    }
    
    const handleComfirModal = async (e) => {
        if(e) {
             try {
                setIsLoaded(true);   
                const permissionJSON = {id: permission.id,role_id: permission.role_id, permission_id:permission.permission_id}
                await UserPermXServiceSDK.postUserPerm(permissionJSON,'permission-del')
                const persIndex = state.findIndex(it=> it.role_id === permission.role_id && it.permission_id===permission.permission_id && it.id===permission.id)
                state.splice(persIndex,1);
              } catch (error) {
                log('Error', error)
             }finally {
              setIsLoaded(false);
             }
        }
    }

    const handleOnCloseModal = () => {
        setOpenModal({open:false,text:''});
    }
   
    return(
        <> 
            <Typography variant="subtitle2" className="mb-4 text-center">Permisos</Typography>
            <Box  display="flex" justifyContent="flex-end" width="100%">
                <Button color="primary" startIcon={<AddIcon />} onClick={(e)=> {history.push(`/roles/${idRole}/permisos`)}}>Nuevo Permiso</Button>
            </Box>
            <LoaderContent loading={isLoaded} minHeight={200}>
            {
            state.length>0 &&
                <Table 
                    columns={columns}
                    rows={state}
                />
            }
            </LoaderContent>   
            <ConfirmDialog open={openModal.open} onConfirm={handleComfirModal} onClose={handleOnCloseModal} title='Confirmar' text={openModal.text} />

        </>
    )
}

export default TablePermissionByRole;