import { makeStyles } from "@material-ui/core";

export const newCardModalStyles = makeStyles((theme) => ({
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingLeft: '104px',
        paddingRight: '104px',
        border: '0.5px solid #404150',
        borderRadius: '8px',
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    modalInside:{
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    proceedButton:
    {
        fontSize: '16px',
        color: 'white',
        fontWeight: '600',
        width: '260px',
        textTransform: 'none',
        "@media (max-width: 510px)": {
            width: '100%'
        }
    },
}));
