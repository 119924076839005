import {
    SOURCES
} from './sources'

import courseraLogoWhite from '../../design-system/assets/images/logos/coursera-white.svg'
// import uerreLogoWhite from '../assets/images/logos/sources/uerre-white.svg';
// import awsLogoWhite from '../assets/images/logos/sources/aws-white.svg';
// import hhLogoWhite from '../assets/images/logos/sources/harmon-hall-white.svg';

import ieLogoWhite from '../../design-system/assets/images/logos/ie-university-white.svg';

// hubspot
import hubspotLogoWhiteColor from '../../design-system/assets/images/logos/hubspot-white-color.svg';

// crehana
import crehanaLogoWhite from '../../design-system/assets/images/logos/crehana-white.svg';

// aws-skill-builder
import awsSkillBuilderLogoWhiteColor from '../../design-system/assets/images/logos/aws-skill-builder-white-color.svg';

// talisis
import talisisResponsive from '../../design-system/assets/images/logos/talisis-responsive.svg';
import talisisWhiteResponsive from '../../design-system/assets/images/logos/talisis-white-responsive.svg';

// google
import googleSkillshopLogo from '../../design-system/assets/images/logos/google.svg';

// linkedIn-Learning
import linkedinLearningLogoWhite from '../../design-system/assets/images/logos/linkedin-learning-white.svg';
// import unidLogoWhite from '../assets/images/logos/sources/unid-white.svg';
// import hubspotLogoWhiteColor from '../assets/images/logos/hubspot-white-color.svg'

// spin
import spinLogo from '../../design-system/assets/images/logos/spin.svg';

// GREY
import advenioLogoGrey from '../../design-system/assets/images/logos/advenio-grey.svg';
import iesaludLogoGrey from '../../design-system/assets/images/logos/iesalud-grey.svg';
import uerreLogoGrey from '../../design-system/assets/images/logos/uerre-grey.svg';
import unidLogoGrey from '../../design-system/assets/images/logos/unid-grey.svg';
import harmonHallLogoGrey from '../../design-system/assets/images/logos/harmon-hall-grey.svg';
import harmonHallLogoYellow from '../../design-system/assets/images/logos/harmon-hall-yellow.svg';

// SIMPLE-WHITE
import advenioSimpleLogoWhite from '../../design-system/assets/images/logos/advenio-simple-white.svg';
import iesaludSimpleLogoWhite from '../../design-system/assets/images/logos/iesalud-simple-white.svg';

// BLACK-COLOR
import talisisBLackColor from '../../design-system/assets/images/logos/talisis-black-color.svg';

export const LOGOS = ({
    default:{
        [SOURCES.SPIN]: spinLogo,
//         [SOURCES.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/advenio.svg",
//         4: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/l%26d.svg",
//         [SOURCES.COURSERA]: courseraLogoWhite,
//         [SOURCES.LINKEDIN_LEARNING]: linkedinLearningLogoWhite,
//         [SOURCES.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis.svg",
//         [SOURCES.UERRE]: uerreLogoWhite,
//         [SOURCES.UNID]: unidLogoWhite,
//         [SOURCES.HH]: hhLogoWhite,
//         [SOURCES.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/isesalud.svg",
        [SOURCES.GOOGLE]: googleSkillshopLogo,
//         [SOURCES.CREHANA]: crehanaLogoWhite,
//         [SOURCES.HUBSPOT]: hubspotLogoWhite,
//         [SOURCES.IE_UNIVERSITY]: ieLogoWhite,
//         [SOURCES.AWS]: awsLogoWhite,
//         17: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/Symplicity.svg",
//         18: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/NEO.svg",
//         [SOURCES.AWS_SKILL_BUILDER]: awsLogoWhite,
    },
    white:{
        [SOURCES.SPIN]: spinLogo,
        [SOURCES.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/advenio_white.svg",
        [SOURCES.NEO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ld_white.svg",
        [SOURCES.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/coursera_white.svg",
        [SOURCES.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/linkedin_white.svg",
        [SOURCES.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/talisis_white.svg",
        [SOURCES.UERRE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/uerre_white.svg",
        [SOURCES.UNID]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/unid_white.svg",
        [SOURCES.HH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/hh_white.svg",
        [SOURCES.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/iesalud_white.svg",
        [SOURCES.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/google_white.svg",
        [SOURCES.CREHANA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/crehana_white.svg",
        [SOURCES.HUBSPOT]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/hubspot_white.svg",
        [SOURCES.IE_UNIVERSITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ieuniversity_white.svg",
        [SOURCES.AWS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/aws_white.svg",
        [SOURCES.SIMPLICITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/symplicity_white.svg",
        [SOURCES.CONEKTA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/neo.svg",
        [SOURCES.AWS_SKILL_BUILDER]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/aws_white.svg",
        [SOURCES.NEO_CH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/neoch_white.svg",
    },
    whiteColor:{
        [SOURCES.SPIN]: spinLogo,
        [SOURCES.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/advenio_white.svg",
        [SOURCES.NEO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ld_white.svg",
        [SOURCES.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/coursera_white.svg",
        [SOURCES.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/linkedin_white.svg",
        [SOURCES.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/talisis_white.svg",
        [SOURCES.UERRE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/uerre_white.svg",
        [SOURCES.UNID]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/unid_whiteColor.svg",
        [SOURCES.HH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/hh_white.svg",
        [SOURCES.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/iesalud_white.svg",
        [SOURCES.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/google_whiteColor.svg",
        [SOURCES.CREHANA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/crehana_whiteColor.svg",
        [SOURCES.HUBSPOT]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/hubsport_whiteColor.svg",
        [SOURCES.IE_UNIVERSITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ieuniversity_white.svg",
        [SOURCES.AWS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/aws_whiteColor.svg",
        [SOURCES.SIMPLICITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/symplicity_white.svg",
        [SOURCES.CONEKTA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/neo.svg",
        [SOURCES.AWS_SKILL_BUILDER]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/aws_white.svg",
        [SOURCES.NEO_CH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/neoch_whiteColor.svg",
    },
    grey:{
        [SOURCES.SPIN]: spinLogo,
        [SOURCES.ADVENIO]: advenioLogoGrey,
        [SOURCES.NEO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ld_white.svg",
        [SOURCES.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/coursera_white.svg",
        [SOURCES.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/linkedin_white.svg",
        [SOURCES.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/talisis_white.svg",
        [SOURCES.UERRE]: uerreLogoGrey,
        [SOURCES.UNID]: unidLogoGrey,
        [SOURCES.HH]: harmonHallLogoGrey,
        [SOURCES.IESALUD]: iesaludLogoGrey,
        [SOURCES.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/google_whiteColor.svg",
        [SOURCES.CREHANA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/crehana_whiteColor.svg",
        [SOURCES.HUBSPOT]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/hubsport_whiteColor.svg",
        [SOURCES.IE_UNIVERSITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ieuniversity_white.svg",
        [SOURCES.AWS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/aws_whiteColor.svg",
        [SOURCES.SIMPLICITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/symplicity_white.svg",
        [SOURCES.CONEKTA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/neo.svg",
        [SOURCES.AWS_SKILL_BUILDER]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/aws_white.svg",
        [SOURCES.NEO_CH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/neoch_whiteColor.svg",
    },
    simpleWhite:{
        [SOURCES.SPIN]: spinLogo,
        [SOURCES.ADVENIO]: advenioSimpleLogoWhite,
        [SOURCES.NEO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ld_white.svg",
        [SOURCES.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/coursera_white.svg",
        [SOURCES.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/linkedin_white.svg",
        [SOURCES.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/talisis_white.svg",
        [SOURCES.UERRE]: uerreLogoGrey,
        [SOURCES.UNID]: unidLogoGrey,
        [SOURCES.HH]: harmonHallLogoGrey,
        [SOURCES.IESALUD]: iesaludSimpleLogoWhite,
        [SOURCES.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/google_whiteColor.svg",
        [SOURCES.CREHANA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/crehana_whiteColor.svg",
        [SOURCES.HUBSPOT]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/hubsport_whiteColor.svg",
        [SOURCES.IE_UNIVERSITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ieuniversity_white.svg",
        [SOURCES.AWS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/aws_whiteColor.svg",
        [SOURCES.SIMPLICITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/symplicity_white.svg",
        [SOURCES.CONEKTA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/neo.svg",
        [SOURCES.AWS_SKILL_BUILDER]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/aws_white.svg",
        [SOURCES.NEO_CH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/neoch_whiteColor.svg",
    },
    black:{
        [SOURCES.SPIN]: spinLogo,
        [SOURCES.ADVENIO]: advenioLogoGrey,
        [SOURCES.NEO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ld_white.svg",
        [SOURCES.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/coursera_white.svg",
        [SOURCES.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/linkedin_white.svg",
        [SOURCES.TALISIS]: talisisBLackColor,
        [SOURCES.UERRE]: uerreLogoGrey,
        [SOURCES.UNID]: unidLogoGrey,
        [SOURCES.HH]: harmonHallLogoGrey,
        [SOURCES.IESALUD]: iesaludLogoGrey,
        [SOURCES.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/google_whiteColor.svg",
        [SOURCES.CREHANA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/crehana_whiteColor.svg",
        [SOURCES.HUBSPOT]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/hubsport_whiteColor.svg",
        [SOURCES.IE_UNIVERSITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ieuniversity_white.svg",
        [SOURCES.AWS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/aws_whiteColor.svg",
        [SOURCES.SIMPLICITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/symplicity_white.svg",
        [SOURCES.CONEKTA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/neo.svg",
        [SOURCES.AWS_SKILL_BUILDER]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/aws_white.svg",
        [SOURCES.NEO_CH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/neoch_whiteColor.svg",
    },
    blackColor:{
        [SOURCES.SPIN]: spinLogo,
        [SOURCES.ADVENIO]: advenioLogoGrey,
        [SOURCES.NEO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ld_white.svg",
        [SOURCES.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/coursera_white.svg",
        [SOURCES.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/linkedin_white.svg",
        [SOURCES.TALISIS]: talisisBLackColor,
        [SOURCES.UERRE]: uerreLogoGrey,
        [SOURCES.UNID]: unidLogoGrey,
        [SOURCES.HH]: harmonHallLogoGrey,
        [SOURCES.IESALUD]: iesaludLogoGrey,
        [SOURCES.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/google_whiteColor.svg",
        [SOURCES.CREHANA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/crehana_whiteColor.svg",
        [SOURCES.HUBSPOT]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/hubsport_whiteColor.svg",
        [SOURCES.IE_UNIVERSITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/ieuniversity_white.svg",
        [SOURCES.AWS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/aws_whiteColor.svg",
        [SOURCES.SIMPLICITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/symplicity_white.svg",
        [SOURCES.CONEKTA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/neo.svg",
        [SOURCES.AWS_SKILL_BUILDER]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/white/aws_white.svg",
        [SOURCES.NEO_CH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/whiteColor/neoch_whiteColor.svg",
    },
    yellow: {
        [SOURCES.HH]: harmonHallLogoYellow
    }
});


export const LOGOS_RESPONSIVE = ({
    default:{
//         [SOURCES.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/advenio.svg",
//         4: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/l%26d.svg",
//         [SOURCES.COURSERA]: courseraLogoWhite,
//         [SOURCES.LINKEDIN_LEARNING]: linkedinLearningLogoWhite,
        [SOURCES.TALISIS]: talisisResponsive,
//         [SOURCES.UERRE]: uerreLogoWhite,
//         [SOURCES.UNID]: unidLogoWhite,
//         [SOURCES.HH]: hhLogoWhite,
//         [SOURCES.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/isesalud.svg",
        // [SOURCES.GOOGLE]: googleSkillshopLogo,
//         [SOURCES.CREHANA]: crehanaLogoWhite,
//         [SOURCES.HUBSPOT]: hubspotLogoWhite,
//         [SOURCES.IE_UNIVERSITY]: ieLogoWhite,
//         [SOURCES.AWS]: awsLogoWhite,
//         17: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/Symplicity.svg",
//         18: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/NEO.svg",
//         [SOURCES.AWS_SKILL_BUILDER]: awsLogoWhite,
    },
    white:{
//         [SOURCES.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/advenio.svg",
//         4: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/l%26d.svg",
        // [SOURCES.COURSERA]: courseraLogoWhite,
        // [SOURCES.LINKEDIN_LEARNING]: linkedinLearningLogoWhite,
        [SOURCES.TALISIS]: talisisWhiteResponsive,
//         [SOURCES.UERRE]: uerreLogoWhite,
//         [SOURCES.UNID]: unidLogoWhite,
//         [SOURCES.HH]: hhLogoWhite,
//         [SOURCES.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/isesalud.svg",
        // [SOURCES.GOOGLE]: googleSkillshopLogo,
        // [SOURCES.CREHANA]: crehanaLogoWhite,
        // [SOURCES.HUBSPOT]: hubspotLogoWhite,
        // [SOURCES.IE_UNIVERSITY]: ieLogoWhite,
//         [SOURCES.AWS]: awsLogoWhite,
//         17: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/Symplicity.svg",
//         18: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/NEO.svg",
//         [SOURCES.AWS_SKILL_BUILDER]: awsLogoWhite,
    },
    whiteColor:{
//         [SOURCES.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/advenio.svg",
//         4: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/l%26d.svg",
//         [SOURCES.COURSERA]: courseraLogoWhite,
//         [SOURCES.LINKEDIN_LEARNING]: linkedinLearningLogoWhite,
//         [SOURCES.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis.svg",
//         [SOURCES.UERRE]: uerreLogoWhite,
//         [SOURCES.UNID]: unidLogoWhite,
//         [SOURCES.HH]: hhLogoWhite,
//         [SOURCES.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/isesalud.svg",
        // [SOURCES.GOOGLE]: googleLogoWhite,
//         [SOURCES.CREHANA]: crehanaLogoWhite,
        // [SOURCES.HUBSPOT]: hubspotLogoWhiteColor,
//         [SOURCES.IE_UNIVERSITY]: ieLogoWhite,
//         [SOURCES.AWS]: awsLogoWhite,
//         17: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/Symplicity.svg",
//         18: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/NEO.svg",
        // [SOURCES.AWS_SKILL_BUILDER]: awsSkillBuilderLogoWhiteColor,
    },
    grey:{

    },
    simpleWhite:{

    },
    black:{

    },
    blackColor:{
        
    }
});