import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    chip:{
        padding: theme.spacing(.5),
        '&:not(:last-child)':{
            marginRight: theme.spacing(1)
        },
        '& .MuiChip-icon':{
            color: theme.palette.colors.white
        }
    },
    sourceLabel:{
        background: 'rgba(28, 31, 36, 0.8)'
    },
    root:{
        position: 'relative',
        zIndex: 5,
        width: '392px',
        height: "167px",
    },
    bg: {
        position: 'absolute',
        zIndex: '-1',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2))',
        /*backgroundImage: props => props.bgImage,*/
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        filter:  props => props.imageColor ? 'unset' : 'brightness(1)',
        overflowY: "hidden",
        '& img':{
            width: '100%',
            minHeight: '100%',
            
        }
    },
    container:{
        height: "calc(100% - 32px)",
        padding: "16px",
        boxSizing: "content-box"
    },
    raiting:{
        pointerEvents: "none",
    },
    saveIcon:{
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "24px",
        maxWidth: "24px",
        height: "24px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        '&:hover':{
            backgroundColor: '#99999996 !important'
        },
    },
    loading:{
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    sourceImg:{
        maxWidth: "82px",
        height: "fit-content",
    },
    tv:{ 
        width: "10px",
        marginRight: "4px",
    },
    modality_text:{
        fontSize: "12px",
        lineHeight: "16px",
    },
    bg_degree:{
        position: "absolute",
        top: "0px",
        left: "0px",
        boxSizing: "content-box", 
        height: "167px", 
        width: "392px",
        background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 61.46%, rgba(0, 0, 0, 0.568) 100%)",
        backgroundBlendMode: "multiply, normal, normal",
    }
}));