import React, {useRef, useState, useEffect} from "react";
// @material-ui/core
import {Card, Button, Box} from '@material-ui/core'; 
// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';

import Dropdown from '../../shared/components/Dropdown/Dropdown';
import { getSessionCookie } from "../../shared/utils/Sessions";
//SDK
import { AllysService } from '@sdk-point/talisis'; 

const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

///TestNeotrigger



const AllysSuscribes = () => {
    const [loading, setLoading] = useState(false);
    let form=useRef();
    const [userNeo, setUserNeo] = useState({userid:null, password:null, neoLink: null});
 /*   const hanldeClickTestAllys= async (event) =>{
       const session = getSessionCookie();

       const allys=["NEO","Coursera","LinkedIn","Crehana"];
       const body = {
           person_id: session.person_id,
           ally: allys[1],
           email: session.email
       }
       const response = await AllysConnctionService.connectAlly(body)
    }*/

    
    const requestType = [
        {id: 1, label: 'NEO', icon:''},
        {id: 2, label: 'Coursera', icon:''},
        {id: 3, label: 'LinkedIn', icon:''},
        {id: 4, label: 'Crehana', icon:''},
        {id: 5, label: 'HubSpot', icon:''},
        {id: 6, label: 'Crack the Code', icon:''},
        {id: 7, label: 'SkillShop', icon:''},
        {id: 8, label: 'AWS Academy', icon:''},
    ]
    
    const onClickOption = async (status) => {
        const session = getSessionCookie();
        const {id, label} = status;

        const body = {
            person_id: session.person_id,
            ally: label,
            email: session.email,
            ou: session.ou
        }
        const response = await AllysServiceSDK.allysConnection(body);
        let redirectAlly = JSON.parse(response.body);
        if( redirectAlly.body.redirect !== '' && typeof redirectAlly.body.redirect !== 'undefined'){
            window.open(redirectAlly.body.redirect);
        }else if(label == 'NEO'){
            setUserNeo({userid:redirectAlly.body.neocredentials.userid, 
                password:redirectAlly.body.neocredentials.pwd, neoLink:redirectAlly.body.neocredentials.neoLink})
            setLoading(false);
           
                    
           let urlRedirect = '';
          switch(session.ou){
            
            case 'talisis':
               urlRedirect= userNeo.neoLink +'?userid=' +session.email+'&password='+userNeo.password;
                break;
            default: //uerre
             urlRedirect=userNeo.neoLink + session.email;
                break;
        }
          
          const win = window.open(urlRedirect );
        
           const timer = setInterval(() => {
               window.focus();
               win.close();
           }, 1000);

        }


        
    }
   

    
        
        
    
    return (

        <>
         <Box display="flex" alignItems="center" marginLeft="auto">
            <Dropdown 
                options={requestType} 
                onClickOption={onClickOption}
                text="Aliados"
                endIcon={<AddIcon />}
                color='primary'
                variant='contained'
            />
        </Box>

       

        </>
        )



}

export default AllysSuscribes;