import React from 'react';

// @material-uid
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const SignupAlert = ({ alertHidden, signupResponse, loginInvite, onLogin, onAlertClose }) => {
    return (
        <Box hidden={alertHidden}>
            <Alert onClose={onAlertClose} variant="filled" severity="info" color="info" hidden={true} icon={<i class="ri-error-warning-fill text-warning-400"></i>}>
                <AlertTitle>{signupResponse}</AlertTitle>
                {
                    loginInvite &&
                    <>
                        <Typography variant='caption'>
                            Te invitamos a
                            <Link className='mx-1 text-orange-300' onClick={onLogin} style={{ cursor: 'pointer' }}>
                                inicia sesión
                            </Link>
                            para continuar tu compra,
                        </Typography>
                        <Typography component="div" variant='caption'>
                            o cerrar este mensaje y registrarte con un correo diferente.
                        </Typography>
                    </>
                }
            </Alert>
        </Box>
    );
}

export default SignupAlert;