import React from 'react';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NotesIcon from '@material-ui/icons/Notes';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

// shared - core components
import Select from '../../../../../../design-system/components/Form/Select/Select'
import Checkboxes from '../../../../../../shared/components/Form/Checkboxes'
import capitalize from '../../../../../../design-system/utils/capitalize';
import IncrementButton from '../../../../../../shared/components/IncrementButton/IncrementButton';


// shared - utils
import removeObjectFromArray from '../../../../../../shared/utils/removeObjectFromArray';

// dependencies
import { v4 as uuid } from 'uuid';

const inputTypeslist = [
    {id: 'text', label: 'Respuesta Corta', icon: <NotesIcon/>},
    {id: 'textarea', label: 'Parrafo', icon:<FormatAlignJustifyIcon/>},
    {id: 'select', label: 'Varias Opciones', options: true, icon:<FormatListBulletedIcon/>},
    {id: 'datepicker', label: 'Fecha', icon:<CalendarTodayIcon/>},
    {id: 'file', label: 'Subir Archivo', icon:<CloudUploadIcon/>},
    {id: 'files', label: 'Listado de archivos', icon:<LibraryBooksIcon/>},
];

const fileExtensionList = [
    {id: 'all', label: 'Todas', accept: ''},
    {id: 'pdf', label: 'PDF', accept:'application/pdf'},
    {id: 'image', label: 'Imagen', accept: 'image/*'},
];

const inputUnitlist = [
    {id: 6, label: 'Ver en media Fila'},
    {id: 12, label: 'Fila Completa'},
    {id: 4, label: 'Un tercio de fila'},
];



function Options({field, onClick, onChangeInputFile, onChange}){

    const {type, accept} = field;

    const [accepts, setAccepts] = React.useState([
        {id: 'all', name: 'all', description: 'Todas',label: 'Todas', accept: '', checked: false},
        {id: 'pdf', name: 'pdf', description: 'PDF', label: 'PDF', accept:'application/pdf', checked: false},
        {id: 'image', name: 'image', description: 'Imagen', label: 'Imagen', accept: 'image/*', checked: false},
    ]);

    React.useEffect(() => {
        if(type === 'file' && field.accept !== null){
            const _accepts = [...accepts];
            const {id} = accepts.find(it => it.accept === accept);

            if(id === 'all'){
                _accepts.map(it => it.checked = true);
            }else{
                const i = accepts.findIndex(it => it.id === id);
                _accepts[i].checked = true;
                _accepts[0].checked = !_accepts.filter(it => it.id !== 'all').some(opt => !opt.checked);
            }
            setAccepts(_accepts);
        }
    }, [])

    const handleChange = (item) => {
        let accept;
        
        const _accepts = [...accepts];
        if(item.id === 'all'){
            _accepts.map(it => it.checked = item.checked);
        }else{
            const i = accepts.findIndex(it => it.id === item.id);
            _accepts[i].checked = item.checked;
            _accepts[0].checked = !_accepts.filter(it => it.id !== 'all').some(opt => !opt.checked);
        }

        const i = _accepts.findIndex(opt => opt.checked);

        accept = i < 0 ? null : _accepts[i].accept;

        onChangeInputFile(accept);
        setAccepts(_accepts);
    }

    if(type === 'file'){
        return <Checkboxes items={accepts} onChange={handleChange}/>
    }

    return ['select', 'checkboxes', 'files'].some(it => it === type) && (
        <Grid item xs={12} style={{padding: '0 8px'}}>
            {
                ['select', 'checkboxes'].some(it => it === type) && (
                    <Box display="flex" alignItems="center">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={field.checkboxes}
                                    onChange={onChange}
                                    color="primary"
                                    name="checkboxes"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label="Respuesta Multiple"
                        />
                    </Box>
                )
            }
            <Box display="flex" alignItems="center">
                <Button color="primary" startIcon={<AddIcon />} onClick={onClick}>
                    Agregar opción
                </Button>
            </Box>
        </Grid>
    )
}

function AddField(props) {
    const [open, setOpen] = React.useState(false);
    const [form, setForm] = React.useState({
        type: '',
        label: '',
        col: 1,
        required: false,
        checkboxes: false,
        options: [],
        break: false
    });
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        setOpen(props.open)
        setForm(props.form)
    }, [props]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['type', 'label'];

        for(const field of requiredFields){
            if(!form[field]){
                _errors[field] = 'Campo Requerido';
            }
        }

        setErrors(_errors);

        return Boolean(Object.keys(_errors).length === 0);
    }

    const handleSubmit = () => {
        if(validate()){
            if(form.hasOwnProperty('options')){
                form.items = form.options.filter(it => it.label !== '');
                
                if(form.type === 'files'){
                    form.options.map(it => {
                        if(!it.hasOwnProperty('extension')){
                            const allExtension = fileExtensionList.find(it => it.id === 'all');
                            it.extension = allExtension.id;
                            it.accept = allExtension.accept;
                        }
                    });
                }
            }

            if(!form.id){
                form.id = uuid();
            }

            if(form.checkboxes == true){
                form.type = 'checkboxes';
            }
            form.label = capitalize(form.label);

            setOpen(false);
            props.onSave(form)
        }
    }

    const handleChange = e => {
        const {name, value, checked} = e.target;
        const _errors = {...errors};
        // Se separan los valores para remover las opciones en caso que se cambio el tipo de campo.
        const { options, ...rest } = form;

        if(value){
            delete _errors[name];
        }
        // Validacion de que campo del formulario se esta modificando para determinar que cambiara del state.
        const oldValuesForm = name === 'type'? rest : form;

        setForm({
            ...oldValuesForm,
            [name]: name === 'required' ? checked : value,
        })


        if(name == 'checkboxes'){
            setForm({
                ...oldValuesForm,
                [name]: name === 'checkboxes' ? checked : value,
            })
        }

        setErrors(_errors);
    };

    const handleClickAddOption = () => {
        setForm(prevState => {
            if(!prevState.hasOwnProperty('options')){
                prevState.options = [{id:1, label: '', name:'option-1'}];
            }else{
                const consecutive = prevState.options.length + 1;
                prevState.options.push({id: consecutive, label: '', name: `option-${consecutive}`});
            }            
            return {...prevState};
        })
    }

    const hanldeChangeOption = e => {
        const {name, value} = e.target;

        setForm(prevState => {
            const {options} = prevState;

            options[options.findIndex(it => it.name === name)].label = value;
            
            return {...prevState}
        })
    }  
    
    const hanldeClickDeleteOpt = option => {
        const options = [...form.options];
        removeObjectFromArray(options, 'id', option.id);

        setForm({
            ...form,
            options
        })
    }

    const hanldeClickColumn = (counter) => {
        handleChange({target:{name:'col', value: counter }})
    }

    const handleOnChangeFileExtension = optionId => (event) => {
        let extensionValue = event.target.value;

        setForm(prevForm => {
            let options = prevForm.options;

            options.map(it => {
                if(it.id === optionId) {
                    it.extension = extensionValue;
                    it.accept = fileExtensionList.find(it => it.id === extensionValue).accept;
                }

                return it;
            });

            return {...prevForm, options};
        });
    };

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center" my={2}>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen} 
                variant="outlined">Agregar Campo</Button>
            </Box>

            <Dialog
                fullWidth 
                maxWidth="sm"
                onClose={handleClose} 
                aria-labelledby="simple-dialog-title" 
                open={open} 
                disableBackdropClick
                disableEscapeKeyDown >
                    <DialogTitle className="pb-0">Configuración de campo</DialogTitle>
                    <DialogContent>
                        <Box my={3}>
                            <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            margin="dense"
                                            variant="outlined"
                                            label="Pregunta"
                                            fullWidth
                                            name="label"
                                            onChange={handleChange}
                                            error={Boolean(errors.label)}
                                            helperText={errors.label}
                                            value={form.label}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Grid item xs={8}>
                                                <Select
                                                    required
                                                    items={inputTypeslist}
                                                    label="Elige Tipo de campo"
                                                    name="type"
                                                    value={form.type}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.type)}
                                                    helperText={errors.type}
                                                />
                                            </Grid>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={form.required}
                                                        onChange={handleChange}
                                                        color="primary"
                                                        name="required"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                }
                                                label="Obligatorio"
                                            />
                                        </Box>
                                    </Grid>
                                    <Options field={form} onClick={handleClickAddOption} onChange={handleChange} onChangeInputFile={(accept) => handleChange({target:{name:'accept', value: accept }})} />
                                    {
                                        form.options &&
                                        form.options.map(opt =>                                             
                                            <Grid item container xs={8} key={opt.id} style={{padding: '0 8px'}}>
                                                <Grid item xs={12} container>
                                                    <Grid item xs container direction="column">
                                                            <TextField
                                                                margin="dense"
                                                                variant="outlined"
                                                                placeholder="Escribe tu opción"
                                                                fullWidth
                                                                name={opt.name}
                                                                onChange={hanldeChangeOption}
                                                                defaultValue={opt.label}
                                                            />
                                                    </Grid>
                                                    {
                                                        form.type === 'files' && <Grid item style={{marginLeft: '5px', marginRight: '5px'}}><Select items={fileExtensionList} onChange={handleOnChangeFileExtension(opt.id)} label={'Tipo'} name={'fileExtensionSelector'} value={!!opt.extension ? fileExtensionList.find( it => it.id === opt.extension).id : fileExtensionList.find(it => it.id === 'all').id}/></Grid>
                                                    }
                                                    <Grid item>
                                                        <Box display="flex" alignItems="center" height="100%">
                                                            <IconButton aria-label="delete" style={{padding: 1}} color="primary" onClick={() => hanldeClickDeleteOpt(opt)}>
                                                                <DeleteOutlineIcon fontSize="small" />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="space-between">
                                            {/* <IncrementButton label="Ancho del campo" 
                                                counter={form.col} min={1}
                                                onClickIncrease={hanldeClickColumn} 
                                                onClickDecrease={hanldeClickColumn}
                                            /> */}
                                             {/* <Select
                                                required
                                                items={inputUnitlist}
                                                label="Ancho del campo"
                                                name="col"
                                                value={form.col}
                                                onChange={handleChange}
                                                error={Boolean(errors.col)}
                                                helperText={errors.col}
                                            />     */}
                                        </Box>
                                            <Box display="flex" pt={2}>
                                                
                                                {/* <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={form.break}
                                                            onChange={handleChange}
                                                            color="primary"
                                                            name="break"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    }
                                                    label="Salto de linea"
                                                /> */}
                                            </Box>
                                    </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{padding: '8px 24px 12px'}} >
                        <Button color="primary" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>
                            Guardar
                        </Button>
                    </DialogActions>
            </Dialog>
            
        </React.Fragment>
    )
}

export default AddField;
