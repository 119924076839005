import React from 'react';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// shared
import Table from '../../../../../../shared/components/Table/Table';

const dateTimeFormat = (row, value, index) => {
    const datetime = value ? moment.utc(value).locale('es').format('DD/MM/YYYY') : '';
    return <div>{datetime}</div>
};

const moneyFormat = (row, value, index) => {
    return <CurrencyFormat value={value} decimalScale={2} fixedDecimalScale={true} displayType="text" prefix="$" suffix=" MXN" />;
}

const stringFormat = (row, value, index) => {
    return <div>{value}</div>;
}

const columns = [
    { name: 'start_date', label: 'Fecha Inicio', format: dateTimeFormat },
    { name: 'end_date', label: 'Fecha Fin', format: dateTimeFormat },
    { name: 'price', label: 'Precio', align: "right", format: moneyFormat },
    { name: 'days', label: 'Días', format: stringFormat },
    { name: 'schedule', label: 'Horario', align: "right", format: stringFormat },
];

const CourseSchedules = ({ data }) => {
    const handleAdd = () => {

    };

    return (
        <Box width="100%">
            <Table
                columns={columns}
                rows={data || []}
            />
            {/* <Button variant="contained" color="primary" onClick={handleAdd} className="mt-3">Agregar fecha</Button> */}
        </Box>
    );
};

export default CourseSchedules;