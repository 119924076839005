export default {
    root: {
      'label + &': {
        marginTop: 12,
      },
      '&.Mui-disabled':{
        color: '#404150 !important'
      }
    },
    input: {
        padding: '11px 16px !important',
    },
};