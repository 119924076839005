import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { Box } from '@material-ui/core';

// Styles
import useStyles from '../../../../assets/styles/jss/pages/account/profile/UserRoleTagsStyle';
import UserRoleTag from './UserRoleTag/UserRoleTag';

const UserRoleTags = ({ tags }) => {
    const classes = useStyles();

    return (
        <Box className={classes.rootUserRoleTags}>
            {
                tags.map(tag => <UserRoleTag text={tag}/>)
            }
        </Box>
    );
};

UserRoleTags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UserRoleTags;