import React, { useEffect, useState } from 'react';
import { Box, Card,Typography,Tooltip } from '@material-ui/core';
import CardFirst from '../../../shared/components/Card/CardTwoParts/CardFirst';
import CardSecond from '../../../shared/components/Card/CardTwoParts/CardSecond';
import palette from '../../../design-system/theme/palette';
import BoxCalificaciones from './BoxCalificaciones';
import HistoryConsultancies from './HistoryConsultancies';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
//Style
import useStyles from '../../../assets/styles/jss/pages/consultancies/advisers/ProfileAdviserStyle';
import { useParams } from 'react-router-dom';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import BoxEducacion from './BoxEducacion';
import { ConsultanciesService } from '@sdk-point/talisis'; 
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import clsx from 'clsx';

const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const ProfileAdviser = (props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [profileAdviserData, setProfileAdviserData] = useState({});
    const [materias, setMaterias] = useState([]);
    const [dataDescripcion, setDataDescripcion] = useState([]);
    const [notfound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

  const classes = useStyles();
  const linksPerfil = [
    {
        nombre: 'Educación y formación',
        icon: 'ri-bank-line font-size-20 text-dark-black-200 mr-1'
    },
    {
        nombre: 'Calificaciones',
        icon: 'ri-profile-line font-size-20 text-dark-black-200 mr-1'
    },
    {
        nombre: 'Historial de asesorías',
        icon: 'ri-bookmark-3-line font-size-20 text-dark-black-200 mr-1'
    },
  ]

    useEffect(() => {
        if(id !== undefined){
            getDataProfile(id)
        }else{
            getDataProfile(props.personIdAsesor);
        }
    }, []);

    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    const getDataProfile = async (person_id) => {
        let dataProfileAdviser = await ConsultanciesServiceSDK.getProfileAdviser(person_id); 
        if(Object.keys(dataProfileAdviser).length > 0){
            setProfileAdviserData(dataProfileAdviser)
            setDataDescripcion(dataProfileAdviser.data_descripcion)
            setMaterias(dataProfileAdviser.materias)
        }else{
            setNotFound(true)
        }
        setLoading(false)
    }

    const renderSelectedTab = () => {
        if (selectedTab === 0 && dataDescripcion.length > 0) {
          return <BoxEducacion key={1} info={dataDescripcion} ClassNameEducacion={classes.boxEducacion} classes={classes}></BoxEducacion>
        } else if (selectedTab === 1 && (profileAdviserData.calificacionInd).length > 0) {
          return <BoxCalificaciones key={2} profileAdviserData={profileAdviserData} ClassNameCalificacion={classes.boxCalificacion} classIconArrow={classes.classIconArrow}></BoxCalificaciones>
        } else if (selectedTab === 2) {
          return <HistoryConsultancies key={3} ClassNameHistory={classes.ClassNameHistory} person_id={id === undefined ? props.personIdAsesor : id}></HistoryConsultancies>
        } else {
          return <NothingFound
          caption=""
          subcaption="No se encontró la información solicitada sobre el asesor" 
          type="asesores" />
        }
      }

  return (
     
    !notfound ?
    <>
    <Breadcrumb items={[
        { to: '/asesorias', label: 'Asesorías' },
        { label: 'Perfil de Asesor' },
    ]} />
    <LoaderContent loading={loading}>
    <Box className={classes.ProfileAdviser}>
      <Box className={classes.leftComponent}>
        <Card className={classes.cardProfile}>
            {
                profileAdviserData.email !== undefined &&
                
                <CardFirst className={classes.cardFirstStyle} profileAdviserData={profileAdviserData} ou={profileAdviserData.ou} location={profileAdviserData.address ? profileAdviserData.address.city + ', ' + profileAdviserData.address.state : 'Ubicacion pendiente'}  name={profileAdviserData.fullname} email={profileAdviserData.email}>
                </CardFirst>
            }
            <CardSecond className={classes.cardSecondStyle} classNameText={classes.classNameText} 
            classNameTabs={classes.classNameTabs} listItems = {linksPerfil} onTabSelect={handleTabSelect} >
                <Box className={clsx('h-50 mt-3', classes.areasConocimientoStyle)}>
                    <Box className={classes.tittleAreas}>
                      <Typography align="center" variant='overline'>AREAS DE CONOCIMIENTO</Typography>
                    </Box>
                    <Box className={classes.boxMaterias}>
                    {materias.map((materia,i) => 
                      <Box key={i} className={classes.boxMateria}>
                          <Tooltip
                          typographyprops={{variant: 'caption'}}
                          classes={{ tooltip: classes.tooltip, tooltipPlacementBottom: classes.tooltipText }}
                          title={materia}
                          placement="bottom-end">
                              <Typography variant='caption' className={classes.materiaStyle}>{materia}</Typography>
                          </Tooltip>
                      </Box>
                    )}
                    </Box>
                </Box>
            </CardSecond>
        </Card>
      </Box>
      <Box className={classes.rightComponent}>
        <Box className={classes.boxContainerRender}>
            {renderSelectedTab()}
        </Box>
      </Box>
    </Box>
      </LoaderContent>
      </>
      :
      <NothingFound />
  );
};

export default ProfileAdviser