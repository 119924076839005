import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import CurrencyFormat from 'react-currency-format'

const OrderSavings = ({ahorro}) => {
    return (
        <Grid container className='mt-2 px-2' justifyContent="space-between">
            <Grid item>
                <Typography variant="caption" className="text-gray-100">
                    Te ahorraste
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption" className="text-gray-100">
                    <CurrencyFormat value={ahorro}  decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix='MXN' />
                </Typography>
            </Grid>
        </Grid>
    )
}

export default OrderSavings
