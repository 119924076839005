import React from 'react';

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// components
import Level from './components/Level'
import Price from './components/Price'

import clsx from 'clsx';

import * as imageSource from '../../../utils/imageSource';
import {SOURCE_ID_COMPANY_NAME,SOURCES } from '../../../constants/sources';

import { AllysService,CourseService,MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../components/Mixpanel/mixpanel';
import { getSessionCookie } from "../../../utils/Sessions";
import objectIsEmpty from "../../../utils/objectIsEmpty";
import PriceAndMembership from '../../Card/CardMyClasses/components/PriceAndMembership';
import CourseRating from '../../CourseCard/components/CourseRating';

import useStyles from '../../../../assets/styles/jss/components/cards/courseCardStyle'
import LogoSource from './components/LogoSource';


// services
const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


const modalityOptions = ({
    2: "Online",
    3: "Híbrida",
    1: "Presencial",
});

const CourseCard = ({data, cardSizeResponsive}) => {
    const classes = useStyles({cardSizeResponsive});
    const [image, setImage] = React.useState("");
    const [hasDiscount, setHasDiscount] = React.useState(false);
    const [saveStatus, setSaveStatus] = React.useState(false);
    const [updatingStatus, setUpdatingStatus] = React.useState(false);
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

    React.useEffect(() => {
        if(data){
            const cardMediaImage = data.image || imageSource.convertSourceToImageResource(data.company_id, data.source?.id);
            setImage(cardMediaImage);
            setSaveStatus(data.saved);
            if(data.priceMembershipActions){
                setHasDiscount((data.content.showPrice && !!data.content.price.number && !!data.content.price.listNumber && data.content.price.listNumber != data.content.price.number))
            }
        }
    }, []);

    const CardFooter = () => {
        const group1 = [SOURCES.UNID, SOURCES.UERRE, SOURCES.HH, SOURCES.TALISIS];
        const group2 = [SOURCES.COURSERA, SOURCES.LINKEDIN_LEARNING, SOURCES.CREHANA];
        const group3 = [SOURCES.HUBSPOT, SOURCES.GOOGLE];

        if(data.detail.coursePurchased === 1){
            return (
                <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center" gridGap={68} width={1}>
                    <Typography variant="body2" color="secondary">Ver en mis cursos</Typography>
                </Box>
            )
        }

        if(group1.some(it => it === data.detail.sourceId) || (data.detail.sourceId === SOURCES.IE_UNIVERSITY && data.detail.companyId === "UERRE" )){
            setHasDiscount(true)
            return (<Price data={data.detail} prices={data.prices} />)
        }

        if(group2.some(it => it === data.detail.sourceId) && !data.detail.userLicensed){
            return (
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gridGap={68} width={1}>
                    <Typography variant="body2" className="text-dark-black-200">Próximamente</Typography>
                    <Icon className="ri-lock-2-line text-dark-black-200 font-size-14"/>
                </Box>
            )
        }
        
        if(group3.some(it => it === data.detail.sourceId)){            
            return (
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gridGap={68} width={1}>
                    <Typography variant="body2" className="semi-bold text-gray-200">Curso Gratis</Typography>
                </Box>
            )
        }

        return(<Box height={42} />)

    }

    const handleSaveUnsaveCourse = async (e) => {
        const {userId, course_id, prices, title} = data;
        const newStatus = !saveStatus;

        e.preventDefault();
        e.persist();

        setUpdatingStatus(true);
        setSaveStatus(newStatus);

        const response = await courseService.saveUnsaveCourse(userId, course_id, newStatus);
        
        if(response?.response?.length > 0){
            var labelObject = "";
            await AllysServiceSDK.getAlliesList().then( response => {

                var array = Object.keys(JSON.stringify(response)).map(function(key) {
                    return response[key];
                });
                
                if (!data.company_id) { 
                    labelObject = array.find(e => e.source_id === data.detail.sourceId);
                    labelObject = labelObject.AllyName
                }else{
                    labelObject = data.company_id;
                }
            })
            const role = JSON.parse(localStorage.getItem('permissions'));
            Mixpanel.track(MIXPANEL_EVENT.CURSO_GUARDAR, {
                userId: session.person_id,
                email: session.email,
                unidadOperativa: session.ou,
                gender: session.gender,
                courseId: course_id,
                courseName: title,
                source: labelObject,
                price: prices.current || null,
                userRole: role && role.length ? JSON.stringify(role[0].role_name).replace(/["']/g, "") : null,//estudiante,maestro,admin
                action: response.response[0].is_active ? 'Guardar' : 'Quitar'
            });
            setSaveStatus(response.response[0].is_active);
            setUpdatingStatus(false);
        }
        e.stopPropagation()
    };

    return (
        <Card variant="outlined" className={clsx(classes.card)}>
            <CardMedia 
                className={classes.cardMedia}
                image={image}
                title="title"
            >
                {data.raiting && 
                    <Box width="100%" className={classes.raiting}>
                        <CourseRating courseId={data.course_id} {...data.raiting}/>
                    </Box>
                }
                { !image && <Box className="card-skeleton" height={1} /> }
            </CardMedia>
            <CardContent component={Box} className={classes.cardContent}>
                <Box className={classes.cardContentTags}>
                    <Typography variant="overline" className="semi-bold text-blue-300 tag-title">
                        curso
                    </Typography>
                    {
                        isLogged &&
                        <IconButton disabled={updatingStatus} onClick={handleSaveUnsaveCourse} className="p-0">
                            <Icon className={clsx(saveStatus ? 'ri-bookmark-fill text-violet-300 bookmark-icon' : 'ri-bookmark-line text-dark-black-200 bookmark-icon')}/>
                        </IconButton>
                    }
                </Box>
                <Box className={classes.cardContentTitle}>
                    <Typography variant="body1" className="semi-bold title">{data.title}</Typography>
                </Box>
                <Box className={classes.cardContentLogo}>
                    <LogoSource
                        color="white" 
                        sourceId={data.priceMembershipActions ? data.source.id : SOURCE_ID_COMPANY_NAME[data.content.company]}/>
                </Box>
                <Box display="flex" alignItems="center" className={classes.cardContentLevelModality}>
                    {data.detail.level && <Level value={data.detail.level} />}
                    <Box display="flex" alignItems="center">
                        <Icon className="ri-slideshow-3-line text-blue-300 offset-margin-right-5" style={{fontSize: '13.5px'}}/>
                        <Typography variant="caption" component="span" className="text-blue-300">{modalityOptions[data.modalityId]}</Typography>
                    </Box>
                </Box>
            </CardContent>
            <CardActions className={clsx(classes.cardActions, {'discount': hasDiscount})}>
                {
                    data.priceMembershipActions 
                    ? <PriceAndMembership isResponsive={false} content={data.content} className={classes.priceMembership}/>
                    : <CardFooter data={data}/>
                }
            </CardActions>
        </Card>
    );
};

export default CourseCard;