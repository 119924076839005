import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Container, Grid, Typography } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';

import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        padding: '59px 0 37px',
        [theme.breakpoints.down('xs')]: {
            padding: '29px 0',
        }
    },
    box:{
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    box_content:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 0px',
        gap: 30,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: 172,
            gap: 0
        }
    },
    box_line:{
        flex: 1,
        display: 'flex',
        justifyContent:'center',
    },
    line:{
        height: '100%',
        borderLeft: '2px solid #404150',
    },
    title:{
        color: theme.palette.colors.blue[300],
        textAlign: 'center'
    }
}));

const items = [
    { number: '4,000', title: 'Vacantes a nivel nacional'},
    { number: '10,500', title: 'Estudiantes en experiencias prácticas'},
    { number: '3,000', title: 'Empresas registradas'},
    { number: '8,500', title: 'Asesores Empresariales'},
]

const StatisticBlock = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Container>
                <Grid container>
                    {items.map((it, index) =>
                        <React.Fragment key={uuid()}>
                            <Grid item xs={6} sm={6} md={3}>
                                <Box className={classes.box}>
                                    <Box className={classes.box_content}>
                                        <Typography variant="h1">{it.number}<Typography variant="inherit" className="text-orange-300">+</Typography></Typography>
                                        <Typography className={classes.title}>{it.title}</Typography>
                                    </Box>
                                    <Hidden smDown>
                                        <Box className={classes.box_line}>
                                            <Box className={classes.line} />
                                        </Box>
                                    </Hidden>
                                </Box>
                            </Grid>
                            <Hidden mdUp>
                                {
                                    index === 1 &&
                                    <Grid item sm={12} xs={12}>
                                    <Divider class="border-slim border-color-fill-stroke-200"/>
                                    </Grid>
                                }
                            </Hidden>
                        </React.Fragment>                       
                    )}                    
                </Grid>
            </Container>
        </Box>
    );
};

export default StatisticBlock;