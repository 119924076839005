import React from 'react';
import { useParams } from 'react-router-dom';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// shared
import Loader from '../../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../../shared/components/Breadcrumb/Breadcrumb';

// components
import CourseCard from '../CourseCard/CourseCard';

// services
// import * as marketService from '../../../../services/MarketPlaceService';
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';

const DEFAULT_PAGE_SIZE = 15;

const CourseCollection = (props) => {
    const { id } = useParams();
    const [courses, setCourses] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [pageNo, setPageNo] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(false);
    const collectionRef = React.useRef(null);
    const pageRef = React.useRef({ pageNo, lastPage });
    pageRef.current = ({ pageNo, lastPage });
    const { user } = props;
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const fetchCatalog = async (page_no) => {
        try {
            const thirdPartyParams = {
                "page_no": page_no,
                "page_size": DEFAULT_PAGE_SIZE,
                "person_id": user.person_id,
                "source_id": id,
            };
            const data = await marketService.getCoursesPagination("", thirdPartyParams);
            setCourses(courses => [...courses, ...data]);
            setLoading(false);
            return data;
        } catch (e) {
            setLoading(false);
        }
        return null;
    };

    const handleScroll = async (event) => {
        const node = event.target;
        const bottom = node.scrollHeight - Math.ceil(node.scrollTop) === node.clientHeight;

        if (bottom && !pageRef.current.lastPage) {
            const nextPage = pageRef.current.pageNo + 1;
            const result = await fetchCatalog(nextPage);

            if (result && result.length > 0) {
                setPageNo(nextPage);
            }

            if (result && result.length < DEFAULT_PAGE_SIZE) {
                setLastPage(true);
            }
        }
    };

    React.useEffect(() => {
        fetchCatalog(0, DEFAULT_PAGE_SIZE);

        if (collectionRef.current) {
            const parentElement = collectionRef.current.closest('div.main-panel');
            parentElement.addEventListener('scroll', handleScroll);
            return () => {
                parentElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <div ref={collectionRef}>
            <Breadcrumb items={[
                { to: '/cursos', label: 'Regresar a Mis cursos' },
                { label: 'Explorar cursos' },
            ]} />
            <Loader loading={loading} transparent={true}>
                <Box minHeight="calc(100vh - 450px)">
                    <Grid container spacing={2}>
                        {
                            courses.map(el => {
                                if ('third_party_id' in el) {
                                    return (
                                        <Grid key={`third-party-course-${el.id}`} item>
                                            <CourseCard
                                                course={{
                                                    ...el,
                                                    source: el.source_name,
                                                    card_image_url: el.image_url,
                                                }}
                                            />
                                        </Grid>
                                    )
                                } else {
                                    return (
                                        <Grid key={`course-${el.id}`} item>
                                            <CourseCard
                                                course={{
                                                    ...el, source: el.company_name
                                                }}
                                            />
                                        </Grid>
                                    )
                                }
                            })
                        }
                    </Grid>
                </Box>
            </Loader>
        </div>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CourseCollection);