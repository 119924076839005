import { makeStyles } from "@material-ui/core";

export const cashNewStyles = makeStyles((theme) => ({
    accordion: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: "432px"
        },
        "& .MuiAccordionSummary-root": {
            marginBottom: "0px"
        }
    },
    titleSteps: {
        fontSize: '32px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingBottom: '32px'
    },
    note:
    {
        color: '#A0A9BA',
        fontSize: '12px',
        paddingBottom: '24px'
    },
    line:
    {
        borderTop: '1px solid #404150',
        paddingBottom: '33px'
    },
    buttonContainer:
    {
        textAlign: 'right'
    },
    paymentMethodsAccordionTitleArrow:
    {
        color: 'white',
        fontSize: '16px'
    },
    paymentMethodsAccordionTitle:
    {
        minHeight: '40px !important',
        height: '40px',
        "@media (max-width: 1300px)": {
            height: '80px'
        },
        "@media (max-width: 960px)": {
            height: '40px'
        },
        "@media (max-width: 600px)": {
            height: '80px'
        }
    },
    paymentMethodsAccordionDetails:
    {
        padding: '0px',
        borderTop: "2px solid #111111"
    },
    warningContainer:
    {
        textAlign: 'left',
        color: '#FCD612',
        display: 'inline-flex',
        paddingBottom: '40px'
    },
    finishButton:
    {
        fontSize: '16px',
        color: 'white',
        fontWeight: '600',
        width: '390px',
        textTransform: 'none',
        marginBottom: '2em',
        "@media (max-width: 510px)": {
            width: '100%'
        }
    },
    price: {
        color: theme.palette.colors.orange[300],
    },
}));
