import React from 'react';

// @shared/components
import { convertSourceToImageResource } from '../../../../shared/utils/imageSource';
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';

// @material-ui
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Collapse,
    Grid,
    IconButton,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { CoursesTaughtHeaderCSSClasses } from '../CoursesTaughtCSSClasses/CoursesTaughtCSSClasses';
import NothingFound from '../../../../shared/components/NothingFound/NothingFound';

const LOGOS = {
    'UERRE': 'https://point-files.s3.us-west-2.amazonaws.com/files/U-ERRE.svg',
    'UNID': 'https://point-files.s3.us-west-2.amazonaws.com/files/UNID.svg',
};

const SOURCE_IDS = {
    'UERRE': 8,
    'UNID': 9,
};

const useStyles = CoursesTaughtHeaderCSSClasses();

const CoursesTaughtHeader = (props) => {
    const { loading, data, handleDetail } = props;
    const [loadingPeriods, setLoadingPeriods] = React.useState(false);
    const [expand, setExpand] = React.useState({});
    const [viewAll, setViewAll] = React.useState(false);
    const classes = useStyles();

    const handleLoadPeriods = async () => {
        setViewAll(!viewAll);
        setLoadingPeriods(true);
        await fetchData();
    };

    const fetchData = async () => {
        await props.fetchData(!viewAll);
        setLoadingPeriods(false);
    };

    React.useEffect(() => {
        const scrollPosition = localStorage.getItem('scrollPosition');
        if (scrollPosition) {
            window.scroll({
                top: scrollPosition,
                behavior: 'auto'
            });
        }
    }, []);

    return (
        <LoaderContent loading={loading} id="main">
            {data && data.length > 0 ?
                <React.Fragment>
                    {data.length > 0 && data.map(periodData => (
                        Object.keys(periodData).map((periodName, index) => (
                            <React.Fragment key={`fragment_period_${periodName}`}>
                                <Typography variant="h3" className={`mb-2 ${classes.title}`} onClick={() => {
                                    if (typeof expand[index] === 'undefined') {
                                        setExpand({ ...expand, [index]: false });
                                    }
                                    else {
                                        setExpand({ ...expand, [index]: !expand[index] })
                                    }
                                }}>
                                    <IconButton size="medium">{(typeof expand[index] === 'undefined' || expand[index] === true) ? <ExpandMore color="secondary" /> : <ChevronRight color="secondary" />}</IconButton>
                                    {periodName}
                                    {Object.keys(periodData[periodName]).map((subject, idx) => {
                                        if (idx === 0 && periodData[periodName][subject][0]['is_current_period']) {
                                            return (
                                                <Typography component="div" className={`ml-2 ${classes.play} bg-blue-500`}>
                                                    <i className="ri-play-circle-fill mr-1 bottom"></i>
                                                    <Typography component="span" variant="caption">En curso</Typography>
                                                </Typography>
                                            )
                                        }
                                    })}
                                </Typography>
                                <Collapse key={`collapse_period_${periodName}`} in={typeof expand[index] === 'undefined' ? true : expand[index]}>
                                    <Grid key={`grid_container_period_${periodName}`} container spacing={3} className="mb-3">
                                        {Object.keys(periodData[periodName]).map((subject) => {
                                            const image = convertSourceToImageResource(periodData[periodName][subject][0]['course_company_id'], SOURCE_IDS[periodData[periodName][subject][0]['course_company_id']]);
                                            return (
                                                <Grid key={`grid_item_${subject}`} item xs={12} sm={6} md={4} lg={3}>
                                                    <Card key={`card_${subject}`} className={`${classes.root}`} onClick={(e) => {
                                                        handleDetail(e, {
                                                            periodName: periodName,
                                                            company_id: periodData[periodName][subject][0]['course_company_id'],
                                                            image_url: image,
                                                            detail: periodData[periodName][subject],
                                                            term_type_description: periodData[periodName][subject][0]['term_type_description']
                                                        });
                                                    }}>
                                                        <CardActionArea>
                                                            <CardMedia className={classes.media} component="img" src={image} />
                                                            <img src={LOGOS[periodData[periodName][subject][0]['course_company_id']]} className={classes.logo} />
                                                            {periodData[periodName][subject][0]['course_modality_name'] &&
                                                                <Typography component="div" className={classes.modality}>
                                                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                                                        <path d="M5.5 8V9H7.5V10H2.5V9H4.5V8H0.5C0.367392 8 0.240215 7.94732 0.146447 7.85355C0.0526784 7.75979 0 7.63261 0 7.5V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V7.5C10 7.63261 9.94732 7.75979 9.85355 7.85355C9.75979 7.94732 9.63261 8 9.5 8H5.5ZM1 1.5V7H9V1.5H1ZM4 2.75L6.5 4.25L4 5.75V2.75Z" fill="#DDDFE3" />
                                                                    </svg>
                                                                    {periodData[periodName][subject][0]['course_modality_name']}
                                                                </Typography>}
                                                        </CardActionArea>
                                                        <CardContent>
                                                            <Typography variant="h5" className={classes.label}>{subject}</Typography>
                                                            <Typography component="div" variant="caption" className={`${classes.group} font-weight-500 text-blue-300`}>
                                                                <i className="ri-team-fill mr-1 icon"></i>
                                                                {`${periodData[periodName][subject].length} ${periodData[periodName][subject].length > 1 ? 'grupos' : 'grupo'}`}
                                                            </Typography>
                                                            <Typography component="div" variant="caption" className={`${classes.group} font-weight-500 text-blue-300`}>
                                                                <i className="ri-collage-fill mr-1 icon"></i>{periodData[periodName][subject][0]['term_type_description']}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Collapse>
                            </React.Fragment>
                        ))
                    ))}
                    <Button className={classes.button} onClick={() => !loadingPeriods && handleLoadPeriods()}>
                        {loadingPeriods ? <CircularProgress className={classes.circularLoadingPeriods} /> : (viewAll ? <React.Fragment><i className="ri-subtract-line mr-1"></i>Ocultar ciclos anteriores</React.Fragment> : <React.Fragment><i className="ri-add-line mr-1"></i>Cargar más ciclos anteriores</React.Fragment>)}
                    </Button>
                </React.Fragment>
                :
                <Box display="flex" flexWrap="wrap" alignContent="flex-start" className={classes.nothingFoundBox}>
                    <Typography className="font-size-24 font-weight-700" color="initial">Tus materias impartidas</Typography>
                    <NothingFound 
                        caption={'No tienes materias impartidas'}
                        subcaption={'Como docente aqui apareceran las materias asignadas para impartir'}
                        type={"courses"}
                        />
                </Box>}
        </LoaderContent>
    )
};

export default CoursesTaughtHeader;
