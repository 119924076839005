import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';

// Shared components 
import CardContinueLearning from '../../../design-system/components/Cards/CardContinueLearning/CardContinueLearning';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import {SOURCE_NAME} from '../../../shared/constants/sources';



// Styles
import useStyles from '../../../assets/styles/jss/pages/feed/learningStyle';

// Functions
import getConnectionAllies from '../../../shared/components/Allies/AlliesConnect';

// Draws
import WomanAndStars from '../../../design-system/assets/images/theme/woman_and_stars.svg';

// Utils
import { convertSourceToImageResource } from '../../../design-system/utils/placeholderImages';

const Learning = ({courses, user, loadingCourses}) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClickGoTo = async (e, course) => {
        e.preventDefault();
        const body = {
            course_url: course?.course_url,
            source_id: course?.source_id,
            course_url_neo: course?.course_url_neo,
            course_id: course?.course_id,
            company_id: course?.company_id,
            course_name: course?.name,
            person_id: user?.person_id,
            email: user?.email,
            ou: user?.ou,
        };
        const redirectSource = course?.company_id == 'UERRE' ? 8 : course?.company_id == 'UNID' ? 9 : course?.source_id 
        const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + redirectSource, "_blank");
        const urlRespuesta = await getConnectionAllies(body);
        setTimeout(() => {
            win.focus();
            win.location.replace(urlRespuesta);
        }, 3000);
        const eventData = {};
        eventData['source'] = 'feed';
        eventData['courseID'] = course?.course_id;
        eventData['marcaCurso'] = course?.company_id || SOURCE_NAME[course?.source_id] || course?.source_id;
        eventTracker('continuar_curso', eventData, ['mixpanel']);
    };

    const loadData = (course) => (e) => handleClickGoTo(e, course);
    
    const goToCourses=()=>{
        
        const eventData = {};
        eventData['source'] = 'feed';
        eventTracker('ver_mis_clases', eventData, ['mixpanel']);

        history.push(`/cursos`);
    }

    return (
        <Card className={classes.learnigRoot}>
            <CardHeader
                className={classes.headerRoot}
                classes={{"action": classes.headerActionButton}}
                title={'Continúa aprendiendo'}
                action={ !loadingCourses && !!courses.length ? <Button 
                        color="secondary"
                        label="Secundario"
                        onClick={() => goToCourses()}
                        size="medium"
                        variant="outlined">
                            Ir a mis clases
                        </Button> : <Box height={'42px'}></Box>
                }
            />
            <LoaderContent loading={loadingCourses}>
                <Grid container className={classes.contentRoot}>
                    {
                        !loadingCourses && !!courses && !!courses.length &&
                        courses.map((course, index) => <Grid item xs={6} key={course.course_id} className={(index + 1) % 2 === 0 ? 'pl-1' : 'pr-1'}>
                            <CardContinueLearning 
                                onClickButton={loadData(course)} 
                                labelButton='Ir a la clase'
                                name={course.name} 
                                imageUrl={course?.image_url ?? convertSourceToImageResource(course?.company_id, course?.source_id, false)}
                                />
                        </Grid>)
                    } 
                    {
                        !!loadingCourses && courses || !courses.length &&
                        <>
                            <Grid item md={12} className={classes.notCourseContainer}>
                                <Box className={classes.notCourseImageContainer}>
                                    <img src={WomanAndStars} className={classes.imageNotCourses}></img>
                                </Box>
                                <Box className={classes.notCourseData}>
                                    <Box>
                                        <Typography variant='h4'>¿Listo para aprender?</Typography>
                                        <Typography variant='body2' className={classes.notCourseTextDescription}>Adquiere conocimientos y desarrolla las habilidades que te permitan responder a los retos de un mundo cambiante y globalizado.</Typography>
                                    </Box>
                                    <Button
                                        className={classes.notCourseButton}
                                        color="primary"
                                        label="Explorar cátalogo de cursos"
                                        onClick={() => history.push('/')}
                                        size="medium"
                                        variant="outlined"
                                    >Explorar cátalogo de cursos</Button>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </LoaderContent>
        </Card>
    );
};

Learning.propTypes =  {
    courses: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object.isRequired,
    loadingCourses: PropTypes.bool,
};

export default Learning;