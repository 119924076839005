import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import { Tooltip, makeStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import Chip from '@material-ui/core/Chip';

// @material-ui/icons
import CheckIcon from '@material-ui/icons/Check';
import MuiCircleIcon from '@material-ui/icons/Brightness1';

// shared
import { DESC_PRODUCT_DEFAULT } from '../../../../shared/constants/products';
import Tabs from '../../../../shared/components/Tabs/Tabs'
import { log } from '../../../../shared/utils/console.js'
import ReadMoreLine from '../../../../shared/components/ReadMore/ReadMoreLine';
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

// design-system
import typography from '../../../../design-system/theme/typography';
import ContentStrip from '../../../../design-system/components/ContentStrip/ContentStrip';
import { COMPANIES, SOURCES } from '../../../../design-system/constants/sources.js';

import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

// components
import EnrollSidebar from './EnrollSidebar/EnrollSidebar';
import Topics from './Topics/Topics';
import CourseRating from '../../../../shared/components/CourseCard/components/CourseRating';
import CourseSelling from './EnrollSidebar/CourseSelling/CourseSelling';
import CourseSubscription from './EnrollSidebar/CourseSubscription/CourseSubscription';

// routes
import * as paths from '../../../../routes/paths';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        padding: '24px 32px'
    },
    toolsContent:{
        display: 'grid'
    },
    container:{
        [theme.breakpoints.up('md')]:{
            marginTop: '-64px'
        }
    },
    brand: {
        fontSize: '24px',
        fontWeight: '700',
        paddingBottom: '16px',
    },
    root: {
        marginTop: '-16px',
        flexWrap: "nowrap",
        justifyContent: "center",
        flexDirection:  'row',
        gap: '20px',
        "@media (max-width: 750px)": {
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            flexDirection:  'column'
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '20px'
        }
    },
    stickyCard: {
        top: '80px', /* offset appbar (64px) + offset card (16px)*/
        marginBottom: '14px'
    },
    paper: {
        overflow: "hidden",
    },
    list: {
        padding: 0,
    },
    listItem: {
        padding: '4px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& .MuiListItemIcon-alignItemsFlexStart': {
            height: "14px",
            alignItems: "center",
            margin: 0
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiTypography-body1':{
                fontSize: 14
            }
        }
    },
    listItemIcon: {
        minWidth: "30px",
        [theme.breakpoints.down('xs')]: {
            marginTop: 4,
            minWidth: 15,
        }
    },
    main2: {
        width: "70%",
        maxWidth: "1125px",
        // flexBasis: "70%",
        "@media (max-width: 1250px)": {
            width: "65%",
            maxWidth: "65%",
            flexBasis: "65%"
        },
        "@media (max-width: 950px)": {
            width: "100%",
            maxWidth: "100%",
            flexBasis: "100%"
        },
        "@media (max-width: 750px)": {
            width: "100%",
            maxWidth: "100%",
            flexBasis: "100%"
        }
    },
    main:{},
    contentDetail: {
        "@media (max-width: 750px)": {
            width: "100%",
            maxWidth: "inherit",
            minWidth: "inherit",
        },
        [theme.breakpoints.up('md')]:{
            marginTop: '-202px'
        }
    },
    button: {
        background: "linear-gradient(298.07deg, #00C1DE 10.33%, #3D23D8 84.35%)",
        fontSize: 11,
        color: theme.palette.colors.white,
        padding: "4px 8px",
        lineHeight: '16px',
        marginLeft: 0,
        borderRadius: 2
    },
    checkIcon: {
        color: theme.palette.colors.orange[400],
        marginRight: '14.26px'
    },
    tv:{ 
        height: "16px",
        marginRight: "4px",
    },
    downloadStudyButton:
    {
        ...typography.v3,
        marginTop: '32px',
        [theme.breakpoints.down('md')]: {
            width:'100%',
            height: 48,
            marginTop: 0,
        }
    },
    circledIcon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        background: theme.palette.colors.grey[400],
    },
    course_subtitle:{
        letterSpacing:'2px',
        color:theme.palette.info.main,
        ...typography.web.captionSmallBold
    },
    infoTipo:{
        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    },
    herramientasStyle:{
        display: 'flex', 
        flexDirection: 'row',
        gap: '10px',
        [theme.breakpoints.down('xs')]:{
            flexDirection: 'column',
        }
    },
    chipsHabilidades: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        height: 'auto',
        padding: '8px',
        background: 'transparent',
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: '24px',
        margin: '4px',
        maxWidth: 296
    },
    overflowText:{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.colors.gray[400]
    },
    tooltip: {
        backgroundColor: theme.palette.colors.blue[100],
        borderRadius: '8px',
        opacity: 0.9
    },
    tooltipText: {
        color: theme.palette.colors.fillStroke[500]
    },
    textColor:{
        color: theme.palette.colors.gray[400]
    },
    textColor2:{
        color: theme.palette.colors.gray[500]
    },
    markerButton:{
        padding: 0,
        height: 40,
        width: 40
    },
    boxPrice:{
        [theme.breakpoints.down('xs')]:{
            display: 'flex',
            flexDirection: 'column',
            gap: 4
        }
    },
    priceFree:{
        [theme.breakpoints.down('xs')]:{
            marginBottom: props => !props.isEnrolled && 8
        }
    },
    marginButtons:{
        [theme.breakpoints.down('xs')]:{
            marginTop: 20,
        }
    },
    marginCards: {
        marginBottom: 16,
        [theme.breakpoints.down('xs')]:{
            marginBottom: 12,
        }
    },
    tittlesPaddings:{
        paddingBottom: 24,
        [theme.breakpoints.down('xs')]:{
            paddingBottom: 16,
        }
    },
    buttonCheckout: {
        color:theme.palette.colors.darkBlack[200]
    },
    customMembershipMessage:{
        ...theme.typography.bodyTextSmallRegular,
        color: `${theme.palette.colors.gray[500]} !important`
    },
    customMembershipMessageBorder:{
        borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        paddingBottom: '24px',
        [theme.breakpoints.down('sm')]:{
            borderBottom: 'none',
            paddingBottom:'0px'
        }
    },
    customMembershipMessageContainer:{
        marginBottom:'24px',
        [theme.breakpoints.down('sm')]:{
            marginTop:'24px',
            marginBottom:'0px'
        }
    },
    [theme.breakpoints.down('xs')]:{
        cardContent: {
            padding: 16
        },
        toolsContent:{
            display: 'block',
            '& .desc':{
                fontSize: 14
            }
        },
       chipsHabilidades: {
            margin: '4px 0px'
        },
        textResponsive:{
            fontSize: 14
        }
    }
}));

const CircleIcon = withStyles((theme) => ({
    root: {
        display: "flex",
        color: "#FFFFFF !important",
        fontSize: "0.4rem",
        marginLeft: "auto",
        marginRight: "10px"
    },
}))(MuiCircleIcon);

const NewCourseSection = ({ data, icon }) => {
    const classes = useStyles();

    if (!(data && data.length > 0)) {
        return null;
    }

    return (
            <List className={classes.list}>
                {
                    data.map((el, index) => (
                        <ListItem key={index} className={classes.listItem}>
                            <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
                            <Typography variant='body1' className='text-gray-400'>{el.description}</Typography>
                        </ListItem>
                    ))
                }
            </List>
    );
};

const CourseContent = (props) => {
    const { course, membershipData } = props;
    const isEnrolled = course.is_enrolled
    const [activeTab, setActiveTab] = React.useState(0);
    const classes = useStyles({isEnrolled});
    const history = useHistory();

    const getLanguage=(lang)=>
    {
        switch(lang)
        {
            case "en":
                return "Inglés";
            case "es":
                return "Español";
            case "fr":
                return "Francés";
            default:
                return lang;
        }
    };

    const tabsData = [
        {
            tab: 'Información',
            content: <Box>
                <Box>
                    {
                        course?.description !== DESC_PRODUCT_DEFAULT && <Typography>Introducción</Typography>
                    }
                    <Box>
                        <Typography variant='body'>
                            {
                                course?.description
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        },
        {
            tab: 'Contenido',
            content: <Box></Box>
        },
    ];

    const handleChangeTab = (id) => {
        setActiveTab(id);
    };

    useEffect(() => {
        log("course info:", JSON.stringify(course));
        log("membership info:", JSON.stringify(membershipData))
    }, [course, membershipData]);

    const gotoMemberships = (e) => {
        e.preventDefault();
        //
        const eventData = {};
        eventData['source'] = 'curso';
        eventTracker('ver_membresías', eventData, ['mixpanel']);
        //
        history.push({
            pathname: paths.MEMBRESIAS
        });
    };

    return (
        <Container className={classes.container} id="course-conten-root">
            <Grid container style={{ position: "relative", zIndex: 2 }} spacing={5}>
                <Grid item xs={12} md={8} className={classes.main}>
                    <Hidden mdUp>
                        <Box>
                            {  <CourseRating courseId={course.course_id} {...course.raiting} />}
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <Box className="mt-5">
                            {
                                course.source_is_internal || (course.source_id === SOURCES.IE_UNIVERSITY && course.company_id === COMPANIES.UERRE) ?
                                    <CourseSelling course={course} classes={classes} membershipData={membershipData} backgroundImage={course?.image_url} hideInfoData={true} /> :
                                    <CourseSubscription course={course} classes={classes} membershipData={membershipData} backgroundImage={course?.image_url} hideInfoData={true} />
                            }
                        </Box>
                    </Hidden>
                    <Hidden mdUp data-screen="mobile">
                        <Box className={clsx('mt-5 mb-3', classes.infoTipo)}>
                            <ContentStrip
                                id="cc-content-strip" 
                                items={{
                                    modality: course?.modality_name,
                                    level: course?.difficulty_level_name,
                                    duration: `${course?.duration ? course?.duration : null}`,
                                    certificate: 'Certificado',
                                    lang: getLanguage(course?.lang)
                                }}
                            />
                        </Box>
                        <Box>
                            <Tabs
                                onChange={handleChangeTab}
                                activeTab={activeTab}
                                tabs={tabsData.map(it => it.tab)}
                            >
                                <Box>
                                    <div>
                                        {
                                            activeTab===0 ?
                                            <Box className='pb-5' pt={.5}>
                                                <Box hidden={course?.description===null}>
                                                    {course?.description !== DESC_PRODUCT_DEFAULT && <Typography variant='h4'>Introducción</Typography>}
                                                    <Box className='pt-3'>
                                                        <ReadMoreLine 
                                                            line={9}
                                                            text={
                                                                <Typography className={clsx('font-size-14', classes.textColor2)}>
                                                                {course?.description}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>:
                                            <Box className='pt-3'>
                                                <Box>
                                                    <Topics data={course.modules} />
                                                </Box>
                                            </Box>
                                        }
                                </div>
                            </Box>
                        </Tabs>
                    </Box>
                    </Hidden>
                    <Hidden smDown data-screen="web">
                        <Box>
                            <ContentStrip 
                                id="cc-contet-strip"
                                items={{
                                    modality: course?.modality_name,
                                    level: course?.difficulty_level_name,
                                    duration: `${course?.duration ? course?.duration : null}`,
                                    certificate: 'Certificado',
                                    lang: getLanguage(course?.lang),
                                }}
                            />
                        </Box>
                    </Hidden>
                    <Hidden smDown>
                        <Box py={3} px={4} mb={2}>
                            {course?.description !== DESC_PRODUCT_DEFAULT && <Typography component="span" variant="h4">{ course?.description ? "Introducción" : null }</Typography>}
                            <Box>
                                <Typography variant="body1">
                                    {course?.description}
                                </Typography>
                            </Box>
                        </Box>
                        <Topics data={course.modules} />
                    </Hidden>
                    {
                        course?.tools && course?.tools.length > 0 &&
                            <Card style={{ marginBottom: 12 }}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant='h4' className={classes.tittlesPaddings}>
                                        Herramientas necesarias
                                    </Typography>
                                    <Box gridTemplateColumns='repeat(auto-fill, minmax(50%, 1fr))' className={classes.toolsContent}>
                                        {
                                            course.tools.map((value,index)=>
                                            {
                                                return(
                                                <Box style={{display: 'flex', alignItems: 'center'}}>
                                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                                    <Typography variant='body1' className='text-gray-400 desc' style={{display:'inline-block'}}>
                                                        {value.description}
                                                    </Typography>
                                                </Box>
                                                );
                                            })
                                        }
                                    </Box>
                                </CardContent>
                            </Card>
                    }
                    {
                        course?.skills && course?.skills.length > 0 &&
                            <Card style={{ marginBottom: 12 }}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant='h4' className={classes.tittlesPaddings}>
                                        Habilidades
                                    </Typography>
                                    <Box style={{display: 'flex', flexWrap: 'wrap'}}>
                                        <ReadMoreLine 
                                            line={4} 
                                            text={course.skills.map((value,index)=> {
                                                return(
                                                    <Chip
                                                        key={uuid()}
                                                        label={
                                                                value.description.length > 40 ?
                                                                <Tooltip
                                                                typographyprops={{variant: 'caption'}}
                                                                classes={{ tooltip: classes.tooltip, tooltipPlacementBottom: classes.tooltipText }}
                                                                title={value.description}
                                                                placement="bottom-end">
                                                                    <Typography variant='body2' className='text-gray-400'>{value.description}</Typography>
                                                                </Tooltip>
                                                                :
                                                                <Typography variant='body2' className='text-gray-400'>{value.description}</Typography>
                                                            }
                                                        className={classes.chipsHabilidades} 
                                                        style={{minWidth: '100px'}} 
                                                    />
                                                );
                                            })}
                                            />
                                    </Box>
                                </CardContent>
                            </Card>
                    }
                    {
                        course?.requirements && course?.requirements.length > 0 &&
                            <Card style={{ marginBottom: 12 }}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant='h4' className={classes.tittlesPaddings}>
                                        Requisitos para acreditar el curso
                                    </Typography>
                                    <NewCourseSection data={course.requirements} icon={<CircleIcon fontSize="small" />} />
                                </CardContent>
                            </Card>
                    }
                    {
                        course?.benefits && course?.benefits.length > 0 &&
                            <Card style={{ marginBottom: 12 }}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant='h4' className={classes.tittlesPaddings}>
                                        Areas de aplicación
                                    </Typography>
                                    <NewCourseSection data={course.benefits} icon={<CircleIcon fontSize="small" />} />                                            </CardContent>
                            </Card>
                    }
                    <Hidden mdUp>
                            {
                                course.source_is_internal || (course.source_id === SOURCES.IE_UNIVERSITY && course.company_id === COMPANIES.UERRE ) ?
                                <React.Fragment>
                                    <Card hidden={course?.is_enrolled==1}>
                                        <CardContent>
                                            <Box hidden={membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_title===""} className='mb-2'>
                                                <Typography variant="h4" className="text-gray-100">{ membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_title }</Typography>
                                            </Box>
                                            <Box style={membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_title===""?{marginTop:'24px'}:{}} hidden={membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_description===""} className='mb-3'>
                                                <Typography variant="body2" className='text-gray-400'>{ membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_description_alt!=undefined && course?.company_id===COMPANIES.UERRE ? membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_description_alt : membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_description }</Typography>
                                            </Box>
                                            {
                                                membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_memberships_button!=="" &&
                                                <Box display="flex" className='mb-3'>
                                                    <Box display="flex" style={{ width: "100%" }}>
                                                        <Button variant="outlined" color='secondary' fullWidth onClick={(e) => gotoMemberships(e)} style={{ textTransform: 'none'}}>
                                                            { membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_memberships_button }
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            }   
                                        </CardContent>
                                    </Card>
                                </React.Fragment> :
                                <React.Fragment>
                                    <Card hidden={course?.is_enrolled==1}>
                                        <CardContent>
                                            <Box className='mb-2' hidden={((course.source_id !== SOURCES.LINKEDIN_LEARNING && course.source_id !== SOURCES.CREHANA && course.source_id !== SOURCES.COURSERA) && membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_title === "") || ((course.source_id === SOURCES.LINKEDIN_LEARNING || course.source_id === SOURCES.CREHANA || course.source_id === SOURCES.COURSERA) && membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_title === "")}>
                                                <Typography className="font-variant-v7">{(course.source_id !== SOURCES.LINKEDIN_LEARNING && course.source_id !== SOURCES.CREHANA && course.source_id !== SOURCES.COURSERA) ? membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_title : membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_title}</Typography>
                                            </Box>
                                            <Box hidden={((course.source_id !== SOURCES.LINKEDIN_LEARNING && course.source_id !== SOURCES.CREHANA && course.source_id !== SOURCES.COURSERA) && membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_description === "") || ((course.source_id === SOURCES.LINKEDIN_LEARNING || course.source_id === SOURCES.CREHANA || course.source_id === SOURCES.COURSERA) && membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_description === "") || (course?.source_id === SOURCES.COURSERA || course?.source_id === SOURCES.LINKEDIN_LEARNING || course?.source_id === SOURCES.CREHANA)} style={((course.source_id !== SOURCES.LINKEDIN_LEARNING && course.source_id !== SOURCES.CREHANA && course.source_id !== SOURCES.COURSERA) && membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_title === "") || ((course.source_id === SOURCES.LINKEDIN_LEARNING || course.source_id === SOURCES.CREHANA || course.source_id === SOURCES.COURSERA) && membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_title === "") ? { paddingTop: '24px' } : {}} className='mb-3'>
                                                <Typography className='font-variant-v2'>{(course.source_id !== SOURCES.LINKEDIN_LEARNING && course.source_id !== SOURCES.CREHANA && course.source_id !== SOURCES.COURSERA) ? membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_description : membershipData?.user_membership?.actual_membership?.sidebar_paid?.description}</Typography>
                                            </Box>
                                            {
                                                (course.source_id !== SOURCES.LINKEDIN_LEARNING && course.source_id !== SOURCES.CREHANA && course.source_id !== SOURCES.COURSERA) && membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_memberships_button !== "" &&
                                                <Box display="flex" style={{marginTop: '24px'}} className="mb-3">
                                                    <Box display="flex" style={{ width: "100%" }}>
                                                        <Button variant="outlined" color='secondary' fullWidth onClick={(e) => gotoMemberships(e)} style={{ textTransform: 'none'}}>
                                                        {
                                                            (course.source_id !== SOURCES.LINKEDIN_LEARNING && course.source_id !== SOURCES.CREHANA && course.source_id !== SOURCES.COURSERA) ? membershipData?.user_membership?.actual_membership?.sidebar_free?.responsive_memberships_button : membershipData?.user_membership?.actual_membership?.sidebar_paid?.responsive_membership_button
                                                        }
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            }
                                        </CardContent>
                                    </Card>
                                </React.Fragment>
                            }
                    </Hidden>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={4} className={classes.contentDetail}>
                        <EnrollSidebar {...props} class={classes.stickyCard} />
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    );
};

export default CourseContent;
