import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    cartContainer: {
        marginTop: 20,
        marginBottom: 120,
    },
    cartGrid: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column-reverse"
        }
    },
    cartContent: {
        paddingLeft: props => props.showAddons ? '24px !important' : '112px !important',
        paddingRight: props => props.showAddons ? '24px !important' : '112px !important',
        paddingBottom: props => props.showAddons ? '16px !important' : '32px !important',
        paddingTop: props => props.showAddons ? '4px !important' : '40px !important',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
            paddingBottom: '16px !important',
            paddingTop: '16px !important',
        }
    },
    cartNavigationBar: {
        marginBottom: "34px",
    },
    cartReview: {
        // marginTop: 40,
        marginBottom: 22
    },
    cartCount: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        backgroundColor: '#1C1F24',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '16px',
        marginLeft: '24px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '8px'
        }
    },
    cartLinkStyle: {
        textDecoration: 'none !important', // Elimina la subrayado del enlace
        display: 'inline-flex',
        alignItems: 'center',
        color: 'inherit' // Hereda el color de texto del padre
    },
    cartListItem: {
        paddingTop: '16px',
        paddingBottom: '16px'
    },
    cartPriceDetails: {
        borderRadius: '0px 0px 8px 8px',
        borderTop: "4px solid",
        borderImageSource: "linear-gradient(273.67deg, rgb(252, 220, 67) 15.67%, rgb(255, 167, 0) 57.57%)",
        borderImageSlice: 1,
        background: theme.palette.colors.fillStroke[300],
        marginTop: "40px",
        [theme.breakpoints.down('md')]: {
            marginTop: '16px'
        }
    },
    cartPriceOff: {
        textDecoration: 'line-through'
    },
    cartPayment: {
        justifyContent: "right",
        marginTop: '32px',
    },
    cartProductImage: {
        objectFit: "cover",
        borderRadius: '8px',
        backgroundColor: theme.palette.colors.fillStroke[300],
    },
    cartDescription: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    cartListItemContent: {
        flexGrow: 1,
        flexBasis: 0,
        maxWidth: 'calc(100% - 122px)' //72 img + 52 button
    },
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    modalCard: {
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            height: '100vh',
        },
    },
    modalCardContent: {
        [theme.breakpoints.up('sm')]: {
            width: '510px',
        },
        [theme.breakpoints.down('xs')]: {
            height: "calc(100% - 118px)", // 100% - height tabs
            alignContent: "center",
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
        },
        textAlign: 'center',
        position: 'relative',
        paddingTop: '0px !important',
        paddingBottom: '29px !important',
        paddingLeft: '42px !important',
        paddingRight: '42px !important',
    },
    modalIcon: {
        fontSize: '48px',
        cursor: 'pointer'
    },
    modalClose: {
        paddingRight: "19px",
        paddingTop: "15px",
    },
    modalButtons: {
        margin: 0,
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column-reverse"
        },
    },
    cartHarmonHall: {
        minHeight: "702px",
        width: "332px",
        [theme.breakpoints.down('xs')]: {
            width: "100%", // 100% - height tabs
            minHeight: "auto",
        },
    },
    /* harmon hall add-ons */
    harmonHallContainer: {
        maxWidth: "100%"
    },
    harmonHallCard: {
        width: "300px !important",
        height: "158px !important",
        [theme.breakpoints.down('xs')]: {
            width: "290px !important",
        },
        position: "relative",
        cursor: "pointer !important",
        background: props => `linear-gradient(238deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 69.38%), url(${props.image_url}) 156.909px -1.837px / 51.333% 100% no-repeat, #3F4550 !important`,
        boxSizing: "border-box !important",
    },
    harmonHallCardHover: {
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.03)', // Efecto de escala
            boxShadow: '0 10px 20px rgba(0,0,0,0.2)', // Sombra de la tarjeta
        },
    },
    harmonHallCardFooter: {
        display: "flex",
        width: "100%",
        height: "40px",
        padding: "12px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        background: "#8B3FFA",
        position: "absolute",
        bottom: 0
    },
    harmonHallMembership: {
        display: "inline-flex",
        padding: "4px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        borderRadius: "0px 8px",
        borderLeft: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        background: "rgba(0, 0, 0, 0.60)",
        position: "absolute",
        top: 0,
        right: 0
    },
    harmonHallCardSelected: {
        border: '1px solid #ffff00 !important', // Color del borde para la tarjeta seleccionada
    },
    harmonHallCardDisabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            zIndex: 1,
        },
    },
    harmonHallCarousel: {
        maxWidth: "100%"
    },
    priceStrikeThrough: {
        color: "#FF7E00 !important",
        textDecoration: "line-through !important",
    },
    /* Harmon Hall Modal Upselling */
    hhUpsellingModalClose: {
        paddingRight: "20px",
        paddingTop: "16px",
        paddingBottom: "16px",
    },
    hhUpsellingModalCardContent: {
        [theme.breakpoints.up('sm')]: {
            width: '641px',
        },
        [theme.breakpoints.down('xs')]: {
            height: "calc(100% - 118px)", // 100% - height tabs
            alignContent: "center",
            paddingLeft: '24px !important',
            paddingRight: '24px !important',
        },
        position: 'relative',
        paddingTop: '0px !important',
        paddingBottom: '29px !important',
        paddingLeft: '42px !important',
        paddingRight: '42px !important',
    },
    hhUpsellingCard: {
        width: "384px",
        height: "158px",
        position: "relative",
        cursor: "pointer !important",
        background: props => `linear-gradient(238deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 69.38%), url(${props.image_url}) 227.35px 0px / 40.104% 100% no-repeat, #3F4550 !important`,
        [theme.breakpoints.down('xs')]: {
            width: "290px",
            height: "138px",
            background: props => `linear-gradient(238deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 69.38%), url(${props.image_url}) 135.822px -7.86px / 62.857% 136.645% no-repeat, #3F4550 !important`,
        },
    },
    hhUpsellingCardDisabled: {
        filter: 'grayscale(100%)',
        cursor: "pointer !important",
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            zIndex: 1,
        },
    },
    hhUpsellingModalButtonContinue: {
        marginTop: "32px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "16px",
        }
    }
}));