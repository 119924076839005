import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    productAssignmentModal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        // paddingTop: '58px',
        paddingLeft: '104px',
        paddingRight: '107.14px',
        border: '0.5px solid #404150',
        borderRadius: '8px',
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    productAssignmentModalInside: {
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        },
        maxWidth: "536px",
        backgroundColor: '#1C1F24',
        textAlign: 'center',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '32px',
        paddingTop: '16px',
        borderRadius: '8px'
    },
    programCard: {
        width: '288px',
        height: '315px',
        borderRadius: '8px',
        gap: '24px',
        background: 'transparent',
    },
    programCardContent: {
        width: '288px',
        height: '315px',
        gap: '0px',
        opacity: '1.0',
        borderRadius: '8px',
        padding: '30px 20px 30px 20px',
        border: `.5px solid ${theme.palette.colors.fillStroke[100]}`,
        backgroundImage: (propsStyles) => `url(${propsStyles.image_url})`,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        imageRendering: "-webkit-optimize-contrast",
        textAlign: "left"
    },
    logoSource: {
        width: '71.74px',
        height: '22px',
        top: '40px',
        left: '16px',
        gap: '0px',
        opacity: '0px',
    },
    courseCard: {
        width: '288px',
        height: '315px',
        borderRadius: '8px',
        gap: '24px',
        background: 'transparent',
    },
    courseCardMedia: {
        width: '288px',
        height: '168px',
        borderRadius: '8px 8px 0px 0px',
        padding: '16px',
        background: props => `linear-gradient(180deg, rgba(0, 0, 0, 0) 61.46%, rgba(0, 0, 0, 0.71) 100%), url(${props.image_url})`,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        imageRendering: "-webkit-optimize-contrast",
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    courseCardContent: {
        width: '288px',
        height: '147px',
        padding: '16px',
        textAlign: "left"
    }
}));