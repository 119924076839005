import React from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui
import { Box, Button, Grid, Typography } from '@material-ui/core';

const CheckoutMaintenancePage = ({ display = true }) => {
    const history = useHistory();

    if (!display) {
        return null;
    }

    const handleHomeClick = (e) => {
        e.preventDefault();
        history.push("/");
    };

    return <Box display='flex' justifyContent='center' className='my-4'>
        <Grid container justifyContent='center' spacing={4}>
            <Grid item>
                <img src='https://point-files.s3.us-west-2.amazonaws.com/market-place/checkout/maintenance/checkout_maintenance.svg' alt="maitenance" width={309} height={347}></img>
            </Grid>
            <Grid item alignContent='center'>
                <Box>
                    <Typography variant='h1' color='primary' className='mb-3'>Proceso de compra en<br />mantenimiento</Typography>
                    <Typography variant='body1' color='' className='mb-4'>Actualmente estamos realizando mejoras en nuestro proceso de <br /> compra para ofrecerte una mejor experiencia. Por favor, vuelve a <br /> intentarlo más tarde. Agradecemos tu comprensión y paciencia.</Typography>
                    <Button style={{ width: 149 }} variant='outlined' color='primary' onClick={handleHomeClick}>Ir a inicio</Button>
                </Box>
            </Grid>
        </Grid>
    </Box>
};

export default CheckoutMaintenancePage;