import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';

// shared components
import Table from "../../../../shared/components/Table/Table";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    table_title:
    {
        '& table':
        {
            border: 'none',
            height: 'inherit',
        },
        '& td:not(:first-child)':
        {
            border: `1px solid ${theme.palette.colors.grey[400]} !important`
        },
        '& td':
        {
            border: `1px solid transparent !important`,
            backgroundColor: '#111111',
            padding: "24px 16px 24px 16px",
            verticalAlign: 'top',
            // width: '202px !important',
            // maxWidth: '202px !important',
            '& p':
            {
                height: '100%'
            }
        }
    }
}));

const columns = [
    { name: 'description', label: 'Description', align: 'left', width: 416 },
    { name: 'freemium', label: 'Freemium', align: 'center', width: 202 },
    { name: 'lite', label: 'Lite', align: 'center', width: 202 },
    { name: 'professional', label: 'Professional', align: 'center', width: 202 },
    { name: 'premium', label: 'Premium', align: 'center', width: 202 }
];

const MembershipHeaders = ({ data }) => {
    const classes = useStyles();

    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        if (data.length > 0) {
            const row = { id: 1, description: "", freemium: "", lite: "", professional: "", premium: "" };

            row.freemium = data.filter(item => item.id === "freemium")[0]?.component;
            row.lite = data.filter(item => item.id === "lite")[0]?.component;
            row.professional = data.filter(item => item.id === "professional")[0]?.component;
            row.premium = data.filter(item => item.id === "premium")[0]?.component;

            setRows([row]);
        }
    }, [data]);

    return (
        <Box>
            <Table
                hideHeaders
                className={classes.table_title}
                maxHeight='calc(1000vh - 300px)'
                columns={columns}
                rows={rows}
            />
        </Box>
    );
};

export default MembershipHeaders;