import React from 'react'

const GradientSVG = () => {
  return (
    <svg style={{ height: 0 }}>
    {/* <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"> */}
        <defs>
            <linearGradient id="linearColors" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="1.56%" stop-color="#580CC7" />
            <stop offset="41.15%" stop-color="#8B3FFA" />
            <stop offset="68.23%" stop-color="#DF43B7" />
            <stop offset="86.46%" stop-color="#FF8000" />
            <stop offset="100%" stop-color="#FFC633" />
            </linearGradient>
        </defs>
        {/* <circle cx="50" cy="50" r="47" stroke="url(#gradient)" stroke-width="6" fill="none" transform='rotate(90 50 50)'/> */}
    </svg>
  )
}

export default GradientSVG
