import React from 'react';
import { makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

//import { log } from '../../../shared/utils/console.js'
import clsx from 'clsx';
import moment from 'moment';
import BodyActions from './BodyActions';

const useRowStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
        '&:hover':{
            backgroundColor: `${theme.palette.colors.grey[400]} !important`,
        }
    },
    'nameLimit': {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "300px",
    },
    titleRow: {
        alignItems: "center",
        display: "flex",
        marginRight: "auto",
    },
    titleRowDates: {
        alignItems: "center",
        display: "flex",
        marginLeft: "2.5px",
        marginRight: "2.5px"
    },
    check: {
        pointerEvents: "none"
    },
    checkGreen: {
        fill: "green",
    },
    checkRed: {
        fill: "red",
    },
    replay: {
        pointerEvents: "all",
        '&:hover':{
            cursor:"pointer"
        }
    },
    iconConcepto:{
        fontSize: "15px", 
        color: "gray", 
        marginLeft: "5px",
        '&:hover':{
            cursor: "pointer",
        }
    },
    rowSteps:{
        '&:hover':{
            backgroundColor: "#323232"
        }
    },
    err:{
        textAlign: "start",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "1",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        marginLeft: "10px",
        maxWidth: "430px",
        height: "15px",
        marginTop: "auto",
        marginBottom: "auto",
        '&:hover':{
            //cursor: "pointer",
        }
    }
}));


const haveTimeAlert = (fechaIni, fechaFin) =>{
    const fecha1 = (new Date(fechaIni));
    const fecha2 = (new Date(fechaFin));

    const seconds = Math.floor((fecha2 - (fecha1)) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const dias = Math.trunc(hours / 24);
    if(dias > 2 && dias < 4){
        return "yellow";
    }else if(dias >= 4){
        return "red";
    }
    return "white";
}


const calcularTiempoProcessoCompra = (fechaIni, fechaFin) => {
    const fecha1 = (new Date(fechaIni));
    const fecha2 = (new Date(fechaFin));

    const seconds = Math.floor((fecha2 - (fecha1)) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const dias = Math.trunc(hours / 24);

    /*log("dias");
    log(dias);
    log("horas");
    log(hours%24);
    log("minutes");
    log(minutes%60);*/

    let resp = "";
    if (dias > 0) {
        
        dias == 1 ? resp += dias + " día " : resp += dias + " días ";
        if (hours % 24 > 0) {
            hours % 24 == 1 ? resp += "con " + hours % 24 + " hora " : resp += "con " + hours % 24 + " horas ";
            if (minutes % 60 > 0) {
                minutes % 60 == 1 ? resp += "y " + minutes % 60 + " minuto" : resp += "y " + minutes % 60 + " minutos";
            }
        } else {
            if (minutes % 60 > 0) {
                minutes % 60 == 1 ? resp += minutes % 60 + " minuto" : resp += minutes % 60 + " minutos";
            }
        }
    } else {
        if (hours % 24 > 0) {
            hours % 24 == 1 ? resp += hours % 24 + " hora " : resp += hours % 24 + " horas ";
            if (minutes % 60 > 0) {
                minutes % 60 == 1 ? resp += "y " + minutes % 60 + " minuto" : resp += "y " + minutes % 60 + " minutos";
            }
        } else {
            if (minutes % 60 > 0) {
                minutes % 60 == 1 ? resp += minutes % 60 + " minuto" : resp += minutes % 60 + " minutos";
            }
        }
    }

    return resp;

}


const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [totalTime, setTotalTime] = React.useState(0);
    
    const [timeAlert, setTimeAlert] = React.useState("white");
    const [firstDate, setFirstDate] = React.useState("En espera");
    const [lastDate, setLastDate] = React.useState("En espera");
    const [haveError, setHaveError] = React.useState(false);
    const [steps, setSteps] = React.useState(0);
    const [nexSteps, setNexSteps] = React.useState([]);
    const classes = useRowStyles();
    
    const nextStepsToRun = (info) =>{
        setNexSteps(info);
    }

    const setLastDateUpdate = () => {
        let first_date = null;
        let last_date = null;
        let countProcess = 0;
        row.steps.map((step, index) => {
            if(index == 0){first_date = step?.start_date}
            if(step.status == 3 || step.status == 2){
                if(step.status == 3){setHaveError(true);}else{countProcess++;}
                last_date = step.end_date;
            }
        });
        setSteps(countProcess);
        setFirstDate(new Date(moment.utc(first_date).local().format()).toLocaleString());
        setLastDate(new Date(moment.utc(last_date).local().format()).toLocaleString());
        if(countProcess == row?.steps?.length){
            setTotalTime(calcularTiempoProcessoCompra(moment.utc(first_date).local().format(), moment.utc(last_date).local().format()));
            setTimeAlert("green");
        }else{
            setTotalTime(calcularTiempoProcessoCompra(moment.utc(first_date).local().format(), new Date().toString()));
            setTimeAlert(haveTimeAlert(moment.utc(first_date).local().format(), new Date().toString()));
        }
    }

    React.useEffect(()=>{
        setLastDateUpdate();
    }, []);

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.id}
                </TableCell>
                <TableCell align="center">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Typography component="span" variant="body2" style={{ marginBottom: "5px" }}>{row.person.name}</Typography>
                    <Typography component="span" variant="body2">{row.person.email}</Typography>
                </Box>
                </TableCell>
                
                    <TableCell align="center" className={classes.nameLimit}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            {row?.concept == 1 ? "Curso": "Programa" }
                            <i className={clsx(classes.iconConcepto, 'ri-information-fill')}></i>
                        </Box>  
                    </TableCell>

                {<TableCell align="center">{ steps < 8 && !haveError ? steps == 1 ? "En checkout...":"En proceso..." : steps == 8 ? "Completado" : "Detenido"}</TableCell>}
                {/*<TableCell align="center">{row.status}</TableCell>*/}
                <TableCell align="center" style={{ color: timeAlert}}>{
                    totalTime
                }</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h4" gutterBottom component="div">
                                Pasos
                            </Typography>
                            <Box display="flex" flexDirection="row" maxWidth="100%" justifyContent="center" alignItems="center">
                                <Typography variant="h6" component="div" style={{ margin: "10px" }}>
                                    Fecha inicio:
                                </Typography>
                                <Typography variant="h6" component="div" color='primary'>
                                    {firstDate}
                                </Typography>
                                <Typography variant="h6" component="div" style={{ margin: "10px" }}>
                                    Última actualización:
                                </Typography>
                                <Typography variant="h6" component="div" color='primary'>
                                    {lastDate}
                                </Typography>
                                {/*
                                    getStatusEndOrContinue(row.steps) == true && <>
                                        <Typography variant="h6" component="div" style={{ margin: "10px" }}>
                                            Tiempo en el estado actual:
                                        </Typography>
                                    
                                    <Typography variant="h6" component="div" color='primary'>
                                            {totalTimeInProcess}
                                        </Typography>

                                    </>*/
                                }
                            </Box>

                            <Box>

                                {
                                    row.steps.map((step) => (
                                        <Box display="flex" flexDirection="row" justifyContent="space-between" textAlign="center" className={classes.rowSteps}>
                                            <BodyActions nexSteps={nexSteps} classes={classes} step={step} rowId={row.id} haveError={haveError}/>
                                        </Box>
                                    ))
                                }
                                
                            </Box>


                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default Row;