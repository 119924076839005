import React from 'react';

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ChevronRight, Group, Link, Lock } from '@material-ui/icons';
import { InputAdornment, MenuItem, Select, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const FORM_NAME = 'contact_me';

const SocialInfo = ({ contact_me, onChange }) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        grid: {
            borderTop: `1.5px solid ${theme.palette.colors.grey[700]}`,
        },
        card: {
            backgroundColor: theme.palette.colors.grey[400],
            padding: '1em',
        },
        titleContainer: {
            marginTop: '3px',
        },
        title: {
            color: theme.palette.colors.white,
            fontSize: '18px',
            fontWeight: 'normal',
        },
        label: {
            color: theme.palette.colors.fillStroke[100],
            fontWeight: 600,
            fontSize: '12px',
        },
        inputText: {
            color: theme.palette.colors.white,
            background: 'none',
            fontSize: '12px',
        },
        select: {
            '& .MuiSvgIcon-root': {
                color: theme.palette.colors.fillStroke[100],
            },
            height: '30px',
        },
        menuItem: {
            '&:hover': {
                backgroundColor: `${theme.palette.colors.fillStroke[100]} !important`,
            },
            '&.MuiListItem-root.Mui-selected': {
                backgroundColor: `${theme.palette.colors.grey[400]} !important`,
            },
            fontSize: '14px',
        },
        icon: {
            color: theme.palette.colors.fillStroke[100],
        },
        iconContainer: {
            textAlign: 'center',
        },
        link: {
            color: theme.palette.colors.fillStroke[100],
        },
        blockIcon: {
            height: '8px',
            marginTop:'5px',
            width:'20px',
        },
        circularProgress: {
            color: theme.palette.colors.white,
        },
        saveButton: {
            backgroundColor: theme.palette.colors.orange[300],
            color: theme.palette.colors.white,
        },
        alertMessage: {
            backgroundColor: theme.palette.colors.grey[500],
            color: theme.palette.colors.white,
            fontFamily: "'Source Sans Pro', sans-serif !important",
            fontSize: '14px',
            marginLeft: '15px'
        },
    });

    const classes = useStyles();
    return (
        <Grid container spacing={3} className="mt-1">
            <Grid item xs={12}>
                <Grid container spacing={5} alignItems='center'>
                    <Grid item xs={6}>
                        <Grid container className='p-2' alignItems='center'>
                            <Grid item xs={10} >
                                <Typography variant="subtitle2" className={classes.label}>
                                    Facebook
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select className={classes.select} value={contact_me.visibility.facebook} disableUnderline
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    renderValue={(value) => value === 'public' ? <Group fontSize='inherit' /> : <Lock fontSize='inherit' />}
                                    onChange={e => {
                                        contact_me.visibility.facebook = e.target.value;
                                        onChange({ target: { name: 'visibility', value: contact_me.visibility } }, FORM_NAME);
                                    }}>
                                    <MenuItem value="public" className={classes.menuItem}>Todos los usuarios</MenuItem>
                                    <MenuItem value="private" className={classes.menuItem}>Solo yo</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container onClick={() => document.getElementById('facebook_profile_url').focus()} className="Profile_FormControl">
                            <Grid item xs={11}>
                                <TextField
                                    id="facebook_profile_url"
                                    name="facebook_profile_url"
                                    type="url"
                                    fullWidth
                                    value={(contact_me.facebook_profile_url && contact_me.facebook_profile_url !== 'null') ? contact_me.facebook_profile_url : ''}
                                    onChange={(e) => onChange(e, FORM_NAME)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Link className={classes.link} />
                                            </InputAdornment>
                                        ),
                                        className: classes.inputText,
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Editar" placement="right-end">
                                    <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                <Grid container spacing={5} alignItems='center'>
                    <Grid item xs={6}>
                        <Grid container className='p-2' alignItems='center'>
                            <Grid item xs={10} >
                                <Typography variant="subtitle2" className={classes.label}>
                                    Linkedin
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select className={classes.select} value={contact_me.visibility.linkedin} disableUnderline
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    renderValue={(value) => value === 'public' ? <Group fontSize='inherit' /> : <Lock fontSize='inherit' />}
                                    onChange={e => {
                                        contact_me.visibility.linkedin = e.target.value;
                                        onChange({ target: { name: 'visibility', value: contact_me.visibility } }, FORM_NAME);
                                    }}>
                                    <MenuItem value="public" className={classes.menuItem}>Todos los usuarios</MenuItem>
                                    <MenuItem value="private" className={classes.menuItem}>Solo yo</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container onClick={() => document.getElementById('linkedin_profile_url').focus()} className="Profile_FormControl">
                            <Grid item xs={11}>
                                <TextField
                                    id="linkedin_profile_url"
                                    name="linkedin_profile_url"
                                    type="url"
                                    fullWidth
                                    value={(contact_me.linkedin_profile_url && contact_me.linkedin_profile_url !== 'null') ? contact_me.linkedin_profile_url : ''}
                                    onChange={(e) => onChange(e, FORM_NAME)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Link className={classes.link} />
                                            </InputAdornment>
                                        ),
                                        className: classes.inputText,
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Editar" placement="right-end">
                                    <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                <Grid container spacing={5} alignItems='center'>
                    <Grid item xs={6}>
                        <Grid container className='p-2' alignItems='center'>
                            <Grid item xs={10} >
                                <Typography variant="subtitle2" className={classes.label}>
                                    Twitter
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select className={classes.select} value={contact_me.visibility.twitter} disableUnderline
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    renderValue={(value) => value === 'public' ? <Group fontSize='inherit' /> : <Lock fontSize='inherit' />}
                                    onChange={e => {
                                        contact_me.visibility.twitter = e.target.value;
                                        onChange({ target: { name: 'visibility', value: contact_me.visibility } }, FORM_NAME);
                                    }}>
                                    <MenuItem value="public" className={classes.menuItem}>Todos los usuarios</MenuItem>
                                    <MenuItem value="private" className={classes.menuItem}>Solo yo</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container onClick={() => document.getElementById('twitter_profile_url').focus()} className="Profile_FormControl">
                            <Grid item xs={11}>
                                <TextField
                                    id="twitter_profile_url"
                                    name="twitter_profile_url"
                                    type="url"
                                    fullWidth
                                    value={(contact_me.twitter_profile_url && contact_me.twitter_profile_url !== 'null') ? contact_me.twitter_profile_url : ''}
                                    onChange={(e) => onChange(e, FORM_NAME)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Link className={classes.link} />
                                            </InputAdornment>
                                        ),
                                        className: classes.inputText,
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Editar" placement="right-end">
                                    <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                <Grid container spacing={5} alignItems='center'>
                    <Grid item xs={6}>
                        <Grid container className='p-2' alignItems='center'>
                            <Grid item xs={10} >
                                <Typography variant="subtitle2" className={classes.label}>
                                    Instagram
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select className={classes.select} value={contact_me.visibility.instagram} disableUnderline
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    renderValue={(value) => value === 'public' ? <Group fontSize='inherit' /> : <Lock fontSize='inherit' />}
                                    onChange={e => {
                                        contact_me.visibility.instagram = e.target.value;
                                        onChange({ target: { name: 'visibility', value: contact_me.visibility } }, FORM_NAME);
                                    }}>
                                    <MenuItem value="public" className={classes.menuItem}>Todos los usuarios</MenuItem>
                                    <MenuItem value="private" className={classes.menuItem}>Solo yo</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container onClick={() => document.getElementById('instagram_profile_url').focus()} className="Profile_FormControl">
                            <Grid item xs={11}>
                                <TextField
                                    id="instagram_profile_url"
                                    name="instagram_profile_url"
                                    type="url"
                                    fullWidth
                                    value={(contact_me.instagram_profile_url && contact_me.instagram_profile_url !== 'null') ? contact_me.instagram_profile_url : ''}
                                    onChange={(e) => onChange(e, FORM_NAME)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Link className={classes.link} />
                                            </InputAdornment>
                                        ),
                                        className: classes.inputText,
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Editar" placement="right-end">
                                    <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                contact_me.toastProps.ok && contact_me.toastProps.type === 'socialNetworks' &&
                <Alert autoFocus id='cm_alert_message' variant="outlined" severity={contact_me.toastProps.ok === 'true' ? 'success' : 'error'} className={`Profile_AlertMessage ${classes.alertMessage}`}>
                    {contact_me.toastProps.message}.
                </Alert>
            }
        </Grid>
    )
};


export default SocialInfo;
