import React from 'react';

// styles
import TimelineStyled from '../../../assets/styles/jss/components/timelineStyle'

function TimelineContainer(props) {
    const {children, ...rest} = props;
    return (
        <TimelineStyled {...rest}>
            <div>{children}</div>
        </TimelineStyled>
    );
}

export default TimelineContainer;