import React, { useEffect, useState } from 'react'

import { Chip, Grid, Typography } from '@material-ui/core'

import { interestGroupStyles } from './interestGroupStyles';

const InterestGroup = (props) => {

    const { academyName, academyId, interestList, allSelectedInterestsList, changeAllSelectedInterest, 
            allAcademiesInterests, setAllAcademiesInterests, changeInterestToSave, selectedAcademies, prevSelectedSkills } = props;
    
    const classes = interestGroupStyles();
    
    const [availableInterestByAcademy, setAvailableInterestByAcademy] = useState([]);

    useEffect(() => {
        if(availableInterestByAcademy.length === 0 && prevSelectedSkills.length > 0){
            const currentSelectedSkills = prevSelectedSkills.map(({id, ...skill }) => {
                return {
                    interestId: id,
                    ...skill,
                }
            })                    
            changeAllSelectedInterest(currentSelectedSkills);

            const modifyAllAcademiesSkills = allAcademiesInterests.map( interest => {
                if( prevSelectedSkills.some( selectedSkill => interest.id === selectedSkill.id) ){
                    return {
                        ...interest,
                        selected: 1
                    }
                }
                if( !prevSelectedSkills.some( selectedSkill => interest.id === selectedSkill.id) ){
                    return {
                        ...interest,
                        selected: 0
                    }
                }
            })
            const areaCardInfo = [];
            interestList.map( currentInterest => {
                modifyAllAcademiesSkills.some( interest => {
                    if(interest.id === currentInterest.id)
                        return areaCardInfo.push(interest)
                })
            })           
            setAllAcademiesInterests(modifyAllAcademiesSkills);
            setAvailableInterestByAcademy(areaCardInfo);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(availableInterestByAcademy.length === 0 && prevSelectedSkills.length === 0){
          const areaCardInfo = interestList.map( interest => (
              {...interest, selected: 0}
          ))
          setAvailableInterestByAcademy(areaCardInfo);
        }
        if(availableInterestByAcademy.length > 0 && allSelectedInterestsList.length > 0){
            const modifyAllAcademiesSkills = allAcademiesInterests.map( interest => {
                if( allSelectedInterestsList.some( selectedSkill => interest.id === selectedSkill.interestId) ){
                    return { ...interest, selected: 1 }
                }
                if( !allSelectedInterestsList.some( selectedSkill => interest.id === selectedSkill.interestId) ){
                    return { ...interest, selected: 0 }
                }
            })
            const chipSkillsInfo = [];
            interestList.map( currentInterest => {
                modifyAllAcademiesSkills.some( interest => {
                    if(interest.id === currentInterest.id)
                        return chipSkillsInfo.push(interest)
                })
            }) 
            setAllAcademiesInterests(modifyAllAcademiesSkills);
            setAvailableInterestByAcademy(chipSkillsInfo);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allSelectedInterestsList]);   

    const maxInterestsLength = 10;
    const currentSelectedTotal = allSelectedInterestsList.length;

    const changeSelectedProp = (interestId, isSelected, academyId) => {

        const verifyInterestIsAlreadySelected = allSelectedInterestsList.some( 
            interest => interest.interestId === interestId
        );

        if( currentSelectedTotal === maxInterestsLength && isSelected === 0)
            return;

        if(verifyInterestIsAlreadySelected && isSelected === 0)
            return;

        if(verifyInterestIsAlreadySelected && isSelected === 1){
            const removeSelectedInterest = allSelectedInterestsList.filter( interest => interest.interestId !== interestId);            
            const modifiedAcademies = availableInterestByAcademy.map( interest => {
                if( interest.id === interestId){
                    return {
                        ...interest,
                        selected: (isSelected === 0) ? 1 : 0,
                        academy: academyId
                    }
                }
                return interest
            })
            setAvailableInterestByAcademy(modifiedAcademies);
            changeAllSelectedInterest(removeSelectedInterest);
        } 
        if( !verifyInterestIsAlreadySelected && isSelected === 0){
            const modifiedAcademies = availableInterestByAcademy.map( interest => {
                if(interest.id === interestId){
                    return {
                        ...interest, 
                        selected: (isSelected === 0) ? 1 : 0,
                        academy: academyId
                    }
                }
                return interest
            })
            setAvailableInterestByAcademy(modifiedAcademies);
            changeAllSelectedInterest( prevState =>
                [ ...prevState, { interestId: interestId, academyId: academyId } ]
            );
        }
    }

    return (
        <Grid item xs={12}>
            <Typography align='left' variant='h5'>{ academyName }</Typography>
            <Grid container className={`${classes.chipWrapper} mt-1`}>
            {
                availableInterestByAcademy.length > 0 &&
                availableInterestByAcademy.map((interest, key) => 
                    <Grid item key={ key }>
                        <Chip
                            variant='outlined'
                            label={<Typography variant='caption' className={ classes.chipOff }>{interest.name}</Typography>}
                            className={ interest.selected ? `${classes.chip} active` : classes.reactChip }
                            onClick={() => changeSelectedProp( interest.id, interest.selected, academyId )}
                        />
                    </Grid>
                )
            }
            </Grid>
        </Grid>
    )
}

export default InterestGroup
