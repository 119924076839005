import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';

// core components
import Available from './Avalilable/Avalilable';

import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const Surveys = (props) => {
        
    const { isCurrentTab } = props;
    const sendEscolarEncuestasEventToMixPanel=()=>{
        const eventData = {};
        eventTracker('s_escolar_encuestas', eventData, ['mixpanel']);
    };    

    React.useEffect(()=>{
        if(isCurrentTab)
        {
            sendEscolarEncuestasEventToMixPanel();
        }
    },[isCurrentTab]);

    return(
        <>
            <Box>
                <Available />
            </Box>
        </>
    );
};

export default Surveys;
