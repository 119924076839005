import React, { useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import clsx from 'clsx';
// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// @material-ui/core
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

// utils
import objectIsEmpty from "../../../../../../shared/utils/objectIsEmpty";
import { getSessionCookie } from "../../../../../../shared/utils/Sessions";

// redux
import { connect } from "react-redux"
import * as paths from '../../../../../../routes/paths';

import { CourseService } from '@sdk-point/talisis';
import SaveUnsaveButton from '../SaveUnsaveButton/SaveUnsaveButton';

import Typography from '@material-ui/core/Typography';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import getConnectionAllies from '../../../../../../shared/components/Allies/AlliesConnect';
import Modalupselling from "../../../../../../shared/components/Modalupselling/ModalUpselling";

import getMembershipIcon from '../../../../../../shared/components/Modalupselling/MembershipIconSelector';
import { log } from '../../../../../../shared/utils/console.js'

import { startCheckout } from '../../../../../../shared/utils/legacyStartCheckout';

import { SOURCES } from '../../../../../../shared/constants/sources';
import { eventTracker } from '../../../../../../shared/components/EventTracker/EventTracker';

const ENABLE_CHECKOUT_SKU = true;

const CourseSubscription = (props) => {
    const history = useHistory();
    const { course, classes, user, membershipData, backgroundImage, extraInfo = true } = props;
    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
    const [loading, setLoading] = React.useState(false);
    const [isEnrolled, setIsEnrolled] = React.useState(false);
    const [licenseStatus, setLicenseStatus] = React.useState("");
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const [membershipIncluded, setMembershipIncluded] = React.useState(false);
    const [actualMembership, setActualMembership] = React.useState([]);
    const [nextMembership, setNextMembership] = React.useState([]);
    const [membershipModalOpen, setMembershipModalOpen] = useState(false);
    const [listDetails, setListDetails] = useState([]);
    const [hideInfoData, setHideInfoData] = useState(props.hideInfoData ? props.hideInfoData : false);
    React.useEffect(() => {
        if (course.is_enrolled === 1) {
            setIsEnrolled(true);
        }

        let temLicenseStatus = "";
        if (course.source_id === SOURCES.LINKEDIN_LEARNING || course.source_id === SOURCES.CREHANA || course.source_id === SOURCES.COURSERA) {
            if (course.is_license_active === 1) {
                temLicenseStatus = "actived";
                setLicenseStatus(temLicenseStatus);
            }
            else {
                temLicenseStatus = "inactive";
                setLicenseStatus(temLicenseStatus);
            }
        } else {
            temLicenseStatus = "free";
            setLicenseStatus(temLicenseStatus);
        }
        setMembershipIncluded(course?.user_membership_included || temLicenseStatus === "free" ? true : false);
        setActualMembership(membershipData?.user_membership?.actual_membership);

        if (course?.closest_membership_included?.length > 0) {
            const membership_found = membershipData?.memberships?.find(x => x.id === course.closest_membership_included[0]?.membership_id);
            setNextMembership(membership_found);
            setListDetails(membership_found?.list_details_alt == undefined || membership_found?.list_details_alt?.length == 0 ? membership_found?.list_details : membership_found?.list_details_alt);
        }
        else {
            setNextMembership(membershipData?.user_membership?.next_membership);
            setListDetails(membershipData?.user_membership?.next_membership?.list_details_alt == undefined || membershipData?.user_membership?.next_membership?.list_details_alt?.length == 0 ? membershipData?.user_membership?.next_membership?.list_details : membershipData?.user_membership?.next_membership?.list_details_alt);
        }
    }, []);

    const handleCourse = async () => {
        const body = {
            person_id: user?.person_id,
            email: user?.email,
            ou: user?.ou,
            course_url: course.course_url,
            source_id: course.source_id,
            course_url_neo: course.course_url_neo,
            course_id: course.id,
            company_id: course.company_id
        }

        const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + course.source_id, "_blank");
        var urlRespuesta = await getConnectionAllies(body);
        setTimeout(() => {
            win.focus();
            win.location.replace(urlRespuesta);
        }, 3000);
    };

    const handleEnroll = async () => {
        if (isLogged && !objectIsEmpty(user)) {
            setMembershipModalOpen(false);
            setLoading(true);
            try {
                const body = {
                    course_id: course.id,
                    person_id: user.person_id,
                }
                await courseService.saveEnrollmentCourse(body);
                setIsEnrolled(true);
                setLoading(false);
                handleCourse();
            } catch (err) {
                setLoading(false);
                log(err);
            }
        } else {
            history.push({ pathname: "/signup" });
        }
    }

    const handleMembershipCheckout = (e, nextMembership) => {
        e.preventDefault();
        const userLogged = isLogged && !objectIsEmpty(user);
        const membershipFees = nextMembership?.fees?.length > 0 ? nextMembership.fees : [];
        const membershipFee = membershipFees.find(obj => obj.term_slug === "TRIM");
        const membershipInfo = {
            membershipId: nextMembership?.id,
            membershipFee: membershipFee?.id || 0,
        };
        const productInfo = [
            { itemType: "membershipId", membershipInfo },
            { itemType: "course", ...course }
        ];

        let paymentPeriod = null;
        if (membershipFee?.term_months === 1) paymentPeriod = "mensual";
        if (membershipFee?.term_months === 3) paymentPeriod = "trimestral";
        if (membershipFee?.term_months === 6) paymentPeriod = "semestral";
        if (membershipFee?.term_months === 12) paymentPeriod = "anual";

        handleMembershipPurchaseEventTrack("popup_curso", nextMembership?.slug, paymentPeriod);

        if (userLogged) {
            startCheckout({
                user,
                orderProducts: productInfo,
                history,
                isLogged: userLogged
            });
        } else {
            startCheckout({
                user: { person_id: 0 },
                orderProducts: productInfo,
                history,
                isLogged: userLogged
            });
        }
    }

    const gotoMemberships = (e) => {
        e.preventDefault();
        //
        const eventData = {};
        eventData['source'] = 'curso';
        eventTracker('ver_membresías', eventData, ['mixpanel']);
        //
        history.push(`${paths.MEMBRESIAS}?sku=${course.product_sku}`);
    };

    const preHandleCheckout = (e) => {
        e.preventDefault();
        if (actualMembership != undefined) {
            if (isLogged && membershipIncluded) {
                handleEnroll();
            }
            else if (isLogged && !membershipIncluded) {
                setMembershipModalOpen(true);
            }
            else {
                setMembershipModalOpen(true);
            }
        }
        else {
            handleEnroll();
        }
    }

    const handleCheckout = (e) => {
        e.preventDefault();
        const userLogged = isLogged && !objectIsEmpty(user);
        let membershipFeeIdCurrently = membershipData?.user_membership?.membership_fee_id;
        let TERM_SLUG_CURRENTLY = actualMembership?.fees?.find(feed => feed?.id===membershipFeeIdCurrently)?.term_slug || 'TRIM';
        const membershipFees = nextMembership?.fees?.length > 0 ? nextMembership.fees : [];
        const membershipFee = membershipFees.find(obj => obj.term_slug === TERM_SLUG_CURRENTLY);
        const membershipInfo = {
            membershipId: nextMembership?.id,
            membershipFee: membershipFee?.id || 0
        };
        const productInfo = [
            { itemType: "membershipId", membershipInfo },
            { itemType: "course", ...course }
        ];

        let paymentPeriod = null;
        if (membershipFee?.term_months === 1) paymentPeriod = "mensual";
        if (membershipFee?.term_months === 3) paymentPeriod = "trimestral";
        if (membershipFee?.term_months === 6) paymentPeriod = "semestral";
        if (membershipFee?.term_months === 12) paymentPeriod = "anual";

        handleMembershipPurchaseEventTrack("curso", nextMembership?.slug, paymentPeriod);
        
        if (userLogged) {
            startCheckout({
                user,
                orderProducts: productInfo,
                history,
                isLogged: userLogged
            });
        } else {
            startCheckout({
                user: { person_id: 0 },
                orderProducts: productInfo,
                history,
                isLogged: userLogged
            });
        }
    }

    const disableIcon=<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0L12.1194 5.56588C12.4337 6.39129 12.5908 6.804 12.8934 7.10659C13.196 7.40917 13.6087 7.56632 14.4341 7.88063L20 10L14.4341 12.1194C13.6087 12.4337 13.196 12.5908 12.8934 12.8934C12.5908 13.196 12.4337 13.6087 12.1194 14.4341L10 20L7.88063 14.4341C7.56633 13.6087 7.40917 13.196 7.10659 12.8934C6.804 12.5908 6.39129 12.4337 5.56588 12.1194L0 10L5.56588 7.88063C6.39129 7.56633 6.804 7.40917 7.10659 7.10659C7.40917 6.804 7.56632 6.39129 7.88063 5.56588L10 0Z" fill="#78787D"/>
        </svg>;

    const customMembershipMessage=( membershipData?.user_membership?.is_custom_membership && course?.source_id === SOURCES.COURSERA && extraInfo && <Grid item xs={12} className={classes.customMembershipMessageContainer}>
        <Box className='mb-3'>
            <Typography className={classes.customMembershipMessage}>
                Los cursos de COURSERA son un beneficio exclusivo para usuarios Premium. Para conocer más sobre nuestras membresías llámanos al:
            </Typography>
        </Box>
        <Box className={clsx(classes.customMembershipMessageBorder)}>
            <Typography className={classes.customMembershipMessage}>
                <Grid container>
                    <Grid item className='mr-2'>
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9.28724V11.6446C12.0001 11.8134 11.9361 11.9759 11.8211 12.0994C11.706 12.2229 11.5484 12.2981 11.38 12.3099C11.0887 12.3299 10.8507 12.3406 10.6667 12.3406C4.77533 12.3406 0 7.56524 0 1.67391C0 1.48991 0.01 1.25191 0.0306667 0.960576C0.0424816 0.792206 0.117721 0.634583 0.241202 0.519515C0.364684 0.404447 0.527215 0.3405 0.696 0.340576H3.05333C3.13603 0.340493 3.2158 0.371149 3.27715 0.42659C3.33851 0.482031 3.37706 0.558298 3.38533 0.640576C3.40067 0.79391 3.41467 0.91591 3.428 1.00858C3.56049 1.93319 3.832 2.83246 4.23333 3.67591C4.29667 3.80924 4.25533 3.96858 4.13533 4.05391L2.69667 5.08191C3.5763 7.13154 5.2097 8.76494 7.25933 9.64458L8.286 8.20858C8.32796 8.14991 8.38919 8.10783 8.459 8.08968C8.52881 8.07153 8.60277 8.07845 8.668 8.10924C9.51135 8.50982 10.4104 8.78066 11.3347 8.91258C11.4273 8.92591 11.5493 8.94058 11.7013 8.95524C11.7835 8.96367 11.8596 9.00229 11.9149 9.06363C11.9702 9.12497 12.0008 9.20465 12.0007 9.28724H12Z" fill="#CCCCCC"/>
                        </svg>
                    </Grid>
                    <Grid item>
                        8007709090 - opción 2
                    </Grid>
                </Grid>
            </Typography>
        </Box>
    </Grid>);

    const handleMembershipPurchaseEventTrack = (source, membership, paymentPeriod) => {
        const eventData = {};
        eventData['source'] = source;
        eventData['tipoMembresia'] = membership;
        eventData['periodoPago'] = paymentPeriod;
        eventTracker('comprar_membresia', eventData, ['mixpanel', 'hubspot','google']);
    };

    return (
        !isLogged || (isLogged && (licenseStatus === "actived" || licenseStatus === "free" || licenseStatus === "inactive")) ?
            <React.Fragment>
                {
                    licenseStatus !== "free" && !membershipIncluded &&
                    (extraInfo && (((membershipData?.user_membership?.is_custom_membership && course?.source_id !== SOURCES.COURSERA) || (!membershipData?.user_membership?.is_custom_membership)) &&
                        <React.Fragment>
                            <Box className="mb-4 mt-2">
                                <Typography variant='body2'>
                                    Si eres miembro <b>{nextMembership ? nextMembership.name : ""}</b>, este curso es solo uno de los muchos beneficios que podrás disfrutar
                                </Typography>
                            </Box>
                        </React.Fragment>))
                }
                {
                    licenseStatus === "free" && <Box>
                        <Typography variant="subtitle1" color={!hideInfoData ? "primary" : "secondary"} className={!hideInfoData ? classes.price : clsx(classes.responsive_price, "mb-3")} style={!hideInfoData ? { paddingBottom: '20px' } : { fontSize: '24px', fontWeight: '700', lineHeight: '24px' }}>
                            Curso gratis
                        </Typography>
                    </Box>
                }
                {isEnrolled ?
                    <Box display="flex">
                        <Box display="flex" style={{ width: isLogged && !hideInfoData ? "85%" : "100%" }}>
                            <Button variant="contained" color='primary' fullWidth onClick={handleCourse} style={{ textTransform: 'none' }}>Continuar curso ahora</Button>
                        </Box>
                        {isLogged && !hideInfoData && <SaveUnsaveButton course={course} classes={classes.markerButton} white={true} />}
                    </Box> :
                    <Box style={{ width: !extraInfo ? '100%' : 'auto', height: !extraInfo ? '48px' : 'auto', display: licenseStatus !== "free" ? 'unset' : 'contents' }}>
                        {
                            licenseStatus!=="free" && !membershipIncluded ?
                            <React.Fragment>
                            {
                                ENABLE_CHECKOUT_SKU ? 
                                    <Box display="flex" className='mb-4'>
                                        <Button variant="outlined" color='secondary' fullWidth onClick={(e) => gotoMemberships(e)} style={{ textTransform: 'none' }}>
                                            {actualMembership != undefined && actualMembership?.sidebar_paid?.memberships_button}
                                        </Button>
                                    </Box> :
                                    <Box display="flex" className='mb-4'>
                                        <Box width="100%" style={{ position: 'relative', width: isLogged && !hideInfoData ? "85%" : "100%" }}>
                                            <Button variant="contained" color={(membershipData?.user_membership?.is_custom_membership && course?.source_id === SOURCES.COURSERA) ? 'secondary' : 'primary'} disabled={loading || (membershipData?.user_membership?.is_custom_membership && course?.source_id === SOURCES.COURSERA)} fullWidth onClick={e => handleCheckout(e)} style={{ textTransform: 'none', fontWeight: '400 !important', height: !extraInfo ? '48px' : 'auto' }}>
                                                <Grid container justifyContent='center'>
                                                    {
                                                        (membershipData?.user_membership?.is_custom_membership && course?.source_id === SOURCES.COURSERA) ?
                                                            <Grid item xs="auto" style={{ paddingRight: '9px', textAlign: 'right' }}>
                                                                {disableIcon}
                                                            </Grid> :
                                                            <Grid item xs="auto" style={{ paddingRight: '9px', textAlign: 'right' }}>
                                                                {getMembershipIcon(nextMembership?.id, 13)}
                                                            </Grid>
                                                    }
                                                    <Grid item xs="auto" style={{ textAlign: 'left' }}>
                                                        <Typography className={clsx({
                                                            [classes.buttonCheckout]: (membershipData?.user_membership?.is_custom_membership && course?.source_id === SOURCES.COURSERA) ? true : false,
                                                            "font-variant-v7": true
                                                        })}>Membresía {nextMembership ? nextMembership.name : ""}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                            {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, }} />}
                                        </Box>
                                        {isLogged && !hideInfoData && <SaveUnsaveButton course={course} classes={classes.markerButton} white={true} />}
                                    </Box>
                                }
                                {customMembershipMessage}
                                <Box className='mb-3' hidden={hideInfoData}>
                                    <Typography variant='body' style={{ fontSize: '12px' }} >Beneficios de { nextMembership?nextMembership.name:"" }</Typography>
                                </Box>
                                { 
                                    !hideInfoData && listDetails.map((value, index) => {
                                        return (
                                            <Box sx={{ textAlign: 'left', paddingBottom: '8px' }}>
                                                <Grid container>
                                                    <Grid item xs="auto">
                                                        <CheckIcon style={{ fontSize: '16px', color: '#9F6FFB', marginRight: '14px' }} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography className="font-variant-v2" style={{ paddingLeft: '0px', color: '#A0A9BA' }}> {value.title}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        );
                                    })
                                }
                            </React.Fragment> :
                            <Box display="flex">
                                <Box width="100%" style={{ position: 'relative', width: isLogged && !hideInfoData ? "85%" : "100%" }} className={hideInfoData ? "" : ""}>
                                    {
                                        actualMembership != undefined ?
                                            <Button variant="contained" color='primary' disabled={loading} fullWidth onClick={e => preHandleCheckout(e)} style={{ textTransform: 'none', fontWeight: '400 !important' }}>{licenseStatus === "free" ? actualMembership?.sidebar_free?.action_button : actualMembership?.sidebar_paid?.action_button}</Button> :
                                            <Button variant="contained" color='primary' disabled={loading} fullWidth onClick={e => handleEnroll(e)} style={{ textTransform: 'none', fontWeight: '400 !important' }}>Tomar curso ahora</Button>
                                    }
                                    {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, }} />}
                                </Box>
                                {isLogged && !hideInfoData && <SaveUnsaveButton course={course} classes={classes.markerButton} white={true} />}
                            </Box>
                        }
                        {
                            actualMembership != undefined &&
                                <React.Fragment>
                                    <Box style={{ marginBottom: '8px', marginTop: '24px' }} hidden={(licenseStatus === "free" && actualMembership?.sidebar_free?.title === "") || (licenseStatus !== "free")}>
                                        <Typography className="font-variant-v7">{licenseStatus === "free" ? actualMembership?.sidebar_free?.title : ""}</Typography>
                                    </Box>
                                    <Box hidden={(licenseStatus === "free" && actualMembership?.sidebar_free?.description === "") || (licenseStatus !== "free" && actualMembership?.sidebar_free?.description === "") || (course?.source_id === SOURCES.COURSERA || course?.source_id === SOURCES.LINKEDIN_LEARNING || course?.source_id === SOURCES.CREHANA)} style={(licenseStatus === "free" && actualMembership?.sidebar_free?.title === "") || (licenseStatus !== "free" && actualMembership?.sidebar_free?.title === "") ? { paddingTop: '24px' } : {}}>
                                        <Typography className='font-variant-v2'>{licenseStatus === "free" ? actualMembership?.sidebar_free?.description : actualMembership?.sidebar_paid?.description}</Typography>
                                    </Box>
                                    {
                                        licenseStatus === "free" && actualMembership?.sidebar_free?.memberships_button !== "" && !hideInfoData &&
                                            <Box display="flex" style={{ marginTop: '24px' }}>
                                                <Box display="flex" style={{ width: "100%" }}>
                                                    <Button variant="outlined" color='secondary' fullWidth onClick={(e) => gotoMemberships(e)} style={{ textTransform: 'none' }}>
                                                        {licenseStatus === "free" ? actualMembership?.sidebar_free?.memberships_button : actualMembership?.sidebar_paid?.memberships_button}
                                                    </Button>
                                                </Box>
                                            </Box>
                                    }
                                </React.Fragment>
                        }
                    </Box>
                }
                {
                    !hideInfoData &&
                    <Box display="flex" flexDirection="column" className='mt-3 mb-2' alignItems="flex-start" style={{ maxHeight: "175px", overflow: 'hidden', borderRadius: '8px' }}>
                        <img src={backgroundImage} alt={course.name} style={{ width: "100%" }} />
                    </Box>
                }
                <Modalupselling isLogged={isLogged} membershipIncluded={membershipIncluded} handleEnroll={handleEnroll} classes={classes} actualMembership={actualMembership} nextMembership={nextMembership} course={course} user={user} membershipModalOpen={membershipModalOpen} setMembershipModalOpen={setMembershipModalOpen} isFree={true} isCourse={true} handleMembershipCheckout={handleMembershipCheckout} />
            </React.Fragment> :
            <Box display="flex">
                <Button variant="outlined" className={classes.subscribe} style={{ textTransform: 'none', width: "80%", marginRight: '10px' }} color='primary' fullWidth>Próximamente</Button>
                <Box className={classes.lockButton}>
                    <LockOutlinedIcon fontSize='small'  ></LockOutlinedIcon>
                </Box>
            </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CourseSubscription);