import React from "react";
import clsx from "clsx";

//@Material-ui
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
//Styles 
import useStyles from '../../../../../../../assets/styles/jss/pages/memberships/versionSKU/MembershipsContainerCard/MembershipCard/MembershipComboBrandsStyle';
//DesignSystem
import LogoSource from '../../../../../../../design-system/components/LogoSource/LogoSource'

const MembershipCardComboOwnBrands = ({membershipName}) => {
    const classes = useStyles();
    const SOURCE_ID_UNID = 9;
    const SOURCE_ID_UERRE = 8;
    return (
        <>
            { membershipName === 'premium' ?
                <Grid container justifyContent="center" className="mt-3">
                    <Grid item ms={6}>
                        <Box className={classes.boxComboOwnBrandsPreMBA}>
                            <Typography variant="body1" className={clsx(["semi-bold", classes.titleBoxComboOwnBrandsPreMBA])}>
                                MBA
                            </Typography>
                            <LogoSource color='white' sourceId={SOURCE_ID_UERRE} disabledAutoRes={true} responsive={false} className={classes.logoSourceUerre}/>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box className={classes.boxComboOwnBrandsAllProgram}>
                            <Typography className={clsx([classes.titleBoxComboOwnBrandsAllProgram])}>
                               Todos los programas
                            </Typography>
                            <LogoSource color='white' sourceId={SOURCE_ID_UNID} disabledAutoRes={true} responsive={false} className={classes.logoSource}/>
                        </Box>
                    </Grid>
                </Grid>
                :
                <Box className={classes.boxComboOwnBrands}>
                    {  membershipName === 'lite' ?
                        <Typography  variant="body1" className={clsx(["semi-bold", classes.titleOwnBrandsLite])}>Bachillerato</Typography>
                        :<Box>
                            <Typography  variant="body1" className={clsx(["semi-bold", classes.titleOwnBrandsProdWhite])}>Bachillerato</Typography>
                            <Typography  variant="body1" className={clsx(["semi-bold", classes.titleOwnBrandsProdYellow])}>Licenciaturas y Maestrías</Typography>                
                        </Box>
                    }
                    <LogoSource color='white' sourceId={SOURCE_ID_UNID} disabledAutoRes={true} responsive={false} className={classes.logoSource}/>
                </Box>
           }
        </>
    )
}

export default MembershipCardComboOwnBrands; 