import { SET_ACTIVE_TAB, SET_ACTIVE_SUB_TAB } from '../types/tabsTypes';

const initialState = {
  activeTabIndex: 0,
  activeSubTabIndex: 0
};

export default(state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.payload,
      };
    case SET_ACTIVE_SUB_TAB:
      return {
        ...state,
        activeSubTabIndex: action.payload,
      };
    default:
      return state;
  }
}