import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    label: {
        textAlign: 'left',
        alignItems: 'start',
        '&& span': {
            fontSize: '12px',
            lineHeight: '16px !important',
        },
        '& .MuiCheckbox-root': {
            paddingTop: 0
        }
    }
}));

const CheckTermsConditions = (props) => {
    const classes = useStyles();
    const {accept, className, startText, endText, variant, colorLink, colorCheckbox, onChange, secondaryLinkText, name} = props;
    return (
        <FormControlLabel
            className={classes.label}
            control={<Checkbox checked={accept} onChange={onChange} name={name} color={colorCheckbox}/>}
            label={
                <Typography variant={variant} className={className}>
                {startText}&nbsp;<Link component={RouterLink} to="/terminos_condiciones" color={colorLink} target={'_blank'}>Términos y Condiciones</Link> y <Link component={RouterLink} to="/aviso_privacidad" color={colorLink} target={'_blank'}>{secondaryLinkText}</Link>&nbsp;{endText}
                </Typography>
            }
        />
    );
};

CheckTermsConditions.defaultProps = {
    name: 'accept',
    startText: '',
    endText:'de Talisis',
    variant:'caption',
    colorLink:'primary',
    colorCheckbox:'primary',
    secondaryLinkText: 'Aviso de privacidad'
};

CheckTermsConditions.propTypes = {
    startText: PropTypes.string.isRequired,
    endText: PropTypes.string.isRequired,
    variant:PropTypes.string,
    colorCheckbox:PropTypes.oneOf([
        "primary",
        "secondary"
    ]),
    colorLink:PropTypes.oneOf([
        "primary",
        "secondary"
    ]),
    className: PropTypes.any,
    accept: PropTypes.bool,
    onChange: PropTypes.func
}

export default CheckTermsConditions;