import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    image: {
        height: props => (props.size === 'small') ? 229 : (props.size === 'medium') ? 128 : 257,
    },
    actionButtonContainer: {
        margin: "0 30px 15px 30px",
    },
    textContainer: {
        margin: "0 30px",
        paddingBottom: 0
    },
    containerDetailResponsive: {
        padding: '16px',
        width: '100%',
        height: '100%',
    },
    containerDetailNoneResponsive: {
        padding: '16px',
        height: '100%',        
    },
    darkImage: {
        backgroundImage: props => `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${props.eventImage}") !important`,
    },
    cardContainer: {
        width: props => props.size === "medium" ? '225px' : "auto",
        boxSizing: "border-box",
    }
}));
