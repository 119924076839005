import { Box, Divider, Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { getLogoCard } from '../../../utils/imageSource';
import ProfilePicUsers from '../../ProfilePicUsers/ProfilePicUsers';
import palette from '../../../../design-system/theme/palette';
import { Rating } from '@material-ui/lab';
import { StarBorder } from '@material-ui/icons';

const useStyles = makeStyles({
    profilePic: {
        width: '96px',
        height: '96px',
    },
});

const CardFirst = (props) => {
  const classes = useStyles();

  return (
    <Box className={props.className} id="card-first-root" display='flex' flexDirection='column' alignItems='center' gridGap={16}>
        <ProfilePicUsers className={classes.profilePic} email={props.email}/>

        <Box>
          <Typography variant='h3'>{props.name}</Typography>
          {
            props.profileAdviserData &&
            <Box style={{display: 'flex', flexDirection: 'row'}} justifyContent='center'>
              <Rating
                  readOnly
                  precision={0.5}
                  value={parseFloat(props.profileAdviserData?.calificacion) || 0}
                  name="consultancies_rating"
                  emptyIcon={<StarBorder fontSize="inherit" />}
              />
              {props.profileAdviserData && <Typography variant='caption'>({props.profileAdviserData.num_asesorias})</Typography>}
            </Box>
          }
        </Box>

        {props.ou && <img alt="logo" style={{padding: 0, margin: 0, width: '96px', height: '96px'}} src={ getLogoCard(props.ou) }/>}
        
        <Box style={{display: 'flex', flexDirection: 'row'}} gridGap={4}>
            <Icon className="ri-map-pin-line text-dark-black-200" style={{fontSize:17}}/>
            <Typography variant='body2' style={{color: palette.colors.darkBlack[200]}} className='semi-bold'>{props.location}</Typography>
        </Box>

        {
          props.children && <Divider style={{background: palette.colors.fillStroke[200], width: '201px'}} />
        }
        {props.children}
    </Box>
  );
};

CardFirst.defaultProps = {
  location: 'Ubicación pendiente',
  name: ''
};
export default CardFirst;