import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    ItemListCourseCardBody: {
        width: '100%',
        height: '100%',
        background: theme.palette.colors.fillStroke[400],
        borderRadius: '8px',
        display: 'flex',
    },
    sectionMedia: {
        height: '100%',
        width: '124px',
    },
    sectionContent: {
        width: '204px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
}));