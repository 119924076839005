

export const validateCard = (form = []) => {
    const _errors = {};
    const requiredFields = ['number', 'name', 'exp_year', 'exp_month', 'cvc'];

    for (const field of requiredFields) {
        if (!form[field]) {
            _errors[field] = 'Este dato es necesario';
        } else {
            if (field === "number") {
                if (form[field].length < 15) {
                    _errors[field] = 'El número mínimo de caracteres es de 15';
                }
            } else if (field === "exp_month") {
                if (form[field].length < 2) {
                    _errors[field] = 'El número mínimo de caracteres es de 2';
                } else if (form[field] > 12) {
                    _errors[field] = 'Debe ser mayor o igual a 12';
                }
            } else if (field === "exp_year") {
                const currentYear = new Date().getFullYear().toString();
                if (form[field] < currentYear.slice(2, 4)) {
                    _errors[field] = `Debe ser mayor o igual a ${currentYear.slice(2, 4)}`;
                }
            } else if (field === "cvc") {
                if (form[field].length < 3) {
                    _errors[field] = 'El número mínimo de caracteres es de 3';
                }
            }
        }
    }

    return Boolean(Object.keys(_errors).length === 0);
};
