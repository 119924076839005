import React from 'react'

// @material-ui
import { Box, Button, useMediaQuery } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

const FinalizePurchase = ({ onFinalizePurchase, acceptPayment }) => {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.down("xs"));

    return <Box className={"my-3"}>
        <Button
            fullWidth={breakpoint}
            disabled={!acceptPayment} 
            onClick={() => onFinalizePurchase()}
            color='primary'
            variant='contained'>
            Finalizar compra
        </Button>
    </Box>
}

export default FinalizePurchase;