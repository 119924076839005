import { makeStyles, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

const toolbarHeight = 102;

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.colors.gray[100],
        minHeight: '100vh'
    },
    content: {
        flexGrow: 1,
        overflowX: "hidden",
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        height: toolbarHeight
    },
    toolbar:{
        justifyContent: 'center',
        height: toolbarHeight
    },
    img:{
        backgroundImage: props => props.img,
        height: 274,
        width: '100%'
    },
    unid:{
        '& .app-bar':{
            backgroundColor: theme.palette.colors.ou.unidPurple
        },
        '& .title, .Mui-checked, .MuiCheckbox-root':{
            color: theme.palette.colors.ou.unidYellow
        },
        '& .subtitle':{
            color: theme.palette.colors.ou.unidPurple
        },
        '& .text':{
            color: theme.palette.colors.fillStroke[500]
        },
        '& .MuiFormLabel-root':{
            color: `${theme.palette.colors.fillStroke[200]} !important`,
        },
        '& .MuiInputBase-root':{
            backgroundColor: `${theme.palette.common.white} !important`,
        },
        '& .MuiInputBase-input': {
            backgroundColor: `${theme.palette.common.white} !important`,
            color: theme.palette.colors.fillStroke[200],
            '&:focus': {
                backgroundColor: '#FFFCF2',
                border: `1.5px solid ${theme.palette.colors.ou.unidYellow} !important`,
            },
            '&::placeholder': {
                color: `${theme.palette.colors.darkBlack[200]} !important`,
            },
        },
        '& .button-start':{
            backgroundColor: theme.palette.colors.ou.unidPurple,
            marginBottom: 12,
        },
        '& .button-login':{
            padding: 12,
            color: theme.palette.colors.ou.unidPurple
        },
        '& .terms':{
            color: theme.palette.colors.darkBlack[200],
            '& a':{
                color: theme.palette.colors.ou.unidYellow
            }
        },
    },
    uerre:{
        '& .app-bar':{
            backgroundColor: theme.palette.colors.ou.uerreBlack
        },
        '& .title':{
            color: theme.palette.colors.blue[300]
        },
        '& .subtitle':{
            color: theme.palette.colors.blue[300]
        },
        '& .text':{
            color: theme.palette.colors.fillStroke[200]
        },
        '& .MuiFormLabel-root':{
            color: `${theme.palette.colors.fillStroke[200]} !important`,
        },
        '& .MuiInputBase-root':{
            backgroundColor: `${theme.palette.common.white} !important`,
        },
        '& .MuiInputBase-input': {
            backgroundColor: `${theme.palette.common.white} !important`,
            color: theme.palette.colors.fillStroke[200],
            '&:focus': {
                border: `1.5px solid ${theme.palette.colors.ou.uerrePurple} !important`,
            },
            '&::placeholder': {
                color: `${theme.palette.colors.darkBlack[200]} !important`,
            },
        },
        '& .button-start':{
            backgroundColor: theme.palette.colors.ou.uerrePurple,
            marginBottom: 12,
        },
        '& .button-login':{
            padding: 12,
        },
        '& .terms':{
            color: theme.palette.colors.darkBlack[200],
            '& a':{
                color: theme.palette.colors.ou.uerrePurple
            }
        },
        '& .Mui-checked, .MuiCheckbox-root, .button-login':{
            color: theme.palette.colors.ou.uerrePurple
        }
    },
    loader:{
        '& .MuiBackdrop-root':{
            maxWidth: "none !important",
            '& .MuiCircularProgress-colorPrimary':{
                color: props => props.ou === 'unid' ? theme.palette.colors.ou.unidPurple : theme.palette.colors.ou.uerrePurple
            }
        }
    }
}))