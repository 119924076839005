import React from 'react';
import { Button, Checkbox, Grid, TextField, Typography } from '@material-ui/core';

const UserNeoData = (props) => {
    const { data, roles, classes, onClick } = props;
    return (
        <Grid container spacing={1} className={`${classes.gridContainer} mt-3`}>
            <Grid item xs={12} className={`${classes.displayInlineFlex} ${classes.alignItemsCenter} mt-1 mb-2`}>
                <Typography component="span" variant="subtitle2"><b>NEO:</b></Typography>
            </Grid>
            {data.map(userDataByTenant => {
                const tenants = Object.keys(userDataByTenant);
                if (tenants && tenants.length > 0) {
                    return (
                        tenants.map(tenant => (
                            <React.Fragment key={`rf_neo_user_tenant_${tenant}`}>
                                <Grid item xs={12} className="mt-1 mb-2">
                                    <Typography variant="subtitle2" color="primary"><b>{tenant}</b></Typography>
                                    <hr />
                                </Grid>
                                {userDataByTenant[tenant] && userDataByTenant[tenant].length > 0 ?
                                    userDataByTenant[tenant].map(userData => (
                                        <React.Fragment key={`rf_neo_user_data_${userData.id}`}>
                                            <Grid item xs={2}>
                                                <TextField
                                                    label="ID"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    value={userData.id}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Correo electrónico"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    value={userData.email}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="User ID"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    value={userData.userid}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    label="¿Archivado?"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    value={userData.archived ? 'Sí' : 'No'}
                                                    fullWidth
                                                />
                                                {userData.archived && <Button variant="contained" color="primary" size="small" className="mt-1" fullWidth onClick={async () => {
                                                    const response = await onClick(userData.archived, userData.id, tenant);
                                                    if (response && response.reactivated) {
                                                        userData.archived = false;
                                                    }
                                                }}>Desarchivar</Button>}
                                            </Grid>
                                            <Grid item xs={12} className="mb-2">
                                                <Typography component="span" variant="subtitle2" className="mt-2" color="textSecondary"><b>Roles:</b></Typography>
                                                {roles.map(field => userData[field] && (
                                                    <Typography component="span" variant="body2" key={`typ_neo_${userData.id}_role_${field}`}>
                                                        <Checkbox checked={userData[field] ? true : false} readOnly color="default" />
                                                        {field.replace(/^./, field[0].toUpperCase())}
                                                    </Typography>
                                                ))}
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                    :
                                    <Grid item xs={12} className="mt-1 mb-2">
                                        <Typography component="div" color="primary">No existe el usuario.</Typography>
                                    </Grid>
                                }
                            </React.Fragment>
                        ))
                    )
                }
            })}
        </Grid>
    )
};

export default UserNeoData;