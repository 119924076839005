import React, { lazy, Suspense } from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
//import { Skeleton } from '@material-ui/lab';

// core components
const CourseCardMedia = lazy(() => import('./CourseCardMedia'));
const CourseCardContent = lazy(() => import('./CourseCardContent'));
const useStyles = makeStyles((theme) => ({
    card_content: {
        width: "286px",
        minHeight: "388px",
        maxHeight: "388px",
        border: "0.01px solid #454b54",
        background: "linear-gradient(241.18deg, #111111 25.24%, #111111 100%)",
        transition: "0.3s",
        "@media (max-width: 445px)": {
            width: "226px",
        },
        "& .MuiCardActionArea-root": {
            width: "100%",
            height: "313px",
            textAlign: "inherit",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
        "&:hover": {
            //hover only color
            background: "linear-gradient(241.18deg, #111111 25.24%, #081e24 100%) !important",

            //hover color and size
            /*left: "0",
            top: "0",
            transition: "0.3s",
            transform: "scale(1.05)",
            zIndex: "10",*/
        },
    }
}));


const CourseCardBody = ({ media, content }) => {
    const classes = useStyles();
    //const [loading, setLoading] = React.useState(false);

    /*React.useEffect(() => {
        setTimerForOptimizeChargerData();
    }, []);*/

    /*const setTimerForOptimizeChargerData = async () => {
        setLoading(true);
    };*/

    return (
        <Card className={`${classes.card_content} card_content`}>
            { /*loading == true ?*/
                <Box>
                    <Suspense fallback={<div></div>}>
                        <CourseCardMedia {...media} />
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                        <CourseCardContent {...content} />
                    </Suspense>
                </Box>

                /*{:<Skeleton
                    style={{
                        width: "287px",
                        height: "649px",
                        minHeight: "649px",
                        maxHeight: "649px",
                        position: "absolute",
                        top: "-39.7%",
                        borderRadius: "8px", }} />}*/
            }
        </Card>
    );
};

export default CourseCardBody;