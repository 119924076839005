import React from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

// style
import useStyles from '../../assets/styles/components/table/tableStyle'

// Material - icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function TableComponent(props) {
    const {
        columns,
        rows,
        maxHeight,
        loading,
        checkboxs,
        checkboxDisabledIcon,
        rowSelectedList,
        onChangeSelectedRow,
        onChangeAllSelectedRow,
        onClickSort,
        className,
        type,
        handleClickInfoTable,
        hideHeaders,
        stripedRows,
        captionNothingFound = "Sin datos disponibles",
        subcaptionNothingFound = "Regresa más tarde",
        nothingFoundImg = "items"
    } = props;
    const classes = useStyles({type, stripedRows});
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [selected, setSelected] = React.useState([]);

    const handleSelectAllClick = (event) => {
        onChangeAllSelectedRow(event.target.checked);
    };

    const isSelected = (row) => {
        if (!rowSelectedList) return false;

        const tempList = rowSelectedList.filter(data => data.id === row.id);

        return !!tempList.length;
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSortColumn = (column) => {
        setOrder(order === "desc" ? "asc" : "desc");
        setOrderBy(column);
    };

    const handleSort = (a, b) => {
        if (b[orderBy] < a[orderBy]) return -1;
        if (b[orderBy] > a[orderBy]) return 1;
        return 0;
    };

    const handleClick = (event, name) => {
        if (checkboxs) {
            const selectedIndex = selected.indexOf(name);
            let newSelected = [];

            if (selectedIndex === -1) {
              newSelected = newSelected.concat(selected, name);
            } else if (selectedIndex === 0) {
              newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
              newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
              newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
              );
            }

            setSelected(newSelected);
            props.handleClickInfoTable(newSelected);
        }
    };

    return (
        <>
            <TableContainer className={className} style={{ maxHeight }}>
                <Table aria-label="simple table" stickyHeader>
                    {
                        !hideHeaders && <TableHead>
                            <TableRow  >
                                {checkboxs &&
                                    <TableCell padding="checkbox" className={classes.cellCheckBox}  align={"inherit"}  >
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Checkbox
                                                className={classes.checkbox}
                                                color="primary"
                                                // indeterminate={rowSelectedList.length > 0 && rowSelectedList.length < rows.length}
                                                checked={rows.length > 0 && rowSelectedList.length === rows.length}
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    'aria-label': 'select all desserts',
                                                }}
                                            />
                                        </Box>
                                    </TableCell>
                                }

                                {columns.map((column, index) => (
                                    <TableCell
                                        key={column.name}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, width: column.width }}
                                    >
                                        {
                                            column.sort
                                                ? <TableSortLabel
                                                    active={column.active}
                                                    direction={column.active ? column.direction : 'desc'}
                                                    onClick={(e) => props.onClickSort(column)}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    className={classes.buttonSortTable}
                                                >
                                                    <Typography variant="button">{column.label}</Typography>
                                                </TableSortLabel>
                                                : <Typography variant="button">{column.label}</Typography>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody style={{height: loading && !rows.length ? 250 : 'inherit' }}>
                        {rows.map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                  onClick={(event) => handleClick(event, row)}
                                  key={`table-row-${index}`}
                                    className={clsx(classes.tableRow, { [classes.stripedRows]: stripedRows, [classes.itemSelected]:isItemSelected })}
                                    hover
                                  tabIndex={-1}
                                    aria-checked={isItemSelected}>
                                    {
                                        checkboxs &&
                                        <TableCell padding="checkbox"  style={{ borderColor:"#404150" }}>
                                            <Box display={"flex"} alignItems={"center"}>
                                              {!checkboxDisabledIcon && (!!row.checkboxDisabled ?
                                                <i className="ri-lock-fill font-size-16 text-gray-100"></i> : <i
                                                  className="ri-lock-unlock-line font-size-16 text-dark-black-200"></i>)}
                                              <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                  'aria-labelledby': labelId,
                                                }}
                                                className={classes.checkbox}
                                                disabled={!!row.checkboxDisabled}
                                                onChange={() => onChangeSelectedRow(row)}
                                              />
                                            </Box>
                                        </TableCell>
                                    }
                                    {columns.map((column, index) => {
                                        let value = row[column.name];
                                        if(column.format) {
                                            value = column.format(row, value, index);
                                        };
                                        return (
                                            <TableCell className={classes.tableCell} key={`${row.id}-${index}`} align={column.align} style={{ width: column.width }}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

TableComponent.defaultProps = {
    columns: [],
    rows: [],
    maxHeight: 'calc(100vh - 290px)',
    loading: true,
  checkboxs: false,
  checkboxDisabledIcon: false
};

TableComponent.propTypes = {
    /* TABLA */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string, // nombre de la etiqueta o key
            align: PropTypes.string, // alineacion del texto
            minWidth: PropTypes.number, // Tamaño minimo de la columna
            width: PropTypes.number, // Tamaño fijo de la columna
            label: PropTypes.string, // Etiqueta de la columna (nombre que se muestra)
            format: PropTypes.func, // Funcion que retorna un componente, esto para celdas personalizadas.
            sort: PropTypes.bool, // Determina si la columana tendra la accion de order las celdas}
            active: PropTypes.bool, // Determina si la columna estara activa
            direction: PropTypes.string, // asc-desc tipo de ordenamiento de la columna.
        })
    ),
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            checkboxDisabled: PropTypes.bool, // Determina si el checkbox de la tabla esta deshabilitado
            /*
                LOS DEMAS NOMBRES DE LAS COLUMNAS SON LOS NAME DE LAS COLUMNAS DONDE VENDRA LOS VALORES.
            */
            // label: PropTypes.string,
            // format: PropTypes.func
        }).isRequired
    ).isRequired,
    hideHeaders: PropTypes.bool, // Ocultar las cabeceras de la tabla.
    stripedRows: PropTypes.bool, // Alternar color de fondo de los renglones de la tabla.
    maxHeight: PropTypes.string, // Tamaño de la tabla
    loading: PropTypes.bool, // Determina si aparecera el loading de la tabla.

  /* CHECKBOXES */
    checkboxs: PropTypes.bool, // Determina si se usaran como primera fila los checkbox
    checkboxDisabledIcon: PropTypes.bool, // Determina si deshabilita el icono del candado en el checkbox
    rowSelectedList: PropTypes.array, // Lista de filas selecionadas
    onChangeSelectedRow: PropTypes.func, // Funcion para modificar el estado de rowSelected
    onChangeAllSelectedRow: PropTypes.func, // Funcion para seleccionar/deseleccionar los items de la tabla.

    /* SORT */
    onClickSort: PropTypes.func, // Funcion de ordenar datos

    /* EXTRA */
    // className: PropTypes.object, // Personalizar la tabla (NO DEBERIA DE ESTAR AQUI)
    type: PropTypes.string, // (NO DEBERIA DE ESTAR AQUI)
    handleClickInfoTable: PropTypes.func, // (NO DEBERIA DE ESTAR AQUI)
};
