import React from 'react';

// components
import WorkflowPage from '../../../WorkflowPage/WorkflowPage';

// @material-ui
import { Box, Button, Icon, Typography } from '@material-ui/core';

// layout
import Login from '../../../../../../layout/components/Login/Login';

const LoginForm = ({ onSignUp }) => {
    return (
        <WorkflowPage title="Iniciar sesión">
            <Button onClick={onSignUp} variant='text' className='mt-2' startIcon={<Icon className="ri-arrow-left-line" color="secondary" /*style={{ verticalAlign: 'text-bottom', display: 'inline-flex' }}*/ />}>
                <Typography variant='body2' className='text-orange-300' style={{ cursor: 'pointer' }}>
                    Regresar
                </Typography>
            </Button>
            <Box style={{ marginTop: '-32px' }}>
                <Login />
            </Box>
        </WorkflowPage>
    );
};

export default LoginForm;