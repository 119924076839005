import React from 'react';

// @material-ui/lab
import TreeView from '@material-ui/lab/TreeView';

// @material-ui/icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// core components
import TreeItemCheckbox from './TreeItem/TreeItemCheckbox'
import TreeItemSimple from './TreeItem/TreeItemSimple'


function recursiveTree(nodes, level, onCheck){

    if(onCheck){
        return nodes.map(node =>   
            <TreeItemCheckbox 
                key={node.id} 
                nodeId={String(node.id)}
                labelText={node.label}
                level={level} 
                onChange={onCheck}
                checked={node.checked}
                name={node.name}
                indeterminate={node.indeterminate}
                parent={node.parent}
            >
                    {
                        node.children && recursiveTree(node.children, level + 1, onCheck)
                    }
            </TreeItemCheckbox>
        )
    }

    return nodes.map(node =>   
        <TreeItemSimple {...node} key={node.id}>
            {
                node.children && recursiveTree(node.children, level + 1, onCheck)
            }
        </TreeItemSimple>
    )
    
}

export default function TreeList(props) {
    const {items, onCheck} = props;
    const [idDefaultExpanded, setiIdDefaultExpanded] = React.useState([]);
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);


    React.useEffect(()=> {
        let ids = [];
        if(items.length){
            for (const i of items){
                ids.push(String(i.id));
            }
            setiIdDefaultExpanded((oldExpanded) =>
                oldExpanded.length === 0 ? ids : ids,);
            setExpanded((oldExpanded) =>
                oldExpanded.length === 0 ? ids : ids,
            );
            //setiIdDefaultExpanded([items[0].id]);
            //setExpanded([items[0].id])
        }
    }, [items])

    const handleToggle = (event, nodeIds) => {
        const hasRootParent = items.length === 1;
        // let _nodeIds = nodeIds.length > 2 && hasRootParent ? [nodeIds[0], 0] : nodeIds;
        let _nodeIds = nodeIds;

        setExpanded(_nodeIds);
      };
    
    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    return (
        <TreeView
        style={{flexGrow: 1,maxWidth: 400}}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        defaultExpanded={idDefaultExpanded}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        >
            {
                items && recursiveTree(items, 0, onCheck)
            }
        </TreeView>
    );
}