import React from 'react';
import { useSelector } from 'react-redux';

// @mui
import { Box, Hidden, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// @componets
import * as FORMAT from './components/genericBrandLandingPageFormat';
import GenericBrandLandingPageHeader from './components/GenericBrandLandingPageHeader/GenericBrandLandingPageHeader';
import BrandBanner from './BrandBanner/BrandBanner';
import BrandProgramsCard from './BrandProgramsCard/BrandProgramsCard';
import BrandPopularCourses from './BrandPopularCourses/BrandPopularCourses';
import GenericBrandLandingPagesPopularCoursesCard from './BrandPopularCourses/BrandPopularCourses';
import GenericBrandLandingPageSlide from './components/GenericBrandLandingPageSlide/GenericBrandLandingPageSlide';
import GenericBrandVideo from './GenericBrandVideo/GenericBrandVideo';
import GenericBrandLandingPageCourses from './GenericBrandLandingPageCourses/GenericBrandLandingPageCourses';
import GenericBrandLandingPageDetail from './GenericBrandLandingPageDetail/GenericBrandLandingPageDetail';
import FAQsComponent from '../../shared/components/FAQsComponent/FAQsComponent';
import BenefitsTableContentHH from './BenefitsTableContentHH/BenefitsTableContentHH'
import { userIsLogged } from '../../shared/utils/Sessions' ;
import { eventTracker } from '../../shared/components/EventTracker/EventTracker';

// @shared
import * as imageSource from '../../shared//utils/imageSource';
import objectIsEmpty from '../../shared//utils/objectIsEmpty';

// @SDK
import { CourseService, ProgramService,MarketPlaceService } from '@sdk-point/talisis';
import GenericBrandLandingPageDemorCourses from './GenericBrandLandingPageDemorCourses/GenericBrandLandingPageDemorCourses';
import StudyModalityHH from './StudyModalityHH/StudyModalityHH';
import ResponsiveContainer from '../../shared/components/ResponsiveContainer/ResponsiveContainer';
const ProgramServiceSDK = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]:{
            paddingBottom: props => props.userIsLogged ? 40 : 0
        }
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '46.5%',
    },
    content: {
        marginTop: '180px',
    },
    mt130px: {
        marginTop: '130px !important',
    }
}));

const GenericBrandLandingPage = (props) => {
    const { match: { params: { brand } } } = props;
    const { user } = useSelector((state) => state.userReducer);
    const userIsLogged = !objectIsEmpty(user);
    const classes = useStyles({userIsLogged});
    const [loading, setLoading] = React.useState(false);
    const [popularPrograms, setPopularPrograms] = React.useState([]);
    const [faqList, setFaqList] = React.useState([])
    const [isDemoUser, setIsDemoUser] = React.useState(false)
    const abortData = new AbortController();
    props = { ...props, user, popularPrograms };

    React.useEffect(() => {
        let role = localStorage.getItem('permissions');
        if(role) {
            role = JSON.parse(role)
            if(role.find(r => r.role_name === "Estudiante-Demo")) setIsDemoUser(true)
        }
        
        const fetchCoursesRecommended = async () => {
            const popularPrograms = await courseService.getCoursesRecommendedByType((user.person_id ?? ''), 'fourProgramsPopulars', FORMAT.getCompanyIdByBrand(brand));
            console.log('popularPrograms.data', popularPrograms)
            const coursesData =popularPrograms ? popularPrograms.data.courses.map(course =>({
                ...course,
                source_id: course.source,
                priceMembershipActions: true,
                price: {
                    number: course.price,
                    listNumber: course.list_price,
                },
                image_url: course.image || imageSource.convertSourceToImageResource(course.company, course.source)
            })) : [];

            if (coursesData?.length) {
                setPopularPrograms(coursesData);
            }
        };
        
        const fetchFAQ = async () => {
            const faqRes = await ProgramServiceSDK.getFAQList();
            const fetchedFaqList = await faqRes.body;
            setFaqList(fetchedFaqList);
        };

        const sendUerreEventToMixPanel=()=>{
            const eventData = {};
            eventTracker('s_uerre', eventData, ['mixpanel']);
        };

        const sendUnidEventToMixPanel=()=>{
            const eventData = {};
            eventTracker('s_unid', eventData, ['mixpanel']);
        };

        if(brand==='unid')
        {
            sendUnidEventToMixPanel();
        }
        else if(brand==='u-erre')
        {
            sendUerreEventToMixPanel();
        }

        fetchCoursesRecommended();
        fetchFAQ();
    }, []);

    return (
        <Box className={classes.root}>
            {
                loading 
                ?<CircularProgress className={classes.loading} />
                :<>
                    <GenericBrandLandingPageHeader brand={brand} />
                    <BrandBanner brand={brand}/>
                    <BrandProgramsCard brand={brand} />    
                    {brand === 'harmonhall' && <BenefitsTableContentHH />}

                    {/* cursos de educacion continua */}
                    <Hidden xsDown>
                        {(brand === 'unid' || brand === 'u-erre') && <BrandPopularCourses {...props} clickFrom="academias" />}
                        <GenericBrandLandingPageSlide {...props} />
                        <GenericBrandVideo brand={brand} />
                    </Hidden>

                    {/* Programas y cursos EDU */
                        (brand === 'unid' || brand === 'u-erre') && <GenericBrandLandingPageCourses className="mt-5" brand={brand} />
                    }

                    { /* Preguntas Frecuentes */
                        brand === 'unid' && faqList?.length && 
                        <ResponsiveContainer>
                            <FAQsComponent title="Preguntas frecuentes" questionsList={faqList} />
                        </ResponsiveContainer>
                    }

                    {brand === 'u-erre' && isDemoUser && 
                        <Box className={classes.content}>
                            <GenericBrandLandingPageDemorCourses className={`${classes.mt130px} mb-5`} {...props} />
                        </Box>
                    }
                    
                    {brand === 'harmonhall' && <StudyModalityHH/>}
                </>
        }
        </Box>
    );
};

export default GenericBrandLandingPage;
