import React from 'react'

// @material-core
import { Badge, CircularProgress, Grid, makeStyles, withStyles } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

// thirty-party
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

// local
import '../../../../assets/styles/scss/pages/profileCircleStyles.css'
import ProfilePic from '../../../../shared/components/ProfilePic/ProfilePic'
import ProfilePicUsers from "../../../../shared/components/ProfilePicUsers/ProfilePicUsers";

const IconProfile = () => {

  const _uuid = uuid();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10 0L10.8847 3.64386C11.18 4.85978 11.3276 5.46774 11.7968 5.6621C12.2661 5.85647 12.8003 5.53096 13.8689 4.87993L17.0711 2.92893L15.1201 6.13113C14.469 7.19967 14.1435 7.73394 14.3379 8.20318C14.5323 8.67243 15.1402 8.82004 16.3561 9.11527L20 10L16.3561 10.8847C15.1402 11.18 14.5323 11.3276 14.3379 11.7968C14.1435 12.2661 14.469 12.8003 15.1201 13.8689L17.0711 17.0711L13.8689 15.1201C12.8003 14.469 12.2661 14.1435 11.7968 14.3379C11.3276 14.5323 11.18 15.1402 10.8847 16.3561L10 20L9.11527 16.3561C8.82004 15.1402 8.67243 14.5323 8.20318 14.3379C7.73394 14.1435 7.19967 14.469 6.13113 15.1201L2.92893 17.0711L4.87993 13.8689C5.53096 12.8003 5.85647 12.2661 5.6621 11.7968C5.46774 11.3276 4.85978 11.18 3.64386 10.8847L0 10L3.64386 9.11527C4.85978 8.82004 5.46774 8.67243 5.6621 8.20318C5.85647 7.73394 5.53096 7.19967 4.87993 6.13113L2.92893 2.92893L6.13113 4.87993C7.19967 5.53096 7.73394 5.85647 8.20318 5.6621C8.67243 5.46774 8.82004 4.85978 9.11527 3.64386L10 0Z" fill={`url(#paint0_linear_${_uuid})`} />
      <defs>
        <linearGradient id={`paint0_linear_${_uuid}`} x1="31.3031" y1="51.2871" x2="2.8827" y2="49.4625" gradientUnits="userSpaceOnUse">
          <stop offset="0.0520833" stopColor="#FCDC43" />
          <stop offset="1" stopColor="#FFA700" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const useStyles = makeStyles((theme) => ({
  profileIcon: {
    width: "193px",
    height: "193px",
    [theme.breakpoints.down('xs')]: {
      width: "120px",
      height: "120px",
    }
  },
  rootCircle: {
    width: "215px",
    height: "215px",
    transform: 'rotate(-3.5deg)',
    [theme.breakpoints.down('xs')]: {
      width: "135px",
      height: "135px",
    }
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#000000',
    border: `3px solid #8B3FFA`,
    borderRadius: '2em',
    fontSize: '1em',
    margin: '0% 42%',
    [theme.breakpoints.down('xs')]: {
      margin: '0% 38%',
    },
    padding: '15px 5px',
  },
}))(Badge);

const ProfileCircle = (props) => {
  const classes = useStyles();
  const { completedProfile } = props
  return (
    <Grid item md={12} xs={12}>
      <Grid container justifyContent='center' spacing={1}>
        <StyledBadge
          badgeContent={
            completedProfile === undefined
              ? <CircularProgress style={{ height: '24px', width: '24px' }} color="primary" />
              : <Grid item style={{ display: "flex" }}>{IconProfile()}</Grid>
            }
          color="primary"
        >
          <Grid item xs={12}>
            <CircularProgressbarWithChildren
              variant="determinate"
              circleRatio={0.98}
              counterClockwise={true}
              strokeWidth={5}
              value={completedProfile}
              className={classes.rootCircle}
              styles={{
                rotation: 0.975,
                path: {
                  stroke: `url(#linearColors)`,
                  transformOrigin: 'center center',
                },
                trail: {
                  stroke: "none",
                }
              }}
            >
              <Grid
                container
                justifyContent='center'
                //className='photo-container'
              >

                <ProfilePic
                  className={clsx('photo', classes.profileIcon)}
                  simple={true}
                  defaultPic={props.profile_pic || '/images/avatars/placeholder.png'}
                />
                {/*<ProfilePicUsers  className={classes.profilePic} email={props.email}/>*/}
              </Grid>
            </CircularProgressbarWithChildren>
          </Grid>
        </StyledBadge>
      </Grid>
    </Grid>
  )
}

export default ProfileCircle
