import React from 'react'; import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material components
import { Box, Button, Icon, Typography, useMediaQuery } from '@material-ui/core';

// Styles
import useStyles from "../../../../../assets/styles/jss/pages/courses/whatIsAcademyCardStyle";

// Icons
import { ReactComponent as MisCursosFillSVG } from '../../../../../design-system/assets/images/icons/mis-cursos-fill.svg';

const IMAGE = {
    "WEB": "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/WHATISACADEMY/default_whatIsAcademy_talisis.jpg",
    "MOVIL": "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/WHATISACADEMY/movil_whatIsAcademy_talisis.jpg"
};

const getCompanyNameWithFormat = (name) => {
    const splitName = name.toLowerCase().split(" ");
    return splitName.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(" ");
};

const WhatIsAcademyCard = ({companyName, onClickReadMore}) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles({background: IMAGE[isResponsive ? "MOVIL" : "WEB"]});

    const companyNameWithFormat = getCompanyNameWithFormat(companyName);

    return (
        <Box className={classes.rootWhatIsAcademyCard}>
            <Box className={classes.containerIcon}>
                <Icon component={MisCursosFillSVG} className={clsx(classes.misCursosFillSVGIcon)}/>
            </Box>

            <Box className={classes.containerName}>
                <Typography variant={"h3"}>{`¿Qué es ${companyNameWithFormat} Academy?`}</Typography>
            </Box>
            <Box className={classes.containerBotton}>
                <Button variant='contained' color='primary' size='small' fullWidth onClick={onClickReadMore}>Leer más</Button>
            </Box>
        </Box>
    );
};

WhatIsAcademyCard.propTypes = {
    companyName: PropTypes.string.isRequired,
    onClickReadMore: PropTypes.func,
};

export default WhatIsAcademyCard;