import React from 'react'

import { Box, Button, Grid, Icon, Modal, Typography } from '@material-ui/core'
import { newCardModalStyles } from './newCardModalStyles';

const NewCardModal = (props) => {

    const { isModalOpen, modalCloseFunc, component, title } = props;

    const classes = newCardModalStyles();

    return (
        <Modal
            open={isModalOpen} onClose={modalCloseFunc}
            className={classes.modal}
            aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description"
        >
            <Box sx={{ maxWidth: "596px", backgroundColor: '#1C1F24', textAlign: 'center', paddingX: '20px', paddingY: '20px', borderRadius: '8px' }} className={classes.modalInside}>
                <Grid container direction="row">
                    <Grid item xs={12} className="mb-1">
                        <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid item>
                                <Typography variant='h4' className="text-gray-200">
                                    { title }
                                </Typography>
                            </Grid>
                            <Grid item style={{ cursor: "pointer" }} onClick={modalCloseFunc}>
                                <Icon className='ri-close-line font-size-24 text-dark-black-200' />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: '14px' }}>
                        { component }
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default NewCardModal
