import React/*, { useEffect }*/ from 'react';
import clsx from 'clsx';

// @material-ui/core
import { Box } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Icon } from '@material-ui/core';

// assets
import IconoSoporte from "../../../../../assets/images/icono-soporte.svg";

// styles
import useStyles from '../../../../../assets/styles/jss/pages/market/EnrollmentConfirmation';

const SupportCard = () => {
    const classes = useStyles();

    return (
        <Card className={clsx('bg-fill-stroke-400', classes.supportCard)}>
            <Box display="flex" flexDirection="column" pl="24px" pr="24px" pt="16px" pb="16px">
                <Typography variant="h5" style={{}}>¿Tienes dudas?</Typography>
            </Box>
            <Divider className='bg-fill-stroke-300' />
            <Grid container direction='column' className='p-3'>
                <Box display="flex">
                    <Box display="flex" flexDirection="column" justifyContent="space-between">
                        <Icon className='ri-customer-service-2-fill text-violet-300' style={{ marginRight: "17.33px", fontSize: "29.3px" }}></Icon>
                        <img style={{ width: "32px" }} alt="icono-soporte" src={IconoSoporte} />
                    </Box>
                    <Box display="flex" flexDirection="column" flexGrow="1">
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{ lineHeight: "18px" }}>Envíanos un correo a:</Typography>
                            <Typography variant="h5" style={{ lineHeight: "16px", marginTop: "4px" }}>support@soportetalisis.zendesk.com</Typography>
                        </Grid>
                        <Divider className="bg-fill-stroke-200" style={{ marginTop: "16px", marginBottom: "16px" }} />
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{ lineHeight: "18px", maxWidth: "297px" }}>O haz clic en el ícono de ayuda en la parte inferior derecha de la pantalla. </Typography>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Card>
    )
};

export default SupportCard;