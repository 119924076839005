import React from 'react'

// @material-core
import { Grid, Icon, Typography } from '@material-ui/core';


// local
import LogoSource from '../../../../design-system/components/LogoSource/LogoSource';
import { SOURCES } from '../../../../design-system/constants/sources';

const ProfileDetails = (props) => {

    const { 
        fullName,
        ou
    } = props;
  return (
    <Grid style={{textAlign: 'center'}} className="mt-3">
        <Typography className='mb-2text-gray-100 offset-margin-bottom-19' variant='h4'>{fullName}</Typography>
        <LogoSource sourceId={SOURCES[ou] || 7 } color={'white'} />
    </Grid>
  )
}

export default ProfileDetails;