import { makeStyles } from '@material-ui/core/styles';

const newLocal = 'flex';
export default makeStyles((theme) => ({
    rootPriceAndMembership: {
        borderTop: props => props.isProgram ? `0.5px solid ${theme.palette.colors.fillStroke[100]}` : `1px solid ${theme.palette.colors.fillStroke[200]}`,
        height: props => props.isListItemCard ? '40px' : '42px',
        width: '100%',
        padding: props => props.isListItemCard ? '3px 12px' : '12px 16px', 
        [theme.breakpoints.down('xs')]: {
            height: props => props.isListItemCard ? '40px' : '32px',
            padding: props => props.isListItemCard ? '3px 12px' : '8px 12px', 
        }
    },
    comingSoonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    comingSoonLabel: {
        color: theme.palette.colors.darkBlack[200],
    },
    freeCourseContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    freeCourseLabel: {
        color: theme.palette.colors.gray[400],
    },
    otherMembershipContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    otherMembershipLabelContainer: {
        width: '90%',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '1.7px'
    },
    otherMembershipLabel: {
        color: props => props.isProgram ? theme.palette.white : theme.palette.colors.darkBlack[200],
    },
    otherMembershipIconContainer: {
        width: '10%',
        display: 'flex',
        justifyContent: 'end',
        
    },
    membershipIcon: {
        color: theme.palette.colors.darkBlack[200],
    },
    priceWithDiscountContainer: {
        borderTop: '3px solid !important',
        borderImageSlice: 1,
        borderImageSource: 'linear-gradient(273.67deg, #FCDC43 15.67%, #FFA700 57.57%) !important',
    },
    priceContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap !important'
    },
    discountLabelContainer: {
        marginTop: '-5px',
    },
    discountLabel: {
        color: theme.palette.colors.error[300],
    },
    listNumberLabel: {
        textDecorationLine: 'line-through',
        color: theme.palette.colors.darkBlack[200],
    },
    numberLabel: {
        color: theme.palette.colors.orange[300],
        textAlign: 'end'
    },
    membershipStarIconContainer: {
        display: "flex",
    }
}));