import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { makeStyles, } from '@material-ui/core';

import AgregarCursos from './Courses/AgregarCursos/AgregarCursos';
import ActualizarProgramas from './Programs/ActualizarProgramas/ActualizarProgramas';

// @material-ui/core
import Box from '@material-ui/core/Box';

// shared components
import Tabs from '../../../shared/components/Tabs/Tabs'
import Toast from '../../../shared/components/Toast/Toast';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import { log } from '../../../shared/utils/console.js'

// core components
import Courses from './Courses/Courses';
import Programs from './Programs/Programs';

const useStyles = makeStyles((theme) => ({
    backgroundPOP:{
        width: "100%",
        height: "100%",
        background: "#4e4e4ebf",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: 99
    }
}));

const CourseManagement = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState(null);
    const [toastProps, setToastProps] = React.useState({ message: "" });
    const [popCourseState, setPopCourseState] = React.useState(false);
    const [popProgramState, setPopProgramState] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    const handleChange = (tab) => {
        if(tab == 0){
            handleChangeTabCourses();
        }else if(tab == 1){
            handleChangeTabPrograms();
        }
        setActiveTab(tab);
    };

    const handleChangeTabCourses = () => {
        history.push('/courses/management');
    };
  
    const handleChangeTabPrograms = () => {
        history.push('/courses/management?target=programas');
    };

    const handleClickOpen = () => {
        history.push("/course/new");
    };

    const handleClickAddCourses = () => {
        setPopCourseState(true);
    };
    const handleClickUpdatePrograms = () => {
        setPopProgramState(true);
    };
    const handleClickclosePOP = (value) => {
        if(value == "cancel"){
            setPopProgramState(false);
            log(value);
        }else if(value == "acept"){
            log(value);
            setRefresh(!refresh);
            setPopProgramState(false);
        }else{
            setPopCourseState(false);
        }
    };

    const getData = async () => {
        try {
          const queryParams = new URLSearchParams(window.location.search);
          const target = await queryParams.get('target');
          return target;
        } catch (e) {
            log(e.message);
        }
    };

    React.useEffect(() => {
        let isCanceled = true;
        getData().then((target)=>{
            if(isCanceled){
               if(target == "programas"){
                setActiveTab(1);
              }else{
                setActiveTab(0);
              }
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, []);

    const handleFinishedToast = async () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    };

    const tabsData = [
        {
            label: 'Cursos',
            content: <div><Courses setToastProps={setToastProps} handleClickOpen={handleClickOpen} handleClickAddCourses={handleClickAddCourses}/></div>
        },
        {
            label: 'Programas',
            content: <div><Programs refresh={refresh} setToastProps={setToastProps} handleClickUpdatePrograms={handleClickUpdatePrograms}/></div>
        },
        // {
        //     label: 'Instructores',
        //     content: <div><Instructors /></div>
        // },
    ];

    return (
        <Box mb={5}>
            <Breadcrumb items={[
                { label: 'Administración de Cursos' },
            ]} />
            {activeTab != null && <Tabs
                onChange={handleChange}
                activeTab={activeTab}
                tabs={tabsData.map(it => it.label)}
                
            >
                {tabsData[activeTab].content}
            </Tabs>}
            <Toast {...toastProps} onFinished={handleFinishedToast} />
            { popCourseState && <Box className={classes.backgroundPOP}><AgregarCursos handleClickclosePOP={handleClickclosePOP}/></Box> }
            { popProgramState && <Box className={classes.backgroundPOP}><ActualizarProgramas handleClickclosePOP={handleClickclosePOP}/></Box> }
        </Box>
    );
};

export default CourseManagement;