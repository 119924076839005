import React from 'react';

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ChevronRight, Group, Lock } from '@material-ui/icons';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { callCreateEventGraph  } from '../../../../services/GraphServiceNew';
import { loginRequest } from '../../../../config/authConfig';
import { useMsal } from "@azure/msal-react";
import { Button } from '@material-ui/core';


const FORM_NAME = 'contact_me';

const ContactMe = ({ contact_me, onChange }) => {
    const theme = useTheme();
    const { instance, accounts } = useMsal();
    const useStyles = makeStyles({
        grid: {
            borderTop: `1.5px solid ${theme.palette.colors.grey[700]}`,
        },
        card: {
            backgroundColor: theme.palette.colors.grey[400],
            padding: '1em',
        },
        titleContainer: {
            marginTop: '3px',
        },
        title: {
            color: theme.palette.colors.white,
            fontSize: '18px',
            fontWeight: 'normal',
        },
        label: {
            color: theme.palette.colors.fillStroke[100],
            fontWeight: 600,
            fontSize: '12px',
        },
        inputText: {
            color: theme.palette.colors.white,
            background: 'none',
            fontSize: '12px',
        },
        select: {
            '& .MuiSvgIcon-root': {
                color: theme.palette.colors.fillStroke[100],
            },
            height: '30px',
        },
        menuItem: {
            '&:hover': {
                backgroundColor: `${theme.palette.colors.fillStroke[100]} !important`,
            },
            '&.MuiListItem-root.Mui-selected': {
                backgroundColor: `${theme.palette.colors.grey[400]} !important`,
            },
            fontSize: '14px',
        },
        icon: {
            color: theme.palette.colors.fillStroke[100],
        },
        iconContainer: {
            textAlign: 'center',
        },
        blockIcon: {
            height: '8px',
            marginTop:'5px',
            width:'20px',
        },
        circularProgress: {
            color: theme.palette.colors.white,
        },
        saveButton: {
            backgroundColor: theme.palette.colors.orange[300],
            color: theme.palette.colors.white,
        },
        alertMessage: {
            backgroundColor: theme.palette.colors.grey[500],
            color: theme.palette.colors.white,
            fontFamily: "'Source Sans Pro', sans-serif !important",
            fontSize: '14px',
            marginLeft: '15px'
        },
    });

    const classes = useStyles();
    return (
        <Grid container spacing={3} className="mt-1">
            <Grid item xs={12}>
                <Grid container spacing={5} alignItems='center'>
                    <Grid item xs={4}>
                        <Grid container className='py-2'>
                            <Grid item xs={10} >
                                <Typography variant="subtitle2" className={classes.label}>
                                    Correo Electrónico
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container>
                                    <Tooltip title={<span>&#8856;</span>} placement="right-end">
                                        <Group fontSize='inherit' className={classes.icon} />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container className="Profile_FormControl">
                            <Grid item xs={11}>
                                <TextField
                                    id="email"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    value={contact_me.email || ''}
                                    onChange={(e) => onChange(e, FORM_NAME)}
                                    InputProps={{
                                        className: classes.inputText,
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title={'Campo no editable'} placement="right-end">
                                    <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                <Grid container spacing={5} alignItems='center'>
                    <Grid item xs={4}>
                        <Grid container className='py-2' alignItems='center'>
                            <Grid item xs={10} >
                                <Typography variant="subtitle2" className={classes.label}>
                                    Teléfono Celular
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select className={classes.select} value={contact_me.visibility.phone_number} disableUnderline
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    renderValue={(value) => value === 'public' ? <Group fontSize='inherit' /> : <Lock fontSize='inherit' />}
                                    onChange={e => {
                                        contact_me.visibility.phone_number = e.target.value;
                                        onChange({ target: { name: 'visibility', value: contact_me.visibility } }, FORM_NAME);
                                    }}>
                                    <MenuItem value="public" className={classes.menuItem}>Todos los usuarios</MenuItem>
                                    <MenuItem value="private" className={classes.menuItem}>Solo yo</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container onClick={() => document.getElementById('phone_number').focus()} className="Profile_FormControl">
                            <Grid item xs={11}>
                                <TextField
                                    id="phone_number"
                                    name="phone_number"
                                    fullWidth
                                    value={contact_me.phone_number || ''}
                                    onChange={(e) => onChange(e, FORM_NAME)}
                                    InputProps={{
                                        className: classes.inputText,
                                        disableUnderline: true,
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Editar" placement="right-end">
                                    <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                contact_me.toastProps.show && contact_me.toastProps.type === 'contact' &&
                <Alert id='cm_alert_message' variant="outlined" severity={contact_me.toastProps.ok === 'true' ? 'success' : 'error'} className={`Profile_AlertMessage ${classes.alertMessage}`}>
                    {contact_me.toastProps.message}.
                </Alert>
            }
        </Grid>
    )
};


export default ContactMe;
