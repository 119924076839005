import React from "react";
import PropTypes from 'prop-types';

// @material-ui
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';

// styles
import useStyles from '../../../../assets/styles/jss/pages/checkout/ProductAssignment.js';

// ds
import LogoSource from '../../../../design-system/components/LogoSource/LogoSource';
import { LEVEL_NAME } from '../../../../design-system/constants/levels';

const CourseCard = ({ product }) => {
    const propsStyles = { image_url: product?.product_image_url };
    const classes = useStyles(propsStyles);
    return (
        <Card variant="outlined" className={classes.courseCard}>
            <Box className={classes.courseCardMedia} >
                <LogoSource color='white' sourceId={product?.source_id} disabledAutoRes responsive={false} className={classes.logoSource} />
            </Box>
            <Box className={classes.courseCardContent}>
                <Typography variant='overline' className='semi-bold text-blue-300'>
                    {LEVEL_NAME[product?.level_id]?.toUpperCase()}
                </Typography>
                <Typography variant='body1' className="semi-bold mt-1" >{product?.product_name?.substring(0, 45)}</Typography>
            </Box>
        </Card>
    )
}

CourseCard.defaultProps = {
    courseInfo: {
        product_image_url: ''
    }
}
CourseCard.propTypes = {
    courseInfo: PropTypes.shape({
        product_image_url: PropTypes.string.isRequired,
        source_id: PropTypes.number.isRequired,
        level_id: PropTypes.string.isRequired,
        modality: PropTypes.string.isRequired,
        card_description: PropTypes.string.isRequired
    }),
}


export default CourseCard;