import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// redux
import { connect } from "react-redux"

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

// shared components
import Loader from '../../../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../../../shared/components/Breadcrumb/Breadcrumb';
import InputFile from '../../../../../shared/components/Form/InputFile';
import Toast from '../../../../../shared/components/Toast/Toast';
import { log } from '../../../../../shared/utils/console.js'

// services
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';

// import * as marketService from '../../../../../services/MarketPlaceService';

//SDK
import { GlobalService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const InstructorEdit = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({});
    const [errors, setErrors] = React.useState({
        full_name: '',
        avatar_url: '',
        image: '',
    });
    const [toastProps, setToastProps] = React.useState({
        message: "",
        open: false
    });
    const [breadcrumbItems, setBreadcrumbItems] = React.useState([
        { to: '/courses/management#instructors', label: 'Administración de Cursos' },
        { label: 'Editar instructor', title: '' }
    ]);
    const { user } = props;
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const getData = async () => {
        try {
            setLoading(true);

            const instructor = await marketService.getInstructor(id);
            if (instructor.file_id) {
                const file = await GlobalServiceSDK.getFile(instructor.file_id);
                if (file) {
                    instructor.image = {
                        name: file.file_name
                    };
                }
            }

            setForm({ ...instructor });
            setLoading(false);
            setBreadcrumbItems(prev => {
                return [
                    { ...prev[0] },
                    { ...prev[1], title: instructor.full_name }
                ];
            });
        } catch (e) {
            setLoading(false);
            log(e.message);
        }
    };

    React.useEffect(() => {
        if (!id) {
            history.push("/home");
        } else {
            getData();
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (value) {
            const _errors = { ...errors };
            delete _errors[name];

            setErrors(_errors);
        }

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const handleFinishedToast = () => {
        setToastProps(prev => { return { ...prev, open: false } });
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['full_name'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            }
        }

        setErrors(_errors);

        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleSubmit = async () => {
        if (validate()) {
            const file = form.image;
            const body = {
                ...form,
                person_id: user.person_id
            };
            delete body.image;

            try {
                setLoading(true);

                const response = await marketService.putInstructor(id, body);

                if (file instanceof File) {
                    await GlobalServiceSDK.deleteFile(form.file_id);
                    await GlobalServiceSDK.uploadFile(file, 'market-place/instructors', 'instructors', id);
                }

                setForm({ ...response });
                setToastProps({ open: true, message: "El instructor ha sido actualizado exitosamente." });
                setLoading(false);
            } catch (e) {
                setToastProps({ severity: "error", open: true, message: "Ocurrió un error al actualizar el instructor. Por favor intenta de nuevo." });
                setLoading(false);
                log(e.message);
            }
        }
    };

    return (
        <Box>
            <Breadcrumb items={breadcrumbItems} />
            <Loader loading={loading}>
                <React.Fragment>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="full_name"
                                        label="Nombre completo"
                                        required
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.full_name || ""}
                                        error={Boolean(errors.full_name)}
                                        helperText={errors.full_name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputFile
                                        accept="image/*"
                                        placeholder="Agrega una imagen"
                                        btnText="Imagen"
                                        name="image"
                                        value={form.image}
                                        onChange={handleChange} />
                                </Grid>
                            </Grid>
                            <Box className="mt-4">
                                <Button color="primary" variant="contained" onClick={handleSubmit}>Guardar cambios</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </React.Fragment>
            </Loader>
            <Toast {...toastProps} onFinished={handleFinishedToast} duration={3000} />
        </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(InstructorEdit);