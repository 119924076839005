import React from 'react'

import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles';

// Styles 
import useStyles from '../../../../../assets/styles/jss/pages/account/profile/dashboardCardStyle';
import clsx from 'clsx';

const DashboardCard = (props) => {
    const classes = useStyles();

    const theme = useTheme();

    const { cardTitle, caption, btnFunction, btnText, icon, information, cardMaxHeight, cardHeight } = props;

    return (
        <Grid item md={6}>
            <Card style={{ background: "#1D1E23", border: "1px solid #2C3236", maxHeight: cardMaxHeight, height: cardHeight}}>
                <CardContent className={classes.cardContent}>
                    <Grid container spacing={1} justifyContent='space-between' alignItems='center' className={clsx(classes.titleContent)}>
                        <Grid item xs={6}>
                            <Typography variant="h5" color='primary' align='left'>
                                { cardTitle }
                            </Typography>
                            <Typography align='left' className={clsx('variant-xsmall', 'mt-1')}>
                                { caption }
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent='flex-end' alignItems='center'>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    // style={{ border: `1px solid ${theme.palette.colors.magenta[500]}` }}
                                    onClick={btnFunction}
                                    size="medium"                                    
                                >
                                    <Typography variant='body2'>{ btnText }</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={clsx(classes.bodyContent)} alignItems='center'>
                        <Grid item xs={4}>
                            <Grid container justifyContent='flex-start'>
                                { icon }
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container justifyContent='flex-end' wrap="wrap">
                                { information }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default DashboardCard
