export const PENDING_STATUS = 1;
export const REVIEW_STATUS = 2;
export const REJECTED_STATUS = 3;
export const PENDING_PHYSICAL_SHIPMENT_STATUS = 4;
export const PHYSICAL_SHIPMENT_STATUS = 5;
export const PHYSICAL_REJECTED_STATUS = 6;
export const APPROVED_STATUS = 7;
export const SEND_TO_REVIEW_PHYSICALLY_STATUS = 8;
export const PENDING_DELIVER_TO_SCHOOL_STATUS = 9;

export const MSSG_BY_STATUS = {
    [REVIEW_STATUS]:{
      image: 'themeSearching',
      title: 'Nada por aquí todavia',
      subtitle: 'El alumno aún no cuenta con documentos en revisión.',
    },
    [APPROVED_STATUS]:{
      image: 'themeApproved',
      title: 'Sin documentos aprobados',
      subtitle: 'Recuerda aprobar o rechazar los documentos que estén en revisión.',
    },
    [PENDING_STATUS]:{
      image: 'themeCheck',
      title: 'Sin documentos pendientes',
      subtitle: 'El alumno ha enviado todos sus documentos.',
    },
  }