import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material components
import { Card } from '@material-ui/core';

// Styles
import useStyles from '../../../assets/styles/components/cards/components/baseStyle';

const Base = ({children, onClick, className, hasClickEvent}) => {
    const classes = useStyles({hasClickEvent});

    return (
        <Card className={clsx(classes.rootBase, className)} onClick={() => {if(!!onClick) onClick()}}>
            {children}
        </Card>    
    );
};

Base.defaultProps = {
    hasClickEvent: false,
};

Base.propTypes = {
    redirectTo: PropTypes.string,
    onClick: PropTypes.func,
    hasClickEvent: PropTypes.bool,
};

export default Base;
