import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    iconsX15: {
        color: theme.palette.primary.main,
        width: "15px",
        marginRight: "2px"
    },
    titles: {
        color: 'white',
        fontSize: "12px",
        fontWeight: "bold",
        marginBottom: "5px"
    },
    brands: {
        fontSize: "10px"
    },
    link: {
        textDecoration: 'none'
    },
    icons: {
        width: "108px",
        marginBottom: "140px",
        "@media (max-width: 700px)": {
            marginBottom: "40px",
        }
    },
    iconButton:
    {
        height: '40px',
        width: '40px',
        border: '1px solid gray',
        borderRadius: '50%'
    },
    mainContent: {
        width: "74%",
        maxWidth: "976px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "30px",
        paddingRight: "100px",
        paddingLeft: "100px",
        paddingBottom: "50px",
        background: theme.palette.colors.grey[400],
        "@media (max-width: 840px)": {
            width: "95%",
            marginBottom: "40px",
            paddingRight: "20px",
            paddingLeft: "20px",
        },
        "@media (max-width: 840px)": {
            width: "90%",
            marginBottom: "40px",
            paddingRight: "20px",
            paddingLeft: "20px",
        }
    },
    mainContentSuccess: {
        width: "74%",
        maxWidth: "976px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "30px",
        paddingRight: "100px",
        paddingLeft: "100px",
        paddingBottom: "50px",
        background: theme.palette.colors.fillStroke[500],
        "@media (max-width: 840px)": {
            width: "95%",
            marginBottom: "40px",
            paddingRight: "20px",
            paddingLeft: "20px",
        },
        "@media (max-width: 840px)": {
            width: "90%",
            marginBottom: "40px",
            paddingRight: "20px",
            paddingLeft: "20px",
        }
    },
    shareInf: {
        margin: "auto",
        width: "85%",
        border: "1px solid",
        marginTop: "30px",
        borderRadius: "30px",
        borderColor: "#9F6FFB",
        paddingTop: "10px",
        paddingBottom: "7px"
    },
    shareText: {
        marginLeft: "14%",
        float: "left"
    },
    shareIcons: {
        height: '20px',
        color: '#78787D',
    },
    confirmacionDivider: {
        backgroundColor: '#404150'
    },
    head: {
        height: "100px",
        background: theme.palette.colors.black,
        width: "74%",
        maxWidth: "976px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingRight: "100px",
        paddingLeft: "100px",
        "@media (max-width: 840px)": {
            width: "95%",
            paddingRight: "20px",
            paddingLeft: "20px",
        },
        "@media (max-width: 840px)": {
            width: "90%",
            paddingRight: "20px",
            paddingLeft: "20px",
        }
    },
    divisor_copy: {
        marginBottom: "23px",
        marginTop: "35px",
    },
    content_copy_footer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        paddingBottom: "30px",
        maxWidth: "1317px",
        marginLeft: "auto",
        marginRight: "auto",
        "@media (max-width: 730px)": {
            flexDirection: "column-reverse",
            alignItems: "center",
            justifyContent: "center"
        }
    },
    title_copy_footer: {
        fontSize: "11.5px",
        fontFamily: 'Inter !important',
        lineHeight: "16px",
        "&:nth-child(1)": {
            marginRight: "57px",
            "@media (max-width: 530px)": {
                marginRight: "20px",
            }
        },
        "&:nth-child(2)": {
            marginRight: "289px",
            "@media (max-width: 1170px)": {
                marginRight: "20%",
            },
            "@media (max-width: 730px)": {
                marginRight: "0px",
                marginBottom: "10px"
            }
        }
    },
    titles_copy_footer: {
        display: "flex",
        flexDirection: "row",
        "@media (max-width: 730px)": {
            alignItems: "flex-start",
            justifyContent: "center",
            width: "inherit"
        }
    },
    titleHead: {
        height: "100px",
        width: "74%",
        maxWidth: "1176px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingRight: "100px",
        paddingLeft: "100px",
        "@media (max-width: 840px)": {
            width: "95%",
            paddingRight: "20px",
            paddingLeft: "20px",
        },
        "@media (max-width: 840px)": {
            width: "90%",
            paddingRight: "20px",
            paddingLeft: "20px",
        }
    },
    courseTitle: {
        maxWidth: "648px"
    },
    includeVat: {
        alignSelf: "center"
    },
    buttonPrint: {
        borderRadius: "40px",
        background: "#9F6FFB",
        '&:hover': {
            background: "#9F6FFB",
        }
    },
    responsiveCard: {
        [theme.breakpoints.up('lg')]: {
            paddingLeft: "100px"
        }
    },
    actionCard: {
        height: "176px",
        [theme.breakpoints.up('lg')]: {
            width: "424px"
        }
    },
    supportCard: {
        [theme.breakpoints.up('lg')]: {
            width: "424px"
        }
    },
    actionCardImg: {
        height: "176px",
        width: "148px"
    },
    buttonCourses: {
        width: "114px !important",
        height: "40px !important",
        '&.MuiButton-text': {
            padding: '8px 10px !important'
        },
        '&:hover': {
            background: 'none',
        }
    },
    boxNextPaymentDate: {
        borderColor: "#9F6FFB",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        backgroundColor: "#1C1F24",
    },
    pending: {
        padding: "4px 16px",
        borderRadius: "16px",
        border: '1px solid ' + theme.palette.colors.warning[300],
        alignSelf: "flex-end",
    },
    helpIcon: {
        fontSize: "16.6px",
        alignSelf: "center",
        marginLeft: "9px",
        '&:hover': {
            cursor: "pointer"
        },
        zIndex: '10000'
    },
    helpIconPopoverContent: {
        padding: "11px 13px",
        width: "220px",
        borderRadius: "4px",
        borderWidth: "1px",
        borderColor: theme.palette.colors.warning[300],
        borderStyle: "solid",
        backgroundColor: theme.palette.colors.grey[400],
        marginLeft: "8px"
    },
}));