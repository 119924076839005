import React, { useState } from 'react';
import { useSelector } from 'react-redux';
//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// style
import useStyles from '../../../assets/styles/jss/pages/consultancies/applicant/ModifyConsultanciesStyle';
import { TextField, IconButton, Icon, Hidden } from '@material-ui/core';
import {Clear} from '@material-ui/icons';
// icons
import palette from '../../../design-system/theme/palette';
//SDK
import { ConsultanciesService,AdminNotifService } from '@sdk-point/talisis'; 
import { getSessionCookie } from '../../../shared/utils/Sessions';
import ProfilePicUsers from '../../../shared/components/ProfilePicUsers/ProfilePicUsers';
import { getLogoCard } from '../../../constants/logosImages';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import {MEMBERSHIPS} from '../../../shared/constants/memberships'


const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const AdminNotifServiceSDK = new AdminNotifService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const ModifyConsultancies = (props) => {
    const classes = useStyles();
    const [Description, setDescription] = useState('');
    const [CancelarAsesoria, setCancelarAsesoria] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const { user } = useSelector((state) => state.userReducer);
    
    const handleClickModify = async () => {
        props.setModifyingOn(true)
        props.setModifyConsultancy(false);
    };

    const handleClickSalir = async () => {
        props.setModifyConsultancy(false);
    };

    const handleClickCancelar = async () => {
        setLoading(true);
        const session = getSessionCookie()
        let body = {
            id_asesoria : props.idConsultancy,
            motivo: 2,
            idTeamsAsesoria: props.idTeamsAsesoria
        }

        const body2 = {
            isCritical: 1,
            title: 'Asesoría cancelada',
            message: 'Ha sido cancelada por el solicitante: ' + user.userName + ' con el correo: ' + user.email,
            notifType: 'Procedures',
            companies: null,
            campus: null,
            groups: null,
            persons: [session.person_id, props.personIdAsesor],
            actionRequired: 1,
            url: '/asesorias',
        };
        await ConsultanciesServiceSDK.postModifyConsultancy(body)
        await AdminNotifServiceSDK.createNotification(body2);
        props.findConsultancies()
        setLoading(false)
        setCancelarAsesoria(true);
    };

    const handleFinalizarSolicitud = () => {
        props.setModifyConsultancy(false)
        props.findConsultancies()
    }

    return (
        <Box>
            {
                !CancelarAsesoria ?
                <Box className={`${classes.boxRoot}`}>
                    <LoaderContent loading={loading}>
                        <Box className={classes.boxTittle}>
                            <Box className={classes.boxPictureName}>
                                <ProfilePicUsers email={props.emailAsesor} className={classes.profilePic}/>
                                <Typography variant='body1' className='semi-bold'>{props.nombreAsesor}</Typography>
                            </Box>
                            <Hidden smDown>
                                <Box className={classes.closeWindow}>                    
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={() => {handleClickSalir()}}
                                    >
                                        <Icon className="ri-close-fill" style={{color: palette.colors.fillStroke[100]}}/>
                                    </IconButton>
                                </Box>
                            </Hidden>
                        </Box>

                        <Box className={classes.boxDataTimes}>
                            <Box className={classes.boxDataTimesSecond}>
                                <Icon className={"ri-calendar-line font-size-16 text-electric-blue-200"} />
                                <Typography variant='body1' className='semi-bold'>{(props?.dateConvert).replace(/['"]+/g, '')}</Typography>
                            </Box>
                            <Box className={classes.boxDataTimesSecond}>
                                <Icon className={"ri-time-line font-size-16 text-electric-blue-200"}/>
                                <Typography variant='body1' className='semi-bold'>{(props?.ScheduleSelected).replace(/['"]+/g, '')}</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.boxUbicacion}>
                            <img alt="logo" className={classes.logoStyle} src={ getLogoCard(props.ouConsultancy) }/>
                            <Icon className={"ri-map-pin-2-line font-size-16 text-fill-stroke-100"} />
                            <Typography variant='caption'>{props.modalidad}</Typography>
                            <Icon className={"ri-book-2-line font-size-16 text-fill-stroke-100"} />
                            <Typography variant='caption'>{props.area_conocimiento}</Typography>
                        </Box>
                        
                        <Box className={classes.boxDataDescription}>
                            <Typography style={{marginBottom: '8px'}} variant='body2'>Descripción de dudas a resolver</Typography>
                            <TextField
                            name='name'
                            value={Description != '' ? Description : props.descripcion}
                            multiline
                            fullWidth
                            minRows={10}
                            maxRows={10}
                            placeholder='No hay descripción sobre la asesoria para mostrar.'
                            InputProps={{ classes: {input: classes['input']} ,
                            disableUnderline: true,
                            readOnly: true,
                            className: classes.textDescription, 
                            }} 
                            />
                        </Box>
                        
                        <Button 
                        color="primary"
                        label="Cancelar asesoría"
                        onClick={() => {handleClickCancelar()}}
                        size="medium"
                        className='mt-4'
                        >
                            <Clear style={{fontSize: '17px'}}/>
                            Cancelar asesoría
                        </Button>
                        
                        <Box className={classes.boxSalir}>
                            <Button 
                            variant='contained'
                            label="Salir"
                            className={classes.buttonSalir}
                            onClick={() => {handleClickSalir()}}
                            >
                                <Hidden smUp>
                                    <Typography variant='body2' className='semi-bold'>Atrás</Typography>
                                </Hidden>
                                <Hidden smDown>
                                    <Typography variant='body2' className='semi-bold'>Salir</Typography>
                                </Hidden>
                            </Button>
                            <Button 
                            variant='contained'
                            color="primary"
                            label="Modificar"
                            className={classes.buttonModificar}
                            onClick={() => {handleClickModify()}}
                            >
                                <Hidden smUp>
                                    <Typography variant='body2' className='semi-bold'>Modificar asesoría</Typography>
                                </Hidden>
                                <Hidden smDown>
                                    <Typography variant='body2' className='semi-bold'>Modificar</Typography>
                                </Hidden>
                            </Button>
                        </Box>
                    </LoaderContent>
                </Box>
                :
                <Box className={classes.boxFinalizar}>
                <Box className={classes.boxControlFinalizar}>
                    <Box className={classes.boxTextFinalizar}>
                        <Typography style={{marginBottom: '8px'}} variant='h3'>Asesoría cancelada</Typography>
                        <Typography variant='caption'>Ha sido cancelada tu asesoría con éxito, notificaremos al asesor para que esté enterado.</Typography>
                        <Box className={classes.boxCreditosGen}>  
                            <Typography variant="body1">
                            Tus créditos actualmente: 
                            </Typography> 
                            <Box className={classes.boxCreditos}>
                                {
                                    (user.membership_id === MEMBERSHIPS.PREMIUM) ?
                                    <AllInclusiveIcon style={{width:'20px', height:'20px'}} />
                                    :
                                    <Typography variant={'body2'} className={'semi-bold'}>{props.creditosAsesorias}</Typography>
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.boxButton}>
                        <Button 
                        variant='contained'
                        color="primary"
                        label="Primario"
                        className={classes.buttonFinalizar}
                        onClick={() => {handleFinalizarSolicitud()}}
                        >
                            <Typography variant='body2' className='semi-bold'>Finalizar</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            }
        </Box>
    );
};

export default ModifyConsultancies;