import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import CurrencyFormat from 'react-currency-format';
import objectIsEmpty from "../../../shared/utils/objectIsEmpty";

// Redux
import { connect, useSelector } from 'react-redux';

// material
import { Box, Card, CardContent, Grid, Select, TextField, Tooltip, Typography } from '@material-ui/core';

// style 
import useStyles from '../../../assets/styles/jss/pages/account/statement/statementStyle';

// shared component
import Table from '../../../shared/components/Table/Table';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import EmptyDataMessage from '../../../shared/components/EmptyDataMessage/EmptyDataMessage';

// component
import Filters from './Filters/Filters';

// svg
import womanSearchFileSVG from '../../../assets/images/woman_search_file.svg';

//SDK
import { BannerService } from '@sdk-point/talisis';

const BannerServiceSDK = new BannerService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const Statement = ({user}) => {
    const classes = useStyles();
    const controller = useRef(new AbortController()); 
    const { student_id } = user;

    const [openLoader, setOpenLoader] = useState(false);
    const [toastProps, setToastProps] = useState({ message: "" });
    const [matricula, setMatricula] = useState("");
    const [userData, setUserData] = useState([{
        ParametrosReporte: {},
        EstadoCuenta: [],
        PorAplicar: [],
        Mensajes: [],
        ResumenReporte: {}
    }]);

    const [dates, setDates] = useState({
        fechaIni: moment().subtract(1, "years"),
        fechaFin: moment()
    });
    const [ou, setOu] = useState(['']);
    const [ouList, setOuList] = useState([]);
    const [textFilter, setTextFilter] = useState('');
    const [data, setData] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [balance, setBalance] = useState('0');
    const [previousBalance, setPreviousBalance] = useState('0');

    const getOuList = async () => {
        setOuList(userData.filter(data => !!(data[0]?.EstadoCuenta?.length)).map(data => ({id: data[0].ParametrosReporte.VPDI, label: data[0].ParametrosReporte.VPDI})));
    };

    const getOu = async () => {
        if (!ouList.length)
            setOu(['']);

        if(!!ouList.length) {
            !ouList.find(itemOu => itemOu.id === ou[0]) ? setOu([ouList[0].id]) : setOu([...ou]);
        }
    }

    const getData = async () => {
        setData(userData.filter(data => data[0]?.ParametrosReporte?.VPDI === ou[0]));
    };

    const getDataTable = async () => {
        let tempDataTable = [];
        if (!!data?.length && !!data[0][0].EstadoCuenta.length) {
            tempDataTable = data[0][0].EstadoCuenta.filter(item => !textFilter.trim().length ||  (item.DescConcepto.toLowerCase().includes(textFilter.trim().toLowerCase()) || item.Concepto.toLowerCase().includes(textFilter.trim().toLowerCase()))).map((item, index) => ({
                id: index.toString(),
                colFecPago: item.Fecha,
                colClave: item.Concepto,
                colConc: item.DescConcepto,
                colCargo: item.Tipo === 'CARGO' ? item.Monto : '',
                colPago: item.Tipo === 'PAGO' ? item.Monto : '',
                colSaldo: item.Saldo
            }));
        }

        setDataTable(tempDataTable);
    };

    const getInfoBalance = async () => {
        if (!!data?.length && !objectIsEmpty(data[0][0].ResumenReporte)) {
            setPreviousBalance(data[0][0].ResumenReporte.SaldoInicial);
            setBalance(data[0][0].ResumenReporte.SaldoFinal);
        } else {
            setPreviousBalance('0');
            setBalance('0');
        }
    };

    const chargeComponent = (row, value, index) => {
        return (
            <Box display={'flex'} justifyContent={'center'}><Typography variant="body2" className={`text-error-300 semi-bold`}><CurrencyFormat value={row['colCargo']} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography></Box>
        );
    };

    const payComponent = (row, value, index) => {
        return (
            <Box display={'flex'} justifyContent={'center'}><Typography variant="body2" className={`text-success-400 semi-bold`}><CurrencyFormat value={row['colPago']} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography></Box>
        );
    };

    const balanceComponent = (row, value, index) => {
        return (
            <Box display={'flex'} justifyContent={'center'}><Typography variant="body2" className={`text-warning-400 semi-bold`}><CurrencyFormat value={row['colSaldo']} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography></Box>
        );
    };
    
    const columnsData = [
        {
            name: 'colFecPago',
            align: 'center',
            minWidth: 0,
            label: 'Fecha de pago',
        },
        {
            name: 'colClave',
            align: 'center',
            minWidth: 0,
            label: 'Clave',
        },
        {
            name: 'colConc',
            align: 'left',
            minWidth: 0,
            label: 'Concepto',
        },
        {
            name: 'colCargo',
            align: 'center',
            minWidth: 0,
            label: 'Cargos',
            format: chargeComponent
        },
        {
            name: 'colPago',
            align: 'center',
            minWidth: 0,
            label: 'Pago',
            format: payComponent
        },
        {
            name: 'colSaldo',
            align: 'center',
            minWidth: 0,
            label: 'Saldo',
            format: balanceComponent
        },
    ];

    const handleChangeDate = (prop, value) => {
        setDates({ ...dates, [prop]: value });
    };

    const handleChangeTextFilter = (event) => {
        setTextFilter(event.target.value);
    };

    const handleClick = async () => {
        const params = ({
            fechaIni: dates.fechaIni ? dates.fechaIni.format("DD/MM/YYYY") : "",
            fechaFin: dates.fechaFin ? dates.fechaFin.format("DD/MM/YYYY") : ""
        });

        setOpenLoader(true);
        
        try {
            if( matricula === null ) return setOpenLoader(false);

            await BannerServiceSDK.getPaymentHistoryMultiOU(user, params, controller.current).then(response => {
                setUserData(response);
                setOpenLoader(false);
            });

        } catch (e) {
            setOpenLoader(false);
            setToastProps({ severity: "error", open: true, message: "Ops! Ocurrió un error al consultar el historial de pagos." });
        }
    };

    
    const handleFinishedToast = () => {
        setToastProps({ ...toastProps, open: false });
    };

    const handleOuChange = (event) => {
        setOu([event.target.value]);
    };

    
    useEffect(() => {
        setMatricula(student_id);
        return () => {
            if (controller) {
                controller.current.abort();
            }
        };
    }, []);

    useEffect(() => {
        handleClick();
    }, [dates]);    

    useEffect(() => {
        getOuList();
    }, [userData]);

    useEffect(() => {
        getOu();
    }, [ouList]);

    useEffect(() => {
        getData();
    }, [ou]);
    
    useEffect(() => {
        getDataTable();
        getInfoBalance();
    }, [data]);

    useEffect(() => {
        getDataTable();
    }, [textFilter]);
    
    
    return (
        <LoaderContent loading={openLoader}>
            <Box className={"offset-margin-buttom-26"}>
                <Typography variant="h4" className='text-violet-300'>
                    Estado de cuenta
                </Typography>
                <Typography variant="button">
                    Consulta tus pagos y cargos realizados con base alguna fecha.
                </Typography>
            </Box>
            <Card className={`offset-margin-top-21`}>
                <CardContent>
                    <Box display={'flex'} alignItems='center'>
                        <i className="ri-error-warning-line font-size-16 text-warning-300 offset-margin-right-12"></i>
                        <Typography variant={'caption'}>Los cargos realizados en efectivo o transferencia como método de pago, aparecerán 48 hrs. posteriores a la fecha del pago en el historial de tu cuenta.</Typography>
                    </Box>
                    <Box className='offset-margin-top-26'>
                        <Filters onOuChange={handleOuChange} ouList={ouList} ouValue={ou[0]} onChangeTextFilter={handleChangeTextFilter} onChangeDate={handleChangeDate} dates={dates}/>
                    </Box>
                    <Box>
                        <hr className={classes.separatorLine}/>
                    </Box>
                    <Box className={'offset-margin-top-18'}>
                        <Grid container spacing={3} direction='row'>
                            <Grid item xs={6}>
                                <Grid container spacing={3} direction='row'>
                                    <Grid item xs={6}>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Typography variant='h5' className='text-gray-100 offset-margin-right-12'>Saldo anterior</Typography>
                                            <Tooltip title={<Typography variant='button' className={'text-dark-black-100'}>Muestra la cantidad de saldo que debías en el periodo anterior.</Typography>} arrow placement="right" classes={{tooltip: classes.tooltip, arrow: classes.arrow}}>
                                                <i className="ri-error-warning-line font-size-16 text-blue-300"></i>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='subtitle2' className='text-dark-black-200'><CurrencyFormat value={previousBalance} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /> MXN</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Typography variant='h5' className='text-gray-100 offset-margin-right-12'>Saldo total</Typography>
                                            <Tooltip title={<Typography variant='button' className={'text-dark-black-100'}>Este saldo incluye los cargos realizados durante el periodo seleccionado.</Typography>} arrow placement="right" classes={{tooltip: classes.tooltip, arrow: classes.arrow}}>
                                                <i className="ri-error-warning-line font-size-16 text-blue-300"></i>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h5' className='text-violet-300'><CurrencyFormat value={balance} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /> MXN</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                            <Grid container spacing={3} direction='row'>
                                    <Grid item xs={8}>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Typography variant="caption" className="caption-small text-gray-500">{'Matrícula'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" className="semi-bold text-gray-100">{matricula}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={`offset-margin-top-28`}>
                        {
                            !!dataTable.length ? <Table columns={columnsData} rows={dataTable}/> : <EmptyDataMessage image={womanSearchFileSVG} titleMessage={'Parece que aún no contamos con esta información'} message={'Aún no presentas movimientos relacionados en tu cuenta de estudiante con ese término o no es existente. Si crees es un error, acude a ventanilla.'}/>
                        }
                    </Box>
                </CardContent>
            </Card>
        </LoaderContent>
    );
};


const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(Statement);