import React from 'react';

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// utils
import objectIsEmpty from '../../../../../shared/utils/objectIsEmpty'
import dateFormat from '../../../../../design-system/utils/dateFormat'
import sortArrayObjects from '../../../../../shared/utils/sortArrayObjects'
import {SIMPLE_DATE_FORMAT} from '../../../../../design-system/constants/date-formats'

// styles
import TimelineContainer from '../../../../../shared/components/Timeline/TimelineContainer';
import TimelineItem from '../../../../../shared/components/Timeline/TimelineItem';

// constants
import {REQUEST_STATUS_ID} from '../../../../../shared/constants/request-status'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// styles
import { useTheme } from '@material-ui/core/styles';
import useStyles from '../../../../../assets/styles/jss/pages/requests/RequestFlowStyle';
import { Divider } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OrderButton from '../components/OrderButton';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const RequestFlow = ({data, onClickShowDetail}) => {
    const {palette} = useTheme();
    const classes = useStyles();
    const [rows, setRows] = React.useState([])
    const [detail, setDetail] = React.useState({})
    const [steps, setSteps] = React.useState([])

    React.useEffect(()=>{
        if(!objectIsEmpty(data)){
            data.statusColor = <Typography style={{color: palette.colors[data.status_color]}}>{data.status}</Typography>;
            setRows([data])
            setDetail(data)
            setSteps([...data.workflow.map((it, index) => ({
                    ...it,
                    stepNumber: index + 1,
                    updatedAt: dateFormat(it.updated_at, SIMPLE_DATE_FORMAT)
                })
            )])
        }
    }, [data]);

    const WaitingFor = () => {
        if(objectIsEmpty(data) || data.status_id !== REQUEST_STATUS_ID.IN_PROGRESS){
            return null;
        }
    
        return <Box>
            <Typography className="font-variant-v6">Esperando:&nbsp;{data.workflow.filter(it => it.is_active)[0].title}</Typography>
            <Divider className={classes.divider}/>
            {/* <Typography component="span" color="textSecondary">{data.workflow.filter(it => it.is_active)[0].authorizers.filter(a => a.status_id === REQUEST_STATUS_ID.REVIEW && a.authorizer_type_id === 1).map(it => it.full_name).join()}</Typography> */}
        </Box>
    }

    const handleOrderSteps = (item) => setSteps(item);

    const TimelineItemContent = ({item, length}) => {
        const disabled = item.status_id === REQUEST_STATUS_ID.PENDING;

        return <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography className="font-variant-v10">{item.title}</Typography>
                    {
                        item.status_id === REQUEST_STATUS_ID.APPROVE 
                        ?
                        <Box display="flex" alignItems="center">
                            <CheckCircleIcon className={classes.checkIcon}/>
                            <Typography className="font-variant-v2">
                                {item.updatedAt}
                            </Typography>
                        </Box>
                        :
                        <Box display="flex" alignItems="center">
                            {item.status_id === REQUEST_STATUS_ID.REVIEW && <AccessTimeIcon className="font-size-12 mr-1"/>}
                            <Typography className="font-variant-v2">
                                {item.status}
                            </Typography>
                        </Box>
                    }
            </Box>
            <Box>
                <Typography className="font-weight-600 text" variant="body2" component="span" style={{minWidth: 70}}>
                    Responsable:&nbsp;
                    <Typography component="span" variant="body2" className="text">{item.authorizers.filter(a => a.authorizer_type_id === 1).map(it => it.full_name).join()}</Typography>
                </Typography>
            </Box>
            { (item.is_active || length === 1) &&
                <Button
                    onClick={() => onClickShowDetail(1)} 
                    className={classes.showDetailBttn} 
                    endIcon={<NavigateNextIcon fontSize="small" color="inherit"/>}>
                        Ver detalle
                </Button>
            }
        </Box>;
    }

    return (
        <Grid item xs={12}>
            <Card variant="contained" className="bg-bg-fill-stroke-400">
                <CardContent className="p-0">
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <WaitingFor />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" >
                                    {
                                        detail.steps && 
                                        <Grid container direction='row' >
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" alignItems="center" style={{height: '100%'}}>
                                                    <Typography className="font-variant-v7">Paso {detail.status_id === REQUEST_STATUS_ID.APPROVE ? detail.totalSteps : `${detail.activeStep} de ${detail.totalSteps}`}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" alignItems="center" justifyContent="end">
                                                        <OrderButton 
                                                            items={[
                                                                {id:'desc', label: 'Últimos primero'},
                                                                {id:'asc', label: 'Primeros'},
                                                            ]}
                                                            arrayToSort={steps}
                                                            onChangeOrder={handleOrderSteps}
                                                        />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                                <Box mt={3} className={classes.timeline}>
                                    <TimelineContainer align="left">
                                        {
                                            steps.map((item, index, {length}) => 
                                                <TimelineItem key={item.id}
                                                    dotContent={item.stepNumber} 
                                                    justDot={length - 1 === index}
                                                    disabled={item.status_id === REQUEST_STATUS_ID.PENDING}
                                                    variant={item.status_id !== REQUEST_STATUS_ID.APPROVE && 'outlined'}
                                                >
                                                    <TimelineItemContent item={item} length={length}/>
                                                </TimelineItem>
                                            )
                                        }
                                    </TimelineContainer>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>            
        </Grid>
    );
};

export default RequestFlow;