import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    icon:{
        color: theme.palette.colors.white,
        fontSize: '8px !important'
    },
    listItemIcon:{
        minWidth: 18
    },
    listItemText:{
        fontSize: 16,
        lineHeight: '16px',
        fontWeight: 400
    },
    listItem:{
        padding: '2px 4px'
    }
}));