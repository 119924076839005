import isPhoneNumber from "./isPhoneNumber";

export const isValidPhoneNumber = (phoneNumber) =>{

    if(!phoneNumber)
        return {
            message: "El campo está vacío, escribe un número de teléfono de 10 digitos para continuar",
            error: true
        }

    if(phoneNumber.length < 10)
        return {
            message: "El número de teléfono no puede ser menor a 10 digitos",
            error: true
        }

    if(!isPhoneNumber(phoneNumber))
        return {
            message: "No es un número de teléfono válido",
            error: true
        }

    return { message: "", error: false }
};
