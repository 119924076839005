import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
        ProfileAdviser: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flexShrink: '0',
            "@media (max-width: 700px)": {
                flexDirection:'column',
                marginBottom: '40px'
            }
        },
        cardProfile: {
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "311px",
            height: "auto",
            background: theme.palette.colors.fillStroke[400],
            border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
            borderRadius: "8px",
            [theme.breakpoints.down('xs')]:{
                width: '100%'
            }
        },
        leftComponent: {
            display: 'flex',
            flexShrink: '0',
            flexWrap: 'wrap',
            width: '30%',
            margin: 0,
            padding: 0,
            "@media (max-width: 700px)": {
                flexDirection:'column',
                marginBottom: '15px',
                width: '100%'
            }
        },
        rightComponent: {
            display: 'flex',
            flexShrink: '0',
            flexWrap: 'wrap',
            width: '70%',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
            [theme.breakpoints.down('xs')]:{
                width: '100%'
            }
        },
        cardFirstStyle:{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "32px 16px 16px",
            width: "311px",
            height: "393px",
            background: theme.palette.colors.darkBlack[500],
            borderRadius: "8px 8px 0px 0px",
            [theme.breakpoints.down('xs')]:{
                width: '100%'
            }
        },
        cardSecondStyle:{
            display: 'flex', 
            flexWrap: 'wrap',
            flexDirection: 'column',
            margin: 0,
            padding: 0,
            background: theme.palette.colors.fillStroke[400],
            [theme.breakpoints.down('xs')]:{
                width: '100%'
            }
        },    
        classNameTabs:{
            '& .MuiTabs-flexContainer':{
                borderBottom: "none",
              },
        },
        classNameText:{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'start',
            color: theme.palette.colors.darkBlack[200]
        },
        boxMateria: { 
            width: 'auto',
            maxWidth: '170px',
            borderRadius: '16px',
            display: 'flex',
            maxHeight: '28px',
            alignItems: 'center',
            flexWrap: 'wrap',
            padding: '6px 16px',
            backgroundColor: theme.palette.colors.fillStroke[300],
            border: '1px solid ' + theme.palette.colors.fillStroke[200],
        },
        boxMaterias: { 
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: '16px',
            alignItems: 'center',
            marginLeft: '16px',
            marginRight: '16px',
            padding: '0px',
            gap: '6px'
        },
        boxEducacion: {
            width: '721px', 
            height: 'auto', 
            display: 'flex', 
            gap:'16px', 
            boxSizing: 'border-box', 
            background: theme.palette.colors.fillStroke[400], 
            border: '0.5px solid ' + 
            theme.palette.colors.fillStroke[200], 
            borderRadius: '8px', 
            padding: '16px 16px 32px',
            flexDirection: 'column', 
            alignItems: 'flex-start',
            marginBottom: '16px',
            [theme.breakpoints.down('xs')]:{
                width: '100%'
            }
        },
        boxCalificacion: {
            width: '721px',
            display: 'flex', 
            gap:'16px', 
            boxSizing: 'border-box', 
            background: theme.palette.colors.fillStroke[400], 
            border: '0.5px solid ' + theme.palette.colors.fillStroke[200], 
            borderRadius: '8px', 
            padding: '16px 16px 32px',
            flexDirection: 'column', 
            alignItems: 'flex-start',
            [theme.breakpoints.down('xs')]:{
                width: '100%'
            }
        },
        boxContainerRender:{
            [theme.breakpoints.down('xs')]:{
                width: '100%'
            }
        },
        ClassNameHistory: {
            width: '721px', 
            height: 'auto', 
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '15px',
            alignItems: 'flex-start',
            padding: '16px 0px 0px',
            gap: '16px',
            background: theme.palette.colors.fillStroke[400], 
            border: '1px solid ' + theme.palette.colors.fillStroke[200], 
            borderRadius: '8px',
            [theme.breakpoints.down('xs')]:{
                width: '100%'
            }
        },
        classIconArrow: {
            color: theme.palette.colors.darkBlack[200]
        },
        tooltip: {
            backgroundColor: theme.palette.colors.blue[100],
            borderRadius: '8px',
            opacity: 0.9
        },
        tooltipText: {
            color: theme.palette.colors.fillStroke[500]
        },
        materiaStyle: {
            color: theme.palette.colors.gray[500],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        areasConocimientoStyle:{
            display:'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        }
}));