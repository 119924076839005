import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    cardListStyle: {
        display: 'flex',
        width: "100%",
        height: 96,
        flex: "none",
        gap: 16,
        border: props => props.disabled === 1 ? `1px solid ${theme.palette.colors.fillStroke[200]}` : `0.5px solid ${theme.palette.colors.fillStroke[100]}`,
        borderRadius: 8,
        [theme.breakpoints.down('xs')]:{
            height: 'fit-content',
            gap: 0
        }
    },
    imgContainer: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    img: {
      height: '100%',
      backgroundBlendMode: "multiply, normal, normal",
      borderRadius: "8px 0px 0px 8px",
      filter: (props) => props.disabled === 1 && "grayscale(1)",
      opacity: (props) => props.disabled === 1 && 0.5,
      [theme.breakpoints.down('xs')]: {
        objectFit: 'cover',
        width: 124,
        height: '100%', 
      },
    },
    titleColor:{
        color: props => props.disabled === 1 && theme.palette.colors.darkBlack[200]
    },
    labelDisabled:{
        '& .MuiChip-label':{
            color: props => props.disabled === 1 &&  `${theme.palette.colors.blue[500]} !important`
        }
    },
    infoStyle:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 16,
        [theme.breakpoints.down('xs')]:{
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            padding: '8px 12px',
            height: 'fit-content',
            gap: 8,
        }
    },
    chipIcon: {
        '& .MuiChip-icon':{
            color: `${theme.palette.colors.gray[200]} !important`,
            marginLeft: 0
        }
    },
    boxProximamente: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `0.5px solid ${theme.palette.colors.blue[300]}`,
        borderRadius: 25,
        width: 326,
        height: 32,
        [theme.breakpoints.down('md')]:{
            width: 158
        }
    },
    proximamenteStyle:{
        color: theme.palette.colors.blue[300],
        opacity: '1 !important',
    },
    statusAccordion:{
        textAlign: 'center', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]:{
            alignItems: 'center',
            width: '100%',
            marginLeft: 0,
            height: 55
        }
    },
    boxInfoCards:{
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        gap: 40
    },
    titleContainer:{
        display: 'flex',
        flexDirection: 'column',
        width: 260,
        gap: 8,
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            '-webkit-line-clamp': 2
        }
    },
    linearProgress:{
        height: 4,
        background: theme.palette.colors.fillStroke[200],
        borderRadius: 4,
        width: '100%',
        [theme.breakpoints.down('xs')]:{
            marginTop: 4
        }

    },
    companyInfo:{
        display:'flex',
        flexDirection: 'row',
        width: 250,
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]:{
            width: 178,
            flexDirection: 'column-reverse',
            gap: 8,
            alignItems: 'flex-start',
        }
    },
    chipImg: {
        position: 'absolute',
        marginTop: 10,
        left: 10,
        zIndex: 1,
    },
    finishedIconStyle:{
        color: theme.palette.colors.success[300],
    },
    finishedLabelStyle:{
        color: theme.palette.colors.success[300], 
        paddingLeft: '5px' 
    },
    buttonOrTagContainer:{
        width: '100%'
    },
    logoCardStyle:{
        maxWidth: props => props.logoCardSize, 
        opacity: props => props.disabled && 0.5
    }
}));