import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootBase: {
        width: '288px',
        minWidth: '288px',
        height: '315px',
        [theme.breakpoints.down('xs')]: {
            width: '224px',
            minWidth: '224px',
            height: '245px',
        }
    },
    rootCardMyClassesPrice: {
    },
    courseLabel: {
        color: theme.palette.colors.blue[300],
    },
    iconContainer: {
        display: 'none',
        marginTop: '16px',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    dataSection: {
        padding: '16px',
        height: '105px',
        [theme.breakpoints.down('xs')]: {
            padding: "12px",
            height: '82px',
        },
    },
    courseName: {
        marginTop: '8px',
        color: theme.palette.colors.grey[200],
        display: '-webkit-box !important',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal',
        overflow: 'hidden'
    },
    price: {
        color: theme.palette.colors.orange[300],
    },
    logo: {
        height: '14px',
    },
    title:{
        display:"flex" ,
        justifyContent:"space-between",
        mb:"11px",
        alignItems:"center"

    }
}));