import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    responsiveContainer:{
        position: 'relative',
        height: '100%',
        '& .container':{
            // paddingTop: 82,
            paddingTop: 0,
            height: '100%'
        }
    },
    root:{
        position: 'relative',
        height: 428,
        '& .title':{
            maxWidth: '100%',
            marginBottom: 32,
            '& h1':{
                textAlign: 'left'
            }
        },
        '& .content':{
            maxWidth:'100%',
            marginBottom:56,
            textAlign: 'left',
            '& > h6':{
                color: `#D7DEE5 !important`
            }
        }
    },
    butttonWhats:{
        backgroundColor: '#25D366',
        '& .MuiButton-startIcon':{
          '&.MuiButton-iconSizeMedium > *:first-child':{
              fontSize: '24px !important'
          },
        },
        "&:hover":{
          background: "#0B8769"
        }
      },
    bgImage:{
        backgroundImage: `url(/images/home/whatsapp-banner.png)`,
        // backgroundImage: `url(../src/design-system/assets/images/home/whatsapp-banner.png)`,
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        position: 'absolute',
        backgroundColor:'#075E54'
    },
    actions:{
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    [theme.breakpoints.down('md')]:{
        root:{
            position: 'relative',
            height: 428,
            '& .title':{
                maxWidth: '100%',
                marginBottom: 24,
                '& h1':{
                    textAlign: 'left'
                }
            },
            '& .content':{
                maxWidth:'100%',
                marginBottom:32,
                textAlign: 'left',
                '& > h6':{
                    color: `#D7DEE5 !important`
                }
            }
        },
        bgImage:{
            backgroundImage: `url(/images/home/whatsapp-banner-md.png)`,
            // backgroundImage: `url(../src/design-system/assets/images/home/whatsapp-banner-md.png)`,
            width: '100%',
            height: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            position: 'absolute',
            backgroundColor:'#075E54'
        },
        responsiveContainer:{
            '& .container':{
                // paddingTop: 46
                paddingTop: 0
            }
        },
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            height: 302,
            '& .title':{
                maxWidth: '100%',
                marginBottom: 24,
                '& h2':{
                    fontWeight: '700 !important',
                    fontSize: '24px !important',
                    lineHeight: '26px !important',
                    textAlign: 'center'
                }
            },
            '& .content':{
                maxWidth:'100%',
                marginBottom:32,
                textAlign: 'center',
                '& > h6':{
                    color: `#D7DEE5 !important`,
                    ...theme.typography.subHeading1
                }
            }
        },
        responsiveContainer:{
            '& .container':{
                paddingTop: 0,
                paddingLeft:'16px',
                paddingRight:'16px'
            }
        },
        bgImage:{
            backgroundImage: `url(/images/home/whatsapp-banner-xs.png)`,
            // backgroundImage: `url(../src/design-system/assets/images/home/whatsapp-banner-xs.png)`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            position: 'absolute',
            backgroundColor:'#075E54'
        },
        actions:{
            flexDirection: 'column',
            '& .MuiButton-root':{
                width: '100%'
            }
        },
    }
}));