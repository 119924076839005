import React from 'react'
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';

//@material-ui/core
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// style
import useStyles from '../../assets/styles/jss/pages/referrals/referralsStyle';

// design-system
import Loader from '../../design-system/components/Loader/LoaderContent';

// utils
import { getSessionCookie, userIsLogged } from '../../shared/utils/Sessions';
import MembershipPlans from '../Memberships/MembershipPlans/MembershipPlans';
import { getPreviousMembership } from '../../shared/utils/membershipUtils';
import SignupForm from '../../layout/components/Signup/SignupForm/SignupForm';

// constants
import { MEMBERSHIPS } from '../../shared/constants/memberships';

// services
import { MarketPlaceService, MembershipService, PRODUCT_TYPES, ProductService } from '@sdk-point/talisis';

// components
import MembershipPlanCard from '../Memberships/MembershipPlans/MembershipPlanCard/MembershipPlanCard';
import ReferralCouponCodeButton from './components/ReferralCouponCodeButton';
import ReferralBenefits from './components/ReferralBenefits';
import StudentFormModal from '../../pages/Market/Checkout/CheckoutSummary/StudentInfo/StudentFormModal/StudentFormModal';
import ReferralBenefitsData from './referralBenefitsData.js';

// shared components
import { log } from '../../shared/utils/console.js';
import * as paths from '../../routes/paths';
import { addProductsToCart } from '../../shared/utils/cartHelper.js';
import { applyCart, applyCartProducts } from '../../redux/actions/cartActions.js';
import { useDispatch, useSelector } from 'react-redux';

const PLAN_SEMESTRAL_MONTHS = 6;
const PLAN_ANUAL_SLUG = "ANU";
const COUPON_CODE = 'R3F3R1D0'
const DISCOUNT_PERCENTAGE = 20;

const Referrals = (props) => {
    const { match: { params: { referer_id } } } = props;
    const history = useHistory();
    const classes = useStyles();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [loading, setLoading] = React.useState(false);
    const [signupLoading, setSignupLoading] = React.useState(false);
    const [memberships, setMemberships] = React.useState([]);
    const [membershipPlansD, setMembershipPlansD] = React.useState([]);
    const [membershipSelectedId, setMembershipSelectedId] = React.useState(MEMBERSHIPS.PROFESSIONAL);
    const [feePlan, setFeePlan] = React.useState(PLAN_ANUAL_SLUG);
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)
    const productService = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const theme = useTheme();
    const { cart } = useSelector((state) => state.cartReducer);
    const dispatch = useDispatch();
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
    const session = getSessionCookie();

    const footerExtras = <Grid container direction='row' spacing={3}>
        <Grid item sm>
            <Typography variant='caption' className='text-dark-black-200'>
                Copia este cupón y pégalo en tu carrito de compra para activar el descuento.
            </Typography>
        </Grid>
        <Grid item xs>
            <ReferralCouponCodeButton classes={classes} couponCode={COUPON_CODE} isResponsive={isResponsive} />
        </Grid>
    </Grid>;

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const getMemberships = async () => {
        try {
            setLoading(true);
            const personMembership = await marketService.getPersonMembership(null);
            if (personMembership?.memberships?.length > 0) {
                const memberships = personMembership?.memberships.filter(m => m.is_active && m.level > 1);
                setMemberships(memberships);
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
        }
    };

    const getMembershipsPlans = async () => {
        try {
            setLoading(true);                                                         //months 6,12
            const responseGetMembershipsPlans = await membershipService.getMembreshipFees([6, 12]);
            setMembershipPlansD(responseGetMembershipsPlans);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            log(error);
        }
    }

    React.useEffect(() => {
        if (userIsLogged()) {
            history.replace("/");
        }
        getMemberships();
        getMembershipsPlans();
    }, []);

    const handleMembershipPlanClick = (value) => {
        setFeePlan(value);
    };

    const handleMembershipSelect = (value) => {
        setMembershipSelectedId(value);
        scrollToSection('registrarse');
    };

    const handleLoadingModal = (isLoading) => {
        setSignupLoading(isLoading);
    };

    const handleSignupSuccess = async (success, response, form) => {
        setLoading(true);
        log("usuario registrado exitosamente:", response);
        log("datos usuario:", form);

        const membership = memberships.filter(m => m.id === membershipSelectedId)[0];
        const membershipFee = membership?.fees?.find((value) => value.term_slug === feePlan);
        const membershipFeeId = membershipFee !== undefined && membershipFee !== null ? membershipFee.id : 0;

        await continueToCart({
            membershipId: membershipSelectedId,
            feeId: membershipFeeId,
            personId: response?.person_id
        });
        setLoading(false)
    };

    const continueToCart = async ({ programId, membershipId, feeId, personId, admissionId }) => {
        try {
            const result = await findMembershipAndFee(membershipId, feeId);
            const data = await productService.getProductByMembershipReference(result.fee.term_months, result.membership.id)
            let membershipProduct = {};
            if (data && data.length) {
                membershipProduct = data[0];
            }
            // Consultar la información del producto adicional (programa)
            let programProduct = null;
            if (programId) {
                const productData = await productService.getProductByReferenceId(PRODUCT_TYPES.PROGRAMA, programId);
                if (productData && productData.length) {
                    programProduct = productData[0];
                }
            }
            // Agregar primero la membresía
            const products = [{
                "product_id": membershipProduct?.id,
                "product_name": membershipProduct?.name,
                "product_image_url": membershipProduct?.image_url,
                "product_description": membershipProduct?.description,
                "product_type_id": membershipProduct?.product_type_id,
                "quantity": 1,
                "product_price_id": membershipProduct?.product_price_id,
                "unit_price": membershipProduct?.list_price,
                "discount": 0
            }];
            // Si es necesario, agregar el programa
            if (programProduct) {
                products.push({
                    "product_id": programProduct?.id,
                    "product_name": programProduct?.name,
                    "product_image_url": programProduct?.image_url,
                    "product_description": programProduct?.description,
                    "product_type_id": programProduct?.product_type_id,
                    "quantity": 1,
                    "product_price_id": programProduct?.product_price_id,
                    "unit_price": programProduct?.list_price,
                    "discount": 0
                });
            }
            // Agregar productos al carrito
            log("products: ", products);
            const response = await addProductsToCart({
                cart_id: cart?.id,
                products: [...products],
                person_id: personId,
                admission_id: admissionId
            });
            log("response cart add: ", response)
            log("response cart cart_details: ", response.cart_details)
            // Actualizar estado reducer del carrito
            dispatch(applyCart(response));
            dispatch(applyCartProducts(response.cart_details));
            // Redireccionar al carrito para ver el detalle.
            const redirectId = personId || session.person_id;
            history.push(`${paths.CART}?personPaymenToken=${redirectId}&isReferred=true`);
        } catch (e) {
            log("error", e);
        }
    }

    const findMembershipAndFee = async (membershipId, feeId) => {
        const data = await marketService.getMemberships()
        // Encuentra la membresía con el id especificado
        const membership = data.memberships.find(m => m.id === membershipId);
        if (!membership) {
            return null; // No se encontró la membresía
        }

        // Encuentra el fee con el id especificado dentro de la membresía encontrada
        const fee = membership.fees.find(f => f.id === feeId);
        if (!fee) {
            return null; // No se encontró el fee
        }

        // Retorna la membresía y el fee encontrados
        return {
            membership,
            fee
        };
    }

    const handleSignupError = (message) => {
        log(message);
    };

    return <Box>
        <Box className={classes.headerContainer}>
            <Box className={classes.radialContainer}>
                <Grid container direction="row" justifyContent="space-between" alignItems={breakpoint ? "flex-start" : "center"} style={{ height: '100%' }} spacing={2}>
                    <Grid item xs={12} md>
                        <Hidden mdDown>
                            <Box className='mb-2'>
                                <Typography className={classes.congratsTitle}>
                                    ¡felicidades, haz sido referido por un amigo!
                                </Typography>
                            </Box>
                        </Hidden>
                        <Box className='mb-3 pb-3'>
                            <Typography className={classes.discountTitle}>
                                Obtén tu membresía Talisis <Box component="span" className={classes.discountSubtitleWrapper}>con <Typography className={classes.discountSubtitle}>{DISCOUNT_PERCENTAGE}% de descuento</Typography></Box>
                            </Typography>
                        </Box>
                        <Box className='mb-4'>
                            <Typography className={clsx(classes.referralsInstructionsSubtitle, 'font-size-16')}>
                                Copia este cupón y pégalo en tu carrito de compra para activar el descuento.
                            </Typography>
                        </Box>
                        <Box className='mb-3 pb-3'>
                            <Grid container direction='row' spacing={2}>
                                <Grid item className={classes.referralsCopyCodeButtonContainer} xs={12} md="auto">
                                    <Box>
                                        <ReferralCouponCodeButton classes={classes} couponCode={COUPON_CODE} isResponsive={isResponsive} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md="auto">
                                    <Button color='primary' variant='contained' size='large' className={classes.referralsMembershipsButton} onClick={() => { scrollToSection('membresias') }} fullWidth={breakpoint ? true : false}>
                                        Ver membresías
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Typography className={classes.referralsDisclaimerSubtitle}>
                                Oferta disponible solamente para personas que aún no tienen una cuenta en Talisis.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md>
                        <Grid container style={{ width: '100%' }} justifyContent='center'>
                            <Grid item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box className={classes.benefitsContainer}>
            <Box className='mb-5 pb-3'>
                <Typography variant="h2" align='center' className={clsx("title")}>Disfruta de todos los beneficios</Typography>
            </Box>
            <Grid container direction='row' justifyContent="center" alignItems="center" spacing={3}>
                {
                    ReferralBenefitsData.map((benefit, index) => {
                        return (
                            <ReferralBenefits
                                key={index}
                                icon={benefit.icon}
                                title={benefit.title}
                                body={benefit.body}
                                classes={classes}
                            />
                        )
                    })
                }
            </Grid>
        </Box>
        <Box className={classes.membershipsContainer} id="membresias">
            <Typography variant="h2" align='center' className={clsx("title")}>Elige tu membresía</Typography>
            <Box>
                <MembershipPlans membershipTerm={PLAN_SEMESTRAL_MONTHS} displaySavedPercentage={false} onClick={handleMembershipPlanClick} membershipPlansD={membershipPlansD} />
            </Box>
            <Loader loading={loading} minHeight="500px">
                <Box className={classes.membershipCards}>
                    <Grid container spacing={3} justifyContent='center'>
                        {memberships.map((membership, index) =>
                            <Grid key={index} item className={classes.grid}>
                                <MembershipPlanCard
                                    id={membership.slug}
                                    name={membership.name}
                                    popular="professional"
                                    fees={membership?.fees}
                                    membershipId={membership.id}
                                    feePlan={feePlan}
                                    discountOff={DISCOUNT_PERCENTAGE}
                                    calculateStrikeOutPrice="standard-discount"
                                    media={{
                                        showIcon: true,
                                        fee: membership?.fees?.filter(fee => fee.term_slug === feePlan)[0]
                                    }}
                                    content={{
                                        previous: getPreviousMembership(membership, memberships)
                                    }}
                                    user={null}
                                    isResponsive={isResponsive}
                                    isReferrals
                                    actionType="checker"
                                    membershipSelected={membershipSelectedId}
                                    onMembershipSelect={handleMembershipSelect}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Loader>
        </Box>
        <Loader loading={loading} minHeight="500px">
            <Box className={classes.signupContainer} id="registrarse">
                <Box className='mb-4 pb-3'>
                    <Typography variant="h2" align='center' className={clsx("title")}>Regístrate</Typography>
                </Box>
                <Box>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs="auto">
                            <Card className={classes.signupCard}>
                                <CardContent className={classes.signupCardContent}>
                                    <SignupForm onLoading={handleLoadingModal} onSignupError={handleSignupError} onSignupSuccess={handleSignupSuccess} footerExtras={footerExtras} isReferralPage={true} refererId={referer_id} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Loader>
        <StudentFormModal
            modalOpenFunc={signupLoading} modalCloseFunc={!signupLoading}
            loading={signupLoading}
        />
    </Box>
};

export default Referrals;