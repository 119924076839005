// CoursesContext.js

import React, { createContext, useContext, useReducer } from 'react';
import coursesReducer, { initialCourses } from './CoursesReducer';

// Creamos el contexto
const CoursesContext = createContext();

// Proveedor del contexto que utiliza el reducer y el estado inicial
const CoursesProvider = ({ children }) => {
    const [coursesState, dispatch] = useReducer(coursesReducer, initialCourses);

    return (
        <CoursesContext.Provider value={{ coursesState, dispatch }}>
            {children}
        </CoursesContext.Provider>
    );
};

// Hook personalizado para utilizar el contexto
const useCoursesContext = () => useContext(CoursesContext);

export { CoursesContext, CoursesProvider, useCoursesContext };
export default CoursesProvider;
