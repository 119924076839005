import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Styles
import useStyles from '../../../assets/styles/components/cards/landSearchCourseCard/landSearchCourseCardStyle';

// Material components
import { Box, Icon, IconButton, Typography, useMediaQuery } from '@material-ui/core';

// Components
import Base from '../components/Base';
import TwoSectionBody from './components/TwoSectionBody';

// Components
import PriceAndMembership from '../components/PriceAndMembership';
import ItemListCourseCard from '../ItemListCourseCard/ItemListCourseCard';

const LandSearchCourseCard = ({courseId, userId, media, content, isLogged, onClick, onClickMixPanelCard, isListItemCard, onSaveCourse}) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles();

    const [saveStatus, setSaveStatus] = React.useState(false);
    const [updatingStatus, setUpdatingStatus] = React.useState(false);

    const handleClickMixPanelCard = (id) => () => {
        if(!!onClickMixPanelCard) onClickMixPanelCard();
        if (!!onClick) onClick({id});
    };

    //funcion para guardar los cursos en favoritos solamente
    const handleSaveUnsaveCourse = async (e) => {
        const newStatus = !saveStatus;

        setUpdatingStatus(true);
        setSaveStatus(newStatus);

        const response = await onSaveCourse(e, newStatus, userId, courseId);

        setSaveStatus(response);
        setUpdatingStatus(false);
    };

    return (
        <>
            {
                isListItemCard && <ItemListCourseCard courseId={courseId} userId={userId} content={content} isLogged={isLogged} media={media} onClick={onClick} onClickMixPanelCard={onClickMixPanelCard} onSaveCourse={onSaveCourse}/>
            }
            { 
                !isListItemCard && 
                <Base onClick={handleClickMixPanelCard(courseId.toString())} className={classes.rootBase} hasClickEvent={!!onClickMixPanelCard || !!onClick}>
                    <TwoSectionBody media={media}>
                        <>
                            <Box className={classes.dataSection}>
                                <Box className={classes.title}>
                                    <Typography variant='overline' className={clsx(classes.courseLabel, isResponsive && 'variant-overline-sm', 'semi-bold')}>CURSO</Typography>
                                    {
                                        (isLogged && !!onSaveCourse) &&
                                        <IconButton disabled={updatingStatus} onClick={handleSaveUnsaveCourse} className="p-0">
                                            <Icon className={clsx(saveStatus ? 'ri-bookmark-fill text-violet-300' : 'ri-bookmark-line text-dark-black-200', `font-size-${isResponsive ? '20' : '24'}`)}/>
                                        </IconButton>
                                    }
                                </Box>
                                <Box>
                                    <Typography variant='body1' className={clsx(classes.courseName, isResponsive && 'variant-xsmall', 'semi-bold')}>{content.courseName}</Typography>
                                </Box>
                            </Box>                
                            <Box>
                                <PriceAndMembership content={content}/>
                            </Box>
                        </>
                    </TwoSectionBody>
                </Base>
            }
        </>
    );
};

LandSearchCourseCard.defaultProps  = {
    isListItemCard: false,
    isLogged: false,
};

LandSearchCourseCard.propTypes = {    
    courseId: PropTypes.number.isRequired,
    userId: PropTypes.number,
    media: PropTypes.shape({
        backgroundImage: PropTypes.string,
        ratingData: PropTypes.shape({
            number: PropTypes.number,
            listNumber: PropTypes.number,
        }),
        sourceIdLogo: PropTypes.number.isRequired,
    }).isRequired,
    content: PropTypes.shape({
        hasMembership: PropTypes.bool,
        courseName: PropTypes.string.isRequired,
        price: PropTypes.shape({
            number: PropTypes.number,
            listNumber: PropTypes.number,
        }),
        showLabel: PropTypes.bool.isRequired,
        showPrice: PropTypes.bool.isRequired,
        labelToShow: PropTypes.string.isRequired,
        typeLabel: PropTypes.number.isRequired,
    }).isRequired,
    isLogged: PropTypes.bool,
    onClick: PropTypes.func,
    onClickMixPanelCard: PropTypes.func,
    isListItemCard: PropTypes.bool,
    onSaveCourse: PropTypes.func,
};

export default LandSearchCourseCard;