import React from 'react';

//@ThirtyParty
import clsx from 'clsx';
import moment from 'moment';

// @material-core
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import {Icon} from "@material-ui/core";

// local
import {detailsStyles} from '../../../../../assets/styles/jss/pages/degreeCertificates/DetailsStyles';
import dateFormat from '../../../../../design-system/utils/dateFormat';
import {ISO_DATE_FORMAT} from '../../../../../design-system/constants/date-formats';
import Select from '../../../../../design-system/components/Form/Select/Select';
import {findIndex, isNil, propEq} from "ramda";
import Picker from "../../../../../shared/components/Form/Picker";


export const InstProceInfo = ({data, onChange, isEdit, catalogues, errorForm, disabledDates}) => {

    const classes = detailsStyles({isEdit});

    return (
        <Grid container spacing={3} className="mt-1">
            <Grid item>
                <Grid container>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Institución
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="institucionProcedencia"
                                    name="institucionProcedencia"
                                    fullWidth
                                    multiline={true}
                                    className={classes.label}
                                    value={data.institucionProcedencia || ''}
                                    InputProps={{
                                        className: classes.inputText,
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onClick={e => e.stopPropagation()}
                                    onChange={e => onChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Id Tipo de Antecedente
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit ?
                                    <TextField
                                        id="idTipoEstudioAntecedente"
                                        name="idTipoEstudioAntecedente"
                                        placeholder='Id Tipo de Antecedente'
                                        fullWidth
                                        multiline={true}
                                        className={classes.label}
                                        value={catalogues.backgroundType[findIndex(propEq(data.idTipoEstudioAntecedente, 'id'))(catalogues.backgroundType)]?.label || ''}
                                        InputProps={{
                                            className: clsx(!isEdit && classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    :
                                    <Select
                                        items={catalogues.backgroundType}
                                        label=""
                                        className={clsx(classes.select)}
                                        name="idTipoEstudioAntecedente"
                                        onClick={e => e.stopPropagation()}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        disabled={!isEdit}
                                        value={data.idTipoEstudioAntecedente || ""}
                                        size="small"
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Entidad Federativa
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="idEntidadFederativaProcedencia"
                                    name="idEntidadFederativaProcedencia"
                                    fullWidth
                                    multiline={true}
                                    className={classes.label}
                                    value={data.idEntidadFederativaProcedencia || ''}
                                    InputProps={{
                                        className: classes.inputText,
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onClick={e => e.stopPropagation()}
                                    onChange={e => onChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1  ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Fecha Inicio
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit ?
                                    <TextField
                                        id="fechaInicioInstProcedencia"
                                        name="fechaInicioInstProcedencia"
                                        placeholder='Fecha Inicio'
                                        fullWidth
                                        multiline={true}
                                        className={classes.label}
                                        value={data.fechaInicioInstProcedencia || ''}
                                        InputProps={{
                                            className: clsx(classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    /> :
                                    <Picker
                                        className={classes.datePicker}
                                        name="fechaInicioInstProcedencia"
                                        value={dateFormat(data.fechaInicioInstProcedencia ||'' , ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                                        onChange={(e) => {
                                            e.target['type'] = "date";
                                            onChange(e)
                                        }}
                                        onClick={e => e.stopPropagation()}
                                        format="DD/MM/YYYY"
                                        placeholder="DD/MM/YYYY"
                                        autoOk={false}
                                        views={['year', 'month', 'date']}
                                        keyboardbuttonprops={{
                                            "aria-label": "change date"
                                        }}
                                        shouldDisableDate={disabledDates}
                                        error={!isNil(errorForm.fechaInicioInstProcedencia)}
                                        helperText={!isNil(errorForm.fechaInicioInstProcedencia) && errorForm.fechaInicioInstProcedencia}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position={'start'}>
                                                    <Icon className={clsx('text-white-200 ri-calendar-2-line mr-1')}
                                                          style={{fontSize: 18}}/>
                                                </InputAdornment>)
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Fecha Término
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit ?
                                    <TextField
                                        id="fechaTerminacionInstProcedencia"
                                        name="fechaTerminacionInstProcedencia"
                                        placeholder='Fecha Término'
                                        fullWidth
                                        className={classes.label}
                                        value={data.fechaTerminacionInstProcedencia || ''}
                                        InputProps={{
                                            className: clsx(classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    /> :
                                    <Picker
                                        className={classes.datePicker}
                                        name="fechaTerminacionInstProcedencia"
                                        value={dateFormat(data.fechaTerminacionInstProcedencia || '' , ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                                        onChange={(e) => {
                                            e.target['type'] = "date";
                                            onChange(e)
                                        }}
                                        onClick={e => e.stopPropagation()}
                                        format="DD/MM/YYYY"
                                        placeholder="DD/MM/YYYY"
                                        autoOk={false}
                                        views={['year', 'month', 'date']}
                                        keyboardbuttonprops={{
                                            "aria-label": "change date"
                                        }}
                                        shouldDisableDate={disabledDates}
                                        error={!isNil(errorForm.fechaTerminacionInstProcedencia)}
                                        helperText={!isNil(errorForm.fechaTerminacionInstProcedencia) && errorForm.fechaTerminacionInstProcedencia}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position={'start'}>
                                                    <Icon className={clsx('text-white-200 ri-calendar-2-line mr-1')}
                                                          style={{fontSize: 18}}/>
                                                </InputAdornment>)
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={clsx((data.ou === "UERRE" && data.nivel !== 'PO'|| data.ou === "UNID") && classes.itemHide)}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Cédula
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="noCedula"
                                    name="noCedula"
                                    placeholder='Cédula'
                                    fullWidth
                                    className={classes.label}
                                    value={data.noCedula || ''}
                                    InputProps={{
                                        className: clsx(!isEdit && classes.inputText, isEdit && classes.inputTextEdit, !isNil(errorForm.noCedula) && classes.inputTextError),
                                        disableUnderline: true,
                                        readOnly: (!isEdit && data.nivel === 'PO'),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        minLength: "7",
                                        maxLength: "8"
                                    }}
                                    error={!isNil(errorForm.noCedula)}
                                    helperText={!isNil(errorForm.noCedula) && errorForm.noCedula}
                                    onClick={e => e.stopPropagation()}
                                    onChange={e => onChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};
