import React from 'react';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// styles
import { useTheme } from '@material-ui/core/styles';

const Palette = () => {

    const {palette: {colors, info, success, error, warning, primary, secondary}} = useTheme();

    return (
        <Grid container spacing={4}>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Variable del colores</Typography>
                    <Typography variant="body2">Se encuentrean ubicados en la paleta del tema en la propiedad "colors" dentro de esta se pueden buscar con el nombre que viene debajo de cada color</Typography>
                </Grid>
                {
                    Object.keys(colors).map(color => 
                        <Grid item xs key={color}>
                            <Box height={70} width="100%" style={{backgroundColor: colors[color]}} />
                            <Typography variant="body2">{color}</Typography>
                        </Grid>                 
                    )
                }
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Primario/ Secundario</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box height={70} width="100%" style={{backgroundColor: primary.main}} />
                    <Typography variant="body2">Primario</Typography>
                </Grid>                 
                <Grid item xs={6}>
                    <Box height={70} width="100%" style={{backgroundColor: secondary.main}} />
                    <Typography variant="body2">Secundario</Typography>
                </Grid>                 
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Estados</Typography>
                    <Typography variant="body2">Son cuatro niveles de gravedad</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Box height={70} width="100%" style={{backgroundColor: info.main}} />
                    <Typography variant="body2">Info</Typography>
                </Grid>                 
                <Grid item xs={3}>
                    <Box height={70} width="100%" style={{backgroundColor: success.main}} />
                    <Typography variant="body2">success</Typography>
                </Grid>                 
                <Grid item xs={3}>
                    <Box height={70} width="100%" style={{backgroundColor: warning.main}} />
                    <Typography variant="body2">warning</Typography>
                </Grid>                 
                <Grid item xs={3}>
                    <Box height={70} width="100%" style={{backgroundColor: error.main}} />
                    <Typography variant="body2">error</Typography>
                </Grid>                 
            </Grid>
        </Grid>            
    );
};

export default Palette;