import React from 'react';

import SimulationBar from '../components/SimulationBar/SimulationBar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

// styles
import useStyles from '../../assets/styles/jss/layout/unidLayoutStyle'
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';

const UnidLayout = (props) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const {children, ou} = props;

    const banner = isResponsive ? `/images/vocational-test/unid-responsivo.png` : `/images/vocational-test/banner-unid.png`;


    const classes = useStyles({ou});

    return (
        <div className={classes.simulation_bar}>
  		    <SimulationBar />
            <Box className={clsx(classes.root, classes[ou])}>
            <CssBaseline />
            <AppBar className={classes.appBar}>
                <Toolbar className={clsx(classes.toolbar, 'container')}>
                    <img src="/images/vocational-test/unid-logo.png" className="mr-4"/>
                </Toolbar>
            </AppBar>
                <main className={classes.content}>
                    <div className={classes.drawerHeader} />
                    <Box className={classes.img}>
                        <img src={banner} className={classes.imgBanner}/>
                    </Box>
                    <Container maxWidth="sm">
                        {children}
                    </Container>
                </main>
            </Box>     
        </div>
    );
};

export default UnidLayout;