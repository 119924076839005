import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Grid, Icon, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import palette from '../../../design-system/theme/palette';
import Picker from '../../../shared/components/Form/Picker';
import Table from '../../../shared/components/Table/Table';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { log } from '../../../shared/utils/console.js'
import Pagination from '@material-ui/lab/Pagination';
import { ConsultanciesService } from '@sdk-point/talisis'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


const HistoryConsultancies = (props) => {
    
    const useStyles = makeStyles((theme) => ({
        table: {
            '& .tHeadRow': {
                height: '33px',
                justifyContent: 'center',
                alignItems: 'center'
            }
        },
        dividerHistory:{
            background: palette.colors.fillStroke[200], 
            width: '689px',
            [theme.breakpoints.down('xs')]:{
                width: '95%'
            }
        },
        calendarIcon:{
            fontSize:'20px', 
            display: 'flex', 
            alignItems:'center'
        },
        selectMonth:{
            display: 'flex',
            background: palette.colors.fillStroke[500], 
            width: '665px',
            [theme.breakpoints.down('xs')]:{
                width: '95%'
            }
        },
        boxDataTime:{
            display: 'flex', 
            flexDirection:'row', 
            gap: '5px',
            alignItems: 'center'
        }
    }));

    const [startMonthSelected, setStartMonthSelected] = useState();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [totalRequestLabel, setTotalRequestLabel] = useState('');
    const [paginationProps, setPaginationProps] = useState({
        page: 1,
        count: 1
    });
    const [columns, setColumns] = useState([
        { id: '_fecha', name: 'fecha', label: 'Fecha', minWidth: 120, align: 'center', sort: true, direction: 'desc', active: true, sortProp: 'ra.fecha_asesoria' },
        { id: '_hora', name: 'hora', label: 'Hora', minWidth: 130, align: 'center' },
        { id: '_materia', name: 'materia', label: 'Área de conocimiento', minWidth: 100, align: 'center' },
        { id: '_solicitante', name: 'solicitante', label: 'Alumno', minWidth: 200, align: 'center' },
    ]);

    const classes = useStyles();

    useEffect(() => {
        getMyRequests(1)
    }, []);

    const getMyRequests = async(page, month = null, year = null) =>{
        try {
            const sortBy = columns.find(it => it.active);
            const sort = `${sortBy.sortProp}-${sortBy.direction.toUpperCase()}`;
            const body = {
                person_id:  props.person_id, 
                page: page, 
                monthSelected: month, 
                yearSelected: year, 
                sort: sort
            };
            const data = await ConsultanciesServiceSDK.getHistoryConsultancies(body);
            let data_asesorias = data.asesorias.map((item) => {
                return {
                    id: item.id,
                    fecha: item.fecha,
                    hora: convertUTCTimeRangeToLocal(item.hora),
                    materia: item.materia,
                    solicitante: item.solicitante,
                };
              });
            setRows(data_asesorias);   
            setLoading(false);
            const total_count = data.asesorias.length ? data.total_count : 0;

            const paginationProps = {
                page,
                count: data.asesorias.length ? Math.ceil(total_count / 5) : 1
            };
            setPaginationProps(paginationProps);
            const to = data.asesorias.length >= 5 ? paginationProps.page * 5 : total_count;
            const from = data.asesorias.length >= 5 ? to - 4 : (total_count - data.asesorias.length) + 1;

            const totalRequestsLabel = `Visualización ${data.asesorias.length ? from : 0} - ${to} de ${total_count} solicitudes`;
            setTotalRequestLabel(totalRequestsLabel);        
        } catch (e) {
            log('error', e)
        }
    }

    const convertUTCTimeRangeToLocal = (timeRange) => {
        const [start, end] = timeRange.split(" - ");
        const startTime = new Date(Date.UTC(1970, 0, 1, ...start.split(":"), 0));
        const endTime = new Date(Date.UTC(1970, 0, 1, ...end.split(":"), 0));
        
        const formattedStart = startTime.toLocaleTimeString().split(":").slice(0,2).join(":");
        const formattedEnd = endTime.toLocaleTimeString().split(":").slice(0,2).join(":");
        
        const startHour = formattedStart.split(":")[0];
        const endHour = formattedEnd.split(":")[0];
        
        const formattedRange = `${startHour.padStart(2, '0')}:${formattedStart.split(":")[1]} - ${endHour.padStart(2, '0')}:${formattedEnd.split(":")[1]}`;
        return formattedRange;
      }

    const handleChangeInicio = (date) => {
        const {target: {value}} = date;
        let month = null;
        let year = null;

        if(value){
            const dateFilter = new Date(value);
            month = dateFilter.getMonth() + 1;
            year = dateFilter.getFullYear();
        }

        setStartMonthSelected(value)
        getMyRequests(1, month, year);
    };

    const handleClickInfoTable = (data) => {
        setSelected(data)
    }

    const handleClickSort = (colum) => {
        colum.direction = colum.direction === 'desc' ? 'asc' : 'desc';

        const tableColumns = columns;

        tableColumns.map(it => {
            if(it.sort){
                it.active = colum.name === it.name;
                it.direction = colum.name === it.name ? colum.direction : 'desc'
            }
        })

        setColumns(tableColumns)
        handleChangeInicio({target: {value: startMonthSelected}})
    }

    const handleChangePage = (event, page) => {
        setPaginationProps({
            ...paginationProps,
            page: page
        })
        if(startMonthSelected === undefined){
            getMyRequests(page)
        }else{
            let month = null;
            let year = null;
            const dateFilter = new Date(startMonthSelected);
            month = dateFilter.getMonth() + 1;
            year = dateFilter.getFullYear();
            getMyRequests(page,month,year)
        }
    }

    return(
    <>
        <Box className={props.ClassNameHistory}>
        <Typography variant="subtitle1">Historial de Asesorías</Typography>
            <Divider className={classes.dividerHistory} />
            <Box className={classes.boxDataTime}>
                <Icon className={clsx("ri-calendar-2-line", classes.calendarIcon)} />
                <Picker
                    margin="none"
                    name="fecha_inicio"
                    value={startMonthSelected}
                    openTo="month"
                    views={["year", "month"]}
                    format="MMMM YYYY"
                    onChange={handleChangeInicio}
                    label="Seleccione mes:"
                    InputProps={{
                        select: classes.selectMonth,
                        endAdornment: (
                            <InputAdornment position={'end'}>
                                <ExpandMoreIcon style={{color: palette.colors.darkBlack[200]}} />
                            </InputAdornment>)
                    }}
                />  
            </Box>

                <LoaderContent loading={loading} minHeight={200} style={{width: '97.5%', height: '65%'}}>
                    <Table 
                        columns={columns}
                        rows={rows}
                        loading={loading}
                        checkboxs={false}
                        handleClickInfoTable={handleClickInfoTable}
                        onClickSort={handleClickSort}
                        selectedArchive={selected}
                        maxHeight="100vh"
                        className={classes.table}
                        captionNothingFound="No hay datos actualmente"
                        subcaptionNothingFound=""
                        nothingFoundImg="misasesorias"
                    />
                    <Grid container className='mt-2'>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">{totalRequestLabel}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <Pagination shape="rounded" onChange={handleChangePage} {...paginationProps}/>
                            </Box>
                        </Grid>
                    </Grid>
                </LoaderContent>
        </Box>
    </>
    );
};

export default HistoryConsultancies;
