// Utils
import { validateCard } from "./validateCard";
import Cookies from 'js-cookie';

//SDK
import { MarketPlaceService } from "@sdk-point/talisis";
import { MIXPANEL_EVENT } from '@sdk-point/talisis';

// Shared
import { getSessionCookie } from "../../../../../../../shared/utils/Sessions";
import { getCheckoutCookie } from "../../../../../../../shared/utils/checkoutSession-legacy.js";

// Constants
import { CONEKTA_PUBLIC_KEYS } from "../../../../../../../shared/constants/conekta";
import { trackEventMixPanel } from "../../../../../../../shared/components/Mixpanel/mixpanel";
import { log } from '../../../../../../../shared/utils/console.js'
import { updateUserMembership } from "./updateUserMembership";

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

const sessionCookie = getSessionCookie();
const checkoutCookie = getCheckoutCookie();

export const createCardPayment = async(cardFormInfo, userInfo, order, history, conekta, updateMembership) => {
    window.scrollTo(0, 0);
    if (validateCard(cardFormInfo)) {
        let tipoTarjeta = (cardFormInfo?.number[0,0]) === '5' ? 'MasterCard' : (cardFormInfo?.number[0,0]) === '4' ? 'Visa': 'No coincide';
        
        let role;
        role ? role = JSON.parse(localStorage.getItem('permissions')) : role = "Estudiante";

        //Se agrega track de Checkout pago con tarjeta
        trackEventMixPanel(MIXPANEL_EVENT.CHECKOUT_PAGO_TARJETA, {
            mensaje: "Checkout pago con tarjeta",
            tipo: tipoTarjeta,
            courseId: order.conceptos['0'].course_id,
            courseName: order.conceptos['0'].name,
            source: order.conceptos['0'].company_id,
            price: order.total,
            checkoutType: 'Marketplace', //(MARKETPLACE-TRAMITES)Desde donde se hace la compra, marketplace, tramites,etc...
            userType: role ? 'Usuario registrado' : 'Público'
        })
        const currentYear = new Date().getFullYear().toString();
        const tokenForm = {
            "card": {
                ...cardFormInfo,
                "email": await checkoutCookie?.personInfo?.email,
                "exp_year": currentYear.slice(0, 2) + cardFormInfo.exp_year
            }
        };

        const successHandler = async (token) => {
            try {
                let firstName = userInfo.first_name;
                let lastName = userInfo.last_name;
                // let emailAddress = userInfo.email_address || userInfo.email;
                let emailAddress = await checkoutCookie?.personInfo?.email;
                const body = {
                    payment_method: "card",
                    token: token,
                    person_id: userInfo.person_id,
                    order_id: order.id,
                    firstName: firstName,
                    lastName: lastName,
                    email: emailAddress,
                    membership_id: null,
                    membership_fee_id: null,
                };
                
                const response = await marketService.payOrder({ person_id: userInfo.person_id}, body);

                if (response.success) {
                    const activeAccount = sessionCookie?.person_id ? true : false;
                    history.replace({
                        pathname: "/payment-confirmation",
                        search: `?orderId=${order.id}`,
                        state: {
                            "user": userInfo,
                            "activeAccount": activeAccount,
                            "activateAccountURL": checkoutCookie?.personInfo?.activateAccountURL
                        }
                    });

                    updateUserMembership(order, updateMembership);
                }

            } catch (e) {
                log('Error Talisis', e);
            }
        };

        const errorHandler = (err) => {
            log(err);
            // setErrorMessage(err.message_to_purchaser);
        };
        
        if (order.conceptos[0]) {
            const company_id = setOuPurchase(order.conceptos);
            // const key = CONEKTA_PUBLIC_KEYS[order.conceptos[0].company_id];
            const key = CONEKTA_PUBLIC_KEYS[company_id];
            conekta.setPublicKey(key);
            conekta.createToken(tokenForm, successHandler, errorHandler);
        }
    }
};

export const setOuPurchase = (orderItems) => {
    if(orderItems.length > 1){
        const membership = orderItems.find( product => product.membership_id)

        return membership?.company_id
    }
    return orderItems[0]?.company_id
}
