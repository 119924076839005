import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        // paddingLeft: "54px",
        // paddingRight: "54px",
        "@media (max-width: 1250px)": {
            paddingLeft: "0px",
            paddingRight: "0px",
        },
        "@media (max-width: 445px)": {
            marginLeft: "0px",
        },
        "& .swiper": {
            overflow: "visible",
            boxSizing: "content-box",
            marginLeft: '-12px'
        }
    },
    sliderArrow:{
        position: 'absolute',
        zIndex: "2",
        pointerEvents: "none",
        top: "-46px",
        right: 0,
        '& .arrow-btn':{
            border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
            padding: 8,
            zIndex: 1,
            backgroundColor: theme.palette.colors.fillStroke[400],
            color: theme.palette.white,
            opacity: '.8',
            pointerEvents: "all",
            '&:hover':{
                backgroundColor: theme.palette.primary.main
            },
            '&.next':{
                left: "inherit",
                right: "0",
                float: 'right'
            }
        },
        [theme.breakpoints.down('xs')]:{
            paddingRight: 16
        }
    },
    
}));