import React from "react";
import { generatePath } from "react-router";
import { useHistory } from 'react-router-dom';

import * as paths from '../../../../routes/paths';

// SDK Point 
import { ProductService } from '@sdk-point/talisis';

// Material UI 
import { convertSourceToImageResource } from '../../../../../src/constants/placeholderImages';
import { getLogoCard, getLogoCardSizeMedia } from '../../../../../src/constants/logosImages';
import live_tv_outlined from '../../../../assets/images/logos/landing-icons/microgrados/live_tv_outlined_white.svg'
import getConnectionAllies from '../../../../shared/components/Allies/AlliesConnect';
import ModalClassEnrollment from "../../../../shared/components/ModalClassEnrollment/ModalClassEnrollment";
import { log } from '../../../../shared/utils/console.js'
import ListItemCourseInProgress from "../../../../design-system/components/Cards/ListItemCourseInProgress/ListItemCourseInProgress";
import { useSelector } from "react-redux";

const modalityOptions = ({
    2: "Online",
    3: "Híbrida",
    1: "Presencial",
});

const PROGRAM_ASYNC = 'Asincrónico';
const STATUS_ACORDION_FINISHED = 0;
const STATUS_ACORDION_IN_COURSE = 1;
const STATUS_ACORDION_PENDING = 2;

const CardProgramList = (props) => {
    const user = useSelector((state) => state.userReducer.user);
    const history = useHistory();
    const productService = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const [modalProps, setModalProps] = React.useState({ open: false, loading: false, course: null, error: null });
    
    let img = props.image_url;
    if (img === null) {
        img = convertSourceToImageResource(props.company_id, props.source_id);
    }

    const handleClickEnrollment = (e, course) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: true, loading: false, error: null, course: course });
    };

    const handleOnCloseModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false, course: null });
    };

    const handleOnConfirmModal = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            setModalProps({ ...modalProps, loading: true });
            const responseEnrollment = await productService.postProductEnrollment({
                person_id: user.person_id,
                course_id: modalProps.course?.course_id,
                program_id: modalProps.course?.program_id,
            });
            log(responseEnrollment);

            if (responseEnrollment?.success) {
                setModalProps({ ...modalProps, loading: false, open: false });
                handleContinueToClass(modalProps.course);
                history.push(paths.CURSOS);
            } else {
                setModalProps({ ...modalProps, loading: false, open: true, error: 1 });
            }
        }
        catch (e) {
            setModalProps({ ...modalProps, loading: false, error: e?.message });
        }
    };

    const handleContinueToClass = async (course) => {
        const body = {
            person_id: user?.person_id,
            email: user?.email,
            ou: user?.ou,
            course_url: course.course_url,
            source_id: course.source_id,
            course_url_neo: course.course_url_neo,
            course_id: course.course_id,
            company_id: course.company_id,
            course_name: course?.name,
        }
        const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + course?.source_id, "_blank");

        var urlRespuesta = await getConnectionAllies(body);
        setTimeout(() => {
            win.focus();
            win.location.replace(urlRespuesta);
        }, 3000);
    };

    const handleGoToCourse = (e, course) => {
        e.preventDefault();
        e.stopPropagation();
        if (STATUS_ACORDION_PENDING === props.status) {
            history.push(generatePath(paths.CURSO_DETALLE, { id: course.course_id }) + "?tipo_inscripcion=" + PROGRAM_ASYNC, {
                enrollment: {
                    course: course,
                    remainingCredits: props.remaining_credits
                }
            });
        }
        else if (STATUS_ACORDION_IN_COURSE === props.status || STATUS_ACORDION_FINISHED === props.status) {
            history.push(generatePath(paths.CURSO_DETALLE, { id: course.course_id }));
        }
    };

    return (
        <>
            <ListItemCourseInProgress
                course={props}
                name={props.name}
                credits={props.remaining_credits}
                disabled={props.no_available}
                grade={props.grade}
                califFinal={props.grade}
                textButtonDisabled={'Próximamente'}
                textButtonFunction1={'Inscribirme ahora'}
                textButtonFunction2={'Ver detalle'}
                handleFunction1={handleClickEnrollment}
                handleFunction2={handleGoToCourse}
                icon_modality={live_tv_outlined}
                image_url={img}
                level_id={'CURSO'}
                logoCard={getLogoCard(7)}
                logoCardSize={getLogoCardSizeMedia(7)}
                modality={modalityOptions[props.modality_id]}
                progress_percentage={props.progress_percentage}
                schedule_days={props.schedule_days}
                schedule_hrs={props.schedule_hrs}
                status={props.status}
                type_program={props.type_program}
            />
            <ModalClassEnrollment {...modalProps} onClose={handleOnCloseModal} onConfirm={handleOnConfirmModal} />
        </>
    )
};

export default CardProgramList;