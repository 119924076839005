import palette from '../palette';

export default {
    root: {
        color: palette.primary.main,
        width: 36,
        height: 36,
        fontSize: 22,
        alignItems: 'center',
        justifyContent: 'center'
    }
};