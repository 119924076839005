import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// material components
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

// @material-ui/icons
import styled from 'styled-components';
import { Box, Icon, Typography } from '@material-ui/core';
import Loader from '../../Loader/Loader';

import clsx from 'clsx';

import useStyles from '../../../assets/styles/components/form/selectStyle'

const ListItemIconStyled = styled(ListItemIcon)`
    min-width: 28px;
`;


const Select = ({value,items, readOnly = false, disabled = false, loading = false, placeholder = "Seleccione", inputProps, className, ...rest}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const textFieldRef = useRef(null);
    
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const ArrowDownIcon = () => {
        if(readOnly){
            return null
        }

        return <Icon className={clsx('text-dark-black-200 arrow-down-icon', classes.arrow, {'ri-arrow-up-s-line': isOpen, 'ri-arrow-down-s-line': !isOpen})} />;
    }
    return (<>
        { loading ?
            <Box width="100%" ml={2} mt={4} height={45} position="relative">
                <Box ml={0.6} pt={0.9} >
                    <Typography variant="h6">
                        Cargando {rest?.label}
                    </Typography>
                </Box>
                <Loader helpColor={false} open={loading} bgTransparent={false} />
            </Box>
        :
            <TextField
                value={value}
                ref={textFieldRef}
                className={clsx(classes.textField, className)}
                select
                variant="outlined"
                fullWidth
                inputProps={{ readOnly, placeholder, 
                    style:{ opacity: value ? 0 : 'unset', padding: "0 16px 0 16px" },
                    ...inputProps 
                }}
                // inputProps={{ disabled, readOnly, placeholder, 
                //     style:{ opacity: textFieldRef.current && !textFieldRef.current.value ? 0 : 'unset'} 
                // }}
                InputLabelProps={{ shrink: true}}
                SelectProps={{
                    IconComponent: ArrowDownIcon,
                    onOpen: handleOpen,
                    onClose: handleClose,
                    disabled
                }}
                {...rest}
            >
                <MenuItem value="" disabled>
                    <em>Seleccione</em>
                </MenuItem>
                {items.map( (it, key) =>
                    {
                        if(it instanceof Object){
                            return <MenuItem key={it.id} value={it.id} disabled={it.disabled}>
                                {it.icon && <ListItemIconStyled>{it.icon}</ListItemIconStyled>}
                                {it.label}</MenuItem>;
                        }

                        return <MenuItem key={key} value={it}>{it}</MenuItem>;
                    }
                )}
            </TextField>
        }
        </>);
};

export default Select;

Select.propTypes = {
    /**
     * Array of options
     */
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
      })).isRequired,
    /**
     * Optional click handler
     */
    onChange: PropTypes.func,
    required: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.any
};
