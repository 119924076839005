import React from 'react';

// @material-ui/core
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';


import { Rating } from '@material-ui/lab';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// shared
import dateFormat from '../../../../design-system/utils/dateFormat';

// icons
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    name: {
        lineHeight: "1.5em !important",
        maxHeight: "3em",
        display: "-webkit-box",
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 2,
    },
}));

const RateButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#049CB4"),
        fontWeight: "bold",
        backgroundColor: "#00C1DE",
        flexGrow: 1,
        '&:hover': {
            backgroundColor: "#049CB4",
        },
    },
}))(Button);

const CourseItem = (props) => {
    const classes = useStyles();
    const { onRateClick, ...rest } = props;

    const handleRateClick = () => {
        if (onRateClick) {
            onRateClick(rest);
        }
    };
    
    return (
        <TableRow>
            <TableCell><Typography variant="body2" color="primary" className="mt-1 mb-1">{rest.source}</Typography></TableCell>
            <TableCell component="th" scope="row">
                <Grid container spacing={2}>
                    <Grid item>
                        <Avatar variant="square" src={rest.image_url} className={classes.avatar} />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography variant="body2" color="textSecondary">{rest.subject}</Typography>
                        <Typography variant="subtitle1" className={classes.name}>{rest.name}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>{dateFormat(rest.end_date)}</TableCell>
            <TableCell width="20%">
                {
                    rest.course_rating === undefined || rest.course_rating === null ?
                        <RateButton onClick={handleRateClick}>Calificar clase</RateButton> :
                        <Rating name="read-only" size="medium" value={parseFloat(rest.course_rating)} precision={0.5} readOnly emptyIcon={<StarBorderIcon fontSize="inherit" />} />
                }
            </TableCell>
        </TableRow>
    );
};

export default CourseItem;