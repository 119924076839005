import { makeStyles, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

const toolbarHeight = 80;

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.colors.fillStroke[500],
        minHeight: '100vh'
    },
    content: {
        flexGrow: 1,
        overflowX: "hidden",
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        height: toolbarHeight
    },
    toolbar:{
        justifyContent: 'center',
        height: toolbarHeight
    },
    img:{
        backgroundImage: props => props.ou === 'unid' ? props.img : `${props.img}, radial-gradient(149.32% 117.35% at 0% 99.24%, #1F84BB 0%, #1C9BC8 25%, #19AFD3 50%, #18BBD9 75%, #18BFDC 100%)`,
        height: 274,
        width: '100%',
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor: props => props.ou === 'unid' ? theme.palette.colors.ou.unidBlue : 'transparent',
    },
    logo:{
        height: 32,
        marginRight: 21
    },
    appBar:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 33,
        width: '100%',
        height: 98
    },
    buttonLogin:{
        padding: 12,
        color: theme.palette.colors.gray[300]
    },
    unid:{
        '& .app-bar':{
            backgroundColor: theme.palette.colors.ou.unidPurple
        },
        '& .subtitle':{
            color: theme.palette.colors.gray[300]
        },
        '& .text':{
            color: theme.palette.colors.gray[300]
        },
    },
    uerre:{
        '& .app-bar':{
            backgroundColor: theme.palette.colors.fillStroke[400]
        },
        '& .subtitle':{
            color: theme.palette.colors.gray[300]
        },
        '& .text':{
            color: theme.palette.colors.gray[300]
        },
    },
}))