import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation } from "react-router-dom";



// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../../../redux/actions/userActions';
import * as profilePicActions from '../../../../../../redux/actions/profilePicActions';
import * as tourInitialActions from '../../../../../../redux/actions/tourInitialActions';

// routes
import * as paths from '../../../../../../routes/paths';

//services
import { setSessionCookie } from '../../../../../../shared/utils/Sessions';
import { MEMBERSHIPS } from '../../../../../../shared/constants/memberships';
import { log } from '../../../../../../shared/utils/console.js'

//SDK
import { MIXPANEL_EVENT,LoginService,SingupService } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../../shared/components/Mixpanel/mixpanel';

import { eventData, eventTracker } from '../../../../../../shared/components/EventTracker/EventTracker';


const LoginServiceSDK = new LoginService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const PasswordButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#049CB4"),
        fontWeight: "bold",
        backgroundColor: "#049CB4",
        flexGrow: 1,
        '&:hover': {
            backgroundColor: "#026d7d",
        },
        '&:disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
    },
}))(Button);

const SSOPasswordUpdate = props => {
    const { applyUser, onLoading, onSignupError, onSignupSuccess, token, resetPassword} = props;
    let history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [form, setForm] = React.useState({
        'password': '',
        'confirm_password': false
    });

    const location = useLocation();

    const [userState, setUserState] = useState({
        isAuthenticated: false,
        user: {},
        error: null
    });

    const preventDefault = (event) => event.preventDefault();

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (value) {
            const _errors = { ...errors };
            delete _errors[name];

            setErrors(_errors);
        }

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['password', 'confirm_password'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            }
        }

        if (form['password'] !== form['confirm_password']) {
            _errors['confirm_password'] = 'las contraseñas no coinciden'
        }

        setErrors({ ..._errors });

        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleSubmit = (e) => {
        preventDefault(e);
        if (validate()) {
            onLoading(true);
            SingupServiceSDK.updatePassword(form, token).then(data => {
                const {mail} = data
                const credentials = {
                    email: mail,
                    password: form.password
                }
                
                //Se agrega track de Enviar nueva password
                eventTracker('set_password', eventData, ['mixpanel']);

                LoginServiceSDK.loginWithEmail(credentials).then( async userProfile => {
                    const {person} = userProfile
                    const userInfo = {
                        userName: person.first_name + ' ' + person.last_name,
                        first_name: person.first_name,
                        last_name: person.last_name,
                        email: person.email ,
                        person_id: person.person_id,
                        is_admin: person.is_admin,
                        ou: 'POINT',
                        ouLabel: 'POINT',
                        membership_id: person.membership_id || MEMBERSHIPS.FREEMIUM,
                        interests: person.interests,
                    }                    
                    if(person.is_active) {
                        setUserState({
                            isAuthenticated: true,
                            user: userInfo,
                            error: null
                        });

                        log(userInfo)
                        applyUser(userInfo);
                        setSessionCookie(userInfo);
                        onLoading(false);
                        
                        var searchParams = new URLSearchParams(location.search);
                        if (searchParams.has('course') || searchParams.has('program')) {
                            history.replace({
                                pathname: paths.CHECKOUT_LEGACY,
                                state: {
                                    'user': userInfo,
                                    'referrer': "password-reset",
                                    // 'company': searchParams.get('company'),
                                    // 'sandbox': searchParams.get('sandbox') == "1",
                                    'items': [{
                                        course_id: searchParams.get('course'),
                                        program_id: searchParams.get('program')
                                    }]
                                }
                            });
                        } else {
                            history.push('/');
                        }
                    }
                });
                
            }).catch().finally();
        }
    };

    return(
        <></>
    )
}

const mapStateToProps = ({userReducer, profilePicReducer, tourInitialReducer}) => ({
	user: userReducer.user,
    profilePic: profilePicReducer.profilePic,
    showTour: tourInitialReducer.showTour
});

const mapDispatchToProps = {...userActions, ...profilePicActions, ...tourInitialActions};

export default connect(mapStateToProps, mapDispatchToProps)(SSOPasswordUpdate);