import React from 'react';

// @material-ui/core
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

// @material-ui/lab
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutocompleteAuthorizers = (props) => {
    const [open, setOpen] = React.useState(false);
    const searchTimer = React.useRef(null);
    const {onChange, onInputChange, getData, criteria, interval = 500, optionLabel, multiple, limitTags, label, placeholder, defaultValue, error, required = false } = props; //, ...rest


    React.useEffect(() => {
        clearTimeout(searchTimer.current);

        if (criteria) {
            searchTimer.current = setTimeout(() => getData(), interval);
        }

        return () => {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
            }
        };
    }, [criteria, interval, getData]);

    return (
        <Box style={{ marginRight: '20px', width: '30ch' }}>
            <Autocomplete
                disableClearable={props.readOnly}
                disabled={props.disabled}
                multiple={multiple}
                limitTags={limitTags}
                id="asynchronous-student"
                fullWidth
                size="small"
                open={props.readOnly ? false : open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                filterOptions={(options) => { if(options){ return options } } }
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => Array.isArray(option) || option === "" ? "": option[optionLabel]}
                options={props.options || []}
                loading={props.loading}
                onInputChange={(event, value, reason) => onInputChange(value)}
                onChange={(event, newValue) => onChange(newValue)}
                defaultValue={defaultValue}
                className={props.class}
                // value={props.value}
                renderTags={props.readOnly ? 
                    (tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip size="small" key={index}
                                label={option[optionLabel]}
                            />
                        ))
                    : null
                }
                renderInput={(params) => (
                    <TextField {...params} 
                    value={defaultValue}
                    // disabled={props.readOnly}
                        label={label}  
                        placeholder={placeholder} 
                        variant="outlined" 
                        size="small" 
                        InputProps={{
                            ...params.InputProps, 
                            endAdornment: (
                                <React.Fragment>
                                    {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                            readOnly: props.readOnly,
                        }} 
                        error={Boolean(error)}
                        helperText={error}
                        required={required}
                    />
                )}
                renderOption={props.hasOwnProperty('renderOption') ? props.renderOption : null}
            />
        </Box>
    );
};

export default AutocompleteAuthorizers;