import { getSessionCookie } from "./Sessions";
import { log } from '../../shared/utils/console.js'
import { updateZendesk } from '../../redux/actions/notificationActions.js';
import { store } from '../../index.js';
let socket = null
export const conektaWSUpgradeMembership = (props) => {

    if (socket && socket.readyState === WebSocket.OPEN) {
        log('Reusing existing WebSocket connection.', socket);
        return;
    }

    if (socket && (socket.readyState === WebSocket.CONNECTING || socket.readyState === WebSocket.CLOSING)) {
        log('WebSocket connection is in progress or closing, waiting to reuse.', socket);
        return;
    }

    //let socket = new WebSocket(`wss://YOUR_ENDPOINT.execute-api.us-west-2.amazonaws.com/${process.env.REACT_APP_ENV}?email=${encodeURIComponent(props.email)}`);
    socket = new WebSocket(`wss://ho8gv09sa3.execute-api.us-west-2.amazonaws.com/${process.env.REACT_APP_ENV}/?email=` + encodeURIComponent(props.email));
    let error = false;

    socket.onopen = () => {
        if (process.env.REACT_APP_ENV === 'dev') {
            log('WebSocket Membership Ready!');
        }
    };

    socket.onerror = (event) => {
        if (process.env.REACT_APP_ENV === 'dev') {
            console.error('WebSocket is in error (connection will be closed)', event);
        }
        error = true;
    };

    socket.onmessage = (event) => {
        if (event && event.data) {
            const data = JSON.parse(event.data);
            if (data?.message?.body) {
                const conektaWebhookData = JSON.parse(data.message.body);
                if (conektaWebhookData?.person_id) {
                    if (process.env.REACT_APP_ENV === 'dev') {
                        log('Message from server:', conektaWebhookData);
                    }
                    if (props?.person_id && props.person_id === conektaWebhookData.person_id) {
                        props.updateMembership(conektaWebhookData.membership_id);
                    }
                    if (conektaWebhookData?.action && conektaWebhookData.action === 'notify'){
                        log('Notificacion Zendesk:', conektaWebhookData);
                        store.dispatch(updateZendesk(true));
                    }
                } else {
                    log('Mensaje de notificación', conektaWebhookData);
                }
            }
        }
    };

    // socket.onclose = (event) => {
    //     if (process.env.REACT_APP_ENV === 'dev') {
    //         log(`WebSocket is closed. Event: ${event.type}`);
    //     }
    //     socket = null;
    //     if (error) {
    //         setTimeout(() => conektaWSUpgradeMembership(props), 5000);
    //         error = null;
    //     }
    // };

    socket.onclose = (event) => {
        if (process.env.REACT_APP_ENV === 'dev') {
            log(`WebSocket is closed. Event: ${event.type}`);
        }
        socket = null;
        const reconnect = () => {
            if (navigator.onLine) {
                conektaWSUpgradeMembership(props);
            } else {
                log('Offline, will retry when online.');
                window.addEventListener('online', reconnect, { once: true });
            }
        };
        setTimeout(reconnect, 5000);
    };
};