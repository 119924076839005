import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
//@Material-ui
import { Typography } from '@material-ui/core';
//Style
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    crossedOutPrice: {
        textDecoration: 'line-through'
    },
    textDarkBlack100: {
        color: theme.palette.colors.darkBlack[100]
    },
}))

const MembershipCrossedOutPrice = ({priceToCrossOut, typographyVariant, currencySymbol}) => {
    const classes = useStyles();
    return (
    <Typography className={clsx([classes.crossedOutPrice, classes.textDarkBlack100])} component='span' variant={typographyVariant}>
        antes <CurrencyFormat value={priceToCrossOut} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix={currencySymbol} />
    </Typography>)
}

MembershipCrossedOutPrice.defaultProps  = {
    currencySymbol: '$',
    typographyVariant:'body2'
};

MembershipCrossedOutPrice.propTypes = {
    priceToCrossOut: PropTypes.number.isRequired
}

export default MembershipCrossedOutPrice;