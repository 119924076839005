import React from 'react';
import { useHistory } from 'react-router-dom';

// Shared - Components
import Table from '../../shared/components/Table/Table'
import LoaderContent from '../../shared/components/Loader/LoaderContent';

import { useSelector } from 'react-redux';
import ConfirmDialog from '../../shared/components/ConfirmDialog/ConfirmDialog';
import Toast from '../../shared/components/Toast/Toast';
import dateFormat from '../../design-system/utils/dateFormat';

// @material-ui
import Pagination from '@material-ui/lab/Pagination';
import AddIcon from '@material-ui/icons/Add';
import {
    Box,
    Button,
    Card,
    CardContent,
    MenuItem,
    MenuList,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';

// Icons
import {
    Edit as PencilIcon,
    Delete as DeleteIcon,
    Check,
    Remove,
} from '@material-ui/icons';

import "./Notifications.css";
import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';
//SDK
import { NotificationService } from '@sdk-point/talisis'; 

const NotificationServiceSDK = new NotificationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const items = [
    { to: "/home", label: "Home" },
    { label: "Listado de Notificaciones" },
]

const AdminNotifications = (props) => {
    const { user } = useSelector((state) => state.userReducer);
    const columns = [
        { id: "_title", name: "title", label: "Título", minWidth: 100, align: "left" },
        { id: "_content", name: "content", label: "Contenido", minWidth: 100, align: "left" },
        { id: "_notification_name", name: "notification_name", label: "Tipo de Notificación", minWidth: 100, align: "left" },
        { id: "_url", name: "url", label: "URL", minWidth: 100, align: "left" },
        { id: "_created_date", name: "created_date", label: "Fecha de Creación", minWidth: 100, align: "left" },
        { name: "action_required", label: "Requiere Acción", minWidth: 50, align: "left" },
        { name: "is_critical", label: "Requiere Acción Inmediata", minWidth: 50, align: "left" },
        { id: "_publish_date", name: "publish_date", label: "Fecha de Publicación", minWidth: 100, align: "left" },
    ];
    if (user.is_admin) {
        columns.push({ name: "actions", label: "Opciones", minWidth: 100, align: "left" });
    }

    React.useEffect(() => {
        getAllNotificationsList(page, searchText);
    }, []);

    const history = useHistory();
    const defaultPage = 1;
    const [page, setPage] = React.useState(1);
    const [state, setState] = React.useState({
        error: false,
        loading: true,
        notificationList: [],
        toastProps: {
            message: "",
        },
        totalPages: 1
    })
    const [searchText, setSearchText] = React.useState("");
    const [viewAll, setViewAll] = React.useState(false);

    const [confirmDialogProps, setConfirmDialogProps] = React.useState({
        notification_id: "",
        title: "",
        content: "",
        open: false
    });

    const handleNewNotification = () => {
        props.history.push('/notifications/new')
    }

    const handleInputSearch = async (event) => {
        setState({ ...state, loading: true });
        const text = event.target.value.toLowerCase();
        setSearchText(text);
        await getAllNotificationsList(defaultPage, text, viewAll);
    };

    const handleChangePage = async (event, value) => {
        setState({ ...state, loading: true });
        await getAllNotificationsList(value, searchText, viewAll);
        setPage(value);
    };

    const handleViewAll = async () => {
        setState({ ...state, loading: true });
        const newViewAll = !viewAll;
        setViewAll(newViewAll);
        await getAllNotificationsList(defaultPage, searchText, newViewAll);
    };

    const handleDeleteConfirm = (value) => {
        if (value === true) {
            const deleteRecord = async () => {
                setState({ ...state, loading: true });
                const { notification_id } = confirmDialogProps;
                await NotificationServiceSDK.Delete(notification_id)
                    .then(async response => {
                        setState({ ...state, error: false, loading: false, toastProps: { open: true, message: "La notificación fue eliminada exitosamente" } });
                        window.location.reload();
                    })
                    .catch(function (error) {
                        setState({ ...state, error: true, loading: false, toastProps: { severity: "error", open: true, message: "¡Ops! Ocurrió un error al eliminar la notificación. Inténtalo de nuevo." } });
                    });
            };
            deleteRecord();
        };
    }; 

    const handleDeleteClose = () => {
        setConfirmDialogProps({ ...confirmDialogProps, notification_id: "", open: false });
    };

    const handleFinishedToast = () => {
        const toastProps = { ...state.toastProps, open: false };
        setState({ ...state, toastProps });
        if (state.error) {
            const toastProps = { ...state.toastProps, open: false };
            setState({ ...state, toastProps });
        }
        else {
            props.history.push('/Notifications');
        }
    }

    const getAllNotificationsList = async (page, searchText, newViewAll) => {
        try {
            let allNotificationsList = [];
            if (searchText) {
                allNotificationsList = await NotificationServiceSDK.search(page, searchText, newViewAll);
            }
            else {
                allNotificationsList = await NotificationServiceSDK.getAllList(page, newViewAll);
            }
            allNotificationsList.map(notification => {
                // Título.
                notification._title = notification.title;
                notification.title = <Tooltip title={notification.title} interactive={true}>
                    <div className="">{notification.title}</div>
                </Tooltip>;

                // Contenido.
                notification._content = notification.content;
                notification.content = <Tooltip title={notification.content} interactive={true}>
                    <div className="">{notification.content}</div>
                </Tooltip>;

                // Tipo de Notificación.
                notification._notification_name = notification.name;
                notification.notification_name = <Tooltip title={notification.notification_name} interactive={true}>
                    <span>{notification.notification_name}</span>
                </Tooltip>

                // URL.
                notification._url = notification.url;
                notification.url = notification.url ? <Tooltip title={notification.url} interactive={true}>
                    <div className="AdminNotificationsURL">{notification.url}</div>
                </Tooltip> : <Remove className="AdminNotificationsIcon" />

                // Fecha de Creación.
                notification.created_date = notification.created_date.replace("T", " ").replace("Z", "");
                notification._created_date = notification.created_date;
                notification.created_date = <Tooltip title={dateFormat(notification.created_date, "DD/MM/YYYY HH:mm")} interactive={true}>
                    <nobr><span>{dateFormat(notification.created_date, "DD/MM/YYYY hh:mm A")}</span></nobr>
                </Tooltip>

                // Requiere Acción.
                notification._action_required = notification.action_required;
                notification.action_required = notification.action_required ? <Check className="AdminNotificationsIcon" /> : <Remove className="AdminNotificationsIcon" />;

                // Requiere Acción Inmediata.
                notification._is_critical = notification.is_critical;
                notification.is_critical = notification.is_critical ? <Check className="AdminNotificationsIcon" /> : <Remove className="AdminNotificationsIcon" />;

                // Fecha de Publicación.
                if (notification.publish_date) {
                    notification.publish_date = notification.publish_date.replace("T", " ").replace("Z", "");
                    notification._publish_date = notification.publish_date;
                    notification.publish_date = <Tooltip title={dateFormat(notification.publish_date, "DD/MM/YYYY HH:mm")} interactive={true}>
                        <nobr><span>{dateFormat(notification.publish_date, "DD/MM/YYYY hh:mm A")}</span></nobr>
                    </Tooltip>;
                }
                else {
                    notification._publish_date = notification.publish_date;
                    notification.publish_date = <Remove className="AdminNotificationsIcon" />;
                }

                if (user.is_admin && notification.allow_modify === 1) {
                    const handleEditClick = (e) => {
                        e.stopPropagation();
                        history.push(`notifications/${notification.id}/edit`);
                    };

                    const handleDeleteClick = (e) => {
                        e.stopPropagation();
                        setConfirmDialogProps({ notification_id: notification.id, open: true, title: "Eliminar Notificación", text: "Presione Confirmar para eliminar la notificación." })
                    };

                    notification.actions =
                        <MenuList id="menu-list-grow" role="" style={{ display: "flex" }}>
                            <Tooltip title="Editar" interactive={true}>
                                <MenuItem onClick={handleEditClick}>
                                    <PencilIcon fontSize="small" />
                                </MenuItem>
                            </Tooltip>
                            <Tooltip title="Eliminar" interactive={true}>
                                <MenuItem onClick={handleDeleteClick}>
                                    <DeleteIcon fontSize="small" />
                                </MenuItem>
                            </Tooltip>
                        </MenuList>;
                }

                return (notification);
            });

            const totalPages = (allNotificationsList.length > 0) ? allNotificationsList[0]["total_pages"] : 1;
            setState({ ...state, loading: false, notificationList: allNotificationsList, totalPages: totalPages });
        }
        catch (error) {
            throw new Error(error);
        }
    }

    return (
        <React.Fragment>
            <Breadcrumb items={items} />
            <table cellPadding={0} cellSpacing={0} style={{ marginTop: "-3rem", width: "100%" }}>
                <tbody>
                    <tr>
                        <td align="right" valign="top" colSpan={3} style={{ width: "100%" }}>
                            {user.is_admin && <Button color="primary" startIcon={<AddIcon />} onClick={handleNewNotification} style={{ float: "right" }}>Crear Notificación</Button>}
                        </td>
                    </tr>
                    <tr>
                        <td align="left" valign="bottom" style={{ width: "30%" }}>
                            <TextField label="Buscar:" variant="outlined" className="AdminNotificationsSearchInput" onChange={handleInputSearch} placeholder="por Título, Contenido, Tipo de Notificación, URL" />
                        </td>
                        <td align="left" valign="bottom" style={{ width: "15%" }}>
                            <label className="AdminNotificationsViewAll"><Switch color="primary" onChange={handleViewAll} checked={viewAll} />Ver todo</label>
                        </td>
                        <td align="right" valign="bottom" style={{ width: "55%" }}>
                            <Pagination count={state.totalPages} page={page} onChange={handleChangePage} className="NotificationsNavigationBarTop" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <LoaderContent loading={state.loading} minHeight={200} display="inline-block">
                {!state.loading && state.notificationList.length === 0 ?
                    <Box width="100%">
                        <Card>
                            <CardContent>
                                <Typography variant="subtitle1" align="center">¡No hay notificaciones pendientes por enviar!</Typography>
                                <Typography variant="subtitle2" align="center">Vuelve pronto para ver más actualizaciones.</Typography>
                            </CardContent>
                        </Card>
                    </Box> :
                    <React.Fragment>
                        <Table maxHeight="calc(100vh - 300px)" columns={columns} rows={state.notificationList} />
                        <ConfirmDialog {...confirmDialogProps} onConfirm={handleDeleteConfirm} onClose={handleDeleteClose} />
                        <Toast {...state.toastProps} onFinished={handleFinishedToast} message="La noticia fue eliminada exitosamente" />
                    </React.Fragment>}
            </LoaderContent>
        </React.Fragment>
    );
};

export default AdminNotifications;