import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    logo:{
        height: 32,
        marginRight: 31
     },
     root:{
         background: theme.palette.colors.fillStroke[400], 
         position: "absolute",
         top: props=> props.isLogged ? 65 : 120,
         marginLeft: "auto",
         marginRight: "auto",
         left: "0",
         right: "0",
        '&.floating-menu-top':{
            top: 188
        }
     },
     content:{
         padding: "40px 108px",
     },
     optionMenu:{
         marginBottom: "22px",
         '&:hover': {
             cursor: "pointer",
             color: `${theme.palette.colors.orange[300]} !important`,
         },
     },
     brands:{
         background: theme.palette.colors.fillStroke[300],
         height: 76,
         borderRadius: "0px 0px 8px 8px",
         padding: '0px 108px',
        '& .brands-item':{
             padding: '18px 0px',
             '&.hover':{
                 backgroundColor: theme.palette.white
             }
         }
     },
     divider:{
         height: 'calc(100% - 24px)',
         borderLeft: `1px solid ${theme.palette.colors.fillStroke[200]}`,
         paddingLeft: 16,
     },
     cardAcademia:{
         '& .card-content':{
             width: 95,
             height: 95,
             padding: '8px 10px'
         }
     },
    bttnAcademia:{
        width: 95,
        height: 95,
        padding: '8px 10px',
        '&:hover':{
            background: theme.palette.colors.electricBlue[300],
            border: `0.5px solid ${theme.palette.colors.electricBlue[300]}`,
            color: theme.palette.white
        },
     },
}));