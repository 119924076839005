import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    input: {
        padding: '0px 16px',
        display:'flex',
        flex: 1,
        borderRadius: 8,
        ...theme.styles.inputSearchStyle.input
    },
    [theme.breakpoints.down('xs')]:{
        input: {
            width: '100%'
        },
    },
}));