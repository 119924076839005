import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// routes
import { useHistory } from 'react-router-dom';
import { generatePath } from "react-router";
import * as paths from '../../../../../routes/paths';

// core components
import { Box, Button } from '@material-ui/core';

// style
import useStyle from '../../../../../assets/styles/jss/pages/events/actionEventButtonStyle';

const ActionEventButton = ({fullSize, interestingEvent, onClickInterestingEvent, eventId, showButtonInterest}) => {
    const classes = useStyle();
    const history = useHistory();

    const [loadingInterestingEvent, setLoadingInterestingEvent] = useState(false);

    useEffect(() => {
        setLoadingInterestingEvent(false);
    }, [interestingEvent]);
    

    const handleClickInterestingEvent = (eventId, isInterestingEvent) => () => {
        setLoadingInterestingEvent(true);
        onClickInterestingEvent(eventId, isInterestingEvent)
    };

    const handleClickEventDetail = id => (event) => history.push(generatePath(paths.EVENTOS_DETALLE, {id}));

    return (
        <Box className={clsx(classes.boxContainer, fullSize && classes.detailButonFullSize)}>
            <Button 
                variant='contained'
                className={clsx(classes.detailButton, fullSize && classes.detailButonFullSize)}
                onClick={handleClickEventDetail(eventId)}
                size="medium"
            > 
                Ver detalle
            </Button>
            {
                showButtonInterest &&
                <Button 
                    variant='contained'
                    className={clsx (classes.interestingButton)}
                    size="medium"
                    disabled={loadingInterestingEvent}
                    onClick={handleClickInterestingEvent(eventId, !interestingEvent)}
                > 
                    {
                        interestingEvent ? <i className="ri-bookmark-fill font-size-20"></i> : <i className="ri-bookmark-line font-size-20"></i>
                    }
                </Button>
            }
        </Box>
    )
};

ActionEventButton.defaultProps = {
    showButtonInterest: true,
};

ActionEventButton.propTypes = {
    fullSize: PropTypes.bool.isRequired,
    interestingEvent: PropTypes.bool.isRequired,
    onClickInterestingEvent: PropTypes.func,
    eventId: PropTypes.number.isRequired
};

export default ActionEventButton;