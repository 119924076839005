import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootBase: {
        height: "362px",
        width: "288px",
        minWidth: "288px",
        [theme.breakpoints.down('xs')]: {
            // height: "303px",
            // width: "224px",
            // minWidth: "224px",
            width: "284px",
            height: "313px",
            minWidth: "284px"
        }
    },
}));