import { makeStyles } from '@material-ui/core/styles';
import {SOURCES} from '../../../../../constants/sources';

export default makeStyles((theme) => ({
    rootTwoSectionMedia: {
        width: '100%',
        height: '100%',        
        background: props => `linear-gradient(180deg, rgba(0, 0, 0, 0) 61.46%, rgba(0, 0, 0, 0.71) 100%), url(${props.backgroundImage})`,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        imageRendering: "-webkit-optimize-contrast"
    },
    rootTwoSectionContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',   
        padding: '16px',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    ratingContainer: {
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'flex',
    },
    ratingNumberContainer: {
        width: '27px',
        height: '16px',
        padding: '0 4px',
        marginRight: '6px',
        background: theme.palette.grey[300],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ratingNumber: {
        color: theme.palette.colors.fillStroke[400],
    },
    [SOURCES.ADVENIO] : {
        height: '25px',
        [theme.breakpoints.down('xs')]:{
            height: '18px',
        }
    },
    [SOURCES.NEO] : {
        height: '19px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.COURSERA] : {
        height: '16px',
        [theme.breakpoints.down('xs')]:{
            height: '10px',
        }
    },
    [SOURCES.LINKEDIN_LEARNING] : {
        height: '25px',
        [theme.breakpoints.down('xs')]:{
            height: '17px',
        }
    },
    [SOURCES.TALISIS] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
        }
    },
    [SOURCES.UERRE] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
        }
    },
    [SOURCES.UNID] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
        }
    },
    [SOURCES.HH] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.IESALUD] : {
        height: '19px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
       }
    },
    [SOURCES.GOOGLE] : {
        height: '17px',
        [theme.breakpoints.down('xs')]:{
            height: '17px',
        }
    },
    [SOURCES.CREHANA] : {
        height: '20px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.HUBSPOT] : {
        height: '21px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
        }
    },
    [SOURCES.IE_UNIVERSITY] : {
        height: '36px',
        [theme.breakpoints.down('xs')]:{
            height: '24px',
        }
    },
    [SOURCES.AWS] : {
        height: '30px',
        [theme.breakpoints.down('xs')]:{
            height: '21px',
        }
    },
    [SOURCES.SIMPLICITY] : {
        height: '17px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.CONEKTA] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.AWS_SKILL_BUILDER] : {
        height: '30px',
        [theme.breakpoints.down('xs')]:{
            height: '21px',
        }
    },
}));