import React from 'react';

//@material
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';

// styles
import useStyles from '../../../../../../assets/styles/jss/pages/requests/SendingDocumentsStyle'


function AcceptTerms(props) {
    const {disabled, accept} = props;
    const classes = useStyles();

    return (
        <Card>
            <CardContent>
                <Box mb="19px">
                    <FormControlLabel
                        control={<Checkbox checked={accept} onChange={props.onChangeAccept} name="accept" />}
                        label={
                            <Typography className={classes.terms}>Acepto los Términos, Condiciones y Políticas de Talisis</Typography>
                        }
                        disabled={disabled}
                    />
                </Box>
                <Button variant="contained" color="primary" fullWidth disabled={disabled || !accept} onClick={props.onClickAccept}>Enviar documentos</Button>
            </CardContent>
        </Card>
    );
}

AcceptTerms.defaultProps = {
    
};

export default AcceptTerms;