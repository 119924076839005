import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';

// @material-ui/core components
import Snackbar from '@material-ui/core/Snackbar';

// @material-ui/lab components
import Alert from '@material-ui/lab/Alert';

// styles
import useStyles from '../../../assets/styles/jss/components/toastStyle'

const Toast = React.forwardRef(({severity, open: propOpen, message, duration, onFinished, anchorOrigin, className}, ref) => {    
    const classes = useStyles();
    const [open, setOpen] = useState(propOpen);

    useEffect(() => {
        if (typeof onFinished === 'function') {
            setOpen(propOpen);
        } else {
            setOpen(propOpen);
            if (propOpen) {
                const timer = setTimeout(() => {
                setOpen(false);
                }, duration );
        
                return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
            }
        }
      }, [propOpen, duration, onFinished]);

    const handleClose = (reason) => {
        console.log('handleClose', propOpen)
        if (reason === 'clickaway') {
            return;
        }

        if (typeof onFinished === 'function') {
            onFinished();
        } else {
            setOpen(false);
        }
    };
  
    return (
        <div className={clsx(classes.root, className)}>
            <Snackbar 
                ref={ref}
                anchorOrigin={anchorOrigin }
                open={open} 
                autoHideDuration={duration} 
                onClose={handleClose}
                // TransitionProps={{ onExited: onFinished }}
            >
                <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity} ref={ref}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
});

Toast.defaultProps = {
    open: false,
    severity: 'success',
    duration: 4000,
    anchorOrigin: { vertical: 'top', horizontal: 'right' }
};

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool,
    severity: PropTypes.oneOf([
        "error",
        "info",
        "success",
        "warning",
    ]),
    onFinished: PropTypes.func
};

export default Toast;