import React from 'react';
import clsx from 'clsx';

// @components
import * as MEDIA from '../../components/genericBrandLandingPageMedia';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardMedia, Typography, Button, Box, Hidden, Chip } from '@material-ui/core';

import BrandProgramCardResponsive from './BrandProgramCardResponsive';


const useStyles = makeStyles((theme) => ({
    circularProgress: {
        height: '15px !important',
        width: '15px !important',
    },
    root: {
        width: '100%',
    },
    card: {
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 27.08%, rgba(0, 0, 0, 0.8) 100%), url(''), linear-gradient(212.47deg, #2C3236 0%, #1C1F24 53.48%)`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        borderRadius: '8px 8px 8px 8px',
        '&:hover': {
            background: `url(${''}), linear-gradient(180deg, rgba(0, 0, 0, 0) 27.08%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(147.53deg, #3D3232 0%, #121134 53.48%)`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'right',
        },
        position: 'relative',
    },
    media: {
        height: '450px',
        width: '100%',
    },
    mediaBackground: {
        backgroundSize: 'cover !important',
    },
    headerCardText: {
        left: '0px',
        right: '0px',
        position: 'absolute',
        top: '8%',
        '@media (max-width: 600px)': {
            fontSize: '20px',
        }
    },
    button: {
        background: 'rgba(160, 169, 186, 0.1)',
        border: `1px solid ${theme.palette.colors.white}`,
        boxShadow: '0px 6px 11px rgba(0, 0, 0, 0.1)',
        textTransform: 'none',
        marginBottom: 16,
        '&:hover': {
            border: `1px solid ${theme.palette.colors.white}`,
            background: 'rgba(160, 169, 186, 0.2)',
        }
    },
    program: {
        background: theme.palette.colors.white,
        borderRadius: '4px',
        color: '#39277A',
        fontSize: '14px',
        fontWeight: '600',
        height: '24px',
        left: '0px',
        margin: '0 auto',
        padding: '4px 8px',
        position: 'absolute',
        right: '0px',
        top: '87%',
        width: '6.6rem',
    },
    cardGerenciaGlobal: {
        background: props => `url(${props.imgMBA1}), url(${props.bgMBA1})`,
        '&:hover' : {
            background: props => `linear-gradient(360deg, #FD495C -12.02%, rgba(253, 73, 92, 0) 59.78%), url(${props.imgMBA1}), url(${props.bgMBA1})`,
            content: "none",
        }
    },
    cardMercadotecniaInternacional: {
        background: props => `url(${props.imgMBA2}), url(${props.bgMBA2})`,
        '&:hover' : {
            background: props => `linear-gradient(360deg, #FD495C -12.02%, rgba(253, 73, 92, 0) 59.78%), url(${props.imgMBA2}), url(${props.bgMBA2})`,
            content: "none",
        }
    },
    cardFinanzasInternacionales: {
        background: props => `url(${props.imgMBA3}), url(${props.bgMBA3})`,
        '&:hover' : {
            background: props => `linear-gradient(360deg, #FD495C -12.02%, rgba(253, 73, 92, 0) 59.78%), url(${props.imgMBA3}), url(${props.bgMBA3})`,
            content: "none",
        }
    },
}));

const levelConfig = {
    MBA_1: {
        key: 'MBA_1',
        title: 'Gerencia Global',
        imgResponsive: 'mba-1-card-responsive-uerre.png',
    }, 
    MBA_2: {
        key: 'MBA_2',
        title: 'Mercadotecnia Internacional',
        imgResponsive: 'mba-2-card-responsive-uerre.png',
    }, 
    MBA_3: {
        key: 'MBA_3',
        title: 'Finanzas Internacionales',
        imgResponsive: 'mba-3-card-responsive-uerre.png',
    }
};

const BrandLandingProgramsCardUerre = (props) => {
    const { className } = props;
    const CARD_IMG = MEDIA.getBrandLandingPagesProgramCardImg('u-erre');
    const imgMBA1 = CARD_IMG['MBA_1'];
    const bgMBA1 = CARD_IMG['background']['MBA_1'];
    const imgMBA2 = CARD_IMG['MBA_2'];
    const bgMBA2 = CARD_IMG['background']['MBA_2'];
    const imgMBA3 = CARD_IMG['MBA_3'];
    const bgMBA3 = CARD_IMG['background']['MBA_3'];

    const classes = useStyles({imgMBA1, bgMBA1, imgMBA2, bgMBA2, imgMBA3, bgMBA3});

    const handleClick = (id) => {
        const container = document.getElementById(id);
        if (container) {
            container.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Box>
            <Hidden id="desktop" xsDown>
                <Typography variant="h3" align="center" className={'offset-mb-6'}>Conoce nuestros MBA's en sus 3 acentuaciones</Typography>
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" className={className}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={classes.card}>
                            <Typography variant="h4" className={classes.headerCardText}>Gerencia Global</Typography>
                            <CardMedia
                                className={clsx(classes.cardGerenciaGlobal, classes.media, classes.mediaBackground)}
                                component="div"
                                title="Bachillerato"
                            />
                            <Box position='absolute' bottom={24} width={1}>
                                <Box width={117} margin='0 auto'>
                                    <Button variant="outlined" size={'small'} color="primary" className={classes.button} onClick={() => handleClick('maestria')}>
                                        <Typography variant="body2" className='semi-bold'>Ver más</Typography>
                                    </Button>
                                    <Chip className='chip1-white' label='100% Online' />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={classes.card}>
                            <Typography variant="h4" className={classes.headerCardText}>Mercadotecnia<br />Internacional</Typography>
                            <CardMedia
                                className={clsx(classes.cardMercadotecniaInternacional, classes.media, classes.mediaBackground)}
                                component="div"
                                title="Licenciatura"
                            />
                            <Box position='absolute' bottom={24} width={1}>
                                <Box width={117} margin='0 auto'>
                                    <Button variant="outlined" size={'small'} color={'primary'} className={classes.button} onClick={() => handleClick('maestria')}>
                                        <Typography variant="body2" className='semi-bold'>Ver más</Typography>
                                    </Button>
                                    <Chip className='chip1-white' label='100% Online' />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={classes.card}>
                            <Typography variant="h4" className={classes.headerCardText}>Finanzas<br />Internacionales</Typography>
                            <CardMedia
                                className={clsx(classes.cardFinanzasInternacionales, classes.media, classes.mediaBackground)}
                                component="div"
                                title="Posgrado"
                            />
                            <Box position='absolute' bottom={24} width={1}>
                                <Box width={117} margin='0 auto'>
                                    <Button variant="outlined" size={'small'} color={'primary'} className={classes.button} onClick={() => handleClick('maestria')}>
                                        <Typography variant="body2" className='semi-bold'>Ver más</Typography>
                                    </Button>
                                    <Chip className='chip1-white' label='100% Online' />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Hidden>

            <Hidden id="mobil" smUp>
                <Typography variant="h3" align="left" className="offset-mb-8">Conoce nuestros MBA's en sus 3 acentuaciones</Typography>

                <Box display="flex" flexDirection="column" gridGap="16px">
                    {
                        Object.keys(levelConfig).map(level => (
                                <BrandProgramCardResponsive
                                    brand="u-erre" 
                                    bgImage={`/images/brands/${levelConfig[level].imgResponsive}`}
                                    title={levelConfig[level].title}
                                    textBttn="Ver más"
                                    textBadge="100% Online"
                                    onClickBttn={() => handleClick('maestria')}
                                    degraded="linear-gradient(180deg, rgba(253, 73, 92, 0) 46.75%, #FD495C 136.59%)"
                                />
                            )
                        )
                    }
                </Box>
            </Hidden>
        </Box>
    )
};

export default BrandLandingProgramsCardUerre;