import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Slider from "react-slick";

import './testimonios.scss';
import { CardContent, CardMedia, Card, IconButton, Hidden } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useStyles from '../../../../src/assets/styles/jss/pages/simplicity/testimoniesStyle'
import { log } from '../../../shared/utils/console.js'


class Selectors extends React.Component {
	_handleClick(e) {
		if (this.props.id !== this.props.activeID) {
			this.props._changeActive(this.props.id);
		} else {
			return;
		}
	}
	render() {
		return (
			<div className="selectors">
				{this.props.data.map((item) => 
					<Selector 
						key={item.id}
						id={item.id}
						_handleClick={this._handleClick}
						_changeActive={this.props._changeActive}
						activeID={this.props.activeID}
					/>
				)}
			</div>
		);
	}
}
class Selector extends React.Component {
	render() {
		let componentClass = 'selector';
		if (this.props.activeID === this.props.id) {
			componentClass = 'selector active';
		}
		return (
			<div className={componentClass} onClick={this.props._handleClick.bind(this)}></div>
		);
	}
}

const data = [
    {
        id: 0,
        desc:<Typography variant="body1" className="semi-bold">
        Los egresados Talisis cuentan con las competencias y habilidades que el mercado solicita.<br/><br/>Son personas que saben trabajar en equipo y se adaptan a las necesidades de la organización.​
        </Typography>,
        icon: '/images/simplicity/icon-adeco.svg',
        caption: 'The Adeco Group, Líder en soluciones de Recursos Humanos'
    },
    {
        id: 1,
        desc:<Typography variant="body1" className="semi-bold">
        La productividad y buena actitud son valores que diferencian a un egresado Talisis.<br/><br/>Están bien preparados, cuentan con mucha experiencia práctica.​
        </Typography>,
        icon: '/images/simplicity/icon-bbva.svg',
        caption: 'BBVA, Líder en soluciones de Recursos Humanos'
    },
    {
        id: 2,
        desc:<Typography variant="body1" className="semi-bold">
        Los egresados Talisis se caracterizan por su liderazgo y la innovación que presentan en sus labores diarias.
        </Typography>,
        icon: '/images/simplicity/icon-hsbc.svg',
        caption: 'HSBC, Líder en soluciones de Recursos Humanos'
    },
    {
        id: 3,
        desc:<Typography variant="body1" className="semi-bold">
        Los alumnos Talisis son grandes colaboradores en la organización, son muy proactivos y demuestran valores afines con nuestra empresa.
        </Typography>,
        icon: '/images/simplicity/icon-hisense.svg',
        caption: 'Hisense, Líder en soluciones de Recursos Humanos'
    },
]

const experiences = [
    {
        title: '“Realicé mi Experiencia Práctica en la empresa XCARET: XPLOR.”',
        desc: 'Me permitió sugerir al parque la creación de las bici-balsas, actividad recreativa para los visitantes, proyecto que fue aprobado y me fue destinado presupuesto para su creación e implementación en el parque.',
        logo: "/images/simplicity/logo-xcaret.svg",
        name: 'Franchesca Pernillo López',
        subtitle: 'Estudiante de Admon. de Empresas Turísticas',
        bg: "/images/simplicity/media-xcaret.jpg"
    },
    {
        title: '“Todo comenzó con las experiencias prácticas que realicé en la empresa Coca Cola”',
        desc: 'Al finalizar fui contratado para dirigir el proyecto “Tiendas Milenio” cuyo objetivo es salvar a las tiendas de abarrotes tradicionales a través de apoyos por parte de la organización.',
        logo: "/images/simplicity/logo-coca.svg",
        name: 'Carlos Castro',
        subtitle: 'Egresado de Admon. y Dir. de Empresas',
        bg: "/images/simplicity/media-coca-cola.jpg"
    },
    {
        title: '“Realice mi Experiencia Práctica en la organización de GreenPeace México”',
        desc: 'Desarrollé un prototipo de la plataforma Whatsapp Business, en el área de recaudación para el registro de nuevos socios. Durante mi estancia, conseguí que otras organizaciones como UNICEF me ofrecieran una oferta económica para comprar mi proyecto”.​',
        logo: "/images/simplicity/greenpeace-icon.svg",
        name: 'Maximiliano Díaz Bonilla',
        subtitle: 'Campus Campeche',
        bg: "/images/simplicity/greenpeace.png"
    },
    {
        title: '“Realice mi Experiencia Práctica en la empresa Honeywell”',
        desc: 'La herramienta basada en realidad aumentada integrada dentro de app móviles de Honeywell me dio la oportunidad de viajar a Boston, Massachusetts para validar su implementación en la convención de tecnología de LiveWorx con expertos”.',
        logo: "/images/simplicity/honeywell.svg",
        name: 'Félix Alfonso Covarrubias Pérez',
        subtitle: 'U-ERRE, Campus Querétaro',
        bg: "/images/simplicity/honeywell.png"
    },
]

const settings = {
    // dots: true,
    arrows: false,
    infinite: true,
    slidestoshow: 1,
    slidestoscroll: 1,
    vertical: true,
    verticalSwiping: true,
    // beforeChange: function(currentSlide, nextSlide) {
    //   log("before change", currentSlide, nextSlide);
    // },
    // afterChange: function(currentSlide) {
    //   log("after change", currentSlide);
    // }
};

const settingsH = {
    arrows: false,
    infinite: true,
    // slidestoshow: 2,
    slidestoscroll: 2
};

const Testimonies = () => {
    const classes = useStyles();
    const sliderQuoteRef = React.useRef(null);
    const sliderVerticalRef = React.useRef(null);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const [value, setValue] = React.useState(0);
    const [activeID, setActiveID] = React.useState(0);


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const _changeActive = (id) => {
        sliderVerticalRef.current.slickGoTo(id)
		setActiveID(id);
	}

    const onPrevious = () => {
        sliderQuoteRef.current.slickPrev();
    };

    const onNext = () => {
        sliderQuoteRef.current.slickNext();
    };

    const onBeforeChange = (currentSlide, nextSlide) => {
        log('nextSlide', nextSlide)
        _changeActive(nextSlide)
    };

    return (
        <Box>
            <Container>
                <Box className={classes.root}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={5}>
                            <Box className={classes.slide_title}>
                                <Box className="title">
                                    <Typography variant="h2">Conoce la opinión de <Typography variant="inherit" color="primary">nuestros</Typography> clientes.</Typography>
                                    <Hidden smDown>
                                        <Divider className="bg-fill-stroke-200"/>
                                    </Hidden>
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box className={classes.dots}>
                                    <Box position="relative" height="100%" width={30} display="flex" alignItems="center">
                                        <Selectors 
                                            data={data}
                                            activeID={activeID}
                                            _changeActive={_changeActive.bind(this)}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <Slider {...settings} 
                                        ref={sliderVerticalRef}
                                        beforeChange={onBeforeChange}
                                    >
                                        {
                                            data.map((it, index) =>                                                
                                                <Box key={uuid()} className={classes.slide}>
                                                    <Box className={classes.slide_opinion}>
                                                        {it.desc}
                                                    </Box>
                                                    <Box display="flex">
                                                        <Box mr={2}><img alt="logo" src="/images/icons/comment-fill.svg" /></Box>
                                                        <Box>
                                                            {/* <Box>icon</Box> */}
                                                            <img src={it.icon} />
                                                            <Typography variant="caption" className="semi-bold text-dark-black-200">
                                                            {it.caption}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    </Slider>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box display="flex" justifyContent="end" flexDirection="column">
                                <Box className={classes.slider_quote}>
                                    <Slider ref={sliderQuoteRef} {...settingsH} slidestoshow={isResponsive ? 1 : 2}>
                                        {
                                            experiences.map(it =>
                                                <Box padding="12px" key={uuid()} >
                                                    <Card variant="outlined" className={classes.card}>
                                                        <CardMedia image={it.bg} component={Box} height={80}/>
                                                        <CardContent className={classes.cardContent}>
                                                            <img src="/images/simplicity/quote.svg" className={classes.quote}/>
                                                            <Box>
                                                                <Typography color="primary" variant="body1" className="semi-bold offset-margin-bottom-8">
                                                                {it.title}
                                                                </Typography>
                                                                <Typography variant="body2" className="text-gray-100">
                                                                {it.desc}
                                                                </Typography>
                                                            </Box>
                                                            <Divider className="bg-fill-stroke-200"/>
                                                            <Box marginTop="auto">
                                                                <img src={it.logo} />
                                                                <Typography variant="body2" className="semi-bold text-gray-100">{it.name}</Typography>
                                                                <Typography variant="caption" className="text-dark-black-200">{it.subtitle}</Typography>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            )
                                        }
                                    </Slider>
                                </Box>
                                <Box display="flex" justifyContent={isResponsive ? "center" : "end"} padding="12px">
                                    <IconButton
                                        onClick={onPrevious}
                                        variant="outlined"
                                        color="secondary"
                                        className="border-button offset-margin-right-18">
                                        <Icon className="ri-arrow-left-line" style={{fontSize:14}}/>
                                    </IconButton>
                                    <IconButton
                                        onClick={onNext}
                                        variant="outlined"
                                        color="secondary"
                                        className="border-button"
                                    >
                                        <Icon className="ri-arrow-right-line" style={{fontSize:14}}/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider className="bg-fill-stroke-200"/>
            </Container>
        </Box>
    );
};

export default Testimonies;