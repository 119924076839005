import React, { Component } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import clsx from 'clsx';
// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Card, CardContent} from '@material-ui/core';
// core components
import CourseProgress from './CourseProgress/CourseProgress'
import { v4 as uuid } from 'uuid';
// shared - components
import Tabs from '../../../../shared/components/Tabs/Tabs'
import CourseInfo from '../../../../shared/components/CourseContent/CourseInfo';
import SourceDetail from '../../../../shared/components/CourseContent/SourceDetail';
import CourseCardMedia from '../components/CourseCardMedia';
import SkillsList from '../../../../shared/components/CourseContent/SkillsList';
import CourseInformation from './CourseInformation/CourseInformation';
//import BlockCourseInfo from '../../../../shared/components/CourseContent/BlockCourseInfo';
import BlockCourseRating from '../../../../shared/components/CourseContent/BlockCourseRating';
//import BlockLessonInfo from '../../../../shared/components/CourseContent/BlockLessonInfo';
import BlockHelpInfo from '../../../../shared/components/CourseContent/BlockHelpInfo';
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';
import icon_right_row from '../../../../assets/images/logos/landing-icons/microgrados/icon_right_row.svg';
import { makeStyles } from '@material-ui/core/styles';
import * as routelinks from '../../../../routes/routelinks'
//import dateFormat from '../../../../design-system/utils/dateFormat';
//import { COMPLETE_DATE_FORMAT } from '../../../../design-system/constants/date-formats';

//import * as coursesService from '../../../../services/CoursesService'
import { AllysService, CourseService } from '@sdk-point/talisis'; 
import getConnectionAllies from '../../../../shared/components/Allies/AlliesConnect'
import { log } from '../../../../shared/utils/console.js'

import { convertSourceToImageResource } from '../../../../../src/constants/placeholderImages';
import { convertOuToSource }from '../../../../../src/constants/convertOuToSource';

import SvgIcon from '@material-ui/core/SvgIcon';
import SaveUnsaveButton from './SaveUnsaveButton/SaveUnsaveButton';

const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

/*const allyLabel = [
    {
        allyId: 1,
        allyName: "NEO",
        allyRedirect: "https://talisis.neolms.com/",
        allySSO: 1,
        source_id: 1,
    },
    {
        allyId: 2,
        allyName: "Coursera",
        allyRedirect: "https://es.coursera.org/?authMode=login",
        allySSO: 0,
        source_id: 5,
    },
    {
        allyId: 3,
        allyName: "LinkedIn",
        allyRedirect: "https://www.linkedin.com/learning/",
        allySSO: 0,
        source_id: 6,
    },
    {
        allyId: 4,
        allyName: "Crehana",
        allyRedirect: "https://www.crehana.com/api/authentication/saml/talisis/sso/",
        allySSO: 1,
        source_id: 13,
    },
    {
        allyId: 5,
        allyName: "HubSpot",
        allyRedirect: "https://academy.hubspot.com/es/",
        allySSO: 0,
        source_id: 14,
    },
    {
        allyId: 6,
        allyName: "Crack the Code",
        allyRedirect: "https://www.crackthecode.la/login",
        allySSO: 0,
        source_id: false,
    },
    {
        allyId: 7,
        allyName: "SkillShop",
        allyRedirect: "https://skillshop.withgoogle.com/",
        allySSO: 12,
        source_id: false,
    },
    {
        allyId: 8,
        allyName: "AWS Academy",
        allyRedirect: "https://aws.amazon.com/es/training/awsacademy",
        allySSO: 0,
        source_id: false,
    },
];*/

const useStyles = makeStyles((theme) => ({
    continueButton:{
        maxWidth: "334px",
        height: "38px",
        width: "100%",
        textTransform: "inherit",
        marginBottom: "16px",
        '& .MuiButton-label':{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "baseline",
        }
        
    },
    icon_right_row:{
        marginLeft: "13.48px",
    },
    linearProgress:{
        '& .MuiLinearProgress-barColorPrimary': {
            backroundColor: theme.palette.primary.main + " !important",
        },
    },
    contentCardImageProgress:{
        maxWidth: "392px",
        width: "100%",
    },
    backButton:{
        marginBottom: "24px",
    },
    blockCourseRating:{
        marginBottom: "16px",
    },
    subscribe:{
        borderRadius: '8px',
        fontWeight: '400',
        fontSize: '14px',
        height: '40px',
        lineHeight: '40px',
        padding: "0px",
    },
    markerButton: {
        backgroundColor: theme.palette.colors.grey[400],
        fontWeight: "bold",
        flexGrow: 1,
        fontSize: "13px",
        minWidth: '20px',
        padding: "0px 5px",
        marginLeft: "7px",
    },
}));
const DetailCoursesInProgress = (props) => {
    const classes = useStyles({});
    const user = useSelector((state) => state.userReducer.user);
    const [activeTab, setActiveTab] = React.useState(0);
    const { id } = useParams();
    const [data, setData] = React.useState(null);
    const [courseRating, setCourseRating] = React.useState(null);
    const [userNeo, setUserNeo] = React.useState({userid:null, password:null, neoLink: null});
    const [loading, setLoading] = React.useState(false);
    const [tabs, setTabs] = React.useState([
        {
            tab: 'Progreso del curso',
            content: <CourseProgress data={[]} />,
        },
        {
            tab: 'Información del curso',
            content: <CourseInformation data={[]}/>,
        },
        /*{
            tab: 'Calificaciones y Reseñas',
            content: <Box></Box>, 
        },
        {
            tab: 'Anuncios',
            content: <Box></Box>,
        },*/
    ]);
    const history = useHistory();
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        let isMounted = true;
        getDetailCourse().then(async (resp) => {
            if (isMounted) {
                setCourseRating(resp?.response?.course_rating != null ?  resp?.response?.course_rating : 0);
                setData(resp?.response);
                setTabs(resp?.tabs);
                setLoading(false);
            } 
        })
        return () => { isMounted = false };
    }, [data]);

    const getDetailCourse = async () =>{
        setLoading(true);
        const response = await courseService.getEnrolledCourseDetailByPersonId(id, user.person_id);
        let tabs = [];
        if(response?.modules?.length != 0){
            tabs.push(
                {
                    tab: 'Progreso del curso',
                    content: <CourseProgress data={response?.modules} />,
                }
            );
        }
        
        if(response?.description_short != null && response?.tools.length != 0 && data?.advantages.length != 0 && data?.requirements.length != 0 && data?.instructor_full_name != null){}else{
            tabs.push(
                {
                    tab: 'Información del curso',
                    content:  <CourseInformation data={{...response}}/>,
                }
            );
        }
        return {tabs: tabs, response: response}
    }

    const handleSubscribe = () => {
        log("user will subscribe")
    };

    const handleChangeTab = (activeTab) => {
        setActiveTab(activeTab);
    }

    const handleClickGoBack = (e) => {
        e.preventDefault();
        history.push(routelinks.CURSOS);
    }
    
    const handleClickGoTo = async (e, course) => {
        e.preventDefault();

        const body = {
            person_id: user?.person_id,
            email: user?.email,
            ou: user?.ou,
            course_url: data.course_url,
            source_id: data.source_id,
            course_url_neo: data.course_url_neo,
            course_id: data.course_id,
            company_id: data.company_id
        }


        const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + data.source_id, "_blank");
        var urlRespuesta = await getConnectionAllies(body);
            setTimeout(() => {
                win.focus();
                win.location.replace(urlRespuesta);
            }, 3000);
    }
    
    return (
        <Box style={{position: "relative" }}> 
            {/*<Box style={{ backgroundColor: "black", position: "absolute", width: "100vw", height: "100%", top: "0", left: "0px" }}></Box>*/}
            {<LoaderContent loading={loading} transparent={true} style={{ minHeight: "calc(150vh - 450px)" }}>
               
                <Button startIcon={<NavigateBeforeIcon fontSize="small" color="primary"/>} onClick={(e) => handleClickGoBack(e)} className={classes.backButton}>
                    <Typography variant="h5" color="primary">Regresar a cursos en progreso</Typography>
                </Button>
                {data != null && <Grid container>
                    <Grid container item xs={8} style={{paddingRight: 32}}>
                        <Grid item xs={12}>
                            <CourseInfo name={data?.name} description={data?.description}>
                                <SourceDetail modality={data?.modality_id} sourceId={convertOuToSource(data.company_id, data.source_id)} lessons={data?.total_modules}/>
                            </CourseInfo>
                            <Tabs
                                style={{marginTop: "32px"}}
                                onChange={(value) => handleChangeTab(value)}
                                activeTab={activeTab}
                                tabs={tabs.map(it => it?.tab) }
                            >
                                
                                {
                                    tabs[activeTab]?.content
                                }
                            </Tabs>
                        </Grid>
                        
                    </Grid>
                    <Grid container item xs={4} style={{paddingLeft: 32}}>
                        <Grid item className={ classes.contentCardImageProgress }>

                            <Card component={Box} variant="outlined" mb={2} maxWidth="392px">
                                <CourseCardMedia 
                                    lazy={true}
                                    minHeight="225px"
                                    image={`${data?.image_url != null ? data?.image_url: convertSourceToImageResource(data?.company_id, data?.source_id)}`}
                                />
                                <LinearProgress className={classes.linearProgress} variant="determinate" value={data?.progress_percentage != null ? data?.progress_percentage : 0} />
                            </Card>

                            

                            {/*data.schedule && data.schedule.nextClassDate && <BlockLessonInfo mb={2}
                                title="Próxima Lección"
                                date={data.schedule && data.schedule.nextClassDate}
                                time={data.schedule && data.schedule.nextClassTime}/>*/}
                            
                            <Card mb={2} variant="outlined" component={Box} {...props} maxWidth="392px">
                                <CardContent className="p-4">
                                    {
                                        data?.enrollment_status_id != 3 || data?.enrollment_status_id != null ? <Typography variant="h4" className="mb-2">En espera de confirmación</Typography> : <Typography variant="h4" className="mb-2">Continua aprendiendo</Typography>}
                                   { 
                                    data?.enrollment_status_id == 1 ? 
                                        <Box display="flex">
                                            <Button variant="outlined" className={classes.subscribe} style={{  textTransform: 'none', width: "85%" }} color='primary' fullWidth endIcon={
                                                <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z" />
                                                </SvgIcon>
                                            } onClick={handleSubscribe}>Ir a curso</Button>

                                            {<SaveUnsaveButton course={data} classes={classes}/>}
                                        </Box>
                                        :
                                        <Box display="flex">
                                            <Button onClick={(e) => handleClickGoTo(e, data)}  style={{  width: "85%", textTransform: "initial" }} variant="contained" color="primary">{"Ir a curso"}<img alt="logo" src={icon_right_row} className={classes.icon_right_row}/></Button>
                                            {<SaveUnsaveButton course={data} classes={classes}/>}
                                        </Box>
                                    }

                                </CardContent>
                            </Card>

                            <BlockCourseRating progress={data?.progress_percentage} enrollment_status_id={data?.enrollment_status_id == null ? 3 : data?.enrollment_status_id} isDetail={true} status={courseRating} userId={user?.person_id} courseId={data?.id} index={uuid()}/>
                            
                            <Box mt={2} maxWidth="392px">
                                {/*<BlockCourseInfo
                                    mb={2}
                                    badge={{title: data.modality_id != null ? modalityOptions[data.modality_id] : modalityOptions[2], color: "blue", icon: 'desktop_mac'}}
                                    description={data.modality_description}/>*/}
                                {data?.skills != null && <SkillsList items={data?.skills} propLabel="text" mb={2}/>}
                                <BlockHelpInfo/>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>}
            </LoaderContent>}
        </Box>
    );
}

export default DetailCoursesInProgress;
