import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        borderBottomWidth: '1px',
        borderBottomColor: theme.palette.colors.fillStroke[200],
        borderBottomStyle: 'solid',
        width: '100%',
        padding: '0 16px'
    },
    separatorLine: {
        marginLeft: '18px',
        marginRight: '18px',
        color: theme.palette.colors.fillStroke[200],
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    closeIconButton: {
        color: theme.palette.colors.white,
    },
    iconFilterContainer: {
        [theme.breakpoints.down('md')]: {
            display: props => props.showFilterButton ? 'block' : 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
}));