import { log } from '../../../../../shared/utils/console.js'

const valueValidator = (value) =>{
    if (value == null || value == 'undefined'){
        let Fixed= 'Sin informacion';
        return Fixed;
    }
    return value;
}

export const downloadSyllabus = ( downloadableContent ) => {
    if(downloadableContent.length === 0 )
        return log("No hay informacion disponible");

    const { name, modality_name, description, subjects, tools, benefits, incentives } = downloadableContent;     

    var printView = window.open('', 'PRINT', 'height=400,width=600');

    printView.document.write('<html><head><title>' + valueValidator(name) + '</title>');
    printView.document.write(`</head><body style="font-family: 'Source Sans Pro', sans-serif">`);
    printView.document.write('<h2 style="padding-top: 30px;">' + valueValidator(name) + '</h2> </br>');

    printView.document.write('<h3>Detalles del programa</h3>');
    printView.document.write(`<p><table style="border: none; width: 100%;">`);

    printView.document.write(`<tr><td><b>Modalidad:</b> ${valueValidator(modality_name)}</td></tr></table></p></br>`);

    printView.document.write('<h3>Introducción</h3>');
    printView.document.write('<p>' + valueValidator(description) + '</p></br>');

    printView.document.write('<h3>Materias del programa</h3>');
    subjects.forEach((unidad, index) => {
        printView.document.write(`<p style="margin-bottom: 8px;">${index + 1}.- ${valueValidator(unidad.name)}</p>`);
    });

    printView.document.write('<h3 style="margin-top: 50px;margin-bottom: 8px;">Herramientas necesarias</h3>');
    tools.forEach((tool) => {
        printView.document.write(`<span style="padding-left:15px;">- ${valueValidator(tool.description)}</span></br>`);
    });

    printView.document.write('<h3 style="margin-top: 50px;margin-bottom: 8px;">Beneficios</h3>');
    benefits.forEach((benefit) => {
        printView.document.write(`<span style="padding-left:15px;">- ${valueValidator(benefit.description)}</span></br>`);
    });

    printView.document.write('<h3 style="margin-top: 50px;margin-bottom: 8px;">¿Qué incluye?</h3>');
    incentives.forEach((incentive) => {
        printView.document.write(`<span style="padding-left:15px;">- ${valueValidator(incentive.description)}</span></br>`);
    });

    printView.document.write('</body></html>');

    printView.document.close(); // necessary for IE >= 10
    printView.focus(); // necessary for IE >= 10*/

    printView.print();
    printView.close();

    return true;
};
