import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    checkBox: {
        padding: "4px 9px"
    }
}));

const OptionType = (props) => {
    const classes = useStyles();
    const data = props.data ? { ...props.data } : {};
    const values = props.values ? [...props.values] : [];

    const handleToggle = (option) => () => {
        let currentIndex = values.findIndex(a => a.id === option.id);
        let newChecked = [...values];

        if (currentIndex === -1) {
            if (!data.is_multiple) {
                newChecked = [];
            }

            newChecked.push(option);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        props.onChange(newChecked);
    };

    React.useEffect(() => {
        if (!(props.values && props.values.length > 0)) {
            let options = data.options.filter(o => !o.disabled);
            if (options.length === 1) {
                handleToggle(options[0])();
            }
        }
    }, []);

    return (
        <List className={classes.root}>
            {data.options.map((option) => {
                const labelId = `checkbox-list-label-${option.id}`;
                const checked = values.findIndex(a => a.id === option.id) !== -1;

                return <ListItem key={option.id} selected={checked} disabled={option.disabled} role={undefined} dense button onClick={handleToggle(option)}>
                    <ListItemIcon style={{ minWidth: 12 }}>
                        <Checkbox
                            edge="start"
                            checked={checked}
                            tabIndex={-1}
                            disableRipple
                            className={classes.checkBox}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={option.name} secondary={option.description} />
                </ListItem>
            })}
        </List>
    )
}

export default OptionType;