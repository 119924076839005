import React from 'react'

import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';


const BoxContent = ({ mt, mb, ml, mr, color, value, ...rest}) => {
    const theme = useTheme();
    
    return (
        <Grid item md={12} xs={12}>
            <Card className={color ? "PointCard-outlined-"+ color : "PointCard-outlined-fillStroke-400"} style={{ marginTop: mt ? mt:"0px", marginBottom:  mb ? mb:"0px", marginLeft:  ml ? ml:"0px", marginRight:  mr ? mr:"0px", background: color == "transparent" ? "transparent": ""}}>
                <CardContent {...rest}>
                    <Grid item xs={12}>
                        {value}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default BoxContent;
