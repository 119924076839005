import React from 'react'

import { Typography } from '@material-ui/core'
import SummaryStep from '../../SummaryStep/SummaryStep'
import MembershipPeriods from '../../MembershipPeriods/MembershipPeriods'
import { useSelector } from 'react-redux'

const MembreshipPeriodsWorkFlow = (props) => {

    const { admisionId, addPendingDiscount, addDaysToNextPayment, percentOff, 
        addLabelNextPaymentDay, addNewDifferenceAmountFees, setSelectMembershipPaymentStep,
        setPeriodo, membershipPlan, currentFeePlan, nextStep
    } = props;

    // Checkout Reducer
    const { order: orderReducer, userCurrentMembership: userCurrentMembershipReducer } = useSelector((state) => state.legacyCheckoutReducer);

    return (
        <>
            <SummaryStep
                isActive
                modifyBtnAction={() => {}}
                stepTitle={"Periodo de pago"}
                membershipPlan={membershipPlan}
                component={
                    <MembershipPeriods
                        admisionId={admisionId} setPeriodo={setPeriodo} addPendingDiscount={addPendingDiscount}
                        addDaysToNextPayment={addDaysToNextPayment} percentOff={percentOff} addLabelNextPaymentDay={addLabelNextPaymentDay} 
                        addNewDifferenceAmountFees={addNewDifferenceAmountFees} 
                        summaryStep={3} 
                        // setSelectMembershipPaymentStep={setSelectMembershipPaymentStep} currentFeePlan={orderReducer?.conceptos[indexForOneOrTwoArticles]} 
                        setSelectMembershipPaymentStep={setSelectMembershipPaymentStep} currentFeePlan={ currentFeePlan } 
                        dayPrice={orderReducer?.day_price} daysToNextPayment={orderReducer?.days_to_next_payment} userCurrentMembership={userCurrentMembershipReducer} 
                        nextStep={nextStep}
                    />
                    // <MembershipPeriods
                    //     admisionId={admisionId} setPeriodo={setPeriodo} addPendingDiscount={addPendingDiscount} 
                    //     addDaysToNextPayment={addDaysToNextPayment} percentOff={percentOff} addLabelNextPaymentDay={addLabelNextPaymentDay} 
                    //     addNewDifferenceAmountFees={addNewDifferenceAmountFees} freeCoupon={freeCoupon} order={order} setOrder={setOrderRef} 
                    //     user={user} membershipFeePlans={membershipFeePlans} setSelectMembershipPaymentStep={setSelectMembershipPaymentStep} 
                    //     currentFeePlan={currentFeePlan} dayPrice={order?.day_price} 
                    //     daysToNextPayment={order?.days_to_next_payment} userCurrentMembership={userCurrentMembership} 
                    //     orderQty={orderQty} setOrderQty={setOrderQty} 
                    //     summaryStep={3} 
                    //     nextStep={nextStep}
                    // />
                }
            />
            <SummaryStep
                isActive={false} modifyBtnHidden
                stepNumber={1} stepTitle={"Metodos de Pago"}
            />
        </>
    )
}

export default MembreshipPeriodsWorkFlow
