import React from 'react';
import clsx from 'clsx';

// @material-ui
import { Box, Grid, ListItem, Typography } from "@material-ui/core";

// shared
import { IMAGE_DEFAULT_PLACEHOLDER } from '../../../../shared/constants/source-images';

// assets
import useStyles from '../../../../assets/styles/jss/pages/checkout/checkout';

const OrderItem = ({ detail }) => {
    const classes = useStyles();

    return (
        <Box>
            <ListItem disableGutters className={classes.orderItem}>
                <Grid container spacing={3}>
                    <Grid item>
                        <Box height={64} width={64}>
                            <img alt={detail.product_name} src={detail.product_image_url || IMAGE_DEFAULT_PLACEHOLDER} height={64} width={64} className={classes.orderItemImage}></img>
                        </Box>
                    </Grid>
                    <Grid item alignContent='center' className={classes.orderItemContent}>
                        <Typography variant='h5' className={clsx("font-weight-700 mb-1", classes.orderItemDescription)}>{detail.product_name}</Typography>
                        <Typography variant='body2' className={clsx("text-dark-black-200", classes.orderItemDescription)}>{detail.product_description}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
        </Box>
    );
}

export default OrderItem;