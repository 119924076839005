const UserObject = () => {
    const listPropertiesUser= [
        {   name:'id',
            label:'ID',
            type:'number',
            length: 100,
            readOnly:true
        },
        {   name:'first_name',
            label:'Nombres',
            required: true,
            type:'text',
            length: 100,
        },
        {   name:'last_name',
            label:'Apellidos',
            required: true,
            type:'text',
            length: 100, 
        } ,
        {
            name: 'email',
            label: 'Correo electrónico',
            required: true,
            type:'email',
            length: 100,
            visibility:'none'
        },
        {   name:'gender',
            label:'Género',
            required: true,
            type:'select',
            length: 100,
            readOnly:false,
            disabled:false,
            items:[{id:'F', label:'Femenino'},
                   {id:'M', label:'Masculino'}]
        },
        {
            name:'referred_by',
            label:'Referenciado',
            type:'text',
            length: 100,
            visibility:'none'
        },
        {   name:'bill_to',
            label:'bill_to',
            type:'text',
            length: 100,
            visibility:'none'
        },
        {   name:'about_me',
            label:'about_me',
            type:'text',
            length: 100,
            visibility:'none'
        } ,
        {   name:'activity_date',
            label:'activity_date',
            type:'text',
            length: 100,
            visibility:'none'
        },
        {   name:'user_id',
            label:'Matrícula',
            type:'text',
            readOnly:true,
            length: 100,
        },
        {   name:'password',
            label: 'password', 
            type:'text',
            length: 100, 
            visibility:'none'
        },
        {   name:'password_token',
            label: 'password_token',
            required: false,
            type:'text',
            length: 100, 
            visibility:'none'
        },
        {   name:'external_id',
            label:'external_id',
            required: false,
            type:'number',
            length: 100,
            disabled: false,
            visibility:'none'
        },
        {   name:'role_id',
            label:'role_id',
            type:'number',
            length: 100,
            visibility:'none'
        },
    ]
    return listPropertiesUser; 
}

export default UserObject;