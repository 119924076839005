import { makeStyles } from '@material-ui/core/styles';

const border = '2px solid rgba(49, 193, 222, 0.5)';
const padding = '3px 3px';
const minWidth = 35;

export default makeStyles((theme) => ({
    decrement: {
        'border-right': 0,
        padding,
        minWidth, 
        '&:hover':{
            border,
            'border-right': 0,
        },
        '&.disabled':{
            color: '#31c1de59',
        }
    },
    quantity: {
        cursor: 'auto',
        'border-right': 0,
        'border-left': 0,
        padding,
        minWidth,       
        '&:hover':{
            'background': 'transparent',
            border,
            'border-right': 0,
            'border-left': 0,
        }

    },
    increment: {
        'border-left': 0,
        padding,
        minWidth,
        '&:hover':{
            border,
            'border-left': 0,
        }
    },
}));