import React, { lazy, Suspense, useContext, useReducer } from 'react';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { convertOuToSource }from '../../../../src/constants/convertOuToSource';

// core components
import Loader from '../../../shared/components/Loader/LoaderContent';
import SlickCarousel from '../../../shared/components/SlickCarousel/SlickCarousel';
import TitleLink from '../../../shared/components/TitleLink/TitleLink';
import getConnectionAllies from '../../../shared/components/Allies/AlliesConnect';
import { Mixpanel } from '../../../shared/components/Mixpanel/mixpanel';
import { log } from '../../../shared/utils/console.js'

import { SwiperSlide } from 'swiper/react/swiper-react.js';

// styles
import useStyles from '../../../assets/styles/jss/pages/courses/coursesInProgressStyle';

// services
import { CourseService, AllysService, HelpCenterService, LogsService } from '@sdk-point/talisis';
import coursesReducer, { initialCourses, types } from '../../../shared/components/CourseCarousel/context/CoursesReducer';
import CoursesProvider from '../../../shared/components/CourseCarousel/context/CoursesProvider';
import { Button } from '@material-ui/core';
import DemoCourses from './components/DemoCourses';
import NoCoursesFound from './components/NoCoursesFound';

const CourseProgressCardBody = lazy(() => import(/* webpackChunkName: "CourseProgressCardBody" */'./components/CourseProgressCardBody'));
const getOU = (email) => {
    let ouId;
    const domain = email.substring(email.lastIndexOf("@") + 1);
    switch (domain) {
        case 'red.unid.mx' || 'unid.mx' :
            ouId = 'UNID'
            break;
        case 'u-erre.mx' || 'u-erre.edu.mx' || 'iesalud.edu.mx':
            ouId='UERRE'
            break;
        case 'talisis.com':
            ouId = 'TAL'
            break;
        default:
            break;
    } 
    
    return ouId;
}

const CoursesInProgress = (props) =>  {
    const classes = useStyles();
    const { title, user, handleSetChargeStatusForOptimize, handleChangeTabInProgress } = props;
    const [loading, setLoading] = React.useState(false);
    const [userHasCourses, setUserHasCourses] = React.useState(true);
    const [allies, setAllies] = React.useState(null)
    const [hiddeCarouselByEmpty, setHiddeCarouselByEmpty] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [isRedirected, setIsRedirected] = React.useState(false)
    const [ticketSended, setTicketSended] = React.useState(false)
    const [coursesSysError, setCoursesSysError] = React.useState(false);
    const [lengthCarousel, setLengthCarousel] = React.useState(12);
    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const logsServiceSDK = new LogsService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const HelpCenterServiceSDK = new HelpCenterService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const [coursesState, dispatch] = useReducer(coursesReducer, initialCourses);
    const [ecCoursesState, ecDispatch] = useReducer(coursesReducer, initialCourses);
    const [programCoursesState, programDispatch] = useReducer(coursesReducer, initialCourses);
    const  otherCourses  = coursesState.courses;
    const  ecCourses = ecCoursesState.courses;
    const  programCourses  = programCoursesState.courses;

    const getShortcutsAllies = async () => {
        try {
            var request = {
                person_id : user.person_id
            }
            const shortcuts = await AllysServiceSDK.getShortcutsAllies(request);
            
            let neoAllies = [];
            neoAllies = shortcuts.filter(ally => ally.company_id === 'UERRE' || ally.company_id === 'UNID');
            if(neoAllies.length===0){
                let error;
                error= {
                    reason: 'No encontramos relacion del alumno con cursos - no allies'
                }
                handleCourseSysError(error)
                const log = { "person_id": user.person_id, "errortype_id": 4, "errororigin_id": 1 }
                await logsServiceSDK.logError(log)     
                log('validacion usuarios 1')
                const neoUser = await AllysServiceSDK.validateNeoUser({studentid: user.email, ou: getOU(user.email) }) 
                if(!neoUser.neo_id){
                    error = {
                        reason: 'Alumno no encontrado en Neo',
                        end: true
                    }
                    handleCourseSysError(error)
                    const log = { "person_id": user.person_id, "errortype_id": 3, "errororigin_id": 1 }
                    await logsServiceSDK.logError(log)     
                    setLoading(false)
                } else {
                    const ally = {
                        "source_id": 4,
                        "company_id": user.company_id,
                        "AllyName": "NEO"
                    };
                    log(ally)
                    await handleGoToAllyNeo(ally)
                    // setAllies(ally)
                    setLoading(false)
                    // setIsRedirected(true)
                    log('redirigido si 95')
                }
                
            } else {
                log(neoAllies)
                setLoading(false);
                if(neoAllies.length < 2){
                    setIsRedirected(true)
                    log('redirigido si 103')
                    await handleGoToAllyNeo(neoAllies[0])
                }
                else {
                    setAllies(neoAllies);
                }
                
            }
               
        }
        catch (err) {
            log(err)   
        }
        
    }

    const handleCourseSysError = async (error) => {    
        const eventdata = {
            userId: user.person_id,
            message: error.reason,
            unidadOperativa: user.ou
        }
        //falta migrar evento a SDK
       
        if (error.end) {
            setCoursesSysError(true) 
        }
        log(error)
        
    }

    const getData = async () => {
        try {
            let coursesData = [];
            setLoading(true);
            
            const data = await courseService.getPersonCoursesInProgress(user.person_id);
            if(data?.response?.length > 0){
                coursesData = data.response.slice();
            } else {
                setUserHasCourses(false)
            }
            setLoading(false);
            handleSetChargeStatusForOptimize("progress");
            return coursesData;
        } catch (e) {
            log(e.message);
        }
    };

    React.useEffect(() => {
        let isMounted = true;
        getData().then(async (data) => {
            //log("Data progress");
            //log(data);
            if (isMounted) {
                let coursesTemp = [];
                let coursesEC = [];
                let coursesProgram = [];
                setLengthCarousel(data?.length);
                if(data?.length == 0){
                    setHiddeCarouselByEmpty(true);
                }else{
                    setHiddeCarouselByEmpty(false);
                }
                const temp = data?.map(item =>{
                    if(item?.level_id == "EC"){
                        coursesEC.push(item);
                    }else if(item?.level_id == "Otros"){
                        coursesTemp.push(item);
                    }else{
                        coursesProgram.push(item);
                    }
                });
                
                dispatch({ type: types.addCourses, payload: coursesTemp});
                ecDispatch({ type: types.addCourses, payload: coursesEC});
                programDispatch({ type: types.addCourses, payload: coursesProgram});
                /*hhDispatch({ type: types.addCourses, payload: hhCoursesTemp});
                uerreDispatch({ type: types.addCourses, payload: uerreCoursesTemp});
                unidDispatch({ type: types.addCourses, payload: unidCoursesTemp});
                advenioDispatch({ type: types.addCourses, payload: advenioCoursesTemp});
                talisisDispatch({ type: types.addCourses, payload: talisisCoursesTemp});
                ieDispatch({ type: types.addCourses, payload: ieCoursesTemp});*/
                //log(coursesTemp);
            } 
            //log(uerreCourses);
        })
        return () => { isMounted = false };
    }, []);


    React.useEffect(() => {
        if (allies) {
            log(allies)
            setLoading(false)
        }
    }, [allies])
    
    const handleGoToNeo = async () => {
        setLoading(true);
        await getShortcutsAllies();
        setOpen(true);
    }

    const handleGoToAllyNeo = async (ally) => {
        setLoading(true)
        log('AAALY',ally)
        log('validacion usuarios 2')
        const neoUser = await AllysServiceSDK.validateNeoUser({studentid: user.email, ou:getOU(user.email) })
        if(neoUser.neo_id) {
            log(neoUser)
            const neoCourses = await AllysServiceSDK.getNeoCoursesByUser({studentid: neoUser.neo_id, ou:getOU(user.email) });
            log(neoCourses)
            if(neoCourses.length > 0) {
                const body = {
                    person_id: user.person_id,
                    email: user.email,
                    ou: getOU(user.email),
                    source_id: ally.source_id,
                    company_id: getOU(user.email)
                }
                const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + convertOuToSource(ally.company_id), "_blank");
        
                var urlRespuesta = await getConnectionAllies(body);
                log('respuesta allies', urlRespuesta)
                setTimeout(() => {
                    win.focus();
                    win.location.replace(urlRespuesta);
                }, 3000);
                setLoading(false)
                setIsRedirected(true)
                log('redirigido si 236')
            } else {
                log('No cursos en Neo')
                const error = {
                    reason: 'Sin Cursos en Neo',
                    end: true
                }
                setIsRedirected(false)
                log('redirigido NO')
                setOpen(true)
                handleCourseSysError(error)
                const log = { "person_id": user.person_id, "errortype_id": 1, "errororigin_id": 1 }
                await logsServiceSDK.logError(log)     
                setLoading(false)
            }
        } else {
            const error = {
                reason: 'Alumno no encontrado en Neo',
                end: true
            }
            handleCourseSysError(error)
            const log = { "person_id": user.person_id, "errortype_id": 3, "errororigin_id": 1 }
            await logsServiceSDK.logError(log)     
            setLoading(false)
        }
    }
    
    const handleSendTicket = async () => {
        setLoading(true)
        log('Send ticket')
        const userReq = {
            user: {
              email: user.email,
              name: user.userName
            }
          }
        const zndeskUser = await HelpCenterServiceSDK.getZendeskUser(userReq).then(usr => usr);
        const ticketPayload = {
            ticketData: {
              zendeskId: zndeskUser.id,
              comment: 'No se muestran los cursos en progreso de NEO en la plataforma Talisis',
              subject: 'Error - No se muestran los cursos',
              custom_fields: [
                {
                  id: "360048350694",
                  value: user.student_id ? user.student_id : ''
                },
                {
                  id: '8667463356439',
                  value: 'plataformas_clase'
                },
                {
                  id: '8529666934935',
                  value: 'neo'
                }
              ],
              tags: [
                "plataforma",
                "web",
                "talisis"
              ]
            }
          }
          const ticket = await HelpCenterServiceSDK.postTicket(ticketPayload).then(tckt => tckt)
          setLoading(false)
          log(ticket)
          setTicketSended(true)
    }
    const noCoursesProps = {
        handleGoToNeo,
        handleGoToAllyNeo,
        allies,
        coursesSysError,
        setCoursesSysError,
        handleSendTicket,
        open,
        setOpen,
        loading,
        ticketSended,
        isRedirected
    }
    return (
        <>
        
        {  <CoursesProvider>
            {  <Loader loading={loading} transparent={true} minHeight="290px" width={ !loading ? "100%" : "95%" } style={{ marginLeft: "auto", marginRight: "auto" }}>
                {
                    !userHasCourses &&
                    <NoCoursesFound { ...noCoursesProps }/>
                }
                 
                <Box className={classes.root}>
                    <DemoCourses user={user} />
                    {programCourses?.length > 0 && <Box position="relative">
                        {  programCourses.map((program, index) => {
                            return (<Box className={classes.hover_carousel} key={program.id}>
                                {<Typography className={classes.title} variant="h4"><TitleLink textPrimary={(program.company_id == "UERRE" ? "U-ERRE": program.company_id == "TAL" ? "TALISIS" : program.company_id == "IE" ? "IESALUD" :  program.company_id == "HH" ? "Harmon Hall": program.company_id == "ADV" ? "ADVENIO": program.company_id == "UNID" ? "UNID" : "OTROS") + " - " +  program.name} textSecondary="Ver todos" onClick={(e) => handleChangeTabInProgress(e)}/></Typography>}
                                <SlickCarousel length={program?.courses.length} typeCard="normal" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                                    {
                                        program?.courses.length > 0 && program.courses.map((item, index) => {
                                            let courseItem = JSON.parse(item);
                                            log(courseItem);
                                            return (<SwiperSlide key={courseItem?.course_id} className={classes.card}>
                                                        <Suspense fallback={<div></div>}>
                                                            <CourseProgressCardBody
                                                                course_url_neo={courseItem?.course_url_neo}
                                                                source_id={courseItem?.source_id} 
                                                                course_url={courseItem?.course_url}
                                                                enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                                course_id={courseItem?.course_id}  
                                                                company_id={courseItem?.company_id}
                                                                course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                                user={user}
                                                                progress_percentage={courseItem?.progress_percentage}
                                                                media={{
                                                                    isProgress: true,
                                                                    index: index,
                                                                    source:{
                                                                        id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                        icon: 'desktop_windows',
                                                                        modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                                    },
                                                                    image: courseItem?.image_url,
                                                                    company_id: courseItem?.company_id,
                                                                }}
                                                                content={{
                                                                    isProcess: true,
                                                                    course_id: courseItem?.course_id,
                                                                    progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                                    enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                                    title: courseItem?.name,
                                                                    hiddeLabel: true,
                                                                }} 
                                                            />
                                                        </Suspense>
                                                    </SwiperSlide>)
                                        })
                                    }
                                </SlickCarousel>
                            </Box>)
                        })}
                    </Box>}

                    { ecCourses?.length > 0 &&  <Box position="relative" >
                    {  ecCourses.map((ecCourse, index) => {
                            return (<Box className={classes.hover_carousel} key={ecCourse.id}>
                    {<Typography className={classes.title} variant="h4"><TitleLink textPrimary={ecCourse.name != "Educación Continua Alianzas" ? (ecCourse.company_id == "UERRE" ? "U-ERRE": ecCourse.company_id == "TAL" ? "TALISIS" : ecCourse.company_id == "IE" ? "IESALUD" :  ecCourse.company_id == "HH" ? "Harmon Hall": ecCourse.company_id == "ADV" ? "ADVENIO": ecCourse.company_id == "UNID" ? "UNID" : "OTROS") + " - " + ecCourse.name : ecCourse.name} textSecondary="Ver todos" onClick={(e) => handleChangeTabInProgress(e)}/></Typography>}
                    <SlickCarousel length={ecCourse?.courses.length} typeCard="normal" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                            {
                                ecCourse?.courses.map((item, index) => {
                                    let courseItem = JSON.parse(item);
                                    //log(courseItem);
                                    return (<SwiperSlide key={courseItem?.course_id} className={classes.card}>
                                                <Suspense fallback={<div></div>}>
                                                    <CourseProgressCardBody
                                                        course_url_neo={courseItem?.course_url_neo}
                                                        source_id={courseItem?.source_id} 
                                                        course_url={courseItem?.course_url}
                                                        enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                        course_id={courseItem?.course_id}  
                                                        company_id={courseItem?.company_id}
                                                        course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                        user={user}
                                                        progress_percentage={courseItem?.progress_percentage}
                                                        media={{
                                                            isProgress: true,
                                                            index: index,
                                                            source:{
                                                                id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                icon: 'desktop_windows',
                                                                modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                            },
                                                            image: courseItem?.image_url,
                                                            company_id: courseItem?.company_id,
                                                        }}
                                                        content={{
                                                            isProcess: true,
                                                            course_id: courseItem?.course_id,
                                                            progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                            enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                            title: courseItem?.name,
                                                            hiddeLabel: true,
                                                        }} 
                                                    />
                                                </Suspense>
                                            </SwiperSlide>)
                                })
                            }
                        </SlickCarousel>
                        </Box>)
                        })}
                    </Box>}
                    
                    { otherCourses[0]?.courses?.length > 0 && <Box position="relative" className={classes.hover_carousel}>
                    {title && <Typography className={classes.title} variant="h4"><TitleLink textPrimary={"Otros cursos"} textSecondary="Ver todos" onClick={(e) => handleChangeTabInProgress(e)}/></Typography>}
                    <SlickCarousel length={otherCourses[0].courses?.length} typeCard="normal" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                            {
                                otherCourses[0].courses.map((item, index) => {
                                    let courseItem = JSON.parse(item);
                                    //log(courseItem);
                                    return (<SwiperSlide key={courseItem?.course_id} className={classes.card}>
                                                <Suspense fallback={<div></div>}>
                                                    <CourseProgressCardBody
                                                        course_url_neo={courseItem?.course_url_neo}
                                                        source_id={courseItem?.source_id} 
                                                        course_url={courseItem?.course_url}
                                                        enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                        course_id={courseItem?.course_id}  
                                                        company_id={courseItem?.company_id}
                                                        course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                        user={user}
                                                        progress_percentage={courseItem?.progress_percentage}
                                                        media={{
                                                            isProgress: true,
                                                            index: index,
                                                            source:{
                                                                id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                icon: 'desktop_windows',
                                                                modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                            },
                                                            image: courseItem?.image_url,
                                                            company_id: courseItem?.company_id,
                                                        }}
                                                        content={{
                                                            isProcess: true,
                                                            course_id: courseItem?.course_id,
                                                            progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                            enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                            title: courseItem?.name,
                                                            hiddeLabel: true,
                                                        }} 
                                                    />
                                                </Suspense>
                                            </SwiperSlide>)
                                })
                            }
                        </SlickCarousel>
                    </Box>}
                </Box>
                </Loader>
            }
        </CoursesProvider>}
        </>
    );
}

CoursesInProgress.defaultProps = {
    lazyLoading: false,
    disableTitleAction: false,
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CoursesInProgress);
