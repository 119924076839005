import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';

// Constants
import {SOURCES, SOURCE_NAME} from '../../constants/sources';
import {DEFAULT, WHITE, WHITE_COLOR, SIMPLE_WHITE, GREY, BLACK, BLACK_COLOR} from '../../constants/color-images';

// Utils
import {getLogoBySource} from '../../utils/getLogo';


const LogoSource = ({sourceId, color, className, style, disabledAutoRes, responsive}) => {
    const tempIsResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isResponsive = responsive || (!disabledAutoRes && tempIsResponsive);
    const logo = getLogoBySource(sourceId, color, isResponsive);

    if(!logo) return null;

    return (
        <img alt={`logo${'-'+SOURCE_NAME[sourceId]}`} src={logo} className={clsx(className, 'source-image')  } style={style}/>
    );
};

LogoSource.propTypes = {
    sourceId: PropTypes.oneOf(Object.values(SOURCES)).isRequired,
    color: PropTypes.oneOf([WHITE, DEFAULT, WHITE_COLOR, SIMPLE_WHITE, GREY, BLACK, BLACK_COLOR]),
    className: PropTypes.any,
    responsive: PropTypes.bool,
    disabledAutoRes: PropTypes.bool,
};

LogoSource.defaultProps = {
    color: DEFAULT,
    responsive: false,
    disabledAutoRes: false,
    style:{}
};

export default LogoSource;
