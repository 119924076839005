import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// MUI
import Fab from '@material-ui/core/Fab';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//SDK
import { HelpCenterService } from '@sdk-point/talisis'; 

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return ({
    fileButton: {
      width: 64,
      height: 64,
      backgroundColor: theme.palette.colors.fillStroke[300],
      color: theme.palette.colors.white,
      borderRadius: 8
    },
    writeTicketContainer: {
      padding: '0 16px'
    },
    writeTicketTextBox: {
      marginTop: '8px',
      '& div': {
        backgroundColor: theme.palette.colors.fillStroke[400],
        padding: '16px'
      },
      '& :hover': {
        backgroundColor: theme.palette.colors.fillStroke[300],
        transition: 'none'
      },
      '& :focus': {
        backgroundColor: theme.palette.colors.fillStroke[300],
        transition: 'none'
      },
      '& :focus-within': {
        backgroundColor: theme.palette.colors.fillStroke[300],
      }
    },
    fileContainer: {
      width: '48px',
      display: 'inline-grid',
      height: '64px',
      overflow: 'hidden',
      marginLeft: '8px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    fileIcon:{
      width: '48px',
      height: '48px',
      backgroundColor: theme.palette.colors.orange[300],
      fontSize: '24px',
      padding: '12px',
      lineHeight: 'initial'
    },
    fileName: {
      fontSize: '10px',
      color: theme.palette.colors.orange[300],
      lineHeight: 'unset',
      textOverflow: 'ellipsis',
    },
    subTitle:{
      paddingTop: '10px'
    }
  })
});

const WriteTicket = (props) => {
  const { 
    attachments,
    setAttachments,
    message,
    setMessage,
    LoaderContent,
    loading,
    setLoading,
    setDisableSend
  } = props;

  const HelpCenterServiceSDK = new HelpCenterService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
  
  
  const handleFileUpload = async (e) => {
    setLoading(true)
    const uploadFile = await HelpCenterServiceSDK.uploadFile(e.target.files);
    setAttachments([... attachments, uploadFile])
    setLoading(false)
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
    const msg = e.target.value
    if(msg.length && msg.length > 0) {
      setDisableSend(false)
    } else {
      setDisableSend(true)
    }
  }

  useEffect(() => {
    if(message.length === 0) setDisableSend(true)
  }, [])
  
  
  const classes = useStyles();
  return (
    <LoaderContent loading={loading}>
      <Box className={classes.writeTicketContainer}>
        <Typography variant='subtitle2' className={classes.subTitle}>Incluye una descripción y capturas de pantalla para resolver tu problema de manera más rápida y eficaz.</Typography>
        <TextField
          multiline
          placeholder='Descripción'
          variant='filled'
          minRows={4}
          fullWidth
          value={message}
          className={classes.writeTicketTextBox}
          InputProps={{ disableUnderline: true }}
          onChange={handleMessageChange}
        />
        <Box mt={1}>
          <Typography variant='subtitle2'>Adjuntar archivos</Typography>
          <Box mt={1}>
            <IconButton
              variant='contained'
              component='label'
              className={classes.fileButton}
            >
              <i className={'ri-add-line'}></i>
              <input type='file' hidden onChange={handleFileUpload} />
            </IconButton>
            {
              attachments.map(file => (
                <Box className={classes.fileContainer}>
                  <Box className={classes.fileIcon}>
                    <i className={'ri-image-fill'}></i>
                  </Box>
                  <Typography className={classes.fileName}>{file.file_name}</Typography>
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
    </LoaderContent>
  )
};

WriteTicket.defaultProps = {
  isHome: false,
  isCheckout: false,
};

WriteTicket.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};


export default WriteTicket;
