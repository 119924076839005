import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// core components
import { Card, CardMedia } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// components
import DetailDataEvent from './components/DetailDataEvent';

// style
import useStyles from '../../../../../assets/styles/jss/pages/events/eventCardStyle';

const EventCard = ({event, onClickInterestingEvent, showButtonInterest, size}) => {
    const classes = useStyles({size, eventImage: event.image});

    return (
        <Card className={classes.cardContainer}>
            <CardMedia className={clsx(classes.image, size === 'small' && classes.darkImage)} image={`${event.image}`} title="Image">
                {
                    size === 'small' && 
                    <Box className={classes.containerDetailResponsive}>
                        <DetailDataEvent event={event} onClickInterestingEvent={onClickInterestingEvent} showButtonInterest={showButtonInterest} size={size}/>
                    </Box>
                }
            </CardMedia>
            {
                size !== 'small' &&
                <Box sx={{height: "201px"}}>
                    <Box className={classes.containerDetailNoneResponsive}>
                        <DetailDataEvent event={event} onClickInterestingEvent={onClickInterestingEvent} showButtonInterest={showButtonInterest} size={size}/>
                    </Box>
                </Box>
            }
        </Card>
    );
};

EventCard.defaultProps = {
    showButtonInterest: true,
    size: 'large', 
};

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
    onClickInterestingEvent: PropTypes.func
};

export default EventCard;