import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootAllResult: {
    },
    quickAccessContainer: {
        marginTop: '32px',
    },
    cardActionArea: {
        position: "relative",
        height: '315px !important',
    },
    quickAccessLabel: {
        color: theme.palette.colors.white,
    },
    quickAccessListContainer: {
        marginTop: '20px',
    },
    programContainer: {
        marginTop: '41px',
    },
    programLabel: {
        color: theme.palette.colors.white,
    },
    programListContainer: {
        marginTop: '20px',
        justifyContent: props => props.isResponsive ? 'center' : 'start',
    },
    courseContainer: {
        marginTop: '41px',
    },
    courseLabel: {
        color: theme.palette.colors.white,
    },
    coursesListContainer: {
        marginTop: '20px',
        justifyContent: props => props.isResponsive ? 'center' : 'start',
    },
    showAllQuickAccessContainer: {
        marginTop: '20px',
    },
    showAllQuickAccessLink: {
        color: theme.palette.colors.orange[300],
    },
    showAllProgramContainer: {
        marginTop: '20px',
    },
    showAllProgramLink: {
        color: theme.palette.colors.orange[300],
    },
    showAllCourseContainer: {
        marginTop: '20px',
    },
    showAllCourseLink: {
        color: theme.palette.colors.orange[300],
    },
    card: {
        padding: "12px",
        maxWidth: "fit-content",
    },
}));