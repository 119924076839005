import React from 'react';
// redux
import { connect } from "react-redux";

// @material-ui/core
import { makeStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';


import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import readXlsxFile from 'read-excel-file';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// shared components
import Loader from '../../../../../shared/components/Loader/LoaderContent';
import InputFile from '../../../../../shared/components/Form/InputFile';
import Toast from '../../../../../shared/components/Toast/Toast';
import { log } from '../../../../../shared/utils/console.js'

import colors from '../../../../../design-system/theme/palette';

//SDK - services
import { MarketPlaceService } from '@sdk-point/talisis';
const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

const useStyles = makeStyles((theme) => ({
    mainPOP:{
        position: "fixed",
        width: "900px",
        height: "435px",
        padding: "20px",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
        background: theme.palette.dark.dark,
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        border: "2px solid " + theme.palette.primary.main,
    },
    close:{
        position:"absolute",
        right: "-18px",
        top: "-18px",
        fontSize: "50px"
    },
    mainTable:{
        width: "100%",
        height: "100%"
    },
    titlesTable:{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        overflowX: "scroll"
    },
    title:{
        marginLeft: "5px",
        marginRight: "5px"
    },
    buttonsContainer:{
        marginTop: "auto",
        justifyContent: "flex-end"
    },
    loader:{
        top: "0",
        left: "0",
        pointerEvents: "none",
        height: "100%",
        width: "100%",
        position: "absolute"
    },
    dataGrid:{
        '& .super_header': {
            backgroundColor: colors.primary.main,
        },
    }
}));

const ActualizarProgramas = (props) => {
    const { handleClickclosePOP }  = props;
    const classes = useStyles();
    const [fileName, setFileName] = React.useState("Abrir archivo excel");
    const [rows, setRows] = React.useState([]);
    const [rowsToSend, setRowsToSend] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [loadMessage, setLoadMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [uploaded, setUploaded] = React.useState(false);
    const [empty, setEmpty] = React.useState(false);
    const [toastProps, setToastProps] = React.useState({
        message: "",
        open: false
    });
    
    const handleChargeExcel = (e) => {
        let { value } = e.target;
        setFileName(value.name);
        loadXlsxFile(value);
    };

    const changeFileCardRow = (id, value, name) => {
        setRows((prevRows) =>{
            return prevRows.map((row) =>
                row.id === id ? { ...row, [name]: value.target.value } : row,
            );
        });
    }

    const closeMessage = () => {
        setEmpty(false);
        setLoading(false);
    };

    const columsData = React.useMemo(
        () => [
            {   
                field: 'company_id',
                headerName: 'Marca',
                width: 150
            },
            {
                field: 'third_party_id',
                headerName: 'Clave',
                width: 200
            },
            {
                field: 'name',
                headerName: 'Nombre',
                width: 200
            },
            {
                field: 'estimated_time',
                headerName: 'Duración',
                width: 200
            },
            {
                field: 'description',
                headerName: 'Introducción',
                width: 200
            },
            {
                field: 'skills',
                headerName: 'Competencias',
                width: 200
            },
            {
                field: 'objetives',
                headerName: '¿Qué aprenderás?',
                width: 200
            },
            {
                field: 'benefits',
                headerName: 'Beneficios',
                width: 200
            },
            {
                field: 'incentives',
                headerName: '¿Qué incluye?',
                width: 200
            },
            {
                field: 'candidate_profile',
                headerName: 'Perfil de ingreso',
                width: 200
            },
            {
                field: 'graduation_profile',
                headerName: 'Perfil de egreso',
                width: 200
            },
            {
                field: 'work_field',
                headerName: 'Campo de trabajo',
                width: 200
            },
            {
                field: 'tools',
                headerName: 'Herramientas necesarias',
                width: 200
            },
        ],
        [changeFileCardRow],
    );

    const loadXlsxFile = (file) => {
        readXlsxFile(file).then((data) => {
            let rowsOb = [];
            data.map((datas, i) => {
                if(i > 0){
                    rowsOb.push({
                        id: i,
                        company_id: datas[0], 
                        third_party_id: datas[1], 
                        name: datas[2],
                        estimated_time: datas[5], 
                        description: datas[7], 
                        skills: datas[8], 
                        objetives: datas[10], 
                        benefits: datas[11], 
                        incentives: datas[12], 
                        candidate_profile: datas[13], 
                        graduation_profile: datas[14],
                        work_field: datas[15], 
                        tools: datas[16],
                    });
                }
            });

            setRows(rowsOb);
            setColumns(columsData);
        });
    }

    const validate = (third_party_id, name, index, size) => {
        if (!third_party_id) {
            setLoadMessage( `Error agregue la clave del programa: ${name} ${index+1}/${size}`);
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if(rowsToSend.length > 0){
            try{
                let isError = 0;
                let dataStructToSend = {"data":[], "is_program": true};
                //log(rowsToSend);
                setLoadMessage(`Verificando los datos...`);
                for(let i = 0; i < rowsToSend.length; i++){
                    if (validate(rowsToSend[i].third_party_id, rowsToSend[i].name, i, rowsToSend.length)) {
                        setLoadMessage(`Programa con datos correctos: ${rowsToSend[i].name} - ${i+1}/${rowsToSend.length}`);
                        dataStructToSend.data.push({
                            "company_id": rowsToSend[i]?.company_id,
                            "third_party_id": rowsToSend[i]?.third_party_id,
                            "name": rowsToSend[i]?.name,
                            "estimated_time": rowsToSend[i]?.estimated_time,
                            "description": rowsToSend[i]?.description,
                            "detail":{
                                "skills": rowsToSend[i].skills ? rowsToSend[i].skills.split('*').filter(n => n) : null,
                                "objetives": rowsToSend[i]?.objetives,
                                "benefits": rowsToSend[i].benefits ? rowsToSend[i].benefits.split('*').filter(n => n) : null,
                                "incentives": rowsToSend[i].incentives ? rowsToSend[i].incentives.split('*').filter(n => n) : null,
                                "candidate_profile": rowsToSend[i]?.candidate_profile,
                                "graduation_profile": rowsToSend[i]?.graduation_profile,
                                "work_field": rowsToSend[i]?.work_field,
                                "tools": rowsToSend[i].tools ? rowsToSend[i].tools.split('/').filter(n => n) : null,
                            }
                        });
                    }else{
                        isError++;
                    }
                }
            
                if(isError == 0){
                    setLoadMessage(`Actualizando programas...`);
                    await marketService.newCourseBulk(JSON.stringify(dataStructToSend));
                    setLoadMessage(`Programas actualizados correctamente.`);
                    setUploaded(true);
                    setLoading(false);
                }else{
                    setLoading(false);
                    setEmpty(true);
                }
            }catch(e){
                log(e);
                setLoadMessage(`Error: ${e}`);
            }
            
        }else{
            setLoading(false);
            setLoadMessage(`No hay programas para actualizar, asegurate de seleccionarlos.`);
            setEmpty(true);
        }
    }

    const handleFinishedToast = () => {
        setToastProps(prev => { return { ...prev, open: false } });
    };

    return (
            <Box>
            {
                uploaded == false ?
                <Box className={classes.mainPOP}>
                    <Box className="mb-2">
                        <Grid item xs={12} md={12}>
                            <InputFile
                                accept="xlsx"
                                placeholder={fileName}
                                btnText="xlsx"
                                name="xlsx_file"
                                onChange={(e) => handleChargeExcel(e)} />
                        </Grid>
                    </Box>
                    <Loader loading={loading} message={loadMessage} className={classes.loader}></Loader>
                    {empty && <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                        <Typography variant="subtitle1" className="mb-2 mt-2">{loadMessage}</Typography>
                        <Button style={{ width: "150px" }} color="primary" onClick={() => closeMessage()} variant="contained">Aceptar</Button>
                    </Box> }
                    {
                        rows.length > 0 && !loading && !empty &&
                        <Box className={classes.mainTable + " mb-2"}>
                            {
                                columns.length > 0 &&
                                <DataGrid
                                    disableColumnMenu
                                    className={classes.dataGrid}
                                    rows={rows}
                                    columns={columns}
                                    colResizeDefault={'shift'}
                                    pageSize={25}
                                    rowsPerPageOptions={[25]}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(ids) => {
                                        const selectedIDs = new Set(ids);
                                        const selectedRows = rows.filter((row) =>
                                            selectedIDs.has(row.id),
                                        );
                                        setRowsToSend(selectedRows);
                                    }}
                                />
                            }
                        </Box>
                    }
                    {!empty && <Box display="flex" className={classes.buttonsContainer}>
                        <Box className="px-2">
                            <Button className='bg-orange-300' onClick={() => handleClickclosePOP("cancel")} variant="contained">Cancelar</Button>
                        </Box> 
                        <Box>
                            <Button className='bg-violet-300' onClick={handleSubmit} variant="contained" style={{ textTransform: 'none', height: "100%" }}>Actualizar programas</Button>
                        </Box>
                    </Box>}
                </Box>:
                <Box className={classes.mainPOP} alignItems="center">
                    <CheckCircleIcon htmlColor={colors.success.main} style={{ fontSize: "66px" }} />
                    <Typography variant="subtitle2" className={classes.title}>{rowsToSend.length} programas han sido actualizados exitosamente.</Typography>
                    <Box className="py-3">
                        <Button color="primary" onClick={() => handleClickclosePOP("acept")} variant="contained">Aceptar</Button>
                    </Box> 
                </Box>
            }
                
            <Toast {...toastProps} onFinished={handleFinishedToast} duration={3000} />
            </Box>
        
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(ActualizarProgramas);