import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    boxRoot: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '32px',
        gap: '16px',
        height: '494px',
        width: '596px',
        borderRadius: '16px',
        background: theme.palette.colors.fillStroke[400],
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            padding: '16px',
            height: 'auto',
        }
    },
    profilePic: {
        display: 'flex',
        alignItems: 'center',
    },
    boxTittle: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]:{
            paddingBottom: '16px'
        }
    },    
    boxUbicacion: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '16px',
        marginTop: '16px',
        [theme.breakpoints.down('xs')]:{
            gap: '8px',
            alignItems: 'center',
        }
    },
    iconContent:{
        color: theme.palette.colors.fillStroke[100],
    },    
    iconPlace:{
        width: "20px",
        height: "20px",
        color: theme.palette.colors.fillStroke[100]
    },
    boxDataSesion: {
        display: 'flex',
        flexDirection: 'column',
        width: '532px',
        height: '64px',
        marginTop: '22px',
        justifyContent: 'space-between',
        marginBottom: '8px'
    },
    boxDataDescription: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '8px',
        marginTop: '16px',
    },    
    textDescription: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        gap: '16px',
        borderRadius: '8px',
        backgroundColor: theme.palette.colors.fillStroke[400],
        border: '0.5px solid ' + theme.palette.colors.fillStroke[100],
        maxWidth: '532px',
        maxHeight: '184px'
    },
    select: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        '&:before': {
            borderColor: theme.palette.colors.fillStroke[100],
        },
        '&:after': {
            borderColor: theme.palette.colors.fillStroke[100],
        }
    },
    icon: {
        fill: theme.palette.colors.white,
    },
    boxSalir: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 10px',
        gap: '8px',
        [theme.breakpoints.down('xs')]:{
            flexDirection: 'column-reverse',
            marginTop: '26px',
            padding: '0px'
        }
    },
    buttonSalir:{
        width: '78px',
        height: '40px',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            height: '48px',
        }
    },
    buttonModificar:{
        width: '113px',
        height: '40px',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            height: '48px',
        }
    },    
    buttonFinalizar:{
        width: '106px',
        height: '40px',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
        }
    },
    boxCreditosGen:{
        display: 'flex', 
        flexDirection: 'row', 
        gap: '8px', 
        alignItems: 'center'
    },
    input: {
    padding: '0px !important',
    marginLeft: '10px',
    marginTop: '10px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400',
    '&::placeholder': {
        color: theme.palette.colors.darkBlack[200],
    }
    },
    boxFinalizar: {
        width: '596px',
        height: '204px',
        backgroundColor: theme.palette.colors.fillStroke[400],
        border: '1px solid ' + theme.palette.colors.fillStroke[200],
        borderRadius: '16px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        zIndex: 12,
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            padding: '16px',
            height: 'auto'
        }
    },
    boxCreditos:{
        display: 'flex', 
        width: '32px', 
        height: '32px', 
        alignItems: 'center', 
        justifyContent: 'center', 
        background: 'linear-gradient(274.05deg,#CDACFC -8.09%,#8B3FFA 61.08%)', 
        borderRadius: '100%',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]:{
            marginTop: '16px',
            marginBottom: '22px'
        }
    },
    boxControlFinalizar: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    boxTextFinalizar: {
        width: '532px',
        height: '64px',
        position: 'relative',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '26px',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            height: 'auto',
            marginBottom: '0px !important',
        }
    },
    boxButton: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
        }
    },
    boxDataTimes: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '16px',
        [theme.breakpoints.down('xs')]:{
            flexDirection: 'column',
            gap: '8px',
        }
    },
    boxDataTimesSecond: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap:'8px',
        marginTop: '15px',
        height: '16px',
        padding: 0,
        [theme.breakpoints.down('xs')]:{
            marginTop: 0,
        }
    },
    textBox: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        borderBottom: '1px solid ' + theme.palette.colors.fillStroke[100],
        padding: '8px',
    },
    iconContentDate: {
        color: theme.palette.colors.electricBlue[200]
    },
    closeWindow:{
        display: 'flex',
        width: '200px',
        justifySelf: 'end',
        justifyContent: 'end'
    },
    logoStyle:{
        display: 'block', 
        margin: '0', 
        maxWidth: '80px', 
        maxHeight: '100%'
    },
    boxPictureName:{
        display: 'flex',
        flex: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        gap: '8px',
    }
}));