import { Box, List, ListItemText,ListItem, Typography, ListItemIcon, Tabs, Tab, Grid, Icon } from '@material-ui/core';
import React, { useState } from 'react';
import palette from '../../../../design-system/theme/palette';
import ProfilePicUsers from '../../ProfilePicUsers/ProfilePicUsers';
import TabPanel from '../../Tabs/TabPanel';

const CardSecond = (props) => {

    const [value, setValue] = React.useState(0);
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
      props.onTabSelect(newValue);
    };

  return (
    <Box className={props.className}>
        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {props.children}
        </Box>
        <Box className='h-50 pr-10'>
                {
                <Tabs
                indicatorColor="primary"
                orientation='vertical'
                value={selectedTab} 
                onChange={handleTabChange}
                className={props.classNameTabs}
                >
                {
                    props.listItems.map((button, index) => 
                    <Tab 
                        key={index}
                        className='mb-1'
                        style={{textTransform: 'none'}}
                        label={
                        <Grid container justifyContent='flex-start'>
                        <Icon className={button.icon} />
                        { <Typography variant='body1' className='semi-bold ml-2'>{button.nombre}</Typography>}
                        </Grid>
                        }  
                    />
                    )
                }
                </Tabs>
                }
        </Box>
    </Box>
  );
};

export default CardSecond;