import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `0.5px solid ${palette.colors.fillStroke[200]}`,
  },
  body:{
    color: `${palette.colors.gray[100]} !important`
  },
  head: {
    borderBottom: 'unset',
    color: '#FDFEFF'
  },
  stickyHeader:{
    backgroundColor: palette.colors.fillStroke[500],
    padding: 6,
    '& .MuiTypography-root':{
      ...typography.captionLargeSemiBold,
      color: palette.colors.darkBlack[200],
    },
    '&:first-child': {
      paddingLeft: 18
    },
    '&:last-child': {
      paddingRight: 18
    }
  }
};
