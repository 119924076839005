import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
// shared - components
import Table from '../../../shared/components/Table/Table'
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import InputSearch from '../../../shared/components/Form/InputSearch';
import ChipFilter from '../../../shared/components/Chip/ChipFilter'
import MoreOptions from '../../../shared/components/MoreOptions/MoreOptions';
import { log } from '../../../shared/utils/console.js'

// services
//import * as RoleService from '../../../services/RoleService';
import {RoleService} from '@sdk-point/talisis';

// @material-ui
import {Grid} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const columns = [
  { name: 'id', label: 'Id', minWidth: 100, format(item){return `#${item.id}`}},
  { name: 'name', label:'Nombre de Rol', minWidth:100},
  { name: 'description', label: 'Descripción', minWidth: 100 }, 
  { name: 'actions', label: 'Acciones', minWidth: 100 }
];
 
function Actions({item, onClickEdit}){
  const options = [
      {label: 'Editar', fun: onClickEdit(item.id), icon: <EditOutlinedIcon/>},
    ];
  
  return (
      <div>
          <MoreOptions options={options} ExAction={true}/>
      </div>
  );
}

const RoleTablePag = ({canReadWrite}) => {
  const PAGE_ONE=1;
  const [state, setState] = useState({state: [], totalPages:0})
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(PAGE_ONE);
  const [filters, setFilters] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [valueFilter, setValueFilter] =useState('id');
  const history = useHistory(); 

  useEffect(() => {
     getFullPerson();
    }, [page,filters]);
  
  const handleClickEdit = id => event => {
    history.push(`/roles/${id}/editar`); 
  }
 
  const getFullPerson = async () => {
        try {
          setLoading(true)
          const roleService = new RoleService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
          const request = await roleService.getAll(page,filters);
          request.data.map(it => {
                  it.actions = <Actions item={it}
                    onClickEdit={handleClickEdit} 
                   />;
                  return (it); 
                  })
          setState({data:request.data, totalPages:request.totalPages})
        } catch (e) {
            log('Error get roles', e)
        } finally {
          setLoading(false);
        }
  }
  
  const handleChangePage = (event, page) => {
    event.preventDefault();
    setPage(page);
  };

  const onChangeSearch = (e) => {
    e.preventDefault();
    setInputSearch(e.target.value);
  }

  const searchRecurse = () => { 
    if(inputSearch && valueFilter){
      handleFilters();
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFilters();
    }  
  }

  const handleFilters = () => {
    setPage(PAGE_ONE);
    let filter = {}; 
    filter[valueFilter.trim()] = inputSearch.trim();
    filter['label'] =`${valueFilter}:${inputSearch}`;
    setFilters([...filters,filter])
    setValueFilter('id')
    setInputSearch('')
  }

  const handleDeleteChip = (chipToDelete) => () => {
    setFilters(() => filters.filter((chip) => chip.id !== chipToDelete.id));
  }
  
  const filterSearch = [{value:'id', descripcion:'Id'}, {value:'name', descripcion:'Nombre del Rol'}]

  const COLUMNS_TABLE= (canReadWrite) ? columns:columns.filter(col=> col.name!=='actions');
  
  const  handleChangeFilter = (event) => {
    setValueFilter(event.target.value);
  };
  
  return (
    <>
      <InputSearch recurse={valueFilter} 
                   valueSearch ={inputSearch} 
                   inputSearch='searchUser' 
                   onChangeSearch={onChangeSearch} 
                   searchRecurse={searchRecurse} 
                   onKeyDown={onKeyDown}
                   filter = {filterSearch}
                   handleChangeFilter= {handleChangeFilter}
                   valueFilter={valueFilter}/>
      <Grid container spacing={1}>
        <Grid item xs={3}> 
        </Grid>
        <Grid item xs={4}>
          <ChipFilter chipData={filters} handleDelete={handleDeleteChip}/>
        </Grid>
      </Grid>
      <LoaderContent loading={loading} minHeight={200}>
      <div style={{  
     float: "right"
     }}>
      <Pagination  count={state.totalPages} page={page} onChange={handleChangePage} defaultPage={1} siblingCount={0} />
      </div>
        <Table
          columns={COLUMNS_TABLE}
          rows={state.data}
        />
        <div style={{  
     float: "right"
     }}>
        <Pagination count={state.totalPages} page={page} onChange={handleChangePage} defaultPage={1} siblingCount={0} />
        </div>
      </LoaderContent>
    </>
  );
};

export default RoleTablePag;