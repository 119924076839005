import React from 'react';

// @material-ui/core
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// local - components
import OrderButton from '../components/OrderButton';

// shared - components
import TimelineContainer from '../../../../../shared/components/Timeline/TimelineContainer';
import TimelineItem from '../../../../../shared/components/Timeline/TimelineItem';

// shared
import dateFormat from '../../../../../design-system/utils/dateFormat'
import getInitials from '../../../../../shared/utils/getInitials'
import {DEFAULT_DATE_TIME_FORMAT} from '../../../../../design-system/constants/date-formats'

import styled from 'styled-components';

// utils
import objectIsEmpty from '../../../../../shared/utils/objectIsEmpty'

const AvatarStyled = styled(Avatar)`
    ${({ theme: {palette}, bg }) => `
        width: 36px;
        height: 36px;
        font-size: 0.8rem;

        background-color: ${palette.colors[bg]};
    `}
`

const REQUEST_ACTIVITY_END = 2;

const SORT_OPTIONS = [
    {id: 'asc', label: 'Más reciente primero'},
    {id: 'desc', label: 'Más antigüos primero'},
];

const Activity = (props) => {
    const {data} = props;

    const [steps, setSteps] = React.useState([]);

    React.useEffect(()=>{
        if(!objectIsEmpty(data)){
            setSteps([...data.activity.map((it, index) => ({...it, stepNumber: index + 1}))])
        }
    }, [data]);

    const timeLineItems = () => activities().map(it =>  {
        const TimelineItemContent = () => 
        <Grid container spacing={1} direction="row">
            <Grid item xs={5} sm={3}>
                <Typography component="span" variant="subtitle2" className="text-fill-stroke-100">{dateFormat(it.date, DEFAULT_DATE_TIME_FORMAT)}</Typography>
            </Grid>

            <Grid item xs={7} sm={9}>
                <Box>
                    {
                        it.person.trim() &&
                        <Typography component="span">{it.person}</Typography>
                    }
                    {
                        it.isSystem &&
                        <React.Fragment>
                            <Typography component="span">
                                <span>
                                {it.activity}&nbsp;<span>{it.from ? it.from :it.to}</span> 
                                {it.from && 
                                    <React.Fragment>
                                        &nbsp;a <span>{it.to}</span> 
                                    </React.Fragment>
                                }
                                </span>
                            </Typography>
                        </React.Fragment>
                    }
                </Box>
                <Box>
                    { !it.isSystem && it.activity &&
                        <Box>
                            <Typography className="text-fill-stroke-100" component="span" variant="subtitle2" >Actividad&nbsp;</Typography>
                            <Typography className="text-fill-stroke-100" component="span" variant="subtitle2">{it.activity}</Typography>
                        </Box>
                    }
                    {it.status &&
                    <Box>
                        <Typography className="text-fill-stroke-100" component="span" variant="subtitle2">Estatus&nbsp;</Typography>
                        <Typography className="text-fill-stroke-100" component="span" variant="subtitle2">{it.status}</Typography>
                    </Box>
                    }
                    {it.assigneeTo &&
                    <Box>
                        <Typography className="text-fill-stroke-100" component="span" variant="subtitle2">Asignado a&nbsp;</Typography>
                        <Typography className="text-fill-stroke-100" component="span" variant="subtitle2">{it.assigneeTo}</Typography>
                    </Box>
                    }
                    {it.comments &&
                        <Box>
                            <Typography className="text-fill-stroke-100" component="span" variant="subtitle2">Comentarios&nbsp;</Typography>
                            <Typography className="text-fill-stroke-100" component="span" variant="subtitle2">{it.comments}</Typography>
                        </Box>
                    }
                    {it.has_file &&
                        <Box>
                            <Typography className="text-fill-stroke-100" component="span" variant="subtitle2">Agrego archivo</Typography>
                        </Box>
                    }
                </Box>
            </Grid>
        </Grid>;

        return <TimelineItem key={it.id}
            dotContent={<AvatarStyled bg={it.person.trim() ? "pink" : 'orange'}>{it.person.trim() ? getInitials(it.person) : 'S'}</AvatarStyled>} 
            disabled={true}
            isBackGroundTranparent={true}
            >
                <TimelineItemContent />
            </TimelineItem>;
    });

    
    const handleOrderSteps = (item) => {
        setSteps(item);
    }

    const activities = () => steps.map(it => {     
        const isSystem = (it.status && (it.step_from || it.step_to)) || it.activity_id === REQUEST_ACTIVITY_END;
        return {
            id: it.id,
            person: it.full_name,
            activity: it.activity,
            date: it.created_at,
            status: it.status,
            from: it.step_from_label,
            to: it.step_to_label,
            assigneeTo: it.assigneeTo ? JSON.parse(it.assigneeTo).join() : '',
            comments: it.comments,
            isSystem,
            has_file: it.has_file
        };
    });

    return (
        <Grid item xs={12}>
            <Card variant="contained" className="bg-bg-fill-stroke-400">
                <CardContent>
                    <Box maxHeight="calc(100vh - 360px)" style={{overflowY: 'auto'}}>
                    <Grid container spacing={1} direction="row" style={{padding: '20px 20px'}}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6">Historial de acciones</Typography>
                        </Grid>
                        <Grid item container xs={12} sm={8} justifyContent='flex-end'>
                            <Box display="flex" alignItems="center">
                                <OrderButton items={SORT_OPTIONS} arrayToSort={steps} onChangeOrder={handleOrderSteps}/>
                            </Box>
                        </Grid>
                    </Grid>
                    <TimelineContainer align="left">
                        { timeLineItems() }
                    </TimelineContainer>
                    </Box>
                </CardContent>
            </Card>    
        </Grid>
    );
};

export default Activity;