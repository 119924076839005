import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';


import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';

// icons 
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import CloudDownload from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';

// shared
import dateFormat from '../../../../design-system/utils/dateFormat';
import ConfirmDialog from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import Loader from '../../../../shared/components/Loader/LoaderContent';
import { log } from '../../../../shared/utils/console.js'

// services
// import * as marketService from '../../../../services/MarketPlaceService';
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';
import NothingFound from '../../../../shared/components/NothingFound/NothingFound';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    avatar: {
        width: "50px",
        height: "50px",
        marginRight: "8px"
    },
    upper:{
        textTransform: "uppercase"
    }
});

const useHeadStyles = makeStyles((theme) => ({
    contain: {
        backgroundColor: theme.palette.primary.main
    },
    table_contain:{
        border: "0.5px solid #828D9E",
        borderRadius: "8px"
    },
    table_body: {
        maxHeight: "500px"
    },
    buttons_contain: {
        justifyContent: "flex-end",
        maxWidth: "100%",
        marginBottom: "25px"
    }
}));


  const ITEM_HEIGHT = 48;

const Courses = (props) => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { setToastProps, user, handleClickOpen, handleClickAddCourses } = props;
    const classes = useHeadStyles();
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const getCourses = async () => {
        try {
            const cursos = await marketService.getCourses();
            return cursos;
        } catch (e) {
            log(e.message);
        }
        return [];
    };

    const handleXml = () =>{
        const link = document.createElement('a');
        link.href = `https://point-files.s3.us-west-2.amazonaws.com/public/Cargas+V4.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleSync = async () => {
        try {
            setLoading(true);
            const body = {
                person_id: user.person_id
            };
            const response = await marketService.postCoursesSync(body, user);
            const cursos = await marketService.getCourses();
            setToastProps({ severity: "success", open: true, message: response.message });
            setData(cursos);
        } catch (e) {
            setToastProps({ severity: "error", open: true, message: 'Ocurrió un error al sincronizar los cursos.' });
            setLoading(false);
            log(e.message);
        }
    };

    React.useEffect(() => {
        let isCanceled = true;
        getCourses().then((data)=>{
            if(isCanceled){
                if(data?.length > 0){
                    setData(data);
                }
                setLoading(false);  
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });

        return () => (isCanceled = false);
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    return <React.Fragment>
        <Box display="flex" flexDirection="row" className={classes.buttons_contain}>
            <Button onClick={handleClickAddCourses} variant="contained" color='secondary' style={{ marginRight: "20px" }}>Cargar cursos</Button>
            <Button onClick={handleClickOpen} variant="contained" color='primary'>+ Nuevo curso</Button>
        </Box>
        <Loader loading={loading} transparent={true} minHeight="calc(100vh - 500px)">
            <Card style={{ background: !loading && data.length == 0 && "transparent" }}>
                {data.length > 0 ?<CardContent>
                 {/*   <Button color="primary" className="mb-2 mr-2" startIcon={<SyncIcon />} disabled={loading} onClick={handleSync}>Sincronizar cursos</Button>*/}
                    <Button color="primary" className="mb-2" startIcon={<CloudDownload />} disabled={loading} onClick={handleXml}>Descargar (xml)</Button>
                    <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                    <TableContainer variant="outlined" className={classes.table_body}>
                        <Table aria-label="collapsible table" stickyHeader className={classes.table_contain}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre del curso</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Institución</TableCell>
                                    <TableCell>Tipo de curso</TableCell>
                                    <TableCell>Actualizado</TableCell>
                                    <TableCell component="th" scope="row">Activo</TableCell>
                                    <TableCell align="center">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                { data!==undefined && data.map((row) => <CourseRow key={row.id} row={row} user={user} setToastProps={setToastProps} setLoading={setLoading} getCourses={getCourses} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                </CardContent>
                :<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    { !loading && <NothingFound
                        caption={"No hay programas"}
                        subcaption={""}
                    /> }
                </Box>}
            </Card>
        </Loader>
    </React.Fragment>;
};

const CourseRow = ({ row, user, getCourses, setToastProps, setLoading }) => {
    const history = useHistory();
    const classes = useRowStyles();
    const [checked, setChecked] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [dialogProps, setDialogProps] = React.useState({
        title: "",
        text: "",
        open: false,
    });
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (row.is_active === true || row.is_active === false) {
            setChecked(row.is_active)
        }
    }, []);

    const handleConfirm = async () => {
        try {
            setLoading(true);
            const body = {
                "person_id": user.person_id,
                "group_id": row.group_id,
            };
            await marketService.deleteCourse(row.id, body);
            await getCourses();
            setToastProps({ severity: "success", open: true, message: 'El curso fue eliminado exitosamente.' });
        } catch (e) {
            if (e.message && e.message.includes("No se pudo eliminar el curso")) {
                setToastProps({ severity: "warning", open: true, message: e.message });
            } else {
                setToastProps({ severity: "error", open: true, message: 'Ocurrió un error al eliminar el curso.' });
            }
            setLoading(false);
            log(e.message);
        }
    };

    const handleClose = () => {
        setDialogProps({ ...dialogProps, open: false });
    };

    const handleChange = async (event) => {
        const value = event.target.checked;
        setChecked(value);
        try {
            const body = {
                "active": value,
                "person_id": user.person_id
            };
            const response = await marketService.postCourseStatus(row.id, body);
            setToastProps({ severity: "success", open: true, message: response.message });
        } catch (e) {
            setToastProps({ severity: "error", open: true, message: 'Ocurrió un error al actualizar el curso.' });
            setChecked(!value);
            log(e.message);
        }
    };

    const handleEdit = () => {
        history.push(`/course/${row.id}/edit`);
    };

    const handleDelete = () => {
        setDialogProps({
            title: "Eliminar curso",
            text: "¿Estás seguro que deseas eliminar el curso?",
            open: true,
        });
    };

    const handlePreview = () => {
        history.replace({
            pathname: `/course/${row.id}`,
            search: `?previewMode=true`,
        });
    };

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                
                
                <TableCell>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Avatar className={classes.avatar} variant="square" alt={row.name} src={row.image_url} />
                    <Typography variant="subtitle2">{row.name}</Typography>
                </Box>
                    
                </TableCell>
                <TableCell>
                    
                    {/* <Link href={`/course/${row.id}`} color="inherit" target="_blank" variant="body1">{row.name}</Link> */}
                </TableCell>
                <TableCell>
                    {row.learning_style_name === "Sincrónico" &&
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                    
                </TableCell>
                <TableCell><Typography className={classes.upper} variant="body1">{row.company_name}</Typography></TableCell>
                <TableCell><Chip color="secondary" size="small" label={row.learning_style_name==null?"No definido":row.learning_style_name} /></TableCell>
                <TableCell>{dateFormat(row.last_updated)}</TableCell>
                <TableCell>
                    <Switch
                            size="small"
                            checked={checked}
                            onChange={handleChange}
                            color="primary"
                            name="active"
                            inputProps={{ 'aria-label': 'Activo' }}
                        />
                </TableCell>    
                <TableCell align="center" >
                    {/*<IconButton aria-label="Editar" title="Editar" onClick={handleEdit}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Eliminar" title="Eliminar" onClick={handleDelete}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vista previa" title="Vista previa" onClick={handlePreview}>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>*/}
                    <Box>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={openMenu ? 'long-menu' : undefined}
                            aria-expanded={openMenu ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick} 
                            key={row.id+"button"}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                },
                            }}
                        >
                            {
                            [
                                <Box key={row.id+"editar"} display="flex" flexDirection="row" justifyContent="center" alignItems="center" onClick={handleEdit}>
                                    <IconButton aria-label="Editar" title="Editar">
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <Typography variant="primary.main">Editar</Typography>
                                </Box>,
                                <Box key={row.id+"eliminar"} display="flex" flexDirection="row" justifyContent="center" alignItems="center" onClick={handleDelete}>
                                    <IconButton aria-label="Eliminar" title="Eliminar">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    <Typography variant="primary.main">Eliminar</Typography>
                                </Box>,
                            /*<IconButton aria-label="Vista previa" title="Vista previa" onClick={handlePreview}>
                                <VisibilityIcon fontSize="small" />
                            </IconButton>*/]
                            .map((option) => (
                            <MenuItem key={option} onClick={handleCloseMenu}>
                                {option}
                            </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </TableCell>
            </TableRow>

            <ConfirmDialog {...dialogProps} onConfirm={handleConfirm} onClose={handleClose} />
        </React.Fragment>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(Courses);