import React, { useState } from 'react';

// redux
import { connect } from "react-redux";

// @material-core
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// local
import ProfileDetails from './ProfileDetails';
import GradientSVG from '../../Components/GradientSVG/GradientSVG';
import ProfileCircle from '../../Components/Profile/ProfileCircle';

// thirty-party
import 'moment/locale/es';
import 'react-circular-progressbar/dist/styles.css';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.colors.darkBlack[500],
    border: "1px solid #2C3236",
    height: '99%',
  },
  cardContent:{
    marginTop: '50%'
  },
  gridContainer:{
    height:'100%'
  },
  button: {

  }
}));

const ProfileBasic = (props) => {
  const classes = useStyles();

  const { initTitulation, disableTitu/*, validated*/} = props

  return (
    <Grid item xs={12}  className={classes.gridContainer} >
      <Card className={classes.card}>
        <CardContent  className={classes.cardContent} >
          <Grid item xs={12}>
            <>
              <ProfileCircle completedProfile={100} {...props} />
              <ProfileDetails {...props} />
              <Grid container direction='column' alignItems="center" >
                {/* <Button
                  variant="outlined"
                  color="secondary"
                  style={{ marginLeft: 20, height: 40, marginTop: 15 }}
                  onClick={(e) => { validated() }}
                  size="small"
                >
                  Validar
                </Button> */}
                {/* <Button
                  variant="outlined"
                  color="secondary"
                  style={{ marginLeft: 20, height: 40, marginTop: 15 }}
                  onClick={(e) => { initTitulation(props.matricula) }}
                  size="small"
                  disabled={disableTitu}
                >
                  Iniciar Proceso Titulación
                </Button> */}
              </Grid>
            </>
          </Grid>
        </CardContent>
      </Card>
      <GradientSVG />
    </Grid>
  );
};
const mapStateToProps = ({ }) => ({});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileBasic);
