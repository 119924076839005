import React from 'react';
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

const UserAllysClassesInfo = (props) => {
    const { data: userData, classes } = props;
    return (
        <React.Fragment>
            {userData.map((data) => {
                return (
                    <fieldset className={`mb-3 ${classes.fieldset}`} key={`fs_ally_user_data_${data.id}`}>
                        <legend>{data.id}</legend>
                        {data.courses_allys && data.courses_allys.courses && data.courses_allys.courses.length > 0 ?
                            data.courses_allys.courses.map(allyCourses => (
                                <Table key={`tab_table_ally_courses_${allyCourses.id}`} size="small" className="mb-4">
                                    <TableBody key={`tab_tbody_ally_courses_${allyCourses.id}`}>
                                        <TableRow>
                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} colSpan={3}>
                                                <Typography variant="subtitle1"><b>{allyCourses.name}</b></Typography>
                                                <hr />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'50%'}>&nbsp;</TableCell>
                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">Tiene URL</TableCell>
                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">&nbsp;</TableCell>
                                        </TableRow>
                                        {allyCourses.courses && allyCourses.courses.length > 0 ?
                                            allyCourses.courses.map(allyCourse => (
                                                <TableRow key={`tr_ally_course_${allyCourse.course_id}`}>
                                                    <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'50%'}>
                                                        <Typography variant="body2" className="mt-1">{allyCourse.name}</Typography>
                                                    </TableCell>
                                                    <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">
                                                        {allyCourse.course_url ? <Check className={classes.green} style={{ width: '0.75em' }} /> : <Close className={classes.red} style={{ width: '0.75em' }} />}
                                                    </TableCell>
                                                    <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">&nbsp;</TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'100%'}>
                                                    <Typography component="div" color="primary">Sin información para mostrar.</Typography>
                                                </TableCell>
                                            </TableRow>}
                                    </TableBody>
                                </Table>
                            ))
                            :
                            <TableRow>
                                <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'100%'}>
                                    <Typography component="div" color="primary">Sin información de cursos.</Typography>
                                </TableCell>
                            </TableRow>}
                    </fieldset>
                )
            })}
        </React.Fragment>
    )
};

export default UserAllysClassesInfo;