import React from 'react';

// redux
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

//Routes
import * as paths from '../../../routes/paths'

// @material-ui/core
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { SwiperSlide } from 'swiper/react/swiper-react.js';

// shared core components
import SlickCarousel from '../../../shared/components/SlickCarousel/SlickCarousel';
import TitleLink from '../../../shared/components/TitleLink/TitleLink';

import AllyCourseCard from '../../../design-system/components/Cards/AllyCourseCard/AllyCourseCard';

import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        padding:' 0 0 32px',
        minHeight: 254,
        [theme.breakpoints.down('xs')]: {
            padding: '24px 0'
        }
    },
    buttonGroup: {
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: `${theme.spacing(1)}px 0px`
    },
    title:{
        marginLeft: "59px",
        width: "100%",
        "@media (max-width: 1250px)": {
            marginLeft: "5px",
        },
    },
    swiper: {
        padding: "12px",
        maxWidth: "fit-content",
        [theme.breakpoints.down('xs')]:{
            padding: "8px",
        }
    },
    card: {
        width: 393,
        [theme.breakpoints.down('xs')]: {
            width: 265,
            background: 'none'
        }
    },
    cardActionArea:{
        width: "393px",
        flexDirection: "column",
        display: "flex",
        justifyContent: "flex-start",
        [theme.breakpoints.down('xs')]: {
            width: 265
        }
    },
    carousel: {
        [theme.breakpoints.down('xs')]:{
            padding: '0px 16px',
            '& .slick_carousel':{
                '& #root-slik-arrows':{
                    paddingRight: '0 !important'
                }
            },
        }
    },
    titleLink:{
        paddingLeft: 0
    }
}));

const CarouselAliados = (props) => {
    const classes = useStyles();
    const { title, className } = props;
    const [aliados, setAliados] = React.useState([]);
    const history = useHistory();    

    React.useEffect(() => {
        const chunkSize = 3;
        const newArray = [];
        
        for (let i = 0; i < props.items.length; i += chunkSize) {
          newArray.push(props.items.slice(i, i + chunkSize));
        }
        setAliados(newArray)
    }, [props.items]);

    const handleClickTitle = (e, id) => {
        history.push(`${paths.BUSQUEDA}?aliados=true`);
   };

    return (
        <Box className={clsx(classes.root, className)} id="carrusel-aliados" pt={3}>
            <TitleLink textPrimary={title} textSecondary="Ver todos" onClick={ (e) => handleClickTitle(e)} className={classes.titleLink} hideSubtitleResponsive/>
            
            <Box className={classes.carousel}>
            { aliados.length && 
                <SlickCarousel isAliados={true} length={aliados.length} typeCard="aliados" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                    {
                        aliados.map(aliadoNode => {
                            return (
                                <SwiperSlide className={classes.swiper} key={uuid()}>
                                    <Box display='flex' flexDirection='column' gridGap={24}>
                                        {
                                            aliadoNode.map(aliado =>                                                        
                                                <AllyCourseCard
                                                    key={uuid()}
                                                    sourceId={aliado.id} 
                                                    sourceName={aliado.value} 
                                                    courses={aliado.total_cursos}
                                                    onClick={() => props.onClickCard(aliado)}
                                                />
                                            )
                                        }                                            
                                    </Box>
                                </SwiperSlide>
                            )
                        })
                    }
                </SlickCarousel>
            }
            </Box>
        </Box>
    );
};

CarouselAliados.defaultProps = {
    lazyLoading: false,
    disableTitleAction: false,
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CarouselAliados);
