import * as pointColors from '../../assets/styles/variables/colors.scss'

const colors = {
  yellow: {
    300: '#F2C80B'
  },
  grey: {
    50:  '#404150',
    100: '#F1F3F6',
    200: '#DDDFE3',
    400: '#2C3236',
    500: '#1C1F24',
    600: '#707a89',
    700: '#34393c',
    900: '#9A9FA7',
    1000: '#78787D'
  },
  fillStroke:{
    50: pointColors.fillStroke50,
    100: pointColors.fillStroke100,
    200: pointColors.fillStroke200,
    300: pointColors.fillStroke300,
    400: pointColors.fillStroke400,
    500: pointColors.fillStroke500,
  },
  gray:{
    50: pointColors.gray50,
    100: pointColors.gray100,
    200: pointColors.gray200,
    300: pointColors.gray300,
    400: pointColors.gray400,
    500: pointColors.gray500,
  },
  darkBlack:{
    100: pointColors.darkBlack100,
    200: pointColors.darkBlack200,
    300: pointColors.darkBlack300,
    400: pointColors.darkBlack400,
    500: pointColors.darkBlack500,
  },
  electricBlue:{
    100: pointColors.electricBlue100,
    200: pointColors.electricBlue200,
    300: pointColors.electricBlue300,
    400: pointColors.electricBlue400,
    500: pointColors.electricBlue500,
  },
  electricRed:{
    100: pointColors.electricRed100,
    200: pointColors.electricRed200,
    300: pointColors.electricRed300,
    400: pointColors.electricRed400,
    500: pointColors.electricRed500,
  },
  blue:{
    100: pointColors.blue100,
    200: pointColors.blue200,
    300: pointColors.blue300,
    400: pointColors.blue400,
    500: pointColors.blue500,
  },
  orange:{
    100: pointColors.orange100,
    200: pointColors.orange200,
    300: pointColors.orange300,
    400: pointColors.orange400,
    500: pointColors.orange500,
  },
  electricGreen:{
    100: pointColors.electricGreen100,
    200: pointColors.electricGreen200,
    300: pointColors.electricGreen300,
    400: pointColors.electricGreen400,
    500: pointColors.electricGreen500,
  },
  magenta:{
    100: pointColors.magenta100,
    200: pointColors.magenta200,
    300: pointColors.magenta300,
    400: pointColors.magenta400,
    500: pointColors.magenta500,
  },
  violet:{
    100: pointColors.violet100,
    200: pointColors.violet200,
    300: pointColors.violet300,
    400: pointColors.violet400,
    500: pointColors.violet500,
  },
  error:{
    100: pointColors.error100,
    200: pointColors.error200,
    300: pointColors.error300,
    400: pointColors.error400,
    500: pointColors.error500,
  },
  warning:{
    100: pointColors.warning100,
    200: pointColors.warning200,
    300: pointColors.warning300,
    400: pointColors.warning400,
    500: pointColors.warning500,
  },
  success:{
    100: pointColors.success100,
    200: pointColors.success200,
    300: pointColors.success300,
    400: pointColors.success400,
    500: pointColors.success500,
  },
  ou:{
    uerreBlack: pointColors.uerreBlack,
    uerrePurple: pointColors.uerrePurple,
    uerreBlueC3: pointColors.uerreBlueC3,
    unidYellow: pointColors.unidYellow,
    unidPurple: pointColors.unidPurple,
    unidBlue: pointColors.unidBlue,
    unidMagenta: pointColors.unidMagenta,
    unidBusinesBlue: pointColors.unidBusinesBlue,
    unidBusinessMagenta: pointColors.unidBusinessMagenta,
    unidBusinesOrange: pointColors.unidBusinesOrange,
    hhBlack: pointColors.hhBlack,
    hhYellow: pointColors.hhYellow,
    advenioRedMain: pointColors.advenioRedMain,
    iesaludGreenM80: pointColors.iesaludGreenM80,
    uerreRedC80: pointColors.uerreRedC80,
    unidYellowMain: pointColors.unidYellowMain,
    iesaludYellow: pointColors.iesaludYellow
  },
  gradient:{
    5: pointColors.gradient5
  }
}

export default colors;
