import React, { useEffect, useState } from 'react';
//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Card } from '@material-ui/core';

// style
import useStyles from '../../../assets/styles/jss/pages/consultancies/applicant/RatingConsultancyStyle';

import clsx from 'clsx';
import ProfilePicUsers from '../../../shared/components/ProfilePicUsers/ProfilePicUsers';
import palette from '../../../design-system/theme/palette';
import Toast from '../../../shared/components/Toast/Toast';
import { ConsultanciesService } from '@sdk-point/talisis'; 
import LoaderContent from '../../../shared/components/Loader/LoaderContent';

const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const RatingConsultancy = (props) => {
    
    const classes = useStyles();
    const [questions, setQuestions] = useState(props.preguntasCalificar);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, []);

    const handleSend = async () => {
        setLoading(true)
        const hasNullProp = questions.some(obj => obj.selectedValue === null);
        if(!hasNullProp){
            let body = {
                person_id: props.personIdAsesor,
                id_asesoria: props.idConsultancy,
                respuestas: questions
            }
            await ConsultanciesServiceSDK.postSetCalificationsConsultancies(body)
            props.findConsultancies()
            props.setCalifications(false)
        }else{
            setOpen(true)
        }
        setLoading(false)
    };

    const ValueSelector = ({ question }) => {

    const handleChange = (value, questionId) => {
        setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.id === questionId
            ? { ...question, selectedValue: value }
            : question
        )
        );
    };

        return (
            <Box>
            {questions.map(({ id, pregunta, selectedValue }) => (
                <Box key={id} style={{marginBottom:'20px'}}>
                    <Typography variant='body2' className='semi-bold'>{pregunta}</Typography>
                    <Box style={{display: 'flex', justifyContent: 'center'}}>
                        {[1, 2, 3, 4, 5].map(value =>   (
                        <Button
                            className={classes.labelRadio}
                            key={value}
                            style={
                            value === selectedValue
                                ? { backgroundColor: palette.colors.fillStroke[100] }
                                : { backgroundColor: palette.colors.fillStroke[300] }
                            }
                            onClick={() => handleChange(value, id)}
                        >
                            {value}
                        </Button>
                        ))}
                    </Box>
                </Box>
            ))}
            </Box>
        )
      };

    return (
                <Box style={{zIndex: '12'}}>
                    <Card variant='outlined' className={classes.CardRoot}>
                        <LoaderContent loading={loading}>
                        <Box className={classes.BoxTittles}>
                            <Box className={classes.BoxTittle}>
                                <Typography variant='h3'>¿Qué tan satisfecho te encuentras con los siguientes aspectos del asesor?</Typography>
                                <Typography variant='h5'> 0 - Nada satisfecho  5- Muy satisfecho </Typography>
                                <ProfilePicUsers email = {props.emailAsesor} className={classes.profilePic}/>
                                <Typography variant='body1' className='semi-bold'>{props.nombreAsesor}</Typography>
                            </Box>
                            {
                                <Box>
                                        <Box>
                                            <Box className={classes.BoxPreguntas}>
                                                <ValueSelector question = {questions} setQuestions={setQuestions}/>
                                            </Box>
                                        </Box>
                                    <Box className={classes.boxSig}>
                                        <Button color='primary' variant='contained' onClick={()=>{handleSend()}}>Enviar</Button>
                                    </Box>
                                </Box>
                            }
                        </Box>

                        </LoaderContent>
                    </Card>           
                    <Toast
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'top'
                    }}
                    message="Debes calificar todas las preguntas para que se puedan enviar"
                    onFinished={() => {setOpen(false)}}
                    open={open}
                    severity="error"
                    />
                </Box>
    );
};

export default RatingConsultancy;