import { makeStyles } from '@material-ui/core';

export const CoursesTaughtHeaderCSSClasses = () => {
    return makeStyles((theme) => ({
        root: {
            height: '100%',
            width: '100%',
        },
        loading: {
            left: '50%',
            margin: '0',
            position: 'absolute',
            top: '50%',
        },
        title: {
            alignItems: 'center',
            display: 'inline-flex',
            '& .bottom': {
                verticalAlign: 'bottom',
            },
        },
        play: {
            alignItems: 'center',
            border: '1px solid #26C5FB',
            borderRadius: '16px',
            boxSizing: 'border-box',
            display: 'inline-flex',
            flex: 'none',
            flexDirection: 'row',
            height: '24px',
            padding: '4px 16px',
            width: '110px',
        },
        media: {
            height: '170px',
        },
        logo: {
            bottom: '10%',
            position: 'absolute',
            right: '14px',
        },
        label: {
            lineHeight: '24px',
        },
        modality: {
            alignItems: 'center',
            background: 'rgba(28, 31, 36, 0.8)',
            bottom: '12px',
            borderRadius: '4px',
            color: theme.palette.colors.grey[200],
            display: 'flex',
            fontSize: '10px',
            height: '24px',
            left: '16px',
            padding: '5px',
            position: 'absolute',
            textAlign: 'center',
        },
        group: {
            color: 'red',
            '& .icon': {
                verticalAlign: 'bottom',
            }
        },
        button: {
            alignItems: 'center',
            alignSelf: 'stretch',
            background: '#1C1F24',
            borderRadius: '8px',
            display: 'flex',
            flex: 'none',
            flexDirection: 'row',
            flexGrow: '0',
            gap: '4px',
            height: '48px',
            justifyContent: 'center',
            order: '4',
            padding: '12px 24px 12px 16px',
            width: '100%',
        },
        circularLoadingPeriods: {
            height: '25px !important',
            width: '25px !important'
        },
        nothingFoundBox: {
            padding: '2%',
        }
    }));
};

export const CoursesTaughtDetailCSSClasses = () => {
    return makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        back: {
            alignItems: 'center',
            display: 'inline-flex',
        },
        box: {
            alignItems: 'center',
            background: theme.palette.colors.grey[50],
            borderRadius: '16px',
            padding: '29px',
            width: '100%',
        },
        header: {
            background: theme.palette.colors.grey[400]
        },
        headerContainer: {
            alignItems: 'center',
            display: 'inline-flex',
            width: '100%',
        },
        detailContent: {
            display: 'flex',
        },
        logoOU: {
            float: 'right',
            height: '32px',
            width: '126.49px'
        },
        labelContainer: {
            display: 'flex',
        },
        label: {
            fontSize: '18px',
        },
        period: {
            '& .icon': {
                verticalAlign: 'bottom',
            }
        },
        cursorPointer: {
            cursor: 'pointer',
        },
        cursorNotAllowed: {
            cursor: 'not-allowed',
        }
    }));
};