import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    'google-skillshop':{
        width: 163,
        height: 25,
        [theme.breakpoints.down('xs')]:{
            width: 128,
            height: 20 
        }
    },
    'crehana':{
        width: 147,
        height: 22,
        [theme.breakpoints.down('xs')]:{
            width: 116,
            height: 17 
        }
    },
    'aws':{
        width: 127,
        height: 32,
        [theme.breakpoints.down('xs')]:{
            width: 100,
            height: 25 
        }
    },
    'aws-skill-builder':{
        width: 127,
        height: 32,
        [theme.breakpoints.down('xs')]:{
            width: 100,
            height: 25 
        }
    },
    'coursera':{
        width: 115,
        height: 16,
        [theme.breakpoints.down('xs')]:{
            width: 91,
            height: 13 
        }
    },
    'hubspot':{
        width: 193,
        height: 27,
        [theme.breakpoints.down('xs')]:{
            width: 152,
            height: 21 
        }
    },
    'ie-university':{
        width: 94,
        height: 37,
        [theme.breakpoints.down('xs')]:{
            width: 75,
            height: 30 
        }
    },
    'linkedin-learning':{
        width: 121,
        height: 26,
        [theme.breakpoints.down('xs')]:{
            width: 95,
            height: 21 
        }
    },
    'uerre':{
        width: 95,
        height: 18,
        [theme.breakpoints.down('xs')]:{
            width: 86,
            height: 16 
        }
    },
    'unid':{
        width: 66,
        height: 20,
        [theme.breakpoints.down('xs')]:{
            width: 53,
            height: 16 
        }
    },
    'harmon-hall':{
        width: 152,
        height: 21,
        [theme.breakpoints.down('xs')]:{
            width: 133,
            height: 19 
        }
    },
}));