import React from 'react';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import Iframe from 'react-iframe';

// material
import { Box, Button, Card, CardContent, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

// assets
import LogoUerreWhite from "../../../../../assets/images/logos/logo-uerre-blanco.png";
import LogoUerreBlack from "../../../../../assets/images/logos/logo-uerre-negro.png";
import LogoUnidWhite from "../../../../../assets/images/logos/logo-unid-blanco.png";
import LogoUnidBlack from "../../../../../assets/images/logos/logo-unid-negro.png";
import BanorteLogo from "../../../../../assets/images/banks/banorte-logo.png";
import BanamexLogo from "../../../../../assets/images/banks/citibanamex-logo.png";
import SantanderLogo from "../../../../../assets/images/banks/santander-logo.png";
import HSBCLogo from "../../../../../assets/images/banks/hsbc-logo.png";
import S7Eleven from "../../../../../assets/images/banks/7-eleven-logo.png";
import PaymentDetail from './PaymentDetail/PaymentDetail';

const useStyles = makeStyles((theme) => ({
    root: {
        color: "#002984",
        fontWeight: 600
    },
    table: {
        overflowX: "auto",
        marginTop: theme.spacing(2),
        padding: theme.spacing(0.5)
    },
    logoOu: {
        color: theme.palette.white
    },
    cardContainer: {
        marginTop: "60px"
    },
    btnPrint: {
        marginLeft: '21px'
    },
    btnContainer: {
        marginTop: '48px'
    },
    cardFrameWrap:{
        overflow:'hidden',
        maxWidth: 450
    },
    paymentFrame: {
        border: 0,
        marginTop:-240,
        borderRadius: '16px'
    },
    tcContainer: {
        maxWidth: '450px'
    }
}));

const changeFormatDate = (date) => {
    if (!date) return '';

    const dateSplit = date.split('-');

    if (dateSplit.length < 3) return '';

    const months = [
        { label: 'ENE', month: "01" },
        { label: 'FEB', month: "02" },
        { label: 'MAR', month: "03" },
        { label: 'ABR', month: "04" },
        { label: 'MAY', month: "05" },
        { label: 'JUN', month: "06" },
        { label: 'JUL', month: "07" },
        { label: 'AGO', month: "08" },
        { label: 'SEP', month: "09" },
        { label: 'OCT', month: "10" },
        { label: 'NOV', month: "11" },
        { label: 'DIC', month: "12" },
    ];

    const newMonth = months.find(month => month.label.toLowerCase() === dateSplit[1].toLowerCase());

    return `20${dateSplit[2]}-${newMonth.month}-${dateSplit[0]}`;
};

const bankInfo = bank => {
    if (bank === "BMX") {
        return { Name: "Banamex", Logo: BanamexLogo }
    } else if (bank === "HSB") {
        return { Name: "HSBC", Logo: HSBCLogo }
    } else if (bank === "SAN") {
        return { Name: "Santander", Logo: SantanderLogo }
    } else if (bank === "NOR") {
        return { Name: "Banorte", Logo: BanorteLogo }
    } else if (bank === "7EL") {
        return { Name: "7-Eleven", Logo: S7Eleven }
    }
};

const PaymentReceipt = ({ student, adeudos, methods, render, onClickReturnToPaymentMethods }) => {
    const classes = useStyles();
    const [today, setToday] = React.useState("");
    const [expiration, setExpiration] = React.useState("");
    const [printable, setPrintable] = React.useState(false);
    const className = printable ? "printable" : "";

    React.useEffect(() => {
        const $expirations = [];
        //
        adeudos.forEach((adeudo) => {
            adeudo.Cargos
                .filter((cargo) => { return cargo.aPagar === "S" })
                .forEach((cargo) => { $expirations.push(cargo.Fecha_vencimiento) })
        });
        //
        setToday(moment(new Date()).format("DD/MM/YYYY"));
        setExpiration(moment(changeFormatDate(methods["Fecha vigencia ficha"])).format('L'));
        // setExpiration($expirations.sort(() => 1)[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adeudos]);

    React.useEffect(() => {
        if (printable === true) {
            const tempTitle = document.title;
            document.title = `ficha de pago ${student.ou} ${student.student_id}`;
            window.print();
            document.title = tempTitle;
            setPrintable(false);
        }
    }, [printable, student.ou, student.student_id]);

    const handlePrint = () => {
        setPrintable(true);
    };

    const logosBlack = {
        unid: <img alt="logo" src={LogoUnidBlack} draggable={false} style={{ maxHeight: 40 }} className={classes.logoOu} />,
        uerre: <img alt="logo" src={LogoUerreBlack} draggable={false} style={{ maxWidth: 150 }} className={classes.logoOu} />
    };

    const logosWhite = {
        unid: <img alt="logo" src={LogoUnidWhite} draggable={false} style={{ maxHeight: 40 }} className={classes.logoOu} />,
        uerre: <img alt="logo" src={LogoUerreWhite} draggable={false} style={{ maxWidth: 150 }} className={classes.logoOu} />
    };

    return <Box className={`${className}`} displayPrint="block" width="100%">
        <Grid container spacing={3} direction="row">
            <Grid item xs={4} md={6}>
                <Box display={'block'} displayPrint={'none'}>
                    {student.ou ? logosWhite[student.ou] : null}
                </Box>
                <Box display={'none'} displayPrint={'block'}>
                    {student.ou ? logosBlack[student.ou] : null}
                </Box>
                <Box>
                    {render === "ventanilla" && <Typography variant="caption"><i className="ri-bank-fill"></i> Pago en ventanilla</Typography>}
                    {render === "transferencia" && <Typography variant="caption"><i className="ri-bank-fill"></i> Pago por transferencia</Typography>}
                    {render === "tc" && <Typography variant="caption"><i className="ri-bank-fill"></i> Pago con Tarjeta de crédito</Typography>}
                </Box>
            </Grid>
            <Grid item xs={8} md={6}>
                <Grid container spacing={3} direction="row">
                    <Grid item xs={6}>
                        <Box>
                            <Box><Typography variant="caption" className="caption-small text-gray-500">Estudiante</Typography></Box>
                            <Box><Typography variant="body2" className='text-gray-100'>{methods.Nombre}</Typography></Box>
                        </Box>
                        {render !== "tc" && 
                            <Box className='mt-3'>
                                <Box><Typography variant="caption" className="caption-small text-gray-500">Vigencia</Typography></Box>
                                <Box><Typography variant="body2" className='text-gray-100'>{expiration}</Typography></Box>
                            </Box>
                        }
                        <Box display='flex' className='mt-3'>
                            <Typography variant="h5" className="text-gray-100">{`Saldo a pagar:`}&nbsp;</Typography>                            
                            <Typography variant="h5" className="text-orange-300"><CurrencyFormat value={methods["Monto a Pagar"]} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" /></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box><Typography variant="caption" className="caption-small text-gray-500">{`Matrícula`}</Typography></Box>
                        <Box><Typography variant="body2" className='text-gray-100'>{methods.Matricula}</Typography></Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        {render === "ventanilla" ?
            <Box className={classes.cardContainer}>
                <Grid container spacing={3}>
                    {methods['Pago en Ventanilla']?.map((item, index) => {
                        return <Grid key={index} item xs={12} sm={6}>
                            <Card>
                                <CardContent>   
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            <Box className="px-1 py-1" display={'flex'} justifyContent='center' alignItems='center' height={'100%'}><img style={{ height: 20 }} draggable={false} src={bankInfo(item.Banco).Logo} alt="Pago en Ventanilla" /></Box>
                                        </Grid>
                                        <Grid container item xs={6} spacing={2}>
                                            {item.Clave ? <Grid item xs={12}><Box><Typography variant="caption" className="caption-small text-gray-500">Clave:</Typography></Box><Box><Typography variant="caption" className="semi-bold text-gray-100">{item.Clave}</Typography></Box></Grid> : null}
                                            {item.Convenio ? <Grid item xs={12}><Box><Typography variant="caption" className="caption-small text-gray-500">Convenio:</Typography></Box><Box><Typography variant="caption" className="semi-bold text-gray-100">{item.Convenio}</Typography></Box></Grid> : null}
                                            {item.Referencia ? <Grid item xs={12}><Box><Typography variant="caption" className="caption-small text-gray-500">Referencia:</Typography></Box><Box><Typography variant="caption" className="semi-bold text-gray-100">{item.Referencia}</Typography></Box></Grid> : null}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Box> : null
        }
        {render === "transferencia" ?
            <Box className={classes.cardContainer}>
                <Grid container spacing={3}>
                    {methods?.Trnsferencia?.map((item, index) => {
                        return <Grid key={index} item xs={12} sm={6}>
                            <Card>
                                <CardContent>   
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            <Box className="px-1 py-1" display={'flex'} justifyContent='center' alignItems='center' height={'100%'}><img style={{ height: 20 }} draggable={false} src={bankInfo("SAN").Logo} alt="Pago por Transferencia" /></Box>
                                        </Grid>
                                        <Grid container item xs={6} spacing={2}>
                                            <Grid item xs={12}><Box><Typography variant="caption" className="caption-small text-gray-500">CLABE:</Typography></Box><Box><Typography variant="caption" className="semi-bold text-gray-100">{item['Clabe (Banco Santander)']}</Typography></Box></Grid>
                                            <Grid item xs={12}><Box><Typography variant="caption" className="caption-small text-gray-500">Referencia:</Typography></Box><Box><Typography variant="caption" className="semi-bold text-gray-100">{item.Referencia}</Typography></Box></Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Box> : null
        }
        { render === "tc" && 
            <Box className={classes.cardContainer}>
                <Grid container spacing={12}>
                    {methods?.TC?.map((card, index) => {
                        return <Grid key={index} item xs={6} className={classes.tcContainer}>
                            <Grid container spacing={3} className={classes.cardTotalWrap} direction="row" >
                                <Grid item xs={5}>
                                    <Typography variant="body2" className="semi-bold text-gray-500">Monto: </Typography>
                                </Grid>
                                <Grid item xs={7} align="right" >
                                    <Typography align="right" variant="body2" className="semi-bold text-gray-100">
                                        <CurrencyFormat value={methods['Monto a Pagar']} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="body2" className="semi-bold text-gray-500">Comisión: </Typography>
                                </Grid>
                                <Grid item xs={7} align="right" >
                                <Typography align="right" variant="body2" className="semi-bold text-gray-100">
                                        <CurrencyFormat value={card.Comision} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="body2" className="semi-bold text-gray-500">IVA: </Typography>
                                </Grid>
                                <Grid item xs={7} align="right" >
                                    <Typography align="right" variant="body2" className="semi-bold text-gray-100">
                                        <CurrencyFormat value={card.Iva} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Grid container spacing={3} direction="row" >
                                                <Grid item xs={5}>
                                                    <Typography variant="body2" className="semi-bold text-white">Total a pagar: </Typography>
                                                </Grid>
                                                <Grid item xs={7} align="right">
                                                    <Typography align="right" variant="body2" className="semi-bold text-white">
                                                        <CurrencyFormat value={card.Monto} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={`${classes.cardFrameWrap} offset-margin-top-12`}>
                                <Iframe width={"100%"} className={classes.paymentFrame} scrolling="no" height="700px" overflow="hidden" src={card.URL} />
                            </Grid>
                        </Grid>
                    })}
                </Grid>
            </Box>
        }

        <PaymentDetail display="none" displayPrint="block" methods={methods} adeudos={adeudos} />

        <Box display='flex' displayPrint="none" className={classes.btnContainer}>
            <Box><Button size="medium" variant="contained" label="Regresar" onClick={onClickReturnToPaymentMethods}>Regresar</Button></Box>
            {render !== "tc" && <Box className={classes.btnPrint}><Button size="medium" variant="contained" color='primary' onClick={handlePrint}>Imprimir</Button></Box>}
        </Box>
    </Box>
}

PaymentReceipt.defaultProps = {
    student: {},
    adeudos: [],
    methods: [],
    render: ""
};

export default PaymentReceipt;
