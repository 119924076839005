import React from "react";
import classnames from "classnames";
//@Material-ui
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  itemCarousel: {
    opacity: 0,
    position: "absolute",
    top: 0,
    zIndex: 100
  },
  active: {
    opacity: 1,
    position: "relative",
    zIndex: 900,
    width: "100%",
    margin: "auto",
    transition: "transform .5s, opacity .5s, z-index .5s"
  }, 
 previous: {
     transform: "translateX(-100%)"
  },
  next: {
    transform: "translateX(100%)"
  }
}));

export const CardCarousel = (props) => {
  const classes = useStyles();
  return (
    <div
      className={classnames(
        props.active ? classes.active : classes.itemCarousel,
        props.previous ? classes.previous : "",
        props.next ? classes.next : ""
      )}
    >
      {props.data}
    </div>
  );
};