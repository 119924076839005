import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

function getCheckboxesValue(value, item){
    if(item.checked){
        value.push(item);
    }else{
        const i = value.findIndex(it => it.id === item.id);
        value.splice(i, 1);
    }

    return value;
}

const Checkboxes = ({label, required, error, onChange, items, itemsSelected, disabled, flexDirectionRow, labelProps}) => {
    // const isUpSm = useMediaQuery(theme => theme.breakpoints.up('sm'));
    
    const handleChange = (item) => event => {
        item.checked = event.target.checked;

        const value = getCheckboxesValue(itemsSelected, item);
        if(onChange){
            onChange(value)
        }
    };
    
    return (
        <Box display="flex" alignItems="center" height="100%">
            <FormControl component="fieldset" required={required} error={error} disabled={disabled}>
                <FormLabel component="legend" {...labelProps}>{label}</FormLabel>

                <FormGroup row={flexDirectionRow}>
                    {
                        items.map(item => (
                            <FormControlLabel key={item.id}
                                control={
                                    <Checkbox 
                                        value={item.id}
                                        name={item.name} 
                                        checked={itemsSelected.some(it => it.id === item.id)}
                                        onChange={handleChange(item)}
                                        color="primary"
                                    />}
                                label={item.label}
                            />
                        ))
                    }
                </FormGroup>
                {error && <FormHelperText>Campo Requerido</FormHelperText>}
            </FormControl>
        </Box>
    );
};

Checkboxes.defaultProps = {
    required: false,
    error: false,
    style: {},
    disabled: false,
    itemsSelected: [],
    flexDirectionRow: false
};

Checkboxes.propTypes = {
    label: PropTypes.string,
    /**
     * Array of options
     */
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    /**
     * Optional click handler
     */
    onChange: PropTypes.func,
    itemsSelected: PropTypes.array,
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    flexDirectionRow: PropTypes.bool,
};

export default Checkboxes;