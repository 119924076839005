import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Hidden, TextField, Typography } from '@material-ui/core';

// @shared/utils
import * as format from '../../utils/format';

import visa from '../../../assets/images/banks/visa-logo.svg';
import mastercard from '../../../assets/images/banks/master-card-logo.svg';
import american_express from '../../../assets/images/american_express.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.colors.grey[500],
        border: `0.5px solid ${theme.palette.colors.grey[400]}`,
        borderRadius: '8px',
    },
    rootLabel: {
        padding: '24px',
    },
    subRoot: {
        padding: '32px 88px 32px 88px',
        '& .right': {
            textAlign: 'right',
        },
        '& .left': {
            textAlign: 'left',
        },
        '& .bottom': {
            verticalAlign: 'bottom',
        }
    },
    masterCard: {
        fontSize: '2rem',
        float: 'right',
    },
    visa: {
        fontSize: '3.5rem',
        float: 'right',
    },
    american_express: {
        float: 'right',
        width: '54px',
    },
    title: {
        color: theme.palette.colors.violet[300],
    },
    button: {
        textTransform: 'none',
    },
    line: {
        border: 0,
        borderBottom: `0.5px solid ${theme.palette.colors.grey[50]}`,
        margin: 0,
    },
}));

export default function AddPaymentCard(props) {
    const { user } = useSelector((state) => state.userReducer);
    const classes = useStyles();

    const handleFormatCreditCardNumber = (e) => {
        if (e.target.value) {
            e.target.value = format.creditCardNumber(e.target.value);
        }
    };

    const handleFormatSecurityCreditCardNumber = (e) => {
        if (e.target.value) {
            e.target.value = format.securityCardNumber(e.target.value);
        }
    };

    const handleFormatCreditCardExp = (e) => {
        if (e.target.value) {
            e.target.value = format.creditCardExp(e.target.value);
        }
    };

    const handleFormatPhoneNumber = (e) => {
        if (e.target.value) {
            e.target.value = format.phoneNumber(e.target.value);
        }
    };

    return (
        <Box className={classes.subRoot}>
            {props.enableTitle &&
                <React.Fragment>
                    <Typography variant='h5'><i className="ri-bank-card-fill bottom mr-2"></i>Agregar una tarjeta de crédito o débito</Typography>
                    <hr className={`${classes.line} mt-3 mb-5`} />
                </React.Fragment>}
            <Grid container spacing={2}>
                <Hidden key="desktop" mdDown>
                    <Grid item xs={5} className="left">
                        <Typography component="span" variant="body2" className="font-weight-700">Titular de la tarjeta</Typography>
                    </Grid>
                    <Grid item xs={1}>&nbsp;</Grid>
                    <Grid item xs={6} className="left">
                        <Typography component="span" variant="body2" className="font-weight-700">Número de la tarjeta</Typography>
                        <img src={american_express} alt="American Express" className={classes.american_express} />
                        <img src={mastercard} alt="Master Card" className={`${classes.masterCard} mr-3`} />
                        <img src={visa} alt="Visa" className={`${classes.visa} mr-3`} />
                    </Grid>
                    <Grid item xs={5} className="right">
                        <TextField
                            name="card_holder"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            placeholder="Nombre en la tarjeta"
                            InputProps={{
                                className: 'bg-fill-stroke-400',
                            }}
                            margin="dense"
                            onInput={props.handleInput}
                            value={props?.data?.card_holder}
                        />
                    </Grid>
                    <Grid item xs={1}>&nbsp;</Grid>
                    <Grid item xs={6} className="right">
                        <TextField
                            id="card_number"
                            name="card_number"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            placeholder="Agregar un número de tarjeta"
                            InputProps={{
                                startAdornment: <i className="ri-bank-card-fill text-dark-black-200 mr-2"></i>,
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                maxLength: 19,
                            }}
                            margin="dense"
                            onInput={(e) => {
                                handleFormatCreditCardNumber(e);
                                props.handleInput(e);
                            }}
                            value={props?.data?.card_number}
                        />
                    </Grid>
                    <Grid item xs={5} className="mt-2">
                        <Typography component="span" variant="body2" className="font-weight-700">Correo electrónico</Typography>
                    </Grid>
                    <Grid item xs={1}>&nbsp;</Grid>
                    <Grid item xs={3} className="mt-2">
                        <Typography component="span" variant="body2" className="font-weight-700">Fecha de vencimiento</Typography>
                    </Grid>
                    <Grid item xs={3} className="mt-2">
                        <Typography component="span" variant="body2" className="font-weight-700">Código de seguridad</Typography>
                    </Grid>
                    <Grid item xs={5} className="mt-2">
                        <TextField
                            name="card_email"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            InputProps={{
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                className: 'text-dark-black-200',
                                readOnly: true,
                            }}
                            placeholder={user.email}
                            margin="dense"
                            onInput={props.handleInput}
                            value={user.email}
                        />
                    </Grid>
                    <Grid item xs={1}>&nbsp;</Grid>
                    <Grid item xs={3} className="mt-2">
                        <TextField
                            name="card_expiration"
                            variant="outlined"
                            size="medium"
                            InputProps={{
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                maxLength: 5,
                            }}
                            placeholder="MM/AA"
                            margin="dense"
                            onInput={(e) => {
                                handleFormatCreditCardExp(e);
                                props.handleInput(e);
                            }}
                            value={props?.data?.card_expiration}
                        />
                    </Grid>
                    <Grid item xs={3} className="mt-2">
                        <TextField
                            name="card_cvv"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            InputProps={{
                                startAdornment: <i className="ri-lock-2-line bottom text-dark-black-200 mr-2"></i>,
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                maxLength: 4,
                            }}
                            type="password"
                            placeholder="CVV"
                            margin="dense"
                            onInput={(e) => {
                                handleFormatSecurityCreditCardNumber(e);
                                props.handleInput(e);
                            }}
                            value={props?.data?.card_cvv}
                        />
                    </Grid>
                    <Grid item xs={5} className="mt-2">
                        <Typography component="span" variant="body2" className="font-weight-700">Número de celular</Typography>
                    </Grid>
                    <Grid item xs={1}>&nbsp;</Grid>
                    <Grid item xs={6} className="mt-2">
                        <Typography variant="caption" className="text-dark-black-200 "><i className="ri-lock-2-line bottom mr-2"></i>Todos los pagos son seguros y encriptados.</Typography>
                    </Grid>
                    <Grid item xs={5} className="mt-2">
                        <TextField
                            name="card_phone"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            InputProps={{
                                startAdornment: <Typography variant="caption" className="text-dark-black-200 mr-2"><i className="ri-smartphone-line bottom text-dark-black-200 mr-2"></i>+52</Typography>,
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                maxLength: 14,
                            }}
                            placeholder="Número de celular"
                            margin="dense"
                            onInput={(e) => {
                                handleFormatPhoneNumber(e);
                                props.handleInput(e);
                            }}
                            value={props?.data?.card_phone}
                        />
                    </Grid>
                </Hidden>
                <Hidden key="responsive" lgUp>
                    <Grid item xs={12} className="left">
                        <Typography component="span" variant="body2" className="font-weight-700">Titular de la tarjeta</Typography>
                    </Grid>
                    <Grid item xs={12} className="right">
                        <TextField
                            name="card_holder"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            placeholder="Nombre en la tarjeta"
                            InputProps={{
                                className: 'bg-fill-stroke-400',
                            }}
                            margin="dense"
                            onInput={props.handleInput}
                            value={props?.data?.card_holder}
                        />
                    </Grid>
                    <Grid item xs={12} className="left">
                        <Typography component="span" variant="body2" className="font-weight-700">Número de la tarjeta</Typography>
                        <img src={american_express} alt="American Express" className={classes.american_express} />
                        <img src={mastercard} alt="Master Card" className={`${classes.masterCard} mr-3`} />
                        <img src={visa} alt="Visa" className={`${classes.visa} mr-3`} />
                    </Grid>
                    <Grid item xs={12} className="right">
                        <TextField
                            id="card_number"
                            name="card_number"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            placeholder="Agregar un número de tarjeta"
                            InputProps={{
                                startAdornment: <i className="ri-bank-card-fill text-dark-black-200 mr-2"></i>,
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                maxLength: 19,
                            }}
                            margin="dense"
                            onInput={(e) => {
                                handleFormatCreditCardNumber(e);
                                props.handleInput(e);
                            }}
                            value={props?.data?.card_number}
                        />
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Typography component="span" variant="body2" className="font-weight-700">Correo electrónico</Typography>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <TextField
                            name="card_email"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            InputProps={{
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                className: 'text-dark-black-200',
                                readOnly: true,
                            }}
                            placeholder={user.email}
                            margin="dense"
                            onInput={props.handleInput}
                            value={user.email}
                        />
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Typography component="span" variant="body2" className="font-weight-700">Fecha de vencimiento</Typography>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <TextField
                            name="card_expiration"
                            fullWidth
                            variant="outlined"
                            size="medium"
                            InputProps={{
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                maxLength: 5,
                            }}
                            placeholder="MM/AA"
                            margin="dense"
                            onInput={(e) => {
                                handleFormatCreditCardExp(e);
                                props.handleInput(e);
                            }}
                            value={props?.data?.card_expiration}
                        />
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Typography component="span" variant="body2" className="font-weight-700">Código de seguridad</Typography>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <TextField
                            name="card_cvv"
                            fullWidth
                            variant="outlined"
                            size="medium"
                            InputProps={{
                                startAdornment: <i className="ri-lock-2-line bottom text-dark-black-200 mr-2"></i>,
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                maxLength: 4,
                            }}
                            type="password"
                            placeholder="CVV"
                            margin="dense"
                            onInput={props.handleInput}
                            value={props?.data?.card_cvv}
                        />
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Typography component="span" variant="body2" className="font-weight-700">Número de celular</Typography>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <TextField
                            name="card_phone"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            InputProps={{
                                startAdornment: <Typography variant="caption" className="text-dark-black-200 mr-2"><i className="ri-smartphone-line bottom text-dark-black-200 mr-2"></i>+52</Typography>,
                                className: 'bg-fill-stroke-400',
                            }}
                            inputProps={{
                                maxLength: 14,
                            }}
                            placeholder="Número de celular"
                            margin="dense"
                            onInput={(e) => {
                                handleFormatPhoneNumber(e);
                                props.handleInput(e);
                            }}
                            value={props?.data?.card_phone}
                        />
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Typography variant="caption" className="text-dark-black-200 "><i className="ri-lock-2-line bottom mr-2"></i>Todos los pagos son seguros y encriptados.</Typography>
                    </Grid>
                </Hidden>
                {props.enableActionButtons &&
                    <React.Fragment>
                        <Hidden key="desktop" mdDown>
                            <Grid item xs={1}>&nbsp;</Grid>
                            <Grid item xs={5} className="left mt-3">
                                <Button variant="contained" className="mr-4 bg-fill-stroke-500" onClick={props.onBackAction}>Regresar</Button>
                                <Button variant="contained" color="primary" className={classes.button} onClick={!props.submitted ? props.handleSubmit : ''}>{!props.submitted ? 'Agregar tarjeta' : 'Agregando tarjeta...'}</Button>
                            </Grid>
                        </Hidden>
                        <Hidden key="responsive" lgUp>
                            <Grid item xs={12} className="center mt-3">
                                <Button variant="contained" fullWidth className="mr-4 bg-fill-stroke-500" onClick={props.onBackAction}>Regresar</Button>
                            </Grid>
                            <Grid item xs={12} className="left mt-3">
                                <Button variant="contained" fullWidth color="primary" className={classes.button} onClick={!props.submitted ? props.handleSubmit : ''}>{!props.submitted ? 'Agregar tarjeta' : 'Agregando tarjeta...'}</Button>
                            </Grid>
                        </Hidden>
                    </React.Fragment>}
            </Grid>
        </Box>
    )
};

AddPaymentCard.propTypes = {
    enableTitle: PropTypes.bool.isRequired,
    enableActionButtons: PropTypes.bool.isRequired,
    onBackAction: PropTypes.func,
    handleInput: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
};