import React from 'react'

// @material-ui/core
import { Card, Typography } from '@material-ui/core'
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import moment from 'moment';

// component-styles
import './horarioStyles.css'

const semana = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];

const Clase = (props) => {

    const diasClase = [];
    props.materias.map(materia => materia.dia.map(dia => diasClase.push(dia)));

    const materiasDia = {};
    let weekDates = props.weekPeriod[Object.keys(props.weekPeriod)[0]];
    weekDates = weekDates.map(date => new Date(date).toISOString().substring(0, 10));
    weekDates.map(weekDate => {
        const _weekDate = moment(weekDate);
        props.materias.map(materia => materia.dia.map(dia => {
            if (_weekDate.isSameOrAfter(materia.fechaInicio)) {
                if (_weekDate.isSameOrBefore(materia.fechaFin)) {
                    materiasDia[dia] = materia;
                }
            }
        }));
    });
    
    return (
        Object.keys(materiasDia).length !== 0 &&  
            <Card variant='outlined' className='week-row mt-3'>
                <Card color="default" className='hour-chip'>
                    <Typography>{props.horario}</Typography>
                </Card>
                {
                    semana.map((dia, id) => {
                        if (!diasClase.includes(dia)) {
                            return <Card key={id} color="default" className={`class-chip`} />;
                        }
                        return (
                            materiasDia[dia] && 
                            <Card key={id} color="default" className={`class-chip ${dia !== null && "enabled"}`}>
                                <Typography variant="caption">{materiasDia[dia].periodo}</Typography>
                                <Typography variant="caption">{materiasDia[dia].materia}</Typography>
                                <Typography variant="caption">
                                    <HomeWorkIcon fontSize='inherit' className='mr-1' />
                                    {materiasDia[dia].grupo} / {materiasDia[dia].salon}
                                </Typography>
                            </Card>
                        )
                    })
                }
            </Card>
    )
}

export default Clase
