import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const StudentInfo = ({ order }) => {
    const location = useLocation();

    return (
        <Card className='bg-fill-stroke-400'>
            <Box display="flex" flexDirection="column" p="24px">
                <Typography variant="h5" className="text-gray-100">Información de estudiante</Typography>
                <Box mt="16px">
                    <Grid container spacing={2} mar>
                        <Grid item>
                            <Box mb="10px"><Typography variant="body2" className="semi-bold">Nombre:</Typography></Box>
                            <Box mb="10px"><Typography variant="body2" className="semi-bold">Teléfono:</Typography></Box>
                            <Typography variant="body2" className="semi-bold">Correo:</Typography>
                        </Grid>
                        <Grid item>
                            <Box mb="10px"><Typography variant="body2" className="text-gray-100">{order?.person?.first_name} {order?.person?.last_name}</Typography></Box>
                            <Box mb="10px"><Typography variant="body2" className="text-gray-100">{order?.person?.phone_number}</Typography></Box>
                            <Typography variant="body2" className="text-gray-100">{order?.person?.email || location?.state?.user?.email || location?.state?.user?.email_address}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Card>
    )
};

export default StudentInfo;