import React from 'react'
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// redux
import { connect } from 'react-redux';

//@material-ui/core
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// style
import useStyles from '../../../assets/styles/jss/pages/memberships/membershipMonthlyPlanStyle.js';

// design-system
import Loader from '../../../design-system/components/Loader/LoaderContent';

// utils
import { getPreviousMembership } from '../../../shared/utils/membershipUtils';
import SignupForm from '../../../layout/components/Signup/SignupForm/SignupForm';

// constants
import { MEMBERSHIPS } from '../../../shared/constants/memberships';

// services
import { MarketPlaceService } from '@sdk-point/talisis';

// components
import MembershipPlanCard from '../../Memberships/MembershipPlans/MembershipPlanCard/MembershipPlanCard';
import MembershipBenefits from './components/MembershipBenefits.jsx';
import StudentFormModal from '../../../pages/Market/Checkout/CheckoutSummary/StudentInfo/StudentFormModal/StudentFormModal';
import MembershipBenefitsData from './membershipBenefitsData.js';
import * as paths from '../../../routes/paths';

// shared components
import { log } from '../../../shared/utils/console.js';
import { setCheckoutCookie, defaultCheckoutCookie } from '../../../shared/utils/checkoutSession-legacy.js';
import { userIsLogged } from '../../../shared/utils/Sessions'
import { checkoutSteps } from '../../Market/Checkout/CheckoutSummary/helpers/checkoutSummaryHelpers.js';

const PLAN_MENSUAL_SLUG = "MENS";
const MEMBERSHIP_SECTION = "membresias";
const SIGNUP_SECTION = "registrarse";

const MembershipMonthlyPlan = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [loading, setLoading] = React.useState(false);
    const [signupLoading, setSignupLoading] = React.useState(false);
    const [memberships, setMemberships] = React.useState([]);
    const [membershipSelectedId, setMembershipSelectedId] = React.useState(MEMBERSHIPS.PROFESSIONAL);
    const feePlan = PLAN_MENSUAL_SLUG;
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
    const membershipScrollRef = React.useRef(null);
    const signupScrollRef = React.useRef(null);
    const isLogged = userIsLogged();
    const { user } = props;
    const appBarTop = isLogged ? 65 : 120; //px

    const scrollToSection = (id) => {
        if (id === MEMBERSHIP_SECTION && membershipScrollRef.current) {
            const scrollTop = membershipScrollRef.current.offsetTop - appBarTop;
            window.scrollTo({ top: scrollTop, behavior: 'smooth' });
        }
        else if (id === SIGNUP_SECTION && signupScrollRef.current) {
            const scrollTop = signupScrollRef.current.offsetTop - appBarTop;
            window.scrollTo({ top: scrollTop, behavior: 'smooth' });
        }
    };

    const getMemberships = async () => {
        try {
            setLoading(true);
            const personMembership = await marketService.getPersonMembership(null);
            if (personMembership?.memberships?.length > 0) {
                const memberships = personMembership?.memberships.filter(m => m.is_active && m.level > 1);
                setMemberships(memberships);
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (isLogged) {
            if (!user?.membership_id || user?.membership_id === MEMBERSHIPS.FREEMIUM) {
                getMemberships();
            } else {
                history.push(paths.MEMBRESIAS);
            }
        } else {
            getMemberships();
        }
    }, []);

    const handleMembershipSelect = (value) => {
        setMembershipSelectedId(value);
        scrollToSection(SIGNUP_SECTION);
    };

    const handleLoadingModal = (isLoading) => {
        setSignupLoading(isLoading);
    };

    const handleSignupSuccess = (success, response, form) => {
        log("usuario registrado exitosamente:", response);
        log("datos usuario:", form);

        const membership = memberships.filter(m => m.id === membershipSelectedId)[0];
        const membershipFee = membership?.fees?.find((value) => value.term_slug === feePlan);
        const membershipFeeId = membershipFee !== undefined && membershipFee !== null ? membershipFee.id : 0;

        const object = {
            membership_id: membershipSelectedId,
            membership_fee_id: membershipFeeId,
            person_id: response?.person_id
        };

        const userInfo = {
            userName: form?.first_name + ' ' + form?.last_name,
            email: form?.email,
            person_id: response?.person_id,
            is_admin: false,
            gender: form?.gender,
            ou: 'POINT',
            ouLabel: 'POINT',
            company_id: 'POINT',
            membership_id: MEMBERSHIPS.FREEMIUM,
            interests: [],
            first_name: form?.first_name,
            last_name: form?.last_name,
            phone_number: form?.phone,
            activateAccountURL: response?.activateAccountURL
        };

        marketService.generateCheckoutToken(object)
            .then(response => {
                const searchParams = `?token=${response.token}`;
                log('response', response);

                setCheckoutCookie({
                    ...defaultCheckoutCookie,
                    personId: userInfo.person_id,
                    personInfo: userInfo,
                    initialStep: checkoutSteps.PAYMENT_METHODS,
                    items: [{
                        membership_info: {
                            membershipId: membershipSelectedId,
                            membershipFee: membershipFeeId
                        }
                    }],
                    membershipSession: true,
                    activateAccountURL: response?.activateAccountURL,
                    activeAccount: false,
                    readOnlySteps: [checkoutSteps.MEMBERSHIP_PERIODS]
                });

                history.replace({
                    pathname: paths.CHECKOUT_LEGACY,
                    search: searchParams,
                    state: {
                        'user': userInfo,
                        'referrer': "course-landingpage"
                    }
                });
            })
            .catch(error => { log('Error', error) })
    };

    const handleSignupError = (message) => {
        log(message);
    };

    return <Box>
        <Box className={classes.headerContainer}>
            <Box className={classes.radialContainer}>
                <Grid container direction="row" justifyContent="center" alignItems={breakpoint ? "flex-start" : "center"} style={{ height: '100%' }}>
                    <Grid item>
                        <Box>
                            <Grid container direction='row' spacing={3}>
                                <Grid item xs={12}>
                                    <Hidden smDown>
                                        <Box>
                                            <Typography variant='h1' display='block'>Obtén tu membresía</Typography>
                                            <Typography variant='h1' display='inline'>Talisis</Typography>
                                            <Typography variant='h1' display='inline' color='secondary'>&nbsp;mensual</Typography>
                                        </Box>
                                    </Hidden>
                                    <Hidden mdUp>
                                        <Box>
                                            <Typography variant='h1' display='block'>Obtén tu</Typography>
                                            <Typography variant='h1' display='inline'>membresía Talisis</Typography>
                                            <Typography variant='h1' display='block' color='secondary'>mensual</Typography>
                                        </Box>
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12} md="auto">
                                    <Button color='primary' variant='contained' size='large' className={classes.referralsMembershipsButton} onClick={() => { scrollToSection(MEMBERSHIP_SECTION) }} fullWidth={breakpoint ? true : false}>
                                        Ver membresías
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box className={classes.benefitsContainer}>
            <Box className='mb-5 pb-3'>
                <Typography variant="h2" align='center' className={clsx("title")}>Disfruta de todos los beneficios</Typography>
            </Box>
            <Grid container direction='row' justifyContent="center" alignItems="center" spacing={3}>
                {
                    MembershipBenefitsData.map((benefit, index) => {
                        return (
                            <MembershipBenefits
                                key={index}
                                icon={benefit.icon}
                                title={benefit.title}
                                body={benefit.body}
                                classes={classes}
                            />
                        )
                    })
                }
            </Grid>
        </Box>
        <Box className={classes.membershipsContainer} ref={membershipScrollRef}>
            <Loader loading={loading} minHeight="500px">
                <Box className={classes.membershipCards}>
                    <Grid container spacing={3} justifyContent='center'>
                        {memberships.map((membership, index) =>
                            <Grid key={index} item className={classes.grid}>
                                <MembershipPlanCard
                                    id={membership.slug}
                                    name={membership.name}
                                    popular="professional"
                                    fees={membership?.fees}
                                    membershipId={membership.id}
                                    feePlan={feePlan}
                                    calculateStrikeOutPrice="standard-discount"
                                    media={{
                                        showIcon: true,
                                        fee: membership?.fees?.filter(fee => fee.term_slug === feePlan)[0]
                                    }}
                                    content={{
                                        previous: getPreviousMembership(membership, memberships)
                                    }}
                                    user={user}
                                    isResponsive={isResponsive}
                                    actionType={!isLogged ? "checker" : null}
                                    customCheckoutCookie={{
                                        initialStep: checkoutSteps.PAYMENT_METHODS,
                                        readOnlySteps: [checkoutSteps.MEMBERSHIP_PERIODS]
                                    }}
                                    membershipSelected={membershipSelectedId}
                                    onMembershipSelect={handleMembershipSelect}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Loader>
        </Box>
        {!isLogged && (
            <Box className={classes.signupContainer} ref={signupScrollRef}>
                <Box className='mb-4 pb-3'>
                    <Typography variant="h2" align='center' className={clsx("title")}>Regístrate</Typography>
                </Box>
                <Box>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs="auto">
                            <Card className={classes.signupCard}>
                                <CardContent className={classes.signupCardContent}>
                                    <SignupForm onLoading={handleLoadingModal} onSignupError={handleSignupError} onSignupSuccess={handleSignupSuccess} /*footerExtras={footerExtras}*/ isReferralPage={true} /*refererId={referer_id}*/ />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <StudentFormModal
                    modalOpenFunc={signupLoading} modalCloseFunc={!signupLoading}
                    loading={signupLoading}
                />
            </Box>
        )}
    </Box>
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(MembershipMonthlyPlan);