import React from "react";
import * as Cookies from "js-cookie";

export const defaultCheckoutCookie = {
    anonymousSession: true,
    orderId: null,
    personId: 0,
    personInfo: {},
    items: [],
    checkoutStep: 0,
    hasFreeCoupon: false,
    activeAccount: null, // Cuenta del usuario está activa o inactiva
    activateAccountURL: "",
    membershipSession: false,
    paymentToken: "",
    admissionId: "",
    isRepurchase: false,
    isReferred: false,
    initialStep: 0, // Id del paso inicial del flujo checkout
    readOnlySteps: [] // Arreglo para indicar los pasos no editables
}

export const setCheckoutCookie = (session = defaultCheckoutCookie) => {
    Cookies.remove("checkout");
    Cookies.set("checkout", session, { expires: 1 });
};

export const getCheckoutCookie = () => {
    const checkoutCookie = Cookies.get("checkout");

    if (!checkoutCookie) {
        return {};
    } else {
        return JSON.parse(checkoutCookie);
    }
};

export const SessionContext = React.createContext(getCheckoutCookie());