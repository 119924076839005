import { log } from '../../../utils/console.js'

const types = {
    addCourses: 'add - courses',
    ADD_PROGRAMS: 'ADD_PROGRAMS',
}

const initialCourses = {
    courses: [],
    programs: []
}

const coursesReducer = (state = initialCourses, action) => {
    log('action?.type', action?.type, action?.payload)
    switch(action?.type){
        case types?.addCourses:
            return {
                ...state,
                courses: action?.payload ? action.payload.slice() : []
            }
        case types.ADD_PROGRAMS:
            return {
                ...state,
                programs: [...state.programs, ...action.payload]
            };
        default:
            return state;
    }
}

export { initialCourses, types }
export default coursesReducer;