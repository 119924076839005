import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

//@material-ui/icons
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

// shared- components
import TreeList from '../../../../../shared/components/TreeList/TreeList';
import AddAuthorizers from './components/AddAuthorizers';

// dependencies
import { v4 as uuid } from 'uuid';

//SDK
import { GlobalService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const SECOND_LEVEL = 2;

const SCOPE_OU = 1;
const SCOPE_CAMPUS = 2;

const form = {
    ou: 'ADV',
    assignee: [{id: 1,first_name: 'Brizeth', last_name: 'Puente', full_name: 'Damaris'}],
    campus: [{label: 'Hospital ABC', id: 'ABC', name: 'Hospital ABC', company_id:'ADV', is_active: true, label: 'Hospital ABC'}]
}

const type_authorizers = [
    {
        "id": 1,
        "title": "Authorizadores",
        "name": "authorizadores",
    },
    {
        "id": 2,
        "title": "Notificadores",
        "name": "notificadores",
    }
]

const afake = [
    {
        "person": {"id": 667,"first_name": "Brizeth", "last_name": "Puente", "full_name": "Damaris"},
        "scope_ids": ["ADV"],
        "scope_type_id": 1,
        "parent": "ADV"
    },
    {
        "person": {"id": 668,"first_name": "Francisco", "last_name": "Hinojosa", "full_name": "Francisco Hinojosa"},
        "scope_ids": ["ABC", "ARM"],
        "scope_type_id": 2,
        "parent": "ADV"
    }
]

const TreeItem = (props) => {
    const {item, onClickDelete, onClickEdit, readOnly} = props;
    return (
        <Box display="flex" alignItems="center">
            <Typography variant="body2">{item.label}</Typography>
            {
                !readOnly &&
                <Box ml={1}>
                    <IconButton aria-label="delete" style={{padding: 1}} color="primary" onClick={() => onClickDelete(item)}>
                        <DeleteOutlineIcon className="font-size-18"/>
                    </IconButton>
                    <IconButton aria-label="edit" style={{padding: 1}} color="primary" onClick={() => onClickEdit(item)}>
                        <EditOutlinedIcon className="font-size-18" />
                    </IconButton>
                </Box>
            }
        </Box>
    )
}

const Authorizers = (props) => {
    const [openAddAuthorizers, setOpenAddAuthorizers] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [operatingUnitsList, setOperatingUnitsList] = React.useState([]);
    const [authorizerForm, setAuthorizerForm] = React.useState({});
    const [authorizersByOU, setAuthorizersByOU] = React.useState([]);
    const [authorizerTreeList, setAuthorizerTreeList] = React.useState([]);
    React.useEffect(() => {
        setAuthorizersByOU(props.authorizers);
        setDisabled(props.disabled);

        if(props.ous.length){
            if(operatingUnitsList.length){
                setAuthorizerTreeList(generateTreeList(operatingUnitsList, props.authorizers))   
            }else{
                getOUs(props.ous);
            }
        }
    }, [props.authorizers, props.disabled]);

    const generateTreeList = (ous, authorizerByOU) =>{
        const authorizer = [...authorizerByOU];

        const items = [];
        
    
        for(const ou of ous){
            ou.level = 1;
            ou.children = [];

            const _authorizerByOu = authorizer.filter(it => it.parent === ou.id);
        
            if(_authorizerByOu.length){
                for( const type_auth of type_authorizers){

                    let newNode = {}
                    let childrenTypes = [];
                    let typeAuthorizadores = _authorizerByOu.filter(it => it.authorizer_type_id === type_auth.id)

                    for(const a of typeAuthorizadores){
                        // const idSecondLevel = `${ou.id}-${SECOND_LEVEL}-${indexSecondLevel}`;
                        const idSecondLevel = a.id;
        
                        let childrenByPerson = [{
                            id: 'all',
                            nodeId: `${idSecondLevel}-0`,
                            label: 'Todos los campus',
                            level: 4,
                            name: 'all',
                            parent: idSecondLevel,
                        }];
                    
                        if(a.scope_type_id === SCOPE_CAMPUS){
                            childrenByPerson = ou.campus.filter(c => a.scope_ids.some(s => s.id === c.id)).map((it, index) => ({
                                id: it.id,
                                nodeId: `${idSecondLevel}-${index}`,// ou-level-indexparent-index
                                level: 4,
                                parent: idSecondLevel,
                                label: it.name
                            }))
                        }
        
                        newNode = {
                            id: idSecondLevel, // ou-level-index
                            label: a.person.full_name,
                            level: 3,
                            name: "autorizadores",
                            parent: a.authorizer_type_id,
                            style: {color: '#00C1DE'},
                            children: childrenByPerson
                        };
    
                        newNode.component = <TreeItem item={newNode} onClickDelete={handleClickDelete} onClickEdit={handleClickEdit(ou)} readOnly={props.readOnly}/>;

                        childrenTypes.push(newNode);
                    }

                    let newNodeTypeAuth = {
                        id: type_auth.id, // ou-level-index
                        label: type_auth.title,
                        level: 2,
                        name: type_auth.name,
                        parent: _authorizerByOu[0].parent,
                        style: {color: '#00C1DE'},
                        children: childrenTypes
                    }

                    ou.children.push(newNodeTypeAuth)
                }   
            }else{
                ou.children = [
                    {
                        id: 'no-authorizers',
                        label: 'Sin Autorizadores',
                        level: 2,
                        name: 'no-authorizers',
                        parent: ou.id,
                        style: {color: '#9e9e9f'},
                    }
                ];
            }
            
            items.push(ou);
        }
        return items;
    }

    const getOUs = async (ous) => {
        try {
            const response = await GlobalServiceSDK.getOperatingUnits();
            const _ous = response.filter(ou => ous.some(a => a.id === ou.id)).map(it => {
                it.label = it.name;
                it.campus = it.campus.map(c => ({label: c.name, ...c }));

                return it;
            });

            setOperatingUnitsList(_ous);
            setAuthorizerTreeList(generateTreeList(_ous, props.authorizers))

        } catch (error) {
            
        }
    }

    const handleClickDelete = (item) => {
        const _authorizers = [...props.authorizers].map(object => ({ ...object }));
        const i = _authorizers.findIndex(it => it.id === item.id);

        _authorizers.splice(i, 1);

        props.onChange(_authorizers)
    }

    const handleClickEdit = (ou) => (item) =>{
        const {id} = item;
        const authorizers = [...props.authorizers];
        const authorizerSelected = authorizers.find(it => it.id === id);

        const _campus = ou.campus;
        let otro = [];

        if(authorizerSelected.scope_type_id === SCOPE_OU){
            otro.push({
                id: 'all',
                label: 'Todos los campus',
                name: 'all',
            })

            for(const c of _campus){
                otro.push(c)
            }
        }else{
            for(const c of _campus){
                if(authorizerSelected.scope_ids.some(s => s.id === c.id)){
                    otro.push(c)
                }
            }
        }

        const form = {
            id: authorizerSelected.id,
            ou: authorizerSelected.parent,
            assignee: authorizerSelected.person,
            campus: otro,
            authorizertype: authorizerSelected.authorizer_type_id
        }

        setAuthorizerForm(form);
        setOpenAddAuthorizers(true)
    }

    const handeAddAuthorizers = items => {
        const {id, ou, assignee, campus, authorizertype} = items;

        const authorizers = [...authorizersByOU];
        const scopeTypeId = campus.findIndex(it => it.id === 'all') > -1 ? SCOPE_OU : SCOPE_CAMPUS;

        const idSecondLevel = `${ou}-${SECOND_LEVEL}-${uuid()}`;

        if(id){
            const i = authorizers.findIndex(it => it.id === id);
            authorizers[i] = {
                "person": assignee,
                "scope_ids": scopeTypeId === SCOPE_OU ? [ou] : campus,
                "scope_type_id": scopeTypeId,
                "parent": ou,
                id,
                "authorizer_type_id": authorizertype
            }

        }else{
            authorizers.push({
                "person": assignee,
                "scope_ids": scopeTypeId === 1 ? [ou] : campus,
                "scope_type_id": scopeTypeId,
                "parent": ou,
                "id": idSecondLevel,
                "authorizer_type_id": authorizertype
            })
        }


        setOpenAddAuthorizers(false);
        setAuthorizerForm({})
        props.onChange(authorizers)

    }

    const handleClose = () => {
        setOpenAddAuthorizers(false);
        setAuthorizerForm({})
    }

    const Items = (item) => {
        let i = [];
        i.push(item)
        return i;
    }



    return (
        <Box width="100%">
             {
                authorizerTreeList.map( (autho, index) => (
                    <div>
                        <Box>
                            <TreeList items={Items(autho)} />
                        </Box>

                        {
                            !props.readOnly &&
                            <AddAuthorizers
                                disabled={disabled}
                                open={openAddAuthorizers}
                                ous={operatingUnitsList}
                                ou={autho.id}
                                form={authorizerForm}
                                authorizer={props.authorizers} 
                                onSubmit={handeAddAuthorizers}
                                onClose={handleClose}/>
                        }
                    </div>
                ))
            }    
        </Box>
    );
};

export default Authorizers;