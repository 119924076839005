import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    boxRoot: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingLeft: "0px",
        paddingRight: "0px",
        [theme.breakpoints.down('xs')]:{
            marginBottom:'30px',
        }
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '16px',
        marginBottom: '32px',
        gap: '16px',
        width: '251px',
        marginRight: '1.5rem',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            marginRight: 0,
            marginBottom:'30px',
        }
    },    
    boxDataTimes: {
        display: 'flex',
        flexDirection: 'column',
        width: '230px',
        justifyContent: 'center',
        gap: '8px'
    },
    boxDataTimesSecond: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: '8px'
    },
    profilePic: {
        display: 'flex',
        alignItems: 'center',
    },
    boxAsesorData: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: '8px',
        width: '195px',
    },
    boxLogoOu: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        gap: '8px',
        width: '201px',
        height: '16px',
    },
    boxDataSession: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,
        gap: '8px',
        width: '230px',
        height: '40px',
        [theme.breakpoints.down('xs')]:{
            flexDirection: 'row',
            height: 'auto',
        }
    },
    boxSessionSpecific: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '230px',
        alignItems: 'center',
        padding: 0,
        gap: '8px',
    },
    boxButtonStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '219px',
        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    },
    buttonConsultancies:{
        width: "201px",
        height: "40px",
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            height: '48px'
        }
    },
    nothingFound:{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: 0, 
        margin: 0
    },
    boxStatus:{
        display: 'flex',
        alignItems: 'center',
        width: '219px',
        borderRadius: '16px',
        border: '1px solid ' + theme.palette.colors.fillStroke[200],
        height: '24px',
        margin: 'auto',
        padding: '6px 16px',
        gap: '8px',
        [theme.breakpoints.down('xs')]:{
            margin: 0,
            width: 'auto'
        }
    },
    nameStyle:{
        display: 'flex',
        flexDirection: 'column'
    },
    buttonActualizar:{
        background: theme.palette.colors.fillStroke[400],
        [theme.breakpoints.down('xs')]:{
            width: '70%'
        }
    },
    refreshIcon:{
        animation: '$refreshIcon 2s linear infinite',
    },
    '@keyframes refreshIcon': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    dividerStyle:{
        backgroundColor: theme.palette.colors.fillStroke[200], 
        width: '219px',
        flex: 'none',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
        }
    },
    logoStyle:{
        padding: 0, 
        margin: 0, 
        width: '64px', 
        height: '36px',
    },
    boxSolicitudesTittles:{
        marginBottom:'20px', 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]:{
            justifyContent: 'center'
        }
    },   
    paperDialogStyle: {
        maxWidth: '750px',
        borderRadius: '16px',
        border: "1px solid " + theme.palette.colors.fillStroke[200],
        margin: '0px !important',
        [theme.breakpoints.down('xs')]:{
            width: '91.114%',
        }
    },
    paperDialogStyleMod:{
        margin: '0px !important',
        background: theme.palette.colors.fillStroke[500],
    }
}));