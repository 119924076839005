import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// MUI
import Fab from '@material-ui/core/Fab';
import {
  Avatar,
  Badge,
  Box,
  Button, Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

import clsx from 'clsx';

//components
import TicketsList from './TicketsList'; 
import TicketComments from './TicketComments';

//SDK
import { HelpCenterService } from '@sdk-point/talisis';


const HelpCenterServiceSDK = new HelpCenterService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return ({
      root: {
        padding: 0,
        backgroundColor: theme.palette.colors.fillStroke[500],
        border: `solid 1px ${theme.palette.colors.fillStroke[400]}`
      },
      header: {
        height: 100,
        width: '100%',
        background: `linear-gradient(113.16deg, ${theme.palette.colors.electricBlue[300]} 4.18%, ${theme.palette.colors.electricBlue[500]} 82.04%)`,
        paddingTop: 32,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 39,
        '& i': {
          float: 'left',
          marginRight: 8,
          '&::before': {
            marginTop: 5
          }
        },
        '& p': {
          fontWeight: 500
        }
      },
      content: {
        marginTop: 16,
        padding: '0 16px 0 16px'
      },
      customerSevrviceIcon: {
        fontSize: 32,
        color: theme.palette.white,
        display: 'flex'
      },
      stepContainer: {
        marginTop: 16,
        padding: '0 16px',
      },
      ticketList: {
        padding: '8px 16px',
        maxHeight: '300px',
        overflowY: 'scroll'
      },
      ticketListItem: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        padding: 16,
        marginTop: 16,
        borderRadius: '4px'
      },
      ticketData: {
        
      },
      ticketCommentListItem:{},
      ticketCommentCntent:{},
      nextButton: {
        textTransform: 'none',
        // fontSize: '14px',
        padding: '16px 0'
      }
    })
  });

const ViewTickets = (props) => {
  const {
    handleViewTickets,
    handleNextStep,
    handleBackStep,
    currentStep,
    showTicket,
    disableSend,
    handleContactAgent,
    handleSendComment,
    handleFinish,
    LoaderContent,
    loading,
    setloading
  } = props 

  const classes = useStyles();
  return (
    <>
      <CardContent className={classes.root}>
        <Box className={classes.header}>
          <i className={clsx(classes.customerSevrviceIcon, 'ri-file-list-2-fill')} />
          <Typography>Tus tickets</Typography>
        </Box>
        <LoaderContent loading={loading}>
          {
            currentStep === 0 &&
            <TicketsList {... props}/>
          }
          {
            currentStep === 1 && 
            <TicketComments {... props} />
          }
        </LoaderContent>        
        <Grid container spacing={1} className={classes.stepContainer}>
          <Grid item xs={6} >
            <Button fullWidth size='large' variant='contained'  onClick={ currentStep > 0 ? handleBackStep : handleViewTickets}>Atrás</Button>                       
          </Grid>
          <Grid item xs={6} > 
            <Button 
              disabled={currentStep === 1 && disableSend} 
              className={classes.nextButton} 
              fullWidth size='large' 
              variant='contained' 
              color='primary' 
              onClick={currentStep === 0 ? handleContactAgent : currentStep === 1 ? handleSendComment : handleFinish}
            >
              {currentStep === 1 ? 'Enviar' : 'Contactar a un agente'}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </>
  )
};

ViewTickets.defaultProps = {
  isHome: false,
  isCheckout: false,
};

ViewTickets.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};


export default ViewTickets;
