import React, { lazy, Suspense } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from '@material-ui/core';

// redux
import { connect } from "react-redux"

// @material-ui/core
import Box from '@material-ui/core/Box';
import Hidden from "@material-ui/core/Hidden";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// shared
import { getSessionCookie } from "../../../shared/utils/Sessions";
import Loader from '../../../shared/components/Loader/LoaderContent';
import objectIsEmpty from "../../../shared/utils/objectIsEmpty";
import FAQsCourse from '../../../shared/components/FAQsCourse/FAQsCourse';
import { log } from '../../../shared/utils/console.js'

// core
import CourseContent from './CourseContent/CourseContent';
import { convertOuToSource, convertSourceToImageResource }from '../../../shared/utils/imageSource';

import clsx from 'clsx';

// services
import { MarketPlaceService } from '@sdk-point/talisis';

// Utils
import { splitWordByLimitLength } from '../../../design-system/utils/splitString';

// Constants
import { DESC_PRODUCT_DEFAULT } from '../../../shared/constants/products';

//SDK
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import CourseSelling from './CourseContent/EnrollSidebar/CourseSelling/CourseSelling';
import CourseSubscription from './CourseContent/EnrollSidebar/CourseSubscription/CourseSubscription';

// styles
import useStyles from '../../../assets/styles/jss/pages/studyPlan/detailStudyPlanCourse'
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';

import BannerDetail from '../../../design-system/components/BannerDetail/BannerDetail';
import SaveUnsaveButton from './CourseContent/EnrollSidebar/SaveUnsaveButton/SaveUnsaveButton';

// ds
import { COMPANIES, SOURCES } from '../../../design-system/constants/sources.js';

const CourseCarousel = lazy(() => import('../../../shared/components/CourseCarousel/CourseCarousel'));

const processCostumData = (course) => {
    return {
        ...course,
        name: course.customData?.name ?? course.name,
        description: course.customData?.description ?? course.description ?? DESC_PRODUCT_DEFAULT,
    };
};

const getIdFromSlug = (slug) => {
    if (!slug) return "";

    const splitSlug = slug.split('-');
    
    return splitSlug.length === 1 ? splitSlug[0] : splitSlug[splitSlug.length -1];
};

const DetailStudyPlanCourse = (props) => {
    const { id } = useParams();
    const { user } = props;
    const history = useHistory();
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const [loading, setLoading] = React.useState(false);
    const [course, setCourse] = React.useState(null);
    const [sourceId, setSourceId] = React.useState(null);
    const [membershipData,setMembershipData]=React.useState(false);
    const [courseImage,setCourseImage]=React.useState(null);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles();
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    // Obtenemos el identificador del curso
    const courseId = getIdFromSlug(id);
    
    const getData = async () => {
        try {
            let params = null;

            if (isLogged && !objectIsEmpty(user)) {
                params = {
                    'personId': user.person_id,
                    'ou': user.ou,
                };
            }

            setLoading(true);

            const courseResponse = await marketService.getCourse(courseId, params);
            const course = processCostumData(courseResponse);

            setCourseImage(course?.image_url != null ? course?.image_url: convertSourceToImageResource(course?.company_id, course?.source_id));
            let membershipData = await marketService.getPersonMembership(isLogged?user.person_id:null);
            setMembershipData(membershipData);
            return course;
        } catch (e) {
            setLoading(false);
            log(e.message);
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        let isCanceled = true;
        if (!courseId) {
            isCanceled = false;
            history.push("/");
        }
        getData().then((data)=>{
            if(isCanceled){
                const source_id = convertOuToSource(data.company_id, data.source_id,);
                setSourceId(source_id);
                setCourse(data);
                setLoading(false); 
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });
        eventTracker('s_curso', eventData, ['mixpanel']);
        return () => (isCanceled = false);
    }, [props.location]);

    React.useEffect(() => {
        log("User has changed:", JSON.stringify(user));
    }, [user]);

    return (
        <React.Fragment>
            <Helmet>
                <meta name="description" content={splitWordByLimitLength(course?.description ?? "", 150, '...')} />
                <title>{course?.name}</title>
            </Helmet>
            <Loader loading={loading} transparent={true} className={clsx(classes.loading)}>
                {!loading && course && (
                    <React.Fragment>
                        <BannerDetail
                            title={course?.name}
                            backgroundImage={isResponsive ? courseImage : ''}
                            disabledOpacityImg
                            labelText='curso'
                            chipType='chip3-blue-300-inverted'
                            sourceId={sourceId}
                            component={!loading && SaveUnsaveButton}
                            saveBttnProps={{
                                course,
                                white:true,
                                icon_button: true,
                                classes: classes.saveBttn
                            }}
                        />
                        <Box className={classes.boxDataLanding} hidden={course?.id==undefined}>
                            <Box minHeight="calc(100vh - 450px)">
                                 <CourseContent
                                        course={course}
                                        source_id={sourceId} 
                                        membershipData={membershipData}
                                    />
                            </Box>
                            <Suspense fallback={<div></div>}>
                                <CourseCarousel
                                    isDetail={true}
                                    course_id={course?.id}
                                    id='courseDetail'
                                    title='Te recomendamos'
                                    color="white"
                                    clickFrom={'curso'}
                                    hideShowMoreResponsive={true}
                                    />
                            </Suspense>
                            <Hidden mdUp>
                                <Card className={classes.cardFAQ}>
                                    <CardContent>
                                        <FAQsCourse 
                                            loading={false} title={"¿Necesitas ayuda?"} 
                                            btnName1={"Ver preguntas frecuentes"}
                                        />
                                    </CardContent>
                                </Card>
                            </Hidden>
                            <Hidden mdUp>
                                <Box className={classes.boxDataSelling}>
                                    {
                                        course.source_is_internal || (course.source_id === SOURCES.IE_UNIVERSITY && course.company_id === COMPANIES.UERRE) ?
                                            <CourseSelling course={course} classes={classes} membershipData={membershipData} backgroundImage={course?.image_url} hideInfoData={true} bottomSelling={true} /> :
                                            <CourseSubscription course={course} classes={classes} membershipData={membershipData} backgroundImage={course?.image_url} hideInfoData={true} extraInfo={false} />
                                    }
                                </Box>
                            </Hidden>
                    </Box>
                    <Box hidden={course?.id!=undefined}>
                        <NothingFound />
                    </Box>
                    </React.Fragment>
                )}
            </Loader>
        </React.Fragment>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(DetailStudyPlanCourse);