import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootSearch: {
        overflow: 'hidden',
        paddingBottom: '30px',
    },
    filterContainer: {
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.colors.fillStroke[400],
        padding: '0 16px',
    },
    filterSubcontainer: {
        maxWidth: '1249px',
        width: '100%'
    },
    dataContainer: {
        marginTop: '25px'
    }
}))