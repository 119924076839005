import React from 'react';

// @material-ui/core
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = ({ id, title, children, form = {} }) => {
    let itemCount = 0;

    if (form[id]) {
        if (form[id].length > 0) {
            itemCount = form[id].filter(item => item._deleted !== true).length;
        }
    }

    return (
        <MuiAccordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content`}
                id={`${id}-header`}
            >
                <Typography variant="subtitle1" className="mr-2">{title}</Typography>
                <Chip size="small" label={itemCount} color="primary" />
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </MuiAccordion>
    );
};

export default Accordion;