import { UserService } from '@sdk-point/talisis';

// conekta
import { CONEKTA_PUBLIC_KEYS } from './../constants/conekta.js';

// utils
import { log } from './console.js'

const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const conektaAccounts = ['UNID', 'UERRE'];

const successToken = async (token) => token.id;

const getExpDateInfo = ( expDate ) => {
    if( expDate.length < 5 ) return 

    const expArray = expDate.split("/", 2);
    const expInfo = {
        exp_month: expArray[0],
        exp_year: expArray[1]
    }
    return expInfo
}

export const cardExists = async ({ person_id, formCardNumber }) => {
    let exists = false;
    const cards = await UserServiceSDK.conektaGetCustomerCard(person_id);
    if (cards) {
        // const cardNumber = form.number;
        const cardNumber = formCardNumber;
        cards.map(card => {
            if (card[0].last4 === cardNumber.slice(-4)) {
                exists = true;
            }
        });
    }

    return exists;
};

export const verifyFailedCards = ( savedCards ) => {
    const hasFailedCards = savedCards.some( card => card === undefined)
    return hasFailedCards
}

const createToken = async( cardForm, conekta ) => {
    const { exp_month, exp_year } = getExpDateInfo(cardForm.card_expiration);
    const promise = new Promise( async( resolve, reject ) => {
        conekta.createToken({
            card: {
                number: cardForm.number,
                name: cardForm.name,
                exp_month,
                exp_year,
                cvc: cardForm.cvc
            }
        },
        async (token) => {
            const token_id = await successToken(token);
            resolve(token_id)
        },
        (error) => {
            reject(error)
        });
    })

    return promise
}

export const generateCardTokens = async (cardForm, conekta) => {
    let tokens = {};
    for (const account of conektaAccounts) {
        conekta.setPublicKey(CONEKTA_PUBLIC_KEYS[account]);
        let generatedToken;
        try {
            generatedToken = await createToken( cardForm, conekta );                
        } catch (error) {
            log(error)
        }
        log("generatedToken", generatedToken)
        tokens[account] = generatedToken            
    }
    
    return tokens;
};

export const addCustomerCards = async ({ person_id, tokens}) => {
    const newConektaCards = await UserServiceSDK.conektaAddCustomerCard(person_id, tokens);
    return newConektaCards
};
