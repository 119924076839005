import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// @shared/components
import TabPanel from './TabPanel';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs, Typography, useMediaQuery } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import typography from '../../../design-system/theme/typography';
import palette from '../../../design-system/theme/palette';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';
// =======================================================
/**
 * Las etiquetas y contenido van ligados por medio del índice
 * (orden de aparición).
 * 
 * Ejemplo de uso:
 * const labels = [
 *      { icon: <i class="ri-user-star-line icon"></i>, title: 'Membresías' },
 *      { icon: <i class="ri-bank-line icon"></i>, title: 'Tesorería' },
 *      { icon: <i class="ri-wallet-line icon"></i>, title: 'Métodos de pago' },
 *      { icon: <i class="ri-bill-line icon"></i>, title: 'Facturación' },
 * ];
 * const panels = [<Membresias />, <Tesoreria />, <MetodoPago />, <Facturacion />];
 * const selected = 3 // El índice del tab que se requiere seleccionar, iniciando por '0'.
 * // ...
 * 
 * <VerticalTab labels={labels} panels={panels} selected={selected} />
 */

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        [theme.breakpoints.down('sm')]:
        {
            display:'block'
        }
    },
    tabs: {
        width: '215px',
        '& .MuiTabs-flexContainer': {
            borderBottom: '0px !important',
        },
        '& .Mui-selected': {
            fontWeight: 'normal !important',
        },
        [theme.breakpoints.down('sm')]:
        {
            marginTop:'4px',
            height:'100%',
            width:'100%',
            marginBottom:'0px',
            '& .MuiTabs-scroller':{
                overflow:'auto !important'
            },
            '& .MuiTabs-scroller::-webkit-scrollbar':{
                display: 'none'
            }
        }
    },
    tab: {
        alignItems: 'center !important',
        border: `0.5px solid ${theme.palette.colors.grey[50]}`,
        borderRadius: '8px',
        height: '80px',
        marginBottom: '16px',
        '& .MuiTab-wrapper': {
            flexDirection: 'row !important',
        },
        [theme.breakpoints.down('sm')]:
        {
            marginRight:'8px',
            height:'56px'
        }
    },
    activeTab:{
        color:theme.palette.colors.darkBlack[200],
        border: `0.5px solid ${theme.palette.colors.violet[300]}`,
    },
    tabLabel: {
        alignItems: 'center',
        display: 'flex',
        textAlign: 'left',
        textTransform: 'none',
        width: '100%',
        '& .icon': {
            fontSize: '20px',
        },
    },
    tabLabelInactive:{
        [theme.breakpoints.down('sm')]:{
            color: theme.palette.colors.darkBlack[200]
        }
    },
    tabTitle:{
        [theme.breakpoints.down('sm')]:{
            ...typography.bodyTextSmallSemiBold
        }
    },
    tabTitleInactive:{
        [theme.breakpoints.down('sm')]:{
            ...typography.bodyTextSmallSemiBold,
            color: theme.palette.colors.darkBlack[200]
        }
    },
    tabPanel: {
        marginLeft: '24px',
        [theme.breakpoints.down('sm')]:
        {
            marginLeft: '0px',
            overflow:'hidden',
            '& > .MuiBox-root':{
                marginBottom:'80px',
            }
        }
    },
}));

export default function VerticalTab(props) {
    const classes = useStyles();
    const { labels, panels, selected, setActivePaymentInfoTab } = props;
    const theme = useTheme();
    const [activeTab, setActiveTab] = React.useState(selected ? selected : 0);
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
    const handleTabChange = (event, tab) => {
        console.log('labels', labels[tab])
        if(labels[tab].title == 'Mis adeudos'){
            eventTracker('s_adeudos',eventData, ['mixpanel']);
        }else if (labels[tab].title == 'Estado de cuenta'){
            eventTracker('s_estado_cuenta',eventData, ['mixpanel']);
        }
        setActiveTab(tab);
        setActivePaymentInfoTab(tab);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation={breakpoint?"horizontal":"vertical"}
                value={activeTab}
                onChange={handleTabChange}
                className={`${classes.tabs} no-print`}
                textColor="inherit"
                TabIndicatorProps={{
                    hidden: true
                }}
            >
                {labels && labels.map((label, i) => (
                    <Tab key={`vertical_tab_label_${i}`} label={<div className={clsx(classes.tabLabel,activeTab===i?null:classes.tabLabelInactive)}>{label.icon}<Typography variant="body2" className={clsx("ml-2",activeTab===i?classes.tabTitle:classes.tabTitleInactive)}>{label.title}</Typography></div>} className={`${activeTab === i ? 'bg-fill-stroke-400' : 'bg-fill-stroke-500'} ${classes.tab} ${activeTab===i?classes.activeTab:null}`} />
                ))}
            </Tabs>
            {panels && panels.map((panel, i) => (
                <TabPanel key={`vertical_tab_panel_${i}`} value={activeTab} index={i} className={classes.tabPanel}>
                    {panel}
                </TabPanel>
            ))}
        </div>
    )
}

VerticalTab.propTypes = {
    labels: PropTypes.array.isRequired,
    panels: PropTypes.array.isRequired,
    selected: PropTypes.number,
};