export const MODALITIES = {
    1: "Presencial",
    2:	"Online",
    3:	"Híbrida",
    4:	"Online Pro",
};

export const MODALITY_ICON = {
    1: "ri-team-line",
    2:	"ri-slideshow-3-line",
    3:	"ri-user-location-line",
    4:	"ri-slideshow-3-line",
};