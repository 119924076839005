import { Grid, Typography } from '@material-ui/core'
import React from 'react'

import SearchSvg from '../../../../../assets/images/undraw_searching_p5ux.svg'

const Asincronicos = () => {
  return (
    <>
        <Grid container alignItems='center' direction='column' className="mt-3">
            <Typography variant='h3'>Alumno Asíncrono</Typography>
            <img
                alt="alumno-asincronico"
                className='mt-2 mb-2'
                src={SearchSvg}
                draggable="false"
                style={{ maxWidth: 250 }}
            />
            <Typography variant='caption'>*Horarios no disponibles en esta modalidad</Typography>
        </Grid>
    </>
  )
}

export default Asincronicos
