import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    boxRoot: { 
        display: 'flex',
        flexDirection: 'row',
        boxSizing: "border-box",
        position: "relative",
        flexWrap: 'wrap',
        overflow: "hidden",
        [theme.breakpoints.down('xs')]:{
            justifyContent: 'center'
        }
    },
    card: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        width: "250.8px",
        height: "429px",
        background: theme.palette.colors.fillStroke[400],
        border: "1px solid " + theme.palette.colors.fillStroke[200],
        borderRadius: "8px",
        marginRight: '16px',
        marginBottom: '16px',
        [theme.breakpoints.down('xs')]:{
            padding: 0,
            width: "100%",
            marginRight: '0px',
        }
    },
    boxMateriaFirst: { 
        width: 'auto',
        maxWidth: '190px',
        borderRadius: '16px',
        display: 'flex',
        maxHeight: '28px',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '6px 16px',
        backgroundColor: theme.palette.colors.fillStroke[300],
        border: '1px solid ' + theme.palette.colors.fillStroke[200],
    },
    boxMateria: { 
        width: 'auto',
        maxWidth: '165px',
        borderRadius: '16px',
        display: 'flex',
        maxHeight: '28px',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '6px 16px',
        backgroundColor: theme.palette.colors.fillStroke[300],
        border: '1px solid ' + theme.palette.colors.fillStroke[200],
    },
    boxMaterias: { 
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '16px',
        marginRight: '16px',
        padding: '0px',
        gap: '6px',
        height: '64px',
        width: "90.69%"
    },
    buttonAgendar: {
        width:'200px', 
        height:'40px',
        marginBottom: '16px',
        [theme.breakpoints.down('xs')]:{
            width: "90.69%",
            height: '48px',
        }
    },
    profilePic: {
        width: '96px',
        height: '96px',
        padding: 0,
        margin: 0
    },
    multilineColor:{
        backgroundColor: theme.palette.colors.fillStroke[200],
        width: '350px',
        height: '30px',
        fontSize: '20px',
        borderRadius: '10px',
        border: '1px solid ' + theme.palette.colors.violet[300]
    },
    nameAdviser:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
        width: '201px',
        height:'48px',
        gap: '8px',
    },    
    tittlesAvatar:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        gap: '5px',
    },
    nothingFound:{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '100vw', 
        padding: 0, 
        margin: 0
    },
    paginationStyle:{
        "& .Mui-selected": {
            backgroundColor: theme.palette.colors.violet[400]
        },
        [theme.breakpoints.down('xs')]:{
            width: '90%'
        }
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        alignItems: 'center',
        bottom: 0,
        right: 0,
        paddingRight: '130px',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            justifyContent: 'space-between',
            paddingRight: '28px',
        }
      },
    filterSkill: {
        width: '280px',
        height: '40px',
        borderRadius: '8px',
        background: theme.palette.colors.fillStroke[400],
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]:{
            width: '190px'
        }
    },
    boxFilter: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        paddingBottom: '32px',
        [theme.breakpoints.down('xs')]:{
            justifyContent: 'center', 
        }
    },
    selectVista:{
        display: 'flex',
        width: '120px',
        height: '34px',
        alignItems: 'center',
        flexDirection:'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        background: theme.palette.colors.fillStroke[400],
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: '8px',
        "& .MuiSelect-icon": {
            color: theme.palette.colors.darkBlack[200]
        }
    },
    boxInfoAsesor:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "16px",
        width: "218.8px",
        height: "194px",
    },
    tooltip: {
        backgroundColor: theme.palette.colors.blue[100],
        borderRadius: '8px',
        opacity: 0.9
    },
    tooltipText: {
        color: theme.palette.colors.fillStroke[500]
    },
    materiaStyle: {
        color: theme.palette.colors.gray[500],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    dividerStyle:{
        backgroundColor: theme.palette.colors.fillStroke[200], 
        width: '90.69%',
        height: '1px'
    },
    calificacionStyle:{
        width: "19px",
        height: "16px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "16px",
        color: theme.palette.colors.fillStroke[400],
    },
    boxCalificacionStyle:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 4px",
        gap: "4px",
        width: "27px",
        height: "16px",
        background: theme.palette.colors.gray[200],
        borderRadius: "2px",
    },
    logoStyle: {
        padding: 0, 
        margin: 0, 
        width: '80px', 
        height: '36px'
    },
    boxCalificacionesStyle: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: '5px'
    },
    paperDialogStyle: {
        maxWidth: '750px',
        borderRadius: '16px',
        margin: '0px !important',
        border: "1px solid " + theme.palette.colors.fillStroke[200],
        [theme.breakpoints.down('xs')]:{
            width: '91.114%',
            paddingBottom: '20px'
        }
    },
    inputSelectStyle:{
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row',
        flexWrap: 'wrap',
        color: theme.palette.colors.darkBlack[200]
    },
    styleDropdown: {
        width: '100%',
        backgroundColor: theme.palette.colors.fillStroke[500],
        padding: 'auto',
        border: `8px solid ${theme.palette.colors.fillStroke[500]}`,
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '-8px',
            left: '-8px',
            right: '-8px',
            bottom: '-8px',
            border: `8px solid ${theme.palette.colors.fillStroke[500]}`,
            borderRadius: '16px',
            pointerEvents: 'none',
        },
        '&:hover': {
            backgroundColor: theme.palette.colors.fillStroke[200],
        },
    },
    filterText:{
        [theme.breakpoints.down('xs')]:{
            width: '28%'
        }
    }
}));