import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    lessons:{
        color: '#2ECFE8', 
        fontSize: 12, 
        marginRight: 4
    },
    sourceImg:{
        height: 30, 
        marginRight: 16
    },
    tv:{ 
        width: "10px",
        marginRight: "4px",
    },
    modality_text:{
        fontSize: "12px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "flex-end",
    },
    button: {
        background: "linear-gradient(298.07deg, #00C1DE 10.33%, #3D23D8 84.35%)",
        fontSize: 11,
        color: theme.palette.colors.white,
        padding: "4px 8px",
        lineHeight: '16px',
        marginLeft: 0,
        borderRadius: 2,
        marginRight: "16px",
    },
}));