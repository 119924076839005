import {toLower, toUpper} from "ramda";

export const titulacion = [
  {
    name: "statusDesc",
    label: "Estatus Proceso",
    minWidth: 160,
    align: "center",
  },
  {name: "matricula", label: "Matricula", minWidth: 10, align: "center"},
  {
    name: "fullName",
    label: "Nombre",
    minWidth: 250,
    align: "center",
    sort: false,
    direction: "desc",
    active: false,
    format(item) {
      return item.id_link;
    },
  },
  // { name: 'status', label: 'Estatus Proceso', minWidth: 100 } ,
  // { name: 'primerApellido', label: 'Apellido Paterno', minWidth: 100 },
  // { name: 'segundoApellido', label: 'Apellido Materno', minWidth: 100 },
  {name: "programa", label: "Carrera", minWidth: 135, align: "center"},
  {name: "nombrePrograma", label: "Nombre Carrera", minWidth: 255, align: "center"},
  {name: "nivel", label: "Nivel", minWidth: 100},
  {name: "campus", label: "Campus", minWidth: 100},
  {name: "crseReq", label: "Cursos Requeridos", minWidth: 130,align: "center",},
  {name: "crseUse", label: "Cursos Usados", minWidth: 130, align: "center",},
  {
    name: "porcentajeMaterias",
    label: "Procentaje Materias",
    minWidth: 130,
    align: "center",
  },
  {
    name: "porcentajeEstatus",
    label: "Procentaje Estatus",
    minWidth: 130,
    align: "center",
  },
  {
    name: "sincronizacion",
    label: "Sincronizado",
    minWidth: 150,
    align: "center",
  },
  // { name: 'porcentajeinfo', label: 'Procentaje Datos Adiciónales', minWidth: 150, align:'center' },
  // { name: 'is_active', label: 'Activo', minWidth: 100 },
  // { name: 'actions', label: 'Acciones', minWidth: 100 }
];

export const certificate = [
  {
    name: "statusDesc",
    label: "Estatus Proceso",
    minWidth: 200,
    align: "center",
  },
  {name: "proceso", label: "Proceso", minWidth: 100},

  {name: "matricula", label: "Matricula", minWidth: 10},
  {name: "fullName", label: "Nombre", minWidth: 200},
  // { name: 'status', label: 'Estatus Proceso', minWidth: 100 } ,
  // { name: 'primerApellido', label: 'Apellido Paterno', minWidth: 100 },
  // { name: 'segundoApellido', label: 'Apellido Materno', minWidth: 100 },
  {name: "programa", label: "Carrera", minWidth: 150},
  {name: "nombrePrograma", label: "Nombre Carrera", minWidth: 200},
  {name: "nivel", label: "Nivel", minWidth: 100},
  {name: "campus", label: "Campus", minWidth: 100},
  {name: "crseReq", label: "Cursos Requeridos", minWidth: 100},
  {name: "crseUse", label: "Cursos Usados", minWidth: 100},
  {
    name: "porcentajeMaterias",
    label: "Procentaje Materias",
    minWidth: 200,
    align: "center",
  },
  // { name: 'porcentajeEstatus', label: 'Procentaje Estatus', minWidth: 200,  align:'center' },
  {
    name: "sincronizacion",
    label: "Sincronizado",
    minWidth: 150,
    align: "center",
  },
  // { name: 'porcentajeinfo', label: 'Procentaje Datos Adiciónales', minWidth: 150, align:'center' },
  // { name: 'is_active', label: 'Activo', minWidth: 100 },
  // { name: 'actions', label: 'Acciones', minWidth: 100 }
];

export const kardex = [
  {
    name: "claveMateriaOficial", label: "Clave Materia Oficial", minWidth: 150, align: "center",
  },
  {
    name: "claveMateriaInst", label: "Clave Materia Institucional", minWidth: 200, align: "center"
  },
  {
    name: "nombreMateria", label: "Nombre Materia", minWidth: 50, align: "center",
  },
  {
    name: "calificacion", label: "Calificacion", minWidth: 50, align: "center",
  },
  {
    name: "motivoCalificacion", label: "Motivo Calificacion", minWidth: 100, align: "center",
  },
  {
    name: "idTipoAsignatura", label: "Tipo Asignatura", minWidth: 100, align: "center",
  }, {
    name: "creditos", label: "Creditos", minWidth: 50, align: "center",
  },
  {
    name: "action", label: "Acciones", minWidth: 100, align: "center",
  },

];

export const requests = [
  {name: "secuencia", label: "Id Secuencia", minWidth: 100},
  {name: "matricula", label: "Matricula", minWidth: 10},
  {name: "campus", label: "Campus", minWidth: 100},
  {name: "proceso", label: "Proceso", minWidth: 100},
  {name: "fecha", label: "Fecha", minWidth: 15},
  {name: 'estatus', label: 'Estatus', minWidth: 100},
  {name: 'estatusDesc', label: 'Estatus Descripcion', minWidth: 150},
  {name: 'porcentajeEstatus', label: 'Procentaje Estatus', minWidth: 150, align: 'center'},
  {name: "programa", label: "Programa", minWidth: 150},
  {name: "nombrePrograma", label: "Nombre Programa", minWidth: 200},
  {name: "nivel", label: "Nivel", minWidth: 100},
  {
    name: "sincronizacion",
    label: "Sincronizado",
    minWidth: 150,
    align: "center",
  },
  {name: "respuestaProveedor", label: "Respuesta Proveedor", minWidth: 250},
];

export const ceritificateDetails = [
  {name: "matricula", label: "Matricula", minWidth: 100},
  {name: "fullName", label: "Nombre", minWidth: 200},
  {name: "curp", label: "curp", minWidth: 200},
  {name: "genero", label: "Genero", minWidth: 100},
  {name: "fechaNacimiento", label: "Fecha Nacimiento", minWidth: 100},
  {name: "tipoPeriodo", label: "Tipo Periodo", minWidth: 100},
  {name: "clavePeriodo", label: "Clave Periodo", minWidth: 100},
  {name: "periodo", label: "periodo", minWidth: 200},
  {name: "clavePlan", label: "Clave Plan", minWidth: 100},
  {name: "planEstudios", label: "Plan Estudios", minWidth: 100},
  {name: "nombreDeEstudios", label: "Plan", minWidth: 250},
  {name: "orden", label: "Orden", minWidth: 100},
  {
    name: "claveMateriaOficial",
    label: "Clave Materia Oficial",
    minWidth: 100,
  },
  {
    name: "claveMateriaInstitucion",
    label: "Clave Materia Institucion",
    minWidth: 100,
  },
  {name: "nombreMateria", label: "Nombre Materia", minWidth: 200},
  {name: "calificacion", label: "Calificacion", minWidth: 100},
  {name: "claveDelCampus", label: "Clave Campus", minWidth: 100},
  {name: "nombreDeCampus", label: "Campus", minWidth: 100},
  {name: "claveDelNivel", label: "Clave Nivel", minWidth: 100},
  {name: "nivel", label: "Nivel", minWidth: 100},
  {name: "prerequisitos", label: "prerequisitos", minWidth: 200},
  {name: "motivoDeCalificacion", label: "Motivo Calificacion", minWidth: 100},
  {name: "idTipoAsignatura", label: "idTipoAsignatura", minWidth: 100},
  {name: "creditos", label: "Creditos", minWidth: 100},
];

export const excelCertificateColumnsbk = [
  {
    column: "numeroRegistro",
    type: String,
    value: (data) => data.numeroRegistro,
    width: 14,
  },
  {
    column: "matricula",
    type: String,
    value: (data) => data.matricula,
    width: 14,
  },
  {
    column: "nombre",
    type: String,
    value: (data) => data.fullName,
    width: 14,
  },
  {
    column: "tipoPeriodo",
    type: String,
    value: (data) => data.tipoPeriodo,
    width: 14,
  },
  {
    column: "planEstudios",
    type: String,
    value: (data) => data.planEstudios,
    width: 14,
  },
  {
    column: "nombreDeEstudios",
    type: String,
    value: (data) => data.nombreDeEstudios,
    width: 14,
  },
  {
    column: "clavePeriodo",
    type: String,
    value: (data) => data.clavePeriodo,
    width: 14,
  },
  {
    column: "periodo",
    type: String,
    value: (data) => data.periodo,
    width: 14,
  },
  {
    column: "orden",
    type: String,
    value: (data) => data.orden,
    width: 14,
  },
  {
    column: "claveMateriaOficial",
    type: String,
    value: (data) => data.claveMateriaOficial,
    width: 14,
  },
  {
    column: "claveMateriaInstitucion",
    type: String,
    value: (data) => data.claveMateriaInstitucion,
    width: 14,
  },
  {
    column: "nombreMateria",
    type: String,
    value: (data) => data.nombreMateria,
    width: 14,
  },
  {
    column: "calificacion",
    type: String,
    value: (data) => data.calificacion,
    width: 14,
  },
  {
    column: "claveDelCampus",
    type: String,
    value: (data) => data.claveDelCampus,
    width: 14,
  },
  {
    column: "nombreDeCampus",
    type: String,
    value: (data) => data.nombreDeCampus,
    width: 14,
  },
  {
    column: "claveDelNivel",
    type: String,
    value: (data) => data.claveDelNivel,
    width: 14,
  },
  {
    column: "nivel",
    type: String,
    value: (data) => data.nivel,
    width: 14,
  },
  {
    column: "prerequisitos",
    type: String,
    value: (data) => data.prerequisitos,
    width: 14,
  },
  {
    column: "curp",
    type: String,
    value: (data) => data.curp,
    width: 14,
  },
  {
    column: "genero",
    type: String,
    value: (data) => data.genero,
    width: 14,
  },
  {
    column: "fechaNacimiento",
    type: String,
    value: (data) => data.fechaNacimiento,
    width: 14,
  },
  {
    column: "motivoDeCalificacion",
    type: String,
    value: (data) => data.motivoDeCalificacion,
    width: 14,
  },
  {
    column: "clavePlan",
    type: String,
    value: (data) => data.clavePlan,
    width: 14,
  },
  {
    column: "idTipoAsignatura",
    type: String,
    value: (data) => data.idTipoAsignatura,
    width: 14,
  },
  {
    column: "creditos",
    type: String,
    value: (data) => data.creditos,
    width: 14,
  },
];

export const excelDegreeColumns = [
  {
    column: "Matricula",
    type: String,
    value: (data) => toUpper(data?.matricula || ''),
    width: 14
  },
  {
    column: "Nombre",
    type: String,
    value: (data) => toUpper(data?.nombre || ''),
    width: 25
  },
  {
    column: "Primer Apellido",
    type: String,
    value: (data) => toUpper(data?.primerApellido || ''),
    width: 20
  },
  {
    column: "Segundo Apellido",
    type: String,
    value: (data) => toUpper(data?.segundoApellido || ''),
    width: 20
  },
  {
    column: "Correo Electrónico",
    type: String,
    value: (data) => toLower(data.correoElectronico),
    width: 25
  },
  {
    column: "Género",
    type: String,
    value: (data) => toUpper(data?.genero || ''),
    width: 10
  },
  {
    column: "CURP",
    type: String,
    value: (data) => toUpper(data?.curp || ''),
    width: 25
  },
  {
    column: "Clave Carrera",
    type: String,
    value: (data) => toUpper(data?.carreraSep || ''),
    width: 25
  },
  {
    column: "Nombre Carrera",
    type: String,
    value: (data) => toUpper(data?.nombreCarrera || ''),
    width: 30
  },
  {
    column: "Fecha Inicio",
    type: String,
    value: (data) => toUpper(data?.fechaInicioCarrera || ''),
    width: 14
  },
  {
    column: "Fecha Término",
    type: String,
    value: (data) => toUpper(data?.fechaTerminacionCarrera || ''),
    width: 14
  },
  {
    column: "Id Autorización Reconocimiento",
    type: String,
    value: (data) => toUpper(data?.idAutorizacionReconocimiento || ''),
    width: 14
  },
  {
    column: "Número Rvoe",
    type: String,
    value: (data) => toUpper(data?.numeroRvoe || ''),
    width: 14
  },
  {
    column: "Fecha Expedición",
    type: String,

    value: (data) => toUpper(data?.fechaExpedicion || ''),
    width: 14
  },
  {
    column: "Id Modalidad Titulación",
    type: String,
    value: (data) => toUpper(data?.idModalidadTitulacion || ''),
    width: 14
  },
  {
    column: "Fecha Exámen Profesional",
    type: String,

    value: (data) => toUpper(data?.fechaExamenProfesional || ''),
    width: 14
  },
  {
    column: "Fecha Exención Exámen Profesional",
    type: String,

    value: (data) => toUpper(data?.fechaExencionExamenProfesional || ''),
    width: 14
  },
  {
    column: "Cumplió Servicio Social",
    type: String,
    value: (data) => toUpper(data?.cumplioServicioSocial || ''),
    width: 14
  },
  {
    column: "Id Fundamento Legal Servicio Social",
    type: String,
    value: (data) => toUpper(data?.idFundamentoLegalServicioSocial || ''),
    width: 14
  },
  {
    column: "Id Entidad Federativa",
    type: String,
    value: (data) => toUpper(data?.idEntidadFederativaCarrera || ''),
    width: 10
  },
  {
    column: "Institución Procedencia",
    type: String,
    value: (data) => toUpper(data?.institucionProcedencia || ''),
    width: 14
  },
  {
    column: "Id Tipo Estudio Antecedente",
    type: String,
    value: (data) => toUpper(data?.idTipoEstudioAntecedente || ''),
    width: 14
  },
  {
    column: "Id Entidad Federativa (escuela de procedencia)",
    type: String,
    value: (data) => toUpper(data?.idEntidadFederativaProcedencia || ''),
    width: 14
  },
  {
    column: "Fecha Inicio",
    type: String,

    value: (data) => toUpper(data?.fechaInicioInstProcedencia || ''),
    width: 14
  },
  {
    column: "Fecha Término",
    type: String,

    value: (data) => toUpper(data?.fechaTerminacionInstProcedencia || ''),
    width: 14
  },
  {
    column: "No Cedula",
    type: String,
    value: (data) => toUpper(data?.noCedula || ''),
    width: 14
  },
  {
    column: "Campus",
    type: String,
    value: (data) => toUpper(data?.campus || ''),
    width: 30
  },
  {
    column: "Folio",
    type: String,
    value: (data) => toUpper(data?.folio || ''),
    width: 25
  },
  {
    column: "Nombre De Campus",
    type: String,
    value: (data) => data?.nombreDeCampus || '',
    width: 30
  },
  {
    column: "Observaciones Internas",
    type: String,
    value: (data) => toUpper(data?.observacionesInternas || ''),
    width: 15
  },
  {
    column: "Revisor",
    type: String,
    value: (data) => toUpper(data?.revisor || ''),
    width: 25
  },
];

export const excelCertificateColumns = [
  {
    column: "MATRICULA",
    type:String,
    value: (data) => data?.matricula|| '',
    width:20
  },
  {
    column: "NOMBRE",
    type:String,
    value: (data) => data?.nombre|| '',
    width:20
  },
  {
    column: "TIPO_PERIODO",
    type:String,
    value: (data) => data?.tipoPeriodo|| '',
    width:20
  },
  {
    column: "PLAN_ESTUDIOS",
    type:String,
    value: (data) => data?.planEstudios|| '',
    width:20
  },
  {
    column: "NOMBRE_DE_ESTUDIOS",
    type:String,
    value: (data) => data?.nombreEstudios|| '',
    width:20
  },
  {
    column: "CLAVE_PERIODO",
    type:String,
    value: (data) => data?.clavePeriodo|| '',
    width:20
  },
  {
    column: "PERIODO",
    type:String,
    value: (data) => data?.periodo|| '',
    width:20
  },
  {
    column: "ORDEN",
    type:String,
    value: (data) => data?.orden|| '',
    width:20
  },
  {
    column: "CLAVE_MATERIA_OFICIAL",
    type:String,
    value: (data) => data?.claveMateriaOficial|| '',
    width:20
  },
  {
    column: "CLAVE_MATERIA_INSTITUCION",
    type:Number,
    value: (data) => data?.claveMateriaInstitucion|| '',
    width:20
  },
  {
    column: "NOMBRE_MATERIA",
    type:String,
    value: (data) => data?.nombreMateria|| '',
    width:20
  },
  {
    column: "CALIFICACION",
    type:String,
    value: (data) => data?.calificacion|| '',
    width:20
  },
  {
    column: "CLAVE_DEL_CAMPUS",
    type:String,
    value: (data) => data?.claveCampus|| '',
    width:20
  },
  {
    column: "NOMBRE_DE_CAMPUS",
    type:String,
    value: (data) => data?.nombreCampus|| '',
    width:20
  },
  {
    column: "CLAVE_DEL_NIVEL",
    type:String,
    value: (data) => data?.claveNivel|| '',
    width:20
  },
  {
    column: "NIVEL",
    type:String,
    value: (data) => data?.nivel|| '',
    width:20
  },
  {
    column: "PREREQUISITOS",
    type:String,
    value: (data) => data?.preRequisitos|| '',
    width:20
  },
  {
    column: "CURP",
    type:String,
    value: (data) => data?.curp|| '',
    width:20
  },
  {
    column: "GENERO",
    type:String,
    value: (data) => data?.genero|| '',
    width:20
  },
  {
    column: "FECHA_NACIMIENTO",
    type:String,
    value: (data) => data?.fechaNacimiento|| '',
    width:20
  },
  {
    column: "MOTIVO_DE_CALIFICACION",
    type:Number,
    value: (data) => data?.motivoCalificacion|| '',
    width:20
  },
  {
    column: "CLAVE_PLAN",
    type:String,
    value: (data) => data?.clavePlan|| '',
    width:20
  },
  {
    column: "ID_TIPO_ASIGNATURA",
    type:String,
    value: (data) => data?.idTipoAsignatura|| '',
    width:20
  },
  {
    column: "CREDITOS",
    type:Number,
    value: (data) => data?.creditos|| '',
    width:20
  },
];

export const excelReportMetColumns = [
  {
    column: "FOLIO_CONTROL",
    type: String,
    value: (data) => toUpper(data?.folio || ''),
    width: 14
  },
  {
    column: "CURP",
    type: String,
    value: (data) => toUpper(data?.curp || ''),
    width: 14
  },
];
