import { makeStyles } from "@material-ui/core";

export const checkoutErrorStyles = makeStyles((theme) => ({
    btn: {
        minWidth: '175px',
        textTransform: 'none',
        "@media (max-width: 500px)": {
            minWidth: '40%'
        }
    },
}));
