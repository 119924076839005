import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation } from "react-router-dom";
import Toast from "../../../../../../shared/components/Toast/Toast"
import { log } from '../../../../../../shared/utils/console.js'
import PasswordChecklist from "react-password-checklist"
// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../../../redux/actions/userActions';
import * as profilePicActions from '../../../../../../redux/actions/profilePicActions';
import * as tourInitialActions from '../../../../../../redux/actions/tourInitialActions';
//SDK
import { MIXPANEL_EVENT, SingupService } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../../shared/components/Mixpanel/mixpanel';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { eventTracker } from '../../../../../../shared/components/EventTracker/EventTracker';

const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    passwordCheck: {
        textAlign: 'left'
    },
    checkIcon: {
        padding: 2
    },
    showPassword: {
        fontSize: '24px'
    }
}));

const SignupForm = props => {
    const { applyUser, onLoading, token } = props;
    const classes = useStyles();
    let history = useHistory();
    const location = useLocation();

    const queryStr = location.search.split('=').pop();
    let redirectTo = queryStr || '/';

    if(redirectTo == 1) {
        redirectTo = '/'
    }

    const [errors, setErrors] = React.useState({});
    const [form, setForm] = React.useState({
        'password': '',
        'confirm_password': false
    });
    
    const [isValidPassword, setIsValidPassword] = React.useState(false);

    const [inputValues, setInputValues] = React.useState({
        showPassword1: false,
        showPassword2: false,
    });

    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [severity, setSeverity] = useState('success')
    const preventDefault = (event) => event.preventDefault();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            const _errors = { ...errors };
            delete _errors[name];
            setErrors(_errors);
        }

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const handleClickShowPassword = (name) => {
        setInputValues({ ...inputValues, [name]: !inputValues[name] })
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['password', 'confirm_password'];
        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            }
        }

        if (form['password'] !== form['confirm_password']) {
            _errors['confirm_password'] = 'las contraseñas no coinciden'
        }

        if (!isValidPassword) {
            _errors['password'] = 'Introduce una contraseña válida'
        }

        setErrors({ ..._errors });
        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleSubmit = async (e) => {
        preventDefault(e);
        if (validate()) {
            onLoading(true);
            let response;
            try {
                response = await SingupServiceSDK.talisisAccountActivation(form, token, process.env.REACT_APP_ENV)
                if(response && response.success){
                    eventTracker('actualizar_contraseña', {}, ['mixpanel']);
                    setToastMessage('Asignación existosa.');
                    setOpenToast(true)
                    setTimeout(() => {
                        history.replace({
                            pathname: "/login",
                        });
                    }, 3000);
                }else{
                    setSeverity(()=> 'warning')
                    setToastMessage(response?.message);
                    onLoading(false);
                    setOpenToast(true);
                }
            } catch (error) {
                setSeverity(()=> 'warning')
                setToastMessage(response?.message);
                onLoading(false);
                setOpenToast(true);
            }
            //Ahora se llama 1 solo servicio
          /*  if(resetPassword) {
                SingupServiceSDK.updatePassword(form, token).then(data => {
                    log('updating password')
                    const { mail } = data;
    
                    eventTracker('actualizar_contraseña', {}, ['mixpanel']);
                    setToastMessage('Tu contraseña ha sido actualizada exitosamente.');
                    setOpenToast(true)
                    setTimeout(() => {
                        history.replace({
                            pathname: "/login",
                        });
                    }, 5000);
                }).catch().finally();
            } else {
                SingupServiceSDK.activateSSOAccount(form, token).then(data => {
                    if(data?.mail) {
                        log('activating account')
                        const { mail } = data;    
                        eventTracker('set_password', {}, ['mixpanel']);
                        setSeverity(()=> 'success')
                        setToastMessage('Tu cuenta ha sido activada. Por favor, inicia sesión.');
                        setOpenToast(true)
                        setTimeout(() => {
                            history.replace({
                                pathname: "/login",
                            });
                        }, 2000);
                    } else {                        
                        setSeverity(()=> 'warning')
                        setToastMessage(data?.error?.message);
                        onLoading(false);
                        setOpenToast(true);
                    }
                }).catch()
                  .finally();
            }*/
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} className="pt-2 pb-4 mt-3">
                    <Grid item xs={12}>
                        <TextField
                            name="password"
                            variant="outlined"
                            label="Contraseña"
                            fullWidth
                            size="medium"
                            type={inputValues.showPassword1 ? 'text' : 'password'}
                            required
                            value={form.password || ""}
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                            onChange={handleChange}
                            placeholder='Ingresa tu nueva contraseña'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Contraseña"
                                            onClick={() => handleClickShowPassword('showPassword1')}
                                            onMouseDown={(e) => e.preventDefault()}
                                            style={{ color: '#A0A9BA' }}
                                        >
                                            {inputValues.showPassword1 ? <i className={`ri-eye-line ${classes.showPassword}`} /> : <i className={`ri-eye-off-line ${classes.showPassword}`} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: 'text-gray-100 semi-bold'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className='mt-3'>
                        <TextField
                            name="confirm_password"
                            variant="outlined"
                            label="Confirma tu contraseña"
                            fullWidth
                            size="medium"
                            type={inputValues.showPassword2 ? 'text' : 'password'}
                            required
                            value={form.confirm_password || ""}
                            error={Boolean(errors.confirm_password)}
                            helperText={errors.confirm_password}
                            onChange={handleChange}
                            placeholder='Confirmar contraseña'
                            InputProps={{
                                className: classes.input,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Contraseña"
                                            onClick={() => handleClickShowPassword('showPassword2')}
                                            onMouseDown={(e) => e.preventDefault()}
                                            style={{ color: '#A0A9BA' }}
                                        >
                                            {inputValues.showPassword2 ? <i className={`ri-eye-line ${classes.showPassword}`} /> : <i className={`ri-eye-off-line ${classes.showPassword}`} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: 'text-gray-100 semi-bold'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" className="mt-2 mb-2">
                            <Button type={'submit'} onClick={handleSubmit} fullWidth variant="contained" color="primary" className={classes.submit}> Asignar contraseña </Button>
                        </Box>
                    </Grid>
                    {
                        form.password.length > 0 && <Grid item xs={12}>
                            <PasswordChecklist 
                                rules={["minLength","number","capital","lowercase"]}
                                minLength={8}
                                value={form.password}
                                valueAgain={form.confirm_password}
                                onChange={(isValid) => setIsValidPassword(isValid)}
                                messages={{
                                    minLength: "Mínimo 8 caracteres",
                                    number: "Debe contener al menos un número",
                                    capital: "Debe contener al menos una mayúscula",
                                    lowercase: "Debe contener al menos una minúscula"
                                }}
                                className={classes.passwordCheck}
                                iconComponents={{
                                    ValidIcon: <i className={`${classes.checkIcon} ri-check-line text-success-300`} />,
                                    InvalidIcon: <i className={`${classes.checkIcon} ri-close-line text-error-300`} />
                                }}
                            />
                        </Grid>
                    }
                </Grid>
            </form>
            <Toast severity={severity} open={openToast} onFinished={()=>{setOpenToast(false)}} message={toastMessage} duration={4000}/>
        </>
    )
}

const mapStateToProps = ({ userReducer, profilePicReducer, tourInitialReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic,
    showTour: tourInitialReducer.showTour
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...tourInitialActions };
export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
