import React from 'react';
import { Box, Typography, Chip, Icon, IconButton } from '@material-ui/core';


const Actions = ({documentsRecived, documentApproved, id, onClickShow}) => {

    let label;
    let statusColor;
    let icon;

    if(documentsRecived === documentApproved){
        label = 'Completado';
        statusColor = 'chip-outlined-purple';
        icon = 'ri-checkbox-circle-fill text-violet-300'
    }else{
        label = `${documentsRecived - documentApproved} doc. por revisar`;
        statusColor = 'chip-outlined-blue';
        icon = 'ri-error-warning-fill text-blue-300'
    }

    return (
        <Box display='flex' gridGap={24}>
            <Box width={232} textAlign='center' className='text-start-xs'>
                <Chip 
                    avatar={<Icon className={icon} style={{fontSize:16, width: 'auto', height: 'auto'}}/>}
                    label={<Typography variant='body2' className='semi-bold'>{label}</Typography>} 
                    variant='outlined' 
                    className={statusColor}
                />
            </Box>
            <Box width={24}>
                <IconButton color='primary' onClick={() => onClickShow(id)} className='show-button'>
                    <Icon className='ri-arrow-right-line text-white' />
                </IconButton>
            </Box>
        </Box>
    )
}

export default Actions;