import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReadMoreLine from '../../../../shared/components/ReadMore/ReadMoreLine';
import clsx from 'clsx';

// Constants
import { DESC_PRODUCT_DEFAULT } from '../../../../shared/constants/products';

const useStyles = makeStyles((theme) => ({
    content:{
        padding: '24px 32px',
        [theme.breakpoints.down('xs')]:{
            padding: 0
        }
    },
    textColor:{
        color: theme.palette.colors.gray[500],
    },
    [theme.breakpoints.down('xs')]:{
        textParagraph:{
            fontSize:'14px !important'
        }
    }
}));

const StudyPlanText = ({ title, textParagraph}) => {

    const classes = useStyles()

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box className={classes.content}>
                    {
                        textParagraph !== DESC_PRODUCT_DEFAULT && <Typography variant='h4' className='mb-3'> { title } </Typography>
                    }
                    <ReadMoreLine
                        line={9}
                        text={
                            <Typography variant='body1' className={clsx(classes.textColor, classes.textParagraph)}>
                                { textParagraph }
                            </Typography>
                        }
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default StudyPlanText
