import React from 'react';
import { Box, Card, CardContent,  Typography } from '@material-ui/core';
import { gridPanelClasses } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

/*0 = GREY / 1 = GREEN / 2 == RED / 3 == VIOLET */
const COLORS_FOR_BG = [
    "grey",
    "green",
    "red",
    "violet",
];

const useStyles = makeStyles((theme) => ({
    stateContent: {
        backgroundColor: props => props.colorState == COLORS_FOR_BG[0] ? theme.palette.colors.fillStroke[300]:
                        props.colorState == COLORS_FOR_BG[1] ? theme.palette.colors.success[500]: 
                        props.colorState == COLORS_FOR_BG[2] ? theme.palette.colors.error[500]:
                        props.colorState == COLORS_FOR_BG[3] ? theme.palette.colors.violet[500]:"inherit",
        borderRadius: "16px",
        border: props => props.colorState == COLORS_FOR_BG[0] ? ("1px solid " + theme.palette.colors.fillStroke[200]):
                        props.colorState == COLORS_FOR_BG[1] ? ("1px solid " + theme.palette.colors.success[300]): 
                        props.colorState == COLORS_FOR_BG[2] ? ("1px solid " + theme.palette.colors.electricRed[300]):
                        props.colorState == COLORS_FOR_BG[3] ? theme.palette.colors.violet[300]:"inherit",
        lineHeight: "21px",
        textAlign: "center",
    },
}));

const SimpleStatus = ({text, state, ...rest}) => {
    const [colorState, setColorState] = React.useState(null);
    const classes = useStyles({colorState});
    React.useEffect(() => {
        setColorState(state != null ? COLORS_FOR_BG[state] : null);
    }, [state]);

    return (
        <Box display="flex" flexDirection="column">
            <Typography {...rest} className={classes.stateContent + " caption-small"}>{text}</Typography>
        </Box>
    );
};

export default SimpleStatus;