import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// core components
import InputFileButton from '../InputFileButton/InputFileButton';
import DetailFileButton from '../InputFileButton/DetailFileButton';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fileIcon:{
        fontSize: 40,
        color: '#828D9E',
        marginRight: 38
    },
    inputFileButton:{
        position: 'relative',
        '& input':{
            width: 'calc(100% + 64px)',
        }
    },
    errorOutlineIcon:{
        position: 'absolute',
        right: 64
    }
}));

const GeneralInputFile = ({isDisabled, options, optionValues, handleChangeFile, handleRemoveOptionFile}) => {
    const classes = useStyles();

    const buttons = options.map( (item, i, {length}) => {
        let valueOption = optionValues?.find( value => value.optionName === item.name);
        let file = {};
        let isSavedFile = false;

        if ( !!valueOption?.file ) {
            file = { 
                ...valueOption.file, 
                value: valueOption.file.name || valueOption.file.file_name, 
                isNew: Boolean( valueOption.file.name )
            };
        } 

        if ( !!valueOption?.id ) {
            isSavedFile = true;
            file = { value: valueOption.fileName || valueOption.file_name || null, isNew: !isDisabled, created_at: valueOption.created_at};
        }

        file.documentStatus = valueOption.documentStatus || null;

        return <Box style={{borderBottom: length - 1 !== i ? '1px solid rgba(119, 119, 119, 0.2)' : null}}>
            {
                (valueOption.documentStatus && valueOption.documentStatus === 1)
                || ((typeof optionValues === 'undefined' || typeof valueOption === 'undefined' || !valueOption['file']) 
                    && (!valueOption['fileName'] || !valueOption['file_name'])) 
                ?
                    <InputFileButton label={ item.label } 
                            onChange={ handleChangeFile } 
                            outlined={ false } 
                            disabled={ isDisabled } 
                            optionName={ valueOption.optionName } 
                            accept={item.accept}
                            display="flex" 
                            alignItems="center" 
                            justifyContent="space-between" key={item.id}
                            fullWidth
                            minHeight="87px"
                            textAlign="start"
                            className={clsx(classes.inputFileButton, 'p-4')}
                            startIcon={<i className={clsx(classes.fileIcon, 'ri-file-text-line')}></i>}
                            style={{color: '#F5F5F5'}}
                        >
                            {!isDisabled &&
                                <React.Fragment>
                                    <Tooltip title={!!valueOption.commentRejected ? valueOption.commentRejected : ""} aria-label="error" placement="top">
                                        <ErrorOutlineIcon color="error" className={classes.errorOutlineIcon}/>
                                    </Tooltip>
                                    <i className="ri-upload-2-line font-size-24 ml-2"></i>
                                </React.Fragment>
                            }
                    </InputFileButton>
                : 
                <DetailFileButton file={ file } onClickDeleteFile={ handleRemoveOptionFile } optionName={ valueOption.optionName } label={ item.label } isSavedFile={ isSavedFile }/>
            }
            </Box>
    });

    return <Grid container direction="column">{ buttons }</Grid>;
}


GeneralInputFile.defaultProps = {
};

GeneralInputFile.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    options: PropTypes.array,
    optionValues: PropTypes.array,
    handleChangeFile: PropTypes.func,
    handleRemoveOptionFile: PropTypes.func
};
 
export default GeneralInputFile;