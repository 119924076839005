import React from 'react';

// material components
import { Box, Icon, IconButton, InputBase, Link } from '@material-ui/core';

import useStyles from '../../../assets/styles/jss/components/simpleSearchWithFilterStyle';
const SimpleSearchWithFilter = ({onChange, placeholder, text, onBlur, onClickFilterButton, showFilterButton, onKeyPress}) => {
    const classes = useStyles({showFilterButton});
    
    const handleClickRemoveText = event => {
        onChange({target: {value : ""}});
    };
    const handleChange = (event) => { 
        onChange(event);
    };

    return (
        <Box display={'flex'} alignItems='center' className={classes.root}>
            <InputBase placeholder={placeholder} startAdornment={<i className="ri-search-line font-size-24"></i>} endAdornment={<Box visibility={(!!text.trim().length)? 'visible' : 'hidden'}><IconButton aria-label="delete" className={classes.closeIconButton} onClick={handleClickRemoveText}> <i className="ri-close-line font-size-24"></i> </IconButton></Box>} fullWidth value={text} onChange={handleChange} onBlur={onBlur} onKeyPress={onKeyPress}/>
            <Box className={`font-size-24 ${classes.separatorLine}`}>
                &#124;
            </Box>
            <Box className={classes.iconFilterContainer}> 
                <IconButton onClick={onClickFilterButton} aria-label="close-filter" component="span">
                    <Icon className="ri-filter-line font-size-24 text-white"></Icon>
                </IconButton>
            </Box>
        </Box>
    );
};

SimpleSearchWithFilter.defaultProps = {
    showFilterButton: true,
    onKeyPress: null,
}

export default SimpleSearchWithFilter;