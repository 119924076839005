import React from 'react';
import moment from 'moment'
import clsx from 'clsx'
import CurrencyFormat from 'react-currency-format';
import { Link as RouterLink } from 'react-router-dom';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// icons
import TimerIcon from '@material-ui/icons/Timer';
import LanguageIcon from '@material-ui/icons/Language';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import EventIcon from '@material-ui/icons/Event';
import FileCopyIcon from '@material-ui/icons/FileCopy';
// import CodeIcon from '@material-ui/icons/Code';
// import SettingsIcon from '@material-ui/icons/Settings';

// shared
import getTimeString from "../../../../shared/utils/getTimeString";

// components
import FavoriteButton from './FavoriteButton/FavoriteButton';

//assets
import Uerre from "../../../../assets/images/logos/logo-uerre-blanco.png";
import Unid from "../../../../assets/images/logos/logo-unid-blanco.png";
import Harmonhall from "../../../../assets/images/logos/cards-ico/harmonhall.svg";
import Advenio from "../../../../assets/images/logos/logo-advenio.png";
import LinkedIn from "../../../../assets/images/logos/logo-linkedin-blanco2.png";
import Coursera from "../../../../assets/images/logos/logo-coursera-blanco.png";

//SDK
import { MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../shared/components/Mixpanel/mixpanel';

const useStyles = makeStyles((theme) => ({
    root: {
        // '&:hover': {
        //     backgroundColor: '#EBFCFF',
        //     transition: '0.3s',
        // },
        // '&:hover .headerColor': {
        //     color: "#049CB4",
        // },
    },
    fixed: {
        minWidth: '363px',
        maxWidth: '363px',
    },
    content: {
        padding: `12px 16px 16px 16px`,
        position: "relative",
    },
    contentConfirm: {
        padding: `8px 10px 16px`,
        position: "relative",
    },
    media: {
        height: '169px',
        position: 'relative',
        filter: 'grayscale(100%)'
    },
    header: {
        fontSize: '18px',
        minHeight: '50px',
        overflow: 'hidden',
        margin: '4px 0px',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        width: "328px",
        height: "25px"
    },
    'headerColor': {
        fontSize: '18px',
        height: "50px",
        width: "328px",
        overflow: 'hidden',
        margin: '4px 0px',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },
    price: {
        fontSize: '20px',
        fontWeight: '700',
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    brand: {
        fontSize: '16px',
        fontWeight: '700',
    },
    mainCards: {
        width: '360px',
        maxHeight: '360px',
        margin: '10px',
        marginBottom: "10px !important",
        "@media (max-width: 500px)": {
            width: '344px',
            margin: '10px',
        },
    },
    rating: {
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        padding: theme.spacing(0.5, 1),
        borderRadius: "4px",
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: "12px"
    },
    favoriteButton: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    logoUO: {
        display: 'inline !important',
        height: '20px'
    },
    logoHH: {
        display: 'inline !important',
        height: "30px",
        marginTop: "-5px",
        "@media (max-width: 500px)": {
            height: "30px !important",
        }
    },
    logoThirdParty: {
        display: 'inline !important',
        height: '18px'
    },
    modalityChip: {
        display: 'flex',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        // justifyContent: 'flex-start',
        // padding: '6px',
        padding: "12px",
        '& .MuiChip-root': {
            padding: theme.spacing(1, 1),
            // margin: '6px',
            background: 'rgba(33, 158, 188, 0.5)',
            '& .MuiChip-label': {
                // fontSize: '10px',
                // textTransform: 'uppercase',
                fontWeight: '600',
                padding: theme.spacing(0.5, 1)
            }
        }
    },
    checkoutCard: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        height: "max-content",
        "@media (max-width: 670px)": {
            flexDirection: "column"
        }
    },
    mainCheckout: {
        maxWidth: "600px",
        minWidth: "600px",
        "@media (max-width: 1394px)": {
            marginRight: "auto",
            marginLeft: "auto"
        },
        "@media (max-width: 760px)": {
            maxWidth: "97%",
            minWidth: "97%"
        }
    },
    boxData: {
        paddingLeft: "226px",
        "@media (max-width: 760px)": {
            paddingRight: "18px"
        },
        "@media (max-width: 670px)": {
            padding: "20px"
        }

    },
    cardImgCheckout: {
        "@media (max-width: 670px)": {
            position: "inherit !important",
            left: "0px",
            width: "100%  !important",
            height: "200px  !important"
        }
    }
}));

const CourseCard = (props) => {
    const classes = useStyles();
    const { user, fixedWidth, buttons, type, marginB } = props;
    let { course } = props;
    if (type === "detail") {
        course = course.concepto;
    } else if (type === "checkout") {
        course = course.concepto;
    }

    let displayPrice = false;
    let durationIcon = null;
    let durationDesc = "";
    let modalityLabel = "";
    let difficultyDesc = "";

    if ('third_party_id' in course) {
        const time = getTimeString(course.estimated_time);

        durationIcon = <TimerIcon fontSize="small" color='secondary' />;
        durationDesc = `${time}`;
        modalityLabel = "Online";

        if (course.difficulty_level) {
            if (course.difficulty_level.toUpperCase() === "BEGINNER") {
                difficultyDesc = "Básico";
            } else if (course.difficulty_level.toUpperCase() === "INTERMEDIATE") {
                difficultyDesc = "Intermedio";
            } else if (course.difficulty_level.toUpperCase() === "ADVANCED") {
                difficultyDesc = "Avanzado";
            }
        }
    } else {
        let spliter = course.duration;
        if (spliter) {
            spliter = spliter.split(' ');
            if (spliter[1] === "horas") {
                spliter[1] = "hrs";
                if (spliter[0] === 1) {
                    spliter[1] = "hra";
                }
                spliter = spliter.join(" ");
            }
        }

        if (course.learning_style_id === 1) {
            if (course.start_date && course.end_date) {
                const date1 = moment(course.start_date);
                const date2 = moment(course.end_date);

                durationIcon = <EventIcon fontSize="small" color='secondary' />;
                durationDesc = `${moment.duration(date2.diff(date1)).asWeeks().toFixed(0)} semanas`;
            } else {
                durationIcon = <TimerIcon fontSize="small" color='secondary' />;
                durationDesc = `${spliter} aprox.`;
            }

        } else {
            durationIcon = <TimerIcon fontSize="small" color='secondary' />;
            durationDesc = `${spliter} aprox.`;
        }
        displayPrice = true;
        modalityLabel = course.modality_name;
        difficultyDesc = course.difficulty_level_name;
    }

    let linkProps = {};

    if (props.linkProps) {
        linkProps = { ...props.linkProps }
    } else {
        if ('third_party_id' in course) {
            linkProps.to = { pathname: course.course_url };
            linkProps.target = "_blank";
        } else {
            linkProps.to = `/course/${course.id}`;
        }
    }
    const preventDefault = (event) => event.preventDefault();
    
    return (
        <Card variant="outlined" className={clsx(classes.root, fixedWidth && classes.fixed, marginB ? "" : classes.mainCards, type === "checkout" && classes.mainCheckout)} style={{ marginBottom: marginB ? marginB : "0px" }}>
            {type === "detail" ?
                <CardActionArea component={RouterLink} {...linkProps} disableTouchRipple style={{ display: "flex", flexDirection: "row", height: "296px" }}>
                    <CardMedia
                        className={classes.media}
                        alt={`Curso ${course.name}`}
                        title={`Curso ${course.name}`}
                        image={course.image_url}
                        style={{ position: "absolute", left: "0", width: "224px", height: "100%" }}
                    >
                        <div className={classes.chip}>
                            {course.lang === "en" && <Chip icon={<LanguageIcon />} label="En Inglés" color="secondary" size="small" />}
                        </div>
                    </CardMedia>
                    <CardContent className={classes.content} style={{ width: "100%" }}>
                        <Box className="pb-3 pt-3" style={{ paddingLeft: "226px" }}>
                            {user ? <FavoriteButton user={user} course={course} initialState={course.favorite_id > 0} buttonClass={classes.favoriteButton} /> : null}
                            <Typography variant="body1" color="secondary">curso</Typography>
                            <Typography variant="subtitle1" className={classes.header}>{course.name}</Typography>
                            <Typography variant="subtitle2" style={{ marginBottom: "0px", marginTop: "2px", maxHeight: "48px", minHeight: "44px", lineHeight: "21px", textOverflow: "ellipsis", display: "-webkit-box", overflow: "hidden", WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, }}>{course.description}</Typography>
                            <Grid container spacing={1} className="mt-2" >
                                {
                                    difficultyDesc && (
                                        <Grid item>
                                            <Box display="flex" alignItems="flex-end">
                                                <EqualizerIcon fontSize="small" color="secondary" />&nbsp;
                                                <Typography component="span" variant="body2" color="secondary">{difficultyDesc}</Typography>
                                            </Box>
                                        </Grid>
                                    )
                                }
                                {/* <Grid item>
                                    <Box display="flex" alignItems="flex-end">
                                        <DesktopWindowsIcon fontSize="small" color="secondary" />&nbsp;
                                        <Typography component="span" variant="body2" color="secondary">{modalityDesc}</Typography>
                                    </Box>
                                </Grid> */}
                                <Grid item>
                                    <Box display="flex" alignItems="flex-end">
                                        {durationIcon}&nbsp;
                                        <Typography component="span" variant="body2" color="textSecondary">{durationDesc}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item style={{ marginTop: course.company_name === "ADVENIO" ? "-6px" : "0px" }}>
                                    {
                                        course.company_name === "U-ERRE" ? <img alt="logo" src={Uerre} className={classes.logoUO} /> :
                                            course.company_name === "UNID" ? <img alt="logo" src={Unid} className={classes.logoUO} /> :
                                                course.company_name === "Harmon Hall" ? <img alt="logo" src={Harmonhall} className={classes.logoUO} /> :
                                                    course.company_name === "ADVENIO" ? <img alt="logo" src={Advenio} className={classes.logoUO} style={{ height: "29px", marginBottom: "-12px" }} /> :
                                                        <Typography variant="h6" className={classes.brand}>{course.company_name}</Typography>
                                    }
                                </Grid>
                            </Grid>
                            {
                                displayPrice ? (
                                    <Box style={{ marginTop: "35px" }}>
                                        <Typography variant="caption" color="textSecondary">Inversión</Typography>
                                        <Typography variant="subtitle1" color="primary" className={classes.price}>
                                            <CurrencyFormat value={course.price} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="MXN $" />
                                        </Typography>
                                    </Box>
                                ) : null
                            }
                        </Box>
                        <Box display="flex">
                            {buttons}
                        </Box>
                    </CardContent>
                </CardActionArea> 
                :
                type === "checkout" ?
                    <CardActionArea disabled className={classes.checkoutCard}>
                        <React.Fragment>
                            <div className={classes.modalityChip}>
                                <Chip icon={<DesktopWindowsIcon />} label={modalityLabel} color="secondary" size="small" />
                                {/*course.lang === "en" && <Chip icon={<LanguageIcon />} label="En Inglés" color="secondary" size="small" />*/}
                                {/*course.environment && course.environment != "PROD" && <Chip icon={course.environment == "DEV" && <CodeIcon /> || course.environment == "TEST" && <SettingsIcon />} label={course.environment} color="primary" size="small" />*/}
                            </div>
                            <CardMedia
                                className={clsx(classes.media, classes.cardImgCheckout)}
                                alt={`Curso ${course.name}`}
                                title={`Curso ${course.name}`}
                                image={course.card_image_url || course.image_url}
                                style={{ position: "absolute", left: "0", width: "224px", height: "100%" }}
                            >
                                {/* <div className={classes.chip}>
                                {course.lang === "en" && <Chip icon={<LanguageIcon />} label="En Inglés" color="secondary" size="small" />}
                            </div> */}
                            </CardMedia>
                        </React.Fragment>
                        <CardContent className={classes.content} style={{ width: "100%" }}>
                            <Box className={clsx(classes.boxData, /*"pb-3"*/ "")}>
                                {/* {user ? <FavoriteButton user={user} course={course} initialState={course.favorite_id > 0} buttonClass={classes.favoriteButton} /> : null} */}
                                <Typography variant="body1" color="secondary">curso</Typography>
                                <Typography variant="subtitle1" className={classes.header} style={{ width: "100%" }}>{course.name}</Typography>
                                {/* <Typography variant="subtitle2" style={{ marginBottom: "10px", maxHeight: "48px", minHeight: "44px", width: "100%", lineHeight: "21px", textOverflow: "ellipsis", display: "-webkit-box", overflow: "hidden", WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, color: "#576275" }}>{course.description}</Typography> */}
                                {
                                    displayPrice ? (
                                        <Box style={{ marginBottom: "15px" }}>
                                            <Typography variant="caption" color="textSecondary">Inversión</Typography>
                                            <Typography variant="subtitle1" color="primary" className={classes.price}>
                                                <CurrencyFormat value={course.price} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="MXN $" />
                                            </Typography>
                                        </Box>
                                    ) : null
                                }
                                <Grid container spacing={1} className="mt-2" >
                                    <Grid item style={{ marginTop: course.company_name === "ADVENIO" ? "-6px" : "0px" }}>
                                        {
                                            course.company_name === "U-ERRE" ? <img alt="logo" src={Uerre} className={classes.logoUO} style={{ height: "24px" }} /> :
                                                course.company_name === "UNID" ? <img alt="logo" src={Unid} className={classes.logoUO} /> :
                                                    course.company_name === "Harmon Hall" ? <img alt="logo" src={Harmonhall} className={classes.logoHH} /> :
                                                        course.company_name === "ADVENIO" ? <img alt="logo" src={Advenio} className={classes.logoUO} style={{ height: "24px", marginBottom: "-5px" }} /> : null
                                        }
                                    </Grid>
                                    {
                                        difficultyDesc && (
                                            <Grid item>
                                                <Box display="flex" alignItems="flex-end">
                                                    <EqualizerIcon fontSize="small" color="secondary" />&nbsp;
                                                    <Typography component="span" variant="body2" color="secondary">{difficultyDesc}</Typography>
                                                </Box>
                                            </Grid>
                                        )
                                    }
                                    {/* <Grid item>
                                        <Box display="flex" alignItems="flex-end">
                                            <DesktopWindowsIcon fontSize="small" color="secondary" />&nbsp;
                                            <Typography component="span" variant="body2" color="secondary" >{modalityDesc}</Typography>
                                        </Box>
                                    </Grid> */}
                                    {/* <Grid item>
                                        <Box display="flex" alignItems="flex-end">
                                            {durationIcon}&nbsp;
                                            <Typography component="span" variant="body2" color="textSecondary">{durationDesc}</Typography>
                                        </Box>
                                    </Grid> */}
                                    {
                                        (course.total_lessons > 0 || course.total_modules > 0) &&
                                        <Grid item>
                                            <Box display="flex" alignItems="flex-end">
                                                <FileCopyIcon fontSize='small' color="secondary" />&nbsp;
                                                <Typography component="span" variant="body2" color="secondary">{course.total_lessons || course.total_modules} Lecciones</Typography>
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        </CardContent>
                    </CardActionArea> 
                    :
                    <Box>
                        <CardActionArea component={RouterLink} {...linkProps} disableTouchRipple>
                            <React.Fragment>
                                <div className={classes.modalityChip}>
                                    <Chip icon={<DesktopWindowsIcon />} label={modalityLabel} color="secondary" size="small" />
                                    {/*course.lang === "en" && <Chip icon={<LanguageIcon />} label="En Inglés" color="secondary" size="small" />*/}
                                    {/*course.environment && course.environment != "PROD" && <Chip icon={course.environment == "DEV" && <CodeIcon /> || course.environment == "TEST" && <SettingsIcon />} label={course.environment} color="primary" size="small" />*/}
                                </div>
                                <CardMedia
                                    className={classes.media}
                                    alt={`Curso ${course.name}`}
                                    title={`Curso ${course.name}`}
                                    image={course.card_image_url || course.image_url}
                                >
                                    {/*
                                <Box className={classes.rating}>
                                    <Rating size="small" value={course.rating_score} precision={0.5} readOnly emptyIcon={<StarBorderIcon fontSize="inherit" />} />
                                    <Typography variant="body2" className="ml-1 mr-1" color="textSecondary">{parseFloat(course.rating_score).toFixed(1)}</Typography>
                                </Box>
                            */}
                                </CardMedia>
                            </React.Fragment>
                            <CardContent className={classes.content}>
                                <Box /*className="mb-3"*/>
                                    {user ? <FavoriteButton user={user} course={course} initialState={course.favorite_id > 0} buttonClass={classes.favoriteButton} /> : null}
                                    <Typography variant="body1" color="secondary">curso</Typography>
                                    <Typography variant="subtitle1" className={classes.headerColor}>{course.name}</Typography>
                                    {
                                        displayPrice ? (
                                            <Box>
                                                <Typography variant="caption" color="textSecondary">Inversión</Typography>
                                                <Typography variant="subtitle1" color="primary" className={classes.price}>
                                                    <CurrencyFormat value={course.price} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="MXN $" />
                                                </Typography>
                                            </Box>
                                        ) : null
                                    }
                                    <Grid container spacing={1} className="mt-2">
                                        <Grid item style={{ marginTop: course.company_name === "ADVENIO" ? "-6px" : "0px" }}>
                                            {
                                                course.source === "UERRE" || course.source === "U-ERRE" ? <img alt="logo" src={Uerre} className={classes.logoUO} /> :
                                                    course.source === "UNID" ? <img alt="logo" src={Unid} className={classes.logoUO} /> :
                                                        course.source === "HH" || course.source === "Harmon Hall" ? <img alt="logo" src={Harmonhall} className={classes.logoHH} /> :
                                                            course.source === "ADV" || course.source === "ADVENIO" ? <img alt="logo" src={Advenio} className={classes.logoUO} style={{ marginBottom: "-5px" }} /> :
                                                                course.source === "LinkedIn Learning" ? <img alt="logo" src={LinkedIn} className={classes.logoThirdParty} /> :
                                                                    course.source === "Coursera" ? <img alt="logo" src={Coursera} className={classes.logoThirdParty} /> :
                                                                        <Typography variant="h6" className={classes.brand}>{course.source}</Typography>
                                            }
                                        </Grid>
                                        {
                                            difficultyDesc && (
                                                <Grid item>
                                                    <Box display="flex" alignItems="flex-end">
                                                        <EqualizerIcon fontSize="small" color="secondary" />&nbsp;
                                                        <Typography component="span" variant="body2" color="secondary">{difficultyDesc}</Typography>
                                                    </Box>
                                                </Grid>
                                            )
                                        }
                                        {/* <Grid item>
                                        <Box display="flex" alignItems="flex-end">
                                            <DesktopWindowsIcon fontSize="small" color="secondary" />&nbsp;
                                            <Typography component="span" variant="body2" color="secondary">{modalityDesc}</Typography>
                                        </Box>
                                    </Grid> */}
                                        {/* <Grid item>
                                        <Box display="flex" alignItems="flex-end">
                                            {durationIcon}&nbsp;
                                            <Typography component="span" variant="body2" color="textSecondary">{durationDesc}</Typography>
                                        </Box>
                                    </Grid> */}
                                        {
                                            (course.total_lessons > 0 || course.total_modules > 0) &&
                                            <Grid item>
                                                <Box display="flex" alignItems="flex-end">
                                                    <FileCopyIcon fontSize='small' color="secondary" />&nbsp;
                                                    <Typography component="span" variant="body2" color="secondary">{course.total_lessons || course.total_modules} Lecciones</Typography>
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                                <Box display="flex">
                                    {buttons}
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Box>
            }
        </Card>
    )
};

export default CourseCard;
