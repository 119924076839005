/* cartHelper.js contiene los métodos principales de gestión del carrito */
import { CartService } from "@sdk-point/talisis";
import { v4 as uuid } from 'uuid';

import { getSessionCookie, userIsLogged } from "./Sessions";
import { getCartCookie, setCartCookie } from "./cartSession";

const cartService = new CartService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

export const getCartGlobal = async (person_id = null) => {
    const isLogged = userIsLogged();

    // Utilizar el id de la persona si está identificada
    if (isLogged || person_id) {
        const session = getSessionCookie();
        let response;
        if (person_id) {
            response = await cartService.getCartByPersonId(person_id);
        } else {
            // Obtener el carrito asociado al id de persona
            response = await cartService.getCartByPersonId(session.person_id);
        }

        return response;
    } else {
        const session = getCartCookie();

        // Obtener el carrito asociado al id de sesión anónima
        const response = await cartService.getCartBySessionId(session.session_id || '0');
        return response;
    }
};

export const addProductsToCart = async ({ cart_id, products, person_id = null, payment_token = null, admission_id = null }) => {
    // Crear nuevo carrito para la persona actual
    let currentCartId = cart_id;

    // Si no existe un id de carrito, se creará uno nuevo
    if (!currentCartId) {
        const isLogged = userIsLogged();

        // Crear un carrito para la persona si está identificada
        if (isLogged || person_id) {
            let data;
            //Si se envia person id por parametro lo utiliza para crear el carrito
            if (person_id) {
                data = await cartService.createCart(person_id, payment_token, admission_id);
            } else {
                const session = getSessionCookie();
                // Crear u obtener el carrito de la persona identificada
                data = await cartService.createCart(session.person_id, payment_token, admission_id);
            }
            currentCartId = await data.id;
        } else {
            // Crear un carrito anónimo
            const session = getCartCookie();

            // Si no existe una sesión anónima se generará una nueva y se actualizará la cookie del carrito
            if (!session.session_id) {
                session.session_id = uuid();
                setCartCookie({ ...session })
            }

            // Crear u obtener el carrito con sesión anónima creada
            const data = await cartService.createCartFromSession(session.session_id);
            currentCartId = await data.id;
        }
    }

    // Agregar productos y obtener carrito actual
    const response = await cartService.addProducts(currentCartId, products);
    return response;
};

export const deleteProductInCart = async ({ cart_id, product_id }) => {
    // Eliminar productos y obtener carrito actualizado.
    const response = await cartService.deleteProduct(cart_id, product_id);
    return response;
};