import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { log } from '../../../shared/utils/console.js';

// Material components
import { Box, Button, Card, Dialog, DialogTitle, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// Shared components
import Select from '../../../design-system/components/Form/Select/Select';
import FileDetail from '../components/FileDetail';
import ListDocuments from '../components/ListDocuments';
import FileDropZone from '../../../design-system/components/Form/FileDropZone/FileDropZone';
import LoaderContent from '../../../design-system/components/Loader/LoaderContent';

import DeliveryGroupPhysicalDocs from '../components/DeliveryGroupPhysicalDocs'

// Shared constants
import { APPROVED_STATUS, PENDING_STATUS, REJECTED_STATUS, REVIEW_STATUS } from '../../../shared/constants/record-status';

// Shared Utils
import dateFormat from '../../../design-system/utils/dateFormat';

// Constants
import { SIMPLE_DATE_FORMAT } from '../../../design-system/constants/date-formats';

// SDK
import { GlobalService, RecordDocumentService, CatalogsService } from '@sdk-point/talisis';

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const CatalogsServiceSDK = new CatalogsService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const BYTE_INTO_MEGABYTE = 1000000;

const useStyles = makeStyles((theme) => ({
    generalContainer: {
        marginBottom: "70px"
    },
    filterContainer: {
        padding: '16px 24px',
    },
    filterProgramLabelContainer: {
        margin: '12px 0',
    },
    titleDocumentListContainer: {
        padding: '16px 24px 16px 24px',
    }, 
    uploadIcon: {
        cursor: 'pointer',
        fontSize: '24px',
    },
    dialogTitle:{
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid rgba(119, 119, 119, 0.15)`,
        '& .MuiTypography-root':{
            flex: 1,
            textAlign: 'center'
        }
    },
    dialogContainer: {
        background: `${theme.palette.colors.fillStroke[500]} !important`,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    dropZoneContainer: {
        width: "100%",
        height: "307px",
        marginBottom: "64px",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '256px',
            marginBottom: "16px",
        }
    },
    bodyDialogContainer: {
        width: '506px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    listItem: {
        borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[200]}`
    },
}));

const StudentRecord = ({ user }) => {
    const classes = useStyles();

    const [programList, setProgramList] = useState([]);
    const [countries, setCountries] = useState({});
    const [programSelected, setProgramSelected] = useState('all');
    const [documentGroups, setDocumentGroups] = useState([]);
    const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
    const [documentSelected, setDocumentSelected] = useState({});
    const [fileInfo, setFileInfo] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);

    // const filteredDocumentLists = documentGroups.filter(itListGroup => (itListGroup.documentData === 'document' || (itListGroup.documentData === 'program' && itListGroup.programs.length)));
    // const filteredDocumentLists = documentGroups.filter(itListGroup => (itListGroup.documentData === 'document' || (itListGroup.documentData === 'program' && itListGroup.programs.length)));
    const filteredDocumentLists = documentGroups;

    useEffect(() => {
        getDocuments();
    }, [programSelected]);

    useEffect(() => {
        getCountries();
    }, []);

    const getCountries = async () => {
        try{
            const countriesResponse = await CatalogsServiceSDK.getCitiesByCountry();
            setCountries(countriesResponse.data);
        }catch(err){

        }
    }

    // Functions
    const getDocuments = async () => {
        try {
            setIsDocumentsLoading(true);

            const params = programSelected !== 'all' ? {value: programSelected} : null;
            
            const dataResponse = await RecordDocumentServiceSDK.getDocumentsForUser(user.person_id, params);

            if (!!dataResponse.success) {
                dataResponse.data.groups.forEach(group => {
                    

                    if(group.documentData === 'program'){
                        group.documents = group.programs.flatMap(p => p.documents);
                    }
                    return group.documents.forEach(doc => doc.showDate = !!doc.showDate ? dateFormat(new Date(doc.showDate), SIMPLE_DATE_FORMAT) : null)
                });

                log('dataResponse.data.groups', dataResponse.data.groups);

                setDocumentGroups(dataResponse.data.groups);
                setProgramList([
                    {
                        id: 'all',
                        label: 'Todos',
                    }, 
                    ...dataResponse.data.programs
                ]);
            }
            
            setIsDocumentsLoading(false);
        }
        catch (error) {
            log('error', error);
        }
    };
    
    const deleteRecentlyUploadedFile = async () => {
        if (!!fileInfo.id) { 
            setIsModalLoading(true);
            await deleteFile(fileInfo.id);
            setFileInfo({});
            setIsModalLoading(false);
        }
    };
    
    const deleteFile = async (id) => {
        try {
            await GlobalServiceSDK.deleteFile(id);
        } catch (error) {
            log('error', error);
        }
    };

    // Hanldes
    const handleCloseModal = async ()  => {
        try {
            if (!!fileInfo.id) await deleteRecentlyUploadedFile();

            setDocumentSelected({});
            setOpenModal(false);
        } catch (error) {
            log('error', error);
        }
    };

    const handleOpenModal = (doc) => (e) => {
        setDocumentSelected(doc);
        setOpenModal(true);
    };

    const handleUploadFile = async (file) => {
        try {
            setIsModalLoading(true);
            const newFile = new File([file], file.name.replace(/[^a-zA-Z0-9-_. áéíóúÁÉÍÓÚñÑ]/g, ' ').trim(), { type: file.type });
            const location = 'record/userDocument';
            const fileResult = await GlobalServiceSDK.uploadFile(newFile, location, 'record-document', documentSelected.document_id);

            setFileInfo({...fileResult, size: file.size});
            setIsModalLoading(false);
        } catch (error) {
            log('error', error);
        }
    };

    const handleResetUploadedFile = async () => {
        await deleteRecentlyUploadedFile();
    };

    const handleGetDocumentWithFilter = (e) => {
        try {
            setProgramSelected(e.target.value);
        } catch (error) {
            log('error', error);
        }
    };

    const handleAddDocumentToRecord = async () => {
        try {
            setIsModalLoading(true);

            if (documentSelected.record_status_id === REJECTED_STATUS || !!documentSelected.record_id) {
                const body = {
                    record: {
                        file_id: fileInfo.id,
                        record_status_id: REVIEW_STATUS,
                    }
                };

                if (documentSelected.record_status_id === REJECTED_STATUS) {
                    await deleteFile(documentSelected.file_id);
                    body.record.approver_person_id = null;
                    body.record.error_message = null;
                }

                await RecordDocumentServiceSDK.updateRecord(documentSelected.record_id, body);
            } else {
                const body = {
                    record: {
                        person_id: user.person_id,
                        document_id: documentSelected.document_id,
                        file_id: fileInfo.id,
                        record_status_id: REVIEW_STATUS
                    }
                };
                await RecordDocumentServiceSDK.createRecord(body);
            }

            setDocumentSelected({});
            setFileInfo({});
            setOpenModal(false);
            setIsModalLoading(false);
            await getDocuments();
        } catch (error) {
            log('error', error);
        }
    };

    // Effects
    // useEffect(() => {
    //     getDocuments();
    // }, []);

    log('filteredDocumentLists', filteredDocumentLists);
    
    return (
        <LoaderContent loading={isDocumentsLoading}>
            <Grid container spacing={3} className={classes.generalContainer}>
                <Grid item xs={12} md={4}>
                    <Card className={classes.filterContainer}>
                        <Typography variant="h4" className='text-gray-100' >Filtrar por:</Typography>
                        <Box className={classes.filterProgramLabelContainer}>
                            <Typography variant="body2" className={clsx('text-gray-100', 'semi-bold')} >Programa</Typography>
                        </Box>
                        <Select
                            value={programSelected}
                            name="programSelected"
                            placeholder="Selecciona el programa"
                            required
                            items={programList} 
                            onChange={handleGetDocumentWithFilter}
                            />
                    </Card>
                </Grid>
                <Grid item xs={12} md={8} justifyContent='flex-end'>
                    <Card>
                        <Box className={classes.titleDocumentListContainer}>
                            <Typography variant="h4">Envío de documentos digitales</Typography>
                            <Typography variant="body1">Adjunta los siguientes documentos para que podamos crear tu expediente escolar.</Typography>
                        </Box>
                        {
                            filteredDocumentLists.map((listGroup, index) =>
                                <ListDocuments 
                                    title={listGroup.label} 
                                    subtitle={`${listGroup.documents.length} Documentos`} 
                                    key={`list-documents-${index}`} open={!index} 
                                    status={listGroup.record_status_id[0]}
                                    noData={listGroup.documentData === 'program' ? false : !listGroup.documents.length}
                                >
                                    {
                                        listGroup.documentData === 'program'
                                        ?
                                            <DeliveryGroupPhysicalDocs listGroup={listGroup.programs} locationCatalog={countries}/>
                                        :listGroup.documents.map((documentObj, documentIndex) =>
                                            <Box className={classes.listItem} key={`list-documents-item-${documentIndex}`}>
                                                <Box p={3} display='flex' alignItems='center' justifyContent='space-between' className={clsx(classes.groupListItem, {'inline': listGroup.record_status_id[0] !== REVIEW_STATUS})}>
                                                    <FileDetail 
                                                        key={uuid()}
                                                        title={documentObj.document_name}
                                                        fileName={documentObj.file_name}
                                                        filePath={documentObj.path}
                                                        dueDate={documentObj.record_status_id !== APPROVED_STATUS ? documentObj.showDate : null}
                                                        active={[REJECTED_STATUS, REVIEW_STATUS, APPROVED_STATUS].includes(documentObj.record_status_id)}
                                                        flex={1}
                                                        status={documentObj.record_status_id}
                                                        tags={documentObj.value_list}
                                                        isAdmin={false}
                                                        downloadableFile={!!documentObj.file_id_downloadable && documentObj.path_downloadable}
                                                        instruction={documentObj.instruction}
                                                        messageToUser={documentObj.record_status_id === REJECTED_STATUS ? documentObj.error_message : ""}
                                                        approvedDate={documentObj.record_status_id === APPROVED_STATUS ? documentObj.showDate : null}
                                                    />
                                                    {
                                                        [PENDING_STATUS, REJECTED_STATUS].includes(documentObj.record_status_id) && 
                                                        <Box>
                                                            <Icon className={clsx('ri-upload-2-line', classes.uploadIcon, documentObj.record_status_id === REJECTED_STATUS && 'offset-ml-4')} onClick={handleOpenModal(documentObj)}/>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </ListDocuments>
                            )
                        }
                    </Card>
                </Grid>

                <Dialog onClose={handleCloseModal} aria-labelledby="simple-dialog-title" open={openModal} classes={{paper: classes.dialogContainer}}>
                    <DialogTitle id="simple-dialog-title" disableTypography className={classes.dialogTitle}>
                        <Typography variant="subtitle1">Subir documento</Typography>
                        <IconButton aria-label="close" className='p-0' onClick={handleCloseModal}>
                            <Icon className='ri-close-line text-fill-stroke-100' style={{fontSize: 24}}/>
                        </IconButton>
                    </DialogTitle>
                    <Box p={3} className={classes.bodyDialogContainer}>
                        <LoaderContent loading={isModalLoading}>
                            {
                                !fileInfo.id &&
                                <Box>
                                    <Box pb="16px"> 
                                        <Typography>{`Únicamente archivos ${!!documentSelected.extensions ? documentSelected.extensions.map(item => item.extension.toUpperCase()).join(", ") : ""} con peso máximo de 10 MB`}</Typography>
                                    </Box>
                                    <FileDropZone className={classes.dropZoneContainer} progressLabel={50} extensions={!!documentSelected.extensionDetails ? documentSelected.extensionDetails : {}} onUploadFile={handleUploadFile}/>
                                </Box>
                            }
                            {
                                !!fileInfo.id &&
                                <Box display="flex" justifyContent="center" flexDirection="column" gridGap={16} mb="8px">
                                    <Box display="flex" justifyContent="center">
                                        <Icon className='ri-file-text-line text-violet-300' style={{fontSize: 33}}/>
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" gridGap={8}>
                                        <Button color='primary' size='large' className='p-0'>{fileInfo.fileName}</Button>
                                        <Typography variant="body1" className={clsx("text-fill-stroke-100")}>{`${Number.parseFloat(fileInfo.size / BYTE_INTO_MEGABYTE).toFixed(2)} MB`}</Typography>
                                    </Box>
                                    <Button variant='outlined' color='secondary' onClick={handleResetUploadedFile}>Volver a subir el archivo</Button>
                                </Box>
                            }
                            <Button variant='contained' color='primary' disabled={!fileInfo.id} fullWidth endIcon={<Icon className='ri-arrow-right-line' />} onClick={handleAddDocumentToRecord}>Enviar documento</Button>
                        </LoaderContent>
                    </Box>
                </Dialog>
            </Grid>

        </LoaderContent>
    );
};

const mapStateToProps = ({ userReducer }) => ({
    user: userReducer.user,
});

export default connect(mapStateToProps, null)(StudentRecord);