import palette from '../palette';

export default {
    root: {
        // backgroundColor: '#FFFFFF'
        '&:hover':{
            backgroundColor: `${palette.colors.fillStroke[300]} !important`,
        },
    },
    head:{
        '& .MuiTableCell-root': {
            '&:first-child':{
                borderTopLeftRadius: 5,
            },
            '&:last-child':{
                borderTopRightRadius: 5,
            },
        },
    }
};
