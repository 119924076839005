import React, { lazy, Suspense } from 'react';
import { connect } from "react-redux";
//Materil UI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { SwiperSlide } from 'swiper/react';

// Shared components
import SlickCarousel from '../../../shared/components/SlickCarousel/SlickCarousel';
const CourseCard = lazy(() => import('../../../shared/components/CourseCarousel/CourseCard/CourseCard'));

// const CourseCarousel = lazy(() => import('./CourseCarousel'));
const CourseCarousel = lazy(() => import('../../../shared/components/CourseCarousel/CourseCarousel'));

const useStyles = makeStyles((theme) => ({
    content:{
        width: "100vw",
        position: "relative",
        /*left: "50%",
        marginLeft: "-50vw",*/
        maxWidth: "100%",
        marginLeft: "20px",
        marginRight: "auto",

        /*marginBottom: "-24px",*/
        overflowX: "hidden",
        boxSizing: "content-box",
        "@media (max-width: 700px)": {
            marginLeft: "0px",
        }
    },
    title:{
        maxWidth: "1280px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "89%",
    },
    continuar:{
        width: "90%",
        maxWidth: "1280px",
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const RecommendedCourseMyClass = ()=>  {
    const classes = useStyles(); 

    

    return (
        <Box className={classes.content}>
            <Box style={{minHeight: 470}}>
                <Suspense fallback={null}>
                    <CourseCarousel
                        lazyLoading
                        id='recommendations'
                        type={'consumo'}
                        landing={true}
                        title='Recomendaciones'
                        urlMore='/busqueda?educon=true'
                        withoutPaddings={true}
                        viewCourseDetails
                        clickFrom={'curso'}
                        />
                </Suspense>
            </Box>
            <Box style={{minHeight: 470}}>
                <Suspense fallback={null}>
                    <CourseCarousel
                        lazyLoading
                        id='recommendations'
                        type={'interes'}
                        landing={true}
                        title='Más cursos sobre lo que te interesa'
                        urlMore=''
                        withoutPaddings={true}
                        noDataFound={{
                            caption: "Al parecer aún no has completado tu listado de intereses", 
                            subcaption: "Te invitamos a ir a tu perfil para personalizar las categorías que te gustaría aprender, así podremos recomendarte los mejores cursos.", 
                            type: 'search'
                        }}
                        viewCourseDetails
                        clickFrom={'curso'}
                        />
                </Suspense>
            </Box>           
        </Box>
    );
}

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(RecommendedCourseMyClass);
