const DEV = 'https://kmnk2k677k.execute-api.us-west-2.amazonaws.com/dev';
const PROD = 'https://kmnk2k677k.execute-api.us-west-2.amazonaws.com/v1';
const BODY_SC = 'https://cloud.scorm.com/api/v2/';

export const PING_SC = BODY_SC + "ping";
export const CURSES_SC = BODY_SC + "courses";
export const REGISTERS_SC = BODY_SC + "registrations";
export const API_ENDPOINT = `https://kmnk2k677k.execute-api.us-west-2.amazonaws.com/${process.env.REACT_APP_ENV}`;
export const KARDEX_ENDPOINT_UERRE = 'http://alumnos.ur.mx/sso.aspx';
export const KARDEX_ENDPOINT_UNID = 'http://apps.u-erre.mx/sso.aspx';

export const CATALOGS_PATH = `${API_ENDPOINT}/catalogs`;
export const CATALOG_PATH = `${CATALOGS_PATH}/catalog`;
export const FILES_PATH = `${API_ENDPOINT}/files`;
export const DOWNLOAD_FILES_PATH = `${FILES_PATH}/download`;
export const REQUESTS_PATH = `${API_ENDPOINT}/requests`;
export const USER_PATH = `${API_ENDPOINT}/user`;
export const COURSES_PATH = `${API_ENDPOINT}/courses`;

export const ENCRYPT_PATH = `https://kmnk2k677k.execute-api.us-west-2.amazonaws.com/${process.env.REACT_APP_ENV}/arcfour`;
export const PROFILE_PATH = `${API_ENDPOINT}/user/profile`;
export const STUDENT_PATH = `${API_ENDPOINT}/user/student`;
export const CATALOG_INTEREST_AREA_PATH = `${CATALOG_PATH}/courses.CourseSkillCat?skill_type_id=2&skill_type_id=1`;
export const FEED_PATH = `${API_ENDPOINT}/feed`;

export const USERPAG_PATH = `${API_ENDPOINT}/user/table`;

export const USERPAGPERM_PATH = `${API_ENDPOINT}/user/table/perm`;

export const NOTIFICATIONS_PATH = `${API_ENDPOINT}/notifications`;
export const COMPANY_CAMPUS_PATH = `${API_ENDPOINT}/company/campus`;
export const UPLOAD_FILE_PATH = `${API_ENDPOINT}/files`;
export const SIMULATION_PATH = `${API_ENDPOINT}/simulation`;
export const PERSONS_SEARCH_PATH = `${API_ENDPOINT}/persons/search`;
export const REQUESTS_BY_TYPE = `${REQUESTS_PATH}/type`;
export const REQUESTS_BY_PERSON = `${REQUESTS_PATH}/person`;
export const REQUESTS_WORKFLOW = `${REQUESTS_PATH}/workflow`;
export const ACCESS_PATH = `${API_ENDPOINT}/access`;
export const MARKET_PLACE_PATH = `${API_ENDPOINT}/market-place`;
export const INITIAL_TUTORIAL_PATH = `${API_ENDPOINT}/user/is-initial-tour`;
export const CREATE_NOTIFICATION_PATH = `${API_ENDPOINT}/notifications/test`;
export const REQUESTS_BY_COMPANY = `${REQUESTS_PATH}/company`;
export const USERS_GROUPS_PATH = `${API_ENDPOINT}/users/groups`;
export const REGISTER_PROGRESS_BY_REGISTERID = `${API_ENDPOINT}/market-place/courses/progress/update`;
export const PERSON_NATIONALITY_CAT = `${CATALOG_PATH}/NationalityCat`;
//export const REQUESTS_ARCHIVE = `${REQUESTS_PATH}/archive`;

export const SURVEYS_PATH = `${API_ENDPOINT}/user/surveys`;
