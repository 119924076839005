import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';

import palette from '../../../design-system/theme/palette';

import { makeStyles } from '@material-ui/core/styles';
import { DialogContentText, Icon } from '@material-ui/core';

const useStyles = makeStyles(({
    paperWidthXs: {
      maxWidth: '596px',
      backgroundColor: palette.colors.fillStroke[400],
      border: '1px solid ' + palette.colors.fillStroke[200],
      borderRadius: '16px'
    },
    dialogActionsStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

const DialogTypes = ({
    "default":<Icon className={"ri-question-line font-size-24 text-electric-blue-200"} />,
    "warning":<Icon className={"ri-error-warning-line font-size-24 text-orange-300"} />,
    "info":<Icon className={"ri-information-line font-size-24 text-electric-blue-200"} />,
    "custom": <Icon className={"ri-settings-3-fill font-size-24 text-electric-blue-200"} />,
});

const ConfirmDialog = (props) => {
    const classes = useStyles();
    const { children, title, text, type, open, disabledConfirmButton, onConfirm, onClose, dialogTitle, titleButtonClose, titleButtonOk, variantButtonClose, colorButtonClose, withButtonClose, withButtonOK, ...rest } = props;
    const icon = DialogTypes[type];

    const handleOk = (e) => {
        if (onConfirm) {
            onConfirm(true);
        }

        onClose();
    };

    const handleClose = (e) => {
        onClose();
    };

    return (
        <React.Fragment>
            <Box>
                <Dialog
                    open={open}
                    fullWidth
                    classes={{
                    paperWidthXs: classes.paperWidthXs,
                    }}
                    onClose={handleClose}
                    {...rest}
                >   
                    {
                    dialogTitle &&
                        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    }
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {
                        !dialogTitle &&
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
                                        {icon}
                                        <Typography variant="h3" className="ml-2">{title}</Typography>
                                    </Box>
                                    <Typography variant="caption" align="center">{text}</Typography>
                                </Grid>
                            </Grid>
                        }
                        </DialogContentText>
                        {children}
                    </DialogContent>
                    <DialogActions className={classes.dialogActionsStyle}>
                    <Button variant={variantButtonClose} onClick={handleClose} color={colorButtonClose} autoFocus className="m-1" style={{width: withButtonClose}} >
                        {titleButtonClose}
                    </Button>
                    <Button variant="contained" onClick={handleOk} color="primary" className="m-1" disabled={disabledConfirmButton} style={{width: withButtonOK}}>
                        {titleButtonOk}
                    </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </React.Fragment>
    );
}

ConfirmDialog.defaultProps = {
    type: "default",
    open: false,
    disabledConfirmButton: false,
    disableEscapeKeyDown: false,
    maxWidth: "xs",
    variantButtonClose : "outlined",
    colorButtonClose: "secondary",
    titleButtonClose: "Volver",
    titleButtonOk: "Confirmar",
    withButtonClose: "auto",
    withButtonOK:"auto"
};

ConfirmDialog.propTypes = {
    dialogTitle: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.any,
    type: PropTypes.oneOf([
        "default",
        "warning",
        "info",
        "custom"
    ]),
    open: PropTypes.bool,
    disabledConfirmButton: PropTypes.bool,
    maxWidth: PropTypes.oneOf([
        "xs",
        "sm",
        "md",
        "lg",
        "xl"
    ]),
    disableEscapeKeyDown: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    variantButtonClose : PropTypes.string,
    colorButtonClose: PropTypes.string,
    titleButtonClose: PropTypes.string,
    titleButtonOk: PropTypes.string,
    withButtonClose: PropTypes.string,
    withButtonOK: PropTypes.string
};

export default ConfirmDialog;