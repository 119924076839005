import React from 'react';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.colors.grey[500],
        borderRadius: '8px',
        height: '35vw',
        margin: '0 auto',
        width: '82.5%',
    },
    image: {
        borderRadius: '8px',
        position: 'relative',
        width: '100%',
    }
}));

const BrandLandingPageDetailUnid = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography variant="h4" align="center" color="textPrimary" className="mt-5">Nuestros canales de aprendizaje</Typography>
            <Box className={`${classes.root} mt-5`}>
                {/* <img src={`https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/unid/programs/learning-channels.svg`} className={classes.image} /> */}
            </Box>
        </React.Fragment>
    )
};

export default BrandLandingPageDetailUnid;