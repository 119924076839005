import React from "react";
import clsx from "clsx";
//@Material-ui
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
//Styles 
import useStyles from '../../../../../../../assets/styles/jss/pages/memberships/versionSKU/MembershipsContainerCard/MembershipCard/MembershipComboBrandsStyle';
//DesignSystem
import LogoSource from '../../../../../../../design-system/components/LogoSource/LogoSource'
 
const MembershipCardComboCoursesAllies = ({membershipName}) => {
    const classes = useStyles();
    const SOURCE_ID_UERRE = 8;
    const SOURCE_ID_UNID = 9;
    const SOURCE_ID_LINKEDIN = 6;
    const SOURCE_ID_CREHANA = 13;
    const SOURCE_ID_COURSERA = 5;
    const isPremium = membershipName === 'premium';
    return (
        <Box className={classes.boxComboCoursesAllies}>
            { isPremium ? 
                <Typography variant="body1" className="semi-bold" style={{marginTop:'-12px', width:'221px', background:'#1C1F24', marginLeft:'15px', color:'#9F6FFB'}} >Acceso a todos los cursos</Typography>
                :  <Typography variant="body1" className="semi-bold" style={{marginTop:'-12px', width:'174px', background:'#1C1F24', marginLeft:'40px'}} > Acceso a cursos de: </Typography>
            }
            <Box className={clsx(['mt-1',isPremium? '':'pl-2'])} >
                {isPremium && <LogoSource color='white' sourceId={SOURCE_ID_COURSERA} disabledAutoRes={true} responsive={false} style = {{width:'74.24px', marginBottom:'2px', marginRight:'10px'}}/>}
                <LogoSource color='white' sourceId={SOURCE_ID_LINKEDIN} disabledAutoRes={true} responsive={false} style = {{width:isPremium ?'64.96px': '74.24px'}}/>
                <LogoSource color='white' sourceId={SOURCE_ID_CREHANA} disabledAutoRes={true} responsive={false} style = {{width:isPremium ?'66.72px':'80.07px', Height: isPremium ? '10px':'12px', marginBottom:'2px',  marginLeft: isPremium ? '10px': '20px'}}/>
            </Box>
            <Box className="mt-1 pr-2">
                <LogoSource color='white' sourceId={SOURCE_ID_UERRE} disabledAutoRes={true} responsive={false} style = {{width:'52px', Height:'10xp', marginRight:'20px'}} />
                <LogoSource color='white' sourceId={SOURCE_ID_UNID} disabledAutoRes={true} responsive={false} style = {{width:'32.24px', Height:'10xp'}}/>
            </Box>
        </Box>
    )
}

export default MembershipCardComboCoursesAllies; 