import React from 'react';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// utils
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';
import { userIsLogged } from '../../../../shared/utils/Sessions'

import * as paths from '../../../../routes/paths'

// styles
import useStyles from '../../../../assets/styles/jss/pages/market/PaymentConfirmation';

// assets
import GirlBook from "../../../../assets/images/UNID_licenciatura.svg";

const PaymentActionCard = ({ personInfo }) => {
    const classes = useStyles();
    const history = useHistory();
    const isLogged = userIsLogged();

    const exploreButtonClick = (e) => {
        e.preventDefault();
        history.push("/");
    };

    const activateAccountClick = (e) => {
        e.preventDefault();

        let activateAccountURL = personInfo?.activateAccountURL;

        if (!personInfo?.is_active) {
            history.push(activateAccountURL);
        } else {
            eventTracker('s_login', {}, ['mixpanel']);
            history.push(paths.LOGIN);
        }
    };

    return (
        <Card className={clsx('bg-fill-stroke-400', classes.actionCard)}>
            <Box display="flex">
                <Box>
                    <img className={classes.actionCardImg} src={GirlBook} alt="Confirmación de pago"></img>
                </Box>
                <Box display="flex" flexDirection="column" py="24px" px="16px" flexGrow="1">
                    {(!isLogged && !personInfo?.is_active) && (
                        <React.Fragment>
                            <Box mb="26px">
                                <Typography variant="body1" color="text-white" className='semi-bold mb-2'>¡No olvides activar tu cuenta!</Typography>
                                <Typography variant="caption" color="text-white">Ingresa aquí para configurar tu contraseña.</Typography>
                            </Box>
                            <Box>
                                <Button fullWidth variant='contained' color="primary" onClick={activateAccountClick}>Activar cuenta</Button>
                            </Box>
                        </React.Fragment>
                    )}
                    {(!isLogged && personInfo?.is_active) && (
                        <React.Fragment>
                            <Box mb="26px">
                                <Typography variant="body1" color="text-white" className='semi-bold mb-2'>¡Empieza a aprender ahora!</Typography>
                                <Typography variant="caption" color="text-white">Presiona el siguiente botón para comenzar</Typography>
                            </Box>
                            <Box>
                                <Button fullWidth variant='contained' component={RouterLink} to={paths.LOGIN} color="primary">Iniciar sesión</Button>
                            </Box>
                        </React.Fragment>
                    )}
                    {isLogged && (
                        <React.Fragment>
                            <Box mb="26px">
                                <Typography variant="body1" color="text-white" className='semi-bold mb-2'>¡Empieza a aprender ahora!</Typography>
                                <Typography variant="caption" color="text-white">Conoce nuestros cursos, Licenciaturas y Maestrías 100% online y estudia a tu ritmo</Typography>
                            </Box>
                            <Box>
                                <Button fullWidth variant='contained' onClick={event => exploreButtonClick(event)} color="primary">Ver catálogo</Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        </Card>
    )
}

export default PaymentActionCard;