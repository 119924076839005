export const personRoleObj =[
    {
      readOnly:true, 
      type:'text',
      defaultValue:'',
      label:'Usuario',
      name:'person_id',
    },
    { 
      type:'select',
      defaultValue:'',
      label:'Seleccione un Role',
      name:'role_id',
      required:true,
      items:[]
    }
  ] 