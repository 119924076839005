import React from 'react';

//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import circle_item from '../../../assets/images/logos/landing-icons/circle_item.svg';
import { getLogoCard }from '../../../shared/utils/imageSource';

const useStyles = makeStyles((theme) => ({
    root:{
        padding: '62px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '32px 16px 40px 16px',
            justifyContent: 'start'
        }
    },
    container:{
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    cardContent:{
        padding: '51px 16px 16px',
        '& h3':{
            maxWidth: 195
        },
        [theme.breakpoints.down('xs')]: {
            padding: '30px 9px 9px',
        }
    },
    typos:{
        background: theme.palette.colors.fillStroke[400],
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: 1000,
        width: "calc(330.66px - 20px)",
        height: "36px",
        marginBottom: 24,
        [theme.breakpoints.down('xs')]: {
            maxWidth: "calc(303.66px - 60px)",
            marginBottom: 16,
            height: '26px'
        },
    },
    learningAccording:{
        maxWidth: "455px",
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: ''
        }
    },
    logo_talisis: {
        width: "148.52px",
        marginBottom: "37px",
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
        },
    },
    circle_item:{
        width: "20px",
        height: "20px",
        marginRight: "12px",
        [theme.breakpoints.down('xs')]: {
            width: "16px",
            height: "16px",
        },
    },
    boxContent:{
        display:"flex",
        justifyContent: 'end',
        alignItems: 'center',
        gap: 24,
        flexFlow: 'row wrap',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 12,
            marginTop: 8
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        }
    },
    cardTitle:{
        color: theme.palette.colors.gray[200],
        marginBottom: 18,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 14,
            fontSize: 14,
            lineHeight: '18px'
        }
    },
    cardText:{
        color: theme.palette.colors.darkBlack[100],
        fontWeight: 700,
        [theme.breakpoints.down('xs')]: {
            fontSize: 10,
            lineHeight: '16px'
        }
    },
    card:{
        height: 320,
        maxWidth: 288,
        [theme.breakpoints.down('xs')]: {
            height: 190,
            width: 155,
            border: 'none !important'
        }
    },
    title:{
        marginBottom: 23,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
            color: theme.palette.colors.violet[300],
            textAlign: 'center',
            maxWidth: 328,
            ...theme.typography.web.h3
        }
    },
    subtitle:{
        color: theme.palette.colors.darkBlack[100],
        marginBottom: 24,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
            fontSize: 14,
            lineHeight: '18px',
            textAlign: 'center'
        }
    }
}));

const LearningAccordding = () => {
    const classes = useStyles();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Box className="bg-dark-black-500">
            <Box display="flex" alignItems="center" maxWidth={1224} margin="auto" className={classes.root}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.container}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="start" className={classes.learningAccording}>
                        <img className={classes.logo_talisis} loading="lazy" src={getLogoCard(7)}></img>
                        <Typography color="primary" variant="h3" className={classes.title}>Estudia una Licenciatura o Maestría Online desde $600 pesos por mes</Typography>
                        <Typography variant={isResponsive ? "body2" : "body1"} className={classes.subtitle}>Accede a contenido actualizado, cursos de primer nivel en plataformas aliadas, y todo lo que necesitas para desarrollar tu perfil profesional.</Typography>
                        <Box className={classes.typos} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" px="10px">
                            <img className={classes.circle_item} loading="lazy" src={circle_item}></img>
                            <Typography variant={!isResponsive && "body2"} className={isResponsive ? "variant-xsmall text-dark-black-100" : "text-dark-black-100"}>Validez oficial ante la SEP</Typography>
                        </Box>
                        <Box className={classes.typos} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" px="10px">
                            <img className={classes.circle_item} loading="lazy"  src={circle_item}></img>
                            <Typography variant={!isResponsive && "body2"} className={isResponsive ? "variant-xsmall text-dark-black-100" : "text-dark-black-100"}>Acceso a plataforma 24/7</Typography>
                        </Box>
                        <Box className={classes.typos} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" px="10px">
                            <img className={classes.circle_item} loading="lazy"  src={circle_item}></img>
                            <Typography variant={!isResponsive && "body2"} className={isResponsive ? "variant-xsmall text-dark-black-100" : "text-dark-black-100"}>Acceso a cursos de nuestros aliados</Typography>
                        </Box>
                        <Box className={classes.typos} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" px="10px">
                            <img className={classes.circle_item} loading="lazy"  src={circle_item}></img>
                            <Typography variant={!isResponsive && "body2"} className={isResponsive ? "variant-xsmall text-dark-black-100" : "text-dark-black-100"}>Titulación incluida</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.boxContent}>
                        <Card variant="outlined" className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                    <Typography variant="h3" className={classes.cardTitle}>Accede a cursos de líderes globales</Typography>
                                    <Typography variant="body1" className={classes.cardText}>Como, LinkedIn, Crehana, Hubspot, AWS , Coursera, entre otros.</Typography>
                                </Box> 
                            </CardContent>
                        </Card>
                        <Card variant="outlined" className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                    <Typography variant="h3" className={classes.cardTitle}>Acceso a oportunidades laborales</Typography>
                                    <Typography variant="body1" className={classes.cardText}>Tendrás acceso a nuestra bolsa de trabajo, <span className='text-blue-300'>CONECTA</span>, con cualquiera de nuestras membresías..</Typography>
                                </Box> 
                            </CardContent>
                        </Card>
                        <Card variant="outlined" className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                    <Typography variant="h3" className={classes.cardTitle}>Eventos exclusivos</Typography>
                                    <Typography variant="body1" className={classes.cardText}>Accede a eventos exclusivos en línea y presenciales, como ferias de empleo, masterclass, etc..</Typography>
                                </Box> 
                            </CardContent>
                        </Card>
                        <Card variant="outlined" className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                    <Typography variant="h3" className={classes.cardTitle}>Asesorías personalizadas</Typography>
                                    <Typography variant="body1" className={classes.cardText}>Con las membresías <span className='text-orange-300'>Professional</span> y <span className='text-orange-300'>Premium</span>, tendrás acceso a asesorías personalizadas con expertos en cada materia.</Typography>
                                </Box> 
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default LearningAccordding;