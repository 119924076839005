import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

// @material-ui
import {
    Box,
    ClickAwayListener,
    Grow,
    Icon,
    IconButton,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography
}
from '@material-ui/core';

// Icons
import {
    Edit as PencilIcon,
    Delete as DeleteIcon,
}
from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    menuIcon: {
        color: theme.palette.colors.fillStroke[100],
    },
    icon: {
        fontSize: '24px',
        color: theme.palette.colors.fillStroke[100],
        // marginRight: '5px',
    }, 
    text: {
        marginLeft: '16px',
    }
}));

const FeedMenu = ({ entry, setDialogProps }) => {
    const classes = useStyles();
    const history = useHistory();
    const [openMenu, setOpenMenu] = React.useState(false);
    const anchorRef = React.useRef(null);
    const prevOpen = React.useRef(openMenu);

    React.useEffect(() => {
        if (prevOpen.current === true && openMenu === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = openMenu;
    }, [openMenu]);

    const handleToggle = (e) => {
        e.stopPropagation();
        setOpenMenu((prevOpen) => !prevOpen);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }
        setOpenMenu(false);
    };

    const handleEditClick = (e) => {
        e.stopPropagation();
        setOpenMenu(false);
        history.push(`feed/${entry.id}/edit`); 
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        setDialogProps({ open: true, title: "Eliminar Noticia", text: "Presione Confirmar para eliminar la noticia." })
        setOpenMenu(false);
    };

    return <Box>
        <IconButton
            ref={anchorRef}
            aria-label="settings"
            aria-controls={openMenu ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.menuIcon}>
            <Icon className={'ri-more-line'} />
        </IconButton>
        <Popper open={Boolean(openMenu)} anchorEl={anchorRef.current} transition disablePortal={false} onClick={(e)=> e.stopPropagation()} placement='bottom-start'>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="menu-list-grow" role="">
                                <MenuItem onClick={handleEditClick}>
                                    <Icon className={clsx(classes.icon, 'ri-pencil-line')} />
                                    <Typography variant="body2" className={clsx('semi-bold', 'text-gray-100', classes.text)} noWrap>Editar publicación</Typography>
                                </MenuItem>
                                <MenuItem onClick={handleDeleteClick}>
                                    <Icon className={clsx(classes.icon, 'ri-delete-bin-6-line')} />
                                    <Typography variant="body2" className={clsx('semi-bold', 'text-gray-100', classes.text)} noWrap>Eliminar publicación</Typography>
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </Box>;
}

export default FeedMenu;