import React, { useState } from 'react'

import { Button, Grid, Typography } from '@material-ui/core'

import AvatarEditor from 'react-avatar-editor'

import BackupIcon from '@material-ui/icons/Backup';
import SaveIcon from '@material-ui/icons/Save';

import { useTheme } from '@material-ui/core/styles';
import EditSlider from './EditSlider/EditSlider';



const EditPhoto = (props) => {

    const theme = useTheme();

    const { getPhotoToUpload, handleClose, saveDisabled } = props;

    const convertBase64ToFile = async(url, filename, mimeType) => {
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }

    let editor = "";

    const [picture, setPicture] = useState({
        cropperOpen: false,
        name: "",
        type: "",
        img: null,
        zoom: 1,
        croppedImg: ""
    });

    const handleSlider = (event, value) => {
        setPicture({
            ...picture,
            zoom: value
        });
    };

    const setEditorRef = (ed) => editor = ed;

    const handleCutSave = async(e) => {
        if (setEditorRef) {
          const canvasScaled = editor.getImageScaledToCanvas();
          const croppedImg = canvasScaled.toDataURL();
    
          setPicture({
            ...picture,
            cropperOpen: false,
            croppedImg: croppedImg
          });

        const newFile = await convertBase64ToFile(croppedImg, picture.name, picture.type);
        await getPhotoToUpload(newFile);
        }
      };

    const handleFileChange = (e) => {
        const currentImg = e.target.files[0];
        let url = URL.createObjectURL(currentImg);

        setPicture({
            ...picture,
            name: currentImg.name,
            type: currentImg.type,
            img: url,
            cropperOpen: true
        });
    };

    return (
        <>
        <Grid 
            container spacing={1} justifyContent='space-between' alignItems='center'
            className='mb-4 pb-2' style={{ borderBottom: `2.5px solid ${theme.palette.colors.grey[700]}` }}
        >
            <Grid item xs={6}>
                <Typography variant="h6" color='primary' align='left'>
                    Cambiar foto de perfil
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container justifyContent='flex-end'>
                    <Button
                        color='primary'
                        variant="contained"
                        size="large"
                        component="label"
                        startIcon={<BackupIcon />}
                    >
                        <Typography variant='body2'>
                            Subir una foto
                        </Typography>
                        <input hidden type="file" accept="image/*" onChange={handleFileChange} />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justifyContent='center'>
            <Grid item xs={8}>
                <Grid container justifyContent='center'>
                <AvatarEditor
                    ref={setEditorRef}
                    image={picture.img}
                    width={250}
                    height={250}
                    border={50}
                    borderRadius={120}
                    color={[0, 0, 0, 0.7]} // RGBA
                    rotate={0}
                    scale={picture.zoom}
                />
                </Grid>
            </Grid>
            <EditSlider
                pictureZoom={picture.zoom}
                handleSlider={handleSlider}
                pictureImg={picture?.img}
                saveDisabled={saveDisabled}
            />
            <Grid item xs={12}>
                <Grid container className='my-1' spacing={3} justifyContent='center'>
                    <Grid item xs={4}>
                        <Grid container justifyContent='center'>
                            <Button 
                                disabled={!picture?.img && saveDisabled} size='large' 
                                startIcon={<SaveIcon />}
                                component="label" onClick={() => handleCutSave()}
                                color="primary" variant="contained"
                                style={{ width: "100% "}}
                            >
                                <Typography variant='body1'>
                                    Guardar
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justifyContent='center'>
                            <Button
                                color="default"
                                size='large' variant="outlined"
                                component="label" onClick={() => handleClose()}
                                style={{ width: "100%", border: `0.5px solid ${theme.palette.colors.grey[1000]}`}}
                            >
                                <Typography variant='body1' style={{ color: theme.palette.colors.grey[1000]}}>
                                    Cancelar
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>
    )
}

export default EditPhoto
