import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { Box, Typography } from '@material-ui/core';

// Styles
import useStyles from '../../../../../assets/styles/jss/pages/account/profile/UserRoleTagStyle';

const UserRoleTag = ({ text }) => {
    const classes = useStyles();

    return (
        <Box className={classes.rootUserRoleTag}>
            <Typography variant='body2' className={`${classes.text}`}>{text}</Typography>
        </Box>
    );
};

UserRoleTag.propTypes = {
    text: PropTypes.string.isRequired,
};

export default UserRoleTag;