import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UsersService } from '@sdk-point/talisis';

import LoaderContent from '../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';
import Table from '../../shared/components/Table/Table';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardContent, MenuItem, MenuList, Tooltip, Typography } from '@material-ui/core';
import { ButtonsDelEdit } from '../../shared/components/Table/components';

import { TreeItem, TreeView } from '@material-ui/lab';
import { Add, Delete, Edit, ExpandMore, ChevronRight } from '@material-ui/icons';
import ConfirmDialog from '../../shared/components/ConfirmDialog/ConfirmDialog';
import Toast from '../../shared/components/Toast/Toast';


const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiList-padding': {
            padding: 0,
        },
        '&::-webkit-scrollbar': {
            borderRadius: '10px',
            width: '10px',
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.colors.grey[500],
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.colors.grey[400],
            borderRadius: '10px',
        },
    },
    root: {
        '& .MuiTreeItem-label': {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontSize: '12px'
        },
        '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        '& .MuiCollapse-root': {
            marginLeft: '0px',
        },
    },
    menu: {
        display: 'flex',
    },
    addButton: {
        float: 'right',
        marginTop: '-41px',
        fontFamily: "'Source Sans Pro', sans-serif",
    }
}));

const Groups = (props) => {
    const { user } = useSelector((state) => state.userReducer);
    const history = useHistory();

    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [confirmDialogProps, setConfirmDialogProps] = React.useState({
        group_id: '',
        title: '',
        content: '',
        open: false
    });
    const [Toastprops, setToastProps] = React.useState({ message: '' });
    const [error, setError] = React.useState(false);
    const fontFamily = { fontFamily: "'Source Sans Pro', sans-serif" };

    const UsersServiceSDK = new UsersService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const classes = useStyles();

    const items = [
        { to: '/home', label: 'Home' },
        { label: 'Listado de Grupos' },
    ]

    const columns = [
        { id: '_name', name: 'name', label: 'Nombre', minWidth: 100, align: 'left' },
        { id: '_description', name: 'description', label: 'Descripción', minWidth: 100, align: 'left' },
        { id: '_members', name: 'members', label: 'Miembros', minWidth: 200, align: 'left' },
        { id: '_owner', name: 'owner', label: 'Propietario', minWidth: 100, align: 'left' },
        { id: '_status', name: 'status', label: 'Estatus', minWidth: 100, align: 'left' },
    ];
    if (user.is_admin) {
        columns.push({ id: '_actions', name: 'actions', label: 'Opciones', minWidth: 100, align: 'left' });
    }

    React.useEffect(() => {
        getGroupsList();
    }, []);

    const getGroupsList = async () => {
        try {
            setLoading(true);
            const groups = await UsersServiceSDK.getGroups();
            if (groups && groups.length > 0) {
                const newGroups = await Promise.all(groups.map(async (group, index) => {
                    const groupMembers = await UsersServiceSDK.getGroupMembers(group.id);
                    group.members =
                        <TreeView key={index} className={classes.root} defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />}>
                            <TreeItem key={index} nodeId={String(index)} label={`${groupMembers.length} persona(s)`}>
                                {groupMembers.map(member => (
                                    <TreeItem key={member.person_id} nodeId={String(member.person_id)} label={member.member_name} />
                                ))}
                            </TreeItem>
                        </TreeView>;

                    if (user.is_admin) {
                        const handleEditClick = (e) => {
                            e.stopPropagation();
                            history.push(`grupos/${group.id}/editar`);
                        };

                        const handleDeleteClick = (e) => {
                            e.stopPropagation();
                            setConfirmDialogProps({ group_id: group.id, open: true, title: 'Eliminar grupo', text: 'Presione Confirmar para eliminar el grupo.' })
                        };

                        group.actions =
                            <ButtonsDelEdit handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick}></ButtonsDelEdit>;
                    }

                    group.status = 'Activo';
                    return group;
                }));
                setRows(newGroups);
            }
            setLoading(false);
        }
        catch (error) {
            throw new Error(error);
        }
    }

    const handleNewGroup = () => {
        props.history.push('/usuarios/grupos/crear')
    }

    const handleDeleteConfirm = (value) => {
        if (value === true) {
            const deleteRecord = async () => {
                setLoading(true);
                const { group_id } = confirmDialogProps;
                await UsersServiceSDK.deleteGroup(group_id)
                    .then(async response => {
                        setError(false);
                        setLoading(false);
                        setToastProps({ ...Toastprops, open: true, message: 'El grupo fue eliminado exitosamente' });
                    })
                    .catch(function (error) {
                        setError(true);
                        setLoading(false);
                        setToastProps({ ...Toastprops, open: true, severity: 'error', message: 'Ocurrió un error al eliminar el grupo. Inténtelo de nuevo.' });
                    });
            };
            deleteRecord();
        };
    };

    const handleDeleteClose = () => {
        setConfirmDialogProps({ ...confirmDialogProps, group_id: '', open: false });
    };

    const handleFinishedToast = () => {
        setToastProps({ ...Toastprops, open: false });
        if (error) {
            setToastProps({ ...Toastprops, open: false });
        }
        else {
            window.location.reload();
        }
    }

    return (
        <React.Fragment>
            <Breadcrumb items={items} separator="/" />
            {user.is_admin && <Button color="primary" startIcon={<Add />} onClick={handleNewGroup} className={classes.addButton}>Alta de nuevo grupo</Button>}
            <LoaderContent loading={loading}>
                {!loading && rows.length === 0 ?
                    <Box width="100%">
                        <Card>
                            <CardContent>
                                <Typography variant="subtitle1" align="center">¡No hay grupos disponibles!</Typography>
                                <Typography variant="subtitle2" align="center">Vuelve pronto para ver más actualizaciones.</Typography>
                            </CardContent>
                        </Card>
                    </Box> :
                    <React.Fragment>
                        {rows.length > 0 && <Table className={classes.table} maxHeight="calc(100vh - 300px)" columns={columns} rows={rows} />}
                        <ConfirmDialog {...confirmDialogProps} onConfirm={handleDeleteConfirm} onClose={handleDeleteClose} />
                        <Toast {...Toastprops} onFinished={handleFinishedToast} />
                    </React.Fragment>}
            </LoaderContent>
        </React.Fragment>
    );
};

export { Groups };