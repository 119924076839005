import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootWhatIsAcademyCard: {
        boxSizing: "border-box",
        backgroundImage: props => `url(${props.background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "103px 16px 64px 16px",
        width: "288px",
        height: "362px",
        [theme.breakpoints.down('xs')]: {
            padding: "80px 12px 61px 12px",
            width: "224px",
            height: "303px",
        }
    },
    containerIcon: {
        display: "flex",
        justifyContent: "center",
    },
    misCursosFillSVGIcon: {
        fontSize: "40px",
        color: theme.palette.white,
        [theme.breakpoints.down('xs')]: {
            fontSize: "32px",
        }
    },
    containerName: {
        marginTop: "24px",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            marginTop: "16px",
        }
    },
    containerBotton: {
        marginTop: "48px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "32px",
        }
    }

}));