import palette from '../palette';

export default {
  tooltip:{
    backgroundColor: '#2C3236',
    padding: 12,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: '#DDDFE3',
  },
  arrow:{
    color: '#2C3236',
    '&.outlined-red':{
      border: '1px solid red'
    }
  },
  popper:{
    '&.outlined-yellow':{
      '& .MuiTooltip-tooltip':{
        // backgroundColor: 'red',
        border: `1px solid ${palette.colors.warning[300]}`
      }
    },
    '&.outlined-blue':{
      '& .MuiTooltip-tooltip':{
        border: `1px solid ${palette.colors.blue[300]}`
      }
    }
  },
};
