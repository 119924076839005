import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootTwoSection: {
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        background: theme.palette.colors.fillStroke[400],
        borderRadius: '8px',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            border: 'none',
        }
    },
    twoSectionMedia: {
        height: '168px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            height: '131px',
        }
    },
    twoSectionContent: {
        width: '100%',
        height: '147px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            height: '141px',
        }
    },
}));