import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const RadioGroup = ({label, required, error, onChange, items, disabled, name, value, labelProps}) => {

    return (
        <Box display="flex" alignItems="center" height="100%">
            <FormControl component="fieldset" required={required} error={error} disabled={disabled}>
                <FormLabel component="legend" {...labelProps}>{label}</FormLabel>
                <MuiRadioGroup aria-label="gender" name={name} value={value} onChange={onChange}>
                    {
                        items.map(item => (
                            <FormControlLabel key={item.id}
                                value={item.id.toString()}
                                control={<Radio color="primary"/>}
                                label={item.label}
                            />
                        ))
                    }
                </MuiRadioGroup>
                {error && <FormHelperText>Campo Requerido</FormHelperText>}
            </FormControl>
        </Box>
    );
};

RadioGroup.defaultProps = {
    required: false,
    error: false,
    style: {},
    disabled: false,
};

RadioGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    /**
     * Array of options
     */
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    /**
     * Optional click handler
     */
    onChange: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default RadioGroup;