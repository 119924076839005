import React, {lazy, useState, Suspense, useEffect} from "react";
import { Link as RouterLink, useParams} from 'react-router-dom';
import clsx from "clsx";
// Material UI
import { Typography, Box, Tabs, Tab, Link, makeStyles} from "@material-ui/core"; 
import { Card, CardContent, CardActions} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CircularProgress from '@material-ui/core/CircularProgress';
// Constants
import { convertOuToSource} from '../../../constants/convertOuToSource';
// SDK-POINT
import { CourseService, STATUS_COURSES } from '@sdk-point/talisis'
//Shared
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { getSessionCookie } from "../../../shared/utils/Sessions";
import { log } from '../../../shared/utils/console.js'
import getConnectionAllies from '../../../shared/components/Allies/AlliesConnect';

const LABEL_IN_PROCESS = 'En progreso';
const LABEL_COMPLETED = 'Completados';
const INDEX_TAB_IN_PROGRESS = 0;
const INDEX_TAB_COMPLETADO = 1;
 const CourseCardMedia = lazy(() => import(/**/'../../../shared/components/CourseCard/CourseCardMedia'));

const useStyles = makeStyles((theme) => ({
  root:{
      maxWidth: "1300px",
      width: "100vw",
      marginLeft: "auto", 
      marginRight: "auto",
  },
  title_name:{
    color: theme.palette.colors.grey[200],
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    whiteSpace: 'initial'
   },
  label_lesson:{
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px"
  },
  card_content:{
      width: "286px",
      minHeight: "300px",
      maxHeight: "300px",
      backgroundColor: "transparent",
      "&:hover": {
          cursor:"pointer",
          "& $continuar_content": {
              opacity: "1",
              border: "1px solid " + theme.palette.primary.main,
              backgroundColor: theme.palette.primary.main,
          },
      },
      
  }
  ,
    continuar_content:{
      opacity: "0",
      width: "108px",
      minWidth: "142px",
      border: "1px solid #FFFFFF",
      paddingLeft: "16px",
      position: "absolute",
      paddingRight: "16px",
      zIndex: "10",
      left: "0",
      right: "0",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      top: "0",
      bottom: "0",
      height: "40px",
      textAlign: "center",
      borderRadius: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  continuar_text:{
      fontFamily: 'Inter',
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
  },
}));

const EduconCourses = () => { 
  const classes = useStyles();
  const [valueActive, setValueActive] = useState(0)
  const [statusCourse, setStatusCourse] = useState(STATUS_COURSES.IN_PROGRESS)
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true)
  const [load, setLoad] = React.useState(false);
  const {company_id, source_id} =  useParams(); 
  const user = getSessionCookie();
  const courseService =  new CourseService(process.env.REACT_APP_ENV ,process.env.REACT_APP_X_API_KEY)

  const pageNumber = 1;
  const pageSize = 30;

  useEffect(()=>{
    getCourses();
  }, [statusCourse])

  const getCourses = async() =>{
    try{
      setLoading(()=>true);
      setCourses(()=>[]);
      let response=[]
      if(company_id) {
        response = await courseService.getCouresEduconByPersonIdAndCompanyId(user.person_id, company_id, statusCourse, pageNumber, pageSize)
      }
       if(source_id) {
        response = await courseService.getCouresEduconByPersonIdAndSourceId(user.person_id, source_id, statusCourse, pageNumber , pageSize);
      } 
      setCourses(()=> response);
       } catch(e) {
       log('Error get Courses', e);
    } finally {
      setLoading(()=>false);
    }
  } 

  const handleChangeTab = (event, newTabIndex) => {
     setValueActive(()=> newTabIndex);
     if(newTabIndex === INDEX_TAB_IN_PROGRESS) {
        setStatusCourse(()=> STATUS_COURSES.IN_PROGRESS)
      } 
     if(newTabIndex === INDEX_TAB_COMPLETADO) {
      setStatusCourse(()=> STATUS_COURSES.COMPLETED)
    } 
  }
    const handleClickContinue = async (e, course) => {
      e.preventDefault();
       setLoad(true);
              const body = {
                  person_id: user?.person_id,
                  email: user?.email,
                  ou: user?.ou,
                  course_url: course.course_url,
                  source_id: course.source_id,
                  course_url_neo: course.course_url_neo,
                  course_id: course.course_id,
                  company_id: course.company_id,
                  course_name: course?.course_name,
              }
          const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + course?.source_id, "_blank");

          var urlRespuesta = await getConnectionAllies(body);
          setTimeout(() => {
              win.focus();
              win.location.replace(urlRespuesta);
          }, 3000);
      setLoad(false);
  }

  return(
    <Box>
         <Box display="flex">
            <i className="ri-arrow-left-s-line text-orange-300"></i> 
            <Link component={RouterLink} to="/cursos" > 
                <Typography variant="v2" className="text-orange-300" style={{marginLeft:'10px'}}>Volver a mi Clases </Typography>
            </Link>   
        </Box>
         <Tabs variant="scrollable" scrollButtons="auto"  value={valueActive} onChange={handleChangeTab} aria-label="scrollable auto tabs example" indicatorColor="primary">
            <Tab label={LABEL_IN_PROCESS}/>
            <Tab label={LABEL_COMPLETED}/>
        </Tabs>
        <Box>
        <LoaderContent loading={loading} minHeight="290px">
          <Box style={{maxWidth: "1300px",
                      width: "100vw",
                      marginLeft: "auto", 
                      marginRight: "auto",}}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{width: "100%", alignItems: "flexStart"}}>
            <Box position="relative" style={{display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            boxSizing: "border-box",
                                            maxWidth: "1248px",
                                            marginRight:"5px"}}>
              {
              courses.map((course, index)=>{
                course.enrollment_status_id = 3;
                  return(
                  <Card key={index+"edcor"} elevation={4} style={{width:"288px", height:"315px", margin:"5px"}} className={classes.card_content}>
                    <Suspense fallback={<></>}>

                    <CardActionArea position="relative" onClick={event => course.enrollment_status_id == 3 && handleClickContinue(event, course)}>
                    {course?.enrollment_status_id == 3 &&<Box className={classes.continuar_content}>
                            {!load ? <Typography className={classes.continuar_text}>{"Continuar"}</Typography> : 
                            <Box style={{ transform: "scale(0.6)", height: "100%", width: "100%" }}><CircularProgress style={{ color: "white" }}/></Box>}
                        </Box>}
                        <CourseCardMedia
                          isCompleted={true}
                          isProgress={true}
                          company_id={course?.company_id}
                          source={{
                            id: convertOuToSource(course.company_id, course.source_id),
                            icon: 'desktop_windows',
                            modality: course?.modality_id != null ? course?.modality_id : 2,
                          }}
                          image={course?.image_url}
                        />

                    </CardActionArea>
                    </Suspense>
                    <LinearProgress variant="determinate" value={course.progress_percentage} style={{Background:'#26C5FB'}} />
                    <CardContent style={{height:"65px", whiteSpace: 'nowrap', overflow: 'hidden'}}>
                      <Typography className={classes.title_name}>{course.name}</Typography>
                    </CardContent>
                    <CardActions style={{padding:"16px"}}>
                     { (statusCourse === STATUS_COURSES.IN_PROGRESS) ?
                         <Typography className={clsx("text-blue-300", classes.label_lesson)} >Lección {course.lesson_percentage} %</Typography>
                         :  <>
                              <i className="ri-checkbox-circle-fill" style={{color:'#01ED8C', position: "absolute"}}></i>
                              <Typography variant='h5' style={{color:'#01ED8C', paddingLeft: "10px"}}>{ (course?.grade) ? `Calificación final: ${course?.grade}`: 'Aprobado'}</Typography>
                            </>
                      }
                    </CardActions>
                  </Card>)
              }) 
              }
            </Box>
          </Box>
        </Box>
        </LoaderContent>
        </Box>
    </Box>
  )
}


export default EduconCourses;