import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @material-ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// assets
import useStyles from '../../../assets/styles/jss/pages/cart/cartStyle';

// routes
import * as paths from '../../../routes/paths'

// components
import CartProductCount from '../components/CartProductCount/CartProductCount';

const CartNavigationBar = ({ showContinueShopping }) => {
    const classes = useStyles();
    const { cartProducts } = useSelector(state => state.cartReducer);

    return <Box className={classes.cartNavigationBar}>
        <Grid container alignItems='center'>
            {showContinueShopping && (
                <Grid item>
                    <Link component={RouterLink} to={paths.ROOT_HOME} className={classes.cartLinkStyle}>
                        <Typography variant='caption' color="secondary" className={clsx(classes.flexContainer)} component="div">
                            <i className="ri-arrow-left-line mr-2 font-size-16" />
                            <span className="font-weight-600">Continuar comprando</span>
                        </Typography>
                    </Link>
                </Grid>
            )}
            <Grid item xs>
                <CartProductCount products={cartProducts} />
            </Grid>
        </Grid>
    </Box>
};

export default CartNavigationBar;