import React from 'react';

// @material-ui/core components
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';

// core components
import Logo from '../../components/Navbar/components/Logo'

// styles
import useStyles from '../../../assets/styles/jss/layout/sidebarStyle'

// @redux
import { connect } from "react-redux"
import * as requestActions from '../../../redux/actions/requestActions'


const INTERVAL_TIME_10_MIN = 600000;

const Sidebar = (props) => {
    const {children, user, getPendingRequests, open, isLogged} = props;
    const classes = useStyles({isLogged});

    React.useEffect(() => {
        if(isLogged){
            getPendingRequests(user.person_id);
    
            const interval = setInterval(() => getPendingRequests(user.person_id), INTERVAL_TIME_10_MIN);
    
            return () => {
              clearInterval(interval);
            }
        }
    }, [])

    return (
        <Drawer
            className={`${classes.root} no-print`}
            anchor="left"
            open={open}
            classes={{paper: classes.drawerPaper, paperAnchorDockedLeft: classes.paperAnchorDockedLeft}}
        >
            <div className={classes.drawerHeader}>
                <Logo />
                <IconButton onClick={props.onClose}>
                    <Icon className="ri-close-line text-white" />
                </IconButton>
            </div>
            {children}
        </Drawer>
    );
};

const mapStateToProps = (state) => ({...state.requestsReducer, ...state.userReducer});

export default connect(mapStateToProps, {...requestActions})(Sidebar);
