import { getSessionCookie } from '../../utils/Sessions.js';
import { getCheckoutCookie} from '../../utils/checkoutSession-legacy.js';

import ReactGA from "react-ga4";

let actions = {
    track: async (eventName, eventData) => {
        const sessionCookie = getSessionCookie();
        const checkoutCookie = getCheckoutCookie();
        if(Object.keys(sessionCookie).length != 0) {
            eventData['person_id'] = sessionCookie?.person_id;
            eventData['first_name'] = sessionCookie?.first_name;
            eventData['last_name'] = sessionCookie?.last_name;
        }
        if(Object.keys(checkoutCookie).length != 0) {
            eventData['person_id'] = checkoutCookie?.personInfo?.person_id;
            eventData['first_name'] = checkoutCookie?.personInfo?.first_name;
            eventData['last_name'] = checkoutCookie?.personInfo?.last_name;
        }
        if(!ReactGA.isInitialized){
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALITICS_TRACKING_ID)
        }

        ReactGA.event(eventName, {
                      category: "Talisis WEB",
                      action: eventName,
                      label: eventData ? JSON.stringify(eventData) : undefined,
          });
    }
}

let GoogleA = actions

export {GoogleA}