export const APPLY_ORDER = "APPLY_ORDER";
export const APPLY_ORDER_PRODUCTS = "APPLY_ORDER_PRODUCTS";
export const APPLY_PERSON_INFO = "APPLY_PERSON_INFO";
export const APPLY_ORDER_PRICE_DETAILS = "APPLY_ORDER_PRICE_DETAILS"; // aplica cambios a todos los datos de precios
export const APPLY_INSTALLMENTS_OPTIONS = "APPLY_INSTALLMENTS_OPTIONS"; // configura los meses sin intereses
export const APPLY_SELECTED_INSTALLMENT_OPTION = "APPLY_SELECTED_INSTALLMENT_OPTION"; // aplica la opción seleccionada de msi
export const APPLY_ORDER_TOTAL = "APPLY_ORDER_TOTAL"; // aplica cambios al total
export const APPLY_ORDER_SUBTOTAL = "APPLY_ORDER_SUBTOTAL"; // aplica cambios al subtotal
export const APPLY_ORDER_BONUSES = "APPLY_ORDER_BONUSES"; // aplica cambios a las bonificaciones
export const APPLY_ORDER_DISCOUNTS = "APPLY_ORDER_DISCOUNTS"; // aplica cambios con descuentos
export const APPLY_ORDER_SAVINGS = "APPLY_ORDER_SAVINGS"; // aplica cambios a los ahorros 
export const APPLY_ORDER_NEXT_PAYMENT_DATE = "APPLY_ORDER_NEXT_PAYMENT_DATE"; // actualia la fecha de próximo pago