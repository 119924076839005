import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    card:{
        width:'288px', 
        height:'315px', 
        borderRadius: '16px',
        gad:'24px',
        background: 'transparent',
    },
    boxMedia:{  
        width: '288px',
        height: '315px',
        gap: '0px',
        opacity: '0px',
        borderRadius: '16px',
        padding: '30px 20px 30px 20px',
        border : `.5px solid ${theme.palette.colors.fillStroke[100]}`,
        backgroundImage: (propsStyles)=> `url(${propsStyles.image_url})`,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        imageRendering: "-webkit-optimize-contrast",
    },
    logoSource:{
        width: '71.74px',
        height: '22px',
        top: '40px',
        left: '16px',
        gap: '0px',
        opacity: '0px',
    }
}))