import React, { Component } from 'react';
import clsx from 'clsx';
import {withRouter} from 'react-router-dom'

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import EventForm from './EventForm';
import CheckTermsConditions from '../../../../shared/components/CheckTermsConditions/CheckTermsConditions'

//style
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../../../shared/components/Loader/Loader';
import Toast from '../../../../shared/components/Toast/Toast';

import isEmail from '../../../../shared/utils/isEmail';
import { log } from '../../../../shared/utils/console.js'

import { EventService } from '@sdk-point/talisis';

//Mixpanel
import { trackEventMixPanel } from '../../../../shared/components/Mixpanel/mixpanel';
import { eventData, eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const EventServiceSDK = new EventService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = (theme) => ({
    root: {
        '& .MuiPaper-root':{
            backgroundColor: theme.palette.colors.fillStroke[500]
        }
    },
    dialogSucces:{
        '& .MuiPaper-root':{
            backgroundColor: theme.palette.colors.fillStroke[400],
            padding: 32
        }        
    },
    dialogTitle: {
        flex: '0 0 auto',
        margin: 0,
        padding: '16px 24px',
    },
});

const FORM_TYPE_COMPONENT_MULTIPLE_SELECT = 3;

function getName(str, index){
    const strNormalize = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const strSpecialCharacters = strNormalize.replace(/[^\w\s]/gi, '');
    const strName = `${strSpecialCharacters.toLowerCase().replace(/ /g, '-')}-${index}`;

    return strName;
}

function resetForm(form){
    return form.map(it => ({...it, value: it.form_type_id === FORM_TYPE_COMPONENT_MULTIPLE_SELECT ? [] : ""}))
}

const initialToastProps = {
    open: false,
    message: '',
};

class EventRegistration extends Component {

    constructor(props){
        super(props);

        this.state = {
            data: [],
            open: false,
            acceptTerms: false,
            loading: false,
            form: [],
            formValue:{

            },
            register: {
                personId: null,
                prospectForm: {}
            },
            isFormValid: false,
            requiredFields: [],
            toastProps: initialToastProps,
            errors:{}
        }
    }

    componentDidMount(){
        this.getFormEvent();
    }

    getFormEvent = async () => {
        try {
            const response = await EventServiceSDK.getFormEvent(this.props.match.params.id);
            const form = response.data.length && response.data[0].items.map((it, index) => ({
                ...it, 
                label: it.title, 
                name: getName(it.title, index), 
                value: it.form_type_id === FORM_TYPE_COMPONENT_MULTIPLE_SELECT ? [] : "",
            })) || [];

            let requiredFields = form.filter(it => it.is_required).map(that => that.name);
            if(!this.props.isLogged){
                requiredFields = requiredFields.concat(['first_name', 'last_name', 'email', 'phoneNumber', 'gender']);
            }
            this.setState(prevState => ({
                ...prevState,
                acceptTerms: this.props.isLogged,
                data: response.data,
                form,
                requiredFields
            }))

        } catch (error) {
            
        }
    }

    createRegister = async (body) => {
        const formValue = {};
        this.setState(prevState => ({
            ...prevState,
            form: resetForm(prevState.form),
            open: false,
            formValue,
            acceptTerms: this.props.isLogged,
            isFormValid: false,
            loading: true,
        }));

        try {
           
            let data_mixpanel={...body.register.prospectForm};
            data_mixpanel["eventId"]=this.props.match.params.id;  
            data_mixpanel["accion"]='Registro';
            if(!this.props.isLogged){            
                data_mixpanel["person_origin"]='Eventos';
                eventTracker('crear_cuenta', eventData, ['mixpanel']);
                trackEventMixPanel('usuario_registro_evento', data_mixpanel);
            }else{
                trackEventMixPanel('usuario_registro_evento', data_mixpanel);
            }
       
            const response = await EventServiceSDK.createRegister(this.props.match.params.id, body);

            if(!response.success){
                this.setState(prevState => ({
                    ...prevState,
                    loading: false,
                    toastProps: { severity: "error", open: true, message: response.message}
                }))
            }else{
                this.setState(prevState => ({
                    ...prevState,
                    loading: false,
                }))

                this.props.onRegisterSubmit();
            }

        } catch (error) {
            log('error', error)
        }
    }

    validate = (formValue) => {
        const {requiredFields, form} = this.state;
        const errors = {};
        const _requiredFields = [...requiredFields];

        const errorMsg = {
            email: {msg:'Correo Invalido', invalid: !isEmail(formValue.email)}, 
        }
        
        for(const field of _requiredFields){
            const input = form.find(it => it.name === field);
            const formType = input ? input.form_type_id : 0;
            if(formType === FORM_TYPE_COMPONENT_MULTIPLE_SELECT){
                if(!formValue[field].length) errors[field] = 'Campo Requerido';
            }else if(errorMsg[field] && errorMsg[field].invalid){
                if(errorMsg[field].invalid) errors[field] = errorMsg[field].msg;
            }else if(!formValue[field]){
                errors[field] = 'Campo Requerido';
            }
        }

        this.setState({...this.state, errors})

        return !(Object.keys(errors).length);
    }

    handleChangeValue = (formValue) => {
        const isFormValid = this.validate(formValue);
        this.setState(prevState => ({
            ...prevState,
            formValue,
            isFormValid
        }));
        
    };

    handleChangeStateBind = (name, value) => e =>{
        this.setState({
            ...this.state,
            [name]: value
        })
    };

    handleClickRegister = () =>{
        if((this.props.isLogged && this.state.form.length) || !this.props.isLogged){
            (this.handleChangeStateBind('open', true))()
        }else{
            this.handleSubmit();
        }
    }

    handleCloseForm = () =>{
        const formValue = {};
        this.setState(prevState => ({
            ...prevState,
            form: resetForm(prevState.form),
            open: false,
            formValue,
            acceptTerms: this.props.isLogged,
            isFormValid: false
        }));
    }

    handleFinishedToast = () => {
        this.setState(prevState => ({
            ...prevState,
            toastProps: initialToastProps
        }));
    };

    handleSubmit = () => {
        const {formValue, form, data} = this.state;
        let items = [...form];
        let register = {
            personId: null,
            prospectForm: {
                first_name: formValue.first_name,
                last_name: formValue.last_name,
                email: formValue.email,
                phoneNumber: formValue.phoneNumber,
                gender: formValue.gender,
            }
        };
     
        if(this.props.isLogged){
            register = {
                personId: this.props.user.person_id,
                prospectForm: {}
            }
        }

        const body = {
            register,
            forms: data.length && [
                {
                    id:data[0].id,
                    items: items.map(f => ({
                        form_type_id: f.form_type_id,
                        title: f.title,
                        order: f.order,
                        options: f.options || null,
                        response: formValue[f.name],
                        is_required: f.is_required,
                    }))
                }
            ] || []
        }

        
        this.createRegister(body)
    }

    render() {
        const { classes, isLogged, fullWidth, isResponsive} = this.props;
        const {open, form, acceptTerms, isFormValid, loading, toastProps, errors} = this.state;
        return (
            <React.Fragment>
                <Loader open={loading} />
                <Button color="primary" variant="contained" onClick={this.handleClickRegister} fullWidth={fullWidth}>Registrate</Button>
                <Dialog onClose={this.handleCloseForm} className={classes.root} open={open} maxWidth="sm" fullWidth>
                    <Box className={classes.dialogTitle}>
                        <Typography variant="h4" className="mb-3">Formulario de registro</Typography>
                        <Typography variant="body2" className="mb-3">Para poder registrar tu asistencia, es necesario que completes el siguiente formulario.</Typography>
                        <Divider className="bg-fill-stroke-200"/>
                    </Box>
                    <DialogContent>
                        <EventForm formData={form} onChangeValue={this.handleChangeValue} isLogged={isLogged} errors={errors}/>
                    </DialogContent> 
                    <DialogActions className="justify-content-initial pb-4 px-4">
                        <Box>
                            {
                                !isLogged &&
                                <Box mb={2}>
                                    <CheckTermsConditions
                                        accept={acceptTerms}
                                        startText="Haciendo click aquí, acepto los"
                                        colorLink="secondary"
                                        onChange={this.handleChangeStateBind('acceptTerms', !acceptTerms)}
                                    />
                                </Box>
                            }
                            <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={!isFormValid || !acceptTerms} className={clsx(isResponsive && "w-100")}>Registrarse</Button>
                        </Box>
                    </DialogActions>
                </Dialog>
                <Toast {...toastProps} onFinished={this.handleFinishedToast} className="position-absolute"/>
            </React.Fragment>
        );
    }
}

export default withRouter((withStyles(useStyles, { withTheme: true })(EventRegistration)));