export const SOURCES = {
    SPIN: 0,
    BANNER: 1,
    ADVENIO: 3,
    NEO: 4,
    COURSERA: 5,
    LINKEDIN_LEARNING: 6, 
    TALISIS: 7,
    UERRE: 8,
    UNID: 9,
    HH: 10,
    IESALUD: 11,
    CREHANA: 13,
    GOOGLE:12,
    HUBSPOT: 14,
    IE_UNIVERSITY: 15,
    AWS: 16,
    SIMPLICITY: 17,
    CONEKTA: 18,
    AWS_SKILL_BUILDER: 19,
    EASYGENERATOR: 20,
    NEO_CH: 21,
}

export const SOURCE_NAME = {
    [SOURCES.COURSERA]: 'coursera',
    [SOURCES.LINKEDIN_LEARNING]: 'linkedin-learning',
    [SOURCES.UERRE]: 'uerre',
    [SOURCES.UNID]: 'unid',
    [SOURCES.HH]: 'harmon-hall',
    [SOURCES.CREHANA]: 'crehana',
    [SOURCES.GOOGLE]: 'google',
    [SOURCES.HUBSPOT]: 'hubspot',
    [SOURCES.IE_UNIVERSITY]: 'ie-university',
    [SOURCES.AWS]: 'aws',
    [SOURCES.AWS_SKILL_BUILDER]: 'aws-skill-builder',
    [SOURCES.NEO_CH]: 'neo-ch',
}

export const SOURCE_ID_COMPANY_NAME = {
    "UERRE": SOURCES.UERRE,
    "UNID": SOURCES.UNID,
    "HH": SOURCES.HH
}

export const COMPANIES = {
    UERRE: 'UERRE',
    UNID: 'UNID',
    HH: 'HH',
};