// @material-ui/core
import MuiTimeline from '@material-ui/lab/Timeline';

import styled from 'styled-components';

export default styled(MuiTimeline)`
    ${({ theme: {palette} }) => `

        .time-line{
            &-content__paper{
                border-radius: 8px;
                background: #2C3236;
                border: 0.5px solid rgba(255, 255, 255, 0.15);
                &.MuiPaper-outlined{
                    border: 0.5px solid #A86FFB;
                    background: #1C1F24;
                }
            }
        }

        .disabled{
            .time-line{

                &-dot{
                    color: ${palette.colors.fillStroke[200]};
                    border-color: ${palette.colors.fillStroke[200]};
                }

                &-content__paper{
                    background: rgba(255, 255, 255, 0.09);
                    border: 0.5px solid #2C3236;
                    background: #1C1F24;

                }
                &-connector{
                    background-color: ${palette.colors.fillStroke[200]} !important;
                }
            }
        
            .text{
                color: ${palette.grey.default};
            }
        }

        .MuiTimelineItem-missingOppositeContent:before{
            flex: 0
        }

    `}
`