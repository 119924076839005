import React from 'react'
import { Button, MenuItem, TextField, Box } from '@material-ui/core'

// styles
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden";
import Select from '../../../design-system/components/Form/Select/Select'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import SchoolSVG from '../../../assets/images/logos/onboarding/school.svg'
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    button:{
        padding: '16px 59px',
        marginBottom: 25
    },
    modality:{
        ...theme.typography.v5,
        maxWidth: 440
    },
    icon:{
        width: '114.49px',
        height: '64px',
        marginBottom: 37
    }
}));

export const OnCardCourseModality = ({ onClickClose, onSetOnCard, form, handleChange, catModalityList, eventData, eventTracker}) => {
    const classes = useStyles();

    useEffect(() => {
        eventTracker('s_ob_modalidad',eventData, ['mixpanel']);
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt="80px">
            <Box display="flex" justifyContent="center" className="mb-1">
                <img src={SchoolSVG} className={classes.icon}/>
            </Box>
            <Typography variant="h3" color="primary" className="mb-4">
            ¡Ya casi terminamos!
            </Typography>
            <Typography variant="body2" align="center" className="mb-3" color="textSecondary">
            Para mejorar tu experiencia de aprendizaje
            </Typography>
            <Typography  align="center" className="font-variant-v4">
            ¿Cuál es la modalidad de estudio que se adapta mejor a ti?
            </Typography>
            <Box mt="61px" mb="55px" width="100%" maxWidth={360}>
                <Select
                    name="modality_id"
                    items={catModalityList.map(it => ({...it, label: it.name}))}
                    required
                    label="Escoge una modalidad"
                    value={form.modality_id || ""}
                    onChange={handleChange}
                />
            </Box>

            <Hidden smDown implementation="css">
                <Button className={classes.button}
                        variant='contained' color="primary"
                        disabled={!form.modality_id}
                        //onClick={() => onSetOnCard(4)}
                        onClick={onClickClose}
                        >
                    Finalizar
                </Button>
            </Hidden>


            <Typography align="center" className={classes.modality} color="textSecondary">
            Al elegir una modalidad de estudio no representa tu decisión final, esto solo es para asegurarnos recomendarte el contenido ideal para ti.
            </Typography>

            <Hidden mdUp implementation="css">
                <Box width="100%" display="flex" justifyContent="center" mt="40px">
                        <Button onClick={() => onSetOnCard(2)} startIcon={<ChevronLeftIcon />} className={classes.button}>
                            Regresar
                        </Button>
                    <Button className={classes.button}
                        variant='contained' color="primary"
                        disabled={!form.modality_id}
                        //onClick={() => onSetOnCard(4)}
                        onClick={onClickClose}
                        >
                    Finalizar
                    </Button>
                </Box>
            </Hidden>
        </Box>
    )
}

export default OnCardCourseModality;
