import React from 'react';
import { Typography, Box, Divider, Icon } from '@material-ui/core';

import ClipboardButton from '../../../design-system/components/ClipboardButton/ClipboardButton';

const ReferralPlanPromotor = ({clipboardUrl, qrUrl, isResponsive, classes }) => {

    return (<>
        <Typography variant='subtitle2' align='center' className={classes.referMssg}>Comparte este <Typography variant="h4" className='text-violet-300' component="span">QR</Typography> o liga para que el prospecto cree su cuenta en <Typography variant="h5" component="span">Talisis.com</Typography>, y su compra pueda ser ligada a tu perfil.</Typography>
        <Box className={classes.container}>
            <Box>
                <Box py={5} className={classes.qrContent}>
                    <Box>
                        <Box className={classes.qr}>
                            <img src={qrUrl} alt="QR Code" />
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' alignItems='center' gridGap={16} className={classes.qrClipboard}>
                        <input readOnly defaultValue={clipboardUrl} className={classes.inputClipboard}/>
                        <ClipboardButton textToCopy={clipboardUrl} variant='contained' size='small' fullWidth={isResponsive}>
                        Copiar enlace <Icon className='ri-links-line font-size-24 ml-1' />
                        </ClipboardButton>
                    </Box>
                </Box>
                <Box>
                    <Typography variant='body1' className='semi-bold'>Solo tienes que:</Typography>
                    <Box maxWidth={515} width={1} display='flex' margin='auto'>
                        <ol className={classes.list}>
                            <li>Compartir la liga ó el QR (cualquiera de los 2 es válido) al prospecto para que cree su cuenta en Talisis.com. Después de que ingrese sus datos, el usuario deberá de aceptar el email de confirmación que le llegó a su correo. </li>
                            <li>Después de que el prospecto tenga su cuenta de Talisis.com, indícale que ingrese a su perfil de Talisis.com y dé clic en <span className='semi-bold'>Mejora tu Plan</span> para que pueda elegir la membresía y plazo de interés.</li>
                            <li>Después de que el usuario haya elegido la membresía y plazo de interés, le debe de dar clic en <span className='semi-bold'>Suscribirme Ahora</span> para que lo dirijan al checkout e ingrese su método de pago.</li>
                        </ol> 
                    </Box>
                    <Divider className="bg-fill-stroke-300"/>
                    <Box py={4}>
                        <Box display='flex' justifyContent='space-between' flexDirection='column' maxWidth={483} margin='auto' className={classes.notePro}>
                            <Typography variant='body1' color='primary' className='semi-bold'>
                                Recuerda que para ayudar a concretar la venta, puedes hacer uso de estos cupones del 20% válidos para plazos semestrales o anuales en cualquier membresía. 
                            </Typography>
                            <Box>
                                <Typography variant='body1'>
                                Código para prospectos de origen UNID: UNID20
                                </Typography>
                                <Typography variant='body1'>
                                Código para prospectos de origen UERRE: UERRE20
                                </Typography>
                                <Typography variant='body1'>
                                Código para prospectos de origen Harmon Hall: HH20 
                                </Typography>
                                <Typography variant='body1'>
                                Código para prospectos de origen IESALUD: IESALUD20
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
    );
};

export default ReferralPlanPromotor;