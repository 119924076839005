import React from 'react'
import BirreteLine from '../../../../assets/images/icons/mis-cursos-line.svg'

export const DetailedPlanIconList = ({ icon, color, classes, width = 20, height = 20 }) => {

    const iconName = icon.toLocaleUpperCase().trim();

    if (iconName === "MODALIDAD")
        return (
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8327 15V16.6667H14.166V18.3333H5.83268V16.6667H9.16602V15H2.49935C2.27834 15 2.06637 14.9122 1.91009 14.7559C1.75381 14.5996 1.66602 14.3877 1.66602 14.1667V3.33333C1.66602 3.11232 1.75381 2.90036 1.91009 2.74408C2.06637 2.5878 2.27834 2.5 2.49935 2.5H17.4993C17.7204 2.5 17.9323 2.5878 18.0886 2.74408C18.2449 2.90036 18.3327 3.11232 18.3327 3.33333V14.1667C18.3327 14.3877 18.2449 14.5996 18.0886 14.7559C17.9323 14.9122 17.7204 15 17.4993 15H10.8327ZM3.33268 4.16667V13.3333H16.666V4.16667H3.33268ZM8.33268 6.25L12.4993 8.75L8.33268 11.25V6.25Z" fill={color} />
            </svg>
        )
    if (iconName === "ACADEMIC")
        return (
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0007 2.5L0.833984 7.5L10.0007 12.5L17.5007 8.40833V14.1667H19.1673V7.5M4.16732 10.9833V14.3167L10.0007 17.5L15.834 14.3167V10.9833L10.0007 14.1667L4.16732 10.9833Z" fill={color} />
            </svg>
        )
    if (iconName === "MATERIAS")
        return (
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 14.9993H5C4.77899 14.9993 4.56702 15.0871 4.41074 15.2434C4.25446 15.3997 4.16667 15.6117 4.16667 15.8327C4.16667 16.0537 4.25446 16.2657 4.41074 16.4219C4.56702 16.5782 4.77899 16.666 5 16.666H17.5V18.3327H5C4.33696 18.3327 3.70107 18.0693 3.23223 17.6005C2.76339 17.1316 2.5 16.4957 2.5 15.8327V3.33268C2.5 2.89065 2.67559 2.46673 2.98816 2.15417C3.30072 1.84161 3.72464 1.66602 4.16667 1.66602H17.5V14.9993ZM4.16667 13.3743C4.30167 13.3468 4.44083 13.3327 4.58333 13.3327H15.8333V3.33268H4.16667V13.3743ZM13.3333 7.49935H6.66667V5.83268H13.3333V7.49935Z" fill={color} />
            </svg>
        )
    if (iconName === "DISPONIBILIDAD")
        return (
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_842_1559)">
                    <path d="M9.99935 18.3327C5.39685 18.3327 1.66602 14.6018 1.66602 9.99935C1.66602 5.39685 5.39685 1.66602 9.99935 1.66602C14.6018 1.66602 18.3327 5.39685 18.3327 9.99935C18.3327 14.6018 14.6018 18.3327 9.99935 18.3327ZM9.99935 16.666C11.7675 16.666 13.4632 15.9636 14.7134 14.7134C15.9636 13.4632 16.666 11.7675 16.666 9.99935C16.666 8.23124 15.9636 6.53555 14.7134 5.2853C13.4632 4.03506 11.7675 3.33268 9.99935 3.33268C8.23124 3.33268 6.53555 4.03506 5.2853 5.2853C4.03506 6.53555 3.33268 8.23124 3.33268 9.99935C3.33268 11.7675 4.03506 13.4632 5.2853 14.7134C6.53555 15.9636 8.23124 16.666 9.99935 16.666V16.666ZM10.8327 9.99935H14.166V11.666H9.16602V5.83268H10.8327V9.99935Z" fill={color} />
                </g>
                <defs>
                    <clipPath id="clip0_842_1559">
                        <rect width="20" height="20" fill={color} />
                    </clipPath>
                </defs>
            </svg>
        )
    if (iconName === "HORAS")
        return (
            <i className={`ri-film-line ${classes}`} style={{ color: color }} />

        )
    if (iconName === "IDIOMA")
        return (
            <i className={`ri-translate-2 ${classes}`} style={{ color: color }} />
        )
    if (iconName === "FLEXIBILIDAD DE HORARIOS")
        return (
            <i className={`ri-time-line ${classes}`} style={{ color: color }} />
        )
    if (iconName === "MAESTROS CERTIFICADOS")
        return (
            <i className={`ri-user-star-line ${classes}`} style={{ color: color }} />
        )
    if (iconName === "HABILIDADES ALINEADAS AL MERCADO LABORAL")
        return (
            <i className={`ri-shield-star-line ${classes}`} style={{ color: color }} />
        )
    if (iconName === "TITULACIÓN INMEDIATA CON VALIDEZ OFICIAL" || iconName === "CERTIFICADO OFICIAL")
        return (
            <svg width={width} height={height} fill="none" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" className={classes}>
                <path d="M21.9951 8.6834C21.9951 8.67731 21.9951 8.67121 21.9951 8.66559C21.9928 8.64402 21.9897 8.62293 21.9859 8.60231L21.9833 8.58918C21.9798 8.57262 21.9759 8.55621 21.9715 8.53996L21.9649 8.51746C21.9597 8.49965 21.9536 8.48231 21.947 8.46496C21.9448 8.45887 21.9422 8.45277 21.9396 8.44715C21.9334 8.43262 21.9273 8.41809 21.9203 8.40402L21.9107 8.38481C21.9016 8.36693 21.8917 8.34956 21.8809 8.33277L21.877 8.32668C21.8669 8.31074 21.856 8.29574 21.8451 8.2798L21.835 8.26668C21.8093 8.234 21.7811 8.20374 21.7506 8.17621L21.7383 8.1659C21.7256 8.15465 21.7121 8.14387 21.6985 8.13355L21.6889 8.12652C21.6753 8.11668 21.6617 8.10731 21.6473 8.09887L11.8473 2.09887C11.7415 2.03408 11.6218 2 11.4999 2C11.3781 2 11.2583 2.03408 11.1526 2.09887L1.35256 8.09887C1.24545 8.16448 1.15643 8.25925 1.09452 8.37359C1.03261 8.48793 1 8.61777 1 8.74996C1 8.88215 1.03261 9.01199 1.09452 9.12633C1.15643 9.24067 1.24545 9.33544 1.35256 9.40106L4.49818 11.329V17.0009C4.49808 17.1348 4.53141 17.2662 4.59473 17.3816C4.65804 17.497 4.74903 17.5921 4.85825 17.6572L11.1482 21.4001C11.2528 21.465 11.3712 21.4997 11.4921 21.5009H11.5061C11.6269 21.4997 11.7453 21.465 11.8499 21.4001L18.1381 17.6572C18.2473 17.5921 18.3383 17.497 18.4016 17.3816C18.465 17.2662 18.4983 17.1348 18.4982 17.0009V11.329L20.5982 10.0432V17.0009C20.5982 17.1998 20.6719 17.3906 20.8032 17.5312C20.9345 17.6719 21.1125 17.7509 21.2982 17.7509C21.4838 17.7509 21.6619 17.6719 21.7932 17.5312C21.9244 17.3906 21.9982 17.1998 21.9982 17.0009V8.7509C21.9982 8.7284 21.9982 8.70402 21.9951 8.6834ZM5.89818 16.5598V12.1859L10.7982 15.1859V19.4764L5.89818 16.5598ZM17.0982 16.5598L12.1982 19.4764V15.1864L17.0982 12.1864V16.5598ZM11.4982 13.8879L3.10912 8.7509L11.4982 3.6148L19.8872 8.7509L11.4982 13.8879Z" fill={color} stroke={color} stroke-width="0.5"/>
            </svg>
        )
    return (
        <i className={`ri-checkbox-blank-circle-line ${classes}`} style={{ color: color }} />
    )
};