import React from 'react';
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';

// styles
import useStyles from '../../../assets/styles/jss/pages/exploreHome/ourProgramsStyle';

import { v4 as uuid } from 'uuid';
import DsIcon from '../../../design-system/components/DsIcon/DsIcon';

import * as paths from '../../../routes/paths'


const programs = [
    {
        title: 'Bachillerato',
        label: '1 Programa',
        border: '#FF7E00',
        goTo: `programa/706`
    },
    {
        title: 'Licenciatura',
        label: '7 Programas',
        border: '#F6BE00',
        goTo: `${paths.BUSQUEDA}?licenciatura=true`,
    },
    {
        title: 'Maestría',
        label: '8 Programas',
        border: '#655DC6',
        goTo: `${paths.BUSQUEDA}?maestria=true`
    },
]

const OurPrograms = () => {
    const history = useHistory();
    const classes = useStyles();

    const goTo = (event, path, title) => {
        const eventData = {};
        eventData['source'] = 'landing';
        let eventName = '';
        if (path === paths.MEMBRESIAS) {
            eventName = 'ver_membresías';
        }
        if(title){
            const ofertaEducativa = title.toLowerCase().replace('í','i');
            eventData['ofertaEducativa'] = ofertaEducativa;
            eventData['texto'] = 'si';
            eventName = 'busqueda';
        }
        eventTracker(eventName, eventData, ['mixpanel','google']);
        history.push(path);
    };

    return (
        <ResponsiveContainer className='bg-dark-black-500'>
            <Box className={classes.root}>
                <Typography variant='h1'>Explora nuestros programas</Typography>
                <Box className={classes.cards}>
                    {
                        programs.map(pObj =>                         
                            <Box className={classes.card} key={uuid()} onClick={(event) => goTo(event, pObj.goTo, pObj.title)}>
                                <Box className={classes.border} style={{background: pObj.border}}/>
                                <Box className={classes.cardContent}>
                                    <Typography variant='h3'>{pObj.title}</Typography>
                                    <Chip className='chip1-fs-300' label={pObj.label} />
                                </Box>
                            </Box>
                        )
                    }
                </Box>
                <Box>
                    <Button color='primary' endIcon={<DsIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" icon='t-arrow-right-line' />} onClick={(event) => goTo(event, paths.MEMBRESIAS)}>Ver membresías</Button>
                </Box>
            </Box>
        </ResponsiveContainer>
    );
};

export default OurPrograms;