import React, { useState, useEffect } from "react";
//@Material-ui
import { IconButton, makeStyles, Box } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { CardCarousel } from "./CardCarousel";
import MembershipHeader from "../../MembershipsContainerCard/MembershipCard/Components/MembershipHeader/MembershipHeader";

const useStyles = makeStyles((theme) => ({
  btn: {
    zIndex: 1001,
    marginTop: "25%",
    padding:'5px',
    width:'35.5px'
  },
  carrousel_wrapper: {
    display: "flex",
    width: '328px',
    height :'194px',
    borderRadius: '8px',
    padding:'24px, 8px, 24px, 8px',
    border : `0.5px solid ${theme.palette.colors.violet[300]}`,
   },
  carrousel: {
    overflow: "hidden",
    width: "70%",
    margin: "auto"
  },
  icon: {
    color: theme.palette.colors.darkBlack[200],
  }
}));

const MembershipsCarousel = ({membershipShow, feeSlugSelected, feeSlugBase, setMembershipSelected, handleClick}) => {
  const [activeMembership, setActiveMembership] = useState(0);
  const [previous , setPrevious ] = useState(membershipShow.length - 1);
  const [next, setNext] = useState(activeMembership + 1);

  useEffect(() => {
    if (activeMembership === 0) {
        setPrevious (membershipShow.length - 1);
        setNext(activeMembership + 1);
    } else if (activeMembership === membershipShow.length - 1) {
       setPrevious (activeMembership - 1);
      setNext(0);
    } else {
      setPrevious (activeMembership - 1);
      setNext(activeMembership + 1);
    }
  }, [activeMembership]);

  const goNext = () => {
     setMembershipSelected(membershipShow[activeMembership + 1]?.name?.toLowerCase())
     if (activeMembership === membershipShow.length - 1) {
      setActiveMembership(0);
    } else {
      setActiveMembership(activeMembership + 1);
    }
  };

   const goBack = () => {
     setMembershipSelected(membershipShow[activeMembership - 1]?.name?.toLowerCase())
     if (activeMembership === 0) {
      setActiveMembership(membershipShow.length - 1);
    } else {
      setActiveMembership(activeMembership - 1);
    }
  };

  const classes = useStyles();
  return (
    <Box className={classes.carrousel_wrapper}>
      <Box className={classes.btn}>
        {  activeMembership !==0 &&
          <IconButton onClick={goBack} size="small">
            <ArrowBackIosRoundedIcon className={classes.icon}/>
          </IconButton>
        }
      </Box>
      <Box className={classes.carrousel}>
         {membershipShow.map((membership, index) => (
           <CardCarousel
            key={index}
            data={
              <MembershipHeader membership={membership} feeSlugSelected={feeSlugSelected} feeSlugBase={feeSlugBase} withBorder={false} handleClick={handleClick}/>
            }
            active={activeMembership === index}
            previous ={previous  === index}
            next={next === index}
          />
        ))}
      </Box>
      <Box className={classes.btn}>
        { membershipShow.length !== (activeMembership+1) &&
          <IconButton onClick={goNext} size="small">
            <ArrowBackIosRoundedIcon className={classes.icon} style={{transform: "rotate(180deg)" }} />
          </IconButton>
        }
      </Box>
    </Box>
  );
};

export default MembershipsCarousel;