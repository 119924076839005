import { makeStyles } from '@material-ui/core/styles';
 
export default makeStyles((theme) => ({
    titleBox:{
        [theme.breakpoints.down('sm')]:{
            textAlign: 'center',
            padding:'20px 40px 5px 40px'
        }
    },
    subTitleGrid:{
        paddingTop:'20px',
        [theme.breakpoints.down('sm')]:{
            textAlign: 'center',
            padding:'10px 15px 5px 15px'
       }
    },
    membershipsLandingSubTitle: {
        color: theme.palette.colors.blue[300]
    },
}))