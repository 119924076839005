import React, { useState } from 'react';
import { useSelector } from 'react-redux';
//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// style
import useStyles from '../../../assets/styles/jss/pages/consultancies/applicant/DescriptionConsultancyStyle';
import { FormControl, Select, TextField, MenuItem, Input, IconButton, Icon, Hidden } from '@material-ui/core';
// icons
import palette from '../../../design-system/theme/palette';
//SDK
import { ConsultanciesService,AdminNotifService } from '@sdk-point/talisis'; 
import clsx from 'clsx';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import ConfirmDialog from '../../../shared/components/ConfirmDialog/ConfirmDialog';
import {MEMBERSHIPS} from '../../../shared/constants/memberships'
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { eventTracker, eventData } from '../../../shared/components/EventTracker/EventTracker';

const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const AdminNotifServiceSDK = new AdminNotifService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const DescriptionConsultancy = (props) => {
    const classes = useStyles();

    const [RequestAdviser, setRequestAdviser] = useState(false);
    const [RequestSend, setRequestSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialogProps, setDialogProps] = React.useState({ data_set: 1, row_id: 1, open: false, type: 'info', title: '', text:''});
    const [rule24, setRule24] = useState(false); 
    const [tempDescription, setTempDescription] = useState(props.Description);
    const [timerId, setTimerId] = useState(null);

    const history = useHistory();
    const { user } = useSelector((state) => state.userReducer);
    
    const handleChangeMateria = (event) => {
        props.setMateria(event.target.value);
    };

    const handleFormChange = (event) => {
        const { value } = event.target;
        if (value.length < 650) {
          setTempDescription(value);
          clearTimeout(timerId);
          const newTimerId = setTimeout(() => {
            props.setDescription(tempDescription);
          }, 200); // Actualizar el estado cada 500ms
          setTimerId(newTimerId);
        }
      };

    const handleRequestSend = async () => {
        validateRule24()
    }

    const handleClickRequestAdviser = async () => {
        if(props.Materia !== '' && !RequestSend ){
            setLoading(true)
            let horarioUTC = convertirHorariosUTC(props.ScheduleSelected)
            let [scheduleA] = (props.ScheduleSelected).split(' - ');
            let fecha_asesoria = new Date(props.DateSelected + ' ' + convertTime12to24(scheduleA))
            let fecha_utc = moment.utc(fecha_asesoria)
            let body={
            person_id: user.person_id,
            email_solicitante: user.email,
            person_id_asesor: props.personIdAsesor,
            email_asesor: props.emailAsesor,
            nombre_asesor: props.nombreAsesor,
            area_conocimiento: props.Materia,
            modalidad: 'Online',
            ubicacion:'Online',
            ou: props.ouAsesor,
            descripcion: props.Description,
            fecha_asesoria: fecha_utc,
            tiempo_asesoria: horarioUTC,
            }
            if(props?.ModifyingOn){
                body.id_asesoria = props.idConsultancy;
                body.motivo = 3;
                body.idTeamsAsesoria = props.idTeamsAsesoria;
                await ConsultanciesServiceSDK.postModifyConsultancy(body)
    
                const body2 = {
                    isCritical: 1,
                    title: 'Asesoría modificada',
                    message: 'Ha sido modificada la asesoría por el solicitante: ' + user.userName + ' con el correo: ' + user.email,
                    notifType: 'Procedures',
                    companies: null,
                    campus: null,
                    groups: null,
                    persons: [user.person_id, props.personIdAsesor],
                    actionRequired: 1,
                    url: '/asesorias',
                };
        
                const ok =  AdminNotifServiceSDK.createNotification(body2);
            }else{
                await ConsultanciesServiceSDK.postCreateConsultancy(body)
                const body2 = {
                    isCritical: 1,
                    title: 'Asesoría solicitada',
                    message: 'Ha sido solicitada una asesoría por: ' + user.userName + ' con el correo: ' + user.email,
                    notifType: 'Procedures',
                    companies: null,
                    campus: null,
                    groups: null,
                    persons: [user.person_id, props.personIdAsesor],
                    actionRequired: 1,
                    url: '/asesorias',
                };
                eventData.IdMaestro = props.personIdAsesor
                eventData.horaAsesoria = body.tiempo_asesoria
                eventData.diaAsesoria = getFormatDayShort(body.fecha_asesoria.format("d"));
                eventData.academias = body.area_conocimiento
                eventTracker('solicitar_asesoria', eventData, ['mixpanel'])
                const ok =  AdminNotifServiceSDK.createNotification(body2);
            }
            props.findConsultancies();
            props.setMateria('')
            props.setDescription('')
            setLoading(false)
            setRequestAdviser(true);
            /*});
        }).catch(e => { 
            instance.acquireTokenRedirect({scopes: ["user.read"]});
        });*/
        }
    };
    
    const getFormatDayShort = (dayId) =>{
        let DaysShortsMixpanel = {
          0: 'D',
          1: 'L',
          2: 'M',
          3: 'Mi',
          4: 'J',
          5: 'V',
          6: 'S'
        };

        return DaysShortsMixpanel[dayId]
    }

    const validateRule24 = ()=>{
        let horarioUTC = convertirHorariosUTC(props.ScheduleSelected)
        let dateNow = new Date();
        let [scheduleA] = (horarioUTC).split(' - ');
        let fechaAsesoria = (props.DateSelected + ' 00:00:00');
        let fechaComparar = new Date(fechaAsesoria.slice(0,10) + 'T' + scheduleA.slice(0,8) + ':00');
        const differenceInMilliseconds = fechaComparar - dateNow;
        const differenceInHours = differenceInMilliseconds / 1000 / 60 / 60;
        if(differenceInHours < 24){
            setRule24(true)
            setDialogProps({ data_set: 1, row_id: 1, open: true, type: 'info',title: 'Confirmación 24 Horas', text: 'Debes tener en cuenta que la asesoría está en un plazo menor a 24 horas, si cancelas la asesoría no se reintegrará el crédito utilizado.' });    
        }else{ 
            handleClickRequestAdviser()
        }
    }

    const convertTime12to24 = time12h => {
        const [time, modifier] = time12h.split(" ");
        const [hoursStr, minutesStr] = time.split(":");
        
        let hours = parseInt(hoursStr);
        let minutes = parseInt(minutesStr);

        if ((modifier == "pm" || modifier == "PM") && hours != 12) {
          hours += 12;
        } else if ((modifier == "am" || modifier == "AM") && hours == 12) {
          hours = 0;
        }
        
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      };
    
    const convertirHorariosUTC = horarios => {
        let [horarioA, horarioB] = horarios.split("-");
        horarioA = convertTime12to24(horarioA.trim())
        horarioB = convertTime12to24(horarioB.trim())
        horarioA = horarioA.trim()
        horarioB = horarioB.trim()
        let UTCHorarioA = new Date('2022-12-01T'+(horarioA.slice(0,5)).trim()+':00')
        let UTCHorarioB = new Date('2022-12-01T'+(horarioB.slice(0,5)).trim()+':00')
        return (UTCHorarioA.getUTCHours()+ ":" + (UTCHorarioA.getUTCMinutes()<10?'0':'') + UTCHorarioA.getUTCMinutes() + " - " + UTCHorarioB.getUTCHours()+ ":" + (UTCHorarioB.getUTCMinutes()<10?'0':'') + UTCHorarioB.getUTCMinutes())
    };


    const handleFinalizarSolicitud = () => {
        props.findConsultancies()
        props?.ModifyingOn ? props.setModifyingOn(false) : props.handleClickRegresar();
    }
    
    const handleModifyDates = () => {
        eventTracker('s_calendario_asesoria', eventData, ['mixpanel']);
        props.setModifyingOn(false)
        props.setModifyingOnHorarios(true)
    }

    const handleClose = () => {
        setDialogProps({ ...dialogProps, data_set: '', id: '', open: false });
        props.handleClickRegresar();
    };

    const handleSelectSchedule = () =>{
        if(!props.ModifyingOn){
            props.handleDescriptionOff()
        }else{
            props.setModifyingOn(false)
            props.setModifyConsultancy(true)
        }
    }
    
    const handleIrAsesorias = () =>{
        props.findConsultancies()
        props?.ModifyingOn ? props.setModifyingOn(false) : props.handleClickRegresar();
        history.push('/asesorias?tab=1')
    }

    return (
        <Box>
            {
            !RequestAdviser ? 
                <LoaderContent loading={loading}>
                <Box className={`${ !props?.ModifyingOn ? classes.boxRoot : classes.boxRootModify}`}>
                    
                    <Hidden smDown>
                        <Box className={classes.closeWindow}>                    
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => {!props?.ModifyingOn ? props.handleClickRegresar() : props.setModifyingOn(false)}}
                            >
                                <Icon className="ri-close-fill" style={{color: palette.colors.fillStroke[100]}}/>
                            </IconButton>
                        </Box>
                    </Hidden>
                    {
                        !props?.ModifyingOn ? 
                        <Box>
                            <Box className={classes.boxTittle}>
                                <Typography variant='h4'>Detalles de la sesión</Typography>
                                <Typography variant='caption'>Captura la siguiente información para agendar un sesión.</Typography>
                            </Box>
                            
                            <Box className={classes.boxDataTimes}>
                                <Box className={classes.boxDataTimesSecond}>
                                    <Icon className={"ri-calendar-line font-size-16 text-electric-blue-200"} />
                                    <Typography variant='body1' className='semi-bold'>{(props?.dateConvert)}</Typography>
                                </Box>
                                <Box className={classes.boxDataTimesSecond}>
                                    <Icon className={"ri-time-line font-size-16 text-electric-blue-200"}/>
                                    <Typography variant='body1' className='semi-bold'>{(props?.ScheduleSelected)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        :
                        <Box className={classes.boxTittle}>
                            <Typography variant='h4'>Modificar detalles de la sesión</Typography>
                            <Typography variant='body2'>Captura la siguiente información para agendar un sesión.</Typography>
                        </Box>
                    }

                <Box className={`${ !props?.ModifyingOn ? classes.boxDataSesionFirst : classes.boxDataSesion}`}>
                    <Typography variant='body2'>Área de conocimiento</Typography>
                    <FormControl id='1'>
                        <Select
                        required
                        value={props.Materia}
                        onChange={handleChangeMateria}
                        className={classes.select}
                        input={<Input classes={{ input: classes.inputSelect }} />}
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                            },
                        }}
                        displayEmpty
                        renderValue={(value)=>{
                            return value == '' || value == undefined ?
                            <Typography variant='caption' className={classes.inputPadding}>Selecciona el área de conocimiento deseada</Typography>
                            :
                            value
                        }}
                        >
                            <MenuItem value=''><Typography variant='caption' style={{color: palette.colors.darkBlack[200]}}>Selecciona el área de conocimiento deseada</Typography></MenuItem>
                            {props.materiasAsesor.map((value,i) => (
                                <MenuItem key={i} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className={classes.boxDataDescription}>
                    <Hidden smDown>
                        <Typography style={{marginBottom: '8px'}} variant='body2'>Descripción de dudas a resolver</Typography>
                    </Hidden>
                    <Hidden smUp>
                        <Typography style={{marginBottom: '8px'}} className='variant-xsmall'>Descripción de dudas a resolver</Typography>
                    </Hidden>
                    <TextField
                    name='name'
                    value={tempDescription}
                    multiline
                    fullWidth
                    minRows={10}
                    maxRows={10}
                    placeholder='Introduzca una descripción sobre la asesoria.'
                    InputProps={{ 
                        classes: {root: classes.input} ,
                        disableUnderline: true,
                        className: classes.textDescription, 
                    }} 
                    onChange={handleFormChange}
                    />
                </Box>
                
                {
                    props.ModifyingOn ?
                    <Box>
                        <Box className={classes.boxDataTimes}>
                                <Box className={classes.boxDataTimesSecond}>
                                    <Icon className={"ri-calendar-line font-size-16 text-electric-blue-200"} />
                                    <Typography variant='body1' className='semi-bold'>{(props?.dateConvert)}</Typography>
                                </Box>
                                <Box className={classes.boxDataTimesSecond}>
                                    <Icon className={"ri-time-line font-size-16 text-electric-blue-200"}/>
                                    <Typography variant='body1' className='semi-bold'>{(props?.ScheduleSelected)}</Typography>
                                </Box>
                            </Box>
                        <Box className={classes.boxTittleModify}>
                            <Button 
                                color="primary"
                                label="Modificar fecha y hora"
                                className={classes.buttonModifyHours}
                                onClick={() => {handleModifyDates()}}
                                size="medium"
                                >
                                    <Icon className={clsx(classes.iconContent, "ri-calendar-line font-size-16")}></Icon>
                                    Modificar fecha y hora
                            </Button>
                        </Box>
                    </Box>
                    :
                    null
                }
                

                <Box className={classes.boxSig}>
                    <Button 
                    variant='contained'
                    color="primary"
                    label="Solicitar asesoría"
                    className={classes.buttonSig}
                    onClick={handleRequestSend}
                    >
                        {
                            props.ModifyingOn ?
                            <Typography variant='body2' className='semi-bold'>Actualizar asesoría</Typography>
                            :
                            <Typography variant='body2' className='semi-bold'>Solicitar asesoría</Typography>
                        }
                    </Button>
                    <Button 
                    color="primary"
                    label="Atrás"
                    className={classes.buttonSig}
                    onClick={handleSelectSchedule}
                    >
                        <Typography variant='body2' className='semi-bold'>Atrás</Typography>
                    </Button>
                </Box>
            </Box>
            </LoaderContent>
            :
            <Box className={classes.boxFinalizar}>
                <Box className={classes.boxControlFinalizar}>
                        {
                        !props?.ModifyingOn ? 
                        <Box className={classes.boxTextFinalizar}>
                            <Typography style={{marginBottom: '8px'}} variant='h4' className="subtitle">Solicitud de asesoría enviada</Typography>
                            <Typography variant='caption'>Tu asesoría está pendiente de ser aprobada por el asesor seleccionado, en un lapso menor de 24 horas recibirás respuesta por medio de una notificación.</Typography>
                            <Box className={classes.boxCreditosActual}> 
                                <Typography variant="body2">
                                Tus créditos actualmente: 
                                </Typography> 
                                <Box className={classes.creditosActual}>
                                    {
                                        (user.membership_id === MEMBERSHIPS.PREMIUM) ?
                                        <AllInclusiveIcon style={{width:'20px', height:'20px'}} />
                                        :
                                        <Typography variant={'body2'} className={'semi-bold'}>{props.creditosAsesorias}</Typography>
                                    }
                                </Box>
                            </Box>
                        </Box>
                        :
                        <Box className={classes.boxTextFinalizar}>
                            <Typography style={{marginBottom: '8px'}} variant='h4' className="subtitle">Solicitud de asesoría actualizada enviada</Typography>
                            <Typography variant='caption'>Tu modificación a la asesoría está pendiente de ser aprobada por el asesor seleccionado, en un lapso menor de 24 horas recibirás respuesta por medio de una notificación.</Typography>   
                        </Box>
                        }
                    <Box className={classes.boxButton}>
                        <Button 
                        variant='contained'
                        color="primary"
                        label="Primario"
                        className={classes.buttonSig}
                        onClick={() => {props.ModifyingOn ? handleIrAsesorias() : handleFinalizarSolicitud() }}
                        >
                        {
                            props.ModifyingOn ?
                            <Typography variant='body2' className='semi-bold'>Finalizar</Typography>
                            :
                            <Typography variant='body2' className='semi-bold'>Ir a mis asesorías</Typography>
                        }
                        </Button>
                    </Box>
                </Box>
            </Box>
            }
        <ConfirmDialog {...dialogProps} onConfirm={handleClickRequestAdviser} onClose={handleClose} />
        </Box>
    );
};

export default DescriptionConsultancy;