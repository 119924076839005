import React from 'react';
import { useLocation } from "react-router-dom";
// redux
import { connect } from 'react-redux';

// @material-ui/core components
import { Box } from '@material-ui/core';
import TalisisLogo_new from '../../../assets/images/logos/logo-talisis-rojo.png';

// services
import { MarketPlaceService } from '@sdk-point/talisis';

//shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import { getCheckoutCookie } from '../../../shared/utils/checkoutSession-legacy.js';
import { PRODUCT_TYPE, PAYMENT_METHOD, ORDER_STATUS } from '../../../shared/constants/orders';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import { log } from '../../../shared/utils/console.js'
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';

// components
import PaymentConfirmationHeader from './PaymentConfirmationHeader/PaymentConfirmationHeader';
import PaymentConfirmationBody from './PaymentConfirmationBody/PaymentConfirmationBody';
import dateFormat from '../../../design-system/utils/dateFormat';

const PaymentConfirmation = (props) => {
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [folio, setFolio] = React.useState(null);
    const [order, setOrder] = React.useState(null);
    const [product, setProduct] = React.useState(null);
    const [membership, setMembership] = React.useState(null);
    const { user } = props;
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
    const checkoutCookie = getCheckoutCookie();

    React.useEffect(() => {
        window.scrollTo(0, 0);
        const search = props.location.search;
        const params = new URLSearchParams(search);
        if (params.has('orderId')) {
            const id = params.get('orderId');
            if (!isNaN(id)) {
                reciveOrderDetails(id);
            }
        } else {
            /* invalid request */
        }
    }, [location.search]);

    const valueValidator = (value) => {
        if (!value) {
            let Fixed = 'Sin información';
            return Fixed;
        }
        return value;
    }

    const downloadReferences = () => {
        var printView = window.open('', 'PRINT', 'height=400,width=600');
        printView.document.write('<html><head><title>Detalle de la compra</title>');
        printView.document.write(`</head><body style="font-family: 'Source Sans Pro', sans-serif">`);
        printView.document.write(`<div style="width: 100%; text-align:center;"><img src="${TalisisLogo_new}" style="width: 200px;"></div>`);
        printView.document.write('<h3>Datos de compra</h3>');
        printView.document.write(`<p><table style="border: none; width: 100%;">`);

        // datos personales
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Folio: <b>${valueValidator(folio)}</b></td></tr>`);
        //printView.document.write(`<tr><td style="padding-bottom: 16px;">Fecha: <b>${valueValidator(dateFormat(order?.created_at, "DD-MM-YYYY hh:mm:ss A"))}</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Nombre: <b>${valueValidator(order?.person?.first_name)} ${valueValidator(order?.person?.last_name)}</b></td></tr>`);

        // productos
        if (membership) {
            printView.document.write(`<tr><td style="padding-bottom: 16px;">Membresía: <b>${valueValidator(membership?.name)} (${valueValidator(membership?.company_name)})</b></td></tr>`);
            printView.document.write(`<tr><td style="padding-bottom: 16px;">Periodo de pago: <b>${valueValidator(membership?.membership_plan_name)}</b></td></tr>`);
        }

        if (product) {
            printView.document.write(`<tr><td style="padding-bottom: 16px;">${product?.product_type_name}: <b>${valueValidator(product?.name)} (${valueValidator(product?.company_name)})</b></td></tr>`);
            if (product?.product_type_id === PRODUCT_TYPE.MEMBERSHIP) {
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Periodo de pago: <b>${valueValidator(product?.membership_plan_name)}</b></td></tr>`);
            }
        }

        printView.document.write(`<tr><td style="padding-bottom: 16px;">Subtotal: <b>$${order?.subtotal ? order?.subtotal.toFixed(2) : 0.00} MXN</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Comisión: <b>$${order?.commission ? order?.commission.toFixed(2) : 0.00} MXN</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">IVA de comisión: <b>$${order?.tax_amount ? order?.tax_amount.toFixed(2) : 0.00} MXN</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Total: <b>$${order?.total ? order?.total.toFixed(2) : 0.00} MXN</b></td></tr>`);

        // referencias de pago
        if (order.total !== 0) {
            if (order?.payment?.payment_method === PAYMENT_METHOD.CARD) {
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Método de pago: <b>Tarjeta</b></td></tr>`);
            }
            else if (order?.payment?.payment_method === PAYMENT_METHOD.SPEI) {
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Método de pago: <b>SPEI</b></td></tr>`);
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Cuenta CLABE: <b>${valueValidator(order?.payment?.payment_reference?.conekta?.spei?.clabe)}</b></td></tr>`);
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Banco: <b>${valueValidator(order?.payment?.payment_reference?.conekta?.spei?.bank)}</b></td></tr>`);
            }
            else if (order?.payment?.payment_method === PAYMENT_METHOD.CASH) {
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Método de pago: <b>Efectivo (OXXO Pay)</b></td></tr>`);
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Referencia de pago: <b>${valueValidator(order?.payment?.payment_reference?.conekta?.cash?.reference)}</b></td></tr>`);
            }
        }

        printView.document.write('</body></html>');
        printView.document.close(); // necessary for IE >= 10
        printView.focus(); // necessary for IE >= 10*/

        setTimeout(() => {
            printView.print();
            printView.close();
        }, 1000);

        return true;
    };

    const getOrderPayment = (order) => {
        const userPaymentMethod = sessionStorage.getItem('userLastPaymentMethod');
        const references = JSON.parse(order?.references);
        let payment_method = "";

        if (order?.payment_source === PAYMENT_METHOD.CARD || references?.conekta?.charge?.payment_method?.object === PAYMENT_METHOD.CARD || (userPaymentMethod && (userPaymentMethod === 'card' || userPaymentMethod.includes('savedMethod')))) {
            payment_method = PAYMENT_METHOD.CARD;
        } else if (order?.payment_source === PAYMENT_METHOD.SPEI || references?.conekta?.charge?.payment_method?.object === PAYMENT_METHOD.SPEI || (userPaymentMethod && userPaymentMethod === 'spei')) {
            payment_method = PAYMENT_METHOD.SPEI;
        } else if (order?.payment_source === PAYMENT_METHOD.CASH || references?.conekta?.charge?.payment_method?.object === PAYMENT_METHOD.CASH || (userPaymentMethod && userPaymentMethod === 'cash')) {
            payment_method = PAYMENT_METHOD.CASH;
        } else if (order?.payment_source === PAYMENT_METHOD.FREE) {
            payment_method = PAYMENT_METHOD.FREE;
        }

        return {
            payment_reference: references,
            payment_method: payment_method
        }
    };

    const mapDataToState = (data, courseInfo) => {
        const course = data.find(item => item.course_id > 0);
        const program = data.find(item => item.program_id > 0);
        const membership = data.find(item => item.membership_id > 0);

        if (course) {
            setProduct({
                product_type_id: PRODUCT_TYPE.COURSE,
                product_type_name: "Curso",
                company_id: course?.company_id,
                company_name: course?.company_name,
                third_party_id: course?.third_party_id,
                name: course?.name,
                course_id: course?.course_id,
                source_id: courseInfo?.source_id,
                course_url: courseInfo?.course_url,
                course_url_neo: courseInfo?.user_enrolled_data?.course_url
            });
        }

        if (program) {
            setProduct({
                product_type_id: PRODUCT_TYPE.PROGRAM,
                product_type_name: "Programa",
                company_id: program?.company_id,
                company_name: program?.company_name,
                name: program?.name,
                program_id: program?.program_id
            });
        }

        if (membership) {
            const _membership = ({
                product_type_id: PRODUCT_TYPE.MEMBERSHIP,
                product_type_name: "Membresía",
                company_id: membership?.company_id,
                company_name: membership?.company_name,
                name: membership?.name,
                membership_id: membership?.membership_id,
                membership_name: membership?.membership_name,
                membership_plan_name: membership?.membership_plan,
                membership_plan_months: membership?.membership_plan_months,
                next_payment_date: getFormatdateNoH(membership?.next_payment_date)
            });

            if (!!course || !!program) {
                setMembership({ ..._membership });
            } else {
                setProduct({ ..._membership });
            }
        }

        const payment = getOrderPayment(data[0]);
        log("payment", payment);

        setOrder({
            subtotal: data[0].subtotal,
            commission: data[0].commission,
            tax_amount: data[0].tax_amount,
            discount: data[0].discount,
            coupon: data[0].coupon_name,
            total: data[0].total,
            created_at: data[0].created_at,
            person: {
                first_name: data[0].first_name,
                last_name: data[0].last_name,
                phone_number: data[0].phone_number,
                email: data[0].email
            },
            payment: payment,
            estatus_id: data[0].estatus_id,
        });
    };

    const reciveOrderDetails = async (idOrder) => {
        setLoading(true);
        setFolio(idOrder.padStart(8, "0"));

        try {
            const personId = checkoutCookie?.personInfo?.person_id ? checkoutCookie?.personInfo?.person_id : user.person_id;
            const data = await marketService.getOrderDetails(idOrder, personId);
            
            if (data.length > 0) {
                let courseInfo = null;

                if (data[0].course_id > 0) {
                    const params = {
                        'personId': personId
                    };
                    courseInfo = await marketService.getCourse(data[0].course_id, params);
                }

                mapDataToState(data, courseInfo);

                const eventData = {};
                if (data[0].estatus_id === ORDER_STATUS.PENDING) {
                    eventTracker('s_pago_pendiente', eventData, ['mixpanel', 'hubspot','google']);
                } else if (data[0].estatus_id === ORDER_STATUS.SUCCESS) {
                    eventTracker('s_pago_exitoso', eventData, ['mixpanel','google']);
                }
            }
            
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    const getFormatdateNoH = (date) => {
        var opciones = { year: 'numeric', month: 'long', day: 'numeric' };
        var fecha = new Date(date)
            .toLocaleDateString('es', opciones)
            .replace('.', '')
            .replace(/-([a-z])/, function (x) { return '-' + x[1].toUpperCase() });
        return fecha;
    };

    return (
        <React.Fragment>
            <Loader loading={loading} transparent={true} style={{ minHeight: loading ? "calc(90vh - 32px)" : "inherit", borderRadius: "8px", paddingTop: "40px", marginTop: "-65px" }}>
                {order ?
                    <Box mt="40px">
                        <PaymentConfirmationHeader
                            order={order}
                            product={product}
                            membership={membership}
                        />
                        <PaymentConfirmationBody
                            order={order}
                            product={product}
                            membership={membership}
                            downloadReferences={downloadReferences}
                        />
                    </Box> :
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {!loading && <NothingFound caption={"No encontramos información"} subcaption={""} />}
                    </Box>
                }
            </Loader>
        </React.Fragment>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(PaymentConfirmation);