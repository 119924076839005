import * as pointColors from '../../../assets/styles/variables/colors.scss'


export default {
    iconFileColor:{
        color: '#A0A9BA'
    },
    title:{
        color: pointColors.gray100
    }
}