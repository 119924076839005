import React from 'react';
import PropTypes from 'prop-types'

// material
import { Typography, Box } from '@material-ui/core';

// styles
import styled from 'styled-components';
import useStyles from '../../../assets/styles/jss/components/inputFileStyle'
import clsx from 'clsx';

import BackupIcon from '@material-ui/icons/Backup';

const ErrorMsg = styled(Typography)`
    color: #f55c54 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0.33px !important;
    line-height: 18px !important;
    margin: 3px 14px 0 !important;
`

const InputFile = (props) => {
    const { name, placeholder, onChange, btnText, value, style } = props;
    const { required, error, accept } = props;
    const [fileName, setFileName] = React.useState('');
    const classes = useStyles();

    React.useEffect(() => {
        if (value) {
            setFileName(value.name);
        }
    }, [value]);

    const handleChange = (event) => {
        const file = event.currentTarget.files[0];
        if (onChange) {
            onChange({ target: { name: name, value: file } });
        }
    }

    return (
        <Box style={style}>
            <div className={clsx(classes.inputContainer, { 'error': error })}>
                <div className={classes.button}>
                    <Typography color="inherit" variant="body2" style={{ marginRight: 5 }} >{btnText}</Typography>
                    <BackupIcon />
                    <input
                        type="file"
                        onChange={handleChange}
                        name={name}
                        accept={accept}
                    />
                </div>
                <div className={classes.inputFile}>
                    <input
                        placeholder={`${placeholder} ${required ? '*' : ''}`}
                        type="text"
                        defaultValue={fileName} />
                </div>
            </div>
            {
                error &&
                <Box>
                    <ErrorMsg >Campo Requerido</ErrorMsg>
                </Box>
            }
        </Box>
    );
};

InputFile.defaultProps = {
    required: false,
    error: false,
    placeholder: 'Seleccionar archivo',
    btnText: 'archivo',
    accept: '',
    style: {}
};

InputFile.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    btnText: PropTypes.string,
    value: PropTypes.string,
    accept: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    onChange: PropTypes.func
};

export default InputFile;