import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui
import { Box, Button, Card, CardContent, Chip, Divider, Grid, Icon, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import clsx from 'clsx';

import { getLogoCard } from '../../../../constants/logosImages';

//desing-system
import InputSearch from '../../../../design-system/components/Form/InputSearch/InputSearch';

const keywordsSearch = ['Membresía', 'Mis clases (NEO)', 'Aclaraciones de pago', 'Titulación', 'Documentos de inscripción'];

const StartHelp = (props) => {
  const { isLogged, handleViewAgent, handleViewTickets, LoaderContent, loading, user, categories, handleNextInfoStep } = props
  const [userName, setUserName] = useState(undefined);
  const [searchCategories, setSearchCategories] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const numberWhatsapp = '528118212007'

  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      padding: '0 0 24px',
      backgroundColor: theme.palette.colors.fillStroke[500],
      border: `solid 1px ${theme.palette.colors.fillStroke[400]}`,
      overflow: 'auto',
      height: 558,
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            background: "#77777747",
            "&:hover":{
                background: "#b2adad"
            }
      },
      "&::-webkit-scrollbar-track": {
          borderRadius: "8px"
      }
    },
    header: {
      height: 200,
      width: '100%',
      background: `linear-gradient(113.16deg, ${theme.palette.colors.electricBlue[300]} 4.18%, ${theme.palette.colors.electricBlue[500]} 82.04%)`,
      paddingTop: 32,
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 39,
    },
    headerTitle: {
      float: 'left'
    },
    headerLogo: {
      maxWidth: 64,
      float: 'right'
    },
    content: {
      marginTop: -72,
      padding: '0 24px 0 24px'
    },
    supportContentCard: {
      backgroundColor: theme.palette.colors.fillStroke[400],
      border: `solid 1px ${theme.palette.colors.fillStroke[200]}`,
    },
    cardMargin:{
      marginBottom: 16
    },
    cardPadding:{
      paddingBottom: 16
    },
    supportContentCardTitle: {
      fontWeight: 500,
    },
    customerSevrviceIcon: {
      fontSize: 32,
      color: theme.palette.white,
      display: 'flex',
      '&::before': {
        marginTop: 8
      }
    },
    startConversationIconText: {
      display: 'flex',
      '& i': {
        float: 'left',
        marginRight: 8
      },
      '& .supportContentCardText': {
        float: 'right'
      }
    },
    supportDivider: {
      backgroundColor: theme.palette.colors.fillStroke[300]
    },
    goToTickets: {
      textDecoration: 'none !important',
      textTransform: "none",
      '& span': {
        color: theme.palette.colors.orange[300],
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '16px',
        letterSpacing: 0,
        '& .MuiButton-endIcon': {
          fontWeight: 200
        }
      }
    },
    linkList: {},
    linkListItem: {
      paddingTop: 0,
      paddingBottom: 0
    },
    boxWhatsapp:{
      display: 'flex',
      flexDirection: 'column',
      gridGap: 8,
      marginBottom: 24
    },
    butttonWhats:{
      backgroundColor: '#25D366',
      '& .MuiButton-startIcon':{
        '&.MuiButton-iconSizeMedium > *:first-child':{
            fontSize: '24px !important'
        },
      },
      "&:hover":{
        background: "#108B50"
      }
    },
    buttonAgente:{
      textTransform: "none", 
      width: '-webkit-fill-available',
      '& .MuiButton-startIcon':{
        '&.MuiButton-iconSizeMedium > *:first-child':{
            fontSize: '24px !important'
        },
      },
    },
    topics: {
      weight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.colors.gray[100]
    },
    resultsText:{
      padding: '10px 0px 0px 0px',
      weight: '600',
      fontSize: '14px',
      lineHeight: '16px',
      color: theme.palette.colors.white
    },
    keywordsSearch:{
      padding: '10px'
    }
  });

  useEffect(() => {
    handleSearchIntoCategories();
    if (isLogged) {
      let usrName = user.userName;
      setUserName(usrName.split(' ')[0])
    }
  }, [])

  useEffect(() => {
    handleSearchIntoCategories();
    
  }, [categories])

  const classes = useStyles();
  const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} target="_blank" />;
  }

  const handleGoToWhatsapp = () => {
    let textoCodificado = encodeURIComponent('¡Hola!, Me pudieran apoyar con una duda sobre Talisis.com.')
    window.open(`https://wa.me/${numberWhatsapp}?text=${textoCodificado}`)
  }

  const handleSearchIntoCategories = () => {
    const options = categories.reduce((acc, curr) => {
        acc.push(...curr.custom_field_options);
        return acc;
    }, []);
    setSearchCategories(options);
  };

  const handleKeyDown = (value) => {
    console.log('Buscar:', value);
  };

  const filteredData = searchCategories.filter(obj => obj.name.toLowerCase().includes(valueSearch.toLowerCase()));

  const handleChange = (value) => {
      setValueSearch(value);
  };

  const handleClickDelete = () => {
      setValueSearch('');
  };

  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const handleChipClick = (keyword) => {
    setValueSearch(keyword)
  };

  return (
    <>
      <LoaderContent loading={loading}>
        <CardContent className={classes.root}>
          <Box className={classes.header}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.headerTitle} variant='h3'>Hola{isLogged ? `, ${userName}` : ''}</Typography>
                <img className={classes.headerLogo} src={getLogoCard(7)} alt="" />
              </Grid>
            </Grid>
            <Box mt={1}>
              <Typography variant='body1'>¿Cómo te podemos ayudar?</Typography>
            </Box>
          </Box>
          <Box className={classes.content}>
            <Card className={clsx(classes.supportContentCard,classes.cardMargin)}>
              <CardContent className={classes.cardPadding}>
                {
                !isLogged ?
                  <Box className={classes.boxWhatsapp}>
                    <Typography variant='body1' className='semi-bold'>¿Tienes dudas sobre nuestros programas y membresías?</Typography>
                    <Button variant='contained' className={classes.butttonWhats} onClick={handleGoToWhatsapp} startIcon={<Icon className={'ri-whatsapp-line'} />}>Escríbenos por WhatsApp</Button>
                    <Box display={'flex'} gridGap={8}>
                      <Icon className='ri-information-line font-size-16' />
                      <Typography className='variant-xsmall'>
                        También puedes marcar al 8007709090 - Opción 2
                      </Typography>
                    </Box>
                  </Box> 
                  :
                  <>
                    <InputSearch
                        value={valueSearch}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        onClickDelete={handleClickDelete}
                        placeholder="Buscar..."
                    />
                    {
                      !valueSearch ?
                        <Box className={classes.keywordsSearch}>
                          {keywordsSearch.map((keyword) => (
                            <Chip
                              key={keyword}
                              label={keyword}
                              onClick={() => handleChipClick(keyword)}
                              color={selectedKeywords.includes(keyword) ? 'primary' : 'default'}
                              style={{ marginRight: 8, marginBottom: 8, fontSize: 14}}
                            />
                          ))}
                        </Box>
                      :
                      <>
                        <Typography variant='subtitle2' className={classes.resultsText}>Resultados: </Typography>
                        <Box>
                            {filteredData.slice(0, 3).map((option, index) => (
                              <Box>
                                <Button key={index} onClick={() => handleNextInfoStep(option)} className={classes.topics} 
                                  endIcon={<i className={'ri-arrow-right-line'} />}>{option.name}</Button>
                              </Box>
                            ))}
                        </Box>
                      </>
                    }
                    
                  </>
                  
                  
                }
                <Box>
                  <Typography variant='body1' className={clsx(classes.supportContentCardTitle,'semi-bold')} gutterBottom>¿Necesitas soporte técnico?</Typography>
                  {
                    isLogged &&
                    <Box className={classes.startConversationIconText} mb={2}>
                      <Typography variant='body2'>
                      Ponte en contacto con nuestro equipo de soporte a través de un ticket.
                      </Typography>
                    </Box>
                  }
                  
                  <Box>
                    <Button variant='contained' className={classes.buttonAgente} onClick={handleViewAgent} color="primary" startIcon={<Icon className={'ri-customer-service-2-fill'} />} >Contactar a soporte Talisis</Button>
                  </Box>
                </Box>
                {
                  isLogged &&
                  <>
                    <Divider variant="middle" className={classes.supportDivider} />
                    <Box mt={2}>
                      <Button onClick={handleViewTickets} className={classes.goToTickets} endIcon={<i className={'ri-arrow-right-line'} />}>Ver tickets </Button>
                    </Box>
                  </>
                }
              </CardContent>
            </Card>
            <Card className={classes.supportContentCard}>
              <CardContent style={{ paddingBottom: 0 }}>
                <Typography className={classes.supportContentCardTitle}>Otros recursos</Typography>
                <Box className={classes.supportContentCardText} mb={2}>
                  <List className={classes.linkList}>
                    <ListItemLink className={classes.linkListItem} href="https://www.youtube.com/playlist?list=PL2tB5ZEW5SxvTdBHLGtH-qcpdpLg2oR-X">
                      <ListItemText>Tutoriales</ListItemText>
                    </ListItemLink>
                    <ListItemLink className={classes.linkListItem} href="/terminos_condiciones">
                      <ListItemText>Términos y condiciones</ListItemText>
                    </ListItemLink>
                    <ListItemLink className={classes.linkListItem} href="/aviso_privacidad">
                      <ListItemText>Aviso de privacidad</ListItemText>
                    </ListItemLink>
                  </List>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </CardContent>
      </LoaderContent>
    </>
  )
};

StartHelp.defaultProps = {
  isHome: false,
  isCheckout: false,
};

StartHelp.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};

export default StartHelp;
