import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
// @material/core
import {Grid} from '@material-ui/core';
// @material
import Pagination from '@material-ui/lab/Pagination';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

// share components
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import Table from '../../../shared/components/Table/Table';
import InputSearch from '../../../shared/components/Form/InputSearch';
import ChipFilter from '../../../shared/components/Chip/ChipFilter';
import MoreOptions from '../../../shared/components/MoreOptions/MoreOptions';
import { log } from '../../../shared/utils/console.js'
// services
import {PermissionService} from '@sdk-point/talisis'

const columns = [
  { name: 'id', label: 'Id', minWidth: 100, format (item) { return '#'+ item.id} },
  { name: 'name', label:'Permiso', minWidth:100},
  { name: 'description', label: 'Descripción', minWidth: 100 }, 
  { name: 'actions', label: 'Acciones', minWidth: 100 }
]

function Actions({item, onClickEdit}){
  const options = [
      {label: 'Editar', fun: onClickEdit(item.id), icon: <EditOutlinedIcon/>},
    ];
  
  return (
      <div>
        <MoreOptions options={options} ExAction={true}/>
      </div>
  );
}

const PermissionTable = ({canReadWrite}) => {
  const [state, setState] = useState({data:[], totalPages:0}) 
  const [loading, setLoading] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10)
  const [inputSearch, setInputSearch] = useState('');
  const [valueFilter, setValueFilter] =useState('id');
  const [filters, setFilters] = useState([]);
  const history = useHistory();

    useEffect(()=>{
      handlePermissions();
    },[page, filters])
    
    const handleClickEdit = id => (e) => {
      history.push(`/permisos/${id}/editar`); 
    };

    const handlePermissions = async () => {
      setLoading(true) 
      try {
        const ps = new PermissionService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
        const response= await ps.getAll(page, filters, pageSize);
        response.data.map(it => {
            it.actions = <Actions item={it}
                            onClickEdit={handleClickEdit} 
                          />;
          return (it); 
          })
        setState({data:response.data, totalPages:response.totalPages});
      } 
      catch(e){
        log('getPermission Error', e);
      }
      finally {
        setLoading(false);
      }
    }
    
    const handleChangePage = async (event, page) => {
      event.preventDefault();
      setPage(page);
    }

    const onChangeSearch = (e) => {
      e.preventDefault();
      setInputSearch(e.target.value);
    }

    const searchRecurse = () => {
      if(inputSearch, valueFilter){
        handleFilters();
      }
    }

    const onKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleFilters();
      }  
    }

    const handleFilters = () => {
      setPage(1);
      let filter = {}; 
      filter[valueFilter.trim()] = inputSearch.trim();
      filter['label'] =`${valueFilter}:${inputSearch}`;
      setFilters([...filters,filter])
      setValueFilter('id')
      setInputSearch('')
    }
    
    const handleDeleteChip = (chipToDelete) => () => {
      setFilters((chips) => filters.filter((chip) => chip.id !== chipToDelete.id));
    };

    const COLUMNS_TABLE= (canReadWrite) ? columns:columns.filter(col=> col.name!=='actions');
    
    const filterSearch = [{value:'id', descripcion:'Id'}, {value:'name', descripcion:'Nombre del Permiso'}]
    
    const  handleChangeFilter = (event) => {
      setValueFilter(event.target.value);
    };
   
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <InputSearch 
          recurse={valueFilter} 
          valueSearch ={inputSearch} 
          inputSearch='searchPermission' 
          onChangeSearch={onChangeSearch} 
          searchRecurse={searchRecurse} 
          onKeyDown={onKeyDown}
          filter = {filterSearch}
          handleChangeFilter= {handleChangeFilter}
          valueFilter={valueFilter}/> 
          <Grid item xs={3}> 
           </Grid>
          <Grid item xs={4}>
            <ChipFilter chipData={filters} handleDelete={handleDeleteChip}/>
          </Grid>
        <LoaderContent loading={loading} minHeight={200}>
        <div style={{  
          float: "right"
          }}>
          <Pagination  count={state.totalPages} page={page} onChange={handleChangePage} defaultPage={1} siblingCount={0} />
          </div>
            <Table
              columns={COLUMNS_TABLE}
              rows={state.data}
            />
        <div style={{  
          float: "right"
          }}>
              <Pagination  count={state.totalPages} page={page} onChange={handleChangePage} defaultPage={1} siblingCount={0}  />
        </div>
        </LoaderContent>
      </Grid>
    )
}

export default PermissionTable;