import React from 'react'

import { Checkbox, FormControlLabel, Grid, Icon, Typography } from '@material-ui/core'

import { Link } from 'react-router-dom'

const PaymentsConditions = ({ acceptPayment, setAcceptPayment }) => {
    return (
        <Grid>
            <Grid item xs={9} className="pb-3">
                <FormControlLabel style={{ alignItems: "flex-start"}} checked={!acceptPayment} 
                    control={<Checkbox color="primary" size='small' onChange={()=> setAcceptPayment(!acceptPayment)} />}
                    label={
                        <Typography variant='caption' className="semi-bold">
                            Estoy de acuerdo con los montos presentados y acepto los &nbsp;
                            <Link to="/terminos_condiciones" className='text-orange-300' target={'_blank'}>
                                Términos y Condiciones
                            </Link>
                        </Typography>
                    } 
                />
            </Grid>
            <Grid item xs={12} className="pb-3">
                <Grid container spacing={1}>
                    <Grid item>
                        <Icon className="ri-error-warning-line text-orange-300" style={{ fontSize: 'inherit' }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant='caption' className="semi-bold">
                            En caso de modificar el método de pago deberás reiniciar el proceso de compra.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="pb-3">
                <Grid container spacing={1}>
                    <Grid item>
                        <Icon className="ri-lock-2-line" style={{ fontSize: 'inherit' }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant='button' className='text-gray-300'>
                            Todos los pagos son seguros y encriptados.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PaymentsConditions
