import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Box, Grid, Typography } from '@material-ui/core';

// styles
import useStyles from '../../../assets/styles/jss/components/emptyDataMessageStyle';

const EmptyDataMessage = ({image, titleMessage, message}) => {
    const classes = useStyles();

    return (
        <Box width={'100%'}>
            <Box display={'flex'} justifyContent={'center'} className={classes.imageContainer}>
                <img src={image} alt="" width={157} height={141}/>
            </Box>
            <Grid container justifyContent='center' className={classes.titleMessageContainer}>
                <Grid item lg={4} xs={12} className={classes.titleMessageSubcontainer}>
                    <Typography variant='h4' color='primary'>{titleMessage}</Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent='center' className={classes.messageContainer}>
                <Grid item lg={7} xs={12} className={classes.messageSubcontainer}>
                    <Typography variant='body1' className='text-gray-100'>{message}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

EmptyDataMessage.propTypes = {
    image: PropTypes.any.isRequired,
    titleMessage: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

export default EmptyDataMessage;