import React from 'react';

import Box from '@material-ui/core/Box';
import CopyClick from './CopyClick';
import ResendAction from './ResendAction';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const BodyActions = ({ classes, step, rowId, haveError, nexSteps}) => {
    const [chagring, setCharging] = React.useState(false);
    const [new_msge, setNew_msge] = React.useState(null);
    const [newError, setNewError] = React.useState(false);
    const [sending, setSending] = React.useState(false);

    const chargingStatus = () => {
        setCharging(!chagring);
    }

    const isSending = (value) => {
        setSending(value);
    }

    const setValuesByProcess = (status, msje) => {
        setNew_msge(msje);
        setNewError(status);
    }
   
    return (
        <>
            <Box display="flex">
                <ResendAction nexSteps={nexSteps} classes={classes} step={step} rowId={rowId} chargingStatus={chargingStatus} isSending={isSending} setValuesByProcess={setValuesByProcess} newError={newError}/>
                <CopyClick nexSteps={nexSteps} sending={sending} classes={classes} step={step} chagring={chagring} new_msge={new_msge} newError={newError}/>
            </Box>
            <Box display="flex">
            {
                step?.start_date ?
                <>
                    <Typography variant="h6" component="div" className={classes.titleRowDates}>
                        Fecha inicio:
                    </Typography>
                    <Typography variant="h6" component="div" color='primary' className={classes.titleRowDates}>
                        { (new Date(moment.utc(step.start_date).local().format())).toLocaleString()}
                    </Typography>
                </>:
                <>
                    <Typography variant="h6" component="div" className={classes.titleRowDates}>
                        Fecha inicio:
                    </Typography>
                    <Typography variant="h6" component="div" color='primary' className={classes.titleRowDates}>
                        {!haveError || newError ? "En proceso...": "Detenido"}
                    </Typography>
                </>
            }

            { 
                step?.end_date ?
                    <>
                        <Typography variant="h6" component="div" className={classes.titleRowDates}>
                            Última actualización:
                        </Typography>
                        <Typography variant="h6" component="div" color='primary' className={classes.titleRowDates}>
                            {(new Date(moment.utc(step.end_date).local().format())).toLocaleString()}
                        </Typography>
                    </>
                    :<>
                        <Typography variant="h6" component="div" className={classes.titleRowDates}>
                            Última actualización:
                        </Typography>
                        <Typography variant="h6" component="div" color='primary' className={classes.titleRowDates}>
                            {!haveError || newError ? "En proceso...": "Detenido"}
                        </Typography>
                    </>
            }
            </Box>
        </>
        
    );
};

export default BodyActions;