import React from 'react';
import { useHistory } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// @material-ui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from '@material-ui/core';

// assets
import useStyles from '../../../assets/styles/jss/pages/cart/cartStyle';

// shared
import { userIsLogged } from '../../../shared/utils/Sessions';
import { startCheckout } from '../../../shared/utils/checkoutHelpers';

// cart/utils
import { getMembershipFromCart } from '../utils/getMembershipFromCart';
import { getAddonFromCart } from '../utils/getAddonFromCart';

// components
import HarmonHallUpsellingModal from '../HarmonHallAddons/HarmonHallUpsellingModal/HarmonHallUpsellingModal';

const CartPayment = ({ hasHarmonHallAddon }) => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useSelector((state) => state.userReducer);
    const { cart, cartProducts } = useSelector((state) => state.cartReducer);
    const [modalProps, setModalProps] = React.useState({ open: false });
    const isLogged = userIsLogged();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const handleCheckout = () => {
        // Verificar si hay membresía de Talisis y add-on en el carrito
        const existingMembership = getMembershipFromCart(cartProducts);
        const existingAddon = getAddonFromCart(cartProducts);

        // No cargar las membresías Harmon Hall si el usuario ya cuenta con una de ellas
        if (existingMembership && !existingAddon && hasHarmonHallAddon === false) {
            setModalProps({ ...modalProps, open: true });
        } else {
            startCheckout({
                history,
                order: cart,
                user: user,
                isLogged
            });
        }
    };

    const handleOnCloseModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false, product: null, message: null });
    };

    return <Box display="flex" className={classes.cartPayment}>
        <Button fullWidth={isMobile} variant="contained" color="primary" size="large" onClick={handleCheckout} disabled={!cartProducts?.length}>Pagar ahora</Button>
        <HarmonHallUpsellingModal {...modalProps} onClose={handleOnCloseModal} />
    </Box>
};

export default CartPayment;