import React from 'react'

import SummaryStep from '../../SummaryStep/SummaryStep'
import PaymentMethodsCheckout from '../../PaymentMethodsCheckout/PaymentMethodsCheckout'

import { getCheckoutCookie } from '../../../../../../shared/utils/checkoutSession-legacy.js'
import { checkoutSteps } from '../../helpers/checkoutSummaryHelpers';

const PaymentMethodsWorkFlow = (props) => {

    const checkoutCookie = getCheckoutCookie();

    const { order, user, admisionId, periodo, freeCoupon, prevStep, nextPaymentDate, membershipPlan } = props;
    
    const isStepReadOnly = (step) => checkoutCookie?.readOnlySteps?.includes(step);

    return (
        <>
            {
                checkoutCookie.membershipSession &&
                <SummaryStep
                    isActive={false}
                    stepTitle={"Periodo de Membresía"}
                    stepNumber={1}
                    modifyBtnHidden={isStepReadOnly(checkoutSteps.MEMBERSHIP_PERIODS)}
                    modifyBtnAction={prevStep}
                    membershipPlan={membershipPlan}
                />
            }
            <SummaryStep
                isActive={true}
                modifyBtnAction={() => {}}
                stepTitle={"Método de pago"}
                component={
                    <PaymentMethodsCheckout
                        admisionId={admisionId} periodo={periodo} nextPaymentDate={nextPaymentDate}
                        freeCoupon={freeCoupon} order={order} user={user} 
                        summaryStep={4} 
                    />
                }
            />
        </>
    )
}

export default PaymentMethodsWorkFlow
