const RolePermisionObj = () =>[
  {
    required:false,
    type: 'number',
    label: 'Id',
    name: 'id',
    length: 100,
    visibility: 'block',
    error: false,
    helperText: '',
    visibility: 'none',
  },
  {
    required: false,
    type: 'text',
    label: 'Nombre',
    name: 'name',
    length: 100,
    disabled:true,
    visibility: 'none',
    error: false,
    helperText: ''
  },
  {
    required: false,
    type: 'text',
    label: 'Descripción',
    name: 'description',
    defaultValue: 'SCOPE',
    disabled:true,
    length: 100,
    visibility: 'none',
    error: false,
    helperText: ''
  },
  {
    required: false,
    type: 'text',
    label: 'Value',
    name: 'value',
    length: 100,
    defaultValue:'E',
    disabled:true,
    visibility: 'none',
    error: false,
    helperText: ''
   },
  {
    required: true,
    type: 'number',
    label: 'Role Id',
    name: 'role_id',
    length: 100,
    defaultValue:0,
    disabled:true,
    visibility: 'block',
    error: false,
    helperText: ''
  },
  {
    required: true,
    type: 'select',
    label: 'Permiso',
    name: 'permission_id',
    defaultValue:'',
    error: false,
    helperText: '',
    items: []
  },
  {
    required: true,
    type: 'select',
    label: 'Alcance',
    name: 'permission_scope_id',
    defaultValue:'',
    error: false,
    helperText: '',
    items:[{"id" : 1,"label" : "Company"},
          {"id" : 2,"label" : "Campus"},
          {"id" : 3,"label" : "Group"},
          {"id" : 4,"label" : "Course"},
          {"id" : 5,"label" : "Person"}]
  },
  {
    required : true,
    type:'select',
    label:'Empresa',
    name:'company_id',
    defaultValue:'',
    error:false,
    helperText:'',
    items: [ {id:'ADV','label'	:'ADVENIO'},
             {id:'HH','label'	:'Harmon Hall'},
             {id:'TAL','label'	:'TALISIS'},
             {id:'UERRE','label'	:'U-ERRE'},
             {id:'UNID','label'	:'UNID'},
             {id:'ALL','label'	:'ALL'}]
  }, {
    required : false,
    type:'select',
    label:'Catalogo',
    name:'catalogo',
    defaultValue:'',
    error:false,
    disabled:true,
    helperText:'',
    items: []
  }
] 

export default RolePermisionObj;