import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { log } from './shared/utils/console.js'

// Talisis:
const firebaseConfig = {
    apiKey: "AIzaSyC8XqZJ2gGfgTE8F9L4l6vu1fPwBu6oDDc",
    authDomain: "point-app-movil.firebaseapp.com",
    projectId: "point-app-movil",
    storageBucket: "point-app-movil.appspot.com",
    messagingSenderId: "364449213750",
    appId: "1:364449213750:web:6d0ba24c56371506ead797",
    measurementId: "G-Q4TTJR6RMN"
};

export const initializeFirebase = () => {
    return initializeApp(firebaseConfig);
};

export const requestForToken = async () => {
    const messaging = getMessaging();
    let PUBLIC_VAPID_KEY = 'BE098FLv48uWy7tkmctrUxw3bFE4lMoe7zt6v1Ngtqulu60DD_wag1mV9H_CYbslNHKEWRZpvLy6C4JpcvPVqus'; // Talisis
    const token = await getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY })
        .then(token => {
            if (token) {
                return token;
            }
            else {
                log('No registration token available. Request permission to generate one.');
                return null;
            }
        })
        .catch(error => {
            log('An error occurred while retrieving token. ', error);
            return null;
        });

    return token;
};

export const onMessageListener = () =>
    new Promise(resolve => {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });