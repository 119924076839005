import React, { useEffect, useState } from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom';

// redux
import { connect, useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../../../../redux/actions/userActions';

import { Box, Button, Collapse, FormControlLabel, Grid, Hidden, Icon, Radio, RadioGroup, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import PaymentComponents from './PaymentComponents/PaymentComponents';
import AsyncPaymentModal from '../../AsyncPaymentModal/AsyncPaymentModal';

import { createCheckoutRefs } from './PaymentComponents/helpers/createCheckoutRefs';
import { getCheckoutCookie } from '../../../../../shared/utils/checkoutSession-legacy.js';

import SpeiLogo from "../../../../../assets/images/banks/spei.svg";
import { getSessionCookie } from '../../../../../shared/utils/Sessions'
import { getCardLogo } from './PaymentComponents/helpers/getCardLogo';
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent';

import { formatCustomerCard, getUserCard, handleFinishAsyncPayment, validateConektaUser, validateCreditCard } from './helpers/checkoutPaymentMethods';
import PaymentsConditions from './PaymentsConditions/PaymentsConditions';
import PaymentModal from '../PaymentModal/PaymentModal';
import { setOuPurchase } from './PaymentComponents/helpers/createCardPayment';
import { useConekta } from '../../../../../shared/hooks';
import { validateCard } from './PaymentComponents/helpers/validateCard';
import Toast from '../../../../../design-system/components/Toast/Toast';
import { log } from '../../../../../shared/utils/console.js'
import { updateUserMembership } from './PaymentComponents/helpers/updateUserMembership';
import { MarketPlaceService } from '@sdk-point/talisis';
import NewCardModal from '../../NewCardModal/NewCardModal';
import NewCard from '../../ConektaPayments/Card/NewCard';
import { setMonthlyPaymentPlan } from '../../../../../redux/actions/legacyCheckoutActions.js';
import MonthlyPaymentsDropdown from './MonthlyPaymentsDropdown/MonthlyPaymentsDropdown';

// shared
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';
import clsx from 'clsx';
import ReferralCouponCodeBanner from '../../../../Referrals/components/ReferralCouponCodeBanner.jsx';
import * as paths from '../../../../../routes/paths';

const useStyles = makeStyles((theme) => ({
    labelFormControl: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
        },
        '& .MuiFormControlLabel-label': {
            width: '100%'
        }
    },
    cardsLabelFormControl: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
            width: '100%'
        },
        '& .MuiFormControlLabel-label': {
            width: '100%'
        }
    },
    message:{
        ...theme.typography.bodyTextXsmallRegular,
    },
    messageOrange: {
        ...theme.typography.bodyTextSmallSemiBold,

    }
}));

const CashLimit = ({ className, theme }) => {
    return <Grid item className={className}>
        <Grid container display="flex" alignContent="center" style={{ flexFlow: "nowrap" }}>
            <Box className="mr-1">
                <i className="ri-error-warning-line" style={{ color: theme.palette.colors.darkBlack[200], fontSize: '16px' }} />
            </Box>
            <Box>
                <Typography variant='caption' className="semi-bold" style={{ color: theme.palette.colors.darkBlack[200] }}>
                    El monto máximo para pagar en efectivo es de $10,000 pesos.
                </Typography>
            </Box>
        </Grid>
    </Grid>
};

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

const PaymentMethodsCheckout = ({ order, freeCoupon, summaryStep, user, updateMembership, nextPaymentDate, admisionId, periodo }) => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    // Checkout Reducer
    const { personInfo, monthly_payments, couponData } = useSelector((state) => state.legacyCheckoutReducer);
    const dispatch = useDispatch();

    const checkoutCookie = getCheckoutCookie();
    const sessionCookie = getSessionCookie();
    const conekta = useConekta();

    const userInfo = checkoutCookie.personInfo || personInfo;

    const [optionSelected, setOptionSelected] = useState('');
    const [orderReferences, setOrderReferences] = useState({});
    const [customerCards, setCustomerCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openLoadingPayment, setOpenLoadingPayment] = useState(false);
    const [openRefModal, setOpenRefModal] = useState(false);
    const [openNewCardModal, setOpenNewCardModal] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [acceptPayment, setAcceptPayment] = useState(true);
    const [openCollapsable] = useState(true)

    const [cardFormInfo, setCardFormInfo] = useState({ 'number': '', 'name': '', 'exp_year': '', 'exp_month': '', 'cvc': '', 'phone': '', 'email': '' })
    const [acceptedSaveCard, setAcceptedSaveCard] = useState(true);
    const [enablePayBtn, setEnablePayBtn] = useState(false);
    const [reLoadCards, setReLoadCards] = useState(false)

    const [openToast, setOpenToast] = useState(false);

    const CASH_LIMIT = 10000;

    const setToastProps = (open, message) => {
        setOpenToast(open);
        setErrorMessage(message);
    };

    const handleFinishedToast = () => setToastProps(false, '')

    const handleRadioSelectionChanged = (e) => {
        const RADION_BUTTON_VALUE = e.target.value;
        if (RADION_BUTTON_VALUE === "card") {
            setOpenNewCardModal(true);
        } else {
            setOptionSelected(RADION_BUTTON_VALUE);
            sessionStorage.setItem('userLastPaymentMethod', RADION_BUTTON_VALUE);
            setAcceptPayment(() => true);
            dispatch(setMonthlyPaymentPlan(1))
        }// Resets monthly_payments when changing selected option
    }

    const handleFinishPurchase = () => {
        if (optionSelected !== '') {
            (optionSelected === "free_charge") && handleContinue(optionSelected);

            if (optionSelected.includes("savedMethod")) {
                const selectedCard = customerCards.find((card) => optionSelected.includes(card[0].last4));
                const ouCard = selectedCard.find(card => card.ou === setOuPurchase(order.conceptos))
                if(ouCard?.id) {
                    handleContinue("savedMethod", ouCard.id);
                } else {
                    const message = <>
                                    <Typography  className={classes.message} style={{display:'flex', paddingTop:1}}>No pudimos procesar los datos de tu tarjeta, ayúdanos a eliminarlo y volver a intentar.
                                        <Link component={RouterLink}
                                            to={paths.PERFIL} 
                                            underline="none" 
                                        >
                                           <Typography className={clsx(classes.messageOrange, 'text-orange-300')} style={{marginLeft:30, display:'flex'}}>Modificar método de pago<i class="ri-arrow-right-line" style={{marginLeft:10}}></i></Typography>
                                        </Link>
                                    </Typography> 
                                   </>
                    setToastProps(true, message)
                }
            }

            // ( optionSelected === "card" ) && handleCardPayment();
            (optionSelected === "card") && setOpenNewCardModal(true);

            if (optionSelected === "spei" || optionSelected === "cash") {
                setOpenRefModal(true)
            }

            handleFinishPurchaseEventTrack(optionSelected);
        } else {
            setToastProps(true, "Seleccione un método de pago")
        }
    }

    const handleFinishPurchaseEventTrack = (optionSelected) => {
        let paymentMethod = "";
        if (optionSelected?.includes("savedMethod")) {
            const selectedCards = customerCards?.find((card) => optionSelected?.includes(card[0].last4));
            if (selectedCards?.length > 0) {
                if (selectedCards[0]?.card_type === "debit") paymentMethod = "TDD";
                if (selectedCards[0]?.card_type === "credit") paymentMethod = "TDC";
            }
        }
        else if (optionSelected === "spei") paymentMethod = "Transferencia";
        else if (optionSelected === "cash") paymentMethod = "Efectivo";
        else if (optionSelected === "free_charge") paymentMethod = "cupon";

        let orderMembership = order?.conceptos.find(concepto => concepto.membership_slug);
        let orderCourse = order?.conceptos.find(concepto => concepto.course_id);

        let paymentPeriod = null;
        if (orderMembership?.membership_plan_months === 1) paymentPeriod = "mensual";
        if (orderMembership?.membership_plan_months === 3) paymentPeriod = "trimestral";
        if (orderMembership?.membership_plan_months === 6) paymentPeriod = "semestral";
        if (orderMembership?.membership_plan_months === 12) paymentPeriod = "anual";

        const eventData = {};
        eventData['tipoMembresia'] = orderMembership?.membership_slug || "freemium";
        eventData['periodoPago'] = paymentPeriod;
        eventData['courseID'] = orderCourse?.course_id;
        eventData['marcaCurso'] = orderCourse?.company_id || orderCourse?.source_name;
        eventData['precio'] = order?.total;
        eventData['cuponDescuento'] = couponData?.code;
        eventData['metodoPago'] = paymentMethod;
        eventTracker('finalizar_compra', eventData, ['mixpanel']);
    };

    //actualizacion crm data
    const handleProspectPutCRM = async () => {
        try {
            setLoading(true);
            const responseProspectCRM = await marketService.putProspectCRM({
                admisionId: admisionId,
                estatusAdmision: '6',
                periodoPago: periodo
            });
            log('result: ', responseProspectCRM);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const fetchData = async () => {
        setLoading(true);
        if (validateConektaUser(userInfo.person_id)) {
            try {
                const userCards = await getUserCard(userInfo.person_id);
                setCustomerCards(userCards)
            } catch (error) {
                setToastProps(true, "Se ha producido un error al cargar los metodos de pago guardados")
            }
        }
        setLoading(false);
        setReLoadCards(false);
    };

    useEffect(() => {
        const eventData = {};
        eventTracker('s_metodo_pago', eventData, ['mixpanel', 'hubspot', 'google']);
    }, [])

    useEffect(() => {
        fetchData();
    }, [reLoadCards])

    useEffect(() => {
        if (!acceptPayment && optionSelected !== "card") {
            setEnablePayBtn(true)
        }
        if (optionSelected === "card" && validateCard(cardFormInfo)) {
            setEnablePayBtn(true)
        }
        if (acceptPayment) setEnablePayBtn(false)
    }, [acceptPayment, cardFormInfo])


    useEffect(() => {
        if (!freeCoupon) {
            if (!validateCard(cardFormInfo)) {
                setEnablePayBtn(false)
            }
        }
    }, [cardFormInfo])

    useEffect(() => {
        if (freeCoupon) {
            setOptionSelected('free_charge');
            // setEnablePaymentCheck( true )
            setEnablePayBtn(true)
        }
        sessionStorage.setItem('userLastPaymentMethod', 'card');
    }, [freeCoupon]);

    const handleAsyncPay = (optionSelected) => {
        setOpenRefModal(false);
        setOpenLoadingPayment(true);
        handleContinue(optionSelected)
    }

    // Old method to pay directly with card and saveCard 
    // const handleCardPayment = async() => {
    //     setOpenLoadingPayment(prev => !prev);
    //     await createCardPayment(cardFormInfo, userInfo, order, history, conekta, updateMembership)
    //     await handleProspectPutCRM();
    //     if( acceptedSaveCard ){
    //         const cardSaved = await handleSaveCard(cardFormInfo, userInfo.person_id, conekta)

    //         if(!cardSaved.ok){
    //             setToastProps( true, cardSaved.message )
    //         }                
    //     }
    // }

    const handleContinue = async (selectedPayment, paymentCard) => {
        setToastProps(false, "")
        setOpenLoadingPayment(true);
        const createdRef = await createCheckoutRefs(selectedPayment, paymentCard, order.id, userInfo?.person_id, orderReferences, monthly_payments.selectedNumberOfPayments)
        if (createdRef?.response?.success) {
            setOrderReferences(createdRef?.response?.references)
            setOpenLoadingPayment(false)
            if (optionSelected === "free_charge") {
                const activeAccount = sessionCookie?.person_id ? true : false;
                history.replace({
                    pathname: "/payment-confirmation",
                    search: `?orderId=${order.id}`,
                    state: {
                        "user": userInfo,
                        "activeAccount": activeAccount,
                        "activateAccountURL": checkoutCookie?.personInfo?.activateAccountURL
                    }
                });

                updateUserMembership(order, updateMembership);
            }
            // TODO: Implement re-usable logic to redirect
            if (optionSelected.includes("savedMethod")) {
                const activeAccount = sessionCookie?.person_id ? true : false;
                history.replace({
                    pathname: "/payment-confirmation",
                    search: `?orderId=${order.id}`,
                    state: {
                        "user": userInfo,
                        "activeAccount": activeAccount,
                        "activateAccountURL": checkoutCookie?.personInfo?.activateAccountURL
                    }
                });

                handleProspectPutCRM();
                updateUserMembership(order, updateMembership);
                setLoading(false);
            }

            if (selectedPayment === "spei" || selectedPayment === "cash") {
                handleFinishAsyncPayment({ sessionCookie, history, order, userInfo, checkoutCookie })
            }

            setOpenLoadingPayment(false);
        }
        if (!createdRef?.response?.success) {
            let message = 'Se ha producido un error al realizar el pago';
            if (createdRef?.conektaErrorMessage) {
                message = createdRef?.conektaErrorMessage;
            }
            setToastProps(true, message);
            setOpenLoadingPayment(false);
        }
    }

    const breakpoint = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <LoaderContent loading={loading}>
            <Grid container className={breakpoint ? 'mt-3' : 'mt-3'} alignItems='baseline' justifyContent='space-between'>
                <Typography variant='h3' className={breakpoint ? 'mb-4' : 'mb-3'} style={{ color: theme.palette.colors.violet[300] }}>
                    Método de pago
                </Typography>
                <ReferralCouponCodeBanner />
                <Grid container direction="row" className='mt-3'>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <RadioGroup value={(freeCoupon) ? "free_charge" : optionSelected} name="radio-buttons-group" onChange={handleRadioSelectionChanged}>
                                {
                                    (freeCoupon)
                                        ? <Grid container>
                                            <Grid item xs='auto'>
                                                <FormControlLabel
                                                    value="free_charge" control={<Radio color="primary" />}
                                                    label="Cupon 100% de Descuento"
                                                    style={{ fontSize: '14px', marginBottom: '14px' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        : <>
                                            <Grid container>
                                                {
                                                    (customerCards.length > 0) &&
                                                    customerCards.map((card, index) => {
                                                        if (card.length === 0) return null
                                                        return (
                                                            <Grid item key={index} xs={12}>
                                                                <FormControlLabel
                                                                    // value={`savedMethod - ${card[0].last4}`} control={<Radio color="primary" />}
                                                                    value={formatCustomerCard(card[0].last4)} className={classes.cardsLabelFormControl} labelPlacement={breakpoint ? 'start' : 'end'} control={<Radio color="primary" />}
                                                                    label={
                                                                        <>
                                                                            <img src={getCardLogo(card[0].brand)} alt={card[0].brand} style={{ height: '15px', marginRight: 12 }} />
                                                                            {
                                                                                breakpoint
                                                                                    ? `Tarjeta terminación ***${card[0].last4}`
                                                                                    : `Tarjeta con terminación **** ${card[0].last4}`
                                                                            }
                                                                        </>
                                                                    }
                                                                    style={{ fontSize: '14px', marginBottom: '14px', width: '100%' }}
                                                                />
                                                                {
                                                                    (optionSelected === formatCustomerCard(card[0].last4) && validateCreditCard(card) && monthly_payments.enabled) &&
                                                                    <Grid container>
                                                                        <Grid item xs={9} className='ml-4 mb-3'>
                                                                            <MonthlyPaymentsDropdown availableMonths={monthly_payments.available_months} />
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs>
                                                    <FormControlLabel value="card" className={classes.labelFormControl} labelPlacement={breakpoint ? 'start' : 'end'} control={<Radio color="primary" />} sx={{ '& label:': { width: '100vw' } }} style={{ fontSize: '14px', marginBottom: '14px', width: '100%' }}
                                                        label={
                                                            <Grid container className='' alignItems='center'>
                                                                <Grid item className='pr-2'>
                                                                    <Grid container alignItems='center'>
                                                                        <Icon className='ri-bank-card-line mr-3' fontSize='inherit' />
                                                                        <Typography variant='body2'>
                                                                            Nueva tarjeta de Crédito / Débito
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} sm="auto" className='ml-1'>
                                                                    <Box display="flex" justifyContent={breakpoint ? "flex-start" : "flex-end"} className={breakpoint ? "pl-4 ml-2" : ""}>
                                                                        <Box>
                                                                            <img src={getCardLogo('visa')} alt="Visa" style={{ height: '15px', marginRight: 12 }} />
                                                                            <img src={getCardLogo('mastercard')} alt="Mastercard" style={{ height: '17px', marginRight: 12 }} />
                                                                            <img src={getCardLogo('american_express')} alt="Amex" style={{ height: '17px', marginRight: 12 }} />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs>
                                                    <FormControlLabel value="spei" className={classes.labelFormControl} labelPlacement={breakpoint ? 'start' : 'end'} control={<Radio color="primary" />} style={{ fontSize: '14px', marginBottom: '14px', width: '100%' }}
                                                        label={
                                                            <Grid container className='' alignItems='center'>
                                                                <Grid item className='pr-2'>
                                                                    <Grid container alignItems='center'>
                                                                        <Icon className='ri-swap-box-line mr-3' fontSize='inherit' />
                                                                        <Typography variant='body2'>
                                                                            Transferencia Electrónica
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs="auto" className='ml-1'>
                                                                    <Box display="flex" justifyContent="flex-end">
                                                                        <Box>
                                                                            <img src={SpeiLogo} alt="Visa" style={{ height: '15px', marginRight: 12 }} />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs>
                                                    <FormControlLabel disabled={(order?.total > CASH_LIMIT ? true : false)} value="cash" className={classes.labelFormControl} labelPlacement={breakpoint ? 'start' : 'end'} control={<Radio disabled={true} color="primary" style={{ color: (order?.total > CASH_LIMIT ? theme.palette.colors.fillStroke[200] : '') }} />} style={{ fontSize: '14px', marginBottom: '14px', width: '100%' }}
                                                        label={
                                                            <Grid container alignItems='center'>
                                                                <Grid item className='pr-2'>
                                                                    <Grid container alignItems='center'>
                                                                        <Icon className='ri-wallet-3-line mr-3' fontSize='inherit' style={{ color: (order?.total > CASH_LIMIT ? theme.palette.colors.fillStroke[200] : '') }} />
                                                                        <Typography variant='body2' style={{ color: (order?.total > CASH_LIMIT ? theme.palette.colors.fillStroke[200] : '') }}>
                                                                            Efectivo
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                {!breakpoint && (order?.total > CASH_LIMIT) && <CashLimit theme={theme} className={clsx(classes.cashLimit, 'pl-2')} />}
                                                            </Grid>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                }
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    {breakpoint && (order?.total > CASH_LIMIT) && <CashLimit theme={theme} className={clsx(classes.cashLimit, 'mb-4')} />}
                    <Grid item md={10} xs={12}>
                        <Collapse in={openCollapsable} className="pt-1" timeout="auto" unmountOnExit>
                            <PaymentComponents
                                nextPaymentDate={nextPaymentDate}
                                paymentOption={optionSelected}
                                user={userInfo}
                                order={order} orderReferences={orderReferences}
                                getCardForm={setCardFormInfo}
                                getAcceptedSaveCard={setAcceptedSaveCard}
                            />
                        </Collapse>
                    </Grid>
                    <PaymentsConditions
                        acceptPayment={acceptPayment} setAcceptPayment={setAcceptPayment}
                    />
                    <Grid item xs={12} className="my-4">
                        <Grid container justifyContent='flex-start'>
                            <Button fullWidth={breakpoint} disabled={!enablePayBtn} onClick={() => handleFinishPurchase()} color='primary' variant='contained'>
                                {
                                    "Finalizar compra"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <PaymentModal
                    isModalOpen={openLoadingPayment}
                    modalCloseFunc={() => setOpenLoadingPayment(false)}
                />
                {
                    <NewCardModal
                        isModalOpen={openNewCardModal}
                        modalCloseFunc={() => setOpenNewCardModal(false)}
                        title={"Nueva tarjeta de crédito o débito"}
                        component={
                            <NewCard
                                getCardForm={setCardFormInfo} getAcceptedSaveCard={setAcceptedSaveCard}
                                nextPaymentDate={nextPaymentDate} order={order}
                                user={userInfo}
                                closeModal={() => setOpenNewCardModal(false)}
                                setReLoadCards={() => setReLoadCards(true)}
                                setOptionSelected={setOptionSelected}
                            />
                        }
                    />
                }
                {
                    !openLoadingPayment &&
                    <AsyncPaymentModal
                        isModalOpen={openRefModal}
                        modalCloseFunc={() => setOpenRefModal(false)}
                        modalBtnFunc={() => handleAsyncPay(optionSelected)}
                        title={"¿Seguro que deseas continuar?"}
                        primaryText={"La orden de pago que se generará es única y cuenta con fecha de expiración."}
                        secondaryText={"En caso de que desees cambiar de método de pago deberás realizar de nuevo la compra."}
                    />
                }
            </Grid>

            <Toast severity="error" open={openToast} message={errorMessage} onFinished={handleFinishedToast} duration={3000} anchorOrigin={{vertical:'top', horizontal:'center'}}/>
        </LoaderContent>
    )
}

const mapStateToProps = ({ userReducer }) => ({
    user: userReducer.user
});

const mapDispatchToProps = { ...userActions };
export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsCheckout);