import React from 'react'

import { Card, CardContent, Grid, Icon, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cardContent:{
        padding: '24px 32px'
    },
    textColor:{
        color: theme.palette.colors.gray[400],
    },
    listItemStyle:{
        [theme.breakpoints.down('xs')]:{
            alignItems: 'flex-start'
        }
    },
    listItemIconStyle:{
        [theme.breakpoints.down('xs')]:{
            minWidth: 32
        }
    },
    listItemIconStyle:{
        [theme.breakpoints.down('xs')]:{
            minWidth: 32
        }
    },
    marginCards:{
        marginTop: 32,
        [theme.breakpoints.down('xs')]:{
            marginTop: 12
        }
    }
}));

const StudyPlanListCard = ({ title, listArray = [] }) => {

    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const theme = useTheme();
    const classes = useStyles()

    return (
        <Grid container justifyContent="center" className={classes.marginCards}>
            <Grid item md={12} xs={12}>
                <Card>
                    <CardContent className={classes.cardContent}>
                        <Grid item xs={12} className="mt-3">
                            <Typography variant='h4'>
                                { title }
                            </Typography>
                            <List dense={true}>
                            {
                                (listArray.length > 0) &&
                                listArray.map((listItem, index) =>
                                    <ListItem key={index} disableGutters={isResponsive ? true : false} className={classes.listItemStyle}>
                                        <ListItemIcon className={classes.listItemIconStyle}>
                                            <Icon className='ri-check-fill font-size-16 text-orange-300 mt-1'/>
                                        </ListItemIcon>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography variant='caption' className={classes.textColor}>
                                                    { listItem }
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                )
                            }
                            </List>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>        
    )
}

export default StudyPlanListCard
