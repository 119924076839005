import React from 'react'

import { Grid } from '@material-ui/core';

import { getCheckoutCookie } from '../../../../../shared/utils/checkoutSession-legacy.js';

import PriceSubtotal from './PriceSubtotal/PriceSubtotal';
import PendingDiscount from './PendingDiscount/PendingDiscount';
import OrderDiscount from './OrderDiscount/OrderDiscount';
import OrderTotal from './OrderTotal/OrderTotal';
import OrderSavings from './OrderSavings/OrderSavings';
import NextPaymentDate from './NextPaymentDate/NextPaymentDate';
import { useSelector } from 'react-redux';
import MonthlyPayments from './MonthlyPayments/MonthlyPayments';

const PriceSummary = ({ orderQty, pendingDiscount, nextPaymentDateSelected }) => {

    const checkoutCookie = getCheckoutCookie();
    
    // Checkout Reducer
    const { monthly_payments } = useSelector((state) => state.legacyCheckoutReducer);

    return (
        <Grid item xs={12}>
        { 
            checkoutCookie.membershipSession &&
            <PriceSubtotal orderQty={orderQty} />
        }
        {  
            orderQty.pending_discount > 0 && 
            // <PendingDiscount pendingDiscount={pendingDiscount} />
            <PendingDiscount pendingDiscount={orderQty.pending_discount} />
        }
        { 
            orderQty?.order_discount > 0 && 
            <OrderDiscount order_discount={orderQty.order_discount} />
        }
        <OrderTotal orderQty={orderQty} />
        {
            monthly_payments?.enabled && monthly_payments?.selectedNumberOfPayments > 1 && 
            <MonthlyPayments months={monthly_payments?.selectedNumberOfPayments} orderQty={orderQty} />
        }
        {
            orderQty?.ahorro > 0 && 
            <OrderSavings ahorro={orderQty.ahorro} />
        }
        {
            checkoutCookie.membershipSession && 
            <NextPaymentDate nextPaymentDateSelected={nextPaymentDateSelected} /> 
        }
        </Grid>
    )
}

export default PriceSummary
