import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    boxContainer: {
        display: 'flex',
        alignItems: 'end',
    },
    interestingButton: {
        marginLeft: "8px",
        backgroundColor: theme.palette.colors.electricBlue[300],
        color: theme.palette.colors.white,
    },
    detailButton: {
        backgroundColor: theme.palette.colors.electricBlue[300],
        color: theme.palette.colors.white,
    },
    detailButonFullSize: {
        width: "100%"
    }
}));
