import * as cartTypes from '../types/cartTypes';

export const applyCart = (cart) => (dispatch) => {
    dispatch({
        type: cartTypes.APPLY_CART,
        payload: cart
    })
};

export const applyCartProducts = (products) => (dispatch) => {
    dispatch({
        type: cartTypes.APPLY_CART_PRODUCTS,
        payload: products
    })
};

export const applyAddons = (addons) => (dispatch) => {
    dispatch({
        type: cartTypes.APPLY_ADDONS,
        payload: addons
    })
};

export const applySelectedAddon = (addon) => (dispatch) => {
    dispatch({
        type: cartTypes.APPLY_SELECTED_ADDON,
        payload: addon
    })
};