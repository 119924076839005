import React from 'react';
import { useSelector } from 'react-redux';

// components
import OrderSummaryCard from '../components/OrderSummaryCard/OrderSummaryCard';
import OrderSubtotal from './OrderSubtotal/OrderSubtotal';
import OrderBonuses from './OrderBonuses/OrderBonuses';
import OrderDiscounts from './OrderDiscounts/OrderDiscounts';
import OrderTotal from './OrderTotal/OrderTotal';
import OrderSavings from './OrderSavings/OrderSavings';
import OrderNextPaymentDate from './OrderNextPaymentDate/OrderNextPaymentDate';

// assets
import useStyles from '../../../assets/styles/jss/pages/checkout/checkout';
import OrderMonthlyPayments from './OrderMonthlyPayments/OrderMonthlyPayments';

const CheckoutPriceDetails = () => {
    const classes = useStyles();
    const { orderPriceDetails } = useSelector(state => state.checkoutReducer);
    const { installments } = useSelector(state => state.checkoutReducer);

    return (
        <OrderSummaryCard className={classes.orderPriceDetailsCard}>
            <OrderSubtotal orderSubtotal={orderPriceDetails?.subtotal} />
            {orderPriceDetails?.bonuses > 0 && <OrderBonuses orderBonuses={orderPriceDetails.bonuses} />}
            {orderPriceDetails?.discounts?.length > 0 && <OrderDiscounts orderDiscounts={orderPriceDetails.discounts} />}
            <OrderTotal orderTotal={orderPriceDetails?.total} />
            {installments?.selectedInstallmentOption > 1 && <OrderMonthlyPayments installmentOption={installments?.selectedInstallmentOption} orderTotal={orderPriceDetails?.total} />}
            {orderPriceDetails?.savings > 0 && <OrderSavings orderSavings={orderPriceDetails.savings} />}
            {orderPriceDetails?.next_payment_date && <OrderNextPaymentDate orderNextPaymentDate={orderPriceDetails.next_payment_date} />}
        </OrderSummaryCard>
    )
};

export default CheckoutPriceDetails;