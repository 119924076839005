import React from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';
import { v4 as uuid } from 'uuid';

// @material-ui/core
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import typography from '../../../../design-system/theme/typography';
import Button from '@material-ui/core/Button';
import { getSessionCookie } from '../../../../shared/utils/Sessions';
import objectIsEmpty from '../../../../shared/utils/objectIsEmpty';
import { startCheckout } from '../../../../shared/utils/legacyStartCheckout';

// shared
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const PLAN_MENSUAL = "MENS";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: props => props.isResponsive ? '24px 16px' : '24px 24px',
        boxSizing: 'border-box',
    },
    rootCompareFeatures: {
        height: '234px !important',
        padding: props => props.isResponsive ? '24px 16px' : '24px 24px',
        boxSizing: 'border-box',
    },
    caption: {
        color: theme.palette.colors.darkBlack[100],
    },
    caption_features: {
        color: theme.palette.colors.darkBlack[200],
    },
    caption_2: {
        color: theme.palette.colors.darkBlack[200],
    },
    total_price: {
        ...typography.web.h6Card
    },
    price_tachado: {
        textDecoration: 'line-through'
    },
    compareFeatureCard: {
        height: '200px !important',
        maxHeight: '100% !important',
        padding: '0 !important',
        position: 'relative',
    },
    compareFeatureCardMensual: {
        height: '140px !important',
        maxHeight: '100% !important',
        padding: '0 !important',
        position: 'relative',
    },
    compareFeaturesButton: {
        [theme.breakpoints.down('xs')]: {
            position: "relative",
            textAlign: "center"
        }
    },
    compareFreemium: {
        minHeight: props => props.feePlan !== PLAN_MENSUAL ? "54px" : "auto",
        [theme.breakpoints.down('xs')]: {
            position: "relative",
            textAlign: "center"
        }
    },
    subscriptionButton: {
        whiteSpace: "nowrap",
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            width: "206px",
        }
    },
    discountOffContainer: {
        display: "flex",
        alignItems: 'center',
        padding: "4px 22px",
        width: "100%",
        minHeight: "40px",
        borderRadius: "5px",
        marginBottom: "24px",
        border: "0.5px solid var(--degradados-degradado-3, #FCDC43)",
        justifyContent: 'center',
        flexDirection: "row"
    },
    discountOff: {
        background: "var(--degradados-degradado-3, linear-gradient(274deg, #FCDC43 15.67%, #FFA700 57.57%))",
        fontWeight: 700,
        backgroundClip: 'text',
        '-webkit-background-clip': "text",
        '-webkit-text-fill-color': "transparent",
        '-moz-background-clip': "text",
        '-moz-text-fill-color': "transparent"
    },
    creditCardOutlined: {
        display: "flex",
        padding: "8px",
        alignItems: "center",
        gap: "10px",
        marginTop: "12px",
        alignSelf: "stretch",
        borderRadius: "4px",
        border: `0.5px solid var(--fills-and-strokes-100, ${theme.palette.colors.fillStroke[100]})`,
    },
    creditCardSolid: {
        display: "flex",
        padding: "0px 8px 0px 8px",
        alignItems: "center",
        gap: "10px",
        marginTop: "16px",
        alignSelf: "stretch"
    },
    comparePrices: {
        minHeight: "60px"
    }
}));

const getMembershipPlanTermVariant = (id, fee) => id === "freemium" ? "siempre" : fee?.term_months > 1 ? `pago ${fee?.term_description.toLowerCase()} ` : "pago mensual";

const getMembershipIcon = (id) => {
    if (id === "lite") {
        const _uuid = uuid();

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 0L11.5577 4.4762C11.9999 5.74708 12.221 6.38253 12.7285 6.7511C13.236 7.11967 13.9087 7.13336 15.254 7.16075L20 7.25735L16.2209 10.1192C15.147 10.9326 14.61 11.3393 14.4159 11.9364C14.2218 12.5335 14.417 13.1781 14.8076 14.4675L16.1803 19L12.283 16.291C11.1789 15.5235 10.6268 15.1398 10 15.1398C9.37319 15.1398 8.82112 15.5235 7.71699 16.291L3.81966 19L5.19243 14.4675C5.58295 13.1781 5.77821 12.5335 5.58413 11.9364C5.39004 11.3393 4.85305 10.9326 3.77905 10.1192L0 7.25735L4.74595 7.16075C6.09131 7.13336 6.76399 7.11967 7.27148 6.7511C7.77897 6.38253 8.00009 5.74708 8.44234 4.4762L10 0Z" fill={`url(#paint0_linear_${_uuid})`} />
                <defs>
                    <linearGradient id={`paint0_linear_${_uuid}`} x1="31.3031" y1="48.7228" x2="2.8953" y2="46.803" gradientUnits="userSpaceOnUse">
                        <stop offset="0.583333" stop-color="#FCDC43" />
                        <stop offset="0.895833" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    if (id === "professional") {
        const _uuid = uuid();

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 0L10.8847 3.64386C11.18 4.85978 11.3276 5.46774 11.7968 5.6621C12.2661 5.85647 12.8003 5.53096 13.8689 4.87993L17.0711 2.92893L15.1201 6.13113C14.469 7.19967 14.1435 7.73394 14.3379 8.20318C14.5323 8.67243 15.1402 8.82004 16.3561 9.11527L20 10L16.3561 10.8847C15.1402 11.18 14.5323 11.3276 14.3379 11.7968C14.1435 12.2661 14.469 12.8003 15.1201 13.8689L17.0711 17.0711L13.8689 15.1201C12.8003 14.469 12.2661 14.1435 11.7968 14.3379C11.3276 14.5323 11.18 15.1402 10.8847 16.3561L10 20L9.11527 16.3561C8.82004 15.1402 8.67243 14.5323 8.20318 14.3379C7.73394 14.1435 7.19967 14.469 6.13113 15.1201L2.92893 17.0711L4.87993 13.8689C5.53096 12.8003 5.85647 12.2661 5.6621 11.7968C5.46774 11.3276 4.85978 11.18 3.64386 10.8847L0 10L3.64386 9.11527C4.85978 8.82004 5.46774 8.67243 5.6621 8.20318C5.85647 7.73394 5.53096 7.19967 4.87993 6.13113L2.92893 2.92893L6.13113 4.87993C7.19967 5.53096 7.73394 5.85647 8.20318 5.6621C8.67243 5.46774 8.82004 4.85978 9.11527 3.64386L10 0Z" fill={`url(#paint0_linear_${_uuid})`} />
                <defs>
                    <linearGradient id={`paint0_linear_${_uuid}`} x1="31.3031" y1="51.2871" x2="2.8827" y2="49.4625" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0520833" stop-color="#FCDC43" />
                        <stop offset="1" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    if (id === "premium") {
        const _uuid = uuid();

        return (
            <svg key={uuid()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 0L12.1194 5.56588C12.4337 6.39129 12.5908 6.804 12.8934 7.10659C13.196 7.40917 13.6087 7.56632 14.4341 7.88063L20 10L14.4341 12.1194C13.6087 12.4337 13.196 12.5908 12.8934 12.8934C12.5908 13.196 12.4337 13.6087 12.1194 14.4341L10 20L7.88063 14.4341C7.56633 13.6087 7.40917 13.196 7.10659 12.8934C6.804 12.5908 6.39129 12.4337 5.56588 12.1194L0 10L5.56588 7.88063C6.39129 7.56633 6.804 7.40917 7.10659 7.10659C7.40917 6.804 7.56632 6.39129 7.88063 5.56588L10 0Z" fill={`url(#paint0_linear_${_uuid})`} />
                <defs>
                    <linearGradient id={`paint0_linear_${_uuid}`} x1="31.3031" y1="51.2871" x2="2.8827" y2="49.4625" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0520833" stop-color="#FCDC43" />
                        <stop offset="1" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    return null;
};

const MembershipDiscountOff = ({ discountOff, classes }) => {
    return <Box display="flex" className={classes.discountOffContainer}>
        <Box textAlign="right" className='pr-2'>
            <Typography variant="h3" className={classes.discountOff}>-{discountOff}%</Typography>
        </Box>
        <Box>
            <Typography variant="body2" className="font-size-12">Descuento adicional por referido</Typography>
        </Box>
    </Box>
};

const CreditCardOutlined = ({ classes, compareFeatures, termMonths }) => {
    return <Box className={clsx({ [classes.creditCardOutlined]: !compareFeatures, [classes.creditCardSolid]: compareFeatures })}>
        <i className="ri-bank-card-line text-dark-black-100 font-size-16"></i>
        <Typography variant="caption" className='text-dark-black-100'>{`${!compareFeatures ? "Paga a" : ""} ${termMonths} meses sin intereses con tarjeta de crédito`}</Typography>
    </Box>;
};

const MembershipPlanCardMedia = (props) => {
    const { id, name, fee, className, showIcon, feePlan, discountOff, fees, membershipId, popular, user, compareFeatures, slideMode, isResponsive, hideButton, calculateStrikeOutPrice } = props;
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const history = useHistory();
    const classes = useStyles({ isResponsive, feePlan });
    const planVariant = getMembershipPlanTermVariant(id, fee);
    const icon = showIcon && getMembershipIcon(id);

    // prices
    const monthlyAmount = fee?.amount / fee?.term_months;
    const baseAmount = fees?.find(fee => fee.term_slug === PLAN_MENSUAL)?.amount || 0;
    let strikeOutPrice = baseAmount;

    // strike out price
    if (calculateStrikeOutPrice === "standard-discount") {
        const baseDiscount = (monthlyAmount / baseAmount);
        strikeOutPrice = Math.round(baseDiscount * baseAmount);
    }

    const handleSubscribe = () => {
        if (id !== "freemium") {
            let tempFee = fees.find((value, index) => value.term_slug === feePlan);
            let tempFeeId = tempFee?.id ?? 0;
            const membershipInfo = {
                membershipId: membershipId, // 1, 2, 6, 13
                membershipFee: tempFeeId, // 1, 2, 3, 4
            }
            const userLogged = isLogged && !objectIsEmpty(user);
            const productInfo = [
                { itemType: "membershipId", membershipInfo }
            ];

            let paymentPeriod = null;
            if (tempFee?.term_months === 1) paymentPeriod = "mensual";
            if (tempFee?.term_months === 3) paymentPeriod = "trimestral";
            if (tempFee?.term_months === 6) paymentPeriod = "semestral";
            if (tempFee?.term_months === 12) paymentPeriod = "anual";
            trackPurchase(id, paymentPeriod);

            startCheckout({
                user: user,
                orderProducts: productInfo,
                history,
                isLogged: userLogged
            });
        }
        else {
            trackPurchase("freemium", null);

            history.replace({
                pathname: "/signup"
            });
        }
    };

    const trackPurchase = (membership, paymentPeriod) => {
        const eventData = {};
        eventData['source'] = 'planes';
        eventTracker('cta_registro', eventData, ['hubspot', 'google']);
        eventData['tipoMembresia'] = membership;
        eventData['periodoPago'] = paymentPeriod;
        eventTracker('comprar_membresia', eventData, ['mixpanel', 'hubspot' ,'google']);
    }

    const applyDiscount = (discount, amount) => {
        if (!discount) return amount;
        return Math.ceil(amount * (1 - (discount / 100)));
    };

    const buttonText = id === "freemium" ? "Empezar ahora" : "Suscribirme ahora";

    return (
        <Box className={compareFeatures ? clsx(className, classes.rootCompareFeatures, (feePlan === PLAN_MENSUAL ? classes.compareFeatureCardMensual : classes.compareFeatureCard)) : clsx(className, classes.root)}>
            {discountOff && <MembershipDiscountOff discountOff={discountOff} classes={classes} />}
            <Grid container spacing={1} justifyContent="center">
                {icon && <Grid item style={{ display: "flex" }}>{icon}</Grid>}
                <Grid item style={{ alignSelf: "center" }}><Typography variant={compareFeatures ? "subtitle2" : "h5"}>{name}</Typography></Grid>
            </Grid>
            <Grid container justifyContent="center" className="mt-3" alignItems='center' direction='column'>
                {
                    id === "freemium" ?
                        <Box>
                            <Grid item className={clsx(classes.compareFreemium, "membership-amount")}>
                                <Typography variant={compareFeatures ? "h4" : "h3"} color="initial" component="span">Gratis</Typography>
                                <Typography variant="body1" className={clsx(classes.caption, 'ml-1')} component="span">siempre</Typography>
                            </Grid>
                            {compareFeatures && !isLogged && <Grid item xs={12} className="mt-4"><Button variant="outlined" fullWidth color="secondary" onClick={handleSubscribe} className={classes.compareFeaturesButton} style={{ right: 0 }}>Empezar ahora</Button></Grid>}
                        </Box> :
                        <Box>
                            {compareFeatures ?
                                <Typography variant="h5" color="secondary" component="div" className='membership-amount-month' align="center">
                                    <CurrencyFormat value={applyDiscount(discountOff, monthlyAmount)} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" />
                                    <Typography variant="h5" color="secondary" component="span" className={clsx('ml-1 mr-1', 'membership-amount-month', 'font-size-12')}>MXN</Typography>
                                    <Typography className="font-size-12" color="secondary" component="span">al mes</Typography>
                                </Typography> :
                                <Grid item className='membership-amount'>
                                    {/*feePlan !== PLAN_MENSUAL && <Typography className={clsx(classes.caption_2, 'mr-1', classes.price_tachado)} component='span' variant='body2'>
                                        <CurrencyFormat value={strikeOutPrice} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" />
                                    </Typography>*/}
                                    <Typography variant="h4" color="secondary" component="span" className='membership-amount-month'>
                                        <CurrencyFormat value={applyDiscount(discountOff, monthlyAmount)} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" />
                                        <Typography variant="h5" color="secondary" component="span" className={clsx('ml-1', 'membership-amount-month')}>
                                            MXN
                                        </Typography>
                                        <Typography className={clsx('ml-1', id !== popular && 'text-orange-300')} style={{ display: 'inline-block' }}>/mes</Typography>
                                    </Typography>
                                </Grid>
                            }
                        </Box>
                }
            </Grid>
            {
                id !== "freemium" && feePlan !== PLAN_MENSUAL ?
                    <Grid container justifyContent='center' alignItems='baseline' direction='row' className={compareFeatures ? 'mt-1' : 'mt-2'}>
                        {
                            showIcon ?
                                <>
                                    <Grid item><Typography variant="body2" className={clsx(classes.caption, "mr-1")} align="center">{planVariant}</Typography></Grid>
                                    <Grid item>
                                        <Typography variant='body2' component='span' className={clsx('semi-bold', 'text-gray-100')}>
                                            <CurrencyFormat value={applyDiscount(discountOff, fee.amount)} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" /> MXN
                                        </Typography>
                                    </Grid>
                                </> :
                                <>
                                    <Grid item><Typography variant="caption" className={clsx(classes.caption, "mr-1")} align="center">{planVariant}</Typography></Grid>
                                    <Grid item className="">
                                        <Typography variant='caption' component='span' className={clsx('semi-bold', 'text-gray-100')}>
                                            <CurrencyFormat value={applyDiscount(discountOff, fee.amount)} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" /> MXN
                                        </Typography>
                                    </Grid>
                                    {
                                        !hideButton &&
                                        <Grid item xs={12} className={clsx(classes.compareFeaturesButton, "mt-4")}>
                                            {
                                                id === popular ?
                                                    <Button variant="contained" color="primary" className={classes.subscriptionButton} onClick={handleSubscribe}>{buttonText}</Button> :
                                                    <Button variant="outlined" color="secondary" className={classes.subscriptionButton} onClick={handleSubscribe}>{buttonText}</Button>
                                            }
                                        </Grid>
                                    }
                                    {!hideButton && !slideMode && <Grid item><CreditCardOutlined classes={classes} compareFeatures={compareFeatures} termMonths={fee?.term_months} /></Grid>}
                                </>
                        }
                    </Grid> :
                    (compareFeatures || slideMode) &&
                    <Grid container justifyContent='center' alignItems='baseline' direction='row' className={compareFeatures ? feePlan === PLAN_MENSUAL ? 'mt-4' : 'mt-1' : 'mt-2'}>
                        {
                            !hideButton &&
                            <Grid item xs={12} className={classes.compareFeaturesButton}>
                                {
                                    id === popular ?
                                        <Button variant="contained" color="primary" className={classes.subscriptionButton} onClick={handleSubscribe}>{buttonText}</Button> :
                                        id !== "freemium" && <Button variant="outlined" color="secondary" className={classes.subscriptionButton} onClick={handleSubscribe}>{buttonText}</Button>
                                }
                            </Grid>
                        }
                    </Grid>
            }
            {!hideButton && !compareFeatures && !slideMode && id !== "freemium" && feePlan !== PLAN_MENSUAL && <CreditCardOutlined classes={classes} termMonths={fee?.term_months} />}
        </Box>
    );
};

MembershipPlanCardMedia.defaultProps = {
    calculateStrikeOutPrice: 'normal'
};

export default MembershipPlanCardMedia;