import React from 'react'

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

//component
import AcademyList from '../components/academyList';
import { useEffect } from 'react';

export const OnCardAcademy= ({onSetOnCard, skillHierarchy, handleChange, eventData, eventTracker}) => {

    useEffect(() => {
        eventTracker('s_ob_areas',eventData, ['mixpanel']);
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt="40px">
            <Typography variant="h3" color="primary" className="mb-4">
                Queremos conocerte mejor
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary" className="mb-3" style={{maxWidth: 480}}>
            Con esta información personalizaremos tu experiencia para potenciar efectivamente tu perfil profesional.
            </Typography>
            <Typography align="center" className="font-variant-v4">
                Elige 3 intereses favoritos.
            </Typography>
            {
                <AcademyList items={skillHierarchy} handleChange={handleChange}  onSetOnCard={onSetOnCard}/>
            }
        </Box>
    )
}

export default OnCardAcademy;
