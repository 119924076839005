import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { Box, useMediaQuery } from '@material-ui/core';

// Components
import TwoSectionMedia from './TwoSectionMedia';

// Styles
import useStyles from '../../../../assets/styles/components/cards/landSearchCourseCard/twoSectionBodyStyle';

const TwoSectionBody = ({children, media}) => {
    const classes = useStyles();

    return (
        <Box className={classes.rootTwoSection}>
            <Box className={classes.twoSectionMedia}>
                <TwoSectionMedia {...media}/>
            </Box>
            <Box  className={classes.twoSectionContent}>
                {children}
            </Box>
        </Box>
    );
};

TwoSectionBody.propTypes = {
    media: PropTypes.shape({
        backgroundImage: PropTypes.string,
        ratingData: PropTypes.shape({
            number: PropTypes.number
        }),
        sourceIdLogo: PropTypes.number.isRequired,
    }).isRequired,
};

export default TwoSectionBody;