import React from 'react'

import { Card, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
      cardContent: {
        padding: '24px 32px'
    },
    textColor:{
        color: theme.palette.colors.gray[400],
    },
    marginCards:{
        marginTop: 32,
        [theme.breakpoints.down('xs')]:{
            marginTop: 12
        }
    },
    [theme.breakpoints.down('xs')]:{
      textColor:{
        fontSize: '14px !important'
      },
      cardContent: {
        padding: 16
      },
    }
}));

const StudyPlanMultiList = ({ title, listArray = [] }) => {

    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const theme = useTheme();   
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Grid container justifyContent="center" className={classes.marginCards}>
          <Grid item md={12} xs={12}>
            <Card style={{ maxHeight: "350px" }}>
              <CardContent className={classes.cardContent}>
                <Grid item xs={12} className="mt-3">
                  <Typography variant='h4'>
                    { title }
                  </Typography>
                </Grid>
                <Grid item xs={12} className="mt-2">
                  <List dense={true}>
                    <Grid container direction={isMobile ? 'column' : 'row'}>
                      {listArray.length > 0 && listArray.map((listItem, index) => (
                        <Grid item xs={12} sm={6} key={index} style={{marginBottom: 8}}>
                          <ListItem  className='p-0' >
                            <CheckIcon
                              fontSize='inherit'
                              style={{ color: theme.palette.colors.orange[300], fontSize: 18 }}
                              className="mr-2"
                            />
                            <ListItemText
                              disableTypography
                              className='m-0'
                              primary={
                                <Typography variant='body1' className={clsx(classes.textColor, 'm-0')}>
                                  { listItem }
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Grid>
                      ))}
                    </Grid>
                  </List>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    )
}

export default StudyPlanMultiList
