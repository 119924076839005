import React, {useState, useEffect} from 'react'
// share component
import NewButton from '../../shared/components/Button/NewButton'
import PermissionTable from './PermissionTable/PermissionTable'
import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';

// @material-ui/core
import {Card, CardContent, Box} from '@material-ui/core'; 
// @material-ui/Tabs
import Tabs from '../../shared/components/Tabs/Tabs';
// services
import * as UserService from '../../services/UserService'

const PermissionManagment = (props) => { 
    const [canReadWrite, setCanReadWrite] = useState(false);
    const tabs = ['Todos'];
    
    useEffect(()=>{
        hanldeCanReadWrite();
    },[])

    const hanldeCanReadWrite = () => {
        const canReadWrite = UserService.canReadWrite(props.pageId);
        setCanReadWrite(canReadWrite);
    }
    
    const hanldeClickNew = () => props.history.push(`/permisos/crear`);

    const items = [
        { to: '/Home', label: 'Administrador' ,title:''},
        { to:'/permisos/management', label: 'Permisos' , title:''}
       
      ];

    return (
        <>
        <Breadcrumb items={items} />
        <Box>
            <Tabs
                activeTab={0}
                tabs={tabs}
                actions={(canReadWrite) && <NewButton onClick={hanldeClickNew} label='Nuevo Permiso'/>}
            >
                <Card>
                    <CardContent>
                       <PermissionTable canReadWrite={canReadWrite}/>
                    </CardContent>
                </Card>
            </Tabs>
        </Box>
        </>
        )
}


export default PermissionManagment;