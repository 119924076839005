import React from 'react';

import { Box, Typography, Divider } from '@material-ui/core';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root:{
        background: theme.palette.colors.fillStroke[400],
        padding: '8px 0px',
        '& div':{
            padding: '0px 16px'
        },
        borderRadius: '8px 8px 0px 0px'
    },
    gutters:{
        padding: '8px 0px',
        '& div':{
            padding: '0px 16px'
        }
    }
}));

const InstructionsTitle = ({title, subtitle, divider = false, plain = false, ...rest}) => {
    const classes = useStyles();

    return (
        <Box id="instructions-title-root"
            display='flex' flexDirection='column' gridGap={8} 
            className={clsx({[classes.root]: !plain})}
            {...rest}
        >
            <Box>
                <Typography variant='h4'>{title}</Typography>
            </Box>
            {divider && <Divider className='bg-fill-stroke-300'/>}
            <Box>
                <Typography variant='body2'>{subtitle}</Typography>
            </Box>
        </Box> 
    );
};

export default InstructionsTitle;