import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootUserRoleTags: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
}));
