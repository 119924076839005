import { makeStyles } from '@material-ui/core/styles';
import typography from '../../../../design-system/theme/typography';

export default makeStyles((theme) => ({
    root:{
        color: "white",
        alignItems: "flex-end",
        justifyContent: "start",
        textDecoration: 'unset !important',
        '& .subtitle':  {
            opacity: 0,
            transform: "translate3d(-6px, 0px, 0px)",
            transition: '0.35s ease-in-out',
            marginBottom: '-4px',
        },
        '& .MuiButton-endIcon':{
            opacity: "1",
            visibility: "1",
            marginBottom: "0px",
            transform: "translate3d(-90px, 0px, 0px) scale(1.2)",
            transition: '0.4s ease-in-out ',
            color: theme.palette.white
        },
        '&:hover':{
            '& .subtitle':  {
                position: 'static !important',
                transition: '0.35s ease-in-out !important',
                transform: "translate3d(4px, 0px, 0px) !important",
                visibility: 'visible !important',
                opacity: "1 !important",
            },
            '& .MuiButton-endIcon':{
                transform: "translate3d(0px, 0px, 0px) scale(1) !important",
                transition: '0.4s ease-in-out opacity 0.2s linear !important',
                color: theme.palette.secondary.main,
                '&.MuiButton-iconSizeMedium > *:first-child':{
                    marginBottom: '-3px',
                    fontSize: 25
                }
            },
        },
        [theme.breakpoints.down('xs')]:{
            '& button':{
                padding: '0px !important',
                '& .MuiButton-label':{
                    marginBottom: '14px',
                    textAlign: 'start',
                    display: 'flex',
                    justifyContent: 'space-between',
                }
            },
            '& .subtitle':  {
                position: 'static !important',
                transition: '0.35s ease-in-out !important',
                transform: "translate3d(4px, 0px, 0px) !important",
                visibility: 'visible !important',
                opacity: "1 !important",
                minWidth: 75
            },
            '& .MuiButton-endIcon':{
                transform: "translate3d(0px, 0px, 0px) scale(1) !important",
                transition: '0.4s ease-in-out opacity 0.2s linear !important',
                color: theme.palette.secondary.main,
                marginLeft: 0,
                marginBottom: '-5px',
            },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            padding: '0px 6px 0px 16px',
            gap: '8px'
        }
    },
    buttonIcon:{
        marginBottom: '-4px',
        fontSize: 20,
        [theme.breakpoints.down('xs')]:{
            marginBottom: 'unset',
            fontSize: '24px !important',
        }
    },
    textPrimary:{
        marginRight: 12,
        [theme.breakpoints.down('xs')]:{
            width: 210,
            marginRight: '0px',
            textAlign: 'start',
            ...typography.h4
        }
    },
    boxSecondary:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        [theme.breakpoints.down('xs')]:{
            alignItems: 'flex-start',
            gap: 8,
            // marginTop: '15px'
        }
    },
    button:{
        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    }
}));