import React from 'react';
import { Favorite, ThumbUp } from "@material-ui/icons";
import Clapping from './component/Clapping';

const LIKE = 1;
const LOVE = 2;
const CLAPPING = 3;

const likeIcons = {
    [LIKE]: {
        icon: <ThumbUp style={{ width: '24px' }} />,
        label: 'Me gusta',
        color: '#9F6FFB',
    },
    [LOVE]: {
        icon: <Favorite style={{ width: '24px' }} />,
        label: 'Me encanta',
        color: '#DF43B7',
    },
    [CLAPPING]: {
        icon: <Clapping className={'MuiSvgIcon-root'} focusable="false" viewBox="0 0 18 18" color={'#22D83D'} style={{ width: '24px' }} />,
        label: '¡Aplausos!',
        color: '#22D83D',
    }
}

export { LIKE, LOVE, CLAPPING, likeIcons };