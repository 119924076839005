import React, { useState, useEffect } from 'react'

import { FormControlLabel, Grid, Radio, RadioGroup, Typography, Button, Box, useMediaQuery } from '@material-ui/core'
import CurrencyFormat from 'react-currency-format';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import MembershipPlanModal from './MembershipPlanModal';
import typography from '../../../../../design-system/theme/typography';
import Toast from '../../../../../shared/components/Toast/Toast';
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';

import { getCheckoutCookie, setCheckoutCookie } from '../../../../../shared/utils/checkoutSession-legacy.js';

import { MarketPlaceService } from '@sdk-point/talisis';

import { useDispatch, useSelector } from 'react-redux';
import { setMembershipOrderSelectedPlan, setOrderQuantities, setPurchaseOrder } from '../../../../../redux/actions/legacyCheckoutActions.js';
import { updateOrderQuantitiesAndSelectedPeriod } from '../../../../../shared/utils/checkoutHelpers-legacy.js';
import { membershipPeriodsStyles } from './membershipPeriodsStyles';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

const MembershipPeriods = (props) => {

    const classes = membershipPeriodsStyles();
    const theme = useTheme();
    const checkoutCookie = getCheckoutCookie();
    
    // const { nextStep, membershipFeePlans, order, setOrder, setSelectMembershipPaymentStep, addNewDifferenceAmountFees, percentOff, addLabelNextPaymentDay, addPendingDiscount, addDaysToNextPayment, currentFeePlan, dayPrice, daysToNextPayment,userCurrentMembership,orderQty,setOrderQty } = props;
    const { setSelectMembershipPaymentStep, 
        addNewDifferenceAmountFees, addLabelNextPaymentDay, addPendingDiscount, 
        addDaysToNextPayment, currentFeePlan, dayPrice, daysToNextPayment, setPeriodo, nextStep
    } = props;

    // Checkout Reducer
    const { order: orderReducer, userCurrentMembership: userCurrentMembershipReducer, 
        orderQuantity: quantityReducer, membershipOrderFeePlans, couponData
    } = useSelector((state) => state.legacyCheckoutReducer);
    const dispatch = useDispatch();

    const PLAN_MENSUAL = "MENS";
    const PLAN_TRIMESTRAL = "TRIM";
    const PLAN_SEMESTRAL = "SEM";
    const PLAN_ANUAL = "ANU";
    const CONEKTA_MIN_PRICE=30;

    const [membershipPlans, setMembershipPlans] = useState([]);
    //const [selectedPlan, setSelectedPlan] = useState({});

    // const [optionSelected, setOptionSelected] = useState(selectedPlan.term_slug || 'MENS');
    const [optionSelected, setOptionSelected] = useState("");
    const [loading, setLoading] = useState(false)
    const [openToast, setOpenToast] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false);
    const [membershipPlansIncluded,setMembershipPlansIncluded]=useState([]);

    const setToastProps = (open, message) => {
        setOpenToast(open);
        setErrorMessage(message);
    };

    const handleFinishedToast = () => {
        setToastProps(false, '');
    };

    const changeSelectedPlan=async(newselected)=>{
        setOptionSelected(newselected);

        const { orderQuantities, selectedPeriod } = updateOrderQuantitiesAndSelectedPeriod(newselected, membershipPlans, quantityReducer)
        addLabelNextPaymentDay(selectedPeriod);        
        dispatch(setOrderQuantities(orderQuantities))
    }

    const handleRadioSelectionChanged = (e) => {
        // setOptionSelected(e.target.value);
        changeSelectedPlan(e.target.value);
    }


    useEffect(()=>{
        // const { membershipPlans } = membershipFeePlans;
        const { membershipPlans } = membershipOrderFeePlans;
        let tempTermSlug="";
        let qtyLeft=CONEKTA_MIN_PRICE;
        if(currentFeePlan)
        {
            tempTermSlug=membershipPlans?.find((value,index)=>value.id===currentFeePlan?.membership_fee_id);
            tempTermSlug=tempTermSlug?.term_slug;
        }
        if(dayPrice && daysToNextPayment)
        {
            qtyLeft=dayPrice*daysToNextPayment;
        }

        if( userCurrentMembershipReducer !== null)
        {
            if(currentFeePlan)
            {
                if(tempTermSlug===PLAN_TRIMESTRAL)
                {
                    setMembershipPlansIncluded(membershipPlans);   
                }
                else if(tempTermSlug===PLAN_SEMESTRAL)
                {
                    let semPeriod=membershipPlans?.find((value,index)=>value.term_slug===PLAN_SEMESTRAL);
                    let anuPeriod=membershipPlans?.find((value,index)=>value.term_slug===PLAN_ANUAL);
                    let tempPlans=[];
                    tempPlans.push(semPeriod);
                    tempPlans.push(anuPeriod);
                    setMembershipPlansIncluded(tempPlans);
                }
                else if(tempTermSlug===PLAN_ANUAL)
                {
                    let anuPeriod=membershipPlans?.find((value,index)=>value.term_slug===PLAN_ANUAL);
                    let tempPlans=[];
                    tempPlans.push(anuPeriod);
                    setMembershipPlansIncluded(tempPlans);                        
                }
                else
                {
                    setMembershipPlansIncluded(membershipPlans);                                    
                }                
            }
            else
            {
                setMembershipPlansIncluded(membershipPlans);                
            }
        }
        else
        {
            setMembershipPlansIncluded(membershipPlans);
        }
        // setMembershipPlansIncluded(membershipPlans);
        const newSelectedOption = membershipPlans?.find( membership => membership.id === checkoutCookie?.membershipPurchase?.membership_fee_id);
        const optionSelectedResponse = newSelectedOption?.term_slug ? newSelectedOption?.term_slug : (tempTermSlug!==""?tempTermSlug:'MENS');
        setMembershipPlans(membershipPlans);
        setOptionSelected(optionSelectedResponse);
        
        // Update quantities upon render
        const { orderQuantities, selectedPeriod } = updateOrderQuantitiesAndSelectedPeriod(optionSelectedResponse, membershipPlans, quantityReducer)
        addLabelNextPaymentDay(selectedPeriod);
        dispatch(setOrderQuantities(orderQuantities));

        const eventData = {};
        eventTracker('s_periodo_pago', eventData, ['mixpanel','google']);
    }, []);   

    const handleContinuePurchase = async(newPlan) => {
        let soq={
            ...quantityReducer,
            onSelectingPeriod:false
        };
        
        dispatch(setOrderQuantities(soq));
        
        // const orderMembership = order.conceptos.find( concepto => concepto.membership_fee_id)
        const orderMembership = orderReducer.conceptos.find( concepto => concepto.membership_fee_id)
        // const newSelectedPlan = membershipPlans.find( plan => plan.term_slug === newPlan)
        const newSelectedPlan = membershipOrderFeePlans.membershipPlans.find( membershipPlan => membershipPlan.term_slug === newPlan)
       
        dispatch(setMembershipOrderSelectedPlan(newSelectedPlan));
       
        await handleProspectPutCRM(newSelectedPlan.term_months);
        
        await handleProspectPutCRM(newSelectedPlan.term_months);
        
        // const selectedNumberOfPayments = membershipOrderFeePlans.membershipPlans.find( membershipPlan => membershipPlan.term_slug === newPlan)
        // console.log("selectedNumberOfPayments", selectedNumberOfPayments )

        let paymentPeriod = null;
        if (newSelectedPlan?.term_months === 1) paymentPeriod = "mensual";
        if (newSelectedPlan?.term_months === 3) paymentPeriod = "trimestral";
        if (newSelectedPlan?.term_months === 6) paymentPeriod = "semestral";
        if (newSelectedPlan?.term_months === 12) paymentPeriod = "anual";
        handleContinuePurchaseEventTrack(newSelectedPlan?.membership_slug, paymentPeriod, newSelectedPlan?.amount, couponData?.code);

        if( orderMembership.membership_fee_id === newSelectedPlan.id){
            // return setSelectMembershipPaymentStep(true)
            return nextStep()
        } 
        try {
            
            setLoading(true);
            const updatedOrder = await marketService.postUpdateConektaOrder({
                membership_fee_id: newSelectedPlan.id,
                
                order_id: orderReducer.id
            });
            let index = 0;
            if(updatedOrder.newMembershipOrder.conceptos.length > 1){
                index = 1;
            }
            
            if(updatedOrder.newMembershipOrder?.order){
                dispatch(setPurchaseOrder(updatedOrder.newMembershipOrder.order))
            }else{
                
                dispatch(setPurchaseOrder(updatedOrder.newMembershipOrder))
            }
        
            setLoading(false);
            
            updatedOrder.newMembershipOrder.conceptos[index]  = {...updatedOrder.newMembershipOrder.conceptos[index] , id: updatedOrder.newMembershipOrder.conceptos[index].membership_fee_id, term_months: updatedOrder.newMembershipOrder.conceptos[index].membership_plan_months, amount: updatedOrder.newMembershipOrder.conceptos[index].fees_amount};
            
            addNewDifferenceAmountFees(updatedOrder.newMembershipOrder.conceptos[index], membershipPlans);
            addLabelNextPaymentDay(updatedOrder.newMembershipOrder.conceptos[index], membershipPlans);
            
            addPendingDiscount(updatedOrder.newMembershipOrder.pending_discount);
            addDaysToNextPayment(updatedOrder.newMembershipOrder.days_to_next_payment);
            setCheckoutCookie({
                ...checkoutCookie,
                membershipPurchase: updatedOrder.newMembershipOrder.conceptos[index]});
            nextStep();
        } catch (error) {
            setLoading(false);
            setToastProps( true, error )
        }
    }

    const handleContinuePurchaseEventTrack = (membership, membershipPeriod, amount, discountCode) => {
        const eventData = {};
        eventData['tipoMembresia'] = membership;
        eventData['periodoPago'] = membershipPeriod;
        eventData['precio'] = amount;
        eventData['cuponDescuento'] = discountCode;
        eventTracker('continuar_checkout', eventData, ['mixpanel']);
    };

    const handleProspectPutCRM = async(planPagos) => {
        //actualizacion crm data
        try{
            setLoading(true);
            const responseProspectCRM = await marketService.putProspectCRM({
                admisionId: props.admisionId,
                estatusAdmision:'1',
                periodoPago: planPagos
            });
            setPeriodo(planPagos);
            setLoading(false);
        }catch (err){
            setLoading(false);
        }
    }

    const calculateDiscountPercent = (term_months) => {
        
        // const basePrice = membershipFeePlans.membershipPlans.find(plan => plan.term_months === 1).amount;
        // const plan = membershipFeePlans.membershipPlans.find(plan => plan.term_months === term_months);

        const basePrice = membershipOrderFeePlans.membershipPlans.find(plan => plan.term_months === 3).amount;
        const plan = membershipOrderFeePlans.membershipPlans.find(plan => plan.term_months === term_months);
        const percentOff = (100 - (((plan.amount/plan.term_months)* 100)/(basePrice/3)));
        return `Ahorra ${percentOff.toFixed(0)}%`;
    }

    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
   
    return (
        <>
            <Grid container className='mt-3' alignItems='baseline' justifyContent='space-between' style={{ borderBottom: breakpoint?'':`1px solid ${theme.palette.colors.fillStroke[200]}`}}>
                <Typography variant='h3' style={{ color: theme.palette.colors.violet[300] }}>
                    Periodo de pago
                </Typography>
                <Grid item xs={12} className={breakpoint?'mt-5':'mt-1'}> 
                    <Typography variant='body1' style={{ color: theme.palette.colors.white }}>
                        Ahorra hasta un 25% con nuestras opciones de prepago.  
                    </Typography>
                </Grid>
                <Grid item xs={12} className={breakpoint?"mt-4":"mt-3"}>
                        {/* <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={membershipPlans.length>0?membershipPlans[0].term_slug:""} name="radio-buttons-group" onChange={handleRadioSelectionChanged}> */}
                        <RadioGroup row={true} aria-labelledby="demo-radio-buttons-group-label" value={optionSelected} name="radio-buttons-group" onChange={handleRadioSelectionChanged}>
                            <Grid container direction="row" spacing={1}>
                            {
                                 membershipPlansIncluded.filter(m => m.term_slug!==PLAN_MENSUAL).map((value,index)=>{
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className='mb-1'>
                                            <Grid container direction='column' className={clsx(breakpoint?'pb-4':'pb-3',{[classes.priceContainerSelected]:value.term_slug===optionSelected,[classes.priceContainerWithSaving]:value.term_slug!==PLAN_TRIMESTRAL,[classes.priceContainer]:value.term_slug===PLAN_TRIMESTRAL})} onClick={()=>changeSelectedPlan(value.term_slug)}>
                                                {
                                                    <Grid item style={{minHeight:'24px'}} className={classes.savingContainer}>
                                                        {
                                                            value.term_slug!==PLAN_TRIMESTRAL
                                                            ?
                                                                <Grid container direction='row'>
                                                                    <Grid item xs>
                                                                            
                                                                    </Grid>
                                                                    <Grid item xs='auto'>
                                                                        <Typography component='span' className={clsx(classes.savingTitle,'py-1','pr-1','pl-2')}>
                                                                        {
                                                                            calculateDiscountPercent(value.term_months)
                                                                        }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            :
                                                            null
                                                        }
                                                    </Grid>
                                                }
                                                <Grid item>
                                                <Grid container direction={breakpoint?"row":"column"} className={breakpoint?'pl-4 ml-2':'px-3 mt-1 pt-2'} justifyContent={breakpoint?'space-between':''}>
                                                    <Grid item>
                                                    <Grid container direction="column" className='' style={{minHeight:'76.5px'}} justifyContent='center'>
                                                {
                                                    breakpoint
                                                    ?
                                                    <Grid item className=''>
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography variant='body2' className={classes.priceContainerTitle}>
                                                                {value.term_description} 
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    :
                                                    <Grid item className=''>
                                                        <FormControlLabel
                                                            value={value.term_slug} control={<Radio color="primary" size='small' />} sx={{ '& label:': { width: '100vw' } }} 
                                                            style={{ fontSize: '14px' }} 
                                                            label={
                                                                <Box display="flex" flexDirection="column">
                                                                    <Typography variant='body2' className={classes.priceContainerTitle}>
                                                                        {value.term_description} 
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item className='pt-2'>
                                                    <Grid container direction={breakpoint?'row':'column'}>
                                                        <Grid item className={clsx(classes.priceContainerSubTitle,'mb-1')}>
                                                            {
                                                                value.term_slug!==PLAN_MENSUAL
                                                                ?
                                                                "Paga por mes:"
                                                                :
                                                                <>
                                                                    Al mes:
                                                                    <Typography className={clsx('ml-2',classes.priceCrossedOut,'text-gray-400')} variant='body2'>
                                                                        <CurrencyFormat value={value.old_amount_per_month} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" />
                                                                    </Typography>
                                                                </>
                                                            }
                                                        </Grid>
                                                        <Grid item className={clsx(classes.priceContainerSubTitlePrice,breakpoint?'pl-1':'')}>
                                                            <Grid item>
                                                                <Typography variant={breakpoint?"h4":"h5"} color="secondary" component="span"><CurrencyFormat value={value.amount_per_month} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" />
                                                                </Typography>
                                                                <Typography color="secondary" component="span" className={clsx('ml-1','font-variant-v7')}>
                                                                    MXN
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={breakpoint?'pt-1 mt-1':'pt-4 mt-1'} style={{minHeight:breakpoint?'auto':'49.5px'}}>
                                                    {
                                                        value.term_slug!==PLAN_MENSUAL
                                                        ?
                                                        <Grid container direction={breakpoint?'row':'column'}>
                                                            <Grid item className={clsx(classes.priceContainerFooterTitle,'mb-1','text-dark-black-100')}>
                                                                {
                                                                    value.term_slug===PLAN_SEMESTRAL
                                                                    ?
                                                                        "Paga por 6 meses: "
                                                                    :
                                                                    value.term_slug===PLAN_TRIMESTRAL
                                                                       ?   "Paga por 3 meses: "
                                                                       :   "Paga por 1 año:"
                                                                }
                                                            </Grid>
                                                            <Grid item className={breakpoint?'pl-1':''}>
                                                                <Typography color="white" component="span" className={clsx(classes.priceContainerFooterTitlePrice,'text-gray-200')}><CurrencyFormat value={value.amount < 0 ? 0 : value.amount} decimalScale={2} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" />
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        
                                                            breakpoint
                                                            ?
                                                            null
                                                            :
                                                            <Grid container direction='column'>
                                                                <Grid item className={clsx(classes.priceContainerFooterTitle,'mb-1','text-dark-black-100')} style={{minHeight:breakpoint?'15px':'16px'}}>
                                                                </Grid>
                                                                <Grid item style={{minHeight:breakpoint?'':'17px'}}>
                                                                </Grid>
                                                            </Grid>
                                                        
                                                    }
                                                </Grid>
                                                    </Grid>
                                                    </Grid>
                                                    {
                                                        breakpoint
                                                        ?
                                                        <Grid item className='pt-3 mt-1 pr-2'>
                                                            <FormControlLabel
                                                            value={value.term_slug} control={<Radio color="primary" size='medium' />} sx={{ '& label:': { width: '100vw' } }} 
                                                            style={{ fontSize: '14px' }} 
                                                            />
                                                        </Grid>
                                                        :
                                                        null
                                                    }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                            </Grid>
                        </RadioGroup>
                </Grid>
                <Grid item xs={12} className="my-4">
                    <Grid container justifyContent='flex-start'>
                        {/* <Button onClick={() => setSelectMembershipPaymentStep(true)} color='primary' variant='contained'> */}
                        <Button fullWidth={breakpoint} onClick={() => handleContinuePurchase(optionSelected)} color='primary' variant='contained'>
                            Siguiente
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <MembershipPlanModal
                modalOpenFunc={loading} modalCloseFunc={!loading} 
                loading={loading}
            />
            <Toast severity="error" open={openToast} message={errorMessage} onFinished={handleFinishedToast} duration={3000} />
        </>
    )
}

export default MembershipPeriods
