import React from 'react'
import CurrencyFormat from 'react-currency-format'

// @materia-ui
import { Grid, Typography } from '@material-ui/core'

// assets
import useStyles from '../../../../assets/styles/jss/pages/checkout/checkout';

const OrderMonthlyPayments = ({ installmentOption, orderTotal }) => {
    const classes = useStyles();
    return (
        <Grid container justifyContent="space-between" alignItems='center' className={classes.orderMonthlyPayments}>
            <Grid item>
                <Typography variant="caption" className="text-gray-100">
                    Pago por mes:
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption" className="text-gray-100">
                    <CurrencyFormat
                        value={(orderTotal / installmentOption)} className="text-gray-100"
                        decimalScale={2} fixedDecimalScale={true} displayType="text"
                        thousandSeparator={true} prefix="$" suffix=' MXN'
                    />
                    {` x ${installmentOption}`}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default OrderMonthlyPayments;