import React from 'react';
import { Link as RouterLink } from 'react-router-dom'

// @material-ui/core
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Breadcrumb from '../../../../shared/components/Breadcrumb/Breadcrumb';
import { log } from '../../../../shared/utils/console.js'
import Box from '@material-ui/core/Box';
import { getLaunch } from "../../../../services/CoursesService";
const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#EBFCFF',
            transition: '0.3s',
        },
    },
    course:{
        width: "100%",
        height: "100vh"
    }
}));

const getData = async () => {
    try {
        /*setBreadcrumbItems(prev => {
            return [
                { ...prev[0] },
                { ...prev[1], title: course.name }
            ];
        });*/
    } catch (e) {
        log(e.message);
    }
};

const ShowCourse = (props) => {
    const classes = useStyles();
    const { subject, name, source, image_url, to, difficulty_level, location} = props;
    const [urlCourse, setUrlCourse] = React.useState("");
    //const [fst, setFst] = React.useState(0);
    const [breadcrumbItems, setBreadcrumbItems] = React.useState([
        { to: '/cursos', label: 'Mis cursos' },
        { label: location.state.name },
    ]);

    //log(location);

    //var curso = <iframe src={location.state.urlCourse}></iframe>;

    const getLaunchUrl = async (registerid) => {
        try {
            const link = await getLaunch(registerid);
            setUrlCourse(link.launchLink);
            // setFst(1);
         //   log(link.launchLink);
        } catch (e) {
            log(e.message);
        }
    }

    React.useEffect(() => {
        // if(fst == 0){
            getLaunchUrl(location.state.registerId);
        // }
    }, []);
    
    return (
        <Box>
            <Breadcrumb items={breadcrumbItems} />
            <Card className={classes.root}> 
                <iframe className={classes.course} src={urlCourse}></iframe> 
            </Card> 
        </Box>
    );
};

export default ShowCourse;