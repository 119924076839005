import React from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

// @redux
import { connect } from "react-redux"

// @material-ui/core
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';

import useStyles from '../../../../assets/styles/jss/layout/navLinksStyle'

const ListItemTextNotification = ({item, className}) => {
    return (
        <Badge color="error" variant="dot" className={className} invisible={!item.notification}>
            <Typography component="span" color='inherit' className='font-size-14 semi-bold'>{item.label}</Typography>
        </Badge>
    ) 
}

const NavLinks = (props) => {
    const {menu, items, className, onClickItem, goTo, ...rest} = props;
    const classes = useStyles();
    const location = useLocation();
    const [activeNavbarItem, setActiveNavbarItem] = React.useState(null)

    React.useEffect(() => {
       setActiveNavbarItem(location.pathname)
    }, [location]);

    return (
        <Box className={className}>
            <List dense={true} className={classes.list}>
                {items.length && items.filter(itF => itF.display).map((item) =>
                    {
                        if(item.href){
                            return <ListItem href={item.href} target="_blank" variant="body1" button component="a" disableGutters className="semi-bold" underline="none">
                                <ListItemText disableTypography primary={item.label} /> </ListItem>
                        }else if(item.isNested){
                            return (
                                <ListItem variant="body1" button disableGutters key={uuid()} onClick={(e) => onClickItem(item, e)} 
                                    className={clsx({
                                        'navlink-active': items.some(it => it.open) }, 
                                        'semi-bold'
                                    )}>
                                    <ListItemText disableTypography primary={item.label}/>
                                    <Icon className={item.open ? "ri-arrow-drop-up-line font-size-24" :"ri-arrow-drop-down-line font-size-24"}/>
                                </ListItem>
                            )
                        }else{
                            return(
                                <ListItem variant="body1" button disableGutters key={uuid()} onClick={goTo.bind(this, item.path)}
                                    className={clsx('semi-bold', {'navlink-active': item.path === location.pathname && !items.some(it => it.open)})}>
                                    <ListItemText disableTypography
                                        primary={
                                            item.hasOwnProperty('notification')
                                            ? <ListItemTextNotification item={{...item, ...menu.find(menuItem => menuItem.id === item.id)}} className={classes.notification}/>
                                            : `${item.label}`
                                    }/>
                                </ListItem>
                            )
                        }
                    }

                )}
            </List>
        </Box>
    );
};

const mapStateToProps = (state) => ({...state.menuReducer});

export default connect(mapStateToProps, {})(NavLinks);