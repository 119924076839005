import React from "react";
// @material-ui/core
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
const LAST_PATH_NAME = 1;
const INTEGRIDAD_JV = "https://elearning.easygenerator.com/5a4540c0-6a04-4040-bf1f-c9f41ef0b58a/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNGQ3YzJmYWU4N2FlMDAwNjcwM2NjZCIsImVtYWlsIjoiam9zZS52YXJnYXNAdGFsaXNpcy5jb20iLCJuYW1lIjoiSm9zw6kgVmFyZ2FzIiwicm9sZXMiOlsibGVhcm5lciJdLCJpYXQiOjE2NzAyNzU0NzMsImV4cCI6MTY3MDM2MTg3MywiaXNzIjoiYXV0aC5lYXN5Z2VuZXJhdG9yLmNvbSIsImp0aSI6IjlmMDEyYTMwLTNhMzQtNGUxZS05NTVlLWRiMDI5NTQ2NjY1YSJ9.C2hZ0EmhTXcQfV1_II_C0oxwfB79pQJHczUsS_bns38";
const ADMINISTRATE_AL_100_JV = "https://elearning.easygenerator.com/ff3023c2-50fb-4fa7-9646-72ddb36eea2c/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNGQ3YzJmYWU4N2FlMDAwNjcwM2NjZCIsImVtYWlsIjoiam9zZS52YXJnYXNAdGFsaXNpcy5jb20iLCJuYW1lIjoiSm9zw6kgVmFyZ2FzIiwicm9sZXMiOlsibGVhcm5lciJdLCJpYXQiOjE2NzAyNzU0NzMsImV4cCI6MTY3MDM2MTg3MywiaXNzIjoiYXV0aC5lYXN5Z2VuZXJhdG9yLmNvbSIsImp0aSI6IjlmMDEyYTMwLTNhMzQtNGUxZS05NTVlLWRiMDI5NTQ2NjY1YSJ9.C2hZ0EmhTXcQfV1_II_C0oxwfB79pQJHczUsS_bns38";

const DemoEasyGenerator = () => {
    const [demoURL, setDemoURL] = React.useState(null);
    const iframeElement = React.createRef(null);
    const location = useLocation();

    React.useEffect(() => {
        const route = location.pathname.split('/').slice(1);
        if(route[LAST_PATH_NAME] == "demo"){
            setDemoURL(INTEGRIDAD_JV);
        }else if(route[LAST_PATH_NAME] == "demo2"){
            setDemoURL(ADMINISTRATE_AL_100_JV);
        }
        
    }, []);

    return (
         <Box style={{
                width: "99vw",
                height: "100vh",
                position: "absolute",
                left: "0",
                top: "81px",
         }}>
            {demoURL && <iframe ref={iframeElement} src={demoURL} frameborder="0" allowfullscreen style={{ width: "100%", height: "100%", border: "0px" }}></iframe>}
        </Box>
    )
}

export default DemoEasyGenerator;