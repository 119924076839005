import React from 'react';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import { Backdrop, Box, Button, Dialog, Fade, Icon, Typography, IconButton, Card, Grid, ButtonBase} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

// shared
import Loader from '../../../../shared/components/Loader/Loader';
import { log } from '../../../../shared/utils/console.js'

// Styles
import useStyles from '../../../../assets/styles/jss/pages/courses/coursesInProgressStyle';

// Assets
import buscando_clases from '../../../../assets/images/buscando_clases.svg';
import un_paso_mas from '../../../../assets/images/un_paso_mas.svg';
import cursosError from '../../../../assets/images/cursosError.svg';
import ticketEnviado from '../../../../assets/images/ticketEnviado.svg';
import logoUerre from '../../../../assets/images/logos/buttons/UERRELogoButton.svg';
import logoUnid from '../../../../assets/images/logos/buttons/UNIDLogoButton.svg';
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const NoCoursesFound = (props) => {
    const {
        userHasCourses,
        handleGoToNeo,
        handleGoToAllyNeo,
        allies,
        coursesSysError,
        handleSendTicket,
        open,
        setOpen,
        loading,
        ticketSended,
        isRedirected
    } = props;

    const classes = useStyles();

    const handleDialogOpen = () => {
        setOpen(!open);
    }

    React.useEffect(() => {
        if (coursesSysError) {
            setOpen(coursesSysError)
        }
    }, [coursesSysError]);

    return (
        <Box className={classes.boxNotFoundRoot}>
            { !userHasCourses ? 
            <Box className={classes.noCoursesContainer}>
                <Box className={classes.noClassPlaceholder}>
                    <img loading="lazy" alt="live_tv_outlined_icon" className={classes.tv} src={buscando_clases}/>&nbsp;
                </Box>
                <Box mb={2}>
                    <Typography className={classes.titleNoCourses} variant="h2">¿Estás buscando tus clases?</Typography>
                    <TitleBody/>
                </Box>
                <ButtonGoNeo handleGoToNeo={handleGoToNeo} classes={classes.btnNoCourses}/>
            </Box> :
            <Card variant='outlined'>
                <Box display="flex"> 
                    <Box className={classes.boxImg}>
                        <img className={classes.imgStyle} loading="lazy" alt="live_tv_outlined_icon" src={buscando_clases} />&nbsp;
                    </Box>
                    <Box p={1} flexGrow={2} className='text-left'>
                        <Typography className={classes.titleNoCourses} variant="h4">¿No encuentras tus clases? </Typography>
                        <TitleBody/>
                        <Box className={classes.boxButtonNeo}>
                            <ButtonGoNeo handleGoToNeo={handleGoToNeo} classes={classes.btnNoCourses}/> 
                        </Box>
                    </Box>
                </Box>
            </Card>
            }
            <Dialog
                className={classes.modal}
                open={open && !isRedirected}
                closeAfterTransition
                BackdropComponent={Backdrop}
                PaperProps={{
                    style:{
                        backgroundColor: '#1C1F24',
                        width: "504px",
                        height: "540px"
                    }
                }}
                aria-labelledby="dialog"
            >   
                <MuiDialogTitle id="dialog">
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogOpen}>
                        <Icon className="ri-close-line" />
                    </IconButton>
                </MuiDialogTitle>
                <Loader open={loading} />
                <Fade in={open} >                
                    <Box className={classes.dialogContent} >
                    {
                        !coursesSysError &&   
                        <>
                            <Box className={classes.noClassPlaceholder}>
                                <img loading="lazy" alt="un_paso_mas" className={classes.tv} src={un_paso_mas}/>
                            </Box>
                            <Box mb={9}>
                                <Typography className={classes.titleNoCourses} variant="h3">¡Solo un paso más!</Typography>
                                <Typography variant="body1">
                                    Encontramos tus clases de UNID y U-ERRE,<br/>
                                    selecciona la universidad que quieres visitar ahora.
                                </Typography>
                            </Box>
                            <Grid container>
                                {
                                    allies &&
                                    <>
                                    {log(allies)}
                                        {   
                                            allies.map(ally => (
                                                <Grid item xs={6}>
                                                    <Button 
                                                        key={ally.id} 
                                                        onClick={() => handleGoToAllyNeo(ally)} 
                                                        variant='contained' 
                                                        className={classes.btnGoToAlly} 
                                                    >
                                                        <img 
                                                            loading="lazy" 
                                                            alt={ally.company_id} 
                                                            className={classes.tv} 
                                                            src={ ally.company_id === 'UERRE' ? logoUerre : logoUnid }/>
                                                    </Button>
                                                </Grid>
                                            ))
                                        }
                                    </>
                                }
                            </Grid>
                        </>
                    }
                    {
                        coursesSysError && !ticketSended &&
                        <>
                            <Box className={classes.noClassPlaceholder}>
                                <img loading="lazy" alt="cursos_error" className={classes.tv} src={cursosError}/>
                            </Box>
                            <Box mb={5}>
                                <Typography className={classes.titleNoCourses} variant="h3">¡Lo sentimos!</Typography>
                                <Typography variant="body1">
                                    <Typography component="span" variant="body1" className={classes.errorText} >
                                        No encontramos tus clases,
                                    </Typography>&nbsp;pero seguimos <br/> trabajando para solucionar el problema. <br/> <br/>
                                    Para asistencia personalizada puedes <br/>levantar un reporte con el Centro de Servicio.
                                </Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button 
                                        onClick={() => setOpen(false)} 
                                        variant="outlined"
                                        color="primary"
                                        className={classes.errorButtons}
                                    >
                                        Omitir
                                    </Button>
                                    
                                </Grid>
                                <Grid item xs={6}>
                                    <Button 
                                        onClick={() => handleSendTicket()} 
                                        variant='contained' 
                                        color='primary'
                                        className={classes.errorButtons}
                                        style={{border: '0.5px solid #3900D9'}}
                                    >
                                        Enviar reporte
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    }   
                    {
                        ticketSended &&
                        <>
                            <Box className={classes.noClassPlaceholder}>
                                <img loading="lazy" alt="cursos_error" className={classes.tv} src={ticketEnviado}/>
                            </Box>
                            <Box mb={10}>
                            <Typography className={classes.titleNoCourses} variant="h3">Se ha enviado tu reporte correctamente</Typography>
                            <Typography variant="body1">
                                Revisa las actualizaciones de tu reporte en <br/>
                                <Typography component="span" variant="body1" className="semi-bold">“Mis tickets”</Typography>&nbsp;dentro del Menú de ayuda. 
                            </Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button  
                                        onClick={() => setOpen(false)} 
                                        variant='contained' 
                                        color='primary'
                                        className={classes.errorButtonClose}
                                    >
                                        Cerrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    }
                    </Box>
                </Fade>
            </Dialog>
        </Box>
    )
}

const ButtonGoNeo = ({handleGoToNeo, classes}) => {
    return(
        <Button 
            onClick={() => {
                eventTracker('neo', {}, ['mixpanel']);
                handleGoToNeo();
            }}
            variant='contained' 
            color="primary" 
            className={classes} 
            size="small"
            endIcon={<Icon className="ri-arrow-right-s-line" />}
            >
            Ir a NEO 
            </Button>
    )
}

const TitleBody = () => {
    return (
    <Typography variant="body1" >
        Estamos trabajando para que puedas ver tus clases en&nbsp;
        <Typography component="span" variant="body1" className="semi-bold" color='primary'>Talisis.com</Typography>, por ahora <br/>
        ingresa a tu plataforma de aprendizaje NEO para seguir aprendiendo.
    </Typography>)
}

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(NoCoursesFound);