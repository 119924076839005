import * as checkoutTypes from '../types/legacyCheckoutTypes';

const INITIAL_STATE = {
    order: { subtotal: 0.0, total: 0.0, conceptos: [] },
    checkoutLoader: false,
    missingPhone: false,
    checkoutCurrentStep: 1,
    personInfo: {},
    pendingOrderDiscount: 0,
    userCurrentMembershipInfo: {},
    hasFreeCoupon: false,
    couponData: "",
    orderConcepts: [],
    orderQuantity: {},
    orderNextPaymentDate: "",
    membershipOrderFeePlans: [],
    monthly_payments: {
        enabled: false,
        selectedNumberOfPayments: 1,
        available_months: []
    }
}

export default ( state = INITIAL_STATE, action ) => {
    switch (action.type) {
        case checkoutTypes.TOGGLE_CHECKOUT_LOADER:
            return { 
                ...state, 
                checkoutLoader: !state.checkoutLoader
            };

        case checkoutTypes.TOGGLE_MISSING_PHONE:
            return { 
                ...state, 
                missingPhone: action.payload
            };

        case checkoutTypes.SET_CHECKOUT_CURRENT_STEP:
            return {
                ...state,
                checkoutCurrentStep: action.payload
            }

        case checkoutTypes.SET_ORDER:
            return {
                ...state,
                order: action.payload
            }

        case checkoutTypes.SET_PERSON_INFO:
            return {
                ...state,
                personInfo: action.payload
            }

        case checkoutTypes.SET_PENDING_DISCOUNT:
            return {
                ...state,
                pendingOrderDiscount: action.payload
            }
        
        case checkoutTypes.GET_USER_CURRENT_MEMBERSHIP:
            return {
                ...state,
                userCurrentMembershipInfo: action.payload
            }

        case checkoutTypes.SET_FREE_COUPON_PURCHASE:
            return {
                ...state,
                hasFreeCoupon: action.payload
            }

        case checkoutTypes.SET_COUPON_DATA:
            return {
                ...state,
                couponData: action.payload
            }

        case checkoutTypes.SET_CONCEPTOS:
            return {
                ...state,
                orderConcepts: action.payload
            }

        case checkoutTypes.SET_ORDER_QUANTITY:
            return {
                ...state,
                orderQuantity: action.payload
            }

        case checkoutTypes.SET_NEXT_PAYMENT_DATE:
            return {
                ...state,
                orderNextPaymentDate: action.payload
            }

        case checkoutTypes.SET_MEMBERSHIP_FEE_PLANS:
            return {
                ...state,
                membershipOrderFeePlans: action.payload
            }
        
        case checkoutTypes.SET_SELECTED_MEMBERSHIP:
            return {
                ...state,
                membershipOrderFeePlans: {
                    ...state.membershipOrderFeePlans,
                    selectedPlan: action.payload
                }
            }
        
        case checkoutTypes.SET_PERSON_ID_TO_ORDER:
            return {
                ...state,
                order: { ...state.order, person_id: action.payload }
            }

        case checkoutTypes.SET_MONTHLY_PAYMENTS_INFO:
            return {
                ...state,
                monthly_payments: action.payload
            }

        case checkoutTypes.SET_PAYMENT_PLAN:
            return {
                ...state,
                monthly_payments: {
                    ...state.monthly_payments,
                    selectedNumberOfPayments: action.payload
                }
            }

        default:
            return state;
    }
}
