import React, { useState } from 'react'

import { Collapse, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core'

import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import ExpandableAnswer from './ExpandableAnswer/ExpandableAnswer';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    expandableFAQRoot: {
        marginBottom: '12px',
    },
    listTitleContainer : {
        paddingBottom: 0,
        paddingTop: 0,
    },
    textColor:{
        color: theme.palette.colors.gray[400],
    },
    textColor2:{
        color: theme.palette.colors.gray[500],
    },
    listItemStyle:{
        // background: theme.palette.colors.fillStroke[400],
        borderRadius: "0.4em",
        '&.list-item-close':{
            borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`
        }
    }
}));

const ExpandableFAQ = ({ faqQuestion , faqAnswer}) => {

    const theme = useTheme();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    return (
        <Grid item xs={12} className={classes.expandableFAQRoot}>
            <List className={classes.listTitleContainer}>
                <ListItem button onClick={handleClick} className={clsx(classes.listItemStyle, {'list-item-close': !open})}>
                {
                    open 
                    ? <ExpandMoreSharpIcon
                        fontSize='inherit' className="mr-2"
                        style={{ color: theme.palette.colors.orange[300]}}
                    /> 
                    : <ChevronRightSharpIcon
                        fontSize='inherit' className="mr-2"
                        style={{ color: theme.palette.colors.orange[300]}}
                    /> 
                }
                    <ListItemText 
                        primary={
                            <Typography variant='body1' className={clsx(classes.textColor, 'semi-bold')}>
                                { faqQuestion  }
                            </Typography>
                        } 
                    />
                </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button>
                        <ExpandableAnswer
                            faqAnswer={ faqAnswer }
                        />
                    </ListItem>
                </List>
            </Collapse>
            </List>
        </Grid>
    )
}

export default ExpandableFAQ
