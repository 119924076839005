import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Card, CardContent, CardHeader, Divider, IconButton } from '@material-ui/core';
// import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SignupForm from './SignupForm/SignupForm';
import Toast from '../../../shared/components/Toast/Toast';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import * as paths from '../../../routes/paths'
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import { getLogoCard, LOGO_SOURCE } from '../../../shared/utils/imageSource';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        width: '100%',
        margin: '0px auto',
        height: 'fit-content',
        marginBottom: 56
    },
    card:{
        [theme.breakpoints.down('xs')]:{
            marginTop: 60,
        },
        backgroundColor: `${theme.palette.colors.fillStroke[400]} !important`,
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        marginTop: 56,
    },
    cardContent:{
        padding: '16px 80px !important',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]:{
            padding: '15px !important',
        }
    },
    signupSuccess: {
        maxWidth: '400px',
        width: '100%',
        padding: '32px 0px',
        margin: '0px auto',
        textAlign: 'center',
    },
    heading: {
        fontSize: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
        flexShrink: 0,
        padding: '24px 0px',
    },
    cardHeader: {
        padding: "16px 20px 0 0"
    },
    closeIcon: {
        fontSize: '24px',
        color: theme.palette.colors.darkBlack[200]
    },
    closeIconButton: {
        padding: '0px'
    },
    talisisLogo: {
        width: 120
    },
    tooltipIcon: {
        fontSize: 20
    }
}));

const Signup = props => {
    const classes = useStyles();
    const { match: { params: { referer_id } } } = props;
    const [toastProps, setToastProps] = React.useState({
        message: 'Por el momento no cuentas con acceso a la plataforma.',
        severity: 'error'
    });

    const [loading, setLoading] = React.useState(false);
    const [signupSucces, setSignupSucces] = React.useState(false);

    const handleSignupError = error => {
        setToastProps({ ...toastProps, open: true, message: error ? error : 'Por el momento no cuentas con acceso a la plataforma.' })
    }

    const handleSignupSuccess = (success, response, form) => {
        setSignupSucces(success);
        if (success) {
            eventTracker('s_signup_confirmation', eventData, ['mixpanel']);
        }
    }

    const hanldeFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    }

    const handleLoader = loader => {
        setLoading(loader);
    }

    React.useEffect(() => {
        eventTracker('s_signup', eventData, ['mixpanel']);
    }, []);

    return (
        <LoaderContent loading={loading}>
            <Box className={classes.root} mb={7}>
                {
                    !signupSucces &&
                        <Card elevation={0} className={`${classes.card}`}>
                            <CardHeader className={classes.cardHeader} action={
                                <IconButton component={RouterLink} to={'/'} className={classes.closeIconButton} >
                                    <i className={clsx(classes.closeIcon, 'ri-close-line')} />
                                </IconButton>
                            } />
                            <CardContent className={classes.cardContent}>
                            <img src={getLogoCard(LOGO_SOURCE.TALISIS)} className={classes.talisisLogo} />
                                <Box mb={4} mt={4} display="flex" alignItems="center" width='100%' justifyContent="center">
                                    <Typography variant="h4" className="text-gray-100 mr-2"  align="center">Crea tu cuenta gratis</Typography>
                                    <Tooltip title="Llena estos campos con los datos de la persona que utilizará la plataforma y su contenido." placement="right" arrow>
                                        <Icon className={`ri-error-warning-line text-warning-300 ${classes.tooltipIcon}`} />
                                    </Tooltip>
                                </Box>
                                <Typography className='text-gray-400' variant='body2'>Certifícate con cursos y programas con validez oficial, <br/>cuando quieras y en cualquier lugar.</Typography>
                                <Box mt={3} display="flex" justifyContent="left">
                                    <Typography variant="body1" color="textPrimary" className="mr-1">¿Ya tienes cuenta?</Typography>
                                    <Link component={RouterLink} variant="body1" color="secondary" to={paths.LOGIN}>Inicia sesión aquí</Link>
                                </Box>
                                <SignupForm onLoading={handleLoader} onSignupError={handleSignupError} onSignupSuccess={handleSignupSuccess} refererId={referer_id}/>
                                
                            </CardContent>
                            <Toast {...toastProps} onFinished={hanldeFinishedToast} />
                        </Card>
                    }
                {
                    signupSucces &&
                    <Card elevation={0} className={`${classes.card}`}>
                        <CardHeader className={classes.cardHeader} action={
                            <IconButton component={RouterLink} to={'/'} className={classes.closeIconButton} >
                                <i className={clsx(classes.closeIcon, 'ri-close-line')} />
                            </IconButton>
                        } />
                        <CardContent className={classes.cardContent}>
                            <img src={getLogoCard(LOGO_SOURCE.TALISIS)} className={classes.talisisLogo} />
                            <Box mt={5}>
                                <img src='/images/theme/signup-confirmation.svg' />
                                <Typography align='left' variant="h4" className="mt-4">
                                    !Listo!
                                </Typography>
                                <Typography align='left' variant='body2' className='text-gray-400 mt-2'>
                                    Te hemos enviado un email de confirmación con un enlace para verificar tu correo. ¡Revisa tu bandeja de entrada!
                                </Typography>
                                <Typography align='left' variant='body2' className='text-gray-400 mt-2'>
                                    Asegúrate de revisar tu carpeta de spam.
                                </Typography>
                            </Box>
                            <Divider className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" variant="fullWidth" />
                            <Box display="flex" mb={7}>
                                <Typography align='left' variant='body2' className='text-gray-100'>¿Necesitas ayuda?<span className='text-dark-black-200'> Haz clic en el icono "?" de abajo a la derecha</span></Typography>
                            </Box>
                        </CardContent>
                    </Card>
                }
            </Box>
        </LoaderContent>
    );
};

export default Signup;