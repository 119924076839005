export const ENROLLMENT_STATUS = {
    PENDING: 1,
    ADMITTED: 2,
    ENROLLED: 3,
    PAUSED: 4,
};

export const ENROLLMENT_STATUS_NAME = {
    [ENROLLMENT_STATUS.PENDING]: 'Pendiente',
    [ENROLLMENT_STATUS.ADMITTED]: 'Admitido',
    [ENROLLMENT_STATUS.ENROLLED]: 'Inscrito',
    [ENROLLMENT_STATUS.PAUSED]: 'Pausado',
};
