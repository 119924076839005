/**
 * Compare a given array of substrings with the given string.
 *
 * @param {string} needle
 * @param {array|string} haystack
 *
 * @return {array}
 */
export const stringContainsAnyOf = (needle, haystack) =>
[].concat(haystack).find(substring => needle.includes(substring))

/**
 * Compare a given array of substrings with the given string.
 *
 * @param {string} needle
 * @param {array|string} haystack
 *
 * @return {array}
 */
export const stringContainsExactlyOf = (needle, haystack) =>
[].concat(haystack).find(substring => needle === substring)


/**
 *  Compare between JSON
 *
 *  @param {json}
 *  @param {json}
 *
 *  @return {json}
 * **/
export const compareFirstInSecondJSON = (firstObject, secondObject) => {
  const keys = Object.keys(firstObject)
  const result = {}

  if (firstObject && secondObject) {
    for (let i = 0; i < keys.length; i += 1) {
      let value = firstObject[keys[i]]

      if (value === ' ') {
        value = null
      }
      if(value instanceof Object && secondObject[keys[i]] instanceof  Object){
        if(Object.keys(value).length > 0 && Object.keys(secondObject[keys[i]]).length > 0){
          compareFirstInSecondJSON(value,secondObject[keys[i]])
        }
      }
      else if (value !== secondObject[keys[i]]) {
        result[keys[i]] = value
      }
    }
  }

  if (firstObject && !secondObject) {
    for (let i = 0; i < keys.length; i += 1) {
      result[keys[i]] = firstObject[keys[i]]
    }
  }

  return result
}

/**
 *  Compare between arrays
 *
 *  @param {array}
 *  @param {array}
 *
 *  @return {array}
 * **/
export const compareFirstInSecondArray = (firstArr, secondArr) => {
  let result = []
  if(firstArr.length > 0 && secondArr.length > 0 ){
    firstArr.forEach(it1 => {
      const index  = secondArr.findIndex(c=>c.id=== it1.id)
      if(index > -1){
        if(Object.keys(compareFirstInSecondJSON(it1,secondArr[index])).length > 0){
          result.push(it1)
        }
      }
      else{
        result.push(it1)
      }
      // secondArr.forEach(it2 =>{
      //   if(it1.id === it2.id){
      //     if(Object.keys(compareFirstInSecondJSON(it1,it2)).length > 0){
      //       result.push(it1)
      //     }
      //   }
      // })

    })
  }
  return result
}
