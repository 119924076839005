import React from 'react';
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import useStyles from '../../../assets/styles/jss/components/titleLinkStyle'
import clsx from 'clsx';
import { Hidden, Icon, useMediaQuery } from '@material-ui/core';


const TitleLink = (props) => {
    const {textPrimary, textSecondary, variant, className, hideSubtitleResponsive} = props;
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles();
    
    return (
        <Box className={classes.root}>
            <Button disableRipple endIcon={(!isResponsive || !hideSubtitleResponsive)&& <ChevronRightIcon  className={classes.buttonIcon}/>} className={clsx(className, 'text-decoration-none', classes.button)} onClick={props.onClick} color="primary">
                <Typography variant={variant} color="textPrimary" className={classes.textPrimary} >{textPrimary}</Typography>
                {
                    !(isResponsive && hideSubtitleResponsive) &&
                    <Box>
                        <Box className={classes.boxSecondary}>
                            <Typography variant="body1" color="secondary" className="subtitle semi-bold" >{textSecondary}</Typography>
                            <Hidden smUp>
                                <Icon className='ri-arrow-right-s-line font-size-24 text-orange-300' />
                            </Hidden>
                        </Box>
                    </Box>
                }
            </Button>
        </Box>

    );
};


TitleLink.defaultProps = {
    variant: 'h3',
    hideSubtitleResponsive: false
};

TitleLink.propTypes = {
    textPrimary: PropTypes.string.isRequired,
    textSecondary: PropTypes.string.isRequired,
    variant:PropTypes.oneOf([
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
    ]),
    onClick: PropTypes.func
};

export default TitleLink;