import * as membershipTypes from '../types/membershipTypes';
import {MarketPlaceService, ENVIRONMENT} from '@sdk-point/talisis';
// import * as userService from '../../services/UserService';
import routelinks from '../../routes/routelinks';
import cloneArray from '../../shared/utils/cloneArray';
import { log } from '../../shared/utils/console.js'

export const applyMembership  = (membership) => (dispatch) => {
    dispatch({
        type: membershipTypes.APPLY_MEMBERSHIP,
        payload: membership
    });
};

export const getUserMembership = (user) => (dispatch) => {
    try
    {
        const marketService=new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
        marketService.getPersonMembership(user.person_id).then(response => {
            dispatch(applyMembership(response?.user_membership?.actual_membership));
        });
    }
    catch(e)
    {
        log("no cargado");
    }
};

