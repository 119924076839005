import React from 'react';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Fade, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Hidden } from '@material-ui/core';
import { Check, Close, Info } from '@material-ui/icons';
import TableContentHHResponsive from './components/TableContentHHResponsive';
import {benefitsList} from '../../../shared/constants/brands-landing-page'
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';

const MODAL_IMG = 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/harmonhall/programs/modal_detail.svg';

const useStyles = makeStyles((theme) => ({
    root:{
        padding: '120px 0px 118px',
        [theme.breakpoints.down('sm')]:{
            padding: '0px 0px 40px'
        },
        [theme.breakpoints.up('sm')]:{
            background: theme.palette.colors.fillStroke[400],
        },
    },
    container:{
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]:{
            display: 'flex',
            flexDirection: 'column'
        },
    },
    table: {
        background: 'transparent',
        borderRadius: '8px',
        margin: '0 auto',
        padding: '1px',
        width: '80%',
    },
    border: {
        border: '0.25px solid rgba(255, 255, 0, 0.8)',
    },
    table: {
        margin: 'auto',
        minWidth: 'max-content',
        width: '99.5%',
    },
    tableTh: {
        background: '#111111',
    },
    label: {
        color: theme.palette.colors.black,
    },
    tableTitle: {
        background: 'rgba(255, 255, 0, 0.03)',
        border: 0,
        borderRight: '0.25px solid rgba(255, 255, 0, 0.8)',
        width: '170px',
    },
    tableDescription: {
        border: 0,
        width: '30%',
    },
    tableBody: {
        boxShadow: '0 0 0 1px rgb(255 255 0 / 80%)',
        borderRadius: '16px',
    },
    tableBodyV2: {
        boxShadow: '0 0 0 1px rgb(255 255 0 / 80%)',
        borderRadius: '16px 0 16px 16px',
    },
    tableCell: {
        border: 0,
        width: `${50 / 3}%`,
        '& .standar': {
            background: '#F5F5F5',
            borderRadius: '16px 16px 0px 0px',
            font: theme.palette.colors.black,
        },
        '& .plus': {
            background: '#FFFF00',
            borderRadius: '16px 16px 0px 0px',
        },
        '& .elite': {
            background: '#0071CE',
            borderRadius: '16px 16px 0px 0px !important',
        },
    },
    lastChild: {
        borderBottomRightRadius: '16px !important',
    },
    pair: {
        background: theme.palette.colors.grey[500],
    },
    checkPlus: {
        color: '#FFFF00',
    },
    checkElite: {
        color: '#0071CE',
    },
    info: {
        color: '#FFFF00',
        verticalAlign: 'bottom',
    },
    modal: {
        backgroundColor: theme.palette.colors.grey[400],
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        height: 'calc(40vw + 8%)',
        margin: '0 auto',
        top: '10% !important',
        verticalAlign: 'middle',
        width: '70%',
    },
    modalLabel: {
        borderBottom: '1px solid rgba(119, 119, 119, 0.15)',
        height: '2em',
        textAlign: 'center',
    },
    modalImageContainer: {
        textAlign: 'center',
    },
    modalImage: {
        position: 'relative',
        width: '95%',
    },
    closeModal: {
        top: '1%',
        float: 'right',
        position: 'absolute',
        right: '1vw',
    },
}));

const BenefitsTableContentHH = (props) => {
    const { className } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const CoursesLevelModal = () => {
        return (
            <Modal
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div>
                        <Typography variant="h4" align="left" color="textPrimary" className={`${classes.modalLabel} mt-3 mb-3`}>
                            Cursos por nivel
                            <IconButton color="inherit" className={classes.closeModal} onClick={() => { setOpen(false) }}><Close /></IconButton>
                        </Typography>
                        <div className={`${classes.modalImageContainer} mt-5`}><img src={MODAL_IMG} className={classes.modalImage} /></div>
                    </div>
                </Fade>
            </Modal>
        );
    };

    return (
        <ResponsiveContainer className={classes.root}>
                <Hidden smDown>
                    <Typography id="detail" variant="h4" align="center" color="textPrimary" className={className}>Beneficios de nuestras licencias</Typography>
                    <TableContainer className={`${classes.table} mt-5`}>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableCell}>
                                <TableRow>
                                    <TableCell className={classes.bg}>&nbsp;</TableCell>
                                    <TableCell align="center" className={`${classes.tableDescription} ${classes.bg}`}>
                                        <Typography variant="body2" component="span" color="textSecondary">CARACTERÍSTICAS</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} standar`}>
                                        <Typography variant="h4" component="span" className={classes.label}>Standard</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} plus`}>
                                        <Typography variant="h4" component="span" className={classes.label}>Plus</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} elite`}>
                                        <Typography variant="h4" component="span">Elite</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBodyV2}>
                                <TableRow>
                                    <TableCell align="center" rowSpan={9} className={classes.tableTitle}>Curso de Inglés</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={classes.tableDescription}>
                                        <Typography variant="body2" component="span" color="textSecondary">Disponibilidad 24/7</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={`${classes.tableDescription} ${classes.pair}`}>
                                        <Typography variant="body2" component="span" color="textSecondary">21 Unidades Harmon Hall <IconButton onClick={handleClick}><Info className={classes.info} /></IconButton></Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={classes.tableDescription}>
                                        <Typography variant="body2" component="span" color="textSecondary">Más de 50 Unidades de Interés</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={`${classes.tableDescription} ${classes.pair}`}>
                                        <Typography variant="body2" component="span" color="textSecondary">Recursos de práctica</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={classes.tableDescription}>
                                        <Typography variant="body2" component="span" color="textSecondary">Guía gramatical</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={`${classes.tableDescription} ${classes.pair}`}>
                                        <Typography variant="body2" component="span" color="textSecondary">Medición de avances</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={classes.tableDescription}>
                                        <Typography variant="body2" component="span" color="textSecondary">Exámenes continuos</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={`${classes.tableDescription} ${classes.pair}`}>
                                        <Typography variant="body2" component="span" color="textSecondary">App Móvil</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair} ${classes.lastChild}`}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table className={`${classes.table} mt-2`}>
                            <TableBody className={classes.tableBody}>
                                <TableRow>
                                    <TableCell align="center" rowSpan={5} className={classes.tableTitle}>Clases privadas</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={classes.tableDescription}>
                                        <Typography variant="body2" component="span" color="textSecondary">Clase 1 a 1</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.lastChild}`}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={`${classes.tableDescription} ${classes.pair}`}>
                                        <Typography variant="body2" component="span" color="textSecondary">Introducción Personalizada</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={classes.tableDescription}>
                                        <Typography variant="body2" component="span" color="textSecondary">Ses. personalizadas al mes</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><span className={classes.checkPlus}>6</span></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><span className={classes.checkElite}>13</span></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={`${classes.tableDescription} ${classes.pair}`}>
                                        <Typography variant="body2" component="span" color="textSecondary">Ses. personalizadas al año</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><span className={classes.checkPlus}>72</span></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair} ${classes.lastChild}`}><span className={classes.checkElite}>156</span></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table className={`${classes.table} mt-2 mb-1`}>
                            <TableBody className={classes.tableBody}>
                                <TableRow>
                                    <TableCell align="center" rowSpan={3} className={classes.tableTitle}>Salas de conversación</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={classes.tableDescription}>
                                        <Typography variant="body2" component="span" color="textSecondary">Sesiones grupales</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check /></TableCell>
                                    <TableCell align="center" className={classes.tableCell}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.lastChild}`}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className={`${classes.tableDescription} ${classes.pair}`}>
                                        <Typography variant="body2" component="span" color="textSecondary">Sesiones ilimitadas</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair}`}><Check className={classes.checkPlus} /></TableCell>
                                    <TableCell align="center" className={`${classes.tableCell} ${classes.pair} ${classes.lastChild}`}><Check className={classes.checkElite} /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <CoursesLevelModal />
                </Hidden>

                <Hidden mdUp>
                    <Typography id="detail" variant="h2" align="left" color="textPrimary">Beneficios de nuestras Licencias</Typography>
                    <TableContentHHResponsive list={benefitsList}/>
                </Hidden>
        </ResponsiveContainer>
    )
};

export default BenefitsTableContentHH;