import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import clsx from 'clsx';
import SimpleTabs from '../../../../shared/components/Tabs/Tabs';
import { log } from '../../../../shared/utils/console.js'
import image from '../../../../assets/images/woman_search_file.svg';

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return ({
      customerSevrviceIcon: {
        fontSize: 32,
        color: theme.palette.white,
        display: 'flex'
      },
      ticketList: {
        padding: '8px 16px',
        maxHeight: '350px',
        overflowY: 'scroll'
      },
      ticketListItem: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        padding: 16,
        marginTop: 16,
        borderRadius: '4px'
      },
      ticketTextHolder: {
        '& p': {
          marginTop: '8px'
        }
      },
      ticketSubject:{
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '16px'
      },
      ticketData: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        color: theme.palette.colors.gray[500],
        height: '16px'
      },
      ticketStatusWarning:{
        color: theme.palette.colors.warning[300],
        display: 'inline',
        fontSize: '14px',
      },
      ticketStatusBlue:{
        color: theme.palette.colors.warning[300],
        display: 'inline',
        fontSize: '14px',
      },
      ticketStatusSuccess:{
        color: theme.palette.colors.success[300],
        display: 'inline',
        fontSize: '14px',
      },
      ticketNumber: {
        color: theme.palette.colors.blue[300],
        display: 'inline',
        fontSize: '14px',
      },
      ticketDate: {
        display: 'inline',
        fontSize: '14px',
      },
      tabs: {
        '> .MuiTabs-root': {
          marginBottom: '10PX'
        }
      }
    })
  });

const TicketsList = (props) => {
  const classes = useStyles();
  const ticketStatus = {
    new: {
      label: 'Nuevo',
      color: 'ticketStatusWarning'
    },
    open: {
      label: 'Abierto',
      color: 'ticketStatusWarning'
    },
    pending: {
      label: 'Pendiente',
      color: 'ticketStatusBlue'
    },
    closed: {
      label: 'Cerrado',
      color: 'ticketStatusSuccess'
    },
    solved: {
      label: 'Resuelto',
      color: 'ticketStatusSuccess'
    }
  };

  const {
    ticketList,
    handleShowTicket,
    LoaderContent,
    loading,
    openTickets,
    closedTickets
  } = props
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    log(openTickets)
    log(closedTickets)
  }, []);
  
  const onTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  // const classes = useStyles();
  return (
    <LoaderContent loading={loading}>
      <Box className={classes.tabs}>
        <SimpleTabs activeTab={activeTab} tabs={["Abiertos", "Cerrados"]} onChange={onTabChange}>
          <List className={classes.ticketList}>
            { 
              activeTab === 0 &&
                openTickets.length ?
                  openTickets.map(ticket => (
                    <ListItem key={ticket.id} role={undefined} dense button className={classes.ticketListItem} onClick={() => handleShowTicket(ticket.id)}>
                      <ListItemIcon>
                        <i className={clsx(classes.customerSevrviceIcon, 'ri-customer-service-2-line')} />
                      </ListItemIcon>
                      <ListItemText 
                        className={classes.ticketTextHolder}
                        primary={<Typography component={'span'} className={classes.ticketSubject}>Asunto: {ticket.subject}</Typography>}
                        secondary={<>
                          <Typography component={'span'} className={classes.ticketData}>
                            Estatus: <Typography component={'span'} className={classes[ticketStatus[ticket.status].color]}>{ticketStatus[ticket.status].label}</Typography>
                          </Typography>
                          <Typography component={'span'} className={classes.ticketData}>Ticket: <Typography component={'span'} className={classes.ticketNumber}>#{ticket.id}</Typography></Typography>
                          <Typography component={'span'} className={classes.ticketData}>Modificado: <Typography component={'span'} className={classes.ticketDate}>{ticket.updated_at}</Typography></Typography>
                        </>}
                      />
                      <ListItemSecondaryAction><i className={clsx(classes.customerSevrviceIcon, 'ri-arrow-right-s-line')} /></ListItemSecondaryAction>
                    </ListItem>
                  ))
                :
                activeTab === 0 &&
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems={"center"} className="my-4">
                    <Box display="flex" justifyContent="center" className="mb-4">
                        <img alt="No cuentas con tickets abiertos" src={image} draggable="false" style={{ maxWidth: 212 }} />
                    </Box>
                    <Box display="flex" maxWidth={155} justifyContent="center" textAlign="center" flexDirection="column">
                        <Typography variant="subtitle1" className="mb-1">No cuentas con tickets abiertos</Typography>
                    </Box>
                </Box>
            }
            { 
              activeTab === 1 &&
                closedTickets.length ?
                closedTickets.map(ticket => (
                  <ListItem key={ticket.id} role={undefined} dense button className={classes.ticketListItem} onClick={() => handleShowTicket(ticket.id)}>
                    <ListItemIcon>
                      <i className={clsx(classes.customerSevrviceIcon, 'ri-customer-service-2-line')} />
                    </ListItemIcon>
                    <ListItemText 
                      className={classes.ticketTextHolder}
                      primary={<Typography component={'span'} className={classes.ticketSubject}>Asunto: {ticket.subject}</Typography>}
                      secondary={<>
                        <Typography component={'span'} className={classes.ticketData}>
                          Estatus: <Typography component={'span'} className={classes[ticketStatus[ticket.status].color]}>{ticketStatus[ticket.status].label}</Typography>
                        </Typography>
                        <Typography component={'span'} className={classes.ticketData}>Ticket: <Typography component={'span'} className={classes.ticketNumber}>#{ticket.id}</Typography></Typography>
                        <Typography component={'span'} className={classes.ticketData}>Modificado: <Typography component={'span'} className={classes.ticketDate}>{ticket.updated_at}</Typography></Typography>
                      </>}
                    />
                    <ListItemSecondaryAction><i className={clsx(classes.customerSevrviceIcon, 'ri-arrow-right-s-line')} /></ListItemSecondaryAction>
                  </ListItem>
                ))
              :
              activeTab === 1 &&
              <Box display="flex" justifyContent="center" flexDirection="column" alignItems={"center"} className="my-4">
                  <Box display="flex" justifyContent="center" className="mb-4">
                      <img alt="No cuentas con tickets abiertos" src={image} draggable="false" style={{ maxWidth: 212 }} />
                  </Box>
                  <Box display="flex" maxWidth={155} justifyContent="center" textAlign="center" flexDirection="column">
                      <Typography variant="subtitle1" className="mb-1">No cuentas con tickets cerrados</Typography>
                  </Box>
              </Box>
            }
          </List>
        </SimpleTabs>
      </Box>
    </LoaderContent>
  )
};

TicketsList.defaultProps = {
  isHome: false,
  isCheckout: false,
};

TicketsList.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};

export default TicketsList;
