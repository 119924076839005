import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// @material-ui/icons
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

// style
import useStyles from '../../../assets/styles/jss/components/incrementButtonStyle';
import clsx from 'clsx';

const IncrementButton = ({label, initialCounter = 0, onClickDecrease, onClickIncrease, min, value = 0, readOnly = false}) => {
    const classes = useStyles();
    const [counter, setCounter] = useState(initialCounter);

    useEffect(() => {
        setCounter(value);
    }, [value]);

    const increase = () => {
        onClickIncrease(Number(counter + 1));
        setCounter(counter + 1);
    }
    
    const decrease = () => {
        if(counter > min){
            onClickDecrease(Number(counter - 1));
            setCounter(counter - 1);
        }
    }

    return (
        <Box>
            <Typography>{label}</Typography>
            <ButtonGroup disabled={readOnly} size="small" color="primary" className={classes.root}>
                <Button onClick={decrease} className={clsx({ 'disabled': counter === min }, classes.decrement)}><RemoveIcon /></Button>
                <Button className={classes.quantity}>{counter}</Button>
                <Button className={classes.increment} onClick={increase}><AddIcon /></Button>
            </ButtonGroup>
        </Box>
    );
};

IncrementButton.defaultProps = {
    counter: 0,
};

IncrementButton.propTypes = {
    label: PropTypes.string,
    initialCounter: PropTypes.number,
    min: PropTypes.number,
    onClickDecrease: PropTypes.func,
    onClickIncrease: PropTypes.func,
};

export default IncrementButton;