import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Divider, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import PasswordForm from './PasswordForm/PasswordForm';
import Toast from '../../../../../shared/components/Toast/Toast';
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent';
import { log } from '../../../../../shared/utils/console.js'
import { getLogoCard } from '../../../../../constants/logosImages';

//SDK
import { MIXPANEL_EVENT, SingupService } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';

const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        width: '100%',
        margin: '0px auto',
        height: '100vh'
    },
    card:{
        [theme.breakpoints.down('xs')]:{
            marginTop: 60,
        },
        backgroundColor: `${theme.palette.colors.fillStroke[400]} !important`,
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        marginTop: 56,
    },
    cardContent:{
        padding: '16px 80px !important',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]:{
            padding: '15px !important',
        }
    },
    cardHeader: {
        padding: "16px 20px 0 0"
    },
    closeIcon: {
        fontSize: '24px',
        color: theme.palette.colors.darkBlack[200]
    },
    closeIconButton: {
        padding: '0px',
        float: 'right'
    },
    talisisLogo: {
        width: 120
    },
    signin: {
        fontSize: '12px',
    },
    link: {
        color: theme.palette.colors.orange[300]
    }
}));

const Password = (props) => {
    let history = useHistory();
    const location = useLocation();
    const { match: { params: { token } } } = props;
    const classes = useStyles();
    const [toastProps, setToastProps] = React.useState({
        message: 'Por el momento no cuentas con acceso a la plataforma.',
        severity: 'error'
    });
    const [resetPassword, setResetPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [passwordSucces, setPasswordSucces] = React.useState(false);

    const handlePasswordError = error => {
        setToastProps({ ...toastProps, open: true, message: error ? error : 'Por el momento no cuentas con acceso a la plataforma.' })
    }

    const handlePasswordSuccess = success => {
        setPasswordSucces(success);
        if (success) {
            eventTracker('set_password', {}, ['mixpanel']);
        }
    }

    const hanldeFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    }

    const handleLoader = loader => {
        setLoading(loader);
    }

    const sleep = time => {
        return new Promise(resolve => setTimeout(resolve, time))
    }

    const handleValidateToken = async () => {
        /*
        if (location.search.includes('reset')) {
            setResetPassword(true);
            eventTracker('s_actualiza_contraseña', {}, ['mixpanel']);
        }
        else {
            setResetPassword(false)
        }
        */
        try {
            await SingupServiceSDK.validateToken(token, handleLoader, handlePasswordError).then(tokenIsValid => {
                if (tokenIsValid) {
                    //Ahora aqui validar para setear setResetPassword
                    log('token valido')
                } else {
                    handlePasswordError('El enlace ha coducado o es incorrecto')
                    sleep(5000).then(r => {
                        history.push('/login')
                    })
                }
            })
                .catch()
                .finally();
        }
        catch (error) {
            log(error);
        }
    }

    React.useEffect(() => {
        handleValidateToken();
        eventTracker('s_setpassword', {}, ['mixpanel']);
    }, []);

    return (
        <Box className={classes.root}>
            <LoaderContent loading={loading}>
                <Card elevation={0} className={`${classes.card}`}>
                    <CardHeader className={classes.cardHeader} action={
                        <>
                            <IconButton className={classes.closeIconButton} component={RouterLink} to={'/'}>
                                <i className={`${classes.closeIcon} ri-close-line`} />
                            </IconButton>
                        </>
                    } />
                    <CardContent className={classes.cardContent}>
                        <img src={getLogoCard(7)} className={classes.talisisLogo} />
                        {
                            //<Typography variant="body1" align={'left'} className={'semi-bold text-gray-100 mb-4 mt-4'}>{!resetPassword ? 'Activa tu cuenta' : 'Ingresa tu nueva contraseña'}</Typography>
                        }
                        <Typography variant="body1" align={'left'} className={'semi-bold text-gray-100 mb-4 mt-4'}>{'Asigna tu contraseña'}</Typography>
                        {!resetPassword && <Typography variant="subtitle2" align={'left'} color="textSecondary">Crea tu contraseña para ingresar a la plataforma</Typography>}
                        <PasswordForm resetPassword={resetPassword} onLoading={handleLoader} token={token} onPasswordError={handlePasswordError} onPasswordSuccess={handlePasswordSuccess} />
                        {/* <Divider className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" variant="fullWidth" />
                        <Box display="flex" justifyContent="center" mb={7}>
                        <Typography variant="body1" color="textPrimary" className="mr-1">¿Ya tienes una cuenta?</Typography>
                            <Link component={RouterLink} variant="body1" color="secondary" to="/login">Inicia sesión aquí</Link>
                        </Box> */}
                    </CardContent>
                </Card>
                <Toast {...toastProps} onFinished={hanldeFinishedToast} />
            </LoaderContent>
        </Box>
    );
};

export default Password;