import React, { useEffect, useState } from 'react';

// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";

// redux
import { Grid } from '@material-ui/core';
//SDK
import { ConsultanciesService } from '@sdk-point/talisis'; 

const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const ProfilePicUsers= (props) => {

    const [profilepic, setprofilepic] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProfileData();
    }, []);

    const getProfileData = async () => {
        const values = await ConsultanciesServiceSDK.getProfilePicUsers(props.email)
        setprofilepic(values)
        setLoading(true);
    }
    return (
        <Grid item>
            {
                loading ? 
                <Avatar
                    alt="Person"
                    src={`data:image/jpeg;charset=utf-8;base64,${profilepic}`}
                    {...props}
                />
                :
                <Avatar
                    alt="Person"
                    {...props}
                />
            }
        </Grid>
    );
};

export default ProfilePicUsers;
