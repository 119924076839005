import React, { lazy, Suspense } from 'react';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// core components
import Loader from '../../../shared/components/Loader/LoaderContent';
// styles
import { makeStyles } from '@material-ui/core/styles';

import { convertOuToSource }from '../../../../src/constants/convertOuToSource';

// services
import { CourseService } from '@sdk-point/talisis';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import { log } from '../../../shared/utils/console.js'

const CourseProgressCardBody = lazy(() => import(/* webpackChunkName: "CourseProgressCardBody" */'./components/CourseProgressCardBody'));


const useStyles = makeStyles((theme) => ({
    root:{
        maxWidth: "1300px",
        width: "100vw",
        marginLeft: "auto", 
        marginRight: "auto",
    },
    card: {
        padding: "12px",
    },
    card_content:{
        width: "286px",
        minHeight: "315px",
        maxHeight: "315px",
        backgroundColor: "#1c1f24",
        "&:hover": {
            cursor:"pointer",
            "& $continuar_content": {
                opacity: "1",
                transition: "0.5s",
            },
        },
        
    },
    schedule:{
        display: 'flex',
        justifyContent: 'space-between',
        '& span':{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 400
        }
    },
    chip:{
        height: 20
    },
    cardActionArea:{
        
    },
    focusHighlight: {
       
    },
    title:{
        marginLeft: "79px",
        width: "100%",
        "@media (max-width: 1250px)": {
            marginLeft: "5px",
        },
    },
    continuar_content:{
        transition: "0.5s",
        opacity: "0",
        width: "74px",
        border: "1px solid #FFFFFF",
        padding: "8px",
        paddingLeft: "16px",
        position: "absolute",
        paddingRight: "16px",
        zIndex: "10",
        left: "0",
        right: "0",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        top: "0",
        bottom: "0",
        height: "38px",
        textAlign: "center",
        borderRadius: "40px",
    },
    continuar_text:{
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
    },
    hover_carousel:{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        boxSizing: "border-box",
        maxWidth: "1248px",
        width: "100%",
        '&:hover':{
            '& h4':{
                '& button':{
                    background: "transparent",
                    color: theme.palette.primary.main + " !important",
                    '& .subtitle':  {
                        color: theme.palette.secondary.main + " !important",
                        position: 'static !important',
                        transition: '0.35s ease-in-out !important',
                        transform: "translate3d(4px, 0px, 0px) !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                    } ,
                    '& .MuiButton-endIcon':{
                        color: theme.palette.secondary.main + " !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        transform: "translate3d(0px, 0px, 0px) scale(1) !important",
                        transition: '0.4s ease-in-out opacity 0.2s linear !important',
                    },
                },  
            },
        },
        "@media (max-width: 1247px)": {
            maxWidth: "936px",
        },
        "@media (max-width: 935px)": {
            maxWidth: "625px",
        },
        "@media (max-width: 768px)": {
            '& h4':{
                '& button':{
                    background: "transparent",
                    '& .subtitle':  {
                        color: theme.palette.secondary.main + " !important",
                        position: 'static !important',
                        transition: '0.35s ease-in-out !important',
                        transform: "translate3d(4px, 0px, 0px) !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        minWidth: "62px",
                    } ,
                    '& .MuiButton-endIcon':{
                        color: theme.palette.secondary.main + " !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        transform: "translate3d(0px, 0px, 0px) scale(1) !important",
                        transition: '0.4s ease-in-out opacity 0.2s linear !important',
                    },
                },  
            },
        },
        "@media (max-width: 623px)": {
            maxWidth: "fit-content",
        },
    },
}));

const CardsProgress = (props) =>  {
    const classes = useStyles();
    const { user } = props;
    const [loading, setLoading] = React.useState(false);
    const [charge, setCharge] = React.useState(false);
    const [courses, setCourses] = React.useState([]);
    const [ecCourses, setEcCourses] = React.useState([]);
    const [programCourses, setProgramCourses] = React.useState([]);
    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

    const getData = async () => {
        try {
            setLoading(true);
            const data = await courseService.getPersonCoursesInProgress(user.person_id);
            let coursesTemp = [];
            let coursesEC = [];
            let coursesProgram = [];
            let coursesData = [];
            if(data?.response?.length > 0){
                coursesData = data.response.slice();
                const temp = coursesData?.map(item =>{
                    if(item?.level_id == "EC"){
                        coursesEC.push(item);
                    }else if(item?.level_id == "Otros"){
                        coursesTemp.push(item);
                    }else{
                        coursesProgram.push(item);
                    }
                });
                return {ec: coursesEC, programs: coursesProgram, otros: coursesTemp };
            }
            return {ec: [], programs: [], otros: [] };
        } catch (e) {
            log(e.message);
        }
    };

    React.useEffect(() => {
        let isCanceled = true;
        getData().then((data)=>{
            if(isCanceled){
                setCourses(data?.otros);
                setEcCourses(data?.ec);
                setProgramCourses(data?.programs);
                setLoading(false);
                setCharge(true);
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, []);

    return (
        <Loader loading={loading} transparent={true} minHeight="500px">
            <Box className={classes.root}>
                
                <Box flexDirection="column" display="flex" style={{
                    width: "fit-content",
                    justifyContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%"
                 }}>
                    { courses?.length > 0 || ecCourses?.length > 0 || programCourses?.length > 0 ?
                        <>
                        {programCourses?.length > 0 && <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{width: "100%", alignItems: "flexStart"}}>
                        {  programCourses.map((program, index) => {
                            return (<React.Fragment key={program.id}>
                                <Typography className={classes.title} variant="h4">{(program.company_id == "UERRE" ? "U-ERRE": program.company_id == "TAL" ? "TALISIS" : program.company_id == "IE" ? "IESALUD" :  program.company_id == "HH" ? "Harmon Hall": program.company_id == "ADV" ? "ADVENIO": program.company_id == "UNID" ? "UNID" : "OTROS") + " - " +  program.name}</Typography>
                                <Box position="relative" className={classes.hover_carousel}>
                                    {
                                        program?.courses.length > 0 && program.courses.map((item, index) => {
                                            let courseItem = JSON.parse(item);
                                            return (<Grid key={courseItem?.course_id} className={classes.card}>
                                                <Suspense fallback={<div></div>}>
                                                    <CourseProgressCardBody
                                                        course_url_neo={courseItem?.course_url_neo}
                                                        source_id={courseItem?.source_id} 
                                                        course_url={courseItem?.course_url}
                                                        noCarusel={true}
                                                        course_id={courseItem?.course_id}  
                                                        company_id={courseItem?.company_id}
                                                        enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                        course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                        index={index}
                                                        user={user}
                                                        person_id={user?.person_id}
                                                        progress_percentage={courseItem?.progress_percentage == null ? 0: courseItem?.progress_percentage}
                                                        media={{
                                                            isProgress: true,
                                                            index: index,
                                                            source:{
                                                                id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                icon: 'desktop_windows',
                                                                modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                            },
                                                            image:  courseItem?.image_url,
                                                            company_id: courseItem?.company_id,
                                                        }}
                                                        content={{
                                                            isProcess: true,
                                                            course_id: courseItem?.course_id,
                                                            progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                            enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                            title: courseItem.name,
                                                            hiddeLabel: true,
                                                        }} 
                                                    />
                                                </Suspense>
                                            </Grid>)
                                        })
                                    }
                                </Box>
                                </React.Fragment>)
                            })}
                            </Box>
                        }

                        {ecCourses?.length > 0 &&
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{width: "100%", alignItems: "flexStart"}}>
                            {  ecCourses.map((ecCourse, index) => {
                                return (<React.Fragment key={ecCourse.id}>
                                    <Typography className={classes.title} variant="h4">{ecCourse.name != "Educación Continua Alianzas" ? (ecCourse.company_id == "UERRE" ? "U-ERRE": ecCourse.company_id == "TAL" ? "TALISIS" : ecCourse.company_id == "IE" ? "IESALUD" :  ecCourse.company_id == "HH" ? "Harmon Hall": ecCourse.company_id == "ADV" ? "ADVENIO": ecCourse.company_id == "UNID" ? "UNID" : "OTROS") + " - " + ecCourse.name : ecCourse.name}</Typography>
                                    <Box position="relative" className={classes.hover_carousel}>
                                        {
                                            ecCourse?.courses.map((item, index) => {
                                                let courseItem = JSON.parse(item);
                                                return (<Grid key={courseItem?.course_id} className={classes.card}>
                                                    <Suspense fallback={<div></div>}>
                                                        <CourseProgressCardBody
                                                            course_url_neo={courseItem?.course_url_neo}
                                                            source_id={courseItem?.source_id} 
                                                            course_url={courseItem?.course_url}
                                                            noCarusel={true}
                                                            course_id={courseItem?.course_id}  
                                                            company_id={courseItem?.company_id}
                                                            enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                            course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                            index={index}
                                                            user={user}
                                                            person_id={user?.person_id}
                                                            progress_percentage={courseItem?.progress_percentage == null ? 0: courseItem?.progress_percentage}
                                                            media={{
                                                                isProgress: true,
                                                                index: index,
                                                                source:{
                                                                    id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                    icon: 'desktop_windows',
                                                                    modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                                },
                                                                image:  courseItem?.image_url,
                                                                company_id: courseItem?.company_id,
                                                            }}
                                                            content={{
                                                                isProcess: true,
                                                                course_id: courseItem?.course_id,
                                                                progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                                enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                                title: courseItem.name,
                                                                hiddeLabel: true,
                                                            }} 
                                                        />
                                                    </Suspense>
                                                </Grid>)
                                            })
                                        }
                                    </Box>
                                </React.Fragment>)})
                            }            
                            </Box>
                        }


                        {courses[0]?.courses?.length > 0 &&
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{width: "100%", alignItems: "flexStart"}}>
                            <Typography className={classes.title} variant="h4">{"Otros cursos"}</Typography>
                                <Box position="relative" className={classes.hover_carousel}>
                                    {
                                        courses[0].courses.map((item, index) => {
                                            let courseItem = JSON.parse(item);
                                            return (<Grid key={courseItem?.course_id} className={classes.card}>
                                                <Suspense fallback={<div></div>}>
                                                    <CourseProgressCardBody
                                                        course_url_neo={courseItem?.course_url_neo}
                                                        source_id={courseItem?.source_id} 
                                                        course_url={courseItem?.course_url}
                                                        noCarusel={true}
                                                        course_id={courseItem?.course_id}
                                                        company_id={courseItem?.company_id}
                                                        enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                        course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                        index={index}
                                                        user={user}
                                                        person_id={user?.person_id}
                                                        progress_percentage={courseItem?.progress_percentage == null ? 0: courseItem?.progress_percentage}
                                                        media={{
                                                            isProgress: true,
                                                            index: index,
                                                            source:{
                                                                id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                icon: 'desktop_windows',
                                                                modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                            },
                                                            image:  courseItem?.image_url,
                                                            company_id: courseItem?.company_id,
                                                        }}
                                                        content={{
                                                            isProcess: true,
                                                            course_id: courseItem?.course_id,
                                                            progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                            enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                            title: courseItem.name,
                                                            hiddeLabel: true,
                                                        }} 
                                                    />
                                                </Suspense>
                                            </Grid>)
                                        })
                                    }
                                </Box>
                            </Box>
                        }
                    </>
                    :<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                {charge && <NothingFound
                                    caption={"Aquí encontrarás el contenido que tengas en progreso"}
                                    subcaption={"No tienes cursos en progreso"}
                                    type={"courses"}
                                /> }
                            </Box>}
                </Box>
            </Box>
        </Loader>
    );
}

CardsProgress.defaultProps = {
    lazyLoading: false,
    disableTitleAction: false,
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CardsProgress);
