import React from 'react';
import { NavLink } from "react-router-dom";

// @redux
import { connect } from "react-redux"

import * as menuActions from '../../../redux/actions/menuActions'

// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSubheader from "@material-ui/core/ListSubheader";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';

// @material-ui/icons 
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { v4 as uuid } from 'uuid';

// styles
import Root from '../../../assets/styles/jss/layout/linksStyle';

function activeRoute(routName){
    return routName === window.location.pathname;
}

const SidebarMenu = (props) => {
    const {menu} = props;
    const menuId = sessionStorage.getItem("menuId") || null;
    const [openMenuId, setOpenMenuId] = React.useState(menuId);
    const [menuItems, setMenuItems] = React.useState([]);

    React.useEffect(() => {
        setMenuItems(menu)
    }, [menu])

    const handleClickMenu = id => {
        const menuId = id === openMenuId ? null : id;
        sessionStorage.setItem("menuId", menuId);
        setOpenMenuId(menuId)
    };

    const NavLinkItem = (it) => {
        return <NavLink
            exact
            key={uuid()}
            to={it.path}
            className={ it.label == "Home" ? "home-step list-link": it.label == "Cursos" ? "cursos-step list-link": "list-link" }
        >
            <ListItem button className={`item ${activeRoute(it.path) ? "active" : ""}`} onClick={handleClickMenu.bind(this, it.id)}>
                <it.icon className="item-icon"/>
                <ListItemText
                    primary={it.label}
                    className="item-text"
                    disableTypography={true}
                />
                {
                    
                    it.hasNotification &&
                    <Chip label="2" className="item-notification"/>
                }
            </ListItem>   
        </NavLink>
    }
    

    const listItemType = routeItem => {
        const {type} = routeItem;
        switch (type) {
            case "section":
                return <ListItemSubheader key={uuid()}>
                    <ListItemText
                        primary={routeItem.label}
                        className="item-text"
                        disableTypography={true}
                    />
                </ListItemSubheader>
            case "external-link":
                return <Link href={routeItem.externalPath} target="_blank" className="list-link" key={uuid()}>
                    <ListItem key={uuid()} button className="item">
                    <routeItem.icon className="item-icon"/>
                        <ListItemText
                            primary={routeItem.label}
                            className="item-text"
                            disableTypography={true}
                        />
                    </ListItem>
                </Link>
            default:

                return <NavLinkItem {...routeItem} key={uuid()}/>;
        }
    }
 
    return (
        <Root>
            <List>
                {
                    menuItems.length && menuItems.filter(route => route.isNavLink).map(it=> 
                        !it.isMenu
                        ?
                            listItemType({...it, type: it.type || 'menu-item'})
                        :
                        <Box key={uuid()} className={ it.label == "Cursos" ? "cursos-step list-link": "list-link" }>
                            <ListItem button className="item" style={{display: 'flex'}} onClick={handleClickMenu.bind(this, it.id)} >    
                            <it.icon className="item-icon"/>
                            <ListItemText
                                primary={it.label}
                                className="item-text"
                                disableTypography={true}
                            />

                                <Box className="item-actions">
                                    {
                                        it.notification && openMenuId !== it.id &&
                                        <Chip label={it.notification} className="notification"/>
                                    }   
                                    {openMenuId === it.id ? <ExpandLess style={{color: '#808080'}} /> : <ExpandMore style={{color: '#808080'}}/>}
                                </Box>
                            </ListItem>
                            <Collapse in={openMenuId === it.id} timeout="auto" unmountOnExit>
                                <List disablePadding className="collapse-list">
                                    {it.children.filter(c => c.isNavLink).map(child => (
                                        <NavLink
                                            exact
                                            key={uuid()}
                                            to={child.path}
                                            className={`list-link`}
                                            activeClassName={`${activeRoute(child.path) ? "active" : ""}`}
                                        >
                                            <ListItem button className={`item child`}>
                                                <ListItemText
                                                    primary={child.label}
                                                    className="item-text"
                                                    disableTypography={true}
                                                />
                                                {
                                                    child.notification &&
                                                    <Chip label={child.notification} className="notification"/>
                                                }
                                            </ListItem>
                                        </NavLink>
                                    ))}
                                </List>
                            </Collapse>
                        </Box>
                    )
                }
            </List>
            {props.children}
        </Root>
    );
};

const mapStateToProps = (state) => ({...state.userReducer, ...state.menuReducer});

export default connect(mapStateToProps, {...menuActions})(SidebarMenu);
