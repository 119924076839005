import React from 'react';
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';

// @materia-ui
import {
    Card,
    Box,
    CardContent,
    Grid,
    Typography,
    Icon
} from '@material-ui/core';

// assets
import useStyles from '../../../../../assets/styles/jss/pages/cart/cartStyle.js';

const HarmonHallUpsellingCard = ({ product, selectedAddonId, onCardClick }) => {
    const classes = useStyles({ image_url: product.image_url });

    return (
        <Card variant="outlined" className={clsx(classes.hhUpsellingCard,
            { [classes.hhUpsellingCardDisabled]: selectedAddonId !== null && selectedAddonId !== product.id }
        )}
            onClick={(e) => onCardClick(e, product)}>
            <CardContent>
                <Grid container direction='column'>
                    <Grid item container>
                        {product.membership_benefits.map((benefit, index) => {
                            return <Grid item key={index}>
                                <Typography variant="body1" className='font-weight-700'>{benefit}</Typography>
                            </Grid>
                        })}
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h3" className={clsx('text-hh-yellow')} component="span">
                            <CurrencyFormat value={product.price_per_month} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" />
                        </Typography>
                        <Typography variant="h6" className={clsx('text-hh-yellow ml-1')} component="span">
                            MXN / mes
                        </Typography>
                    </Grid>
                    {product.original_price > 0 && (
                        <Grid item xs>
                            <Box>
                                <Typography variant="caption" className={clsx(classes.priceStrikeThrough, "font-size-10")} component="span">
                                    precio regular
                                </Typography>
                                <Typography variant="body2" className={clsx(classes.priceStrikeThrough, "font-size-12")} component="span">
                                    <CurrencyFormat value={product.original_price} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix=" $" />
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
            <Box className={classes.harmonHallCardFooter}>
                <Box display="flex" justifyContent='center' alignItems="inherit">
                    <Icon className={clsx("font-size-16 mr-1", {
                        "ri-check-fill": (selectedAddonId === product.id),
                        "ri-add-line": (selectedAddonId !== product.id),
                    })} />
                    <Typography variant="body2" className='font-weight-600 mr-1' component="span">
                        Agregar por
                    </Typography>
                    <Typography variant="body1" className='font-weight-600' component="span">
                        <CurrencyFormat value={product.list_price} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" suffix=' MXN' />
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.harmonHallMembership}>
                <Typography variant="body2" className='font-weight-400'>
                    {product.membership_type}
                </Typography>
            </Box>
        </Card>
    );
}

export default HarmonHallUpsellingCard;