import React from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';


// @material-ui/core components
import Snackbar from '@material-ui/core/Snackbar';

// @material-ui/lab components
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *':{
            marginTop: theme.spacing(2)
        }
    }
}));

const Toast = React.forwardRef(({severity, open, message, duration, onFinished, anchorOrigin, className}, ref) => {    
    const classes = useStyles();
    const handleClose = (reason) => {
      if (reason === 'clickaway') {
        return;
      }

      onFinished();
    };
  
    return (
        <div className={clsx(classes.root, className)}>
            <Snackbar 
                ref={ref}
                anchorOrigin={anchorOrigin }
                open={open} 
                autoHideDuration={duration} 
                onClose={handleClose}
                // TransitionProps={{ onExited: onFinished }}
            >
                <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity} ref={ref}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
});

Toast.defaultProps = {
    open: false,
    severity: 'success',
    duration: 4000,
    anchorOrigin: { vertical: 'top', horizontal: 'right' }
};

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool,
    severity: PropTypes.oneOf([
        "error",
        "info",
        "success",
        "warning",
    ]),
    onFinished: PropTypes.func
};

export default Toast;