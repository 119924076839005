import palette from '../palette';

const typography = {
  default:{
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px',
  },
  large:{
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '16px',
  },
  bttnTextSecondary:{
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: 2
  },
}

export default {
  root:{
    fontSize: 14,
    borderRadius: 8,
    textTransform: 'none',
    lineHeight: '16px',
    color: palette.colors.white,
    padding: '12px 24px',
  },
  sizeSmall:{
    padding: '8px 24px',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px'
  },
  sizeLarge:{
    padding: '16px 24px',
    ...typography.large,
  },
  iconSizeMedium:{
    '&> *:first-child':{
      fontSize: '16px !important'
    }
  },
  iconSizeLarge:{
    '&> *:first-child':{
      fontSize: '16px !important'
    }
  },
  iconSizeSmall:{
    '&> *:first-child':{
      fontSize: '16px !important'
    }
  },
  contained: {
    boxShadow: 'unset',
    backgroundColor: palette.colors.fillStroke[400],
    color: palette.colors.gray[100],
    ...typography.default,

    '&:hover': {
      backgroundColor: palette.colors.fillStroke[300]
    },

    '&.Mui-disabled':{
      backgroundColor: palette.colors.fillStroke[500],
      border: `1px solid ${palette.colors.fillStroke[300]}`,
      color: palette.colors.darkBlack[200],
    }
  },
  outlined:{
    // '&.electric-green': {
    //   borderRadius: 40,
    //   border: `1px solid ${palette.colors.electricGreen[300]}`,
    //   backgroundColor: 'rgba(82, 124, 31, 0.15)',
    //   '& .MuiIcon-root':{
    //     color: palette.colors.electricGreen[300]
    //   }
    // }
  },
  containedPrimary:{
    backgroundColor: palette.colors.electricBlue[300],
    '&:hover':{
      backgroundColor: palette.colors.violet[400],
    },
    '&.Mui-disabled':{
      backgroundColor: palette.colors.fillStroke[400],
      color: palette.colors.darkBlack[200],
      border: 'unset'
    }
  },
  outlinedPrimary: { // terciario
    borderRadius: 40,
    border: `0.5px solid ${palette.colors.violet[300]}`,
    color: palette.colors.gray[100],
    backgroundColor: palette.colors.fillStroke[400],
    ...typography.default,
    padding: '12px 24px',
    '&:hover': {
      backgroundColor: palette.colors.violet[300]
    },
    '&.electric-green': {
      borderRadius: 40,
      border: `1px solid ${palette.colors.electricGreen[300]}`,
      backgroundColor: 'rgba(82, 124, 31, 0.15)',
      '&:not(.Mui-disabled) .MuiIcon-root':{
        color: palette.colors.electricGreen[300]
      }
    },
    '&.Mui-disabled':{
      backgroundColor: palette.colors.fillStroke[500],
      border: `1px solid ${palette.colors.fillStroke[200]}`,
      color: palette.colors.darkBlack[200],
    }
  },
  outlinedSecondary: { // secundario v2
    borderRadius: 8,
    border: `0.5px solid ${palette.colors.violet[300]}`,
    color: palette.colors.gray[100],
    backgroundColor: palette.colors.fillStroke[400],
    ...typography.default,
    padding: '12px 24px',
    '&:hover': {
      backgroundColor: palette.colors.violet[300],
      border: `0.5px solid ${palette.colors.violet[300]}`,
    },
    '&.Mui-disabled':{
      backgroundColor: palette.colors.fillStroke[500],
      border: `1px solid ${palette.colors.fillStroke[200]}`,
      color: palette.colors.darkBlack[200],
    }
  },
  text:{
    padding: '8px 16px',
    ...typography.default,
    '& .MuiButton-endIcon':{
      marginLeft: 7,
      '& .MuiIcon-root':{
        fontWeight: 'normal'
      }
    }
  },
  textPrimary:{
    padding: '8px 16px',
    color: palette.colors.orange[300],
    textTransform: 'unset',
    ...typography.default,
    letterSpacing: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    },
  },
  textSecondary:{
    color: palette.white,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    textTransform: 'uppercase',
    ...typography.bttnTextSecondary,
  }
};
