import React from 'react';
import moment from 'moment';
// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// shared
import dateFormat from '../../../../../design-system/utils/dateFormat'
import {COMPLETE_DATE_FORMAT} from '../../../../../design-system/constants/date-formats'

import Schedule from '../../../../../shared/components/CourseContent/Schedule'
import DetailsList from '../../../../../shared/components/CourseContent/DetailsList';
import Instructor from '../../../../../shared/components/CourseContent/Instructor';

const CourseInformation = (props) => {
    const {data} = props;

    const [scheduleItems, setScheduleItems] = React.useState([[],[]]);

    React.useEffect(() => {
        if(data.schedule){
            setScheduleItems([
                [
                    { label: 'Inicia', value: moment.utc(data?.schedule[0]?.start_date).locale('es').format('DD/MM/YYYY'), icon: 'today' },
                    { label: 'Termina', value: moment.utc(data?.schedule[0]?.end_date).locale('es').format('DD/MM/YYYY'), icon: 'event_available' },
                    { label: 'Frecuencia', value: data?.schedule[0]?.frecuency ? data?.schedule[0]?.frecuency : '-', icon: 'history'},
                ],
                [
                    { label: 'Horario', value: data?.schedule[0]?.start_time && data?.schedule[0]?.end_time ? `${moment.utc(data?.schedule[0]?.start_time).locale('es').format('H:mm A')} - ${moment.utc(data?.schedule[0]?.end_time).locale('es').format('H:mm A')}` : '-', icon: 'access_time' },
                    { label: 'Plataforma', value: data?.schedule[0]?.plataform ? data?.schedule[0]?.plataform:'-', icon: 'important_devices' },
                ]
            ])
        }
    }, [])

    return (
        <Box>
            {data != null && <Grid container spacing={4}>
                <Grid container item xs={12}>
                    <Grid item xs={12} md={6}>
                        <Schedule items={scheduleItems[0]}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Schedule items={scheduleItems[1]}/>
                    </Grid>
                </Grid>
                {data?.description_short && <Grid item xs={12}>
                    <Card>
                        <CardContent style={{padding: 32}}>
                            <Typography variant="h4" className="mb-3">Que aprenderas en este curso</Typography>
                            <Typography variant="body1">
                                {data?.description_short}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>}
                {data?.tools.length > 0 &&
                    <Grid item xs={12}>
                        <DetailsList title="Herramientas necesarias" items={data?.tools} labelProp="text"/>
                    </Grid>
                }
                {data?.advantages.length > 0 &&<Grid item xs={12}>
                    <DetailsList title="Ventajas competitivas" items={data?.advantages} labelProp="text"/>
                </Grid>}
                {data?.requirements.length > 0 &&<Grid item xs={12}>
                    <DetailsList title="Requisitos para acreditar curso" items={data?.requirements} labelProp="text" className="text-orange-300"/>
                </Grid>}
                {data?.instructor_full_name && <Grid item xs={12}>
                    <Instructor image_instructor_url={data?.instructor_avatar != null ? data?.instructor_avatar : data?.instructor_avatar2} name={data?.instructor_full_name} description={data?.objective} />
                </Grid>}
            </Grid>}
        </Box>
    );
};

export default CourseInformation;