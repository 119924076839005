import React from 'react'

import { Grid, List, ListItem, ListItemText, Typography, useMediaQuery } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DetailedPlanIconList } from '../../DetailedStudyPlan/components/DetailedPlanIconList';

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: 5
    },
    paddingList:{
        [theme.breakpoints.down('xs')]:{
            padding: '0px !important',
        }
    }
}));

const SummaryListWithIcons = ({ title, bulletList = [] }) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const theme = useTheme();
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Typography variant='h5'>
                { title }
            </Typography>
            <List dense={isResponsive ? false : true}>
            {
                (bulletList.length > 0) &&
                bulletList.map((listItem, index) =>
                    <ListItem key={index} disableGutters className='p-0'>
                        <DetailedPlanIconList width={16} height={16} icon={listItem} color={ !isResponsive ? theme.palette.colors.gray[400] : theme.palette.colors.white } classes={classes.icon}/>
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography variant='body2' style={{ color: !isResponsive ? theme.palette.colors.gray[400] : theme.palette.colors.white, lineHeight: '26px' }}>
                                    { listItem }
                                </Typography>
                            }
                            className='m-0'
                        />
                    </ListItem>
                )
            }
            </List>
        </Grid>
    )
}

export default SummaryListWithIcons
