import React from 'react';
import { useSelector } from 'react-redux';

// @material-ui
import List from '@material-ui/core/List';

// components
import OrderItem from './OrderItem/OrderItem';
import OrderSummaryCard from '../components/OrderSummaryCard/OrderSummaryCard';

const OrderDetails = () => {
    const { orderProducts } = useSelector(state => state.checkoutReducer);

    return (
        <OrderSummaryCard title="Resumen de compra" titleVariant="h5" showDivider>
            <List dense disablePadding>
                {orderProducts?.map(detail => {
                    return (
                        <OrderItem
                            key={detail.product_id}
                            detail={detail}
                        />
                    )
                })}
            </List>
        </OrderSummaryCard>
    )
};

export default OrderDetails;