import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';

// reducers 
import { setInstallmentOptions, setOrder, setOrderPriceDetails, setOrderProducts, setPersonInfo, setSelectedInstallmentOption } from '../../redux/actions/checkoutActions';

// @material-ui
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// components
import CheckoutLoader from './components/CheckoutLoader/CheckoutLoader';

// assets
import useStyles from '../../assets/styles/jss/pages/checkout/checkout';

// components
import CartProductCount from '../Cart/components/CartProductCount/CartProductCount';
import CheckoutWorkflow from './CheckoutWorkflow/CheckoutWorkflow';
import OrderDetails from './OrderDetails/OrderDetails.jsx';
import StudentInfo from './StudentInfo/StudentInfo';
import CheckoutPriceDetails from './CheckoutPriceDetails/CheckoutPriceDetails';
import CheckoutCoupon from './CheckoutCoupon/CheckoutCoupon';
import CheckoutMaintenanceAlert from './components/CheckoutMaintenanceAlert/CheckoutMaintenanceAlert.jsx';

// paths
import * as paths from '../../routes/paths.js';

// shared
import { log } from '../../shared/utils/console.js';
import { isOrderProcessed } from '../../shared/utils/checkoutHelpers.js';

// sdk
import { CartService } from "@sdk-point/talisis";
import { getCheckoutCookie, setCheckoutCookie } from '../../shared/utils/checkoutSession.js';

const cartService = new CartService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

const Checkout = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const checkoutCookie = getCheckoutCookie();
    const { orderProducts } = useSelector(state => state.checkoutReducer);
    const [loading, setLoading] = React.useState(true);

    const initializeCheckout = async () => {
        try {
            setLoading(true);
            // Regresar usuario al inicio si no hay una orden activa
            const checkedOrderId = checkoutCookie?.orderId > 0 ? checkoutCookie?.orderId : null;
            if (!checkedOrderId) {
                history.push(paths.ROOT_HOME);
            } else {
                // Consultar la orden que está cargada en la cookie
                const response = await cartService.getCartById(checkedOrderId);
                const orderProcessed = isOrderProcessed(response.status_id);
                //  Redirigir a confirmación de compra si la orden ya está procesada
                if (orderProcessed) {
                    history.replace(generatePath(paths.PAYMENT_CONFIRMATION, { order_id: checkedOrderId }));
                } else {
                    // Actualizar el reducer de la compra con los detalles de los productos
                    dispatch(setOrder(response));
                    dispatch(setOrderProducts(response?.cart_details));
                    // Procesar los totales del carrito para el checkout
                    const checkoutProcess = await cartService.shoppingCartProcess(checkedOrderId, response?.person_id || null);
                    // Procesar los datos de la persona en el checkout
                    const personInfo = checkoutProcess?.person_info || {};
                    const userInfo = {};
                    if (personInfo && Object.keys(personInfo).length > 0) {
                        userInfo.userName = personInfo.name;
                        userInfo.phone_number = personInfo.phone_number;
                        userInfo.email = personInfo.email_address;
                        userInfo.person_id = response?.person_id;
                    }
                    // Actualizar reducer de la persona
                    dispatch(setPersonInfo(userInfo));
                    // Actualizar checkout cookie 
                    const checkoutCookie = getCheckoutCookie();
                    setCheckoutCookie({
                        ...checkoutCookie,
                        personInfo: userInfo
                    });
                    // Actualizar detalles de precios en la orden
                    dispatch(setOrderPriceDetails({
                        total: checkoutProcess?.total,
                        subtotal: checkoutProcess?.subtotal,
                        bonuses: checkoutProcess?.bonuses,
                        discounts: checkoutProcess.discounts,
                        savings: checkoutProcess?.savings,
                        next_payment_date: checkoutProcess?.next_payment_date
                    }));
                    // Actualizar opciones de plazo msi
                    dispatch(setInstallmentOptions([...checkoutProcess?.msi_options]));
                    dispatch(setSelectedInstallmentOption(1));
                }
            }
            setLoading(false);
        } catch (error) {
            log('error', error)
        }
    };

    React.useEffect(() => {
        initializeCheckout()
    }, []);

    return <Box>
        <CheckoutMaintenanceAlert display={false} />
        <CheckoutLoader loading={loading} />
        {!loading ?
            <Grid container>
                <Grid item xs>
                    <Box className={classes.cartProductCount}>
                        <CartProductCount products={orderProducts} />
                    </Box>
                </Grid>
                <Grid container item spacing={4}>
                    <Grid item xs={12} md={6}>
                        <CheckoutWorkflow />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <OrderDetails />
                            </Grid>
                            <Grid item xs={12}>
                                <StudentInfo />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckoutPriceDetails />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckoutCoupon />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> : null}
    </Box>
};

export default Checkout;