import React from 'react';
import moment from 'moment';

// @material-core
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {MenuItem, Select, Tooltip, Divider} from '@material-ui/core';

// local
import {detailsStyles} from '../../../../../assets/styles/jss/pages/degreeCertificates/DetailsStyles'

export const AboutMe = ({data, nationalityCat, xData, onSetForm, onChange}) => {

  const classes = detailsStyles();

  return (
    <Grid container spacing={3} className="mt-1">
      <Grid item>
        <Grid container>
          <Grid item xs={4} className={classes.item}>
            <Grid container className='py-1 ml-2'>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                  Nombre Completo
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="full_name"
                  name="full_name"
                  fullWidth
                  multiline={true}
                  className={classes.label}
                  value={data.nombre ? `${data.nombre} ${data.primerApellido} ${data.segundoApellido}` : ''}
                  InputProps={{
                    className: classes.inputText,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Grid container className='py-1 ml-2'>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                  Matricula
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="matricula"
                  name="matricula"
                  fullWidth
                  className={classes.label}
                  value={data.matricula || ''}
                  InputProps={{
                    className: classes.inputText,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container className='py-1 ml-2'>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                  CURP
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="curp"
                  name="curp"
                  fullWidth
                  multiline={true}
                  className={classes.label}
                  value={data.curp || ''}
                  InputProps={{
                    className: classes.inputText,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Grid container className='py-1 ml-2'>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                  Género
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="genero"
                  name="genero"
                  fullWidth
                  className={classes.label}
                  value={data.genero === 'M' ? 'Masculino' : data.genero === 'F' ? 'Femenino' : ''}
                  InputProps={{
                    className: classes.inputText,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Grid container className='py-1 ml-2'>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                  Correo Electronico
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="correoElectronico"
                  name="correoElectronico"
                  fullWidth
                  className={classes.label}
                  value={data.correoElectronico || ''}
                  InputProps={{
                    className: classes.inputText,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container className='py-1 ml-2'>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                  Nombre Legal
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="nombreLegal"
                  name="nombreLegal"
                  fullWidth
                  multiline={true}
                  className={classes.label}
                  value={data.nombreLegal || ''}
                  InputProps={{
                    className: classes.inputText,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Grid container className='py-1 ml-2'>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                  Nacional
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="esNacional"
                  name="esNacional"
                  fullWidth
                  className={classes.label}
                  value={data.esNacional || ''}
                  InputProps={{
                    className: classes.inputText,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};
