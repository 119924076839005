import React from 'react';

// @material-ui
import { Box, Typography } from '@material-ui/core';

// ds
import { getLogoBySource } from '../../../../../../design-system/utils/getLogo';
import { SOURCES } from '../../../../../../design-system/constants/sources';

const HarmonHallHeader = () => {
    return <Box display="flex" alignItems="center">
        <Box>
            <img alt="talisis" src={getLogoBySource(SOURCES.TALISIS, "white")} height="24" />
        </Box>
        <Box marginX="11px">
            <Typography variant='h3'>+</Typography>
        </Box>
        <Box>
            <img alt="harmon-hall" src={getLogoBySource(SOURCES.HH, "white")} height="24" />
        </Box>
    </Box>;
}

export default HarmonHallHeader;