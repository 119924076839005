import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import { v4 as uuid } from 'uuid';

export default function CardSkeletonLoading(props) {
    const {numItems, ...rest} = props;
    return (
        <Box display="flex" gridGap={24} position="absolute" {...rest}>
            {
                [...Array(numItems).keys()].map(it =>                     
                    <Card variant="outlined" key={uuid()} style={{width:288}}>
                        <CardMedia>
                            <Box className="card-skeleton" height={168} />
                        </CardMedia>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" mb="11px">
                                <Box className="card-skeleton" width={51} height={16} borderRadius={45}/>
                                <Box className="card-skeleton" width={24} height={24} borderRadius={30}/>
                            </Box>
                            <Box mb="25.5px">
                                <Box className="card-skeleton" width="70%" height={18} borderRadius={45} mb={1}/>
                                <Box className="card-skeleton" width="74%" height={18} borderRadius={45}/>
                            </Box>
                            <Box>
                                <Box className="card-skeleton" width="32%" height={18} borderRadius={45} mb="18.5px"/>
                                <Box className="card-skeleton" width="52%" height={16} borderRadius={45}/>
                            </Box>
                        </CardContent>
                        <Divider className="bg-dark-black-200"/>
                        <CardActions>
                        <Box width={1}>
                            <Box className="card-skeleton" width={84} height={16} borderRadius={45} marginLeft="auto"/>
                        </Box>
                        </CardActions>
                    </Card>
                )
            }
        </Box>
    );
}

CardSkeletonLoading.defaultProps = {
    numItems:6,
};

CardSkeletonLoading.propTypes = {
    numItems: PropTypes.number
};