import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    membershipsRoot: {
        marginTop: '64px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '24px',
        },
    },
    faq: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        padding: '24px 316px',
        [theme.breakpoints.down('md')]: {
            padding: '24px 128px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '24px 64px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '24px 32px',
        },
        left: '50%',
        width: '100vw',
        position: 'relative',
        marginLeft: '-50vw',
    },
    cardSection: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 32
        },
    },
    grid: {
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    containerPrincipalLabel: {
        textAlign: 'center',
        maxWidth: 539,
        margin: 'auto'
    },
}));