import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootFilterSelection: {
        
    },
    containerFilterTitle: {
        paddingBottom: '13px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`,
    },
    filterTitleLabel: {
        color: theme.palette.colors.gray[400]
    },
    closeFilterIcon: {
        color: theme.palette.colors.darkBlack[200]
    },
    deleteFiltersLabel: {
        color: theme.palette.colors.darkBlack[200]
    },
    searchFilterTitleBottom:
    {
        color: theme.palette.colors.fillStroke[100],
        borderTop: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        paddingTop: '12px'
    },
    refreshIconContainer:
    {
        cursor: 'pointer'
    },
    refreshIcon: {
        color: theme.palette.colors.darkBlack[200],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    restoreFilters: {
        cursor: 'pointer',
    },
    readyBottomContainer: {
        marginTop: '16px',
    },
    buttonComponent: {
        padding: 0,
    },
    cardContainer: {
        backgroundColor: "transparent !important",
    },
}));