import React from 'react';
import Pending2 from './Pending/Pending2';

// @mui
import Box from '@material-ui/core/Box';

const Billing = () => {
    return(
        <Box mb={5}>
            <Pending2 />
        </Box>
    );
}

export default Billing;
