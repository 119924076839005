import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    academyName: {
        color: theme.palette.colors.gray[500],
    }, 
    pathName: {
        color: theme.palette.colors.gray[100],
    },
    rootSwiperSlide: {
        marginLeft: "12px",
        maxWidth: "fit-content",
    }
}));