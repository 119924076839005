import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Hidden, useMediaQuery } from '@material-ui/core';
import { Typography, Box, Divider, Button, Icon } from '@material-ui/core';

import ClipboardButton from '../../design-system/components/ClipboardButton/ClipboardButton';
import ReferralPlanPromotor from './components/ReferralPlanPromotor';

import useStyles from '../../assets/styles/jss/pages/referralPlan/referralPlan'

import * as paths from '../../routes/paths';

import { hasRole } from '../../services/RoleService';
import { ROLE_PROMOTOR } from '../../shared/constants/roles';


const ReferralPlan = () => {
    const classes = useStyles();
    const [clipboardUrl, setClipboardUrl] = React.useState('')
    const [qrUrl, setQrUrl] = React.useState('')
    const { user } = useSelector((state) => state.userReducer);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

    React.useEffect(()=>{
        if(user.person_id){
            const size = (isResponsive ? '&chs=280x280' : '&chs=145x145') + '&chl=';
            const REFIERE_UN_AMIGO_PATH = window.origin + generatePath(paths.REFIERE_UN_AMIGO_PATH, { referer_id: user.person_id });
            setQrUrl(`${paths.CHART_GOOGLE_QR_PATH}${size}${REFIERE_UN_AMIGO_PATH}`)
            setClipboardUrl(REFIERE_UN_AMIGO_PATH)
            // const SIGNUP_REFERER_PATH = 'https://talisis.com' + generatePath(paths.SIGNUP_REFERER_ID, { referer_id: user.person_id });
            // setQrUrl(`${paths.CHART_GOOGLE_QR_PATH}${size}${SIGNUP_REFERER_PATH}`)
            // setClipboardUrl(SIGNUP_REFERER_PATH)
        }
    })


    return (
        <Box className={classes.root} id="referral-plan-root">
            <Box className={classes.banner}>
                <Box className={classes.bgLabel}>
                    <Typography variant='h1'>{hasRole(ROLE_PROMOTOR) ? 'Promociona Talisis.com' : '¡Refiere y gana!'}</Typography>
                </Box>
            </Box>
            {
                hasRole(ROLE_PROMOTOR) 
                ?   <ReferralPlanPromotor clipboardUrl={clipboardUrl} qrUrl={qrUrl} isResponsive={isResponsive} classes={classes}/>
                :<>
                    <Typography variant='subtitle2' align='center' className={classes.referMssg}>Invita a <Typography variant="h4" className='text-violet-300' component="span">2</Typography> amigos para que adquieran una <Typography variant="h5" component="span">membresía de Talisis</Typography> y gana una tarjeta de regalo de Amazon de <span variant="body1" className='text-blue-300 semi-bold'>$500MXN</span></Typography>
                    <Box className={classes.container}>
                        <Box>
                            <Box py={5} className={classes.qrContent}>
                                <Box>
                                    <Box className={classes.qr}>
                                        <img src={qrUrl} alt="QR Code" />
                                    </Box>
                                </Box>
                                <Box display='flex' flexDirection='column' alignItems='center' gridGap={16} className={classes.qrClipboard}>
                                    <input readOnly defaultValue={clipboardUrl} className={classes.inputClipboard}/>
                                    <ClipboardButton textToCopy={clipboardUrl} variant='contained' size='small' fullWidth={isResponsive}>
                                    Copiar enlace <Icon className='ri-links-line font-size-24 ml-1' />
                                    </ClipboardButton>
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant='body1' className='semi-bold'>Solo tienes que:</Typography>
                                <Box maxWidth={515} width={1} display='flex' margin='auto'>
                                    <ol className={classes.list}>
                                        <li>Compartir este enlace o código QR a dos amigos que aún no tengan una cuenta en Talisis para que se registren y adquieran una membresía.</li>
                                        <li>Pídeles elegir entre nuestras membresía por un período <span className='semi-bold'>semestral o anual</span> (no aplica para membresía Freemium)</li>
                                        <li>Para que adquieran un <span className='semi-bold'>descuento del 20%</span> en su compra, puedes compartirles el siguiente cupón.</li>
                                    </ol> 
                                </Box>
                                <Box pt={4} pb={5} className={classes.cuponBox}> 
                                    <Box className='cupon-text'>
                                        <Typography className='text-fill-stroke-400' component='span'>R3F3R1D0</Typography>
                                    </Box>
                                    <Box maxWidth={341} textAlign='center'>
                                        <Typography variant='caption'>Cupón válido solo con el uso del enlace de referidos y para usuarios que aún no tienen una cuenta en Talisis.com</Typography>
                                    </Box>
                                </Box>
                                <Divider className="bg-fill-stroke-300"/>
                                <Box py={4}>
                                    <Box display='flex' justifyContent='space-between' flexDirection='column' maxWidth={483} margin='auto' className={classes.note}>
                                        <Typography variant='body1'>
                                            Si tus dos referidos han completado la compra te contactaremos por correo electrónico para entregarte tu tarjeta de regalo en el transcurso de la semana.
                                        </Typography>
                                        <Typography variant='body1' color='primary' className='semi-bold'>
                                            Recuerda que para que los referidos cuenten como válidos deben utilizar tu enlace personal al registrarse.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
            <Hidden smDown>
                <Divider className="bg-fill-stroke-300"/>
                <Box pt={5} pb={2} textAlign='center'>
                    <Button variant='outlined' color="primary" size='small' component={RouterLink} to={paths.HOME}>Regresar a inicio</Button>
                </Box>
            </Hidden>
        </Box>
    );
};

export default ReferralPlan;