import { makeStyles } from "@material-ui/core";

export const interestSelectorStyles = makeStyles((theme) => ({
    interestContainer: {
        maxHeight: "49em",
        [theme.breakpoints.down('xs')]:{
            width: '295px'
        },
        overflowY: "scroll",
        overflowX: "hidden"
    },
}));
