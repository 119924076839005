import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import useStyles from '../../../../../assets/styles/jss/pages/memberships/versionSKU/MembershipsContainerCard/HarmonHallAddOnsStyle.js';
import { log } from '../../../../../shared/utils/console.js';
import HarmonHallHeader from './HarmonHallHeader/HarmonHallHeader.jsx';
import HarmonHallCard from './HarmonHallCard/HarmonHallCard.jsx';
import { PRODUCT_SKU_PREFIX, ProductService } from '@sdk-point/talisis';
import LoaderContent from '../../../../../design-system/components/Loader/LoaderContent.jsx';
import { useMediaQuery } from '@material-ui/core';

const productService = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const HarmonHallAddons = ({ termMonths, setProductAddOn }) => {
    const classes = useStyles();
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedAddonId, setSelectedAddonId] = useState(null);
    const [loading, setLoading] = useState(true);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const normalizeProduct = (product) => {
        return {
            product_id: product?.id,
            product_name: product?.name,
            product_image_url: product?.image_url,
            product_description: product?.description,
            product_type_id: product?.product_type_id,
            quantity: 1,
            product_price_id: product?.product_price_id,
            unit_price: product?.list_price,
            discount: 0
        };
    };

    const handleCardClick = (e, product) => {
        e.preventDefault();
        e.stopPropagation();
        if (selectedAddonId && selectedAddonId === product.id) {
            setSelectedAddonId(null);
            setProductAddOn(null);
        } else {
            setSelectedAddonId(product.id);
            setProductAddOn(normalizeProduct(product));
        }
    };

    const filterMembershipsByMonths = async (memberships, termMonths) => {
        return memberships.find(arr => arr.some(obj => obj.period_in_months === termMonths));
    };

    const getHarmonHallAddons = async () => {
        try {
            const products = await productService.getProductsBySkuPattern(PRODUCT_SKU_PREFIX.HARMON_HALL + "-STD");
            if (products && products.length) {
                let allProducts = [];
                for (const product of products) {
                    const result = await productService.getHarmonHallMemberships(product.sku);
                    allProducts.push(result);
                }
                setAllProducts(allProducts);
                const data = await filterMembershipsByMonths(allProducts, termMonths);
                setFilteredProducts(data);
            } else {
                setAllProducts([]); // Aseguramos que sea un array vacío
                setFilteredProducts([]); // Aseguramos que sea un array vacío
            }
            setLoading(false);
        } catch (error) {
            log("error al obtener addons: ", error);
            setAllProducts([]); // En caso de error, aseguramos que sea un array vacío
            setFilteredProducts([]); // En caso de error, aseguramos que sea un array vacío
        }
    };

    useEffect(() => {
        getHarmonHallAddons();
    }, []);

    useEffect(() => {
        setSelectedAddonId(null);
    }, [termMonths]);

    useEffect(() => {
        const updateFilteredProducts = async () => {
            const filtered = await filterMembershipsByMonths(allProducts, termMonths);
            setFilteredProducts(filtered);
        };
        updateFilteredProducts();
    }, [termMonths, allProducts]);

    return (
        <Card variant='outlined' className={classes.cardHarmonHallAddOn}>
            <CardContent className={classes.cardContentStyle}>
                <Grid container spacing={2} className={classes.harmonhallContainer}>
                    {
                        loading ?
                            <LoaderContent loading={loading} minHeight="225px" /> :
                            <>
                                <Grid item>
                                    {isResponsive ? (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <HarmonHallHeader />
                                            </Grid>
                                            <Grid item className='pt-2'>
                                                <Typography variant="h4" component="span" className='text-hh-yellow'>Aprende Inglés ahorrando<br /> hasta el 80%</Typography>&nbsp;
                                                <Typography variant="h4" component="span">con tu membresía Talisis</Typography>
                                            </Grid>
                                            <Grid item className='mt-2'>
                                                <Typography variant="caption">Obtén tu membresía de Harmon Hall Online a precio <br />preferencial en la compra de tu membresía Talisis</Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <HarmonHallHeader />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3" className='mt-3 mb-1 text-hh-yellow'>Aprende Inglés<br />ahorrando hasta el 80%</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">Con tu membresía Talisis</Typography>
                                            </Grid>
                                            <Grid item className='mt-3'>
                                                <Typography variant="caption">Obtén tu membresía de Harmon Hall Online a precio <br />preferencial en la compra de tu membresía Talisis</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs>
                                    <Grid container spacing={3} className={classes.harmonhallContainerCard}>
                                        {
                                            filteredProducts && filteredProducts.length > 0 && filteredProducts.map((product, index) => (
                                                <Grid item key={index}>
                                                    <HarmonHallCard
                                                        product={product}
                                                        onCardClick={handleCardClick}
                                                        selectedAddonId={selectedAddonId} />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

export default HarmonHallAddons;
