import React from 'react';
import { Box, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles((theme) => ({
    root: {
        color: "#002984"
    },
    table: {
        width: "100%",
        overflowX: "auto",
        marginBottom: theme.spacing(4)
    }
}));

const PaymentDetail = ({ methods, adeudos, display, displayPrint }) => {
    const classes = useStyles();

    return <Box className={classes.table} display={display} displayPrint={displayPrint}>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell><Typography variant='body2'>Clave</Typography></TableCell>
                    <TableCell><Typography variant='body2'>Descripción</Typography></TableCell>
                    <TableCell><Typography variant='body2'>Periodo Escolar</Typography></TableCell>
                    <TableCell><Typography variant='body2'>Fecha de Vencimiento</Typography></TableCell>
                    <TableCell align="right"><Typography variant='body2'>Monto</Typography></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {adeudos.map((adeudo) => {
                    return adeudo.Cargos
                        .filter((cargo) => { return cargo.aPagar === "S" })
                        .map((cargo, index) => {
                            return <TableRow key={index}>
                                <TableCell><Typography variant='body2'>{cargo.Clave}</Typography></TableCell>
                                <TableCell><Typography variant='body2'>{cargo.Descripcion}</Typography></TableCell>
                                <TableCell><Typography variant='body2'>{adeudo.Periodo}</Typography></TableCell>
                                <TableCell><Typography variant='body2'>{cargo.Fecha_vencimiento}</Typography></TableCell>
                                <TableCell align="right"><Typography variant='body2'><CurrencyFormat value={cargo.Monto} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" /></Typography></TableCell>
                            </TableRow>
                        })
                })}
                <TableRow>
                    <TableCell style={{ borderBottom: "none" }} align="right" colSpan={4}><Typography variant="body2" color="textPrimary">Total a pagar:</Typography></TableCell>
                    <TableCell style={{ borderBottom: "none" }} align="right"><Typography variant="body2" className={classes.root}><CurrencyFormat value={methods["Monto a Pagar"]} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" /></Typography></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </Box>
};

PaymentDetail.defaultProps = {
    methods: [],
    adeudos: [],
    display: "block",
    displayPrint: "none"
};

export default PaymentDetail;