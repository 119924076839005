import React from 'react'

import { Badge, CircularProgress, Grid, makeStyles, withStyles } from '@material-ui/core';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import './profileCircleStyles.css'

import ProfilePic from '../../../../../shared/components/ProfilePic/ProfilePic'
import clsx from 'clsx';



const useStyles = makeStyles((theme) => ({
    profileIcon:{
        width: "193px", 
        height: "193px",
        [theme.breakpoints.down('xs')]:{
            width: "120px", 
            height: "120px",
        }
    },
    rootCircle: {
        width: "215px", 
        height: "215px",
        transform: 'rotate(-3.5deg)',
        [theme.breakpoints.down('xs')]:{
            width: "135px", 
            height: "135px",
        }
    },
}));

    const StyledBadge = withStyles((theme) => ({
        badge: {
            backgroundColor: '#000000',
            border: `3px solid #8B3FFA`,
            borderRadius: '2em',
            fontSize: '1em',
            margin: '0% 37%',
            [theme.breakpoints.down('xs')]:{
                margin: '0% 33%',
            },
            padding: '1.3em 2em',
            width: '30%',
        },
    }))(Badge);

const ProfileCircle = (props) => {
    const classes = useStyles();
    const { completedProfile, handlePhotoClick } = props;

    return (
        <Grid item md={12} xs={12}>
            <Grid container justifyContent='center' spacing={1}>
                <StyledBadge
                        badgeContent={
                            completedProfile === undefined 
                            ? <CircularProgress style={{ height: '24px', width: '24px' }} color="primary" />
                            : `${completedProfile}%`
                        }
                        color="primary"
                >
                    <Grid item xs={12}>
                        <CircularProgressbarWithChildren
                            variant="determinate"
                            circleRatio={0.98}
                            counterClockwise={true}
                            strokeWidth={5}
                            value={completedProfile}
                            className={classes.rootCircle}
                            styles={{
                                rotation: 0.975,
                                path: { 
                                    stroke: `url(#linearColors)`,
                                    transformOrigin: 'center center',
                                },
                                trail: {
                                    stroke: "none",
                                }
                            }}
                        >
                            <Grid 
                                container justifyContent='center'
                                className='photo-container' 
                                onClick={() => handlePhotoClick()}
                            >
                                
                                <ProfilePic 
                                    className={clsx('photo', classes.profileIcon)}
                                    simple={true} 
                                />
                                <PhotoCameraIcon fontSize='large' className='edit-icon' />
                            </Grid>
                        </CircularProgressbarWithChildren>
                    </Grid>
                </StyledBadge>
            </Grid>
        </Grid>
    )
}

export default ProfileCircle
