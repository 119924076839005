import React from 'react';

import * as MEDIA from '../../components/genericBrandLandingPageMedia'


// @mui
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
    root: {
        height: '397px',
        margin: '32px auto 0',
        maxWidth: '808px',
    },
    video: {
        borderRadius: '4px',
        height: '100%',
        width: '100%',
    },
    subLabel: {
        margin: '0 auto',
        width: '65%',
    },
}));

const GenericBrandLandingPageVideo = (props) => {
    const { className } = props;
    const classes = useStyles();
    
    return (
        <React.Fragment>
            <Typography variant="h2" color="textPrimary" >En U-ERRE estamos hechos para cambiar</Typography>
            <Typography component="div" variant="body2" color="textPrimary" className={`${classes.subLabel} mt-3`}>Estamos convencidos de que la educación es la base que cambia el futuro del país, es el vehículo de transformación social y de movimiento socio-económico; por eso estamos comprometidos con la educación de calidad que responda a las necesidades del mundo real.</Typography>
            <Box className={`${classes.root} ${className}`}>
                <video className={classes.video} src={MEDIA.getBrandLandingPagesVideo('u-erre')} controls controlsList="nodownload"></video>
            </Box>
        </React.Fragment>
    )
};

export default GenericBrandLandingPageVideo;