import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    chargeLabel: {
        color: theme.palette.colors.fillStroke[100]
    },
    iconInformation: {
        color: theme.palette.colors.warning[300]
    },
    policiesAndPrivacyLabel: {
        color: theme.palette.colors.gray[100]
    },
    policiesAndPrivacyCheckbox: {
        color: theme.palette.colors.violet[300]
    },
    debtsTitleLabel: {
        color: theme.palette.colors.violet[300]
    },
    totalCotainer: {
        display: "flex",
        width: "100%"
    },
    totalLabel: {
        display: "flex",
        justifyContent: "end",
        width: "40%"
    },
    payButtonContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    payButton: {
        width: "281px"
    },
    debtsTotalLabel: {
        color: theme.palette.colors.darkBlack[200]
    },
    debtsTotalAmountLabel: {
        color: theme.palette.colors.darkBlack[200]
    },
    balanceTotalLabel: {
        color: theme.palette.colors.gray[100]
    },
    balanceTotalAmountLabel: {
        color: theme.palette.colors.orange[300]
    },
    debtsContainer: {
        backgroundColor: theme.palette.colors.fillStroke[400]
    },
    ouSelect: {
        width: "150px"
    }
}));
