import { makeStyles } from "@material-ui/core";

export const orderDetailsNewStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.colors.grey[500],
        padding: '32px 16px',
        borderRadius: '8px'
    },
    subprice: {
        color: theme.palette.colors.orange[300],
        fontWeight: 600,
        fontSize: '16px',
        width: '100%'
    },
    price: {
        color: theme.palette.colors.orange[300],
        fontWeight: 600,
        fontSize: '22px',
        width: '100%'
    },
    priceContainer:
    {
        backgroundColor:theme.palette.colors.grey[400],
        borderBottomRightRadius:'8px',
        borderBottomLeftRadius:'8px'
    },
    divider: {
        width: "100%",
        marginTop: "10px",
        height:3,
        backgroundImage:'linear-gradient(to right, #FF8001 , #FFB601)'
    },
    commission: {
        fontWeight: 400,
        fontSize: '16px'
    },
    total: {
        fontWeight: 600,
        fontSize: '16px'
    },
    deleteButton:
    {
        color: '#A0A9BA'
    },
    discountText:
    {
        cursor: 'pointer'
    },
    total_without_discount:
    {
        fontSize:'12px',
        color:theme.palette.colors.grey[900],
        textDecoration:'line-through'
    },
    membershipCardContent: {
        border: "0.5px solid",
        borderColor: theme.palette.colors.violet[300],
        background: 'rgba(159, 111, 251, 0.1)',
    }
}));
