import React, { useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import confetti from 'canvas-confetti';
//Material-ui
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
//Shared
import LoaderContent from '../Loader/LoaderContent';
import { setCouponData, setFreeCouponPurchase, setOrderConcepts, setOrderPendingDiscount, setOrderQuantities, setPurchaseOrder } from '../../../redux/actions/legacyCheckoutActions.js';
import { log } from '../../../shared/utils/console.js'
//SDK-POINT
import { MarketPlaceService } from '@sdk-point/talisis';
import clsx from 'clsx';
import { getOrderQuantityOff, updateOrderQuantitiesDiscount } from '../../../shared/utils/checkoutHelpers-legacy.js';

const useStyles = makeStyles((theme) => ({
    btn: {
        width: '100%',
        paddingLeft: "12px",
        paddingRight: "12px"
    },
    input: {
        width: '100%',
        marginRight: '46px',
        borderColor: `${theme.palette.colors.violet[300]}`,
        '& div':
        {
            borderColor: `${theme.palette.colors.violet[300]}`
        }
    },
    inputCode:{
        width: "100%",
        pointerEvents: props => props.typeMessage !='' ? "none" : "inherit",
        backgroundColor:props => props.typeMessage=='success' ? 'rgb(0 146 86 / 10%)': props.typeMessage=='warning' && 'rgb(179 157 47 / 10%)',
        '& .MuiInputBase-root':{
            marginTop: "0px",
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
            transform: "translate(0px, -15px) scale(0.75) !important",
        }
    },
    inputIconSucces:{
        fontSize: '24px',
        color: theme.palette.colors.success[300]
    },
    inputIconWarning:{
        fontSize: '24px',
        color: theme.palette.colors.warning[300]
    }
}));

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

const CouponChecker = (props) => {
    
    const { validCoupon, setValidCoupon, alertCupon, setAlertCupon, txtCupon, setTxtCupon, loadingCoupon, setLoadingCoupon , typeMessage, setTypeMessage, disabledCoupon} = props;
    const { order, orderQuantity: quantityReducer, membershipOrderFeePlans } = useSelector((state) => state.legacyCheckoutReducer);
    const dispatch = useDispatch();
    const classes = useStyles({typeMessage});
    const [alertMessage, setAlertMessage] = useState("");

    let count = 200;
    let defaults = { origin: { y: 0.7 } };

    const fire = (particleRatio, opts) => {
        confetti(Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * particleRatio)
        }));
    }

    const randomInRange = (min, max) => Math.random() * (max - min) + min;

    const checkCupon = async () => {
        try {
            if (txtCupon.length > 0) {
                setLoadingCoupon(true);
                setAlertCupon(false);

                const response = await marketService.postCoupon(txtCupon, {
                    order
                });
                
                if (response.valid_coupon == 0) {
                    setTypeMessage('warning')
                    setValidCoupon(false);
                    setAlertMessage(response.message);
                    setAlertCupon(true);
                }
                else if (response.valid_coupon == 1) {
                    setTypeMessage('success')
                    setValidCoupon(true);
                    setAlertMessage(response.message);
                    setAlertCupon(true);
                    const couponData = response.couponData[0];
                    dispatch(setCouponData(couponData))

                    dispatch(setPurchaseOrder(response.order))
                    
                    dispatch(setOrderConcepts([...response.order.conceptos]))
                
                    dispatch(setOrderPendingDiscount(response.order.pending_discount))
                    
                    const quantityOff = getOrderQuantityOff( membershipOrderFeePlans?.membershipPlans, membershipOrderFeePlans?.selectedPlan );
                    const orderQuantities = updateOrderQuantitiesDiscount(response?.order?.pending_discount, quantityOff, response?.order?.discount, quantityReducer);
                    dispatch(setOrderQuantities({
                        ...orderQuantities,
                        order_subtotal: response.order.subtotal,
                        order_discount: response.order.discount,
                        order_total: response.order.total,
                    }));

                    if (response.couponData[0].discount_percentage === 100 || (response.couponData[0].value === 100 && response.couponData[0].discount_type_id == 3) || (response.order.total <= 0 && response.couponData[0].discount_type_id == 2) || (response.order.total <= 0)) {
                        dispatch(setFreeCouponPurchase(true))
                        fire(0.25, {
                            spread: 26,
                            startVelocity: 55,
                        });
                        fire(0.2, {
                            
                        });
                        fire(0.35, {
                            spread: 100,
                            decay: 0.91,
                            scalar: 0.8
                        });
                        fire(0.1, {
                            spread: 120,
                            startVelocity: 25,
                            decay: 0.92,
                            scalar: 1.2
                        });
                        fire(0.1, {
                            spread: 120,
                            startVelocity: 45,
                        });
                    }
                    else {
                        dispatch(setFreeCouponPurchase(false))
                        confetti({
                            angle: randomInRange(55, 125),
                            spread: randomInRange(50, 70),
                            particleCount: randomInRange(50, 100),
                            origin: { y: 0.6 }
                        });
                    }
                }
                setLoadingCoupon(false);
            }
            else {
                setValidCoupon(false);
                setAlertMessage("Escriba un código para continuar");
                setAlertCupon(true);
            }
        }
        catch (e) {
            log(e);
            setAlertCupon(true);
        }
    };

    const handleCheckCupon = (e) => {
        let value = e.target.value;

        validCoupon != true &&
            setTxtCupon(value);
    };

    return (
        <React.Fragment>
            <Box className='pt-3'>
                <Grid container spacing={1} direction="column" >
                    <Grid container spacing={1} direction="row">
                        <Grid item xs={typeMessage ==='' ? 9:12}>
                            <TextField className={`${classes.inputCode} text-gray-100`} id="outlined-basic" placeholder='Ingresa código de cupón' variant="outlined" value={txtCupon} onChange={handleCheckCupon} style={{ border: typeMessage==='success' ? '1px solid #01ED8C': typeMessage==='warning'  && txtCupon.length>0 &&  '1px solid #FCDC43', borderRadius:8}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{ typeMessage==='success' && <i className={clsx(classes.inputIconSucces,'ri-checkbox-circle-fill')} />} {typeMessage==='warning' && txtCupon.length>0 && <i className={clsx(classes.inputIconWarning,'ri-error-warning-fill')} />}</InputAdornment>,
                                backgroundColor: "red"
                              }}
                              disabled={disabledCoupon}
                            />
                        </Grid>
                        {typeMessage==='' && <Grid item xs={3}>
                                            <Button className= {classes.btn} variant="contained" disabled={txtCupon.length>0 ? false:true} onClick={checkCupon} color='primary' style={{maxWidth: "96px", height: "41px" }}>
                                                <LoaderContent loading={loadingCoupon}>
                                                    {
                                                        loadingCoupon === true ?
                                                            "Espere..."
                                                            :
                                                            "Aplicar"
                                                    }
                                                </LoaderContent>
                                            </Button>
                                        </Grid>
                        }
                    </Grid>
                    {alertCupon && txtCupon.length>0 && <Grid item xs="auto">
                        <Box hidden={!alertCupon}>
                            <Alert icon={false} style={{ background: 'transparent', fontSize: 14, color:validCoupon === true? '#01ED8C': '#FCDC43', padding:0}} hidden={true}>{alertMessage}</Alert>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default CouponChecker;
