import React from 'react';
import PropTypes from 'prop-types';

// material components
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// styles
import clsx from 'clsx';
import useStyles from '../../../assets/styles/jss/components/loaderStyle'

const Loader = ({open, bgTransparent, message, helpColor=false}) => {
    const classes = useStyles({helpColor});
    return (
        <Backdrop open={open} className={clsx(classes.root, { 'transparent': bgTransparent })}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <CircularProgress className={classes.loaderCircular} />
                {message && <Typography variant="subtitle1" className="mb-2 mt-2">{message}</Typography>}
            </Box>
           
        </Backdrop>
    );
};

Loader.defaultProps = {
    open: false,
    bgTransparent: false,
};

Loader.propTypes = {
    open: PropTypes.bool,
    bgTransparent: PropTypes.bool,
};

export default Loader;