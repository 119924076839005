import React from 'react';
import { useHistory } from 'react-router-dom';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import { Box, Container, Grid, Hidden, useMediaQuery } from '@material-ui/core';

// components
import Feed from '../Feed/Feed'
import Events from '../Events/EventList/EventList'
import Menu from './Menu/Menu';
import ProductsAssignments from '../../pages/Courses/ProductsAssignments/ProductsAssignments.jsx';

// Services
import * as userService from '../../services/UserService';

// Contants
import {TAB_HOME_NAME} from '../../shared/constants/tabs';

// Styles
import useStyles from '../../assets/styles/jss/pages/home/homeStyle';
import FeedAlerts from '../Feed/FeedAlerts';

const tabsData = [
    {
        id: 0,
        tab: 'Feed',
        icon: 'ri-home-5-line',
        tabName: TAB_HOME_NAME.FEED,
        component: <Grid container> <Grid item md={8}> <Feed /> </Grid> <Grid item md={4}> </Grid> </Grid>
    },
    {
        id: 1,
        tab: 'Eventos',
        icon: 'ri-calendar-event-fill',
        tabName: TAB_HOME_NAME.EVENTS,
        component: <Grid container> <Grid item md={12}> <Events /> </Grid> </Grid>
    },
];

const getTabId = (tabName) => {
    const tabNameTemp = tabName ?? TAB_HOME_NAME.FEED
    const tabNameLowerCase = tabNameTemp.toLowerCase();
    
    const tab = tabsData.find(tab => tab.tabName === tabNameLowerCase);

    return tab.id ?? tabsData[0].id;
};

const Home = ({user, roles, pageId, profilePic}) => {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const tabName = queryParams.get('tab');

    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles({isResponsive});

    const [activeTab, setActiveTab] = React.useState(getTabId(tabName));
    const [canReadWrite, setCanReadWrite] = React.useState(false);
    const [userRole, setUserRole] = React.useState('');

    const handleChangeTab = (id) => {
        const tab = tabsData.find(tab => tab.id === id);
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?tab=${tab.tabName}`;
        window.history.pushState({path:newurl},'',newurl);

        setActiveTab(id);
    };

    React.useEffect(() => {
        setTimeout(() => {
            const tempCanReadWrite = userService.canReadWrite(pageId);
            setCanReadWrite(tempCanReadWrite);
        }, 3000)
    }, []);
    
    React.useEffect(() => {
        setUserRole(!!roles.length ? roles[0].role_name.replace(/["']/g, "") : '');
    }, [roles]);

    return (
        <Grid container className={classes.homeRoot}>
            <ProductsAssignments/>
            <Grid item md={isResponsive ? 12 : 3} className={classes.menuContainer}>
                <Menu data={tabsData} onChangeTab={handleChangeTab} user={user} canReadWrite={canReadWrite} activeTab={activeTab} userRole={userRole} profilePic={profilePic} isResponsive={isResponsive}/>
            </Grid>
            <Hidden smUp>
                <Grid item md={12}>
                    <FeedAlerts />
                </Grid>
            </Hidden>
            <Grid item md={isResponsive ? 12 : 6} >
                <Box className={classes.componentContainer}>
                    { activeTab === 0 && 
                        <Box maxWidth={isResponsive ? '100%' : '90%'}>
                            <Feed user={user} userRole={userRole} pageId={pageId} canReadWrite={canReadWrite} isResponsive={isResponsive}/>
                        </Box>
                    }
                    {
                        activeTab === 1 &&
                        <Events />
                    }
                </Box>
            </Grid>
            <Hidden smDown>
                <Grid item md={3} >
                    <FeedAlerts />
                </Grid>
            </Hidden>
        </Grid>
    );
};

const mapStateToProps = ({ userReducer, profilePicReducer, permissionReducer}) => ({
    ...userReducer,
    profilePic: profilePicReducer.profilePic,
    roles: permissionReducer.roles
});

export default connect(mapStateToProps, {})(Home);