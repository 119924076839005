import React from 'react';

// @material-ui/core
import { Hidden, makeStyles, } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// core
import CustomizedAccordions from './CustomizedAccordion/CustomizedAccordion';
import CustomizedLessons from './CustomizedAccordion/CustomizedLessonBlock';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '16px',
        marginBottom: '32px',
    },
    expandLink: {
        cursor: 'pointer'
    },
    gridTitle:{
        marginBottom: 24,
        [theme.breakpoints.down('xs')]:{
            marginBottom: 4,
            '& .title':{
                marginBottom: 4
            }
        }
    }
}));

function counterLessons(datos) {
    let cantidadTotal = 0;
  
    // Recorrer cada nodo en el array de datos
    datos.forEach((nodo) => {
      // Verificar si el nodo tiene la propiedad "topics" y es un array
      if (nodo.topics && Array.isArray(nodo.topics)) {
        // Sumar la longitud del array "topics" al total
        cantidadTotal += nodo.topics.length;
      }
    });
  
    return cantidadTotal;
  }

const Topics = ({ data }) => {
    const classes = useStyles();
    const [expandAll,setExpandAll]=React.useState(false);

    console.log('data', data)

    const toggleExpandAll=({event})=>{
        setExpandAll(!expandAll);
    };

    if (!(data && data.length > 0)) {
        return null;
    }

    return (
        <div className="pb-2" id="topics-root">
            <Grid container>
                <Grid item xs={12} className={classes.gridTitle}>
                    <Typography variant="h3" className='title'>Contenido del curso</Typography>
                    <Hidden smUp>
                    <Typography variant="caption" className='text-gray-400'>{`${data.length} temas, ${counterLessons(data)} lecciones`}</Typography>
                    </Hidden>
                </Grid>
            </Grid>
            { data[0].section_type_id == 1 && data[0]?.topics.length != 0 ? 
                data.map((el) => (
                    <CustomizedAccordions
                        key={`topic-${el.id}`}
                        id={`topic-${el.id}`}
                        title={el.description}
                        topics={el.topics}
                        allExpanded={expandAll}
                    >
                        {
                            el.topics.length > 0 ? (
                                <List dense>
                                    {
                                        el.topics.map(t => (
                                            <ListItem key={t.id} alignItems="flex-start">
                                                <ListItemText primary={
                                                    <Typography variant="h6">{t.description}</Typography>
                                                }></ListItemText>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            ) : null
                        }
                    </CustomizedAccordions>
                ))
            :    
                data.map((el) => (
                    <CustomizedLessons 
                        id={`topic-${el.id}`}
                        title={el.name}>

                    </CustomizedLessons>
                ))
            }
        </div>
    );
};

export default Topics;