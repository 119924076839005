import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        maxWidth: "536px",
        marginTop: "8px",
        marginBottom: "8px",
        position: "relative"
    },
    discountCouponContainer: {
        display: "flex",
        padding: "8px 0px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "5px",
        border: "0.5px solid var(--degradados-degradado-3, #FCDC43)",
    },
    alert: {
        position: 'absolute',
        // bottom: '-38px',
        // top: '50%',
        // right: 'calc(50% - 60px)',
        left: 'calc(50% - 60px)',
        zIndex: 1,
        '& .MuiAlert-root': {
            border: `0.5px solid ${theme.palette.colors.success[300]}`,
            background: theme.palette.colors.success[100],
            '& .MuiAlert-icon': {
                padding: 0,
                fontSize: 16,
                color: theme.palette.colors.success[400]
            },
            '& .MuiAlert-message': {
                padding: 0,
                ...theme.typography.bodyTextSmallSemiBold,
                color: theme.palette.colors.darkBlack[500],
            }
        }
    },
}));