import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'

// @material-ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import * as paths from '../../../../../routes/paths'
import clsx from 'clsx';

// shared
import FloatingContainerMenu from '../FloatingContainerMenu/FloatingContainerMenu';
import SimpleSearchWithFilter from '../../../../../shared/components/SimpleSearchWithFilter/SimpleSearchWithFilter';

const useStyles = makeStyles((theme) => ({
    filterContainer: {
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.colors.fillStroke[400],
        padding: '0 16px',
      },
      filterSubcontainer: {
        maxWidth: '1249px',
        width: '100%'
    },
}));

const SearchButton = ({isLogged, classes: className}) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const wrapperRefContainerNav = React.useRef(null);
    const [openContainerNav, setOpenContainerNav] = React.useState(false);
    const [textFilter, setTextFilter] = React.useState('');

    const handleChangeOpenContainerNav = (value) => {
        setOpenContainerNav(value);
    }; 

    function useOutsideAlerterContainerNav(ref) {
        React.useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutsideContainerNav(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setTextFilter('');
                handleChangeOpenContainerNav(false);
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutsideContainerNav);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutsideContainerNav);
          };
        }, [ref]);
    }

    const handleKeyPressFilter = (event) => {
        if (event.key === 'Enter' && textFilter && textFilter.replace(/ /g,'') != "") {
            setOpenContainerNav(false);
            history.push(`${paths.BUSQUEDA}?buscar=`  +textFilter);
            if (location.pathname === paths.BUSQUEDA) history.go(0);
        }
    };

    const handleChangeFilter = (event) => {
        setTextFilter(event.target.value);
    };

    useOutsideAlerterContainerNav(wrapperRefContainerNav);

    return (
        <>
            <IconButton aria-label="buscar" onClick={() => handleChangeOpenContainerNav(true)} 
                className={clsx({'active': location.pathname === paths.BUSQUEDA}, className)}>
                <Icon className="ri-search-line font-size-20 text-dark-black-100"/>
            </IconButton>
            <FloatingContainerMenu wrapperRef={wrapperRefContainerNav} open={openContainerNav} isLogged={isLogged}>
                <Box className={classes.filterContainer}>
                    <Container className={classes.filterSubcontainer}>
                        <SimpleSearchWithFilter onChange={handleChangeFilter} placeholder={"Buscar"} text={textFilter} onClickFilterButton={null} showFilterButton={false} onKeyPress={handleKeyPressFilter}/>
                    </Container>
                </Box>
            </FloatingContainerMenu>
        </>
    );
};

export default SearchButton;