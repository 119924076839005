import React, { useEffect } from 'react';
import clsx from 'clsx';

// @components
import * as MEDIA from '../../components/genericBrandLandingPageMedia';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardMedia, Typography, Button, Box, Hidden, Chip } from '@material-ui/core';

import BrandProgramCardResponsive from './BrandProgramCardResponsive';

import { eventTracker, eventData } from '../../../../shared/components/EventTracker/EventTracker';

const useStyles = makeStyles((theme) => ({
    circularProgress: {
        height: '15px !important',
        width: '15px !important',
    },
    root: {
        width: '100%',
    },
    card: {
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 27.08%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(147.53deg, #FCE694 0%, #F6E23A 45.83%, #F1BA28 98.96%)',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        borderRadius: '8px',
        position: 'relative',
    },
    media: {
        height: '457px',
        width: '100%',
    },
    headerCardText: {
        color: theme.palette.colors.black,
        left: '0px',
        right: '0px',
        position: 'absolute',
        top: '8%',
        '@media (max-width: 600px)': {
            fontSize: '20px',
        }
    },
    button: {
        background: 'rgba(160, 169, 186, 0.1)',
        border: '1px solid #FFFF00',
        boxShadow: '0px 6px 11px rgba(0, 0, 0, 0.1)',
        textTransform: 'none',
        marginBottom: 16,
        '&:hover': {
            border: '1px solid #FFFF00',
            background: 'rgba(160, 169, 186, 0.2)',
        }
    },
    program: {
        background: theme.palette.colors.black,
        borderRadius: '4px',
        fontSize: '14px',
        height: '24px',
        left: '0px',
        margin: '0 auto',
        padding: '4px 8px',
        position: 'absolute',
        right: '0px',
        top: '87%',
        width: '12rem',
    },
    cardStandar: {
        backgroundSize: 'cover !important',
        backgroundPosition: 'center !important',
        background: props => `url(${props.imgStandar}), linear-gradient(147.53deg, #FCE694 0%, #F6E23A 45.83%, #F1BA28 98.96%)`,
        '&:hover' : {
            background: props => `linear-gradient(180deg, rgba(0, 0, 0, 0) 27.08%, #000000 100%), url(${props.imgStandar}), linear-gradient(147.53deg, #FCE694 0%, #F6E23A 45.83%, #F1BA28 98.96%)`,
        }
    },
    cardPlus: {
        backgroundSize: 'cover !important',
        backgroundPosition: 'center !important',
        background: props => `url(${props.imgPlus}), linear-gradient(147.53deg, #FCE694 0%, #F6E23A 45.83%, #F1BA28 98.96%)`,
        '&:hover' : {
            background: props => `linear-gradient(180deg, rgba(0, 0, 0, 0) 27.08%, #000000 100%), url(${props.imgPlus}), linear-gradient(147.53deg, #FCE694 0%, #F6E23A 45.83%, #F1BA28 98.96%)`,
        }
    },
    cardElite: {
        backgroundSize: 'cover !important',
        backgroundPosition: 'center !important',
        background: props => `url(${props.imgElite}), linear-gradient(147.53deg, #FCE694 0%, #F6E23A 45.83%, #F1BA28 98.96%)`,
        '&:hover' : {
            background: props => `linear-gradient(180deg, rgba(0, 0, 0, 0) 27.08%, #000000 100%), url(${props.imgElite}), linear-gradient(147.53deg, #FCE694 0%, #F6E23A 45.83%, #F1BA28 98.96%)`,
        }
    }
}));

const levelConfig = {
    'ONLINE-STANDARD': {
        key: 'ONLINE-STANDARD',
        title: 'Online Standard',
        textBadge: 'Clases grupales 24/7',
        imgResponsive: 'standard-card-responsive-hh.png',
    }, 
    'ONLINE-PLUS': {
        key: 'ONLINE-PLUS',
        title: 'Online Plus',
        textBadge: '6 clases privadas al mes',
        imgResponsive: 'plus-card-responsive-hh.png',
    }, 
    'ONLINE-ELITE': {
        key: 'ONLINE-ELITE',
        title: 'Online Elite',
        textBadge: '13 clases privadas al mes',
        imgResponsive: 'elite-card-responsive-hh.png',
    }
};

const BrandLandingProgramsCardHarmonHall = (props) => {
    const { className } = props;
    const CARD_IMG = MEDIA.getBrandLandingPagesProgramCardImg('harmonhall');
    const imgStandar = CARD_IMG['ONLINE-STANDARD'];
    const imgPlus = CARD_IMG['ONLINE-PLUS'];
    const imgElite = CARD_IMG['ONLINE-ELITE'];
    const classes = useStyles({imgStandar, imgPlus, imgElite});

    useEffect(() => {
        eventTracker('s_hh', eventData, ['mixpanel']);
    }, []);

    const handleClick = (id) => {
        const container = document.getElementById(id);
        if (container) {
            container.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Box>
            <Hidden id="desktop" xsDown>
                <Typography variant="h3" align="center" className="mb-3">Conoce nuestras licencias</Typography>
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" className={className}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={clsx(classes.card)}>
                            <Typography variant="h4" className={classes.headerCardText}>Online Standard</Typography>
                            <CardMedia
                                className={clsx(classes.media, classes.cardStandar)}
                                component="div"
                                title="Online Standard"
                            />
                            <Box position='absolute' bottom={24} width={1}>
                                <Box width={156} margin='0 auto'>
                                    <Button variant="outlined" size={'small'} color="primary" className={classes.button} onClick={() => handleClick('detail')}>
                                        <Typography variant="body2" className='semi-bold'>Ver más</Typography>
                                    </Button>
                                    <Chip className='chip1-hh-black' label='Clases grupales 24/7' />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={clsx(classes.card)}>
                            <Typography variant="h4" className={classes.headerCardText}>Online Plus</Typography>
                            <CardMedia
                                className={clsx(classes.media, classes.cardPlus)}
                                component="div"
                                title="Online Plus"
                            />
                            <Box position='absolute' bottom={24} width={1}>
                                <Box width={156} margin='0 auto'>
                                    <Button variant="outlined" size={'small'} color="primary" className={classes.button} onClick={() => handleClick('detail')}>
                                        <Typography variant="body2" className='semi-bold'>Ver más</Typography>
                                    </Button>
                                    <Chip className='chip1-hh-black' label='6 clases privadas al mes' />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={clsx(classes.card)}>
                            <Typography variant="h4" className={classes.headerCardText}>Online Elite</Typography>
                            <CardMedia
                                className={clsx(classes.media, classes.cardElite)}
                                component="div"
                                title="Online Elite"
                            />
                            <Box position='absolute' bottom={24} width={1}>
                                <Box width={156} margin='0 auto'>
                                    <Button variant="outlined" size={'small'} color="primary" className={classes.button} onClick={() => handleClick('detail')}>
                                        <Typography variant="body2" className='semi-bold'>Ver más</Typography>
                                    </Button>
                                    <Chip className='chip1-hh-black' label='13 clases privadas al mes' />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Hidden>

            <Hidden id="mobil" smUp>
                <Typography variant="h3" align="left" className="offset-mb-8">Conoce nuestras licencias</Typography>

                <Box display="flex" flexDirection="column" gridGap="16px">
                    {
                        Object.keys(levelConfig).map(level => (
                                <BrandProgramCardResponsive
                                    brand="harmonhall" 
                                    bgImage={`/images/brands/${levelConfig[level].imgResponsive}`}
                                    title={levelConfig[level].title}
                                    textBttn="Ver más"
                                    textBadge={levelConfig[level].textBadge}
                                    onClickBttn={() => handleClick('detail')}
                                />
                            )
                        )
                    }
                </Box>
            </Hidden>
        </Box>
    )
};

export default BrandLandingProgramsCardHarmonHall;