export const processGroups = (data) => {
    
    const {groups, level, id, ref} = data;
    const inputRefChecked = ref.checked;
    
    let newGroups = [];
    switch (level) {
        case "all_groups":
            newGroups = groups.map(group => {
                group.members = group.members.map(member => {
                    member.checked = !member.checked;
                    member.checked = inputRefChecked ? true : false;
                    return member;
                });
                group.checked = !group.checked;
                group.checked = inputRefChecked ? true : false;
                return group;
            });
            break;
        case "group":
            let groupsChecked = [];
            newGroups = groups.map(group => {
                if (group.id === id) {
                    group.members = group.members.map(member => {
                        member.checked = !member.checked;
                        member.checked = inputRefChecked ? true : false;
                        return member;
                    });
                    group.checked = !group.checked;
                }
                groupsChecked.push(group.checked);
                return group;
            });
            break;
        case "member":
            let groupMembersChecked = [];
            newGroups = groups.map(group => {
                group.members = group.members.map(member => {
                    if (member.person_id === id) {
                        member.checked = !member.checked;
                    }
                    groupMembersChecked.push(member.checked);
                    return member;
                });
                return group;
            });
            break;
    }

    return {
        newGroups: () => {
            return newGroups;
        },
        indeterminateGroups: () => {
            const selectedGroups = newGroups.filter(group => group.checked);
            return (newGroups.length > selectedGroups.length && selectedGroups.length > 0) ? true : false;
        },
        selectedGroups: () => {
            return newGroups.filter(group => group.checked);
        },
        selectedGroupMembersIds: () => {
            const selectedGroupMembersIds = [];
            newGroups.forEach(group => {
                selectedGroupMembersIds.push({[group.id] : group.members.filter(member => member.checked).map(member => member.person_id)})
            });
            return selectedGroupMembersIds;
        },
        selectedMembers: () => {
            const groupMembers = {};
            newGroups.forEach(group => {
                groupMembers[group.id] = group.members.filter(member => member.checked);
            });
            return groupMembers;
        },
    };
};