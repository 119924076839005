import React from 'react'

// redux
import { useSelector } from 'react-redux';

// Logos
import LogoTalisis from '../../../../assets/images/logos/logotipo-talisis.png';
import LogoUerre from '../../../../assets/images/logos/logo-uerre-blanco.png';
import LogoUnid from '../../../../assets/images/logos/logo-unid-blanco.png';
import LogoHarmonHall from '../../../../assets/images/logos/logo-harmon-hall.png';
import LogoIeSalud from '../../../../assets/images/logos/logo-iesalud.png'

const LogoOu = ({ logoWidth = 80}) => {

  const { ou } = useSelector((state) => state.userReducer.user);

  const checkUnidadOperativa = (ou) => {
    switch (ou) {
      case 'talisis':
        return LogoTalisis;
      case 'uerre':
        return LogoUerre;
      case 'unid':
        return LogoUnid;
      case 'hh':
        return LogoHarmonHall;    
      case 'iesalud':
        return LogoIeSalud; 
      default:
        return LogoTalisis;
       
    }
  }

  return (
    <>
        <img alt="logo" src={checkUnidadOperativa(ou)} style={{maxWidth: logoWidth}} />
    </>
  )
}

export default LogoOu
