import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import CurrencyFormat from 'react-currency-format'

const PriceSubtotal = ({orderQty}) => {
    return (
        <Grid container justifyContent="space-between" alignItems='baseline'>
            <Grid item>
                <Typography variant="body1" className="text-white semi-bold">
                    Sub-total
                </Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={1} alignItems="baseline">
                    {/* <Grid item>
                        <Typography className="variant-subheading-3 text-dark-black-100">
                            {`${orderQty?.plan_months} ${orderQty?.plan_months !== 1 ? 'meses' : 'mes' } x `}
                            <CurrencyFormat
                                value={(orderQty?.order_subtotal/orderQty?.plan_months)} className="variant-subheading-3 text-dark-black-100"
                                // decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="" suffix='MXN' 
                                decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix=""
                            />
                        </Typography>
                    </Grid> */}
                    <Grid item>
                        <Typography variant="h5" className="text-orange-300">
                            <CurrencyFormat
                                value={orderQty?.order_subtotal} decimalScale={2} fixedDecimalScale={true} 
                                // displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' 
                                displayType="text" thousandSeparator={true} prefix="$"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PriceSubtotal
