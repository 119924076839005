import isEmail from "../../../../../../shared/utils/isEmail";
import isName from "../../../../../../shared/utils/isName";
import { isValidPhoneNumber } from "../../../../../../shared/utils/isValidPhoneNumber";

export const validateFormFields = ({ formFields }) => {
    const requiredFields = Object.keys(formFields);
    const _errors = {};

    const { message: errorMessage, error: hasPhoneError } = isValidPhoneNumber(formFields['phoneNumber']);

    for(const field of requiredFields) {
        if (!formFields[field]) {
            _errors[field] = 'Campo Requerido';
        }
    }

    if(!isName(formFields['firstName']) ){
        _errors['firstName'] = 'Solo se permiten letras en este campo'
    }
    if(!isName(formFields['lastName1'])){
        _errors['lastName1'] = 'Solo se permiten letras en este campo'
    }
    if(!isName(formFields['lastName2'])){
        _errors['lastName2'] = 'Solo se permiten letras en este campo'
    }

    if(hasPhoneError){
        _errors['phoneNumber'] = errorMessage
    }

    if(formFields['email1'] !== formFields['email2']) {
        _errors['email2'] = 'Los correos no coinciden'
    }
    
    if(!isEmail(formFields['email1'])){
        _errors['email1'] = 'El email no es valido'
    }

    const isFormComplete = Boolean(Object.keys(_errors).length === 0);
    
    return {
        _errors,
        isFormComplete
    }
};
