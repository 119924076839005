import React from 'react';

// @components
import { SlideShow } from '../../../../shared/components/SlideShow/SlideShow';
import * as MEDIA from '../genericBrandLandingPageMedia';


// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

const MEDIA_HEIGHT = '31vw';
const useStyles = makeStyles((theme) => ({
    mediaContainer: {
        width: '100%'
    },
    media: {
        height: MEDIA_HEIGHT,
        width: '100%',
        position: 'relative'
    },
    dot: {
        marginTop: '20px',
        position: 'absolute',
        textAlign: 'center',
        top: 'auto',
        '& .dot': {
            backgroundColor: theme.palette.colors.grey[400],
            height: '1vw',
            width: '1vw',
        },
        '& .SlideShowActive': {
            backgroundColor: `${theme.palette.colors.white} !important`,
        }
    },
    unid_xcontent: {
        left: '15vw',
        height: '100%',
        position: 'absolute',
        textAlign: 'left',
        zIndex: 1,
        '& .xcontent_media': {
            width: '22vw',
        },
        '& .xcontent_button': {
            borderRadius: '8px',
            fontSize: '1vw',
            padding: 0,
            paddingTop: '3%',
            paddingBottom: '3%',
            textTransform: 'none',
            width: '18vw',
        },
    },
    harmonhall_xcontent: {
        display: 'table-caption',
        float: 'right',
        marginTop: '12vw',
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        zIndex: 1,
        '& .xcontent_h2': {
            fontSize: '2vw',
            lineHeight: '0',
        },
        '& .xcontent_subtitle2': {
            fontSize: '1vw',
            marginTop: '1.5vw',
        },
        '& .xcontent_button': {
            borderRadius: '8px',
            fontSize: '1vw',
            marginTop: '1vw',
            padding: 0,
            paddingTop: '1%',
            paddingBottom: '1%',
            textTransform: 'none',
            width: '16vw',
        },
    }
}));

const GenericBrandLandingPageSlide = (props) => {
    const { match: { params: { brand } } } = props;
    const classes = useStyles();

    const slides = MEDIA.getBrandLandingPagesSlideImgs(brand);
    let slidesInfo = MEDIA.getBrandLandingPagesSlideImgsInfo(brand);
    if (slidesInfo.length > 0) {
        slidesInfo = slidesInfo.map(info => {
            return (
                <table className={classes[`${brand}_xcontent`]}>
                    <tbody>
                        <tr>
                            <td height={'100%'}>
                                {brand === 'unid' && <img src={info['image']} className="xcontent_media" />}
                                {/*
                                @NOTE: Desactivados temporalmente en lo que se define para el ciclo posterior
                                si se utilizará y asignar un link o totalmente retirar.
                                <br />
                                <Button size="small" variant="contained" color="primary" className="xcontent_button" onClick={() => window.open(info['link'], '_blank')}>Ver más</Button>
                                */}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        });
    }
    else {
        if (brand === 'harmonhall') {
            slidesInfo.push(
                <Box className={classes[`${brand}_xcontent`]}>
                    <Typography variant="h2" className="xcontent_h2">CURSO DE ESPECIALIZACIÓN DE INGLÉS</Typography>
                    <Typography variant="subtitle2" className="xcontent_subtitle2">Especialízate y domina el inglés como un experto</Typography>
                    <Button size="small" variant="contained" color="primary" fullWidth className="xcontent_button" onClick={() => {
                        window.open('https://lll.harmonhall.com/', '_blank');
                    }}>Conoce más</Button>
                </Box>
            );
        }
    }

    return (
        <Box height='calc(31vw + 38px)' style={{backgroundColor: (brand !== 'harmonhall' && '#1c1f24')}}>
            <div className={classes.media}>
                <SlideShow name={`brand_landing_page_${'unid'}`} src={slides} className={classes.mediaContainer} mediaCSSClass={classes.media} dotCSSClass={classes.dot} enableArrows={true} mediaHeight={MEDIA_HEIGHT} xContent={slidesInfo} />
            </div>
        </Box>
    )
};

export default GenericBrandLandingPageSlide;