import React from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';

const UserRoles = (props) => {
    const { data: role } = props;
    return (
        <Grid item xs={3} key={`grd_role_${role.id}`}>
            <Typography variant="body2"><Checkbox checked={true} readOnly color="default" />{role.name}</Typography>
        </Grid>
    )
};

export default UserRoles;