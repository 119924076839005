const SOURCES = [
    {id:'1', name: 'Banner'},
    {id:'2', name: 'CRM'},
    {id:'3', name: 'SAP'},
    {id:'4', name: 'Neo'},
    {id:'5', name: 'Coursera'},
    {id:'6', name: 'LinkedIn Learning'},
    {id:'7', name: 'TALISIS'},
    {id:'8', name: 'U-ERRE'},
    {id:'9', name: 'UNID'},
    {id:'10', name: 'Harmon Hall'},
    {id:'11', name: 'IESALUD'},
    {id:'12', name: 'Google SkillShop'},
    {id:'13', name: 'Crehana'},
    {id:'14', name: 'Hubspot Academy'},
    {id:'15', name: 'IE Publishing'},
    {id:'16', name: 'Central Test'},
    {id:'17', name: 'Symplicity'},
]


export const nameSource = (id) =>{
    return SOURCES.find((source)=> source.id == id).name
}