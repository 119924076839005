import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

// @redux
import {connect} from 'react-redux';

// @design-system
import LoaderContent from '../../../../design-system/components/Loader/LoaderContent';
import Toast from '../../../../design-system/components/Toast/Toast';
import Table from '../../../../design-system/components/Table/Table';
import Select from '../../../../design-system/components/Form/Select/Select';
import dateFormat from "../../../../design-system/utils/dateFormat";
import {DATE_FORMAT_LOCAL, ISO_DATE_FORMAT} from "../../../../design-system/constants/date-formats";

// @material-core
import {
  Icon, Grid, Link, Box, Button, Card, CardContent, Typograph, Modal, Tooltip, CardHeader, CardActions
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Close from "@material-ui/icons/Close";

// @ThirtyParty
import {
  clone,
  concat,
  dropRepeats,
  findIndex,
  groupBy,
  groupWith,
  indexOf,
  isEmpty,
  isNil,
  join,
  propEq, reverse, sort,
  values
} from 'ramda';
import queryString from 'query-string'
import clsx from 'clsx';
import writeXlsxFile from 'write-excel-file'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import moment from 'moment';

// @local
import {certificate, excelCertificateColumns, kardex as columnsKardex} from '../../ColumnsTable';
import ModalDetails from '../../Student/ModalDetails';
import {compareFirstInSecondArray, stringContainsExactlyOf} from '../../../../shared/utils/common';
import {certificateStyles} from '../../../../assets/styles/jss/pages/degreeCertificates/CertificateStyles'
import {STATUS_PROCESS, API_RESPONSE_STATUS_ERROR} from "../../../../shared/constants/degree-certificate";

// SDK
import {DegreeAndCertificateService} from '@sdk-point/talisis';
import {REJECTED_STATUS} from "../../../../shared/constants/record-status";
import NothingFound from "../../../../shared/components/NothingFound/NothingFound";

const CertificateServiceSDK = new DegreeAndCertificateService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const REQUIRMENTS = [
  {descripcion: 'Acreditación del 100% de créditos del plan de estudio a certificar.', fecha: 'N/A'},
  {descripcion: 'Ser egresado.', fecha: '14/08/2018', extra: 'Informacion en el Tooltip'},
  {
    descripcion: 'Certificado de Secundaria Original, o con código QR.',
    fecha: '14/08/2018',
    extra: 'Informacion en el Tooltip'
  },
  {descripcion: 'Pago del certificado.', fecha: 'N/A'},
  {descripcion: 'Copia de cédula profesional legible.', fecha: '14/08/2018'},
  {
    descripcion: 'En su caso, carta de solicitud de emisión de certificado parcial como modalidad de titulación de su licenciatura. La carta debe indicar el número de créditos necesarios, o porcentaje de avance.',
    fecha: 'N/A'
  },

]
const FORMATREQUIREMENTS = {
  "reqIndCapCumplimiento": "CAPP con cumplimiento del 100%.",
  "reqIndPagoCertificacion": {"true": "Pago del certificado parcial.", "false": "Pago del certificado."},
  "reqIndDocumentacion": "Documentación.",
}
const useStyles = makeStyles(() => {
  const theme = useTheme();
  return ({
    table: {
      backgroundColor: theme.palette.colors.fillStroke[500],
    },
    borderButtom: {
      borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`
    },
    title: {
      color: theme.palette.colors.violet[300],
      fontWeight: 600
    },
    subTitle: {
      color: theme.palette.colors.gray[100]
    },
    icon: {
      fontSize: 24,
      width: 24
    },
    dateTitle: {
      color: theme.palette.colors.blue[300]
    },
    tooltipContainer: {
      backgroundColor: "#C4C4C4 !important",
      color: `${theme.palette.colors.fillStroke[400]} !important`,
    },
    tooltipArrowContainer: {
      color: "#C4C4C4 !important",
    },
    description: {
      color: theme.palette.colors.gray[50]
    }
  })
});
const RquirmentsDetails = (props) => {
  const classes = useStyles();
  return (
    <LoaderContent loading={props.loadingRequirements}>
      {!isNil(props.student?.requirements) && Object.keys(props?.student?.requirements).length > 0 ?
        <Box className={classes.table} borderRadius={8}>
          <Box display='grid' alignItems='center' flex={1}>
            <Box display='flex' flexDirection='column' className={clsx('p-3', classes.borderButtom)}>
              <Box display="flex" alignItems="center">
                <Typography variant={'body1'} className={clsx(classes.title, 'mb-1')}> UNID - Requisitos
                  para
                  certificación {props.student.isPartial ? 'parcial' : 'total'} {props.student.datosGenerales[0].nivel === 'LI' ? 'Licenciatura' : 'Maestria'}
                </Typography>
              </Box>
              <Typography variant={'caption'} className={classes.subTitle}>Requisitos
                cumplidos y no cumplidos por parte del alumno.
              </Typography>
            </Box>
            {Object.keys(props.student.requirements).map((value, idx) => (
              props?.student?.requirements[value]?.check !== 'N/A' && (
                <Box display='flex' flexDirection='row' key={idx}
                     className={clsx('pt-2 pb-2', (idx + 1) !== Object.keys(props?.student?.requirements).filter(idx => props?.student?.requirements[idx].check !== 'N/A').length &&
                       classes.borderButtom)}>
                  <Box display="grid" className={'pr-3 pl-3 pb-1 pt-1'} alignItems="center">
                    <Icon
                      className={clsx(`ri-${props.student.requirements[value].check === 'N' ? 'close' : 'checkbox'}-circle-fill`, classes.icon)}
                      style={{color: (props.student.requirements[value].check === 'N' ? '#F74F58' : '#01ED8C')}}/>
                  </Box>
                  <Box display="grid" alignItems="center" className={'pt-2 pb-2'}>
                    <Typography variant={'body2'}
                                className={clsx(classes.description, 'mb-1')}> {typeof FORMATREQUIREMENTS[value] === 'object' ? FORMATREQUIREMENTS[value][props.student.isPartial] : FORMATREQUIREMENTS[value]}
                      {!isEmpty(props?.student?.requirements[value]?.message) && (
                        <Tooltip title={props?.student?.requirements[value]?.message} arrow placement="top" interactive
                                 classes={{tooltip: classes.tooltipContainer, arrow: classes.tooltipArrowContainer}}>
                          <Icon className={'ri-information-line text-fill-stroke-100 ml-1'} style={{fontSize: 16}}/>
                        </Tooltip>
                      )}
                    </Typography>
                    {/*<Typography variant={'caption'} className={classes.dateTitle}>*/}
                    {/*  Fecha: {requirment.fecha}*/}
                    {/*</Typography>*/}
                  </Box>
                </Box>)
            ))}
          </Box>
        </Box>
        : <></>}
    </LoaderContent>
  )
};
const mapStateToProps = ({}) => ({});

const mapDispatchToProps = { /*...userActions, ...profilePicActions, ...menuActions, ...membershipActions */};
export default connect(mapStateToProps, mapDispatchToProps)(RquirmentsDetails);
