import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';

// shared - components
import Tabs from '../../shared/components/Tabs/Tabs'
import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';
import { log } from '../../shared/utils/console.js'

// - services
import * as userService from '../../services/UserService';

// @redux
import { connect } from "react-redux"
import * as requestWorkflowActions from '../../redux/actions/requestWorkflowActions'

// styles
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
  }));

const MembresiaManagment = (props) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        free: false,
        basic: false,
        pre: false,
      });
    
      const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };
    
    const { gilad, jason, antoine } = state;
 
    const tabs = ['Todos'];

    React.useEffect(()=> {
        getPermission(props.pageId)
    }, [props])

    const getPermission = (pageId) => {
        const canReadWrite = userService.canReadWrite(pageId);
        setState({...state, canReadWrite});
    }

    const handleChangeTab = tab => setState({activeTab: tab});

    const handleChangeMembresia = (e) => {
      log(e.target.value)
      switch(Number(e.target.value)){
        case 1: 
            setState({free: true, basic: false, pre: false})
            break;
        case 2: 
            setState({free: false, basic: true, pre: false})
            break;
        case 3: 
            setState({free: false, basic: false, pre: true})
            break;  
        default:
            setState({free: false, basic: false, pre: false})
            break;                     
      }
      
    }
    return (
        <Box>
            <Breadcrumb items={[
                {label: 'Membresía'}
            ]} />
            <Tabs
                onChange={handleChangeTab}
                activeTab={0}
                tabs={tabs}
                //actions={<NewRequestButton onClick={hanldeClickNewRequest}/>}
            >
                <h2>Membresias</h2>
                <div className={classes.root}>
                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel htmlFor="filled-age-native-simple">Selecciona</InputLabel>
                    <Select
                    native
                    value={state.age}
                    onChange={handleChangeMembresia}
                    inputProps={{
                        name: 'age',
                        id: 'filled-age-native-simple',
                    }}
                    >
                    <option aria-label="None" value="Seleccina" />
                    <option value={1}>FREEMIUM</option>
                    <option value={2}>BASICO</option>
                    <option value={3}>PREMIUM</option>
                    </Select>
                </FormControl>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={state.free} onChange={handleChange} name="free" />}
                            label="Coursera"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.free} onChange={handleChange} name="free" />}
                            label="Crehana"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.basic} onChange={handleChange} name="basic" />}
                            label="HubSpot Academy"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.basic} onChange={handleChange} name="basic" />}
                            label="Crack the Code"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.pre} onChange={handleChange} name="pre" />}
                            label="Google SkillShop"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.pre} onChange={handleChange} name="pre" />}
                            label="AWS Academy "
                        />
                        </FormGroup>
                    </FormControl>
                </div>
            </Tabs>
        </Box>
    );
};

export default connect(null, {...requestWorkflowActions})(MembresiaManagment);