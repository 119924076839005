import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

// @material-ui/icons
import MoreVertIcon from '@material-ui/icons/MoreVert';

import styled from 'styled-components';

// style
import useStyles from '../../../assets/styles/jss/components/moreOptionStyle';
import clsx from 'clsx';
import { Divider } from '@material-ui/core';

const ListItemIconStyled = styled(ListItemIcon)`
    min-width: 28px;
`
const ITEM_HEIGHT = 48;

export default function MoreOptions({options, onClickOption, ExAction = false}) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [optionSelected, setOptionSelected] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOption = (option) => () => {
        setOptionSelected(option.id);
        handleClose();
        onClickOption(option)
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon color="primary"/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '25ch',
                    },
                }}
            >
                { ExAction ?
                options.map((option) => (
                    <MenuItem key={option.label} onClick={option.fun} className={classes.menuItem}>
                        {option.icon && <ListItemIconStyled className={classes.menuItem}>{option.icon}</ListItemIconStyled>}
                        {option.label}
                    </MenuItem>
                )) :
                options.map((option) => (
                    <MenuItem key={option.id} selected={option.id === optionSelected} onClick={handleClickOption(option)}>
                        {option.icon && <ListItemIconStyled>{option.icon}</ListItemIconStyled>}
                        {option.label}
                    </MenuItem>
                )) 
                }
            </Menu>
        </div>
    );
}

MoreOptions.propTypes = {
    /**
     * Array of options
     */
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.element
      })).isRequired,
    /**
     * Optional click handler
     */
    onClickOption: PropTypes.func,
};
