const ACADEMIES = {
    ARTE: 6,
    CIENCIAS: 9,
    CIENCIAS_SOCIALES: 15,
    DATA_SCIENCE: 10,
    DERECHO: 13,
    DERECHO_URBANO: 2,
    EDUCACION: 8,
    IDIOMAS: 14,
    INGENIERIA: 3,
    LIDERAZGO_DESARROLLO: 12,
    MARKETING_COMUNICACION: 4,
    NEGOCIOS: 5,
    SALUD_BIENESTAR: 7,
    SUSTENTABILIDAD: 11,
    TECH_DIGITAL: 1
}

export default [
    {
        "id": ACADEMIES.ARTE,
        "name": "Arte",
        icon: '/images/academies/arte.svg' 
    },
    {
        "id": ACADEMIES.CIENCIAS,
        "name": "Ciencias",
        icon: '/images/academies/ciencias.svg'
    },
    {
        "id": ACADEMIES.CIENCIAS_SOCIALES,
        "name": "Ciencias Sociales",
        icon: '/images/academies/ciencias-sociales.svg'
    },
    {
        "id": ACADEMIES.DATA_SCIENCE,
        "name": "Data Science",
        icon: '/images/academies/data-science.svg'
    },
    {
        "id": ACADEMIES.DERECHO,
        "name": "Derecho",
        icon: '/images/academies/derecho.svg'
    },
    {
        "id": ACADEMIES.DERECHO_URBANO,
        "name": "Desarrollo Urbano",
        icon: '/images/academies/desarrollo-urbano.svg'
    },
    {
        "id": ACADEMIES.EDUCACION,
        "name": "Educación",
        icon: '/images/academies/educacion.svg'
    },
    {
        "id": ACADEMIES.IDIOMAS,
        "name": "Idiomas",
        icon: '/images/academies/idiomas.svg'
    },
    {
        "id": ACADEMIES.INGENIERIA,
        "name": "Ingeniería",
        icon: '/images/academies/ingenieria.svg'
    },
    {
        "id": ACADEMIES.LIDERAZGO_DESARROLLO,
        "name": "Liderazgo y Desarrollo",
        icon: '/images/academies/liderazgo-desarrollo.svg'
    },
    {
        "id": ACADEMIES.MARKETING_COMUNICACION,
        "name": "Marketing y Comunicación",
        icon: '/images/academies/marketing-comunicacion.svg'
    },
    {
        "id": ACADEMIES.NEGOCIOS,
        "name": "Negocios",
        icon: '/images/academies/negocios.svg'
    },
    {
        "id": ACADEMIES.SALUD_BIENESTAR,
        "name": "Salud y Bienestar",
        icon: '/images/academies/salud-bienestar.svg'
    },
    {
        "id": ACADEMIES.SUSTENTABILIDAD,
        "name": "Sustentabilidad",
        icon: '/images/academies/sustentabilidad.svg'
    },
    {
        "id": ACADEMIES.TECH_DIGITAL,
        "name": "Tech & Digital",
        icon: '/images/academies/tech-digital.svg'
    },
]