import React, { useState } from 'react';
import { Icon } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

import useStyles from '../../../assets/styles/components/form/inputSearchStyle';

const InputSearch = ({value, onKeyDown, onChange, onClickDelete, placeholder}) => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');

    React.useEffect(() => {
        setSearchValue(value)
    }, [value])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onKeyDown(searchValue);
        }
    };

    const handleChange = (event) => {
        onChange(event.target.value)
    }

    return (
        <InputBase
            value={searchValue}
            onChange={handleChange}
            startAdornment={<Icon className='ri-search-line text-dark-black-200 font-size-22' />}
            endAdornment={
                searchValue && 
                <Icon className='ri-close-line text-dark-black-200 font-size-22' 
                    onClick={() => onClickDelete()} 
                />}
            placeholder={placeholder}
            className={classes.input}
            onKeyDown={handleKeyDown}
      />
    );
};

export default InputSearch;