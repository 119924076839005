import * as Cookies from "js-cookie";
import objectIsEmpty from "./objectIsEmpty";

export const defaultCheckoutCookie = {
    orderId: 0,
    personInfo: {},
    anonymousSession: true,
    activeAccount: false,
    isReferred: false
};

const reduceUserInfo = (userInfo) => {
    if (userInfo && typeof userInfo === 'object' && !objectIsEmpty(userInfo)) {
        const { person_id, email, phone_number, userName } = userInfo;
        return { person_id, email, phone_number, userName };
    }
    return {};
};

export const setCheckoutCookie = (session = defaultCheckoutCookie) => {
    // Create a shallow copy of the session to avoid mutating the original object
    const checkoutCookie = { ...session };
    // Reduce the personInfo object in the session
    checkoutCookie.personInfo = reduceUserInfo(session.personInfo);
    // Set the cookie with the modified session
    Cookies.remove("checkout_sku");
    Cookies.set("checkout_sku", checkoutCookie, {});
};

export const getCheckoutCookie = () => {
    const checkoutCookie = Cookies.get("checkout_sku");

    if (!checkoutCookie) {
        return {};
    } else {
        return JSON.parse(checkoutCookie);
    }
};

export const clearCheckoutCookie = () => {
    setCheckoutCookie({ ...defaultCheckoutCookie });
};