import React, { lazy, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// core
import CardAcademia from '../../../shared/components/Academies/CardAcademia';
import {eventTracker } from '../../../shared/components/EventTracker/EventTracker';

// Routes
import * as paths from '../../../routes/paths'
import academies from '../../../shared/constants/academies';

const useStyles = makeStyles((theme) => ({
    title:{
        color: theme.palette.colors.gray[200],
        [theme.breakpoints.down('xs')]: {
            fontWeight: 700,
            fontSize: 24,
            lineHeight: '32px'
        }
    },
    academias_container:{
        maxWidth: 1224,
        margin: 'auto',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: '62px 0',
        [theme.breakpoints.down('xs')]: {
            padding: "32px 0",
        },
    },
    cards_academias_container:{
        marginTop: 62,
        [theme.breakpoints.down('xs')]: {
            marginTop: 24,
            width: "95%",
        },
    },
    cardAcademia:{
        '& .card-content':{
            width: 108,
            height: 108,
            padding: '8px 10px',
        }
    },
}));

const Academias = ({eventClick}) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = (e, id) => {
        const eventData = {}
        eventData['source'] = 'landing';
        eventTracker('explorar_academias', eventData, ['mixpanel']);
        eventClick();
        history.push(`${paths.BUSQUEDA}?academia=${id}`);
   };

    return (
        <Box className={classes.academias_container}>
            <Typography variant="h3" className={classes.title}>Academias</Typography>
            <Box className={classes.cards_academias_container}>
                <Suspense fallback={<div></div>}>
                    <Box width={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center" flexWrap="wrap" gridGap="24.5px">
                        { 
                            academies.length && academies.map((academia) => {
                                return (<CardAcademia className={classes.cardAcademia} id={academia.id} handleClick={handleClick} key={academia.id} src={academia.icon} title={academia.name} />);
                            })
                        }
                    </Box>
                </Suspense>
            </Box>
        </Box>
    );
};

export default Academias;