import React, { lazy, Suspense, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// redux
import { connect } from 'react-redux';

// @material-ui/core
import { Hidden, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

// shared core components
import Loader from '../../../shared/components/Loader/LoaderContent';

import { convertOuToSource }from '../../../../src/constants/convertOuToSource';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import { getCompanyNoDuplicate, newFilterByCompany} from '../filterByCompany'
import * as imageSource from '../../../shared/utils/imageSource';
import { log } from '../../../shared/utils/console.js'

// services
import { CourseService } from '@sdk-point/talisis'
import { handleClickMixpanel } from '../../Market/AlliesLandingPage/AlliesCardContainer/helpers/handleClickMixpanel';
// import CourseCard from '../../../shared/components/CourseCarousel/CourseCard/CourseCard';
import CourseCard from '../../../shared/components/Card/CourseCard/CourseCard';
const CourseCardBody = lazy(() => import(/* webpackChunkName: "CourseCardBody" */'../../../shared/components/CourseCard/CourseCardBody'));

const useStyles = makeStyles((theme) => ({
    root_carousel:{
        maxWidth: "1300px",
        width: "100%",
        marginLeft: "auto", 
        marginRight: "auto",
    },
    card: {
        maxWidth: "326px",
    },
    card_content:{
        width: "288px",
        minHeight: "315px",
        maxHeight: "315px",
        backgroundColor: "#1c1f24",
        "&:hover": {
            cursor:"pointer",
            "& $continuar_content": {
                opacity: "1",
                transition: "0.5s"
            },
        },
        
    },
    schedule:{
        display: 'flex',
        justifyContent: 'space-between',
        '& span':{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 400
        }
    },
    chip:{
        height: 20
    },
    cardActionArea:{
        
    },
    focusHighlight: {
       
    },
    title:{
        marginLeft: "59px",
        width: "100%",
        "@media (max-width: 1250px)": {
            marginLeft: "5px",
        },
    },
    continuar_text:{
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
    },
    rootTitles: {
        display:"flex",
        flexWrap:"wrap",
        justifyContent:"space-between",
        [theme.breakpoints.down('xs')]:{
            marginTop: '16px',
            marginBottom: '16px'
        }
    },
    rootCardsStyle:{
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        paddingBottom: '32px',
        [theme.breakpoints.down('xs')]:{
            gap: 16,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
}));

const SavedCourses = (props) => {
    const classes = useStyles();
    const { id, title, user, isMyCourses } = props;
    const [filter, setFilter] = useState([]);
    const [courses, setCourses] = React.useState([]);
    const [coursesAll, setCoursesAll] = React.useState([]);
    const [charge, setCharge] = React.useState(false);
    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const [loading, setLoading] = React.useState(false)

    const getData = async () => {
        try {
            setLoading(true);
            const courses = await courseService.getUserFavoriteCourses(user?.person_id);
            setFilter(()=> getCompanyNoDuplicate(courses?.response))
            log(JSON.stringify(courses?.response))
            return courses?.response;
        } catch (e) {
            log('Ops! error al get courses saved',e.message);
        }
    };

    React.useEffect(() => { 
        let isCanceled = true;
        getData().then((data)=>{
            if(isCanceled){
                data = transformDataPriceMembership(data);
                setCourses(data?.slice());
                setCoursesAll(data?.slice());
                setLoading(false);
                setCharge(true);
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, []);

    const handleClick = (e, filterSelected) => {
        e.preventDefault()
        if(typeof filterSelected.id === 'number') {
          if(filterSelected.id===0) {
            setCourses(()=> coursesAll)
          } else {
            const COURSES_FILTER_BY_SOURCE = coursesAll.filter((course)=> course.source === filterSelected.id)
            setCourses(()=> COURSES_FILTER_BY_SOURCE)
          }
        } else {
           const COURSES_FILTER_BY_COMPANY = coursesAll.filter((course)=> course.company === filterSelected.id)
           setCourses(()=> COURSES_FILTER_BY_COMPANY)
        } 
        setFilter(()=> newFilterByCompany(filter, filterSelected));
    }

    function transformDataPriceMembership(data){
        return data.map(course =>({
            ...course,
            source_id: course.source,
            priceMembershipActions: true,
            price: {
                number: course.price,
                listNumber: course.list_price,
            },
            image_url: course.image_url || imageSource.convertSourceToImageResource(course.company, course.source)
        }));
    }

    return (
        <Loader loading={loading} transparent={true} minHeight="500px">
            <Box className={classes.root_carousel}>
                <Box className={classes.rootTitles}>
                    <Hidden smDown>
                        <Box>
                            <Typography className="font-size-24 font-weight-700" color="white">Tus cursos guardados</Typography>
                        </Box>
                    </Hidden>
                    <Box>
                        {  filter.map((filter, index) => {
                                return (
                                    <Chip key={index+filter.label} 
                                        label={filter.label} 
                                        onClick={((e) => handleClick(e, filter))}
                                        value={filter}
                                        clickable style={{
                                                    width: "auto",
                                                    height: "32px",
                                                    background: filter.active,
                                                    border: "0.5px solid #404150",
                                                    borderRadius: "24px",
                                                    gap: "10px",
                                                    marginRight:5,
                                                    fontSize:12,
                                                    color: "#EBEBEB"
                                                    }}/>
                                );
                            }) 
                        }
                    </Box> 
                </Box>
                <Box>
                    {courses?.length > 0 ? 
                        <Grid container spacing={3}>
                            {
                                courses.map((courseItem, index) => {
                                    if ('third_party_id' in courseItem) {
                                         let linkProps = {};
                                        if (props.linkProps){
                                            linkProps = { ...props.linkProps }
                                        }else{
                                            var pattern = /^((http|https):\/\/)/;
                                            if(!pattern.test(courseItem.course_url)) {
                                                 courseItem.course_url = "http://" + courseItem.course_url;
                                             } 
                                            linkProps.to={pathname: `/course/${courseItem.id}`, state: { prevPath: isMyCourses == true ? "mis_cursos": "", target: "guardados"  }};
                                        }
                                        return (                     
                                            <Grid item lg={3} md={4} sm={6} xs={12} key={courseItem.id} className="d-flex justify-content-center">
                                            <RouterLink {...linkProps} style={{ textDecoration: "none" }} onClick={()=>{handleClickMixpanel(courseItem.name, courseItem.source_name, props)}}>
                                                <CourseCard 
                                                    data={{
                                                        company_id: courseItem?.company,
                                                        source:{
                                                            id: convertOuToSource(courseItem.company, courseItem.source_id),
                                                        },
                                                        raiting: {
                                                            value: courseItem?.courseItem_rating != null ? courseItem?.courseItem_rating : 0,
                                                            totalReview: courseItem?.total_review != null ? courseItem?.total_review : 0,
                                                        },
                                                        labels: courseItem?.labels,
                                                        image:  courseItem?.image_url,
                                                        saved: (courseItem?.is_favorited == 1 || courseItem?.is_favorited == true) ? true : false,
                                                        course_id: courseItem?.id,
                                                        userId: user?.person_id,
                                                        saved: (courseItem?.is_favorited == 1 || courseItem?.is_favorited == true) ? true : false,
                                                        label: "",
                                                        title: courseItem?.name,
                                                        course_id: courseItem?.id,
                                                        userId: user?.person_id,
                                                        prices: {
                                                            original: courseItem?.list_price,
                                                            current: courseItem?.price,
                                                        },
                                                        detail: {
                                                            sourceId: convertOuToSource(courseItem.company, courseItem.source),
                                                            companyId: courseItem?.company,
                                                            lessons: courseItem?.lessons,
                                                            level: courseItem?.difficulty_level,
                                                            estimated_time: courseItem?.estimated_time,
                                                            userLicensed: courseItem?.is_license_active,
                                                            courseItemPurchased: courseItem?.is_purchased
                                                        },
                                                        modalityId: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                        content: courseItem,
                                                        priceMembershipActions: courseItem.priceMembershipActions
                                                    }}
                                                />
                                            </RouterLink>
                                        </Grid>
                                        )
                                    }else{
                                        let linkProps = {};
                                        linkProps.to={pathname: `/course/${courseItem.id}`, state: { prevPath: isMyCourses == true ? "mis_cursos": ""  }};
                                        return (
                                            <Grid item lg={3} md={4} sm={6} xs={12} key={courseItem.id}>
                                            <RouterLink {...linkProps} style={{ textDecoration: "none" }} onClick={()=>{handleClickMixpanel(courseItem.name, courseItem.source_name, props)}}>
                                                <CourseCard 
                                                    data={{
                                                        company_id: courseItem?.company,
                                                        source:{
                                                            id: convertOuToSource(courseItem.company, courseItem.source_id),
                                                        },
                                                        raiting: {
                                                            value: courseItem?.courseItem_rating != null ? courseItem?.courseItem_rating : 0,
                                                            totalReview: courseItem?.total_review != null ? courseItem?.total_review : 0,
                                                        },
                                                        labels: courseItem?.labels,
                                                        image:  courseItem?.image_url,
                                                        saved: (courseItem?.is_favorited == 1 || courseItem?.is_favorited == true) ? true : false,
                                                        course_id: courseItem?.id,
                                                        userId: user?.person_id,
                                                        saved: (courseItem?.is_favorited == 1 || courseItem?.is_favorited == true) ? true : false,
                                                        label: "",
                                                        title: courseItem?.name,
                                                        course_id: courseItem?.id,
                                                        userId: user?.person_id,
                                                        prices: {
                                                            original: courseItem?.list_price,
                                                            current: courseItem?.price,
                                                        },
                                                        detail: {
                                                            sourceId: convertOuToSource(courseItem.company, courseItem.source),
                                                            companyId: courseItem?.company,
                                                            lessons: courseItem?.lessons,
                                                            level: courseItem?.difficulty_level,
                                                            estimated_time: courseItem?.estimated_time,
                                                            userLicensed: courseItem?.is_license_active,
                                                            courseItemPurchased: courseItem?.is_purchased
                                                        },
                                                        modalityId: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                        content: courseItem,
                                                        priceMembershipActions: courseItem.priceMembershipActions
                                                    }}
                                                />
                                            </RouterLink>
                                        </Grid>
                                        )
                                    }
                                })
                            } 
                        </Grid>
                        :<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            {charge && <NothingFound
                                        caption={"No cuentas con cursos guardados"}
                                        subcaption={"Aquí encontrarás el contenido que hayas guardado"}
                                        type={"completed-courses"}
                                    /> }
                        </Box>
                    }
                </Box>
            </Box>
        
        </Loader>
    );
};

SavedCourses.defaultProps = {
    lazyLoading: false,
    disableTitleAction: false,
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(SavedCourses);
