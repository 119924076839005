import React from 'react'

import { Box, Button, CircularProgress, Grid, Modal, Typography } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { checkoutModalStyles } from '../../AsyncPaymentModal/checkoutModalStyles';

const MembershipPlanModal = (props) => {

    const { modalOpenFunc, modalCloseFunc, modalBtnFunc,
        title, primaryText, secondaryText, loading } = props;

    const classes = checkoutModalStyles();

    return (
        <Modal
            open={modalOpenFunc} onClose={modalCloseFunc}
            className={classes.modal}
            aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description"
        >
            <Box sx={{ width: 650, backgroundColor: '#111111', textAlign: 'center', paddingLeft: '100px', paddingRight: '100px', paddingTop: '40px', paddingBottom: '30px', borderRadius: '8px' }} className={classes.modalInside}>
                {
                    loading
                    //TODO: Fix styles for modalLoader
                    ? <> 
                        <Typography>Actualizando tu orden</Typography>
                        <Typography>No cierres la ventana...</Typography>
                        <CircularProgress />
                    </>
                    : <Grid container direction="row">
                        <Grid item xs={12} style={{ paddingBottom: '14px' }}>
                            <ErrorOutlineIcon color="primary" fontSize='large' />
                        </Grid>
                        <Grid item xs={12} style={{ color: "#A86FFB", fontSize: '24px', paddingBottom: '50px' }}>
                            { title }
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '10px', fontSize: '16px' }}>
                            { primaryText }
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '50px', fontSize: '16px', color: '#A0A9BA' }}>
                            { secondaryText }
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color='primary' onClick={modalBtnFunc} className={classes.proceedButton}>
                                Continuar
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Modal>
    )
}

export default MembershipPlanModal
