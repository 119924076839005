import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import CurrencyFormat from 'react-currency-format'

const OrderDiscount = ({order_discount}) => {
    return (
        <Grid container className='mt-1' justifyContent="space-between" alignItems="baseline">
            <Grid item>
                <Typography variant="body1" className="text-gray-100">
                    Cupón:
                </Typography>
            </Grid>
            <Grid item>
                {/*<Typography variant="caption" className={clsx("text-success-300")} style={{ fontWeight: "600", marginRight: "8px" }}>{`${"CouponName"}`}</Typography>*/}
                <Typography variant="subtitle2" className="text-dark-black-100">
                    <CurrencyFormat
                        value={order_discount} decimalScale={2} fixedDecimalScale={true} 
                        displayType="text" thousandSeparator={true} prefix="-$" suffix='MXN' 
                    />
                </Typography>
            </Grid>
        </Grid>
    )
}

export default OrderDiscount
