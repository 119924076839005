import { makeStyles } from '@material-ui/core/styles';
import { SOURCES } from '../../../../../constants/sources';

export default makeStyles((theme) => ({
    rootStatusCourseMedia: {
        height: "158px",
        width: "100%",
        backgroundImage: props => `url(${props.backgroundImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down('xs')]: {
            height: "130px",
        }
    },
    containerWithStatusCourse: {
        padding: "16px",
        flexGrow: "1",
        display: "flex",
        alignItems: "end",
        [theme.breakpoints.down('xs')]: {
            padding: "8px",
        }
    },
    containerBottomWithStatusCourse: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    containerNewCourse: {
        width: "100%",
        height: "100%",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            padding: "8px",
        }
    },
    containerBottomNewCourse: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    approvedChip: {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        top: "40%",
    },
    [SOURCES.ADVENIO] : {
        height: '25px',
        [theme.breakpoints.down('xs')]:{
            height: '18px',
        }
    },
    [SOURCES.NEO] : {
        height: '19px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.COURSERA] : {
        height: '16px',
        [theme.breakpoints.down('xs')]:{
            height: '10px',
        }
    },
    [SOURCES.LINKEDIN_LEARNING] : {
        height: '25px',
        [theme.breakpoints.down('xs')]:{
            height: '17px',
        }
    },
    [SOURCES.TALISIS] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
        }
    },
    [SOURCES.UERRE] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
        }
    },
    [SOURCES.UNID] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
        }
    },
    [SOURCES.HH] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.IESALUD] : {
        height: '19px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
       }
    },
    [SOURCES.GOOGLE] : {
        height: '17px',
        [theme.breakpoints.down('xs')]:{
            height: '17px',
        }
    },
    [SOURCES.CREHANA] : {
        height: '20px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.HUBSPOT] : {
        height: '21px',
        [theme.breakpoints.down('xs')]:{
            height: '16px',
        }
    },
    [SOURCES.IE_UNIVERSITY] : {
        height: '36px',
        [theme.breakpoints.down('xs')]:{
            height: '24px',
        }
    },
    [SOURCES.AWS] : {
        height: '30px',
        [theme.breakpoints.down('xs')]:{
            height: '21px',
        }
    },
    [SOURCES.SIMPLICITY] : {
        height: '17px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.CONEKTA] : {
        height: '18px',
        [theme.breakpoints.down('xs')]:{
            height: '14px',
        }
    },
    [SOURCES.AWS_SKILL_BUILDER] : {
        height: '30px',
        [theme.breakpoints.down('xs')]:{
            height: '21px',
        }
    },
    [SOURCES.NEO_CH] : {
        height: '27px',
        [theme.breakpoints.down('xs')]:{
            height: '21px',
        }
    },
}));