import React, { useEffect, useState } from 'react';

// core components
import Box from '@material-ui/core/Box';

// Material components
import { useMediaQuery } from '@material-ui/core';

// shared - components
import LoaderContent from '../../../shared/components/Loader/LoaderContent';

// components
import EventFilter from './components/EventFilter/EventFilter';
import NotEvent from './components/NotEvent/NotEvent';

// pages
import AllEvents from './pages/AllEvents/AllEvents';
import MyEvents from './pages/MyEvents/MyEvents';

// styles
import useStyle from '../../../assets/styles/jss/pages/events/eventListStyle';

// redux
import { connect } from 'react-redux';

//SDK
import { EventService } from '@sdk-point/talisis'; 
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';
const EventServiceSDK = new EventService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const EventList = ({user})  => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyle();

    // Filtros
    const [isMyEventsPage, setIsMyEventsPage] = useState(false);
    const [modalityValue, setModalityValue] = useState(0);
    const [categoryValue, setCategoryValue] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [modalityList, setModalityList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSearching, setIsSearching] = useState(true);

    
    // Listado de eventos
    const [eventList, setEventList] = useState([]);
    const [eventListFilter, setEventListFilter] = useState([]);

    useEffect(() => {
        getAllEvents();
        getCategories();
        getModalities();
    }, []);

    useEffect(() => {
        proccessEventFilter();    
    }, [modalityValue, categoryValue, searchValue]);

    useEffect(() => {
        processEventListChange();
    }, [eventList]);

    const processEventListChange = () => { 
        proccessEventFilter();
     }
    
    const proccessEventFilter = () => {
        const eventListTemp = [...eventList];

        const filterData = eventListTemp.filter(item => {
            if (modalityValue !== 0 && modalityValue !== item.modality_id) return false;
            if (categoryValue !== 0 && categoryValue !== item.category_id) return false;
            if (searchValue.trim() !== '' && !item.title.toLowerCase().includes(searchValue.trim().toLowerCase())) return false;
            return true;
        });

        setEventListFilter(filterData);
    };

    const getCategories = async () => { 
        const categoriesResponse = await EventServiceSDK.getCategoryCat(); 
        const data = categoriesResponse.success ? categoriesResponse.data : [];

        const categories = data.map(item => ({id: item.id, label: item.name, icon: null}));
        categories.unshift({id: 0, label: 'Todos', icon: null});

        setCategoryList(categories);
    };

    const getModalities = async () => { 
        const modalitiesResponse = await EventServiceSDK.getModalityCat(); 
        const data = modalitiesResponse.success ? modalitiesResponse.data : [];

        const modalities = data.map(item => ({id: item.id, label: item.name, icon: null}));
        modalities.unshift({id: 0, label: 'Todos', icon: null});

        setModalityList(modalities);
    };

    const getAllEvents = async () => { 
        const params = {
            personId: user.person_id.toString(),
            type: "2",
            companyId: user.company_id,
        };

        const eventResponse = await EventServiceSDK.getAllEvents(params);
        const data = eventResponse.success? eventResponse.data : [];
        setLoading(false);
        setEventList(data);
        setIsSearching(false);
    };

    const handleChangeFilter = (event, type) => {
        switch(type) {
            case 'text':
                setSearchValue(event.target.value);
                break;
            case 'category':
                setCategoryValue(event.id);
                break;
            case 'modality':
                setModalityValue(event.id);
                break;
        }
    };

    const handleChangeMyEventPage = (value) => { 
        setIsMyEventsPage(value);
    };

    const handleClickInterestingEvent = async (eventId, value) => { 
        const body = {
            isInteresting: value, 
            personId: user.person_id, 
            eventId: eventId
        };

        const response = await EventServiceSDK.interestingEvent(body);

        if (response.success) {
            const eventListTemp = [...eventList];
            const newEventList = eventListTemp.map(item => {
                if (item.id === eventId) {
                    item.is_interested = value;
                }
                return item;
            });

            setEventList(newEventList);
        } else {
            // AGREGAR UN ERROR O ALGO 
        }
        const eventData = {};
        eventData['sources'] = 'eventos';
        eventTracker('guardar_evento', eventData, ['mixpanel']);
    };

    return (<>
        <Box sx={{width: "100%"}}>
            <EventFilter onChangeFilter={handleChangeFilter}  categoryList={categoryList} modalityList={modalityList} onChangeMyEventPage={handleChangeMyEventPage}/>
        </Box>
        <Box className={classes.boxContainer}>
            <LoaderContent loading={loading}>
                {isMyEventsPage? <MyEvents  eventList={eventListFilter} onClickInterestingEvent={handleClickInterestingEvent} isResponsive={isResponsive}/> : <AllEvents eventList={eventListFilter} onClickInterestingEvent={handleClickInterestingEvent} isResponsive={isResponsive}/>}
                {!eventListFilter.length && !isSearching && <NotEvent text="Los eventos aparecerán aquí."/>}
            </LoaderContent>
        </Box>
    </>);
};

const mapStateToProps = (state) => ({...state.userReducer});

export default connect(mapStateToProps, null)(EventList);