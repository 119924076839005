import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { Box, Button, Chip, Icon } from '@material-ui/core';

// Styles
import useStyles from "../../../../assets/styles/components/cards/disabledCourseCard/disabledCourseMediaStyle";

// Components
import LogoSource from '../../../LogoSource/LogoSource';

//Constants
import {MODALITIES, MODALITY_ICON} from "../../../../constants/modalities";

//Constants
import { WHITE } from '../../../../constants/color-images';
import clsx from 'clsx';

const DisabledCourseMedia = ({media, sourceIdLogo, isResponsive}) => {
    const classes = useStyles({backgroundImage: media.backgroundImage, isResponsive });

    return (
        <Box className={classes.rootStatusCourseMedia}>
            <Box className={classes.container}>
                <Box className={classes.containerBottom}>
                    <Box>
                        {
                            !!media.modalityId && 
                            <Chip label={MODALITIES[media.modalityId]} icon={<Icon className={MODALITY_ICON[media.modalityId]} />} className={"chip7-fs-400"} />
                        }
                    </Box>
                    {
                        !!sourceIdLogo && <LogoSource sourceId={sourceIdLogo} color={WHITE} className={clsx(classes[sourceIdLogo], classes.logoIconSource)} disabledAutoRes/>
                    }
                </Box>
            </Box>
        </Box>
    );
};

DisabledCourseMedia.propTypes = {
    media: PropTypes.shape({
        backgroundImage: PropTypes.string,
        modalityId: PropTypes.number,
    }).isRequired,
    sourceIdLogo: PropTypes.number,
    isResponsive: PropTypes.bool,
};

export default DisabledCourseMedia;