import React from 'react';
import { generatePath } from "react-router";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import { connect } from 'react-redux';

//material
import { Hidden, makeStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// routes
import * as paths from '../../../routes/paths'

// Utils
import dateFormat from '../../../design-system/utils/dateFormat'

// Constants
import {DATE_TIME2} from '../../../design-system/constants/date-formats'
import { log } from '../../../shared/utils/console.js'
import {TAB_HOME_NAME} from "../../../shared/constants/tabs";

// shared core components
import TitleLink from '../../../shared/components/TitleLink/TitleLink';
import CardAlliesSkeletonLoading from '../../../shared/components/Card/CardAlliesSkeletonLoading';
import SlickCarousel from '../../../shared/components/SlickCarousel/SlickCarousel';

import { setActiveTab } from '../../../redux/actions/tabsActions';
import DsIcon from '../../../design-system/components/DsIcon/DsIcon';


// SDK
import { EventService } from '@sdk-point/talisis';
const EventServiceSDK = new EventService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


const useStyles = makeStyles((theme) => ({
    container:{
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        padding: '62px 0',
        minHeight: 405,
        [theme.breakpoints.down('xs')]:{
            minHeight: 339,
            padding: '32px 0',
            '& .title-events':{
                '& .MuiButton-label':{
                    marginBottom: 0
                }
            }
        }
    },
    card: {
        width: 392,
        height: 225,
        [theme.breakpoints.down('xs')]:{
            width: 265,
            height: 195,
        }
    },
    bg: {
        width: '100%',
        height: 225,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        imageRendering: "-webkit-optimize-contrast",
        [theme.breakpoints.down('xs')]:{
            height: 195
        }
    },
    content: {
        height: 187,
        padding: '19px 22px',
        [theme.breakpoints.down('xs')]:{
            height: 157
        }
    },
    contentDefault:{
        height: 187,
        padding: '19px 22px',
        background: 'linear-gradient(113.16deg, #3900D9 4.18%, #1F0076 82.04%)',
        [theme.breakpoints.down('xs')]:{
            height: 157
        }
    },
    swiper: {
        padding: "12px",
        maxWidth: "fit-content",
        [theme.breakpoints.down('xs')]:{
            padding: "8px",
        }
    },
    carousel: {
        [theme.breakpoints.down('xs')]:{
            padding: '0px 16px',
            '& .slick_carousel':{
                '& #root-slik-arrows':{
                    paddingRight: '0 !important'
                }
            },
        }
    },
    buttonDetalleEventos:{
        width: 121,
        height: 40,
        [theme.breakpoints.down('xs')]:{
            height: 34,
        }
    },
    textEventos:{
        maxHeight: '5rem', // Establece la altura máxima permitida, en este caso 5rem
        overflow: 'hidden', // Oculta cualquier contenido que se desborde
        textOverflow: 'ellipsis', // Muestra tres puntos (...) para indicar que se ha cortado el contenido
        display: '-webkit-box', // Establece la visualización como caja flexible para limitar el número de líneas
        '-webkit-box-orient': 'vertical', // Establece la orientación vertical de la caja
        '-webkit-line-clamp': 2, // Establece el número máximo de líneas permitidas, en este caso 2
      },
}));

const UpcomingEvents = ({isLogged, setActiveTab}) => {
    
    let history = useHistory();

    const classes = useStyles();
    const [events, setEvents] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));

    React.useEffect(()=> {
        getEventDetail();
    }, [])

    const getEventDetail = async() => {
        try {
           const response = await EventServiceSDK.getAllEvents({type:1});
           setEvents(response.data);
           setLoading(false);
        } catch (error) {
            log('error', error); 
        }
    }

    const handleClickGo = (path, tab=null) => {
        history.push(path)

        if(tab) setActiveTab(tab);
    };
    const handleClickEvent = id => history.push(generatePath(paths.EVENTOS_DETALLE, {id}));

    const goTo = () => {
        history.push(paths.MEMBRESIAS);
    };

    return (
        <Box className="bg-electric-blue-500">
            <Box className={classes.container}>
                <TitleLink 
                    hideSubtitleResponsive={true}
                    textPrimary="Enterate de nuestros próximos eventos" 
                    textSecondary="Ver todos"
                    className="offset-margin-bottom-9 pl-0 title-events"
                    onClick={handleClickGo.bind(this, isLogged ? `${paths.HOME}?tab=${TAB_HOME_NAME.EVENTS}` : paths.EVENTOS)}
                />
                {
                    loading && <CardAlliesSkeletonLoading />
                }
                <Box className={classes.carousel}>
                { !loading && 
                    <SlickCarousel length={events.length} slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                        {
                            events.map(it => {
                                return (
                                    <SwiperSlide className={classes.swiper} key={it.id} onClick={handleClickEvent.bind(this, it.id)}>
                                            <Card className={classes.card} key={it.id}>
                                                <Box className={classes.bg} style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${it.image}")`, }}>
                                                    <Box className={classes.content} minWidth={392}>
                                                        <Box display="flex" flexDirection="column" height="100%">
                                                            <Box>
                                                                <Typography variant="h6" className="text-violet-300 text-uppercase">{dateFormat(it.schedules[0].end_register, DATE_TIME2)}</Typography>
                                                                <Typography variant="h3">{it.title}</Typography>
                                                                {it.modality_name && <Typography variant="button" className="text-dark-black-100">{it.modality_name}</Typography>}
                                                            </Box>
                                                            <Box mt="auto">
                                                                <Button className={classes.buttonDetalleEventos} variant="contained" color="primary" onClick={handleClickEvent.bind(this, it.id)}>Ver detalle</Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                        </Card>
                                    </SwiperSlide>
                                )
                            })
                        }
                        <SwiperSlide className={classes.swiper}>
                            <Card className={classes.card}>
                                <Box className={classes.bg} >
                                    <Box className={classes.contentDefault}>
                                        <Box display="flex" flexDirection="column" height="100%">
                                            <Box>
                                                <Typography variant="h3" className={clsx("offset-margin-bottom-8 offset-margin-top-13", classes.textEventos)}>Ver más eventos exclusivos para miembros</Typography>
                                                <Typography variant="button" className="text-dark-black-100">No te pierdas los eventos que nuestras marcas tienen para ti.</Typography>
                                            </Box>
                                            {
                                                <Box mt="auto">
                                                    {
                                                        isLogged
                                                        ?<Button className={classes.buttonDetalleEventos} variant="contained" onClick={handleClickGo.bind(this, isLogged ? `${paths.HOME}?tab=${TAB_HOME_NAME.EVENTS}` : paths.EVENTOS)}>Ir a eventos</Button>
                                                        :<Button className={classes.buttonDetalleEventos} variant="contained" onClick={handleClickGo.bind(this, paths.SIGNUP)}>Regístrate</Button>
                                                    }
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                        </SwiperSlide>
                    </SlickCarousel>
                }

                </Box>
                
                <Hidden smUp>
                    <Box marginTop={4} marginBottom={isLogged ? 6 : 0}>
                        <Button fullWidth color='primary' endIcon={<DsIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" icon='t-arrow-right-line' />} onClick={goTo}>Comparar membresías</Button>
                    </Box>
                </Hidden>
            
        </Box>
    </Box>
    );
};

const mapDispatchToProps = { setActiveTab };

export default connect(null, mapDispatchToProps)(UpcomingEvents);
