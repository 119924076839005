import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';

import {HorizontalTabs, TabPanel} from '../../../../shared/components/Tabs';
import { Card, Icon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

const tabs = [
    'Standard',
    'Plus',
    'Elite',
];

const useStyles = makeStyles((theme) => ({
    root:{
        padding: '120px 0px 118px',
        [theme.breakpoints.down('sm')]:{
            padding: '0px 15px 40px'
        },
        [theme.breakpoints.up('sm')]:{
            background: theme.palette.colors.fillStroke[400],
        },
    },
    tabContent:{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 16
    },
    card:{
        borderColor: 'rgba(255, 255, 0, 0.5) !important',
        '& .card-row':{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 46,
            padding: '0px 15px',
            '&:nth-child(odd)':{
                background: 'rgba(17, 17, 17, 0.5)'
            }
        }
    }
}));

const TableContentHHResponsive = ({list}) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState(0);

    const handleChange = tab => setActiveTab(tab);

    const MemershipContent = ({type}) => {
        const classColors = {
            standard: 'text-white',
            plus: 'text-hh-yellow',
            elite: 'text-ld-blue'
        };

        return <Box className={classes.tabContent}>
            {
                Object.keys(list).map(node =>
                    <Box key={uuid()}>
                        <Typography variant="body2" className="semi-bold offset-mb-4" color="primary" align="left">{node}:</Typography>
                        <Card className={classes.card} elevation={4}>
                            {
                                list[node].map(listItem =>                                                    
                                    <Box className="card-row" key={uuid()}>
                                        <Typography className="body2" className="text-gray-300">
                                            {listItem.label}
                                        </Typography>
                                        {
                                            listItem[type] &&
                                            <Box width="31px">
                                                {
                                                    typeof listItem[type] === "boolean"
                                                    ? <Icon className={clsx(classColors[type], "ri-check-fill","font-size-14")} />
                                                    : <Typography variant="subtitle1" className={classColors[type]}>{listItem[type]}</Typography>
                                                }   
                                            </Box>
                                        }
                                    </Box>
                                )
                            }
                        </Card>
                    </Box>
                )
            }
        </Box>
    }  


    return (
        <Box width="100%">
            <HorizontalTabs
                activeTab={activeTab}
                onChange={handleChange}
                tabs={tabs}>
                <>                                        
                    <TabPanel value={activeTab} index={0}>
                        <MemershipContent type="standard" />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <MemershipContent type="plus" />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <MemershipContent type="elite" />
                    </TabPanel>
                </>
            </HorizontalTabs>
        </Box>
            
    );
};

export default TableContentHHResponsive;