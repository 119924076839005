import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const UserAllyAccess = (props) => {
    const { data, classes } = props;
    return (
        <Grid container spacing={1} className="mt-3">
            <Grid item xs={12} className={`${classes.gridContainer} ${classes.displayInlineFlex} ${classes.alignItemsCenter} mt-1 mb-2`}>
                <Typography component="span" variant="subtitle2"><b>Coursera:</b></Typography>
                <Typography component="span" variant="subtitle2" color="primary" className="ml-2">
                    {data.coursera || 'Sin acceso.'}
                </Typography>
            </Grid>
            <Grid item xs={12} className={`${classes.gridContainer} ${classes.displayInlineFlex} ${classes.alignItemsCenter} mt-1 mb-2`}>
                <Typography component="span" variant="subtitle2"><b>LinkedIn Learning:</b></Typography>
                <Typography component="span" variant="subtitle2" color="primary" className="ml-2">
                    {data.linkedin_learning || 'Sin acceso.'}
                </Typography>
            </Grid>
            <Grid item xs={12} className={`${classes.gridContainer} ${classes.displayInlineFlex} ${classes.alignItemsCenter} mt-1 mb-2`}>
                <Typography component="span" variant="subtitle2"><b>Crehana:</b></Typography>
                <Typography component="span" variant="subtitle2" color="primary" className="ml-2">
                    {data.crehana || 'Sin acceso.'}
                </Typography>
            </Grid>
        </Grid>
    )
};

export default UserAllyAccess;