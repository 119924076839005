import React from 'react';
import moment from 'moment';

// @material-core
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import {Box, Icon, Tooltip} from '@material-ui/core';

// local
import {detailsStyles} from '../../../../../assets/styles/jss/pages/degreeCertificates/DetailsStyles';
import clsx from "clsx";
import {stringContainsExactlyOf} from "../../../../../shared/utils/common";
import {isNil} from "ramda";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.common.white ,
  }
}))(Tooltip);

export const RequirmentsDegreeInfo = ({data, onChange, mnationalityCat, xData, onSetForm, isEdit}) => {

  const classes = detailsStyles();
  const isSelected = (value) => {
    return value === 'S' ? true : value === 'N' ? false : null;
  }

  const completedDocuments = ()=>{
    const docs = []
    if(Array.isArray(data.documents) && data.documents.length > 0) {
      data.documents.forEach(doc => {
        if (Boolean(doc.is_loaded)) {
          docs.push(doc)
        }
      })
      return docs.length === data.documents.length
    }
    return false
  }
  const renderInfoToolTip = (requirment) =>{


      let msg = 'No Disponible';
      if (requirment === 'documents'){
        if(Array.isArray(data.documents) && data.documents.length > 0 && !completedDocuments()){
          return (
            <Box className={"bg-white p-1"}>
              <Typography variant="body2" className={'text-dark-black-500 font-weight-600'} gutterBottom>
                Documentos pendientes:
              </Typography>
              <Box
                className={"text-dark-black-500"}
                variant="body2"
              >
                <ul>
                  {data.documents.filter(doc=>!doc.is_loaded).map(doc=><li>{doc.name}</li>)}
                </ul>
              </Box>
              <Typography variant="body2" className={'text-dark-black-200'} gutterBottom>
                Para más información consulta el modulo de documentación.
              </Typography>
            </Box>)
        }
        msg = !Array.isArray(data.documents) ? data.documents : 'Ningún Documento pendiente.';
      }
      if(requirment === 'egel' && !isNil(data?.reqRazonAcreditacionEgel)){
        msg = data.reqRazonAcreditacionEgel
      }
      if(requirment === 'ingles' && !isNil(data?.reqRazonAcreditacionIngles)){
        msg = data.reqRazonAcreditacionIngles
      }
      return(
        <Box className={"bg-white p-1"}>
        <Typography variant="body2" className={'text-dark-black-500 font-weight-600'} gutterBottom>
          {msg}
        </Typography>
      </Box>)
  }

  return (
    <Grid container spacing={3} className="mt-1">
      <Grid item>
        <Grid container>
          <Grid item xs={12}
                className={clsx(data.reqIndCapCumplimiento === undefined || data.reqIndCapCumplimiento === 'N/A' && classes.checkboxHide)}>
            <Grid container className='py-1' spacing={2} alignItems='center'>
              <Grid item xs={1}>
                <Checkbox
                  className={clsx(classes.checkbox)}
                  icon={<Icon
                    className={clsx(`ri-checkbox-blank-circle-line font-size-25 text-stroke-100`)}/>}
                  checkedIcon={<Icon
                    className={clsx(`ri-checkbox-circle-fill font-size-25 text-stroke-100`)}/>}
                  disabled={true}
                  color="primary"
                  value={data.reqIndCapCumplimiento || ''}
                  checked={isSelected(data.reqIndCapCumplimiento)}
                  onChange={(e) => onChange(e)}
                  name={'reqIndCapCumplimiento'}
                  inputProps={{
                    'aria-label': 'select',
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" className={classes.titleReq} gutterBottom>
                  CAPP con cumplimiento del 100%.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}
                className={clsx( data.reqIndAcreditacionIngles === undefined || data.reqIndAcreditacionIngles === 'N/A' && classes.checkboxHide)}>
            <Grid container className='py-1' spacing={2} alignItems='center'>
              <Grid item xs={1}>
                <Checkbox
                  className={classes.checkbox}
                  icon={<Icon
                    className={clsx(`ri-checkbox-blank-circle-line font-size-25 text-stroke-100`)}/>}
                  checkedIcon={<Icon
                    className={clsx(`ri-checkbox-circle-fill font-size-25 text-stroke-100`)}/>}
                  disabled={true}
                  color="primary"
                  value={data.reqIndAcreditacionIngles || ''}
                  checked={isSelected(data.reqIndAcreditacionIngles)}
                  onChange={(e) => onChange(e)}
                  name={'reqIndAcreditacionIngles'}
                  inputProps={{
                    'aria-label': 'select',
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" className={classes.titleReq} gutterBottom>
                  Acreditación del idioma inglés.
                  <LightTooltip
                    placement="top-start"
                    arrow
                    title={
                      renderInfoToolTip('ingles')
                    }>
                    <Icon className={clsx(`ri-information-line font-size-16 text-fill-stroke-100 ml-1 mt'1`)}/>
                  </LightTooltip>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={clsx( data.reqIndPagoTitulacion === undefined || data.reqIndPagoTitulacion === 'N/A' && classes.checkboxHide)}>
            <Grid container className='py-1' spacing={2} alignItems='center'>
              <Grid item xs={1}>
                <Checkbox
                  className={classes.checkbox}
                  icon={<Icon
                    className={clsx(`ri-checkbox-blank-circle-line font-size-25 text-stroke-100`)}/>}
                  checkedIcon={<Icon
                    className={clsx(`ri-checkbox-circle-fill font-size-25 text-stroke-100`)}/>}
                  disabled={true}
                  color="primary"
                  value={data.reqIndPagoTitulacion || ''}
                  checked={isSelected(data.reqIndPagoTitulacion)}
                  onChange={(e) => onChange(e)}
                  name={'reqIndPagoTitulacion'}
                  inputProps={{
                    'aria-label': 'select',
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" className={classes.titleReq} gutterBottom>
                  Pago cubierto al 100% de titulación.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={clsx( data.reqIndDocumentacion === undefined || data.reqIndDocumentacion === 'N/A' && classes.checkboxHide)}>
            <Grid container className='py-1' spacing={2} alignItems='center'>
              <Grid item xs={1}>
                <Checkbox
                  className={classes.checkbox}
                  icon={<Icon
                    className={clsx(`ri-checkbox-blank-circle-line font-size-25 text-stroke-100`)}/>}
                  checkedIcon={<Icon
                    className={clsx(`ri-checkbox-circle-fill font-size-25 text-stroke-100`)}/>}
                  disabled={true}
                  color="primary"
                 /* value={completedDocuments() ? 'S' : 'N'}
                  checked={completedDocuments()}*/
                  value={data.reqIndDocumentacion || ''}
                  checked={isSelected(data.reqIndDocumentacion)}
                  // onChange={(e) => onChange(e)}
                  name={'reqIndDocumentacion'}
                  inputProps={{
                    'aria-label': 'select',
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" className={classes.titleReq} gutterBottom>
                  Documentación.
                  <LightTooltip
                    placement="top-start"
                    arrow
                    title={
                     renderInfoToolTip('documents')
                    }>
                    <Icon className={clsx(`ri-information-line font-size-16 text-fill-stroke-100 ml-1 mt'1`)}/>
                  </LightTooltip>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={clsx( data.reqIndAcreditacionEgel === undefined || data.reqIndAcreditacionEgel === 'N/A' && classes.checkboxHide)}>
            <Grid container className='py-1' spacing={2} alignItems='center'>
              <Grid item xs={1}>
                <Checkbox
                  className={classes.checkbox}
                  icon={<Icon
                    className={clsx(`ri-checkbox-blank-circle-line font-size-25 text-stroke-100`)}/>}
                  checkedIcon={<Icon
                    className={clsx(`ri-checkbox-circle-fill font-size-25 text-stroke-100`)}/>}
                  disabled={true}
                  color="primary"
                  value={data.reqIndAcreditacionEgel || ''}
                  checked={isSelected(data.reqIndAcreditacionEgel)}
                  onChange={(e) => onChange(e)}
                  name={'reqIndAcreditacionEgel'}
                  inputProps={{
                    'aria-label': 'select',
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" className={classes.titleReq} gutterBottom>
                  Acreditación del EGEL o EVIPE.
                  <LightTooltip
                    placement="top-start"
                    arrow
                    title={
                      renderInfoToolTip('egel')
                    }>
                    <Icon className={clsx(`ri-information-line font-size-16 text-fill-stroke-100 ml-1 mt'1`)}/>
                  </LightTooltip>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={clsx( data.reqIndSinAdeudo === undefined || data.reqIndSinAdeudo === 'N/A' && classes.checkboxHide)}>
            <Grid container className='py-1' spacing={2} alignItems='center'>
              <Grid item xs={1}>
                <Checkbox
                  className={classes.checkbox}
                  icon={<Icon
                    className={clsx(`ri-checkbox-blank-circle-line font-size-25 text-stroke-100`)}/>}
                  checkedIcon={<Icon
                    className={clsx(`ri-checkbox-circle-fill font-size-25 text-stroke-100`)}/>}
                  disabled={true}
                  color="primary"
                  value={data.reqIndSinAdeudo || ''}
                  checked={isSelected(data.reqIndSinAdeudo)}
                  onChange={(e) => onChange(e)}
                  name={'reqIndSinAdeudo'}
                  inputProps={{
                    'aria-label': 'select',
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" className={classes.titleReq} gutterBottom>
                  No contar con ningún adeudo.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={clsx( data.reqIndPromedio === undefined || data.reqIndPromedio === 'N/A' && classes.checkboxHide)}>
            <Grid container className='py-1' spacing={2} alignItems='center'>
              <Grid item xs={1}>
                <Checkbox
                  className={classes.checkbox}
                  icon={<Icon
                    className={clsx(`ri-checkbox-blank-circle-line font-size-25 text-stroke-100`)}/>}
                  checkedIcon={<Icon
                    className={clsx(`ri-checkbox-circle-fill font-size-25 text-stroke-100`)}/>}
                  disabled={true}
                  color="primary"
                  value={data.reqIndPromedio || ''}
                  checked={isSelected(data.reqIndPromedio)}
                  onChange={(e) => onChange(e)}
                  name={'reqIndPromedio'}
                  inputProps={{
                    'aria-label': 'select',
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" className={classes.titleReq} gutterBottom>
                  Promedio.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
