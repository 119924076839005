import React from 'react';
import moment from 'moment';

// @material-core
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local
import {detailsStyles} from '../../../../../assets/styles/jss/pages/degreeCertificates/DetailsStyles';
import clsx from 'clsx';
import {SOURCE_CMPLESS_DEGREE_CERTIFICATE} from '../../../../../shared/constants/sources';
import Select from '../../../../../design-system/components/Form/Select/Select';
import {findIndex, propEq} from "ramda";


export const ServiceSocInfo = ({data, nationalityCat, xData, onSetForm, onChange, isEdit, catalogues}) => {

    const classes = detailsStyles({isEdit});

    return (
        <Grid container spacing={3} className="mt-1">
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Cumplió SS
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit || (data.nivel === 'MA' && data.ou === "UNID") || (data.nivel === 'PO' && data.ou === "UERRE")?
                                    <TextField
                                        id="cumplioServicioSocial"
                                        name="cumplioServicioSocial"
                                        placeholder='Cumplió SS'
                                        fullWidth
                                        multiline={true}
                                        className={classes.label}
                                        value={ SOURCE_CMPLESS_DEGREE_CERTIFICATE[findIndex(propEq(data.cumplioServicioSocial, 'id'))(SOURCE_CMPLESS_DEGREE_CERTIFICATE)]?.label || ''}
                                        InputProps={{
                                            className: clsx(!isEdit && classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    :
                                    <Select
                                        items={SOURCE_CMPLESS_DEGREE_CERTIFICATE}
                                        label=""
                                        className={clsx(classes.select)}
                                        name="cumplioServicioSocial"
                                        onClick={e => e.stopPropagation()}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        disabled={!isEdit}
                                        value={data.cumplioServicioSocial || ""}
                                        size="small"
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Id Fundamento SS
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit || (data.nivel === 'MA' && data.ou === "UNID")|| (data.nivel === 'PO' && data.ou === "UERRE") ?
                                    <TextField
                                        id="idFundamentoLegalServicioSocial"
                                        name="idFundamentoLegalServicioSocial"
                                        placeholder='Id Fundamento SS'
                                        fullWidth
                                        className={classes.label}
                                        value={catalogues.fundLegal[findIndex(propEq(data.idFundamentoLegalServicioSocial, 'id'))(catalogues.fundLegal)]?.label || ''}
                                        InputProps={{
                                            className: clsx(!isEdit && classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    :
                                    <Select
                                        items={catalogues.fundLegal}
                                        label=""
                                        className={clsx(classes.select)}
                                        name="idFundamentoLegalServicioSocial"
                                        onClick={e => e.stopPropagation()}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        disabled={!isEdit}
                                        value={data.idFundamentoLegalServicioSocial || ""}
                                        size="small"
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};
