import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';

// styles
import useStyles from '../../../../../assets/styles/jss/pages/requests/rejectedFileCommentsStyle';

const RejectedFileComments = ({title, comments, onClick}) => {
    const classes = useStyles();

    return (
        <Accordion className={classes.accordionRoot}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
            >
            <Typography variant='subtitle2' color="textSecondary">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
            {
                comments.map(comment => 
                    <Box className={classes.commentMessage}>
                            <Grid container spacing={1} direction="row">
                                <Grid item xs={11}>
                                    <Typography variant='subtitle2' color="textSecondary">{comment}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton color="textSecondary" aria-label="upload" component="span" onClick={ onClick } className={classes.editMessageIcon}>
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                    </Box>)
            }    
            </AccordionDetails>
        </Accordion>    
    );
};

RejectedFileComments.propTypes = {
    title: PropTypes.string, 
    comments: PropTypes.array
};

export default RejectedFileComments;