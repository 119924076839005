import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import { Box } from '@material-ui/core';

// Styles
import useStyles from '../../../../assets/styles/jss/pages/feed/feedFileListStyle';
import FeedFileItem from './components/FeedFileItem';


const FeedFileList = ({files, onDownloadFile, isResponsive}) => {
    const classes = useStyles();

    return (
        <Box className={classes.feedFileListRoot}>
            {
                files.map((file, index) => <Box mt={index > 0 ? '8px' : '0'} key={file.file_id}><FeedFileItem media={file.media} onDownloadFile={onDownloadFile} isResponsive={isResponsive}/></Box>)
            }
        </Box>
    );
};

FeedFileList.defaultProps = {
    isResponsive: false,
};

FeedFileList.propTypes = {
    files: PropTypes.arrayOf(PropTypes.object),
    onDownloadFile: PropTypes.func,
    isResponsive: PropTypes.bool,
};

export default FeedFileList;