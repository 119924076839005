import React from 'react'
import loadScript from './load-script'
import removeScript from './remove-script'
import { log } from '../../../shared/utils/console.js'

const useConekta = ({ jsSrc = 'https://cdn.conekta.io/js/latest/conekta.js' } = {}) => {
    function setPublicKey(key) {
        window.Conekta.setPublicKey(key)
    }

    function setLanguage(lang) {
        window.Conekta.setLanguage(lang)
    }

    function createToken(tokenForm, successHandler, errorHandler) {
        window.Conekta.Token.create(tokenForm, successHandler, errorHandler)
    }

    React.useEffect(() => {
        loadScript(document, 'script', 'conekta-js', jsSrc, () => {
            log("Conekta JS loaded")
        })

        return () => {
            removeScript(document, 'conekta-js')
        }
    }, []);

    return {
        setPublicKey,
        setLanguage,
        createToken
    }
};

export { useConekta };
