import React from 'react';

// @material-ui/core
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';

// styles
import useStyles from '../../../../assets/styles/jss/treeItemStyle'

const TreeItemSimple = ({id, label, level, style = {}, component, ...rest}) => {
    const classes = useStyles();

    return (
        <TreeItem 
            nodeId={id} 
            label={
                component 
                ?component
                :<Typography variant="body2" className={classes.labelText} style={style}>{label}</Typography>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: level > 0 ? classes.groupChild : classes.group,
                label: classes.label,
            }}
            {...rest}
        />
    );
};

export default TreeItemSimple;