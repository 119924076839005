import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    titleEventRegistered: {
        marginBottom: "16px",
    },
    titleEventInteresting: {
        margin: "32px 0 16px 0",
    },
    imageNotEvent: {
        width: '189.31px',
        height: '192px'
    },
    containerCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    carruselContainer: {
        width: '225px',
        marginLeft: '0 !important',
    },
    swiperSlide: {
        marginRight : '16px',
    }
}));
