import { setCheckoutCookie, defaultCheckoutCookie } from '../../../../../shared/utils/checkoutSession-legacy.js';
import { MEMBERSHIPS } from '../../../../../shared/constants/memberships';
import { isValidPhoneNumber } from '../../../../../shared/utils/isValidPhoneNumber';
import { log } from '../../../../../shared/utils/console.js';
import { clearUserData } from '../../../../../shared/components/EventTracker/EventTracker.js';
import { checkoutSteps } from '../../CheckoutSummary/helpers/checkoutSummaryHelpers.js';
import * as paths from '../../../../../routes/paths';
import Cookies from 'js-cookie';

export const paymentLinkErrors = {
    ERROR_DEFAULT: 1,
    ERROR_ACCOUNT_EXISTS: 2,
    ERROR_ANOTHER_OPEN_SESSION: 3,
    ERROR_BAD_PAYMENT_LINK: 4
};

// listo
export const processOrder = async (orderData, history) => {
    let userInfo = {};
    userInfo.userName = orderData?.first_name + ' ' + orderData?.last_name;
    userInfo.email = orderData?.email;
    userInfo.is_admin = false;
    userInfo.gender = orderData?.gender;
    userInfo.ou = 'POINT';
    userInfo.ouLabel = 'POINT';
    userInfo.company_id = 'POINT';
    userInfo.membership_id = MEMBERSHIPS.FREEMIUM;
    userInfo.interests = [];
    userInfo.first_name = orderData?.first_name;
    userInfo.last_name = orderData?.last_name;
    userInfo.phone_number = orderData?.phone;
    userInfo.person_id = orderData?.person_id;
    userInfo.activeAccount = orderData?.activeAccount;
    userInfo.activateAccountURL = orderData?.activateAccountURL;
    //
    let checkoutCookieItems = [];
    //
    if (orderData?.membership_id > 0) {
        checkoutCookieItems.push({
            membership_info: {
                membershipId: orderData.membership_id,
                membershipFee: orderData.membership_fee_id
            }
        });
    }
    //
    if (orderData?.program_id > 0) {
        checkoutCookieItems.push({
            program_id: orderData.program_id
        });
    }

    setCheckoutCookie({
        ...defaultCheckoutCookie,
        orderId: orderData?.order_id ? orderData?.order_id : null,
        admissionId: orderData?.admission_id ? orderData.admission_id : null,
        personId: userInfo?.person_id,
        personInfo: userInfo,
        items: checkoutCookieItems,
        membershipSession: true,
        paymentToken: orderData?.payment_token,
        activeAccount: orderData?.activeAccount, // Gestionar la activación de cuentas
        activateAccountURL: orderData?.activateAccountURL,
        initialStep: checkoutSteps.PAYMENT_METHODS
    });

    history.replace({
        pathname: paths.CHECKOUT_LEGACY,
        state: {
            'user': userInfo,
            "activeAccount": orderData?.activeAccount,
            "activateAccountURL": orderData?.activateAccountURL,
            'referrer': "course-landingpage"
        }
    });
};

// listo
export const userCanOpenLink = (linkInfo, isLogged, userData) => {
    if (!validateLink(linkInfo)) {
        return { canOpen: false, message: "Link de pago inválido", error: paymentLinkErrors.ERROR_BAD_PAYMENT_LINK };
    }
    
    if (!isLogged) {
        return { canOpen: true, message: "Usuario no está autenticado", error: null };
    } else if (isLogged) {
        if (linkInfo?.person_id && linkInfo?.person_id === userData?.person_id) {
            return { canOpen: true, message: "Usuario autorizado", error: null };
        } else if (linkInfo?.email && linkInfo?.email === userData?.email) {
            return { canOpen: true, message: "Usuario autorizado", error: null };
        } else {
            return { canOpen: false, message: "No autorizado: la información no coincide", error: paymentLinkErrors.ERROR_ANOTHER_OPEN_SESSION };
        }
    }

    return { canOpen: false, message: "Error desconocido", error: paymentLinkErrors.ERROR_DEFAULT };
};

// listo
export const validateLink = (linkData) => {
    if (!linkData) {
        return false;
    }
    const _errors = {};
    const requiredFields = ['first_name', 'last_name', 'gender', 'email', 'phone'];
    for (const field of requiredFields) {
        if (!linkData[field]) {
            _errors[field] = 'Campo Requerido';
        }
    }
    const { message, error: phoneError } = isValidPhoneNumber(linkData?.phone);
    log(message)
    log(phoneError)
    if (phoneError) {
        _errors.phone = message
    }
    log(_errors)
    return Boolean(Object.keys(_errors).length === 0);
};

export const singleSignOut = (instance, history) => {
    log('logginout----')
    // e.preventDefault();
    const instanceAccount = instance.getActiveAccount();
    const logoutRequest = {
        account: instanceAccount,
        onRedirectNavigate: (url) => {
            // Utiliza el objeto history para navegar a la ruta "/login"
            history.push('/login');
            // Devuelve false para indicar que la redirección se manejará manualmente
            return false;
        },
        postLogoutRedirectUri: `${window.location.origin}/login`,
    };
    Cookies.remove("checkout");
    Cookies.remove("cart");
    Cookies.remove("checkout_sku");
    clearUserData();
    instance.logoutRedirect(logoutRequest);
}