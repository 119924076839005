import React from 'react'

import { Button, Card, CardContent, Grid, Typography, Icon } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    buttonEdit:{
        color: theme.palette.colors.fillStroke[100]
    },
    buttonEditDisabled:{
        color: theme.palette.colors.fillStroke[300]
    },
    card:{
        background:  theme.palette.colors.fillStroke[400],
        border: "1px solid #2C3236"
    },
    cardTitle:{
        fontSize:12,
        fontWeight: 700,
        lineHeight: '24px',
    },
    cardCaption:{
        fontSize:12,
        fontWeight: 400,
        lineHeight: '16px',
        color: theme.palette.colors.gray[100]
    }
}));

const MyProfileCard = (props) => {

    const classes = useStyles();
    const theme = useTheme();

    const { cardSize = 12, cardTitle, caption, btnDisabled, btnFunction, btnText = null, btnIcon, component, loading, componentHeight, btnVisible = true, btnComponents = null, btnComponentsVisible = false} = props;

    return (
        <Grid item md={cardSize} xs={12}>
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={10}>
                            <Typography variant="h6" className={classes.cardTitle} color='primary' align='left'>
                                {cardTitle}
                            </Typography>
                            <Typography className={classes.cardCaption} align='left'>
                                {caption}
                            </Typography>
                        </Grid>
                        {
                            btnVisible &&
                            <Grid item xs={2}>
                                <Grid container justifyContent='flex-end'>
                                    <Button
                                        variant="text"
                                        aria-label="delete"
                                        size="medium"
                                        endIcon={btnIcon && (<Icon className={clsx(`${btnIcon} font-size-16 text-fill-stroke-100` , ((loading || btnDisabled) && 'text-fill-stroke-300'))} />)}
                                        onClick={btnFunction}
                                        disabled={loading || btnDisabled}
                                    >
                                        {/* {btnIcon && ( <Icon className={` ${btnIcon} font-size-16 text-fill-stroke-100`} />)} */}
                                        {btnText && (<Typography variant='body2' className={clsx(classes.buttonEdit, ((loading || btnDisabled) && classes.buttonEditDisabled))} >{btnText}</Typography>)}
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid container
                        spacing={1}
                        className='mt-2'
                        alignItems='center'
                        style={{ height: componentHeight, borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}
                    >
                        <Grid item xs={12}>
                            <Grid container justifyContent='flex-end' wrap="wrap">
                                {component}
                            </Grid>
                        </Grid>
                        {btnComponents && btnComponentsVisible && (
                            <Grid item xs={12}>
                                <Grid container justifyContent='flex-start' alignItems='center' style={{ display:'flex',alignItems: 'center',marginBottom: 5,height: 35 }} >
                                {btnComponents}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default MyProfileCard
