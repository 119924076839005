import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    boxRoot: {
        width: '596px',
        height: '528px',
        backgroundColor: theme.palette.colors.fillStroke[400],
        borderRadius: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '32px',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            paddingLeft: '16px',
            overflowX: 'hidden'
        },
    },  
    boxRootModify: {
        width: '596px',
        height: '575px',
        backgroundColor: theme.palette.colors.fillStroke[400],
        borderRadius: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '32px',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            paddingLeft: '16px',
            overflowX: 'hidden'
        }
    },
    boxTittle: {
        display: 'flex',
        flexDirection: 'column',
        width: '377px',
        marginTop: '32px',
        justifyContent: 'space-between',
        gap: '8px',
        [theme.breakpoints.down('xs')]:{
            width: '88.85%',
            marginTop: '16px',
            
        }
    },
    boxTittleModify: {
        display: 'flex',
        flexDirection: 'column',
        width: '377px',
        [theme.breakpoints.down('xs')]:{
            width: '326px',
        }
    },     
    boxDataSesionFirst: {
        display: 'flex',
        flexDirection: 'column',
        width: '532px',
        height: '64px',
        marginTop: '20px',
        justifyContent: 'space-between',
        marginBottom: '8px',
        [theme.breakpoints.down('xs')]:{
            width: '95.361%',
        }
    },
    boxDataSesion: {
        display: 'flex',
        flexDirection: 'column',
        width: '532px',
        height: '64px', 
        marginTop: '22px',
        justifyContent: 'space-between',
        marginBottom: '8px'
    },
    boxDataDescription: {
        display: 'flex',
        flexDirection: 'column',
        width: '532px',
        height: '200px',
        marginTop: '16px',
        justifyContent: 'space-between',
        marginBottom: '8px',
        [theme.breakpoints.down('xs')]:{
            width: '95.361%',
        }
    },    
    textDescription: {
        width: '532px',
        height: '180px',
        borderRadius: '8px',
        backgroundColor: theme.palette.colors.fillStroke[400],
        border: '0.5px solid ' + theme.palette.colors.fillStroke[100],
    },
    select: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        '&:before': {
            borderColor: theme.palette.colors.fillStroke[100],
        },
        '&:after': {
            borderColor: theme.palette.colors.fillStroke[100],
        },
        [theme.breakpoints.down('xs')]:{
            width: '283px',
            minWidth: '283px'
        }
    },
    icon: {
        fill: theme.palette.colors.white,
    },
    boxSig: {
        display: 'flex',
        width: '521px',
        height: '60px',
        margin: '25px',
        justifyContent: 'right',
        gap:'9px',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('xs')]:{
            width: '95.361%',
            margin: '16px 0px',
            flexDirection: 'column',
            height: 'auto',
        }
    },
    buttonSig:{
        width: 'auto',
        height: '40px',
        marginRight: '12px',
        justifyContent: 'end',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            height: '48px',
            marginRight: '0px !important',
            justifyContent: 'center'
        }
    },
    input: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400',
    paddingTop: '35px',
    maxHeight: '180px',
    [theme.breakpoints.down('xs')]:{
        width: '100%',
        fontSize: '16px',
    },
    '&::placeholder': {
        paddingTop: '35px',
        color: theme.palette.colors.darkBlack[200],
    }
    },
    boxFinalizar: {
        width: '596px',
        height: '204px',
        backgroundColor: theme.palette.colors.fillStroke[400],  
        borderRadius: '16px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]:{
            width:'100%',
            margin: '16px 0px',
        },
    },
    boxControlFinalizar: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]:{
            width:'89.635%'
        },
    },
    boxTextFinalizar: {
        width: '532px',
        height: '64px',
        position: 'relative',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '26px',
        [theme.breakpoints.down('xs')]:{
            width:'100%',
            height: 'auto',
        },
    },
    boxButton: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]:{
            alignSelf: 'stretch'
        },
    },
    boxDataTimes: {
        display: 'flex',
        alignItems: 'center',
        gap:'16px',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: '2px',
        width: '500px',
        [theme.breakpoints.down('xs')]:{
            width: '250px',
            flexDirection: 'column',
            flexWrap: 'wrap',
            gap: 0,
        }
    },
    boxDataTimesSecond: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap:'8px',
        marginTop: '15px',
        height: '16px',
        padding: 0,
        [theme.breakpoints.down('xs')]:{
            width: '250px',
        }
    },
    iconContent: {
        marginRight:'5px',
        color: theme.palette.colors.orange[300]
    },
    iconContentDate: {
        color: theme.palette.colors.electricBlue[200]
    },
    buttonModifyHours: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        padding: '16px 0px',
        gap: '4px', 
        width: '172px',
        height: '32px', 
        marginTop: '8px'
    },
    closeWindow:{
        position: 'absolute',
        display: 'flex',
        width: '550px',
        height: '20px',
        marginTop: '22px',
        justifySelf: 'end',
        justifyContent: 'end',
        [theme.breakpoints.down('xs')]:{
            width: '350px',
            margin: 'auto',
            paddingTop: '13px'
        }
    },
    inputPadding:{
        color: theme.palette.colors.darkBlack[200],
    },
    inputSelect:{
        [theme.breakpoints.down('xs')]:{
            paddingRight: '16px !important'
        },
    },
    boxCreditosActual:{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        [theme.breakpoints.down('xs')]:{
            marginTop: '16px'
        },
    },
    creditosActual:{
        display: 'flex',
        width: '32px',
        height: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(274.05deg,#CDACFC -8.09%,#8B3FFA 61.08%)',
        borderRadius: '100%'
    }
}));