import React from 'react'

// @material-ui/core
import { Button, Card, CardContent, Grid} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/Chat';
import EventIcon from '@material-ui/icons/Event';

const BlockHelpInfo = (props) => {
    return (
        <Card variant="outlined" component={Box} {...props}>
            <CardContent className="p-4">
                <Typography variant="h4" className="mb-2">¿Necesitas Ayuda?</Typography> 
                <Typography variant="body1">Comunícate al service center.</Typography> 
                {/*<Box width="100%" mt={3}>
                    <Button variant="contained"  color="primary" className="py-2 mr-3"
                        startIcon={<ChatIcon style={{ fontSize: "20px" }} />}>Chat</Button>
                    <Button variant="contained"  color="primary" className="py-2"
                        startIcon={<EventIcon style={{ fontSize: "20px" }} />}>Agendar Asesoria</Button>
                </Box>*/}
            </CardContent>
        </Card>
    )
}

export default BlockHelpInfo;
