import React from "react";

// @redux
import { connect } from "react-redux";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinkIcon from "@material-ui/icons/InsertLink";
import { Checkbox, InputAdornment, List, ListItem, Paper } from "@material-ui/core";

import Switch from "@material-ui/core/Switch";

// shared/components
import Breadcrumb from "../../../shared/components/Breadcrumb/Breadcrumb";
import Loader from "../../../shared/components/Loader/LoaderContent";
import Picker from "../../../shared/components/Form/Picker";
import Select from "../../../design-system/components/Form/Select/Select";
import Toast from "../../../shared/components/Toast/Toast";
import TreeList from "../../../shared/components/TreeList/TreeList";
import GroupsSelector from "../../../shared/components/GroupsSelector/GroupsSelector";
import { checkItem, changeCheckParent, transformToNestedList, someItemChecked, getAllChecked } from "../../Feed/FeedNew/treeListVariations";
import moment from "moment";
import StudentList from "../../Simulation/StudentList/StudentList";

//SDK
import { AdminNotifService } from '@sdk-point/talisis'; 

const AdminNotifServiceSDK = new AdminNotifService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const items = [
    { to: "/notifications", label: "Notificaciones" },
    { label: "Administrador de Notificaciones" },
]

const NotificationsNew = (props) => {
    const { history, user } = props;

    const classes = {};
    const today = new Date();
    const fontFamily = { fontFamily: "'Source Sans Pro', sans-serif" };

    const [loading, setLoading] = React.useState(false);
    const [formInfo, setFormInfo] = React.useState({
        is_critical: false,
        title: "",
        publish_date: today,
        publish_hour: "",
        persons: [],
        groups: [],
        type_id: "",
        body: "",
        url: "",
        action_required: false,
        unitsList: [],
    });
    const [persons, setPersons] = React.useState([]);
    const [receivers, setReceivers] = React.useState("");
    const [notificationTypes, setNotificationTypes] = React.useState([]);
    const [toastProps, setToastProps] = React.useState({
        message: "",
    });
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await setOperatingUnits();
            await reciveNotificationsTypes();
            setLoading(false);
        }
        fetchData();
    }, []);

    const setOperatingUnits = async () => {
        const operatingUnits = await AdminNotifServiceSDK.getOperatingUnits();
        const units = operatingUnits.map(units => units.id);
        const _operatingUnits = operatingUnits.filter(it => units.some(u => u === it.id))
        const unitsList = transformToNestedList(_operatingUnits);
        setFormInfo({ ...formInfo, unitsList });
    };

    const reciveNotificationsTypes = async () => {
        await AdminNotifServiceSDK.getNotifTypes()
            .then(data => {
                const notificationTypesCat = data.body;
                notificationTypesCat.map(element => {
                    element.label = element.name;
                    return element;
                });
                setNotificationTypes(notificationTypesCat);
            })
            .catch(e => {
                setToastProps({
                    open: true,
                    message: `Ops! Ha ocurrido un error al cargar la información: ${e}`,
                    severity: "error",
                });
            });
    }

    const handleChange = e => {
        let { name, value } = e.target;
        if (value) {
            delete errors[name];
        }
        switch (name) {
            case "is_critical":
                value = !formInfo.is_critical;
                break;
            case "action_required":
                value = !formInfo.action_required;
                break;
        }
        setFormInfo({ ...formInfo, [name]: value });
    };

    const handleTimeFormat = () => {
        const timeSelector = document.getElementById("time");
        const splitHour = timeSelector.value.split(":");
        const hour = splitHour[0];
        let minutes = splitHour[1];
        minutes = Math.round(minutes / 15) * 15;
        minutes = (minutes === 60) ? 0 : minutes;
        minutes = String(minutes).padStart(2, 0);
        const newTime = `${hour}:${minutes}`;
        timeSelector.value = newTime;
        setFormInfo({ ...formInfo, publish_hour: newTime });
    };

    const handlePersonChange = (event, value) => {
        setPersons(value);
    };

    const handleReceivers = (event) => {
        setReceivers(event.target.value);
    };

    const handleCheck = (itemSelected) => {
        const items = [...formInfo.unitsList];
        checkItem(items, "id", itemSelected, "children");
        changeCheckParent(items, itemSelected.parent, items);
        setFormInfo({ ...formInfo, unitsList: items });
    };

    const handleGroups = (indeterminateGroups, selectedGroups) => {
        const allGroupsCheckboxCtrl = document.getElementsByName("all_groups").item(0);
        if ((allGroupsCheckboxCtrl && allGroupsCheckboxCtrl.checked) || indeterminateGroups) {
            if (selectedGroups.length > 0) {
                const groupIds = [];
                for (let group of selectedGroups) {
                    groupIds.push(group.id);
                }
                setFormInfo({ ...formInfo, groups: groupIds });
            }
        }
        else {
            setFormInfo({ ...formInfo, groups: [] });
        }
    }

    const validate = () => {
        const errors = {};
        const requiredFields = [
            "title",
            "publish_date",
            "publish_hour",
            "type_id",
        ];

        for (const field of requiredFields) {
            if (formInfo.is_critical && field === "publish_hour") {
                continue;
            }
            if (!formInfo[field]) {
                errors[field] = "Campo Requerido";
            }
        }

        switch (receivers) {
            case "units":
                if (!someItemChecked(formInfo.unitsList)) {
                    errors.receivers = "Campo Requerido";
                }
                break;
            case "groups":
                if (formInfo.groups.length === 0) {
                    errors.receivers = "Campo Requerido";
                }
                break;
            case "persons":
                if (persons.length === 0) {
                    errors.receivers = "Campo Requerido";
                }
                break;
        }

        setErrors(errors);
        return Boolean(Object.keys(errors).length === 0);
    }

    const handleSubmit = async () => {
        if (validate()) {
            try {
                setLoading(true);
                setErrors({});

                let companies = [];
                let campus = [];
                let groups = [];
                let personIds = [];

                if (receivers === "units") {
                    const itemsChecked = getAllChecked(formInfo.unitsList[0].children);
                    companies = itemsChecked.map((item) => {
                        let company = "";
                        if (item.level === 2) {
                            company = item.id;
                        }
                        else if (item.level === 3) {
                            company = item.company_id;
                        }
                        return company;
                    });

                    itemsChecked.map((item) => {
                        if (item.level === 2) {
                            if (item.children.length > 0) {
                                item.children.map(it => campus.push(it.id));
                            }
                        }
                        if (item.level === 3) {
                            campus.push(item.id);
                        }
                    });
                }
                else if (receivers === "groups") {
                    groups = formInfo.groups;
                }
                else if (receivers === "persons") {
                    personIds = persons.map(person => person.id);
                }

                const notificationType = notificationTypes.filter(type => type.id === formInfo.type_id);
                const notifType = notificationType[0].name;

                const body = {
                    isCritical: formInfo.is_critical ? 1 : 0,
                    title: formInfo.title,
                    message: formInfo.body,
                    notifType,
                    companies: companies.length > 0 ? companies : null,
                    campus: campus.length > 0 ? campus : null,
                    groups: groups.length > 0 ? groups : null,
                    persons: personIds.length > 0 ? personIds : null,
                    actionRequired: formInfo.action_required ? 1 : 0,
                    url: formInfo.url,
                };

                const hours = formInfo.publish_hour.substring(0, 2);
                const minutes = formInfo.publish_hour.substring(3, 5);
                const publishDate = new Date(formInfo.publish_date);
                publishDate.setHours(hours, minutes);
                if (formInfo.is_critical || (publishDate.getTime() <= new Date().getTime())) {
                    body['publishDate'] = '';
                }
                else {
                    body['publishDate'] = `${moment(formInfo.publish_date).format("YYYY-MM-DD")} ${formInfo.publish_hour}:00`;
                }

                const ok = await AdminNotifServiceSDK.createNotification(body);
                if (ok) {
                    setToastProps({ open: true, message: "La Notificación fue creada correctamente." });
                }
            }
            catch (e) {
                setLoading(false);
                setErrors({ message: "Ha ocurrido un error al intentar crear la notificación." });
                setToastProps({
                    open: true,
                    message: `Ops! Ha ocurrido un error al intentar crear la notificación. ${e}`,
                    severity: "error",
                });
            }
        }
    };

    const handleFinishedToast = () => {
        if (Object.keys(errors).length > 0) {
            setToastProps({ ...toastProps, open: false });
            return;
        }
        else {
            history.push("/notifications");
        }
    }

    const PersonsList = () => {
        if (persons.length > 0) {
            return (
                <Paper>
                    <List style={{ overflow: "auto", maxHeight: "10em" }}>
                        {persons.map(person => (
                            <ListItem key={person.id}>
                                {person.full_name}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            );
        }
        return null;
    };

    return (
        <React.Fragment>
            <Breadcrumb items={items} />
            <Loader loading={loading}>
                <Card>
                    <CardContent className="NotificationsCardContent">
                        <span className="NotificationsCardContent">
                            <div style={{ height: "35px" }}></div>
                        </span>
                    </CardContent>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Typography variant="subtitle2" style={fontFamily}><b>Mensaje</b></Typography>
                                <TextField
                                    required
                                    variant="outlined"
                                    placeholder="Ingresar texto"
                                    label="Título de la Notificación"
                                    fullWidth
                                    margin="dense"
                                    name="title"
                                    value={formInfo.title}
                                    onChange={handleChange}
                                    error={Boolean(errors.title)}
                                    helperText={errors.title}
                                />
                            </Grid>
                            <Grid item xs={7} className="NotificationsIsCriticalGrid">
                                <Typography variant="subtitle2" style={{ margin: "0", padding: "0", verticalAlign: "middle", display: "table-cell" }}>
                                    <b>Enviar ahora</b><Switch name="is_critical" color="primary" checked={formInfo.is_critical} onChange={handleChange} />
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className="NotificationsGridLeft">
                                <Typography variant="subtitle2" style={fontFamily}><b>Fecha de Publicación</b></Typography>
                                <Picker
                                    required
                                    margin="dense"
                                    name="publish_date"
                                    value={formInfo.publish_date}
                                    onChange={handleChange}
                                    label="Fecha de Publicación"
                                    minDate={new Date()}
                                    disabled={formInfo.is_critical ? true : false}
                                    error={Boolean(errors.publish_date)}
                                    helperText={errors.publish_date}
                                />
                                <span className="note">Zona Horaria: Ciudad de México, CDMX (GMT-5)</span>
                            </Grid>
                            <Grid item xs={2} className="NotificationsGridRight">
                                <Typography variant="subtitle2" style={fontFamily}><b>Hora de Publicación</b></Typography>
                                <TextField
                                    required
                                    id="time"
                                    name="publish_hour"
                                    label="Hora de Publicación"
                                    type="time"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleTimeFormat();
                                    }}
                                    margin="dense"
                                    className={classes.hourPicker}
                                    defaultValue={formInfo.publish_hour}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 900, // 15 min
                                    }}
                                    InputProps={{
                                        className: classes.hourPickerInput,
                                    }}
                                    disabled={formInfo.is_critical ? true : false}
                                    error={Boolean(errors.publish_hour)}
                                    helperText={errors.publish_hour}
                                />
                            </Grid>
                            <Grid item xs={2}>&nbsp;</Grid>
                            <Grid item xs={5} className="NotificationsGridLeft">
                                <Typography variant="subtitle2" style={fontFamily}><b>Categoría de la Notificación</b></Typography>
                                <Select
                                    key="type_id"
                                    required
                                    items={notificationTypes}
                                    value={formInfo.type_id}
                                    label="Categoría de la Notificación"
                                    name="type_id"
                                    onChange={handleChange}
                                    error={Boolean(errors.type_id)}
                                    helperText={errors.type_id}
                                    SelectProps={{
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Ingresar texto"
                                    label="Mensaje (Opcional)"
                                    fullWidth
                                    margin="dense"
                                    name="body"
                                    value={formInfo.body}
                                    onChange={handleChange}
                                    multiline
                                    rows={8}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="URL"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><LinkIcon /></InputAdornment>,
                                    }}
                                    margin="dense"
                                    name="url"
                                    value={formInfo.url ? formInfo.url : ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" style={fontFamily}><b>Destinatarios</b></Typography>
                                <Select
                                    key="receivers"
                                    required
                                    items={[
                                        { id: "units", name: "units", label: "Unidades Operativas", },
                                        { id: "groups", name: "groups", label: "Grupos", },
                                        { id: "persons", name: "persons", label: "Contactos Individuales", },
                                    ]}
                                    value={receivers}
                                    label="Seleccionar Destinatarios"
                                    name="receivers"
                                    onChange={handleReceivers}
                                    error={Boolean(errors.receivers)}
                                    helperText={errors.receivers}
                                    SelectProps={{
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }
                                    }}
                                />
                                <React.Fragment>
                                    <div style={{ display: (receivers === "units" ? "" : "none"), marginTop: "5px", width: "100%" }}>
                                        <TreeList items={formInfo.unitsList} onCheck={handleCheck} />
                                    </div>
                                    <div style={{ display: (receivers === "groups" ? "" : "none"), marginTop: "5px", width: "100%" }}>
                                        <GroupsSelector onToggle={handleGroups} />
                                    </div>
                                    <div style={{ display: (receivers === "persons" ? "" : "none"), marginTop: "5px", width: "100%" }}>
                                        <StudentList user={user} handleChange={handlePersonChange} includePersons={true}/>
                                        <PersonsList data={persons} />
                                    </div>
                                </React.Fragment>
                            </Grid>
                            <Grid item xs={6} className="NotificationsGridLeft">
                                <Typography variant="subtitle2" className="mb-4" style={fontFamily}>
                                    <b>Acción requerida</b><Switch name="action_required" color="primary" checked={formInfo.action_required} onChange={handleChange} />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} className="NotificationsGridRight">
                                <Box display="flex" justifyContent="flex-end" width="100%" >
                                    <Button color="primary" variant="contained" onClick={handleSubmit}>Crear</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Loader>
            <Toast {...toastProps} onFinished={handleFinishedToast} />
        </React.Fragment>
    );
}

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(NotificationsNew);