import { makeStyles } from '@material-ui/core/styles';

export const certificateStyles = makeStyles((theme) => ({
    search: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 2,
      height: "7em",
      paddingLeft: 10,
      background: theme.palette.colors.black,
      borderRadius: 8,
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      height: 35,
      // paddingLeft:10
    },
    buttonfilter: {
      display: 'flex',
      alignItems: 'center',
      height: 35,
    },
    select: {
      flex: 1,
      marginTop: '0px !important',
      paddingLeft: 10,
      marginLeft: 5,
      "& .arrow-down-icon":{
        marginRight: 20
      }
    },
    input: {
      flex: 1,
      width: 40

    },
    iconButton: {
      padding: 10,
      color: theme.palette.white,
    },
    grid: {
      marginBottom: '10px'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      width: '30%',
      height: '22%'
    },
    modalStudent: {
      display: 'flex',
      justifyContent: 'center',
    },
    cardStudent: {
      width: '75%',
      overflowY: 'scroll',
      height: '96%',
      marginTop: '1%'

    },
    dangerBtn: {
      marginRight: '0.5rem',
    },
    title: {
      justifyContent: 'center',
      display: 'flex',
    },
    textArea: {
      backgroundColor: theme.palette.colors.black,
      height: "6em",
      overflow: "auto",
      width: "100%",
      borderRadius: 8,
    },
    bioText: {
      color: theme.palette.colors.white,
      background: "none",
      fontSize: "0.75em",
    }
}));
