import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

//style 
import useStyles from '../../../assets/styles/jss/components/simpleSearchStyle';

import {
    InputBase,
    IconButton, Icon, Box
} from '@material-ui/core';

const SearchContainer = styled.div`
    ${({ theme}) => `
        border-radius: ${theme.shape.borderRadius * 2}px;
        background-color: ${theme.palette.colors.fillStroke[300]};
        display: flex;
        align-items: center;
        height: 100%;
    `}
`;

const SearchIcon = styled.div`
    ${({ theme}) => `
        padding: 0px 10px 0 16px;
    `}
`

const InputBaseStyled = styled(InputBase)`
    ${({ theme}) => `
        width: 100%;
        color: #FFFFFF;
        border-radius: 0;
        background-color: transparent !important;
    `}
`;

const SimpleSearch = ({onChange, placeholder}) => {
    const classes = useStyles();
    const handleClickRemoveText = event => {
        setTextFilter('');
        onChange({target: {value : ""}});
    };

    const [textFilter, setTextFilter] = useState('');

    const handleChange = (event) => { 
        setTextFilter(event.target.value);
        onChange(event);
    };

    return (
        <SearchContainer>
            <SearchIcon>
                <i className="ri-search-line"></i>
            </SearchIcon>
            <InputBaseStyled
                value={textFilter}
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleChange}
            />                
            <Box visibility={(!!textFilter.trim().length)? 'visible' : 'hidden'}>
                <IconButton aria-label="delete" className={classes.closeIconButton} onClick={handleClickRemoveText}>
                    <Icon className="fa fa-close" style={{fontSize: 16}}/>
                </IconButton>
            </Box>
        </SearchContainer>
    );
};

SimpleSearch.defaultProps = {
    placeholder: 'Buscar',
}

SimpleSearch.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

export default SimpleSearch;