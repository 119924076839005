import React from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// components
import CourseItem from './CourseItem/CourseItem';
import RateModal from './RateModal/RateModal';

// shared
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import Toast from '../../../shared/components/Toast/Toast';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';

// redux
import { connect } from 'react-redux';

// services
// import * as marketService from '../../../services/MarketPlaceService';
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';


const useStyles = makeStyles((theme) => ({
    table: {
        width: "100%",
        "& .MuiTableCell-root": {
            borderBottom: "none"
        }
    },
    paper: {
        textAlign: "center",
        padding: theme.spacing(3)
    }
}));

const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#049CB4"),
        fontWeight: "bold",
        textTransform: "capitalize",
        backgroundColor: "#00C1DE",
        '&:hover': {
            backgroundColor: "#049CB4",
        },
    },
}))(Button);

const CompletedCourses = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [neoCourses, setNeoCourses] = React.useState([]);
    const [thirdPartyCourses, setThirdPartyCourses] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [toastProps, setToastProps] = React.useState({ message: '' });
    const [userIdNeo, setUserIdNeo] = React.useState(0);
    const [openModal, setOpenModal] = React.useState(false);
    const { user } = props;
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const fetchCourses = async () => {
        setLoading(true);
        try {
            const data = await marketService.getUserCourses(user);
            setNeoCourses(data.neo_courses.classes.filter(it => it.archived));
            setThirdPartyCourses(data.third_party_courses.filter(it => it.end_date));
            setUserIdNeo(data.neo_courses.user_id);
        } catch (error) {
            setToastProps({ severity: "error", open: true, message: 'Ops! ha ocurrido un error' })
        }
        setLoading(false);
    };

    React.useEffect(() => {
        fetchCourses();
    }, []);

    const handleModalSuccess = () => {
        fetchCourses();
    };

    const handleFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    };

    const handleRateClick = (item) => {
        setSelectedItem(item);
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const handleGoToCatalog = () => {
        history.push("/");
    };

    return (
        <Box mb={5}>
            <Breadcrumb items={[
                { label: 'Cursos' },
                { label: 'Clases concluidas' },
            ]} />
            <LoaderContent loading={loading} transparent={true}>
                <Box minHeight="calc(100vh - 450px)">
                    {!loading ?
                        <React.Fragment>
                            {neoCourses.length > 0 || thirdPartyCourses.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Plataforma</TableCell>
                                                <TableCell>Nombre del curso</TableCell>
                                                <TableCell>Fecha de concluido</TableCell>
                                                <TableCell width="20%">Evaluación del curso</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                neoCourses.map(el =>
                                                    <CourseItem
                                                        {...el}
                                                        key={el.id}
                                                        source={user.ouLabel}
                                                        subject={el.subject_description}
                                                        onRateClick={handleRateClick}
                                                    />
                                                )
                                            }
                                            {
                                                thirdPartyCourses.map(el =>
                                                    <CourseItem
                                                        {...el}
                                                        key={el.id}
                                                        source={el.source_name}
                                                        subject={el.subject_description}
                                                        onRateClick={handleRateClick}
                                                    />
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer> :
                                <Box textAlign="center">
                                    <NothingFound
                                        caption="No cuentas con clases completadas"
                                        subcaption="Aquí encontrarás el contenido que hayas finalizado"
                                        type="completed-courses" />
                                    <BlueButton onClick={handleGoToCatalog}>Ver catálogo de cursos</BlueButton>
                                </Box>
                            }
                        </React.Fragment> : null
                    }
                </Box>
            </LoaderContent>
            <RateModal
                open={openModal}
                course={selectedItem}
                user={user}
                userIdNeo={userIdNeo}
                onClose={handleModalClose}
                onSuccess={handleModalSuccess}
                onToastProps={setToastProps}
            />
            <Toast {...toastProps} onFinished={handleFinishedToast} />
        </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CompletedCourses);