import React, { Component } from 'react';
import { generatePath } from "react-router";

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// shared - core components
import Breadcrumb from '../../../../../shared/components/Breadcrumb/Breadcrumb'
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent'
import Form from '../../../../../shared/components/Form/Form'

// core componentes
import Authorizers from '../../components/Authorizers/Authorizers';

// @redux
import { connect } from "react-redux"
import * as requestWorkflowActions from '../../../../../redux/actions/requestWorkflowActions'

import * as routelinks from '../../../../../routes/routelinks'

const STEP_TYPE_FORM_ID = 1;

class StepConfig extends Component {

    constructor(props){
        super();

        const { match: {params: {id, stepId} }, newRequest: {form:requestDetail, workflow} } = props;

        let step = {
            title: '',
            instruction: '',
            type: '',
            fields: [],
            authorizers: [],
            assignee: requestDetail.ou.map(ou => ({...ou, full_name: ou.label}))        
        };

        let formValue = {};

        if(stepId && workflow.length){
            step = workflow.find(it => it.id === stepId);

            for(const f of step.fields){
                formValue[f.name] = f.value;
            }
        }


        this.state = {
            ous: requestDetail.ou,
            openDynamicForm: step.type === STEP_TYPE_FORM_ID,
            loading: false,
            options: [],
            criteria: "",
            stepTypesList: [],
            form:{
                ...step
            },
            formField:{
                type: '',
                label: '',
                col: 12,
                required: false
            },
            formValue:{},
            errors:{},
            openAddField: false,
            breadcrumbItems: [
                {to: routelinks.GESTION_SERVICIOS , label: 'Administracion de Trámites'},
                //{to: `/requests-managment/request/${id}`, label: 'Trámite'},
                {to: generatePath(routelinks.GESTION_SERVICIO_SHOW, {id}), label: 'Trámite'},
                {label: 'Configuración de Paso', title:step.title},
            ]
        }
    }

    handleCancel = () => {
        let id = this.props.match.params.id;
        let path = generatePath(routelinks.GESTION_SERVICIO_SHOW, {id})
        this.props.history.push(path);
    }

    render() {
        const {loading, form, openDynamicForm, breadcrumbItems, ous, formValue} = this.state;
        return (
            <div>
                <Breadcrumb items={breadcrumbItems} />

                <Grid container spacing={3}>
                    <Grid container item xs={12}>
                        <LoaderContent loading={loading}>
                            <Card>
                                <CardContent>
                                    <Typography variant="subtitle2" className="mb-4">Trámite</Typography>
                                    <Grid container spacing={3}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                    margin="dense"
                                                    variant="outlined"
                                                    label="Titulo del paso"
                                                    fullWidth
                                                    name="title"
                                                    value={form.title || ''}
                                                    readOnly
                                                />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                    margin="dense"
                                                        variant="outlined"
                                                        label="Tipo de paso"
                                                        fullWidth
                                                        name="type"
                                                        value={form.type}
                                                        readOnly
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Instrucciones"
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        name="instruction"
                                                        value={form.instruction}
                                                        readOnly
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <br/>

                                    <Typography variant="subtitle2" className="mb-4">Autorizadores</Typography>
                                    <Box mb={2}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    readOnly
                                                    checked={form.assignToApplicant}
                                                    color="primary"
                                                    name="assignToApplicant"
                                                />
                                            }
                                            label="Asignar al solicitante"
                                        />
                                    </Box>

                                    <Authorizers ous={ous} authorizers={form.authorizers} readOnly={true}/>

                                    {
                                        openDynamicForm &&
                                        <Grid container item xs={12}>
                                            <Typography variant="subtitle2" className="mb-4 mt-4">Formulario</Typography>
                                            <Grid container>
                                                <Grid item xs={12} md={8}>
                                                    <Box style={{border:'1px solid #B8BCC0', borderRadius: 16}} p={2}>
                                                        <Form fields={form.fields} values={formValue}/>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                </CardContent>
                            </Card> 
                        </LoaderContent>
                    </Grid>

                    <Grid container item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            <Button color="primary" variant="outlined" onClick={this.handleCancel} className="mr-2">Regresar</Button>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (reducers) => ({newRequest: reducers.requestWorkflowReducer});

export default connect(mapStateToProps, {...requestWorkflowActions})(StepConfig);