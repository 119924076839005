import { makeStyles, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

const toolbarHeight = 48;

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.colors.gray[100],
        minHeight: '100vh'
    },
    content: {
        flexGrow: 1,
        overflowX: "hidden",
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        height: toolbarHeight
    },
    toolbar:{
        justifyContent: 'center',
        height: toolbarHeight
    },
    img:{
        backgroundRepeat:'no-repeat',
        backgroundPosition: "center", 
        backgroundSize: "cover",
        height:274,
        [theme.breakpoints.down('sm')]:{
            height: 145.6,

        },
        [theme.breakpoints.down('xs')]:{
            height: 200,
        },
        width: '100%',
    },
    imgBanner:{
        width: '100%',
        height: '100%'
    },
    appBar:{
        backgroundColor: theme.palette.colors.ou.unidPurple
    },
    appBarUerre:{
        backgroundColor: theme.palette.colors.ou.uerreBlack
    },
    logo:{

    },
    unid:{
        '& .app-bar':{
            backgroundColor: theme.palette.colors.ou.unidPurple
        },
        '& .title, .Mui-checked, .MuiCheckbox-root':{
            color: theme.palette.colors.ou.unidYellow
        },
        '& .subtitle':{
            color: theme.palette.colors.ou.unidPurple
        },
        '& .text':{
            color: theme.palette.colors.fillStroke[500]
        },
        '& .MuiFormLabel-root':{
            color: `${theme.palette.colors.fillStroke[200]} !important`,
        },
        '& .MuiInputBase-root':{
            backgroundColor: `${theme.palette.common.white} !important`,
        },
        '& .MuiInputBase-input': {
            backgroundColor: `${theme.palette.common.white} !important`,
            color: theme.palette.colors.fillStroke[200],
            '&:focus': {
                backgroundColor: '#FFFCF2',
                border: `1.5px solid ${theme.palette.colors.ou.unidYellow} !important`,
            },
            '&::placeholder': {
                color: `${theme.palette.colors.darkBlack[200]} !important`,
            },
        },
        '& .button-start':{
            backgroundColor: theme.palette.colors.ou.unidPurple,
            marginBottom: 12,
        },
        '& .button-login':{
            padding: 12,
            color: theme.palette.colors.ou.unidPurple
        },
        '& .terms':{
            color: theme.palette.colors.darkBlack[200],
            '& a':{
                color: theme.palette.colors.ou.unidYellow
            }
        },
        '& .MuiBackdrop-root':{
            maxWidth: "none !important",
            '& .MuiCircularProgress-colorPrimary':{
                color: theme.palette.colors.ou.unidPurple
            }
        }
    },
}))