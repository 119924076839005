import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root:{
        padding: '71px 0 47px',
        [theme.breakpoints.down('sm')]: {
            padding: '31px 0 40px',
        }
    },
    slide:{
        height: 350,
        maxWidth: 448,
        [theme.breakpoints.down('sm')]: {
            // height: 370,
            maxWidth: 280
        }
    },
    slide_title:{
        '& .title':{
            height: 92,
            maxWidth: 412,
            display: 'flex',
            marginLeft: 'auto',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        [theme.breakpoints.down('sm')]: {
            '& .title':{
                height: 'auto'
            },
        }
    },
    slide_opinion:{
        margin: '43px 0 27px',
        minHeight: 111,
        [theme.breakpoints.down('sm')]: {
            margin: '25px 0',
        }
    },
    slider_quote:{
        marginLeft: 'auto',
        width: '94%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    card:{
        height: 420
    },
    cardContent:{
        position: 'relative',
        padding: '47px 16px 24px',
        height: 'calc(100% - 80px)',
        display: 'grid'
    },
    quote:{
        position: 'absolute',
        top: '-24px',
        left: 16
    },
    dots:{
        marginRight: 62,
        [theme.breakpoints.down('sm')]: {
            marginRight: 36,
        }
    }
}));