import { CATALOG_PATH, UPLOAD_FILE_PATH, FILES_PATH, COMPANY_CAMPUS_PATH, DOWNLOAD_FILES_PATH } from './endpoints'
export const getCatalog = async (tableName, conditions = '') => {
    let queryParams = '';

    if (!!conditions && typeof conditions === 'object') {
        queryParams = '?';
        for (let param in conditions) {
            queryParams += `${param}=${conditions[param]}&`;
        }

        queryParams = queryParams.substring(0, queryParams.length - 1);
    }

    const url = `${CATALOG_PATH}/${tableName}${queryParams}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_X_API_KEY
        },
    };

    const response = await fetch(url,requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const uploadFile = async (file, location, category, rowId, name) => {
    const formData = new FormData();

    formData.append(name, file)
    formData.append('location', location)
    formData.append('category', category)
    formData.append('rowId', rowId)

    const options = {
        method: 'POST',
        body: formData,
    };

    const response = await fetch(UPLOAD_FILE_PATH, options);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getFile = async (id) => {
    const url = `${FILES_PATH}/${id}`;

    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}
export const deleteFile = async (id) => {
    const url = `${FILES_PATH}/${id}`;

    const requestOptions = {
        method: 'DELETE'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const downloadFile = async (docURL) => {
    const url = `${DOWNLOAD_FILES_PATH}/${encodeURIComponent(docURL)}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        },
    };

    const response = await fetch(url, requestOptions);
    const data = await response.text();

    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
};

export const getOperatingUnits = async (company) => {
    const url = `${COMPANY_CAMPUS_PATH}`;

    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}
