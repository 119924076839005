import React from 'react'

import { Box, Button, Card, CardContent, Fab, Grid, Icon, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import EventIcon from '@material-ui/icons/Event';
import { LineStyle } from '@material-ui/icons';
import { userIsLogged } from '../../utils/Sessions';


const useStyles = makeStyles((theme) => ({
    tittleText:{
        color: theme.palette.colors.gray[500]
    },
    helpButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        width: '32px',
        height: '32px',
        minHeight: '32px !important',
        background: theme.palette.colors.orange[300]
    },
    helpButtonText: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.colors.gray[100],
        fontFamily: 'Inter',
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '40px',
        letterSpacing: 0,
        textAlign: 'center',
    },
    boxInfoFAQ:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        gap: '17px',
        marginBottom: props => !(!props.buttonName?.length && props.isLogged) && 16
    },
    boxInfoAyuda:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '9px'
    },
    boxFAQ:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: props => props.buttonName ? '24px 24px 8px 24px' : (props.noPadding?'20px 0px 0px 0px':'24px'),
        width: '-webkit-fill-available',
        [theme.breakpoints.down('xs')]: {
            padding: '0px !important'
        }
    },
    boxQuestion:{
        display:'flex', 
        alignItems:'center', 
        justifyContent: 'center'
    },
    butttonWhats:{
        backgroundColor: '#25D366',
        '& .MuiButton-startIcon':{
          '&.MuiButton-iconSizeMedium > *:first-child':{
              fontSize: '24px !important'
          },
        },
        "&:hover":{
          background: "#0B8769"
        }
    },
    boxWhatsapp:{
      display: 'flex',
      flexDirection: 'column',
      width: '-webkit-fill-available',
    },
    lineSeparator:{
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        width: '100%',
        height: 0
    },
    boxTittle:{
        display: 'flex',
        width: '-webkit-fill-available',
        marginBottom: 24
    }
}));

const FAQsCourse = ({ title, buttonName, buttonAction, noPadding }) => {
    
    const isLogged = userIsLogged();
    const classes = useStyles({buttonName, isLogged, noPadding});
    const numberWhatsapp = '528118212007'

    const handleGoToWhatsapp = () => {
        let textoCodificado = encodeURIComponent('¡Hola!, Me pudieran apoyar con una duda sobre Talisis.com.')
        window.open(`https://wa.me/${numberWhatsapp}?text=${textoCodificado}`)
      }

    return (
        <Box className={classes.boxFAQ}>
            <Box className={classes.boxTittle}>
                <Typography variant='h4' className={classes.tittleText}>{title}</Typography>
            </Box>
            <Box width={'100%'}>
                <Box className={classes.boxInfoFAQ}>
                    <Box display={'flex'} flex={'none'} width={32} height={32}>
                        <Box aria-label="ayuda" className={classes.helpButton}>
                            <Icon className='ri-customer-service-2-fill text-dark-black-500 font-size-16'/>
                        </Box>
                    </Box>
                    <Box className={classes.boxInfoAyuda}>
                        <Typography variant='body2'>
                            Haz clic en el ícono de ayuda en la parte inferior derecha de la pantalla.
                        </Typography>
                    </Box>
                </Box>
                {
                !isLogged &&
                <Box display={'flex'} flexDirection={'column'} gridGap={16}>
                    <Box display={'flex'} flexDirection={'row'} gridGap={8} alignItems={'center'} justifyContent={'center'}>
                        <Box className={classes.lineSeparator} />
                        <Typography>o</Typography>
                        <Box className={classes.lineSeparator} />
                    </Box>
                    <Box className={classes.boxWhatsapp}>
                        <Button variant='contained' className={classes.butttonWhats} onClick={handleGoToWhatsapp} startIcon={<Icon className={'ri-whatsapp-line'} />}>Escríbenos por WhatsApp</Button>
                    </Box>
                </Box>
                }
                {
                buttonName?.length &&
                <Box mt={2} width={'-webkit-fill-available'} justifyContent={'center'}>
                    <Button
                        color={'primary'}
                        variant="contained" onClick={buttonAction}
                        style={{ width: "100%" }}
                    >
                        <HelpOutlineIcon fontSize='small' className='mr-1'/>
                        <Typography variant='body2'>
                            { buttonName }
                        </Typography>
                    </Button>
                </Box>
                }
            </Box>
        </Box>
    )
}

export default FAQsCourse
