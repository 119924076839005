import React, { useState } from 'react'

import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { checkoutPhoneErrorStyles } from './checkoutPhoneErrorStyles';

import { isValidPhoneNumber } from '../../../../shared/utils/isValidPhoneNumber';

import { MarketPlaceService } from '@sdk-point/talisis';
import { getCheckoutCookie, setCheckoutCookie } from '../../../../shared/utils/checkoutSession-legacy.js';
import { useDispatch, useSelector } from 'react-redux';
import { setPersonInfo, toggleMissingPhone } from '../../../../redux/actions/legacyCheckoutActions.js';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

const CheckoutPhoneError = ({ phone, setPhone, setMissingPhone, currentPerson }) => {

    const history = useHistory();
    const classes = checkoutPhoneErrorStyles();
    const checkoutCookie = getCheckoutCookie();

    // Checkout Reducer
    const { personInfo } = useSelector((state) => state.legacyCheckoutReducer);
    const dispatch = useDispatch();

    const [phoneErrorMsg, setphoneErrorMsg] = useState("")

    const handlePhoneInput = (e) => setPhone(e.target.value);  
    
    const handleVerifyPhone = async() => {
        const { message, error } = isValidPhoneNumber(phone);
        if(error) 
            return setphoneErrorMsg(message)

        if(!error){
            
            dispatch(toggleMissingPhone(error))
    
            const responseUpdateNumber = await marketService.createOrder({ person_id: currentPerson }, {
                'phoneNumber': phone,
                'updatePhone': true
            });

            dispatch(setPersonInfo({ 
                ...personInfo, 
                phone_number: responseUpdateNumber?.phoneNumber 
            }));
    
            setCheckoutCookie({
                ...checkoutCookie,
                personInfo: {
                    ...checkoutCookie?.personInfo,
                    phone_number: responseUpdateNumber?.phoneNumber
                }
            })
            
        }
    }

    return (
        <Box style={{ paddingTop: '38px' }}>
            <Typography className={classes.phoneTitle} variant="h3">Para continuar solo nos falta un dato.</Typography>
            <Typography>Compártenos tu teléfono para contactarte en caso que requieras ayuda.</Typography>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField id="outlined-basic" label="Número de teléfono" className={classes.phoneInput} 
                        variant="outlined" value={phone} onChange={handlePhoneInput} helperText={phoneErrorMsg}
                        placeholder="Número a 10 dígitos"
                        InputProps={{
                            classes: {
                                notchedOutline: (phoneErrorMsg === "") ? null : classes.phoneInputError
                            }
                        }}
                        inputProps={{ maxLength: 10 }}
                    />
                </Grid>
            </Grid>
            <Box className="mt-4">
                <Button variant="outlined" color="secondary" className={classes.btn + " mr-2"} onClick={() => history.replace('/')}>Ir a inicio</Button>
                <Button disabled={phone.length < 1 }variant="contained" color="primary" className={classes.btn} onClick={handleVerifyPhone}>
                    Continuar
                </Button>
            </Box>
        </Box>
    )
}

export default CheckoutPhoneError
