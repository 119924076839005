import React from 'react';
import PropTypes from 'prop-types';

// core components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// style
import useStyles from '../../../../../assets/styles/jss/pages/events/allEventStyle';

// components
import PrincipalEventCard from '../../components/PrincipalEventCard/PrincipalEventCard';
import EventCard from '../../components/EventCard/EventCard';

const AllEvents = ({eventList, onClickInterestingEvent, displayTitle, showButtonInterest, isResponsive}) => {
    const classes = useStyles({isResponsive});

    return (
    <Box className={classes.allEventRoot}>
        {
            displayTitle &&
            <Box className={classes.title}>
                <Typography variant='h3'>Todos los eventos</Typography>
            </Box>
        }
        <Grid container spacing={isResponsive ? 1 : 2} direction="row">
            {
                eventList.map((item, index) => (<Grid item xs={12} md={index === 0? 8 : 4} key={item.id}>
                        {
                            !index && !isResponsive
                            ? <PrincipalEventCard event={item} onClickInterestingEvent={onClickInterestingEvent} showButtonInterest={showButtonInterest}/> 
                            : <EventCard event={item} onClickInterestingEvent={onClickInterestingEvent} showButtonInterest={showButtonInterest} size={isResponsive ? "small" : "large"}/>
                        }
                    </Grid>)
                )
            }
        </Grid>
    </Box>)
};

AllEvents.defaultProps = {
    displayTitle: true,
    showButtonInterest: true,
    isResponsive: false,
};

AllEvents.propTypes = {
    eventList: PropTypes.array.isRequired,
    onClickInterestingEvent: PropTypes.func,
    isResponsive: PropTypes.bool,
};

export default AllEvents;