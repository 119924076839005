import React, {useState, useEffect} from "react";

// share component
import NewBotton from '../../shared/components/Button/NewButton'
// @material-ui/core
import {Card, CardContent, Box} from '@material-ui/core'; 
// @material-ui/tabs
import Tabs from '../../shared/components/Tabs/Tabs';
import RoleTablePag from "./RoleTablePag/RoleTablePag"; 

// service
import * as UserService from '../../services/UserService'

import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';
 
const RoleManagement = (props) => {
    const [canReadWrite, setCanReadWrite] = useState(false);
    const tabs = ['Todos'];

    useEffect(()=>{
        hanldeCanReadWrite();
    },[])

    const hanldeCanReadWrite = () => {
        const canReadWrite = UserService.canReadWrite(props.pageId);
        setCanReadWrite(canReadWrite);
    }
    const hanldeClickNewRole = () => props.history.push(`/roles/crear`);
    const items = [
        { to: '/Home', label: 'Administrador' ,title:''},
        { to:'/permisos/management', label: 'Roles' , title:''}
       
      ];
    return (
        <>
        <Breadcrumb items={items} />
        <Box>
            <Tabs
                activeTab={0}
                tabs={tabs}
                actions={(canReadWrite) && <NewBotton onClick={hanldeClickNewRole} label='Nuevo Rol'/>}
            >
                <Card>
                    <CardContent>
                        <RoleTablePag canReadWrite={canReadWrite}/> 
                    </CardContent>
                </Card>
            </Tabs>
        </Box>
        </>
        )
}

export default RoleManagement;