import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    containerHeader:{
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start'
        }
    },
    loaderMain:{
        minHeight: "calc(150vh - 450px)" 
    },
    resultadosContainer:{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 24,
        [theme.breakpoints.down('xs')]: {
            marginTop: 16,
        }
    },
    marginsTittles:{
        marginTop: 56,
        marginBottom: 48,
        [theme.breakpoints.down('xs')]: {
            margin: '32px 0px',
        }
    },
    buscadorContainer:{
        height: 48,
        [theme.breakpoints.down('xs')]: {
            height: 40,
        }
    },
    buscadorStyle:{
        "& .MuiInputBase-root": {
            background: theme.palette.colors.fillStroke[400], 
            border: 'none !important',
            height: 48,
            [theme.breakpoints.down('xs')]: {
                height: 40,
            }
        } 
    },
    rootGrid:{
        height: "144px",
        position: "relative", 
        width: "100vw", 
        left: "50%", 
        marginLeft: "-50vw",
        backgroundSize: "cover", 
        backgroundPosition: "center",
    }
}));