import React from 'react'

import { Grid } from '@material-ui/core'

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import { CustomSlider } from '../CustomSlider/CustomSlider';

const EditSlider = (props) => {

    const { pictureZoom, handleSlider, pictureImg, saveDisabled } = props;

    return (
        <Grid item xs={8} className="my-1 mt-3">
            <Grid container spacing={2} alignContent='center'>
                <Grid item xs={1}>
                    <Grid container>
                        <ZoomOutIcon fontSize='medium'/>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Grid container alignContent='center'>
                        <CustomSlider
                            aria-label="raceSlider"
                            value={pictureZoom}
                            min={1}
                            max={10}
                            step={0.1}
                            onChange={handleSlider}
                            disabled={!pictureImg && saveDisabled}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Grid container>
                        <ZoomInIcon fontSize='medium'/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EditSlider
