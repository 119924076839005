import React from 'react';
import clsx from 'clsx';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// components
import OrderDetail from './OrderDetail/OrderDetail';
import PaymentActionCard from '../components/PaymentActionCard';
import SupportCard from '../components/SupportCard';

// assets
import useStyles from '../../../../assets/styles/jss/pages/market/PaymentConfirmation';

const PaymentConfirmationBody = ({ order, orderDetails, orderPayments, personInfo }) => {
    const classes = useStyles();

    return <Box>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <OrderDetail
                    order={order}
                    orderDetails={orderDetails}
                    orderPayments={orderPayments}
                    personInfo={personInfo}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box className={clsx(classes.responsiveCard)} mb="24px">
                    <PaymentActionCard
                        order={order}
                        orderDetails={orderDetails}
                        personInfo={personInfo} />
                </Box>
                <Box className={clsx(classes.responsiveCard)}>
                    <SupportCard />
                </Box>
            </Grid>
        </Grid>
    </Box>;
};

export default PaymentConfirmationBody;