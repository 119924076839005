import React, { useState } from 'react'

// @material-ui/core
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Personalized Icons
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

// component-styles
import './dynamicTableStyles.css'

const DynamicTable = ({ key, tableHeaders, tableRows, tableTitle = null, colSpan, style }) => {

    const [titleWidth, setTitleWidth] = useState(colSpan);
    (colSpan <= 2) && setTitleWidth(tableHeaders);

    return (
        <>
            {/* <div className='mt-3'> */}
            <Table key={key} style={style} className='mt-3'>
                <TableHead>
                    {
                        tableTitle !== null &&
                        <TableRow className='table-title'>
                            <TableCell colSpan={titleWidth} align="justify">
                                <Typography variant='subtitle1'>{tableTitle}</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    <TableRow>
                        {
                            tableHeaders.map((header, index) =>
                                <TableCell key={index} className='table-headers' align="center">
                                    <Typography variant='body2'>{header}</Typography>
                                </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows.map((tableRow, key) => (
                        <TableRow key={key}>
                            {
                                Object.values(tableRow).map((tableCell, k) =>
                                    <TableCell key={`${tableCell}_${k}`} className='table-cells' align="center" component="th" scope="row">
                                        {
                                            <Typography variant="caption">
                                                {
                                                    (tableCell === "Presencial") &&
                                                    <HomeWorkIcon fontSize='inherit' className='mr-1' />
                                                }
                                                {
                                                    (tableCell === "Online") &&
                                                    <OndemandVideoIcon fontSize='inherit' className='mr-1' />
                                                }
                                                {typeof tableCell === 'string' ? tableCell.toUpperCase() : tableCell}
                                            </Typography>
                                        }
                                    </TableCell>)
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default DynamicTable;
