import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// core components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

// style
import useStyles from '../../../../../assets/styles/jss/pages/events/eventFilterStyle';

// shared - components 
import SimpleSearch from '../../../../../shared/components/SimpleSearch/SimpleSearch';
import Dropdown from '../../../../../shared/components/Dropdown/Dropdown';

// @material-ui/icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';

const EventFilter = ({onChangeFilter, categoryList, modalityList, onChangeMyEventPage}) => {
    const classes = useStyles();

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [modalityOptions, setModalityOptions] = useState([]);
    const [myEventPageSelected, setMyEventPageSelected] = useState(false);

    useEffect(() => {
        setCategoryOptions(categoryList);
    }, [categoryList]);

    useEffect(() => {
        setModalityOptions(modalityList);
    }, [modalityList]);

    const handleChange = (type) => (event) => onChangeFilter(event, type);
    const handleClickPage = (event) => { 
        const value = !myEventPageSelected;
        setMyEventPageSelected(value);
        onChangeMyEventPage(value);
        if (value) {
            eventTracker('s_tus_eventos', {}, ['mixpanel']);
        }
    };

    return (
        <>
            <Grid container spacing={2} className={classes.boxContainer}>
                <Grid item xs={12} md>
                    <Box height="34px">
                        <SimpleSearch onChange={handleChange('text')}/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2} className={classes.gridItem}>
                    <Dropdown 
                        options={modalityOptions} 
                        onClickOption={handleChange('modality')}
                        text="Modalidad"
                        endIcon={<ArrowDropDownIcon />}
                        variant='contained'
                        size="small"
                        style={{width: "100%"}}
                        className="bg-fill-stroke-300"
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.gridItem}>
                    <Button 
                        variant='contained'
                        className={myEventPageSelected ? classes.myEventButtonClicked : classes.myEventButton}
                        size="small"
                        onClick={handleClickPage}
                    > 
                        Tus eventos
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

EventFilter.propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
    categoryList: PropTypes.array.isRequired,
    modalityList: PropTypes.array.isRequired,
    onChangeMyEventPage: PropTypes.func.isRequired,
};

export default EventFilter;