import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

// @material-ui
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

// shared
import { IMAGE_DEFAULT_PLACEHOLDER } from '../../../../shared/constants/source-images';

// assets
import useStyles from '../../../../assets/styles/jss/pages/cart/cartStyle';

const CartItem = ({ detail, onItemDeleteClick }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return <Box>
        <ListItem disableGutters className={classes.cartListItem}>
            <Grid container spacing={1}>
                <Grid item>
                    <Box height={64} width={64}>
                        <img alt={detail.product_name} src={detail.product_image_url || IMAGE_DEFAULT_PLACEHOLDER} height={64} width={64} className={classes.cartProductImage}></img>
                    </Box>
                </Grid>
                <Grid item alignContent='center' className={clsx({ [classes.cartListItemContent]: isMobile })}>
                    <Typography variant='h5' className={clsx("font-weight-700 mb-1", classes.cartDescription)}>{detail.product_name}</Typography>
                    <Typography variant='body2' className={clsx("text-dark-black-200", classes.cartDescription)}>{detail.product_description}</Typography>
                    {isMobile && detail.total_price > 0 && (
                        <Box className="mt-2">
                            <Typography variant='body1' className="text-orange-300 font-weight-600" component='span'>
                                <CurrencyFormat value={detail.total_price} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" />
                            </Typography>
                        </Box>

                    )}
                </Grid>
                <Grid item alignContent='center' xs>
                    <Grid container alignItems='center' justifyContent='flex-end'>
                        {!isMobile && detail.total_price > 0 && (
                            <Grid item>
                                <Typography variant='body1' className="text-orange-300 font-weight-600" component='span'>
                                    <CurrencyFormat value={detail.total_price} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" />
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <IconButton color="inherit" onClick={() => onItemDeleteClick(detail)}>
                                <Icon className='ri-delete-bin-line text-dark-black-200 font-size-16' style={{ lineHeight: '15px' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
        <Divider className='bg-fill-stroke-200' />
    </Box>
};

export default CartItem;