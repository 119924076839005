import * as muiColors from '@material-ui/core/colors';
import colors from './common/colors'

const white = '#FFFFFF';
const black = '#000000';
const transparent = 'transparent';
const blue = '#00C1DE';
const grey = muiColors.grey[400];

export default {
  black,
  white,
  transparent,
  dark: {
    contrastText: white,
    dark: '#222222',
    main: '#383737',
    light: '#4e4e4e'
  },
  primary: {
    contrastText: white,
    dark: colors.violet[500],
    main: colors.violet[300],
    light: colors.violet[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.orange[500],
    main: colors.orange[300],
    light: colors.orange[100]
  },
  success: {
    contrastText: white,
    dark: colors.success[500],
    main: colors.success[300],
    light: colors.success[100]
  },
  info: {
    contrastText: white,
    dark: muiColors.blue[900],
    main: blue,
    light: muiColors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.warning[500],
    main: colors.warning[300],
    light: colors.warning[100]
  },
  error: {
    contrastText: white,
    dark: colors.error[500],
    main: colors.error[300],
    light: colors.error[100]
  },
  text: {
    primary: white,
    secondary: colors.fillStroke[100],
  },
  icon: {
    primary: blue,
    secondary: '#808080',
    link: muiColors.blue[600],
    disabled: muiColors.grey[100]
  },
  grey: {
    contrastText: white,
    dark: colors.gray[500],
    main: colors.gray[300],
    light: colors.gray[100],
    gluon: colors.gray[100],
    default: grey,
  },
  background: {
    default: black,
    paper: white,
    primary: muiColors.blue[700]
  },
  border: {
    input: '#c1c5c9',
    inputHover: blue
  },
  divider: muiColors.grey[200],
  colors: {
    ...colors,
    black,
    white,
  },
};
