import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// @material-ui/core
import { makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// shared
import Loader from './../Loader/LoaderContent';

// services
import { MembershipService } from '@sdk-point/talisis';

const useStyles = makeStyles((theme) => ({
    list: {
        marginTop: "-8px"
    },
    listItemIcon: {
        minWidth: '20px',
        "& > .MuiTypography-root": {
            color: props => props.iconColor === "inherit" ? theme.palette.colors.darkBlack[200] : ""
        }
    },
    listItemText: {
        lineHeight: '18px',
        "& > .MuiTypography-root": {
            color: props => props.textColor === "inherit" ? theme.palette.colors.darkBlack[100] : ""
        }
    },
    title: {
        color: theme.palette.colors.blue[300],
        marginBottom: "24px",
    },
    iconAlignFlexStart: {
        alignSelf: 'flex-start',
        position: 'relative',
        top: '4px',
    },
    logoContainer: {
        margin: '30px 0px 30px -20px',
        justifyContent: 'center',
    }
}));

const MembershipDetails = (props) => {
    const { id, iconAlignFlexStart, page, iconColor, textColor, textBold, title } = props;
    const classes = useStyles({ iconColor, textColor });
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

    const getMembershipDetails = async () => {
        try {
            setLoading(true);
            const response = await membershipService.getMembershipDetails({ context: page, membership: id });
            setData(response);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getMembershipDetails();
    }, []);

    return (
        <Box>
            {title && <Typography variant="body2" className={clsx(classes.title, "semi-bold")}>&nbsp;{title}</Typography>}
            <Loader loading={loading} transparent="1">
                <List dense className={clsx('details', classes.list)} disablePadding>
                    {
                        data.map((item, index) => {
                            return (
                                <ListItem key={index} disableGutters>
                                    <ListItemIcon className={clsx({
                                        [classes.listItemIcon]: true,
                                        [classes.iconAlignFlexStart]: iconAlignFlexStart
                                    })}>
                                        <Typography variant="body2" color={iconColor}><i className="ri-check-fill"></i></Typography>
                                    </ListItemIcon>
                                    <ListItemText className={classes.listItemText} primary={
                                        <Typography className={clsx({ "semi-bold": textBold })} variant="body2" color={textColor} dangerouslySetInnerHTML={{ __html: item }}></Typography>
                                    } />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Loader>
        </Box>
    );
}

MembershipDetails.defaultProps = {
    iconAlignFlexStart: true,
    iconColor: "inherit",
    page: "landing",
    textBold: false,
    textColor: "inherit",
};

MembershipDetails.propTypes = {
    id: PropTypes.oneOf([
        "freemium",
        "lite",
        "professional",
        "premium"
    ]).isRequired,
    iconAlignFlexStart: PropTypes.bool,
    title: PropTypes.string,
    page: PropTypes.oneOf([
        "landing",
        "checkout",
        "program",
        "upselling"
    ]).isRequired,
    iconColor: PropTypes.string,
    textColor: PropTypes.string,
    textBold: PropTypes.bool
};

export default MembershipDetails;