import React from 'react';
import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import NextPaymentDate from './NextPaymentDate';

// utils
import { PRODUCT_TYPE, ORDER_STATUS, PAYMENT_METHOD } from '../../../../../../shared/constants/orders';

const getMonthlyPayments = (order) => {
    let monthlyPayments = 1;
    if (order?.payment?.payment_method === PAYMENT_METHOD.CARD) {
        const charge = order?.payment?.payment_reference?.conekta?.charge;
        if (charge && charge?.monthly_installments > 0) {
            monthlyPayments = charge?.monthly_installments;
        }
    }
    return monthlyPayments;
};

const OrderSummary = ({ order, product, membership }) => {
    const monthlyPayments = getMonthlyPayments(order);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='body1' color='text-white' className='semi-bold'>Sub-total:</Typography>
                    <Typography variant='h5' className="text-orange-300">
                        <CurrencyFormat value={order?.subtotal} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' />
                    </Typography>
                </Box>
            </Grid>
            {(order?.discount > 0) &&
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant='body1' color='text-white'>Cupón:</Typography>
                        <Box display="flex">
                            <Typography variant='caption' className="text-success-300 mr-1">{order?.coupon}</Typography>
                            <Typography variant='subtitle2' className="text-gray-500">
                                <CurrencyFormat value={order?.discount} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="-$" suffix=' MXN' />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            }
            {(order?.total !== (order?.subtotal - order?.discount)) &&
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant='body1' color='text-white'>Bonificación por suscripción anterior:</Typography>
                        <Box display="flex">
                            <Typography variant='subtitle2' className="text-gray-500">
                                <CurrencyFormat value={((order?.subtotal - order?.total) - order?.discount)} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="-$" suffix=' MXN' />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            }
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='h5' color='text-white'>Total:</Typography>
                    <Typography variant='h3' className="text-orange-300">
                        <CurrencyFormat value={order?.total < 0 ? 0 : order?.total} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' />
                    </Typography>
                </Box>
            </Grid>
            {(monthlyPayments > 1) &&
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant='body1' color='text-white'>Pago por mes:</Typography>
                        <Box display="flex">
                            <Typography variant='subtitle2' className="text-gray-500">
                                <CurrencyFormat value={(order?.total / monthlyPayments)} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="$" suffix={` MXN x ${monthlyPayments}`} />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            }
            {(product?.product_type_id !== PRODUCT_TYPE.COURSE) && (order?.estatus_id === ORDER_STATUS.SUCCESS) &&
                <Grid item xs={12}>
                    <NextPaymentDate product={product} membership={membership} />
                </Grid>
            }
        </Grid>
    )
};

export default OrderSummary;