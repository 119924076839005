export const getCompanyIdByBrand = (brand) => {
    let companyId = '';
    if (brand === 'u-erre') { companyId = 'UERRE'; }
    else if (brand === 'unid') { companyId = 'UNID'; }
    else if (brand === 'harmonhall') { companyId = 'HH'; }

    return companyId;
};

export const getCompanyNameByBrand = (brand) => {
    let companyName = '';
    if (brand === 'u-erre') { companyName = 'U-ERRE'; }
    else if (brand === 'unid') { companyName = 'UNID'; }
    else if (brand === 'harmonhall') { companyName = 'HARMON HALL'; }

    return companyName;
};