export const splitWordByLimitLength = (data, limitLength, additionalData = "") => {
    const words = data.split(' ');
    const wordsLength = words.length;
    let newData = words[0] ?? "";

    for (let i = 1; i < wordsLength; i++) {
        const newDataTemp = `${newData} ${words[i]}`;
        
        if (newDataTemp.length > limitLength) {
            newData += additionalData;
            break;
        } else newData = newDataTemp;
    }

    return newData;
};