import React from 'react'

import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles';

const MyProfileCard = (props) => {

    const theme = useTheme();

    const { cardSize = 12, cardTitle, caption, btnDisabled, btnFunction, btnText, component, loading, componentHeight, btnVisible = true } = props;

    return (
        <Grid item md={cardSize} xs={12}>
            <Card style={{ background: "#1D1E23", border: "1px solid #2C3236"}}>
                <CardContent>
                    <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={8}>
                            <Typography variant="h6" color='primary' align='left'>
                                { cardTitle }
                            </Typography>
                            <Typography style={{ fontSize: '0.65em'}} align='left'>
                                { caption }
                            </Typography>
                        </Grid>
                        {
                            btnVisible && 
                            <Grid item xs={4}>
                                <Grid container justifyContent='flex-end'>
                                    <Button
                                        color={(!loading) ? 'primary' : 'secondary'}
                                        variant="contained"
                                        onClick={btnFunction}
                                        size="large"
                                        disabled={loading || btnDisabled}
                                    >
                                        <Typography variant='body2'>
                                            { btnText }
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid container 
                        spacing={1}
                        className='mt-2'
                        alignItems='center'
                        style={{ height: componentHeight, borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}
                    >
                        <Grid item xs={12}>
                            <Grid container justifyContent='flex-end' wrap="wrap">
                                { component }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default MyProfileCard
