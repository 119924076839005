import React from 'react';

import Box from '@material-ui/core/Box';
import { Icon, Button, Hidden } from '@material-ui/core';

import LogoSource from '../../../../design-system/components/LogoSource/LogoSource';
import { SOURCES } from '../../../../design-system/constants/sources';

import useStyles from '../../../../assets/styles/jss/layout/headerOusStyle'
import clsx from 'clsx';

const HeaderOUS = ({isLogged, onClickLogin}) => {

    const classes = useStyles();

    const [brandsList, setBrandList] = React.useState([
        {id: SOURCES.ADVENIO, hoverColor: 'simpleWhite', color: 'grey', style:{height: '9.5px'}, link: 'https://www.advenio.mx/'},
        {id: SOURCES.IESALUD, hoverColor: 'simpleWhite', color: 'grey', style:{height: 10}, link: 'https://iesalud.edu.mx/'},
        {id: SOURCES.UERRE, hoverColor: 'white', color: 'grey', style:{height: 9}, link: 'https://www.u-erre.mx/'},
        {id: SOURCES.UNID, hoverColor: 'white', color: 'grey', style:{height: 10}, link: 'https://www.unid.edu.mx/'},
        {id: SOURCES.HH, hoverColor: 'white', color: 'grey', style:{height: 11}, link: 'https://www.harmonhall.com/'},
    ])

    const onMouseChange = (index, color) => (e) => {
        setBrandList(prev => {
            const list = [...prev];
            list[index].color = color;

            return list;
        })
    }

    if(isLogged){
        return null
    }

    return (
        <Box className={classes.root} id="header-ous-root">
            <Box display='flex'>
                {/* <Button disableRipple className={clsx(classes.button, classes[SOURCES.TALISIS])}>
                    <LogoSource sourceId={SOURCES.TALISIS} color='white' style={{height: 12}} disabledAutoRes/>
                </Button> */}
                {
                    brandsList.map((ouObj, index) =>                        
                        <Button key={`button-ou-${index}`}
                            href={ouObj.link} target='_blanck'
                            disableRipple className={clsx(classes[ouObj.id], classes.button)} 
                            onMouseEnter={onMouseChange(index, ouObj.hoverColor)} onMouseLeave={onMouseChange(index, 'grey')}
                        >
                            <LogoSource sourceId={ouObj.id} color={ouObj.color} disabledAutoRes style={ouObj.color !== 'grey' ? ouObj.style : null}/>
                        </Button>
                    )
                }
            </Box>
            <Hidden smDown>
                <Box display='flex' className={classes.buttonLoginContainer}>
                    <Button
                        disableRipple
                        className={classes.buttonLogin}
                        startIcon={<Icon className='ri-account-circle-fill' style={{fontSize: '24px !important'}}/>}
                        onClick={onClickLogin}
                    >
                        Iniciar sesión
                    </Button>
                </Box>
            </Hidden>
        </Box>
    );
};

export default HeaderOUS;