import React from 'react';
import moment from 'moment'
import 'moment/locale/es';

// @material-ui
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

// shared
import Table from '../../../../../shared/components/Table/Table';

const locationFormat = (row, value, index) => (
    <div>
        <Chip color="default" size="small" label={row.city_name} />&nbsp;
        <Chip color="primary" size="small" label={row.location_name} />
    </div>
);

const dateTimeFormat = (row, value, index) => {
    const start_date = value ? moment.utc(row.start_date).locale('es').format('DD/MM/YYYY') : '';
    const end_date = value ? moment.utc(row.end_date).locale('es').format('DD/MM/YYYY') : '';

    return <div>{`de ${start_date} a ${end_date}`}</div>
};

const authorizedFormat = (row, value, index) => (
    <div>
        {(value && value === true) ?
            <Tooltip title="Authorized"><CheckCircleIcon color="primary" /></Tooltip> :
            <Tooltip title="Not authorized"><CancelIcon color="disabled" /></Tooltip>
        }
    </div>
);

const columns = [
    { name: 'location_name', label: 'Ubicación', minWidth: 100, format: locationFormat },
    { name: 'start_date', label: 'Vigencia', minWidth: 100, format: dateTimeFormat },
    { name: 'authorized', label: 'Autorizado', minWidth: 100, format: authorizedFormat },
];

const PersonRegisters = ({ data }) => {
    return (
        <React.Fragment>
            <Table
                columns={columns}
                rows={data}
            />
            {!(data && data.length > 0) ? <Typography variant="h5" className="p-3" style={{ textAlign: "center" }}>No se encontraron registros.</Typography> : null}
        </React.Fragment>
    );
}

export default PersonRegisters;