import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
//Material-ui
import { Grid, Link, Typography, Card, CardContent, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Shared
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';
import CouponChecker from '../../../../shared/components/CouponChecker/CouponChecker';
import { setCouponData, setOrderConcepts, setOrderPendingDiscount, setOrderQuantities, setPurchaseOrder } from '../../../../redux/actions/legacyCheckoutActions.js';
import { getCheckoutCookie } from '../../../../shared/utils/checkoutSession-legacy.js';
//SDK-POINT
import { MarketPlaceService } from '@sdk-point/talisis';
import clsx from 'clsx';
import { getOrderQuantityOff, updateOrderQuantitiesDiscount } from '../../../../shared/utils/checkoutHelpers-legacy.js';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
const useStyles = makeStyles((theme) => ({
    backIcon: {
        fontSize: '24px',
        color: theme.palette.colors.gray[100]
    },
    InfoIcon:{
        width: '16px',
        height: '16px',
        color: theme.palette.colors.orange[300],
        marginRight: "4px",
        [theme.breakpoints.down('sm')]:{
            marginRight: "8px"
        }
    }
    ,
    card: {
        padding: "16px 24px",
    },
}));

const CheckoutCoupons = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {activeStep} = getCheckoutCookie();
    const STEP_MEMBERSHIP_PAY = 3;
    const { order, orderQuantity: quantityReducer, couponData, membershipOrderFeePlans} = useSelector((state) => state.legacyCheckoutReducer);
    const [validCoupon, setValidCoupon] = useState(false);
    const [alertCupon, setAlertCupon] = useState(false);
    const [txtCupon, setTxtCupon] = useState('');
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [typeMessage, setTypeMessage] = useState('') // warning, success, error
        
    const handleCheckCupon = async () => {
        if(typeMessage==='warning'){
            setTypeMessage('')
            setTxtCupon('')
            setValidCoupon(false);
            setAlertCupon(false);
        }else{
            try {
                setLoadingCoupon(true)
                const response = await marketService.removeCouponOrder('txtCupon', {
                    order
                });

                if(response.status === 1) {
                    setTypeMessage('')
                    setTxtCupon('')
                    setValidCoupon(false);
                    setAlertCupon(false);
                    dispatch(setPurchaseOrder(response.order))
                    dispatch(setOrderConcepts([...response.order.conceptos]))
                    dispatch(setCouponData({}))
                    dispatch(setOrderPendingDiscount(response.order.pending_discount))
                    const quantityOff = getOrderQuantityOff( membershipOrderFeePlans?.membershipPlans, membershipOrderFeePlans?.selectedPlan );
                    const orderQuantities = updateOrderQuantitiesDiscount(response?.order?.pending_discount, quantityOff, response?.order?.discount, quantityReducer);
                    dispatch(setOrderQuantities({
                        ...orderQuantities,
                        order_subtotal: response.order.subtotal,
                        order_discount: response.order.discount,
                        order_total: response.order.total,
                    }));
                }
            } catch (error) {
                console.log('responseRemoveOrderCoupon Error', error)
            } finally {
                setLoadingCoupon(false);
            }
      }
    } 

    const isPaymentStep = !!(activeStep === STEP_MEMBERSHIP_PAY);

    return (
        <Grid>
            <Card style={{ background: "#1D1E23", border: "1px solid #2C3236"}}>
                <CardContent className={classes.card}>
                    <Grid container >
                        <Grid item xs={ isPaymentStep ? 11 : 12 }>
                            <Typography variant="body1" className="text-white semi-bold">
                             Cupón de descuento
                            </Typography>
                            { !isPaymentStep && <Box style={{ marginTop: 10 }}>
                                <Box width="100%" display="flex">
                                    <Box><i className={clsx(classes.InfoIcon, 'ri-information-line')} /></Box>
                                    <Typography variant='body2' className='text-gray-100'>Ingresa el cupón al seleccionar tu método de pago.</Typography>
                                </Box>
                            </Box>
                            }
                        </Grid>
                        { isPaymentStep &&
                            <Grid item xs={1} >
                                {typeMessage !== '' && <Link onClick={handleCheckCupon}>
                                    {!loadingCoupon ? <i className={clsx(classes.backIcon, 'ri-delete-bin-6-line')} /> : <LoaderContent loading={loadingCoupon}></LoaderContent>}
                                </Link>
                                }
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12}>
                         <CouponChecker validCoupon={validCoupon} 
                                    setValidCoupon = {setValidCoupon}
                                    alertCupon={alertCupon} 
                                    setAlertCupon={setAlertCupon}
                                    txtCupon={txtCupon} 
                                    setTxtCupon={setTxtCupon}
                                    loadingCoupon={loadingCoupon} 
                                    setLoadingCoupon={setLoadingCoupon}
                                    typeMessage={typeMessage} 
                                    setTypeMessage={setTypeMessage}
                                    couponData={couponData}
                                    disabledCoupon={activeStep !== STEP_MEMBERSHIP_PAY}/>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default CheckoutCoupons
