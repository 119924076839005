import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core';

// shared
import Loader from '../../../../shared/components/Loader/LoaderContent';

// core
import RegisterQuestion from './RegisterQuestion/RegisterQuestion';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    buttons: {
        marginLeft: "auto"
    },
    buttonBack: {
        marginRight: theme.spacing(1)
    }
}));

const RegisterQuestions = ({ loading, tasks, questions, form, validations, onChange, onCancel, onRequest }) => {
    const classes = useStyles();
    const [index, setIndex] = React.useState(0);
    const [errors, setErrors] = React.useState({});
    const isFirst = index === 0;
    const isLast = index === (questions.length - 1);
    const data = questions[index];
    const dataValues = form.questions.find(q => q.id === data.id)?.values;
    const error = errors[data.name];

    const validate = async () => {
        const _errors = {};

        if (data.is_required) {
            if (!(dataValues && dataValues.length > 0)) {
                _errors[data.name] = ({
                    severity: "error",
                    message: "Por favor responde la pregunta"
                });
            }
        }

        if (!_errors[data.name]) {
            if (validations && validations.length > 0) {
                for (const v of validations) {
                    if (v.id === data.name) {
                        if (!(await v.validate())) {
                            _errors[data.name] = ({
                                severity: v.severity,
                                message: v.message
                            });
                            break;
                        }
                    }
                };
            }
        }

        setErrors(_errors);

        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleBack = () => {
        if (!isFirst) {
            setIndex(i => i - 1);
        }
    };

    const handleNext = async () => {
        if (await validate()) {
            if (!isLast) {
                setIndex(i => i + 1);
            } else {
                onRequest();
            }
        }
    };

    const handleChange = (values) => {
        let _questions = [...form.questions];
        let questionIndex = _questions.findIndex(q => q.id === data.id);

        if (questionIndex === -1) {
            _questions.push({
                id: data.id,
                name: data.name,
                type_name: data.type_name,
                is_required: data.is_required
            });
        }

        _questions = _questions.map(q => {
            if (q.id === data.id) {
                q.values = [...values];
            }
            return q;
        });

        onChange("questions", _questions);
    };

    const questionProps = {};
    questionProps.key = `question-${index}`;
    questionProps.data = data;
    questionProps.index = index;
    questionProps.values = dataValues;
    questionProps.loading = loading;
    questionProps.tasks = tasks;
    questionProps.onChange = handleChange;

    return <Loader loading={loading}>
        <Card>
            <CardContent className="p-4">
                <RegisterQuestion {...questionProps} />
                {error ? <Alert className="mb-3" severity={error.severity}>{error.message}</Alert> : null}
                <Hidden smDown implementation="css">
                    <Box display="flex">
                        <Button color="secondary" variant="outlined" onClick={onCancel}>Cancelar</Button>
                        <div className={classes.buttons}>
                            <Button className={classes.buttonBack} color="secondary" variant="contained" disabled={isFirst} onClick={handleBack}>Anterior</Button>
                            {isLast ?
                                <Button color="primary" variant="contained" onClick={handleNext}>Enviar</Button> :
                                <Button color="primary" variant="contained" onClick={handleNext} disabled={!(data.options && data.options.length > 0)}>Siguiente</Button>
                            }
                        </div>
                    </Box>
                </Hidden>
                <Hidden mdUp implementation="css">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {isLast ?
                                <Button fullWidth color="primary" variant="contained" onClick={handleNext}>Enviar</Button> :
                                <Button fullWidth color="primary" variant="contained" onClick={handleNext} disabled={!(data.options && data.options.length > 0)}>Siguiente</Button>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth color="secondary" variant="contained" disabled={isFirst} onClick={handleBack}>Anterior</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth color="secondary" variant="outlined" onClick={onCancel}>Cancelar</Button>
                        </Grid>
                    </Grid>
                </Hidden>
            </CardContent>
        </Card>
    </Loader >
}

export default RegisterQuestions;