export const creditCardNumber = (value) => {
    if (value) {
        value = value.replace(/[^0-9]/g, '');
        if (value) {
            value = value.match(/[0-9]{1,4}/g)?.join(' ');
        }
        else {
            value = '';
        }
    }

    return value;
};

export const securityCardNumber = (value) => {
    if (value) {
        value = value.replace(/[^0-9]/g, '');
        if (value) {
            value = value.replaceAll(/[^\d]/g, '')
        }
        else {
            value = '';
        }
    }

    return value;
};

export const creditCardExp = (value) => {
    if (value) {
        value = value.replace(/[^0-9]/g, '');
        if (value) {
            value = value.match(/[0-9]{1,2}/g).join('/');
        }
        else {
            value = '';
        }
    }

    return value;
};

export const phoneNumber = (value) => {
    if (value) {
        value = value.replace(/[^0-9]/g, '');
        if (value) {
            value = value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2 $3");
        }
        else {
            value = '';
        }
    }

    return value;
};