export default {
  root:{
    border: 0,
    marginBottom: 4,
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '4px 0',
    },
  }
};
