import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

// Shared - Components
import Select from '../../../../../design-system/components/Form/Select/Select';

// Shared - Constants
import {PHYSICAL_REVIEW_STATUS} from '../../../../../shared/constants/request-status';

// icons
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

// style
import useStyles from '../../../../../assets/styles/jss/pages/requests/physicalDocumentReviewStyle';

const statusPhysicalFile = [
    {id: PHYSICAL_REVIEW_STATUS.APPROVED, label: 'Recibido correctamente'},
    {id: PHYSICAL_REVIEW_STATUS.REJECTED, label: 'Regresar'},
];

const PhysicalDocumentReview = ({physicalFiles, onChangePhysicalFileStatus, shipment}) => {
    const classes = useStyles();

    const handleChangePhysicalFileStatus = (file) => {
        return (event) => {
            onChangePhysicalFileStatus(event.target.value, file);
        };
    };



    return (
        <>
            <Grid container direction='column' spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">Consulta que los siguientes documentos físicos sean recibidos y estén correctos y luego asígnales un estatus.</Typography>
                </Grid>

                {
                    physicalFiles.map(physicalFile => {
                        return (
                            <Grid key={physicalFile.name} container item xs={12} className={classes.borderPhysicalDocumentItem} spacing={1}>
                                <Grid container item xs={1} justifyContent="center" alignItems="center">
                                    <LocalShippingIcon className={classes.documentIcon} color="primary"/>
                                </Grid>

                                <Grid item xs={7}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Box sx={{display: 'inline-block'}}>
                                                <Typography variant="h5" className={classes.inlineBlock}>{physicalFile.label} &nbsp;
                                                    <Link href={ physicalFile.path } className='MuiLink-underlineNone'>
                                                        <Typography variant="h6" className={`text-orange-300 ${classes.inlineBlock}`}>Ver documento</Typography>
                                                    </Link>
                                                </Typography>
                                            </Box>
                                            
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" className="text-orange-300">Número de rastreo: {shipment.tracking_number}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={4} justifyContent="center" alignItems="center">
                                    <Select items={statusPhysicalFile} label={'Seleccione'} name={'statusPhysicalFile'} onChange={handleChangePhysicalFileStatus(physicalFile)} value={physicalFile.physicalReviewStatus || ''}/>
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </>
    );
};

PhysicalDocumentReview.propTypes = {
    physicalFiles: PropTypes.array,
    onChangePhysicalFileStatus: PropTypes.func,
    shipment: PropTypes.object,
};
 

export default PhysicalDocumentReview;