import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// shared component
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';
import Toast from '../../../../shared/components/Toast/Toast';

// components
import PaymentDetails from './PaymentDetails/PaymentDetails';
import PendingListItems from './PendingListItems/PendingListItems';

// style
import useStyles from '../../../../assets/styles/jss/pages/account/billing/pendingStyle';

// SDK
import { BannerService } from '@sdk-point/talisis';

// utils
import dateFormat from '../../../../design-system/utils/dateFormat';

const BannerServiceSDK = new BannerService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const PENDING_COMP = 'pending';
const PENDING_DETAILS_COMP = 'paymentDetails';

const Pending2 = ({ user }) => {
    const classes = useStyles();
    const abortData = new AbortController();

    const [userData, setUserData] = useState([]);
    const [status, setStatus] = useState("");
    const [openLoader, setOpenLoader] = useState(false);
    const [refData, setRefData] = useState({});
    const [errors, setErrors] = useState([]);
    const [toastProps, setToastProps] = useState({ message: "" });
    const [matricula, setMatricula] = useState("");


    const [ou, setOu] = useState("");
    const [ouList, setOuList] = useState([]);
    const [activeComponent, setActiveComponent] = useState(PENDING_COMP);
    const [ouPendingData, setOuPendingData] = useState([]);
    const [ouPendingDataSelected, setOuPendingDataSelected] = useState([]);
    const [pendingDataTable, setPendingDataTable] = useState([]);
    const [numberOfAttempts,  setNumberOfAttempts] = useState(0);

    const getAdeudos = async () => {
        try {
            setOpenLoader(true);

            const matricula = user.student_id;

            if (matricula === null) {
                setOpenLoader(false);
                setMatricula(matricula);
                return;
            }

            await BannerServiceSDK.getStudentMultiOU(user, abortData).then(async response => {
                setOpenLoader(false);
                setUserData(response);
                setStatus("success");
                setMatricula(matricula);
                setNumberOfAttempts(0);
            });

        } catch (e) {
            if (numberOfAttempts === 3) {
                setStatus("error");
                setToastProps({ severity: "error", open: true, message: "¡Oops! Ocurrió un error al consultar los adeudos pendientes." });
            } else {
                setNumberOfAttempts(prevData => prevData + 1);
            }

            setOpenLoader(false);
        }
    };

    const changeFormatDate = (date) => {
        const dateSplit = date.split('/');
        return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
    };

    const goToDebtsArray = (callback) => {
        
        let newArray = [];

        ouPendingData.forEach(pendingDataItem => {
            const tempDebts = pendingDataItem.Adeudos_pendientes;
            if(!!tempDebts) {
                tempDebts.forEach(debtItem => {
                    debtItem.Cargos.forEach(debt => {
                        const processData = callback(debtItem.Periodo, debt);
                        if (!!processData) newArray.push(processData);
                    });
                });
            }
        });

        return newArray;
    }
    
    const getOuList = async () => {
        const tempOuList = userData.filter(data => !!data.Informacion_curricular).map(data => {
            const vpdi = data.Informacion_curricular[0].VPDI;
            return { id: vpdi, label: vpdi};
        });

        setOuList(tempOuList);
        
        if(!!tempOuList.length)
            setOu(tempOuList[0].id);
    };

    const getOuPendingData = async () => {
        const tempOuPendingData = userData.filter(data => !!data.Informacion_curricular && data.Informacion_curricular[0].VPDI === ou);
        setOuPendingData(tempOuPendingData);
    };

    const getOuPendingDataSelected = async () => {
        const ouPendingDataLenght = ouPendingData.length;
        let tempOuPendingDataSelected = [];

        for(let i = 0; i < ouPendingDataLenght; i++) {
            const outstandingDebts = ouPendingData[i].Adeudos_pendientes;
            
            if (!!outstandingDebts) {
                const outstandingDebtsLength = outstandingDebts.length;

                for(let j = 0; j < outstandingDebtsLength; j++) {
                    let charges = outstandingDebts[j].Cargos;
                    let period = outstandingDebts[j].Periodo;

                    tempOuPendingDataSelected = [...tempOuPendingDataSelected, ...charges.filter(data => data.aPagar === 'S').map(data => ({id: data.Secuencia}))];
                }
            }
        }

        setOuPendingDataSelected(tempOuPendingDataSelected);
    };

    const getPendingDataTable = async () => {
        const pendingDataLenght = ouPendingData.length;
        let tempPendingDataSelected = [];

        for(let i = 0; i < pendingDataLenght; i++) {
            const outstandingDebts = ouPendingData[i].Adeudos_pendientes;
            
            if (!!outstandingDebts) {
                const outstandingDebtsLength = outstandingDebts.length;

                for(let j = 0; j < outstandingDebtsLength; j++) {
                    let charges = outstandingDebts[j].Cargos;
                    let period = outstandingDebts[j].Periodo;

                    tempPendingDataSelected = [...tempPendingDataSelected, ...charges.map(data => ({
                        id: data.Secuencia,
                        colPeriod: period,
                        colEndDate: dateFormat(changeFormatDate(data.Fecha_vencimiento)),
                        colAmount: data.Monto,
                        date: changeFormatDate(data.Fecha_vencimiento),
                        concept: data.Descripcion,
                        conceptKey: data.Clave,
                        checkboxDisabled: data.Obligatorio === 'S'
                    }))];
                }
            }
        }

        setPendingDataTable(tempPendingDataSelected);
    };

    const handleOuChange = (event) => {
        setOu(event.target.value);
    };

    const handleGoToPendingComponent = async () => {
        try {
            setOpenLoader(true);
            
            const newUser = {...user, ou: ou};
            
            // await BannerServiceSDK.validateRef(userData, user).then(async data => {
            await BannerServiceSDK.validateRef(ouPendingData, newUser).then(async data => {
                const validations = await data;

                if (validations[0].Mensajes.length > 0) {
                    setOpenLoader(false);
                    setErrors([]);

                    validations[0].Validaciones.map(validation => {
                        if (validation.Resultado === "ERROR") {
                            setErrors([...this.state.errors, validation]);
                        }
                    });

                    setToastProps({ severity: "error", open: true, message: "Ops! Ocurrió un error al validar referencias de la información." });
                } else {
                    let user_session = user;
                    if (user_session.is_fake && user_session.fake_session) {
                        user_session = {  
                            person_id: user_session.fake_session.person_id
                        };
                    } else {
                        user_session = {  
                            person_id: user_session.person_id
                        };
                    };

                    // await BannerServiceSDK.getRef(userData, user, user_session).then(references => {
                    await BannerServiceSDK.getRef(ouPendingData, newUser, user_session).then(references => {
                        setRefData(references[0]);
                        setOpenLoader(false);                       
                    });

                    setActiveComponent(PENDING_DETAILS_COMP);
                }
            });
        } catch (e) {
            setOpenLoader(false);
            setToastProps({ severity: "error", open: true, message: "Ops! Ocurrió un error al validar la información." });
        }
    };

    const handleReturnComponent = () => {
        setActiveComponent(PENDING_COMP);
    };

    const handleChangeDataSelected = (row) => {

        const tempOuPendingData = [...ouPendingData];


        tempOuPendingData.forEach((pendingDataItem, pendingDataItemIndex) => {
            const tempDebts = pendingDataItem.Adeudos_pendientes;
            if(!!tempDebts) {
                tempDebts.forEach((debtItem, debtItemIndex) => {
                    const tempNewDebts = debtItem.Cargos.map(debt => {
                        if (debt.Secuencia === row.id) {
                            return {...debt, aPagar: (debt.aPagar === 'S') ? 'N' : 'S'};
                        }

                        return debt;
                    });

                    tempOuPendingData[pendingDataItemIndex].Adeudos_pendientes[debtItemIndex].Cargos = tempNewDebts;
                });
            }
        });

        setOuPendingData(tempOuPendingData);
    };

    const handleChangeAllDataSelected = (isChecked) => {
        const tempOuPendingData = [...ouPendingData];

        tempOuPendingData.forEach((pendingDataItem, pendingDataItemIndex) => {
            const tempDebts = pendingDataItem.Adeudos_pendientes;
            if(!!tempDebts) {
                tempDebts.forEach((debtItem, debtItemIndex) => {
                    const tempNewDebts = debtItem.Cargos.map(debt => {

                        if (!!isChecked) {
                            return {...debt, aPagar: 'S'};
                        }
                        
                        return {...debt, aPagar: (debt.Obligatorio === 'S') ? 'S' : 'N'};
                    });

                    tempOuPendingData[pendingDataItemIndex].Adeudos_pendientes[debtItemIndex].Cargos = tempNewDebts;
                });
            }
        });

        setOuPendingData(tempOuPendingData);
    };

    const handleFinishedToast = () => {
        const tempToastProps = {...toastProps};
        tempToastProps.open = false;
        setToastProps(tempToastProps);
    };

    useEffect(() => {
        getAdeudos();

        return () => {
            abortData.abort();
        }
    }, []);

    useEffect(() => {
        getOuList();
    }, [userData]);

    useEffect(() => {
        getOuPendingData();
    }, [ou]);

    useEffect(() => {
        getPendingDataTable();
        getOuPendingDataSelected();
    }, [ouPendingData]);

    useEffect(() => {
        if (numberOfAttempts !== 0) {
            getAdeudos();
        } 
    }, [numberOfAttempts]);
    


    return (
        <LoaderContent loading={openLoader}>
            { activeComponent === PENDING_COMP && <PendingListItems ouList={ouList} OnOuChange={handleOuChange} ouValue={ou} pendingDataTable={pendingDataTable} pendingDataSelected={ouPendingDataSelected} onGoToComponent={handleGoToPendingComponent} onChangeDataSelected={handleChangeDataSelected} onChangeAllDataSelected={handleChangeAllDataSelected}/>}
            { activeComponent === PENDING_DETAILS_COMP && <PaymentDetails onReturnComponent={handleReturnComponent} pendingDataTable={pendingDataTable} pendingDataSelected={ouPendingDataSelected} ouPendingData={ouPendingData} user={user} refData={refData}/>}

            <Toast {...toastProps} onFinished={handleFinishedToast} />
        </LoaderContent>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(Pending2);