import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// shared components
import Table from "../../../shared/components/Table/Table";
import { getLogoCard } from '../../../constants/logosImages';

// services
import { MembershipService } from '@sdk-point/talisis';

// shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import { Link } from '@material-ui/core';

// styles
import useStyles from '../../../assets/styles/jss/pages/memberships/membershipBenefitsStyles';

// columns & format
const columnFormat = (row, value, index) => {
    if (typeof value === "string") {
        let str = value;

        try {
            while (str?.includes("img{")) {
                let imgStartIndex = str?.indexOf("img{");
                let imgEndIndex = str?.indexOf("}");
                let sourceName = str?.substring(imgStartIndex + 4, imgEndIndex)?.toLocaleLowerCase();
                let imageStr;

                if (sourceName === "coursera") imageStr = `<img src="${getLogoCard(5)}" height="15" alt="${sourceName}" />`;
                if (sourceName === "linkedin") imageStr = `<img src="${getLogoCard(6)}" height="23" alt="${sourceName}" />`;
                if (sourceName === "crehana") imageStr = `<img src="${getLogoCard(13)}" height="18" alt="${sourceName}" />`;
                if (sourceName === "unid") imageStr = `<img src="${getLogoCard(9)}" height="17" alt="${sourceName}" />`;
                if (sourceName === "uerre") imageStr = `<img src="${getLogoCard(8)}" height="16" alt="${sourceName}" />`;

                str = str?.substring(0, imgStartIndex) + "&nbsp;" + imageStr + "&nbsp;" + str?.substring(imgEndIndex + 1);
            }
        } catch (e) { }

        // table body cells
        if (index > 0) {
            return <Box>
                <Typography variant='body1' className={clsx(value === 'Próximamente' ? 'text-fill-stroke-100' : 'text-gray-100', 'variant-xsmall')} component={Box} dangerouslySetInnerHTML={{ __html: str }}></Typography>
            </Box>
        }

        // table main cell 0
        if (index === 0) {
            return <Box display="flex" alignItems="center">
                <Typography variant='body1' className={clsx('text-gray-100')} display="flex" lineHeight="18px" component={Box} dangerouslySetInnerHTML={{ __html: str }}></Typography>
                {
                    !row.is_responsive && (
                        <React.Fragment>
                            {row.id === 10 && <Link variant="body2" className='semi-bold ml-2' color="secondary" component={RouterLink} style={{ textDecoration: 'none' }} target='_blank' to="/busqueda?bachillerato=true">Ver programa</Link>}
                            {row.id === 11 && <Link variant="body2" className='semi-bold ml-2' color="secondary" component={RouterLink} style={{ textDecoration: 'none' }} target='_blank' to="/busqueda?licenciatura=true">Ver programas</Link>}
                            {row.id === 12 && <Link variant="body2" className='semi-bold ml-2' color="secondary" component={RouterLink} style={{ textDecoration: 'none' }} target='_blank' to="/busqueda?maestria=true">Ver programas</Link>}
                        </React.Fragment>
                    )
                }
            </Box>
        }
    }

    if (typeof value === "boolean") {
        if (value === true) {
            return <Box>
                <Typography variant="h3" style={{ fontWeight: "normal", marginRight: row.is_responsive ? "8px" : "0px" }}><i className="ri-checkbox-circle-fill" style={{ color: "rgba(159, 111, 251, 1)" }}></i></Typography>
                {row.is_responsive && row.id === 10 && <Link variant="body2" className='ml-2' color="secondary" component={RouterLink} style={{ textDecoration: 'none', fontSize: '12px' }} target='_blank' to="/busqueda?bachillerato=true">Ver programa</Link>}
                {row.is_responsive && row.id === 11 && <Link variant="body2" className='ml-2' color="secondary" component={RouterLink} style={{ textDecoration: 'none', fontSize: '12px' }} target='_blank' to="/busqueda?licenciatura=true">Ver programas</Link>}
                {row.is_responsive && row.id === 12 && <Link variant="body2" className='ml-2' color="secondary" component={RouterLink} style={{ textDecoration: 'none', fontSize: '12px' }} target='_blank' to="/busqueda?maestria=true">Ver programas</Link>}
            </Box>
        } else {
            return <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: row.is_responsive ? "16px" : "0px" }}>-</Typography>
        }
    }

    return value;
};

const tableColumns = [
    { name: 'description', label: 'Descripción', align: 'left', width: 416, format: columnFormat },
    { name: 'freemium', label: 'Freemium', align: 'center', width: 202, format: columnFormat },
    { name: 'lite', label: 'Lite', align: 'center', width: 202, format: columnFormat },
    { name: 'professional', label: 'Professional', align: 'center', width: 202, format: columnFormat },
    { name: 'premium', label: 'Premium', align: 'center', width: 202, format: columnFormat }
];

const responsiveColumns = [
    { name: 'description', label: 'Descripción', align: 'left', format: columnFormat },
    { name: 'value', label: 'Value', align: 'right', width: 150, format: columnFormat }
];

// compute
const getColumns = (isResponsive) => {
    return isResponsive ? responsiveColumns : tableColumns;
};

const getRows = (data, isResponsive, currentMembership) => {
    let details = { ...data };

    if (currentMembership?.slug) {
        Object.keys(details).forEach(key => {
            details[key].forEach((row) => {
                row.value = row[currentMembership.slug];
                row.is_responsive = isResponsive;
            })
        });

        return details;
    }

    return details;
};

// components
const MembershipBenefits = ({ currentMembership }) => {
    const classes = useStyles();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

    const getMembershipBenefits = async () => {
        try {
            setLoading(true);
            const response = await membershipService.getMembershipBenefits();
            setData(response);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getMembershipBenefits();
    }, []);

    const columns = React.useMemo(() => getColumns(isResponsive), [isResponsive]);
    const rows = React.useMemo(() => getRows(data, isResponsive, currentMembership), [data, isResponsive, currentMembership]);

    return (
        <Loader loading={loading}>
            <Box>
                <Box>
                    <Typography variant="h4" color="primary" className='mb-4'>Contenido</Typography>
                    <Table
                        stripedRows
                        hideHeaders
                        className={classes.table_benefits}
                        maxHeight='calc(1000vh - 300px)'
                        columns={columns}
                        rows={rows["contenido"]}
                    />
                </Box>
                <Box mt={isResponsive ? '12px' : '45px'}>
                    <Typography variant="h4" color="primary" className='mb-4'>Certificaciones</Typography>
                    <Table
                        stripedRows
                        hideHeaders
                        className={classes.table_benefits}
                        maxHeight='calc(1000vh - 300px)'
                        columns={columns}
                        rows={rows["certificaciones"]}
                    />
                </Box>
                <Box mt={isResponsive ? '12px' : '45px'}>
                    <Typography variant="h4" color="primary" className='mb-4'>Vinculación laboral</Typography>
                    <Table
                        stripedRows
                        hideHeaders
                        className={classes.table_benefits}
                        maxHeight='calc(1000vh - 300px)'
                        columns={columns}
                        rows={rows["laboral"]}
                    />
                </Box>
                <Box mt={isResponsive ? '12px' : '45px'}>
                    <Typography variant="h4" color="primary" className='mb-4'>Perfilamiento (perfil profesional)</Typography>
                    <Table
                        stripedRows
                        hideHeaders
                        className={classes.table_benefits}
                        maxHeight='calc(1000vh - 300px)'
                        columns={columns}
                        rows={rows["perfilamiento"]}
                    />
                </Box>
                <Box mt={isResponsive ? '12px' : '45px'}>
                    <Typography variant="h4" color="primary" className='mb-4'>Soporte</Typography>
                    <Table
                        stripedRows
                        hideHeaders
                        className={classes.table_benefits}
                        maxHeight='calc(1000vh - 300px)'
                        columns={columns}
                        rows={rows["soporte"]}
                    />
                </Box>
            </Box>
        </Loader>
    );
};

export default MembershipBenefits;