import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const icons = {
    't-award-line': <path d="M17 15.245V22.117C17 22.2054 16.9766 22.2923 16.932 22.3688C16.8875 22.4452 16.8235 22.5085 16.7465 22.5521C16.6696 22.5957 16.5824 22.6181 16.4939 22.617C16.4055 22.616 16.3189 22.5914 16.243 22.546L12 20L7.757 22.546C7.68104 22.5915 7.59436 22.616 7.50581 22.617C7.41727 22.6181 7.33004 22.5956 7.25304 22.5518C7.17605 22.5081 7.11205 22.4447 7.06759 22.3681C7.02313 22.2915 6.9998 22.2045 7 22.116V15.246C5.70615 14.2101 4.76599 12.7979 4.30946 11.2045C3.85293 9.61117 3.90256 7.91536 4.45149 6.35145C5.00043 4.78753 6.02156 3.43272 7.37378 2.47425C8.726 1.51578 10.3425 1.00098 12 1.00098C13.6575 1.00098 15.274 1.51578 16.6262 2.47425C17.9784 3.43272 18.9996 4.78753 19.5485 6.35145C20.0974 7.91536 20.1471 9.61117 19.6905 11.2045C19.234 12.7979 18.2939 14.2101 17 15.246V15.245ZM9 16.418V19.468L12 17.668L15 19.468V16.418C14.0468 16.8035 13.0282 17.0011 12 17C10.9718 17.0011 9.95316 16.8035 9 16.418ZM12 15C13.5913 15 15.1174 14.3678 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 8.99997C18 7.40868 17.3679 5.88255 16.2426 4.75733C15.1174 3.63212 13.5913 2.99997 12 2.99997C10.4087 2.99997 8.88258 3.63212 7.75736 4.75733C6.63214 5.88255 6 7.40868 6 8.99997C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3678 10.4087 15 12 15Z" fill="#E0E0E0"/>,
    't-avanzado': <>
        <path opacity="0.3" d="M7 12H3V21H7V12Z" fill="#E0E0E0"/>
        <path opacity="0.3" d="M14 8H10V21H14V8Z" fill="#E0E0E0"/>
        <path d="M21 2H17V21H21V2Z" fill="#E0E0E0"/>
    </>,    
    't-intermedio': <>
        <path opacity="0.3" d="M7 12H3V21H7V12Z" fill="#E0E0E0"/>
        <path d="M14 8H10V21H14V8Z" fill="#E0E0E0"/>
        <path opacity="0.3" d="M21 2H17V21H21V2Z" fill="#E0E0E0"/>
    </>,
    't-basico': <>
        <path d="M7 12H3V21H7V12Z" fill="#E0E0E0"/>
        <path opacity="0.3" d="M14 8H10V21H14V8Z" fill="#E0E0E0"/>
        <path opacity="0.3" d="M21 2H17V21H21V2Z" fill="#E0E0E0"/>
    </>,
    't-mis-cursos-line': <path d="M21.9951 8.68242C21.9951 8.67633 21.9951 8.67024 21.9951 8.66461C21.9928 8.64305 21.9897 8.62195 21.9859 8.60133L21.9833 8.5882C21.9798 8.57164 21.9759 8.55524 21.9715 8.53899L21.9649 8.51648C21.9597 8.49867 21.9536 8.48133 21.947 8.46398C21.9448 8.45789 21.9422 8.4518 21.9396 8.44617C21.9334 8.43164 21.9273 8.41711 21.9203 8.40305L21.9107 8.38383C21.9016 8.36595 21.8917 8.34858 21.8809 8.3318L21.877 8.3257C21.8669 8.30977 21.856 8.29477 21.8451 8.27883L21.835 8.2657C21.8093 8.23303 21.7811 8.20276 21.7506 8.17523L21.7383 8.16492C21.7256 8.15367 21.7121 8.14289 21.6985 8.13258L21.6889 8.12555C21.6753 8.1157 21.6617 8.10633 21.6473 8.09789L11.8473 2.09789C11.7415 2.0331 11.6218 1.99902 11.4999 1.99902C11.3781 1.99902 11.2583 2.0331 11.1526 2.09789L1.35256 8.09789C1.24545 8.1635 1.15643 8.25828 1.09452 8.37262C1.03261 8.48696 1 8.6168 1 8.74898C1 8.88117 1.03261 9.01101 1.09452 9.12535C1.15643 9.23969 1.24545 9.33447 1.35256 9.40008L4.49818 11.328V16.9999C4.49808 17.1338 4.53141 17.2652 4.59473 17.3806C4.65804 17.496 4.74903 17.5912 4.85825 17.6562L11.1482 21.3991C11.2528 21.464 11.3712 21.4987 11.4921 21.4999H11.5061C11.6269 21.4987 11.7453 21.464 11.8499 21.3991L18.1381 17.6562C18.2473 17.5912 18.3383 17.496 18.4016 17.3806C18.465 17.2652 18.4983 17.1338 18.4982 16.9999V11.328L20.5982 10.0423V16.9999C20.5982 17.1988 20.6719 17.3896 20.8032 17.5303C20.9345 17.6709 21.1125 17.7499 21.2982 17.7499C21.4838 17.7499 21.6619 17.6709 21.7932 17.5303C21.9244 17.3896 21.9982 17.1988 21.9982 16.9999V8.74992C21.9982 8.72742 21.9982 8.70305 21.9951 8.68242ZM5.89818 16.5588V12.1849L10.7982 15.1849V19.4754L5.89818 16.5588ZM17.0982 16.5588L12.1982 19.4754V15.1854L17.0982 12.1854V16.5588ZM11.4982 13.887L3.10912 8.74992L11.4982 3.61383L19.8872 8.74992L11.4982 13.887Z" fill="#E0E0E0"/>,
    't-arrow-right-line': <path d="M10.7814 7.33336L7.20535 3.75736L8.14802 2.8147L13.3334 8.00003L8.14802 13.1854L7.20535 12.2427L10.7814 8.6667H2.66669V7.33336H10.7814Z" fill="#FFA700"/>,
    't-app': <>
        <g clip-path="url(#clip0_10425_2786)">
            <rect width="16" height="16" rx="4" fill="#F5F5F5"/>
            <path d="M8.125 4.0332V18.6039H12.3917V8.3001H17.4136V4.0332H8.125Z" fill="#1C1F24"/>
            <path d="M5.54317 7.9415H4.96031L5.36621 7.04082L4.64572 5.33156H5.25141L5.66055 6.43836L6.06645 5.33156H6.37262C6.20297 4.88492 5.9015 4.50042 5.50823 4.22911C5.11495 3.9578 4.64847 3.8125 4.17069 3.8125C3.6929 3.8125 3.22642 3.9578 2.83314 4.22911C2.43987 4.50042 2.1384 4.88492 1.96875 5.33156H2.34696V5.55402C2.45156 5.41122 2.63241 5.31294 2.86393 5.31294C3.13027 5.31294 3.34593 5.43033 3.46639 5.64275C3.53032 5.54233 3.61838 5.45954 3.72255 5.40192C3.82672 5.34431 3.94366 5.31372 4.06269 5.31294C4.49984 5.31294 4.78253 5.59207 4.78253 6.06775V7.10153H4.24338V6.14013C4.24338 5.90876 4.11029 5.78183 3.90338 5.78183C3.69646 5.78183 3.56337 5.90876 3.56337 6.14013V7.10104H3.02503V6.14013C3.02503 5.90876 2.89178 5.78183 2.68503 5.78183C2.47827 5.78183 2.34502 5.90876 2.34502 6.14013V7.10104H2.00842C2.23844 7.63319 2.65664 8.06182 3.18297 8.30487C3.70929 8.54793 4.30676 8.58835 4.86103 8.41839C5.41529 8.24843 5.8874 7.88003 6.187 7.38371C6.4866 6.88739 6.59264 6.29802 6.48482 5.7284L5.54317 7.9415Z" fill="#1C1F24"/>
        </g>
        <defs>
            <clipPath id="clip0_10425_2786">
                <rect width="16" height="16" rx="4" fill="white"/>
            </clipPath>
        </defs>
    </>
}

const DsIcon = ({icon = '', ...rest}) => {
    return (
        <SvgIcon {...rest}>
            {icons[icon]}
        </SvgIcon>
    );
};

export default DsIcon;