import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import StatisticBlock from '../../shared/components/StatisticBlock/StatisticBlock';
import LoginButtonsBlock from '../../layout/components/Login/LoginButtonsBlock/LoginButtonsBlock';
import { Card, CardContent, Divider, Grid, Hidden, Icon } from '@material-ui/core';

import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import Testimonies from './components/Testimonies';

import useStyles from '../../../src/assets/styles/jss/pages/simplicity/simplicityStyle';
import {userIsLogged} from '../../shared/utils/Sessions'
import { eventTracker } from '../../shared/components/EventTracker/EventTracker';

const items = [
    { title: 'Asesoramiento laboral', text: 'Forma parte de nuestros talleres de capacitación laboral presenciales y en línea.', link: 'Consulta el directorio'},
    { title: 'Oportunidades laborales', text: 'Mantén tu acceso a Conecta para aplicar a vacantes que le den más certeza a tu futuro laboral.', link: 'Consultar las vacantes'},
    { title: 'Prácticas profesionales y Servicio Social', text: 'Revisa las vacantes de acuerdo a tu carrera para comenzar tu experiencia profesional lo antes posible.', link: 'Consulta las vacantes'},
    { title: 'Eventos y ferias de empleo', text: 'Tendrás actividades que fortalecerán tu networking y te permitirán el contacto directo con las mejores organizaciones y eventos de empleabilidad.', link: 'Consulta los eventos'},
    { title: 'Formación continua', text: 'Capacítate a través de los talleres y sesiones que te permitirán desarrollar competencias para impulsar tu empleabilidad.', link: 'Consulta el directorio'},
    { title: 'Plataforma en línea y App Móvil', text: 'Busca las vacantes en línea de acuerdo con tu perfil que te ayudarán a dar seguimiento desde tu ordenador o smartphone.', link: 'Descarga la aplicación'},
]

const SIMPLICITY_URL = 'https://shibboleth-talisis-csm.symplicity.com/sso/';

const Simplicity = () => {
    const classes = useStyles();
    const isLogged = userIsLogged()

    const handleClick = () => {
        window.location.href = "mailto:conecta@talisis.com?subject=Soporte Conecta&body=Mensaje";
    };

    React.useEffect(() => {
        eventTracker('s_conecta', {}, ['mixpanel']);
    }, []);

    return (
        <Box>
            <Box className={classes.banner}>
                <Container>
                    <Box position="relative">
                        <Box>
                            <Box>
                            <img src="/images/logos/logo-conecta.svg" className={classes.logo}/>
                            </Box>
                            <Box className={classes.title_container}>
                                <Box className={classes.title}>
                                    <Typography variant="h1">
                                        Un nuevo capítulo
                                        <Hidden mdUp>&nbsp;en</Hidden>
                                    </Typography>
                                    <Typography variant="h1">
                                        <Hidden smDown>en&nbsp;</Hidden>
                                        <Typography variant="h1" component="span" className="text-violet-300">tu&nbsp;</Typography> 
                                        carrera profesional.
                                    </Typography>
                                </Box>
                                <Box className={classes.subtitle}>
                                    <Typography variant="h4">Encuentra tu trabajo ideal con Talisis. Descubre asesoramiento profesional, prácticas laborales, servicio social y mucho más. </Typography>
                                </Box>
                            </Box>
                            <Button color="primary" variant="contained" href={SIMPLICITY_URL} target={'_blank'}>
                            Conecta ahora
                            </Button>
                        </Box>
                        <Box className="banner-image" >
                            <Hidden mdDown>
                                <img src="/images/simplicity/banner-simplicity.svg"/>                   
                            </Hidden>
                            <Hidden lgUp>
                                <img src="/images/simplicity/banner-simplicity-responsive.svg"/>                   
                            </Hidden>
                        </Box>
                    </Box>
                </Container>
                <Box></Box>
            </Box>

            <StatisticBlock />

            {/* <--- Encontrar ---> */}
            <Box className={classes.find}>
                <Container>
                    <Box display="flex" className="find-content">
                        <Box className={classes.find_fig_1} />
                        <Box className={classes.find_content}>
                            <Card elevation={0} className={clsx(classes.find_card, "gradient-electric-blue")}>
                                <CardContent className={classes.find_cardContent}>
                                    <Typography variant="h2">¿Buscas talento?</Typography>
                                    <Typography variant="body2"><Typography variant="body2" className="semi-bold" component="span">
                                        Conecta con nuestros más de 140 mil alumnos y egresados
                                        </Typography>a nivel nacional y encuentra el talento que tu organización necesita.​</Typography>
                                    <Button variant="contained" className="bttn" href={SIMPLICITY_URL} target={'_blank'}>Conoce nuestros servicios</Button>
                                </CardContent>
                            </Card>
                            <Hidden mdUp>
                                <Divider className="bg-fill-stroke-100" />
                            </Hidden>
                            <Card elevation={0} className={clsx(classes.find_card, "gradient-violet")}>
                                <CardContent className={classes.find_cardContent}>
                                    <Typography variant="h2">¿Buscas oportunidades?</Typography>
                                    <Typography variant="body2">Conecta con tu desarrollo profesional y encuentra la oportunidad laboral que estás buscando. ​<Typography variant="body2" className="semi-bold" component="span">
                                    Conecta con tu futuro.
                                        </Typography></Typography>
                                    <Button variant="contained" className="bttn" href={SIMPLICITY_URL} target={'_blank'}>Ver ofertas de empleo</Button>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box className={classes.find_fig_2}/>
                    </Box>
                </Container>
            </Box>

            {/* <--- Testimonios ---> */}
            <Testimonies />

            {/* <--- Servicios ---> */}
            <Box className={classes.offer}>
                <Container>
                    <Box className={classes.offer_content}>
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <Box>
                                    <Box className={classes.offer_contentTitle}>
                                        <Typography variant="h2">¿Qué tenemos preparado para <Typography variant="inherit" color="primary">ti</Typography>?</Typography>
                                    </Box>
                                    <Box className={classes.offer_contentText}>
                                        <Typography>
                                        Desarrollamos programas que ayudan a nuestros estudiantes y egresados a acceder a más y mejores oportunidades laborales.<br/><br/>
                                        Descubre más de los múltiples programas que tenemos disponibles a través de la Bolsa de Trabajo ¡y potencia tu desarrollo profesional! 
                                        </Typography>
                                    </Box>
                                    <Button color="primary" className="p-0 bttn" endIcon={<Icon className="ri-arrow-right-s-line"/>} href={SIMPLICITY_URL} target={'_blank'}>Ver todas las ofertas laborales</Button>
                                </Box>
                            </Grid>
                            <Grid container item xs={12} md={7} spacing={3} className="offer-items">
                                {
                                    items.map(it =>                                         
                                        <Grid item xs={12} md={6} key={uuid()}>
                                            <Card variant="outlined" className="primary-hover">
                                                <CardContent className={classes.offer_cardContent}>
                                                    <Box>
                                                        <Typography variant="h3" className="offset-margin-bottom-34">{it.title}</Typography>
                                                        <Typography>{it.text}</Typography>
                                                    </Box>
                                                    <Button color="primary" className="p-0 bttn-link" endIcon={<Icon className="ri-arrow-right-s-line"/>} href={SIMPLICITY_URL} target={'_blank'}>{it.link}</Button>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            {/* <--- Integrate ---> */}
            <Box>
                <Container>
                    <Divider className="bg-fill-stroke-200"/>
                    <Box className={classes.jobMarket}>
                        <Grid container>
                            <Grid item xs={12} md={5} className={classes.jobMarket_content}>
                                <Box className={classes.offer_contentTitle}>
                                    <Typography variant="h2">Regístrate ahora e <Typography variant="inherit" color="primary">intégrate</Typography> con éxito al Mercado Laboral</Typography>
                                </Box>
                                <Box height={88} maxWidth={488} marginBottom="44px">
                                    <Typography>
                                    Ten acceso a cientos de ofertas laborales en todo México. ¡Estarás en la lista de prospectos cuando surja la oportunidad soñada!
                                    </Typography>
                                </Box>
                                {
                                    !isLogged &&
                                    <Box>
                                        <LoginButtonsBlock 
                                            loginBttnProps={{
                                                color: 'default',
                                                variant:"contained",
                                                label: 'Ya tengo cuenta',
                                                style: {order: 2}
                                            }}
                                            registerBttnProps={{
                                                variant:"contained",
                                                label: 'Registrarme',
                                                style: {order: 1, marginRight: 23}
                                            }}
                                        />
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Box className={classes.jobMarket_girl}>
                                    <img src="/images/simplicity/girl.svg" className="image"/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            <Box>
                <Container>
                    <Divider className="bg-fill-stroke-200"/>
                    <Box className={classes.jobMarket} display="flex" justifyContent="center">
                        <Box maxWidth={500} display="grid" textAlign="center">
                            <Typography variant="h1" align="center" className="offset-margin-bottom-41">¿Tienes alguna duda o sugerencia?</Typography>
                            <Typography align="center">
                            Si tienes alguna duda, dificultad para acceder a la plataforma o simplemente nos quieres compartir tu opinión, escríbenos a:
                            </Typography>
                            <Box mt="28px">
                                <Button onClick={handleClick} color="primary" variant="outlined" startIcon={<Icon className="ri-mail-line"/>}>
                                conecta@talisis.com
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default Simplicity;