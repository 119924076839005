export function normalizeError(error) {
    var normalizedError = {};
    if (typeof (error) === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ?
            { message: errParts[1], debug: errParts[0] } :
            { message: error };
    } else {
        normalizedError = {
            message: error.message,
            debug: JSON.stringify(error)
        };
    }
    return normalizedError;
}