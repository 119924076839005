import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

// styles
import useStyles from '../../../../assets/styles/jss/treeItemStyle'
  
export default function StyledTreeItem(props) {
    const classes = useStyles();
    const { labelText, level, onChange, checked, name, indeterminate, parent,...other } = props;
  
    const handleChange = (e, checked) => {
        const node = {
            id: props.nodeId,
            level,
            checked,
            parent

        }
        onChange(node)
    };
  
    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <Checkbox color="primary" 
                        name={name} 
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={handleChange}
                        onClick={e => (e.stopPropagation())} />
                    <Typography variant="body2" className={classes.labelText}>
                    {labelText}
                    </Typography>
                </div>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: level > 0 ? classes.groupChild : classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
  }
  
StyledTreeItem.propTypes = {
    labelText: PropTypes.string
};