const RoleObject = () => {
  return [
          {
            required: false,
            type: 'number',
            label: 'Id',
            name: 'id',
            defaultValue: '',
            length: 100,
            onChange: null,
            disabled: true,
            visibility: 'block',
            error: false,
            helperText: ''
          },
          {
            required: true,
            type: 'text',
            label: 'Nombre',
            name: 'name',
            defaultValue: '',
            length: 100,
            onChange: null,
            visibility: 'block'
          },
          {
            required: true,
            type: 'text',
            label: 'Descripción',
            name: 'description',
            defaultValue: '',
            length: 100,
            onChange: null,
            disabled: false,
            visibility: 'block',
            error: false,
            helperText: ''
          },
          {
            required: true,
            type: 'text',
            label: 'Value',
            name: 'value',
            defaultValue: 'management',
            length: 100,
            onChange: null,
            disabled: true,
            visibility: 'block',
            error: false,
            helperText: ''
          }
        ] 
}

export default RoleObject;