import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root:{
    },
    container:{
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        textAlign: 'center',
        [theme.breakpoints.down('md')]:{
            maxWidth: theme.breakpoints.values.md
        },
        [theme.breakpoints.down('sm')]:{
            maxWidth: theme.breakpoints.values.sm
        },
        [theme.breakpoints.down('xs')]:{
            paddingLeft: props => props.disableGuttersXs ? 0 : 15,
            paddingRight: props => props.disableGuttersXs ? 0 : 15
        },
    },
}));

const ResponsiveContainer = ({children, className, disableGuttersXs}) => {
    const classes = useStyles({disableGuttersXs});
    return (
        <Box className={clsx(classes.root, className)}>
            <Box className={clsx(classes.container, 'container')}>
                {children}
            </Box>
        </Box>
    );
};

export default ResponsiveContainer;