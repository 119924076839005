import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    imageContainer:{
        marginTop: '27px',
        width: '100%'
    },
    titleMessageContainer:{
        marginTop: '43px',
        width: '100%'
    },
    titleMessageSubcontainer:{
        textAlign: 'center',
    },
    messageContainer:{
        marginTop: '12px',
        width: '100%'
    },
    messageSubcontainer:{
        textAlign: 'center',
    },
}));