import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root:{
        color: theme.palette.primary.main,
        '&:hover':{
            background: 'rgba(255, 255, 255, 0.04)',
            cursor: 'pointer',
        },
        borderRadius: 8,
        padding: '6px 8px',

        '&.outlined':{
            border: '1px solid rgba(144, 202, 249, 0.5)',
            '&:hover':{
                border: `1px solid ${theme.palette.primary.main}`,
            }
        },

        '&.disabledButton':{
            color: theme.palette.grey.main,
            '&:hover':{
                cursor: 'none',
                backgroundColor: 'transparent',
            }
        },
    },
    inputFile:{
        width: '100%',
        position: 'relative',
        justifyContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        display: 'inline-flex',
        flexDirection: 'column',
        borderRadius: 4,
        color: 'inherit',
    
        '& input':{
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            bottom: 0,
            cursor: 'pointer',
            margin: 0,
            opacity: 0,
            padding: 0,
            overflow: 'visible',
            position: 'absolute',
            fontSize: 14,
            '&:hove':{
                cursor: 'pointer',
            }
        }
    }
}));