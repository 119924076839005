import React from 'react'
import { Avatar, Button, Box, Typography} from '@material-ui/core'

// styles
import { makeStyles } from '@material-ui/core/styles';
import qrImg from '../../../assets/images/logos/onboarding/qr.png'
import CheckSVG from '../../../assets/images/logos/onboarding/check.svg'

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    img:{
        width: 160,
        height: 160,
        marginTop: 119,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    check:{
        width: 75,
        height: 75,
        marginTop: 40,
        marginBottom: 32,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    button:{
        padding: '16px 59px'
    },
    typoRecommendation:{
        maxWidth: 348,
        marginBottom: 56
    }
}));

export const OnCardFinalize = ({onClickClose, loading}) => {

    const classes = useStyles();
    
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
           {/* <Avatar alt="Uss" src={qrImg} className={classes.img} />  */}
            <Avatar alt="Uss" src={CheckSVG} className={classes.check} />
            <Typography variant="h3" color="primary" className="mb-4">
                ¡Listo! 
            </Typography>
            <Typography  align="center" className="font-variant-v4 mb-3">
            ¿Quieres llevar Talisis en tu teléfono? 
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary" className={classes.typoRecommendation}>
            Te recomendamos descargar nuestra aplicación para no perderte del contenido en todos lados.
            </Typography>
            <Button variant='contained' color="primary"
                className={classes.button}
                onClick={onClickClose}
            >
            Finalizar
            </Button>
        </Box>
    )
}

export default OnCardFinalize;
