import React from 'react';
import { useHistory } from 'react-router-dom';

// @redux
import { connect } from 'react-redux';
import * as userActions from '../../../redux/actions/userActions';
import * as menuActions from '../../../redux/actions/menuActions';
import * as profilePicActions from '../../../redux/actions/profilePicActions';
import * as membershipActions from '../../../redux/actions/membershipActions';
import { MEMBERSHIPS } from '../../../shared/constants/memberships';

// @shared
import Table from '../../../shared/components/Table/Table'
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import InputSearch from '../../../shared/components/Form/InputSearch';
import Toast from '../../../shared/components/Toast/Toast';
import ChipFilter from '../../../shared/components/Chip/ChipFilter';
import MoreOptions from '../../../shared/components/MoreOptions/MoreOptions';
import { setSessionCookie } from "../../../shared/utils/Sessions";
import { log } from '../../../shared/utils/console.js'

// @material-ui
import { Icon, Grid, Link } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { green, red } from '@material-ui/core/colors';
import { columns } from './ColumnsUserTable';

// @sdk-point
import { UserService, SimulationService, RestrictionsService } from '@sdk-point/talisis';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';

const units = [
    { ou: "iesalud", ouLabel: "IESALUD" },
    { ou: "talisis", ouLabel: "TALISIS" },
    { ou: "uerre", ouLabel: "U-ERRE" },
    { ou: "unid", ouLabel: "UNID" },
    { ou: "hh", ouLabel: "HARMON HALL" }
];

function Actions({ item, onClickEdit, onClickSimulator, onClickHealthCheck }) {
    const options = [
        { id: 1, label: 'Ver información', fun: onClickEdit(item.id), icon: <i className="ri-eye-fill"></i> },
        { id: 2, label: 'Iniciar simulación', fun: onClickSimulator(item), icon: <i className="ri-play-circle-fill"></i> },
    ];
    return (
        <>
            <MoreOptions options={options} ExAction={true} />
        </>
    );
}

const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const SimulationServiceSDK = new SimulationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RestrictionsServiceSDK = new RestrictionsService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const UserTablePag = (props) => {
    const { user, profilePic, applyUser, applyProfilePic, applyMembership, getUserMenu } = props;

    const [state, setState] = React.useState({ data: [], totalPages: 0 })
    const [loading, setLoading] = React.useState(false);
    const [toast, setToast] = React.useState({ toastProps: { message: '', open: false } })
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [filters, setFilters] = React.useState([]);
    const [selectedFilters, setSelectedFilters] = React.useState({});
    const [inputSearch, setInputSearch] = React.useState('');
    const [valueFilter, setValueFilter] = React.useState('person_id');
    const valueFilterUserLabels = {
        person_id: 'ID',
        first_name: 'Nombre',
        email: 'Correo electrónico',
        user_id: 'Número de Matrícula',
    };
    const valueFilterQueryStringURL = {
        person_id: 'id',
        first_name: 'nombre',
        email: 'email',
        user_id: 'matricula',
    };
    const history = useHistory();

    const URLParams = new URLSearchParams(window.location.search);
    const personID = URLParams.get("id");
    const firstName = URLParams.get("nombre");
    const userID = URLParams.get("matricula");
    const email = URLParams.get("email");

    React.useEffect(() => {
        getAllUsers();
        history.push({ search: new URLSearchParams(selectedFilters).toString() });
        return () => { setState({ data: [], totalPages: 0 }) }
    }, [page, filters]);

    React.useEffect(() => {
        handleApplyQueryParamsFilters();
    }, []);

    const handleClickEdit = id => event => {
        event.preventDefault();
        history.push(`/usuarios/${id}/editar`);
    };

    const handleClickSimulator = item => event => {
        event.preventDefault();
        startSimulator(item);
    };

    const handleClickHealthCheck = item => event => {
        event.preventDefault();
        history.push(`/usuarios/${item.id}/health-check`);
    };

    const getAllUsers = async () => {
        try {
            setLoading(true);
            const requests = await UserServiceSDK.getAll(page, filters, pageSize);
            requests.data.map((it, index) => {
                it.id = String(it.id);
                it.id_link = <Link href="#" onClick={() => history.push(`/usuarios/${it.id}/editar`)} color="inherit" variant="body2" className="semi-bold">{String(it.id)}</Link>;
                it.actions = <Actions item={it} onClickEdit={handleClickEdit} onClickSimulator={handleClickSimulator} onClickHealthCheck={handleClickHealthCheck} />;
                it.is_active = <Icon className="fa fa-circle" color="primary" key={index} style={{ color: (it.is_active) ? green[500] : red[500], fontSize: 20 }} />;
                it.label = '';
                it.user_id = it.user_id ?? '-';
                return (it);
            });
            setState({ data: requests.data, totalPages: requests.totalPages })
        }
        catch (error) {
            setToast({ toastProps: { severity: "error", open: true, message: "Ops! Ha ocurrido un error" } })
            log('Error get Users', error)
        }
        finally {
            setLoading(false);
        }
    }

    const handleChangePage = (e, page) => {
        e.preventDefault();
        setPage(page);
    };

    const onChangeSearch = (e) => {
        e.preventDefault();
        setInputSearch(e.target.value);
    }

    const searchRecurse = () => {
        if (inputSearch && valueFilter) {
            handleFilters();
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleFilters();
        }
    }

    const handleFilters = () => {
        if (inputSearch.trim() && !selectedFilters[valueFilterQueryStringURL[valueFilter.trim()]]) {
            setPage(1);
            let filter = {};
            filter[valueFilter.trim()] = inputSearch.trim();
            filter['label'] = `${valueFilterUserLabels[valueFilter.trim()]}: ${inputSearch}`;
            setFilters([...filters, filter]);
            setSelectedFilters({ ...selectedFilters, [valueFilterQueryStringURL[valueFilter.trim()]]: inputSearch.trim() });
            setValueFilter('person_id');
            setInputSearch('');
        }
    }

    const handleDeleteChip = (chipToDelete) => () => {
        setFilters(() => filters.filter((chip) => JSON.stringify(chip) !== JSON.stringify(chipToDelete)));
        setSelectedFilters((prevState) => {
            const newState = { ...prevState };
            const value = Object.keys(chipToDelete)[0];
            delete newState[valueFilterQueryStringURL[value]];
            return newState;
        });
    }

    const handleFinishedToast = () => {
        setToast({ toastProps: { open: false, message: '' } })
    }

    const COLUMNS_TABLE = columns;

    const filterSearch = [
        { value: 'person_id', descripcion: 'ID' },
        { value: 'user_id', descripcion: 'Matrícula' },
        { value: 'first_name', descripcion: 'Nombre' },
        { value: 'email', descripcion: 'Correo electrónico' }
    ];

    const handleChangeFilter = (event) => {
        setValueFilter(event.target.value);
    };

    const startSimulator = async (item) => {
        setLoading(true);
        setToast({ toastProps: { open: false, message: '' } })

        // Backup current user
        localStorage.setItem("admin_session", JSON.stringify({
            user: user ?? {},
            profilePic: profilePic ?? {}
        }));

        // Setup fake user
        let company = !item.company_id ? "" : item.company_id.toLowerCase();
        if (item.email && item.email.includes('iesalud') === true) {
            company = 'iesalud'
        }

        const ou = company;
        const unit = units.filter(u => u.ou === ou)[0];
        const f_user = {
            userName: item.first_name + ' ' + item.last_name || "",
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email || "",
            student_id: item.user_id,
            person_id: item.id,
            is_admin: false,
            is_fake: true,
            ou: unit?.ou,
            ouLabel: unit?.ouLabel,
            profile_picture_url: item.profile_picture_url,
            membership_id: item.membership_id || MEMBERSHIPS.FREEMIUM,
            [userActions.USER_PROPERTIES.ACCEPT_TERMS_CONDITIONS]: true
        };

        try {
            // Profile pic URL.
            const f_profilePic = {
                src: item.profile_picture_url ?? '/images/avatars/placeholder.png',
                blob: false
            };
            applyProfilePic(f_profilePic);

            // Create BD session.
            const session = ({
                "person_id": user.person_id,
                "student_id": item.id
            });
            await SimulationServiceSDK.createSession(session).then(async response => {
                f_user.fake_session = await response;
                f_user.interests = f_user.interests || [];
                f_user.first_name = f_user.first_name || '';
                f_user.last_name = f_user.last_name || '';
                f_user.company_id = company ? company.toUpperCase() : 'TAL';

                const getUserRestrictions = async () => {
                    try {
                        const response = await RestrictionsServiceSDK.getRestrictionsByUser(item.id);
                        f_user.restrictions = response.result;
                    }
                    catch (error) {
                        console.error('Error al obtener las restricciones:', error);
                    }
                }
                await getUserRestrictions();

                // Apply fake user
                applyUser(f_user);
                getUserMenu(f_user);
                applyMembership(f_user.membership_id);
                setSessionCookie(f_user);

                history.push("/home");
            });
        }
        catch (e) {
            log(e);
            setLoading(false);
        }
    };

    const handleApplyQueryParamsFilters = () => {
        const newFilters = [];
        const newSelectedFilters = {};
        if (personID) {
            newFilters.push({
                person_id: personID,
                label: `${valueFilterUserLabels['person_id']}: ${personID}`
            });
            newSelectedFilters['id'] = personID;
        }
        if (firstName) {
            newFilters.push({
                first_name: firstName,
                label: `${valueFilterUserLabels['first_name']}: ${firstName}`
            });
            newSelectedFilters['nombre'] = firstName;
        }
        if (email) {
            newFilters.push({
                email: email,
                label: `${valueFilterUserLabels['email']}: ${email}`
            });
            newSelectedFilters['email'] = email;
        }
        if (userID) {
            newFilters.push({
                user_id: userID,
                label: `${valueFilterUserLabels['user_id']}: ${userID}`
            });
            newSelectedFilters['matricula'] = userID;
        }
        setFilters(newFilters);
        setSelectedFilters(newSelectedFilters);
    };
    return (
        <>
            <InputSearch
                recurse={valueFilterUserLabels[valueFilter]}
                valueSearch={inputSearch}
                inputSearch='searchUser'
                onChangeSearch={onChangeSearch}
                searchRecurse={searchRecurse}
                onKeyDown={onKeyDown}
                filter={filterSearch}
                handleChangeFilter={handleChangeFilter}
                valueFilter={valueFilter} />
            <Grid container spacing={1}>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={4}>
                    <ChipFilter chipData={filters} handleDelete={(e) => handleDeleteChip(e, valueFilter)} />
                </Grid>
            </Grid>
            <LoaderContent loading={loading} minHeight={200}>
                {state.data.length > 0 ?
                    <React.Fragment>
                        <div style={{ float: "right" }}>
                            {(!loading) && <Pagination count={state.totalPages} page={page} onChange={handleChangePage} defaultPage={1} siblingCount={0} showFirstButton showLastButton />}
                        </div>
                        <Table columns={COLUMNS_TABLE} rows={state.data} />
                        <div style={{ float: "right" }}>
                            {(!loading) && <Pagination count={state.totalPages} page={page} onChange={handleChangePage} defaultPage={1} siblingCount={0} showFirstButton showLastButton />}
                        </div>
                    </React.Fragment>
                    :
                    !loading && <NothingFound width='200px' caption={'No se encontraron registros que coincidan con el criterio de búsqueda ingresado.'} type={"data"} subcaption={'Intenta cambiando tus criterios.'} />}
            </LoaderContent>
            <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={3000} />
        </>
    );
};

const mapStateToProps = ({ userReducer, profilePicReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...menuActions, ...membershipActions };
export default connect(mapStateToProps, mapDispatchToProps)(UserTablePag);