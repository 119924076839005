import { SET_ACTIVE_TAB, SET_ACTIVE_SUB_TAB } from '../types/tabsTypes';

export function setActiveTab(index) {
    return {
        type: SET_ACTIVE_TAB,
        payload: index,
    };
}

export function setActiveSubTab(index) {
    return {
        type: SET_ACTIVE_SUB_TAB,
        payload: index,
    };
}