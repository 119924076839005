import React from 'react'
import clsx from 'clsx'

// @material-ui 
import { Box, Button, Grid, Icon, Modal, Typography } from '@material-ui/core'

// assets
import useStyles from '../../../../../../assets/styles/jss/pages/checkout/checkout';

const PaymentConfirmationModal = ({ openModal, onModalClose, onModalConfirm, title, primaryText, secondaryText }) => {
    const classes = useStyles();

    return (
        <Modal
            open={openModal}
            onClose={onModalClose}
            className={classes.paymentConfirmationModal}
            aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description"
        >
            <Box sx={{ maxWidth: "536px", backgroundColor: '#1C1F24', textAlign: 'center', paddingX: '16px', paddingBottom: '32px', paddingTop: '16px', borderRadius: '8px' }} className={classes.paymentConfirmationModalInside}>
                <Grid container direction="row">
                    <Grid item xs={12} style={{ paddingBottom: '8px', textAlign: 'right' }}>
                        <Icon className='ri-close-fill text-dark-black-200' style={{ cursor: 'pointer' }} fontSize='small' onClick={onModalClose} />
                    </Grid>
                    <Grid item xs={12} className='px-4' style={{ paddingBottom: '14px' }}>
                        <Icon className='ri-error-warning-line text-warning-300' fontSize='large' />
                    </Grid>
                    <Grid item xs={12} className={clsx("mb-4", 'px-4')}>
                        <Typography variant='h4'>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={clsx("mb-2", 'px-4')}>
                        <Typography variant='body1' className='semi-bold'>
                            {primaryText}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={clsx("mb-4", 'px-4')}>
                        <Typography variant='body1' className="text-dark-black-100">
                            {secondaryText}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ borderTop: "1px solid #404150" }} className={clsx('mx-4')}>
                        <Grid container spacing={2} className="pt-4" alignContent="space-between" alignItems='center'>
                            <Grid item xs={6}>
                                <Button fullWidth variant='contained' className='px-2' onClick={onModalClose}>
                                    Cambiar método de pago
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth variant="contained" color='primary' onClick={onModalConfirm}>
                                    Generar orden
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default PaymentConfirmationModal;
