function isIndeterminate(checked, children) {
    const someCheckChild = children.some(it => it.checked) || children.some(it => it.indeterminate);
    return !checked && someCheckChild;
}

function checkChildren(item, checked) {
    const items = item.children;
    if (!items) {
        return;
    }
    
    for (const child of items) {
        const {children} = child;
        if (children && children.length) {
            child.checked = checked;
            checkChildren(child, checked);
            child.indeterminate = isIndeterminate(checked, children);
        }
        else {
            child.checked = checked;
        }
    }
}

export function checkItem(items, prop, itemSelected, nestedKey) {
    for (let item of items) {
        if (String(item[prop]) === String(itemSelected.id)) {
            item.checked = itemSelected.checked;
            if (item.children) {
                item.indeterminate = false;
                checkChildren(item, itemSelected.checked);
            }
            return true;
        }
        else if (item[nestedKey] && checkItem(item[nestedKey], prop, itemSelected, 'children')) {
            return;
        }
    }
}

export function changeCheckParent(items, id, allItems) {
    for (const item of items) {
        if (item.id === id) {
            const {children} = item;
            const childrenCheckedLength = children.filter(it => it.checked).length;

            item.checked = childrenCheckedLength === children.length;
            item.indeterminate = isIndeterminate(item.checked, children);
            if (item.hasOwnProperty('parent')) {
                changeCheckParent(allItems, item.parent, allItems)
            }

            return true;
        }
        else if (item.children && changeCheckParent(item.children, id, allItems)) {
            return;
        }
    }
}

export function transformToNestedList(items) {
    return [{
        id: 0,
        label: 'Todo',
        name: 'all',
        checked: false,
        level: 1,
        children: items.map(it => {
            it.label = it.name;
            it.children = it.campus;
            it.checked = false;
            it.parent = 0;
            it.level = 2;
            delete it.campus;
    
            it.children.map(child => {
                child.id = `${it.id}-${child.id}`;
                child.parent = it.id;
                child.checked = false;
                child.label = child.name;
                child.level = 3;
                return child;
            })
    
            return it;
        })
    }]      
}

export function someItemChecked(items) {
    for(let item of items) {
        if (item.checked) {
            return true;
        }
        else if (item.children && someItemChecked(item.children)) {
            return true;
        }
    }
    return false;
} 

export function getAllChecked(items, itemsChecked = []) {
    for (let item of items) {
        if (item.checked) {
            itemsChecked.push(item);
        }
        else if (item.children) {
            itemsChecked.concat(getAllChecked(item.children, itemsChecked))
        }
    }

    return itemsChecked;
}