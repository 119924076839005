import React from 'react';
import clsx from 'clsx';

// @material-ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// assets
import useStyles from '../../../../assets/styles/jss/pages/checkout/checkout';

const OrderNextPaymentDate = ({ orderNextPaymentDate }) => {
    const classes = useStyles();

    return <Box className={classes.orderNextPaymentDate}>
        <Typography variant='caption' className={clsx("font-weight-600")}>Próximo pago: {orderNextPaymentDate}</Typography>
    </Box>
}

export default OrderNextPaymentDate;