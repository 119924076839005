import {SOURCES} from '../constants/sources';
import {COMPANIES} from '../constants/sources';

export const getSourceBySourceAndCompany = (sourceId, companyId) => {
    let tempSourceId = sourceId;

    if (!!companyId && (sourceId === SOURCES.BANNER || sourceId === SOURCES.EASYGENERATOR)) {
        if (companyId === COMPANIES.UERRE) {
            tempSourceId = SOURCES.UERRE;
        }
        if (companyId === COMPANIES.UNID) {
            tempSourceId = SOURCES.UNID;
        }
        if (companyId === COMPANIES.HH) {
            tempSourceId = SOURCES.HH;
        }
    }

    return tempSourceId;
};

export const convertOuToSource = (company_id, source_id) => {
    if (company_id) {
        if (company_id === "UERRE" && source_id === SOURCES.IE_UNIVERSITY) return source_id;
        if (company_id === "TAL") return SOURCES.TALISIS;
        if (company_id === "UERRE") return SOURCES.UERRE;
        if (company_id === "UNID") return SOURCES.UNID;
        if (company_id === "HH") return SOURCES.HH;
        if (company_id === "ADV") return SOURCES.ADVENIO;
    }
    return source_id;
}
