import React, { useRef } from 'react';
import { useHistory } from "react-router-dom";
import update from 'react-addons-update';
import clsx from 'clsx';
import moment from 'moment';

//SHARED COMPONENT
import BoxContent from '../../../shared/components/BoxContent/BoxContent';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import Select from '../../../design-system/components/Form/Select/Select';
import { SimpleStatus } from '../../../shared/components/Table/components';
import Loader from '../../../shared/components/Loader/LoaderContent';
import { log } from '../../../shared/utils/console.js'
import { PATH_NAME_DETECTOR_FOR_SHOW_INFO, LABEL_ACTIVE_INACTIVE } from '../constants';
import { ActionState } from "../components";
//MATERIAL UI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Chip, TextField, Box, Typography, Button, Icon, Switch, InputAdornment, FormControlLabel, Checkbox, Divider } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
//SDK POINT
import { MarketPlaceService } from '@sdk-point/talisis';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

const items = [
    { to: '/comercial/promociones/descuentos', label: 'Descuentos' },
]

const ACTION_STATE_SUCCESS = 1;
const ACTION_STATE_ERROR = 2;

const useStyles = makeStyles((theme) => ({
    inputMaterialOnfocus: {
        height: "40px"
    },
    borderFocus: {
        border: "1px solid",
        borderColor: theme.palette.colors.darkBlack[200],
        "&:focus": {
            borderColor: theme.palette.primary.main,
        }
    },
    customTextFieldName: {
        fontWeight: "700",
        color: theme.palette.colors.gray[500],
        backgroundColor: theme.palette.colors.fillStroke[400],
        height: "14px",
        maxWidth: "200px",
        borderRadius: "8px 8px 8px 8px",
        "&::placeholder": {
            fontWeight: "400",
        },
    },
    customTextFieldCode: {
        marginTop: "0px",
        borderRadius: "8px 8px 8px 8px",
        border: "1px solid",
        borderColor: theme.palette.colors.darkBlack[200],
        backgroundColor: theme.palette.colors.fillStroke[500],
        height: "40px",
        maxWidth: "340px",
        "& input[type='time']::-webkit-calendar-picker-indicator": {
            filter: "invert(100%) sepia(0%) saturate(3207%) hue-rotate(130deg) brightness(100%) contrast(100%)",
        },
        "& input[type='date']::-webkit-calendar-picker-indicator": {
            filter: "invert(100%) sepia(0%) saturate(3207%) hue-rotate(130deg) brightness(100%) contrast(100%)",
        }
    },
    option: {
        "&:hover": {
            backgroundColor: theme.palette.colors.fillStroke[200],
        },
        '&[aria-selected="true"]': {
            backgroundColor: theme.palette.colors.fillStroke[200],
            borderColor: 'transparent',
        },
    },
    TextFieldAddProducts: {
        "& .MuiInputBase-root": {
            "& .MuiInputBase-input": {
                border: "0 !important",
            }

        },
    }
}));

const CouponDetail = () => {
    const classes = useStyles();
    const [typeDatail, setTypeDatail] = React.useState(false);
    const [pageName, setPageName] = React.useState("");
    const [couponId, setCouponId] = React.useState(null);
    const [actionState, setActionState] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [errors, setErrors] = React.useState({}); 
    const [clientFreemiumState, setClientFreemiumState] = React.useState(false);
    const [clientNoRegistrados, setClientNoRegistrados] = React.useState(false);
    const [clientLiteState, setClientLiteState] = React.useState(false);
    const [clientProfesionalState, setClientProfesionalState] = React.useState(false);
    const [clientPremiumState, setClientPremiumState] = React.useState(false);
    const [isCreateDiscount, setIsCreateDiscount] = React.useState(false);

    const valueRefExclude = useRef('');
    const valueRefInclude = useRef('');
    const addNewCustomClient = useRef('');
    //ESTE ESTADO SE FORMA EN EL LAMBDA SI YA EXISTE HACE UN SELECT Y SI NO HAY NINGUNO CREADO LOS HACE CON UN ESTATUS DE FALSE, 
    //LOS ID SON PROVISIONALES, AL DARLE CREAR SE ASIGNAN DINÁMICAMENTE EN LOS REGISTROS A LA BD, AL GUARDAR SOLO SE CAMBIA EL ESTADO.
    const [categoryProductsMemberships, setCategoryProductsMemberships] = React.useState([]);
    const [categoryProductsCourses, setCategoryProductsCourses] = React.useState([]);
    const [categoryProductsAliados, setCategoryProductsAliados] = React.useState([]);

    const theme = useTheme();
    const history = useHistory();
    //CAMPOS
    //ESTADO GENERAL DE ACTIVO/INACTIVO + LABEL (ACTIVO/INACTIVO)
    const [stateDiscount, setStateDiscount] = React.useState(true);
    const [stateDiscountLabel, setStateDiscountLabel] = React.useState(LABEL_ACTIVE_INACTIVE[0]);

    // TARGET TIPO PROMOCIÓN 
    const [typePromocion, setTypePromocion] = React.useState('Membresía');

    //TIPO DE DESCUENTO PORCENTAJE/MONTO FIJO/COMPRA X OBTÉN Y
    const [typeDiscount, setTypeDiscount] = React.useState("Meses Gratis");

    //LLENA EL SELECTOR DE TARGET CON LAS DIFERENTES OPCIONES => PORCENTAJE/MONTO FIJO/COMPRA X OBTÉN Y
    const [selectTypeDiscount, setSelectTypeDiscount] = React.useState([]);
 
    // LLENA CHECK PERIODO DE MEMBRESIAS 
    const [membershipPeriods, setMembershipPeriods] = React.useState([{ id: 1, name: "1 Mes", status: false }, { id: 3, name: "3 Meses", status: false }, { id: 6, name: "6 Meses", status: false }, { id: 12, name: "12 Meses", status: false }]);

    //NOMBRE DE DESCUENTO
    const [discountName, setDiscountName] = React.useState("");

    //DESCRIPCIÓN DE DESCUENTO
    const [discountDescription, setDiscountDescription] = React.useState("");

    //TARGET DEL PRODUCTO
    const [productTarget, setProductTarget] = React.useState("Todos");
 
    //TARGET DEL CLIENTE
    const [clientTarget, setClientTarget] = React.useState("Todos los usuarios");

    //CÓDIGO DE DESCUENTO
    const [codeDiscount, setCodeDiscount] = React.useState("");

    //VALOR DE DESCUENTO PORCENTAJE/MONTO FIjo
    const [valueDiscount, setvalueDiscount] = React.useState(0);

    //ESTADO PARA ACTIVAR AGREGAR FECHA FINAL
    const [endDateStatus, setEndDateStatus] = React.useState(false);

    //VALORES DE LAS FECHAS Y TIEMPOS DE VIGENCIA
    const [startDate, setStartDate] = React.useState("");
    const [startHour, setStartHour] = React.useState("");

    const [endDate, setEndDate] = React.useState("");
    const [endHour, setEndHour] = React.useState("");

    //ESTADO PARA ACTIVAR AGREGAR LIMITE DE USO POR CLIENTE
    const [limitUseClient, setLimitUseClient] = React.useState(false);
    const [valueLimitClient, setValueLimitClient] = React.useState(0);

    const [illimitedUse, setIllimitedUse] = React.useState(true);

    const [valueLimitMin, setValueLimitMin] = React.useState(1); 

    //VALOR DE LIMITE GLOBAL DEL CUPÓN
    const [limitUseGlobal, setLimitUseGlobal] = React.useState(-1);

    //VALOR DE USO GLOBAL DEL CUPÓN
    const [usedGlobal, setUsedGlobal] = React.useState(0);
 

    //ARRAY OF PRODUCTS INCLUDED
    const [productsIncludeOriginal, setProductsIncludeOriginal] = React.useState([]);

    //ARRAY OF SELECTED PRODUCTS INCLUDED
    const [productsSelectedInclude, setProductsSelectedInclude] = React.useState([]);

    //ARRAY OF PRODUCTS EXCLUDED
    const [productsExcludeOriginal, setProductsExcludeOriginal] = React.useState([]);

    //ARRAY OF SELECTED PRODUCTS EXCLUDED
    const [productsSelectedExclude, setProductsSelectedExclude] = React.useState([]);

    //ARRAY OF SELECTED CUSTOM CLIENTS - NO SEARCH
    const [clientsInclude, setClientsInclude] = React.useState([]);

    const [valueCompraDe, setValueCompraDe] = React.useState('Una cantidad mínima de productos');
 
    const handleChangeCompraDe = (event) => {
        setValueCompraDe(event.target.value);
    };

    function moveToTheEnd(arr, word) {
        arr.map((elem, index) => {
            if (elem.type.toLowerCase() === word.toLowerCase()) {
                arr.splice(index, 1);
                arr.push(elem);
            }
        })

        arr.map((elem, index) => {
            if (elem.type === "Membresía Lite") {
                arr.splice(index, 1);
                arr.unshift(elem);
            }
        })

        return arr;
    }

    React.useEffect(() => {
        let isCanceled = true;
        var pathName = window.location.pathname.split("/").pop();
        pathName == PATH_NAME_DETECTOR_FOR_SHOW_INFO[0] ? setTypeDatail(PATH_NAME_DETECTOR_FOR_SHOW_INFO[0]) : setTypeDatail(PATH_NAME_DETECTOR_FOR_SHOW_INFO[1]);
        setLoading(true);
        getInitialData(pathName).then((coupon) => {
            if (isCanceled) {
                setPageName(pathName);
                //IF PATH NAME ES CREAR ENTRA EN IF, ELSE OBTIENE EL COUPON_ID DENTNTRO DE LOS PARAMS DEL PATH.
                if (pathName == "editar") {
                    if (coupon.couponInfo) {
                        //AGREGA EL TIPO DE PROMOCIÓN 
                        setTypePromocion(coupon.couponInfo.promotion_for);
                        setCouponId(coupon.couponInfo.id);
                        //AGREGA EL NOMBRE DEL CUPÓN
                        setDiscountName(coupon.couponInfo.name);
                        //AGREGA EL CODIGO DEL CUPÓN
                        setCodeDiscount(coupon.couponInfo.code);
                        //AGREGA ESTADO DEL CUPÓN
                        setStateDiscount(coupon.couponInfo.is_active);
                        //ESTADO USO HASTA EL MOMENTO DEL CUPÓN
                        setUsedGlobal(coupon.couponInfo.total_used);
                        //AGREGA LA DESCRIPCIÓN DEL CUPÓN
                        setDiscountDescription(coupon.couponInfo.description);

                        //LLENA LAS OPCIONES INCLUDE/EXLUDE DE PRODUCTOS
                        const listProducts = JSON.parse(coupon.optionsForExcludeInclude);
                        setProductsIncludeOriginal(listProducts);
                        setProductsExcludeOriginal(listProducts);

                        //AGREGA ITEMS SELECCIONADES DE PRODUCTOS INCLUIDOS/EXCLUIDOS
                        let productsSelectedInclude = JSON.parse(coupon?.specificProducts);
                        setProductsSelectedInclude(productsSelectedInclude);

                        let productsSelectedExclude = JSON.parse(coupon?.productsSelectedExclude);

                        //AGREGA PRODUCTOS EXCLUIDOS SI ASÍ LO TIENE
                        setProductsSelectedExclude(productsSelectedExclude);

                        setTypeDiscount(coupon.couponInfo.discount_type);
                        setvalueDiscount(coupon.couponInfo.value);
                        // PERIODOS 
                        const PERIODOS_MEMBERSHIP = coupon.couponInfo?.membership_periods;
                        let STATUS_PERIODS_ONE = false;
                        let STATUS_PERIODS_TRI = false;
                        let STATUS_PERIODS_SIX = false;
                        let STATUS_PERIODS_TWELVE = false;
                        const INDEX_ONE_MONTH = 0;
                        const INDEX_TRI_MONTH = 1;
                        const INDEX_SIX_MONTH = 2;
                        const INDEX_TWEVEL_MONTH = 3;

                        for (let index = 0; index < membershipPeriods.length; index++) {
                                const period = membershipPeriods[index];
                                if (PERIODOS_MEMBERSHIP?.includes(period.name)) {
                                    switch (index) {
                                        case INDEX_ONE_MONTH:
                                             STATUS_PERIODS_ONE = true;
                                             break;
                                        case INDEX_TRI_MONTH:
                                             STATUS_PERIODS_TRI = true;
                                             break;
                                        case INDEX_SIX_MONTH:
                                             STATUS_PERIODS_SIX = true;
                                             break;
                                        case INDEX_TWEVEL_MONTH:
                                             STATUS_PERIODS_TWELVE = true;
                                            break;
                                    }
                                }
            
                            }
                        setMembershipPeriods(update(membershipPeriods, { [INDEX_ONE_MONTH]: { status: { $set: STATUS_PERIODS_ONE}},
                                                                             [INDEX_TRI_MONTH]: { status: { $set: STATUS_PERIODS_TRI}},
                                                                             [INDEX_SIX_MONTH]: { status: { $set: STATUS_PERIODS_SIX}},
                                                                             [INDEX_TWEVEL_MONTH]: { status: { $set: STATUS_PERIODS_TWELVE}}
                                                                             }))
                        

                        //AGREGA LIMITE DE USO AL CUPON POR CLIENTE
                        if (coupon.couponInfo.limit_usage_by_person) {
                            //setValueLimitClient(coupon.couponInfo.limit_usage_by_person);
                            //setLimitUseClient(true);
                            if (coupon.couponInfo.limit_usage_by_person != -1) {
                                //SI ES DIFERENTE A -1 ASIGNA EL LIMITE DE USO AL CUPON POR CLIENTE
                                setValueLimitClient(coupon.couponInfo.limit_usage_by_person);
                                setLimitUseClient(true);
                            } else {
                                //SI ES -1 EL LIMITE DE USO AL CUPON POR CLIENTE ES ILIMITADO
                                setValueLimitClient(coupon.couponInfo.limit_usage_by_person);
                                setLimitUseClient(false);
                            }
                        }

                        //OBTIENE EL TARGET DE CLIENTES Y LO AGREGA A setClientTarget O AGREGA EL ESTADO EN EL TIPO DE CLIENTE SELECIONADO CLIENTE FREE.LITE.PROFESIONAL.PREMIUM
                        let clientsInclude = JSON.parse(coupon?.clientsInclude);
                        let clientsGroupInclude = JSON.parse(coupon?.clientsGroupInclude)
                        setClientsInclude(clientsInclude);
                        if (clientsInclude.length > 0) {
                            setClientTarget("Usuarios específicos");
                            setClientNoRegistrados(false);
                            setClientFreemiumState(false);
                            setClientLiteState(false);
                            setClientProfesionalState(false);
                            setClientPremiumState(false);
                        } else if (clientsGroupInclude?.clientFreemiumState && clientsGroupInclude?.clientLiteState && clientsGroupInclude?.clientProfesionalState && clientsGroupInclude?.clientPremiumState || (clientsGroupInclude?.clientFreemiumState && clientsGroupInclude?.clientLiteState && clientsGroupInclude?.clientProfesionalState && clientsGroupInclude?.clientPremiumState && clientsGroupInclude?.clientNoRegisterState)) {
                            setClientTarget("Todos los usuarios");
                        } else {
                            setClientTarget("Tipos de usuarios");
                            setClientNoRegistrados(clientsGroupInclude?.clientNoRegisterState);
                            setClientFreemiumState(clientsGroupInclude?.clientFreemiumState);
                            setClientLiteState(clientsGroupInclude?.clientLiteState);
                            setClientProfesionalState(clientsGroupInclude?.clientProfesionalState);
                            setClientPremiumState(clientsGroupInclude?.clientPremiumState);
                        }

                        //AGREGA LIMITE DE USO AL CUPON SI NO ES ILIMITADO
                        if (coupon.couponInfo.usage_limit == -1) {
                            setLimitUseGlobal(coupon.couponInfo.usage_limit);
                            setIllimitedUse(true);
                        } else {
                            setLimitUseGlobal(coupon.couponInfo.usage_limit);
                            setIllimitedUse(false);
                        }

                        //SI HAY UNA FECHA FINAL ACTIVA EL CAMPO DE FECHA FINAL
                        if (coupon.couponInfo.end_date) {
                            setEndDateStatus(true);
                        }

                        //CONVIERTE START_DATE Y END_DATE EN FECHA Y HORA
                        const start_date = new Date(moment.utc(coupon.couponInfo.start_date).local().format().toLocaleString());
                        const end_date = new Date(moment.utc(coupon.couponInfo.end_date).local().format().toLocaleString());

                        const formatStart_date = coupon.couponInfo.start_date ? formatDate(start_date) : "";
                        const formatEndDate = coupon.couponInfo.end_date ? formatDate(end_date) : "";

                        //OBTIENE SOLO LA FECHA INICIAL Y LA AGREGA AL INPUT
                        setStartDate(formatStart_date.date);

                        //OBTIENE SOLO LA FECHA FINAL Y LA AGREGA AL INPUT
                        setEndDate(formatEndDate.date ? formatEndDate.date : "");

                        setStartHour(formatStart_date.hour);
                        setEndHour(formatEndDate.date ? formatEndDate.hour : "");
 
                        if (coupon.couponInfo.promotion_for === 'Cursos') {
                            let newSelectTypeDiscount = coupon.couponTypeSelectOptions;
                            const indexDeleteTypeDiscount = newSelectTypeDiscount.findIndex((item) => item === "Meses Gratis")
                            newSelectTypeDiscount.splice(indexDeleteTypeDiscount, 1);
                            setSelectTypeDiscount(() => newSelectTypeDiscount)
                        }
                        const clientsGroupIncludeUpdate = JSON.parse(coupon.clientsGroupInclude);
                        setClientNoRegistrados(clientsGroupIncludeUpdate.clientNoRegisterState);
                        setClientFreemiumState(clientsGroupIncludeUpdate.clientFreemiumState);
                        setClientLiteState(clientsGroupIncludeUpdate.clientLiteState);
                        setClientProfesionalState(clientsGroupIncludeUpdate.clientProfesionalState);
                        setClientPremiumState(clientsGroupIncludeUpdate.clientPremiumState);
                        setClientsInclude(JSON.parse(coupon.clientsInclude));
                    } else {
                        log("No se encontraron los datos del cupón");
                    }

                } else {
                    let nowDate = new Date;
                    const formatStart_date = formatDate(nowDate);
                    //OBTIENE SOLO LA FECHA INICIAL Y LA AGREGA AL INPUT
                    setStartDate(formatStart_date.date);

                    setStartHour(formatStart_date.hour);
                    setEndHour(formatStart_date.hour ? formatStart_date.hour : "");

                    //INICIALIZAR TODOS LOS USUARIOS
                    setClientNoRegistrados(true);
                    setClientFreemiumState(true);
                    setClientLiteState(true);
                    setClientProfesionalState(true);
                    setClientPremiumState(true);
                    setClientsInclude([]);
                }

                let setSelectDiscountTypes = [];
                if (coupon.couponTypeSelectOptions) {
                    for (const typeOption of coupon.couponTypeSelectOptions) {
                        if (typeOption.name != "Compra X, Obtén Y") {
                            setSelectDiscountTypes.push(typeOption.name);
                        }
                    }
                }
                setSelectTypeDiscount(setSelectDiscountTypes);

                //AGREGA LAS OPCIONES PARA CATEGORÍA POR MEMBRESÍAS
                setCategoryProductsMemberships(moveToTheEnd(coupon.couponSettings.categoryProductsMemberships, "Membresía Premium"));

                //AGREGA LAS OPCIONES PARA CATEGORÍA POR CURSOS
                setCategoryProductsCourses(coupon.couponSettings.categoryProductsCourses);

                //AGREGA LAS OPCIONES PARA CATEGORÍA POR ALIADOS
                setCategoryProductsAliados(coupon.couponSettings.categoryProductsAliados);
                let specificProducts = JSON.parse(coupon?.specificProducts);
                specificProducts = (specificProducts === null) ? [] : specificProducts;
                // SE COMENTA YA QUE ACTUALMETE SOLO APLICA A TODOS LOS CURSOS
                /*
                if (specificProducts.length > 0) {
                    setProductTarget("Productos específicos");
                } else {
                    if (coupon?.couponSettings?.categoryProductsMemberships[0]?.status == false
                        && coupon?.couponSettings?.categoryProductsMemberships[1]?.status == false
                        && coupon?.couponSettings?.categoryProductsMemberships[2]?.status == false
                        && coupon?.couponSettings?.categoryProductsCourses[0]?.status == false
                        && coupon?.couponSettings?.categoryProductsCourses[1]?.status == false) {
                        setProductTarget("Todos");
                    } else {
                        setProductTarget("Categorías de productos");
                    }
                }*/
                setLoading(false);
            }
        }).catch((err) => {
            log(`Failed: ${err}`);
        })
        return () => (isCanceled = false);
    }, []);


    const getInitialData = async (pathName) => {
        try {
            if (pathName == "editar") {
                const url = new URL(document.URL);
                const params = new URLSearchParams(url.search);
                const coupon_id = params.get("cupon");
                //OBTIENE LA INFORMACIÓN DEL CUPÓN DENTRO DEL SERVIDOR
                const coupon = await marketService.getCoupon(coupon_id);
                return coupon;
            } else {
                 const coupon = await marketService.getCoupon(-1);
                setLoading(false);
                return coupon;
            }
        } catch (e) {
            log(e);
            return null;
        }
    }

    //FUNCIÓN PARA CONVERTIR FECHA EN EL FORMATO DEL INPUT
    const formatDate = (d) => {
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            minute = d.getMinutes();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        if (hour.toString().length < 2) {
            hour = '0' + hour.toString();
        }
        if (minute.toString().length < 2) {
            minute = '0' + minute.toString();
        }

        return { date: [year, month, day].join('-'), hour: [hour, minute].join(':') };
    }
    // ASIGNAR ESTADO TIPO PROMOCION
    const handleChangeTypePromocion = (event) => {
        const valuePromocion = event.target.value;
        let newSelectTypeDiscount = selectTypeDiscount;
        if (valuePromocion === 'Membresía') {
            if (newSelectTypeDiscount.length === 2) {
                newSelectTypeDiscount.push("Meses Gratis")
            }
            setTypeDiscount('Meses Gratis')
        } else {
            const indexDeleteTypeDiscount = newSelectTypeDiscount.findIndex((item) => item === "Meses Gratis")
            newSelectTypeDiscount.splice(indexDeleteTypeDiscount, 1);
            setTypeDiscount('Monto fijo')
        }
        setSelectTypeDiscount(() => newSelectTypeDiscount)
        setTypePromocion(valuePromocion)
    }

    //ACTIVA/DESACTIVA DESCUENTO
    const handleChangeStatusDiscount = (event) => {
        setStateDiscount(event.target.checked);
        setStateDiscountLabel(LABEL_ACTIVE_INACTIVE[Number(event.target.checked)]);
     }

    //AGREGA ESTADOS CUANDO SE ACTIVA FECHA FINAL
    const handleChangeEndDateTime = (event) => {
        setEndDateStatus(event.target.checked);
    }

    //AGREGA VALOR DE LA FECHA/HORA
    const handleChangeDateTimeValue = (event) => {
        if (event.target.name == "startDate") {
            setStartDate(event.target.value);
        } else if (event.target.name == "startHour") {
            setStartHour(event.target.value);
        } else if (event.target.name == "endDate") {
            setEndDate(event.target.value);
        } else if (event.target.name == "endHour") {
            setEndHour(event.target.value);
        }
    }

    //AGREGA ESTADOS CUANDO SE ACTIVA LIMITE POR CLIENTE
    const handleChangeLimit = (event) => {
        setLimitUseClient(event.target.checked);
        if (event.target.checked == false) {
            setValueLimitClient(-1);
        } else {
            setValueLimitClient(100);
        }
    }

    //AGREGA ESTADOS CUANDO SE ACTIVA LIMITE POR USOS ILIMITADOS
    const handleChangeLimitUse = (event) => {
        setIllimitedUse(event.target.checked);
        if (event.target.checked) {
            setLimitUseGlobal(-1);
        } else {
            setLimitUseGlobal(100);
        }
    }

    //AGREGA EL VALOR DE LIMITE POR CLIENTE
    const handleChangeLimitValue = (event) => {
        if (/^[0-9]*$/.test(event.target.value)) {
            setValueLimitClient(event.target.value);
         }
    }

    //AGREGA ESTADOS CUANDO SE SELECCIONA PORCENTAJE/MONTO FIJO
    const handleChangeDiscountType = (event) => {
        setTypeDiscount(event.target.value);
    }

    // CHECK UPDATE MEMBERSHIPS PERIODS
    const handleCheckChangePeriodMembership = (event) => {
        event.preventDefault();
        const periodId = event.target.id
        const INDEX_ONE_MONTH = 0;
        const periodOneMonthStatusNew = membershipPeriods[INDEX_ONE_MONTH].status
        const INDEX_TRI_MONTH = 1;
        const periodTriMonthsStatusNew = membershipPeriods[INDEX_TRI_MONTH].status
        const INDEX_SIX_MONTH = 2;
        const periodSixMonthsStatusNew = membershipPeriods[INDEX_SIX_MONTH].status
        const INDEX_TWELVE_MONTH = 3;
        const periodTwelveMonthsStatusNew = membershipPeriods[INDEX_TWELVE_MONTH].status
        switch (Number(periodId)) {
            case 1:
                setMembershipPeriods(update(membershipPeriods, { [INDEX_ONE_MONTH]: { status: { $set: !periodOneMonthStatusNew } } }))
                break;
            case 3:
                setMembershipPeriods(update(membershipPeriods, { [INDEX_TRI_MONTH]: { status: { $set: !periodTriMonthsStatusNew } } }))
                break;
            case 6:
                setMembershipPeriods(update(membershipPeriods, { [INDEX_SIX_MONTH]: { status: { $set: !periodSixMonthsStatusNew } } }))
                break;
            case 12:
                setMembershipPeriods(update(membershipPeriods, { [INDEX_TWELVE_MONTH]: { status: { $set: !periodTwelveMonthsStatusNew } } }))
                break;
            default:
                break;
        }
    }

    //AGREGA ESTADOS CUANDO SE SELECCIONA PORCENTAJE/MONTO FIJO
    const handleChangeProductTarget = (event) => {
        setProductTarget(event.target.value);
        setProductsSelectedInclude([]);
        let newObject = [...categoryProductsCourses];
        for (let i = 0; i < newObject.length; i++) {
            newObject[i].status = false;
        }
        let newObject2 = [...categoryProductsMemberships];
        for (let i = 0; i < newObject2.length; i++) {
            newObject2[i].status = false;
        }
        setCategoryProductsMemberships(newObject2);
        setCategoryProductsCourses(newObject);
        setProductsSelectedExclude([]);
    }

    //AGREGA TARGE DE TIPOS DE CLIENTES
    const handleChangeClientTarget = (event) => {
         if (event.target.value === "Todos los usuarios") {
            setClientTarget(event.target.value);
            setClientNoRegistrados(true);
            setClientFreemiumState(true);
            setClientLiteState(true);
            setClientProfesionalState(true);
            setClientPremiumState(true);
            setClientsInclude([]);
        } else {
            setClientTarget(event.target.value);
            setClientNoRegistrados(false);
            setClientFreemiumState(false);
            setClientLiteState(false);
            setClientProfesionalState(false);
            setClientPremiumState(false);
            setClientsInclude([]);
        }
    }

    const setClientSelectStatus = (event) => {
         if (event.target.name == "NoRegistrados") {
            setClientNoRegistrados(event.target.checked);
        } else if (event.target.name == "Freemium") {
            setClientFreemiumState(event.target.checked);
        } else if (event.target.name == "Lite") {
            setClientLiteState(event.target.checked);
        } else if (event.target.name == "Profesional") {
            setClientProfesionalState(event.target.checked);
        } else if (event.target.name == "Premium") {
            setClientPremiumState(event.target.checked);
        }
    }

    //CAMBIA EL ESTADO DEL TARGET SI ES CHECK O NO
    function handleCheckedProductTypeTarget(e, id, uo) {
        e.preventDefault(); 
        if (uo == "U-ERRE") {
            let newObject = [...categoryProductsMemberships];
            for (let i = 0; i < newObject.length; i++) {
                if (newObject[i].id == id) {
                    newObject[i].status = (e.target.checked) ? true : false;
                }
            }
             setCategoryProductsMemberships(() => newObject);
        } else if (uo == "UNID") {
            let newObject = [...categoryProductsCourses];
            for (let i = 0; i < newObject.length; i++) {
                 if (newObject[i].id == id) {
                     newObject[i].status = e.target.checked;
                }
            }
             setCategoryProductsCourses(newObject);
        } else if (uo == "Aliados") {
            let newObject = [...categoryProductsAliados];
            for (let i = 0; i < newObject.length; i++) {
                if (newObject[i].id == id) {
                    newObject[i].status = e.target.checked;
                }
            }
             setCategoryProductsAliados(newObject);
        }
     }

    //AGREGA NUEVO VALOR DEL CUPON
    const handleChangeDiscountValue = (event) => {
        if (/^[0-9]*$/.test(event.target.value)) {
            setvalueDiscount(event.target.value);
         }
    }

    //AGREGA NUEVO VALOR NOMBRE DE DESCUENTO
    const handleChangeDiscountNameValue = (event) => {
        setDiscountName(event.target.value);
     }

    //AGREGA NUEVO VALOR CODIGO DE DESCUENTO
    const handleChangeDiscountCode = (event) => {
        if (!event.target.value.match(/[^a-zA-Z0-9]/g)) {
            setCodeDiscount(event.target.value);
         } else {
            log("omite caracteres especiales");
        }
    }

    //AGREGA NUEVO VALOR A LA DESCRIPCIÓN DEL CUPÓN
    const handleChangeDiscountDescriptionValue = (event) => {
        const valueDescription = event.target.value
        setDiscountDescription(valueDescription);
     }

    //AGREGA NUEVO VALOR A LA CANTIDAD DE CUPONES
    const handleChangeDiscountQuantityValue = (event) => {
        if (/^[-0-9]*$/.test(event.target.value)) {
            setLimitUseGlobal(event.target.value);
         }
    }

    //CIERRA LA CAJA DE ESTADO SUCCESS/ERROR
    const closeBoxState = (event) => {
        event.preventDefault();
        setActionState(null);
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
             if (addNewCustomClient.current.value.includes(',')) {
                let arrayOfEmails = addNewCustomClient.current.value.split(",");
                let confirmEmails = [];
                for (const email of arrayOfEmails) {
                    if (regExp.test(email.trim())) {
                         if (clientsInclude) {
                            if (clientsInclude.filter(function (e) { return e.name === email.trim(); }).length > 0) {
                             } else {
                                confirmEmails.push({ name: email.trim() });
                            }
                        } else {
                            confirmEmails.push({ name: email.trim() });
                        }
                    } else {
                        log("You have entered an invalid email address!:", email.trim());
                    }
                }
                const newItems = clientsInclude ? clientsInclude.concat(confirmEmails) : confirmEmails;
                setClientsInclude([...newItems]);
                addNewCustomClient.current.blur();
            } else {
                if (regExp.test(addNewCustomClient.current.value)) {
                     if (clientsInclude && clientsInclude.length > 0) {
                        if (clientsInclude.filter(function (e) { return e.name === addNewCustomClient.current.value.trim(); }).length > 0) {
                        } else {
                            let items = clientsInclude;
                            items.push({ name: addNewCustomClient.current.value.trim() });
                            setClientsInclude([...items]);
                            addNewCustomClient.current.blur();
                        }
                    } else {
                        let items = [];
                        items.push({ name: addNewCustomClient.current.value.trim() });
                        setClientsInclude([...items]);
                        addNewCustomClient.current.blur();
                    }
                } else {
                    log("You have entered an invalid email address!");
                }
            }
        }
    }

        //CREA UN NUEVO CUPÓN
        const setCoupon = async (e) => {
            e.preventDefault();
            if (validate()) {
                setLoadingButton(true);
                setErrorMessage(null);
                setActionState(null);
     
                const fullStartDate = new Date(moment.utc(startDate + " " + startHour));
                const fullEndDate = new Date(moment.utc(endDate + " " + endHour));
     
                //AGREGA LA INFORMACIÓN DEL CUPÓN EN EL BODY PARA ACTUALIZAR EN EL SERVIDOR
                const body = {
                    is_active: stateDiscount ? 1 : 0,
                    code: codeDiscount,
                    name: discountName,
                    usage_limit: Number(limitUseGlobal),
                    limit_usage_by_person: Number(valueLimitClient),
                    start_date: fullStartDate,
                    end_date: endDateStatus ? fullEndDate : null,
                    discount_percentage: null,
                    discount_amount: null,
                    value: Number(valueDiscount),
                    minQuantity: 0,
                    maxQuantity: 0,
                    minAmount: 0,
                    maxAmount: 0,
                    description: discountDescription + `//Promoción para::${typePromocion}`,
                    categoryProductsMemberships: JSON.stringify(categoryProductsMemberships),
                    categoryProductsCourses: JSON.stringify(categoryProductsCourses),
                    categoryProductsAliados: JSON.stringify(categoryProductsAliados),
                    productsSelectedExclude: JSON.stringify(productsSelectedExclude),
                    productsSelectedInclude: JSON.stringify(productsSelectedInclude),
                    clientsInclude: JSON.stringify(clientsInclude),
                    clientsGroupInclude: JSON.stringify({ clientNoRegisterState: clientNoRegistrados, clientFreemiumState, clientLiteState, clientProfesionalState, clientPremiumState }),
                    productype: typeDiscount == "Meses Gratis" ? 1 : 0,
                    discount_type: typeDiscount,
                    membership_periods: getPeriodsMembershioSelectdInString(),
                    promotion_for: typePromocion
                }
                try {
                    const coupon = await marketService.newCoupon(body);
                    setActionState(ACTION_STATE_SUCCESS);
                } catch (e) {
                    log(e);
                    setErrorMessage(e);
                    setActionState(ACTION_STATE_ERROR);
                }
                setLoadingButton(false);
            }
        }
       
    //HACE UPDATE A LA INFO DE EL CUPÓN BY ID
    const updateCoupon = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoadingButton(true);
            setErrorMessage(null);
            setActionState(null); 
            const fullStartDate = new Date(moment.utc(startDate + " " + startHour));
            const fullEndDate = new Date(moment.utc(endDate + " " + endHour));
 
            //AGREGA LA INFORMACIÓN DEL CUPÓN EN EL BODY PARA ACTUALIZAR EN EL SERVIDOR
             const body = {
                id: Number(couponId),
                is_active: stateDiscount ? 1 : 0,
                code: codeDiscount,
                name: discountName,
                usage_limit: Number(limitUseGlobal),
                limit_usage_by_person: Number(valueLimitClient),
                start_date: fullStartDate,
                end_date: endDateStatus ? fullEndDate : null,
                discount_percentage: null,
                discount_amount: null,
                value: Number(valueDiscount),
                minQuantity: 0,
                maxQuantity: 0,
                minAmount: valueLimitMin,
                maxAmount: 0,
                description: (discountDescription.includes('::Cursos') || discountDescription.includes('::Membresía')) ? discountDescription : discountDescription + `//Promoción para::${typePromocion}`,
                categoryProductsMemberships: JSON.stringify(categoryProductsMemberships),
                categoryProductsCourses: JSON.stringify(categoryProductsCourses),
                categoryProductsAliados: JSON.stringify(categoryProductsAliados),
                productsSelectedExclude: JSON.stringify(productsSelectedExclude),
                productsSelectedInclude: JSON.stringify(productsSelectedInclude),
                clientsInclude: JSON.stringify(clientsInclude),
                clientsGroupInclude: JSON.stringify({ clientNoRegisterState: clientNoRegistrados, clientFreemiumState, clientLiteState, clientProfesionalState, clientPremiumState }),
                productype: typeDiscount == "Meses Gratis" ? 1 : 0,
                discount_type: typeDiscount,
                membership_periods: getPeriodsMembershioSelectdInString(),
                promotion_for: typePromocion
            }
            try {
                const coupon = await marketService.updateCoupon(couponId, body);
                setActionState(ACTION_STATE_SUCCESS);
            } catch (e) {
                log(e);
                setErrorMessage(e);
                setActionState(ACTION_STATE_ERROR);
            }
            setLoadingButton(false);
        }
    }

    const getPeriodsMembershioSelectdInString = () => {
        let periodsSelected = [];
        for (let index = 0; index < membershipPeriods.length; index++) {
            const period = membershipPeriods[index];
            if (period.status === true) {
                periodsSelected.push(period.name)
            }
        }
        return periodsSelected.join(', ')
    }  

    const getMembershipSelectedLabel = () => {
        if (typePromocion === 'Membresía' && categoryProductsMemberships.length > 0) {
            // TIPO DE PROMOCIÓN PARA MEMBRESIA
            const STATUS_MEMBRESHIP_LITE = categoryProductsMemberships[0].status;
            const STATUS_MEMBRESHIP_PROFETIONAL = categoryProductsMemberships[1].status;
            const STATUS_MEMBRESHIP_PREMIUM = categoryProductsMemberships[2].status;
            if ( STATUS_MEMBRESHIP_LITE && STATUS_MEMBRESHIP_PROFETIONAL && STATUS_MEMBRESHIP_PREMIUM) {
                return "En cualquier Membresía";
            } else if (!STATUS_MEMBRESHIP_LITE && !STATUS_MEMBRESHIP_PROFETIONAL && !STATUS_MEMBRESHIP_PREMIUM) {
                return "Ninguna membresía seleccinada"
            } else {
                let label_membership_selected = [];
                for (let index = 0; index < categoryProductsMemberships.length; index++) {
                    const membreshipSelected = categoryProductsMemberships[index];
                    if(membreshipSelected.status === true) {
                        label_membership_selected.push(membreshipSelected.type)
                    }
                }
                return "En la "+ label_membership_selected.join(' y ');
            } 
        } else {
            return `${productTarget} (Cursos)`
        }
        return "";
    }

    const getTypeDiscountLabel = () => {
        switch (typeDiscount) {
            case 'Monto fijo':
                    return `$ ${valueDiscount} de descuento`;
                break;
            case 'Porcentaje':
                    return `${valueDiscount} % de descuento`;
                break;
            default: // free mounts
                    return valueDiscount == 1 ? ` ${valueDiscount} Mes Gratis` : `${valueDiscount} Meses Gratis`;
                break;
        }
    }
    
    const getPeriodsSelectedLabel = () => {
        const STATUS_PERIOD_ONE_MONTH = membershipPeriods[0].status
        const STATUS_PERIOD_TRI_MONTHS= membershipPeriods[1].status
        const STATUS_PERIOD_SIX_MONTHS= membershipPeriods[2].status
        const STATUS_PERIOD_TWELVE_MONTHS= membershipPeriods[3].status
        if(STATUS_PERIOD_ONE_MONTH && STATUS_PERIOD_SIX_MONTHS && STATUS_PERIOD_TWELVE_MONTHS && STATUS_PERIOD_TRI_MONTHS ){
            return 'En la compra de cualquier periodo';
        } else if(!STATUS_PERIOD_ONE_MONTH && !STATUS_PERIOD_SIX_MONTHS && !STATUS_PERIOD_TWELVE_MONTHS && !STATUS_PERIOD_TRI_MONTHS){
            return 'Ningun periodo selecionado';
        } else {
            let label_periods_selected = [];
            for (let index = 0; index < membershipPeriods.length; index++) {
                const periodsSelected = membershipPeriods[index];
                if(periodsSelected.status === true) {
                    label_periods_selected.push(periodsSelected.name)
                }
            }
            return "En la compra de "+ label_periods_selected.join(' y ');
        }
        
    }

    const validate = () => {
        const _errors = {};
        const requiredFields = ['discount_name_value', 'discount_code', 'discount_description_name', 'startDate', 'startHour', 'discount_quantity_value', 'discount_value'];

        for (const field of requiredFields) {
            if (discountName == "" && field == 'discount_name_value') {
                _errors[field] = 'Campo requerido';
            } else if (codeDiscount == "" && field == 'discount_code') {
                _errors[field] = 'Campo requerido';
            } else if (discountDescription == "" && field == 'discount_description_name') {
                _errors[field] = 'Campo requerido';
            } else if (startDate == "" && field == 'startDate') {
                _errors[field] = 'Campo requerido';
            } else if (startHour == "" && field == 'startHour') {
                _errors[field] = 'Campo requerido';
            } else if (limitUseGlobal == 0 && field == 'discount_quantity_value') {
                _errors[field] = 'Campo requerido';
            } else if (valueDiscount == 0 && field == 'discount_value') {
                _errors[field] = 'Campo requerido';
            }
        }
        setErrors({ ..._errors });
        return Boolean(Object.keys(_errors).length === 0);
    };

    return (
        <>
            <Breadcrumb items={items} separator="/" />
            {/* TITULO DE LA PAGINA SI ES CREAR O EDITAR */}
            {/*<Typography variant="h3" style={{ fontWeight: "600", marginTop: "24px", marginBottom: "18px", textTransform: "capitalize"}}>{pageName}</Typography>*/}
            {/* PRIMERA OPCIÓN */}
            <Loader loading={loading} style={{ marginTop: loading ? "150px" : "initial" }}>
                {!loading && <>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mb={!actionState ? "24px" : "16px"}>
                        {/* INPUT ESCRIBIR NOMBRE */}
                        <Box maxWidth="200px" marginBottom="0px">
                            <TextField
                                required
                                error={Boolean(errors.discount_name_value)}
                                helperText={errors.discount_name_value}
                                fontWeight="700"
                                disableUnderline="true"
                                className={clsx(classes.inputMaterial)}
                                style={{
                                    marginTop: "0px",
                                }}
                                inputProps={{
                                    variant: "h5",
                                    className: clsx(classes.customTextFieldName, classes.borderFocus)
                                }}
                                onChange={event => handleChangeDiscountNameValue(event)}
                                value={discountName}
                                fullWidth
                                placeholder="Nombre de la promoción"
                                name="discount_name_value"
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            {/* ICONO VALIDACION DE NOMBRE */}
                            <Icon style={{ marginLeft: "14px", marginRight: "23px" }} className={clsx("text-violet-300", "ri-check-line")} fontSize='medium' />
                            {/* SWITCH ACTIVO/INACTIVO */}
                            <Switch
                                checked={stateDiscount}
                                onChange={handleChangeStatusDiscount}
                                color="primary"
                                name="status"
                            />
                            <Typography className={clsx("text-white")} variant="body2">{stateDiscountLabel}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="flex-start" ml="auto" mr="30px">
                            <Button variant="contained" color="primary" style={{ minWidth: "188px", maxHeight: "40px" }} onClick={event => typeDatail == PATH_NAME_DETECTOR_FOR_SHOW_INFO[0] ? setCoupon(event) : updateCoupon(event)} >
                                <Typography variant="body2" style={{ fontWeight: "600", textTransform: "initial" }}>{loadingButton ? <CircularProgress style={{ width: "25px", height: "25px" }} color="primary" /> : (typeDatail == PATH_NAME_DETECTOR_FOR_SHOW_INFO[0] ? "Crear" : "Guardar")}</Typography>
                            </Button>
                        </Box>

                    </Box>

                    <Box mr="30px">
                        { /* CAJA DE ESTADOS AL CREAR-EDITAR */}
                        <ActionState style={{ marginRight: "30px" }} type={pageName} state={actionState} onClose={closeBoxState} errorMessage={errorMessage} couponCode={codeDiscount} />
                    </Box>

                    <Box justifyContent="space-arrownd" display="flex" flexDirection="row" mt={actionState ? "16px" : "0px"}>
                        <Box container justifyContent="center" className="ml-0" maxWidth="770px" width="100%" margin="31px" marginTop="0px">
                            {/* INPUT PARA CÓDIGO */}
                            <BoxContent value={
                                <>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant='h5' style={{ marginBottom: "6px" }}>
                                                {"Código de descuento"}
                                            </Typography> 
                                        </Box>
                                        <Typography className="text-fill-stroke-100" variant='button' fontWeight="400" style={{ marginBottom: "32px" }}>
                                            {"Los usuarios introducirán este descuento en su pantalla de pago"}
                                        </Typography>
                                    </Box>
                                    <Box display="flex">
                                        <TextField
                                            required
                                            error={Boolean(errors.discount_code)}
                                            helperText={errors.discount_code}
                                            disableUnderline="true"
                                            className={clsx(classes.customTextFieldCode, "bg-fill-stroke-400")}
                                            inputProps={{
                                                variant: "outlined"
                                            }}
                                            fullWidth
                                            placeholder="Escribir un código"
                                            name="discount_code"
                                            onChange={event => handleChangeDiscountCode(event)}
                                            value={codeDiscount}
                                            endAdornment={<InputAdornment position="end"><Icon className={clsx("text-dark-black-200", "ri-ticket-2-line")} fontSize='small' /></InputAdornment>}
                                        />
                                        {!illimitedUse && <Box display="flex" alignItems="center">
                                            <Typography variant='body2' className='text-gray-500' style={{ marginRight: "12px", marginLeft: "32px" }}>
                                                {"Cupones disponibles:"}
                                            </Typography>
                                            <Box maxWidth="114px" width="100%">
                                                {/* CANTIDAD TOTAL DE CUPONES DISPONIBLE */}
                                                <TextField
                                                    required
                                                    error={Boolean(errors.discount_quantity_value)}
                                                    helperText={errors.discount_quantity_value}
                                                    disableUnderline="true"
                                                    className={clsx(classes.customTextFieldCode)}
                                                    inputProps={{
                                                        variant: "outlined"
                                                    }}
                                                    value={limitUseGlobal}
                                                    onChange={event => handleChangeDiscountQuantityValue(event)}
                                                    fullWidth
                                                    placeholder="0"
                                                    name="discount_quantity_value"
                                                />
                                            </Box>
                                        </Box>}
                                    </Box>
                                    {/* ESTADO DE LIMITE POR CLIENTE */}
                                    <FormControlLabel
                                        style={{ marginBottom: "20px", marginTop: "24px" }}
                                        control={
                                            <Checkbox
                                                checked={illimitedUse}
                                                onChange={handleChangeLimitUse}
                                                name="limitUses"
                                                color="primary"
                                                icon={<CircleUnchecked />}
                                                checkedIcon={<CircleCheckedFilled />}
                                            />
                                        }
                                        label="Disponibilidad ilimitada"
                                    />
                                    {/* ESTADO DE LIMITE POR CLIENTE */}
                                    <FormControlLabel
                                        style={{ marginBottom: "20px", marginTop: "24px" }}
                                        control={
                                            <Checkbox
                                                checked={limitUseClient}
                                                onChange={handleChangeLimit}
                                                name="limitUseClient"
                                                color="primary"
                                                icon={<CircleUnchecked />}
                                                checkedIcon={<CircleCheckedFilled />}
                                            />
                                        }
                                        label="Limitar las veces que puede ser usado por un usuario"
                                    />
                                    {limitUseClient && <Box display="flex" flexDirection="column">
                                        <Typography variant='h6' style={{ marginBottom: "6px" }}>
                                            {"Cantidad de cupones disponibles por un usuario"}
                                        </Typography>
                                        {<Box maxWidth="114px" width="100%">
                                            {/* VALOR DE LIMITE POR CLIENTE */}
                                            <TextField
                                                required
                                                error={Boolean(errors.limit_value)}
                                                helperText={errors.limit_value}
                                                disableUnderline="true"
                                                className={clsx(classes.customTextFieldCode)}
                                                inputProps={{
                                                    variant: "outlined"
                                                }}
                                                onChange={event => handleChangeLimitValue(event)}
                                                value={valueLimitClient}
                                                fullWidth
                                                placeholder="0"
                                                name="limit_value"
                                            />
                                        </Box>}
                                    </Box>}
                                </>
                            } />
                            {/** PROMOCIÓN PARA*/}
                            <BoxContent value={
                                <>
                                    <Box display="flex" flexDirection="column" style={{ marginBottom: "20px" }}>
                                        <Typography variant='h5' style={{ marginBottom: "32px" }}>
                                            {"Promoción para:"}
                                        </Typography>
                                        <Select
                                            items={[
                                                "Membresía",
                                                "Cursos"
                                            ]}
                                            onChange={event => handleChangeTypePromocion(event)}
                                            value={typePromocion}
                                            margin="dense" style={{ maxWidth: "340px", width: "100%", marginTop: "0px" }}
                                        />
                                    </Box>
                                </>
                            } mt="16px" />

                            {/** VALIDAR TIPO DE PROMOCION CURSOS/MEMBRESIAS*/}
                            <BoxContent value={
                                <>
                                    {
                                        typePromocion === 'Cursos' && 
                                         <Box display="flex" flexDirection="column" style={{ marginBottom: "20px" }}>
                                           <Typography variant='h5' style={{ marginBottom: "32px" }}>
                                               {"Aplica a todos los cursos"}
                                           </Typography>
                                            {/*<Select
                                               items={[
                                                   "Todos",
                                                   "Categorías de productos",
                                                   "Productos específicos"
                                               ]}
                                               onChange={event => handleChangeProductTarget(event)}
                                               value={productTarget}
                                               margin="dense" style={{ maxWidth: "340px", width: "100%", marginTop: "0px" }}
                                            />*/}
                                        </Box>
                                    }
                                    {
                                        (typePromocion === 'Membresía') &&
                                        <Box display="flex" flexDirection="column">
                                            <Box display="flex" style={{ marginBottom: "32px" }}>
                                                {<Box display="flex" flexDirection="column" minWidth="225px" minHeight="104px">
                                                    <Typography variant='h5' style={{ marginBottom: "18px" }}>
                                                        {"Membresías"}
                                                    </Typography>
                                                    {
                                                        categoryProductsMemberships.map(item => (
                                                            <Box key={`categoryProductsMemberships-${item.id}`} display="flex" alignItems="center" marginBottom="12px">
                                                                <Checkbox name={`UERRE-${item.id}`} checked={item.status} onClick={event => handleCheckedProductTypeTarget(event, item.id, "U-ERRE")} style={{ padding: 0, paddingRight: "5px" }} />
                                                                <Typography variant='body2' className='text-dark-black-200'>
                                                                    {item.type}
                                                                </Typography>
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>}
                                            </Box>
                                        </Box>
                                    }
                                    {(typePromocion === "Cursos" && productTarget == "Categorías de productos") ? <Box display="flex" flexDirection="column">
                                        <Box display="flex" style={{ marginBottom: "32px" }}>
                                            {categoryProductsCourses && categoryProductsCourses.length > 0 && typeDiscount != "Meses Gratis" && <Box display="flex" flexDirection="column" minWidth="225px" minHeight="104px">
                                                <Typography variant='h5' style={{ marginBottom: "18px" }}>
                                                    {"Cursos"}
                                                </Typography>
                                                {
                                                    categoryProductsCourses.map(item => (
                                                        <Box key={`categoryProductsCourses-${item.id}`} display="flex" alignItems="center" marginBottom="12px">
                                                            <Checkbox name={`UNID-${item.id}`} checked={item.status} onClick={event => handleCheckedProductTypeTarget(event, item.id, "UNID")} style={{ padding: 0, paddingRight: "5px" }} />
                                                            <Typography variant='body2' className='text-dark-black-200'>
                                                                {item.type}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>}
                                            {categoryProductsAliados && categoryProductsAliados.length > 0 && <Box display="flex" flexDirection="column" minWidth="225px" minHeight="104px">
                                                <Typography variant='h5' style={{ marginBottom: "18px" }}>
                                                    {"Aliados"}
                                                </Typography>
                                                {
                                                    categoryProductsAliados.map(item => (
                                                        <Box key={`categoryProductsAliados-${item.id}`} display="flex" alignItems="center" marginBottom="12px">
                                                            <Checkbox name={`Aliados-${item.id}`} checked={item.status} onClick={event => handleCheckedProductTypeTarget(event, item.id, "Aliados")} style={{ padding: 0, paddingRight: "5px" }} />
                                                            <Typography variant='body2' className='text-dark-black-200'>
                                                                {item.type}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>}
                                        </Box>


                                        {productTarget == "Productos específicos" && <>
                                            <Typography variant='h5' style={{ marginBottom: "12px" }}>
                                                {"Excluir productos"}
                                            </Typography>
                                            <Typography variant='body2' className='text-dark-black-200' style={{ marginBottom: "32px" }}>
                                                {"Selecciona productos específicos de la(s) categoría(s) que seleccionaste previamente, que quieres que queden fuera de la promoción."}
                                            </Typography>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                limitTags={2}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                defaultValue={productsSelectedExclude}
                                                options={productsExcludeOriginal}
                                                getOptionLabel={(option) => option.name}
                                                renderTags={(tagValue, getTagProps) =>
                                                    tagValue.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            style={{ backgroundColor: theme.palette.colors.fillStroke[200], color: "white", maxWidth: "200px" }}
                                                            label={option.name}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                filterOptions={(options, { inputValue }) => options.filter(item => item.name.includes(inputValue) || JSON.stringify(item.id).includes(inputValue))}
                                                onChange={(e, value, reason, option) => {
                                                    if (reason === "select-option") {
                                                        let selected = productsSelectedExclude;
                                                        selected.push({ id: option.option.id, name: option.option.name });
                                                        setProductsSelectedExclude(selected);
                                                    } else if (reason === "remove-option") {
                                                        let selected = productsSelectedExclude;
                                                        selected.splice(selected.findIndex(item => item.id === option.option.id), 1)
                                                        setProductsSelectedExclude(selected);
                                                    } else if (reason === "clear") {
                                                        setProductsSelectedExclude([]);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={valueRefExclude}
                                                        variant="outlined"
                                                        className={classes.TextFieldAddProducts}
                                                        placeholder="Escribe el nombre o el id de producto y selecciona para agregar"
                                                    />
                                                )}
                                            />
                                        </>}

                                    </Box>
                                        : (typePromocion == "Cursos" && productTarget == "Productos específicos") && <Box display="flex" flexDirection="column" style={{ marginBottom: "20px" }}>
                                            <Typography variant='h5' style={{ marginBottom: "3px" }}>
                                                {"Producto(s)"}
                                            </Typography>
                                            <Typography variant='body2' className='text-dark-black-200' style={{ marginBottom: "12px" }}>
                                                {"Selecciona los productos que serán parte de la promoción."}
                                            </Typography>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                limitTags={2}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                defaultValue={productsSelectedInclude}
                                                options={productsExcludeOriginal}
                                                getOptionLabel={(option) => option.name}
                                                renderTags={(tagValue, getTagProps) =>
                                                    tagValue.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            style={{ backgroundColor: theme.palette.colors.fillStroke[200], color: "white", maxWidth: "200px" }}
                                                            label={option.name}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                filterOptions={(options, { inputValue }) => options.filter(item => item.name.includes(inputValue) || JSON.stringify(item.id).includes(inputValue))}
                                                onChange={(e, value, reason, option) => {
                                                    if (reason === "select-option") {
                                                        let selected = productsSelectedInclude;
                                                        selected.push({ id: option.option.id, name: option.option.name });
                                                        setProductsSelectedInclude(selected);
                                                    } else if (reason === "remove-option") {
                                                        let selected = productsSelectedInclude;
                                                        selected.splice(selected.findIndex(item => item.id === option.option.id), 1)
                                                        setProductsSelectedInclude(selected);
                                                    } else if (reason === "clear") {
                                                        setProductsSelectedInclude([]);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={valueRefInclude}
                                                        variant="outlined"
                                                        className={classes.TextFieldAddProducts}
                                                        placeholder="Escribe el nombre o el id de producto y selecciona para agregar"
                                                    />
                                                )}
                                            />

                                        </Box>

                                    }
                                </>
                            } mt="16px" />

                            {/* INPUT TIPO DE DESCUENTO */}
                            <BoxContent value={
                                <>
                                    <Typography variant='h5' style={{ marginBottom: "32px" }}>
                                        {"Tipo de descuento"}
                                    </Typography>
                                    <Box  display="flex" flexDirection="row">
                                        <Box display="flex" flexDirection="column" width={3/5}>
                                            <Typography className="text-fill-stroke-100" variant='button' fontWeight="400">
                                                {typeDiscount == "Meses Gratis" ? "Ofrece meses gratís en membresías en la compra de una suscripción." : (typeDiscount === "Monto fijo") ? "Descuenta una cantida fija en pesos. (ej: $100 de descuento)" : "Aplica un porcentaje de descuento. (ej. 50% de descuento)"}
                                            </Typography>
                                            <Select
                                                required
                                                name="discount_type"
                                                error={Boolean(errors.discount_type)}
                                                helperText={errors.discount_type}
                                                items={selectTypeDiscount}
                                                onChange={event => handleChangeDiscountType(event)}
                                                value={typeDiscount}
                                                margin="dense" style={{ maxWidth: "340px", width: "100%", }}
                                            />
                                            {typeDiscount == "Compra X, Obtén Y" && <Typography className="text-dark-black-200" variant='button' fontWeight="400" style={{ marginTop: "12px" }}>
                                                {"En la compra de un producto o cantidad mínima de compra, se aplica un descuento en porcentaje o cantidad en un segundo producto o categoría de productos (ej. Compra 1 curso y obtén gratis el segundo. O bien, compra $1000 y obtén $100 de descuento en otro producto)"}
                                            </Typography>}
                                        </Box> 
                                        <Box display="flex" flexDirection="column" width={2/5}> 
                                            <Typography className="text-fill-stroke-100" variant='button' fontWeight="400" style={{ marginBottom: "8px" }}>
                                                {typeDiscount == "Meses Gratis" ? "Cantidad meses gratis" : (typeDiscount === "Monto fijo") ? "Valor de descuento" : "Porcentaje de descuento"}
                                            </Typography>
                                            <Box maxWidth="114px" width="100%">
                                                <TextField
                                                    required
                                                    error={Boolean(errors.discount_value)}
                                                    helperText={errors.discount_value}
                                                    disableUnderline="true"
                                                    className={clsx(classes.customTextFieldCode)}
                                                    inputProps={{
                                                        variant: "outlined"
                                                    }}
                                                    value={valueDiscount}
                                                    onChange={event => handleChangeDiscountValue(event)}
                                                    fullWidth
                                                    placeholder="0"
                                                    name="discount_value"
                                                    endAdornment={<InputAdornment position="end"><Icon className={clsx("text-fill-stroke-100", typeDiscount == "Monto fijo" ? "ri-money-dollar-box-line" : typeDiscount == "Porcentaje" && "ri-percent-line")} fontSize='small' /></InputAdornment>}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            } mt="16px" />

                            {/* INPUT VALOR */}
                            {typeDiscount == "Compra X, Obtén Y" && <BoxContent value={
                                <>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant='h5' style={{ marginBottom: "30px" }}>
                                                {"En la compra de:"}
                                            </Typography>
                                        </Box>
                                        <FormControl component="fieldset" style={{ marginBottom: "30px" }}>
                                            <RadioGroup aria-label="compraDe" name="compraDe1" value={valueCompraDe} onChange={handleChangeCompraDe}>
                                                <FormControlLabel value="Una cantidad mínima de productos" control={<Radio color='primary' />} label="Una cantidad mínima de productos" />
                                                <FormControlLabel value="Un monto mínimo de compra" control={<Radio color='primary' />} label="Un monto mínimo de compra" />
                                            </RadioGroup>
                                        </FormControl>

                                        <Box display="flex" style={{ marginBottom: "32px" }}>
                                            <Box display="flex" style={{ marginRight: "16px" }}>
                                                <Box maxWidth="114px" width="100%">
                                                    <Typography variant='body2' className='text-gray-500' style={{ marginBottom: "12px" }}>
                                                        {"Cantidad"}
                                                    </Typography>
                                                    {/* CANTIDAD TOTAL DE CUPONES DISPONIBLE */}
                                                    <TextField
                                                        required
                                                        error={Boolean(errors.discount_quantity_value)}
                                                        helperText={errors.discount_quantity_value}
                                                        disableUnderline="true"
                                                        className={clsx(classes.customTextFieldCode)}
                                                        inputProps={{
                                                            variant: "outlined"
                                                        }}
                                                        value={limitUseGlobal}
                                                        onChange={event => handleChangeDiscountQuantityValue(event)}
                                                        fullWidth
                                                        placeholder="0"
                                                        name="discount_quantity_value"
                                                    />
                                                </Box>
                                            </Box>

                                            <Box display="flex">
                                                <Box maxWidth="463px" width="100%">
                                                    <Typography variant='body2' className='text-gray-500' style={{ marginBottom: "12px" }}>
                                                        {"Cualquier artículo de"}
                                                    </Typography>
                                                    <Select
                                                        items={[
                                                            "Categorías de productos",
                                                            "Productos específicos"
                                                        ]}
                                                        onChange={event => handleChangeProductTarget(event)}
                                                        value={productTarget}
                                                        margin="dense"
                                                        style={{ width: "463px", marginTop: "0px" }}
                                                    />
                                                </Box>
                                            </Box>

                                        </Box>

                                        {<>
                                            <Typography variant='h5' style={{ marginBottom: "12px" }}>
                                                {"Excluir productos"}
                                            </Typography>
                                            <Typography variant='body2' className='text-dark-black-200' style={{ marginBottom: "32px" }}>
                                                {"Selecciona productos específicos de la(s) categoría(s) que seleccionaste previamente, que quieres que queden fuera de la promoción."}
                                            </Typography>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                limitTags={2}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                defaultValue={productsSelectedExclude}
                                                options={productsExcludeOriginal}
                                                getOptionLabel={(option) => option.name}
                                                renderTags={(tagValue, getTagProps) =>
                                                    tagValue.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            style={{ backgroundColor: theme.palette.colors.fillStroke[200], color: "white", maxWidth: "200px" }}
                                                            label={option.name}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                filterOptions={(options, { inputValue }) => options.filter(item => item.name.includes(inputValue) || JSON.stringify(item.id).includes(inputValue))}
                                                onChange={(e, value, reason, option) => {
                                                    if (reason === "select-option") {
                                                        let selected = productsSelectedExclude;
                                                        selected.push({ id: option.option.id, name: option.option.name });
                                                        setProductsSelectedExclude(selected);
                                                    } else if (reason === "remove-option") {
                                                        let selected = productsSelectedExclude;
                                                        selected.splice(selected.findIndex(item => item.id === option.option.id), 1)
                                                        setProductsSelectedExclude(selected);
                                                    } else if (reason === "clear") {
                                                         setProductsSelectedExclude([]);
                                                    }
                                                 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={valueRefExclude}
                                                        variant="outlined"
                                                        className={classes.TextFieldAddProducts}
                                                        placeholder="Escribe el nombre o el id de producto y selecciona para agregar"
                                                    />
                                                )}
                                            />
                                        </>}

                                        <Typography variant='h6' style={{ marginBottom: "12px", marginTop: "44px" }}>
                                            {"Valor de descuento"}
                                        </Typography>
                                        <Box maxWidth="114px" width="100%">
                                            <TextField
                                                required
                                                error={Boolean(errors.discount_value)}
                                                helperText={errors.discount_value}
                                                disableUnderline="true"
                                                className={clsx(classes.customTextFieldCode)}
                                                inputProps={{
                                                    variant: "outlined"
                                                }}
                                                value={valueDiscount}
                                                onChange={event => handleChangeDiscountValue(event)}
                                                fullWidth
                                                placeholder="0"
                                                name="discount_value"
                                                endAdornment={<InputAdornment position="end"><Icon className={clsx("text-fill-stroke-100", typeDiscount == "Monto fijo" ? "ri-money-dollar-box-line" : typeDiscount == "Porcentaje" && "ri-percent-line")} fontSize='small' /></InputAdornment>}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            } mt="16px" />
                            }
                            {/* CHECK PERIOD MEMBRESHIP */}
                            {(typePromocion === 'Membresía') &&
                                <BoxContent value={
                                    <>
                                        <Typography variant='h5'>
                                            {"En la compra:"}
                                        </Typography>
                                        <Box display="flex" flexDirection="column">
                                            <Box display="flex">
                                                {<Box display="flex" flexDirection="row" minWidth="225px" minHeight="90px">
                                                    {
                                                        membershipPeriods.map(item => (
                                                            <Box key={`periods-${item.id}`} display="flex" alignItems="center" marginRight="50px">
                                                                <Checkbox id={item.id} name={item.name} checked={item.status} onClick={(e) => handleCheckChangePeriodMembership(e)} style={{ padding: 0, paddingRight: "5px" }} />
                                                                <Typography variant='body2' className='text-dark-black-200' >
                                                                    {item.name}
                                                                </Typography>
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>}
                                            </Box>
                                        </Box>
                                    </>
                                } mt="16px" />
                            }

                            {/* INPUT DESCRIPCIÓN DEL DESCUENTO */}
                            <BoxContent value={
                                <>

                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant='h5' style={{ marginBottom: "6px" }}>
                                                {"Descripción"}
                                            </Typography>
                                        </Box>
                                        <Typography className="text-fill-stroke-100" variant='button' fontWeight="400" style={{ marginBottom: "32px" }}>
                                            {"Describe brevemente el beneficio del cupón"}
                                        </Typography>
                                    </Box>

                                    <TextField
                                        required
                                        error={Boolean(errors.discount_description_name)}
                                        helperText={errors.discount_description_name}
                                        disableUnderline="true"
                                        className={clsx(classes.customTextFieldCode)}
                                        style={{ maxWidth: "100%" }}
                                        inputProps={{
                                            variant: "outlined"
                                        }}
                                        onChange={event => handleChangeDiscountDescriptionValue(event)}
                                        value={discountDescription}
                                        fullWidth
                                        placeholder="Escribir una descripción"
                                        name="discount_description_name"
                                    />
                                </>
                            } mt="16px" />
                            {/* INPUTS FECHAS DE VIGENCIA */}
                            <BoxContent value={
                                <>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant='h5' style={{ marginBottom: "6px" }}>
                                                {"Periodo de duración"}
                                            </Typography>
                                        </Box>
                                        <Typography className="text-fill-stroke-100" variant='button' fontWeight="400" style={{ marginBottom: "32px" }}>
                                            {""}
                                        </Typography>
                                    </Box>

                                    <Box display="flex">
                                        <Box display="flex" flexDirection="column" style={{ maxWidth: "340px", width: "100%", marginRight: "12px" }}>
                                            <Typography className="text-white" variant='h5' style={{ marginBottom: "12px", fontWeight: "600", fontSize: "14px" }}>
                                                {"Fecha de inicio"}
                                            </Typography>
                                            <Box>
                                                {/* FECHA DE INICIO */}
                                                <TextField
                                                    required
                                                    error={Boolean(errors.startDate)}
                                                    helperText={errors.startDate}
                                                    type='date'
                                                    disableUnderline="true"
                                                    className={clsx(classes.customTextFieldCode)}
                                                    inputProps={{
                                                        variant: "outlined"
                                                    }}
                                                    name="startDate"
                                                    defaultValue={startDate}
                                                    value={startDate}
                                                    onChange={event => handleChangeDateTimeValue(event)}
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="column" style={{ maxWidth: "340px", width: "100%", marginLeft: "12px" }}>
                                            <Typography className="text-white" variant='h5' style={{ marginBottom: "12px", fontWeight: "600", fontSize: "14px" }}>
                                                {"Hora de inicio (Hora centro)"}
                                            </Typography>
                                            <Box>
                                                {/* HORA INICIAL */}
                                                <TextField
                                                    type='time'
                                                    disableUnderline="true"
                                                    className={clsx(classes.customTextFieldCode)}
                                                    inputProps={{
                                                        variant: "outlined",
                                                    }}
                                                    name="startHour"
                                                    value={startHour}
                                                    onChange={event => handleChangeDateTimeValue(event)}
                                                    //startAdornment={ <InputAdornment position="start"><Icon style={{ color: "transparent" }} className={clsx("ri-time-line")} fontSize='small'/></InputAdornment> }
                                                    fullWidth
                                                />
                                            </Box>

                                        </Box>
                                    </Box>
                                    <FormControlLabel
                                        style={{ marginTop: "18px", marginBottom: "24px" }}
                                        control={
                                            <Checkbox
                                                checked={endDateStatus}
                                                onChange={handleChangeEndDateTime}
                                                name="endDate"
                                                color="primary"
                                            />
                                        }
                                        label="Fijar una fecha y hora de finalización"
                                    />
                                    {endDateStatus && <Box display="flex">
                                        <Box display="flex" flexDirection="column" style={{ maxWidth: "340px", width: "100%", marginRight: "12px" }}>
                                            <Typography className="text-white" variant='h5' style={{ marginBottom: "12px", fontWeight: "600", fontSize: "14px" }}>
                                                {"Fecha de finalización"}
                                            </Typography>
                                            <Box>
                                                {/* FECHA FINAL */}
                                                <TextField
                                                    type='date'
                                                    disableUnderline="true"
                                                    className={clsx(classes.customTextFieldCode)}
                                                    inputProps={{
                                                        variant: "outlined"
                                                    }}
                                                    name="endDate"
                                                    value={endDate}
                                                    onChange={event => handleChangeDateTimeValue(event)}
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="column" style={{ maxWidth: "340px", width: "100%", marginLeft: "12px" }}>
                                            <Typography className="text-white" variant='h5' style={{ marginBottom: "12px", fontWeight: "600", fontSize: "14px" }}>
                                                {"Hora de finalización (Hora centro)"}
                                            </Typography>
                                            <Box>
                                                {/* HORA FINAL */}
                                                <TextField
                                                    type='time'
                                                    disableUnderline="true"
                                                    className={clsx(classes.customTextFieldCode)}
                                                    inputProps={{
                                                        variant: "outlined",
                                                    }}
                                                    name="endHour"
                                                    value={endHour}
                                                    onChange={event => handleChangeDateTimeValue(event)}
                                                    fullWidth
                                                />
                                            </Box>

                                        </Box>
                                    </Box>}
                                </>
                            } mt="16px" />
                            {/* INPUT CANTIDAD GLOBAL DISPONIBLE Y LIMITE POR CLIENTE */}
                            <BoxContent value={
                                <>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant='h5' style={{ marginBottom: "6px" }}>
                                                {"Elegibilidad del usuario"}
                                            </Typography>
                                        </Box>
                                        <Typography className="text-fill-stroke-100" variant='button' fontWeight="400" style={{ marginBottom: "32px" }}>
                                            {"Selecciona los tipos de usuarios en los que va a aplicar la promoción"}
                                        </Typography>
                                        <Box display="flex" flexDirection="column" style={{ marginBottom: "20px" }}>
                                            <Typography variant='body2' style={{ marginBottom: "12px", fontWeight: "600" }}>
                                                {"Usuarios"}
                                            </Typography>
                                            <Select
                                                items={[
                                                    "Todos los usuarios",
                                                    "Tipos de usuarios",
                                                    "Usuarios específicos",
                                                ]}
                                                onChange={event => handleChangeClientTarget(event)}
                                                value={clientTarget}
                                                margin="dense" style={{ maxWidth: "340px", width: "100%", marginTop: "0px" }} />
                                        </Box>

                                        {clientTarget == "Tipos de usuarios" ? <Box>
                                            <Typography variant='body2' style={{ marginBottom: "16px", fontWeight: "600" }}>
                                                {"MIEMBROS"}
                                            </Typography>
                                            <Box display="flex" alignItems="center" marginBottom="12px">
                                                <Checkbox name={`NoRegistrados`} checked={clientNoRegistrados} onChange={event => setClientSelectStatus(event)} style={{ padding: 0, paddingRight: "5px" }} />
                                                <Typography variant='body2' className='text-dark-black-200'>
                                                    {"No registrados"}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" marginBottom="12px">
                                                <Checkbox name={`Freemium`} checked={clientFreemiumState} onChange={event => setClientSelectStatus(event)} style={{ padding: 0, paddingRight: "5px" }} />
                                                <Typography variant='body2' className='text-dark-black-200'>
                                                    {"Freemium"}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" marginBottom="12px">
                                                <Checkbox name={`Lite`} checked={clientLiteState} onChange={event => setClientSelectStatus(event)} style={{ padding: 0, paddingRight: "5px" }} />
                                                <Typography variant='body2' className='text-dark-black-200'>
                                                    {"Lite"}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" marginBottom="12px">
                                                <Checkbox name={`Profesional`} checked={clientProfesionalState} onChange={event => setClientSelectStatus(event)} style={{ padding: 0, paddingRight: "5px" }} />
                                                <Typography variant='body2' className='text-dark-black-200'>
                                                    {"Profesional"}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" marginBottom="12px">
                                                <Checkbox name={`Premium`} checked={clientPremiumState} onChange={event => setClientSelectStatus(event)} style={{ padding: 0, paddingRight: "5px" }} />
                                                <Typography variant='body2' className='text-dark-black-200'>
                                                    {"Premium"}
                                                </Typography>
                                            </Box>
                                        </Box> :
                                            clientTarget == "Usuarios específicos" &&
                                            <Box>
                                                <Typography variant='h5' style={{ marginBottom: "4px" }}>
                                                    {"Usuario(s)"}
                                                </Typography>
                                                <Typography variant='body2' className='text-dark-black-200' style={{ marginBottom: "12px" }}>
                                                    {"Escribe el correo de los usuarios"}
                                                </Typography>
                                                {<Autocomplete
                                                    multiple
                                                    id="free-solo-demo"
                                                    limitTags={2}
                                                    classes={{
                                                        option: classes.option,
                                                    }}
                                                    onChange={(e, value, reason, option) => {
                                                         if (reason === "remove-option") {
                                                            if (option) {
                                                                if (option.option) {
                                                                     let selected = clientsInclude;
                                                                    selected.splice(selected.findIndex(item => {
                                                                        return item.name === option.option.name
                                                                    }), 1);
                                                                    setClientsInclude(selected.length > 0 ? selected : null);
                                                                    addNewCustomClient.current.blur();
                                                                    addNewCustomClient.current.focus();
                                                                }
                                                            }
                                                        } else if (reason === "clear") {
                                                             setClientsInclude([]);
                                                            addNewCustomClient.current.blur();
                                                            addNewCustomClient.current.focus();
                                                        }
                                                     }}
                                                     value={clientsInclude ? clientsInclude : []}
                                                    options={clientsInclude ? clientsInclude : []}
                                                    getOptionLabel={(option) => option.name}
                                                    renderTags={(tagValue, getTagProps) =>
                                                        clientsInclude.map((option, index) => (
                                                            <Chip
                                                                variant="outlined"
                                                                style={{ backgroundColor: theme.palette.colors.fillStroke[200], color: "white", maxWidth: "200px" }}
                                                                label={option.name}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputRef={addNewCustomClient}
                                                            variant="outlined"
                                                            className={classes.TextFieldAddProducts}
                                                            placeholder="Escribe el email o emails separados por coma y presiona enter para agregar"
                                                            onKeyUp={(event) => { handleKeyUp(event) }}
                                                        />
                                                    )}
                                                />}

                                            </Box>
                                        }
                                    </Box>
                                </>
                            } mt="16px" />
                        </Box>

                        {/* OPCIONES DERECHA */}
                        <Box maxWidth="392px" width="100%" margin="31px" marginTop="0px">
                            <BoxContent value={
                                <>
                                    <Typography variant='h5' fontWeight="600" className='mb-3'>
                                        {"Resumen de cupón"}
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box display="flex" flexDirection="column">
                                            {codeDiscount != "" && <Typography variant='h5' fontWeight="600" className='mb-1'>
                                                {codeDiscount}
                                            </Typography>}
                                            {discountDescription != "" && <Typography style={{ lineHeight: "12px" }} className="text-gray-500 caption-small">{discountDescription}</Typography>}
                                        </Box>
                                        <SimpleStatus style={{ width: "59px" }} text={stateDiscount == 1 ? "Activo" : "Inactivo"} state={stateDiscount == 0 ? 2 : 1} />
                                    </Box>
                                    <Box>
                                        <ul style={{ paddingLeft: "23px" }}>
                                            <li>Promoción para {typePromocion}</li>
                                            { valueDiscount != "" && <li>{getTypeDiscountLabel()}</li>}
                                            { typePromocion === 'Membresía' && <li style={{ lineHeight: "24px" }}>{getPeriodsSelectedLabel()}</li>}
                                            {getMembershipSelectedLabel() != "" && <li style={{ lineHeight: "24px" }}>{getMembershipSelectedLabel()}</li>}
                                            {<li style={{ lineHeight: "24px" }}>{`Para ${clientTarget.toLocaleLowerCase()}`}</li>}
                                            {<li style={{ lineHeight: "24px" }}>{limitUseClient ? (valueLimitClient > 1 ? (valueLimitClient + " usos por usuario") : "Un uso por usuario") : "Uso ilimitado por usuario"}</li>}
                                            {startDate != "" && <li style={{ lineHeight: "24px" }}>{`Activo desde ${startDate} ${startHour != "" ? (", " + startHour) : ""} ${endDateStatus && endDate != "" ? ("hasta el " + endDate + ", " + endHour) : ""}`} </li>}
                                        </ul>
                                    </Box>
                                    <Divider variant="inset" component="div" className='bg-fill-stroke-200' style={{ margin: "16px", marginLeft: "0px", marginRight: "0px" }} />
                                    {<Typography variant='h5' fontWeight="600" className='mb-1'>
                                        {"Rendimiento"}
                                    </Typography>}
                                    <Typography style={{ lineHeight: "12px", marginTop: "12px" }} className="text-white">{`Usados ${usedGlobal}/${limitUseGlobal == -1 ? "ilimitado" : limitUseGlobal}`}</Typography>
                                </>
                            } color="violet-300" style={{ paddingLeft: "39px", paddingRight: "39px", paddingTop: "24px", paddingBottom: "24px" }} />
                            <BoxContent value={
                                <>
                                    <Typography variant='h4' className='mb-3'>
                                        {"No puede combinarse con otros descuentos automáticos"}
                                    </Typography>
                                    <Typography variant='body1' style={{ marginTop: "16px" }}>
                                        {"Los usuarios no podrán introducir un código si ya se aplicó un descuento automático en la pantalla de pago."}
                                    </Typography>
                                </>
                            } mt="12px" />
                        </Box>
                    </Box>
                </>}
            </Loader>
        </>
    );
};

export default CouponDetail;