
import { MarketPlaceService } from '@sdk-point/talisis';
import { eventTracker } from '../../../../../../../shared/components/EventTracker/EventTracker';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

export const createCheckoutRefs = async( paymentMethod, paymentCard = null, orderId, personId, receivedReferences, selectedNumberOfPayments ) => { // SPEI, Cash, Free Charge

    const monthly_payment_months = (selectedNumberOfPayments > 1) ? selectedNumberOfPayments : null;
    
    try {
        const body = {
            paymentCard,
            payment_method: paymentMethod,
            person_id: personId,
            order_id: orderId,
            monthly_payment_months
        };
        const response = await marketService.payOrder({ person_id: personId }, body);
        return { paymentMethod, response }
    } 
    catch (e) {
        const eventData = {};
        let conektaErrorMessage = '';
        if(e.type !== undefined && e.type ==="Conekta" ){
            if( paymentMethod === "spei") {
                conektaErrorMessage = e.code !== undefined ? e.code : "";
                switch(conektaErrorMessage) {
                    case "conekta.errors.precondition_required.combo.order.cannot_be_updated_because_has_charge":{
                        conektaErrorMessage="Error al realizar el pago, la orden ya tiene un cargo realizado.";
                        break;
                    }
                    default:
                        conektaErrorMessage="Se ha producido un error al realizar la referencia, intente de nuevo.";
                        break;
                    }
                eventData['failtype'] = 'cargo'
            }
            if(paymentMethod === "savedMethod") {
                let messageCard = '';
                switch(e.code) {
                    case "conekta.errors.processing.charge.card_payment.suspicious_behaviour":
                        messageCard = 'Por favor intenta con otro método de pago'
                        break;
                    default:
                        messageCard = e.message;
                        break;
                }
               conektaErrorMessage = messageCard;
               eventData['failtype'] = 'metodo_pago'
            }
            if(paymentMethod === "cash") {
               conektaErrorMessage = e.message;
               eventData['failtype'] = 'cargo' 
            } 
        } else {
            conektaErrorMessage = 'Error de registro desconocido';
            eventData['failtype'] = 'registro'; 
        }
        eventTracker('pago_rechazado', eventData, ['hubspot']);
        return {conektaErrorMessage}
    }    
}
