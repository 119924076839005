import React from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import Button from '@material-ui/core/Button';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';
import { log } from '../../../../../shared/utils/console.js'

// redux
import { connect } from "react-redux"

import { CourseService,MIXPANEL_EVENT } from '@sdk-point/talisis';
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const SaveUnsaveButton = (props) => {
    const { course, classes, user } = props;
    const [updatingStatus, setUpdatingStatus] = React.useState(false);
    const [saveStatus, setSaveStatus] = React.useState(false);

    const handleSaveUnsaveCourse = async (e) => {
        e.preventDefault();
        e.persist();
        setUpdatingStatus(true);
        let newStatus = !saveStatus;
        setSaveStatus(newStatus);
        const response = await courseService.saveUnsaveCourse(user.person_id, course.id, newStatus);
        if(response.response.length > 0){
            
            let companyMixpanel =  course?.company_id != null ? course?.company_id : course?.source_name;
            const role = JSON.parse(localStorage.getItem('permissions'));
            

            setSaveStatus(response.response[0].is_active);
            setUpdatingStatus(false);
        }
        e.stopPropagation()
    };

    const getData = async () => {
        log(course);
        log(course.is_favorited);
        if(course.is_favorited != null){
            setSaveStatus(course.is_favorited);
        }
    }

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <Button variant="contained" color='primary' className={classes.markerButton} disabled={updatingStatus} onClick={(e) => handleSaveUnsaveCourse(e) }>{ saveStatus ? <BookmarkIcon color="primary"/> : <BookmarkBorderIcon color="primary"/>}</Button>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(SaveUnsaveButton);