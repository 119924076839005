import React from 'react';
import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { SOURCE_NAME } from '../../../../shared/constants/sources';

const UserPrograms = (props) => {
    const { data, classes } = props;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle1"><b>Programas</b></Typography>
                <hr />
            </Grid>
            {data.map(program => (
                <Table key={`table_program_${program.id}`}>
                    <TableBody>
                        <TableRow>
                            <TableCell width="30%" className={`${classes.borderNone} ${classes.valignTop}`}>
                                <Typography variant="body2" className="mt-1">{program.program_name}</Typography>
                                <Typography component="p" variant="caption" color="textSecondary">{`${program.courses[0]?.company_id}`}</Typography>
                                <Typography component="p" variant="caption" color="textSecondary">{`id: ${program.id}`}</Typography>
                            </TableCell>
                            <TableCell width="70%" className={classes.borderNone}>
                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`learning_style: ${program.learning_style}`}</Typography></li>
                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`credits: ${program.remaining_credits}`}</Typography></li>
                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">materias:</Typography></li>
                                <ol type="i" style={{ marginTop: 0 }}>
                                    {program.courses.map(course => (
                                        <React.Fragment key={`rf_program_course_${course.course_id}`}>
                                            <li className="ml-4"><Typography variant="caption">{course.course_name}</Typography></li>
                                            <ul>
                                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`source_id: ${course.source_id}`}</Typography></li>
                                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`ally_name: ${SOURCE_NAME[course.source_id]}`}</Typography></li>
                                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`course_id: ${course.course_id}`}</Typography></li>
                                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`course_url: ${course.course_url}`}</Typography></li>
                                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`third_party_id: ${course.third_party_id}`}</Typography></li>
                                                <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`modality_id: ${course.modality_id === 1 ? 'Presencial' : 'Online'}`}</Typography></li>
                                            </ul>
                                        </React.Fragment>
                                    ))}
                                </ol>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            ))}
        </React.Fragment>
    )
};

export default UserPrograms;