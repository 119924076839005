import React from 'react';
import clsx from 'clsx';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Hidden, Icon } from '@material-ui/core';
import ButtonAction from './ButtonAction';

const useStyles = makeStyles((theme) => (   {
    root: {
      maxWidth: 345,
    },
    media: {
      height: 417,
    },
    cardContent:{
        padding: props => props.isResponsive ? "16px" : "32px",
        flexDirection: 'column',
        display: 'flex',
        "&:last-child" : {
            paddingBottom: props => props.isResponsive ? "16px" : "32px",
        }
    }
  }));

const EventCard = ({isLogged, user, event, location, registerEvent, optionDropdown, onClickRegister, onRegisterSubmit, onClickInterestEvent, interestEvent, isResponsive }) => {
    const classes = useStyles({isResponsive});
    
    return (
        <Card variant="outlined" className="offset-margin-bottom-22 w-100">
            <Box className={classes.media}>
            {
                event?.image &&
                <CardMedia
                    className={classes.media}
                    image={event?.image}
                    title="Contemplative Reptile"
                />
            }
            </Box>
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" color="primary" className={clsx("mb-3", "text-uppercase")}>
                    {event?.date}
                </Typography>
                <Typography gutterBottom variant="h2">
                    {event?.title}
                </Typography>
                {
                    location?.place && 
                    <Box display="flex" alignItems="center">
                        {location.place && <Icon className="ri-map-pin-2-line mr-2 text-electric-red-300 font-size-24"></Icon>}
                        <Typography variant="subtitle2" color="textSecondary">
                        {location.place}
                        </Typography>
                    </Box>
                }
                <Hidden smUp>
                    <Box className='mt-3'>
                        <ButtonAction registerEvent={registerEvent} optionDropdown={optionDropdown} onClickRegister={onClickRegister} onRegisterSubmit={onRegisterSubmit} onClickInterestEvent={onClickInterestEvent} interestEvent={interestEvent} isResponsive={isResponsive}/>
                    </Box>
                </Hidden>
            </CardContent>
        </Card>
    );
};

export default EventCard;