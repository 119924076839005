import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// material components
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { log } from '../../../shared/utils/console.js'

// @material-ui/icons
import styled from 'styled-components';
import { Divider, Typography } from '@material-ui/core';


const ListItemIconStyled = styled(ListItemIcon)`
    min-width: 28px;
`

const ListGroup = ({items, onOpenInfo}) => {

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    let itemsGroup = [{}];
    const handleListItemClick = (event, index) => {
      log(index);  
      setSelectedIndex(index);
    };

    return (
        <>
            {
                items.map((ig, key ) => {
                    
                    return <List key={key} subheader={<ListSubheader style={{ fontSize: '1rem', fontWeight: 'bold'}}>{ig.nameArea}</ListSubheader>} component="nav" aria-label="secondary mailbox folder">
                        {ig.area.map( (it, key) => 
                            {
                                return <ListItem button
                                key={key}
                                selected={selectedIndex === it.id}
                                onClick={() => onOpenInfo(it)}
                                style={{padding: '10px 50px'}}
                                >
                                    <Typography style={{color: '#36BEE3', padding: '0px 50px 0px 0px'}} variant="body1" key={key}>{it.label}</Typography>
                                    {/* <ListItemText primary={it.label} /> */}
                                </ListItem>
                            }
                        )}
                         <Divider/>  
                    </List>
                })
            }
        </>
        
    );
};

export default ListGroup;

ListGroup.propTypes = {
    /**
     * Array of options
     */
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.isRequired,
        label: PropTypes.string.isRequired,
      })).isRequired,
    /**
     * Optional click handler
     */
    onChange: PropTypes.func,
    // required: PropTypes.bool,
    // label: PropTypes.string.isRequired,
    // name: PropTypes.string.isRequired,
    // error: PropTypes.bool,
    // helperText: PropTypes.any
};