import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    shippingGuideIcon: {
        fontSize: "40px !important",
    },
    panelDownloadGuide: {
        borderRadius: 8,
        padding: '25px'
    },
    containerShippingGuide: {
        borderBottom: `0.5px solid ${theme.palette.white}15`
    }
}));