import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// MUI
import Fab from '@material-ui/core/Fab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//SDK
import { HelpCenterService } from '@sdk-point/talisis'; 

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return ({
    ticketText: {
      fontSize: '14px',
      lineHeight: '16px'
    },
    guestFormContainer: {
      padding: '16px 16px 0px 16px'
    },
    guestFormTextBox: {
      marginTop: '8px',
      '& div': {
        backgroundColor: theme.palette.colors.fillStroke[300],
        borderRadius: '4px !important',
      },
      '& input': {
        padding: '16px',
      },
      '& input::placeholder': {
        color: theme.palette.colors.white,
        ...theme.typography.bodyTextLargeRegular,
        [theme.breakpoints.down('xs')]:{
          ...theme.typography.bodyTextSmallRegular,
        }
       },
      '& :hover': {
        backgroundColor: theme.palette.colors.fillStroke[200],
        transition: 'none'
      },
      '& :focus': {
        backgroundColor: theme.palette.colors.fillStroke[200],
        transition: 'none'
      },
      '& :focus-within': {
        backgroundColor: theme.palette.colors.fillStroke[200],
        borderRadius: '4px',
      }
    },
    fileContainer: {
      width: '48px',
      display: 'inline-grid',
      height: '64px',
      overflow: 'hidden',
      marginLeft: '8px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    inputLabel: {
        fontSize: 14,
        marginBottom: '20px'
    },
    label: {
        textAlign: 'left',
        alignItems: 'start',
        '&& span': {
            fontSize: '14px',
            lineHeight: '16px !important',
        },
        '& .MuiCheckbox-root': {
            paddingTop: 0
        },
        '& .MuiTypography-body1':{
          marginTop: '7px'
        }
    },
  })
});

const GuestForm = (props) => {
  const { 
    userForm,
    setUserForm,
    LoaderContent,
    loading,
    setLoading,
    setDisableSend
  } = props;

  const [isPhoneRequired, setIsPhoneRequired] = useState(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      // Permitir solo valores numéricos
      if (/^\d*$/.test(value) && value.length <= 10) {
        setUserForm((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setUserForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    //setUserForm({...userForm, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    if (userForm.name.length===0 || userForm.lastName.length===0 || userForm.email.length===0 ||
        (isPhoneRequired && userForm.phone.length > 10)) {
      setDisableSend(true)
    } else {
      setDisableSend(false)
    }
  }, [])

  const handleCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log('checkbox',  name, value, type, checked)
    setIsPhoneRequired((prevState) => !prevState);
    setUserForm({
      ...userForm,
      [name]: type === 'checkbox' ? checked : value,
    });
    //setUserForm({...userForm, [e.target.name]: e.target.value})
  };
  
  
  const classes = useStyles();
  return (
    <LoaderContent loading={loading}>
      <Box className={classes.guestFormContainer}>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
            <TextField
                name="name"
                variant="outlined"
                label="Nombre(s) *"
                fullWidth
                size="medium"
                //required
                value={userForm.name || ""}
                //error={Boolean(errors.first_name)}
                //helperText={errors.first_name}
                onChange={handleFormChange}
                InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                placeholder="Ingresa tu(s) nombre(s)"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                name="lastName"
                variant="outlined"
                label="Apellidos *"
                fullWidth
                size="medium"
                //required
                value={userForm.lastName || ""}
                //error={Boolean(errors.last_name)}
                //helperText={errors.last_name}
                onChange={handleFormChange}
                InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                placeholder="Ingresar apellidos"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                name="email"
                variant="outlined"
                label="Correo *"
                fullWidth
                size="medium"
                //required
                value={userForm.email || ""}
                //error={Boolean(errors.email) || emailError}
                //helperText={errors.email}
                onChange={handleFormChange}
                InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                placeholder="Ingresar correo electrónico"
                //className={emailError ? classes.emailInputError : classes.emailInput}
                // InputProps={{
                //     endAdornment: emailError ? <i className={`ri-alert-fill ${classes.alertIcon}`} /> : ''
                // }}
            />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
              className={classes.label}
              control={<Checkbox checked={isPhoneRequired} onChange={handleCheckboxChange} name='isPhoneRequired'/>}
              label={
                  <Typography variant='caption'>
                    Contactarme por WhatsApp
                  </Typography>
              }
          />
        </Grid>
        {isPhoneRequired && (
            <Grid item xs={12}>
                  <TextField
                      name="phone"
                      variant="outlined"
                      label="Númer de celular"
                      fullWidth
                      size="medium"
                      required
                      value={userForm.phone || ""}
                      //error={Boolean(errors.phone)}
                      //helperText={errors.phone}
                      onChange={handleFormChange}
                      InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                      placeholder="Número a 10 dígitos"
                      InputProps={{
                          startAdornment: <InputAdornment position="start">+52</InputAdornment>,
                      }}
                  />
              </Grid>
            )}
          <Grid item xs={12}>
              <TextField
                  name="matricula"
                  variant="outlined"
                  label="Matrícula"
                  fullWidth
                  size="medium"
                  //required
                  value={userForm.matricula || ""}
                  //error={Boolean(errors.last_name)}
                  //helperText={errors.last_name}
                  onChange={handleFormChange}
                  InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                  placeholder="Ingresar Matrícula"
              />
          </Grid>
        
        </Grid>
      </Box>
    </LoaderContent>
  )
};

GuestForm.defaultProps = {
  isHome: false,
  isCheckout: false,
};

GuestForm.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};


export default GuestForm;
