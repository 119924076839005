import React, { lazy, Suspense, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import CoursesInProgress from '../CoursesInProgress/CoursesInProgress';
import Box from '@material-ui/core/Box';
import { log } from '../../../shared/utils/console.js'
const CourseCarousel = lazy(() => import(/* webpackChunkName: "CourseCarousel" */'../../../shared/components/CourseCarousel/CourseCarousel'));
//const ProgramCarousel = lazy(() => import(/* webpackChunkName: "CourseCarousel" */'../../../shared/components/ProgramCarousel/ProgramCarousel'));
const useStyles = makeStyles((theme) => ({
    item:{
        marginBottom: "40px",
    },
    content:{
        
        width: "100vw",
        position: "relative",
        /*left: "50%",
        marginLeft: "-50vw",*/
        maxWidth: "100%",
        marginLeft: "20px",
        marginRight: "auto",

        /*marginBottom: "-24px",*/
        overflowX: "hidden",
        boxSizing: "content-box",
        "@media (max-width: 700px)": {
            marginLeft: "0px",
        }

    },
    title:{
        maxWidth: "1280px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "89%",
    },
    continuar:{
        width: "90%",
        maxWidth: "1280px",
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const CatalogAllCourses = ({ handleChangeTabSaved, handleChangeTabInProgress  }) =>  {
    const classes = useStyles();
    const [chargeCoursesInProgress, setChargeCoursesInProgress] = React.useState(false);
    const [chargeCoursesSaved, setChargeCoursesSaved] = React.useState(false);
 
    /*const handleClickCourse = (event, id) => { 
        event.preventDefault();
        event.persist();
        history.push(generatePath(routelinks.CURSOS_DETALLE, {id}));
        event.stopPropagation();
    }*/

    const handleSetChargeStatusForOptimize = (charge) => { 
        if(charge == "progress"){
            log("charge progress");
            setChargeCoursesInProgress(true);
        }else if(charge == "favorites"){
            log("charge favorites");
            setChargeCoursesSaved(true);
        }
    }

    return (
        <Box className={classes.content}>
            <Box className={classes.item}>
                <CoursesInProgress
                    handleChangeTabInProgress={handleChangeTabInProgress}
                    id="progress"
                    handleSetChargeStatusForOptimize={handleSetChargeStatusForOptimize}
                    title='Continúa aprendiendo'
                    color="white"/>
            </Box>
           { chargeCoursesInProgress == true && 
           <Box className={classes.item}>
                <Suspense fallback={<div></div>}>
                    <CourseCarousel
                        handleChangeTabSaved={handleChangeTabSaved}
                        isMyCourses={true}
                        id="favorites"
                        handleSetChargeStatusForOptimize={handleSetChargeStatusForOptimize}
                        title='Cursos guardados'
                        color="white"
                        clickFrom={'curso'}
                        />
                </Suspense>  
            </Box>}
            

            { chargeCoursesInProgress == true &&  
            <Box className={classes.item}>
                <Suspense fallback={<div></div>}>
                    <CourseCarousel
                        isMyCourses={true}
                        type="consumo"
                        id='recommendations'
                        title='Recomendación 1'
                        color="white"
                        clickFrom={'curso'}
                        />
                </Suspense>  
            </Box>}
            { chargeCoursesSaved == true &&  
            <Box className={classes.item}>
                <Suspense fallback={<div></div>}>
                    <CourseCarousel
                        isMyCourses={true}
                        type="interes"
                        id='recommendations'
                        title='Más cursos sobre '
                        color="white"
                        clickFrom={'curso'}
                        />
                </Suspense>
            </Box>}

            {/* EJEMPLO TIPOS DE PROGRAMAS */}
            {/*<Box className={classes.item}>
                <Suspense fallback={<div></div>}>
                    <ProgramCarousel
                        ou="UNID"
                        type="bachillerato"
                        title='Bachillerato'
                        />
                </Suspense>
            </Box>}
            {<Box className={classes.item}>
                <Suspense fallback={<div></div>}>
                    <ProgramCarousel
                        ou="UNID"
                        type="licenciatura"
                        title='Licenciatura'
                        />
                </Suspense>
            </Box>}
            {<Box className={classes.item}>
                <Suspense fallback={<div></div>}>
                    <ProgramCarousel
                        ou="UNID"
                        type="maestria"
                        title='Maestría'
                        />
                </Suspense>
            </Box>*/}
                {/*<Box className={classes.item}>
                    <CourseCarousel
                        id='recommendations'
                        title='Recomendación 3'
                        color="white"/>
                </Box>*/}
            
        </Box>
    );
}


const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CatalogAllCourses);
