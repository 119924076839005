import React, { useState, useEffect, Fragment } from "react";

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkboxes from '../../../../shared/components/Form/Checkboxes';
import RadioGroup from '../../../../shared/components/Form/RadioGroup';
import Select from '../../../../design-system/components/Form/Select/Select';

const EventForm = (props) => {
    const {formData, onChangeValue, isLogged, errors} = props;

    const [form, setForm] = useState(formData);
    const [values, setValues] = useState({});

    useEffect(() => {
        setValues((currentValues) => {
            const newValues = formData.reduce((obj, field) => {
                obj[field.name] = "";
                return obj;
            }, {});

            newValues.first_name = "";
            newValues.last_name = "";
            newValues.email = "";
            newValues.phoneNumber = "";
            newValues.gender = "";

            return Object.assign({}, newValues, currentValues);
        });
    }, [formData]);

    const handleChangeCheckboxes = (name, value) => {
        const e = {
            target:{
                name,
                value
            }
        }
        handleChange(e)
    };

    const handleChange = e => {
        const {name, value} = e.target;
        
        setValues((currentValues) => {
            currentValues[name] = value;
            onChangeValue(currentValues);
            return currentValues;
        });

        setForm((form) => Object.assign([], form));
    };    

    return (
        <Grid container spacing={2}>
            {
                !isLogged &&
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent component={Box} display="flex" flexDirection="column" gridGap={16} className="bg-fill-stroke-400">
                            <Typography variant="body1" className="mb-2 semi-bold">Tus datos</Typography>
                            <TextField name="first_name" label="Nombres" variant="outlined" fullWidth color="primary" required onChange={handleChange}/>
                            <TextField name="last_name" label="Apellidos" variant="outlined" fullWidth color="primary" required onChange={handleChange}/>
                            <Select
                                name="gender"
                                items={[
                                    { id: 'm', label: 'Masculino'},
                                    { id: 'f', label: 'Femenino'},
                                ]}
                                required
                                label="Género"
                                onChange={handleChange}
                            />
                            <TextField name="email" label="Correo" 
                                variant="outlined" fullWidth 
                                color="primary" 
                                required 
                                onChange={handleChange}                                         
                                helperText={values.email && errors.email}
                                error={values.email && errors.email}
                            />
                            <TextField name="phoneNumber" label="Telefono" variant="outlined" fullWidth color="primary" required type="number" onChange={handleChange}/>
                        </CardContent>      
                    </Card> 
                </Grid>
            }
        {form.map((field, index) => {
            switch (field.formTypeComponent) {
                case 'textArea':
                    return (
                        <Grid item xs={12} key={`${field.name}-${index}`}>
                            <Card variant="outlined">
                                <CardContent className="bg-fill-stroke-400">
                                    <Typography variant="body1" className="semi-bold">{field.label}{field.is_required && <span>*</span>}</Typography>
                                    <TextField 
                                        placeholder="Escribe aquí..."
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        name={field.name}
                                        value={values[field.name]}
                                        onChange={handleChange}
                                        required={field.is_required}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                case 'checkbox':
                    return (
                        <Grid item xs={12} key={`${field.name}-${index}`}>
                            <Card variant="outlined">
                                <CardContent className="bg-fill-stroke-400">
                                    <Checkboxes 
                                        items={field.options}
                                        onChange={handleChangeCheckboxes.bind(this, field.name)}
                                        itemsSelected={field.value}
                                        name={field.name}
                                        label={field.label}
                                        value={values[field.name]}
                                        required={field.is_required}
                                        labelProps={{
                                            className: 'semi-bold'
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                case 'radio':
                    return (
                        <Grid item xs={12} key={`${field.name}-${index}`}>
                            <Card variant="outlined">
                                <CardContent className="bg-fill-stroke-400">
                                    <RadioGroup 
                                        items={field.options}
                                        name={field.name}
                                        label={field.label}
                                        value={values[field.name]}
                                        onChange={handleChange}
                                        required={field.is_required}
                                        labelProps={{
                                            className: 'd-flex align-items-center semi-bold'
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                default:
                    return (
                        <Grid item xs={12} key={`${field.name}-${index}`}>
                            <Card variant="outlined">
                                <CardContent className="bg-fill-stroke-400">
                                    <Typography variant="body1" className="mb-2 semi-bold">{field.label} {field.is_required && <span>*</span>}</Typography>
                                    <TextField 
                                        variant="outlined" 
                                        fullWidth 
                                        color="primary"
                                        id={`${field.name}-${index}`}
                                        name={field.name}
                                        value={values[field.name]}
                                        onChange={handleChange}
                                        required={field.is_required}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    );
            }
        })}
        </Grid>
    );
};

export default EventForm;