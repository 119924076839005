export const MEMBERSHIPS = {
    FREEMIUM: 1,
    LITE: 2,
    PROFESSIONAL: 6,
    PREMIUM: 13
}

export const MEMBERSHIPS_NAME = {
    [MEMBERSHIPS.FREEMIUM]: 'Freemium',
    [MEMBERSHIPS.LITE]: 'Lite',
    [MEMBERSHIPS.PROFESSIONAL]: 'Professional',
    [MEMBERSHIPS.PREMIUM]: 'Premium',
}