import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    block: {
        display: 'block',
    },
    list: {
        padding: "15px",
        // minHeight: "500px"
    },
    notification: {
    },
    notificationContainer: {
        background: theme.palette.colors.grey[400],
        borderRadius: "8px",
        marginBottom: "10px"
    },
    title:{
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
    },
    info:{
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "18px",
    },
    viewMore:{
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "16px",

        display: "flex",
        alignItems: "center",

        color: theme.palette.colors.orange[300],
    },
    buttonTest:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 24px",
        gap: "8px",
        marginBottom:'10px',

        width: "179px",
        height: "42px",

        borderRadius: "8px",

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    timeAgo:{
        color: theme.palette.colors.blue[400],
        paddingTop: '10px'
    },
    badgeNotification:{
        backgroundColor:theme.palette.colors.blue[300],  
        color: 'white', 
        top:'10px'
    },
    chip:{
        margin: '0px 16px 10px 0px !important',
        background: theme.palette.black,
        border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: 24,
        '&.active':{
            background: theme.palette.colors.fillStroke[300] + ' !important',
            border: `0.5px solid ${theme.palette.colors.fillStroke[100]} !important`,
        }
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 16px',
    },
    chip:{
        margin: '0px 16px 10px 0px !important',
        background: theme.palette.black,
        border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: 24,
        '&.active':{
            background: theme.palette.colors.fillStroke[300] + ' !important',
            border: `0.5px solid ${theme.palette.colors.fillStroke[100]} !important`,
        }
    },
}));