import React from 'react';
import clsx from 'clsx';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import CurrencyFormat from 'react-currency-format';

// icons
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';

// shared
import getTimeString from "../../../../../shared/utils/getTimeString";

// logos
import { getLogoCard, getLogoCardSizeMedia } from '../../../../../../src/constants/logosImages';


import timer_icon from '../../../../../assets/images/logos/landing-icons/microgrados/card_hora_icon.svg';
import live_tv_outlined from '../../../../../assets/images/logos/landing-icons/microgrados/live_tv_outlined.svg';

import { convertSourceToImageResource }from '../../../../../../src/constants/placeholderImages';
import { convertOuToSource } from '../../../../../../src/constants/convertOuToSource';

//SDK
import { MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';
import CourseRating from '../../../../../shared/components/CourseCard/components/CourseRating';
import { log } from '../../../../../shared/utils/console.js'
import { Button, Collapse, Grid, Link, List, ListItem } from '@material-ui/core';

/*const Logos = ({
    0: { logo: LogoTalisisImg, ancho: "73px", alto: "20px" },
    5: { logo: LogoCourseraImg, ancho: "98px", alto: "14px" },
    6: { logo: LogoLinkedinImg, ancho: "102px", alto: "22px" },
    8: { logo: LogoUerreImg, ancho: "88px", alto: "15.5px" },
    9: { logo: LogoUnidImg, ancho: "72.5px", alto: "20px" },
    10: { logo: LogoHarmonHall, ancho: "95px", alto: "20px" },
    12: { logo: logo_google_skillshop, ancho: "166px", alto: "26px" },
    13: { logo: LogoCrehana, ancho: "100px", alto: "18px" },
    14: { logo: LogoHubspot, ancho: "84.5px", alto: "24px" },
    15: { logo: LogoIEPublishing, ancho: "61.5px", alto: "24px" },
});*/

const modalityOptions = ({
    2: "Online",
    3: "Híbrida",
    1: "Presencial",
});

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontWeight: 700,
        fontSize: 16,
        marginTop: 8,
        color: theme.palette.colors.grey[200],
        maxHeight: "48px",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
        overflow: "hidden",
        lineHeight:'17px'
    },
    time_text: {
        fontSize:'8px',
        color: '#A3DEFC'
    },
    time: {
        width: "12px",
    },
    tv: {
        width: "10px",
        color: '#A3DEFC'
    },
    fixed: {
        minWidth: '363px',
        maxWidth: '363px',
    },
    content: {
        padding: `4px 6px 6px 16px`,
        position: "relative",
        "@media (max-width: 550px)": {
            paddingLeft: '0px',
            height: '160px !important'
        }
    },
    lessons: {
        fontSize: 10,
        marginRight: 4
    },
    contentConfirm: {
        padding: `8px 10px 16px`,
        position: "relative",
    },
    info: {
        display: "flex",
        flexDirection: "row",
        // marginBottom: "9px",
    },
    media: {
        height: '169px',
        position: 'relative',
        '&::after':{
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            display: "inline-block",
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 61.46%, rgba(0, 0, 0, 0.568) 100%)`,
        },
        // filter: 'grayscale(100%)'
    },
    header: {
        fontSize: '18px',
        minHeight: '50px',
        overflow: 'hidden',
        margin: '4px 0px',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        width: "328px",
        height: "25px"
    },
    'headerColor': {
        fontSize: '18px',
        height: "50px",
        width: "328px",
        overflow: 'hidden',
        margin: '4px 0px',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },
    price: {
        fontSize: '20px',
        fontWeight: '700',
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    brand: {
        fontSize: '16px',
        fontWeight: '700',
    },
    mainCards: {
        width: '360px',
        maxHeight: '360px',
        margin: '10px',
        marginBottom: "10px !important",
        "@media (max-width: 500px)": {
            width: '344px',
            margin: '10px',
        },
    },
    rating: {
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        padding: theme.spacing(0.5, 1),
        borderRadius: "4px",
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: "12px"
    },
    favoriteButton: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    logoUO: {
        display: 'inline !important',
        height: '20px'
    },
    logoHH: {
        display: 'inline !important',
        height: "30px",
        marginTop: "-5px",
        "@media (max-width: 500px)": {
            height: "30px !important",
        }
    },
    logoThirdParty: {
        display: 'inline !important',
        height: '18px'
    },
    modalityChip: {
        bottom: '6px',
        left: '12px',
        display: 'flex',
        zIndex: 1,
        position: 'absolute',
        "@media (max-width: 670px)": {
            display: "none"
        }
    },
    checkoutCard: {
        padding: 0,
        position: "relative",
        display: "flex",
        flexDirection: "row",
        // height: "140px",
        height: "90px", //! Image Size
        "@media (max-width: 670px)": {
            flexDirection: "column"
        }
    },
    mainCheckout: {
        // maxWidth: "600px",
        // minWidth: "600px",
        "@media (max-width: 1394px)": {
            marginRight: "auto",
            marginLeft: "auto"
        },
        "@media (max-width: 670px)": {
            height: "150px"
        },
        backgroundColor:`${theme.palette.colors.grey[500]}`
        // "@media (max-width: 760px)": {
        //     maxWidth: "97%",
        //     minWidth: "97%"
        // }
    },
    boxData: {
        paddingLeft: "184px",
        "@media (max-width: 760px)": {
            paddingRight: "18px"
        },
        "@media (max-width: 670px)": {
            padding: "20px",
            paddingLeft: "0px"
        }

    },
    cardImgCheckout: {
        "@media (max-width: 670px)": {
            position: "inherit !important",
            left: "0px",
            width: "100%  !important",
            height: "200px  !important"
        }
    },
    sourceImg: {
        marginRight: "16px",
        marginBottom: "12.5px",
    },
    discount:
    {
        fontSize:12,
        lineHeight:'17px',
        color: theme.palette.colors.orange[300]
    }
}));

const Level = ({ value }) => {
    const levels = [
        { label: 'Básico', height: 4, activate: 1 },
        { label: 'Intermedio', height: 6, activate: 2 },
        { label: 'Avanzado', height: 8, activate: 3 },
    ];

    return <Box display="flex" alignItems="center" mr={1}>
        <Box>
            {
                value === 1 ?
                    <Box display="flex" alignItems="end" mr={.5}>
                        <Box key={1} width={2} height={3} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '1' }}></Box>
                        <Box key={2} width={2} height={6} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '0.3' }}></Box>
                        <Box key={3} width={2} height={9} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '0.3' }}></Box>
                    </Box>
                    : value === 2 ?
                        <Box display="flex" alignItems="end" mr={.5}>
                            <Box key={1} width={2} height={3} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '1' }}></Box>
                            <Box key={2} width={2} height={6} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '1' }}></Box>
                            <Box key={3} width={2} height={9} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '0.3' }}></Box>
                        </Box>
                        : value === 3 &&
                        <Box display="flex" alignItems="end" mr={.5}>
                            <Box key={1} width={2} height={3} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '1' }}></Box>
                            <Box key={2} width={2} height={6} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '1' }}></Box>
                            <Box key={3} width={2} height={9} style={{ background: '#FFFFFF', marginRight: '1px', opacity: '1' }}></Box>
                        </Box>
            }
        </Box>
        <Typography className="font-size-8 font-weight-400" style={{ color: '#FFFFFF',fontSize:'8px' }}>{value != null ? levels[value - 1].label : levels[0].label}</Typography>
    </Box>
};

const CourseOrderCardNew = (props) => {
    const classes = useStyles();
    let { course,couponData } = props;
    log("detalles de curso: ",course);
    const [duration, setDuration] = React.useState(0);
    const [backgroundImage, setBackgroundImage] = React.useState(null);

    React.useEffect(() => {
        if (course.estimated_time != null) {
            let estimated_time = getTimeString(course.estimated_time);
            setDuration(estimated_time);
        }
    }, []);

    let linkProps = {};

    if (props.linkProps) {
        linkProps = { ...props.linkProps }
    } else {
        if ('third_party_id' in course) {
            linkProps.to = { pathname: course.course_url };
            linkProps.target = "_blank";
        } else {
            linkProps.to = `/course/${course.id}`;
        }
    }
    const preventDefault = (event) => event.preventDefault();

    const raiting = {
        value: course.course_rating != null ? course.course_rating : 0,
        totalReview: course.total_review != null ? course.total_review : 0,
    };

    const setData = async () => {
        let imageToSet = null;
        if(course.image_url != null){
            imageToSet = course.image_url;
        }else{
            imageToSet = await convertSourceToImageResource(course.company_id, course.source_id);
        }
        //log(company_id, source?.id);
        return imageToSet;
    }

    React.useEffect(() => {
        let isCanceled = true;
        setData().then((data)=>{
            if(isCanceled){
                setBackgroundImage(data);
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, []);

    const theme = useTheme();
    const sourceId = convertOuToSource(course.company_id, course.source_id);
    const level = course.difficulty_level;
    const lessons = course.total_lessons || course.total_modules
    const modalityId = course.modality_id;
    let tipo = course.order_detail_type_id==1?"curso":course.level_description;
    tipo=tipo.toLowerCase();
    tipo = tipo.charAt(0).toUpperCase() + tipo.slice(1);

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    return (
        <Card className={clsx(classes.root, classes.mainCheckout)} style={{}}>
            <CardContent className={classes.checkoutCard}>
                <>
                    <div className={classes.modalityChip}>
                        {raiting && <CourseRating courseId={course.course_id} {...raiting} />}
                    </div>
                    <CardMedia
                        className={clsx(classes.media, classes.cardImgCheckout)}
                        alt={`Curso ${course.name}`}
                        title={`Curso ${course.name}`}
                        image={backgroundImage}
                        style={{ position: "absolute", left: "0", width: "100%", height: "100%", objectFit: "cover", borderRadius:'8px' }}
                    >
                    </CardMedia>
                </>
            </CardContent>
        </Card>
    )
};

export default CourseOrderCardNew;
