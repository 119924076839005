import React from 'react';
import clsx from 'clsx';

// @material-ui/core components
import { Box, Typography, Icon } from '@material-ui/core';

// utils
import { ORDER_STATUS } from '../../../../../shared/constants/orders';

const PaymentStatus = ({ estatus_id }) => {
    let classValue = {
        name: "",
        color: "",
        icon: "",
    };

    if (estatus_id === ORDER_STATUS.PENDING) {
        classValue.name = "Pago pendiente";
        classValue.color = "text-warning-300";
        classValue.icon = "ri-loader-2-fill";
    } else if (estatus_id === ORDER_STATUS.SUCCESS) {
        classValue.name = "Pago exitoso";
        classValue.color = "text-success-300";
        classValue.icon = "ri-checkbox-circle-fill";
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Icon className={clsx(classValue.icon, classValue.color)} style={{ fontSize: "32px", marginRight: "18.6px" }} />
            <Typography variant="h2" className={clsx(classValue.color)} color="primary">{classValue.name}</Typography>
        </Box>
    );
};


export default PaymentStatus;