import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';

import * as inputStatus from '../../../constants/text-field-status'

const TextFieldStatus = ({status, onChange, statusText, ...rest}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState('');
    
    // const handleFocus = () => {
    //     setIsFocused(true);
    // };
      
    // const handleBlur = () => {
    //     setIsFocused(false);
    // };

    const handleChange = (event) => {
        // setValue(event.target.value);
        onChange(event);
    };

    const EndAdornmentIcon = () => {
        if (status === inputStatus.ERROR || status === inputStatus.WARNING) {
            return <InputAdornment position="end">
                <Icon className={clsx(status === inputStatus.ERROR ? 'ri-alert-fill text-error-300 font-size-20' : 'ri-error-warning-fill text-warning-400 font-size-20')}/>
            </InputAdornment>;
        // } else if (!isFocused && value) {
        } else if (status === inputStatus.SUCCESS) {
            return (
                <InputAdornment position="end">
                    <Icon className='ri-checkbox-circle-fill text-success-400 font-size-20'/>
                </InputAdornment>
            );
        }
      
        return null;
      
    }

    return (
        <React.Fragment>
            <TextField
                onChange={handleChange}
                error={status === inputStatus.ERROR}
                variant="outlined" 
                className={clsx({ 'TextField-warning': status === inputStatus.WARNING, 'TextField-error': status === inputStatus.ERROR, 'TextField-success': status === inputStatus.SUCCESS})}
                helperText={status && statusText}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment:<EndAdornmentIcon />,
                    className: clsx({ 'Mui-warning': status === inputStatus.WARNING, 'Mui-success':  status === inputStatus.SUCCESS})
                    // className: clsx({ 'Mui-warning': status === inputStatus.WARNING, 'Mui-success':  status !== inputStatus.ERROR && status !== inputStatus.WARNING && !isFocused && value})
                }}
                // onFocus={handleFocus}
                // onBlur={handleBlur}
                // value={value}
                {...rest}
            />
        </React.Fragment>
    );
};

TextFieldStatus.propTypes = {
    status: PropTypes.oneOf(['', 'error', 'warning', 'success']),
    onChange: PropTypes.func,
};

export default TextFieldStatus;