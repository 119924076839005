import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    card:{
        border:`1px solid ${theme.palette.colors.fillStroke[200]}`
    },
    boxMedia:{ 
        width: '288px',
        height: '168px',    
        borderRadius: '8px 8px 0px 0px',
        padding: '16px',    
        background: props => `linear-gradient(180deg, rgba(0, 0, 0, 0) 61.46%, rgba(0, 0, 0, 0.71) 100%), url(${props.image_url})`,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        imageRendering: "-webkit-optimize-contrast",
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    boxTextContent: {
        width: '288px',
        height: '147px',    
        padding: '16px', 
    },
    logoSource:{
        width: '71.74px',
        height: '22px',
        top: '40px',
        left: '16px',
        gap: '0px',
        opacity: '0px',
        display: 'block',
        alignSelf: 'auto'
    }
}))