import React from 'react';

// redux
import { connect } from "react-redux";

import { v4 as uuid } from 'uuid';
// @material-ui/core
import { makeStyles, } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// shared components
import Loader from '../../../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../../../shared/components/Breadcrumb/Breadcrumb';
import InputFile from '../../../../../shared/components/Form/InputFile';
import Toast from '../../../../../shared/components/Toast/Toast';
import { log } from '../../../../../shared/utils/console.js'

// services
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';

// import * as marketService from '../../../../../services/MarketPlaceService';
import * as coursesService from '../../../../../services/CoursesService';

// components
import SectionList from '../CourseEdit/SectionList/SectionList';
import CourseSchedules from '../CourseEdit/CourseSchedules/CourseSchedules';
import Accordion from '../Accordion/Accordion';
//SDK
import { GlobalService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const useStyles = makeStyles((theme) => ({
    switch: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    paper: {
        overflow: "hidden",
    }
}));

const languageOptions = [
    { id: "es", name: "Español" },
    { id: "en", name: "Inglés" }
];

const CourseNew = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        price: "0.00",
        is_active: 1,
        is_sandbox: 0,
    });
    const [errors, setErrors] = React.useState({});
    const [levelOptions, setLevelOptions] = React.useState([]);
    const [modalityOptions, setModalityOptions] = React.useState([]);
    const [sourceOptions, setSourceOptions] = React.useState([]);
    const [loadMessage, setLoadMessage] = React.useState("");
    const [companyOptions, setCompanyOptions] = React.useState([]);
    const [instructors, setInstructors] = React.useState([]);
    // const [isAsync, setIsAsync] = React.useState(false);
    const [learningStyleOptions, setLearningStyleOptions] = React.useState([]);
    const [toastProps, setToastProps] = React.useState({
        message: "",
        open: false
    });
    const [breadcrumbItems, setBreadcrumbItems] = React.useState([
        { to: '/courses/management', label: 'Administración de Cursos' },
        { label: 'Nuevo curso' }
    ]);
    const { user } = props;
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const getData = async () => {
        try {
            setLoading(true);

            const levelData = await GlobalServiceSDK.getCatalog("CourseDifficultLevelCat");
            setLevelOptions(levelData);
            const modalityData = await GlobalServiceSDK.getCatalog("CourseModalityCat");
            setModalityOptions(modalityData);
            const instructorsData = await GlobalServiceSDK.getCatalog("InstructorCat");
            setInstructors(instructorsData);
            const sourceData = await GlobalServiceSDK.getCatalog("coursesourcecat");
            setSourceOptions(sourceData);
            const companyData = await GlobalServiceSDK.getCatalog("CompanyCat");
            setCompanyOptions(companyData);
            const learningStyleData = await GlobalServiceSDK.getCatalog("CourseLearningStyleCat");
            setLearningStyleOptions(learningStyleData);

            setLoading(false);
            setBreadcrumbItems(prev => {
                return [
                    { ...prev[0] },
                    { ...prev[1], title: "Nuevo curso" }
                ];
            });
        } catch (e) {
            setLoading(false);
            log(e.message);
        }
    };

    React.useEffect(() => {
        getData();
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name == "learning_style_id") {
            if (value == "Sincrónico") { } else {
                form.instructor_id = -1;
                form.frequency = "";
                form.schedule = "";
            }
        }

        if (value) {
            const _errors = { ...errors };
            delete _errors[name];

            setErrors(_errors);
        }

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const handleChangeImage = (fileList) => {
        const { name, value } = fileList;
        setForm(prev => { return { ...prev, [name]: value } });
    }

    const handleChecked = (e, checked) => {
        const { name } = e.target;
        setForm(prev => { return { ...prev, [name]: checked } });
    };

    const handleListChange = (name, values) => {
        setForm(prev => { return { ...prev, [name]: values } });
    };

    const handleFinishedToast = () => {
        setToastProps(prev => { return { ...prev, open: false } });
    };

    const checkUploadedCourseStatus = async (jobid, file, file_card, body, group_id, company_id) => {
        const jobStatus = await coursesService.getImportJobStatus(jobid);
        if(jobStatus["message"] != "error"){
            if(jobStatus["status"] == "COMPLETE"){
                setPlayerLaunchType(jobStatus.importResult.course.id);
                setPlayerScoLaunchType(jobStatus.importResult.course.id);
                setCourseVersionConfiguration(jobStatus.importResult.course.id);
                //setCourseApiRollupRegistrationPostBackUrl(jobStatus.importResult.course.id);
                setProgressUpdateConfiguration(jobStatus.importResult.course.id);
                setLoadMessage("Finalizando configuración del curso...");
                return await finsihUploadDataToPoint(jobStatus.importResult.course.id, jobid, file, file_card, body, group_id, company_id);
            }else{
                await delay(5000);
                checkUploadedCourseStatus(jobid, file, file_card, body, group_id, company_id);
            }
        }else{
            setToastProps({ open: true, message: "Error al subir el archivo del curso." });
            setLoadMessage(null);
            setLoading(false);
            return false;
        }
        return true;
    }
    const setPlayerLaunchType = async (courseId) => {
        const result = await coursesService.setPlayerLaunchType(courseId);
        log("setPlayerLaunchType");
        log(result);
    }

    const setPlayerScoLaunchType = async (courseId) => {
        const result =  await coursesService.setPlayerScoLaunchType(courseId);
        log("setPlayerScoLaunchType");
        log(result);
    }

    const setCourseVersionConfiguration = async (courseId) => {
        const result =  await coursesService.setCourseVersionConfiguration(courseId);
        log("setCourseVersionConfiguration");
        log(result);
    }
    const setCourseApiRollupRegistrationPostBackUrl = async (courseId) => {
        const result =  await coursesService.setCourseApiRollupRegistrationPostBackUrl(courseId);
        log("setCourseApiRollupRegistrationPostBackUrl");
        log(result);
    }

    const setProgressUpdateConfiguration = async (courseId) => {
        const result =  await coursesService.setProgressUpdateConfiguration(courseId);
        log("setProgressUpdateConfiguration");
        log(result);
    }

    const finsihUploadDataToPoint = async (scorm_course_id, jobid, file, file_card, body, group_id, company_id) =>{
        setLoadMessage("Subiendo imágenes del curso...");
        body['scorm_course_id'] = scorm_course_id;
        body['scorm_job_id'] = jobid;
        // if (file instanceof File) {
        //     const location = `market-place/courses/${company_id}/courses/landing`;
        //     const fileResult = await GlobalServiceSDK.uploadFile(file, location, 'course-landing', group_id, 'file');
        //     body['image_url'] = fileResult.path;
        //     log(body['image_url']);
        // }
        const tpid=uuid();
        if (file_card instanceof File) {
            const location = `market-place/courses/${tpid}/courses/card`;
            const fileResult = await GlobalServiceSDK.uploadFile(file_card, location, 'course-card', group_id, 'file_card');
            body['image_url'] = fileResult.path;
            log(body['image_url']);
        }

        body['price'] = body['price'] || "0.00";
        body['is_active'] = body['is_active'] ? 1 : 0;
        body['is_sandbox'] = body['is_sandbox'] ? 1 : 0;
        body['display_in_catalog'] = 1;
        body['third_party_id']=tpid;
        body['duration']=body['duration']===undefined?0:body['duration'];

        const result = await marketService.newCourse(body);
        setForm({
            price: "0.00",
            is_active: 1,
            is_sandbox: 0,
        });
        log(result);
        setLoadMessage(null);
        setLoading(false);
        setToastProps({ open: true, message: "El curso ha sido creado exitosamente." });
    }

    const validate = () => {
        const _errors = {};
        const requiredFields = ['name', 'company_id', 'source_id', 'learning_style_id'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo requerido';
            }
        }

        setErrors({ ..._errors });

        return Boolean(Object.keys(_errors).length === 0);
    };

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const handleSubmit = async () => {
        if (validate()) {
            const file = form.image;
            const file_card = form.card_image;
            const file_course = form.course_url;
            const body = {
                ...form,
                person_id: user.person_id
            };
            delete body.image;
            delete body.card_image;
            delete body.course_url;


            try {
                setLoading(true);

                // const response = await marketService.getCourseNewGroupId();
                // body['group_id'] = response.group_id;

                if (file_course instanceof File) {
                    //const locationCourse = `market-place/courses/${form.company_id}/course-${response.group_id}/course`;
                    setLoadMessage("Subiendo archivo del curso...");
                    const jobCourse = await coursesService.uploadCourseFile(file_course);

                    log("JOB ID------------------");
                    log(jobCourse.result);
                    await delay(5000);
                    setLoadMessage("Configurando datos del curso...");
                    checkUploadedCourseStatus(jobCourse.result, file, file_card, body, null, form.company_id);
                } else {
                    setLoadMessage("Finalizando configuración del curso...");
                    await finsihUploadDataToPoint(null, null, file, file_card, body, null, form.company_id)
                    setLoading(false);
                    setLoadMessage(null);
                }
            } catch (e) {
                setToastProps({ severity: "error", open: true, message: "Ocurrió un error al crear el curso. Por favor intenta de nuevo." });
                setLoading(false);
                log(e.message);
            }
        }
    };

    return (
        <Box>
            <Breadcrumb items={breadcrumbItems} />
            <Loader loading={loading} message={loadMessage}>
                <React.Fragment>
                    <Card>
                        <CardContent>
                            <Typography variant="subtitle1" className="mb-2">Información general</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="name"
                                        label="Nombre"
                                        required
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.name || ""}
                                        error={Boolean(errors.name)}
                                        helperText={errors.name}
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 255 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="company_id"
                                        label="Institución"
                                        required
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={form.company_id || ""}
                                        error={Boolean(errors.company_id)}
                                        helperText={errors.company_id}
                                        onChange={handleChange}
                                    >
                                        {companyOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="source_id"
                                        label="Origen de datos"
                                        required
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={form.source_id || ""}
                                        error={Boolean(errors.source_id)}
                                        helperText={errors.source_id}
                                        onChange={handleChange}
                                    >
                                        {sourceOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2} className={classes.switch}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="is_active"
                                                    size="medium"
                                                    checked={form.is_active || false}
                                                    onChange={handleChecked}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'Activo' }}
                                                />
                                            }
                                            label="Activo"
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="description"
                                        label="Descripción"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        variant="outlined"
                                        value={form.description || ""}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="objective"
                                        label="Objetivo"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        variant="outlined"
                                        value={form.objective || ""}
                                        onChange={handleChange}
                                        inputProps={{ maxLength:250 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="audience"
                                        label="¿A quién va dirigido?"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        variant="outlined"
                                        value={form.audience || ""}
                                        onChange={handleChange}
                                        inputProps={{ maxLength:250 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="url_course"
                                        label="Url del Curso"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        variant="outlined"
                                        value={form.url_course || ""}
                                        onChange={handleChange}
                                        inputProps={{ maxLength:255 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <InputFile
                                        accept="image/*"
                                        placeholder="Agrega una imagen"
                                        btnText="Card"
                                        name="card_image"
                                        value={form.card_image}
                                        onChange={(e) => handleChangeImage(e.target)} />
                                </Grid>
                                {/* <Grid item xs={12} md={4} hidden={tempHidden}>
                                    <InputFile
                                        accept="image/*"
                                        placeholder="Agrega una imagen"
                                        btnText="Landing"
                                        name="image"
                                        value={form.image}
                                        onChange={(e) => handleChangeImage(e.target)} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <InputFile
                                        accept="zip/*"
                                        placeholder="Agrega un curso"
                                        btnText="Curso"
                                        name="course_url"
                                        value={form.course_url}
                                        onChange={(e) => handleChangeImage(e.target)} />
                                </Grid> */}
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="learning_style_id"
                                        label="Tipo Enseñanza"
                                        required
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        error={Boolean(errors.learning_style_id)}
                                        helperText={errors.learning_style_id}
                                        value={form.learning_style_id || ""}
                                        onChange={handleChange}
                                    >
                                        {learningStyleOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="lang"
                                        label="Idioma"
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={languageOptions.filter(el => el.id === form.lang)[0]?.id || ""}
                                        onChange={handleChange}
                                    >
                                        {languageOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="difficulty_level_id"
                                        label="Nivel Dificultad"
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={form.difficulty_level_id || ""}
                                        onChange={handleChange}
                                    >
                                        {levelOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="platform"
                                        label="Plataforma"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.platform || ""}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {
                                    form.learning_style_id === 1 && <Grid item xs={12} md={4}>
                                        <TextField
                                            name="instructor_id"
                                            label="Instructor"
                                            select
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            value={form.instructor_id || ""}
                                            onChange={handleChange}
                                        >
                                            {instructors.map(option => (
                                                <MenuItem key={option.id} value={option.id}>{option.full_name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="modality_id"
                                        label="Modalidad"
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={form.modality_id || ""}
                                        onChange={handleChange}
                                    >
                                        {modalityOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {
                                    form.learning_style_id === 1 &&
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            name="duration"
                                            label="Duración"
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            value={form.duration || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                }
                                {/* {form.learning_style_id === 1 &&
                                    <React.Fragment>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                name="frequency"
                                                label="Frecuencia"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={form.frequency || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                name="schedule"
                                                label="Horario"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={form.schedule || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                } */}
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="price"
                                        label="Precio"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        onBlur={() => {
                                            handleChange({
                                                'target': {
                                                    'name': "price",
                                                    'value': parseFloat(form.price || "0.00").toFixed(2),
                                                }
                                            });
                                        }}
                                        value={form.price || "0.00"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="list_price"
                                        label="Precio de Lista"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        onBlur={() => {
                                            handleChange({
                                                'target': {
                                                    'name': "list_price",
                                                    'value': parseFloat(form.list_price || "0.00").toFixed(2),
                                                }
                                            });
                                        }}
                                        value={form.list_price || "0.00"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={2} className={classes.switch}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="is_sandbox"
                                                    size="medium"
                                                    checked={form.is_sandbox || false}
                                                    onChange={handleChecked}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'Modo Sandbox' }}
                                                />
                                            }
                                            label="Modo Sandbox"
                                        />
                                    </FormGroup>
                                </Grid> */}
                            </Grid>
                            <Grid container spacing={2} className="mt-3">
                                {form.learning_style_id === 1 ?
                                    <Grid item xs={12} md={12}>
                                        <Accordion id="instances" title="Fechas del curso" form={form}>
                                            <CourseSchedules data={form.instances} />
                                        </Accordion>
                                    </Grid> : null
                                }
                                <Grid item xs={12} md={12}>
                                    <Accordion id="tools" title="Herramientas a utilizar" form={form}>
                                        <SectionList
                                            id="tools"
                                            fieldName="description"
                                            items={form.tools}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="benefits" title="Beneficios" form={form}>
                                        <SectionList
                                            id="benefits"
                                            fieldName="description"
                                            items={form.benefits}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="skills" title="Habilidades" form={form}>
                                        <SectionList
                                            id="skills"
                                            fieldName="description"
                                            items={form.skills}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="requirements" title="Requisitos para acreditar curso" form={form}>
                                        <SectionList
                                            id="requirements"
                                            fieldName="description"
                                            items={form.requirements}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="advantages" title="Ventajas Competitivas" form={form}>
                                        <SectionList
                                            id="advantages"
                                            fieldName="description"
                                            items={form.advantages}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="modules" title="Temas y Módulos" form={form}>
                                        <SectionList
                                            id="modules"
                                            fieldName="description"
                                            nestedProp="topics"
                                            maxNestingLevel={2}
                                            items={form.modules}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                            </Grid>
                            <Box className="mt-4">
                                <Button color="primary" variant="contained" onClick={handleSubmit}>Crear curso</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </React.Fragment>
            </Loader>
            <Toast {...toastProps} onFinished={handleFinishedToast} duration={3000} />
        </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CourseNew);