import palette from './palette';

const fontSize = {
  40: 40,
  32: 32,
  24: '24px',
  20: 20,
  16: 16,
  14: 14,
  12: 12,
  10: 10,
}

const fontWeight = {
  700: 700,
  600: 600,
  500: 500,
  400: 400,
}

const lineHeight = {
  48: '48px',
  40: '40px',
  34: '34px',
  32: '32px',
  26: '26px',
  24: '24px',
  20: '20px',
  16: '16px',
  18: '18px',
  27: '27px',
}

const typographyVariants= {
  v1:{
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px'
  },
  v2:{
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px'
  },
  v3:{
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px'
  },
  v4:{
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '18px'
  },
  v5:{
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '15px'
  },
  v6:{
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '22px'
  },
  v7:{
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '17px'
  },
  v8:{
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '17px'
  },
  v9:{
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '17px'
  },
  v10:{
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  v11:{
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '40px'
  },
  v12:{
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '16px'
  },
}

const web = {
  h2: {
    color: palette.text.primary,
    fontWeight: fontWeight[700],
    fontSize: fontSize[32],
    lineHeight: lineHeight[32],
  },
  h3: {
    fontWeight: fontWeight[700],
    fontSize: fontSize[24],
    lineHeight: lineHeight[26],
  },
  captionSmallBold:{
    fontWeight: fontWeight[600],
    fontSize: fontSize[10],
    lineHeight: lineHeight[16]
  },
  captionSmallRegular:{
    fontWeight: fontWeight[400],
    fontSize: fontSize[10],
    lineHeight: lineHeight[16]
  },
  captionLargeSemiBold:{
    fontWeight: fontWeight[600],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16]
  },
  h6Card:{
    fontWeight: fontWeight[700],
    fontSize: fontSize[14],
    lineHeight: lineHeight[18]
  }
}

const mobile = {
  h1: {
    fontWeight: `${fontWeight[700]} !important`,
    fontSize: `${fontSize[32]} !important`,
    lineHeight: `${lineHeight[34]} !important`
  },
  h2: {
    fontWeight: `${fontWeight[700]} !important`,
    fontSize: `${fontSize[24]} !important`,
    lineHeight: `${lineHeight[26]} !important`
  },
}

const overlineVariants = {
  overlineLargeSemiBold: {
    color: palette.text.primary,
    fontWeight: fontWeight[600],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16],
    letterSpacing: '2px',
    textTransform: 'uppercase'
  },
  overlineSmallSemiBold:{
    color: palette.text.primary,
    fontWeight: fontWeight[600],
    fontSize: fontSize[10],
    lineHeight: lineHeight[16],
    letterSpacing: '2px',
    textTransform: 'uppercase'
  },
  overlineXsmallSemiBold:{
    color: palette.text.primary,
    fontWeight: fontWeight[600],
    fontSize: fontSize[8],
    lineHeight: lineHeight[16],
    letterSpacing: '2px',
    textTransform: 'uppercase'
  }
}

const bodyTextVariants = {
  bodyTextLargeSemiBold: {
    color: palette.text.primary,
    fontWeight: fontWeight[600],
    fontSize: fontSize[16],
    lineHeight: lineHeight[24],
  },
  bodyTextLargeRegular:{
    fontWeight: fontWeight[400],
    fontSize: fontSize[16],
    lineHeight: lineHeight[24]
  },
  bodyTextSmallSemiBold:{
    color: palette.text.primary,
    fontWeight: fontWeight[600],
    fontSize: fontSize[14],
    lineHeight: lineHeight[16],
  },
  bodyTextSmallRegular:{
    fontWeight: fontWeight[400],
    fontSize: fontSize[14],
    lineHeight: lineHeight[18]
  },
  bodyTextXsmallSemiBold:{
    color: palette.text.primary,
    fontWeight: fontWeight[600],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16],
  },
  bodyTextXsmallRegular:{
    color: palette.text.primary,
    fontWeight: fontWeight[400],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16],
  },
}

const subHeadingVariants = {
  subHeading1:{
    fontWeight: fontWeight[500],
    fontSize: fontSize[20],
    lineHeight: lineHeight[24],
  },
  subHeading2:{
    fontWeight: fontWeight[500],
    fontSize: fontSize[16],
    lineHeight: lineHeight[16],
  }
}

const captionVariants = {
  captionLargeSemiBold:{
    fontWeight: fontWeight[600],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16]
  },
}

export default {
  h1: {
    color: palette.text.primary,
    fontWeight: fontWeight[700],
    fontSize: fontSize[40],
    lineHeight: lineHeight[40],
    '@media (max-width:600px)': {
      fontSize: fontSize[32],
      lineHeight: lineHeight[34]
    },
  },
  h2: {
    color: palette.text.primary,
    fontWeight: fontWeight[700],
    fontSize: fontSize[32],
    lineHeight: lineHeight[34],
    '@media (max-width:600px)': {
      fontSize: fontSize[24],
      lineHeight: lineHeight[26]
    },
  },
  h3: {
    color: palette.text.primary,
    fontWeight: fontWeight[700],
    fontSize: fontSize[24],
    lineHeight: lineHeight[26],
    '@media (max-width:600px)': {
      fontSize: fontSize[20],
      lineHeight: lineHeight[24]
    },
  },
  h4: {
    color: palette.text.primary,
    fontWeight: fontWeight[700],
    fontSize: fontSize[20],
    lineHeight: lineHeight[24],
    '@media (max-width:600px)': {
      fontSize: fontSize[16],
      lineHeight: lineHeight[20],
    },
  },
  h5: {
    color: palette.text.primary,
    fontWeight: fontWeight[700],
    fontSize: fontSize[16],
    lineHeight: lineHeight[16],
    '@media (max-width:600px)': {
      fontSize: fontSize[12],
      lineHeight: lineHeight[16],
    },
  },
  h6: {
    color: palette.text.primary,
    fontWeight: fontWeight[700],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16],
    '@media (max-width:600px)': {
      fontSize: fontSize[10],
      lineHeight: lineHeight[16],
    },
  },
  subtitle1: {
    color: palette.text.primary,
    fontWeight: fontWeight[500],
    fontSize: fontSize[20],
    lineHeight: lineHeight[24],
    '@media (max-width:600px)': {
      fontSize: fontSize[16],
      lineHeight: lineHeight[16],
    },
  },
  subtitle2: {
    color: palette.text.primary,
    fontWeight: fontWeight[500],
    fontSize: fontSize[16],
    lineHeight: lineHeight[16],
    '@media (max-width:600px)': {
      fontSize: fontSize[12],
      lineHeight: lineHeight[16],
    },
  },
  body1: {
    color: palette.text.primary,
    fontWeight: fontWeight[400],
    fontSize: fontSize[16],
    lineHeight: lineHeight[24]
  },
  body2: {
    color: palette.text.primary,
    fontWeight: fontWeight[400],
    fontSize: fontSize[14],
    lineHeight: lineHeight[18],
  },
  button: {
    color: palette.text.primary,
    fontWeight: fontWeight[500],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16],
    textTransform: 'unset',
    '@media (max-width:600px)': {
      fontSize: fontSize[10],
    },
  },
  caption: {
    color: palette.text.primary,
    fontWeight: fontWeight[400],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16]
  },
  overline: {
    color: palette.text.primary,
    fontWeight: fontWeight[600],
    fontSize: fontSize[12],
    lineHeight: lineHeight[16],
    letterSpacing: '2px',
    textTransform: 'uppercase'
  },
  desktop:{
    h4: {
      color: palette.text.primary,
      fontWeight: fontWeight[700],
      fontSize: fontSize[20],
      lineHeight: lineHeight[24],
    }
  },
  mobile,
  ...typographyVariants,
  web,
  ...overlineVariants,
  ...bodyTextVariants,
  ...subHeadingVariants,
  ...captionVariants
};
