import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const UserData = (props) => {
    const { data } = props;
    return (
        <Grid container spacing={3} className="mt-2">
            <Grid item xs={12}>
                <TextField
                    label="Nombre completo"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={`${data.first_name} ${data.last_name}`}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Matrícula"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.user_id || ''}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    label="¿Es Admin?"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.is_admin ? 'Sí' : 'No'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    label="¿Esta activo?"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.is_active ? 'Sí' : 'No'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="External ID"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.external_id || ''}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Last Updated"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.last_updated && data.last_updated.replace('T', ' ')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Origin ID"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.origin_id ? `${data.origin_id} - ${data.person_origin_name}` : ''}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Created date"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.created_date ? data.created_date.replace('T', ' ') : ''}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
};

export default UserData;