import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// MUI
import {
  Box,
  Button,
  Icon,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const InfoTopic = (props) => {
  const history = useHistory();
  const {selectedTopic, selectedArticle} = props
  const numberWhatsapp = '528118212007'
  const theme = useTheme();
  const useStyles = makeStyles({
    boxInfo: {
      padding: '0px 16px 16px 16px',
      textAlign: 'left',
      paddingBottom: '0px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px'
    },
    titleInfo: {
      textAlign:  'left',
      //marginTop: '16px',
      fontSize: '20px',
      lineHeight: '24px'
      // '& p': {
      //   fontSize: '20pxpx',
      //   lineHeight: '24px'
      // }
    },
    subTitle:{
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.colors.violet[300],
      paddingBottom: '10px'
    },
    info:{
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.colors.gray[100],
    },
    goToTickets: {
      textDecoration: 'none !important',
      textTransform: "none",
      padding: '5px',
      '& span': {
        color: theme.palette.colors.orange[300],
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '16px',
        letterSpacing: 0,
        '& .MuiButton-endIcon': {
          fontWeight: 200
        }
      }
    },
    boxWhatsapp:{
      display: 'flex',
      flexDirection: 'column',
      gridGap: 8,
      marginBottom: 24
    },
    butttonWhats:{
      backgroundColor: '#25D366',
      '& .MuiButton-startIcon':{
        '&.MuiButton-iconSizeMedium > *:first-child':{
            fontSize: '24px !important'
        },
      },
      "&:hover":{
        background: "#108B50"
      }
    },
  });

  const classes = useStyles();

  const handleGoToWhatsapp = () => {
    let textoCodificado = encodeURIComponent('¡Hola!, Me pudieran apoyar con una duda sobre Talisis.com.')
    window.open(`https://wa.me/${numberWhatsapp}?text=${textoCodificado}`)
  }

  const handleGoInfoAll = () => {
    history.push(`/help-guide/${selectedTopic.name}/${selectedTopic.value}`);
    props.handleCloseDrawer();
  }
  return (
    <Box className={classes.boxInfo}>
      {/* <Box>
      </Box> */}
      <Typography variant='h4' className={classes.titleInfo}>{selectedTopic.name}</Typography>
      {
        selectedArticle.length > 0 ? (<>
          <Box className={classes.confirmationText}>
            {selectedArticle.length > 0 && (
              <Typography className={classes.subTitle}>{selectedArticle[0].title}</Typography>
            )}
    
            {selectedArticle.length > 0 && (
              <Typography className={classes.info}>{selectedArticle[0].snippet}</Typography>
            )}
          </Box>
          <Box mt={2}>
            <Button onClick={handleGoInfoAll} 
            className={classes.goToTickets} 
            endIcon={<i className={'ri-arrow-right-line'} />}>Ver en Pantalla completa </Button>
          </Box>
        </>) 
        :
        <Box className={classes.boxWhatsapp}>
          <Typography variant='body1' className='semi-bold'>¿Tienes dudas sobre nuestros programas y membresías?</Typography>
          <Button variant='contained' className={classes.butttonWhats} onClick={handleGoToWhatsapp} startIcon={<Icon className={'ri-whatsapp-line'} />}>Escríbenos por WhatsApp</Button>
          <Box display={'flex'} gridGap={8}>
            <Icon className='ri-information-line font-size-16' />
            <Typography className='variant-xsmall'>
              También puedes marcar al 8007709090 - Opción 2
            </Typography>
          </Box>
        </Box>
      }
    </Box>
  )
};

InfoTopic.defaultProps = {
  isHome: false,
};

InfoTopic.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
};


export default InfoTopic;
