import React, {useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';

import { hasRole } from '../../../services/RoleService';
import { ROLE_PROMOTOR } from '../../../shared/constants/roles';

import * as paths from '../../../routes/paths'
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    alert:{
        padding: '18px 32px',
        borderRadius: 0,
        background: theme.palette.colors.violet[300],
        '& .MuiIcon-root':{
            fontSize: 17
        },
        '& .MuiAlert-message':{
            width: '100%',
            padding: 0,
            '& .MuiButton-text':{
                marginBottom: 3
            }
        },
        [theme.breakpoints.down('xs')]:{
            padding: '16px 13px',
            positon: 'relative',
            '& .MuiAlert-message':{
    
                '& div, .bttn-text':{
                    ...theme.typography.bodyTextSmallRegular
                }
            },
            '& .MuiAlert-action':{
                padding: 0,
                position: 'absolute',
                bottom: 16,
                right: 19
            },
        }
    }
}));

export default function RefferalPlanBanner() {
    const { user } = useSelector((state) => ({...state.userReducer}));

  const classes = useStyles();
  const [open, setOpen] = React.useState(localStorage.getItem('refferalPlanBanner') === 'true');

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
        localStorage.setItem('refferalPlanBanner', 'true');
    });
    
    return () => {
        window.removeEventListener('beforeunload', () => {
            localStorage.setItem('refferalPlanBanner', 'true');
        });
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('refferalPlanBanner', 'false');
  };
  
  if(user.person_id && hasRole(ROLE_PROMOTOR)){
    return null
  }

  return (
    <div className={classes.root} id="refferal-plan-banner-root">
        <Collapse in={open}>
            <Alert
                icon={false}
                className={classes.alert}
                action={
                    <IconButton className='p-0' aria-label="close" onClick={handleClose}>
                        <Icon className='ri-close-circle-fill text-white' />
                    </IconButton>
                }
            >
                <Box display='flex' alignItems='center' justifyContent='center'>
                    <Typography variant='body1' align='center' component={Box}>
                    🚀 <span className='semi-bold'>¡Refiere y gana!</span> Invita a dos amigos a adquirir su membresía Talisis y gana <span className='semi-bold'>$500MXN</span> en Amazon.
                        &nbsp;
                        <Button color='primary' disableTouchRipple className='text-electric-blue-300 p-0' component={RouterLink} to={paths.REFIERE_GANA_PATH}>
                            <Typography variant='body1' color='inherit' className='semi-bold bttn-text'>
                                Conoce cómo aquí
                            </Typography>
                        </Button> 
                    </Typography>
                    {/* <Hidden smDown>  */}
                    {/* </Hidden> */}
                </Box>
            </Alert>
        </Collapse>
    </div>
  );
}
