import React from 'react';
import { Grid, Icon, Paper, Chip } from '@material-ui/core';

import useStyles from '../../../assets/styles/components/table/tableFilterStyle'

const TableFilter = ({children, filterList, onClickDeleteFilter}) => {
    const classes = useStyles();
    return (
        <>
            <Paper className={classes.root} id="table-filter-root" aria-describedby="">
                <Grid container spacing={2}>
                    {children}
                </Grid>
            </Paper>
            {
                filterList &&
                <Paper className={classes.chips}>
                    <Grid container style={{gap: 8}} >
                        {
                            filterList.map((chip, index) =>
                                <Chip label={chip.label} 
                                    deleteIcon={<Icon className='ri-close-line d-flex align-items-center'/>} 
                                    onDelete={() => onClickDeleteFilter(chip)} 
                                    key={`filter-chip-${index}`}/>
                            )
                        }
                    </Grid>
                </Paper>
            }
        </>
    );
};

export default TableFilter;