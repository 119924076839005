import React from 'react';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import clsx from 'clsx';
import { Hidden, makeStyles } from '@material-ui/core';

const useStyles =  makeStyles((theme) => ({
    clamp:{
        '-webkit-line-clamp': props => props.line,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
    }
}));

const ReadMoreLine = ({ text, line = 2 }) => {
    const classes = useStyles({line});
    const [clamped, setClamped] = React.useState(true);
    const [showButton, setShowButton] = React.useState(true);
    const containerRef = React.useRef(null);
    const handleClick = () => setClamped(!clamped);

  
    React.useEffect(() => {
      const hasClamping = (el) => {
        const { clientHeight, scrollHeight } = el;
        return clientHeight !== scrollHeight;
      };
  
      const checkButtonAvailability = () => {
        if (containerRef.current) {
          // Save current state to reapply later if necessary.
          const hadClampClass = containerRef.current.classList.contains("clamp");
          // Make sure that CSS clamping is applied if aplicable.
          if (!hadClampClass) containerRef.current.classList.add("clamp");
          // Check for clamping and show or hide button accordingly.
          setShowButton(hasClamping(containerRef.current));
          // Sync clamping with local state.
          if (!hadClampClass) containerRef.current.classList.remove("clamp");
        }
      };
  
    const debouncedCheck = (fn, delay) => {
        let timerId;
        return (...args) => {
          clearTimeout(timerId);
          timerId = setTimeout(() => fn(...args), delay);
        }
    };
  
    checkButtonAvailability();
        window.addEventListener("resize", debouncedCheck(checkButtonAvailability, 50));
    
        return () => {
            window.removeEventListener("resize", debouncedCheck(checkButtonAvailability, 50));
        };
    }, [containerRef]);
  
    return (
        <>
            <Box ref={containerRef} className={clsx(clamped && classes.clamp)}>
            {text}
            </Box>
            {showButton && (
              <>
              <Hidden smDown>
                <Link component="button" color="secondary" variant="body1"
                      className="semi-bold" 
                      onClick={handleClick}>Ver {clamped ? "más" : "menos"}
                  </Link>
              </Hidden>
              <Hidden smUp>
                <Link component="button" color="secondary" className='variant-xsmall mt-3'
                      onClick={handleClick}>Ver {clamped ? "más" : "menos"}
                  </Link>
              </Hidden>
            </>
            )}
        </>
    );
};

export default ReadMoreLine;