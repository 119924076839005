import { hasInstructorPermission } from '../../../services/UserService';
import { Mixpanel } from '../Mixpanel/mixpanel';
import { log } from '../../../shared/utils/console.js'
import { AccountService } from '@sdk-point/talisis'
import { Hubspot } from '../HubSpot/hubspot.js';
import { GoogleA } from '../GoogleA/googlea.js';
import { MEMBERSHIPS_NAME } from '../../../design-system/constants/memberships.js';
import { getSessionCookie } from '../../utils/Sessions.js';

const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

let userData = {
    person_id: '',
    email: '',
    name: '',
    last_name: '',
    phone_number: '',
    interest_1: '',
    interest_2: '',
    interest_3: '',
    interest_4: '',
    user_type: '',
    user_id: '',
    "$name": '',
    "$email": ''
};

let eventData = {};
let sessionCookie = getSessionCookie();

const fillUserData = async (data) => {
    var interestsList = {}
    if (data.interests && !!data.interests.length) {
        interestsList = await AccountServiceSDK.getSkillsNames({ skills: data.interests })
        // console.log(interestsList)
    }

    userData = {
        person_id: data.person_id,
        email: data.email,
        name: data.first_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        gender: data.gender,
        interest_1: (interestsList?.skills && interestsList?.skills[0].name) || '',
        interest_2: (interestsList?.skills && interestsList?.skills[1].name) || '',
        interest_3: (interestsList?.skills && interestsList?.skills[2].name) || '',
        interest_4: (interestsList?.skills && Object.keys(interestsList?.skills.length) === 4 && interestsList?.skills[3].name) || '',
        user_type: hasInstructorPermission() ? 'Maestro' : 'Estudiante',
        user_id: data.student_id,
        subscription_type: MEMBERSHIPS_NAME[data.membership_id]
    };

    return userData;

    // setUser(userData, ['mixpanel']);
};

const clearUserData = () => {
    Mixpanel.reset();
    Object.keys(userData).map(k => {
        userData[k] = '';
    });
};

/**
 * Servicio para envío de traza de eventos en la plataforma.
 * @param {Nombre del evento en Mixpanel} event
 * @param {Objeto global eventData} data
 * @param {Arreglo de plataformas analíticas} services
 */
const eventTracker = async (event, data, services) => {
    // log(services);
    // log('tracking event');
    if(!sessionCookie.is_fake){
        if (services.includes('mixpanel')) {
            Mixpanel.track(event, data);
        }
    
        if (services.includes('hubspot')) {
            let newObject = {}
            for (const property in data) {
                 newObject[property?.toLowerCase()] = data[property];
               }
            Hubspot.track(event, newObject);
        }
        
        if (services.includes('google')) {
            GoogleA.track(event, data);
        }
    
        // Restore initial eventData structure.
        fillUserData(data);
    }
}

const setUser = async (user, services) => {
    // log(services);
    if (services.includes('mixpanel')) {
        // log('setting mixpanel user')
        Mixpanel.identify(user.person_id);
        Mixpanel.people(user)
    }
}

export { eventTracker, setUser, fillUserData, clearUserData, userData, eventData }
