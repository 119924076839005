import React from 'react';
import PropTypes from 'prop-types';

// Routes
import * as paths from '../../../routes/paths';

// Material components
import { Box, Button, Typography } from '@material-ui/core';

const CardInformationEvent = ({isLogged, onClickRegister, isResponsive}) => {
    return (
        <Box mt={isResponsive ? 1 : "40px"} mb={isResponsive ? 1 : 2} py={isResponsive ? 2 : 4} px={isResponsive ? 2 : 8} display="flex" justifyContent="space-between" height="248px" className="gradient-blue-1 border-radius-8">
            <Box flexDirection="column" display="flex" maxWidth="522px">
                <Typography variant="h3" className="offset-margin-bottom-9">Ver más eventos exclusivos para miembros</Typography>
                <Typography variant={isResponsive ? "body1" : "subtitle2"} className={isResponsive ? "variant-subheading-3" : ""}>No te pierdas los eventos que nuestras marcas tienen para ti.</Typography>

                <Box mt="auto">
                    {
                        !isLogged && <Button variant="contained" fullWidth onClick={onClickRegister.bind(this, paths.SIGNUP)}>Registrate</Button>
                    }                        
                </Box>
            </Box>
            {
                !isResponsive &&
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={0} gridGap="32px">
                    <Box display="flex" flexDirection="row" alignItems="flex-start" gridGap="64px">
                        <img src="/images/logos/advenio.png" />
                        <img src="/images/logos/iesalud.png" />
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="flex-start" gridGap="64px">
                        <img src="/images/logos/hh.png" />
                        <img src="/images/logos/uerre.png" />
                        <img src="/images/logos/unid.png" />
                    </Box>
                </Box>
            }
        </Box>
    );
};

CardInformationEvent.defaultProps = {
    isResponsive: false,
};

CardInformationEvent.propTypes = {
    isLogged: PropTypes.bool,
    onClickRegister: PropTypes.func,
    isResponsive: PropTypes.bool,
};

export default CardInformationEvent;