import React from 'react';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom'

// @material-ui/core
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

// icons
// import EventIcon from '@material-ui/icons/Event';
// import TimerIcon from '@material-ui/icons/Timer';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

// shared components
// import ProgressWithLabel from "./../../../../shared/components/ProgressWithLabel/ProgressWithLabel";

// shared utils
import getTimeString from "./../../../../shared/utils/getTimeString";
import * as dateUtils from "./../../../../shared/utils/dateUtils";
import { log } from '../../../../shared/utils/console.js'

//assets
// import Uerre from "../../../../assets/images/logos/cards-ico/uerre.png";
// import Unid from "../../../../assets/images/logos/cards-ico/unid.png";
// import Harmonhall from "../../../../assets/images/logos/cards-ico/harmonhall.png";
// import Point from "../../../../assets/images/logos/cards-ico/point_ico.png";
// import Advenio from "../../../../assets/images/logos/landing-marcas/Advenio_s.svg";
// import LinkedIn from "../../../../assets/images/logos/cards-ico/third-party/linkedin.svg";
// import Coursera from "../../../../assets/images/logos/cards-ico/third-party/coursera.svg";

//assets
import Uerre from "../../../../assets/images/logos/logo-uerre-blanco.png";
import Unid from "../../../../assets/images/logos/logo-unid-blanco.png";
import Harmonhall from "../../../../assets/images/logos/cards-ico/harmonhall.svg";
import Advenio from "../../../../assets/images/logos/logo-advenio.png";
import LinkedIn from "../../../../assets/images/logos/logo-linkedin-blanco2.png";
import Coursera from "../../../../assets/images/logos/logo-coursera-blanco.png";

// services
// import { getRegistrationStatus } from "../../../../services/CoursesService";

// const WhiteTypography = withStyles((theme) => ({
//     root: {
//         color: theme.palette.getContrastText("#111317"),
//     },
// }))(Typography);

const useStyles = makeStyles((theme) => ({
    root: {
        maxHeight: '296px',
        // '&:hover': {
        //     backgroundColor: '#EBFCFF',
        //     transition: '0.3s',
        // },
    },
    // fixed: {
    //     minWidth: '363px',
    //     maxWidth: '363px',
    // },
    media: {
        height: '169px',
        position: 'relative',
    },
    header: {
        width: '85%',
        margin: '4px 0px',
        // paddingBottom: '8px',
        fontSize: '18px',
        minHeight: '50px',
        overflow: 'hidden',
        // marginTop: '4px',
        // marginBottom: '8px',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },
    brand: {
        fontSize: '16px',
        fontWeight: '700',
    },
    content: {
        padding: '12px 16px 16px 16px',
        position: 'relative',
    },
    banner: {
        position: "absolute",
        height: "40px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(17, 19, 23, 0.8)",
        padding: `0px ${theme.spacing(2)}px`
    },
    logoUO:{
        display: 'inline !important',
        height: '20px'
    },
    logoThirdParty: {
        display: 'inline !important',
        height: '18px'
    },
    modalityChip: {
        display: 'flex',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        padding: "12px",
        '& .MuiChip-root': {
            padding: theme.spacing(1, 1),
            background: 'rgba(33, 158, 188, 0.5)',
            '& .MuiChip-label': {
                fontWeight: '600',
                padding: theme.spacing(0.5, 1)
            }
        }
    },
}));

const CourseBanner = (props) => {
    const classes = useStyles();

    if (!props.start_date) {
        return null;
    }

    const start_date = moment(dateUtils.ISODateToString(props.start_date));
    const seconds = start_date.diff(moment(), "seconds");

    if (seconds <= 0) {
        return null;
    }

    return (
        <Box className={classes.banner}>
            <Typography variant="subtitle1" color='white'>{`Clase inicia en ${getTimeString(seconds)}`}</Typography>
        </Box>
    );
};


const CourseCard = (props) => {
    const classes = useStyles();
    const { name, source, image_url, to, difficulty_level, register_id, progress, admission_id, admission_status} = props;
    //const [urlCourse, setUrlCourse] = React.useState();
    //const [fst, setFst] = React.useState(0);
    const [progressValue, setProgressValue] = React.useState(0);
    const [data, setData] = React.useState();
    // let durationIcon = null;
    // let durationText = "";
    let difficultyLabel = "";

    React.useEffect(() => {
        //if(fst == 0){
            getDataCourse(register_id);
        //}
    }, []);


    const getDataCourse = async (registerid) => {
        try {
            //if(fst == 0){
                //setFst(1);
                
                if(registerid){
                    //const result = await getRegistrationStatus(registerid);
                    setData({registerId: registerid, name });
                    setProgressValue(progress || 0);
                    //setPge(result["activityDetails"]["activityCompletion"], props.progress_percentage );
                    //log(result)
                }else{
                    if (props.learning_style_id === 1) {
                        const date1 = moment(dateUtils.ISODateToString(props.start_date));
                        const date2 = moment(dateUtils.ISODateToString(props.end_date));
                        // Calcular progreso hasta que el curso banner comience.
                        if (moment().isSameOrAfter(date1)) {
                            const diff1 = Math.abs(date1.diff(date2, "days"));
                            const diff2 = Math.abs(date1.diff(moment(), "days"));
                            setProgressValue(Math.round((diff2 * 100) / diff1));
                        }
                    } else {
                        setProgressValue(progress || 0);
                    }
                }
            //}
        } catch (e) {
            log(e.message);
        }
    }

    const setPge =  async (activityCompletion, progress_percentage) =>{
        if(activityCompletion === "INCOMPLETE"){
            setProgressValue(15);
        }else if(activityCompletion === "COMPLETED"){
            setProgressValue(100);
        }else{
            setProgressValue(progress_percentage || 0);
        }
    }

    if ('third_party_id' in props/* || props.learning_style_id === 3*/ /*source == "ADV"*/) {
        if (difficulty_level) {
            if (difficulty_level.toUpperCase() === "BEGINNER") {
                difficultyLabel = "Básico";
            } else if (difficulty_level.toUpperCase() === "INTERMEDIATE") {
                difficultyLabel = "Intermedio";
            } else if (difficulty_level.toUpperCase() === "ADVANCED") {
                difficultyLabel = "Avanzado";
            }
        }
        //const timeString = getTimeString(props.estimated_time || 0);
        // let timeString = 0;
        // durationIcon = <TimerIcon fontSize="small" />;
        // if(props.duration){
        //     timeString = props.duration;
        //     let spliter = timeString;
        //     if(spliter != null){
        //         spliter = spliter.split(' ');
        //         if(spliter[1].toLowerCase() == "horas"){
        //             spliter[1] = "hrs";
        //             if(spliter[0] == 1){
        //                 spliter[1] = "hra"; 
        //             }
        //             spliter = spliter.join(" ");
        //             timeString = spliter;
        //         }
        //     }
        // }
        // durationText = `${timeString} aprox.`;
    } else {
        difficultyLabel = props.difficulty_level_name;
        // log("SOURCE");
        // log(source);

        // if (props.learning_style_id === 1) {
        //     const date1 = moment(dateUtils.ISODateToString(props.start_date));
        //     const date2 = moment(dateUtils.ISODateToString(props.end_date));

        //     // if (moment().isSameOrAfter(date1)) {
        //     //     const diff1 = Math.abs(date1.diff(date2, "days"));
        //     //     const diff2 = Math.abs(date1.diff(moment(), "days"));
        //     //     setProgressValue(Math.round((diff2 * 100) / diff1));
        //     // }

        //     durationIcon = <EventIcon fontSize="small" />;
        //     durationText = `${date1.utc().locale('es').format('DD MMMM')} - ${date2.utc().locale('es').format('DD MMMM, YYYY')}`;
        // }
    }

    let linkProps = {};

    if (register_id || props.learning_style_id === 3 /*source == "ADV"*/) {
        linkProps.to = { pathname: to, state: data };
        linkProps.target = "_self";
    } else {
        if(admission_id != null){
            if(admission_status === 3){
                linkProps.to = to;
                linkProps.target = "_blank";
            }
        }else{
            linkProps.to = to;
            linkProps.target = "_blank"; 
        }
    }

    return (
        <Card className={classes.root}>
            <CardActionArea component={RouterLink} {...linkProps} /*to={{pathname: to, state: data }} target={ register_id != null || register_id != "" || source == "ADV" ? "_self":"_blank" }*/>
                <React.Fragment>
                    <div className={classes.modalityChip}>
                        <Chip icon={<DesktopWindowsIcon />} label={"Online"} color="secondary" size="small" />
                        {/*course.lang === "en" && <Chip icon={<LanguageIcon />} label="En Inglés" color="secondary" size="small" />*/}
                        {/*course.environment && course.environment != "PROD" && <Chip icon={course.environment == "DEV" && <CodeIcon /> || course.environment == "TEST" && <SettingsIcon />} label={course.environment} color="primary" size="small" />*/}
                    </div>
                    <CardMedia className={classes.media} image={image_url}>
                        <CourseBanner {...props} />
                    </CardMedia>
                    <LinearProgress variant="determinate" value={progressValue} />
                </React.Fragment>
                <CardContent className={classes.content}>
                    <Box>
                        {/*<Typography variant="caption" color="textSecondary">{subject}</Typography>*/}
                        {/* <Typography variant="caption" color="secondary">CURSO</Typography> */}
                        <Typography variant="subtitle1" className={classes.header}>{name}</Typography>
                        <Grid container spacing={1} className="mt-2" >
                            <Grid item style={{ marginTop: source === "ADV" || source === "ADVENIO" ? "-6px" : "0px"  }}>
                                {
                                    source === "UERRE" || source === "U-ERRE" ? <img alt="logo" src={Uerre} className={classes.logoUO} style={{ height: "24px" }} /> :
                                        source === "UNID" ? <img alt="logo" src={Unid} className={classes.logoUO} /> :
                                            source === "HH" || source === "Harmon Hall" ? <img alt="logo" src={Harmonhall} className={classes.logoUO} style={{ height: "24px" }} /> :
                                                source === "ADV" || source === "ADVENIO" ? <img alt="logo" src={Advenio} className={classes.logoUO} style={{ height: "24px", maxHeight: "29px" }}/> :
                                                    source === "LinkedIn Learning" ? <img alt="logo" src={LinkedIn} className={classes.logoThirdParty} /> :
                                                        source === "Coursera" ? <img alt="logo" src={Coursera} className={classes.logoThirdParty} /> : null
                                                            // source == "Point" ? <Box display="flex" flexDirection="row"><img alt="logo" src={Point} className={classes.logoUO} /><Typography variant="h6" className={classes.brand}>POINT</Typography></Box> :
                                                                // <Typography variant="h6" className={classes.brand}>{source}</Typography>
                                }
                            </Grid>
                            {
                                difficultyLabel && (
                                    <Grid item>
                                        <Box display="flex" alignItems="flex-end">
                                            <EqualizerIcon fontSize="small" color="secondary" />&nbsp;
                                            <Typography component="span" variant="body2" color="secondary">{difficultyLabel}</Typography>
                                        </Box>
                                    </Grid>
                                )
                            }
                            {/* <Grid item>
                                <Box display="flex" alignItems="flex-end">
                                    {durationIcon}&nbsp;
                                    <Typography component="span" variant="body2" color="textSecondary">{durationText}</Typography>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </Box>
                    {/* {admission_id == null ? <ProgressWithLabel value={progressValue} /> : admission_status == 3 ? <ProgressWithLabel value={progressValue} /> :<Typography variant="caption" color="textSecondary">Pendiente de admisión</Typography> } */}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default CourseCard;