import React from "react";
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';
//@Material-ui
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
//Component
import getMembershipIcon from '../../Components/MembershipIcon';
//Styles
import useStyles from "../../../../../assets/styles/jss/pages/memberships/versionSKU/MembershipModalUpSelling/MembershipModalUpSellingStyle";

const MembershipCardUpSellingMain = ({membershipSelect, feeShow}) => {
    const classes = useStyles();
    const icon = getMembershipIcon(membershipSelect?.name.toLowerCase(),28, 26);
    return (
       <Box className="text-left"> 
            <Typography className={classes.cardTitleMain} >
                Has seleccionado    
            </Typography>
            <Card className={classes.cardMain}>
                <Grid container >
                    <Grid item sm={3}>
                        <Box className={classes.cardMainBox}>
                           {icon}
                        </Box>
                     </Grid>
                    <Grid item sm={9} className="pt-2">
                        <Typography variant="h5">Membresía {membershipSelect?.name} ({feeShow?.months} meses)</Typography>
                        <Typography variant="h4" color="secondary" component="span" className="pt-2">
                            <CurrencyFormat value={feeShow?.monthlyPrice} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix={feeShow?.currencySymbol} />
                            <Typography variant="h5" color="secondary" component="span"className={'ml-1'} >
                                {feeShow?.currencyCode}
                            </Typography>
                            <Typography className={clsx('ml-1', 'text-orange-300')} style={{ display: 'inline-block' }}>al mes</Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </Card> 
       </Box>
    )
}

export default MembershipCardUpSellingMain; 