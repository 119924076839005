import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Typography from '@material-ui/core/Typography';

function TextError({text, ...rest}) {
    return (
        <Typography variant="caption" color="error" {...rest}>{text}</Typography>
    );
}

TextError.propTypes = {
    text: PropTypes.string
};

export default TextError;