import React from 'react';
import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { SOURCE_NAME } from '../../../../shared/constants/sources';

const UserEduconCourses = (props) => {
    const { data, classes } = props;
    return (
        <React.Fragment>
            {data && data.courses && data.courses.map(courses => (
                <React.Fragment key={`rf_courses_${courses.id}`}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1"><b>{courses.name}</b></Typography>
                        <hr />
                    </Grid>
                    {courses.courses.map(course => (
                        <Table key={`table_course_${course.course_id}`}>
                            <TableBody>
                                <TableRow>
                                    <TableCell width="30%" className={`${classes.borderNone} ${classes.valignTop}`}>
                                        <Typography variant="body2" className="mt-1">{course.name}</Typography>
                                        <Typography variant="caption" color="textSecondary">{`id: ${course.course_id}`}</Typography>
                                    </TableCell>
                                    <TableCell width="70%" className={classes.borderNone} valign="top">
                                        <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`source_id: ${course.source_id}`}</Typography></li>
                                        <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`ally_name: ${SOURCE_NAME[course.source_id]}`}</Typography></li>
                                        <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`course_url: ${course.course_url}`}</Typography></li>
                                        <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`third_party_id: ${course.third_party_id}`}</Typography></li>
                                        <li className={`${classes.ul_li_list} ml-4`}><Typography variant="caption">{`modality_id: ${course.modality_id === 1 ? 'Presencial' : 'Online'}`}</Typography></li>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    ))}
                </React.Fragment>
            ))}
        </React.Fragment>
    )
};

export default UserEduconCourses;