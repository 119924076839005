import { makeStyles } from '@material-ui/core/styles';

const backgroundImageDefault = 'girl';
const backgroundImageName = 'banner-temp';

const heightResponsiveDefault = 629;
const paddingTopResponsiveDefault = 242;
const maxWidthResponsiveDefault = '100%';

const heightResponsive = 660;
const paddingTopResponsive = 297;
const maxWidthResponsive = '328px';

export default makeStyles((theme) => ({
    responsiveContainer:{
        position: 'relative',
        height: '100%',
        '& .container':{
            paddingTop: 161
        }
    },
    root:{
        position: 'relative',
        height: 629,
        '& .title':{
            maxWidth: '58%',
            marginBottom: 24,
            '& h1':{
                textAlign: 'left'
            }
        },
        '& .content':{
            maxWidth:'48%',
            marginBottom:69,
            textAlign: 'left'
        }
    },
    bg:{
        background: 'radial-gradient(135.10% 169.11% at 49.76% 38.34%, rgba(32, 7, 105, 0.72) 10.20%, rgba(27, 14, 63, 0.78) 28.76%, #38149B 44.49%, #030344 56.52%, #6441C3 64.99%);mix-blend-mode: soft-light;',
        height: '656.299px',
        position: 'absolute',
        width: '100%',
        right: 0,
        zIndex: 1,
    },
    bgTop:{
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: theme.palette.colors.fillStroke[300],
        left: 0
    },
    bgImage:{
        backgroundImage: `url(/images/home/${backgroundImageDefault}.png)`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        position: 'absolute',
    },
    actions:{
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            height: heightResponsiveDefault,
            '& .title':{
                maxWidth: `${maxWidthResponsiveDefault} !important`,
                margin: '0 auto',
                // default
                marginBottom: 25,
                marginBottom: 8,
                '& h1':{
                //default
                    fontWeight: '700 !important',
                    fontSize: '24px !important',
                    lineHeight: '26px !important',
                    textAlign: 'center'
                }
            },
            '& .content':{
                maxWidth:'100%',
                marginBottom:17,
                textAlign: 'center',
                '& h3':{ //temp
                    display: 'flex',
                    maxWidth: 230,
                    margin: 'auto'
                }
            }
        },
        responsiveContainer:{
            '& .container':{
                paddingTop: paddingTopResponsiveDefault
            }
        },
        bgImage:{
            backgroundImage: `url(/images/home/${backgroundImageDefault}-xs.png)`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            position: 'absolute',
        },
        actions:{
            flexDirection: 'column',
            '& .MuiButton-root':{
                width: '100%'
            }
        },
    }
}));