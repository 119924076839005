import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {Grid} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom:2,
    height: 35,
    paddingLeft:10
  },
  select:{
    flex:1,
    marginTop: '0px !important',
    paddingLeft:10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width:40
  },
  iconButton: {
    padding: 10,
    color: theme.palette.common.white,
  }
}));

const InputSearch = (prosp) => { 
  
  const {recurse,inputSearch,valueSearch, searchRecurse, onChangeSearch,onKeyDown, filter, handleChangeFilter, valueFilter} = prosp;

  const classes = useStyles();

  return (
      <>
      <Grid container spacing={1}>
        <Grid item xs={3}> 
          <Paper component="form" className={classes.root} >
            <InputLabel htmlFor="filled-age-native-simple">Buscar por  :  </InputLabel>
            <Select
              className={classes.select}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={valueFilter}
              onChange={handleChangeFilter}
              label="filter"
            >
            {filter.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.descripcion}
              </MenuItem>
            ))}
            </Select>
          </Paper>
        </Grid>
        <Grid item xs={5}> 
          <Paper component="form" className={classes.root} >
            <IconButton className={classes.iconButton} aria-label="search" onClick={searchRecurse}>
              <SearchIcon />
            </IconButton> 
            <InputBase
              onChange={onChangeSearch}
              className={classes.input}
              placeholder={`Escribe el ${recurse}`}
              name = {inputSearch}
              value={valueSearch}
              onKeyDown={onKeyDown}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
          </Paper>
        </Grid>
      </Grid>
      </>
    );
}

export default InputSearch;
