import * as profilePicTypes from '../types/profilePicTypes'

const INITIAL_STATE = {
  user: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case profilePicTypes.APPLY_PROFILE_PIC:
      return { ...state, profilePic: action.payload };
    default:
      return state;
  }
};