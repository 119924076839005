import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box, Icon } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    accordionContainer:{

    },
    root: {
        width: '100%',
    },
    accordion:{
        marginBottom: 0,
        '&.Mui-expanded':{
            border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
            borderLeft: 0,
            borderRight: 0,
            marginBottom: '-1px !important',
            borderRadius: 0,
            paddingBottom: 8
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordionSummary:{
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        borderLeft: 0,
        borderRight: 0,
        padding: '8px 16px 8px 8px',
        borderRadius: 0,
        backgroundColor: `${theme.palette.colors.fillStroke[400]}`,
        '& .MuiAccordionSummary-content':{
            order: 2,
            margin: 0,
            '&.Mui-expanded':{
                margin: '0 !important'
            },
        },
        '& .MuiIconButton-edgeEnd':{
            margin: '0px 8px 0px 0px',
            padding: 0
        },
        '&.Mui-expanded':{
            border: 0
        },
    },
    accordionDetails:{
        padding: '8px 24px 8px 48px',
        flexDirection: 'column',
        backgroundColor: `${theme.palette.colors.fillStroke[400]}`,
        gap: 8,
        '& .total-documents-content':{
            justifyContent: 'flex-start !important'
        }
    },
}));

export default function ResponsiveTableDocuments({rows = [], minHeight = 0}) {
    const classes = useStyles();

    return (
        <Box className={classes.accordionContainer} minHeight={minHeight}>{
            rows.map((itemObj, index) =>    
            <div className={classes.root} key={`responsive-table-documents-${index}`}>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                    expandIcon={<Icon className='ri-arrow-down-s-line text-orange-300 font-size-32' />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Box display='flex' width={1} flexDirection='column'>
                            <Typography variant='capiton' className='text-dark-black-200'>Nombre</Typography>
                            <Typography variant='body2'className={clsx('semi-bold')}>{itemObj.person_name}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Box display='flex' width={1} flexDirection='column'>
                            <Typography variant='capiton' className='text-dark-black-200'>Matrícula</Typography>
                            <Typography variant='body2'className={clsx('semi-bold')}>{itemObj.mat}</Typography>
                        </Box>
                        <Box display='flex' width={1} flexDirection='column'>
                            <Typography variant='capiton' className='text-dark-black-200'>Clave banner del programa</Typography>
                            <Typography variant='body2'className={clsx('semi-bold')}>{itemObj.third_party_id}</Typography>
                        </Box>
                        <Box display='flex' width={1} flexDirection='column'>
                            <Typography variant='capiton' className='text-dark-black-200'>Fecha de recepción</Typography>
                            <Typography variant='body2'className={clsx('semi-bold')}>{itemObj.date}</Typography>
                        </Box>
                        <Box display='flex'>
                            <Box display='flex' width={1} flexDirection='column'>
                                <Typography variant='capiton' className='text-dark-black-200'>Recibidos</Typography>
                                <Typography variant='body2'className={clsx('semi-bold')}>{itemObj.documentsRecived}</Typography>
                            </Box>
                            <Box display='flex' width={1} flexDirection='column'>
                                <Typography variant='capiton' className='text-dark-black-200'>Aprobados</Typography>
                                <Typography variant='body2'className={clsx('semi-bold')}>{itemObj.documentApproved}</Typography>
                            </Box>
                        </Box>
                        <Box display='flex' width={1} flexDirection='column' gridGap={4}>
                            <Typography variant='capiton' className='text-dark-black-200'>Acciones</Typography>
                            {itemObj.actions}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
        )            
            
        }
            
        </Box>
    );
}
