import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';

// style
import useStyles from '../../../assets/styles/jss/pages/referrals/referralCouponCodeStyle';
import { Link, Typography } from '@material-ui/core';
import { getCheckoutCookie } from '../../../shared/utils/checkoutSession.js';

const COUPON_CODE = 'R3F3R1D0';

const ReferralCouponCodeBanner = () => {
    const classes = useStyles();
    const [copied, setCopied] = React.useState(false);
    const checkoutCookie = getCheckoutCookie();

    const handleCopy = () => {
        navigator.clipboard.writeText(COUPON_CODE);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    if (!checkoutCookie?.isReferred) {
        return null;
    }

    return <Box className={classes.root}>
        <Typography variant="body1" className='mb-3'>Para obtener un descuento adicional por referidos, no olvides ingresar manualmente el código de cupón.</Typography>
        <Box className={classes.discountCouponContainer}>
            <Typography variant="h4" className='font-weight-700'>{COUPON_CODE}</Typography>
            <Link variant="caption" color='secondary' className='font-weight-600 font-size-12' onClick={handleCopy}>Copiar código</Link>
        </Box>
        <Collapse in={copied} className={classes.alert}>
            <Alert icon={<Icon className='ri-checkbox-circle-fill font-size-16' />} severity="success">Copiado</Alert>
        </Collapse>
    </Box>
}

export default ReferralCouponCodeBanner;