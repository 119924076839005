import palette from '../palette';

export default {
    filledError: {
        backgroundColor: '#8D2D3280',
        border: `0.5px solid ${palette.colors.error[300]}`,
    },
    filledInfo: {
        backgroundColor: '#B39D2F80',
        '& .MuiAlert-message': {
            width: '100%',
        },
    },
};