import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    banner: {
        // backgroundImage: 'url(/images/vocational-test/unid-papeletas.png)',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        paddingTop: 98,
        paddingBottom: 84,
        '& .banner-image':{
            position: 'absolute',
            top: '-142px',
            right: 0,
            height: 548,
            overflow: 'hidden',
            '& img':{
                width: 672
            }
        },
        [theme.breakpoints.only('md')]: {
            '& .banner-image':{
                width: '50%',
                '& img':{
                    width: '100% !important',
                    height: '100% !important',
                }
            }
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 38,
            paddingBottom: 39,
            '& .banner-image':{
                top: '-14px',
                height: 'auto',
                right: '-24px',
                '& img':{
                    width: '200px !important'
                }
            }
        },
        [theme.breakpoints.down('xs')]: {
            '& .banner-image':{
                '& img':{
                    width: '139px !important'
                }
            }
        }
    },
    title_container:{
        margin: '32px 0 24px',
        [theme.breakpoints.down('xs')]: {
            margin: '27px 0 36px'
        }
    },
    title:{
        width: 491,
        marginBottom: 32,
        [theme.breakpoints.down('xs')]: {
            width: 265,
            marginBottom: 16,
            '& h1, span':{
                ...theme.typography.h3,
            }
        }
    },
    subtitle:{
        width: 565,
        [theme.breakpoints.down('xs')]: {
            width: 224,
            '& h4':{
                ...theme.typography.h5
            }
        }
    },
    logo:{
        [theme.breakpoints.down('xs')]: {
            width: 85,
        }
    },
    offer:{
        padding: '107px 0 82px',
        [theme.breakpoints.down('sm')]: {
            padding: '49px 0 35px',
        }
    },
    offer_content:{
        paddingLeft: 113,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            '& .offer-items':{
                paddingTop: 38,
                margin: 0
            }
        }
    },
    offer_contentTitle:{
        width: 459,
        height: 113,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            height: 48,
            marginBottom: 27
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    offer_contentText:{
        height: 202,
        maxWidth: 448
    },
    offer_cardContent:{
        padding: '24px 25px 33px 20px',
        height: 321,
        display: 'inline-grid',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-between',
        '&:hover':{
            '& .bttn-link':{
                color: theme.palette.white
            }
        },
        [theme.breakpoints.down('sm')]: {
            height: 200,
            '& h3':{
                marginBottom: '12px !important'
            }
        }
    },
    find:{
        padding: '59px 0',
        [theme.breakpoints.down("sm")]: {
            padding: '28px 0',
            background: 'linear-gradient(179.08deg, #111111 16.24%, #3900D9 48.71%, #1F0076 88.96%)',
            '& .find-content':{
                flexDirection: 'column',
            }
        },
    },
    find_card:{
        height: '100%',
        maxWidth: 311,
        width: '100%',
        [theme.breakpoints.down("sm")]: {
            background: 'transparent',
            maxWidth: 'unset'
        }
    },
    find_cardContent:{
        padding: '38px 16px 37px !important',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        alignItems: 'flex-start',
        '& h2':{
            maxWidth: 236
        },
        [theme.breakpoints.down("sm")]: {
            '& h2':{
                maxWidth: '100%',
                marginBottom: 12
            },
            '& .bttn':{
                marginTop: 12
            }   
        }
    },
    find_content:{
        height: 295,
        display: 'flex',
        gap: 25,
        padding: '0px 25px',
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            flexDirection: 'column',
            height: 'fit-content',
            gap: 0
        }
    },
    find_fig_1:{
        width: 88,
        backgroundImage: 'url(/images/figures-1.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            height: 115,
            backgroundImage: 'url(/images/figures-2-responsive.svg)',
        },
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            height: 57,
            backgroundImage: 'url(/images/figures-2-responsive.svg)',
        },
    },
    find_fig_2:{
        flex: 1,
        backgroundImage: 'url(/images/figures-2.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    jobMarket:{
        padding: '51px 0 93px 113px',
        [theme.breakpoints.down('sm')]: {
            padding: '49px 0 35px',
        }
    },
    jobMarket_girl:{
        backgroundImage: 'url(/images/figures.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 341,
        position: 'relative',
        '& .image':{
            position: 'absolute',
            bottom: 0,
            right: '33%'
        },
        [theme.breakpoints.down('xs')]: {
            '& .image':{
                right: '20%'
            }
        }
    },
    jobMarket_content:{
        [theme.breakpoints.down('xs')]: {
            marginTop: 29,
            display: 'flex',
            order:2,
            flexDirection: 'column',
            alignItems: 'start'
        }
    },
    sectionContent:{
        paddingLeft: 113,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    }
}));