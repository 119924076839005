import React from 'react';
import { useHistory } from 'react-router-dom';
import CountUp from 'react-countup';
// styles
import { makeStyles/*, withStyles*/ } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import VisibilitySensor from 'react-visibility-sensor';
// shared
import Loader from '../../../../../shared/components/Loader/LoaderContent';
import NothingFound from '../../../../../shared/components/NothingFound/NothingFound';

// core
import CourseCard from '../../../CourseCatalog/CourseCard/CourseCard';
import Footer from '../../../../../layout/components/Footer/Footer';

//assets
import IconPoint from "../../../../../assets/images/logos/icon-point.svg";
/*import Uerre from "../../../../../assets/images/logos/landing-marcas/UERRE.svg";
import Unid from "../../../../../assets/images/logos/landing-marcas/UNID.svg";
import HarmonHall from "../../../../../assets/images/logos/landing-marcas/HarmonHall_s.svg";
import Advenio from "../../../../../assets/images/logos/landing-marcas/Advenio.svg";*/
import MuiKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import LineDow from "../../../../../assets/images/logos/linedow.svg";
import Uerre from "../../../../../assets/images/logos/landing-marcas/boxLogos/uerre.svg";
import Unid from "../../../../../assets/images/logos/landing-marcas/boxLogos/unid.svg";
import HarmonHall from "../../../../../assets/images/logos/landing-marcas/boxLogos/harmonhall.svg";
import Advenio from "../../../../../assets/images/logos/landing-marcas/boxLogos/advenio.svg";

import Colaboradores from "../../../../../assets/images/logos/landing-icons/colaboradores.svg";
import MaestrosCoaches from "../../../../../assets/images/logos/landing-icons/maestrosycoaches.svg";
import Exalumnos from "../../../../../assets/images/logos/landing-icons/exalumnos.svg";
import ConveniosEmpresariales from "../../../../../assets/images/logos/landing-icons/conveniosempresariales.svg";
import CentrosAprendizaje from "../../../../../assets/images/logos/landing-icons/centrosdeaprenizaje.svg";
import Paises from "../../../../../assets/images/logos/landing-icons/paises.svg";
import Ciudades from "../../../../../assets/images/logos/landing-icons/ciudades.svg";
import ClientesInstitucionales from "../../../../../assets/images/logos/landing-icons/clientesinstitucionales.svg";

// services

const useStyles = makeStyles((theme) => ({
    banner: {
        paddingTop: '0em',
        paddingBottom: '2em',
        marginBottom: "39px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    heading: {
        fontSize: "36px",
        fontWeight: "normal",
        flexShrink: 0,
        maxWidth: "670px",
        lineHeight: "40px",
        paddingLeft: "64px",
        color: '#FFFFFF',
        "@media (max-width: 750px)": {
            padding: '0px',
            maxWidth: "80%",
            lineHeight: "28px",
            fontSize: theme.typography.pxToRem(25),
        }
    },
    learn: {
        fontSize: "36px",
        fontWeight: "normal",
        flexShrink: 0,
        maxWidth: "662px",
        lineHeight: "42px",
        paddingLeft: "64px",
        color: '#FFFFFF',
        "@media (max-width: 750px)": {
            padding: '0px',
            maxWidth: "450px",
            lineHeight: "32px",
            fontSize: theme.typography.pxToRem(25),
        }
    },
    title: {
        fontSize: theme.typography.pxToRem(28),
        fontWeight: theme.typography.fontWeightBold,
        flexShrink: 0,
        textAlign: "center",
        marginBottom: "35px",
        "@media (max-width: 700px)": {
            lineHeight: "40px",
            fontSize: theme.typography.pxToRem(28),
        }
    },
    heroText: {
        fontSize: theme.typography.pxToRem(24),
        maxWidth: "775px",
        lineHeight: "32px",
        paddingLeft: "64px",
        color: '#FFFFFF',
        "@media (max-width: 750px)": {
            padding: '0px',
            maxWidth: "80%",
            lineHeight: "22px",
            fontSize: theme.typography.pxToRem(18),
        }
    },
    heroButton: {
        marginLeft: "64px",
        marginTop: "24px",
        "@media (max-width: 700px)": {
            marginLeft: "0px",
        }
    },
    hero: {
        boxSizing: "content-box",
        marginTop: "-95px",
        width: "calc(100%)",
        height: "400px",
        minHeight: '380px',
        background: "#EBFCFF",
        marginLeft: "-5em", // offset navbar.
        padding: "0px 80px",
        paddingTop: "200px",
        //paddingBottom: "30px",
        overflow: "hidden",
        backgroundImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url("https://blog.u-erre.mx/hubfs/diseno-sin-titulo-1.jpg")',
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("https://blog.u-erre.mx/hubfs/diseno-sin-titulo-1.jpg")',
        backgroundPosition: '0px 0px, 50% 40%',
        backgroundSize: 'cover, cover',

        "@media (max-width: 700px)": {
            paddingBottom: "50px",
            height: "auto",
            width: "93vw",
            padding: "0px 24px",
            paddingTop: "200px",
            marginLeft: "-2em",
        }
        
    },
    buttonVerMas: {
        fontWeight: '600',
        marginTop: "48px",
        textTransform: "none",
    },
    whiteBox:{
        background: "#ffffff !important",
        display:"none",
        "@media (max-width: 1324px)": {
            display:"inherit"
        },
        "@media (max-width: 1032px)": {
            display:"none"
        }
    },
    content: {
        paddingLeft: '64px',
        paddingRight: '64px',
        "@media (max-width: 640px)": {
            paddingLeft: '0px',
            paddingRight: '0px',
        }
    },
    containerUOlogos:{
        width: '100%', 
        maxWidth:"1200px", 
        marginLeft: "auto", 
        marginRight: "auto", 
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "row",
        justifyContent:"space-around", 
        alignItems:"baseline",
        "@media (max-width: 600px)": {
            justifyContent:"center", 
        }
    },
    logoUO:{
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: "1rem",
        marginBottom: "40px",
    
    },
    cardBrands:{
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "0px"
    },
    cardIcon:{
        width: "272px",
        height: "223px",
        justifyContent: "center",
        borderRadius: "8px",
        background: "#F8FAFC",
        marginLeft: "9.5px",
        marginRight: "9.5px",
        marginBottom: "19px",
        textAlign: "center"
    }
}));

const APPBAR_HEIGHT = 64;
const DEFAULT_PAGE_SIZE = 15;

const AboutUs = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [pageNo, setPageNo] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(false);
    const [criteria, setCriteria] = React.useState(null);
    const browseScrollRef = React.useRef(null);
    const pageRef = React.useRef({ pageNo, lastPage });
    pageRef.current = ({ pageNo, lastPage, criteria });

    React.useEffect(() => {
        handleScroll();
    }, []);

    const handleViewMore = async () => {
        history.push("/#browse");
    };

    const handleScroll = () => {
        if (browseScrollRef.current) {
            const scrollTop = browseScrollRef.current.offsetTop - APPBAR_HEIGHT;

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            <Box className={classes.hero}>
                <Typography className={classes.heading}>Te imaginas poder acceder al aprendizaje en formatos prácticos y a la medida, <span style={{ fontWeight: "bold" }}>con validez de instituciones educativas reconocidas en México</span></Typography>
                <Typography className={"mt-4 " + classes.heroText} variant="body1">Eso es Talisis, cursos co-creados y certificados por las instituciones educativas que conforman Talisis.<br></br> <span style={{ fontWeight: "bold" }}>Accede a una selección de cursos para impulsar tu desarrollo</span></Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="mt-5 mb-5">
                <img alt="logo" src={IconPoint} className={classes.logoUO}  style={{ marginTop: "3px" }}/>
                <Typography variant="subtitle2" className={classes.learn } style={{ color: "#454E5E", textAlign: "center", marginBottom: "65px" }}>Aprende de profesionales certificados por instituciones como</Typography>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className='mt-4' style={{ width: '100%' }}>
                    
                   
                        <Box className={classes.containerUOlogos}>
                            <Box display="flex" className={classes.cardBrands}>
                                <img alt="logo" src={Advenio} className={classes.logoUO + " mb-3"} style={{ maxWidth:"306px", marginLeft: "auto", marginRight: "auto" }} />
                                <img alt="logo" src={LineDow} className={classes.logoUO + " mb-5"} style={{ width: "92%" }}/>
                                <Typography variant="subtitle2" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "392px", fontWeight: "normal", marginBottom: "0px"  }}>Centros de desarrollo infantil especializados en la estimulación, la educación y el desarrollo de bebés, niñas y niños de 43 días a 6 años.<br></br><br></br> Con más de 10 años de experiencia, <span style={{ fontWeight: "bold" }}>Advenio</span> existe para dar a los padres de familia la tranquilidad y la posibilidad de seguir su desarrollo personal y profesional sabiendo que sus hijos están en las mejores manos. </Typography>
                            </Box>
                            <Box display="flex" className={classes.cardBrands}>
                                <img alt="logo" src={HarmonHall} className={classes.logoUO + " mb-3"} style={{ maxWidth:"250px", marginLeft: "auto", marginRight: "auto" }}/>
                                <img alt="logo" src={LineDow} className={classes.logoUO + " mb-5"} style={{ width: "92%" }}/>
                                <Typography variant="subtitle2" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "392px", fontWeight: "normal", marginBottom: "0px"  }}>Empresa líder en México en la enseñanza del idioma inglés con experiencia de más de 55 años.<br></br><br></br> <span style={{ fontWeight: "bold" }}>Harmon Hall</span> se ha convertido en la mejor manera de aprender inglés gracias a su calidad en el servicio y gran prestigio.</Typography>
                            </Box>
                            <Box display="flex" className={classes.cardBrands}>
                                <img alt="logo" src={Uerre} className={classes.logoUO + " mb-3"} style={{ maxWidth:"306px", marginLeft: "auto", marginRight: "auto" }}/>
                                <img alt="logo" src={LineDow} className={classes.logoUO + " mb-5"} style={{ width: "92%" }}/>
                                <Typography variant="subtitle2" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "392px", fontWeight: "normal", marginBottom: "0px"  }}>La <span style={{ fontWeight: "bold" }}>Universidad Regiomontana</span> cuenta con más de 52 años de experiencia promoviendo el desarrollo integral y capacitación de alta calidad a profesionales competitivos y ciudadanos socialmente responsables.</Typography>
                            </Box>
                            <Box display="flex" className={classes.cardBrands}>
                                <img alt="logo" src={Unid} className={classes.logoUO + " mb-3"} style={{ maxWidth:"306px", marginLeft: "auto", marginRight: "auto" }}/>
                                <img alt="logo" src={LineDow} className={classes.logoUO + " mb-5"} style={{ width: "92%" }}/>
                                <Typography variant="subtitle2" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "392px", fontWeight: "normal", marginBottom: "0px"  }}>La <span style={{ fontWeight: "bold" }}>Universidad Interamericana para el Desarrollo</span> promueve la formación Integral de las personas, ofreciendo una educación de calidad con experiencias laborales y formativas, creando profesionales competitivos y socialmente responsables.<br></br><br></br> Cuenta con 48 campus en 23 estados de República, y 21 años de trayectoria.</Typography>
                            </Box>
                        </Box> 

                </Box>
            </Box>
            <div ref={browseScrollRef}>
                <Box className={classes.banner}>
                    <Typography className={classes.title} style={{ maxWidth: "345px", lineHeight: "35px", color: "#454E5E", fontWeight: "normal", marginTop: "110px" }}>Nuestra experiencia <span style={{ fontWeight: "bold" }}>a través de las personas</span></Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "754px", fontWeight: "normal" }}>Hasta hoy hemos logrado impactar de manera positiva la vida de millones de personas y sus entornos, impulsándolos a través de distintos programas de formación y desarrollo profesional y personal.</Typography>
                </Box>
            </div>


          { /* import Colaboradores from "../../../../../assets/images/logos/landing-icons/colaboradores.svg";
import MaestrosCoaches from "../../../../../assets/images/logos/landing-icons/maestrosycoaches.svg";
import Exalumnos from "../../../../../assets/images/logos/landing-icons/exalumnos.svg";
import ConveniosEmpresariales from "../../../../../assets/images/logos/landing-icons/conveniosempresariales.svg";
import CentrosAprendizaje from "../../../../../assets/images/logos/landing-icons/centrosdeaprenizaje.svg";
import Paises from "../../../../../assets/images/logos/landing-icons/paises.svg";
import Ciudades from "../../../../../assets/images/logos/landing-icons/ciudades.svg";
import ClientesInstitucionales from "../../../../../assets/images/logos/landing-icons/clientesinstitucionales.svg";*/}
            
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" flexWrap="wrap" style={{ maxWidth: "1200px", marginLeft: "auto", marginRight:"auto" }}>
                <Box className={classes.cardIcon} display="flex" flexDirection="column">
                    <img alt="Colaboradores" src={Colaboradores} className={"mb-3"} style={{ maxWidth:"187px", marginLeft: "auto", marginRight: "auto" }}/>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "bold" }}><span>+ </span> 
                    <CountUp end={2400} redraw={true}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp>
                    </Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "normal" }}>Colaboradores</Typography>
                </Box>
                <Box className={classes.cardIcon} display="flex" flexDirection="column">
                    <img alt="Maestros y Coaches" src={MaestrosCoaches} className={"mb-3"} style={{ maxWidth:"187px", marginLeft: "auto", marginRight: "auto" }}/>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "bold" }}><span>+ </span> 
                    <CountUp end={8000} redraw={true}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp></Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "normal" }}>Maestros y Coaches</Typography>
                </Box>
                <Box className={classes.cardIcon} display="flex" flexDirection="column">
                    <img alt="Exalumnos" src={Exalumnos} className={"mb-3"} style={{ maxWidth:"187px", marginLeft: "auto", marginRight: "auto" }}/>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "bold" }}><span>+ </span> 
                    <CountUp end={2150000} redraw={true}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp></Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "normal" }}>Exalumnos</Typography>
                </Box>
                <Box className={classes.cardIcon} display="flex" flexDirection="column">
                    <img alt="Convenios Empresariales" src={ConveniosEmpresariales} className={"mb-3"} style={{ maxWidth:"187px", marginLeft: "auto", marginRight: "auto" }}/>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "bold" }}><span>+ </span> 
                    <CountUp end={5000} redraw={true}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp></Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "normal" }}>Convenios Empresariales</Typography>
                </Box>
                <Box className={classes.cardIcon} display="flex" flexDirection="column">
                    <img alt="Centros de Aprendizaje" src={CentrosAprendizaje} className={"mb-3"} style={{ maxWidth:"187px", marginLeft: "auto", marginRight: "auto" }}/>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "bold" }}><span>+ </span>
                    <CountUp end={160} redraw={true}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp></Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "normal" }}>Centros de Aprendizaje</Typography>
                </Box>
                <Box className={classes.cardIcon} display="flex" flexDirection="column">
                    <img alt="Países" src={Paises} className={"mb-3"} style={{ maxWidth:"100px", marginLeft: "auto", marginRight: "auto" }}/>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "bold" }}> 
                    <CountUp end={5} redraw={true}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp></Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "normal" }}>Países</Typography>
                </Box>
                <Box className={classes.cardIcon} display="flex" flexDirection="column">
                    <img alt="Ciudades" src={Ciudades} className={"mb-3"} style={{ maxWidth:"187px", marginLeft: "auto", marginRight: "auto" }}/>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "bold" }}>
                    <CountUp end={80} redraw={true}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp></Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "normal" }}>Ciudades</Typography>
                </Box>
                <Box className={classes.cardIcon} display="flex" flexDirection="column">
                    <img alt="Clientes Institucionales" src={ClientesInstitucionales} className={"mb-3"} style={{ maxWidth:"187px", marginLeft: "auto", marginRight: "auto" }}/>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "bold" }}><span>+ </span> 
                    <CountUp end={300} redraw={true}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp></Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "16px", textAlign: "center", maxWidth: "657px", fontWeight: "normal" }}>Clientes Institucionales</Typography>
                </Box>
                <Box className={classes.cardIcon + " " + classes.whiteBox} display="flex" flexDirection="column">
                    
                </Box>
            </Box>
            

            <Box className={classes.banner}>
                    <Typography className={classes.title + " mt-4"} style={{ maxWidth: "445px", lineHeight: "42px", color: "#454E5E", fontWeight: "normal" }}>Aprendizaje Experencial</Typography>
                    <Typography variant="subtitle2" className="mb-1" style={{ color: "#8D8D8D", fontSize: "24px", lineHeight:"27px", textAlign: "center", maxWidth: "495px", fontWeight: "normal" }}>+150,000 alumnos impulsando su educación en México y Latinoamérica</Typography>
                    <Box display="flex" width="100%" justifyContent="center">
                        <Button variant="contained" size="large" className={classes.buttonVerMas} color="primary" onClick={handleViewMore}>Ver cursos<MuiKeyboardArrowRight fontSize="medium" /></Button>
                    </Box>
            </Box>
            {/* <Footer></Footer> */}
        </div>
    );
};

export default AboutUs;