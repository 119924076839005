import palette from '../palette';

export default {
    root: {
        '&.TextField-warning':{
            '& .MuiFormHelperText-root':{
                margin: '8px 0px 0px',
                color:  `${palette.colors.warning[400]} !important`
            }
        },
        '&.TextField-error':{
            '& .MuiFormHelperText-root':{
                margin: '8px 0px 0px',
                color:  `${palette.colors.error[300]} !important`
            }
        },
        '&.TextField-success':{
            '& .MuiFormHelperText-root':{
                margin: '8px 0px 0px',
                color:  `${palette.colors.success[300]} !important`
            }
        },
        '& label': {
            color: `${palette.colors.gray[100]} !important`
        },
        '& .MuiOutlinedInput-input:not(.MuiInputBase-inputAdornedStart,.MuiInputBase-inputAdornedEnd), .MuiOutlinedInput-adornedStart':{
            '&:focus': {
                borderRadius: 8,
                backgroundColor: palette.colors.fillStroke[400]
            }
        },
        '&.input-text-plain': {
            '& label':{
                color: `${palette.colors.darkBlack[200]} !important`
            },
            '& .MuiOutlinedInput-root, .Mui-disabled .MuiOutlinedInput-notchedOutline':{
                border: 'unset !important',
                '& input':{
                    backgroundColor: 'transparent !important',
                    padding: '0 !important'
                },
                '& fieldset':{
                    border: 'unset !important',
                }
            },
            '& .MuiOutlinedInput-input:not(.MuiInputBase-inputAdornedStart,.MuiInputBase-inputAdornedEnd), .MuiOutlinedInput-adornedStart':{
                backgroundColor: 'transparent',
                padding: '0 !important',
                color: palette.white,
                cursor: 'text'
            }
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
            transform: 'translate(0px, -9px)',
            fontWeight: 600,
            fontSize: 14,
        }
    }
};