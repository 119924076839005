import React, { useState } from 'react';

import { Box, Button, Grid, Icon, InputAdornment, Link, TextField, Tooltip, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';

// hooks
import { useForm } from '../../../../Account/Profile/UserPreferences/hooks/useForm';

import Login from '../../../../../layout/components/Login/Login';

import { MarketPlaceService } from '@sdk-point/talisis';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { getCheckoutCookie, setCheckoutCookie } from '../../../../../shared/utils/checkoutSession-legacy.js';
import StudentFormModal from './StudentFormModal/StudentFormModal';
import { validateFormFields } from './helpers/validateFormFields';
import CheckTermsConditions from '../../../../../shared/components/CheckTermsConditions/CheckTermsConditions';
import { log } from '../../../../../shared/utils/console.js'

import { useDispatch, useSelector } from 'react-redux';
import { setOrderQuantities, setPersonIdToPurchaseOrder, setPersonInfo, setPurchaseOrder } from '../../../../../redux/actions/legacyCheckoutActions.js';
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

const StudentInfo = (props) => {

    const { order, handleIsActiveStep, summaryStep = 2 } = props;
    const { setStep, nextStep, setAdmisionId } = props;
    const theme = useTheme();
    const history = useHistory();
    const checkoutCookie = getCheckoutCookie();

    // Checkout Reducer
    const { order: orderReducer, orderQuantity } = useSelector((state) => state.legacyCheckoutReducer);
    const dispatch = useDispatch();
    
    const [loginScreen, setLoginScreen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registerResponse, setRegisterResponse] = useState("");
    const [loginInvite, setLoginInvite] = useState(false);
    const [alertHidden, setAlertHidden] = useState(true);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const { formulario, handleInputChange } = useForm({
        firstName: "",
        lastName1: "",
        lastName2: "",
        phoneNumber: "",
        email1: "",
        email2: "",
    });
    const { firstName, lastName1, lastName2, phoneNumber, email1 } = formulario;

    const updateOrderUser = async () => {
        try {
            setLoading(true);

            const response = await marketService.registerUpdateOrder({ person_id: 0 },{
                // orderId: order.id,
                orderId: orderReducer.id,
                first_name: firstName,
                last_name: lastName1 + " " + lastName2,
                email: email1,
                phone: phoneNumber,
                // location: null,
                location: window.location.origin,
                sso: false,
                isLogin: false
            });
            if( response?.process_status === "CREATED"){
                setCheckoutCookie({
                    ...checkoutCookie,
                    anonymousSession: false,
                    personId: response?.personInfo.id,
                    personInfo: response?.personInfo,
                    activateAccountURL: response?.activateAccountURL,
                    activeAccount: false
                })
                dispatch(setPersonInfo(response.personInfo))
                dispatch(setPersonIdToPurchaseOrder(response.person_id))
                nextStep()
                setStep(1)
            }
            if( response?.process_status === "DUPLICATE"){
                setRegisterResponse(response)
                setLoginInvite(true);
                setAlertHidden(false)
            }
            setRegisterResponse(response);
            setLoading(false);
        }
        catch (e) {
            setLoading(false);;
            // log("error de orden: ",e);
        }
    };

    const handleCheckoutLogin = () => {
        setLoginScreen(true)
        history.replace({
            ...history,
            state: {
                ...history.location.state,
                'referrer': "checkout-login-SSO"
            }
        })
    }
        
    const handleProceed2Pay = async () => {
        const { _errors, isFormComplete } = validateFormFields({
            formFields: formulario
        });

        if(!isFormComplete) return setFormErrors({..._errors});

        await handleProspectCRM();

        if(isFormComplete){
            setFormErrors({});
            updateOrderUser();
        }
    }

    const handleProspectCRM = async () =>{
        log('entro funcion')
        try{
            setLoading(true);
            const responseProspectCRM = await marketService.postProspectCRM(formulario);
            const eventData = {};
            eventData['source'] = 'checkout';
            eventTracker('crear_cuenta', eventData, ['mixpanel']);
            // log('result: ', responseProspectCRM);
            setAdmisionId(responseProspectCRM.data);
            //setLoading(false);
        }catch (err){
            // log('error lamnda', err)
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (!loginScreen) {
            const eventData = {};
            eventTracker('s_signup_checkout', eventData, ['mixpanel']);
        }
    }, [loginScreen]);
    
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid container className='mt-3' alignItems='baseline' justifyContent='space-between' style={{ borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`}}>
            {
                !loginScreen
                ? <>
                    <Typography variant='h3' style={{ color: theme.palette.colors.violet[300] }}>
                        Creación de cuenta
                    </Typography>
                    <Grid item xs={12} className='mt-5'>
                        <Typography variant='body1'>
                            ¿Ya tienes cuenta?
                            <Link className='ml-2' onClick={handleCheckoutLogin} style={{ cursor: 'pointer', color: theme.palette.colors.orange[300] }}>
                                Inicia sesión aquí
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} className="my-4">
                        <Grid item md={10} xs={12} className='mb-4'>
                            {/* <Typography variant="body1" className="semi-bold">Usuario*</Typography> */}
                            <TextField
                                name="email1"
                                variant="outlined"
                                placeholder="Ingresar correo electrónico"
                                fullWidth
                                required
                                size="small"
                                onChange={handleInputChange}
                                error={Boolean(formErrors.email1)}
                                helperText={formErrors.email1}
                                InputLabelProps={{ shrink: true }}
                                label="Usuario"
                            />
                        </Grid>
                        <Grid item md={10} xs={12} className='mb-4'>
                            {/* <Typography variant="body1" className="semi-bold">Confirma usuario*</Typography> */}
                            <TextField
                                name="email2"
                                variant="outlined"
                                placeholder="Confirmar correo electrónico"
                                fullWidth
                                required
                                size="small"
                                onChange={handleInputChange}
                                error={Boolean(formErrors.email2)}
                                helperText={formErrors.email2}
                                InputLabelProps={{ shrink: true }}
                                label="Confirma usuario"
                            />
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <Typography className='text-dark-black-100' variant='caption'>
                                El correo electrónico que elegiste será tu usuario para iniciar sesión y el medio principal de contacto. Asegúrate de que sea una dirección a la que siempre tengas acceso.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                        <Typography variant="h5">Información del estudiante</Typography>
                    </Grid>
                    <Grid item md={10} xs={12} className='mb-4'>
                        {/* <Typography variant="body1" className="semi-bold">Nombre del alumno*</Typography> */}
                        <TextField
                            name="firstName"
                            variant="outlined"
                            fullWidth
                            size="small"
                            required
                            placeholder="Ingresa tu(s) nombre(s)"
                            onChange={handleInputChange}
                            error={Boolean(formErrors.firstName)}
                            helperText={formErrors.firstName}
                            InputLabelProps={{ shrink: true }}
                            label="Nombre (s)"
                        />
                    </Grid>
                    <Grid container spacing={2} className='mb-4 mt-1'>
                        <Grid item md={5} xs={12} className='mb-4'>
                            {/* <Typography variant="body1" className="semi-bold">Apellido paterno*</Typography> */}
                            <TextField
                                name="lastName1"
                                variant="outlined"
                                placeholder="Ingresa tu apellido paterno"
                                fullWidth
                                required
                                size="small"
                                onChange={handleInputChange}
                                error={Boolean(formErrors.lastName1)}
                                helperText={formErrors.lastName1}
                                InputLabelProps={{ shrink: true }}
                                label="Apellido paterno"
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            {/* <Typography variant="body1" className="semi-bold">Apellido materno*</Typography> */}
                            <TextField
                                name="lastName2"
                                variant="outlined"
                                placeholder="Ingresa tu apellido materno"
                                fullWidth
                                required
                                size="small"
                                onChange={handleInputChange}
                                error={Boolean(formErrors.lastName2)}
                                helperText={formErrors.lastName2}
                                InputLabelProps={{ shrink: true }}
                                label="Apellido materno"
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={10} xs={12} className='mb-4 mt-1'>
                        {/* <Typography variant="body1" className="semi-bold">Número de celular*</Typography> */}
                        <TextField
                            name="phoneNumber"
                            variant="outlined"
                            placeholder="Número a 10 dígitos"
                            fullWidth
                            required
                            size="small"
                            inputProps={{ maxLength: 10 }}
                            onChange={handleInputChange}
                            error={Boolean(formErrors.phoneNumber)}
                            helperText={formErrors.phoneNumber}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+52</InputAdornment>,
                                shrink: "true"
                            }}
                            label="Número de celular"
                        />
                    </Grid>
                    <Typography className='mb-4' variant='caption' style={{ color: theme.palette.colors.darkBlack[200] }}>
                        *Campos obligatorios
                    </Typography>
                    <Grid item md={10} xs={12} className='mb-4'>
                        <CheckTermsConditions
                            accept={acceptTerms}
                            startText="Confirmo que todos los datos son correctos y acepto los"
                            colorLink="secondary"
                            onChange={() => setAcceptTerms(!acceptTerms)}
                            variant="body2"
                            endText=""
                            secondaryLinkText="las políticas de privacidad."
                        />
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                        <Box hidden={alertHidden}>
                            <Alert onClose={() => setAlertHidden(true)} variant="outlined" severity="warning" hidden={true}>
                                <AlertTitle>{registerResponse?.message_id}</AlertTitle>
                                {
                                    loginInvite &&
                                    <>
                                        <Typography variant='caption'>
                                            Te invitamos a
                                            <Link className='mx-1' onClick={handleCheckoutLogin} style={{ cursor: 'pointer', color: theme.palette.colors.orange[300] }}>
                                                inicia sesión
                                            </Link>
                                            para continuar tu compra
                                        </Typography>
                                        <Typography component="div" variant='caption'>
                                            O cerrar este mensaje y registrarte con un correo diferente
                                        </Typography>
                                    </>
                                }
                            </Alert>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                        <Grid container justifyContent='flex-start'>
                            <Button fullWidth={breakpoint} disabled={!acceptTerms} onClick={handleProceed2Pay} color='primary' variant='contained'>
                                Siguiente
                            </Button>
                        </Grid>
                    </Grid>
                </>
                : <>
                    <Grid item xs={12}>
                        <Button onClick={() => setLoginScreen(false)}>
                            <Typography variant='caption' style={{ cursor: 'pointer', color: theme.palette.colors.orange[300] }}>
                                <i className="ri-arrow-left-line mr-1" style={{ verticalAlign: 'text-bottom', display: 'inline-flex'}} />
                                Regresar
                            </Typography>
                        </Button>
                        <Login />
                    </Grid>
                </>
            }
        <StudentFormModal
            modalOpenFunc={loading} modalCloseFunc={!loading} 
            loading={loading}
        />
        </Grid>
        
    )
}

export default StudentInfo
