import React from 'react';
import { useParams } from "react-router-dom";

// @material-ui/core components
import { Box } from '@material-ui/core';

// services
import { OrderService } from '@sdk-point/talisis';

//shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import { log } from '../../../shared/utils/console.js'

// components
import PaymentConfirmationHeader from './PaymentConfirmationHeader/PaymentConfirmationHeader';
import PaymentConfirmationBody from './PaymentConfirmationBody/PaymentConfirmationBody';

const OrderServiceSDK = new OrderService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const PaymentConfirmation = () => {
    const { order_id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [order, setOrder] = React.useState({ id: 0 });
    const [orderDetails, setOrderDetails] = React.useState([]);
    const [orderPayments, setOrderPayments] = React.useState([]);
    const [personInfo, setPersonInfo] = React.useState({});

    const getOrder = async () => {
        try {
            setLoading(true);
            const response = await OrderServiceSDK.getOrderById(order_id);
            setOrder(response);
            setOrderDetails(response.order_details);
            setOrderPayments(response.order_payments);
            setPersonInfo(response.person_info);
            setLoading(false);
        } catch (e) {
            log("error", e);
        }
    };

    React.useEffect(() => {
        getOrder();
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <Loader loading={loading} transparent={false} style={{ minHeight: loading ? "calc(90vh - 32px)" : "inherit", borderRadius: "8px", paddingTop: "40px", marginTop: "-65px" }}>
                {order?.id > 0 && (
                    <Box mt="40px">
                        <PaymentConfirmationHeader
                            order={order}
                            orderDetails={orderDetails}
                            orderPayments={orderPayments}
                        />
                        <PaymentConfirmationBody
                            order={order}
                            orderDetails={orderDetails}
                            orderPayments={orderPayments}
                            personInfo={personInfo}
                        />
                    </Box>
                )}
            </Loader>
        </React.Fragment>
    );
};

export default PaymentConfirmation;