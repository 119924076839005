import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

import { v4 as uuid } from 'uuid';

export default function CardAlliesSkeletonLoading(props) {
    const {numItems} = props;
    return (
        <Box display="flex" gridGap={24} position="absolute">
            {
                [...Array(numItems).keys()].map(it =>                     
                    <Card variant="outlined" style={{width: 393}} key={uuid()}>
                        <CardMedia>
                            <Box className="card-skeleton" height={82} />
                        </CardMedia>
                        <CardContent className="p-0">
                            <Box p="11px 16px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gridGap={49} height={78}>
                                <Box>
                                    <Box className="card-skeleton" width={52} height={16} borderRadius={45} mb={1}/>
                                    <Box className="card-skeleton" width={93} height={16} borderRadius={30}/>
                                </Box>
                                <Box height={32}>
                                    <Box className="card-skeleton" width={73} height={16} borderRadius={45}/>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                )
            }
        </Box>
    );
}

CardAlliesSkeletonLoading.defaultProps = {
    numItems:6,
};

CardAlliesSkeletonLoading.propTypes = {
    numItems: PropTypes.number
};