import React, { Component } from 'react';
import { generatePath } from "react-router";

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

// shared
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent';
import Toast from '../../../../../shared/components/Toast/Toast';

// coree components
import DocumentUploadList from './components/DocumentUploadList';

// import data from '../../../../../shared/mock/request-detail-sending-docs';
import Deadline from './components/Deadline';
import PhysicalDocuments from './components/PhysicalDocuments';
import AcceptTerms from './components/AcceptTerms';
import DownloadableFiles from './components/DownloadableFiles';

//SDK
import * as routerlinks from '../../../../../routes/routelinks'
import {REQUEST_STATUS_ID} from '../../../../../shared/constants/request-status'
import { GlobalService,RequestService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

class SendingDocuments extends Component {
    state = {
        loading: true,
        data: null ,
        fileList: [],
        checkedG: false,
        toastProps: { message: "", open: false },
        acceptTerms: false,
        activateDocDelivery: false
    }

    constructor(props){
        super(props);
    }

    componentDidMount() {

        const {id} = this.props.match.params;

        this.getRequestWorkflow(id)
    }

    getRequestWorkflow = async (id) => {
        try {
            const data = await RequestServiceSDK.getRequestWorkflow(id);

            const files = data.workflow[0].form[0].options;
            const name = data.workflow[0].form[0].name;
    
            let fileList = [];
    
            for(const file of files){
                const fileFormValueObj = data.workflow[0].form_value[name].find(it => it.optionName === file.name);
    
                const value = fileFormValueObj.hasOwnProperty('fileName') ? data.optionFiles.find(it => it.file_name === fileFormValueObj.fileName) : null;
    
                fileList.push({
                    ...file,
                    value
                })
            }

            const fileValues = fileList.map(it => ({...it.value}));
        
            this.setState({
                ...this.state,
                loading: false,
                fileList,
                data,
                activateDocDelivery: fileValues.every(item1 => data.optionFiles.some(item2 => item1.id === item2.id))
            })
        } catch (e) {
            this.setState({ ...this.state, loading: false, toastProps: { severity: "error", open: true, message: "Ops! ha ocurrido un error." } });
        }
    }

    handleChangeFile = async optFile => {
        this.setState({...this.state, loading: true});
        const {data, fileList} = this.state;
        const {id: requestStepId, form, form_value} = data.workflow[0];
        const firstStepFilesName = form[0].name;

        const _form_value = form_value[firstStepFilesName].map(it => {
            if(it.optionName === optFile.optionName){
                it.fileName= optFile.file.name
            }
            return it;
        });

        const body = {
            id: requestStepId,
            form_value: {
                [firstStepFilesName]: _form_value
            }
        }

        try {
            
            const responseUploadFile = await GlobalServiceSDK.uploadFile(optFile.file, 'requests', 'request-step', data.id);
            await RequestServiceSDK.updateRequestWorkflowDetail(body);
            
            responseUploadFile.file_name = responseUploadFile.fileName;
            fileList[fileList.findIndex(it => it.name === optFile.optionName)].value = responseUploadFile;
            data.optionFiles.push(responseUploadFile);
    
            this.setState({
                ...this.state,
                data,
                fileList,
                loading: false,
                toastProps: { open: true, message: "El documento ha sido guardado correctamente." },
                activateDocDelivery: fileList.length === data.optionFiles.length
            })
            
        } catch (error) {
            this.setState({
                ...this.state,
                loading: false,
                toastProps: { severity: "error", open: true, message: "Ops! Ha ocurrido un error." }
            })
        }
    }

    handleFinishedToast = () => {
        this.setState({ ...this.state, toastProps: { message: "", open: false }});
    }

    handleChangeAcceptTerms = (e) => {
        this.setState({...this.state, acceptTerms: e.target.checked})
    }

    handleClickDeleteFile = async ({id}) => {
        this.setState({...this.state, loading: true});
        const {data, fileList} = this.state;
        const {id: requestStepId, form, form_value} = data.workflow[0];
        const firstStepFilesName = form[0].name;
        const indexOptFile = fileList.findIndex(it => it.value?.id === id );
        const optFile = fileList[indexOptFile].name;
        
        form_value[firstStepFilesName].map(it => {
            if(it.optionName === optFile){
                delete it.fileName;
            }
            return it;
        })

        const body = {
            id: requestStepId,
            form_value
        }

        try {
            await GlobalServiceSDK.deleteFile(id);
            await RequestServiceSDK.updateRequestWorkflowDetail(body);

            fileList[indexOptFile].value = null;
            data.optionFiles = data.optionFiles.filter(it => it.id !== id);

            this.setState({
                ...this.state,
                data,
                fileList,
                loading: false,
                toastProps: { open: true, message: "El documento ha sido borrado correctamente." },
                activateDocDelivery: fileList.length === data.optionFiles.length,
                acceptTerms: false
            })
            
        } catch (error) {
            this.setState({
                ...this.state,
                loading: false,
                toastProps: { severity: "error", open: true, message: "Ops! Ha ocurrido un error." }
            })
        }
        
    }

    handleClickAcceptTerms = async () => {
        this.setState({...this.state, loading: true});
        const {data:{id, person_id, workflow}} = this.state;
        const activeStep = workflow[0];

        const body = {
            person_id,
            request_workflow_id: activeStep.id,
            comments:"",
            reassign_to_step:null,
            has_file:0,
            form_value: activeStep.form_value
        }
        
        try {
            await RequestServiceSDK.updateRequestWorkflow(id, body, REQUEST_STATUS_ID.APPROVE);
            
            this.setState({
                ...this.state,
                toastProps: { open: true, message: "Los documentos se han enviado correctamente" },
            })
            this.props.history.push(generatePath(routerlinks.SOLICITUDES_DETALLE, {id}));
            
        } catch (error) {
            this.setState({
                ...this.state,
                toastProps: { severity: "error", open: true, message: "Ops! Ha ocurrido un error." }
            })
        }
        
    }    

    handleClickGoBack = (e) => {
        e.preventDefault();
        this.props.history.push(routerlinks.SOLICITUDES);
    }
    
    render() {
        const { classes } = this.props;
        const {loading, data, fileList, activateDocDelivery, toastProps, acceptTerms} = this.state;

        return (
            <Box>
                <Button startIcon={<NavigateBeforeIcon fontSize="small" className="text-orange-300"/> } onClick={this.handleClickGoBack} className="mb-4">
                    <Typography variant="h6" className="text-orange-300">Regresar a mis solicitudes</Typography>
                </Button>
                <Grid container>
                    <Grid container item xs={8} style={{paddingRight: 32}}>
                        <Grid item xs={12}>
                            <Typography variant="h1">Envío de documentos oficiales</Typography>
                            <Typography>
                                Adjunta los siguientes documentos para que podamos crear tu expediente escolar. Te recomendamos utilizar un scanner o aplicaciones como CamScanner en tu celular para que tus documentos tengan buena resolución y todos los detalles se puedan leer con claridad.
                            </Typography>
                            <Box>
                                <Box mt={5} mb={1} display="flex" justifyContent="space-between">
                                    <Typography variant="h6" className="mb-3" component={Box}>Documentos digitales</Typography>
                                        <Typography variant="subtitle1" className="text-blue-500" component={Box}>{fileList.length} Documentos</Typography>
                                </Box>
                                <Box>
                                    <LoaderContent loading={loading} transparent={true}>
                                        <DocumentUploadList 
                                            items={fileList} 
                                            onChangeFile={this.handleChangeFile} 
                                            minHeight="200px"
                                            onClickDelete={this.handleClickDeleteFile}
                                        />
                                    </LoaderContent>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} style={{paddingLeft: 32}}>
                        <Grid item xs={12}>
                            <Deadline date={data ? data.deadline_at : null} />
                            {/* <PhysicalDocuments /> */}
                            {/* <DownloadableFiles /> */}
                            <AcceptTerms 
                                disabled={!activateDocDelivery || loading} 
                                accept={acceptTerms} 
                                onChangeAccept={this.handleChangeAcceptTerms}
                                onClickAccept={this.handleClickAcceptTerms}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Toast {...toastProps} autoHideDuration={6000} onFinished={this.handleFinishedToast}/>
            </Box>
        );
    }
}

export default SendingDocuments;
