import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import LoginForm from './LoginForm/LoginForm';
import SignupForm from './SignupForm/SignupForm';

const AccountForms = () => {
    const history = useHistory();
    const [loginScreen, setLoginScreen] = React.useState(false);

    const handleLoginClick = () => {
        setLoginScreen(true)
        history.replace({
            ...history,
            state: {
                ...history.location.state,
                'referrer': "checkout-login-SSO"
            }
        })
    };
    
    const handleSignupClick = () => {
        setLoginScreen(false);
    };

    return loginScreen ? <LoginForm onSignUp={handleSignupClick} /> : <SignupForm onLogin={handleLoginClick} />;
};

export default AccountForms;