import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootStatusCourseContent: {
        height: "204px",
        width: "100%",
        // minWidth: "288px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('xs')]: {
            height: "172px",
            padding: "8px",
        }
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    courseNameContainer: {
        flexGrow: 1,
    },
    courseName: {
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal',
        overflow: 'hidden'
    }
}));