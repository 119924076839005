import React from 'react';

//@ThirtyParty
import {findIndex, isNil, propEq} from "ramda";

// @material-core
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local
import {detailsStyles} from '../../../../../assets/styles/jss/pages/degreeCertificates/DetailsStyles';
import clsx from 'clsx';
import Select from '../../../../../design-system/components/Form/Select/Select';


export const AditionalInformation = ({data, nationalityCat, xData, onSetForm, onChange, isEdit, catalogues, errorForm}) => {

    const classes = detailsStyles({isEdit});

    return (
        <Grid container spacing={3} className="mt-1">
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Observaciónes Internas
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit ?
                                    <TextField
                                        id="observacionesInternas"
                                        name="observacionesInternas"
                                        placeholder='Observaciónes Internas'
                                        fullWidth
                                        multiline={true}
                                        className={classes.label}
                                        value={catalogues.observations[findIndex(propEq(data.observacionesInternas, 'id'))(catalogues.observations)]?.label || ''}
                                        InputProps={{
                                            className: clsx(!isEdit && classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    :
                                    <Select
                                        items={catalogues.observations}
                                        label=""
                                        className={clsx(classes.select)}
                                        name="observacionesInternas"
                                        onClick={e => e.stopPropagation()}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        disabled={!isEdit}
                                        value={data.observacionesInternas || ""}
                                        size="small"
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Nombre de Campus
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="nombreDeCampus"
                                    name="nombreDeCampus"
                                    placeholder='Nombre de Campus'
                                    fullWidth
                                    className={classes.label}
                                    value={data.nombreDeCampus || ''}
                                    InputProps={{
                                        className: clsx(!isEdit && classes.inputText, isEdit && classes.inputTextEdit, !isNil(errorForm.nombreDeCampus) && classes.inputTextError),
                                        disableUnderline: true,
                                        readOnly: !isEdit,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        minLength: "1",
                                        maxLength: "25"
                                    }}
                                    error={!isNil(errorForm.nombreDeCampus)}
                                    helperText={!isNil(errorForm.nombreDeCampus) && errorForm.nombreDeCampus}
                                    onClick={e => e.stopPropagation()}
                                    onChange={e => onChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} >
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Revisor
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="revisor"
                                    name="revisor"
                                    fullWidth
                                    className={classes.label}
                                    value={data.revisor || ''}
                                    InputProps={{
                                        className: classes.inputText,
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item} style={{display: 'none'}}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <TextField
                                    id="personId"
                                    name="personId"
                                    fullWidth
                                    className={classes.label}
                                    value={data.personId || ''}
                                    InputProps={{
                                        className: classes.inputText,
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};
