import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { log } from '../../../shared/utils/console.js'
import { updateZendesk } from '../../../redux/actions/notificationActions';

import clsx from 'clsx';
// MUI
import Fab from '@material-ui/core/Fab';
import {
  ClickAwayListener,
  Typography,
  useMediaQuery,
  Popper,
  Modal,
  Icon,
  Box,
  Paper
} from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';

import Lottie from 'react-lottie';
import alert from '../../../assets/images/animations/alerta-notif.json'
// Components
import Menu from './components/Menu'
import objectIsEmpty from '../../../shared/utils/objectIsEmpty';

//SDK
import { HelpCenterService, ENVIRONMENT } from '@sdk-point/talisis';
import { PROSPECTCATEGORY } from '../../../shared/constants/help-prospects-category';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';

import isEmail from '../../../shared/utils/isEmail';
import { conektaWSUpgradeMembership } from '../../../shared/utils/conektaWSUpgradeMembership';
import { ContactsOutlined, Phone } from '@material-ui/icons';
import NotificationAlert from './components/NotificationAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 444,
    zIndex: theme.zIndex.modal,
  },
  helpButtonContainer:{
    // height: 60,
    // width: 60,
    // position: 'relative',
    position: 'fixed',
    right: 60,
    bottom: 46,
    width: 70,
    height: 70,
    zIndex: theme.zIndex.modal + 1,
    [theme.breakpoints.down('sm')]:{
      bottom: 65,
      right: 20,
    },
},
  helpButton: {
    position: 'absolute',
    left: 40,
    top: 40,
    width: 40,
    height: 40,
    background: theme.palette.colors.orange[300],
    '&:hover': {
      backgroundColor: theme.palette.colors.violet[300]
    },
    // [theme.breakpoints.down('sm')]:{
    //   bottom: 65,
    //   right: 20,
    // },
    // zIndex: theme.zIndex.modal + 1
  },
  helpLabel:{
    height: '100%'
  },
  helpButtonText: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '40px',
    letterSpacing: 0,
    textAlign: 'center',
  },
  boxQuestion:{
    display:'flex', 
    alignItems:'center', 
    justifyContent: 'center',
  },
  swipeableDrawer:{
    '& .MuiDrawer-paper':{
      bottom: props => props.isLogged ? 57 : 0
    }
  },
  loader:{
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dotNotification:{
    position: 'absolute',
    top: '-3px',
    right: '-1px',
    border: '3px solid black',
    borderRadius: '50%',
    width: 16,
    height: 16,
    background: 'red'
  },
  alertAnimation:{
    // height: 70,
    // width
  },
  popperContent:{
    maxHeight: '80vh', // Ajusta esto según sea necesario
    overflowY: 'auto',
  }
}));


const Help = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const zendeskNotification = useSelector((state) => state.notificationReducer.zendesk);
  const HelpCenterServiceSDK = new HelpCenterService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
  const [viewContactAgent, setviewContactAgent] = useState(false);
  const [viewTickets, setviewTickets] = useState(false);
  const [viewMainMenu, setViewMainMenu] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [categories, setCategories] = useState([]);
  const [zendeskUser, setZendeskUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryLastChange, setSelectedCategoryLastChange] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [message, setMessage] = useState(undefined);
  const [ticketRes, setTicketRes] = useState(null);
  const [ticketList, setTicketList] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState({});

  const [showTicket, setShowTicket] = useState(false);
  const [ticketDetail, setTicketDetail] = useState(null);
  const [ticketComments, setTicketComments] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [changeToSend, setChangeToSend] = useState(false);
  const [disableSend, setDisableSend] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reqClass, setReqClass] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const [userForm, setUserForm] = useState({
    name: '',
    lastName: '',
    email: '',
    matricula: '', 
    phone: '',
    isPhoneRequired: false,
  });
  const [openTickets, setOpenTickets] = useState([]);
  const [closedTickets, setcCosedTickets] = useState([]);
  const theme = useTheme();

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    log('Cambio de estado notificación de Zendesk:', zendeskNotification);
    // Aquí puedes manejar lo que necesites cuando zendesk cambie 
    if (zendeskNotification && zendeskUser?.id) {
        setviewContactAgent(false);
        setCurrentStep(0);
        handleViewTickets();
    } 
  }, [zendeskNotification]);

  const sortTickets = () => {
    ticketList.sort(function (a, b) {
      var keyA = new Date(a.updated_at),
        keyB = new Date(b.updated_at);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
  }

  const handleShowTicket = async (ticketId) => {

    await getTicketData(ticketId)
    setShowTicket(!showTicket)
    setSelectedTicket(ticketId)
  }

  const getTicketData = async (ticketId) => {
    setLoading(true)
    const myTicket = await HelpCenterServiceSDK.getTicketById(ticketId).then(t => t);
    const comments = await HelpCenterServiceSDK.getTicketComments(ticketId).then(t => t);
    setTicketDetail(myTicket)
    setTicketComments(comments)
    handleNextStepTicketList()
    setLoading(false)
  }

  useEffect(() => {
    if (userForm.name.length === 0 || userForm.lastName.length === 0 || !isEmail(userForm.email) ||
    (userForm.isPhoneRequired && userForm.phone.length < 10)) {
      setDisableSend(true)
    } else {
      setDisableSend(false)
    }
  }, [userForm])

  useEffect(() => {

  }, [zendeskUser])


  const handleCategory = categoryId => {
    setSelectedCategory(categoryId)
    const selectedObject = categories.find(cat => cat.id === categoryId)
    setDisableSend(false)
    setReqClass(selectedObject.clase)
    setSelectedTopic({})
  }

  const handleSubmit = async () => {
    const userTags = user.userTags ?? [];
    setLoading(true);

    let tags = [
        "plataforma",
        "web",
        "talisis",
        ...userTags,
        process.env.REACT_APP_ENV === 'v3' ? 'prod' : process.env.REACT_APP_ENV
    ];
    const ticketPayload = {
      ticketData: {
        zendeskId: zendeskUser.id,
        comment: message,
        subject: isLogged ? message.substring(0, 30) : selectedTopic.name,
        custom_fields: [
          {
            id: "360048350694",
            value: !isLogged ? userForm.matricula : user.student_id ? user.student_id : ''
          },
          {
            id: selectedCategoryLastChange ? selectedCategoryLastChange : selectedCategory,
            value: selectedTopic.value
          },
          {
            // id: isLogged ? reqClass.id : PROSPECTCATEGORY[0].clase.id,
            // value: isLogged ? reqClass.value : PROSPECTCATEGORY[0].clase.value
            id: !isLogged ? 8667463356439 : reqClass.id,
            value: !isLogged ? 'sin_login': reqClass.value
          },
          {
            id: "23453124515351",
            value: userForm.isPhoneRequired ? 'mp_whatsapp' : 'mp_correo_electronico'
          },
          {
            id: "23453243173527",
            value: userForm.isPhoneRequired ? userForm.phone : userForm.email
          },
        ],
        files: attachments.map(file => file.token),
        tags: tags
      }
    }
    
    let categoria_mixpanel='';
    if(!isLogged) {
      categoria_mixpanel = 'plataformas';
    } else {
      switch (reqClass.value) {
        case 'membresias':
          categoria_mixpanel = 'membresías'
          break;
        case 'administración':
          categoria_mixpanel = 'admin'
          break;
        case 'escolar_clase':
          categoria_mixpanel = 'escolar y académica'
          break;
        default:
          categoria_mixpanel = 'plataformas'
          break;
      }
   }
    const ticket = await HelpCenterServiceSDK.postTicket(ticketPayload).then(tckt => tckt)
    const eventData= {}
    eventData['categoria'] = categoria_mixpanel;
    eventTracker('contactar_agente', eventData, ['mixpanel']) 
    setTicketRes(ticket)
    setLoading(false)
  }

  const handleGetGestUser = async () => {
    const extUser = {
      user: {
        email: userForm.email,
        name: `${userForm.name} ${userForm.lastName}`
      }
    }
    const zndeskUser = await HelpCenterServiceSDK.getZendeskUser(extUser).then(usr => usr);
    setZendeskUser(zndeskUser)
    setLoading(false)
  }

  const handleNextStep = async (e) => {
    if (currentStep === 3) await handleSubmit();
    if (currentStep === 1 && !isLogged) await handleGetGestUser();

    let count = currentStep < 4 ? currentStep + 1 : 4
    if (count === 2){
      setCurrentStep(stepTopic(selectedArticle, selectedTopic.value))
    }else{
      setCurrentStep(count)
    }
  }

  const getArticleInfo = async (topic) => {
    //let tag = 'no_puedo_crear_cuenta'
    setLoading(true)
    const infoArticle = await HelpCenterServiceSDK.getCategoriesArticles(topic.value).then(t => t);
    setSelectedArticle(infoArticle);
    setLoading(false)
    setDisableSend(false)
  }

  const handleNextInfoStep = async (topic) => {
    //setLoading(true)
    const category_id = getCategoryIdbyTopic(topic.id);
    

    const infoArticle = await HelpCenterServiceSDK.getCategoriesArticles(topic.value).then(t => t);
    setDisableSend(false)
    setSelectedArticle(infoArticle);
    //necesito acomodar esto para llevarlo a la pantalla que quiero y poner los datos necesarios para ese paso
    setSelectedTopic(topic)
    setviewContactAgent(!viewContactAgent)
    setViewMainMenu(viewContactAgent)
    setMessage('')
    setCurrentStep(stepTopic(infoArticle, topic.value))
    setAttachments([])
    setSelectedCategory(isLogged ? category_id : 22477284353303)
    setTicketRes(null)
    setUserForm({
      name: '',
      lastName: '',
      email: '',
      matricula: '',
      phone: '',
      isPhoneRequired: false
    })
    //setLoading(false);
    //setCurrentStep(3)
  }

  const stepTopic = (infoArticle = [], topic = '') => {
    const contieneWhatsapp = topic.includes("whatsapp");
    if (infoArticle.length > 0 || contieneWhatsapp){
      return 2
    }else{
      return 3
    }
  }

  const backStepTopic = (infoArticle = [], topic = '') => {
    const contieneWhatsapp = topic.includes("whatsapp");
    if (infoArticle.length > 0 || contieneWhatsapp){
      return 2
    }else{
      return 1
    }
  }

  const getCategoryIdbyTopic = (topic_id) => {
      for (const obj of categories) {
          for (const option of obj.custom_field_options) {
              if (option.id === topic_id) {
                  return obj.id;
              }
          }
      }
      return null; // Si no se encuentra ninguna coincidencia
  };

  const handleNextStepTicketList = async () => {
    let count = currentStep < 3 ? currentStep + 1 : 3

    setCurrentStep(count)
  }

  const handleBackStep = (e) => {
    let count = currentStep > 0 ? currentStep - 1 : 0
    if (count === 2){
      setCurrentStep(backStepTopic(selectedArticle, selectedTopic.value))
    }else{
      setCurrentStep(count)
    }
  }

  const handleViewAgent = () => {
    setviewContactAgent(!viewContactAgent)
    setViewMainMenu(viewContactAgent)
    setMessage('')
    setCurrentStep(0)
    setAttachments([])
    setSelectedCategory(isLogged ? null : 22477284353303)
    setSelectedTopic({})
    setTicketRes(null)
    setUserForm({
      name: '',
      lastName: '',
      email: '',
      matricula: '',
      phone: '',
      isPhoneRequired: false,
    })

  }

  const handleViewTickets = async () => {
    setLoading(true)
    const tickets = await HelpCenterServiceSDK.getUserTickets(`${zendeskUser.id}`).then(ticketsList => ticketsList);
    setTicketList(tickets)

    setviewTickets(!viewTickets)
    setViewMainMenu(viewTickets)
    setLoading(false)
  }

  // const open = Boolean(anchorEl);
  const id = open ? 'help-menu' : undefined;

  const handleClick = (event) => {
    if(zendeskNotification ){
      dispatch(updateZendesk(false));
    }
    if(open){
      setviewContactAgent(false);
      setviewTickets(false);
      setViewMainMenu(true);
    }
    setLoading(true)
    setAnchorEl(event.currentTarget)
    setIsLogged(!objectIsEmpty(user));
    setInitials();
    if (!loading) setOpen((prev) => !prev);
    eventTracker('ayuda', eventData, ['mixpanel']);
  }

  const handleCloseDrawer = () => {
    setOpen(false)
  }

  const userReq = {
    user: {
      email: user.email,
      name: user.userName
    }
  }

  const setInitials = async () => {
    const categoriesList = await HelpCenterServiceSDK.getCategories();
    if (isLogged) {
      const zndeskUser = await HelpCenterServiceSDK.getZendeskUser(userReq).then(usr => usr);
      // 


      setZendeskUser(zndeskUser)
      setCategories(categoriesList)
      setLoading(false)
    }
    else {

      setZendeskUser({})
      // setCategories(PROSPECTCATEGORY)
      // setSelectedCategory('8529666934935')
      setCategories(categoriesList)
      setSelectedCategory(22477284353303)
      setLoading(false)
    }

  }

  // useEffect(() => {
  //   setIsLogged(!objectIsEmpty(user));  
  // }, [])

  useEffect(() => {
    setIsLogged(!objectIsEmpty(user));
  }, [user])

  useEffect(() => {

  }, [isLogged])

  useEffect(() => {
    sortTickets();
    setOpenTickets(ticketList.filter(ticket => ticket.status !== 'closed' && ticket.status !== 'solved'))
    setcCosedTickets(ticketList.filter(ticket => ticket.status === 'closed' || ticket.status === 'solved'))
  }, [ticketList])


  const handleContactAgent = async () => {
    handleViewAgent();
    setviewTickets(false);

  }
  const handleSendComment = async () => {
    setLoading(true)
    const ticketCommentData = {
      commentData: {
        zendeskId: zendeskUser.id,
        ticket_id: selectedTicket,
        comment: message,
        files: attachments.map(file => file.token)
      }
    }

    const response = await HelpCenterServiceSDK.postComment(ticketCommentData).then(cmnt => cmnt)
    const coment = {
      id: selectedTicket,
      author_id: zendeskUser.id,
      plain_body: message,
    }
    setTicketComments([...ticketComments, coment])
    setAttachments([])
    setMessage('')

    setLoading(false)
  }
  const handleFinish = async () => {

  }

  const isXs = useMediaQuery(theme.breakpoints.down('xs'))

  const helpProps = {
    user,
    zendeskUser,
    viewContactAgent,
    viewMainMenu,
    currentStep,
    categories,
    selectedCategory,
    selectedTopic,
    handleViewAgent,
    handleNextStep,
    handleBackStep,
    handleCategory,
    setSelectedTopic,
    setSelectedCategoryLastChange,
    attachments,
    setAttachments,
    message,
    setMessage,
    ticketRes,
    handleViewTickets,
    viewTickets,
    ticketList,
    showTicket,
    setTicketDetail,
    ticketComments,
    selectedTicket,
    handleShowTicket,
    setChangeToSend,
    changeToSend,
    handleNextStepTicketList,
    disableSend,
    setDisableSend,
    handleContactAgent,
    handleSendComment,
    handleFinish,
    LoaderContent,
    loading,
    setLoading,
    isLogged,
    userForm,
    setUserForm,
    openTickets,
    closedTickets,
    setReqClass,
    handleCloseDrawer,
    selectedArticle,
    getArticleInfo,
    handleNextInfoStep,
    setviewTickets,
    setViewMainMenu
  }

  const classes = useStyles({isLogged});

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: alert,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        className: classes.alertAnimation
    }
};
  return (
    <>
      {
        ((!open && !isXs) || (open && !isXs) || (!open && isXs)) && 
        <Box className={classes.helpButtonContainer}>
          {zendeskNotification && <Lottie options={defaultOptions}  width={120}  height={120}/>}
          <Fab id="talisis_helpdesk_button" aria-label="ayuda" classes={{label: classes.helpLabel}} className={classes.helpButton} onClick={handleClick}>
            <Box className={clsx(classes.dotNotification, {'d-none': !zendeskNotification})} />
            <LoaderContent loading={loading} helpColor={true} className={classes.loader}>
              {open && <Box className={classes.boxQuestion}><Icon className={'ri-arrow-down-s-line font-size-24'} /></Box>}
              {!open && <Box className={classes.boxQuestion}><Icon className='ri-customer-service-2-fill text-dark-black-500 font-size-24'/></Box>}
            </LoaderContent>
          </Fab>
        </Box>
      }
      <div className={classes.root}>
        {
          open && !isXs &&
          <Modal
            open={open}
            onClose={() => setOpen(false)}
          >
            <Popper
              role='dialog'
              id={id}
              open={open}
              placement='top-end'
              className={classes.root}
              transition
              keepMounted
              anchorEl={anchorEl}
              modifiers={{
                offset: {
                  enabled: true,
                  offset: '0, 16'
                },
                 preventOverflow: {
                  enabled: true,
                  boundariesElement: 'viewport',
                  padding: 8
                },
                flip: {
                  enabled: false
                }
              }}
            >
              <Paper className={classes.popperContent}>
                {/* Tu contenido aquí */}
                <div>
                  <Menu {...helpProps} />
                  
                </div>
              </Paper>
              {/* <Menu {...helpProps} /> */}
            </Popper>
          </Modal>
        }
        {
          open && isXs &&
          <Modal
            open={open}
            onClose={() => setOpen(false)}
          >
            <SwipeableDrawer
              id="swipeable-drawer-xs"
              className={classes.swipeableDrawer}
              anchor='bottom'
              open={open}
              onClose={handleCloseDrawer}
              onOpen={() => setOpen(true)}
              disablePortal
              SlideProps={{ direction: 'down' }}
            >
              <Menu {...helpProps} />
            </SwipeableDrawer >
          </Modal>
        }
      </div>
    </>
  )
};

Help.defaultProps = {
  open: false,
};

Help.propTypes = {
  open: PropTypes.bool
};


export default Help;