import { API_ENDPOINT } from './endpoints'
import {getAllPermission} from './UserService';
const RESOURCE = 'roles'

function getAllPermissionType(type){
    const permissions = getAllPermission();
    const _permissions = [...new Set(permissions.map(item => item[type]))];
    return _permissions;
}

function hasSpecificPermission(type, id){
    const permissions = JSON.parse(localStorage.getItem('permissions')) || null;
    return permissions ? permissions.some(pObj => pObj[type] === id) : false; 
}

export const getAll=async (page,filters, size=10) => {
    let url = `${API_ENDPOINT}/${RESOURCE}?page=${page}&size=${size}`;
    for (let items of filters) {
        let filter = items;
        for(const key in filter){
            let keyFilter = key;
            url+=`&${key}=${filter[keyFilter]}`
        }
    }
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        },
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}

const RESOURCE_ROLE= 'user/table/entry/role'
export const getById = async (id) =>{
    const url = `${API_ENDPOINT}/${RESOURCE_ROLE}/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        },
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}

export const update = async (body,id) =>{
    const url = `${API_ENDPOINT}/${RESOURCE_ROLE}`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        },
        body: JSON.stringify(body)
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}

export const save = async (body) =>{
    const url = `${API_ENDPOINT}/${RESOURCE_ROLE}`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        },
        body: JSON.stringify(body)
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}

export const getRolesByUser = () =>{
    return getAllPermissionType('role_id');
}

export const hasRole = (roleId) => {
    return hasSpecificPermission('role_id', roleId);
}

export const hasPermission = (permissionId) => {
    return hasSpecificPermission('permission_id', permissionId);
}

export const getPermissionsByUser = () =>{
    return getAllPermissionType('permission_id');
}