import { makeStyles } from '@material-ui/core/styles';

import typography from '../../../../../design-system/theme/typography';

export default makeStyles((theme) => ({
    table_benefits:
    {
        '& td:not(:first-child)':
        {
            borderLeft: `1px solid ${theme.palette.colors.grey[400]} !important`
        },
        '& td div':
        {
            ...typography.body1
        }
    }
}));