import * as tourInicialTypes from '../types/tourInicialTypes';

const initialProps = {
    showTour: false,
    tourPreferences: null
}

export default function(state = initialProps, action){
    switch(action.type){
        case tourInicialTypes.setState:
            return{
                ...state,
                showTour: action.tourState,
                tourPreferences: action.tourPreferences
            };
        default:
            return state;
    }
}