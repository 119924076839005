import React from 'react';
import KardexButton from './KardexButton/KardexButton';

const Kardex = (props) => {
    return (
        <div>
            <KardexButton />
        </div>
    );
};

export default Kardex;