import React from 'react';

import { Grid, Box, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ProgressBar = ({ title = "Deseabilidad Social", value = 5, color = '#DF43B7' }) => {

    const useStyles = makeStyles({
        colorPrimary: {
            backgroundColor: '#111111',
        },
        barColorPrimary: {
            backgroundColor: color,
        }
    });

    const classes = useStyles();
    return (
        <>
            <Grid item xs={12}>
                <Grid container justifyContent='space-between'>
                    <Typography variant="body2" color="textSecondary">{ title }</Typography>
                    <Typography variant="body2">
                        { value }
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent='space-between' className='mt-2'>
                    <Grid item xs={12}>
                        <LinearProgress
                            classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}
                            variant="determinate"
                            value={ (value * 100) / 10 }
                            style={{ borderRadius: "0.2em", height: "0.2em"}}
                        />
                    </Grid>
                </Grid>
            </Grid>
        <br />
        </>
    )
}

export default ProgressBar
