import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material components
import { Box, Typography, useMediaQuery } from '@material-ui/core';

// Styles
import useStyles from "../../../assets/styles/components/cards/components/statusCourseBarStyle";

const IN_PROGRESS = 1;
const FINISHED = 2;
const NOT_APPROVED = 3;

const StatusCourseBar = ({type, approved = false}) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles();
    let status = null;
    let label = '';

    if(type === IN_PROGRESS){
        status = 1;
        label = "En progreso";
    }else if(type === FINISHED && approved){
        status = 2;
        label = "Finalizado";
    }else{
        status = 3;
        label = "Reprobado";
    }

    return (
        <Box id="status-course-bar-root" className={clsx(
            classes.rootStatusCourseBar, 
            {
                [classes.inProgressCourse]: status === IN_PROGRESS,
                [classes.finishedCourse]: status === FINISHED,
                [classes.reprobateCourse]: status === NOT_APPROVED,
            }
        )}>
            <Typography variant={isResponsive ? "caption" : "body2"} className={clsx("semi-bold", isResponsive && "variant-caption-sm")}>
                { label }
            </Typography>
        </Box>
    );
};

StatusCourseBar.propTypes = {
    type: PropTypes.oneOf([IN_PROGRESS, FINISHED])
};

export default StatusCourseBar;