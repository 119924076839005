import React from 'react';

// @material-ui/core
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Rating } from '@material-ui/lab';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// icons
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CloseIcon from '@material-ui/icons/Close';

// shared
import Loader from '../../../../shared/components/Loader/LoaderContent';

// services
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';

// import * as coursesServices from '../../../../services/MarketPlaceService';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    title: {
        lineHeight: "1.5em !important",
        maxHeight: "3em",
        display: "-webkit-box",
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    buttons: {
        flexGrow: 1,
    }
}));

const SendButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#049CB4"),
        fontWeight: "bold",
        backgroundColor: "#00C1DE",
        '&:hover': {
            backgroundColor: "#049CB4",
        },
    },
}))(Button);

const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();

    return (
        <MuiDialogTitle disableTypography {...other}>
            <div>{children}</div>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const RateModal = ({ course, open, user, onClose, onSuccess, onToastProps }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        "course_rating": 0,
        "teacher_rating": 0,
        "course_review": ''
    });
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    React.useEffect(() => {
        if (open) {
            setForm({
                "course_rating": 0,
                "teacher_rating": 0,
                "course_review": ''
            });
        }
    }, [open]);

    if (!(course && course.id)) {
        return null;
    }

    const handleOnChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleSubmit = async () => {
        const getLanguage = (value) => {
            if (value.includes("Español")) {
                return "es";
            } else if (value.includes("English")) {
                return "en";
            }
            return value.substring(0,2).toLowerCase();
        };

        const getNeoLink = (value) => {
            let ou = "";

            if (value.includes("unid")) {
                ou = "//unid.neolms.com/class/show/";
            } else if (value.includes("talisis")) {
                ou = "//talisisch.neolms.com/class/show/";
            } else {
                ou = "//talisis.neolms.com/class/show/";
            }

            return `${ou}${course.id}`;
        };

        const body = {
            ...course,
            ...form,
            company_id: user.ou.toUpperCase(),
            person_id: user.person_id,
            lang: getLanguage(course.lang),
            course_url: getNeoLink(course.company_name || user.ou),
        };

        try {
            setLoading(true);
            await marketService.postRating(body);
            setLoading(false);
            onToastProps({ severity: "success", open: true, message: '¡Gracias por calificar el curso!' });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            setLoading(false);
            onToastProps({ severity: "error", open: true, message: 'Ha ocurrido un error' });
        }

        handleClose();
    };

    return (
        <Dialog open={open} maxWidth="xs" onClose={onClose} aria-labelledby="form-dialog-title">
            <Loader loading={loading}>
                <div>
                    <DialogTitle id="form-dialog-title" onClose={onClose}>
                        <Typography variant="subtitle1">Queremos conocer tu opinión</Typography>
                        <Typography variant="subtitle2">Califica el curso</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Avatar variant="square" src={course.image_url} className={classes.avatar} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography variant="subtitle1" className={classes.title}>{course.name}</Typography>
                                <Typography variant="body2" color="primary">{course.source}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="mt-3">
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="body1">Contenido del curso</Typography>
                                    <Rating
                                        precision={0.5}
                                        value={parseFloat(form.course_rating) || 0}
                                        name="course_rating"
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                        onChange={handleOnChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="body1">Desempeño del Instructor</Typography>
                                    <Rating
                                        precision={0.5}
                                        value={parseFloat(form.teacher_rating) || 0}
                                        name="teacher_rating"
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                        onChange={handleOnChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Comentarios adicionales"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={form.course_review || ''}
                                    name="course_review"
                                    onChange={handleOnChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className="px-4 pt-2 pb-4">
                        <Button className={classes.buttons} variant="outlined" color="primary" onClick={handleClose}>Ahora no</Button>
                        <SendButton className={classes.buttons} onClick={handleSubmit} >Enviar</SendButton>
                    </DialogActions>
                </div>
            </Loader>
        </Dialog>
    );
};

export default RateModal;