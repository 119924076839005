import * as userTypes from '../types/userTypes';

import { setSessionCookie } from '../../shared/utils/Sessions';

export const USER_PROPERTIES = {
    ACCEPT_TERMS_CONDITIONS: 'term_cond'
} 

export const applyUser  = (user) => (dispatch) => {
    dispatch({
        type: userTypes.APPLY_USER,
        payload: user
    });
};

export const getInterestsProfile = (interests) => (dispatch) => {
    dispatch({
        type: userTypes.GET_INTEREST,
        payload: interests
    });
};


export const getFavInterests = (favInterests) => (dispatch) => {
    dispatch({
        type: userTypes.GET_FAV_INTEREST,
        payload: favInterests
    });
};

// todo: Borrar saveFavInterests
export const saveFavInterests = (favInterests) => (dispatch) => {
    dispatch({
        type: userTypes.GET_FAV_INTEREST,
        payload: {
            data_set: 'fav_interest_areas',
            favInterests
        }
    });
};

export const setInterests = (interests) => (dispatch, getState) => {
    const { user } = getState().userReducer;
    const _user = {...user, interests};

    setSessionCookie(_user);

    dispatch({
        type: userTypes.SET_INTERESTS,
        payload: interests
    });
};

export const getInterests = () => (dispatch, getState) => {
    const state = getState();
    const interests = state.user.interests;
    dispatch({
        type: userTypes.GET_INTERESTS,
        payload: interests
    });
};

export const updateMembership = (membership_id) => (dispatch, getState) => {
    const { user } = getState().userReducer;
    const _user = {...user, membership_id};

    setSessionCookie(_user);

    dispatch({
        type: userTypes.UPDATE_MEMBERSHIP,
        payload: membership_id
    });
};

export const updateUserProperty = (propName, value) => {
    return (dispatch, getState) => {
        const { user } = getState().userReducer; // Ajusta esto según cómo tengas estructurado tu estado
  
      const updatedPerson = {
        ...user,
        [propName]: value,
      };

      setSessionCookie(updatedPerson);
  
      dispatch(applyUser(updatedPerson));
    };
};
