import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { Box } from '@material-ui/core';

// Components
import ItemListCourseCardMedia from './ItemListCourseCardMedia';

// Styles
import useStyles from '../../../../assets/styles/components/cards/itemListCourseCard/itemListCourseCardBodyStyle';

const ItemListCourseCardBody = ({children, media}) => {
    const classes = useStyles();

    return (
        <Box className={classes.ItemListCourseCardBody}>
            <Box className={classes.sectionMedia}>
                <ItemListCourseCardMedia {...media}/>
            </Box>
            <Box  className={classes.sectionContent}>
                {children}
            </Box>
        </Box>
    );
};

ItemListCourseCardBody.defaultProps = {
};

ItemListCourseCardBody.propTypes = {
    media: PropTypes.shape({
        backgroundImage: PropTypes.string,
        ratingData: PropTypes.shape({
            number: PropTypes.number
        }),
        sourceIdLogo: PropTypes.number.isRequired,
    }).isRequired,
};

export default ItemListCourseCardBody;