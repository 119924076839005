import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { log } from '../../../shared/utils/console.js'

const LightTooltip = withStyles((theme) => ({
    arrow: {
        color: '#2C3236',
    },
    tooltip: {
        backgroundColor: '#2C3236',
        color: '#EBEBEB',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        maxWidth: '350px',
        padding: '8px'
    },
}))(Tooltip);

const CopyClick = ({ step, classes, chagring, new_msge, newError, sending, nexSteps }) => {
    const [isCopied, setIsCopied] = React.useState(false);
    const [newMessage, setNewMessage] = React.useState(null);
    const [newStepError, setNewStepError] = React.useState(null);

    // function that accepts text as an argument and copies it to the user’s clipboard
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
        } else {
        return document.execCommand('copy', true, text);
        }
    }
      
    // onClick handler function for the copy button
    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
        .then(() => {
            // If successful, update the isCopied state value
            setIsCopied(true);
            setTimeout(() => {
            setIsCopied(false);
            }, 1000);
        })
        .catch((err) => {
            log(err);
        });
    }
    
    const setValuesIfNextStepsHaveStatus = () => {
        if(nexSteps.length > 0){
            nexSteps.find(e => {
                if(e.step_id === step.step_id){
                    setNewMessage(step.message);
                    setNewStepError(step.error);
                }
            });
        }
    }
    React.useEffect(()=>{
        setValuesIfNextStepsHaveStatus();
    }, []);

    return (
        <Box display="flex" >
            <Typography variant="h6" component="div" className={classes.titleRow} style={{marginRight: "20px"}}>
                {step.step_name}
            </Typography>
            {step.err_desc && step.err_desc != "" && 
            <LightTooltip describechild="true" title={!new_msge ? step.err_desc : new_msge} arrow placement="bottom-start">
                <Typography variant="h6" component="div" className={classes.titleRow + " " + classes.err} onClick={() => {!chagring && handleCopyClick(!new_msge ? step.err_desc : new_msge)}} style={{color: (step.status == 3 && !newError && !newStepError && !sending) || (newError && !sending) || (newStepError && !sending) ? "red" : "white"}}>
                    {isCopied ? 'Copiado' : !sending ? `${ (!new_msge && !newMessage) ? step.err_desc : (new_msge && !newMessage) ? new_msge: newMessage }`:  "Re-enviando..."}
                </Typography>
            </LightTooltip>}
        </Box>
    );
};

export default CopyClick;