import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// @material-ui/icons
import PublishIcon from '@material-ui/icons/Publish';

// styles
import useStyles from '../../../assets/styles/jss/components/inputFileBttnStyle'

// shared
import capitalize from '../../../design-system/utils/capitalize'

// dependencies
import clsx from 'clsx';

const InputFileButton = (props) => {
    const { children, label, outlined, onChange, disabled, optionName, accept, fullWidth, textAlign, className, startIcon, ...rest } = props;
    const classes = useStyles();

    const handleChange = (event) => {
        const file = event.currentTarget.files[0];

        if(file){
            if ( !!optionName ) {
                onChange( file, optionName );
            } else {
                onChange(file);
            }
        }
    }

    return (
        <Box display="inline-flex" {...rest} className={clsx({ 'outlined': outlined, 'disabledButton': disabled, 'w-100': fullWidth }, classes.root, className)}>
            <Box className={classes.inputFile}>
                <Box display="inline-flex" position={disabled ? "initial" : "absolute"} justifyContent={textAlign} width="100%" alignItems="center">
                    {startIcon}
                    {
                        typeof label === 'string' ?
                        <Typography color="inherit" variant="body1" style={{marginLeft: 5}} >{capitalize(label)}</Typography>
                        : label
                    }
                    <input 
                        type="file" 
                        onChange={handleChange} 
                        disabled={ disabled }
                        accept={accept}
                    />
                </Box>
            </Box>
            {children}
        </Box>
        
    );
};

InputFileButton.defaultProps = {
    startIcon: <PublishIcon/>,
    label: "subir",
    // fullWidth: true,
    outlined: true,
    disabled: false,
    accept: '',
    textAlign: 'center'
};

InputFileButton.propTypes = {
    label: PropTypes.string,
    // fullWidth: PropTypes.bool,
    outlined: PropTypes.bool,
    /**
    * Return file selected type "File"
    */
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    optionName: PropTypes.string,
    accept: PropTypes.string,
};

export default InputFileButton;