import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    loading:{
        // marginTop: "55px", 
        minHeight: "calc(150vh - 450px)",
        [theme.breakpoints.down('xs')]: {
            marginTop: "0px", 
        },
    },
    boxDataSelling:{
        position: 'absolute',
        left: '-16px',
        display: 'flex',
        padding: '16px 30px',
        background: theme.palette.colors.fillStroke[400],
        bottom: '58px',
        width: 'calc(100% + 32px)',
        alignItems: 'center',
        borderRadius: '8px 8px 0px 0px',
        justifyContent: 'space-between',
        BoxShadow: '0px -6px 16px rgba(17, 17, 17, 0.8)'
    },
    search: {
        backgroundColor: "white",
        width: "100%",
        maxWidth: "550px",
        border: "1.5px solid #CBD0DA",
        borderRadius: "12px",
        padding: "3px",
        paddingLeft: "16px",
        paddingRight: "8px",
        height: "44px",
        marginTop: "14px",
        textAlign: "left",
        "&:hover":
        {
            borderColor:"#00C1DE",
            "& $SearchLogo":
            {
                color: "#00C1DE",
                animation:"$SearchLogoColorChange 2000ms"
            }
        }
    },
    SearchResults:
    {
        position:"fixed",
        zIndex: "999999",
        width: "100%",
        backgroundColor: "white",
        height: "100%",
        padding: "36px 0px 0px 0px",
        margin: "0px 0px 0px -24px",
        overflow: "auto",
        marginTop:"72px"
    },
    SearchLogo:
    {
        "&:hover":
        {
            color: "#00C1DE",
            animation:"$SearchLogoColorChange 2000ms"
        }
    },
    "@keyframes SearchLogoColorChange": {
        "0%": {
          color: "#7F7F7F"
        },
        "100%": {
          color: "#00C1DE"
        }
      },
      root: {
        padding: '0px 0px',
        flexWrap: "nowrap",
        justifyContent: "center",
        "@media (max-width: 750px)": {
            flexWrap:"wrap-reverse",
            justifyContent: "center"
        }
    },
    searchItem:{
        color: "black"
    },
    loadingCircle:{
        textAlign: "center",
        padding:"calc(100vh/3) 0px 0px 0px !important"
    },
    courseBackground:{
        width: "100vw", 
        height: "217px",
        // position: "absolute",
        // backgroundSize: "cover",
        // filter: "blur(2px)",
        left: "0",
        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    },
    courseBackgroundImage:
    {
        width: "-webkit-fill-available",
        position: "absolute",
        left: 0,
        objectFit:'cover',
        height:'inherit',
        border:'none',
        [theme.breakpoints.down('xs')]:{
            minWidth: '360px',
            width: '100%',
        }
    },
    item:{
        marginLeft: "-66px",
        marginBottom: "-24px",
        "@media (max-width: 1250px)": {
            marginLeft: "-10px",
            '& > div':{
                marginRight: "auto",
            }
        },
        "@media (max-width: 768px)": {
            marginLeft: "-3vw",
            '& > div':{
                marginLeft: "10px !important",
                marginRight: "auto",
            }
        },
        "@media (max-width: 550px)": {
            marginLeft: "-3vw",
            '& > div':{
                marginLeft: "0px !important",
                marginRight: "auto",
                width: "105%",
            }
        },
    },
    boxDataLanding:{
        [theme.breakpoints.down('xs')]:{
            paddingBottom: '184px'
        }
    },
    responsive_price:{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px !important',
        lineHeight: '20px !important',
        marginBottom: '0px !important'
    },
    boxPrice:{
        [theme.breakpoints.down('xs')]:{
            display: 'flex',
            flexDirection: 'column',
            gap: 4
        }
    },
    cardFAQ:{
        marginRight: 16,
        marginLeft: 16,
        marginBottom: 40,
        [theme.breakpoints.down('xs')]:{
            // margin: '0px !important'
            marginRight: 16,
            marginLeft:16,
            marginBottom:16,
            marginTop:16
        }
    },
    saveBttn: {
        padding: 0,
        color: theme.palette.colors.darkBlack[200]
    },
    buttonCheckout: {
        color:theme.palette.colors.darkBlack[200]
    },
}));