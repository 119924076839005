import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    boxContainer: {
        margin: 0,
        width: "100%"
    },
    gridItem: {
        maxHeight: "100%",
    },
    myEventButton: {
        backgroundColor: theme.palette.colors.electricBlue[300],
        color: theme.palette.colors.white,
        width: "100%",
        height: "100%"
    },
    myEventButtonClicked: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        color: theme.palette.colors.white,
        width: "100%",
        height: "100%"
    },
    buttonPressed: {
        backgroundColor: theme.palette.colors.fillStroke[400],
    }
}));
