import React from 'react';

// @material-ui/core components
import Typography from '@material-ui/core/Typography';
import { Grid, Tooltip } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const Menu = ({ onSetForm }) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        grid: {
            marginBottom: '25%',
        },
        card: {
            backgroundColor: theme.palette.colors.grey[400],
            padding: '1em',
        },
        hr: {
            border: '0px',
            borderTop: `1px solid ${theme.palette.colors.grey[700]}`,
        },
        title: {
            color: theme.palette.colors.fillStroke[100],
            cursor: 'default',
            fontSize: '12px',
            fontWeight: 600,
        },
        label: {
            color: theme.palette.colors.fillStroke[100],
            fontWeight: 600,
            fontSize: '12px',
            padding: '4px',
        },
        icon: {
            color: theme.palette.colors.fillStroke[100],
        },
    });

    const classes = useStyles();
    return (
        <Grid item xs={12} className={classes.grid}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" className={`Profile_Title ${classes.title}`} onClick={() => onSetForm(0)}>
                        <Tooltip title="Regresar" placement="left">
                            <ChevronLeft className="Profile_ChevronPointer Profile_ChevronLeft" />
                        </Tooltip>
                        Regresar
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className='py-3' alignItems='center' onClick={() => onSetForm(2)}>
                        <Grid item xs={10} >
                            <Typography variant="subtitle2" className={classes.label}>
                                Añadir profesión
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent='center'>
                                <Tooltip title="Añadir puesto" placement="right-end">
                                    <ChevronRight className={`Profile_ChevronRight ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                    <Grid container className='py-3' alignItems='center' onClick={() => onSetForm(3)}>
                        <Grid item xs={10} >
                            <Typography variant="subtitle2" className={classes.label}>
                                Añadir historial de empleo
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent='center'>
                                <Tooltip title="Añadir empleo" placement="right-end">
                                    <ChevronRight className={`Profile_ChevronRight ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                    <Grid container className='py-3' alignItems='center' onClick={() => onSetForm(4)}>
                        <Grid item xs={10} >
                            <Typography variant="subtitle2" className={classes.label}>
                                Añadir historial de formación
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent='center'>
                                <Tooltip title="Añadir estudio" placement="right-end">
                                    <ChevronRight className={`Profile_ChevronRight ${classes.icon}`} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
