import React from 'react';

// @material-ui/core
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';

// shared core components
import capitalize from '../../../../../design-system/utils/capitalize'

import LinearProgress from '@material-ui/core/LinearProgress';

import styled from 'styled-components';
import palette from '../../../../../design-system/theme/palette'

const BorderLinearProgress = styled(LinearProgress)`
    ${({ theme:{palette}, barcolor }) => `
        height: 15px;
        border-radius: 33px;   
        background-color: ${palette.white}; 
        border: 3px solid ${palette.white};

        .MuiLinearProgress-barColorPrimary{
            background-color: ${barcolor};
        }
    `}
`

function RequestCard(props) {
    const {id, label, steps, activeStep, hasNotification, onClick, color, status} = props;

    const progress = Math.floor((activeStep * 100)/steps);

    return (
        <Badge color="secondary" style={{width: "100%"}} invisible={!hasNotification}>
            <Box width="100%">
                <Card style={{border: '1px solid #36BEE3', background:'#FFFFFF'}}>
                    <CardActionArea onClick={onClick}>
                        <CardContent component={Box} minHeight={80} py={3} px={2}>
                            <Box display="flex">
                                <Typography gutterBottom variant="h6" className="mb-1" component={Box} flexGrow={1}>
                                {capitalize(label)}
                                </Typography>
                                <Typography gutterBottom variant="h6" component={Box}>{activeStep}/{steps}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" mb={1}>
                                <Typography variant="body2">
                                    <Typography color="primary" component="span" variant="inherit">Folio:</Typography> #{String(id).padStart(6, '0')}
                                </Typography>
                                <Typography variant="body2">
                                    {status}
                                </Typography>
                            </Box>
                            <Box style={{border: '1px solid #36BEE3', borderRadius:33}}>
                                <BorderLinearProgress variant="determinate" value={progress} barcolor={palette.colors[color]} />
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </Badge>
    );
}

RequestCard.defaultProps = {
    hasNotification: false
};

export default RequestCard;