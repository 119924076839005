import React from 'react';
import { Box, Divider, Icon, Typography } from '@material-ui/core';
import palette from '../../../design-system/theme/palette';
import clsx from 'clsx';

const BoxEducacion = (props) => {
    
return(
        <>
            {
                props?.info.length > 0 &&
                (props?.info).map((datos, i) => (
                    <Box key={i} className={props.ClassNameEducacion}>
                    <Typography key={`${i}-tituloprinc`} variant="h4">{datos.tittle}</Typography>
                    {   
                        datos?.info.length > 0 &&
                        datos?.info.map((infoData, index) => (
                            <Box key={`${i}-fragment`} >
                                <Box key={`${i}-${index}`} style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '16px', padding: '0px'}}>
                                    <Icon key={`${i}-${index}-icon`} className={datos.tittle === 'Experiencia' ? clsx(props.classes.boxIcons,'ri-suitcase-line') : clsx(props.classes.boxIcons,'ri-bank-line')} />
                                    <Box key={`${i}-${index}-inner`} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '0px'}}>
                                        <Typography key={`${i}-${index}-titulo`} variant='h4' color='primary'>{infoData.cargo}</Typography>
                                        <Typography key={`${i}-${index}-empresa`} variant='body1'>{infoData.empresa}</Typography>
                                        <Typography key={`${i}-${index}-periodo`} variant='body2'>{infoData.periodo}</Typography>
                                    </Box>
                                </Box>
                                {
                                    index !== datos.info.length - 1 &&
                                    <Divider key={`${i}-${index}-divider`} style={{background: palette.colors.fillStroke[200], width: '689px'}} />
                                }
                            </Box>
                        ))
                    }
                    </Box>
                ))
            }
        </>
    );
};

export default BoxEducacion;
