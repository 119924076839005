import React from 'react';
import PropTypes from 'prop-types'

// @materila
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// icons
import CloseIcon from '@material-ui/icons/Close';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// shared components
import CircularProgressWithLabel from '../../../../../../shared/components/CircularProgressWithLabel/CircularProgressWithLabel';

import dateFormat from '../../../../../../design-system/utils/dateFormat';
import { SIMPLE_DATE_FORMAT } from '../../../../../../design-system/constants/date-formats';

// styles
import useStyles from '../../../../../../assets/styles/jss/pages/requests/DocumentUploadList'
import { Typography } from '@material-ui/core';

import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

const LightTooltip = withStyles((theme) => ({
    arrow: {
        color: theme.palette.common.white,
      },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: '#1C1F24',
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }))(Tooltip);

const title = "Con fecha no mayor a 3 meses;  Los recibos de pago anual deben ser vigentes o del año anterior inmediato; Emitido por una empresa debidamente constituida  y reconocida o de una oficina de gobierno."

const DocumentUploadList = (props) => {
    const {items, ...rest} = props;
    const classes = useStyles();

    const handleChangeFile = item => event =>{
        const file = event.currentTarget.files[0];
        
        props.onChangeFile({
            optionName:item.name,
            fileName:file.name,
            file
        });
    };

    return (
        <Box className={classes.root} {...rest}>
            <List component="nav" aria-label="main mailbox folders">
                {
                    items.map(it => (
                        it.value
                        ?   <ListItem key={it.id}>
                                <ListItemIcon>
                                    <DescriptionOutlinedIcon className={classes.fileIcon} color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary={it.label} secondary={`${it.value.file_name}  -  ${dateFormat(it.value.created_at, SIMPLE_DATE_FORMAT)}`}/>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => props.onClickDelete(it.value)}>
                                        <CloseIcon className={classes.actionIcon}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        :   <ListItem key={it.id}>
                                <ListItemIcon>
                                    <DescriptionOutlinedIcon className={clsx(classes.fileIcon, 'text-fill-stroke-100')}/>
                                </ListItemIcon>
                                <ListItemText 
                                    primary={
                                        <Box display="flex" alignItems="center">
                                            <Typography className={classes.fileName}>{it.label}</Typography>
                                            {/* <LightTooltip title={it.tooltip} arrow placement="top">
                                                <InfoOutlinedIcon className={classes.infoIcon}/>
                                            </LightTooltip> */}
                                        </Box>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Box mr="-15px">
                                        <input accept={it.accept} className="d-none" id={`icon-button-file-${it.id}`} type="file" onChange={ handleChangeFile(it) } />
                                        <label htmlFor={`icon-button-file-${it.id}`}>
                                            <IconButton color="primary" aria-label="upload picture" component="span" className>
                                                <PublishIcon className={classes.actionIcon}/>
                                            </IconButton>
                                        </label>
                                    </Box>
                                </ListItemSecondaryAction>
                            </ListItem>
                    ))
                }
            </List>
        </Box>
    );
};

DocumentUploadList.defaultProps = {
    items: []
};

export default DocumentUploadList;