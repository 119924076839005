import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
// @material-ui/core components
import Box from '@material-ui/core/Box';

import clsx from 'clsx';

// core components
import Loader from './Loader';

const useStyles = makeStyles((theme) => ({
    mainContentLoader: {
        '& .MuiBackdrop-root':{
            zIndex: "1100",
            // maxWidth: "1248px",
            marginLeft: "auto",
            marginRight: "auto",
        }
    },
    
}));


const LoaderContent = ({children, loading, transparent, message, className, helpColor=false,...rest}) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.mainContentLoader, className)} width="100%" position="relative" {...rest}>
            {children}
            {loading && <Loader helpColor={helpColor} open={loading} bgTransparent={transparent} message={message} />}
        </Box>
    );
};

LoaderContent.defaultProps = {
    loading: false,
};

LoaderContent.propTypes = {
    children: PropTypes.any,
    loading: PropTypes.bool
};

export default LoaderContent;




























































































































