import * as permissionTypes from '../types/permissionTypes';
import {ROLE_ADMINISTRADOR, ROLE_ESTUDIANTE, ROLE_MAESTRO, ROLE_STAFF, ROLE_CAPTURISTA} from '../../shared/constants/roles';

function filterAndDistinctRoles(array) {
    const ids = [ROLE_ADMINISTRADOR, ROLE_ESTUDIANTE, ROLE_MAESTRO, ROLE_STAFF, ROLE_CAPTURISTA];
    const filteredArray = array.filter(item => ids.includes(item.role_id));
    const distinctArray = Array.from(new Set(filteredArray.map(item => {
        return filteredArray.find(filterItem => filterItem.role_id === item.role_id);
    })));
    return distinctArray;
}

const getOnlyPermission = (permission) => {
    const tempPermission = {...permission};

    delete tempPermission.person_id;
    delete tempPermission.role_id;
    delete tempPermission.role_name;

    return tempPermission;
};

const getRoleAndPermission = (permission) => ({
        person_id: permission.person_id,
        role_id: permission.role_id,
        role_name: permission.role_name,
        permissions: [getOnlyPermission(permission)],
    });

const groupPermissionByRole = (permissions) => {
    let response = {};
    permissions.map(permission => {
        if(!response[permission.role_id]) {
            response[permission.role_id] = getRoleAndPermission(permission);
        } else {
            response[permission.role_id].permissions = [...response[permission.role_id].permissions, getOnlyPermission(permission)];
        }
    });

    return response;
};

export const applyPermission = (permissions) => (dispatch) => {
    const newObject = groupPermissionByRole(permissions);
    const roles = filterAndDistinctRoles(permissions);

    dispatch({
        type: permissionTypes.APPLY_PERMISSION,
        payload: newObject
    });

    dispatch({
        type: permissionTypes.APPLY_ROLES,
        payload: roles
    });

    localStorage.setItem('permissions', JSON.stringify(permissions))
};

export const removePermission = () => (dispatch) => {
    dispatch({
        type: permissionTypes.REMOVE_PERMISSION
    });
};

export const getAllPermissionsByRoleId = (permissions, roleId) => (dispatcher) => permissions[roleId]?.permissions || [];

export const getPermissionByRoleIdAndPermissionName = (permissions, roleId, permissionName) => (dispatcher) => {
    const tempPermissions = permissions[roleId]?.permissions || [];

    return tempPermissions.find(permission => permission.permission_name === permissionName) || {};
};

export const existsPermissionByRoleId = (permissions, roleId) => (dispatcher) => !!permissions[roleId];
