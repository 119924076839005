import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';

import '../../../../assets/styles/scss/layout/notification-alert.scss';


const useStyles = makeStyles((theme) => ({
    root:{
        // height: 60,
        // width: 60,
        // position: 'relative',
        position: 'fixed',
        right: 60,
        bottom: 46,
        width: 70,
        height: 70,
        zIndex: theme.zIndex.modal + 1
    },
    lightCircle:{
        height: 60,
        width: 60,
        backgroundColor: 'lightcoral',
        borderRadius: '50%',
        // display: 'none',
        // opacity: 0
    },
    mainCircle:{
        height: 50,
        width: 50,
        backgroundColor: 'red',
        borderRadius: '50%',
        // display: 'none',
        // opacity: 0,
        position: 'absolute',
        top: 5,
        left: 5
    }
}));

const NotificationAlert = ({children}) => {
    const classes = useStyles();

    const [isVisible, setIsVisible] = useState(false);
    const [isLooping, setIsLooping] = useState(true);
  
    useEffect(() => {
      let interval;
      if (isLooping) {
        interval = setInterval(() => {
          setIsVisible((prev) => !prev);
        }, 1000); // Cambia cada 1 segundo
      } else {
        // Ocultar los elementos cuando se detenga el bucle
        setIsVisible(false);
        const miDiv = document.getElementById("miDiv");
        const miDiv2 = document.getElementById("miDiv2");
        miDiv.classList.remove("fadeIn");
        miDiv.classList.add("fadeOut");
        miDiv2.classList.remove("fadeIn");
        miDiv2.classList.add("fadeOut");
        setTimeout(() => {
          miDiv.style.display = "none";
          miDiv2.style.display = "none";
          miDiv.classList.remove("fadeOut");
          miDiv2.classList.remove("fadeOut");
        }, 100); // Duración de la animación fadeOut
      }
      return () => clearInterval(interval);
    }, [isLooping]);
  
    useEffect(() => {
      const miDiv = document.getElementById("miDiv");
      const miDiv2 = document.getElementById("miDiv2");
      if (isVisible) {
        miDiv.style.display = "block";
        miDiv.classList.add("fadeIn");
        miDiv.classList.remove("fadeOut");
        setTimeout(() => {
          miDiv2.style.display = "block";
          miDiv2.classList.add("fadeIn");
          miDiv2.classList.remove("fadeOut");
        }, 150); // Cambio más rápido entre las dos divs
      } else if (isLooping) {
        miDiv.classList.remove("fadeIn");
        miDiv.classList.add("fadeOut");
        miDiv2.classList.remove("fadeIn");
        miDiv2.classList.add("fadeOut");
        setTimeout(() => {
          miDiv.style.display = "none";
          miDiv2.style.display = "none";
        }, 50); // Duración de la animación fadeOut
      }
    }, [isVisible, isLooping]);

    return (
        <div id="notification-alert-root" className={classes.root}>
            <div id="miDiv2"/>
            <div id="miDiv"/>
            {children}
        </div>
    );
};

export default NotificationAlert;