import React  from 'react';
import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { getLogoCard } from '../../../../src/constants/logosImages';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CourseCardBody from "../../../shared/components/MembershipCourseCard/CourseCardBody";
import clsx from 'clsx';

import { convertOuToSource }from '../../../../src/constants/convertOuToSource';
import { eventTracker } from '../EventTracker/EventTracker';
import getMembershipIcon from '../../../shared/components/Modalupselling/MembershipIconSelector';
import { Dialog, Icon } from '@material-ui/core';
import { modalUpsellingStyles } from './modalUpsellingStyles';

// routes
import * as paths from '../../../routes/paths';

const CourseCardContent = (props) => {
    // const { isLogged,membershipIncluded,handleCheckout,classes,actualMembership,nextMembership,course,membershipModalOpen,setMembershipModalOpen,handleEnroll,isFree, isCourse,  handleMembershipCheckout } = props;
    const { isLogged, membershipIncluded, handleCheckout, actualMembership, nextMembership, course, membershipModalOpen, setMembershipModalOpen, handleEnroll, isFree, isCourse, handleMembershipCheckout } = props;
    const history = useHistory();
    const classes = modalUpsellingStyles();

    const handleMembershipModalClose=()=>{
        setMembershipModalOpen(false);
    };

    const gotoMemberships = (e) => {
        e.preventDefault();
        //
        const eventData = {};
        eventData['source'] = 'popup_curso';
        eventTracker('ver_membresías', eventData, ['mixpanel']);
        //
        history.push({
            pathname: paths.MEMBRESIAS
        });
    };

    return (
        <Dialog
            open={membershipModalOpen} onClose={handleMembershipModalClose}
            aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description"
        >
            {/* <Box sx={{ minWidth: 650, textAlign: 'left', paddingLeft: '24px', paddingRight: '24px', paddingTop: '16px', paddingBottom: '32px', borderRadius: '8px' }} className={classes.modalInside}> */}
            <Box sx={{ textAlign: 'left', paddingLeft: '24px', paddingRight: '24px', paddingTop: '16px', paddingBottom: '32px', borderRadius: '8px' }} className={classes.modalInside}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='h4' style={{marginBottom:'40px'}}> { isFree?actualMembership?.modal_free?.title:actualMembership?.modal_paid?.title }</Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography style={{ display:'inline' }}>
                            <CloseIcon style={{ fontSize:'22px',color:'#78787D',cursor:'pointer' }} onClick={handleMembershipModalClose} />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item sm={6} xs={10} style={{ paddingTop:'22px' }}>
                        <Box sx={{ textAlign:'center',paddingBottom:'24px' }}>
                            <Grid container justifyContent='center'>
                                <Grid item xs="auto" style={{ paddingRight:'9px' }}>
                                    { getMembershipIcon(actualMembership?.icon,13) }
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography className="font-variant-v7"> { isFree?actualMembership?.modal_free?.actual_membership?.title:actualMembership?.modal_paid?.actual_membership?.title }</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ textAlign:'left' }}>
                            <CourseCardBody
                                media={{
                                    company_id: course?.company_id,
                                        source:{
                                            id: convertOuToSource(course?.company_id, course?.source_id),
                                        },
                                        image: course?.image_url,
                                        saved: false,
                                        course_id: course?.id,
                                        userId: props.user?.person_id,
                                }}
                                content={{
                                    saved: false,
                                    title: course?.name,
                                    course_id: course?.id,
                                    userId: props.user?.person_id,
                                    prices: {
                                        original: course?.list_price,
                                        current: course?.price,
                                    },
                                    detail: {
                                        sourceId: convertOuToSource(course?.company_id, course?.source_id),
                                        lessons: course?.lessons,
                                        level: course?.difficulty_level,
                                        estimated_time: course?.estimated_time,
                                        company_id: course?.company_id,
                                        userLicensed: course?.is_license_active,
                                        coursePurchased: course?.is_purchased,
                                        membership_included: course?.user_membership_included
                                    },
                                    modalityId: course?.modality_id != null ? course.modality_id : 2,
                                    isCourse: isCourse,
                                    level_description:!isCourse?course?.level_description:null
                                }}
                                classname={classes.cardContentHeight}
                            />
                        </Box>
                        <Box sx={{ textAlign:'center' }}>
                        
                        <Button style={{marginTop:59,marginBottom:72}} 
                            onClick={(e) => handleCheckout?handleCheckout(e):handleEnroll()}>
                                { !isLogged?"Continuar sin membresía":(actualMembership?.slug!=="freemium"?"Continuar con "+actualMembership?.name:"Continuar sin membresía") } 
                                <Icon className={clsx(classes.arrowIcon,'ri-arrow-right-s-line')} />
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={10} style={{ paddingTop:'22px',border:'0.5px solid #9F6FFB',borderRadius:'6px',backgroundColor:'rgba(159, 111, 251, 0.16)',paddingLeft:'24px',paddingRight:'24px' }}>
                        <Box sx={{ textAlign:'center',paddingBottom:'24px' }}>
                            <Grid container justifyContent='center'>
                                <Grid item xs="auto" style={{paddingRight:'9px',textAlign:'right'}}>
                                    { getMembershipIcon(nextMembership?.icon,13) }
                                </Grid>
                                <Grid item xs="auto" style={{textAlign:'left'}}>
                                    <Typography className="font-variant-v7">Membresía { nextMembership?nextMembership.name:"" }</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ textAlign:'left',paddingBottom:'8px' }}>
                            <Typography className="font-variant-v7" style={{ color:'#9F6FFB' }}>
                                {
                                    nextMembership?.sub_title
                                }
                            </Typography>
                        </Box>
                        {
                            nextMembership?.list_details?.map((val,index)=>
                            {
                                return (
                                    <Box sx={{ textAlign:'left',paddingBottom:'8px' }}>
                                        <Grid container>
                                            <Grid item xs="auto">
                                                <CheckIcon style={{ fontSize:'16px',color:'#9F6FFB' }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography className="font-variant-v2" style={{ paddingLeft:'16px' }}> {val.title}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                );    
                            })
                        }
                        <Box sx={{ textAlign:'left',paddingBottom:'75px',paddingLeft:'40px',paddingTop:'18px' }} hidden={nextMembership?.slug!=="lite"}>
                            <img src={getLogoCard(13)} style={{ height:'16px',paddingRight:'16px' }} />
                            <img style={{ display:'inline',height:'16px' }} src={getLogoCard(6)} />
                        </Box>
                        <Box sx={{ textAlign:'left',paddingBottom:'50px',paddingLeft:'82px',paddingTop:'18px' }} hidden={nextMembership?.slug!=="premium"}>
                            <img src={getLogoCard(5)} style={{ height:'16px',paddingRight:'16px' }} />
                        </Box>
                        <Box sx={{ textAlign:'left',paddingBottom:'50px',paddingLeft:'40px',paddingTop:'18px' }} hidden={nextMembership?.slug!=="professional"}>
                            <img src={getLogoCard(13)} style={{ height:'16px',paddingRight:'16px' }} />
                            <img style={{ display:'inline',height:'16px' }} src={getLogoCard(6)} />
                        </Box>
                        <Box sx={{ textAlign: 'center', paddingBottom: '12px' }}>
                            <Typography variant='h3'><CurrencyFormat value={nextMembership && nextMembership?.fees ? nextMembership.fees[1].amount : 0} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" /> <Typography variant='h5' style={{ display: 'inline' }}>Por mes</Typography> </Typography>
                        </Box>
                        <Box sx={{ paddingBottom:'20px' }}>
                            <Button variant="contained" color='primary' fullWidth onClick={(e) => handleMembershipCheckout(e,nextMembership)}>
                                {
                                    isFree?(isLogged && actualMembership?.slug!=="freemium"?actualMembership?.modal_free?.next_membership?.action_button+" "+nextMembership?.name:actualMembership?.modal_free?.next_membership?.action_button):(isLogged && actualMembership?.slug!=="freemium"?actualMembership?.modal_paid?.next_membership?.action_button+" "+nextMembership?.name:actualMembership?.modal_paid?.next_membership?.action_button)
                                }
                            </Button>
                        </Box>
                        <Box sx={{ paddingBottom:'24px',textAlign:'center' }}>
                            <Button color='primary' component={RouterLink} onClick={(e) => gotoMemberships(e)} to={paths.MEMBRESIAS}>
                                Ver membresías
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item sm={12} xs={10} style={{textAlign:'right'}}>
                        {
                            <Typography variant='body2' className={classes.modalFooter} style={{ fontSize:'10px',paddingTop:'4px' }}>
                                {isFree?actualMembership?.modal_free?.next_membership?.bottom_text:(course?.company_id==="UNID" && actualMembership?.slug==="lite"?"*No incluye COURSERA":actualMembership?.modal_paid?.next_membership?.bottom_text)}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default CourseCardContent;
