import React from 'react';
import CurrencyFormat from 'react-currency-format';
import clsx from 'clsx';

// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';

const OrderSubtotal = ({ orderSubtotal }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return <Grid container justifyContent="space-between" alignItems='center'>
        <Grid item>
            <Typography variant="body1" className='semi-bold'>Sub-total</Typography>
        </Grid>
        <Grid item>
            <Typography variant='h5' className={clsx("text-orange-300", "font-weight-700", { "font-size-22": isMobile })}>
                <CurrencyFormat value={orderSubtotal} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />
            </Typography>
        </Grid>
    </Grid>
};

export default OrderSubtotal;