import * as menuTypes from '../types/menuTypes'
//import * as userService from '../../services/UserService';
import routelinks from '../../routes/routelinks';
import cloneArray from '../../shared/utils/cloneArray';
import {UserService, ENVIRONMENT} from '@sdk-point/talisis';
import {applyPermission} from './permissionActions';

function getRoutes(permissions){
    //const menu = cloneArray(routelinks.filter(it => it.isNavLink));
    const menu = routelinks.filter(it => it.isNavLink);
    const routes = [];

    for(const route of menu){
        if(route.isMenu){
            const _children = permissions.filter(it => it.parent_id === parseInt(route.id)).map(c => c.object_id);
            if(_children.length){
                route.children = route.children.filter(it => _children.some(a => a === it.pageId))
                routes.push(route);
            }
        }else if(permissions.some(it => it.object_id === route.pageId)){
            routes.push(route);
        }
    }

    return routes;
}

function updateItem (items, prop, value, nestedKey, body){
    for(let item of items){
        if(item[prop] === value){
            item = Object.assign(item, body);
            return items;
        }else if(item[nestedKey] && updateItem(item[nestedKey], prop, value, nestedKey, body)){
            return updateItem(item[nestedKey], prop, value, nestedKey, body);
        }
    }
}
export const getMenu  = () => (dispatch, getState) => {
    const { menu } = getState().menuReducer;

    dispatch({
        type: menuTypes.GET_MENU,
        payload: menu
    });
};
export const applyMenu  = (menu) => (dispatch) => {
    dispatch({
        type: menuTypes.APPLY_MENU,
        payload: menu
    });
};

export const updateMenuItem = (item) => (dispatch, getState) => {
    const { menu } = getState().menuReducer;
    let _menu = [...menu];

    for(const i of item){
        updateItem(_menu, 'id', i.id, 'children', {notification: i.notification});
    }

    dispatch({
        type: menuTypes.APPLY_MENU,
        payload: _menu
    });
};

export const getUserMenu = (user) => (dispatch) => {
    try {
        const userService =  new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)
        userService.getPermissionsAxios(user).then(response => {
            localStorage.setItem("permissions", JSON.stringify(response));
            dispatch(applyPermission(response));    
            const routes = getRoutes(response);
            dispatch(applyMenu(routes));    
        })
        .catch(error => {
            const routes = getRoutes([]);
            dispatch(applyMenu(routes));  
        });
    } catch(e) {
        const routes = getRoutes([]);
        dispatch(applyMenu(routes));  
    }
};