import React from 'react';

// @mui
import { Avatar, Box, CardContent, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import {learnListHH} from '../../../shared/constants/brands-landing-page'

import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme) => ({
    containerModality:{
        background: theme.palette.colors.fillStroke[400],
        padding: '103px 0 125px',
        [theme.breakpoints.down('xs')]:{
            padding: '55px 0 56px',
        },
    },
    containerLearn:{
        padding: '67px 0 118px',
        [theme.breakpoints.down('xs')]:{
            padding: '48px 0',
        },
    },
    modalityParagraph:{
        margin: '19px auto 46px',
        maxWidth: 809,
        [theme.breakpoints.down('sm')]:{
            maxWidth: 600,
        },
        [theme.breakpoints.down('xs')]:{
            maxWidth: 330,
        },
    },
    cardContent: {
        backgroundColor: theme.palette.colors.fillStroke[500],
        border: `.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: '8px',
        height: '326px',
        padding: 0,
        [theme.breakpoints.down('xs')]:{
            marginTop: 40,
            height: '100%',
            paddingBottom: '40px !important'
        },
    },
    info: {
        backgroundColor: theme.palette.colors.ou.hhYellow,
        margin: 'auto',
        marginTop: '-30px',
        height: 84,
        width: 84,
        '& .MuiAvatar-img': {
            height: '49%',
            width: '55%',
        },
        [theme.breakpoints.down('xs')]:{
            height: 64,
            width: 64,
        },
    },
    body: {
        paddingLeft: '7%',
        paddingRight: '7%',
        textAlign: 'left',
    },
    extraInfo: {
        background: theme.palette.colors.grey[500],
        border: '0.5px solid #404150',
        borderRadius: '8px',
        height: '225px',
        padding: '1.5vw',
    },
    learnTitle:{
        [theme.breakpoints.down('xs')]:{
            textAlign: 'start',
            '& h3':{
                maxWidth: 291,
                textAlign: 'inherit'
            }
        },
    },
    learnAvatar:{
        background: theme.palette.colors.gray[500],
        width: 24,
        height: 24,
        color: theme.palette.colors.fillStroke[400],
        marginRight: 16
    }
}));

const INFO_2 = 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/harmonhall/info-2.svg';
const INFO_3 = 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/harmonhall/info-3.svg';

const StudyModalityHH = () => {
    const classes = useStyles();

    return (
        <>
        <ResponsiveContainer className={classes.containerModality}>
            <Typography id="info" variant="h3" align="center" color="textPrimary">Modalidades de estudio</Typography>
            <Box className={classes.modalityParagraph}>
                <Typography variant="body2">Desarrollamos un modelo de aprendizaje inmediato y continuo que te acompañará durante toda tu vida. Contamos con 3 modalidades de estudio para que elijas el que más se acomode a tu estilo de vida:</Typography>
            </Box>
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" className={classes.mt90px}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CardContent className={classes.cardContent}>
                        <Avatar src={INFO_2} className={classes.info} />
                        <Typography variant="h4" className="mt-4">Modelo Virtual</Typography>
                        <Typography component="div" variant="body1" color="textSecondary" className={`${classes.body} mt-4`}>
                            Pensado para una nueva era de aprendizaje y bajo la mejor metodología de enseñanza presencial adaptada a un ambiente digital donde lograrás estudiar en horarios específicos a través de plataformas virtuales.
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CardContent className={classes.cardContent}>
                        <Avatar src={INFO_3} className={classes.info} />
                        <Typography variant="h4" className="mt-4">Modelo 100% Online</Typography>
                        <Typography component="div" variant="body1" color="textSecondary" className={`${classes.body} mt-4`}>
                            Vanguardista y 100% un modelo de aprendizaje online con un acceso a plataforma 24/7 para adaptarse en su totalidad con tu modo de vida.
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </ResponsiveContainer>

        <ResponsiveContainer className={classes.containerLearn}>
            <Box className={classes.learnTitle}>
                <Typography id="info" variant="h3" align="center" color="textPrimary">¿Por qué aprender en Harmon Hall?</Typography>
                <Typography variant="body1">Estudiar inglés en Harmon Hall tiene cada vez más beneficios:</Typography>
            </Box>

            <Hidden xsDown>
                <Grid container spacing={3} direction="row" justifyContent="center" className="mt-5">
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Box className={classes.extraInfo}>
                            <Typography component="div" variant="body1" color="textSecondary" align="center">
                                <svg className="mt-3" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="22" fill="#CCCCCC" />
                                    <path d="M25.0085 13.5455V31H21.3182V17.0483H21.2159L17.2188 19.554V16.2812L21.5398 13.5455H25.0085Z" fill="#2C3236" />
                                </svg>
                                <br />
                                <Typography className="mt-2" color="textSecondary">Cobertura nacional de más de 100 institutos con 55 años de experiencia que avalan nuestros más de 2.000.000 de egresados.</Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Box className={classes.extraInfo}>
                            <Typography component="div" variant="body1" color="textSecondary" align="center">
                                <svg className="mt-3" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="22" fill="#CCCCCC" />
                                    <path d="M15.4148 31V28.3409L21.6278 22.5881C22.1563 22.0767 22.5994 21.6165 22.9574 21.2074C23.321 20.7983 23.5966 20.3977 23.7841 20.0057C23.9716 19.608 24.0653 19.179 24.0653 18.7188C24.0653 18.2074 23.9489 17.767 23.7159 17.3977C23.483 17.0227 23.1648 16.7358 22.7614 16.5369C22.358 16.3324 21.9006 16.2301 21.3892 16.2301C20.8551 16.2301 20.3892 16.3381 19.9915 16.554C19.5938 16.7699 19.2869 17.0795 19.071 17.483C18.8551 17.8864 18.7472 18.3665 18.7472 18.9233H15.2443C15.2443 17.7812 15.5028 16.7898 16.0199 15.9489C16.5369 15.108 17.2614 14.4574 18.1932 13.9972C19.125 13.5369 20.1989 13.3068 21.4148 13.3068C22.6648 13.3068 23.7528 13.5284 24.679 13.9716C25.6108 14.4091 26.3352 15.017 26.8523 15.7955C27.3693 16.5739 27.6278 17.4659 27.6278 18.4716C27.6278 19.1307 27.4972 19.7812 27.2358 20.4233C26.9801 21.0653 26.5227 21.7784 25.8636 22.5625C25.2045 23.3409 24.2756 24.2756 23.0767 25.3665L20.5284 27.8636V27.983H27.858V31H15.4148Z" fill="#2C3236" />
                                </svg>
                                <br />
                                <Typography className="mt-2" color="textSecondary">Cursos con certificaciones internacionales.</Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Box className={classes.extraInfo}>
                            <Typography component="div" variant="body1" color="textSecondary" align="center">
                                <svg className="mt-3" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="22" fill="#CCCCCC" />
                                    <path d="M21.8665 31.2386C20.5938 31.2386 19.4602 31.0199 18.4659 30.5824C17.4773 30.1392 16.696 29.5312 16.1222 28.7585C15.554 27.9801 15.2614 27.0824 15.2443 26.0653H18.9602C18.983 26.4915 19.1222 26.8665 19.3778 27.1903C19.6392 27.5085 19.9858 27.7557 20.4176 27.9318C20.8494 28.108 21.3352 28.196 21.875 28.196C22.4375 28.196 22.9347 28.0966 23.3665 27.8977C23.7983 27.6989 24.1364 27.4233 24.3807 27.071C24.625 26.7187 24.7472 26.3125 24.7472 25.8523C24.7472 25.3864 24.6165 24.9744 24.3551 24.6165C24.0994 24.2528 23.7301 23.9687 23.2472 23.7642C22.7699 23.5597 22.2017 23.4574 21.5426 23.4574H19.9148V20.7472H21.5426C22.0994 20.7472 22.5909 20.6506 23.017 20.4574C23.4489 20.2642 23.7841 19.9972 24.0227 19.6562C24.2614 19.3097 24.3807 18.9062 24.3807 18.446C24.3807 18.0085 24.2756 17.625 24.0653 17.2955C23.8608 16.9602 23.571 16.6989 23.196 16.5114C22.8267 16.3239 22.3949 16.2301 21.9006 16.2301C21.4006 16.2301 20.9432 16.321 20.5284 16.5028C20.1136 16.679 19.7812 16.9318 19.5312 17.2614C19.2812 17.5909 19.1477 17.9773 19.1307 18.4205H15.5938C15.6108 17.4148 15.8977 16.5284 16.4545 15.7614C17.0114 14.9943 17.7614 14.3949 18.7045 13.9631C19.6534 13.5256 20.7244 13.3068 21.9176 13.3068C23.1222 13.3068 24.1761 13.5256 25.0795 13.9631C25.983 14.4006 26.6847 14.9915 27.1847 15.7358C27.6903 16.4744 27.9403 17.304 27.9347 18.2244C27.9403 19.2017 27.6364 20.017 27.0227 20.6705C26.4148 21.3239 25.6222 21.7386 24.6449 21.9148V22.0511C25.929 22.2159 26.9063 22.6619 27.5767 23.3892C28.2528 24.1108 28.5881 25.0142 28.5824 26.0994C28.5881 27.0938 28.3011 27.9773 27.7216 28.75C27.1477 29.5227 26.3551 30.1307 25.3438 30.5739C24.3324 31.017 23.1733 31.2386 21.8665 31.2386Z" fill="#2C3236" />
                                </svg>
                                <br />
                                <Typography className="mt-2" color="textSecondary">Docentes certificados en las mejores prácticas de enseñanzas.</Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Box className={classes.extraInfo}>
                            <Typography component="div" variant="body1" color="textSecondary" align="center">
                                <svg className="mt-3" width="46" height="44" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" width="45" height="44" rx="22" fill="#CCCCCC" />
                                    <path d="M15.6506 27.9318V25.0256L22.9375 13.5455H25.4432V17.5682H23.9602L19.3665 24.8381V24.9744H29.7216V27.9318H15.6506ZM24.0284 31V27.0455L24.0966 25.7585V13.5455H27.5568V31H24.0284Z" fill="#2C3236" />
                                </svg>
                                <br />
                                <Typography className="mt-2" color="textSecondary">Pertenece a la comunidad de aprendizaje más grande de México.</Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box className={classes.extraInfo}>
                            <Typography component="div" variant="body1" color="textSecondary" align="center">
                                <svg className="mt-3" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="22" fill="#CCCCCC" />
                                    <path d="M21.7898 31.2386C20.5852 31.2386 19.5114 31.017 18.5682 30.5739C17.6307 30.1307 16.8864 29.5199 16.3352 28.7415C15.7841 27.9631 15.4972 27.071 15.4744 26.0653H19.054C19.0938 26.7415 19.3778 27.2898 19.9062 27.7102C20.4347 28.1307 21.0625 28.3409 21.7898 28.3409C22.3693 28.3409 22.8807 28.2131 23.3239 27.9574C23.7727 27.696 24.1222 27.3352 24.3722 26.875C24.6278 26.4091 24.7557 25.875 24.7557 25.2727C24.7557 24.6591 24.625 24.1193 24.3636 23.6534C24.108 23.1875 23.7528 22.8239 23.2983 22.5625C22.8438 22.3011 22.3239 22.1676 21.7386 22.1619C21.2273 22.1619 20.7301 22.267 20.2472 22.4773C19.7699 22.6875 19.3977 22.9744 19.1307 23.3381L15.8494 22.75L16.6761 13.5455H27.3466V16.5625H19.7188L19.267 20.9347H19.3693C19.6761 20.5028 20.1392 20.1449 20.7585 19.8608C21.3778 19.5767 22.071 19.4347 22.8381 19.4347C23.8892 19.4347 24.8267 19.6818 25.6506 20.1761C26.4744 20.6705 27.125 21.3494 27.6023 22.2131C28.0795 23.071 28.3153 24.0597 28.3097 25.179C28.3153 26.3551 28.0426 27.4006 27.4915 28.3153C26.946 29.2244 26.1818 29.9403 25.1989 30.4631C24.2216 30.9801 23.0852 31.2386 21.7898 31.2386Z" fill="#2C3236" />
                                </svg>
                                <br />
                                <Typography className="mt-2" color="textSecondary">Metodología de aprendizaje basada en el método de Cambridge.</Typography>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Hidden>
        
            <Hidden smUp>
                <Box paddingTop={7} display="flex" flexDirection="column" gridGap={16}>
                    {
                        learnListHH.map((item, index) =>                             
                            <Box display="inline-flex" key={uuid()}>
                                <Avatar className={classes.learnAvatar}>
                                    <Typography variant="h6" color="inherit">{index + 1}</Typography>
                                </Avatar>
                                <Typography variant="subtitle2" align="left" className="text-gray-100">
                                    {item}
                                </Typography>
                            </Box>
                        )
                    }
                </Box>
            </Hidden>
        </ResponsiveContainer>
        </>
    )
};

export default StudyModalityHH;