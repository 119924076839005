import { Grid, Typography } from '@material-ui/core'
import React from 'react'

const StudentSummary = ({ name, phoneNumber, email }) => {
    return (
        <Grid container className='mt-1' spacing={1}>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography variant='body2' className='semi-bold text-gray-100'>
                            Correo:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' className='text-gray-400'>
                            { email }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography variant='body2' className='semi-bold text-gray-100'>
                            Nombre del alumno:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' className='text-gray-400'>
                            { name }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography variant='body2' className='semi-bold text-gray-100'>
                            Teléfono:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' className='text-gray-400'>
                            +52 { phoneNumber }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StudentSummary
