import React, { Component } from 'react';

// @redux
import { connect } from "react-redux"

// @material-ui/core
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

/// core components
import RequestFlow from './RequestFlow/RequestFlow'
import RequestFiles from './RequestFiles/RequestFiles'
import Activity from './Activity/Activity'
import RequestDetail from './RequestDetail/RequestDetail';
import StatusValidationBttn from './components/StatusValidationBttn';
import Deadline from '../pages/SendingDocuments/components/Deadline';
import RequestInformation from './components/RequestInformation';

// shared - core components
import Breadcrumb from '../../../../shared/components/Breadcrumb/Breadcrumb'
import Tabs from '../../../../shared/components/Tabs/Tabs'
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';
import Toast from '../../../../shared/components/Toast/Toast';

// shared - functions
import downloadFile from '../../../../shared/utils/downloadFiles';
import sleep from '../../../../shared/utils/sleep';
import addDaysToDate from '../../../../shared/utils/addDaysToDate';
import { log } from '../../../../shared/utils/console.js'

// services
import * as routelinks  from '../../../../routes/routelinks';

import {REQUEST_STATUS_ID, REQUEST_STEP_TYPE_ID, DOCUMENT_STATUS_ID, PHYSICAL_REVIEW_STATUS} from '../../../../shared/constants/request-status';

// dependencies
import { v4 as uuid } from 'uuid';

//SDK
import { GlobalService,RequestService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const STATUS_REVIEW = 6;
const TAB_DETAIL = 1;
const TAB_WORKFLOW = 0;

function getFieldsWithFiles( form ) {
    return form.filter( item => !item.section && item.type === 'files' );
}

function getRequiredFieldsWithFiles( form, requiredFields ) {
    let formFiles = getFieldsWithFiles( form );
    return formFiles.filter( item => requiredFields.includes( item.name ));
}

function validate(values, fields, requiredFields){
    const errors = {};

    for(const field of requiredFields){
        const isArrayValid = Array.isArray(values[field]) ? values[field].length > 0 : true;

        if (!values[field] || !isArrayValid) {
            errors[field] = 'Campo requerido';
        }
    }   

    return errors;
}

function validateOptionFiles( errors, formValue, form, requiredFields ) {
    const formList = getRequiredFieldsWithFiles( form, requiredFields );

    formList.map( item => {
        let forms = formValue[ item.name ].filter( value => !(value.file || value.fileName) );
        
        if ( forms.length > 0 ) {
            errors[ item.name ] = 'Campo requerido';
        }
    });

    return errors;
}


function getFormValues(workflow = [], isAuthorizer, optionFiles){
    const forms = [];
    let values = {};
    let requiredFields = [];

    for(const step of workflow){
        if(step.form && step.form.length){
            const plaintext = step.status_id === REQUEST_STATUS_ID.APPROVE;
            const newForm = [
                {id: step.id, section: step.title, status: step.status_id, plaintext}
            ];
            
            for(const field of step.form){

                if(plaintext){
                    field.plaintext = plaintext;
                }

                field.disabled = !step.is_active || !isAuthorizer;

                if(!field.disabled && field.required){
                    requiredFields.push(field.name);
                }

                delete field.value;
                delete field.checkboxes;

                field['request_step_type_id'] = step.request_step_type_id;
                field['sequence'] = step.sequence;

                newForm.push(field);
            }

            Array.prototype.push.apply(forms, newForm); 
            values = { ...values, ...step.form_value };
        }
    }

    if ( !!optionFiles ) {
        values = { ...values, ...processOptionFiles( forms, values, optionFiles )};
    }

    return {forms, values, requiredFields};
}

function showButtons(isAuthorizer,activeTab, form, activeWorkflowStep){
    const authorizationDetail = isAuthorizer && activeTab === TAB_DETAIL && form.length;
    const authorizationOnly = isAuthorizer && activeTab === TAB_WORKFLOW && !form.length;

    return (authorizationDetail || authorizationOnly) && (activeWorkflowStep.request_step_type_id !== REQUEST_STEP_TYPE_ID.WEBHOOK)
}

function processOptionFiles( form, formValue, optionFile) {
    const formList = getFieldsWithFiles( form );

    let responseFormValue = {};

    for ( const formItem of formList ) {
        responseFormValue[ formItem.name ] = formValue[ formItem.name ].map( item => {
            let index = -1;

            for ( let file of optionFile ) {
                index++;
                if ( file.file_name === item.fileName ) {
                    file.optionName = item.optionName;
                    file.documentStatus = item.documentStatus;
                    file.physicalReviewStatus = item.physicalReviewStatus;
                    file.commentRejected = item.commentRejected;
                    return { 
                        optionName: item.optionName, 
                        fileName: item.documentStatus === DOCUMENT_STATUS_ID.REJECTED ? '' : item.fileName,
                        documentStatus: item.documentStatus,
                        commentRejected: item.commentRejected,
                        physicalReviewStatus: item.physicalReviewStatus,
                        ...optionFile[ index ] 
                    };
                }
            }

            return item;
        });
    }
    return responseFormValue;
}

async function processFormValueWithFiles( form, removeOptionFiles, form_value, dataId ) {
    let formFilesList = getFieldsWithFiles( form );

    for ( const fileId of removeOptionFiles ) {
        await GlobalServiceSDK.deleteFile( fileId );
    }

    for ( const formFile of  formFilesList ) {
        let _formValue = form_value[ formFile.name ];
        let lengthFormValue = _formValue.length;

        for (let index = 0; index < lengthFormValue; index++ ) {
            let objectValue = _formValue[ index ];

            let file = objectValue.file;
            if( !!file && file instanceof File){
                await GlobalServiceSDK.uploadFile(file, 'requests', 'request-step', dataId);
                delete objectValue.file;
                form_value[ formFile.name ][ index ] = objectValue;
            } 
            
            if ( !!objectValue.id ) {
                const requiredTags = ['optionName', 'fileName', 'documentStatus', 'physicalReviewStatus', 'commentRejected' ];
                const newObject = {};

                for ( let requiredTag of requiredTags ) {
                    if ( objectValue.hasOwnProperty(requiredTag) ) {
                        newObject[requiredTag] = objectValue[requiredTag];
                    }
                }                

                form_value[ formFile.name ][ index ] = newObject;
            }
        }
    }

    return { ...form_value };
}

function getPreviousStepToDocumentReception(activeWorkflowStep, data, baseFileUpload = false) {
    let response = {};
    let previousStepSequence = 0;

    if (activeWorkflowStep.sequence > 1) {
        previousStepSequence = baseFileUpload? 1: activeWorkflowStep.sequence - 1;

        response = data.workflow.find(item => item.sequence === previousStepSequence && item.request_step_type_id === (baseFileUpload? REQUEST_STEP_TYPE_ID.DOCUMENTS : item.request_step_type_id));
    }

    return response;
}

class ShowRequestItem extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            activeTab: 0,
            loading: true,
            data: {},
            activeWorkflowStep: {},
            isAuthorizer: false,
            files: [],
            requiredFields: [],
            errors: {},
            requestTitle: '',
            formValue: {},
            form: [],
            removeOptionFiles: [],
            previousStep: {},
            baseStepWorkflow: {},
            toastProps: { message: "", open: false },
        }
    }

    componentDidMount(){
        const {id} = this.props.match.params;

        this.getRequestWorkflow(id)
    }

    getRequestWorkflow = async (id) => {
        try {
            const data = await RequestServiceSDK.getRequestWorkflow(id);
            // await RequestServiceSDK.getRequestWorkflow(id);

            // const data = requesItemData;
            this.setState(prevState => {
                const title = `${data.title} #${String(id).padStart(6, '0')}`
                const activeWorkflowStep = data.workflow.find(it => it.is_active) || null;
                const authorizerPendingReview = activeWorkflowStep ? activeWorkflowStep.authorizers.filter(a => a.status_id === STATUS_REVIEW && a.authorizer_type_id === 1) : [];
                const isAuthorizer = authorizerPendingReview.some(it => it.person_id === this.props.user.person_id);
                // data.workflow[0].form = data.workflow[0].form.map(it => ({...it, required: true}));
                const {forms, values, requiredFields} = getFormValues(data.workflow, isAuthorizer, data.optionFiles);
                const baseStepWorkflow = activeWorkflowStep ? getPreviousStepToDocumentReception(activeWorkflowStep, data, true) : {};
                const previousStep = activeWorkflowStep ? getPreviousStepToDocumentReception(activeWorkflowStep, data) : {};
                data.deadline_at = data.deadline_at ? data.deadline_at : addDaysToDate(data.created_at, data.duration);

                return {
                    ...prevState,
                    id: id,
                    requestTitle: title,
                    loading: false,
                    data,
                    activeWorkflowStep,
                    isAuthorizer: authorizerPendingReview.some(it => it.person_id === this.props.user.person_id),
                    files: data.files,
                    formValue: values,
                    form: forms,
                    requiredFields,
                    errors: validateOptionFiles( validate(values, forms, requiredFields), values, forms, requiredFields ),
                    previousStep,
                    baseStepWorkflow
                }
            })
        } catch (e) {
            log('error', e)
        }
    }

    // Validacion del paso 2 de recepcion de documentos
    validateRecepcionDocumentCompleted = () => {
        let responseIsCompleted = false;
        let responseHasData = false;
        const { activeWorkflowStep, previousStep, formValue } = this.state;
        let formName = '';

        // Revision de documentos digitales
        if (activeWorkflowStep.request_step_type_id === REQUEST_STEP_TYPE_ID.DIGITAL_DOCUMENT_REVIEW) {
            formName = previousStep.form[0].name;
            let fileLength = formValue[formName].length;
            const filesRows = formValue[formName];
            let counterIsCompleted = 0;
            let counterHasData = 0;

            for (let i = 0; i < fileLength; i++) { 
                if (!!filesRows[i].documentStatus) {
                    counterHasData++;
                    if (filesRows[i].documentStatus === DOCUMENT_STATUS_ID.APPROVED) counterIsCompleted++;
                }
            }

            responseHasData = !(counterHasData === fileLength);
            responseIsCompleted = !(counterIsCompleted === fileLength);
            
        }

        return {responseIsCompleted, responseHasData};
    };

    // Validacion del paso 4 de recepcion de documentos
    validateRecepcionPhysicalDocumentCompleted = () => {
        let responseIsCompleted = false;
        let responseHasData = false;
        const { activeWorkflowStep, formValue, baseStepWorkflow } = this.state;
        let formName = '';

         // Revision de documentos fisicos
         if (activeWorkflowStep.request_step_type_id === REQUEST_STEP_TYPE_ID.PHYSICAL_DOCUMENT_REVIEW) {
            formName = baseStepWorkflow.form[0].name;
            const options = baseStepWorkflow.form[0].options;
            const physicalFiles = options.filter(item => !!item.isPhysicalFile).map(item => item.name);
            let fileLength = physicalFiles.length;
            const filesRowsLength = formValue[formName].length;
            const filesRows = formValue[formName];
            let counterIsCompleted = 0;
            let counterHasData = 0;

            for (let i = 0; i < filesRowsLength; i++) { 
                if (!!filesRows[i].physicalReviewStatus){;
                    counterHasData++;
                    if (filesRows[i].physicalReviewStatus === PHYSICAL_REVIEW_STATUS.APPROVED) counterIsCompleted++;
                } 
            }

            responseIsCompleted = !(counterIsCompleted === fileLength);
            responseHasData = !(counterHasData === fileLength);
            
        }

        return {responseIsCompleted, responseHasData};
    };


    handleChangeTab = (tab) => this.setState({activeTab: tab});

    handleSelectStatus = async opt => { 
        const {files, activeWorkflowStep, formValue, form, removeOptionFiles, previousStep, data, baseStepWorkflow } = this.state;
        const { statusId, comments, returnToStep } = opt; 
        let _removeOptionFiles = [...removeOptionFiles];
        let baseStepWorkflowId;

        // Procesar el paso de validacion de documentos digitlaes y fisicos.
        let allowedKeysBaseStep;
        let form_value_base_step;
        if (activeWorkflowStep.request_step_type_id === REQUEST_STEP_TYPE_ID.DIGITAL_DOCUMENT_REVIEW || activeWorkflowStep.request_step_type_id === REQUEST_STEP_TYPE_ID.PHYSICAL_DOCUMENT_REVIEW) {
            allowedKeysBaseStep = Object.keys(baseStepWorkflow.form_value);
            form_value_base_step = Object.keys(formValue)
            .filter(key => allowedKeysBaseStep.includes(key))
            .reduce((obj, key) => {
                obj[key] = formValue[key];
                return obj;
            }, {});
            
            // Modificamos los estatus de la recepcion de documentos y pasamos los replazados a rechazados.
            if (returnToStep === baseStepWorkflow.id) {
                for (let item in form_value_base_step) {
                    form_value_base_step[item] = form_value_base_step[item].map(itemFormValue => {
                        if (itemFormValue.documentStatus && itemFormValue.documentStatus === DOCUMENT_STATUS_ID.REPLACED) {
                            itemFormValue.documentStatus = DOCUMENT_STATUS_ID.REJECTED;
                        }

                        return itemFormValue;
                    });
                }                    
            }

            form_value_base_step = await processFormValueWithFiles( baseStepWorkflow.form, [], form_value_base_step, undefined);
            baseStepWorkflowId = baseStepWorkflow.id;

        }

        if(activeWorkflowStep.request_step_type_id === REQUEST_STEP_TYPE_ID.DOCUMENTS && activeWorkflowStep.is_reassigned){
            _removeOptionFiles = data.optionFiles.filter(optFile => optFile.documentStatus === DOCUMENT_STATUS_ID.REJECTED).map(it => it.id);
        }

        const allowedKeys = Object.keys(activeWorkflowStep.form_value);
        let form_value = Object.keys(formValue)
            .filter(key => allowedKeys.includes(key))
            .reduce((obj, key) => {
                obj[key] = formValue[key];
                return obj;
            }, {});

        this.setState({ loading: true });

        try {
            form_value = await processFormValueWithFiles( activeWorkflowStep.form, _removeOptionFiles, form_value, this.state.data.id );
            const body = {
                "person_id": this.props.user.person_id,
                "request_workflow_id": activeWorkflowStep.id,
                "comments": comments,
                "reassign_to_step": returnToStep || null,
                "has_file" : files.length && files.some(it => it instanceof File),
                form_value,
                form_value_base_step,
                baseStepWorkflowId
            };

            await RequestServiceSDK.updateRequestWorkflow(this.state.data.id, body, statusId);

            for(const file of files){
                if(file instanceof File){
                    await GlobalServiceSDK.uploadFile(file, 'requests', 'requests', this.state.data.id)
                }
            }
            this.props.history.push(routelinks.SOLICITUDES);
        } catch (e) {
            log('error', e)
        }
    }
    
    handleChangeFiles = (files) => {
        this.setState({files})
    };

    handleChangeForm = e => {
        const {name, value, type, optionFile} = e.target;
        const {formValue, form, requiredFields} = this.state;

        let _formValue = { ...formValue };

        if ( !!type && type === 'files' ) {
            let optionValues = _formValue[ name ];
            let option = optionValues.find( value => value.optionName === optionFile );

            option.documentStatus = DOCUMENT_STATUS_ID.REPLACED;

            let index = optionValues.indexOf( option );
            
            if ( index !== -1 ) {
                let file = value;
                file.id = uuid();

                _formValue[ name ][ index ] = { 
                    ..._formValue[ name ][ index ], 
                    file, 
                    fileName: file.name,
                    documentStatus: DOCUMENT_STATUS_ID.REPLACED,
                };
            }
        } else {
            _formValue[name] = value;
        }

        const errors = validateOptionFiles( validate(_formValue, form, requiredFields), _formValue, form, requiredFields );

        this.setState({
            formValue: _formValue,
            errors
        })
    };

    handleRemoveOptionFile = ({ target }) => {
        const { name, value, optionFile, isSavedFile } = target;
        const { formValue, form, requiredFields, removeOptionFiles } = this.state;
        
        let _formValue = { ...formValue };

        const optionsValue = _formValue[ name ];

        let _removeOptionFiles = [];

        if ( !!optionsValue ) {
            const newOptionsValue = optionsValue.map( value => {
                if ( value.optionName === optionFile ) {
                    if ( isSavedFile )
                        _removeOptionFiles = [...removeOptionFiles, value.id];

                    value = { optionName: optionFile };
                }
            
                return value;
            });
            
            _formValue[ name ] = newOptionsValue;

            const errors = validateOptionFiles( validate( _formValue, form, requiredFields ), _formValue, form, requiredFields );
            
            this.setState({
                formValue: _formValue,
                errors,
                removeOptionFiles: _removeOptionFiles
            });
        }
    };

    handleChangeDetailForm = async (body) => {
        const { activeWorkflowStep, removeOptionFiles, data } = this.state;

        this.setState({loading: true});

        try {
            let form_value = await processFormValueWithFiles( activeWorkflowStep.form, removeOptionFiles, body.form_value, data.id );
            body['form_value'] = form_value;
            
            await RequestServiceSDK.updateRequestWorkflowDetail(body);

            await this.getRequestWorkflow( data.id );
            this.setState({loading: false});
        } catch (e) {
            log('error', e)
        }
    }

    handleChangeStatusToDocumentReception = (optionName, statusValue, stepName, comment) => {
        this.setState(prevState => {
            let _formValues = {...prevState.formValue};

            _formValues[stepName].map(item => {
                if (item.optionName === optionName) {
                    if (!!statusValue) {
                        item[`documentStatus`] = statusValue;
                    } else {
                        delete item[`documentStatus`];
                    }

                    if (!!comment) {
                        item[`commentRejected`] = comment;
                    } else {
                        delete item[`commentRejected`];
                    }
                }

                return {...item};
            });

            prevState['formValue'] = _formValues;

            return {...prevState};
        });
    };

    handleGetShippingGuide = async (personId, requestPersonId) => {
        const { data } = this.state;
        try {
            this.setState({loading: true});
            const response = await RequestServiceSDK.createShippingGuide({personId, requestPersonId});

            if (!!response && response.hasOwnProperty('customError')) {
                this.setState({loading: false, toastProps: {severity: "error", open: true, message: response.customError}});
            }else if (!!response && response.hasOwnProperty('label_url')) { 
                downloadFile(response.label_url, 'Guía de documentos oficiales.pdf', true);
                await sleep(2500);

                await this.handleSelectStatus({ statusId: REQUEST_STATUS_ID.APPROVE, comments: `Aprobación automática por parte de la generación de guía para recepción de documentos`, returnToStep: "" });
            }
        } catch(error) {
            this.setState({loading: false, toastProps: {severity: "error", open: true, message: "Ocurrio un error al procesar la guía, favor de intentar de nuevo"}});
            log('error\n', error);
        }
    };

    handleChangeStatusToPhysicalFilesReview = (nameForm, statusValue, file) => {
        this.setState(prevState => {
            let _formValues = {...prevState.formValue};

            _formValues[nameForm].map(item => {
                if (item.optionName === file.name) {
                    if (!!statusValue) {
                        item[`physicalReviewStatus`] = statusValue;
                    } 
                }

                return {...item};
            });

            prevState['formValue'] = _formValues;

            return {...prevState};
        });
    };

    handleFinishedToast = () => {
        this.setState({ ...this.state, toastProps: { message: "", open: false }});
    }

    render() {
        const {activeTab, loading, data, isAuthorizer, files, formValue, form, requestTitle, errors, activeWorkflowStep, toastProps} = this.state;
        const tabs = [
            {
                tab: 'Estatus',
                content: <RequestFlow data={data} onClickShowDetail={this.handleChangeTab}/>
            },
            {
                tab: <Typography variant="subtitle2">
                    Detalle &nbsp;
                    {
                        (isAuthorizer && Boolean(form.length)) &&
                        <FiberManualRecordIcon color="error" style={{fontSize: 9}}/>
                    }
                </Typography>,
                content: <RequestDetail 
                    onChange={this.handleChangeForm} 
                    values={formValue} 
                    form={form} 
                    onRemoveFile={ this.handleRemoveOptionFile } 
                    data={data.workflow} 
                    onChangeDetailForm={this.handleChangeDetailForm} 
                    isAuthorizer={isAuthorizer} 
                    onChangeStatusToDocumentReception={this.handleChangeStatusToDocumentReception} 
                    onGetShippingGuide={this.handleGetShippingGuide} 
                    onChangeStatusToPhysicalFilesReview={this.handleChangeStatusToPhysicalFilesReview} 
                    shipment={JSON.parse(data.shipment || null)}
                    deadlineAt={data.deadline_at}
                />
            },
            {
                tab: 'Archivos',
                content: <RequestFiles isAuthorizer={isAuthorizer} onChange={this.handleChangeFiles} files={files} />
            },
            {
                tab: 'Actividad',
                content: <Activity data={data}/>
            },
        ];

        return (
            <Box mb={5}>
                <Breadcrumb
                    items={[
                        {to: routelinks.SOLICITUDES, label: 'Trámites'},
                        {label: 'Mis Solicitudes'}
                    ]} 
                />

                {data && <RequestInformation data={data}/>}
                <Tabs
                    onChange={this.handleChangeTab}
                    activeTab={activeTab}
                    tabs={tabs.map(it => it.tab) }
                    disabledAll={loading}
                >
                    
                    <LoaderContent loading={loading} transparent={true}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={8} container>
                                {
                                    tabs[activeTab].content
                                }
                            </Grid>
                            <Grid item xs={12} md={4} container>
                                <Grid item xs={12} container>
                                    <Grid item xs={12}>
                                        <Deadline date={data ? data.deadline_at : null} />
                                        {
                                            (showButtons(isAuthorizer,activeTab,form, activeWorkflowStep) && activeWorkflowStep.request_step_type_id !== REQUEST_STEP_TYPE_ID.SHIPPING_GUIDE) &&
                                            <StatusValidationBttn 
                                                onSelect={this.handleSelectStatus} 
                                                workflow={data.workflow}
                                                disabled={Boolean(Object.keys(errors).length)}
                                                rejectDisabledDocument={this.validateRecepcionDocumentCompleted()}
                                                rejectDisabledPhysicalDocument={this.validateRecepcionPhysicalDocumentCompleted()}
                                                activeStepId={activeWorkflowStep.request_step_type_id}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </LoaderContent> 
                    <Toast {...toastProps} autoHideDuration={6000} onFinished={this.handleFinishedToast}/>
                </Tabs>
            </Box>
        );
    }
}

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(ShowRequestItem);