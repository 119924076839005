import { Box, Card, Icon, Typography, useMediaQuery } from '@material-ui/core';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../../assets/styles/jss/pages/feed/feedAlertsStyle';

const FeedAlerts = () => {
    const classes = useStyles();
    const {restrictions} = useSelector((state) => state.userReducer.user);
    //Recordar para la siguientes integraciones revisar si habra personas con 2 retenciones al mismo tiempo
    return (
        <>
            {
                (restrictions && restrictions.length > 0) &&
                <Card className={classes.cardRoot}>
                    <Box className={classes.boxTitle}>
                        <Typography className='text-gray-100' variant='h4'>Alertas</Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gridGap={8}>
                        <Box className={classes.boxAlert}>
                            <Icon className={`ri-error-warning-line text-warning-300 font-size-24`} />
                            <Typography className='text-warning-300' variant='body1'>{restrictions[0].alert_title}</Typography>
                        </Box>
                        <Typography variant='body2' className='text-gray-100'>{restrictions[0].alert_message}</Typography>
                    </Box>
                </Card>
            }
        </>
    );
};

export default FeedAlerts;
