import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    documentIcon: {
        fontSize: "40px !important",
    },
    borderPhysicalDocumentItem: {
        borderTop: `0.5px solid ${theme.palette.colors.fillStroke[200]}`
    },
    inlineBlock: {
        display: 'inline-block',
    }
}));