import React, { useState, useEffect } from 'react';

// @material-ui/core
import { Grid, Icon, Typography } from '@material-ui/core';
import Toast from '../../../../../shared/components/Toast/Toast';
import clsx from 'clsx';

// assets
// import CashInstructionsSvg from "../../../../../assets/images/banks/payments/cash_instructions.svg";
import CashInstructionsSvg from "../../../../../assets/images/banks/payments/cash.svg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

//SDK
import { cashNewStyles } from './cashNewStyles';

const Cash = ({ user, order, references, onGenerateRef, captured, back, proceed }) => {
    // const classes = classesRef;
    const classes = cashNewStyles();
    const data = references?.conekta?.cash || {};
    // const history = useHistory();
    const [accordionExpanded, setAccordionExpanded] = useState(true);
    const [toastProps,setToastProps]=useState({severity: "error", open: false, message: ""});

    if (!data.amount) data.amount = order?.total || "0";
    if (!data.currency) data.currency = "MXN";
    if (!data.reference) data.reference = "----";

    const sendTrack = () => {
        //Se agrega track de Checkout pago con efctivo
        const role = JSON.parse(localStorage.getItem('permissions'));
    };
    useEffect(() => {
        if((references?.conekta?.cash?.reference) != undefined){
            sendTrack();
        }
    }, []);

    const handleCloseToast = ()=>{

    };

    const valueValidator = (value) =>{
        if (value == null || value == 'undefined'){
            let Fixed= 'Sin informacion';
            return Fixed;
        }
        return value;
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container direction="row">
                    <Grid item xs={12} className='mt-5 mb-5'>
                        <Accordion className={clsx('bg-fill-stroke-400', classes.accordion)} expanded={accordionExpanded}>
                            <AccordionSummary className={clsx('bg-fill-stroke-400','px-3')} expandIcon={<ExpandMoreIcon className={classes.paymentMethodsAccordionTitleArrow} />} onClick={() => { setAccordionExpanded(!accordionExpanded) }}>
                                <Grid container wrap='nowrap' alignItems='left'>
                                    <Icon className='ri-wallet-3-line font-size-16' />
                                    <Typography variant='body2' className={clsx('semi-bold','pl-3')} align='left'>
                                        Información acerca del pago en Efectivo
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.paymentMethodsAccordionDetails}>
                                <img style={{maxWidth:'100%'}} src={CashInstructionsSvg} alt="instrucciones efectivo" />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
            <Toast {...toastProps} onFinished={handleCloseToast} duration={3000} />
        </>
    )
};

export default Cash;
