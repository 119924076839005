export const PRODUCT_TYPE = {
    COURSE: 1,
    PROGRAM: 2,
    MEMBERSHIP: 3
};

export const ORDER_STATUS = {
    PENDING: 1,
    SUCCESS: 3,
    MEMBERSHIP_PURCHASE: 5
};

export const PAYMENT_METHOD = {
    CARD: "card_payment",
    SPEI: "bank_transfer_payment",
    CASH: "cash_payment",
    FREE: "free_charge"
};