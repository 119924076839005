import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';

// @shared
import Loader from '../../../../shared/components/Loader/LoaderContent';
import MembershipPlanCard from './MembershipCard/MembershipCard';
import CustomMembershipPlanCard from './CustomMembershipCard/CustomMembershipCard';
import dateFormat from '../../../../design-system/utils/dateFormat';
import { MEMBERSHIPS } from '../../../../shared/constants/memberships';
import { log } from '../../../../shared/utils/console.js'
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

// Cart
import { addProductsToCart } from '../../../../shared/utils/cartHelper.js';

// @reducer
import { applyCart, applyCartProducts } from '../../../../redux/actions/cartActions.js';

// @routes
import * as paths from '../../../../routes/paths.js';

// @SDK
import { MembershipService, ProductService } from '@sdk-point/talisis';
import { getNameMembership, getPeriodMembership } from '../../../../shared/utils/checkoutHelpers.js';

const ProductServiceSDK = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    titleLabel: {
        color: theme.palette.colors.violet[300]
    },
    memberSinceLabel: {
        color: theme.palette.colors.gray[400]
    },
    description_alert:
    {
        color: '#CCCCCC'
    }
}));

const Membership = ({ user, isCurrentTab }) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [userAddOn, setUserAddOn] = React.useState(null);
    const [userMembership, setUserMembership] = React.useState(null);
    const [nextMembership, setNextMembership] = React.useState(null);
    const [defaultMembership, setDefaultMembership] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [cancelMode, setCancelMode] = React.useState(false);
    const [cancelled, setCancelled] = React.useState(false);
    const [cancelError, setCancelError] = React.useState(false);
    const { cart } = useSelector((state) => state.cartReducer);
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));

    const getPersonMembershipInfo = async () => {
        try {
            setLoading(true);
            const response = await membershipService.getPersonMembershipDetail(user.person_id);

            if (!response.userMembership && !response.nextMembership) {
                setError("No se encontró información de tu membresía Talisis.");
            }
            else {
                setUserMembership(response.userMembership);
                setNextMembership(response.nextMembership);
                setDefaultMembership(response.defaultMembership);
                setUserAddOn(response.userAddOn);
            }

            setLoading(false);
        }
        catch (e) {
            setError("Ocurrió un error al obtener la información de tu membresía Talisis.");
            setLoading(false);
            log(e.message);
        }
    };

    const manuallyAttemptMembershipPayment = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            setLoading(true);
            const productInfo = await ProductServiceSDK.getProductById(userMembership?.product_id);
            log("productInfo:", productInfo);
            // Preparar los productos para la recompra
            const products = [];
            products.push({
                "product_id": productInfo.id,
                "product_name": productInfo.name,
                "product_image_url": productInfo?.image_url,
                "product_description": productInfo?.description,
                "product_type_id": productInfo?.product_type_id,
                "quantity": 1,
                "product_price_id": productInfo?.product_price_id,
                "unit_price": productInfo?.list_price,
                "discount": 0
            });
            // Agregar el add-on a la recompra si el usuario lo tiene con su membresía 
            if (userAddOn) {
                products.push({
                    "product_id": userAddOn.id,
                    "product_name": userAddOn.name,
                    "product_image_url": userAddOn?.image_url,
                    "product_description": userAddOn?.description,
                    "product_type_id": userAddOn?.product_type_id,
                    "quantity": 1,
                    "product_price_id": userAddOn?.product_price_id,
                    "unit_price": userAddOn?.list_price,
                    "discount": 0
                });
            }
            // Agregar productos carrito
            const response = await addProductsToCart({
                cart_id: cart?.id,
                products: [...products]
            });
            // Event Tracker v2
            if (userAddOn) {
                handleMembershipAddonEventTrack(productInfo, userAddOn);
            } else {
                handleMembershipRepurchaseEventTrack(productInfo);
            }
            // Actualizar estado reducer del carrito
            dispatch(applyCart(response));
            dispatch(applyCartProducts(response.cart_details));
            setLoading(false);
            // Redireccionar al carrito para ver el detalle.
            history.push(paths.CART);
        } catch (e) {
            log("error", e);
        }
    };

    const handleMembershipRepurchaseEventTrack = (product) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product?.name;
            eventData['precioArticulo'] = product?.list_price;
            eventData['articuloType'] = "membresías";
            eventData['userId'] = user?.matricula || user?.person_id || "";
            eventData['subscriptionType'] = getNameMembership(product?.name);
            eventData['periodoPago'] = getPeriodMembership(product?.sku);
            eventTracker('agregar_membresia', eventData, ['mixpanel', 'hubspot', 'google']);
        } catch (e) {
            log("event tracker error", e);
        }
    };
    
    const handleMembershipAddonEventTrack = (standalone, addOn) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = addOn?.name;
            eventData['precioArticulo'] = addOn?.list_price + standalone?.list_price;
            eventData['userId'] = user?.matricula || user?.person_id || "";
            eventData['articuloType'] = "addOn"
            eventData['subscriptionType'] = getNameMembership(standalone?.name);
            eventTracker('agregar_membresia_addon', eventData, ['mixpanel', 'hubspot', 'google']);
        } catch (e) {
            log("event agregar_membresia_addon error", e);
        }
    };

    const handleCancelMembership = async () => {
        try {
            setLoading(true);
            const body = { person_id: user.person_id };
            const response = await membershipService.postCancelPersonMembership(body);
            setCancelled(true);
            setCancelMode(false);
            setLoading(false);
            setCancelError(false);
            const eventData = {};
            eventData['tipoMembresia'] = userMembership?.slug;
            const months = userMembership?.term_months;
            eventData['periodoPago'] = months === 12 ? 'anual' : months === 6 ? 'semestral' : months === 3 ? 'trimestral' : 'mensual';
            eventData['precio'] = userMembership?.amount;
            eventData['metodoPago'] = response?.metodo_pago;
            eventTracker('cancelar_pago', eventData, ['hubspot', 'google']);
        }
        catch (e) {
            setCancelled(false);
            setLoading(false);
            setCancelError(true);
            setCancelMode(false);
        }
    }

    React.useEffect(() => {
        getPersonMembershipInfo();
    }, []);

    React.useEffect(() => {
        if (isCurrentTab) {
            sendMembresiaEventToMixPanel();
        }
    }, [isCurrentTab]);

    const sendMembresiaEventToMixPanel = () => {
        const eventData = {};
        eventTracker('s_membresia', eventData, ['mixpanel']);
    };

    return (
        <Loader loading={loading}>
            <Box hidden={cancelMode}>
                {userMembership && userMembership.id > MEMBERSHIPS.FREEMIUM && userMembership?.subscription_past_due && userMembership.is_active &&
                    <Box className="pb-3">
                        <Alert variant="filled" severity="error" color="error" icon={<i class="ri-alert-fill text-error-300"></i>} className="mb-3">
                            <Typography component="div" variant="body2" className="semi-bold">Tu fecha de pago ya pasó, tienes un pago pendiente.</Typography>
                            <Typography component="p" variant="body2" className="mt-2 text-gray-500">
                                No hemos recibido el pago de tu suscripción. <Link href="" variant="body2" className="text-orange-300" onClick={manuallyAttemptMembershipPayment}>Haz clic aquí</Link> para realizar tu pago manualmente y evitar la suspensión del servicio.
                            </Typography>
                            <Typography component="p" variant="body2" className="text-gray-500">
                                Si ya lo realizaste <Link href="#" variant="body2" className="text-orange-300" onClick={() => {
                                    const talisiHelpDeskButton = document.getElementById('talisis_helpdesk_button');
                                    if (talisiHelpDeskButton) {
                                        talisiHelpDeskButton.click();
                                    }
                                }}>Contacta a soporte</Link> para recibir atención personalizada.
                            </Typography>
                        </Alert>
                    </Box>
                }
                {userMembership && userMembership.id > MEMBERSHIPS.FREEMIUM && !userMembership.is_active &&
                    <Box className='pb-3'>
                        <Alert variant="filled" severity="error" color="error" icon={<i class="ri-alert-fill text-error-300"></i>} className="mb-3">
                            <Typography component="div" variant="body2" className="semi-bold">Tu acceso a la membresía ha sido temporalmente suspendido debido a un pago pendiente.</Typography>
                            <Typography component="p" variant="body2" className="mt-2 text-gray-500">
                                Te invitamos a renovar tu membresía lo antes posible. <Link href="" variant="body2" className="text-orange-300" onClick={manuallyAttemptMembershipPayment}>Haz clic aquí</Link> para ir al checkout y agregar un nuevo método de pago.
                            </Typography>
                            <Typography component="p" variant="body2" className="mt-0 text-gray-500">
                                No dejes que nada te detenga. ¡Sigue aprendiendo!
                            </Typography>
                        </Alert>
                    </Box>
                }
                {
                    cancelled === true &&
                    <Box className='pb-3'>
                        <Alert onClose={() => { }} variant="standard" className='mb-3' severity="success" style={{ background: 'rgba(0, 198, 117,0.1)', color: 'rgb(255, 255, 255)', border: '0.5px solid #00C675' }} hidden={true}>
                            <Box className='mb-1'>
                                <Typography className='font-variant-v3'>
                                    Tu solicitud de cancelación se ha procesado exitosamente
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className={clsx('font-variant-v2', classes.description_alert)}>
                                    El proceso puede tardar unos días en reflejarse. Si ves algún cargo posterior, te será reembolsado al 100%. Tus cursos, licenciaturas o maestrías que te estarán esperando cuando quieras volver ¡nos vemos pronto!
                                </Typography>
                            </Box>
                        </Alert>
                    </Box>
                }
                {
                    cancelError &&
                    <Box className='pb-3'>
                        <Alert onClose={() => { }} variant="standard" className='mb-3' severity="warning" style={{ background: '#8D2D32', color: 'rgb(255, 255, 255)' }} hidden={true}>
                            <Box className='mb-1'>
                                <Typography className='font-variant-v3'>
                                    Ha ocurrido un error al cancelar tu membresía
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className={clsx('font-variant-v2', classes.description_alert)}>
                                    Por favor intenta de nuevo.
                                </Typography>
                            </Box>
                        </Alert>
                    </Box>
                }
                <Box className='mb-4'>
                    <Typography variant='h4' className={classes.titleLabel}>Mi membresía Talisis</Typography>
                    {userMembership && <Typography variant='caption' className={classes.memberSinceLabel}>Miembro desde {dateFormat(userMembership.created_at, "MMMM YYYY")}</Typography>}
                    {error && <Box className="mt-4"><Typography variant='body2' color='textSecondary'>{error}</Typography></Box>}
                </Box>
                <Box>
                    {
                        userMembership?.is_active ?
                            <Grid container spacing={breakpoint ? 2 : 3} alignItems='stretch'>
                                {
                                    (userMembership && !userMembership.is_custom_membership && <Grid item xs={12} md={6}>
                                        <MembershipPlanCard
                                            id={userMembership.slug}
                                            name={userMembership.name}
                                            elevation={1}
                                            selected
                                            setCancelMode={setCancelMode}
                                            user={user}
                                            customMembership={userMembership.is_custom_membership}
                                            customMembershipContent
                                            addOn={userAddOn}
                                            plan={{
                                                amount: userMembership.amount,
                                                termMonths: userMembership.term_months,
                                                nextBillingDate: userMembership.next_billing_date,
                                                paymentType: userMembership?.payment_type,
                                                pastDue: userMembership?.subscription_past_due,
                                                remainingDaysBeforeExpired: userMembership?.remaining_days_before_expired,
                                                isNextMembership: false,
                                                membershipId: userMembership.id,
                                                feeId: userMembership.fee_id,
                                                productId: userMembership.product_id
                                            }} />
                                    </Grid>) ||
                                    (userMembership && userMembership.is_custom_membership && <Grid item xs={12} md={6}>
                                        <CustomMembershipPlanCard
                                            id={userMembership.slug}
                                            name={userMembership.name}
                                            elevation={1}
                                            selected
                                            setCancelMode={setCancelMode}
                                            user={user}
                                            membershipCardContent={userMembership?.membership_card_content}
                                            plan={{
                                                amount: userMembership.amount,
                                                termMonths: userMembership.term_months,
                                                nextBillingDate: userMembership.next_billing_date,
                                                paymentType: userMembership?.payment_type,
                                                pastDue: userMembership?.subscription_past_due,
                                                remainingDaysBeforeExpired: userMembership?.remaining_days_before_expired,
                                                isNextMembership: false,
                                                membershipId: userMembership.id,
                                                feeId: userMembership.fee_id,
                                                productId: userMembership.product_id,
                                            }} />
                                    </Grid>)
                                }
                                {
                                    nextMembership && !userMembership.is_custom_membership && <Grid item xs={12} md={6}>
                                        <MembershipPlanCard
                                            id={nextMembership.slug}
                                            name={nextMembership.name}
                                            addOn={userAddOn}
                                            elevation={0}
                                            user={user}
                                            variant='outlined'
                                            plan={{
                                                amount: nextMembership.amount,
                                                termMonths: nextMembership.term_months,
                                                nextBillingDate: null,
                                                membershipId: nextMembership.id,
                                                feeId: nextMembership.fee_id,
                                                isNextMembership: true,
                                                amountPerMonth: nextMembership?.amount_per_month,
                                                cheapestPricePerMonth: nextMembership?.cheapestPricePerMonth,
                                                productId: nextMembership?.product_id
                                            }} />
                                    </Grid>
                                }
                            </Grid> :
                            <Grid container spacing={breakpoint ? 2 : 3} alignItems='stretch'>
                                {
                                    defaultMembership && <Grid item xs={12} md={6}>
                                        <MembershipPlanCard
                                            id={defaultMembership.slug}
                                            name={defaultMembership.name}
                                            addOn={userAddOn}
                                            elevation={1}
                                            selected
                                            user={user}
                                            setCancelMode={setCancelMode}
                                            plan={{
                                                amount: defaultMembership.amount,
                                                termMonths: defaultMembership.term_months,
                                                nextBillingDate: defaultMembership.next_billing_date,
                                                paymentType: defaultMembership?.payment_type,
                                                pastDue: defaultMembership?.subscription_past_due,
                                                remainingDaysBeforeExpired: defaultMembership?.remaining_days_before_expired,
                                                isNextMembership: false,
                                                membershipId: userMembership.id,
                                                feeId: userMembership.fee_id,
                                                expiredUserMembership: userMembership,
                                                productId: userMembership?.product_id
                                            }} />
                                    </Grid>
                                }
                                {
                                    nextMembership && !userMembership.is_custom_membership && <Grid item xs={12} md={6}>
                                        <MembershipPlanCard
                                            id={nextMembership.slug}
                                            name={nextMembership.name}
                                            addOn={userAddOn}
                                            elevation={0}
                                            user={user}
                                            variant='outlined'
                                            plan={{
                                                amount: nextMembership.amount,
                                                termMonths: nextMembership.term_months,
                                                nextBillingDate: null,
                                                membershipId: nextMembership.id,
                                                feeId: nextMembership.fee_id,
                                                isNextMembership: true,
                                                amountPerMonth: nextMembership?.amount_per_month,
                                                cheapestPricePerMonth: nextMembership?.cheapestPricePerMonth,
                                                productId: nextMembership?.product_id
                                            }} />
                                    </Grid>
                                }
                            </Grid>
                    }
                </Box>
            </Box>
            <Box hidden={!cancelMode}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                    <Grid item>
                        <Button variant='text' color='primary' onClick={() => { setCancelMode(false) }}>Regresar</Button>
                    </Grid>
                    <Grid item style={{ textAlign: 'center' }}>
                        <Typography variant='h4'>¿Deseas cancelar tu membresía {userMembership?.name}?</Typography>
                    </Grid>
                    <Grid item className='mt-5'>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button variant="outlined" color="secondary" onClick={handleCancelMembership}>Confirmar cancelación</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color="primary" onClick={() => { setCancelMode(false) }}>Continuar como miembro</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Loader>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(Membership);