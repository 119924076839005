import React from 'react'

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from "@material-ui/core/Hidden";

//styles
import { makeStyles } from '@material-ui/core/styles';
import { AcademyListIcon } from './AcademyListIcon';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import clsx from 'clsx';
import academies from '../../../shared/constants/academies';
import { Typography } from '@material-ui/core';
import CardAcademia from '../../../shared/components/Academies/CardAcademia';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme) => ({
    boxRoot:{
        display: 'flex',
        maxWidth: '496px',
        flexWrap: 'wrap',
        gap: '16px',
        marginBottom: '56px',
        justifyContent: 'center',
    },
    disabled:{
        maxWidth: 100,
        paddingTop: 0,
        margin: 'auto',
        '& svg':{
            opacity: '.5',            
            '& rect':{
                // fill: '#1c1f244a'
            }
        } 
    },
    button:{
        padding: '16px 59px',
        marginBottom: 25
    },
    logo_academia:{
        paddingTop: 0,
        maxWidth: 100,
    },
    cardAcademia:{
        '& .card-content':{
            width: 108,
            height: 108,
            padding: '8px 10px',
        }
    },
    cardAcademiaSelect:{
        '& .card-content':{
            width: 108,
            height: 108,
            padding: '8px 10px',
            backgroundColor: theme.palette.colors.electricBlue[300]
        }
    },
}));

const AcademyList = (props) => {
    const classes = useStyles();

    const {items, handleChange, onSetOnCard} = props;
    const [academySelect, setAcademySelect] = React.useState([]);
    const [type, setType] = React.useState(1);
    const [open, setOpen] = React.useState(false);

    const handleClick = (item) => {
        let { id, name } = item;
        let isExist = false
        let newSelect = academySelect;
        let filter = academySelect.filter(academy => academy == id)
        if (filter.length > 0){
            newSelect = academySelect.filter(academy => academy !== id)
            setAcademySelect(newSelect)
            isExist = true;
            handleChange(id, isExist, 1);
            //setType(1);
        }else{
            newSelect.push(id);
            setAcademySelect(newSelect);
            handleChange(id, isExist, 1);
            //setType(2);
        }

        handleSetActive(newSelect);
      };

    const handleSetActive = (academyList) => {
        setOpen(academyList.length === 3);
    }

    return (
        <Box mt="32px">
            <Box className={classes.boxRoot}>
            {
                items.map((el) => {
                    const disabled = academySelect.length === 3 && !academySelect.includes(el.id);
                    const itemAcademy = academies.find(academy => academy.id === el.id);
                    const selected = academySelect.includes(el.id);
                    return (
                    itemAcademy !== undefined &&
                        <CardAcademia
                            id={itemAcademy.id} 
                            key={uuid()} 
                            src={itemAcademy.icon} 
                            title={itemAcademy.name}
                            disabled={disabled}
                            className={selected ? classes.cardAcademiaSelect : classes.cardAcademia}
                            handleClick={() => handleClick(el)}
                            variantCard=''
                        />
                    )
                })
            }
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
                <Hidden mdUp implementation="css" style={{flex: 1}}>
                    <Button onClick={() => onSetOnCard(0)} startIcon={<ChevronLeftIcon />} className={classes.button}>
                        Regresar
                    </Button>
                </Hidden>
                <Button variant='contained' color="primary" className={classes.button} disabled={!open} onClick={() => onSetOnCard(2)}>
                Siguiente
                </Button>
            </Box>
        </Box>
    )
}

export default AcademyList;
