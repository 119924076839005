import React from 'react'
import clsx from 'clsx';
import { Icon } from '@material-ui/core';

//@material-ui/core
import Box from '@material-ui/core/Box'
import ClipboardButton from '../../../design-system/components/ClipboardButton/ClipboardButton';

const ReferralCouponCodeButton = (props) => {
    const {classes, couponCode, isResponsive}=props;
    return <ClipboardButton textToCopy={couponCode} variant='contained' size='large' fullWidth={isResponsive} color="" className={clsx('bg-gray-300 text-fill-stroke-500',classes.referralsCopyCodeButton)}>
                <Box className='mr-3'>{couponCode}</Box> <Icon className='ri-file-copy-line font-size-18 ml-3 text-violet-300' />
            </ClipboardButton>

};

export default ReferralCouponCodeButton;