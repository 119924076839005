import React from 'react'

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// shared components
import Select from '../../../design-system/components/Form/Select/Select';
import InterestList from '../components/interestList';

import { makeStyles, useTheme } from '@material-ui/core/styles';

export const OnCardInterests = ({onSetOnCard, skillHierarchy, interestSkill = [1, 2, 3], handleChange, eventData, eventTracker}) => {
    //Style
    const theme = useTheme();
    const useStyles = makeStyles({
        card: {
            backgroundColor: theme.palette.colors.grey[400],
        },
        cardcontent:{
            padding: '30px 60px !important'
        },
        title: {
            color: theme.palette.colors.violet[300],
            cursor: 'default',
            fontSize: '18px',
            fontWeight: 'normal',
        },
        large: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
    });
    const classes = useStyles();

    const [interest, setinterest] = React.useState([]);


    const skillsFilter = (array, filters) => {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
    }

    React.useEffect(() => {
        eventTracker('s_ob_temas',eventData, ['mixpanel']);
        let arrI = []
        interestSkill.map((is) => (
            arrI.push(is.id)
        ))
        let filters = {
            id: arrI
          };
        let resultFilter = skillsFilter(skillHierarchy, filters)
        setinterest(resultFilter);
    }, []);
    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt="40px">
            <Typography variant="h3" color="primary" className="mb-4">
            Queremos conocerte mejor
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary" className="mb-3" style={{maxWidth: 348}}>
            Esta información nos ayudará a refinar aún mejor tu perfil profesional. 
            </Typography>
            <Typography align="center" className="font-variant-v4">
                Selecciona al menos un tema por área de interés (máximo 10 en total)
            </Typography>
            <InterestList 
                interests={interest}
                handleChange={handleChange}
                //onSetActive={handleSetActive}
                onSetOnCard={onSetOnCard}
            />
        </Box>
    )
}

export default OnCardInterests;
