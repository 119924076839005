import React from 'react';

// @mui core
import { Button, Grid, Hidden, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TEST_VOC_ONLINE_UERRE, TEST_VOC_ONLINE_UNID } from '../../../routes/paths';

import clsx from 'clsx';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';

const config = {
    'unid':{
        text: 'Descubre tu programa académico ideal',
        textBttn: 'Prueba vocacional',
        link:TEST_VOC_ONLINE_UNID
    },
    'u-erre':{
        text: 'Descubre tu programa académico ideal realizando la:',
        textBttn: 'Prueba vocacional',
        link: TEST_VOC_ONLINE_UERRE
    },
    'harmonhall':{
        text: '¡Totalmente gratis! Obtén tus resultados inmediatos',
        textBttn: 'Conoce tu nivel de inglés',
        link: 'https://conocetuniveldeingles.harmonhall.com/'
    },
}

const useStyles = makeStyles((theme) => ({
    root:{
        paddingTop: 32,
        [theme.breakpoints.down('xs')]:{
            padding: 0
        },
    },
    boxContent:{
        height: 174,
        [theme.breakpoints.down('xs')]:{
            height: 594,
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center'
        },
        borderRadius: 8
    },
    'u-erre':{
        '& .box-content':{
            background: 'radial-gradient(103.68% 142.92% at -30.6% 116.87%, #D64DD0 0%, #7344FF 100%)',
        },
        '& .text':{
            color: theme.palette.white
        }
    },
    'unid':{
        '& .box-content':{
            backgroundImage: 'url(/images/brands/bg-banner-unid.png)',
            [theme.breakpoints.down('xs')] : {
                backgroundImage: 'url(/images/brands/bg-banner-responsive-unid.png)',
                backgroundSize: 'cover',
            },
        },
        '& .text':{
            color: theme.palette.colors.ou.unidBlue
        }
    },
    'harmonhall':{
        '& .box-content':{
            background: '#FFFF00'
        },
        '& .text':{
            color: theme.palette.colors.ou.hhBlack
        }
    },
    imgBannerPt3: {
    },
    'textu-erre':{
        width: 255,
    },
    'textunid':{
        width: 239
    },
    'textharmonhall':{
        width: 255
    },
    imgBannerPt1:{
        [theme.breakpoints.only('sm')]:{
            width: '65%'
        },
    }
}));

const BrandBanner = (props) => {
    const { brand} = props;
    const classes = useStyles();

    // @images
    let BANNER_PT1 = `https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/${brand}/banner-part-1.svg`;
    let BANNER_PT2 = `https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/${brand}/banner-part-2.svg`;
    let BANNER_PT3 = `https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/${brand}/banner-part-3.svg`;

    const handleClick = () => window.open(config[brand].link, '_blank');

    return (
        <ResponsiveContainer className={classes.root} disableGuttersXs>
            <Box className={classes[brand]}>
                <Box className={clsx(classes.boxContent, "box-content")}>                
                    <Hidden key="desktop" xsDown>
                        <Grid container alignItems="center" direction="row">
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="center">
                                    <img src={BANNER_PT1} className={clsx(classes.imgBannerPt1, `classes.text${brand}`)} />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="center">
                                    <img src={BANNER_PT2} className={classes.imgBannerPt2} />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <img src={BANNER_PT3} className={classes.imgBannerPt3} />
                                    <Box mt="16px" display="flex" justifyContent="center">
                                        <Button variant="contained" color="primary" onClick={() => {
                                            if (brand === 'unid') {
                                                window.open(TEST_VOC_ONLINE_UNID, '_blank');
                                                eventData['marcaCurso'] = 'unid';
                                                eventTracker('cta_vocacional', eventData, ['mixpanel']);
                                            }
                                            else if (brand === 'u-erre') {
                                                window.open(TEST_VOC_ONLINE_UERRE, '_blank');
                                                eventData['marcaCurso'] = 'uerre';
                                                eventTracker('cta_vocacional', eventData, ['mixpanel']);
                                            }
                                            else if (brand === 'harmonhall') { window.open('https://conocetuniveldeingles.harmonhall.com/', '_blank'); }
                                        }}>
                                            {brand === 'unid' && 'Prueba vocacional'}
                                            {brand === 'u-erre' && 'Prueba vocacional'}
                                            {brand === 'harmonhall' && 'Conoce tu nivel de inglés'}
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden key="mobile" smUp>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" width={1}>
                            <img src={`/images/brands/avatar-${brand}.svg`} style={{maxHeight:300}}/>
                            <Box>
                                <img id="img_banner_pt2" src={`/images/brands/label-${brand}.svg`} className="offset-mb-4" />
                                <Box>
                                    <Box maxWidth={251} margin="auto" marginBottom={3}>
                                        <Typography variant="h4" className="text">
                                            {config[brand].text}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box padding={3}>
                                    <Button variant="contained" color="primary" onClick={handleClick} fullWidth size='large'>
                                        {config[brand].textBttn}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Hidden>
                </Box>
            </Box>
        </ResponsiveContainer>
            // </Box>
        //</Box>
    )
};

export default BrandBanner;