import * as apiTypes from '../types/apiTypes'
import * as endpoints from '../../services/endpoints'
import {updateMenuItem} from './menuActions'
import { log } from '../../shared/utils/console.js'

export const getPendingRequests = (personId, showLoading = false) => async dispatch => {
    if(showLoading){
        dispatch({
            type: apiTypes.LOADING_REQUESTS
        });
    }
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_X_API_KEY
        },
    };

    const response = await fetch(`${endpoints.REQUESTS_BY_PERSON}/${personId}/pending-requests`, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;

        log("Error: ", error.message);
        dispatch({
          type: apiTypes.ERROR_REQUESTS,
          payload: error
        });
        return Promise.reject(error);
    }

    const notification = data.length ? data.length.toString() : "";
    const notifications = [
            {id: "25", notification},
            {id: "25.1.2", notification}
    ]

    dispatch(updateMenuItem(notifications));

    dispatch({
        type: apiTypes.APPLY_REQUESTS_BY_PERSON,
        payload: data
    });
}