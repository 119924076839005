export default {
  badge:{
    height: 12,
    minWidth: 12,
    padding: '0px 1px',
    fontSize: 9,
  },
  anchorOriginTopRightRectangle:{
    transform: 'scale(1) translate(2%, -25%)'
  }
};
