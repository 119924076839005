import React, {useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { CardContent, Hidden } from '@material-ui/core';
import getImageFromAssets from '../../utils/getImageFromAssets';


const useStyles = makeStyles((theme) => ({
    image:{
        height: 88,
        width: 101
    },
    cardContent:{
        paddingLeft: 24,
        paddingRight: 24,
        '& .box-wrapper':{
            display: 'flex',
            gridGap: 24
        }
    },
    [theme.breakpoints.down('xs')]:{
        cardContent:{
            padding: '10px 8px 16px 8px',
            '& .box-wrapper':{
                gridGap: 0
            },
            '& .button-close':{
                alignItems: 'start'
            }
        },
    }
}));

export default function InformativeBanner({image, title = '', text = ''}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} id="informative-banner-root">
        <Collapse in={open}>
            <Card elevation={7}>
                <CardContent className={classes.cardContent}>
                    <Box className='box-wrapper'>
                        <Hidden smDown>
                            <Box>
                                {image && <img src={getImageFromAssets(image)} className={classes.image}/>}
                            </Box>
                        </Hidden>
                        <Box flex={1} gridGap={6} display='flex' flexDirection='column'>
                            <Typography variant='subtitle1' className='text-orange-300 h5-xs'>{title}</Typography>
                            <Typography variant='body1' className='text-white body-text-xsamll-xs'>{text}</Typography>
                        </Box>
                        <Box display='flex' className='button-close'>
                            <IconButton className='p-0' aria-label="close" onClick={handleClose} disableRipple>
                                <Icon className='ri-close-line text-dark-black-200 font-size-24' />
                            </IconButton>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Collapse>
    </div>
  );
}
