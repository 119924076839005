import React from 'react';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

// core components
import SectionListItem from './SectionListItem/SectionListItem';
import SectionAddItem from './SectionAddItem/SectionAddItem';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

const SectionList = (props) => {
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const { fieldName, nestedProp, parent, items, nestedLevel, maxNestingLevel } = props;

    React.useEffect(() => {
        if (items) {
            setData(items);
        }
    }, [items]);

    const handleUpdate = (item) => {
        const elements = [...data];

        if (!item.id) {
            const minId = elements.reduce((a, b) => Math.min(a, b.id), 0);

            item.id = minId - 1;
            item._untracked = true;
            
            elements.push(item);
        } else {
            if (item.id > 0) {
                item._modified = true;
            }

            const itemIndex = elements.findIndex(el => el.id === item.id);
            elements[itemIndex] = item;
        };

        if (parent) {
            if (props.onUpdate) {
                props.onUpdate({ ...parent, [nestedProp]: elements });
            }
        } else {
            if (props.onChange) {
                props.onChange(props.id, elements);
            } else {
                setData(elements);
            }
        }
    };

    const handleDelete = (id) => {
        const elements = [...data];
        const itemIndex = elements.findIndex(el => el.id === id);

        if (id > 0) {
            elements[itemIndex]._modified = undefined;
            elements[itemIndex]._deleted = true;
        } else {
            elements.splice(itemIndex, 1);
        };

        if (parent) {
            if (props.onUpdate) {
                props.onUpdate({ ...parent, [nestedProp]: elements });
            }
        } else {
            if (props.onChange) {
                props.onChange(props.id, elements);
            } else {
                setData(elements);
            }
        }
    };

    return (
        <div className={classes.root}>
            <List dense disablePadding>
                {data.map(el => {
                    if (el._deleted) {
                        return null;
                    }

                    let nestedChildren = null;

                    if (nestedProp && nestedLevel < (maxNestingLevel - 1)) {
                        nestedChildren = (
                            <SectionList
                                id={nestedProp}
                                fieldName={fieldName}
                                nestedProp={nestedProp}
                                maxNestingLevel={maxNestingLevel}
                                parent={el}
                                items={el[nestedProp] || []}
                                nestedLevel={nestedLevel + 1}
                                onUpdate={handleUpdate}
                            />
                        );
                    }

                    return (
                        <SectionListItem
                            key={el.id}
                            item={el}
                            fieldName={fieldName}
                            nestedChildren={nestedChildren}
                            onUpdate={handleUpdate}
                            onDelete={handleDelete}
                        />
                    );
                })}
            </List>
            <SectionAddItem fieldName={fieldName} onUpdate={handleUpdate} />
        </div>
    );
};

SectionList.defaultProps = {
    items: [],
    nestedLevel: 0,
    maxNestingLevel: 99,
};

export default SectionList;