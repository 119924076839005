import React, { useState, useEffect} from 'react'
import update from 'react-addons-update';
import { withRouter, useHistory } from 'react-router-dom';

// Share component
import Loader from '../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import Toast from '../../../shared/components/Toast/Toast';
import ConfirmDialog from '../../../shared/components/ConfirmDialog/ConfirmDialog';
import TextFieldDefault from '../../../shared/components/Form/TextFielDefault';
import { log } from '../../../shared/utils/console.js'
import TablePermissionByRole from './TablePermissionByRole/TablePermissionByRole'
// Services
import {ENVIRONMENT, RoleService} from '@sdk-point/talisis';
// Material UI
import {Card, Grid, CardContent, Typography, Button, Box} from '@material-ui/core';
// JS
import RoleObject from './RoleObject'; 

const RoleFormF = (props) => {
  const {match} = props;
  const paramsIdRole = (match.params.id) ? match.params.id:false;
  const [state, setState] = useState([]);
  const [roleId, setRoleId] =  useState(0)
  const [isLoaded, setIsLoaded] = useState(false);
  const [openModal, setOpenModal] =  useState({open:false, text:''});
  const [toast, setToast] = useState({ toastProps: {message: '',open: false}})
  const history = useHistory();
  const roleService = new RoleService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

  const items = [
    { to: '/Home', label: 'Administrador', title:'' },
    { to:'/roles/management', label: 'Roles', title:''},
    {label:(paramsIdRole) ? 'Editar ':'Nuevo', title:''} 
  ];

  useEffect(()=>{
      getByid(); 
  },[])
  
  const getByid = async () => {
     try {
         setIsLoaded(true)
         const responseRole = (paramsIdRole)? await roleService.getById(paramsIdRole):false;
         const dataRole = formInput(responseRole);
         setState(dataRole);
     } catch (error) {
        log('Error getById',error);
     } finally {
       setIsLoaded(false);
     }
  }
 
  const formInput = (responseRole) => {
   let data = RoleObject().map(item=> {
       if (responseRole) {
         item.defaultValue = responseRole[item.name]
       } else {
         if(item.name==='id'){
           item.visibility = 'none';
         }
       }
      return item;
    })
    return data;
  }

  const handleonBlur =(e) =>{
    const {name} = e.target;
    const indexObj = state.findIndex(obj=> obj.name===name);
    setState(update(state,{[indexObj]:{autoFocus:{$set:false}}}))
  }

  const handleChange = (e) =>{
    const {name, value} = e.target; 
    const indexObj = state.findIndex(obj=> obj.name===name);
    setState(update(state,{[indexObj]:{error:{$set:false},
                                       defaultValue:{$set:value},
                                       helperText:{$set:''},
                                       autoFocus:{$set:true}}}))
  }

  const ListInput = () => {
    const Item = state.map((item,index)=> {
            item.onChange = handleChange;
            item.onBlur = handleonBlur;
            return (
              <Grid item xs={12} sm={12} md={12} key={index}>
                <TextFieldDefault {...item}/> 
              </Grid>
           )
        
    })
    return Item;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    let bodyObject = {};
    let isError=false;
      for (let i=0; i<state.length && !isError; i++) {
          const name = state[i].name;
          const value = state[i].defaultValue;
          if(state[i].required) {
           isError = (value) ? false :true;
            if(isError) {
               const indexObj = state.findIndex(obj=> obj.name===name);
              setState(update(state,{[indexObj]:{error:{$set:true},
                                                 helperText:{$set:'Campo requerido'}}}))
            }
         }
          bodyObject[name]= value;
      }
    if(isError) {
      setToast({toastProps:{severity: "error", open:true, message:'Ingrese campos requeridos'}})
    } else {
      try {
          setIsLoaded(true);
          const response =(paramsIdRole) ? await roleService.update({...bodyObject,id:paramsIdRole}): await roleService.save(bodyObject); 
          setToast({toastProps:{open:true, message:`Se ${(paramsIdRole) ? 'actualizado' :'creo'} satisfactoriamente`}})
          if(!paramsIdRole) {
            setRoleId(response.id);
            setOpenModal({open:true, text:'¿Requieres asignar un permiso al rol?'});
          }
      } catch (error) {
         log('Error', error);
      } finally {
         setIsLoaded(false)
      }
   }
  }

  const handleFinishedToast = () => {  
    setToast({toastProps:{open:false, message:''}})
  }
  
  const handleComfirModal = (E) => {
    history.push(`/roles/${roleId}/permisos`)
  }
 
  const handleOnCloseModal = () => {
    setOpenModal({open:false,text:''});
  }

  const handleback = () => props.history.push(`/roles/management`);


  return(
        <>
          <Breadcrumb items={items} />
            <Grid container spacing={3}>
                <Grid container item xs={12} md={10}>
                    <Loader loading={isLoaded}>
                    <Card>
                        <CardContent>
                            <Typography variant="subtitle2" className="mb-4">Datos del Rol</Typography>
                             <ListInput/>
                             { paramsIdRole && !isLoaded && 
                                <TablePermissionByRole idRole={paramsIdRole}/>
                             }
                        </CardContent>    
                    </Card>
                    </Loader>
                </Grid>
                
                
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Box display="flex" justifyContent="flex-end" width="100%" >
                      <Button color="secondary" variant="contained" onClick={handleback}>Cancelar</Button>
                    </Box>
                  </Grid>
                  <Grid item xs={1}> 
                    <Box display="flex" justifyContent="flex-end" width="100%" >
                      <Button color="primary" variant="contained" onClick={handleSubmit}>Guardar</Button>
                    </Box>
                  </Grid>
                </Grid>
            </Grid>            
          <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={3000} />
          <ConfirmDialog open={openModal.open} onConfirm={handleComfirModal} onClose={handleOnCloseModal} title='Confirmar' text={openModal.text} />
        </>
    )
}
 
const RoleForm = withRouter(RoleFormF);
export default RoleForm;