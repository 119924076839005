import React from 'react';

// @material-ui/core
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import WcOutlinedIcon from '@material-ui/icons/WcOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';

// shared
import Loader from '../../../../shared/components/Loader/LoaderContent';
import PersonQR from '../../PersonQR/PersonQR';

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: "60px"
    },
    subtitle: {
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    text: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    root: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        fontSize: "16px",
        backgroundColor: "#00C1DE",
        color: "#ffffff",
        marginRight: theme.spacing(2)
    },
}));

const GetAvatar = ({ step }) => {
    const classes = useStyles();
    return <Avatar className={classes.avatar} variant="circle">{step}</Avatar>;
}

const RegisterResult = ({ loading, result, onClick }) => {
    const classes = useStyles();

    return <Loader loading={loading}>
        <Card>
            <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center" className="p-2">
                    {result.authorized ?
                        <React.Fragment>
                            <CheckCircleOutlineIcon color="primary" className={classes.icon} />
                            <Typography variant="subtitle1" className={classes.subtitle}>{result.message}</Typography>
                            <Typography variant="h5" className={classes.text}>A continuación te presentamos los siguientes pasos:</Typography>
                            <div className={classes.root}>
                                <List>
                                    <ListItem>
                                        <GetAvatar step={1} />
                                        <ListItemText primary="Usa tu código QR para acceder al campus." />
                                    </ListItem>
                                    <ListItem>
                                        <GetAvatar step={2} />
                                        <ListItemText primary="Tu código QR es único y nunca cambiará." />
                                    </ListItem>
                                    <ListItem>
                                        <GetAvatar step={3} />
                                        <ListItemText primary="Descarga aquí tu código QR, o lo puedes obtener ingresando a tu Perfil." />
                                    </ListItem>
                                    <ListItem>
                                        <GetAvatar step={4} />
                                        <ListItemText primary="Recuerda realizar tu siguiente registro a partir de las 12:00 p. m. del día previo a tu acceso al campus." />
                                    </ListItem>
                                    <ListItem>
                                        <Box width="100%" display="flex" justifyContent="center">
                                            <PersonQR width="150px" />
                                        </Box>
                                    </ListItem>
                                </List>
                            </div>
                            <Typography variant="h5" className={classes.text}>Te pedimos estar atento a los signos de advertencia del COVID-19 y realizar este registro cada vez que sea necesario acudir presencialmente al campus.</Typography>
                        </React.Fragment> :
                        <React.Fragment>
                            <ErrorOutlineOutlinedIcon color="secondary" className={classes.icon} />
                            <Typography variant="subtitle1" className={classes.subtitle}>{result.message}</Typography>
                            <Typography variant="h5" className={classes.text}>El registro no fue autorizado, estas pueden ser las posibles razones:</Typography>
                            <div className={classes.root}>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <PeopleAltOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Perteneces a alguno de los grupo de riesgo de la COVID-19." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <WcOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Crees que estuviste en contacto con alguna persona portadora del virus." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocalHospitalOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Sufres sintomas leves de la enfermedad." />
                                    </ListItem>
                                </List>
                            </div>
                            <Typography variant="h5">El Campus cuenta con un protocolo de seguridad sanitaria y se podria no otorgar tu acceso a las instalaciones.</Typography>
                            <Typography className={classes.text} variant="h5">Te recomendamos seguir las medidas sanitarias y estar atento a los signos de advertencia del COVID-19 durante los próximos días.</Typography>
                        </React.Fragment>
                    }
                    <Button color="primary" variant="contained" onClick={onClick}>Volver a inicio</Button>
                </Box>
            </CardContent>
        </Card>
    </Loader>
}

export default RegisterResult;