import React, { useEffect, useState } from 'react'

import { Grid, Typography } from '@material-ui/core'

const DynamicList = ({ listArray = [], customColor = "" }) => {

    const [dynamicListInfo, setDynamicListInfo] = useState([])

    useEffect(() => {
      if(listArray.length === 0 ) return;


      setDynamicListInfo(listArray);

    }, [listArray]);

    return (
        <Grid item xs={12} className="mt-1">
            <ul>
                <Grid container direction="column" wrap='wrap' style={{ maxHeight: "500px" }}>
                {
                    (dynamicListInfo.length > 0) &&
                    dynamicListInfo.map((listItem, index) =>
                        <Grid item key={index} className="mt-2">
                            <li>
                                <Typography
                                    variant='body1' 
                                    style={{ color: (customColor !== "") ? customColor : ""}} 
                                    dangerouslySetInnerHTML={{ __html: listItem?.main ? listItem?.main : listItem }}
                                >
                                </Typography>
                                {
                                    ( listItem?.subList?.length > 0 ) &&
                                    <ul className="mt-2">
                                        <Grid container direction="column" wrap='wrap' style={{ maxHeight: "500px" }}>
                                            {
                                                listItem?.subList.map((sublistItem, subIndex) => 
                                                    <Grid item key={subIndex}>
                                                        <li className="mt-2">
                                                            <Typography variant='caption' style={{ color: (customColor !== "") ? customColor : ""}}>
                                                                { sublistItem }
                                                            </Typography>
                                                        </li>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </ul>
                                }
                            </li>
                        </Grid>
                    )
                }
                </Grid>
            </ul>
        </Grid>
    )
}

export default DynamicList
