import React from 'react';
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

// styles
import useStyles from '../../../../../assets/styles/jss/pages/market/PaymentConfirmation';
// assets
import TalisisLogo_new from '../../../../../assets/images/logos/logo-talisis-rojo.png';

// components
import StudentInfo from './components/StudentInfo';
import PaymentMethod from './components/PaymentMethod';
import OrderSummary from './components/OrderSummary';

// helper
import { getDefaultProductOfType, validateOrderDetails } from '../../paymentConfirmationHelper';
import { PRODUCT_TYPES, PAYMENT_METHODS } from '@sdk-point/talisis';
// shared
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';
import { getMethodPayment, getPeriodMembership } from '../../../../../shared/utils/checkoutHelpers';

import dateFormat from '../../../../../design-system/utils/dateFormat';

const OrderDetail = ({ order, orderDetails, orderPayments, personInfo }) => {
    const classes = useStyles();
    const productTypeId = validateOrderDetails(orderDetails);
    const product = getDefaultProductOfType(orderDetails, productTypeId);

    const printOrder = (e) => {
        e.preventDefault();
        eventPrintTicket();
        downloadReferences();
    };

    const getMonthlyPayment = () => {
        let membershipMonths = 1;

        if (product?.product_sku?.includes("-1M")) membershipMonths = 1;
        if (product?.product_sku?.includes("-3M")) membershipMonths = 3;
        if (product?.product_sku?.includes("-6M")) membershipMonths = 6;
        if (product?.product_sku?.includes("-12M")) membershipMonths = 12;

        const monthlyPayment = (order?.subtotal / membershipMonths);

        return monthlyPayment;
    }
    const downloadReferences = () => {
        var printView = window.open('', 'PRINT', 'height=400,width=600');
        printView.document.write('<html><head><title>Detalle de la compra</title>');
        printView.document.write(`</head><body style="font-family: 'Source Sans Pro', sans-serif">`);
        printView.document.write(`<div style="width: 100%; text-align:center;"><img src="${TalisisLogo_new}" style="width: 200px;"></div>`);
        printView.document.write('<h3>Datos de compra</h3>');
        printView.document.write(`<p><table style="border: none; width: 100%;">`);

        // datos personales
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Folio: <b>${order?.id}</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Fecha: <b>${dateFormat(order?.created_at, "DD-MM-YYYY hh:mm:ss A")}</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Nombre: <b>${order?.person_info?.first_name} ${order?.person_info?.last_name}</b></td></tr>`);
        
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Producto: <b>${order?.order_details[0].product_name}</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Periodo de pago: <b>${getPeriodMembership(order?.order_details[0].product_sku)}</b></td></tr>`);
    
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Subtotal: <b>$${order?.subtotal ? order?.subtotal.toFixed(2) : 0.00} MXN</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Comisión: <b>$${order?.commission ? order?.commission.toFixed(2) : 0.00} MXN</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">IVA de comisión: <b>$${order?.tax_amount ? order?.tax_amount.toFixed(2) : 0.00} MXN</b></td></tr>`);
        printView.document.write(`<tr><td style="padding-bottom: 16px;">Total: <b>$${order?.total ? order?.total.toFixed(2) : 0.00} MXN</b></td></tr>`);

        // referencias de pago
        if (order.total !== 0) {
            let payment = order.order_payments[0];
            let metadata = JSON.parse(payment.metadata);
            if (payment?.payment_method_id === PAYMENT_METHODS.CARD) {
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Método de pago: <b>Tarjeta</b></td></tr>`);
            }
            else if (payment?.payment_method_id === PAYMENT_METHODS.SPEI) {
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Método de pago: <b>SPEI</b></td></tr>`);
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Cuenta CLABE: <b>${metadata?.payment_method?.clabe}</b></td></tr>`);
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Banco: <b>${metadata?.payment_method?.bank}</b></td></tr>`);
            }
            else if (payment?.payment_method_id=== PAYMENT_METHODS.CASH) {
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Método de pago: <b>Efectivo (OXXO Pay)</b></td></tr>`);
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Referencia de pago: <b>${metadata?.payment_method?.reference}</b></td></tr>`);
            }
        } else {
            let payment = order.order_payments[0];
            if (payment?.payment_method_id === PAYMENT_METHODS.FREE) {
                printView.document.write(`<tr><td style="padding-bottom: 16px;">Método de pago: <b>Cupon 100% de Descuento</b></td></tr>`);
            } 
        }

        printView.document.write('</body></html>');
        printView.document.close(); // necessary for IE >= 10
        printView.focus(); // necessary for IE >= 10*/

        setTimeout(() => {
            printView.print();
            printView.close();
        }, 1000);

        return true;
    };

    const eventPrintTicket = () => {
        let payment = order.order_payments[0];
        let metadata = JSON.parse(payment.metadata);
        const {conekta} = metadata;
        const paymentMetadata = conekta? {card_type:conekta?.payment_method.type}: {};
        let paymentMethod = getMethodPayment(payment.payment_method_id, paymentMetadata);
        const eventData = {};
        eventData['metodoPago'] = paymentMethod;
        eventTracker('imprimir_ficha', eventData, ['mixpanel', 'hubspot', 'google']);
    }

    return (
        <Box>
            <Typography variant="h3" className={clsx("mb-2", "text-white")}>Detalle de tu compra</Typography>
            <Box display="flex" flexDirection="row" mb="8px">
                <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>
                    {product?.product_type_name}:&nbsp;
                    <Typography variant="h4" className={clsx("text-gray-400")} component={Box} display="inline">{`${product?.product_name}`}</Typography>
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb="24px">
                {product?.product_type_id === PRODUCT_TYPES.MEMBRESIA ?
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>Pago mensual: &nbsp;</Typography>
                        <Typography variant="h4" className={clsx("text-gray-400")}><CurrencyFormat variant="h5" className="text-gray-400" value={getMonthlyPayment()} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="" suffix=' MXN' /></Typography>
                        <Typography variant="body2" className={clsx("text-gray-400", "ml-2", classes.includeVat)}>(IVA incluido)</Typography>
                    </Box> :
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>Pago: &nbsp;</Typography>
                        <Typography variant="h4" className={clsx("text-gray-400")}><CurrencyFormat variant="h5" className="text-gray-400" value={order?.total} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="" suffix=' MXN' /></Typography>
                        <Typography variant="body2" className={clsx("text-gray-400", "ml-2", classes.includeVat)}>(IVA incluido)</Typography>
                    </Box>
                }
            </Box>
            <Box mb="16px">
                <StudentInfo personInfo={personInfo} />
            </Box>
            <Box mb="16px">
                <PaymentMethod order={order} orderPayments={orderPayments} />
            </Box>
            <Box mb="16px">
                <OrderSummary order={order} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" p="8px">
                <Typography variant="body2" className='text-white semi-bold p-2 mb-2'>Descarga o imprime tu recibo</Typography>
                <Box>
                    <IconButton size='medium' className={classes.buttonPrint} onClick={event => printOrder(event)}>
                        <Icon fontSize='small' className="ri-printer-cloud-line text-white"></Icon>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default OrderDetail;