import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Card, CardContent, CardHeader, Divider, IconButton, Button } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SSOPasswordUpdate from './SSOPasswordUpdate/SSOPasswordUpdate';
import Toast from '../../../../../shared/components/Toast/Toast';
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent';
import { log } from '../../../../../shared/utils/console.js'
import clsx from 'clsx';
import { getLogoCard, LOGO_SOURCE } from '../../../../../shared/utils/imageSource';
//SDK
import { MIXPANEL_EVENT, SingupService } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';

const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        width: '100%',
        margin: '0px auto',
        height: '100vh'
    },
    card:{
        [theme.breakpoints.down('xs')]:{
            marginTop: 60,
        },
        backgroundColor: `${theme.palette.colors.fillStroke[400]} !important`,
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        marginTop: 56
    },
    cardHeader: {
        padding: "16px 20px 0 0"
    },
    closeIcon: {
        fontSize: '24px',
        color: theme.palette.colors.darkBlack[200]
    },
    backIconButton: {
        fontSize: 14,
        color: theme.palette.colors.gray[100]
    },
    backIcon: {
        fontSize: '24px',
        color: theme.palette.colors.gray[100]
    },
    closeIconButton: {
        padding: 0,
        float: 'right'
    },
    cardContent:{
        padding: '16px 80px !important',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]:{
            padding: '15px !important',
        }
    },
    boxSplitLogin:{
        marginTop: 40
    },
    talisisLogo: {
        width: 120
    },
    resetPasswordText: {
        fontSize: 14, 
        color: theme.palette.colors.gray[400],
        fontWeight: 400
    },
    heacerActions: {
        width: '100%'
    },
    helpLink: {
        fontSize: 14,
        cursor: 'pointer'
    }
}));

const PasswordSSO = (props) => {
    let history = useHistory();
    const location = useLocation();
    const { match: { params: { token } } } = props;
    log(token)
    const classes = useStyles();
    const [toastProps, setToastProps] = React.useState({
        message: 'Por el momento no cuentas con acceso a la plataforma.',
        severity: 'error'
    });
    const [resetPassword, setResetPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [passwordSucces, setPasswordSucces] = React.useState(false);

    const handlePasswordError = error => {
        setToastProps({ ...toastProps, open: true, message: error ? error : 'Por el momento no cuentas con acceso a la plataforma.' })
    }

    const handlePasswordSuccess = success => {
        setPasswordSucces(success)
    }

    const hanldeFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    }

    const handleLoader = loader => {
        setLoading(loader);
    }

    const sleep = time => {
        return new Promise(resolve => setTimeout(resolve, time))
    }

    const handleValidateToken = async () => {
        if (location.search.includes('reset')) {
            setResetPassword(true)
        } else {
            setResetPassword(false)
        }
        try {
            await SingupServiceSDK.validateToken(token, handleLoader, handlePasswordError).then(async tokenIsValid => {
                setLoading(true)
                if (tokenIsValid) {
                    //token valido
                    await SingupServiceSDK.updatePasswordSSO(token).then(respData => {
                        log(respData)
                        setLoading(false);
                    })
                } else {
                    handlePasswordError('El enlace ha coducado o es incorrecto')
                    sleep(5000).then(r => {
                        history.push('/login')
                    })
                }
            })
                .catch()
                .finally();
        }
        catch (error) {

        }
    }

    React.useEffect(() => {
        handleValidateToken();
    }, []);

    const contactTechnicalSupport = () => {
        const talisisHelpDeskButton = document.getElementById('talisis_helpdesk_button');
        if (talisisHelpDeskButton) {
            talisisHelpDeskButton.click();
        }
    }
    return (
        <Box>
            <Box className={classes.root}>
                <Card elevation={0} className={`${classes.card}`}>
                    <LoaderContent loading={loading}>
                        <CardHeader className={classes.cardHeader} 
                            action={
                                <>
                                    <IconButton className={classes.closeIconButton} component={RouterLink} to={'/'}>
                                        <i className={clsx(classes.closeIcon, 'ri-close-line')} />
                                    </IconButton>
                                </>
                            } 
                            classes={{action: classes.heacerActions}}
                        />
                        <CardContent className={classes.cardContent}>                            
                            <img src={getLogoCard(LOGO_SOURCE.TALISIS)} className={classes.talisisLogo} />
                            <Box mt={5}>
                                <img src='/images/theme/nuevo-password.svg' />
                                <Typography align='left' variant="h4" className="mt-4">
                                    Contraseña Actualizada
                                </Typography>
                                <Typography align='left' variant='body2' className='text-gray-400 mt-2'>
                                    Te hemos enviado una contraseña temporal a tu email.
                                </Typography>
                                <Typography align='left' variant='body2' className='text-gray-400'>
                                    Asegúrate de revisar tu carpeta de spam si aún no lo has recibido.
                                </Typography>
                                <Button className='mt-4' variant="contained" color="primary" fullWidth>Ir a iniciar sesión</Button>
                            </Box>
                            <Divider className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" variant="fullWidth" />
                            <Box mb={7}>
                                <Typography align='center' variant='body2' className='text-gray-100'>¿Necesitas ayuda? <Link variant="body1" color="secondary" className={classes.helpLink} onClick={contactTechnicalSupport}>Haz clic aquí</Link></Typography>
                            </Box>

                        </CardContent>
                    </LoaderContent>    
                </Card>
            </Box>
            <Toast {...toastProps} onFinished={hanldeFinishedToast} />
        </Box>
    );
};

export default PasswordSSO;