import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootTwoSectionMedia: {
        width: '100%',
        height: '100%',        
        background: props => `linear-gradient(180deg, rgba(0, 0, 0, 0) 61.46%, rgba(0, 0, 0, 0.71) 100%), url(${props.backgroundImage})`,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        imageRendering: "-webkit-optimize-contrast",
        borderTopFeftRadius: '8px',
        borderBottomLeftRadius: '8px',
    },
    rootTwoSectionContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',   
        padding: '12px',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    ratingContainer: {
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'flex',
    },
    ratingNumberContainer: {
        width: '27px',
        height: '16px',
        padding: '0 4px',
        marginRight: '6px',
        background: theme.palette.grey[300],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ratingNumber: {
        color: theme.palette.colors.fillStroke[400],
    },
}));