import React from 'react'
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Helmet } from "react-helmet";

// Shared utils
import findTitleAndMetaDescription from '../shared/utils/findTitleAndMetaDescription';

const RouteChangeTrackerService = ({ history }) => {
    const {title, description } = findTitleAndMetaDescription(history.location.pathname + history.location.search);

    history.listen((location, action) => {
        ReactGA.send({ event: "page_view", page: location.pathname });
        
        /*

        TagManager.dataLayer({
            dataLayer:{
                event:"page_view",
                path: location.pathname
            }
        });

        */
        
        
    });

    return (
        <Helmet>
            <meta name="description" content={description} />
            <title>{title}</title>
        </Helmet>
    );
};

export default withRouter(RouteChangeTrackerService);