import * as permissionTypes from '../types/permissionTypes';

const INITIAL_STATE = {
    permissions: {},
    roles: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case permissionTypes.APPLY_PERMISSION:
            return {...state, permissions: action.payload};
        case permissionTypes.REMOVE_PERMISSION:
            return {...state, permissions: {}};
        case permissionTypes.APPLY_ROLES:
            return {...state, roles: action.payload};
        default:
            return state;
    }
};
