import React from 'react';
import CurrencyFormat from 'react-currency-format';
import clsx from 'clsx';

// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';

// assets
import useStyles from '../../../../assets/styles/jss/pages/checkout/checkout';

const OrderSavings = ({ orderSavings }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return <Grid container justifyContent="space-between" alignItems='center' className={classes.orderSavings}>
        <Grid item>
            <Typography variant="caption" className='text-gray-100'>Te ahorraste:</Typography>
        </Grid>
        <Grid item>
            <Typography variant='caption' className={clsx("text-gray-100", { "font-size-22": isMobile })}>
                <CurrencyFormat value={orderSavings} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />
            </Typography>
        </Grid>
    </Grid>
};

export default OrderSavings;