// @SDK
import { UserService } from '@sdk-point/talisis';
import { log } from '../../../../../../shared/utils/console.js'

const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

export const validateConektaUser = async( person_id ) => {
    try {
        const conektaUser = await UserServiceSDK.conektaGetCustomer(person_id);
        const hasConektaUser = conektaUser.some( user => !user)
        return hasConektaUser
    } catch (error) {
        log(error)
        return false
    }
}


export const getUserCard = async ( person_id ) => {
    try {
        const allCards = await UserServiceSDK.conektaGetCustomerCard(person_id);

        return allCards
    } catch (error) {
        return error
    }
};

export const validateCreditCard = ( payment_card ) => {
    
    const creditCardType = "credit";
    
    if(payment_card.length === 0 ) 
        return false

    if(payment_card[0].card_type !== creditCardType)
        return false

    return true
}

export const formatCustomerCard = ( cardLast4 ) => {
    return `savedMethod - ${cardLast4}`
}

export const handleFinishAsyncPayment = ({ sessionCookie, history, order, user, checkoutCookie }) => {
    const activeAccount = sessionCookie?.person_id ? true : false;
    history.replace({
        pathname: "/payment-confirmation",
        search: `?orderId=${order.id}`,
        state: {
            "user": user,
            "activeAccount": activeAccount,
            "activateAccountURL": checkoutCookie?.personInfo?.activateAccountURL
        }
    });
}
