import { PRODUCT_TYPES } from "@sdk-point/talisis";

export const getModalConfirmationMessage = (productTypeId) => {
    // Define los mensajes basados en el product_type_id
    const messages = {
        [PRODUCT_TYPES.MEMBRESIA]: "Si eliminas la membresía se eliminarán también todos los otros productos que hayas agregado.",
        [PRODUCT_TYPES.PROGRAMA]: "Este programa esta incluido en la membresía, si lo eliminas del carrito siempre podrás encontrarlo en el catálogo y volver a inscribirte."
    };

    // Devuelve el mensaje específico o el mensaje por defecto
    return messages[productTypeId] || messages.default;
}