import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    separatorLine: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.colors.fillStroke[200],
        marginTop: '28px',        
    },
    tooltipContainer: {
    },
    tooltip: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.colors.blue[300],
        backgroundColor: theme.palette.colors.darkBlack[500],
        borderRadius: '4px'
    },
    arrow: {
        '&:before': {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: theme.palette.colors.blue[300],
            backgroundColor: theme.palette.colors.darkBlack[500],
        }
    }
}));
