import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// @material-ui/core
import { Box, Button, CircularProgress, Icon, Typography } from '@material-ui/core';

// Style
import useStyles from '../../../../../assets/styles/jss/pages/feed/feedFileItemStyle';

const getName = (media) => {
    const tempData = media.split('/');
    return tempData[tempData.length - 1];
};

const FeedFileItem = ({ media, onDownloadFile, isResponsive }) => {
    const classes = useStyles({isResponsive});

    const [isDownloading, setIsDownloading] = React.useState(false);

    const handleDownloadFile = async () => {
        setIsDownloading(true);
        await onDownloadFile(media);
        setIsDownloading(false);
    };

    return (
        <Box className={classes.feedFileItemRoot}>
            <Box className={clsx(classes.fileNameContainer)}>
                <Icon className={clsx('ri-file-3-line', isResponsive ? 'font-size-24' : 'font-size-32')} />
                <Typography variant={isResponsive ? 'body2' : 'body1'} className={clsx(classes.fileName)}>{getName(media)}</Typography>
            </Box>
            <Box>
                <Button
                    color="primary"
                    label="Button"
                    onClick={() => handleDownloadFile(media)}
                    size={isResponsive ? 'medium' : 'small'}
                    disabled={isDownloading}
                    variant="outlined"
                    className={classes.downloadButton}>
                    Descargar
                    <Icon className={clsx('ri-download-cloud-2-line', classes.iconButton, 'font-size-24')} />
                </Button>
            </Box>
        </Box>
    );
};

FeedFileItem.defaultProps = {
    isResponsive: false,
};

FeedFileItem.propTypes = {
    media: PropTypes.string,
    onDownloadFile: PropTypes.func,
    isResponsive: PropTypes.bool,
};

export default FeedFileItem;