import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root:{
        padding: '12px 24px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ...theme.styles.tableFilter.root
    },
    chips:{
        borderRadius:0,
        padding: '0 12px 12px',
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            padding: 8
        },
    }
}));