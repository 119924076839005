import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// @material-ui/core
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// components
import MembershipPlanCardMedia from './MembershipPlanCardMedia';
import MembershipPlanCardContent from './MembershipPlanCardContent';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        width: "288px",
        height: props => props.isResponsive ? 'auto' : props.isReferrals ? "718px" : '639px',
        minHeight: props => props.isResponsive ? 'auto' : props.isReferrals ? "718px" : '639px',
        maxHeight: props => props.isResponsive ? 'auto' : props.isReferrals ? "718px" : '639px',
        display: 'flex',
        flexDirection: 'column',
        //
        [theme.breakpoints.down('xs')]: {
            minWidth: "auto",
            width: "auto",
        },
        //
        border: "0.5px solid",
        borderColor: theme.palette.colors.fillStroke[200],
        background: "rgba(28, 31, 36, 1)",
        transition: "0.3s",
        "& .MuiCardActionArea-root": {
            width: "100%",
            height: "577px",
            textAlign: "inherit",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    cardContentYear: {
        width: "288px",
        height: props => props.isResponsive ? 'auto' : props.isReferrals ? "718px" : '639px',
        minHeight: props => props.isResponsive ? 'auto' : props.isReferrals ? "718px" : '639px',
        maxHeight: props => props.isResponsive ? 'auto' : props.isReferrals ? "718px" : '639px',
        display: 'flex',
        flexDirection: 'column',
        //
        [theme.breakpoints.down('xs')]: {
            minWidth: "auto",
            width: "auto",
        },
        //
        border: "0.5px solid",
        borderColor: theme.palette.colors.fillStroke[200],
        background: "rgba(28, 31, 36, 1)",
        transition: "0.3s",
        "& .MuiCardActionArea-root": {
            width: "100%",
            height: "650px",
            textAlign: "inherit",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        }
    },
    special: {
        borderWidth: '0.5px',
        border: `0.5px solid ${theme.palette.colors.violet[300]} !important`
    },
    divider: {
        marginLeft: '24px',
        marginRight: '24px',
        color: theme.palette.colors.darkBlack[200],
    },
    popularCard: {
        [theme.breakpoints.up('1008')]: {
            position: "relative",
            top: "-40px",
        },
        background: `${theme.palette.colors.violet[500]} !important`,
        "& .membership-amount .membership-amount-month": {
            color: "white !important",
        },
        "& .details .MuiListItemText-root > .MuiTypography-root": {
            color: theme.palette.colors.gray[100]
        },
        //
        height: props => props.slideMode ? "210px !important" : (props.isResponsive ? 'auto' : props.isReferrals ? "758px" : "679px !important"),
        minHeight: props => props.slideMode ? "160px !important" : (props.isResponsive ? 'auto' : props.isReferrals ? "758px" : "679px !important"),
        maxHeight: props => props.slideMode ? "210px !important" : (props.isResponsive ? 'auto' : props.isReferrals ? "758px" : "679px !important"),
    },
    popularCardYear: {
        [theme.breakpoints.up('1008')]: {
            position: "relative",
            top: "-40px",
        },
        background: `${theme.palette.colors.violet[500]} !important`,
        "& .membership-amount .membership-amount-month": {
            color: "white !important",
        },
        "& .details .MuiListItemText-root > .MuiTypography-root": {
            color: theme.palette.colors.gray[100]
        },
        //
        height: props => props.slideMode ? "210px !important" : (props.isResponsive ? 'auto' : props.isReferrals ? "758px" : "679px !important"),
        minHeight: props => props.slideMode ? "160px !important" : (props.isResponsive ? 'auto' : props.isReferrals ? "758px" : "679px !important"),
        maxHeight: props => props.slideMode ? "210px !important" : (props.isResponsive ? 'auto' : props.isReferrals ? "758px" : "679px !important"),
    },
    popularBadge: {
        backgroundColor: "rgba(28, 31, 36, 1)",
        borderBottom: "0.5px solid",
        borderColor: `${theme.palette.colors.violet[300]} !important`,
        padding: "8px",
    },
    slide: {
        height: "210px !important",
        minHeight: "160px !important",
        maxHeight: "210px !important",
    }
}));

const MembershipPlanCard = ({ media, membershipLevel, content, feePlan, actionType, discountOff, isResponsive, isReferrals, membershipSelected, onMembershipSelect, calculateStrikeOutPrice, customCheckoutCookie, ...rest }) => {
    const { id, popular, slideMode } = rest;
    const classes = useStyles({ slideMode, isResponsive, isReferrals });
    const PLAN_ANUAL = "ANU";

    return (
        <Card className={clsx({
            [classes.cardContentYear]: feePlan === PLAN_ANUAL,
            [classes.cardContent]: feePlan !== PLAN_ANUAL,
            [classes.special]: id !== "freemium",
            [classes.popularCardYear]: id === popular && feePlan === PLAN_ANUAL,
            [classes.popularCard]: id === popular && feePlan !== PLAN_ANUAL,
            [classes.slide]: slideMode
        })}>
            {id === popular && !slideMode && (
                <Box className={classes.popularBadge}><Typography variant="body1" align="center">Recomendada</Typography></Box>
            )}
            <MembershipPlanCardMedia {...media} {...rest} feePlan={feePlan} discountOff={discountOff} isResponsive={isResponsive} calculateStrikeOutPrice={calculateStrikeOutPrice} />
            {
                !slideMode && (
                    <React.Fragment>
                        <Divider variant='inset' light className={classes.divider} />
                        <MembershipPlanCardContent {...content} {...rest}
                            feePlan={feePlan}
                            isReferrals={isReferrals}
                            actionType={actionType}
                            customCheckoutCookie={customCheckoutCookie}
                            membershipSelected={membershipSelected}
                            onMembershipSelect={onMembershipSelect} />
                    </React.Fragment>
                )
            }
        </Card>
    );
};

MembershipPlanCard.defaultProps = {
    isResponsive: false,
};

MembershipPlanCard.propTypes = {
    id: PropTypes.oneOf([
        "freemium",
        "lite",
        "professional",
        "premium",
    ]),
    slideMode: PropTypes.bool,
    name: PropTypes.string.isRequired,
    isResponsive: PropTypes.bool,
    media: PropTypes.shape({
        showIcon: PropTypes.bool,
        fee: PropTypes.object,
    }),
    content: PropTypes.shape({
        previous: PropTypes.string
    }),
    popular: PropTypes.string,
    user: PropTypes.object,
};

export default MembershipPlanCard;