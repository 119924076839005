import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// @material-ui/core
import {withStyles} from '@material-ui/core';
import {useMediaQuery} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

// shared - constant
import {REQUEST_STATUS_ID} from '../../../../../shared/constants/request-status';

const ChipStatus = withStyles((theme) => ({
    root: {
        borderRadius: 16,
        '&.pending':{
            background: 'rgba(168, 111, 251, 0.3)',
            border: '0.5px solid #A86FFB',
        },
        '&.approve':{
            background: 'rgba(132, 227, 73, 0.3)',
            border: '0.5px solid #84E349',
        }
    },
}))(Chip);

// La segunda palabra la pondra toda en minusculas
const secoundWordLowerCase = (text) => {
    if(!!text) {
        const words = text.split(' ');

        // Validamos que exista mas de una palabra.
        if ( words.length > 1) {
            words[1] = words[1].toLowerCase();

            return words.join(' ');
        }

        return text;
    }
    
    return "";
};

const RequestInformation = ({data}) => {
    let displayTittleMediaQuery = "flex";

    if (useMediaQuery(theme => theme.breakpoints.down("sm"))) {
        displayTittleMediaQuery = "block"
    }

    return (
        <Box mb={3} mt={4} display={displayTittleMediaQuery} alignItems="center">
            <Typography variant="h3" className="mr-4">{data.title}</Typography>
            <Typography className={"font-variant-v11 text-fill-stroke-100 mr-4"}>Folio #{data.id && String(data.id).padStart(6, '0')}</Typography>
            {
                data.status && 
                <ChipStatus label={secoundWordLowerCase(data.status)} variant="outlined" className={clsx({'pending': data.status_id === REQUEST_STATUS_ID.IN_PROGRESS, 'approve': data.status_id === REQUEST_STATUS_ID.APPROVE})}/>
            }
        </Box>
    );
};

RequestInformation.propTypes =  {
    data: PropTypes.object
};

export default RequestInformation;