import React from 'react';

// @material-ui/core
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


// styles
import useStyles from '../../../assets/styles/jss/components/detailsListStyle';

function DetailsList(props) {
    const {title, items, labelProp, ...rest} = props;
    const classes = useStyles();

    return (
        <Box className={classes.root} {...rest}>
            <Typography variant="h5" color="inherit">{title}</Typography>
            <List dense classes={{root: classes.list}}>
                {items.map( obj => 
                    <ListItem key={obj.course_group_id} className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <FiberManualRecordIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText classes={{primary: classes.listItemText}} primary={obj.description}/>
                    </ListItem>
                )}
            </List>
        </Box>
    );
}

export default DetailsList;