import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { blue } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';

import ModalSlider from "../../../../design-system/components/ModalSlider/ModalSlider";

import { MEMBERSHIPS } from '../../../../shared/constants/memberships';

import { AccountService } from '@sdk-point/talisis';

import { eventData, eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    content:{
        padding: 24,
        gap: 24
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    image:{
        height: 280
    },
    img:{
        height: 280,
        background: theme.palette.colors.fillStroke[300],
        '& div':{
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    },
    gif1:{
        backgroundImage: 'url(/images/on-start/OS_1.gif)',
    },
    gif2:{
        backgroundImage: 'url(/images/on-start/OS_2_1.gif)',
    },
    gif3:{
        backgroundImage: 'url(/images/on-start/OS_3.gif)',
    },
    gif4:{
        backgroundImage: 'url(/images/on-start/OS_4_1.gif)',
    },
    gif5:{
        backgroundImage: 'url(/images/on-start/OS_5.gif)',
    },
    gif6:{
        backgroundImage: 'url(/images/on-start/OS_6_1.gif)',
    },
    gif7:{
        backgroundImage: 'url(/images/on-start/OS_7_1.gif)',
    },
    gif8:{
        backgroundImage: 'url(/images/on-start/OS_8.gif)',
    },
    [theme.breakpoints.down('sm')]:{
        content:{
            padding: 16,
            gap: 16
        },
        img:{
            height: 340
        },
    },
    [theme.breakpoints.down('xs')]:{
        gif1:{
            backgroundImage: 'url(/images/on-start/OS_1_Responsive.gif)',
        },
        gif2:{
            backgroundImage: 'url(/images/on-start/OS_2_Responsive.gif)',
        },
        gif3:{
            backgroundImage: 'url(/images/on-start/OS_3_Responsive.gif)',
        },
        gif4:{
            backgroundImage: 'url(/images/on-start/OS_4_Responsive.gif)',
        },
        gif5:{
            backgroundImage: 'url(/images/on-start/OS_5_Responsive.gif)',
        },
        gif6:{
            backgroundImage: 'url(/images/on-start/OS_6_Responsive.gif)',
        },
        gif7:{
            backgroundImage: 'url(/images/on-start/OS_7_Responsive.gif)',
        },
        gif8:{
            backgroundImage: 'url(/images/on-start/OS_8_Resp.gif)',
        }, 
    }
}));

const sliders = {
    start: {
        gif: 'gif1',
        title: <Typography variant='h3' align='center' className='h4-sm'>
            ¡Hoy empieza tu experiencia en Talisis!
        </Typography>,
        description: <Typography align='center' className='body-text-samll-sm'>
            Te he preparado una guía con consejos esenciales para aprovechar al máximo la plataforma y llevar tu desarrollo al siguiente nivel.
        </Typography>
    },
    neo: {
        gif: 'gif3',
        title: <Typography variant='h3' align='center' className='h4-sm'>
            Estudia y aprueba tus materias en
            <span className='text-orange-300 ml-1'>NEO</span>
        </Typography>,
        description: <Typography align='center' className='body-text-samll-sm'>
            NEO es nuestra
            <span className='text-blue-300 bold mx-1'>plataforma aliada</span>
            donde encontrarás los módulos y evaluaciones que deberás completar para
            <span className='text-blue-300 bold ml-1'>aprobar tus materias</span>.<br/>
            En la sección de ‘Mis clases’ da clic en la materia que quieres estudiar y el contenido se cargará automáticamente en NEO.
        </Typography>
    },
    documentation: {
        gif: 'gif4',
        title: <Typography variant='h3' align='center' className='h4-sm'>
            Recuerda que es muy importante completar el &nbsp;
            <span className='text-orange-300'>envío de tus documentos</span>
        </Typography>,
        description: <Typography align='center' className='body-text-samll-sm'>
            Estarás cursando un
            <span className='text-blue-300 bold' style={{margin: '0px 2px'}}>programa oficial ante la SEP</span>
            y solo tienes
            <span className='text-blue-300 bold' style={{margin: '0px 2px'}}>3 meses</span><br/>
            para completar tu documentación o podrías provocar tu baja del programa. Para ver el listado de documentos ve a la sección de ‘Mi perfil’ y selecciona la pestaña de ‘Envío de documentos’.
        </Typography>
    },
    account:{
        gif: 'gif5',
        title: <Typography variant='h3' align='center' className='h4-sm'>
            Recuerda tener tu <span className='text-orange-300 mr-1'>correo institucional</span>
            activo para poder usar la biblioteca digital y office365
        </Typography>,
        description: <Typography align='center' className='body-text-samll-sm'>
            Al ser alumno de un programa académico se te ha asignado un
            <span className='text-blue-300 bold ml-1'>correo institucional</span> con el que podrás entrar a la
            <span className='text-blue-300 bold ml-1'>Biblioteca digital</span>&nbsp;y usar tu cuenta de 
            <span className='text-blue-300 bold ml-1'>Microsoft Office 365</span>. ¡Las instrucciones para activar tu cuenta las encuentras en tu correo de Bienvenida que te enviamos al inscribirte!
        </Typography>
    },
    advisers:[
        {
            gif: 'gif6',
            title: <Typography variant='h3' align='center' className='h4-sm'>
                Solicita <span className='text-orange-300 ml-1'>asesorías personalizadas</span> y<br/>refuerza tu aprendizaje
            </Typography>,
            description: <Typography align='center' className='body-text-samll-sm'>
                Tu membresía incluye el beneficio de solicitar
                <span className='text-blue-300 bold ml-1'>2 asesorías por mes</span>.<br/>
                Visita la sección de ‘Asesorías’, selecciona a uno de nuestros asesores certificados en el 
                <span className='text-blue-300 bold mx-1'>área de conocimiento</span>
                en la que necesites ayuda, y agenda una videollamada de 
                <span className='text-blue-300 bold ml-1'>30 minutos.</span>
            </Typography>
        },
        {
            gif: 'gif6',
            title: <Typography variant='h3' align='center' className='h4-sm'>
                Solicita <span className='text-orange-300 ml-1'>asesorías personalizadas</span> y<br/>refuerza tu aprendizaje
            </Typography>,
            description: <Typography align='center' className='body-text-samll-sm'>
                Disfruta el beneficio de tu membresía y recibe
                <span className='text-blue-300 bold ml-1'>asesorías ilimitadas</span>.<br/>
                Visita la sección de ‘Asesorías’, selecciona a uno de nuestros asesores certificados en el 
                <span className='text-blue-300 bold mx-1'>área de conocimiento</span>
                en la que necesites ayuda, y agenda una videollamada de 
                <span className='text-blue-300 bold ml-1'>30 minutos.</span>
            </Typography>
        },
    ],
    button:{
        gif: 'gif8',
        title: <Typography variant='h3' align='center' className='h4-sm'>
            ¡Estamos listos para crear tu futuro!
        </Typography>,
        description: <Typography align='center' className='body-text-samll-sm'>
            Si tienes dudas o algún problema técnico con la plataforma da clic en el 
            <span className='text-orange-300 bold mx-1'>botón naranja</span>
            para levantar un reporte.<br/><br/>
            ¡Aprovecha y disfruta al máximo de la experiencia Talisis!
        </Typography>
    },
    allys:[
        {
            gif: 'gif7',
            title: <Typography variant='h3' align='center' className='h4-sm'>
                Estudia los cursos que quieras de educación continua de <span className='text-orange-300'>nuestros aliados</span>
            </Typography>,
            description: <Typography align='center' className='body-text-samll-sm'>
                Otro gran beneficio de tu membresía es que tienes el acceso a todos los cursos de nuestros aliados
                <span className='text-blue-300 bold mx-1'>Crehana</span>y<span className='text-blue-300 bold ml-1'>LinkedIn Learning</span>. <br/>
                También puedes inscribirte a cualquiera de los cursos de educación continua de nuestras marcas
                <span className='text-blue-300 bold mx-1'>UNID</span>y<span className='text-blue-300 bold ml-1'>U-ERRE</span>,
                &nbsp;y acceder al catalogo de cursos de 
                <span className='text-blue-300 bold ml-1'>IE Publishing</span>,<span className='text-blue-300 bold mx-1'>Google Skillshop</span>y<span className='text-blue-300 bold ml-1'>Hubspot</span>.
            </Typography>
        },
        {
            gif: 'gif7',
            title: <Typography variant='h3' align='center' className='h4-sm'>
                Estudia los cursos que quieras de educación continua de <span className='text-orange-300'>nuestros aliados</span>
            </Typography>,
            description: <Typography align='center' className='body-text-samll-sm'>
                Otro gran beneficio de tu membresía es que tienes el acceso a todos los cursos de nuestros aliados
                <span className='text-blue-300 bold ml-1'>Coursera</span>,<span className='text-blue-300 bold mx-1'>Crehana</span>y<span className='text-blue-300 bold mx-1'>LinkedIn Learning</span>. <br/>
                También puedes inscribirte a cualquiera de los cursos de educación continua de nuestras marcas
                <span className='text-blue-300 bold mx-1'>UNID</span>y<span className='text-blue-300 bold ml-1'>U-ERRE</span>
                &nbsp;y acceder al catalogo de cursos de 
                <span className='text-blue-300 bold ml-1'>IE Publishing</span>,<span className='text-blue-300 bold mx-1'>Google Skillshop</span>y<span className='text-blue-300 bold ml-1'>Hubspot</span>.
            </Typography>
        },
    ]
};

const myClases = (levels) => {
    const level = [...new Set(levels)].join('');

    switch (level) {
        case 'BA':
            return {
                gif: 'gif2',
                title: <Typography variant='h3' align='center' className='h4-sm'>
                    Encuentra el avance de tu Bachillerato en la sección de <span className='text-orange-300'>‘Mis clases’</span>
                </Typography>,
                description: <Typography align='center' className='body-text-samll-sm'>
                    Visita esta sección para ver tus cursos en progreso, los terminados, y el catálogo con todas las materias que tienes que cursar para completar tu Bachillerato. Puedes estar inscrito en 
                    <span className='text-blue-300 bold mx-1'>2 materias</span>
                    a la vez y en cuanto termines una, podrás
                    <span className='text-blue-300 bold mx-1'>elegir e inscribirte</span>
                    a una nueva.
                </Typography>
            }
        case 'LI':
            return {
                gif: 'gif2',
                title: <Typography variant='h3' align='center' className='h4-sm'>
                    Encuentra el avance de tu Licenciatura en la sección de <span className='text-orange-300'>‘Mis clases’</span>
                </Typography>,
                description: <Typography align='center' className='body-text-samll-sm'>
                    Visita esta sección para ver tus cursos en progreso, los terminados, y el catálogo con todas las materias que tienes que cursar para completar tu Licenciatura. Puedes estar inscrito en 
                    <span className='text-blue-300 bold mx-1'>2 materias</span>
                    a la vez y en cuanto termines una, podrás
                    <span className='text-blue-300 bold mx-1'>elegir e inscribirte</span>
                    a una nueva.
                </Typography>,
                boxProps:{
                    maxWidth: 577
                }
            }
        case 'MA':
            return {
                gif: 'gif2',
                title: <Typography variant='h3' align='center' className='h4-sm'>
                    Encuentra el avance de tu Maestría en la sección de <span className='text-orange-300'>‘Mis clases’</span>
                </Typography>,
                description: <Typography align='center' className='body-text-samll-sm'>
                    Visita esta sección para ver tus cursos en progreso, los terminados, y el catálogo con todas las materias que tienes que cursar para completar tu Maestría. Puedes estar inscrito en
                    <span className='text-blue-300 bold mx-1'>1 materia</span>
                    a la vez y en cuanto la termines, podrás
                    <span className='text-blue-300 bold mx-1'>elegir e inscribirte</span>
                    a una nueva.
                </Typography>,
            }
        case 'PO':
            return {
                gif: 'gif2',
                title: <Typography variant='h3' align='center' className='h4-sm'>
                    Encuentra el avance de tu Maestría en la sección de <span className='text-orange-300'>‘Mis clases’</span>
                </Typography>,
                description: <Typography align='center' className='body-text-samll-sm'>
                    Visita esta sección para ver tus cursos en progreso, los terminados, y el catálogo con todas las materias que tienes que cursar para completar tu Maestría. Puedes estar inscrito en
                    <span className='text-blue-300 bold mx-1'>1 materia</span>
                    a la vez y en cuanto la termines, podrás
                    <span className='text-blue-300 bold mx-1'>elegir e inscribirte</span>
                    a una nueva.
                </Typography>,
            }
        default:
            return {
                gif: 'gif2',
                title: <Typography variant='h3' align='center' className='h4-sm'>
                    Encuentra el avance de tu programa en la sección de <span className='text-orange-300'>‘Mis clases’</span>
                </Typography>,
                description: <Typography align='center' className='body-text-samll-sm'>
                    Visita esta sección para ver tus cursos en progreso, los terminados, y el catálogo con todas las materias que tienes que cursar para completar tu programa. Puedes estar inscrito en
                    <span className='text-blue-300 bold mx-1'>2 materias</span>
                    a la vez y en cuanto termines una, podrás
                    <span className='text-blue-300 bold mx-1'>elegir e inscribirte</span>
                    a una nueva.
                </Typography>,
            }
    }
};

function Item({item}) {
    const classes = useStyles();
    return (
        <Box>
            <Box className={classes.img}>
                <Box className={classes[item.gif]} />
            </Box>
            <Box display='flex' flexDirection='column' className={classes.content} height={234}>
                <Box>
                    {item.title}
                </Box>
                <Box px={1} {...item.boxProps}>
                    {item.description}
                </Box>
            </Box>
        </Box>
    );
}


const OnStartMyClasses = React.memo(({user, levels = []}) => {
    const acceptedMemberships = [
        MEMBERSHIPS.LITE,
        MEMBERSHIPS.LITE_PLUS,
        MEMBERSHIPS.PREMIUM,
        MEMBERSHIPS.PROFESSIONAL,
    ];

    if(!acceptedMemberships.includes(user.membership_id)) return;

    const [open, setOpen] = React.useState(false);
    const [preferencesBody, setPreferencesBody] = React.useState({});
    const showOnStart = open && levels.length;

    React.useEffect(() => {
        getPreferences();
    }, []);

    const getPreferences = async () => {
        try {
            const preferences = await AccountServiceSDK.getPreferences(user.person_id);
            setPreferencesBody(preferences);
            setOpen(!preferences.on_start_my_classes);
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleClose = (event) => {
        event.stopPropagation();
    };

    const handleClickLastButton = async () => {
        try {
            eventTracker('finalizar_onstart',eventData, ['mixpanel']);
            setOpen(false);
            await AccountServiceSDK.updatePreferences(user.person_id, {
                ...preferencesBody,
                on_start_my_classes: true
            });
        } catch (error) {
            setOpen(false);
        }
    };

    if(!showOnStart) return;

    return (
        <ModalSlider size={8} onClose={handleClose} open={open}
            textLastButton='Ir a mis clases'
            onClickLastButton={handleClickLastButton}
        >
            <Item item={sliders.start}/>
            <Item item={myClases(levels)}/>
            <Item item={sliders.neo}/>
            <Item item={sliders.documentation}/>
            <Item item={sliders.advisers[Number(user.membership_id === MEMBERSHIPS.PREMIUM)]}/>
            <Item item={sliders.account}/>
            <Item item={sliders.allys[Number(user.membership_id === MEMBERSHIPS.PREMIUM)]}/>
            <Item item={sliders.button}/>
        </ModalSlider>
    );
});

export default OnStartMyClasses;