import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';

// Material components
import { Box } from '@material-ui/core';

// styles
import useStyles from '../../../../../assets/styles/jss/layout/floatingContainerMenuStyle';

const FloatingContainerMenu = ({ wrapperRef, children, classNameContainer, open, isLogged }) => {
    const classes = useStyles({isLogged});

    return (
        <Backdrop className={classes.backdrop} open={open} style={{alignItems: 'normal'}}>
            <Box className={`${classNameContainer ?? ''}`}>
                <Box ref={wrapperRef} className={`${classes.rootFloatingContainerMenu}`}>
                    {children}
                </Box>
            </Box>
        </Backdrop>
    );
};


export default FloatingContainerMenu;