import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';

//material
import { Typography, Card, Grid, Checkbox, Button, Box, Chip } from '@material-ui/core';

// shared components
import Select from '../../../../../design-system/components/Form/Select/Select';
import Table from '../../../../../shared/components/Table/Table';
import EmptyDataMessage from '../../../../../shared/components/EmptyDataMessage/EmptyDataMessage';

// style
import useStyles from '../../../../../assets/styles/jss/pages/account/billing/pendingListItemsStyle';

// utils
import moment from 'moment/moment';

// svg
import womanSearchFileSVG from '../../../../../assets/images/woman_search_file.svg';

const MuiChip =  styled(Chip)`
    ${({ theme, valid }) => `
        border: 2px solid;
        border-color: ${ valid === 'true'? theme.palette.colors.warning[200] : theme.palette.colors.error[200]};
        background-color: ${ valid === 'true' ? theme.palette.colors.warning[500] : theme.palette.colors.error[400]};
        color: ${theme.palette.colors.white};
        border-radius: 16px;
    `}
`;

const compareDateExpired = (date) => {
    const dateNowValue = moment().startOf('day').valueOf();
    const dateTimeValue = moment(date).valueOf();
    return dateTimeValue < dateNowValue;
};

const currencyFormatFunc = (row, value, index) => {
    return (
        <Box>
            <Box><Typography variant="caption" className={`text-gray-500 caption-small`}>Cantidad a pagar:</Typography></Box>
            <Box><Typography variant='body2' className={`text-violet-300 semi-bold`}>MXN <CurrencyFormat value={value} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography></Box>
        </Box>
    );
};

const keyAndDescritionConcept = (row, value, index) => {
    return (
        <Box>
            <Box display={'flex'} justifyContent={'start'}><Typography variant="caption" className={`text-gray-500 caption-small`}>CLAVE: {row['conceptKey']}</Typography></Box>
            <Box display={'flex'} justifyContent={'start'}><Typography variant='body2' className={`text-gray-100 semi-bold`}>{row['concept']}</Typography></Box>
        </Box>
    );
};

const chipExpirationFunc = (row, value, index) => {
    let isValidDate = 'true';
    let label = 'Por vencer';
    
    if (compareDateExpired(row.date)) {
        isValidDate = 'false';
        label = 'Vencido';
    }
    
    return <MuiChip label={label} valid={isValidDate}/>;
};

const columnsOuPendingData = [
    {
        name: 'colConcept',
        align: 'left',
        minWidth: 0,
        label: 'Concepto',
        format: keyAndDescritionConcept
    },
    {
        name: 'colPeriod',
        align: 'center',
        minWidth: 0,
        label: 'Periodo',
    },
    {
        name: 'colEndDate',
        align: 'center',
        minWidth: 0,
        label: 'Fecha limite de pago',
    },
    {
        name: 'colStatus',
        align: 'center',
        minWidth: 0,
        label: 'Estatus',
        format: chipExpirationFunc
    },
    {
        name: 'colAmount',
        align: 'center',
        minWidth: 0,
        label: 'Monto MX',
        format: currencyFormatFunc
    },
];


const PendingListItems = ({ ouList, OnOuChange, ouValue, pendingDataTable, pendingDataSelected, onGoToComponent, onChangeDataSelected, onChangeAllDataSelected }) => {
    const classes = useStyles();

    const [totalDebts, setTotalDebts] = useState(0);
    const [totalDebtsSelected, setTotalDebtsSelected] = useState(0);
    const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);

    const getTotalDebts = async () => {
        const initialValue = 0;

        setTotalDebts(pendingDataTable.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.colAmount), initialValue));
    };

    const getTotalDebtsSelected = async () => {
        const initialValue = 0;

        setTotalDebtsSelected(pendingDataTable.filter(item => !!pendingDataSelected.find(item2 => item2.id === item.id)).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.colAmount), initialValue));
    };

    useEffect(() => {
        getTotalDebts();
    }, [pendingDataTable]);
    
    useEffect(() => {
        getTotalDebtsSelected();
    }, [pendingDataSelected]); 

    return (
        <>
            <Typography variant='h4' className={classes.debtsTitleLabel}>Mis adeudos pendientes</Typography>
            <Typography variant='h5'>Realiza tus pagos cuanto antes para seguir navegando en la plataforma</Typography>

            <Card className={`${classes.debtsContainer} offset-margin-top-21`}>
                <Box className={`offset-margin-top-22`}>
                    <Select items={ouList} label="Marca" className={`offset-margin-left-24 ${classes.ouSelect}`} name="ouSelectDebts" onChange={OnOuChange} value={ouValue} size="small"/>
                </Box>

                <Box className={`offset-margin-top-20`}>
                    {!!pendingDataTable.length ? 
                    <Table columns={columnsOuPendingData} rows={pendingDataTable} checkboxs={true} rowSelectedList={pendingDataSelected} onChangeSelectedRow={onChangeDataSelected} onChangeAllSelectedRow={onChangeAllDataSelected}/>
                    : <EmptyDataMessage image={womanSearchFileSVG} titleMessage={'Parece que aún no contamos con esta información'} message={'Aún no presentas movimientos relacionados en tu cuenta de estudiante con ese término o no es existente. Si crees es un error, acude a ventanilla.'}/>}
                </Box>

                <Grid container spacing={3} className={`offset-margin-bottom-16 offset-margin-top-21`}>
                    <Grid item xs={12} md={7}>
                        <Box>
                            <i className={`${classes.iconInformation} ri-error-warning-line offset-margin-left-25 font-size-16`}></i>
                            <Typography variant='caption' className={`${classes.chargeLabel} offset-margin-left-12`}>El cargo aparecerá en el estado de cuenta de tu tarjeta de crédito como "TALISIS TESORERÍA"</Typography>
                        </Box>
                        <Box className='offset-margin-top-13'>
                            <Checkbox color="primary" className={`${classes.policiesAndPrivacyCheckbox} offset-margin-left-16`} value={termsConditionsChecked} onChange={() => setTermsConditionsChecked(prev => !prev)} disabled={!pendingDataSelected.length}/>
                            <Typography variant='caption' className={classes.policiesAndPrivacyLabel}>Acepto Términos, Condiciones y Políticas de Talisis</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box className={classes.totalCotainer}>
                            <Box className={classes.totalLabel}>
                                <Typography variant='subtitle1' className={`${classes.debtsTotalLabel} offset-margin-left-12`}>Deuda total:</Typography>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                                <Typography variant='subtitle1' className={`${classes.debtsTotalAmountLabel} offset-margin-left-12`}>MXN <CurrencyFormat value={totalDebts} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography>
                            </Box>
                            <Box sx={{ width: "10%" }}>
                                <i className={`ri-error-warning-line font-size-16`}></i>
                            </Box>
                        </Box>
                        <Box className={`${classes.totalCotainer} offset-margin-top-21`}>
                            <Box className={classes.totalLabel}>
                                <Typography variant='h5' className={`${classes.balanceTotalLabel} offset-margin-left-12`}>Saldo a pagar:</Typography>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                                <Typography variant='h5' className={`${classes.balanceTotalAmountLabel} offset-margin-left-12`}>MXN <CurrencyFormat value={totalDebtsSelected} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography>
                            </Box>
                            <Box sx={{ width: "10%" }}>
                                <i className={`ri-error-warning-line font-size-16`}></i>
                            </Box>
                        </Box>
                        <Box className={`${classes.payButtonContainer} offset-margin-top-23`}>
                            <Button
                                color="primary"
                                label="Pagar ahora"
                                onClick={() => onGoToComponent()}
                                size="large"
                                variant="contained"
                                className={classes.payButton}
                                disabled={!termsConditionsChecked}>
                                Pagar ahora
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

PendingListItems.propTypes = {
    ouList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired, 
        label: PropTypes.string.isRequired
    })),
    OnOuChange: PropTypes.func.isRequired,
    ouValue: PropTypes.string.isRequired,
    pendingDataTable: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        colPeriod: PropTypes.string.isRequired,
        colEndDate: PropTypes.string.isRequired,
        colAmount: PropTypes.string.isRequired,
        date: PropTypes.string,
        concept: PropTypes.string.isRequired,
        conceptKey: PropTypes.string.isRequired,
        checkboxDisabled: PropTypes.bool
    })),
    pendingDataSelected: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired
    })),
    onGoToComponent: PropTypes.func.isRequired,
    onChangeDataSelected: PropTypes.func.isRequired,
    onChangeAllDataSelected: PropTypes.func.isRequired
};

export default PendingListItems;