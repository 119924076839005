import { MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';

export const handleClickMixpanel = (name, source_name, userProps)=> {
    const role = JSON.parse(localStorage.getItem('permissions'));

    const eventdata = {
        userId: userProps.user.person_id,
        email: userProps.user.email,
        cursoName: name,
        source: source_name,
        gender: userProps.user.gender,
        userRole: role != null ? JSON.stringify(role[0]?.role_name).replace(/["']/g, "") : undefined,//estudiante,maestro,admin
        userType: role == null ? 'Publico':'Usuario registrado'
    }

   
}
