import { makeStyles } from '@material-ui/core/styles';

const border = '2px solid rgba(49, 193, 222, 0.5)';
const padding = '3px 3px';
const minWidth = 35;

export default makeStyles((theme) => ({
    menuItem: {
        color: '#62C4ED'
    }
}));