import React from 'react'

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import useStyles from '../../../../assets/styles/jss/components/academicStyle';
import womanSearchFileSVG from '../../../../assets/images/woman_search_file.svg';

// component-styles
import './academicStyles.css'

import TabPanel from '../../../../shared/components/Tabs/TabPanel';

import Horario from '../Horario/Horario';
import Calificaciones from '../Calificaciones/Calificaciones';
import Kardex from '../Kardex/Kardex';
import Surveys from '../Surveys/Surveys';
import { Box, Card, Icon, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import Access from '../../../Access/Register/Register'
import { useSelector } from 'react-redux';
import ErrorContainer from '../../../../design-system/components/ErrorAccess/ErrorAccess';
import ErrorAccess from '../../../../design-system/components/ErrorAccess/ErrorAccess';

let buttons = ['Horario', 'Calificaciones', 'Kárdex', 'Encuestas y evaluaciones'];

const Academic = (props) => {

  const { state, service, subTab } = props;

  const [value, setValue] = React.useState(0);
  const [activeTabIndex,setActiveTabIndex]=React.useState(0);
  const [hasActionIdOne,setHasActionIdOne]=React.useState(false);
  const TABINDEX=1;
  const {restrictions} = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    setValue(subTab);
    if(subTab !== 0){
      window.scrollTo(0, 0);
    } 
  }, [subTab]);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const isCurrentTab=(val)=>{
    return activeTabIndex===TABINDEX && value===val?true:false;
  };

  useEffect(()=>{
    setActiveTabIndex(props.props.activeTabIndex);
    for (const key in restrictions) {
      if (restrictions[key].action_id === 1) {
        setHasActionIdOne(true)
        break; // Terminar el bucle si se encuentra un objeto con action_id: 1
      }
    }
  },[props.props.activeTabIndex]);

  const AcademnicIcon = (index) => {
    
    switch (index.index) {
      case 0:
        return  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM20 11H4V19H20V11ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM6 13H8V15H6V13ZM11 13H13V15H11V13ZM16 13H18V15H16V13Z" fill="#A0A9BA"/>
                </svg>
      case 1:
        return  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 12H7V21H3V12ZM17 8H21V21H17V8ZM10 2H14V21H10V2Z" fill="white"/>
                </svg>  
      case 2:
        return  <svg width="1.5em" height="1.5em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.9865 21H10.9932C5.47413 21 1 16.5228 1 11C1 5.47715 5.47413 1 10.9932 1C16.5124 1 20.9865 5.47715 20.9865 11C21.0744 12.144 20.7538 14.259 19.4017 16.4545" stroke="#A0A9BA" stroke-width="2" stroke-linecap="round"/>
                  <path d="M6.3267 14.9091H4.25391L7.68395 5.45454H10.163L13.2791 14.9091H11.2063L8.92578 7.56427H8.85192L6.3267 14.9091ZM6.29901 11.1974H11.3402L11.3125 12.7163H6.27131L6.29901 11.1974Z" fill="#A0A9BA"/>
                  <path d="M15.0859 12.5273V7.12109H16.3125V12.5273H15.0859ZM12.9961 10.4375V9.21094H18.4023V10.4375H12.9961Z" fill="#A0A9BA"/>
                </svg>
        
      case 3:
        return  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 4H21V6H11V4ZM11 8H17V10H11V8ZM11 14H21V16H11V14ZM11 18H17V20H11V18ZM3 4H9V10H3V4ZM5 6V8H7V6H5ZM3 14H9V20H3V14ZM5 16V18H7V16H5Z" fill="#A0A9BA"/>
                </svg>        
      case 4:   
        return  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 17V16H13V13H16V15H18V17H17V19H15V21H13V18H15V17H16ZM21 21H17V19H19V17H21V21ZM3 3H11V11H3V3ZM5 5V9H9V5H5ZM13 3H21V11H13V3ZM15 5V9H19V5H15ZM3 13H11V21H3V13ZM5 15V19H9V15H5ZM18 13H21V15H18V13ZM6 6H8V8H6V6ZM6 16H8V18H6V16ZM16 6H18V8H16V6Z" fill="#A0A9BA"/> 
                </svg>
      default:
        break;
    }
   
    
  }
  const classes = useStyles();
  return (
    <Grid container className='options-wrapper'>
      <Grid item>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabs}
          orientation={isWidthDown("sm", props.width) ? "horizontal" : "vertical"}
          value={value}
        >
          {
            buttons.map((button, index) => 
              <Tab 
                key={index}
                className='mb-2'
                label={
                <Grid container justifyContent='flex-start'>
                  <AcademnicIcon index={index}  />
                  { !isWidthDown("sm", props.width) && <Typography className='ml-2'>{button}</Typography>}
                </Grid>
                }  
              />
            )
          }
        </Tabs>
      </Grid>
      <Grid item xs>
        <TabPanel className={classes.tabBody} value={value} index={0}>
          <Horario {...state} service={service} isCurrentTab={isCurrentTab(0)} />
        </TabPanel>
        {!hasActionIdOne ? 
          <TabPanel className={classes.tabBody} value={value} index={1}>
            <Calificaciones {...state} service={service} isCurrentTab={isCurrentTab(1)} />
          </TabPanel>
          :
          <TabPanel className={classes.tabBody} value={value} index={1}>
            <ErrorAccess 
            title={"No podemos mostrarte esta sección"} 
            text={restrictions[0].access_message}
            imgSize={115}
            image={womanSearchFileSVG}
            classNameIcon={"ri-error-warning-line text-warning-300 font-size-24"}
            width={947}
            />
            <Box
              sx={{
                position: 'relative',
                filter: 'blur(20px)',
              }}
            >
              <Calificaciones {...state} service={service} isCurrentTab={isCurrentTab(1)} />
            </Box>
          </TabPanel>
        }
        <TabPanel className={classes.tabBody} value={value} index={2}>
          <Kardex {...state} service={service} isCurrentTab={isCurrentTab(2)} />
        </TabPanel>
        <TabPanel className={classes.tabBody} value={value} index={3}>
          <Surveys isCurrentTab={isCurrentTab(3)} />
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default withWidth() (Academic);
