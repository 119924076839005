import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    accordionRoot: {
        backgroundColor: 'transparent'
    },
    accordionSummary: {
        borderRadius: 0,
        padding: '0 40px',
        justifyContent: "start",
        '& .MuiAccordionSummary-content': {
            flexGrow: 0,
        }
    },
    accordionDetails: {
        padding: '10px 0 16px 74px',
    },
    expandMoreIcon: {
        color: theme.palette.colors.fillStroke[100]
    },
    editMessageIcon: {
        color: theme.palette.colors.fillStroke[100]
    },
    commentMessage: {
        marginBottom: '10px',
        borderLeft: `2px solid ${theme.palette.colors.fillStroke[400]}`,
        paddingLeft: "25px",
        width: '100%',
    }
}));