import React from 'react';
import { useParams } from "react-router-dom";

// redux
import { connect } from 'react-redux';

import { Box, Typography, Grid } from '@material-ui/core';
import Divider from "@material-ui/core/Divider";

// react-lottie
import Lottie from 'react-lottie';
import animationData from './tredireccion.json';

// logos
import { getLogoCard } from '../../../src/shared/utils/imageSource';

// style
import useStyles from '../../assets/styles/jss/layout/redirectPageStyle';
import { log } from '../../shared/utils/console.js'

// SDK
import { AllysService } from '@sdk-point/talisis';

const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const RedirectPage = () => {
    const { company_id } = useParams();
    const classes = useStyles();
    const [allies_id, setAlliesID] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            await AllysServiceSDK.getAlliesList().then(response => {
                log(JSON.stringify(response));
                for (const property in response) {
                    if (response[property].source_id == company_id) {
                        setAlliesID(response[property].source_id);
                        break;
                    }
                    else {
                        setAlliesID(company_id);
                    }
                }
            });
        };
        fetchData();
    }, []);

    return (
        <Box className={classes.main}>
            <Box className={classes.root}>
                <Grid container item xs={6} style={{ justifyContent: "center", alignItems: "center" }}>
                    <img alt="logo" style={{ width: "200px" }} src={getLogoCard(7)} />
                    <Lottie options={defaultOptions} height={100} width={100} />
                    {allies_id && <img alt="logo" style={{ width: "200px" }} src={getLogoCard(allies_id)} />}
                </Grid>
            </Box>
            <Box className={classes.contentText}>
                <Grid container item xs={3} style={{ justifyContent: "center", alignItems: "center" }}>
                    <Typography variant="h3" align='left'>Espera un momento</Typography>
                    <Typography variant="subtitle1" align='left'>Te estamos redirigiendo...</Typography>
                </Grid>
            </Box>
            <Divider className={classes.divider} />
        </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(RedirectPage);
