import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Level = ({ value }) => {
    const levels = [
        { label: 'Básico', height: 4, activate: 1 },
        { label: 'Intermedio', height: 6, activate: 2 },
        { label: 'Avanzado', height: 8, activate: 3 },
    ];

    return <Box display="flex" alignItems="center" mr={1}>
        <Box>
            {
                value == 1 ?
                    <Box display="flex" alignItems="end" mr={.5}>
                        <Box key={1} width={2} height={3} style={{ background: '#B8B8BB', marginRight: 1, opacity: '1' }}></Box>
                        <Box key={2} width={2} height={6} style={{ background: '#B8B8BB', marginRight: 1, opacity: '0.3' }}></Box>
                        <Box key={3} width={2} height={9} style={{ background: '#B8B8BB', marginRight: 1, opacity: '0.3' }}></Box>
                    </Box>
                    : value == 2 ?
                        <Box display="flex" alignItems="end" mr={.5}>
                            <Box key={1} width={2} height={3} style={{ background: '#B8B8BB', marginRight: 1, opacity: '1' }}></Box>
                            <Box key={2} width={2} height={6} style={{ background: '#B8B8BB', marginRight: 1, opacity: '1' }}></Box>
                            <Box key={3} width={2} height={9} style={{ background: '#B8B8BB', marginRight: 1, opacity: '0.3' }}></Box>
                        </Box>
                        : value == 3 &&
                        <Box display="flex" alignItems="end" mr={.5}>
                            <Box key={1} width={2} height={3} style={{ background: '#B8B8BB', marginRight: 1, opacity: '1' }}></Box>
                            <Box key={2} width={2} height={6} style={{ background: '#B8B8BB', marginRight: 1, opacity: '1' }}></Box>
                            <Box key={3} width={2} height={9} style={{ background: '#B8B8BB', marginRight: 1, opacity: '1' }}></Box>
                        </Box>
            }
        </Box>
        <Typography className="font-size-12 font-weight-400 text-dark-black-100" style={{ lineHeight: '16px' }}>{value ? levels[value - 1].label : levels[0].label}</Typography>
    </Box>
}

export default Level;