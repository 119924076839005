import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            marginTop: '-202px'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: props => props.hideInfoMembership ? '1em' : '-2em'
        },
    },
    card: {
        background: props => props.hideInfoMembership ? theme.palette.colors.fillStroke[500] : "#1D1E23",
    },
    cardContent: {
        padding:  props => props.hideInfoMembership ? "0px" : "24px",
    },
    cardPriceOnly:{
        position: 'relative',
        left: '-16px !important',
        width: 'calc(100% + 34px)',
        height: 82,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px -6px 16px 0px rgba(17, 17, 17, 0.80)',
    },
    cardContentPriceOnly: {
        width: "100%",
        margin: props => props.membershipIncluded && '0px 16px',
        padding: "16px 30px !important",
    },
    boxPrice:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginRight: 20
    },
    buttonMemberships:{
        padding: '8px 0px'
    },
    messageBox:{
        color: theme.palette.colors.darkBlack[100]
    },
    messageBoxOrange:{
        color: theme.palette.colors.orange[300]
    },
    bottomBlock:{
        width: "100%",
        "&.Mui-disabled": {
            [theme.breakpoints.up('md')]: {
                background: theme.palette.colors.fillStroke[500],
                //background: "#111111",
                color: theme.palette.colors.darkBlack[200]
            },
        }
    },
    bottomResp:{
        "&.Mui-disabled": {
            background: theme.palette.colors.fillStroke[500],
            //background: "#111111",
            color: theme.palette.colors.darkBlack[200]
        }
    },
    iconlock:{
        fontSize: '16px'

    },
    titleBox:{
        fontSize: "14px",
        color: theme.palette.colors.grey[100],
    }
}));