import React from 'react';
import PropTypes from 'prop-types';

//@material-ui/core 
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import clsx from 'clsx';

// styles
import { withStyles } from '@material-ui/core/styles';
import {BootstrapInputStyled, LabelStyled} from '../../../assets/styles/jss/components/inputComponentsStyle'

import { v4 as uuid } from 'uuid';

const ExpandIcon = withStyles((theme) => ({
    root: {
        marginRight: 5
    }
}))(ExpandMoreIcon);


const FormControlSelect = (props) => {
    const {label, placeholder, value, items, ...rest} = props;
    
    return (
        <FormControl {...rest}>
            <LabelStyled id="customized-select-label" shrink>{label}</LabelStyled>
            <Select
                labelId="customized-select-label"
                id="customized-select"
                value={value}
                displayEmpty
                {...rest}
                className={clsx({'placeholder-select': placeholder && !value})}
                input={<BootstrapInputStyled />}
                IconComponent={ExpandIcon}
            >
                <MenuItem value="" disabled>{placeholder}</MenuItem>
                {
                    items.map(it => 
                        <MenuItem value={it.id} key={uuid()}>{it.label}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    );
};

export default FormControlSelect;

FormControlSelect.propTypes = {
    /**
     * Array of options
     */
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    /**
     * Optional click handler
     */
    onChange: PropTypes.func,
    required: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.any
};