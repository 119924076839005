import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Hidden from '@material-ui/core/Hidden';

// Media query
import { useMediaQuery } from '@material-ui/core';

// Components
import EventCard from './components/EventCard';
import EventDetail from './components/EventDetail';

import AvatarPerson from './components/AvatarPerson';
import ButtonAction from './components/ButtonAction';

// Shared components
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import Loader from '../../../shared/components/Loader/Loader';

import Maps from '../../../shared/components/Maps/Maps';

// Constants
import {DAY_DATE} from '../../../design-system/constants/date-formats'

// Shared utils
import objectIsEmpty from '../../../shared/utils/objectIsEmpty';
import dateFormat from '../../../design-system/utils/dateFormat'

// styles
import { makeStyles } from '@material-ui/core/styles';

// SDK
import { EventService } from '@sdk-point/talisis';

//Mixpanel
import { trackEventMixPanel } from '../../../shared/components/Mixpanel/mixpanel';

// routes
import * as routerlinks from '../../../routes/routelinks';

// utils
import dateTimeFormat from '../../../shared/utils/dateTimeFormat';
import { userIsLogged } from '../../../shared/utils/Sessions';
import { log } from '../../../shared/utils/console.js'

// Shared constants
import { EVENT_ADD_HOUR } from '../../../shared/constants/event-add-hours';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';

const EventServiceSDK = new EventService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const RETURN_TO = [{label: 'Volver a Inicio', to: routerlinks.HOME_PATH}]

const processDateTime = (date, startTime, format) => dateTimeFormat(`${date.split("T")[0]}T${startTime.split("T")[1]}`, format, EVENT_ADD_HOUR);

const list = [
    {icon: 'ri-facebook-fill', text: 'Facebook'},
    {icon: 'ri-twitter-fill', text: 'Twitter'},
    {icon: 'ri-whatsapp-fill', text: 'Whatsapp'},
    {icon: 'ri-linkedin-box-fill', text: 'Linkedin'},
    {icon: 'ri-file-copy-fill', text: 'Copiar link'},
]

const registerOptions = [
    {id: 1, label: 'Asistiré'},
    {id: 2, label: 'Cancelar asistencia'},
]

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 417,
    },
    cardContent:{
        padding: 32,
    },
    listItem:{
        '& .MuiListItemIcon-root':{
            minWidth:8
        },
    },
});

const ShowEvent = () => {
    const user = useSelector((state) => state.userReducer.user);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isLogged = userIsLogged();
    const classes = useStyles();
    const { id } = useParams();
    const history = useHistory();

    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [toastProps, setToastProps] = React.useState({});
    const [registerEvent, setRegisterEvent] = React.useState(false);
    const [interestEvent, setInterestEvent] = React.useState({
        isInteresting: false, 
        personId: user.person_id, 
        eventId: id
    })

    React.useEffect(()=> {
        getEventDetail();
        eventTracker('s_detalle_evento', {}, ['mixpanel']);
    }, [])

    const getEventDetail = async() => {
        let response;
        try {
            if(isLogged){
                response = await EventServiceSDK.getEventDetail(id, {personId: user.person_id, companyId: user.company_id});
            }else{
                response = await EventServiceSDK.getEventDetail(id);
            }

            if(response.success){
                response.data.event.date = transformEventDate(response.data.schedules);
                setRegisterEvent(response.data.event.is_registered);
                setInterestEvent({...interestEvent, isInteresting: response.data.event.is_interested})
                setData(response.data);
                setLoading(false);
            }else{
                history.push(routerlinks.HOME_PATH)
            }

        } catch (error) {
            log('error', error); 
        }
    }

    const transformEventDate = (schedules) => {
        let days = [];
        let date = "";
        
        if(schedules.length > 1){
            for(const [index, s] of schedules.entries()){
                const d = moment(s.date, 'YYYY/MM/DD').add(EVENT_ADD_HOUR, 'hours');
                let day = d.format('D');
                days.push(day);
                date += `${ index === schedules.length - 1 ? ` y ${day}` : `${index > 0 ? `, ${day}` : day }` }`;
            }

            date = `${date} ${dateFormat(schedules[0].date, '[de] MMMM [del] YYYY', EVENT_ADD_HOUR)}`
            return date;
        }

        return dateFormat(schedules[0].date, DAY_DATE, EVENT_ADD_HOUR);
    } 

    const handleClickInterestEvent = async() => {
        const body = {...interestEvent};
        body.isInteresting = !body.isInteresting;
        setLoading(true)
        setInterestEvent(body);

        try {
            await EventServiceSDK.interestingEvent(body);
            setLoading(false);
            const eventData = {};
            eventData['sources'] = 'detalle_evento';
            eventTracker('guardar_evento', eventData, ['mixpanel']);
        } catch (error) {
            log('error', error); 
        }
    }

    const handleClickRegister = async(opt) => {
        const CANCEL_ID = registerOptions[1].id;    
        
        if(opt.id === CANCEL_ID){
            setLoading(true)
            try {
                await EventServiceSDK.cancelRegister(id, {
                    "personId": user.person_id
                });
                getEventDetail()
         trackEventMixPanel('usuario_registro_evento', {eventId:id,accion: 'Cancelación'});
            } catch (error) {
                log('error', error); 
            }
        }
    }

    const handleFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    };

    const handleRegisterSubmit = () =>{
        setOpenDialog(true);
        getEventDetail();
    }

    const goToHome = e =>{
        e.preventDefault();
        history.push(routerlinks.HOME_PATH)
    }

    return (
        <Box my={"22px"}>
            <Breadcrumb items={RETURN_TO}/>
            <Loader open={loading}/>
            <Grid container spacing={2} direction={'row'}>
                <Grid item md={8} xs={12}>
                    <Hidden smUp>
                        <Box display="flex" mb={4}>
                            <AvatarPerson initialName={data?.event?.author_name.charAt(0).toUpperCase()} authorName={data?.event?.author_name}/>
                        </Box>
                    </Hidden>
                    <EventCard event={data?.event} location={data?.location} isLogged={isLogged} user={user} registerEvent={registerEvent} optionDropdown={registerOptions} onClickRegister={handleClickRegister} onRegisterSubmit={handleRegisterSubmit} onClickInterestEvent={handleClickInterestEvent} interestEvent={interestEvent} isResponsive={isResponsive}/>
                    <EventDetail data={data} isResponsive={isResponsive}/>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Hidden xsDown>
                        <Box width="100%" mb={4}>
                            <Box display="flex" mb={4}>
                                <AvatarPerson initialName={data?.event?.author_name.charAt(0).toUpperCase()} authorName={data?.event?.author_name}/>
                            </Box>
                            <Box display="flex" gridGap={8}>
                                <ButtonAction registerEvent={registerEvent} optionDropdown={registerOptions} onClickRegister={handleClickRegister} isLogged={isLogged} user={user} onRegisterSubmit={handleRegisterSubmit} onClickInterestEvent={handleClickInterestEvent} interestEvent={interestEvent} isResponsive={isResponsive}/>
                            </Box>
                        </Box>
                    </Hidden>
                    <Box mb={2}>
                        <Card variant="outlined">
                            <CardContent>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <Icon className="ri-calendar-check-fill offset-margin-right-18" color="primary" fontSize="small"/>
                                    <Typography variant="h4">{data && data.event.date}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Icon className="ri-time-fill offset-margin-right-18" color="primary" fontSize="small"/>
                                    <Typography variant="h4">{data && `A las ${processDateTime(data.schedules[0].date, data.schedules[0].start_time, 'HH:mm')} hasta las ${processDateTime(data.schedules[0].date, data.schedules[0].end_time, 'HH:mm')}`}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    {
                        data && data.location.address &&
                        <Box mb={2}>
                            <Card variant="outlined">
                                <Box height={370}>
                                    <Maps 
                                        lat={parseFloat(data.location.lat_address)} 
                                        lng={parseFloat(data.location.lng_address)}
                                        styleProps={{ width: "100%", height:370 }}
                                    />
                                </Box>
                                <Box p={2}>
                                    <Typography variant="subtitle1" className="text-gray-100 mb-2">{data?.location.place}</Typography>
                                    <Typography variant="body2" className="text-dark-black-100">{data?.location.address}</Typography>
                                </Box>
                            </Card>
                        </Box>
                    }
                    {
                        data && data.shared && 
                        <Box>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="subtitle1">Compartir evento</Typography>
                                    <List dense={true}>
                                        {list.map( it =>
                                            <ListItem className={classes.listItem}>
                                                <ListItemIcon>
                                                    <Icon className={clsx(it.icon, 'offset-margin-right-22')} color="primary" fontSize="small"/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={it.text}
                                                    primaryTypographyProps={{
                                                        variant: 'body1'
                                                    }}
                                                />
                                            </ListItem>,
                                        )}
                                    </List>
                                </CardContent>
                            </Card>
                        </Box>
                    }
                </Grid>
            </Grid>
            <Dialog onClose={() => setOpenDialog(false)} className={classes.dialogSucces} open={openDialog} maxWidth="xs">
                <DialogTitle id="simple-dialog-title">
                    <Typography variant="h2" align="center" className="text-gray-100">Registrado!</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" align="center" className="text-gray-100">Hemos envíado a tu correo los detalles del evento, también lo podrás consultar desde tus eventos y visualizarlo en tu calendario.</Typography>
                </DialogContent> 
                <DialogActions className="justify-content-center">
                    {
                        isLogged && <Button variant="contained" color="primary" className="mt-3" onClick={goToHome}>Ir a mis eventos</Button>
                    }
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ShowEvent;