import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';

// core components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// style
import useStyles from '../../../../../assets/styles/jss/pages/events/myEventsStyle';

// components
import EventCard from '../../components/EventCard/EventCard';

// Shared components
import SlickCarousel from '../../../../../shared/components/SlickCarousel/SlickCarousel';
import NothingFound from '../../../../../shared/components/NothingFound/NothingFound';

// shared - images
import holdingCalendarSVG from '../../../../../assets/images/events/holding_calendar.svg';

const MyEvents = ({eventList, onClickInterestingEvent, isResponsive}) => {
    const classes = useStyles();

    const [eventRegisteredList, setEventRegisteredList] = useState([]);
    const [eventInterestingList, setEventInterestingList] = useState([]);
    const [isSearching, setIsSearching] = useState(true);

    useEffect(() => {
        getEventsRegistered();
        getEventsIntersting();
        setIsSearching(false);
    }, [eventList]);
        
    const getEventsRegistered = () => { 
        const data = eventList.filter(item => item.is_registered);
        setEventRegisteredList(data);
    };

    const getEventsIntersting = () => {
        const data = eventList.filter(item => item.is_interested);
        setEventInterestingList(data);
    };

    return (
        <>
            <Box className={classes.titleEventRegistered}>
                <Typography variant='h3'>Eventos a los que asistirás</Typography>
            </Box>
            {
                !!eventRegisteredList.length && !isResponsive && 
                        <Grid container spacing={2} direction="row">
                            {
                                eventRegisteredList.map((item, index) =>  (<Grid item xs={12} md={4} key={item.id}>
                                        <EventCard event={item} onClickInterestingEvent={onClickInterestingEvent} size={"large"}/>
                                    </Grid>)
                                )
                            }
                        </Grid>
            }
            
            {
                !!eventRegisteredList.length && isResponsive && 
                <SlickCarousel length={eventRegisteredList.length} typeCard="normal" className={classes.carruselContainer}>
                {
                    eventRegisteredList.map(event => 
                        <SwiperSlide className={classes.swiperSlide} key={event.id}>
                            <Suspense fallback={<div></div>}>
                                <EventCard event={event} onClickInterestingEvent={onClickInterestingEvent} size={"medium"}/>
                            </Suspense>
                        </SwiperSlide>
                    )
                }
                </SlickCarousel>
            }
                    
            {
                !eventRegisteredList.length && !isSearching &&
                    <Box>
                        <NothingFound caption={'Los eventos a los que hayas dado asistir aparecerán aquí.'} subcaption={""}/>                        
                    </Box>
            }

            <Box className={classes.titleEventInteresting}>
                <Typography variant='h3'>Eventos guardados</Typography>
            </Box>
            {
                !!eventInterestingList.length && !isResponsive && 
                        <Grid container spacing={2} direction="row">
                            {
                                eventInterestingList.map((item, index) =>  (<Grid item xs={12} md={4} key={item.id}>
                                        <EventCard event={item} onClickInterestingEvent={onClickInterestingEvent} size={"large"}/>
                                    </Grid>)
                                )
                            }
                        </Grid>
            }
            
            {
                !!eventInterestingList.length && isResponsive && 
                <SlickCarousel length={eventInterestingList.length} typeCard="normal" className={classes.carruselContainer}>
                {
                    eventInterestingList.map(event => 
                        <SwiperSlide className={classes.swiperSlide} key={event.id}>
                            <Suspense fallback={<div></div>}>
                                <EventCard event={event} onClickInterestingEvent={onClickInterestingEvent} size={"medium"}/>
                            </Suspense>
                        </SwiperSlide>
                    )
                }
                </SlickCarousel>
            }
                    
            {
                !eventInterestingList.length && !isSearching &&
                <Box>
                    <NothingFound caption={'Los eventos a los que hayas dado asistir aparecerán aquí.'} subcaption={""}/>
                </Box>
            }
        </>)
};

MyEvents.propTypes = {
    eventList: PropTypes.array.isRequired,
    onClickInterestingEvent: PropTypes.func.isRequired,
    isResponsive: PropTypes.bool,
};

export default MyEvents;