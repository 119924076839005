import React from 'react';
import { useSelector } from 'react-redux';

import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import {
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
    Grid,
    CircularProgress,
    Box,
    Link,
} from '@material-ui/core';
import { HCClasses } from './HealthCheckHelpers/HealthCheckClasses';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, Warning, ExpandMore, Add, Info } from '@material-ui/icons';
import SimpleTabs from '../../../shared/components/Tabs/Tabs';
import { ACTIONS } from './HealthCheckHelpers/HealthCheckActions';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import UserData from './HealthCheckSections/UserData';
import UserEmails from './HealthCheckSections/UserEmails';
import UserRoles from './HealthCheckSections/UserRoles';
import UserEduconCourses from './HealthCheckSections/UserEduconCourses';
import UserAllyCourses from './HealthCheckSections/UserAllyCourses';
import UserPrograms from './HealthCheckSections/UserPrograms';
import UserNeoData from './HealthCheckSections/UserNeoData';
import UserAllyAccess from './HealthCheckSections/UserAllyAccess';
import UserFixClassesNeo from './HealthCheckSections/UserFixClassesNeo';
import UserAllysClassesInfo from './HealthCheckSections/UserAllysClassesInfo';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import { ACC_UNIF_REQ_STATUS } from './HealthCheckHelpers/HealthCheckAccUnifReqStatus';
import Toast from '../../../shared/components/Toast/Toast';

import { HealthCheckService } from '@sdk-point/talisis';
import * as UserServices from '../../../services/UserService';
const HealthCheckServiceSDK = new HealthCheckService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => (HCClasses(theme)));

const HealthCheck = (props) => {
    const classes = useStyles();
    const { user: sessionUser } = useSelector((state) => state.userReducer);
    const items = [
        { to: '/Home', label: 'Health check', title: '' },
        { to: '', label: 'Usuarios', title: '' }
    ];

    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [activeTab, setActiveTab] = React.useState(0);
    const [userData, setUserData] = React.useState([]);
    const [canReadWrite, setCanReadWrite] = React.useState(false);
    const [diagnostic, setDiagnostic] = React.useState([]);
    const [unificationStatus, setUnificationStatus] = React.useState('No aplica');
    const [emailsLoading, setEmailsLoading] = React.useState(false);
    const [fixClassesIsLoading, setFixClassesIsLoading] = React.useState(false);
    const initialAlertProps = {
        open: false,
        severity: 'success',
        message: '',
        duration: 5000,
    };
    const [alertProps, setAlertProps] = React.useState(initialAlertProps);
    const neoRoles = ['administrator', 'assistant', 'mentor', 'monitor', 'student', 'teacher'];

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const restoreData = () => {
        setUserData([]);
        setDiagnostic([]);
        setUnificationStatus('No Aplica');
        setAlertProps(initialAlertProps);
    };

    const handleSearchEmail = async (loading = true) => {
        if (loading) {
            restoreData();
            setLoading(true);
        }

        let finalEmail = email.trim();
        console.log({ finalEmail });
        if (finalEmail) {
            // Datos del usuario:
            let response = await HealthCheckServiceSDK.invoke(ACTIONS.TALISIS_USER_INFO, { email: finalEmail });
            console.log(response)
            if (response && response.data && response.data.length > 0) {
                response.data = response.data.filter((data, index) => (index === response.data.findIndex(d => data.id === d.id)));
                if (response.data.length > 1) {
                    console.log('Solicitud de unificación de cuentas');
                    const person_ids_to_unification = [];
                    for (let i = 0; i <= response.data.length; i++) {
                        if (response.data[i] && response.data[i]?.id) {
                            person_ids_to_unification.push(response.data[i].id);
                        }
                    }
                    if (response.data[0]) {
                        if (!response.data[0]?.acc_unif_req_status) {
                            await HealthCheckServiceSDK.invoke(ACTIONS.ACCOUNT_UNIFICATION_REQUEST, { person_id_request: sessionUser.person_id, person_ids_to_unification, email_to_unification: finalEmail });
                            setUnificationStatus(ACC_UNIF_REQ_STATUS['Pending']);
                        }
                        else if (response.data[0]?.acc_unif_req_status) {
                            setUnificationStatus(ACC_UNIF_REQ_STATUS[response[0].acc_unif_req_status]);
                        }
                    }
                }

                console.log({ responseData: response.data });
                setUserData(response.data);
                setDiagnostic(response.logs);
            }
        }
        if (loading) {
            setLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearchEmail();
        }
    };

    const handleUserNeoArchived = async (archived, neo_user_id, company_id) => {
        const archivedResponse = await HealthCheckServiceSDK.invoke(ACTIONS.ARCHIVE_NEO_USER, { archived, neo_user_id, company_id })
            .then(response => {
                if (response.reactivated === 1) {
                    setAlertProps({ ...initialAlertProps, open: true, severity: 'success',  message: 'Se ha reactivado el usuario correctamente.' });
                }
                else if (response.reactivated === 0) {
                    setAlertProps({ ...initialAlertProps, open: true, severity: 'error', message: `No se pudo reactivar el usuario: ${JSON.stringify(response)}` });
                }

                return response;
            })
            .catch(error => {
                setAlertProps({ ...initialAlertProps, open: true,  severity: 'error', message: `Ha ocurrido un error al reactivar el usuario en NEO: ${error}.` });
            });

        return archivedResponse;
    };

    const handleUpdatePrincipalEmail = async (personIndex, personId, emailId, newEmail) => {
        setEmailsLoading(true);
        const _userData = JSON.parse(JSON.stringify(userData));
        if (newEmail) {
            await HealthCheckServiceSDK.invoke(ACTIONS.UPDATE_USER_EMAIL, { "person_id": personId, "email_id": emailId, "email": newEmail });
        }
        const updatedEmails = await HealthCheckServiceSDK.invoke(ACTIONS.SET_USER_PRINCIPAL_EMAIL, { "person_id": personId, "id": emailId });
        _userData[personIndex].emails = updatedEmails;
        setUserData(_userData);
        setEmailsLoading(false);
    }

    const handleFixClassInNeo = async (index, person, courseData, level_id) => {
        setFixClassesIsLoading(true);
        await HealthCheckServiceSDK.invoke(ACTIONS.FIX_CLASS_NEO, {
            body: {
                "course_id": courseData.course_id,
                "course_session_id": courseData.course_session_id,
                "company_id": courseData.company_id,
                "level_id": level_id,
                "source_id": courseData.source_id,
                "person_id": person.id,
                "first_name": person.first_name,
                "last_name": person.last_name,
                "email": email,
                "matricula": person.user_id,
                "pidm": person.external_id
            }
        })
            .then(result => {
                const severity = result.errors.length > 0 ? 'error' : 'success'; 
                const message = result.errors.length > 0 ? result.errors.join(', ') : 'Reparación aplicada correctamente';
                if (result.success) {
                    setAlertProps({ ...initialAlertProps, open: true, severity, message });
                    if (severity === 'success') {
                        handleSearchEmail(false);
                    }
                }
                else {
                    setAlertProps({ ...initialAlertProps, open: true, severity, message });
                }
            })
            .catch(error => {
                setAlertProps({ ...initialAlertProps, open: true, severity: 'error', message: error.message });
            });
        setFixClassesIsLoading(false);
    };

    React.useEffect(() => {
        const canReadWrite = UserServices.canReadWrite(props.pageId);
        setCanReadWrite(canReadWrite);
    }, []);

    return (
        <React.Fragment>
            <Breadcrumb items={items} />
            <Box className={`${classes.emailInputContainer} mt-5 mb-5`}>
                <TextField
                    type="text"
                    label="Correo electrónico"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={email}
                    onChange={handleChangeEmail}
                    onKeyDown={handleKeyDown}
                    placeholder="Escribe aquí..."
                />
                <Button variant="contained" color="primary" onClick={handleSearchEmail} className="mt-2 ml-2" size="medium">Buscar</Button>
            </Box>
            {loading ?
                <Box className={classes.loading}>
                    <Typography component="div"><CircularProgress /></Typography>
                    <Typography component="div" variant="caption">Espera un momento porfavor.<br />Cargando información...</Typography>
                </Box>
                :
                <React.Fragment>
                    <Grid container spacing={1} className="mb-4">
                        {diagnostic && diagnostic.length > 0 && diagnostic.map((diagnosticData, i) => (
                            <Grid item xs={12} key={`grd_diagnostic_msg_${i}`}>
                                <Grid item xs={12} className={classes.valign_center}>
                                    {diagnosticData.ok === true && diagnosticData.severity === 'success' && <CheckCircle className={classes.green} />}
                                    {diagnosticData.ok === true && diagnosticData.severity === 'info' && <Info className={classes.blue} />}
                                    {diagnosticData.ok === false && diagnosticData.severity === 'error' && <Warning className={classes.red} />}
                                    {diagnosticData.ok === false && diagnosticData.severity === 'warning' && <Warning className={classes.yellow} />}
                                    <Typography variant="body2" className={`ml-2 ${diagnosticData.ok ? '' : 'bold'}`}>{diagnosticData.text}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    {userData && userData.length > 0 ?
                        userData.map((data, index) => (
                            <fieldset className={`mb-3 ${classes.fieldset}`} key={`fs_user_data_${data.id}`}>
                                <legend>{data.id}</legend>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography variant="subtitle2">Información básica del usuario</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <UserData data={data} />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography variant="subtitle2">Correos electrónicos</Typography>
                                    </AccordionSummary>
                                    <LoaderContent loading={emailsLoading}>
                                        <AccordionDetails>
                                            <Grid container spacing={3} className="mt-2">
                                                {data.emails && data.emails.length > 0 && data.emails.map(email => (
                                                    <UserEmails key={`email_${email.id}`} data={email} index={index} classes={classes} onClick={handleUpdatePrincipalEmail} />
                                                ))}
                                            </Grid>
                                        </AccordionDetails>
                                    </LoaderContent>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography variant="subtitle2">Roles</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1}>
                                            {canReadWrite && <Grid item xs={12} key={`grd_role_new`} className={classes.textAlignRight}>
                                                <Link onClick={() => props.history.push(`/usuarios/${data.id}/roles`)} className={classes.valign_center}>
                                                    <Add color="secondary" /><Typography variant="body2" color="secondary">Nuevo rol</Typography>
                                                </Link>
                                            </Grid>}
                                            {data.roles && data.roles.length > 0 ?
                                                data.roles.map(role => (
                                                    <UserRoles key={`role_${data.id}_${role.id}`} data={role} />
                                                ))
                                                :
                                                <Typography component="div" color="primary">Usuario no tiene roles asignados.</Typography>}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography variant="subtitle2">Información de cursos y programas en progreso</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={3} className="mt-2">
                                            {data.courses_educon && <UserEduconCourses data={data.courses_educon} classes={classes} />}
                                            {data.courses_allys && <UserAllyCourses data={data.courses_allys} classes={classes} />}
                                            {data.programs && data.programs.programs && data.programs.programs.length > 0 && <UserPrograms data={data.programs.programs} classes={classes} />}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography variant="subtitle2">Información de acceso a alianzas</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box className={classes.displayBlock}>
                                            {data.neo && data.neo.length > 0 && <UserNeoData data={data.neo} roles={neoRoles} classes={classes} onClick={handleUserNeoArchived} />}
                                            <UserAllyAccess data={data.allys_connect} classes={classes} />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </fieldset>
                        ))
                        :
                        <NothingFound caption="Sin información para mostrar." subcaption="Intenta la búsqueda con un correo válido." />}
                    {userData && userData.length > 0 &&
                        <SimpleTabs activeTab={activeTab} tabs={['Unificación de cuentas', 'Enrolamiento de clases NEO', 'Clases de aliados / Acceso URL']} onChange={(index) => { setActiveTab(index) }} className={classes.tabs}>
                            {activeTab === 0 && <Typography component="div" className="ml-2">Estado de solicitud:<Typography component="div" color="primary">{unificationStatus}.</Typography></Typography>}
                            {activeTab === 1 &&
                                <LoaderContent loading={fixClassesIsLoading}>
                                    <UserFixClassesNeo data={userData} classes={classes} onClick={handleFixClassInNeo} />
                                </LoaderContent>}
                            {activeTab === 2 && <UserAllysClassesInfo data={userData} classes={classes} onClick={handleFixClassInNeo} />}
                        </SimpleTabs>}
                </React.Fragment>}
            <Toast {...alertProps} onFinished={() => { setAlertProps({ ...alertProps, open: false, message: '' }); }} />
        </React.Fragment>
    )
};

export default HealthCheck;