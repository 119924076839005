import React from 'react';


import { makeStyles, withStyles } from '@material-ui/core/styles';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

// shared
import dateFormat from '../../../../../design-system/utils/dateFormat';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';


import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useRowStyles = makeStyles({
    avatar: {
        width: "50px",
        height: "50px",
        marginRight: "8px"
    },
    upper:{
        textTransform: "uppercase"
    },
    name:{
        textAlign: "start",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "1",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        marginLeft: "10px",
        '&:hover':{
            //cursor: "pointer",
        }
    }
});

const LightTooltip = withStyles((theme) => ({
    arrow: {
        color: '#2C3236',
    },
    tooltip: {
        backgroundColor: '#2C3236',
        color: '#EBEBEB',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        maxWidth: '350px',
        padding: '8px'
    },
}))(Tooltip);

const Row = ({ row, user, getPrograms, setToastProps, setLoading }) => {
    const classes = useRowStyles();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell align="center">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Avatar className={classes.avatar} variant="square" alt={row.name} src={row.image_url} />
                        <LightTooltip describechild="true" title={row.name} arrow placement="bottom-start">
                            <Typography className={classes.name} variant="subtitle2">{row.name}</Typography>
                        </LightTooltip>
                    </Box>
                </TableCell>
                <TableCell align="center"><Typography className={classes.upper} variant="body1">{row.company_name}</Typography></TableCell>
                <TableCell align="center"><Chip className='bg-violet-300' size="small" label={row.learning_style_name==null?"No definido":row.learning_style_name} /></TableCell>
                <TableCell align="center">{dateFormat(row.last_updated)}</TableCell>  
                <TableCell align='right' width="5%">
                    <Box>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={openMenu ? 'long-menu' : undefined}
                            aria-expanded={openMenu ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick} 
                            key={row.id+"button"}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
};

export default Row;