import React from 'react';
// styles
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden";

import clsx from 'clsx';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    logo_academia:{
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            maxWidth: 37,
        }
    },
    root:{
        padding: 0,
            '& .card-content':{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.colors.darkBlack[100],
            '&:hover':{
                cursor: "pointer",
                background: theme.palette.colors.electricBlue[300],
                border: `0.5px solid ${theme.palette.colors.electricBlue[300]}`,
                color: theme.palette.white
            },
        },
    '&.Mui-disabled':{
        background: theme.palette.colors.fillStroke[500],
        opacity: '.4'
    },
    }
}));


const CardAcademia = ({ src, title, handleClick, id, className, disabled=false, variantCard='outlined' }) => {
    const classes = useStyles();
    const [titleShow, setTitleShow] = React.useState("");

    React.useEffect(() => {
        setTitleShow(title.replace(/\w\S*/g,function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}));
    }, []);

    return (
            <Button disabled={disabled} onClick={(e) => handleClick(e, id)} className={clsx(classes.root, className)}>
                { src && 
                    <React.Fragment>
                        <Card variant={variantCard} className="card-content">
                            <img loading="lazy" alt="logo-aliados" src={src} className={classes.logo_academia} />
                            <Hidden smDown>
                                <Typography className="variant-subheading-4" align="center" color="inherit">{titleShow}</Typography>
                            </Hidden>
                            <Hidden mdUp>
                                <Typography variant="button" className="text-dark-black-100">{titleShow}</Typography>
                            </Hidden>
                        </Card>
                    </React.Fragment>
                }
            </Button>
    );
};


export default CardAcademia;