// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// styles
const drawerWidth = 350;

export default makeStyles((theme) => ({
    root: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    drawerPaper: {
        width: drawerWidth,
        boxShadow: '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
        background: theme.palette.colors.fillStroke[500],
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 4),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
        // height: 80,
        height: props=> props.isLogged ? 65 : 80,
        background: theme.palette.colors.darkBlack[500],
        '& .MuiIconButton-root':{
            color: theme.palette.primary.main
        },
        [theme.breakpoints.down('sm')]: {
            height: '65px !important',
        },
    },
    paperAnchorDockedLeft:{
        borderRight: 'unset'
    },
    link:{
        width: 'auto',
        margin: '0 20px',
        display: 'block',
        padding: '8px 15px',
        position: 'relative',
        backgroundColor: 'transparent'
    },
    logoutButton:{
        marginTop: 8,
        padding: '8px 0',
        fontWeight: 300,
        lineHeight: '1.5em',
        fontSize: 14,
        color: theme.palette.white,
        display: 'flex',
        alignItems: 'center',
        '& .icon':{
            marginRight: 15,
            color: '#808080'
        },
    },
    logginButtons:{
        display: 'flex',
        justifyContent: 'center',
        padding: '32px 0',
        gap: 20
    }
}));