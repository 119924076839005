import React from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

// components
import CourseCard from '../CourseCard/CourseCard';

// shared
import NothingFound from "./../../../../shared/components/NothingFound/NothingFound";
import { log } from '../../../../shared/utils/console.js'

const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#049CB4"),
        fontWeight: "bold",
        textTransform: "capitalize",
        backgroundColor: "#00C1DE",
        '&:hover': {
            backgroundColor: "#049CB4",
        },
    },
}))(Button);

const PurchasedCourses = (props) => {
    const history = useHistory();
    const { neoCourses, thirdPartyCourses, userIdNeo, user } = props;

    let neoLink = "";

    switch (user.ou) {
        case 'unid':
            neoLink = `//unid.neolms.com/class/show/`;
            break;
        case 'talisis':
            neoLink = `//talisisch.neolms.com/class/show/`;
            break;
        default: //uerre
            neoLink = `//talisis.neolms.com/class/show/`;
            break;
    }

    const handleGoToCatalog = () => {
        history.push("/");
    };

    const purchasedCoursesNeo = neoCourses.filter(c => c.order_id) ?? [];
    const purchasedCoursesThirdParty = thirdPartyCourses.filter(c => c.order_id) ?? [];
   /* log("NEO:");*/
    log(purchasedCoursesNeo);
    log(purchasedCoursesThirdParty);
    return (
        
        <React.Fragment>
             {purchasedCoursesNeo.length > 0 || purchasedCoursesThirdParty.length > 0 ?
                <Grid container spacing={3}>
                    {
                        purchasedCoursesNeo.map(el => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={el.id}>
                                    <CourseCard
                                        {...el}
                                        progress={el.progress || el.progress_percentage}
                                        source={el.company_id || user.ouLabel}
                                        subject={el.register_id ? "":el.subject_description}
                                        to={el.register_id ? "course-s":(el.course_url ?? `${neoLink}${el.id}?user=${userIdNeo}`)}
                                        registerId={el.register_id}
                                        courseId={el.id}
                                        userId={user.person_id}
                                    />
                                </Grid>
                            )
                        })
                    }
                    {
                        purchasedCoursesThirdParty.map(el => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={el.id}>
                                    <CourseCard
                                        {...el}
                                        progress={el.progress || el.progress_percentage}
                                        source={el.company_id || user.ouLabel}
                                        subject={el.subject_description}
                                        to={`${neoLink}${el.id}?user=${userIdNeo}`}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid> :
                <Box textAlign="center">
                    <NothingFound
                        caption="Todavía no has comprado ningún curso."
                        subcaption="Empieza explorando nuestro catálogo donde puedes elegir las clases que mejor se acomoden a tu plan de estudios o contacta a nuestro departamente de admisiones en:"
                        type="purchased-courses" />
                    <BlueButton onClick={handleGoToCatalog}>Ver catálogo de cursos</BlueButton>
                </Box>
            }
        </React.Fragment>
    );
};

export default PurchasedCourses;