import React from 'react';
import ReactDOM from 'react-dom';

// redux
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import reducers from './redux/reducers';
import reduxThunk from 'redux-thunk';
import { msalConfig } from './config/authConfig';

// styles
import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import 'remixicon/fonts/remixicon.css'

import App from './App';

import '@sdk-point/talisis/lib/core/interceptors/axios'

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(reduxThunk),
);

const store = createStore(
	reducers,
	enhancer
);

export { store };

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

