import React from 'react';
import { useHistory } from 'react-router-dom'

import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import MuiLinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from "@material-ui/core/Hidden";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

//components
import { OnCardPronoun } from './OnboardingCards/OnCardPronoun';
import OnCardCourseModality from './OnboardingCards/OnCardCourseModality';
import OnCardAcademy from './OnboardingCards/OnCardAcademy';
import OnCardInterests from './OnboardingCards/OnCardInterests';
import OnCardFinalize  from './OnboardingCards/OnCardFinalize';

// shared
import Loader from '../../shared/components/Loader/LoaderContent'
import Toast from '../../shared/components/Toast/Toast';
import { log } from '../../shared/utils/console.js'

import TalisisSVG from '../../assets/images/logos/new_logotipo-talisis.svg';

import { OnboardingService, TourService } from '@sdk-point/talisis';
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/userActions';

import { eventData, eventTracker } from '../../shared/components/EventTracker/EventTracker';


const OnboardingServiceSDK = new OnboardingService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const TourServiceSDK = new TourService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const ACADEMIES_CARD = 1;


const LinearProgress = withStyles((theme) => ({
    root: {
        height: 8,
        borderRadius: 16,
    },
    colorPrimary: {
        backgroundColor: theme.palette.colors.fillStroke[200],
    },
    bar: {
        borderRadius: 16,
        backgroundColor: theme.palette.colors.violet[500],
    },
}))(MuiLinearProgress);

const useStyles = makeStyles((theme) => ({
    backdrop: {
        overflowY: "auto",
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.black,
    },
    steps:{
        ...theme.typography.v1,
        color: theme.palette.colors.fillStroke[100],
    },
    bttnBack:{
      color: theme.palette.colors.fillStroke[100],
    },
    logoTalisis:{
        width: '126.68px',
        height: 32
    }
}));

const Onboarding = (props) => {
    const { user } = props;
    const classes = useStyles();
    const history = useHistory();

    const [form, setForm] = React.useState({'person_id': user.person_id});
    const [onCard, setOnCard] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [academySelect, setAcademySelect] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    //info Catalagos
    const [skillHierarchy, setSkillHierarchy] = React.useState([]);
    const [catModality, setCatModality] = React.useState([]);
    const [listModality, setListModality] = React.useState([]);
    //info intereses seleccionados
    const [interestSelect, setInterestSelect] = React.useState([]);
    const [toastProps, setToastProps] = React.useState({ message: '' })
  
    React.useEffect(() => {
        setLoading(true)
        getOnboardingCatModality();
        getOnboardingSkillHierarchy();
        setLoading(false);
        getFirstTime(user.person_id);
    }, [user]);

    const getFirstTime = async (personId) =>{
        try {
            const response = await TourServiceSDK.getifItsFirstTime(personId);
            setOpen(response.tour_initial)
        } catch (error) {
            setLoading(false)
            setToastProps({ severity: "error", open: true, message: "Ops! ha ocurrido un error." });
        }
    }
  
    const handleSetOnCard = async (onCard, interest = 0) => {
        setOnCard(onCard);

        if(onCard === ACADEMIES_CARD){
            setAcademySelect([])
            setInterestSelect([])
        }

        if(interest == 1){
          await filterUnique()
        }
    };
  
    // apertura de cards visualizacion
    const handleClose = async ()  => {
        setLoading(true);
        await postOnboardingInfo();
        eventTracker('finalizar_ob',eventData, ['mixpanel']);
        setOpen(false);
        setLoading(false);
    };
  
    //cambios de cards por medio de componentes
    const cards = (onCard) => {
      switch (onCard) {
          case 1: // Card Academias.
              return <OnCardAcademy onSetOnCard={handleSetOnCard} skillHierarchy={skillHierarchy} handleChange={handleChangeInterest} eventData={eventData} eventTracker={eventTracker}/>
          case 2: // Card Intereses.
              return <OnCardInterests  onSetOnCard={handleSetOnCard} skillHierarchy={skillHierarchy} handleChange={handleChangeInterest} interestSkill={academySelect} eventData={eventData} eventTracker={eventTracker}/>
          case 3: // Card selecionar modalidad de estudio
            return <OnCardCourseModality onSetOnCard={handleSetOnCard} form={form} handleChange={handleChange} catModalityList={listModality} onClickClose={handleClose} eventData={eventData} eventTracker={eventTracker}/>
        //   case 4: // Forma para añadir estudios
        //       return <OnCardFinalize  onClickClose={handleClose} loading={loading}/>
          default: //Card Inicial
              return <OnCardPronoun onSetOnCard={handleSetOnCard} form={form} handleChange={handleChange} userName={user.userName} eventData={eventData} eventTracker={eventTracker}/>
      }
    }
  
    //data info and save
    const handleChange = (e) => {
        let { name, value } = e.target;
    
        if (name == "modality_id") {
            let modality = catModality.filter(lm => lm.id == value);
            form.learning_mode_preference_id = modality[0].learning_mode_preference_id;
            form.preferred_modality = modality[0].preferred_modality;
        }
    
        setForm(prev => { return { ...prev, [name]: value } });
    };
  
    const handleChangeInterest = (id, isExist, card, ist) => {
        let name = 'curseSkill_ids';
        let _interestSelect = [...interestSelect]
        //let filterselect;
        if (isExist){
            if (ist == 3){
            setInterestSelect(_interestSelect);
            }else{
            _interestSelect = _interestSelect.filter(is => is.id !== id)
            setInterestSelect(_interestSelect);
            }
        }else{
            let itemobj = {
            "id": id,
            "is_favorite": 0
            }
            _interestSelect.push(itemobj);
            setInterestSelect(_interestSelect);
        }
    
        if (card == 1){
            setAcademySelect(_interestSelect);
        }
        setForm(prev => { return { ...prev, [name]: _interestSelect } });
    }
  
    //test info catalagos
    const getOnboardingCatModality = async () => {
        const resultCatModality = await OnboardingServiceSDK.getOnboardingCatModality();
        setCatModality(resultCatModality);
        setListModality(resultCatModality)
    } 
  
    const getOnboardingSkillHierarchy = async () => {
        const resultSkillHierarchy = await OnboardingServiceSDK.getOnboardingSkillHierarchy();
        setSkillHierarchy(resultSkillHierarchy);
    }
  
    const postOnboardingInfo = async () => {
        try {
            await OnboardingServiceSDK.registerPersonOnboardingRel(form);
            props.setInterests(form.curseSkill_ids.map(it => it.id));
            history.push("/profile");
        } catch (error) {
            log('error', error)
        }
    }
  
    const filterUnique = async () => {
        let name = 'curseSkill_ids';
        let _interestSelect = [...interestSelect]
        let result = [];
        _interestSelect.forEach((item) => {
            if (!result.some( e => e.id == item.id)){
            result.push(item);
            }
        })
        _interestSelect = result
        setInterestSelect(_interestSelect);
    
        setForm(prev => { return { ...prev, [name]: _interestSelect } });
    }

    const handleFinishedToast = () => {
        setToastProps(prev => {
            return {
                ...prev, open: false
            }
        });
    };

    return (
        <Backdrop className={classes.backdrop} open={open}>  
            <Container fixed style={{height: '100%'}}>
                <Loader loading={loading} transparent={true}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} className="pb-0 pt-4">
                            <Hidden smDown implementation="css">                            
                                <img src={TalisisSVG} className={classes.logoTalisis}/>
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} md={8} lg={6}>
                            <Box pb="49px">
                                <Box display="flex" alignItems="center" mb="10px">
                                    <Box width="45%">
                                        <Hidden smDown implementation="css">
                                        {
                                            Boolean(onCard) &&
                                            <Button className={classes.steps} startIcon={<ChevronLeftIcon />} onClick={() => handleSetOnCard(onCard - 1)}>
                                            Regresar
                                            </Button>
                                        }
                                        </Hidden>
                                    </Box>
                                    <Typography className={classes.steps} align="center">{onCard + 1} / 4</Typography>
                                </Box>
                                <LinearProgress variant="determinate" value={(25 * (onCard + 1))} />
                                <Box>
                                    {cards(onCard)}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Toast {...toastProps} onFinished={handleFinishedToast}/>
                </Loader>
            </Container>
        </Backdrop>
        //Backdrop
    );
};

// const mapStateToProps = ({tourInitialReducer, userReducer}) => ({
//     ...tourInitialReducer,
//     ...userReducer
// });

export default connect(null, { ...userActions})(Onboarding);
