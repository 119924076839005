import React from 'react';
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

// styles
import useStyles from '../../../../../assets/styles/jss/pages/market/PaymentConfirmation';

// utils
import { PRODUCT_TYPE, PAYMENT_METHOD } from '../../../../../shared/constants/orders';

// components
import StudentInfo from './components/StudentInfo';
import PaymentMethod from './components/PaymentMethod';
import OrderSummary from './components/OrderSummary';
import HelpIcon from './components/HelpIcon';

// shared
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';

const OrderDetail = ({ order, product, membership, downloadReferences }) => {
    const classes = useStyles();

    const printOrder = (e) => {
        e.preventDefault();
        downloadReferences();
        handlePrintOrderEventTrack();
    };

    const handlePrintOrderEventTrack = () => {
        let paymentMethod = "";
        if (order?.payment?.payment_method === PAYMENT_METHOD.SPEI) paymentMethod = "Transferencia";
        else if (order?.payment?.payment_method === PAYMENT_METHOD.CASH) paymentMethod = "Efectivo";
        else if (order?.payment?.payment_method === PAYMENT_METHOD.CARD) {
            const conektaPaymentMethod = order?.payment?.payment_reference?.conekta?.charge?.payment_method;
            if (conektaPaymentMethod?.type === "debit") paymentMethod = "TDD";
            if (conektaPaymentMethod?.type === "credit") paymentMethod = "TDC";
        }
        const eventData = {};
        eventData['metodoPago'] = paymentMethod;
        eventTracker('imprimir_ficha', eventData, ['mixpanel']);
    };

    const getPagoMensual = () => {
        let pagoMensual = 0;

        if (product.product_type_id === PRODUCT_TYPE.MEMBERSHIP) {
            pagoMensual = (order?.subtotal / product?.membership_plan_months);
        } else {
            pagoMensual = (order?.subtotal / membership?.membership_plan_months);
        }

        return pagoMensual;
    }

    return (
        <Box>
            <Typography variant="h3" className={clsx("mb-2", "text-white")}>Detalle de tu compra</Typography>
            <Box display="flex" flexDirection="row" mb="8px">
                <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>
                    {product?.product_type_name}:&nbsp;
                    <Typography variant="h4" className={clsx("text-gray-400")} component={Box} display="inline">{`${product?.name}`}</Typography>
                </Typography>
            </Box>
            {membership && (
                <Box display="flex" flexDirection="row" mb="8px">
                    <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>
                        Membresía:&nbsp;
                        <Typography variant="h4" className={clsx("text-gray-400")} component={Box} display="inline">{`${membership.membership_name}`}</Typography>
                    </Typography>
                </Box>
            )}
            <Box display="flex" flexDirection="row" mb="24px">
                {
                    product.product_type_id !== PRODUCT_TYPE.COURSE ?
                        <Box display="flex" flexDirection="row">
                            <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>Pago mensual: &nbsp;</Typography>
                            <Typography variant="h4" className={clsx("text-gray-400")}><CurrencyFormat variant="h5" className="text-gray-400" value={getPagoMensual()} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="" suffix=' MXN' /></Typography>
                            <Typography variant="body2" className={clsx("text-gray-400", "ml-2", classes.includeVat)}>(IVA incluido)</Typography>
                            <HelpIcon product={product} />
                        </Box> :
                        <Box display="flex" flexDirection="row">
                            <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>Pago: &nbsp;</Typography>
                            <Typography variant="h4" className={clsx("text-gray-400")}><CurrencyFormat variant="h5" className="text-gray-400" value={order?.total} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="" suffix=' MXN' /></Typography>
                            <Typography variant="body2" className={clsx("text-gray-400", "ml-2", classes.includeVat)}>(IVA incluido)</Typography>
                        </Box>
                }
            </Box>
            <Box mb="16px">
                <StudentInfo order={order} />
            </Box>
            {order?.payment?.payment_method !== PAYMENT_METHOD.FREE && (
                <Box mb="16px">
                    <PaymentMethod order={order} />
                </Box>
            )}
            <Box mb="16px">
                <OrderSummary order={order} product={product} membership={membership} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" p="8px">
                <Typography variant="body2" className='text-white semi-bold p-2 mb-2'>Descarga o imprime tu recibo</Typography>
                <Box>
                    <IconButton size='medium' className={classes.buttonPrint} onClick={event => printOrder(event)}>
                        <Icon fontSize='small' className="ri-printer-cloud-line text-white"></Icon>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default OrderDetail;