import { 
    UERRE_IMAGES_PLACEHOLDER, 
    UNID_IMAGES_PLACEHOLDER,
    PROGRAMS_IMAGES_PLACEHOLDER,
    IMAGE_DEFAULT_PLACEHOLDER,
    ALIADOS_IMAGES_PLACEHOLDER,
    LOGOS_RESPONSIVE,
    LOGO_IMAGES,
    LOGO_IMAGES_CARDS_SIZES_CONTENT,
    LOGO_IMAGES_CARDS_SIZES_MEDIA,
    LANDING_BRAND_IMAGES,
} from '../constants/source-images';
import {
    SOURCE_NAME
} from '../constants/sources'

const getGeneric = () => {
    return IMAGE_DEFAULT_PLACEHOLDER;
}

export const LOGO_SOURCE = {
    BANNER: 1,
    ADVENIO: 3,
    TALISIS: 7,
    UERRE: 8,
    UNID: 9,
    HH: 10,
    IESALUD: 11,
    EASYGENERATOR: 20,
}

export const convertSourceToImageResource = (company_id, source_id) => {
    if (source_id == 1 || source_id == 8 || source_id == 9 || source_id == 10 || source_id == 20) {
        if (company_id == "UERRE" || source_id == 8){
            return UERRE_IMAGES_PLACEHOLDER[Math.floor(Math.random() * UERRE_IMAGES_PLACEHOLDER.length)];
        } 
        if (company_id == "UNID" || source_id == 9){
            return UNID_IMAGES_PLACEHOLDER[Math.floor(Math.random() * UNID_IMAGES_PLACEHOLDER.length)];
        } 
        if (company_id == "HH" || source_id == 10){
            return  getGeneric();
        }
    }else{
        if(ALIADOS_IMAGES_PLACEHOLDER[source_id]){
            return ALIADOS_IMAGES_PLACEHOLDER[source_id];
        }else{
            return IMAGE_DEFAULT_PLACEHOLDER;
        }
        
    }
}

export const getProgramPlaceholderByType = (type) => {
    return PROGRAMS_IMAGES_PLACEHOLDER[type];
}

export const getLogoCard = (source_id, responsive = false) => {
    if(responsive){
        return LOGOS_RESPONSIVE[source_id];
    }

    return LOGO_IMAGES[source_id];
}

export const getLogoCardSizeMedia = (source_id) => {
    return LOGO_IMAGES_CARDS_SIZES_MEDIA[source_id];
}

export const getLogoCardSizeContent = (source_id) => {
    return LOGO_IMAGES_CARDS_SIZES_CONTENT[source_id];
}

export const getLogoClass = (source_id) => {
    return SOURCE_NAME[source_id] ? `logo-${SOURCE_NAME[source_id]}` : 'logo-default';
}

export const getLogoCardWithSourceAndCompany = (source_id, company_id, responsive = false) => {
    let logoId = source_id;
    
    if (!!company_id && (source_id === LOGO_SOURCE.BANNER || source_id === LOGO_SOURCE.EASYGENERATOR)) {
        if (company_id === "UERRE"){
            logoId = LOGO_SOURCE.UERRE;
        } 
        if (company_id === "UNID"){
            logoId = LOGO_SOURCE.UNID;
        } 
        if (company_id === "HH"){
            logoId = LOGO_SOURCE.HH;
        }
    } 
    
    if(responsive){
        return LOGOS_RESPONSIVE[logoId];
    }

    return LOGO_IMAGES[logoId];
}

export const getLandingBrandImageCard = (ou) => LANDING_BRAND_IMAGES[ou];

export const convertOuToSource = (company_id, source_id) => {
    if (company_id) {
        if (company_id === "UERRE" && source_id===15) return source_id;
        if (company_id === "TAL") return 7;
        if (company_id === "UERRE") return 8;
        if (company_id === "UNID") return 9;
        if (company_id === "HH") return 10;
        if (company_id === "ADV") return 3;
    }
    return source_id;
}