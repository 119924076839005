import React, { useState, useEffect } from 'react'

// Shared Components
import CreateAccountWorkFlow from './WorkFlows/CreateAccountWorkFlow';
import MembreshipPeriodsWorkFlow from './WorkFlows/MembreshipPeriodsWorkFlow';
import PaymentMethodsWorkFlow from './WorkFlows/PaymentMethodsWorkFlow';

// helpers
import { getCheckoutCookie, setCheckoutCookie } from '../../../../../shared/utils/checkoutSession-legacy.js';
import { checkoutSteps, generateCheckoutWorkFlow } from '../helpers/checkoutSummaryHelpers';

const CheckoutWorkFlow = (props) => {

    const checkoutCookie = getCheckoutCookie();

    const [currentStep, setCurrentStep] = useState(1);
    
    useEffect(() => {
        const purchaseInitialStep = generateCheckoutWorkFlow(checkoutCookie.anonymousSession, checkoutCookie.membershipSession, checkoutCookie.isReferred, !!(checkoutCookie.paymentToken), checkoutCookie.initialStep)
        setCurrentStep(purchaseInitialStep)
    }, [])
    
    useEffect(() => {
        if (Object.keys(props.user).length > 0) {
            if (!checkoutCookie.initialStep) { // Inicialización predeterminada si no existe initialStep.
                setCurrentStep((checkoutCookie.membershipSession) ? currentStep + 1 : currentStep + 2)
            }
        }
    }, [props.user]);
    
    const prevStep = () => setCurrentStep(currentStep - 1);

    const nextStep = () => setCurrentStep(currentStep + 1);

    const nextStepCreateAccount = () => setCurrentStep((checkoutCookie.membershipSession) ? currentStep + 1 : currentStep + 2)

    const checkoutComponents = (activeStep) => {
        setCheckoutCookie({...checkoutCookie, activeStep});
        props.setStep(()=>activeStep);
        switch (activeStep) {
            case checkoutSteps.REGISTER: 
                return <CreateAccountWorkFlow {...props} nextStep={nextStepCreateAccount} />
            case checkoutSteps.MEMBERSHIP_PERIODS: 
                return <MembreshipPeriodsWorkFlow {...props} nextStep={nextStep} />
            case checkoutSteps.PAYMENT_METHODS: 
                return <PaymentMethodsWorkFlow {...props} prevStep={prevStep} />
            default: 
                return <PaymentMethodsWorkFlow {...props} prevStep={prevStep} />
        }
    }

    return (
        <>
            {
                checkoutComponents(currentStep)
            }
        </>
    )
}

export default CheckoutWorkFlow
