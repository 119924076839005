import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';
//@Material-ui
import { Typography } from '@material-ui/core';

const MembershipMonthlyPrice = ({price, currencySymbol}) => {
    return (
        <Typography variant="h4" color="secondary" component="span">
            <CurrencyFormat value={price} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix={currencySymbol} />
            <Typography variant="h5" color="secondary" component="span"className={'ml-1'} >
                MXN
            </Typography>
            <Typography className={clsx('ml-1', 'text-orange-300')} style={{ display: 'inline-block' }}>al mes</Typography>
        </Typography>
    )
}

MembershipMonthlyPrice.defaultProps  = {
    currencySymbol: '$',
};

MembershipMonthlyPrice.propTypes = {
    price: PropTypes.number.isRequired,
    currencySymbol: PropTypes.string
};
export default MembershipMonthlyPrice;