import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material components
import { Box, Typography } from '@material-ui/core';

// Components
import ActionEventButton from '../../ActionEventButtons/ActionEventButton';

// Style
import useStyles from '../../../../../../assets/styles/jss/pages/events/detailDataEventStyle';

// utils
import dateTimeFormat from '../../../../../../shared/utils/dateTimeFormat';

// Shared constants
import { EVENT_ADD_HOUR } from '../../../../../../shared/constants/event-add-hours';

const processDateTime = (date, startTime) => dateTimeFormat(`${date.split("T")[0]}T${startTime.split("T")[1]}`, undefined, EVENT_ADD_HOUR);

const DetailDataEvent = ({event, onClickInterestingEvent, showButtonInterest, size}) => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.containerDetail}>
                <Typography variant={size === 'medium' ? "body1" : "h6"} className={clsx(classes.dateText, "text-uppercase", (size === "medium") && 'variant-subheading-3')}>
                    {processDateTime(event.schedules[0].date, event.schedules[0].start_time)}
                </Typography>
                <Typography variant={size === 'small' ? "h3" : "h4"} className={classes.titleText}>
                    {event.title}
                </Typography>
                <Typography noWrap className={clsx(classes.locationText, 'variant-subheading-3')} component="div">
                    {
                        event.modality_id == 1 ? `${event.place} - ${event.address}` : event.modality_name
                    }
                </Typography>
            </Box>
            <Box className={classes.containerButtons}>
                <ActionEventButton fullSize={true} interestingEvent={event.is_interested} eventId={event.id} onClickInterestingEvent={onClickInterestingEvent} showButtonInterest={showButtonInterest} />
            </Box>
        </>
    );
};

DetailDataEvent.defaultProps = {
    isResponsive: false,
};

DetailDataEvent.propTypes = {
    event: PropTypes.object.isRequired,
    onClickInterestingEvent: PropTypes.func,
    showButtonInterest: PropTypes.bool,
    isResponsive: PropTypes.bool,
};

export default DetailDataEvent;