import React from 'react';
import clsx from 'clsx';

// @material-ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';

// assets
import useStyles from '../../../../assets/styles/jss/pages/cart/cartStyle';

const CartProductCount = ({ products }) => {
    const classes = useStyles();
    
    return <Grid container alignItems='center' justifyContent='flex-end'>
        <Hidden mdDown>
            <Grid item>
                <Typography variant='body2' className={clsx(classes.flexContainer, "text-dark-black-200")} component="div">
                    <Icon className='ri-shopping-cart-2-line mr-1 text-dark-black-200 font-size-16' />
                    <span className="font-weight-600">Artículos en el carrito</span>
                </Typography>
            </Grid>
        </Hidden>
        <Hidden mdUp /* responsive */>
            <Grid item>
                <Typography variant='body2' className={clsx(classes.flexContainer, "text-dark-black-200")} component="div">
                    <Icon className='ri-shopping-cart-2-line mr-1 text-dark-black-200 font-size-24' />
                </Typography>
            </Grid>
        </Hidden>
        <Grid item>
            <Box className={classes.cartCount}>{products?.length || 0}</Box>
        </Grid>
    </Grid>
};

export default CartProductCount;