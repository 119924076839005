// import { updateMembership } from "../../../../../../../redux/actions/userActions";

import { MEMBERSHIPS_NAME } from "../../../../../../../design-system/constants/memberships";
import { getSessionCookie } from "../../../../../../../shared/utils/Sessions";
import { setUser } from "../../../../../../../shared/components/EventTracker/EventTracker";

export const updateUserMembership = (order, updateMembership) => {
    const sessionCookie = getSessionCookie();
    const activeAccount = sessionCookie?.person_id ?? false;
    
    if (!activeAccount) return
    
    if (order?.conceptos?.length === 0) return 

    const membership = order.conceptos.find( concepto => concepto.membership_id)
    if ( membership?.membership_id > 0) {
        //Setting new membership profile mixpanel
        const membership_name = MEMBERSHIPS_NAME[membership.membership_id]
        setUser({person_id: sessionCookie?.person_id, subscription_type: membership_name}, ['mixpanel'])
        //Updating membership
        updateMembership && updateMembership(membership.membership_id)
    }
};    
