import { makeStyles } from "@material-ui/core/styles";

export const degreeStyles = makeStyles((theme) => ({
  search: {
    display: "flex",
    alignItems: "center",
    marginBottom: 2,
    // height: "7em",
    paddingLeft: 10,
    background: theme.palette.colors.fillStroke[500],
    borderRadius: 8,
  },
  cardHeader:{
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    // paddingLeft:10
  },
  buttonfilter: {
    display: "flex",
    alignItems: "center",
    height: 35,
  },
  select: {
    flex: 1,
    marginTop: "0px !important",
    paddingLeft: 10,
    marginLeft: 5,
    "& .arrow-down-icon":{
      marginRight: 20
    },
    "& .Mui-disabled":{
      color: theme.palette.colors.grey[50]
    },
    "& .MuiOutlinedInput-root.Mui-focused":{
      border: `1px solid ${theme.palette.colors.grey[50]} !important`
    }
  },
  input: {
    // marginLeft: theme.spacing(1),
    flex: 1,
    width: 40,
  },
  iconButton: {
    padding: 10,
    color: theme.palette.white,
  },
  grid: {
    marginBottom: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width:"649px",
    height: "296px",
  },
  modalStudent: {
    display: "flex",
    justifyContent: "center",
    padding: "25px 0px",
  },
  cardStudentDegree: {
    width: "85%",
    overflowY: "scroll",
    backgroundColor: theme.palette.colors.fillStroke[500],
  },
  cardStudentCertificate: {
    width: "85%",
    overflowY: "scroll",
    backgroundColor: theme.palette.colors.fillStroke[400],
  },
  dangerBtn: {
    marginRight: "0.5rem",
  },
  title: {
    justifyContent: "center",
    display: "flex",
  },
  subtitle:{
    color:theme.palette.colors.darkBlack[100],
    textAlign:"center",
  },
  CardTable: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  filterButton: {
    border: "0.5px solid #8962D2",
    backgroundColor: "#9F6FFB",
  },
  button:{
    marginLeft: 13,
    width: 185,
  },
  textArea: {
    backgroundColor: theme.palette.colors.fillStroke[500],
    // height: "6em",
    overflow: "auto",
    width: "100%",
    borderRadius: 8,
    fontSize: 15,
  },
  bioText: {
    color: theme.palette.colors.white,
    background: "none",
  },
  datePicker:{
    width: '100%',
    borderRadius: 8,
    flex: 1,
  },
}));
