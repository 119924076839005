import React from 'react'

import { Button, Typography } from '@material-ui/core';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const exportPDF = ( elementId, pageOrientation ) => {
    const input  = elementId;
    html2canvas(input, { logging: true, letterRendering: 1, useCORS: true})
      .then( canvas => {
        const imgWidth = 235;
        const imgHeight = (canvas.height * imgWidth / canvas.width);
  
        const imgData = canvas.toDataURL('img/png');
  
        const pdf = new jsPDF(pageOrientation, 'mm', [imgWidth, imgHeight]);
        // const pdf = new jsPDF('p', 'mm', [imgWidth, imgHeight]);
  
        // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        // pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('test-print.pdf');
      });
  }
  

const PrintPDFBtn = ({ btnName, elementId, icon = null, btnDisabled, pageOrientation = "l" }) => {
  return (
    <Button disabled={btnDisabled} data-html2canvas-ignore="true" variant='outlined' color="inherit" onClick={ () => exportPDF(elementId, pageOrientation) }>
        {
            icon !== null && icon
        }
        <Typography variant="caption">{ btnName }</Typography>
    </Button>
  )
}

export default PrintPDFBtn
