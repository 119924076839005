const ReferralBenefitsData = [
    {
        icon: 'ri-computer-fill',
        title: 'Clases 100% en línea sin horario',
        body: 'Estudia bachillerato, Licenciatura o Maestría desde cualquier lugar.'
    },
    {
        icon: 'ri-wechat-fill',
        title: 'Asesorías personalizadas',
        body: 'Programa asesorías con más de 100 profesores expertos en diferentes áreas.'
    },
    {
        icon: 'ri-video-fill',
        title: 'Cursos de líderes globales',
        body: 'Accede a cursos de Crehana, Google Skillshop, HubSpot, Coursera y más.'
    },
    {
        icon: 'ri-user-2-fill',
        title: 'Oportunidades laborales',
        body: 'Accede a nuestra bolsa de trabajo, CONECTA y encuentra vacantes para ti.'
    }
];

export default ReferralBenefitsData;