import React from 'react'
import clsx from 'clsx';
import { Icon } from '@material-ui/core';

//@material-ui/core
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const MembershipBenefits = (props) => {
    const { icon, title, body, classes } = props;

    return (
        <Grid item className={clsx(classes.benefitsItem)}>
            <Box className={classes.benefitCard}>
                <Box className={classes.benefitIconContainer}>
                    <Icon className={clsx(`${icon} text-violet-300`, classes.benefitIcon)} />
                </Box>
                <Box className={classes.benefitsTitle}>
                    <Typography variant='h4'>{title}</Typography>
                </Box>
                <Box className={classes.benefitsBody}>
                    <Typography variant="body2" className='text-fill-stroke-100'>{body}</Typography>
                </Box>
            </Box>
        </Grid>
    )
};

export default MembershipBenefits;