import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    dateText: {
        color: theme.palette.colors.violet[300]
    }, 
    titleText: {
        marginTop: "8px"
    },
    locationText: {
        marginTop: "8px",
        color: theme.palette.colors.darkBlack[100]
    },
    containerDetail: {
        width: '100%',
        height: "70%",
    },
    containerButtons: {
        width: '100%',
        height: "30%",
        display: 'flex',
    }
}));
