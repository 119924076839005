import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    card:{
        border: `1px solid ${theme.palette.colors.blue[500]}`,
        backgroundColor: 'rgba(33, 158, 188, 0.16)'
    },
    cardContent:{
        padding: '24px 51px',
    },
    icon:{
        color: theme.palette.colors.blue[500],
        fontSize: 56,
        marginRight: 37
    },
    infoText:{
        height: 74,
        display: 'grid',
        '& :nth-child(1)':{ 
            fontWeight: 700,
            fontSize: 14,
            lineHeight: '17px',
            height: 17
        },
        '& :nth-child(2)':{
            fontWeight: 700,
            fontSize: 28,
            lineHeight: '24px',
            height: 31,
            height: '100%'
        },
        '& :nth-child(3)':{
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '15px',
            height: 15
        }
    }
}));