import React, { useEffect, useState } from 'react';

// @material-ui/core
import { Grid, Dialog, DialogTitle, DialogContent, Typography, Button } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';

import EditPhoto from '../ProfileInfo/EditPhoto/EditPhoto';

// shared - core components
import Toast from '../../../../shared/components/Toast/Toast'
import LoaderContent from '../../../../shared/components/Loader/LoaderContent'

// Utils 
import { log } from '../../../../shared/utils/console.js'
import { getSessionCookie, setSessionCookie } from '../../../../shared/utils/Sessions';

// redux
import { connect } from 'react-redux';
import * as profilePicActions from '../../../../redux/actions/profilePicActions';

//SDK
import { AccountService } from '@sdk-point/talisis'; 
import { GlobalService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const EditPhotoDialog = (props) => {
    const session = getSessionCookie();

    const {user, applyProfilePic, openPhotoEditor, setOpenPhotoEditor} = props;

    const defaultPic = JSON.parse(localStorage.getItem('profilePic'));

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null)
    const [toastProps, setToastProps] = useState({message: ''})
    
    const handleClose = () => {
        setOpenPhotoEditor(false);
    };

    useEffect(() => {
        handleSave();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file])
    
    const handleSave = async () => {
        if(file){
            setLoading(true);
            try{
                const profilePic = await GlobalServiceSDK.uploadFile(file, 'profile', 'profile-picture', user.person_id);

                await AccountServiceSDK.updateProfilePicture(user.person_id, profilePic.path);
                const profilePhoto = {
                    src: profilePic.path,
                    blob: false
                };

                if (!!session && session.hasOwnProperty("profile_picture_url")) setSessionCookie({...session, profile_picture_url: profilePic.path});
                
                applyProfilePic(profilePhoto)
                setOpenPhotoEditor(false);
                setToastProps({open: true, message: 'Se ha actualizado correctamente la foto'})
                setLoading(false);
                setFile(null);
            }catch(err){
                setToastProps({severity: "error", open: true, message: 'Ops! Los datos no han sido actualizados correctamente'})
                log(err);
                setLoading(false);
                setFile(null);
            }
        }
    }

    const handleFinishedToast = () => {
        const _toastProps = {...toastProps};
        _toastProps.open = false;
        setToastProps(_toastProps);
    }

    return (
        <>
            <Dialog
                onClose={handleClose} 
                aria-labelledby="simple-dialog-title" 
                open={openPhotoEditor} 
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                maxWidth="sm"
            >
                <LoaderContent loading={loading} transparent={true}>
                    <DialogContent>
                        <EditPhoto
                            getPhotoToUpload={setFile}
                            handleClose={handleClose}
                            saveDisabled={!file}
                        />
                    </DialogContent>
                </LoaderContent>
            </Dialog>
            
            <Toast {...toastProps} onFinished={handleFinishedToast}/>
        </>
    );
};

const mapStateToProps = ({userReducer, profilePicReducer}) => ({
	user: userReducer.user,
    profilePic: profilePicReducer.profilePic
});

export default connect(mapStateToProps, profilePicActions)(EditPhotoDialog);
