
export const navToSocial = (socialNetwork, userName) => {
    
    if(userName === null || userName === 'null') return

    switch (socialNetwork) {
        case "FACEBOOK":
            window.open(`https://www.facebook.com/${userName}`, "_blank", 'noopener,noreferrer')
            break;    
        case "TWITTER":
            window.open(`https://twitter.com/${userName}`, "_blank", 'noopener,noreferrer')
            break;    
        case "INSTAGRAM":
            window.open(`https://www.instagram.com/${userName}`, "_blank", 'noopener,noreferrer')
            break;    
        case "LINKEDIN":
            window.open(`https://www.linkedin.com/in/${userName}`, "_blank", 'noopener,noreferrer')
            break;    
        default:
            break;
    }
}
