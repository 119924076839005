import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom'

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Button } from '@material-ui/core';
import Link from '@material-ui/core/Link';

// icons
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

// shared
import dateFormat from '../../../../../design-system/utils/dateFormat';
import { SIMPLE_DATE_FORMAT } from '../../../../../design-system/constants/date-formats';

// style
import useStyles from '../../../../../assets/styles/jss/pages/requests/shippingGuideStyle';

const ShippingGuide = ({onGetShippingGuide, perdonId, requestPersonId, physicalFiles, deadlineAt}) => {
    const classes = useStyles();
    const history = useHistory();

    const handleGenerateGuide = () => {
        onGetShippingGuide(perdonId, requestPersonId);
    };

    const handleClickProfile = () => {history.push("/profile")};

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item xs={12}>
                <Grid container direction='row' spacing={3}>
                    <Grid item container justifyContent="center" xs={3}>
                        <Typography variant="h3" className="text-magenta-500">Paso 1</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={3} direction="column" className={classes.containerShippingGuide}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Asegúrate de que tu dirección en el perfil este completa, de no ser así, no se podra generar la guía para el envío.</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Button color="primary" variant="outlined" onClick={handleClickProfile}>Ir al perfil</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container direction='row' spacing={3}>
                    <Grid item container justifyContent="center" xs={3}>
                        <Typography variant="h3" className="text-magenta-500">Paso 2</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={3} direction="column" className={classes.containerShippingGuide}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Guarda en un sobre amarillo bien sellado los siguientes documentos y envíanoslos por paquetería antes del {dateFormat(deadlineAt, SIMPLE_DATE_FORMAT)} para que podamos completar tu registro.</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Box className={`${classes.panelDownloadGuide} bg-fill-stroke-500`}>
                                    <Grid container direction='column' spacing={3}>
                                        {
                                            physicalFiles.map(physicalFile => 
                                                <Grid item container direction='row'>
                                                    <Grid item container xs={3} justifyContent="center" alignItems="center">
                                                        <LocalShippingIcon className={classes.shippingGuideIcon} color="primary"/>
                                                    </Grid>

                                                    <Grid item xs={9}>
                                                        <Typography variant="subtitle1">{physicalFile.label}</Typography>
                                                        <Link href={ physicalFile.path } className='MuiLink-underlineNone'>
                                                            <Typography variant="subtitle2" className="text-orange-300">Ver documento</Typography>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                                )
                                        }
                                        
                                        <Grid item container direction='row'>
                                            <Button color="primary" variant="outlined" onClick={handleGenerateGuide}>Descarga guía e instrucciones</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container direction='row' spacing={3}>
                    <Grid item container justifyContent="center" xs={3}>
                        <Typography variant="h3" className="text-magenta-500">Paso 3</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={3} direction="column" className={classes.containerShippingGuide}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Asegúrate de que el documento que envíes sea el correcto, ya que en caso de no serlo, se te devolverá y el costo del reenvío correrá por tu cuenta.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>);
};

ShippingGuide.propTypes = {
    onGetShippingGuide: PropTypes.func,
    perdonId: PropTypes.number, 
    requestPerson: PropTypes.number,
    physicalFiles: PropTypes.array,
};
 
export default ShippingGuide;