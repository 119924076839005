import React from 'react';
import PropTypes from 'prop-types';

// material components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

// core components
import TabPanel from './TabPanel/TabPanel'

export default function SimpleTabs(props) {
	const { children, onChange, activeTab, tabs, actions, disabledAll, ...rest } = props;

	return (
		<Box width="100%">
			<Tabs
				value={activeTab}
				onChange={(event, value) => onChange(value)}
				aria-label="simple tabs"
				indicatorColor="primary"
				{...rest}
				className="no-print"
				textColor="inherit"
			>
				{tabs.map((it, index) =>
					<Tab key={index}
						label={it}
						id={`tab-${index}`}
						aria-controls={`tabpanel-${index}`}
						disabled={disabledAll}
					/>
				)}
				{actions}
			</Tabs>
			{
				tabs.map((it, index) => (
					index === activeTab && <TabPanel value={activeTab} index={index} key={index}>
						{children}
					</TabPanel>
				))
			}
		</Box>
	);
}

SimpleTabs.defaultProps = {
	activeTab: 0,
	tabs: [],
	disabledAll: false
};

SimpleTabs.propTypes = {
	disabledAll: PropTypes.bool,
	children: PropTypes.element,
	onChange: PropTypes.func,
	activeTab: PropTypes.number,
	tabs: PropTypes.array.isRequired,
	actions: PropTypes.element,
};