import { defaultCheckoutCookie, setCheckoutCookie } from "../utils/checkoutSession-legacy.js";
import * as paths from '../../routes/paths';

const itemTypes = {
    course: "course_id",
    program: "program_id",
    membershipId: "membership_info",
    productWithMembership: "product_with_membership",
}

const validateProduct = (productInfo) => {
    const { itemType, membershipInfo } = productInfo;
    return (itemType === "membershipId") ? membershipInfo : productInfo.id
}

const handleOrderProducts = (productsArray) => {
    if( productsArray.length === 1 ){
        const product = productsArray[0];
        const orderItems = validateProduct(product)
        const items = [{
            [itemTypes[product.itemType]]: orderItems,
        }]
        return items
    }
    if( productsArray.length > 1 ){
        const items = productsArray.map((product) => {
            const orderItem = validateProduct(product)
            return {[itemTypes[product.itemType]]: orderItem}
        })
        return items
    }
}

const manageCheckoutCookie = (cookieInfo) => {
    const { isLogged, user, products, isRepurchase, customCheckoutCookie } = cookieInfo;
    const isMembershipPurchase = products.some( product => product.membership_info );
    if(!isLogged){
        return setCheckoutCookie({
            ...defaultCheckoutCookie,
            ...customCheckoutCookie,
            membershipSession: isMembershipPurchase,
            items: products,
            isRepurchase: isRepurchase
        });
    }
    return setCheckoutCookie({
        ...defaultCheckoutCookie, 
        ...customCheckoutCookie,
        personId: user.person_id,
        anonymousSession: false,
        membershipSession: isMembershipPurchase,
        items: products,
        isRepurchase: isRepurchase,
        activeAccount: true
    })
}

export const startCheckout = ({ user, orderProducts, searchParams, isLogged, history, isRepurchase = false, customCheckoutCookie = {} }) => {

    const products = handleOrderProducts(orderProducts);

    manageCheckoutCookie({ isLogged, user, products, isRepurchase, customCheckoutCookie })

    history.replace({
        pathname: paths.CHECKOUT_LEGACY,
        search: searchParams,
        state: {
            'user': user,
            'referrer': "course-landingpage",
            'items': products,
            "isRepurchase": isRepurchase
        }
    });
}
