import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    calendarContainer: {
        width: '596px',
        height: 'auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.colors.fillStroke[400],
        borderRadius: '16px',   
        overflow: "hidden",
        paddingBottom: '38px',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
        }

    },
    calendarContainerSche: {
        width: '596px',
        height: 'auto',
        backgroundColor: theme.palette.colors.fillStroke[400],
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
        }
    },
    boxTittles: {
        marginTop: '32px',
        marginLeft: '32px',
        marginBottom: '5px',
        [theme.breakpoints.down('xs')]:{
            margin: '16px',
        }
    },
    boxMonthsControl: {
        position: 'relative',
        display: 'flex',
        alignSelf: 'center',
        justifySelf: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.colors.fillStroke[300],
        width: '532px',
        height: '40px',
        borderRadius: '4px',
        marginBottom: '16px',
        [theme.breakpoints.down('xs')]:{
            width: '296px',
        }
    },
    boxDaysPeerWeek: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '531px',
        height: '32px',
        gap: '8px',
        [theme.breakpoints.down('xs')]:{
            width: '293px',
        }
    },
    boxCalendarContain: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'space-around',
        alignSelf: 'center',
        width: '531px',
        [theme.breakpoints.down('xs')]:{
            width: '293px',
        }
    },
    boxDaysContainMonth: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        alignItems: 'center',
        width: '531px',
        height: '224px',
        [theme.breakpoints.down('xs')]:{
            width: '293px',
        }
    },
    buttonDaysPeerMonth: {
        width: '69px',
        height: '32px',
        backgroundColor: theme.palette.colors.fillStroke[300],
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        minWidth: '39px',
        [theme.breakpoints.down('xs')]:{
            width: '35px',
            padding: 0,
            margin: 0
        },
    },
    boxDaysPeerMonth: {
        width: '69px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]:{
            width: '35px',
            padding: 0
        }
    },
    boxSchedule: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '530px',
        height: '250px',
        alignSelf: 'center',
        justifySelf: 'center',
        marginTop: '16px',
        marginRight: '3px',
        [theme.breakpoints.down('xs')]:{
            alignSelf: 'end',
            width: '94.06%',
            height: '152px'
        }
    },
    radioGroup:{
        gap: '8px'
    },
    controlRadio: {
        padding: 0,
        width: '100%',
        maxWidth: '530px',
        maxHeight: '250px',
        overflow: 'auto',
        overflowX: 'hidden',
        scrollMargin: "7.3em",
        '&::-webkit-scrollbar': {
            borderRadius: '10px',
            width: '5px',
            height: '70px',
            background: theme.palette.colors.fillStroke[100],
            marginLeft: '20px',
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.colors.fillStroke[400],
            borderRadius: '10px',
            width: '5px',
            height: '70px',
            marginLeft: '20px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.colors.fillStroke[100],
            borderRadius: '10px',
            marginLeft: '20px',
        },
    },
    labelRadio: {
        backgroundColor: theme.palette.colors.fillStroke[300],
        borderRadius: '4px',
        width: '505px',
        height: '32px',
        padding: '8px 16px',
        margin: 0,
        "& svg": {
            width: "13px",
            height: "13px"
        },
        [theme.breakpoints.down('xs')]:{
            width: '97.29%',
        }
    },        
    labelRadioCheck: {
        padding: 0,
        backgroundColor: theme.palette.colors.fillStroke[100],
        borderRadius: '4px',
        width: '505px',
        height: '32px',
        padding: '8px 16px',
        margin: 0,
        "& svg": {
            width: "13px",
            height: "13px"
        },
        [theme.breakpoints.down('xs')]:{
            width: '97.29%',
        }
    },
    boxScheduleResume: {
        padding: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyItems: 'space-around',
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.colors.fillStroke[300],
        width: '532px',
        height: '56px',
        borderRadius: '4px',
        [theme.breakpoints.down('xs')]:{
            width: '90.077%',
            height: '40px'
        }
    },    
    boxScheduleCalendar: {
        padding: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        justifyContent: 'space-around',
        width: '433px',
        height: '16px',
        marginTop:'15px'
    },
    boxSig: {
        display: 'flex',
        width: '532px',
        height: '60px',
        margin: '20px',
        justifyContent: 'right',
        [theme.breakpoints.down('xs')]:{
            width: '90.69%',
            margin: '20px 16px 0px 16px'
        }
    },
    buttonSig:{
        width: '113px',
        height: '40px',
        marginRight: '12px',
        [theme.breakpoints.down('xs')]:{
            width: '326px',
            height: '48px',
        }
    },
    closeWindow:{
        position: 'absolute',
        display: 'flex',
        width: '540px',
        height: '20px',
        marginLeft: '32px',
        marginTop: '22px',
        justifySelf: 'end',
        justifyContent: 'end',
        [theme.breakpoints.down('xs')]:{
            width: '326px',
            height: '48px',
            marginTop: '10px',
        }
    },
    boxMessageSchedule:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px'
    },
    nothingFound:{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        padding: 0, 
        margin: 0
    },
    arrowLeft:{
        display: 'flex',
        justifyContent: 'start'
    },
    arrowRight:{
        display: 'flex',
        justifyContent: 'end'

    },
}));