import React from 'react';
import { Link } from 'react-router-dom';
import { generatePath } from "react-router";

// @components
import { convertOuToSource } from '../../../constants/convertOuToSource';
import CourseCardBody from '../../../shared/components/CourseCard/CourseCardBody';
import { CURSOS_DETALLE, CURSO_DETALLE } from '../../../routes/paths';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';


// @SDK
import { MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../shared/components/Mixpanel/mixpanel';
import CardSkeletonLoading from '../../../shared/components/Card/CardSkeletonLoading';
import CourseCard from '../../../shared/components/Card/CourseCard/CourseCard';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';


const useStyles = makeStyles((theme) => ({
    root:{
        padding: '80px 0px 80px',
        background: theme.palette.colors.fillStroke[400],
        [theme.breakpoints.down('sm')]:{
            padding: '56px 15px 40px'
        },
    },
    container:{
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]:{
            display: 'flex',
            flexDirection: 'column'
        },
    },
    link: {
        cursor: 'pointer',
    },
    courseCard:{
        maxWidth: 288,
        width: 'auto',
        [theme.breakpoints.down('xs')]: {
            margin: 'auto'
        }
    }
}));

const BrandPopularCourses = (props) => {
    const { user, popularPrograms, clickFrom} = props;
    const classes = useStyles();
    const isLaptop = useMediaQuery(theme => theme.breakpoints.only('md'));

    return (
        <ResponsiveContainer className={classes.root}>
                <Typography variant="h2" align="center" className="offset-mb-16">Nuestros cursos populares de Educación Continua</Typography>
                <Grid container spacing={3}>
                    {
                        !popularPrograms.length 
                        ? <CardSkeletonLoading numItems={4} position="unset"/>
                        : popularPrograms.slice(0, isLaptop ? 3 : 4).map(program => {
                        let linkProps = {};
                        if (!/^((http|https):\/\/)/.test(program.course_url)) {
                            program.course_url = "http://" + program.course_url;
                        }

                        if (program?.is_purchased) {
                            linkProps.to = { pathname: generatePath(CURSOS_DETALLE, {id: program.id}), state: { prevPath: 'mis_cursos' } };
                        }
                        else {
                            linkProps.to = { pathname: generatePath(CURSO_DETALLE, {id: program.id}), state: { prevPath: 'mis_cursos' } };
                        }

                        return (
                            <Grid key={`grid_item_course_${program.id}`} item xs={12} sm={6} md={4} lg={3}>
                                <Link className="text-left" key={`link_course_${program.id}`} onClick={() => {
                                    let brand = program?.company_id !== null ? program?.company_id : program?.source_name;
                                    brand = brand ? brand.toLowerCase().split(" ")[0] : '';
                                    eventData['source'] = clickFrom;
                                    eventData['marcaCurso'] = brand;
                                    eventData['courseID'] = program.id;
                                    eventTracker('ver_curso', eventData, ['mixpanel']);
                                }} {...linkProps} style={{ textDecoration: 'none' }}>
                                    <CourseCard
                                        className={classes.courseCard}
                                        data={{
                                            company_id: program?.company,
                                            source: {
                                                id: convertOuToSource(program.company, program.source),
                                            },
                                            labels: program?.labels,
                                            image: program?.image_url,
                                            saved: (program?.is_favorited == 1 || program?.is_favorited == true) ? true : false,
                                            course_id: program?.id,
                                            userId: user?.person_id,
                                            saved: (program?.is_favorited == 1 || program?.is_favorited == true) ? true : false,
                                            label: program?.label,
                                            title: program?.name,
                                            course_id: program?.id,
                                            userId: user?.person_id,
                                            detail: {
                                                sourceId: convertOuToSource(program.company, program.source),
                                                lessons: program?.lessons,
                                                level: program?.difficulty_level,
                                                estimated_time: program?.estimated_time,
                                            },
                                            prices: {
                                                original: program?.list_price,
                                                current: program?.price,
                                            },
                                            modalityId: program?.modality_id != null ? program?.modality_id : 2,
                                            membershipsPrices:{program},
                                            priceMembershipActions: program.priceMembershipActions,
                                            content: program,
                                        }}
                                    />
                                </Link>
                            </Grid>
                        )
                    })}
                </Grid>
                <Button color="primary" onClick={() => props.history.push('/')} size="medium" className={`mt-5`}>Ver todos los cursos de educación continua</Button>
        </ResponsiveContainer>
    )
};

export default BrandPopularCourses;