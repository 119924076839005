import React from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';

// Material components 
import { Box, Grid, Icon, Typography } from '@material-ui/core';

import clsx from 'clsx';

// Styles
import useStyles from '../../../../../assets/styles/jss/components/cards/cardMyClasses/priceAndMembershipStyle';

const TYPE_LABEL = {
    FREE: 1,
    OTHER_MEMBERSHIP: 2,
    INCLUDE_MEMBERSHIP: 3,
    PRICE: 4,
};

const PriceAndMembership = ({ isResponsive, content, className }) => {
    const classes = useStyles({isResponsive});

    return (
        <Box className={clsx(classes.rootPriceAndMembership, className)}>
            {
                content.showLabel && 
                <>
                    {
                        (content.typeLabel === TYPE_LABEL.FREE || content.typeLabel === TYPE_LABEL.INCLUDE_MEMBERSHIP) && 
                        <Box className={classes.freeCourseContainer}>
                            <Typography variant='body2' className={`semi-bold ${classes.freeCourseLabel}`}>{content.labelToShow}</Typography>
                        </Box>
                    }
                    {
                        content.typeLabel === TYPE_LABEL.OTHER_MEMBERSHIP &&    
                        <Box className={clsx(classes.otherMembershipContainer, 'membership-container')}>
                            <Box className={classes.otherMembershipLabelContainer}>
                                <Typography noWrap variant='body2' className={`semi-bold ${classes.otherMembershipLabel}`}>{content.labelToShow}</Typography>
                            </Box>
                            <Box className={classes.otherMembershipIconContainer}>
                                <Icon className={`ri-lock-2-line font-size-16 ${classes.otherMembershipIcon}`}/>
                            </Box>
                        </Box>
                    }
                </>
            }
            {
                content.showPrice && !isResponsive &&
                <>
                    <Grid container className={clsx(classes.priceContainer, 'price-container')}>
                        <Grid item xs>
                        {
                            (!!content.price.number && !!content.price.listNumber && content.price.listNumber != content.price.number) &&
                            <Typography variant='caption' className={`semi-bold ${classes.discountLabel}`}>{`${Math.round(((content.price.number * 100) / content.price.listNumber))}% de Dto.`}</Typography>
                        }
                        </Grid>
                        <Grid item xs>
                        {
                            !!content.price.listNumber && content.price.listNumber != content.price.number &&
                            <Typography variant='body2' className={`classes.price ${classes.listNumberLabel}`}><CurrencyFormat value={content.price.listNumber} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN'/></Typography>
                        }
                        </Grid>
                        <Grid item xs>
                            <Typography variant='h5' className={classes.numberLabel}>
                                {
                                    content.price.number &&
                                    <CurrencyFormat value={content.price.number} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN'/>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            }
            {
                content.showPrice && isResponsive &&
                <>
                    <Grid container className={clsx(classes.priceContainer, 'price-container-responsive')}>
                        <Grid item xs={5}>
                            <Box>
                                <Box>
                                {
                                    !!content.price.listNumber && content.price.listNumber != content.price.number &&
                                    <Typography className={`variant-subheading-3 ${classes.listNumberLabel}`}><CurrencyFormat value={content.price.listNumber} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN'/></Typography>
                                }
                                </Box>
                                <Box>  
                                {
                                    (!!content.price.number && !!content.price.listNumber && content.price.listNumber != content.price.number) &&
                                    <Typography variant='caption' className={`
                                    variant-caption-sm semi-bold ${classes.discountLabel}`}>{`${Math.round(((content.price.number * 100) / content.price.listNumber))}% de Dto.`}</Typography>
                                }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={7}>
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                {
                                    !!content.price.number &&
                                    <Typography variant='h3' className={classes.numberLabel}><CurrencyFormat value={content.price.number} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN'/></Typography>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </>
            }

        </Box>
    );
};

PriceAndMembership.propTypes = {
    isResponsive: PropTypes.bool.isRequired,
    content: PropTypes.shape({
        price: PropTypes.shape({
            number: PropTypes.number,
            listNumber: PropTypes.number,
        }),
        showLabel: PropTypes.bool.isRequired,
        showPrice: PropTypes.bool.isRequired,
        labelToShow: PropTypes.string.isRequired,
        typeLabel: PropTypes.number.isRequired,
    }).isRequired
};

export default PriceAndMembership;