import React from 'react';
import { connect } from "react-redux";
// @material-ui/core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
// core components
import CardsProgress from './CoursesInProgress/CardsProgress';
// import Tabs from '../../shared/components/Tabs/Tabs';
import CoursesCompleted from './CoursesCompleted/CoursesCompleted';
import SavedCourses from './SavedCourses/SavedCourses';
import CatalogAllCourses from './CatalogAllCourses/CatalogAllCourses';

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { CourseService } from '@sdk-point/talisis';
import { log } from '../../shared/utils/console.js'

const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
        {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabs = [
    {value:'Todos los cursos', uuid: uuid()},
    {value:'Cursos en progreso', uuid: uuid()},
    {value:'Cursos completados', uuid: uuid()},
    {value:'Cursos guardados', uuid: uuid()}
];

const useStyles = makeStyles((theme) => ({
  tabMain:{
    overflow: "visible",
    borderBottom: "1px solid #828D9E",
    paddingLeft: "62px",
    '& .MuiTabs-flexContainer':{
      borderBottom: "none",
    },
    '& .MuiTabs-scroller':{
      maxWidth: "1378px",
      marginLeft: "24px",      
      // marginRight: "auto",
    },
    "@media (max-width: 1250px)": {
      '& .MuiTabs-flexContainer':{
        minWidth: "max-content",
      }
    },
    "@media (max-width: 740px)": {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },
  mainCenter:{
    width: "100%",
    boxSizing: "border-box",
    position: "absolute",
    left: "0px",
    top: "101px",
    paddingLeft: "0px",
    paddingRight: "0px",
    overflow: "hidden",
    maxWidth: "100vw",


    /*"@media (max-width: 1240px)": {
      width: "102%",
      overflow: "hidden",
      maxWidth: "102vw",
      boxSizing: "content-box",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginLeft: "-2%",
    },
    "@media (max-width: 750px)": {
      marginLeft: "-5%",
    },*/



  },
  mainCarousels:{
    width: "100%",
    boxSizing: "border-box",
    position: "absolute",
    left: "0px",
    top: "185px",
    paddingLeft: "0px",
    paddingRight: "0px",
    overflow: "hidden",
    maxWidth: "100vw",

  },
  tabs:{
    textTransform: "initial",
  },

}));


const Courses = ({ user }) => {
    const [activeTab, setActiveTab] = React.useState(0);
    const [chargeTarget, setChargeTarget] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [tabCatalogAllCourses, setTabCatalogAllCourses] = React.useState(true);
    const [tabCoursesInProgress, setTabCoursesInProgress] = React.useState(false);
    const [tabCoursesCompleted, setTabCoursesCompleted] = React.useState(false);
    const [tabSavedCourses, setTabSavedCourses] = React.useState(false);
    const history = useHistory();
    const classes = useStyles();

    const handleChange = (event, newValue) => {
      event.preventDefault();
      if(newValue == 0){
        handleChangeTabAllCourses();
      }else if(newValue == 1){
        handleChangeTabInProgress(event);
      }
      else if(newValue == 2){
        handleChangeTabCompleted();
      }
      else if(newValue == 3){
        handleChangeTabSaved();
      }
      setValue(newValue);
    };

    const handleChangeTabAllCourses = () => {
      history.push('/cursos');
      setTabCatalogAllCourses(true);
      setValue(0);
    };

    const handleChangeTabCompleted = () => {
      history.push('/cursos?target=completados');
      setTabCoursesCompleted(true);
      setValue(2);
    };

    const handleChangeTabSaved = () => {
      history.push('/cursos?target=guardados');
      setTabSavedCourses(true);
      setValue(3);
    };
    const handleChangeTabInProgress = (e) => {
      e.preventDefault();
      history.push('/cursos?target=progreso');
      setTabCoursesInProgress(true);
      setValue(1);
    };

    const handleChangeTab = activeTab => setActiveTab(activeTab);

    const getData = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const target = await queryParams.get('target');
        return target
      } catch (e) {
          log(e.message);
      }
  };

    React.useEffect(() => {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
      let isCanceled = true;
      getData().then((target)=>{
          if(isCanceled){
            if(target == "progreso"){
              setTabCoursesInProgress(true);
              setValue(1);
            }else if(target == "completados"){
              setTabCoursesCompleted(true);
              setValue(2);
            }else if(target == "guardados"){
              setTabSavedCourses(true);
              setValue(3);
            }else{
              setTabCatalogAllCourses(true);
              setValue(0);
            }
            setChargeTarget(true);
          }
      }).catch((err) => {
        log(`Failed: ${err}`);
      });
      return () => (isCanceled = false);
  }, []);


  const syncPersonCoursesRelBanner = async () => {
    try{
      log(user);
      if(user?.person_id && user?.student_id){
        //courseService.syncPersonCoursesRelInBanner(user.person_id);
      }
    }catch(e){
      log("Error syncPersonCoursesRelInBanner");
      log(e);
    }
  }

  React.useEffect(() => {
      let isMounted = true;
      if (isMounted) {
          //syncPersonCoursesRelBanner();
      } 
      return () => { isMounted = false };
  }, []);

    return (
        <Box width="100%" style={{ overflowX: "hidden" }}>
              <Box className={classes.mainCenter}>
                <Tabs className={ classes.tabMain } variant="scrollable" scrollButtons allowScrollButtonsMobile value={value} onChange={handleChange} aria-label="scrollable auto tabs example" indicatorColor="primary">
                  {
                    tabs.map((tab, index) => <Tab className={classes.tabs} key={tab.uuid} label={tab.value} {...a11yProps(index)} />)
                  }
                </Tabs>
              </Box>
              
              <Box className={classes.mainCarousels}>
                  {

                    tabCatalogAllCourses == true && value == 0 && chargeTarget == true && <Box>
                      <CatalogAllCourses handleChangeTabSaved={handleChangeTabSaved} handleChangeTabInProgress={handleChangeTabInProgress} />
                    </Box>}
                    {tabCoursesInProgress == true && value == 1 && <Box>
                        <CardsProgress user={user} />
                    </Box>}
                    {tabCoursesCompleted == true && value == 2 && <Box>
                        <CoursesCompleted />
                    </Box>}
                    {tabSavedCourses == true && value == 3 && <Box>
                        <SavedCourses isMyCourses={true} />
                    </Box>}
              </Box>
              

        </Box>
        
        // <Tabs
        //     onChange={handleChangeTab}
        //     activeTab={activeTab}
        //     tabs={tabs.map(it => it.tab)}
        // >
            
        //     {
        //         tabs[activeTab].content
        //     }
        // </Tabs>
    );
};
const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(Courses);