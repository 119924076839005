import React from "react";
//@Material-ui
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
//Components
import CourseCard from "../../MembershipsContainerCard/CourseCard/CourseCard";
import ProgramCard from "../../MembershipsContainerCard/ProgramCard/ProgramCard";
//Sku
import { PRODUCT_TYPES } from '@sdk-point/talisis';

const useStyles = makeStyles((theme) => ({
    boxMain:{
      width:'288px', 
      height:'auto',
      marginRight: '10px',
      [theme.breakpoints.down('sm')]:{
        marginRight: '0px'
    }
    },
    cardTextDescription: {
        marginTop:'20px',
        ...theme.typography.bodyTextLargeRegular
    }
}))
const ProductLayoutLanding = ({product}) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxMain}>
      {
        product.product_type_id === PRODUCT_TYPES.CURSO &&
        <CourseCard courseInfo = {product}/>
      }
      {
        product.product_type_id === PRODUCT_TYPES.PROGRAMA && 
        <ProgramCard programInfo = {product} />
      }
      <Box className="mb-2">
        <Typography className={classes.cardTextDescription}>{product?.card_description}</Typography>
      </Box>
    </Box>
  )
}




export default ProductLayoutLanding;