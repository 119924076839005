import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

// @material-ui/core
import { Box, Button, Grid, Hidden } from '@material-ui/core';

// components
import CardUser from './components/CardUser/CardUser';

// Style
import useStyles from '../../../assets/styles/jss/pages/home/menuStyle';
import CardItem from './components/CardItem/CardItem';

const Menu = ({data, onChangeTab, user, canReadWrite, activeTab, userRole, profilePic, isResponsive}) => {
    const classes = useStyles({isResponsive});
    const history = useHistory();

    return (
        <Grid container className={classes.menuRoot} direction={isResponsive ? 'row' : 'column'}>
            <Hidden xsDown>
                <Grid item className={clsx('w-100')} xs={12}>
                    <CardUser user={user} userRole={userRole} profilePic={profilePic} />
                </Grid>
            </Hidden>
            {
                data.map((item, index) => <Grid item className={clsx(classes.itemContainer2, 'w-100', isResponsive && (index + 1 ) % 2 === 1 && 'pr-1', isResponsive && (index + 1 ) % 2 === 0 && 'pl-1')} key={item.id} md={12} xs={6}> <CardItem id={item.id} name={item.tab} icon={item.icon} onChangeTab={onChangeTab} activeTab={activeTab}/> </Grid>)
            }
            {
                canReadWrite && 
                <Grid item className={clsx('w-100')} xs={12}>
                    <Button
                        color="primary"
                        label="Publicar noticia nueva"
                        onClick={() => history.push('/feed/new')}
                        size={isResponsive ? 'large' : 'medium'}
                        variant="contained"
                        className={classes.newEventButton}>
                        Publicar noticia nueva
                    </Button>
                </Grid>
            }
        </Grid>
    );
};

Menu.defaultProps = {
    isResponsive: false,
};

Menu.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        tab: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    })),
    onChangeTab: PropTypes.func,
    user: PropTypes.object.isRequired,
    canReadWrite: PropTypes.bool,
    activeTab: PropTypes.number,
    userRole: PropTypes.string,
    profilePic: PropTypes.object,
    isResponsive: PropTypes.bool,
};

export default Menu;