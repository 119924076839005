import React, { useEffect, useState } from 'react'

import { Box, Chip, Collapse, Grid, List, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import { orderDetailsNewStyles } from '../../orderDetailsNewStyles';

import CourseOrderCard from '../../CourseOrderCard/CourseOrderCardNew';
import CurrencyFormat from 'react-currency-format';

import DynamicList from '../../../../DetailedStudyPlan/DynamicList/DynamicList';
import { getMembershipIcon } from '../../helpers/getMembershipLogo';
import { getMembershipDetails } from '../../helpers/getMembershipDetails';
import LogoSource from '../../../../../../shared/components/Card/CourseCard/components/LogoSource';
import { SOURCE_ID_COMPANY_NAME } from '../../../../../../shared/constants/sources';
import MembershipBenefitsCheckout from '../../../CheckoutSummary/MembershipBenefitsCheckout/MembershipBenefitsCheckout';
import LogoByOu from '../../../../DetailedStudyPlan/LogoByOu/LogoByOu';

const OrderProduct = (props) => {
    
    const { conceptType, product, couponData, order, totalWoDiscount, isProductwithMembership, hidePrices } = props;
    const theme = useTheme();
    const classes = orderDetailsNewStyles();

    // let tipo = product.order_detail_type_id === 1 ? "curso": product.level_description;
    let tipo = (product?.course_id) ? "curso" : product.level_description 

    const icon = getMembershipIcon(product?.membership_slug)
    const [openOrderProductDetails, setOpenOrderProductDetails] = useState(false);
    const [productDetails, setProductDetails] = useState([]);

    const default_image = "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_7.jpg"
    
    const handleClick = () => setOpenOrderProductDetails(!openOrderProductDetails);

    useEffect(() => {
        const fetchMembershipDetails = async() => {
            // const conceptoMembresia = order.conceptos.filter( concepto => concepto.order_detail_type_slug === "membership" );
            const conceptoMembresia = order.conceptos.filter( concepto => concepto.membership_fee_id );  // TODO: Recibir tipo del back
            const respDetails = await getMembershipDetails(conceptoMembresia);
           
            
            setProductDetails(respDetails);
        }
        fetchMembershipDetails()
    }, [order])

    return (
        <>
        {
            conceptType === "membership" &&
            <>
                <Grid container alignItems='center' spacing={1}>
                    {icon && <Grid item style={{ display: "flex" }}>{icon}</Grid>}
                    <Grid item xs>
                        <Typography variant='h4'>{product?.name}</Typography>
                    </Grid>
                </Grid>
                <List className='mt-4' component="div" disablePadding>
                    <Typography onClick={handleClick} variant='body2' className='semi-bold text-orange-300' style={{ cursor: 'pointer'}}>
                        Ver detalle de producto
                        {
                            openOrderProductDetails
                            ? <i className="ri-arrow-up-s-line ml-1" style={{ verticalAlign: 'middle', display: 'inline-flex'}} />
                            : <i className="ri-arrow-down-s-line ml-1" style={{ verticalAlign: 'middle', display: 'inline-flex'}} />
                        }
                    </Typography>
                    <Collapse in={openOrderProductDetails} timeout="auto" unmountOnExit>
                        <MembershipBenefitsCheckout order={order} />
                    </Collapse>
                    {/* <Collapse in={open} timeout="auto" unmountOnExit>
                        <DynamicList listArray={productDetails} customColor={ theme.palette.colors.darkBlack[100] } />
                    </Collapse> */}
                    {
                        ( !isProductwithMembership && !hidePrices) &&
                        <Grid container justifyContent="flex-start" alignItems="center" style={{marginTop:10}}>
                            <Grid item xs="auto" style={{paddingLeft:10}}>
                                <Typography component='div' align='right' variant="h3" className={classes.total_without_discount} hidden={totalWoDiscount===0}>
                                    <CurrencyFormat value={order.subtotal} hidden={order.discount > 0 ? false:true} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' />
                                </Typography>
                            </Grid>
                            
                        </Grid>
                    }
                </List>
            </>
        }
        {
            conceptType !== "membership" &&
            <>
                <Grid container spacing={1} style={{ height: "78px",  }}>
                    <Grid item xs={3} 
                        style={{ borderRadius:'8px', backgroundImage: `url(${product.image_url ?? default_image})`, 
                            display: "block", backgroundSize: "cover", backgroundPosition: "center",
                            backgroundRepeat: "no-repeat", maxWidth: "72px", height: "100%"
                        }} 
                    />
                    <Grid item xs={9}>
                        <Grid container spacing={1} alignContent='flex-end' className="ml-1">
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    {
                                        tipo !== 'curso'
                                        ? 
                                            <Grid item>
                                                <Chip className={tipo ==='LICENCIATURA' ?"chip3-blue-400": tipo==='MBA' ? "chip3-orange-400":"chip3-green-500"} label={tipo.toUpperCase()} />
                                            </Grid>
                                        : <Grid item>
                                            <Typography variant='body2' className='variant-xsmall semi-bold text-blue-300' style={{ letterSpacing:'2px' }}>
                                                {tipo.toUpperCase()}
                                            </Typography>                                        
                                        </Grid>
                                    }
                                    <Grid item>
                                        <LogoByOu logoWidth={product.company_id === "UERRE" ? 100 : 60} operativeUnit={product.company_id} />
                                    </Grid>
                                </Grid>                                
                            </Grid>
                            <Grid item style={{ maxHeight: "3.3em", overflow: "hidden", textOverflow: "ellipsis" }}>
                                <Typography variant='h4'>
                                    {product.name}
                                </Typography>
                            </Grid>
                            {
                                !hidePrices &&
                                <Typography>
                                    {<CurrencyFormat value={order.discount != null || order.discount > 0 ? order.discount : 0} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} 
                                    prefix='$' suffix=' de descuento' hidden={order.discount != null && order.discount > 0 ? false:true} style={{ fontWeight:500 }} /> }
                                    {/*<CurrencyFormat value={order.subtotal} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix=" $" suffix=' MXN' hidden={order.subtotal===undefined} style={{ fontWeight:700,textDecoration:'line-through'}} />*/}
                                </Typography>
                            }
                            {
                                !hidePrices &&
                                <Grid item md={4} xs={12}>
                                    <Grid container justifyContent="flex-end" alignItems="center">
                                        <Grid item xs="auto" style={{paddingRight:10}}>
                                            <Typography component='div' align='right' variant="h3" className={classes.total_without_discount} hidden={totalWoDiscount===0}>
                                                <CurrencyFormat value={order.subtotal} hidden={order.discount > 0 ? false:true} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography component='div' align='right' variant="h6" className={classes.price}>
                                                <CurrencyFormat value={order.total || 0} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' />
                                            </Typography>        
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        }
        </>
    )
}

export default OrderProduct
