// Constants
import {LOGOS, LOGOS_RESPONSIVE} from '../constants/source-images';
import {DEFAULT} from '../constants/color-images';
import {getSourceBySourceAndCompany} from '../utils/source';

/*
    sourceId.- Identificador del la fuente de la informacion
    companyId.- (opcional) Identificador de la compania.
    color.- Valor de ../constants/color-images.
    responsive.- Determina que tipo de imagen obtendremos del logo.
*/
export const getLogoBySourceAndCompany = (sourceId, companyId, color = DEFAULT, responsive = false) => {
    let tempSourceId = getSourceBySourceAndCompany(sourceId, companyId);

    if (responsive) {
        return LOGOS_RESPONSIVE[color][tempSourceId];
    }

    return LOGOS[color][tempSourceId];
};

/*
    sourceId.- Identificador del la fuente de la informacion
    color.- Valor de ../constants/color-images.
    responsive.- Determina que tipo de imagen obtendremos del logo.
*/
export const getLogoBySource = (sourceId, color = DEFAULT, responsive = false) => {
    if (responsive) {
        return LOGOS_RESPONSIVE[color][sourceId] ?? null;
    }

    return LOGOS[color][sourceId];
};