import { Grid, Typography } from '@material-ui/core'
import React from 'react'

const NextPaymentDate = ({nextPaymentDateSelected}) => {
    return (
        <Grid item 
            style={{ 
                marginTop: "11px", padding: "16px", paddingTop: "4px", paddingBottom: "4px", 
                background: "#2C3236", borderRadius: "4px" 
            }}
        >
            <Typography variant='caption' className='semi-bold'>
                {`Próximo pago: ${nextPaymentDateSelected}`}
            </Typography>
        </Grid> 
    )
}

export default NextPaymentDate
