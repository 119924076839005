import React, { lazy, Suspense } from 'react';
import { useLocation } from "react-router-dom";

// redux
import { connect } from 'react-redux';

// @material-ui/core components
import { Box } from '@material-ui/core';

// services
import { MarketPlaceService, ProgramService } from '@sdk-point/talisis';

// constants
import { PRODUCT_TYPE } from '../../../shared/constants/orders';

// components
import EnrollmentConfirmationHeader from './EnrollmentConfirmationHeader/EnrollmentConfirmationHeader';
import EnrollmentConfirmationBody from './EnrollmentConfirmationBody/EnrollmentConfirmationBody';

// styles
import useStyles from '../../../assets/styles/jss/pages/market/EnrollmentConfirmation';

//shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
const CourseCarousel = lazy(() => import('../../../shared/components/CourseCarousel/CourseCarousel'));

const EnrollmentConfirmation = (props) => {
    const location = useLocation();
    const classes = useStyles();
    const [product, setProduct] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
    const programServiceSDK = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const { user } = props;

    const getCourseDetails = async (idCourse) => {
        setLoading(true);
        try {
            const params = {
                'personId': user?.person_id,
                'ou': user?.ou,
            };
            const courseData = await marketService.getCourse(idCourse, params);
            if (courseData && !!courseData.id) {
                mapCourseToProduct(courseData);
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    };

    const getProgramDetails = async (idProgram) => {
        setLoading(true);
        try {
            const programData = await programServiceSDK.getProgramById(idProgram, { person_id: user.person_id });
            if (programData && !!programData.id) {
                mapProgramToProduct(programData);
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    };

    const mapCourseToProduct = (data) => {
        setProduct({
            product_type_id: PRODUCT_TYPE.COURSE,
            product_type_name: "Curso",
            company_id: data?.company_id,
            company_name: data?.company_name,
            third_party_id: data?.third_party_id,
            name: data?.name,
            course_id: data?.id,
            source_id: data?.source_id,
            course_url: data?.course_url,
            course_url_neo: data?.user_enrolled_data?.course_url
        });
    };

    const mapProgramToProduct = (data) => {
        setProduct({
            product_type_id: PRODUCT_TYPE.PROGRAM,
            product_type_name: "Programa",
            company_id: data?.company_id,
            company_name: data?.company_name,
            name: data?.name,
            program_id: data?.id,
            level_id: data?.level_id
        });
    };

    const fetchData = async () => {
        const params = new URLSearchParams(location?.search);
        if (params.has('courseId')) {
            const id = params.get('courseId');
            if (id > 0) {
                await getCourseDetails(id);
            }
        } else if (params.has('programId')) {
            const id = params.get('programId');
            if (id > 0) {
                await getProgramDetails(id);
            }
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [location?.search]);

    return <Box>
        <Loader loading={loading} transparent={true} style={{ minHeight: loading ? "calc(90vh - 32px)" : "inherit", borderRadius: "8px", paddingTop: "40px", marginTop: "-65px" }}>
            {product ?
                <Box className={classes.container}>
                    <EnrollmentConfirmationHeader product={product} />
                    <EnrollmentConfirmationBody product={product} />
                    <Box mt="18px">
                        <Suspense fallback={<div></div>}>
                            {product?.course_id > 0 && (
                                <CourseCarousel
                                    isDetail={true}
                                    course_id={product?.course_id}
                                    id='courseDetail'
                                    title='Otros cursos recomendados'
                                    viewCourseDetails
                                    clickFrom={'checkout'}
                                />
                            )}
                            {product?.program_id > 0 && (
                                <CourseCarousel
                                    isDetail={true}
                                    id='catalog::educon_courses'
                                    title="Te recomendamos"
                                    viewCourseDetails
                                    clickFrom={'checkout'}
                                />
                            )}
                        </Suspense>
                    </Box>
                </Box> :
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {!loading && <NothingFound caption={"No encontramos información"} subcaption={""} />}
                </Box>
            }
        </Loader>
    </Box>
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(EnrollmentConfirmation);