import React from 'react';
import { Box, Button, Icon, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import NoNotificationsImage from '../../../../../../assets/images/error-test-notification.svg'

import { PruebasService } from '@sdk-point/talisis'; 
const PruebasServiceSDK = new PruebasService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


const useStyles = makeStyles((theme) => ({
    buttonTest:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 24px",
        gap: "8px",
        marginBottom:'10px',

        width: "179px",
        height: "42px",

        borderRadius: "8px",

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    dialog:{
        '& .MuiDialog-paper':{
            maxWidth: 536,
            border: `1px solid ${theme.palette.colors.fillStroke[400]}`,
        }
    },
    img:{
        maxWidth: 202
    }
}));

const NotTestAssignment = ({enableBttn = false, handleClosePopover, user}) => {
    const classes = useStyles();
    const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
    const [openResponseDialog, setOpenResponsedDialog] = React.useState(false);
    const [responseDilaogProps, setResponsedDialogProps] = React.useState({icon: '', title: '', desc: '', btnText: ''});
  
    const handleClickOpen = async () => {
        setOpenLoadDialog(true);
        let responseMssg = {
            icon: 'ri-checkbox-circle-line text-success-300',
            title: 'Solicitud exitosa',
            desc: 'Han sido asignadas tus pruebas diagnosticas con éxito, puedes realizarlas desde la sección de Evaluaciones que se encuentra en el menú superior.',
            btnText: 'Aceptar'
        }

        try {            
            const response =  await PruebasServiceSDK.postCreateCentralTest({
                "user_id": "A00000000",
                "person_id": user.person_id,
                "scope": "TAL",
                "retry_test": true
            });

        } catch (error) {
            responseMssg = {
                icon: 'ri-error-warning-line text-warning-300',
                title: error.status === 201 ? '¡Vaya! Encontramos un inconveniente' : '¡Ups! Hubo un problema',
                desc: error.data.message,
                btnText: 'Cerrar'
            }
        }

        setOpenLoadDialog(false);
        setResponsedDialogProps(responseMssg);
        setOpenResponsedDialog(true);
    };

    const handleClose = () => {
        setOpenResponsedDialog(false);
        handleClosePopover();
    };

        return <Box p={2} display='flex' gridGap={24} flexDirection='column' alignItems='center'>

            <img src={NoNotificationsImage} className={classes.img}/>
            <Typography variant='body1' color='primary' align='center' className='semi-bold'>Asignación de pruebas diagnósticas</Typography>
            <Typography variant='body2' align='center'>
                Si no puedes visualizar tus evaluaciones: <span className='semi-bold'>Emotion 2.1</span> y <span className='semi-bold'>Razonamiento 20</span> da click en el botón “Solicitar pruebas diagnósticas”.
            </Typography>

            <Button fullWidth variant='contained' color='primary' onClick={handleClickOpen} disabled={!enableBttn}>Solicitar pruebas diagnósticas</Button>
            
            <Dialog aria-labelledby="simple-dialog-title" open={openLoadDialog} fullWidth="sm">
                <Box display='flex' flexDirection='column' gridGap='26px' alignItems='center' py={4}>
                    <Box textAlign='center'>
                        <Typography variant='h4' className='mb-1'>Solicitud en proceso</Typography>
                        <Typography variant='body1' className='text-dark-black-100'>Esto puede demorar un minuto, no cierres la ventana.</Typography>
                    </Box>

                    <CircularProgress size={70}/>

                </Box>
            </Dialog>
            <Dialog aria-labelledby="simple-dialog-title" open={openResponseDialog} fullWidth="sm" className={classes.dialog}>
                <Box>
                    <Box textAlign='end'>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <Icon className='ri-close-line text-dark-black-200' />
                        </IconButton>
                    </Box>

                    <Box textAlign='center' p='0px 40px 32px'>
                        <Box alignItems='center' display='flex' flexDirection='column' gridGap='16px' justifyContent='center' mb={3}>
                            <Icon className={responseDilaogProps.icon}/>
                            <Typography variant='h4' className='mb-1'>{responseDilaogProps.title}</Typography>
                        </Box>
                        <Typography variant='body1' className='text-dark-black-100 mb-4'>{responseDilaogProps.desc}</Typography>
                        <Button variant='contained' color='primary' onClick={handleClose}>{responseDilaogProps.btnText}</Button>
                    </Box>
                </Box>
            </Dialog>
        </Box>
};

export default NotTestAssignment;