import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    container: {
        marginTop: "40px",
        [theme.breakpoints.down('xs')]: {
            marginBottom: "48px",
        },
    },
    courseTitle: {
        maxWidth: "648px"
    },
    programTitle: {
        maxWidth: "648px"
    },
    programDescription: {
        marginTop: "24px",
        maxWidth: "600px"
    },
    supportCard: {
        [theme.breakpoints.up('lg')]: {
            width: "424px",
            marginLeft: "auto",
            marginRight: "0px",
        }
    },
    actionCard: {
        height: "196px",
        [theme.breakpoints.up('lg')]: {
            width: "424px"
        }
    },
    actionCardImg: {
        height: "196px",
        width: "148px"
    },
    buttonCourses: {
        width: "114px !important",
        height: "40px !important",
        '&.MuiButton-text': {
            padding: '8px 10px !important'
        },
        '&:hover': {
            background: 'none',
        }
    },
    logoSource: {
        maxWidth: "168px",
        maxHeight: "32px"
    }
}));