import React from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/core components
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
    List,  Chip,
} from '@material-ui/core';

// core components
import LoaderContent from "../../../../../../shared/components/Loader/LoaderContent"
import NothingFound from '../../../../../../shared/components/NothingFound/NothingFound';


// redux
import { connect } from "react-redux";
import { log } from '../../../../../../shared/utils/console.js'
import { setActiveTab } from '../../../../../../redux/actions/tabsActions';
//SDK
import { PruebasService } from '@sdk-point/talisis'; 
import { Badge, Button, Card, CardContent, Divider } from '@material-ui/core';

import { ArrowDropDown } from '@material-ui/icons';

// styles
import useStyles from '../../../../../../assets/styles/jss/layout/notificationTestListStyle';
import clsx from 'clsx';
import NotTestAssignment from './NotTestAssignment';



const PruebasServiceSDK = new PruebasService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const TAB_PENDING = 0;
const TAB_RESOLVED = 1;

const NotificationTestList = (props) => {
    const { person_id } = props.user;
    const classes = useStyles();
    const [loader, setLoader] = React.useState(true);
    const [tests, setTests] = React.useState([]);

    const [listTest, setListTest] = React.useState([]);
    const [result, setResult] = React.useState(false);
    const [hasPendingAssignment, setHasPendingAssignment] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(TAB_PENDING);
    const [enableBttn, setEnableBttn] = React.useState(false);

    const history = useHistory();

    const getPruebas = async () => {
        await PruebasServiceSDK.getPruebas(person_id, true)
            .then(data => {
                setLoader(false);
                const testsItems = data.tests;
                setTests(testsItems)
                setListTest(testsItems.filter(test => test.resolved == 0))
                setHasPendingAssignment(data.retry_test);
                setEnableBttn(data.enableTest);
            })
            .catch(error => log(error));
    }

    React.useEffect(() => {
       getPruebas();
       handleClickTest();
    }, []);

    const onProfile = () => {
        history.push('/profile')
        props.setActiveTab(0)
    }

    const handleClickTest= () => {
        setListTest(tests.filter(test => test.resolved == 0))
        setResult(false);
    };

    const handleClickResult = () => {
        setListTest(tests.filter(test => test.resolved == 1))
        setResult(true)
    };

    const nothing = {
        type: 'notifications',
        caption: 'No hay evaluaciones Pendientes',
        subcaption: "si quieres ver los resultados de tus evaluaciones, da click en el boton 'ir a mi perfil' o ve a tu perfil directamente",
        extra: (<Button variant="contained" color="primary" onClick={onProfile}
        className={classes.buttonTest} style={{marginTop: '20px', width: '100%'}}
        >Ir a mi perfil</Button>)
    }

    return (
    <LoaderContent loading={loader}>
        <div className={classes.drawerHeader}>
            <Chip
                className={clsx(classes.chip, {['active']: result == false})}
                variant="outlined"
                size="small"
                label='Pendientes'
                onClick={handleClickTest}
            />
            <Chip
                className={clsx(classes.chip, {['active']: result})}
                variant="outlined"
                size="small"
                label='Resueltas'
                onClick={handleClickResult}
            />
        </div>
        <Divider className="bg-fill-stroke-200"/>

        <List className={classes.list}>
            {listTest.map((test, index) => (
                <Card key={index}>
                    <CardContent style={{padding:'0px'}}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography variant="h5" className={classes.title}>
                            {
                                test.title
                            }
                        </Typography>
                        {
                            !result && <Badge classes={{ badge: classes.badgeNotification }} badgeContent=''></Badge>
                        }
                            

                        </div>
                        <Typography variant="body2" color="textSecondary" component="div" className={classes.info} gutterBottom>
                            {
                                test.description
                            }
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="div" className={classes.viewMore} gutterBottom>
                            Ver más <ArrowDropDown />
                        </Typography>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {
                                result ? 
                                <Button variant="contained" color="primary" onClick={onProfile}
                                className={classes.buttonTest}
                                >Ver Resultados</Button>
                                :
                                <Button variant="contained" color="primary" onClick={() => window.open(test.link_test) } 
                                className={classes.buttonTest}
                                >Realizar evaluación</Button> 
                            }
                            
                        </div>
                    </CardContent>
                    <Divider className="bg-fill-stroke-200" style={{marginBottom: '15px'}}/>
                </Card>
            ))}
        </List>


        {   ((!hasPendingAssignment &&  !listTest.length) || (!listTest.length && result) ) && <NothingFound type={nothing.type} subcaption={nothing.subcaption} 
                caption={nothing.caption}>
                    {nothing.extra}
                </NothingFound>
        }

        {(hasPendingAssignment && !result) && <NotTestAssignment enableBttn={enableBttn} handleClosePopover={props.handleClose} user={props.user}/>}
                {/* <FloatingMenuNotifications
                    labelTrue="Resueltas"
                    labelFalse="Pendientes"
                    handleClickTrue={handleClickResult}
                    handleClickFalse={handleClickTest}
                    list={listTest}
                    nothing={nothing}
                    result={result}
                >
                    {listTest.map((test, index) => (
                            <Card key={index}>
                                <CardContent style={{padding:'0px'}}>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <Typography variant="h5" className={classes.title}>
                                        {
                                            test.title
                                        }
                                    </Typography>
                                    {
                                        !result && <Badge classes={{ badge: classes.badgeNotification }} badgeContent=''></Badge>
                                    }
                                        

                                    </div>
                                    <Typography variant="body2" color="textSecondary" component="div" className={classes.info} gutterBottom>
                                        {
                                            test.description
                                        }
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" component="div" className={classes.viewMore} gutterBottom>
                                        Ver más <ArrowDropDown />
                                    </Typography>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        {
                                            result ? 
                                            <Button variant="contained" color="primary" onClick={onProfile}
                                            className={classes.buttonTest}
                                            >Ver Resultados</Button>
                                            :
                                            <Button variant="contained" color="primary" onClick={() => window.open(test.link_test) } 
                                            className={classes.buttonTest}
                                            >Realizar evaluación</Button> 
                                        }
                                        
                                    </div>
                                </CardContent>
                                <Divider className="bg-fill-stroke-200" style={{marginBottom: '15px'}}/>
                            </Card>
                        ))}
                </FloatingMenuNotifications> */}
        </LoaderContent>
    );
};


const mapStateToProps = (reducers) => reducers.userReducer;

const mapDispatchToProps = { setActiveTab };

export default connect(mapStateToProps,mapDispatchToProps)(NotificationTestList);