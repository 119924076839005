import React from 'react';

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Button, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { ChevronLeft, Group, Lock } from '@material-ui/icons';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const FORM_NAME = 'career';
const requiredFields = ['career'];

export const AddCareer = ({ data, onChange, onSetForm, validate, onSubmit }) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        card: {
            backgroundColor: theme.palette.colors.grey[400],
            padding: '1em',
        },
        title: {
            color: theme.palette.colors.fillStroke[100],
            cursor: 'default',
            fontSize: '12px',
            fontWeight: 600,
        },
        label: {
            color: theme.palette.colors.white,
            fontWeight: 600,
            fontSize: '12px',
        },
        inputText: {
            border: `1.5px solid ${theme.palette.colors.fillStroke[200]}`,
            borderRadius: '5px',
            color: theme.palette.colors.white,
            fontSize: '14px',
            padding: '0.5em'
        },
        icon: {
            color: theme.palette.colors.white,
            marginRight: '10px',
        },
        toogleButton: {
            backgroundColor: theme.palette.colors.black,
            border: `2px solid ${theme.palette.colors.fillStroke[200]}`,
            color: theme.palette.colors.white,
            width: '50%',
        },
        toogleButtonSelected: {
            '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: '#3316D2',
                // backgroundColor: theme.palette.colors.fillStroke[100],
                color: theme.palette.colors.white,
            }
        },
        circularProgress: {
            color: theme.palette.colors.white,
        },
        cancelButton: {
            color: theme.palette.colors.white,
        },
        saveButton: {
            backgroundColor: theme.palette.colors.orange[300],
            color: theme.palette.colors.white,
        },
        alertMessage: {
            backgroundColor: theme.palette.colors.grey[500],
            color: theme.palette.colors.white,
            float: 'left',
            fontFamily: "'Source Sans Pro', sans-serif !important",
            fontSize: '12px',
            marginBottom: '10px',
        },
    });

    const classes = useStyles();
    const [errorMessage, setErrorMessage] = React.useState("");

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className={`Profile_Title ${classes.title}`} onClick={() => onSetForm(1)}>
                    <Tooltip title="Regresar" placement="left">
                        <ChevronLeft className="Profile_ChevronPointer Profile_ChevronLeft" />
                    </Tooltip>
                    Puesto actual
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="career"
                    name="career"
                    placeholder='Escribe tu profesión'
                    fullWidth
                    onChange={(e) => onChange(e, FORM_NAME)}
                    inputProps={{
                        maxLength: 100,
                    }}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={`Profile_Title ${classes.label}`}>
                    Elige quien puede ver tu profesión
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ToggleButtonGroup color="primary" style={{ width: '80%'}} exclusive value={data.visibility} onChange={(e, v) => {
                    onChange({ target: { name: "visibility", value: v } }, FORM_NAME)
                }}>
                    <ToggleButton className={`${classes.toogleButton}`} value="public" classes={{
                        selected: classes.toogleButtonSelected
                    }}>
                        <Group fontSize='inherit' />
                        <Typography variant='caption'>Todos los usuarios</Typography>
                    </ToggleButton>
                    <ToggleButton className={`Profile_VisibilityFields ${classes.toogleButton}`} value="private" classes={{
                        selected: classes.toogleButtonSelected
                    }}>
                        <Lock fontSize='inherit' />
                        <Typography variant='caption'>Solo yo</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                <Typography variant="caption" className="Profile_noteContainer mt-1">
                    Cualquier persona puede ver esta información cuando se comunica contigo.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    errorMessage &&
                    <Alert id='car_alert_message' variant="outlined" severity={'error'} className={classes.alertMessage}>
                        {errorMessage}.
                    </Alert>
                }
                {
                    data.toastProps.ok ?
                        <Alert id='car_alert_message' variant="outlined" severity={data.toastProps.ok === 'true' ? 'success' : 'error'} className={classes.alertMessage}>
                            {data.toastProps.message}.
                        </Alert>
                        :
                        <Typography variant="subtitle2" className="Profile_buttonsContainer">
                            <Button
                                color="primary"
                                variant="text"
                                className={`Profile_Button ${classes.cancelButton}`}
                                disabled={data.loading}
                                onClick={() => onSetForm(1)}>
                                Cancelar
                            </Button>
                            <Button
                                color="primary"
                                variant="outlined"
                                className={`Profile_Button ${classes.saveButton}`}
                                disabled={data.loading}
                                onClick={() => {
                                    const error = validate(requiredFields);
                                    if (error) {
                                        setErrorMessage("Favor de completar los campos requeridos");
                                        setTimeout(() => {
                                            if (document.getElementById('car_alert_message')) {
                                                document.getElementById('car_alert_message').style.visibility = 'hidden';
                                                setErrorMessage("");
                                            }
                                        }, 4000);

                                        return;
                                    }

                                    onSubmit(FORM_NAME);
                                    setTimeout(() => onSetForm(0), 4000);
                                }}>
                                {data.loading && <CircularProgress size={20} className={classes.circularProgress} />}
                                {!data.loading && 'Guardar'}
                            </Button>
                        </Typography>
                }
            </Grid>
        </Grid>
    );
};
