import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { Avatar, Box, Typography } from '@material-ui/core';

const AvatarPerson = ({initialName, authorName}) => {
    return (
        <>
            <Avatar className="bg-electric-blue-300 text-white">
                {initialName}
            </Avatar>
            <Box ml={2}>
                <Typography variant="body2" className="semi-bold mb-1">{authorName}</Typography>
                <Typography variant="body2">Organizador</Typography>
            </Box>
        </>
    );
};

AvatarPerson.defaultProps = {
    initialName: '',
    authorName: '',
};

AvatarPerson.propTypes = {
    initialName: PropTypes.string,
    authorName: PropTypes.string,
};

export default AvatarPerson;