import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// styles
import useStyles from '../../../../../../assets/styles/jss/pages/market/PaymentConfirmation';

const NextPaymentDate = ({ product, membership }) => {
    const classes = useStyles();
    const nextPaymentDate = membership?.next_payment_date || product?.next_payment_date;

    return (
        <Box className={classes.boxNextPaymentDate} p="8px" textAlign="center">
            <Typography variant="body1" className='text-white'>Fecha próximo pago: {nextPaymentDate}</Typography>
        </Box>
    );
};

export default NextPaymentDate;