import React from 'react';

import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

function Instructor(props) {
    const {name, description, image_instructor_url} = props;
    return (
        <Card>
            <CardContent style={{padding: 32}}>
                <Typography variant="h4" className="mb-4">Instructor</Typography>
                <Box mb={2} display="flex" alignItems="center">
                    <Avatar src={image_instructor_url} style={{width: 48, height: 48, marginRight: 22}}/>
                    <Typography variant="h6" color="secondary" className="font-weight-700">{name}</Typography>
                </Box>
                <Typography variant="body1">{description}</Typography>
            </CardContent>
        </Card>
    );
}

export default Instructor;