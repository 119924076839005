export const PERMISSION_OBJ =[
    {
      required: true,
      type: 'muber',
      label: 'Id',
      name: 'id',
      defaultValue: '',
      length: 100,
      onChange: null,
      disabled: true,
      visibility: 'none',
      error: false,
      helperText: ''
    },
    {
    required: true,
    type: 'text',
    label: 'Nombre',
    name: 'name',
    defaultValue: '',
    length: 100,
    onChange: null,
    disabled: false,
    visibility: 'block',
    error: false,
    helperText: ''
  },
  {
      required: true,
      type: 'text',
      label: 'Descripción',
      name: 'description',
      defaultValue: '',
      length: 100,
      onChange: null,
      disabled: false,
      visibility: 'block',
      error: false,
      helperText: ''
    },
    {
      required: true,
      type: 'select',
      label: 'Object',
      name: 'object_id',
      defaultValue: '',
      length: 100,
      onChange: null,
      error: false,
      helperText: '',
      items:[]
    },
    {
      required: true,
      type: 'select',
      label: 'Acción',
      name: 'action_id',
      defaultValue: '',
      length: 100,
      onChange: null,
      error: false,
      helperText: '',
      items :[{id:1, label:'read'},
              {id:2, label:'write'},
              {id:3, label:'read-write'},
              {id:4, label:'edit'},
              {id:5, label:'delete'}
            ]
    },
    {
      required: false,
      type: 'select',
      label: 'Object padre',
      name: 'parent_id',
      defaultValue: '',
      length: 100,
      onChange: null,
      disabled:true,
      error: false,
      helperText: '',
      visibility: 'none',
      items:[]
    }
] 