import React from 'react';
import clsx from 'clsx';

// @material-ui
import { Box, Typography } from "@material-ui/core"

// assets
import useStyles from '../../../../../../assets/styles/jss/pages/checkout/checkout';

const RecurringPaymentDate = ({ nextPaymentDate }) => {
    const classes = useStyles();

    return <Box className={clsx(classes.recurringPaymentDate, "my-3")}>
        <Typography variant="body2" className='font-weight-600 font-size-12' component='span'>Pago domiciliado:</Typography>&nbsp;
        <Typography variant="body2" className='text-gray-300 font-size-12' component='span'>El próximo cobro se realizará automáticamente a tu método de pago el día {nextPaymentDate}</Typography>
    </Box>
}

export default RecurringPaymentDate;