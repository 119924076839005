import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon,Box,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

import Select from '../../../../Form/Select/Select'
import InputSearch from '../../../../Form/InputSearch/InputSearch'

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '0px 16px',
        display:'flex',
        flex: 1,
        border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: 8
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            flexDirection: 'column',
        },
        selectContainer:{
            flexDirection: 'column',
            width: '100%',
            '& div':{
                width: '100%',
            }
        },
        input: {
            width: '100%'
        },
    }
}));

const SearchByInput = ({selectValue, searchValue, items, onChange, onKeyDown, onClickDelete, selectContainerProps}) => {
    const classes = useStyles();
    const [search, setSearch] = React.useState({
        selectValue: '',
        searchValue: ''
    })

    const [placeholder, setPlaceholder] = React.useState('Buscar')

    React.useEffect(() => {
        setSearch({
            selectValue,
            searchValue
        });

        const itemSelected = items.find(it => it.id === selectValue);

        setPlaceholder(itemSelected.placeholder || itemSelected.label)
    }, [selectValue, searchValue]);

    const handleChangeSelect = (event) => {
        onChange({name: event.target.value, value: searchValue})
    };

    const handleChangeSearchValue = (value) => {
        onChange({name: selectValue, value})
    };

    return (
        <Box display='flex' alignItems='center' gridGap={8} className={classes.root}>
            <Box display='flex' flex="1" alignItems='center' gridGap={8} className={classes.selectContainer} {...selectContainerProps}>
                <Box minWidth="72px">
                    <Typography variant='body2' className='text-dark-black-200'>Buscar por</Typography>
                </Box>
                <Box display='flex' flex="1">
                    <Select
                        value={search.selectValue}
                        name="search-select" 
                        items={items}
                        onChange={handleChangeSelect}
                    />
                </Box>
            </Box>
            <InputSearch
                className='input-search' 
                value={search.searchValue}
                onKeyDown={() => onKeyDown()} 
                onChange={handleChangeSearchValue} 
                onClickDelete={() => onClickDelete()}
                placeholder={placeholder}
            />
        </Box>
    );
};

SearchByInput.defaultProps = {
    onChange: () => {},
    onClickDelete: () => {},
    items:[],
};

export default SearchByInput;