import React from 'react'

// @material-ui
import { Box, Grid, Icon, Modal, Typography } from '@material-ui/core';

// assets
import useStyles from '../../../../../../assets/styles/jss/pages/checkout/checkout';

const CardModal = ({ openModal, onModalClose, children }) => {
    const classes = useStyles();

    return (
        <Modal
            open={openModal}
            onClose={onModalClose}
            className={classes.checkoutModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ maxWidth: "596px", backgroundColor: '#1C1F24', textAlign: 'center', paddingX: '20px', paddingY: '20px', borderRadius: '8px' }} className={classes.checkoutModalInside}>
                <Grid container direction="row">
                    <Grid item xs={12} className="mb-1">
                        <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid item>
                                <Typography variant='h4' className="text-gray-200">
                                    Nueva tarjeta de crédito o débito
                                </Typography>
                            </Grid>
                            <Grid item style={{ cursor: "pointer" }} onClick={onModalClose}>
                                <Icon className='ri-close-line font-size-24 text-dark-black-200' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: '14px' }}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default CardModal;