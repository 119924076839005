import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    cardUserRoot: {
        padding: '16px 8px',
        width: '100%',
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        display: 'flex',
        borderRadius: '8px',
        backgroundColor: theme.palette.colors.darkBlack[500],
        boxSizing: 'border-box',
        cursor: 'pointer',
    },
    infoContainer: {
        marginLeft: '8px',
        width: 'calc(100% - 48px)',
    },
    roleContainer: {
        marginTop: '4px',
    },
}));