import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root:{
        position: 'relative',
        marginLeft: 0,
        borderRadius: 40,
        backgroundColor: 'transparent',
        minWidth: 52,
        '&.expanded':{
            backgroundColor: theme.palette.colors.fillStroke[400],
            borderRadius: 121,  
        },
        '&:hover':{
            cursor: 'pointer'
        }
    },
    searchIcon:{
        height: '100%',
        display: 'flex',
        padding: '0 17px',
        position: 'absolute',
        alignItems: 'center',
        pointerEvents: 'none',
        justifyContent: 'center',
    },
    iconButton:{
        width: 30,
        height: 30,
        paddingLeft: 0,
        display: props => props.inputfocus || props.expanded ? 'inline-flex' : 'none',
        [theme.breakpoints.down('xs')]:{
            marginLeft: '10px',
            alignItems: 'center'
        }
    },
    inputPadding: {
        [theme.breakpoints.down('xs')]:{
            padding: '11px 10px !important'
        }
    },
    inputBase:{
        backgroundColor:'transparent',
        '& .MuiInputBase-input':{
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: 'calc(1em + 10px)',
            transition: theme.transitions.create('width'),
            width: '100%',
            color: theme.palette.colors.white,
            width: '0ch',
            backgroundColor:'transparent',
            border: 'unset',
            '&:hover':{
                cursor: 'pointer'
            },
            '&:focus ':{
                paddingLeft: 'calc(1em + 26px)',
                borderRadius: 4,
                width: 280,
                cursor: 'text',
                paddingLeft: '54px !important',
            }
        }
    },
    inputBaseExpanded:{
        paddingLeft: 54,
        width: 'calc(100% - 54px)',
    },
    iconButtoFixed:{
        background: 'inherit',
        padding: '12px 17px 13px',
        position: 'relative',
        borderRadius: 0,
        height: props=> props.isLogged ? 65 : 80,
        '&.active': {
          background: theme.palette.colors.fillStroke[400],
          '&::before': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 8,
            borderTop: `8px solid ${theme.palette.colors.violet[400]}`,
          },
        },
    }
}));