import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import ExpandableFAQ from './ExpandableFAQ/ExpandableFAQ'

const FAQsComponent = ({ title, questionsList = [], innerRef }) => {

    return (
        <Grid container justifyContent="center" ref={innerRef} style={{ scrollMargin: "7.3em", marginTop: '3rem', marginBottom: '2rem' }}>
            <Grid item md={12} xs={12}>
                <Typography variant='h2' className='mb-4'>
                    { title }
                </Typography>
                <Grid container className="mt-2">
                {
                    (questionsList.length > 0) &&
                    questionsList.map(( faqInfo, index) => 
                        <ExpandableFAQ
                            key={index}
                            faqQuestion={ faqInfo.question}
                            faqAnswer={ faqInfo.answer}
                        />
                    )
                }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FAQsComponent

//! Mock
// const faqList = [
//     { id: 1, question: "¿Cuándo podré acceder a las lecciones y tareas?", answer: "Proximamente..."},
//     { id: 2, question: "¿Qué recibiré si compro el certificado?", answer: "Como egresado de la maestría en Mercadotecnia y MEdios Digitales podrás desempeñarte en cualquier tipo de organización dentro del sector productivo, manufacturero, financiero, gubernamental, de salud, educativo y de servicios, o en un area de desarrollo profesional distinguida por la mejora continua y la optimización de sistemas de trabajo." },
//     { id: 3, question: "¿Hay ayuda económica disponible?", answer: "Proximamente..."},
//     { id: 4, question: "¿Cuándo podré acceder a las lecciones y tareas?", answer: "Como egresado de la maestría en Mercadotecnia y MEdios Digitales podrás desempeñarte en cualquier tipo de organización dentro del sector productivo, manufacturero, financiero, gubernamental, de salud, educativo y de servicios, o en un area de desarrollo profesional distinguida por la mejora continua y la optimización de sistemas de trabajo." },
// ]
