import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Icon,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// @DS
import LogoSource from '../../../../design-system/components/LogoSource/LogoSource';
import { SOURCES } from '../../../../design-system/constants/sources';

import clsx from 'clsx';

const SelectCategory = (props) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    categoriesContainer: {
      padding: '0 16px 0 16px',
    },
    categoryCard: {
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: theme.palette.colors.fillStroke[400],
      width: '100%',
      textAlign: 'center',
      padding: '16px 8px',
      height: '100%',
    },
    categoryCardSelected: {
      backgroundColor: theme.palette.colors.electricBlue[300],
    },
    categoryIcon: {
      fontSize: 36,
      textAlign: 'center'
    },
    categoryTitle: {
      ...theme.typography.bodyTextLargeRegular,
      [theme.breakpoints.down('xs')]:{
        ...theme.typography.bodyTextSmallRegular
      }
    },
    gridPlataforma: {
      padding: '0 4px 4px 0' 
    },
    gridAdministracion: {
      padding: '0 0 4px 4px' 
    },
    gridEscolarAcademica: {
      padding: '4px 4px 0 0' 
    },
    gridMembresia: {
      padding: '4px 0 0 4px'
    },
    cardDesign:{
      height: '100%',
      borderRadius: 4
    },
    titleBox:{
      height: 52,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]:{
        height:50
      }
    },
    logoTalisis:{
      width:25,
      height:24
    }
  });

  const {categories, handleCategory, selectedCategory, setDisableSend} = props;

  const handleSelectedCategory = catId => {
    handleCategory(catId)
  }

  React.useEffect(() => {
    if(selectedCategory) setDisableSend(false)
    if(!selectedCategory) setDisableSend(true)
  }, [])
  
  const classes = useStyles();

  return (
    <Grid container className={classes.categoriesContainer} >
      <Grid xs={6} className={classes.gridPlataforma}>
        <Card className={classes.cardDesign}>
          <CardActionArea onClick={() => handleSelectedCategory(categories[0]?.id)} className={`${classes.categoryCard} ${categories[0].id === selectedCategory ? classes.categoryCardSelected : ''}`} id={categories[0].id}>
            <CardContent className='p-0'>
              <Box mb={1} className={classes.titleBox}>
                <LogoSource sourceId={SOURCES.TALISIS} color='white' responsive className={classes.logoTalisis}/>
                <Typography className={classes.categoryTitle}>Plataformas</Typography>
              </Box>
              <Typography variant="caption" className="variant-caption-sm text-gray-400">Acceso a tus cursos de Neo o afiliados, recuperaración de contraseña y problemas con tu cuenta institucional</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid xs={6} className={classes.gridAdministracion}>
        <Card className={classes.cardDesign}>
          <CardActionArea onClick={() => handleSelectedCategory(categories[1]?.id)} className={`${classes.categoryCard} ${categories[1].id === selectedCategory ? classes.categoryCardSelected : ''}`} id={categories[1].id}>
            <CardContent className='p-0'>
              <Box mb={1} className={classes.titleBox}>
                <Icon className={clsx(categories[1].id === selectedCategory ? 'ri-calculator-fill' : 'ri-calculator-line', 'font-size-24')} />
                <Typography className={classes.categoryTitle}>Administración</Typography>
              </Box>
              <Typography variant="caption" className="variant-caption-sm text-gray-400">Estados de cuenta, métodos de pago y facturación</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid xs={6} className={classes.gridEscolarAcademica}>
        <Card className={classes.cardDesign}>
          <CardActionArea onClick={() => handleSelectedCategory(categories[3]?.id)} className={`${classes.categoryCard} ${categories[3].id === selectedCategory ? classes.categoryCardSelected : ''}`} id={categories[3].id}>
            <CardContent className='p-0'>
              <Box mb={1} className={classes.titleBox}>
                <Icon className={clsx(categories[3].id === selectedCategory ? 'ri-folder-5-fill' : 'ri-folder-5-line', 'font-size-24')} />
                <Typography className={classes.categoryTitle}>Escolar y Académica</Typography>
              </Box>
              <Typography variant="caption" className="variant-caption-sm text-gray-400">Documentos de inscripción, historial académico, visualización de horarios, evaluaciones y calificaciones</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid xs={6} className={classes.gridMembresia}>
        <Card className={classes.cardDesign}>
          <CardActionArea onClick={() => handleSelectedCategory(categories[2]?.id)} className={`${classes.categoryCard} ${categories[2].id === selectedCategory ? classes.categoryCardSelected : ''}`} id={categories[2].id}>
            <CardContent className='p-0'>
              <Box mb={1} className={classes.titleBox}>
                <Icon className={clsx(categories[2].id === selectedCategory ? 'ri-user-star-fill' : 'ri-user-star-line', 'font-size-24')} />
                <Typography className={classes.categoryTitle}>Membresías</Typography>
              </Box>
              <Typography variant="caption" className="variant-caption-sm text-gray-400">Información, adquisición, pagos, cambios y renovaciones</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  )
};

SelectCategory.defaultProps = {
  isHome: false,
  isCheckout: false,
};

SelectCategory.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};


export default SelectCategory;
