import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';

// material
import { Grid, makeStyles, TextField, Typography, Box } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// shared component
import SimpleSearch from '../../../../shared/components/SimpleSearch/SimpleSearch';
import Select from '../../../../design-system/components/Form/Select/Select';

const useStyles = makeStyles(theme => ({
}));

const Filters = ({ouList, onOuChange, ouValue, onChangeTextFilter, onChangeDate, dates}) => {
    const classes = useStyles();

    const handleChangeDate = (prop) => (value) => {
        if (!value._isValid) {
            return;
        }
        
        let tempNewDate;
        if (prop === 'fechaFin') {
            const tempDateStart = dates['fechaIni'];
            const tempDateEnd = value;

            tempNewDate = tempDateEnd.valueOf() < tempDateStart.valueOf() ? tempDateStart : value;
        }else if (prop === 'fechaIni') {
            const tempDateStart = value;
            const tempDateEnd = dates['fechaFin'];

            tempNewDate = tempDateStart.valueOf() > tempDateEnd.valueOf() ? tempDateEnd : value;
        }

        onChangeDate(prop, tempNewDate);
    };

    return (
        <Grid container spacing={3} direction="column">
            <Grid item xs={12}>
                <Grid container spacing={3} direction="row">
                    <Grid item xs={3} md={3}>
                        <Select items={ouList} label="Marca" className={``} name="ouSelectStatement" onChange={onOuChange} value={ouValue} size="small" fullWidth={true}/>
                    </Grid>
                    <Grid item xs={5} md={4}>
                        <Box display="flex" alignItems="end" height={1}>
                            <Box height="41px" width={1}>
                                <SimpleSearch onChange={onChangeTextFilter} placeholder='Busqueda por clave o concepto'/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={5}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                            size='small'
                                            name="fecha_inicio"
                                            label="Fecha Inicio"
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="DD/MM/YYYY"
                                            InputAdornmentProps={{ position: "start"}}
                                            fullWidth={true}
                                            value={dates.fechaIni.format('YYYY-MM-DD')}
                                            onChange={handleChangeDate("fechaIni")}
                                            keyboardIcon={<i className="ri-calendar-2-line text-fill-stroke-100"></i>}
                                        />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                            size='small'
                                            name="fecha_final"
                                            label="Fecha Fin"
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="DD/MM/YYYY"
                                            InputAdornmentProps={{ position: "start"}}
                                            fullWidth={true}
                                            value={dates.fechaFin.format('YYYY-MM-DD')}
                                            onChange={handleChangeDate("fechaFin")}
                                            keyboardIcon={<i className="ri-calendar-2-line text-fill-stroke-100"></i>}
                                        />
                                </Grid>
                            </Grid>

                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

Filters.propTypes = {
    ouList: PropTypes.array.isRequired, // Listado de unidades operativas
    onOuChange: PropTypes.func.isRequired, // Evento para el cambio del selector de unidades operativas
    ouValue: PropTypes.string.isRequired, // Valor de la unidad operativa
    onChangeDate: PropTypes.func.isRequired, // Evento para cambiar la fecha
    dates: PropTypes.object.isRequired // Valores de las fechas
};

export default Filters;