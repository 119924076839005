import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// @redux
import { connect } from 'react-redux';
// design-system
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import LoaderContent from '../../../design-system/components/Loader/LoaderContent';
import Toast from '../../../design-system/components/Toast/Toast';
import Table from '../../../shared/components/Table/Table';
// @material-core
import { Box, Card, CardContent, Grid, Button,Icon, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from "../../../design-system/components/Tabs/Tabs";
//@ThirtyParty
import { concat, isEmpty, isNil } from 'ramda';
import clsx from 'clsx';
// local
import ProfileBasic from '../Student/Profile/ProfileBasic';
import ProfileInfo from '../Components/Profile/ProfileInfo';
import { ceritificateDetails } from '../ColumnsTable';
import KardexDetails from "../Components/Certification/KardexDetails";
import RequirmentsDetails from "../Components/Certification/RequirmentsDetails";
// const DegreeServiceSDK = new DegreeService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return ({
    rootGrid: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '50px'
      },
      marginBottom:20,
      marginTop:20,
      marginRight:2
    },
    loading: {
      color: '#ffffff',
      width: '50%',
      height: '70%'
    },
    cardTable:{
      height:'97%'
    }
  })
});

const TABS = [
  'Kardex Y Equivalencias',
  'Requisitos para certificación',
];

const ModalDetails = (props) => {

  const classes = useStyles();
  const theme =  useTheme()
  const [tabsProps, setTabsProps] = React.useState({tabs: TABS, activeTab: 0});
  const [toast, setToast] = React.useState({ toastProps: { message: '', open: false } })
  const [loading, setLoading] = React.useState(false)

  const handleFinishedToast = () => {
    setToast({ toastProps: { open: false, message: '' } })
  }
  const handleChangeTab = activeTab => {
    setTabsProps({tabs: TABS, activeTab})
  };
  return (
    <React.Fragment>
      <Tabs
        onChange={handleChangeTab}
        {...tabsProps}
      >
      {!isNil(props.student?.datosGenerales) ?
        <LoaderContent loading={loading}>
          {tabsProps.activeTab === 0 &&  <KardexDetails {...props} setLoading={setLoading} setToast={setToast}/>}
          {tabsProps.activeTab === 1 &&  <RequirmentsDetails  {...props} setLoading={setLoading}/>}
        </LoaderContent>
        : null
      }
      </Tabs>
      <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={5000} />
    </React.Fragment>
  )

};
const mapStateToProps = ({ userReducer, profilePicReducer }) => ({
  // user: userReducer.user,
  // profilePic: profilePicReducer.profilePic
});

const mapDispatchToProps = { /*...userActions, ...profilePicActions, ...menuActions, ...membershipActions */ };
export default connect(mapStateToProps, mapDispatchToProps)(ModalDetails);
