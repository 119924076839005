import React from 'react';
import { 
    Box,
    Typography,
    Icon,
    Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    tooltip:{
        border: '1px solid #F2C80B',
    },
    creditDot:{
        width:32,
        height:32,
        background: 'linear-gradient(274.05deg, #CDACFC -8.09%, #8B3FFA 61.08%)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const ProgressAccordionDetail = ({credits = 0}) => {
    const classes = useStyles();

    return (
        <Box display='flex' gridGap={24} flexDirection='column' pb={3}>
            <Box display='flex' alignItems='center' gridGap={8}>
                <Typography variant="h4">Resumen del Programa</Typography>
                <Tooltip arrow classes={{ tooltip: classes.tooltip }}
                    title="Debes completar cada cuatrimestre antes de pasar al siguiente, el orden de las materias es recomendado pero puedes tomarlas en el orden que mejor se te acomode." 
                >
                    <Icon className="ri-error-warning-line text-warning-300 font-size-20"/>
                </Tooltip>
            </Box>
            <Box display='flex' gridGap={12} flexDirection='column'>
                <Typography variant="body1">Este listado excluye materias fuera de plan y revalidaciones.</Typography>
                <Box display='flex' alignItems='center' gridGap={8}>
                    <Tooltip arrow classes={{ tooltip: classes.tooltip }}
                        title="Puedes cursar hasta 2 materias al estar inscrito en este programa siempre que sean del mismo cuatrimestre." arrow classes={{ tooltip: classes.tooltip }}>
                        <Icon className="ri-error-warning-line text-warning-300 font-size-20"/>
                    </Tooltip>
                    <Typography variant="body1">Créditos disponibles:</Typography>
                    <Box className={classes.creditDot}>
                        {credits}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ProgressAccordionDetail;