import React from 'react';
import PropTypes from 'prop-types';

// core components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Card } from '@material-ui/core';

// components
import ActionEventButton from '../ActionEventButtons/ActionEventButton';

// style
import useStyles from '../../../../../assets/styles/jss/pages/events/principalEventCardStyle';

// utils
import dateTimeFormat from '../../../../../shared/utils/dateTimeFormat';

// Shared constants
import { EVENT_ADD_HOUR } from '../../../../../shared/constants/event-add-hours';

const processDateTime = (date, startTime) => dateTimeFormat(`${date.split("T")[0]}T${startTime.split("T")[1]}`, undefined, EVENT_ADD_HOUR);

const PrincipalEventCard = ({event, onClickInterestingEvent, showButtonInterest}) => {
    const classes = useStyles();

    return (
        <>
            <Card style={{height: '458px'}}>
                <Box sx={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${event.image})`}} className={classes.backgroundContainer}>
                    <Box sx={{height: "50%"}}></Box>

                    <Box sx={{height: "50%"}}>
                        <Box className={classes.contentInfoEvent}>
                            <Box>
                                <Typography variant='h1'>{event.title}</Typography>
                            </Box>

                            <Box className="offset-margin-top-16">
                                <Typography variant='body2'>{processDateTime(event.schedules[0].date, event.schedules[0].start_time)}</Typography>
                            </Box>

                            <Box className={`offset-margin-top-17`}>
                                <ActionEventButton fullSize={false} interestingEvent={event.is_interested} eventId={event.id} onClickInterestingEvent={onClickInterestingEvent} showButtonInterest={showButtonInterest}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </>
    );
};


PrincipalEventCard.defaultProps = {
    showButtonInterest: true,
};

PrincipalEventCard.propTypes = {
    event: PropTypes.object.isRequired,
    onClickInterestingEvent: PropTypes.func
};

export default PrincipalEventCard;