import React, { useState, useEffect } from 'react'

import { FormControl, Grid, Icon, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { setMonthlyPaymentPlan } from '../../../../../../redux/actions/legacyCheckoutActions';

const MonthlyPaymentsDropdown = ({ availableMonths }) => {

    // Checkout Reducer
    const { membershipOrderFeePlans, orderQuantity } = useSelector((state) => state.legacyCheckoutReducer);
    const dispatch = useDispatch();

    const [openMenuList, setOpenMenuList] = useState(false)
    const [availablePayments, setAvailablePayments] = useState([])
    const [selectedOption, setSelectedOption] = useState(1)
    const [singlePayment, setSinglePayment] = useState(false)
    
    const handleChange = (event) => {
        setSelectedOption(event.target.value)
        dispatch(setMonthlyPaymentPlan(event.target.value))
    }

    const isSinglePayment = ( paymentOptions ) => {
        const singlePayment = 1
        return paymentOptions.includes(singlePayment)        
    }

    useEffect(() => {
        if(availableMonths.length === 0 ) return

        const filteredOptions = availableMonths.filter(( month ) => 
            month === membershipOrderFeePlans.selectedPlan.term_months
        );

        if(isSinglePayment(filteredOptions)){
            setSinglePayment(true)
        }

        const formatMonths = filteredOptions.map((option) => ({
            id: option, label: "meses sin intereses de"
        }));

        setAvailablePayments(formatMonths)
    }, [])

    return (
        <>
        {
            availablePayments.length > 0 &&
            <Grid item xs={12}>
                <Grid container justifyContent='center'>
                    <FormControl fullWidth style={{ background: "#1D1E23", borderRadius: "8px"}}>
                        <Select
                            onOpen={() => setOpenMenuList(true)}
                            onClose={() => setOpenMenuList(false)}
                            id="customized-select"
                            value={selectedOption}
                            onChange={handleChange}
                            disabled={singlePayment}
                            fullWidth
                            endAdornment={
                                <Icon style={{ verticalAlign: 'middle', display: 'inline-flex'}}
                                    className={ openMenuList 
                                        ? "ri-arrow-up-s-line font-size-24 mr-2"  
                                        : "ri-arrow-down-s-line font-size-24 mr-2"
                                    }
                                />
                            }
                            disableUnderline
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                MenuListProps: { style: { width: '100%' } }
                            }}
                            inputProps={{ IconComponent: () => null }}
                        >
                            <MenuItem value={1}>
                            { `Pago de contado por $${orderQuantity?.order_total}` }
                            </MenuItem>
                        {
                            !singlePayment &&
                            availablePayments.map((option) => 
                                <MenuItem value={option.id}>
                                    {`${option.id} ${option.label} `}
                                    <CurrencyFormat
                                        value={(orderQuantity?.order_total/option.id)} className="text-gray-100 ml-1"
                                        decimalScale={2} fixedDecimalScale={true} displayType="text" 
                                        thousandSeparator={true} prefix="$"
                                    />
                                </MenuItem>
                            )
                        }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        }
        </>
    )
}

export default MonthlyPaymentsDropdown
