import React from 'react';

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// shared
import Dropdown from '../../../../../shared/components/Dropdown/Dropdown';
import sortArrayObjects from '../../../../../shared/utils/sortArrayObjects'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const OrderButton = ({items:options, arrayToSort, onChangeOrder}) => {
    const [textButton, setTextButton] = React.useState("");

    React.useEffect(()=> {
        if(!textButton && options.length && arrayToSort.length){
            handleClickSort(options[0]);
        }
    }, [arrayToSort])

    const handleClickSort = (item) => {
        setTextButton(item.label)

        const _steps = [...arrayToSort];
        sortArrayObjects(_steps, 'id', item.id);

        onChangeOrder(_steps)
    }

    return (
        <React.Fragment>
            <Typography className="font-variant-v9">Mostar:</Typography>
            <Box display="flex" alignItems="center">
                <Dropdown 
                    style={{
                        minWidth: 145,
                        justifyContent: 'space-between',
                        textTransform: 'none'
                    }}
                    options={options} 
                    onClickOption={handleClickSort}
                    text={<Typography className="font-variant-v8">{textButton}</Typography>}
                    endIcon={<ArrowDropDownIcon className="font-size-16"/>}
                />
            </Box>
        </React.Fragment>
    );
};

export default OrderButton;