import { Button, Card, Divider, Typography, Box, Icon, CardContent } from '@material-ui/core';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root:{
        '& .step':{
            minWidth: 70
        }
    },
    list:{
        paddingLeft: 20,
        ...theme.typography.body2,
        color: theme.palette.colors.blue[300],
        display: 'grid',
        gridGap: 8
    }
}));

const ShippingDetail = ({data}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root} id="shipping-detail-root">
            <Box display='flex' flexDirection='row' gridGap={16} pb={3}>
                <Box>
                    <Typography variant='h4' className='text-violet-300 step'>Paso 1</Typography>
                </Box>
                <Box display='flex' gridGap={16} flexDirection='column'>
                    <Typography variant='body1' className='text-gray-100'>Descarga e imprime la guía de envío.</Typography>
                    <Button 
                        variant="outlined" color="primary" 
                        startIcon={<Icon className='ri-download-2-line text-gray-100'/>} 
                        href={data?.label_url}
                        target='_blank'
                    >
                        Descargar guía de envío
                    </Button>
                </Box>
            </Box>
            <Divider className='bg-fill-stroke-200'/>
            <Box display='flex' flexDirection='row' gridGap={16} py={3}>
                <Box>
                    <Typography variant='h4' className='text-violet-300 step'>Paso 2</Typography>
                </Box>
                <Box>
                    <Typography variant='body1' className='text-gray-100'>Guarda en un sobre los siguientes documentos:</Typography>
                    <Box>
                        <ul className={classes.list}>
                            {
                                data?.documents.map((doc, index) =>
                                    <li key={`doc-list-${index}`}>{doc.name}</li>
                                )
                            }
                        </ul>  
                    </Box>
                    <Typography variant='body1' className='text-gray-100'>
                        Cierra el sobre y pega la guía de envío al exterior. 
                        Medidas máximas del sobre: 35cm x 24cm x 1.5cm (Largo, ancho y altura) 
                        con un peso máximo de 1 kilo.
                    </Typography>
                </Box>
            </Box>
            <Divider className='bg-fill-stroke-200'/>
            <Box py={3}>
                <Box display='flex' flexDirection='row' gridGap={16} pb={3}>
                    <Typography variant='h4' className='text-violet-300 step'>Paso 3</Typography>
                    <Typography variant='body1' className='text-gray-100'>Entrega el sobre en la sucursal de NombrePaqueteía más cercana.</Typography>
                </Box>
                <Box>
                    <Card elevation={6}>
                        <CardContent>
                            <Typography variant='body1' className='text-gray-100 semi-bold mb-2'>
                                Revisa el estatus de la guía: <span className='text-orange-300'>{data?.tracking_number}</span>
                            </Typography>
                            <Box display='flex' flexDirection='column' gridGap={16}>
                                {
                                    data?.tracking.events.map((trackEvent, index) => 
                                        <Box display='flex' flexDirection='row' gridGap={8} alignItems='center' key={`track-event-${index}`}>
                                            <Icon className="ri-checkbox-circle-fill text-fill-stroke-100 font-size-24"/>
                                            <Box>
                                                <Typography variant='body1' className='text-gray-100 semi-bold'>{trackEvent.status}</Typography>
                                                <Typography variant='body1' className='text-dark-black-100'>{trackEvent.status_text}</Typography>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};

ShippingDetail.defaultProps = {
    data:{
        label_url: '',
        documents: [],
        tracking_number: '',
        tracking:{
            events:[]
        }
    }
}

export default ShippingDetail;