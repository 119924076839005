import { makeStyles } from '@material-ui/core/styles';
//import '../../../../pages/Market/Public/pages/CourseCatalog/Styles/CourseCatalog.css';
export default makeStyles((theme) => ({
    list:{
        paddingTop: 16
    },
    listItemLink:{
        '& .MuiListItemIcon-root':{
            minWidth: 23
        },
        [theme.breakpoints.down('xs')]:{
            padding: '4px 16px'
        }
    },
    root: {
        position: "relative",
        background: theme.palette.colors.darkBlack[500],
        padding: "40px 56px 25px",
        display: "flex",
        flexDirection: "column",
        "@media (max-width: 500px)": {
            paddingLeft: "15px",
            paddingRight: "15px",
            width: "calc(100% - 30px)",
            boxSizing: "content-box",
        }
    },
    content_footer:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "1720px",
        marginLeft: "auto",
        marginRight: "auto",
        "@media (max-width: 1440px)": {
            justifyContent: "space-between",
        },
        "@media (max-width: 1300px)": {
            flexDirection: "column",
            alignItems: "center"
        }
    },
    content_message_footer:{
        display: "flex",
        flexDirection: "column",
        marginRight: "7.7%",
        "@media (max-width: 1300px)": {
            marginBottom: "80px",
            width: "100%",
            maxWidth: "595px",
        },
        "@media (max-width: 828px)": {
            marginRight: "auto",
            maxWidth: "630px",
            width: "100%",
            marginRight: "0%",
        },
        "@media (max-width: 720px)": {
            width: "100%",
            maxWidth: "452px",
            marginRight: "0%"
        },
        [theme.breakpoints.down('xs')]:{
            marginBottom: "0px",
        }
    },
    message_footer:{
        fontFamily: 'Poppins !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "32px",
        width: 440,
        marginBottom: 61,
        "@media (max-width: 530px)": {
            width: "90%",
        },
        "@media (max-width: 487px)": {
            width: "100%",
            fontSize: "21px",
            lineHeight: "25px"

        }
    },
    logo_footer:{
        width: "119.42px",
        marginBottom: 24
    },
    link_email: {
        display: "flex",
        flexDirection: "row",
        textDecoration: "none"
    },
    content_info_footer:{
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },
    content_info_footer_row:{
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        padding: 0,
        gridGap: 142,
        height: 80,
        "@media (max-width: 828px)": {
            flexDirection: 'column',
            gridGap: 16,
            alignItems: 'flex-start',
        },
        [theme.breakpoints.down('xs')]:{
            paddingTop: '30px',
            borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        }
    },
    row_info_footer:{
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        padding: '30px 0px',
        gridGap: 142,
        borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        "&:nth-child(3)": {
            [theme.breakpoints.down('xs')]:{
                order: '2',
                borderBottom: 0
            }
        },
        "&:nth-child(4)": {
            [theme.breakpoints.down('xs')]:{
                order: '1'
            }
        },
        "&:nth-child(5)": {
            marginBottom: "0px",
        },
        "@media (max-width: 730px)": {
            flexDirection: "column",
        },
        "@media (max-width: 530px)": {
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            gridGap: 16,
            alignItems: 'flex-start',
        }
    },
    contacto_info_footer:{
        display: "flex",
        flexDirection: "column",
    },
    contacto_flex_column_footer:{
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        lineHeight: "16px"
    },
    contacto_info_row_footer:{
        display: "flex",
        flexDirection: "row",
        marginBottom: "16px",
        "&:last-child":{
            marginBottom: "0px",
        }
    },
    title_info_footer:{
        ...theme.typography.subtitle2,
        minWidth: "135px",
        "&:nth-child(3)":{
            marginBottom: "0px",
        },
        [theme.breakpoints.down('xs')]:{
            fontSize: '16px'
        }
    },
    title_info_footer_redes:{
        ...theme.typography.body1,
        [theme.breakpoints.down('xs')]:{
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.colors.darkBlack[200],
            width: '100%',
            textAlign: 'center'
        }
    },
    title_info_footer_row:{
        ...theme.typography.subtitle2,
        "&:nth-child(3)":{
            marginBottom: "0px",
        },
        "@media (max-width: 828px)": {
            marginRight: "30px",
        },
        [theme.breakpoints.down('xs')]:{
            fontSize: '16px'
        }
    },
    contacto_icon_footer:{
        width: "16px",
        marginRight: "9.33px"
    },
    marcas_info_footer:{
        paddingLeft: "5px",
        width: "463px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: "-21px",
        "@media (max-width: 730px)": {
            marginLeft: "0px",
            justifyContent: "center"
        },
        "@media (max-width: 530px)": {
            flexWrap: "wrap",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    marcas_uerre_footer:{
        width: "66.6px"
    },
    marcas_iesalud_footer:{
        width: "61px"
    },
    marcas_l_d_footer:{
        width: "51.5px"
    },
    marcas_harmon_hall_footer:{
        width: "110px"
    },
    marcas_card_footer:{
        width: "84.37px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        alignItems: "center",
        marginRight: "88px",
        "@media (max-width: 487px)": {
            margin: "20px",
        },
        "@media (max-width: 375px)": {
            margin: "10px",
        },
        "@media (max-width: 320px)": {
            margin: "3px",
        },
        "&:nth-child(-n+3)":{
            marginBottom: "44px",
            "@media (max-width: 487px)": {
                marginBottom: "20px",
            }
        },
        "&:nth-child(3)":{
            marginRight: "0px",
            "@media (max-width: 487px)": {
                margin: "20px",
            },
            "@media (max-width: 320px)": {
                margin: "10px",
            },
        },
        "&:nth-child(6)":{
            marginRight: "0px",
            "@media (max-width: 487px)": {
                margin: "20px",
            },
            "@media (max-width: 375px)": {
                margin: "20px 20px 6px 20px",
            },
            "@media (max-width: 320px)": {
                margin: "10px 10px 1px 10px",
            },
        }
    },
    marcas_advenio_footer:{
        width: "66.5px"
    },
    marcas_unid_footer:{
        width: "56.6px"
    },
    flex_end:{
        justifyContent: "flex-end",
    },
    redes_info_footer:{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 28,
        '& .icon-twitter':{
            width: '100%',
            height: 20
        }
    },
    redes_footer:{
        width: 18,
        height: 18
    },
    divisor:{
        position: "absolute",
        width: "92%",
        top: "53px",
        height: "1px",
        background: theme.palette.colors.fillStroke[100],
        "@media (max-width: 1300px)": {
            width: "87%",
        },
        "@media (max-width: 778px)": {
            width: "82%",
        },
        "@media (max-width: 530px)": {
            width: "76%",
        },
        "@media (max-width: 460px)": {
            width: "67.5%",
        }
    },
    content_copy_footer:{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-start',
        padding: '14px 0',
        gridGap: 10,
        "@media (max-width: 730px)": {
            flexDirection: "column-reverse",
            alignItems: "center",
            justifyContent: "center"
        },
        [theme.breakpoints.down('xs')]:{
            marginBottom:'30px'
        }
    },
    titles_copy_footer:{
        borderTop: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        height: 41,
        display: 'flex',
        width: '100%',
        alignItems: 'end',
        '& .copyright':{
            position: 'absolute',
            left: '50%'
        },
        "@media (max-width: 730px)": {
            alignItems: "flex-start",
            //flexDirection: 'column',
            '& .copyright':{
                position: 'inherit',
            }
        },
        [theme.breakpoints.down('xs')]:{
            justifyContent: 'space-around',
            alignItems: 'center',
            flexWrap: 'wrap'
        }
    },
    text_inter:{
        fontFamily: 'Inter !important'
    },
    title_copy_footer:{
        ...theme.typography.caption,
        color: theme.palette.colors.darkBlack[200],
        [theme.breakpoints.down('xs')]:{
            marginTop: '25px',
        },
        "&:nth-child(1)":{
            marginRight: "57px",
            "@media (max-width: 530px)": {
                marginRight: "20px",
            }
        },
        "&:nth-child(2)":{
            marginRight: "289px",
            "@media (max-width: 1170px)": {
                marginRight: "20%",
            },
            "@media (max-width: 730px)": {
                marginRight: "0px",
                marginBottom: "10px"
            }
        }
    },
    margin_botom:{
        marginBottom: "35px",
        "&:nth-child(3)":{
            marginRight: "0px",
        }
    },

    footer_login:{
        background: theme.palette.colors.fillStroke[500],
        top:'auto',
        bottom: 0,
        padding: '16px 16px 24px',
        gap: '16px',
        height: '136px'
    },
    footer_login_btn:{
        display: 'flex',
        flexDirection: "column",
        padding: '16px 24px',
        gap: '8px',
    },
    btn_float:{
        width: '100%',
        "&:nth-child(2)":{
            background: theme.palette.colors.fillStroke[500],
            color: theme.palette.colors.orange[300],
        }
    },
    /* BPT */
    social:{
        flexDirection: 'column',
        display: 'flex',
        paddingTop: 16,
        [theme.breakpoints.down('sm')]:{
            alignItems: 'center',
            padding: '32px 0',
        }
    },
    logo:{
        [theme.breakpoints.down('sm')]:{
            padding: '0px 16px',
            marginBottom: 40,
        }
    },
    boxDescargaAPP:{
        flexDirection: 'column',
        padding: '32px 32px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        [theme.breakpoints.down('sm')]:{
            padding: '32px 32px',
        }
    },
    boxEntrarPlataforma:{
        flexDirection: 'column',
        padding: '32px 32px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        [theme.breakpoints.down('sm')]:{
            padding: '32px 32px',
        }
    },
    marginApps: {
        marginBottom: '24px'
    },
    iniciarStyles:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '24px',
        marginTop: '32px'
    }
}));