// @material-ui/icons
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

export const DELETE_EDIT_OPTIONS = [
    {
        id: 1, 
        label: 'editar',
        icon: <EditIcon fontSize="small" />
    },
    {
        id: 2, 
        label: 'eliminar',
        icon: <DeleteIcon fontSize="small" />
    },
]