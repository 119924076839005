import React, { useState } from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { Box, Typography, Button } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";

import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';
import LoaderContent from '../../shared/components/Loader/LoaderContent';
import Table from '../../shared/components/Table/Table';
import { makeStyles } from '@material-ui/core/styles';

import ConfirmDialog from '../../shared/components/ConfirmDialog/ConfirmDialog';
import Toast from '../../shared/components/Toast/Toast';
import { ButtonsDelEdit, SimpleStatus } from '../../shared/components/Table/components';
import { log } from '../../shared/utils/console.js'
import { ActionState } from './components';

import { MarketPlaceService } from '@sdk-point/talisis';
import { PATH_NAME_DETECTOR_FOR_SHOW_INFO } from './constants';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');


/* CUSTOM NAME FOR TABLE COUPONS ACTIVE TYPE */
const TABLE_TYPE_COUPONS = "coupons";

const ACTION_STATE_LOADING = 0;
const ACTION_STATE_SUCCESS = 1;
const ACTION_STATE_ERROR = 2;

const MONTHS_NAME = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
];

const NAME_STATUS_BY_ACTIVE_STATUS = [
    "Vencido",
    "Activo",
    "Inactivo",
    "Programado",
];

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTreeItem-label': {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontSize: '12px'
        },
        '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        '& .MuiCollapse-root': {
            marginLeft: '0px',
        },
    },
    menu: {
        display: 'flex',
    },
    addButton: {
        float: 'right',
        marginTop: '-41px',
        fontFamily: "'Source Sans Pro', sans-serif",
    },
    download: {
        '&:hover': {
            cursor: "pointer",
        }
    }
}));

const Coupons = () => {
    const classes = useStyles();
    const { user } = useSelector((state) => state.userReducer);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [actionState, setActionState] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const history = useHistory();
    const [confirmDialogProps, setConfirmDialogProps] = React.useState({
        group_id: '',
        title: '',
        content: '',
        open: false
    });
    const [couponId, setCouponId] = React.useState(0);

    const [Toastprops, setToastProps] = React.useState({ message: '' });
    const items = [
        { to: '/home', label: 'Comercial' },
        { label: 'Promociones' },
        { label: 'Descuentos' },
    ]

    const columns = [
        { id: '_name_promo', name: 'name_promo', label: <Typography variant="h6" className="text-dark-black-100" style={{ paddingLeft: "16px" }}>{'Nombre de Promoción'}</Typography>, minWidth: 280, align: 'left', paddingLeft: "16px" },
        { id: '_code_discount', name: 'code_discount', label: <Typography variant="h6" className="text-dark-black-100">{'Código de descuento'}</Typography>, minWidth: 214, align: 'left' },
        { id: '_status', name: 'status', label: <Typography variant="h6" className="text-dark-black-100">{'Estatus'}</Typography>, minWidth: 100, align: 'left' },
        { id: '_canj_disp', name: 'canj_disp', label: <Typography variant="h6" className="text-dark-black-100">{'Canjeados/Disp.'}</Typography>, minWidth: 138, align: 'left' },
        { id: '_period', name: 'period', label: <Typography variant="h6" className="text-dark-black-100">{'Periodo de duración'}</Typography>, minWidth: 200, align: 'left' },
    ];
    
    columns.push({ id: '_actions', name: 'actions', label: <Typography variant="h6" className="text-dark-black-100">{'Acciones'}</Typography>, minWidth: 100, align: 'left' });
    

    React.useEffect(() => {
        let isCanceled = true;
        setLoading(true);
        getCouponsList().then((data) => {
            if (isCanceled) {
                if (data || data?.length != 0) {
                    setRows(data);
                    setLoading(false);
                }
            }
        }).catch((err) => {
            log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, []);

    const handleEditClick = (e, coupon_id) => {
        e.stopPropagation();
        log("Editar cupón");
        log(coupon_id);
    };

    //Modal Confirm delete
    const handleDeleteClick = async (e, coupon_id, total_used) => {
        e.stopPropagation();
        e.preventDefault();
        log('coupon_id', coupon_id);
        let TITLE_MODAL_DELETE = '¿Estás seguro de eliminar este cupón?'
        let TEXT_MODAL = `Este cupón aún no ha sido aplicado por ningún usuario. 
                          En caso que ya lo hayas compartido con usuarios, estos no podrán aplicarlo a partir de ahora.`;
        let TITLE_BUTTON_OK="Eliminar cupón";
        let TITLE_BUTTON_CANCELAR="Cancelar";
        let COLOR_BUTTON_CLOSE = "secondary";
        let VARIANT_BUTTON_CLOSE = "outlined";
        let DISABLE_BUTTON_DELETE = false;
        let WITH_BUTTON_OK = '220PX';
        if(total_used > 0 ) {
            TITLE_MODAL_DELETE = 'No es posible eliminar este cupón';
            TEXT_MODAL = `El cupón ya fue entregado y canjeado por algunos usuarios. Si se elimina, los usuarios faltantes no podrán canjearlo. 
                          Si deseas solo inhabilitarlo temporalmente puedes cambiar su estatus a “inhabilitado” entrando al detalle del cupón.`;
            DISABLE_BUTTON_DELETE = true; //false for test
            TITLE_BUTTON_OK = "";
            WITH_BUTTON_OK = "0px";
            TITLE_BUTTON_CANCELAR = "Ok";
            COLOR_BUTTON_CLOSE = "primary";
            VARIANT_BUTTON_CLOSE = "contained"
        } else {
            setCouponId(coupon_id); 
        }
        setConfirmDialogProps({title: TITLE_MODAL_DELETE, 
                               disabledConfirmButton:DISABLE_BUTTON_DELETE,
                               text:TEXT_MODAL, 
                               titleButtonOk:TITLE_BUTTON_OK,
                               titleButtonClose:TITLE_BUTTON_CANCELAR,
                               colorButtonClose:COLOR_BUTTON_CLOSE,
                               variantButtonClose: VARIANT_BUTTON_CLOSE,
                               withButtonClose: "220px",
                               withButtonOK: WITH_BUTTON_OK,
                               type:'warning', 
                               open: true})
    };

    const getFormatedName = (date) => {
        const d = new Date(moment.utc(date).local().format().toLocaleString());
        const monthIndex = d.getMonth();
        const monthName = MONTHS_NAME[monthIndex];
        const day = d.getDate();
        const year = d.getFullYear();
        return day + " " + monthName + " " + year;
    }

    const getFullDateName = (start_date, end_date) => {
        let dateLabel = " - ";
        if (start_date && end_date) {
            dateLabel = `${getFormatedName(start_date)} - ${getFormatedName(end_date)}`;
        } else if (start_date) {
            dateLabel = `${getFormatedName(start_date)} -`
        }
        return dateLabel;
    }

    const changeToStatusName = (status, end_date, start_date) => {
        if (status) {
            let now = new Date();
            let startDate = new Date(start_date);
            let endDate = new Date(end_date);
            if (end_date && start_date) {
                if ((now < endDate)) {
                    if (now > startDate) {
                        return NAME_STATUS_BY_ACTIVE_STATUS[1];
                    }
                } else{
                    return NAME_STATUS_BY_ACTIVE_STATUS[0];
                }
            }else{
                if (now > startDate) {
                    return NAME_STATUS_BY_ACTIVE_STATUS[1];
                }
            }
    }
    return NAME_STATUS_BY_ACTIVE_STATUS[2];
}

const changeToStatusColor = (status, end_date, start_date) => {
    if (status) {
        let now = new Date();
        let startDate = new Date(start_date);
        let endDate = new Date(end_date);
        if (end_date && start_date) {
            if ((now < endDate)) {
                if (now > startDate) {
                    return 1;
                }
            }
        } else{
            if (now > startDate) {
                return 1;
            }
        }
    }
    return 2;
}

const goTo = (e, pathName) => {
    e.preventDefault();
    history.push('/comercial/promociones/descuentos/' + pathName);
}

const getCouponsList = async () => {
    try {
        //CALL TO SERVER AND GET COUPONS INFO
        let couponsRows = [];
        const coupons = await marketService.getCouponsList();
        coupons.map((coupon) => {
            let labelStatusName = changeToStatusName(coupon?.is_active, coupon?.end_date, coupon?.start_date);
            let labelColorStatus = changeToStatusColor(coupon?.is_active, coupon?.end_date, coupon?.start_date);
            couponsRows.push({
                name_promo: <Box display="flex" flexDirection="column" style={{ paddingLeft: "16px", maxWidth: "280px" }}>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>{coupon?.name}</Typography>
                    <Typography style={{ lineHeight: "12px" }} className="text-gray-500 caption-small">{coupon?.description}</Typography>
                </Box>,
                code_discount: <Typography variant="body2" style={{ fontWeight: "600" }}>{coupon?.code}</Typography>,
                status: <SimpleStatus text={labelStatusName} state={labelColorStatus} />,
                canj_disp: <Typography variant="body2" className="text-fill-stroke-100">{coupon?.total_used + " / " + (coupon?.usage_limit != -1 ? coupon?.usage_limit : "ilimitado")}</Typography>,
                period: <Typography variant="body2" className="text-fill-stroke-100">{getFullDateName(coupon?.start_date, coupon?.end_date)}</Typography>,
                actions: <ButtonsDelEdit reverse={true} handleEditClick={(e) => goTo(e, PATH_NAME_DETECTOR_FOR_SHOW_INFO[1] + "?cupon=" + coupon?.id)} handleDeleteClick={(e) => handleDeleteClick(e, coupon?.id, coupon?.total_used)}></ButtonsDelEdit>
            });
        });
        return couponsRows;
    } catch (error) {
        throw new Error(error);
    }
}

const handleDeleteConfirm = async () => {
        handleDeleteClose();
        setLoading(true);
        window.scrollTo(0, 0);
        setErrorMessage(null);
        setActionState(null);
        try {
            const response = await marketService.deleteCoupon(couponId);
             if(response.status===true) {
                setActionState(ACTION_STATE_SUCCESS);
            } else {
                setErrorMessage('El cupón no puede ser eliminado ya que se encuentra en uso');
                setActionState(ACTION_STATE_ERROR);
            }
        } catch (e) {
            log(e);
            setErrorMessage(JSON.stringify(e));
            setActionState(ACTION_STATE_ERROR);
        }
        getCouponsList().then((data) => {
            if (data || data?.length != 0) {
                setRows(data);
                setLoading(false);
            }

        }).catch((err) => {
            log(`Failed: ${err}`);
        });
};

const handleDeleteClose = () => {
    setConfirmDialogProps({open: false})
};

//CIERRA LA CAJA DE ESTADO SUCCESS/ERROR
const closeBoxState = (event) => {
    event.preventDefault();
    setActionState(null);
}

return (
    <>
        <Breadcrumb items={items} separator="/" />
        <Typography variant="h3" style={{ fontWeight: "600", marginTop: "24px" }}>{"Descuentos"}</Typography>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" style={{ marginBottom: "29px", alignItems: "center", }}>
            {/*<Box className={clsx(classes.download)} display="flex" justifyContent="center" style={{ marginRight: "37px" }}>
                    <Typography className={clsx("text-dark-black-200")} variant="h6" style={{ fontWeight: "600", textTransform: "initial", marginRight: "6px" }}>{"Descargar reporte"}</Typography>
                    <Icon className={clsx("text-dark-black-200","ri-download-line")} style={{fontSize: "1rem",}} fontSize='small'/>
                </Box>*/}
            <Button variant="contained" color="primary" onClick={(e) => goTo(e, PATH_NAME_DETECTOR_FOR_SHOW_INFO[0])}>
                <Icon className={clsx("text-white", "ri-add-line")} fontSize='small' style={{ marginRight: "6px" }} />
                <Typography variant="body2" style={{ fontWeight: "600", textTransform: "initial" }}>{"Crear nuevo cupón"}</Typography>
            </Button>
        </Box>

        { /* CAJA DE ESTADOS AL CREAR-EDITAR */}
        <ActionState type={"eliminado"} state={actionState} onClose={closeBoxState} errorMessage={errorMessage} couponCode={null} />
        <Box mt="47px"></Box>
        <LoaderContent loading={loading}>
            {!loading &&
                <React.Fragment>
                    {rows && <Table type={TABLE_TYPE_COUPONS} maxHeight="inherit" columns={columns} rows={rows} />}
                    <ConfirmDialog {...confirmDialogProps} onConfirm={handleDeleteConfirm} onClose={handleDeleteClose} />
                 </React.Fragment>}
        </LoaderContent>
    </>
);
};

export default Coupons;