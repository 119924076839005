import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    backgroundContainer: {
        width: "100%",
        height: "100%",
        backgroundSize: "cover"
    },
    contentInfoEvent: {
        marginLeft: "50px",
        width: "447px"
    },
    containerActionButtons: {
        display: "inline-block"
    }
}));
