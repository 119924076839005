import { height } from 'dom7';
import React from 'react'

// Logos
const logo_images = ({
    ADVENIO: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/advenio.svg",
    COURSERA: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/coursera.svg",
    TALISIS: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis.svg",
    UERRE: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/uerre.svg",
    UNID: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/unid.svg",
    HARMONHALL: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/harmonhall.svg",
    ISESALUD: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/isesalud.svg",
});


const LogoByOu = ({ logoWidth = 80, logoHeight, operativeUnit = ""}) => {  

  return (
    <>
        <img alt="logo" src={logo_images[operativeUnit]} style={{maxWidth: logoWidth, height: logoHeight && logoHeight}} />
    </>
  )
}

export default LogoByOu
