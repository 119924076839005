import React from 'react'

import { Button, Card, CardContent, Grid, Hidden, Typography, useMediaQuery, makeStyles, Divider } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "8px 8px 24px 24px",
    },
    divideFullWidth: {
        backgroundColor: `${theme.palette.colors.grey[700]}`,
        marginLeft: '-24px',
        marginRight: '-24px',
    },
    cardTitle: {
        paddingBottom: "8px"
    }
}));

const SummaryStep = ({ isActive, stepNumber, stepTitle, modifyBtnAction, modifyBtnHidden, component, membershipPlan }) => {
    const theme = useTheme();
    const classes = useStyles();
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <>
            {
                !isActive ?
                    <>
                        {
                            !modifyBtnHidden &&
                            <Hidden mdUp>
                                <Card style={{ background: "#1D1E23", border: "1px solid #2C3236" }}>
                                    <CardContent className={classes.root}>
                                        <Grid container className={classes.cardTitle} alignItems='baseline' justifyContent='space-between'>
                                            <Typography variant='h5' className={breakpoint ? "text-dark-black-200" : ""}>
                                                {stepTitle}
                                            </Typography>
                                            {
                                                !modifyBtnHidden &&
                                                <Button onClick={modifyBtnAction}>
                                                    <Typography variant='caption' className='text-orange-300' style={{ cursor: 'pointer' }}>
                                                        <i className="ri-arrow-left-line mr-1" style={{ verticalAlign: 'text-bottom', display: 'inline-flex' }} />
                                                        Modificar
                                                    </Typography>
                                                </Button>
                                            }
                                        </Grid>
                                        <Divider light className={classes.divideFullWidth} />
                                        {
                                            membershipPlan &&
                                            <Grid container className='pt-4' style={{ borderTop: `${(stepNumber > 1) && 1}px solid ${theme.palette.colors.fillStroke[200]}` }}>
                                                <Typography variant='body2'>
                                                    {membershipPlan}
                                                </Typography>
                                            </Grid>
                                        }
                                    </CardContent>
                                </Card>
                            </Hidden>
                        }
                        <Hidden smDown>
                            <Grid
                                container className={`pt-3 ${!isActive && "pb-3"}`}
                                alignItems='baseline' justifyContent='space-between'
                                style={{ borderBottom: `${(stepNumber > 1) && 1}px solid ${theme.palette.colors.fillStroke[200]}` }}
                            >
                                <Typography variant='h3' className='text-dark-black-200'>
                                    {stepTitle}
                                </Typography>
                                {
                                    !modifyBtnHidden &&
                                    <Button onClick={modifyBtnAction}>
                                        <Typography variant='caption' className='text-orange-300' style={{ cursor: 'pointer' }}>
                                            <i className="ri-arrow-left-line mr-1" style={{ verticalAlign: 'text-bottom', display: 'inline-flex' }} />
                                            Modificar
                                        </Typography>
                                    </Button>
                                }
                            </Grid>
                        </Hidden>
                    </>

                    : <Grid container style={{ borderTop: (stepTitle === 'Método de pago' && !breakpoint) ? `1px solid ${theme.palette.colors.fillStroke[200]}` : '' }}>
                        {component}
                    </Grid>
            }
        </>
    )
}

export default SummaryStep
