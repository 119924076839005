import React from 'react';
import { connect } from "react-redux";

// @material-ui/core
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// icons
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "238px",
        maxWidth: "238px",
        marginRight: theme.spacing(2),
        marginBottom: "15px"
    },
    content: {
        padding: "1rem !important",
        position: "relative",

        "& .headline": {
            lineHeight: "1.5em !important",
            maxHeight: "3em",
            display: "-webkit-box",
            overflow: "hidden",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 2,
        },
        "& .card-box": {
            display: "flex",
            alignItems: "center",

            "& .schedule": {
                lineHeight: "normal",
                marginLeft: "2px"
            },
            "& .initial-price": {
                textDecoration: "line-through"
            },
            "& .price": {
                marginRight: "6px",
                fontWeight: "700"
            }
        }
    },
    media: {
        height: 140,
        position: "relative"
    },
    rating: {
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        padding: theme.spacing(0.5, 1),
        borderRadius: "4px",
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: "12px"
    },
    saveButton: {
        position: "absolute",
        top: 8,
        right: 8,
    },
    buttons: {
        display: "flex"
    }
}));


const SurveyCard = (props) => {
    const classes = useStyles();
    const { urlSurvey } = props;

    const handleClick = (e) => {
        e.preventDefault();
        window.open(urlSurvey, '_blank').focus();
    };

    return (
        <Card className={classes.root}>
            <CardActionArea component="div" disableTouchRipple onClick={handleClick}>
                <CardContent className={classes.content}>
                    <Box style={{ marginTop: "-5px", width: "100%", textAlign: "center" }} className="mt-3 mb-3">
                    <Typography style={{ textTransform: "capitalize"}} variant="body1" className="headline">{props.descripcion}</Typography>
                        <Typography style={{ textTransform: "capitalize"}} variant="body2" color="textSecondary">{props.materia}</Typography>
                    </Box>
                    <Box className="card-box mb-3">
                        <EventAvailableIcon color="primary" fontSize="small" />
                        <Typography variant="body2" color="textSecondary" className="schedule">{props.periodo}</Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(SurveyCard);
