import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    inputContainer: {
        border: `1px solid ${theme.palette.border.input}`,
        position: 'relative',
        'border-radius': 20,
        'margin-top': 6,

        '&:hover':{
            'border-color': theme.palette.border.inputHover
        },

        '&.error':{
            border: '1px solid #e53935 !important',
    
            '&input':{
                '::-webkit-input-placeholder': { /* WebKit, Blink, Edge */
                    color:    '#f55c54',
                },
                ':-moz-placeholder': { /* Mozilla Firefox 4 to 18 */
                    color:    '#f55c54',
                    opacity:  1,
                },
                '::-moz-placeholder': { /* Mozilla Firefox 19+ */
                    color:    '#f55c54',
                    opacity:  1,
                },
                ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
                    color:    '#f55c54',
                },
                '::-ms-input-placeholder': { /* Microsoft Edge */
                    color:    '#f55c54',
                },
                '::placeholder': { /* Most modern browsers support this now. */
                    color:    '#f55c54',
                }
            }
        },

    },
    button:{
        color: '#fff',
        float: 'left',
        cursor: 'pointer',
        padding: '7px 14px',
        'font-size': '0.875rem',
        transition: 'background-color .2s ease-out',
        'line-height': '2.5rem',
        'border-radius': 20,
       ' text-transform': 'uppercase',
        'background-color': theme.palette.primary.main,
        display: 'inline-flex',
        'align-items': 'center',

        '& input':{
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            bottom: 0,
            cursor: 'pointer',
            margin: 0,
            opacity: 0,
            padding: 0,
            overflow: 'visible',
            position: 'absolute',
            'font-size': 13,
            '&:hove':{
                cursor: 'pointer',
            }
        }
    },
    inputFile:{
        height: 37,
        display: 'flex',
        padding: '0 10px',
        overflow: 'hidden',
        'align-items': 'center',

        '& input': {
            color: theme.palette.text.primary,
            width: '100%',
            border: 'none',
            height: '2.5rem',
            outline: 'none',
            padding: 0,
            'font-size': 13,
            'border-radius': 0,
            'background-color': 'transparent',


            '::-webkit-input-placeholder': { /* WebKit, Blink, Edge */
                color:  theme.palette.text.tertiary,
            },
            ':-moz-placeholder': { /* Mozilla Firefox 4 to 18 */
               color:    theme.palette.text.tertiary,
               opacity:  1,
            },
            '::-moz-placeholder': { /* Mozilla Firefox 19+ */
               color:    theme.palette.text.tertiary,
               opacity:  1,
            },
            ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
               color:    theme.palette.text.tertiary,
            },
            '::-ms-input-placeholder': { /* Microsoft Edge */
               color:    theme.palette.text.tertiary,
            },
            '::placeholder': { /* Most modern browsers support this now. */
               color:    theme.palette.text.tertiary,
            }
        }
    }
}));