import React from 'react'

// @material-ui/core
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Hidden from "@material-ui/core/Hidden";

//styles
import { makeStyles } from '@material-ui/core/styles';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    title: {
        ...theme.typography.v3,
        marginBottom: theme.spacing(2)
    },
    chip:{
        margin: '0px 16px 16px 0px !important',
        background: theme.palette.black,
        border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: 24,
        '&.active':{
            background: theme.palette.colors.fillStroke[300] + ' !important',
            border: `0.5px solid ${theme.palette.colors.fillStroke[100]} !important`,
        },
        maxWidth: '100%',
    },
    interest:{
        position:  'relative',
        // maxHeight: 418,
        //maxHeight: 305,
        maxWidth: '537px',
        //overflowY: "auto"
    },
    box: {
        '&:not(:last-child)':{
            marginBottom: '16px'
        }
    },
    modalityChip: {
        margin: '3px',
        padding: "1px",
        '& .MuiChip-root': {
            margin: '2px',
            '& .MuiChip-label': {
            }
        }
    },
    button:{
        padding: '16px 59px',
        marginBottom: 25
    },

}));

const InterestList = (props) => {
    const classes = useStyles();

    const {interests, handleChange, onSetActive, onSetOnCard} = props;
    const [interestSelect, setInterestSelect] = React.useState([]);
    const [academySelect, setAcademySelect] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);

    const handleValidationSelect = (idacademy) => {
        let newAcademySelect = academySelect;
        const i = academySelect.indexOf(idacademy);
        let allAcademiesSelected;
        
        if (i < 0){
            newAcademySelect.push(idacademy);
            setAcademySelect(newAcademySelect);
        }else if(i >= 0 && !interestSelect.some(it => it.idAcademy === idacademy)){
            newAcademySelect.splice(i, 1)
        }

        allAcademiesSelected = interests.map(it => it.id).every(val => academySelect.includes(val));

        return allAcademiesSelected;
    }

    const handleSetActive = (interestList, validacion) => {
        if (validacion && interestList.length <= 10) {
            setOpen(true);
        }else{
            setOpen(false);
        }

        setDisabled(interestList.length >= 10);
    }

    const handleClick = (item, idacademy) => {

        let { id, name } = item;
        let isExist = false
        let newSelect = interestSelect;
        let filter = interestSelect.filter(academy => academy.id == id && academy.idAcademy == idacademy)
        let numFilter = interestSelect.filter(academy => academy.id == id);
        if (filter.length > 0){
            let indexobj = interestSelect.findIndex(academy => academy.id == id && academy.idAcademy == idacademy)
            interestSelect.splice(indexobj, 1);
            newSelect = interestSelect
            setInterestSelect(newSelect)
            isExist = true;
            if (numFilter.length == 1){
                handleChange(id, isExist, 2, 1);   
            }else{
            handleChange(id, isExist, 2, 3);

            }     
        }else{
            newSelect.push(item);
            setInterestSelect(newSelect);
            handleChange(id, isExist, 2);
        }
        let validate = handleValidationSelect(idacademy);
        handleSetActive(newSelect, validate);
      };

    return (
        <Box mt="42px" width={'100%'}>
            <Box className={classes.interest}>
            {
                interests.map((i) => (
                    <Box className={classes.box} key={i.id}>
                        <Typography className={classes.title}>{i.name}</Typography>
                        <Box mt={2} className={classes.modalityChip}>
                            {
                                i.items.map((el) => (
                                    <Chip
                                        className={clsx(classes.chip, {['active']: interestSelect.some(e => e.id == el.id && e.idAcademy == el.idAcademy)})}
                                        disabled={disabled && !interestSelect.some(e => e.id === el.id && e.idAcademy === el.idAcademy)}
                                        key={el.id}
                                        variant="outlined"
                                        size="small"
                                        value={el.id}
                                        label={el.name.toLowerCase()}
                                        onClick={() => handleClick(el, i.id)}
                                    />
                                ))
                            }
                        </Box>
                    </Box> 
                ))
            }
            </Box>

            <Box width="100%" display="flex" justifyContent="center" mt={3}>
                <Hidden mdUp implementation="css" style={{flex: 1}}>
                    <Button onClick={() => onSetOnCard(1)} startIcon={<ChevronLeftIcon />} className={classes.button}>
                        Regresar
                    </Button>
                </Hidden>
                <Button variant='contained' color="primary"
                        disabled={open ? false : true}
                        onClick={() => onSetOnCard(3, 1)}
                        className={classes.button}
                    >
                    Siguiente
                </Button>
            </Box>

        </Box>
    )
}

export default InterestList;
