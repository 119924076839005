import React from "react";
import PropTypes from 'prop-types';

// @Material
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

// Styles
import useStyles from '../../../../assets/styles/jss/pages/checkout/ProductAssignment.js';

// ds
import LogoSource from '../../../../design-system/components/LogoSource/LogoSource';
import { CHIP_CLASSNAME, LEVEL_NAME } from '../../../../design-system/constants/levels';
import { ReactComponent as MisCursosFillSVG } from '../../../../design-system/assets/images/icons/mis-cursos-fill.svg';

const ProgramCard = ({ product }) => {
    const propsStyles = { image_url: product?.product_image_url };
    const classes = useStyles(propsStyles);
    return (
        <Card className={classes.programCard}>
            <Box className={classes.programCardContent}>
                <LogoSource color='white' sourceId={product?.source_id} disabledAutoRes responsive={false} className={classes.logoSource} />
                <Box className="mt-3">
                    <Icon component={MisCursosFillSVG} />
                </Box>
                <Typography variant="h4" >{product?.product_name?.substring(0, 45)}</Typography>
                <Typography variant="h4" className={'mb-3'} >{product?.modality_name}</Typography>
                <Chip className={CHIP_CLASSNAME[product?.level_id]} label={LEVEL_NAME[product?.level_id]} />
            </Box>
        </Card>
    )
}

ProgramCard.defaultProps = {
    programInfo: {
        product_image_url: ''
    }
}

ProgramCard.propTypes = {
    programInfo: PropTypes.shape({
        product_image_url: PropTypes.string.isRequired,
        source_id: PropTypes.number.isRequired,
        level_id: PropTypes.string.isRequired,
        modality: PropTypes.string.isRequired,
        card_description: PropTypes.string.isRequired
    }),
}


export default ProgramCard;