export default {
    root:{
        border: 'unset',
        background: 'unset',
        '&:hover':{
            backgroundColor: 'unset'
        },
        // '&.MuiIconButton-colorPrimary': {
        //     color: palette.colors.violet[300]
        // },
    },
    // indeterminate: {
    //     color: '#00C1DE'
    // },
    // primary:{
    //     color: palette.colors.violet[300]
    // }
};