import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const CircularProgressWithLabel = ({ className, ...rest }) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...rest} className={className} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(rest.value,)}%`}</Typography>
            </Box>
        </Box>
    );
};

CircularProgressWithLabel.defaultProps = {
    value: 0,
};

export default CircularProgressWithLabel;