import { makeStyles } from "@material-ui/core/styles";

export const detailsStyles = makeStyles((theme) => ({
  grid: {
    borderTop: `1.5px solid ${theme.palette.colors.grey[700]}`,
  },
  card: {
    backgroundColor: theme.palette.colors.grey[400],
  },
  titleContainer: {
    marginTop: "3px",
  },
  title: {
    color: theme.palette.colors.white,
    fontSize: "18px",
    fontWeight: "normal",
  },
  subTitle: {
    color: theme.palette.colors.fillStroke[100],
    fontSize: "12px !important",
    fontWeight: "bold",
    marginLeft: "15px",
    marginTop: "15px",
  },
  label: {
    color: theme.palette.colors.darkBlack[100],
    fontWeight: 400,
    fontSize: "12px",
  },
  titleReq: {
    color: theme.palette.colors.fillStroke[100],
    fontWeight: 600,
    fontSize: "12px",
  },
  inputText: {
    color: theme.palette.colors.gray[100],
    background: "none",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight:"24px"
  },
  inputTextEdit: {
    border: props => props.isEdit ? `1.5px solid ${theme.palette.colors.fillStroke[200]}` : '1px solid #16171b',
    borderRadius: "8px",
    color: theme.palette.colors.white,
    // height: "43px",
    fontSize: "14px",
    // padding: "0.5em",
    marginTop: "5px",
    width: '86%',
    // marginLeft:'10px',
    background: theme.palette.colors.darkBlack[500]
  },
  inputTextError: {
    border: props =>  `.5px solid ${theme.palette.colors.error[300]} !important`,
  },
  inputDate: {
    colorScheme: "dark",
    width: "70%"
  },
  datePicker:{
    width: '85%',
    borderRadius: 8,
    marginTop: 5,
    "& .MuiPickersCalendarHeader-iconButton":{
      backgroundColor:'#000'
    },
    '& .MuiOutlinedInput-root':{
      background: theme.palette.colors.darkBlack[500],
    }
  },
  textArea: {
    border: props => props.isEdit ? `1.5px solid ${theme.palette.colors.fillStroke[200]}` : '1px solid #16171b',
    borderRadius: "5px",
    padding: "0.5em",
    marginTop: "10px",
    height: "8em",
    overflow: "auto",
    width:'94%',
    marginLeft:'10px',
  },
  bioText: {
    color: theme.palette.colors.white,
    background: "none",
    fontSize: "12px",
  },
  select: {
    marginTop: "5px !important",
    width: '85%',
    background: theme.palette.colors.darkBlack[500],
    borderRadius: 8,
    '& .MuiSelect-nativeInput':{
      height: '100%',
      border: 0,
      backgroundColor: 'transparent',
      '&::placeholder': {
        padding: 16,
        fontSize: 14,
        color: theme.palette.colors.darkBlack[200]
      },
    },
    '& .MuiInputBase-input':{
      padding: '10.5px 16px !important'
    },
    "& .MuiSelect-selectMenu": {
      // fontSize: 15,
      // background: theme.palette.colors.darkBlack[500],
      backgroundColor: `${theme.palette.colors.darkBlack[500]} !important`,
    },
    // "& .arrow-down-icon":{
    //   marginRight: 0
    // }
  },
  selectDisabled: {
    "& .MuiInputBase-fullWidth": {
      border: `1px solid ${theme.palette.colors.grey[500]}`,
    },
  },
  dateDisabled: {
      color: theme.palette.colors.grey[500]
  },
  // select: {
  //   '& .MuiSvgIcon-root': {
  //     color: theme.palette.colors.fillStroke[100],
  //   },
  //   // height: '1.1876em',
  //   // width: '100%'
  // },
  iconSelect: {
    right: -10,
    transform: "rotate(270deg)",
    marginTop: -5,
  },
  iconSelectOpen: {
    transform: "rotate(0deg)",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: `${theme.palette.colors.fillStroke[100]} !important`,
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: `${theme.palette.colors.grey[400]} !important`,
    },
    fontFamily: "'Source Sans Pro', sans-serif !important",
    fontSize: "12px",
  },
  iconContainer: {
    textAlign: "center",
  },
  icon: {
    color: theme.palette.colors.fillStroke[100],
  },
  circularProgress: {
    color: theme.palette.colors.white,
  },
  saveButton: {
    backgroundColor: theme.palette.colors.orange[300],
    color: theme.palette.colors.white,
  },
  alertMessage: {
    backgroundColor: theme.palette.colors.grey[500],
    color: theme.palette.colors.white,
    fontFamily: "'Source Sans Pro', sans-serif !important",
    fontSize: "14px",
  },
  vertical: {
    backgroundColor: theme.palette.colors.grey[400],
  },
  item: {
    borderRight: `1px solid ${theme.palette.colors.grey[400]}`,
  },
  itemHide: {
    display:'none',
  },
  subtitle: {
    color: theme.palette.colors.blue[300],
    fontWeight: 600,
    fontSize: "14px",
  },
  checkboxHide: {
    display:'none'
  },
  checkbox: {
    "&.MuiIconButton-colorPrimary .MuiIcon-root":{
      fontSize: "30px !important"
    },
    '&.MuiCheckbox-colorPrimary.Mui-checked':{
      color: theme.palette.colors.success[300],
    },
    "&.Mui-disabled.Mui-checked": {
      color: theme.palette.colors.success[300],
    },
    "&.Mui-disabled": {
      color: "#1a1f22",
    },
  },
}));
