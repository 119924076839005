import React from 'react'

import { Card, CardContent, Grid, Typography, Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "16px 24px",
    },
    divideFullWidth: {
        backgroundColor: `${theme.palette.colors.grey[700]}`,
        marginLeft: '-24px',
        marginRight: '-24px',
    },
    cardTitle: {
        paddingBottom: "16px"
    },
    cardBody: {
        paddingTop: "16px"
    }
}));

const CheckoutSideCard = (props) => {
    const classes = useStyles();
    const { cardSize = 12, cardTitle, cardTitleColor, componentHeight, component, noTitle = false } = props;

    return (
        <Grid item md={cardSize} xs={12}>
            <Card style={{ background: "#1D1E23", border: "1px solid #2C3236"}}>
                <CardContent className={classes.root}>
                    {!noTitle && <Grid container className={classes.cardTitle} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                            <Typography variant="h5" align='left' className={cardTitleColor}>
                                { cardTitle }
                            </Typography>
                        </Grid>
                    </Grid>}
                    {!noTitle && <Divider light className={classes.divideFullWidth} />}
                    {!noTitle ? <Grid container 
                        spacing={1}
                        className={classes.cardBody}
                        alignItems='center'
                    >
                        <Grid item xs={12}>
                            <Grid container wrap="wrap">
                                { component }
                            </Grid>
                        </Grid>
                    </Grid>:
                    <Grid container 
                        spacing={2}
                        alignItems='center'
                        style={{ height: componentHeight, borderTop: "none"}}
                    >
                        <Grid item xs={12}>
                            <Grid container wrap="wrap">
                                { component }
                            </Grid>
                        </Grid>
                    </Grid>}
                </CardContent>
            </Card>
        </Grid>
    )
}

export default CheckoutSideCard
