import React from 'react';
import PropTypes from 'prop-types'

// @material-ui/core
import { Typography, Box, Chip, Icon } from '@material-ui/core';
// @material-ui/icons

//assets
import { getLogoCard }from '../../../../../src/constants/logosImages';
import { log } from '../../../../shared/utils/console.js'

import live_tv_outlined from '../../../../assets/images/logos/landing-icons/microgrados/live_tv_outlined_white.svg';

// core components
import CourseRating from '../../../../shared/components/CourseCard/components/CourseRating';

// logos
import { CourseService } from '@sdk-point/talisis';
// styles
import palette from '../../../../design-system/theme/palette';
import useStyles from './style/cardMediaStyle';

import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

const modalityOptions = ({
    2: "Online",
    3: "Híbrida",
    1: "Presencial",
});

const CourseCardMedia = (props) => {
    const {children, saved, labels, raiting, source, image, imageColor, course_id, userId, isProgress, lazy} = props;
    const [saveStatus, setSaveStatus] = React.useState(false);
    const [updatingStatus, setUpdatingStatus] = React.useState(false);
    const [progressStatus, setProgressStatus] = React.useState(false);
    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    
    const setData = async (e) => {
        setSaveStatus(saved);
        isProgress && setProgressStatus(true);
    };
    React.useEffect(() =>{
        setData();
    }, [saved, isProgress])

    const classes = useStyles({image, imageColor});

    log("source.id", source)

    /*const CourseSource = source => <Box display="flex" justifyContent="space-between">
        <Box>
            {
                source.modality && <Chip className={classes.sourceLabel}
                    label={source.modality} 
                    icon={<Icon>{source.icon}</Icon>}
                />
            }
        </Box>
        <img alt="logo" loading="lazy" src={Logos[source.id]} style={{height: 20}}/>
    </Box>*/

    const handleSaveUnsaveCourse = async (e) => {
        e.preventDefault();
        e.persist();
        setUpdatingStatus(true);
        let newStatus = !saveStatus;
        setSaveStatus(newStatus);
        const response = await courseService.saveUnsaveCourse(userId, course_id, newStatus);
        if(response.response.length > 0){
            setSaveStatus(response.response[0].is_active);
            setUpdatingStatus(false);
        }
        e.stopPropagation()
    };

    return (
        <Box>
            <Box className={classes.root}>
                <Box className={classes.bg}>
                    <img loading="lazy" src={image}></img>
                    <Box className={classes.bg_degree}></Box>
                </Box>
                {
                    progressStatus && 
                    <Box display="flex" alignItems="flex-end" justifyContent="space-between"  style={{ boxSizing: "content-box", height: "155px", width: "calc(258px - 29px)", paddingLeft: "14.5px", paddingRight: "14.5px", paddingBottom: "12px" }}>
                        {
                            source.modality != null ? 
                            <Box display="flex" alignItems="center" style={{ padding: "4px", paddingLeft: "8px", paddingRight: "8px", background: "rgba(28, 31, 36, 0.8)", borderRadius: "4px"}}>
                                <img loading="lazy" alt="live_tv_outlined_icon" className={classes.tv} src={live_tv_outlined}/>&nbsp;
                                <Typography className={classes.modality_text} component="span" variant="body2">{modalityOptions[source.modality]}</Typography>
                            </Box>:<Box></Box>
                        }
                        {
                            source.id != null && 
                            <img loading="lazy" alt="logo" src={getLogoCard(source.id)} className={classes.sourceImg}/>
                        }
                        
                    </Box>
                }
                <Box className={classes.container} alignContent="space-between" flexWrap="wrap" display="flex">
                    <Box display="inline-flex" width="100%">
                        <Box flex="1" display="inline-flex" alignItems="center">
                            {
                                labels.map(it =>
                                    (it.label ?
                                        <Chip
                                            key={uuid()}
                                            className={classes.chip} 
                                            icon={<Icon 
                                                className={clsx(it.icon.awesome)}
                                                style={{color: it.icon.color ? palette.colors[it.icon.color][it.icon.colorValue || 400 ] : 'inherit'}}>
                                                {it.icon.material}
                                            </Icon>} 
                                            label={it.label} 
                                            style={{background:palette.colors[it.color][50]}}
                                        />
                                        :   <Icon key={uuid()}
                                                className={clsx(it.icon.awesome)}
                                                style={{color: it.icon.color ? palette.colors[it.icon.color][it.icon.colorValue || 400 ] : 'inherit'}}>
                                                {it.icon.material}
                                            </Icon>
                                    )
                                )
                            }
                        </Box>
                        {/*  <Box className={classes.saveIcon} disabled={typeof saved === 'boolean' ? updatingStatus : true} onClick={(e) => handleSaveUnsaveCourse(e) }>{ typeof saved === 'boolean' && (saveStatus ? <BookmarkIcon color="primary"/> : <BookmarkBorderIcon color="primary"/>)}</Box>*/}
                    </Box>
                    <Box>
                        {children}
                    </Box> 
                    <Box width="100%" className={classes.raiting}>
                        { raiting && <CourseRating courseId={course_id} {...raiting}/>}
                        {/* source && <CourseSource {...source}/>*/}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

CourseCardMedia.defaultProps = {
    labels: [],
    imageColor: false
    // source: {
    //     id: 9,
    //     icon: 'desktop_windows',
    //     modality: 'Online',
    // },
    // raiting:{
    //     value: 2.5,
    //     totalReview: 223
    // } 
};

CourseCardMedia.propTypes = {
    imageColor: PropTypes.bool,
    saved: PropTypes.bool,
    labels: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.any,
        color: PropTypes.string
    })),
    onChangeRating: PropTypes.func,
    source: PropTypes.shape({
        id: PropTypes.number,
        icon: PropTypes.string,
        modality: PropTypes.number
    }),
    rating: PropTypes.instanceOf(CourseRating),
};

export default CourseCardMedia;
