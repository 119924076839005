import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import CurrencyFormat from 'react-currency-format'

const PendingDiscount = ({pendingDiscount}) => {
    return (
        <Grid container className='mt-1' justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography variant="body2" className="text-gray-100">
                    Bonificación por suscripción anterior:
                </Typography>
            </Grid>
            <Grid item>
                <Typography className="variant-subheading-3 text-dark-black-100">
                    <CurrencyFormat
                        value={pendingDiscount} decimalScale={2} fixedDecimalScale={true} displayType="text"
                        thousandSeparator={true} prefix="-$" suffix='MXN' 
                    />
                </Typography>
            </Grid>
        </Grid>
    )
}

export default PendingDiscount
