import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

// @material-ui/core
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import Link from '@material-ui/core/Link';

// shared components
import getMembershipIcon from '../../../shared/components/Modalupselling/MembershipIconSelector';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import { log } from '../../../shared/utils/console.js'

// routes
import * as paths from '../../../routes/paths';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            marginTop: '-202px'
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: `0 !important`,
            marginTop: props => props.hideInfoMembership ? '1em' : '12px'
        }
    },
    card: {
        background: "rgba(159, 111, 251, 0.16)",
        border: `0.5px solid ${theme.palette.colors.violet[300]}`
    },
    cardContent: {
        padding:  props => props.hideInfoMembership ? "0px" : "24px",
    }
}));

const SummaryCardMemberships = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const { cardSize = 12, cardTitle, price, oldPrice, btnDisabled, btnFunction,
        btnText, loading, components = [], isPurchased,
        membershipData, company_id, user_membership_included, closest_membership_included, isLogged, simulacionPermission, hideBookmark, aux_components=[], hideInfoMembership } = props;
    const [membershipIncluded, setMembershipIncluded] = React.useState(user_membership_included);
    const [specialCase, setSpecialCase] = React.useState(company_id === "UERRE" && membershipData?.user_membership?.actual_membership?.slug === "professional" ? true : false);
    const [actualMembership, setActualMembership] = React.useState(membershipData?.user_membership?.actual_membership);
    log("membership: ", membershipData?.user_membership?.actual_membership);
    const [nextMembership, setNextMembership] = React.useState(closest_membership_included.length > 0 ? membershipData?.memberships?.find(x => x.id === closest_membership_included[0].membership_id) : membershipData?.user_membership?.next_membership);
    const classes = useStyles({hideInfoMembership});

    const gotoMemberships = (e) => {
        e.preventDefault();
        //
        const eventData = {};
        eventData['source'] = 'landing';
        eventTracker('ver_membresías', eventData, ['mixpanel','google']);
        //
        history.push({
            pathname: paths.MEMBRESIAS
        });
    };

    return (
        <Grid item className={classes.root} md={cardSize} xs={12}>
        {
            // actualMembership !== undefined && !membershipIncluded && !specialCase
            (actualMembership !== undefined && !membershipIncluded && !hideInfoMembership) &&
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                                        <Grid item xs={12}>
                                            <Box sx={{ paddingBottom: '24px' }}>
                                                <Typography className='variant-xsmall text-gray-100'>Al suscribirte a este programa obtén:</Typography>
                                            </Box>
                                            <Box sx={{ textAlign: 'left', paddingBottom: '16px' }}>
                                                <Grid container>
                                                    <Grid item xs="auto" style={{ paddingRight: '9px' }}>
                                                        {getMembershipIcon(nextMembership?.id, 13)}
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant='body2' className="semi-bold"> Membresía {nextMembership ? nextMembership.name : ""}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {
                                                nextMembership?.list_details.map((value, index) => {
                                                    return (
                                                        <Box sx={{ textAlign: 'left', paddingBottom: '8px' }}>
                                                            <Grid container>
                                                                <Grid item xs="auto">
                                                                    <CheckIcon style={{ fontSize: '16px', color: '#9F6FFB', marginRight: '14px' }} />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography className='variant-xsmall text-gray-500'> {value.title}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    );
                                                })
                                            }
                                            {
                                                <Box sx={{ paddingBottom: '24px', textAlign: 'left', paddingTop: '8px' }}>
                                                    <Link component={RouterLink} to={paths.MEMBRESIAS} onClick={(e) => gotoMemberships(e)}>
                                                        <Typography variant='body2' className="semi-bold" color='secondary'>Ver membresías</Typography>
                                                    </Link>
                                                </Box>
                                            }
                                        </Grid>
                </CardContent>
            </Card>
                                
            }
            {
                (components.length > 0) &&
                <Card className='mt-3'>
                    <CardContent>
                        <Box>
                            {components.map((component, index) =>
                                <Grid container
                                    key={index}
                                    spacing={1}
                                    className={ index>0?'mt-2':null }
                                    alignItems='center'
                                    style={{ borderTop: index>0? `1.5px solid ${theme.palette.colors.grey[700]}`:null }}
                                >
                                    {component}
                                </Grid>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            }
            {
            (aux_components.length > 0) &&
                <Card className='mt-3'>
                    <CardContent>
                        <Box>
                            {aux_components.map((component, index) =>
                                <Grid container
                                    key={index}
                                    spacing={1}
                                    className={ index>0?'mt-2':null }
                                    alignItems='center'
                                    style={{ borderTop: index>0? `1.5px solid ${theme.palette.colors.grey[700]}`:null }}
                                >
                                    {component}
                                </Grid>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            }
        </Grid>
    )
}

export default SummaryCardMemberships;