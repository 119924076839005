import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '@material-ui/core/Icon';
import { useTheme } from '@material-ui/core/styles';

// assets
import VisaLogo from "../../../../../../assets/images/visa.svg";
import AmexLogo from "../../../../../../assets/images/amex.svg";
import MastercardLogo from "../../../../../../assets/images/master.svg";
import SpeiLogo from "../../../../../../assets/images/banks/spei.svg";

// styles
import useStyles from '../../../../../../assets/styles/jss/pages/market/PaymentConfirmation';

// utils
import { ORDER_STATUS, PAYMENT_METHOD } from '../../../../../../shared/constants/orders';

const getTitlebyPaymmentMethod = (reference) => {
    let title = `   Tarjeta de `;
    title += reference?.type === `debit` ? `débito ` : `crédito `;
    title += reference?.brand?.toUpperCase() || "";
    title += ` con terminación `;
    title += reference?.last4 || "";
    return title;
}

const PaymentMethod = ({ order }) => {
    const theme = useTheme();
    const classes = useStyles();
    const breakpoint = useMediaQuery(theme.breakpoints.down("xs"));
    const paymentRef = order?.payment?.payment_reference?.conekta?.charge?.payment_method;

    return (
        <Card className='bg-fill-stroke-400'>
            <Box display="flex" flexDirection="column" px="24px" pt="24px" pb="30px">
                <Typography variant="h5" className="text-gray-100">Método de pago</Typography>
                <Box display="flex" justifyContent="space-between">
                    {order?.payment?.payment_method === PAYMENT_METHOD.CARD &&
                        <React.Fragment>
                            {paymentRef?.brand ?
                                <Box display="flex" mt="16px" alignItems="center">
                                    <Box mr="16px">
                                        <img style={{ width: "55.8px", maxHeight: "30px" }} alt="visa-logo" src={paymentRef.brand === "visa" ? VisaLogo : paymentRef.brand === "mastercard" ? MastercardLogo : AmexLogo} />
                                    </Box>
                                    <Typography variant="body2">{getTitlebyPaymmentMethod(paymentRef)}</Typography>
                                </Box> :
                                <Grid container className='mt-2' justifyContent='flex-start' alignItems='center'>
                                    <Grid item xs={6}>
                                        <Box display="flex">
                                            <img style={{ width: breakpoint ? "25.8px" : "55.8px", maxHeight: breakpoint ? "15px" : "30px" }} alt="visa-logo" src={VisaLogo} />
                                            <img style={{ width: breakpoint ? "25.8px" : "55.8px", maxHeight: breakpoint ? "15px" : "30px", marginLeft: "10px" }} alt="mastercard-logo" src={MastercardLogo} />
                                            <img style={{ width: breakpoint ? "25.8px" : "55.8px", maxHeight: breakpoint ? "15px" : "30px" }} alt="amex-logo" src={AmexLogo} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">Tarjeta de crédito/débito</Typography>
                                    </Grid>
                                </Grid>
                            }
                        </React.Fragment>
                    }
                    {order?.payment?.payment_method === PAYMENT_METHOD.SPEI &&
                        <Box display="flex" mt="16px" alignItems="center">
                            <Box mr="16px" display="flex">
                                <img style={{ width: "55.8px", maxHeight: "30px" }} alt="spei-logo" src={SpeiLogo} />
                            </Box>
                            <Typography variant="body2">Pago por transferencia electrónica</Typography>
                        </Box>
                    }
                    {order?.payment?.payment_method === PAYMENT_METHOD.CASH &&
                        <Box display="flex" mt="16px" alignItems="center">
                            <Box display="flex" mr="16px">
                                <Icon style={{ fontSize: "16px", lineHeight: "16px" }} alt="wallet" className='ri-wallet-3-line' />
                            </Box>
                            <Typography variant="body2">Pago en efectivo</Typography>
                        </Box>
                    }
                    {order?.estatus_id === ORDER_STATUS.PENDING && <Typography variant="caption" className={`${classes.pending} bg-warning-500`}>Pendiente</Typography>}
                </Box>
            </Box>
        </Card>
    )
};

export default PaymentMethod;