import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// style
import useStyles from '../../../../../assets/styles/jss/pages/events/myEventsStyle';

// shared - images
import holdingCalendarSVG from '../../../../../assets/images/events/holding_calendar.svg';

const NotEvent = ({text}) => {
    const classes = useStyles();

    return (
        <Box>
            <Box display="flex" justifyContent="center">
                <img src={holdingCalendarSVG} className={classes.imageNotEvent}/>
            </Box>
            <Box className={`${classes.containerCenter} offset-margin-top-16`}><Typography variant='h5'>{text}</Typography></Box>
            
        </Box>
    );
};

export default NotEvent;