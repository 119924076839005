import * as checkoutTypes from '../types/legacyCheckoutTypes';

export const toggleOpenModal = () => ({ 
    type: checkoutTypes.TOGGLE_CHECKOUT_LOADER 
});

export const toggleMissingPhone = (status) => (dispatch) => { 
    dispatch({
        type: checkoutTypes.TOGGLE_MISSING_PHONE,
        payload: status
    })
};

export const setCheckoutCurrentStep  = (stepNumber) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_CHECKOUT_CURRENT_STEP,
        payload: stepNumber
    });
};

export const setPurchaseOrder = (order) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_ORDER,
        payload: order
    })
};

export const setPersonInfo = (personInfo) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_PERSON_INFO,
        payload: personInfo
    })
};

export const setOrderPendingDiscount = (discount) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_PENDING_DISCOUNT,
        payload: discount
    })
};

export const setCurrentUserMembership = (membershipInfo) => (dispatch) => {
    dispatch({
        type: checkoutTypes.GET_USER_CURRENT_MEMBERSHIP,
        payload: membershipInfo
    })
};

export const setFreeCouponPurchase = (isFreePurchase) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_FREE_COUPON_PURCHASE,
        payload: isFreePurchase
    })
};

export const setCouponData = (couponInfo) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_COUPON_DATA,
        payload: couponInfo
    })
};

export const setOrderConcepts = (concepts) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_CONCEPTOS,
        payload: concepts
    })
};

export const setOrderQuantities = (quantityInfo) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_ORDER_QUANTITY,
        payload: quantityInfo
    })
};

export const setOrderNextPaymentDate = (paymentDate) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_NEXT_PAYMENT_DATE,
        payload: paymentDate
    })
}

export const setMembershipOrderFeePlans = (feePlans) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_MEMBERSHIP_FEE_PLANS,
        payload: feePlans
    })
}

export const setMembershipOrderSelectedPlan = ( selectedMembershipInfo ) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_SELECTED_MEMBERSHIP,
        payload: selectedMembershipInfo
    })
}

export const setPersonIdToPurchaseOrder = (personId) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_PERSON_ID_TO_ORDER,
        payload: personId
    })
}

export const setMonthlyPaymentsInfo = ( availablePayments ) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_MONTHLY_PAYMENTS_INFO,
        payload: availablePayments
    })
}

export const setMonthlyPaymentPlan = ( paymentPlan ) => (dispatch) => {
    dispatch({
        type: checkoutTypes.SET_PAYMENT_PLAN,
        payload: paymentPlan
    })
}
