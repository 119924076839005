import React from 'react';

// @material-core
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Divider, Icon} from '@material-ui/core';
import {alpha} from '@material-ui/core/styles'

//@ThirtyParty
import clsx from 'clsx';
import moment from 'moment';

// local
import {detailsStyles} from '../../../../../assets/styles/jss/pages/degreeCertificates/DetailsStyles';
import dateFormat from '../../../../../design-system/utils/dateFormat';
import {ISO_DATE_FORMAT} from '../../../../../design-system/constants/date-formats';
import Select from '../../../../../design-system/components/Form/Select/Select';
import {SOURCE_SZBMITC_DEGREE_CERTIFICATE} from '../../../../../shared/constants/sources';
import Picker from '../../../../../shared/components/Form/Picker';
import {findIndex, isNil, propEq} from "ramda";

export const DegreeInfo = ({data, nationalityCat, xData, onSetForm, onChange, isEdit, isExention, catalogues, errorForm, disabledDates}) => {

    const classes = detailsStyles({isEdit});

    return (
        <Grid container spacing={3} className="mt-1">
            <Grid item>
                <Grid container>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Id Modalidad
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit ?
                                    <TextField
                                        id="idModalidadTitulacion"
                                        name="idModalidadTitulacion"
                                        placeholder='Id Modalidad'
                                        fullWidth
                                        multiline={true}
                                        className={classes.label}
                                        value={catalogues.modality[findIndex(propEq(data.idModalidadTitulacion, 'id'))(catalogues.modality)]?.label || ''}
                                        InputProps={{
                                            className: clsx(!isEdit && classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    :
                                    <Select
                                        items={catalogues.modality}
                                        label=""
                                        className={clsx(classes.select)}
                                        name="idModalidadTitulacion"
                                        onClick={e => e.stopPropagation()}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        disabled={!isEdit}
                                        value={data.idModalidadTitulacion || ""}
                                        size="small"
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Libro
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="libro"
                                    name="libro"
                                    placeholder='Libro'
                                    fullWidth
                                    className={classes.label}
                                    value={data.libro || ''}
                                    InputProps={{
                                        className: clsx(!isEdit && classes.inputText, isEdit && classes.inputTextEdit),
                                        disableUnderline: true,
                                        readOnly: !isEdit,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onClick={e => e.stopPropagation()}
                                    onChange={e => onChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Foja
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="foja"
                                    name="foja"
                                    placeholder='Foja'
                                    fullWidth
                                    className={classes.label}
                                    value={data.foja || ''}
                                    InputProps={{
                                        className: clsx(!isEdit && classes.inputText, isEdit && classes.inputTextEdit),
                                        disableUnderline: true,
                                        readOnly: !isEdit,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onClick={e => e.stopPropagation()}
                                    onChange={e => onChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Fecha Exámen
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit || isExention || (isEdit && data.idModalidadTitulacion === null) ?
                                    <TextField
                                        id="fechaExamenProfesional"
                                        name="fechaExamenProfesional"
                                        placeholder='Fecha Exámen'
                                        multiline={true}
                                        fullWidth
                                        className={classes.label}
                                        value={data.fechaExamenProfesional || ''}
                                        InputProps={{
                                            className: clsx(classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    /> :
                                    <Picker
                                        className={classes.datePicker}
                                        name="fechaExamenProfesional"
                                        value={dateFormat(data.fechaExamenProfesional || '', ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                                        onChange={(e) => {
                                            e.target['type'] = "date";
                                            onChange(e)
                                        }}
                                        onClick={e => e.stopPropagation()}
                                        format="DD/MM/YYYY"
                                        placeholder="DD/MM/YYYY"
                                        autoOk={false}
                                        views={['year', 'month', 'date']}
                                        keyboardbuttonprops={{
                                            "aria-label": "change date"
                                        }}
                                        shouldDisableDate={disabledDates}
                                        error={!isNil(errorForm.fechaExamenProfesional)}
                                        helperText={!isNil(errorForm.fechaExamenProfesional) && errorForm.fechaExamenProfesional}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position={'start'}>
                                                    <Icon className={clsx('text-white-200 ri-calendar-2-line mr-1')}
                                                          style={{fontSize: 18}}/>
                                                </InputAdornment>)
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Fecha Exención Exámen
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!isEdit || !isExention ?
                                    <TextField
                                        id="fechaExencionExamenProfesional"
                                        name="fechaExencionExamenProfesional"
                                        placeholder='Fecha Exención Exámen'
                                        fullWidth
                                        className={classes.label}
                                        value={data.fechaExencionExamenProfesional || ''}
                                        InputProps={{
                                            className: clsx(classes.inputText),
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    /> :
                                    <Picker
                                        className={classes.datePicker}
                                        name="fechaExencionExamenProfesional"
                                        value={dateFormat(data.fechaExencionExamenProfesional || '', ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                                        onChange={(e) => {
                                            e.target['type'] = "date";
                                            onChange(e)
                                        }}
                                        onClick={e => e.stopPropagation()}
                                        format="DD/MM/YYYY"
                                        placeholder="DD/MM/YYYY"
                                        autoOk={false}
                                        views={['year', 'month', 'date']}
                                        keyboardbuttonprops={{
                                            "aria-label": "change date"
                                        }}
                                        shouldDisableDate={disabledDates}
                                        error={!isNil(errorForm.fechaExencionExamenProfesional)}
                                        helperText={!isNil(errorForm.fechaExencionExamenProfesional) && errorForm.fechaExencionExamenProfesional}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position={'start'}>
                                                    <Icon className={clsx('text-white-200 ri-calendar-2-line mr-1')}
                                                          style={{fontSize: 18}}/>
                                                </InputAdornment>)
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};
