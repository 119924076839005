import React from 'react';
import { useSelector } from 'react-redux';

// @material-ui
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { Typography, useMediaQuery } from '@material-ui/core';

// components
import CartItem from './CartItem/CartItem';

const CartDetails = ({ loading, onItemDeleteClick }) => {
    const { cartProducts } = useSelector(state => state.cartReducer);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return <Box>
        <List dense disablePadding={isMobile}>
            {cartProducts?.map(detail => {
                return <CartItem
                    key={detail.product_id}
                    detail={detail}
                    onItemDeleteClick={onItemDeleteClick}
                />
            })}
        </List>
    </Box>
}

export default CartDetails;