import * as paths from './paths'

// @material-ui/icons
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
//import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import ViewList from '@material-ui/icons/ViewList';

import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Notifications from '@material-ui/icons/Notifications';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import SchoolIcon from '@material-ui/icons/School';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DescriptionIcon from '@material-ui/icons/DescriptionRounded';
import { Group } from '@material-ui/icons';

// pages
import Feed from '../pages/Feed/Feed';
import Home from '../pages/Home/Home';
import DemoEasyGenerator from '../pages/Courses/DemoEasyGenerator/DemoEasyGenerator'
import FeedNew from '../pages/Feed/FeedNew/FeedNew';
import FeedEdit from '../pages/Feed/FeedEdit/FeedEdit';
import MyCourses from '../pages/Courses/MyCourses/MyCourses';
import OrdersStatus from '../pages/OrdersStatus/OrdersStatus';
import Coupons from '../pages/Discounts/Coupons';
import ShowCourse from '../pages/Courses/MyCourses/CourseCard/ShowCourse';
import CompletedCourses from '../pages/Courses/CompletedCourses/CompletedCourses';
import CourseCollection from '../pages/Market/CourseCatalog/CourseCollection/CourseCollection';
import Kardex from '../pages/Courses/Kardex/Kardex';
import CourseManagement from '../pages/Market/CourseManagement/CourseManagement';
import CourseEdit from '../pages/Market/CourseManagement/Courses/CourseEdit/CourseEdit';
import CourseNew from '../pages/Market/CourseManagement/Courses/CourseNew/CourseNew';
import InstructorEdit from '../pages/Market/CourseManagement/Instructors/InstructorEdit/InstructorEdit';
import InstructorNew from '../pages/Market/CourseManagement/Instructors/InstructorNew/InstructorNew';
import Profile from '../pages/Account/Profile/Profile';
import Billing from '../pages/Account/Billing/Billing';
import RequestsItems from '../pages/Requests/RequestItems/RequestsItems';
import RequestManagment from '../pages/Requests/RequestManagment/RequestManagment';
import ShowRequest from '../pages/Requests/RequestManagment/ShowRequest/ShowRequest';
import NewRequest from '../pages/Requests/RequestManagment/NewRequest/NewRequest';
import EditRequest from '../pages/Requests/RequestManagment/EditRequest/EditRequest';
import ShowRequestItem from '../pages/Requests/RequestItems/ShowRequestItem/ShowRequestItem';
import StepConfig from '../pages/Requests/RequestManagment/components/StepConfig/StepConfig';
import StepConfigShow from '../pages/Requests/RequestManagment/ShowRequest/StepConfig/StepConfig';
import SendingDocuments from '../pages/Requests/RequestItems/pages/SendingDocuments/SendingDocuments';
import RedirectPage from '../layout/RedirectionPage/RedirectToAllies';
import Memberships from '../pages/Memberships/Memberships';

// cursos
import Courses from '../pages/Courses/Courses';
import Coursesv2 from '../pages/Courses/Coursesv2';
import CurrentCourseDetail from '../pages/Courses/EnrolledCourses/CurrentCourseDetail/CurrentCourseDetail';
import AsyncProgram from '../pages/Courses/SyncProgram/SyncProgram'
import Typography from '../pages/System/Typography';
import Palette from '../pages/System/Palette';

import Simulation from '../pages/Simulation/Simulation';
import Register from '../pages/Access/Register/Register';
import AdminNotifications from '../pages/Notifications/AdminNotifications';
import NotificationsNew from '../pages/Notifications/NotificationsNew/NotificationsNew';
import NotificationsEdit from '../pages/Notifications/NotificationsEdit/NotificationsEdit';
import CommitmentLetter from '../pages/Access/CommitmentLetter/CommitmentLetter';
import { Groups } from '../pages/Groups/Groups';

// permission
import UserManagement from '../pages/User/UserManagment';
import UserForm from '../pages/User/UserForm/UserForm';
import RoleManagement from '../pages/Role/RoleManagement';
import RoleForm from '../pages/Role/RoleForm/RoleForm';
import PersonRoleForm from '../pages/Role/PersonRoleform/PersonRoleForm';
import PermissionRoleForm from '../pages/Role/PermissionRoleForm/PermissionRoleForm';
import PermissionManagment from '../pages/Permission/PermissionManagment';
import PermissionForm from '../pages/Permission/PermissionForm/PermissionForm'
import DetailCoursesInProgress from '../pages/Courses/CoursesInProgress/DetailCoursesInProgress/DetailCoursesInProgress';
import MembresiaManagment from '../pages/Menbresia/MembresiaManagment';
import { CouponDetail } from '../pages/Discounts/CouponDetail';
import HealthCheck from '../pages/User/HealthCheck/HealthCheck';

//Allys
import AllysSuscribes from '../pages/Aliados/AllysSuscribes';

//Consultancies
import Consultancies from '../pages/Consultancies/Consultancies';

// SDK-POINT
import {MODULE_MENU} from '@sdk-point/talisis'
import { FreeBreakfastOutlined } from '@material-ui/icons';
import NewGroup from '../pages/Groups/NewGroup/NewGroup';
import EditGroup from '../pages/Groups/EditGroup/EditGroup';
import EduconCourses from '../pages/Courses/EduconCourses/EduconCourses';
import ProfileAdviser from '../pages/Consultancies/Advisers/ProfileAdviser';
import RatingConsultancy from '../pages/Consultancies/Applicant/RatingConsultancy';
import ReferralPlan from '../pages/ReferralPlan/ReferralPlan';

import TitulCerti from '../pages/DegreeCertificates/index';
import TitulCertiStdtEdit from '../pages/DegreeCertificates/Student/EditForm';
import DocumentsReception from '../pages/DocumentsReception/DocumentsReception';
import DocumentReceptionDetail from '../pages/DocumentsReception/DocumentsReceptionDetail/DocumentReceptionDetail';

// ya se encuentra en paths
export const HOME_PATH = '/home';

// conecta
export const CONECTA = '/conecta'

// cursos
export const CURSOS = '/cursos';
export const CURSOS_GUARDADOS = '/cursos?target=guardados'
export const ADMIN_CURSOS = 'courses/management';
export const CURSOS_ACTUALES_DETALLE = `${CURSOS}/actules/:id`;
export const CURSOS_DETALLE = `${CURSOS}/:id`;


//catalogo
export const CATALOGO = '/';
export const CATALOGO_BUSQUEDA = '/busqueda';

// tramites
export const SOLICITUDES = '/solicitudes';
export const SOLICITUDES_DETALLE = `${SOLICITUDES}/:id`;
export const SOLICITUDES_CARGA_ARCHIVOS = `${SOLICITUDES_DETALLE}/envio-de-documentos`;
export const GESTION_SERVICIOS = '/gestion-de-servicios';
export const ESTATUS_ORDENES = '/gestion-de-ordenes';
export const GESTION_SERVICIOS_CREAR = `${GESTION_SERVICIOS}/crear`;
export const GESTION_SERVICIOS_EDITAR = `${GESTION_SERVICIOS}/:id/editar`;

export const GESTION_SERVICIO_CONFIGURACION_PASO = `${GESTION_SERVICIOS_EDITAR}/configuracion-paso`
export const GESTION_SERVICIO_EDITAR_PASO = `${GESTION_SERVICIOS_EDITAR}/configuracion-paso/:stepId`
///requests-managment/edit/:id/step/:stepId
export const GESTION_SERVICIO_NEW_STEP = `${GESTION_SERVICIOS}/configuracion-paso`

export const GESTION_SERVICIO_SHOW = `${GESTION_SERVICIOS}/:id`
export const GESTION_SERVICIO_SHOW_STEP = `${GESTION_SERVICIOS}/:id/configuracion-paso`
///requests-managment/request/:id/step/:stepId

// Usuarios / Permisos
export const USUARIOS = '/usuarios'
export const ROLES = '/roles'
export const PERMISOS = '/permisos'
export const DESCUENTOS = '/descuentos'
export const ADMIN_USUARIOS = `${USUARIOS}/management`
export const USUARIOS_CREAR = `${USUARIOS}/crear`
export const USUARIOS_EDITAR = `${USUARIOS}/:id/editar` 
export const USUARIOS_ROLES = `${USUARIOS}/:id${ROLES}`
export const GRUPOS = `${USUARIOS}/grupos`;

export const ADMIN_ROLES = `${ROLES}/management`
export const ROLES_CREAR = `${ROLES}/crear`
export const ROLES_EDITAR = `${ROLES}/:id/editar`
export const ROLES_PERMISOS = `${ROLES}/:id${PERMISOS}`

export const ADMIN_PERMISOS = `${PERMISOS}/management`
export const PERMISOS_CREAR = `${PERMISOS}/crear`
export const PERMISOS_EDITAR = `${PERMISOS}/:id/editar`

export const routes = [
    {
        id:1,
        pageId: MODULE_MENU.FEED,
        path: paths.HOME,
        component: Home,
        label: 'Home',
        icon: HomeOutlinedIcon,
        isNavLink: true,
        isAdminPage: false,
        children:[
            {
                pageId: MODULE_MENU.FEED,
                path: paths.FEED_NUEVO,
                component: FeedNew,
                label: 'Crear Noticia',
            },
            {
                pageId: MODULE_MENU.FEED,
                path: paths.FEED_EDITAR,
                component: FeedEdit,
                label: 'Editar Noticia',
            },
        ]
    },
    {
        pageId:MODULE_MENU.EDUCACION_CONTINUA,
        externalPath: 'https://quienessomos.talisis.com/',
        label: 'Quiénes somos',
        isNavLink: true,
        type: 'external-link',
        isDefault: true,
        icon: AccessibilityNewIcon
    },
    {
        pageId:27,
        externalPath: 'https://expedientes.talisis.com/index.php/',
        label: 'Expediente',
        isNavLink: true,
        type: 'external-link',
        isAdminPage: true,
        icon: DescriptionIcon
    },
    {
        id:29,
        pageId:MODULE_MENU.DEGREE,
        path: paths.TITLU_CERT,
        label: 'Titulación y Certificación',
        component: TitulCerti,
        isNavLink: true,
        isAdminPage: true,
        children:[
            {
                pageId:MODULE_MENU.DEGREE,
                path: paths.TITLU_CERT_STUDENT_EDIT,
                component: TitulCertiStdtEdit,
                label: 'Editar Alumno'
            }
        ]
    },
    {
        path: paths.COMUNIDAD,
        component: Feed,
        label: 'Comunidad',
        icon: FreeBreakfastOutlined,
    },
    
    {
        id:13,
        pageId:MODULE_MENU.EDUCACION_CONTINUA,
        path: '/',
        label: 'Explorar',
        icon: SchoolIcon,
        isNavLink: true,
        isAdminPage: false,
        isDefault: true
    },
    {
        pageId: MODULE_MENU.EDUCACION_CONTINUA,
        path: '/demo',
        isNavLink: false,
        component: DemoEasyGenerator,
    },
    {
        pageId: MODULE_MENU.EDUCACION_CONTINUA,
        path: '/demo2',
        isNavLink: false,
        component: DemoEasyGenerator,
    },
    {
        path: paths.CURSOS,
        id:2,
        pageId: MODULE_MENU.CURSOS,
        component: Coursesv2,
        label: 'Mis Cursos',
        icon: LibraryBooksOutlinedIcon,
        // isMenu: true,
        isNavLink:true,
        children: [
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.MIS_CURSOS,
                label: 'Mis cursos',
                component: MyCourses,
                isNavLink: true,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.MIS_CURSOS_COMPLETOS,
                label: 'Clases concluidas',
                component: CompletedCourses,
                isNavLink: true,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.CATALOGO_CURSOS_DETALLE,
                label: 'Catálogo de Cursos',
                component: CourseCollection,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.KARDEX,
                label: 'Ver Kárdex',
                component: Kardex,
                isNavLink: true,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.CURSO_EDITAR,
                label: 'Editar curso',
                component: CourseEdit,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.CURSO_NUEVO,
                label: 'Nuevo curso',
                component: CourseNew,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.INSTRUCTOR_NUEVO,
                label: 'Nuevo instructor',
                component: InstructorNew,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.INSTRUCTOR_EDITAR,
                label: 'Editar instructor',
                component: InstructorEdit,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: '/course-s',
                component: ShowCourse,
                isNavLink: false,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.CURSOS_ACTUALES_DETALLE,
                label: 'Mis cursos Actuales',
                component: CurrentCourseDetail,
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.CURSOS_DETALLE,
                component: DetailCoursesInProgress
            },
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.PROGRAMAS_ID_CURSOS,
                component: AsyncProgram
            }
            ,
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.ALIADOS_SOURCE_ID_COURSOS,
                component: EduconCourses
            }
            ,
            {
                pageId: MODULE_MENU.CURSOS,
                path: paths.EDUCON_COMPANY_ID_COURSOS,
                component: EduconCourses
            }
        ]
    },
    {
        path: paths.ASESORIAS,
        pageId: MODULE_MENU.ASESORIAS,
        component: Consultancies,
        label: 'Asesorías',
        icon: LocalLibraryIcon,
        isNavLink:true,
        children: [
            {
                pageId: MODULE_MENU.ASESORIAS,
                path: paths.PERFIL_ASESORIAS,
                label: 'Perfil de asesor',
                component: ProfileAdviser,
                isNavLink: true,
            },
            {
                pageId: MODULE_MENU.ASESORIAS,
                path: paths.CALIFICAR_ASESORIA,
                label: 'Calificar Asesoría',
                component: RatingConsultancy,
                isNavLink: true,
            },
        ]
    },
    {
        id:3,
        pageId: MODULE_MENU.FEED,
        path: paths.PERFIL,
        component: Profile,
    },
    {
        id:3,
        pageId: MODULE_MENU.TESORERIA,
        path: paths.BILLING,
        component: Billing,
        label: 'Tesorería',
        icon: MonetizationOnOutlinedIcon,
        
    },
    {
        id:27,
        pageId: MODULE_MENU.ACCESO_CAMPUS,
        path: paths.ACCESO,
        component: Register,
        label: 'Acceso a Campus',
        icon: EventAvailableIcon,
        children:[
            {
                path: paths.ACCESO_REGRESO_PRESENCIAL,
                component: CommitmentLetter,
                label: 'Regreso Presencial',
                icon: EventAvailableIcon,
            }
        ]
    },

    {
        id:'25.1.2',
        path: paths.SOLICITUDES,
        icon: PrintOutlinedIcon,
        pageId: MODULE_MENU.TRAMITES,
        label: 'Trámites',
        component: RequestsItems,
        isAdminPage: false,
        isNavLink: true,
        children: [
            {     
                path: paths.SOLICITUDES,
                pageId: MODULE_MENU.TRAMITES,
                label: 'Solicitudes',
                component: RequestsItems,
                isAdminPage: false,
            },
            {
                path: paths.SOLICITUDES_DETALLE,
                pageId: MODULE_MENU.TRAMITES,
                label: 'Solicitudes',
                component: ShowRequestItem,
                isAdminPage: false,
            },
            {
                path: paths.SOLICITUDES_CARGA_ARCHIVOS,
                pageId: MODULE_MENU.TRAMITES,
                label: 'Solicitudes',
                component: SendingDocuments,
                isAdminPage: false,
            }
        ]
    },
    // {
    //     id:7,
    //     pageId: MODULE_MENU.SIMULACION,
    //     path: '',
    //     label: 'ADMIN',
    //     isNavLink: true,
    //     isAdminPage: false,
    //     isLabel:true,
    //     type: 'section'
    // },

    {
        id: 25,
        pageId: MODULE_MENU.GESTION_SERVICIOS,
        label: 'Trámites',
        path: paths.GESTION_SERVICIOS,
        component: RequestManagment,
        icon: PrintOutlinedIcon,
        isNavLink: true,
        isAdminPage: true,
        children: [
            {
                id:'25.1',
                path: paths.SOLICITUDES,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
                label: 'Solicitudes',
                component: RequestsItems,
                isAdminPage: false,
            },
            {
                path: paths.SOLICITUDES_DETALLE,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
                label: 'Solicitudes',
                component: ShowRequestItem,
                isAdminPage: false,
            },
            {
                path: paths.SOLICITUDES_CARGA_ARCHIVOS,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
                label: 'Solicitudes',
                component: SendingDocuments,
                isAdminPage: false,
            },
            {
                path: paths.GESTION_SERVICIOS,
                label: 'Gestión de Servicios',
                component: RequestManagment,
                isAdminPage: false,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
            },
            {
                path: paths.GESTION_SERVICIOS_CREAR,
                label: 'Trámites',
                component: NewRequest,
                isAdminPage: false,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
            },
            {
                //path: '/requests-managment/new/step/:stepId?',
                path: paths.GESTION_SERVICIO_NEW_STEP,
                label: 'Trámites',
                component: StepConfig,
                isAdminPage: false,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
            },
            {
                path: paths.GESTION_SERVICIOS_EDITAR,
                label: 'Trámites',
                component: EditRequest,
                isAdminPage: false,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
            },
            {
                //path: '/requests-managment/edit/:id/step/:stepId',
                path: paths.GESTION_SERVICIO_EDITAR_PASO,
                label: 'Trámites',
                component: StepConfig,
                isAdminPage: false,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
            },
            {
                //path: '/requests-managment/edit/:id/new/step',
                path: paths.GESTION_SERVICIO_CONFIGURACION_PASO,
                label: 'Trámites',
                component: StepConfig,
                isAdminPage: false,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
            },
            {
                //path: '/requests-managment/request/:id',
                path: paths.GESTION_SERVICIO_SHOW,
                label: 'Administracion de Trámites',
                component: ShowRequest,
                isAdminPage: false,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
            },
            {
                //path: '/requests-managment/request/:id/step/:stepId',
                path: paths.GESTION_SERVICIO_SHOW_STEP,
                label: 'Trámites',
                component: StepConfigShow,
                isAdminPage: false,
                pageId: MODULE_MENU.GESTION_SERVICIOS,
            },
        ]
    },

    
    
    
    {
        id:7,
        pageId:MODULE_MENU.SIMULACION,
        path: paths.SIMULACION,
        component: Simulation,
        label: 'Simulación',
        icon: PageviewOutlinedIcon,
        isNavLink: true,
        isAdminPage: false
    },
    {
        id:42,
        pageId: MODULE_MENU.NOTIFICACIONES,
        path: paths.NOTIFICACIONES,
        component: AdminNotifications,
        label: 'Notificaciones',
        icon: Notifications,
        isNavLink: false,
    },
    {
        pageId: MODULE_MENU.NOTIFICACIONES,
        path: paths.NOTIFICACIONES_CREAR,
        component: NotificationsNew,
        label: 'Crear Notificación'
    },
    {
        pageId: MODULE_MENU.NOTIFICACIONES,
        path: paths.NOTIFICACIONES_EDITAR,
        component: NotificationsEdit,
        label: 'Editar Notificación'
    },
    {
        
        path: paths.ACCESO_REGRESO_PRESENCIAL,
        component: CommitmentLetter,
        label: 'Regreso Presencial',
        icon: EventAvailableIcon,
    },
    {
        id:43,
        pageId: MODULE_MENU.SIMULACION,
        path: paths.USUARIOS_GRUPOS,
        icon: Group,
        label: 'Grupos',
        component: Groups,
        isAdminPage: true,
        isNavLink: true,
        children: [
            {  
                pageId: MODULE_MENU.SIMULACION,
                path: paths.USUARIOS_GRUPOS_CREAR,
                component: NewGroup,
                label: 'Nuevo Grupo',
                isAdminPage: false,
                isNavLink: true,
            },
            {  
                pageId: MODULE_MENU.SIMULACION,
                path: paths.USUARIOS_GRUPOS_EDITAR,
                component: EditGroup,
                label: 'Editar Grupo',
                isAdminPage: false,
                isNavLink: true,
            },
        ]
    },
    {
        id: 43,
        pageId: MODULE_MENU.SIMULACION,
        path: paths.COURSES_MANAGEMENT,
        component: CourseManagement,
        label: 'Cursos',
        icon: LibraryBooksIcon,
        isNavLink: true,
        isAdminPage: true,
    },
   /*  No entra en MVP
    {
        id:34,
        pageId:34,
        path: '/membresias',
        component : MembresiaManagment,
        label: 'Membresía',
        icon: SchoolIcon,
        isNavLink: true,
    },*/
    {

        id: 19,
        pageId: MODULE_MENU.ESTATUS_ORDENES,
        label: 'Órdenes',
        path: paths.GESTION_DE_SERVICIOS,
        component: OrdersStatus,
        icon: ViewList,
        isNavLink: true,
        isAdminPage: true,
    },
    { // Se agrega para release Ciclo 7
        id: 20,
        pageId: MODULE_MENU.SIMULACION,
        label: 'Membresías',
        path: paths.MEMBRESIAS,
        component: Memberships,
        icon: CardMembershipIcon,
        isNavLink: true,
        isAdminPage: false,
    },
    {
        id: 21,
        pageId: MODULE_MENU.COUPONS_MANAGEMENT,
        label: 'Descuentos',
        path: paths.CO_COUPONS_MANAGEMENT,
        component: Coupons,
        icon: ViewList,
        isNavLink: true,
        isAdminPage: true,
    },
    {
        pageId: MODULE_MENU.COUPONS_MANAGEMENT,
        path: paths.CO_COUPONS_CREATE,
        component: CouponDetail,
    },
    {
        pageId: MODULE_MENU.COUPONS_MANAGEMENT,
        path: paths.CO_COUPONS_EDIT,
        component: CouponDetail,
    },
    {
        id: MODULE_MENU.USUARIOS_PERMISOS,
        label: 'Usuarios',
        icon: SupervisorAccountIcon,
        isNavLink: true,
        isAdminPage: true,
        isMenu: true,
        type: 'menu',
        children: [
            {  
                pageId:MODULE_MENU.USUARIOS,
                path: paths.USUARIOS_MANAGMENT,
                component: UserManagement,
                label: 'Personas',
                isAdminPage: false,
                isNavLink: true,
            },
            {
                pageId: MODULE_MENU.USUARIOS,
                path: paths.USUARIOS_EDITAR,
                component: UserForm,
                label: 'Editar Usuario'
            },
            {
                pageId: MODULE_MENU.USUARIOS,
                path: paths.USUARIOS_CREAR,
                component: UserForm,
                label: 'Nuevo Usuario'
            },
            { 
                pageId: MODULE_MENU.USUARIOS,
                path: paths.USUARIOS_ROLES,
                component:PersonRoleForm,
                label: 'Usuario roles'
            },
            {
                pageId: MODULE_MENU.ROLES,
                path: paths.ROLES_MANAGMENT,
                component: RoleManagement,
                label: 'Roles',
                isAdminPage: false,
                isNavLink: true,
            },
            {
                pageId: MODULE_MENU.ROLES,
                path: paths.ROLES_CREAR,
                component:RoleForm,
                label: 'Nuevo Role'
            },
            {
                pageId: MODULE_MENU.ROLES,
                path: paths.ROLES_EDITAR,
                component: RoleForm,
                label: 'Editar Role'
            },
            { 
                pageId: MODULE_MENU.ROLES,
                path: paths.ROLES_PERMISOS,
                component:PermissionRoleForm,
                label: 'Asignación de permisos'
            }, 
            {
                pageId: MODULE_MENU.PERMISOS,
                path: paths.PERMISOS_ADMIN,
                component: PermissionManagment,
                label: 'Permisos',
                isAdminPage: false,
                isNavLink: true,
            },
            {
                pageId: MODULE_MENU.PERMISOS,
                path: paths.PERMISOS_CREAR,
                component: PermissionForm,
                label: 'Permiso' 
            },
            {
                pageId: MODULE_MENU.PERMISOS,
                path: paths.PERMISOS_EDITAR,
                component: PermissionForm,
                label: 'Permiso' 
            },
            {
                pageId: MODULE_MENU.USUARIOS,
                path: paths.USUARIOS_HEALTH_CHECK,
                component: HealthCheck,
                label: 'Health check',
                isAdminPage: true,
                isNavLink: true,
            },
        ]
    },
    {
        id: MODULE_MENU.SYSTEM,
        label:'Diseño',
        icon: DesktopWindowsOutlinedIcon,
        isNavLink: true,
        isAdminPage: false,
        isMenu: true,
        type: 'menu',
        children: [
            {
                pageId: MODULE_MENU.TOPOGRAFIA,
                path: paths.SYSTEM_TYPOGRAPHY,
                label: 'Tipografía',
                component: Typography,
                isAdminPage: false,
                isNavLink: true,
            },
            {
                pageId: MODULE_MENU.PALETA,
                path: paths.SYSTEM_PALETTE,
                label: 'Paleta',
                component: Palette,
                isAdminPage: false,
                isNavLink: true,
            }
        ]
    },
    {
        id:13,
        pageId:MODULE_MENU.EDUCACION_CONTINUA,
        path: '/',
        label: 'Explorar',
        icon: SchoolIcon,
        isNavLink: true,
        isAdminPage: false,
        isDefault: true
    },
    {
        path: paths.REFIERE_GANA_PATH,
        label: 'Explorar',
        isNavLink: false,
        isAdminPage: false,
        component: ReferralPlan
    },
    {
        // id:'25.1.2',
        path: '/recepcion-documentos',
        // pageId: MODULE_MENU.TRAMITES,
        label: 'Recepción de Documentos',
        component: DocumentsReception,
        isAdminPage: false,
        isNavLink: false,
        children: [
            {     
                path: '/recepcion-documentos/:id',
                label: 'Solicitudes',
                component: DocumentReceptionDetail,
                isAdminPage: false,
            },
        ]
    },
];

export default routes.map((it, index) => {
    // it.id = (index + 1 ).toString();
    it.id = (it.id)? it.id.toString(): 27;
    it.pageId = it.pageId ? it.pageId : null;

    if (it.children) {
        it.children = it.children.map((child, i) => ({
            ...child,
            id: `${it.id}.${(i + 1).toString()}`,
            pageId: child.pageId ? child.pageId : `${it.id}.${(i + 1).toString()}`
        }))
    }

    return it;
});
