import React from 'react';
import clsx from 'clsx';

// Material components
import { Box, Divider, Icon, makeStyles } from '@material-ui/core';

// core components
import FileDetail from './FileDetail';
import ShippingBttn from './ShippingBttn';

// Shared Utils
import dateFormat from '../../../design-system/utils/dateFormat';

// Constants
import { SIMPLE_DATE_FORMAT } from '../../../design-system/constants/date-formats';

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column"
        }
    },
    containerShippingGuideButton: {
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    }
}));

const DeliveryGroupPhysicalDocs = ({listGroup, locationCatalog = {}}) => {
    const classes = useStyles();

    return (
        listGroup.map((programObj, programIndex, {length}) =>
        <Box key={`list-programs-item-${programIndex}`}>
            <Box p={3} display='flex' alignItems='center' justifyContent='space-between' className={classes.container}>
                <Box display='flex' flexDirection='row' gridGap={38} alignItems='center'>
                    <Icon className={clsx("ri-file-text-line text-fill-stroke-100")} />
                    <Box>
                        {
                            programObj.documents.map((documentObj, documentIndex) =>
                            <FileDetail
                                    displayFileIcon={false}
                                    key={`program-document-item-${programIndex}-${documentIndex}`}
                                    title={documentObj.document_name}
                                    fileName={documentObj.file_name}
                                    filePath={documentObj.path}
                                    dueDate={dateFormat(documentObj.deadline, SIMPLE_DATE_FORMAT)}
                                    active={false}
                                    flex={1}
                                    status={documentObj.record_status_id}
                                    tags={documentObj.value_list}
                                    isAdmin={false}
                                    downloadableFile={!!documentObj.file_id_downloadable && documentObj.path_downloadable}
                                    instruction={documentObj.instruction}
                                />
                            )
                        }
                    </Box>
                </Box>
                <Box minWidth={215} className={classes.containerShippingGuideButton}>
                    <ShippingBttn
                        templateId={programObj.template_id}
                        documents={programObj.documents} 
                        company={programObj.company_id}
                        shippingGuideId={programObj.shipping_guide_id}
                        locationCatalog={locationCatalog}
                        generateShipment={programObj.showShippingGuideButton} 
                        showShippingDetail={programObj.showDetailGuideButton }
                    />
                </Box>
            </Box>
            { programIndex < length && <Divider className='bg-fill-stroke-200'/> }
        </Box>
    ));
};

export default DeliveryGroupPhysicalDocs;