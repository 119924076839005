import { SOURCES } from "../design-system/constants/sources";

export const convertOuToSource = (company_id, source_id) => {
    if (company_id) {
        if (company_id === "UERRE" && source_id === SOURCES.IE_UNIVERSITY) return source_id;
        if (company_id === "TAL") return SOURCES.TALISIS;
        if (company_id === "UERRE") return SOURCES.UERRE;
        if (company_id === "UNID") return SOURCES.UNID;
        if (company_id === "HH") return SOURCES.HH;
        if (company_id === "ADV") return SOURCES.ADVENIO;
    }
    return source_id;
}