import LogoVisa from "../../../../../../../assets/images/banks/visa-checkout-blanco.svg";
import LogoMaster from "../../../../../../../assets/images/banks/master-card-logo.svg";
import LogoAmex from "../../../../../../../assets/images/banks/american-expre-texto.svg";

export const getCardLogo = (logoName) => {
    const cardLogos = {
        'visa': LogoVisa,
        'mastercard': LogoMaster,
        'american_express': LogoAmex,
    }
    return cardLogos[logoName]
}
