import React from 'react';

// @material-ui/core
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';

// services
// import * as marketService from '../../../../../services/MarketPlaceService';
import { log } from '../../../../../shared/utils/console.js'
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';

const FavoriteButton = ({ course, user, initialState, buttonClass, render, fontSize = "small", size = "medium" }) => {
    const [favorited, setFavorited] = React.useState(false);
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const handleClick = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        try {
            setFavorited(!favorited);

            const body = {};

            if ('third_party_id' in course) {
                body['person_id'] = user.person_id;
                body['course_id'] = course.id;
                body['third_party_id'] = course.third_party_id;
            } else {
                body['person_id'] = user.person_id;
                body['course_id'] = course.id;
            }

            if (!favorited) {
                await marketService.postFavorite(body, "create");
            } else {
                await marketService.postFavorite(body, "delete");
            }
        } catch (e) {
            log(e.message);
        }
    };

    React.useEffect(() => {
        setFavorited(initialState);
    }, []);

    return (
        <Tooltip
            PopperProps={{
                disablePortal: false,
                popperOptions: {
                    positionFixed: true,
                    modifiers: {
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "window"
                        }
                    }
                }
            }}
            title={favorited ? "Eliminar de favoritos" : "Agregar a favoritos"}
        >
            {
                render ? render({ favorited, onClick: handleClick }) : (
                    <IconButton className={buttonClass} size={size} onClick={handleClick}>
                        {favorited ?
                            <BookmarkIcon fontSize={fontSize} color="secondary" /> :
                            <BookmarkBorderOutlinedIcon fontSize={fontSize} color="primary" />
                        }
                    </IconButton>
                )
            }
        </Tooltip>
    );
};

export default FavoriteButton;