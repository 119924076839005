import { makeStyles } from "@material-ui/core";

export const checkoutPhoneErrorStyles = makeStyles((theme) => ({
    btn: {
        minWidth: '175px',
        textTransform: 'none',
        "@media (max-width: 500px)": {
            minWidth: '40%'
        }
    },
    phoneInput:
    {
        marginTop: '40px',
        width:'100%'
    },
    phoneTitle:
    {
        color: theme.palette.colors.white,
        paddingBottom: '14px'
    },
    phoneInputError:
    {
        borderColor: `${theme.palette.colors.electricRed[400]} !important`
    },
}));
