import React, { lazy, Suspense, useContext, useReducer } from 'react';
import PropTypes from 'prop-types'

// @material-ui/core
import { Typography, Box, Chip, Icon } from '@material-ui/core';


import { SwiperSlide } from 'swiper/react/swiper-react.js';
import SlickCarousel from '../../../../shared/components/SlickCarousel/SlickCarousel';
import TitleLink from '../../../../shared/components/TitleLink/TitleLink';

import useStyles from '../../../../assets/styles/jss/pages/courses/coursesInProgressStyle';
import { convertOuToSource }from '../../../../../src/constants/convertOuToSource';

const CourseProgressCardBody = lazy(() => import(/* webpackChunkName: "CourseProgressCardBody" */'./CourseProgressCardBody'));


const courses = [
    {
        "course_id": 62690,
        "start_date": "2022-10-17T00:00:00.000Z",
        "end_date": null,
        "grade": null,
        "progress_percentage": 0,
        "course_rating": 5,
        "third_party_id": "MMA00504",
        "source_id": 14 ,
        "name": "Integridad",
        "lang": null,
        "estimated_time": null,
        "difficulty_level": null,
        "image_url": "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_1.jpg",
        "is_active": true,
        "modality_id": 2,
        "level_id": null,
        "learning_style_id": 3,
        "company_id": "TAL",
        "course_url": "/demo",
        "course_url_neo": null,
        "enrollment_status_id": 3
    },
    {
        "course_id": 56116,
        "start_date": "2022-10-17T00:00:00.000Z",
        "end_date": null,
        "grade": null,
        "progress_percentage": 0,
        "course_rating": 4,
        "third_party_id": "MMA00504",
        "source_id": 14,
        "name": "Adminístrate al 100",
        "lang": null,
        "estimated_time": null,
        "difficulty_level": null,
        "image_url": "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_2.jpg",
        "is_active": true,
        "modality_id": 2,
        "level_id": null,
        "learning_style_id": 3,
        "company_id": "TAL",
        "course_url": "/demo2",
        "course_url_neo": null,        
        "enrollment_status_id": 3
    }
]




const DemoCourses = (props) => {
    const { user } = props;
    const classes = useStyles();
    const [isDemoUser, setIsDemoUser] = React.useState(false)
    
    React.useEffect(() => {
        const role = JSON.parse(localStorage.getItem('permissions'));
        role.find(r => r.role_name === "Estudiante-Demo")
        if(role.find(r => r.role_name === "Estudiante-Demo")) setIsDemoUser(true)
    }, [])
       
    return (
        <>
            {
                isDemoUser &&
                <Box className={classes.hover_carousel}>
                    {<Typography className={classes.title} variant="h4"><TitleLink textPrimary='Cursos demo' textSecondary="Ver todos" /* onClick={(e) => handleChangeTabInProgress(e)} */ /></Typography>}
                    <SlickCarousel length={courses.length} typeCard="normal" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                        {
                            courses.length > 0 && courses.map((courseItem, index) => {
                                return (<SwiperSlide key={courseItem?.course_id} className={classes.card}>
                                    <Suspense fallback={<div></div>}>
                                        <CourseProgressCardBody
                                            course_url_neo={courseItem?.course_url_neo}
                                            source_id={courseItem?.source_id}
                                            course_url={courseItem?.course_url}
                                            enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                            course_id={courseItem?.course_id}
                                            company_id={courseItem?.company_id}
                                            course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                            user={user}
                                            progress_percentage={courseItem?.progress_percentage}
                                            media={{
                                                isProgress: true,
                                                index: index,
                                                source: {
                                                    id: convertOuToSource(courseItem.company_id),
                                                    icon: 'desktop_windows',
                                                    modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                },
                                                image: courseItem?.image_url,
                                                company_id: courseItem?.company_id,
                                            }}
                                            content={{
                                                isProcess: true,
                                                course_id: courseItem?.course_id,
                                                progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                title: courseItem?.name,
                                                hiddeLabel: true,
                                            }}
                                        />
                                    </Suspense>
                                </SwiperSlide>)
                            })
                        }
                    </SlickCarousel>
                </Box>
            }
            {
                !isDemoUser && ''
            }
        </>
    );
};


export default DemoCourses;