import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Helmet } from 'react-helmet';
import { generatePath } from "react-router";

// @material-ui/core
import { Box, Card, CardContent, Container, Dialog, DialogContent, DialogTitle, Grid, Hidden, IconButton, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';

import { useHistory } from 'react-router-dom';

// redux
import { connect, useSelector } from 'react-redux';
import SummaryCard from '../../Market/SummaryCard/SummaryCard';
import SummaryList from '../../Market/SummaryCard/SummaryList/SummaryList';
// import StudyPlanHighlights from './StudyPlanHighlights/StudyPlanHighlights';
import StudyPlanText from './StudyPlanText/StudyPlanText';
import StudyPlanChips from './StudyPlanChips/StudyPlanChips';
import StudyPlanTextCard from './StudyPlanTextCard/StudyPlanTextCard';
import StudyPlanListCard from './StudyPlanListCard/StudyPlanListCard';
// import FAQsCard from './FAQsCard/FAQsCard';
import DynamicList from './DynamicList/DynamicList';
import StudyPlanMultiList from './StudyPlanMultiList/StudyPlanMultiList';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import SummaryListWithIcons from '../../Market/SummaryCard/SummaryListWithIcons/SummaryListWithIcons';

 // Shared Components
import FAQsComponent from '../../../shared/components/FAQsComponent/FAQsComponent';
import CourseCarousel from '../../../shared/components/CourseCarousel/CourseCarousel';
import ModalClassEnrollment from "../../../shared/components/ModalClassEnrollment/ModalClassEnrollment";
import { log } from '../../../shared/utils/console.js'

// utils
import objectIsEmpty from "../../../shared/utils/objectIsEmpty";
import { getSessionCookie } from "../../../shared/utils/Sessions";
import { splitWordByLimitLength } from '../../../design-system/utils/splitString';
import { downloadSyllabus } from '../DetailStudyPlanProgram/DetailedStudyPlanHeader/helpers/downloadSyllabus';

// SDK
import { ProgramService, MarketPlaceService, ProductService, CourseService } from '@sdk-point/talisis';
import FAQsCourse from '../../../shared/components/FAQsCourse/FAQsCourse';
import SummaryCardMemberships from '../../Market/SummaryCard/SummaryCardMemberships'

// Constants
import { DESC_PRODUCT_DEFAULT } from '../../../shared/constants/products';

// styles
import useStyles from '../../../assets/styles/jss/pages/studyPlan/detailStudyPlanProgram'
import BannerDetail from '../../../design-system/components/BannerDetail/BannerDetail';
import { SOURCE_ID_COMPANY_NAME } from '../../../design-system/constants/sources';
import ContentStrip from '../../../design-system/components/ContentStrip/ContentStrip';

import clsx from 'clsx';
//Hubspot
import { HubspotForm } from 'react-hubspot-forms'

import {PORTAL_ID, PROGAM_NAME, ID_FORM} from '../../../shared/constants/hubspot.js'
import * as paths from '../../../routes/paths';

const tempHeader = "https://point-files.s3.us-west-2.amazonaws.com/market-place/detailed-study-headerImgs/unid-header.png"

const ProgramServiceSDK = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const CourseServiceSDK = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const THIRD_PARTY_ID_BACH = "B27-BACA-19O";
const BROCHURE_URL_BACH = 'https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/brochures/UNID/Bachillerato.pdf';

const processCostumData = (planInformation) => {
    return {
        ...planInformation,
        name: planInformation.customData?.name ?? planInformation.name,
        description: planInformation.customData?.description ?? planInformation.description ?? DESC_PRODUCT_DEFAULT,
    };
};

const getIdFromSlug = (slug) => {
     if (!slug) return "";
 
     const splitSlug = slug.split('-');
     
     return splitSlug.length === 1 ? splitSlug[0] : splitSlug[splitSlug.length -1];
};

const DetailStudyPlanProgram = (props) => {
    const etiquetaType = {
        LI: "chip3-blue-400",
        MA: "chip3-green-500",
        BA: "chip3-unid-magenta",
        PO: "chip3-orange-400"
    }
    const { person_id } = useSelector((state) => state.userReducer.user);
    const { id } = useParams();
    const {user}=props;
    const [studyPlanInfo, setStudyPlanInfo] = useState({
        isLoading: true,
        planInformation: [],
        subjectTotal: "#",
        faqList: [],
    });
    const [downloadableContent, setDownloadableContent] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const [formId, setFormId] = useState('')

    // Obtenemos el identificador del programa mediante el slug
    const programId = getIdFromSlug(id);

    const history = useHistory();
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const classes = useStyles({isLogged});
    const { isLoading, planInformation, subjectTotal, faqList } = studyPlanInfo;
    const myRef = useRef(null);
    const [membershipData,setMembershipData]=React.useState(false);
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');
    const productService = new ProductService(process.env.REACT_APP_ENV ,process.env.REACT_APP_X_API_KEY);
    const [disabledCta,setDisabledCta]=React.useState(false);
    const [modalProps, setModalProps] = React.useState({ open: false, loading: false, course: null, error: null });
    const [saveAsFavorite, setSaveAsFavorite] = React.useState(false);
    const [showSubscribeStickyButton, setShowSubscribeStickyButton] = React.useState(false);
    const [userCanEnroll, setUserCanEnroll] = React.useState(true)

    useEffect(() => {
        window.scrollTo(0, 0);
        const doFetch = async() => {
            const onlineInfoTemp = await ProgramServiceSDK.getProgramById(programId, { person_id } );
            const onlineInfo = processCostumData(onlineInfoTemp);
            const totalSubjects = await onlineInfo?.subjects?.length;
            const faqRes = await ProgramServiceSDK.getFAQList();
            let membershipData = await marketService.getPersonMembership(isLogged?user.person_id:null);
            log("membresias: ",membershipData);
            setMembershipData(membershipData);
            const fetchedFaqList = await faqRes.body;
            setStudyPlanInfo({
                ...studyPlanInfo,
                isLoading: false,
                planInformation: onlineInfo,
                subjectTotal: totalSubjects,
                faqList: fetchedFaqList,
            });
            log("programa: ",onlineInfo);

        };
        doFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setProgramId();
      if( planInformation.length === 0 ) return;
      setDownloadableContent(planInformation);
    }, [planInformation]);

    useEffect(() =>{
        const verifyUserCanEnroll = async () => {
            if(isLogged) {
                const {can_enrroll} = await CourseServiceSDK.userCanEnroll(person_id);
                console.log(can_enrroll)
                setUserCanEnroll(can_enrroll)
            }
        };

        verifyUserCanEnroll();
    }, []);

    const handleOnCloseModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false });
    };
    
    const handleOnRetry = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false });
        membershipProgramEnrollment();
    };

    const handleOnSupport = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false });
    };

    const handleChangeShowSubscribeStickyButton = () => {
        setShowSubscribeStickyButton(prevStatus => !prevStatus);
    };

    const chipsInfo2 = planInformation?.skills?.map( skillName => skillName.description)
    const planListCardObj = planInformation?.objectives?.map( objectiveName => objectiveName.description)
    
    let tituloIngreso;
    const listaIngreso = [];
    const sampleConstant = planInformation?.candidate_profile?.forEach((candidateInfo, index) => {
        if( index === 0 ){
            tituloIngreso = candidateInfo.description
        }
        if( index !== 0 ){
            listaIngreso.push(candidateInfo.description)
        }
    })

    let tituloCampoLaboral;
    const listaCampoLaboral = [];
    const sampleConstant2 = planInformation?.work_field?.forEach((workInfo, index) => {
        if( index === 0 ){
            tituloCampoLaboral = workInfo.description
        }
        if( index !== 0 ){
            listaCampoLaboral.push(workInfo.description)
        }
    })

    const toolsList = planInformation?.tools?.map( toolName => toolName.description)
    const benefitsList = planInformation?.benefits?.map( benefit => benefit.description)
    const incentivesList = planInformation?.incentives?.map( incentive => incentive.description)
    const reqInscripcion = planInformation?.registration_requirements?.map( registrationItem => registrationItem.description)
    const degreeRequirements = planInformation?.degree_requirements?.map( degreeItem => degreeItem.description)

    const summaryCardChildren = [
        <SummaryListWithIcons title={"Beneficios de nuestros programas"} bulletList={benefitsList} />,
        <SummaryList title={"Este curso incluye"} bulletList={incentivesList} />,
        <SummaryList title={"Requisitos de inscripción"} bulletList={reqInscripcion} />,
        <SummaryList title={"Requisitos de titulación"} bulletList={degreeRequirements} />,
    ];

    const membershipProgramEnrollment = async()=>
    {
        try
        {
            setDisabledCta(true);
            const response = await productService.postProductEnrollment({
                person_id: user?.person_id,
                program_id: planInformation?.id,
            });
            if(planInformation?.id!==null && user?.person_id!==null && planInformation?.company_id!==null)
            {
                setDisabledCta(false);
                let searchParams = `programId=${planInformation?.id}`;
                if(response?.success)
                {
                    history.replace({
                        pathname: "/confirmacion-inscripcion",
                        search: searchParams
                    });
                }
                else
                {
                    setModalProps({ ...modalProps, open: true, loading: false, error: 2 });
                }
            }
            else
            {
                setDisabledCta(false);
                setModalProps({ ...modalProps, open: true, loading: false, error: 1 });
            }
        }
        catch(e)
        {
            log(e);
            setDisabledCta(false);
            setModalProps({ ...modalProps, open: true, loading: false, error: 2 });
        }
    }

    const handleCheckout = () => {
        if (planInformation?.user_membership_included) {
            membershipProgramEnrollment();
            log("incluido en membresía");
        }
        else {
            history.push(`${paths.MEMBRESIAS}?sku=${planInformation?.product_sku}`);
        }
    }
    
    const handleDownloadClick = () => {
        const brochure_url = planInformation.third_party_id === THIRD_PARTY_ID_BACH ? BROCHURE_URL_BACH : planInformation.brochure_url;
        if (person_id || !formId){
            if (brochure_url) {
                let link = document.createElement('a');
                link.href = brochure_url;
                link.target = '_blank';
                link.download = brochure_url.substr(brochure_url.lastIndexOf('/') + 1);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                downloadSyllabus(downloadableContent);
            }
        }else{
            setFormOpen(true)
        }
    };

    const setProgramId = () => {
        switch (planInformation.third_party_id) {
            case PROGAM_NAME.LIC_ADM_EMP:
                setFormId(ID_FORM[PROGAM_NAME.LIC_ADM_EMP])
                break;
            case PROGAM_NAME.LIC_ADM_EMPTUC:
                setFormId(ID_FORM[PROGAM_NAME.LIC_ADM_EMPTUC])
                break;
            case PROGAM_NAME.LIC_CON_FIN:
                setFormId(ID_FORM[PROGAM_NAME.LIC_CON_FIN])
                break;
            case PROGAM_NAME.LIC_DER_CIE:
                setFormId(ID_FORM[PROGAM_NAME.LIC_DER_CIE])
                break;
            case PROGAM_NAME.LIC_EDU:
                setFormId(ID_FORM[PROGAM_NAME.LIC_EDU])
                break;
            case PROGAM_NAME.LIC_MER_EST:
                setFormId(ID_FORM[PROGAM_NAME.LIC_MER_EST])
                break;
            case PROGAM_NAME.LIC_ING_SOFT_SIS:
                setFormId(ID_FORM[PROGAM_NAME.LIC_ING_SOFT_SIS])
                break;
            case PROGAM_NAME.MA_ADM_NEG_FIN_INT:
                setFormId(ID_FORM[PROGAM_NAME.MA_ADM_NEG_FIN_INT])
                break;
            case PROGAM_NAME.MA_ADM_NEG_GEN_GLOB:
                setFormId(ID_FORM[PROGAM_NAME.MA_ADM_NEG_GEN_GLOB])
                break;
            case PROGAM_NAME.MA_ADM_NEG_MER_INT:
                setFormId(ID_FORM[PROGAM_NAME.MA_ADM_NEG_MER_INT])
                break;
            case PROGAM_NAME.MA_MER_DIG:
                setFormId(ID_FORM[PROGAM_NAME.MA_MER_DIG])
                break;
            case PROGAM_NAME.MA_TEC_INF_COM:
                setFormId(ID_FORM[PROGAM_NAME.MA_TEC_INF_COM])
                break;
            case PROGAM_NAME.MA_EDU:
                setFormId(ID_FORM[PROGAM_NAME.MA_EDU])
                break;
            case PROGAM_NAME.MA_JUI_ORA:
                setFormId(ID_FORM[PROGAM_NAME.MA_JUI_ORA])
                break;
            case PROGAM_NAME.MA_ADM_NEG:
                setFormId(ID_FORM[PROGAM_NAME.MA_ADM_NEG])
                break;
            default:
                setFormId(0)
                break;
        }
    }

    const handleClose = () => {
        setFormOpen(false)
    }

    const SaveBttn = () => {
        const handleSaveAsFavorite = async (e) => {
            e.stopPropagation();
            setSaveAsFavorite(!saveAsFavorite);
            await CourseServiceSDK.saveUnsaveCourse(person_id, '', id, !saveAsFavorite);
        };

        return <IconButton onClick={handleSaveAsFavorite} className='p-0'>
            <Icon className={clsx({'ri-bookmark-fill text-white': saveAsFavorite, 'ri-bookmark-line': !saveAsFavorite}, 'font-size-24 text-dark-black-200')} />
        </IconButton>
    }
    
    return (
        <>
            <LoaderContent loading={isLoading} className={classes.rootLoader}>
                {
                    ( !isLoading ) &&
                    <>
                        <Helmet>
                            <meta name="description" content={splitWordByLimitLength(planInformation?.description ?? "", 150, '...')} />
                            <title>{planInformation?.name}</title>
                        </Helmet>

                        {/* ---- Banner ---- */}
                        <BannerDetail
                            title={planInformation.name}
                            backgroundImage={planInformation.image_url? planInformation.image_url: tempHeader} 
                            buttonText={"Descargar programa"} 
                            buttonIcon={"ri-download-2-line"}
                            actionButton={handleDownloadClick}
                            labelText={planInformation.level_description}
                            chipType={etiquetaType[planInformation.level_id]}
                            sourceId={SOURCE_ID_COMPANY_NAME[planInformation.company_id]}
                            displayBttn
                            component={SaveBttn}
                        />

                        {/* ---- HUBSPOT Form ---- */}
                        <React.Fragment>
                            <Dialog
                                open={formOpen} onClose={handleClose} className={classes.dialog}
                                aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" 
                            >
                                <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                                    <Grid container className={classes.containerTitle}>
                                        <Grid item md={10}>
                                            <Typography variant='h4' className={classes.dialogTitleTypography}>{planInformation.name}</Typography> 
                                        </Grid>
                                        <Grid item md={2} className={classes.gridClose}>
                                            <Typography>
                                                <CloseIcon className={classes.iconClose} onClick={handleClose}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    {
                                        <HubspotForm portalId={PORTAL_ID} formId={formId} />
                                    }
                                </DialogContent>
                            </Dialog>
                        </React.Fragment>

                        {/* ---- Detalle de Membresía inscripcion---- */}
                        <Container className={classes.container}>
                            <Hidden mdUp data-info="xs-summary">
                                <SummaryCard
                                    cardSize={4}
                                    hideBookmark={false} 
                                    cardTitle={ planInformation?.purchased ? "Ya has comprado este programa" : "Cúrsala desde:"}
                                    price={ planInformation?.price}
                                    oldPrice={  planInformation?.price + 100}
                                    caption={"Es posible que otros usuarios puedan ver parte de la información al usar la plataforma"}
                                    btnText={ planInformation?.purchased ? "Ir a mis clases" : "Inscribirme ahora"}
                                    btnDisabled={!planInformation?.purchased && !userCanEnroll}
                                    loading={false}
                                    btnFunction={ planInformation?.purchased ? () => history.push(`/cursos`) : () => handleCheckout()} 
                                    disabledCta={disabledCta} 
                                    // btnFunction={ planInformation?.purchased ? () => history.push(`/cursos`) : () => handleCheckout()}
                                    isPurchased={ planInformation?.purchased }
                                    membershipData={ membershipData }
                                    company_id={ planInformation?.company_id }
                                    user_membership_included={ planInformation?.user_membership_included }
                                    closest_membership_included={ planInformation?.closest_membership_included }
                                    hideInfoMembership={true}
                                    isLogged={isLogged} 
                                    inlinePrice={false}
                                    onChangeShowSubscribeStickyButton={handleChangeShowSubscribeStickyButton}
                                />
                                <Box mb={3}>
                                    <Button
                                        fullWidth
                                        startIcon={<Icon className='ri-download-2-line font-size-24' />}
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleDownloadClick}
                                    >
                                        Descarga el programa de estudios
                                    </Button>
                                </Box>
                            </Hidden>

                        {/* --- contenido ----- */}
                            <Grid container style={{ position: "relative", zIndex: 2 }} spacing={5}>
                                <Grid item md={8} xs={12} style={{paddingBottom: 0}}>
                                    <Box mb={2}>
                                        <ContentStrip
                                            id="dspp-contet-strip" 
                                            items={{
                                                modality: 'Online',
                                                title: planInformation?.level_id !== 'BA' ? "Título oficial" : null,
                                                certificate: planInformation?.level_id === 'BA' ? "Certificado oficial" : '',
                                                subjects: `${subjectTotal} Materias`,
                                                duration: `Duración: ${planInformation?.estimated_time === null ? 0 : planInformation.estimated_time} hrs`,
                                                lang: 'Español'
                                            }}
                                        />
                                    </Box>
                                    {
                                        planInformation?.description &&
                                        <StudyPlanText
                                            title={"Introducción"}
                                            textParagraph={planInformation?.description}
                                        />
                                    }
                                    {
                                        chipsInfo2?.length > 0 &&
                                        <StudyPlanChips 
                                            title={"Competencias"}
                                            chipsArray={chipsInfo2}
                                        />
                                    }
                                    {
                                        planListCardObj?.length > 0 &&
                                        <StudyPlanListCard
                                            title={"Lo que aprenderás"}
                                            listArray={planListCardObj}
                                        />
                                    }
                                    {
                                        ( listaCampoLaboral.length > 0 ) &&
                                        <StudyPlanTextCard
                                            title={"Campo de Trabajo"}
                                            textParagraph={tituloCampoLaboral}
                                            components={
                                                <DynamicList
                                                    listArray={listaCampoLaboral}
                                                />
                                            }
                                        />
                                    }
                                    {
                                        ( listaIngreso?.length > 0 ) &&
                                        <StudyPlanTextCard
                                            title={"Perfil del ingreso"}
                                            textParagraph={tituloIngreso}
                                            components={
                                                <>
                                                    <Typography variant='caption'>
                                                        Conocimientos sobre:
                                                    </Typography>
                                                    <DynamicList
                                                        listArray={listaIngreso}
                                                    />
                                                </>
                                            }
                                        />
                                    }
                                    {
                                        ( planInformation?.graduation_profile?.length > 0 ) &&
                                        <StudyPlanTextCard
                                            title={"Perfil del egresado"}
                                            textParagraph={planInformation?.graduation_profile?.[0].description}
                                        />
                                    }
                                    {
                                        ( toolsList?.length > 0 ) &&
                                        <StudyPlanMultiList 
                                            title={"Herramientas necesarias"}
                                            listArray={toolsList}
                                        />
                                    }
                                </Grid>
                                <Hidden smDown data-info="web-summary">
                                    <SummaryCard
                                        cardSize={4}
                                        hideBookmark={false} 
                                        cardTitle={ planInformation?.purchased ? "Ya has comprado este programa" : "Cúrsalo desde:"}
                                        price={ planInformation?.price}
                                        oldPrice={  planInformation?.price + 100}
                                        caption={"Es posible que otros usuarios puedan ver parte de la información al usar la plataforma"}
                                        btnText={ planInformation?.purchased ? "Ir a mis clases" : "Inscribirme ahora"}
                                        btnDisabled={!planInformation?.purchased && !userCanEnroll}
                                        loading={false}
                                        btnFunction={ planInformation?.purchased ? () => history.push(`/cursos`) : () => handleCheckout()} 
                                        disabledCta={disabledCta} 
                                        // btnFunction={ planInformation?.purchased ? () => history.push(`/cursos`) : () => handleCheckout()}
                                        components={summaryCardChildren}
                                        showStudentRegulation={true}
                                        isPurchased={ planInformation?.purchased }
                                        membershipData={ membershipData }
                                        company_id={ planInformation?.company_id }
                                        user_membership_included={ planInformation?.user_membership_included }
                                        closest_membership_included={ planInformation?.closest_membership_included }
                                        hideInfoMembership={false}
                                        isLogged={isLogged} 
                                        FAQsComponent={<FAQsCourse title={'¿Necesitas ayuda?'} />}
                                    />
                                </Hidden>
                                <Hidden smUp data-info="xs-summary-member">
                                    <SummaryCardMemberships
                                        cardSize={4}
                                        hideBookmark={false} 
                                        cardTitle={ planInformation?.purchased ? "Ya has comprado este programa" : "Cúrsala desde:"}
                                        price={ planInformation?.price}
                                        oldPrice={  planInformation?.price + 100}
                                        caption={"Es posible que otros usuarios puedan ver parte de la información al usar la plataforma"}
                                        btnText={ planInformation?.purchased ? "Ir a mis clases" : "Inscribirme ahora"}
                                        btnDisabled={!planInformation?.purchased && !userCanEnroll}
                                        loading={false}
                                        btnFunction={ planInformation?.purchased ? () => history.push(`/cursos`) : () => handleCheckout()} 
                                        disabledCta={disabledCta} 
                                        components={summaryCardChildren}
                                        showStudentRegulation={true}
                                        isPurchased={ planInformation?.purchased }
                                        membershipData={ membershipData }
                                        company_id={ planInformation?.company_id }
                                        user_membership_included={ planInformation?.user_membership_included }
                                        closest_membership_included={ planInformation?.closest_membership_included }
                                        hideInfoMembership={false}
                                        isLogged={isLogged} 
                                    />
                                </Hidden>
                            </Grid>

                            {/* ----- FAQ ---- */}
                            <Grid container md={8} xs={12}>
                                {
                                    ( faqList?.length > 0 ) &&
                                    <FAQsComponent
                                        title={"Preguntas frecuentes"}
                                        questionsList={faqList}
                                        innerRef={myRef}
                                    />
                                }
                            </Grid>
                        </Container>

                        {/* -----Carrusel --- */}
                        <Suspense fallback={<div></div>}>
                            <CourseCarousel isDetail={true} lazyLoading id='catalog::educon_courses' title="Te recomendamos" urlMore='/busqueda?educon=true' viewCourseDetails hideShowMoreResponsive={true}/>
                        </Suspense>
                        <Hidden mdUp>
                            <Card className='mx-3 my-3'>
                                <CardContent>
                                    <FAQsCourse title={'¿Necesitas ayuda?'} />
                                </CardContent>
                            </Card>
                        </Hidden>
                        
                        <ModalClassEnrollment {...modalProps} onClose={handleOnCloseModal} onConfirm={()=>{}} onRetry={handleOnRetry} onSupport={handleOnSupport} />
                    </>
                }
            </LoaderContent>

            <Hidden mdUp>
            {
                !isLoading && !!showSubscribeStickyButton &&
                <Box className={classes.containerFixedSubscribeButton}>
                    <SummaryCard
                        cardClass={classes.cardClass}
                        cardSize={4}
                        hideBookmark={false} 
                        cardTitle={ planInformation?.purchased ? "Ya has comprado este programa" : "Cursa este programa por:"}
                        price={ planInformation?.price}
                        oldPrice={  planInformation?.price + 100}
                        caption={"Es posible que otros usuarios puedan ver parte de la información al usar la plataforma"}
                        btnText={ planInformation?.purchased ? "Ir a mis clases" : "Inscribirme ahora"}
                        btnDisabled={!planInformation?.purchased && !userCanEnroll}
                        loading={false}
                        btnFunction={ planInformation?.purchased ? () => history.push(`/cursos`) : () => handleCheckout()} 
                        disabledCta={disabledCta} 
                        // btnFunction={ planInformation?.purchased ? () => history.push(`/cursos`) : () => handleCheckout()}
                        isPurchased={ planInformation?.purchased }
                        membershipData={ membershipData }
                        company_id={ planInformation?.company_id }
                        user_membership_included={ planInformation?.user_membership_included }
                        closest_membership_included={ planInformation?.closest_membership_included }
                        hideInfoMembership={true}
                        isLogged={isLogged}
                        inlinePrice={true}
                        />
                </Box>
            }
            </Hidden>
        </>
    )
}
const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(DetailStudyPlanProgram);