import React, { useState }  from 'react';

// @material-ui/core components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

// core components
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';

// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../redux/actions/userActions';
import * as tourInitialActions from '../../../../redux/actions/tourInitialActions';
//SDK
import { AccountServiceSDK } from '@sdk-point/talisis'
import { Button, CircularProgress, makeStyles, useTheme } from '@material-ui/core';
import DeleteAccountModal from './DeleteAccountModal/DeleteAccountModal';
import { Alert } from '@material-ui/lab';

const FORM_NAME = 'userPreferences';

const UserPreferences = (props) => {

    const theme = useTheme();
    const useStyles = makeStyles({
        grid: {
            borderTop: `1.5px solid ${theme.palette.colors.grey[700]}`,
        },
        circularProgress: {
            color: theme.palette.colors.white,
        },
        saveButton: {
            backgroundColor: theme.palette.colors.orange[300],
            color: theme.palette.colors.white,
        },
        alertMessage: {
            backgroundColor: theme.palette.colors.grey[500],
            color: theme.palette.colors.white,
            fontFamily: "'Source Sans Pro', sans-serif !important",
            fontSize: '14px',
        },
    });
    
    const classes = useStyles();

    const{ user, user: userName, handleChangePreferences, handleSubmit } = props;
    const{ loading, userPreferences } = props.state;
    const { send_weekly_email, send_to_personal_email, personal_email, notification_email, tour_initial } = userPreferences;

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    
    const handleDelete = () => {
      
        AccountServiceSDK.requestDeleteaccount(user)
    }

    return(
        <LoaderContent loading={loading}>
            <Card >
                <CardContent>
                    <Typography variant="subtitle2" className="mb-4">Notificaciones</Typography>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <FormGroup>
                                <FormControlLabel
                                    style={{marginTop: '8px'}}
                                    control={
                                        <Switch
                                            checked={send_weekly_email}
                                            onChange={handleChangePreferences} 
                                            name="send_weekly_email" 
                                            value={send_weekly_email ? send_weekly_email : false}
                                            color="primary"
                                        />}
                                    label='Recibir resumen semanal vía email'
                                />
                            </FormGroup>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <FormGroup>
                                <FormControlLabel
                                    style={{marginTop: '8px'}}
                                    control={
                                        <Switch
                                            checked={send_to_personal_email && send_weekly_email} 
                                            onChange={handleChangePreferences} 
                                            name="send_to_personal_email" 
                                            value={send_to_personal_email ? send_to_personal_email : false}
                                            color="primary"
                                        />}
                                    label='Enviar también a mi correo personal'
                                />
                            </FormGroup>
                        </Grid> */}
                        {/* {true ?  */}
                        {userPreferences.send_to_personal_email ? 
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    label="Correo Personal"
                                    fullWidth
                                    margin="dense"
                                    name="personal_email"
                                    value={personal_email ? personal_email : " "}
                                    onChange={handleChangePreferences}
                                />
                            </Grid> :''
                        }
                    </Grid>
                    <Grid container className='mt-2'>
                        <Grid item xs={12} md={4}>
                            <FormGroup>
                                <FormControlLabel
                                    style={{marginTop: '8px'}}
                                    control={
                                        <Switch
                                            checked={userPreferences.notification_email}
                                            onChange={handleChangePreferences} 
                                            name="notification_email"
                                            value={notification_email ? notification_email : false}
                                            color="primary"
                                        />}
                                    label='Recibir notificaciones vía email'
                                />
                            </FormGroup>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <FormGroup>
                                <FormControlLabel
                                    style={{marginTop: '8px'}}
                                    control={
                                        <Switch
                                            checked={tour_initial} 
                                            onChange={handleChangePreferences} 
                                            name="tour_initial"
                                            value={tour_initial}
                                            color="primary"
                                        />}
                                    label='Mostrar Onboarding'
                                />
                            </FormGroup>
                        </Grid> */}
                    </Grid>
                </CardContent>
                <CardActions>
                    <Typography variant="caption">*Recibirás en tu correo notificaciones de carácter importante.</Typography>
                </CardActions>
                <Grid container >
                    <Grid item xs={12} className={classes.grid}>
                        {
                            userPreferences.toastProps.ok &&
                            <Alert id='cm_alert_message' variant="outlined" severity={userPreferences.toastProps.ok === 'true' ? 'success' : 'error'} className={`m-3 Profile_AlertMessage ${classes.alertMessage}`}>
                                {userPreferences.toastProps.message}.
                            </Alert>
                        }
                        <Typography variant="subtitle2" className="m-3 Profile_buttonsContainer">
                            <Button
                                color="primary"
                                variant="outlined"
                                className={`Profile_Button ${classes.saveButton}`}
                                disabled={userPreferences.loading}
                                onClick={() => handleSubmit(FORM_NAME)}>
                                {userPreferences.loading && <CircularProgress size={20} className={classes.circularProgress} />}
                                {!userPreferences.loading && 'Guardar'}
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
                        
            <Card style={{marginTop: '24px'}}>
                <CardContent>
                    <Typography variant="subtitle2" className="mb-4">Eliminar Cuenta</Typography>
                    <Button variant='text' onClick={handleOpenModal}>Eliminar cuenta</Button>
                </CardContent>
            </Card>
            <DeleteAccountModal 
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                handleDelete={handleDelete}
                stateUser={userName}
            />
        </LoaderContent>
    );
}



const mapStateToProps = ({userReducer,tourInitialReducer}) => ({
	user: userReducer.user,
    tourPreferences: tourInitialReducer.tourPreferences
});

const mapDispatchToProps = {...userActions, ...tourInitialActions};

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferences);
