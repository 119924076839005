import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../../redux/actions/userActions';

// @material-ui/core
import { useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Box, Grid, Typography, FormControlLabel, Checkbox, Card, CardContent, useMediaQuery, Icon, Switch, TextField, InputAdornment } from '@material-ui/core';
import { cardPaymentStyles } from './cardPaymentStyles';

import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';

// services
import { MarketPlaceService } from '@sdk-point/talisis';

// shared
import { useConekta } from '../../../../../shared/hooks';

// constants
import { CONEKTA_PUBLIC_KEYS } from '../../../../../shared/constants/conekta';

//SDK
import { MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';
import { UserService } from '@sdk-point/talisis';

import Cookies from 'js-cookie';
import { DEBUG_CARD } from './debugCard';
import { getSessionCookie } from '../../../../../shared/utils/Sessions';
import { getCheckoutCookie } from '../../../../../shared/utils/checkoutSession-legacy.js';
import Toast from '../../../../../shared/components/Toast/Toast';

const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const CardNew = (props) => {
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [platformErrorMessage, setPlatformErrorMessage] = useState("");
    const [collapsedError,setCollapsedError] = useState(false);
    const history = useHistory();
    const sessionCookie = getSessionCookie();
    const checkoutCookie = getCheckoutCookie();
    
    const classes = cardPaymentStyles();
    const theme = useTheme();
    const conekta = useConekta();
    const [loading,setLoading] = useState(false);
    const [sameUserPayment,setSameUserPayment] = useState(false);
    const [form, setForm] = useState({
        'number': '',
        'name': '',
        'exp_year': '',
        'exp_month': '',
        'cvc': '',
        'phone': '',
        'email': ''
    });
    
    const [acceptSaveCard, setAcceptSaveCard] = useState(true)
    const [userSaveCard, setUserSaveCard] = useState(true);

    useEffect(() => {
        getCardForm(form)
        getAcceptedSaveCard(acceptSaveCard)
    }, [form, acceptSaveCard])
    

    const { email_address, phone_number, first_name, last_name, person_id } = checkoutCookie?.personInfo;

    const [openToast, setOpenToast] = useState(false);

    // TODO: Limpiar y separar helpers
    //! Inician helpers de guardar tarjeta

    const conektaAccounts = ['UNID', 'UERRE'];

    const setToastProps = (open, message) => {
        setOpenToast(open);
        setErrorMessage(message);
    };

    const handleFinishedToast = () => {
        setToastProps(false, '');
    };

    const cardExists = async () => {
        let exists = false;
        const cards = await UserServiceSDK.conektaGetCustomerCard(person_id);
        if (cards) {
            const cardNumber = form.number;
            cards.map(card => {
                if (card[0].last4 === cardNumber.slice(-4)) {
                    exists = true;
                }
            });
        }

        return exists;
    };

    const successToken = async (token) => {
        return token.id;
    };

    const errorToken = async (e) => {
        setToastProps(true, e.message_to_purchaser);
        return false;
    };

    const getTokens = async () => {
        let tokens = {};
        for (const account of conektaAccounts) {
            conekta.setPublicKey(CONEKTA_PUBLIC_KEYS[account]);
            conekta.createToken({
                card: {
                    number: form.number,
                    name: form.name,
                    exp_month: form.exp_month,
                    exp_year: form.exp_year,
                    cvc: form.cvc
                }
            },
            async (token) => {
                const token_id = await successToken(token);
                tokens[account] = token_id;
            },
            errorToken);
        }
        
        return tokens;
    };

    const addCustomerCards = async (tokens) => {
        const newConektaCards = await UserServiceSDK.conektaAddCustomerCard(person_id, tokens);
        return newConektaCards
    };

    const handleSaveCard = async() => {
        if(validate()){
            const exists = await cardExists();
            if (exists) {
                setToastProps(true, 'Ya existe el método de pago. Prueba con uno diferente.');
                return false;
            }
            if(!exists){
                const tokens = await getTokens();
                const newConektaCards = await addCustomerCards(tokens);
                if( !newConektaCards.includes(undefined) ){
                    return true;                    
                }
                await addCustomerCards(tokens)
                return true
            }
        }
    }
    //! Terminan helpers de guardar tarjeta

    // const { order, user, loggedUser, updateMembership, getCardForm, loadingConekta, nextPaymentDate, getAcceptedSaveCard } = props;
    const { order, user, loggedUser, updateMembership, getCardForm, nextPaymentDate, getAcceptedSaveCard } = props;
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "v1" && process.env.REACT_APP_ENV !== "v2" && process.env.REACT_APP_ENV !== "v3") {
            setForm(DEBUG_CARD);
        }
        if(checkoutCookie.membershipSession){
            setUserSaveCard(false)
        }
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (value) {
            const _errors = { ...errors };
            delete _errors[name];

            setErrors(_errors);
        };

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['number', 'name', 'exp_year', 'exp_month', 'cvc'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Este dato es necesario';
            } else {
                if (field === "number") {
                    if (form[field].length < 15) {
                        _errors[field] = 'El número mínimo de caracteres es de 15';
                    }
                } else if (field === "exp_month") {
                    if (form[field].length < 2) {
                        _errors[field] = 'El número mínimo de caracteres es de 2';
                    } else if (form[field] > 12) {
                        _errors[field] = 'Debe ser mayor o igual a 12';
                    }
                } else if (field === "exp_year") {
                    const currentYear = new Date().getFullYear().toString();
                    if (form[field] < currentYear.slice(2, 4)) {
                        _errors[field] = `Debe ser mayor o igual a ${currentYear.slice(2, 4)}`;
                    }
                } else if (field === "cvc") {
                    if (form[field].length < 3) {
                        _errors[field] = 'El número mínimo de caracteres es de 3';
                    }
                }
            }
        }

        setErrors({ ..._errors });

        return Boolean(Object.keys(_errors).length === 0);
    };

    const changeCardName=()=>{
        setSameUserPayment(!sameUserPayment);
        let name = first_name + " " + last_name;
        let email = email_address; 

        if (name) {
            const _errors = { ...errors };
            delete _errors["name"];
            setErrors(_errors);
        };

        if (email) {
            const _errors = { ...errors };
            delete _errors["email"];
            setErrors(_errors);
        };

        setForm(prev => { return { ...prev, name, email } });
    }

    return (
        <>
        {
            !loading &&
            <Box>
                <Grid container direction="row">
                    <Grid item xs={12} style={{ paddingTop: '0px', marginBottom: '30px' }}>
                    {
                        platformErrorMessage==""
                        ?
                        <></>
                        :
                        <Box style={{ paddingTop: '10px' }}>
                            <Alert variant="outlined" severity="warning">
                                <Typography color="secondary">Hola, hemos detectado un error al momento de realizar el pago</Typography>
                                <Typography color='secondary'>Por favor, escríbenos a  <Link href={`mailto:support@soportetalisis.zendesk.com?subject=Problema al generar pago`} target="_blank">support@soportetalisis.zendesk.com</Link> y te ayudaremos lo más pronto posible.</Typography>
                                <Typography color='secondary'>En el correo coloca tu nombre y correo.</Typography>
                                <Typography color='secondary'>Gracias</Typography>
                                <Box className='mt-3'>
                                    <Link href="#" variant="body1" onClick={(e)=>{e.preventDefault();setCollapsedError(!collapsedError)}}>{collapsedError ? "Ocultar detalles" : "Mostrar detalles"}</Link>
                                    <Collapse in={collapsedError}>
                                        <Typography color='secondary' className="pt-2">{platformErrorMessage}</Typography>
                                    </Collapse>
                                </Box>
                            </Alert>
                        </Box>
                    }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} className="mb-1">
                                <TextField 
                                    name="number"
                                    variant="outlined"
                                    label="Número de la tarjeta"
                                    value={form.number || ""}
                                    error={Boolean(errors.number)}
                                    helperText={errors.number}
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={validate}
                                    placeholder="Ingresa un número de tarjeta"
                                    inputProps={{ maxLength: 16 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Icon className='ri-bank-card-fill' fontSize='inherit' />
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            {
                                userSaveCard &&
                                <Grid item xs={12} className="mb-1">
                                    <FormControlLabel checked={acceptSaveCard} control={<Switch color="primary" onChange={()=> setAcceptSaveCard(!acceptSaveCard)} />} 
                                        label={
                                            <Typography variant='caption' className="semi-bold">
                                                Guardar método de pago
                                            </Typography>
                                        } 
                                    />
                                </Grid>
                            }
                            {
                                (!userSaveCard && checkoutCookie.membershipSession) &&
                                <Grid item xs={12} className='mb-2'> 
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Icon className="ri-error-warning-line" style={{ color: theme.palette.colors.orange[300], fontSize: 'inherit' }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant='caption' className="semi-bold">
                                                Se guardará automáticamente tu método de pago para futuras compras.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12} md={6} lg={4} className="my-2">
                                <TextField 
                                    name="exp_month"
                                    label="Mes de vencimiento"
                                    variant="outlined"
                                    value={form.exp_month || ""}
                                    error={Boolean(errors.exp_month)}
                                    helperText={errors.exp_month}
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={validate}
                                    placeholder="MM"
                                    inputProps={{ maxLength: 2 }}
                                    InputProps={{ startAdornment: <></> }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} className="my-2">
                                <TextField 
                                    name="exp_year"
                                    label="Año de vencimiento"
                                    variant="outlined"
                                    value={form.exp_year || ""}
                                    error={Boolean(errors.exp_year)}
                                    helperText={errors.exp_year}
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={validate}
                                    placeholder="AA"
                                    inputProps={{ maxLength: 2 }}
                                    InputProps={{ startAdornment: <></> }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} className="my-2">
                                <TextField 
                                    name="cvc"
                                    label="Código de seguridad"
                                    variant="outlined"
                                    value={form.cvc || ""}
                                    type="password"
                                    error={Boolean(errors.cvc)}
                                    helperText={errors.cvc}
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={validate}
                                    placeholder="CVV"
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Icon className='ri-lock-2-line' fontSize='inherit' />
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="mb-3">
                                <FormControlLabel control={<Checkbox color="primary" size='small' onChange={changeCardName} />} 
                                    label={
                                        <Typography variant='caption' className="semi-bold">
                                            El titular de la tarjeta es el mismo que el estudiante / alumno.
                                        </Typography>
                                    } 
                                />
                            </Grid>
                            <Grid item xs={12} className="mb-2">
                                <TextField 
                                    name="name"
                                    label="Titular de la tarjeta"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    value={form.name || ""}
                                    onChange={handleChange}
                                    onBlur={validate}
                                    inputProps={{ readOnly: sameUserPayment }}
                                    placeholder="Ingresa tu nombre de titular"
                                    InputProps={{ startAdornment: <></> }}
                                />
                            </Grid>
                            {
                                (!userSaveCard && checkoutCookie.membershipSession) &&
                                <Grid item xs={12}>
                                    <Card variant='outlined' className='mb-3'>
                                        <CardContent>
                                            <Typography variant='h6' component="span">Pago domiciliado: </Typography>
                                            <Typography className='variant-xsmall semi-bold' component="span">
                                                {`El próximo cobro se realizará automáticamente a tu método de pago el día ${nextPaymentDate}`}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} className="my-4">
                        <Grid container justifyContent='flex-start'>
                            <Button onClick={handlePayment} color='primary' variant='contained' disabled={acceptPayment}>
                                Pagar ahora
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Box>
        }
        <Toast severity="error" open={openToast} message={errorMessage} onFinished={handleFinishedToast} duration={3000} />
        </>
    );
};

const mapStateToProps = ({ userReducer }) => ({
    loggedUser: userReducer.user
});

const mapDispatchToProps = { ...userActions };
export default connect(mapStateToProps, mapDispatchToProps)(CardNew);
