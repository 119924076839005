import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    CardRoot: { 
        width: "480px"
    },
    BoxTittles: { 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '32px',
        padding: 0
    },
    BoxTittle: { 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '16px',
        padding: 0,
        gap: '16px'
    },
    BoxPreguntas: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '441px',
        marginBottom: '25px'
    },
    labelRadio: {
        margin: '4px',
        borderRadius: '8px',
        width: '69px',
        height: '32px'
    },
    profilePic: {
        width: '64px',
        height: '64px'
    },
    boxSig: {
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flex: 'none',
        overflow: 'hidden'
        
    },
}));