import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { AppBar, IconButton, useMediaQuery } from '@material-ui/core';
import Carousel from "react-material-ui-carousel";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const ModalSlider = ({children, size = 0, textLastButton = '', onClickLastButton, ...rest}) => {
    const [index, setIndex] = React.useState(0);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    
    const handleChange = (cur, prev) => {
        setIndex(cur);
    };
  
    return (
        <Dialog maxWidth={'md'} fullWidth PaperProps={{style:{maxWidth: 641}}}
            id="modal-slider-root"
            {...rest}
        >
            <Box component={isResponsive ? AppBar : null} style={{background: 'inherit'}}>
                <Carousel
                    index={index}
                    onChange={handleChange}
                    animation="slide"
                    indicators={false}
                    className="my-carousel"
                    autoPlay={false}
                    navButtonsAlwaysInvisible={true}
                >
                    {children}
                </Carousel>
                <Box display='flex' flexDirection='column' alignItems='center' pb={3} gridGap={isResponsive ? 16 : 28}>
                    <Box display='flex' gridGap={24}>
                        {
                            index > 0 &&
                            <Button onClick={() => setIndex(index - 1)} variant='outlined' color='secondary'>Regresar</Button>
                        }
                        {
                            index < (size - 1)
                            ?<Button onClick={() => setIndex(index + 1)} variant='contained' color='primary'>
                                { index === 0 ? 'Empezar' : 'Siguiente'}
                            </Button>
                            :<Button onClick={onClickLastButton} variant='contained' color='primary'>
                                {textLastButton}
                            </Button>
                        }
                    </Box>
                    <Box>
                        {[...Array(size).keys()].map((item, i) => (
                            <IconButton onClick={() => setIndex(i)} style={{padding: 4}} key={`modal-slider-dot-${i}`}>
                                <FiberManualRecordIcon style={{color: i === index ? '#9F6FFB' : '#404150', fontSize: 12}}/>
                            </IconButton>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export default ModalSlider;