import React from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui
import { makeStyles, Box, Button, CircularProgress, Typography } from '@material-ui/core';

// redux
import { connect } from 'react-redux';
import * as userActions from '../../../redux/actions/userActions';
import * as profilePicActions from '../../../redux/actions/profilePicActions';

//SDK
import { SimulationService } from '@sdk-point/talisis'; 

// shared
import { setSessionCookie } from "../../../shared/utils/Sessions";
import { log } from '../../../shared/utils/console.js'

// styles
import styled from 'styled-components';

const SimulationServiceSDK = new SimulationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
var w = window;

const SimulationBarStyled = styled(Box)`
    ${({ theme }) => `
        height: 50px;
        display: flex;
        position: fixed;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(57, 0, 217, 0.24);
        -webkit-transition: all 150ms ease 0s;
        transition: all 150ms ease 0s;
        box-shadow: none;
        padding: 0px 15px;
        z-index: 1000;
        top: 65px;
        width: 100%;
        & > * + * {
            color: ${theme.palette.common.white};
        }

        .abort-button {
            border-color: ${theme.palette.common.white};
            text-transform: none;
            padding:4px 18px;
            width: 15%;
  		    height: 65%;
        }

        .fake-user {
            color: ${theme.palette.common.white};
        }
    `}
`;

const useStyles = makeStyles((theme) => ({
    loading: {
        bottom: 0,
        color: theme.palette.common.white,
        height: '1.5vw !important',
        left: 0,
        margin: 'auto',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '1.5vw !important',
    }
}));

const SimulationBar = props => {
    const history = useHistory();
    const classes = useStyles();
    const { user } = props;
    const [loading, setLoading] = React.useState(false);
    //
    const handleClose = async () => {
        try {
            setLoading(true);
  		    document.getElementById('close_simulation_button').disabled = true;

            // delete bd session
            await SimulationServiceSDK.closeSession(user.fake_session);

            // restaurar session de admin
            const session = JSON.parse(localStorage.getItem('admin_session'));

            props.applyProfilePic(session.profilePic);
            props.applyUser(session.user);
            setSessionCookie(session.user);

            localStorage.removeItem('admin_session');

            setLoading(false);
            history.push("/home");
            window.location.reload();
        } catch (e) {
            log(e.message);
        }
    };

    return <React.Fragment>
        {user.is_fake &&
            <SimulationBarStyled className="no-print">
                <Box display="flex" alignItems="flex-start">
                    <Box p={1}>
                        <Typography variant="body2">Viendo como:</Typography>
                    </Box>
                    <Box p={1}>
                        <i className="ri-eye-fill"></i>
                    </Box>
                    <Box p={1}>
                        <Typography variant='body2' className='semi-bold'>{`${user.userName} - ${user.person_id}`}</Typography>
                    </Box>
                </Box>
                <Button id="close_simulation_button" color="primary" variant="outlined" className="abort-button" size="small" onClick={handleClose}>
                    <Typography variant='caption'>{loading ? <CircularProgress className={classes.loading} onClick={(e) => { e.stopPropagation(); } } /> : 'Terminar simulación'}</Typography>
                </Button>
            </SimulationBarStyled>
        }
    </React.Fragment>;
};

const mapStateToProps = ({ userReducer, profilePicReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic
});

const mapDispatchToProps = { ...userActions, ...profilePicActions };

export default connect(mapStateToProps, mapDispatchToProps)(SimulationBar);