import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// MUI
import Fab from '@material-ui/core/Fab';
import {
  Avatar,
  Badge,
  Box,
  Button, Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import clsx from 'clsx';

//components
import SelectCategory from './SelectCategory';
import SelectTopic from './SelectTopic';
import WriteTicket from './WriteTicket';
import TicketConfirmation from './TicketConfirmation';
import GuestForm from './GuestForm';
import InfoTopic from './InfoTopic';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    backgroundColor: theme.palette.colors.fillStroke[500],
    border: `solid 1px ${theme.palette.colors.fillStroke[400]}`,
  },
  header: {
    width: '100%',
    background: `linear-gradient(113.16deg, ${theme.palette.colors.electricBlue[300]} 4.18%, ${theme.palette.colors.electricBlue[500]} 82.04%)`,
    padding: '32px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    [theme.breakpoints.down('xs')]:{
      padding: '16px 24px',
    }
  },
  content: {
    marginTop: 16,
    padding: '0 16px 0 16px'
  },
  blue: {
    color: theme.palette.white,
    backgroundColor: theme.palette.colors.electricBlue[300]
  },
  stepTitle:{
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& .MuiAvatar-root': {
      height: 36,
      width: 36
    },
    '& .category-title':{
      ...theme.typography.bodyTextLargeSemiBold,
      [theme.breakpoints.down('xs')]:{
        ...theme.typography.bodyTextSmallRegular,
      }
    }
  },
  categoriesContainer: {
    padding: '0 16px 0 16px',
  },
  categoryCard: {
    backgroundColor: theme.palette.colors.fillStroke[400],
    width: '100%',
    textAlign: 'center',
    padding: '16px 8px 16px 8px'
  },
  categoryIcon: {
    fontSize: 36,
    textAlign: 'center'
  },
  categoryTitle: {
    fontSize: 16,
    fontWeight: 500
  },
  categoryDescription: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px'
  },
  stepContainer: {
    marginTop: 16,
    padding: '0 16px',
  },
  stepBars: {
    height: theme.spacing(1),
    backgroundColor: theme.palette.colors.fillStroke[300],
    borderRadius: '6px',
    margin: '0 2px'
  },
  stepBarsActive: {
    backgroundColor: theme.palette.colors.violet[300],
  },
  gridPaper:{
    maxWidth: '20%'
  }
}));

const ContactAgent = (props) => {
  const {
    zendeskUser, 
    handleViewAgent,
    handleNextStep,
    handleBackStep,
    currentStep,
    isLogged,
    disableSend,
    selectedTopic = {},
    handleCloseDrawer
  } = props
  const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const classes = useStyles();

  
  
  const steps = [
    {
      id: 1,
      title: isLogged ? '¿A qué categoría corresponde tu problema?' : '¿Qué problema estás experimentando?',
      component: isLogged ?  <SelectCategory {... props} /> : <SelectTopic {... props} />,
      
    },
    {
      id: 2,
      title: isLogged ? '¿En qué apartado estás teniendo tu problema?' : 'Ingresa los siguientes datos para poder ayudarte',
      component: isLogged ? <SelectTopic {... props} /> : <GuestForm {... props}/>,
    },
    {
      id: 3,
      title: isLogged ? 'Ayuda' : 'Ayuda',
      component: isLogged ? <InfoTopic {... props}/> : <InfoTopic {... props}/> ,
    },
    {
      id: 4,
      title: 'Detalla tu problema',
      component: <WriteTicket {... props} />,
    },
    {
      id: 5,
      title: 'Ticket enviado',
      component: <TicketConfirmation {... props} />,
    }
  ]

  return (
    <>
      <CardContent className={clsx(classes.root, {'card-content-responsive': isLogged && isResponsive})}> 
        <Box className={classes.header}>
          <Icon className='ri-customer-service-2-line font-size-32' />
          <Typography className='subheading-2'>Contactar con un agente</Typography>
        </Box>
        {
          currentStep !== 3 &&
          <Box className={classes.content} mb={2}>
            <Box className={classes.stepTitle}>
              <Avatar className={classes.blue}>{steps[currentStep].id}</Avatar> <Typography className='category-title'>{steps[currentStep].title}</Typography>
            </Box>
          </Box>
        }
        {
          steps[currentStep].component
        }
        <Grid container spacing={1} className={classes.stepContainer}>
          <Grid item xs={12}>
            <Grid container >
              {
                steps.map(step => (
                  <Grid item xs={3} key={step.id} className={classes.gridPaper}>
                    <Paper className={`${classes.stepBars} ${step.id <= currentStep +1 ? classes.stepBarsActive :''}`}></Paper>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Grid item xs={(currentStep === 2 && (!selectedTopic || !selectedTopic.value.includes("whatsapp"))) ? 2 : 6} >
            <Button disabled={currentStep===4} fullWidth size='large' variant='contained'  
            onClick={ currentStep > 0 ? handleBackStep : handleViewAgent}>Atrás</Button>                       
          </Grid>
          {
            (currentStep === 2 && (!selectedTopic || !selectedTopic.value.includes("whatsapp"))) &&
            <Grid item xs={4} > 
              <Button
                      fullWidth size='large' variant='contained' 
                      onClick={ handleViewAgent }>
                        Si, Cerrar
              </Button>
            </Grid>
          }
          {
            (currentStep === 2 && selectedTopic && selectedTopic.value.includes("whatsapp")) ?
            <Grid item xs={6} > 
              <Button
                      fullWidth size='large' variant='contained' color='primary' 
                      onClick={ handleViewAgent }>
                        Salir
              </Button>
            </Grid>
            :
            <Grid item xs={6} > 
              <Button disabled={disableSend && ((currentStep === 1 && !isLogged) || (currentStep <= 3 && currentStep !== 2))}
                      fullWidth size='large' variant='contained' color='primary' 
                      onClick={ currentStep === 4 ? handleViewAgent : handleNextStep}>
                      {currentStep === 2 ? 'No, levantar ticket' : currentStep === 3 ? 'Enviar' : currentStep === 4 ? 'Finalizar' : 'Siguiente'}
              </Button>
            </Grid>
          }
          
        </Grid>
      </CardContent>
    </>
  )
};

ContactAgent.defaultProps = {
  isHome: false,
  isCheckout: false,
};

ContactAgent.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};


export default ContactAgent;
