import React from 'react'

import { Grid} from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { Button, makeStyles, Typography } from '@material-ui/core';
// routes
import * as routerlinks from '../../../../../routes/routelinks';

const useStyles = makeStyles((theme) => ({
    descriptionBold:{
        ...theme.typography.bodyTextLargeSemiBold,
        display: 'inline-flex'
    }
}));

const CustomMembershipMessage = (props) => {
    const history = useHistory();
    const {customMembershipData}=props;
    const goToHome=()=>{
        history.push(routerlinks.HOME_PATH)
    };    
    const classes = useStyles();
    
    return (
        <Grid container>
            <Grid item className='mb-4'>
                <Typography variant='h3' color='primary'>
                    ¡Lo sentimos!
                </Typography>
            </Grid>
            <Grid item className='mb-4'>
                <Typography>
                    Debido a que tu cuenta es parte de un beneficio especial que te otorga <Typography className={classes.descriptionBold}>{customMembershipData.company}</Typography>, no puedes adquirir contenidos de otras membresías.
                </Typography>
            </Grid>
            <Grid item className='mb-4'>
                <Typography>
                    Para adquirir otras membresías, hazlo desde una cuenta personal o contacta a un asesor comercial.
                </Typography>
            </Grid>
            <Grid item>
                <Button color="primary" variant="contained" onClick={goToHome}>Volver a inicio</Button>
            </Grid>
        </Grid>
    )
}

export default CustomMembershipMessage
