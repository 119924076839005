import React from 'react';
import { useHistory } from 'react-router-dom';

//SDK
import { AccessService } from '@sdk-point/talisis';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// shared
import Loader from '../../../../shared/components/Loader/LoaderContent';

import RegisterButton from './RegisterButton/RegisterButton';
import RegisterTable from './RegisterTable/RegisterTable';
import PersonQR from '../../PersonQR/PersonQR';
import CommitmentLetter from '../../CommitmentLetter/CommitmentLetter';

const AccessServiceSDK = new AccessService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const RegisterHome = (props) => {
    const { user } = props;

    const history = useHistory();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showLetter, setShowLetter] = React.useState(false);

    React.useEffect(() => {
        const getRegisters = async () => {
            setLoading(true);
            const data = await AccessServiceSDK.getPersonRegisters(user);
            setData(data);
            setLoading(false);
        };
        getRegisters();
    }, []);

    const handleLetter = () => {
        setShowLetter(!showLetter);
    };

    return <Card>
        <CardContent>
            {!showLetter &&
                <React.Fragment>
                    <Box display="flex" flexDirection="column" alignItems="center" className="p-2">
                        <Typography variant="h3" className="mb-3">Acceso a Campus</Typography>
                    </Box>
                    <Grid container spacing={2} className="mt-2" justifyContent='center'>
                        <Grid item xs={12} sm={12} md={2}>
                            <PersonQR alt="qr-person" width="100%" />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            {
                showLetter &&
                <React.Fragment>
                    <CommitmentLetter isOpen={handleLetter} />
                </React.Fragment>
            }
        </CardContent>
    </Card>
}

export default RegisterHome;