import React from 'react';
// styles
import useStyles from '../../../assets/styles/jss/pages/exploreHome/whatsappStyle'

//material
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

//assets/images
import { Hidden, useMediaQuery, Grid, Icon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';

const Whatsapp = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const breakpoint = useMediaQuery(theme.breakpoints.down("xs"));
    const numberWhatsapp = '528118212007'
    const handleGoToWhatsapp = () => {
        let textoCodificado = encodeURIComponent('¡Hola!, Me pudieran apoyar con una duda sobre Talisis.com.')
        window.open(`https://wa.me/${numberWhatsapp}?text=${textoCodificado}`)
      }
    return (
        <Box className={classes.root}>
            <Box className={classes.bgImage}/>
            <ResponsiveContainer className={classes.responsiveContainer}>
                <Grid container direction='row' style={{height:'100%'}}>
                    <Grid item xs={0} sm>

                    </Grid>
                    <Grid item xs sm alignContent={breakpoint?'center':'left'}>
                        <Grid container direction="column" justifyContent='center' style={{height:'100%'}}>
                            <Grid item>
                                <Box className="title">
                                    <Typography variant={breakpoint?"h2":"h1"} className='text-gray-100'>¿Tienes dudas sobre nuestros programas o membresías?</Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box display='flex' gridGap={4} flexDirection='column' className='content'>
                                    <Typography variant='subtitle1' >Escríbenos por Whatsapp para ayudarte con toda la información que necesitas.</Typography>
                                </Box>  
                            </Grid>
                            <Grid item>
                                <Box className={classes.actions}>
                                    <Button variant='contained' className={classes.butttonWhats} onClick={handleGoToWhatsapp} startIcon={<Icon className={'ri-whatsapp-line'} />}>Contactar por WhatsApp</Button>
                                </Box>  
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ResponsiveContainer>
        </Box>
    );
};

export default Whatsapp;