import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom';
// Shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import Toast from '../../../shared/components/Toast/Toast';
import TextFieldDefault from '../../../shared/components/Form/TextFielDefault';
import Select from '../../../design-system/components/Form/Select/Select';
import { log } from '../../../shared/utils/console.js'
// Service
import * as PermissionService from '../../../services/PermissionService';

// @material-ui
import  {Card, Grid, CardContent, Typography, Button, Box} from '@material-ui/core';

import RolePermisionObj from './RolePermissionObj';

//SDK
import { UserPermXService } from '@sdk-point/talisis'; 

const UserPermXServiceSDK = new UserPermXService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const PermissionRoleForm = (props) => {
  const {match} = props;
  const roleId = match.params.id
  const items = [
    { to: '/Home', label: 'Home',title:''},
    { to: '/roles/management', label: 'Roles',title:''},
    { to: `/roles/${roleId}/editar`, label: `Role_${roleId}`,title:''},
    {label: 'Permisos', title:''}
  ];
  const [state, setState] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toast, setToast] = useState({ toastProps: {message: '',open: false}})
  const history = useHistory();

  useEffect(()=>{
    getAllRequest();
  },[])

  const getAllRequest = async () => {
    try {
        setIsLoaded(true);
        const responsePermission = await PermissionService.getAll(1,[],1000);
        const permissions = responsePermission.data.map(item=> {return {id:item.id,label:item.description}})
        const rolePermission = RolePermisionObj();
        rolePermission[4].defaultValue = roleId;
        rolePermission[5].items = permissions;
        setState(rolePermission)
    } catch (error) {
      console.error('Error all request', error)
    } finally {
      setIsLoaded(false);
    }
  }
  const handleChange = async (e) => {
    const {name, value} = e.target; 
    const indexObj = state.findIndex(obj=> obj.name===name);
    state[indexObj].defaultValue = value;
    state[indexObj].error = false;
    state[indexObj].helperText = '';
    const SCOPE_DEFAULT = 'SCOPE'
    if(name==='permission_scope_id' || name==='company_id') {
      const VALUE_SCOPE_COMPANY = 1;
      const VALUE_SCOPE_CAMPUS = 2;
      const scope = state[6].items.find(item=> item.id===state[6].defaultValue);
      const valueDef = ( name==='company_id')? value: state[7].defaultValue; 
      state[1].defaultValue=`${scope.label} ${valueDef}`;
      if(scope.id===VALUE_SCOPE_COMPANY) {
        state[2].defaultValue= `${SCOPE_DEFAULT} all ${valueDef}`;
        state[3].defaultValue=value;
        state[8].disabled=true;
      } else {
        try {
          setIsLoaded(true);
          state[8].disabled=false;
          const responseCatalog = await UserPermXServiceSDK.getUserPermByRecurso(`ccat/${value}`);
          const arrayCataloo = responseCatalog.map(item =>{ return {'id':item.id, 'label':item.name}})
          state[2].defaultValue= `${SCOPE_DEFAULT} ${scope.label} all`;
          state[3].defaultValue='all';
          log('responseCatalog', responseCatalog);
          state[8].items=arrayCataloo;
          setIsLoaded(false);
        } catch (error) {
          log('Error request Campust', error)
        }
      }
    }
    if(name==='catalogo') {
      state[1].defaultValue=`campus ${value}`;
      state[2].defaultValue= `${SCOPE_DEFAULT} campus ${value}`;
      state[3].defaultValue= value; 
    }
  }

  const ListInput = () => {
    const Item = state.map((item,index)=> {
            item.onChange = handleChange;
            if (item.type==='select') {
              return ( 
                    <Grid item xs={12} md={6} sm={12} key={index}>
                       <Select {...item}/> 
                   </Grid> 
             )
            }
            return (
                <Grid item xs={12} sm={12} md={6} key={index}>
                   <TextFieldDefault {...item}/>
                </Grid>
           )
        
    })
    return  (Item);
  }
  const handleSubmit = async () => {
    let bodyObject = {};
      setIsLoaded(true);
      for (let i=0; i<state.length; i++) {
          const name = state[i].name;
          const value = state[i].defaultValue;
          bodyObject[name]= value;
      }
    try {
        const response= await UserPermXServiceSDK.postUserPerm(bodyObject,'permission-scope-rel') 
        //setToast({toastProps:{open:true, message:'Se Creo satisfactoriamente'}})
        log('Response', response, bodyObject);
        history.push(`/roles/${roleId}/editar`)
    } catch (error) {
        setToast({toastProps:{severity: "error", open: true, message: "Ops! Ha ocurrido un error" }})
       log('Error', error);
    } finally {
      setIsLoaded(false)
    }
  }
  const handleFinishedToast = () => {  
    setToast({toastProps:{open:false, message:''}})
  }
  return(
        <>
          <Breadcrumb items={items} />
            <Grid container spacing={3}>
                <Grid container item xs={12}>
                    <Loader loading={isLoaded}>
                    <Card>
                        <CardContent>
                            <Typography variant="subtitle2" className="mb-4">Permisos por Role</Typography>
                             <ListInput/>
                        </CardContent>    
                    </Card>
                    </Loader>
                </Grid>
                <Grid container item xs={12}>
                  <Box display="flex" justifyContent="flex-end" width="100%" >
                    <Button color="primary" variant="contained" onClick={handleSubmit}>Guardar</Button>
                  </Box>
                </Grid>
            </Grid>
          <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={3000} />
        </>
    )
}
  
export default PermissionRoleForm;