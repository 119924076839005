import React from 'react';

// @material-ui/core
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// components
import SupportCard from './components/SupportCard';
import EnrollmentActionCard from './components/EnrollmentActionCard';

const EnrollmentConfirmationBody = ({ product }) => {
    return <Box mt="64px">
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <Box>
                    <SupportCard />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <EnrollmentActionCard product={product} />
                </Box>
            </Grid>
        </Grid>
    </Box>
};

export default EnrollmentConfirmationBody;