import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Card from '@material-ui/core/Card';

import CustomMembershipCardContent from './CustomMembershipCardContent/CustomMembershipCardContent';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    default:{
        height: '100%'
    },
    border:{
        height: '100%',
        border: `1px solid ${theme.palette.colors.fillStroke[300]}`,
    }
}));

const MembershipCard = (props) => {
    const classes = useStyles();
    const { elevation, ...rest } = props;
    return (
        <Card elevation={elevation} className={elevation===0?classes.border:classes.default}>
            <CustomMembershipCardContent {...rest} />
        </Card>
    );
};

MembershipCard.defaultProps = {
    elevation: 0,
};

MembershipCard.propTypes = {
    id: PropTypes.oneOf([
        "freemium",
        "lite",
        "lite-plus",
        "professional",
        "premium",
    ]),
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    plan: PropTypes.shape({
        amount: PropTypes.number,
        termMonths: PropTypes.number,
    }).isRequired,
    elevation: PropTypes.number,
};

export default MembershipCard;