import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    cardRoot:{
        padding: 16,
        [theme.breakpoints.down('sm')]: {
            marginTop: 16
        }
    },
    boxTitle:{
        borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[100]}`,
        paddingBottom: 16,
        marginBottom: 16,
    },
    boxAlert:{
        display: 'flex',
        gap: 8,
        alignItems: 'center'
    }
}));
