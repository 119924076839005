import React from 'react';

import {LOGOS} from '../../../../constants/source-images';
import {SOURCE_NAME} from '../../../../constants/sources';
import useStyles from '../../../../../assets/styles/jss/components/cards/LogoSourceCardStyle';

const LogoSource = ({sourceId, color = "default", className}) => {
    const classes = useStyles();
    const logo = LOGOS[color][sourceId];
    const sourceName = SOURCE_NAME[sourceId] || 'default';
    
    if(!logo){
        return null;
    }
    
    return (
        <img alt={`logo${'-'+SOURCE_NAME[sourceId]}`} src={LOGOS[color][sourceId]} className={ className ?? classes[sourceName]} />
    );
};

export default LogoSource;
