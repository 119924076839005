import styled from 'styled-components';

export default styled.div`
    ${({ theme: {palette} }) => `
        position: relative;
        height: 100%;
        overflow: auto;
        width: 100%;
        z-index: 4;

        .list{
            position: unset;
            list-style: none;
            margin-top: 20px;
            padding-top: 0;
            padding-left: 0;
            margin-bottom: 0;
            padding-bottom: 0;

            &-link{
                display: block;
                position: relative;
                text-decoration: none;

                &:hover,&:focus,&:visited {
                    color: ${palette.white}
                }

                .item{
                    width: auto;
                    margin: 0 20px;
                    display: block;
                    padding: 8px 15px;
                    position: relative;
                    transition: all 300ms linear;
                    line-height: 1.5em;
                    border-radius: 3px;
                    background-color: transparent;

                    &.child{
                        padding-left: 54px;
                    }

                    &-icon{
                        color: ${palette.icon.secondary};
                        float: left;
                        width: 24px;
                        height: 30px;
                        font-size: 24px;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 15px;
                        vertical-align: middle;
                    }

                    &-text{
                        color: ${palette.white};
                        margin: 0;
                        font-size: 14px;
                        line-height: 30px;
                    }

                    &-actions{
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .notification{
                            font-size: 11px;
                            font-weight: 600;
                            height: 16px;
                            background: ${palette.secondary.main};
                            border-radius: 4px;
    
                            .MuiChip-label{
                                padding-left: 8px;
                                padding-right: 8px;
                                color: rgb(255, 255, 255);
                            }
                        }
                    }

                }

                .active{
                    .item-icon, .item-text{
                        color: ${palette.primary.main} !important;
                    }
                }

                .collapse-list{
                    .item{
                        color: ${palette.white};
                        padding-left: 48px;
                        display: flex;
                        align-items: baseline;

                        .notification{
                            font-size: 11px;
                            font-weight: 600;
                            height: 16px;
                            background: ${palette.secondary.main};
                            border-radius: 4px;
    
                            .MuiChip-label{
                                padding-left: 8px;
                                padding-right: 8px;
                                color: rgb(255, 255, 255);
                            }
                        }
                    }
                }
            }
        }
    `}
`