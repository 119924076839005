import React, { useState } from "react"
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';
//@Material-ui
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
//Style
import useStyles from '../../../../../assets/styles/jss/pages/memberships/versionSKU/membershipCardStyle';
//Component
import MembershipModalUpSelling from '../../MembershipModalUpSelling/MembershipModalUpSelling';
import MembershipCardComboOwnBrands from "./Components/MembershipComboBrands/MembershipCardComboOwnBrands";
import MembershipCardComboCoursesAllies from "./Components/MembershipComboBrands/MembershipCardComboCoursesAllies";
import MembershipCardComboConecta from "./Components/MembershipComboBrands/MembershipCardComboConecta";
import getMembershipIcon from '../../Components/MembershipIcon';
//Utils
import {TxtBtnToSubscribe} from '../../utils/TxtBtnToSubscribe';
import {TxtSavingsByFees} from '../../utils/TxtSavingsByFees'

const MembershipCard = ({ membership, feeSlugSelected, feeSlugBase, additionalProduct, productAddOn, handleClick}) => {
    const [toOpenModal, setToOpenModal] = useState(false);
    const classes = useStyles({popular:membership?.popular});
    const classCard = [classes.card, membership?.popular ? classes.cardPopular : membership?.free ? classes.cardFree : {}]
    const icon = getMembershipIcon(membership?.name.toLowerCase());
    const feeSelectedShow = membership.fees.find(fee => fee?.slug === feeSlugSelected);
    const feeBase = membership.fees.find(fee => fee?.slug === feeSlugBase);
    const feesPrice = feeSelectedShow?.list_price; //* feeSelectedShow?.months;
    const feesBasePrice = feeBase?.monthlyPrice * feeSelectedShow?.months; 
    
    const scrollPositionComparefeatures = () => {
        var element = document.getElementById("id_compare_features");
        var headerOffset = 100; // Height of navbar.
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            left: 0,
            behavior: "smooth"
        });
    }

    return (
        <Card className={clsx(classCard)}>
            <CardContent className="pt-1">
                <Grid container justifyContent="center" className="mb-2">
                    <Grid item className="mr-2" style={{paddingTop:'2px'}}>{icon}</Grid>
                    <Grid item><Typography variant={"h3"}>{membership?.name}</Typography></Grid>
                </Grid>
                <Typography variant="h3" color="secondary" component="span">
                    <CurrencyFormat value={feeSelectedShow?.monthlyPrice} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix={feeSelectedShow?.currencySymbol} />
                    <Typography variant="h5" color="secondary" component="span" className={'ml-1'} >
                        {feeSelectedShow?.currencyCode}
                    </Typography>
                    <Typography className={clsx('ml-1', 'text-orange-300')} style={{ display: 'inline-block' }}>/mes</Typography>
                </Typography>
                {feeSelectedShow?.slug !== feeSlugBase &&
                    <Grid>
                        <Typography className={clsx('mr-1', classes.crossedOutPrice, classes.textDarkBlack100)} component='span' variant='body2'>
                            antes <CurrencyFormat value={feeBase.monthlyPrice} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix={feeSelectedShow?.currencySymbol} />
                        </Typography>
                    </Grid>
                }
                <Grid>
                    <Typography component="span" className={clsx(classes.membershipPrice)}>
                        <CurrencyFormat value={feeSelectedShow?.list_price} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix={membership.currencySymbol} />
                        <Typography component="span" className={clsx('ml-1', classes.membershipPrice)} >
                            {feeSelectedShow?.currencyCode}
                        </Typography>
                        <Typography className={clsx('ml-1', classes.textDarkBlack100, classes.textMembershipPrice)} style={{ display: 'inline-block' }}>por {feeSelectedShow?.months} meses</Typography>
                    </Typography>
                </Grid>

            </CardContent>
            <Box className={classes.rectangle}></Box>
            <MembershipCardComboOwnBrands membershipName={membership?.name.toLowerCase()} />
            <MembershipCardComboCoursesAllies membershipName={membership?.name.toLowerCase()} />
            <MembershipCardComboConecta />
            {   (membership.name!=='Lite') &&
                    <Typography variant="body1" className="semi-bold mt-3">
                        Asesorías personalizadas
                    </Typography>
            }
            <Button color='primary' className={clsx(classes.textBlue300, 'mt-2')}  onClick={scrollPositionComparefeatures}>+ Otros beneficios</Button>
            <Box className={classes.boxActionSubscription}>
                <Button variant='contained' color='primary' onClick={(e) => { feeSelectedShow.slug==='ANU' ? handleClick(e, membership, feeSelectedShow) :setToOpenModal(() => true) }}>
                    {TxtBtnToSubscribe(feeSelectedShow?.list_price, feeSelectedShow?.currencyCode)}
                </Button>
                <Box minHeight="14px">
                    {feeSelectedShow?.slug !== feeSlugBase &&
                        <Typography className={classes.textSavingsByFees}>
                            {TxtSavingsByFees(feeSelectedShow?.feeName, feesBasePrice-feesPrice, feeSelectedShow?.currencySymbol)}
                        </Typography>
                    }
                </Box>
            </Box>
            <MembershipModalUpSelling toOpen={toOpenModal} setToOpen={setToOpenModal} membershipSelected={membership} additionalProduct={additionalProduct} productAddOn={productAddOn} feeSelectedShow={feeSelectedShow} feeBase={feeBase} />
        </Card>
    )
}

export default MembershipCard;