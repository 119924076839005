import React from 'react';
import { useHistory } from "react-router-dom";

// @material-ui/cor
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { Hidden, IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    button:{
        padding: 0,
        '& .MuiButton-startIcon':{
            marginLeft: 0,
            marginRight: 0,
            height: 24,
            width: 24,
            display: 'flex',
            alignItems: 'center'
        }
    },
}));

const BackToGo = ({className, text, path, autoResponsive = false}) => {
    const classes = useStyles();
    const history = useHistory();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));


    const handleClick = () =>{
        if(path){
            history.push(path);
        }else{
            history.goBack()
        }
    }

    return (
        <>
            {
                isResponsive && autoResponsive
                ?
                    <IconButton onClick={handleClick} className='p-0'>
                        <Icon className={clsx('ri-arrow-left-s-line text-orange-300 font-size-24')}/>
                    </IconButton>
                :
                    <Button color='primary'
                        onClick={handleClick} 
                        className={clsx(classes.button, className)} 
                        startIcon={<Icon className={clsx('ri-arrow-left-s-line')}/>} 
                        disableRipple>
                        {text}
                    </Button>
            }
        </>
    );
};

export default BackToGo;