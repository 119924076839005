import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootUserRoleTag: {
        height: '26px',
        borderRadius: '4px',
        padding: '4px 8px',
        backgroundColor: theme.palette.colors.fillStroke[200],
        margin: '5px'
    },
    text: {
        color: theme.palette.colors.gray[100]
    }
}));
