import palette from '../palette';

export default {
  root: {
    height: 10,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: palette.colors.grey[400],
  },
  bar: {
    borderRadius: 0,
    backgroundColor: palette.colors.grey[400],
  },
  barColorPrimary:{
    backgroundColor: palette.colors.blue[400]
  }
};
