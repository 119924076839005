import React, { Suspense, lazy } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

//Material UI
import { Box, Container, Grid } from '@material-ui/core';

// Shared components
import LoaderContent from "../../../../../shared/components/Loader/LoaderContent";
import SimpleSearchWithFilter from '../../../../../shared/components/SimpleSearchWithFilter/SimpleSearchWithFilter';
import TabPanel from '../../../../../shared/components/Tabs/TabPanel';
import HorizontalTabs from '../../../../../shared/components/Tabs/HorizontalTabs';
import FilterSelection from '../../../../../design-system/components/Filters/FilterSelection/FilterSelection';

// Components
import AllResult from './AllResult/AllResult';

// SDK
import { MarketPlaceService, MIXPANEL_EVENT, ProgramService, CourseService } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';

// @redux
import { connect } from "react-redux"
import * as userActions from '../../../../../redux/actions/userActions';
import * as globalService from '../../../../../services/GlobalService';

// Constants
import { SOURCES } from '../../../../../shared/constants/sources';
import { TYPE_COURSE, TYPE_PROGRAM, TYPE_QUICKACCESS } from '../../../../../shared/constants/searchTypes';
import { TAB_ALL_RESULT, TAB_PROGRAM, TAB_COURSE, TAB_DIRECT_ACCESS } from '../../../../../shared/constants/searchTabs';

// Utils
import { getSessionCookie } from '../../../../../shared/utils/Sessions';
import objectIsEmpty from '../../../../../design-system/utils/objectIsEmpty';

// Styles
import useStyles from '../../../../../assets/styles/jss/pages/market/search/searchStyle';

// Material Functions
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Paths
import * as paths from "../../../../../routes/paths";
import { eventTracker, eventData } from '../../../../../shared/components/EventTracker/EventTracker';

// Index
const ACADEMY_INDEX = 0;
const OFFERT_EDUCATION_INDEX = 1;
const SOURCES_INDEX = 2;
const LEVEL_INDEX = 3;
const DURATION_INDEX = 4;
const LANGUAGE_INDEX = 5 

const firstLetter =(str)=> {
    let strArray = str.split(" ");
    let palabra='';
    for(let item of strArray) {
        palabra += ' '+item[0].toUpperCase() + (item.slice(1)).toLowerCase()
    }
    return palabra.trim();
};

const getSourcesAndCompanies = (data) => {
    let companies=[];
    let sources=[];
    data.forEach((value,index)=>{
        if(!!value.is_internal)
        {
            let source_id="";
            switch(value.id)
            {
                case SOURCES.HH:
                {
                    source_id="HH";
                    break;
                }
                case SOURCES.IESALUD:
                {
                    source_id="IE";
                    break;
                }
                case SOURCES.TALISIS:
                {
                    source_id="TAL";
                    break;
                }
                case SOURCES.UERRE:
                {
                    source_id="UERRE";
                    break;
                }
                case SOURCES.UNID:
                {
                    source_id="UNID";
                    break;
                }
                default:
                {
                    source_id="";
                    break;
                }
            }
            if(source_id!="")
            {
                companies.push(source_id);
            }
            else
            {
                sources.push(value.id);
            }
        }
        else
        {
            sources.push(value.id);
        }
    });

    // Se requiere cuando se envian companias para poder identificar de mejor manera los datos
    if (!!companies.length) sources.push(1);

    return {
        sources,
        companies
    };
};

const getObjectFilter = (encodeData) => {
    if (!encodeData) return null;

    const decodeFilters = Buffer.from(encodeData, 'base64').toString('ascii');
    const tempUrlFilter = new URLSearchParams(decodeFilters);
    const objectResult = {};

    tempUrlFilter.forEach((value, key) => {
        const getFilterId = key.split("-")[1];
        objectResult[getFilterId] = [...(objectResult[getFilterId] ?? []), value];
    });

    return objectResult;
};

const Search = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);

    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');
    const programService = new ProgramService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'')
    const courseService = new CourseService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'')
    const abortData = new AbortController();
    const responsive = useMediaQuery(theme => theme.breakpoints.down('sm'));

    // ==========
    //Params
    // ==========
    let Txt = new URLSearchParams(props.location.search);
    
    // Disferentes tipos de busquedas agrupadas.
    let search = Txt.get('buscar');
    let skillsSearch = Txt.get('academia');
    let schoolsSearch = Txt.get('escuela');
    let educonSearch = Txt.get('educon') === 'true';
    let aliadosSearch = Txt.get('aliados') === 'true';
    let explorarSearch = Txt.get('explorar') === 'true';
    let popularesSearch = Txt.get('populares') === 'true';
    let programasSearch = Txt.get('programas') === 'true';
    const ofertaEducativaBachilleratoSearch = Txt.get('bachillerato') === 'true';
    const ofertaEducativaLicenciaturaSearch = Txt.get('licenciatura') === 'true';
    const ofertaEducativaMaestriaSearch = Txt.get('maestria') === 'true';
    const filters = Txt.get("filtro");
    const urlFilterList = getObjectFilter(filters);

    const [textFilter, setTextFilter] = React.useState('');
    // Datos sobre los filtros disponibles.
    const [dataFilter, setDataFilter] = React.useState([
        {
            id: ACADEMY_INDEX,
            title: 'Academia',
            data: []
        },
        {
            id: OFFERT_EDUCATION_INDEX,
            title: 'Oferta educativa',
            data: []
        },
        {
            id: SOURCES_INDEX,
            title: 'Escuela',
            data: []
        },
        {
            id: LEVEL_INDEX,
            title: 'Nivel',
            data: []
        },
        {
            id: DURATION_INDEX,
            title: 'Duración',
            data: []
        },
        {
            id: LANGUAGE_INDEX,
            title: 'Idioma',
            data: []
        },
    ]);
    // Estados del acordeon de las secciones en los filtros.
    const [accordingFilters, setAccordingFilters] = React.useState([
        {
            id: ACADEMY_INDEX,
            isExpanded: false,
        },
        {
            id: OFFERT_EDUCATION_INDEX,
            isExpanded: false,
        },
        {
            id: SOURCES_INDEX,
            isExpanded: false,
        },
        {
            id: LEVEL_INDEX,
            isExpanded: false,
        },
        {
            id: DURATION_INDEX,
            isExpanded: false,
        },
        {
            id: LANGUAGE_INDEX,
            isExpanded: false,
        },
    ]);
    // Filtros seleccionados por el usuario.
    const [selectedFilters, setSelectedFilters] = React.useState([
        {
            id: ACADEMY_INDEX,
            selected: []
        },
        {
            id: OFFERT_EDUCATION_INDEX,
            selected: []
        },
        {
            id: SOURCES_INDEX,
            selected: []
        },
        {
            id: LEVEL_INDEX,
            selected: []
        },
        {
            id: DURATION_INDEX,
            selected: []
        },
        {
            id: LANGUAGE_INDEX,
            selected: []
        },
    ]);

    const [activeTab, setActiveTab] = React.useState(0);
    // Datos de la busqueda.
    const [dataResult, setDataResult] = React.useState({
        [TYPE_PROGRAM]: {    
            length: 0,
            list: []
        },
        [TYPE_COURSE]: {    
            length: 0,
            list: []
        },
        [TYPE_QUICKACCESS]: {    
            length: 0,
            list: []
        },
    });
    
    const [loading, setLoading] = React.useState(false);
    const [showFilter, setShowFilter] = React.useState(true);
    const [emptySearch, setEmptySearch] = React.useState(true);
    const [tabs, setTabs] = React.useState([]);
    const [isResponsive, setIsResponsive] = React.useState(responsive);
    const [isFirstSearch, setIsFirstSearch] = React.useState(true);
    
    // Module status
    const [filterChargeStatus, setFilterChargeStatus] = React.useState({[ACADEMY_INDEX]: false, [OFFERT_EDUCATION_INDEX]: false, [SOURCES_INDEX]: false, [LEVEL_INDEX]: false, [DURATION_INDEX]: false, [LANGUAGE_INDEX]: false});
    const [filterLoadComplete, setFilterLoadComplete] = React.useState(false);

    if (isResponsive !== responsive) {
        setIsResponsive(responsive);
        if (responsive) setShowFilter(false);
    }

    // ==========
    // Handles
    // ==========
    const handleChangeAccording = (idFilter) => {
        setAccordingFilters(prevState => prevState.map(item => {
            if (item.id === idFilter)
                return { ...item, isExpanded: !item.isExpanded };

            return { ...item, isExpanded: false};
        }));
    };

    const handleChangeChecked = (idFilter, idItem, value) => {
        setDataFilter(prevState => prevState.map(item => {
            if (item.id === idFilter)
                return { ...item, data: item.data.map(item2 => item2.id === idItem ? { ...item2, checked: !item2.checked } : item2) }
            return item;
        }));
    };

    const handleChangeFilter = (event) => {
        const eventData={}
        eventData['source'] = 'explorar'
        eventData['texto'] = 'si'
        eventTracker('busqueda', eventData, ['mixpanel']);
        setTextFilter(event.target.value);
    };

    const handleChangeTab = (tabIndex) => {
        let tabSelect = tabs[tabIndex]
        if (tabSelect == 'Programas') {
            eventTracker('s_resultados_programas', eventData, ['mixpanel']);
        }else if(tabSelect == 'Cursos') {
            eventTracker('s_resultados_cursos', eventData, ['mixpanel']);
        }
        setActiveTab(tabIndex);
    };

    const handleClickDeleteFilterWithChip = (filterId, selectedItemId) => {
        setDataFilter(prevState => {
            prevState[filterId].data = prevState[filterId].data.map(item => {
                if (item.id === selectedItemId) {
                    item.checked = false;
                }
                return item;
            });
            return [...prevState];
        });

        if (isResponsive && !showFilter) setShowFilter(true);
    };

    const handleResetFilters = async () => {
        setDataFilter(prevState => {
            prevState.forEach(filter => filter.data.forEach(item => item.checked = false));

            return [...prevState];
        });
    };

    const handleChangeShowFilters = async () => {
        setShowFilter(prevState => !prevState);
    };

    const handleChangeShowFilter = async () => {
        if (!showFilter)
            setShowFilter(prevState => true);
    };

    const onClickReadyFilterButton = () => {
        handleChangeShowFilters();
        searchInformation();
    };

    // ==========
    // Functions
    // ==========
    // Procesamos los datos del filtro de oferta educativa.
    const structureFilterEducationOffer = async () => {
        setDataFilter(prevState => {
            prevState[OFFERT_EDUCATION_INDEX].data = [
                {id: 'CUR', name: 'Curso', checked: educonSearch || validateUrlQueryFilter(OFFERT_EDUCATION_INDEX, 'CUR') },
                {id: 'BA', name: 'Bachillerato', checked: programasSearch || ofertaEducativaBachilleratoSearch || validateUrlQueryFilter(OFFERT_EDUCATION_INDEX, 'BA')} ,
                {id: 'LI', name: 'Licenciatura', checked: programasSearch || ofertaEducativaLicenciaturaSearch || validateUrlQueryFilter(OFFERT_EDUCATION_INDEX, 'LI')},
                {id: 'MA', name: 'Maestría', checked: programasSearch || ofertaEducativaMaestriaSearch || validateUrlQueryFilter(OFFERT_EDUCATION_INDEX, 'MA')},
            ];
            return [...prevState];
        });
        setFilterChargeStatus(prevState => ({...prevState, [OFFERT_EDUCATION_INDEX]: true}));
    };
    // Procesamos los datos del filtro para las escuelas.
    const structureFilterSchool = async () => {
        let data = await globalService.getCatalog("InformationSource");
        let sourceDataAux=[]

        data.forEach((value)=>{
           if(value.id == SOURCES.COURSERA || value.id == SOURCES.LINKEDIN_LEARNING || value.id == SOURCES.UERRE || value.id == SOURCES.UNID || value.id == SOURCES.HH || value.id == SOURCES.GOOGLE || value.id == SOURCES.CREHANA || value.id == SOURCES.HUBSPOT || value.id == SOURCES.IE_UNIVERSITY)
            {
                value.checked=false;

                if(!!educonSearch)
                {
                    if(value.id == SOURCES.UERRE || value.id == SOURCES.UNID || value.id == SOURCES.HH) value.checked=true;
                }

                if(!!aliadosSearch)
                {
                    if(value.id == SOURCES.COURSERA || value.id == SOURCES.LINKEDIN_LEARNING || value.id == SOURCES.GOOGLE || value.id == SOURCES.CREHANA || value.id  == SOURCES.HUBSPOT) value.checked=true; 
                }

                if(!!explorarSearch || !!popularesSearch) value.checked=true;

                if(parseInt(schoolsSearch) === value.id) value.checked=true;

                if (validateUrlQueryFilter(SOURCES_INDEX, value.id, 2)) value.checked=true;

                sourceDataAux.push({id: value.id, name: value.value, checked: value.checked, is_internal: value.is_internal});
            }
        });

        setDataFilter(prevState => {
            prevState[SOURCES_INDEX].data = sourceDataAux;
            return [...prevState];
        });
        setFilterChargeStatus(prevState => ({...prevState, [SOURCES_INDEX]: true}));
    }
    // Procesamos los datos del filtro de academias.
    const structureFilterAcademy = async () => {        
        let data = await globalService.getCatalog("CourseSkillCat_Academy");

        setDataFilter(prevState => {
            prevState[ACADEMY_INDEX].data = data.map(item => ({id: item.id, name: firstLetter(item.name), checked: skillsSearch == item.id || validateUrlQueryFilter(ACADEMY_INDEX, item.id, 2)}));
            return [...prevState];
        });
        setFilterChargeStatus(prevState => ({...prevState, [ACADEMY_INDEX]: true}));
    };

    // Procesamos los datos del filtro de nivel.
    const structureFilterLevel = async () => {        
        let data = await globalService.getCatalog("CourseDifficultyLevelCat");

        setDataFilter(prevState => {
            prevState[LEVEL_INDEX].data = data.map(item => ({id: item.id, name: item.name, checked: validateUrlQueryFilter(LEVEL_INDEX, item.id, 2)}));
            return [...prevState];
        });
        setFilterChargeStatus(prevState => ({...prevState, [LEVEL_INDEX]: true}));
    };

    // Procesamos los datos del filtro de duracion.
    const structureFilterDuration = async () => {        
        let data = await globalService.getCatalog("DurationCat");

        setDataFilter(prevState => {
            prevState[DURATION_INDEX].data = data.map(item => ({id: item.id, name: item.description, checked: validateUrlQueryFilter(DURATION_INDEX, item.id, 2)}));
            return [...prevState];
        });
        setFilterChargeStatus(prevState => ({...prevState, [DURATION_INDEX]: true}));
    };
    // Procesamos los datos del filtro de lenguaje.
    const structureFilterLanguage = async () => {        
        let data = await globalService.getCatalog("LanguageCat");

        setDataFilter(prevState => {
            prevState[LANGUAGE_INDEX].data = data.map(item => ({id: item.id, name: item.name, checked: validateUrlQueryFilter(LANGUAGE_INDEX, item.id, 2)}));
            return [...prevState];
        });
        setFilterChargeStatus(prevState => ({...prevState, [LANGUAGE_INDEX]: true}));
    };

    const getSelectedFilters = () => {
        const dataFilterTemp = [...dataFilter];
        const selectedFiltersTemp = [...selectedFilters];

        const selectedFiltersTempLength = selectedFiltersTemp.length;

        dataFilterTemp.forEach(filter => {
            const selectedItems = filter.data.filter(item => item.checked);
            for (let i = 0; i < selectedFiltersTempLength; i++) {
                if (selectedFiltersTemp[i].id === filter.id) {
                    selectedFiltersTemp[i].selected = selectedItems;
                    break;
                }
            }
        });

        setSelectedFilters(selectedFiltersTemp);
    };

    const setDataResultFunc = (coursesList, programsList, quickAccessList) => {
        setDataResult({
            [TYPE_PROGRAM]: {    
                length: programsList.length,
                list: programsList
            },
            [TYPE_COURSE]: {    
                length: coursesList.length,
                list: coursesList
            },
            [TYPE_QUICKACCESS]: {    
                length: quickAccessList.length,
                list: quickAccessList
            },
        });
    };

    const setTabsFunc = (coursesList, programsList, quickAccessList) => {
        let arrayTabs = [];

        arrayTabs = [TAB_ALL_RESULT];

        if (!!programsList.length) arrayTabs = [...arrayTabs, TAB_PROGRAM];

        if (!!coursesList.length) arrayTabs = [...arrayTabs, TAB_COURSE];

        if (!!quickAccessList.length && isLogged) arrayTabs = [...arrayTabs, TAB_DIRECT_ACCESS];

        setTabs(arrayTabs);
    };
    
    const changeSearchQueryString = () => { 
        let urlQueryString = ""; 
        const filterName = "filtro-";
        let urlFilter = "";

        if(!!textFilter) urlQueryString += `buscar=${textFilter.trim()}`;

        selectedFilters.forEach((item) => {
            item.selected.forEach((item2) => {
                urlFilter += `${!!urlFilter ? "&" : ""}${filterName}${item.id}=${item2.id}`;
            });
        });

        if (!!urlFilter) {
            const encodeFilter = Buffer.from(urlFilter).toString('base64');
            urlQueryString += `${!!urlQueryString ? "&" : ""}filtro=${encodeFilter}`;
        } 

        const urlQueryStringTemp = `?${urlQueryString}`;
        
        if (location.search !== urlQueryStringTemp) {
            history.push(`${paths.BUSQUEDA}${urlQueryStringTemp}`);
        }        
    };

    const searchInformation = async () => {
        setLoading(true);
        setIsFirstSearch(false);

        changeSearchQueryString();

        if (validateEmptySearch()) {
            setEmptySearch(true);

            if (!validateDataFilterIsEmpty()) {
                setDataResultFunc([], [], []);
            }

            setLoading(false);
            return;
        }

        setEmptySearch(false);
        const responseGetSourcesAndCompanies = getSourcesAndCompanies(selectedFilters[SOURCES_INDEX].selected);
        const body = {
            search: textFilter.trim(),
            personId: props.user.person_id,
            academies: selectedFilters[ACADEMY_INDEX].selected.map(academy => academy.id),
            educativeOffer: selectedFilters[OFFERT_EDUCATION_INDEX].selected.map(offert => offert.id),
            sources: responseGetSourcesAndCompanies.sources,
            modalities: [],
            companies: responseGetSourcesAndCompanies.companies,
            difficulties: selectedFilters[LEVEL_INDEX].selected.map(item => item.id),
            languages: selectedFilters[LANGUAGE_INDEX].selected.map(item => item.id),
            durations: selectedFilters[DURATION_INDEX].selected.map(item => item.id),
        };

        handleMixpanelSearchFilters(body.academies, body.sources, body.modalities, body.companies, body.educativeOffer, body.search);

        marketService.searchDataV2(body, null, abortData).then(response => {
            let quickAccessList = [];
            let programsList = [];
            let coursesList = [];

            if (!response.data?.error) {
                response.data[0].forEach((item) => {
                    const typeInLowerCase = item.type?.trim().toLowerCase();

                    if (typeInLowerCase === 'curso' && item.hasOwnProperty("labelToShow")) coursesList.push(item);
                    if (typeInLowerCase === 'programa' && item.hasOwnProperty("labelToShow")) programsList.push(item);
                    if (typeInLowerCase === 'acceso') quickAccessList.push(item);
                });
            }
            setActiveTab(0);
            setDataResultFunc(coursesList, programsList, quickAccessList);
            if (!coursesList.length && !programsList.length && !quickAccessList.length) {
                eventTracker('s_sin_resultados', eventData, ['mixpanel']);
            }else{
                eventTracker('s_resultados', eventData, ['mixpanel']);
            }
            setTabsFunc(coursesList, programsList, quickAccessList);
            setLoading(false);
        }).catch(err => {
            eventTracker('s_sin_resultados', eventData, ['mixpanel']);
            setDataResultFunc([], [], []);
            setLoading(false);
        });
    };

    const validateEmptySearch = () => !textFilter.trim().length && !selectedFilters[ACADEMY_INDEX].selected.length && !selectedFilters[OFFERT_EDUCATION_INDEX].selected.length && !selectedFilters[SOURCES_INDEX].selected.length && !selectedFilters[LEVEL_INDEX].selected.length && !selectedFilters[DURATION_INDEX].selected.length && !selectedFilters[LANGUAGE_INDEX].selected.length;
    
    const validateUrlQueryFilter = (filterId, value, typeOfValue = 1) => {
        return (!!urlFilterList && !!urlFilterList[filterId] && urlFilterList[filterId].some((item) => (typeOfValue === 2 ? parseInt(item) : item) === value));
    };
    
    const validateDataFilterIsEmpty = () => !dataFilter[ACADEMY_INDEX].data.length && !dataFilter[OFFERT_EDUCATION_INDEX].data.length && !dataFilter[SOURCES_INDEX].data.length && !dataFilter[LEVEL_INDEX].data.length && !dataFilter[DURATION_INDEX].data.length && !dataFilter[LANGUAGE_INDEX].data.length;

    // Funcion que procesa los filtros cuando el usuario preciona el boton de retroceso en el navegador.
    const processToBackPreviousSearch = () => {
        setDataFilter(prevState => {
            const newState = prevState.map((filter) => ({...filter, data: filter.data.map((item) => ({...item, checked: validateUrlQueryFilter(filter.id, item.id, (typeof item.id === "string" ? 1 : 2))}))}))
            return newState;
        });

        if (!!search) setTextFilter(search.trim());
    };

    // ==========
    // Mix panel
    // ==========
    const handleMixpanelSearchFilters = (academies, sources, modalities, companies, educativeOffer, searchText)=> {
        //MIXPANEL ARMAR EVENTO SDK
               
        let role = JSON.parse(localStorage.getItem('permissions'));
       
        const eventdata ={
            userId: props.user.person_id,
            email: props.user.email,
            unidadOperativa: props.user.ou,
            gender: props.user.gender,
            userRole: !!role && !!role.length ? JSON.stringify(role[0]?.role_name).replace(/["']/g, "") : undefined,//estudiante,maestro,admin
            skills: academies,
            educativeOffer: educativeOffer,
            schools: sources,
            modalities: modalities,
            companies: companies,
            criteria: searchText.replace(/\s+/g, ' ').trim().toUpperCase()  
        };
        
       
    };

    const handleClickMixpanel = async (courseName, source_name, courseId, programId, programName, redirectTo) => {
        if (courseId) {
            courseService.getEnrolledCourseDetail(courseId)
                .then(detail => {
                    let marcaCurso = '';
                    if (detail) {
                        marcaCurso = detail?.company_id !== null ? detail?.company_id : detail?.source_name;
                        marcaCurso = marcaCurso ? marcaCurso.toLowerCase().split(" ")[0] : '';
                    }
                    eventData['source'] = 'explorar';
                    eventData['marcaCurso'] = marcaCurso;
                    eventData['courseID'] = courseId;
                    eventTracker('ver_curso', eventData, ['mixpanel']);
                });
        }
        else if (programId) {
            programService.getProgramById(programId)
                .then(detail => {
                    let marcaPrograma = '';
                    if (detail) {
                        marcaPrograma = detail?.company_id ? (detail?.company_id).toLowerCase() : '';
                    }
                    eventData['source'] = 'explorar';
                    eventData['marcaCurso'] = marcaPrograma;
                    eventData['courseID'] = programId;
                    eventTracker('ver_curso', eventData, ['mixpanel']);
                });
        }
    };

    const handleMixpanelSearch = () => {
        if(textFilter.trim() !== ''){
            const role = JSON.parse(localStorage.getItem('permissions'));
            const eventdata = {
                userId: props.user.person_id,
                email: props.user.email,
                unidadOperativa: props.user.ou,
                criteria: textFilter.replace(/\s+/g, ' ').trim().toUpperCase(),
                gender: props.user.gender,
                userRole: !!role && !!role.length ? JSON.stringify(role[0]?.role_name).replace(/["']/g, "") : undefined,//estudiante,maestro,admin
                userType: role == null ? 'Publico':'Usuario registrado'
            }
           
        };
    };

    // ==========
    // Effects
    // ==========
    React.useEffect(() => {
        window.scrollTo(0, 0);

        structureFilterSchool();
        structureFilterAcademy();
        structureFilterEducationOffer();
        structureFilterLevel();
        structureFilterDuration();
        structureFilterLanguage();

        if (!!search) setTextFilter(search.trim());

        return () => {
            abortData.abort();
        };
    }, []);

    React.useEffect(() => {
        const statusValues = Object.values(filterChargeStatus);
        if (statusValues.every((status) => !!status)) {
            setFilterLoadComplete(true);
            searchInformation();
        }
    }, [filterChargeStatus]);
    
    // Se usa para cuando se le da back al navegador.
    React.useEffect(() => {
        if (history.action === "POP" && !!urlFilterList) {
            processToBackPreviousSearch();
        }
    }, [location]);

    React.useEffect(() => {
        getSelectedFilters();
    }, [dataFilter]);

    React.useEffect(() => {
        let timer = null;

        if (filterLoadComplete && (!isResponsive || (isResponsive && isFirstSearch))) {
            timer = setTimeout(() => {
                window.scrollTo(0, 0);
                searchInformation();
            }, 1000);
        }

        return () => clearTimeout(timer);
    }, [selectedFilters]);

    // Effectos para cuando sea resposivo
    React.useEffect(() => {
        let timer = null;

        if (filterLoadComplete) {
            timer = setTimeout(() => {
                window.scrollTo(0, 0);
                searchInformation();
            }, 1000);
        }

        return () => clearTimeout(timer);
    }, [textFilter]);

    return (
        <Box className={classes.rootSearch}>
            <LoaderContent loading={loading}>
                <Box className={classes.filterContainer}>
                    <Container className={classes.filterSubcontainer}>
                        <SimpleSearchWithFilter onChange={handleChangeFilter} placeholder={"Buscar"} text={textFilter} onBlur={handleMixpanelSearch}
                        onClickFilterButton={handleChangeShowFilter}/>
                    </Container>
                </Box>

                <Container className={classes.dataContainer}>
                    <Grid container spacing={3} direction='row'>
                        <Grid item xs={12} lg={3} style={{display: (showFilter ? 'block' : 'none') }}>
                            <FilterSelection data={dataFilter} accordingFilters={accordingFilters} onChangeAccording={handleChangeAccording} onChangeChecked={handleChangeChecked} onResetFilters={handleResetFilters} onChangeShowFilters={handleChangeShowFilters} onClickReadyFilterButton={onClickReadyFilterButton} showClearFilterButton={!!selectedFilters[ACADEMY_INDEX].selected.length || !!selectedFilters[OFFERT_EDUCATION_INDEX].selected.length || !!selectedFilters[SOURCES_INDEX].selected.length || !!selectedFilters[LEVEL_INDEX].selected.length || !!selectedFilters[DURATION_INDEX].selected.length || !!selectedFilters[LANGUAGE_INDEX].selected.length}/>
                        </Grid>
                        <Grid item xs={12} lg={(showFilter ? 9 : 12)}>
                            <Box>
                                <HorizontalTabs
                                    activeTab={activeTab}
                                    onChange={handleChangeTab}
                                    tabs={tabs}>
                                        <>        
                                        {
                                            tabs.map((tab, index) => 
                                            <TabPanel value={activeTab} index={index} key={index}>
                                                {
                                                    tab === TAB_ALL_RESULT && 
                                                    <AllResult data={dataResult} filterSelected={selectedFilters} types={[TYPE_COURSE, TYPE_PROGRAM, TYPE_QUICKACCESS]} showAll={false} dataNumberShow={{[TYPE_COURSE]: 9, [TYPE_PROGRAM]: 9, [TYPE_QUICKACCESS]: 9}} onChangeTab={handleChangeTab} onClickDeleteFilterWithChip={handleClickDeleteFilterWithChip} onClickMixPanelCard={handleClickMixpanel} emptySearch={emptySearch} doingSearch={loading} textIsSearching={textFilter} dataTabs={tabs}/>
                                                }
                                                {
                                                    tab === TAB_PROGRAM && 
                                                    <AllResult data={dataResult} filterSelected={selectedFilters} types={[TYPE_PROGRAM]} showAll={true} onClickDeleteFilterWithChip={handleClickDeleteFilterWithChip} onClickMixPanelCard={handleClickMixpanel}  emptySearch={emptySearch} doingSearch={loading} textIsSearching={textFilter}/>
                                                }
                                                {
                                                    tab === TAB_COURSE && 
                                                    <AllResult data={dataResult} filterSelected={selectedFilters} types={[TYPE_COURSE]} showAll={true} onClickDeleteFilterWithChip={handleClickDeleteFilterWithChip} onClickMixPanelCard={handleClickMixpanel}  emptySearch={emptySearch} doingSearch={loading} textIsSearching={textFilter}/>
                                                }
                                                {
                                                    tab === TAB_DIRECT_ACCESS && 
                                                    <AllResult data={dataResult} filterSelected={selectedFilters} types={[TYPE_QUICKACCESS]} showAll={true} onClickDeleteFilterWithChip={handleClickDeleteFilterWithChip} onClickMixPanelCard={handleClickMixpanel}  emptySearch={emptySearch} doingSearch={loading} textIsSearching={textFilter}/>
                                                }
                                            </TabPanel>) 
                                        }     
                                        </>
                                </HorizontalTabs>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </LoaderContent>
        </Box>
    );
};

const mapStateToProps = (state) => ({ ...state.userReducer });

export default connect(mapStateToProps, { ...userActions })(Search);