const uerre_images_placeholder = [
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_1.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_2.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_3.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_4.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_5.jpg",
];

const unid_images_placeholder = [
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_1.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_2.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_3.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_4.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_5.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_6.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_7.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_8.jpg",
];

const aliados_images_placeholder = ({
    5: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_coursera.jpg",
    6: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_linkedin.jpg",
   12: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_google.jpg",
   13: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_crehana.jpg",
   14: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_hubspot.jpg",
   15: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_ieuniversity.jpg",
   16: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_aws.jpg",
});

const programs_images_placeholder = ({
    "licenciatura": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Licenciatura.png",
    "bachillerato": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Bachillerato.png",
    "maestria": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Maestria.png",
});

const image_default_placeholder = "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default.jpg";

const getGeneric = () => {
    return image_default_placeholder;
}
export const convertSourceToImageResource = (company_id, source_id) => {
    if (source_id == 1 || source_id == 8 || source_id == 9 || source_id == 10 || source_id == 20) {
        if (company_id == "UERRE" || source_id == 8){
            return uerre_images_placeholder[Math.floor(Math.random() * uerre_images_placeholder.length)];
        } 
        if (company_id == "UNID" || source_id == 9){
            return unid_images_placeholder[Math.floor(Math.random() * unid_images_placeholder.length)];
        } 
        if (company_id == "HH" || source_id == 10){
            return  getGeneric();
        }
    }else{
        if(aliados_images_placeholder[source_id]){
            return aliados_images_placeholder[source_id];
        }else{
            return image_default_placeholder;
        }
        
    }
}

export const getProgramPlaceholderByType = (type) => {
    return programs_images_placeholder[type];
}