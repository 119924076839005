import { Grid, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import React from 'react';

import ErrorBackground from './../../assets/images/theme/error/error_background.svg';
import ErrorWoman from './../../assets/images/theme/error_woman.svg';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        position: "relative"
    },
    imageBackgroundContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "700px",
        maxHeight: "235px",
        paddingTop: "51px",
        paddingBottom: "51px",
    },
    imageBackground: {
        [theme.breakpoints.down('600')]: {
            display: "none"
        }
    },
    ErrorPageContainer: {},
    titleContainer: {
        paddingTop: "64px",
        [theme.breakpoints.down('960')]: {
            paddingTop: "32px",
        }
    },
    title: {
        textAlign: "center",
        [theme.breakpoints.up('600')]: {
            whiteSpace: "pre-line",
        }
    },
    buttonsContainer: {
        paddingTop: '32px'
    }
}));

const ErrorPage = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { title, path, buttonText } = props;

    const handleHomeButton = () => {
        history.push(path);
    };

    return (
        <Grid container className={classes.ErrorPageContainer}>
            <Grid item xs={12}>
                <Box className={classes.imageBackgroundContainer}>
                    <img src={ErrorBackground} className={classes.imageBackground} alt="ups!" />
                </Box>
                <Box className={classes.imageContainer}>
                    <img src={ErrorWoman} className={classes.image} alt="error" />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.titleContainer} justifyContent='center'>
                    <Grid item xs={12}>
                        <Typography variant="body1" className={classes.title}>{title}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonsContainer}>
                        <Button variant="contained" color="primary" onClick={() => handleHomeButton()} className={classes.button}>{buttonText}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

ErrorPage.defaultProps = {
    path: "/",
    title: "Ha ocurrido un error al procesar la información.\nSi el error persiste, contacta a nuestro equipo de soporte.",
    buttonText: "Volver a Talisis.com"
};

export default ErrorPage;