import { makeStyles } from '@material-ui/core/styles';
import { SOURCES } from '../../../../../constants/sources';

export default makeStyles((theme) => ({
    rootStatusCourseMedia: {
        height: props => props.isResponsive ? "130px" : "158px",
        width: "100%",
        backgroundImage: props => `url(${props.backgroundImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        filter: "grayscale(100%)",
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    container: {
        padding: props => props.isResponsive ? "8px 12px 8px 12px" : "16px",
        flexGrow: "1",
        display: "flex",
        alignItems: "end",
    },
    containerBottom: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    logoIconSource: {
        color: `${theme.palette.colors.gray[400]} !important`,
    },
    [SOURCES.ADVENIO] : {
        height: props => props.isResponsive ? "18px" : '25px',
    },
    [SOURCES.NEO] : {
        height: props => props.isResponsive ? "14px" : '19px',
    },
    [SOURCES.COURSERA] : {
        height: props => props.isResponsive ? "10px" : '16px',
    },
    [SOURCES.LINKEDIN_LEARNING] : {
        height: props => props.isResponsive ? "17px" : '25px',
    },
    [SOURCES.TALISIS] : {
        height: props => props.isResponsive ? "16px" : '18px',
    },
    [SOURCES.UERRE] : {
        height: props => props.isResponsive ? "16px" : '18px',
    },
    [SOURCES.UNID] : {
        height: props => props.isResponsive ? "16px" : '18px',
    },
    [SOURCES.HH] : {
        height: props => props.isResponsive ? "14px" : '18px',
    },
    [SOURCES.IESALUD] : {
        height: props => props.isResponsive ? "16px" : '19px',
    },
    [SOURCES.GOOGLE] : {
        height: '17px',
    },
    [SOURCES.CREHANA] : {
        height: props => props.isResponsive ? "14px" : '20px',
    },
    [SOURCES.HUBSPOT] : {
        height: props => props.isResponsive ? "16px" : '21px',
    },
    [SOURCES.IE_UNIVERSITY] : {
        height: props => props.isResponsive ? "24px" : '36px',
    },
    [SOURCES.AWS] : {
        height: props => props.isResponsive ? "21px" : '30px',
    },
    [SOURCES.SIMPLICITY] : {
        height: props => props.isResponsive ? "14px" : '17px',
    },
    [SOURCES.CONEKTA] : {
        height: props => props.isResponsive ? "14px" : '18px',
    },
    [SOURCES.AWS_SKILL_BUILDER] : {
        height: props => props.isResponsive ? "21px" : '30px',
    },
    [SOURCES.NEO_CH] : {
        height: props => props.isResponsive ? "21px" : '27px',
    },
}));