import React from 'react';
import PropTypes from 'prop-types';

//@material
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';

// styles
import useStyles from '../../../../../../assets/styles/jss/pages/requests/PhysicalDocumentsStyle'
import clsx from 'clsx';

const PhysicalDocuments = props => {

    const classes = useStyles();

    return (
        <Card component={Box} mb={2}>
            <CardContent component={Box} className={classes.cardContent}>
                <Typography className={classes.title}>Envío de documentos físicos</Typography>
                <Typography className={classes.subtitle}>Adicionalmente envíanos por paquetería los siguientes documentos originales en físico:</Typography>
                <Divider className={classes.divider}/>
                <Box className={classes.file}>
                    <ListAltOutlinedIcon/>
                    <Typography component="span">Certificado de último grado de estudios</Typography>
                </Box>
                <Divider className={clsx(classes.divider, 'mb-4')}/>
                <Button startIcon={<GetAppIcon />} className={classes.button} variant="outlined" fullWidth>Descarga guía e instrucciones</Button>
            </CardContent>
        </Card>
    );
};

PhysicalDocuments.propTypes = {
    
};

export default PhysicalDocuments;