export const SOURCES = {
    BANNER: 1,
    ADVENIO: 3,
    COURSERA: 5,
    LINKEDIN_LEARNING: 6,
    TALISIS: 7,
    UERRE: 8,
    UNID: 9,
    HH: 10,
    IESALUD: 11,
    CREHANA: 13,
    GOOGLE:12,
    HUBSPOT: 14,
    IE_UNIVERSITY: 15,
    AWS: 16,
    AWS_SKILL_BUILDER: 19,
    EASYGENERATOR: 20,
    VOXY: 23
}

export const SOURCE_NAME = {
    [SOURCES.BANNER]: 'banner',
    [SOURCES.COURSERA]: 'coursera',
    [SOURCES.LINKEDIN_LEARNING]: 'linkedin-learning',
    [SOURCES.UERRE]: 'uerre',
    [SOURCES.UNID]: 'unid',
    [SOURCES.HH]: 'harmon-hall',
    [SOURCES.CREHANA]: 'crehana',
    [SOURCES.GOOGLE]: 'google',
    [SOURCES.HUBSPOT]: 'hubspot',
    [SOURCES.IE_UNIVERSITY]: 'ie-university',
    [SOURCES.AWS]: 'aws',
    [SOURCES.AWS_SKILL_BUILDER]: 'aws-skill-builder',
    [SOURCES.EASYGENERATOR]: 'easygenerator',
}

export const SOURCE_ID_COMPANY_NAME = {
    "UERRE": SOURCES.UERRE,
    "UNID": SOURCES.UNID,
    "HH": SOURCES.HH
}

export const SOURCE_COMPANY_DEGREE_CERTIFICATE = [
    { "id": "UNID", "label": "UNID", "value": "UNID" },
    // { "id": "UERRE", "label": "UERRE", "value": "UERRE" },
]

export const SOURCE_ACADEMIC_DEGREE_CERTIFICATE = [
    { "id": "LI", "label": "LICENCIATURA", "value": "LI" },
    { "id": "MA", "label": "MAESTRIA", "value": "MA" },
]
export const SOURCE_STATUS_PROCESS_DEGREE_CERTIFICATE = [
    { "id": "CAN", "label": "CANDIDATO", "value": "CAN"},
    { "id": "VAL", "label": "EN VALIDACION", "value": "VAL"},
    { "id": "APT", "label": "APTO PARA PROCESAR", "value": "APT"},
    { "id": "INI", "label": "PROCESO INICIADO", "value": "INI"},
    { "id": "PRV", "label": "CON PROVEEDOR", "value": "PRV"},
]
export const SOURCE_SZBAREC_DEGREE_CERTIFICATE = [
    {"id":"1","label":"RVOE FEDERAL"},
    {"id":"2","label":"RVOE ESTATAL"},
    {"id":"3","label":"AUTORIZACIÓN FEDERAL"},
    {"id":"4","label":"AUTORIZACIÓN ESTATAL"},
    {"id":"5","label":"ACTA DE SESIÓN"},
    {"id":"6","label":"ACUERDO DE INCORPORACIÓN"},
    {"id":"7","label":"ACUERDO SECRETARIAL SEP"},
    {"id":"8","label":"DECRETO DE CREACIÓN"},
    {"id":"9","label":"OTRO"},
]

export const SOURCE_SZBMITC_DEGREE_CERTIFICATE = [
    {"id":"1","label":"POR TESIS"},
    {"id":"2","label":"POR PROMEDIO"},
    {"id":"3","label":"POR ESTUDIOS DE POSGRADOS"},
    {"id":"4","label":"POR EXPERIENCIA LABORAL"},
    {"id":"5","label":"POR CENEVAL"},
    {"id":"6","label":"OTRO"},
]
export const SOURCE_CMPLESS_DEGREE_CERTIFICATE = [
    {"id":"SI","label":"SI"},
    {"id":"NO","label":"NO"},
]
export const SOURCE_PROCESS_DEGREE_CERTIFICATE = [
    {"id":"CERTIFICACION","label":"Certificación"},
    {"id":"CERTIFICACION_PARCIAL","label":"Certificación Parcial"},
    {"id":"TITULACION","label":"Titulación"},
]
