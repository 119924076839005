import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// MUI
import Fab from '@material-ui/core/Fab';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

//Components
import StartHelp from './StartHelp'
import ContactAgent from './ContactAgent';
import ViewTickets from './ViewTickets'

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 64
  }
}));

const Menu = (props) => {
  const {viewMainMenu, viewContactAgent, viewTickets} = props;
  
  // const menuProps = {
  //   handleViewAgent,
  //   zendeskUser
  // }
  
  // const agentProps =
  const classes = useStyles();
  return (
    <Card>
      {viewMainMenu && <StartHelp { ... props } /> }
      {viewContactAgent && <ContactAgent { ... props } /> }
      {viewTickets && <ViewTickets { ... props } /> }
    </Card>
  )
};

Menu.defaultProps = {
    isHome: false,
    isCheckout: false,
};

Menu.propTypes = {
    isHome: PropTypes.bool,
    isLogged: PropTypes.bool,
    isCheckout: PropTypes.bool,
    hideNavLinks: PropTypes.bool,
};


export default Menu;
