import React from 'react';

// @material-ui/core
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const RegisterButton = ({ loading, onClick }) => {
    const classes = useStyles();

    return <div className={classes.wrapper}>
        <Button
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={onClick}>
            Registrarse
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
}

export default RegisterButton;