import React from 'react';
import { useHistory } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// assets
import { getLogoCard, LOGO_SOURCE }from '../../../../../src/constants/logosImages';

const Logo = (props) => {
    const { classes, autoResponsive = true } = props;
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const history = useHistory();

    const reloadRedirect = (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        history.push('/');
        window.location.reload();
        window.scrollTo(0, 0);
    }

    return (
        <Link onClick={reloadRedirect} className={classes}>
            <img alt="logo" src={getLogoCard(LOGO_SOURCE.TALISIS, isResponsive && autoResponsive)} />
        </Link>
    );
};

export default Logo;