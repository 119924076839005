import React from 'react';

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, Checkbox, CircularProgress, Tooltip } from '@material-ui/core';
import { ChevronLeft, Group, Lock } from '@material-ui/icons';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// @utils
import * as DateUtils from '../../../../../../shared/utils/dateUtils';

const FORM_NAME = 'job_history';
let originalRequiredFields = ['company_name', 'position', 'jh_start_year', 'jh_ending_year'];
let requiredFields = originalRequiredFields;

export const AddJobHistory = ({ data, onChange, onSetForm, validate, onSubmit }) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        card: {
            backgroundColor: theme.palette.colors.grey[400],
            padding: '1em',
        },
        title: {
            color: theme.palette.colors.fillStroke[100],
            cursor: 'default',
            fontSize: '12px',
            fontWeight: 600,
        },
        label: {
            color: theme.palette.colors.white,
            fontWeight: 600,
            fontSize: '12px',
        },
        inputText: {
            border: `1.5px solid ${theme.palette.colors.fillStroke[200]}`,
            borderRadius: '5px',
            color: theme.palette.colors.white,
            fontSize: '14px',
            padding: '0.5em',
            '&.Mui-disabled': {
                color: theme.palette.colors.white,
            },
        },
        icon: {
            color: theme.palette.colors.white,
            marginRight: '10px',
        },
        toogleButton: {
            backgroundColor: theme.palette.colors.black,
            border: `2px solid ${theme.palette.colors.fillStroke[200]}`,
            color: theme.palette.colors.white,
        },
        toogleButtonSelected: {
            '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: '#3316D2',
                color: theme.palette.colors.white,
            }
        },
        circularProgress: {
            color: theme.palette.colors.white,
        },
        cancelButton: {
            color: theme.palette.colors.white,
        },
        saveButton: {
            backgroundColor: theme.palette.colors.orange[300],
            color: theme.palette.colors.white,
        },
        alertMessage: {
            backgroundColor: theme.palette.colors.grey[500],
            color: theme.palette.colors.white,
            float: 'left',
            fontFamily: "'Source Sans Pro', sans-serif !important",
            fontSize: '12px',
            marginBottom: '10px',
        },
    });

    const classes = useStyles();
    const [currentJob, setCurrentJob] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className={`Profile_Title ${classes.title}`} onClick={() => { onSetForm(1) }}>
                    <Tooltip title="Regresar" placement="left">
                        <ChevronLeft className="Profile_ChevronPointer Profile_ChevronLeft" />
                    </Tooltip>
                    Empleo
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="company_name"
                    name="company_name"
                    placeholder='Nombre de la compañía'
                    fullWidth
                    onChange={(e) => onChange(e, FORM_NAME)}
                    inputProps={{
                        maxLength: 200,
                    }}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <TextField
                    id="position"
                    name="position"
                    placeholder='Puesto'
                    fullWidth
                    onChange={(e) => onChange(e, FORM_NAME)}
                    inputProps={{
                        maxLength: 100,
                    }}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="caption">
                    <Checkbox id="current_job" onClick={() => {
                        setCurrentJob(!currentJob);
                        onChange({target: {name: 'ending_year', value: DateUtils.CURRENT_YEAR}}, FORM_NAME)
                    }} />
                    <label htmlFor="current_job">Actualmente tengo este cargo</label>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <TextField
                    id="jh_start_year"
                    name="start_year"
                    type="number"
                    placeholder='Año de inicio'
                    fullWidth
                    onChange={(e) => onChange(e, FORM_NAME)}
                    onInput={(e) => {
                        if (e.target.value) {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                            if (e.target.value > DateUtils.CURRENT_YEAR) {
                                e.target.value = 2022;
                            }
                        }
                    }}
                    inputProps={{
                        min: 1900,
                        max: DateUtils.CURRENT_YEAR,
                    }}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <TextField
                    id="jh_ending_year"
                    name="ending_year"
                    type="number"
                    placeholder={currentJob ? 'Actual' : 'Año de finalización'}
                    fullWidth
                    onChange={(e) => onChange(e, FORM_NAME)}
                    onInput={(e) => {
                        if (e.target.value) {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                            if (e.target.value > DateUtils.CURRENT_YEAR) {
                                e.target.value = 2022;
                            }
                        }
                    }}
                    inputProps={{
                        min: 1900,
                        max: DateUtils.CURRENT_YEAR,
                    }}
                    InputProps={{
                        className: classes.inputText,
                        disableUnderline: true
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={currentJob ? true: false}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={`Profile_Title ${classes.label}`}>
                    Elige quien puede ver tu información laboral
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ToggleButtonGroup color="primary" style={{ width: '80%'}} exclusive value={data.visibility} onChange={(e, v) => {
                    onChange({ target: { name: "visibility", value: v } }, FORM_NAME)
                }}>
                    <ToggleButton className={`Profile_VisibilityFields ${classes.toogleButton}`} value="public" classes={{
                        selected: classes.toogleButtonSelected
                    }}>
                        <Group fontSize='inherit' />
                        <Typography variant='caption'>
                            Todos los usuarios
                        </Typography>
                    </ToggleButton>
                    <ToggleButton className={`Profile_VisibilityFields ${classes.toogleButton}`} value="private" classes={{
                        selected: classes.toogleButtonSelected
                    }}>
                        <Lock fontSize='inherit' />
                        <Typography variant='caption'>
                            Solo yo
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                <Typography display='block' variant="caption" className="Profile_noteContainer mt-2">
                    Esta información es privada y solo puedes verla tú.&nbsp;<a>Más Información.</a>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                {
                    errorMessage &&
                    <Alert id='jh_alert_message' variant="outlined" severity={'error'} className={classes.alertMessage}>
                        {errorMessage}.
                    </Alert>
                }
                {
                    data.toastProps.ok ?
                        <Alert id='jh_alert_message' variant="outlined" severity={data.toastProps.ok === 'true' ? 'success' : 'error'} className={classes.alertMessage}>
                            {data.toastProps.message}.
                        </Alert>
                        :
                        <Typography variant="subtitle2" className="Profile_buttonsContainer">
                            <Button
                                color="primary"
                                variant="text"
                                className={`Profile_Button ${classes.cancelButton}`}
                                disabled={data.loading}
                                onClick={() => onSetForm(1)}>
                                Cancelar
                            </Button>
                            <Button
                                color="primary"
                                variant="outlined"
                                className={`Profile_Button ${classes.saveButton}`}
                                disabled={data.loading}
                                onClick={() => {
                                    if (currentJob) {
                                        requiredFields = ['company_name', 'position', 'jh_start_year'];
                                    }
                                    const error = validate(requiredFields);
                                    if (error) {
                                        setErrorMessage("Favor de completar los campos requeridos");
                                        setTimeout(() => {
                                            if (document.getElementById('jh_alert_message')) {
                                                document.getElementById('jh_alert_message').style.visibility = 'hidden';
                                                setErrorMessage("");
                                            }
                                        }, 4000);

                                        return;
                                    }

                                    onSubmit(FORM_NAME);
                                    requiredFields = originalRequiredFields;
                                    setTimeout(() => onSetForm(0), 1000);
                                }}>
                                {data.loading && <CircularProgress size={20} className={classes.circularProgress} />}
                                {!data.loading && 'Guardar'}
                            </Button>
                        </Typography>
                }
            </Grid>
        </Grid>
    );
};
