import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        '&.MuiTypography-h5': {
            fontWeight: 'normal'
        }
    },
    outlined: {
        padding: '16px 32px',
    },
    control: {
        alignItems: 'flex-start',
    },
    checkbox: {
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingTop: '0px',
        paddingBottom: '0px',
        marginTop: '-2px',
    },
    line: {
        flexGrow: 1,
        marginLeft: '4px',
        borderBottom: '1px solid black',
        height: '16px',
    },
    paperCardTranparent: {
        backgroundColor: 'transparent !important'
    }
}));

const TimelineItem = (props) => {
    const classes = useStyles();
    const {children, oppositeContent, dotContent, disabled, justDot, color, variant, isBackGroundTranparent } = props;
    return (
        <MuiTimelineItem className={clsx({'outlined': variant === 'outlined', 'disabled': disabled })}>
            {
                oppositeContent &&
                <TimelineOppositeContent>
                    <Typography color="textSecondary">{oppositeContent}</Typography>
                </TimelineOppositeContent>
            }
            <TimelineSeparator>
                <TimelineDot className="time-line-dot" color="primary" variant="outlined">{dotContent}</TimelineDot>
                {!justDot && <TimelineConnector className="time-line-connector" style={{background: color}}/>}
            </TimelineSeparator>
            <TimelineContent>
                {<Paper elevation={disabled ? 1 : 0} component={Box} p={2} className={clsx('time-line-content__paper', isBackGroundTranparent && classes.paperCardTranparent)} variant={variant}>
                    {children}
                </Paper>}
            </TimelineContent>
        </MuiTimelineItem>
    );
};

export default TimelineItem;

TimelineItem.defaultProps = {
    color: '',
    variant: 'contained'
}

TimelineItem.propTypes = {
    /**
     * Array of options
     */
    children: PropTypes.node,
    /**
     * Optional click handler
     */
    // key: PropTypes.any.isRequired,
    oppositeContent: PropTypes.string,
    dotContent: PropTypes.any,
    disabled: PropTypes.bool,
    color: PropTypes.string,
};