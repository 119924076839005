import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    containerInfoEvent: {
        width: '100%',
    },
    containerEvents: {
        width: '100%',
    }
}));
