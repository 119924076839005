import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

// styles
import useStyles from '../../assets/styles/jss/layout/unidLayoutStyle'
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';

const UerreLayout = (props) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const {children, ou} = props;

    const banner = isResponsive ? `/images/vocational-test/uerre-responsivo.png` : `/images/vocational-test/uerre-2.png`;
    const classes = useStyles({ou});

    return (
        <Box className={clsx(classes.root, classes[ou])}>
        <CssBaseline />
        <AppBar className={classes.appBarUerre}>
            <Toolbar className={clsx(classes.toolbar, 'container')}>
                <img src="/images/vocational-test/uerre-logo.png" className={classes.logo}/>
            </Toolbar>
        </AppBar>
            <main className={classes.content}>
                <div className={classes.drawerHeader} />
                <Box className={classes.img}>
                    <img src={banner} className={classes.imgBanner}/>
                </Box>
                <Container maxWidth="sm">
                    {children}
                </Container>
            </main>
        </Box>     
    );
};

export default UerreLayout;