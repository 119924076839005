const PATH_NAME_DETECTOR_FOR_SHOW_INFO = [
    "crear",
    "editar",
];

const LABEL_ACTIVE_INACTIVE = [
    "Activar",
    "Desactivar",
]

export  {
    PATH_NAME_DETECTOR_FOR_SHOW_INFO,
    LABEL_ACTIVE_INACTIVE,
};

