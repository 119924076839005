import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// redux
import { connect } from "react-redux"

// @material-ui/core
import { makeStyles, } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// shared components
import Loader from '../../../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../../../shared/components/Breadcrumb/Breadcrumb';
import InputFile from '../../../../../shared/components/Form/InputFile';
import Toast from '../../../../../shared/components/Toast/Toast';
import { log } from '../../../../../shared/utils/console.js'

// services
// import * as marketService from '../../../../../services/MarketPlaceService';
// import * as globalserv from '../../../../../services/GloabalService';
// import * as globalserv from '../../../../../services/GlobalService';
import * as coursesService from '../../../../../services/CoursesService';

import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';

// components
import SectionList from './SectionList/SectionList';
import CourseSchedules from './CourseSchedules/CourseSchedules';
import Accordion from '../Accordion/Accordion';

//SDK
import { GlobalService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const useStyles = makeStyles((theme) => ({
    switch: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    paper: {
        overflow: "hidden",
    }
}));

const languageOptions = [
    { id: "es", name: "Español" },
    { id: "en", name: "Inglés" }
];

const CourseEdit = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [loadMessage, setLoadMessage] = React.useState("");
    const [levelOptions, setLevelOptions] = React.useState([]);
    const [modalityOptions, setModalityOptions] = React.useState([]);
    const [instructors, setInstructors] = React.useState([]);
    const [learningStyleOptions, setLearningStyleOptions] = React.useState([]);
    const tempHidden=true;
    const [toastProps, setToastProps] = React.useState({
        message: "",
        open: false
    });
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const [breadcrumbItems, setBreadcrumbItems] = React.useState([
        { to: '/courses/management', label: 'Administración de Cursos' },
        { label: 'Editar curso' }
    ]);
    const { user } = props;
    
    const getData = async () => {
        try {
            setLoading(true);

            var course = await marketService.getCourse(id, null);
            //log("START LOAD FILES");
            log("curso seleccionado: ",course);
            if (course.file_id) {
                const file = await GlobalServiceSDK.getFile(course.file_id);
                if (file) {
                    course.image = {
                        name: file.file_name
                    };
                }
            }

            if (course.file_card_id) {
                const file_card = await GlobalServiceSDK.getFile(course.file_card_id);
                if (file_card) {
                    course.card_image = {
                        name: file_card.file_name
                    };
                }
            }
            course.audience=course.audience;
            course.objective=course.objective;
            setForm({
                ...course,
                price: parseFloat(course.price_ || "0.00").toFixed(2)
            });

            const levelData = await GlobalServiceSDK.getCatalog("CourseDifficultyLevelCat");
            setLevelOptions(levelData);
            const modalityData = await GlobalServiceSDK.getCatalog("CourseModalityCat");
            setModalityOptions(modalityData);
            const instructorsData = await GlobalServiceSDK.getCatalog("InstructorCat");
            setInstructors(instructorsData);
            const learningStyleData = await GlobalServiceSDK.getCatalog("CourseLearningStyleCat");
            // log("tipos de enseñanza: ",learningStyleData);
            setLearningStyleOptions(learningStyleData);

            setLoading(false);
            setBreadcrumbItems(prev => {
                return [
                    { ...prev[0] },
                    { ...prev[1], title: course.name }
                ];
            });
        } catch (e) {
            setLoading(false);
            log(e.message);
        }
    };

    React.useEffect(() => {
        if (!id) {
            history.push("/home");
        } else {
            getData();
        }
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name == "learning_style_id") {
            if (value == "Sincrónico") { } else { form.instructor_id = -1; }
        }
        if (value) {
            const _errors = { ...errors };
            delete _errors[name];

            setErrors(_errors);
        }

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const handleChangeImage = (fileList) => {
        const { name, value } = fileList;
        // log(value);
        setForm(prev => { return { ...prev, [name]: value } });
    }

    const handleChecked = (e, checked) => {
        const { name } = e.target;
        setForm(prev => { return { ...prev, [name]: checked } });
    };

    const handleListChange = (name, values) => {
        setForm(prev => { return { ...prev, [name]: values } });
    };

    const handleFinishedToast = () => {
        setToastProps(prev => { return { ...prev, open: false } });
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['name'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo requerido';
            }
        }

        setErrors({ ..._errors });

        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleSubmit = async () => {
        if (validate()) {
            const file = form.image;
            const file_card = form.card_image;
            const file_asset = form.course_url;
            const body = {
                ...form,
                person_id: user.person_id
            };
            delete body.image;
            delete body.card_image;
            delete body.course_url;
            try {
                setLoading(true);
                if (file instanceof File) {
                    await GlobalServiceSDK.deleteFile(form.file_id);

                    const location = `market-place/courses/${form.company_id}/course-${form.group_id}/landing`;
                    const fileResult = await GlobalServiceSDK.uploadFile(file, location, 'course-landing', form.group_id, 'file');
                    body['image_url'] = fileResult.path;
                }
                if (file_card instanceof File) {
                    // await globalService.deleteFile(form.file_card_id);
                    
                    // const location = `market-place/courses/${form.company_id}/course-${form.group_id}/card`;
                    // const fileResult = await globalService.uploadFile(file_card, location, 'course-card', form.group_id, 'file_card');
                    // body['card_image_url'] = fileResult.path;
                    // await GlobalServiceSDK.deleteFile(form.file_card_id);
                    const location = `market-place/courses/${form.third_party_id}/courses/card`;
                    const fileResult = await GlobalServiceSDK.uploadFile(file_card, location, 'course-card', null, 'file_card');
                    // const fileResult = await globalserv.uploadFile(file_card, location, 'course-card', null, 'file_card');
                    body['image_url'] = fileResult.path;

                }
                if (file_asset instanceof File) {
                    if (form.scorm_course_id) {
                        setLoadMessage("Subiendo archivo...");
                        const updateCourse = await coursesService.updateCourseFile(file_asset, form.scorm_course_id);
                        log("UPDATE COURSE-------------------->");
                        log(updateCourse);
                    }
                }

                body['price'] = body['price'] || "0.00";
                body['is_active'] = body['is_active'] ? 1 : 0;
                body['is_sandbox'] = body['is_sandbox'] ? 1 : 0;
                setLoadMessage("Configurando datos...");
                const result = await marketService.updateCourse(id, body);
                // setForm({ ...result.course });
                
                setForm({
                    ...result.course,
                    price: parseFloat(result.course.price_ || "0.00").toFixed(2)
                });

                setToastProps({ open: true, message: "El curso ha sido actualizado exitosamente." });
                setLoading(false);
            } catch (e) {
                setToastProps({ severity: "error", open: true, message: "Ocurrió un error al actualizar el curso. Por favor intenta de nuevo." });
                setLoading(false);
                log(e.message);
            }
        }
    };

    return (
        <Box>
            <Breadcrumb items={breadcrumbItems} />
            <Loader loading={loading} message={loadMessage}>
                <React.Fragment>
                    <Card>
                        <CardContent>
                            <Typography variant="subtitle1" className="mb-2">Información general</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="name"
                                        label="Nombre"
                                        required
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.name || ""}
                                        error={Boolean(errors.name)}
                                        helperText={errors.name}
                                        onChange={handleChange} 
                                        inputProps={{ maxLength: 255 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="company_name"
                                        label="Institución"
                                        required
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.company_name || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="source_name"
                                        label="Origen de datos"
                                        required
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.source_name || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} className={classes.switch}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="is_active"
                                                    size="medium"
                                                    checked={form.is_active || false}
                                                    onChange={handleChecked}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'Activo' }}
                                                />
                                            }
                                            label="Activo"
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="description"
                                        label="Descripción"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        variant="outlined"
                                        value={form.description || ""}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="objective"
                                        label="Objetivo"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        variant="outlined"
                                        value={form.objective || ""}
                                        onChange={handleChange} 
                                        inputProps={{ maxLength:250 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="audience"
                                        label="¿A quién va dirigido?"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        variant="outlined"
                                        value={form.audience || ""}
                                        onChange={handleChange} 
                                        inputProps={{ maxLength:250 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="url_course"
                                        label="Url del Curso"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        variant="outlined"
                                        value={form.url_course || ""}
                                        onChange={handleChange} 
                                        inputProps={{ maxLength:255 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <InputFile
                                        accept="image/*"
                                        placeholder="Agrega una imagen"
                                        btnText="Card"
                                        name="card_image"
                                        value={form.card_image}
                                        onChange={(e) => handleChangeImage(e.target)} />
                                </Grid>
                                {/* <Grid item xs={12} md={4}>
                                    <InputFile
                                        accept="image/*"
                                        placeholder="Agrega una imagen"
                                        btnText="Landing"
                                        name="image"
                                        value={form.image}
                                        onChange={(e) => handleChangeImage(e.target)} />
                                </Grid>
                                {
                                    form.scorm_course_id &&
                                    <Grid item xs={12} md={4}>
                                        <InputFile
                                            accept="/*"
                                            placeholder="Agrega un curso"
                                            btnText="Curso"
                                            name="course_url"
                                            value={form.course_url}
                                            onChange={(e) => handleChangeImage(e.target)} />

                                    </Grid>
                                } */}
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="learning_style_id"
                                        label="Tipo Enseñanza"
                                        required
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={form.learning_style_id || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        onChange={handleChange}
                                    >
                                        {learningStyleOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="lang"
                                        label="Idioma"
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={languageOptions.filter(el => el.id === form.lang)[0]?.id || ""}
                                        onChange={handleChange}
                                    >
                                        {languageOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="difficulty_level_id"
                                        label="Nivel Programa"
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={form.difficulty_level_id || ""}
                                        onChange={handleChange}
                                    >
                                        {levelOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="platform"
                                        label="Plataforma"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.platform || ""}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {
                                    form.learning_style_id === 1 && <Grid item xs={12} md={4}>
                                        <TextField
                                            name="instructor_id"
                                            label="Instructor"
                                            select
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            value={form.instructor_id || ""}
                                            onChange={handleChange}
                                        >
                                            {
                                                instructors.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>{option.full_name}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="modality_id"
                                        label="Modalidad"
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={form.modality_id || ""}
                                        onChange={handleChange}
                                    >
                                        {modalityOptions.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="duration"
                                        label="Duración"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.duration || ""}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {/* {form.learning_style_id === 1 ?
                                    <React.Fragment>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                name="frequency"
                                                label="Frecuencia"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={form.frequency || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                name="schedule"
                                                label="Horario"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={form.schedule || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </React.Fragment> :
                                } */}
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            name="price"
                                            label="Precio"
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            onBlur={() => {
                                                handleChange({
                                                    'target': {
                                                        'name': "price",
                                                        'value': parseFloat(form.price || "0.00").toFixed(2),
                                                    }
                                                });
                                            }}
                                            value={form.price || "0.00"}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            name="list_price"
                                            label="Precio de Lista"
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            onBlur={() => {
                                                handleChange({
                                                    'target': {
                                                        'name': "list_price",
                                                        'value': parseFloat(form.list_price || "0.00").toFixed(2),
                                                    }
                                                });
                                            }}
                                            value={form.list_price || "0.00"}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                {/* <Grid item xs={12} md={2} className={classes.switch}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="is_sandbox"
                                                    size="medium"
                                                    checked={form.is_sandbox || false}
                                                    onChange={handleChecked}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'Modo Sandbox' }}
                                                />
                                            }
                                            label="Modo Sandbox"
                                        />
                                    </FormGroup>
                                </Grid> */}
                            </Grid>
                            <Grid container spacing={2} className="mt-3">
                                {form.learning_style_id === 1 ?
                                    <Grid item xs={12} md={12}>
                                        <Accordion id="instances" title="Fechas del curso" form={form}>
                                            <CourseSchedules data={form.instances} />
                                        </Accordion>
                                    </Grid> : null
                                }
                                <Grid item xs={12} md={12}>
                                    <Accordion id="tools" title="Herramientas a utilizar" form={form}>
                                        <SectionList
                                            id="tools"
                                            fieldName="description"
                                            items={form.tools}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="benefits" title="Beneficios" form={form}>
                                        <SectionList
                                            id="benefits"
                                            fieldName="description"
                                            items={form.benefits}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="skills" title="Habilidades" form={form}>
                                        <SectionList
                                            id="skills"
                                            fieldName="description"
                                            items={form.skills}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="requirements" title="Requisitos para acreditar curso" form={form}>
                                        <SectionList
                                            id="requirements"
                                            fieldName="description"
                                            items={form.requirements}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="advantages" title="Ventajas Competitivas" form={form}>
                                        <SectionList
                                            id="advantages"
                                            fieldName="description"
                                            items={form.advantages}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion id="modules" title="Temas y Módulos" form={form}>
                                        <SectionList
                                            id="modules"
                                            fieldName="description"
                                            nestedProp="topics"
                                            maxNestingLevel={2}
                                            items={form.modules}
                                            onChange={handleListChange}
                                        />
                                    </Accordion>
                                </Grid>
                            </Grid>
                            <Box className="mt-4">
                                <Button color="primary" variant="contained" onClick={handleSubmit}>Guardar cambios</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </React.Fragment>
            </Loader>
            <Toast {...toastProps} onFinished={handleFinishedToast} duration={3000} />
        </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CourseEdit);