import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    cardRoot: {
        height: '158px',
        background: props => `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(270deg, rgba(0, 0, 0, 0) 41.31%, rgba(0, 0, 0, 0.64) 100%), url(${props.imageUrl})`,
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center !important',
        backgroundSize: 'cover !important',
    }, 
    cardContentRoot: {
        padding: '16px !important',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    nameCourse: {
        display: '-webkit-box !important',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal',
        overflow: 'hidden'
    },
    iconButton: {
        marginLeft: '4px',
    },
    buttonGoToCourse: {
        width: 'fit-content',
        color: theme.palette.colors.gray[100],
    }
}));