import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import * as paths from '../../../src/routes/paths';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// shared components
import Loader from '../../shared/components/Loader/LoaderContent';
import FAQsComponent from '../../shared/components/FAQsComponent/FAQsComponent';
import { getPreviousMembership } from '../../shared/utils/membershipUtils';
import { log } from '../../shared/utils/console.js'
import { eventTracker } from '../../shared/components/EventTracker/EventTracker';

// components
import MembershipPlans from './MembershipPlans/MembershipPlans';
import MembershipPlanCard from './MembershipPlans/MembershipPlanCard/MembershipPlanCard';
import MembershipBenefits from './MembershipBenefits/MembershipBenefits';
import MembershipHeaders from './MembershipBenefits/MembershipHeaders/MembershipHeaders';
import MembershipCarousel from './MembershipCarousel/MembershipCarousel';
import MembershipPlanCardMedia from './MembershipPlans/MembershipPlanCard/MembershipPlanCardMedia';
import SpinPopup from './components/SpinPopup/SpinPopup';

import { getSessionCookie } from "../../shared/utils/Sessions";

// Utils
import objectIsEmpty from "../../shared/utils/objectIsEmpty";

// services
import { MarketPlaceService, MembershipService } from '@sdk-point/talisis';

// styles
import useStyles from '../../assets/styles/jss/pages/memberships/membershipsStyle'

function PromotionalTitle(){
    return (
        <Box textAlign='center' margin='auto'>
            <Typography variant='h2'>
                Adquiere un 🔥 20% de descuento adicional en cualquiera de nuestras membresías en el&nbsp;
                <span className='text-blue-300'>Plazo Anual</span> usando el código: <span className='text-orange-300'>BACK2024</span>
            </Typography>
        </Box>
    )
}

const Memberships = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [memberships, setMemberships] = React.useState([]);
    const [membershipPlansD, setMembershipPlansD] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [faqList, setFaqList] = React.useState([]);
    const [slideIndex, setSlideIndex] = React.useState(0);
    const [feePlan, setFeePlan] = React.useState("ANU");
    const user = useSelector((state) => state.userReducer.user);
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const [membershipLevel, setMembershipLevel] = React.useState(0);
    const [membershipTerm, setMembershipTerm] = React.useState(0);

    const [pageState, setPageState] = React.useState({
        productId: null,
        productTypeId: null,
        membershipId: null,
        membershipFeeId: null,
        membershipLevel: 0,
    });
    const history = useHistory();
    const [spinPopup, setSpinPopup] = React.useState(null)

    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)

    const getMemberships = async () => {
        try {
            setLoading(true);
            const personMembership = await marketService.getPersonMembership(isLogged ? user.person_id : null);
             if (personMembership?.memberships?.length > 0) {
                const memberships = personMembership?.memberships.filter(m => m.is_active && m.level > 0);
                 setMemberships(memberships);
            }
             setMembershipLevel(personMembership?.user_membership && isLogged ? personMembership?.user_membership?.next_membership?.level : 0);
            setMembershipTerm(personMembership?.user_membership && isLogged ? personMembership?.user_membership?.membership_term_months ?? 0 : 0);
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            log(e.message);
        }
    };

    const getMembershipsPlans = async () => {
        try {                                                                              //months 3,6,12
            const responseGetMembershipsPlans = await membershipService.getMembreshipFees([3,6,12]);
            setMembershipPlansD(responseGetMembershipsPlans);
        } catch (error) {
            log(error);
        } 
    }

    React.useEffect(() => {
        const searchParams = new URLSearchParams(props.location.search);
        const spinParam = searchParams.get('spin');
        setSpinPopup(spinParam)
        const custom_membership_ids=[14,20,21,22];

        if(custom_membership_ids.includes(user.membership_id))
        {
            history.push(paths.HOME);            
        }
        getMembershipsPlans();
        getMemberships();
        if (location.state) {
            setPageState({ ...location.state });
        }

        const eventData = {};
        eventTracker('s_planes', eventData, ['mixpanel', 'hubspot', 'google']);
    }, []);

    const handleMembershipPlanClick = (value) => {
         setFeePlan(value);
    };

    const handleChangeSlideIndex = (index) => {
        setSlideIndex(index);
    };

    const handleCloseSpinPopup = () => setSpinPopup(false);
    return (
        <Box className={classes.membershipsRoot}>
            <SpinPopup open={spinPopup} onClose={handleCloseSpinPopup}/>
            {/* <Box className={classes.containerPrincipalLabel}>
                <Typography variant='h2'>
                    Conoce nuestras membresías y ahorra realizando pago anticipado
                </Typography>
            </Box> */}
            <PromotionalTitle />
                
            <Loader loading={loading} minHeight="500px">
                <React.Fragment>
                    <MembershipPlans onClick={handleMembershipPlanClick} membershipTerm={membershipTerm} membershipPlansD={membershipPlansD}/>
                    <Box className={classes.cardSection}>
                        <Grid container spacing={isResponsive ? 2 : 3} justifyContent='center'>
                            {
                                memberships.map((membership, index) => {
                                    if (membership.level >= membershipLevel) {
                                        return (
                                            <Grid key={index} item className={classes.grid}>
                                                <MembershipPlanCard
                                                    id={membership.slug}
                                                    name={membership.name}
                                                    popular="professional" 
                                                    fees={membership?.fees} 
                                                    membershipId={membership.id}
                                                    feePlan={feePlan}
                                                    media={{
                                                        showIcon: true,
                                                        fee: membership?.fees?.filter(fee => fee.term_slug === feePlan)[0]
                                                    }}
                                                    content={{
                                                        previous: getPreviousMembership(membership, memberships)
                                                    }}
                                                    user={user}
                                                    isResponsive={isResponsive}
                                                />
                                            </Grid>
                                        )
                                    }
                                    return null;
                                })
                            }
                        </Grid>
                        <Hidden smUp implementation='css'>
                            <Box mt={ '56px' }>
                                <Typography variant="h2" align='center' className="title">Compara características</Typography>
                            </Box>
                            <Box mt={ '32px' }>
                                <MembershipCarousel
                                    memberships={memberships}
                                    membershipLevel={membershipLevel}
                                    membershipTerm={membershipTerm}
                                    pageState={pageState}
                                    feePlan={feePlan}
                                    onChangeIndex={handleChangeSlideIndex}
                                    isResponsive={isResponsive}
                                    user={user}
                                />
                            </Box>
                        </Hidden>
                    </Box>
                    <Hidden xsDown implementation='css'>
                        <Box mt={'80px'}>
                            <Typography variant="h2" align='center' className="title">Compara características</Typography>
                        </Box>
                        <Box mt={'40px'}>
                            <MembershipHeaders data={
                                memberships.map((membership) => {
                                    const fee = membership.fees.filter(fee => fee.term_slug === feePlan)[0];
                                    const result = {};

                                    result.id = membership.slug;
                                    result.component = <MembershipPlanCardMedia id={membership.slug} name={membership.name} fee={fee} feePlan={feePlan} fees={membership.fees} membershipId={membership.id} popular="professional" user={user} compareFeatures={true} hideButton={membership.level >= membershipLevel ? false : true} />

                                    return result;
                                })
                            } />
                        </Box>
                    </Hidden>
                    <Box mt={'32px'} mb={'64px'}>
                        <MembershipBenefits currentMembership={memberships[slideIndex]} />
                    </Box>
                    {
                        faqList.length>0
                        ?
                        <Box mt={'136px'}>
                            <FAQsComponent
                                title={"Preguntas frecuentes"}
                                questionsList={faqList}
                                innerRef={null}
                            />
                        </Box>
                        :
                        null
                    }
                </React.Fragment>
            </Loader>
        </Box>
    );
};

export default Memberships;