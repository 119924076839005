import React, { useCallback, useEffect, useState } from 'react'

import { Grid, Hidden, Input, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import { connect, useSelector } from 'react-redux';

import { AlliesLogos } from './AlliesLogos/AlliesLogos'
import AlliesCardContainer from './AlliesCardContainer/AlliesCardContainer';

//styles
import useStyles from '../../../assets/styles/jss/pages/market/AlliesLandingPageStyle'

// Helpers
import { debounce } from './helpers/debounce';

// SDK
import { MarketPlaceService, CourseService } from '@sdk-point/talisis';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { eventTracker, eventData } from '../../../shared/components/EventTracker/EventTracker';

// Functions
import clsx from 'clsx';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const AlliesLandingPage = (props) => {
    const abortData = new AbortController();

    const { name } = useParams();
    const { person_id } = useSelector((state) => state.userReducer.user);

    const currentAllyBanner = AlliesLogos(name).allyBanner;
    const currentAllyName = AlliesLogos(name).allyName;
    const currentAllyId = AlliesLogos(name).allyId;

    const classes = useStyles({currentAllyBanner});

    const [currentAlly, setCurrentAlly] = useState({
        isLoading: false,
        allyInfo: [],
        fetchedCourses: [],
        totalCourses: 0
    });

    const { isLoading, fetchedCourses, totalCourses } = currentAlly;

    const [filteredCourses, setFilteredCourses] = useState([]);

    const sendCourseraEventToMixPanel=()=>{
        eventTracker('s_coursera', eventData, ['mixpanel']);
    };

    const sendCrehanaEventToMixPanel=()=>{
        eventTracker('s_crehana', eventData, ['mixpanel']);
    };

    const sendHubSpotEventToMixPanel=()=>{
        eventTracker('s_hubspot', eventData, ['mixpanel']);
    };

    const sendGoogleEventToMixPanel=()=>{
        eventTracker('s_google_skillshop', eventData, ['mixpanel']);
    };

    const sendlinkedinEventToMixPanel=()=>{
        eventTracker('s_linkedin', eventData, ['mixpanel']);
    };

    const eventMapping = {
        'coursera': sendCourseraEventToMixPanel,
        'crehana': sendCrehanaEventToMixPanel,
        'hubspot-academy': sendHubSpotEventToMixPanel,
        'google-skillshop': sendGoogleEventToMixPanel,
        'linkedin-learning': sendlinkedinEventToMixPanel
        // Agrega más mapeos si es necesario
      };

    useEffect(() => {
      if( totalCourses > 0 && !isLoading){
          const sliceTotalCourses = fetchedCourses    
          setFilteredCourses(sliceTotalCourses)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(() => {
        if (eventMapping.hasOwnProperty(name)) {
            eventMapping[name]();
        }
      }, []);

    const filterCourseBody={
        course: "",
        filters:{
            skills: [] ,
            educativeOffer: [],
            schools: [currentAllyId],
            modalities: [],
            companies: []
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const doFetch = async() => {
            setCurrentAlly({ isLoading: true })

            const fetchedAllies = await courseService.getCoursesAliados();
            const alliesArray = await fetchedAllies.response;
            const currentAllyInfo = await alliesArray.filter( ally => ally.id == currentAllyId );

            let coursesFetchedInfo;
            if( currentAllyInfo.length > 0 ){
                coursesFetchedInfo = await marketService.searchDataV2(
                {
                    "search":"",
                    personId: props.user.person_id,
                    "academies":[],
                    "educativeOffer":["CUR"],
                    "sources":[currentAllyId],
                    "modalities":[],
                    "companies":[]
                },  null, abortData);
            }
             
            let coursesArray = coursesFetchedInfo.data[0].filter(it => it.hasOwnProperty('id')).map(course =>({
                ...course,
                source_id: courseService.source,
                priceMembershipActions: true,
                price: {
                    number: course.price,
                    listNumber: course.list_price,
                },
                image_url: course.image,
            }));
            if( coursesArray.length === 0 )
                return;

            setCurrentAlly({
                ...currentAlly,
                isLoading: false,
                fetchedCourses: coursesArray,
                allyInfo: currentAllyInfo,
                totalCourses: currentAllyInfo[0].total_cursos
            });
        };
        doFetch();
    }, []);

    const handleInputChange = (searchedValue) => {
        const doFetchSearch = async() => {
            setCurrentAlly({ isLoading: true })
            const coursesFetchedInfo = await marketService.searchDataV2(
                {
                    "search": searchedValue,
                    personId: props.user.person_id,
                    "academies":[],
                    "educativeOffer":["CUR"],
                    "sources":[currentAllyId],
                    "modalities":[],
                    "companies":[]
                },  null, abortData);

            let coursesArray = coursesFetchedInfo.data[0].filter(it => it.hasOwnProperty('id')).map(course =>({
                ...course,
                source_id: courseService.source,
                priceMembershipActions: true,
                price: {
                    number: course.price,
                    listNumber: course.list_price,
                },
                image_url: course.image,
            })); 
            setFilteredCourses(coursesArray)
            setCurrentAlly({ isLoading: false })
        }
        doFetchSearch();
    }

    const optimizedFn = useCallback(
        debounce(handleInputChange), []
    );

    return (
        <>
            <Grid item className={clsx("mt-2", classes.rootGrid)} style={{backgroundImage: `url(${currentAllyBanner})`}} />
            <LoaderContent loading={isLoading} className={classes.loaderMain}>
                <Grid container className={classes.containerHeader}>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                            <Grid item xs={12} className={classes.marginsTittles}>
                                                <Typography variant="h2">
                                                    Cursos { currentAllyName }
                                                </Typography>
                                            </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.buscadorContainer}>
                                    <TextField
                                        id="standard-full-width"
                                        name='searchValue'
                                        onChange={ (e) => optimizedFn(e.target.value, fetchedCourses) }
                                        placeholder="¿Qué quieres aprender hoy?"
                                        variant="outlined"
                                        fullWidth
                                        className={classes.buscadorStyle}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start" style={{ fontSize: "1.5em" }}>
                                                <i className="ri-search-line text-dark-black-200 font-size-24" />
                                            </InputAdornment>
                                            ),
                                            endAdornment:(
                                            <InputAdornment position="start" style={{ fontSize: "1.5em" }}>
                                                <i className="ri-filter-2-line text-dark-black-100 font-size-24" />
                                            </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.resultadosContainer}>
                                    <Typography variant="body1" align="right" className='text-fill-stroke-100'>
                                        Resultados { filteredCourses.length ? filteredCourses.length : 0 }
                                    </Typography>
                                </Grid>
                    {
                        (filteredCourses.length > 0 ) &&
                        <AlliesCardContainer
                            isLoading={isLoading} 
                            userProps={props} 
                            coursesArray={ filteredCourses }
                        />
                    }
                </Grid>
            </LoaderContent>
            {/* <Footer /> */}
        </>
    )
}

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(AlliesLandingPage);
