import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as recordStatus from '../../../shared/constants/record-status';

import getImageFromAssets from '../../../design-system/utils/getImageFromAssets'

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionSummary:{
    borderRadius: 0,
    paddingLeft: 11,
    '& .MuiAccordionSummary-content':{
        order: 2,
    },
    '& .MuiIconButton-edgeEnd':{
        margin: 0
    }
  },
  accordionDetails:{
    padding: 0,
    flexDirection: 'column'
  },
  [theme.breakpoints.down('sm')]:{
    title:{
      ...theme.typography.bodyTextSmallSemiBold
    },
    subtitle:{
      width: 'auto',
      '& .MuiTypography-root':{
        ...theme.typography.bodyTextXsmallRegular
      }
    },
    accordionSummary:{
      padding: '0px 16px 0px 3px',
    },
  }
}));

export default function ListDocuments({children, title, subtitle, open, status, noData = true}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(()=>{
    setExpanded(open)

  }, [])

  const Mssg = ({image, title, subtitle}) => {
    if(!noData) return null;

        return <Box p={3} textAlign='center'>
        <img src={getImageFromAssets(image)} />
            <Typography variant='body1' className='text-gray-100 semi-bold'>{title}</Typography>
            <Typography variant='body2' className='text-gray-100'>{subtitle}</Typography>
        </Box>
    }

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded} onChange={() => setExpanded(prev => !prev)}>
                <AccordionSummary
                    className={classes.accordionSummary}
                expandIcon={<Icon className='ri-arrow-up-s-line text-white font-size-24' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Box display='flex' width={1}>
                        <Typography variant='body1' style={{flex:1}} className={clsx('semi-bold', classes.title)}>{title}</Typography>
                        <Box width={191} className={classes.subtitle}>
                            <Typography variant='body2' align='right' className='text-blue-300'>{subtitle}</Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                <Mssg {...recordStatus.MSSG_BY_STATUS[status]} />
                {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
