import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material components
import { Box, Button, Chip, CircularProgress, Icon, IconButton, Typography, makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    rootStatusCourseContent: {
        height: props => props.isResponsive ? "172px" : "204px",
        width: "100%",
        padding: props => props.isResponsive ? "12px" : "16px",
        display: "flex",
        flexDirection: "column",
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    courseNameContainer: {
        flexGrow: 1,
    },
    courseName: {
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal',
        overflow: 'hidden'
    },
    disabledLabelName: {
        color: theme.palette.colors.darkBlack[200],
    }, 
    dateLabelAvailable: {
        textTransform: "uppercase",
    }
}));

const DisabledCourseContent = ({ content, isResponsive }) => {
    const classes = useStyles({isResponsive});

    return (
        <Box className={classes.rootStatusCourseContent}>
            <Box className={classes.headerContainer}>
                <Chip label="CURSO" className={"chip3-blue-500-inverted"} />
            </Box>
            <Box className={classes.courseNameContainer}>
                <Typography variant="body1" className={clsx("semi-bold", isResponsive && "variant-xsmall", classes.courseName, classes.disabledLabelName)}>{content.courseName}</Typography>
            </Box>
            <Box mt="8px" height="82px">
                {
                    !!content.dateAvailableFrom &&
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                        <Chip label="DISPONIBLE A PARTIR DE:" className={clsx("chip3-blue-300-inverted", isResponsive && "font-size-10")} />
                        <Box display="flex" alignItems="center" justifyContent="center" mt="4px">
                            <Icon className={clsx("ri-calendar-todo-line", "font-size-16", "offset-margin-right-8")}/>
                            <Typography variant='overline' className={clsx("semi-bold", isResponsive && "variant-overline-sm", classes.dateLabelAvailable)}>{content.dateAvailableFrom}</Typography>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    );
};

DisabledCourseContent.propTypes = {
    content: PropTypes.shape({
        courseName: PropTypes.string.isRequired,
        dateAvailableFrom: PropTypes.string,
    }).isRequired,
    isResponsive: PropTypes.bool,
};

export default DisabledCourseContent;