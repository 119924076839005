import * as Cookies from "js-cookie";

export const defaultCartCookie = {
    session_id: null
}

export const setCartCookie = (session = defaultCartCookie) => {
    Cookies.remove('cart');
    Cookies.set('cart', session, { expires: undefined });
};

export const getCartCookie = () => {
    const cartCookie = Cookies.get('cart');

    if (!cartCookie) {
        return {};
    } else {
        return JSON.parse(cartCookie);
    }
};

export const clearCartCookie = () => {
    setCartCookie({...defaultCartCookie});
};