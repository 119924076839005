import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// reducers 
import { applyCart, applyCartProducts } from '../../../../redux/actions/cartActions.js';

// @material-ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button, Card, CardContent, CircularProgress, Typography } from '@material-ui/core';

// shared
import { addProductsToCart } from '../../../../shared/utils/cartHelper.js';
import { log } from '../../../../shared/utils/console.js'

// SDK
import { ProductService, PRODUCT_TYPES_NAME } from '@sdk-point/talisis';

const productService = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const CartTest = () => {
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state.cartReducer);
    const [loading, setLoading] = React.useState(false);
    const [productEntries, setProductEntries] = React.useState([]);

    const groupByProductTypeId = (productArray) => {
        return productArray.reduce((acc, product) => {
            // Crea una clave para cada product_type_id si no existe
            if (!acc[product.product_type_id]) {
                acc[product.product_type_id] = [];
            }
            // Agrega el producto al arreglo correspondiente a su product_type_id
            acc[product.product_type_id].push(product);
            return acc;
        }, {});
    };

    const getProducts = async () => {
        try {
            setLoading(true);
            const response = await productService.getProducts();
            const groupedProducts = groupByProductTypeId(response);
            const productEntries = Object.entries(groupedProducts);
            setProductEntries(productEntries);
            setLoading(false);
        } catch (error) {
            log("error al obtener productos ", error);
        }
    };

    React.useEffect(() => {
        getProducts();
    }, []);

    const handleClick = async (product) => {
        let response = await addProductsToCart({
            cart_id: cart.id,
            products: [{
                "product_id": product.id,
                "quantity": 1,
                "product_price_id": product.product_price_id,
                "unit_price": product.list_price,
                "discount": 0
            }]
        });
        dispatch(applyCart(response))
        dispatch(applyCartProducts(response.cart_details))
    };

    return <Box className='mt-4'>
        <Box className='mb-3'>
            <Typography variant='h3' className="text-violet-300">Lista de productos</Typography>
        </Box>
        <Card variant='outlined'>
            <CardContent className="px-4 pt-3 pb-4">
                {loading && <Box>
                    <CircularProgress color='primary' />
                </Box>}
                <Grid container direction='row' spacing={2}>
                    {productEntries.map(([productTypeId, products]) => {
                        return <Grid key={productTypeId} item container direction='column' spacing={2} xs={12} md={3}>
                            <Grid item>
                                <Typography variant="h4">{PRODUCT_TYPES_NAME[productTypeId]}s</Typography>
                            </Grid>
                            {products.map(product => {
                                return <Grid key={product.id} item>
                                    <Button variant="outlined" color="secondary" fullWidth onClick={() => handleClick(product)}>{product.name}: ${product.list_price} MXN</Button>
                                </Grid>
                            })}
                        </Grid>
                    })}
                </Grid>
            </CardContent>
        </Card>
    </Box>
};

export default CartTest;