import mixpanel from 'mixpanel-browser';
import { getSessionCookie } from "../../utils/Sessions"
import { log } from '../../../shared/utils/console.js'

// const config = {
//     api_host: "https://api.mixpanel.com",
//     loaded: 
// }
const session = getSessionCookie();
let user_id

//Inicializacion de mixpanel a KEY relacionada por ambiente
mixpanel.init(process.env.REACT_APP_CLIENT_MIXPANEL, {
    api_host: "https://api.mixpanel.com",
    loaded: function(mixpanel) {
        user_id = mixpanel.get_property('name');
    }
});


// register once, only for the current pageload
mixpanel.register_once({
    'First interaction time': new Date().toISOString()
}, 'None', {persistent: false});

//Obtiene los datos de la sesión del usuario

let actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    track: async (name, props) => {
        try{
        await mixpanel.track(name, props);
        // console.log(user_id)
        } catch (error) {
        log("ERROR MIXPANEL", error);
        }    
    },
    people: (data) =>{
        mixpanel.people.set(data);
    },
    time_event: (eventName) => {
        mixpanel.time_event(eventName);
    },
    get_property: (eventName) => {
        mixpanel.get_property(eventName);
    },
    reset: () => {
        mixpanel.reset();
    }
};
  
let Mixpanel = actions;

const trackEventMixPanel = (eventName, properties = {}) => {
    const role = JSON.parse(localStorage.getItem('permissions'));
    const eventData = {
        userId: session.person_id || "",
        email: session.email || "",
        unidadOperativa: session.ou || "",
        gender: session.gender || "",
        userRole: role && role.length ? JSON.stringify(role[0].role_name).replace(/["']/g, "") : "",//estudiante,maestro,admin
    }
    const data = Object.assign(eventData, properties);
    Mixpanel.track(eventName, data);
};

export{
    Mixpanel,
    trackEventMixPanel
}
