import React from 'react';

// core components
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';

// Forms
import { Menu } from './Forms/Menu/Menu';
import { AddCareer } from './Forms/Career/AddCareer';
import { AddEducation } from './Forms/Education/AddEducation';
import { AddJobHistory } from './Forms/JobHistory/AddJobHistory';
import { Principal } from './Forms/Principal/Principal';

// style
import '../Profile.css';
import Detail from './Forms/Detail/Detail';

const JobAndEducation = ({ career, job_history, education, formation, loading, onChange, onSubmit, afterSubmitProcess }) => {
    const [form, setForm] = React.useState(0);
    const handleSetForm = (form) => {
        setForm(form);
    };

    const handleValidate = (requiredFields) => {
        let error = false;
        if (requiredFields.length > 0) {
            for (const field of requiredFields) {
                let element = document.getElementById(field);
                if (element) {
                    if (element.value === "") {
                        error = true;
                        break;
                    }
                }
            }
        }
        return error;
    };

    const forms = (form) => {
        switch (form) {
            case 1: // Menú.
                return <Menu onSetForm={handleSetForm} />
            case 2: // Forma para añadir profesión.
                return <AddCareer data={career} onSetForm={handleSetForm} onChange={onChange} validate={handleValidate} onSubmit={onSubmit} />
            case 2.1: // Forma para detalle de profesiones.
                return <Detail dataSet="career" detail={formation.career} label={'Profesión'} form={2} onSetForm={handleSetForm} afterSubmitProcess={afterSubmitProcess} />
            case 3: // Forma para añadir historial de empleo
                return <AddJobHistory data={job_history} onSetForm={handleSetForm} onChange={onChange} validate={handleValidate} onSubmit={onSubmit} />
            case 3.1: // Forma para detalle de empleos.
                return <Detail dataSet="job_history" detail={formation.job_history} label={'Trabajos'} form={3} onSetForm={handleSetForm} afterSubmitProcess={afterSubmitProcess} />
            case 4: // Forma para añadir estudios
                return <AddEducation data={education} onSetForm={handleSetForm} onChange={onChange} validate={handleValidate} onSubmit={onSubmit} />
            case 4.1: // Forma para detalle de estudios.
                return <Detail dataSet="education" detail={formation.education} label={'Estudios'} form={4} onSetForm={handleSetForm} afterSubmitProcess={afterSubmitProcess} />
            default: // Forma por principal
                return <Principal data={formation} onSetForm={handleSetForm} validate={handleValidate} />
        }
    };

    return (
        <LoaderContent loading={loading}>
            {forms(form)}
        </LoaderContent>
    );
};

export default JobAndEducation;
