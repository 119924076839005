import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    hideConnector: {
        minHeight: 44,
        '&::before': {
          display: 'none',
        },
    },
    v2:{
        color: theme.palette.colors.orange[300],
        marginBottom:15,
        paddingLeft: 15
    },
    ri_arrow_left_s_line:{
        color: theme.palette.colors.orange[300],
    },
    h3:{
        marginTop:30
    },
    completed_porcentage:{
         height: "8px",
        left: "0px",
        top: "0px",
        background:  theme.palette.colors.white,
        borderRadius: "10px 0px 0px 10px"
    },
    progress_porcentage:{
         height: "8px",
        left: "0px",
        top: "0px",
        background: theme.palette.colors.blue[300], 
        borderRadius: props => !props.program.count_courses_completed && "10px 0px 0px 10px"
        
    },
    pending_porcentage:{
        height: "8px",
        left: "0px",
        top: "0px",
        background: theme.palette.colors.grey[50],
        borderRadius: props => (!props.program.count_courses_completed && !props.program.count_courses_in_process) ? "10px" : "0px 10px 10px 0px"
    },
    completed_circle:{
        color:  theme.palette.colors.white,
    },
    progress_circle:{
        color: theme.palette.colors.blue[300], 
    },
    pendings_circle:{
        color: theme.palette.colors.grey[50],
    },
    accordionSummary: {
        flexDirection: 'row-reverse',
        paddingLeft:'5px',
        background: theme.palette.colors.grey[500]
    },
    chip_status: {
      marginLeft: 5,
      width: 120,
      height: "24px",
      borderRadius: "16px",
      paddingLeft: '2px',
      paddingRight: '2px',
      gap: "4px"
    }, 
    chip_status_finished:{
        background: theme.palette.colors.success[500], 
        border: `1px solid ${theme.palette.colors.success[300]}`,
    },
    chip_status_in_process:{
        background: theme.palette.colors.blue[500],
        border: `1px solid ${theme.palette.colors.blue[300]}`
    },
    root_courses:{
        width: "100%",
        marginLeft: "auto", 
        marginRight: "auto",
    },
    hover_carousel:{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        boxSizing: "border-box",
        width:'100%', 
        gap: 16
    },
    button_ver_catalogo:{
        marginRight: "10px"
    }, 
    button_list_unordered:{
        padding: "8px",
        borderRadius: "4px 0px 0px 4px",
        width: "40px",
        height: "40px" 
    },
    button_function_fill:{
        padding: "8px",
        borderRadius: "0px 4px 4px 0px",
        width: "40px",
        height: "40px" 
    },
    card: {
        padding: "12px",
        maxWidth: "fit-content",
    },
    accordionDetailStyle:{
        padding: "16px 32px",
        display: "block",
        [theme.breakpoints.down('xs')]:{
            padding: 0,
        }
    },
    rootAccordion:{
        marginBottom: 16
    },
    programName:{
        display:"flex",
        flexWrap:"wrap",
        alignContent:"flex-start",
        gap: 8
    },
    boxAccordions:{
        marginTop: 18,
        [theme.breakpoints.down('xs')]:{
            marginBottom: 64
        }
    },
    progressBar:{
        width: 248, 
        gap: 16,
        [theme.breakpoints.down('xs')]:{
            width: 500
        }
    },
    finishedIcon:{
        background: 'none !important'
    },
    boxInfo:{
        margin: '-7px 0px 10px 0px'
    },
    tooltip:{
        border: '1px solid #F2C80B',
    },
    dot: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.colors.fillStroke[300],
        color: theme.palette.colors.grey[200],
        fontSize: '14px',
        margin:'0px',
        '&.done':{
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.colors.success[500]}`
        }
    },
    icon: {
        color: theme.palette.colors.white,
    },
    accordionTitle: {
        padding: '0px 16px',
        borderRadius:'8px',
        gap: '12px',
        backgroundColor: theme.palette.colors.fillStroke[300],
        minHeight:'48px !important',
        '&.Mui-expanded': {
            minHeight:'48px !important',
          },
    },
    accordionDetails: {
        padding: 0,
        backgroundColor: 'transparent',
        '& .MuiTimeline-root':{
            padding: 0
        }
    },
    gridCardClass:{
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    accordionPrimary:{
        backgroundColor: 'transparent !important',
        boxShadow: 'unset !important'
    },
    accordionSummaryTitle:{
        flexDirection: 'row-reverse',
        padding: 0,
        backgroundColor: 'transparent !important',
        gridGap: 16,
        '& .MuiAccordionSummary-expandIcon':{
            padding: 0
        }
    },
    chipProgress:{
        border: `1px solid ${theme.palette.colors.blue[300]}`,
        backgroundColor: theme.palette.colors.blue[500],
        padding: '0px 16px',
        '& .MuiChip-icon':{
            backgroundColor: 'transparent !important'
        }
    },
    chipCompleted:{
        border: `1px solid ${theme.palette.colors.success[300]}`,
        backgroundColor: theme.palette.colors.success[500],
        padding: '0px 16px',
        '& .MuiChip-icon':{
            backgroundColor: 'transparent !important'
        }
    }
}));