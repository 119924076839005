import React from 'react';
import { useSelector } from 'react-redux';

import AddPaymentMethod from './AddPaymentMethod/AddPaymentMethod';
import PaymentMethodList from './PaymentMethodList/PaymentMethodList';
import EditPaymentMethod from './EditPaymentMethod/EditPaymentMethod';

import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

// @SDK
import { UserService } from '@sdk-point/talisis';
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const PaymentMethods = ({ isCheckout = false, isCurrentTab }) => {
    const { user } = useSelector((state) => state.userReducer);

    const [form, setForm] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [customerCards, setCustomerCards] = React.useState([]);
    const [customerCardEdit, setCustomerCardEdit] = React.useState('');
    const [customerSubscriptions, setCustomerSubscriptions] = React.useState([]);

    const getCustomerCards = async () => {
        const cards = await UserServiceSDK.conektaGetCustomerCard(user?.person_id);
        setCustomerCards(cards);
    };

    const getCustomerSubscriptions = async () => {
        const subscriptions = await UserServiceSDK.getPersonSubscriptions(user?.person_id);
        setCustomerSubscriptions(subscriptions);
    };

    const fetchData = async () => {
        await getCustomerCards();
        await getCustomerSubscriptions();
        setLoading(false);
    };

    const handleDeleteCustomerCard = async (payment_source_ids) => {
        if (payment_source_ids) {
            setLoading(true);
            await UserServiceSDK.conektaDeleteCustomerCard(user.person_id, payment_source_ids);
            await getCustomerCards();
            setLoading(false);
        }
    };

    const handleCancelCustomerSubscription = async (id) => {
        setLoading(true);
        await UserServiceSDK.deletePersonSubscription(user.person_id, id);
        await getCustomerSubscriptions();
        setLoading(false);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    React.useEffect(() => {
        if (isCurrentTab) {
            sendMetodoPagosEventToMixPanel();
        }
    }, [isCurrentTab]);

    const sendMetodoPagosEventToMixPanel = () => {
        const eventData = {};
        eventTracker('s_metodo_pagos', eventData, ['mixpanel']);
    };

    const printForm = (action) => {
        switch (action) {
            case 'ADD':
                return <AddPaymentMethod setForm={setForm} fetch={fetchData} getUserCard={getCustomerCards} />;
            case 'EDIT':
                return <EditPaymentMethod setForm={setForm} fetch={fetchData} cards={customerCardEdit} getUserCard={getCustomerCards} />;
            default:
                return <PaymentMethodList isCheckout={isCheckout} loading={loading} setForm={setForm} cards={customerCards} setCardEdit={setCustomerCardEdit} subscriptions={customerSubscriptions} handleDeleteCard={handleDeleteCustomerCard} handleCancelSubscription={handleCancelCustomerSubscription} />;
        }
    };

    return (
        printForm(form)
    )
};

export default PaymentMethods;
