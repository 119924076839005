import React from "react";
//@Material-ui
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
//Styles 
import useStyles from '../../../../../../../assets/styles/jss/pages/memberships/versionSKU/MembershipsContainerCard/MembershipCard/MembershipComboBrandsStyle';

const MembershipCardComboConecta = () => {
    const classes = useStyles();
    return (
        <Box className={classes.boxComboConecta}>
            <Typography variant="body1" className="semi-bold" style={{marginTop:'-12px', width:'217px', background:'#1C1F24', marginLeft:'15px'}} >Acceso a bolsa de trabajo</Typography>
            <Typography variant="body1" className="semi-bold">Conecta</Typography>
        </Box>
    )
}

export default MembershipCardComboConecta; 