import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    bodyContent: {
        borderTop: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        paddingTop: '20px',
    },
    titleContent: {
        paddingBottom: '16px',
    },
    cardContent: {
        padding: '16px 30px 16px 27px'
    }
}));
