import React from 'react';
import { useSelector } from 'react-redux';

import CoursesTaughtHeader from './CoursesTaughtHeader/CoursesTaughtHeader';
import CoursesTaughtDetail from './CoursesTaughtDetail/CoursesTaughtDetail';

// @sdk
import { UserService } from '@sdk-point/talisis';
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const CoursesTaught = () => {
    const { user } = useSelector((state) => state.userReducer);

    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState('');
    const [data, setData] = React.useState([]);
    const [detail, setDetail] = React.useState([]);

    const handleDetail = (e, detailData) => {
        setForm('DETAIL');
        setDetail(detailData);
        localStorage.setItem('scrollPosition', window.scrollY);
    };

    const fetchData = async (viewAll = false) => {
        setLoading(true);
        const classesTaught = await UserServiceSDK.getInstructorClasses(user.person_id, viewAll);
        setData(classesTaught);
        setLoading(false);
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    const printForm = (action) => {
        switch (action) {
            case 'DETAIL':
                return <CoursesTaughtDetail data={detail} setForm={setForm} />;
            default:
                return <CoursesTaughtHeader loading={loading} data={data} fetchData={fetchData} handleDetail={handleDetail} />;
        }
    };

    return (
        printForm(form)
    )
};

export default CoursesTaught;
