import React, { useState } from 'react';

import curp from 'curp';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Typography, Link, Icon, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import WarningBanner from '../../../design-system/components/Banners/WarningBanner.jsx';
import TextFieldStatus from '../../../design-system/components/Form/TextFieldStatus/TextFieldStatus';
import * as inputStatus from '../../../design-system/constants/text-field-status'

import { useCoursesContext }  from '../../../shared/components/CourseCarousel/context/CoursesProvider.jsx';
import LoaderContent from '../../../design-system/components/Loader/LoaderContent.jsx';

import Toast from '../../../shared/components/Toast/Toast'

import { UserService } from '@sdk-point/talisis';
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const STATUS_BANNER_SUCCESS = 0;

const useStyles = makeStyles((theme) => ({
    dialog:{
        paddingTop: 16,
        '& .MuiPaper-root':{
            maxWidth: 512
        }
    },
    dialogTitle: {
        padding: theme.spacing(2),
        textAlign: 'center',
        borderBottom: '1px solid rgba(119, 119, 119, 0.15)',
    },
    closeButton: {
        padding: 0,
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
}));
const CURP_REGEX = /^[A-Z]{4}\d{6}[HM][A-Z]{5}[A-Z0-9]{2}$/;

const AddCurp = ({person, updateCurpSuccessful}) => {
    const classes = useStyles();
    const { coursesState } = useCoursesContext();
    const [open, setOpen] = useState(false);
    const [activateCurp, setActivateCurp] = React.useState(null);
    const [curpValue, setCurpValue] = useState('');
    const [error, setError] = useState('');
    const [helperText, setHelperText] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModalUpdated, setShowModalUpdated] = useState(false);

    const [toastProps, setToastProps] = React.useState({ message: '' })

    const handleChange = (event) => {
        const value = event.target.value.toUpperCase(); // Convertir a mayúsculas
        setCurpValue(value);

        if(!value){
            setError('');
            setHelperText('');
        }else if (curp.validar(value)) {
            setError(inputStatus.SUCCESS);
            setHelperText('');
        } else {
            setError(inputStatus.ERROR);
            setHelperText('Ingresa una CURP válida de 18 caracteres.');
        }
    };

    React.useEffect(() => {
        if(person.activateCurp !== null){
            setActivateCurp(person.activateCurp);
            const open = showModalUpdated ? true : person.activateCurp;
            setOpen(open)
        }
    }, [person])

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
        setCurpValue('');
        setError('');
        setHelperText('');
    };

    const updateCurp = async () => {
        setLoading(true)
        if(curpValue && person.id){
            try {
                const response = await UserServiceSDK.updateCurp(person.id, {curp: curpValue});
                if(response.status === STATUS_BANNER_SUCCESS){
                    setShowModalUpdated(true);
                    updateCurpSuccessful();
                }else{
                    setToastProps({ severity: "error", open: true, message: 'Ocurrió un error al actualizar CURP' });
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setToastProps({ severity: "error", open: true, message: 'Ocurrió un error al actualizar CURP' });
            }
        }
    }

    if((!coursesState.programs.length || activateCurp === null)){
        return null;
    }

    return (
        <>
            {
                activateCurp &&
                <WarningBanner 
                    title="Solicitud de CURP"
                    text={
                        <Typography variant='body2'>
                            Verifica tu identidad y completa tu inscripción,&nbsp;
                            <Link underline="always" color='secondary' component="button" variant="body2" onClick={handleClickOpen} style={{marginBottom: 4}}>
                                haz click aquí para ingresar tu CURP.
                            </Link>
                        </Typography>
                    }
                />
            }

            <Dialog open={open} onClose={handleClose} className={classes.dialog} PaperProps={{elevation: 3}}>
                <LoaderContent loading={loading}>
                    <DialogTitle id="form-dialog-title" disableTypography className={classes.dialogTitle}>
                        <Typography variant="subtitle1">
                            { showModalUpdated ? 'CURP actualizada' : 'Actualización de CURP'}
                        </Typography>
                        <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
                            <Icon className='ri-close-line' />
                        </IconButton>
                    </DialogTitle>
                    {
                        !showModalUpdated
                        ?
                        <>
                            <DialogContent>
                                <DialogContentText className='text-white' style={{marginBottom: 24}}>
                                Parece que no contamos con tu CURP actualizada. Esta información es necesaria para completar tu inscripción al programa académico y ante la SEP.
                                </DialogContentText>
                                <TextFieldStatus
                                    fullWidth 
                                    id="outlined-error-helper-text"
                                    status={error}
                                    label="CURP"
                                    value={curpValue}
                                    onChange={handleChange}
                                    statusText={helperText}
                                    placeholder="Ingresa tu CURP"
                                />
                                <Box display='flex' gridGap={8} flexDirection='column' mt={3}>
                                    <Typography variant='h5' color='primary' className='margin-b-1'>
                                    ¿Dónde encontrar tu CURP?
                                    </Typography>
                                    <Typography variant='body2'>
                                    Puedes encontrar tu CURP en documentos oficiales. También puedes consultarlo en&nbsp; 
                                    <Link underline="always" color='secondary' href="https://www.gob.mx/curp/" variant='body2' target='_blank'>
                                        https://www.gob.mx/curp/</Link>
                                    </Typography>
                                </Box>
                            </DialogContent>
                            <DialogActions className='justify-content-center padding-2'>
                                <Button variant='outlined' color='secondary' onClick={updateCurp} 
                                    disabled={error !== inputStatus.SUCCESS}
                                >
                                    Aceptar
                                </Button>
                            </DialogActions>
                        </>
                        :
                        <>
                            <DialogContent>
                                <DialogContentText className='text-white text-center' style={{marginBottom: 24}}>
                                    Tu CURP ha sido actualizado correctamente en nuestro sistema. Gracias por mantener tu información al día.
                                </DialogContentText>
                                <Box p={2} className='bg-fill-stroke-400' textAlign='center' style={{borderRadius: 8}}>
                                    <Typography variant='h3' color='primary'>{curpValue}</Typography>
                                </Box>
                            </DialogContent>
                            <DialogActions className='justify-content-center padding-2'>
                                <Box display='flex' gridGap={24}>
                                    <Button variant='outlined' color='secondary' onClick={() => setShowModalUpdated(false)}>
                                    Corregir CURP
                                    </Button>
                                    <Button variant='contained' color='primary' onClick={handleClose}>
                                        Aceptar
                                    </Button>
                                </Box>
                            </DialogActions>
                        </>
                    } 
                </LoaderContent>
            </Dialog>

            <Toast {...toastProps} />
        </>
    );
};

export default AddCurp;