import { PROFILE_PATH, STUDENT_PATH, CATALOG_INTEREST_AREA_PATH, API_ENDPOINT, PERSON_NATIONALITY_CAT } from './endpoints'
import { log } from '../shared/utils/console.js'

var abortController = null;

export const getProfile = async (email) => {
    const url = `${PROFILE_PATH}`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ email: email })
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getDashboard = async(person_id, ou) => {
    const url = `${PROFILE_PATH}/${person_id}/dashboard/${ou}`;
    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getProfileData = async (id) => {
    const url = `${PROFILE_PATH}/${id}`;
    const requestOptions = {
        method: 'GET',
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getStudentInfo = async (ou, id) => {
    const url = `${STUDENT_PATH}/${ou}/${id}`;
    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const searchStudent = async (ou, criteria, includePersons) => {
    if (abortController) {
        abortController.abort();
    }

    abortController = new AbortController();
    log(includePersons);
    let url = `${STUDENT_PATH}/${ou}/search/${criteria.replace(/\s/g, '+')}`;
    if (includePersons) {
        url = `${url}?includePersons=true`;
    }
    const requestOptions = {
        method: 'GET',
        signal: abortController?.signal
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getCatalogInterestArea = async () => {

    const response = await fetch(CATALOG_INTEREST_AREA_PATH);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const updateProfile = async (id, body) => {
    const url = `${PROFILE_PATH}/${id}`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const updateProfileData = async (id, body) => {
    const url = `${PROFILE_PATH}/${id}`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const createStudentQR = async (user) => {
    const url = `${STUDENT_PATH}/${user.ou}/${user.person_id}/qr-code`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ "idUnidadOperativa": user.student_id || user.person_id })
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getPreferences = async personId => {
    const url = `${PROFILE_PATH}/${personId}/preferences`;

    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const updatePreferences = async (personId, preferences) => {
    const url = `${PROFILE_PATH}/${personId}/preferences`;

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ "preferences": preferences })
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}


export const updateProfilePicture = async (personId, imgUrl) => {
    const url = `${PROFILE_PATH}/picture`;
    
    const payload = {
        'person_id': personId,
        'img_url': imgUrl
    }
    
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(payload)
    };
    
    log(payload)

    const response = await fetch(url, requestOptions);
    log(response)
    const data = await response.json();
    log(data)

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getNationalityCat = async() => {
    const response = await fetch(PERSON_NATIONALITY_CAT, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_X_API_KEY
        }
    });

    const data = await response.json();
    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getStudentScheduleCalifications = async(ou, user_id, type) => {
    const response = await fetch(`${STUDENT_PATH}/${ou}/${user_id}/academic?type=${type}`, {
        method: 'GET'
    });

    const data = await response.json();
    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
};
