import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    boxRoot:{
        marginBottom: '65px'
    },
    boxTittles: { 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]:{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    boxCreditos:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px'
    },
    cantCreditos:{
        display: 'flex',
        width: '32px',
        height: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(274.05deg,#CDACFC -8.09%,#8B3FFA 61.08%)',
        borderRadius: '100%',
    }
}));