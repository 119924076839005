import React from 'react';
import InputFile from '../../../../../shared/components/Form/InputFile';
import { log } from '../../../../../shared/utils/console.js'
import Box from '@material-ui/core/Box';

const FileSelector = (props) => {
    const { fileDirection } = props;
    const [file, setFile] = React.useState(0);

    React.useEffect(() => {
        setFile(fileDirection);
    }, []);

    const handleChangeImage = (fileList) => {
        const { value } = fileList;
        log(value);
        setFile(value);
    }

    return (
        <Box>
            {
                file &&
                <InputFile
                    accept="image/*"
                    placeholder="Agrega una imagen"
                    btnText="Card"
                    name="image"
                    value={fileDirection}
                    onChange={(e) => handleChangeImage(e.target)} />
            }
        </Box>
        
    );
};

export default FileSelector;