import React, { useState } from 'react';

// @material-ui/core
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
const useStyles = makeStyles((theme) => ({
    accordionSummary:{
        flexDirection: 'row-reverse'
    },
}));

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        margin: "12px 0px",
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: "12px 0px",
        },
    }
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: '#2C3236',
        padding: `0px 12px`,
        borderRadius: '8px',
        minHeight: '40px',
        '&$expanded': {
            minHeight: '40px',
        },
    }
}))(MuiAccordionSummary);

const CustomizedLesson = ({ id, title }) => {
    const classes = useStyles();

    return (
        <Accordion
            square
        >
            <AccordionSummary
                className={classes.accordionSummary}
                aria-controls={`${id}-content`}
                id={`${id}-header`}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ marginLeft: "8px",color: "#F1F3F6" }}>{title}</Typography>
                        </Grid>
                    </Grid>
            </AccordionSummary>
        </Accordion>
    );
}

export default CustomizedLesson;