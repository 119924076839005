import React from 'react';

// @material-ui/core components
import Box from '@material-ui/core/Box';

import Navbar from '../layout/components/Navbar/Navbar'

const Layout = () => {
    return (
        <Box>
            <Navbar />
        </Box>
    );
};

export default Layout;