import React from 'react';

/* material-ui */
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const CheckoutLoader = ({ loading }) => {
    if (!loading) return null;

    return <Box textAlign="center" position="absolute" left={0} right={0} top="calc(50% - 80px)">
        <CircularProgress />
        <Typography className='mt-2'><b>Espere un momento por favor...</b></Typography>
    </Box>
};

export default CheckoutLoader;