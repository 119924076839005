import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import {Button, Box} from '@material-ui/core'; 
// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';

const NewButton= ({onClick,label})=> {
    return (
        <Box display="flex" alignItems="center" marginLeft="auto">
            <Button color="primary" startIcon={<AddIcon />} onClick={onClick}>{label}</Button>
        </Box>
    )
}

NewButton.defaultProps= {
  label:'Nuevo'
}

export default NewButton;