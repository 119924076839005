import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import CurrencyFormat from 'react-currency-format'

const OrderTotal = ({orderQty}) => {
    return (
        <Grid container justifyContent="space-between" style={{ marginTop: "16px", borderTop: "4px solid", borderImageSource: "linear-gradient(273.67deg, #FCDC43 15.67%, #FFA700 57.57%)", borderImageSlice: "1", padding: "16px", background: "#2C3236", borderRadius: "0px 0px 8px 8px"}}>
            <Grid item>
                <Typography variant="h5" className="text-white">
                    Total a pagar:
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5" className="text-orange-300">
                    <CurrencyFormat value={orderQty?.order_total} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN' />
                </Typography>
            </Grid>
        </Grid>
    )
}

export default OrderTotal
