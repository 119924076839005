import React, { useEffect,useState } from 'react';
import { connect, useSelector } from 'react-redux';

//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardAdviser from './Applicant/CardAdviser';
import CardRequestAdviser from './Advisers/CardRequestAdviser';
import TabPanel from '../../shared/components/Tabs/TabPanel';
import HorizontalTabs from '../../shared/components/Tabs/HorizontalTabs';
import MyConsultancies from './Applicant/MyConsultancies';
//SDK
import { ConsultanciesService } from '@sdk-point/talisis'; 
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ProfileAdviser from './Advisers/ProfileAdviser';
import {MEMBERSHIPS} from '../../shared/constants/memberships'
import {PERFIL_DE_ASESOR,AGENDAR_ASESORIA,SOLICITUDES_ASESORIAS,MIS_ASESORIAS} from '../../shared/constants/tabs'
import useStyles from '../../assets/styles/jss/pages/consultancies/ConsultanciesStyle';
import { setActiveTab } from '../../redux/actions/tabsActions';
import moment from 'moment';
import { Hidden } from '@material-ui/core';

import { eventTracker, eventData } from '../../shared/components/EventTracker/EventTracker';



const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const Consultancies = ({activeTabIndex, setActiveTab}) => {   

    const classes = useStyles()

    const [tab, setTab] = useState(0);
    const [DataLoad, setDataLoad] = useState(false);
    const [DataConsultancies, setDataConsultancies] = React.useState([]);
    const [DataConsultanciesAsesor, setDataConsultanciesAsesor] = React.useState([]);      
    const [creditosAsesorias, setCreditosAsesorias] = useState(0);
    const [isAsesor, setIsAsesor] = useState(false);
    const [preguntasCalificar, setPreguntasCalificar] = useState([]);
    const [tabs, setTabs] = useState([]);
    
    const { user } = useSelector((state) => state.userReducer);

    const handleChange = (event, newTab) => {
        setTab(newTab);
    };

    const setActiveTabConsult = (tabIndex) =>  {
        console.log('tab', tabIndex, tabs)
        let tabSelect = tabs[tabIndex]
        //texto relaciono con los tabs de asesorias
        if (tabSelect == 'Agendar Asesoría') {
            eventTracker('s_agendar_asesorias', eventData, ['mixpanel']);
        }else if(tabSelect == 'Mis Asesorías') {
            eventTracker('s_mis_asesorias', eventData, ['mixpanel']);
        }
        setActiveTab(tabIndex);
    }

    useEffect(() => {
        eventTracker('s_agendar_asesorias', eventData, ['mixpanel']);
        findConsultancies();
        const intervalId = setInterval(() => {
            findConsultancies();
        }, 60000);
        return () => clearInterval(intervalId);
    }, []);

    const convertTimeUTCtoLocal = tiempo => {
        let [scheduleA, scheduleB] = (tiempo).split(' - ');
      
        // Si el formato es "2:30 - 3:00", agregar un cero inicial
        if (/^\d:\d{2}/.test(scheduleA)) {
          scheduleA = '0' + scheduleA;
        }
        if (/^\d:\d{2}/.test(scheduleB)) {
          scheduleB = '0' + scheduleB;
        }
      
        // Convertir a hora local
        scheduleA = convertDateTo12('2022-12-27T' + scheduleA + ':00.000Z');
        scheduleB = convertDateTo12('2022-12-27T' + scheduleB + ':00.000Z');
      
        let scheduleTransform = scheduleA + ' - ' + scheduleB;
        return scheduleTransform;
      };
    
    const convertDateTo12 = (datex) => {
        let hoursStart = '';
        let minutesStart = '';
        let AmOrPmStart = '';
        let dateFinalStart = '';

        let date = new Date(datex)
        hoursStart = date.getHours();
        minutesStart = date.getMinutes() == 0 ? '00' : date.getMinutes();
        AmOrPmStart = hoursStart >= 12 ? 'pm' : 'am';
        hoursStart = (hoursStart % 12) || 12;
        hoursStart = date.getHours() < 10 || date.getHours() > 12  ?  '0'+hoursStart: hoursStart;
        
        dateFinalStart = minutesStart != '' ? hoursStart + ":" + minutesStart + " " + AmOrPmStart : hoursStart + " " + AmOrPmStart;

        return dateFinalStart
    }

    const findConsultancies = async () => {
        const fecha = moment().format('YYYY-MM-DD');
        let dataList = await ConsultanciesServiceSDK.getConsultancyByPerson(user.person_id, '', fecha);
        dataList.consultancies.forEach((consultancy) => {
            const fechaLocal = moment(consultancy.fecha_asesoria).local().format('YYYY-MM-DDTHH:mm:ss');
            const horaLocal = convertTimeUTCtoLocal(consultancy.tiempo_asesoria)
            consultancy.fechaLocal = fechaLocal;
            consultancy.horaLocal = horaLocal;
        });
        dataList.consultanciesAsesor.forEach((consultancy) => {
            const fechaLocal = moment(consultancy.fecha_asesoria).local().format('YYYY-MM-DDTHH:mm:ss');
            const horaLocal = convertTimeUTCtoLocal(consultancy.tiempo_asesoria)
            consultancy.fechaLocal = fechaLocal;
            consultancy.horaLocal = horaLocal;
        });
        setDataConsultancies(dataList.consultancies);
        setDataConsultanciesAsesor(dataList.consultanciesAsesor)
        setCreditosAsesorias(dataList.credits);
        setIsAsesor(dataList.isAsesor);
        //const pendientes = dataList.consultancies.filter(item => item.status_asesoria === "PENDIENTE");
        //const aprobadas = dataList.consultancies.filter(item => item.status_asesoria === "APROBADA");
        if (dataList.isAsesor) {
            setTabs(['Solicitudes','Agendar Asesoría','Mis Asesorías', 'Perfil Asesor']);
        }else{
           setTabs(['Agendar Asesoría','Mis Asesorías',]);
        }
        dataList.preguntasCalificar.map((obj)=>{
            if(preguntasCalificar.length !== dataList.preguntasCalificar.length){
                preguntasCalificar.push({
                    id: obj.pregunta_id,
                    pregunta: obj.pregunta,
                    selectedValue: null
                })
            } 
        });
        setDataLoad(true);
    }

    return (
        <React.Fragment>
            {
                <Box className={classes.boxRoot}>  
                    <HorizontalTabs
                    activeTab={activeTabIndex}
                    onChange={setActiveTabConsult}
                    tabs={tabs}>
                        <> 
                            <TabPanel value={activeTabIndex} index={isAsesor ? AGENDAR_ASESORIA : 0}>
                                <Box className={classes.boxTittles}>
                                    <Hidden smDown>
                                        <Typography variant="h1">Asesores certificados</Typography> 
                                    </Hidden>
                                    <Box className={classes.boxCreditos}>
                                    
                                    <Hidden smDown>
                                        <Typography variant="body1">
                                            Tus créditos para asesorías: 
                                        </Typography> 
                                    </Hidden>
                                    <Box className={classes.cantCreditos}>
                                        {
                                            (user.membership_id === MEMBERSHIPS.PREMIUM) ?
                                            <AllInclusiveIcon style={{width:'20px', height:'20px'}} />
                                            :
                                            <Typography variant={'body2'} className={'semi-bold'}>{creditosAsesorias}</Typography>
                                        }
                                    </Box>
                                    <Hidden smUp>
                                        <Typography variant="body1">
                                        Créditos para asesorías
                                        </Typography> 
                                    </Hidden>
                                    </Box>
                                </Box>    
                                <CardAdviser creditosAsesorias={creditosAsesorias} DataConsultancies={DataConsultancies} findConsultancies={findConsultancies}/>
                            </TabPanel>
                            {
                                <TabPanel value={activeTabIndex} index={isAsesor ? MIS_ASESORIAS : 1}>
                                    <MyConsultancies creditosAsesorias={creditosAsesorias} preguntasCalificar = {preguntasCalificar} DataConsultancies={DataConsultancies} DataLoad={DataLoad} findConsultancies={findConsultancies}/>
                                </TabPanel>
                            }
                            {
                            isAsesor &&
                                <>
                                    <TabPanel value={activeTabIndex} index={PERFIL_DE_ASESOR}>
                                        <ProfileAdviser personIdAsesor={user.person_id} />
                                    </TabPanel>

                                    <TabPanel value={activeTabIndex} index={SOLICITUDES_ASESORIAS}>
                                        <CardRequestAdviser DataConsultancies={DataConsultanciesAsesor} DataLoad={DataLoad} findConsultancies={findConsultancies} />
                                    </TabPanel>
                                </>
                            }
                        </>
                    </HorizontalTabs>
                </Box>
            }
        </React.Fragment>
    );
};

// setea el tab en el redux
const mapDispatchToProps = { setActiveTab };

// obtiene el tab activo del redux
const mapStateToProps = (state) => ({ activeTabIndex: state.tabsReducer.activeTabIndex});

export default connect(mapStateToProps, mapDispatchToProps)(Consultancies);