import clsx from 'clsx';
import React from 'react';
import { useDropzone } from 'react-dropzone';

// Material components
import { Box, Icon, Typography, makeStyles } from '@material-ui/core';
import CircularProgressWithLabel from '../Progress/CircularProgressWithLabel';

const useStyles = makeStyles((theme) => ({
    dropZoneContainer: {
        borderRadius: '8px',
        border: `0.5px dashed ${theme.palette.colors.fillStroke[100]}`,
        background: `${theme.palette.colors.fillStroke[400]}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    progressCircle: {
        width: "64px !important",
        height: "64px !important",
    }
}));

const FileDropZone = ({ className, extensions, onUploadFile }) => {
    const classes = useStyles();

    const onDrop = React.useCallback(acceptedFiles => {
        if (!!acceptedFiles.length) onUploadFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1, accept: extensions })

    return (
        <Box {...getRootProps({ className: clsx(classes.dropZoneContainer, className)})}>
            <input {...getInputProps()} />
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"> 
                <Icon className={clsx("ri-file-add-fill", "font-size-32")}/>
                <Box mt="16px" display="flex" flexDirection="column" gridGap="4px" alignItems="center">
                    <Typography variant='h4'>Añadir documento</Typography>
                    <Typography variant='body2'>Arrastrar y soltar</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default FileDropZone;