export const REQUEST_STATUS_ID = {
    REJECT: 3,
    CANCEL: 4,
    APPROVE: 5,
    IN_PROGRESS: 2,
    PENDING: 1,
    REVIEW: 6
}

export const REQUEST_STATUS_BTTN = [
    {id: REQUEST_STATUS_ID.APPROVE, label: 'Aprobar'},
    {id: REQUEST_STATUS_ID.REJECT, label: 'Rechazar'},
    {id: REQUEST_STATUS_ID.CANCEL, label: 'Cancelar'},
]

export const REQUEST_STATUS = {
    [REQUEST_STATUS_ID.APPROVE]: {
        path: 'approve',
    },
    [REQUEST_STATUS_ID.REJECT]: {
        path: 'reject',
        warningMssg: "Esta acción provocara un retroceso del flujo. Es necesario reasignar a un paso.",
    },
    [REQUEST_STATUS_ID.CANCEL]: {
        path: 'cancel',
        warningMssg: "Esta acción provocara la finalización completa del flujo"
    }
}

export const REQUEST_STEP_TYPE_ID = {
    WEBHOOK: 4,
    DOCUMENTS: 5,
    SHIPPING_GUIDE: 6,
    DIGITAL_DOCUMENT_REVIEW: 7,
    PHYSICAL_DOCUMENT_REVIEW: 8,
}

// Identificador de estatus para la revision de documentacion digital en recepcion de documentos.
export const DOCUMENT_STATUS_ID = {
    REJECTED: 1,
    APPROVED: 2,
    REPLACED: 3
}

// Identificadore de estatus para la revision de documentacion fisica en recepcion de documentos. 
export const PHYSICAL_REVIEW_STATUS = {
    APPROVED: 1,
    REJECTED: 2,
};