import { makeStyles } from '@material-ui/core/styles';
import { SOURCES } from '../../../../design-system/constants/sources'

export default makeStyles((theme) => ({
    root:{
        display: 'inline-flex',
        padding:'0px 24px 0px',
        justifyContent: 'flex-end',
        alignItems: 'center',   
        gap: '16.086px',
        borderBottom: `2px solid ${theme.palette.colors.fillStroke[200]}`
    },
    buttonLoginContainer:{
        borderLeft: `1px solid ${theme.palette.colors.fillStroke[200]}`
    },
    buttonLogin:{
        marginLeft: 16,
        borderRadius: 0,
        color: theme.palette.colors.gray[500],
        padding: 0,
        '& .MuiButton-iconSizeMedium> *:first-child':{
            fontSize: '24px !important'
        }
    },
    button:{
        height: 40,
        borderRadius: 0,
        borderBottom: `3px solid transparent`
    },
    [SOURCES.TALISIS]:{
        width: 96,
        borderBottom: `3px solid ${theme.palette.colors.electricBlue[300]} !important`,
        [theme.breakpoints.down('sm')]:{
            minWidth: 0,
            width: 56,
            '& .source-image':{
                height: '9px !important'
            }
        }
    },
    [SOURCES.ADVENIO]:{
        width: 96,
        '&:hover':{
            borderBottom: `3px solid ${theme.palette.colors.ou.advenioRedMain}`
        },
        [theme.breakpoints.down('sm')]:{
            minWidth: 0,
            width: 64,
            '& .source-image':{
                height: '9px !important'
            }
        }
    },
    [SOURCES.IESALUD]:{
        width: 82,
        '&:hover':{
            borderBottom: `3px solid ${theme.palette.colors.ou.iesaludGreenM80}`
        },
        [theme.breakpoints.down('sm')]:{
            minWidth: 0,
            width: 64,
            '& .source-image':{
                height: '9px !important'
            }
        }
    },
    [SOURCES.UERRE]:{
        width: 81,
        '&:hover':{
            borderBottom: `3px solid ${theme.palette.colors.ou.uerreRedC80}`
        },
        [theme.breakpoints.down('sm')]:{
            minWidth: 0,
            width: 64,
            '& .source-image':{
                height: '9px !important'
            }
        }
    },
    [SOURCES.UNID]:{
        width: 69,
        '&:hover':{
            borderBottom: `3px solid ${theme.palette.colors.ou.unidYellowMain}`
        },
        [theme.breakpoints.down('sm')]:{
            minWidth: 0,
            width: 45,
            '& .source-image':{
                height: '11px !important'
            }
        }
    },
    [SOURCES.HH]:{
        width: 120,
        '&:hover':{
            borderBottom: `3px solid ${theme.palette.colors.ou.iesaludYellow}`
        },
        [theme.breakpoints.down('sm')]:{
            minWidth: 0,
            width: 94,
            '& .source-image':{
                height: '11px !important'
            }
        }
    },
    [theme.breakpoints.down('sm')]:{
        root:{
            display: 'flex',
            padding: '0px 60px 0px'
        }
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            justifyContent: 'center',
            padding: 0
        }
    }
}));