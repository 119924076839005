import React, { Component } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import { Box, Grid, Hidden } from '@material-ui/core';

// @redux
import { connect } from "react-redux"

// Components
import Learning from './Learning/Learning';
import FeedCard from './FeedCard/FeedCard';

//SDK
import { FeedService, GlobalService, CourseService, AllysService } from '@sdk-point/talisis';
import { eventTracker } from '../../shared/components/EventTracker/EventTracker';
import Toast from '../../shared/components/Toast/Toast';

const FeedServiceSDK = new FeedService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const CourseServiceSDK = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const Feed = ({user, pageId, userRole, canReadWrite, isResponsive}) => {
    const [data, setData] = React.useState([]);
    const [courses, setCourses] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingCourses, setLoadingCourses] = React.useState(true);
    const [loadingAllies, setLoadingAllies] = React.useState(false);
    const [toastProps, setToastProps] = React.useState({ message: '' });
    const [shortcutsAllies, setshortcutsAllies] = React.useState([]);

    const getUserFeeds = async () => {
        setLoading(true);
        try {
            const data = await FeedServiceSDK.getUserFeeds(user);
            setData(data);
            setLoading(false);
        }
        catch (err) {
            setToastProps({ severity: 'error', open: true, message: 'Ops! ha ocurrido un error' });
        }
    };

    const getShortcutsAllies = async () => {
        try {
            const request = {
                person_id: user.person_id
            }
            const shortcuts = await AllysServiceSDK.getShortcutsAllies(request);
            setshortcutsAllies(shortcuts);
        }
        catch (err) {
            setToastProps({ severity: 'error', open: true, message: 'Ops! ha ocurrido un error' });
        }
    };

    const getData = async () => {
        await getUserFeeds();
        await getShortcutsAllies();

        setLoadingCourses(true);
        const coursesRecommendedByType = await CourseServiceSDK.getCoursesRecommendedByType(user.person_id, 'feed');
        setLoadingCourses(false);

        const { data: { courses } } = coursesRecommendedByType;
        setCourses(courses);
    };

    const handleDelete = async (id) => {
        setLoading(true);
        await FeedServiceSDK.DeleteFeed(id)
            .then(async response => {
                await getUserFeeds();
                setToastProps({ severity: 'success', open: true, message: 'La noticia fue eliminada exitosamente' });
            })
            .catch(function (error) {
                setToastProps({ severity: 'error', open: true, message: '¡Ops! Ocurrió un error al eliminar la noticia. Intentalo de nuevo.' });
            });
        setLoading(false);
    };

    const handleCreateReaction = async (body) => {
        try {
            const response = await FeedServiceSDK.createReaction(body);
            return response;
        }
        catch (error) {
            setToastProps({ severity: 'error', open: true, message: 'Ops! ha ocurrido un error' });
        }
    };

    const handleDownloadFile = async (docURL) => {
        try {
            const response = await GlobalServiceSDK.downloadFile(docURL);
            return response;
        }
        catch (error) {
            setToastProps({ severity: 'error', open: true, message: 'Ops! ha ocurrido un error' });
        }
    };

    const hanldeFinishedToast = () => {
        setToastProps({ open: false, severity: '', message: '' });
    }

    React.useEffect(() => {
        getData();
        eventTracker('s_feed', {}, ['mixpanel']);
    }, []);

    return (
        <>
            <Hidden xsDown>
                <Learning courses={courses} user={user} loadingCourses={loadingCourses}/>
            </Hidden>
            {data.map((entry) =>
                <Box key={entry.id} width={'100%'} mt={'16px'}>
                    <FeedCard entry={entry} onDeleteNew={handleDelete} createReaction={handleCreateReaction} onDownloadFile={handleDownloadFile} canReadWrite={canReadWrite} isResponsive={isResponsive}/>
                </Box>
            )}
            <Toast {...toastProps} onFinished={hanldeFinishedToast} duration={3000} />
        </>
    );
};

Feed.defaultProps = {
    isResponsive: false,
};

Feed.propTypes = {
    user: PropTypes.object,
    pageId: PropTypes.number,
    userRole: PropTypes.string,
    canReadWrite: PropTypes.bool,
    isResponsive: PropTypes.bool,
};

export default Feed;
