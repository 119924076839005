import React from 'react';
import clsx from 'clsx';

// @material-ui/core
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

// Ou Logos
import LogoByOu from '../../../DetailedStudyPlan/LogoByOu/LogoByOu';

// style
import useStyles from '../../../../../assets/styles/jss/pages/market/PaymentConfirmation';

// utils
import { MEMBERSHIPS } from '../../../../../shared/constants/memberships';
import * as paths from '../../../../../routes/paths'

const getMembershipIcon = (id, width = 20, height = 20) => {
    if (id === MEMBERSHIPS.LITE) {
        return (
            <svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0L11.5577 4.4762C11.9999 5.74708 12.221 6.38253 12.7285 6.7511C13.236 7.11967 13.9087 7.13336 15.254 7.16075L20 7.25735L16.2209 10.1192C15.147 10.9326 14.61 11.3393 14.4159 11.9364C14.2218 12.5335 14.417 13.1781 14.8076 14.4675L16.1803 19L12.283 16.291C11.1789 15.5235 10.6268 15.1398 10 15.1398C9.37319 15.1398 8.82112 15.5235 7.71699 16.291L3.81966 19L5.19243 14.4675C5.58295 13.1781 5.77821 12.5335 5.58413 11.9364C5.39004 11.3393 4.85305 10.9326 3.77905 10.1192L0 7.25735L4.74595 7.16075C6.09131 7.13336 6.76399 7.11967 7.27148 6.7511C7.77897 6.38253 8.00009 5.74708 8.44234 4.4762L10 0Z" fill="url(#paint0_linear_3088_272)" />
                <defs>
                    <linearGradient id="paint0_linear_3088_272" x1="31.3031" y1="48.7228" x2="2.8953" y2="46.803" gradientUnits="userSpaceOnUse">
                        <stop offset="0.583333" stop-color="#FCDC43" />
                        <stop offset="0.895833" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    if (id === MEMBERSHIPS.PROFESSIONAL) {
        return (
            <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 0.333008L18.4746 6.4061C18.9666 8.43263 19.2127 9.4459 19.9947 9.76985C20.7768 10.0938 21.6673 9.55127 23.4482 8.46622L28.7852 5.21456L25.5335 10.5516C24.4484 12.3325 23.9059 13.2229 24.2299 14.005C24.5538 14.7871 25.5671 15.0331 27.5936 15.5251L33.6667 16.9997L27.5936 18.4742C25.5671 18.9663 24.5538 19.2123 24.2299 19.9944C23.9059 20.7764 24.4484 21.6669 25.5335 23.4478L28.7852 28.7848L23.4482 25.5331C21.6673 24.4481 20.7768 23.9056 19.9947 24.2295C19.2127 24.5534 18.9666 25.5667 18.4746 27.5932L17 33.6663L15.5255 27.5932C15.0334 25.5667 14.7874 24.5534 14.0053 24.2295C13.2233 23.9056 12.3328 24.4481 10.5519 25.5331L5.21493 28.7848L8.46659 23.4478C9.55163 21.6669 10.0942 20.7764 9.77021 19.9944C9.44627 19.2123 8.433 18.9663 6.40647 18.4742L0.333374 16.9997L6.40647 15.5251C8.433 15.0331 9.44627 14.7871 9.77021 14.005C10.0942 13.2229 9.55164 12.3325 8.46659 10.5516L5.21493 5.21456L10.5519 8.46622C12.3328 9.55127 13.2233 10.0938 14.0053 9.76985C14.7874 9.4459 15.0334 8.43264 15.5255 6.4061L17 0.333008Z" fill="url(#paint0_linear_428_800)" />
                <defs>
                    <linearGradient id="paint0_linear_428_800" x1="52.5052" y1="85.8116" x2="5.13787" y2="82.7706" gradientUnits="userSpaceOnUse">
                        <stop offset="0.583333" stop-color="#FCDC43" />
                        <stop offset="0.895833" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    if (id === MEMBERSHIPS.PREMIUM) {
        return (
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0L12.1194 5.56588C12.4337 6.39129 12.5908 6.804 12.8934 7.10659C13.196 7.40917 13.6087 7.56632 14.4341 7.88063L20 10L14.4341 12.1194C13.6087 12.4337 13.196 12.5908 12.8934 12.8934C12.5908 13.196 12.4337 13.6087 12.1194 14.4341L10 20L7.88063 14.4341C7.56633 13.6087 7.40917 13.196 7.10659 12.8934C6.804 12.5908 6.39129 12.4337 5.56588 12.1194L0 10L5.56588 7.88063C6.39129 7.56633 6.804 7.40917 7.10659 7.10659C7.40917 6.804 7.56632 6.39129 7.88063 5.56588L10 0Z" fill="url(#paint0_linear_3089_671)" />
                <defs>
                    <linearGradient id="paint0_linear_3089_671" x1="31.3031" y1="51.2871" x2="2.8827" y2="49.4625" gradientUnits="userSpaceOnUse">
                        <stop offset="0.583333" stop-color="#FCDC43" />
                        <stop offset="0.895833" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    return null;
};

const ProductDetail = ({ order, product, membership }) => {
    const classes = useStyles();

    if (product.course_id) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="subtitle2" align='center' className={clsx("text-gray-400")}>Te has inscrito al curso de:</Typography>
                <Box className="mt-5 mb-4">
                    <LogoByOu logoWidth={"168px"} operativeUnit={product?.company_id} />
                </Box>
                <Typography variant="h3" align='center' className={clsx("text-white", classes.courseTitle)}>{product?.name}</Typography>
            </Box>
        )
    } else if (product.program_id) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Box mb="18px">
                    <Typography variant="h2" align='center' className={clsx("text-white")}>¡Bienvenido a {product?.company_name} Online!</Typography>
                </Box>
                <Box maxWidth="800px">
                    <Typography variant="body1" align="center" className='mb-3'>
                        Ya puedes acceder a tu plataforma de aprendizaje y disfrutar de los <Link href={paths.MEMBRESIAS} variant='body1' color='secondary' target='_blank'>nuevos beneficios de tu membresía {membership?.membership_name}</Link>. En unos minutos recibirás un correo de confirmación a: <b>{order?.person?.email}</b>.<br/>
                    </Typography>
                    <Typography variant="body1" align="center">
                        Nos complace darte la bienvenida a esta nueva experiencia,<br/>estamos seguros de que lograrás tus objetivos personales y profesionales.
                    </Typography>
                </Box>
            </Box>
        )
    } else if (product.membership_id) {
        const membershipIcon = getMembershipIcon(product.membership_id, 33, 33);

        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="subtitle2" align='center' className={clsx("text-gray-400", "mb-4")}>Has obtenido la:</Typography>
                <Box display="flex" alignItems="center" mb="22px">
                    <Box mr='12px'>{membershipIcon}</Box>
                    <Typography variant="h3" align='center' className={clsx("text-white", classes.courseTitle)}>Membresía {product?.membership_name}</Typography>
                </Box>
                <Box maxWidth="680px">
                    <Typography variant="body1" align="center">
                        ¡Bienvenido! Comienza a disfrutar de todos los <Link href={paths.MEMBRESIAS} variant='body1' color='secondary' target='_blank'>beneficios de tu membresía</Link>.<br />Si tienes preguntas o necesitas ayuda, nuestro equipo de soporte estará para asistirte.<br />¡No dudes en contactarnos!
                    </Typography>
                </Box>
            </Box>
        )
    }

    return null;
};

export default ProductDetail;