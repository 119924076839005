import React from 'react';
import { useEffect } from 'react';

// @shared/components
import VerticalTab from '../../../../shared/components/Tabs/VerticalTab';

// components
import Statement from '../../Statement/Statement';
import Billing from '../../Billing/Billing';
import Membership from '../Membership/Membership';
import PaymentMethods from '../../Billing/PaymentMethods/PaymentMethods';

import { eventData, eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const PaymentInfo = (props) => {
    const [activeTabIndex,setActiveTabIndex]=React.useState(0);
    const [activePaymentInfoTab,setActivePaymentInfoTab]=React.useState(0);
    const TABINDEX=2;
    const labels = [
        { icon: <i className="ri-contacts-line"></i>, title: 'Estado de cuenta' },
        { icon: <i className="ri-money-dollar-circle-line"></i>, title: 'Mis adeudos' },
        { icon: <i className="ri-user-star-line icon"></i>, title: 'Membresías' },
        { icon: <i className="ri-wallet-line icon"></i>, title: 'Métodos de pago' },
    ];

    useEffect(() => {
        eventTracker('s_estado_cuenta',eventData, ['mixpanel']);
    }, []);

    const isCurrentTab=(val)=>{
        return activeTabIndex===TABINDEX && activePaymentInfoTab===val?true:false;
    };

    const panels = [<Statement isCurrentTab={isCurrentTab(0)} />, <Billing isCurrentTab={isCurrentTab(1)} />, <Membership isCurrentTab={isCurrentTab(2)} />, <PaymentMethods isCurrentTab={isCurrentTab(3)} />];

    useEffect(()=>{
        setActiveTabIndex(props.props.activeTabIndex);
      },[props.props.activeTabIndex]);

    return (
        <VerticalTab labels={labels} panels={panels} setActivePaymentInfoTab={setActivePaymentInfoTab} />
    )
};

export default PaymentInfo;