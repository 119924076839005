import React from 'react';

// @format
import * as MEDIA from '../genericBrandLandingPageMedia'

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box, 
    Icon, 
    IconButton,
    Hidden
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    headerLogo: {
        backgroundColor: props => props.brand === 'unid' ? theme.palette.colors.ou.unidPurple : theme.palette.colors.grey[500],
        height: '144px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLogoImageLink: {
        cursor: 'pointer',
        height: '36px',
        position: 'absolute',
        right: '54px',
        top: '40%',
        width: '136px',
        '@media (max-width: 640px)': {
            position: 'initial',
        }
    },
}));

const brandsConfig = {
    'u-erre':{
        title: "U-ERRE",
        link: 'https://www.u-erre.mx/'
    },
    'unid':{
        title: "UNID",
        link: 'https://www.unid.edu.mx/' 
    },
    'harmonhall':{
        title: "Harmon Hall",
        link: 'https://www.harmonhall.com/' 
    }
}

const GenericBrandLandingPageHeader = ({brand}) => {
    const classes = useStyles({brand});

    const handleClick = () => window.open(brandsConfig[brand].link, '_blank');

    return (
        <Box className={classes.headerLogo}>
            <img src={MEDIA.getBrandLandingPagesLogo(brand)} title={brandsConfig[brand].title} />
            <Hidden xsDown>
                <img className={classes.headerLogoImageLink} src={MEDIA.getBrandLandingPagesWebsiteLinkImg(brand)} onClick={handleClick} />
            </Hidden>
            <Hidden smUp>
                <IconButton aria-label="upload picture" component="span" onClick={handleClick}>
                    <Icon className="ri-global-line font-size-18 text-white" />
                </IconButton>
            </Hidden>
        </Box>
    )
};

export default GenericBrandLandingPageHeader;