import React from 'react';

import TableBody from '@material-ui/core/TableBody';
import Row from './Row';

const RowContainer = ({ data, order, page, rowsPerPage, orderBy, user, getPrograms, setToastProps, setLoading }) => {
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    return (
        <React.Fragment>
            {<TableBody>
                {
                    stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                            <Row key={row.id} row={row} />
                        ))
                }
            </TableBody>}
        </React.Fragment>
    );
};

export default RowContainer;