import React from 'react';

import { 
    Card,
    Box,
    Typography,
    Chip,
    Button
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    bg: {
        width: '100%',
        height: '100%',
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        imageRendering: "-webkit-optimize-contrast",
        position: 'relative'
    },
    content:{
        height: 158,
        padding: 22,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    'unid':{
        borderTopRightRadius: 0,
        '& .title':{
            color: theme.palette.colors.gray[100]
        }
    },
    'u-erre':{
        '& .title':{
            color: theme.palette.colors.gray[100]
        }
    },
    'harmonhall':{
        '& .title':{
            color: theme.palette.colors.darkBlack[500]
        }
    },
    vector:{
        position: 'absolute',
        right: 0
    }
}));

const configClass = {
    'unid':{
        button: 'bttn-unid-yellow',
        tag: 'chip1-fs-200',
        gradiend: 'linear-gradient(180deg, rgba(241, 186, 40, 0) 57%, rgba(241, 186, 40, 0.5) 126.9%)'
    },
    'u-erre':{
        button: 'bttn-white',
        tag: 'chip1-white',
        gradient: '0deg, rgba(28, 147, 187, 0.2), rgba(28, 147, 187, 0.2)'
    },
    'harmonhall':{
        button: 'bttn-hh-yellow',
        tag: 'chip1-hh-black',
        gradient: '0deg, rgba(28, 147, 187, 0.2), rgba(28, 147, 187, 0.2)'
    }
}

const BrandProgramCardResponsive = ({brand, bgImage, title, textBttn, textBadge, onClickBttn, degraded}) => {
    const classes = useStyles();
    return (
        <Card className={classes[brand]}>
            <Box className={classes.bg} style={{ background: `${!!degraded ? `${degraded}, ` : ""}url("${bgImage}")` }}>
                {
                    brand === 'unid' && <img src="/images/brands/vector-unid.svg" className={classes.vector}/>
                }
                <Box className={classes.content}>
                    <Box textAlign="initial">
                        <Typography variant="h4" className="title offset-mb-6">{title}</Typography>
                        <Button variant="outlined" className={configClass[brand].button} onClick={onClickBttn}>{textBttn}</Button>
                    </Box>
                    {textBadge && <Chip className={configClass[brand].tag} label={textBadge} />}
                </Box>
            </Box>
        </Card>
    );
};

export default BrandProgramCardResponsive;