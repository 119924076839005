import React from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as paths from '../../../../routes/paths'
import { eventData, eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const LoginButtonsBlock = ({className, loginBttnProps, registerBttnProps}) => {
    const history = useHistory();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handleLogin = path => e => {
        eventData['source'] = 'landing_conecta';
        if (path === paths.SIGNUP) {
            eventTracker('cta_registro', eventData, ['mixpanel', 'hubspot', 'google']);
        }
        else if (path === paths.LOGIN) {
            eventTracker('s_login', {}, ['mixpanel']);
        }
        history.push(path);
    };

    return(
        <Box className={className} display="flex" alignItems="center">
            <Button
                className={isResponsive ? 'mr-0' : "mr-3"}
                color="primary"
                onClick={handleLogin(paths.LOGIN)}
                {...loginBttnProps}
                >
                    {
                        loginBttnProps?.label || 'Iniciar sesión'
                    }
            </Button>
            <Button
                color="primary"
                onClick={handleLogin(paths.SIGNUP)}
                variant="contained"
                {...registerBttnProps}
            >
                {
                    registerBttnProps?.label || 'Empezar ahora'
                }
            </Button>
        </Box>
    )
}


export default LoginButtonsBlock;