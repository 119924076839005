import React, { lazy, Suspense } from 'react';

// @mui
import { Grid, Typography, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'

// @components
import * as FORMAT from '../components/genericBrandLandingPageFormat';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
const ProgramCarousel = lazy(() => import('../../../shared/components/ProgramCarousel/ProgramCarousel'));
const CourseCarousel = lazy(() => import('../../../shared/components/CourseCarousel/CourseCarousel'));

const useStyles = makeStyles((theme) => ({
    root:{
        padding: '120px 0px 118px',
        [theme.breakpoints.down('sm')]:{
            padding: '0px 0px 40px'
        },
    },
    container:{
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]:{
            display: 'flex',
            flexDirection: 'column'
        },
    },
    search: {
        border: '0px',
        '& input': {
            fontSize: '16px'
        }
    },
    programs: {
        paddingLeft: '0 !important',
        paddingTop: '0 !important',
        textAlign: 'left',
        '& .program_carousel_title': {
            marginLeft: '14px !important',
        },
        '& .slick_carousel': {
            paddingLeft: '0px !important',
        },
        '& .course_carousel': {
            marginLeft: '0px !important',
        },
    },
    courseCarousel:{
        '& .course-carousel-root': {
            paddingBottom: 0,
            minHeight: 400
        },
        [theme.breakpoints.down('xs')]:{
            position: 'relative',
            height: 404,
            '& .course-carousel-root': {
                paddingTop: 0,
                position: 'absolute',
                left: '-16px'
            },
        }
    },
}));

const GenericBrandLandingPageCourses = (props) => {
    const classes = useStyles();
    const { brand } = props;

    return (
        <ResponsiveContainer className={classes.root}>
            <Hidden xsDown>
                <Typography variant="h2" align="left" color="textPrimary" className="offset-mb-10">{`Cursos de ${FORMAT.getCompanyNameByBrand(brand)}`}</Typography>
            </Hidden>
            <Grid container>
                {
                    brand === 'unid' &&
                    <React.Fragment> 
                        <Grid id="bachillerato" item xs={12} className={classes.programs}>
                            <Suspense fallback={<div></div>}>
                                <ProgramCarousel isDetail={true} type="bachillerato" title='Bachillerato' ou={FORMAT.getCompanyIdByBrand(brand)} disableLink={true} />
                            </Suspense>
                        </Grid>
                        <Grid id="licenciatura" item xs={12} className={classes.programs}>
                            <Suspense fallback={<div></div>}>
                                <ProgramCarousel isDetail={true} type="licenciatura" title='Licenciatura' ou={FORMAT.getCompanyIdByBrand(brand)} disableLink={true} />
                            </Suspense>
                        </Grid>
                    </React.Fragment>
                }
                <Grid id="maestria" item xs={12} className={classes.programs}>
                    <Suspense fallback={<div></div>}>
                        <ProgramCarousel isDetail={true} type="maestria" title='Maestría' ou={FORMAT.getCompanyIdByBrand(brand)} disableLink={true} />
                    </Suspense>
                </Grid>
                <Grid id="educacion_continua" item xs={12} className={clsx(classes.programs, classes.courseCarousel)}>
                    <Suspense fallback={<div></div>}>
                        <CourseCarousel 
                            isDetail={true} 
                            lazyLoading 
                            id={`escuela::${FORMAT.getCompanyIdByBrand(brand)}`} 
                            title="Educación continua" 
                            urlMore='/busqueda?educon=true' 
                            viewCourseDetails
                            clickFrom={'academias'}
                        />
                    </Suspense>
                </Grid>
            </Grid>
        </ResponsiveContainer>
    )
};

export default GenericBrandLandingPageCourses;