import * as membershipTypes from '../types/membershipTypes'

const INITIAL_STATE = {
  membership: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case membershipTypes.APPLY_MEMBERSHIP:
      return { ...state, membership: action.payload };
    default:
      return state;
  }
};