import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

// Material components
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "4px",
    },
    cardContent:{
        display: "flex",
        width: "100%",
        padding: "16px",
        alignItems: "center",
        gap: "32px",
        borderRadius: "4px",
        border: `0.5px solid ${theme.palette.colors.warning[400]}`,
        background: "rgba(179, 157, 47, 0.30)",
    },
    container: {
        display: "flex",
        gap: "16px",
        alignItems: "flex-start",
        flex: "1 0 0",
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    textContainer: {
        display: "flex",
        gap: "8px",
        justifyContent: "space-between",
        width: "100%",
    },
    closeButton: {
        "&:hover": {
            background: 'unset'
        }
    }, 
    redirectToLabel: {
        cursor: "pointer",
    }
}));

const WarningBanner = ({title = '', text = '', open = true, onClickClose}) => {
    const classes = useStyles();
    
    return (
        <div id="informative-banner-root">
            <Collapse in={open}>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <Box className={classes.container}>
                            <Box>
                                <Icon className='ri-error-warning-fill text-warning-400 font-size-24' />
                            </Box>
                            <Box className={classes.textContainer}>
                                <Box flex={1} gridGap={6} display='flex' flexDirection='column'>
                                    <Typography variant='body2' className='text-white semi-bold'>{title}</Typography>
                                    {typeof text === 'string' ? (
                                        <Typography variant='body2' className='text-warning-100'>{text}</Typography>
                                    ) : (
                                        text
                                    )}
                                </Box>
                                {
                                    onClickClose &&
                                    <Box display='flex' className='button-close'>
                                        <IconButton className={ clsx("p-0", classes.closeButton)} aria-label="close" onClick={onClickClose} disableRipple disableFocusRipple>
                                            <Typography variant='overline' className='text-white variant-overline-sm semi-bold'>CERRAR</Typography>
                                            <Icon className='ri-close-line text-dark-black-200 font-size-24' />
                                        </IconButton>
                                    </Box>
                                }
                            </Box>

                        </Box>
                    </CardContent>
                </Card>
            </Collapse>
        </div>
    );
};

export default WarningBanner;