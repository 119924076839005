import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

// @redux
import {connect} from 'react-redux';

// @design-system
// import InputSearchV2 from './SubComponents/InputSearchV2';
import ChipFilter from '../../design-system/components/Chip/ChipFilter';
import LoaderContent from '../../design-system/components/Loader/LoaderContent';
import NothingFound from '../../shared/components/NothingFound/NothingFound';
import Toast from '../../design-system/components/Toast/Toast';
import Table from '../../design-system/components/Table/Table';
import MoreOptions from '../../shared/components/MoreOptions/MoreOptions';
import Select from '../../design-system/components/Form/Select/Select';
import dateFormat from "../../design-system/utils/dateFormat";
import {DATE_FORMAT_LOCAL, ISO_DATE_FORMAT} from "../../design-system/constants/date-formats";
import Dropdown from '../../design-system/components/Dropdown/Dropdown'

// @material-core
import {
  Icon,
  Grid,
  Link,
  Box,
  Button,
  Card,
  CardContent,
  Typograph,
  Modal,
  Backdrop,
  Zoom,
  Fade
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InputAdornment from "@material-ui/core/InputAdornment";

// @ThirtyParty
import {
  clone,
  concat,
  dropRepeats,
  findIndex,
  groupWith,
  indexOf,
  is,
  isEmpty,
  isNil,
  join,
  propEq,
  slice, sort
} from 'ramda';
import queryString from 'query-string'
import clsx from 'clsx';
import writeXlsxFile from 'write-excel-file'
import moment from 'moment';

// @local
import {requests, excelReportMetColumns} from './ColumnsTable';
import EditForm from './Student/EditForm';
import {degreeStyles} from '../../assets/styles/jss/pages/degreeCertificates/DegreeStyles'
import {
  SOURCE_ACADEMIC_DEGREE_CERTIFICATE,
  SOURCE_COMPANY_DEGREE_CERTIFICATE,
  SOURCE_PROCESS_DEGREE_CERTIFICATE
} from '../../shared/constants/sources';
import {stringContainsExactlyOf} from '../../shared/utils/common';
import Picker from "../../shared/components/Form/Picker";
import {STATUS_PROCESS,API_RESPONSE_STATUS_ERROR} from "../../shared/constants/degree-certificate";


// SDK
import {DegreeAndCertificateService} from '@sdk-point/talisis';

const STATUES_FILTER = [
  {id: 'ALL', label: 'Opciones'},
  {id: 'MET', label: 'Reporte MET', icon: "ri-download-line", condition: {status: [STATUS_PROCESS.DEGREE_STAMPED]}},
]

let STATUSPROC = {}
const DegreeServiceSDK = new DegreeAndCertificateService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const StatusTablePage = (props) => {
  const classes = degreeStyles();
  const history = useHistory();
  const location = useLocation();

  const [toast, setToast] = React.useState({toastProps: {message: '', open: false}})
  const [columns, setColumns] = React.useState(requests)
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({data: [], totalPages: 0, dataSource: []})
  const [page, setPage] = React.useState(1);
  const [btnActions, setBtnActions] = React.useState({id: 'ALL', label: 'Opciones'});
  const [inputSearch, setInputSearch] = React.useState('');
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [valueFilter, setValueFilter] = React.useState('matricula');
  const [filters, setFilters] = React.useState([]);
  const [selectedList, setSelectedList] = React.useState([]);
  const [isSelectedAll, setIsSelectedAll] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [filterCss, setFilteCss] = React.useState({});
  const [totalRequestLabel, setTotalRequestLabel] = React.useState("");
  const [openModalDetails, setOpenModalDetials] = React.useState(false);
  const [openStudentDetails, setOpenStudentDetails] = React.useState(false);
  const [student, setStudent] = React.useState({});
  const [students, setStudents] = React.useState([]);
  const [loadingStudents, setLoadingStudents] = React.useState(false);
  const [loadingStudent, setLoadingStudent] = React.useState(false);
  const [btnTitu, setBtnTitu] = React.useState(true);
  const [isDisabledDegree, setIsDisabledDegree] = React.useState(true);
  const [isCheckBox, setIsCheckBox] = React.useState(false);
  const [disabledOptions, setDisabledOptions] = React.useState(true);
  const [campus, setCampus] = React.useState([]);
  const [catalogues, setCatalogues] = React.useState({
    campus: [],
    status: []
  });
  const [studentUpdate, setStudentUpdate] = React.useState({secuencia: "", id: 0});
  const [loadingCamp, setLoadingCamp] = React.useState(false)
  const [loadingCatalogue, setLoadingCatalogue] = React.useState(false)
  const [messageTable, setMessageTable] = React.useState({
    default: {
      caption: 'Selecciona tu criterio de búsqueda dando click en el botón de Filtrado avanzado.',
      subtitle: 'Filtra por unidad operativa, matriculas, campus o nivel académico.'
    },
    data: {
      caption: 'Selecciona tu criterio de búsqueda dando click en el botón de Filtrado avanzado.',
      subtitle: 'Filtra por unidad operativa, matriculas, campus o nivel académico.'
    }
  });


  const valueFilterLabels = {
    ou: 'Marca',
    campus: 'Campus',
    level: 'Nivel Academico',
    status: 'Estatus',
    process: 'Tipo de Proceso',
    dateStart: 'Fecha Inicio',
    dateEnd: 'Fecha Fin',
  };

  const valueFilterQueryStringURL = {
    ou: 'ou',
    campus: 'campus',
    level: 'level',
    status: 'status',
    process: 'process',
    dateStart: 'dateStart',
    dateEnd: 'dateEnd',
  };

  React.useEffect(() => {
    const params = {...selectedFilters}
    history.push({search: new URLSearchParams(params).toString()});
    return () => {
    }
  }, [selectedFilters]);

  React.useEffect(() => {
    setLoading(true)
    const params = queryString.parse(location.search)
    setSelectedFilters(params);
    hanledFilterUrl(params);
    if (!isNil(params.ids)) {
      setInputSearch(params.ids)
    }
    getAllRequests()
  }, []);

  const getAllRequests = async () => {
    try {
      let payload = isEmpty(selectedFilters) ? queryString.parse(location.search) : selectedFilters
      if (payload.ou) {
        setLoadingCatalogue(true)
        getCatalogues(payload.ou)
        //getCampus(payload.ou)
      }
      if (isEmpty(payload) || isNil(payload.ou) /* || isNil(payload.campus) || isNil(payload.level) */ /*|| isNil(payload.ids)*/) {
        // setToast({toastProps: {severity: "error", open: true, message: "Aplica los filtros necesarios."}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }
      setDisabledOptions(true)
      if (!isNil(payload.status) && !stringContainsExactlyOf(payload.status [STATUS_PROCESS.DEGREE_STAMPED, STATUS_PROCESS.CERTIFICATE_STAMPED])) {
        setDisabledOptions(false)
      }
      const response = await DegreeServiceSDK.getStatusDegreeAndCertificate(payload);
      // const { data: alumnos} = dataJosn
      const data = response?.data
      const alumnos = data
      if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
        setStudent(prevStudent => ({...prevStudent}))
        const message = data?.message || response?.message
        setToast({toastProps: {severity: "error", open: true, message}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }

      if (isEmpty(alumnos)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }
      setLoading(false)
      const dataFormat = alumnos.map(row => ({
        ...row,
        id: row.secuencia + '-' + row.matricula + '-' + row.proceso + '-' + row.programa + '-' + row.estatus,
        sincronizacion: row.sincronizacion === 'S' ? 'Sincronizado' : 'No Sincronizado',
        ou: payload.ou,
      }))
      setState({data: dataFormat, dataSource: dataFormat})
    } catch (err) {
      setLoading(false)
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
      } else {
        setToast({toastProps: {severity: "error", open: true, message: err.message}})
      }
    }
  }

  const getCatalogues = async (ou) => {
    try {
      Promise.allSettled([

        DegreeServiceSDK.postCatalogueDynamic(ou, "estatus").then((value) => ({
          "status": sort((a,b) => a.order_process - b.order_process,value.data.filter(c => c.id === STATUS_PROCESS.DEGREE_STAMPED || !c.long_description.includes('Estatus Proveedor'))).map(c => {
            STATUSPROC = {...STATUSPROC, [c.id]: c.description}
            return {id: c.id.toString(), label: c.description, value: c.id.toString()}
          })
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "campus").then((value) => ({
          "campus": value.data.map(c => ({
            id: c.id.toString(),
            label: c.description,
            value: c.id.toString()
          }))
        }), (reason) => (console.error('ERROR', reason))),
      ]).then((results) => results.forEach((result) => {
          let catalogue = result.value
          setCatalogues((prevState) => ({
            ...prevState,
            ...catalogue
          }))
          setLoadingCatalogue(false)
          return
        }
      )).then(() => {
      })
    } catch (err) {
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
      } else {
        setToast({toastProps: {severity: "error", open: true, message: err.message}})
      }
      setLoadingCatalogue(false)
    }
  }

  const getCampus = async (ou) => {
    try {
      const payload = {ou}
      const {data} = await DegreeServiceSDK.getCampusCatalogue(payload);
      const formatCampus = data.map(camp => ({
        id: camp.stvcampCode,
        label: camp.stvcampDesc,
      }))
      setCampus(formatCampus)

    } catch (err) {
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
      } else {
        setToast({toastProps: {severity: "error", open: true, message: err.message}})
      }
    }
    setLoadingCamp(false)
  }

  const handleFilters = () => {
    setLoading(true);
    setBtnActions({id: 'ALL', label: 'Todos'});
    setState({data: [], dataSource: []});
    setIsCheckBox(false);
    setShowFilter(false)
    getAllRequests();

  }
  const onChangeSearch = (e) => {
    e.preventDefault();
    const {target: {value}} = e
    let newInput = value.replace(/" "|\n|\t+/g, "")
    newInput = newInput.replace(/\s/g, "");

    setInputSearch(newInput);
    let newSelectedFilters = clone(selectedFilters)
    if (isEmpty(newInput) && newSelectedFilters.ids !== undefined) {
      delete newSelectedFilters.ids
    } else {
      newSelectedFilters.ids = newInput
    }
    setSelectedFilters(newSelectedFilters);
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFilters();
    }
  }

  const handleDeleteChip = (chipToDelete) => () => {
    setFilters(() => filters.filter((chip) => JSON.stringify(chip) !== JSON.stringify(chipToDelete)));
    setSelectedFilters((prevState) => {
      const newState = {...prevState};
      const value = Object.keys(chipToDelete)[0];
      delete newState[valueFilterQueryStringURL[value]];
      return newState;
    });
  }

  const handleChangeFilter = (e) => {
    let {target: {value, name, type}} = e
    if (name === 'ou') {
      setLoadingCatalogue(true)
      setLoadingCamp(true)
      setCampus([])
      //getCampus(value)
      getCatalogues(value)
    }
    if ( type === 'date'){
      value = dateFormat(value || '', DATE_FORMAT_LOCAL, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
    }
    let filter = {};
    filter[name] = value;
    filter['label'] = `${valueFilterLabels[name]}: ${value}`;
    setFilters([...filters, filter]);
    setSelectedFilters({...selectedFilters, [valueFilterQueryStringURL[name]]: value});
  }


  const handleFilter = () => {
    setLoading(true);
    setShowFilter(false);
    setFilteCss({});
    setState({data: [], dataSource: []});
    setIsCheckBox(false);
    getAllRequests();
  }

  const handleChangeDataSelected = (row) => {
    setIsDisabledDegree(false);
    let newSelectedList = clone(selectedList)
    let isSelected = selectedList.filter(data => data.id === row.id)
    setIsSelectedAll(false)
    if (!!isSelected.length) {
      let index = findIndex(propEq(row.id, 'id'))(newSelectedList)
      if (index > -1) {
        newSelectedList.splice(index, 1)
      }
    } else {

      newSelectedList.push({
        "id": row.secuencia + '-' + row.matricula + '-' + row.proceso + '-' + row.programa + '-' + row.estatus,
        "matricula": row.matricula,
        "estatus": row.estatus || "",
        "program": row.programa || "",
        "seq": row.secuencia || "",
        "ou": selectedFilters.ou
      })
    }
    setSelectedList(newSelectedList)
  };

  const handleChangeAllDataSelected = (isChecked) => {
    setIsDisabledDegree(true);
    let newSelectedList = clone(selectedList)
    if (isChecked) {
      setIsSelectedAll(true)
      newSelectedList = []
      state.data.forEach(row => newSelectedList.push({
        "id": row.secuencia + '-' + row.matricula + '-' + row.proceso + '-' + row.programa + '-' + row.estatus,
        "matricula": row.matricula,
        "estatus": row.estatus || "",
        "program": row.programa || "",
        "seq": row.secuencia || "",
        "ou": selectedFilters.ou
      }))
    } else {
      newSelectedList = []
    }
    setSelectedList(newSelectedList)

  };

  const handleClickStatus = opt => {
    let checkBox = true
    let data = []
    setSelectedList([]);
    setBtnActions(opt)
    data = state.dataSource.filter(row => !!stringContainsExactlyOf(row.estatus, opt.condition.status))
    if (isEmpty(data)) {
      setMessageTable(prevState => ({
        ...prevState,
        data: {
          caption: 'No se encuentra ninguna matrícula para esta acción.',
          subtitle: 'Aplica otro criterio de búsqueda.'
        }
      }))
    } else {
      setMessageTable(prevState => ({...prevState, data: prevState.default}))
    }
    setState(prevState => ({...prevState, data}))
    setIsCheckBox(checkBox)
    setLoading(false)

  };

  const hanledActions = (mod) => {

    setLoadingStudents(true)
    const formatIds = join(',', selectedList.map(r => r.matricula))
    const formatList = selectedList.map(r => r.id)
    const students = state.data.filter(student => !!stringContainsExactlyOf(student.id, formatList))
    const studentsSeq = join(',', students.map(s => s.secuencia || null))
    let payload = {ou: selectedFilters.ou, secuencias: studentsSeq}

    handledReportMet(payload)
  };

  const handledReportMet = async (payload) => {
    try {

      const {data} = await DegreeServiceSDK.postReportMet(payload)
      const newDataSource = state.dataSource.map(r => {
        if (!isNil(r.secuencia) && stringContainsExactlyOf(r.secuencia.toString(), payload.secuencias.split(","))) {
          r.estatus = STATUS_PROCESS.REPORT_MET
          r.estatusDesc = STATUSPROC[STATUS_PROCESS.REPORT_MET]
        }
        return {...r}
      })
      setState(preState => ({...preState, dataSource: newDataSource}))
      setLoadingStudents(false)
      setLoading(true);
      const groupByCampus = groupWith((a, b) => a.campus === b.campus, data)
      groupByCampus.forEach(async (campus) =>
        await writeXlsxFile(campus, {
          schema: excelReportMetColumns, // (optional) column widths, etc.
          fileName: `Reporte de REPORTE MET ${campus[0].campus} ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`
        })
      )

      handleClickStatus(btnActions)
    } catch (err) {
      setToast({toastProps: {severity: "error", open: true, message: err.message}})
      setLoadingStudents(false)
      setLoading(true);

    }
  }

  const hanledFilterUrl = (params) => {
    let filters = [];
    Object.keys(params).forEach((k, i) => {
      let filter = {};
      if (valueFilterLabels[k]) {
        filter[k] = params[k];
        filter['label'] = `${valueFilterLabels[k]}: ${params[k]}`;
        filters.push(filter);
      }
    });
    setFilters(filters);
  }

  const handleFinishedToast = () => {
    setToast({toastProps: {open: false, message: ''}})
  }

  const handleClickInfoTable = (data) => {
    console.log(data)
  }

  const disabledDates = (_date) => {
    const date = dateFormat(_date, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
    let isDisabled = false;
 /*   const daysHolding = [
      "2023-01-01",
      "2023-02-05",
      "2023-03-21",
      "2023-05-01",
      "2023-09-16",
      "2023-11-20",
      "2023-12-01",
      "2023-12-25",
      "2024-01-01",
      "2024-02-05",
      "2024-03-21",
      "2024-05-01",
      "2024-09-16",
      "2024-11-20",
      "2024-12-01",
      "2024-12-25",
    ]
    daysHolding.forEach(_holdingDate => {
      const holdingDate = dateFormat(_holdingDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
      if (moment(date).isSame(holdingDate)) {
        isDisabled = true
      }
    })*/
    if (moment(date).day() === 0) {
      isDisabled = true
    }
    return isDisabled
  }
  return (
    <>
      <Card  className={classes.cardHeader}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Paper component="form" className={classes.search}>
                <IconButton className={classes.iconButton} aria-label="search" onClick={handleFilters}>
                  <SearchIcon/>
                </IconButton>
                <InputBase
                  onChange={onChangeSearch}
                  className={classes.textArea}
                  multiline
                  maxRows={4}
                  placeholder={`Buscar matriculas separado por comas`}
                  name={'searchUser'}
                  value={inputSearch}
                  onKeyDown={onKeyDown}
                  // inputProps={{ 'aria-label': 'search google maps' }}
                  InputProps={{
                    className: classes.bioText,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper component="form" className={classes.buttons}>
                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Button
                        color="secondary"
                        variant="outlined"
                        className={clsx(showFilter && classes.filterButton)}
                        disabled={loading}
                        onClick={() => {
                          setShowFilter(!showFilter)
                        }}
                        size="small"
                      >
                        <Typography variant='body2'> Filtro Avanzado</Typography>
                        <Icon className={clsx("text-white", "ri-equalizer-line", 'font-size-20')} fontSize='small'
                              style={{marginLeft: "6px"}}/>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Dropdown
                        options={STATUES_FILTER.filter(r => selectedFilters.status === STATUS_PROCESS.DEGREE_STAMPED ? r.id === STATUS_PROCESS.REPORT_MET : false)}
                        onClickOption={(data) => {
                          setLoading(true);
                          handleClickStatus(data);
                        }}
                        text={btnActions?.label || 'Acciones'}
                        endIcon={<ArrowDropDownIcon className='font-size-24'/>}
                        style={{height: 40}}
                        variant='contained'
                        className={clsx("bg-fill-stroke-300", classes.button)}
                        disabled={loading || !stringContainsExactlyOf(selectedFilters.status, [ STATUS_PROCESS.DEGREE_STAMPED, STATUS_PROCESS.CERTIFICATE_STAMPED]) || disabledOptions}
                        // disabled={!state.data.length}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{height: 40}}
                        className={classes.button}
                        onClick={() => hanledActions(btnActions?.id)}
                        size="small"
                        disabled={((!selectedList.length || isEmpty(btnActions)) || btnActions?.label === 'ALL') || loadingStudents}
                      >
                        <Typography variant='body2'>Confirmar</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ChipFilter chipData={filters} handleDelete={(e) => handleDeleteChip(e, valueFilter)}/>
              </Grid>
            </Grid>
            {showFilter && (
              <Grid container className={classes.grid} spacing={1}>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Select items={SOURCE_COMPANY_DEGREE_CERTIFICATE} label="Marca"
                            name="ou" onChange={(e) => {
                      handleChangeFilter(e)
                    }} disabled={!isNil(selectedFilters.ou)} value={!isNil(selectedFilters.ou) ? selectedFilters.ou : ''}
                            size="small"/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Select items={SOURCE_ACADEMIC_DEGREE_CERTIFICATE} label="Nivel Académico"
                            name="level" onChange={(e) => {
                      handleChangeFilter(e)
                    }} disabled={!isNil(selectedFilters.level)}
                            value={!isNil(selectedFilters.level) ? selectedFilters.level : ''} size="medium"/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Select items={catalogues.campus} label="Campus" name="campus"
                            onChange={(e) => {
                              handleChangeFilter(e)
                            }} disabled={!isNil(selectedFilters.campus) || !catalogues.campus.length}
                            loading={loadingCatalogue}
                            value={!isNil(selectedFilters.campus) ? selectedFilters.campus : ''} size="medium"/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Select items={SOURCE_PROCESS_DEGREE_CERTIFICATE} label="Proceso"
                            name="process" onChange={(e) => {
                      handleChangeFilter(e)
                    }} disabled={!isNil(selectedFilters.process)}
                            value={!isNil(selectedFilters.process) ? selectedFilters.process : ''} size="medium"/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Select items={catalogues.status} label="Estatus" name="status"
                            onChange={(e) => {
                              handleChangeFilter(e)
                            }}
                            disabled={!catalogues.status.length || !isNil(selectedFilters.status)}
                            loading={loadingCatalogue}
                            value={!isNil(selectedFilters.status) ? selectedFilters.status : ''} size="medium"/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Picker
                      className={classes.datePicker}
                      name="dateStart"
                      value={dateFormat(selectedFilters.dateStart || '', ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                      onChange={(e) => {
                        e.target['type'] = "date";
                        handleChangeFilter(e)
                      }}
                      onClick={e => e.stopPropagation()}
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      autoOk={false}
                      views={['year', 'month', 'date']}
                      keyboardbuttonprops={{
                        "aria-label": "change date"
                      }}
                      label={'Fecha Inicio'}
                      disabled={!isNil(selectedFilters.dateStart)}
                      shouldDisableDate={disabledDates}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>
                            <Icon className={clsx('text-white-200 ri-calendar-2-line mr-1')}
                                  style={{fontSize: 18}}/>
                          </InputAdornment>)
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Picker
                      className={classes.datePicker}
                      name="dateEnd"
                      value={dateFormat(selectedFilters.dateEnd || '', ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                      onChange={(e) => {
                        e.target['type'] = "date";
                        handleChangeFilter(e)
                      }}
                      onClick={e => e.stopPropagation()}
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      autoOk={false}
                      views={['year', 'month', 'date']}
                      keyboardbuttonprops={{
                        "aria-label": "change date"
                      }}
                      label={'Fecha Fin'}
                      disabled={!isNil(selectedFilters.dateEnd)}
                      shouldDisableDate={disabledDates}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>
                            <Icon className={clsx('text-white-200 ri-calendar-2-line mr-1')}
                                  style={{fontSize: 18}}/>
                          </InputAdornment>)
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-38`}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{marginLeft: '20%', width: '65%'}}
                      onClick={() => handleFilter()}
                      size="small"
                      disabled={loading}
                    >
                      <Typography variant='body2'>Aplicar</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <LoaderContent loading={loading} minHeight={500}>
          {state.data.length > 0 ?
            <Card className={classes.CardTable}>
              <React.Fragment>
                <Table
                  checkboxs={isCheckBox}
                  maxHeight={'800px'}
                  columns={columns}
                  rows={state.data}
                  // onClickSort={handleClickSort}
                  rowSelectedList={selectedList}
                  onChangeSelectedRow={handleChangeDataSelected}
                  onChangeAllSelectedRow={handleChangeAllDataSelected}
                  checkboxDisabledIcon={true}
                  handleClickInfoTable={handleClickInfoTable}
                />
              </React.Fragment>
            </Card>
            :
            !loading && <NothingFound width='200px'
                                      caption={messageTable.data.caption}
                                      subcaption={messageTable.data.subtitle}/>}
        </LoaderContent>
      <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={5000}/>
    </>);
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StatusTablePage);
