import { getSessionCookie } from '../../utils/Sessions.js';
import {getEventsCat} from './hubspotEventsCat.js'
//SDK
import { HubspotService } from '@sdk-point/talisis';

const HubspotServiceSDK = new HubspotService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const currentURL = window.location.href;

let actions = {
    track: async (eventName, eventData) => {
      const sessionCookie = getSessionCookie();
      const data = {
        email: sessionCookie?.email?.length > 0 ? sessionCookie.email : 'digital@talisis.com',
        eventName: getEventsCat(eventName),
        properties: {
            hs_page_url:  currentURL.substring(0, 1024),      
            ...eventData // Datos adicionales del evento que quieras enviar
        }
      };

      HubspotServiceSDK.hubspotTracking(data);
    }
}

let Hubspot = actions

export {Hubspot}