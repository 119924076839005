import { makeStyles } from "@material-ui/core";

export const profileStyles = makeStyles((theme) => ({
    profileInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
            cursor: 'pointer'
          },
    },
    iconsRedes: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginTop: '62px',
        '& > *': {
            cursor: 'pointer'
          },
    },
    iconDisabled: {
        backgroundColor: '#1C1F24' ,
        border: '1px solid #404150',
        color: '#404150',
    },
    facebookEnabled: {
        backgroundColor: '#3b5998',
        color: 'white'
    },
    instagramEnabled: {
        // background: '#d6249f',
        background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
        boxShadow: '0px 3px 10px rgba(0,0,0,.25)',
        color: 'white'
    },
    twitterEnabled: {
        backgroundColor: '#1DA1F2',
        color: 'white'
    },
    linkedinEnabled: {
        backgroundColor: '#0A66C2',
        color: 'white'
    },
    buttonTwitterX:{
        padding: 0,
        minWidth: 0
    }
}));
