import React, { Component } from 'react';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

/// core components
import Workflow from '../Workflow/Workflow'

// shared - core components
import Breadcrumb from '../../../../../shared/components/Breadcrumb/Breadcrumb'
import AutocompleteInput from '../../../../../shared/components/AutocompleteInput/AutocompleteInput'
import Select from '../../../../../design-system/components/Form/Select/Select'
import MultipleSelect from '../../../../../shared/components/Form/MultipleSelect'
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent'
import IncrementButton from '../../../../../shared/components/IncrementButton/IncrementButton';
import DividerDetail from '../../../../../shared/components/DividerDetail/DividerDetail';
import { log } from '../../../../../shared/utils/console.js'

// @redux
import { connect } from "react-redux"
import dateFormat from '../../../../../design-system/utils/dateFormat';
//SDK
import { GlobalService,RequestService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const DELETE = 2;
const SCOPE_OU = 1;

const types = [
    {id: 1, name: 'para Alumno'},
    {id: 2, name: 'para Colaborador'},
]

function RequestDate(data){
    return (
        <Box display="flex" alignItems="center" marginLeft="auto">
            <Typography gutterBottom variant="h6" component="div">
                <Typography color="textSecondary" component="span">Fecha de Creacion:</Typography> {dateFormat(data.data.createdAt)}
            </Typography>
        </Box>   
    )
}

const BodyRequest = (props) => {

    const [state, setState] = React.useState({
        loading: true,
        form: {
            createdAt: null,
            title: '',
            ou: [],
            responsable: {},
            duration: 1,
            description: '',
            type: null
        },
        workflow:[],
        breadcrumbItems: [
            {to: '/gestion-de-servicios', label: 'Administracion de Trámites'},
            {label: 'Flujo de autorización', title:''}
        ],
        errors:{},
        path: '',
        tabs: 'Servicio'
    });

    const [autocomplete, setAutocomplete] = React.useState({
        persons: [],
        criteria: '',
        loading: false
    })

    const [units, setUnits] = React.useState([])

    React.useEffect(() => { 
        let {tabs} = state;

        if(props.form && props.form.type){
            tabs = [
                `Servicio ${types.find(it => it.id === props.form.type).name}`
            ];
        }

        setState({...state, ...props, tabs});
        getOUs();
        
    }, [props]);

    const getOUs = async () => {
        const units = await GlobalServiceSDK.getCatalog('CompanyCat');
        setUnits(units.map(it => ({...it, label: it.name})))        
    }

    const getPersons = async () => {
        setAutocomplete(prevstate => ({...prevstate, loading: true}));

        try {
            const persons = await RequestServiceSDK.searchPerson(autocomplete.criteria);
            setAutocomplete({persons, loading: false});
        } catch (e) {
            setAutocomplete({persons: [], loading: false});
        }
    };

    const handleSubmit = () => {
        if(validate()){
            const {form, workflow} = state;

            const body = {
                person_responsible_id: state.form.responsable.id,
                area: 'Transformación Digital',
                company_id: state.form.ou.map(it => it.id),
                title: state.form.title,
                steps: [],
                type_id: state.form.type,
                duration: state.form.duration,
                description: state.form.description,
                area_id: 1
            }

            for(const step of workflow){

                const authorizers = [];
                let form_value = {};
                for(const f of step.fields){
                    form_value[f.name] = f.value;
                    delete f.disabled;
                }

                if(step.assignToApplicant){
                    authorizers.push({
                        scope_ids: form.ou.map(it => it.id),
                        scope_type_id: 3,
                        person_id: [{"id": 0}]
                    });
                }
                if(step.authorizers.length > 0){
                    for(const a of step.authorizers){
                        authorizers.push({
                            scope_ids: a.scope_type_id === SCOPE_OU ? a.scope_ids : a.scope_ids.map(it => it.id),
                            scope_type_id: a.scope_type_id,
                            authorizer_type_id: a.authorizer_type_id,
                            person_id: [a.person]
                        });
                    }
                }

                body.steps.push({
                    id: step.id,
                    title: step.title,
                    form: step.fields,
                    form_value,
                    instruction: step.instruction,
                    emailmsg: step.emailmsg,
                    type: step.type,
                    authorizers,
                    request_type_id: null
                })
            }

            props.onSubmit(body);
        }
    }

    const hanldeInputChange = (value) => {
        setAutocomplete({criteria: value})
    }

    const handleChangeAutocomplete = (value) => {
        props.onChange({
            target:{
                name: 'responsable', value
            }
        })
    }

    const validate = () => {
        const errors = {};
        const requiredFields = ['title', 'responsable', 'ou', 'duration'];
        const { form, workflow } = state;

        for(const field of requiredFields){
            if(!form[field]){
                errors[field] = 'Campo Requerido';
            }
        }

        if (!workflow.length) {
            errors.workflow = '* Flujo Requerido';
        }
        
        if(!form.ou.length) {
            errors.ou = 'Campo Requerido';
        }else{
            const valido = validateAuthorizersPerOU(form, workflow)
            if (valido == 0 && !errors.workflow){
                errors.workflow = '* Existen unidades operativas sin autorizador favor de agregar al menos uno para cada unidad';
            }
        }

        props.onChangeErrors(errors)

        return Boolean(Object.keys(errors).length === 0);
    }

    const validateAuthorizersPerOU = (form, workflow) => {
        let val = 0;
        for (const wf of workflow){
            if (!wf.assignToApplicant){
                for (const ou of form.ou){
                    val = 0;
                    if (wf.authorizers.some(c => c.parent === ou.id)){
                        val = 1;
                    }else {
                        break;
                    }
                }
                if (val == 0){
                    return val;
                }
            }else{
                log('entro')
                return 1;
            }
        }
        return val;
    }

    const handleSelectOU = (value) => {
        handleDeleteOuStep(value)
        props.onChange({target:{
            name: 'ou',
            value
        }})
    }

    // validacion modificar step
    const handleDeleteOuStep = (ou) => {
        const {form, workflow } = state;
        if (form.ou.length > ou.length){
            if (workflow.length > 0){
                for (const step of workflow){
                    const authorizers = [];
                    const assignee = [];

                    for(const a in step.authorizers){
                        if (ou.some(ou => ou.id === step.authorizers[a].parent)){
                            authorizers.push(step.authorizers[a]);
                        }
                    }
                    for(const a in step.assignee){
                        if (ou.some(ou => ou.id === step.assignee[a].id)){
                            assignee.push(step.assignee[a]);
                        }
                    }
                    step.assignee = assignee;
                    step.authorizers = authorizers;
                    props.onChangeStep(step);
                }
            }
        }
    }

    const onClickIncrement = value => {
        props.onChange({target:{name:'duration', value}})
    }

    return (
        <div>
            <Breadcrumb items={state.breadcrumbItems} />

                <DividerDetail title={state.tabs} mb={5}>
                    <RequestDate data={state.form}/>
                </DividerDetail>

                <Grid container spacing={3}>
                    <Grid container item xs={12}>
                        <LoaderContent loading={state.loading}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        required
                                                        margin="dense"
                                                        variant="outlined"
                                                        label="Titulo del Servicio"
                                                        fullWidth
                                                        name="title"
                                                        value={state.form.title}
                                                        onChange={props.onChange}
                                                        error={Boolean(state.errors.title)}
                                                        helperText={state.errors.title}
                                                        disabled={props.readOnly}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <MultipleSelect
                                                        required
                                                        menuItems={units}
                                                        inputLabel="Unidad Operativa"
                                                        name="ou"
                                                        itemsSelected={state.form.ou}
                                                        onSelect={handleSelectOU}
                                                        error={Boolean(state.errors.ou)}
                                                        helperText={state.errors.ou}
                                                        disabled={props.readOnly}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    {
                                                        state.loading ?
                                                        <TextField
                                                                required
                                                                margin="dense"
                                                                variant="outlined"
                                                                label="Responsable"
                                                                fullWidth
                                                                name="responsable"
                                                                value={state.form.responsable.full_name || ''}
                                                                placeholder="Agregar responsable"
                                                                disabled={props.readOnly}
                                                            />

                                                        :
                                                        <AutocompleteInput 
                                                            required
                                                            margin="dense"
                                                            variant="outlined"
                                                            label="Responsable"
                                                            fullWidth
                                                            name="responsable"
                                                            //value={state.form.responsable.full_name || ''}
                                                            placeholder="Agregar responsable"
                                                            optionLabel="full_name"
                                                            options={autocomplete.persons}
                                                            loading={autocomplete.loading}
                                                            criteria={autocomplete.criteria}
                                                            getData={getPersons}
                                                            onInputChange={hanldeInputChange}
                                                            onChange={handleChangeAutocomplete}
                                                            error={state.errors.responsable}
                                                            defaultValue={state.form.responsable}
                                                            disabled={props.readOnly}
                                                        />
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <IncrementButton 
                                                        label='Dias de Entrega '
                                                        initialCounter={state.form.duration}
                                                        value={state.form.duration}
                                                        onClickDecrease={onClickIncrement}
                                                        onClickIncrease={onClickIncrement}
                                                        min={0}
                                                        readOnly={props.readOnly}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        margin="dense"
                                                        variant="outlined"
                                                        label="Descripcion"
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        name="description"
                                                        required
                                                        onChange={props.onChange}
                                                        error={Boolean(state.errors.description)}
                                                        helperText={state.errors.description}
                                                        value={state.form.description}
                                                        disabled={props.readOnly}
                                                    />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Grid item xs={12}>
                                                {
                                                    props.readOnly ? 
                                                    <Workflow 
                                                        showStepPath={state.path}
                                                        steps={state.workflow}
                                                        readOnly 
                                                    /> :
                                                    <Workflow
                                                        path={state.path} 
                                                        onChange={props.onChangeWorkflow}
                                                        onClickAction={props.onClickActionStep} 
                                                        onDragSteps={props.onDragSteps}
                                                        error={state.errors.workflow} 
                                                        steps={state.workflow}
                                                        disabledButton={!state.form.ou.length}
                                                    />     
                                                }     
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card> 
                        </LoaderContent>
                    </Grid>

                    <Grid container item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            {
                                !props.readOnly && 
                                <Button color="primary" variant="contained" onClick={handleSubmit}>Guardar</Button>

                            }
                        </Box>
                    </Grid>
                </Grid>
        </div>
    );
}

export default BodyRequest;