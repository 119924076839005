import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    boxComboOwnBrands: {
        marginTop:'20px',
        width: '252px',
        height: '48px',
        top: '-4px',
        left: '20px',
        gap: '0px',
        borderRadius: '8px',
        border: `0.5px solid #F6BE00`,
        opacity: '0px'
        
    },
    titleOwnBrandsLite: {
        marginTop:'-15px',
        marginLeft:'70px',
        width: '110px',
        height: '32px',
        top: '4px',
        left: '95.5px',
        padding: '4px 8px 4px 8px',
        gap: '4px',
        opacity: '0px',
        background: theme.palette.colors.fillStroke[400]
    },
    titleOwnBrandsProdWhite:{
        marginTop:'-20px',
        marginLeft:'15px',
        width: '220px',
        height: '32px',
        top: '4px',
        left: '40px',
        gap: '4px',
        opacity: '0px',
        background: theme.palette.colors.fillStroke[400]
    },
    titleOwnBrandsProdYellow:{
        marginTop:'-15px',
        marginLeft:'15px',
        width: '220px',
        height: '25px',
        color: '#F6BE00',
        background: theme.palette.colors.fillStroke[400]
    },
    boxComboOwnBrandsPreMBA:{
        width: '103px',
        height: '48px',
        top: '18px',
        left: '20px',
        gap: '0px',
        borderRadius: '8px',
        border: '0.5px solid #7571CA',
        opacity: '0px',
        marginRight:'5px'
    },
    boxComboOwnBrandsAllProgram:{
        width: '142px',
        height: '48px',
        top: '18px',
        left: '135px',
        gap: '0px',
        borderRadius: '8px',
        border: '0.5px solid #F6BE00',
        opacity: '0px'
        
    },
    titleBoxComboOwnBrandsPreMBA:{
        width:  '51px',
        height: '32px',
        marginTop : '-15px',
        marginLeft: '25px',
        color: theme.palette.colors.blue[300],
        background: theme.palette.colors.fillStroke[400]
    },
    titleBoxComboOwnBrandsAllProgram:{
        width: '97px',
        height: '40px',
        marginTop : '-19px',
        marginLeft: '20px',
        ...theme.typography.bodyTextSmallSemiBold,
        background: theme.palette.colors.fillStroke[400],
    },
    boxComboCoursesAllies: {
        marginTop:'20px',
        width: '252px',
        height: '80px',
        top: '3px',
        left: '20px',
        gap: '0px',
        borderRadius: '8px',
        border: `0.5px solid  ${theme.palette.colors.violet[300]}`,
        opacity: '0px'
        
    },
    boxComboConecta: {
        marginTop: '20px',
        width: '252px',
        height: '48px', 
        borderRadius: '8px',
        border: `0.5px solid ${theme.palette.colors.blue[300]}`  
    },
    logoSource: {
        width: '38.69px',
        height: '12px',
        top: '39.5px',
        left: '131px',
        [theme.breakpoints.down('sm')]:{
            left: '5px',
        },
        gap: '0px',
        opacity: '0px'
    },
    logoSourceUerre :{
        width: '57.6px',
        height: '12px',
        top: '40px',
        left: '43px',
        gap: '0px',
        opacity: '0px' 
        
    }
}))