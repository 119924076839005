import React from 'react';
import { useSelector } from 'react-redux';

// @shared/hooks
import { useConekta } from '../../../../../shared/hooks';

// @shared/components
import AddPaymentCard from '../../../../../shared/components/AddPaymentCard/AddPaymentCard';
import Toast from '../../../../../shared/components/Toast/Toast';

// @mui
import { Box, makeStyles, Typography } from '@material-ui/core';

// constants
import { CONEKTA_PUBLIC_KEYS } from '../../../../../shared/constants/conekta';

// @SDK
import { UserService } from '@sdk-point/talisis';
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.colors.grey[500],
        border: `0.5px solid ${theme.palette.colors.grey[400]}`,
        borderRadius: '8px',
    },
    rootLabel: {
        padding: '24px',
    },
    title: {
        color: theme.palette.colors.violet[300],
    },
    line: {
        border: 0,
        borderBottom: `0.5px solid ${theme.palette.colors.grey[50]}`,
        margin: 0,
    },
}));

const AddPaymentMethod = (props) => {
    const { user } = useSelector((state) => state.userReducer);
    const classes = useStyles();
    
    const conekta = useConekta();
    const conektaAccounts = ['UNID', 'UERRE'];

    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({
        card_holder: '',
        card_number: '',
        card_email: '',
        card_expiration: '',
        card_expiration_month: '',
        card_expiration_year: '',
        card_cvv: '',
    });
    const [submitted, setSubmitted] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleInput = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let message = '';
        
        const cardNumber = data.card_number.replaceAll(' ', '');
        if (!data.card_holder || !data.card_number || !data.card_expiration || !data.card_cvv) {
            message = 'Favor de completar los campos requeridos';
        }
        else if (!/^[a-zA-Z\u00F1\u00D1]+ [a-zA-Z\u00F1\u00D1\s]+$/.test(data.card_holder)) {
            message = 'El nombre en la tarjeta tiene un formato inválido';
        }
        else if (String(cardNumber).length < 15 || String(cardNumber).length > 17) {
            message = 'El número de tarjeta es inválido';
        }
        else if (!/^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(data.card_expiration)) {
            message = 'La fecha de vencimiento es inválida';
        }
        else if (String(data.card_cvv).length != 3) {
            message = 'El código de seguridad es inválido';
        }

        return message;
    };

    const successToken = async (token) => {
        return token.id;
    };

    const cardExists = async () => {
        let exists = false;
        const cards = await UserServiceSDK.conektaGetCustomerCard(user.person_id);
        if (cards) {
            const cardNumber = data.card_number.replaceAll(' ', '');
            cards.map(card => {
                if (card[0].last4 === cardNumber.slice(-4)) {
                    exists = true;
                }
            });
        }

        return exists;
    };

    const getTokens = async () => {
        let tokens = {};
        for (const account of conektaAccounts) {
            conekta.setPublicKey(CONEKTA_PUBLIC_KEYS[account]);
            conekta.createToken({
                card: {
                    number: data.card_number.replaceAll(' ', ''),
                    name: data.card_holder,
                    exp_month: String(data.card_expiration).split('/')[0],
                    exp_year: String(data.card_expiration).split('/')[1],
                    cvc: data.card_cvv
                }
            },
            async (token) => {
                const token_id = await successToken(token);
                tokens[account] = token_id;
            },
            errorToken);
        }
        
        return tokens;
    };
    
    const errorToken = async (e) => {
        setToastProps(true, e.message_to_purchaser);
        return false;
    };

    const addCustomerCards = async (tokens) => {
        await UserServiceSDK.conektaAddCustomerCard(user.person_id, tokens);
        await props.getUserCard();
        setSubmitted(false);
    };

    const redirect = () => {
        props.setForm('');
    };

    const handleSubmit = async () => {
        const errorMessage = validate();
        if (errorMessage) {
            setToastProps(true, errorMessage);
            return false;
        }

        setSubmitted(true);
        
        const exists = await cardExists();
        if (exists) {
            setToastProps(true, 'Ya existe el método de pago. Prueba con uno diferente.');
            setSubmitted(false);
            return false;
        }

        const tokens = await getTokens();
        await addCustomerCards(tokens);
        redirect();
    };

    const setToastProps = (open, message) => {
        setOpen(open);
        setErrorMessage(message);
    };

    const handleFinishedToast = () => {
        setToastProps(false, '');
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.rootLabel}>
                <Typography variant='h5' className={classes.title}>Agregar tarjeta de débito o crédito</Typography>
            </Box>
            <hr className={classes.line} />
            <AddPaymentCard data={data} enableTitle={false} enableActionButtons={true} onBackAction={() => { props.setForm('') }} handleInput={handleInput} handleSubmit={handleSubmit} submitted={submitted} />
            <Toast severity="error" open={open} message={errorMessage} onFinished={handleFinishedToast} duration={3000} />
        </Box>
    )
};

export default AddPaymentMethod;