import React from 'react';

/* @material-ui */
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// assets
import useStyles from '../../../../../../assets/styles/jss/pages/checkout/checkout';

const PaymentMethodItemSkeleton = () => {
    const classes = useStyles();

    return (
        <Grid container alignItems='center' className={classes.paymentMethodItemSkeleton} spacing={3}>
            <Grid item>
                <Skeleton variant="circle" width={26} height={26} /> {/* radio */}
            </Grid>
            <Grid item className="ml-2">
                <Skeleton variant="text" width={300} height={24} /> {/* text */}
            </Grid>
        </Grid>
    );
};

export default PaymentMethodItemSkeleton;