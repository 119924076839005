import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

// shared - core components
import ConfirmDialog from '../../../../../shared/components/ConfirmDialog/ConfirmDialog'
import Select from '../../../../../design-system/components/Form/Select/Select'

// constants
import {REQUEST_STATUS_ID, REQUEST_STEP_TYPE_ID} from '../../../../../shared/constants/request-status'
import { HTTPMessageHandler } from '@microsoft/microsoft-graph-client';
import { CardContent } from '@material-ui/core';


const REQUEST_STEP_TYPE_EMAIL = 3;

function showRejectBttn(workflow){
    const activeStep = workflow.find(it => it.is_active) || {};
    const conditions = (activeStep.sequence !== 1 && activeStep.request_step_type_id !== REQUEST_STEP_TYPE_ID.DOCUMENTS)
    return conditions;
}

const getPreviousSteps = (workflow) => workflow.slice(0, workflow.findIndex(x => x.is_active))
    .filter(s => s.request_step_type_id !== REQUEST_STEP_TYPE_EMAIL)
    .map(it => ({id: it.id,label: it.title}));

//
const validateApproveButton = (isError, rejectDisabledDocument, step, rejectDisabledPhysicalDocument) => {;
    switch(step) {
        case REQUEST_STEP_TYPE_ID.DIGITAL_DOCUMENT_REVIEW:
            return rejectDisabledDocument.responseIsCompleted? rejectDisabledDocument.responseIsCompleted: isError;
        case REQUEST_STEP_TYPE_ID.PHYSICAL_DOCUMENT_REVIEW:
            return rejectDisabledPhysicalDocument.responseIsCompleted? rejectDisabledPhysicalDocument.responseIsCompleted: isError;
        default:
            return isError;
    }
};

const validateRejectButton = (isError, rejectDisabledDocument, step, rejectDisabledPhysicalDocument) => {

    switch(step) {
        case REQUEST_STEP_TYPE_ID.DIGITAL_DOCUMENT_REVIEW:
            return rejectDisabledDocument.responseHasData? rejectDisabledDocument.responseHasData: isError;
        case REQUEST_STEP_TYPE_ID.PHYSICAL_DOCUMENT_REVIEW:
            return rejectDisabledPhysicalDocument.responseHasData? rejectDisabledPhysicalDocument.responseHasData: isError;
        default:
            return isError;
    }
};

const StatusValidationBttn = (props) => {
    const {onSelect, workflow, disabled, rejectDisabledDocument, rejectDisabledPhysicalDocument, activeStepId} = props;


    const disabledApproveButton = validateApproveButton(disabled, rejectDisabledDocument, activeStepId, rejectDisabledPhysicalDocument);

    const rejectDisabled = validateRejectButton(disabled, rejectDisabledDocument, activeStepId, rejectDisabledPhysicalDocument);


    const [action, setAction] = React.useState(null);
    const [previousSteps, setPreviousSteps] = React.useState([]);

    const [dialogProps, setDialogProps] = React.useState({ 
        title: "",  
        text: "", 
        open: false,
    });
    const [form, setForm] = React.useState({
        statusId: null,
        comments: "",
        returnToStep: ""
    })

    var isNotApproved = action === REQUEST_STATUS_ID.REJECT;
    var disabledConfirmButton = action === REQUEST_STATUS_ID.REJECT && (!form.statusId || (
        (form.statusId === REQUEST_STATUS_ID.REJECT && (Object.values(form).some(it => !it))) || 
        (form.statusId === REQUEST_STATUS_ID.CANCEL && !form.comments)
    ));

    React.useEffect(()=> {
        const prev = getPreviousSteps(workflow);
        setPreviousSteps(prev);
    }, [workflow]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        setForm({
            ...form,
            [name]: value,
        })
    };

    const onClickOption = (action) => () => {
        const {title} = workflow.find(it => it.is_active);
        let dialog = {
            type: "warning", 
            title: 'Rechazar', 
            text: '¿Cuál es el motivo de su rechazo?', 
            open: true,
        }

        if(action === REQUEST_STATUS_ID.APPROVE){
            dialog = {
                ...dialog, 
                title: 'Aprobar',  
                text: <React.Fragment>Esta seguro de aprobar <span className="font-italic">"{title}"</span>?</React.Fragment>, 
            }

            handleChange({target: {name: 'statusId', value: action}})
        }

        setAction(action)
        setDialogProps(dialog);
    }

    const handleClose = () => {
        setDialogProps({ ...dialogProps, open: false });
        setForm({
            statusId: null,
            comments: "",
            returnToStep: ""
        })
    };

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Button 
                        fullWidth
                        color="primary" 
                        variant="contained" 
                        disabled={disabledApproveButton}
                        onClick={onClickOption(REQUEST_STATUS_ID.APPROVE)}>
                            Enviar
                    </Button>
                    {
                        showRejectBttn(workflow) &&
                        <Button 
                            fullWidth
                            color="primary" 
                            variant="outlined" 
                            disabled={rejectDisabled}
                            className="mt-3" 
                            onClick={onClickOption(REQUEST_STATUS_ID.REJECT)}>
                                Rechazar paso
                        </Button>
                    }
                </CardContent>
            </Card>
            <ConfirmDialog {...dialogProps} 
                onConfirm={() => onSelect(form)} 
                onClose={handleClose} 
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                disabledConfirmButton={disabledConfirmButton}
            >
                <Box px={2}>
                    <Grid container spacing={1}>
                        {
                            action === REQUEST_STATUS_ID.REJECT &&
                            <React.Fragment>
                                <Select
                                    required
                                    items={[
                                        {
                                            id: REQUEST_STATUS_ID.REJECT,
                                            label: 'Reasignar paso',
                                            disabled: !previousSteps.length
                                        },
                                        {
                                            id: REQUEST_STATUS_ID.CANCEL,
                                            label: 'Finalizar solicitud',
                                        }
                                    ]}
                                    label="Selecciona una opción"
                                    name="statusId"
                                    value={form.statusId}
                                    onChange={handleChange}
                                />
                                <Select
                                    disabled={form.statusId !== REQUEST_STATUS_ID.REJECT}
                                    required={form.statusId === REQUEST_STATUS_ID.REJECT}
                                    items={previousSteps}
                                    label="Selecciona el paso a reasignar"
                                    name="returnToStep"
                                    value={form.returnToStep}
                                    onChange={handleChange}
                                />
                            </React.Fragment>
                        }
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Agregar comentario"
                                multiline
                                margin="dense"
                                rows={4}
                                name="comments"
                                value={form.comments}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                required={isNotApproved}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </ConfirmDialog>
        </React.Fragment>
    );
};

export default StatusValidationBttn;