import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';

// redux
import { setSelectedInstallmentOption } from '../../../../../redux/actions/checkoutActions';
import { applyCart, applyCartProducts } from '../../../../../redux/actions/cartActions';
import { updateMembership } from '../../../../../redux/actions/userActions';

// routes
import * as paths from '../../../../../routes/paths'

// components
import WorkflowPage from '../../WorkflowPage/WorkflowPage';

// @material-ui
import { Box, Grid, RadioGroup } from '@material-ui/core';

// shared
import { log } from '../../../../../shared/utils/console';
import { isPaymentSuccessful, isPaymentSuccessfulOrPending, processCheckoutPayment, getMethodPayment, getPeriodMembership, getNameMembership } from '../../../../../shared/utils/checkoutHelpers';
import { clearCartCookie } from '../../../../../shared/utils/cartSession';
import { userIsLogged } from '../../../../../shared/utils/Sessions';
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';
// sdk
import { 
    CONEKTA_CARD_TYPES, 
    PAYMENT_METHODS, 
    PAYMENT_STATUS, 
    PAYMENT_TYPES, 
    PRODUCT_CATEGORIES, 
    PRODUCT_TYPES, 
    PaymentService 
} from "@sdk-point/talisis";

// ds
import Toast from '../../../../../design-system/components/Toast/Toast';

// components
import PaymentMethodItem from './PaymentMethodItem/PaymentMethodItem';
import PaymentMethodItemSkeleton from './PaymentMethodItemSkeleton/PaymentMethodItemSkeleton';
import PaymentsConditions from './PaymentConditions/PaymentConditions';
import RecurringPaymentDate from './RecurringPaymentDate/RecurringPaymentDate';
import FinalizePurchase from './FinalizePurchase/FinalizePurchase';
import CardModal from './CardModal/CardModal';
import NewCardForm from './CardModal/NewCardForm/NewCardForm';
import PaymentConfirmationModal from './PaymentConfirmationModal/PaymentConfirmationModal';
import PaymentProcessingModal from './PaymentProcessingModal/PaymentProcessingModal';
import ReferralCouponCodeBanner from '../../../../Referrals/components/ReferralCouponCodeBanner';
import { getCheckoutCookie } from '../../../../../shared/utils/checkoutSession';

const paymentService = new PaymentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const PaymentMethods = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isLogged = userIsLogged();
    const { personInfo } = useSelector(state => state.checkoutReducer);
    const { order, orderProducts } = useSelector(state => state.checkoutReducer);
    const { orderPriceDetails } = useSelector(state => state.checkoutReducer);
    const { installments } = useSelector(state => state.checkoutReducer);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [optionSelected, setOptionSelected] = React.useState('');
    const [acceptPayment, setAcceptPayment] = React.useState(false);
    const [openCardModal, setOpenCardModal] = React.useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
    const [openProcessingModal, setOpenProcessingModal] = React.useState(false);
    const [toastProps, setToastProps] = React.useState({
        openToast: false,
        message: ''
    });
    const checkoutCookie = getCheckoutCookie()


    const getPaymentMethods = async () => {
        try {
            setLoading(true);
            const response = await paymentService.getPaymentMethods(personInfo?.person_id);
            setPaymentMethods(response);
            setLoading(false);
        } catch (e) {
            log("error", e);
        }
    };

    React.useEffect(() => {
        getPaymentMethods();
    }, []);

    const handlePaymentMethodChanged = (e) => {
        const radioButtonValue = e.target.value;
        const selectedMethod = paymentMethods.find((paymentMethod) => radioButtonValue === paymentMethod?.unique_id);
        if (selectedMethod?.payment_method_id === PAYMENT_METHODS.CARD && selectedMethod.payment_type_id === PAYMENT_TYPES.GLOBAL) {
            setOpenCardModal(true);
        }
        else {
            setOptionSelected(radioButtonValue);
            setAcceptPayment(false);
            dispatch(setSelectedInstallmentOption(1));
        }
        eventTracker('s_metodo_pago', {}, ['mixpanel']);
    };

    const handleNewCardCreated = (newCardId) => {
        setOptionSelected(newCardId);
        setAcceptPayment(false);
        dispatch(setSelectedInstallmentOption(1));
    };

    const handleFinishedToast = () => {
        setToastProps({ ...toastProps, openToast: false });
    };

    const handlePaymentMethodConfirm = () => {
        setOpenConfirmationModal(false);
        handleProcessPayment(optionSelected);
    };

    const handleFinalizePurchase = () => {
        const selectedMethod = paymentMethods.find((paymentMethod) => optionSelected === paymentMethod?.unique_id);
        //Event
        try {
            const eventData = {} 
            eventData['metodoPago'] = getMethodPayment(selectedMethod?.payment_method_id, selectedMethod?.metadata)
            const product = orderProducts[0];
            eventData['tipoMembresia'] =product.product_type_id ===PRODUCT_TYPES.MEMBRESIA ? getNameMembership(product.product_name):'';
            eventData['periodoPago'] = product.product_type_id === PRODUCT_TYPES.MEMBRESIA ? getPeriodMembership(product.product_sku):'';
            eventData['courseID'] = product.product_type_id === PRODUCT_TYPES.CURSO ? product.product_reference_id:0;
            eventData['marcaCurso'] = product.product_type_id === PRODUCT_TYPES.CURSO ? product.product_image_url.includes('unid')? 'UNID':'UERRE':''; 
            eventData['precio'] = Number(orderPriceDetails?.total);
            eventData['cuponDescuento'] = orderPriceDetails.discounts.length>0 ? orderPriceDetails.discounts[0].code:'';
            eventTracker('finalizar_compra', eventData, ['mixpanel', 'hubspot', 'google']);
        } catch (error) {
            log("error",error);
        }
        //End Event
        if (selectedMethod?.payment_method_id === PAYMENT_METHODS.SPEI ||
            selectedMethod?.payment_method_id === PAYMENT_METHODS.CASH && orderPriceDetails.total <= selectedMethod.payment_limit) {
            setOpenConfirmationModal(true);
        }
        else if (selectedMethod?.payment_method_id === PAYMENT_METHODS.CARD || selectedMethod?.payment_method_id === PAYMENT_METHODS.FREE) {
            handleProcessPayment(optionSelected);
        }
        else {
            setToastProps({ ...toastProps, openToast: true, message: "Seleccione un método de pago" })
        }
    };

    const membershipAssignment = () => {
        if (!isLogged) {
            return;
        }

        const membershipProduct = orderProducts.find(item =>
            item.product_category_id === PRODUCT_CATEGORIES.STANDALONE &&
            item.product_type_id === PRODUCT_TYPES.MEMBRESIA
        );

        if (membershipProduct) {
            dispatch(updateMembership(membershipProduct.product_reference_id));  // Actualizar membresía del usuario
        }
    };

    const handleProcessPayment = async (paymentMethodId) => {
        const selectedMethod = paymentMethods.find((paymentMethod) => paymentMethodId === paymentMethod?.unique_id);
        log("selectedMethod:", selectedMethod);

        setOpenProcessingModal(true);
        setToastProps({ ...toastProps, openToast: false, message: "" });

        const monthlyPayments = installments?.selectedInstallmentOption > 1 ? installments?.selectedInstallmentOption : 0;
        log("monthlyPayments:", monthlyPayments);

        const responsePayment = await processCheckoutPayment(order?.id, selectedMethod, monthlyPayments);
        log("responsePayment:", responsePayment);

        const { payment_status_id, payment_message, payment_reference } = responsePayment;
        const eventData = {};
        if (isPaymentSuccessfulOrPending(payment_status_id)) {
            //Evento
            try {
                if (selectedMethod.payment_method_id === PAYMENT_METHODS.CARD || selectedMethod.payment_method_id === PAYMENT_METHODS.FREE )
                {
                    eventTracker('s_pago_exitoso', eventData, ['hubspot', 'google', 'mixpanel']); 
                    eventData['metodoPago'] = getMethodPayment(selectedMethod?.payment_method_id, selectedMethod?.metadata)
                    const product = orderProducts[0];
                    eventData['tipoMembresia'] =product.product_type_id ===PRODUCT_TYPES.MEMBRESIA ? getNameMembership(product.product_name):'';
                    eventData['periodoPago'] = product.product_type_id === PRODUCT_TYPES.MEMBRESIA ? getPeriodMembership(product.product_sku):'';
                    eventData['courseID'] = product.product_type_id === PRODUCT_TYPES.CURSO ? product.product_reference_id:0;
                    eventData['marcaCurso'] = product.product_type_id === PRODUCT_TYPES.CURSO ? product.product_image_url.includes('unid')? 'UNID':'UERRE':''; 
                    eventData['precio'] = Number(orderPriceDetails?.total);
                    eventData['cuponDescuento'] = orderPriceDetails.discounts.length>0 ? orderPriceDetails?.discounts[0].code:'';
                    eventData['montoCupon'] =  orderPriceDetails.discounts.length>0 ? orderPriceDetails?.discounts[0].discount:0;
                    eventData['porcentajeCupon'] = orderPriceDetails.discounts.length>0 ?  parseInt((orderPriceDetails?.discounts[0].discount/orderPriceDetails?.subtotal)*100):0;
                    eventData['programID'] = 0
                    eventTracker('pago_exitoso', eventData, ['hubspot', 'google', 'mixpanel']); 
                }
                if (selectedMethod.payment_method_id === PAYMENT_METHODS.SPEI || selectedMethod.payment_method_id === PAYMENT_METHODS.CASH) {
                    eventTracker('s_pago_pendiente', eventData, ['hubspot', 'google', 'mixpanel']); 
                }
            } catch (error) {
                log("error",error); 
            }
            //End Event
            if (isPaymentSuccessful(payment_status_id)) {
                membershipAssignment();
            }
            // Limpiar el carrito de compras original
            clearCartCookie();
            dispatch(applyCart({}));
            dispatch(applyCartProducts([]));
            // Redireccionar usuario a confirmación de pago
            history.replace(generatePath(paths.PAYMENT_CONFIRMATION, { order_id: order?.id }));
        }
        else if (payment_status_id === PAYMENT_STATUS.PAGO_FALLIDO) {
            eventData['failtype'] = payment_reference; 
            eventTracker('pago_rechazado', eventData, ['hubspot', 'google', 'mixpanel']);
            const errorMessage = payment_message || 'Se ha producido un error al realizar el pago';
            setToastProps({ ...toastProps, openToast: true, message: errorMessage });
        }
        else {
            eventData['failtype'] = payment_reference; 
            eventTracker('pago_rechazado', eventData, ['hubspot', 'google', 'mixpanel']);
            const errorMessage = payment_message || 'Se ha producido un error al realizar el pago'; /* manejar otro tipo de error */
            setToastProps({ ...toastProps, openToast: true, message: errorMessage }); 
        }
        setOpenProcessingModal(false);
    };

    return (
        <WorkflowPage title="Elige método de pago">
            <Box className='pt-4 pb-2'>
                {loading ? (
                    <Box>
                        <PaymentMethodItemSkeleton />
                        <PaymentMethodItemSkeleton />
                        <PaymentMethodItemSkeleton />
                    </Box>
                ) : (
                    <Grid container direction='row' alignItems='center'>
                        {
                            checkoutCookie?.isReferred &&
                                <ReferralCouponCodeBanner />
                        }
                        <RadioGroup value={optionSelected} name="radio-buttons-group" onChange={handlePaymentMethodChanged}>
                            {paymentMethods.filter(mt => Number(orderPriceDetails.total) === 0 ? mt.payment_method_id === PAYMENT_METHODS.FREE: mt.payment_method_id < PAYMENT_METHODS.FREE).map((paymentMethod, index) => {
                                let displayInstallmentOptions;
                                if (paymentMethod.unique_id === optionSelected &&
                                    paymentMethod.payment_method_id === PAYMENT_METHODS.CARD &&
                                    paymentMethod.payment_type_id === PAYMENT_TYPES.USER &&
                                    paymentMethod.metadata?.card_type === CONEKTA_CARD_TYPES.CREDIT &&
                                    installments?.installmentOptions?.length > 0) {
                                    displayInstallmentOptions = true;
                                } 
                                return (
                                    <Grid item xs key={index}>
                                        <PaymentMethodItem
                                            disablePayment= {(paymentMethod.payment_limit !== 0 && orderPriceDetails.total>paymentMethod.payment_limit)  ? true : false}
                                            paymentMethod={paymentMethod}
                                            installmentOptions={installments?.installmentOptions}
                                            displayInstallmentOptions={displayInstallmentOptions} />
                                    </Grid>
                                );
                            })}
                        </RadioGroup>
                    </Grid>
                )}
            </Box>
            <Grid container>
                <Grid item>
                    <PaymentsConditions
                        acceptPayment={acceptPayment}
                        setAcceptPayment={setAcceptPayment} />
                </Grid>
                {orderPriceDetails?.next_payment_date && <Grid item>
                    <RecurringPaymentDate
                        nextPaymentDate={orderPriceDetails?.next_payment_date} />
                </Grid>}
                <Grid item>
                    <FinalizePurchase
                        acceptPayment={acceptPayment}
                        onFinalizePurchase={handleFinalizePurchase}
                    />
                </Grid>
            </Grid>
            <CardModal
                openModal={openCardModal}
                onModalClose={() => setOpenCardModal(false)}>
                <NewCardForm
                    onModalClose={() => setOpenCardModal(false)}
                    onReloadPaymentMethods={() => getPaymentMethods(true)}
                    onNewCardCreated={(id) => handleNewCardCreated(id)}
                />
            </CardModal>
            <PaymentProcessingModal
                openModal={openProcessingModal}
            />
            <PaymentConfirmationModal
                openModal={openConfirmationModal}
                onModalClose={() => setOpenConfirmationModal(false)}
                onModalConfirm={handlePaymentMethodConfirm}
                title="¿Seguro que deseas continuar?"
                primaryText="La orden de pago que se generará es única y cuenta con fecha de expiración."
                secondaryText="En caso de que desees cambiar de método de pago deberás realizar de nuevo la compra."
            />
            <Toast
                severity="error"
                open={toastProps.openToast}
                message={toastProps.message}
                onFinished={handleFinishedToast}
                duration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        </WorkflowPage>
    );
};

export default PaymentMethods;