import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const CourseInfo = (props) => {
    const {children, name, description} = props;

    return (
        <Box minHeight={185} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Typography style={{  fontSize: "32px", lineHeight: "41px", letterSpacing: "0px", marginBottom: "17px", fontFamily: "Inter !important" }} variant="h1">{name}</Typography>
            <Typography style={{  fontSize: "16px", lineHeight: "24px", letterSpacing: "0px", marginBottom: "24px", fontFamily: "Inter !important", fontWeight:"400" }} variant="body1">{description}</Typography>
            <Box>
                {children}
            </Box>
        </Box>
    );
};

export default CourseInfo;