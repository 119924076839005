import { Slider, withStyles } from "@material-ui/core";

export const CustomSlider = withStyles((theme) => ({
    root: {
      color: theme.palette.colors.black,
      height: 8,
    },
    thumb: {
      height: 18,
      width: 18,
      backgroundColor: theme.palette.colors.magenta[500],
      "&.Mui-disabled": {
        height: 0,
        width: 0,
      }
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)'
    },
    track: {
      height: 8,
      borderRadius: 4,
      color: theme.palette.colors.magenta[500]
    },
    rail: {
      height: 8,
      borderRadius: 4
    },
    vertical: {
      '& $rail': {
        width: 8
      },
      '& $track': {
        width: 8
      },
      '& $thumb': {
        marginLeft: -8,
        marginBottom: -11
      }
    }
  }))(Slider);
