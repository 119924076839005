import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: 'transparent',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
        '&$selected > $content $label:hover, &$selected:focus > $content $label': {
            backgroundColor: 'transparent',
        },
        '$group':{
            background: 'red'
        }
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    groupChild: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
        'max-height': 400,
        'overflow-y': 'scroll',
        'overflow-x': 'hidden',
        "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
            backgroundColor: "none"
          },
          "&::-webkit-scrollbar-thumb": {
                borderRadius: "100px",
                background: "#77777747",
                "&:hover":{
                    background: "#b2adad"
                }
          },
          "&::-webkit-scrollbar-track": {
              borderRadius: "8px"
          }
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));