import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootStatusCourseBar: {
        width: "100%",
        height: "24px",
        borderRadius: "8px 8px 0 0",
        color: theme.palette.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            height: "20px",
        }
    },
    inProgressCourse: {
        borderBottom: `1px solid ${theme.palette.colors.blue[300]}`,
        backgroundColor: theme.palette.colors.blue[500],
    },
    finishedCourse: {
        borderBottom: `1px solid ${theme.palette.colors.electricGreen[300]}`,
        backgroundColor: theme.palette.colors.success[500],
    },
    reprobateCourse:{
        borderBottom: `1px solid ${theme.palette.colors.error[300]}`,
        backgroundColor: theme.palette.colors.error[500],   
    }
}));