import React from 'react';
import PropTypes from 'prop-types'

// material components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tabMain:{
        marginBottom: "20px",
        maxWidth: "100%",
        marginLeft: "68px",
        marginRight: "108px",
    }
}));

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const TabCarousel = ({handleChange, tabs, value}) => {
    const classes = useStyles();
    return (
        <Tabs className={classes.tabMain}  variant="scrollable" scrollButtons allowScrollButtonsMobile value={value} onChange={handleChange} aria-label="scrollable auto tabs example" indicatorColor="primary">
            {
                tabs.map((tab, index) => <Tab key={tab.uuid} label={tab.value} {...a11yProps(index)} />)
            }
        </Tabs>
    );
};

TabCarousel.propTypes = {
    handleChange: PropTypes.any,
    tabs: PropTypes.any,
};

export default TabCarousel;