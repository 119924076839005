import React from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import clsx from 'clsx';

// Material components 
import { Box, Grid, Icon, Typography, useMediaQuery } from '@material-ui/core';


// Styles
import useStyles from '../../../assets/styles/components/cards/components/priceAndMembershipStyle';
import MembershipStarIcon from './MembershipStarIcon';

const TYPE_LABEL = {
    FREE: 1,
    OTHER_MEMBERSHIP: 2,
    INCLUDE_MEMBERSHIP: 3,
    PRICE: 4,
};

const MembershipLockIcon = ({className}) => {
    return (
        <Icon className={clsx('ri-lock-2-line', 'font-size-16', className)}/>
    )
};

const PriceAndMembership = ({content, membershipIdList, isListItemCard, isShowInMyClassesLabel, isComingSoonLabel, isProgram, classNameBorderTop }) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles({isListItemCard, membershipNameLength: (content?.labelToShow.length ?? 0), isProgram});

    return (
        <Box className={clsx(classNameBorderTop, classes.rootPriceAndMembership, (!!content?.price?.listNumber && content.showPrice && content?.price?.listNumber !== content?.price?.number) && classes.priceWithDiscountContainer)}>
            {
                isShowInMyClassesLabel && !isComingSoonLabel && 
                <Box >
                    <Typography variant={isResponsive || isListItemCard ? 'body1' : 'body2'} className={clsx('text-warning-300', (isResponsive || isListItemCard) && 'variant-xsmall')}>Ver en mis clases</Typography>
                </Box>
            }
            {
                isComingSoonLabel && !isShowInMyClassesLabel && 
                <Box className={clsx(classes.comingSoonContainer)}>
                    <Box>
                        <Typography variant={ isResponsive ? 'body1' : 'body2'} className={clsx('semi-bold', classes.comingSoonLabel, isResponsive && 'variant-xsmall')}>Próximamente</Typography>
                    </Box>
                    <Box>
                        <MembershipLockIcon className={classes.membershipIcon}/>
                    </Box>
                </Box>
            }
            {
                !isShowInMyClassesLabel && !isComingSoonLabel && content.showLabel &&
                <>
                    {
                        (content.typeLabel === TYPE_LABEL.FREE || content.typeLabel === TYPE_LABEL.INCLUDE_MEMBERSHIP) && 
                        <Box className={classes.freeCourseContainer}>
                            <Typography variant={ isResponsive || isListItemCard ? 'body1' : 'body2'} className={clsx('semi-bold', classes.freeCourseLabel, (isResponsive || isListItemCard) && 'variant-xsmall')}>{content.labelToShow}</Typography>
                        </Box>
                    }
                    {
                        content.typeLabel === TYPE_LABEL.OTHER_MEMBERSHIP &&    
                        <Box className={clsx(classes.otherMembershipContainer)}>
                            <Box className={classes.otherMembershipLabelContainer}>
                                {
                                    isProgram && 
                                    <Box className={classes.membershipStarIconContainer}>
                                        <MembershipStarIcon membershipId={membershipIdList[0] ?? 0}  />
                                    </Box>
                                }
                                <Typography variant={ (isResponsive || isListItemCard) ? 'body1' : 'body2'} className={clsx(classes.otherMembershipLabel, (isResponsive || isListItemCard) && 'variant-xsmall')}>{content.labelToShow}</Typography>
                            </Box>
                            {!isProgram && 
                                <Box className={classes.otherMembershipIconContainer}>
                                    <MembershipLockIcon className={classes.membershipIcon}/>
                                </Box>
                            }
                        </Box>
                    }
                </>
            }
            {
                !isShowInMyClassesLabel && !isShowInMyClassesLabel && !isListItemCard && content.showPrice &&
                <>
                    <Grid container className={clsx(classes.priceContainer)}>
                        <Grid item xs>
                        {
                            (!!content.price.number && !!content.price.listNumber && content.price.listNumber != content.price.number) &&
                            <Typography variant='caption' className={clsx('semi-bold', classes.discountLabel, isResponsive && 'variant-caption-sm')}>{`${Math.round(((content.price.number * 100) / content.price.listNumber))}% de Dto.`}</Typography>
                        }
                        </Grid>
                        <Grid item xs>
                        {
                            !!content.price.listNumber && content.price.listNumber != content.price.number &&
                            <Typography variant={isResponsive ? 'body1' : 'body2'} className={clsx('classes.price', classes.listNumberLabel, isResponsive && 'variant-xsmall')}><CurrencyFormat value={content.price.listNumber} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN'/></Typography>
                        }
                        </Grid>
                        <Grid item xs>
                            <Typography variant={isResponsive ? 'h6' : 'h5'} className={classes.numberLabel}>
                                {
                                    content.price.number &&
                                    <CurrencyFormat value={content.price.number} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN'/>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            }
            {
                !isShowInMyClassesLabel && !isShowInMyClassesLabel && isListItemCard && content.showPrice &&
                <>
                    <Grid container className={clsx(classes.priceContainer, 'price-container-responsive')}>
                        <Grid item xs={5}>
                            <Box>
                                <Box>
                                {
                                    !!content.price.listNumber && content.price.listNumber != content.price.number &&
                                    <Typography className={`variant-subheading-3 ${classes.listNumberLabel}`}><CurrencyFormat value={content.price.listNumber} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN'/></Typography>
                                }
                                </Box>
                                <Box className={classes.discountLabelContainer}>  
                                {
                                    (!!content.price.number && !!content.price.listNumber && content.price.listNumber != content.price.number) &&
                                    <Typography variant='caption' className={`
                                    variant-caption-sm semi-bold ${classes.discountLabel}`}>{`${Math.round(((content.price.number * 100) / content.price.listNumber))}% de Dto.`}</Typography>
                                }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={7}>
                            <Box display="flex" justifyContent="end" alignItems="center">
                                {
                                    !!content.price.number &&
                                    <Typography variant='h4' className={classes.numberLabel}><CurrencyFormat value={content.price.number} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=' MXN'/></Typography>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </>
            }

        </Box>
    );
};

PriceAndMembership.defaultProps = {
    isListItemCard: false,
    isShowInMyClassesLabel: false,
    isComingSoonLabel: false,
    isProgram: false,
    membershipIdList: [],
    classNameBorderTop: "",
};

PriceAndMembership.propTypes = {
    content: PropTypes.shape({
        price: PropTypes.shape({
            number: PropTypes.number,
            listNumber: PropTypes.number,
        }),
        showLabel: PropTypes.bool.isRequired,
        showPrice: PropTypes.bool.isRequired,
        labelToShow: PropTypes.string.isRequired,
        typeLabel: PropTypes.number.isRequired,
    }).isRequired,
    membershipIdList: PropTypes.arrayOf(PropTypes.number),
    isListItemCard: PropTypes.bool,
    isShowInMyClassesLabel: PropTypes.bool,
    isComingSoonLabel: PropTypes.bool,
    isProgram: PropTypes.bool,
    classNameBorderTop: PropTypes.string,
};

export default PriceAndMembership;