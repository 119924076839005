import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useSelector, useDispatch } from 'react-redux';

// redux
import { setSelectedInstallmentOption } from '../../../../../../redux/actions/checkoutActions';

// @material-ui
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';

const InstallmentOptionsDropdown = ({ installmentOptions }) => {
    const dispatch = useDispatch();
    const { order } = useSelector((state) => state.checkoutReducer);
    const [openMenuList, setOpenMenuList] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState(1);
    const [availablePayments, setAvailablePayments] = React.useState([]);

    React.useEffect(() => {
        if (!installmentOptions?.length) return;

        const paymentOptions = installmentOptions.map((option) => ({
            id: option, label: "meses sin intereses de"
        }));

        setAvailablePayments(paymentOptions);
    }, []);

    const handleChange = (event) => {
        setSelectedOption(event.target.value)
        dispatch(setSelectedInstallmentOption(event.target.value))
    };

    return (
        <Grid item xs={12}>
            <Grid container justifyContent='center'>
                <FormControl fullWidth style={{ background: "#1D1E23", borderRadius: "8px" }}>
                    <Select
                        onOpen={() => setOpenMenuList(true)}
                        onClose={() => setOpenMenuList(false)}
                        id="customized-select"
                        value={selectedOption}
                        onChange={handleChange}
                        fullWidth
                        endAdornment={
                            <Icon style={{ verticalAlign: 'middle', display: 'inline-flex' }}
                                className={openMenuList
                                    ? "ri-arrow-up-s-line font-size-24 mr-2"
                                    : "ri-arrow-down-s-line font-size-24 mr-2"
                                }
                            />
                        }
                        disableUnderline
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            MenuListProps: { style: { width: '100%' } }
                        }}
                        inputProps={{ IconComponent: () => null }}
                    >
                        <MenuItem value={1}>
                            {`Pago de contado por $${order?.total}`}
                        </MenuItem>
                        {
                            availablePayments.map((option) =>
                                <MenuItem value={option.id}>
                                    {`${option.id} ${option.label}`}
                                    <CurrencyFormat
                                        value={(order?.total / option.id)}
                                        className="text-gray-100 ml-1"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$"
                                    />
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default InstallmentOptionsDropdown;