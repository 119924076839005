import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment'
import { useLocation } from "react-router-dom";

// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../redux/actions/userActions';
import * as profilePicActions from '../../../../redux/actions/profilePicActions';
import * as tourInitialActions from '../../../../redux/actions/tourInitialActions';

//services
import { MenuItem } from '@material-ui/core';

//SDK
import { MIXPANEL_EVENT, SingupService } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../shared/components/Mixpanel/mixpanel';
import { isValidPhoneNumber } from '../../../../shared/utils/isValidPhoneNumber';
import CheckTermsConditions from '../../../../shared/components/CheckTermsConditions/CheckTermsConditions';
import Select from '../../../../design-system/components/Form/Select/Select';
import { log } from '../../../../shared/utils/console.js'

//new events mixpanel
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '318px',
        width: '100%',
        padding: '32px 0px',
        margin: '0px auto',
        textAlign: 'center',
    },
    talisisLogo: {
        marginBottom: '40px',
        width: '126.68px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
        flexShrink: 0,
        padding: '24px 0px'
    },
    input: {
        alignItems: 'center',
        gap: '10px',
        width: '100%',
        maxWidth: '318px',
        height: '48px',
        background: `${theme.palette.colors.grey[500]} !important`,
        border: `0.5px solid ${theme.palette.colors.grey[50]} !important`,
        borderRadius: '4px',
        textAlign: 'left',
        '& :-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${theme.palette.colors.grey[500]} inset`,
            '-webkit-text-fill-color': `${theme.palette.colors.white} !important`,
            'caret-color': 'white',
            fontWeight: 400,
            padding: '14px',
        },
        '& :-webkit-autofill:focus': {
            '-webkit-box-shadow': `0 0 0 100px ${theme.palette.colors.grey[500]} inset`,
            '-webkit-text-fill-color': `${theme.palette.colors.white} !important`,
            'caret-color': 'white',
            fontWeight: 400,
            padding: '14px',
        },
    },
    legal: {
        textAlign: 'left',
    },
    legalLink: {
        fontSize: '12px',
    },
    label: {
        fontSize: '11px',
        color: theme.palette.colors.white,
        textDecoration: 'none !important',
        cursor: 'default',
    },
    link: {
        fontSize: '11px',
        color: theme.palette.colors.orange[300]
    },
    submit: {
        width: '100%',
        maxWidth: '318px',
        height: '48px',
        background: theme.palette.colors.violet[500],
        borderRadius: '4px',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        '&.Mui-disabled': {
            borderRadius: '4px',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '16px',
        }
    },
    signupCaption: {
        paddingTop: '0 !important',
        textAlign: 'left'
    },
    signupSubtitle: {
        textAlign: 'left',
        marginBottom: '16px'
    },
    inputLabel: {
        fontSize: 14
    },
    emailInputError: {
        "& .Mui-error": {
            borderColor: theme.palette.error.main,
            backgroundColor: '#8D2D3220'
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: theme.palette.error.main,
            backgroundColor: 'unset'
        },
        "& .MuiFormLabel-root": {
            color: theme.palette.white,
        },
        "& .MuiFormLabel-asterisk": {
            color: theme.palette.white
        }
    },
    alertIcon: {
        color: theme.palette.error.main,
        fontSize: 24
    },
    errorText: {
        marginTop: 24,
        textAlign: 'left'
    }
}));

const SignupForm = props => {
    const { onLoading, onSignupError, onSignupSuccess, refererId, footerExtras, isReferralPage } = props;
    const classes = useStyles();
    const [errors, setErrors] = React.useState({});
    const [emailError, setEmailError] = React.useState(false);
    const [emailValidatorError, setEmailValidatorError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [isRefererId, setIsRefererId] = React.useState(false);
    const [form, setForm] = React.useState({
        'first_name': '',
        'last_name': '',
        'email': '',
        'confirm_email': '',
        'phone': '',
        'gender': '',
        'referer_id': null,
        'legal': false
    });
    const location = useLocation();
    const preventDefault = (event) => event.preventDefault();
    const isEmail = (email) =>
        /^[\w.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    

    const handleChange = (e) => {
        const _errors = { ...errors };
        let { name, value } = e.target;
        if (e.target.name === 'email') {
            if (!isEmail(e.target.value)) {
                _errors[name] = 'Ingresa un email válido'
                setErrors({ ..._errors });
            } else {
                delete _errors[name];
                setErrors(_errors);
            }
            value = value.trim();
        } else {
            if (value) {
                if (name === 'confirm_email') {
                    if (value !== form.email) {
                        _errors[name] = 'Los correos no coinciden'
                        setErrors({ ..._errors });
                    } else {
                        delete _errors[name];
                        setErrors(_errors);
                    }
                } else {
                    delete _errors[name];
                    setErrors(_errors);
                }
            }
        }    

        setForm(prev => { return { ...prev, [name]: value } });
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['first_name', 'last_name', 'gender', 'email', 'phone'];
        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            }
        }
        const { message, error: phoneError } = isValidPhoneNumber(form.phone);
        log(message)
        log(phoneError)
        if (phoneError) {
            _errors.phone = message
        }
        setErrors({ ..._errors });
        log(_errors)
        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleSubmit = async (e) => {
        preventDefault(e)
        if (validate()) {
            onLoading(true)
            try {
                if (location.search.includes('course')) {
                    origin = 'Checkout';
                }
                else {
                    origin = 'SignUp';
                }
                
                const emailValidation = await SingupServiceSDK.validateEmail(form.email);
                // console.log(emailValidation)
                if (emailValidation.canSignup) {
                    SingupServiceSDK.signup(form, location.search).then(data => {
                        log(data)
                        if (data.error) {
                            setEmailError(true);
                            log(data)
                            onLoading(false)
                        }
                        else {
                            const eventData = {};
                            eventData['source'] = 'landing';
                            eventTracker('crear_cuenta', eventData, ['mixpanel']);
                            onSignupSuccess(true, data, form)
                        }
                    })
                        .catch((err) => {
                            log(err)
                            onSignupError('Ocurrió un error, intenta de nuevo mas tarde.')
                        })
                        .finally(() => {
                            onLoading(false)
                        })
                }
                else {
                    setEmailError(true);
                    setEmailValidatorError(true)
                    setEmailErrorMessage(emailValidation.reason);
                    const _errors = { ...errors };
                    _errors['email'] = emailValidation.reason;
                    onLoading(false)
                }

            }
            catch (error) {
                log(error);
            }
        }
    };

    const handleChecked = (e, checked) => {
        const { name } = e.target;
        setForm(prev => { return { ...prev, [name]: checked } });
    };

    React.useEffect(() => {
        if (refererId) {
            setIsRefererId(true);
            setForm(prev => { return { ...prev, referer_id: refererId } });
        }
    }, [])

    return (
        <>
            {emailError && <Typography variant='body1' className={`text-error-300 font-variant-v9 ${classes.errorText}`}>
                {emailValidatorError ? emailErrorMessage : 'Este correo ya está asociado a una cuenta Talisis. Puedes iniciar sesión o restablecer tu contraseña.'}
            </Typography>}
            <form className='mt-4' onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            variant="outlined"
                            label="Usuario"
                            fullWidth
                            size="medium"
                            required
                            value={form.email || ""}
                            error={Boolean(errors.email) || emailError}
                            // error
                            helperText={errors.email}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                            placeholder="Ingresar correo electrónico"
                            className={emailError ? classes.emailInputError : classes.emailInput}
                            InputProps={{
                                endAdornment: emailError ? <i className={`ri-alert-fill ${classes.alertIcon}`} /> : ''
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="confirm_email"
                            variant="outlined"
                            label="Confirmar usuario"
                            fullWidth
                            size="medium"
                            required
                            value={form.confirm_email || ""}
                            error={Boolean(errors.confirm_email) || emailError}
                            helperText={errors.confirm_email}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                            placeholder="Confirmar correo electrónico"
                            className={emailError ? classes.emailInputError : classes.emailInput}
                            InputProps={{
                                endAdornment: emailError ? <i className={`ri-alert-fill ${classes.alertIcon}`} /> : ''
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.signupCaption}>
                        <Typography variant="caption" className='text-dark-black-100'>
                            Asegúrate de usar tu propio correo, ya que en adelante este será tu usuario y principal medio de contacto con la plataforma.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.signupSubtitle}>
                        <Typography variant="h5">
                            Información del estudiante
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="first_name"
                            variant="outlined"
                            label="Nombre(s)"
                            fullWidth
                            size="medium"
                            required
                            value={form.first_name || ""}
                            error={Boolean(errors.first_name)}
                            helperText={errors.first_name}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                            placeholder="Ingresa tu(s) nombre(s)"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="last_name"
                            variant="outlined"
                            label="Apellidos"
                            fullWidth
                            size="medium"
                            required
                            value={form.last_name || ""}
                            error={Boolean(errors.last_name)}
                            helperText={errors.last_name}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                            placeholder="Ingresar apellidos"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Select 
                            name="gender"
                            label="Género" 
                            placeholder="género"
                            required
                            items={[
                                { id: "M", label: "Masculino" },
                                { id: "F", label: "Femenino" }
                            ]} 
                            InputLabelProps={{ shrink: true }}
                            value={form.gender || ""}
                            error={Boolean(errors.gender)}
                            helperText={errors.gender}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="phone"
                            variant="outlined"
                            label="Númer de celular"
                            fullWidth
                            size="medium"
                            required
                            value={form.phone || ""}
                            error={Boolean(errors.phone)}
                            helperText={errors.phone}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                            placeholder="Número a 10 dígitos"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+52</InputAdornment>,
                            }}
                        />
                    </Grid>
                    {isRefererId && !isReferralPage && 
                        <Grid item xs={12}>
                            <TextField
                                name="referer_id"
                                variant="outlined"
                                label="Código de referido"
                                fullWidth
                                size="medium"
                                value={form.referer_id || ""}
                                error={Boolean(errors.referer_id)}
                                helperText={errors.referer_id}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true, className: classes.inputLabel }}
                                disabled={isRefererId}
                                placeholder="Código de referido"
                            />
                        </Grid>
                    }
                    {   isReferralPage &&
                        <Grid item xs={12} className='pt-1'>
                            <Typography variant='caption' className='text-dark-black-200'>*Campos obligatorios</Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <CheckTermsConditions 
                            startText="Confirmo que todos los datos son correctos y acepto los"
                            endText=" de Talisis."
                            colorLink="secondary"
                            secondaryLinkText="Políticas de privacidad"
                            onChange={handleChecked}
                            name="legal"
                            variant="caption"
                        />
                        {
                            footerExtras &&
                            <Box className='mt-3 pt-3'>
                                {footerExtras}
                            </Box>
                        }
                        <Box mt={footerExtras ? 0 : 4} /*mb={footerExtras ? 0 : 0}*/ className={footerExtras ? "mt-3 pt-3" : ""}>
                            <Button fullWidth variant="contained" size="large" color="primary" disabled={!form.legal} type={'submit'} onClick={handleSubmit}>
                                {isReferralPage?"Realizar compra":"Crear cuenta"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

const mapStateToProps = ({ userReducer, profilePicReducer, tourInitialReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic,
    showTour: tourInitialReducer.showTour
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...tourInitialActions };
export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);