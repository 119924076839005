import React from 'react';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';

// @material-ui/core
import Menu from '@material-ui/core/Menu';
import { ListItemText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
// @material-ui/icons
import styled from 'styled-components';

const ListItemIconStyled = styled(ListItemIcon)`
    min-width: 28px;
`
const ITEM_HEIGHT = 48;

export default function Dropdown(props) {
    const {type, options, onClickOption, text, classStyleItems='',...restButtonProps} = props;

    const useStyles = makeStyles((theme) => ({
        listSubHeaderStyle: {
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [optionSelected, setOptionSelected] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOption = (option) => () => {
        if(!option.category){
            setOptionSelected(option.id);
            handleClose();
            onClickOption(option)
        }
    };

    return (
        <>
            <Button aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick} {...restButtonProps}>
                {text}
            </Button>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                elevation={1}
                getContentAnchorEl={null}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                        marginTop: '5px'
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem className={!option.category ? classStyleItems : ''} key={option.id ? option.id : uuid()} selected={option.id === optionSelected} onClick={handleClickOption(option)}>
                        {
                        option.category && <ListSubheader classes={{gutters: classes.listSubHeaderStyle}} className='variant-xsmall text-gray-100'
                        disableSticky={true}>{option.category}</ListSubheader>
                        }
                        {option.icon && <ListItemIconStyled>{option.icon}</ListItemIconStyled>}
                        <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

Dropdown.defaultProps = {
    options:[],
};

Dropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.element
    })),
    text: PropTypes.string.isRequired,
    onClickOption: PropTypes.func,
};
