import React from 'react';
import { Box, Typography, Card, CardContent, Button, CircularProgress } from '@material-ui/core';

const VoxyHHCard = ({ classes, onClick, mt, loading }) => {
    return (
        <Box position="relative" mt={mt || 0}>
            <Box position="relative" className={[classes.hover_carousel, "mb-4"]}>
                <Typography className={classes.title} variant="h3">Idiomas</Typography>
                <Card className={`${classes.HH_online_card} mt-5`}>
                    <CardContent className={classes.HH_online_card_content}>
                        <Box className="mt-3">
                            <svg width="140" height="22" viewBox="0 0 155 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M143.181 14.3638V14.1185C143.187 12.3711 142.613 10.6711 141.549 9.28467C140.486 7.89828 138.992 6.90385 137.303 6.45715C135.614 6.01044 133.824 6.1367 132.214 6.81614C130.604 7.49557 129.265 8.6898 128.406 10.2118C127.548 11.7337 127.219 13.4975 127.47 15.2267C127.721 16.956 128.539 18.553 129.795 19.7677C131.051 20.9824 132.675 21.7462 134.411 21.9393C136.148 22.1325 137.9 21.7442 139.392 20.8352L139.571 20.7261V21.9916H143.173L143.181 14.3638ZM135.296 18.4288C134.441 18.4281 133.605 18.1736 132.894 17.6977C132.184 17.2218 131.631 16.5458 131.304 15.7553C130.978 14.9649 130.894 14.0954 131.062 13.2569C131.23 12.4185 131.643 11.6487 132.249 11.0451C132.855 10.4415 133.626 10.0312 134.465 9.86609C135.304 9.70096 136.173 9.78845 136.963 10.1175C137.752 10.4465 138.426 11.0023 138.899 11.7145C139.373 12.4267 139.624 13.2633 139.622 14.1185C139.62 15.2644 139.163 16.3627 138.352 17.1723C137.541 17.9819 136.442 18.4366 135.296 18.4366" fill="#231F20" />
                                <path d="M145.377 0.143677C145.377 5.93366 145.377 12.7243 145.35 18.7246V21.9953H148.831V18.8336C148.831 13.0436 148.831 6.05047 148.858 0.143677H145.377Z" fill="#231F20" />
                                <path d="M151.066 0.148376C151.066 5.94225 151.066 12.729 151.039 18.7332V22H154.52V18.8383C154.52 13.0522 154.52 6.05517 154.547 0.148376H151.066Z" fill="#231F20" />
                                <path d="M115.964 10.4002C116.719 9.91713 117.622 9.71712 118.51 9.83558C119.18 9.85349 119.828 10.0749 120.368 10.4703C121.049 10.9842 121.411 11.6929 121.536 12.7637C121.594 13.2638 121.621 13.7671 121.618 14.2705C121.618 15.6645 121.618 19.3324 121.618 21.7893V21.984H125.215V21.7893C125.215 19.3402 125.215 15.645 125.215 14.2472C125.219 13.5965 125.181 12.9463 125.103 12.3003C125.021 11.3807 124.75 10.4879 124.307 9.67789C123.864 8.86784 123.259 8.15798 122.529 7.59278C121.428 6.77262 120.107 6.29948 118.736 6.23387C117.381 6.10537 116.015 6.3448 114.784 6.92696L114.609 7.01262V0.116821H111.007V1.79112C111.007 6.10538 111.007 10.9959 111.007 14.6755C110.984 16.5367 110.984 19.0053 110.984 21.1858V21.9646H114.617V21.2481C114.617 18.4991 114.617 14.7261 114.675 13.0713C114.734 11.4164 115.454 10.7701 115.968 10.4002" fill="#231F20" />
                                <path d="M92.9982 10.3762C93.7538 9.88945 94.6571 9.68534 95.5486 9.79993C96.2178 9.81785 96.8657 10.0392 97.4059 10.4346C98.0834 10.9486 98.4495 11.6572 98.5741 12.728C98.6299 13.2297 98.6571 13.7341 98.6558 14.2388C98.6558 15.6288 98.6558 19.3006 98.6558 21.7537V21.9484H102.254V21.7537C102.254 19.3084 102.254 15.6094 102.254 14.2154C102.257 13.5647 102.219 12.9145 102.141 12.2686C102.058 11.3486 101.787 10.4556 101.344 9.64503C100.901 8.83447 100.296 8.12375 99.5669 7.55714C98.4649 6.73928 97.1451 6.2664 95.7744 6.19823C94.0606 6.02019 92.3391 6.45471 90.9151 7.42475C89.1512 8.66296 88.1895 10.5592 88.0921 12.9149C88.0337 14.6282 88.0337 18.2805 88.0337 21.2164V21.9328H91.6432V21.2164C91.6432 18.4635 91.6432 14.6944 91.7016 13.0395C91.76 11.3847 92.4803 10.7422 92.9904 10.3684" fill="#231F20" />
                                <path d="M58.6511 10.3457C59.4043 9.85667 60.3073 9.65233 61.1976 9.76942C61.8669 9.78733 62.5147 10.0087 63.0549 10.4041C63.7363 10.9181 64.0984 11.6267 64.223 12.6975C64.2808 13.1977 64.3081 13.7009 64.3048 14.2044C64.3048 15.5944 64.3048 19.2662 64.3048 21.7193V21.9062H67.9065V21.7115C67.9065 19.2623 67.9065 15.5672 67.9065 14.1693C67.9096 13.5187 67.8719 12.8684 67.7936 12.2225C67.7123 11.3028 67.4417 10.4098 66.9987 9.59972C66.5558 8.78961 65.9502 8.07983 65.2199 7.51495C64.119 6.69479 62.7986 6.22164 61.4274 6.15603C59.714 5.97618 57.9923 6.40937 56.568 7.37867C56.2105 7.6333 55.8792 7.92287 55.579 8.24308L55.4894 8.33652L55.431 8.27812C55.1851 8.00778 54.9154 7.76017 54.625 7.53831C53.5215 6.72035 52.2005 6.24749 50.8286 6.17939C49.1148 6.00136 47.3933 6.43588 45.9692 7.40593C44.2092 8.64414 43.2436 10.5209 43.1502 12.8961C43.0918 14.6093 43.0879 18.2616 43.0879 21.1975V21.9062H46.6896V21.1897C46.6896 18.4369 46.6896 14.6677 46.748 13.0129C46.8064 11.3581 47.5267 10.7117 48.0407 10.3418C48.7963 9.85503 49.6996 9.65094 50.5911 9.76553C51.2602 9.78418 51.9078 10.0055 52.4484 10.4002C53.1259 10.9142 53.488 11.6228 53.6165 12.6936C53.6759 13.195 53.7046 13.6995 53.7022 14.2044C53.7022 15.5944 53.7022 19.2662 53.7022 21.7193V21.914H57.3117V21.1975C57.3117 18.4447 57.3117 14.6755 57.3701 13.0207C57.4285 11.3658 58.1488 10.7195 58.6628 10.3496" fill="#231F20" />
                                <path d="M39.327 10.326C39.7903 10.0108 40.3264 9.81916 40.8845 9.76921V6.15582C39.5762 6.20751 38.31 6.63317 37.2361 7.38236C35.4761 8.61667 34.5105 10.5168 34.417 12.8725C34.3586 14.5858 34.3586 18.2381 34.3547 21.174V21.906H37.9564V21.1895C37.9564 18.4406 37.9564 14.6714 38.0149 13.0127C38.0733 11.354 38.7936 10.7115 39.3076 10.3416" fill="#231F20" />
                                <path d="M32.1943 14.2593C32.1943 14.2593 32.1943 14.0257 32.1943 14.0062C32.2004 12.258 31.6267 10.5572 30.5627 9.17005C29.4987 7.78292 28.0047 6.78787 26.3147 6.34074C24.6246 5.8936 22.834 6.01963 21.2233 6.69911C19.6126 7.37858 18.2727 8.57313 17.4135 10.0956C16.5543 11.6181 16.2244 13.3826 16.4754 15.1127C16.7264 16.8427 17.5442 18.4407 18.8006 19.6563C20.057 20.8718 21.6811 21.6363 23.4185 21.8301C25.1559 22.0238 26.9085 21.6358 28.4018 20.7268L28.5848 20.6139V21.8832H32.1826L32.1943 14.2593ZM24.3134 18.3205C23.4584 18.3197 22.6228 18.0654 21.9124 17.5896C21.202 17.1139 20.6487 16.4382 20.3224 15.6479C19.9961 14.8576 19.9115 13.9883 20.0794 13.15C20.2472 12.3116 20.6599 11.5419 21.2653 10.9381C21.8706 10.3344 22.6415 9.92377 23.4803 9.7582C24.3191 9.59263 25.1882 9.67955 25.9776 10.008C26.767 10.3364 27.4412 10.8916 27.915 11.6033C28.3888 12.315 28.6408 13.1512 28.6393 14.0062C28.6362 15.1515 28.1791 16.2488 27.3682 17.0576C26.5572 17.8663 25.4587 18.3205 24.3134 18.3205Z" fill="#231F20" />
                                <path d="M4.97618 10.295C5.73112 9.80669 6.635 9.60246 7.52657 9.71877C8.19596 9.73569 8.84412 9.95717 9.38389 10.3534C10.0614 10.8674 10.4274 11.5722 10.552 12.6468C10.6078 13.1472 10.6351 13.6503 10.6338 14.1537C10.6338 15.5438 10.6338 19.2156 10.6338 21.6686V21.8672H14.2355V21.6725C14.2355 19.2234 14.2355 15.5282 14.2355 14.1304C14.2385 13.4797 14.2008 12.8295 14.1226 12.1835C14.0403 11.264 13.7693 10.3714 13.3264 9.56143C12.8836 8.75144 12.2785 8.0415 11.5488 7.47597C10.4477 6.65644 9.1274 6.18337 7.75633 6.11706C6.39934 5.98862 5.03279 6.22804 3.80029 6.81015L3.62896 6.89191V0H0.027259V1.67431C0.027259 5.98857 0.027259 10.8791 0.027259 14.5587C0.00389659 16.4199 0.00389373 18.8885 0 21.069V21.8477H3.64453V21.1274C3.64453 18.3745 3.64452 14.6054 3.70682 12.9506C3.76912 11.2957 4.48556 10.6494 4.99564 10.2795" fill="#231F20" />
                                <path d="M78.0147 6.17127C76.4558 6.16896 74.9313 6.62901 73.6339 7.49323C72.3365 8.35744 71.3246 9.58701 70.7261 11.0264C70.1276 12.4658 69.9694 14.0503 70.2715 15.5797C70.5736 17.109 71.3226 18.5143 72.4235 19.6179C73.5244 20.7216 74.9279 21.4739 76.4564 21.7799C77.985 22.0858 79.5699 21.9315 81.0108 21.3365C82.4517 20.7416 83.6837 19.7327 84.5511 18.4375C85.4185 17.1422 85.8824 15.6188 85.8839 14.06C85.8839 11.9711 85.0555 9.96755 83.5803 8.48869C82.1051 7.00983 80.1035 6.17643 78.0147 6.17127ZM78.0147 18.3742C77.1597 18.3742 76.3239 18.1207 75.6131 17.6456C74.9022 17.1705 74.3483 16.4952 74.0213 15.7053C73.6943 14.9153 73.6089 14.0461 73.776 13.2076C73.943 12.3691 74.3551 11.599 74.9599 10.9947C75.5648 10.3904 76.3352 9.97905 77.1739 9.81272C78.0125 9.6464 78.8817 9.73254 79.6714 10.0603C80.461 10.388 81.1358 10.9426 81.6102 11.6538C82.0846 12.3651 82.3375 13.2011 82.3367 14.0561C82.3347 15.2014 81.8785 16.2991 81.0683 17.1086C80.2581 17.9181 79.16 18.3732 78.0147 18.3742Z" fill="#231F20" />
                            </svg>
                        </Box>
                        <Box textAlign='left'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28"><path d="M17 15.2454V22.1169C17 22.393 16.7761 22.617 16.5 22.617C16.4094 22.617 16.3205 22.5923 16.2428 22.5457L12 20L7.75725 22.5457C7.52046 22.6877 7.21333 22.6109 7.07125 22.3742C7.02463 22.2964 7 22.2075 7 22.1169V15.2454C5.17107 13.7793 4 11.5264 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9C20 11.5264 18.8289 13.7793 17 15.2454ZM12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15ZM12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13Z" fill="rgba(255,255,255,1)"></path></svg>
                            <Typography component="div" variant="h4" className="mt-2">{'Inglés profesional'}</Typography>
                        </Box>
                        <Box width={"100%"} textAlign="center" className={`${classes.HH_online_card_button_container} mb-4`}>
                            <Button className={`${classes.HH_online_card_button} w-100`} variant="contained" size="small" onClick={onClick}>
                                {!loading ? 'Ir a harmon hall' : <CircularProgress style={{ width: '10%', height: 'auto' }} />}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
};

export default VoxyHHCard;