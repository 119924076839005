import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    showDocument: {
        display: 'flex'
    },
    downloadLink: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px'
    },
    fileIcon:{
        fontSize: 40,
        color: '#A86FFB',
        marginRight: 38
    }
}));