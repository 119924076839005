import React from 'react';
import { connect } from 'react-redux';

import { UsersService } from '@sdk-point/talisis';

import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import Toast from '../../../shared/components/Toast/Toast';
import StudentList from '../../Simulation/StudentList/StudentList';

import { Box, Button, Card, CardContent, Grid, List, ListItem, Paper, TextField } from '@material-ui/core';

const NewGroup = (props) => {

    const { user } = props;
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        owner_id: user.person_id,
        name: '',
        description: '',
        members: []
    });
    const [Toastprops, setToastProps] = React.useState({ message: '' });
    const [errors, setErrors] = React.useState({});
    const UsersServiceSDK = new UsersService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

    const items = [
        { to: '/usuarios/grupos', label: 'Grupos' },
        { label: 'Alta de Nuevo Grupo' },
    ]

    const handleChange = (e) => {
        let { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleMembers = (e, value) => {
        setForm({ ...form, members: value });
    };

    const PersonsList = () => {
        if (form.members.length > 0) {
            return (
                <Paper>
                    <List>
                        {form.members.map(member => (
                            <ListItem key={member.id}>
                                {member.full_name}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            );
        }
        return null;
    };

    const validate = () => {
        const errors = {};
        const requiredFields = [
            'name',
            'description',
            'members',
        ];

        for (const field of requiredFields) {
            if (!form[field] || form[field].length === 0) {
                errors[field] = 'Campo Requerido *';
            }
        }

        setErrors(errors);
        return Boolean(Object.keys(errors).length === 0);
    }

    const handleSubmit = async () => {
        if (validate()) {
            try {
                setLoading(true);
                const body = {
                    name: form.name,
                    description: form.description,
                    owner_id: form.owner_id,
                    members: form.members.map(member => member.id),
                };
                const response = await UsersServiceSDK.createGroup(body);
                if (response.ok) {
                    setToastProps({ open: true, message: 'El grupo fue creado correctamente.' });
                }
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
                setToastProps({
                    open: true,
                    message: `Ops! Ha ocurrido un error al intentar crear el grupo. ${error}`,
                    severity: 'error',
                });
            }
        }
    };

    const handleFinishedToast = () => {
        setToastProps({ ...Toastprops, open: false });
        if (Object.keys(errors).length > 0) {
            setToastProps({ ...Toastprops, open: false });
        }
        else {
            props.history.push('/usuarios/grupos');
        }
    }

    return (
        <React.Fragment>
            <Breadcrumb items={items} separator="/" />
            <LoaderContent loading={loading}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    placeholder="Ingresar texto"
                                    label="Nombre de Grupo"
                                    fullWidth
                                    margin="dense"
                                    name="name"
                                    value={form.name}
                                    onChange={handleChange}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    variant="outlined"
                                    placeholder="Ingresar texto"
                                    label="Descripción"
                                    fullWidth
                                    margin="dense"
                                    name="description"
                                    multiline
                                    minRows={5}
                                    value={form.description}
                                    onChange={handleChange}
                                    error={Boolean(errors.description)}
                                    helperText={errors.description}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <StudentList user={user} handleChange={handleMembers} includePersons={true} />
                                    <PersonsList data={form.members} />
                                    {errors && errors.members && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required MuiFormHelperText-marginDense">Campo Requerido *</p>}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end" width="100%" >
                                    <Button color="primary" variant="contained" onClick={handleSubmit}>Crear</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </LoaderContent>
            <Toast {...Toastprops} onFinished={handleFinishedToast} />
        </React.Fragment>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(NewGroup);