import { PRODUCT_TYPES } from "@sdk-point/talisis";

// Función para validar si solo hay productos tipo "curso"
const hasOnlyCourses = (orderDetails) => {
    return orderDetails.every(item => item.product_type_id === PRODUCT_TYPES.CURSO);
};

// Función para validar si tiene un producto tipo "membresía"
const hasMembership = (orderDetails) => {
    return orderDetails.some(item => item.product_type_id === PRODUCT_TYPES.MEMBRESIA);
};

// Función para validar si tiene mínimo dos productos: uno tipo "membresía" y otro tipo "programa"
// const hasMembershipAndProgram = (orderDetails) => {
//     const hasMembership = orderDetails.some(item => item.product_type_id === PRODUCT_TYPES.MEMBRESIA);
//     const hasProgram = orderDetails.some(item => item.product_type_id === PRODUCT_TYPES.PROGRAMA);
//     return hasMembership && hasProgram;
// };

// Función principal de validación considerando el peso de las condiciones
export const validateOrderDetails = (orderDetails = []) => {
    /*if (hasMembershipAndProgram(orderDetails)) {
        return PRODUCT_TYPES.PROGRAMA;
    } else */
    if (hasMembership(orderDetails)) {
        return PRODUCT_TYPES.MEMBRESIA;
    } else if (hasOnlyCourses(orderDetails)) {
        return PRODUCT_TYPES.CURSO;
    }
    return null;
};

export const getDefaultProductOfType = (orderDetails = [], productTypeId) => {
    return orderDetails.find(item => item?.product_type_id === productTypeId) || null;
};

export const getLastOrderPayment = (orderPayments = []) => {
    return orderPayments.length > 0 ? orderPayments[orderPayments.length - 1] : null;
};