import React from 'react';

// @material-ui/core components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, Tooltip } from '@material-ui/core';
import { Add, ChevronRight, Group, Lock } from '@material-ui/icons';

// @utils
import * as DateUtils from '../../../../../../shared/utils/dateUtils';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const Principal = ({ data: formation, onSetForm }) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        grid: {
            borderBottom: `1px solid ${theme.palette.colors.grey[700]}`,
            padding: '3em',
        },
        card: {
            backgroundColor: theme.palette.colors.grey[400],
            padding: '1em',
        },
        hr: {
            border: '0px',
            borderTop: `1px solid ${theme.palette.colors.grey[700]}`,
        },
        title: {
            color: theme.palette.colors.white,
            fontSize: '18px',
            fontWeight: 'normal',
        },
        label: {
            color: theme.palette.colors.fillStroke[100],
            fontWeight: 600,
            fontSize: '12px',
        },
        text: {
            color: theme.palette.colors.white,
            fontSize: '10px',
            marginLeft: '1.2em',
            fontWeight: '600',
        },
        inputText: {
            border: `1.5px solid ${theme.palette.colors.fillStroke[200]}`,
            borderRadius: '5px',
            color: theme.palette.colors.white,
            padding: '0.5em'
        },
        select: {
            '& .MuiSvgIcon-root': {
                color: theme.palette.colors.fillStroke[100],
            },
            height: '30px',
        },
        icon: {
            color: theme.palette.colors.fillStroke[100],
            fontSize: '14px',
        },
        iconContainer: {
            textAlign: 'center',
        },
        toogleButton: {
            backgroundColor: theme.palette.colors.grey[500],
            border: `2px solid ${theme.palette.colors.fillStroke[200]}`,
            color: theme.palette.colors.white,
        },
        toogleButtonSelected: {
            '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: theme.palette.colors.fillStroke[100],
                color: theme.palette.colors.white,
            }
        },
        button: {
            border: `1.5px solid ${theme.palette.colors.grey[600]}`,
            backgroundColor: theme.palette.colors.black,
            borderRadius: '4px',
            color: theme.palette.colors.white,
            fontSize: '12px',
            '& .MuiButton-label': {
                textTransform: 'none',
                textAlign: 'left',
            }
        },
        footerLabel: {
            fontSize: '12px',
            fontWeight: 600,
        }
    });

    const classes = useStyles();
    return (
        <Grid container>
            <Grid container spacing={1}>
                {
                    formation.career && formation.career.length > 0 ?
                        <Grid item xs={12} className={classes.grid}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} className={classes.titleContainer}>
                                    <Typography variant="subtitle2" className={classes.label}>
                                        Profesión
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container spacing={2} key={`career_detail`}>
                                        <Grid item xs={1} className={classes.iconContainer}>
                                            <div className="Profile_FormControl">
                                                <Tooltip title={<span>&#8856;</span>} placement="right-end">
                                                    {formation.career[0].visibility === 'public' ? <Group className={classes.icon} /> : <Lock className={classes.icon} />}
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <div className="Profile_FormControl">
                                                <Typography variant="subtitle2" className={classes.text} style={{ width: '100%' }}>
                                                    {formation.career[0].career}
                                                </Typography>
                                                <Tooltip title="Ver todos" placement="right-end" onClick={() => onSetForm(2.1)}>
                                                    <ChevronRight style={{fontSize: '1.5rem'}} className={`Profile_ChevronLeft ${classes.icon}`} />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> : ''
                }
                {
                    formation.job_history && formation.job_history.length > 0 ?
                        <Grid item xs={12} className={classes.grid}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} className={classes.titleContainer}>
                                    <Typography variant="subtitle2" className={classes.label}>
                                        Trabajo
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container spacing={2} key={`jh_detail`}>
                                        <Grid item xs={1} className={classes.iconContainer}>
                                            <div className="Profile_FormControl">
                                                <Tooltip title={<span>&#8856;</span>} placement="right-end">
                                                    {formation.job_history[0].visibility === 'public' ? <Group className={classes.icon} /> : <Lock className={classes.icon} />}
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <div className="Profile_FormControl">
                                                <Typography variant="subtitle2" className={classes.text} style={{ width: '100%' }}>
                                                    {formation.job_history[0].company_name}
                                                    <br />
                                                    <div className="Profile_DetailContent" style={{lineHeight: '15px'}}>{formation.job_history[0].position}</div>
                                                    <div className="Profile_DetailContent" style={{lineHeight: '15px'}}>{`${formation.job_history[0].start_year} - ${formation.job_history[0].ending_year === DateUtils.CURRENT_YEAR ? 'Actual' : formation.job_history[0].ending_year}`}</div>
                                                </Typography>
                                                <Tooltip title="Ver todos" placement="right-end" onClick={() => onSetForm(3.1)}>
                                                    <ChevronRight style={{fontSize: '1.5rem'}} className={`Profile_ChevronLeft ${classes.icon}`} />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> : ''
                }
                {
                    formation.education && formation.education.length > 0 ?
                        <Grid item xs={12} className={classes.grid}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} className={classes.titleContainer}>
                                    <Typography variant="subtitle2" className={classes.label}>
                                        Estudios
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container spacing={2} key={`edu_detail`}>
                                        <Grid item xs={1} className={classes.iconContainer}>
                                            <div className="Profile_FormControl">
                                                <Tooltip title={<span>&#8856;</span>} placement="right-end">
                                                    {formation.education[0].visibility === 'public' ? <Group className={classes.icon} /> : <Lock className={classes.icon} />}
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <div className="Profile_FormControl">
                                                <Typography variant="subtitle2" className={classes.text} style={{ width: '100%' }}>
                                                    <b>{formation.education[0].educational_institution_name}</b>
                                                    <div className="Profile_DetailContent" style={{lineHeight: '15px'}}>{formation.education[0].specialization}</div>
                                                    <div className="Profile_DetailContent" style={{lineHeight: '15px'}}>{`${formation.education[0].start_year} - ${formation.education[0].ending_year === DateUtils.CURRENT_YEAR ? 'Actual' : formation.education[0].ending_year}`}</div>
                                                </Typography>
                                                <Tooltip title="Ver todos" placement="right-end" onClick={() => onSetForm(4.1)}>
                                                    <ChevronRight style={{fontSize: '1.5rem'}} className={`Profile_ChevronLeft ${classes.icon}`} />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> : ''
                }
            </Grid>
            <hr className={classes.hr} />
            <Grid container>
                <Grid item xs={12} className="mt-2" style={{ borderBottom: `1px solid ${theme.palette.colors.grey[700]}` }}>
                    <Typography variant="subtitle2" className={`${classes.footerLabel} mb-4`}>
                        Profesión actual, historial laboral e historial de formación
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        startIcon={<Add />}
                        className={`mt-4 Profile_AddedButton ${classes.button}`}
                        onClick={() => {
                            onSetForm(1);
                        }}
                        size="large">
                        Añadir datos sobre trabajo y estudios
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
