import React from 'react';
import { Link } from 'react-router-dom';

// @components
import { convertOuToSource } from '../../../constants/convertOuToSource';
import CourseCardBody from '../../../shared/components/CourseCard/CourseCardBody';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// @SDK
import { MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../shared/components/Mixpanel/mixpanel';

const useStyles = makeStyles(({
    root: {
        '& .card_content': {
            width: '100% !important'
        },
        '& .card_title': {
            textAlign: 'left',
        }
    },
    link: {
        cursor: 'pointer',
    },
}));

const GenericBrandLandingPageDemorCourses = (props) => {
    const { user, popularPrograms, className } = props;
    const classes = useStyles();

    const setTMixpanelTrack = (courseId, courseName, companyId, source_name) => {
        
    }

    const demoProgram = [
        {
            "id": 62430,
            "demo_id": "demo",
            "source_id": 14,
            "name": "Integridad",
            "lang": null,
            "estimated_time": null,
            "difficulty_level": null,
            "image_url": "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_1.jpg",
            "course_url": "/demo",
            "created_at": "2022-09-12T18:06:30.827Z",
            "last_updated": "2022-10-28T05:30:58.163Z",
            "is_active": true,
            "modality_id": 2,
            "level_id": null,
            "learning_style_id": 3,
            "company_id": "UERRE",
            "price": 0,
            "list_price": 0,
            "total_review": 0,
            "course_rating": null
        },
        {
            "id": 62430,
            "demo_id": "demo2",
            "source_id": 14,
            "name": "Adminístrate al 100",
            "lang": null,
            "estimated_time": null,
            "difficulty_level": null,
            "image_url": "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_2.jpg",
            "course_url": "/demo2",
            "created_at": "2022-09-12T18:06:30.827Z",
            "last_updated": "2022-10-28T05:30:58.163Z",
            "is_active": true,
            "modality_id": 2,
            "level_id": null,
            "learning_style_id": 3,
            "company_id": "TAL",
            "price": 0,
            "list_price": 0,
            "total_review": 0,
            "course_rating": null
        }
    ]

    return (
        <React.Fragment>
            <Typography variant="h2" align="center" className={className}>Cursos Demo</Typography>
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" className={classes.root}>
                {demoProgram.length > 0 && demoProgram.map(program => {
                    let linkProps = {};
                    if (!/^((http|https):\/\/)/.test(program.course_url)) {
                        program.course_url = "http://" + program.course_url;
                    }

                    linkProps.to = { pathname: `/${program.demo_id}`, state: { prevPath: 'mis_cursos' } };

                    return (
                        <Grid key={`grid_item_course_${program.id}`} item xs={12} sm={6} md={3} lg={3}>
                            <Link key={`link_course_${program.id}`} onClick={(e) => setTMixpanelTrack(program?.id, program?.name, program?.company_id, program?.source_name)} {...linkProps} style={{ textDecoration: 'none' }}>
                                <CourseCardBody
                                    key={`card_course_${program.id}`}
                                    media={{
                                        company_id: program?.company_id,
                                        source: {
                                            id: 14
                                        },
                                        raiting: {
                                            value: program?.course_rating != null ? program?.course_rating : 0,
                                            totalReview: program?.total_review != null ? program?.total_review : 0,
                                        },
                                        labels: program?.labels,
                                        image: program?.image_url,
                                        saved: (program?.is_favorited == 1 || program?.is_favorited == true) ? true : false,
                                        course_id: program?.id,
                                        userId: user?.person_id,
                                    }}
                                    content={{
                                        saved: (program?.is_favorited == 1 || program?.is_favorited == true) ? true : false,
                                        label: program?.label,
                                        title: program?.name,
                                        course_id: program?.id,
                                        userId: user?.person_id,
                                        detail: {
                                            sourceId: convertOuToSource(program.company_id, program.source_id),
                                            lessons: program?.lessons,
                                            level: program?.difficulty_level,
                                            estimated_time: program?.estimated_time,
                                        },
                                        prices: {
                                            original: program?.list_price,
                                            current: program?.price,
                                        },
                                        modalityId: program?.modality_id != null ? program?.modality_id : 2,
                                    }}
                                />
                            </Link>
                        </Grid>
                    )
                })}
            </Grid>
        </React.Fragment>
    )
};

export default GenericBrandLandingPageDemorCourses;