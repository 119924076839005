import React from 'react';
import { Box, Button, Grid, Typography, makeStyles} from '@material-ui/core';

import { userIsLogged } from '../../shared/utils/Sessions';
import * as path from '../../routes/paths';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        padding: '5% 10% 0% 10%',
        textAlign: 'center',
        width: '90%',
    },
    left: {
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        }
    },
    right: {
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        }
    },
    img: {
        position: 'absolute',
        marginLeft: '-35px',
        marginTop: '-40px',
    },
    message: {
        marginTop: `${theme.spacing(10)}px`,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'justify',
        }
    }
}));

const IMAGES = {
    number_0  : '/images/theme/number-0.svg',
    number_4  : '/images/theme/number-4.svg',
    surprised : '/images/theme/surprised.svg',
};

export const NotFound = (props) => {
    const classes = useStyles();
    const loggedIn = userIsLogged();

    return (
        <Grid container spacing={0} className={classes.root}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.right}>
                <img src={IMAGES.number_4} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Box display="inline-flex">
                    <img src={IMAGES.surprised} className={classes.img} />
                    <img src={IMAGES.number_0} />
                </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.left}>
                <img src={IMAGES.number_4} />
            </Grid>
            <Grid xs={12} className={classes.message}>
                <Typography component="div" variant="body1" className="semi-bold">Lo sentimos, la página que buscas no se encuentra en nuestro plan de estudios.</Typography>
                <Typography component="div" variant="body1" className="semi-bold">Pero eso no significa que no puedas aprender algo nuevo en <Typography component="span" variant="body1" color="primary" className="semi-bold">talisis.com</Typography></Typography>
            </Grid>
            <Grid xs={12}>
                <Button color="default" variant="contained" onClick={() => props.history.push(`${path.BUSQUEDA}?educon=true`)} className="mt-5 ml-2 mr-2">Ver catálogo</Button>
                <Button color="primary" variant="contained" onClick={() => props.history.push(loggedIn ? path.HOME : '/')} className="mt-5 ml-2 mr-2">Volver al inicio</Button>
            </Grid>
        </Grid>
    )
};