export default (url, name, newTab = false) => {
    const link = document.createElement('a');
    
    if (newTab) {
        link.href = url;
        link.target = "_blank"; 
    }

    if (!!name) link.download = name;

    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};