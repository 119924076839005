import React, { lazy, Suspense, useReducer } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { generatePath } from "react-router";

// Routes
import * as paths from '../../../routes/paths';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

// shared core components
import SlickCarousel from '../../../shared/components/SlickCarousel/SlickCarousel';
import TitleLink from '../../../shared/components/TitleLink/TitleLink';
import TabCarousel from '../../../shared/components/Tabs/TabCarousel';
import { log } from '../../../shared/utils/console.js'
import CardSkeletonLoading from '../../components/Card/CardSkeletonLoading';
import CourseCard from '../../components/Card/CourseCard/CourseCard';

// services
import { CourseService, MarketPlaceService } from '@sdk-point/talisis';
import { convertOuToSource }from '../../../../src/constants/convertOuToSource';
import * as imageSource from '../../../shared/utils/imageSource';


//MIXPANEL
import {  MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../Mixpanel/mixpanel';
import CoursesProvider from './context/CoursesProvider';
import coursesReducer, { initialCourses, types } from './context/CoursesReducer';

import { SwiperSlide } from 'swiper/react/swiper-react.js';
import { v4 as uuid } from 'uuid';
import NothingFound from '../NothingFound/NothingFound';
import { SOURCES } from '../../constants/sources';
import clsx from 'clsx';
import CourseCardSkeleton from '../Card/CardSkeletonLoading/CourseCardSkeleton';
import { eventData, eventTracker } from '../EventTracker/EventTracker';

const CourseCardBody = lazy(() => import('../CourseCard/CourseCardBody'));

const tabs = [
    {value:'Todos', uuid: uuid()},
    {value:'Populares', uuid: uuid()},
    {value:'Nuevas', uuid: uuid()},
];

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        padding: props => !!props.withoutPaddings ? "" : '32px 0 62px',
        minHeight: props => !!props.withoutPaddings ? 470 : 576,
        [theme.breakpoints.down('xs')]:{
            minHeight: '0px !important',
            padding: '24px 0px !important',
        }
    },
    carousel: {
        '& .slick-list':{
            '& .slick-track':{
                display: "flex",
                flexFlow: "nowrap",
            },
            '& .slick-slide':{
                width: "312px",
            }
        },
        [theme.breakpoints.down('xs')]:{
            padding: '0px',
            marginLeft: 16
        }
    },
    hover_carousel_detail:{
        '& > h4':{
            marginLeft: "0px",
        },
        '& > div':{
            paddingLeft: "0px",
            paddingRight: "0px",
        },
        
    },
    hover_carousel:{
        '&:hover':{
            '& h4':{
                '& button':{
                    background: "transparent",
                },  
            },
        },
        "@media (max-width: 768px)": {
            '& h4':{
                '& button':{
                    background: "transparent"
                },  
            },
        }
    },
    buttonGroup: {
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: `${theme.spacing(1)}px 0px`,
        [theme.breakpoints.down('xs')]:{
            margin: '0px'
        }
    },
    title:{
        marginLeft: "59px",
        width: "100%",
        "@media (max-width: 1250px)": {
            marginLeft: "5px",
        },
    },
    card: {
        padding: "12px",
        maxWidth: "fit-content",
        [theme.breakpoints.down('xs')]:{
            padding: "8px",
        }
    },
    cardActionArea:{
        position: "relative",
        maxWidth: "286px",
    },
    tabMain:{
        '& .MuiTabs-root':{
            marginBottom: "20px",
            maxWidth: theme.breakpoints.maxWidth.desktop,
            marginLeft: "68px",
        },
    },
    titleLink:{
        paddingLeft: 0
    }
}));


function transformDataPriceMembership(data){
    return data.map(course =>({
        ...course,
        company_id: course.company,
        source_id: course.source,
        priceMembershipActions: true,
        price: {
            number: course.price,
            listNumber: course.list_price,
        },
        image_url: course.image || imageSource.convertSourceToImageResource(course.company, course.source)
    }));
}


const CourseCarousel = (props) => {
    const { id, user, handleSetChargeStatusForOptimize, handleChangeTabSaved, type, viewCourseDetails, isMyCourses, course_id, urlMore, isDetail, showTabs, title, withoutPaddings, noDataFound, clickFrom, hideShowMoreResponsive} = props;
    const classes = useStyles({withoutPaddings});
    const history = useHistory();
    const abortData = new AbortController();
    
    const [hiddeCarouselByEmpty, setHiddeCarouselByEmpty] = React.useState(false);
    const [dynamicTitleLink, setDynamicTitleLink] = React.useState('Conoce nuestros cursos de educación continua');
    const [lengthCarousel, setLengthCarousel] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const [coursesState, dispatch] = useReducer(coursesReducer, initialCourses);
    const [value, setValue] = React.useState(0);
    const { courses }  = coursesState;
    const [finishSearch, setFinishSearch] = React.useState(false);
   
    const getFavorites = async () => {
        let coursesData = [];
        const favorite = await courseService.getUserFavoriteCourses(user.person_id);
        if(favorite?.response?.length > 0){
            if(favorite.response.length > 10){
                coursesData = favorite.response.slice(0, 10);
            }else{
                coursesData = favorite.response.slice();
            }
        }
        handleSetChargeStatusForOptimize("favorites");
        return coursesData;
    }
   
    const getData = async () => {
        try {
            let coursesData = [];
            setLoading(true);
            if(id === "favorites"){
                const coursesResponse = await getFavorites();
                coursesData = coursesResponse.slice();
                
            }else if(id === "recommendations"){
                const typeRecommendation = type != null ? type : "generic";
                let recommended = await courseService.getCoursesRecommendedByType(user.person_id, typeRecommendation);
            
                if(recommended?.data?.courses?.length > 0){
                    if(typeRecommendation == "consumo" || typeRecommendation == "interes"){
                        recommended.data.courses = recommended.data.courses.map(course =>({
                            ...course,
                            company_id: course.company,
                            source_id: course.source,
                            priceMembershipActions: true,
                            price: {
                                number: course.price,
                                listNumber: course.list_price,
                            },
                            image_url: imageSource.convertSourceToImageResource(course.company, course.source)
                        }));

                        if(typeRecommendation == "consumo" && !!recommended.data.titleLink.length) setDynamicTitleLink("Por que cursaste " + recommended.data.titleLink);
                        if(typeRecommendation == "interes") setDynamicTitleLink("Más cursos sobre lo que te interesa");    
                    }

                    coursesData = recommended.data.courses.slice();
                }
                if(handleSetChargeStatusForOptimize){
                    handleSetChargeStatusForOptimize("generic");
                }
                
                setFinishSearch(true);

            }else if(id === "courseDetail"){
                if(course_id){
                    const recommended = await courseService.getCoursesRecommendedByCourseId(user.person_id, "courseDetail", course_id);
                    if(recommended?.data?.length > 0){
                        setDynamicTitleLink(title || "Te recomendamos");
                        const coursesResponseTemp = transformDataPriceMembership(recommended?.data);

                        if (coursesResponseTemp?.length > 0) {
                            coursesData = coursesResponseTemp.slice();
                        }
                        //coursesData = recommended.data.courses.slice();
                    }
                }
            } else if (id && id.includes("catalog::")) {
                const op = id.split("::")[1];
                if(op === 'educon_courses'){ // explora(educacion continua)
                    const coursesResponse = await marketService.getCoursesRecommended(op, user.person_id, 0, 20);
                    const coursesResponseTemp = transformDataPriceMembership(coursesResponse);

                    if (coursesResponseTemp?.length > 0) {
                        coursesData = coursesResponseTemp.slice();
                    }
                } else if(op === 'popular_courses'){ // explora(cursos populares)
                    const coursesResponse = await marketService.getCoursesRecommended(op, user.person_id, 0, 20);
                    const coursesResponseTemp = transformDataPriceMembership(coursesResponse);

                    if (coursesResponseTemp?.length > 0) {
                        coursesData = coursesResponseTemp.slice();
                    }
                } else{
                    const coursesResponse = await marketService.getCoursesRecommended(op, user.person_id, 0, 20);
                    const coursesResponseTemp = coursesResponse.filter(it => it.source !== SOURCES.AWS_SKILL_BUILDER).map(course =>({
                        ...course,
                        priceMembershipActions: true,
                        price: {
                            number: course.price,
                            listNumber: course.list_price,
                        },
                        image_url: course.image || imageSource.convertSourceToImageResource(course.company, course.source)
                    }));
                    if (coursesResponseTemp?.length > 0) {
                        coursesData = coursesResponseTemp.slice();
                    }
                }

            }else if (id && id.includes("escuela::")) { // landing escuela
                const ou = id.split("::")[1];
                const recommended = await courseService.getCoursesRecommendedByType(user.person_id, "escuela", ou);  
                const coursesResponseTemp = transformDataPriceMembership(recommended?.data?.courses);

                if (coursesResponseTemp?.length > 0) {
                    coursesData = coursesResponseTemp.slice();
                }
            } else {
                const typeRecommendation = type != null ? type : "generic";
                const recommended = await courseService.getCoursesRecommended(user.person_id, typeRecommendation);
                if(recommended?.courses?.length > 0){
                    coursesData = recommended.courses.slice();
                }
            }
            return coursesData;
        } catch (e) {
            log(e.message);
        }
    };

    React.useEffect(() => {
        let isCanceled = true;
        setDynamicTitleLink(title);

        getData().then((data)=>{
            if(isCanceled){                
                if(!data || data?.length == 0){
                    setLengthCarousel(0);
                    setHiddeCarouselByEmpty(true);
                }else{
                    setLengthCarousel(data?.length);
                    setHiddeCarouselByEmpty(false);
                }
                dispatch({ type: types.addCourses, payload: data});
                setLoading(false);  
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });

        return () => (isCanceled = false);
    }, []);

    const handleClickInTitle = (e) =>{
        e.preventDefault();
        if(id === "favorites"){
            handleChangeTabSaved();
        }else if(id === "catalog::educon_courses"){
            history.push(`${paths.BUSQUEDA}?educon=true`);
        }else if(id === "catalog::discovery_courses"){
            history.push(`${paths.BUSQUEDA}?explorar=true`);
        }else{
            history.push(`${paths.BUSQUEDA}?explorar=true`);
        }
    }

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setLoading(true);
        if(newValue == 0){
            getData().then((data)=>{
                if( !data || data?.length == 0){
                    setLengthCarousel(0);
                    setHiddeCarouselByEmpty(true);
                }else{
                    setLengthCarousel(data?.length);
                    setHiddeCarouselByEmpty(false);
                }
                dispatch({ type: types.addCourses, payload: data});
                setLoading(false);
            }).catch((err) => {
              log(`Failed: ${err}`);
            });
        }else if(newValue == 1){
            setLengthCarousel(0);
            dispatch({ type: types.addCourses, payload: []});
            setLoading(false);
        }
        else if(newValue == 2){
            setLengthCarousel(0);
            dispatch({ type: types.addCourses, payload: []});
            setLoading(false);
        }
        setValue(newValue);
      }

    return (
        <CoursesProvider>
            <Box className={classes.root}>
                <TitleLink textPrimary={dynamicTitleLink} textSecondary="Ver todos" onClick={ (e) => handleClickInTitle(e)} className={classes.titleLink} hideSubtitleResponsive={hideShowMoreResponsive}/>
                <Box className={clsx({'position-relative': !courses.length && !finishSearch}, classes.carousel)}>
                    {
                        !courses.length && !finishSearch && 
                        <Box position='relative' minHeight={317}>
                            <CourseCardSkeleton />
                        </Box>
                    }
                    {
                        !courses.length && finishSearch && <NothingFound caption={noDataFound.caption} subcaption={noDataFound.subcaption} type={noDataFound.type}/>
                    }
                    {  Boolean(courses.length) && 
                        <Box position="relative" className={ isDetail ? (classes.hover_carousel + " " + classes.hover_carousel_detail): classes.hover_carousel}>
                            {showTabs && <TabCarousel tabs={tabs} handleChange={handleChange} value={value}/>}
                            <SlickCarousel isDetail={isDetail} length={lengthCarousel} typeCard="normal" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                                {
                                    courses.length && courses.map((courseItem, index) => {
                                        let linkProps = {};

                                        if (!viewCourseDetails && courseItem.course_url) {
                                            if (props.linkProps) {
                                                linkProps = { ...props.linkProps }
                                            } else {
                                                var pattern = /^((http|https):\/\/)/;
                                                if (!pattern.test(courseItem.course_url)) {
                                                    courseItem.course_url = "http://" + courseItem.course_url;
                                                }
                                                linkProps.to={pathname: generatePath(paths.CURSO_DETALLE, {id: courseItem.id}), state: { prevPath: isMyCourses ? "mis_cursos": "", target: null  }};
                                            }
                                        }else{
                                            linkProps.to={pathname: generatePath(paths.CURSO_DETALLE, {id: courseItem.id}), state: { prevPath: isMyCourses ? "mis_cursos": "", target: null  }};
                                        }

                                        return (
                                            <SwiperSlide className={classes.card} key={courseItem.id}>
                                            <RouterLink onClick={() => {
                                                let brand = courseItem?.company_id !== null ? courseItem?.company_id : courseItem?.source_name;
                                                brand = brand ? brand.toLowerCase().split(" ")[0] : '';
                                                eventData['source'] = clickFrom;
                                                eventData['marcaCurso'] = brand;
                                                eventData['courseID'] = courseItem.id;
                                                eventTracker('ver_curso', eventData, ['mixpanel']);
                                            }} {...linkProps} style={{ textDecoration: "none" }}>
                                                <CourseCard 
                                                    data={{
                                                        company_id: courseItem?.company_id,
                                                        source:{
                                                            id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                        },
                                                        raiting: {
                                                            value: courseItem?.course_rating != null ? courseItem?.course_rating : 0,
                                                            totalReview: courseItem?.total_review != null ? courseItem?.total_review : 0,
                                                        },
                                                    // labels: "Popular",
                                                        labels: courseItem?.labels,
                                                        image:  courseItem?.image_url,
                                                        saved: (courseItem?.is_favorited == 1 || courseItem?.is_favorited == true) ? true : false,
                                                        course_id: courseItem?.id,
                                                        userId: user?.person_id,
                                                        saved: (courseItem?.is_favorited == 1 || courseItem?.is_favorited == true) ? true : false,
                                                        label: "",
                                                        title: courseItem?.name,
                                                        course_id: courseItem?.id,
                                                        userId: user?.person_id,
                                                        prices: {
                                                            original: courseItem?.list_price,
                                                            current: courseItem?.price,
                                                        },
                                                        detail: {
                                                            sourceId: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                            companyId: courseItem?.company_id,
                                                            lessons: courseItem?.lessons,
                                                            level: courseItem?.difficulty_level,
                                                            estimated_time: courseItem?.estimated_time,
                                                            userLicensed: courseItem?.is_license_active,
                                                            coursePurchased: courseItem?.is_purchased
                                                        },
                                                        modalityId: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                        content: courseItem,
                                                        priceMembershipActions: courseItem.priceMembershipActions
                                                    }}
                                                />
                                            </RouterLink>      
                                        </SwiperSlide>
                                        )
                                    })
                                }
                            </SlickCarousel>

                        </Box>
                    }
                    <Hidden smUp>
                        <Box textAlign='center'>
                            <Button color='primary' endIcon={<Icon className='ri-arrow-right-s-line' style={{fontSize: '24px !important'}} />} onClick={handleClickInTitle}>Ver todos</Button>
                        </Box>
                    </Hidden>
                </Box>
            </Box>
        </CoursesProvider>
        
    );
};

CourseCarousel.defaultProps = {
    lazyLoading: false,
    disableTitleAction: false,
    viewCourseDetails: false,
    urlMore: '',
    withoutPaddings: false,
    noDataFound: {
        caption: "", 
        subcaption: "", 
        type: 'search',
    },
    hideShowMoreResponsive: false
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default React.memo(connect(mapStateToProps, {})(CourseCarousel), (prevProps, nextProps) => prevProps.show === nextProps.show) ;

