import palette from '../palette';

export default {
    root: {
        color: palette.colors.yellow[300],
        fontSize: 14,
        '& .MuiRating-iconEmpty':{
            color: palette.colors.fillStroke[100]
        }
    }
};