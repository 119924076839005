import React from 'react';

// @material-ui/core
import { makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        textDecoration: 'none',
        fontWeight: '500'
    },
}));

const ReadMore = ({ children, className, minLength, variant }) => {
    const classes = useStyles();
    const [readMore, setReadMore] = React.useState(false);
    const text = children ? children : "";
    const canReadMore = text.length && text.length > minLength;
    const mainText = text.substring(0, minLength);
    const moreText = text.substring(minLength, text.length);

    const handleReadMore = () => {
        setReadMore(prev => !prev);
    };

    return (
        <div className={className}>
            <Typography variant={variant}>
                {mainText}
                {canReadMore && readMore !== true ? <span id="dots">...</span> : null}
                {canReadMore && readMore === true ? <span id="more">{moreText}</span> : null}
            </Typography>
            {
                canReadMore === true ? (
                    <Link
                        component="button"
                        color="primary"
                        variant={variant}
                        className={classes.button}
                        onClick={handleReadMore}
                    >
                        {readMore === true ? "Ver menos" : "Ver más"}
                    </Link>
                ) : null
            }
        </div>
    );
};

export default ReadMore;