import React, { useEffect } from 'react';
import { connect } from "react-redux";
// @material-ui/core
import Box from '@material-ui/core/Box';

import SurveyCard from '../SurveyCard/SurveyCard';
import { getSurveys } from '../../../../../services/SurveysService';

// shared
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent';
import NothingFound from '../../../../../shared/components/NothingFound/NothingFound';
import { log } from '../../../../../shared/utils/console.js'


const Available = (props) => {
    const { user } = props;
    const [surveys, setSurveys] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    
    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            await getSurveys(user).then(async response => {
                let resp = response.surveys_availiables.data.persona?.encuestas || null;
                log(JSON.stringify(resp));
                setSurveys(resp);
                setLoading(false);
            });
        } catch (e) {
            log("error", e);
            setLoading(false);
        }
        
    };

    const getURL = (data) => {
        let response = null;
        if(data.isQuestionPro){
            let idEncuesta = data.encuesta;
            let uo = data.campus == "H" || data.campus == "V" ? "VIRTUAL" : data.campus == "I" ? "IESALUD" : "UERRE";
            let region = uo == "VIRTUAL" || uo == "IESALUD" ? "NORTE" : "NORESTE";
            let id = props.user.student_id;
            let periodo = data.periodo;
            let campus = data.campus;
            let crn = data.crn;
            let programa = data.programa;
            let CUSTOM15 = [data.pidm, idEncuesta, periodo, crn, campus, programa].join(",");
            let body = "https://www.questionpro.com/a/TakeSurvey?tt=";
            let body2 = idEncuesta + "&CUSTOM15="+ CUSTOM15 + "&uo=" + uo + "&region=" + region + "&id=" + id + "&periodo=" + periodo + "&campus=" + campus; 
            response = body+body2;
            
        }else if((data.isMedallia)&&(data.url)){
            response = data.url;
            //log("MEDALLIA: " +  JSON.stringify(data.url, null, 4));
        }
        return response;
    }

        return (
            <>
                <LoaderContent loading={loading}>
                    { ((surveys != null) && (surveys.edges)) ?
                    <Box style={{ display: "flex", width: "100%","flexDirection": "row", "flexWrap":"wrap"}}>
                        { 
                            surveys.edges.length > 0 ?
                            surveys.edges.map((c, index) => (
                                <SurveyCard key={index} urlSurvey={getURL(c.node)} descripcion={c.node.descripcion} materia={c.node.materia} periodo={c.node.periodo}/>
                            )):<NothingFound caption={"No se encontraron encuestas"} subcaption={"Intenta consultar más tarde."}/>
                        }
                    </Box>
                    : !loading ? <NothingFound caption={"No se encontraron encuestas"} subcaption={"Intenta consultar más tarde."}/> : ""
                    }
                </LoaderContent>
            </>
        );
};


const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(Available);
