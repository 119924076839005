import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';

// components
import PaymentStatus from './components/PaymentStatus';
import PaymentInstructions from './components/PaymentInstructions';
import ProductDetail from './components/ProductDetail';

// utils
import { ORDER_STATUS } from '../../../../shared/constants/orders';

const PaymentConfirmationHeader = ({ order, product, membership }) => {
    return <Box>
        <Box mb="32px"><PaymentStatus {...order} /></Box>
        <Box mb="64px">
            {order.estatus_id === ORDER_STATUS.PENDING && <PaymentInstructions order={order} product={product} />}
            {order.estatus_id === ORDER_STATUS.SUCCESS && <ProductDetail order={order} product={product} membership={membership} />}
        </Box>
    </Box>;
}

export default PaymentConfirmationHeader;