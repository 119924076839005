import { AllysService, CourseService, MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../shared/components/Mixpanel/mixpanel';
import { getSessionCookie } from '../../utils/Sessions';
import { log } from '../../../shared/utils/console.js'

const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
var labelObject = "";

async function getConnectionAllies(props) {
    const session = getSessionCookie();
    await AllysServiceSDK.getAlliesList().then(response => {
        var array = Object.keys(JSON.stringify(response)).map(function (key) {
            return response[key];
        })
            .filter(element => {
                return element !== undefined;
            });

        if (props.person_id !== null) {
            labelObject = array.find(e => e.source_id === props.source_id);
        }
        else {
            labelObject = array.find(e => e.source_id === props.source_id);
            urlRespuesta = labelObject.allyRedirect;
        }
    });

    const role = JSON.parse(localStorage.getItem('permissions'));
    const eventdata = {
        userId: session?.person_id,
        email: session?.email,
        unidadOperativa: session?.ou,
        gender: session?.gender,
        courseId: props.course_id,
        courseName: props.course_name,
        source: labelObject.AllyName == 'NEO' || labelObject.AllyName == 'Banner' ? props.company_id : labelObject.AllyName,
        userRole: (role != null && role?.length > 0) ? JSON.stringify(role[0]?.role_name).replace(/["']/g, "") : 'Estudiante',
    }
    
    var urlRespuesta = "";
    const body = {
        person_id: props.person_id,
        ally: labelObject.AllyName,
        email: props.email,
        ou: props.ou,
        source_id: props.source_id,
        company_id: props.company_id !== null ? props.company_id : '',
        course_id: props.course_id
    };

    if (JSON.stringify(props.person_id).length === 0 || JSON.stringify(props.email).length === 0 || JSON.stringify(labelObject?.AllyName).length === 0) {
        return null;
    }
    else {
        await AllysServiceSDK.allysConnection(body)
            .then(data => {
                if (labelObject?.AllySSO === 1) {
                    if (data.neocredentials) {
                        urlRespuesta = data.neocredentials.neoLink;
                    }
                    else {
                        if (data.sso_course_url !== null && typeof data.sso_course_url !== 'undefined' && data.sso_course_url !== '') {
                            urlRespuesta = data.sso_course_url;
                        }
                        else {
                            urlRespuesta = props.course_url
                        }
                    }
                }
                else {
                    if (props.course_url !== "" && typeof props.course_url !== 'undefined' && data.sso_course_url !== '') {
                        urlRespuesta = props.course_url;
                    }
                    else {
                        urlRespuesta = data.sso_course_url;
                    }
                }

                testActivityDate(props.person_id, props.course_id);
                return (urlRespuesta);
            })
            .catch(e => {
                log("Error a conectar a aliados: " + e);
            });
    }

    return Promise.resolve(urlRespuesta);
}

const testActivityDate = (person_id, course_id) => {
    const last_activity_date_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
    courseService.setActivityCourseByersonId(person_id, course_id, last_activity_date_at);
}

export default getConnectionAllies;
