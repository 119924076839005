import React from 'react'

// @material-ui/core
import { makeStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        paddingInlineStart: '24px',
        [theme.breakpoints.down('xs')]:{
            paddingInlineStart: '16px',
        }
    },
    listItem: {
        color: theme.palette.colors.grey[900]
    }
}));

const SummaryList = ({ title, bulletList = [] }) => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <Grid item xs={12} className="mt-3">
            <Typography variant='h5'>
                {title}
            </Typography>
            <ul className={classes.list}>
                {
                    (bulletList.length > 0) &&
                    bulletList.map((listItem, index) =>
                        <li key={index} className={classes.listItem}>
                            <Typography variant='body2' style={{ color: theme.palette.colors.gray[400], lineHeight: '26px' }}>
                                {listItem}
                            </Typography>
                        </li>
                    )
                }
            </ul>
        </Grid>
    )
};

export default SummaryList;