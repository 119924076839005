import React from 'react';

// @material-ui/core
import Grid from '@material-ui/core/Grid';

// shared/components
import NumberProgressCard from '../../../../../shared/components/CourseContent/NumberProgressCard';
import Topics from '../../../../../shared/components/CourseContent/Topics';

const  CourseProgress = (props) => {
    const {data} = props;

    return (
        <Grid container>
            {/*<Grid item container xs={12} spacing={3}>
                <Grid item xs={5}>
                    <NumberProgressCard textPrimary="Lecciones completadas" textSecondary="4/15" lastUpdate="2 min"/>
                </Grid>
                <Grid item xs={5}>
                    <NumberProgressCard textPrimary="Calificación del curso" textSecondary="--/15" lastUpdate="2 min"/>
                </Grid>
            </Grid>*/}
            <Grid item xs={12}>
                <Topics data={data} />
            </Grid>
        </Grid>
    );
};

export default  CourseProgress;