import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { columns } from './ColumnsRoleTable'
//Shared
import Table from "../../../../shared/components/Table/Table";
import ConfirmDialog from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import Toast from '../../../../shared/components/Toast/Toast';
import { log } from '../../../../shared/utils/console.js'
// Material UI
import {
   Typography,
   ListItemIcon,
   MenuItem,
   MenuList,
   Card,
   CardContent,
   Button,
   Box
} from '@material-ui/core';
import {
   Delete as DeleteIcon,
   Add as AddIcon
} from '@material-ui/icons';

//SDK
import { UserPermXService } from '@sdk-point/talisis';

const UserPermXServiceSDK = new UserPermXService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RoleTable = (props) => {
   const { user, canReadWrite } = props;
   const history = useHistory();
   const [roles, setRoles] = useState([]);
   const [role, setRole] = useState([]);
   const [toast, setToast] = useState({ toastProps: { message: '', open: false } })
   const [openModal, setOpenModal] = useState(false);

   useEffect(() => {
      getRolesByUserId();
      return () => { }
   }, [])

   const getRolesByUserId = async () => {
      try {
         let responseRoleByUser = (user) ? await UserPermXServiceSDK.getUserPermByRecurso(`person-role/${user}`) : [];
         responseRoleByUser.map((it, index) => {
            const handleDeleteClick = (e) => {
               e.stopPropagation();
               setOpenModal(true);
               setRole(it);
            };
            it.id = it.role_id;
            it.actions = <MenuList id="menu-list-grow" role="" key={index}>
               <MenuItem onClick={handleDeleteClick} >
                  <ListItemIcon ><DeleteIcon fontSize="small" /></ListItemIcon>
               </MenuItem>
            </MenuList>;
            return (it)
         })
         setRoles(responseRoleByUser);
      }
      catch (error) {
         log('Error get user roles', error)
      }
   }

   const handleFinishedToast = () => {
      setToast({ toastProps: { open: false, message: '' } })
   }

   const handleComfirModal = async (e) => {
      if (e) {
         try {
            const response = await UserPermXServiceSDK.postUserPerm(role, 'role-person-del')
            log('Response delete', response);
            const indexRemoveRol = roles.findIndex(it => it.id === role.id)
            roles.splice(indexRemoveRol, 1);
            setToast({ toastProps: { open: true, message: `Se elimino satisfactoriamente` } })
         }
         catch (error) {
            setToast({ toastProps: { severity: "error", open: true, message: "Ops! Ha ocurrido un error al intentar eliminar el Rol" } })
         }
      }
   }

   const handleOnCloseModal = () => {
      setOpenModal(false);
   }
   const COLUMNS_TABLE = (canReadWrite) ? columns : columns.filter(col => col.name !== 'actions');
   return (
      <>
         <Typography variant="subtitle2" className="mb-4">Roles Asignados {canReadWrite && <Button color="primary" startIcon={<AddIcon />} onClick={(e) => { history.push(`/usuarios/${user}/roles`) }} style={{ position: 'absolute', marginTop: '-7px' }}>{(roles.length) ? 'Nuevo Rol' : ' Asignar Rol'}</Button>}</Typography>
         <Table
            maxHeight='calc(1000vh - 300px)'
            columns={COLUMNS_TABLE}
            rows={roles}
         />
         <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={3000} />
         <ConfirmDialog open={openModal} onConfirm={handleComfirModal} onClose={handleOnCloseModal} title='Confirmar' text='¿Está seguro que desea eliminar el registro?' />
      </>
   )
}

export default RoleTable;