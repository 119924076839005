import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { generatePath } from "react-router";

// material components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import * as paths from '../../../../../../routes/paths'
import { eventTracker } from '../../../../../../shared/components/EventTracker/EventTracker';

import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme) => ({
    listItem:{
        padding: '4px 8px',
        marginBottom: 6,
        borderRadius: 8,
        // transition: 'none !important',
        backgroundColor: 'transparent !important',
        '&:hover': {
            backgroundColor: 'transparent !important',
            '& .MuiListItemText-root':{
                color: theme.palette.colors.gray[500],
                textDecoration: 'underline',
                width: 'fit-content'
            }
        },
    }
}));

const ofertaList = [
    {label: 'Bachillerato', goTo: `${paths.BUSQUEDA}?bachillerato=true` },
    {label: 'Licenciaturas', goTo: `${paths.BUSQUEDA}?licenciatura=true`},
    {label: 'Maestrías', goTo: `${paths.BUSQUEDA}?maestria=true`},
    {label: 'Inglés profesional', goTo: generatePath(paths.ESCUELAS_OUS, {brand: 'harmonhall'})},
    {label: 'Educación continua', goTo: `${paths.BUSQUEDA}?educon=true&bachillerato=true&licenciatura=true&maestria=true`},
];

const coursesList = [
    {label: 'Nuestros aliados', goTo: `${paths.BUSQUEDA}?aliados=true`},
    // {label: 'Competencias', goTo: `${paths.BUSQUEDA}?aliados=true`},
]

export default function EduOfferList(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const reloadRedirect = (event, path) => {
        event.preventDefault();
        const BAL = 'bachillerato';
        const LIC = 'licenciatura';
        const MAS = 'maestria';
        const eventData = {};
        eventData['source'] = 'menu_explorar';
        eventData['texto'] = 'si'
        let ofertaEducativa = (path.includes(BAL)) ? BAL: path.includes(LIC) ? LIC :path.includes(MAS) ? MAS: 'curso';
        eventData['ofertaEducativa'] = ofertaEducativa;
        eventTracker('busqueda', eventData, ['mixpanel']);
        const gotToPathname = path.split("?")[0];
        const currentPath = location.pathname;
    
        if(currentPath === gotToPathname){
            history.push(path);
            window.location.reload();
        }else{
            history.push(path)
        }
    }

    return (
        <Box {...props}>
            <Box mb="32px">
                <Typography variant="h4" className="mb-2">Oferta educativa</Typography>
                <List component="nav" aria-label="main mailbox folders" dense={true}>
                    {
                        ofertaList.map((it) =>
                            <Button className={classes.listItem} onClick={(event) => reloadRedirect(event, it.goTo)} key={uuid()} component={ListItem} disableRipple disableFocusRipple>
                                <ListItemText primary={
                                    <Typography variant="subtitle2" className="text-gray-500">
                                        {it.label}
                                    </Typography>
                                }/>
                            </Button>
                        )
                    }
                </List>
            </Box>
            <Box>
                <Typography variant="h4" className="mb-2">Cursos</Typography>
                <List component="nav" aria-label="main mailbox folders" dense={true}>
                    {
                        coursesList.map((it) =>
                            <Button className={classes.listItem} onClick={(event) => reloadRedirect(event, it.goTo)} key={uuid()} component={ListItem} disableRipple disableFocusRipple>
                                <ListItemText primary={
                                    <Typography variant="subtitle2" className="text-gray-500">
                                        {it.label}
                                    </Typography>
                                }/>
                            </Button>
                        )
                    }
                </List>
                <Button
                    color="primary" 
                    endIcon={<Icon className="ri-arrow-right-s-line" />}
                    className="px-1"
                    onClick={(event) => reloadRedirect(event, `${paths.BUSQUEDA}?explorar=true`)}
                >
                    <Typography variant="body1" className="semi-bold" color="inherit">
                        Ver todos los cursos
                    </Typography>
                </Button>
            </Box>
            
        </Box>
    );
}