import React, { useEffect, useState } from 'react'

import { Box, Collapse, Grid, Icon, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import getMembershipIcon from '../../../../../shared/components/Modalupselling/MembershipIconSelector';
import { getMembershipDetails } from '../../OrderDetails/helpers/getMembershipDetails';

const MembershipBenefitsCheckout = ({ order, isCollapsable = false, productWithMembership = false }) => {

    const [productDetails, setProductDetails] = useState({
        membershipName: "",
        membershipBenefits: []
    });
    const { membershipName, membershipBenefits } = productDetails;

    const [openMembershipBenefits, setOpenMembershipBenefits] = useState(false)
    const handleClick = () => setOpenMembershipBenefits(!openMembershipBenefits);

    useEffect(() => {
        const fetchMembershipDetails = async() => {
            const conceptoMembresia = await order.conceptos.filter( concepto => concepto.membership_fee_id );  // TODO: Recibir tipo del back
            const respDetails = await getMembershipDetails(conceptoMembresia, "page");
                  
            setProductDetails({
                membershipName: conceptoMembresia?.[0]?.name,
                membershipBenefits: respDetails
            });
        }
        fetchMembershipDetails()
    }, [order])

    return (
        <>
        {
            isCollapsable &&
            <Typography onClick={handleClick} variant='body2' className='mt-3 semi-bold text-orange-300' style={{ cursor: 'pointer'}}>
                Ver detalle de producto
                {
                    openMembershipBenefits
                    ? <Icon className="ri-arrow-up-s-line ml-1 font-size-16" style={{ verticalAlign: 'middle', display: 'inline-flex'}} />
                    : <Icon className="ri-arrow-down-s-line ml-1 font-size-16" style={{ verticalAlign: 'middle', display: 'inline-flex'}} />
                }
            </Typography>
        }
            <Collapse in={isCollapsable ? openMembershipBenefits : true} timeout="auto" unmountOnExit className={`${!productWithMembership && "mt-3"}`}>
                <Grid item xs={12}>
                    {
                        productWithMembership &&
                        <>
                            <Box sx={{ paddingY: '24px' }}>
                                <Typography variant='caption' >Al suscribirte a este programa obtén:</Typography>
                            </Box>
                            <Box sx={{ textAlign: 'left', paddingBottom: '16px' }}>
                                <Grid container>
                                    <Grid item xs="auto" className='mr-2'>
                                        {getMembershipIcon(6, 20)}
                                    </Grid>
                                    <Grid item xs>
                                        {/* <Typography className="font-variant-v2"> { membershipName } </Typography> */}
                                        <Typography variant='h4'> { membershipName } </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    }
                    {
                        membershipBenefits.map((value, index) => {
                            return (
                                <Box key={index} sx={{ textAlign: 'left', paddingBottom: '8px' }}>
                                    <Grid container>
                                        <Grid item xs="auto">
                                            <Icon className="ri-check-line text-gray-400 font-size-16 mr-1" />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography
                                                variant='body2'
                                                className="variant-xsmall text-gray-400"
                                                // style={{ paddingLeft: '0px' }}
                                                dangerouslySetInnerHTML={{ __html: value }} 
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            );
                        })
                    }
                </Grid>
            </Collapse>
        </>
    )
}

export default MembershipBenefitsCheckout
