import React from 'react';

// @material-ui/core
import { makeStyles, } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// core
import CustomizedAccordions from './CustomizedAccordion/CustomizedAccordion';
import CustomizedLessons from './CustomizedAccordion/CustomizedLessonBlock';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '16px',
        marginBottom: '32px',
    },
    expandLink: {
        cursor: 'pointer'
    }
}));

const Topics = ({ data }) => {
    const classes = useStyles();
    const [expandAll,setExpandAll]=React.useState(false);

    const toggleExpandAll=({event})=>{
        setExpandAll(!expandAll);
    };

    if (!(data && data.length > 0)) {
        return null;
    }

    return (
        <div className="mb-4">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Contenido del curso</Typography>
                </Grid>
                <Grid item>
                    <Typography color='secondary'>{data[0]?.section_type_id == 1 ? data?.length + " Módulos" :  data?.length + " Lecciones" } </Typography>
                </Grid>
                <Grid item style={{ marginLeft: "auto" }}>
                    <Typography color='primary' onClick={toggleExpandAll} className={classes.expandLink}>{data[0]?.section_type_id == 1 ? expandAll===true?"Ocultar todo":"Expandir todo" : ""}</Typography>
                </Grid>
            </Grid>
            { data[0].section_type_id == 1 ? 
                data.map((el) => (
                    <CustomizedAccordions
                        key={`topic-${el?.id}`}
                        id={`topic-${el?.id}`}
                        title={el?.description}
                        topics={el?.topics}
                        allExpanded={expandAll}
                    >
                        {
                            el?.topics?.length > 0 ? (
                                <List dense>
                                    {
                                        el?.topics.map(t => (
                                            <ListItem key={t?.id} alignItems="flex-start">
                                                <ListItemText primary={
                                                    <Typography variant="h6">{t?.description}</Typography>
                                                }></ListItemText>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            ) : null
                        }
                    </CustomizedAccordions>
                ))
            :    
                data.map((el) => (
                    <CustomizedLessons 
                        id={`topic-${el?.id}`}
                        title={el?.name}>

                    </CustomizedLessons>
                ))
            }
        </div>
    );
};

export default Topics;