import React from 'react'
import clsx from 'clsx'

// @material-ui
// import Box, Button, CircularProgress, Grid, Modal, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// assets
import useStyles from '../../../../../../../assets/styles/jss/pages/checkout/checkout';

const SignupProgressModal = ({ openModal, onModalClose /*, onModalProceed, title, primaryText, secondaryText, loading */ }) => {
    const classes = useStyles();

    return (
        <Modal
            open={openModal} onClose={() => onModalClose()}
            className={classes.checkoutModal}
            aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description"
        >
            <Box className={clsx(classes.checkoutModalContent, classes.checkoutModalInside)}>
                <Grid container direction='column'>
                    <Grid item xs>
                        <Typography variant="body1">Verificando tus datos</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body1">No cierres la ventana...</Typography>
                    </Grid>
                    <Grid item xs>
                        <Box className='py-2'>
                            <CircularProgress />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default SignupProgressModal;