import React from 'react';
import { Button, Grid, TextField } from '@material-ui/core';

const UserEmails = (props) => {
    const { data: email, index, classes, onClick } = props;

    const handleEmailChange = (newValue) => {
        email.email_address = newValue;
    };

    return (
        <React.Fragment key={`rf_email_${email.id}`}>
            <Grid item xs={3}>
                <TextField
                    label="Correo electrónico"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    defaultValue={email.email_address}
                    onChange={e => handleEmailChange(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    label="Código"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={email.email_code}
                    fullWidth
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="¿Principal?"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={email.is_main ? 'Sí' : 'No'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="¿Activo?"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={email.is_active ? 'Sí' : 'No'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    label="¿Utilizado para login?"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={email.is_used_for_login ? 'Sí' : 'No'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={3} className={classes.displayInlineFlex}>
                <Button variant="contained" color="primary" size="medium" className="mt-1" onClick={() => {
                    onClick(index, email.person_id, email.id, email.email_address);
                }}>
                    ¿Establecer como correo principal?
                </Button>
            </Grid>
        </React.Fragment>
    )
};

export default UserEmails;