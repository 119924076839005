import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core';

// core
import DatetimeType from './DatetimeType/DatetimeType';
import OptionType from './OptionType/OptionType';
import TextType from './TextType/TextType';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        fontSize: "16px",
        backgroundColor: "#1976d2",
        color: "#ffffff",
        marginRight: theme.spacing(1)
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontWeight: "bold"
    },
    description: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1),
        fontSize: "14px",
        fontStyle: 'italic'
    },
    empty: {
        width: '100%',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3)
    }
}));

const RegisterQuestion = (props) => {
    const classes = useStyles();
    const { data, index } = props;
    const required = data.is_required ? "*" : "";
    const questionTypes = {
        "Text": <TextType {...props} />,
        "Datetime": <DatetimeType {...props} />,
        "Option": <OptionType {...props} />
    };

    React.useEffect(() => {
        const _init = async () => {
            if (props.tasks) {
                if (props.tasks[data.name]) {
                    await props.tasks[data.name].load(data);
                }
            }
        };
        _init();
    }, []);

    return <Fade in>
        <div>
            <Box display="flex">
                <Avatar className={classes.avatar} variant="circular">{(index + 1)}</Avatar>
                <Box display="flex" flexDirection="column">
                    {data.title ? <Typography variant="h4" className={classes.title}>{data.title} {required}</Typography> : null}
                    {data.description ? <Typography variant="caption" className={classes.description}>{data.description}</Typography> : null}
                </Box>
            </Box>
            {!(data.options && data.options.length > 0) ?
                <Box className={classes.empty}>
                    {props.loading ?
                        <Typography variant="subtitle1" align="center" className="p-3">Cargando la información...</Typography> :
                        <Typography variant="subtitle1" align="center" className="p-3">No hay ningún elemento para mostrar.</Typography>
                    }
                </Box> :
                <Box className="mt-3 mb-3">{questionTypes[(data.type_name)]}</Box>
            }
        </div>
    </Fade>
}

export default RegisterQuestion;