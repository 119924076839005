export default (seconds) => {
    var unit = "semana";
    var duration = (seconds / 604800);
    //
    if (duration < 1) {
        unit = "día"
        duration = (seconds / 86400);
        if (duration < 1) {
            unit = "hora"
            duration = (seconds / 3600);
            if (duration < 1) {
                unit = "minuto"
                duration = (seconds / 60);
            }
        }
    }
    //
    if (Math.round(duration) > 1) {
        unit = unit + "s";
    }
    //
    return `${Math.round(duration)} ${unit}`;
};