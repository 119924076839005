import { makeStyles } from '@material-ui/core/styles';

const backgroundImageDefault = 'banner-temp';
const backgroundImageName = 'banner-temp';

const heightResponsiveDefault = 734;
const paddingTopResponsiveDefault = 297;
const maxWidthResponsiveDefault = '328px';

export default makeStyles((theme) => ({
    responsiveContainer:{
        position: 'relative',
        height: '100%',
        '& .container':{
            height: '100%',
            paddingTop: 66
        }
    },
    root:{
        position: 'relative',
        height: 629,
    },
    content:{
        marginBottom:69,
        textAlign: 'left',
        padding: '0px 32px',
        gridGap: 24
    },
    title:{
        width: 570,
        height: 285,
        marginBottom: 16
    },
    gif:{
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/images/home/hot-sale.gif)',
        backgroundSize: 'cover',
    },
    subtitle:{

    },
    bg:{
        background: 'radial-gradient(135.10% 169.11% at 49.76% 38.34%, rgba(32, 7, 105, 0.72) 10.20%, rgba(27, 14, 63, 0.78) 28.76%, #38149B 44.49%, #030344 56.52%, #6441C3 64.99%);mix-blend-mode: soft-light;',
        height: '656.299px',
        position: 'absolute',
        width: '100%',
        right: 0,
        zIndex: 1,
    },
    bgTop:{
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: theme.palette.colors.fillStroke[300],
        left: 0
    },
    bgImage:{
        backgroundImage: `url(/images/home/${backgroundImageDefault}.png)`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        position: 'absolute',
    },
    bgImageVideo:{
        backgroundImage: `url(/videos/banner.jpg)`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        position: 'absolute',
    },
    bgVideo:{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute', 
    },
    actions:{
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    videoContent:{
        display: 'flex',
        height:'100%',
        justifyContent:'end',
        flexDirection:'column',
        paddingBottom: 24,
        textAlign: 'start',
        gridGap: 24
    },
    [theme.breakpoints.only('sm')]:{
        root:{
            height: 351,
        },
        title:{
            width: '54%',
            height: 141,
            marginBottom: 8
        },
        gif:{
            width: '100%',
            height: '100%',
            backgroundImage: 'url(/images/home/hot-sale-xs.gif)',
            backgroundSize: 'cover',
        },
        content:{
            padding: '0px 0px 0px 18px',
            gridGap: 8,
            '& h2':{
                fontSize: 24,
                lineHeight: '26px'
            }
        },
        bgImage:{
            backgroundImage: `url(/images/home/${backgroundImageDefault}-sm.png)`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            position: 'absolute',
        },
        responsiveContainer:{
            '& .container':{
                paddingTop: 24,
                maxWidth: 700
                // paddingLeft: '4px !important'
            }
        },
        subtitle:{
            fontSize: 16,
            '&:nth-child(3)': {
                marginTop: '-16px'
            }
        },
        videoContent:{
            flexDirection: 'row',
            alignItems:'end',
            gridGap: 32,
            justifyContent: 'center',
            '& > :nth-child(1)': {
                order: 2,
            },
            '& h3':{
                fontSize: 20
            },
            '& h2':{
                fontSize: 24
            }
        },
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            height: heightResponsiveDefault,
            '& .title':{
                maxWidth: `${maxWidthResponsiveDefault} !important`,
                margin: '0 auto',
                // default
                marginBottom: 25,
                marginBottom: 8,
                '& h1':{
                //default
                    fontWeight: '700 !important',
                    fontSize: '24px !important',
                    lineHeight: '26px !important',
                    textAlign: 'center'
                }
            },
        },
        title:{
            width: '124%',
            height: 163,
            marginBottom: 8,
            maxWidth: 440
        },
        gif:{
            width: '100%',
            height: '100%',
            backgroundImage: 'url(/images/home/hot-sale-xs.gif)',
            backgroundSize: 'cover',
        },
        content:{
            padding: '0px 0px 0px 18px'
        },
        contentSubtitles:{
            maxWidth: 304
        },
        responsiveContainer:{
            '& .container':{
                paddingTop: 183,
                paddingLeft: '4px !important'
            }
        },
        bgImage:{
            backgroundImage: `url(/images/home/${backgroundImageDefault}-xs.png)`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            position: 'absolute',
        },
        bgImageVideo:{
            backgroundImage: `url(/videos/banner-xs.jpg)`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            position: 'absolute',
        },
        actions:{
            flexDirection: 'column',
            '& .MuiButton-root':{
                width: '100%'
            }
        },
        videoContent:{
            padding: '0px 22px 16px',
            alignItems:'center',
            textAlign: 'center',
            gridGap: 19,
            '& > :nth-child(1)': {
                order: 2,
            },
            '& h3':{
                fontSize: 24,
                lineHeight: '26px'
            },
            '& h2':{
                fontSize: 32,
                lineHeight: '34px'
            }
        },
    }
}));