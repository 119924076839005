import React from 'react';
import CurrencyFormat from 'react-currency-format';
import clsx from 'clsx';

// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const OrderDiscounts = ({ orderDiscounts }) => {
    return <Grid container justifyContent="space-between" alignItems='center' className='mt-1'>
        <Grid item>
            <Typography variant="body1">Promoción aplicada:</Typography>
        </Grid>
        {/*<Grid item style={{ flexGrow: 1, textAlign: 'right', marginRight: '16px' }} >
            <Typography variant='caption' className='text-success-300'>{orderDiscounts[0].description}</Typography>
        </Grid>*/}
        <Grid item>
            <Typography className={clsx("variant-subheading-2", "text-dark-black-100", "font-weight-500")}>
                <CurrencyFormat value={orderDiscounts[0].discount} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="-$" suffix="MXN" />
            </Typography>
        </Grid>
    </Grid>;
};

export default OrderDiscounts;