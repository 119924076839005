import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';

// @material-ui/icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// shared - components
import Tabs from '../../../shared/components/Tabs/Tabs'
import Dropdown from '../../../shared/components/Dropdown/Dropdown'
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';

// - services
import * as userService from '../../../services/UserService';

// core components
import AllRequests from './AllRequests/AllRequests';
import Table from '../../../shared/components/Table/Table'

// @redux
import { connect } from "react-redux"
import * as requestWorkflowActions from '../../../redux/actions/requestWorkflowActions'
import * as routerlinks from '../../../routes/routelinks'
import Add from '@material-ui/icons/Add';


function NewRequestButton({props}){
    const requestType = [
        {id: 1, label: 'Para Alumno', icon:''},
        {id: 2, label: 'Para Colaborador', icon:''},
    ]

    const onClickOption = (status) => {
        const {id, label} = status;

        localStorage.setItem("newRequestType", id);
        props.setRequest({
            workflow:[], 
            form:{
                createdAt:new Date(),
                title: '',
                ou: [],
                responsable: '',
                type: id,
                duration: 0,
                description: ''
            },
        })
        props.history.push(routerlinks.GESTION_SERVICIOS_CREAR);
    }

    return (
        <Box display="flex" alignItems="center" marginLeft="auto">
            {/* <Button color="primary" startIcon={<AddIcon />} onClick={onClick}>Nuevo Trámite</Button> */}
            <Dropdown 
                options={requestType} 
                onClickOption={onClickOption}
                text="Nuevo Servicio"
                endIcon={<Add />}
                color='primary'
                variant='contained'
            />
        </Box>
    )
}

const RequestManagment = (props) => {

    const [state, setState] = React.useState({
        loading: false,
        activeTab: 0,
        canReadWrite: null,
    });

    const tabs = ['Todos'];

    React.useEffect(()=> {
        getPermission(props.pageId)
    }, [props])

    const getPermission = (pageId) => {
        const canReadWrite = userService.canReadWrite(pageId);
        setState({...state, canReadWrite});
    }

    const handleChangeTab = tab => setState({activeTab: tab});

    return (
        <Box>
            <Breadcrumb items={[
                {label: 'Trámites'},
                {label: 'Gestión de servicios'},
            ]} />
            <Tabs
                onChange={handleChangeTab}
                activeTab={state.activeTab}
                tabs={tabs}
                //actions={<NewRequestButton onClick={hanldeClickNewRequest}/>}
                actions={ state.canReadWrite && <NewRequestButton props={props}/>}
            >
                <AllRequests {...props} canReadWrite={state.canReadWrite}/>
            </Tabs>
        </Box>
    );
};


export default connect(null, {...requestWorkflowActions})(RequestManagment);