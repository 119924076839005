import React from 'react';
import moment from 'moment';

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ArrowDropDown, ChevronLeft, ChevronRight, Group, Lock } from '@material-ui/icons';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const FORM_NAME = 'about_me';

export const Principal = ({ data, nationalityCat, xData, onSetForm, onChange }) => {
    const [editName, setEditName] = React.useState(false);
    const theme = useTheme();
    const useStyles = makeStyles({
        grid: {
            borderTop: `1.5px solid ${theme.palette.colors.grey[700]}`,
        },
        card: {
            backgroundColor: theme.palette.colors.grey[400],
        },
        titleContainer: {
            marginTop: '3px',
        },
        title: {
            color: theme.palette.colors.white,
            fontSize: '18px',
            fontWeight: 'normal',
        },
        subTitle: {
            color: theme.palette.colors.fillStroke[100],
            fontSize: '18px !important',
            fontWeight: 'bold',
            marginLeft: '15px',
            marginTop: '15px',
        },
        label: {
            color: theme.palette.colors.fillStroke[100],
            fontWeight: 600,
            fontSize: '12px',
        },
        bioText: {
            color: theme.palette.colors.white,
            background: 'none',
            fontSize: '0.75em',
        },
        inputText: {
            color: theme.palette.colors.white,
            background: 'none',
            fontSize: '12px',
        },
        inputTextEdit: {
            border: `1.5px solid ${theme.palette.colors.fillStroke[200]}`,
            borderRadius: '5px',
            color: theme.palette.colors.white,
            height: '29.5px',
            fontSize: '14px',
            padding: '0.5em',
        },
        inputDate: {
            colorScheme: 'dark',
            width: '38%',
        },
        textArea: {
            backgroundColor: theme.palette.colors.black,
            border: `1.5px solid ${theme.palette.colors.grey[600]}`,
            borderRadius: '5px',
            padding: '0.7em',
            height: '20em',
            overflow: 'auto',
        },
        select: {
            '& .MuiSvgIcon-root': {
                color: theme.palette.colors.fillStroke[100],
            },
            height: '0.2em',
        },
        iconSelect: {
            right: 0,
            transform: 'rotate(270deg)',
        },
        iconSelectOpen: {
            transform: 'rotate(0deg)',
        },
        menuItem: {
            '&:hover': {
                backgroundColor: `${theme.palette.colors.fillStroke[100]} !important`,
            },
            '&.MuiListItem-root.Mui-selected': {
                backgroundColor: `${theme.palette.colors.grey[400]} !important`,
            },
            fontFamily: "'Source Sans Pro', sans-serif !important",
            fontSize: '12px',
        },
        iconContainer: {
            textAlign: 'center',
        },
        icon: {
            color: theme.palette.colors.fillStroke[100],
        },
        circularProgress: {
            color: theme.palette.colors.white,
        },
        saveButton: {
            backgroundColor: theme.palette.colors.orange[300],
            color: theme.palette.colors.white,
        },
        alertMessage: {
            backgroundColor: theme.palette.colors.grey[500],
            color: theme.palette.colors.white,
            fontFamily: "'Source Sans Pro', sans-serif !important",
            fontSize: '14px',
        },
    });

    const classes = useStyles();
    return (
        <Grid container spacing={3} className="mt-3">
            <Grid item md={5} xs={12}>
                <TextField
                    id="about_me"
                    name="about_me"
                    minRows={3}
                    className={classes.textArea}
                    fullWidth
                    multiline
                    placeholder="Escribe algo aquí..."
                    label={
                        <Typography variant="subtitle2" className={classes.subTitle}>
                            BIO
                        </Typography>
                    }
                    value={(data.about_me && data.about_me !== 'null') ? data.about_me : ''}
                    InputProps={{
                        className: classes.bioText,
                        disableUnderline: true,
                    }}
                    inputProps={{ maxLength: 250 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => onChange(e, FORM_NAME)}
                />
            </Grid>
            <Grid item md={7} xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={4}>
                                <Grid container className='py-4'>
                                    <Grid item xs={10} >
                                        <Typography variant="subtitle2" className={classes.label}>
                                            Nombre
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container>
                                            <Tooltip title={<span>&#8856;</span>} placement="right-end">
                                                <Group fontSize='inherit' className={classes.icon} />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container onClick={() => setEditName(!editName)} className="Profile_FormControl">
                                    <Grid item xs={11}>
                                        <Grid container>
                                            {!editName 
                                                ?   <Grid item xs={12}>
                                                        <TextField id="full_name" name="full_name" fullWidth className={classes.label} value={`${data.first_name} ${data.last_name}`}
                                                            InputProps={{
                                                                className: classes.inputText,
                                                                disableUnderline: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                : <>
                                                    <Grid item xs={6}>
                                                        <TextField id="first_name" name="first_name" className={classes.label} placeholder="Nombre(s)" value={data.first_name} size="medium"
                                                            InputProps={{
                                                                className: classes.inputTextEdit,
                                                                disableUnderline: true,
                                                            }}
                                                            style={{ marginRight: '5px' }}
                                                            onClick={e => e.stopPropagation()}
                                                            onChange={e => onChange(e, FORM_NAME)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField id="last_name" name="last_name" className={classes.label} placeholder="Apellidos" value={data.last_name} size="medium"
                                                            InputProps={{
                                                                className: classes.inputTextEdit,
                                                                disableUnderline: true,
                                                            }}
                                                            onClick={e => e.stopPropagation()}
                                                            onChange={e => onChange(e, FORM_NAME)}
                                                        />
                                                    </Grid>
                                                </>}
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title={editName ? 'Volver' : 'Editar'} placement="right-end">
                                            {editName ?
                                                <ChevronLeft className={`Profile_ChevronLeft ${classes.icon}`} />
                                                :
                                                <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />}
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                        <Grid container spacing={2} alignItems='center' >
                            <Grid item xs={4}>
                                <Grid container className='py-4'>
                                    <Grid item xs={10}>
                                        <Typography variant="subtitle2" className={classes.label}>
                                            Nacionalidad
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select className={classes.select} value={data.visibility.nationality_code} disableUnderline
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                            renderValue={(value) => value === 'public' ? <Group fontSize='inherit' /> : <Lock fontSize='inherit' />}
                                            onChange={e => {
                                                data.visibility.nationality_code = e.target.value;
                                                onChange({ target: { name: 'visibility', value: data.visibility } }, FORM_NAME);
                                            }}>
                                            <MenuItem value="public" className={classes.menuItem}>Todos los usuarios</MenuItem>
                                            <MenuItem value="private" className={classes.menuItem}>Solo yo</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container onClick={() => { document.getElementById('nationality_code').focus() }} className="Profile_FormControl">
                                    <Grid item xs={12}>
                                        <Select id="nationality_code"
                                            name="nationality_code"
                                            value={(data.nationality_code && data.nationality_code !== 'undefined') ? data.nationality_code : ''}
                                            fullWidth
                                            onChange={(e) => onChange(e, FORM_NAME)}
                                            className={classes.select}
                                            disableUnderline
                                            classes={{ icon: classes.iconSelect, iconOpen: classes.iconSelectOpen }}
                                            style={{
                                                fontSize: '12px'
                                            }}>
                                            {nationalityCat.map(nationalityData => {
                                                return <MenuItem key={nationalityData.id} className={classes.menuItem} value={nationalityData.nationality_code}>{nationalityData.name}</MenuItem>
                                            })}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={4}>
                                <Grid container alignItems='center' className='py-4'>
                                    <Grid item xs={10}>
                                        <Typography variant="body2" className={classes.label}>
                                            Fecha de Nacimiento
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select className={classes.select} value={data.visibility.nationality_code} disableUnderline
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null
                                            }}
                                            renderValue={(value) => value === 'public' ? <Group fontSize='inherit' /> : <Lock fontSize='inherit' />}
                                            onChange={e => {
                                                data.visibility.nationality_code = e.target.value;
                                                onChange({ target: { name: 'visibility', value: data.visibility } }, FORM_NAME);
                                            }}>
                                            <MenuItem value="public" className={classes.menuItem}>Todos los usuarios</MenuItem>
                                            <MenuItem value="private" className={classes.menuItem}>Solo yo</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container onClick={() => { document.getElementById('birth_date').showPicker() }} className="Profile_FormControl">
                                    <Grid item xs={11}>
                                        <TextField
                                            id="birth_date"
                                            name="birth_date"
                                            type="date"
                                            value={
                                                data.birth_date && moment(data.birth_date.replace('T', ' ').replace('Z', '')).format('YYYY-MM-DD')
                                            }
                                            fullWidth
                                            InputProps={{
                                                className: `${classes.inputText} ${classes.inputDate}`,
                                                disableUnderline: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onClick={e => e.stopPropagation()}
                                            onChange={e => onChange(e, FORM_NAME)}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title="Editar" placement="right-end">
                                            <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ borderTop: `1.5px solid ${theme.palette.colors.grey[700]}` }}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={4}>
                                <Grid container alignItems='center' className='py-4'>
                                    <Grid item xs={10}>
                                        <Typography variant="body2" className={classes.label}>
                                            Dirección
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {xData && xData.visibility ?
                                            <Select className={classes.select} value={xData.visibility} disableUnderline
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                                renderValue={(value) => value === 'public' ? <Group fontSize='inherit' /> : <Lock fontSize='inherit' />}
                                                onClick={() => onSetForm('address')}
                                                disabled>
                                                <MenuItem value="public" className={classes.menuItem}>Todos los usuarios</MenuItem>
                                                <MenuItem value="private" className={classes.menuItem}>Solo yo</MenuItem>
                                            </Select>
                                            :
                                            <div className="Profile_FormControl" onClick={() => onSetForm('address')}>
                                                <Lock fontSize='inherit' />
                                                <ArrowDropDown className={classes.icon} />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container onClick={() => onSetForm('address')} className="Profile_FormControl">
                                    <Grid item xs={11}>
                                        <TextField
                                            id="full_address"
                                            name="full_address"
                                            value={(xData && xData.full_address) || ''}
                                            fullWidth
                                            InputProps={{
                                                className: classes.inputText,
                                                disableUnderline: true,
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title="Editar" placement="right-end">
                                            <ChevronRight className={`Profile_ChevronLeft ${classes.icon}`} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                data.toastProps.ok &&
                <Alert id="am_alert_message" variant="outlined" severity={data.toastProps.ok === 'true' ? 'success' : 'error'} className={`Profile_AlertMessage ${classes.alertMessage}`}>
                    {data.toastProps.message}.
                </Alert>
            }
        </Grid>
    )
};
