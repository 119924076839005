import React from 'react';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import Box from '@material-ui/core/Box';

// shared core components
import Tabs from '../../../shared/components/Tabs/Tabs'
import Loader from '../../../shared/components/Loader/LoaderContent';
import Toast from '../../../shared/components/Toast/Toast';
// import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';

// components
import AllCourses from './AllCourses/AllCourses';
import OngoingCourses from './OngoingCourses/OngoingCourses';
import PurchasedCourses from './PurchasedCourses/PurchasedCourses';

// services
// import * as marketService from '../../../services/MarketPlaceService';
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';
import CourseCarousel from '../../../shared/components/CourseCarousel/CourseCarousel';

// const DEFAULT_PAGE_SIZE = 9;

const MyCourses = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(0);
    const [toastProps, setToastProps] = React.useState({ message: "" });
    const [neoCourses, setNeoCourses] = React.useState([]);
    const [thirdPartyCourses, setThirdPartyCourses] = React.useState([]);
    // const [thirdPartyOferta, setThirdPartyOferta] = React.useState([]);
    const [userIdNeo, setUserIdNeo] = React.useState(0);
    const { user } = props;
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await marketService.getUserCourses(user);
                setNeoCourses(data.neo_courses.classes.filter(it => !it.archived));
                setThirdPartyCourses(data.third_party_courses.filter(it => !it.end_date));
                setUserIdNeo(data.neo_courses.user_id);
            } catch (error) {
                setToastProps({ severity: "error", open: true, message: "Ha ocurrido un error al consultar la información" })
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    const handleChangeTab = (id) => {
        setActiveTab(id);
    };

    const handleFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    };

    const tabsData = [
        {
            tab: 'Todos los cursos',
            content: <AllCourses
                neoCourses={neoCourses}
                thirdPartyCourses={thirdPartyCourses}
                userIdNeo={userIdNeo}
                user={user} />
        },
        {
            tab: 'Cursos en progreso',
            content: <OngoingCourses
                neoCourses={neoCourses}
                thirdPartyCourses={thirdPartyCourses}
                userIdNeo={userIdNeo}
                user={user} />
        },
        {
            tab: 'Cursos comprados',
            content: <PurchasedCourses
                neoCourses={neoCourses}
                thirdPartyCourses={thirdPartyCourses}
                userIdNeo={userIdNeo}
                user={user} />
        },
    ];

    return (
        <Box mb={5}>
            {/* <Breadcrumb items={[
                { label: 'Cursos' },
                { label: 'Mis cursos' },
            ]} /> */}
            <Tabs
                onChange={handleChangeTab}
                activeTab={activeTab}
                tabs={tabsData.map(it => it.tab)}
            >
                <Loader loading={loading} transparent={true}>
                    <Box minHeight="calc(100vh - 450px)">
                        {
                            !loading && (
                                <React.Fragment>
                                    <div>
                                        {tabsData[activeTab].content}
                                    </div>
                                    <div style={{ marginTop: '32px', marginBottom: '32px' }}>
                                        <CourseCarousel
                                            id='third-party-courses'
                                            lazyLoading
                                            title='Otros cursos que quizá te puedan interesar'
                                            disableTitleAction
                                            clickFrom={'curso'}
                                        />
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </Box>
                </Loader>
            </Tabs>
            <Toast {...toastProps} onFinished={handleFinishedToast} />
        </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(MyCourses);