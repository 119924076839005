import { API_ENDPOINT } from './endpoints'
const RESOURCE = 'user/table/entry/permission'  

export const getAll=async (page,filters, size=10) => { 
    let url = `${API_ENDPOINT}/${RESOURCE}?page=${page}&size=${size}`;
    for (let items of filters) {
        let filter = items;
        for(const key in filter){
            let keyFilter = key;
            url+=`&${key}=${filter[keyFilter]}`
        }
    }
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        },
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}

export const save = async (body, method='POST') => { 
    let url = `${API_ENDPOINT}/${RESOURCE}`; 

    const requestOptions = {
        method,
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}

export const getPermissionByRoleId = async (id) => {
    const url = `${API_ENDPOINT}/${RESOURCE}/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        },
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }
    return data;
}