import * as requestWorkflowTypes from '../types/requestWorkflowTypes'
import removeObjectFromarray from '../../shared/utils/removeObjectFromArray'
import { log } from '../../shared/utils/console.js'

export const updateRequestWorkflowForm  = (form) => (dispatch) => {
    dispatch({
        type: requestWorkflowTypes.UPDATE_FORM,
        payload: form
    });
};
export const setRequest  = (request) => (dispatch) => {
    dispatch({
        type: requestWorkflowTypes.SET_REQUEST,
        payload: request
    });
};
export const addStepToRequest  = (step) => (dispatch, getState) => {
    const {workflow} = getState().requestWorkflowReducer;
    workflow.push(step);
    dispatch({
        type: requestWorkflowTypes.UPDATE_WORKFLOW,
        payload: workflow
    });
};
export const getStep  = (id) => (dispatch, getState) => {
    const {workflow} = getState().requestWorkflowReducer;
    let step = null;

    if(workflow.length){
        step = workflow.find(it => it.id === id);
        log('step entro', id, step);
    }
    log('step', step);
    dispatch({
        type: requestWorkflowTypes.GET_STEP,
        payload: step
    });
};

export const deleteStep = (id) => (dispatch, getState) => {
    const {workflow} = getState().requestWorkflowReducer;
    removeObjectFromarray(workflow, 'id', id);

    dispatch({
        type: requestWorkflowTypes.UPDATE_WORKFLOW,
        payload: workflow
    });
};
export const updateStep = (step) => (dispatch, getState) => {
    const {workflow} = getState().requestWorkflowReducer;

    workflow[workflow.findIndex(it => it.id === step.id)] = {...step};
     log('workflow', workflow);

    dispatch({
        type: requestWorkflowTypes.UPDATE_WORKFLOW,
        payload: workflow
    });
};

export const setWorkflow = workflow => (dispatch) =>{
    log('setWorkflow', workflow)
    dispatch({
        type: requestWorkflowTypes.UPDATE_WORKFLOW,
        payload: workflow
    });
}

