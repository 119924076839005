import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    clamp:{
        '-webkit-line-clamp': props => props.line,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
    }
}));