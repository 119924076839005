import React from 'react';

// @material-ui/core
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const SectionListItem = (props) => {
    const [viewMode, setViewMode] = React.useState(true);
    const [form, setForm] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const { item, fieldName, nestedChildren, onUpdate, onDelete } = props;

    const validate = () => {
        const _errors = {};
        const requiredFields = [fieldName];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo requerido';
            }
        }

        setErrors(_errors);

        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleEdit = () => {
        setForm({ [fieldName]: item[fieldName] });
        setViewMode(false);
    };

    const handleSave = () => {
        if (validate()) {
            onUpdate({ ...item, [fieldName]: form[fieldName] });
            setViewMode(true);
        }
    };

    const handleDelete = () => {
        onDelete(item.id);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const _errors = { ...errors };

        if (value) {
            delete _errors[name];
            setErrors(_errors);
        }

        setForm(prev => {
            return { ...prev, [name]: value }
        });
    };

    return (
        <React.Fragment>
            <ListItem divider>
                {
                    nestedChildren ? (
                        <ListItemIcon style={{ minWidth: '32px', marginLeft: '-12px' }}>
                            {open ? <ExpandLess onClick={handleClick} /> : <ExpandMore onClick={handleClick} />}
                        </ListItemIcon>
                    ) : null
                }
                <ListItemText primary={
                    viewMode ?
                        <div>{item[fieldName]}</div> :
                        <div>
                            <TextField
                                name={fieldName}
                                label="Editar elemento"
                                size="small"
                                required
                                fullWidth
                                variant="outlined"
                                value={form[fieldName] || ""}
                                onChange={handleChange}
                                error={Boolean(errors[fieldName])}
                                helperText={errors[fieldName]}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton size="small" color="primary" aria-label="save" edge="end" onClick={handleSave}>
                                                <DoneIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                } />
                {viewMode ? (
                    <ListItemSecondaryAction>
                        <IconButton size="small" aria-label="edit" edge="end" onClick={handleEdit}>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small" aria-label="delete" edge="end" onClick={handleDelete}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </ListItemSecondaryAction>
                ) : null}
            </ListItem>
            <Collapse component="li" in={open} timeout="auto" unmountOnExit style={{ paddingLeft: '24px' }}>
                {nestedChildren}
            </Collapse>
        </React.Fragment>
    );
};

export default SectionListItem;