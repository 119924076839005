import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core
import Rating from '@material-ui/lab/Rating';
// styles
import useStyles from '../../../../assets/styles/jss/pages/courses/coursesInProgressStyle';
import { log } from '../../../../shared/utils/console.js'
// services
import { CourseService } from '@sdk-point/talisis';

const SetRatingStatus = (props) =>  {
    const { userId, courseId, index, status, isDetail } = props;
    const classes = useStyles({isDetail});
    const [rankValue, setRankValue] = React.useState(0);
    const [sendStatus, setSendStatus] = React.useState(false);
    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

    const setInitialStatus = async () => {
        if(status != null){
            setRankValue(status);
        }else{
            setRankValue(0);
        }
    };

    const handleNewRank = async (e, newValue) => {
        e.preventDefault();
        /*log("NEW RANK");*/
        log(newValue);
        log(userId);
        log(courseId);
        setSendStatus(true);
        setRankValue(newValue);
        const response = await courseService.setRatingCourse(userId, courseId, newValue);
        if(response != "OK"){
            setRankValue(rankValue);
        }
        setSendStatus(false);
        e.stopPropagation();
    };

    React.useEffect(() => {
        setInitialStatus();
    }, [status]);

    return (          
        <Rating
            className={classes.rating}
            disabled={sendStatus}
            name={"rating-controller-"+index+"-"+ courseId}
            value={rankValue} 
            onChange={ (e, newValue) => {
                handleNewRank(e, newValue);
            }}
            precision={0.5}/>
    );
}

SetRatingStatus.defaultProps = {
    lazyLoading: false,
    disableTitleAction: false,
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(SetRatingStatus);
