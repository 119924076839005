import * as requestWorkflowTypes from '../types/requestWorkflowTypes'

const INITIAL_STATE = {
    workflow:[
      // {id: "1", title: 'test', assignee: [{id: 1,first_name: 'Brizeth', last_name: 'Puente', full_name: 'Damaris'}], instruction: 'test', fields: [], type: "2"},
      // {id: "2", title: 'test 2', assignee: [{id: 1,first_name: 'Brizeth', last_name: 'Puente', full_name: 'Damaris'}], instruction: 'test', fields: [], type: "2"},
      // {id: "3", title: 'test 3', assignee: [{id: 1,first_name: 'Brizeth', last_name: 'Puente', full_name: 'Damaris'}], instruction: 'test', fields: [], type: "2"}
    ],
    form:{
        requestId: null,
        createdAt:new Date(),
        title: '',
        ou: [],
        responsable: '',
        type: '',
        duration: 1
    }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case requestWorkflowTypes.SET_REQUEST:
      return {
        ...action.payload
      };
    case requestWorkflowTypes.UPDATE_FORM:
      return { 
        ...state,
        form: {...action.payload}
      };
    case requestWorkflowTypes.GET_STEP:
      return {
        ...state,
        step: action.payload
      };
    case requestWorkflowTypes.UPDATE_WORKFLOW:
      return {
        ...state,
        workflow: action.payload
      };
    default:
      return state;
  }
};