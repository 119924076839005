import React from 'react';
import clsx from 'clsx';

// @material-ui
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// assets
import useStyles from '../../../../assets/styles/jss/pages/cart/cartStyle';

const ModalCloseButton = ({ className, onClose }) => {
    return <Box fullWidth textAlign='right' className={className}>
        <IconButton color="inherit" onClick={onClose} size="small">
            <Icon className='ri-close-fill text-dark-black-200 font-size-24' />
        </IconButton>
    </Box>
};

const CartProductDeleteModal = ({ open, message, onClose, onConfirm }) => {
    const classes = useStyles();

    return <Box>
        <Modal
            className={classes.modal}
            open={open}
            onClose={onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >

            <Card width='50%' className={classes.modalCard}>
                <ModalCloseButton className={classes.modalClose} onClose={onClose} />
                <CardContent width='40%' className={classes.modalCardContent}>
                    <Box>
                        <Box fullWidth className='pb-2'>
                            <Icon className={clsx("ri-error-warning-line text-warning-300", classes.modalIcon)}></Icon>
                        </Box>
                        <Box fullWidth className='pb-3'>
                            <Typography variant="h4" color="primary">¿Estás seguro que deseas eliminar este producto del carrito?</Typography>
                        </Box>
                        <Box fullWidth className='pb-4'>
                            <Typography variant="body1" className='font-weight-600'>{message}</Typography>
                        </Box>
                        <Grid container xs={12} spacing={2} className={classes.modalButtons}>
                            <Grid item xs={12} md={6}>
                                <Button variant='outlined' color="secondary" fullWidth onClick={onClose}>Cancelar</Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" color="primary" fullWidth startIcon={<Icon className="ri-delete-bin-line font-size-24" fontSize='large'></Icon>} onClick={onConfirm}>Eliminar del carrito</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>

        </Modal>
    </Box>
}

export default CartProductDeleteModal;