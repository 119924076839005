export default {
  gutters:{
    paddingLeft: 34,
    paddingRight: 34,
    // '@media(min-width: 600px)':{
    //   paddingLeft: 60,
    //   paddingRight: 60,
    // }
  }
};
