import React from "react";
import { Box, Card, Icon, Typography, makeStyles, useMediaQuery } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    notAccessCard: {
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gridGap: 24,
        padding: 24,
        margin: 31,
        width: props => props.width,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gridGap: 16,
            padding: 16,
            margin: "16px 16px 16px 0px"
        }
    },
    boxAlert:{
        display: 'flex',
        gap: 8,
        alignItems: 'center'
    }
}));

const ErrorAccess = ({ title, text, image, imgSize, classNameIcon='', width = "auto"}) => {
    const classes = useStyles({width});

    return (
        <Card className={classes.notAccessCard} elevation={2} id="error-access-root">
            <Box height={imgSize}>
                <img src={image} height={"100%"} />
            </Box>
            <Box maxWidth={548} display={"flex"} flexDirection={"column"} gridGap={8}>
                <Box className={classes.boxAlert}>
                    <Icon className={classNameIcon} />
                    <Typography className='text-warning-300 semi-bold' variant='body1'>{title}</Typography>
                </Box>
                <Typography variant='body2' className='text-gray-100'>{text}</Typography>
            </Box>
        </Card>
    );
};

export default ErrorAccess;