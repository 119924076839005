import React from 'react'
import { useSelector } from 'react-redux';

// @material-ui/core components
import { Avatar, Grid, Icon, Typography, Box, Button } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

// shared comp.
import LogoOu from '../LogoOu/LogoOu';

// component-styles
import { profileStyles } from './profileStyles';
import { navToSocial } from './helpers/navToSocial';
import UserRoleTags from '../UserRoleTags/UserRoleTags';
import clsx from 'clsx';

const ProfileDetails = (props) => {
    const user = useSelector((state) => state.userReducer.user);
    const classes = profileStyles();

    const { 
        about_me: {userName, about_me},
        contact_me: {
            email,
            facebook_profile_url,
            instagram_profile_url,
            linkedin_profile_url,
            twitter_profile_url
        },
        address: {full_address},
        userTags
    } = props;

    const TwitterXLogo = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24">
            <path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z" fill="white"></path>
          </svg>
        );
      };

  return (
    <Grid style={{textAlign: 'center'}} className="mt-3" id="profile-data-root">
        <Box mb="2px" height={22}>
            <Typography variant='subtitle2' className='text-fill-stroke-100'>¡Hola!</Typography>
        </Box>
        <Typography className='mb-2text-gray-100 offset-margin-bottom-19' variant='h4'>{user.first_name}</Typography>
        <LogoOu />
        {
            !!userTags && !!userTags.length && 
            <Grid className='mt-1'>
                <UserRoleTags tags={userTags}/>
            </Grid>
        }
        {
            user?.student_id &&
            <Grid className={`mt-3 ${classes.profileInfo}`}>
                <Typography className='variant-xsmall text-fill-stroke-100'>Matrícula: 
                &nbsp;<span className='text-gray-100 semi-bold'>{user.student_id}</span>
                </Typography>
            </Grid>
        }
        <Grid className={`mt-3 ${classes.profileInfo}`}>
            <Icon className='m-1 ri-mail-line font-size-16 text-dark-black-200' />
            <Typography variant='body2' className='text-fill-stroke-100'>
                {
                    user?.email || <span style={{color: '#78787D'}}>Email pendiente</span>
                }
            </Typography>
        </Grid>
        <Grid className={`mt-3 ${classes.profileInfo}`}>
            <Icon className='m-1 ri-map-pin-2-line font-size-16 text-dark-black-200' />
            <Typography variant='body2' style={{color: '#78787D'}}>
                {
                    full_address || <span style={{color: '#78787D'}}>Ubicación pendiente</span>
                }
            </Typography>
        </Grid>
        <Typography className='mt-4 text-fill-stroke-100' variant='h3'>BIO.</Typography>
        <Typography className='mt-6 text-gray-100' variant='caption'>
            {
                !!about_me && about_me !== "null" ? about_me : "Cuéntanos sobre ti..."
            }
        </Typography>
        <Grid className={classes.iconsRedes}>
            <Icon
                className='ri-facebook-circle-line font-size-24'
                alt="facebook-logo"
                onClick={() => navToSocial('FACEBOOK', facebook_profile_url)}
            >
            </Icon>
            <Icon
                className='ri-instagram-line font-size-24'
                alt="instagram-logo"
                onClick={() => navToSocial('INSTAGRAM', instagram_profile_url)}
            >
            </Icon>
            <Button className={classes.buttonTwitterX} onClick={() => navToSocial('TWITTER', twitter_profile_url)}>
                <TwitterXLogo alt="twitter-logo" />
            </Button>
            <Icon
                className='ri-linkedin-box-line font-size-24'
                alt="linkedin-logo" 
                onClick={() => navToSocial('LINKEDIN', linkedin_profile_url)}
            >
            </Icon>
        </Grid>
    </Grid>
  )
}

export default ProfileDetails;