import { makeStyles } from "@material-ui/core";

export const checkoutNewStyles = makeStyles((theme) => ({
    btn: {
        minWidth: '175px',
        textTransform: 'none',
        "@media (max-width: 500px)": {
            minWidth: '40%'
        }
    },
    container: {
        maxWidth: "1280px",
        marginLeft: "auto",
        marginRight: "auto",
        flexWrap: "wrap-reverse",
        justifyContent: "space-between",
        "@media (max-width: 1394px)": {
            justifyContent: "center"
        },
        marginTop: '24px',
    },
    contentData: {
        marginRight: "5px",
        width: "550px",
        maxWidth: "550px",
        "@media (max-width: 1394px)": {
            marginRight: "0px",
        },
        "@media (max-width: 510px)": {
            width: "100%",
            maxWidth: "100%",
        }
    },
    contentDetail: {
        maxWidth: "566px"
    },
    price: {
        color: theme.palette.colors.orange[300],
    },
    title: {
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left',
        "@media (max-width: 510px)": {
            lineHeight: '32px !important',
        }
    },
    titleSteps: {
        fontSize: '32px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingBottom: '32px'
    },
    folio: {
        textAlign: 'right',
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '40px',
        color: '#828D9E',
        "@media (max-width: 599px)": {
            paddingTop: '14px',
            textAlign: 'left',
        }
    },
    cartTitle:
    {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        paddingTop: '48px',
        color: '#A0A9BA',
        paddingBottom: '11px',
    },
    cartCourses:
    {
        marginBottom: '63px'
    },
    note:
    {
        color: '#A0A9BA',
        fontSize: '12px',
        paddingBottom: '24px'
    },
    line:
    {
        borderTop: '1px solid #404150',
        paddingBottom: '33px'
    },
    buttonContainer:
    {
        textAlign: 'right'
    },
    warningContainer:
    {
        textAlign: 'left',
        color: '#FCD612',
        display: 'inline-flex',
        paddingBottom: '40px'
    },
    proceedButton:
    {
        fontSize: '16px',
        color: 'white',
        fontWeight: '600',
        width: '260px',
        textTransform: 'none',
        "@media (max-width: 510px)": {
            width: '100%'
        }
    },
    finishButton:
    {
        fontSize: '16px',
        color: 'white',
        fontWeight: '600',
        width: '390px',
        textTransform: 'none',
        "@media (max-width: 510px)": {
            width: '100%'
        }
    },
    finishCardButton:
    {
        fontSize: '16px',
        color: 'white',
        fontWeight: '600',
        width: '390px',
        textTransform: 'none',
        "@media (max-width: 959px)": {
            width: '100%'
        }
    },
    textButton:
    {
        marginRight: '70px',
        height: '57px',
        "@media (max-width: 510px)": {
            width: '100%',
            height: '70px',
        }
    },
    paymentMethodsAccordionTitleArrow:
    {
        color: 'white',
        fontSize: '14px'
    },
    paymentMethodsAccordionTitle:
    {
        minHeight: '40px !important',
        height: '40px',
        "@media (max-width: 1300px)": {
            height: '80px'
        },
        "@media (max-width: 960px)": {
            height: '40px'
        },
        "@media (max-width: 600px)": {
            height: '80px'
        }
    },
    paymentMethodsAccordionDetails:
    {
        padding: '0px'
    },
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingTop: '58px',
        paddingLeft: '104px',
        paddingRight: '107.14px',
        border: '0.5px solid #404150',
        borderRadius: '8px',
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    modalInside:{
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    cardOrderContainer:
    {
        paddingLeft: '100px !important', 
        paddingRight: '100px !important',
        [theme.breakpoints.down('1000')]:{
            paddingLeft: '8px !important', 
            paddingRight: '8px !important',
        },
        backgroundColor: `${theme.palette.colors.grey[500]}`,
        border: `0.5px solid ${theme.palette.colors.grey[50]}`
    },
    textFieldCard:
    {
        borderRadius: '0px !important',
        backgroundColor: 'transparent',
    },
    inputCard:
    {
        borderRadius: '0px !important',
        borderBottom: '2px solid #A0A9BA',
        paddingLeft:'14px',
        backgroundColor: 'transparent',
        height: '45px'
    },
    inputCardStart:
    {
        borderRadius: '0px !important',
        borderBottom: '2px solid #A0A9BA',
        backgroundColor: 'transparent',
        height: '45px'
    },
    phoneInput:
    {
        marginTop: '40px',
        width:'100%'
    },
    phoneInputError:
    {
        borderColor: `${theme.palette.colors.electricRed[400]} !important`
    },
    helpIcon:
    {
        color: '#FF8000',
        marginLeft: '2px'
    },
    filterCheckbox:
    {
        "@media (max-width: 959px)": {
            display: 'none'
        }
    },
    filterCheckbox2:
    {
        display:'none',
        "@media (max-width: 959px)": {
            display:'block'
        }
    },
    price: {
        color: theme.palette.colors.orange[300],
        fontWeight: 600,
    },
    phoneTitle:
    {
        color: theme.palette.colors.white,
        paddingBottom: '14px'
    }
}));
