import React from 'react';
import { useSelector } from 'react-redux';

// @SDK
import { UserService } from '@sdk-point/talisis';

// @shared/utils
import * as format from '../../../../../shared/utils/format';
import Toast from '../../../../../shared/components/Toast/Toast';

// @mui
import { Box, Button, Grid, Hidden, makeStyles, TextField, Typography } from '@material-ui/core';

import visa from '../../../../../assets/images/banks/visa-logo.svg';
import mastercard from '../../../../../assets/images/banks/master-card-logo.svg';

const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.colors.grey[500],
        border: `0.5px solid ${theme.palette.colors.grey[400]}`,
        borderRadius: '8px',
    },
    rootLabel: {
        padding: '24px',
    },
    title: {
        color: theme.palette.colors.violet[300],
    },
    line: {
        border: 0,
        borderBottom: `0.5px solid ${theme.palette.colors.grey[50]}`,
        margin: 0,
    },
    subRoot: {
        padding: '32px 88px 32px 88px',
        '& .right': {
            textAlign: 'right',
        },
        '& .left': {
            textAlign: 'left',
        },
        '& .bottom': {
            verticalAlign: 'bottom',
        }
    },
    masterCard: {
        fontSize: '2rem',
        float: 'right',
    },
    visa: {
        fontSize: '3.5rem',
        float: 'right',
    },
}));

const EditPaymentMethod = (props) => {
    const classes = useStyles();
    const { user } = useSelector((state) => state.userReducer);

    const [open, setOpen] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [data, setData] = React.useState({
        card_holder: props?.cards[0]?.name,
        card_number: `···· ${props?.cards[0]?.last4}`,
        card_email: user.email,
        card_expiration: `${props?.cards[0]?.exp_month}/${props?.cards[0]?.exp_year}`,
        card_expiration_month: props?.cards[0]?.exp_month,
        card_expiration_year: props?.cards[0]?.exp_year,
        card_cvv: '000'
    });
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleFormatCreditCardNumber = (e) => {
        if (e.target.value) {
            e.target.value = format.creditCardNumber(e.target.value);
        }
    };

    const handleFormatCreditCardExp = (e) => {
        if (e.target.value) {
            e.target.value = format.creditCardExp(e.target.value);
        }
    };

    const handleFormatPhoneNumber = (e) => {
        if (e.target.value) {
            e.target.value = format.phoneNumber(e.target.value);
        }
    };

    const handleInput = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let errorMessage = '';

        if (!data.card_holder || !data.card_expiration) {
            errorMessage = 'Favor de completar los campos requeridos';
        }
        else if (!/^[a-zA-Z\u00F1\u00D1]+ [a-zA-Z\u00F1\u00D1\s]+$/.test(data.card_holder)) {
            errorMessage = 'El nombre en la tarjeta tiene un formato inválido';
        }
        else if (!/^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(data.card_expiration)) {
            errorMessage = 'La fecha de vencimiento es inválida';
        }

        return errorMessage;
    };

    const handleSubmit = async () => {
        const errorMessage = validate();
        if (errorMessage) {
            setOpen(true);
            setErrorMessage(errorMessage);
            return false;
        }

        setSubmitted(true);
        const cardExpDate = String(data.card_expiration).split('/');
        const payment_source_ids = {};
        props.cards.map(card => {
            payment_source_ids[card.ou] = card.id;
        });
        
        await UserServiceSDK.conektaUpdateCustomerCard(user.person_id, {
            payment_source_ids: payment_source_ids,
            data: {
                name: data.card_holder,
                exp_month: cardExpDate[0],
                exp_year: cardExpDate[1],
            },
        });
        
        await props.getUserCard();
        props.setForm('');
        setSubmitted(false);
    };

    const handleFinishedToast = () => {
        setOpen(false);
        setErrorMessage('');
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.rootLabel}>
                <Typography variant='h5' className={classes.title}>Editar tarjeta</Typography>
            </Box>
            <hr className={classes.line} />
            <Box className={classes.subRoot}>
                <Grid container spacing={2}>
                    <Hidden key="desktop" mdDown>
                        <Grid item xs={5} className="left">
                            <Typography component="span" variant="body2" className="font-weight-700">Titular de la tarjeta</Typography>
                        </Grid>
                        <Grid item xs={1}>&nbsp;</Grid>
                        <Grid item xs={6} className="left">
                            <Typography component="span" variant="body2" className="font-weight-700">Número de la tarjeta</Typography>
                            <img src={mastercard} alt="Master Card" className={classes.masterCard} />
                            <img src={visa} alt="Visa" className={`${classes.visa} mr-3`} />
                        </Grid>
                        <Grid item xs={5} className="right">
                            <TextField
                                name="card_holder"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                placeholder="Nombre en la tarjeta"
                                InputProps={{
                                    className: 'bg-fill-stroke-400',
                                }}
                                margin="dense"
                                onInput={handleInput}
                                value={data?.card_holder}
                            />
                        </Grid>
                        <Grid item xs={1}>&nbsp;</Grid>
                        <Grid item xs={6} className="right">
                            <TextField
                                id="card_number"
                                name="card_number"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                placeholder="Agregar un número de tarjeta"
                                InputProps={{
                                    startAdornment: <i class="ri-bank-card-fill text-dark-black-200 mr-2"></i>,
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    className: 'text-dark-black-200',
                                    maxLength: 19,
                                    readOnly: true,
                                }}
                                margin="dense"
                                onInput={(e) => {
                                    handleFormatCreditCardNumber(e);
                                }}
                                value={data?.card_number}
                            />
                        </Grid>
                        <Grid item xs={5} className="mt-2">
                            <Typography component="span" variant="body2" className="font-weight-700">Correo electrónico</Typography>
                        </Grid>
                        <Grid item xs={1}>&nbsp;</Grid>
                        <Grid item xs={3} className="mt-2">
                            <Typography component="span" variant="body2" className="font-weight-700">Fecha de vencimiento</Typography>
                        </Grid>
                        <Grid item xs={3} className="mt-2">
                            <Typography component="span" variant="body2" className="font-weight-700">Código de seguridad</Typography>
                        </Grid>
                        <Grid item xs={5} className="mt-2">
                            <TextField
                                name="card_email"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                InputProps={{
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    className: 'text-dark-black-200',
                                    readOnly: true,
                                }}
                                placeholder={user.email}
                                margin="dense"
                                value={user.email}
                            />
                        </Grid>
                        <Grid item xs={1}>&nbsp;</Grid>
                        <Grid item xs={3} className="mt-2">
                            <TextField
                                name="card_expiration"
                                variant="outlined"
                                size="medium"
                                InputProps={{
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    maxLength: 5,
                                }}
                                placeholder="MM/AA"
                                margin="dense"
                                onInput={(e) => {
                                    handleFormatCreditCardExp(e);
                                    handleInput(e);
                                }}
                                value={data?.card_expiration}
                            />
                        </Grid>
                        <Grid item xs={3} className="mt-2">
                            <TextField
                                name="card_cvv"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                InputProps={{
                                    startAdornment: <i class="ri-lock-2-line bottom text-dark-black-200 mr-2"></i>,
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    className: 'text-dark-black-200',
                                    maxLength: 3,
                                    readOnly: true,
                                }}
                                type="password"
                                placeholder="CVV"
                                margin="dense"
                                value={data?.card_cvv}
                            />
                        </Grid>
                        <Grid item xs={5} className="mt-2">
                            <Typography component="span" variant="body2" className="font-weight-700">Número de celular</Typography>
                        </Grid>
                        <Grid item xs={1}>&nbsp;</Grid>
                        <Grid item xs={6} className="mt-2">
                            <Typography variant="caption" className="text-dark-black-200 "><i class="ri-lock-2-line bottom mr-2"></i>Todos los pagos son seguros y encriptados.</Typography>
                        </Grid>
                        <Grid item xs={5} className="mt-2">
                            <TextField
                                name="card_phone"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                InputProps={{
                                    startAdornment: <Typography variant="caption" className="text-dark-black-200 mr-2"><i class="ri-smartphone-line bottom text-dark-black-200 mr-2"></i>+52</Typography>,
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    className: 'text-dark-black-200',
                                    maxLength: 14,
                                    readOnly: true,
                                }}
                                placeholder="Número de celular"
                                margin="dense"
                                onInput={(e) => {
                                    handleFormatPhoneNumber(e);
                                }}
                                value={data?.card_phone || user?.phone_number}
                            />
                        </Grid>
                    </Hidden>
                    <Hidden key="responsive" lgUp>
                        <Grid item xs={12} className="left">
                            <Typography component="span" variant="body2" className="font-weight-700">Titular de la tarjeta</Typography>
                        </Grid>
                        <Grid item xs={12} className="right">
                            <TextField
                                name="card_holder"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                placeholder="Nombre en la tarjeta"
                                InputProps={{
                                    className: 'bg-fill-stroke-400',
                                }}
                                margin="dense"
                                onInput={handleInput}
                                value={data?.card_holder}
                            />
                        </Grid>
                        <Grid item xs={12} className="left">
                            <Typography component="span" variant="body2" className="font-weight-700">Número de la tarjeta</Typography>
                            <img src={mastercard} alt="Master Card" className={classes.masterCard} />
                            <img src={visa} alt="Visa" className={`${classes.visa} mr-3`} />
                        </Grid>
                        <Grid item xs={12} className="right">
                            <TextField
                                id="card_number"
                                name="card_number"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                placeholder="Agregar un número de tarjeta"
                                InputProps={{
                                    startAdornment: <i class="ri-bank-card-fill text-dark-black-200 mr-2"></i>,
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    className: 'text-dark-black-200',
                                    maxLength: 19,
                                    readOnly: true,
                                }}
                                margin="dense"
                                onInput={(e) => {
                                    handleFormatCreditCardNumber(e);
                                }}
                                value={data?.card_number || user?.phone_number}
                            />
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <Typography component="span" variant="body2" className="font-weight-700">Correo electrónico</Typography>
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <TextField
                                name="card_email"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                InputProps={{
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    className: 'text-dark-black-200',
                                    readOnly: true,
                                }}
                                placeholder={user.email}
                                margin="dense"
                                value={user.email}
                            />
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <Typography component="span" variant="body2" className="font-weight-700">Fecha de vencimiento</Typography>
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <TextField
                                name="card_expiration"
                                fullWidth
                                variant="outlined"
                                size="medium"
                                InputProps={{
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    maxLength: 5,
                                }}
                                placeholder="MM/AA"
                                margin="dense"
                                onInput={(e) => {
                                    handleFormatCreditCardExp(e);
                                    handleInput(e);
                                }}
                                value={data?.card_expiration}
                            />
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <Typography component="span" variant="body2" className="font-weight-700">Código de seguridad</Typography>
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <TextField
                                name="card_cvv"
                                fullWidth
                                variant="outlined"
                                size="medium"
                                InputProps={{
                                    startAdornment: <i class="ri-lock-2-line bottom text-dark-black-200 mr-2"></i>,
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    className: 'text-dark-black-200',
                                    maxLength: 3,
                                    readOnly: true,
                                }}
                                type="password"
                                placeholder="CVV"
                                margin="dense"
                                value={data?.card_cvv}
                            />
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <Typography component="span" variant="body2" className="font-weight-700">Número de celular</Typography>
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <TextField
                                name="card_phone"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                InputProps={{
                                    startAdornment: <Typography variant="caption" className="text-dark-black-200 mr-2"><i class="ri-smartphone-line bottom text-dark-black-200 mr-2"></i>+52</Typography>,
                                    className: 'bg-fill-stroke-400',
                                }}
                                inputProps={{
                                    className: 'text-dark-black-200',
                                    maxLength: 14,
                                    readOnly: true,
                                }}
                                placeholder="Número de celular"
                                margin="dense"
                                onInput={(e) => {
                                    handleFormatPhoneNumber(e);
                                }}
                                value={data?.card_phone}
                            />
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <Typography variant="caption" className="text-dark-black-200 "><i class="ri-lock-2-line bottom mr-2"></i>Todos los pagos son seguros y encriptados.</Typography>
                        </Grid>
                    </Hidden>
                    <React.Fragment>
                        <Hidden key="desktop" mdDown>
                            <Grid item xs={1}>&nbsp;</Grid>
                            <Grid item xs={5} className="left mt-3">
                                <Button variant="standard" className="mr-4 bg-fill-stroke-500" onClick={() => { props.setForm('') }}>Regresar</Button>
                                <Button variant="contained" color="primary" className={classes.button} onClick={!submitted ? handleSubmit : ''}>{!submitted ? 'Editar tarjeta' : 'Editando tarjeta...'}</Button>
                            </Grid>
                        </Hidden>
                        <Hidden key="responsive" lgUp>
                            <Grid item xs={12} className="center mt-3">
                                <Button variant="standard" fullWidth className="mr-4 bg-fill-stroke-500" onClick={() => { props.setForm('') }}>Regresar</Button>
                            </Grid>
                            <Grid item xs={12} className="left mt-3">
                                <Button variant="contained" fullWidth color="primary" className={classes.button} onClick={!submitted ? handleSubmit : ''}>{!submitted ? 'Editar tarjeta' : 'Editando tarjeta...'}</Button>
                            </Grid>
                        </Hidden>
                    </React.Fragment>
                </Grid>
                <Toast severity="error" open={open} message={errorMessage} onFinished={handleFinishedToast} duration={3000} />
            </Box>
        </Box>
    )
};

export default EditPaymentMethod;