import React from 'react';

// @material-ui
import { FormControlLabel, Grid, Radio } from "@material-ui/core"
import { useTheme } from '@material-ui/core/styles';

// assets
import useStyles from '../../../../../../assets/styles/jss/pages/checkout/checkout';

// components
import PaymentMethodItemLabel from '../PaymentMethodItem/PaymentMethodItemLabel/PaymentMethodItemLabel';
import InstallmentOptionsDropdown from '../InstallmentOptionsDropdown/InstallmentOptionsDropdown';

const PaymentMethodItem = ({disablePayment, paymentMethod, installmentOptions = [], displayInstallmentOptions = false }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Grid container alignItems='center'>
            <Grid item xs>
                <FormControlLabel
                    disabled ={disablePayment}
                    className={classes.paymentMethodItem}
                    value={disablePayment? '':paymentMethod?.unique_id}
                    control={<Radio color="primary" style={{ color: (disablePayment && theme.palette.colors.fillStroke[200]) }}/>}
                    label={<PaymentMethodItemLabel paymentMethod={paymentMethod} disablePayment={disablePayment}/>}
                />
                {displayInstallmentOptions && <Grid container>
                    <Grid item xs={9} className='ml-4 mb-3'>
                        <InstallmentOptionsDropdown installmentOptions={installmentOptions} />
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
    );
}

export default PaymentMethodItem;