import React from 'react';
import { useSelector } from 'react-redux';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { Add, ChevronLeft, Group, Lock } from '@material-ui/icons';

// @utils
import * as DateUtils from '../../../../../../shared/utils/dateUtils';
import ConfirmDialog from '../../../../../../shared/components/ConfirmDialog/ConfirmDialog';
import LoaderContent from '../../../../../../shared/components/Loader/LoaderContent';
import { Alert } from '@material-ui/lab';

//SDK
import { AccountService } from '@sdk-point/talisis';

const Detail = ({ dataSet, detail, label, form, onSetForm, afterSubmitProcess }) => {
    const [loading, setLoading] = React.useState(null);
    const [sucess, setSuccess] = React.useState(false);
    const { user } = useSelector((state) => state.userReducer);
    const [dialogProps, setDialogProps] = React.useState({ data_set: '', row_id: '', title: '', text: '', open: false });
    
    const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    
    const theme = useTheme();
    const useStyles = makeStyles({
        card: {
            backgroundColor: theme.palette.colors.grey[400],
            padding: '1em',
        },
        hr: {
            border: '0px',
            borderTop: `1px solid ${theme.palette.colors.grey[700]}`,
        },
        title: {
            color: theme.palette.colors.white,
            cursor: 'default',
            fontSize: '12px',
            fontWeight: 600,
        },
        label: {
            color: theme.palette.colors.fillStroke[100],
            fontWeight: 600,
            fontSize: '12px',
        },
        text: {
            color: theme.palette.colors.white,
            fontSize: '10px',
            fontWeight: '600',
        },
        inputText: {
            border: `1.5px solid ${theme.palette.colors.fillStroke[200]}`,
            borderRadius: '5px',
            color: theme.palette.colors.white,
            padding: '0.5em'
        },
        icon: {
            color: theme.palette.colors.fillStroke[100],
            cursor: 'pointer',
            fontSize: '14px',
        },
        iconContainer: {
            textAlign: 'center',
        },
        toogleButton: {
            backgroundColor: theme.palette.colors.grey[500],
            border: `2px solid ${theme.palette.colors.fillStroke[200]}`,
            color: theme.palette.colors.white,
        },
        toogleButtonSelected: {
            '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: theme.palette.colors.fillStroke[100],
                color: theme.palette.colors.white,
            }
        },
        button: {
            border: `1.5px solid ${theme.palette.colors.grey[600]}`,
            backgroundColor: theme.palette.colors.black,
            borderRadius: '4px',
            color: theme.palette.colors.white,
            fontSize: '12px',
        },
        footerLabel: {
            fontSize: '12px',
            fontWeight: 600,
        },
        alert: {
            backgroundColor: theme.palette.colors.grey[500],
            color: theme.palette.colors.white,
            float: 'left',
            fontSize: '12px',
            marginTop: '10px',
        },
        button: {
            border: `1.5px solid ${theme.palette.colors.grey[600]}`,
            backgroundColor: theme.palette.colors.black,
            borderRadius: '4px',
            color: theme.palette.colors.white,
            fontSize: '12px',
            '& .MuiButton-label': {
                textTransform: 'none'
            }
        },
    });

    const Delete = ({style, onClick}) => {
        return <Tooltip title="Eliminar">
            <svg width="12" className={classes.icon} style={style} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
                <path d="M11.0416 3.75008H14.5833V5.16675H13.1666V14.3751C13.1666 14.5629 13.092 14.7431 12.9592 14.8759C12.8263 15.0088 12.6462 15.0834 12.4583 15.0834H2.54163C2.35376 15.0834 2.1736 15.0088 2.04076 14.8759C1.90792 14.7431 1.83329 14.5629 1.83329 14.3751V5.16675H0.416626V3.75008H3.95829V1.62508C3.95829 1.43722 4.03292 1.25705 4.16576 1.12421C4.2986 0.991376 4.47876 0.916748 4.66663 0.916748H10.3333C10.5212 0.916748 10.7013 0.991376 10.8342 1.12421C10.967 1.25705 11.0416 1.43722 11.0416 1.62508V3.75008ZM11.75 5.16675H3.24996V13.6667H11.75V5.16675ZM5.37496 7.29175H6.79163V11.5417H5.37496V7.29175ZM8.20829 7.29175H9.62496V11.5417H8.20829V7.29175ZM5.37496 2.33341V3.75008H9.62496V2.33341H5.37496Z" fill="#A0A9BA"/>
            </svg>
        </Tooltip>;
    };

    const Edit = ({style, onClick}) => {
        return <Tooltip title="Editar">
            <svg width="12" className={classes.icon} style={style} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
                <path d="M4.54325 11.8334L11.7272 4.64943L10.7256 3.64785L3.54167 10.8318V11.8334H4.54325ZM5.13046 13.25H2.125V10.2446L10.2248 2.14477C10.3576 2.01198 10.5378 1.93738 10.7256 1.93738C10.9134 1.93738 11.0935 2.01198 11.2264 2.14477L13.2302 4.14864C13.363 4.28148 13.4376 4.46161 13.4376 4.64943C13.4376 4.83726 13.363 5.01739 13.2302 5.15023L5.13046 13.25V13.25ZM2.125 14.6667H14.875V16.0834H2.125V14.6667Z" fill="#A0A9BA"/>
            </svg>
        </Tooltip>;
    }

    const printDetail = (dataSet, data) => {
        let jsx = null;
        switch (dataSet) {
            case 'career':
                jsx = <Typography variant="subtitle2" className={classes.text} style={{ width: '100%' }}>
                    {data.career}
                </Typography>;
                break;
            case 'job_history':
                jsx = <Typography variant="subtitle2" className={classes.text} style={{ width: '100%' }}>
                    <b>{data.company_name}</b>
                    <br />
                    <div className="Profile_DetailContent" style={{lineHeight: '15px'}}>{data.position}</div>
                    <div className="Profile_DetailContent" style={{lineHeight: '15px'}}>{`${data.start_year} - ${data.ending_year === DateUtils.CURRENT_YEAR ? 'Actual' : data.ending_year}`}</div>
                </Typography>;
                break;
            case 'education':
                jsx = <Typography variant="subtitle2" className={classes.text} style={{ width: '100%' }}>
                    <b>{data.educational_institution_name}</b>
                    <div className="Profile_DetailContent" style={{lineHeight: '15px'}}>{data.specialization}</div>
                    <div className="Profile_DetailContent" style={{lineHeight: '15px'}}>{`${data.start_year} - ${data.ending_year === DateUtils.CURRENT_YEAR ? 'Actual' : data.ending_year}`}</div>
                </Typography>;
                break
            default:
                break;
        }

        return jsx;
    }

    const handleDeleteClick = (e, dataSet, row_id) => {
        e.stopPropagation();
        setDialogProps({ data_set: dataSet, row_id: row_id, open: true, title: 'Eliminar la información', text: 'Presione [Confirmar] para eliminar la información.' });
    };

    const handleConfirm = (value) => {
        if (value === true) {
            setLoading(true);
            const deleteRecord = async () => {
                const { data_set, row_id } = dialogProps;
                const response = await AccountServiceSDK.deleteProfileData({
                    person_id: user.person_id,
                    dataSet: data_set,
                    row_id: row_id
                });
                const {ok} = response;
                if (ok) {
                    setLoading(false);
                    setSuccess(true);
                    await afterSubmitProcess();
                    onSetForm(0);
                }
                setLoading(false);
            };
            deleteRecord();
        };
    };

    const handleClose = () => {
        setDialogProps({ ...dialogProps, data_set: '', id: '', open: false });
    };

    const classes = useStyles();
    return (
        detail && detail.length > 0 ?
            <LoaderContent loading={loading}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={`Profile_Title ${classes.title}`} onClick={() => { onSetForm(0) }}>
                                <Tooltip title="Regresar" placement="left">
                                    <ChevronLeft className="Profile_ChevronPointer Profile_ChevronLeft" />
                                </Tooltip>
                                {label}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {detail.map((data, k) => (
                                <Grid container spacing={1} key={`detail_${k}`} style={{borderBottom: `0.5px solid #404150`}}>
                                    <Grid item xs={1} className={classes.iconContainer}>
                                        <div className="Profile_FormControl" style={{padding: '0.5em'}}>
                                            <Tooltip title={<span>&#8856;</span>} placement="right-end">
                                                {data.visibility === 'public' ? <Group className={classes.icon} /> : <Lock className={classes.icon} />}
                                            </Tooltip>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className="Profile_FormControl" style={{padding: '0.5em'}}>
                                            {printDetail(dataSet, data)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="Profile_FormControl" style={{float: 'right', padding: '0.5em'}}>
                                            <Delete style={{marginLeft: '10px'}} onClick={(e) => handleDeleteClick(e, dataSet, data[`person_${dataSet}_id`])}/>
                                            {/* Temporalmente deshabilitado */}
                                            {/* <Edit style={{marginLeft: '10px'}} /> */}
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    {sucess && <Alert id='alert' variant="outlined" severity="success" className={classes.alert}>
                        Los datos se ha borrado con éxito.
                    </Alert>}
                    <Button
                        variant="outlined"
                        startIcon={<Add />}
                        className={`mt-4 Profile_AddedButton ${classes.button}`}
                        onClick={() => {
                            onSetForm(form);
                        }}
                        size="large">
                        Añadir datos sobre {label}
                    </Button>
                    <ConfirmDialog {...dialogProps} onConfirm={handleConfirm} onClose={handleClose} />
                </Grid>
            </LoaderContent>: ''
    )
}

export default Detail;