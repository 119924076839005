import React from 'react';
import clsx from "clsx";
import CurrencyFormat from 'react-currency-format';
//Material-ui
import Card from '@material-ui/core/Card';
import { Grid, Typography, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
//Component
import MembershipCrossedOutPrice from '../../../../Components/MembershipCrossedOutPrice';
//Style
import { makeStyles } from '@material-ui/core/styles';

const useStyles =  makeStyles((theme) => ({
  card: {
    background:'transparent',
    borderRadius:'0px',
    textAlign:'center',
    display: 'flex',
    flexDirection: 'column',
  },
  borderCard:{
    width: '202px',
    height: '170px',
    padding: '24px 16px 24px 16px',
    border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
  },
  boxActionSubscription:{
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    justifyContent: 'flex-end',
    marginTop:'10px'
  },
  textAMonth: {
    ...theme.typography.bodyTextXsmallRegular,
  }
}))

const MembershipHeader = ({membership, feeSlugSelected, feeSlugBase, handleClick, withBorder = true}) =>{
  const classes = useStyles();
  const feeSelected = membership?.fees?.find(fee=> fee.slug === feeSlugSelected);
  const feeBase = membership?.fees?.find(fee=> fee.slug === feeSlugBase);
  return (
    <Card className={clsx(classes.card, withBorder? classes.borderCard:'')}>
      <Typography variant='subtitle2'>{membership?.name}</Typography>
      <Grid style={{marginTop:'10px'}}>
        { feeSelected?.slug && feeSelected?.slug !== feeSlugBase &&
          <MembershipCrossedOutPrice priceToCrossOut={Number(feeBase?.monthlyPrice)} typographyVariant='caption'/>
        }
      </Grid>
      { membership.free ?
        <Grid item className={clsx("membership-amount")}>
          <Typography variant={"h4"} color="initial" component="span">Gratis</Typography>
          <Typography variant="body1" className={clsx(classes.caption, 'ml-1')} component="span">siempre</Typography>
        </Grid>
        :
        <Typography variant="h5" color="secondary" component="span">
          <CurrencyFormat value={feeSelected?.monthlyPrice} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix={feeSelected?.currencySymbol} />
          <Typography variant="h6" color="secondary" component="span" className={'ml-1'} >
                          {feeSelected?.currencyCode}
          </Typography>
          <Typography className={clsx('ml-1', 'text-orange-300', classes.textAMonth)} style={{ display: 'inline-block' }}>al mes</Typography>
        </Typography>
       }
       { membership?.active && <Box className={classes.boxActionSubscription}>
          <Button   variant={membership?.popular ? 'contained': 'outlined'} color={membership?.popular ? 'primary': 'secondary'} onClick={(e)=> handleClick(e, membership, feeSelected)}>
            { membership?.free ? 'Empezar ahora' : 'Suscribirse ahora'} 
          </Button>
         </Box>
       }
    </Card>
  )
}


export default MembershipHeader;