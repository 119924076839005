import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    label:{
        color: theme.palette.colors.grey[500],
        fontWeight: 600,
        fontSize: 12,
        width: 27,
        height: 16,
        '& .MuiChip-label':{
            padding: 0
        },
        marginRight: 6
    },
    totalReview:{
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.colors.grey[100],
        marginLeft: 5
    },
    rating:{
        fontSize: "18px"
    },
    emptyStar:{
        fontSize: "18px",
        color: "#F2C80B"
    },
    rating_contains:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "2px",
        /*background: "#00000078",*/
        borderRadius: "7px",
        paddingRight: "6px",
        paddingLeft: "6px",
    }
}));