import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    card:{
        width:'300px', 
        height:'435px', 
        borderRadius: '16px',
        gad:'32px',
        padding: '8px 24px 8px 24px',
        backgroundColor:'transparent',
        marginRight: '0px',
    },
    cardTextDescription: {
        marginTop: '10px',
        ...theme.typography.bodyTextSmallRegular,
    },
    cardListBenefits: {
        marginTop:'20px'
    },
    cardItemListBenefits: {
        marginTop:'10px'
    },
    ItemListBenefitsIcon:{
        marginRight:'10px', 
        color: theme.palette.colors.blue[300],
        fontSize: '16px'
    },
    ItemListBenefitsDescription:{
        ...theme.typography.bodyTextSmallRegular
    }
}))