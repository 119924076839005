import React from 'react'

import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles';
import { interestSelectorStyles } from '../interestSelectorStyles';

const InterestsScreen = (props) => {

  const { title, caption1, caption2, interestList, btn1Text, btn1Function, btn1Disabled = false, 
            btn2Text, btn2Function, btn2Disabled = false } = props;
  
    const classes = interestSelectorStyles();
    const theme = useTheme();

    return (
      <>
      <Card style={{ background: "#1D1E23", textAlign: "center" }}>
        <CardContent>
          <Grid container style={{ borderBottom: `2.5px solid ${theme.palette.colors.grey[700]}` }}>
            <Grid item xs={12}>
              <Typography variant='h3' className='mb-3'>
                { title }
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ borderRadius: '2em' }}>
            <Grid item xs={12}>
              <Typography display='block' variant="caption" className='mt-4' style={{ color: `#A0A9BA`}}>
              { caption1 }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display='block' variant="caption" className='mt-4'>
              { caption2 }
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
            <Grid container spacing={1} className={classes.interestContainer}>
                { interestList }
            </Grid>
      </Card>
      <Grid item xs={12} className='mt-4'>
          <Grid container justifyContent='center'>
              <Button
                  color="primary"
                  variant="contained"
                  style={{ width: '13em'}}
                  disabled={ btn1Disabled }
                  onClick={ btn1Function }
              >
                  <Typography variant='body2'> { btn1Text } </Typography>
              </Button>
          </Grid>
      </Grid>
      <Grid item xs={12} className='mt-2'>
          <Grid container justifyContent='center'>
              <Button
                  color="default"
                  variant="contained"
                  style={{ width: '13em', backgroundColor: '#404150'}}
                  disabled={ btn2Disabled }
                  onClick={ btn2Function }
              >
                  <Typography style={{textDecoration: 'underline'}} variant='body2'>{ btn2Text} </Typography>
              </Button>
          </Grid>
      </Grid>
      </>
    )
}

export default InterestsScreen
