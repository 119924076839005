import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

// @redux
import {connect} from 'react-redux';

// @design-system
import LoaderContent from '../../../../design-system/components/Loader/LoaderContent';
import Toast from '../../../../design-system/components/Toast/Toast';
import Table from '../../../../design-system/components/Table/Table';
import Select from '../../../../design-system/components/Form/Select/Select';
import dateFormat from "../../../../design-system/utils/dateFormat";
import {DATE_FORMAT_LOCAL, ISO_DATE_FORMAT} from "../../../../design-system/constants/date-formats";

// @material-core
import {
  Icon, Grid, Link, Box, Button, Card, CardContent, Typograph, Modal, Tooltip, CardHeader, CardActions
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Close from "@material-ui/icons/Close";

// @ThirtyParty
import {
  clone,
  concat,
  dropRepeats,
  findIndex,
  groupBy,
  groupWith,
  indexOf,
  isEmpty,
  isNil,
  join,
  propEq, reverse, sort,
  values
} from 'ramda';
import queryString from 'query-string'
import clsx from 'clsx';
import writeXlsxFile from 'write-excel-file'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import moment from 'moment';

// @local
import {certificate, excelCertificateColumns, kardex as columnsKardex} from '../../ColumnsTable';
import ModalDetails from '../../Student/ModalDetails';
import {compareFirstInSecondArray, stringContainsExactlyOf} from '../../../../shared/utils/common';
import {certificateStyles} from '../../../../assets/styles/jss/pages/degreeCertificates/CertificateStyles'
import {STATUS_PROCESS, API_RESPONSE_STATUS_ERROR} from "../../../../shared/constants/degree-certificate";

// SDK
import {DegreeAndCertificateService} from '@sdk-point/talisis';

const CertificateServiceSDK = new DegreeAndCertificateService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
  droppable: {
    backgroundColor: theme.palette.colors.fillStroke[500],
    width: '100%',
    padding: 16,
    borderRadius: 8,
    display: "inline-table"
  }, titleColumn: {
    marginLeft: 5, marginBottom: 5, paddingTop: 5,
  }, card: {
    backgroundColor: theme.palette.colors.fillStroke[200],
    width: '100%',
    height: 60,
    padding: "10px 16px 10px 16px",
    borderRadius: 8,
    marginBottom: 5,
    display: "inline-table"
  }, equiv: {
    backgroundColor: '#312C46'
  }, buttonEquiv: {
    marginTop: 5,
    height: 40
  },
  cardCombine: {
    height: "auto",
    backgroundColor: theme.palette.colors.fillStroke[300],
  },
  subCardCombine: {
    marginTop: 5,
    borderRadius: 8,
    padding: "8px 16px 8px 16px",
    display: "inline-table",
    backgroundColor: theme.palette.colors.fillStroke[400],
  },
  cardKardex: {
    marginBottom: 20,
    paddingBottom: 20,
    borderRadius: 8,
    backgroundColor: theme.palette.colors.fillStroke[500],
    // '& .MuiCard-root':{
    // }

  },
  headerKardex: {
    backgroundColor: theme.palette.colors.fillStroke[500],
  },
  tablesKardex: {
    position: 'relative',
    height: 470,
    overflow: 'auto',
    overflowX: 'hidden',
  },
  kardex: {
    backgroundColor: theme.palette.colors.fillStroke[500],
    border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`
  },
  cardSubjects: {},
  tablesSubjects: {
    borderRadius: 8,
    backgroundColor: theme.palette.colors.fillStroke[500],
  }
}));

const KardexDetails = (props) => {
    const classes = useStyles();
    const theme = useTheme()
    const [toast, setToast] = React.useState({toastProps: {message: '', open: false}})
    const [columns, setColumns] = React.useState([])
    const [kardex, setKardex] = React.useState([])
    const [countEquiv, setCountEquiv] = React.useState(0)
    const [kardexColumns, setkardexColumns] = React.useState(columnsKardex)
    const [cardDrop, setCardDrop] = React.useState(null);
    const [cardDrag, setCardDrag] = React.useState(null);
    const [btnActions, setBtnActions] = React.useState({id: 'DEFAULT', label: 'Motivo Calificacion'})
    const [selectedList, setSelectedList] = React.useState({})
    const [isCheckBox, setIsCheckBox] = React.useState(false)
    const [dataOriginal, setDataOriginal] = React.useState(clone(props.student?.kardex))
    const [sequencies, setSequencies] = React.useState([])
    const [dataSend, setDataSend] = React.useState([])
    const [selectMotive, setSelectMotive] = React.useState('')

    React.useEffect(() => {
      getStudentCertDetails()
    }, [])

    const isEdit = () => props.student?.datosGenerales ? !!stringContainsExactlyOf(props.student?.datosGenerales[0]?.status, [STATUS_PROCESS.CANDIDATE, STATUS_PROCESS.VALIDATE, STATUS_PROCESS.ERROR]) : false

    const getStudentCertDetails = () => {
      let groupByPeriod = groupWith((a, b) => a.clavePeriodo === b.clavePeriodo, props.student?.kardex)
      const formatColumns = []
      Object.keys(props.student).forEach((idx) => {
        if (stringContainsExactlyOf(idx, ["fueraPlan", "equivalencias"])) {

          formatColumns.push({
            id: idx.length,
            name: idx,
            combine: idx !== "fueraPlan",
            dragging: idx === "fueraPlan",
            subjects: props.student[idx].map(subj => idx !== 'fueraPlan' ? {
              ...subj,
              equiv: {},
              parent: idx,
              id: subj.secuenciaDetalle.toString()
            } : {...subj, id: subj.secuenciaDetalle.toString(), parent: idx})
          });
        }
      })
      setColumns(formatColumns)
      groupByPeriod = groupByPeriod.map(grp => grp.map(subj => {
        if (!isNil(subj.secuenciaEquivalencia)) {
          dataSend.push({
            seq_fuera_plan: subj.secuenciaDetalle.toString(),
            seq_equivalente: subj.secuenciaEquivalencia.toString()
          })
          setDataSend(dataSend)
          subj = {
            ...subj,
            id: subj.secuenciaDetalle.toString(),
            parent: "fueraPlan",
            equiv: {
              id: subj.secuenciaEquivalencia.toString(),
              secuenciaDetalle: subj.secuenciaEquivalencia,
              claveMateriaOficial: subj.claveMateriaOficialEq,
              claveMateriaInst: subj.claveMateriaInstEq,
              nombreMateria: subj.nombreMateriaEq,
              parent: "equivalencias",
            },
          }
        }
        return {...subj, id: subj.secuenciaDetalle}
      }))
      groupByPeriod = groupByPeriod.map(grp => grp.map(subj => {
        if (!isNil(subj.secuenciaEquivalencia)) {
          const data = subj
          subj = {
            ...subj,
            action: <Button
              color="secondary"
              variant="outlined"
              onClick={() => handledChangeEquivalency(data, groupByPeriod, formatColumns)}
              size="small"
              disabled={!isEdit()}
            >
              <Icon className={'ri-download-line font-size-24'} style={{marginRight: 5}}/>
              <Typography variant='body2'>Bajar Sustitución</Typography>
            </Button>
          }
        }
        return subj
      }))

      setKardex(groupByPeriod)
    }
    const onDragStart = (data) => console.log(`⛔ - start: `, data)
    const onDragEnd = (data) => {
      if (!isNil(data.destination)) {
        if (data.source.droppableId === data.destination.droppableId) {
          const card = document.getElementById(data.draggableId)
          if (!isNil(card)) {
            card.style.backgroundColor = '';

          }
        }
      }
      if (isNil(data.combine)) {
        return
      }
      if (!isEmpty(data.combine) && (data.source.droppableId === data.combine.droppableId)) {
        return
      }
      if (!isNil(data.combine)) {
        const cardDrag = data.combine
        let [programDestination] = columns.filter(prog => prog.id.toString() === cardDrag.droppableId)
        let [programSource] = columns.filter(prog => prog.id.toString() === data.source.droppableId)
        let materiaSource = programSource.subjects[data.source.index]
        programSource.subjects.splice(data.source.index, 1)
        programDestination.subjects.forEach(subj => {
          if (subj.id === data.combine.draggableId) {
            if (!isEmpty(subj.equiv)) {
              programSource.subjects.push(subj.equiv)
            }
            document.getElementById(subj.id).style.backgroundColor = '#312C46'
            subj.equiv = materiaSource
            setCountEquiv(prevState => prevState + 1)
          }
          return subj
        })
      }
    }
    const getStyle = (style, snapshot, column, subj) => {

      if (snapshot.isDragging && (!isNil(snapshot.draggingOver) && snapshot.draggingOver !== column)) {
        const _cardDrop = document.getElementById(snapshot.combineWith)
        if (!isNil(_cardDrop)) {
          setCardDrop(snapshot.combineWith)
          // if (_cardDrop.dataset.rbdEquiv === 'false') {
          //   _cardDrop.style.backgroundColor = '#482F6B';
          // } else {
          //   _cardDrop.style.backgroundColor = '#482F6B';
          // }

        }

        if (!isNil(cardDrop) && isNil(_cardDrop)) {
          const _cardDrop = document.getElementById(cardDrop)
          if (_cardDrop.dataset.rbdEquiv === 'false') {
            _cardDrop.style.backgroundColor = '';
          } else {
            _cardDrop.style.backgroundColor = '#312C46';
          }
          setCardDrop(null)
        }
      }
      if (!snapshot.isDropAnimating) {
        return style;
      }

      return {
        ...style,
        transitionDuration: `0.001s`
      };
    }
    const handledRemove = (subjId, columnId) => {
      const [column] = columns.filter(col => col.id.toString() === columnId.toString())
      let columnEquiv = {}
      let newColumns = []
      column.subjects.forEach(mate => {
        if (mate.id.toString() === subjId.toString()) {
          [columnEquiv] = columns.filter(col => col.name.toString() === mate.equiv.parent.toString())

          columnEquiv.subjects.push(mate.equiv)
          mate.equiv = {}
          if (countEquiv > 0) {
            setCountEquiv(prevState => prevState - 1)
          }
          document.getElementById(subjId).style.backgroundColor = '';
        }
      })
      newColumns.push(columnEquiv)
      newColumns.push(column)
      setColumns([{...columnEquiv}, {...column}])

    }

    const handleValidate = async () => {

      let payload = {
        ou: props.student?.datosGenerales[0]?.ou,
        sequences: props.student?.datosGenerales[0]?.seqEncabezado,
        action: "APT"
      }

      const response = await CertificateServiceSDK.postStatusStudents(payload)
      const data = response?.data
      if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
        const message = data?.message || response?.message
        props.setToast({toastProps: {severity: "error", open: true, message}})
        props.setLoading(false)
        return
      }
      props.handledSuccesKardex({
        secuencia: props.student?.datosGenerales[0]?.seqEncabezado,
        statusProceso: "APT",
        porcentajeProceso: props.student?.datosGenerales[0]?.porcentajeEstatus
      })
      props.setLoading(false)

    }
    const handledKardex = async () => {
      const _dataSend = clone(dataSend)
      sequencies.forEach(seq => _dataSend.push({
        seq_fuera_plan: seq.secuenciaDetalle.toString(),
        seq_equivalente: seq.secuenciaEquivalencia.toString()
      }))
      const payload = {
        seqEncabezado: props.student?.datosGenerales[0]?.seqEncabezado,
        jsonIn: {'secuencias': _dataSend}
        // jsonIn: {'secuencias': {seq_fuera_plan:"4805",seq_equivalente:"10001"}}
      }
      const response = await CertificateServiceSDK.updateStudentKardexDetails(props.student?.datosGenerales[0]?.matricula, payload)
      const data = response?.data

      if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success) || (!isNil(data?.message))) {
        const message = data?.message || response?.message
        props.setToast({toastProps: {severity: "error", open: true, message}})
        props.setLoading(false)
        return
      }
      props.handledSuccesKardex({...data, secuencia: props.student?.datosGenerales[0]?.seqEncabezado})
      props.setToast({toastProps: {severity: "success", open: true, message: "Se guardo correctamente."}})
      setSequencies([])
      setDataSend(_dataSend.filter(row => row.seq_equivalente !== ""))
      props.setLoading(false)
    }

    const handledChangeEquivalency = (subj, kdx = [], col = []) => {
      // TODO: REMOVE subj from Kardex and add subj to respectiv columns
      setDataSend(prevState => prevState.map(subject => {
        if (subject.seq_fuera_plan === subj.id.toString()) {
          subject.seq_equivalente = ""
        }
        return subject
      }))
      let subject = {}
      let subjectEquiv = {}
      let _columns = col
      kdx.forEach(grp => {
        grp.forEach(_subject => {
          if (_subject.id === subj.id) {
            subject = _subject
            subjectEquiv = _subject.equiv
          }
        })
      })
      if (isEmpty(subject)) {
        kardex.forEach(grp => {
          grp.forEach(_subject => {
            if (_subject.id === subj.id) {
              subject = {
                ..._subject,
                claveMateriaInst: subj.claveMateriaInstEq,
                claveMateriaOficial: subj.claveMateriaOficialEq,
                nombreMateria: subj.nombreMateriaEq,
              }
              subjectEquiv = _subject.equiv
            }
          })
        })
      }
      if (isEmpty(_columns)) {
        _columns = columns
      }

      setSequencies(prevState => prevState.filter(seq => seq.id !== subject.id))
      let [column] = _columns.filter(col => col.name === subject.parent)
      let [columnEquiv] = _columns.filter(col => col.name === subjectEquiv.parent)
      console.log(`⛔ - Down `, subjectEquiv, subject)
      columnEquiv.subjects.push({...subjectEquiv, equiv: {}})
      delete subject.equiv
      delete subject.action
      delete subject.motivoCalificacion
      delete subject.secuenciaEquivalencia
      delete subject.nombreMateriaEq
      delete subject.claveMateriaInstEq
      delete subject.claveMateriaOficialEq
      column.subjects.push({...subject, id: subject.id.toString()})
      setColumns([{...column}, {...columnEquiv}])
      setKardex(kardex => kardex.map(grp => grp.filter(subj => subj.id !== subject.id)).filter(grp => !!grp.length))
    }

    const handledSaveEquivalency = () => {
      const [column] = columns.filter(col => col.combine === true)
      const subjects = column.subjects.filter(subj => !isEmpty(subj.equiv))
      subjects.forEach(subj => {
        //TODO: refactor find by index into kardex then push grp
        let idx = null
        kardex.forEach((grp, index) => grp[0].orden === subj.equiv.orden ? idx = index : null)
        let _subj = clone(subj)
        let subject = {
          ..._subj.equiv,
          id: _subj.equiv.id,
          claveMateriaInstEq: _subj.equiv.claveMateriaInst,
          claveMateriaOficialEq: _subj.equiv.claveMateriaOficial,
          nombreMateriaEq: _subj.equiv.nombreMateria,
          motivoCalificacion: "100"
        }
        delete _subj.equiv
        subject = {
          ...subject,
          claveMateriaInst: _subj.claveMateriaInst,
          claveMateriaOficial: _subj.claveMateriaOficial,
          nombreMateria: _subj.nombreMateria,
          equiv: clone(_subj),
          secuenciaEquivalencia: _subj.secuenciaDetalle
        }
        const data = clone(subject)
        subject = {
          ...subject,
          action: <Button
            color="secondary"
            variant="outlined"
            onClick={() => handledChangeEquivalency(data)}
            size="small"
          >
            <Icon className={'ri-download-line font-size-24'} style={{marginRight: 5}}/>
            <Typography variant='body2'>Bajar Sustitución</Typography>
          </Button>,
        }

        const existsSubj = findIndex(propEq(subject.id.toString(), 'seq_fuera_plan'))(dataSend)
        if (existsSubj < 0) {
          sequencies.push(subject)
          setSequencies(sequencies)
        }
        setDataSend(prevState => prevState.map(subj => {
          if (subj.seq_fuera_plan === subject.id.toString()) {
            subj.seq_equivalente = subject.equiv.id
          }
          return subj
        }))
        if (!isNil(idx)) {
          kardex[idx].push(subject)
        } else {
          let grp = []
          grp.push(subject)
          kardex.push(grp)
        }
        // TODO: REMOVE subj from Column
        let index = findIndex(propEq(subj.id, 'id'))(column.subjects)
        if (index > -1) {
          column.subjects.splice(index, 1)
        }
        // kardex.forEach(grp => {
        //   if (grp[0].orden === subj.equiv.orden) {
        //     let _subj = clone(subj)
        //     let subject = {
        //       ..._subj.equiv,
        //       motivoCalificacion: "100"
        //     }
        //     delete _subj.equiv
        //     subject = {
        //       ...subject,
        //       equiv: clone(_subj),
        //       secuenciaEquivalencia:_subj.secuenciaDetalle
        //     }
        //     const data = clone(subject)
        //     subject = {
        //       ...subject,
        //       action: <Button
        //         color="secondary"
        //         variant="outlined"
        //         onClick={() => handledChangeEquivalency(data)}
        //         size="small"
        //       >
        //         <Icon className={'ri-download-line font-size-24'} style={{marginRight: 5}}/>
        //         <Typography variant='body2'>Bajar Equivalencia</Typography>
        //       </Button>,
        //     }
        //     grp.push(subject)
        //     // TODO: REMOVE subj from Column
        //     let index = findIndex(propEq(subj.id, 'id'))(column.subjects)
        //     if (index > -1) {
        //       column.subjects.splice(index, 1)
        //     }
        //   }
        // })
      })
      setCountEquiv(0)
      setKardex(sort((a, b) => Number(a[0].orden) > Number(b[0].orden), kardex))
      setCardDrop(null)
    }
    const handleChangeMotive = ({target: {value}}) => {
      const [data] = props.catalogues.motiveScore.filter(opt => opt.id === value)
      setBtnActions(data)
      if (data.id === '0') {
        setIsCheckBox(false)
        setSelectedList([])
        setSelectMotive('')
        return
      }
      setIsCheckBox(true)
      setSelectMotive(value)
    }

    const handleSubmitMotive = async () => {
      props.setLoading(true)
      if (!isEmpty(selectMotive)) {
        const subjects = []
        Object.keys(selectedList).forEach(idx => {
          selectedList[idx].forEach(subj => {
            subjects.push(subj)
          })
        })
        const seqEncabezado = props.student?.datosGenerales[0]?.seqEncabezado
        let payload = {
          seqEncabezado,
          motivo: btnActions.id,
          secuencias: join(',', subjects.map(s => s.secuenciaDetalle || null))
        }
        const matricula = props.student?.datosGenerales[0]?.matricula
        const response = await CertificateServiceSDK.postMoviteScore(matricula, payload)
        const data = response?.data

        if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
          const message = data?.message || response?.message
          props.setToast({toastProps: {severity: "error", open: true, message}})
          props.setLoading(false)
          return
        }
        Object.keys(selectedList).forEach(idx => {
          selectedList[idx].forEach(subj => {
            subj.motivoCalificacion = btnActions.id
          })
        })
        props.setToast({toastProps: {severity: "success", open: true, message: "Se guardo correctamente."}})
        props.setLoading(false)

      }
      setBtnActions({id: 'DEFAULT', label: 'Motivo Calificacion'})
      setSelectMotive('')
      setIsCheckBox(false)
      setSelectedList([])
    }

    const showCardCombine = (subj) => <React.Fragment>
      <Box display='inline-grid' width={'45%'}>
        <Typography variant='caption'>Nombre Materia</Typography>
        <Typography variant='body1' className={clsx('semi-bold')}>{subj.nombreMateria}</Typography>
      </Box>
      <Box display='inline-grid' className={'mr-1'}>
        <Typography variant='caption'>Clave Periodo</Typography>
        <Typography variant='body2'>{subj.clavePeriodo}</Typography>
      </Box>
      <Box display='inline-grid' className={'mr-1'}>
        <Typography variant='caption'>Calificacion</Typography>
        <Typography variant='body2'>{subj.calificacion}</Typography>
      </Box>
      <Box display='inline-grid'>
        <Typography variant='caption'>Creditos</Typography>
        <Typography variant='body2'>{subj.creditos}</Typography>
      </Box>
      <Box display='inline-grid' className={'mt-2 float-right'}>
        <Icon className={'ri-drag-move-2-fill font-size-24'}/>
      </Box>
    </React.Fragment>

    const showCardDarg = (subj) => <React.Fragment>
      <Box display='inline-grid' className={'mr-1'} width={'50%'}>
        <Typography variant='caption'>Nombre Materia</Typography>
        <Typography variant='body1' className={clsx('semi-bold')}>{subj.nombreMateria}</Typography>
      </Box>
      <Box display='inline-grid' className={'mr-1'}>
        <Typography variant='caption'>Id</Typography>
        <Typography variant='body2'>{subj.id}</Typography>
      </Box>
      <Box display='inline-grid' className={'mr-1'}>
        <Typography variant='caption'>Clave Oficial</Typography>
        <Typography variant='body2'>{subj.claveMateriaOficial}</Typography>
      </Box>
      <Box display='inline-grid'>
        <Typography variant='caption'>Clave Institucional</Typography>
        <Typography variant='body2'>{subj.claveMateriaInst}</Typography>
      </Box>
      <Box display='inline-grid' width={1} className={classes.subCardCombine}>
        {isEmpty(subj.equiv) && (
          <React.Fragment>
            <Box display={'flex'} alignItems={'center'} flexDirection='column'>
              <Icon className={'ri-add-line font-size-22 text-fill-stroke-100'}/>
            </Box>
            <Box display={'flex'} alignItems={'center'} flexDirection='column'>
              <Typography variant='caption' className={'text-fill-stroke-100'}>Suelta la sustitución aquí</Typography>
            </Box>
          </React.Fragment>
        )}
        {!isEmpty(subj.equiv) && (
          <Box>
            <Box display='inline-grid' width={'50%'}>
              <Typography variant='caption'>Nombre Materia</Typography>
              <Typography variant='body1' className={clsx('semi-bold')}>{subj.equiv.nombreMateria}</Typography>
            </Box>
            <Box display='inline-grid' className={'mr-1'}>
              <Typography variant='caption'>Clave Periodo</Typography>
              <Typography variant='body2'>{subj.equiv.clavePeriodo}</Typography>
            </Box>
            <Box display='inline-grid' className={'mr-1'}>
              <Typography variant='caption'>Calificacion</Typography>
              <Typography variant='body2'>{subj.equiv.calificacion}</Typography>
            </Box>
            <Box display='inline-grid'>
              <Typography variant='caption'>Creditos</Typography>
              <Typography variant='body2'>{subj.equiv.creditos}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </React.Fragment>

    const showInfoGeneral = () => <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Grid container spacing={5}>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Nombre
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.nombre || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Matricula
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.matricula || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  CURP
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.curp || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Genero
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.genero || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Fecha de Nacimiento
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {dateFormat(props.student?.datosGenerales[0]?.fechaNacimiento, DATE_FORMAT_LOCAL, 0, DATE_FORMAT_LOCAL) || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Nivel
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.nivel || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Plan de Estudios
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.planEstudios || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Nombre de estudios
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.nombreEstudios || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Clave campus
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.claveCampus || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Nombre del Campus
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.nombreCampus || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Num. Rvoe
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.numeroRvoe || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='caption' className={'mr-1 text-dark-black-200'}>
                  Tipo Periodo
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body2' className={'text-gray-100'}>
                  {props.student?.datosGenerales[0]?.tipoPeriodo || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    const onChangeSelectedRow = (row, idx) => {

      let isSelectGrp = !!selectedList[idx] || false
      if (isSelectGrp) {
        let index = findIndex(propEq(row.id, 'id'))(selectedList[idx])
        if (index > -1) {
          selectedList[idx].splice(index, 1)
          if (selectedList[idx] !== undefined && selectedList[idx].length === 0) {
            delete selectedList[idx]
          }
          setSelectedList({...selectedList})

        } else {
          let grp = selectedList[idx]
          grp.push(row)
          setSelectedList((prevState) => ({...prevState, [idx.toString()]: grp}))
        }
      } else {
        let grp = []
        grp.push(row)
        setSelectedList((prevState) => ({...prevState, [idx.toString()]: grp}))
      }
    }

    const onChangeAllSelectedRow = (isCheckAll, idx) => {
      let isSelectGrp = !!selectedList[idx] || false
      if (isSelectGrp) {
        delete selectedList[idx]
        setSelectedList({...selectedList})
        return
      }
      let grp = []
      kardex[idx].forEach(row => grp.push(row))
      setSelectedList((prevState) => ({...prevState, [idx.toString()]: grp}))
    }
    /* const handleFinishedToast = () => {
       setToast({toastProps: {open: false, message: ''}})
     }*/

  console.log(`⛔ - `,props.student)
    return (<>
      {/*{!isNil(props.student?.datosGenerales) ? */}
      <React.Fragment>
        {/*<LoaderContent loading={props.loading}>*/}
        <Grid container spacing={1} className={classes.cardKardex}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className={classes.headerKardex}>
                  <CardHeader
                    title={<Typography variant={'body1'} className={'font-weight-600'}>Kardex</Typography>}
                    action={
                      <Grid container justifyContent={'flex-end'}>
                        <Grid item xs={9}>
                          <Grid container>
                          {props.student?.datosGenerales[0]?.folio && (
                            <Grid item xs={12}>
                              <Grid container justifyContent={'flex-end'}>
                                <Typography variant='caption' style={{color: theme.palette.colors.gray[100]}}>
                                  Folio: {props.student?.datosGenerales[0]?.folio}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {props.student?.datosGenerales[0]?.seqEncabezado && (
                            <Grid item xs={12}>
                              <Grid container justifyContent={'flex-end'}>
                                <Typography variant='caption' style={{color: theme.palette.colors.gray[100]}}>
                                  Secuencia: {props.student?.datosGenerales[0]?.seqEncabezado}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {props.student?.datosGenerales[0]?.porcentajeEstatus && (
                            <Grid item xs={12}>
                              <Grid container justifyContent={'flex-end'}>
                                <Typography variant='caption' style={{color: theme.palette.colors.gray[100]}}>
                                  Procentaje de Estatus: {props.student?.datosGenerales[0]?.porcentajeEstatus}%
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton aria-label="go to back" onClick={props.goToBack}>
                            <Close className="font-size-24 text-dark-black-200"/>
                          </IconButton>
                        </Grid>
                      </Grid>
                    }
                  />
                  <CardContent>
                    {showInfoGeneral()}
                  </CardContent>
                  <CardActions>
                    {isEdit() && (
                      <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                        <Grid item xs={7} sm={7} md={7} lg={7} xl={8}>
                          <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Box display='inline-flex' className={'mr-1'}>
                                <Typography variant={'body2'} className={'text-dark-black-200'}>Cambiar
                                  calificación</Typography>
                              </Box>
                              <Box display='inline-flex' width={'50%'}>
                                <Select
                                  value={selectMotive}
                                  name="search-select"
                                  items={props.catalogues.motiveScore}
                                  onChange={handleChangeMotive}
                                  disabled={props.loading || kardex.length === 0}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={clsx(classes.buttonEquiv, 'mr-2')}
                                onClick={() => {
                                  handleSubmitMotive()
                                }}
                                disabled={btnActions.id === 'DEFAULT' || props.loading}
                                size="small"
                              >
                                <Typography variant='body2'>Confirmar</Typography>
                              </Button>
                            </Grid>
                            {props.student.isPartial && (props.student?.datosGenerales?.[0]?.porcentajeEstatus === 100) && (<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                              <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.buttonEquiv}
                                onClick={() => {
                                  props.setLoading(true)
                                  handleValidate()
                                }}
                                disabled={props.loading}
                                size="small"
                              >
                                <Typography variant='body2' noWrap>Validar Certificación Parcial</Typography>
                              </Button>
                            </Grid>)}
                          </Grid>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} xl={4}>
                          <Grid container spacing={1} justifyContent={'flex-end'} alignItems={'center'}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonEquiv}
                                onClick={() => {
                                  props.setLoading(true)
                                  handledKardex()
                                }}
                                disabled={(btnActions.id !== 'DEFAULT' || props.loading)}
                                size="small"
                              >
                                <Typography variant='body2'>Guardar Kardex</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.tablesKardex}>
                {kardex.map((period, index) => (
                  <Grid key={index} container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card className={classes.kardex}>
                        <Box className={'mt-2 ml-2'}>
                          <Typography variant='h6' className='text-withe-200'>{period[0].periodo}</Typography>
                        </Box>
                        <Table
                          checkboxs={isCheckBox}
                          maxHeight={'800px'}
                          columns={kardexColumns}
                          rowSelectedList={selectedList[index] || []}
                          rows={period}
                          onChangeSelectedRow={(e) => onChangeSelectedRow(e, index)}
                          onChangeAllSelectedRow={(e) => onChangeAllSelectedRow(e, index)}
                          checkboxDisabledIcon={true}
                          handleClickInfoTable={(data) => {
                          }}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.cardSubjects}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                  <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    {columns.map((column, index) => (
                      <Grid key={index} item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Card className={classes.tablesSubjects}>
                          <CardHeader
                            title={<Typography
                              variant={'body1'}>{column.name === 'fueraPlan' ? 'Fuera Plan' : 'Sustitución'}</Typography>}
                            subheader={column.name === 'fueraPlan' ?
                              <Typography variant={'caption'}>Arrastra las materias hacia la sustitución
                                deseada.</Typography> : <></>}
                            action={column.name !== 'fueraPlan' && isEdit() ? <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonEquiv}
                              onClick={() => {
                                handledSaveEquivalency()
                              }}
                              placeholder={'Subir Sustituciónes'}
                              disabled={countEquiv === 0 || props.loading}
                              size="small"
                            >
                              <Icon className={'ri-upload-line font-size-24'} style={{marginRight: 5}}/>
                              <Typography variant='body2'>Subir Sustituciones </Typography>
                            </Button> : <></>}
                          />
                          <CardContent>
                            <Box className={classes.droppable}>
                              <Droppable droppableId={column.id.toString()} key={column.id.toString()}
                                         isCombineEnabled={isEdit()}
                                         groupable={true} groupableType="item">
                                {(prov) => {
                                  const {innerRef: dropInnerRef} = prov
                                  return (<Box
                                    ref={dropInnerRef}
                                    {...prov.droppableProps}
                                  >
                                    {column.subjects.map((subj, index) => {
                                      return (<Draggable key={subj.id} draggableId={subj.id.toString()}
                                                         index={index} isDragDisabled={!column.dragging || !isEdit()}>
                                        {({innerRef, draggableProps, dragHandleProps}, snapshot) => {
                                          return (<Box ref={innerRef}
                                                       {...draggableProps}
                                                       {...dragHandleProps}
                                                       style={getStyle(draggableProps.style, snapshot, column.id.toString(), subj)}
                                                       className={clsx(classes.card, {
                                                         [classes.equiv]: !!isEmpty(subj.equiv),
                                                         [classes.cardCombine]: column.combine
                                                       },)}
                                                       id={subj.id}
                                                       data-rbd-equiv={!isEmpty(subj.equiv)}
                                                       onClick={() => {
                                                         if (!isNil(subj?.equiv) && !isEmpty(subj.equiv)) {
                                                           handledRemove(subj.id, column.id)
                                                         }
                                                       }}
                                          >
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                              {column.dragging && showCardCombine(subj)}
                                              {!column.dragging && showCardDarg(subj)}
                                            </Grid>
                                          </Box>)
                                        }}
                                      </Draggable>)
                                    })}
                                    {prov.placeholder}
                                  </Box>)
                                }}
                              < /Droppable>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>))}
                  </DragDropContext>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*</LoaderContent>*/}
      </React.Fragment>
      {/*  : null
      }
      <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={5000}/>*/}
    </>);
  }
;

KardexDetails.defaultProps = {
  student: {
    datosGenerales: [{
      status: ''
    }]
  }
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(KardexDetails);
