import React, {useState, useEffect} from "react";
// share compoment
import NewBotton from '../../shared/components/Button/NewButton'
// @material-ui/core
import {Card, Button, CardContent, Box} from '@material-ui/core'; 
// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';
import Tabs from '../../shared/components/Tabs/Tabs';
import { log } from '../../shared/utils/console.js'
import UserTablePag from './UserTablePag/UserTablePag';
// services
import * as UserService from '../../services/UserService'
//import * as AllysConnctionService from '../../services/AllysConnectionService'


import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';

const UserManagement = (props) => {
    const [canReadWrite, setCanReadWrite] = useState(false);
    useEffect(()=>{
        hanldeCanReadWrite();
    },[])

    const hanldeCanReadWrite = () => {
        const canReadWrite = UserService.canReadWrite(props.pageId);
        setCanReadWrite(canReadWrite);
    }
    const tabs = ['Todos'];

 /*   const hanldeClickTestAllys= async (event) =>{
       const session = getSessionCookie();

       const allys=["NEO","Coursera","LinkedIn","Crehana"];
       const body = {
           person_id: session.person_id,
           ally: allys[1],
           email: session.email
       }
       const response = await AllysConnctionService.connectAlly(body)
       log(response);
    }*/

    const hanldeClickNewUser = () => {
        props.history.push(`/usuarios/crear`);
    }

    const items = [
        { to: '/Home', label: 'Administrador' ,title:''},
        { to:'', label: 'Usuarios' , title:''}
       
      ];
    return (

        <>
         <Breadcrumb items={items} />
        <Box>
            <Tabs
                activeTab={0}
                tabs={tabs}
                //actions={(canReadWrite) && <NewBotton onClick={hanldeClickNewUser} label='Nuevo Usuario'/>}
            >
                <Card>
                    <CardContent>
                        { <UserTablePag canReadWrite={canReadWrite}/>}
                    </CardContent>
                </Card>
            </Tabs>
        </Box>
        </>
        )
}

export default UserManagement;