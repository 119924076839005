import React from 'react';

// core components
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';
import { Principal } from './Forms/Principal/Principal';
import { AddAddress } from './Forms/Address/AddAddress';

const AboutMe = ({ loading, about_me, nationalityCat, address, onChange, onSubmit, cardSaveBtn }) => {

    const [form, setForm] = React.useState('');
    const handleSetForm = (form) => {
        setForm(form);
        cardSaveBtn()
    };

    const handleValidate = (requiredFields) => {
        let error = false;
        if (requiredFields.length > 0) {
            for (const field of requiredFields) {
                let element = document.getElementById(field);
                if (element) {
                    if (element.value === "") {
                        error = true;
                        break;
                    }
                }
            }
        }
        return error;
    };

    const forms = (form) => {
        switch (form) {
            case 'address': // Dirección.
                return <AddAddress data={address} onSetForm={handleSetForm} onChange={onChange} validate={handleValidate} onSubmit={onSubmit} />
            default: // Forma por principal
                return <Principal data={about_me} nationalityCat={nationalityCat} xData={address} onSetForm={handleSetForm} onChange={onChange} validate={handleValidate} onSubmit={onSubmit} />
        }
    };

    return (
        <LoaderContent loading={loading}>
            {forms(form)}
        </LoaderContent>
    );
};

export default AboutMe;
