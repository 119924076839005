import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    main:{
        position: 'absolute !important',
        width:"100% !important",
        height:"100% !important",
        marginTop: "-8px",
        marginLeft: "-12px",
        backgroundColor: theme.palette.colors.fillStroke[400],
    },
    root: {
        position: 'relative',
        display: "flex",
        marginTop: "12%",
        justifyContent: "center",
        width:"100% !important"
    },
    contentText: { 
        position: 'static',
        display: "flex",
        justifyContent: "center",
        marginTop: '85px'
    },
    title: {
        width: '445px',
        height: '80px',
        marginTop: '200px',
    },
    divider:{
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "62px",
        marginBottom: "62px",
        backgroundColor: theme.palette.colors.grey[400],
        "@media (max-width: 445px)": {
            marginTop: "20px",
            marginBottom: "20px",
        },
    },
}));