import React, { Component } from 'react';
import { generatePath } from "react-router";

// @material-ui/core
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Card from '@material-ui/core/Card';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// shared core components
import Tabs from '../../../shared/components/Tabs/Tabs'
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import Table from '../../../shared/components/Table/Table';
import Picker from '../../../shared/components/Form/Picker';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';

import { log } from '../../../shared/utils/console.js'

import dateFormat from '../../../design-system/utils/dateFormat';
import {REQUEST_STATUS_ID} from '../../../shared/constants/request-status'

// core components
import RequestCard from './components/RequestCard/RequestCard'
import NewRequestItemButton from './components/NewRequestItemButton/NewRequestItemButton'

// @redux
import { connect } from "react-redux"
import * as requestActions from '../../../redux/actions/requestActions'
import * as routerlinks from '../../../routes/routelinks'

// style
import palette from '../../../design-system/theme/palette'

// icons
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
//SDK
import { RequestService } from '@sdk-point/talisis'; 

const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const REQUEST_STATUS_APPROVED = 5;
const style= {
    background: '#FEECED',
    border: '1px solid #F74F58',
    borderRadius: 4,
    color: '#EA0B16',
    padding: '4px 8px'
}

const requestsType = ['requests-as-authorizer', 'my-requests', 'request-archive'];
const REQUEST_TYPE_SENDING_DOCUMENTS = 1;
const REQUESTS_STATUS_FILTER = [
    {id: 0, label: 'Todos' },
    {id: REQUEST_STATUS_ID.PENDING, label: 'En Progreso' },
    {id: REQUEST_STATUS_ID.REVIEW, label: 'Pendientes' },
    {id: REQUEST_STATUS_ID.APPROVE, label: 'Aprobadas' },
]

function Actions({item, onClickShow, onClickArchive}){
        
    return <div>
        {/* <IconButton color="primary" aria-label="archive" onClick={onClickArchive(item)} style={{padding: 0, marginRight: 8}}>
            <ArchiveOutlinedIcon className="font-size-20"/>
        </IconButton> */}
        <IconButton color="primary" aria-label="show" onClick={onClickShow(item)} style={{padding: 0}}>
            <VisibilityOutlinedIcon className="font-size-20"/>
        </IconButton>
    </div>
}

class RequestsItems extends Component {

    constructor(props){
        super(props);
        this.state = {
            status: 0,
            loading: true,
            requests: [],
            selected:[],
            tabsProps: {
                tabs: ['Todos','Mis Solicitudes'],
                activeTab: 0
            },
            paginationProps:{
                page: 1,
                count: 1
            },
            totalRequestsLabel: "",
            dateFilter: null,
            tableColumns: [
                { name: 'folio', label: 'Folio'},
                { name: 'createdAt', label: 'Fecha', minWidth: 100, direction: 'desc', active: true, sortProp: 'created_at'},
                { name: 'title', label: 'Solicitud', minWidth: 100 },
                { name: 'activeStep', label: 'Paso', minWidth: 100 },
                { name: 'waitingFor', label: 'Seguimiento', minWidth: 100 },
                { name: 'status', label: 'Estatus', minWidth: 120, direction: 'desc', sortProp: 'status_id'},
                { name: 'actions', label: 'Acciones', align:"center"},
            ]
        }
    }

    componentDidMount(){
        this.getMyRequests('requests-as-authorizer', 1);
    }

    getMyRequests = async(type, page, month = null, year = null) =>{
        this.props.getPendingRequests(this.props.user.person_id);
        const sortBy = this.state.tableColumns.find(it => it.active);
        const sort = this.state.status ? this.state.status : `${sortBy.sortProp}-${sortBy.direction.toUpperCase()}` ;
        this.setState({
            loading: true
        })
        try {
            const data = await RequestServiceSDK.getMyRequests(this.props.user.person_id, page, type, month, year, sort);

            const requests = data.map(it => {
                let waitingFor = it.steps.filter(it => it.is_active)[0] || '';

                if(waitingFor){
                    waitingFor = waitingFor.authorizers.filter(a => a.status_id === REQUEST_STATUS_ID.REVIEW && a.authorizer_type_id === 1).map(it => it.full_name).join();
                }

                const status = it.pendingReview
                    ?   <Box style={style} textAlign="center">
                            <Typography color="inherit">Pendiente</Typography>
                        </Box>
                    :   <Box style={{color: palette.colors[it.color_name]}} textAlign="center">
                            <Typography color="inherit">{it.description}</Typography>
                        </Box>;
                
                return {
                    ...it,
                    folio: `#${String(it.id).padStart(6, '0')}`,
                    createdAt: dateFormat(it.created_at),
                    activeStep: `${it.status_id === REQUEST_STATUS_APPROVED ? it.totalSteps : it.activeStep} de ${it.totalSteps}`,
                    waitingFor,
                    status,
                    actions: <Actions item={it} onClickShow={this.handleClickShow} onClickArchive={this.handleClickInArchive}/>
                }
            });
            const total_count = requests.length ? requests[0].total_count : 0;

            const paginationProps = {
                page,
                count: requests.length ? Math.ceil(total_count / 30) : 1
            }
            const to = requests.length >= 30 ? paginationProps.page * 30 : total_count;
            const from = requests.length >= 30 ? to - 29 : (total_count - requests.length) + 1;

            const totalRequestsLabel = `Visualización ${requests.length ? from : 0} - ${to} de ${total_count} solicitudes`            
            this.setState({loading: false, requests, paginationProps, totalRequestsLabel})
        } catch (e) {
            log('error', e)
        }
    }

    handleClickShow = item => event => {
        const {id, request_type_id} = item;
        const firstStep = item.steps[0];
        const showPageSendindDocs = request_type_id === REQUEST_TYPE_SENDING_DOCUMENTS && item.activeStep === 1 && !firstStep.is_reassigned && (firstStep.authorizers[0].person_id === this.props.user.person_id);
        const routerLinkPage = showPageSendindDocs ? routerlinks.SOLICITUDES_CARGA_ARCHIVOS : routerlinks.SOLICITUDES_DETALLE;

        const url = generatePath(routerLinkPage, { id });
        
        if(request_type_id === REQUEST_TYPE_SENDING_DOCUMENTS && !showPageSendindDocs){
            window.open(url, '_blank');
        }else{
            this.props.history.push(url);
        }
    }

    hanldeClickStart = async (options) => {
        const {request, campus} = options;
        const scope = campus ? `${this.props.user.company_id}-${campus}`: this.props.user.company_id;

        this.setState({
            ...this.state, 
            loading: true
        });

        const body = {
            "request_id": request,
            "person_id": this.props.user.person_id,
            scope
        }

        try {
           // log('body', body)
            await RequestServiceSDK.createRequestWorkflow(body);
            this.setState({
                tabsProps: {
                    tabs: ['Todos','Mis Solicitudes'],
                    activeTab: 0
                },
            });
        //    this.props.getPendingRequests(this.props.user.person_id);
           this.getMyRequests(requestsType[0], 1);
        } catch (e) {
            log('error', e)
        }
    };

    handleChangeTab = activeTab => {
        this.setState({            
            loading: true,
            requests: [],
            selected:[],
            tabsProps: {
                tabs: ['Todos','Mis Solicitudes'],
                activeTab
            },
            paginationProps:{
                page: 1,
                count: 1
            },
            totalRequestsLabel: "",
            dateFilter: null,
            tableColumns: [
                { name: 'folio', label: 'Folio'},
                { name: 'createdAt', label: 'Fecha', minWidth: 100, sort: true, direction: 'desc', active: true, sortProp: 'created_at'},
                { name: 'title', label: 'Solicitud', minWidth: 100 },
                { name: 'activeStep', label: 'Paso', minWidth: 100 },
                { name: 'waitingFor', label: 'Seguimiento', minWidth: 100 },
                { name: 'status', label: 'Estatus', minWidth: 120, sort: true, direction: 'desc', sortProp: 'status_id'},
                { name: 'actions', label: 'Acciones', align:"center"},
            ]
        });

       this.getMyRequests(requestsType[activeTab], 1);
    };

    handleChangePage = (event, page) => {
        const {paginationProps, tabsProps} = this.state;

        this.setState({
            paginationProps:{
                ...paginationProps,
                page
            }
        });
        this.getMyRequests(requestsType[tabsProps.activeTab], page);
    }

    handleChangeDateFilter = (date) => {
        const {target: {value}} = date;
        let month = null;
        let year = null;

        if(value){
            const dateFilter = new Date(value);
            month = dateFilter.getMonth() + 1;
            year = dateFilter.getFullYear();
        }

        this.setState({
            ...this.state,
            dateFilter: value
        });
        this.getMyRequests(requestsType[this.state.tabsProps.activeTab], 1, month, year);
    }

    handleClickSort = (colum) => {
        colum.direction = colum.direction === 'desc' ? 'asc' : 'desc';

        const tableColumns = this.state.tableColumns;

        tableColumns.map(it => {
            if(it.sort){
                it.active = colum.name === it.name;
                it.direction = colum.name === it.name ? colum.direction : 'desc'
            }
        })

        this.setState({
            ...this.state,
            tableColumns
        })

        this.handleChangeDateFilter({target: {value: this.state.dateFilter}})
    }

    handleClickInfoTable = (data) => {
        this.setState({
            ...this.state,
            selected: data
        })
    }

    handleClickArchive = async () => {
        this.setState({
            ...this.state, 
            loading: true
        });

        const body = {
            "selectedArchive": this.state.selected,
        }

        try {
            if (this.state.tabsProps.activeTab == 2){

                await RequestServiceSDK.deleteRequestSelectedArchive(body, this.props.user.person_id);

            }else{
                await RequestServiceSDK.createRequestSelectedArchive(body, this.props.user.person_id);
            }
           this.getMyRequests(requestsType[this.state.tabsProps.activeTab], 1);
        } catch (e) {
            log('error', e)
        }
    }

    handleClickInArchive = it => async event => {
        this.setState({
            ...this.state, 
            loading: true
        });

        let selectedItem = [];
        selectedItem.push(it)

        const body = {
            "selectedArchive": selectedItem,
        }

        try {
            if (this.state.tabsProps.activeTab == 2){

                await RequestServiceSDK.deleteRequestSelectedArchive(body, this.props.user.person_id);

            }else{
                await RequestServiceSDK.createRequestSelectedArchive(body, this.props.user.person_id);
            }
            const requests = this.state.requests;
            const index = requests.findIndex(r => r.id === selectedItem[0].id);

            requests.splice(index, 1);

            const total_count = requests.length ? requests[0].total_count : 0;
            const paginationProps = {
                page: 1,
                count: requests.length ? Math.ceil(total_count / 10) : 1
            }
            const to = requests.length >= 10 ? paginationProps.page * 10 : total_count;
            const from = requests.length >= 10 ? to - 9 : (total_count - requests.length) + 1;

            const totalRequestsLabel = `Visualización ${requests.length ? from : 0} - ${to} de ${total_count} solicitudes`
            

            this.setState({loading: false, requests, paginationProps, totalRequestsLabel})
            // this.getMyRequests(requestsType[this.state.tabsProps.activeTab], 1);
        } catch (e) {
            log('error', e)
        }
    }

    handleClickStatus = opt =>{
        this.setState({
              ...this.state,
              status: opt.id
        },() => {
            this.getMyRequests('requests-as-authorizer', 1);
        });
    }
    

    render() {
        const {loading, requests,selected, tabsProps, paginationProps, totalRequestsLabel, dateFilter, tableColumns} = this.state;
        return (
            <Box mb={5}>
                <Breadcrumb items={[
                    {label: 'Trámites'}
                ]} />
                <Tabs
                    onChange={this.handleChangeTab}
                    /* actions={
                        <NewRequestItemButton
                            ou={this.props.user.company_id} 
                            onClickNext={this.hanldeClickStart}/>
                    } */
                    {...tabsProps}
                >
                    <Grid container  spacing={3}>
                        {/* <Grid item xs={12} md={12}>
                            <Box display="flex" justifyContent="flex-end" alignItems="center">
                                <Button color="primary" variant="outlined" 
                                    onClick={this.handleClickArchive} 
                                    disabled={loading || !selected.length}
                                    >
                                        {
                                            tabsProps.activeTab == 2 ? 'Quitar Archivado' : 'Archivado'
                                        }
                                        
                                </Button>
                            </Box> 
                        </Grid> */}
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" gridGap={16} mb={2}>
                                <Box minWidth={90}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                    Filtrar por 
                                    </Typography>
                                </Box>
                                <Picker 
                                    className="mt-0"
                                    disabled={loading}
                                    name="dateFilter"
                                    value={dateFilter}
                                    onChange={this.handleChangeDateFilter}
                                    openTo="month"
                                    views={["year", "month"]}
                                    format="MMMM YYYY"
                                    placeholder="MES / AÑO"
                                    clearable
                                    autoOk={false}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <TodayIcon color="primary"/>
                                        </InputAdornment>)
                                    }}
                                />
                                <Box>
                                    <Dropdown 
                                        options={REQUESTS_STATUS_FILTER} 
                                        onClickOption={this.handleClickStatus}
                                        text="Estatus"
                                        endIcon={<ArrowDropDownIcon className='font-size-24'/>}
                                        variant='contained'
                                        className="bg-fill-stroke-300"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Card variant="outlined">
                        <LoaderContent loading={loading} minHeight={200}>
                            <Table 
                                columns={tableColumns}
                                rows={requests}
                                loading={loading}
                                checkboxs={false}
                                handleClickInfoTable={this.handleClickInfoTable}
                                onClickSort={this.handleClickSort}
                                selectedArchive={selected}
                                maxHeight="100vh"
                            />
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="caption">{totalRequestsLabel}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Pagination shape="rounded" onChange={this.handleChangePage} {...paginationProps}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </LoaderContent>
                    </Card>
                </Tabs>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({...state.userReducer});
const mapDispatchToProps = {...requestActions};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsItems);