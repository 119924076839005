import React from 'react';
import PropTypes from 'prop-types';

// components
import MembershipPlanCard from '../MembershipPlans/MembershipPlanCard/MembershipPlanCard';

// shared components
import { getPreviousMembership } from '../../../shared/utils/membershipUtils';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss';

const MembershipCarousel = (props) => {
    const { memberships, membershipLevel, feePlan, onChangeIndex, isResponsive, user } = props;

    return (
        <Swiper
            spaceBetween={16}
            slidesPerView={1.2}
            onSlideChange={(swiper) => onChangeIndex(swiper.realIndex)}
        >
            {memberships.map((membership, index) => {
                return (
                    <SwiperSlide key={index}>
                        <MembershipPlanCard
                            id={membership.slug}
                            name={membership.name}
                            popular="professional"
                            slideMode
                            fees={membership.fees}
                            membershipId={membership.id}
                            hideButton={membership.level >= membershipLevel ? false : true}
                            feePlan={feePlan}
                            media={{
                                showIcon: false,
                                fee: membership?.fees?.filter(fee => fee.term_slug === feePlan)[0]
                            }}
                            content={{
                                previous: getPreviousMembership(membership, memberships)
                            }}
                            isResponsive={isResponsive}
                            user={user}
                        />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    );
};

MembershipCarousel.defaultProps = {
    isResponsive: false,
};

MembershipCarousel.propTypes = {
    feePlan: PropTypes.string,
    isResponsive: PropTypes.bool,
    memberships: PropTypes.arrayOf(PropTypes.object),
    pageState: PropTypes.any,
    onChangeIndex: PropTypes.func,
    user: PropTypes.object,
};

export default MembershipCarousel;