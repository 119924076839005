import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    homeRoot: {
        marginBottom: props => props.isResponsive ? '80px' : '40px',
        justifyContent: 'center',
    },
    componentContainer: {
        width: '100%',
    },
    menuContainer: {
        width: '100%',
    }
}));
