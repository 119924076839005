import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootPriceAndMembership: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('xs')]:{
            display: 'flex'
        }
        // borderTop: `1px solid ${theme.palette.colors.fillStroke[200]}`,
    },
    freeCourseContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    freeCourseLabel: {
        color: theme.palette.colors.gray[400],
    },
    otherMembershipContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]:{
            flexWrap: 'wrap',
            alignItems:'center',
        }
    },
    otherMembershipLabelContainer: {
        width: '90%'
    },
    otherMembershipLabel: {
        color: theme.palette.colors.darkBlack[200],
    },
    otherMembershipIconContainer: {
        width: '10%'
    },
    otherMembershipIcon: {
        color: theme.palette.colors.darkBlack[200],
    },
    priceContainer: {
        width: '100%',
        height: '100%',
        // padding: props => props.isResponsive ? '0 16px' : '9px 16px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    discountLabel: {
        color: theme.palette.colors.error[300],
        [theme.breakpoints.down('xs')]:{
            fontSize: '10px !important'
        }
    },
    listNumberLabel: {
        textDecorationLine: 'line-through',
        color: theme.palette.colors.darkBlack[200],
        [theme.breakpoints.down('xs')]:{
            fontSize: '10px !important'
        }
    },
    numberLabel: {
        color: theme.palette.colors.orange[300],
        textAlign: 'end'
    },
}));