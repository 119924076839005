import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { log } from '../../../../shared/utils/console.js'

// MUI
import Fab from '@material-ui/core/Fab';
import {
  Avatar,
  Badge,
  Box,
  Button, Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

import clsx from 'clsx';

//components
import SelectCategory from './SelectCategory';
import SelectTopic from './SelectTopic';
import WriteTicket from './WriteTicket';
import TicketConfirmation from './TicketConfirmation';

//SDK
import { HelpCenterService } from '@sdk-point/talisis';


const HelpCenterServiceSDK = new HelpCenterService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return ({
    commentsHolder: {
      padding: 16,
      paddingBottom: 0
    },
    ticketComments: {
      maxHeight:'300px',
      overflowY: 'auto'
    },
    ticketCommentListItem: {
      backgroundColor: theme.palette.colors.fillStroke[400],
      padding: 0,
      marginTop: 16,
      borderRadius: '4px'
    },
    goToTicketButton: {
      letterSpacing: 0,
      fontSize: '12px',
      float: 'right',
      textDecoration: 'none !important'
    },
    goToTicketIcon: {
      fontSize: '12px !important',
      fontWeight: 300
    },
    ticketCommentContent: {
      padding: '16px !important'
    },
    supportDivider: {
      backgroundColor: theme.palette.colors.fillStroke[300],
      marginBottom: '16px'
    },
    commentSender: {
      fontSize: '12px',
      color: theme.palette.colors.blue[300],
    },
    commentMessage: {
      fontSize: '12px'
    },
    commentText: {
      fontSize: '14px'
    },
    writeTicketTextBox: {
      marginTop: '8px',
      '& div': {
        backgroundColor: theme.palette.colors.fillStroke[300],
        padding: '16px'
      },
      '& :hover': {
        backgroundColor: theme.palette.colors.fillStroke[300],
        transition: 'none'
      },
      '& :focus': {
        backgroundColor: theme.palette.colors.fillStroke[300],
        transition: 'none'
      },
      '& :focus-within': {
        backgroundColor: theme.palette.colors.fillStroke[300],
      }
    },
    attachmentsIcon: {
      fontSize: '24px',
      color: theme.palette.colors.white
    },
    attachmentsIconButton: {
      padding: '0px'
    },
    fileButton: {
      width: 64,
      height: 64,
      backgroundColor: theme.palette.colors.fillStroke[300],
      color: theme.palette.colors.white
    },
    fileContainer: {
      width: '48px',
      display: 'inline-grid',
      height: '64px',
      overflow: 'hidden',
      marginLeft: '8px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    fileIcon: {
      width: '48px',
      height: '48px',
      backgroundColor: theme.palette.colors.orange[300],
      fontSize: '24px',
      padding: '12px',
      lineHeight: 'initial'
    },
    fileName: {
      fontSize: '10px',
      color: theme.palette.colors.orange[300],
      lineHeight: 'unset',
      textOverflow: 'ellipsis',
    },
    ticketText: {
      fontSize: '14px',
      lineHeight: '16px'
    },
  })
});

const TicketComments = (props) => {
  const {
    zendeskUser,
    selectedTicket,
    ticketComments,
    setAttachments,
    setMessage,
    attachments,
    message,
    setChangeToSend,
    currentStep,
    handleNextStepTicketList,
    disableSend,
    setDisableSend,
    LoaderContent,
    loading,
    setLoading
  } = props

  const [showAttachmets, setShowAttachmets] = useState(false)
  

  useEffect(() => {
    log(currentStep)
    setChangeToSend(true)
  }, [])

  const handleFileUpload = async (e) => {
    setLoading(true)
    const uploadFile = await HelpCenterServiceSDK.uploadFile(e.target.files);
    setAttachments([...attachments, uploadFile])
    setLoading(false)
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
    const message = e.target.value
    if(message.length && message.length > 0) {
      setDisableSend(false)
    } else {
      setDisableSend(true)
    }
  }

  const toggleAttachments = () => {
    setShowAttachmets(!showAttachmets)
  }

  const classes = useStyles();
  return (
    <LoaderContent loading={loading}>
      <Box className={classes.commentsHolder}>
        <Grid container>
          <Grid item xs={6}>
            <Typography>Folio #{selectedTicket}</Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Button color='primary' className={classes.goToTicketButton} endIcon={<i className={clsx(classes.goToTicketIcon, 'ri-arrow-right-line')} />}>Ver detalle del ticket</Button>
          </Grid> */}
        </Grid>
        <Divider className={classes.supportDivider} />
        <Typography>Comentarios:</Typography>
        <Box className={classes.ticketComments}>
          {ticketComments &&
            ticketComments.map(comment => (
              <Card key={comment.id} role={undefined} className={classes.ticketCommentListItem}>
                <CardContent className={classes.ticketCommentContent} >
                  <Typography className={classes.commentSender}>{comment.author_id === zendeskUser.id ? 'Tú' : 'Soporte Talisis'}</Typography>
                  <Box className={classes.commentMessage}>
                    <Typography className={classes.commentText}>{comment.plain_body}</Typography>
                  </Box>
                </CardContent>
              </Card>
            ))
          }
        </Box>
        <TextField
          multiline
          placeholder='Descripción'
          variant='filled'
          minRows={1}
          fullWidth
          value={message}
          className={classes.writeTicketTextBox}
          InputProps={{
            disableUnderline: true,
            endAdornment: <IconButton onClick={toggleAttachments} className={classes.attachmentsIconButton}><i className={clsx(classes.attachmentsIcon, 'ri-attachment-line')} /></IconButton>
          }}
          onChange={handleMessageChange}
        />
        {
          showAttachmets &&
          <Box mt={1}>
            <Typography className={classes.ticketText}>Adjuntar archivos</Typography>
            <Box mt={1}>
              <IconButton
                variant='contained'
                component='label'
                className={classes.fileButton}
                style={{ borderRadius: 0 }}
              >
                <i className={'ri-add-line'}></i>
                <input type='file' hidden onChange={handleFileUpload} />
              </IconButton>
              {
                attachments.map(file => (
                  <Box className={classes.fileContainer}>
                    <Box className={classes.fileIcon}>
                      <i className={'ri-image-fill'}></i>
                    </Box>
                    <Typography className={classes.fileName}>{file.file_name}</Typography>
                  </Box>
                ))
              }
            </Box>
          </Box>
        }
      </Box>
    </LoaderContent>
  )
};

TicketComments.defaultProps = {
  isHome: false,
  isCheckout: false,
};

TicketComments.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};


export default TicketComments;
