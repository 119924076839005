import React from 'react';
import clsx from 'clsx';

// Material components
import { Box, Button, Chip, Hidden, Icon, LinearProgress, Typography, useMediaQuery } from '@material-ui/core';
import useStyles from '../../../assets/styles/components/cards/listItemCourseInProgress/listItemCourseInProgressStyle';


const PROGRAM_ASYNC = 'Asincrónico';
const STATUS_ACORDION_FINISHED = 0;
const STATUS_ACORDION_IN_COURSE = 1;
const STATUS_ACORDION_PENDING = 2;

const ListItemCourseInProgress = ({name, status, icon_modality, schedule_days, schedule_hrs, logoCard, logoCardSize, level_id, textButtonDisabled, textButtonFunction1, textButtonFunction2,
                            progress_percentage, modality, credits, type_program, grade, image_url, disabled, className, handleFunction1, handleFunction2, course, califFinal}) => {
    
    const classes = useStyles({disabled, logoCardSize});
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const buttonOrTag = () => { 
        return( 
            <Box id='button-container' className={classes.statusAccordion}>
                {STATUS_ACORDION_FINISHED === status && (
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Icon className={clsx("ri-checkbox-circle-fill",classes.finishedIconStyle)} />
                    <Typography variant='h5' className={classes.finishedLabelStyle}> Calificación final: {califFinal}</Typography>
                </Box>
                )}
            
                {STATUS_ACORDION_PENDING === status && type_program === PROGRAM_ASYNC && credits > 0 && disabled !== 1 && (
                <>
                    <Button color='primary' variant='contained' onClick={(e) => handleFunction1(e, course)}>
                    {textButtonFunction1}
                    </Button>
                    <Button color='primary' onClick={(e) => handleFunction2(e, course)}>
                    {textButtonFunction2}
                    </Button>
                </>
                )}
            
                {STATUS_ACORDION_PENDING === status && type_program === PROGRAM_ASYNC && disabled === 1 && (
                <Box className={classes.boxProximamente}>
                    <Typography variant="overline" className={clsx(classes.proximamenteStyle, "semi-bold")}>
                    {textButtonDisabled}
                    </Typography>
                </Box>
                )}
            
                {STATUS_ACORDION_IN_COURSE === status && type_program === PROGRAM_ASYNC && (
                <Button color='primary' onClick={(e) => handleFunction2(e, course)}>
                    {textButtonFunction2}
                </Button>
                )}
            </Box>
    )}

    return (
        <>
          <Box id='root-list-in-progress' className={classes.cardListStyle}>
            <Box id='img-container' className={classes.imgContainer}>
                <img className={classes.img} alt="imagen-curso" src={image_url} />
                <Hidden smUp>
                    <Chip 
                        style={{gap: 10}}
                        label={modality} 
                        icon={<img alt="live_tv_outlined_icon" src={icon_modality} />}
                        className={clsx('chip7-fs-400',classes.chipImg, classes.chipIcon)}
                    />
                </Hidden>
            </Box>
            <Box id='info-container' className={classes.infoStyle}>
                <Hidden smUp>
                    <Box className={classes.buttonOrTagContainer}>
                        {buttonOrTag()}
                    </Box>
                </Hidden>
                <Hidden smUp>
                    {(STATUS_ACORDION_IN_COURSE === status) &&
                        <LinearProgress variant="determinate" className={classes.linearProgress} value={progress_percentage} />
                    }
                </Hidden>
                <Box className={classes.titleContainer}>
                    <Typography variant={isResponsive ? 'body2' : 'body1'} className={clsx('semi-bold', classes.titleColor)}>{name}</Typography>
                    <Hidden smDown>
                        {(STATUS_ACORDION_IN_COURSE === status) &&
                            <LinearProgress variant="determinate" className={classes.linearProgress} value={progress_percentage} />
                        }
                    </Hidden>
                </Box>
                <Box className={classes.companyInfo}>
                    <img alt="logo" loading="lazy" src={logoCard} className={classes.logoCardStyle} />
                    <Hidden smDown>
                        <Chip 
                        label={modality} 
                        className={clsx('chip7-fs-400',classes.chipIcon)}
                        style={{gap: 10}}
                        icon={<img alt="live_tv_outlined_icon" src={icon_modality} />}
                        />
                    </Hidden>
                    {(schedule_days && progress_percentage != 100) ? 
                    <>
                        <Icon className={disabled ? "ri-calendar-event-line text-blue-500" : "ri-calendar-event-line text-blue-300"} />
                        <Typography component="span" className={disabled ? "text-blue-500 font-size-12 font-weight-500 mr-1" : "text-blue-300 font-size-12 font-weight-500 mr-1"} style={{ marginLeft: 5 }}>{schedule_days}</Typography>
                    </> : 
                    <Box>
                        <Chip label={level_id} className={clsx(classes.labelDisabled,'chip3-blue-300-inverted')}></Chip>
                    </Box>
                    }
                </Box>
                {
                    disabled !== 1 &&
                    <>
                    {
                        (schedule_hrs && progress_percentage != 100) &&
                        <>
                            <Icon className="ri-time-line text-blue-300" />
                            <Typography component="span" className="text-blue-300 font-size-12 font-weight-500 mr-1" style={{ marginLeft: 5 }}>{schedule_hrs}</Typography>
                        </>
                    }
                    </>
                }
            </Box>
                <Hidden smDown>
                    {buttonOrTag()}
                </Hidden>
          </Box>  
        </>
    );
};

export default ListItemCourseInProgress;