import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string'
// redux
import { connect, useDispatch, useSelector } from 'react-redux';

// @material-ui/core
import { checkoutNewStyles } from './checkoutNewStyles';
import { Box, Typography, CircularProgress} from '@material-ui/core';

// SDK
import ConektaPayments from './ConektaPayments/ConektaPaymentsNew';
import { MarketPlaceService, ProgramService } from '@sdk-point/talisis';
import { getCheckoutCookie, setCheckoutCookie } from '../../../shared/utils/checkoutSession-legacy.js';
import CheckoutError from './CheckoutError/CheckoutError';
import { getOrderQuantityOff, verifyPhone } from '../../../shared/utils/checkoutHelpers-legacy.js';
import CheckoutPhoneError from './CheckoutPhoneError/CheckoutPhoneError';
import { isValidPhoneNumber } from '../../../shared/utils/isValidPhoneNumber';
import { setCurrentUserMembership, setMembershipOrderFeePlans, setMonthlyPaymentsInfo, setOrderNextPaymentDate, setOrderPendingDiscount, setPersonInfo, setPurchaseOrder, toggleMissingPhone } from '../../../redux/actions/legacyCheckoutActions.js';
import CheckoutMaintenancePage from './CheckoutMaintenancePage/CheckoutMaintenancePage';

const CheckoutNew = () => {

    const location = useLocation();
    const checkoutCookie = getCheckoutCookie();
    const classes = checkoutNewStyles();

    // Checkout Reducer
    const { missingPhone: missingPhoneReducer } = useSelector((state) => state.legacyCheckoutReducer);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [tokenCheckout, setTokenCheckout] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [feePlans,setFeePlans]= useState([]);

    const [phone, setPhone]= useState(checkoutCookie?.personInfo?.phone_number || '');
    const [missingPhone, setMissingPhone] = useState(false);
    const [folio, setFolio] = useState('');
    
    const [productIncluded,setProductIncluded] = useState([]);
    const [amountOff,setAmountOff] = useState(0);
    const [percentOff,setPercentOff] = useState("");
    const [nextPaymentDate, setNextPaymentDate] = useState("");
    const [isCustomMembership,setIsCustomMembership]=useState(false);
    const [customMembershipData,setCustomMembershipData]=useState(null);
    
    const [daysToNextPayment, setDaysToNextPayment] = useState(0);
    

    const [order, setOrder] = useState({
        subtotal: 0.0,
        total: 0.0,
        conceptos: [],
    });

    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
    const programService = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
    
    const checkoutGlobal = async () => {
        setSuccess(false);
        setLoading(true);
        const params = queryString.parse(location.search)
        let dataToken= null;
        if(params?.token){
            location['state'] = {referrer: "course-landingpage"}
            try { 
                const decodeToken = await marketService.decodeCheckoutToken(params.token);
                dataToken = {
                    items: [
                        {
                        "membership_info" : {
                             "membershipId": decodeToken.membership_id,
                             "membershipFee": decodeToken.membership_fee_id
                            }
                        }
                    ],
                    person_id: decodeToken.person_id 
                }
                setTokenCheckout(dataToken)
            } catch(e){
                console.log('decode dataToken', e);
            }
        }
        const usuarioAnonimo = 0;
        try {
            if(location?.state?.referrer === "checkout-login" || location?.state?.referrer === "checkout-login-SSO"){
                
                const responseUpdateOrder = await marketService.registerUpdateOrder({ person_id: 0 },{
                    orderId: checkoutCookie.orderId,
                    first_name: checkoutCookie.personInfo.firstName,
                    last_name: checkoutCookie.personInfo.lastName,
                    email: checkoutCookie.personInfo.email,
                    phone: checkoutCookie.personInfo.phone_number,
                    location: null,
                    sso: false,
                    isLogin: true
                });

                const items = checkoutCookie.items ? [...checkoutCookie.items] : [];
                const responseCreateOrder = await marketService.createOrder({ person_id: responseUpdateOrder.personId }, {
                    'items': items,
                    'order_id': responseUpdateOrder.order_id
                });
                
                if (responseCreateOrder.success === true) {
                    
                    if(responseCreateOrder.order?.pending_discount && responseCreateOrder.order?.pending_discount > 0){
                        addPendingDiscount(responseCreateOrder.order.pending_discount);
                    }
                    setSuccess(true);
                    
                    dispatch(setPurchaseOrder(responseCreateOrder.order))
                    let tempoFolio=responseCreateOrder.order.id+"";
                    setFolio(tempoFolio.padStart(8,"0"));
                    if(responseCreateOrder.order.conceptos[0].order_detail_type_id===2)
                    {
                        const fee = await programService.getProgramFeePlans(responseCreateOrder.order.conceptos[0].program_id);
                        
                        setFeePlans(fee);
                    }
                }
                setCheckoutCookie({
                    ...checkoutCookie,
                    checkoutStep: 1
                })
                setSuccess(true);
                setLoading(false);
            }

            if((location.state.referrer === "course-landingpage") || (location.state.referrer !== "checkout-login" || "checkout-login-SSO")){
                let checkedOrderId;
                checkedOrderId = ( checkoutCookie?.orderId > 0 ) ? checkoutCookie?.orderId : null;
                let currentPerson;
                currentPerson =  ( checkoutCookie?.personId > 0 ) ? checkoutCookie?.personId : (dataToken?.person_id) ? dataToken?.person_id: 0; 
                const items = checkoutCookie.items ? [...checkoutCookie.items] : dataToken?.items ? [...dataToken?.items]:[];
                const isRepurchase = checkoutCookie?.isRepurchase ? checkoutCookie?.isRepurchase : false;
                const paymentToken = checkoutCookie?.paymentToken ? checkoutCookie?.paymentToken : null;
                const admissionId = checkoutCookie?.admissionId ? checkoutCookie?.admissionId : null;
                const response = await marketService.createOrder({ person_id: currentPerson }, {
                    'items': items,
                    'order_id': checkedOrderId,
                    'is_repurchase': isRepurchase,
                    'payment_token': paymentToken,
                    'admission_id': admissionId,
                });
                if (response.success === true) {
                    
                    if(response.order?.pending_discount && response.order?.pending_discount > 0){
                        addPendingDiscount(response.order.pending_discount);
                    }
                    
                    setSuccess(true);
                    
                    dispatch(setPurchaseOrder(response.order))
                    dispatch(setPersonInfo(response.personInfo))
                    let tempoFolio=response.order.id+"";
                    setFolio(tempoFolio.padStart(8,"0"));

                    if(checkoutCookie.membershipSession){
                        const { membership_info: selectedPlan } = checkoutCookie.items.find(( item ) => item.membership_info.membershipFee );
                        const { memberships,user_membership } = await marketService.getPersonMembership(currentPerson);
                        
                        setIsCustomMembership(user_membership.is_custom_membership);
                        setCustomMembershipData(user_membership.custom_membership_data);
                    
                        const currentMembership = response?.order?.conceptos.find( concepto => concepto.membership_id)
                        
                        dispatch(setCurrentUserMembership(user_membership))
                        const { fees } = memberships.find((membreshipPlan) => membreshipPlan.id === currentMembership?.membership_id);
                        
                        let currentSelectedPlan = fees.find( plan => plan.id == selectedPlan.membershipFee)
                        if(!currentSelectedPlan){
                             currentSelectedPlan = fees.find( plan => plan.id == selectedPlan.membershipId)
                        }
                        
                        addLabelNextPaymentDay(currentSelectedPlan);
                        addNewDifferenceAmountFees(currentSelectedPlan, fees);

                    }
                    else {
                        const { memberships,user_membership } = await marketService.getPersonMembership(currentPerson);

                        setIsCustomMembership(user_membership.is_custom_membership);
                        setCustomMembershipData(user_membership.custom_membership_data);

                        dispatch(setCurrentUserMembership(user_membership))

                        let indexProdcut = response?.order?.conceptos.length > 1 ? 1 : 0;
                        let membershipFeeSelected = response?.order?.conceptos[indexProdcut].membership_fee_id;
                        let membershipIdElected = response?.order?.conceptos[indexProdcut].membership_id ;
                        if (membershipIdElected) {
                            const { fees } = memberships.find((membreshipPlan) => membreshipPlan.id == membershipIdElected);

                            let currentSelectedPlan = fees.find( plan => plan.id == membershipFeeSelected)
                            if(!currentSelectedPlan){
                                currentSelectedPlan = fees.find( plan => plan.id == membershipIdElected)
                            }
                            
                            addLabelNextPaymentDay(currentSelectedPlan);
                            addNewDifferenceAmountFees(currentSelectedPlan, fees);
                        }
                    }
                    if( currentPerson > usuarioAnonimo ){
                        if(params?.token) {
                            response.personInfo["activateAccountURL"] = checkoutCookie?.personInfo?.activateAccountURL}                     
                        if( isValidPhoneNumber( await response?.personInfo?.phone_number).error ){
                            dispatch(toggleMissingPhone(true))
                            setCheckoutCookie({
                                ...checkoutCookie,
                                anonymousSession: false,
                                orderId: response?.order?.id,
                                personInfo: (response?.personInfo?.length > 0) ? response?.personInfo?.[0] : response?.personInfo
                            })
                        }
                        if( !isValidPhoneNumber( await response?.personInfo?.phone_number).error ){
                            setCheckoutCookie({
                                ...checkoutCookie,
                                anonymousSession: false,
                                orderId: response?.order?.id,
                                personInfo: (response?.personInfo?.length > 0) ? response?.personInfo?.[0] : response?.personInfo
                            })
                        }
                    }
    
                    if( currentPerson === usuarioAnonimo ){
                        setCheckoutCookie({
                            ...checkoutCookie, 
                            orderId: response?.order?.id
                        })
                    }
    
                }
                else {
                    setError(response?.message);
                }
    
                setLoading(false);
            }
        }
        catch (e) {
            setLoading(false);
            setError(e?.message);
        }
    };

    const checkout = () => checkoutGlobal()

    useEffect(() => {
        //checkout();
    }, [missingPhone]);

    const addPendingDiscount = (amount) => {
        
        dispatch(setOrderPendingDiscount(amount))
    }
    const addDaysToNextPayment = (days) => {
        setDaysToNextPayment(days);
    }

    const addLabelNextPaymentDay = (currentSelectedPlan) =>{
        let toDate = "";
        toDate = addMonthsToDate(currentSelectedPlan.term_months);
        let farmatdate = "";
        farmatdate = getFormatdateNoH(toDate);
        setNextPaymentDate(farmatdate);
        dispatch(setOrderNextPaymentDate(farmatdate));
    }
    
    const addMonthsToDate = (months) => {
        let date = new Date();
        date.setMonth(date.getMonth() + months);
        return date;
    }

    const getFormatdateNoH = (date = new Date().toDateString) => {
        var opciones = { year: 'numeric', month: 'long', day: 'numeric' };
        var fecha = new Date(date)
            .toLocaleDateString('es', opciones)
            .replace('.', '')
            .replace(/-([a-z])/, function (x) { return '-' + x[1].toUpperCase() });
        return fecha;
    };

    const addNewDifferenceAmountFees = (currentSelectedPlan, fees) =>{
        
        dispatch(setMembershipOrderFeePlans({ membershipPlans: fees, selectedPlan: currentSelectedPlan }));

        if (fees.length > 1) {
            const availableMonths = fees.map(({ term_months }) => term_months);
            const monthlyPaymentsInfo = {
                enabled: (currentSelectedPlan?.term_months !== 1),
                selectedNumberOfPayments: 1,
                available_months: availableMonths
            };
            dispatch(setMonthlyPaymentsInfo(monthlyPaymentsInfo))
        }
        
        let quantityOff = getOrderQuantityOff(fees, currentSelectedPlan);
        setAmountOff(quantityOff);
    }

    return <CheckoutMaintenancePage />

    return (
        <Box>
            {
                loading && 
                    <Box textAlign="center" position="absolute" left={0} right={0} top="35%">
                        <CircularProgress />
                        <Typography><b>Espere un momento por favor...</b></Typography>
                    </Box>
            }
            {
                // (missingPhone && !loading) &&
                (missingPhoneReducer && !loading) &&
                <CheckoutPhoneError
                    phone={phone} setPhone={setPhone} currentPerson={checkoutCookie.personId}
                    setMissingPhone={setMissingPhone} successFunc={verifyPhone}
                />
            }
            {
                // (!loading && success && !missingPhone) &&
                (!loading && success && !missingPhoneReducer) &&
                    <Box hidden={!loading && !success}>
                        {/* <ConektaPayments 
                            user={checkoutCookie.personInfo} pendingDiscount={pendingDiscount} daysToNextPayment={daysToNextPayment} addPendingDiscount={addPendingDiscount} addDaysToNextPayment={addDaysToNextPayment} order={order} nextPaymentDate={nextPaymentDate}  addLabelNextPaymentDay={addLabelNextPaymentDay}  percentOff={percentOff} amountOff={amountOff} addNewDifferenceAmountFees={addNewDifferenceAmountFees}
                            setOrderRef={setOrder} classes={classes} productIncluded={productIncluded} membershipFeePlans={membershipFeePlans} userCurrentMembership={userCurrentMembership}
                        /> */}
                        <ConektaPayments 
                            user={checkoutCookie.personInfo} daysToNextPayment={daysToNextPayment} 
                            addPendingDiscount={addPendingDiscount} addDaysToNextPayment={addDaysToNextPayment} nextPaymentDate={nextPaymentDate}  
                            addLabelNextPaymentDay={addLabelNextPaymentDay}  percentOff={percentOff}  addNewDifferenceAmountFees={addNewDifferenceAmountFees}
                            // amountOff={amountOff} setOrderRef={setOrder} productIncluded={productIncluded} membershipFeePlans={membershipFeePlans}
                            amountOff={amountOff} setOrderRef={setOrder} productIncluded={productIncluded} isCustomMembership={isCustomMembership} customMembershipData={customMembershipData}
                        />
                    </Box>
            }
            {
                // (!loading && !success && !missingPhone) &&
                (!loading && !success && !missingPhoneReducer) &&
                    <CheckoutError error={error} tryAgainFunc={checkout} />
            }
        </Box>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CheckoutNew);
