import React from 'react';
import clsx from 'clsx';
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { generatePath } from "react-router";
import { Link as RouterLink } from 'react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

// utils
import * as sessions from '../../../../shared/utils/Sessions';
import * as links from '../../../../routes/routelinks';
import { convertOuToSource } from '../../../../../src/constants/convertOuToSource';
import getConnectionAllies from '../../../../shared/components/Allies/AlliesConnect'
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';
import { getSessionCookie } from '../../../../shared/utils/Sessions'
import { getCheckoutCookie } from '../../../../shared/utils/checkoutSession-legacy.js'

import * as paths from '../../../../routes/paths'

// styles
import useStyles from '../../../../assets/styles/jss/pages/market/PaymentConfirmation';

// assets
import UNID_licenciatura from "../../../../assets/images/UNID_licenciatura.svg";

// utils
import { ORDER_STATUS, PRODUCT_TYPE } from '../../../../shared/constants/orders';

const PaymentActionCard = ({ order, product }) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const user = useSelector((state) => state.userReducer.user);
    const userIsLogged = sessions.userIsLogged();
    const sessionCookie = getSessionCookie();
    const checkoutCookie = getCheckoutCookie();
    const activeAccount = typeof checkoutCookie?.activeAccount === 'boolean' ? checkoutCookie?.activeAccount : !!(sessionCookie?.person_id);

    const exploreButtonClick = (e) => {
        e.preventDefault();
        history.push("/");
    }

    const classesButtonClick = (e) => {
        e.preventDefault();

        const eventData = {};
        eventData['source'] = 'checkout';
        eventTracker('ver_mis_clases', eventData, ['mixpanel']);

        history.push(links.CURSOS);
    }

    const programButtonClick = (e) => {
        e.preventDefault();
        history.push(generatePath(paths.PROGRAMAS_ID_CURSOS, { id: product?.program_id, student: false }));
    }

    const courseButtonClick = async (e) => {
        e.preventDefault();
        const media_source_id = convertOuToSource(product?.company_id, product?.source_id);
        const body = {
            person_id: user?.person_id,
            email: user.email,
            ou: user.ou,
            course_url: product?.course_url,
            source_id: product?.source_id,
            course_url_neo: product?.course_url_neo,
            course_id: product?.course_id,
            company_id: product?.company_id,
            course_name: product?.name
        }
        const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + media_source_id, "_blank");
        const urlRespuesta = await getConnectionAllies(body);
        setTimeout(() => {
            win.focus();
            win.location.replace(urlRespuesta);
        }, 3000);
    }

    const activateAccountClick = (e) => {
        e.preventDefault();

        let activateAccountURL = location?.state?.user?.activateAccountURL;

        if (!activateAccountURL) {
            activateAccountURL = checkoutCookie?.personInfo?.activateAccountURL ?? checkoutCookie?.activateAccountURL;
        }

        if (activateAccountURL) {
            history.push(activateAccountURL);
        } else {
            eventTracker('s_login', {}, ['mixpanel']);
            history.push(paths.LOGIN);
        }
    };

    return (
        <Card className={clsx('bg-fill-stroke-400', classes.actionCard)}>
            <Box display="flex">
                <Box>
                    <img className={classes.actionCardImg} src={UNID_licenciatura} alt="Confirmación de pago"></img>
                </Box>
                <Box display="flex" flexDirection="column" py="24px" px="16px" flexGrow="1">
                    {(!userIsLogged && activeAccount === false) && (
                        <React.Fragment>
                            <Box mb="26px">
                                <Typography variant="body1" color="text-white" className='semi-bold mb-2'>¡No olvides activar tu cuenta!</Typography>
                                <Typography variant="caption" color="text-white">Ingresa aquí para configurar tu contraseña.</Typography>
                            </Box>
                            <Box>
                                <Button fullWidth variant='contained' color="primary" onClick={activateAccountClick}>Activar cuenta</Button>
                            </Box>
                        </React.Fragment>
                    )}
                    {(!userIsLogged && activeAccount === true) && (
                        <React.Fragment>
                            <Box mb="26px">
                                <Typography variant="body1" color="text-white" className='semi-bold mb-2'>¡Empieza a aprender ahora!</Typography>
                                <Typography variant="caption" color="text-white">Presiona el siguiente botón para comenzar</Typography>
                            </Box>
                            <Box>
                                <Button fullWidth variant='contained' component={RouterLink} to={paths.LOGIN} color="primary">Iniciar sesión</Button>
                            </Box>
                        </React.Fragment>
                    )}
                    {(userIsLogged) && (product.product_type_id === PRODUCT_TYPE.COURSE || product.product_type_id === PRODUCT_TYPE.PROGRAM) && (
                        <React.Fragment>
                            {order?.estatus_id === ORDER_STATUS.SUCCESS ?
                                <React.Fragment>
                                    <Box mb="26px">
                                        <Typography variant="body1" color="text-white" className='semi-bold mb-2'>¡Empieza a aprender ahora!</Typography>
                                        <Typography variant="caption" color="text-white">Presiona el siguiente botón para comenzar</Typography>
                                    </Box>
                                    {product.product_type_id === PRODUCT_TYPE.COURSE &&
                                        <Box>
                                            <Hidden xsDown>
                                                <Button variant='text' onClick={event => classesButtonClick(event)} className={clsx(classes.buttonCourses, "mr-2")}>
                                                    <Typography color='secondary' variant='body2'>Ir a mis clases</Typography>
                                                </Button>
                                            </Hidden>
                                            <Button variant='contained' onClick={event => courseButtonClick(event)} color="primary">Ir al curso</Button>
                                        </Box>
                                    }
                                    {product.product_type_id === PRODUCT_TYPE.PROGRAM &&
                                        <Box>
                                            <Button variant='contained' fullWidth color='primary' onClick={event => programButtonClick(event)}>Ir al programa</Button>
                                        </Box>
                                    }
                                </React.Fragment> :
                                <React.Fragment>
                                    <Box mb="26px">
                                        <Typography variant="body1" color="text-white" className='semi-bold mb-2'>Explora otros cursos</Typography>
                                        <Typography variant="caption" color="text-white">Conoce nuestros cursos, Licenciaturas y Maestrías 100% online y estudia a tu ritmo</Typography>
                                    </Box>
                                    <Box>
                                        <Button fullWidth variant='contained' onClick={event => exploreButtonClick(event)} color="primary">Ver catálogo</Button>
                                    </Box>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    )}
                    {(userIsLogged) && (product.product_type_id === PRODUCT_TYPE.MEMBERSHIP) && (
                        <React.Fragment>
                            <Box mb="26px">
                                <Typography variant="body1" color="text-white" className='semi-bold mb-2'>¡Empieza a aprender ahora!</Typography>
                                <Typography variant="caption" color="text-white">Conoce nuestros cursos, Licenciaturas y Maestrías 100% online y estudia a tu ritmo</Typography>
                            </Box>
                            <Box>
                                <Button fullWidth variant='contained' onClick={event => exploreButtonClick(event)} color="primary">Ver catálogo</Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        </Card>
    )
}

export default PaymentActionCard;