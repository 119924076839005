import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

// @material-ui/lab
import Autocomplete from '@material-ui/lab/Autocomplete';

import {LabelStyled} from '../../../assets/styles/jss/components/inputComponentsStyle'
import { Icon, Input, TextField } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const font =  "'Inter', sans-serif";

const AutocompleteStyled = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '&.placeholder-select .MuiSelect-root':{
          color: '#d1d1d3'
      }
    },
    inputRoot:{
        paddingLeft: 12,
        backgroundColor: theme.palette.colors.fillStroke[400],
        '& .MuiAutocomplete-input, .MuiAutocomplete-input:focus':{
            border: 'unset !important',
        },
        '& .MuiOutlinedInput-notchedOutline':{
            border: `0.5px solid #404150 !important`,
        },
        '&.MuiOutlinedInput-root':{
            border: 'none',
        },
        '&.MuiOutlinedInput-root:hover':{
            border: `0.5px solid #404150 !important`,
        },
        '&.MuiOutlinedInput-root:focus':{
            border:`1.5px solid ${theme.palette.colors.ou.unidYellow} !important`
        },
        '&.MuiOutlinedInput-root.Mui-focused':{
            '& .MuiOutlinedInput-notchedOutline':{
                border:`1.5px solid ${theme.palette.colors.ou.unidYellow} !important`
            }
        },
        '&.MuiOutlinedInput-root:hover':{
            '& .MuiOutlinedInput-notchedOutline':{
                borderColor: '#404150'
            }
        }
    },
    input: {
        ...theme.typography.subtitle2,
        fontFamily: `${font} !important`,
        '&::placeholder': {
            color: theme.palette.colors.fillStroke[100],
        },
    },
    text:{
        border: 'none',
    }
}))(Autocomplete);

const AutocompleteSelect = (props) => {
    const styleDisplay= (props.visibility) ? props.visibility:'block';
    const {label, classes, error,accounstsList,placeholder,onChange, onInputChange, defaultValue, value, ...rest} = props;

    const [inputValue, setInputValue] = React.useState(value);

    useEffect(() => {
        if (!value){
            setInputValue('');
        }
    }, [value]);
    return (
        <FormControl {...rest} error={Boolean(error)}>
            <LabelStyled shrink htmlFor="bootstrap-input">
                {label}
            </LabelStyled>
            <AutocompleteStyled
                id="grouped-demo"
                options={accounstsList}
                onChange={(event, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option.label}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                popupIcon={<Icon className="ri-arrow-down-s-line" style={{color: '#808080'}}/> }
                renderInput={(params) => 
                    <TextField {...params} 
                            placeholder={placeholder}
                            variant="outlined"
                            margin="dense"
                    />
                }
            />
        </FormControl>
    );
};

export default AutocompleteSelect;