import { combineReducers } from 'redux'

import profilePicReducer from './profilePicReducer'
import userReducer from './userReducer'
import menuReducer from './menuReducer'
import requestsReducer from './requestsReducer'
import tourInitialReducer from './tourInitialReducers'
import requestWorkflowReducer from './requestWorkflowReducer'
import searchReducer from './searchReducer'
import permissionReducer from './permissionReducer';
import membershipReducer from './membershipReducer';
import tabsReducer from './tabsReducer';
import legacyCheckoutReducer from './legacyCheckoutReducer';
import checkoutReducer from './checkoutReducer';
import notificationReducer from './notificationReducer';
import cartReducer from './cartReducer'; 

export default combineReducers({
    profilePicReducer,
    userReducer,
    menuReducer,
    legacyCheckoutReducer,
    checkoutReducer,
    requestsReducer,
    tourInitialReducer,
    requestWorkflowReducer,
    searchReducer,
    permissionReducer,
    membershipReducer,
    tabsReducer,
    notificationReducer,
    cartReducer,
})
