import React from 'react';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { Container } from '@material-ui/core';

// components
import LogoSource from '../LogoSource/LogoSource';

// styles
import clsx from 'clsx';
import useStyles from '../../assets/styles/components/bannerDetailStyle'
import BackToGo from '../BackToGo/BackToGo';

const BannerDetail = (props) => {
    const {title, sourceId, buttonText, buttonIcon, actionButton, backgroundImage, labelText, chipType, displayBttn = false, disabledOpacityImg = false, component: SaveBttnComponent, saveBttnProps} = props;
    const classes = useStyles({ backgroundImage});

    return (
        <>
            <Box id="banner-detail-root" className={classes.root}>
                <Box className={clsx(classes.img, {[classes.imgOpacity]: !disabledOpacityImg})} />
                <Container className={classes.container}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Box className={classes.backToGo}>
                                <BackToGo text="Regresar al catálogo" autoResponsive/>
                            </Box>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={8}>
                                <Grid item>
                                    <Box display='flex' alignItems='center' gridGap={16} mb={2}>
                                        <LogoSource color='white' sourceId={sourceId} className={clsx(classes.logo,classes[sourceId])}/>
                                        <Chip className={chipType} label={labelText} />
                                    </Box>
                                    <Box height={146} display='flex' flexDirection='column' justifyContent='space-between'>
                                        <Typography variant="h2" component='h1'>{title}</Typography>
                                        <Box>
                                            {displayBttn && 
                                                <Button
                                                    startIcon={<Icon className={`${buttonIcon} font-size-24`} />}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={actionButton}
                                                >
                                                    {buttonText}
                                                </Button>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </Box>
            <Hidden mdUp>
                <Container component={Box} pt={2.5}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='space-between' mb={2}>
                                <Box display='flex' alignItems='center' gridGap={16}>
                                    <LogoSource color='white' sourceId={sourceId} className={clsx(classes.logo,classes[sourceId])} disabledAutoRes/>
                                    <Chip className={chipType} label={labelText} />
                                </Box>
                                
                                {SaveBttnComponent && 
                                    <Box>
                                        <SaveBttnComponent {...saveBttnProps}/>
                                    </Box>
                                }
                                    
                            </Box>
                            <Box>
                                <Typography variant="h2">{title}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>
        </>
    );
};

export default BannerDetail;