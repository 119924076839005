import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';

import Footer from '../../../layout/components/Footer/Footer';

const useStyles = makeStyles((theme) => ({
    root_terminos:{
        left: "50%",
        width: "100vw",
        position: "relative",
        marginLeft: "-50vw",
        marginBottom: "-24px",
        backgroundColor: "#FFFFFF"
    },
    content_terminos:{
        maxWidth: "1440px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    title:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "48px",
        lineHeight: "48px",
        color: "#23272F",
        marginBottom: "25px",
    },
    date:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#111317",
    },
    divider:{
        width: "100%",
        marginTop: "47px", 
        marginBottom: "56px",
        backgroundColor: "#A0A9BA",
    },
    titleToGo:{
        fonFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#3D23D8",
        marginBottom: '8px'
    },
    titleText:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#111317",
        marginBottom: "24px",
    },
    text:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#111317",
        marginBottom: "16px",
    },
    subtitleBold:{

    },
    titleBold:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#23272F",
        marginBottom: "16px",
    },
    marginLeft:{
        marginLeft: "17px",
    },
    marginLeftS:{
        marginLeft: "24px",
    },
    marginBottom:{
        marginTop:"0px",
        marginBottom: "34px",
        
    },
    textSubrayed:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#111317",
        textDecoration: "underline",
        marginLeft: "48px",
    },
    textLeft:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#111317",
        marginLeft: "41px",
    },
    textNormal:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#111317",
    },
    contentTitle:{
        marginTop: "106px",
        maxWidth: "992px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        "@media (max-width: 1100px)": {
            marginLeft: "54px",
        },
        "@media (max-width: 650px)": {
            width: "calc(100% - 40px)",
            marginLeft: "0px",
            paddingLeft: "20px",
            paddingRight: "20px",
        }
    },
    content:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "@media (max-width: 1100px)": {
            flexDirection: "column",
            alignItems: "center",
        }
    },
    definiciones:{
        display: "flex",
        flexDirection: "column",
        width: "393px",
        position: "fixed",
        transform: "translate3d(-300px, 0px, 0px)",
        "@media (max-width: 1100px)": {
            marginBottom: "32px",
            width: "393px",
            display: "flex",
            position: "inherit",
            transform: "translate3d(0px, 0px, 0px)",
            flexDirection: "column",
            marginRight: "auto",
        },
        "@media (max-width: 650px)": {
            width: "calc(100% - 40px)",
            paddingLeft: "20px",
            paddingRight: "20px",
        }
    },
    stopFix:{
        width: "393px",
        display: "flex",
        position: "absolute",
        transform: "translate3d(-300px, 0px, 0px)",
        flexDirection: "column",
        bottom: "668px",
        "@media (max-width: 1300px)": {
            bottom: "116.5vh",
        },
        "@media (max-width: 1170px)": {
            bottom: "118.5vh",
        },
    },
    ghost:{
        backgroundColor: "red",
        width: "392px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        opacity: "0",
        "@media (max-width: 1100px)": {
            display: "none",
        }
    },
    rightBox:{
        maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
        marginBottom: "90px",
        "@media (max-width: 650px)": {
            width: "calc(100% - 32px)",
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    link:{
        '&:hover':{ 
            cursor: "pointer" 
        }
    }
}));


const AvisoPrivacidad = (props) => {
    const classes = useStyles();

    const [stopFix, setStopFix] = React.useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
   
    return (
        <Box display="flex" flexDirection="column" className={classes.root_terminos}>
            <Box display="flex" flexDirection="column" className={ classes.contentTitle }>
                <Typography className={classes.title}>Aviso de Privacidad</Typography>
                <Typography className={classes.date} id='estudiatesTalisis'>Fecha de última actualización: 24 de Junio de 2022.</Typography>
            </Box>
            <Divider className={classes.divider}/>
            <Box className={ classes.content }>
            <Box className={classes.ghost}></Box>
                <Box className={ stopFix == false ? classes.definiciones : classes.stopFix}>
                    <Link href="#estudiatesTalisis"><Typography className={classes.titleToGo}>Aviso de privacidad para estudiantes | TALISIS</Typography></Link>
                    <Link href="#estudiantesUerre"><Typography className={classes.titleToGo}>Aviso de Privacidad integral Estudiantes | UNIVERSIDAD REGIOMONTANA</Typography></Link>
                    <Link href="#proveedores"><Typography className={classes.titleToGo}>Aviso de Privacidad Proveedores | TALISIS</Typography></Link>
                </Box>
                <Box className={classes.rightBox}>
                    <Typography className={classes.titleText}>AVISO DE PRIVACIDAD INTEGRAL PARA ESTUDIANTES TALISIS HOLDING S.A.P.I DE C.V.</Typography>
                    <Typography className={classes.text}>De conformidad con lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, el Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y los Lineamientos del Aviso de Privacidad, le damos a conocer a Usted (en lo sucesivo y de manera indistinta, “Usted” o el “Titular”), el presente Aviso de Privacidad Integral (en lo sucesivo el “Aviso de Privacidad”). Nos permitimos solicitarle leer cuidadosamente el presente Aviso de Privacidad en el que se señala el tratamiento que TALISIS HOLDING S.A.P.I DE C.V. (en lo sucesivo y de manera indistinta, “TALISIS” o “Nosotros”), dará a los datos personales que Usted nos proporcione. </Typography>
                    <Typography className={classes.text}>A. DE NUESTRA IDENTIDAD Y DOMICILIO. TALISIS HOLDING S.A.P.I. DE C.V. con domicilio en calle Mariano Matamoros número 420, Colonia Monterrey Centro, C.P. 64000, Monterrey, Nuevo León, será la responsable de tratar los datos personales que nos proporcione de conformidad a lo dispuesto por el presente Aviso de Privacidad.</Typography>
                    <Typography className={classes.text}>B. DE LOS DATOS PERSONALES QUE SERÁN RECABADOS. Los datos que Usted proporcione para ser tratados conforme a la Sección D del presente Aviso de Privacidad serán datos personales de identificación, datos personales de contacto, datos personales laborales, datos personales académicos, datos personales patrimoniales.</Typography>
                    <Typography className={classes.text}>Estos mismos datos serán solicitados respecto a los padres o tutores de los alumnos y/o personas que deseen formar parte de la comunidad educativa de TALISIS (en lo sucesivo, los “Datos Personales Generales”).</Typography>
                    <Typography className={classes.text}>C. DATOS PERSONALES SENSIBLES QUE SERÁN RECABADOS. TALISIS como parte de su registro de alumnos y/o personas que deseen formar parte de su comunidad educativa, recabará y tratará los datos personales relacionados al grupo sanguíneo al que pertenece y características físicas, datos personales biométricos, estado de salud y padecimientos médicos,(en lo sucesivo, los “Datos Personales Sensibles” y, junto con los Datos Personales Generales, los “Datos Personales”).Únicamente y bajo ninguna otra circunstancia, podrá crearse una base de datos con los Datos Personales Sensibles a efecto de integrar un expediente para control médico de medicinas y necesidades de salud especiales que se deban de tener, además de establecer si se encuentra en las condiciones físicas necesarias para realizar algún deporte u actividades físicas. Esta base de datos será conservada durante el tiempo que Usted esté cursando cualquier ciclo educativo en TALISIS, en cualquiera de las sedes que lleguemos a tener.</Typography>
                    <Typography className={classes.text}>D. DEL TRATAMIENTO QUE SE DARÁ A LOS DATOS PERSONALES. TALISIS tratará los datos personales que recabe para cumplir las siguientes finalidades:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Para prestar servicios educativos conforme a los acuerdos correspondientes que establecen los requisitos y procedimientos relacionados con el reconocimiento de validez oficial de estudios del tipo medio superior y superior del estado de Nuevo León.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para integrar expedientes académicos conforme la legislación aplicable en materia educativa para el nivel medio superior y superior, integrar expedientes administrativos para contar con información de contacto para enviar comunicados, para localizar a los padres o tutores y para analizar solicitudes de becas.</li></Typography>
                        <Typography className={classes.textNormal}><li>Enviar información relacionada a los cursos en los que estén inscritos los alumnos de TALISIS e información necesaria para trámites internos y de servicios de alumnos.</li></Typography>
                        <Typography className={classes.textNormal}><li>Identificación, administración y creación de cuentas de alumnos.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para procesar las solicitudes y los pedidos de cursos, productos, servicios específicos, información o funciones que realice.</li></Typography>
                        <Typography className={classes.textNormal}><li>Procurar la seguridad y la identificación cuando Usted se encuentre dentro de las instalaciones de TALISIS y de las personas que en él se encuentran, por medio del uso de cámaras de video fijas o móviles con o sin sonido, o de sistemas cerrados de televisión que involucren la colocación de una o varias cámaras en espacios de TALISIS, limitadas a la supervisión o monitoreo de las instalaciones.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para contactar a sus familiares en caso de una emergencia.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para las gestiones de facturación y procesos de cobranza correspondientes.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para pruebas antidoping y/o ayuda psicológica, como parte de la comunidad educativa de TALISIS.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para inscribirse a eventos y/o actividades extracurriculares en las que participe representando a TALISIS.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para contar con una base de contacto respecto a los integrantes que fueron inscritos dentro de TALISIS.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para integrar su expediente con el respectivo control médico de medicinas y necesidades de salud especiales que tenga, y sobre todo establecer si se encuentra en las condiciones físicas necesarias para realizar algún deporte u actividades físicas.</li></Typography>
                    </ul>
                    <Typography className={classes.text}>Al proporcionarnos sus datos, Usted acepta de manera tácita que tratemos sus Datos Personales para los fines señalados y durante un tiempo indefinido y necesario para alcanzarlos objetivos señalados. Para solicitar que dejemos de utilizarlos, deberá de dirigirnos un escrito en la forma señalada para ello en la Sección H del Presente Aviso de Privacidad.</Typography>
                    <Typography className={classes.text}>E. DEL TRATAMIENTO QUE SE DARÁ A LOS DATOS PERSONALES CON FINES DE MERCADOTECNIA, PUBLICIDAD O PROSPECCIÓN COMERCIAL. TALISIS tratará los datos personales que recabe para cumplir las siguientes finalidades:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Para evaluar la calidad en el servicio, realizar estadísticas sobre los servicios educativos.</li></Typography>
                        <Typography className={classes.textNormal}><li>Enviar información promocional sobre los servicios educativos ofrecidos por TALISIS.</li></Typography>
                        <Typography className={classes.textNormal}><li>Estadísticas, investigaciones y acciones relativas a estudio de mercado.</li></Typography>
                    </ul>
                    <Typography className={classes.text}>F. DE LA TRANSFERENCIA QUE HAREMOS DE SUS DATOS PERSONALES. Para poder cumplir con la finalidad del presente Aviso de Privacidad, Nosotros podremos transferir sus Datos a terceras personas, físicas o morales, nacionales o extranjeras, empresas tenedoras, filiales o subsidiarias, pertenecientes a cualquier ramo industrial y/o de servicios, sin necesidad de su consentimiento, tales como instituciones educativas nacionales o internacionales, autoridades municipales, estatales y federales.</Typography>
                    <Typography className={classes.text}>G. DE LOS MEDIOS PARA EJERCER LOS DERECHOS ARCO. Usted tendrá en todo momento el acceso a sus Datos Personales para solicitar su rectificación, cancelación u oposición (en lo sucesivo, los “Derechos ARCO”).</Typography>
                    <Typography className={classes.text}>Para el ejercicio de los Derechos ARCO, deberá dirigirnos su respectiva solicitud en forma escrita, por correo postal o de forma electrónica, dirigiendo su mensaje a TALISIS HOLDING S.A.P.I DE C.V., con atención a Legal TALISIS. Si es de manera escrita, dirigiendo la comunicación correspondiente a Mariano Matamoros número 420, Colonia Monterrey Centro, C.P.64000, Monterrey, Nuevo León o, a través del correo electrónico: legal@talisis.com.</Typography>
                    <Typography className={classes.text}>En caso de optar por la manera escrita, además de lo señalado en el párrafo anterior, la solicitud para ejercer los Derechos ARCO deberá contener y estar acompañada de lo siguiente:</Typography>
                    <Typography className={classes.text}>(i) el nombre del Titular, su domicilio y/o correo electrónico y/o cualquier otro medio para comunicarle la respuesta a su solicitud; (ii) los documentos oficiales que acrediten la identidad o, en su caso, la representación legal del Titular; (iii) la descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados; y (iv) cualquier otro elemento o documento que facilite la localización de los Datos Personales. Recibido el escrito por el que nos indique que hará uso de sus Derechos ARCO, tendremos 3 días hábiles para analizar, atender y enviarle la respuesta correspondiente. El medio por el cual le enviaremos nuestra conclusión, será aquel que nos sea indicado por Usted en su solicitud (correo electrónico o postal, exclusivamente).</Typography>
                    <Typography className={classes.text}>H. DE LOS MECANISMOS DE COMUNICACIÓN. Cualquier comunicación que desee dirigirnos con motivo del presente Aviso de Privacidad o para limitar el uso y divulgación de sus Datos, diferentes a los procedimientos que hemos establecido en presente Aviso, deberá realizarse a las direcciones proporcionadas para el ejercicio de los Derechos ARCO, mencionando de manera clara y precisa: (i) el nombre del Titular, su domicilio y/o correo electrónico; (ii) los documentos oficiales que acrediten la identidad o, en su caso, la representación legal del Titular; y (iii) el objeto de su comunicación.</Typography>
                    <Typography className={classes.text}>I. DE OTROS MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS. El Titular podrá acudir o solicitar vía telefónica su inscripción al Registro Público de Consumidores previsto por la Ley Federal de Protección al Consumidor y/o al Registro Público de Usuarios previsto por la Ley de Protección y Defensa al Usuario de Servicios Financieros, a efecto delimitar el uso de los datos personales en posesión de los particulares.</Typography>
                    <Typography className={classes.text}>J. DE LOS MEDIOS REMOTOS. Si por cualquier razón Usted nos proporciona sus Datos a través de medios electrónicos, incluyendo nuestro sitio web (Internet), Usted entiende, acepta y reconoce que:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Nuestro sitio web puede incluir enlaces a sitios web de terceros que, en caso de acceder, ocasionará que se abandone nuestro sitio web, por lo cual el no asumiríamos ninguna responsabilidad en relación con los sitios web de terceros.</li></Typography>
                        <Typography className={classes.textNormal}><li>Nuestro sitio web puede incluir enlaces a sitios que administran redes sociales, en cuyo caso Usted acepta que al proporcionar cualquier tipo de información, incluso sus Datos en dichos sitios, ocasionará que la misma pueda ser leída, vista, accedida, retransmitida y tratada por cualquier persona, liberándonos de cualquier responsabilidad.</li></Typography>
                        <Typography className={classes.textNormal}><li>Es posible que nuestros sistemas recopilen datos adicionales a los que Usted nos proporcione, como lo son el tipo de navegador, el sistema operativo, las páginas de Internet visitadas, la dirección IP, etc., a través “cookies” o “web beacons”, entre otros. Para poder deshabilitar estos sistemas, deberá acceder a “Opciones de internet” en la barra de “Herramientas” del navegador que utilice.</li></Typography>
                    </ul>
                    <Typography className={classes.text}>K. DE LAS MODIFICACIONES AL AVISO DE PRIVACIDAD. El presente Aviso de Privacidad podrá ser modificado por Nosotros tantas veces como lo consideremos necesario, tanto para guardar congruencia con los ordenamientos legales aplicables, a petición de las autoridades competentes y/o a nuestra propia conveniencia. En tanto no modifiquemos nuestra identidad, requiramos de Usted más datos de los aquí mencionados, modifiquemos el fin del presente Aviso de Privacidad y/o cambien las condiciones de transferencia, Usted está de acuerdo y conforme en que la forma de notificar cualquier cambio al presente Aviso de Privacidad, sea a través de la publicación de este a través de la página de Internet www.talisis.com.</Typography>
                    <Typography className={classes.text}>Para los casos en que su consentimiento sea necesario por los cambios realizados al presente Aviso de Privacidad, Nosotros le haremos llegar nuestro aviso correspondiente a cualquiera de los medios de contacto que Usted nos haya brindado; se entenderá que Usted acepta dicho Aviso hasta que no recibamos cualquier comunicación de Usted en sentido contrario.</Typography>
                    <Typography className={classes.text} id='estudiantesUerre'>Es su obligación visitar periódicamente dicho sitio a fin de verificar la versión más actual de nuestro aviso de privacidad .No obstante lo señalado en el párrafo anterior y únicamente cuando TALISIS deje de ser el responsable del tratamiento de sus datos personales, cuando requiera recabar datos personales sensibles, patrimoniales o financieros de los que se solicitan en este Aviso de Privacidad, cuando cambien las finalidades descritas en el Apartado D de este Aviso de Privacidad, o bien, ,se incorporen nuevas que requieran del consentimiento de Usted, o se modifiquen las condiciones de las transferencias o se vayan a realizar transferencias no previstas inicialmente, y su consentimiento del titular sea necesario, TALISIS pondrá a su disposición un nuevo aviso de privacidad.</Typography>
                    <Typography className={classes.text}>La presente versión de este Aviso de Privacidad fue actualizada por última vez el 24 de Junio de 2022.</Typography>




                    
                    <Typography className={classes.titleText}>AVISO DE PRIVACIDAD INTEGRAL PARA ESTUDIANTES UNIVERSIDAD REGIOMONTANA, S.C</Typography>
                    <Typography className={classes.text}>De conformidad con lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, el Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y los Lineamientos del cidad, le damos a conocer a Usted (en lo sucesivo y de manera indistinta, “Usted” o el “Titular”), el presente cidad Integral (en lo sucesivo el “cidad”). Nos permitimos solicitarle leer cuidadosamente el presente cidad en el que se señala el tratamiento que la Universidad Regiomontana, S.C. (en lo sucesivo y de manera indistinta, la “U-ERRE” o “Nosotros”), dará a los datos personales que Usted nos proporcione. La aportación que haga de sus datos personales constituye la aceptación del presente cidad.</Typography>
                    <Typography className={classes.text}>A. DE NUESTRA IDENTIDAD Y DOMICILIO. La Universidad Regiomontana S.C., con domicilio en calle Mariano Matamoros número 420, Colonia Monterrey Centro, C.P. 64000, Monterrey, Nuevo León, será la responsable de tratar los datos personales que nos proporcione de conformidad a lo dispuesto por el presente cidad.</Typography>
                    <Typography className={classes.text}>B. DE LOS DATOS PERSONALES QUE SERÁN RECABADOS. Los datos que Usted proporcione para ser tratados conforme a la Sección D del presente cidad, serán datos personales de identificación, datos personales de contacto, datos personales laborales, datos personales académicos, datos personales patrimoniales.</Typography>
                    <Typography className={classes.text}>Estos mismos datos serán solicitados respecto a los padres o tutores de los alumnos y/o personas que deseen formar parte de la comunidad educativa de la U-ERRE (en lo sucesivo, los “Datos Personales Generales”).</Typography>
                    <Typography className={classes.text}>C. DATOS PERSONALES SENSIBLES QUE SERÁN RECABADOS. La U-ERRE como parte de su registro de alumnos y/o personas que deseen formar parte de su comunidad educativa, recabará y tratará los datos personales relacionados al grupo sanguíneo al que pertenece y características físicas, datos personales biométricos, estado de salud y padecimientos médicos,(en lo sucesivo, los “Datos Personales Sensibles” y, junto con los Datos Personales Generales, los “Datos Personales”).Únicamente y bajo ninguna otra circunstancia, podrá crearse una base de datos con los Datos Personales Sensibles a efecto de integrar un expediente para control médico de medicinas y necesidades de salud especiales que se deban de tener, además de establecer si se encuentra en las condiciones físicas necesarias para realizar algún deporte u actividades físicas. Esta base de datos será conservada durante el tiempo que Usted esté cursando cualquier ciclo educativo en la U-ERRE, en cualquiera de las sedes que lleguemos a tener.</Typography>
                    <Typography className={classes.text}>D. DEL TRATAMIENTO QUE SE DARÁ A LOS DATOS PERSONALES. La U-ERRE tratará los datos personales que recabe para cumplir las siguientes finalidades:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Para prestar servicios educativos conforme a los acuerdos correspondientes que establecen los requisitos y procedimientos relacionados con el reconocimiento de validez oficial de estudios del tipo medio superior y superior del estado de Nuevo León.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para integrar expedientes académicos conforme la legislación aplicable en materia educativa para el nivel medio superior y superior, integrar expedientes administrativos para contar con información de contacto para enviar comunicados, para localizar a los padres o tutores y para analizar solicitudes de becas.</li></Typography>
                        <Typography className={classes.textNormal}><li>Enviar información relacionada a los cursos en los que estén inscritos los alumnos de la U-ERRE e información necesaria para trámites internos y de servicios de alumnos.</li></Typography>
                        <Typography className={classes.textNormal}><li>Identificación, administración y creación de cuentas de alumnos.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para procesar las solicitudes y los pedidos de cursos, productos, servicios específicos, información o funciones que realice.</li></Typography>
                        <Typography className={classes.textNormal}><li>Procurar la seguridad y la identificación cuando Usted se encuentre dentro de las instalaciones de La U-ERRE y de las personas que en él se encuentran, por medio del uso de cámaras de video fijas o móviles con o sin sonido, o de sistemas cerrados de televisión que involucren la colocación de una o varias cámaras en espacios de la U-ERRE, limitadas a la supervisión o monitoreo de las instalaciones.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para contactar a sus familiares en caso de una emergencia.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para las gestiones de facturación y procesos de cobranza correspondientes.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para pruebas antidoping y/o ayuda psicológica, como parte de la comunidad educativa de la U-ERRE.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para inscribirse a eventos y/o actividades extracurriculares en las que participe representando a la U-ERRE.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para contar con una base de contacto respecto a los integrantes que fueron inscritos dentro de la U-ERRE.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para integrar su expediente con el respectivo control médico de medicinas y necesidades de salud especiales que tenga, y sobretodo establecer si se encuentra en las condiciones físicas necesarias para realizar algún deporte u actividades físicas.</li></Typography>
                    </ul>
                    
                    <Typography className={classes.text}>Al proporcionarnos sus datos, Usted acepta de manera tácita que tratemos sus Datos Personales para los fines señalados y durante un tiempo indefinido y necesario para alcanzarlos objetivos señalados. Para solicitar que dejemos de utilizarlos, deberá de dirigirnos un escrito en la forma señalada para ello en la Sección H del Presente cidad.</Typography>
                    <Typography className={classes.text}>E. DEL TRATAMIENTO QUE SE DARÁ A LOS DATOS PERSONALES CON FINES DE MERCADOTECNIA, PUBLICIDAD O PROSPECCIÓN COMERCIAL. La U-ERRE tratará los datos personales que recabe para cumplir las siguientes finalidades:</Typography>
                    
                    <ul>
                        <Typography className={classes.textNormal}><li>Para evaluar la calidad en el servicio, realizar estadísticas sobre los servicios educativos.</li></Typography>
                        <Typography className={classes.textNormal}><li>Enviar información promocional sobre los servicios educativos ofrecidos por la UERRE.</li></Typography>
                        <Typography className={classes.textNormal}><li>Estadísticas, investigaciones y acciones relativas a estudio de mercado.</li></Typography>
                    </ul>

                    <Typography className={classes.text}>F. DE LA TRANSFERENCIA QUE HAREMOS DE SUS DATOS PERSONALES. Para poder cumplir con la finalidad del presente cidad, Nosotros podremos transferir sus Datos a terceras personas, físicas o morales, nacionales o extranjeras, empresas tenedoras, filiales o subsidiarias, pertenecientes a cualquier ramo industrial y/o de servicios, sin necesidad de su consentimiento, tales como instituciones educativas nacionales o internacionales, autoridades municipales, estatales y federales.</Typography>
                    <Typography className={classes.text}>G. DE LOS MEDIOS PARA EJERCER LOS DERECHOS ARCO. Usted tendrá en todo momento el acceso a sus Datos Personales para solicitar su rectificación, cancelación u oposición (en lo sucesivo, los “Derechos ARCO”).</Typography>
                    <Typography className={classes.text}>Para el ejercicio de los Derechos ARCO, deberá dirigirnos su respectiva solicitud en forma escrita, por correo postal o de forma electrónica, dirigiendo su mensaje a la Universidad Regiomontana, S.C., con atención a Legal TALISIS. Si es de manera escrita, dirigiendo la comunicación correspondiente a Mariano Matamoros número 420, Colonia Monterrey Centro, C.P.64000, Monterrey, Nuevo León o, a través del correo electrónico: <Link href="mailto:legal@talisis.com">legal@talisis.com.</Link></Typography>
                    <Typography className={classes.text}>En caso de optar por la manera escrita, además de lo señalado en el párrafo anterior, la solicitud para ejercer los Derechos ARCO deberá contener y estar acompañada de lo siguiente:</Typography>
                    <Typography className={classes.text}>(i) el nombre del Titular, su domicilio y/o correo electrónico y/o cualquier otro medio para comunicarle la respuesta a su solicitud; (ii) los documentos oficiales que acrediten la identidad o, en su caso, la representación legal del Titular; (iii) la descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados; y (iv) cualquier otro elemento o documento que facilite la localización de los Datos Personales. Recibido el escrito por el que nos indique que hará uso de sus Derechos ARCO, tendremos 3 días hábiles para analizar, atender y enviarle la respuesta correspondiente. El medio por el cual le enviaremos nuestra conclusión, será aquel que nos sea indicado por Usted en su solicitud (correo electrónico o postal, exclusivamente).</Typography>
                    <Typography className={classes.text}>H. DE LOS MECANISMOS DE COMUNICACIÓN. Cualquier comunicación que desee dirigirnos con motivo del presente cidad o para limitar el uso y divulgación de sus Datos, diferentes a los procedimientos que hemos establecido en presente Aviso, deberá realizarse a las direcciones proporcionadas para el ejercicio de los Derechos ARCO, mencionando de manera clara y precisa: (i) el nombre del Titular, su domicilio y/o correo electrónico; (ii) los documentos oficiales que acrediten la identidad o, en su caso, la representación legal del Titular; y (iii) el objeto de su comunicación.</Typography>
                    <Typography className={classes.text}>I. DE OTROS MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS.</Typography>
                    <Typography className={classes.text}>El Titular podrá acudir o solicitar vía telefónica su inscripción al Registro Público de Consumidores previsto por la Ley Federal de Protección al Consumidor y/o al Registro Público de Usuarios previsto por la Ley de Protección y Defensa al Usuario de Servicios Financieros, a efecto delimitar el uso de los datos personales en posesión de los particulares.</Typography>
                    <Typography className={classes.text}>J. DE LOS MEDIOS REMOTOS. Si por cualquier razón Usted nos proporciona sus Datos a través de medios electrónicos, incluyendo nuestro sitio web (Internet), Usted entiende, acepta y reconoce que:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Nuestro sitio web puede incluir enlaces a sitios web de terceros que, en caso de acceder, ocasionará que se abandone nuestro sitio web, por lo cual el no asumiríamos ninguna responsabilidad en relación con los sitios web de terceros.</li></Typography>
                        <Typography className={classes.textNormal}><li>Nuestro sitio web puede incluir enlaces a sitios que administran redes sociales, en cuyo caso Usted acepta que al proporcionar cualquier tipo de información, incluso sus Datos en dichos sitios, ocasionará que la misma pueda ser leída, vista, accedida, retransmitida y tratada por cualquier persona, liberándonos de cualquier responsabilidad.</li></Typography>
                        <Typography className={classes.textNormal}><li>Es posible que nuestros sistemas recopilen datos adicionales a los que Usted nos proporcione, como lo son el tipo de navegador, el sistema operativo, las páginas de Internet visitadas, la dirección IP, etc., a través “cookies” o “web beacons”, entre otros. Para poder deshabilitar estos sistemas, deberá acceder a “Opciones de internet” en la barra de “Herramientas” del navegador que utilice.</li></Typography>
                    </ul>
                    <Typography className={classes.textNormal}>K. DE LAS MODIFICACIONES AL CIDAD.</Typography>
                    <Typography className={classes.text}>El presente cidad podrá ser modificado por Nosotros tantas veces como lo consideremos necesario, tanto para guardar congruencia con los ordenamientos legales aplicables, a petición de las autoridades competentes y/o a nuestra propia conveniencia. En tanto no modifiquemos nuestra identidad, requiramos de Usted más datos de los aquí mencionados, modifiquemos el fin del presente cidad y/o cambien las condiciones de transferencia, Usted está de acuerdo y conforme en que la forma de notificar cualquier cambio al presente cidad, sea a través de la publicación de este a través de la página de Internet <Link target="_blank" href="https://www.u-erre.mx/">www.u-erre.mx.</Link></Typography>
                    <Typography className={classes.text}>Para los casos en que su consentimiento sea necesario por los cambios realizados al presente cidad, Nosotros le haremos llegar nuestro aviso correspondiente a cualquiera de los medios de contacto que Usted nos haya brindado; se entenderá que Usted acepta dicho Aviso hasta que no recibamos cualquier comunicación de Usted en sentido contrario.</Typography>
                    <Typography className={classes.text} id='proveedores'>Es su obligación visitar periódicamente dicho sitio a fin de verificar la versión más actual de nuestro cidad .No obstante lo señalado en el párrafo anterior y únicamente cuando la U-ERRE deje de ser el responsable del tratamiento de sus datos personales, cuando requiera recabar datos personales sensibles, patrimoniales o financieros de los que se solicitan en este cidad, cuando cambien las finalidades descritas en el Apartado D de este cidad, o bien, ,se incorporen nuevas que requieran del consentimiento de Usted, o se modifiquen las condiciones de las transferencias o se vayan a realizar transferencias no previstas inicialmente, y su consentimiento del titular sea necesario, la U-ERRE pondrá a su disposición un nuevo cidad.</Typography>
                    <Typography className={classes.text}>La presente versión de este cidad fue actualizada por última vez el 24 de Junio de 2022.</Typography>


                    <Typography className={classes.titleText}>AVISO DE PRIVACIDAD INTEGRAL PARA PROVEEDORES TALISIS HOLDING S.A.P.I. DE C.V.</Typography>
                    <Typography className={classes.text}>De conformidad con lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, el Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y los Lineamientos del Aviso de Privacidad, le damos a conocer a Usted (en lo sucesivo y de manera indistinta, “Usted” o el “Titular”), el presente Aviso de Privacidad Integral (en lo sucesivo el “Aviso de Privacidad”). Nos permitimos solicitarle leer cuidadosamente el presente Aviso de Privacidad en el que se señala el tratamiento que TALISIS HOLDING S.A.P.I. DE C.V. (en lo sucesivo y de manera indistinta,  “TALISIS:” o “Nosotros”), dará a los datos personales que Usted nos proporcione.</Typography>
                    <Typography className={classes.text}>A. DE NUESTRA IDENTIDAD Y DOMICILIO. Talisis Holding S.A.P.I. de C.V., con domicilio en calle Mariano Matamoros número 420, Colonia Monterrey Centro, C.P. 64000, Monterrey, Nuevo León, será la responsable de tratar los datos personales que nos proporcione de conformidad a lo dispuesto por el presente Aviso de Privacidad.</Typography>
                    <Typography className={classes.text}>B. DE LOS DATOS PERSONALES QUE SERÁN RECABADOS. Los datos que Usted proporcione para ser tratados conforme a la Sección D del presente Aviso de Privacidad serán datos personales de identificación, datos personales de contacto, datos personales laborales, datos personales académicos, datos personales patrimoniales (en lo sucesivo, los “Datos Personales Generales”).</Typography>
                    <Typography className={classes.text}>C. DATOS PERSONALES SENSIBLES QUE SERÁN RECABADOS. TALISIS: no recaba datos personales sensibles para las finalidades enumeradas en el siguiente apartado.</Typography>
                    <Typography className={classes.text}>D. DEL TRATAMIENTO QUE SE DARÁ A LOS DATOS PERSONALES. TALISIS: tratará los datos personales que recabe para cumplir las siguientes finalidades:</Typography>
                    <Typography className={classes.text}>Finalidades originarias o primarias:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Generar el registro en nuestro Padrón de Proveedores </li></Typography>
                        <Typography className={classes.textNormal}><li>Verificar si cumple con los requisitos necesarios para ser proveedor de TALISIS:</li></Typography>
                        <Typography className={classes.textNormal}><li>Conocer su capacidad de servicio, los recursos materiales y humanos con los que cuenta, su capacidad de operación y experiencia en el ramo al cual pertenece</li></Typography>
                        <Typography className={classes.textNormal}><li>Recibir los servicios y/o productos brindados por usted</li></Typography>
                        <Typography className={classes.textNormal}><li>Gestionar la facturación y llevar a cabo los procesos de pago y cobranza correspondientes</li></Typography>
                        <Typography className={classes.textNormal}><li>Generar la alta y pago de adeudos a proveedores</li></Typography>
                        <Typography className={classes.textNormal}><li>Verificar cumplimiento de obligaciones fiscales y de seguridad social relacionadas con proveedores</li></Typography>
                        <Typography className={classes.textNormal}><li>Documentar y controlar de la asignación de activos de cómputo y comunicaciones electrónicas para personal de proveedores</li></Typography>
                        <Typography className={classes.textNormal}><li>Realizar auditorías internas</li></Typography>
                        <Typography className={classes.textNormal}><li>Asignar perfiles en los sistemas del responsable para personal de proveedores</li></Typography>
                        <Typography className={classes.textNormal}><li>Asignar claves y contraseñas para personal de proveedores</li></Typography>
                        <Typography className={classes.textNormal}><li>Generar el control de personal de proveedores, con acceso a oficinas o nodos del responsable</li></Typography>
                        <Typography className={classes.textNormal}><li>Asegurar el cumplimiento de obligaciones de confidencialidad, honorabilidad y transparencia, mediante la implementación de procedimientos administrativos y técnicos establecidos para tales efectos</li></Typography>
                        <Typography className={classes.textNormal}><li>Realizar estadística y registro histórico de proveedores.</li></Typography>
                    </ul>
                    <Typography className={classes.text}>Finalidades Adicionales o Secundarias:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>No existen</li></Typography>
                    </ul>
                    <Typography className={classes.text}>Al proporcionarnos sus datos, Usted acepta de manera tácita que tratemos sus Datos Personales para los fines señalados y durante un tiempo indefinido y necesario para alcanzarlos objetivos señalados. Para solicitar que dejemos de utilizarlos, deberá de dirigirnos un escrito en la forma señalada para ello en la Sección H del Presente Aviso de Privacidad.</Typography>
                    <Typography className={classes.text}>E. DEL TRATAMIENTO QUE SE DARÁ A LOS DATOS PERSONALES CON FINES DE MERCADOTECNIA, PUBLICIDAD O PROSPECCIÓN COMERCIAL. TALISIS: tratará los datos personales que recabe para cumplir las siguientes finalidades:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Envío de información promocional de cursos, diplomados, seminarios, simposios, talleres extra-académicos y eventos</li></Typography>
                        <Typography className={classes.textNormal}><li>Envío de publicidad y comunicaciones con fines de mercadotecnia, tele-marketing o campañas financieras</li></Typography>
                        <Typography className={classes.textNormal}><li>Invitarlo a reuniones, eventos, foros y asociaciones organizados por TALISIS.</li></Typography>
                    </ul>
                    <Typography className={classes.text}>F. DE LA TRANSFERENCIA QUE HAREMOS DE SUS DATOS PERSONALES. Para poder cumplir con la finalidad del presente Aviso de Privacidad, Nosotros podremos transferir sus Datos a terceras personas, físicas o morales, nacionales o extranjeras, empresas tenedoras, filiales o subsidiarias, pertenecientes a cualquier ramo industrial y/o de servicios, sin necesidad de su consentimiento, tales como instituciones educativas nacionales o internacionales, autoridades municipales, estatales y federales. </Typography>
                    <Typography className={classes.text}>Si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que otorga su consentimiento para ello.                    </Typography>
                    <Typography className={classes.text}>G. DE LOS MEDIOS PARA EJERCER LOS DERECHOS ARCO. Usted tendrá en todo momento el acceso a sus Datos Personales para solicitar su rectificación, cancelación u oposición (en lo sucesivo, los “Derechos ARCO”).      </Typography>
                    <Typography className={classes.text}>Para el ejercicio de los Derechos ARCO, deberá dirigirnos su respectiva solicitud en forma escrita, por correo postal o de forma electrónica, dirigiendo su mensaje a TALISIS HOLDING S.A.P.I. DE C.V., con atención a Legal TALISIS:. Si es de manera escrita, dirigiendo la comunicación correspondiente a Mariano Matamoros número 420, Colonia Monterrey Centro, C.P.64000, Monterrey, Nuevo León o, a través del correo electrónico: legal@talisis.com.</Typography>
                    <Typography className={classes.text}>En caso de optar por la manera escrita, además de lo señalado en el párrafo anterior, la solicitud para ejercer los Derechos ARCO deberá contener y estar acompañada de lo siguiente:</Typography>
                    <Typography className={classes.text}>(i) el nombre del Titular, su domicilio y/o correo electrónico y/o cualquier otro medio para comunicarle la respuesta a su solicitud; (ii) los documentos oficiales que acrediten la identidad o, en su caso, la representación legal del Titular; (iii) la descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados; y (iv) cualquier otro elemento o documento que facilite la localización de los Datos Personales. Recibido el escrito por el que nos indique que hará uso de sus Derechos ARCO, tendremos 3 días hábiles para analizar, atender y enviarle la respuesta correspondiente. El medio por el cual le enviaremos nuestra conclusión será aquel que nos sea indicado por Usted en su solicitud (correo electrónico o postal exclusivamente).</Typography>
                    <Typography className={classes.text}>H. DE LOS MECANISMOS DE COMUNICACIÓN. Cualquier comunicación que desee dirigirnos con motivo del presente Aviso de Privacidad o para limitar el uso y divulgación de sus Datos, diferentes a los procedimientos que hemos establecido en presente Aviso, deberá realizarse a las direcciones proporcionadas para el ejercicio de los Derechos ARCO, mencionando de manera clara y precisa: (i) el nombre del Titular, su domicilio y/o correo electrónico; (ii) los documentos oficiales que acrediten la identidad o, en su caso, la representación legal del Titular; y (iii) el objeto de su comunicación.</Typography>
                    <Typography className={classes.text}>I. DE OTROS MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS. El Titular podrá acudir o solicitar vía telefónica su inscripción al Registro Público de Consumidores previsto por la Ley Federal de Protección al Consumidor y/o al Registro Público de Usuarios previsto por la Ley de Protección y Defensa al Usuario de Servicios Financieros, a efecto delimitar el uso de los datos personales en posesión de los particulares.                  </Typography>
                    <Typography className={classes.text}>J. DE LOS MEDIOS REMOTOS. Si por cualquier razón Usted nos proporciona sus Datos a través de medios electrónicos, incluyendo nuestro sitio web (Internet), Usted entiende, acepta y reconoce que:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Nuestro sitio web puede incluir enlaces a sitios web de terceros que, en caso de acceder, ocasionará que se abandone nuestro sitio web, por lo cual el no asumiríamos ninguna responsabilidad en relación con los sitios web de terceros.</li></Typography>
                        <Typography className={classes.textNormal}><li>Nuestro sitio web puede incluir enlaces a sitios que administran redes sociales, en cuyo caso Usted acepta que al proporcionar cualquier tipo de información, incluso sus Datos en dichos sitios, ocasionará que la misma pueda ser leída, vista, accedida, retransmitida y tratada por cualquier persona, liberándonos de cualquier responsabilidad.</li></Typography>
                        <Typography className={classes.textNormal}><li>Es posible que nuestros sistemas recopilen datos adicionales a los que Usted nos proporcione, como lo son el tipo de navegador, el sistema operativo, las páginas de Internet visitadas, la dirección IP, etc., a través “cookies” o “web beacons”, entre otros. Para poder deshabilitar estos sistemas, deberá acceder a “Opciones de internet” en la barra de “Herramientas” del navegador que utilice.</li></Typography>
                    </ul>
                    <Typography className={classes.text}>K. DE LAS MODIFICACIONES AL AVISO DE PRIVACIDAD. El presente Aviso de Privacidad podrá ser modificado por Nosotros tantas veces como lo consideremos necesario, tanto para guardar congruencia con los ordenamientos legales aplicables, a petición de las autoridades competentes y/o a nuestra propia conveniencia. En tanto no modifiquemos nuestra identidad, requiramos de Usted más datos de los aquí mencionados, modifiquemos el fin del presente Aviso de Privacidad y/o cambien las condiciones de transferencia, Usted está de acuerdo y conforme en que la forma de notificar cualquier cambio al presente Aviso de Privacidad, sea a través de la publicación de este a través de la página de Internet www.talisis.mx.</Typography>
                    <Typography className={classes.text}>Para los casos en que su consentimiento sea necesario por los cambios realizados al presente Aviso de Privacidad, Nosotros le haremos llegar nuestro aviso correspondiente a cualquiera de los medios de contacto que Usted nos haya brindado; se entenderá que Usted acepta dicho Aviso hasta que no recibamos cualquier comunicación de Usted en sentido contrario.</Typography>
                    <Typography className={classes.text}>Es su obligación visitar periódicamente dicho sitio a fin de verificar la versión más actual de nuestro aviso de privacidad .No obstante lo señalado en el párrafo anterior y únicamente cuando TALISIS: deje de ser el responsable del tratamiento de sus datos personales, cuando requiera recabar datos personales sensibles, patrimoniales o financieros de los que se solicitan en este Aviso de Privacidad, cuando cambien las finalidades descritas en el Apartado D de este Aviso de Privacidad, o bien, ,se incorporen nuevas que requieran del consentimiento de Usted, o se modifiquen las condiciones de las transferencias o se vayan a realizar transferencias no previstas inicialmente, y su consentimiento del titular sea necesario, TALISIS: pondrá a su disposición un nuevo aviso de privacidad.</Typography>
                    <Typography className={classes.text}>La presente versión de este Aviso de Privacidad fue actualizada por última vez el 26 de julio de 2022.</Typography>
                </Box>
            </Box>
            {/* <Footer></Footer> */}
        </Box>
    );
};


export default AvisoPrivacidad;