import React from 'react'
import { generatePath, Link as RouterLink, useHistory } from 'react-router-dom';
import { Grid, Hidden, makeStyles, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Routes
import {CURSO_DETALLE} from '../../../../routes/paths';

// Shared components
import LoaderContent from '../../../../shared/components/Loader/LoaderContent'


// Mix panel
import { handleClickMixpanel } from './helpers/handleClickMixpanel';
import InfiniteScroll from 'react-infinite-scroll-component';
import LandSearchCourseCard from '../../../../design-system/components/Cards/LandSearchCourseCard/LandSearchCourseCard';

// Utils
import { convertSourceToImageResource } from '../../../../design-system/utils/placeholderImages';
import { getSourceBySourceAndCompany } from '../../../../design-system/utils/source';
import objectIsEmpty from '../../../../design-system/utils/objectIsEmpty';
import {getSessionCookie} from '../../../../shared/utils/Sessions';

// services
import { CourseService } from '@sdk-point/talisis';
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    styledCard:{
        [theme.breakpoints.down('xs')]:{
            width: '100% !important'
        }
    },
    marginsCardsAliados:{
        marginTop: 16
    },
    infiniteStyle:{
        overflow: 'hidden !important'
    },
    loaderInfinite:{
        marginTop:'30px',
        marginBottom:'16px'
    },
    rootGridItem: {
        display: 'flex',
        justifyContent: 'center',
    }
}));

const NUM_COURSE = 20;

const AlliesCardContainer = ({ isLoading, coursesArray, userProps, dataNumberShow = 9, showAll=true}) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session); 
    const classes = useStyles();

    const [hasMoreCourses, setHasMoreCourses] = React.useState(true);    
    const [courses, setCourses] = React.useState([]);
    const { user } = useSelector((state) => state.userReducer);

    const validateHasMoreCourses = (countCourses, totalCourses) => {
        if (totalCourses === countCourses) setHasMoreCourses(false);
    };

    const getMoreCourses = () => {
        const newArray = coursesArray.slice(0, courses.length + NUM_COURSE);
        setCourses(newArray);
        validateHasMoreCourses(newArray.length, coursesArray.length);
    };

    //funcion para guardar los cursos en favoritos solamente
    const handleSaveCourse = async (e, newStatus, userId, courseId) => {
        e.preventDefault();
        e.persist();

        const response = await courseService.saveUnsaveCourse(userId, courseId, newStatus);

        e.stopPropagation()

        return response.response[0].is_active;
    };

    const handleClickCourseCard = (courseIdParam) => () => {
        history.push(generatePath(CURSO_DETALLE, {id: courseIdParam}));
    };

    React.useEffect(() => {
        setHasMoreCourses(true);
        //Infinite scroll manage
        const arrayCoursesTemp = coursesArray.slice(0, NUM_COURSE);
        setCourses(arrayCoursesTemp);
        validateHasMoreCourses(arrayCoursesTemp.length, coursesArray.length);
    }, [coursesArray, showAll]);

    return (
        <Grid item xs={12} className={classes.marginsCardsAliados}>
            <LoaderContent loading={isLoading}>
            <InfiniteScroll
                className={classes.infiniteStyle}
                dataLength={courses.length}
                next={getMoreCourses}
                hasMore={hasMoreCourses}
                loader={
                    <Grid container direction='row'>
                        <Grid item xs={12} className={classes.loaderInfinite}>
                            <LoaderContent loading={true}></LoaderContent>
                        </Grid>
                        <Grid item xs={12}>
                            <p className='text-center'>cargando datos...</p> 
                        </Grid>
                    </Grid>
            }>
                <Grid container spacing={2} alignContent="center">
                {
                    ( courses && courses.length ) &&
                    courses.map(( course ) => {
                        let linkProps = {};
                        linkProps.to={pathname: generatePath(CURSO_DETALLE, {id: course.id}), state: { prevPath: "", target: null  }};
                        return(
                            <Grid item lg={3} md={4} sm={6} xs={12} key={course.id} className={classes.rootGridItem}>
                                <LandSearchCourseCard 
                                    onClickMixPanelCard={() => {handleClickMixpanel(course.name, course.source_name, userProps)}}
                                    onClick={handleClickCourseCard(course.id)}
                                    courseId={course.id}
                                    userId={user?.person_id}
                                    media={{
                                        backgroundImage: course?.image_url ?? convertSourceToImageResource(course.companyId, course.source),
                                        ratingData: {},
                                        sourceIdLogo: getSourceBySourceAndCompany(course.source, course.companyId),
                                    }}
                                    content={{
                                        hasMembership: course?.is_included,
                                        courseName: course?.name,
                                        showLabel: course?.showLabel,
                                        showPrice: course?.showPrice ,
                                        labelToShow: course?.labelToShow,
                                        typeLabel: course?.typeLabel, 
                                        price: {
                                            number: course?.price.number,
                                            listNumber: course?.list_price,
                                        }
                                    }}
                                    isLogged={isLogged}
                                    isListItemCard={isResponsive}
                                />
                            </Grid>
                        );
                    })
                }
                </Grid>
            </InfiniteScroll>
            </LoaderContent>
        </Grid>
    )
}

export default AlliesCardContainer
