import React from 'react'

import { Button, Grid, makeStyles } from '@material-ui/core'

import { ProfileIconList } from '../../components/ProfileIconList'
import academies from '../../../../../shared/constants/academies'
import { v4 as uuid } from 'uuid';
import CardAcademia from '../../../../../shared/components/Academies/CardAcademia';

const useStyles = makeStyles((theme) => ({
  cardAcademia:{
      '& .card-content':{
          width: 108,
          height: 108,
          padding: '8px 10px',
      }
  },
  cardAcademiaSelect:{
      '& .card-content':{
          width: 108,
          height: 108,
          backgroundColor: theme.palette.colors.electricBlue[300]
      }
  },
}));

const InterestCard = ({academy, btnFunction, selectedAcademies}) => {
  const classes = useStyles();
  const itemAcademy = academies.find(academys => academys.id === academy.id);
  const selected = selectedAcademies.includes(academy.id);
  return (
    itemAcademy !== undefined &&
    <Grid item lg={6} md={12} xs={6}>
      <CardAcademia
          id={itemAcademy.id} 
          key={uuid()} 
          src={itemAcademy.icon} 
          title={itemAcademy.name}
          className={selected ? classes.cardAcademiaSelect : classes.cardAcademia}
          handleClick={() => btnFunction(academy)}
      />
    </Grid>
  )
}

export default InterestCard
