import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

// @material-ui/core
import { Button, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

// @material-ui/icons
import DescriptionIcon from '@material-ui/icons/Description';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import PrintIcon from '@material-ui/icons/Print';

// shared components
import DynamicTable from '../Horario/DynamicTable';
import LogoOu from '../LogoOu/LogoOu';

import { AccountService } from '@sdk-point/talisis';
import PrintPDFBtn from '../Academic/PrintPDFBtn/PrintPDFBtn';

import NothingFound from '../../../../shared/components/NothingFound/NothingFound';
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';
const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

// Mock info
let colNames = ["Clave", "Materia", "Estatus", "Periodo", "Calificacion Final"];
let lessColNames = ["Área", "Materia", "Estatus", "Calificación Final"];
const replaces = {
    'primer': 1, 'primera': 1,
    'segundo': 2, 'segunda': 2,
    'tercer': 3, 'tercera': 3,
    'cuarto': 4, 'cuarta': 4,
    'quinto': 5, 'quinta': 5,
    'sexto': 6, 'sexta': 6,
    'septimo': 7, 'septima': 7,
    'octavo': 8, 'octava': 8,
    'noveno': 9, 'novena': 9,
    'decimo': 10, 'decima': 10
};
const classNote = { fontSize: '8px', color: 'gray' };

const Kardex = (props) => {

    const refPeriodosContainer = useRef(null);
    const { person_id } = useSelector((state) => state.userReducer.user);

    const { about_me: { userName, user_id, ou }, studentProgram, service, isCurrentTab } = props;

    const { student_id } = useSelector((state) => state.userReducer.user);

    const [kardex, setKardex] = React.useState([]);
    const kardexData = [];

    const [kardexSM, setKardexSM] = React.useState([]);
    const kardexDataSM = [];

    const [promedioGeneral, setPromedioGeneral] = React.useState(0);
    const promedios = [];

    const formattedAverage = (promedioGeneral && promedioGeneral <= 10)
                                ? (promedioGeneral * 100 / 10).toFixed(2)
                                : promedioGeneral
    React.useEffect(() => {
        const fetchData = async () => {
            const kardex = await AccountServiceSDK.getStudentScheduleCalifications(ou, user_id, 'kardex');
            const personDashboardInfo = await AccountServiceSDK.getDashboard(person_id, ou);
            setPromedioGeneral(personDashboardInfo.generalAverage);

            if (!student_id) return;

            if (kardex && kardex.length > 0) {
                let lastArea = '';
                kardex.map(asignature => {
                    if (lastArea !== asignature.area) {
                        kardexData.push({
                            [asignature.area]: []
                        });
                        kardexDataSM.push({
                            [asignature.area]: []
                        });
                        lastArea = asignature.area;
                    }
                    const promedio = asignature.calificacion;
                    promedios.push(promedio ? parseFloat(promedio) : 0);
                });

                // Construcción de Etiqueta de columna para area ¬_¬.
                let headerAreaParts = kardex[0].area.split(' ');
                let headerAreaLabel = headerAreaParts[1].toLowerCase();
                headerAreaLabel = headerAreaLabel[0].toUpperCase() + headerAreaLabel.slice(1);
                if (colNames.length === 5) {
                    colNames.unshift(headerAreaLabel);
                }

                kardex.map(asignature => {
                    kardexData.map(data => {
                        if (data[asignature.area]) {
                            // Construcción de serie ¬_¬.
                            const areaParts = asignature.area.split(' ');
                            let area = areaParts[0].toLowerCase();
                            area = area.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                            area = replaces[area] ? replaces[area] : '-';
                            data[asignature.area].push({
                                serie: area,
                                clave: asignature.materiaPlan,
                                materia: asignature.area === 'FUERA DE PLAN Y/O REPROBADAS' ? asignature.tituloLargoMateriaCursada : asignature.tituloLargoMateriaPlan,
                                estatus: asignature.estatus,
                                periodo: asignature.periodoCursado,
                                calificacion: asignature.calificacion !== null ? asignature.calificacion : '-',
                            });
                        }
                    });
                });
                setKardex(kardexData);

                kardex.map(asignature => {
                    kardexDataSM.map(data => {
                        if (data[asignature.area]) {
                            // Construcción de serie ¬_¬.
                            const areaParts = asignature.area.split(' ');
                            let area = areaParts[0].toLowerCase();
                            area = area.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                            area = replaces[area] ? replaces[area] : '-';
                            data[asignature.area].push({
                                serie: area,
                                materia: asignature.area === 'FUERA DE PLAN Y/O REPROBADAS' ? asignature.tituloLargoMateriaCursada : asignature.tituloLargoMateriaPlan,
                                estatus: asignature.estatus,
                                calificacion: asignature.calificacion !== null ? asignature.calificacion : '-',
                            });
                        }
                    });
                });
                setKardexSM(kardexDataSM)

                if (promedios.length) {
                    // const sumaPromedios = promedios.filter(p => !Number.isNaN(p)).reduce((pv, cv) => pv + cv, 0);
                    // setPromedioGeneral(sumaPromedios > 0 ? sumaPromedios / promedios.length : 0);
                }
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ou, user_id]);
    
    React.useEffect(()=>{
        if(isCurrentTab)
        {
            sendEscolarKardexEventToMixPanel();
        }
    },[isCurrentTab]);

    const sendEscolarKardexEventToMixPanel=()=>{
        const eventData = {};
        eventTracker('s_escolar_kardex', eventData, ['mixpanel']);
    };    

    if (!student_id)
        return <NothingFound
            caption={"Sin Kardex"}
            subcaption={"No estás inscrito a ningún plan de estudios actualmente"}
        />

    return (
        <React.Fragment>
            <Grid md={12} justify='center'>
                <Card ref={refPeriodosContainer}>
                    {/* <Card> */}
                    <CardContent>
                        <Grid md={12} justify='center'>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2} justify="space-between">
                                        <Grid item md={4} xs={12}>
                                            <Card className='mb-3' style={{ backgroundColor: "#1C333C" }}>
                                                <CardContent>
                                                    <Grid container >
                                                        <Grid item xs={10}>
                                                            <Typography variant="h5" color='primary'>
                                                                Promedio General
                                                            </Typography>
                                                            {promedioGeneral ?
                                                                <>
                                                                    <Typography variant="h1">
                                                                        {/* {promedioGeneral.toFixed(1)} / {promedioGeneral <= 10 ? 10 : 100} */}
                                                                        { !!promedioGeneral && promedioGeneral !== 'NaN'
                                                                            ? formattedAverage
                                                                            : 0 
                                                                        }
                                                                            <span style={{ color: "#A0A9BA" }}>  /100</span>
                                                                    </Typography>
                                                                    <Typography variant="caption">Última actualización hace 2 min</Typography>
                                                                    <br />
                                                                    <Typography variant="caption" style={classNote}><nobr>*Se excluyen las calificaciones en formato Sistema Americano de Notas (GPA)</nobr></Typography>
                                                                </>
                                                                :
                                                                <CircularProgress style={{ height: '24px', marginLeft: '50px', marginTop: '17px', width: '24px' }} color="primary" />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Grid className='align-info'>
                                                <LogoOu />
                                                <SignalCellularAltIcon fontSize='inherit' color="secondary" />
                                                <Typography color="secondary" variant='caption' className='mr-2'>-</Typography>
                                                {/* <Typography color="secondary" variant='caption' className='mr-2'>Maestria</Typography> */}
                                                <DescriptionIcon fontSize='inherit' color="secondary" />
                                                <Typography color="secondary" variant='caption' className='mr-2'>-</Typography>
                                                {/* <Typography color="secondary" variant='caption' className='mr-2'>Plan de Estudio 2008</Typography> */}
                                            </Grid>
                                            <Typography className='m-1' variant="body1">Consulta de horario</Typography>
                                            <Typography className='ml-1' color="textSecondary" variant="body1">
                                                <nobr>Alumno: {userName} </nobr>
                                            </Typography>
                                            <Typography className='ml-1' color="textSecondary" variant="body1">
                                                Carrera: {
                                                    studentProgram &&
                                                    studentProgram.map(program => `${program.description[1]} (${program.program_id} ${program.major_id})`)
                                                }
                                            </Typography>
                                            <Grid className='align-info'>
                                                <Typography className='ml-1' color="textSecondary" variant="body1">
                                                    Nivel: {
                                                        studentProgram &&
                                                        studentProgram.map(program => program.level_id)
                                                    }
                                                </Typography>
                                                <Typography className='ml-1' color="textSecondary" variant="body1">
                                                    Campus: {
                                                        studentProgram &&
                                                        studentProgram.map(program => program.campus_id)
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container className='mt-2'>
                                        <Grid item>
                                            {/* <PrintPDFBtn btnName={"Imprimir Boleta"} elementId={refPeriodos.current[0]} icon={<PrintIcon />} pageOrientation="p" /> */}
                                            <PrintPDFBtn
                                                btnName={"Imprimir Boleta"}
                                                elementId={refPeriodosContainer.current}
                                                icon={<PrintIcon />}
                                                pageOrientation="p"
                                                btnDisabled={kardex.length === 0}
                                            />
                                            {/* <Button variant='outlined' style={{ backgroundColor: "#1C333C" }}>
                                                <PrintIcon />
                                                <Typography variant="caption">Imprimir Boleta</Typography>
                                            </Button> */}
                                        </Grid>
                                    </Grid>
                                    {kardex.length > 0 ?
                                        isWidthDown("sm", props.width) ?
                                            kardexSM.map((data, index) => {
                                                const title = Object.keys(data)[0];
                                                return (
                                                    <Grid container key={index} className='mt-4'>
                                                        <Grid item xs={12} style={{ overflowX: 'auto' }}>
                                                            {/* <Grid item xs={12} ref={(element) => refPeriodos.current[index] = element} > */}
                                                            <DynamicTable tableTitle={title} tableHeaders={lessColNames} tableRows={data[title]} colSpan={7} />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                            :
                                            kardex.map((data, index) => {
                                                const title = Object.keys(data)[0];
                                                return (
                                                    <Grid container key={index} className='mt-4'>
                                                        <Grid item xs={12}>
                                                            {/* <Grid item xs={12} ref={(element) => refPeriodos.current[index] = element} > */}
                                                            <DynamicTable tableTitle={title} tableHeaders={colNames} tableRows={data[title]} colSpan={7} />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        :
                                        <Grid container className='mt-5'>
                                            <Grid item xs={12} className='mt-1' style={{ textAlign: 'center' }}>
                                                <CircularProgress style={{ height: '24px', marginTop: '5px', width: '24px' }} color="primary" />
                                            </Grid>
                                        </Grid>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </React.Fragment>
    )
}

export default withWidth()(Kardex);
