import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
//Material-UI
import Typography from '@material-ui/core/Typography';
import { Grid, Link, Card, CardContent, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Components
import CouponCheckerV2 from  '../../../shared/components/CouponChecker/CouponCheckerV2';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { log } from '../../../shared/utils/console.js';
//SDK-Point
import { CartService } from '@sdk-point/talisis';
//Redux
import { setOrderPriceDetails } from '../../../redux/actions/checkoutActions';

const useStyles = makeStyles((theme) => ({
    backIcon: {
        fontSize: '24px',
        color: theme.palette.colors.gray[100]
    },
    InfoIcon:{
        width: '16px',
        height: '16px',
        color: theme.palette.colors.orange[300],
        marginRight: "4px",
        [theme.breakpoints.down('sm')]:{
            marginRight: "8px"
        }
    },
    card: {
        padding: "16px 24px",
    },
}));

const cartService = new CartService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY,'');

const CheckoutCoupon = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {order, orderPriceDetails} = useSelector(state => state.checkoutReducer);
    const [validCoupon, setValidCoupon] = useState(false);
    const [alertCoupon, setAlertCoupon] = useState(false);
    const [txtCoupon, setTxtCoupon] = useState('');
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [typeMessage, setTypeMessage] = useState('') // warning, success, error
    const [alertMessage, setAlertMessage] = useState('');
    const [disabledCoupon, setDisabledCoupon] = useState(false);
    
    useEffect(() => {
         if(orderPriceDetails?.discounts.length>0 ) {
            let discount = orderPriceDetails.discounts[0];
            setValidCoupon(() => true);
            setAlertCoupon(() => true);
            setTxtCoupon(() => discount.code)
            setTypeMessage(() => 'success')
            setAlertMessage(() => discount.name)
        }
   }, [])
    
    const handleCheckCoupon = async () => {
        if(typeMessage === 'warning' || typeMessage === 'error'){
            setTypeMessage('')
            setTxtCoupon('')
            setValidCoupon(false);
            setAlertCoupon(false);
        } else {
            try {
                setLoadingCoupon(true);
                const response = await cartService.removeCouponCheckout(order.id, orderPriceDetails?.discounts[0].coupon_id)
                if (response.status === 1) {
                     dispatch(setOrderPriceDetails({...orderPriceDetails, 
                        total: orderPriceDetails?.subtotal,  
                        discounts:[]}
                     ));
                }
                setTypeMessage('')
                setTxtCoupon('')
                setValidCoupon(false);
                setAlertCoupon(false);
            } catch (error) {
                log('Error removeCouponCheckout', error)
            } finally {
                setLoadingCoupon(false);
            }
        }
    }

    return (
        <Grid>
            <Card style={{ background: "#1D1E23", border: "1px solid #2C3236"}}>
                <CardContent className={classes.card}>
                    <Grid container >
                        <Grid item xs={11}>
                            <Typography variant="body1" className="text-white semi-bold">
                                Cupón de descuento
                            </Typography>
                            { disabledCoupon &&
                            <Box style={{ marginTop: 10 }}>
                                <Box width="100%" display="flex">
                                    <Box><i className={clsx(classes.InfoIcon, 'ri-information-line')} /></Box>
                                    <Typography variant='body2' className='text-gray-100'>Ingresa el cupón al seleccionar tu método de pago.</Typography>
                                </Box>
                            </Box>
                            }
                        </Grid>
                        <Grid item xs={1} >
                                {typeMessage !== '' && <Link onClick={handleCheckCoupon}>
                                    {!loadingCoupon ? <i className={clsx(classes.backIcon, 'ri-delete-bin-6-line')} /> : <LoaderContent loading={loadingCoupon}></LoaderContent>}
                                </Link>
                                }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <CouponCheckerV2 orderId= {order.id} 
                                         typeMessage={typeMessage} 
                                         setTypeMessage={setTypeMessage}
                                         validCoupon={validCoupon} 
                                         setValidCoupon = {setValidCoupon}
                                         txtCoupon={txtCoupon} 
                                         setTxtCoupon={setTxtCoupon}
                                         alertCoupon={alertCoupon} 
                                         setAlertCoupon={setAlertCoupon}
                                         loadingCoupon={loadingCoupon} 
                                         setLoadingCoupon={setLoadingCoupon}
                                         alertMessage ={alertMessage}
                                         setAlertMessage = {setAlertMessage}
                                         disabledCoupon={disabledCoupon}/>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
};

export default CheckoutCoupon;