import React from 'react';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';

// @material-ui/core components
import { Box, Typography } from '@material-ui/core';

// shared
import * as paths from '../../../../../routes/paths';

// sdk
import { PAYMENT_METHODS, PRODUCT_TYPES } from '@sdk-point/talisis';

// helper
import { getLastOrderPayment, validateOrderDetails } from '../../paymentConfirmationHelper';

const PaymentInstructions = ({ orderDetails, orderPayments }) => {
    const productTypeId = validateOrderDetails(orderDetails);
    const orderPayment = getLastOrderPayment(orderPayments);

    if (orderPayment?.payment_method_id === PAYMENT_METHODS.SPEI) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="h2" align='center' className={clsx("text-white", "mb-4")}>Has elegido pago con transferencia electrónica</Typography>
                <Box maxWidth="872px">
                    {productTypeId === PRODUCT_TYPES.CURSO && <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>Una vez que se refleje la transferencia en el sistema,<br />recibirás un correo de confirmación y podrás acceder a tu curso.</Typography>}
                    {productTypeId === PRODUCT_TYPES.PROGRAMA && <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>Una vez que se refleje la transferencia en el sistema, recibirás un correo de confirmación y podrás acceder a tu programa y disfrutar de los <Link href={paths.MEMBRESIAS} variant='body1' color='secondary' target='_blank'>beneficios de tu nueva membresía</Link>.</Typography>}
                    {productTypeId === PRODUCT_TYPES.MEMBRESIA && <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>Una vez que se refleje la transferencia en el sistema, recibirás un correo de confirmación y podrás acceder a los <Link href={paths.MEMBRESIAS} variant='body1' color='secondary' target='_blank'>beneficios de tu nueva membresía</Link>.</Typography>}
                    <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>En unos minutos recibirás una copia del número de referencia para realizar la transferencia en tu correo.</Typography>
                    <Typography variant="body1" align='center' className={clsx("text-white")}>¡Agradecemos tu preferencia y confianza!</Typography>
                </Box>
            </Box>
        )
    }
    
    if (orderPayment?.payment_method_id === PAYMENT_METHODS.CASH) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="h2" align='center' className={clsx("text-white", "mb-4")}>Has elegido pago en efectivo</Typography>
                <Box maxWidth="872px">
                    <Typography variant="body1" align='center' className={clsx("text-white", "mb-4")}>En unos minutos recibirás el número de referencia para realizar tu pago en efectivo en tu correo.</Typography>
                    {productTypeId === PRODUCT_TYPES.CURSO && <Typography variant="body1" align='center' className={clsx("text-white")}>Una vez realizado el pago, recibirás un correo de confirmación y podrás acceder a tu curso.<br />¡Agradecemos tu preferencia y confianza!</Typography>}
                    {productTypeId === PRODUCT_TYPES.PROGRAMA && <Typography variant="body1" align='center' className={clsx("text-white")}>Una vez realizado el pago, recibirás un correo de confirmación y podrás acceder a tu programa y disfrutar de los <Link href={paths.MEMBRESIAS} variant='body1' color='secondary' target='_blank'>beneficios de tu nueva membresía</Link>. ¡Agradecemos tu preferencia y confianza!</Typography>}
                    {productTypeId === PRODUCT_TYPES.MEMBRESIA && <Typography variant="body1" align='center' className={clsx("text-white")}>Una vez realizado el pago, recibirás un correo de confirmación y podrás acceder a tus clases.<br />¡Agradecemos tu preferencia y confianza!</Typography>}
                </Box>
            </Box>
        )
    }

    return null;
};

export default PaymentInstructions;