import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';

// shared
import { log } from '../../../../shared/utils/console.js'
import { addProductsToCart } from '../../../../shared/utils/cartHelper.js';
import { userIsLogged } from '../../../../shared/utils/Sessions';

// assets
import useStyles from '../../../../assets/styles/jss/pages/cart/cartStyle';
import HarmonHallHeader from '../HarmonHallHeader/HarmonHallHeader';
import HarmonHallUpsellingCard from './HarmonHallUpsellingCard/HarmonHallUpsellingCard';

// design-system
import LoaderContent from '../../../../design-system/components/Loader/LoaderContent.jsx';
import { applyCart, applyCartProducts, applySelectedAddon } from '../../../../redux/actions/cartActions.js';
import { startCheckout } from '../../../../shared/utils/checkoutHelpers.js';

const ModalCloseButton = ({ className, onClose }) => {
    return <Box fullWidth textAlign='right' className={className}>
        <IconButton color="inherit" onClick={onClose} size="small">
            <Icon className='ri-close-fill text-dark-black-200 font-size-24' />
        </IconButton>
    </Box>
};

const HarmonHallUpsellingModal = ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [selectedAddonId, setSelectedAddonId] = React.useState(null);
    const { user } = useSelector((state) => state.userReducer);
    const { cart, addons } = useSelector((state) => state.cartReducer);
    const isLogged = userIsLogged();

    const handleCardClick = (e, product) => {
        e.preventDefault();
        const selected = selectedAddonId !== product?.id ? product.id : null;
        setSelectedAddonId(selected);
    };

    const handleContinue2Pay = async () => {
        try {
            if (selectedAddonId) {
                const selectedAddon = addons.find(addon => addon.id === selectedAddonId);
                // Preparar el add-on que seleccionó el usuario en el modal 
                const products = [{
                    "product_id": selectedAddon?.id,
                    "product_name": selectedAddon?.name,
                    "product_image_url": selectedAddon?.image_url,
                    "product_description": selectedAddon?.description,
                    "product_type_id": selectedAddon?.product_type_id,
                    "quantity": 1,
                    "product_price_id": selectedAddon?.product_price_id,
                    "unit_price": selectedAddon?.list_price,
                    "discount": 0
                }];
                // Iniciar loading del modal add-on
                setLoading(true);
                //
                let response = await addProductsToCart({
                    cart_id: cart?.id,
                    products: [...products]
                });
                // Actualizar estado reducer del carrito
                dispatch(applyCart(response));
                dispatch(applyCartProducts(response.cart_details));
                // Establecer el add-on seleccionado (desde el modal upselling)
                dispatch(applySelectedAddon(selectedAddon?.id))
                // Actualizar loading del modal add-on
                setLoading(false);
            }
        } catch (e) {
            log("error", e);
        }

        // Redirigir al checkout con el add-on agregado
        startCheckout({
            history,
            order: cart,
            user: user,
            isLogged
        });
    };

    return <Box>
        <Modal
            className={classes.modal}
            open={open}
            onClose={onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Card width='50%' className={classes.modalCard}>
                <LoaderContent loading={loading}>
                    <Box>
                        <ModalCloseButton className={classes.hhUpsellingModalClose} onClose={onClose} />
                        <CardContent width='40%' className={classes.hhUpsellingModalCardContent}>
                            <Grid container spacing={1} direction='column' alignItems='center'>
                                <Grid item className='mb-1'>
                                    <HarmonHallHeader />
                                </Grid>
                                <Grid item className='mb-1'>
                                    <Typography variant="h4" align='center' className='text-hh-yellow mb-1 font-weight-700'>Aprende Inglés ahorrando hasta el 80%</Typography>
                                    <Typography variant="h4" align='center' className=''>Con tu membresía Talisis</Typography>
                                </Grid>
                                {addons?.map((product, index) => {
                                    return <Grid item key={index}>
                                        <HarmonHallUpsellingCard
                                            product={product}
                                            selectedAddonId={selectedAddonId}
                                            onCardClick={(handleCardClick)}
                                        />
                                    </Grid>
                                })}
                                <Grid item>
                                    <Box className={classes.hhUpsellingModalButtonContinue}>
                                        <Button color='primary' endIcon={<Icon className='ri-arrow-right-line' style={{ fontSize: '16px !important' }} />} onClick={handleContinue2Pay}>
                                            <Typography variant='caption' className='font-weight-600' color='inherit'>Continuar a pagar</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Box>
                </LoaderContent>
            </Card>
        </Modal>
    </Box>
};

export default HarmonHallUpsellingModal;