import React from 'react';

// @material-ui/core
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


//@material-ui/icons
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

// core components
import Select from '../../../design-system/components/Form/Select/Select'
import Picker from './Picker'
import InputFile from './InputFile'
import Checkboxes from './Checkboxes'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import GeneralInputFile from '../InputFileButton/GeneralInputFile';

function getInput(field, values, onChange, onRemove){
    let component;

    const handleChangeCheckboxes = (value) => {
        const e = {
            target:{
                name:field.name,
                value
            }
        }
        onChange(e)
    };

    const handleChangeFile = ( file, optionName ) => {
        const data = {
            target: {
                name: field.name,
                value: file,
                type: 'files', 
                optionFile: optionName
            }
        };
        
        onChange(data);
    };

    const handleRemoveOptionFile = ( file, optionName, isSavedFile ) => {
        const data = {
            target: {
                name: field.name,
                value: file,
                optionFile: optionName,
                isSavedFile
            }
        };

        onRemove( data );
    };

    switch (field.type) {
        case 'text':
            component = <TextField 
                margin="dense" 
                variant="outlined" 
                fullWidth 
                {...field} 
                onChange={onChange} 
                value={values[field.name]}/>
            break;
        case 'textarea':
            component = <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    {...field}
                    onChange={onChange} 
                    value={values[field.name]}
                />
            break;
        case 'select':
            component = <Select items={[]} {...field} value={values[field.name]} onChange={onChange}/>
            break;
        case 'datepicker':
            component = <Picker margin="dense" onChange={onChange} value={values[field.name]} {...field}/>
            break;
        case 'file':
            component = <InputFile {...field} style={{paddingTop: 2}} />
            break;
        case 'checkboxes':
            component = <Checkboxes itemsSelected={values[field.name]} {...field} onChange={handleChangeCheckboxes}/>
            break;
        case 'files':
            component = <GeneralInputFile isDisabled={field.disabled} options={field.options} optionValues={values[field.name]} handleChangeFile={handleChangeFile} handleRemoveOptionFile={handleRemoveOptionFile}/>
            break;
        default:
            component = <TextField margin="dense" variant="outlined" fullWidth {...field}/>
            break;
    }

    return component;
}


function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};


const Form = (props) => {

    const {fields, onDragSteps, values, onChange, config, onClickDelete, onClickEdit, readonly, enableActions= false, onRemove, isDragAndDropDisabled = false, withoutSapcing} = props;
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const _items = reorder(
            fields,
            result.source.index,
            result.destination.index
        );

        onDragSteps(_items);
    }

    return(
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable' isDropDisabled={ isDragAndDropDisabled }>
            {(provided) => (
                <Grid container xs={12} spacing={withoutSapcing ? 0 : 2}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {
                        fields.map((field,index) => (
                            <Draggable key={field.id} draggableId={field.id} index={index} isDragDisabled={ isDragAndDropDisabled }>
                            {(provided) => (
                                <React.Fragment key={`${field.id}-${index}`}>
                                    {
                                        field.section ?
                                            <React.Fragment>
                                                {
                                                    index > 0 && <Grid item xs={12}><Divider className="primary"/></Grid>
                                                }
                                                <Grid item xs={12} sm={12} style={{padding: '0 12px'}}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                     <Typography variant="body1" className="font-size-18">{field.section}</Typography>
                                                    {/* <Typography variant="body1" color={field.disabled ? "textSecondary" : ""}>{field.section}</Typography> */}
                                                </Grid>
                                            </React.Fragment>
                                        :
                                        <Grid item xs={12} sm={12} md={field.col}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {
                                                readonly ?
                                                    <TextField
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={values[field.name]}
                                                        // onChange={onChange(index)}
                                                        {...field}
                                                    />
                                                :
                                                    <Box position="relative">
                                                        {
                                                            enableActions &&
                                                            <Box position="absolute" right={12} top="-15px">
                                                                <IconButton aria-label="delete" style={{padding: 1}} color="primary" onClick={() => onClickDelete(field)}>
                                                                    <DeleteOutlineIcon fontSize="small" />
                                                                </IconButton>
                                                                <IconButton aria-label="edit" style={{padding: 1}} color="primary" onClick={() => onClickEdit(field)}>
                                                                    <EditOutlinedIcon fontSize="small" />
                                                                </IconButton>
                                                            </Box>
                                                        }
                                                        { getInput(field, values, onChange, onRemove) }
                                                    </Box>    
                                            }
                                            {/* {
                                                <Divider style={{backgroundColor: '#36BEE3'}}/>
                                            } */}
                                        </Grid>
                                    }
                                </React.Fragment>
                            )}
                            </Draggable>
                        ))
                    }
                </Grid>
            )}
            </Droppable>     
        </DragDropContext>   
    )
};

export default Form;
