import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material components
import { Box, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

// Styles
import useStyles from '../../../../assets/styles/components/cards/landSearchCourseCard/twoSectionMediaStyle';

// Components
import LogoSource from '../../../LogoSource/LogoSource';

// Constants
import { WHITE } from '../../../../constants/color-images';
import { SOURCES } from '../../../../constants/sources';

// Utils
import objectIsEmpty from '../../../../utils/objectIsEmpty';
import {convertSourceToImageResource} from '../../../../utils/placeholderImages';
import {getSourceBySourceAndCompany} from '../../../../utils/source';

const TwoSectionMedia = ({backgroundImage, ratingData, sourceIdLogo}) => {

    const classes = useStyles({backgroundImage});
    
    return (
        <Box className={classes.rootTwoSectionMedia}>
            <Box className={classes.rootTwoSectionContainer}>
                <Box className={classes.ratingContainer}> 
                    {
                        !objectIsEmpty(ratingData) && <>
                            <Box className={classes.ratingNumberContainer}><Typography variant="caption" className={`semi-bold ${classes.ratingNumber}`}>{ratingData.number}</Typography></Box>
                            <Rating name="half-rating" defaultValue={ratingData.number} precision={0.5} readOnly />
                        </>
                    }
                </Box>
                <Box>
                    {
                        !!sourceIdLogo && <LogoSource sourceId={sourceIdLogo} color={WHITE} className={classes[sourceIdLogo]} disabledAutoRes/>
                    }
                </Box>
            </Box>
        </Box>
    );
};

TwoSectionMedia.defaultProps = {
    backgroundImage: '',
    ratingData: {},
};
  
TwoSectionMedia.propTypes = {
    backgroundImage: PropTypes.string,
    ratingData: PropTypes.shape({
        number: PropTypes.number
    }),
    sourceIdLogo: PropTypes.number.isRequired,
};

export default TwoSectionMedia;