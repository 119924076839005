import {
  UPDATE_ZENDESK
} from '../types/notificationTypes';

const initialState = {
    zendesk: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ZENDESK:
            return {
                ...state,
                zendesk: action.payload,
            };
        default:
            return state;
    }
};

