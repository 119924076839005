import palette from '../palette';

export default {
    root:{
        border: 'unset',
        background: 'unset',
        '&:hover':{
            backgroundColor: 'unset'
        },
        // '&.MuiIconButton-colorPrimary': {
        //     color: palette.colors.violet[300]
        // },
    },
    indeterminate: {
        color: '#00C1DE'
    },
    colorPrimary:{
        color: palette.colors.darkBlack[200],
        '&:checked':  {
            color: palette.colors.violet[300]
        }
    }
};