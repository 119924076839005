import React from 'react'

import { Box, Card, CardContent, Chip, Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import ReadMoreLine from '../../../../shared/components/ReadMore/ReadMoreLine';

const useStyles = makeStyles((theme) => ({
    cardContent:{
        padding: '24px 32px'
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.7),
            },
        "& .MuiChip-root": {
            borderRadius: '2em'
        }
    },
    chipsHabilidades: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        height: 'auto',
        padding: '8px 16px',
        margin: 4,
        background: 'transparent',
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: '24px',
        '& .MuiChip-label': {
          whiteSpace: 'pre-wrap',
        },
    },
    textColor:{
        color: theme.palette.colors.gray[400],
    },
    overflowText:{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.colors.gray[400]
    },
    marginBoxRoot:{
        marginTop: 40,
        [theme.breakpoints.down('xs')]:{
            marginTop: 48,
        }
    },
    [theme.breakpoints.down('xs')]:{
        cardContent:{
            padding: 16
        },
    }
}));

const StudyPlanChips = ({ title, chipsArray = [] }) => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box mt={2}>
            <Box>
                <Card>
                    <CardContent className={classes.cardContent}>
                        <Typography variant='h4' className='mb-3'>
                            { title }
                        </Typography>
                        <Box style={{display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                            {
                                <ReadMoreLine 
                                    line={4}
                                    text={
                                        (chipsArray.length > 0) &&
                                        chipsArray.map((infoItem, index) =>
                                            <Chip
                                                key={index}
                                                color="primary"
                                                size="small"
                                                label={<Typography className={classes.overflowText} variant='body2'>{infoItem}</Typography>}
                                                className={classes.chipsHabilidades} 
                                            />
                                        )
                                    }
                                />
                                
                            }
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}

export default StudyPlanChips
