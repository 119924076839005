import React, { Component } from 'react';
import { generatePath } from "react-router";

// @redux
import { connect } from "react-redux"
import * as requestWorkflowActions from '../../../../redux/actions/requestWorkflowActions'
import BodyRequest from '../components/BodyRequest/BodyRequest';
//import routelinks from '../../../../routes/routelinks';


import { log } from '../../../../shared/utils/console.js'

// routes
import * as routelinks from '../../../../routes/routelinks'

//SDK
import { GlobalService,RequestService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const DELETE = 2;

const SCOPE_OU = 1;

class NewRequest extends Component {

    constructor(props){
        super();

        this.state = {
            ...props.request,
            // form:{...props.newRequest.form},
            // workflow: [...props.newRequest.workflow],
            errors:{},
            units:[],
            loading: true,
            body: {},
            breadcrumbItems: [
                {to: '/gestion-de-servicios', label: 'Administracion de Trámites'},
                {label: 'Editar Trámite', title:''}
            ]
        }
    }
    

    async componentDidMount(){
        if(!this.props.request.form.requestId){
            this.getRequest();
        }else{
            this.setState(prevstate =>{
                const breadcrumbItems = [...prevstate.breadcrumbItems];
                breadcrumbItems[1].title = this.props.request.form.title;
                return {
                    ...prevstate,
                    breadcrumbItems,
                    loading: false,
                }
            });
        }
    }    
    
    getRequest = async () => {
        const { match: {params: {id} } } = this.props;
        try {
            const request = await RequestServiceSDK.getRequest(id);
            const assignee = request.companies.map(it => ({full_name: it.name, ...it}));
            const units = await GlobalServiceSDK.getOperatingUnits();
            const ousSelected = units.filter(u => request.companies.some(it => it.id === u.id));

            request.steps.map(it => {

                it.id = it.id.toString();
                it.type = it.request_step_type_id;
                it.assignee = assignee;
                it.type = it.request_step_type_id;
                it.fields = it.form.map(f => ({disabled: true, ...f}));
                it.authorizers = it.authorizers.map(it => {
                    const campus = ousSelected.find(o => o.id === it.parent).campus.map(cs => ({label: cs.id, ...cs}));
                    it.scope_ids = it.scope_type_id === SCOPE_OU ? it.scope_ids : campus.filter(c => it.scope_ids.some(s => s === c.id));
                    return it;
                })
                
                return it;
            });

            const form = {
                requestId: id,
                createdAt: request.created_at,
                title: request.title,
                ou: request.companies.map(it => ({...it, label: it.name})),
                responsable: {
                    first_name: "Miguel", 
                    full_name: request.full_name,
                    id: request.person_responsible_id,
                    last_name: "Oropeza Hernandez"
                },
                type: request.type_id,
                duration: request.duration,
                description: request.description
            }

            this.props.setRequest({
                workflow:request.steps, 
                form
            })

            this.setState(prevstate =>{
                const breadcrumbItems = [...prevstate.breadcrumbItems];
                breadcrumbItems[1].title = request.title;
                return {
                    ...prevstate,
                    breadcrumbItems,
                    form,
                    workflow: request.steps,
                    loading: false,
                }
            });
        } catch (e) {
            log('error', e)
        }
    }

    handleClickActionStep = (action, step) => {
        const { match: {params: {id} } } = this.props;
        if(action.id === DELETE){
            this.props.deleteStep(step.id);
        }else{
            let stepId = step.id;
            let path = generatePath(routelinks.GESTION_SERVICIO_EDITAR_PASO, {id,  stepId});
            //this.props.history.push(`/requests-managment/edit/${id}/step/${step.id}`);
            this.props.history.push(path);
        }
    }


    handleChange = e => {
        const {name, value} = e.target;
        const errors = {...this.state.errors};

        if(value){
            delete errors[name];
        }

        this.setState({
            form:{
                ...this.state.form,
                [name]: value,
            },
            errors
        });

        this.props.updateRequestWorkflowForm({
            ...this.state.form,
            [name]: value,
        })
    };

    handleChangeWorkflow = steps => {
        const errors = {...this.state.errors};
        if(errors.workflow && steps.length){
            delete errors.workflow;
        }
        this.setState({workflow: steps, errors})
    }

    handleDragSteps = (workflow) => {
        this.setState({workflow});
        this.props.setWorkflow(workflow);
    }

    hanldeSubmit = async (body) => {
        this.setState({loading: true})

        try {
            await RequestServiceSDK.updateRequest(this.props.match.params.id, body);
            this.setState({loading: false})
            this.props.history.push('/gestion-de-servicios')
        } catch (e) {
            this.setState({loading: false})
        }
    }

    handleChangeErrors = (errors) => {
        this.setState({ errors })
    }

    handleChangeStep = step => this.props.updateStep(step);

    handleGeneratePath = id => event => {
        const path = generatePath(routelinks.GESTION_SERVICIO_CONFIGURACION_PASO, {id});
        return path
    }

    render() {
        return (
            <BodyRequest {...this.state} 
                //path={`/requests-managment/edit/${this.props.match.params.id}/new/step`}
                path={this.handleGeneratePath(this.props.match.params.id)}
                onClickActionStep={this.handleClickActionStep}
                onChange={this.handleChange}
                onChangeWorkflow={this.handleChangeWorkflow}
                onDragSteps={this.handleDragSteps}
                onSubmit={this.hanldeSubmit}
                onChangeErrors={this.handleChangeErrors}
                onChangeStep={this.handleChangeStep}
            />
        );
    }
}

const mapStateToProps = ({ requestWorkflowReducer }) => ({request: requestWorkflowReducer});

export default connect(mapStateToProps, {...requestWorkflowActions})(NewRequest);