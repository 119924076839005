import React, { useState } from 'react';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Divider, Hidden, Icon, InputBase,Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import "moment/locale/es";

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 16px',
      borderRadius: 8,
      gap: 12,
      border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`
    },
    datePicker:{
        ...theme.typography.bodyTextSmallSemiBold,
        color: theme.palette.colors.gray[300],
        '& .MuiInputBase-input':{
            padding: '8px !important'
        }
    },
    endDate:{
        '& .MuiInputBase-input':{
            paddingRight: '0px !important'
        }
    }
}));

const DateRangePicker = ({startDate, endDate, onChangeStartDate, onChangeEndDate}) => {
    const classes = useStyles();

    const [valueStartDate, setValueStartDate] = useState(null);
    const [valueEndDate, setValueEndDate] = useState(null);
    const [minDate, setMinDate] = useState(new Date());


    React.useEffect(() => {
        setValueEndDate(endDate);
        setValueStartDate(startDate);
    }, [endDate, startDate])

    const handleStartDateChange = (date) => {
        onChangeStartDate(date);
        setMinDate(date);
    };

    const handleEndDateChange = (date) => {
        onChangeEndDate(date);
    };
    const handleStartDateClose = () => {
        if (moment(valueStartDate).isAfter(valueEndDate)) {
            onChangeEndDate(valueStartDate);
        }
    };

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <Box display="flex" alignItems="center" className={classes.root}>
                <Hidden xsDown>
                    <Icon className='ri-calendar-2-line text-fill-stroke-100 font-size-22' style={{marginTop: 4}}/>
                    <Divider orientation="vertical" className='bg-fill-stroke-200' style={{height: 26}}/>
                </Hidden>
                <Box display='flex'  alignItems="center">
                    <Typography variant='caption' className='text-fill-stroke-100'>Del</Typography>
                    <DatePicker
                        className={classes.datePicker}
                        disableToolbar
                        variant="inline"
                        format="DD MMMM YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date picker inline"
                        value={valueStartDate}
                        onChange={handleStartDateChange}
                        onClose={handleStartDateClose}
                        TextFieldComponent={InputBase}
                        autoOk={false}
                        placeholder='DD-MM-YYYY'
                    />
                    <Typography variant='caption' className='text-fill-stroke-100'>Al</Typography>
                    <DatePicker
                        className={clsx(classes.datePicker, classes.endDate)}
                        disableToolbar
                        variant="inline"
                        format="DD MMMM YYYY"
                        label="Desde"
                        value={valueEndDate}
                        onChange={handleEndDateChange}
                        TextFieldComponent={InputBase}
                        minDate={minDate}
                        autoOk={false}
                        placeholder='DD-MM-YYYY'
                    />
                </Box>
            </Box>
        </MuiPickersUtilsProvider>
    );
};

DateRangePicker.defaultProps = {
    onChangeStartDate: () => {},
    onChangeEndDate: () => {},
    startDate: new Date(),
    endDate: new Date(),
};


export default DateRangePicker;
