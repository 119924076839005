import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Styles
import useStyles from '../../../assets/styles/components/cards/itemListCourseCard/itemListCourseCardStyle';

// Components
import Base from '../components/Base';
import ItemListCourseCardBody from './components/ItemListCourseCardBody';
import PriceAndMembership from '../components/PriceAndMembership';
import LogoSource from '../../LogoSource/LogoSource';

// Constants
import { WHITE_COLOR } from '../../../constants/color-images';

// Material components
import { Box, Icon, IconButton, Typography } from '@material-ui/core';

const ItemListCourseCard = ({courseId, userId, media, content, isLogged, onClick, onClickMixPanelCard, onSaveCourse}) => {
    const classes = useStyles();

    const [saveStatus, setSaveStatus] = React.useState(false);
    const [updatingStatus, setUpdatingStatus] = React.useState(false);

    const handleClick = (id) => () => {
        if(!!onClickMixPanelCard) onClickMixPanelCard();
        if (!!onClick) onClick({id});
    };
    
    //funcion para guardar los cursos en favoritos solamente
    const handleSaveUnsaveCourse = async (e) => {
        const newStatus = !saveStatus;

        setUpdatingStatus(true);
        setSaveStatus(newStatus);

        const response = await onSaveCourse(e, newStatus, userId, courseId);

        setSaveStatus(response);
        setUpdatingStatus(false);
    };

    return (
        <Base onClick={handleClick(courseId.toString())} className={classes.rootBase} hasClickEvent={!!onClickMixPanelCard || !!onClick}>
            <ItemListCourseCardBody media={media}>
                <>
                    <Box className={classes.dataSection}>
                        <Box className={classes.title}>
                            <Typography variant='overline' className={clsx(classes.courseLabel, 'semi-bold')}>CURSO</Typography>
                            {
                                (isLogged && !!onSaveCourse) &&
                                <IconButton disabled={updatingStatus} onClick={handleSaveUnsaveCourse} className="p-0">
                                    <Icon className={clsx(saveStatus ? 'ri-bookmark-fill text-violet-300' : 'ri-bookmark-line text-dark-black-200', 'font-size-24')}/>
                                </IconButton>
                            }
                        </Box>
                        <Box className={classes.imageSourceContainer}>
                            {
                                !!media.sourceIdLogo && <LogoSource sourceId={media.sourceIdLogo} className={classes[media.sourceIdLogo]} color={WHITE_COLOR} disabledAutoRes/>
                            }
                        </Box>
                        <Box>
                            <Typography variant='body2' className={clsx(classes.courseName, 'semi-bold')}>{content.courseName}</Typography>
                        </Box>
                    </Box>                
                    <Box>
                        <PriceAndMembership content={content} isListItemCard={true}/>
                    </Box>
                </>
            </ItemListCourseCardBody>
        </Base>
    );
};

ItemListCourseCard.defaultProps  = {
    isLogged: false,
};

ItemListCourseCard.propTypes = {    
    courseId: PropTypes.number.isRequired,
    media: PropTypes.shape({
        backgroundImage: PropTypes.string,
        ratingData: PropTypes.shape({
            number: PropTypes.number,
            listNumber: PropTypes.number,
        }),
        sourceIdLogo: PropTypes.number.isRequired,
    }).isRequired,
    content: PropTypes.shape({
        hasMembership: PropTypes.bool,
        courseName: PropTypes.string.isRequired,
        price: PropTypes.shape({
            number: PropTypes.number,
            listNumber: PropTypes.number,
        }),
        showLabel: PropTypes.bool.isRequired,
        showPrice: PropTypes.bool.isRequired,
        labelToShow: PropTypes.string.isRequired,
        typeLabel: PropTypes.number.isRequired,
    }).isRequired,
    isLogged: PropTypes.bool,
    onClick: PropTypes.func,
    onClickMixPanelCard: PropTypes.func,
    onSaveCourse: PropTypes.func,
};

export default ItemListCourseCard;