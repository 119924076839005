import { makeStyles } from '@material-ui/core/styles';
import { SOURCES }  from '../../../../../constants/sources';

export default makeStyles((theme) => ({
    rootBase: {
        height: '158px',
        width: '328px',
    }, 
    imageSourceContainer: {
        marginTop: '8px',
    },
    dataSection: {
        padding: '12px',
        height: '117px',
        width: '204px',
    },
    title:{
        display:"flex" ,
        justifyContent:"space-between",
        mb:"11px",
        alignItems:"center"

    },
    courseLabel: {
        color: theme.palette.colors.blue[300],
    },
    courseName: {
        marginTop: '4px',
        color: theme.palette.colors.grey[100],
        display: '-webkit-box !important',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal',
        overflow: 'hidden'
    },
    [SOURCES.ADVENIO] : {
        height: '18px',
    },
    [SOURCES.NEO] : {
        height: '18px',
    },
    [SOURCES.COURSERA] : {
        height: '18px',
    },
    [SOURCES.LINKEDIN_LEARNING] : {
        height: '18px',
    },
    [SOURCES.TALISIS] : {
        height: '18px',
    },
    [SOURCES.UERRE] : {
        height: '18px',
    },
    [SOURCES.UNID] : {
        height: '18px',
    },
    [SOURCES.HH] : {
        height: '18px',
    },
    [SOURCES.IESALUD] : {
        height: '18px',
    },
    [SOURCES.GOOGLE] : {
        height: '18px',
    },
    [SOURCES.CREHANA] : {
        height: '18px',
    },
    [SOURCES.HUBSPOT] : {
        height: '18px',
    },
    [SOURCES.IE_UNIVERSITY] : {
        height: '22px',
    },
    [SOURCES.AWS] : {
        height: '18px',
    },
    [SOURCES.SIMPLICITY] : {
        height: '18px',
    },
    [SOURCES.CONEKTA] : {
        height: '18px',
    },
    [SOURCES.AWS_SKILL_BUILDER] : {
        height: '18px',
    },
}));