export const HCClasses = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(5),
            width: '25ch',
        },
    },
    loading: {
        marginTop: '10%',
        textAlign: 'center',
    },
    emailInputContainer: {
        display: 'flex',
    },
    fieldset: {
        borderRadius: '8px',
        borderColor: theme.palette.colors.grey[500],
    },
    gridContainer: {
        border: `1px solid ${theme.palette.colors.grey[50]}`,
        borderRadius: '8px',
        padding: '16px !important',
    },
    ul_li_list: {
        listStyleType: 'square',
    },
    green: {
        fill: "green",
    },
    blue: {
        fill: theme.palette.info.light,
    },
    yellow: {
        fill: "yellow",
    },
    red: {
        fill: "red",
    },
    valign_center: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textAlignRight: {
        textAlign: 'right',
    },
    borderNone: {
        border: 'none',
    },
    textDecorationUnderline: {
        textDecoration: 'underline',
    },
    valignTop: {
        verticalAlign: 'top',
    },
    displayInlineFlex: {
        display: 'inline-flex',
    },
    displayBlock: {
        display: 'block',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
});