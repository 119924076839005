import palette from '../palette';

export default {
    root:{
      // color: palette.grey[100],
      marginBottom: 32
    },
    flexContainer:{
      borderBottom: '1px solid ' + palette.colors.fillStroke[100],
      minHeight: 47
    }
};