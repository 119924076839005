import React, { useState} from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import confetti from 'canvas-confetti';
//Material-ui
import { Box, Button, Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
//Shared
import LoaderContent from '../Loader/LoaderContent';
import { log } from '../../../shared/utils/console.js'
//SDK-POINT
import { CartService } from '@sdk-point/talisis';
//Redux
import {setOrderPriceDetails} from '../../../redux/actions/checkoutActions';

const useStyles = makeStyles((theme) => ({
    btn: {
        width: '100%',
        paddingLeft: "12px",
        paddingRight: "12px"
    },
    input: {
        width: '100%',
        marginRight: '46px',
        borderColor: `${theme.palette.colors.violet[300]}`,
        '& div':
        {
            borderColor: `${theme.palette.colors.violet[300]}`
        }
    },
    inputCode:{
        width: "100%",
        pointerEvents: props => props.typeMessage !== '' ? "none" : "inherit",
        backgroundColor:props => props.typeMessage === 'success' ? 'rgb(0 146 86 / 10%)': props.typeMessage === 'warning' && 'rgb(179 157 47 / 10%)',
        '& .MuiInputBase-root':{
            marginTop: "0px",
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
            transform: "translate(0px, -15px) scale(0.75) !important",
        }
    },
    inputIconSuccess:{
        fontSize: '24px',
        color: theme.palette.colors.success[300]
    },
    inputIconWarning:{
        fontSize: '24px',
        color: theme.palette.colors.warning[300]
    }
}));

const cartService = new CartService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
const CouponCheckerV2 = (props) => {
    const {orderId, validCoupon, setValidCoupon, alertCoupon, setAlertCoupon, txtCoupon, setTxtCoupon, loadingCoupon, setLoadingCoupon , typeMessage, setTypeMessage, disabledCoupon, alertMessage, setAlertMessage} = props;
    const classes = useStyles({typeMessage});
    const dispatch = useDispatch();
    const {orderPriceDetails} = useSelector(state => state.checkoutReducer);

    const randomInRange = (min, max) => Math.random() * (max - min) + min;

    const checkCoupon =  async () => {
       try {
            if (txtCoupon.length > 0) {
                setLoadingCoupon(true);
                setAlertCoupon(false);
                const response = await cartService.couponCheckerCheckout(orderId, txtCoupon);
                if (!response.valid) {
                    setTypeMessage(response.typeMessage)
                    setValidCoupon(false);
                    setAlertMessage(response.message);
                    setAlertCoupon(true);
                } else {
                    dispatch(setOrderPriceDetails({...orderPriceDetails, 
                                                   total: (parseInt(orderPriceDetails.total)-response.totalDiscount),  
                                                   discounts:[{ description:'Descuento', 
                                                                discount:response.totalDiscount, 
                                                                coupon_id:response.couponData?.id, 
                                                                name:response.couponData.name, 
                                                                code:response.couponData.code}]}
                                                ));
                    setTypeMessage(response.typeMessage)
                    setValidCoupon(true);
                    setAlertMessage(response.message);
                    setAlertCoupon(true);
                    confetti({
                        angle: randomInRange(55, 125),
                        spread: randomInRange(50, 70),
                        particleCount: randomInRange(50, 100),
                        origin: { y: 0.6 }
                    });
                }
                setLoadingCoupon(false);
            } else {
                setValidCoupon(false);
                setAlertMessage("Escriba un código para continuar");
                setAlertCoupon(true);
            }
       } catch (error) {
            log(error);
            setAlertCoupon(true);
       }
    }

    const handleCheckCoupon = (e) => {
        validCoupon !== true &&
            setTxtCoupon(e.target.value);
    };

    return (
        <Box className='pt-3'>
            <Grid container spacing={1} direction="column" >
                <Grid container spacing={1} direction="row">
                    <Grid item xs={typeMessage === '' ? 9:12}>
                        <TextField className={`${classes.inputCode} text-gray-100`} id="outlined-basic" placeholder='Ingresa código de cupón' variant="outlined" value={txtCoupon} onChange={handleCheckCoupon} style={{ border: typeMessage==='success' ? '1px solid #01ED8C': typeMessage==='warning'  && txtCoupon.length>0 &&  '1px solid #FCDC43', borderRadius:8}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{ typeMessage==='success' && <i className={clsx(classes.inputIconSuccess,'ri-checkbox-circle-fill')} />} {typeMessage==='warning' && txtCoupon.length>0 && <i className={clsx(classes.inputIconWarning,'ri-error-warning-fill')} />}</InputAdornment>,
                                backgroundColor: "red"
                              }}
                              disabled={disabledCoupon}
                        />
                    </Grid>
                    {typeMessage==='' && <Grid item xs={3}>
                        <Button className= {classes.btn} variant="contained" disabled={txtCoupon.length>0 ? false:true} onClick={checkCoupon} color='primary' style={{maxWidth: "96px", height: "41px" }}>
                            <LoaderContent loading={loadingCoupon}>
                                {
                                    loadingCoupon === true ?
                                        "Espere..."
                                            :
                                        "Aplicar"
                                }
                            </LoaderContent>
                        </Button>
                    </Grid>
                    } 
                </Grid>
                 { alertCoupon && txtCoupon.length>0 && <Grid item xs="auto">
                    <Box hidden={!alertCoupon}>
                        <Alert icon={false} style={{ background: 'transparent', fontSize: 14, color:validCoupon === true? '#01ED8C': '#FCDC43', padding:0}} hidden={true}>{alertMessage}</Alert>
                    </Box>
                </Grid>
                }
            </Grid>
        </Box>
    )
}

export default CouponCheckerV2;
