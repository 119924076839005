import {
    SOURCES
} from './sources'

import uerreLogoWhite from '../../assets/images/logos/sources/uerre-white.svg';
import awsLogoWhite from '../../assets/images/logos/sources/aws-white.svg';
import courseraLogoWhite from '../../assets/images/logos/sources/coursera-white.svg';
import crehanaLogoWhite from '../../assets/images/logos/sources/crehana-white.svg';
import googleLogoWhite from '../../assets/images/logos/sources/google-skillshop-white.svg';
import hhLogoWhite from '../../assets/images/logos/sources/harmon-hall-white.svg';
import hubspotLogoWhite from '../../assets/images/logos/sources/hubspot-white.svg';
import ieLogoWhite from '../../assets/images/logos/sources/ie-university-white.svg';
import linkedinLearningLogoWhite from '../../assets/images/logos/sources/linkedIn-Learning-white.svg';
import unidLogoWhite from '../../assets/images/logos/sources/unid-white.svg';

export const LOGO_SOURCE = {
    ADVENIO: 3,
    TALISIS: 7,
    UERRE: 8,
    UNID: 9,
    HH: 10,
    IESALUD: 11,
    GOOGLE: 12,
    COURSERA: 5,
    LINKEDIN_LEARNING: 6,
    TALISISCH: 21,
}

export const UERRE_IMAGES_PLACEHOLDER = [
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_1.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_2.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_3.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_4.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_5.jpg",
];

export const UNID_IMAGES_PLACEHOLDER = [
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_1.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_2.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_3.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_4.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_5.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_6.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_7.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_8.jpg",
];

export const ALIADOS_IMAGES_PLACEHOLDER = ({
    5: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_coursera.jpg",
    6: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_linkedin.jpg",
   12: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_google.jpg",
   13: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_crehana.jpg",
   14: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_hubspot.jpg",
   15: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_ieuniversity.jpg",
   16: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_aws.jpg",
});

export const PROGRAMS_IMAGES_PLACEHOLDER = ({
    "licenciatura": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Licenciatura.png",
    "bachillerato": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Bachillerato.png",
    "maestria": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Maestria.png",
});

export const IMAGE_DEFAULT_PLACEHOLDER = "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default.jpg";

export const LOGOS_RESPONSIVE = ({
    [LOGO_SOURCE.UERRE]: "https://point-files.s3.us-west-2.amazonaws.com/public/logos-ous/uerre-responsive.svg",
    [LOGO_SOURCE.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis_icon.svg",
    [LOGO_SOURCE.UNID]: "https://point-files.s3.us-west-2.amazonaws.com/public/logos-ous/unid-responsive.svg",
    [LOGO_SOURCE.HH]: "https://point-files.s3.us-west-2.amazonaws.com/public/logos-ous/hh-responsive.svg",
    [LOGO_SOURCE.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/public/logos-ous/iesalud-responsive.svg",
    [LOGO_SOURCE.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/public/logos-ous/advenio-responsive.svg",
    [LOGO_SOURCE.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/public/logos-ous/google-responsive.svg",
    [LOGO_SOURCE.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/public/logos-ous/coursera-responsive.svg",
    [LOGO_SOURCE.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/public/logos-ous/in-learning-responsive.svg",
    13: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/crehana.svg",
    14: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/hubspot.svg",
    15: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/ie_university.svg",
    16: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/aws.svg",
    17: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/Symplicity.svg",
    18: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/NEO.svg",
    19: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/aws.svg",
    [LOGO_SOURCE.TALISISCH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis_academy.svg",
})

export const LOGO_IMAGES = ({
    [LOGO_SOURCE.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/advenio.svg",
    4: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/l%26d.svg",
    [LOGO_SOURCE.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/coursera.svg",
    [LOGO_SOURCE.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/learning.svg",
    [LOGO_SOURCE.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis.svg",
    [LOGO_SOURCE.UERRE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/uerre.svg",
    [LOGO_SOURCE.UNID]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/unid.svg",
    [LOGO_SOURCE.HH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/harmonhall.svg",
    [LOGO_SOURCE.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/isesalud.svg",
    [LOGO_SOURCE.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/google_skillshop.svg",
    13: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/crehana.svg",
    14: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/hubspot.svg",
    15: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/ie_university.svg",
    16: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/aws.svg",
    17: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/Symplicity.svg",
    18: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/NEO.svg",
    19: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/aws.svg",
    [LOGO_SOURCE.TALISISCH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis_academy.svg",
});

export const LOGO_IMAGES_CARDS_SIZES_CONTENT= ({
    5: "98px",
    6: "102px",
    [LOGO_SOURCE.TALISIS]: "82px",
    [LOGO_SOURCE.UERRE]: "82px",
    [LOGO_SOURCE.UNID]: "64.5px",
    [LOGO_SOURCE.HH]: "110px",
    [LOGO_SOURCE.IESALUD]: "64.5px",
   12: "166px",
   13: "133.5px",
   14: "90.5px",
   15: "76px",
   16: "120px",
});

export const LOGO_IMAGES_CARDS_SIZES_MEDIA = ({
    5: "88px",
    6: "92px",
    [LOGO_SOURCE.TALISIS]: "72px",
    [LOGO_SOURCE.UERRE]: "72px",
    [LOGO_SOURCE.UNID]: "54.5px",
    [LOGO_SOURCE.HH]: "100px",
    [LOGO_SOURCE.IESALUD]: "54.5px",
   12: "156px",
   13: "123.5px",
   14: "80.5px",
   15: "81px",
   16: "110px",
});

export const LANDING_BRAND_IMAGES = {
    [SOURCES.UERRE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/cards/UERRE.png",
    [SOURCES.UNID]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/cards/UNID.png",
    [SOURCES.HH]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/cards/HH.png",
};

export const LOGOS = ({
    white:{
        [SOURCES.ADVENIO]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/advenio.svg",
        4: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/l%26d.svg",
        [SOURCES.COURSERA]: courseraLogoWhite,
        [SOURCES.LINKEDIN_LEARNING]: linkedinLearningLogoWhite,
        [SOURCES.TALISIS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/talisis.svg",
        [SOURCES.UERRE]: uerreLogoWhite,
        [SOURCES.UNID]: unidLogoWhite,
        [SOURCES.HH]: hhLogoWhite,
        [SOURCES.IESALUD]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/isesalud.svg",
        [SOURCES.GOOGLE]: googleLogoWhite,
        [SOURCES.CREHANA]: crehanaLogoWhite,
        [SOURCES.HUBSPOT]: hubspotLogoWhite,
        [SOURCES.IE_UNIVERSITY]: ieLogoWhite,
        [SOURCES.AWS]: awsLogoWhite,
        17: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/Symplicity.svg",
        18: "https://point-files.s3.us-west-2.amazonaws.com/market-place/logos/NEO.svg",
        [SOURCES.AWS_SKILL_BUILDER]: awsLogoWhite,
    }
});