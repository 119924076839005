import React from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// components
import CourseCard from '../CourseCard/CourseCard';

// shared components 
import NothingFound from '../../../../shared/components/NothingFound/NothingFound';
import { log } from '../../../../shared/utils/console.js'
import Course_1 from "../../../../../src/assets/course_1.png";
import Course_2 from "../../../../../src/assets/liderazgo.jpg";
import Course_3 from "../../../../../src/assets/tv.jpg";

import { getCourses, getRegister, getLaunch, setRegister } from "../../../../services/CoursesService";

const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#049CB4"),
        fontWeight: "bold",
        textTransform: "capitalize",
        backgroundColor: "#00C1DE",
        '&:hover': {
            backgroundColor: "#049CB4",
        },
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: "center",
        padding: theme.spacing(3)
    }
}));

const AllCourses = (props) => {
    const history = useHistory();
    const { neoCourses, thirdPartyCourses, userIdNeo, user } = props;
    //const [neoFiltered, setNeoFiltered] = React.useState([]);
    /*const [pointCourses, setPointCourses] = React.useState([
        {
            name: "Curso de Liderazgo",
            id: "6db91a08-ccf2-4f9e-b340-185456a5ac60",
            description: "Este programa esta diseñado para que desarrolles habilidades que conforman a un buen líder. La primer persona de la que debemos ser líderes somos nosotros mismo...",
            image_url: Course_2,
            start_date: "2022-01-11 00:00:00.000", 
            end_date: null,
            estimated_time: 19000,
            registerId: "81f651ee-3836-47e6-a877-643977afe993",
            progress_percentage: 0,
            urlCourse: "https://cloud.scorm.com/content/courses/FNI9KCYG2J/6db91a08-ccf2-4f9e-b340-185456a5ac60/0/index.html?actor=%7B%22name%22%3A%5B%22Francisco%20Javier%20Hinojosa%20Garza%22%5D%2C%22account%22%3A%5B%7B%22accountServiceHomePage%22%3A%22http%3A%2F%2Fcloud.scorm.com%22%2C%22accountName%22%3A%22FNI9KCYG2J%7C394%22%7D%5D%2C%22objectType%22%3A%22Agent%22%7D&endpoint=https%3A%2F%2Fcloud.scorm.com%2Flrs%2FFNI9KCYG2J%2F&auth=Basic%20OmZkZWJjNTJkLWFmNGEtNDk5Ni1hNmUxLWQxYmY5MDI1MzNkNg%3D%3D&content_token=b971b40c-2da7-471b-8918-4bd66962eda0&externalRegistration=KDEbrRIgnx_wv5_U48MNKkZnvQmCGkijH8eVwB6trDEINQ7Y1SJ_4ySfVZ-uXSL8yv9J_0u7ai39IrasHZB89Og42Tza_vEpsz_jHqA_VpinPD4UAMtQGNnvi_p8q5k&activity_id=http%3A%2F%2FCWfb5ItTb_CF_e3VCQQau3_dLiu3buDC_rise&registration=146573b4-5589-4385-af6a-fcbd1a25359a&externalConfiguration=4Qp4L7a0EOYhT763MXeaC2AtEzDvdrDR2dMHJisW9UJ3R3YKQYcy4h3-jxXwr-FhX7qGgMkR-GOr3AlxwTTe_Afv-tfwA6RqHQ&grouping=http%3A%2F%2FCWfb5ItTb_CF_e3VCQQau3_dLiu3buDC_rise&content_endpoint=https%3A%2F%2Fcloud.scorm.com%2Flrs%2FFNI9KCYG2J%2Fcontent%2F&width=988&height=724&left=466&top=0#/"
        }
    ]);*/
    let neoLink = "";

    switch (user.ou) {
        case 'unid':
            neoLink = `//unid.neolms.com/class/show/`;
            break;
        case 'talisis':
            neoLink = `//talisisch.neolms.com/class/show/`;
            break;
        default: //uerre
            neoLink = `//talisis.neolms.com/class/show/`;
            break;
    }

    const handleGoToCatalog = () => {
        history.push("/");
    };

    React.useEffect(() => {
      //  log("ALL COURSES");
      //  log(neoCourses);
        /*
        log("NEO FILTERED");
        const tempArray = neoCourses.reduce(function(a,b){
            if (a.indexOf(b) < 0 ) a.push(b);
            return a;
        },[]);
        setNeoFiltered(tempArray);
        log(neoFiltered);*/
        /*getCourses().then(async response => {
            //log("RESPONSE");
            //log(response);

            //log("USER");
            //log(user);
            //CARGAR LISTA DE CURSOS EN UN ESTADO
        });*/
    }, []);

    return (
        <React.Fragment>
            {neoCourses.length > 0 || thirdPartyCourses.length > 0 ?
                <Grid container spacing={3}>
                    {
                        neoCourses.map(el => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={el.id}>
                                    <CourseCard
                                        {...el}
                                        progress={el.progress || el.progress_percentage}
                                        source={el.company_id || user.ouLabel}
                                        subject={el.register_id ? "":el.subject_description}
                                        to={el.register_id ? "course-s":(el.course_url ?? `${neoLink}${el.id}?user=${userIdNeo}`)}
                                        registerId={el.register_id}
                                        courseId={el.id}
                                        userId={user.person_id}
                                        //urlCourse={ getLaunchUrl(el.register_id) }
                                    />
                                </Grid>
                            )
                        })
                    }
                    {
                        thirdPartyCourses.map(el => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={el.id}>
                                    <CourseCard
                                        {...el}
                                        progress={el.progress || el.progress_percentage}
                                        source={el.source_name}
                                        subject={el.subject_description}
                                        to={`//${el.course_url.replace(/(^\w+:|^)\/\//, '')}`}
                                    />
                                </Grid>
                            )
                        })
                    }
                   
                </Grid>:
                <Box textAlign="center">
                    <NothingFound
                        caption="Todavía no estas inscrito en ninguna clase"
                        subcaption="Empieza explorando nuestro catálogo donde puedes elegir las clases que mejor se acomoden a tu plan de estudios o contacta a nuestro departamente de admisiones en:"
                        type="in-progress-courses" />
                    <BlueButton onClick={handleGoToCatalog}>Ver catálogo de cursos</BlueButton>
                </Box>
            }
        </React.Fragment>
    );
};

export default AllCourses;