import React, {useEffect} from 'react';
import { generatePath } from "react-router";

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

// shared - core components
import Breadcrumb from '../../../../shared/components/Breadcrumb/Breadcrumb'
import Picker from '../../../../shared/components/Form/Picker'
import LoaderContent from '../../../../shared/components/Loader/LoaderContent'
import { log } from '../../../../shared/utils/console.js'

import WorkFlow from '../components/Workflow/Workflow'
import BodyRequest from '../components/BodyRequest/BodyRequest';

// @redux
import { connect } from "react-redux"
import * as requestWorkflowActions from '../../../../redux/actions/requestWorkflowActions'

// routes
import * as routelinks from '../../../../routes/routelinks'

//SDK
import { GlobalService,RequestService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const SCOPE_OU = 1;

const ShowRequest = (props) => {
    const [state, setState] = React.useState({
        ...props.request,
        // form:{...props.newRequest.form},
        // workflow: [...props.newRequest.workflow],
        errors:{},
        units:[],
        loading: true,
        body: {},
        breadcrumbItems: [
            {to: '/gestion-de-servicios', label: 'Administracion de Trámites'},
            {label: 'Trámite', title:''}
        ]
    })

    useEffect(() => {
        getRequest();
    }, []);

    const getRequest = async () => {
        const { match: {params: {id} } } = props;
        try {
            const request = await RequestServiceSDK.getRequest(id);
            const assignee = request.companies.map(it => ({full_name: it.name, ...it}));
            const units = await GlobalServiceSDK.getOperatingUnits();
            const ousSelected = units.filter(u => request.companies.some(it => it.id === u.id));

            request.steps.map(it => {

                it.id = it.id.toString();
                it.type = it.request_step_type_id;
                it.assignee = assignee;
                it.type = it.request_step_type_id;
                it.fields = it.form.map(f => ({disabled: true, ...f}));
                it.authorizers = it.authorizers.map(it => {
                    const campus = ousSelected.find(o => o.id === it.parent).campus.map(cs => ({label: cs.id, ...cs}));
                    it.scope_ids = it.scope_type_id === SCOPE_OU ? it.scope_ids : campus.filter(c => it.scope_ids.some(s => s === c.id));
                    return it;
                })
                
                return it;
            });

            const form = {
                requestId: id,
                createdAt: request.created_at,
                title: request.title,
                ou: request.companies.map(it => ({...it, label: it.name})),
                responsable: {
                    first_name: "Miguel", 
                    full_name: request.full_name,
                    id: request.person_responsible_id,
                    last_name: "Oropeza Hernandez"
                },
                type: request.type_id,
                duration: request.duration,
                description: request.description
            }

            log(form);

            props.setRequest({
                workflow:request.steps, 
                form
            })

            setState(prevstate =>{
                const breadcrumbItems = [...prevstate.breadcrumbItems];
                breadcrumbItems[1].title = request.title;
                return {
                    ...prevstate,
                    breadcrumbItems,
                    form,
                    workflow: request.steps,
                    loading: false,
                }
            });
        } catch (e) {
            log('error', e)
        }
    }

    // const getRequest = async () => {
    //     const { match: {params: {id} } } = props;
    //     try {
    //         const request = await RequestServiceSDK.getRequest(id);
    //         const assignee = request.companies.map(it => ({full_name: it.name, ...it}));
    //         const units = await GlobalServiceSDK.getOperatingUnits();
    //         const ousSelected = units.filter(u => request.companies.some(it => it.id === u.id));

    //         request.steps.map(it => {

    //             it.id = it.id.toString();
    //             it.type = it.request_step_type_id;
    //             it.assignee = assignee;
    //             it.type = it.request_step_type_id;
    //             it.fields = it.form.map(f => ({disabled: true, ...f}));
    //             it.authorizers = it.authorizers.map(it => {
    //                 const campus = ousSelected.find(o => o.id === it.parent).campus.map(cs => ({label: cs.id, ...cs}));
    //                 it.scope_ids = it.scope_type_id === SCOPE_OU ? it.scope_ids : campus.filter(c => it.scope_ids.some(s => s === c.id));
    //                 return it;
    //             })
                
    //             return it;
    //         });

    //         props.setRequest({
    //             workflow:request.steps, 
    //             form:{
    //                 createdAt:new Date(),
    //                 title: '',
    //                 ou: request.companies,
    //                 responsable: '',
    //                 type: '',
    //                 duration: ''
    //             },
    //         })

    //         const form = {
    //             createdAt: request.created_at,
    //             title: request.title,
    //             ou: request.name,
    //             personResponsible: request.full_name,
    //             steps: request.steps,
    //             type: request.request_type
    //         }

    //         setState(prevstate => {
    //             const items = [...prevstate.breadcrumbItems];
    //             items[1].title = request.title;

    //             return {
    //                 breadcrumbItems: items,
    //                 loading: false, 
    //                 form, 
    //             }
    //         });
    //     } catch (e) {
    //         log('error', e)
    //     }
    // }

    const handleGeneratePath = (id) => {
        const path = generatePath(routelinks.GESTION_SERVICIO_SHOW_STEP, {id});
        return path
    }

    return (
        // <div>

        // </div>
        <BodyRequest 
            {...state}
            //path={`/gestion-de-servicios/servicio/${props.match.params.id}/step`}
            path={handleGeneratePath(props.match.params.id)}
            readOnly={true}
            //onClickActionStep={this.handleClickActionStep}
            //onChange={this.handleChange}
        />
        // <div>
        //     <Breadcrumb items={state.breadcrumbItems} />
        //     <Grid container spacing={3}>
        //         <Grid container item xs={12}>
        //             <LoaderContent loading={state.loading}>
        //                 {/* <Box width="100%"> */}
        //                     <Card>
        //                         <CardContent>
        //                             <Typography variant="subtitle2" className="mb-4">Trámite</Typography>
        //                             <Grid container spacing={3}>
        //                                 <Grid container item xs={12} spacing={2}>
        //                                     <Grid item xs={12} sm={12} md={6}>
        //                                         <Picker
        //                                             margin="dense" 
        //                                             name="published_date"
        //                                             value={state.form.createdAt}
        //                                             label="Fecha de Creación"
        //                                             readOnly
        //                                         />
        //                                     </Grid>
        //                                     <Grid item xs={12} sm={12} md={6}>
        //                                         <TextField
        //                                             required
        //                                             margin="dense"
        //                                             variant="outlined"
        //                                             label="Titulo del Servicio"
        //                                             fullWidth
        //                                             name="title"
        //                                             readOnly
        //                                             value={state.form.title}
        //                                         />
        //                                     </Grid>
        //                                     <Grid item xs={12} sm={12} md={6}>

        //                                         <TextField
        //                                             margin="dense"
        //                                             variant="outlined"
        //                                             label="Responsable"
        //                                             fullWidth
        //                                             name="title"
        //                                             readOnly
        //                                             value={state.form.personResponsible}
        //                                         />
        //                                     </Grid>
        //                                     <Grid item xs={12} sm={12} md={6}>
        //                                         <TextField
        //                                             margin="dense"
        //                                             variant="outlined"
        //                                             label="Tipo de Trámite"
        //                                             fullWidth
        //                                             name="type"
        //                                             readOnly
        //                                             value={state.form.type}
        //                                         />

        //                                         {/* <Select
        //                                             readOnly
        //                                             label="Unidad Operativa"
        //                                             name="ou"
        //                                             value={state.form.ou || ''}
        //                                         /> */}
        //                                     </Grid>
        //                                 </Grid>
        //                                 <Grid container item xs={12}>
        //                                     <Grid item xs={12}>
        //                                         <WorkFlow 
        //                                             showStepPath={`/requests-managment/request/${props.match.params.id}/step`}
        //                                             steps={state.form.steps} 
        //                                             readOnly 
        //                                         />          
        //                                     </Grid>
        //                                 </Grid>
        //                             </Grid>
        //                         </CardContent>
        //                     </Card> 
        //                 {/* </Box> */}
        //             </LoaderContent>
        //         </Grid>
        //     </Grid>
        // </div>
    );
};

const mapStateToProps = ({ requestWorkflowReducer }) => ({newRequest: requestWorkflowReducer});

export default connect(mapStateToProps, {...requestWorkflowActions})(ShowRequest);