import palette from '../palette';

export default {
  root:{
    padding: 13,
  },
  colorPrimary:{
    color: palette.colors.white,
    background: palette.colors.electricBlue[300],
    borderRadius: 8,
    padding: 8,
    '& .MuiIcon-root':{
      fontSize: '16px !important'
    },
    '&:hover':{
      backgroundColor: palette.colors.violet[400],
    },
  },
  colorSecondary:{
    background: palette.colors.fillStroke[500],
    border: `0.5px solid ${palette.colors.violet[300]}`,
    borderRadius: 40,
    color: palette.colors.white,
    '&.border-button':{
      borderRadius: 8
    },
    '&:hover':{
      backgroundColor: palette.colors.violet[300],
    },
  },
};
