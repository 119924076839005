import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import { v4 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import DsIcon from '../DsIcon/DsIcon';
import capitalize from '../../utils/capitalize'

const useStyles = makeStyles((theme) => ({
    root:{
        padding: '22px 32px',
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-between',
        boxShadow: '0px 0px 40px 0px rgba(0, 0, 0, 0.60);'
    },
    avatar:{
        background: theme.palette.colors.fillStroke[300],
        padding: 8,
        color: theme.palette.colors.gray[300],
        '& .MuiIcon-root, .MuiSvgIcon-root':{
            fontSize: 24
        }
    }
}));

const ContentStrip = ({items, id}) => {
    const contentList = {
        modality: 'ri-slideshow-3-line',
        title: 't-mis-cursos-line',
        certificate: 't-award-line',
        subjects: 'ri-book-2-line',
        duration: 'ri-time-line',
        lang: 'ri-translate-2',
        level: items.level === "Básico" ? 't-basico' : items.level === "Intermedio" ? 't-intermedio' : 't-avanzado'
    }
    const classes = useStyles();

    const cleanList = Object.keys(items).filter(key => items[key]);
    const contentKeys = cleanList;
    const halfLength = Math.ceil(contentKeys.length / 2);

    return (
        <>
            <Hidden smDown>
                <Box id="content-strip-root" aria-label={`${id}`}>
                    <Card className={classes.root}>
                        {
                            cleanList.map(key =>                    
                                <Box display='flex' alignItems='center' gridGap={8} key={uuid()} maxWidth={120}>
                                    <Box>
                                        <Avatar className={classes.avatar}>
                                            {
                                                contentList[key].includes('t-')
                                                ? <DsIcon icon={contentList[key]}/>
                                                : <Icon className={contentList[key]}/>
                                            }
                                        </Avatar>
                                    </Box>
                                    <Typography variant='caption' className='text-gray-400'>{capitalize(items[key], true)}</Typography>
                                </Box>
                            )
                        }
                    </Card>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box display='flex' id="xs-content-strip-root" aria-label={`xs-${id}`}>
                    {[0, 1].map((columnIndex) => (
                        <Box flex={1} key={columnIndex}>
                        {contentKeys.slice(columnIndex * halfLength, (columnIndex + 1) * halfLength).map((key) => (
                            <Box display='flex' alignItems='center' gridGap={8} key={uuid()}>
                            <Box>
                                {
                                    contentList[key].includes('t-')
                                    ? <DsIcon icon={contentList[key]} className='text-gray-400'/>
                                    : <Icon className={clsx(contentList[key], 'text-gray-400')} />
                                }
                            </Box>
                            <Typography variant='body2' className='text-gray-400'>{items[key]}</Typography>
                            </Box>
                        ))}
                        </Box>
                    ))}
                </Box>
            </Hidden>
        </>
    );
};

ContentStrip.defaultProps = {
  items: null  
};

ContentStrip.propTypes = {
    items: PropTypes.shape({
        modality: PropTypes.any,
        title: PropTypes.any,
        certificate: PropTypes.any,
        subjects: PropTypes.any,
        duration: PropTypes.any,
        lang: PropTypes.any,
        level: PropTypes.any,
    }),
};

export default ContentStrip;