import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootFilterChips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chips: {
        marginTop: '17px',
        marginRight: '18px',
        backgroundColor: theme.palette.colors.fillStroke[300],
    },
    labelChip: {
        paddingRight: "8px",
    }
}));