import { makeStyles } from "@material-ui/core";

export const modalUpsellingStyles = makeStyles((theme) => ({
    modalInside: {
        backgroundColor: `${theme.palette.colors.fillStroke[400]}`,
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    arrowIcon: {
        fontSize: 16
    },
    modalFooter: {
        color: `${theme.palette.colors.darkBlack[200]}`
    },
    cardContentHeight: {
        maxHeight: '283px !important'
    },
}));
