import React, { useEffect, useState } from 'react';

//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// style
import useStyles from '../../../assets/styles/jss/pages/consultancies/applicant/ModifyConsultanciesStyle';
import { TextField, IconButton, Icon } from '@material-ui/core';
// icons
import palette from '../../../design-system/theme/palette';
//SDK
import { ConsultanciesService,AdminNotifService } from '@sdk-point/talisis';

import ProfilePicUsers from '../../../shared/components/ProfilePicUsers/ProfilePicUsers';
import { getLogoCard } from '../../../constants/logosImages';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { useSelector } from 'react-redux';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';


const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const AdminNotifServiceSDK = new AdminNotifService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const ManageConsultancy = (props) => {
    const { user } = useSelector((state) => state.userReducer);

    const classes = useStyles();
    const [Description, setDescription] = useState('');
    const [CancelarAsesoria, setCancelarAsesoria] = useState(false);
    const [RequestAdviser, setRequestAdviser] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => { 
    }, []);


    const handleClickSalir = async () => {
        props.setModifyConsultancy(false);
    };

    const handleClickCancelar = async () => {
        setLoading(true)
        let body = {
            id_asesoria : props.idConsultancy,
            motivo: 0
        }

        const body2 = {
            isCritical: 1,
            title: 'Asesoría cancelada',
            message: 'Ha sido cancelada la asesoría por el asesor: ' + user.userName + ' con el correo: ' + props.emailAsesor,
            notifType: 'Procedures',
            companies: null,
            campus: null,
            groups: null,
            persons: [user.person_id, props.personIdSolicitante],
            actionRequired: 1,
            url: '/asesorias',
        };
        await ConsultanciesServiceSDK.postModifyConsultancy(body)
        eventTracker('rechazar_asesoria', eventData, ['mixpanel'])
        props.findConsultancies()
        setLoading(false)
        setCancelarAsesoria(true);
        AdminNotifServiceSDK.createNotification(body2);
    };

    const handleFinalizarSolicitud = () => {
        props.setModifyConsultancy(false)
        props.findConsultancies()
    }
    
    function convertirHora(cadenaHora) {
        // Separar las horas y los minutos
        const partesHora = cadenaHora.split(':');
        let horas = parseInt(partesHora[0]);
        let minutos = partesHora[1];
      
        // Verificar si las horas tienen un solo dígito
        if (horas < 10) {
          horas = '0' + horas;
        }
      
        // Verificar si los minutos tienen un solo dígito
        if (minutos.length < 2) {
          minutos = minutos.padStart(2, '0');
        }
      
        // Unir las horas y los minutos en el formato deseado
        return horas + ':' + minutos;
      }
      
      const convertTime12to24 = time12h => {
        const [time, modifier] = time12h.split(" ");
       
        let [hours, minutes] = time.split(":");
        
        if (modifier == "pm" && parseInt(hours) != 12) {
          hours = parseInt(hours, 10) + 12;
        }
       
        return `${hours}:${minutes}`;
    };
    const handleClickAceptar = async () => {
        setLoading(true)
        let [hoursA, hoursB] = props.scheduleLocal.split("-");
        hoursA = convertTime12to24(convertirHora(hoursA.trim())) 
        hoursB = convertTime12to24(convertirHora(hoursB.trim()))
        let time_start = (new Date((props.dateLocal).slice(0,10) + "T" + hoursA + ':00')).toISOString();
        let time_end = (new Date((props.dateLocal).slice(0,10)+ "T" + hoursB+ ':00')).toISOString();
        
        let body = {
            id_asesoria: props.idConsultancy,
            date_start: time_start,
            date_end: time_end,
            email_asesor: props.emailAsesor,
            email_solicitante: props.emailSolicitante
        }

        await ConsultanciesServiceSDK.postCreateMeetTeams(body)
        const body2 = {
            isCritical: 1,
            title: 'Asesoría aprobada',
            message: 'Ha sido aprobada una asesoría por el asesor: ' + user.userName + ' con el correo: ' + user.email,
            notifType: 'Procedures',
            companies: null,
            campus: null,
            groups: null,
            persons: [user.person_id, props.personIdSolicitante],
            actionRequired: 1,
            url: '/asesorias',
        };
        const ok =  AdminNotifServiceSDK.createNotification(body2);
        props.findConsultancies()
        setLoading(false)
        setRequestAdviser(true);
        eventTracker('aceptar_asesoria', {}, ['mixpanel']);
    }
    return (
        <Box>
            {
                !CancelarAsesoria && !RequestAdviser ?
                <LoaderContent loading={loading}>
                <Box className={`${classes.boxRoot}`}>
                        <Box className={classes.boxTittle}>
                            <Box className={classes.closeWindow}>                    
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() => {handleClickSalir()}}
                                >
                                    <Icon className="ri-close-fill" style={{color: palette.colors.fillStroke[100]}}/>
                                </IconButton>
                            </Box>
                            <ProfilePicUsers email={props.emailSolicitante} className={classes.profilePic}/>
                            <Typography variant='body1' className='semi-bold'>{props.nombreAsesor}</Typography>
                        </Box>

                        <Box className={classes.boxDataTimes}>
                            <Box className={classes.boxDataTimesSecond}>
                                <Icon className={"ri-calendar-line font-size-16 text-electric-blue-200"} />
                                <Typography variant='body1' className='semi-bold'>{(props?.dateConvert)}</Typography>
                            </Box>
                            <Box className={classes.boxDataTimesSecond}>
                                <Icon className={"ri-time-line font-size-16 text-electric-blue-200"}/>
                                <Typography variant='body1' className='semi-bold'>{(props?.scheduleLocal)}</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.boxUbicacion}>
                            <img alt="logo" style={{padding: 0, margin: 0, width: '84px'}} src={ getLogoCard(7) }/>
                            <Icon className={"ri-map-pin-2-fill font-size-16 text-fill-stroke-100"} />
                            <Typography variant='caption'>{props.modalidad}</Typography>
                            <Icon className={"ri-book-2-fill font-size-16 text-fill-stroke-100"} />
                            <Typography variant='caption'>{props.area_conocimiento}</Typography>
                        </Box>
                        
                        <Box className={classes.boxDataDescription}>
                            <Typography style={{marginBottom: '8px'}} variant='body2'>Descripción de dudas a resolver</Typography>
                            <TextField
                            name='name'
                            value={Description != '' ? Description : props.descripcion}
                            multiline
                            fullWidth
                            minRows={10}
                            maxRows={10}
                            placeholder='No hay descripción sobre la asesoria para mostrar.'
                            InputProps={{ classes: {input: classes['input']} ,
                            disableUnderline: true,
                            readOnly: true,
                            className: classes.textDescription, 
                            }} 
                            />
                        </Box>
                        
                        {
                            props.idTeamsAsesoria.length === 0 ?
                            <Box className={classes.boxSalir}>
                                <Button 
                                variant='contained'
                                label="Rechazar"
                                className={classes.buttonSig}
                                onClick={() => {handleClickCancelar()}}
                                >
                                    <Typography variant='body2' className='semi-bold'>Rechazar</Typography>
                                </Button>

                                <Button 
                                variant='contained'
                                color="primary"
                                label="Aceptar"
                                className={classes.buttonSig}
                                onClick={() => {handleClickAceptar()}}
                                >
                                    <Typography variant='body2' className='semi-bold'>Aceptar</Typography>
                                </Button>
                            </Box>
                            :
                            <Box className={classes.boxSalir}>
                                <Button 
                                variant='contained'
                                color="primary"
                                label="Aceptada"
                                disabled
                                className={classes.buttonSig}
                                onClick={() => {}}
                                >
                                    <Typography variant='body2' className='semi-bold'>Aceptada</Typography>
                                </Button>
                            </Box>
                        }
                    </Box>
                    </LoaderContent>
                : RequestAdviser && !CancelarAsesoria ?
                <Box className={classes.boxFinalizar}>
                    <Box className={classes.boxControlFinalizar}>
                        <Box className={classes.boxTextFinalizar}>
                            <Typography style={{marginBottom: '8px'}} variant='h3' className="subtitle">Solicitud de asesoría aceptada</Typography>
                            <Typography variant='caption'>Gracias por aceptar la asesoría, espera la hora en la cual se impartira e ingresa desde la plataforma.</Typography>
                        </Box>
                        <Box className={classes.boxButton}>
                            <Button 
                            variant='contained'
                            color="primary"
                            label="Primario"
                            className={classes.buttonSig}
                            onClick={() => {handleFinalizarSolicitud()}}
                            >
                                <Typography variant='body2' className='semi-bold'>Finalizar</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
                : 
                <Box className={classes.boxFinalizar}>
                <Box className={classes.boxControlFinalizar}>
                    <Box className={classes.boxTextFinalizar}>
                        <Typography style={{marginBottom: '8px'}} variant='h3'>Asesoría rechazada</Typography>
                        <Typography variant='caption'>Recuerda que solo tienes hasta 3 cancelaciones por mes, si cancelas mas, no avisas con tiempo o no asistes, se te puede dar de baja del programa por 1 meses.</Typography>
                    </Box>
                    <Box className={classes.boxButton}>
                        <Button 
                        variant='contained'
                        color="primary"
                        label="Primario"
                        className={classes.buttonFinalizar}
                        onClick={() => {handleFinalizarSolicitud()}}
                        >
                            <Typography variant='body2' className='semi-bold'>Finalizar</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            }
        </Box>
    );
};

export default ManageConsultancy;