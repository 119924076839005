import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Grid, Hidden, useMediaQuery } from '@material-ui/core';

// routes
import * as paths from '../../routes/paths'

//SDK
import { EventService } from '@sdk-point/talisis'; 

// Shared components
import LoaderContent from '../../shared/components/Loader/LoaderContent';
import {userIsLogged} from '../../shared/utils/Sessions';
import { log } from '../../shared/utils/console.js'

// Components
import AllEvents from './EventList/pages/AllEvents/AllEvents';
import CardInformationEvent from './components/CardInformationEvent';

// Styles
import useStyles from '../../assets/styles/jss/pages/events/eventsStyle';
import { eventData, eventTracker } from '../../shared/components/EventTracker/EventTracker';

const EventServiceSDK = new EventService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const TYPE_EVENT_PUBLIC = 1;

const Events = () => {
    let history = useHistory();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles();

    const isLogged = userIsLogged();
    const [loading, setLoading] = useState(true);
    const [eventList, setEventList] = useState([]);

    useEffect(() => {
        getAllEvents();
        eventTracker('s_eventos', {}, ['mixpanel']);
    }, []);

    const getAllEvents = async () => { 

        try {
            const response = await EventServiceSDK.getAllEvents({ type: TYPE_EVENT_PUBLIC });
            const data = response.success? response.data : [];
            setLoading(false);
            setEventList(data);
        } catch (error) {
            log('error', error);
        }
    };

    const handleClick = (path) => {
        eventData['source'] = 'landing_eventos';
        eventTracker('cta_registro', eventData, ['mixpanel','hubspot','google']);
        history.push(path);
    };

    return (
        <Box mt={isResponsive ? "16px" : "0"} mb={'128px'}>
            <Typography variant={isResponsive ? 'h2' : 'h3'}>{isResponsive ? 'Entérate de nuestros próximos eventos' : 'Eventos populares'}</Typography>
            <Grid container direction={!isResponsive ? 'column' : 'column-reverse'}>
                <Grid item className={classes.containerInfoEvent}>
                    <CardInformationEvent isLogged={isLogged} onClickRegister={handleClick} isResponsive={isResponsive}/>
                </Grid>
                <Grid item className={classes.containerEvents}>
                    <LoaderContent loading={loading}>
                        <AllEvents eventList={eventList} displayTitle={false} showButtonInterest={false} isResponsive={isResponsive}/>
                    </LoaderContent>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Events;