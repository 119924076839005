import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    allEventRoot: {
        marginTop: props => props.isResponsive ? '16px' : '0',
    },
    title: {
        marginBottom: "16px",
    }
}));
