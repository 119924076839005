export const DEFAULT_DATE_FORMAT = 'DD MMMM, YYYY'; // 13 mayo, 2021
export const DEFAULT_DATE_TIME_FORMAT = 'DD MMMM, YYYY HH:mm'; // 13 mayo, 2021 18:00
export const ISO_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm"; // 2021-05-13 18:00:34
export const COMPLETE_DATE_FORMAT = "DD [de] MMMM [del] YYYY"; // 13 de mayo del 2021
export const DATE_FORMAT_LOCAL = "DD/MM/YYYY"; // 2021-05-13
export const DATE_WITH_DAY_NAME = 'dddd DD, MMMM YYYY'; // lunes 13, mayo 2021
export const USER_DATE_FORMAT = "DD [de] MMMM [del] YYYY"; // 13 de mayo del 2021
export const COMPLETE_DATE_FORMAT_SHORT = "DD [de] MMMM YYYY"; // 13 de mayo 2021
export const SIMPLE_DATE_FORMAT = "DD MMMM YYYY"; // 13 Mayo 2021
export const DATE_TIME = "dddd, DD [de] MMMM [del] YYYY [a las] HH:mm"; // miércoles, 16 de noviembre del 2022 a las 18:00
export const DATE_TIME2 = "dddd, DD [de] MMM [del] YYYY [a las] HH:mm"; // miércoles, 16 de noviembre del 2022 a las 18:00
export const DAY_DATE = "dddd, DD [de] MMMM [del] YYYY"; // miércoles, 16 de noviembre
export const DD_MMMM_YYYY_FORMAT = "DD / MMMM / YYYY"; // miércoles, 16 de noviembre
export const ISO_DATE_FORMAT = "YYYY-MM-DD"; // 2021-05-13
export const DD_MMM_YYYY_FORMAT = "DD-MMM-YYYY"; // 04-ago-2023
export const DATE_FORMAT_SHORT = "DD/MM/YYYY"; // 04/25/2024


