import { makeStyles } from "@material-ui/core";

export const interestGroupStyles = makeStyles((theme) => ({
    chipWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
          margin: theme.spacing(0.5),
        },
    },
    chip: {
        // backgroundColor: "#111111",
        border: "1px solid #111111",
        backgroundColor: "#404150",
        '&.active':{
          background: theme.palette.colors.violet[400] + ' !important',
          border: `0.5px solid ${theme.palette.colors.electricBlue[500]} !important`,
        },
        [theme.breakpoints.down('xs')]:{
            width: "280px", 
        }
      },
    reactChip: {
      // backgroundColor: "#2C3236",
        border: "1px solid #111111",
        backgroundColor: "#1D1E23",
        [theme.breakpoints.down('xs')]:{
            width: "280px", 
        }
    },
    chipOn: {
      color: theme.palette.colors.white
    },
    chipOff: {
      color: "#A0A9BA"
    }
}));
