import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//components 
import objectIsEmpty from '../../../../shared/utils/objectIsEmpty';
import clsx from 'clsx';


const SelectTopic = (props) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    topicList: {
      padding: '8px 16px',
      maxHeight: '300px',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    topicListItem: {
      backgroundColor: theme.palette.colors.fillStroke[400],
      borderRadius: '4px',
      padding: '8px 16px',
      [theme.breakpoints.down('xs')]:{
        padding: '8px 12px',
      },
      '& .MuiListItemIcon-root':{
        minWidth: 28
      }
    },
    topicListItemSelected: {
      backgroundColor: theme.palette.colors.electricBlue[300]
    },
    listItemText:{
      '& span':{
        ...theme.typography.bodyTextLargeRegular,
        [theme.breakpoints.down('xs')]:{
          ...theme.typography.bodyTextSmallRegular,
        }
      }
    },
    checkSize:{

      width: 26,
      height: 26,
      marginRight: 8
    }
  });

  const { categories, selectedCategory, selectedTopic, setSelectedTopic, setDisableSend, setSelectedCategoryLastChange, setReqClass} = props;

  const [catTopics, setCatTopics] = useState(categories.filter(cat => cat.id === selectedCategory)[0])
  // const [selectedTopic, setSelectedTopic] = useState(null)

  useEffect(() => {
    setDisableSend(true)
  }, [])

  // useEffect(() => {
  //   if(!objectIsEmpty(selectedTopic)) setDisableSend(false)
  // }, [selectedTopic])
  

  const handleSelect = async(key) => {
    if(key.category){
      setSelectedCategoryLastChange(key.category)
      setReqClass(key.clase)
    }
    setSelectedTopic(key)
    await props.getArticleInfo(key);
  }

  const classes = useStyles();
  return (
    <List className={classes.topicList}>
      {
        catTopics.custom_field_options.map(topic => {
          const isSelected = selectedTopic.id === topic.id;
          return (
            <ListItem key={topic.id} role={undefined} dense button 
              className={clsx(classes.topicListItem, {'bg-electric-blue-300': isSelected})} 
              onClick={() => handleSelect(topic)}
            >
              <ListItemIcon>
                <Checkbox
                  className={clsx('p-0',classes.checkSize)}
                  checked={isSelected}
                  checkedIcon={<i style={{ color: 'white' }} className={'ri-checkbox-circle-fill'} />}
                  icon={<i className={'ri-checkbox-blank-circle-line'} />}
                />
              </ListItemIcon>
              <ListItemText id={topic.id} primary={topic.name} className={classes.listItemText}/>
            </ListItem>
          )
        })
      }
    </List>
  )
};

SelectTopic.defaultProps = {
  isHome: false,
  isCheckout: false,
};

SelectTopic.propTypes = {
  isHome: PropTypes.bool,
  isLogged: PropTypes.bool,
  isCheckout: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
};


export default SelectTopic;
