import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import clsx from 'clsx';

import { Box, makeStyles } from '@material-ui/core';

import {SOURCES} from '../../../constants/sources';
import LogoSource from '../../LogoSource/LogoSource';


const useStyles = makeStyles((theme) => ({
    root:{
        width:393,
        [theme.breakpoints.down('xs')]:{
            width:265,
        }
    },
    cardMedia:{
        height: 82,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]:{
            height:71,
        }
    },
    cardContent:{
        height: 48,
        padding: '15px 16px !important',
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]:{
            height:68,
            padding: '8px 16px 11px!important',
            alignItems: 'flex-start',
            flexDirection: 'column'
        }
    },
    sourceInfo:{
        height: 48,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between',
        [theme.breakpoints.down('xs')]:{
            height:44
        }
    },
    [SOURCES.COURSERA]:{
        background: '#0056D2',
        '& .source-image':{
            width: 154,
            height: 22,
            [theme.breakpoints.down('xs')]:{
                height:19,
                width: 132
            }
        }
    },
    [SOURCES.LINKEDIN_LEARNING]:{
        background: '#0A66C1',
        '& .source-image':{
            width: 242,
            height: 32,
            [theme.breakpoints.down('xs')]:{
                height:28,
                width: 208
            }
        }
    },
    [SOURCES.GOOGLE]:{
        background: `${theme.palette.white}`,
        '& .source-image':{
            width: 230,
            height: 36,
            [theme.breakpoints.down('xs')]:{
                height:31,
                width: 198
            }
        }
    },
    [SOURCES.CREHANA]:{
        background: '#4B22F4',
        '& .source-image':{
            width: 185,
            height: 27,
            [theme.breakpoints.down('xs')]:{
                height:23,
                width: 159
            }
        }
    },
    [SOURCES.HUBSPOT]:{
        background: '#445B75',
        '& .source-image':{
            width: 246,
            height: 34,
            [theme.breakpoints.down('xs')]:{
                height:29,
                width: 212
            }
        }
    },
    [SOURCES.IE_UNIVERSITY]:{
        background: '#000066',
        '& .source-image':{
            width: 127,
            height: 50,
            [theme.breakpoints.down('xs')]:{
                height:43,
                width: 109
            }
        }
    },
    [SOURCES.AWS_SKILL_BUILDER]:{
        background: '#252F3E',
        '& .source-image':{
            width: 200,
            height: 50,
            [theme.breakpoints.down('xs')]:{
                height:41,
                width: 163
            }
        }
    },
    [SOURCES.UNID]:{
        background: '#1E1A34',
        '& .source-image':{
            width: 97,
            height: 30,
            [theme.breakpoints.down('xs')]:{
                height:24,
            }
        }
    },
    [SOURCES.UERRE]:{
        background: '#000000',
        '& .source-image':{
            width: 126,
            height: 24,
            [theme.breakpoints.down('xs')]:{
                height:19,
                width: 132
            }
        }
    },
}));

const sourceLogoColors = {
    [SOURCES.COURSERA]: 'white',
    [SOURCES.LINKEDIN_LEARNING]:'white',
    [SOURCES.GOOGLE]:'default',
    [SOURCES.CREHANA]:'white',
    [SOURCES.HUBSPOT]:'whiteColor',
    [SOURCES.IE_UNIVERSITY]:'white',
    [SOURCES.AWS_SKILL_BUILDER]:'whiteColor',
    [SOURCES.UERRE]:'white',
    [SOURCES.UNID]:'white',
}

const AllyCourseCard = ({sourceId, sourceName, courses = 0, onClick}) => {
    const classes = useStyles();

    if(!sourceLogoColors[sourceId]){
        return null;
    }

    return (
        <Card variant='outlined' className={classes.root} onClick={onClick}>
            <CardActionArea>
                <CardContent className='p-0'>
                    <CardMedia
                        className={clsx(classes[sourceId], classes.cardMedia)}
                        title="Contemplative Reptile"
                    >
                        <LogoSource sourceId={sourceId} color={sourceLogoColors[sourceId]} disabledAutoRes/>
                    </CardMedia>
                    <CardContent className={classes.cardContent}>
                        <Box className={classes.sourceInfo}>
                            <Typography variant='caption' className='text-dark-black-100'>{sourceName}</Typography>
                            <Typography variant='body1' className='text-blue-300 bold'>+{courses} Cursos</Typography>
                        </Box>
                        <Box sx={{ py: { xs:0, sm:2 }, px: { xs:0, sm:1 }}}>
                            <Typography variant='body2' className='semi-bold text-orange-300'>Ver cursos</Typography>
                        </Box>
                    </CardContent>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default AllyCourseCard;