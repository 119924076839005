import Cookies from 'js-cookie';
import { Mixpanel } from '../shared/components/Mixpanel/mixpanel';

//SDK
import { UsersService } from '@sdk-point/talisis'; 
import { MIXPANEL_EVENT } from '@sdk-point/talisis';

const UsersServiceSDK = new UsersService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const getSessionCookie = () => {
  const sessionCookie = Cookies.get("session");
  return sessionCookie ? JSON.parse(sessionCookie) : {};
};

export const logOut = (e) => {
    // e.preventDefault();
    const session = getSessionCookie();
    const role = JSON.parse(localStorage.getItem('permissions'));

    if ((window.location.host).includes(".talisis.com")) {
      Cookies.remove("session", { domain: ".talisis.com"} );
      Cookies.remove("LOGIN_PLATFORM_TALISIS", { domain: ".talisis.com" });
      Cookies.remove("LOGIN_ADMIN_TALISIS", { domain: ".talisis.com" });
    }
    else {
      Cookies.remove("session");
      Cookies.remove("LOGIN_PLATFORM_TALISIS");
      Cookies.remove("LOGIN_ADMIN_TALISIS");
    }
    
    Cookies.remove("checkout");
    Cookies.remove("cart");
    Cookies.remove("checkout_sku");
    
    localStorage.removeItem('profilePic');
    localStorage.removeItem('permissions');
    sessionStorage.removeItem('menuId');
    localStorage.clear();
    sessionStorage.clear();

    const deleteDeviceToken = async () => {
        const FCMToken = localStorage.getItem('fcm_token');
        if (FCMToken) {
        localStorage.removeItem('fcm_token');
        await UsersServiceSDK.deleteToken({ token: FCMToken });
        }
    };
    deleteDeviceToken();

    window.location.reload(false)
};
