import React from 'react';

// @shared/components
import LoaderContent from '../../../../../shared/components/Loader/LoaderContent';

import Cards from '../MyPaymentMethods/Cards/Cards';
import Debits from '../MyPaymentMethods/Debits/Debits';

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.colors.grey[500],
        border: `0.5px solid ${theme.palette.colors.grey[400]}`,
        borderRadius: '8px',
        padding: '28.33px 34px 28.33px 34px',
        '& .bottom': {
            verticalAlign: 'bottom',
        }
    },
    title: {
        color: theme.palette.colors.violet[300],
    },
    line: {
        border: 0,
        borderBottom: `1px solid ${theme.palette.colors.grey[50]}`,
        margin: 0,
    },
}));

const PaymentMethodList = (props) => {
    const classes = useStyles();
    return (
        <LoaderContent loading={props.loading}>
            <Box className="mb-5">
                <Typography variant='h4' className={classes.title}>Mis métodos de pagos</Typography>
                <Typography variant='caption' className="font-weight-500">Agrega, edita o elimina tus tarjetas de crédito y débito.</Typography>
                <Box className={`${classes.root} mt-2`}>
                    <Typography component="div" variant="body1"><i className="ri-bank-card-line mr-2 bottom"></i>Agregar una tarjeta de crédito o débito</Typography>
                    <hr className={classes.line} />
                    <Cards {...props} />
                </Box>
            </Box>
            {
                !props.isCheckout &&
                <Box className="mt-5">
                    <Typography variant='h4' className={classes.title}>Mis pagos domiciliados</Typography>
                    <Typography variant='caption' className="font-weight-500">Puedes ver cualquier pago que hayas domiciliado correspondiente a tu cuenta.</Typography>
                    <Box className="mt-2">
                        <Debits {...props} />
                    </Box>
                </Box>
            }
        </LoaderContent>
    )
};

export default PaymentMethodList;
