import React, { useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";

// Components
import Routes from './routes/Routes';

// Styles
import Theme from './design-system/theme'
import './App.css';
import './hubspot.scss';
import { StylesProvider } from "@material-ui/core/styles";

import { SessionContext, getSessionCookie, setSessionCookie } from "./shared/utils/Sessions";
import { createBrowserHistory } from "history";
import * as cookie from "js-cookie";

// shared
import objectIsEmpty from "./shared/utils/objectIsEmpty";

// redux
import { connect, useSelector } from 'react-redux';
import * as userActions from './redux/actions/userActions';
import * as profilePicActions from './redux/actions/profilePicActions';
import * as menuActions from './redux/actions/menuActions';
import * as membershipActions from './redux/actions/membershipActions';
import * as permissionActions from './redux/actions/permissionActions';

//Google Analitics
import ReactGA from 'react-ga4'; //react-ga';
import RouteChangeTrackerService from './services/RouteChangeTrackerService';
//Google Tag Manager
import TagManager from 'react-gtm-module';

//Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import packageJSON from "../package.json"

// import ZendDesk from './shared/components/ZendDesk/ZendDesk'
import Help from './layout/components/Help/Help';

//SDK
import { SimulationService, RestrictionsService } from '@sdk-point/talisis';
import { logOut } from './services/LogoutService'
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { conektaWSUpgradeMembership } from './shared/utils/conektaWSUpgradeMembership';
import { log } from './shared/utils/console';
import { fillUserData } from './shared/components/EventTracker/EventTracker';

const SimulationServiceSDK = new SimulationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RestrictionsServiceSDK = new RestrictionsService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const history = createBrowserHistory();

// Google analitics
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALITICS_TRACKING_ID
    /*
    , {
        gaOptions: {
        debug_mode: true,
        },
        gtagOptions: {
        debug_mode: true,
        },
    }
    */
);
//tag mannager
try {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
    }
    TagManager.initialize(tagManagerArgs);
    TagManager.dataLayer({
        dataLayer: {
            event: "page_view"
        }
    });
}
catch (error) {
    console.log({ error });
}

Sentry.init({
    environment: process.env.REACT_APP_ENV,
    release: packageJSON.version,
    dsn: "https://6f6fcd3675bb46deb9810fdec279e01d@sentry.talisis.com/35",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            unmask: ['.sentry-unmask, [data-sentry-unmask]'],
            blockAllMedia: false,
            networkDetailAllowUrls: [
                window.location.origin,
                "kmnk2k677k.execute-api.us-west-2.amazonaws.com",
                /^https:\/\/kmnk2k677k\.execute-api\.us-west-2\.amazonaws\.com/,
            ],
            networkRequestHeaders: ["Cache-Control"],
            networkResponseHeaders: ["Referrer-Policy"],
            mutationBreadcrumbLimit: 1000,
            mutationLimit: 1500,
        }),
        new BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({console: false})
    ],
});

function App(props) {
    const { instance } = useMsal();
    let session = getSessionCookie();
    let isLogged = !objectIsEmpty(session);

    const loginAdminTalisisCookie = cookie.get("LOGIN_ADMIN_TALISIS");
    const sessionCookie = cookie.get("session");
    let isAuthenticated = useIsAuthenticated();

    if (loginAdminTalisisCookie && sessionCookie) {
        isLogged = true;
        isAuthenticated = true;
        const sessionCookieJSON = JSON.parse(sessionCookie);
        sessionCookieJSON["ignore_session_cookie"] = true;
        session = sessionCookieJSON;
        setSessionCookie(sessionCookieJSON);
    }

    const singleSignOut = () => {
        log('logginout----')
        // e.preventDefault();
        const instanceAccount = instance.getAllAccounts()[0];
        const logoutRequest = {
            account: instanceAccount,
            postLogoutRedirectUri: `${window.location.origin}/`,
        };
        logOut();
        instance.logoutRedirect(logoutRequest);
    }

    if (isLogged && objectIsEmpty(props.user)) {
        const profilePhoto = {
            src: session.profile_picture_url ?? '/images/avatars/placeholder.png',
            blob: false
        };

        props.applyProfilePic(profilePhoto);
        props.applyUser(session);
        props.getUserMenu(session);
        props.applyMembership(session.membership_id);
        log('session user:', session)
        // props.getUserMembership(session);
        if (!session?.SSO && !session?.is_fake) {
            // const instanceAccount = instance.getAllAccounts()[0];
            // const logoutRequest = {
            //     account: instanceAccount,
            //     postLogoutRedirectUri: `${window.location.origin}/`,
            // };
            logOut();
            history.replace("/")
            // instance.logoutRedirect(logoutRequest);
        }
    }

    if (!isAuthenticated && isLogged) {
        singleSignOut();
        history.replace("/")
    }

    const handleUnload = () => {
        /*
        const $session = getSessionCookie();

        if (!objectIsEmpty($session) && $session.is_fake) {
            try {
                const admin_session = JSON.parse(localStorage.getItem('admin_session'));
                setSessionCookie(admin_session.user);
                localStorage.removeItem('admin_session');

                SimulationServiceSDK.closeSession($session.fake_session);
            } catch (e) { }
        }
        */
    };

    React.useEffect(() => {
        log('log props user', props.user)
        if (isLogged){
            conektaWSUpgradeMembership({
                person_id: session.person_id,
                updateMembership: props.updateMembership,
                email: session.email
            });
        }
    },[props.user])

    React.useEffect(async () => {
        window.addEventListener('unload', handleUnload);
        if (isLogged) {
            //console.log('log islogged');
            // conektaWSUpgradeMembership({
            //     person_id: session.person_id,
            //     updateMembership: props.updateMembership,
            //     email: session.email
            // });
            fillUserData(session);

            async function obtenerYActualizarRestricciones() {
                try {
                    const response = await RestrictionsServiceSDK.getRestrictionsByUser(session.person_id);
                    session.restrictions = response.result;
                    props.applyUser(session);
                }
                catch (error) {
                    console.error('Error al obtener las restricciones:', error);
                }
            }
            await obtenerYActualizarRestricciones()
        }
        return () => {
            window.removeEventListener('unload', handleUnload);
        }
    }, []);

    return (
        <React.Fragment>
            {/* <StylesProvider injectFirst> */}
            <Theme>
                <SessionContext.Provider value={session}>
                    <Router history={history}>
                        <RouteChangeTrackerService />
                        <Routes isLogged={isLogged} />
                        <Help />
                    </Router>
                </SessionContext.Provider>
            </Theme>
            {/* </StylesProvider> */}
        </React.Fragment>
    )
}

const mapStateToProps = ({ userReducer, profilePicReducer, menuReducer, membershipReducer, permissionReducer }) => ({
    ...userReducer,
    ...profilePicReducer.profilePic,
    ...menuReducer,
    ...membershipReducer,
    ...permissionReducer,
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...menuActions, ...membershipActions, ...permissionActions };
export default connect(mapStateToProps, mapDispatchToProps)(App);
