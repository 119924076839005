import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tabs from '../../shared/components/Tabs/Tabs';
import Loader from '../../shared/components/Loader/LoaderContent';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';

import RowContainer from './components/rowContainer';
import { MarketPlaceService } from '@sdk-point/talisis';
import NothingFound from '../../shared/components/NothingFound/NothingFound';
import { log } from '../../shared/utils/console.js'
const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

const TableHeadCustom = withStyles(theme => ({
    root: {
      backgroundColor: theme.palette.primary.main,
    }
  }))(TableHead);

const useStyles = makeStyles((theme) => ({
    'main': {
        zIndex: "1100",
        maxWidth: "1248px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    filters:{
        '&:hover':{
            cursor: "pointer",
        }
    },
    loader:{
        minHeight: "500px",
    }
}));

const OrdersStatus = () => {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('ordenId');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [orders, setOrders] = React.useState([]);
    const [inCheckout, setInCheckout] = React.useState([]);
    const [inProcess, setInProcess] = React.useState([]);
    const [inError, setInError] = React.useState([]);
    const [completed, setCompleted] = React.useState([]);
    const [filteredOrders, setFilteredOrders] = React.useState([]);
    const [charge, setCharge] = React.useState(false);
    const [selectFiler, setSelectFiler] = React.useState(0);
    const [isCanceled, setIsCanceled] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [tabsDataDynamic, setTabsDataDynamic] = React.useState([
        {
            label: 'Todos (0)',
        },
        {
            label: 'En checkout (0)',
        },
        {
            label: 'En proceso (0)',
        },
        {
            label: 'Detenidos (0)',
        },
        {
            label: 'Completados (0)',
        },
    ]);

    const handleChange = async (tab) => {
        log(tab);
        await applyFilter(tab);
        setActiveTab(tab);
    };

    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    //const date = (new Date("Tue Sep 15 2022 10:25:46 GMT-0500"));
    //log(date);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const headCells = [
        { id: 'orderID', label: 'Orden id' },
        { id: 'nombreUsuario', label: 'Comprador' },
        { id: 'concepto', label: 'Concepto' },
        { id: 'status', label: 'Estatus' },
        { id: 'tiempoTranscurrido', label: 'Tiempo total' },
    ];

    const getOrders = async () => {
        let orders = [];
        const response = await marketService.getAdminOrders();
        if(response?.orders){
            orders = JSON.parse(response.orders);
            // log(orders);
        }
        return {"orders": orders};
        //return await dataOrdenes;
    } 

    const applyFilter = async (filterId) => {
        if(filterId == 0){
            setFilteredOrders(orders);
        }else if(filterId == 1){
            //EN CHECKOUT
            setFilteredOrders(inCheckout);
        }else if(filterId == 2){
             //EN PROCESO
             setFilteredOrders(inProcess);
        }else if(filterId == 3){
            //CON ERROR
            setFilteredOrders(inError);
        }else if(filterId == 4){
            //COMPLETADOS
            setFilteredOrders(completed);
        }
        setSelectFiler(filterId);
    } 

    const setOrderFilters = (ordersTemp) => {
        let TempInCheckout = [];
        let TempInProcess = [];
        let TempInError = [];
        let TempCompleted = [];
        
        ordersTemp.map((orderFilter) => {
            let countProcess = 0;
            let haveError = false;
            orderFilter.steps.map((step) => {
                if(step.status == 3 || step.status == 2){
                    if(step.status == 3){haveError = true;}else{countProcess++;}
                }
            });

            if(countProcess <= 8 && !haveError){
                if(countProcess == 1){
                    //AGREGAR A ESTATUS DE EN CHECKOUT
                    TempInCheckout.push(orderFilter);
                }else if(countProcess == 8){
                    //AGREGAR A ESTATUS DE EN COMPLETADO
                    TempCompleted.push(orderFilter);
                }else{
                    //AGREGAR A ESTATUS EN PROCESO
                    TempInProcess.push(orderFilter);
                }
            }else{
                //AGREGAR A ESTATUS CON ERROR
                TempInError.push(orderFilter);
            }
        });
        //log(TempInCheckout);
        //log(TempInProcess);
        //log(TempInError);
        setInCheckout(TempInCheckout.length > 0 ? TempInCheckout : []);
        setInProcess(TempInProcess.length > 0 ? TempInProcess : []);
        setInError(TempInError.length > 0 ? TempInError : []);
        setCompleted(TempCompleted.length > 0 ? TempCompleted : []);

        setTabsDataDynamic([
            {
                label: `Todos (${ordersTemp.length})`,
            },
            {
                label: `En checkout (${TempInCheckout.length})`,
            },
            {
                label: `En proceso (${TempInProcess.length})`, 
            },
            {
                label: `Detenidos (${TempInError.length})`,
            },
            {
                label: `Completados (${TempCompleted.length})`,
            },
        ]);
        
    }

    React.useEffect(()=>{
        getOrders().then((data)=>{
            if(isCanceled){
                if(data?.orders.length > 0){
                    setOrders(data?.orders);
                    setFilteredOrders(data?.orders);
                    setOrderFilters(data?.orders);
                }
                setCharge(true);
                setLoading(false);  
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });
        return () => (setIsCanceled(false));
    }, []);


    const handleSync = async () => {
        setLoading(true);
        setCharge(false);
        getOrders().then((data)=>{
            if(isCanceled){
                if(data?.orders.length > 0){
                    setOrders(data?.orders);
                    setFilteredOrders(data?.orders);
                    setOrderFilters(data?.orders);
                }
                setCharge(true);
                setLoading(false);  
            }
        }).catch((err) => {
            log(`Failed: ${err}`);
        });
    };

    /*const tabsData = [
        {
            label: 'Todos',
        },
        {
            label: 'En checkout',
        },
        {
            label: 'En proceso',
        },
        {
            label: 'Detenidos',
        },
        {
            label: 'Completados',
        },
    ];*/


    return (
        <>
            <Tabs
                onChange={handleChange}
                activeTab={activeTab}
                tabs={tabsDataDynamic.map(it => it.label)}></Tabs>

            {/*<Box display="flex" justifyContent="center">
                <Box onClick={(e) => applyFilter(e, 0)} m="10px" ml="15px"><Typography variant="body1" className={classes.filters} style={{ color: selectFiler == 0 ? "#9F6FFB": "white", textDecoration: selectFiler == 0 ? "underline #9F6FFB": "unset"}}>Todos</Typography></Box>
                <Box onClick={(e) => applyFilter(e, 1)} m="10px" ml="15px"><Typography variant="body1" className={classes.filters} style={{ color: selectFiler == 1 ? "#9F6FFB": "white", textDecoration: selectFiler == 1 ? "underline #9F6FFB": "unset"}}>Checkout</Typography></Box>
                <Box onClick={(e) => applyFilter(e, 2)} m="10px" ml="15px"><Typography variant="body1" className={classes.filters} style={{ color: selectFiler == 2 ? "#9F6FFB": "white", textDecoration: selectFiler == 2 ? "underline #9F6FFB": "unset"}}>Proceso</Typography></Box>
                <Box onClick={(e) => applyFilter(e, 3)} m="10px"><Typography variant="body1" className={classes.filters} style={{ color: selectFiler == 3 ? "#9F6FFB": "white", textDecoration: selectFiler == 3 ? "underline #9F6FFB": "unset"}}>Detenido</Typography></Box>
            </Box>*/}
            <Button color="primary" className="mb-2 mr-2" startIcon={<SyncIcon />} disabled={loading} onClick={handleSync}>Actualizar ordenes</Button>
            <Loader loading={loading} transparent={true} className={classes.loader}>
                {filteredOrders.length > 0 ? !loading && <Box className={classes.main}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHeadCustom>
                                <TableRow>
                                    <TableCell />
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'center'}
                                            padding={'normal'}
                                            sortDirection={orderBy === headCell.id ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                            >
                                                {headCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHeadCustom>
                            <RowContainer orders={filteredOrders} order={order} page={page} rowsPerPage={rowsPerPage} orderBy={orderBy} />
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[2, 5, 10, 25]}
                        component="div"
                        count={filteredOrders.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
                :<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {charge && <NothingFound
                        caption={selectFiler == 0 ? "No hay ordenes": selectFiler == 1 ? "No hay ordenes en checkout": selectFiler == 2 ? "No hay ordenes en en proceso": selectFiler == 3 ? "No hay ordenes detenidas": selectFiler == 4 && "No hay ordenes completadas"}
                        subcaption={""}
                    /> }
                </Box>}
            </Loader>
        </>
        
    );
};

export default OrdersStatus;