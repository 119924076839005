import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.colors.grey[400],
        borderRadius: 8
    },
    fileIcon:{
        fontSize: 40,
        // color: theme.palette.colors.fillStroke[100]
    },
    actionIcon:{
        color: theme.palette.colors.grey[100],
        fontSize: 20
    },
    infoIcon:{
        fontSize: 13
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    fileName:{
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '19px',
        marginRight: 10
    }
}));