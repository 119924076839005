import {SURVEYS_PATH} from './endpoints';

export const getSurveys = async (user) => {
    const url = `${SURVEYS_PATH}/${user.student_id}?ou=${user.ou}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'x-api-key': 'JEod5pgrda4bB2ie3yMNLaOMQeJdODRC6dR4Gi7A'
        }
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}
