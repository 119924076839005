import React, { useEffect, useState } from 'react';

import { useSelector,connect } from 'react-redux';

import './interestSelectorStyles.css';

// core components
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';

//MIXPANEL
import {  MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../shared/components/Mixpanel/mixpanel';
import { getSessionCookie } from '../../../../shared/utils/Sessions' ;

import InterestCard from './InterestCard/InterestCard';
import InterestsScreen from './InterestScreen/InterestScreen';
import InterestGroup from './InterestGroup/InterestGroup';

import { AccountService } from '@sdk-point/talisis';
import { CircularProgress } from '@material-ui/core';
import * as userActions from '../../../../redux/actions/userActions';

const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

export const InterestSelector = (props) => {

  const { person_id } = useSelector((state) => state.userReducer.user);

  const { loading, interest_areas: stateAllInterests, userInterests, onChangeInterest,
          onSubmit, handleInterestMode } = props;
          
  const [academiesReady, setAcademiesReady] = useState(false);
  const [allAcademyAreas, setAllAcademyAreas] = useState([])
  const [selectedAcademies, setSelectedAcademies] = useState([]);
  const [academyInterests, setAcademyInterests] = useState([]);
  const [loadingInterests, setLoadingInterests] = useState(false);
  const [allAcademiesInterests, setAllAcademiesInterests] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [interestToSave, setInterestToSave] = useState(userInterests.userInterests);
  const [prevSelectedSkills, setPrevSelectedSkills] = useState([]);
  const [savingInterests, setSavingInterests] = useState(false)

  useEffect(() => {
    if(selectedAcademies.length === 0 && interestToSave.length === 0){
      const areaCardInfo = stateAllInterests.map( interest => (
        {...interest, selected: 0}
      ))
      setAllAcademyAreas(areaCardInfo);
    }
    onChangeInterest(interestToSave, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedInterests, selectedAcademies.length, stateAllInterests, interestToSave, onChangeInterest]);

  useEffect(() => {
    if(selectedAcademies.length === 0 && interestToSave.length > 0){
      const areaCardInfo = stateAllInterests.map( interest => {
        if( interestToSave.some( selectedInterest => interest.id === selectedInterest.id) ){
          setSelectedAcademies( prevState => [...prevState, interest.id]);
          return {
            ...interest,
            selected: 1
          }
        }
        if( !interestToSave.some( selectedInterest => interest.id === selectedInterest.id) ){
          return {
            ...interest,
            selected: 0
          }
        }
      })
      
      const oldSkills = [];
      interestToSave.map( skill => {
        if(!stateAllInterests.some( interest => skill.id === interest.id)) {
          oldSkills.push(skill)
        }
      })
      //setPrevSelectedSkills(oldSkills);
      setAllAcademyAreas(areaCardInfo);
    }

    onChangeInterest(interestToSave, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academyInterests])
  
  const updateProfileAcademies = () => {
    const currentAcademies = allAcademyAreas.filter(interest => 
      selectedAcademies.includes(interest.id)
      );
      let modifyUserAcademies = [];
      for (const academy of currentAcademies) {
        modifyUserAcademies.push(
            { 
              id: academy?.id,
              name: academy?.name,
              selected: academy?.selected,
              is_favorite: 0
            }
        )
      }
      //ccc
      setSelectedAcademies(modifyUserAcademies);
  }

  const updateProfileInterests = () => {
    const currentInterests = 
    allAcademiesInterests.filter((academyInterest) => 
        selectedInterests.some( interest => 
            academyInterest.id === interest.interestId
        ));
      let modifyUserInterests = [];
      let arrInt = []
      for (const interest of currentInterests) {
        modifyUserInterests.push(
            { ...interest, is_favorite: 0 }
          )
      }
      arrInt = [ ...selectedAcademies, 
        ...modifyUserInterests ];
      setInterestToSave([ ...selectedAcademies, 
        ...modifyUserInterests ]);
        props.setInterests(arrInt.map(it => it.id));
  }
  
  const maxAcademyLength = 5;
  const currentSelectedTotal = allAcademyAreas.filter(( academy ) => academy.selected === 1).length;

  const changeSelectedProp = (academyId, isSelected) => {
    if( currentSelectedTotal === maxAcademyLength && isSelected === 0) return;
    
    const modifiedAcademies = allAcademyAreas.map( academy => {
      if(academy.id === academyId){
        return {
          ...academy,
          selected: (isSelected === 0) ? 1 : 0
        }
      }
      return academy
    })
    //ccc
    setAllAcademyAreas(modifiedAcademies);
    
    const previouslySelected = selectedAcademies.some( selectedAcademyId => selectedAcademyId === academyId)
    if( previouslySelected ){
      const removeSelectedAcademy = selectedAcademies.filter( selectedAcademyId => selectedAcademyId !== academyId);
      return setSelectedAcademies(removeSelectedAcademy);
    }
    setSelectedAcademies( prevState => [...prevState, academyId]);
  }

  const handleInterestSteps = () => {
    setAcademiesReady(!academiesReady);
  }

  const doFetch = async() => {
    setLoadingInterests(true);
    const response = await AccountServiceSDK.getSubInterestAreas(person_id, selectedAcademies)
    return response;
  };

  const removeDuplicates = (array) => {
    const newArray = array.filter((value, index, self) =>
      index === self.findIndex((item) => (
        item.place === value.place && item.name === value.name
      ))
    )
    return newArray
  }

  const handleNextStep = async() => {
    handleInterestSteps();
    const academiesInterests = await doFetch();
    const allAcademiesSelectedInterests = await academiesInterests.map(({ interestsAreas }) => interestsAreas );
    const addSelectedPropToInterest = allAcademiesSelectedInterests.flat().map( interest => (
      {...interest, selected: 0}
    ));
    const filteredSkills = removeDuplicates(addSelectedPropToInterest);
    setAllAcademiesInterests(filteredSkills);
    setAcademyInterests(academiesInterests);
    updateProfileAcademies();
    setLoadingInterests(false);
  }

  //ccc se borraria esta parte cuando se de hacia atras, preservando los datos del state
  const handlePrevStep = () => {
    handleInterestSteps();
    setAllAcademiesInterests([]);
    setAcademyInterests([]);
    setSelectedAcademies([]);
    arryPrevStep();
    setLoadingInterests(false);

  }

  const arryPrevStep = () => {
    for (const academy in selectedAcademies){
      setSelectedAcademies( prevState => [...prevState, selectedAcademies[academy].id]);
    }
  }
  
  const handleCancelBtn = () => {
    setInterestToSave(userInterests.userInterests);
    handleInterestMode()  
  }

  const validateAcademiesInterests = (array) => {
    if(array === undefined) return;    
    return array.some( interest => ( selectedInterests.some( skill => skill.interestId === interest.id)))
  };

  const validateCurrent = academyInterests?.map( interestList => {
    return validateAcademiesInterests(interestList?.interestsAreas)
  });
  const hasMinSelectedSkills = validateCurrent?.every( validation => validation === true)

  const saveInterest = () => {
    //reducer
    //props.setInterests(userInterests.userInterests.map(it => it.id));
    setSavingInterests(true);
    updateProfileInterests();
  }

  useEffect(() => {
    if( !savingInterests ) return 

    //MIXPANEL: ACTUALIZACION DE INTERESES
    const session = getSessionCookie();
    const areaMaped = interestToSave.map(mixpanelTrigger);

    function mixpanelTrigger(areaint){

      const role = JSON.parse(localStorage.getItem('permissions'));
      const eventdata = {
          userId: session.person_id,
          email: session.email,
          unidadOperativa: session.ou,
          gender: session.gender,
          nombreInteres: areaint.name,
          favorito: areaint.is_favorite,
          skillType: areaint.skill_type_id,
          userRole: JSON.stringify(role[0].role_name).replace(/["']/g, ""),//estudiante,maestro,admin
      }
       
    }

    onSubmit('userInterests');
    handleInterestMode();
    
    setSavingInterests(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInterests.userInterests]);

  const academyList = 
    allAcademyAreas.length > 0 &&
    allAcademyAreas.map(academy => 
      <InterestCard
        academy = {academy}
        selectedAcademies = {selectedAcademies}
        btnFunction={() => changeSelectedProp(academy.id, academy.selected)}
      />
    )
    
    const interestsLists = 
    academyInterests.length >= 3 &&
    academyInterests.map(academy => 
      <InterestGroup
        key={academy.id}
        academyName={ allAcademyAreas[academy.id - 1]?.name }
        academyId={academy.id}
        interestList={ academy.interestsAreas }
        allSelectedInterestsList={selectedInterests}
        changeAllSelectedInterest={setSelectedInterests}
        allAcademiesInterests={allAcademiesInterests}
        setAllAcademiesInterests={setAllAcademiesInterests}
        changeInterestToSave={setInterestToSave}
        interestToSave={interestToSave}
        selectedAcademies={selectedAcademies}
        prevSelectedSkills={prevSelectedSkills}
      />
    )

  return (
    <LoaderContent loading={loading || loadingInterests}>
      {
        !academiesReady
        ?
          <InterestsScreen
            title={'Listado de Mis Intereses'}
            caption1={'Con esta información personalizaremos tu experiencia para potenciar efectivamente tu perfil profesional.'}
            caption2 ={'Elige de 3 a 5 intereses favoritos.'}
            interestList={academyList}
            btn1Text={"Siguiente"}
            btn1Function={() => handleNextStep()}
            btn1Disabled={currentSelectedTotal < 3}
            btn2Text={"Cancelar"}
            btn2Function={() => handleCancelBtn()}
          />
        :
          <>
          {
              !loadingInterests &&
              <InterestsScreen
                  title={'Editar mis temas de Interes'}
                  caption1={'Esta información nos ayudará a refinar aún mejor tu perfil profesional.'}
                  caption2 ={'Selecciona 10 temas de tu interés'}
                  interestList={interestsLists}
                  btn1Text={ savingInterests ? <CircularProgress size="1.2em" /> : "Guardar" }
                  btn1Function={saveInterest}
                  btn1Disabled={!hasMinSelectedSkills}
                  btn2Text={"Regresar"}
                  btn2Function={() => handlePrevStep()}
              />
          }
          </>
      }
    </LoaderContent>
  )
}

export default connect(null, { ...userActions})(InterestSelector);