import React from 'react';

//@material
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';


// styles
import useStyles from '../../../../../../assets/styles/jss/pages/requests/DeadlineStyle'

// shared
import dateFormat from '../../../../../../design-system/utils/dateFormat';
import calcDaysBetweenDates from '../../../../../../shared/utils/calcDaysBetweenDates';
import { SIMPLE_DATE_FORMAT } from '../../../../../../design-system/constants/date-formats';
import capitalize from '../../../../../../design-system/utils/capitalize';

// icons
import calendarEventLineSVG from '../../../../../../assets/images/icons/calendar-event-line.svg';


// Abreviamos el mes a solo la cantidad de letras especificadas
const abbreviatedMonth = (date) => {
    // Cantidad de letras especificadas
    const numberOfLetters = 3;

    if (!!date) {
        const words = date.split(' ');
        // Validamos que el tamanio de words sea de 3 por la separacion de las fechas. Ej. 18 May 2000
        if (words.length === 3) {
            words[1] = capitalize(words[1].substring(0, numberOfLetters));

            return words.join(' ');
        }

    } 

    return "";
};


function Deadline(props) {
    const {date} = props;
    const [remainingDays, setRemainingDays] = React.useState(0);

    React.useEffect(()=>{
        if(date){
            const diff = calcDaysBetweenDates(new Date(), date);
            setRemainingDays(diff)
        }
    },[date])

    const classes = useStyles();

    return (
        <Card component={Box} mb={2} variant="outlined" className="PointCard-outlined-blue">
            <CardContent className={classes.cardContent} component={Box} display="flex" alignItems="center">
                <Box>
                    <img src={calendarEventLineSVG} className={classes.icon}/>
                    

                    {/* <DateRangeOutlinedIcon className={classes.icon}/> */}
                </Box>
                <Box className={classes.infoText}>
                    <Typography className="text-orange-400" component="div">Fecha Límite</Typography>
                    <Typography component="div">{abbreviatedMonth(dateFormat(date, SIMPLE_DATE_FORMAT))}</Typography>
                    <Typography component="div">{remainingDays < 0 ? 'Excedido': 'Faltan'} {Math.abs(remainingDays)} días</Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

Deadline.defaultProps = {
    
};

export default Deadline;