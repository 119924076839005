export const LEVEL_NAME = {
    MA: "maestría",
    BA: "bachillerato",
    LI: "licenciatura",
    PO: "mba",
    COURSE: "curso"
};

export const CHIP_CLASSNAME = {
    MA: "chip3-green-500",
    BA: "chip3-magenta",
    LI: "chip3-blue-400",
    PO: "chip3-orange-400",
    COURSE: "chip3-transparent"
};