import React from 'react';
import BrandLandingProgramsCardUnid from './BrandLandingProgramsCard/BrandLandingProgramsCardUnid';
import BrandLandingProgramsCardUerre from './BrandLandingProgramsCard/BrandLandingProgramsCardUerre';
import BrandLandingProgramsCardHarmonHall from './BrandLandingProgramsCard/BrandLandingProgramsCardHarmonHall';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root:{
        padding: '56px 64px',
        [theme.breakpoints.down('sm')]:{
            padding: '56px 15px 40px'
        },
    },
    container:{
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]:{
            display: 'flex',
            flexDirection: 'column'
        },
    },
}));

const BrandProgramsCard = ({brand}) => {
    const classes = useStyles();
    
    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                {brand === 'unid' && <BrandLandingProgramsCardUnid brand={brand} />}
                {brand === 'u-erre' && <BrandLandingProgramsCardUerre brand={brand} />}
                {brand === 'harmonhall' && <BrandLandingProgramsCardHarmonHall brand={brand} />}
            </Box>
        </Box>
    )
};

export default BrandProgramsCard;