import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { Box, Button, Card, CardContent, Grid, Hidden, Icon, IconButton, Typography } from '@material-ui/core';

// styles 
import useStyles from '../../../assets/styles/components/filters/filterSelectionStyle';
import FilterSection from './FilterSection/FilterSection';

const FilterSelection = ({onChangeAccording, onChangeChecked, data, onResetFilters, accordingFilters, onChangeShowFilters, onClickReadyFilterButton, showClearFilterButton}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} direction="column" className={classes.rootFilterSelection}>
            <Grid item xs={12}>
                <Card className={classes.cardContainer}>
                    <CardContent>
                        <Box className={classes.containerFilterTitle}>
                            <Typography varciant="body1" className={classes.filterTitleLabel}>Filtrar por:</Typography>
                            <Hidden lgUp>
                                <IconButton onClick={onChangeShowFilters} aria-label="close-filter" component="span" className={classes.buttonComponent}>
                                    <Icon className={`ri-close-line font-size-24 ${classes.closeFilterIcon}`}></Icon>
                                </IconButton>
                            </Hidden>
                        </Box>
                        
                        { data.map(item => <FilterSection id={item.id} isExpanded={(accordingFilters.find(accordingItem => accordingItem.id === item.id))?.isExpanded} key={item.id} title={item.title} data={item.data} onChangeAccording={onChangeAccording} onChangeChecked={onChangeChecked}/>) }

                        {
                            showClearFilterButton &&
                            <Grid container direction="row" className={classes.searchFilterTitleBottom} onClick={onResetFilters}>
                                <Grid item xs>
                                    <Box className={classes.restoreFilters}>
                                        <Typography variant="body1" className={`semi-bold ${classes.deleteFiltersLabel}`}>Eliminar filtros</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs="auto">
                                    <Box className={classes.refreshIconContainer}>
                                        <Icon className={`ri-filter-off-line ${classes.refreshIcon} font-size-20`}></Icon>
                                    </Box>
                                </Grid>
                            </Grid>
                        }


                        <Hidden mdUp>
                            <Box className={classes.readyBottomContainer}>
                                <Button
                                    color="secondary"
                                    label="Listo"
                                    onClick={onClickReadyFilterButton}
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                >Listo</Button>
                            </Box>
                        </Hidden>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

FilterSelection.defaultProps = {  
    data: [],
} 

FilterSelection.propTypes = {
    onChangeAccording: PropTypes.func.isRequired,
    onChangeChecked: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onResetFilters: PropTypes.func.isRequired,
    accordingFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeShowFilters: PropTypes.func,
    onClickReadyFilterButton: PropTypes.func,
    showClearFilterButton: PropTypes.bool
};

export default FilterSelection;