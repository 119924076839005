import React from 'react';
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';

// redux
import { useSelector } from 'react-redux';

// @material-ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';

// assets
import useStyles from '../../../assets/styles/jss/pages/cart/cartStyle';

const CartPriceDetails = () => {
    const classes = useStyles();
    const { cart } = useSelector(state => state.cartReducer);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return <Box className={clsx(classes.cartPriceDetails, 'p-3')}>
        <Grid container alignItems='center'>
            <Grid item>
                <Typography variant='h5' className='font-weight-700'>Sub-total:</Typography>
            </Grid>
            <Grid item xs >
                <Grid container alignItems='center' justifyContent='flex-end' spacing={1}>
                    {cart?.total_discounts > 0 &&
                        <Grid item>
                            <Typography variant='caption' className={clsx("text-dark-black-100 font-weight-600", classes.cartPriceOff)}>
                                <CurrencyFormat value={cart?.subtotal || 0} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" />
                            </Typography>
                        </Grid>
                    }
                    <Grid item>
                        <Typography variant='body1' className={clsx({ "text-orange-300": true, "font-weight-700": true, "font-size-22": isMobile })}>
                            <CurrencyFormat value={cart?.total || 0} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>;
}

export default CartPriceDetails;