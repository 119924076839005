import { makeStyles } from '@material-ui/core/styles';
const TABLE_TYPE_COUPONS =  "coupons";
export default makeStyles((theme) => ({
    checkbox:{
        '&.MuiCheckbox-indeterminate':{
            color: '#ACF1FC'
        },
        "&.Mui-disabled" : {
            color: theme.palette.colors.violet[300],
        },
        color: theme.palette.colors.violet[300],
        borderColor: theme.palette.colors.fillStroke[300],
    },
    tableRow:{
        backgroundColor:  props => props.stripedRows ? "inherit" : theme.palette.colors.fillStroke[400],
        borderWidth: "0.5px 0px",
        borderStyle: "solid",
        height: props => props.type == TABLE_TYPE_COUPONS ? '80px' : 'inherit',
        '&.Mui-selected':{
            backgroundColor: '#FAFAFB',
            '&:hover':{
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            }
        },
    },
    tableNotFound:{
        backgroundColor: theme.palette.colors.fillStroke[400],
        borderWidth: "0.5px 0px",
        borderStyle: "solid",
        borderColor: theme.palette.colors.fillStroke[300],
    },
    tableCell:{
        borderWidth: "0.5px 0px",
        borderStyle: "solid",
        borderColor: theme.palette.colors.fillStroke[300],
        fontSize: "14px",
    },
    buttonSortTable: {
        '& svg': {
            color: `${theme.palette.colors.white} !important`,
        }
    },
    stripedRows:{
        '&:nth-of-type(odd)': {
            backgroundColor: `${theme.palette.colors.fillStroke[400]} !important`,
        },
    }
}));