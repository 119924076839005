import React, { useEffect } from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';

// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

// shared - core components
import Select from '../../../../../design-system/components/Form/Select/Select'
import ListGroup from '../../../../../shared/components/Form/ListGroup'
import unidCampus from '../../../../../shared/constants/unid-campus-requests'

// shared - icons
import searchSVG from '../../../../../assets/images/icons/search.svg';

// constants
import operatingUnits from '../../../../../shared/constants/operating-units'
import { log } from '../../../../../shared/utils/console.js'

//SDK
import { RequestService } from '@sdk-point/talisis'; 


const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
export default function NewRequestItemButton(props){

    const [open, setOpen] = React.useState(false);
    const [info, setInfo] = React.useState({});
    const [unidad, setUnidad] = React.useState({});
    const [form, setForm] = React.useState({
        request: '',
        campus: ''
    });
    const [requestsList, setRequestsList] = React.useState([])
    const [requestsListSearch, setRequestsListSearch] = React.useState([])
    const [busqueda, setBusqueda] = React.useState('');

    var disabledButton = (props.ou === operatingUnits.UNID && !form.campus) || !form.request;

    useEffect(() => {
        getRequest();
    }, []);

    const getRequest = async () => {
        try {
            // const requestsList = await RequestServiceSDK.getRequestsByType(REQUEST_TYPE);
            const requestsList = await RequestServiceSDK.getRequestsByCompany(props.ou);

            requestsList.forEach(element => element.label = element.title);

            setRequestsList(requestsList)
            requestGroup(requestsList);
            

        } catch (e) {
            log('error', e)
        }
    }

    const requestGroup = (items) => {
        let itemsGroup = [{}];
        
        items.forEach(x => {
            if( !itemsGroup.hasOwnProperty(x.area_id)){
                itemsGroup[x.area_id] = {
                    nameArea: x.area,
                    area: []
                }
            }

            itemsGroup[x.area_id].area.push({
                ...x
              })
        });
        itemsGroup = itemsGroup.splice(1, itemsGroup.length);

        setRequestsListSearch(itemsGroup);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setForm({
            request: '',
            campus: ''
        })
    };

    const handleClickNext = () => {
        setAnchorEl(null);
        props.onClickNext(form);
        handleClose();
    };

    const handleChange = (e) => {
        setBusqueda(e.target.value);
        filterRequest(e.target.value);
        // const {name, value} = e.target;
        // setForm({
        //     ...form,
        //     [name]: value
        // })
    }

    const filterRequest = (terminoBusqueda) => {
        var resultadoBusqueda = requestsList.filter((elemento) => {
            if(elemento.title.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())){
                return elemento;
            }
        });
        requestGroup(resultadoBusqueda);
        //setRequestsListSearch(resultadoBusqueda);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleClickOpenInfo = (it) => {
        //const {name, value} = e.target;
        setOpen(true);
        setInfo(it);
        setUnidad(it.companies);
        setForm({
            ...form,
            request: it.id
        })
    };

    const openPop = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center" marginLeft="auto">
                <Button aria-describedby={id} color="primary" startIcon={<AddIcon />} onClick={handleClick} variant="contained">Nueva Solicitud</Button>
            </Box>
            <Popover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                
            >
                <Box sx={{
                        width: 1000,
                        maxWidth: '100%',
                    }}>
                    <Grid item xs={12} style={{padding: '20px'}}>
                        <TextField
                        value={busqueda}
                        onChange={handleChange} 
                        fullWidth
                        margin="dense"
                        id="outlined-basic" 
                        label="Busca el tipo de solicitud que deseas" 
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={searchSVG}/>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {/* <Typography sx={{ p: 6 }}>Academico</Typography> */}
                            <ListGroup
                                // required
                                items={requestsListSearch}
                                //label="Trámite"
                               // name="request"
                                value={form.request}
                                onOpenInfo={handleClickOpenInfo}>
                            </ListGroup>
                    </Grid>
                </Box>
            </Popover>

            <Dialog
                fullWidth 
                maxWidth="xs"
                onClose={handleClose} 
                aria-labelledby="simple-dialog-title" 
                open={open} 
                disableBackdropClick
                disableEscapeKeyDown 
                >
                    
                    <DialogTitle style={{textAlign: 'center'}} className="pb-0">
                        <Typography variant="subtitle1" style={{color: '#9ADEF1'}} component="div">
                            {info.title}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography  variant="subtitle1"  component="div"  color="text.secondary">
                                        Unidad Operativa:
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {unidad.length > 0 ? unidad[0].name : unidad}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Area:
                                    </Typography>
                                    <Typography component="div" variant="h5">
                                        {info.area}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Responsable:
                                    </Typography>
                                    <Typography component="div" variant="h5">
                                        {info.full_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Dias de Entrega:
                                    </Typography>
                                    <Typography component="div" variant="h5">
                                        {info.duration}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Divider />
                                <hr/>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Descripcion:
                                    </Typography>
                                    <Typography component="div" variant="h5">
                                        {info.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{padding: '8px 24px 12px'}} >
                        <Button color="primary" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleClickNext} disabled={disabledButton}>
                            Enviar Solicitud
                        </Button>
                    </DialogActions>
            </Dialog>
            {/* <Box display="flex" alignItems="center" marginLeft="auto">
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen} variant="contained">Nueva Solicitud</Button>
            </Box>

            <Dialog
                fullWidth 
                maxWidth="xs"
                onClose={handleClose} 
                aria-labelledby="simple-dialog-title" 
                open={open} 
                disableBackdropClick
                disableEscapeKeyDown >
                    <DialogTitle className="pb-0">Selecciona tu trámite</DialogTitle>
                    <DialogContent>
                        <Box mt={3}>
                            <Select
                                required
                                items={requestsList}
                                label="Trámite"
                                name="request"
                                value={form.request}
                                onChange={handleChange}
                            />
                        </Box>
                        {
                            props.ou === operatingUnits.UNID &&
                            <Box mb={3}>
                                <Select
                                    required
                                    items={unidCampus}
                                    label="Campus"
                                    name="campus"
                                    value={form.campus}
                                    onChange={handleChange}
                                />
                            </Box>
                        }
                    </DialogContent>
                    <DialogActions style={{padding: '8px 24px 12px'}} >
                        <Button color="primary" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleClickNext} disabled={disabledButton}>
                            Continuar
                        </Button>
                    </DialogActions>
            </Dialog> */}
            
        </React.Fragment>
    )

}