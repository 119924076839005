export const getPreviousMembership = (membership, memberships) => {
    if (membership?.level > 1) {
        if (memberships?.length > 0) {
            const previousItem = memberships.find((m, i, o) => m.level === (membership.level - 1));
            return previousItem;
        }
    }

    return null;
};
