import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    card:{
        width: 286,
        boxSizing: 'content-box',
        "&:hover": {
            background: "linear-gradient(241.18deg, #111111 25.24%, #081e24 100%) !important"
        },
        [theme.breakpoints.down('xs')]: {
            width: props => props.cardSizeResponsive ? props.cardSizeResponsive : 224, // Aplicar el valor numérico como ancho en píxeles
            border: 'none !important'
        }
    },
    cardContent:{
        display: 'grid',
        padding: '19px 16px 7px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 12px 8px',
            height: 116,
        }
    },
    cardMedia:{
        height: 168,
        [theme.breakpoints.down('xs')]: {
            height: 130
        }
    },
    cardActions:{
        borderTop: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        padding: '12px 16px',
        '&.discount':{
            borderTop: '3px solid',
            borderImage: 'linear-gradient(to right, #FCDC43, #FFA700) 1',
            borderImageSlice: 1,
            paddingTop: 10
        },
        [theme.breakpoints.down('xs')]: {
            padding: '7px 12px',
        }
    },
    carousel: {
        '& .slick-list':{
            '& .slick-track':{
                display: "flex",
                flexFlow: "nowrap",
            },
            '& .slick-slide':{
                width: "312px",
            }
        }
    },
    cardContentTitle:{
        height: 58,
        overflow: 'hidden',
        width: '100%',
        '& .title':{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 2, /* number of lines to show */
                    'line-clamp': 2,
            '-webkit-box-orient': 'vertical',
        },
        [theme.breakpoints.down('xs')]: {
            height: 32,
            '& .title':{
                ...theme.typography.bodyTextXsmallSemiBold
            }
        }
    },
    cardContentTags:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 24,
        '& .bookmark-icon':{
            fontSize: 20
        },
        [theme.breakpoints.down('xs')]: {
            height: 19,
            '& .tag-title':{
                ...theme.typography.overlineSmallSemiBold
            },
            '& .bookmark-icon':{
                fontSize: 17
            },
        }
    },
    cardContentLogo:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        [theme.breakpoints.down('xs')]: {
            height: 43
        }
    },
    cardContentLevelModality:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: 8,
        height: 16
    },
    raiting:{
        pointerEvents: "none",
    },
}));