import React from 'react';
import { Button, Icon, Popover,Box, Collapse } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    content:{
        padding: 24,
        flexDirection: 'row'
    },
    [theme.breakpoints.down('xs')]:{
        content:{
            padding: '24px 16px 16px',
            flexDirection: 'column'
        },
        boxCollapse:{
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.60)',
            backgroundColor: theme.palette.colors.fillStroke[400],
            position: 'absolute',
            left: 0,
            zIndex: 1
        }
    }
}));

const AdvancedFilters = ({children, onClickApplyFilters, ...gridItemProps}) => {
    const classes = useStyles();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const paperElement = document.getElementById("table-filter-root");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const id = open ? 'simple-popover' : undefined;
    const paperWidth = paperElement ? paperElement.clientWidth + 25 : 0;

    const handleClick = () => {
        if(!isResponsive){
            setOpen(true)
            setAnchorEl(paperElement);
        }else{
            setOpen((prev) => !prev);
        }
    };
  
    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null);
    };

    const handleClickApply = () => {
        handleClose();
        onClickApplyFilters();
    }

    const Filters = () => (
        <Box className={classes.content} display='flex' gridGap={24}>
            {children}
            <Box display='flex' alignItems='end' minWidth='15%'> 
                <Button variant='contained' color='primary' fullWidth onClick={handleClickApply}>Aplicar</Button>
            </Box>
        </Box>
    )

    return (
        <>
            <Button
                fullWidth  
                endIcon={<Icon className='ri-equalizer-line'></Icon>} 
                variant='outlined' color='secondary' 
                onClick={handleClick}>
                    Filtrado avanzado
            </Button>

            {
                isResponsive
                ?
                    <Box className={classes.boxCollapse}>
                        <Collapse in={open}>
                            <Filters/>
                        </Collapse>
                    </Box>
                :<Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={
                        isResponsive 
                        ?   { vertical: 'top', horizontal: 'left',}
                        :   { vertical: 'bottom',    horizontal: 'center'}
                    }
                    transformOrigin={
                        isResponsive 
                        ? { vertical: 'top', horizontal: 'left',}
                        : { vertical: 'top', horizontal: 'center'}
                    }
                    elevation={1}
                    PaperProps={{
                        style:{width: paperWidth}
                    }}
                >
                    <Filters/>
                </Popover>
            }            
        </>
    );
};

export default AdvancedFilters;