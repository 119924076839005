import moment from 'moment'
import 'moment/locale/es';
import {DEFAULT_DATE_FORMAT} from '../constants/date-formats'

export default (date, format = DEFAULT_DATE_FORMAT, addHour = 0, inputFormat = []) => {
    let _date;

    if(!date) return null;

    _date = inputFormat.length ?  moment(date,inputFormat) : moment(date);

    return _date.locale('es').add(addHour, 'hours').format(format);
}
