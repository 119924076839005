import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const DividerDetail = ({children, title, titleComponent, ...rest}) => {
    return (
        <Box display="flex" style={{borderBottom: '2px solid #c1f0f6'}} alignItems="center" {...rest}>
            {
                title 
                ?
                    <Typography color="primary" variant="h5">{title}</Typography>
                : 
                titleComponent
            }
            {children}
        </Box>
    );
};

export default DividerDetail;