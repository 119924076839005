import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Icon, Typography, IconButton, Dialog, DialogTitle } from '@material-ui/core';

import Select from '../../../design-system/components/Form/Select/Select';

const useStyles = makeStyles((theme) => ({
    dialogTitle:{
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid rgba(119, 119, 119, 0.15)`,
        '& .MuiTypography-root':{
            flex: 1,
            textAlign: 'center'
        }
    }
}));

const NewDocumentButton = ({onClick, listDocument}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState("");

    const handleClose = () => {
        setValue('');
        setOpen(false)  
    };

    const handleClickOpen = () => {
        setValue('');
        setOpen(true);
    };

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const hanldeClickNewDocument = () => {
        onClick(value);
        setOpen(false);
    }

    return (
        <>
            <Button variant='contained' color='primary' fullWidth onClick={handleClickOpen}>Solicitar un nuevo documento</Button>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xs" fullWidth>
                <DialogTitle id="simple-dialog-title" disableTypography className={classes.dialogTitle}>
                    <Typography variant="subtitle1">Solicitar documento nuevo</Typography>
                    <IconButton aria-label="close" className='p-0' onClick={handleClose}>
                        <Icon className='ri-close-line text-fill-stroke-100' style={{fontSize: 24}}/>
                    </IconButton>
                </DialogTitle>
                <Box p={3}>
                    <Select 
                        label="Selecciona el documento a solicitar"
                        value={value}
                        name="newStatus"
                        placeholder="Seleccionar estatus"
                        required
                        items={listDocument} 
                        onChange={handleChange}
                    />
                    <Button variant='contained' color='primary' fullWidth 
                        className='offset-mt-4' disabled={!value} 
                        onClick={hanldeClickNewDocument}>
                        Solicitar el documento
                    </Button> 
                </Box>
            </Dialog>
            
        </>
    );
};

export default NewDocumentButton;