import React, { useEffect, useState } from "react";
import { Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { generatePath } from "react-router";

import * as paths from '../../../../routes/paths';

// Material UI
import { Box, Typography, useMediaQuery } from '@material-ui/core';

//components
import StatusCourseCard from '../../../../design-system/components/Cards/StatusCourseCard/StatusCourseCard';
import { getSourceBySourceAndCompany } from '../../../../design-system/utils/source';
import ModalClassEnrollment from "../../../../shared/components/ModalClassEnrollment/ModalClassEnrollment";

//ver si usar
import { convertSourceToImageResource } from '../../../../../src/constants/placeholderImages';
import getConnectionAllies from '../../../../shared/components/Allies/AlliesConnect';
import { log } from '../../../../shared/utils/console.js'
import { useSelector } from "react-redux";

// Paths
import { CURSO_DETALLE, SIGNUP } from '../../../../routes/paths';

// SDK Point 
import { MembershipService } from '@sdk-point/talisis';

// Styles
import useStyles from "../../../../assets/styles/jss/pages/courses/academiesSectionStyle";



const CardProgramListExpand = (props) => {
    const user = useSelector((state) => state.userReducer.user);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();
    const classes = useStyles();
    const [NoAvailable, setNotAvailable] = useState(0);
    const [modalProps, setModalProps] = React.useState({ open: false, loading: false, course: null, error: null });

    useEffect(() => {
        setNotAvailable(props.no_available)
        if (props.cuatrimestre !== props.area_priority || props.remaining_credits === 0){
            setNotAvailable(1)
        }
    }, []);

    const [img, setImg] = useState(props.image_url);

    useEffect(() => {
        if (img === null) {
            const newImg = convertSourceToImageResource(props.company_id, props.source_id);
            setImg(newImg);
        }
    }, [img, props.company_id, props.source_id]);

    const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)

    const handleClickGoToCourse = (course) => async () => {
        const route = window.location.protocol + '//' + window.location.host + '/redireccionando/' + course.source_id;
        let win = window.open(route, "_blank");
        goToCourse(win, course);
    };

    const handleClickGoToDetail = (courseId) => () => {
        history.push(generatePath(CURSO_DETALLE, {id: courseId}));
    };

    const goToCourse = async (win, course) => {
        try {
            const body = {
                person_id: user?.person_id,
                email: user?.email,
                ou: user?.ou,
                course_url: course.course_url,
                source_id: course.source_id,
                course_url_neo: course.course_url_neo,
                course_id: course.course_id,
                company_id: course.company_id
            }

            var urlRespuesta = await getConnectionAllies(body);

            setTimeout(() => {
                win.focus();
                win.location.replace(urlRespuesta);
            }, 3000);
        } catch(error) {
            log(error);
        }
    };

    const handleClickEnrollment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: true, loading: false, error: null, course: props });
    };

    const handleOnCloseModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false, course: null });
    };

    const handleOnConfirmModal = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            setModalProps({ ...modalProps, loading: true });
            const body = {
                course_id: modalProps.course?.course_id,
                source_id: modalProps.course?.source_id,
                level_id: modalProps.course?.level_id,
                course_session_id: modalProps.course?.course_session_id,
                company_id: modalProps.course?.company_id,
                course_program: modalProps.course?.program_id,
                person_id: user.person_id,
                course_price:0
            };

            const responseEnrollment = await membershipService.postMembershipCourseEnrollment(body);
            log(responseEnrollment);

            setModalProps({ ...modalProps, loading: false, open: false });
            handleContinueToClass(modalProps.course);
            history.push(paths.CURSOS);
        }
        catch (e) {
            setModalProps({ ...modalProps, loading: false, error: e?.message });
        }
    };

    const handleContinueToClass = async (course) => {
        const body = {
            person_id: user?.person_id,
            email: user?.email,
            ou: user?.ou,
            course_url: course.course_url,
            source_id: course.source_id,
            course_url_neo: course.course_url_neo,
            course_id: course.course_id,
            company_id: course.company_id,
            course_name: course?.name,
        }
        const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + course?.source_id, "_blank");

        var urlRespuesta = await getConnectionAllies(body);
        setTimeout(() => {
            win.focus();
            win.location.replace(urlRespuesta);
        }, 3000);
    };

    return (
        <>
            <Suspense fallback={<div></div>}>
                <StatusCourseCard 
                    courseId={props.course_id}
                    userId={parseInt(user.person_id ?? 0)}
                    sourceIdLogo={getSourceBySourceAndCompany(props.source_id, props.company_id)}
                    enrollmentStatusId={props.enrollment_status_id}
                    noAvailable={NoAvailable}
                    tagPeriodo={props.tagPeriodo}
                    media={{
                        backgroundImage: img,
                        modalityId: props.modality_id,
                        percentage: props.progress_percentage,
                    }}
                    content={{
                        courseName: props.name,
                    }}
                    isLogged={true}
                    onClickGoToCourse={handleClickGoToCourse(props)}
                    onClickRegister={handleClickEnrollment}
                    onClickGoToDetail={handleClickGoToDetail(props.course_id)}
                    approved={props.approved}
                />
            </Suspense>
            <ModalClassEnrollment {...modalProps} onClose={handleOnCloseModal} onConfirm={handleOnConfirmModal} />
        </>
    )
};

export default CardProgramListExpand;