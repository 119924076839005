import React from 'react';
import { Link as RouterLink } from 'react-router-dom'

// @material-ui/core
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddIcon from '@material-ui/icons/Add';

// dependencies
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import clsx from 'clsx';

// shared
import MoreOptions from '../../../../../shared/components/MoreOptions/MoreOptions'
import TextError from '../../../../../shared/components/TextError/TextError';
import {DELETE_EDIT_OPTIONS} from '../../../../../shared/constants/more-options'

import styled from 'styled-components';

// style
import '../../../../../assets/styles/jss/components/timelineStyle';

const TimelineDotStyled = styled(TimelineDot)`
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
`

const TimelineConnectorStyled = styled(TimelineConnector)`
    ${({ theme: {palette} }) => `
        background-color: ${palette.primary.main}  
    `}
 `
 const TimelineItemStyled = styled(TimelineItem)`
    width: 50%;
    &:before {
        flex: 0!important;
    }
`;
function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

const Workflow = (props) =>{
    const [items, setItems] = React.useState([
        // {id: "1", title: 'test', assignee: [{id: 1,first_name: 'Brizeth', last_name: 'Puente', full_name: 'Damaris'}], instruction: 'test'},
        // {id: "2", title: 'test2', assignee: [{id: 1,first_name: 'Brizeth', last_name: 'Puente', full_name: 'Damaris'}], instruction: 'test'}
    ])

    React.useEffect(() => {
        setItems([])
        if(props.hasOwnProperty('steps')){
            setItems(props.steps)
        }

    }, [props]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const _items = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        props.onDragSteps(_items);
    }

    const handleClickOption = (step) => action => props.onClickAction(action, step);

    return(

        // <Box mt={12}>
        <Box>
            <Typography variant="subtitle2" className="mb-2">Flujo de autorización</Typography>
            {props.error && <TextError text={props.error}/>}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" isDropDisabled={props.readOnly}>
                    {(provided) => (
                        <Timeline
                        style={{alignItems: 'flex-start'}}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={props.readOnly}>
                            {(provided) => (
                                    <TimelineItemStyled ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <TimelineSeparator>
                                            <TimelineDotStyled color="primary">{index+1}</TimelineDotStyled>
                                            <TimelineConnectorStyled/>
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Paper elevation={1} component={Box} pb={2} px={2} pt={1} style={{borderRadius: 20}}>
                                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={props.readOnly ? 1 : ""}>
                                                    <Typography variant="h6" className="font-weight-600">{item.title}</Typography>
                                                    {
                                                        !props.readOnly && <MoreOptions options={DELETE_EDIT_OPTIONS} onClickOption={handleClickOption(item)} />
                                                    }
                                                    {
                                                        props.showStepPath &&
                                                        <IconButton color="secondary" component={RouterLink} to={`${props.showStepPath}/${item.id}`} style={{padding: 0}}>
                                                            <VisibilityOutlinedIcon />
                                                        </IconButton>
                                                    }

                                                </Box>
                                                <Box display="flex">
                                                    <Typography align="left" className="font-weight-600" variant="body2" component="span" style={{minWidth: 70, color: '#36BEE3'}}>
                                                        Asignado a:
                                                    </Typography>&nbsp;
                                                    {
                                                        React.isValidElement(item.assignee) 
                                                        ? item.assignee 
                                                        : <Typography component="span" variant="body2">{item.assignee.map(it => it.full_name).join()}</Typography>
                                                    }
                                                </Box>
                                                <Box display="flex">
                                                    <Typography align="left" className={clsx({ 'mr-2': index % 2 == 0 },'font-weight-600 text')} variant="body2" component="span" style={{minWidth: 70, color: '#36BEE3'}}>
                                                        Instrucciones:
                                                    </Typography>
                                                    <Typography component="span" variant="body2" className="text">{item.instruction}</Typography>
                                                </Box>                          
                                            </Paper>
                                        </TimelineContent>
                                    </TimelineItemStyled>
                            )}
                            </Draggable>
                        ))}
                        </Timeline>
                    )}
                </Droppable>
            </DragDropContext>

            {
                !props.readOnly && 
                <Box display="inline-block" alignItems="center" marginLeft="auto" >
                    <Button
                        disabled={props.disabledButton} 
                        color="primary"
                        //variant="outlined"
                        variant="contained"
                        startIcon={<AddIcon />} 
                        component={RouterLink} 
                        to={props.path}>
                        Agregar Paso
                    </Button>
                    {
                        props.disabledButton &&
                        <Box>
                            <Typography variant="caption">* Seleccione Unidad Operativa para habilitar la creación de flujo</Typography>
                        </Box>
                    }
                </Box>
                // <StepConfig onSave={hanldeAddNewStep} open={openStepConfig} form={stepForm}/>x
            }
        </Box>
    )
}


export default Workflow;