import React from 'react';

// @redux
import { connect } from "react-redux";

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

// shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import { log } from '../../../shared/utils/console.js'

// SDK
import { AccountService } from '@sdk-point/talisis'; 

const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
class PersonQR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            src: '',
            error: false
        };

        this.defaultUrl = "https://via.placeholder.com/100/FFFFFF/FFFFFF";
        this.handleError = this.handleError.bind(this);
    };
    
    getURL() {
        return `https://point-files.s3.amazonaws.com/qrcodes/${this.props.user.ou}/${this.props.user.person_id}.png`;
    };

    handleError = async () => {
        if (!this.state.error) {
            this.setState({ loading: true, src: this.defaultUrl });
            try {
                await AccountServiceSDK.createStudentQR(this.props.user);
                setTimeout(() => {
                    this.setState({ loading: false, src: this.getURL(), error: true })
                }, 500);
            }
            catch (e) {
                log(e.message);
            };
        }
    };

    render() {
        const { src, loading } = this.state;
        const { width, download } = this.props;

        return <Box width={width}>
            <Loader loading={loading} transparent={true}>
                <div>
                    <img src={src} onError={this.handleError} width="100%" alt="Codigo QR" />
                    {(!loading && download) ?
                        <Button
                            style={{ width: "100%" }}
                            variant="text"
                            color="primary"
                            href={this.getURL()}
                            startIcon={<GetAppIcon />}>
                            Descargar
                    </Button> : null
                    }
                </div>
            </Loader>
        </Box>
    };
};

PersonQR.defaultProps = {
    width: "100px",
    download: true
};

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(PersonQR);