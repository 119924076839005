import React, { Component } from 'react';

/// core components
import BodyRequest from '../components/BodyRequest/BodyRequest'

// @redux
import { connect } from "react-redux"
import * as requestWorkflowActions from '../../../../redux/actions/requestWorkflowActions'


import { log } from '../../../../shared/utils/console.js'

// routes
import * as routelinks from '../../../../routes/routelinks'
//SDK
import { GlobalService,RequestService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const breadcrumbItems = [
    {to: '/gestion-de-servicios', label: 'Trámites'},
    {to: '/gestion-de-servicios', label: 'Gestion de servicion'},
    {label: 'Nuevo Servicio'},
]

const DELETE = 2;
const SCOPE_OU = 1;


class NewRequest extends Component {


    constructor(props){
        super();

        this.state = {
            ...props.newRequest,
            // form:{...props.newRequest.form},
            // workflow: [...props.newRequest.workflow],
            errors:{},
            units:[],
            loading: false,
            breadcrumbItems,
            body: {},
            cou: 1
        }
    }    

    async componentDidMount(){
        const {form} = this.state;
        let {type} = form;

        if(!form.type){
            type = JSON.parse(localStorage.getItem('newRequestType')) || 1;
        }

        const units = await GlobalServiceSDK.getCatalog('CompanyCat');
        units.forEach(it => it.label = it.name);
        this.setState({units, form: {...form, type}})
    }

    handleClickActionStep = (action, step) => {
        if(action.id === DELETE){
            this.props.deleteStep(step.id);
        }else{
            this.props.history.push(`${routelinks.GESTION_SERVICIO_NEW_STEP}/${step.id}`);
        }
    }

    handleChange = e => {
        const {name, value} = e.target;
        const errors = {...this.state.errors};

        if(value){
            delete errors[name];
        }

        this.setState({
            form:{
                ...this.state.form,
                [name]: value,
            },
            errors
        });

        this.props.updateRequestWorkflowForm({
            ...this.state.form,
            [name]: value,
        })
    };

    handleChangeWorkflow = steps => {
        const errors = {...this.state.errors};
        if(errors.workflow && steps.length){
            delete errors.workflow;
        }
        this.setState({workflow: steps, errors})
    }

    handleDragSteps = (workflow) => {
        this.setState({workflow});
        this.props.setWorkflow(workflow);
    }

    handleSubmit = async (body) => {
        this.setState({loading: true})

        try {
            //log('body', body);
            await RequestServiceSDK.createRequest(body);
            this.setState({loading: false})
            this.props.history.push(routelinks.GESTION_SERVICIOS)
        } catch (e) {
            log(e);
            this.setState({loading: false})
        }
    }

    handleChangeErrors = (errors) => {
        this.setState({ errors })
    }

    handleChangeStep = step => this.props.updateStep(step);

    render() {
        return (
            <BodyRequest {...this.state} 
                //path={'/requests-managment/new/step'}
                path={routelinks.GESTION_SERVICIO_NEW_STEP}
                onClickActionStep={this.handleClickActionStep}
                onChange={this.handleChange}
                onChangeWorkflow={this.handleChangeWorkflow}
                onDragSteps={this.handleDragSteps}
                onSubmit={this.handleSubmit}
                onChangeErrors={this.handleChangeErrors}
                onChangeStep={this.handleChangeStep}
            />
        );
    }
}

const mapStateToProps = ({ requestWorkflowReducer }) => ({newRequest: requestWorkflowReducer});

export default connect(mapStateToProps, {...requestWorkflowActions})(NewRequest);