// SDK
import { MembershipService } from "@sdk-point/talisis";
import { log } from '../../../../../shared/utils/console.js'

const MEMBERSHIP = 3;
const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

export const getMembershipDetails = async(concepto) => {

    if(concepto[0]?.order_detail_type_id !== MEMBERSHIP) return

    const membershipName = concepto[0]?.membership_slug;
    try {
        const responseDetails = await membershipService.getMembershipDetails({ context: "checkout", membership: membershipName });
        return responseDetails;
    } catch (error) {
        log("getMembershipDetails Error", error)
    }
}
