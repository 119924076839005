import React from 'react';
import clsx from 'clsx';

// Material components
import { Avatar, Box, Button, makeStyles, Typography } from '@material-ui/core';

// Shared components
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import LogoSource from '../../../design-system/components/LogoSource/LogoSource';

// Utils
import {WHITE_COLOR} from '../../../design-system/constants/color-images';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

// Paths
import {MEMBRESIAS, ESCUELAS_OUS} from "../../../routes/paths";

// Style
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.colors.darkBlack[500],
        boxSizing: "border-box",
    },
    containerBorder: {
        border: `1px solid #FF0`,
        borderRadius: "8px",
        background: "url(/images/home/banner-hh.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        padding: "40px 80px",
        [theme.breakpoints.down('sm')]: {
            padding: "32px 16px",
            background: "unset",
            backgroundSize: "unset",
            backgroundRepeat: "unset",
            backgroundPosition: "unset",
        },
    },
    container: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        textAlign: "start",
        [theme.breakpoints.down('sm')]: {
            gap: "24px",
        },
    },
    talisisLogo: {
        width: "119.421px",
        height: "32px",
        [theme.breakpoints.down('sm')]: {
            width: "89px",
            height: "24px",
        },
    },
    hhLogo: {
        width: "224.797px",
        height: "32px",
        [theme.breakpoints.down('sm')]: {
            width: "168px",
            height: "24px",
        },
    },
    title: {
        color: theme.palette.colors.ou.hhYellow,
        textWrap: "wrap",
    },
    subtitle: {
        color: theme.palette.white,
        textWrap: "wrap",
    },
    avatarContent: {
        backgroundColor: theme.palette.colors.ou.hhYellow,
        width: "32px",
        height: "32px"
    },
    section1: {
        display: "flex",
        width: "100%",
        gap: "16px"
    },
    section2: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "16px",
        [theme.breakpoints.down('sm')]: {
            gap: "8px",
        },
    },
    section3: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "16px"
    },
    section4: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        gap: "16px",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            gap: "8px",
        },
    },
    textLargeContainer: {
        [theme.breakpoints.down('xl')]: {
            width: "60%"
        },
        [theme.breakpoints.down('lg')]: {
            width: "50%"
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    accessList: {
        display: "flex",
        flexDirection: "row",
        gap: "40px",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            gap: "8px",
        },
    },
    accessContainer: {
        display: "flex",
        gap: "8px",
        alignItems: "center"    
    }
}));

const BannerHH = () => {
    const classes = useStyles();
    const history = useHistory();

    // HANDLES
    const hanldeOnClick = (route) => {
        try {
            history.push(route);
        } catch(error) {}
    };
    
    return (
        <ResponsiveContainer className={clsx(classes.root)}>
            <Box className={classes.containerBorder}>
                <Box className={classes.container}>
                    <Box className={classes.section1}>
                        <LogoSource sourceId={7} color={WHITE_COLOR} className={classes.talisisLogo} disabledAutoRes/>
                        <Typography variant='h2'>+</Typography>
                        <LogoSource sourceId={10} color={WHITE_COLOR} className={classes.hhLogo} disabledAutoRes/>
                    </Box>
                    <Box className={classes.section2}>
                        <Box className={classes.textLargeContainer}>
                            <Typography variant='h2' className={clsx(classes.title)}>¡Ahora puedes aprender inglés 100% en línea con tu membresía Talisis!</Typography>
                        </Box>
                        <Box className={classes.textLargeContainer}>
                            <Typography variant='h4' className={classes.subtitle}>Aprende desde inglés básico hasta avanzado, cuando quieras y dónde quieras.</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.section3}> 
                        <Typography variant='h4' className="text-white">Tendrás acceso a:</Typography>
                        <Box>
                            <Box className={classes.accessList}>
                                <Box display="flex" flexDirection="column" gridGap="8px">
                                    <Box className={classes.accessContainer}>
                                        <Avatar className={classes.avatarContent}>
                                            <i className={clsx('ri-time-line', "font-size-16")}/>
                                        </Avatar>
                                        <Typography variant='body1' className='semi-bold'>Cursos 24/7</Typography>
                                    </Box>
                                    <Box className={classes.accessContainer}>
                                        <Avatar className={classes.avatarContent}>
                                            <i className={clsx('ri-funds-box-line', "font-size-16")}/>
                                        </Avatar>
                                        <Typography variant='body1' className='semi-bold'>Medición de avances</Typography>
                                    </Box>
                                    <Box className={classes.accessContainer}>
                                        <Avatar className={classes.avatarContent}>
                                            <i className={clsx('ri-smartphone-line', "font-size-16")}/>
                                        </Avatar>
                                        <Typography variant='body1' className='semi-bold'>App Móvil</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" gridGap="8px">
                                    <Box className={classes.accessContainer}>
                                        <Avatar className={classes.avatarContent}>
                                            <i className={clsx('ri-user-line', "font-size-16")}/>
                                        </Avatar>
                                        <Typography variant='body1' className='semi-bold'>Clases privadas 1 a 1</Typography>
                                    </Box>
                                    <Box className={classes.accessContainer}>
                                        <Avatar className={classes.avatarContent}>
                                            <i className={clsx('ri-group-line', "font-size-16")}/>
                                        </Avatar>
                                        <Typography variant='body1' className='semi-bold'>Salas de conversación grupales</Typography>
                                    </Box>
                                    <Box className={classes.accessContainer}>
                                        <Avatar className={classes.avatarContent}>
                                            <i className={clsx('ri-todo-line', "font-size-16")}/>
                                        </Avatar>
                                        <Typography variant='body1' className='semi-bold'>Examen de diagnóstico de inglés</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.section4}>
                        <Button variant='contained' color='primary' onClick={() => hanldeOnClick(MEMBRESIAS)}>Ver membresías</Button>
                        <Button color='primary' onClick={() => hanldeOnClick(ESCUELAS_OUS.replace(/:brand/i, 'harmonhall'))}>Ver más información</Button>
                    </Box>
                </Box>
            </Box>
        </ResponsiveContainer>
    );
};

export default BannerHH;