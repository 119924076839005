import React, { useState } from 'react'

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import ProfileInfo from '../ProfileInfo/ProfileInfo';
import DashboardSkills from '../DashboardSkills/DashboardSkills';
import AboutMe from '../AboutMe/AboutMe';
import ContactMe from '../ContactMe/ContactMe';
import JobAndEducation from '../JobAndEducation/JobAndEducation';
import MyProfileCard from './MyProfileCard/MyProfileCard';
import SocialInfo from '../SocialInfo/SocialInfo';

const FORM_NAME_AboutMe = 'about_me';
const FORM_NAME_ContactMe = 'contact_me';
const FORM_NAME_JobAndEducation = 'about_me';


    
const useStyles = makeStyles((theme) => ({
    rootGrid:{
        [theme.breakpoints.down('xs')]:{
            paddingBottom: '50px'
        }
    },
}));

const MyProfile = (props) => {
    
    const classes = useStyles();

    const { state, handleChangeTab, changeSubTab, handleChange,
            handleReadOnlyMode, handleInterestChange, handleSubmit,
            handleReadOnlyInterest, getProfile } = props;

    const [saveBtn, setsaveBtn] = useState(false);

    const cardSaveBtn = () => setsaveBtn(!saveBtn);

    return (
      <>
          <Grid container spacing={3} direction="row" className={classes.rootGrid}>
              <Grid item lg={4} md={4} sm={12} alignItems='center'>
                  <Grid>
                        <ProfileInfo {...state} onChangeMode={handleReadOnlyMode} onChangeInterestMode={handleReadOnlyInterest} handleInterestChange={handleInterestChange} onSubmit={handleSubmit} />
                  </Grid>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                  <Grid container spacing={3}>
                      {
                        state.isReadOnlyMode ?
                            // Componente Dashboard de Skills
                            <Grid item xs={12}>
                                <DashboardSkills handleChangeTab={handleChangeTab} changeSubTab={changeSubTab} />
                            </Grid>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Typography display="inline" variant='h4' color="secondary">
                                        /
                                    </Typography>
                                    <Typography display="inline" variant='h4'> Editar información</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='caption'>
                                        Esta información es confidencial y se usará únicamente con propósitos institucionales.
                                    </Typography>
                                </Grid>
                                <MyProfileCard
                                    cardTitle={"Editar Información Personal"}
                                    caption={"Es posible que otros usuarios puedan ver parte de la información al usar la plataforma"}
                                    btnText={"Guardar"}
                                    btnDisabled={saveBtn}
                                    loading={ state.about_me.loading }
                                    btnFunction={() => handleSubmit(FORM_NAME_AboutMe)}
                                    component={<AboutMe {...state} onChange={handleChange} onSubmit={handleSubmit} cardSaveBtn={cardSaveBtn} />}
                                />
                                <MyProfileCard
                                    cardTitle={"Editar Información de Contacto"}
                                    caption={"Es posible que otros usuarios puedan ver parte de la información al usar la plataforma"}
                                    btnText={"Guardar"}
                                    loading={ state.contact_me.loading }
                                    btnFunction={() => handleSubmit(FORM_NAME_ContactMe, 'contact')}
                                    component={<ContactMe {...state} onChange={handleChange} onSubmit={handleSubmit} />}
                                />
                                <MyProfileCard
                                    cardSize={6}
                                    cardTitle={"Enlaces a perfiles"}
                                    caption={"Gestiona tus cuentas"}
                                    btnText={"Guardar"}
                                    loading={ state.contact_me.loading }
                                    btnFunction={() => handleSubmit(FORM_NAME_ContactMe, 'socialNetworks')}
                                    component={<SocialInfo {...state} onChange={handleChange} onSubmit={handleSubmit} />}
                                    // componentHeight={"21em"}
                                />
                                <MyProfileCard
                                    cardSize={6}
                                    cardTitle={"Trabajo y Formación"}
                                    caption={"Compártenos tu trayectoria"}
                                    btnVisible={false}
                                    // btnText={"Guardar"}
                                    btnDisabled={true}
                                    loading={ state.contact_me.loading }
                                    btnFunction={() => handleSubmit(FORM_NAME_JobAndEducation)}
                                    component={<JobAndEducation {...state} onChange={handleChange} onSubmit={handleSubmit} cardSaveBtn={cardSaveBtn} afterSubmitProcess={getProfile} />}
                                    // componentHeight={"21em"}
                                />
                            </>
                      }
                  </Grid>
              </Grid>
          </Grid>
      </>
    )
}

export default MyProfile
