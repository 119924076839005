import React from 'react';
import { Route, useLocation } from 'react-router-dom';

// @redux
import { connect } from "react-redux";

import PropTypes from 'prop-types';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const RouteWithLayout = props => {
    const {isLogged, layout: Layout, component: Component, ...rest } = props;

    const { user } = props;
    let location = useLocation();
    const { setPathPageView, setIdentity, setContentType } = useTrackingCode();  

    setPathPageView(location.pathname);
    setIdentity(isLogged ? user.email : '');
    setContentType("site-page"); 

    return (
        <Route {...rest} 
            render={matchProps => (
                <Layout {...matchProps} {...rest}>
                    {Component && <Component {...matchProps} />}
                </Layout>
            )}
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

const mapStateToProps = ({userReducer}) => ({
	...userReducer,
});

export default connect(mapStateToProps, {})(RouteWithLayout);

//export default RouteWithLayout;
