import React from 'react';
import { Box, Button, Icon, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PHYSICAL_REJECTED_STATUS } from '../../../shared/constants/record-status';


const REJECTED_STATUS = 3;

const useStyles = makeStyles((theme) => ({
    comments:{
        '& .MuiIcon-root':{
            fontSize: '24px !important'
        }
    },
}));

const ReviewConfirmBttn = ({newStatus, rejectedComments, onChangeDocValues, onClickConfirm}) => {
    const classes = useStyles();

    return (
        <Box id="review-confirm-bttn-root" 
        display='flex' p="0px 32px 24px 64px" flexDirection='column' alignItems='flex-start' gridGap={20} className={classes.comments}>
                                                        
        {
            [PHYSICAL_REJECTED_STATUS, REJECTED_STATUS].includes(newStatus) &&
            <>
                <Button className='p-0 text-fill-stroke-100 disable-hover' endIcon={<Icon className='ri-arrow-drop-up-fill'/>} disableRipple>Comentarios</Button>
                <TextField
                    value={rejectedComments}
                    name="rejectedComments"
                    fullWidth
                    variant='outlined'
                    placeholder='Texto muestra de captura'
                    multiline
                    minRows={4}
                    onChange={onChangeDocValues}
                />
            </>
        }

        <Box width={1} textAlign='end'>
            <Button variant='outlined' color="primary"size='small' 
                endIcon={<Icon className='ri-arrow-right-line'/>} 
                disabled={[PHYSICAL_REJECTED_STATUS, REJECTED_STATUS].includes(newStatus) && !rejectedComments}
                onClick={onClickConfirm}
            >
                Confirmar
            </Button>
        </Box>
    </Box> 
    );
};

export default ReviewConfirmBttn;