import React from 'react'
import { Avatar, Button, MenuItem, TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// styles
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '../../../design-system/components/Form/Select/Select'
import { log } from '../../../shared/utils/console.js'

//SDK
import { GlobalService } from '@sdk-point/talisis'; 
const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    large: {
        width: 120,
        height: 120,
        marginBottom: 56
    },
    button:{
        padding: '16px 59px',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    terms:{
        ...theme.typography.v5,
        color: theme.palette.colors.fillStroke[100],
        maxWidth: 392,
        '& a':{
            fontWeight: 600
        }
    }
}));

export const OnCardPronoun = ({onSetOnCard, form, handleChange, userName, eventData, eventTracker}) => {
    const [listPronoun, setListPronoun] = React.useState([]);
    const classes = useStyles();

    const getCatPronoun = async () => {
        const response = await GlobalServiceSDK.getCatalog('PronounCat');
        return response;
    } 
      
    React.useEffect(() => {
        eventTracker('s_ob_pronombre',eventData, ['mixpanel']);
        //EL "IS CANCEL" PERMITE SABER CUANDO UN RESPONSE CON AWAIT LLEGA Y EL COMPONENTE YA ESTÁ DESMONTADO, 
        //EVITA LA ASIGNACIÓN DE DATA CON EL COMPONENTE DESMONTADO
        let isCanceled = true;
        getCatPronoun().then((data)=>{
            if(isCanceled){
                setListPronoun(data);  
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });

        return () => (isCanceled = false);
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt="103px">
            <Avatar alt="Uss" src="" className={classes.large} />
            <Typography variant="h3" color="primary" className="mb-4">¡Hola {userName}!</Typography>
            <Typography variant="h6" style={{marginBottom: 56}}>
            Permite que otros sepan cómo referirse a ti...
            </Typography>
            <Box width="100%" maxWidth={360} mb="56px">
                <Select
                    name="pronoun_id"
                    items={listPronoun.map(it => ({...it, label: it.name}))}
                    required
                    label="Como referirse a ti"
                    value={form.pronoun_id || ""}
                    onChange={handleChange}
                />
            </Box>
            <Button
                variant='contained' 
                color="primary" 
                disabled={!form.pronoun_id} 
                onClick={() => onSetOnCard(1)} 
                className={classes.button}>
                Siguiente
            </Button>
            <Typography className={classes.terms} align="center">
            Si continúas, aceptas los <Link color="primary" component={RouterLink} to="/terminos_condiciones">Términos y Condiciones</Link>
            &nbsp;de Talisis y confirmas que has leído nuestro&nbsp;<Link color="primary" component={RouterLink} to="/aviso_privacidad">Aviso de privacidad</Link>
            </Typography>
        </Box>
    )
}

export default OnCardPronoun;
