import React from 'react'

export const getMembershipIcon = (id) => {
    if (id === "lite") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 0L11.5577 4.4762C11.9999 5.74708 12.221 6.38253 12.7285 6.7511C13.236 7.11967 13.9087 7.13336 15.254 7.16075L20 7.25735L16.2209 10.1192C15.147 10.9326 14.61 11.3393 14.4159 11.9364C14.2218 12.5335 14.417 13.1781 14.8076 14.4675L16.1803 19L12.283 16.291C11.1789 15.5235 10.6268 15.1398 10 15.1398C9.37319 15.1398 8.82112 15.5235 7.71699 16.291L3.81966 19L5.19243 14.4675C5.58295 13.1781 5.77821 12.5335 5.58413 11.9364C5.39004 11.3393 4.85305 10.9326 3.77905 10.1192L0 7.25735L4.74595 7.16075C6.09131 7.13336 6.76399 7.11967 7.27148 6.7511C7.77897 6.38253 8.00009 5.74708 8.44234 4.4762L10 0Z" fill="url(#paint0_linear_638_161)" />
                <defs>
                    <linearGradient id="paint0_linear_638_161" x1="31.3031" y1="48.7228" x2="2.8953" y2="46.803" gradientUnits="userSpaceOnUse">
                        <stop offset="0.583333" stopColor="#FCDC43" />
                        <stop offset="0.895833" stopColor="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        )
    }

    if (id === "professional") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 0L10.8847 3.64386C11.18 4.85978 11.3276 5.46774 11.7968 5.6621C12.2661 5.85647 12.8003 5.53096 13.8689 4.87993L17.0711 2.92893L15.1201 6.13113C14.469 7.19967 14.1435 7.73394 14.3379 8.20318C14.5323 8.67243 15.1402 8.82004 16.3561 9.11527L20 10L16.3561 10.8847C15.1402 11.18 14.5323 11.3276 14.3379 11.7968C14.1435 12.2661 14.469 12.8003 15.1201 13.8689L17.0711 17.0711L13.8689 15.1201C12.8003 14.469 12.2661 14.1435 11.7968 14.3379C11.3276 14.5323 11.18 15.1402 10.8847 16.3561L10 20L9.11527 16.3561C8.82004 15.1402 8.67243 14.5323 8.20318 14.3379C7.73394 14.1435 7.19967 14.469 6.13113 15.1201L2.92893 17.0711L4.87993 13.8689C5.53096 12.8003 5.85647 12.2661 5.6621 11.7968C5.46774 11.3276 4.85978 11.18 3.64386 10.8847L0 10L3.64386 9.11527C4.85978 8.82004 5.46774 8.67243 5.6621 8.20318C5.85647 7.73394 5.53096 7.19967 4.87993 6.13113L2.92893 2.92893L6.13113 4.87993C7.19967 5.53096 7.73394 5.85647 8.20318 5.6621C8.67243 5.46774 8.82004 4.85978 9.11527 3.64386L10 0Z" fill="url(#paint0_linear_462_551)" />
                <defs>
                    <linearGradient id="paint0_linear_462_551" x1="31.3031" y1="51.2871" x2="2.8827" y2="49.4625" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0520833" stopColor="#FCDC43" />
                        <stop offset="1" stopColor="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    if (id === "premium") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 0L12.1194 5.56588C12.4337 6.39129 12.5908 6.804 12.8934 7.10659C13.196 7.40917 13.6087 7.56632 14.4341 7.88063L20 10L14.4341 12.1194C13.6087 12.4337 13.196 12.5908 12.8934 12.8934C12.5908 13.196 12.4337 13.6087 12.1194 14.4341L10 20L7.88063 14.4341C7.56633 13.6087 7.40917 13.196 7.10659 12.8934C6.804 12.5908 6.39129 12.4337 5.56588 12.1194L0 10L5.56588 7.88063C6.39129 7.56633 6.804 7.40917 7.10659 7.10659C7.40917 6.804 7.56632 6.39129 7.88063 5.56588L10 0Z" fill="url(#paint0_linear_462_142)" />
                <defs>
                    <linearGradient id="paint0_linear_462_142" x1="31.3031" y1="51.2871" x2="2.8827" y2="49.4625" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0520833" stopColor="#FCDC43" />
                        <stop offset="1" stopColor="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    return null;
}
