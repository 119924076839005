import React from 'react';
import { Table, TableBody, TableCell, TableRow, Typography, Tooltip, Box, IconButton } from '@material-ui/core';
import { Check, Close, LinkOff, Autorenew } from '@material-ui/icons';

const UserFixClassesNeo = (props) => {
    const { data: userData, classes, onClick } = props;
    return (
        <React.Fragment>
            {userData.map((data, index) => {
                const hasEduconCourses = data.courses_educon && data.courses_educon.courses && data.courses_educon.courses.length > 0 ? true : false;
                const hasPrograms = data.programs && data.programs.programs && data.programs.programs.length > 0 ? true : false;
                if (hasEduconCourses || hasPrograms) {
                    return (
                        <fieldset className={`mb-3 ${classes.fieldset}`} key={`fs_fix_user_data_${data.id}`}>
                            <legend>{data.id}</legend>
                            {hasEduconCourses && data.courses_educon.courses.map((educonCourses) => (
                                <Table key={`tab_table_courses_${educonCourses.id}`} size="small" className="mb-4">
                                    <TableBody key={`tab_tbody_courses_${educonCourses.id}`}>
                                        <TableRow>
                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} colSpan={3}>
                                                <Typography variant="subtitle1"><b>{educonCourses.name}</b></Typography>
                                                <hr />
                                            </TableCell>
                                        </TableRow>
                                        {educonCourses.courses.length > 0 ?
                                            educonCourses.courses.filter((course) => course.source_id === 1 && course.modality_id === 2).length > 0 ?
                                                <React.Fragment>
                                                    <TableRow>
                                                        <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'50%'}>&nbsp;</TableCell>
                                                        <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">Talisis</TableCell>
                                                        <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">NEO</TableCell>
                                                    </TableRow>
                                                    {educonCourses.courses.map(educonCourse => educonCourse.source_id === 1 && (
                                                        <TableRow key={`tr_educon_course_${educonCourse.course_id}`}>
                                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'50%'}>
                                                                <Typography variant="body2" className="mt-1">{educonCourse.name}</Typography>
                                                            </TableCell>
                                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center"><Check className={classes.green} style={{ width: '0.75em' }} /></TableCell>
                                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">
                                                                {!educonCourse.course_url_neo ?
                                                                    <Tooltip title="Curso sin configuración de URL"><LinkOff className={classes.red} style={{ width: '0.75em' }} /></Tooltip>
                                                                    :
                                                                    data.neoCourses && data.neoCourses.length > 0 && data.neoCourses[0] && data.neoCourses[0][educonCourse.company_id] && data.neoCourses[0][educonCourse.company_id].length > 0 ?
                                                                        <React.Fragment>
                                                                            {(() => {
                                                                                let result = null;
                                                                                for (let neoCourse of data.neoCourses[0][educonCourse.company_id]) {
                                                                                    if (educonCourse.course_url_neo.includes(neoCourse.id)) {
                                                                                        result = <Check key={`neo_course_educon_check_${neoCourse.id}`} className={classes.green} style={{ width: '0.75em' }} />;
                                                                                        break;
                                                                                    }
                                                                                    else {
                                                                                        result = (
                                                                                            <Box key={`neo_course_educon_error_${neoCourse.id}`} className={`${classes.displayInlineFlex} ${classes.alignItemsCenter}`}>
                                                                                                <Close className={classes.red} style={{ width: '0.75em' }} />
                                                                                                <IconButton title="Clic para reparar" onClick={() => onClick(index, data, educonCourse, 'EC')} className="ml-2" style={{ position: 'absolute' }}><Autorenew color="primary" /></IconButton>
                                                                                            </Box>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                return result;
                                                                            })()}
                                                                        </React.Fragment>
                                                                        :
                                                                        <Box key={`neo_course_educon_not_exists_${educonCourse.course_id}`} className={`${classes.displayInlineFlex} ${classes.alignItemsCenter}`}>
                                                                            <Close className={classes.red} style={{ width: '0.75em' }} />
                                                                            <IconButton title="Clic para reparar" onClick={() => onClick(index, data, educonCourse, 'EC')} className="ml-2" style={{ position: 'absolute' }}><Autorenew color="primary" /></IconButton>
                                                                        </Box>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </React.Fragment>
                                                :
                                                <TableRow>
                                                    <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'100%'}>
                                                        <Typography component="div" color="primary">Sin información para mostrar.</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            :
                                            <TableRow>
                                                <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'100%'}>
                                                    <Typography component="div" color="primary">Sin información para mostrar.</Typography>
                                                </TableCell>
                                            </TableRow>}
                                    </TableBody>
                                </Table>
                            ))}
                            {hasPrograms && data.programs.programs.map(program => program.learning_style === 'asincronico' && (
                                <Table key={`table_fix_program_${program.id}`} size="small" className="mb-4">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={`${classes.borderNone} ${classes.valignTop}`} colSpan={3}>
                                                <Typography component="div" variant="overline" className={"overline-small text-gray-500"}>Programa inscrito:</Typography>
                                                <Typography component="div" variant="subtitle1"><b>{program.program_name}</b></Typography>
                                                <hr />
                                            </TableCell>
                                        </TableRow>
                                        {program.courses.length > 0 ?
                                            <React.Fragment>
                                                <TableRow>
                                                    <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'50%'}>&nbsp;</TableCell>
                                                    <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">Talisis</TableCell>
                                                    <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">NEO</TableCell>
                                                </TableRow>
                                                {program.courses.map(programCourse => (
                                                    <TableRow key={`tr_program_course_${programCourse.course_id}`}>
                                                        <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'50%'}>
                                                            <Typography variant="body2" className="mt-1">{programCourse.course_name}</Typography>
                                                        </TableCell>
                                                        <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center"><Check className={classes.green} style={{ width: '0.75em' }} /></TableCell>
                                                        <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'25%'} align="center">
                                                            {!programCourse.courses_url_neo ?
                                                                <Tooltip title="Curso sin configuración de URL"><LinkOff className={classes.red} style={{ width: '0.75em' }} /></Tooltip>
                                                                :
                                                                data.neoCourses && data.neoCourses.length > 0 && data.neoCourses[0] && data.neoCourses[0][programCourse.company_id] && data.neoCourses[0][programCourse.company_id].length > 0 ?
                                                                    <React.Fragment>
                                                                        {(() => {
                                                                            let result = null;
                                                                            for (let neoCourse of data.neoCourses[0][programCourse.company_id]) {
                                                                                if (programCourse.courses_url_neo.includes(neoCourse.id)) {
                                                                                    result = <Check key={`neo_course_program_check_${neoCourse.id}`} className={classes.green} style={{ width: '0.75em' }} />;
                                                                                    break;
                                                                                }
                                                                                else {
                                                                                    result = (
                                                                                        <Box key={`neo_course_program_error_${neoCourse.id}`} className={`${classes.displayInlineFlex} ${classes.alignItemsCenter}`}>
                                                                                            <Close className={classes.red} style={{ width: '0.75em' }} />
                                                                                            <IconButton title="Clic para reparar" onClick={async () => await onClick(index, data, programCourse, programCourse.level_id)} className="ml-2" style={{ position: 'absolute' }}><Autorenew color="primary" /></IconButton>
                                                                                        </Box>
                                                                                    );
                                                                                }
                                                                            }
                                                                            return result;
                                                                        })()}
                                                                    </React.Fragment>
                                                                    :
                                                                    <Box key={`neo_course_program_not_exists_${programCourse.course_id}`} className={`${classes.displayInlineFlex} ${classes.alignItemsCenter}`}>
                                                                        <Close className={classes.red} style={{ width: '0.75em' }} />
                                                                        <IconButton title="Clic para reparar" onClick={() => onClick(index, data, programCourse, programCourse.level_id)} className="ml-2" style={{ position: 'absolute' }}><Autorenew color="primary" /></IconButton>
                                                                    </Box>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                            :
                                            <TableRow>
                                                <TableCell className={`${classes.borderNone} ${classes.valignTop}`} width={'100%'}>
                                                    <Typography component="div" color="primary">Sin información para mostrar.</Typography>
                                                </TableCell>
                                            </TableRow>}
                                    </TableBody>
                                </Table>
                            ))}
                        </fieldset>
                    )
                }
            })}
        </React.Fragment>
    )
};

export default UserFixClassesNeo;