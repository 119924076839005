const UERRE = 'u-erre';
const UNID = 'unid';
const HARMON_HALL = 'harmonhall';
const PATH = 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page';

export const getBrandLandingPagesLogo = (brand) => {
    const logo = `${PATH}/${brand}/logo.svg`;
    return logo ? logo : null;
};

export const getBrandLandingPagesWebsiteLinkImg = (brand) => {
    const webSiteLinkImg = `${PATH}/${brand}/go-to-website.svg`;
    return webSiteLinkImg ? webSiteLinkImg : null;
};

export const getBrandLandingPagesProgramCardImg = (brand) => {
    const images = {
        [UERRE]: {
            'MBA_1': `${PATH}/u-erre/programs/mba_1.svg`,
            'MBA_2': `${PATH}/u-erre/programs/mba_2.svg`,
            'MBA_3': `${PATH}/u-erre/programs/mba_3.svg`,
            'background': {
                'MBA_1': `${PATH}/u-erre/programs/bg_mba_1.svg`,
                'MBA_2': `${PATH}/u-erre/programs/bg_mba_2.svg`,
                'MBA_3': `${PATH}/u-erre/programs/bg_mba_3.svg`,
            }
        },
        [UNID]: {
            'BACHILLERATO': `${PATH}/unid/programs/bachillerato.svg`,
            'LICENCIATURA': `${PATH}/unid/programs/licenciatura.svg`,
            'MAESTRIA': `${PATH}/unid/programs/maestria.svg`,
            'EDUCACION_CONTINUA': `${PATH}/unid/programs/educacion-continua.svg`,
            'background': {
                'BACHILLERATO': `${PATH}/unid/programs/bg_bachillerato.svg`,
                'LICENCIATURA': `${PATH}/unid/programs/bg_licenciatura.svg`,
                'MAESTRIA': `${PATH}/unid/programs/bg_maestria.svg`,
                'EDUCACION_CONTINUA': `${PATH}/unid/programs/bg_educacion-continua.svg`,
            }
        },
        [HARMON_HALL]: {
            'ONLINE-STANDARD': `${PATH}/harmonhall/programs/online-standard.svg`,
            'ONLINE-PLUS': `${PATH}/harmonhall/programs/online-plus.svg`,
            'ONLINE-ELITE': `${PATH}/harmonhall/programs/online-elite.svg`,
        }
    };

    return images[brand];
};

export const getBrandLandingPagesSlideImgs = (brand) => {
    const listByBrands = {
        [UERRE]: [
            `${PATH}/u-erre/slide/image_1.svg`,
            `${PATH}/u-erre/slide/image_2.svg`,
        ],
        [UNID]: [
            `${PATH}/unid/slide/image_1.svg`,
            `${PATH}/unid/slide/image_2.svg`,
            `${PATH}/unid/slide/image_3.svg`,
        ],
        [HARMON_HALL]: [
            `${PATH}/harmonhall/slide/image_1.svg`,
        ],
    };

    return listByBrands[brand] ? listByBrands[brand] : null;
};

export const getBrandLandingPagesSlideImgsInfo = (brand) => {
    const listByBrands = {
        [UERRE]: [],
        [UNID]: [
            { image: 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/unid/slide/info_image_1.svg', link: 'https://www.unid.edu.mx/?page_id=12211' },
            { image: 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/unid/slide/info_image_2.svg', link: '' },
            { image: 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/unid/slide/info_image_3.svg', link: 'https://www.unid.edu.mx/?page_id=15677' },
        ],
        [HARMON_HALL]: [],
    };

    return listByBrands[brand] ? listByBrands[brand] : null;
};

export const getBrandLandingPagesVideo = (brand) => {
    const listByBrands = {
        [UERRE]: 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/u-erre/video/video_1.mp4',
        [UNID]: 'https://point-files.s3.us-west-2.amazonaws.com/market-place/brands-landing-pages/landing-page/unid/video/video_1.mp4',
        [HARMON_HALL]: '',
    };

    return listByBrands[brand] ? listByBrands[brand] : null;
};

export const PROGRAM_CARD_IMAGES = {
    [UERRE]: {
        'MBA_1': `${PATH}/u-erre/programs/mba_1.svg`,
        'MBA_2': `${PATH}/u-erre/programs/mba_2.svg`,
        'MBA_3': `${PATH}/u-erre/programs/mba_3.svg`,
        'background': {
            'MBA_1': `${PATH}/u-erre/programs/bg_mba_1.svg`,
            'MBA_2': `${PATH}/u-erre/programs/bg_mba_2.svg`,
            'MBA_3': `${PATH}/u-erre/programs/bg_mba_3.svg`,
        }
    },
    [UNID]: {
        'BACHILLERATO': `${PATH}/unid/programs/bachillerato.svg`,
        'LICENCIATURA': `${PATH}/unid/programs/licenciatura.svg`,
        'MAESTRIA': `${PATH}/unid/programs/maestria.svg`,
        'EDUCACION_CONTINUA': `${PATH}/unid/programs/educacion-continua.svg`,
        'background': {
            'BACHILLERATO': `${PATH}/unid/programs/bg_bachillerato.svg`,
            'LICENCIATURA': `${PATH}/unid/programs/bg_licenciatura.svg`,
            'MAESTRIA': `${PATH}/unid/programs/bg_maestria.svg`,
            'EDUCACION_CONTINUA': `${PATH}/unid/programs/bg_educacion-continua.svg`,
        }
    },
    [HARMON_HALL]: {
        'ONLINE-STANDARD': `${PATH}/harmonhall/programs/online-standard.svg`,
        'ONLINE-PLUS': `${PATH}/harmonhall/programs/online-plus.svg`,
        'ONLINE-ELITE': `${PATH}/harmonhall/programs/online-elite.svg`,
    }
};