import React from 'react';

// redux
import { useSelector } from 'react-redux';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

// core components
import HarmonHallCard from '../HarmonHallCard/HarmonHallCard';

const HarmonHallCarousel = ({ onCardClick }) => {
    const { addons, selectedAddon } = useSelector((state) => state.cartReducer);

    return (
        <Swiper
            spaceBetween={8}
            slidesPerView="auto"
            loop
            style={{ width: "100%" }}
        >
            {addons.map((product, index) => {
                return <SwiperSlide key={index} style={{ width: "auto" }}>
                    <HarmonHallCard
                        product={product}
                        onCardClick={onCardClick}
                        selectedAddonId={selectedAddon} />
                </SwiperSlide>
            })}
        </Swiper>
    );
};

export default HarmonHallCarousel;