import React from 'react';
import { useSelector } from 'react-redux';

import { AllysService } from "@sdk-point/talisis";

// @shared/components
import { SOURCES } from '../../../../shared/constants/sources';
import getConnectionAllies from '../../../../shared/components/Allies/AlliesConnect';
import Toast from '../../../../shared/components/Toast/Toast';
import { log } from '../../../../shared/utils/console.js'

// @material-ui
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { CoursesTaughtDetailCSSClasses } from '../CoursesTaughtCSSClasses/CoursesTaughtCSSClasses';

const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const LOGOS = {
    'UERRE': 'https://point-files.s3.us-west-2.amazonaws.com/files/U-ERRE.svg',
    'UNID': 'https://point-files.s3.us-west-2.amazonaws.com/files/UNID.svg',
};

const useStyles = CoursesTaughtDetailCSSClasses();

const CoursesTaughtDetail = (props) => {
    const { user } = useSelector((state) => state.userReducer);
    const [loginMessage, setLoginMessage] = React.useState('');
    const classes = useStyles();

    const handleClickRedirect = async (e, LMSClassId, courseData) => {
        e.preventDefault();
        await AllysServiceSDK.validateNeoUser({ studentid: user?.email, ou: courseData?.course_company_id })
            .then(async neoUser => {
                if (neoUser.neo_id) {
                    let URL = '';
                    if (courseData?.course_company_id === 'UNID') {
                        URL = 'https://unid.neolms.com';
                    }
                    else if (courseData?.course_company_id === 'UERRE') {
                        URL = 'https://talisis.neolms.com';
                    }
                    URL = `${URL}/teacher_lessons/list/${LMSClassId}`;

                    const body = {
                        person_id: user?.person_id,
                        email: user?.email,
                        ou: courseData?.course_company_id,
                        source_id: 4,
                        company_id: courseData?.course_company_id,
                        course_id: courseData?.course_cat_id,
                    };
                    const newWindow = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + SOURCES[courseData?.course_company_id], "_blank");
                    await getConnectionAllies(body)
                        .then(responseURL => {
                            setTimeout(() => {
                                newWindow.focus();
                                const teacherURL = `${responseURL}?from=/teacher_lessons/list/${LMSClassId}`
                                newWindow.location.replace(teacherURL);
                            }, 3000);
                            
                            /* setTimeout(() => {
                                newWindow.focus();
                                newWindow.location.replace(URL);
                                console.log(URL)
                            }, 3000); */
                        })
                        .catch(error => log(error));
                }
            })
            .catch(error => {
                setLoginMessage('No se pudo establecer el inicio de sesión en NEO');
            });
    };

    const getDetail = () => {
        let detail = [];
        for (let i = 0; i < props?.data?.detail.length; i++) {
            const courseURL = props?.data?.detail[i]['course_session_url'];
            detail.push(
                <Grid key={`grid_item_detail_${props?.data?.detail[i]['course_cat_id']}_${i}`} item xs={12} sm={6} md={4} lg={3}>
                    <Tooltip title={
                        <Typography variant="caption">{props?.data?.detail[i]['course_session_url'] && user?.email ? 'Click para ver detalle de clase en NEO' : 'Link de clase no disponible'}</Typography>
                    } arrow>
                        <Card className={`${classes.root} ${(courseURL && user?.email) ? classes.cursorPointer : classes.cursorNotAllowed}`} onClick={(e) => {
                            if (courseURL && user?.email) {
                                const courseURLParts = courseURL.split('/');
                                const LMSClassId = courseURLParts[courseURLParts.length - 1];
                                handleClickRedirect(e, LMSClassId, props?.data?.detail[i]);
                            }
                        }}>
                            <CardHeader className={classes.header} title={<Typography variant="h4" className={classes.label}><i className="ri-team-line mr-1"></i>{`CRN: ${props?.data?.detail[i]['crn']}`}</Typography>} />
                            <CardContent>
                                <Typography component="div" variant="caption" className={`${classes.period} font-weight-500 text-blue-300 mb-2`}><i className="ri-book-2-line mr-1 icon"></i>{`Período: ${props?.data?.detail[i]['termcode_id']}`}</Typography>
                                <Typography component="div" variant="caption" className={`${classes.period} font-weight-500 text-blue-300 mb-2`}><i className="ri-book-mark-line mr-1 icon"></i>{`Sede: ${props?.data?.detail[i]['campus_name'] ?? ''}`}</Typography>
                            </CardContent>
                        </Card>
                    </Tooltip>
                </Grid>
            );
        }
        return detail;
    };

    return (
        <React.Fragment>
            <Typography component="div" variant="caption" color="secondary" className={classes.back} onClick={() => props?.setForm('')}>
                <IconButton size="medium"><KeyboardArrowLeft color="secondary" /></IconButton>Volver a Mis Materias
            </Typography>
            <Box className={`${classes.box} mb-4`}>
                <Box className={classes.headerContainer}>
                    <img src={props?.data?.image_url} className="mr-2" style={{ width: '9%' }} />
                    <Typography component="div" style={{ width: '100%' }}>
                        <Typography variant="h2">{props?.data?.detail[0]['course_name']}<img src={LOGOS[props?.data?.company_id]} className={classes.logoOU} /></Typography>
                        <Typography component="span" variant="subtitle1">{props?.data?.periodName}</Typography>
                        <Typography component="span" variant="subtitle1" className="ml-4 text-blue-300">{props?.data?.term_type_description}</Typography>
                    </Typography>
                </Box>
            </Box>
            <Typography variant="h3" className="mb-4">{`Grupos (${props?.data?.detail.length})`}</Typography>
            <Grid container spacing={3} className={classes.detailContent}>
                {getDetail()}
            </Grid>
            <Toast severity="error" open={loginMessage ? true : false} message={loginMessage} onFinished={() => {
                setLoginMessage('');
            }} />
        </React.Fragment>
    )
};

export default CoursesTaughtDetail;
