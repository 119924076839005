import { makeStyles } from '@material-ui/core/styles';

const border = 'rgba(119, 119, 119, 0.1)';

export default makeStyles((theme) => ({
    accordion: {
        boxShadow: 'unset',
        background: 'transparent',
        '& .MuiButtonBase-root':{
            padding: 0,
            minHeight: 'unset'
        },
        '& .MuiAccordionDetails-root':{
            padding: 0
        },
        '& .MuiAccordionSummary-content':{
            margin: 0,
            '&.Mui-expanded':{
                marginBottom: 8
            }
        }
    },
    timeline:{
        maxHeight: 'calc(100vh - 500px)',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
            backgroundColor: "#e4e4e4"
          },
          "&::-webkit-scrollbar-thumb": {
                borderRadius: "100px",
                background: "#77777747",
                "&:hover":{
                    background: "#b2adad"
                }
          },
          "&::-webkit-scrollbar-track": {
              borderRadius: "8px"
          }
    },
    divider:{
        backgroundColor: border,
        marginTop: 19
    },
    showDetailBttn:{
        color: theme.palette.colors.orange[300],
        ...theme.typography.v8,
        marginTop: 24,
        textTransform: 'none',
        "& span": {
            marginLeft: 0
        }
    },
    checkIcon:{
        color: theme.palette.colors.electricGreen[400],
        fontSize: 13,
        marginRight: 5
    },
    typoFol:{
        color: theme.palette.colors.fillStroke[100],
        ...theme.typography.v11,
    }
}));