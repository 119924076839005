import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    cardItemRoot: {
        padding: '20px 28px',
        width: '100%',
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        display: 'flex',
        borderRadius: '8px',
        backgroundColor: theme.palette.colors.fillStroke[500],
        boxSizing: 'border-box',
        color: theme.palette.colors.darkBlack[200],
        alignItems: 'center',
        height: '80px',
    },
    textContainer: {
        paddingLeft: '10px',
    },
    selectedCard: {
        backgroundColor: theme.palette.colors.fillStroke[400],
        color: 'white',
    },
    labelButton: {
        color: 'inherit',
    },
}));