import React from 'react';
import PropTypes from 'prop-types'

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
// styles
import useStyles from '../../../../assets/styles/jss/components/courseRaitingStyle'


const CourseRating = ({value, totalReview, onChange}) => {
    const classes = useStyles();
    const [chipValue, setChipValue] = React.useState(0);
    React.useEffect(() =>{
        if(value != null){
            setChipValue(value);
        }
    }, [value]);
    return (
        <Box>
            {totalReview > 0 &&
                <Box display="flex" alignItems="center">
                <Chip label={chipValue} className={classes.label}/>
                <Box className={classes.rating_contains}>
                    <Rating 
                        className={classes.rating}
                        name="simple-controlled" 
                        value={chipValue} 
                        onChange={(event, newValue) => {onChange(newValue)}}
                        precision={0.5}
                        emptyIcon={<StarBorderIcon className={classes.emptyStar} />}
                    />
                    <Typography component="span" className={classes.totalReview}>({totalReview})</Typography>
                </Box>
            </Box>}
        </Box>
        
    );
};

CourseRating.defaultProps = {
    value: 0,
    totalReview: 0
};

CourseRating.propTypes = {
    value: PropTypes.any,
    totalReview: PropTypes.number,
    onChangeRating: PropTypes.func
};

export default CourseRating;