import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { readFileSync } from "fs";
// redux
import { connect } from "react-redux";

// @material-ui/core
import { makeStyles, withStyles, createMuiTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


import { DataGrid, GridColDef, GridApi, GridCellValue } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/CloseRounded';
import FileSelector from './FileSelector';
import readXlsxFile from 'read-excel-file';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// shared components
import Loader from '../../../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../../../shared/components/Breadcrumb/Breadcrumb';
import InputFile from '../../../../../shared/components/Form/InputFile';
import Toast from '../../../../../shared/components/Toast/Toast';
import { log } from '../../../../../shared/utils/console.js'

// services
import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';

// import * as marketService from '../../../../../services/MarketPlaceService';
import * as coursesService from '../../../../../services/CoursesService';

import colors from '../../../../../design-system/theme/palette';

//SDK
import { GlobalService } from '@sdk-point/talisis'; 

const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const ActionButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.contrastText,
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
        minWidth: '175px',
        textTransform: 'none', border: "2px solid " + theme.palette.primary.main,
        flexGrow: 1,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
}))(Button);

const ActionButtonInvert = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.contrastText,
        minWidth: '175px',
        flexGrow: 1,
        textTransform: 'none', border: "2px solid " + theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
}))(Button);


const useStyles = makeStyles((theme) => ({
    mainPOP:{
        position: "fixed",
        width: "900px",
        height: "435px",
        padding: "20px",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
        background: theme.palette.dark.dark,
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        border: "2px solid " + theme.palette.primary.main,
    },
    close:{
        position:"absolute",
        right: "-18px",
        top: "-18px",
        fontSize: "50px"
    },
    mainTable:{
        width: "100%",
        height: "100%"
    },
    titlesTable:{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        overflowX: "scroll"
    },
    title:{
        marginLeft: "5px",
        marginRight: "5px"
    },
    buttonsContainer:{
        marginTop: "auto",
        justifyContent: "flex-end"
    },
    loader:{
        top: "0",
        left: "0",
        pointerEvents: "none",
        height: "100%",
        width: "100%",
        position: "absolute"
    },
    dataGrid:{
        '& .super_header': {
            backgroundColor: colors.primary.main,
        },
    }
}));

const AgregarCursos = (props) => {
    const {handleClickclosePOP, user}  = props;
    const classes = useStyles();
    const [file, setFile] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [rowsToSend, setRowsToSend] = React.useState([]);
    const [titles, setTitles] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [loadMessage, setLoadMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [uploaded, setUploaded] = React.useState(false);
    const [actualGrupId, setActualGrupId] = React.useState(null);
    const [toastProps, setToastProps] = React.useState({
        message: "",
        open: false
    });
    const [errors, setErrors] = React.useState({});
    const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const handleChargeExcel = (e) => {
        let { value } = e.target;
        setFile(value);
        loadXlsxFile(value);
    };

    const changeFileCardRow = (id, value, name) => {
        log("TARGET");
        log(value.target.value);
        setRows((prevRows) =>{
            log("CALLBACK");
            return prevRows.map((row) =>
                row.id === id ? { ...row, [name]: value.target.value } : row,
            );
        });
        log("ROWS AFTER");
        log(rows);
    }


    const handleChecked = (e, id, value) => {
        const { name } = e.target;
        value = value == 1 ? true : false;
        log(!value);
        setRows((prevRows) =>{
            return prevRows.map((row) =>
                row.id === id ? { ...row, [name]: !value} : row,
            );
        });
    };

    const columsData = React.useMemo(
        () => [
            {   
                field: 'id',
                headerName: 'ID',
                width: 0
            },
            {
                field: 'name',
                headerName: 'Nombre',
                width: 200
            },
            {
                field: 'is_active',
                headerName: 'Activo',
                width: 200,
                renderCell: (params) => {
                    return <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                name="is_active"
                                size="medium"
                                checked={params.value != 1 ? false : true}
                                onChange={(e) => handleChecked(e, params.id, params.value)}
                                color="primary"
                                inputProps={{ 'aria-label': 'Activo' }}
                            />
                        }
                        label="Activo"
                    />
                </FormGroup>;
                },
            },
            {
                field: 'file_card',
                headerName: 'Card',
                width: 250,
                renderCell: (params) => {
                    return <InputFile
                    accept="image"
                    placeholder="Agrega una imagen"
                    btnText="Card"
                    name="file_card"
                    value={params.value}
                    onChange={(e) => changeFileCardRow(params.id, e, "file_card")} />;
                
                }, 
            },
            {
                field: 'file_landing',
                headerName: 'Landing',
                width: 280,
                renderCell: (params) => {
                    return <InputFile
                    accept="image"
                    placeholder="Agrega una imagen"
                    btnText="Landing"
                    name="file_landing"
                    value={params.value}
                    onChange={(e) => changeFileCardRow(params.id, e, "file_landing")} />;
                },
            },
            {
                field: 'file_curse',
                headerName: 'Curso',
                width: 250,
                renderCell: (params) => {
                    return params.row.plataform == "POINT" && <InputFile
                    accept="zip"
                    placeholder="Agrega una curso"
                    btnText="Curso"
                    name="file_curse"
                    value={params.value}
                    onChange={(e) => changeFileCardRow(params.id, e, "file_curse")} />;
                },
            },
            {
                field: 'period',
                headerName: 'Periodo',
                editable: true,
                width: 150
            },
            {
                field: 'crn',
                headerName: 'CRN',
                width: 150
            },
            {
                field: 'materia',
                headerName: 'Materia',
                width: 150
            },
            {
                field: 'curso',
                headerName: 'Curso',
                width: 180
            },
            {
                field: 'secuencia',
                headerName: 'Secuencia',
                width: 200
            },
            {
                field: 'codigo',
                headerName: 'Código',
                width: 150
            },
            {
                field: 'programa',
                headerName: 'Programa',
                width: 150
            },
            {
                field: 'company_id',
                headerName: 'Marca',
                width: 150
            },
            {
                field: 'description',
                headerName: 'Descripción',
                width: 200
            },
            {
                field: 'objetive',
                headerName: 'Objetivo',
                width: 150
            },
            {
                field: 'audience',
                headerName: 'Dirigido a (lista)',
                width: 200
            },
            {
                field: 'difficulty_level_id',
                headerName: 'Nivel del programa',
                width: 200
            },
            {
                field: 'herramientas',
                headerName: 'Herramientas (lista)',
                width: 200
            },
            {
                field: 'beneficios',
                headerName: 'Beneficios (lista)',
                width: 200
            },
            {
                field: 'habilidades',
                headerName: 'Habilidades (lista)',
                width: 200
            },
            {
                field: 'modulos',
                headerName: 'Módulo y/o temas (lista)',
                width: 250
            },
            {
                field: 'requisitos',
                headerName: 'Requisitos (lista)',
                width: 250
            },
            {
                field: 'ventajas_competitivas',
                headerName: 'Ventajas competitivas (lista)',
                width: 250
            },
            {
                field: 'duration',
                headerName: 'Duración',
                width: 150
            },
            {
                field: 'frequency',
                headerName: 'Frecuencia',
                width: 150
            },
            {
                field: 'schedule',
                headerName: 'Horario',
                width: 150
            },
            {
                field: 'correo_maestro',
                headerName: 'Correo del maestro',
                width: 250
            },
            {
                field: 'nombre_maestro',
                headerName: 'Nombre del maestro',
                width: 250
            },
            {
                field: 'apellidos_maestro',
                headerName: 'Apellidos del maestro',
                width: 250
            },
            {
                field: 'modality_id',
                headerName: 'Modalidad',
                width: 150
            },
            {
                field: 'plataform',
                headerName: 'Plataforma',
                width: 150
            },
            {
                field: 'fecha_inicio',
                headerName: 'Fecha inicio',
                width: 150
            },
            {
                field: 'fecha_fin',
                headerName: 'Fecha fin',
                width: 150
            },
            {
                field: 'learning_style_id',
                headerName: 'Tipo de enseñanza',
                width: 200
            },
            {
                field: 'lang',
                headerName: 'Idioma',
                width: 200
            },
            {
                field: 'price',
                headerName: 'Precio',
                width: 200
            },
            {
                field: 'source_id',
                headerName: 'Recurso',
                width: 200
            },
        ],
        [changeFileCardRow],
    );

    const loadXlsxFile = (file) => {
        //log("File reacive");
        //log(file);
        /*const buffer = readFileSync(file);
        log("Buffer load");
        log(buffer);*/
        //log("ReadXlsxFile");
        readXlsxFile(file).then((data) => {
            //log(data);
            //log("ROWS SAVED");
            //log(data[0]);
            //let titles = [];
            let rowsOb = [];
            data.map((datas, i) => {
                log(datas);
                if(i > 0){
                    rowsOb.push({ 
                        id: i, 
                        period: datas[0], 
                        crn: datas[1],
                        materia: datas[2], 
                        curso: datas[3], 
                        secuencia: datas[4], 
                        codigo: datas[5], 
                        programa: datas[6], 
                        company_id: datas[7], 
                        name: datas[8], 
                        description: datas[9],
                        objetive: datas[10], 
                        audience: datas[11] && datas[11].split('/'), 
                        difficulty_level_id: datas[12], 
                        herramientas: datas[13] && datas[13].split('/'), 
                        beneficios: datas[14] && datas[14].split('/'), 
                        habilidades: datas[15] && datas[15].split('/'), 
                        modulos: datas[16] && datas[16].split('/'), 
                        requisitos: datas[17] && datas[17].split('/'), 
                        ventajas_competitivas: datas[18] && datas[18].split('/'),
                        duration: datas[19], 
                        frequency: datas[20],
                        schedule: datas[21],
                        correo_maestro: datas[22],
                        nombre_maestro: datas[23],
                        apellidos_maestro: datas[24],
                        modality_id: datas[25],
                        plataform: datas[26],
                        fecha_inicio: datas[27],
                        fecha_fin: datas[28],
                        is_active: datas[29],
                        learning_style_id: datas[30],
                        lang: datas[31],
                        price: parseFloat(datas[32] || "0.00").toFixed(2),
                        source_id: datas[34],
                        file_card: datas[35],
                        file_landing: datas[36],
                        
                    });
                }
            });


            log("ROWS");
            log(rowsOb);
            setRows(rowsOb);
            log("COLUMNS");
            log(columsData);
            setTitles(rowsOb);
            setColumns(columsData);
            
        });

        
    }

    const resetFiles = (index) =>{
        rowsToSend[index].file_curse = "";
        rowsToSend[index].file_landing = "";
        rowsToSend[index].file_card = "";
    }

    const validate = (file_card, file_landing, file_course, nombre, company_id, source_id, plataform, index, size) => {
        let _errors = false;
        if (!nombre) {
            _errors = true;
        }
        if (!company_id) {
            _errors = true;
        }
        if (!source_id) {
            _errors = true;
        }
        if (!plataform) {
            _errors = true;
        }
        if (!file_card) {
            _errors = true;
        }
        if (!file_landing) {
            _errors = true;
        }
        if(plataform == "POINT"){
            if (!file_course) {
                _errors = true;
            }
        }   
        if(_errors == true){
            setToastProps({ severity: "error", open: true, message: `Error en los datos del curso.: ${nombre} ${index+1}/${size}` });
        }
        return !_errors;
    };

    const checkUploadedCourseStatus = async (jobid, group_id, i) => {
        const jobStatus = await coursesService.getImportJobStatus(jobid);
        if(jobStatus["message"] != "error"){
            if(jobStatus["status"] == "COMPLETE"){
                setLoadMessage(`Configurando datos del curso: ${rowsToSend[i].nombre} - ${i+1}/${rowsToSend.length}`);
                setPlayerLaunchType(jobStatus.importResult.course.id);
                setPlayerScoLaunchType(jobStatus.importResult.course.id);
                setCourseVersionConfiguration(jobStatus.importResult.course.id);
                setProgressUpdateConfiguration(jobStatus.importResult.course.id);
                return await finsihUploadDataToPoint(jobStatus.importResult.course.id, jobid, group_id, i, rowsToSend.length);
            }else{
                await delay(5000);
                await checkUploadedCourseStatus(jobid, group_id, i);
            }
        }else{
            setToastProps({ severity: "error", open: true, message: `Error al subir el archivo del curso.: ${rowsToSend[i].nombre} - ${i+1}/${rowsToSend.length}` });
            return false;
        }
        return true;
    }

    const setPlayerLaunchType = async (courseId) => {
        const result = await coursesService.setPlayerLaunchType(courseId);
        log("setPlayerLaunchType");
        log(result);
    }

    const setPlayerScoLaunchType = async (courseId) => {
        const result =  await coursesService.setPlayerScoLaunchType(courseId);
        log("setPlayerScoLaunchType");
        log(result);
    }

    const setCourseVersionConfiguration = async (courseId) => {
        const result =  await coursesService.setCourseVersionConfiguration(courseId);
        log("setCourseVersionConfiguration");
        log(result);
    }

    const setProgressUpdateConfiguration = async (courseId) => {
        const result =  await coursesService.setProgressUpdateConfiguration(courseId);
        log("setProgressUpdateConfiguration");
        log(result);
    }

    const finsihUploadDataToPoint = async (scorm_course_id, jobid, group_id, i) =>{
        setLoadMessage(`Subiendo imágenes del curso: ${rowsToSend[i].name} - ${i+1}/${rowsToSend.length}`);
        rowsToSend[i]['scorm_course_id'] = scorm_course_id;
        rowsToSend[i]['scorm_job_id'] = jobid;
        rowsToSend[i]['person_id'] = user.person_id;

        if (rowsToSend[i].file_landing instanceof File) {
            const location = `market-place/courses/${rowsToSend[i].company_id}/course-${group_id}/landing`;
            const fileResult = await GlobalServiceSDK.uploadFile(rowsToSend[i].file_landing, location, 'course-landing', group_id, 'file');
            rowsToSend[i]['image_url'] = fileResult.path;
            log(rowsToSend[i]['image_url']);
        }
        setLoadMessage(`Finalizando configuración del curso: ${rowsToSend[i].name} - ${i+1}/${rowsToSend.length}`);
        if (rowsToSend[i].file_card instanceof File) {
            const location = `market-place/courses/${rowsToSend[i].company_id}/course-${group_id}/card`;
            const fileResult = await GlobalServiceSDK.uploadFile(rowsToSend[i].file_card, location, 'course-card', group_id, 'file_card');
            rowsToSend[i]['card_image_url'] = fileResult.path;
            log(rowsToSend[i]['card_image_url']);
        }

        rowsToSend[i]['price'] = rowsToSend[i]['price'] || "0.00";
        rowsToSend[i]['is_active'] = rowsToSend[i]['is_active'] ? 1 : 0;
        rowsToSend[i]['is_sandbox'] = rowsToSend[i]['is_sandbox'] ? 1 : 0;
        rowsToSend[i]['display_in_catalog'] = 1;

        delete rowsToSend[i].file_card;
        delete rowsToSend[i].file_curse;
        delete rowsToSend[i].file_landing;

        //const result = await marketService.newCourseBulk(JSON.stringify(rows));
        setToastProps({ open: true, message: `El curso: ${rowsToSend[i].name} - ${i+1}/${rowsToSend.length} ha sido creado exitosamente.` });

        //const result = await marketService.newCourse(JSON.stringify(body));
        //log(rows[i]);
        //setToastProps({ open: true, message: `El curso: ${rows[i].name} - ${i+1}/${rows.length} ha sido creado exitosamente.` });
    }


    const delay = ms => new Promise(res => setTimeout(res, ms));
    const uploadData = async (id, nombre, institucion, origen_datos, 
        activo, descripcion, objetivo, dirigido, 
        file_card, file_landing, file_course, tipo_enseñanza, 
        idioma, nivel, plataforma, modalidad, instructor,
        duracion, frecuencia, horario, precio, sandbox, index, size) => {
        //log(user);
       /* if (validate(file_card, file_landing, file_course, nombre, institucion, origen_datos, tipo_enseñanza, index, size)) {
            let body = {
                id: id, 
                period: precio, 
                crn: precio,
                materia: precio, 
                curso: precio, 
                secuencia: precio, 
                codigo: precio, 
                programa: precio, 
                company_id: precio, 
                nombre: precio, 
                descripcion: precio,
                objetivo: precio, 
                audience: precio, 
                difficulty_level_id: precio, 
                herramientas:precio, 
                beneficios: precio, 
                habilidades: precio, 
                modulos: precio, 
                requisitos: precio, 
                ventajas_competitivas: precio,
                duration: precio, 
                frequency: precio,
                schedule: precio,
                correo_maestro: precio,
                nombre_maestro: precio,
                apellidos_maestro: precio,
                modality_id: precio,
                plataform: precio,
                fecha_inicio: precio,
                fecha_fin: precio,
                is_active: precio,
                learning_style_id: precio,
                lang: precio,
                price: precio,
                file_card: precio,
                file_landing: precio,
                file_curse: precio,
                
            };
            log(body);
            try {
                const response = await marketService.getCourseNewGroupId();
                log("COMPROBATION");
                log(file_course);
                if (file_course instanceof File) {
                    log("UPLOAD FILE");
                    const jobCourse = await coursesService.uploadCourseFile(file_course);
                    await delay(5000);
                    setLoadMessage(`Subiendo archivo del curso: ${nombre} ${index+1}/${size}`);
                    await checkUploadedCourseStatus(jobCourse.result, file_landing, file_card, body, response.group_id, institucion, nombre, index, size);
                } else {
                    log("UPLOAD NORMAL");
                    await finsihUploadDataToPoint(null, null, file_landing, file_card, body, response.group_id, institucion, nombre, index, size);
                }
            } catch (e) {
                setToastProps({ severity: "error", open: true, message: `Ocurrió un error al crear el curso: ${nombre} ${index+1}/${size}` });
                log(e.message);
            }
        }else{
            setToastProps({ severity: "error", open: true, message: `Ocurrió un error al crear el curso: ${nombre} ${index+1}/${size} revisa los datos de los cursos.` });
        }

        return true;*/
    };

    const handleSubmit = async () => {
        setLoading(true);
        log(rowsToSend);
        for(let i = 0; i < rowsToSend.length; i++){
            setLoadMessage(`Subiendo curso: ${rowsToSend[i].name} - ${i+1}/${rowsToSend.length}`);
            /*let response = await uploadData(, , rows[i].institucion, rows[i].origen_datos, 
                rows[i].activo, rows[i].descripcion, rows[i].objetivo, rows[i].dirigido, 
                rows[i].card, rows[i].landing, rows[i].curso, rows[i].tipo_enseñanza, 
                rows[i].idioma, rows[i].nivel, rows[i].plataforma, rows[i].modalidad, rows[i].instructor,
                rows[i].duracion, rows[i].frecuencia, rows[i].horario, rows[i].precio, rows[i].sandbox, i, rows.length);*/
                if (validate(rowsToSend[i].file_card, rowsToSend[i].file_landing, rowsToSend[i].file_curse, rowsToSend[i].name, rowsToSend[i].company_id, rowsToSend[i].plataform, rowsToSend[i].modality_id, i, rowsToSend.length)) {
                    /*let body = {
                        id: rows[i].id, 
                        period: rows[i].period, 
                        crn: rows[i].crn,
                        materia: rows[i].materia, 
                        curso: rows[i].curso, 
                        secuencia: rows[i].secuencia, 
                        codigo: rows[i].codigo, 
                        programa: rows[i].programa, 
                        company_id: rows[i].company_id, 
                        name: rows[i].name, 
                        description: rows[i].description,
                        objetive: rows[i].objetive, 
                        audience: rows[i].audience, 
                        difficulty_level_id: rows[i].difficulty_level_id, 
                        herramientas: rows[i].herramientas, 
                        beneficios: rows[i].beneficios, 
                        habilidades: rows[i].habilidades, 
                        modulos: rows[i].modulos, 
                        requisitos: rows[i].requisitos, 
                        ventajas_competitivas: rows[i].ventajas_competitivas,
                        duration: rows[i].duration, 
                        frequency: rows[i].frequency,
                        schedule: rows[i].schedule,
                        correo_maestro: rows[i].correo_maestro,
                        nombre_maestro: rows[i].nombre_maestro,
                        apellidos_maestro: rows[i].apellidos_maestro,
                        modality_id: rows[i].modality_id,
                        plataform: rows[i].plataform,
                        fecha_inicio: rows[i].fecha_inicio,
                        fecha_fin: rows[i].fecha_fin,
                        is_active: rows[i].is_active,
                        learning_style_id: rows[i].learning_style_id,
                        lang: rows[i].lang,
                        price: rows[i].price,
                        file_card: rows[i].file_card,
                        file_landing: rows[i].file_landing,
                        file_curse: rows[i].file_curse,
                        source_id: rows[i].source_id,
                    };*/
                    log(rowsToSend[i].name);
                    try {
                        const response = await marketService.getCourseNewGroupId();
                        rowsToSend[i]['group_id'] = response.group_id + i + 1;
                        log("COMPROBATION");
                        if(rowsToSend[i].file_curse != null){
                            if (rowsToSend[i].file_curse instanceof File) {
                                log(rowsToSend[i].file_curse);
                                log("UPLOAD FILE");
                                const jobCourse = await coursesService.uploadCourseFile(rowsToSend[i].file_curse);
                                await delay(5000);
                                setLoadMessage(`Subiendo archivo del curso: ${rowsToSend[i].name} - ${i+1}/${rowsToSend.length}`);
                                await checkUploadedCourseStatus(jobCourse.result, actualGrupId, i);
                            }
                        }else {
                            log("UPLOAD NORMAL");
                            await finsihUploadDataToPoint(null, null, actualGrupId, i);
                        }
                    } catch (e) {
                        setToastProps({ severity: "error", open: true, message: `Ocurrió un error al crear el curso: ${rowsToSend[i].name} - ${i+1}/${rowsToSend.length}` });
                        log(e.message);
                    }
                }else{
                    setToastProps({ severity: "error", open: true, message: `Ocurrió un error al crear el curso: ${rowsToSend[i].name} - ${i+1}/${rowsToSend.length} revisa los datos de los cursos.` });
                }

                //setToastProps({ open: true, message: `El curso: ${rows[i].name} - ${i+1}/${rows.length} ha sido creado exitosamente.` });
        }
        
        setLoadMessage(`Finalizando...`);
        await marketService.newCourseBulk(JSON.stringify(rowsToSend));
        setLoading(false);
        setUploaded(true);
        //setLoadMessage(``);

        
        //setLoading(false);
    }

    const handleFinishedToast = () => {
        setToastProps(prev => { return { ...prev, open: false } });
    };


    return (
            <Box>
            {
                uploaded == false ?
                <Box className={classes.mainPOP}>
                    <Box className="mb-2">
                        <Grid item xs={12} md={12}>
                            <InputFile
                                accept="xlsx"
                                placeholder="Abrir archivo excel"
                                btnText="xlsx"
                                name="xlsx_file"
                                value={file}
                                onChange={(e) => handleChargeExcel(e)} />
                        </Grid>
                    </Box>
                    <Loader loading={loading} message={loadMessage} className={classes.loader}></Loader>
                    {
                        rows.length > 0 && loading == false &&
                        <Box className={classes.mainTable + " mb-2"}>
                            {
                                columns.length > 0 &&
                                <DataGrid
                                    disableColumnMenu
                                    className={classes.dataGrid}
                                    rows={rows}
                                    columns={columns}
                                    colResizeDefault={'shift'}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(ids) => {
                                        const selectedIDs = new Set(ids);
                                        const selectedRows = rows.filter((row) =>
                                            selectedIDs.has(row.id),
                                        );
                                        log(selectedRows);
                                        setRowsToSend(selectedRows);
                                    }}
                                />
                            }
                        </Box>
                    }
                    <Box display="flex" className={classes.buttonsContainer}>
                        <Box className="px-2">
                            <Button color="secondary" onClick={() => handleClickclosePOP()} variant="contained">Cancelar</Button>
                        </Box> 
                        <Box>
                            <Button color="primary" onClick={handleSubmit} variant="contained" style={{ textTransform: 'none', height: "100%" }}>Subir cursos</Button>
                        </Box>
                    </Box>
                </Box>:
                <Box className={classes.mainPOP} alignItems="center">
                    <CheckCircleIcon htmlColor={colors.success.main} style={{ fontSize: "66px" }} />
                    <Typography variant="subtitle2" className={classes.title}>{rows.length} cursos han sido creados exitosamente.</Typography>
                    <Box className="py-3">
                        <Button color="primary" onClick={() => handleClickclosePOP()} variant="contained">Aceptar</Button>
                    </Box> 
                </Box>
            }
                
            <Toast {...toastProps} onFinished={handleFinishedToast} duration={3000} />
            </Box>
        
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(AgregarCursos);