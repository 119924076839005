import React, { useState, useEffect }  from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

// shared - componentes
import Input from '../../shared/components/Form/Input'
import Toolbar from '../components/Navbar/components/Toolbar'
import Logo from '../components/Navbar/components/Logo'
import FormControlSelect from '../../shared/components/Form/FormControlSelect';

// styles
import useStyles from '../../assets/styles/jss/layout/onlineTestLayoutStyle'
import clsx from 'clsx';
import LoaderContent from '../../shared/components/Loader/LoaderContent';
import isEmail from '../../shared/utils/isEmail';
import {CONSECUTIVE_NUMBERS} from '../../shared/constants/regex';

//sdk
import { ENVIRONMENT, TestVocationalService, MIXPANEL_EVENT } from '@sdk-point/talisis';
import Toast from '../../shared/components/Toast/Toast';
import { async } from '@firebase/util';
import { Mixpanel } from '../../shared/components/Mixpanel/mixpanel';
import { log } from '../../shared/utils/console.js'
import { useMediaQuery } from '@material-ui/core';
import { eventData, eventTracker } from '../../shared/components/EventTracker/EventTracker';
import AutocompleteSelect from '../../shared/components/Form/AutoCompleteSelect';

const ouCat = {
    unid: {
        label: 'UNID',
        id: '11780572-1b4d-ea11-a812-000d3a1ab78a'
    },
    uerre: {
        label: 'U-ERRE',
        id: '8e780572-1b4d-ea11-a812-000d3a1ab78a'
    }
}

const setFormatCellphone = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
};

const OnlineVocationalTest = (props) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const {ou} = props;

    const [unidId, setUnidId] = useState('')
    const [uerreId, setUerreId] = useState('')
    //styles by ou
    const img = isResponsive ? `url(/images/vocational-test/${ou}-externo-resp.png)` : `url(/images/vocational-test/${ou}-externo.png)`;
    const logo = `/images/vocational-test/${ou}-logo.png`;
    const classes = useStyles({ img, ou });

    const [acceptTerms, setAcceptTerms] = React.useState(false)
    const [sedeList, setSedeList] = React.useState([])
    const [programList, setProgramList] = React.useState([]);
    const [isValid, setValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const [toastProps, setToastProps] = React.useState({ message: "" });
    const [accounstsList, setAccountsList] = React.useState([]);

    const [form, setForm] = useState({
        name: '',
        lastName: '',
        secondLastName: '',
        email: '',
        confirmEmail: '',
        phone: '',
        procedencia: 'f74ef0da-34ce-eb11-bacc-002248233d9c',
        program: '5d6df162-f408-eb11-a812-000d3a546a72',
        sede: '',
        ou
    });

    useEffect(() => {
        eventData['marca'] = ou;
        eventTracker('s_prueba_vocacional', eventData, ['mixpanel']);
        setSelectValues();
        setSede();
    }, []);

    useEffect(() => {
        const isValid = validate();
        setValid(isValid);
    }, [form]);

    const emailMatch = () => (form.confirmEmail && form.email) && (form.email !== form.confirmEmail);
    const validEmail = () => form.email && !isEmail(form.email);
    const invalidPhone = () => {
        if (!form.phone) return false;
        const n = form.phone.replace(/[^\d]/g, '');

        const consecutive = CONSECUTIVE_NUMBERS.test(n);
        const multiple = /(\d)\1{4}/.test(n);

        const isInvalidNumber = consecutive || multiple;
        return isInvalidNumber;
    }

    const validate = () => {
        const _errors = {};
        const formFields = Object.keys(form);
        const requiredFields = formFields.filter(field => field !== 'procedencia');
        const errorMsg = {
            email: {msg:'Correo Invalido', invalid: validEmail()}, 
            confirmEmail: {msg:'El correo no coincide', invalid: emailMatch()},
            // phone: {msg: 'El telefono no puede tener mas de 5 numeros consecutivos', invalid: invalidPhone()}
        }

        for(const field of requiredFields){
            if(errorMsg[field] && errorMsg[field].invalid){
                if(errorMsg[field].invalid) _errors[field] = errorMsg[field].msg;
            }else if(!form[field]){
                _errors[field] = 'Campo Requerido';
            }
        }

        setErrors(_errors);

        return Boolean(Object.keys(_errors).length === 0);
    }

    const handleChange = (event) => {
        let {name, value} = event.target;
        event.persist();

        if(name === 'phone'){
            value = setFormatCellphone(value, form.phone);
        }
        
        setForm((form) => ({
            ...form,
            [name]: value
        }));
    };


    const handleChangeSearch = (event) => {
        const value = event?.value || '';
        setForm((form) => ({
          ...form,
          procedencia: value,
        }));
      };
      
    const handleFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    };

    const setSelectValues = async () => {
        const sedesPath = `scolaris_campuses?$select=scolaris_nombre,scolaris_clave&$filter=_scolaris_marca_value+eq+'${ouCat[ou].id}'`

        let data  = await getCatalogORM(sedesPath)
        let infoData = []
        for(const i of data.data){
            let sede = {
                'id': i.scolaris_campusid,
                'value': i.scolaris_campusid,
                'label': i.scolaris_nombre,
            }
            infoData.push(sede);
        }
        setSedeList(infoData);
        await getDatAacconts();
    }

    const getDatAacconts = async () => {
        let path = 'accounts?$select=name,scolaris_codigodelaorganizacion&$filter=scolaris_tipodeinstitucion%20eq%201'
       
        let d = await getCatalogORM(path);
        let infoData = []
        for(const i of d.data){
            let accounts = {
                'id': i.accountid,
                'value': i.accountid,
                'label': i.name,
            }
            infoData.push(accounts);
        }
        setAccountsList(infoData)
    }

    const getCatalogORM = async (body) => {
        let bodyquery = {
            "queryORM": body
        }
        const testVocational = new TestVocationalService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY);
        const resultORM = await testVocational.getInfoORM(bodyquery);
        //log('orm', resultORM);
        return resultORM;
        //setSkillHierarchy(resultSkillHierarchy);
    }

    const setSede = async () =>{
        log(process.env.REACT_APP_ENV);
        if (process.env.REACT_APP_ENV === 'v2'){
            setUnidId('850e1da6-68cd-ec11-a7b5-000d3a4d58de')
            setUerreId('32661a3e-f2d6-ec11-a7b5-00224827871c')
            setForm({...form,
                sede: ou == 'uerre' ? '32661a3e-f2d6-ec11-a7b5-00224827871c' : '850e1da6-68cd-ec11-a7b5-000d3a4d58de',
            })
        }else{
            setUnidId('fb248675-ccdd-ec11-a7b6-000d3a8ef0b6')
            setUerreId('fa369b83-ccdd-ec11-a7b6-000d3a8ef0b6')
            setForm({...form,
                sede: ou == 'uerre' ? 'fa369b83-ccdd-ec11-a7b6-000d3a8ef0b6' : 'fb248675-ccdd-ec11-a7b6-000d3a8ef0b6',
            })
        }
        
        //log('form', form);
    }
    const postTestVocationUser = async () => {
        form.phone = form.phone.replace(/[^\d]/g, '');
        //log(form);
        setLoading(true);
        eventTracker('crear_cuenta', eventData, ['mixpanel']);
        const testVocational = new TestVocationalService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY);
        const response = await testVocational.postTestVocationUser(form);
        if (response.status===200){
            setForm({name: '',
                    lastName: '',
                    secondLastName: '',
                    email: '',
                    confirmEmail: '',
                    phone: '',
                    program: '5d6df162-f408-eb11-a812-000d3a546a72',
                    sede: ou == 'uerre' ? uerreId : unidId,
                    ou});
            setAcceptTerms(false);
            setLoading(false);
            var win = window.open(response.data.linkTest, '_blank');
            win.focus();
        }else if (response.status===203){
            //log('usuario ya existe')
            setLoading(false);
            setErrors({email: 'El correo ya existe. Intenta uno nuevo'});
        }else {
            setLoading(false);
            setToastProps({ severity: "error", open: true, message: "Ha ocurrido un error, por favor intentar nuevamente" })
            //log(response.data.message);
        }
    }

    return (
        <Box className={clsx(classes.root, classes[ou])}>
            <CssBaseline />
            <Toolbar>
                <Logo />
            </Toolbar>
            <LoaderContent loading={loading}>
                <main className={classes.content}>
                    <div className={classes.drawerHeader} />
                    <Box className={clsx(classes.appBar, 'app-bar')}>
                        <img src={logo} className={classes.logo}/>
                        <Typography variant="h3" > Prueba Vocacional</Typography>   
                    </Box>
                    <Box className={classes.img}/>
                    <Container maxWidth="sm">
                        
                        <Box my="42px">
                            <Box mb="48px">
                                <Typography variant="h2" className="mb-3 subtitle">Te damos la bienvenida</Typography>
                                <Typography variant="body1" className="text">A la <span className="font-weight-700">Prueba Vocacional {ouCat[ou].label}</span>, la cual te ayudará a decidir tu futuro profesional.Te tomará 15 minutos responderlo. <br/><br/> Si ya lo hiciste anteriormente solo <span className="font-weight-700"><Link component={RouterLink} to="/login" color="secondary" style={{fontSize:'17.5px'}}>iniciar sesión aquí</Link> para ver tus resultados</span> en la sección de Perfil.<br/><br/>Una vez que lo inicies es importante que termines ya que <span className="font-weight-700">solo tendrás una oportunidad</span>, por lo que recomendamos que pongas mucha atención.<br/><br/><span className="font-weight-700">Para iniciar la prueba</span> primero completa el siguiente formulario</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Input 
                                        fullWidth
                                        required
                                        label="Nombre (s)"
                                        // className={classes[ou]}
                                        placeholder="Nombres"
                                        name="name"
                                        value={form.name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Input 
                                        fullWidth
                                        required={true}
                                        label="Apellido paterno"
                                        className={classes[ou]}
                                        placeholder="Apellido paterno"
                                        name="lastName"
                                        value={form.lastName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Input 
                                        fullWidth
                                        required={true}
                                        label="Apellido materno"
                                        className={classes[ou]}
                                        placeholder="Apellido materno"
                                        name="secondLastName"
                                        value={form.secondLastName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Input 
                                        fullWidth
                                        required={true}
                                        label="Correo electrónico"
                                        className={classes[ou]}
                                        placeholder="correo@mail.com"
                                        name="email"
                                        value={form.email}
                                        onChange={handleChange}
                                        error={form.email && errors.email}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Input 
                                        fullWidth
                                        required={true}
                                        label="Confirmar correo"
                                        className={classes[ou]}
                                        placeholder="correo@mail.com"
                                        name="confirmEmail"
                                        value={form.confirmEmail}
                                        onChange={handleChange}
                                        error={form.confirmEmail && errors.confirmEmail}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        fullWidth
                                        required={true}
                                        label="Celular"
                                        className={classes[ou]}
                                        placeholder="(811) 123-456"
                                        name="phone"
                                        value={form.phone}
                                        onChange={handleChange}
                                        error={form.phone && errors.phone}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        fullWidth
                                        className={classes[ou]}
                                        label= "Escuela de procedencia (opcional)"
                                        placeholder="Busca el lugar de procedencia"
                                        name="procedencia"
                                        value={form.procedencia}
                                        accounstsList={accounstsList}
                                        onChange={handleChangeSearch}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                                        <FormControlSelect 
                                                            fullWidth
                                                            //required
                                                            label="Sede"
                                                            className={classes[ou]}
                                                            placeholder="Selecciona una sede"
                                                            name="sede"
                                                            value={form.sede}
                                                            onChange={handleChange}
                                                            items={sedeList}
                                                        />
                                                    </Grid>
                                {/* {
                                    ou == 'unid' &&  <Grid item md={6} xs={12}>
                                                        <FormControlSelect 
                                                            fullWidth
                                                            //required
                                                            label="Sede"
                                                            className={classes[ou]}
                                                            placeholder="Selecciona una sede"
                                                            name="sede"
                                                            value={form.sede}
                                                            onChange={handleChange}
                                                            items={sedeList}
                                                        />
                                                    </Grid>
                                } */}
                            </Grid>

                            <Box my="52px">
                                <FormControlLabel
                                    control={<Checkbox checked={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} name="accept" color="primary"/>}
                                    label={
                                        <Typography variant="body2">
                                        He leído y acepto los <Link component={RouterLink} to="/terminos_condiciones" color="secondary">Términos y Condiciones</Link> y <Link component={RouterLink} to="/aviso_privacidad" color="secondary">Aviso de privacidad</Link> de Talisis.
                                        </Typography>
                                    }
                                />
                            </Box>
                            <Box>
                                <Button variant="contained" fullWidth color="primary" disabled={!acceptTerms || !isValid} onClick={postTestVocationUser} className="mb-2">Iniciar Prueba</Button>
                                <Button fullWidth className={classes.buttonLogin} color="primary" component={RouterLink} to="/login">Iniciar sesión</Button>
                            </Box>
                        </Box>
                    </Container>
                    <Toast {...toastProps} onFinished={handleFinishedToast} />
                </main>
            </LoaderContent>
        </Box>
    );
};

export default OnlineVocationalTest;