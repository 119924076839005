
// Checkout Loader
export const TOGGLE_CHECKOUT_LOADER = "[checkout] Toggle checkout loader";

// Checkout Missing Phone
export const TOGGLE_MISSING_PHONE = "[checkout] Toggle missing phone component";

export const SET_CHECKOUT_CURRENT_STEP = "[checkout] Set checkout current step";

// Checkout Order
export const SET_ORDER = "[checkout] Set Purchase Order";
export const SET_CONCEPTOS = "[checkout] Set Order products";
export const SET_NEXT_PAYMENT_DATE = "[checkout] Set suscription's next payment date";
export const SET_ORDER_QUANTITY = "[checkout] Set purchase price details";
export const SET_PENDING_DISCOUNT = "[checkout] Set pending discount";
export const SET_ORDER_SAVINGS = "[checkout] Set order's savings";
export const SET_COUPON_DATA = "[checkout] Coupon information";
export const SET_FREE_COUPON_PURCHASE = "[checkout] Set 100% discount to the current purchase";
export const SET_SELECTED_PAYMENT = "[checkout] Set the selected payment method";

// Checkout Register User
export const SET_USER_REGISTER_FORM = "[checkout] Display user registration form";
export const APPLY_USER_REGISTER_FORM = "[checkout] Apply user registration information";
export const SET_PERSON_INFO = "[checkout] Set user's information for purchase"
export const SET_PERSON_ID_TO_ORDER = "[checkout] Set's person id to current order"

// Checkout Membership Info
export const SET_SELECTED_MEMBERSHIP = "[checkout] Set the selected membership to purchase";
export const GET_USER_CURRENT_MEMBERSHIP = "[checkout] Get user's current membership info";
export const SET_MEMBERSHIP_FEE_PLANS = "[checkout] Set available membership fee plans";

// Checkout Monthly Payments ( Meses Sin Intereses - MSI )
export const SET_MONTHLY_PAYMENTS_INFO = "[checkout] Set monthly payments for purchase"
export const SET_PAYMENT_PLAN = "[checkout] Set monthly payment plan"

