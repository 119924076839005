import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootFloatingContainerMenu: {
        background: theme.palette.colors.fillStroke[400], 
        width: "100%", 
        position: "absolute",
        left: "0",
        right: "0",
        overflow: 'auto',
        overflowX: 'hidden',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        top: props=> props.isLogged ? 65 : 122,
        backgroundColor: 'rgba(0, 0, 0, .8)',
    },
}))