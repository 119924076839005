import React, { useEffect, useState } from 'react'

import { Grid, useMediaQuery, Hidden} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';

import CheckoutSideCard from './CheckoutSideCard/CheckoutSideCard';

import { getCheckoutCookie, setCheckoutCookie } from '../../../../shared/utils/checkoutSession-legacy.js';
import StudentSummary from './StudentSummary';
import OrderProduct from '../OrderDetails/OrderDetailsCard/OrderProduct/OrderProduct';

import CheckoutCoupons from '../CheckoutCoupons/CheckoutCoupons';
import MembershipBenefitsCheckout from './MembershipBenefitsCheckout/MembershipBenefitsCheckout';
import PriceSummary from './PriceSummary/PriceSummary';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderQuantities } from '../../../../redux/actions/legacyCheckoutActions.js';
import CheckoutWorkFlow from './CheckoutWorkFlow/CheckoutWorkFlow';
import { updateOrderQuantitiesDiscount } from '../../../../shared/utils/checkoutHelpers-legacy.js';
import CustomMembershipMessage from './CustomMembershipMessage/CustomMembershipMessage';

const membershipPlans = {
    1: 'Mensual',
    6: 'Semestral',
    12: 'Anual'
}

const CheckoutSummary = (props) => {

    const checkoutCookie = getCheckoutCookie();
    const theme = useTheme();

    const { order, userCurrentMembership, hasFreeCoupon: freeCouponReducer, personInfo,
        orderQuantity: quantityReducer, orderNextPaymentDate, pendingOrderDiscount
    } = useSelector((state) => state.legacyCheckoutReducer);
    const { user } = useSelector((state) => state.userReducer);
    const dispatch = useDispatch();

    const { setCheckoutStarted, percentOff, amountOff, addNewDifferenceAmountFees, 
        addLabelNextPaymentDay, addPendingDiscount, addDaysToNextPayment, isCustomMembership, customMembershipData
    } = props;

    const [step, setStep] = useState(0);
    const [selectMembershipPaymentStep,setSelectMembershipPaymentStep] = useState(true);
    const [nextPaymentDateSelected, setNextPaymentDateSelected] = useState("");
    const [indexForOneOrTwoArticles, setIndexForOneOrTwoArticles] = useState(0);
    const [ahorro, setAhorro] = useState(0);
    
    const [admisionId, setAdmisionId] = useState('');
    const [periodo, setPeriodo] = useState(12);

    const handleCheckoutReturn = () => {
        setCheckoutStarted(0)
        setCheckoutCookie({
            ...checkoutCookie,
            checkoutStep: 0
        })
    }

    useEffect(() => {}, [step])

    const conceptosOrden = order?.conceptos;

    useEffect(() => {
        if(checkoutCookie.membershipSession){
            setSelectMembershipPaymentStep(false);
        }
        
        // setNextPaymentDateSelected(nextPaymentDate);
        setNextPaymentDateSelected(orderNextPaymentDate);
        
        const orderQuantities = updateOrderQuantitiesDiscount(pendingOrderDiscount, amountOff, order?.discount, quantityReducer);
        
        setAhorro(orderQuantities?.ahorro);
        let oneOrTwoArticles=0;
        // if(order.conceptos.length > 1){
        if( order.conceptos.length > 1 ){
            setIndexForOneOrTwoArticles(1);
            oneOrTwoArticles=1;
        }else{
            setIndexForOneOrTwoArticles(0);
        }
        
        // if(!orderQty.onSelectingPeriod)
        if( !quantityReducer.onSelectingPeriod )
        {
            dispatch(setOrderQuantities({
                // ...orderQty,
                ...orderQuantities,
                order_subtotal: order?.subtotal,
                order_discount: order?.discount,
                order_total: order?.total,
                plan_months: order?.conceptos[oneOrTwoArticles]?.membership_plan_months,
            }))
        }
    // }, [order, pendingDiscount, nextPaymentDate, amountOff]); 
    }, [ order, pendingOrderDiscount, orderNextPaymentDate, amountOff]); 

    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid container spacing={2} justifyContent="space-around" direction={ breakpoint ? "column-reverse" : "row"}>
            {/* <Grid item md={7} xs={12}> */}
            {/* <MonthlyPaymentsDropdown /> */}
            <Grid item md={7} xs={12}>
                {
                    isCustomMembership
                    ?
                        <CustomMembershipMessage customMembershipData={customMembershipData} />
                    :
                        <CheckoutWorkFlow
                            // Create User
                            order={order} user={user} admisionId={admisionId} setAdmisionId={setAdmisionId}
                            // Membership Periods
                            // membershipPlan={membershipPlans[orderQty.plan_months]} 
                            membershipPlan={ membershipPlans[quantityReducer.plan_months] } 
                            setPeriodo={setPeriodo}
                            addPendingDiscount={addPendingDiscount} addDaysToNextPayment={addDaysToNextPayment}
                            percentOff={percentOff} addLabelNextPaymentDay={addLabelNextPaymentDay} addNewDifferenceAmountFees={addNewDifferenceAmountFees}
                            freeCoupon={freeCouponReducer} 
                            // setOrderRef={setOrderRef} membershipFeePlans={membershipFeePlans} orderQty={orderQty} setOrderQty={setOrderQty}
                            orderQty={quantityReducer}
                            currentFeePlan={order?.conceptos[indexForOneOrTwoArticles]}
                            setSelectMembershipPaymentStep={setSelectMembershipPaymentStep} userCurrentMembership={userCurrentMembership}
                            setStep={setStep} nextPaymentDate={nextPaymentDateSelected}
                        />
                }
                <Hidden mdUp>
                    {
                        !isCustomMembership && 
                        <Grid item xs={12} className='mt-3'>
                            <CheckoutCoupons />
                        </Grid>
                    }
                    <Grid item xs={12} className='mt-3' style={{ marginBottom: '60px'}}>
                        <CheckoutSideCard 
                            cardSize={12}
                            noTitle={true}
                            component={
                                <PriceSummary
                                    // orderQty={orderQty}
                                    orderQty={quantityReducer}
                                    pendingDiscount={pendingOrderDiscount}
                                    nextPaymentDateSelected={nextPaymentDateSelected}
                                />                           
                            }
                        />
                    </Grid>
                </Hidden>
            </Grid>
            <Grid item xs={12} md={4} style={{ minWidth: breakpoint ? "auto" : "424px" }}>
                <CheckoutSideCard 
                    cardSize={12} cardTitle={breakpoint ? "Orden de compra" : "Resumen de compra"}
                    cardTitleColor={breakpoint ? "text-dark-black-200" : ""}
                    component={
                        <Grid style={{marginTop: 6}}>
                            {
                                (conceptosOrden.length > 1) &&
                                <>
                                    <OrderProduct
                                        product={conceptosOrden[0]} order={order}
                                        conceptType={"product"} // TODO: Recibir tipo del back
                                        couponData={""} totalWoDiscount={""}
                                        hidePrices
                                    />
                                    <MembershipBenefitsCheckout order={order} isCollapsable productWithMembership />
                                </>
                            }
                            {
                                (conceptosOrden.length === 1) &&
                                conceptosOrden.map(function (concepto, key) {
                                    return (
                                        <OrderProduct
                                            key={key} product={concepto} order={order}
                                            conceptType={concepto?.order_detail_type_slug}
                                            couponData={""} totalWoDiscount={""}
                                            hidePrices
                                        />
                                    );
                                })
                            }
                        </Grid>
                    }
                />
                {
                    (Object.keys(checkoutCookie.personInfo).length > 0) && !isCustomMembership && 
                    <Grid item xs={12} className={breakpoint ? "mt-3" : "mt-2"}>
                        <CheckoutSideCard 
                            cardSize={12} cardTitle={"Estudiante"}
                            cardTitleColor={breakpoint ? "text-dark-black-200" : ""}
                            component={
                                <StudentSummary
                                    name={ checkoutCookie.personInfo.userName ? `${checkoutCookie.personInfo.userName}` : `${checkoutCookie.personInfo.first_name} ${checkoutCookie.personInfo.last_name}`}
                                    phoneNumber={checkoutCookie?.personInfo?.phone_number ?? personInfo.phone_number}
                                    email={checkoutCookie.personInfo.email_address || checkoutCookie.personInfo.email}
                                />
                            }
                        />
                    </Grid>
                }
                <Hidden smDown>
                    {
                        !isCustomMembership && 
                        <Grid item xs={12} className='mt-2'>
                            <CheckoutCoupons />
                        </Grid>
                    }
                    <Grid item xs={12} className='mt-2'>
                        <CheckoutSideCard 
                            cardSize={12}
                            noTitle={true}
                            component={
                                <PriceSummary
                                    // orderQty={orderQty}
                                    orderQty={quantityReducer}
                                    pendingDiscount={pendingOrderDiscount}
                                    nextPaymentDateSelected={nextPaymentDateSelected}
                                />                        
                            }
                        />
                    </Grid>
                </Hidden>
            </Grid>
        </Grid>
    )
}

export default CheckoutSummary
