import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        // 'background-color': 'rgb(240 244 250 / 66%)',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        'z-index': theme.zIndex.drawer + 1,
        position: 'absolute',
        'border-radius': 10,

        '&.transparent':{
            // 'background-color': 'rgb(255 255 255 / 66%)',
        }
    },
    loaderCircular:{
        color: props => props.helpColor ? theme.palette.colors.orange[300] : theme.palette.colors.violet[300]
    }    
}));