import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    icon:{
        color: theme.palette.colors.blue[500],
        fontSize: '14px !important'
    },
    listItemIcon:{
        minWidth: 29
    },
    listItemText:{
        fontSize: 16,
        lineHeight: '16px',
        fontWeight: 400,
        color: theme.palette.colors.grey[200],
    },
    listItem:{
        paddingLeft: 8,
        paddingRight: 8,
    }
}));