import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// @redux
import { connect, useDispatch, useSelector } from "react-redux";

// @material-ui/core
import { Grid, Box, Typography, Icon, Hidden, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

//SDK
import { MIXPANEL_EVENT } from '@sdk-point/talisis';
import { Mixpanel } from '../../../../shared/components/Mixpanel/mixpanel';

//shared
import CheckoutSummary from '../CheckoutSummary/CheckoutSummary';

//dependencies
import Cookies from 'js-cookie';
import { getCheckoutCookie, setCheckoutCookie } from '../../../../shared/utils/checkoutSession-legacy.js';
import { setFreeCouponPurchase } from '../../../../redux/actions/legacyCheckoutActions.js';

//routes
import * as paths from '../../../../routes/paths';

const ConektaPaymentsNew = (props) => {

    // const { user, order, classes, setOrderRef, productIncluded, membershipFeePlans, percentOff, amountOff, addNewDifferenceAmountFees, nextPaymentDate, addLabelNextPaymentDay, addPendingDiscount, addDaysToNextPayment, pendingDiscount, daysToNextPayment, userCurrentMembership } = props;
    const { user: userReducer, percentOff, amountOff, addNewDifferenceAmountFees, addLabelNextPaymentDay, addPendingDiscount, addDaysToNextPayment, daysToNextPayment, isCustomMembership, customMembershipData } = props;
    const theme = useTheme();
    const history = useHistory();
    const checkoutCookie = getCheckoutCookie();
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
    
    // Checkout Reducer
    const { order: orderReducer, orderNextPaymentDate, pendingOrderDiscount } = useSelector((state) => state.legacyCheckoutReducer);
    const dispatch = useDispatch();

    const [freeCoupon,setFreeCoupon] = useState(false);
    const [checkoutStarted, setCheckoutStarted] = useState(checkoutCookie?.checkoutStep);

    const [conceptos, setConceptos] = useState([]);    
    const [couponData,setCouponData]=useState("");

    useEffect(() => {
      if( orderReducer?.total === 0 ){
        setFreeCoupon(true);
        dispatch(setFreeCouponPurchase(true))
        setCheckoutCookie({
            ...checkoutCookie,
            hasFreeCoupon: true
        })
      }
      if( orderReducer?.total > 0 ){
        setFreeCoupon(false);
        dispatch(setFreeCouponPurchase(false))
        setCheckoutCookie({
            ...checkoutCookie,
            hasFreeCoupon: false
        })
      }
    }, [orderReducer])
    
    const handleMix=()=>{
        const role = JSON.parse(localStorage.getItem('permissions'));
        
    }

    useEffect(() => {
        return () => {
            if (history.action === "POP") {
                if (history.location.pathname !== paths.CHECKOUT_LEGACY && history.location.pathname !== "/payment-confirmation") {
                    handleMix();
                }
            }
        }
    }, [history]);

    return (
        <>
            <Box>
                <Grid container direction="row" justifyContent='flex-end' className={`pb-3 ${breakpoint && "mt-3"}`}>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'right', color: '#A0A9BA' }}>
                        <Grid container justifyContent='flex-end' alignItems='center'>
                            <Icon className={`ri-shopping-cart-2-line text-dark-black-200 ${breakpoint ? "font-size-21" : "font-size-14"}`} /> 
                            <Hidden smDown>
                                <Typography variant='body2' className='text-dark-black-200 semi-bold ml-2'>
                                    Artículos en el carrito
                                </Typography>
                            </Hidden>
                            <span style={{ marginLeft: '10px', borderRadius: '50%', padding: '10px 15px', backgroundColor: '#1C1F24' }}>
                                1
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* <CheckoutSummary pendingDiscount={pendingDiscount} daysToNextPayment={daysToNextPayment} addPendingDiscount={addPendingDiscount} addDaysToNextPayment={addDaysToNextPayment} percentOff={percentOff} addLabelNextPaymentDay={addLabelNextPaymentDay} nextPaymentDate={nextPaymentDate} amountOff={amountOff} addNewDifferenceAmountFees={addNewDifferenceAmountFees} user={user} course={order?.conceptos[0]} setCheckoutStarted={setCheckoutStarted} */}
            <CheckoutSummary
                // freeCoupon={freeCoupon} setFreeCoupon={setFreeCoupon} order={order} setOrderRef={setOrderRef}
                daysToNextPayment={daysToNextPayment} addPendingDiscount={addPendingDiscount} 
                addDaysToNextPayment={addDaysToNextPayment} percentOff={percentOff} addLabelNextPaymentDay={addLabelNextPaymentDay} 
                // nextPaymentDate={nextPaymentDate} amountOff={amountOff} addNewDifferenceAmountFees={addNewDifferenceAmountFees} 
                amountOff={amountOff} addNewDifferenceAmountFees={addNewDifferenceAmountFees} 
                course={orderReducer?.conceptos[0]} setCheckoutStarted={setCheckoutStarted} isCustomMembership={isCustomMembership} customMembershipData={customMembershipData}
            />
        </>
    );
}

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(ConektaPaymentsNew);
