import React from 'react';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

// @material-ui/icons
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: "8px",
        paddingBottom: "8px",
    },
}));

const SectionAddItem = (props) => {
    const classes = useStyles();
    const [form, setForm] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const { fieldName, onUpdate } = props;

    const validate = () => {
        const _errors = {};
        const requiredFields = [fieldName];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo requerido';
            }
        }

        setErrors(_errors);

        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleAdd = () => {
        if (validate()) {
            onUpdate({ [fieldName]: form[fieldName] });
            setForm({});
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const _errors = { ...errors };

        if (value) {
            delete _errors[name];
            setErrors(_errors);
        }

        setForm(prev => {
            return { ...prev, [name]: value }
        });
    };

    return (
        <Box className={classes.root}>
            <TextField
                name={fieldName}
                label="Agregar elemento"
                required
                size="small"
                fullWidth
                variant="outlined"
                value={form[fieldName] || ""}
                onChange={handleChange}
                error={Boolean(errors[fieldName])}
                helperText={errors[fieldName]}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton color="primary" aria-label="add" edge="end" onClick={handleAdd}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                inputProps={{maxLength:250}}
            />
        </Box>
    );
};

export default SectionAddItem;