import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material components
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';

// Styles
import useStyles from '../../../../assets/styles/components/filters/filterSectionStyle';

const FilterSection = ({id, isExpanded, onChangeAccording, title, data, onChangeChecked}) => {
    const classes = useStyles();

    return (
        <>
            <Accordion expanded={isExpanded} elevation={0} className={classes.rootFilterSection}>
                <AccordionSummary expandIcon={<i className={clsx(`ri-arrow-down-s-line`, classes.icon, `font-size-24`)}></i>} className={classes.accordionTitle} onClick={() => { onChangeAccording(id) }}>
                    <Typography variant='body1' className={clsx('semi-bold', 'text-gray-100')}>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <FormGroup>
                        {
                            data.map((item, index) => {
                                return (
                                    <FormControlLabel control={
                                        <Checkbox 
                                            color='primary' 
                                            className={classes.filterCheckbox} 
                                            onChange={() => { onChangeChecked(id, item.id) }} 
                                            value={item.id} 
                                            checked={item.checked} 
                                            size='small'
                                            disabled={!!item.disabled}/>} 
                                            label={<Typography variant='body2' className={clsx('text-gray-400')}>{item.name}</Typography>} 
                                            className={classes.formControlContainer}
                                            key={item.id} />
                                );
                            })
                        }
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

FilterSection.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    isExpanded: PropTypes.bool.isRequired,
    onChangeAccording: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
        name: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        disabled: PropTypes.bool
    })),
    onChangeChecked: PropTypes.func.isRequired,
};

export default FilterSection;