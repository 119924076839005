import { makeStyles } from "@material-ui/core";

export const cardPaymentStyles = makeStyles((theme) => ({
    titleSteps: {
        fontSize: '32px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingBottom: '32px'
    },
    note:
    {
        color: '#A0A9BA',
        fontSize: '12px',
        paddingBottom: '24px'
    },
    line:
    {
        borderTop: '1px solid #404150',
        paddingBottom: '33px'
    },
    buttonContainer:
    {
        textAlign: 'right'
    },
    warningContainer:
    {
        textAlign: 'left',
        color: '#FCD612',
        display: 'inline-flex',
        paddingBottom: '40px'
    },
    textFieldCard:
    {
        borderRadius: '0px !important',
        backgroundColor: 'transparent',
    },
    inputCard:
    {
        borderRadius: '0px !important',
        // borderBottom: '2px solid #A0A9BA',
        paddingLeft:'14px',
        backgroundColor: 'transparent',
        height: '45px'
    },
    inputCardStart:
    {
        borderRadius: '0px !important',
        borderBottom: '2px solid #A0A9BA',
        backgroundColor: 'transparent',
        height: '45px'
    },
    helpIcon:
    {
        color: '#FF8000',
        marginLeft: '2px'
    },
    filterCheckbox:
    {
        "@media (max-width: 959px)": {
            display: 'none'
        }
    },
    filterCheckbox2:
    {
        display:'none',
        "@media (max-width: 959px)": {
            display:'block'
        }
    },
}));
