import React from 'react';

// @material-ui 
import { Box, Button, Grid, Icon, Modal, Typography } from '@material-ui/core'

// assets
import useStyles from '../../../assets/styles/jss/pages/checkout/ProductAssignment.js';

// core components
import ProgramCard from './ProgramCard/ProgramCard.jsx';
import CourseCard from './CourseCard/CourseCard.jsx';

// ds
import { PRODUCT_TYPES } from '@sdk-point/talisis';
// import Loader from '../../../design-system/components/Loader/Loader.jsx';
import LoaderContent from '../../../design-system/components/Loader/LoaderContent.jsx';

const ProductAssignmentModal = ({ openModal, onModalClose, onProductEnrollment, onGoToCatalog, product, loading }) => {
    const classes = useStyles();

    return (
        <Modal
            open={openModal}
            onClose={onModalClose}
            className={classes.productAssignmentModal}
            aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description"
        >
            <Box className={classes.productAssignmentModalInside}>
                <LoaderContent loading={loading}> 
                <Grid container direction="row" justifyContent='center'>
                    <Grid item xs={12} style={{ paddingBottom: '8px', textAlign: 'right' }}>
                        <Icon className='ri-close-fill text-dark-black-200' style={{ cursor: 'pointer' }} fontSize='small' onClick={onModalClose} />
                    </Grid>
                    <Grid item xs={12} className='pb-4'>
                        <Typography variant='h4'>
                            {`¿Estás listo para empezar tu ${product?.product_type_name?.toLowerCase()}?`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className='pb-4'>
                        <Box display="flex" justifyContent="center">
                            {product?.product_type_id === PRODUCT_TYPES.PROGRAMA && <ProgramCard product={product} />}
                            {product?.product_type_id === PRODUCT_TYPES.CURSO && <CourseCard product={product} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} className='pb-2'>
                            <Button variant="contained" color='primary' onClick={onProductEnrollment}>
                                {`Inscribirme a ${product?.product_type_name?.toLowerCase()}`}
                            </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color='primary' endIcon={<Icon className='ri-arrow-right-line' style={{ fontSize: '24px !important' }} />} onClick={onGoToCatalog}>
                            Ir a catálogo
                        </Button>
                    </Grid>
                </Grid>
                </LoaderContent>
            </Box>
        </Modal>
    )
}

export default ProductAssignmentModal;
