import React from 'react';

// @material-ui/core
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { v4 as uuid } from 'uuid';
// styles
import useStyles from '../../../assets/styles/jss/components/scheduleStyle';

function Schedule(props) {
    const {items} = props;
    const classes = useStyles();

    return (
        <div>
            <List dense>
                {items.map(obj => 
                    <ListItem key={uuid()} disableGutters className={classes.listItem}>
                        {obj.value != '-'  && 
                            <>
                                <ListItemIcon className={classes.listItemIcon}>
                                    <Icon className={classes.icon}>{obj.icon}</Icon>
                                </ListItemIcon>
                                    <ListItemText classes={{primary: classes.listItemText}} primary={`${obj.label}: ${obj.value}`}/>
                                
                            </>
                        }
                    </ListItem>
                )}
            </List>
        </div>
    )
}

export default Schedule;