import React from 'react';
import PropTypes from 'prop-types';

// Constants
import {MEMBERSHIPS} from '../../../constants/memberships';

// Icons 
import Star2SVG from '../../../assets/images/icons/star-2.svg';
import Star3SVG from '../../../assets/images/icons/star-3.svg';
import Star4SVG from '../../../assets/images/icons/star-4.svg';

const MembershipStarIcon = ({membershipId}) => {
    return (
        <img src={membershipId === MEMBERSHIPS.PREMIUM ? Star4SVG : (membershipId === MEMBERSHIPS.PROFESSIONAL ? Star3SVG : Star2SVG)} className='font-size-16'/>
    );
};

MembershipStarIcon.defaultProps = {
    membershipId: 0,
};

MembershipStarIcon.propTypes = {
    membershipId: PropTypes.number,
};

export default MembershipStarIcon;