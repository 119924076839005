import palette from '../palette';
export default {
    root: {
        borderRadius: 8,
        border: '1px solid #404150',
        '&.Mui-error': {
            border: `.5px solid ${palette.colors.error[300]} !important`,
            background: 'rgba(141, 45, 50, 0.1)',
            '&.Mui-focused': {
                border: `.5px solid ${palette.colors.error[300]} !important`,
            },
            '& .MuiOutlinedInput-notchedOutline':{
                border: 0
            }
        },
        '&.Mui-warning': {
            border: `.5px solid ${palette.colors.warning[400]} !important`,
            background: 'rgba(179, 157, 47, 0.1)',
            '&.Mui-focused': {
                border: `.5px solid ${palette.colors.warning[400]} !important`,
            },
            '& .MuiOutlinedInput-notchedOutline':{
                border: 0
            }
        },
        '&.Mui-success': {
            border: `.5px solid ${palette.colors.success[400]} !important`,
            background: 'rgba(0, 146, 86, 0.1)',
            '&.Mui-focused': {
                border: `.5px solid ${palette.colors.success[400]} !important`,
            },
            '& .MuiOutlinedInput-notchedOutline':{
                border: 0
            }
        },
        '&.Mui-focused': {
            border: `1px solid ${palette.colors.violet[300]} !important`,
            '& .MuiOutlinedInput-notchedOutline':{
                border: 0
            }
        },
        '&:hover':{
            border: '1px solid #404150',
            '& .MuiOutlinedInput-notchedOutline':{
                border: 0
            }
        }
    },
    input:{
        caretColor: palette.colors.violet[300],
        '&::placeholder': {
            color: palette.colors.darkBlack[200], // Cambia el color del marcador de posición aquí
        },
        '&:focus::placeholder': {
            padding: '0 0.5rem', // Agrega espacio horizontal aquí
        },
    },
    multiline:{
        padding: '5px 0px'
    }
};