import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    learnigRoot: {
        minHeight: '264px',
        [theme.breakpoints.down("sm")]: {
            marginTop: '16px',
        }
    },
    headerRoot: {
        borderBottom: `1px solid ${theme.palette.colors.fillStroke[300]}`
    },
    headerActionButton: {
        marginTop: 0,
        marginRight: 0,
    },
    contentRoot: {
        padding: '16px',
        height: '100%',
    },
    notCourseImageContainer: {
        width: '170px',
    },
    imageNotCourses: {
        width: '170px',
        height: '100%',
    },
    notCourseContainer: {
        display: 'flex',
        width: 'calc(100% - 170px)'
    },
    notCourseData: {
        paddingLeft: '32px',
        display: 'flex',
        flexDirection: 'column',
    },
    notCourseTextDescription: {
        marginTop: '8px',
        color: theme.palette.colors.gray[500],
    }, 
    notCourseButton: {
        marginTop: '28px',
        width: 'fit-content',
    },
}));