import { makeStyles } from '@material-ui/core';
import { SOURCES } from '../../../constants/sources'

export default makeStyles((theme) => ({
    root:{
        height: 346,
        background: theme.palette.colors.fillStroke[400],
        [theme.breakpoints.down('xs')]:{
            height: 217,
        }
    },
    container:{
        paddingTop: 32,
        position: 'relative'
    },
    img:{
        backgroundImage: props => `url(${props.backgroundImage})`,
        height: 346,
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        position: 'absolute',
        [theme.breakpoints.down('xs')]:{
            height: 217,
        }
    },
    imgOpacity:{
        opacity: 0.5,
    },
    shareIcon:{
        color: theme.palette.colors.gray[200]
    },
    [SOURCES.UNID]:{
        width: 71,
        height: 22,
        [theme.breakpoints.down('xs')]:{
            with: 53,
            height: 16
        }
    },
    logo:{
        width: 'auto',
        height: 22,
        [theme.breakpoints.down('xs')]:{
            height: 16,
        }
    },
    backToGo:{
        marginBottom: 32
    }
}));