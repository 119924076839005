import React from 'react';

// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';

// redux
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import getMembershipIcon from '../Modalupselling/MembershipIconSelector';
import {MEMBERSHIPS} from '../../constants/memberships'

import clsx from 'clsx';

// @redux

const useStyles = makeStyles((theme) => ({
    badge: {
        height: '100%',
        width: '100%',
    },
    badgeMembership:{
        border: '2px solid transparent',
        borderRadius: 54,
        '&::before': {
            content: '" "',
            position: 'absolute',
            top: '-2px',
            left: '-2px',
            right: '-2px',
            bottom: '-2px',
            backgroundImage: 'linear-gradient(90deg, #3900D9 1.56%, #9F6FFB 41.15%, #DF43B7 63.95%, #FFA700 82.85%, #FCDC43 100%)',
            borderRadius: 'inherit'
          },
    }
}));

const ProfilePic = ({simple = false, defaultPic = null, ...props}) => {
    const classes = useStyles();
    const profile = useSelector((state) => ({...state.profilePicReducer.profilePic, ...state.userReducer.user}));
    
    return (
        <Grid item>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={
                    getMembershipIcon(profile?.membership_id,20)
                }
                className={clsx({[classes.badgeMembership]: !simple && profile?.membership_id !== MEMBERSHIPS.FREEMIUM, [classes.badge]: true})}
                invisible={simple}
            >
                <Avatar
                    alt="Person"
                    src={defaultPic || profile?.src}
                    {...props}
                    
                />
            </Badge>
        </Grid>
    );
};

export default ProfilePic;
