import React from 'react';
import CurrencyFormat from 'react-currency-format';
import clsx from 'clsx';

// @material-ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// assets
import useStyles from '../../../../assets/styles/jss/pages/checkout/checkout';

const OrderTotal = ({ orderTotal }) => {
    const classes = useStyles();

    return <Box className={classes.orderPriceDetails}>
        <Grid container justifyContent="space-between" alignItems='center'>
            <Grid item>
                <Typography variant="h5" className='font-weight-700'>Total a pagar:</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' className={clsx("text-orange-300", "font-weight-700", "font-size-22")}>
                    <CurrencyFormat value={orderTotal} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />
                </Typography>
            </Grid>
        </Grid>
    </Box>
};

export default OrderTotal;