import React from 'react';
import moment from 'moment'
import { useHistory } from 'react-router-dom';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

// components
import CourseCard from '../CourseCard/CourseCard';

// shared
import * as dateUtils from "./../../../../shared/utils/dateUtils";
import NothingFound from "./../../../../shared/components/NothingFound/NothingFound";

const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#049CB4"),
        fontWeight: "bold",
        textTransform: "capitalize",
        backgroundColor: "#00C1DE",
        '&:hover': {
            backgroundColor: "#049CB4",
        },
    },
}))(Button);


const OngoingCourses = (props) => {
    const history = useHistory();
    const { neoCourses, thirdPartyCourses, userIdNeo, user } = props;

    let neoLink = "";

    switch (user.ou) {
        case 'unid':
            neoLink = `//unid.neolms.com/class/show/`;
            break;
        case 'talisis':
            neoLink = `//talisisch.neolms.com/class/show/`;
            break;
        default: //uerre
            neoLink = `//talisis.neolms.com/class/show/`;
            break;
    }

    const handleGoToCatalog = () => {
        history.push("/");
    };

    const coursesInProgress = neoCourses.filter(c => {
        const start_date = moment(dateUtils.ISODateToString(c.start_date));
        return moment().isSameOrAfter(start_date);
    });

    return (
        <React.Fragment>
            {coursesInProgress.length > 0 || thirdPartyCourses.length > 0 ?
                <Grid container spacing={3}>
                    {
                        coursesInProgress.map(el => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={el.id}>
                                    <CourseCard
                                        {...el}
                                        progress={el.progress || el.progress_percentage}
                                        source={el.company_id || user.ouLabel}
                                        subject={el.register_id ? "":el.subject_description}
                                        to={el.register_id ? "course-s":(el.course_url ?? `${neoLink}${el.id}?user=${userIdNeo}`)}
                                        registerId={el.register_id}
                                        courseId={el.id}
                                        userId={user.person_id}
                                    />
                                </Grid>
                            )
                        })
                    }
                    {
                        thirdPartyCourses.map(el => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={el.id}>
                                    <CourseCard
                                        {...el}
                                        progress={el.progress || el.progress_percentage}
                                        source={el.source_name}
                                        subject={el.subject_description}
                                        to={`//${el.course_url.replace(/(^\w+:|^)\/\//, '')}`}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid> :
                <Box textAlign="center">
                    <NothingFound
                        caption="No cuentas con clases en progreso"
                        subcaption="Aquí encontrarás el contenido que tengas en progreso"
                        type="in-progress-courses" />
                    <BlueButton onClick={handleGoToCatalog}>Ver catálogo de cursos</BlueButton>
                </Box>
            }
        </React.Fragment>
    );
};

export default OngoingCourses;