import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generatePath } from "react-router";

// Material Components
import { Box, Grid, Icon, Link, Typography } from '@material-ui/core';

// Material Functions
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Shared components
import FilterChips from '../../../../../../design-system/components/Filters/FilterChips/FilterChips';
import LoaderContent from '../../../../../../shared/components/Loader/LoaderContent';
import NothingFound from '../../../../../../shared/components/NothingFound/NothingFound';

// Styles
import useStyles from '../../../../../../assets/styles/jss/pages/market/search/allResultStyle';


// Constants
import { TYPE_COURSE, TYPE_PROGRAM, TYPE_QUICKACCESS } from '../../../../../../shared/constants/searchTypes';
import { TAB_PROGRAM, TAB_COURSE, TAB_DIRECT_ACCESS } from '../../../../../../shared/constants/searchTabs';

// Path
import {PROGRAMA, CURSO_DETALLE} from '../../../../../../routes/paths';

// Functions
import objectIsEmpty from '../../../../../../shared/utils/objectIsEmpty';
import { getSessionCookie } from "../../../../../../shared/utils/Sessions";
import SlickCarousel from '../../../../../../shared/components/SlickCarousel/SlickCarousel';
import { SwiperSlide } from 'swiper/react';
import { getSourceBySourceAndCompany } from '../../../../../../design-system/utils/source';
import { eventData } from '../../../../../../shared/components/EventTracker/EventTracker';

// Lazy components
const LandSearchProgramCard = lazy(() => import('../../../../../../design-system/components/Cards/LandSearchProgramCard/LandSearchProgramCard'));
const LandSearchCourseCard = lazy(() => import('../../../../../../design-system/components/Cards/LandSearchCourseCard/LandSearchCourseCard'));
const CardQuickAccess = lazy(() => import('../../../../../../shared/components/Card/CardQuickAccess/CardQuickAccess'));

const NUM_COURSE = 20;
const NUM_PROGRAM = 20;

const getIndexTab = (tabName, tabs) => {
    const index = tabs.indexOf(tabName);
    return index < 0 ? 0 : index;
};

const AllResult = ({ data, filterSelected, types, showAll, dataNumberShow, onChangeTab, onClickDeleteFilterWithChip, onClickMixPanelCard, emptySearch, doingSearch, textIsSearching, dataTabs}) => {
    const history = useHistory();
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles({isResponsive});

    const [dataProcessed, setDataProcessed] = React.useState({});
    const [courses, setCourses] = React.useState([]);
    const [hasMoreCourses, setHasMoreCourses] = React.useState(true);
    const [programs, setPrograms] = React.useState([]);
    const [hasMorePrograms, setHasMorePrograms] = React.useState(true);
    const [numFoundData, setNumFounddData] = React.useState({
        [TYPE_PROGRAM]: 0,
        [TYPE_COURSE]: 0,
        [TYPE_QUICKACCESS]: 0,
    });    
    const [dataToChipList, setDataToChipList] = React.useState([]);

    // Functions
    const validateHasMoreCourses = (countCourses, totalCourses) => {
        if (totalCourses === countCourses) setHasMoreCourses(false);
    };

    const validateHasMorePrograms = (countPrograms, totalPrograms) => {
        if (totalPrograms === countPrograms) setHasMorePrograms(false);
    };
    
    const getMoreCourses = () => {
        const newArray = dataProcessed[TYPE_COURSE].list.slice(0, courses.length + NUM_COURSE);
        setCourses(newArray);
        validateHasMoreCourses(newArray.length, dataProcessed[TYPE_COURSE].length);
    };

    const getMorePrograms = () => {
        const newArray = dataProcessed[TYPE_PROGRAM].list.slice(0, programs.length + NUM_PROGRAM);
        setPrograms(newArray);
        validateHasMorePrograms(newArray.length, dataProcessed[TYPE_PROGRAM].length);
    };
    
    const getDataChipList = () => {
        let response = [];
        filterSelected.forEach(item => {
            if (!!item.selected.length){
                response = [...response, ...item.selected.map(item2 => ({...item2,  filterId: item.id}))];
            }
        });

        setDataToChipList(response);
    };

    // Hadles

    const handleClickQuickAccessCard = (params) => {
        history.push(params.url);
    };

    const handleClickProgramCard = (param) => {
        history.push(generatePath(PROGRAMA, {id: param.id}));
    };

    const handleClickCourseCard = (param) => {
        history.push(generatePath(CURSO_DETALLE, {id: param.id}));
    };

    const handleClickMixPanelQuickAccess  = (source_name, redirectTo) => () => {
        eventData['Current URL'] = window.location.href;
        onClickMixPanelCard(null, source_name, null, null, null, redirectTo);
    };

    const handleClickMixPanelProgram  = (source_name, programId, programName) => () => {
        eventData['Current URL'] = window.location.href;
        onClickMixPanelCard(null, source_name, null, programId, programName, null);
    };

    const handleClickMixPanelCourse  = (source_name, courseName, courseId) => () => {
        eventData['Current URL'] = window.location.href;
        onClickMixPanelCard(courseName, source_name, courseId, null, null, null);
    };

    React.useEffect(() => {
        const dataTemp = {...data};

        setNumFounddData({ [TYPE_PROGRAM]: dataTemp[TYPE_PROGRAM].length, [TYPE_COURSE]: dataTemp[TYPE_COURSE].length, [TYPE_QUICKACCESS]: dataTemp[TYPE_QUICKACCESS].length});
        setHasMoreCourses(true);

        const programLength = (showAll) ? dataTemp[TYPE_PROGRAM].length : (dataTemp[TYPE_PROGRAM].length > dataNumberShow[TYPE_PROGRAM] ? dataNumberShow[TYPE_PROGRAM] : dataTemp[TYPE_PROGRAM].length);
        const courseLength = (showAll) ? dataTemp[TYPE_COURSE].length : (dataTemp[TYPE_COURSE].length > dataNumberShow[TYPE_COURSE] ? dataNumberShow[TYPE_COURSE] : dataTemp[TYPE_COURSE].length);
        const quickAccessLength = (showAll) ? dataTemp[TYPE_QUICKACCESS].length : (dataTemp[TYPE_QUICKACCESS].length > dataNumberShow[TYPE_QUICKACCESS] ? dataNumberShow[TYPE_QUICKACCESS] : dataTemp[TYPE_QUICKACCESS].length);


        const arrayCourses = (showAll) ? dataTemp[TYPE_COURSE].list : dataTemp[TYPE_COURSE].list.slice(0, courseLength);
        const arrayPrograms = (showAll) ? dataTemp[TYPE_PROGRAM].list : dataTemp[TYPE_PROGRAM].list.slice(0, programLength);

        setDataProcessed({
            [TYPE_PROGRAM]: {    
                length: programLength,
                list: arrayPrograms,
            },
            [TYPE_COURSE]: {    
                length: courseLength,
                list: arrayCourses
            },
            [TYPE_QUICKACCESS]: {    
                length: quickAccessLength,
                list: (showAll) ? dataTemp[TYPE_QUICKACCESS].list : dataTemp[TYPE_QUICKACCESS].list.slice(0, quickAccessLength)
            },
        });

        // infiniti scroll manage
        const arrayCoursesTemp = arrayCourses.slice(0, NUM_COURSE);
        setCourses(arrayCoursesTemp);
        validateHasMoreCourses(arrayCoursesTemp.length, courseLength);
        const arrayProgramsTemp = arrayPrograms.slice(0, NUM_PROGRAM);
        setPrograms(arrayProgramsTemp);
        validateHasMorePrograms(arrayProgramsTemp.length, programLength);
    }, [data, showAll]);
    
    React.useEffect(() => {
        getDataChipList();
    }, [filterSelected]);

    React.useEffect(() => {
        getDataChipList();
    }, [filterSelected]);

    React.useEffect(() => {
        getDataChipList();
    }, [filterSelected]);

    return (
        <Box pb={'70px'}>
            {
                !!(numFoundData[TYPE_PROGRAM] + numFoundData[TYPE_COURSE] + numFoundData[TYPE_QUICKACCESS]) && 
                <>
                    {
                        !objectIsEmpty(dataProcessed) && (
                            (types.includes(TYPE_QUICKACCESS) && dataProcessed[TYPE_QUICKACCESS].length > 0) ||
                            (types.includes(TYPE_COURSE) && dataProcessed[TYPE_COURSE].length > 0) ||
                            (types.includes(TYPE_PROGRAM) && dataProcessed[TYPE_PROGRAM].length > 0)
                        ) && (!!dataToChipList.length) && 
                        <Box>
                            <Box>
                                <FilterChips data={filterSelected} dataList={dataToChipList} onClickDeleteFilter={onClickDeleteFilterWithChip} />
                            </Box>
                        </Box>
                    }
                    {
                        !objectIsEmpty(dataProcessed) && showAll && (
                            (types.includes(TYPE_QUICKACCESS) && dataProcessed[TYPE_QUICKACCESS].length === 0) ||
                            (types.includes(TYPE_COURSE) && dataProcessed[TYPE_COURSE].length === 0) ||
                            (types.includes(TYPE_PROGRAM) && dataProcessed[TYPE_PROGRAM].length === 0)
                        ) &&
                        <NothingFound caption={`No encontramos ${
                            types.includes(TYPE_QUICKACCESS) ? 'accesos directos' : (types.includes(TYPE_COURSE) ? 'cursos' : 'programas')
                        }${!!textIsSearching.trim().length ? ` para ${textIsSearching}` : '' }`} subcaption="Te recomendamos consultar las otras secciones, probar con términos de búsqueda diferentes y asegúrate de que todas las palabras estén escritas correctamente."/>
                    }
                    {
                        isLogged && types.includes(TYPE_QUICKACCESS) && !objectIsEmpty(dataProcessed) && dataProcessed[TYPE_QUICKACCESS].length > 0 && (
                            <Box className={classes.quickAccessContainer}>
                                <Typography variant="h4" className={classes.quickAccessLabel}>Rutas de accesos directos</Typography>
                                {
                                    (!isResponsive)  && 
                                    <Grid container className={classes.quickAccessListContainer}>
                                    {
                                        dataProcessed[TYPE_QUICKACCESS].list.map((quickAccess, index) => 
                                            <Grid item sm={6} md={4} key={quickAccess.name}>
                                                <Suspense fallback={<div></div>}>
                                                    <CardQuickAccess onClickMixPanelCard={handleClickMixPanelQuickAccess((quickAccess.source_name ?? 'LinkedIn Learning'), quickAccess.url)} name={quickAccess.name} url={quickAccess.url} onClick={handleClickQuickAccessCard}/>
                                                </Suspense>
                                            </Grid>)
                                    }
                                    </Grid>
                                }
                                {
                                    (isResponsive) && 
                                    <SlickCarousel length={dataProcessed[TYPE_QUICKACCESS].list.length} typeCard="normal" className={classes.quickAccessListContainer}>
                                    {
                                        dataProcessed[TYPE_QUICKACCESS].list.map((quickAccess) => 
                                        <Grid item sm={6} md={4} key={quickAccess.name}>
                                            <Suspense fallback={<div></div>}>
                                                <CardQuickAccess onClickMixPanelCard={handleClickMixPanelQuickAccess((quickAccess.source_name ?? 'LinkedIn Learning'), quickAccess.url)} name={quickAccess.name} url={quickAccess.url} onClick={handleClickQuickAccessCard}/>
                                            </Suspense>
                                        </Grid>)
                                    }
                                    </SlickCarousel>
                                }
                                {
                                    !showAll && numFoundData[TYPE_QUICKACCESS] > dataNumberShow[TYPE_QUICKACCESS] &&
                                    <Box className={classes.showAllQuickAccessContainer}>
                                        <Link variant='body1' underline='none' component="button" className={`semi-bold ${classes.showAllQuickAccessLink}`} onClick={() => onChangeTab(getIndexTab(TAB_DIRECT_ACCESS, dataTabs))}>
                                            <Box display='flex' alignItems={'center'}>
                                                Ver todos los accesos directos <Icon className={`ri-arrow-right-s-line font-size-24 ${classes.showAllQuickAccessLink}`}/>
                                            </Box>
                                        </Link>
                                    </Box>
                                }
                            </Box>
                        )
                    }

                    {
                        types.includes(TYPE_PROGRAM) && !objectIsEmpty(dataProcessed) && dataProcessed[TYPE_PROGRAM].length > 0 && (

                            <Box className={classes.programContainer}>
                                <Typography variant="h4" className={classes.programLabel}>Programas relacionados</Typography>
                                    <InfiniteScroll
                                        style={{overflow: 'hidden'}}
                                        dataLength={programs.length}
                                        next={getMorePrograms}
                                        hasMore={hasMorePrograms}
                                        loader={
                                            <Grid container direction='row'>
                                                <Grid item xs={12} style={{marginTop:'30px',marginBottom:'16px'}}>
                                                    <LoaderContent loading={true}></LoaderContent>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <p className='text-center'>cargando datos...</p> 
                                                </Grid>
                                            </Grid>
                                    }>
                                    {
                                        (!isResponsive || showAll) && 
                                        <Grid container className={classes.programListContainer} spacing={isResponsive ? 1 : 3}>
                                            {
                                                programs.map(program => <Grid item sm={6} md={4} key={program.id}>
                                                    <Suspense fallback={<div></div>}>
                                                        <LandSearchProgramCard
                                                            onClickMixPanelCard={handleClickMixPanelProgram((program.source_name ?? 'LinkedIn Learning'), program?.id, program.level_id === "BA" ? "Bachillerato Online" : program?.name)}
                                                            userId={0}
                                                            programId={program.id}
                                                            onClick={handleClickProgramCard}
                                                            backgroundImage={(showAll && isResponsive) ? program.images["itemlist"] : (isResponsive ? program.images["movil"] : program.images["default"])}
                                                            membershipIdList={program.membresia_id}
                                                            levelId={program.level_id}
                                                            sourceId={getSourceBySourceAndCompany(program.source, program.company)}
                                                            name={program?.name}
                                                            content={{
                                                                price: {
                                                                    number: program.price,
                                                                    listNumber: program.price,
                                                                },
                                                                showLabel: program.showLabel,
                                                                showPrice: program.showPrice,
                                                                labelToShow: program.labelToShow,
                                                                typeLabel: program.typeLabel,
                                                            }}
                                                            isListItemCard={isResponsive && showAll}
                                                        />
                                                    </Suspense>
                                                </Grid>)
                                            }
                                        </Grid>
                                    }

                                    {
                                        (isResponsive && !showAll) && 
                                        <SlickCarousel length={programs.length} typeCard="normal">
                                        {
                                            programs.map(program => 
                                                <SwiperSlide className={classes.card} key={program.id}>
                                                    <Suspense fallback={<div></div>}>
                                                        <LandSearchProgramCard
                                                            onClickMixPanelCard={handleClickMixPanelProgram((program.source_name ?? 'LinkedIn Learning'), program?.id, program.level_id === "BA" ? "Bachillerato Online" : program?.name)}
                                                            userId={0}
                                                            programId={program.id}
                                                            onClick={handleClickProgramCard}
                                                            backgroundImage={(showAll && isResponsive) ? program.images["itemlist"] : (isResponsive ? program.images["movil"] : program.images["default"])}
                                                            membershipIdList={program.membresia_id}
                                                            levelId={program.level_id}
                                                            sourceId={getSourceBySourceAndCompany(program.source, program.company)}
                                                            name={program?.name}
                                                            content={{
                                                                price: {
                                                                    number: program.price,
                                                                    listNumber: program.price,
                                                                },
                                                                showLabel: program.showLabel,
                                                                showPrice: program.showPrice,
                                                                labelToShow: program.labelToShow,
                                                                typeLabel: program.typeLabel,
                                                            }}
                                                        />
                                                    </Suspense>
                                                </SwiperSlide>)
                                        }
                                        </SlickCarousel>
                                    }
                                </InfiniteScroll>
                                {
                                    !showAll && numFoundData[TYPE_PROGRAM] > dataNumberShow[TYPE_PROGRAM] && 
                                    <Box className={classes.showAllProgramContainer}>
                                        <Link variant='body1' underline='none' component="button" className={`semi-bold ${classes.showAllProgramLink}`} onClick={() => onChangeTab(getIndexTab(TAB_PROGRAM, dataTabs))}>
                                            <Box display='flex' alignItems={'center'}>
                                                Ver todos los programas <Icon className={`ri-arrow-right-s-line font-size-24 ${classes.showAllProgramLink}`}/>
                                            </Box>
                                        </Link>
                                    </Box>
                                }
                            </Box>
                        )
                    }
                    {
                        types.includes(TYPE_COURSE) && !objectIsEmpty(dataProcessed) && dataProcessed[TYPE_COURSE].length > 0 && (

                            <Box className={classes.courseContainer}>
                                <Typography variant="h4" className={classes.courseLabel}>Cursos relacionados</Typography>
                                <InfiniteScroll
                                    style={{overflow: 'hidden'}}
                                    dataLength={courses.length}
                                    next={getMoreCourses}
                                    hasMore={hasMoreCourses}
                                    loader={
                                        <Grid container direction='row'>
                                            <Grid item xs={12} style={{marginTop:'30px',marginBottom:'16px'}}>
                                                <LoaderContent loading={true}></LoaderContent>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className='text-center'>cargando datos...</p> 
                                            </Grid>
                                        </Grid>
                                }>
                                    {
                                        (!isResponsive || showAll) && 
                                        <Grid container spacing={isResponsive ? 1 : 3} className={classes.coursesListContainer}>
                                        {
                                            courses.map(course => 
                                                <Grid item sm={6} md={4} key={course.id}>
                                                    <Suspense fallback={<div></div>}>
                                                        <LandSearchCourseCard 
                                                            onClickMixPanelCard={handleClickMixPanelCourse((course.source_name ?? 'LinkedIn Learning'), course.name, course.id)}
                                                            courseId={course.id}
                                                            userId={0}
                                                            onClick={handleClickCourseCard}
                                                            media={{
                                                                backgroundImage : (showAll && isResponsive) ? course.images["itemlist"] : (isResponsive ? course.images["movil"] : course.images["default"]),
                                                                ratingData : {},
                                                                sourceIdLogo: getSourceBySourceAndCompany(course.source, course.company),
                                                            }}
                                                            content={{
                                                                hasMembership: course.is_included,
                                                                courseName: course.name, 
                                                                price: {
                                                                    number: course.price,
                                                                    listNumber: course.list_price,
                                                                },
                                                                showLabel: course.showLabel,
                                                                showPrice: course.showPrice,
                                                                labelToShow: course.labelToShow,
                                                                typeLabel: course.typeLabel,
                                                            }}
                                                            isListItemCard={showAll && isResponsive}
                                                            />
                                                    </Suspense>
                                                </Grid>
                                            )
                                        }
                                        </Grid>
                                    }

                                    {
                                        isResponsive && !showAll && 
                                        <SlickCarousel length={courses.length} typeCard="normal">
                                        {
                                            courses.map(course => 
                                                <SwiperSlide className={classes.card} key={course.id}>
                                                    <Suspense fallback={<div></div>}>
                                                        <LandSearchCourseCard 
                                                            onClickMixPanelCard={handleClickMixPanelCourse((course.source_name ?? 'LinkedIn Learning'), course.name, course.id)}
                                                            courseId={course.id}
                                                            userId={0}
                                                            onClick={handleClickCourseCard}
                                                            media={{
                                                                backgroundImage : isResponsive ? course.images["movil"] : course.images["default"],
                                                                ratingData : {},
                                                                sourceIdLogo: getSourceBySourceAndCompany(course.source, course.company),
                                                            }}
                                                            content={{
                                                                hasMembership: course.is_included,
                                                                courseName: course.name,
                                                                price: {
                                                                    number: course.price,
                                                                    listNumber: course.list_price,
                                                                },
                                                                showLabel: course.showLabel,
                                                                showPrice: course.showPrice,
                                                                labelToShow: course.labelToShow,
                                                                typeLabel: course.typeLabel,
                                                            }}
                                                            />
                                                    </Suspense>
                                                </SwiperSlide>
                                            )
                                        }
                                        </SlickCarousel>
                                    }
                                    
                                </InfiniteScroll>
                                {
                                    !showAll && numFoundData[TYPE_COURSE] > dataNumberShow[TYPE_COURSE] && 
                                    <Box className={classes.showAllCourseContainer}>
                                        <Link variant='body1' underline='none' component="button" className={`semi-bold ${classes.showAllCourseLink}`} onClick={() => onChangeTab(getIndexTab(TAB_COURSE, dataTabs))}>
                                            <Box display='flex' alignItems={'center'}>
                                                Ver todos los cursos <Icon className={`ri-arrow-right-s-line font-size-24 ${classes.showAllCourseLink}`}/>
                                            </Box>
                                        </Link>
                                    </Box>
                                }
                            </Box>
                        )
                    }
                </>
            }
            {
                !(numFoundData[TYPE_PROGRAM] + numFoundData[TYPE_COURSE] + numFoundData[TYPE_QUICKACCESS]) && !emptySearch && !doingSearch &&
                <NothingFound caption={`No encontramos resultado.`} subcaption="Revisa si las palabras están escritas correctamente o prueba una nueva búsqueda"/>
            }
        </Box>
    );
};

AllResult.propTypes = {
    data: PropTypes.object.isRequired,
    filterSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
    types: PropTypes.arrayOf(PropTypes.oneOf([TYPE_COURSE, TYPE_PROGRAM, TYPE_QUICKACCESS])).isRequired,
    showAll: PropTypes.bool,
    dataNumberShow: PropTypes.object,
    onChangeTab: PropTypes.func,
    onClickDeleteFilterWithChip: PropTypes.func.isRequired,
    onClickMixPanelCard: PropTypes.func,
    emptySearch: PropTypes.bool,
    doingSearch: PropTypes.bool,
    textIsSearching: PropTypes.string,
    dataTabs: PropTypes.arrayOf(PropTypes.string),
};


export default AllResult;