import React from 'react'

// @material-ui
import { Box, CircularProgress, Grid, Modal, Typography } from '@material-ui/core'

// assets
import useStyles from '../../../../../../assets/styles/jss/pages/checkout/checkout';

const PaymentProcessingModal = ({ openModal }) => {
    const classes = useStyles();

    return (
        <Modal
            open={openModal}
            className={classes.paymentProcessingModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            disableEscapeKeyDown
        >
            <Box sx={{ maxWidth: "596px", backgroundColor: '#1C1F24', textAlign: 'center', paddingX: '45px', paddingY: '60px', borderRadius: '8px' }} className={classes.paymentProcessingModalInside}>
                <Grid container direction="row">
                    <Grid item xs={12} className="mb-2">
                        <Typography variant='h4'>
                            Estamos procesando tu compra
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                        <Typography variant='body1' className="text-gray-100">
                            No cierres la ventana...
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
};

export default PaymentProcessingModal;
