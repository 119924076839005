import React, { Component } from 'react';

// @material-ui/core
import Button from '@material-ui/core/Button';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

// @redux
import { connect } from "react-redux"

// services
import * as meService from '../../../../services/FeedService'
import { KARDEX_ENDPOINT_UERRE, KARDEX_ENDPOINT_UNID } from '../../../../services/endpoints'

// @dependencies
import WindowOpener from 'react-window-opener'
import moment from 'moment';

import { log } from '../../../../shared/utils/console.js'
//styled
import styled from 'styled-components';
//SDK
import { FeedService } from '@sdk-point/talisis'; 

const FeedServiceSDK = new FeedService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const KaredexContainer = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`

const url = {
    unid: KARDEX_ENDPOINT_UNID,
    uerre: KARDEX_ENDPOINT_UERRE
}

class KardexButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            encryptData: '',
        }
    }

    async componentDidMount() {
        this.generateKardexData()
    }

    generateKardexData = async () => {
        const { student_id, ou } = this.props.user;
        const date = moment().format('YYYY-MM-DD HH:mm:ss');

        const studentData = {
            Fecha: date,
            Marca: ou.toUpperCase(),
            Tipo: "Alumno",
            Tabid: ou === "uerre" ? "00421" : "00456",
            ID: student_id
        }

        try {
            const encryptData = await FeedServiceSDK.encryptData(studentData);
            this.setState({ encryptData: encryptData.data, ou: ou })
        } catch (error) {
            log('error', error);
        }
    }

    childResponse = (err, res) => {
        if (err) {
            log(res, 'err')
        }
        log(res, 'res')
    }

    render() {
        return (
            <KaredexContainer>
                <WindowOpener url={`${url[this.props.user.ou]}?p=${this.state.encryptData}`} bridge={this.childResponse} width={600}>
                    <Button color="primary" variant="contained" startIcon={<DescriptionOutlinedIcon />}>
                        Ver Kardex
                    </Button>
                </WindowOpener>
            </KaredexContainer>
        );
    }
}

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(KardexButton);