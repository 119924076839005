export const PORTAL_ID = 23623758
export const PROGAM_NAME = {
    //"LIC_ADM_EMP":'Licenciatura En Administración Empresarial',
    "LIC_ADM_EMP":'LIC-ADMA-18',
    //"LIC_ADM_EMPTUC":'Licenciatura En Administración De Empresas Turísticas',
    "LIC_ADM_EMPTUC":'LIC-ADTA-18',
    //"LIC_CON_FIN":'Licenciatura En Contabilidad Y Finanzas',
    "LIC_CON_FIN":'LIC-COFA-18',
    //"LIC_DER_CIE":'Licenciatura En Derecho Y Ciencias Jurídicas',     
    "LIC_DER_CIE":'LIC-DECA-18',  
    //"LIC_EDU":'Licenciatura En Educación',
    "LIC_EDU":'LIC-EDTA-18',
    //"LIC_MER_EST":'Licenciatura En Mercadotecnia Estratégica',
    "LIC_MER_EST":'LIC-MEEA-18',
    //"LIC_ING_SOFT_SIS":'Licenciatura En Ingeniería De Software Y Sistemas Computacionales',
    "LIC_ING_SOFT_SIS":'LIC-SYSA-18',
    //"MA_ADM_NEG_FIN_INT":'Maestría en Administración de Negocios con acentuación en Finanzas Internacionales',
    "MA_ADM_NEG_FIN_INT":'M1A-ADFI-08',
    //"MA_ADM_NEG_GEN_GLOB":'Maestría en Administración de Negocios con acentuación en Gerencia Global',
    "MA_ADM_NEG_GEN_GLOB":'M1A-ADGG-08',
    //"MA_ADM_NEG_MER_INT":'Maestría en Administración de Negocios con acentuación en Mercadotecnia Internacional',  
    "MA_ADM_NEG_MER_INT":'M1A-ADMI-08',
    //"MA_MER_DIG":'Maestría En Mercadotecnia Y Medios Digitales',
    "MA_MER_DIG":'MAE-MERA-22',
    //"MA_TEC_INF_COM":'Maestría En Tecnologías De La Información Y Comunicación',
    "MA_TEC_INF_COM":'MAE-TEIA-22',
    //"MA_EDU":'Maestría En Educación',
    "MA_EDU":'MAE-EDUA-22',      
    //"MA_JUI_ORA":'Maestría En Juicios Orales',
    "MA_JUI_ORA":'MAE-JUIA-18',
    //"MA_ADM_NEG":'Maestría En Administración De Negocios'
    "MA_ADM_NEG":'MAE-MADA-22'         
};

export const ID_FORM = {
    [PROGAM_NAME.LIC_ADM_EMP]:'7951d3e4-2099-4832-9be7-9bb06495c0ff',
    [PROGAM_NAME.LIC_ADM_EMPTUC]:'9e743484-dbac-4a37-b1cb-86e2737aa6ac',
    [PROGAM_NAME.LIC_CON_FIN]:'8185bd85-2edd-4198-8e6b-fcf3540a12af',
    [PROGAM_NAME.LIC_DER_CIE]:'6a811157-ef16-4c3b-a1b7-049415f07f08',
    [PROGAM_NAME.LIC_EDU]:'ae0e59e7-c71f-43c8-897d-3528ba23b716',
    [PROGAM_NAME.LIC_MER_EST]:'6541e938-a82b-4552-8ec0-8761dd1c75ff',
    [PROGAM_NAME.LIC_ING_SOFT_SIS]:'d0cb042d-c2ad-47be-954d-472d2ffab463',
    [PROGAM_NAME.MA_ADM_NEG_FIN_INT]:'4719115b-23d0-4fb1-8998-225dbad917d0',
    [PROGAM_NAME.MA_ADM_NEG_GEN_GLOB]:'10f080e3-1a75-4b90-8aae-6c58df98d778',
    [PROGAM_NAME.MA_ADM_NEG_MER_INT]:'027ba84b-13f2-4caf-a3ae-73647981f712',
    [PROGAM_NAME.MA_MER_DIG]:'b2cc09ad-d795-4780-9c52-67d01c9b0042',
    [PROGAM_NAME.MA_TEC_INF_COM]:'c1afdfa1-04fc-4ea1-b5c7-47865739f984',
    [PROGAM_NAME.MA_EDU]:'77d02538-82fa-48c0-a80a-3407212f90a4',
    [PROGAM_NAME.MA_JUI_ORA]:'6f5364b8-687f-443c-8a91-2378fa691e2f',
    [PROGAM_NAME.MA_ADM_NEG]:'5b7c81a1-002c-4fd2-a667-fff9af386073',
}