import * as cartTypes from '../types/cartTypes';

const INITIAL_STATE = {
    cart: {},
    cartProducts: [],
    addons: [],
    selectedAddon: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case cartTypes.APPLY_CART:
            return {
                ...state,
                cart: action.payload
            };

        case cartTypes.APPLY_CART_PRODUCTS:
            return {
                ...state,
                cartProducts: action.payload
            };

        case cartTypes.APPLY_ADDONS:
            return {
                ...state,
                addons: action.payload
            };

        case cartTypes.APPLY_SELECTED_ADDON:
            return {
                ...state,
                selectedAddon: action.payload
            };

        default:
            return state;
    }
}