import { makeStyles } from '@material-ui/core/styles';
import card_voxy_hh from '../../../../../assets/images/card_voxy_hh.png';
export default makeStyles((theme) => ({
    card: {
        padding: "12px",
        maxWidth: "fit-content",
    },
    card_content:{
        width: "286px",
        minHeight: "362px",
        maxHeight: "362px",
        backgroundColor: "transparent",
        "&:hover": {
            cursor:"pointer",
            "& $continuar_content": {
                opacity: "1",
                border: "1px solid " + theme.palette.primary.main,
                backgroundColor: theme.palette.primary.main,
            },
        },
        
    },
    rating:{
        zIndex: "5",
        fontSize: props => props.isDetail == true ? "22px" : "14px",
    },
    schedule:{
        display: 'flex',
        justifyContent: 'space-between',
        '& span':{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 400
        }
    },
    chip:{
        height: 20
    },
    cardActionArea:{
        
    },
    focusHighlight: {
       
    },
    title:{
        "@media (max-width: 1250px)": {
            marginLeft: "5px",
        },
    },
    continuar_content:{
        opacity: "0",
        width: "108px",
        minWidth: "142px",
        border: "1px solid #FFFFFF",
        paddingLeft: "16px",
        position: "absolute",
        paddingRight: "16px",
        zIndex: "10",
        left: "0",
        right: "0",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        top: "0",
        bottom: "0",
        height: "40px",
        textAlign: "center",
        borderRadius: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    continuar_text:{
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
    },
    hover_carousel:{
        '&:hover':{
            '& h4':{
                '& button':{
                    background: "transparent",
                    '& .subtitle':  {
                        position: 'static !important',
                        transition: '0.35s ease-in-out !important',
                        transform: "translate3d(4px, 0px, 0px) !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                    },
                    '& .MuiButton-endIcon':{
                        color: "#26C5FB !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        transform: "translate3d(0px, 0px, 0px) scale(1) !important",
                        transition: '0.4s ease-in-out opacity 0.2s linear !important',
                    },
                },  
            },
        },
        "@media (max-width: 768px)": {
            '& h4':{
                '& button':{
                    background: "transparent",
                    '& .subtitle':  {
                        color: "#26C5FB !important",
                        position: 'static !important',
                        transition: '0.35s ease-in-out !important',
                        transform: "translate3d(4px, 0px, 0px) !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        minWidth: "62px",
                    } ,
                    '& .MuiButton-endIcon':{
                        color: "#26C5FB !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        transform: "translate3d(0px, 0px, 0px) scale(1) !important",
                        transition: '0.4s ease-in-out opacity 0.2s linear !important',
                    },
                },  
            },
        }
    },
    noCoursesContainer: {
        padding: 64,
        textAlign: 'center'
    },
    titleNoCourses: {
        marginBottom: '16px'
    },
    btnNoCourses: {
        marginTop: '16px',
        textTransform: 'none'
    },
    noClassPlaceholder: {
        marginBottom: '24px'
    },
    modal: {
        // backgroundColor: theme.palette.colors.fillStroke[400],
        // borderRadius: '4px',
        // boxShadow: theme.shadows[5],
        // height: 'calc(40vw + 8%)',
        margin: '0 auto',
        // top: '10% !important',
        // verticalAlign: 'middle',
        // maxWidth: '504px',
    },
    dialogContent: {
        padding: '40px',
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    btnGoToAlly: {
        width: '170px',
        height:' 80px',
        backgroundColor: theme.palette.colors.fillStroke[300]
    },
    errorText: {
        color: theme.palette.colors.error[300]
    },
    errorButtons: {
        borderRadius: '8px',
        width: '100%',
        textTransform: 'none'
    },
    errorButtonClose: {
        borderRadius: '8px',
        width: '180px',
        textTransform: 'none'
    },
    titlesin:{
      paddingLeft:'0px'
    },
    card_completed:{
        height: '26px',
        position: 'absolute',
        background: '#009256',
        zIndex: '10000',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
    },
    boxNotFoundRoot:{
        [theme.breakpoints.down('md')]:{
            marginBottom: '60px'
        }
    },
    imgStyle:{
        width: '45%',
        [theme.breakpoints.down('xs')]:{
            width: '100%'
        }
    },
    boxImg:{
        padding: '8px',
        flexGrow: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]:{
            display: 'flex',
            justifyContent: 'center'
        }
    },
    boxButtonNeo:{
        [theme.breakpoints.down('xs')]:{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '8px'
        }
    },
    closeMembershipAlertButton: {
        letterSpacing: '2px',
        fontSize: '10px',
        marginTop: '-14%',
    },
    HH_online_card: {
        backgroundImage: `url(${card_voxy_hh})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "100%",
        height: "315px",
        width: "288px",
        minHeight: "362px",
        maxHeight: "362px",
        position: "relative",
    },
    HH_online_card_content:{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "24px 16px",
        gap: 24,
    },
    HH_online_card_button_container: {
        position: "absolute",
        bottom: 0,
        left: 0,
    },
    HH_online_card_button:{
        color: theme.palette.colors.grey[100],
        width: "85% !important",
    },
    HH_online_dialog: {
        textAlign: 'center',
    },
    HH_online_dialog_message_error: {
        flex: "0 0 auto",
        margin: 0,
        padding: "16px 24px",
        "& .HH_online_dialog_img_error": {
            width: "45%"
        }
    }
}));