import React from "react";
import  {TextField} from '@material-ui/core';

const TextFieldDefault = (props) => {
    const styleDisplay= (props.visibility) ? props.visibility:'block';
    return (
        <TextField 
            {...props}
            margin="dense"
            variant="outlined"
            fullWidth
            style={{display:styleDisplay}}
            InputProps = {{readOnly:(props.readOnly) ? props.readOnly:false }}
        /> 
    )
}

export default TextFieldDefault;

