import {nameSource} from '../../../src/constants/infoSource'

const CHIP_ACTIVE = '#2C3236';
const CHIP_DESACTIVE = '#111111'
let FILTER_TODO = [{id:0, label:'Todos', active: CHIP_ACTIVE}];
const TYPE_NUMBER = 'number'
const UERRE = 'UERRE';
const U_ERRE = 'U-ERRE';
const UNID = 'UNID'

export const getCompanyNoDuplicate = (courses) => {
    const COMPANY_SOURCE = courses.map((course)=>{
        if(course.company_id!==null){
            return course.company_id
        } else {
            return course.source_id
        }
    })
    const COM_SOUR_NO_DUPLICADO = new Set(COMPANY_SOURCE);
    let RESULT_COM_SOUR_NO_DUPLICADO=[...COM_SOUR_NO_DUPLICADO];
    
    const OBJE_SOURCE_COMPANY = RESULT_COM_SOUR_NO_DUPLICADO.map(data=>{
        return {id:data,label:(typeof data === TYPE_NUMBER) ? nameSource(data):data, active: CHIP_DESACTIVE}
    })

   const INDEX_UNID = OBJE_SOURCE_COMPANY.findIndex(obj=> obj.label===UNID);
    if(INDEX_UNID!==-1) {
     OBJE_SOURCE_COMPANY.splice(INDEX_UNID, 1);
     OBJE_SOURCE_COMPANY.unshift({id:UNID, label:UNID, active: CHIP_DESACTIVE})
   }

   const INDEX_U_ERRE = OBJE_SOURCE_COMPANY.findIndex(obj=> obj.label===U_ERRE);
   if(INDEX_U_ERRE!==-1){
     OBJE_SOURCE_COMPANY.splice(INDEX_U_ERRE, 1);
   }

   const INDEX_UERRE = OBJE_SOURCE_COMPANY.findIndex(obj=> obj.label===UERRE);
   if(INDEX_UERRE!==-1) {
    OBJE_SOURCE_COMPANY.splice(INDEX_UERRE, 1);
    OBJE_SOURCE_COMPANY.unshift({id:UERRE, label:UERRE, active: CHIP_DESACTIVE})
   }
   return  [...FILTER_TODO, ...OBJE_SOURCE_COMPANY]
}

export const newFilterByCompany = (filters, filterSelected) => {
    const NUEVO_FILTER = filters.map( filter=>{
        return {id:filter.id, label:filter.label, active:(filter.id === filterSelected.id) ? CHIP_ACTIVE: CHIP_DESACTIVE}
    });
    return NUEVO_FILTER;
}