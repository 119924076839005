import {COMPANIES, SOURCES} from '../constants/sources';

const uerre_images_placeholder = [
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_1.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_2.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_3.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_4.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/uerre/uerre_5.jpg",
];

const unid_images_placeholder = [
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_1.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_2.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_3.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_4.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_5.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_6.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_7.jpg",
    "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/unid/unid_8.jpg",
];

const aliados_images_placeholder = ({
    [SOURCES.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_coursera.jpg",
    [SOURCES.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_linkedin.jpg",
    [SOURCES.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_google.jpg",
    [SOURCES.CREHANA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_crehana.jpg",
    [SOURCES.HUBSPOT]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_hubspot.jpg",
    [SOURCES.IE_UNIVERSITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_ieuniversity.jpg",
    [SOURCES.AWS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default_aws.jpg",
});

const aliados_images_placeholder_itemlist = ({
    [SOURCES.COURSERA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/ITEMLIST_PLACEHOLDER/itemlist_coursera.jpg",
    [SOURCES.LINKEDIN_LEARNING]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/ITEMLIST_PLACEHOLDER/itemlist_linkedin.jpg",
    [SOURCES.GOOGLE]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/ITEMLIST_PLACEHOLDER/itemlist_google.jpg",
    [SOURCES.CREHANA]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/ITEMLIST_PLACEHOLDER/itemlist_crehana.jpg",
    [SOURCES.HUBSPOT]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/ITEMLIST_PLACEHOLDER/itemlist_hubspot.jpg",
    [SOURCES.IE_UNIVERSITY]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/ITEMLIST_PLACEHOLDER/itemlist_ieuniversity.jpg",
    [SOURCES.AWS]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/ITEMLIST_PLACEHOLDER/itemlist_aws.jpg",
});

const programs_images_placeholder = ({
    "licenciatura": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Licenciatura.png",
    "bachillerato": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Bachillerato.png",
    "maestria": "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/UNID_Maestria.png",
});

const PROGRAMS_PLACEHOLDER = {
    ["M1A-ADGG-08"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/uerre/MBA+Gerencia+Global.png",
    ["M1A-ADFI-08"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/uerre/MBA+Finanzas+Internacionales.png",
    ["M1A-ADMI-08"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/uerre/MBA+Mercadotecnia+Internacional.png",
    ["LIC-ADMA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+Administraci%C3%B3n+Empresarial.png",
    ["LIC-ADTA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+Administraci%C3%B3n+de+Empresas+Tur%C3%ADsticas.png",
    ["LIC-EDTA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+en+Educaci%C3%B3n.png",
    ["LIC-SYSA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+en+Ingenier%C3%ADa+de+Software+y+Sistemas+Computacionales.png",
    ["LIC-MEEA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+en+Mercadotecnia+Estrat%C3%A9gica.png",
    ["LIC-COFA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.en+Contabilidad+y+Finanzas.png",
    ["LIC-DECA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.en+Derecho.png",
    ["MAE-JUIA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.++En+Juicios+Orales.png",
    ["MAE-MADA-22"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.+En+Administraci%C3%B3n+de+Negocios.png",
    ["MAE-EDUA-22"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.+En+Educaci%C3%B3n.png",
    ["MAE-MERA-22"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.+Mercadotecnia+en+Medios+Digitales.png",
    ["MAE-TEIA-22"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.+Tecnolog%C3%ADas+de+Informaci%C3%B3n+y+Comunicaci%C3%B3n.png",
    ["B27-BACA-19O"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Bachillerato.png",
};

const PROGRAMS_PLACEHOLDER_MOVIL = {
    ["M1A-ADGG-08"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/uerre/MBA+Gerencia+Global_movil.png",
    ["M1A-ADFI-08"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/uerre/MBA+Finanzas+Internacionales_movil.png",
    ["M1A-ADMI-08"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/uerre/MBA+Mercadotecnia+Internacional_movil.png",
    ["LIC-ADMA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+Administraci%C3%B3n+Empresarial_movil.png",
    ["LIC-ADTA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+Administraci%C3%B3n+de+Empresas+Tur%C3%ADsticas_movil.png",
    ["LIC-EDTA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+en+Educaci%C3%B3n_movil.png",
    ["LIC-SYSA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+en+Ingenier%C3%ADa+de+Software+y+Sistemas+Computacionales_movil.png",
    ["LIC-MEEA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.+en+Mercadotecnia+Estrat%C3%A9gica_movil.png",
    ["LIC-COFA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.en+Contabilidad+y+Finanzas_movil.png",
    ["LIC-DECA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Lic.en+Derecho_movil.png",
    ["MAE-JUIA-18"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.++En+Juicios+Orales_movil.png",
    ["MAE-MADA-22"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.+En+Administraci%C3%B3n+de+Negocios_movil.png",
    ["MAE-EDUA-22"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.+En+Educaci%C3%B3n_movil.png",
    ["MAE-MERA-22"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.+Mercadotecnia+en+Medios+Digitales_movil.png",
    ["MAE-TEIA-22"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Mtr.+Tecnolog%C3%ADas+de+Informaci%C3%B3n+y+Comunicaci%C3%B3n_movil.png",
    ["B27-BACA-19O"]: "https://point-files.s3.us-west-2.amazonaws.com/market-place/programas/cards/unid/Bachillerato_movil.png",
};

const image_default_placeholder = "https://point-files.s3.us-west-2.amazonaws.com/market-place/courses/DEFAULT_PLACEHOLDER/default.jpg";

const getGeneric = () => {
    return image_default_placeholder;
}
export const convertSourceToImageResource = (company_id, source_id, isItemList = false) => {
    if (source_id == SOURCES.BANNER || source_id == SOURCES.UERRE || source_id == SOURCES.UNID || source_id == SOURCES.HH) {
        if (company_id == COMPANIES.UERRE || source_id == SOURCES.UERRE){
            return uerre_images_placeholder[Math.floor(Math.random() * uerre_images_placeholder.length)];
        } 
        if (company_id == COMPANIES.UNID|| source_id == SOURCES.UNID){
            return unid_images_placeholder[Math.floor(Math.random() * unid_images_placeholder.length)];
        } 
        if (company_id == COMPANIES.HH|| source_id == SOURCES.HH){
            return  getGeneric();
        }
    }else{
        const tempImage = isItemList ? aliados_images_placeholder_itemlist[source_id] : aliados_images_placeholder[source_id];

        if (tempImage){
            return tempImage;
        }else{
            return image_default_placeholder;
        }
        
    }
};

export const getProgramPlaceholderByType = (type) => {
    return programs_images_placeholder[type];
};

export const getProgramPlaceholderByThirdPartyId = (thirdPartyId, isResponsive = false) => {
    if (isResponsive) return PROGRAMS_PLACEHOLDER_MOVIL[thirdPartyId];
    return PROGRAMS_PLACEHOLDER[thirdPartyId];
};