import React from 'react';

// @mui
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
    root: {
        height: '397px',
        margin: '32px auto 0',
        maxWidth: '707px',
    },
    subLabel: {
        margin: '0 auto',
        width: '65%',
    },
    iframe: {
        borderRadius: '4px',
        height: '100%',
        width: '100%',
    }
}));

const GenericBrandLandingPageVideo = (props) => {
    const { className } = props;
    const classes = useStyles();
    
    return (
        <React.Fragment>
            <Typography variant="h2" color="textPrimary">Conoce nuestra metodología</Typography>
            <Typography component="div" variant="body2" color="textPrimary" className={`${classes.subLabel} mt-3`}>El éxito de nuestra metodología radica en la personalización de nuestros cursos, basada en el análisis de necesidades de cada uno de nuestros alumnos.</Typography>
            <Box className={`${classes.root} ${className}`}>
                <iframe className={classes.iframe} src="https://www.youtube.com/embed/k9_2s7jNX5M" title="¿Cómo funciona Harmon Hall Online?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Box>
        </React.Fragment>
    )
};

export default GenericBrandLandingPageVideo;