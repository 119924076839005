import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Card, CardContent, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as paths from '../../../routes/paths'

import { hasRole } from '../../../services/RoleService';
import { ROLE_PROMOTOR } from '../../../shared/constants/roles';


const useStyles = makeStyles((theme) => ({
    root: {
        width: 276,
        padding: 16
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            width: '100%'
        }
    }
}));

const ReferralPlanMssg = () => {
  const classes = useStyles();

    if(hasRole(ROLE_PROMOTOR)){
        return null;
    }

    return (
        <Box className={classes.root}>
            <Card elevation={3}>
                <CardContent>
                    <Typography variant='body1' align='center'>
                        🎁<br/>
                        Recomienda <span className='semi-bold'>Talisis</span><br/> 
                        y gana <span className='text-blue-300 semi-bold'>$500MXN</span><br/>
                        en Amazon
                    </Typography>
                    <Box marginTop={1.5} textAlign='center'>
                        <Button variant='outlined' color="primary" size='small' component={RouterLink} to={paths.REFIERE_GANA_PATH}>Conoce cómo</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default ReferralPlanMssg;