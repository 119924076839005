import React from 'react'

import SummaryStep from '../../SummaryStep/SummaryStep'
import StudentInfo from '../../StudentInfo/StudentInfo'

import { getCheckoutCookie } from '../../../../../../shared/utils/checkoutSession-legacy.js'
import { userIsLogged } from '../../../../../../shared/utils/Sessions'

const CreateAccountWorkFlow = (props) => {

    const { order, user, admisionId, setAdmisionId, nextStep, setStep } = props

    const isLogged = userIsLogged()
    const checkoutCookie = getCheckoutCookie();

    return (
        <>
            <SummaryStep
                isActive={!isLogged}
                modifyBtnAction={() => {}}
                stepTitle={"Creación de cuenta"}
                component={
                    <StudentInfo
                        order={order} user={user} 
                        admisionId={admisionId}
                        setAdmisionId={setAdmisionId}
                        summaryStep={2} 
                        handleIsActiveStep={() => {}}
                        nextStep={nextStep}
                        setStep={setStep}
                    />
                }
            />
            {
                checkoutCookie.membershipSession &&
                <SummaryStep
                    isActive={false} modifyBtnHidden
                    stepNumber={1} stepTitle={"Periodo de Membresía"}
                />
            }
            <SummaryStep
                isActive={false} modifyBtnHidden
                stepNumber={1} stepTitle={"Metodos de Pago"}
            />
        </>
    )
}

export default CreateAccountWorkFlow
