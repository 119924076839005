import React, { createContext, useContext, useState } from 'react';

// styles
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import '../assets/styles/styles.scss'
import '../assets/styles/bootstrap-utilities.css';
// import theme from './theme'

// import lightTheme from './light'
import darkTheme from './dark'

const ThemeContext = createContext();

export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';

export const ThemeProviderComponent = ({theme = darkTheme, children, prefersTheme = DARK_THEME, onChangeTheme = () => {}}) => {
    const [themeDefault] = useState(prefersTheme);
    const darkMode = prefersTheme === DARK_THEME;

    const toggleTheme = () => {
        onChangeTheme(prefersTheme);
    };
    
    return (
        <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
                <ThemeContext.Provider value={{ prefersTheme: themeDefault,  toggleTheme, darkMode}}>
                    <div style={{ backgroundColor: darkMode ? '#000000' : '#F9F9F9', height: '100vh' }}>
                    {/* <div> */}
                        {children}
                    </div>
                </ThemeContext.Provider>
            </ThemeProvider>
        </MuiThemeProvider>
    )
}

export const usePrefersTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};


export default ThemeProviderComponent;