import React from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Button, Card, CardContent, CardHeader, Divider, IconButton } from '@material-ui/core';

import LoginForm from './Form/LoginForm';
import SplitLogin from './SplitLogin/SplitLogin';
import AutoSignup from './AutoSignup/AutoSignup';
import { useTheme } from '@material-ui/core/styles';

import { getLogoCard, LOGO_SOURCE } from '../../../shared/utils/imageSource';
import Toast from '../../../shared/components/Toast/Toast';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import ResetPassword from './ResetPassword/ResetPassword';
import * as paths from '../../../routes/paths'
import clsx from 'clsx';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        width: '100%',
        margin: '0px auto',
        height: '100vh'
    },
    card: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 60,
        },
        backgroundColor: `${theme.palette.colors.fillStroke[400]} !important`,
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        marginTop: 56
    },
    cardHeader: {
        padding: "16px 20px 0 0"
    },
    closeIcon: {
        fontSize: '24px',
        color: theme.palette.colors.darkBlack[200]
    },
    backIcon: {
        fontSize: '24px',
        color: theme.palette.colors.gray[100]
    },
    closeIconButton: {
        padding: 0,
        float: 'right'
    },
    cardContent: {
        padding: '16px 80px !important',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: '15px !important',
        }
    },
    boxSplitLogin: {
        marginTop: 40
    },
    talisisLogo: {
        width: 120
    },
    resetPasswordText: {
        fontSize: 14 
    },
    heacerActions: {
        width: '100%'
    }
}));

const Login = (props) => {
    const classes = useStyles();

    const [toastProps, setToastProps] = React.useState({
        message: 'Por el momento no cuentas con acceso a la plataforma.',
        severity: 'error'
    });

    const location = useLocation();
    const [errorMail, setErrorMail] = React.useState('');
    const [loginMail, setLoginMail] = React.useState('');
    const [openForm, setOpenForm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [resetPasswordForm, setResetPasswordForm] = React.useState(false)
    const [openAutoSignUp, setOpenAutoSignUp] = React.useState(false);
    const [autoSignupForm, setAutoSignupForm] = React.useState({});
    const [mailSent, setMailSent] = React.useState(false);
    const [resetError, setResetError] = React.useState(false);
    const [hideTalisisLogo, setHideTalisisLogo] = React.useState(false);
    const [statusLogin, setStatusLogin] = React.useState({status: 0, error: false});
    const theme = useTheme();

    const history = useHistory();
    React.useEffect(()=>{
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.has('forgot')) {
            setResetPasswordForm(true)
        }
    })

    const handleSignupData = data => {
        setAutoSignupForm(data)
    }

    const handleAutoSignup = isOpen => {
        setOpenAutoSignUp(isOpen)
    }

    const handleLoginError = error => {
        setToastProps({ ...toastProps, open: true, message: error ? error : 'Por el momento no cuentas con acceso a la plataforma.' })
        setLoading(false)
    }

    const hanldeFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    }

    const handleLoader = loader => {
        setLoading(loader);
    }

    const handleOpenForm = canOpenForm => {
        setOpenForm(canOpenForm)
    }

    const getMail = mail => {
        setLoginMail(mail)
    }

    const handleResetPassword = open => {
        if (open) {
            eventTracker('s_restablecer_contraseña', {}, ['mixpanel']);
        }
        history.push('/recover-password')
        // setResetPasswordForm(open)
    }

    const sleep = time => {
        return new Promise(resolve => setTimeout(resolve, time))
    }

    const handleMailSent = isSent => {
        setMailSent(isSent)
        if (isSent) {
            // sleep(3000).then(() => {
            //     window.location.reload(false)
            // })
        }
    }

    const preventDefault = (event) => event.preventDefault();

    const openResetPassword = e => {
        setResetError(false)
        preventDefault(e);
        handleResetPassword(true)
    }

    const handleResetError = error => {
        setResetError((error?.data && error?.data?.error) ? error.data.error : error)
        setErrorMail(error?.email ? error.email: error);
    }

    
	const goBack = (e) => {
        preventDefault(e);
        history.goBack();
    }

    const closeResetPassword = e => {
        preventDefault(e);
        setMailSent(false)
        const searchParams = new URLSearchParams(location.search)
        searchParams.delete('forgot');
        history.replace({
            search: searchParams.toString(),
        })
        handleResetPassword(false);
    }

    const handleGoBack = ()=>{
        setStatusLogin({status: 0, error: false})
    }

    return (
        <Box>
            <LoaderContent loading={loading}>
                <Box className={classes.root}>
                    <Card elevation={0} className={`${classes.card}`}>
                        <CardHeader className={classes.cardHeader} 
                            action={
                                <Box display={'flex'} p={2} justifyContent={'space-between'}>
                                    {
                                        statusLogin?.status !== 0 ?
                                            <Button style={{padding:4}} onClick={()=>{handleGoBack()}} >
                                                <Typography variant='body2'>
                                                    <i className="ri-arrow-left-s-line mr-1" style={{ verticalAlign: 'text-bottom', display: 'inline-flex', fontSize: '24px'}} />
                                                    Regresar
                                                </Typography>
                                            </Button>
                                        :
                                        <Box></Box>
                                    }
                                    {
                                        resetPasswordForm && 
                                        <IconButton style={{padding:12}} onClick={closeResetPassword}>
                                            <i className={clsx(classes.backIcon, 'ri-arrow-left-s-line')} />
                                        </IconButton>
                                    }
                                    <IconButton className={classes.closeIconButton} component={RouterLink} to={'/'}>
                                        <i className={clsx(classes.closeIcon, 'ri-close-line')} />
                                    </IconButton>
                                </Box>
                            } 
                            classes={{ action: classes.heacerActions }}
                        />
                        <CardContent className={classes.cardContent}>
                            {
                                hideTalisisLogo 
                                    ? <Typography variant="h2" className="text-gray-100 offset-margin-bottom-42" align="left">Iniciar sesión</Typography>
                                    :
                                    <React.Fragment>
                                        <img src={getLogoCard(LOGO_SOURCE.TALISIS)} className={classes.talisisLogo} />
                                        {
                                            !resetPasswordForm && !mailSent && <Typography variant="body1" className="semi-bold mt-4" color="primary">Bienvenid@</Typography>
                                        }
                                        {!mailSent && <Typography variant="body1" className={`semi-bold text-gray-100 ${resetPasswordForm ? 'mt-4' : 'mb-4'}`} align={resetPasswordForm ? 'left' : 'center'}>{resetPasswordForm ? 'Restablecer contraseña' : 'a tu plataforma Talisis'}</Typography>}
                                    </React.Fragment>
                            }
                            {
                                (resetPasswordForm && !openAutoSignUp && !mailSent) && <React.Fragment>
                                    <Typography variant="subtitle2" className={`${classes.resetPasswordText} ${resetPasswordForm ? 'mt-2 mb-4' : 'mb-4'}`} color="textSecondary" align={resetPasswordForm ? 'left' : 'center'}>{resetError ? `No podemos recuperar tu contraseña porque no contamos con información suficiente para verificar tu identidad. Favor de comunicarte ${errorMail.includes('unid') ? 'con tu COPA' : 'a support@soportetalisis.zendesk.com'}` : 'Ingresa tu correo para que podamos enviarte indicaciones para restablecer tu contraseña.'}</Typography>
                                    <ResetPassword mailSent={handleMailSent} resetPassword={handleResetPassword} onLoading={handleLoader} mail={loginMail} onPasswordError={handleResetError} />
                                </React.Fragment>
                            }
                            {/* {
                                (openAutoSignUp && !mailSent) && <React.Fragment>
                                    <Typography variant="subtitle2" className="mb-4" color="textSecondary">Completa tu registro para continuar.</Typography>
                                    <AutoSignup onLoading={handleLoader} onLoginError={handleLoginError} formData={autoSignupForm} />
                                </React.Fragment>
                            } */}
                            {
                                (openForm && !openAutoSignUp && !resetPasswordForm && !mailSent) && <LoginForm onLoading={handleLoader} mail={loginMail} onLoginError={handleLoginError} onResetPassword={handleResetPassword} />
                            }
                            {
                                (!openForm && !openAutoSignUp && !mailSent && !resetPasswordForm) && 
                                <Box className={classes.boxSplitLogin}>
                                    <SplitLogin signupData={handleSignupData} onOpenAutoSignup={handleAutoSignup} onOpenForm={handleOpenForm} onLoading={handleLoader} setLoading={setLoading} loading={loading} getMail={getMail} onLoginError={handleLoginError} onResetPassword={handleResetPassword} setStatusLogin={setStatusLogin} statusLogin={statusLogin} handleGoBack={handleGoBack} />
                                </Box>
                            }
                            {
                                mailSent &&
                                <Box mt={5}>
                                    <img src='/images/theme/signup-confirmation.svg' />
                                    <Typography align='left' variant="h4" className="mt-4">
                                        !Enviado!
                                    </Typography>
                                    <Typography align='left' variant='body2' className='text-gray-400 mt-2'>
                                        Te hemos enviado un email con un enlace para que puedas restablecer tu contraseña. ¡Revisa tu bandeja de entrada!
                                    </Typography>
                                    <Typography align='left' variant='body2' className='text-gray-400 mt-2'>
                                        En caso de que no lo hayas recibido, asegúrate de revisar tu carpeta de spam.
                                    </Typography>
                                </Box>
                                
                            }
                            {
                                (statusLogin?.status === 0) && (
                                    <>
                                        <Divider className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" variant="fullWidth" />
                                        {
                                        mailSent ? 
                                        <Box display="flex" mb={7}>
                                            <Typography align='left' variant='body2' className='text-gray-100'>¿Necesitas ayuda?<span className='text-dark-black-200'> Haz clic en el icono "?" de abajo a la derecha</span></Typography>
                                        </Box>
                                        : 
                                        <Box display="flex" justifyContent="center" mb={7}>
                                            <Typography variant="body1" color="textPrimary" className="mr-1">¿No tienes cuenta aún?</Typography>
                                            <Link component={RouterLink} variant="body1" color="secondary" to={'/signup' + location.search} onClick={() => {
                                                eventData['source'] = 'login';
                                                eventTracker('cta_registro', eventData, ['mixpanel', 'hubspot', 'google']);
                                            }}>
                                                Registrate aquí
                                            </Link>
                                        </Box>
                                        }
                                    </>
                                )
                            }

                        </CardContent>
                    </Card>
                </Box>
            </LoaderContent>
            <Toast {...toastProps} onFinished={hanldeFinishedToast} />
        </Box>
    );
};

export default Login;
