import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material components
import { Box, Chip, Icon, Typography } from '@material-ui/core';

// Styles
import useStyles from '../../../assets/styles/components/filters/filterChipsStyle';

const FilterChips = ({dataList, onClickDeleteFilter}) => {
    const classes = useStyles();

    return (
        <Box className={classes.rootFilterChips}>
            {
                dataList.map((item, index) => 
                    <Chip 
                    classes={{"label": classes.labelChip}}
                    key={index} 
                    label={<Typography variant="body2" className={"text-gray-300"}>{`${item.name}${!!item.count ? item.count : ''}`}</Typography>} 
                    clickable={false} 
                    onDelete={() => { onClickDeleteFilter(item.filterId, item.id) }} 
                    className={classes.chips} 
                    deleteIcon={<Box display={'flex'} alignItems={'center'}><Icon className={clsx("ri-close-line", "font-size-16", "text-gray-300")}></Icon></Box>}
                    />)
            }
        </Box>
    );
};

FilterChips.propTypes = {
    dataList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
        ]).isRequired,
        name: PropTypes.string.isRequired,
        count: PropTypes.number,
        filterId: PropTypes.number.isRequired,
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        selected: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            name: PropTypes.string.isRequired,
            count: PropTypes.number,
        }))
    })),
    onClickDeleteFilter: PropTypes.func.isRequired,
};

export default FilterChips;