import * as apiTypes from '../types/apiTypes'

const INITIAL_STATE = {
    requests:{
        byPerson:{
            myRequests:[],
            requestsAsAuthorizer:[],
            pendentRequests:[]
        },
        loading: false,
        error: "",
    }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case apiTypes.APPLY_REQUESTS_BY_PERSON:
        return { 
            requests:{
                byPerson:{
                    myRequests: action.payload.myRequests,
                    requestsAsAuthorizer: action.payload.requestsAsAuthorizer,
                    pendentRequests: action.payload.pendentRequests 
                },
                loading: false,
                error: "",
            }
        };
    case apiTypes.LOADING_REQUESTS:
        return { 
            requests:{
                ...state.requests,
                loading: true,
            }
        };
    case apiTypes.ERROR_REQUESTS:
        return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};