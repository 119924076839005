import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { log } from '../../../shared/utils/console.js'
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Circle from '@material-ui/icons/Brightness1';
import ReplayIcon from '@material-ui/icons/Replay';

import { ENVIRONMENT, MarketPlaceService } from '@sdk-point/talisis';
const marketService = new MarketPlaceService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

const ResendAction = ({ step, classes, rowId, chargingStatus, setValuesByProcess, isSending, nexSteps}) => {
    const [loading, setLoading] = React.useState(false);
    const [newError, setNewError] = React.useState(false);
    const [pass, setPass] = React.useState(false);
    
    const correctStatus = (message) =>{
        log("OK");
        log(message);
        chargingStatus();
        setValuesByProcess(false, message);
        setLoading(false);
        setNewError(false);
        setPass(true);
    }
    const wrongStatus = (message) =>{
        log("Error");
        log(message);
        chargingStatus();
        setValuesByProcess(true,message);
        setLoading(false);
        setNewError(true);
        setPass(false);
    }

    const resendAction = async (e, order_id, stepId, statusId) => {
        e.preventDefault();
        if(statusId == 3 && stepId == 7){
            //CORRE STEPID 7 Y STEPID 8
            setLoading(true);
            isSending(true);
            try{
                const response = await marketService.neoRetryAddStudentToClass(order_id, { step: 1 });
                if(response && response.success){
                    correctStatus(response.message);
                }else{
                    wrongStatus(response.message);
                }
                isSending(false);
            }catch(e){
                wrongStatus(e);
                isSending(false);
            }
        }if(statusId == 3 && stepId == 8){
            //CORRE STEPID 8
            setLoading(true);
            isSending(true);
            try{
                const response = await marketService.neoRetryAddStudentToClass(order_id, { step: 2 });
                if(response && response.success){
                    correctStatus(response.message);
                }else{
                    wrongStatus(response.message);
                }
                isSending(false);
            }catch(e){
                wrongStatus(e);
                isSending(false);
            }
        }else if(statusId == 3 && stepId == 5){
            //CORRE STEPID 5;
            setLoading(true);
            isSending(true);
            try{
                const response = await marketService.postApplyBannerPayment(order_id);
                if(response && response.success){
                    correctStatus(response.message);
                }else{
                    wrongStatus(response.message);
                }
                isSending(false);
            }catch(e){
                wrongStatus(e);
                isSending(false);
            }
            
        }
    }

    return (
        <>
            {
                (step.status == 3 || newError) && !nexSteps.find(e => (e.step_id === 7 && !e.error) || (e.step_id === 8 && !e.error)) ?
                <>
                    {!pass ? <Checkbox
                        icon={<Cancel className={classes.checkRed} />} checkedIcon={ (step.step_id == 7 || step.step_id == 8 || step.step_id == 5)  ? <LoaderContent style={{ transform: loading ? "scale(0.5)": "scale(1)"}} loading={loading}> <ReplayIcon className={classes.replay} style={{ fill: loading ? "transparent":"white" }}/></LoaderContent> : <Cancel className={classes.checkRed} />}
                        checked={step.status == 1 ? false : true}
                        name="checked1"
                        color="primary"
                        onClick={(e) => !loading && resendAction(e, rowId, step.step_id, step.status)}
                        className={classes.check + " " + classes.replay}
                    />: <Checkbox
                    icon={<Circle />} checkedIcon={<CheckCircle className={classes.checkGreen} />}
                    checked={step.status == 1 ? false : true}
                    name="checked0"
                    color="primary"
                    className={classes.check}
                />
                    }
                </>: 
                <Checkbox
                    icon={<Circle />} checkedIcon={<CheckCircle className={classes.checkGreen} />}
                    checked={step.status == 1 ? false : true}
                    name="checked0"
                    color="primary"
                    className={classes.check}
                />
            }
        </>
    );
};

export default ResendAction;