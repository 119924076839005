import React from 'react';
import clsx from 'clsx';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// style
import useStyles from '../../../../assets/styles/jss/pages/market/EnrollmentConfirmation';

// design-system
import LogoSource from '../../../../design-system/components/LogoSource/LogoSource';
import { convertOuToSource } from '../../../../../src/constants/convertOuToSource';

const EnrollmentConfirmationHeader = ({ product }) => {
    const classes = useStyles();
    const sourceId = convertOuToSource(product?.company_id, product?.source_id);
    const logoParams = {
        sourceId: sourceId,
        disabledAutoRes: true,
        className: classes.logoSource,
        color: "white"
    }

    if (product?.course_id) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="subtitle2" align='center' className={clsx("text-gray-400")}>Te has inscrito al curso de:</Typography>
                <Box className="mt-5 mb-4">{LogoSource(logoParams)}</Box>
                <Typography variant="h3" align='center' className={clsx("text-white", classes.courseTitle)}>{product?.name}</Typography>
            </Box>
        );
    } else if (product?.program_id) {
        let levelLabel = "";

        if (product?.level_id === "BA") {
            levelLabel = "al bachillerato"
        } else if (product?.level_id === "LI") {
            levelLabel = "a la licenciatura"
        } else if (product?.level_id === "MA") {
            levelLabel = "a la maestría"
        } else if (product?.level_id === "PO") {
            levelLabel = "al posgrado"
        } else {
            levelLabel = "al programa"
        }

        return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="subtitle2" align='center' className={clsx("text-gray-400")}>Te has inscrito {levelLabel}:</Typography>
                <Box className="mt-5 mb-4">{LogoSource(logoParams)}</Box>
                <Typography variant="h3" align='center' className={clsx("text-white", classes.programTitle)}>{product?.name}</Typography>
                <Typography variant="body1" align='center' className={clsx("text-white", classes.programDescription)}>Nos complace darte la bienvenida a esta nueva experiencia, estamos seguros de que lograrás tus objetivos personales y profesionales.</Typography>
            </Box>
        );
    }

    return null;
};

export default EnrollmentConfirmationHeader;