import { 
    Typography,
    Box
} from '@material-ui/core';
import React from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const RejectedFilesMssg = ({files}) => {
    const filesRejected = files.filter(it => it.documentStatus === 1);
    if(!filesRejected.length) return null;

    return (
        <Box display="flex" alignItems="center" p={3} style={{borderBottom: '1px solid rgba(119, 119, 119, 0.2)'}}>
            <ErrorOutlineIcon className="mr-1"/>
            <Typography>Hay {filesRejected.length} documentos con errores, por favor súbelos nuevamente antes de la fecha límite.</Typography>
        </Box>
    );
};

export default RejectedFilesMssg;