import React, { useEffect } from 'react';
import clsx from 'clsx';

// @shared/utils
import dateFormat from '../../../design-system/utils/dateFormat';

// @react-redux
import { useSelector } from 'react-redux';

// Feed
import FeedMenu from './FeedMenu/FeedMenu';
import ConfirmDialog from '../../../shared/components/ConfirmDialog/ConfirmDialog';
import { LIKE, LOVE, CLAPPING, likeIcons } from '../FeedIcons';
import FeedFileList from './FeedFileList/FeedFileList';

// Shared components
import LogoSource from '../../../design-system/components/LogoSource/LogoSource';
import ReadMoreLine from '../../../design-system/components/Texts/ReadMoreLine/ReadMoreLine';

// Constants
import {SOURCES} from '../../../design-system/constants/sources';

import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Typography,
    CardActions,
    Avatar,
    Box,
    Icon,
} from '@material-ui/core';

// @externals
import videoURLParser from "js-video-url-parser";
import { SlideShow } from '../../../shared/components/SlideShow/SlideShow';
import { FeedCardStyles, baseIconColor } from './FeedCardStyles';
import "../Feed.css";

// Icons
import { ReactComponent as HandsClappingFillSVG } from '../../../design-system/assets/images/icons/hands-clapping-fill.svg';
import { ReactComponent as HandsClappingLineSVG } from '../../../design-system/assets/images/icons/hands-clapping-line.svg';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';

// @makeStyles
const useStyles = FeedCardStyles;

const FeedCard = (props) => {

    // Card information.
    const { user } = useSelector((state) => state.userReducer);
    const { entry, onDeleteNew, createReaction, onDownloadFile, canReadWrite, isResponsive } = props;
    const publishedDate = dateFormat(entry.published_date);

    // FeedMenu.
    const [dialogProps, setDialogProps] = React.useState({ title: '', text: '', open: false, });

    // Card Like reaction.
    const [allReactions, setAllReactions] = React.useState({});
    const [reaction, setReaction] = React.useState(false);
    const [currentTypeReaction, setCurrentTypeReaction] = React.useState(LIKE);
    const [iconReaction, setIconReaction] = React.useState(likeIcons[LIKE]['icon']);
    const [iconReactionLabel, setIconReactionLabel] = React.useState(likeIcons[LIKE]['label']);
    const [likeReactionCounter, setLikeReactionCounter] = React.useState(0);
    const [loveReactionCounter, setLoveReactionCounter] = React.useState(0);
    const [clappingReactionCounter, setClappingReactionCounter] = React.useState(0);

    // video
    const video = videoURLParser.parse(entry.url ? entry.url : "");

    // Popup
    const [open, setOpen] = React.useState(false);
    let modalDate = dateFormat(entry.published_date, 'MMM DD, YYYY');
    if (modalDate) {
        modalDate = modalDate.replace('.', '')
    }

    // styles
    const classes = useStyles();

    useEffect(() => {
        setReaction(entry.reacted === 1 ? true : false);
        setCurrentTypeReaction(entry.type_reaction ? entry.type_reaction : LIKE);
        setIconReaction(likeIcons[entry.type_reaction ? entry.type_reaction : LIKE]['icon']);
        setIconReactionLabel(likeIcons[entry.type_reaction ? entry.type_reaction : LIKE]['label']);
        initReactionCounters();
    }, [entry]);

    const initReactionCounters = () => {
        let totalLikeReactions = [];
        let totalLoveReactions = [];
        let totalClappingReactions = [];

        if (entry.all_reactions && entry.all_reactions.length > 0) {
            totalLikeReactions = entry.all_reactions.filter(reaction => reaction.type_reaction === LIKE);
            totalLoveReactions = entry.all_reactions.filter(reaction => reaction.type_reaction === LOVE);
            totalClappingReactions = entry.all_reactions.filter(reaction => reaction.type_reaction === CLAPPING);
        }

        setAllReactions({
            [LIKE]: totalLikeReactions[0] ? parseInt(totalLikeReactions[0].total) : 0,
            [LOVE]: totalLoveReactions[0] ? parseInt(totalLoveReactions[0].total) : 0,
            [CLAPPING]: totalClappingReactions[0] ? parseInt(totalClappingReactions[0].total) : 0,
        });
        setLikeReactionCounter(totalLikeReactions[0] ? parseInt(totalLikeReactions[0].total) : 0);
        setLoveReactionCounter(totalLoveReactions[0] ? parseInt(totalLoveReactions[0].total) : 0);
        setClappingReactionCounter(totalClappingReactions[0] ? parseInt(totalClappingReactions[0].total) : 0);
    };

    const handleReaction = async (feed_id, type_reaction) => {
        if (feed_id) {
            setReaction(reaction && type_reaction === currentTypeReaction ? !reaction : true);
            setCurrentTypeReaction(type_reaction);
            setIconReaction(likeIcons[reaction && type_reaction === currentTypeReaction ? LIKE : type_reaction]['icon']);
            setIconReactionLabel(likeIcons[reaction && type_reaction === currentTypeReaction ? LIKE : type_reaction]['label']);
            const { ok, reacted } = await createReaction({ type_reaction: (type_reaction === currentTypeReaction ? currentTypeReaction : type_reaction), feed_id, person_id: user.person_id });
            handleReactionCounter(ok, type_reaction, reacted);
            if (reacted) {
                const eventData = {};
                if (type_reaction === LIKE) eventData['tipoReaccion'] = 'gusta';
                else if (type_reaction === LOVE) eventData['tipoReaccion'] = 'encanta';
                else if (type_reaction === CLAPPING) eventData['tipoReaccion'] = 'aplauso';
                eventTracker('reaccion_noticia', eventData, ['mixpanel']);
            }
        };
    };

    const handleReactionCounter = (ok, type_reaction, reacted) => {
        if (ok) {
            initReactionCounters();
            switch (type_reaction) {
                case LIKE:
                    if (reacted) {
                        setLikeReactionCounter(likeReactionCounter + 1);
                        setLoveReactionCounter(allReactions[LOVE] > 0 ? (entry.type_reaction === LOVE ? allReactions[LOVE] - 1 : allReactions[LOVE]) : 0);
                        setClappingReactionCounter(allReactions[CLAPPING] > 0 ? (entry.type_reaction === CLAPPING ? allReactions[CLAPPING] - 1 : allReactions[CLAPPING]) : 0);
                    }
                    else {
                        setLikeReactionCounter(allReactions[LIKE] > 0 ? (entry.type_reaction === LIKE ? allReactions[LIKE] - 1 : allReactions[LIKE]) : 0);
                        setLoveReactionCounter(allReactions[LOVE] > 0 ? (entry.type_reaction === LOVE ? allReactions[LOVE] - 1 : allReactions[LOVE]) : 0);
                        setClappingReactionCounter(allReactions[CLAPPING] > 0 ? (entry.type_reaction === CLAPPING ? allReactions[CLAPPING] - 1 : allReactions[CLAPPING]) : 0);
                    }
                    break;
                case LOVE:
                    if (reacted) {
                        setLikeReactionCounter(allReactions[LIKE] > 0 ? (entry.type_reaction === LIKE ? allReactions[LIKE] - 1 : allReactions[LIKE]) : 0);
                        setLoveReactionCounter(loveReactionCounter + 1);
                        setClappingReactionCounter(allReactions[CLAPPING] > 0 ? (entry.type_reaction === CLAPPING ? allReactions[CLAPPING] - 1 : allReactions[CLAPPING]) : 0);
                    }
                    else {
                        setLikeReactionCounter(allReactions[LIKE] > 0 ? (entry.type_reaction === LIKE ? allReactions[LIKE] - 1 : allReactions[LIKE]) : 0);
                        setLoveReactionCounter(allReactions[LOVE] > 0 ? (entry.type_reaction === LOVE ? allReactions[LOVE] - 1 : allReactions[LOVE]) : 0);
                        setClappingReactionCounter(allReactions[CLAPPING] > 0 ? (entry.type_reaction === CLAPPING ? allReactions[CLAPPING] - 1 : allReactions[CLAPPING]) : 0);
                    }
                    break;
                case CLAPPING:
                    if (reacted) {
                        setLikeReactionCounter(allReactions[LIKE] > 0 ? (entry.type_reaction === LIKE ? allReactions[LIKE] - 1 : allReactions[LIKE]) : 0);
                        setLoveReactionCounter(allReactions[LOVE] > 0 ? (entry.type_reaction === LOVE ? allReactions[LOVE] - 1 : allReactions[LOVE]) : 0);
                        setClappingReactionCounter(clappingReactionCounter + 1);
                    }
                    else {
                        setLikeReactionCounter(allReactions[LIKE] > 0 ? (entry.type_reaction === LIKE ? allReactions[LIKE] - 1 : allReactions[LIKE]) : 0);
                        setLoveReactionCounter(allReactions[LOVE] > 0 ? (entry.type_reaction === LOVE ? allReactions[LOVE] - 1 : allReactions[LOVE]) : 0);
                        setClappingReactionCounter(allReactions[CLAPPING] > 0 ? (entry.type_reaction === CLAPPING ? allReactions[CLAPPING] - 1 : allReactions[CLAPPING]) : 0);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    const handleDownloadFile = async (docURL) => {
        const pdfBase64 = await onDownloadFile(docURL);
        const anchor = document.createElement('a');
        anchor.href = `data:application/pdf;base64,${pdfBase64}`;
        let fileName = docURL.split('/').pop().split('#')[0].split('?')[0];
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    const handleConfirm = (value) => {
        if (value === true) {
            onDeleteNew(entry.id);
        }
    };

    const handleClose = () => {
        setDialogProps({ ...dialogProps, open: false });
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Card className={`${classes.card}`}>
                <CardHeader 
                    className={classes.cardHeaderRoot} 
                    avatar={<Avatar><LogoSource sourceId={SOURCES.TALISIS} responsive={true} className={classes.iconAvatar}/> </Avatar>} 
                    title={<Typography variant='body1' className={clsx('semi-bold', 'text-gray-100')}>
                        Familia Talisis
                    </Typography>} 
                    subheader={<Typography className={clsx('variant-xsmall', 'text-gray-500')}>
                        {publishedDate}
                    </Typography>}
                    action={ canReadWrite && <FeedMenu entry={entry} setDialogProps={setDialogProps} /> }
                    />

                <CardContent>
                    <ReadMoreLine text={<Typography variant={isResponsive ? 'body2' : 'body1'} className='text-gray-100'>{entry.body}</Typography>} line={4}/>
                </CardContent>

                <CardContent className={classes.actionArea}>
                    {/* Texto + Imagen */}
                    {entry.format === 'text/image' && (entry.files && <CardMedia image={entry.files.map(file => file.media)[0]} component="img" title={entry.title} className={classes.image} />)}
                    {/* Texto + Carrusel */}
                    {entry.format === 'text/images' && (entry.files && <SlideShow name={entry.id} src={entry.files.map(file => file.media)} className={''} mediaCSSClass={classes.image} dotCSSClass={''} enableArrows={true} mediaHeight={'100%'} arrowCSSClass={classes.multiImageArrows} arrowIconCSSClass={classes.multiImageArrowIcon} />)}
                    {/* Texto + YouTube */}
                    {entry.format === 'text/youtube' && (entry.url && <CardMedia src={`https://www.youtube.com/embed/${video.id}`} component="iframe" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className={classes.video} autoPlay />)}
                    {/* Files */}
                    {
                        entry.format === 'text/files' && entry.files && 
                        <FeedFileList files={entry.files} onDownloadFile={handleDownloadFile} isResponsive={isResponsive}/>
                    }
                </CardContent>
                <CardActions className={classes.actions}>
                    <Box className={classes.reactionContainer}>
                        <Box className={classes.countReactionContainer}>
                            <Icon className={clsx('ri-thumb-up-fill', 'font-size-16')}/>
                            <Typography className={clsx('variant-xsmall', 'offset-ml-2')}>{likeReactionCounter}</Typography>

                            <Icon className={clsx('ri-heart-3-fill', 'font-size-16', 'offset-ml-2')}/>
                            <Typography className={clsx('variant-xsmall', 'offset-ml-2')}>{loveReactionCounter}</Typography>

                            <Icon component={HandsClappingFillSVG} className={clsx(classes.handsClappingFillIcon, 'offset-ml-2')}/>
                            <Typography className={clsx('variant-xsmall', 'offset-ml-2')}>{clappingReactionCounter}</Typography>
                        </Box>
                        <Box className={classes.buttonsReactionContainer}>
                            <Box className={classes.buttonReactionContainer} onClick={() => handleReaction(entry.id, LIKE)} style={{color: reaction && currentTypeReaction === LIKE ? likeIcons[LIKE].color : 'inherit'}}>
                                <Icon className={clsx(reaction && currentTypeReaction === LIKE ? 'ri-thumb-up-fill' : 'ri-thumb-up-line', isResponsive ? 'font-size-16' : 'font-size-24')}/>
                                <Typography variant='body1' className={clsx('semi-bold', 'offset-ml-2', classes.labelButtons, isResponsive && 'variant-xsmall')}>{likeIcons[LIKE].label}</Typography>

                            </Box>
                            <Box className={classes.buttonReactionContainer} onClick={() => handleReaction(entry.id, LOVE)} style={{color: reaction && currentTypeReaction === LOVE ? likeIcons[LOVE].color : 'inherit'}}>
                                <Icon className={clsx(reaction && currentTypeReaction === LOVE ? 'ri-heart-3-fill' : 'ri-heart-3-line', isResponsive ? 'font-size-16' : 'font-size-24')}/>
                                <Typography variant='body1' className={clsx('semi-bold', 'offset-ml-2', classes.labelButtons, isResponsive && 'variant-xsmall')}>{likeIcons[LOVE].label}</Typography>

                            </Box>
                            <Box className={classes.buttonReactionContainer} onClick={() => handleReaction(entry.id, CLAPPING)} style={{color: reaction && currentTypeReaction === CLAPPING ? likeIcons[CLAPPING].color : 'inherit'}}>
                                <Icon component={reaction && currentTypeReaction === CLAPPING ? HandsClappingFillSVG : HandsClappingLineSVG} className={clsx(classes.handsClappingFillIcon, isResponsive ? 'font-size-16' : 'font-size-24')}/>
                                <Typography variant='body1' className={clsx('semi-bold', 'offset-ml-2', classes.labelButtons, isResponsive && 'variant-xsmall')}>{likeIcons[CLAPPING].label}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardActions>
            </Card>
            <ConfirmDialog {...dialogProps} onConfirm={handleConfirm} onClose={handleClose} />
        </React.Fragment>
    );
};

export default FeedCard;
