import React from 'react';
import PropTypes from 'prop-types'

// material picker
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider,DatePicker} from '@material-ui/pickers';
import "moment/locale/es";

const Picker = (props) => {
    const {onChange, format, placeholder, autoOk, ...rest} = props;
    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DatePicker
                placeholder={placeholder}
                format={format}
                fullWidth
                autoOk={autoOk}
                // variant="inline"
                inputVariant="outlined"
                onChange={date => props.onChange({target:{name:props.name, value: date}})}
                {...rest}
            />
        </MuiPickersUtilsProvider>
    );
};

Picker.defaultProps = {
    required: false,
    error: false,
    format: "DD MMMM, YYYY",
    placeholder: "día/mes/año",
    autoOk: true
};

Picker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    label: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func
};

export default Picker;
