import React from "react";
import * as Cookies from "js-cookie";
import objectIsEmpty from './objectIsEmpty';

export const setSessionCookie = (session) => {
  // Set session cookie. Bi-directional login in subdomains.
  if ((window.location.host).includes(".talisis.com")) {
    Cookies.set("session", session, { domain: ".talisis.com" });
    if (!session["ignore_session_cookie"]) {
      Cookies.set("LOGIN_PLATFORM_TALISIS", true, { domain: ".talisis.com" });
    }
  }
  else {
    Cookies.set("session", session);
    if (!session["ignore_session_cookie"]) {
      Cookies.set("LOGIN_PLATFORM_TALISIS", true);
    }
  }

  localStorage.removeItem("session");
  localStorage.setItem("session", JSON.stringify(session));
};

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get("session");
  const sessionData = localStorage.getItem('session');
  if (sessionData === undefined || sessionData === null) {
    return {};
  } else {
    return JSON.parse(sessionData);
  }
};

export const userIsLogged = () => {
  const session = getSessionCookie();
  const isLogged = !objectIsEmpty(session);

  return isLogged;
}

export const SessionContext = React.createContext(getSessionCookie());