import React from 'react';
import clsx from 'clsx';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import typography from '../../../design-system/theme/typography';

const PLAN_MENSUAL = "MENS"; 
const PLAN_ANUAL = "ANU";

const useStyles = makeStyles((theme) => ({
    membershipPlansRoot: {
        paddingTop: '64px',
        paddingBottom: '110px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '32px',
            paddingBottom: '32px',
        },
    },
    planContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        backgroundColor: theme.palette.colors.fillStroke[400],
        borderRadius: '32px',
        padding: '8px',
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            padding: '0px',
        },
        '& .MuiGrid-item:not(:last-child)': {
            paddingRight: 0
        }
    },
    planQuatityOff: {
        marginTop: '8px',
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginTop: '6px',
        }
    },
    planQuatityOffItem: {
        minWidth: '152px',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            minWidth: '94px',
            width: '94px',
        }
    },
    planButton: {
        width: '144px',
        height: '48px',
        ...typography.subtitle2,
        color: theme.palette.colors.gray[300],
        [theme.breakpoints.down('xs')]: {
            width: '109px',
            height: '40px',
            ...typography.body2,
            fontWeight: 400,
            color: theme.palette.colors.gray[300],
        },
        "&:hover": {
            backgroundColor: theme.palette.colors.violet[500],
        }
    },
    planActive: {
        backgroundColor: theme.palette.colors.violet[500],
        ...typography.h5,
        color: theme.palette.colors.grey[100],
        fontWeight: 700,
        [theme.breakpoints.down('xs')]: {
            ...typography.body2,
            fontWeight: 600,
            color: theme.palette.colors.gray[100],
        },
    },
    planDefault: {
        backgroundColor: "inherit",
        border: 'none'
    },
    planSize: {
        width: "144px",
        [theme.breakpoints.down('xs')]: {
            width: '109px',
        },
    },
    hotDays:{
        borderRadius: 32,
        border: '0.5px solid var(--Fills-and-strokes-400, #1C1F24)',
        background: 'linear-gradient(90deg, #D92223 0.28%, #3900D9 51.41%, #766BED 99.97%)'
    }
}));

function HotLabel(){
    return <Typography>
        <span style={{fontSize:16}}>🔥</span>
        &nbsp;Anual&nbsp;
        <span style={{fontSize:16}}>🔥</span>
    </Typography>
}

const MembershipPlans = ({ onClick, membershipTerm, displaySavedPercentage = true, membershipPlansD }) => {
    const classes = useStyles();
    const [plan, setPlan] = React.useState(PLAN_ANUAL);

    const handleClick = (value) => {
        setPlan(value);

        if (onClick) {
            onClick(value)
        }
    };
    
    const membershipPlansData = membershipPlansD.filter(MP => MP.months >= membershipTerm);

    return (
        <Box className={classes.membershipPlansRoot}>
            <Grid container className={classes.planContainer}>
                {membershipPlansData.map(membershipPlan => {
                    return <Grid item>
                        <Button
                            className={clsx(classes.planButton, {
                                [classes.planActive]: plan === membershipPlan.slug,
                                [classes.planDefault]: plan !== membershipPlan.slug,
                                [classes.planSize]: true,
                                'semi-bold': plan === membershipPlan.slug
                            })}
                            color="primary"
                            onClick={() => handleClick(membershipPlan.slug)}
                            variant="outlined">
                                {membershipPlan.slug === PLAN_ANUAL ? <HotLabel/> : membershipPlan.name}
                        </Button>
                    </Grid>
                })}
            </Grid>
            <Grid container className={classes.planQuatityOff}>
                {membershipPlansData.map(membershipPlan => {
                    return <Grid item className={clsx(classes.planQuatityOffItem, { [classes.planSize]: true })}>
                        {displaySavedPercentage && membershipPlan.quantityOff && <Typography variant="body2" className="text-blue-300">Ahorra {membershipPlan.quantityOff}</Typography>}
                    </Grid>
                })}
            </Grid>
        </Box >
    );
};

export default MembershipPlans;