import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

// @material-ui/core
import { Avatar, Box, Typography } from '@material-ui/core';

// Style
import useStyles from '../../../../../assets/styles/jss/pages/home/cardUserStyle';

// Paths
import {PERFIL} from '../../../../../routes/paths';

const CardUser = ({ user, userRole, profilePic }) => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <Box className={classes.cardUserRoot} onClick={() => history.push(PERFIL)}>
            <Box>
                <Avatar src={profilePic?.src ?? ''}></Avatar>
            </Box>
            <Box className={classes.infoContainer}>
                <Box>
                    <Typography noWrap variant='body2' className={clsx('semi-bold')}> {user?.userName} </Typography>
                </Box>
                <Box className={classes.roleContainer}>
                    <Typography noWrap className={clsx(`variant-xsmall`, `text-dark-black-200`)}> {userRole} </Typography>
                </Box>
            </Box>
        </Box>
    );
};

CardUser.propTypes = {
    user: PropTypes.object,
    userRole: PropTypes.string,
    profilePic: PropTypes.object,
};

export default CardUser;