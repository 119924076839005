import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    content: {
        borderRadius: 10,
        // background: theme.palette.colors.white,
        padding: theme.spacing(3),
        maxHeight: 'calc(100vh - 350px)',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
            backgroundColor: "#e4e4e4"
          },
          "&::-webkit-scrollbar-thumb": {
                borderRadius: "100px",
                background: "#77777747",
                "&:hover":{
                    background: "#b2adad"
                }
          },
          "&::-webkit-scrollbar-track": {
              borderRadius: "8px"
          }

    }
}));