import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import RolesByUserTable from './RolesByUser/RolesTable';
import UserObject from "./UserObject";

// Services
import * as UserServices from '../../../services/UserService';

// Shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import Toast from '../../../shared/components/Toast/Toast';
import TextFielDefault from "../../../shared/components/Form/TextFielDefault";
import Select from "../../../design-system/components/Form/Select/Select";
import ConfirmDialog from '../../../shared/components/ConfirmDialog/ConfirmDialog';
import Table from "../../../shared/components/Table/Table";
import { log } from '../../../shared/utils/console.js'
import { setSessionCookie } from "../../../shared/utils/Sessions";

// Material UI
import {
    Switch,
    Grid,
    Card,
    CardActions,
    CardContent,
    Typography,
    FormControlLabel,
    Button,
    Box
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import IconButton from '@material-ui/core/IconButton';

//SDK
import { RequestService, SimulationService, CourseService, ProgramService, RestrictionsService } from '@sdk-point/talisis';

// redux
import { connect } from 'react-redux';
import * as userActions from '../../../redux/actions/userActions';
import * as profilePicActions from '../../../redux/actions/profilePicActions';
import * as menuActions from '../../../redux/actions/menuActions';
import * as membershipActions from '../../../redux/actions/membershipActions';
import { MEMBERSHIPS } from '../../../shared/constants/memberships';

const RequestServiceSDK = new RequestService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const SimulationServiceSDK = new SimulationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const CourseServiceSDK = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const ProgramServiceSDK = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RestrictionsServiceSDK = new RestrictionsService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const units = [
    { ou: "iesalud", ouLabel: "IESALUD" },
    { ou: "talisis", ouLabel: "TALISIS" },
    { ou: "uerre", ouLabel: "U-ERRE" },
    { ou: "unid", ouLabel: "UNID" },
    { ou: "hh", ouLabel: "HARMON HALL" }
];

function Actions({ item, onClickShow }) {
    return <div>
        <IconButton color="primary" aria-label="show" onClick={onClickShow(item)} style={{ padding: 0 }}>
            <VisibilityOutlinedIcon className="font-size-20" />
        </IconButton>
    </div>
}

const UserForm = (props) => {
    const { userL, profilePic, applyUser, applyProfilePic, applyMembership, getUserMenu, match } = props;
    let id = (match.params.id) ? match.params.id : 0;
    const [userData, setUserData] = useState({});
    const [user, setUser] = useState([]);
    const [userConnect, setUserConnect] = useState([]);
    const [userMembresia, setuserMembresia] = useState([]);
    const [userEmail, setUserEmail] = useState([]);
    const [userOrders, setUserOrders] = useState([]);
    const [userRequest, setUserRequest] = useState([]);
    const [userId, setUserId] = useState(id);
    const [isLoaded, setIsLoaded] = useState(false);
    const history = useHistory();
    const [programsInProgress, setProgramInProgress] = useState([]);
    const [coursesEduconInProgress, setCoursesEduconInProgress] = useState([]);
    const [courseAllysInProgress, setCoursesAllysInProgress] = useState([]);
    const [canReadWrite, setCanReadWrite] = useState(false);
    const [openModal, setOpenModal] = useState({
        open: false,
        text: ''
    });
    const [toast, setToast] = useState({
        toastProps: {
            message: '',
            open: false
        }
    })
    const items = [
        { to: '/home', label: 'Administrador', title: '' },
        { to: '/usuarios/management', label: 'Usuarios', title: '' },
        { label: (userId) ? 'Información de usuario' : 'Nuevo Usuario', title: '' }
    ];
    useEffect(() => {
        hanldeCanReadWrite();
        getUser();
    }, [])

    const hanldeCanReadWrite = () => {
        const canReadWrite = UserServices.canReadWrite(props.pageId);
        setCanReadWrite(canReadWrite);
    }

    const getUser = async () => {
        let responseUser = {}
        try {
            setIsLoaded(true);
            responseUser = (userId) ? await UserServices.getById(userId) : false;
            setUserData(() => responseUser);
            setUserConnect(() => responseUser.allysConnect);
            setUserEmail(() => responseUser.emails);
            setuserMembresia(() => responseUser.membresia);
            const ordenes = responseUser.orders.map((value) => {
                return {
                    ...value,
                    actions: <Actions item={value} onClickShow={item => e => {
                        e.preventDefault();
                        history.push(`/gestion-de-ordenes`)
                    }} />
                }
            })

            setUserOrders(() => ordenes)

            const responseRequest = await RequestServiceSDK.getMyRequests(userId, 1);
            const requests = responseRequest.map((value) => {
                return {
                    ...value,
                    actions: <Actions item={value} onClickShow={item => e => {
                        e.preventDefault();
                        history.push(`/solicitudes/${item.id}`)
                    }} />
                }
            })
            setUserRequest(() => requests)
            const data = form(responseUser);
            setUser(data);
        }
        catch (error) {

        }
        finally {
            setIsLoaded(false);
            getCoursesEduconInProgress(responseUser);
        }
    }

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        const indexObj = user.findIndex(obj => obj.name === name);
        user[indexObj].defaultValue = (type === 'checkbox') ? checked : value;
        user[indexObj].error = false;
        user[indexObj].helperText = '';
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        let isError = false;
        let newObjUser = {}
        setIsLoaded(true);
        for (let i = 0; i < user.length; i++) {
            const name = user[i].name;
            const value = user[i].defaultValue;
            if (user[i].type != 'boolean') {
                if (user[i].required) {
                    isError = (value) ? false : true;
                    if (isError) {
                        const indexObj = user.findIndex(obj => obj.name === name);
                        user[indexObj].error = true;
                        user[indexObj].helperText = 'Campo requerido'
                    }
                }
            }
            newObjUser[name] = value;
        }
        if (!isError) {
            try {
                let response = (userId) ? await UserServices.update(newObjUser, userId) : await UserServices.save(newObjUser);
                setToast({ toastProps: { open: true, message: `Se ha ${(userId) ? 'actualizado' : 'creado'} satisfactoriamente el usuario` } })
                if (!userId) {
                    setUserId(response.id);
                    setOpenModal({ open: true, text: '¿Asignar un Rol?' });
                }
            }
            catch (error) {
                setToast({ toastProps: { severity: "error", open: true, message: "Ops! Ha ocurrido un error" } })
            }
            finally {
                setIsLoaded(false);
            }
        }
        else {
            setIsLoaded(false)
            setToast({ toastProps: { severity: "error", open: true, message: 'Ingrese campos requeridos' } })
        }
    }

    const handleFinishedToast = () => {
        setToast({ toastProps: { open: false, message: '' } })
    }

    const handleComfirModal = async (e) => {
        history.push(`/usuarios/${userId}/roles`)
    }

    const handleOnCloseModal = () => {
        setOpenModal({ open: false, text: '' });
    }

    const form = (form) => {
        let data = UserObject().map(item => {
            let defaultValue;
            if (form) {
                defaultValue = form[item.name];
            }
            else {
                const type = item.type;
                defaultValue = (type === 'boolean') ? false : undefined;
                defaultValue = (item.name === 'gender') ? 'M' : undefined;
            }

            if (item.name === 'id' && !userId) {
                item.visibility = 'none'
            }
            return { defaultValue, error: false, helperText: '', ...item }
        })
        return data;
    }

    const ListInput = () => {
        const ItemsVisivilite = user.filter(item => (!item.visibility))
        const Item = ItemsVisivilite.map((item, index) => {
            item.onChange = handleChange;
            if (item.type === 'boolean') {
                return (
                    <Grid item xs={12} md={4} ms={12} key={index}>
                        <FormControlLabel control={
                            <Switch
                                onChange={item.onChange}
                                defaultChecked={item.defaultValue}
                                color="primary"
                                name={item.name}
                                variant="outlined"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        } label={item.label} />
                    </Grid>
                )
            }
            else if (item.type === 'select') {
                return (
                    <Grid item xs={12} md={4} ms={12} key={index}>
                        <Select {...item} margin="dense" />
                    </Grid>
                )
            }
            else {
                return (
                    <Grid item xs={12} md={4} ms={12} key={index}>
                        <TextFielDefault {...item} InputLabelProps={{ shrink: true }} />
                    </Grid>
                )
            }
        })
        return (Item);
    }

    const handleback = () => props.history.push(`/usuarios/management`);

    const startSimulator = async () => {
        let item = { ...userData }
        setIsLoaded(true);
        // backup current user
        localStorage.setItem("admin_session", JSON.stringify({
            user: userL ?? {},
            profilePic: profilePic ?? {}
        }));
        // setup fake user
        let company = !item.company_id ? "" : item.company_id.toLowerCase();
        if (item.email && item.email.includes('iesalud') === true) {
            company = 'iesalud'
        }
        const ou = company;
        const unit = units.filter(u => u.ou === ou)[0];
        const f_user = {
            userName: item.first_name + ' ' + item.last_name || "",
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email || "",
            student_id: item.user_id,
            person_id: item.id,
            is_admin: false,
            is_fake: true,
            ou: unit?.ou,
            ouLabel: unit?.ouLabel,
            profile_picture_url: item.profile_picture_url,
            membership_id: item.membership_id || MEMBERSHIPS.FREEMIUM,
        };

        try {
            const f_profilePic = {
                src: "/images/avatars/placeholder.png",
                blob: false
            };

            applyProfilePic(f_profilePic);

            const session = ({
                "person_id": userL.person_id,
                "student_id": item.id
            });

            // create bd session
            await SimulationServiceSDK.createSession(session).then(async response => {
                f_user.fake_session = await response;

                f_user.membership_id = f_user.membership_id || MEMBERSHIPS.FREEMIUM;
                f_user.interests = f_user.interests || [];
                f_user.first_name = f_user.first_name || '';
                f_user.last_name = f_user.last_name || '';
                f_user.company_id = company ? company.toUpperCase() : 'TAL';

                const getUserRestrictions = async () => {
                    try {
                        const response = await RestrictionsServiceSDK.getRestrictionsByUser(item.id);
                        f_user.restrictions = response.result;
                    }
                    catch (error) {
                        console.error('Error al obtener las restricciones:', error);
                    }
                }
                await getUserRestrictions();

                // apply fake user
                applyUser(f_user);
                getUserMenu(f_user);
                applyMembership(f_user.membership_id);
                setSessionCookie(f_user);

                history.push("/home");
            });
        }
        catch (e) {
            log(e);
            setIsLoaded(false);
        }
    };
    const getCoursesEduconInProgress = (userData) => {
        ProgramServiceSDK.getProgramsInProgressByUser(userData.id, userData.user_id).then((response) => {
            setProgramInProgress(() => response.programs);
        })
            .catch((error) => {
                log('Error fetch programs', error)
            })

        CourseServiceSDK.getCoursesAllyInProgressByUser(userData.id).then(
            (response) => {
                let coursesLlys = []
                response.courses.forEach(element => {
                    const itemCourse = element.courses.map(value => {
                        return {
                            'marca': element.name, 'name': value.name, modality: (value.modality_id == 1) ? 'Presencial' : 'Online', actions: <Actions item={value} onClickShow={item => e => {
                                e.preventDefault();
                                history.push(`/cursos`)
                            }} />
                        }
                    })
                    coursesLlys = [...coursesLlys, ...itemCourse]
                });
                setCoursesAllysInProgress(() => coursesLlys)
            }
        )
            .catch((error) => {
                log('Error fetch courses allys in progress', error)
            })
            .finally(() => {

            })
        CourseServiceSDK.getCoursesEduconInProgressByUser(userData.id).then(
            (response) => {
                let coursesEducon = []
                response.courses.forEach(element => {
                    const itemCourse = element.courses.map(value => {
                        return {
                            'marca': element.name, 'name': value.name, modality: (value.modality_id == 1) ? 'Presencial' : 'Online', actions: <Actions item={value} onClickShow={item => e => {
                                e.preventDefault();
                                history.push(`/cursos`)
                            }} />
                        }
                    })
                    coursesEducon = [...coursesEducon, ...itemCourse]
                });
                setCoursesEduconInProgress(() => coursesEducon);
            }
        )
            .catch((error) => {
                log('Error fetch courses educon in progress', error)
            })
    }

    return (
        <>
            <Breadcrumb items={items} />
            <Loader loading={isLoaded}>
                <Card>
                    <CardContent>
                        <Typography variant="subtitle2" className="mb-4">Información de usuario</Typography>
                        <Grid container spacing={3}>
                            <ListInput />
                        </Grid>

                        {
                            (!isLoaded && userId !== 0) &&
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3} >
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <RolesByUserTable user={userId} canReadWrite={canReadWrite} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={3} >
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" className="mb-4">
                                            Membresía
                                        </Typography>
                                        <Table
                                            columns={[{ name: 'name', label: 'Descripción', minWidth: 100, align: 'center', format: null },
                                            { name: 'credits', label: 'Créditos asesoría', minWidth: 100, align: 'center', format: null }]}
                                            rows={userMembresia}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" className="mb-4">Acceso a plataformas</Typography>
                                        <Table columns={[{ name: 'value', label: 'Descripción', minWidth: 100, align: 'center', format: null }]} rows={userConnect.length > 0 ? userConnect : [{ value: '\u00A0' }]} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={3} >
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" className="mb-4">Correos electrónicos:</Typography>
                                        <Table columns={[{ name: 'email_address', label: 'Descripción', minWidth: 100, align: 'center', format: null }]} rows={userEmail.length > 0 ? userEmail : [{ email_address: '\u00A0' }]} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6} >
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" className="mb-4">Trámites</Typography>
                                        <Table columns={[
                                            { name: 'id', label: 'Folio', minWidth: 100, align: 'center', format: null },
                                            { name: 'title', label: 'Titulo', minWidth: 100, align: 'center', format: null },
                                            { name: 'description', label: 'Descripción', minWidth: 100, align: 'center', format: null },
                                            { name: 'actions', label: '', minWidth: 100, align: 'center', format: null }]}
                                            rows={userRequest.length > 0 ? userRequest : [{ id: '\u00A0' }]}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6} >
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" className="mb-4">Ordenes</Typography>
                                        <Table columns={[
                                            { name: 'folio', label: 'Folio', minWidth: 100, align: 'center', format: null },
                                            { name: 'description', label: 'Descripción', minWidth: 100, align: 'center', format: null },
                                            { name: 'actions', label: '', minWidth: 100, align: 'center', format: null }]}
                                            rows={userOrders.length > 0 ? userOrders : [{ folio: '\u00A0' }]}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} >
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" className="mb-4">Programas</Typography>
                                        <Table columns={[
                                            { name: 'id', label: 'Id', minWidth: 100, align: 'center', format: null },
                                            { name: 'program_name', label: 'Programa', minWidth: 100, align: 'center', format: null }]}
                                            rows={programsInProgress.length > 0 ? programsInProgress : [{ id: '\u00A0' }]}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} >
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" className="mb-4">Cursos Educación continua</Typography>
                                        <Table columns={[
                                            { name: 'name', label: 'Descripción', minWidth: 100, align: 'center', format: null },
                                            { name: 'modality', label: 'Modalidad', minWidth: 100, align: 'center', format: null },
                                            { name: 'marca', label: 'Marca', minWidth: 100, align: 'center', format: null },
                                            { name: 'actions', label: '', minWidth: 100, align: 'center', format: null }]}
                                            rows={coursesEduconInProgress.length > 0 ? coursesEduconInProgress : [{ name: '\u00A0' }]}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} >
                                    <Box p={1} style={{ borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" className="mb-4">Cursos aliados</Typography>
                                        <Table columns={[
                                            { name: 'name', label: 'Descripción', minWidth: 100, align: 'center', format: null },
                                            { name: 'modality', label: 'Modalidad', minWidth: 100, align: 'center', format: null },
                                            { name: 'marca', label: 'Aliado', minWidth: 100, align: 'center', format: null },
                                            { name: 'actions', label: '', minWidth: 100, align: 'center', format: null }]}
                                            rows={courseAllysInProgress.length > 0 ? courseAllysInProgress : [{ name: '\u00A0' }]}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </CardContent>
                    <CardActions>
                        <Box display="flex" justifyContent="flex-end" width="100%"  >
                            <Button color="primary" variant="contained" style={{ margin: '2px' }} onClick={startSimulator} className="mr-3">Iniciar Simulación</Button>
                            <Button color="secondary" variant="outlined" style={{ margin: '2px' }} onClick={handleback} className="mr-1">Cancelar</Button>
                            {canReadWrite && <Button color="primary" variant="contained" style={{ margin: '2px' }} onClick={handleSubmit}>Guardar</Button>}
                        </Box>
                    </CardActions>
                </Card>
                <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={3000} />
                <ConfirmDialog open={openModal.open} onConfirm={handleComfirModal} onClose={handleOnCloseModal} title='Confirmar' text={openModal.text} />
            </Loader>
        </>
    )
}

const mapStateToProps = ({ userReducer, profilePicReducer }) => ({
    userL: userReducer.user,
    profilePic: profilePicReducer.profilePic
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...menuActions, ...membershipActions };
export default connect(mapStateToProps, mapDispatchToProps)(UserForm);