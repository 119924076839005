import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// reducers 
import { setOrder, setOrderProducts, setPersonInfo } from '../../../../../../redux/actions/checkoutActions';

// @material-ui
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// shared
import CheckTermsConditions from '../../../../../../shared/components/CheckTermsConditions/CheckTermsConditions';
import { updatePersonInCart, validateFormFields } from '../../../../../../shared/utils/checkoutHelpers';
import { log } from '../../../../../../shared/utils/console';

// hooks
import { useForm } from '../../../../../Account/Profile/UserPreferences/hooks/useForm';

// sdk
import { SingupService, PERSON_ORIGIN_CAT } from '@sdk-point/talisis';

// components
import WorkflowPage from '../../../WorkflowPage/WorkflowPage';
import SignupProgressModal from './SignupProgressModal/SignupProgressModal';
import SignupAlert from './SignupAlert/SignupAlert';
import { getCheckoutCookie, setCheckoutCookie } from '../../../../../../shared/utils/checkoutSession';

const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const SignupForm = ({ onLogin }) => {
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch();
    const [acceptTerms, setAcceptTerms] = React.useState(false);
    const [alertHidden, setAlertHidden] = React.useState(true);
    const [formErrors, setFormErrors] = React.useState({});
    const [loginInvite, setLoginInvite] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [signupResponse, setSignupResponse] = React.useState("");
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
    const { formulario, handleInputChange } = useForm({
        firstName: "",
        lastName1: "",
        lastName2: "",
        phoneNumber: "",
        email1: "",
        email2: "",
    });

    const onSignupSuccess = async (userInfo) => {
        // En compras anónimas, la orden se obtiene de la cookie en forma segura 
        const checkoutCookie = getCheckoutCookie();
        // Actualizar la persona en la compra activa
        const response = await updatePersonInCart(checkoutCookie?.orderId, userInfo?.person_id);
        dispatch(setOrder(response));
        dispatch(setOrderProducts(response?.cart_details));
        // Actualizar reducer de la persona
        dispatch(setPersonInfo(userInfo));
        // Actualizar la cookie con los datos del usuario creado
        setCheckoutCookie({
            ...checkoutCookie,
            personInfo: userInfo
        });
    };

    const updatePersonInOrder = async () => {
        try {
            setLoading(true);
            const { firstName, lastName1, lastName2, phoneNumber, email1 } = formulario;
            const emailValidation = await SingupServiceSDK.validateEmail(email1);
            if (emailValidation?.canSignup) {
                const userData = {
                    first_name: firstName,
                    last_name: lastName1 + " " + lastName2,
                    email: email1,
                    phone: phoneNumber,
                    origin_id: PERSON_ORIGIN_CAT.CHECKOUT
                };
                const responseSignup = await SingupServiceSDK.signup(userData, location.search);
                if (!responseSignup?.error) {
                    await onSignupSuccess({
                        userName: `${firstName} ${lastName1} ${lastName2}`,
                        email: email1,
                        phone_number: phoneNumber,
                        person_id: responseSignup.person_id
                    });
                } else {
                    log("error", responseSignup?.error);
                    setAlertHidden(false);
                    setSignupResponse("Tuvimos un error al crear tu cuenta.");
                }
            } else {
                setLoginInvite(true);
                setAlertHidden(false);
                setSignupResponse(emailValidation?.reason);
            }
            setLoading(false);
        }
        catch (e) {
            log("error", e.message);
        }
    };

    const handleProceed2Pay = async () => {
        const { _errors, isFormComplete } = validateFormFields({
            formFields: formulario
        });

        if (!isFormComplete) return setFormErrors({ ..._errors });

        setFormErrors({});
        updatePersonInOrder();
    }

    return (
        <WorkflowPage title="Crea tu cuenta">
            <Grid item xs={12} className='mt-2'>
                <Typography variant='body1'>¿Ya tienes cuenta?
                    <Link className="ml-1 text-orange-300" onClick={onLogin} style={{ cursor: 'pointer' }}>
                        Inicia sesión aquí
                    </Link>
                </Typography>
            </Grid>
            <Grid container spacing={2} className="my-4">
                <Grid item md={10} xs={12} className='mb-2'>
                    <TextField
                        name="email1"
                        variant="outlined"
                        placeholder="Ingresar correo electrónico"
                        fullWidth
                        required
                        size="small"
                        onChange={handleInputChange}
                        error={Boolean(formErrors.email1)}
                        helperText={formErrors.email1}
                        InputLabelProps={{ shrink: true }}
                        label="Correo electrónico"
                    />
                </Grid>
                <Grid item md={10} xs={12} className=''>
                    <TextField
                        name="email2"
                        variant="outlined"
                        placeholder="Confirmar correo electrónico"
                        fullWidth
                        required
                        size="small"
                        onChange={handleInputChange}
                        error={Boolean(formErrors.email2)}
                        helperText={formErrors.email2}
                        InputLabelProps={{ shrink: true }}
                        label="Confirmar correo electrónico"
                    />
                </Grid>
                <Grid item md={10} xs={12}>
                    <Typography className='text-dark-black-100' variant='caption'>
                        Asegúrate de usar tu propio correo, ya que en adelante este será tu usuario y principal medio de contacto con la plataforma.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} className="mb-4">
                <Typography variant="h5">Información del estudiante</Typography>
            </Grid>
            <Grid item md={10} xs={12} className='mb-4 pt-3'>
                <TextField
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                    placeholder="Ingresa tu(s) nombre(s)"
                    onChange={handleInputChange}
                    error={Boolean(formErrors.firstName)}
                    helperText={formErrors.firstName}
                    InputLabelProps={{ shrink: true }}
                    label="Nombre(s)"
                />
            </Grid>
            <Grid container spacing={2} className='mb-2'>
                <Grid item md={5} xs={12} className='mb-2'>
                    <TextField
                        name="lastName1"
                        variant="outlined"
                        placeholder="Ingresa tu apellido paterno"
                        fullWidth
                        required
                        size="small"
                        onChange={handleInputChange}
                        error={Boolean(formErrors.lastName1)}
                        helperText={formErrors.lastName1}
                        InputLabelProps={{ shrink: true }}
                        label="Apellido paterno"
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        name="lastName2"
                        variant="outlined"
                        placeholder="Ingresa tu apellido materno"
                        fullWidth
                        required
                        size="small"
                        onChange={handleInputChange}
                        error={Boolean(formErrors.lastName2)}
                        helperText={formErrors.lastName2}
                        InputLabelProps={{ shrink: true }}
                        label="Apellido materno"
                    />
                </Grid>
            </Grid>
            <Grid item md={10} xs={12} className='mb-3'>
                <TextField
                    name="phoneNumber"
                    variant="outlined"
                    placeholder="Número a 10 dígitos"
                    fullWidth
                    required
                    size="small"
                    inputProps={{ maxLength: 10 }}
                    onChange={handleInputChange}
                    error={Boolean(formErrors.phoneNumber)}
                    helperText={formErrors.phoneNumber}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+52</InputAdornment>,
                        shrink: "true"
                    }}
                    label="Número de celular"
                />
            </Grid>
            <Grid item xs={12} className='mb-4'>
                <Typography className='text-dark-black-200' variant='caption'>
                    *Campos obligatorios
                </Typography>
            </Grid>
            <Grid item md={10} xs={12} className='mb-4'>
                <CheckTermsConditions
                    accept={acceptTerms}
                    startText="Confirmo que todos los datos son correctos y acepto los"
                    colorLink="secondary"
                    onChange={() => setAcceptTerms(!acceptTerms)}
                    variant="caption"
                    endText=""
                    secondaryLinkText="las políticas de privacidad."
                />
            </Grid>
            <Grid item xs={12} className="mb-4">
                <SignupAlert
                    alertHidden={alertHidden}
                    onAlertClose={() => setAlertHidden(true)}
                    onLogin={onLogin}
                    loginInvite={loginInvite}
                    signupResponse={signupResponse}
                />
            </Grid>
            <Grid item xs={12} className="mb-4">
                <Grid container justifyContent='flex-start'>
                    <Button fullWidth={breakpoint} disabled={!acceptTerms} onClick={handleProceed2Pay} color='primary' variant='contained'>
                        Siguiente
                    </Button>
                </Grid>
            </Grid>
            <SignupProgressModal
                openModal={loading}
                onModalClose={!loading}
                loading={loading}
            />
        </WorkflowPage>
    )
};

export default SignupForm;