import React from 'react';

// @material-ui
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

const CheckoutMaintenanceAlert = ({ display = true }) => {
    if (!display) return null;
    return (
        <Alert variant="filled" severity="error" color="error" icon={<i class="ri-alert-fill text-error-300"></i>} className="mb-3">
            <Typography component="div" variant="body2" className="semi-bold">¡Atención!</Typography>
            <Typography component="p" variant="body2" className="mt-2 text-gray-500">Proceso en mantenimiento. Intenta más tarde.</Typography>
        </Alert>
    );
};

export default CheckoutMaintenanceAlert;