import React from "react";

// @redux
import { connect } from "react-redux";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinkIcon from "@material-ui/icons/InsertLink";
import { InputAdornment } from "@material-ui/core";

import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";

// shared/components
import Breadcrumb from "../../../shared/components/Breadcrumb/Breadcrumb";
import Loader from "../../../shared/components/Loader/LoaderContent";
import Picker from "../../../shared/components/Form/Picker";
import Select from "../../../design-system/components/Form/Select/Select";
import Toast from "../../../shared/components/Toast/Toast";

import moment from "moment";
//SDK
import { NotificationService,AdminNotifService } from '@sdk-point/talisis'; 

const AdminNotifServiceSDK = new AdminNotifService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const NotificationServiceSDK = new NotificationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const items = [
    { to: "/notifications", label: "Notificaciones" },
    { label: "Administrador de Notificaciones" },
]

const NotificationsEdit = (props) => {
    const { history, match } = props;

    const classes = {};
    const today = new Date();
    const fontFamily = { fontFamily: "'Source Sans Pro', sans-serif" };

    const [loading, setLoading] = React.useState(false);
    const [formInfo, setFormInfo] = React.useState({
        is_critical: false,
        title: "",
        publish_date: today,
        publish_hour: "",
        persons: [],
        groups: [],
        type_id: "",
        body: "",
        url: "",
        action_required: false,
        unitsList: [],
    });
    const [minDate, setMinDate] = React.useState(new Date());
    const [notificationTypes, setNotificationTypes] = React.useState([]);
    const [toastProps, setToastProps] = React.useState({
        message: "",
    });
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await reciveNotificationsTypes();
            await setData();
            setLoading(false);
        }
        fetchData();
    }, []);

    const setData = async () => {
        if (match.params.id) {
            const notification = await NotificationServiceSDK.get(match.params.id);
            const newFormInfo = {
                is_critical: notification.is_critical,
                title: notification.title,
                persons: [],
                groups: [],
                type_id: notification.notification_type,
                body: notification.content,
                url: notification.url,
                action_required: notification.action_required,
                unitsList: [],
            };

            const createdDate = notification.created_date.replace("T", " ").replace("Z", "");
            const publishDate = notification.publish_date.replace("T", " ").replace("Z", "");
            if (publishDate) {
                setMinDate(new Date(`${publishDate}`));
                newFormInfo['publish_date'] = new Date(publishDate);
                newFormInfo['publish_hour'] = new Date(publishDate).toTimeString().slice(0, 5);
            }
            else {
                setMinDate(new Date(`${createdDate}`));
                newFormInfo['publish_date'] = new Date(createdDate);
                newFormInfo['publish_hour'] = new Date(createdDate).toTimeString().slice(0, 5);
            }
            setFormInfo(newFormInfo);
        }
    };

    const reciveNotificationsTypes = async () => {
        await AdminNotifServiceSDK.getNotifTypes()
            .then(data => {
                const notificationTypesCat = data.body;
                notificationTypesCat.map(element => {
                    element.label = element.name;
                    return element;
                });
                setNotificationTypes(notificationTypesCat);
            })
            .catch(e => {
                setToastProps({
                    open: true,
                    message: `Ops! Ha ocurrido un error al cargar la información: ${e}`,
                    severity: "error",
                });
            });
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (value) {
            delete errors[name];
        }
        switch (name) {
            case "is_critical":
                value = !formInfo.is_critical;
                break;
            case "action_required":
                value = !formInfo.action_required;
                break;
        }
        setFormInfo({ ...formInfo, [name]: value });
    };

    const handleTimeFormat = () => {
        const timeSelector = document.getElementById("time");
        const splitHour = timeSelector.value.split(":");
        const hour = splitHour[0];
        let minutes = splitHour[1];
        minutes = Math.round(minutes / 15) * 15;
        minutes = (minutes === 60) ? 0 : minutes;
        minutes = String(minutes).padStart(2, 0);
        const newTime = `${hour}:${minutes}`;
        timeSelector.value = newTime;
        setFormInfo({ ...formInfo, publish_hour: newTime });
    };

    const validate = () => {
        const errors = {};
        const requiredFields = [
            "title",
            "publish_date",
            "publish_hour",
            "type_id",
        ];

        for (const field of requiredFields) {
            if (formInfo.is_critical && field === "publish_hour") {
                continue;
            }
            if (!formInfo[field]) {
                errors[field] = "Campo Requerido";
            }
        }

        setErrors(errors);
        return Boolean(Object.keys(errors).length === 0);
    }

    const handleSubmit = async () => {
        if (validate()) {
            try {
                setLoading(true);
                setErrors({});

                const body = {
                    title: formInfo.title,
                    content: formInfo.body,
                    notification_type: formInfo.type_id,
                    url: formInfo.url,
                    action_required: formInfo.action_required ? 1 : 0,
                    is_critical: formInfo.is_critical ? 1 : 0,
                };
                body['publish_date'] = (!formInfo.is_critical) ? `${moment(formInfo.publish_date).format("YYYY-MM-DD")} ${formInfo.publish_hour}:00` : null;

                const ok = await NotificationServiceSDK.update(match.params.id, body);
                if (ok) {
                    setToastProps({ open: true, message: "La Notificación fue editada correctamente." });
                }
            }
            catch (e) {
                setLoading(false);
                setErrors({ message: "Ha ocurrido un error al intentar editar la notificación." });
                setToastProps({
                    open: true,
                    message: `Ops! Ha ocurrido un error al intentar editar la notificación. ${e}`,
                    severity: "error",
                });
            }
        }
    };

    const handleFinishedToast = () => {
        if (Object.keys(errors).length > 0) {
            setToastProps({ ...toastProps, open: false });
            return;
        }
        else {
            history.push("/notifications");
        }
    }

    return (
        <React.Fragment>
            <Breadcrumb items={items} />
            <Loader loading={loading}>
                <Card>
                    <CardContent className="NotificationsCardContent">
                        <span className="NotificationsCardContent">
                            <Checkbox color="primary"/>Previo a la fecha límite
                        </span>
                    </CardContent>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Typography variant="subtitle2" style={fontFamily}><b>Mensaje</b></Typography>
                                <TextField
                                    required
                                    variant="outlined"
                                    placeholder="Ingresar texto"
                                    label="Título de notificación"
                                    fullWidth
                                    margin="dense"
                                    name="title"
                                    value={formInfo.title}
                                    onChange={handleChange}
                                    error={Boolean(errors.title)}
                                    helperText={errors.title}
                                />
                            </Grid>
                            <Grid item xs={7} className="NotificationsIsCriticalGrid">
                                <Typography variant="subtitle2" style={{ margin: "0", padding: "0", verticalAlign: "middle", display: "table-cell" }}>
                                    <b>Enviar ahora</b><Switch name="is_critical" color="primary" checked={formInfo.is_critical} onChange={handleChange} />
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className="NotificationsGridLeft">
                                <Typography variant="subtitle2" style={fontFamily}><b>Fecha de Publicación</b></Typography>
                                <Picker
                                    required
                                    margin="dense"
                                    name="publish_date"
                                    value={formInfo.publish_date}
                                    onChange={handleChange}
                                    label="Fecha de Publicación"
                                    minDate={minDate}
                                    disabled={formInfo.is_critical ? true : false}
                                    error={Boolean(errors.publish_date)}
                                    helperText={errors.publish_date}
                                />
                                <span className="note">Zona Horaria: Ciudad de México, CDMX (GMT-5)</span>
                            </Grid>
                            <Grid item xs={2} className="NotificationsGridRight">
                                <Typography variant="subtitle2" style={fontFamily}><b>Hora de Publicación</b></Typography>
                                <TextField
                                    required
                                    id="time"
                                    name="publish_hour"
                                    label="Hora de Publicación"
                                    type="time"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleTimeFormat();
                                    }}
                                    margin="dense"
                                    className={classes.hourPicker}
                                    value={formInfo.publish_hour}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    InputProps={{
                                        className: classes.hourPickerInput,
                                    }}
                                    disabled={formInfo.is_critical ? true : false}
                                    error={Boolean(errors.publish_hour)}
                                    helperText={errors.publish_hour}
                                />
                            </Grid>
                            <Grid item xs={2}>&nbsp;</Grid>
                            <Grid item xs={5} className="NotificationsGridLeft">
                                <Typography variant="subtitle2" style={fontFamily}><b>Categoría de la Notificación</b></Typography>
                                <Select
                                    key="type_id"
                                    required
                                    items={notificationTypes}
                                    value={formInfo.type_id}
                                    label="Categoría de notificación"
                                    name="type_id"
                                    onChange={handleChange}
                                    error={Boolean(errors.type_id)}
                                    helperText={errors.type_id}
                                    SelectProps={{
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Ingresar texto"
                                    label="Mensaje (Opcional)"
                                    fullWidth
                                    margin="dense"
                                    name="body"
                                    value={formInfo.body}
                                    onChange={handleChange}
                                    multiline
                                    rows={8}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="URL"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><LinkIcon /></InputAdornment>,
                                    }}
                                    margin="dense"
                                    name="url"
                                    value={formInfo.url ? formInfo.url : ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} className="NotificationsGridLeft">
                                <Typography variant="subtitle2" className="mb-4" style={fontFamily}>
                                    <b>Acción requerida</b><Switch name="action_required" color="primary" checked={formInfo.action_required} onChange={handleChange} />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} className="NotificationsGridRight">
                                <Box display="flex" justifyContent="flex-end" width="100%" >
                                    <Button color="primary" variant="contained" onClick={handleSubmit}>Editar</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Loader>
            <Toast {...toastProps} onFinished={handleFinishedToast} />
        </React.Fragment>
    );
}

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(NotificationsEdit);