import React from 'react';

// @material-ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// assets
import useStyles from '../../../../assets/styles/jss/pages/checkout/checkout';
import clsx from 'clsx';

const OrderSummaryCard = ({ title, className, titleVariant, titleClassName, showDivider, children }) => {
    const classes = useStyles();
    
    return (
        <Card variant='outlined'>
            <CardContent className={className || classes.orderSummaryCard}>
                {title && <Box className={classes.orderSummaryCardTitle}>
                    <Typography variant={titleVariant} className={titleClassName}>{title}</Typography>
                </Box>}
                {title && showDivider && <Divider light className={clsx(classes.divideFullWidth, 'mb-2')} />}
                <Box>
                    {children}
                </Box>
            </CardContent>
        </Card>
    )
};

export default OrderSummaryCard;