import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
// @redux
import {connect} from 'react-redux';
import {useSelector} from 'react-redux';
// @design-system
import ChipFilter from '../../design-system/components/Chip/ChipFilter';
import LoaderContent from '../../design-system/components/Loader/LoaderContent';
import NothingFound from '../../shared/components/NothingFound/NothingFound';
import Toast from '../../design-system/components/Toast/Toast';
import Table from '../../design-system/components/Table/Table';
import Select from '../../design-system/components/Form/Select/Select';
import dateFormat from "../../design-system/utils/dateFormat";
import {ISO_DATE_FORMAT} from "../../design-system/constants/date-formats";
import Dropdown from '../../design-system/components/Dropdown/Dropdown'
// @material-core
import {
  Icon,
  Grid,
  Link,
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Fade, CardHeader
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// @ThirtyParty
import {clone, concat, findIndex, isEmpty, isNil, join, propEq, toUpper} from 'ramda';
import queryString from 'query-string'
import clsx from 'clsx';
import writeXlsxFile from 'write-excel-file'
import moment from 'moment';
// @local
import {titulacion, excelDegreeColumns} from './ColumnsTable';
import EditForm from './Student/EditForm';
import {degreeStyles} from '../../assets/styles/jss/pages/degreeCertificates/DegreeStyles'
import {hasRole} from '../../services/RoleService';
import {stringContainsExactlyOf} from '../../shared/utils/common';
import {ATTRINPUT, STATUS_PROCESS, API_RESPONSE_STATUS_ERROR} from '../../shared/constants/degree-certificate';
import {ROLE_ADMIN_DEGREE} from '../../shared/constants/roles';
import {SOURCE_ACADEMIC_DEGREE_CERTIFICATE, SOURCE_COMPANY_DEGREE_CERTIFICATE} from '../../shared/constants/sources';
// SDK
import {DegreeAndCertificateService, RecordDocumentService} from '@sdk-point/talisis';
import {ConsultanciesService} from '@sdk-point/talisis';
import Close from "@material-ui/icons/Close";

const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const DegreeServiceSDK = new DegreeAndCertificateService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RecordDocumentsSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

let STATUSPROC = {}

const DEGREE_FILTER = [
  {id: 'ALL', label: 'Todos', icon: "ri-team-line"},
  {
    id: 'DOWN',
    label: 'Descargar Excel',
    icon: "ri-download-line",
    condition: {status: [STATUS_PROCESS.CANDIDATE, STATUS_PROCESS.VALIDATE, STATUS_PROCESS.APTO_PROCESS, STATUS_PROCESS.INITIAL]}
  },
  {
    id: 'REST',
    label: 'Reiniciar Proceso',
    icon: "ri-restart-line",
    condition: {status: [STATUS_PROCESS.VALIDATE, STATUS_PROCESS.APTO_PROCESS, STATUS_PROCESS.CANDIDATE]}
  },
  // {id: 'VALI', label: 'Validar', icon: "ri-check-line",condition:{ status: ["VAL"] }},
  {
    id: 'TITU',
    label: 'Iniciar Titulación',
    icon: "ri-folder-shared-line",
    condition: {status: [STATUS_PROCESS.APTO_PROCESS]}
  },
]

const DegreeTablePage = (props) => {

  const classes = degreeStyles();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.userReducer.user);

  const [toast, setToast] = React.useState({toastProps: {message: '', open: false}})
  const [columns, setColumns] = React.useState(titulacion)
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({data: [], totalPages: 0, dataSource: []})
  const [btnActions, setBtnActions] = React.useState({id: 'ALL', label: 'Todos'});
  const [inputSearch, setInputSearch] = React.useState('');
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [filters, setFilters] = React.useState([]);
  const [selectedList, setSelectedList] = React.useState([]);
  const [isSelectedAll, setIsSelectedAll] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [filterCss, setFilteCss] = React.useState({});
  const [totalRequestLabel, setTotalRequestLabel] = React.useState("");
  const [openModalDetails, setOpenModalDetials] = React.useState({payload: {}, show: false});
  const [openStudentDetails, setOpenStudentDetails] = React.useState(false);
  const [student, setStudent] = React.useState({});
  const [students, setStudents] = React.useState([]);
  const [loadingStudents, setLoadingStudents] = React.useState(false);
  const [loadingStudent, setLoadingStudent] = React.useState(false);
  const [btnTitu, setBtnTitu] = React.useState(true);
  const [isDisabledDegree, setIsDisabledDegree] = React.useState(true);
  const [isCheckBox, setIsCheckBox] = React.useState(false);
  const [campus, setCampus] = React.useState([]);
  const [catalogues, setCatalogues] = React.useState({
    acknowledgment: [],
    modality: [],
    fundLegal: [],
    backgroundType: [],
    status: [],
    observations: [],
    entityFederative: [],
    campus: [],
  });
  const [studentUpdate, setStudentUpdate] = React.useState({secuencia: "", id: 0});
  const [loadingCamp, setLoadingCamp] = React.useState(false);
  const [messageTable, setMessageTable] = React.useState({
    default: {
      caption: 'Selecciona tu criterio de búsqueda dando click en el botón de Filtrado avanzado.',
      subtitle: 'Filtra por unidad operativa, matriculas, campus o nivel académico.'
    },
    data: {
      caption: 'Selecciona tu criterio de búsqueda dando click en el botón de Filtrado avanzado.',
      subtitle: 'Filtra por unidad operativa, matriculas, campus o nivel académico.'
    }
  });
  const [holidays, setHolidays] = React.useState([])
  const [loadingSection, setLoadingSection] = React.useState({
    person: false,
    academic: false,
    degree: false,
    serviceSoc: false,
    instProc: false,
    infoAdc: false,
    reqdegree: false,
  })


  const valueFilterLabels = {
    ou: 'Marca',
    campus: 'Campus',
    level: 'Nivel Academico',
    // percent: 'Porcentaje',
    // process: 'Tipo de Proceso',
  };

  const valueFilterQueryStringURL = {
    ou: 'ou',
    campus: 'campus',
    level: 'level',
    // percent: 'percent',
    // process: 'process'
  };


  React.useEffect(() => {
    setLoading(true)
    let params = queryString.parse(location.search)
    params.ou = 'UNID'
    if (!isNil(params.ids)) {
      setInputSearch(params.ids)
    }
    // if(params.ou && campus.length === 0){
    //   getCampus(params.ou)
    // }
    setSelectedFilters(params);
    hanledFilterUrl(params);
    getAllTitulation()
  }, [])

  React.useEffect(() => {
    const params = {...selectedFilters}
    history.push({search: new URLSearchParams(params).toString()});
    return () => {
    }
  }, [selectedFilters]);

  const getAllTitulation = async () => {
    try {
      let payload = isEmpty(selectedFilters) ? queryString.parse(location.search) : selectedFilters
      if (campus.length === 0 && payload.ou) {
        setLoadingCamp(true)
        //getCampus(payload.ou)
        getCatalogues(payload.ou)
      }
      if (isEmpty(payload) || isNil(payload.ou) /*|| isNil(payload.campus) || isNil(payload.level)*/ || isNil(payload.ids)) {
        // setToast({toastProps: {severity: "error", open: true, message: "Aplica los filtros necesarios."}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }
      payload = {
        ...payload
        , process: "TITULACION"
      }
      //TODO: rol Administracion Titulacion
      // if(hasRole(ROLE_ADMIN_DEGREE)){
      //   payload= {
      //     ...payload,
      //     statusStudent: "EG"
      //   }
      // }

      const response = await DegreeServiceSDK.getStudentsDegreeAndCertificate(payload);
      const alumnos = response?.data || [];

      if (alumnos?.status === "1" || alumnos?.status === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
        const message = alumnos?.message || response?.message
        setToast({toastProps: {severity: "error", open: true, message: `Lista - ${message}`}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }

      if (isEmpty(alumnos)) {
        setToast({toastProps: {severity: "error", open: true, message: `Lista - Alumnos no encontrados.`}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }
      setLoading(false)
      const dataFormat = alumnos.map(row => ({
        ...row,
        id: row.matricula + '-' + row.programa,
        fullName: concat((row.nombre + ' '), (row.primerApellido + ' ' + row.segundoApellido)),
        sincronizacion: row.sincronizacion === 'S' ? 'Sincronizado' : 'No Sincronizado',
        ou: payload.ou,
        id_link: <>{concat((row.nombre + ' '), (row.primerApellido + ' ' + row.segundoApellido))}
          <br/>
          <Link href="#" onClick={() => getStudentsDetails({...row, ou: payload.ou})} color="secondary"
                variant="body2" className="semi-bold">Mostrar Detalles <Icon
            className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
            style={{marginBottom: "-5px"}}/></Link>
        </>,
      }))
      setState({data: dataFormat, dataSource: dataFormat})
    } catch (err) {
      setLoading(false)
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
      } else {
        setToast({
          toastProps: {
            severity: "error",
            open: true,
            message: err?.message || 'Error Internal server Time Out.'
          }
        })
      }
    }
  }

  const getDetails = async (matricula, payload) => {
    let details = {}
    try {
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload, section: 'person'}).then(
        (value) => {
          const data = value?.data?.[0]
          details = {...details, ...data}
          setStudent(prevState1 => ({
            ...prevState1, ...data,
            fullName: concat((data?.nombre + ' '), (data?.primerApellido + ' ' + data?.segundoApellido)),
          }))
          setLoadingSection(prevState => ({...prevState, person: false}))
        },
        (reason) => (setToast({
          toastProps: {
            severity: 'error',
            open: true,
            message: `Ops! Ha ocurrido un error al cargar los datos.`
          }
        }), console.error('ERROR', reason)));
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload, section: 'academic'}).then(
        (value) => (
          details = {...details, ...value?.data?.[0]},
            setStudent(prevState1 => ({...prevState1, ...value?.data?.[0]})),
            setLoadingSection(prevState => ({...prevState, academic: false}))
        ),
        (reason) => (setToast({
          toastProps: {
            severity: 'error',
            open: true,
            message: `Ops! Ha ocurrido un error al cargar los datos.`
          }
        }), console.error('ERROR', reason)));
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload, section: 'modality'}).then(
        (value) => (
          details = {...details, ...value?.data?.[0]},
            setStudent(prevState1 => ({...prevState1, ...value?.data?.[0]})),
            setLoadingSection(prevState => ({...prevState, degree: false}))
        ),
        (reason) => (setToast({
          toastProps: {
            severity: 'error',
            open: true,
            message: `Ops! Ha ocurrido un error al cargar los datos.`
          }
        }), console.error('ERROR', reason)));
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload, section: 'serviceSocial'}).then(
        (value) => (
          details = {...details, ...value?.data?.[0]},
            setStudent(prevState1 => ({...prevState1, ...value?.data?.[0]})),
            setLoadingSection(prevState => ({...prevState, serviceSoc: false}))
        ),
        (reason) => (setToast({
          toastProps: {
            severity: 'error',
            open: true,
            message: `Ops! Ha ocurrido un error al cargar los datos.`
          }
        }), console.error('ERROR', reason)));
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload, section: 'procedence'}).then(
        (value) => (
          details = {...details, ...value?.data?.[0]},
            setStudent(prevState1 => ({...prevState1, ...value?.data?.[0]})),
            setLoadingSection(prevState => ({...prevState, instProc: false}))
        ),
        (reason) => (setToast({
          toastProps: {
            severity: 'error',
            open: true,
            message: `Ops! Ha ocurrido un error al cargar los datos.`
          }
        }), console.error('ERROR', reason)));
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload, section: 'aditional'}).then(
        (value) => (
          details = {...details, ...value?.data?.[0]},
            setStudent(prevState1 => ({...prevState1, ...value?.data?.[0]})),
            setLoadingSection(prevState => ({...prevState, infoAdc: false}))
        ),
        (reason) => (setToast({
          toastProps: {
            severity: 'error',
            open: true,
            message: `Ops! Ha ocurrido un error al cargar los datos.`
          }
        }), console.error('ERROR', reason)));
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload, section: 'requirments'}).then(
        (value) => (
          details = {...details, ...value?.data?.[0]},
            setStudent(prevState1 => ({...prevState1, ...value?.data?.[0]})),
            setLoadingSection(prevState => ({...prevState, reqdegree: false}))
        ),
        (reason) => (setToast({
          toastProps: {
            severity: 'error',
            open: true,
            message: `Ops! Ha ocurrido un error al cargar los datos.`
          }
        }), console.error('ERROR', reason)));
    } catch (err) {
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: `Detalle - Ops! Ha ocurrido un error`}})
        setLoadingStudent(false)
      } else {
        setToast({
          toastProps: {
            severity: "error",
            open: true,
            message: `Detalle - ${err?.message || 'Error Internal server Time Out.'}`
          }
        })
        setLoadingStudent(false)
      }
    }
  }
  const getCatalogues = async (ou) => {
    try {
      Promise.allSettled([
        DegreeServiceSDK.postCatalogueDynamic(ou, "campus").then((value) => ({
          "campus": value.data.map(c => ({
            id: c.id.toString(),
            label: c.description,
            value: c.id.toString()
          }))
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "entiFederativa").then((value) => ({
          "entityFederative": value.data.map(c => ({
            id: c.id.toString(),
            label: c.description,
            value: c.id.toString()
          }))
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "autReconocimiento").then((value) => ({
          "acknowledgment": value.data.map(c => ({
            id: c.id.toString(),
            label: c.description,
            value: c.id.toString()
          }))
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "modTitulacion").then((value) => ({
          "modality": value.data.map(c => ({
            id: c.id.toString(),
            label: c.description,
            value: c.id.toString()
          }))
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "fundLegal").then((value) => ({
          "fundLegal": value.data.map(c => ({
            id: c.id.toString(),
            label: c.description,
            value: c.toString()
          }))
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "observaciones").then((value) => ({
          "observations": value.data.map(c => ({
            id: c.description,
            label: c.description,
            value: c.description
          }))
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "estuAntecedentes").then((value) => ({
          "backgroundType": value.data.map(c => ({
            id: c.id.toString(),
            label: c.description,
            value: c.id.toString()
          }))
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "estatus").then((value) => ({
          "status": value.data.map(c => {
            STATUSPROC = {...STATUSPROC, [c.id]: c.description}
            return {id: c.id.toString(), label: c.description, value: c.id.toString()}
          })
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "diasFestivos", {vpdi_code: `'${ou}'`}).then((value) => ({
          "holidays": value.data.map(day => {
            const _dateFormat = dateFormat(moment(day.exclude_date) || '', ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
            if (!stringContainsExactlyOf(_dateFormat, holidays)) {
              holidays.push(_dateFormat)
            }
            return {id: day.year.toString(), label: day.description, value: day.year.toString()}
          })
        }), (reason) => (console.error('ERROR', reason))),
      ]).then((results) => results.forEach((result) => {
          let catalogue = result.value
          setCatalogues((prevState) => ({
            ...prevState,
            ...catalogue
          }))
          setLoadingCamp(false)
        }
      ))
    } catch (err) {
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: `Catalogos - Ops! Ha ocurrido un error`}})
      } else {
        setToast({
          toastProps: {
            severity: "error",
            open: true,
            message: `Catalogos - ${err?.message || 'Error Internal server Time Out.'}`
          }
        })
      }
    }
  }
  const getStudentProfilePic = async (pi) => {
    const resp = await DegreeServiceSDK.getStudentProfilePic(pi);
    const dataPic = resp?.data
    if (!isEmpty(dataPic)) {
      setStudent(prevState => ({...prevState, ...dataPic[0]}))
    }
  }
  const getStudentDetailsReqs = async (matriculate, mod, dataInfo) => {
    let payload = {input: {}}
    Object.keys(ATTRINPUT[mod]).forEach((key) => {
      if (key === "inputs") {
        Object.keys(ATTRINPUT[mod][key]).forEach(i => {
          if (dataInfo[i]) {
            let value = dataInfo[i]
            let index = ATTRINPUT[mod][key][i]
            payload = {
              ...payload,
              input: {
                ...payload.input,
                [index]: value,
              }
            }
          }
        })
      }
      if (dataInfo[key]) {
        let value = dataInfo[key]
        let index = ATTRINPUT[mod][key]
        payload = {
          ...payload,
          [index]: value,
        }
      }
    })
    payload = {
      ...payload,
      section: mod
    }
    const response = await DegreeServiceSDK.updateStudentDegreeDetails(matriculate, payload);
    const data = response?.data
    if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
      setStudent(prevStudent => ({...prevStudent}))
      const message = data?.message || response?.message
      setToast({toastProps: {severity: "error", open: true, message: `Submit - ${message}`}})
      return
    }
    setState(prevState => ({
      data: prevState.data.map(r => {
        if (r.matricula === payload.matricula && r.programa === payload.programa) {
          r.secuencia = data.seqEncabezado
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
        }
        return {...r}
      }), dataSource: prevState.dataSource.map(r => {
        if (r.matricula === payload.matricula && r.programa === payload.programa) {
          r.secuencia = data.seqEncabezado
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
        }
        return {...r}
      })
    }));
    setStudent(prevState => ({
      ...prevState, porcentajeEstatus: data.porcentajeEstatus, status: data.estatusProceso,
      statusDesc: STATUSPROC[data.estatusProceso]
    }))
  }
  const getStudentDocuments = async (payload) => {
    const {ou, secuencia: secuenciaEncabezado, status} = payload
    delete payload.ou
    delete payload.secuencia
    delete payload.status
    const responseDoc = await RecordDocumentsSDK.getDocumentsCheckList(payload)

    const documents = !responseDoc.success ? responseDoc.message : responseDoc.data
    let reqIndDocumentacion = 'N'
    const docs = []
    if (Array.isArray(documents) && documents.length > 0) {
      documents.forEach(doc => {
        if (Boolean(doc.is_loaded)) {
          docs.push(doc)
        }
      })
      reqIndDocumentacion = docs.length === documents.length ? 'S' : 'N'
    }
    if (reqIndDocumentacion === 'S') {
      if(!! stringContainsExactlyOf(status, ['CAND', 'VAL'])) {
        getStudentDetailsReqs(payload.user, 'reqdegree', {ou, secuenciaEncabezado, reqIndDocumentacion})
      }
    }
    setStudent(prevState => ({...prevState, documents, reqIndDocumentacion}))
  }
  const getStudentPercent = async (payload) => {
    const response = await DegreeServiceSDK.getStudentPercent(payload)
    const data = response?.data
    if ((!isNil(response.success) && !response.success) || Number(data?.status) === API_RESPONSE_STATUS_ERROR ||
      isNil(data?.porcentajeEstatus)) {
      setStudent(prevStudent => ({...prevStudent}))
      const message = isNil(data?.porcentajeEstatus) ? 'No se Proceso la peticion.' : data?.message || response?.message
      setToast({toastProps: {severity: "error", open: true, message: `Detalle obtener procentaje - ${message}`}})
      return
    }
    setState(prevState => ({
      data: prevState.data.map(r => {
        if (r.matricula === payload.matricula && r.programa === payload.programa) {
          r.secuencia = data.seqEncabezado
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
        }
        return {...r}
      }), dataSource: prevState.dataSource.map(r => {
        if (r.matricula === payload.matricula && r.programa === payload.programa) {
          r.secuencia = data.seqEncabezado
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
        }
        return {...r}
      })
    }));
    setStudent(prevState => ({
      ...prevState, porcentajeEstatus: data.porcentajeEstatus, status: data.estatusProceso,
      statusDesc: STATUSPROC[data.estatusProceso]
    }))
  }
  const getStudentsDetails = async ({
                                      matricula,
                                      programa,
                                      ou,
                                      secuencia,
                                      fechaInicioCarrera,
                                      campus,
                                      fechaFinCarrera,
                                      crseReq,
                                      crseUse,
                                      porcentajeMaterias,
                                      porcentajeEstatus,
                                      maxRequestNo,
                                      nivel,
                                      status,
                                      pidm,
                                      correoElectronico
                                    }) => {
    try {
      setLoadingSection({
        person: true,
        academic: true,
        degree: true,
        serviceSoc: true,
        instProc: true,
        infoAdc: true,
        reqdegree: true,
      })
      setLoadingStudent(true)
      setStudent({matricula: null})
      setOpenStudentDetails(true)
      let payload = {
        ou,
        program: programa,
        dateStart: fechaInicioCarrera,
        dateEnd: fechaFinCarrera,
        maxRequestNo,
        crseReq,
        crseUse,
        porcentajeMaterias,
        secuencia: secuencia || null,
        nivel,
        pidm,
        campus,
        process: "TITULACION",
      }
      /* const resp = await DegreeServiceSDK.getStudentProfilePic(pidm);
       const dataPic = resp?.data*/
      getStudentProfilePic(pidm)

      /*const values = await ConsultanciesServiceSDK.getProfilePicUsers(correoElectronico)
      const dataPic = values*/
      /*const responseDoc = await RecordDocumentsSDK.getDocumentsCheckList({
        processTypeId: 2,
        user: matricula,
        program: programa,
        dataType: 'required'
      })

      const documents = !responseDoc.success ? responseDoc.message : responseDoc.data*/
      if (isNil(payload.secuencia)) {
        const responseSequence = await DegreeServiceSDK.postStudentSequence(payload)
        const sequence = responseSequence?.data

        if ((!isNil(responseSequence.success) && !responseSequence.success) || Number(sequence?.status) === API_RESPONSE_STATUS_ERROR) {
          const message = sequence?.message || responseSequence?.message
          setToast({toastProps: {severity: "error", open: true, message: `Detalle Crear Encabezado - ${message}`}})
          setLoadingStudent(false)
          setStudent({})
          setOpenStudentDetails(false)
          return
        }
        setState(prevState => ({
          data: prevState.data.map(r => {
            if (r.matricula === matricula && r.programa === programa) {
              r.secuencia = sequence.seqEncabezado
              r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
                <br/>
                <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                      className="semi-bold">Mostrar Detalles <Icon
                  className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
                  style={{marginBottom: "-5px"}}/></Link>
              </>
            }
            return {...r}
          }), dataSource: prevState.dataSource.map(r => {
            if (r.matricula === matricula && r.programa === programa) {
              r.secuencia = sequence.seqEncabezado
              r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
                <br/>
                <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                      className="semi-bold">Mostrar Detalles <Icon
                  className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
                  style={{marginBottom: "-5px"}}/></Link>
              </>
            }
            return {...r}
          })
        }));
        setStudentUpdate({secuencia: sequence.seqEncabezado, id: matricula})
        //handleClickStatus(btnActions);


        payload = {...payload, secuencia: sequence.seqEncabezado}
      }
      getStudentDocuments({
        processTypeId: 2,
        user: matricula,
        program: programa,
        dataType: 'required',
        ou,
        secuencia: payload.secuencia,
        status,
      })
      if(!! stringContainsExactlyOf(status, ['CAND', 'VAL'])) {
        getStudentPercent({
          ou,
          process: 'TITULACION',
          seq: payload.secuencia,
          programa,
          matricula
        })
      }
      // const studentDatils = await getDetails(matricula, payload)
      getDetails(matricula, payload)
      /*DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload,section:'person'}).then((value) =>(
        details = {...details, person:value?.data?.[0]} ,
          setStudent(prevState => ({...prevState, ...value?.data?.[0],fullName: concat((value?.data?.[0].nombre + ' '), (value?.data?.[0].primerApellido + ' ' + value?.data?.[0].segundoApellido)),
          secuencia: secuencia||null,
          procesoRequestNo: maxRequestNo,
          revisor: value?.data?.[0].revisor || props.user.email,
          personId: value?.data?.[0].personId || props.user.person_id,
        })),
          setStudentUpdate({secuencia: value?.data?.[0].secuenciaEncabezado || "", id: matricula}) ), (reason) => (console.error('ERROR', reason)));
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload,section:'academic'}).then((value) =>(
        details = {...details, academic:value?.data?.[0]}, setStudent(prevState => ({...prevState, ...value?.data?.[0] }))), (reason) => (console.error('ERROR', reason)));
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload,section:'modality'}).then((value) =>(
        details = {...details, modality:value?.data?.[0]}, setStudent(prevState => ({...prevState, ...value?.data?.[0] }))), (reason) => (console.error('ERROR', reason)))
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload,section:'serviceSocial'}).then((value) =>(
        details = {...details, serviceSocial:value?.data?.[0]}, setStudent(prevState => ({...prevState, ...value?.data?.[0] }))), (reason) => (console.error('ERROR', reason)))
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload,section:'procedence'}).then((value) =>(
        details = {...details, procedence:value?.data?.[0]}, setStudent(prevState => ({...prevState, ...value?.data?.[0] }))), (reason) => (console.error('ERROR', reason)))
      DegreeServiceSDK.getStudentDegreeDetails(matricula, {...payload,section:'requirments'}).then((value) =>(
        details = {...details, requirments:value?.data?.[0]}, setStudent(prevState => ({...prevState, ...value?.data?.[0] }))), (reason) => (console.error('ERROR', reason)))
      console.log(`⛔ - `, details)
      console.log(`⛔ - student`,student)*/

      // let response  ={}
      // const data = response?.data
      // if ((!isNil(response.success) && !response.success) || Number(data?.status) === API_RESPONSE_STATUS_ERROR ) {
      //   setStudent(prevStudent => ({...prevStudent}))
      //   const message = data?.message || response?.message
      //   setToast({toastProps: {severity: "error", open: true, message: `Detalle - ${message}`}})
      //   setLoadingStudent(false)
      //   setStudent({})
      //   return
      // }

      //setStudentUpdate({secuencia: studentDatils?.secuenciaEncabezado || "", id: matricula})
      // const pattr = new RegExp(/^[0-9]*?(?=-)/g)
      // const campus = data[0].campus.match(pattr) || []
      let dataStudent = {
        // ...data[0],
        // ...studentDatils,
        ...payload,
        // fullName: concat((studentDatils?.nombre + ' '), (studentDatils?.primerApellido + ' ' + studentDatils?.segundoApellido)),
        procesoRequestNo: maxRequestNo,
        nivel,
        crseReq,
        crseUse,
        porcentajeMaterias,
        secuenciaEncabezado: payload.secuencia,
        campus,
        status,
        porcentajeEstatus,
        /*   revisor: data[0].revisor || props.user.email,
           personId: data[0].personId || props.user.person_id,*/
        //documents
      }
      /*let reqIndDocumentacion = 'N'
      const docs = []
      if (Array.isArray(documents) && documents.length > 0) {
        documents.forEach(doc => {
          if (Boolean(doc.is_loaded)) {
            docs.push(doc)
          }
        })
        reqIndDocumentacion = docs.length === documents.length ? 'S' : 'N'
      }*/

      // dataStudent = {
      //   ...dataStudent,
      //   reqIndDocumentacion
      // }
      // if (!isEmpty(dataPic)) {
      //   dataStudent = {
      //     ...dataStudent,
      //     //...dataPic[0]
      //   }
      // }
      setStudent(prevState => ({...prevState, ...dataStudent}))
      setLoadingStudent(false)
    } catch (err) {
      setLoadingStudent(false)
      setStudent({})
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: "Detalle - Ops! Ha ocurrido un error"}})
      } else {
        setToast({
          toastProps: {
            severity: "error",
            open: true,
            message: err?.message || 'Error Internal server Time Out.'
          }
        })
      }
    }
  }

  const handleFinishedToast = () => {
    setToast({toastProps: {open: false, message: ''}})
  }

  const onChangeSearch = (e) => {
    e.preventDefault();
    const {target: {value}} = e
    let newInput = value.replace(/" "|\n|\t+/g, "")
    newInput = newInput.replace(/\s/g, "");
    setInputSearch(newInput);
    let newSelectedFilters = clone(selectedFilters)
    if (isEmpty(newInput) && newSelectedFilters.ids !== undefined) {
      delete newSelectedFilters.ids
    } else {
      newSelectedFilters.ids = newInput
    }
    setSelectedFilters(newSelectedFilters);
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFilters();
    }
  }

  const handleFilters = () => {
    setLoading(true);
    setBtnActions({id: 'ALL', label: 'Todos'});
    setState({data: [], dataSource: []});
    setIsCheckBox(false);
    setShowFilter(false);
    getAllTitulation();

  }

  const handleDeleteChip = (chipToDelete) => () => {
    setFilters(() => filters.filter((chip) => JSON.stringify(chip) !== JSON.stringify(chipToDelete)));
    setSelectedFilters((prevState) => {
      const newState = {...prevState};
      const value = Object.keys(chipToDelete)[0];
      delete newState[valueFilterQueryStringURL[value]];
      return newState;
    });
  }

  const handleChangeDataSelected = (row) => {
    setIsDisabledDegree(false);
    let newSelectedList = clone(selectedList)
    let isSelected = selectedList.filter(data => data.id === row.id)
    setIsSelectedAll(false)
    if (!!isSelected.length) {
      let index = findIndex(propEq(row.id, 'id'))(newSelectedList)
      if (index > -1) {
        newSelectedList.splice(index, 1)
      }
    } else {
      let payload = {
        "id": row.matricula + '-' + row.programa,
        "matricula": row.matricula,
        "status": row.status || "",
        "program": row.programa || "",
        "dateStart": row.fechaInicioCarrera || "",
        "dateEnd": row.fechaFinCarrera || "",
        "seq": row.secuencia || "",
        "ou": selectedFilters.ou,
        "maxRequestNo": row.maxRequestNo
      }
      newSelectedList.push(payload)
    }
    newSelectedList.forEach(row => {
      if (!!stringContainsExactlyOf(row.status, [STATUS_PROCESS.CANDIDATE, STATUS_PROCESS.VALIDATE])) {
        setIsDisabledDegree(true)
      }
    })
    setSelectedList(newSelectedList)
  };

  const handleChangeAllDataSelected = (isChecked) => {
    setIsDisabledDegree(true);
    let newSelectedList = clone(selectedList)
    if (isChecked) {
      setIsSelectedAll(true)
      newSelectedList = []
      state.data.forEach(row => newSelectedList.push({
        "id": row.matricula + '-' + row.programa,
        "matricula": row.matricula,
        "status": row.status || "",
        "program": row.programa || "",
        "dateStart": row.fechaInicioCarrera || "",
        "dateEnd": row.fechaFinCarrera || "",
        "seq": row.secuencia || "",
        "maxRequestNo": row.maxRequestNo,
        "ou": selectedFilters.ou
      }))
    } else {
      newSelectedList = []
    }
    newSelectedList.forEach(row => {
      if (!!stringContainsExactlyOf(row.status, [STATUS_PROCESS.CANDIDATE, STATUS_PROCESS.VALIDATE])) {
        setIsDisabledDegree(true)
      }
    })
    setSelectedList(newSelectedList)

  };

  const hanledFilterUrl = (params) => {
    let filters = [];
    Object.keys(params).forEach((k, i) => {
      let filter = {};
      if (valueFilterLabels[k]) {
        filter[k] = params[k];
        filter['label'] = `${valueFilterLabels[k]}: ${params[k]}`;
        filters.push(filter);
      }
    });
    setFilters(filters);
  }

  const handleChangeFilter = (e) => {
    const {target: {value, name}} = e
    if (name === 'ou') {
      setLoadingCamp(true)
      // setCampus([])
      // getCampus(value)
      getCatalogues(value)
    }
    let filter = {};
    filter[name] = value;
    filter['label'] = `${valueFilterLabels[name]}: ${value}`;
    setFilters([...filters, filter]);
    setSelectedFilters({...selectedFilters, [valueFilterQueryStringURL[name]]: value});
  }


  const hanledCloseModalDetails = () => {
    setOpenModalDetials({payload: {}, show: false})
  }

  const hanledCloseStudentDetails = () => {
    setOpenStudentDetails(false)
    setStudent({})
  }

  const handleFilter = () => {
    setLoading(true);
    setBtnActions({id: 'ALL', label: 'Todos'});
    setShowFilter(false);
    setFilteCss({});
    setState({data: [], dataSource: []});
    setIsCheckBox(false);
    setShowFilter(false);
    getAllTitulation();
  }


  const handleClickSort = (colum) => {
    colum.direction = colum.direction === 'desc' ? 'asc' : 'desc';

    const tableColumns = columns;

    tableColumns.map(it => {
      if (it.sort) {
        it.active = colum.name === it.name;
        it.direction = colum.name === it.name ? colum.direction : 'desc'
      }
    })

    setColumns(tableColumns)
  }

  const handledTitulation = async (data) => {
    try {
      console.log("🚀 ~ file: DegreeTablePage.jsx:516 ~ handledTitulation ~ data:", data)

    } catch (err) {
      console.log(err);
    }
  }

  const handledSubmit = async (matricula, dataInfo, mod) => {
    try {
      // setLoadingStudent(true)
      setLoadingSection(prevState => ({...prevState, [mod]: true}))
      //console.log(`⛔ - `,dataInfo)
      let payload = {input: {}}
      Object.keys(ATTRINPUT[mod]).forEach((key) => {
        if (key === "inputs") {
          Object.keys(ATTRINPUT[mod][key]).forEach(i => {
            if (dataInfo[i]) {
              let value = dataInfo[i]
              let index = ATTRINPUT[mod][key][i]
              payload = {
                ...payload,
                input: {
                  ...payload.input,
                  [index]: value,
                }
              }
            }
          })
        }
        if (dataInfo[key]) {
          let value = dataInfo[key]
          let index = ATTRINPUT[mod][key]
          payload = {
            ...payload,
            [index]: value,
          }
        }
      })
      payload = {
        ...payload,
        section: mod
      }
      // Object.keys(dataInfo).forEach((key, index) => {
      //   let value = ""
      //   console.log(`⛔ - key `,key)
      //   if (ATTRINPUT[mod][key]) {
      //     if (!isNil(dataInfo[key])) {
      //       value = dataInfo[key]
      //     }
      //     payload.input[ATTRINPUT[mod][key]] = toUpper(value).trim()
      //   }
      // });
      // payload.input = {
      //   ...payload.input,
      //   revisor: props.user.email,
      //   personId: props.user.person_id,
      //   secuencia: studentUpdate.secuencia,
      //   ou: selectedFilters.ou,
      // }
      const response = await DegreeServiceSDK.updateStudentDegreeDetails(matricula, payload);
      const data = response?.data

      if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
        setStudent(prevStudent => ({...prevStudent}))
        const message = data?.message || response?.message
        setToast({toastProps: {severity: "error", open: true, message: `Submit - ${message}`}})
        setLoadingStudent(false)
        setLoadingSection(prevState => ({...prevState, [mod]: false}))
        return
      }
      //const statusProc = JSON.parse(data.statusProc)
      const newData = state.dataSource.map(r => {
        if (r.matricula === matricula && r.programa === student.carrera) {
          r.secuencia = data.seqEncabezado
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
          r.porcentajeEstatus = data.porcentajeEstatus
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
        }
        return {...r}
      })
      //console.log(`⛔ - load submit`,data, dataInfo,student,payload) //,00279713,00295703,00109699,00249150,00328423,00093116,00192149,00064920,00326685,00111866,00064725,00238425,00158544
      // setState({data:newData,dataSource:[newData]})
      setState(prevState => ({data: [], dataSource: newData}));
      setStudentUpdate({secuencia: data.seqEncabezado, id: matricula})
      setStudent(prevStudent => ({
        ...prevStudent,
        ...dataInfo,
        revisor: props.user.email,
        personId: props.user.person_id,
        secuenciaEncabezado: data.seqEncabezado,
        secuencia: data.seqEncabezado,
        porcentajeEstatus: data.porcentajeEstatus
      }))
      setToast({toastProps: {severity: "success", open: true, message: "Informacion Actualizada."}})
      handleClickStatus(btnActions);
      setLoadingStudent(false)
      setLoadingSection(prevState => ({...prevState, [mod]: false}))
    } catch (err) {
      setToast({
        toastProps: {
          severity: "error",
          open: true,
          message: err?.message || 'Error Internal server Time Out.'
        }
      })
      setLoadingStudent(false)
      setLoadingSection(prevState => ({...prevState, [mod]: false}))
    }
  }

  const handledDownLoadExcel = async () => {

    try {

      const payload = {
        students: selectedList
      }
      const response = await DegreeServiceSDK.postStudentsDegreeDetails(payload);

      if (!response.success) {
        handledToastFailed(response);
        setLoadingStudents(false)
        setLoading(false);
        return
      }
      let data = response?.data

      if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
        setStudent(prevStudent => ({...prevStudent}))
        const message = data?.message || response?.message
        setToast({toastProps: {severity: "error", open: true, message: `Obtener Excel - ${message}`}})
        setLoadingStudent(false)
        setLoading(false);
        setStudent({})
        return
      }

      let students = [];
      const patter = new RegExp(/^[0-9]*?(?=-)/g)
      data = data.map(row => {
        const formatCampus = row.campus.match(patter) || []

        if (isEmpty(formatCampus)) {
          return row
        }
        return {...row, campus: formatCampus[0]}
      })
      await writeXlsxFile(data, {
        schema: excelDegreeColumns, // (optional) column widths, etc.
        fileName: `Reporte de TITULACION ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`
      })
      //TODO: FIX THIS
      payload.students.forEach(r => students.push({
        "matricula": r.matricula,
        "secuencia": data.filter(row => r.matricula.includes(row.matricula))[0].secuenciaEncabezado,
        "id": r.id
      }))
      const ids = students.map(r => r.id)
      const newData = state.dataSource.map(r => {
        if (stringContainsExactlyOf(r.id, ids)) {
          if (r.status === STATUS_PROCESS.CANDIDATE) {
            r.status = STATUS_PROCESS.VALIDATE
            r.statusDesc = STATUSPROC[STATUS_PROCESS.VALIDATE]
            r.secuencia = students.filter(row => row.id === r.id)[0].secuencia
            r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
              <br/>
              <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                    className="semi-bold">Mostrar Detalles <Icon
                className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')}
                fontSize='small' style={{marginBottom: "-5px"}}/></Link>
            </>
          }
        }
        return {
          ...r
        }
      })

      setState(prevState => ({...prevState, dataSource: newData}));
      setLoadingStudents(false)
      setLoading(false);
      handleClickStatus(btnActions);
    } catch (err) {
      setToast({
        toastProps: {
          severity: "error",
          open: true,
          message: `Excel - ${err?.message || 'Error Internal server Time Out.'}`
        }
      })
      setLoadingStudents(false)
      setLoading(false);
    }

  }

  const hanledStatusStudents = async (payload) => {
    try {

      const response = await DegreeServiceSDK.postStatusStudents(payload)
      let data = response?.data

      if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
        setStudent(prevStudent => ({...prevStudent}))
        const message = data?.message || response?.message
        setToast({toastProps: {severity: "error", open: true, message}})
        setLoadingStudent(false)
        setLoading(false);
        setStudent({})
        return
      }

      let seqsSucess = data.filter(r => r.status === 'success').map(r => r.seq.toString())
      let seqsFailed = data.filter(r => r.status === 'failed').map(r => r.seq.toString())

      const newDataSource = state.dataSource.map(r => {
        if (!isNil(r.secuencia) && stringContainsExactlyOf(r.secuencia.toString(), seqsSucess)) {
          r.status = payload.action === "DEL" ? STATUS_PROCESS.CANDIDATE : payload.action === "INI" ? STATUS_PROCESS.INITIAL : r.status
          r.statusDesc = payload.action === "DEL" ? STATUSPROC[STATUS_PROCESS.CANDIDATE] : payload.action === "INI" ? STATUSPROC[STATUS_PROCESS.INITIAL] : r.statusDesc
          r.secuencia = payload.action === "DEL" ? null : r.secuencia
          r.porcentajeEstatus = payload.action === "DEL" ? null : r.porcentajeEstatus
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => getStudentsDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
        }
        return {...r}
      })
      setState(preState => ({...preState, dataSource: newDataSource}))
      setLoadingStudents(false)
      setLoading(false);

      handleClickStatus(btnActions)
    } catch (err) {
      setToast({
        toastProps: {
          severity: "error",
          open: true,
          message: `Submit Estatus - ${err?.message || 'Error Internal server Time Out.'}`
        }
      })
      setLoadingStudents(false)
      setLoading(false);

    }
  }

  const handleClickStatus = opt => {
    let checkBox = true
    let data = []
    setLoading(true)
    setSelectedList([]);
    setIsSelectedAll(false);
    setBtnActions(opt)
    if (opt.id === "ALL") {
      checkBox = false
      data = state.dataSource
    } else {
      data = state.dataSource.filter(row => !!stringContainsExactlyOf(row.status, opt.condition.status))
      if (opt.id === 'REST') {
        data = data.filter(row => isNil(row?.folio) && !isNil(row.secuencia))
      }
    }
    if (isEmpty(data)) {
      setMessageTable(prevState => ({
        ...prevState,
        data: {
          caption: 'No se encuentra ninguna matrícula para esta acción.',
          subtitle: 'Aplica otro criterio de búsqueda.'
        }
      }))
    } else {
      setMessageTable(prevState => ({...prevState, data: prevState.default}))
    }
    setState(prevState => ({...prevState, data: data}))
    setIsCheckBox(checkBox)
    setLoadingStudent(false)
    setLoading(false);
  }

  const hanledActions = (mod) => {
    setStudents([])
    setLoadingStudents(true)
    const formatIds = join(',', selectedList.map(r => r.matricula))
    const formatList = selectedList.map(r => r.id)
    const students = state.data.filter(student => !!stringContainsExactlyOf(student.id, formatList))
    const studentsSeq = join(',', students.map(s => s.secuencia || null))
    let payload = {ou: selectedFilters.ou}

    if (mod === 'DOWN') {
      setLoading(true)
      handledDownLoadExcel()
      return
    }
    setLoading(true)
    if (mod === "REST") {
      payload = {...payload, sequences: studentsSeq, action: 'DEL'}
      setOpenModalDetials({payload, show: true})
      setLoadingStudents(false)
      setLoading(false)
      return
    }
    if (mod === 'TITU') {
      payload = {...payload, sequences: studentsSeq, action: "INI"}
    }
    hanledStatusStudents(payload)
  }

  const handledToastFailed = ({message}) => {
    setToast({toastProps: {severity: "error", open: true, message}})
  }

  const getOptions = () => hasRole(ROLE_ADMIN_DEGREE) ? DEGREE_FILTER : DEGREE_FILTER.filter(o => o.id !== 'TITU')
  const handleClickInfoTable = (data) => {
    console.log(data)
  }
  const setLoadingAllSections = (flag) => {
    setLoadingSection({
      person: flag,
      academic: flag,
      degree: flag,
      serviceSoc: flag,
      instProc: flag,
      infoAdc: flag,
      reqdegree: flag,
    })
  }

  return (
    <>
      <Card className={classes.cardHeader}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Paper component="form"
                     className={classes.search}
              >
                <IconButton className={classes.iconButton} aria-label="search" onClick={handleFilters}>
                  <SearchIcon/>
                </IconButton>
                <InputBase
                  onChange={onChangeSearch}
                  className={clsx(classes.textArea,'sentry-unmask')}
                  multiline
                  maxRows={4}
                  placeholder={`Para búsquedas múltiples separar matrículas con coma`}
                  name={'searchUser'}
                  value={inputSearch}
                  onKeyDown={onKeyDown}
                  // inputProps={{ 'aria-label': 'search google maps' }}
                  inputProps={{
                    className: classes.bioText,
                    disableunderline: true,
                  }}
                  inputlabelprops={{
                    shrink: true,
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper component="form" className={classes.buttons}>
                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Button
                        color="secondary"
                        variant="outlined"
                        className={clsx(classes.button, showFilter && classes.filterButton)}
                        disabled={loading}
                        onClick={() => {
                          setShowFilter(!showFilter)
                        }}
                        size="small"
                      >
                        <Typography variant='body2' noWrap> Filtro Avanzado</Typography>
                        <Icon className={clsx("text-white", "ri-equalizer-line", 'font-size-20')}
                              fontSize='small' style={{marginLeft: "6px"}}/>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Dropdown
                        options={getOptions()}
                        onClickOption={(data) => {
                          setLoading(true);
                          handleClickStatus(data);
                        }}
                        text={btnActions?.label || 'Acciones'}
                        endIcon={<ArrowDropDownIcon className='font-size-24'/>}
                        style={{height: 40}}
                        variant='contained'
                        className={clsx("bg-fill-stroke-300", classes.button)}
                        disabled={loading}
                        // disabled={!state.data.length}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{height: 40}}
                        className={classes.button}
                        onClick={() => hanledActions(btnActions?.id)}
                        size="small"
                        disabled={((!selectedList.length || isEmpty(btnActions)) || btnActions?.label === 'ALL') || loadingStudents}
                      >
                        <Typography variant='body2'>Confirmar</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ChipFilter chipData={filters} handleDelete={(e) => handleDeleteChip(e)}/>
            </Grid>
          </Grid>
          {showFilter && (
            <Grid container className={classes.grid} spacing={1}>
              <Grid item xs={12} md={3}>
                <Box className={`offset-margin-top-22`}>
                  <Select items={SOURCE_COMPANY_DEGREE_CERTIFICATE}
                          label="Marca"
                          name="ou"
                          onChange={(e) => {
                            handleChangeFilter(e)
                          }}
                          disabled={!isNil(selectedFilters.ou)}
                          value={!isNil(selectedFilters.ou) ? selectedFilters.ou : ''}
                          size="small"/>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className={`offset-margin-top-22`}>
                  <Select items={SOURCE_ACADEMIC_DEGREE_CERTIFICATE}
                          label="Nivel Académico"
                          name="level"
                          onChange={(e) => {
                            handleChangeFilter(e)
                          }}
                          disabled={!isNil(selectedFilters.level)}
                          value={!isNil(selectedFilters.level) ? selectedFilters.level : ''} size="medium"/>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className={`offset-margin-top-22`}>
                  <Select items={catalogues.campus}
                          label="Campus"
                          name="campus"
                          onChange={(e) => {
                            handleChangeFilter(e)
                          }}
                          disabled={!isNil(selectedFilters.campus) || !catalogues.campus.length}
                          loading={loadingCamp}
                          value={!isNil(selectedFilters.campus) ? selectedFilters.campus : ''}
                          size="medium"/>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className={`offset-margin-top-38`}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginLeft: '20%', width: '65%'}}
                    onClick={() => handleFilter()}
                    size="small"
                    disabled={loading}
                  >
                    <Typography variant='body2'>Aplicar</Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <LoaderContent loading={loading} minHeight={500}>
        {state.data.length > 0 ?
          <Card className={classes.CardTable}>
            <React.Fragment>
              <Table
                checkboxs={isCheckBox}
                maxHeight={'800px'}
                columns={columns}
                rows={state.data}
                onClickSort={handleClickSort}
                rowSelectedList={selectedList}
                onChangeSelectedRow={handleChangeDataSelected}
                onChangeAllSelectedRow={handleChangeAllDataSelected}
                checkboxDisabledIcon={true}
                handleClickInfoTable={handleClickInfoTable}
              />
            </React.Fragment>
          </Card>
          :
          !loading && <NothingFound width='200px'
                                    caption={messageTable.data.caption}
                                    subcaption={messageTable.data.subtitle}/>}
      </LoaderContent>
      <Modal
        open={openModalDetails.show}
        onClose={hanledCloseModalDetails}
        className={classes.modal}
      >
        <Card className={classes.card}>
          <CardHeader
            action={
              <IconButton aria-label="go to back" onClick={hanledCloseModalDetails}>
                <Icon className={clsx("text-white", "ri-close-line", 'font-size-24')}/>
              </IconButton>
            }
          />
          <CardContent>
            <Grid container justifyContent='center'>
              <Icon className={clsx("text-white", "ri-restart-line", 'font-size-32')}/>
            </Grid>
            <Grid container className='mt-2' justifyContent='center'>
              <Typography variant="h4" className={classes.title}>Reiniciar proceso</Typography>
            </Grid>
            <Grid container className='mt-2' justifyContent='center'>
              <Typography variant='body1' className={classes.subtitle}>¿Estás seguro de que deseas reiniciar el proceso
                para todos los alumnos seleccionados? </Typography>
            </Grid>
            <Grid container justifyContent='center' className='mt-3' spacing={4}>
              <Button variant='outlined' color="secondary" className={classes.dangerBtn} onClick={() => {
                hanledCloseModalDetails()
              }}>
                Cancelar
              </Button>
              <Button variant='contained' color="primary" onClick={() => {
                setLoading(true)
                setLoadingStudents(true)
                hanledStatusStudents(openModalDetails.payload)
                hanledCloseModalDetails()
              }}>
                Aceptar
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={openStudentDetails}
        onClose={hanledCloseStudentDetails}
        className={classes.modalStudent}
        disableScrollLock={false}
      >
        <Fade in={openStudentDetails}>
          <Card className={classes.cardStudentDegree}>
            <CardContent>
              <EditForm student={student} onClose={hanledCloseStudentDetails} catalogues={catalogues}
                        loading={loadingStudent} loadingSection={loadingSection} setLoadingSection={setLoadingStudent}
                        handledSubmit={handledSubmit} holidays={holidays}
                        handledTitulation={handledTitulation} btnTitu={btnTitu}/>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
      <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={5000}/>
    </>
  );
};

const mapStateToProps = (state) => ({...state.userReducer});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DegreeTablePage);
