import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import Footer from '../../../layout/components/Footer/Footer';

const useStyles = makeStyles((theme) => ({
    root_terminos:{
        left: "50%",
        width: "100vw",
        position: "relative",
        marginLeft: "-50vw",
        marginBottom: "-24px",
        backgroundColor: "#FFFFFF"
    },
    content_terminos:{
        maxWidth: "1440px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    title:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "48px",
        lineHeight: "48px",
        color: "#23272F",
        marginBottom: "25px",
    },
    date:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#111317",
    },
    divider:{
        width: "100%",
        marginTop: "47px", 
        marginBottom: "56px",
        backgroundColor: "#A0A9BA",
    },
    titleToGo:{
        fonFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#3D23D8",
    },
    titleText:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#111317",
        marginBottom: "24px",
    },
    text:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#111317",
        marginBottom: "16px",
    },
    subtitleBold:{

    },
    titleBold:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#23272F",
        marginBottom: "16px",
        textTransform: 'uppercase',
    },
    marginLeft:{
        marginLeft: "17px",
    },
    marginLeftS:{
        marginLeft: "24px",
    },
    marginBottom:{
        marginTop:"0px",
        marginBottom: "34px",
        
    },
    textSubrayed:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#111317",
        textDecoration: "underline",
        marginLeft: "48px",
    },
    textLeft:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#111317",
        marginLeft: "41px",
    },
    textNormal:{
        fontFamily: 'Inter !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#111317",
    },
    contentTitle:{
        marginTop: "106px",
        maxWidth: "992px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        "@media (max-width: 1100px)": {
            marginLeft: "54px",
        },
        "@media (max-width: 650px)": {
            width: "calc(100% - 40px)",
            marginLeft: "0px",
            paddingLeft: "20px",
            paddingRight: "20px",
        }
    },
    content:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "@media (max-width: 1100px)": {
            flexDirection: "column",
            alignItems: "center",
        }
    },
    definiciones:{
        display: "flex",
        flexDirection: "column",
        width: "393px",
        position: "fixed",
        transform: "translate3d(-300px, 0px, 0px)",
        "@media (max-width: 1100px)": {
            marginBottom: "32px",
            width: "393px",
            display: "flex",
            position: "inherit",
            transform: "translate3d(0px, 0px, 0px)",
            flexDirection: "column",
            marginRight: "auto",
        },
        "@media (max-width: 650px)": {
            width: "calc(100% - 40px)",
            paddingLeft: "20px",
            paddingRight: "20px",
        }
    },
    stopFix:{
        width: "393px",
        display: "flex",
        position: "absolute",
        transform: "translate3d(-300px, 0px, 0px)",
        flexDirection: "column",
        bottom: "668px",
        "@media (max-width: 1300px)": {
            bottom: "116.5vh",
        },
        "@media (max-width: 1170px)": {
            bottom: "118.5vh",
        },
    },
    ghost:{
        backgroundColor: "red",
        width: "392px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        opacity: "0",
        "@media (max-width: 1100px)": {
            display: "none",
        }
    },
    rightBox:{
        maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
        marginBottom: "90px",
        "@media (max-width: 650px)": {
            width: "calc(100% - 32px)",
            paddingLeft: "16px",
            paddingRight: "16px",
        }
    },
    link:{
        '&:hover':{ 
            cursor: "pointer" 
        }
    }
}));


const TerminosCondiciones = (props) => {
    const classes = useStyles();
    const [stopFix, setStopFix] = React.useState(false);

    const handleScroll = () => {
        const position = window.pageYOffset;
        const {innerWidth} = window;
        if(position >= 9990 && innerWidth > 1100){
            setStopFix(true);
        }else{
            setStopFix(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box display="flex" flexDirection="column" className={classes.root_terminos}>
            <Box display="flex" flexDirection="column" className={ classes.contentTitle }>
                <Typography className={classes.title}>Términos y Condiciones</Typography>
                <Typography className={classes.date}>Fecha de última actualización: 19 de junio de 2023</Typography>
            </Box>
            <Divider className={classes.divider}/>
            <Box display="flex" flexDirection="column" className={classes.content_terminos}>
            <Box className={ classes.content }>
            <Box className={classes.ghost}></Box>
                <Box className={ !stopFix ? classes.definiciones : classes.stopFix}>
                    <Link href="#definiciones"><Typography className={classes.titleToGo}>I. Definiciones</Typography></Link>
                    <Link href="#servicios"><Typography className={classes.titleToGo}>II. Servicios proporcionados</Typography></Link>
                    <Link href="#requerimientos"><Typography className={classes.titleToGo}>III. Requerimientos técnicos</Typography></Link>
                    <Link href="#materiales"><Typography className={classes.titleToGo}>IV. De los materiales y contenidos de la plataforma</Typography></Link>
                    <Link href="#acceso"><Typography className={classes.titleToGo}>V. Acceso del usuario a la plataforma</Typography></Link>
                    <Link href="#usoservicio"><Typography className={classes.titleToGo}>VI. Uso del servicio</Typography></Link>
                    <Link href="#funcionamiento"><Typography className={classes.titleToGo}>VII. Funcionamiento de la plataforma</Typography></Link>
                    <Link href="#aceptacion"><Typography className={classes.titleToGo}>VIII. Aceptación de los Términos</Typography></Link>
                    <Link href="#cambios"><Typography className={classes.titleToGo}>IX. Cambios a estos términos</Typography></Link>
                    <Link href="#derechos"><Typography className={classes.titleToGo}>X. Derechos de propiedad intelectual</Typography></Link>
                    <Link href="#cookies"><Typography className={classes.titleToGo}>XI. Cookies</Typography></Link>
                    <Link href="#otras"><Typography className={classes.titleToGo}>XII. Otras disposiciones</Typography></Link>
                </Box>
                <Box className={classes.rightBox}>
                    <Typography className={classes.titleText}>TÉRMINOS Y CONDICIONES DE USUARIOS PARA EL USO DE LA PLATAFORMA TALISIS.COM</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px"  }} id="definiciones">El presente documento establece los términos y condiciones generales del uso que serán aplicables entre el Usuario, y UNIVERSIDAD REGIOMONTANA S.C., sociedad constituida bajo las Leyes de la República Mexicana, con Registro Federal de Contribuyentes URE740701IQ1 y domicilio ubicado en Calle Matamoros Número 430, Colonia Centro, C.P. 64000, Monterrey, Estado de Nuevo León ,  en lo subsecuente Talisis, con respecto al uso de la plataforma Talisis.com en lo sucesivo la “Plataforma” y a los servicios de suscripción correspondientes a la cuota pagada.</Typography>



                    <Typography className={classes.titleBold}>I. Definiciones</Typography>
                    <Typography className={classes.text}>Para efectos de los presentes Términos y Condiciones se entenderá por:</Typography>
                    <ol type="a">
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Aliados:</span> Se entenderá por Aliados a los terceros que ofrecen su contenido mediante la plataforma como de manera enunciativa mas no limitativa son Coursera, LinkedIn Learning, Crehana, Google Skillshop, IE Publushing, AWS, y Hubspot.</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>App Móvil Talisis</span> Aplicación que permite a los Usuarios utilizar Talisis.com a través de teléfonos inteligentes y tabletas, disponible en App Store y Google Play. La app móvil no contiene todas las características de la plataforma, por lo que a través de ésta no se podrán gestionar, ni administrar ciertas configuraciones del sistema por parte de los usuarios..</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Call Center:</span> Centro de atención telefónica que ayuda al Usuario a aclarar de dudas de servicios relacionados con la Plataforma.</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Cuenta:</span> Registro en la base de datos de plataforma el cual brinda un acceso al usuario para consumo y uso de la misma.</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Talisis:</span> A la persona moral denominada Universidad Regiomontana y/o cualquier otra sociedad filial o subsidiaria de Talisis Holding, S.A.P.I de C.V.</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Interesado:</span> Persona que desea obtener información o compra de algún curso de educación continua ya sea a través de la plataforma o el Call Center.</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Marca:</span> Las Sociedades prestadoras de servicios educativos que pertenecen a Talisis de manera enunciativa mas no limitativa las marcas registradas UNID, U-ERRE y Harmon Hall.</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Membresías:</span> Tipo de acceso con el que cuenta el Usuario que le permite utilizar los contenidos de la Plataforma siendo las mismas:</li></Typography>
                        <ul>
                            <Typography className={classes.text + " " + classes.marginLeft}><li>Membresía Freemium que incluye:</li></Typography>
                            <ul>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Cursos limitados a tu tiempo y ritmo</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Evaluaciones diagnósticas de razonamiento e inteligencia emocional</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Evaluaciones vocacionales para orientar tu camino profesional</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Recomendaciones personalizadas</li></Typography>
                            </ul>
                            <Typography className={classes.text + " " + classes.marginLeft}><li>Membresía Lite que incluye:</li></Typography>
                            <ul>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Mismos beneficios que la Membresía Freemium</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Cursos ilimitados</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Acceso a contenido de Crehana y LinkedIn Learning</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Bachillerato con validez oficial de marca UNID (incluye costo de certificado)</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Diplomas de marcas participantes</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Bolsa de trabajo Conecta</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Perfilamiento profesional</li></Typography>
                            </ul>
                            <Typography className={classes.text + " " + classes.marginLeft}><li>Membresía Professional que incluye:</li></Typography>
                            <ul>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Mismos beneficios que la Membresía Lite</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Licenciaturas y Maestrías con validez oficial de marca UNID (incluye costo de título)</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Asesorías limitadas</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Contenido online y offline</li></Typography>
                            </ul>
                            <Typography className={classes.text + " " + classes.marginLeft}><li>Membresía Premium que incluye:</li></Typography>
                            <ul>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Mismos beneficios que la Membresía Profesional</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Maestría con validez oficial de marca U-ERRE (incluye costo de título)</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Asesorías ilimitadas</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Acceso a contenido de Coursera</li></Typography>
                                <Typography className={classes.text + " " + classes.marginLeft}><li>Eventos exclusivos virtuales</li></Typography>
                            </ul>
                        </ul>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Plataforma:</span> Sistema diseñado por Talisis para prestar el servicio educativo a traves de la enseñanza que permite la ejecución de diversas aplicaciones bajo un mismo entorno, dando a los usuarios la posibilidad de acceder a ella a través de internet.</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Usuario:</span> Persona con acceso a la Plataforma, mismo que puede hacer uso de los recursos que esta le proporcione conforme a su tipo de membresia.</li></Typography>
                        <Typography className={classes.text + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}} id="servicios">Visitante:</span> Persona que tiene acceso a la Plataforma sin que haya adquirido algún curso de educación continua por lo que su acceso a los contenidos de la misma es limitado.</li></Typography>
                    </ol>



                    <Typography className={classes.titleBold}>II. Servicios proporcionados</Typography>
                    <Typography className={classes.text}>Los Usuarios que adquieran cualquiera de las membresías para acceder a la Plataforma gozarán de los recursos que la misma les proporcione para la utilización de contenidos diseñados para desarrollar las habilidades ofertadas, y de los diferentes servicios relacionados a su vida estudiantil.</Typography>
                    <Typography className={classes.textSubrayed}>Recursos</Typography>
                    <ul className={classes.marginBottom}>
                        <Typography className={classes.textLeft}><li>Oferta académica de programa inscrito</li></Typography>
                        <Typography className={classes.textLeft}><li>Cursos de educación continua</li></Typography>
                        <Typography className={classes.textLeft}><li>Cursos de aliados</li></Typography>
                        <Typography className={classes.textLeft}><li>Tablero de habilidades duras y blandas</li></Typography>
                        <Typography className={classes.textLeft}><li>Recomendaciones de cursos personalizada</li></Typography>
                        <Typography className={classes.textLeft}><li>Historial de aprendizaje</li></Typography>
                        <Typography className={classes.textLeft}><li>Exámenes de diagnóstico</li></Typography>
                    </ul>
                    <Typography className={classes.textSubrayed}>Servicios</Typography>
                    <ul className={classes.marginBottom}>
                        <Typography className={classes.textLeft}><li>Inscripción: Solicitud de inscripción y despliegue de requisitos</li></Typography>
                        <Typography className={classes.textLeft}><li>Entrega de documentos oficiales: Entrega de documentos en línea, completar expediente, monitoreo de avance y visualización personalizada de documentos entregados/faltantes</li></Typography>
                        <Typography className={classes.textLeft}><li>Credenciales: Generación de ID digitales (identificación y acceso) y trámite de credencial física</li></Typography>
                        <Typography className={classes.textLeft}><li>Pago: Realización de pagos de colegiatura, servicios, y compra de cursos en plataforma</li></Typography>
                        <Typography className={classes.textLeft}><li>Estado de cuenta: Despliegue personalizado a tiempo real de estado de cuenta y saldos </li></Typography>
                        <Typography className={classes.textLeft}><li>Reseteo de contraseña: Solución a reseteo de contraseña por medio de plataforma/correo</li></Typography>
                    </ul>
                    
                    <Typography className={classes.text}>2.1.  REGISTRO EN PLATAFORMA.</Typography>
                    <Typography className={classes.text}>Para poder acceder a los contenidos de la Plataforma, el Usuario deberá estar registrado en la misma siguiendo las indicaciones a continuación:</Typography>
                    <Typography className={classes.text}>La suscripción y pago de cualquiera de los programas que forman parte de las Marcas y Aliados, darán acceso al Usuario a la Plataforma. Los Usuarios podrán adquirir su accesoa través de Talisis.com:</Typography>
                    <ol type="a">
                        <Typography className={classes.textNormal}><li>El Usuario  deberá ingresar a Talisis.com para hacer el registro: Inscripción y llenado de datos solicitados.</li></Typography>
                        <Typography className={classes.textNormal}><li>Recepción de correo para activación de cuenta a la dirección de correo electrónico ingresada.</li></Typography>
                        <Typography className={classes.textNormal}><li>Asignación de contraseña.</li></Typography>
                        <Typography className={classes.textNormal}><li>En caso de no recibir dicho correo, el Usuario debe solicitar el reenvío del mismo mediante el botón de ayuda del lado derecho inferior de la página principal de Talisis.com, proporcionando su nombre, apellido, correo, y matrícula en caso de ser alumno actual de cualquier Marca de Talisis.</li></Typography>
                        <Typography className={classes.textNormal}><li>En caso de que el usuario sea un alumno actual de cualquier Marca de Talisis, y no recuerde la contraseña de su correo institucional.</li></Typography>
                        <ul>
                            <Typography className={classes.textNormal}><li>Se le hará llegar una liga a su correo alterno (personal) registrado para la restauración de su contraseña.</li></Typography>
                            <Typography className={classes.textNormal}><li>Si no tiene correo alterno registrado, tendrá que seguir el proceso de recuperación de contraseña dictado por la marca a la que corresponde.</li></Typography>
                        </ul>
                    </ol>



                    <Typography className={classes.titleBold}>III. Requerimientos técnicos</Typography>
                    <Typography className={classes.text}>Para el adecuado uso de la Plataforma el Usuario deberá contar con los siguientes recursos como mínimo.</Typography>
                    <ol style={{ paddingLeft: "20px" }}>
                        <Typography className={classes.textNormal}><li>Computadora fija o portátil / Celular smartphone.</li></Typography>
                        <Typography className={classes.textNormal}><li>Sistema operativo y navegador en su versión más actualizada.</li></Typography>
                        <Typography className={classes.textNormal}><li>Utilizar preferentemente Google Chrome, Safari o FireFox.</li></Typography>
                        <Typography className={classes.textNormal}><li>Conexión a internet estable (recomendación de al menos 2 MBps de descarga y 2MBps de carga).</li></Typography>
                    </ol>
                    <Typography className={classes.text} style={{ marginBottom: "32px" }} id="materiales">En caso de que el Usuario no cuente con las herramientas y/o recursos técnicos requeridos para el funcionamiento de la Plataforma, Talisis no se hace responsable del mal funcionamiento que por dicha causa puedan presentar los contenidos y/o recursos de esta, por lo que dicha utilización queda bajo la exclusiva responsabilidad del Usuario.</Typography>



                    <Typography className={classes.titleBold}>IV. De los materiales y contenidos de la plataforma</Typography>
                    <Typography className={classes.text}>La Plataforma utiliza una nueva forma de enseñanza, ya que está basada en la fusión de nuestra metodología y el desarrollo tecnológico de vanguardia educativa diseñado para adaptarse a las necesidades de nuestros Usuarios de manera versátil y dinámica.</Typography>
                    <Typography className={classes.text}>La Plataforma cuenta con materiales y contenidos actualizados en la oferta académica que ofrece, la cual está constituida de diferentes áreas de conocimiento tales como administración, mercadotecnia, finanzas, contaduría, ingenierías, entre otras, que reflejan las actividades cotidianas en las que nuestros Usuarios se desenvuelven.</Typography>
                    <Typography className={classes.text}>El contenido de la Plataforma se actualiza constantemente.</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px" }} id="acceso">Está prohibido reutilizar, transferir, prestar, duplicar o de cualquier otra forma reproducir los contenidos en la Plataforma. Los accesos serán otorgados únicamente mediante el correo del Usuario, por lo que no se aceptará que ningún Usuario adquiera las licencias accesos por otros medios. </Typography>



                    <Typography className={classes.titleBold}>V. Acceso del usuario a la plataforma</Typography>
                    <Typography className={classes.text}>Talisis.com ofrece al Usuario un acceso no transferible y exclusivo que le concede el derecho para tener acceso y utilizar el contenido de la Plataforma, siempre y cuando se cumpla con los presentes Términos y Condiciones, y mantenga una conducta respetuosa para con otros Usuarios y Talisis.</Typography>
                    <Typography className={classes.text}>Los materiales de la Plataforma se proporcionan al Usuario para su uso privado y no comercial y estarán disponibles sólo para Usuarios debidamente registrados en la Plataforma.</Typography>
                    <Typography className={classes.text}>Cursos Disponibles</Typography>
                    <Typography className={classes.text}>Talisis.com ofrece al Usuario de la plataforma acceso a los cursos que en su momento se encuentren activos, en el entendido que Talisis podrá realizar cambios y actualizaciones en el catálogo sin previo aviso y sin responsabilidad alguna.</Typography>
                    <Typography className={classes.text}>El acceso en todo momento se encontrará limitado a disponibilidad de los cursos.</Typography>
                    <Typography className={classes.text}>Los Usuarios que reserven, o se inscriban a un curso con cupo limitado, tendrán, en todo momento, preferencia frente a aquellos que no hayan reservado o se hayan inscrito, por lo que no será responsabilidad de Talisis que diversos Usuarios no tengan cupo en cursos previamente reservados por otros Usuarios.</Typography>

                    <Typography className={classes.text}>5.1. Derechos de los Usuarios de la Plataforma:</Typography>
                    <ul style={{ listStyleType: "upper-roman",  marginBottom: "32px"  }}>
                        <Typography className={classes.textNormal}><li>Mantener la condición de Usuario mientras cumpla con los requisitos y condiciones establecidas por Talisis, brindándole acceso y uso a la Plataforma.</li></Typography>
                        <Typography className={classes.textNormal}><li>Recibir de manera adecuada los servicios ofrecidos en la Plataforma por Talisis.</li></Typography>
                        <Typography className={classes.textNormal}><li>Expresar libremente sus ideas y opiniones, siempre y cuando lo hagan a título personal y con respeto a los valores morales, al marco jurídico y sin más limitaciones que, el no perturbar las actividades, labores y derechos de los demás Usuarios en las distintas sesiones y a Talisis.</li></Typography>
                        <Typography className={classes.textNormal}><li>Recibir de los demás Usuarios en la Plataforma el respeto debido a su persona y derechos.</li></Typography>
                        <Typography className={classes.textNormal}><li>Solicitar los programas de estudio del curso en el cual se encuentra inscrito.</li></Typography>
                        <Typography className={classes.textNormal}><li>Solicitar asesoría técnica u operativa cuando lo necesite por medio de teléfono.</li></Typography>
                    </ul>
                    <Typography className={classes.text}>NOTA: El Usuario deberá estar en cumplimiento con los siguientes supuestos para no invalidar su avance de grado:</Typography>
                    <ul>
                        <Typography className={classes.textNormal}><li>Para inscribir una segunda Licenciatura: Tener más del 50% de avance en la primera licenciatura inscrita.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para inscribir una segunda maestría: Tener más del 50% de avance en la primera maestría inscrita.</li></Typography>
                        <Typography className={classes.textNormal}><li>Para inscribir una licenciatura y una maestría: Haber terminado al menos una licenciatura previo a inscripción de maestría.</li></Typography>
                    </ul>

                    <Typography className={classes.text}>5.2. Expedición de Constancias</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px"  }} id="usoservicio">Talisis.com podrá expedir constancias de estudio, constancias de término y acreditación de curso y/o nivel al Usuario.</Typography>



                    <Typography className={classes.titleBold}>VI. Uso del servicio</Typography>
                    <Typography className={classes.text}>El derecho del Usuario para acceder y utilizar la Plataforma y las características de la misma es personal e intransferible a cualquier otra persona o entidad. Limitando su uso para fines lícitos y el propio objeto de la Plataforma, consistente en el aprendizaje de los cursos a los que tendrá acceso.</Typography>
                    <Typography className={classes.text}>El Usuario es responsable de mantener la confidencialidad de su cuenta para acceder a la Plataforma, tales como nombre de usuario y contraseña. Por lo que el Usuario deberá notificarle a Talisis.com cualquier uso no autorizado de su cuenta.</Typography>
                    <Typography className={classes.text}>Para que las características de la Plataforma funcionen de manera eficiente, el Usuario también deberá mantener su información de registro actualizada y precisa. Si no lo hace, la precisión y la eficacia de la Plataforma se podrá ver afectada.</Typography>
                    <Typography className={classes.text}>Talisis.com a su entera y absoluta discreción podrá cancelar la cuenta del Usuario, recuperar o eliminar cualquier dato del mismo asociado a su cuenta, por razones relacionadas con el uso ilegal o no autorizado de la misma, y Talisis tendrá las obligaciones establecidas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, en atención al Aviso de Privacidad que puede ser consultado en la página de internet www.u-erre.mx  en el apartado “Aviso de Privacidad”.</Typography>
                    <Typography className={classes.text}>El acceso del Usuario y el uso de la Plataforma puede ser interrumpido de vez en cuando por cualquiera de las siguientes razones, incluyendo, sin limitación, el mal funcionamiento del equipo con que el Usuario este intentado ingresar o hacer uso de la Plataforma, la actualización periódica, mantenimiento o la reparación de la Plataforma, la inadecuada conexión a Internet del Usuario u otras acciones o acontecimientos que Talisis.com deba solucionar.</Typography>
                    <Typography className={classes.text}>Talisis no se hace responsable por cualquier pérdida resultante del uso de la Plataforma por parte del Usuario.</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px"  }} id="funcionamiento">La Plataforma y sus contenidos están diseñados para personas de 14 años en adelante, lo anterior a fin de que el uso de la misma sea aprovechada al máximo.</Typography>



                    <Typography className={classes.titleBold}>VII. Funcionamiento de la plataforma</Typography>
                    <Typography className={classes.text}>En la prestación de los Servicios en la Plataforma, Talisis.com no vigila activamente la pantalla, la transmisión y el intercambio de información. Sin embargo, Talisis se reserva el derecho de supervisar el funcionamiento y uso de la Plataforma con fines de determinar que su uso y funcionamiento se esté prestando de forma adecuada, asimismo, Talisis mantiene un absoluto e incondicional derecho a revisar y eliminar el material accesible o transmitido a través de la Plataforma como administrador de la misma, lo que será a discreción.</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px"  }} id="aceptacion">Talisis sin previo aviso, podrá cambiar, ampliar y mejorar la Plataforma el funcionamiento de la misma, mejorar el desempeño de la misma, o resolver errores que se registren durante su uso. También es posible que, en cualquier momento, deje de seguir operando la totalidad o una parte de la Plataforma o se desactiven selectivamente determinados aspectos de la misma. Cualquier modificación o eliminación de las características en la Plataforma se llevará a cabo a discreción de Talisis y sin una obligación permanente o responsabilidad para con el Usuario, sin que esto afecte el objetivo de la Plataforma que es el aprendizaje del Usuario a través del contenido y dinámicas de la Plataforma.</Typography>


                
                    <Typography className={classes.titleBold}>VIII. Aceptación de los Términos</Typography>
                    <Typography className={classes.text}>Al visitar la Plataforma, suscribirse o hacer uso de la Plataforma, el Usuario acepta cumplir con los Términos y Condiciones de uso aquí especificados.</Typography>
                    <Typography className={classes.text}>La aceptación de los presentes términos deberá ser efectuada por el Usuario, el Padre o tutor del mismo en caso de que el usuario sea menor de edad.</Typography>
                    <Typography className={classes.text}>Mediante el uso de la información, las herramientas, características y la funcionalidad que se encuentran en la Plataforma, el Usuario acepta que quedará vinculado por el presente Acuerdo, por lo que, si el usuario desea hacer uso de la Plataforma, deberá leer este Acuerdo e indicar su aceptación durante el proceso de registro.</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px"  }} id="cambios">En caso de no estar de acuerdo con los términos y condiciones, el Visitante o Usuario no tendrá derecho a utilizar la Plataforma, por lo que Talisis no se hace responsable del uso que se dé a la misma.</Typography>
                


                    <Typography className={classes.titleBold}>IX. Cambios a estos términos</Typography>
                    <Typography className={classes.text}>Talisis tiene el derecho de modificar este acuerdo y cualquier política que afecte el sitio.</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px"  }} id="derechos">El uso continuado de la Plataforma después de la notificación de la modificación de los Términos y Condiciones se considerará una aceptación de los términos modificados y las condiciones de uso. Por lo que si el Usuario estuviera insatisfecho con los términos modificados de uso puede cancelar su suscripción siguiendo las instrucciones correspondientes, sin que esto le genere derecho alguno para realizar algún reclamo a Talisis. En caso de que el usuario decida cancelar o eliminar su cuenta, el historial generado en la misma será guardado por un máximo de 5 años por si el mismo desea reactivarla. Sobrepasando este tiempo, toda información e historial generado durante el uso de la cuenta, no podrá ser recuperado. Además, Talisis se reserva el derecho de modificar, suspender o interrumpir la Plataforma en cualquier momento por razones que deriven de mantenimiento o fallas técnicas, lo que en cualquier caso será resuelto en un plazo no mayor a 24 horas.</Typography>


                    
                    <Typography className={classes.titleBold}>X. Derechos de propiedad intelectual</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px"  }} id="cookies">Todos los materiales de la Plataforma, incluyendo contenido, texto, imágenes, programas, archivos de audio, video y/o cualquier otra que se presente en la Plataforma quedan en todo momento como la propiedad única de Talisis.com, Talisis, Marcas o de sus aliados comerciales respectivamente. Dichos materiales están protegidos en cuanto a los derechos de autor y propiedad industrial. Por lo que el Usuario no puede publicar, distribuir, dar acceso, traducir ni reproducir en ninguna manera ningún material o cualquier elemento en la Plataforma sin el consentimiento expreso y escrito de Talisis.com.</Typography>



                    <Typography className={classes.titleBold}>XI. Cookies</Typography>
                    <Typography className={classes.text} style={{ marginBottom: "32px"  }} id="otras">Los Usuarios y los Terceros reconocen y aceptan, dando su consentimiento total, pleno e irrevocable desde el momento de aceptar los presentes Términos y Condiciones, que el Sitio Web utiliza cookies propias y de terceros, las cuales se clasifican normalmente, en cookies “temporales” y cookies “permanentes”. Las cookies temporales suelen utilizarse para realizar la transmisión de información en una red de comunicaciones electrónica y son estrictamente necesarias para el desarrollo de las Funcionalidades y de los Recursos Digitales solicitados explícitamente por el Usuario, tales como las cookies de sesión que son el tipo más habitual de cookies temporales, mismas que ayudan al Usuario a navegar de forma eficiente por el Sitio Web, realizando un seguimiento del recorrido del Usuario por el Sitio Web, teniendo en cuenta la información que ha proporcionado durante la sesión iniciada con su Cuenta. Las cookies temporales se guardan en la memoria temporal del navegador que utilice el Usuario y se borran cuando éste se cierra.  Las cookies permanentes, en cambio, guardan las preferencias del usuario para la sesión actual y futuras visitas.  Las cookies permanentes suelen utilizarse para hacer un seguimiento del comportamiento del Usuario y facilitar una publicidad personalizada.</Typography>

                    

                    <Typography className={classes.titleBold}>XII. Otras disposiciones</Typography>
                    <ul type="a"> 
                        <Typography className={classes.textNormal + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Cesión.</span> Este documento y cualesquiera derechos y accesos otorgados aquí no podrán ser transferidos o cedidos por el Usuario, pero Talisis sí estará en posibilidad de transferirlos o cederlos sin restricción alguna y sin necesidad de recabar el consentimiento previo del Usuario.</li></Typography>
                        <Typography className={classes.textNormal + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Encabezados.</span> Las referencias a encabezados existentes en las presentes Condiciones son hechas únicamente con fines de referencia y no serán consideradas para limitar o afectar cualquiera de las disposiciones aquí contenidas.</li></Typography>
                        <Typography className={classes.textNormal + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Integridad de las Condiciones.</span> El presente documento constituye el acuerdo integral entre el Usuario y Talisis.com relacionado con el desarrollo de las Funcionalidades, de los Recursos Digitales y la utilización del Sitio Web y no podrá modificarse, salvo lo dispuesto en las presentes Condiciones.</li></Typography>
                        <Typography className={classes.textNormal + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Invasión de Cursos, Documento que no acredite nivel antecedente y/o Documentación Aprocrifa.</span> No se hará ningún tipo de reembolso de pago por los servicios ya prestados, si por culpa, dolo o error de la documentacion presentada por el usuario a talisis.com se incumplen los requisitos para la obtención del grado correspondiente o éstos sean documentos apócrifos.</li></Typography>
                        <Typography className={classes.textNormal + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Impuestos.</span> Los impuestos y/o derechos que se causen en virtud del presente servicio, correrán a cargo de la parte a quien los mismos correspondan en términos de las leyes aplicables.</li></Typography>
                        <Typography className={classes.textNormal + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Legislación Aplicable.</span> Las presentes Condiciones, así como la relación de Talisis por sí misma o mediante Plataforma o Pagina Web (www.talisis.com), con respecto de los Usuarios, se regirán exclusivamente por la legislación y las leyes aplicables en México.</li></Typography>
                        <Typography className={classes.textNormal + " " + classes.marginLeft}><li><span style={{ fontWeight: "bold"}}>Jurisdicción.</span> Las partes se someten a la jurisdicción de los Juzgados y Tribunales competentes de la Ciudad de Monterrey, México renunciando expresamente a cualquier otro fuero que les pudiere corresponder en virtud de sus domicilios o por cualquier otra causa.</li></Typography>
                    </ul>
                </Box>
            </Box>
            </Box>
            {/* <Footer></Footer> */}
        </Box>
    );
};


export default TerminosCondiciones;