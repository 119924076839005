import React from 'react';

// @components
import * as FORMAT from '../../components/genericBrandLandingPageFormat';
import * as MEDIA from '../../components/genericBrandLandingPageMedia';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { generatePath } from "react-router";

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardMedia, Typography, Button, CircularProgress, Box, Hidden, Chip } from '@material-ui/core';

// @SDK
import { ProgramService } from '@sdk-point/talisis';
import BrandProgramCardResponsive from './BrandProgramCardResponsive';
import { log } from '../../../../shared/utils/console.js'
// Path
import {PROGRAMA} from '../../../../routes/paths';
import clsx from 'clsx';
const ProgramServiceSDK = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    circularProgressPrincipal: {
        marginTop: '10vw',
    },
    circularProgress: {
        height: '15px !important',
        width: '15px !important',
    },
    root: {
        width: '100%',
    },
    card: {
        border: '0.5px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '8px',
        position: 'relative',
    },
    media: {
        backgroundSize: 'cover !important',
        backgroundRepeat: 'no-repeat !important',
        width: '100%',
    },
    headerCardText: {
        left: '0px',
        right: '0px',
        position: 'absolute',
        top: '8%',
        '@media (max-width: 600px)': {
            fontSize: '20px',
        }
    },
    button: {
        background: 'rgba(160, 169, 186, 0.1)',
        border: '1px solid #F6BE00',
        boxShadow: '0px 6px 11px rgba(0, 0, 0, 0.1)',
        textTransform: 'none',
        marginBottom: 16,
        '&:hover': {
            border: '1px solid #F6BE00',
            background: 'rgba(160, 169, 186, 0.2)',
        }
    },
    program: {
        background: theme.palette.colors.grey[50],
        borderRadius: '4px',
        fontSize: '14px',
        height: '24px',
        left: '0px',
        margin: '0 auto',
        padding: '4px 8px',
        position: 'absolute',
        right: '0px',
        top: '87%',
        width: '6.6rem',
    },
    title:{
        [theme.breakpoints.down('xs')]:{
            textAlign: 'start'   
        }
    }
}));

const levelConfig = {
    BA: {
        key: 'BACHILLERATO',
        title: 'Bachillerato',
        imgResponsive: 'bachillerato-card-responsive-unid.png',
    }, 
    LI: {
        key: 'LICENCIATURA',
        title: 'Licenciatura',
        imgResponsive: 'licenciatura-card-responsive-unid.png',
    }, 
    MA: {
        key: 'MAESTRIA',
        title: 'Maestría',
        imgResponsive: 'maestria-card-responsive-unid.png',
    }, 
    EC: {
        key: 'EDUCACION_CONTINUA',
        title: 'Educación continua',
        imgResponsive: 'educacion-continua-card-responsive-unid.png',
    }
};

const BrandLandingProgramsCardUnid = (props) => {
    const { className } = props;    
    const history = useHistory();
    const classes = useStyles();
    const CARD_IMG = MEDIA.getBrandLandingPagesProgramCardImg('unid');
    const [loading, setLoading] = React.useState(true);
    const [programsNumberByOULevel, setProgramsNumberByOULevel] = React.useState({});

    React.useEffect(() => {
        const fetch = async () => {
            const list = {};
            for (const level_id of ['BA', 'LI', 'MA', 'EC']) {
                const ou = FORMAT.getCompanyIdByBrand('unid');
                const response = await ProgramServiceSDK.getProgramsNumberByOU(ou, level_id);
                list[level_id] = response.number_of_programs;
            }

            log('lots', list);

            setProgramsNumberByOULevel(list);
            setLoading(false);
        }
        fetch();
    }, []);

    const handleClick = (id) => {
        const container = document.getElementById(id);
        if (container && id !== 'bachillerato') {
            container.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }else{
            process.env.REACT_APP_ENV === 'dev' ? history.push(generatePath(PROGRAMA, {id: 226})) : history.push(generatePath(PROGRAMA, {id: 706}))
        }
    };

    return (
        <React.Fragment>
            {loading 
                ? <CircularProgress className={classes.circularProgressPrincipal} />
                :   
                <Box>
                    <Hidden id="desktop" xsDown>                    
                        <Typography variant="h3" align="center" className={clsx('offset-mb-6', classes.title)}>Estudia a tu ritmo nuestros programas con validez oficial</Typography>
                        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" className={className}>
                            {programsNumberByOULevel['BA'] > 0 && <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Card id="card_bachillerato" className={classes.card}>
                                    <Typography variant="h4" className={classes.headerCardText}>Bachillerato</Typography>
                                    <CardMedia
                                        className={classes.media}
                                        component="img"
                                        src={CARD_IMG['BACHILLERATO']}
                                        title="Bachillerato"
                                        onMouseOver={(e) => {
                                            e.target.src = CARD_IMG['background']['BACHILLERATO'];
                                            document.getElementById('card_bachillerato').style.borderRadius = '8px 0 8px 8px';
                                        }}
                                        onMouseOut={(e) => {
                                            e.target.src = CARD_IMG['BACHILLERATO'];
                                            document.getElementById('card_bachillerato').style.borderRadius = '8px';
                                        }}
                                    />

                                    <Box position='absolute' bottom={24} width={1}>
                                        <Box width={117} margin='0 auto'>
                                            <Button variant="outlined" size={'small'} color="primary" className={classes.button} onClick={() => handleClick('bachillerato')}>
                                                <Typography variant="body2" className='semi-bold'>Ver todos</Typography>
                                            </Button>
                                            <Chip className='chip1-fs-200' label={loading ? <CircularProgress className={classes.circularProgress} /> : `${programsNumberByOULevel['BA']} Programas`} />
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>}
                            {programsNumberByOULevel['LI'] > 0 && <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Card id="card_licenciatura" className={classes.card}>
                                    <Typography variant="h4" className={classes.headerCardText}>Licenciatura</Typography>
                                    <CardMedia
                                        className={classes.media}
                                        component="img"
                                        src={CARD_IMG['LICENCIATURA']}
                                        title="Licenciatura"
                                        onMouseOver={(e) => {
                                            e.target.src = CARD_IMG['background']['LICENCIATURA'];
                                            document.getElementById('card_licenciatura').style.borderRadius = '8px 0 8px 8px';
                                        }}
                                        onMouseOut={(e) => {
                                            e.target.src = CARD_IMG['LICENCIATURA'];
                                            document.getElementById('card_licenciatura').style.borderRadius = '8px';
                                        }}
                                    />
                                    <Box position='absolute' bottom={24} width={1}>
                                        <Box width={117} margin='0 auto'>
                                            <Button variant="outlined" size={'small'} color="primary" className={classes.button} onClick={() => handleClick('licenciatura')}>
                                                <Typography variant="body2" className='semi-bold'>Ver todos</Typography>
                                            </Button>
                                            <Chip className='chip1-fs-200' label={loading ? <CircularProgress className={classes.circularProgress} /> : `${programsNumberByOULevel['LI']} Programas`} />
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>}
                            {programsNumberByOULevel['MA'] > 0 && <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Card id="card_maestria" className={classes.card}>
                                    <Typography variant="h4" className={classes.headerCardText}>Maestría</Typography>
                                    <CardMedia
                                        className={classes.media}
                                        component="img"
                                        src={CARD_IMG['MAESTRIA']}
                                        title="Maestría"
                                        onMouseOver={(e) => {
                                            e.target.src = CARD_IMG['background']['MAESTRIA'];
                                            document.getElementById('card_maestria').style.borderRadius = '8px 0 8px 8px';
                                        }}
                                        onMouseOut={(e) => {
                                            e.target.src = CARD_IMG['MAESTRIA'];
                                            document.getElementById('card_maestria').style.borderRadius = '8px';
                                        }}
                                    />

                                    <Box position='absolute' bottom={24} width={1}>
                                        <Box width={117} margin='0 auto'>
                                            <Button variant="outlined" size={'small'} color="primary" className={classes.button} onClick={() => handleClick('maestria')}>
                                                <Typography variant="body2" className='semi-bold'>Ver todos</Typography>
                                            </Button>
                                            <Chip className='chip1-fs-200' label={loading ? <CircularProgress className={classes.circularProgress} /> : `${programsNumberByOULevel['MA']} Programas`} />
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>}
                            {!loading && <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Card id="card_educacion_continua" className={classes.card}>
                                    <Typography variant="h4" className={classes.headerCardText}>Educación continua</Typography>
                                    <CardMedia
                                        className={classes.media}
                                        component="img"
                                        src={CARD_IMG['EDUCACION_CONTINUA']}
                                        title="Educación continua"
                                        onMouseOver={(e) => {
                                            e.target.src = CARD_IMG['background']['EDUCACION_CONTINUA'];
                                            document.getElementById('card_educacion_continua').style.borderRadius = '8px 0 8px 8px';
                                        }}
                                        onMouseOut={(e) => {
                                            e.target.src = CARD_IMG['EDUCACION_CONTINUA'];
                                            document.getElementById('card_educacion_continua').style.borderRadius = '8px';
                                        }}
                                    />
                                    <Box position='absolute' bottom={24} width={1} height={76}>
                                        <Box width={117} margin='0 auto'>
                                            <Button variant="outlined" size={'small'} color="primary" className={classes.button} onClick={() => handleClick('educacion_continua')}>
                                                <Typography variant="body2" className='semi-bold'>Ver todos</Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>}
                        </Grid>
                    </Hidden>

                    <Hidden id="mobil" smUp>
                        <Typography variant="h3" align="left" className={clsx('offset-mb-8', classes.title)}>Estudia a tu ritmo nuestros programas con validez oficial 100% online</Typography>
                        
                        <Box display="flex" flexDirection="column" gridGap="16px">
                            {
                                Object.keys(programsNumberByOULevel).map(level => {

                                    if(programsNumberByOULevel[level] || level === 'EC'){
                                        return (
                                            <BrandProgramCardResponsive
                                                key={uuid()}
                                                brand="unid" 
                                                bgImage={`/images/brands/${levelConfig[level].imgResponsive}`}
                                                title={levelConfig[level].title}
                                                textBttn={level === 'EC' ? "Ver cursos" : "Ver Todos"}
                                                textBadge={programsNumberByOULevel[level] ? `${programsNumberByOULevel[level]} Programas` : ""}
                                                onClickBttn={() => handleClick(levelConfig[level].key.toLowerCase())}
                                                degraded="linear-gradient(180deg, rgba(241, 186, 40, 0) 57%, rgba(241, 186, 40, 0.5) 114.56%), linear-gradient(180deg, rgba(18, 17, 52, 0) 26.59%, rgba(20, 17, 36, 0.8) 100%)"
                                            />
                                        )

                                    }

                                })
                            }
                        </Box>
                    </Hidden>
                </Box>
            }
        </React.Fragment>
    )
};

export default BrandLandingProgramsCardUnid;