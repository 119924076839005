import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from '@material-ui/core';


// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../redux/actions/userActions';
import * as menuActions from '../../../../redux/actions/menuActions';
import * as profilePicActions from '../../../../redux/actions/profilePicActions';
import * as tourInitialActions from '../../../../redux/actions/tourInitialActions';

//services
import { getSessionCookie, setSessionCookie } from '../../../../shared/utils/Sessions';
import { MEMBERSHIPS } from '../../../../shared/constants/memberships';

//SDK
import { LoginService,MIXPANEL_EVENT } from '@sdk-point/talisis';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Mixpanel } from '../../../../shared/components/Mixpanel/mixpanel';
import { log } from '../../../../shared/utils/console.js'
import * as paths from '../../../../routes/paths';


//Sentry
import * as Sentry from "@sentry/react";
import { getCheckoutCookie, setCheckoutCookie } from '../../../../shared/utils/checkoutSession-legacy.js';
import { eventData, eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

// Checkout sku
import { clearCartCookie } from '../../../../shared/utils/cartSession.js';
import { clearCheckoutCookie } from '../../../../shared/utils/checkoutSession.js';

const LoginServiceSDK = new LoginService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '395px',
        padding: '12px 0px',
        margin: '0px auto',
    },
    heading: {
        fontSize: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
        flexShrink: 0,
        padding: '24px 0px'
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold,
    },
    divider: {
        position: 'relative',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    dividerText: {
        position: 'absolute',
        top: '4px',
        left: '45%',
        backgroundColor: '#ffffff',
        padding: '0px 10px',
    },
    input: {
        alignItems: 'center',
        gap: '10px',
        width: '100%',
        maxWidth: '318px',
        height: '48px',
        background: `${theme.palette.colors.grey[500]} !important`,
        border: `0.5px solid ${theme.palette.colors.grey[50]} !important`,
        borderRadius: '4px',
        '& :-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${theme.palette.colors.grey[500]} inset`,
            '-webkit-text-fill-color': `${theme.palette.colors.white} !important`,
            'caret-color': 'white',
            fontWeight: 400,
            padding: '14px',
        },
    },
    submit: {
        width: '318px',
        height: '48px',
        background: theme.palette.colors.violet[500],
        borderRadius: '4px',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
    }
}));

const LoginForm = props => {
    const { mail, applyUser, onLoading, onLoginError, onResetPassword } = props;
    const classes = useStyles();
    let history = useHistory();
    let location = useLocation();
    const [errors, setErrors] = React.useState({});
    const [form, setForm] = React.useState({
        'email': mail || '',
        'password': '',
    });
    const [inputValues, setInputValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const [userState, setUserState] = useState({
        isAuthenticated: false,
        user: {},
        error: null
    });

    const preventDefault = (event) => event.preventDefault();

    const handleResetPassword = () => {
        onResetPassword(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            const _errors = { ...errors };
            delete _errors[name];
            setErrors(_errors);
        }

        setForm(prev => { 
            let clean_value = value.trim();
            if(name == "email"){
                clean_value = clean_value.toLowerCase(); 
            }
            return { ...prev, [name]: clean_value }
         });
    };

    const handleClickShowPassword = () => {
        setInputValues({ ...inputValues, showPassword: !inputValues.showPassword })
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['email', 'password'];

        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            }
        }

        setErrors({ ..._errors });
        return Boolean(Object.keys(_errors).length === 0);
    };

    const handleSubmit = (e) => {
        preventDefault(e);
        if (validate()) {
            onLoading(true)
            try {
                LoginServiceSDK.loginWithEmail(form).then(async userProfile => {
                    onLoading(false)
                    if (userProfile.login) {
                        const { person } = userProfile
                        const userInfo = {
                            userName: person.first_name + ' ' + person.last_name,
                            email: person.email,
                            person_id: person.person_id,
                            is_admin: person.is_admin,
                            gender: person.gender,
                            ou: 'POINT',
                            ouLabel: 'POINT',
                            company_id: 'POINT',
                            membership_id: person.membership_id || MEMBERSHIPS.FREEMIUM,
                            interests: person.interests,
                            first_name: person.first_name,
                            last_name: person.last_name,
                        }
                        
                        
                        Mixpanel.people({
                            "$name": userInfo.userName,
                            "$email": userInfo.email
                        });

                        Sentry.setUser({ email:userInfo.email,username: userInfo.userName });
                        
                        eventTracker('usuario_login', eventData, ['mixpanel']);

                        if (person.is_active) {
                            setUserState({
                                isAuthenticated: true,
                                user: userInfo,
                                error: null
                            });

                            applyUser(userInfo);
                            setSessionCookie(userInfo);

                            var searchParams = new URLSearchParams(location.search);
                            if (searchParams.has('course') || searchParams.has('program')) {
                                history.replace({
                                    pathname: paths.CHECKOUT_LEGACY,
                                    state: {
                                        'user': userInfo,
                                        'referrer': "point-login",
                                        'items': [{
                                            course_id: searchParams.get('course'),
                                            program_id: searchParams.get('program')
                                        }]
                                    }
                                });
                            }
                            /* checkout sku */
                            clearCartCookie();
                            clearCheckoutCookie();
                            /* checkout legacy */
                            const checkoutCookie = getCheckoutCookie()
                            if(checkoutCookie.orderId){
                                setCheckoutCookie({
                                    ...checkoutCookie,
                                    personId: userInfo.person_id,
                                    personInfo: person,
                                    anonymousSession: false,
                                    activeAccount: true
                                })
                                await history.replace({
                                    pathname: paths.CHECKOUT_LEGACY,
                                    state: {
                                        'user': userInfo,
                                        'person': person,
                                        'referrer': "checkout-login",
                                    }
                                });
                            }
                            else {
                                history.push('/');
                            }
                            history.go(0);
                        }
                    }
                    else {
                        onLoginError('Email o contraseña incorrectos')
                    }
                });
            }
            catch (error) {
                log(error)
            }
        }
    };

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            variant="outlined"
                            label="Correo electrónico"
                            fullWidth
                            size="medium"
                            required
                            value={form.email || ""}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            name="password"
                            variant="outlined"
                            label="Contraseña"
                            fullWidth
                            size="medium"
                            type={inputValues.showPassword ? 'text' : 'password'}
                            required
                            value={form.password || ""}
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Contraseña"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={(e) => e.preventDefault()}
                                            style={{ color: '#A0A9BA' }}
                                        >
                                            {inputValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            // InputLabelProps={{
                            //     style: { color: '#A0A9BA' },
                            // }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" className="offset-margin-bottom-32">
                            <Button fullWidth variant="contained" color="primary" type={'submit'} onClick={handleSubmit}>Iniciar sesión</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Link component="button" variant="caption" onClick={handleResetPassword} color="secondary">¿Olvidaste tu contraseña?</Link>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

const mapStateToProps = ({ userReducer, profilePicReducer, tourInitialReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic,
    showTour: tourInitialReducer.showTour
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...tourInitialActions, ...menuActions };
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
