import React from 'react';
import { useHistory } from 'react-router-dom';
import { generatePath } from "react-router";
import Lottie from 'react-lottie';
import clsx from 'clsx';

// redux
import { connect, useDispatch, useSelector } from 'react-redux';

// @material/ui
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { log } from '../../../../shared/utils/console.js';
import { LOGIN, CHECKOUT_PAYMENT_LINK } from '../../../../routes/paths.js';
import { getSessionCookie } from "../../../../shared/utils/Sessions";
import { makeStyles } from '@material-ui/core/styles';
import { useMsal } from "@azure/msal-react";

// design-system
import ErrorPage from '../../../../design-system/components/ErrorPage/ErrorPage';
import animationData from '../../../../assets/images/animations/authRedirect.json'

// services
import { MarketPlaceService, PRODUCT_TYPES, ProductService, SingupService } from '@sdk-point/talisis';
import { PERSON_ORIGIN_CAT } from '@sdk-point/talisis';
import objectIsEmpty from '../../../../design-system/utils/objectIsEmpty.js';
import { paymentLinkErrors, singleSignOut, userCanOpenLink } from './helpers/paymentLinksHelpers.js';
import { addProductsToCart } from '../../../../shared/utils/cartHelper.js';
import { applyCart, applyCartProducts } from '../../../../redux/actions/cartActions.js';
import * as paths from '../../../../routes/paths.js';
import { isOrderProcessed, isPaymentLinkActive } from '../../../../shared/utils/checkoutHelpers.js';

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const productService = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const marketplaceService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const productsService = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    animatedLoader: {
        width: '361px !important',
    },
    logout: {
        marginTop: "32px"
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up('sm')]: {
            minWidth: "503px",
        }
    }
}));

const PaymentLinks = (props) => {
    const { match: { params: { token } }, user } = props;
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(0);
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const { instance } = useMsal();
    const { cart } = useSelector((state) => state.cartReducer);
    const dispatch = useDispatch();

    const paymentLinkGlobal = async () => {
        try {
            const tokenOrder = await marketService.getOrderByPaymentToken(token);
            //
            if (tokenOrder?.success) {
                log("tokenOrder: ", tokenOrder);
                const { order } = tokenOrder;
                if (order?.order_id > 0 && (isOrderProcessed(order?.status_id) || !isPaymentLinkActive(order?.payment_status_id))) {
                    history.replace(generatePath(paths.PAYMENT_CONFIRMATION, { order_id: order?.order_id }));
                }
                else {
                    const { linkDetails } = tokenOrder;
                    const canOpenLink = userCanOpenLink(linkDetails, isLogged, user);
                    //
                    if (canOpenLink.canOpen) {
                        log("linkDetails: ", linkDetails)
                        if (isLogged) {
                            log("isLogged: ", isLogged)
                            await continueToCart({
                                programId: linkDetails.program_id,
                                membershipId: linkDetails.membership_id,
                                feeId: linkDetails.membership_fee_id,
                                admissionId: linkDetails.admission_id
                            });
                        } else {
                            const emailValidation = await SingupServiceSDK.validateEmail(linkDetails?.email);
                            log("emailValidation: ", emailValidation)
                            if (emailValidation?.canSignup) {
                                const personData = {
                                    first_name: linkDetails?.first_name,
                                    last_name: linkDetails?.last_name,
                                    gender: linkDetails?.gender,
                                    email: linkDetails?.email,
                                    phone: linkDetails?.phone,
                                    crm_id: linkDetails?.crm_id,
                                    referer_id: linkDetails?.agent_id,
                                    origin_id: PERSON_ORIGIN_CAT.PAYMENT_LINK
                                };
                                log("personData: ", personData)
                                //
                                const responseSignup = await SingupServiceSDK.signup(personData, null);
                                log("responseSignup: ", responseSignup)
                                //
                                if (responseSignup?.error) {
                                    setError(paymentLinkErrors.ERROR_DEFAULT);
                                } else {
                                    await continueToCart({
                                        programId: linkDetails.program_id,
                                        membershipId: linkDetails.membership_id,
                                        feeId: linkDetails.membership_fee_id,
                                        personId: responseSignup?.person_id,
                                        admissionId: linkDetails.admission_id
                                    });
                                }
                            } else {
                                await continueToCart({
                                    programId: linkDetails.program_id,
                                    membershipId: linkDetails.membership_id,
                                    feeId: linkDetails.membership_fee_id,
                                    personId: emailValidation?.additionalData[0].person_id,
                                    admissionId: linkDetails.admission_id
                                });
                            }
                        }
                        /* if (order && order?.order_id > 0) {
                             // Orden encontrada
                             if (order?.estatus_id === ORDER_STATUS.SUCCESS || !!(order?.payment_id)) {
                                 redirectPaymentConfirmation(order);
                             } else {
                                 processOrder({
                                     ...linkDetails,
                                     person_id: order?.person_id,
                                     activateAccountURL: person?.activateAccountURL,
                                     activeAccount: person?.is_active,
                                     order_id: order?.order_id,
                                     payment_token: token
                                 }, history);
                             }
                         } else if (isLogged) {
                             // No sé encontró orden, usuario ya está loggeado
                             processOrder({
                                 ...linkDetails,
                                 person_id: user?.person_id,
                                 payment_token: token,
                                 activeAccount: true
                             }, history);
                         } else {
                             if (person) {
                                 // Person ya existe, activo o inactivo.
                                 processOrder({
                                     ...linkDetails,
                                     person_id: person?.person_id,
                                     activateAccountURL: person?.activateAccountURL,
                                     activeAccount: person?.is_active,
                                     payment_token: token
                                 }, history);
                             } else {
                                 // Crear nuevo usuario
                                 const emailValidation = await SingupServiceSDK.validateEmail(linkDetails?.email);
                                 if (emailValidation?.canSignup) {
                                     const personData = {
                                         first_name: linkDetails?.first_name,
                                         last_name: linkDetails?.last_name,
                                         gender: linkDetails?.gender,
                                         email: linkDetails?.email,
                                         phone: linkDetails?.phone,
                                         crm_id: linkDetails?.crm_id,
                                         referer_id: linkDetails?.agent_id,
                                         origin_id: PERSON_ORIGIN_CAT.PAYMENT_LINK
                                     };
                                     //
                                     const responseSignup = await SingupServiceSDK.signup(personData, null);
                                     //
                                     if (responseSignup?.error) {
                                         setError(paymentLinkErrors.ERROR_DEFAULT);
                                     } else {
                                         processOrder({
                                             ...linkDetails,
                                             person_id: responseSignup?.person_id,
                                             activateAccountURL: responseSignup?.activateAccountURL,
                                             activeAccount: false,
                                             payment_token: token
                                         }, history);
                                     }
                                 } else {
                                     log(emailValidation?.reason);
                                     setError(paymentLinkErrors.ERROR_ACCOUNT_EXISTS);
                                 }
                             }
                         }*/
                    } else {
                        setError(canOpenLink.error);
                    }
                }
            } else {
                setError(paymentLinkErrors.ERROR_DEFAULT);
            }
        } catch (e) {
            log(e);
            setError(paymentLinkErrors.ERROR_DEFAULT);
        } finally {
            setLoading(false);
        }
    };

    const findMembershipAndFee = async (membershipId, feeId) => {
        const data = await marketplaceService.getMemberships()
        // Encuentra la membresía con el id especificado
        const membership = data.memberships.find(m => m.id === membershipId);
        if (!membership) {
            return null; // No se encontró la membresía
        }

        // Encuentra el fee con el id especificado dentro de la membresía encontrada
        const fee = membership.fees.find(f => f.id === feeId);
        if (!fee) {
            return null; // No se encontró el fee
        }

        // Retorna la membresía y el fee encontrados
        return {
            membership,
            fee
        };
    }

    const continueToCart = async ({ programId, membershipId, feeId, personId, admissionId }) => {
        try {
            const result = await findMembershipAndFee(membershipId, feeId);
            const data = await productsService.getProductByMembershipReference(result.fee.term_months, result.membership.id)
            let membershipProduct = {};
            if (data && data.length) {
                membershipProduct = data[0];
            }
            // Consultar la información del producto adicional (programa)
            let programProduct = null;
            if (programId) {
                const productData = await productService.getProductByReferenceId(PRODUCT_TYPES.PROGRAMA, programId);
                if (productData && productData.length) {
                    programProduct = productData[0];
                }
            }
            // Agregar primero la membresía
            const products = [{
                "product_id": membershipProduct?.id,
                "product_name": membershipProduct?.name,
                "product_image_url": membershipProduct?.image_url,
                "product_description": membershipProduct?.description,
                "product_type_id": membershipProduct?.product_type_id,
                "quantity": 1,
                "product_price_id": membershipProduct?.product_price_id,
                "unit_price": membershipProduct?.list_price,
                "discount": 0
            }];
            // Si es necesario, agregar el programa
            if (programProduct) {
                products.push({
                    "product_id": programProduct?.id,
                    "product_name": programProduct?.name,
                    "product_image_url": programProduct?.image_url,
                    "product_description": programProduct?.description,
                    "product_type_id": programProduct?.product_type_id,
                    "quantity": 1,
                    "product_price_id": programProduct?.product_price_id,
                    "unit_price": programProduct?.list_price,
                    "discount": 0
                });
            }
            // Agregar productos al carrito
            log("products: ", products);
            const response = await addProductsToCart({
                cart_id: cart?.id,
                products: [...products],
                person_id: personId,
                payment_token: token,
                admission_id: admissionId
            });
            log("response cart add: ", response)
            log("response cart cart_details: ", response.cart_details)
            // Actualizar estado reducer del carrito
            dispatch(applyCart(response));
            dispatch(applyCartProducts(response.cart_details));
            // Redireccionar al carrito para ver el detalle.
            const redirectId = personId || session.person_id;
            console.log("redirectId:", redirectId);
            history.push(`${paths.CART}?personPaymenToken=${redirectId}`);
        } catch (e) {
            log("error", e);
        }
    }

    React.useEffect(() => {
        setLoading(true);

        if (!token) {
            setError(paymentLinkErrors.ERROR_DEFAULT);
            setLoading(false);
        } else {
            paymentLinkGlobal();
        }
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            className: classes.animatedLoader
        }
    };

    return <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        minHeight="calc(100vh - 300px)">
        {loading && <Lottie options={defaultOptions}
            width={445}
            height={342} />}
        {error === paymentLinkErrors.ERROR_DEFAULT && <Box maxWidth="626px"><ErrorPage /></Box>}
        {error === paymentLinkErrors.ERROR_BAD_PAYMENT_LINK && <Box maxWidth="626px"><ErrorPage /></Box>}
        {error === paymentLinkErrors.ERROR_ACCOUNT_EXISTS && <Box maxWidth="498px"><ErrorPage path={`${LOGIN}?redirect=${generatePath(CHECKOUT_PAYMENT_LINK, { token })}`} buttonText="Iniciar sesión" title={
            <Box><span className='font-weight-700'>Detectamos que tu cuenta ya existe.</span> Por favor, intenta de nuevo iniciando sesión y abriendo nuevamente el enlace.</Box>} />
        </Box>}
        {error === paymentLinkErrors.ERROR_ANOTHER_OPEN_SESSION && <Box maxWidth="498px">
            <Card>
                <CardContent className={clsx('p-4', classes.cardContent)}>
                    <Box maxWidth="340px"> <Typography variant="body1" className='font-weight-700'>Parece que hay una sesión activa distinta en este dispositivo.</Typography></Box>
                    <Box className={classes.logout}>
                        <Button variant="contained" color="primary" onClick={() => { singleSignOut(instance, history) }} className={classes.button}>Iniciar sesión con mi cuenta</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>}
    </Box>
}

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(PaymentLinks);