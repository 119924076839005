import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    textField:{
        '& .MuiSelect-nativeInput':{
            height: '100%',
            border: 0,
            backgroundColor: 'transparent',
            '&::placeholder': {
                padding: 16,
                fontSize: 14,
                color: theme.palette.colors.darkBlack[200]
            },
        },
        '& .MuiInputBase-input':{
            padding: '10.5px 16px !important'
        }
    },
    [theme.breakpoints.down('xs')]:{
    },
    arrow: {
        paddingRight: '16px',
        display: 'flex',
        alignItems: 'center',
    }
}));