export const MEMBERSHIPS = {
    FREEMIUM: 1,
    LITE: 2,
    PROFESSIONAL: 6,
    PREMIUM: 13,
    LITE_PLUS: 14,
    UNID: 20,
    U_ERRE: 21,
    U_ERRE_EJECUTIVA: 22,
    IESALUD: 23
}

export const MEMBERSHIPS_NAME = {
    [MEMBERSHIPS.FREEMIUM]: 'Freemium',
    [MEMBERSHIPS.LITE]: 'Lite',
    [MEMBERSHIPS.LITE_PLUS]: 'Lite Plus',
    [MEMBERSHIPS.PROFESSIONAL]: 'Professional',
    [MEMBERSHIPS.PREMIUM]: 'Premium',
    [MEMBERSHIPS.UNID]: 'unid',
    [MEMBERSHIPS.U_ERRE]: 'u-erre',
    [MEMBERSHIPS.U_ERRE_EJECUTIVA]: 'u-erre ejecutiva',
    [MEMBERSHIPS.IESALUD]: 'iesalud',
}