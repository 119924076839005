import React from 'react';
import clsx from 'clsx';

import BoxContent  from '../../../shared/components/BoxContent/BoxContent';
import { log } from '../../../shared/utils/console.js'
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Icon  } from '@material-ui/core';

const ACTION_STATE_LOADING = 0;
const ACTION_STATE_SUCCESS = 1;
const ACTION_STATE_ERROR = 2;

const useStyles = makeStyles((theme) => ({
    hover:{
        "&:hover":{
            cursor: "pointer",
        }
    }
}));

const CouponDetail = ({ state, onClose, errorMessage, couponCode, type }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [isCopied, setIsCopied] = React.useState(false);
    
    // function that accepts text as an argument and copies it to the user’s clipboard
     const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
        } else {
        return document.execCommand('copy', true, text);
        }
    }

    const copyCode = (e) =>{
        e.preventDefault();
         // Asynchronously call copyTextToClipboard
         copyTextToClipboard(couponCode)
         .then(() => {
             // If successful, update the isCopied state value
             setIsCopied(true);
             setTimeout(() => {
             setIsCopied(false);
             }, 1000);
         })
         .catch((err) => {
             log(err);
         });
    }
   
    return (
        <>
            {
                state == ACTION_STATE_SUCCESS ? <BoxContent value={
                <>  
                    <Box style={{ position: "relative", zIndex: "1"}} display="flex">
                        <Box>
                            <Icon style={{ marginRight: "17.8px", fontSize: "18px" }} className={'ri-checkbox-circle-fill text-success-400'}></Icon>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Typography className="text-white" variant='h5' style={{ marginBottom: "8px", fontWeight: "600", fontSize: "14px" }}>
                                { `El cupón se ha ${type == "editar" ? "editado": type == "eliminado" ? "eliminado": "creado"} correctamente` }
                            </Typography>
                            {type != "eliminado" && <Typography className="text-gray-500" variant='button' fontWeight="400">
                                { "Copia y comparte el código para promocionarlo por correo electrónico o redes sociales." }
                            </Typography>}
                        </Box>
                        <Box display="flex" alignItems="center" style={{ marginLeft: "auto" }}>

                            {type != "eliminado" && <Box onClick={e => copyCode(e)} display="flex" className={classes.hover}>
                                <Icon style={{ marginRight: "6px", fontSize: "14px" }} className={'ri-file-copy-line '+ (isCopied ? "text-fill-stroke-100": "text-orange-300")}></Icon>
                                <Typography className={(isCopied ? "text-fill-stroke-100": "text-orange-300")} variant='h5' style={{ marginRight: "29.6px", fontWeight: "600", fontSize: "14px" }}>
                                    { isCopied ? 'Copiado' : "Copiar código" }
                                </Typography>
                            </Box>}

                            <Box onClick={ e => onClose(e)}>
                                <Icon style={{ fontSize: "18px" }} className={clsx('ri-close-line text-gray-300', classes.hover)}></Icon>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", background: theme.palette.colors.success[400], zIndex: "0", opacity: "10%", borderRadius: "8px", border: "none" }}></Box>
                </>
                } mt="0px" style={{ padding:"21.8px", position: "relative", backgroundColor: "transparent", border: "0.5px solid "+ theme.palette.colors.success[400], borderRadius: "8px" }} />
                :
                state == ACTION_STATE_ERROR && <BoxContent value={
                    <>  
                        <Box style={{ position: "relative", zIndex: "1"}} display="flex">
                            <Box>
                                <Icon style={{ marginRight: "17.8px", fontSize: "18px" }} className={'ri-alert-fill text-error-400'}></Icon>
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Typography className="text-white" variant='h5' style={{ marginBottom: "8px", fontWeight: "600", fontSize: "14px" }}>
                                    { "Hay un error en este cupón" }
                                </Typography>
                                <Box display="flex" flexDirection="column">
                                    <ul style={{ paddingLeft: "17px", marginTop: "0px", marginBottom: "0px" }}>
                                        <li className="text-gray-500">
                                            <Typography className="text-gray-500" variant='button' fontWeight="400">
                                                { errorMessage }
                                            </Typography>
                                        </li>
                                        {/*<li className="text-gray-500">
                                            <Typography className="text-gray-500" variant='button' fontWeight="400">
                                                { "El número de cupones disponibles no puede estar en cero" }
                                            </Typography>
                                        </li>
                                        <li className="text-gray-500">
                                            <Typography className="text-gray-500" variant='button' fontWeight="400">
                                                { "Compra mínima no puede estar en blanco" }
                                            </Typography>
                                        </li>
                                        <li className="text-gray-500">
                                            <Typography className="text-gray-500" variant='button' fontWeight="400">
                                                { "Debes seleccionar al menos una categoría de productos" }
                                            </Typography>
                                        </li>
                                        <li className="text-gray-500">
                                            <Typography className="text-gray-500" variant='button' fontWeight="400">
                                                { "Debes seleccionar al menos una categoría de clientes" }
                                            </Typography>
                                        </li>
                                        <li className="text-gray-500">
                                            <Typography className="text-gray-500" variant='button' fontWeight="400">
                                                { "No puedes excluir todos los productos de la categoría de productos" }
                                            </Typography>
                                        </li>
                                        <li className="text-gray-500">
                                            <Typography className="text-gray-500" variant='button' fontWeight="400">
                                                { "El valor de descuento no puede superar el valor de compra" }
                                            </Typography>
                                        </li>
                                        <li className="text-gray-500">
                                            <Typography className="text-gray-500" variant='button' fontWeight="400">
                                                { "El Monto máximo de compra no puede ser igual o inferior al precio de los productos" }
                                            </Typography>
                                        </li>*/}
                                    </ul>
                                </Box>
                            </Box>
                            <Box display="flex" style={{ marginLeft: "auto" }}>
                                {/*<Box display="flex" className={classes.hover}>
                                    <Icon style={{ marginRight: "6px", fontSize: "14px" }} className={'ri-file-copy-line text-orange-300'}></Icon>
                                    <Typography className="text-orange-300" variant='h5' style={{ marginRight: "29.6px", fontWeight: "600", fontSize: "14px" }}>
                                        { "Copiar código" }
                                    </Typography>
                                </Box>*/}
                                <Box onClick={ e => onClose(e)}>
                                    <Icon style={{ fontSize: "18px" }} className={clsx('ri-close-line text-gray-300', classes.hover)}></Icon>
                                </Box>
                            </Box>
                            
                        </Box>
                        <Box style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", background: theme.palette.colors.error[400], zIndex: "0", opacity: "10%", borderRadius: "8px", border: "none" }}></Box>
                    </>
                } color="transparent" mt="0px" style={{ padding:"21.8px", position: "relative", border: "0.5px solid "+ theme.palette.colors.error[400], borderRadius: "8px" }} />
            }
        </>
    );
};

export default CouponDetail;