//=======================================================
/**
 * SlideShow.jsx
 * Slide show / Carousel de imagenes. 
 * author: lmunoz
 * v1.0
 */
//=======================================================

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { CardMedia, IconButton, makeStyles, useTheme } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import './SlideShow.css';

// @makeStyles
const useStyles = makeStyles((theme) => ({
    slideShowContainer: {
        margin: 'auto',
        position: 'relative',
    },
    slides: {
        display: 'none',
    },
    fade: {
        animation: "$slideDown 5s infinite",
        height: props => props.width,
        position: "relative",
        width: props => props.width,
    },
    "@keyframes slideDown": {
        from: { opacity: .4 },
        to: { opacity: 1 }
    },
    dotContainer: {
        left: '0px',
        marginTop: '-11%',
        position: 'absolute',
        right: '0px',
        textAlign: 'center',
    },
    dot: {
        backgroundColor: '#DCDFE5',
        borderRadius: '50%',
        display: 'inline-block',
        height: '7px',
        margin: '0 8.33px',
        transition: 'background-color 0.6s ease',
        width: '7px',
    },
    media: {
        border: '0px',
        cursor: "pointer",
        height: '358px',
        width: '289px',
    },
    sliderArrows: {
        left: 0,
        position: 'absolute',
        width: '100%',
        '& .previous': {
            backgroundColor: theme.palette.colors.grey[200],
            float: 'left',
            left: '2vw',
            height: '3.6vw',
            top: '45%',
            width: '3.6vw',
            zIndex: 1,
        },
        '& .next': {
            backgroundColor: theme.palette.colors.grey[200],
            float: 'right',
            height: '3.6vw',
            right: '2vw',
            top: '45%',
            width: '3.6vw',
            zIndex: 1,
        },
    },
    arrowIcon: {
        color: theme.palette.colors.black,
    }
}));

let AUTOMATIC_SLIDE = true;
export const SlideShow = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { name, src, className, mediaCSSClass, dotCSSClass, enableArrows, mediaHeight, xContent = {}, arrowCSSClass, arrowIconCSSClass } = props;

    let slideIndex = 0;
    const Arrows = () => {
        const previousSlide = () => {
            showSlides(slideIndex += -1);
        };

        const nextSlide = () => {
            showSlides(slideIndex += 1);
        };
        return (
            <div className={classes.sliderArrows} style={{ height: mediaHeight }}>
                <IconButton className={clsx("previous", !!arrowCSSClass && arrowCSSClass)} onClick={previousSlide}>
                    <KeyboardArrowLeft fontSize="medium" className={clsx(classes.arrowIcon, !!arrowIconCSSClass && arrowIconCSSClass)} />
                </IconButton>
                <IconButton className={clsx("next", !!arrowCSSClass && arrowCSSClass)} onClick={nextSlide}>
                    <KeyboardArrowRight fontSize="medium" className={clsx(classes.arrowIcon, !!arrowIconCSSClass && arrowIconCSSClass)} />
                </IconButton>
            </div>
        );
    };

    const showSlides = (n) => {
        if (n) {
            AUTOMATIC_SLIDE = false;
        }

        let i;
        let slides = document.getElementsByName(`slide_${name}`);
        let dots = document.getElementsByName(`dot_${name}`);

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }

        if (AUTOMATIC_SLIDE) {
            slideIndex++;
            if (slideIndex > slides.length) {
                slideIndex = 1;
            }
        }
        else {
            if (n < 1) { slideIndex = slides.length; }
            if (slideIndex > slides.length) {
                slideIndex = 1;
            }
        }

        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(' SlideShowActive', '');
        }

        if (slides[slideIndex - 1]) {
            slides[slideIndex - 1].style.display = 'block';
        }

        if (dots[slideIndex - 1]) {
            dots[slideIndex - 1].className += ' SlideShowActive';
        }

        if (AUTOMATIC_SLIDE) {
            setTimeout(showSlides, 4000);
        }
    }

    React.useEffect(() => {
        showSlides();
    }, []);

    return (
        <CardMedia component="div" className={className}>
            {enableArrows && <Arrows />}
            {src && src.map((src, k) => {
                return (
                    <React.Fragment key={k}>
                        <div key={k} name={`slide_${name}`} className={`slides ${classes.slides} ${classes.fade}`}>
                            {xContent.length > 0 && xContent[k]}
                            <CardMedia image={src} component="img" className={mediaCSSClass} />
                        </div>
                    </React.Fragment>
                );
            })}
            <div className={`${classes.dotContainer} ${dotCSSClass}`}>
                {src && src.map((src, k) => {
                    return (
                        <span key={k} name={`dot_${name}`} className={`dot ${classes.dot}`}></span>
                    )
                })}
            </div>
        </CardMedia>
    );
};

SlideShow.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    src: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
    mediaCSSClass: PropTypes.string.isRequired,
    dotCSSClass: PropTypes.string.isRequired,
    enableArrows: PropTypes.bool.isRequired,
    mediaHeight: PropTypes.string.isRequired,
    xContent: PropTypes.array,
    arrowCSSClass: PropTypes.string,
    arrowIconCSSClass: PropTypes.string,
};