import React from 'react';

// @material-core
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Local
import {detailsStyles} from '../../../../../assets/styles/jss/pages/degreeCertificates/DetailsStyles';
import dateFormat from '../../../../../design-system/utils/dateFormat';
import {ISO_DATE_FORMAT} from '../../../../../design-system/constants/date-formats';

// @design-system
import Select from '../../../../../design-system/components/Form/Select/Select';

//@ThirtyParty
import clsx from 'clsx';
import {findIndex, isEmpty, isNil, propEq} from "ramda";
import Picker from "../../../../../shared/components/Form/Picker";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Icon} from "@material-ui/core";


export const AcademicInfo = ({data, onChange, isEdit, catalogues, errorForm, disabledDates}) => {

  const classes = detailsStyles({isEdit});
  return (<Grid container spacing={3} className="mt-1">
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={4} className={classes.item}>
          <Grid container className='py-1  ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Carrera
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carrera"
                name="carrera"
                fullWidth
                className={classes.label}
                value={data.carrera || ''}
                InputProps={{
                  className: classes.inputText, disableUnderline: true, readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Grid container className='py-1 ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Carrera SEP
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carreraSep"
                name="carreraSep"
                fullWidth
                className={classes.label}
                value={data.carreraSep || ''}
                InputProps={{
                  className: classes.inputText, disableUnderline: true, readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container className='py-1 ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Nombre Carrera
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="nombreCarrera"
                name="nombreCarrera"
                multiline={true}
                fullWidth
                className={classes.label}
                value={data.nombreCarrera || ''}
                InputProps={{
                  className: classes.inputText, disableUnderline: true, readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Grid container className='py-1  ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Campus
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="campus"
                name="campus"
                multiline={true}
                fullWidth
                className={classes.label}
                value={data.campus || ''}
                InputProps={{
                  className: classes.inputText, disableUnderline: true, readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Grid container className='py-1 ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Entidad Federativa
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!isEdit ? <TextField
                id="idEntidadFederativaCarrera"
                name="idEntidadFederativaCarrera"
                placeholder='Entidad Federativa'
                fullWidth
                className={classes.label}
                value={catalogues.entityFederative[findIndex(propEq(data.idEntidadFederativaCarrera, 'id'))(catalogues.entityFederative)]?.label || ''}
                InputProps={{
                  className: clsx(!isEdit && classes.inputText), disableUnderline: true, readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              /> : <Select
                items={catalogues.entityFederative}
                label=""
                className={clsx(classes.select)}
                name="idEntidadFederativaCarrera"
                onClick={e => e.stopPropagation()}
                onChange={(e) => {
                  onChange(e)
                }}
                disabled={!isEdit}
                value={data.idEntidadFederativaCarrera || ""}
                size="small"
              />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container className='py-1 ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                No. RVOE
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="numeroRvoe"
                name="numeroRvoe"
                fullWidth
                className={classes.label}
                value={data.numeroRvoe || ''}
                InputProps={{
                  className: classes.inputText, disableUnderline: true, readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Grid container className='py-1 ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Fecha Inicio
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/*!isEdit*/ true ?<TextField
                  id="fechaInicioCarrera"
                  name="fechaInicioCarrera"
                  placeholder='Fecha Inicio'
                  fullWidth
                  className={classes.label}
                  value={data.fechaInicioCarrera || ''}
                  InputProps={{
                    className: clsx(classes.inputText), disableUnderline: true, readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!isNil(errorForm?.fechaInicioCarrera)}
                  helperText={!isNil(errorForm?.fechaInicioCarrera) && errorForm?.fechaInicioCarrera}
                /> :
                <Picker
                  className={classes.datePicker}
                  name="fechaInicioCarrera"
                  value={dateFormat(data.fechaInicioCarrera || '', ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                  onChange={(e) => {
                    e.target['type'] = "date";
                    onChange(e)
                  }}
                  onClick={e => e.stopPropagation()}
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  autoOk={false}
                  keyboardbuttonprops={{
                    "aria-label": "change date"
                  }}
                  views={['year', 'month', 'date']}
                  shouldDisableDate={disabledDates}
                  error={!isNil(errorForm?.fechaInicioCarrera)}
                  helperText={!isNil(errorForm?.fechaInicioCarrera) && errorForm?.fechaInicioCarrera}
                  InputProps={{
                    startAdornment: (<InputAdornment position={'start'}>
                      <Icon className={clsx('text-white-200 ri-calendar-2-line mr-1')}
                            style={{fontSize: 18}}/>
                    </InputAdornment>)
                  }}
                />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Grid container className='py-1 ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Fecha Término
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/*!isEdit*/ true ? <TextField
                  id="fechaTerminacionCarrera"
                  name="fechaTerminacionCarrera"
                  placeholder='Fecha Término'
                  fullWidth
                  className={classes.label}
                  value={data.fechaTerminacionCarrera || ''}
                  InputProps={{
                    className: clsx(classes.inputText), disableUnderline: true, readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!isNil(errorForm?.fechaTerminacionCarrera)}
                  helperText={!isNil(errorForm?.fechaTerminacionCarrera) && errorForm?.fechaTerminacionCarrera}
                /> :
                <Picker
                  className={classes.datePicker}
                  name="fechaTerminacionCarrera"
                  value={dateFormat(data.fechaTerminacionCarrera || '', ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                  onChange={(e) => {
                    e.target['type'] = "date";
                    onChange(e)
                  }}
                  onClick={e => e.stopPropagation()}
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  autoOk={false}
                  views={['year', 'month', 'date']}
                  keyboardbuttonprops={{
                    "aria-label": "change date"
                  }}
                  shouldDisableDate={disabledDates}
                  error={!isNil(errorForm?.fechaTerminacionCarrera)}
                  helperText={!isNil(errorForm?.fechaTerminacionCarrera) && errorForm?.fechaTerminacionCarrera}
                  InputProps={{
                    startAdornment: (<InputAdornment position={'start'}>
                      <Icon
                        className={clsx('text-white-200 ri-calendar-2-line mr-1')}
                        style={{fontSize: 18}}/>
                    </InputAdornment>)
                  }}
                />}
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={4}>

          <Grid container className='py-1 ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Fecha Expedición
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="fechaExpedicion"
                name="fechaExpedicion"
                type='date'
                fullWidth
                value={dateFormat(data.fechaExpedicion || ' ', ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])}
                InputProps={{
                  className: `${classes.inputText} ${classes.inputDate}`, disableUnderline: true, readOnly: true,
                }}
              />
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Grid container className='py-1 ml-2'>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Id Reconocimiento
              </Typography>
            </Grid>
            <Grid item xs={12}>

              {/*{!isEdit ?*/}
              <TextField
                id="idAutorizacionReconocimiento"
                name="idAutorizacionReconocimiento"
                placeholder='Id Reconocimiento'
                fullWidth
                multiline={true}
                className={classes.label}
                value={catalogues.acknowledgment[findIndex(propEq(data.idAutorizacionReconocimiento, 'id'))(catalogues.acknowledgment)]?.label || ''}
                InputProps={{
                  className: clsx(!isEdit && classes.inputText), disableUnderline: true, readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/*: <Select
                  items={catalogues.acknowledgment}
                  label=""
                  className={clsx(classes.select)}
                  name="idAutorizacionReconocimiento"
                  onClick={e => e.stopPropagation()}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  disabled={!isEdit}
                  value={data.idAutorizacionReconocimiento || ""}
                  size="small"
                />}*/}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>)
};
