import React from 'react';

// @redux
import { connect } from "react-redux";

// @material-ui/core
import Box from '@material-ui/core/Box';

// SDK
import { AccessService } from '@sdk-point/talisis';

//shared
import Toast from '../../../shared/components/Toast/Toast';
import { log } from '../../../shared/utils/console.js'

// core
import RegisterHome from './RegisterHome/RegisterHome';
import RegisterQuestions from './RegisterQuestions/RegisterQuestions';
import RegisterResult from './RegisterResult/RegisterResult';

const AccessServiceSDK = new AccessService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

class Register extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            card: "home",
            questions: [],
            result: {},
            form: {
                questions: []
            },
            toastProps: {
                message: ''
            }
        };
    };
    
    getData = async () => {
        this.setState({
            loading: true,
            questions: [],
            result: {},
            form: {
                questions: []
            },
            toastProps: {
                message: ''
            }
        });

        try {
            const questions = await AccessServiceSDK.getQuestions();
            this.setState({ loading: false, questions });
        }
        catch (e) {
            this.setState({ loading: false, toastProps: { severity: "error", open: true, message: 'Ocurrió un error al configurar el cuestionario.' } });
        }
    };

    componentDidMount() {
        this.getData();
    };

    handleBegin() {
        this.setState({ card: "questions" });
    };
    
    handleFinish() {
        this.setState({ card: "home" });
        this.getData();
    };
    
    handleCancel() {
        this.setState({ card: "home" });
        this.getData();
    };
    
    handleChange(name, value) {
        this.setState(prevState => {
            return { ...prevState, form: { ...prevState.form, [name]: value } }
        });
    };
    
    getSelectedValues(name) {
        let values = this.state.form.questions.find(q => q.name === name)?.values;
        if (!values) {
            values = []
        }
        return values;
    };
    
    setQuestionOptions(name, options) {
        const questions = this.state.questions.map(el => {
            if (el.name === name) {
                el.options = options;
            }
            return el;
        });
        this.setState({ questions });
    };
    
    setQuestionForm(name, values) {
        const questions = this.state.form.questions.map(el => {
            if (el.name === name) {
                el.values = values;
            }
            return el;
        });

        this.handleChange("questions", questions);
    };
    
    handleRequest = async () => {
        const questions = [...this.state.form.questions];
        const location = this.getSelectedValues("location")[0];
        const schedule = this.getSelectedValues("schedule")[0];

        const body = {
            location_id: location.id,
            person_id: this.props.user.person_id,
            date: schedule.date,
            questions: questions.filter(q => !(q.name === "location" || q.name === "schedule"))
        };

        try {
            this.setState({ loading: true });
            const data = await AccessServiceSDK.register(body);
            this.setState({ loading: false, card: "result", result: await data });
        } catch (e) {
            this.setState({ loading: false, toastProps: { severity: "error", open: true, message: 'Ocurrió un error al enviar el registro. Por favor intenta de nuevo.' } });
        }
    };
    
    handleFinishedToast() {
        const toastProps = { ...this.state.toastProps };
        toastProps.open = false;
        this.setState({ toastProps });
    };
    
    render() {
        const tasks = {
            ["location"]: {
                load: async () => {
                    try {
                        this.setState({ loading: true });

                        let company = this.props.user.ou;

                        if (this.props.user.email.includes('iesalud') === true) {
                            company = 'iesalud'
                        }

                        const locations = await AccessServiceSDK.getLocations(company);
                        this.setQuestionOptions("location", locations);
                        this.setState({ loading: false });
                    } catch (e) {
                        this.setState({ loading: false, toastProps: { severity: "error", open: true, message: 'Ocurrió un error al consultar las ubicaciones.' } });
                    }
                }
            },
            ["schedule"]: {
                load: async () => {
                    try {
                        this.setState({ loading: true });
                        this.setQuestionOptions("schedule", []);
                        this.setQuestionForm("schedule", []);
                        const location = this.getSelectedValues("location")[0];
                        const schedule = await AccessServiceSDK.getPersonLocationSchedule(this.props.user, location);
                        this.setQuestionOptions("schedule", schedule?.days);
                        this.setState({ loading: false });
                    } catch (e) {
                        this.setState({ loading: false, toastProps: { severity: "error", open: true, message: 'Ocurrió un error al consultar tu horario de clases.' } });
                    }
                }
            }
        };

        const vLocation = {
            id: "schedule",
            severity: "warning",
            message: "La ubicación ya no se encuentra disponible en la fecha seleccionada.",
            validate: async () => {
                try {
                    this.setState({ loading: true });
                    const location = this.getSelectedValues("location")[0];
                    const schedule = this.getSelectedValues("schedule")[0];
                    const response = await AccessServiceSDK.getLocationRegisters(location.id, schedule.date);
                    this.setState({ loading: false });

                    return response.person_count < response.person_limit;
                } catch (e) {
                    this.setState({ loading: false, toastProps: { severity: "error", open: true, message: 'Ops! Ocurrió un error al consultar la disponibilidad del día. Por favor intenta de nuevo.' } });
                }

                return false;
            }
        };

        const vRegisters = {
            id: "schedule",
            severity: "warning",
            message: "Ya realizaste un registro en la fecha seleccionada.",
            validate: async () => {
                try {
                    const schedule = this.getSelectedValues("schedule")[0];
                    const logs = schedule?.logs;
                    return !(logs && logs.length > 0);
                }
                catch (e) {
                    log(e.message);
                };

                return false;
            }
        };

        const { card } = this.state;
        const cards = {
            "home": <RegisterHome {...this.state} user={this.props.user} onClick={this.handleBegin.bind(this)} />,
            "questions": <RegisterQuestions {...this.state} validations={[vLocation, vRegisters]} tasks={tasks} onChange={this.handleChange.bind(this)} onCancel={this.handleCancel.bind(this)} onRequest={this.handleRequest.bind(this)} />,
            "result": <RegisterResult {...this.state} onClick={this.handleFinish.bind(this)} />
        };

        return <Box>
            {card && cards[card]}
            <Toast {...this.state.toastProps} onFinished={this.handleFinishedToast.bind(this)} />
        </Box>
    };
}

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(Register);