import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import { Icon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
// styles
import useStyles from '../../../assets/styles/jss/pages/market/SummaryCard'
// shared components
import getMembershipIcon from '../../../shared/components/Modalupselling/MembershipIconSelector';
import { log } from '../../../shared/utils/console.js';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import clsx from 'clsx';
import StudentRegulation from '../../../design-system/components/StudentRegulation/StudentRegulation.jsx';

// routes
import * as paths from '../../../routes/paths';

const SummaryCard = (props) => {
    const theme = useTheme();
    const history = useHistory();

    const { cardSize = 12, cardTitle, price, oldPrice, btnDisabled, btnFunction,
        btnText, loading, components = [], isPurchased,
        membershipData, company_id, user_membership_included, closest_membership_included, isLogged, simulacionPermission, hideBookmark, aux_components=[], 
        hideInfoMembership, cardClass, inlinePrice, onChangeShowSubscribeStickyButton, disabledCta, showStudentRegulation = false } = props;

    const [membershipIncluded, setMembershipIncluded] = React.useState(user_membership_included);
    const [specialCase, setSpecialCase] = React.useState(company_id === "UERRE" && membershipData?.user_membership?.actual_membership?.slug === "professional" ? true : false);
    const [actualMembership, setActualMembership] = React.useState(membershipData?.user_membership?.actual_membership);
    log("membership: ", membershipData?.user_membership?.actual_membership);
    const [nextMembership, setNextMembership] = React.useState(closest_membership_included.length > 0 ? membershipData?.memberships?.find(x => x.id === closest_membership_included[0].membership_id) : membershipData?.user_membership?.next_membership);
    const [isCustomMembership,setIsCustomMembership]=React.useState(membershipData?.user_membership?.is_custom_membership);
    
    let isSubscribeButtonHide = false;

    const classes = useStyles({hideInfoMembership, membershipIncluded});

    const btnSubscribeRef = React.useRef();

    const gotoMemberships = (e) => {
        e.preventDefault();
        //
        const eventData = {};
        eventData['source'] = 'landing';
        eventTracker('ver_membresías', eventData, ['mixpanel','google']);
        //
        history.push({
            pathname: paths.MEMBRESIAS
        });
    };

    const customMembershipMessage=( isCustomMembership && !membershipIncluded && <Grid item xs={12} className={clsx(classes.customMembershipMessageContainer)}>
        <Box className='mb-3'>
            <Typography className={classes.customMembershipMessage}>
                Este programa no está incluido en tu membresía. Para conocer más acerca de nuestras membresías llámanos al:
            </Typography>
        </Box>
        <Box className={clsx(classes.customMembershipMessageBorder)}>
            <Typography className={classes.customMembershipMessage}>
                <Grid container>
                    <Grid item className='mr-2'>
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9.28724V11.6446C12.0001 11.8134 11.9361 11.9759 11.8211 12.0994C11.706 12.2229 11.5484 12.2981 11.38 12.3099C11.0887 12.3299 10.8507 12.3406 10.6667 12.3406C4.77533 12.3406 0 7.56524 0 1.67391C0 1.48991 0.01 1.25191 0.0306667 0.960576C0.0424816 0.792206 0.117721 0.634583 0.241202 0.519515C0.364684 0.404447 0.527215 0.3405 0.696 0.340576H3.05333C3.13603 0.340493 3.2158 0.371149 3.27715 0.42659C3.33851 0.482031 3.37706 0.558298 3.38533 0.640576C3.40067 0.79391 3.41467 0.91591 3.428 1.00858C3.56049 1.93319 3.832 2.83246 4.23333 3.67591C4.29667 3.80924 4.25533 3.96858 4.13533 4.05391L2.69667 5.08191C3.5763 7.13154 5.2097 8.76494 7.25933 9.64458L8.286 8.20858C8.32796 8.14991 8.38919 8.10783 8.459 8.08968C8.52881 8.07153 8.60277 8.07845 8.668 8.10924C9.51135 8.50982 10.4104 8.78066 11.3347 8.91258C11.4273 8.92591 11.5493 8.94058 11.7013 8.95524C11.7835 8.96367 11.8596 9.00229 11.9149 9.06363C11.9702 9.12497 12.0008 9.20465 12.0007 9.28724H12Z" fill="#CCCCCC"/>
                        </svg>
                    </Grid>
                    <Grid item>
                        8007709090 - opción 2
                    </Grid>
                </Grid>
            </Typography>
        </Box>
    </Grid>);

    log(nextMembership)

    const handleScroll = (event) => {
        if (!isSubscribeButtonHide && window.scrollY > (btnSubscribeRef.current.offsetTop + btnSubscribeRef.current.offsetHeight)) {
            isSubscribeButtonHide = true;
            onChangeShowSubscribeStickyButton();
        }
            
        if (!!isSubscribeButtonHide && window.scrollY <= btnSubscribeRef.current.offsetTop) {
            isSubscribeButtonHide = false;
            onChangeShowSubscribeStickyButton();
        }
    };

    React.useEffect(() => {
        if (!!onChangeShowSubscribeStickyButton) {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getMembershipFee = () => {
        const membershipFees = nextMembership?.fees?.length > 0 ? nextMembership?.fees : [];
        const membershipFee = membershipFees.find(obj => obj.term_slug === "TRIM");
        return membershipFee;
    };

    return (
        <Grid item className={classes.root} md={cardSize} xs={12}>
            {
            !inlinePrice ? 
            <Card className={clsx(classes.card, cardClass)}>
                <CardContent className={classes.cardContent}>
                    {
                        actualMembership !== undefined ?
                            <Grid container spacing={!hideInfoMembership && 1} justifyContent='space-between' alignItems='center'>
                                {
                                    // actualMembership !== undefined && !membershipIncluded && !specialCase && (
                                    actualMembership !== undefined && !membershipIncluded && (
                                        <Grid item xs={12} style={{ paddingBottom: '8px' }}>
                                            <Typography variant='caption' align='left' className='text-gray-500'>
                                                {cardTitle}
                                            </Typography>
                                        </Grid>
                                    )
                                }
                                {
                                    // (actualMembership !== undefined && !isPurchased && !membershipIncluded && !specialCase) &&
                                    (actualMembership !== undefined && !isPurchased && !membershipIncluded) &&
                                    <Grid container justifyContent='flex-start' className="mb-3">
                                        <Grid item >
                                            <Typography variant="h3" color='secondary' align='left'>
                                                <CurrencyFormat value={getMembershipFee()?.amount_per_month || 0} decimalScale={0} fixedDecimalScale={true} decimalSeparator={false} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />
                                                <Typography variant='h5' color="secondary" style={{ display: 'inline', paddingLeft: '8px' }}>
                                                    al mes
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={isLogged ? 12 : 12} style={{ paddingBottom: !hideInfoMembership && '20px' }} >
                                    { membershipIncluded && <Box sx={{ paddingBottom: '24px' }}>
                                            <Typography variant='h5' className={classes.titleBox}>
                                              { (btnText==='Ir a mis clases') ? 'Inscrito': 'Incluido en tu Membresía'}
                                            </Typography>
                                        </Box>
                                    }
                                    <Box>
                                        <Button
                                            ref={btnSubscribeRef}
                                            startIcon={btnDisabled && <i class="ri-lock-line"></i>}
                                            color={(!loading) ? (isCustomMembership && !membershipIncluded?'secondary':'primary') : 'secondary'}
                                            variant="contained" onClick={btnFunction}
                                            disabled={loading || disabledCta || btnDisabled || (isCustomMembership && !membershipIncluded)}
                                            style={{ width: "100%", height: hideInfoMembership && "48px" }}
                                            className={classes.bottomBlock}
                                        >
                                                 {btnText}
                                        </Button>
                                        {
                                            disabledCta
                                            ?
                                                <CircularProgress size={24} style={{ position: 'absolute', top: '-8%', left: '80%' }} />
                                            :
                                            null
                                        }
                                    </Box>
                                    {
                                        btnDisabled &&
                                        <Box sx={{ paddingTop: '20px' }} >
                                            <Typography variant='caption' className={classes.messageBox}>
                                                Lo sentimos, solo puedes cursar un programa a la vez. 
                                                Dirígete a “Mis clases” para continuar o <Typography variant='caption' className={classes.messageBoxOrange}>contacta a soporte</Typography> para más información.
                                            </Typography>
                                        </Box> 
                                    }
                                    
                                </Grid>
                                {customMembershipMessage}
                                {
                                    // (actualMembership !== undefined && membershipIncluded || specialCase) && !isPurchased && nextMembership ?
                                    (actualMembership !== undefined && membershipIncluded) && !isPurchased && nextMembership &&  !btnDisabled &&
                                        <Grid item xs={12} style={{ paddingTop: '4px', paddingBottom: '24px' }}>
                                            <Typography className="font-variant-v2">
                                                {nextMembership?.sidebar_program?.description}
                                            </Typography> 
                                            <Button 
                                              variant="outlined" 
                                              color='secondary' 
                                              onClick={()=>{}}
                                              style={{ width: "100%", marginTop:'24px'}}
                                              component={RouterLink} 
                                              to={paths.MEMBRESIAS}
                                              >Ver membresías</Button>
                                        </Grid> 


                                }
                                {
                                    // actualMembership !== undefined && !membershipIncluded && !specialCase
                                    actualMembership !== undefined && !membershipIncluded && !hideInfoMembership
                                        ?
                                        <Grid item xs={12}>
                                            <Box sx={{ paddingBottom: '24px' }}>
                                                <Typography variant='body' className='text-gray-100' style={{ fontSize: '12px' }} >Al suscribirte a este programa obtén:</Typography>
                                            </Box>
                                            <Box sx={{ textAlign: 'left', paddingBottom: '16px' }}>
                                                <Grid container>
                                                    <Grid item xs="auto" style={{ paddingRight: '9px' }}>
                                                        {getMembershipIcon(nextMembership?.icon, 13)}
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography className="font-variant-v2"> Membresía {nextMembership ? nextMembership.name : ""}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {
                                                nextMembership?.list_details.map((value, index) => {
                                                    return (
                                                        <Box sx={{ textAlign: 'left', paddingBottom: '8px' }}>
                                                            <Grid container>
                                                                <Grid item xs="auto">
                                                                    <CheckIcon style={{ fontSize: '16px', color: '#9F6FFB', marginRight: '8px' }} />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography className="font-variant-v2" style={{ paddingLeft: '0px', color: '#A0A9BA' }}> {value.title}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    );
                                                })
                                            }
                                            {
                                                <Button color='primary' className={classes.buttonMemberships} component={RouterLink} to={paths.MEMBRESIAS}>
                                                    Ver membresías
                                                </Button>
                                            }
                                        </Grid>
                                        :
                                        null
                                }
                            </Grid> :
                            <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                                <Grid item xs={12}>
                                    <Typography variant='caption' align='left' style={{ color: theme.palette.colors.grey[900] }}>
                                        {cardTitle}
                                    </Typography>
                                </Grid>
                                {
                                    (!isPurchased) &&
                                    <Grid container justifyContent='flex-start' className="mb-3">
                                        <Grid item >
                                            <Typography variant="h3" color='secondary' align='left'>
                                                {
                                                    (price !== undefined && !isNaN(price))
                                                        ? `$${price} MXN`
                                                        : "No disponible"
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography
                                                className='ml-2' variant="subtitle2"
                                                color='textSecondary' align='left'
                                                style={{ textDecoration: "line-through", marginTop: "5px" }}
                                            >
                                                {
                                                    (oldPrice !== undefined && !isNaN(oldPrice))
                                                        ? `$${oldPrice} MXN`
                                                        : "No disponible"
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={hideBookmark ? 12 : 10}>
                                    <Box>
                                        <Button
                                            ref={btnSubscribeRef}
                                            color={(!loading) ? (isCustomMembership && !membershipIncluded?'secondary':'primary') : 'secondary'}
                                            variant="contained" onClick={btnFunction}
                                            disabled={loading || disabledCta || btnDisabled || (isCustomMembership && !membershipIncluded)}
                                            style={{ width: "100%", height: hideInfoMembership && "48px"  }}
                                        >
                                            <Typography variant='body2'>
                                                {btnText}
                                            </Typography>
                                        </Button>
                                        {
                                            disabledCta
                                            ?
                                                <CircularProgress size={24} style={{ position: 'absolute', top: '-8%', left: '80%' }} />
                                            :
                                            null
                                        }
                                    </Box>
                                </Grid>
                                {
                                    !hideBookmark && (<Grid item xs={2}>
                                        <IconButton style={{ color: theme.palette.colors.white }}>
                                            <BookmarkBorderIcon />
                                        </IconButton>
                                    </Grid>)
                                }
                                {customMembershipMessage}
                            </Grid>
                    }
                </CardContent>
            </Card>
            :
            <Card className={classes.cardPriceOnly}>
                <CardContent className={classes.cardContentPriceOnly}>
                    {
                        actualMembership !== undefined ?
                            <Grid container spacing={!hideInfoMembership && 1} justifyContent='space-around' alignItems='center'>
                                <Box className={classes.boxPrice}>
                                    {
                                        // actualMembership !== undefined && !membershipIncluded && !specialCase && (
                                        actualMembership !== undefined && !membershipIncluded && (
                                            <Box>
                                                <Typography align='left' className='variant-caption-sm text-gray-100'>
                                                    {cardTitle}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                    {
                                        // (actualMembership !== undefined && !isPurchased && !membershipIncluded && !specialCase) &&
                                        (actualMembership !== undefined && !isPurchased && !membershipIncluded) &&
                                            <Box>
                                                <Typography variant="h4" color='secondary' align='left'>
                                                    <CurrencyFormat value={getMembershipFee()?.amount_per_month || 0} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />
                                                    <Typography variant='h5' color="secondary" style={{ display: 'inline', paddingLeft: '4px' }}>
                                                        al mes
                                                    </Typography>
                                                </Typography>
                                            </Box>
                                    }
                                </Box>
                                <Grid item xs={membershipIncluded ? 12 : 6} style={{ paddingBottom: !hideInfoMembership && '20px' }} >
                                    <Box minWidth={167}>
                                        <Button
                                            startIcon={btnDisabled && <Icon className={`ri-lock-line font-size-24`} />}
                                            ref={btnSubscribeRef}
                                            color={(!loading) ? (isCustomMembership && !membershipIncluded?'secondary':'primary') : 'secondary'}
                                            variant="contained" onClick={btnFunction}
                                            disabled={loading || disabledCta || btnDisabled || (isCustomMembership && !membershipIncluded)}
                                            style={{ width: "100%", height: hideInfoMembership && "48px" }}
                                            className={classes.bottomResp}
                                        >
                                            <Typography variant='body2'>
                                                {btnText}
                                            </Typography>
                                        </Button>
                                        {
                                            disabledCta
                                            ?
                                                <CircularProgress size={24} style={{ position: 'absolute', top: '-8%', left: '80%' }} />
                                            :
                                            null
                                        }
                                    </Box>
                                </Grid>
                                {customMembershipMessage}
                                {
                                    // (actualMembership !== undefined && membershipIncluded || specialCase) && !isPurchased && nextMembership ?
                                    (actualMembership !== undefined && membershipIncluded) && !isPurchased && nextMembership ?
                                        <Grid item xs={12} style={{ paddingTop: '4px', paddingBottom: '24px' }}>
                                            <Typography className="font-variant-v2">
                                                {nextMembership ? nextMembership?.sidebar_program?.description : ""}
                                            </Typography>
                                        </Grid> :
                                        null
                                }
                                {
                                    // actualMembership !== undefined && !membershipIncluded && !specialCase
                                    actualMembership !== undefined && !membershipIncluded && !hideInfoMembership
                                        ?
                                        <Grid item xs={12}>
                                            <Box sx={{ paddingBottom: '24px' }}>
                                                <Typography variant='body1' className='text-gray-100' style={{ fontSize: '12px' }} >Al suscribirte a este programa obtén:</Typography>
                                            </Box>
                                            <Box sx={{ textAlign: 'left', paddingBottom: '16px' }}>
                                                <Grid container>
                                                    <Grid item xs="auto" style={{ paddingRight: '9px' }}>
                                                        {getMembershipIcon(nextMembership?.icon, 13)}
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography className="font-variant-v2"> Membresía {nextMembership ? nextMembership.name : ""}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {
                                                nextMembership?.list_details.map((value, index) => {
                                                    return (
                                                        <Box sx={{ textAlign: 'left', paddingBottom: '8px' }}>
                                                            <Grid container>
                                                                <Grid item xs="auto">
                                                                    <CheckIcon style={{ fontSize: '16px', color: '#9F6FFB', marginRight: '8px' }} />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography className="font-variant-v2" style={{ paddingLeft: '0px', color: '#A0A9BA' }}> {value.title}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    );
                                                })
                                            }
                                            {
                                                !membershipData?.user_membership?.is_custom_membership?
                                                <Box sx={{ paddingBottom: '24px', textAlign: 'left', paddingTop: '8px' }}>
                                                    <Link component={RouterLink} to={paths.MEMBRESIAS} onClick={(e) => gotoMemberships(e)}>
                                                        <Typography variant='body2' color="secondary" style={{ fontWeight: '600', cursor: 'pointer' }}>Ver membresías</Typography>
                                                    </Link>
                                                </Box>:null
                                            }
                                        </Grid>
                                        :
                                        null
                                }
                            </Grid> :
                            <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                                <Grid item xs={12}>
                                    <Typography variant='caption' align='left' style={{ color: theme.palette.colors.grey[900] }}>
                                        {cardTitle}
                                    </Typography>
                                </Grid>
                                {
                                    (!isPurchased) &&
                                    <Grid container justifyContent='flex-start' className="mb-3">
                                        <Grid item >
                                            <Typography variant="h3" color='secondary' align='left'>
                                                {
                                                    (price !== undefined && !isNaN(price))
                                                        ? `$${price} MXN`
                                                        : "No disponible"
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography
                                                className='ml-2' variant="subtitle2"
                                                color='textSecondary' align='left'
                                                style={{ textDecoration: "line-through", marginTop: "5px" }}
                                            >
                                                {
                                                    (oldPrice !== undefined && !isNaN(oldPrice))
                                                        ? `$${oldPrice} MXN`
                                                        : "No disponible"
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={hideBookmark ? 12 : 10}>
                                    <Box>
                                        <Button
                                            ref={btnSubscribeRef}
                                            color={(!loading) ? (isCustomMembership && !membershipIncluded?'secondary':'primary') : 'secondary'}
                                            variant="contained" onClick={btnFunction}
                                            disabled={loading || disabledCta || btnDisabled || (isCustomMembership && !membershipIncluded)}
                                            style={{ width: "100%", height: hideInfoMembership && "48px"  }}
                                        >
                                            <Typography variant='body2'>
                                                {btnText}
                                            </Typography>
                                        </Button>
                                        {
                                            disabledCta
                                            ?
                                                <CircularProgress size={24} style={{ position: 'absolute', top: '-8%', left: '80%' }} />
                                            :
                                            null
                                        }
                                    </Box>
                                </Grid>
                                {
                                    !hideBookmark && (<Grid item xs={2}>
                                        <IconButton style={{ color: theme.palette.colors.white }}>
                                            <BookmarkBorderIcon />
                                        </IconButton>
                                    </Grid>)
                                }
                                {customMembershipMessage}
                            </Grid>
                    }
                </CardContent>
            </Card>
            }
            {
                (components.length > 0) &&
                <Card style={{marginTop:'12px'}}>
                    <CardContent>
                        <Box>
                            {components.map((component, index) =>
                                <Grid container
                                    key={index}
                                    spacing={1}
                                    className={ index>0?'mt-2':null }
                                    alignItems='center'
                                    style={{ borderTop: index>0? `1.5px solid ${theme.palette.colors.grey[700]}`:null }}
                                >
                                    {component}
                                </Grid>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            }
            {
                showStudentRegulation && <StudentRegulation companyId={company_id} mt={1.5}/>
            }
            {
            (aux_components.length > 0) &&
                <Card style={{marginTop:'12px'}}>
                    <CardContent>
                        <Box>
                            {aux_components.map((component, index) =>
                                <Grid container
                                    key={index}
                                    spacing={1}
                                    className={ index>0?'mt-2':null }
                                    alignItems='center'
                                    style={{ borderTop: index>0? `1.5px solid ${theme.palette.colors.grey[700]}`:null }}
                                >
                                    {component}
                                </Grid>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            }
            {
                !hideInfoMembership && !inlinePrice && props.FAQsComponent && <Card style={{marginTop:'12px'}}>
                <CardContent className='px-0 py-0'>
                    {props.FAQsComponent}
                </CardContent>
            </Card>
            }
        </Grid>
    )
}

export default SummaryCard;