import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root:{
        padding: '48px 0px 52px',
        '& h1':{
            marginBottom: 48
        },
        borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`
    },
    cards:{
        maxWidth: 912,
        width: '100%',
        display: 'inline-flex',
        alignItems: 'flex-start',
        gap: 24,
        marginBottom: 38,
    },
    card:{
        borderRadius: 8,
        border: `0.5px solid ${theme.palette.colors.violet[300]}`,
        background: theme.palette.colors.darkBlack[400],
        paddingBottom: 63,
        gap: 50,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 288,
        flex: 1,
        '&:hover':{
            cursor: 'pointer'
        }
    },
    cardContent:{
        '& h3':{
            marginBottom: 48,
            [theme.breakpoints.down('xs')]:{
                marginBottom: 16,
            }
        }
    },
    border:{
        height: 13,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            padding: '40px 0px 32px',
            '& h1':{
                marginBottom: 32
            }
        },
        cards:{
            flexDirection: 'column',
        },
        card:{
            maxWidth: '100%',
            width: '100%',
            paddingBottom: 37,
            gap: 24,
        },
        cardContent:{
            '& h2':{
                marginBottom: 16
            }
        },
        border:{
            height: 13,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
        } 
    }
}));