import React from 'react';

// redux
import { connect } from 'react-redux';

// @material-ui/core
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RowContainer from './components/RowContainer';
import Loader from '../../../../shared/components/Loader/LoaderContent';
import { log } from '../../../../shared/utils/console.js'

// services
import { ENVIRONMENT, ProgramService } from '@sdk-point/talisis';
import NothingFound from '../../../../shared/components/NothingFound/NothingFound';

const useHeadStyles = makeStyles((theme) => ({
    contain: {
        backgroundColor: theme.palette.primary.main
    },
    table_contain:{
        border: "0.5px solid #828D9E",
        borderRadius: "8px"
    },
    table_body: {
        maxHeight: "500px"
    },
    buttons_contain: {
        justifyContent: "flex-end",
        maxWidth: "100%",
        marginBottom: "25px"
    },
    'main': {
        zIndex: "1100",
        maxWidth: "1248px",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "8px",
    },
}));

const TableHeadCustom = withStyles(theme => ({
    root: {
      backgroundColor: theme.palette.primary.main,
    }
  }))(TableHead);
  

const Programs = (props) => {
    const [data, setData] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('ordenId');
    const [loading, setLoading] = React.useState(true);
    const { user, handleClickUpdatePrograms, refresh } = props;
    const classes = useHeadStyles();
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const programsService = new ProgramService(process.env.REACT_APP_ENV,process.env.REACT_APP_X_API_KEY,'');

    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getData = async () => {
        try {
            const programs = await programsService.getPrograms("ALL", user.person_id, 0, 20);
            return programs;
        } catch (e) {
            log(e.message);
        }
        return [];
    };


    React.useEffect(() => {
        let isCanceled = true;
        log("refresh---------");
        log(refresh);
        getData().then((data)=>{
            if(isCanceled){
                if(data?.length > 0){
                    setData(data);
                }
                setLoading(false);  
            }
        }).catch((err) => {
        log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, [refresh]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const headCells = [
        { id: 'name', label: 'Nombre del programa' },
        { id: 'company', label: 'Institución' },
        { id: 'type', label: 'Tipo de programa' },
        { id: 'last_update', label: 'Actualizado' },
        { id: 'actions', label: '' },
    ];

    return <React.Fragment>
        <Box display="flex" flexDirection="row" className={classes.buttons_contain}>
            <Button onClick={handleClickUpdatePrograms} variant="contained" className='bg-violet-300' style={{ marginRight: "20px" }}>Actualizar Programas</Button>
        </Box>
        <Loader loading={loading} transparent={true}>
            {data.length > 0 ? <Box className={classes.main} style={{ background: !loading && data.length == 0 ? "transparent" : "#2C3236"}}>
                <TableContainer component={Paper}>
                    <Table aria-label="table" className={classes.table_contain}>
                        <TableHeadCustom>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={'center'}
                                        padding={'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHeadCustom>
                        <RowContainer data={data} getPrograms={getData} order={order} page={page} rowsPerPage={rowsPerPage} orderBy={orderBy} user={user} />
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[2, 5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />
            </Box>
            :<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                { !loading && <NothingFound
                    caption={"No hay programas"}
                    subcaption={""}
                /> }
            </Box>}
        </Loader>
    </React.Fragment>;
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(Programs);