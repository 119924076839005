import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    responsiveContainer:{
        position: 'relative',
        height: '100%',
        '& .container':{
            paddingTop: 82
        }
    },
    root:{
        background: '#141124',
        position: 'relative',
        height: 646,
        '& .title':{
            maxWidth: '58%',
            marginBottom: 24,
            '& h1':{
                textAlign: 'left'
            }
        },
        '& .content':{
            maxWidth:'48%',
            marginBottom:69,
            textAlign: 'left'
        }
    },
    bg:{
        position: 'absolute', 
        right: 0
    },
    bgTop:{
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: theme.palette.colors.fillStroke[300],
        left: 0
    },
    bgImage:{
        // backgroundImage: props => `url(${props.background})`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        position: 'absolute',
    },
    actions:{
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    imgPrimary:{
        width:383
    },
    imgSecondary:{
        width:486
    },
    [theme.breakpoints.down('sm')]:{
        bgResponsive:{
            background: 'linear-gradient(179deg, #3900D9 0.44%, rgba(29, 25, 52, 0.13) 100.31%);mix-blend-mode: soft-light;',
            height: '100%',
            position: 'absolute',
            width: '100%'
        },
    },
    [theme.breakpoints.down('xs')]:{
        container:{
            width: 328
        },
        root:{
            height: 'fit-content',
            '& .title':{
                maxWidth: '100%',
                marginBottom: 25,
                '& h1':{
                    fontWeight: '700 !important',
                    fontSize: '24px !important',
                    lineHeight: '26px !important',
                    textAlign: 'center'
                }
            },
            '& .content':{
                maxWidth:'100%',
                marginBottom:17,
                textAlign: 'center'
            }
        },
        responsiveContainer:{
            '& .container':{
                paddingTop: 48,
                paddingBottom: 26
            }
        },
        actions:{
            flexDirection: 'column',
            '& .MuiButton-root':{
                width: '100%'
            }
        },
        imgPrimary:{
            width:292
        },
        imgSecondary:{
            width:'100%'
        },
    }
}));