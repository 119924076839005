import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Typography, Grid } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel'

import LogoOu from '../LogoOu/LogoOu';
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';
import { eventTracker, eventData } from '../../../../shared/components/EventTracker/EventTracker';
import DashboardCard from './DashboardCard/DashboardCard';
import TestScoreCard from './TestScoreCard/TestScoreCard';
import DashboardChart from './DashboardChart/DashboardChart';

import { AccountService } from '@sdk-point/talisis';

import { ProfileIconList } from '../components/ProfileIconList';

const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const colorsArray = ['#DF43B7', '#ABA0EE', '#8529D8', '#F4AC3D'];

const groupArray = (array, groupSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += groupSize) {
        result.push(array.slice(i, i + groupSize));
    }
    return result;
};

const DashboardSkills = ({ handleChangeTab, changeSubTab}) => {

    const history = useHistory();
    const { person_id, ou } = useSelector((state) => state.userReducer.user);

    const [dashboardState, setDashboardState] = useState({
        isLoading: true,
        personInfo: [],
        chartInfo: [],
        centralTestsInfo: [],
        centralTests: []
    });
    const { isLoading, personInfo, centralTests } = dashboardState;

    const formattedAverage = (personInfo.generalAverage && personInfo.generalAverage <= 10)
                                ? (personInfo.generalAverage * 100 / 10).toFixed(2)
                                : personInfo.generalAverage

    useEffect(() => {
        const doFetch = async() => {
            const personDashboardInfo = await AccountServiceSDK.getDashboard(person_id, ou);
            const centralTests = personDashboardInfo.testResults.map(it => ({...it, factorChunks: groupArray(it.factorItems, 5)}));
            console.log('centralTests', centralTests);
            setDashboardState({
                ...dashboardState,
                isLoading: false,
                personInfo: personDashboardInfo,
                centralTests
            });
        };

        doFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        const chartData = [];
        const centralTest = [];
        if(personInfo.hasOwnProperty('contentBySkills')){
            if(personInfo.contentBySkills.length > 0 && !isLoading){
                personInfo.contentBySkills.map( skill => 
                    (skill.name !== null) && chartData.push(skill)
                );
            }
        }
        if(personInfo.hasOwnProperty('centralTestsInfo')){
            if(personInfo.centralTestsInfo.length > 0 && !isLoading){
                personInfo.centralTestsInfo.map( test => centralTest.push(test));
            }
        }
        setDashboardState({
            ...dashboardState,
            chartInfo: chartData,
            centralTestsInfo: centralTest
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personInfo, isLoading])
    

    const go2Cursos = () => {
        const eventData = {};
        eventData['source'] = 'perfil_estudiante';
        eventTracker('ver_mis_clases', eventData, ['mixpanel']);

        history.push('/cursos')
    }

    const go2Kardex = (event) => {
        eventTracker('ver_mi_kardex', eventData, ['mixpanel']);
        handleChangeTab(1);
        changeSubTab(2);

    }

    return (
        <LoaderContent loading={isLoading}>
            <Typography variant="h2" className='mb-3'>
                Resumen de actividad
            </Typography>
            <Grid container spacing={2}>
                <DashboardCard
                    cardTitle={"Cursos terminados"}
                    caption={"Estos son los últimos datos"}
                    btnFunction={go2Cursos}
                    btnText={"Ir a mis cursos"}
                    icon={ <ProfileIconList icon={"CURSOS"} type={0} />}
                    cardMaxHeight={"initial"}
                    cardHeight={"194px"} 
                    information={<Typography variant="h2">{ personInfo.finishedCourses } cursos</Typography>}
                />
                <DashboardCard
                    cardTitle={"Tu promedio general"}
                    caption={"Estos son los últimos datos"}
                    btnFunction={go2Kardex}
                    btnText={"Ir a mi kárdex"}
                    icon={ <ProfileIconList icon={"CURSOS"} type={0} />}
                    cardMaxHeight={"initial"}
                    cardHeight={"194px"}
                    information={
                        <Grid container direction='column' alignContent='flex-end'>
                            <Typography variant='h2' style={{fontWeight: "bold"}} >
                            { !!personInfo.generalAverage && personInfo.generalAverage !== 'NaN'
                                ? formattedAverage
                                : 0 
                            }
                                <span style={{ color: "#A0A9BA" }}>  /100</span>
                            </Typography>
                            <Grid item>
                                <Grid container direction='row' alignItems='center' className='mt-1'>
                                    <Typography className={clsx('variant-xsmall', 'mr-1')}>
                                        Alumno de 
                                    </Typography>
                                    <LogoOu logoWidth={38} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            <Grid container spacing={3} className="mt-2">
                {
                    centralTests.map((testItem, index) =>
                        <Grid item md={12}>
                            <Carousel interval={7000}
                                navButtonsAlwaysInvisible={testItem?.factorChunks?.length === 1}
                                indicators={testItem?.factorChunks?.length > 1 || false}> 
                                {
                                    testItem.factorChunks.map( chunkItem => 
                                        <TestScoreCard 
                                            key={`$test-item-${testItem.id}`}
                                            cardTitle={testItem.testName}
                                            scoresArray={chunkItem}
                                            caption={"Estos son los ultimos resultados de tu prueba de  "} 
                                            icon={ <ProfileIconList icon={"CHECKMARK"} type={0} />}
                                            color={colorsArray[index]}
                                            gridItemSize={12}
                                            linkTestResult={testItem.link_test_result}
                                        />
                                    )
                                }                   
                            </Carousel>
                        </Grid>
                    ) 
                }
            </Grid>
        </LoaderContent>
    );
};
export default DashboardSkills;
