import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from '../../../../images/pages/referral-page/refer-bg.png'

export default makeStyles((theme) => ({
    root:{
        paddingTop: 8,
        maxWidth: 540,
        width: '100%',
        margin: 'auto'
    },
    banner:{
        height: 147,
        marginBottom: 32
    },
    bgLabel: {
        backgroundImage: `url(${backgroundImage})`,
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    container:{
    },
    list:{
        ...theme.typography.bodyTextLargeRegular
    },
    cuponBox:{
        padding: '32px 0 40px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 16,
        '& .cupon-text':{
            display: 'inline-flex',
            padding: '8px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            borderRadius: 4,
            background: '#D7DEE5',
            '& span':{
                fontSize: 24,
                lineHeight: '24px'
            }
        }
    },
    inputClipboard:{
        background: theme.palette.black,
        padding: '8px 16px',
        ...theme.typography.bodyTextLargeSemiBold,
        color: theme.palette.white,
        border: 'none',
        '&:focus-visible':{
            outline: 'none'
        }
    },
    qrContent:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
    },
    qr:{
        width: 160,
        height: 160,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: '50%',
        background: 'white'
    },
    qrClipboard:{
        maxWidth: 340,
        width: '100%',
        position: 'relative'
    },
    note:{
        height: 150
    },
    notePro:{
        height: '100%',
        gap: 24
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            paddingTop: 0,
            paddingBottom: 85
        },
        banner:{
            height: 98,
            position: 'relative',
            marginBottom: 10,
            '& h1':{
                ...theme.typography.mobile.h2,
                marginTop: 31
            }
        },
        bgLabel: {
            position: 'absolute',
            width: 'calc(100% + 32px)',
            right: '-16px',
            display: 'block',
            textAlign: 'center',
        },
        container:{
            padding: '10px 0 0'
        },
        qrContent:{
            flexDirection: 'column',
            paddingTop: 10,
            paddingBottom: 16
        },
        qr:{
            width: 264,
            height: 264,
            borderRadius: 32,
        },
        inputClipboard:{
            minWidth: 250,
            ...theme.typography.bodyTextSmallSemiBold,
        },
        note:{
            height: 145,
            '& p':{
                ...theme.typography.bodyTextSmallRegular,
            }
        },
        notePro:{
            marginBottom: 54,
            height: '100%',
            '& p':{
                ...theme.typography.bodyTextSmallRegular,
            }
        },
        notePro:{
            marginBottom: 54,
            height: '100%',
            '& p':{
                ...theme.typography.bodyTextSmallRegular,
            }
        },
    }
}));