import { MarketPlaceService } from "@sdk-point/talisis";
import { log } from "../utils/console";

const marketService = new MarketPlaceService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

export const setPersonToOrderOnLogin = async({personInfo, orderId}) => {
  
  const responseUpdateOrder = await marketService.registerUpdateOrder({ person_id: 0 },{
    orderId: orderId,
    first_name: personInfo.firstName,
    last_name: personInfo.lastName,
    email: personInfo.email,
    phone: personInfo.phone_number,
    location: null,
    sso: false,
    isLogin: true
  });

  // const items = checkoutCookie.items ? [...checkoutCookie.items] : [];
  const responseCreateOrder = await marketService.createOrder({ person_id: responseUpdateOrder.personId }, {
      // 'items': items,
      'order_id': responseUpdateOrder.order_id
  });

  return responseCreateOrder
}

export const getOrderQuantityOff = (membershipPlans = [], selectedPlan) => {
  const basePrice = membershipPlans.find(plan => plan.term_months === 1).amount;
  let quantityOff = 0;

  if (selectedPlan?.term_months !== 1) {
    let diffBasePrice = basePrice * selectedPlan?.term_months;
    let diffActualPrice = selectedPlan?.amount;
    quantityOff = diffBasePrice - diffActualPrice;
  }
  log("quantityOff:", quantityOff);

  return quantityOff;
};

export const updateOrderQuantitiesAndSelectedPeriod = (new_term_slug, membershipPlans, quantityReducer) => {
  let selectedPeriod = membershipPlans.find((value,index)=>value.term_slug === new_term_slug);
  let quantityOff = getOrderQuantityOff(membershipPlans, selectedPeriod);
  let total_ahorro = parseFloat( quantityReducer?.pending_discount + quantityReducer?.coupon_discount + quantityOff);
  let total = selectedPeriod?.amount - quantityReducer?.coupon_discount - quantityReducer?.pending_discount;
  const orderQuantities = {
    ...quantityReducer,
    order_subtotal: selectedPeriod?.amount,
    order_total: total,
    plan_months: selectedPeriod?.term_months,
    plan_price: selectedPeriod?.amount,
    ahorro: total_ahorro,
    onSelectingPeriod:true
  };

  return { orderQuantities, selectedPeriod }
}

export const updateOrderQuantitiesDiscount = (pendingDiscount, amountOff, couponDiscount, quantityReducer) => {
  let pending_discount = parseFloat( pendingDiscount ? pendingDiscount : 0 );
  let amount_off = parseFloat( amountOff ? amountOff : 0 );
  let coupon_discount = parseFloat( couponDiscount ? couponDiscount : 0);
  let total_ahorro = parseFloat( pending_discount + coupon_discount + amount_off );
  
  const orderQuantities = {
    ...quantityReducer,
    ahorro: total_ahorro,
    pending_discount: pending_discount,
    coupon_discount: coupon_discount
  };
  log("orderQuantities:", orderQuantities);
  
  return orderQuantities;
};

export const verifyPhone = (phoneNumber) =>{
    
    if(phoneNumber === "")
        return {
            message: "El campo está vacío, escriba un número de teléfono de 10 digitos para continuar",
            error: true
        }

    let format=/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let result=format.test(phoneNumber);
    if(result === false)
        return {
            message: "No es un número de teléfono válido",
            error: true
        }

    return {
        message: "",
        error: false
    }
};