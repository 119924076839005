import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Shared components
import Dropdown from '../../../../shared/components/Dropdown/Dropdown';

// Material components
import { Icon, IconButton } from '@material-ui/core';

// Components
import EventRegistration from './EventRegistration';

// Styles
import useStyles from '../../../../assets/styles/jss/pages/events/buttonActionStyle';

const ButtonAction = ({registerEvent, optionDropdown, onClickRegister, isLogged, user, onRegisterSubmit, onClickInterestEvent, interestEvent, isResponsive}) => {
    const classes = useStyles();

    return (
        <>
        {
            registerEvent 
            ? <Dropdown 
                text={optionDropdown[0].label}
                options={optionDropdown} 
                variant="contained" 
                color="primary" 
                fullWidth 
                endIcon={<Icon className="ri-arrow-down-s-line text-white"/>} 
                className={classes.buttonRegister}
                onClickOption={onClickRegister}
            />
            :<EventRegistration isLogged={isLogged} user={user} fullWidth onRegisterSubmit={onRegisterSubmit} isResponsive={isResponsive}/>
        }
        {
            isLogged &&
            <IconButton color="primary" onClick={onClickInterestEvent}>
                <Icon className={clsx(interestEvent.isInteresting ? 'ri-bookmark-fill' : 'ri-bookmark-line', 'font-size-20')}/>
            </IconButton>
        }
        </>
    );
};

ButtonAction.defaultProps = {
    registerEvent: false,
    isLogged: false,
    user: {},
    isResponsive: false,
};

ButtonAction.propTypes = {
    registerEvent: PropTypes.bool,
    onClickRegister: PropTypes.func,
    isLogged: PropTypes.bool,
    user: PropTypes.object,
    onRegisterSubmit: PropTypes.func,
    onClickInterestEvent: PropTypes.func,
    interestEvent: PropTypes.object
};

export default ButtonAction;