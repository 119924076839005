import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, Typography, Button, Paper, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Tooltip, LineChart, Line, XAxis, YAxis, CartesianGrid } from "recharts";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TimelineIcon from '@material-ui/icons/Timeline';
import DescriptionIcon from '@material-ui/icons/Description';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PrintIcon from '@material-ui/icons/Print';
import { TrendingFlat, TrendingUp } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

// shared components
import LogoOu from '../LogoOu/LogoOu';
import DynamicTable from '../Horario/DynamicTable';
import LoaderContent from '../../../../shared/components/Loader/LoaderContent';
import PrintPDFBtn from '../Academic/PrintPDFBtn/PrintPDFBtn';

import { AccountService } from '@sdk-point/talisis';
import NothingFound from '../../../../shared/components/NothingFound/NothingFound';
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';
const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        background: theme.palette.colors.grey[500],
        color: theme.palette.colors.grey[400]
    },
    inputDashboard: {
        background: theme.palette.colors.grey[500],
        '& > div': {
            padding: '12px 12px 10px'
        }
    },
    topFive: {
        marginTop: '25px'
    },
    topFiveText: {
        marginBottom: '15px'
    },
    spanChart: {
        boxSizing: ' border-box',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // padding:' 2px 15px',
        gap: '4px',
        border: '0.5px solid rgba(255, 255, 255, 0.15)',
        borderRadius: '4px',
        marginRight: '10px',
    }
}));

// Table headers
let colNames = ["Clave", "Materia", "Grupo", "Período", "Calificación Final"];
let colNames2 = ["Clave", "Materia", "Faltas", "1er Parcial", "2do Parcial", "3er Parcial", "4to Parcial", "5to Parcial", "Global"];

const Calificaciones = (props) => {
    const classes = useStyles();

    const { about_me: { userName, user_id, ou }, studentProgram, service, isCurrentTab } = props;

    const { student_id } = useSelector((state) => state.userReducer.user);

    const printAreaCalificaciones = useRef(null);
    const printAreaEvaluaciones = useRef(null);

    const [time, setTime] = React.useState(40)
    const avance = 20
    const [restante, setRestante] = React.useState(time - avance)
    const [data, setData] = React.useState([
        { name: "Avance", value: avance },
        { name: "Restante", value: restante },
    ]);
    const [courseType, setCourseType] = React.useState('enrrolled');
    const [periodTyme, setPeriodTime] = React.useState('month');

    const [califications, setCalifications] = React.useState([]);
    const calificationsData = [];

    const [currentPeriodCalifications, setCurrentPeriodCalifications] = React.useState('');
    const [currentPeriodNameCalifications, setCurrentPeriodNameCalifications] = React.useState('');
    const [currentPeriodEvaluation, setCurrentPeriodEvaluation] = React.useState('');
    const [currentPeriodNameEvaluation, setCurrentPeriodNameEvaluation] = React.useState('');
    const [periods, setPeriods] = React.useState([]);
    const periodsData = [];

    const [evaluation, setEvaluation] = React.useState([]);
    const evaluationData = [];

    const [promedios, setPromedios] = React.useState('');
    const promediosData = [];

    const [promedioGeneral, setPromedioGeneral] = React.useState('');
    const promedioGeneralData = [];

    const [lineChart, setLineChart] = React.useState([]);
    let variation = 0;
    const lineChartData = [];

    const sendEscolarCalificacionesEventToMixPanel=()=>{
        const eventData = {};
        eventTracker('s_escolar_calificaciones', eventData, ['mixpanel']);
    };    

    React.useEffect(()=>{
        if(isCurrentTab)
        {
            sendEscolarCalificacionesEventToMixPanel();
        }
    },[isCurrentTab]);

    React.useEffect(() => {
        setRestante(time - avance)
        const rest = time - avance
        setData([
            { name: "Avance", value: avance },
            { name: "Restante", value: rest },
        ])

        const fetchData = async () => {
            const result = await AccountServiceSDK.getStudentScheduleCalifications(ou, user_id, 'califications');
            if (!student_id) return;

            if (result.data) {
                if (result.data.persona) {
                    if (result.data.persona.boletas) {
                        if (result.data.persona.boletas.edges) {
                            result.data.persona.boletas.edges.map(edge => {
                                if (edge.node) {
                                    if (edge.node.materias) {
                                        if (edge.node.materias.edges) {
                                            const periodo = edge.node.clave;
                                            const promedio = parseFloat(edge.node.promedio);
                                            const nombrePeriodo = edge.node.periodo.nombre;
                                            periodsData.push({
                                                [parseInt(periodo)]: nombrePeriodo
                                            });
                                            lineChartData.push({
                                                name: periodo,
                                                calificacion: promedio
                                            })
                                            promediosData.push({
                                                [periodo]: promedio
                                            });
                                            promedioGeneralData.push(parseFloat(promedio));
                                            edge.node.materias.edges.map(edge => {
                                                calificationsData.push({
                                                    clave: edge.node.materia,
                                                    materia: edge.node.titulo,
                                                    grupo: edge.node.grupo,
                                                    periodo: periodo,
                                                    calificacion: edge.node.califFinal !== ' ' ? edge.node.califFinal : '-',
                                                });
                                                
                                                const primerParcial = edge.node.primerParcial !== ' ' ? edge.node.primerParcial : '-';
                                                const segundoParcial = edge.node.segundoParcial !== ' ' ? edge.node.segundoParcial : '-';
                                                const tercerParcial = edge.node.tercerParcial !== ' ' ? edge.node.tercerParcial : '-';
                                                const cuartoParcial = edge.node.cuartoParcial !== ' ' ? edge.node.cuartoParcial : '-';
                                                const quintoParcial = edge.node.quintoParcial !== ' ' ? edge.node.quintoParcial : '-';
                                                const califFinal = edge.node.califFinal !== ' ' ? edge.node.califFinal : '-';
                                                evaluationData.push({
                                                    [periodo]: {
                                                        clave: edge.node.materia,
                                                        materia: edge.node.titulo,
                                                        faltas: edge.node.faltas,
                                                        primerParcial: primerParcial,
                                                        segundoParcial: segundoParcial,
                                                        tercerParcial: tercerParcial,
                                                        cuartoParcial: cuartoParcial,
                                                        quintoParcial: quintoParcial,
                                                        califFinal: califFinal
                                                    }
                                                });
                                            });
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
                setLineChart(lineChartData);
                setCalifications(calificationsData);
                setPeriods(periodsData);
                setCurrentPeriodCalifications(periodsData.length > 0 ? Object.keys(periodsData[periodsData.length - 1])[0] : '');
                setCurrentPeriodNameCalifications(periodsData.length > 0 ? periodsData[periodsData.length - 1][Object.keys(periodsData[periodsData.length - 1])[0]] : '');
                setCurrentPeriodEvaluation(periodsData.length > 0 ? Object.keys(periodsData[periodsData.length - 1])[0] : '');
                setCurrentPeriodNameEvaluation(periodsData.length > 0 ? periodsData[periodsData.length - 1][Object.keys(periodsData[periodsData.length - 1])[0]] : '');
                setEvaluation(evaluationData);
                setPromedios(promediosData);
                setPromedioGeneral(promedioGeneralData);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ou, service, time, user_id]);

    const changePeriodCalifications = (period, direction) => {
        periods.map((p, k) => {
            if (periods[k][period]) {
                if (direction === 'left') {
                    if (periods[k - 1]) {
                        setCurrentPeriodCalifications(Object.keys(periods[k - 1])[0]);
                        setCurrentPeriodNameCalifications(periods[k - 1][Object.keys(periods[k - 1])[0]]);
                    }
                }
                else {
                    if (periods[k + 1]) {
                        setCurrentPeriodCalifications(Object.keys(periods[k + 1])[0]);
                        setCurrentPeriodNameCalifications(periods[k + 1][Object.keys(periods[k + 1])[0]]);
                    }
                }
            }
        });
    };

    const changePeriodEvaluations = (period, direction) => {
        colNames2 = ["Clave", "Materia", "Faltas", "1er Parcial", "2do Parcial", "3er Parcial", "4to Parcial", "5to Parcial", "Global"];
        periods.map((p, k) => {
            if (periods[k][period]) {
                if (direction === 'left') {
                    if (periods[k - 1]) {
                        setCurrentPeriodEvaluation(Object.keys(periods[k - 1])[0]);
                        setCurrentPeriodNameEvaluation(periods[k - 1][Object.keys(periods[k - 1])[0]]);
                    }
                }
                else {
                    if (periods[k + 1]) {
                        setCurrentPeriodEvaluation(Object.keys(periods[k + 1])[0]);
                        setCurrentPeriodNameEvaluation(periods[k + 1][Object.keys(periods[k + 1])[0]]);
                    }
                }
            }
        });
    };

    const lineChartVariation = () => {
        let variation = 0;
        if (promedios.length > 0) {
            promedios.map((p, k) => {
                if (promedios[k] && promedios[k + 1]) {
                    let v1 = Object.values(promedios[k])[0];
                    let v2 = Object.values(promedios[k + 1])[0];
                    v2 = (promedios.length === (k + 1) && v2 === 0) ? 'NaN' : v2;
                    if (!isNaN(v1) && !isNaN(v2)) {
                        if (v1 > 0) {
                            variation += ((v2 - v1) / v1) * 100;
                        }
                    }
                }
            });
            variation = variation.toFixed(1);
        }
        return variation;
    };
    variation = lineChartVariation();

    if (!student_id)
        return <NothingFound
            caption= {"Sin Calificaciones"}
            subcaption={"No estás inscrito a ningún plan de estudios actualmente"}
        />

    const lessColumnNames = colNames.filter(columna => {
        if (columna !== 'Clave' && columna !== 'Grupo') {
            return columna
        }
    });

    const lessColnames2 = colNames2.filter(columna => {
        if (columna !== 'Clave' && columna !== 'Faltas') {
            return columna
        }
    });

    let tableCalifications = califications.filter(calification => calification.periodo === String(currentPeriodCalifications))

    let lessTableCalifications = tableCalifications.map(row => {
        return {
            materia: row.materia,
            periodo: row.periodo,
            calificacion: row.calificacion,
        }
    })

    let tableEvaluations = evaluation.filter(e => Object.keys(e)[0] === currentPeriodEvaluation).map(e => e[currentPeriodEvaluation])
    if (tableEvaluations) {
        let parciales = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
        tableEvaluations.map(evaluation => {
            parciales[1] += evaluation.primerParcial && evaluation.primerParcial !== '-' ? 1 : 0;
            parciales[2] += evaluation.segundoParcial && evaluation.segundoParcial !== '-' ? 1 : 0;
            parciales[3] += evaluation.tercerParcial && evaluation.tercerParcial !== '-' ? 1 : 0;
            parciales[4] += evaluation.cuartoParcial && evaluation.cuartoParcial !== '-' ? 1 : 0;
            parciales[5] += evaluation.quintoParcial && evaluation.quintoParcial !== '-' ? 1 : 0;
        });

        tableEvaluations.map(evaluation => {
            if (parciales[1] === 0) {
                delete colNames2[3];
                delete evaluation.primerParcial;
            }
            if (parciales[2] === 0) {
                delete colNames2[4];
                delete evaluation.segundoParcial;
            }
            if (parciales[3] === 0) {
                delete colNames2[5];
                delete evaluation.tercerParcial;
            }
            if (parciales[4] === 0) {
                delete colNames2[6];
                delete evaluation.cuartoParcial;
            }
            if (parciales[5] === 0) {
                delete colNames2[7];
                delete evaluation.quintoParcial;
            }
            return evaluation;
        });
    }

    let lessTAbleEvaluations = tableEvaluations.map(row => {
        return {
            materia: row.materia,
            primerParcial: row.primerParcial,
            segundoParcial: row.segundoParcial,
            califFinal: row.califFinal
        }
    })

    return (
        <LoaderContent loading={false}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} justify='center'>
                    <Card>
                        <CardContent>
                            <Grid container justify="space-between" alignItems='center'>
                                <Grid item >
                                    <Typography className='mt-1' variant="body1">
                                        Estadística de Desempeño por período
                                    </Typography>
                                    <Typography className='mt-1' color="textSecondary" variant="subtitle2">
                                        Última actualización hace 2 minutos
                                    </Typography>
                                </Grid>
                                <Grid item className="variation">
                                    <Paper>
                                        <Grid container justify="space-around" alignItems='center'>
                                            {variation ?
                                                variation >= 0 ?
                                                    <>
                                                        {variation > 0 ? <TrendingUp /> : <TrendingFlat />}
                                                        <Typography className='m-1' variant="h5">{variation}%</Typography>
                                                    </>
                                                    :
                                                    <>
                                                        <TrendingDownIcon />
                                                        <Typography className='m-1' variant="h5">{Math.abs(variation)}%</Typography>
                                                    </>
                                                :
                                                <Typography className='m-1' variant="h5" style={{ textAlign: 'center', width: '74px' }}>
                                                    <CircularProgress style={{ height: '12px', marginTop: '5px', width: '12px' }} color="primary" />
                                                </Typography>
                                            }
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between" className='mt-2'>
                                <Grid item xs={12}>
                                    <LineChart
                                        width={isWidthDown("sm", props.width) ? 388 : 500}
                                        height={300}
                                        data={lineChart}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="0" vertical={false} />
                                        <XAxis dataKey="name" />
                                        <YAxis dataKey="calificacion" />
                                        <Tooltip />
                                        <Line
                                            type="monotone"
                                            dataKey="calificacion"
                                            stroke="#FF8000"
                                            activeDot={{ r: 8 }}

                                        />
                                    </LineChart>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Grid container direction='column' alignItems='center'>
                                <Grid item xs={2} className='mt-1'>
                                    <Avatar variant="rounded" className={classes.large}>
                                        <TimelineIcon style={{ fontSize: 60 }} />
                                    </Avatar>
                                </Grid>
                                <Grid item xs={8} alignItems='center'>
                                    <Typography variant="h3" className='mt-2' style={{ textAlign: 'center' }}>
                                        En camino a la Excelencia
                                    </Typography>
                                    <Typography variant="caption" className='mt-2'>
                                        Excelencia academica y diversidad cultural
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={12} justify='center' className='mt-4'>
                    <Card ref={printAreaCalificaciones}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Grid className='align-info'>
                                        <LogoOu />
                                        <SignalCellularAltIcon fontSize='inherit' color="secondary" />
                                        <Typography color="secondary" variant='caption' className='mr-2'>-</Typography>

                                        <DescriptionIcon fontSize='inherit' color="secondary" />
                                        <Typography color="secondary" variant='caption' className='mr-2'>{currentPeriodNameCalifications.toUpperCase()}</Typography>

                                    </Grid>
                                    <Typography className='ml-1' color="textSecondary" variant="body1">
                                        Alumno: {userName}
                                    </Typography>
                                    <Typography className='ml-1' color="textSecondary" variant="body1">
                                        Carrera: {
                                            studentProgram &&
                                            studentProgram.map(program => `${program.description[1]} (${program.program_id} ${program.major_id})`)
                                        }
                                    </Typography>
                                    <Grid className='align-info'>
                                        <Typography className='ml-1' color="textSecondary" variant="body1">
                                            Nivel: {
                                                studentProgram &&
                                                studentProgram.map(program => program.level_id)
                                            }
                                        </Typography>
                                        <Typography className='ml-1' color="textSecondary" variant="body1">
                                            Campus: {
                                                studentProgram &&
                                                studentProgram.map(program => program.campus_id)
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card className='mb-3' style={{ backgroundColor: "#1C333C" }}>
                                        <CardContent>
                                            <Grid container >
                                                <Grid item xs={10}>
                                                    <Typography variant="body2" color='primary'>
                                                        Promedio del Período
                                                    </Typography>
                                                    {promedios.length > 0 ?
                                                        <>
                                                            <Typography variant="h1">
                                                                {promedios.length > 0 && promedios.filter(p => Object.keys(p)[0] === currentPeriodCalifications)[0][currentPeriodCalifications]} /
                                                                {promedios.filter(p => Object.keys(p)[0] === currentPeriodCalifications)[0][currentPeriodCalifications] <= 10 ? ' 10' : ' 100'}
                                                            </Typography>
                                                            <Typography variant="caption">Ultima actualización hace 2 min</Typography>
                                                        </>
                                                        :
                                                        <CircularProgress style={{ height: '24px', marginLeft: '50px', marginTop: '17px', width: '24px' }} color="primary" />
                                                    }

                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card className='mb-3' style={{ backgroundColor: "#1C333C" }}>
                                        <CardContent>
                                            <Grid container >
                                                <Grid item xs={10}>
                                                    <Typography variant="body2" color='primary'>
                                                        Promedio General
                                                    </Typography>
                                                    {promedioGeneral.length > 0 ?
                                                        <>
                                                            <Typography variant="h1">
                                                                {(promedioGeneral.reduce((pv, cv) => pv + cv, 0) / promedioGeneral.length).toFixed(1)} /
                                                                {(promedioGeneral.reduce((pv, cv) => pv + cv, 0) / promedioGeneral.length) <= 10 ? ' 10' : ' 100'}
                                                            </Typography>
                                                            <Typography variant="caption">Ultima actualización hace 2 min</Typography>
                                                        </>
                                                        :
                                                        <CircularProgress style={{ height: '24px', marginLeft: '50px', marginTop: '17px', width: '24px' }} color="primary" />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <br />
                                <Grid container justify="space-between" className='mt-2 ml-2'>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <PrintPDFBtn
                                            btnName={"Imprimir Boleta"}
                                            elementId={printAreaCalificaciones.current}
                                            icon={<PrintIcon />}
                                            btnDisabled={califications.length === 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <Card variant='outlined' className='period-container mr-2' style={{ backgroundColor: "#1C333C" }}>
                                            <Button onClick={() => changePeriodCalifications(currentPeriodCalifications, 'left')}><ArrowLeftIcon /></Button>
                                            <Typography className="period-label">{currentPeriodNameCalifications ? currentPeriodNameCalifications.toUpperCase() : <CircularProgress style={{ height: '12px', marginTop: '5px', width: '12px' }} color="primary" />}</Typography>
                                            <Button onClick={() => changePeriodCalifications(currentPeriodCalifications, 'right')}><ArrowRightIcon /></Button>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container className='mt-2'>
                                <Grid item xs={12} style={{ overflowX: 'auto' }}>
                                    {califications.length > 0 ?
                                        <DynamicTable tableHeaders={isWidthDown("sm", props.width) ? lessColumnNames : colNames} tableRows={isWidthDown("sm", props.width) ? lessTableCalifications : tableCalifications} />
                                        :
                                        <div style={{ textAlign: 'center' }}>
                                            <DynamicTable tableHeaders={isWidthDown("sm", props.width) ? lessColumnNames : colNames} tableRows={[]} colSpan={colNames.length} />
                                            <div style={{ backgroundColor: '#111111', padding: '16px', verticalAlign: 'middle' }}><CircularProgress style={{ height: '24px', marginTop: '5px', width: '24px' }} color="primary" /></div>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} justify='center' className='mt-4'>
                    <Card ref={printAreaEvaluaciones}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item >
                                    <Typography className='m-1' variant="h6">
                                        Consulta de Evaluación
                                    </Typography>
                                </Grid>
                                <br />
                                <Grid container justify="space-between" className='mt-2 ml-2'>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <PrintPDFBtn
                                            btnName={"Imprimir Evaluaciones"}
                                            elementId={printAreaEvaluaciones.current}
                                            icon={<PrintIcon />}
                                            btnDisabled={evaluation.length === 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <Card variant='outlined' className='period-container mr-2' style={{ backgroundColor: "#1C333C" }}>
                                            <Button onClick={() => changePeriodEvaluations(currentPeriodEvaluation, 'left')}><ArrowLeftIcon /></Button>
                                            <Typography className="period-label">{currentPeriodNameEvaluation ? currentPeriodNameEvaluation.toUpperCase() : <CircularProgress style={{ height: '12px', marginTop: '5px', width: '12px' }} color="primary" />}</Typography>
                                            <Button onClick={() => changePeriodEvaluations(currentPeriodEvaluation, 'right')}><ArrowRightIcon /></Button>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container className='mt-2'>
                                <Grid item xs={12} style={{ overflowX: 'auto' }}>
                                    {evaluation.length > 0 ?
                                        <DynamicTable tableHeaders={isWidthDown("sm", props.width) ? lessColnames2 : colNames2} tableRows={
                                            isWidthDown("sm", props.width) ? lessTAbleEvaluations : tableEvaluations
                                        } />
                                        :
                                        <div style={{ textAlign: 'center' }}>
                                            <DynamicTable tableHeaders={isWidthDown("sm", props.width) ? lessColnames2 : colNames2} tableRows={[]} colSpan={colNames.length} />
                                            <div style={{ backgroundColor: '#111111', padding: '16px', verticalAlign: 'middle' }}><CircularProgress style={{ height: '24px', marginTop: '5px', width: '24px' }} color="primary" /></div>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </LoaderContent>
    );
};

export default withWidth()(Calificaciones);
