import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// shared
import {REQUEST_STATUS_ID, REQUEST_STEP_TYPE_ID, DOCUMENT_STATUS_ID} from '../../constants/request-status';
import dateFormat from '../../../design-system/utils/dateFormat';
import { SIMPLE_DATE_FORMAT } from '../../../design-system/constants/date-formats';
import { log } from '../../../shared/utils/console.js'

// Styles
import useStyles from '../../../assets/styles/jss/pages/requests/detailFileButtonStyle';

// utils
import objectIsEmpty from "../../../shared/utils/objectIsEmpty";

import { green } from '@material-ui/core/colors';

import Select from '../../../design-system/components/Form/Select/Select';

import clsx from 'clsx';


import { GlobalService } from '@sdk-point/talisis'; 
const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const itemsActionToDocumentReception = [
    {id: 0, label: "Seleccione"},
    {id: DOCUMENT_STATUS_ID.REJECTED, label: "Rechazado"},
    {id: DOCUMENT_STATUS_ID.APPROVED, label: "Aprobado"}
];

const DetailFileButton = ({ file, onClickDeleteFile, optionName, label, isSavedFile = false, isAuthorizer, activeWorkflowStep, onValidateChangeStatus, statusDocumentValue, stepName }) => {
    const classes = useStyles();

    const handleClickDeleteFile = () => {
        if ( !!optionName ) {
            if (isSavedFile)
                onClickDeleteFile( file, optionName, isSavedFile );
            else 
                onClickDeleteFile( file, optionName );
        } else {
            onClickDeleteFile(file);
        }
    };

    const handleChangeStatusDocument = (event) => {
        onValidateChangeStatus(optionName, event.target.value, stepName, event.target.value === DOCUMENT_STATUS_ID.REJECTED);
    };

    const getTypeOfActionButtons = (file, label) => {
        const activateValidationBtts = isAuthorizer && REQUEST_STEP_TYPE_ID.DIGITAL_DOCUMENT_REVIEW === activeWorkflowStep.request_step_type_id;

        if(file.documentStatus === 2 && !activateValidationBtts && (objectIsEmpty(activeWorkflowStep) || activeWorkflowStep.hasOwnProperty('request_step_type_id')) && statusDocumentValue === 0){
            return <CheckCircleIcon fontSize="small" style={{ color: green[500] }}/>
        }

        // DocumentsStatus REJECTED Y REPLACED seran los mismo
        const STATUS_DOCUMENT_VALUE = statusDocumentValue == DOCUMENT_STATUS_ID.REJECTED || statusDocumentValue == DOCUMENT_STATUS_ID.REPLACED ? DOCUMENT_STATUS_ID.REJECTED : statusDocumentValue;

        return file.isNew ?
            <IconButton color="primary" aria-label="upload" component="span" onClick={ handleClickDeleteFile } className="p-0">
                <DeleteOutlineOutlinedIcon />
            </IconButton>
            :
            <>
                {
                    !label && <DownloadFile url={file.path} name={file.value} />
                }
                {
                    (activateValidationBtts)
                    && 
                    <Box minWidth="150px">
                        <Select items={itemsActionToDocumentReception} label={`Seleccionar estatus`} name={`estatus`} onChange={handleChangeStatusDocument} value={STATUS_DOCUMENT_VALUE}/>
                    </Box>
                }
                
            </>;        
    };

    return (
        <Button fullWidth style={{ justifyContent: 'unset' }} className="p-0">
            <Box display="flex" alignItems="center" width="100%" minHeight={88} p={3}>
                <i className={clsx(classes.fileIcon, 'ri-file-text-line')}></i>
                {/* <DescriptionOutlinedIcon className="mr-2" color="primary" style={{fontSize: 40}}/> */}
                <Box flexGrow={1} textAlign="initial">
                    {
                        !!label && (
                            <Box className={classes.showDocument}>
                                <Typography className="text-transform-none d-block">{ label }</Typography>
                                {
                                    !file.isNew && (
                                        <Link href={ file.path } className={`MuiLink-underlineNone ${classes.downloadLink}`}>
                                            <Typography variant="subtitle2" className="text-orange-300">Ver documento</Typography>
                                        </Link>
                                    )
                                }
                            </Box>
                        )
                    }
                    <Typography className="text-transform-none d-block" variant="subtitle2" color="textSecondary">{file.value} - {dateFormat(file.created_at, SIMPLE_DATE_FORMAT)}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    {
                        getTypeOfActionButtons(file, label)
                    }
                </Box>
            </Box>
        </Button>
    )
};

function DownloadFile({ url, name }) {
    const handleClick = async () => {
        try {
            const blobAsBase64 = await GlobalServiceSDK.downloadFile(url);

            let anchor = document.createElement('a');
            anchor.setAttribute("type", "hidden");
            anchor.href = `data:application/pdf;base64,${blobAsBase64}`;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            anchor = undefined;
        } catch (error) {
            log('error', error);
        }
    }

    return (
        <Link color="primary" onClick={handleClick}>
            <GetAppIcon color="primary" />
        </Link>
    )
}

DetailFileButton.defaultProps = {
    isAuthorizer: false,
    activeWorkflowStep: {},
    onValidateChangeStatus: undefined,
    statusDocumentValue: 0,
    stepName: undefined
};

DetailFileButton.propTypes = {
    file: PropTypes.object, 
    onClickDeleteFile: PropTypes.func,
    optionName: PropTypes.string, 
    label:PropTypes.string,
    isSavedFile: PropTypes.bool, 
    isAuthorizer: PropTypes.bool,
    activeWorkflowStep: PropTypes.object,
    onValidateChangeStatus: PropTypes.func,
    statusDocumentValue: PropTypes.number,
    stepName: PropTypes.string
};

export default DetailFileButton;


