import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import { Box, Typography, Icon } from '@material-ui/core';

// Style
import useStyles from '../../../../../assets/styles/jss/pages/home/cardItemStyle';
import clsx from 'clsx';

const CardItem = ({id, name, icon, onChangeTab, activeTab}) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.cardItemRoot, id === activeTab && classes.selectedCard)} onClick={() => onChangeTab(id)}>
            <Icon className={icon}></Icon>
            <Box className={classes.textContainer}>
                <Typography variant='body1' className={clsx('semi-bold', classes.labelButton)}>{name}</Typography>
            </Box>
        </Box>
    );
};

CardItem.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onChangeTab: PropTypes.func,
    activeTab: PropTypes.number,
};

export default CardItem;