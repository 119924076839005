import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    cardContent:{
        padding: 24
    },
    title:{
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
        marginBottom: theme.spacing(2)
    },
    subtitle:{
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
        marginBottom: theme.spacing(3)
    },
    divider:{
        backgroundColor: 'rgba(119, 119, 119, 0.2)'
    },
    file:{
        padding: '12px 0',
        '& .MuiSvgIcon-root':{
            fontSize: 13,
            color: '#2ECFE8',
            marginRight: 10
        },
        'span':{
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '16px'
        }
    },
    button:{
        border: '1px solid #A86FFB',
        filter: 'drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.1))',
        borderRadius: 27,
        padding: '8px 16px',
        height: 48,
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '16px'
    }
}));