import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    note:
    {
        color: '#A0A9BA',
        fontSize: '12px',
        paddingBottom: '24px'
    },
    line:
    {
        borderTop: '1px solid #404150',
        paddingBottom: '33px'
    },
    textButton: {
        marginRight: '70px',
        height: '57px',
        "@media (max-width: 510px)": {
            width: '100%',
            height: '70px',
        }
    },
    proceedButton: {
        fontSize: '16px',
        color: 'white',
        fontWeight: '600',
        width: '260px',
        textTransform: 'none',
        "@media (max-width: 510px)": {
            width: '100%'
        },
        marginLeft: "16px"
    },
    radioPaymentMethod: {
        "&.MuiRadio-root": {
            color: theme.palette.colors.violet[300]
        }
    }
}));
